import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { SnakeLoader } from "@butterfly-frontend/ui"
import withStyles from "HOC/withStyles"
import { Summary, SummarySalesPersonList, Header } from "modules/DashboardModule/components"

import styles from "./DashboardPage.module.css"

const DashboardPage = ({ dashboardData, summarySalesPersonList, sortState, onChangeSort, isLoading }) => (
    <Fragment>
        <Header title="Dashboard" />
        {isLoading ? (
            <SnakeLoader />
        ) : (
            <Fragment>
                <Summary dashboardData={dashboardData} />
                <SummarySalesPersonList
                    list={summarySalesPersonList}
                    sortState={sortState}
                    onChangeSort={onChangeSort}
                />
            </Fragment>
        )}
    </Fragment>
)

DashboardPage.propTypes = {
    dashboardData: PropTypes.shape({
        orders_totals: PropTypes.shape({
            total_sales_amount: PropTypes.number,
            total_returns_amount: PropTypes.number,
            total_store_credits: PropTypes.number,
            total_payments: PropTypes.number
        }).isRequired,
        invoiced: PropTypes.shape({
            invoices_count: PropTypes.number,
            fully_paid: PropTypes.number
        }).isRequired,
        orders_by_completion: PropTypes.shape({
            incomplete: PropTypes.number,
            finished: PropTypes.number
        }).isRequired,
        sale_channels: PropTypes.shape({
            showroom: PropTypes.number,
            web: PropTypes.number
        }).isRequired
    }),
    summarySalesPersonList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            full_name: PropTypes.string.isRequired,
            clients_count: PropTypes.number.isRequired,
            invoices_count: PropTypes.number.isRequired,
            total_sales_amount: PropTypes.number.isRequired,
            total_payments: PropTypes.number.isRequired
        }).isRequired
    ),
    sortState: PropTypes.shape({
        by: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired
    }).isRequired,
    onChangeSort: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default withStyles(DashboardPage, styles)
