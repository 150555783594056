import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import isEqual from "lodash/isEqual"
import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"

import ListingLayout from "../../OtherListingLayout/index"
import Table from "../ClientsTable"
import { scrollToTop } from "helpers/scrollTo"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import styles from "./ListingClients.css"
import customSnakeLoaderStyles from "./overrides/snakeloader.module.css"

class ListingClients extends Component {
    clientsPerPage = 20

    state = {
        deleteModalOpen: false,
        currentClientDelete: null,
        sorting: { by: "last_name", direction: "asc" },
        isLoadingMore: false
    }

    componentDidMount() {
        const { clientGroupId, getClientGroup } = this.props
        getClientGroup(clientGroupId)
        this.handleGetClients()
        scrollToTop()
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevState.sorting, this.state.sorting)) {
            this.setState({ isLoadingMore: true }, this.handleGetClients)
        }

        if (prevState.isLoadingMore && prevProps.clients.isLoading && !this.props.clients.isLoading) {
            this.setState({ isLoadingMore: false })
        }
    }

    handleGetClients = (params = {}) => {
        const { getClients, clientGroupId } = this.props
        const { sorting } = this.state
        const requestParams = {
            length: this.clientsPerPage,
            params: { ...params, group_id: clientGroupId, sort_by: sorting.by, sort_direction: sorting.direction }
        }
        getClients(requestParams)
    }

    handleLoadMore = () => {
        const { current_page } = this.props.clients.meta
        const page = current_page ? current_page + 1 : 1

        this.handleGetClients({ page })
    }

    handleOpenModal = id => {
        const { clients } = this.props
        const currentClientDelete = clients.data.find(c => c.id === id)

        if (currentClientDelete) {
            this.setState({ deleteModalOpen: true, currentClientDelete })
        }
    }

    handleDeleteClient = id => {
        const { clientGroupId, showSuccessNotification, showErrorNotification, clients, getClientsSuccess } = this.props

        const data = clients.data.filter(c => c.id !== id)
        getClientsSuccess({ ...clients, data })

        const body = { clients_detach: [{ id }] }
        fetch
            .patch(`/client-groups/${clientGroupId}`, body)
            .then(() => showSuccessNotification())
            .catch(() => {
                showErrorNotification()
                this.handleGetClients()
            })
    }

    handleSetSorting = sorting => this.setState({ sorting })

    handleHideModal = callback => this.setState({ deleteModalOpen: false, currentClientDelete: null }, callback)

    handleDelete = id => this.handleHideModal(() => this.handleDeleteClient(id))

    render() {
        const { currentClientGroup, clientGroupId, clients } = this.props
        if (!clientGroupId) {
            return <Redirect to="/settings/interactions" />
        }

        if (!currentClientGroup.isLoaded) {
            return <SnakeLoader customStyles={customSnakeLoaderStyles} />
        }

        return (
            <ListingLayout
                returnLink={{
                    label: "Interactions",
                    link: `/settings/interactions`
                }}
                topLayout={this.renderTopLayout()}
            >
                <Table
                    {...this.props}
                    {...this.state}
                    isLoading={clients.isLoading}
                    isLoadingMore={this.state.isLoadingMore}
                    list={(clients && clients.data) || []}
                    currentSorting={this.state.sorting}
                    handleHideModal={this.handleHideModal}
                    handleDelete={this.handleDelete}
                    handleOpenModal={this.handleOpenModal}
                    handleLoadMore={this.handleLoadMore}
                    handleSetSorting={this.handleSetSorting}
                    customSnakeLoaderStyles={customSnakeLoaderStyles}
                />
            </ListingLayout>
        )
    }

    renderTopLayout = () => {
        const { cx, currentClientGroup } = this.props
        const [name, discount, description] =
            currentClientGroup && currentClientGroup.data
                ? [currentClientGroup.data.name, currentClientGroup.data.discount, currentClientGroup.data.description]
                : ["", "", ""]

        return (
            <div className={cx("topLayout")}>
                <div className={cx("groupName")}>{name}</div>
                <div className={cx("bottonInfo")}>
                    <div>
                        <div className={cx("bottomTitle")}>Discount:</div>
                        <br />
                        {discount}%
                    </div>
                    <div>
                        <div className={cx("bottomTitle")}>Description:</div>
                        <br />
                        <div className={cx("description")}>{description}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(ListingClients, styles)
