import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import withShowScrollBar from "HOC/withShowScrollBar"

import styles from "./styles.module.css"
import Button from "ui/Button"
import Modal from "components/Modal/Modal"

class TransferConfirmModal extends Component {
    handleCloseModal = () => {
        const { handleHide, handleShowScrollBar } = this.props
        handleShowScrollBar()
        handleHide()
        document.removeEventListener("keydown", this.handleKeyboardArrows, false)
    }

    handleDelete = () => {
        const { handleAccept, handleImagePanel } = this.props
        handleAccept()
        handleImagePanel()
        this.handleCloseModal()
    }

    render() {
        const { cx, imageUrl } = this.props
        return (
            <Modal>
                <div className={cx("modal-background")}>
                    <div className={cx("modal-cover")}>
                        {imageUrl && <img src={imageUrl} alt="current" />}
                        <p>Are you sure you want to delete this image from product?</p>
                        <div className={cx("footer")}>
                            <Button className={["first-button"]} label="Delete" onClick={this.handleDelete} />
                            <span onClick={this.handleCloseModal}>Cancel</span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withShowScrollBar(withStyles(TransferConfirmModal, styles))
