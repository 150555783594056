import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import fetch from "helpers/fetch"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"

import Layout from "components/Reusable/Layout"
import { Dropdown, Toggle, SubmitModal } from "ui"
import {
    Header,
    SectionModalGallery,
    SectionModalImage,
    SectionModalImageWithDescription,
    SectionModalParallaxWithText,
    SectionModalImageWithProduct,
    SectionModalImageWithPins,
    SectionModalVideo,
    SectionModalProducts,
    SectionModalMatterport,
    DragAndDropList
} from "modules/ContentModule/components"

import styles from "./styles.css"
import sectionsLayoutStyles from "./overrides/sectionsLayoutStyles.css"
import dropdownStyles from "./overrides/dropdown.css"
import toggleStyles from "./overrides/toggle.css"

import folderIcon from "assets/ico-folder-new.svg"
import closeSvg from "assets/close-blue.svg"
import editSvg from "assets/pencil.svg"

const IMAGE_WITH_PINS_DIMENSION = {
    desktop: {
        width: 2500
    },
    tablet: {
        width: 1024
    },
    mobile: {
        width: 420
    }
}

const List = ({
    cx,
    setSections,
    sections,
    sectionableID,
    sectionableType,
    showSuccessNotification,
    showErrorNotification
}) => {
    const [currentSectionModal, setCurrentSectionModal] = useState({})
    const [deleteSectionID, setDeleteSectionID] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)

    const openSectionModal = data => {
        setCurrentSectionModal(data)
    }

    const saveSection = async data => {
        try {
            const sectionValues = {
                ...data,
                sectionable_id: sectionableID,
                sectionable_type: sectionableType
            }

            const saveSectionResult = data.id
                ? await fetch.patch(`/sections/${data.id}`, sectionValues)
                : await fetch.post("/sections", sectionValues)

            showSuccessNotification && showSuccessNotification()

            data.id
                ? setSections(sections.map(item => (data.id === item.id ? saveSectionResult.data : item)))
                : setSections([...sections, saveSectionResult.data])
        } catch (error) {
            showErrorNotification && showErrorNotification()

            throw Object.keys(error.errors || []).reduce((acc, field) => {
                acc[field] = error.errors[field][0]
                return acc
            }, {})
        }
    }

    const deleteSection = async () => {
        setIsDeleting(true)

        if (deleteSectionID === "all") {
            await Promise.all(sections.map(section => fetch.deleteRAW(`/sections/${section.id}`)))
            setSections([])
        } else {
            await fetch.deleteRAW(`/sections/${deleteSectionID}`)
            setSections(sections.filter(item => item.id !== deleteSectionID))
        }

        showSuccessNotification && showSuccessNotification()

        setDeleteSectionID(null)
        setIsDeleting(false)
    }

    const toggleSection = id =>
        setSections(
            sections.map(item =>
                item.id === id
                    ? {
                          ...item,
                          active: !item.active
                      }
                    : item
            )
        )

    const sectionTypes = [
        {
            label: "Image with product",
            type: "image_with_product"
        },
        {
            label: "Parallax",
            type: "image_with_paralax"
        },
        {
            label: "Image",
            type: "image_or_two_images"
        },
        {
            label: "Image with description",
            type: "image_with_description"
        },
        {
            label: "Video",
            type: "video"
        },
        {
            label: "Gallery",
            type: "gallery"
        },
        {
            label: "Products",
            type: "products_list"
        },
        {
            label: "Image with pins",
            type: "image_with_pins"
        },
        {
            label: "Matterport visualization",
            type: "matterport"
        }
    ]

    return (
        <Fragment>
            <Layout customStyles={sectionsLayoutStyles}>
                <Header
                    title="Sections"
                    customButton={
                        <Dropdown
                            label="Add New"
                            isListOnRight
                            isListOnTop={sections.length && sections.length < 3}
                            customStyles={dropdownStyles}
                            options={sectionTypes.map(item => ({
                                label: item.label,
                                onClick: () => openSectionModal({ type: item.type })
                            }))}
                        />
                    }
                />

                {sections.length > 0 ? (
                    <div className={cx("sectionList")}>
                        <div className={cx("listHeader")}>
                            <div className={cx("sectionsCount")}>{sections.length} sections added</div>
                            <button onClick={() => setDeleteSectionID("all")}>
                                <img src={closeSvg} alt="delete" />
                                Delete all
                            </button>
                        </div>
                        <DragAndDropList itemsList={sections} handleReorder={setSections}>
                            {sections.map(section => (
                                <div key={section.id} className={cx("listItem")}>
                                    <div
                                        className={cx("name", {
                                            nameDisabled: !section.active
                                        })}
                                    >
                                        {section.type === "image_or_two_images" && `Image (${section.settings.type})`}
                                        {section.type === "image_with_description" && `Image with description`}
                                        {section.type === "image_with_paralax" && "Parallax"}
                                        {section.type === "video" && "Video"}
                                        {section.type === "gallery" && "Gallery"}
                                        {section.type === "products_list" && "Products"}
                                        {section.type === "image_with_product" &&
                                            `Image with product (${section.settings.alignment})`}
                                        {section.type === "image_with_pins" && "Image with pins"}
                                        {section.type === "matterport" && "Matterport visualization"}
                                    </div>

                                    <div className={cx("toggle")}>
                                        <Toggle
                                            isOn={!!section.active}
                                            handleChange={() => toggleSection(section.id)}
                                            customStyles={toggleStyles}
                                        />
                                    </div>

                                    <button onClick={() => openSectionModal(section)} className={cx("edit")}>
                                        <img src={editSvg} alt="edit" /> Edit
                                    </button>

                                    <button onClick={() => setDeleteSectionID(section.id)} className={cx("delete")}>
                                        <img src={closeSvg} alt="delete" /> Delete
                                    </button>
                                </div>
                            ))}
                        </DragAndDropList>
                    </div>
                ) : (
                    <div className={cx("emptyListPlaceholder")}>
                        <img src={folderIcon} alt="placeholder" />
                        <p>No section added</p>
                    </div>
                )}
            </Layout>

            {!!currentSectionModal.type && renderSectionModal(currentSectionModal.type)}

            {deleteSectionID && (
                <SubmitModal
                    isOpen
                    redButton
                    withLine
                    header={`Do you want to delete ${deleteSectionID === "all" ? " all sections" : "section"}?`}
                    body="This action cannot be reversed."
                    submitText={`Delete ${deleteSectionID === "all" ? " All Sections" : "Section"}`}
                    isLoading={isDeleting}
                    onClose={() => setDeleteSectionID(null)}
                    onSubmit={deleteSection}
                />
            )}
        </Fragment>
    )

    function renderSectionModal(type) {
        switch (type) {
            case "image_with_product":
                return (
                    <SectionModalImageWithProduct
                        onClose={() => setCurrentSectionModal({})}
                        data={{ ...currentSectionModal, product_id: (currentSectionModal.product || {}).id }}
                        onSave={saveSection}
                    />
                )
            case "image_with_paralax":
                return (
                    <SectionModalParallaxWithText
                        onClose={() => setCurrentSectionModal({})}
                        data={currentSectionModal}
                        onSave={saveSection}
                    />
                )
            case "image_or_two_images":
                return (
                    <SectionModalImage
                        onClose={() => setCurrentSectionModal({})}
                        data={currentSectionModal}
                        onSave={saveSection}
                    />
                )
            case "image_with_description":
                return (
                    <SectionModalImageWithDescription
                        onClose={() => setCurrentSectionModal({})}
                        data={currentSectionModal}
                        onSave={saveSection}
                    />
                )
            case "video":
                return (
                    <SectionModalVideo
                        onClose={() => setCurrentSectionModal({})}
                        data={currentSectionModal}
                        onSave={saveSection}
                    />
                )
            case "gallery":
                return (
                    <SectionModalGallery
                        onClose={() => setCurrentSectionModal({})}
                        data={currentSectionModal}
                        onSave={saveSection}
                    />
                )
            case "products_list":
                return (
                    <SectionModalProducts
                        onClose={() => setCurrentSectionModal({})}
                        data={currentSectionModal}
                        onSave={saveSection}
                    />
                )
            case "image_with_pins":
                return (
                    <SectionModalImageWithPins
                        onClose={() => setCurrentSectionModal({})}
                        data={currentSectionModal}
                        onSave={saveSection}
                        imageDimensions={IMAGE_WITH_PINS_DIMENSION}
                    />
                )
            case "matterport":
                return (
                    <SectionModalMatterport
                        onClose={() => setCurrentSectionModal({})}
                        data={currentSectionModal}
                        onSave={saveSection}
                    />
                )
            default:
                return null
        }
    }
}

List.propTypes = {
    cx: PropTypes.func.isRequired,
    setSections: PropTypes.func.isRequired,
    sections: PropTypes.array.isRequired,
    showSuccessNotification: PropTypes.func.isRequired,
    showErrorNotification: PropTypes.func.isRequired,
    sectionableID: PropTypes.number.isRequired,
    sectionableType: PropTypes.string.isRequired
}

export default withUploader(withStyles(List, styles))
