import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import styles from "./Radio.css"

const Radio = ({ cx, className, name, label, value, checked, isHorizonal, handleSelect, isDisabled }) => (
    <label className={cx("root", className, { isHorizonal })}>
        <input
            name={name}
            type="radio"
            onChange={() => handleSelect(name, value)}
            checked={checked}
            disabled={isDisabled}
        />
        <div />
        <span>{label}</span>
    </label>
)

Radio.defaultProps = {
    className: "",
    label: "",
    isHorizonal: false
}

Radio.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    checked: PropTypes.bool.isRequired,
    isHorizonal: PropTypes.bool,
    handleSelect: PropTypes.func.isRequired
}

export default withStyles(Radio, styles)
