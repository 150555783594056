import { useEffect, useCallback } from "react"
import { useQueryClient } from "react-query"

import { useActions } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useShipmentEdit, KEY as SHIPMENT_QUERY_KEY } from "modules/WmsModule/hooks/api/useShipment"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"

const useShipmentStatusEdit = ({
    id,
    newStatus,
    setIsConfirmationModalOpen,
    isStockTransfer = false,
    onStatusChanged,
    onError
}) => {
    const queryKey = [SHIPMENT_QUERY_KEY, { id }]
    const queryClient = useQueryClient()
    const actions = useActions({ showSuccessNotification, showErrorNotification })

    useEffect(() => {
        if (newStatus && newStatus !== SHIPMENT_STATUS_KEYS.CANCELLED) {
            handleUpdateShipmentStatus()
        } else if (newStatus) {
            setIsConfirmationModalOpen(true)
        }
    }, [newStatus])

    const changeShipmentStatusInCache = useCallback(
        updatedStatus =>
            queryClient.setQueryData(queryKey, prevState => ({
                data: { ...prevState.data, status: updatedStatus }
            })),
        [queryClient, queryKey]
    )

    const shipmentEdit = useShipmentEdit({ skipInvalidateQueries: true })

    const handleUpdateShipmentStatus = useCallback(() => {
        shipmentEdit
            .mutate({
                id,
                data: { status: newStatus }
            })
            .then(newShipmentData => {
                changeShipmentStatusInCache(newShipmentData.data.status)
                if (onStatusChanged) {
                    onStatusChanged(id, newShipmentData.data)
                }
                actions.showSuccessNotification({
                    title: `${isStockTransfer ? "Stock transfer" : "Shipment"} status changed successfully`
                })
            })
            .catch(() => {
                if (onError) {
                    onError()
                }
                actions.showErrorNotification()
            })
    }, [id, newStatus, onStatusChanged, changeShipmentStatusInCache, actions])

    return {
        isStatusLoading: shipmentEdit.fetchStatus.isLoading,
        handleUpdateShipmentStatus
    }
}

export default useShipmentStatusEdit
