import { useMemo } from "react"
import { useQuery } from "react-query"

const INITIAL_META = { current_page: 0, last_page: 0, total: 0 }
const INITIAL_DATA = { data: [], meta: INITIAL_META }

const mapReactQueryFetchStatus = query => ({
    isLoading: query.isFetching,
    isLoaded: query.isFetched,
    isError: query.isError,
    lastFetch: query.dataUpdatedAt
})

const useQueryListFactory = ({ key, fetchFunction, defaultParams = {}, initialData = INITIAL_DATA }) => {
    return function useQueryList(props = {}) {
        const { reactQueryProps = {} } = props
        const params = { ...defaultParams, ...(props.params || {}) }
        const queryKey = useMemo(() => [key, params], [key, params])

        const query = useQuery(queryKey, () => fetchFunction(params), {
            initialData: initialData,
            refetchOnWindowFocus: false,
            ...reactQueryProps
        })

        const fetchStatus = useMemo(() => mapReactQueryFetchStatus(query), [
            query.isError,
            query.isFetching,
            query.isFetched,
            query.dataUpdatedAt
        ])

        return {
            meta: query.data.meta,
            data: query.data.data,
            refetch: query.refetch,
            fetchStatus,
            query,
            queryKey
        }
    }
}

export default useQueryListFactory
