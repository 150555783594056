import React, { Fragment, useCallback, useState } from "react"
import PropTypes from "prop-types"
import getStyles from "helpers/getCxFromStyles"
import { formatPriceByComa } from "helpers/units"
import { useActions } from "hooks"
import { showErrorNotification } from "actions/notification"
import styles from "./PriceElements.css"
import cardChargeFeeInputStyles from "./overrides/cardChargeFeeInput.css"
import { DEFAULT_CURRENCY } from "constants/index"
import { Input } from "ui"
import { Button, RefreshIcon } from "@butterfly-frontend/ui"

const cx = getStyles(styles)

const renderPriceSign = (value, isPositive) => (value < 0 ? "- " : isPositive ? "+ " : "")

const renderPrice = value => (
    <Fragment>
        {formatPriceByComa(value, true) + "."}
        <span className={cx("cents")}>{formatPriceByComa(value)}</span>
    </Fragment>
)

const PriceElements = ({ config, updateResource, id, cardChargeFee }) => {
    const actions = useActions({ updateResource, showErrorNotification })
    const [cardChargeFeeInputValue, setCardChargeFeeInputValue] = useState(cardChargeFee || 0)
    const [isCalculationsReloading, setIsCalculationsReloading] = useState(false)

    const onCardChargeFeeChange = useCallback(value => {
        actions
            .updateResource(id, { card_charge_fee: value })
            .catch(error => actions.showErrorNotification(error))
            .finally(() => {
                setIsCalculationsReloading(false)
                setCardChargeFeeInputValue(value)
            })
    }, [])

    const handleValueChanged = useCallback((key, value) => {
        setIsCalculationsReloading(true)
        switch (key) {
            case "card_charge_fee":
                onCardChargeFeeChange(value)
                break
            default:
                setIsCalculationsReloading(false)
        }
    }, [])

    const renderRow = ({
        label,
        value,
        isBig,
        isColor,
        isGreen,
        isGold,
        isPositive,
        inputKey,
        hasRefreshTaxButton,
        refreshTaxButtonProps,
        customValue
    }) => (
        <div
            key={label}
            className={cx("row", { isBig, isColor, isGreen, isGold, isInput: !!inputKey || !!hasRefreshTaxButton })}
        >
            <span className={cx("label")}>{label}:</span>
            {!!inputKey ? (
                <div className={cx("rowInputWrapper")}>
                    {cardChargeFeeInputValue !== value && (
                        <Button
                            classes={{ button: styles.submitCalcInputBtn }}
                            size="small"
                            color="blue"
                            onClick={() => handleValueChanged(inputKey, parseFloat(cardChargeFeeInputValue || "0"))}
                            isLoading={isCalculationsReloading}
                        >
                            Save
                        </Button>
                    )}
                    {inputKey === "card_charge_fee" && (
                        <Fragment>
                            <span className={cx(["percentageSign", "value"])}>%</span>
                            <Input
                                value={cardChargeFeeInputValue}
                                onChange={({ event }) => {
                                    let value = event.target.value
                                    if (value) {
                                        value = value > 100 ? 100 : value
                                        value = value < 0 ? 0 : value
                                    }
                                    setCardChargeFeeInputValue(value)
                                }}
                                customStyles={cardChargeFeeInputStyles}
                                type="number"
                            />
                        </Fragment>
                    )}
                </div>
            ) : (
                <span className={cx("value")}>
                    {!!hasRefreshTaxButton && refreshTaxButtonProps && (
                        <Button
                            Icon={RefreshIcon}
                            classes={{ button: styles.refreshButton }}
                            size="small"
                            onClick={refreshTaxButtonProps.onClick}
                            isLoading={refreshTaxButtonProps.isLoading}
                            disabled={refreshTaxButtonProps.disabled}
                        />
                    )}
                    {!!customValue ? (
                        customValue
                    ) : (
                        <Fragment>
                            {typeof value === "number" && (
                                <Fragment>
                                    {renderPriceSign(value, isPositive)}
                                    {`${DEFAULT_CURRENCY} `}
                                    {renderPrice(Math.abs(value))}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </span>
            )}
        </div>
    )

    return (
        <div className={cx("root")}>
            {config.map((section, index) => {
                return <section key={index}>{section.map(row => renderRow(row))}</section>
            })}
        </div>
    )
}

PriceElements.propTypes = {
    config: PropTypes.arrayOf(
        PropTypes.arrayOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number,
                isBig: PropTypes.bool,
                isColor: PropTypes.bool,
                hasRefreshTaxButton: PropTypes.bool,
                refreshTaxButtonProps: PropTypes.shape({
                    disabled: PropTypes.bool.isRequired,
                    isLoading: PropTypes.bool,
                    onClick: PropTypes.func.isRequired
                }),
                customValue: PropTypes.node
            })
        )
    )
}

export default PriceElements
