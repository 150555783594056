export const INIT_VALUES = {
    name: "",

    company_name: "",
    country: "United States of America",
    address: "",
    post_code: "",
    city: "",
    state: "",

    contact_person: "",
    phone: "",
    po_email: [""],
    accounting_email: [""],

    manufacturing_min: "",
    manufacturing_max: "",
    delivery_min: "",
    delivery_max: "",

    discounts: [],

    pricing_mu_usd_retail: "",
    pricing_mu_memo: "",
    pricing_structure_id: "",
    pricing_exchange_rate: "",
    pricing_note: "",

    payment_terms: "",

    bank_information: "",

    logo: "",
    type: "standard_vendor"
}

export const TABS = {
    GENERAL: "general",
    FINANCIALS: "financials"
}

export const FIELDS_IN_TABS = {
    name: TABS.GENERAL,

    company_name: TABS.GENERAL,
    country: TABS.GENERAL,
    address: TABS.GENERAL,
    post_code: TABS.GENERAL,
    city: TABS.GENERAL,
    state: TABS.GENERAL,

    contact_person: TABS.GENERAL,
    phone: TABS.GENERAL,
    po_emails: TABS.GENERAL,
    accounting_emails: TABS.GENERAL,

    manufacturing_min: TABS.GENERAL,
    manufacturing_max: TABS.GENERAL,
    delivery_min: TABS.GENERAL,
    delivery_max: TABS.GENERAL,

    discounts: TABS.FINANCIALS,

    pricing_mu_usd_retail: TABS.FINANCIALS,
    pricing_mu_memo: TABS.FINANCIALS,
    pricing_structure_id: TABS.FINANCIALS,
    pricing_exchange_rate: TABS.FINANCIALS,
    pricing_note: TABS.FINANCIALS,

    payment_terms: TABS.FINANCIALS,

    bank_information: TABS.FINANCIALS
}
