import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./header.css"

const Header = props => {
    const { cx, className, children } = props

    return <h2 className={cx("root", className)}>{children}</h2>
}

Header.defaultProps = {
    className: ""
}

Header.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
}

export default withStyles(Header, styles)
