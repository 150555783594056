import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import ReactSvg from "react-svg"
import { Note, DeleteModal, AddEditModal } from "ui/Notes"

import fetch from "helpers/fetch"

import { getProject } from "actions/projects"
import { showErrorNotification, showSuccessNotification } from "actions/notification"

import withStyles from "HOC/withStyles"
import styles from "./clientCommunication.css"

import commentIcon from "assets/comment.svg"

const ClientCommunication = props => {
    const { cx, notes, projectItemId } = props

    const addEditModalInitData = { isOpen: false, noteId: null }

    const project = useSelector(state => state.db.projects.current.current.data)
    const dispatch = useDispatch()

    const [isDeleteModalOpenFor, setIsDeleteModalOpenFor] = useState(null)
    const [addEditModalData, setAddEditModalData] = useState(addEditModalInitData)

    const [isAddingOrEditing, setIsAddingOrEditing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const closeDeleteModal = () => setIsDeleteModalOpenFor(null)
    const handleDeleteComment = () => deleteComment(isDeleteModalOpenFor)

    const closeAddEditModal = () => setAddEditModalData(addEditModalInitData)
    const openAddModal = () => setAddEditModalData({ isOpen: true, noteId: null })
    const openEditModal = noteId => setAddEditModalData({ isOpen: true, noteId })
    const handleAddEditComment = value => addEditComment({ noteId: addEditModalData.noteId, value })

    const deleteComment = commentId => {
        setIsDeleting(true)
        fetch
            .deleteRAW(`/notes/${commentId}`)
            .then(() => {
                dispatch(showSuccessNotification())
                dispatch(getProject(project.id))
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                closeDeleteModal()
                setIsDeleting(false)
            })
    }

    const addEditComment = commentData => {
        setIsAddingOrEditing(true)

        const { noteId } = addEditModalData
        const isEdit = !!noteId

        const [fetchMethod, url] = isEdit ? ["patch", `/${noteId}`] : ["post", ""]

        const body = {
            text: commentData.value,
            object_type: "projectItem",
            object_id: projectItemId
        }

        fetch[fetchMethod](`/notes${url}`, body)
            .then(() => {
                dispatch(showSuccessNotification())
                dispatch(getProject(project.id))
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                setIsAddingOrEditing(false)
                closeAddEditModal()
            })
    }

    const editedNote = Array.isArray(notes) && notes.find(note => note.id === addEditModalData.noteId)
    const text = !!editedNote ? editedNote.content : ""

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <div>
                    <h3>Client communication</h3>
                    <span>(Visible to client)</span>
                </div>
                <button onClick={openAddModal}>
                    <ReactSvg src={commentIcon} />
                    Leave a comment
                </button>
            </div>
            <ul>
                {Array.isArray(notes) && !!notes.length ? (
                    notes.map(note => (
                        <Note key={note.id} editNote={openEditModal} deleteNote={setIsDeleteModalOpenFor} {...note} />
                    ))
                ) : (
                    <div className={cx("emptyComments")}>Comments list is empty.</div>
                )}
            </ul>
            <DeleteModal
                isOpen={!!isDeleteModalOpenFor}
                isLoading={isDeleting}
                onClose={closeDeleteModal}
                onSubmit={handleDeleteComment}
            />
            <AddEditModal
                isEdit={!!addEditModalData.noteId}
                isOpen={addEditModalData.isOpen}
                isLoading={isAddingOrEditing}
                type={"comment"}
                text={text}
                onClose={closeAddEditModal}
                onSubmit={handleAddEditComment}
            />
        </div>
    )
}

export default withStyles(ClientCommunication, styles)
