import React, { Fragment } from "react"
import PropTypes from "prop-types"

import ReactSvg from "react-svg"
import Button from "ui/Button"
import { CreateSalesOrderButton, MultiSelectActions } from "../components"

import withStyles from "HOC/withStyles"
import styles from "./bottomBar.css"

import peopleIcon from "assets/people.svg"

const BottomBar = props => {
    const { cx, isMultiSelect, checkedItemsCount, sharePeopleCount, handleAction, toggleShareModalIsOpen } = props

    const onDelete = () => handleAction("delete")
    const onAddToRoom = () => handleAction("addToRoom")
    const onCopyToRoom = () => handleAction("copyToRoom")
    const onAddToSalesOrder = () => handleAction("addToSalesOrder")

    return (
        <div className={cx("root")}>
            <div className={cx("leftColumn")}>
                {isMultiSelect ? (
                    `Selected ${checkedItemsCount} items`
                ) : (
                    <Fragment>
                        <Button
                            className={`first-button ${cx("shareButton")}`}
                            label="Share"
                            onClick={toggleShareModalIsOpen}
                        />
                        <ReactSvg src={peopleIcon} />
                        Shared for {sharePeopleCount} people
                    </Fragment>
                )}
            </div>

            {isMultiSelect ? (
                <MultiSelectActions
                    onDelete={onDelete}
                    onAddToRoom={onAddToRoom}
                    onCopyToRoom={onCopyToRoom}
                    onAddToSalesOrder={onAddToSalesOrder}
                />
            ) : (
                <CreateSalesOrderButton
                    isDisabled={true}
                    className={cx("createSalesOrderButton")}
                    onClick={onAddToSalesOrder}
                />
            )}
        </div>
    )
}

BottomBar.propTypes = {
    isMultiSelect: PropTypes.bool.isRequired,
    checkedItemsCount: PropTypes.number.isRequired,
    sharePeopleCount: PropTypes.number.isRequired,
    handleAction: PropTypes.func.isRequired,
    toggleShareModalIsOpen: PropTypes.func.isRequired
}

export default withStyles(BottomBar, styles)
