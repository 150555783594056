import { useMemo, useCallback } from "react"
import { useInfiniteQuery } from "react-query"

const INITIAL_META = { current_page: 0, last_page: 0, total: 0 }
const INITIAL_PAGE = { data: [], meta: INITIAL_META }
const INITIAL_DATA = { pages: [INITIAL_PAGE] }

const getNextPageParam = lastFetchedPage =>
    lastFetchedPage.meta.current_page < lastFetchedPage.meta.last_page
        ? lastFetchedPage.meta.current_page + 1
        : undefined

const mapPageToPagination = ({ meta }) => ({
    current_page: meta.current_page,
    last_page: meta.last_page
})

const getLastPage = (pages = []) => (pages.length > 0 ? pages[pages.length - 1] : INITIAL_PAGE)

const useSelectData = ({ key, params, setValue, selectOptionsMapper, fetchSelectData, infiniteQueryOptions }) => {
    const selectDataQuery = useInfiniteQuery([key, params], fetchSelectData, {
        getNextPageParam,
        initialData: INITIAL_DATA,
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        ...infiniteQueryOptions
    })

    const {
        data = { pages: [] },
        isFetching,
        isFetchingNextPage,
        fetchNextPage,
        error,
        remove: clearOptions
    } = selectDataQuery

    const fetchValues = useCallback(() => (isFetching || isFetchingNextPage ? undefined : fetchNextPage()), [
        fetchNextPage,
        isFetching,
        isFetchingNextPage
    ])

    const pagination = useMemo(() => mapPageToPagination(getLastPage(data.pages)), [data.pages])

    const values = useMemo(
        () =>
            data.pages.reduce(
                (currentValues, currentPage) => [...currentValues, ...currentPage.data.map(selectOptionsMapper)],
                []
            ),
        [data.pages]
    )

    return {
        values,
        fetchValues,
        isLoading: isFetching,
        error,
        pagination,
        clearOptions,
        selectDataQuery,
        propsForSelectWithSearch: {
            values,
            fetchValues,
            setValue,
            pagination
        }
    }
}

export default useSelectData
