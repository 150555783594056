import React, { Component } from "react"
import { connect } from "react-redux"
import { Route, Switch } from "react-router-dom"
import isEqual from "lodash/isEqual"
import * as clientActions from "actions/client"
import * as noteActions from "actions/nextNote"
import * as notificationActions from "actions/notification"
import * as searchBar from "actions/searchBar"

import { Edit, Overview, List, AwaitingList } from "./pages"
import withDebounce from "HOC/withDebounce"
import withPermissions from "HOC/withPermissions"
import { MINIMAL_SEARCH_WORD_LENGTH, PERMISSIONS, SHOWN_CLIENTS_COUNT } from "constants/index"
import { scrollToTop } from "helpers/scrollTo"
import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"

class Clients extends Component {
    sortKeys = [
        { type: "first_name", title: "Contact Name" },
        { title: "Company" },
        { title: "Email" },
        { title: "Phone" },
        { type: "created_at", title: "Added" },
        { type: "updated_at", title: "Last Updated" }
    ]

    state = {
        params: {
            sort_by: this.sortKeys[0].type,
            sort_direction: "asc",
            page: 1
        },
        autoSelectMyClients: false,
        isLoaded: false,
        isSpreadsheetModalOpen: false,
        isSpreadsheetLoading: false,
        spreadsheetData: {},
        isSpreadsheetSaving: false,
        isExporting: false
    }

    componentDidMount() {
        const { getClients } = this.props
        getClients({ length: SHOWN_CLIENTS_COUNT, params: { ...this.state.params } })
        scrollToTop()
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchBarFilters } = this.props

        if (
            !isEqual(prevProps.clientsLis, this.props.clientsList) &&
            (!this.state.isLoaded && this.props.clientsList.isLoaded)
        ) {
            this.setState({
                isLoaded: true
            })
        }

        if (prevState.autoSelectMyClients) {
            this.setState({ autoSelectMyClients: false })
        }

        if (prevState.isSpreadsheetModalOpen && !this.state.isSpreadsheetModalOpen) {
            const { query } = searchBarFilters
            const { params } = this.state
            this.filterClients({ query, params })
        }

        const hasPathChangedToClients =
            this.props.location.pathname === "/contact" && prevProps.location.pathname !== "/contact"

        if (hasPathChangedToClients) {
            this.props.getClients({
                length: SHOWN_CLIENTS_COUNT,
                params: { ...this.state.params },
                query: searchBarFilters.query
            })
        }
    }

    componentWillUnmount() {
        this.props.setFilters({ params: {}, query: "" })
    }

    handleSearch = ({ query = "", params = {}, isFromFilter = false }) => {
        const { setFilters, searchBarFilters } = this.props
        const { params: stateParams } = this.state
        const { query: prevQuery } = searchBarFilters
        const nextParams = {
            page: 1,
            sort_by: stateParams.sort_by,
            sort_direction: stateParams.sort_direction,
            ...params
        }

        let isCleared = prevQuery.length > query.length
        this.setState(
            {
                params: nextParams
            },
            () => {
                setFilters({ params: nextParams, query })
                if (query.length >= MINIMAL_SEARCH_WORD_LENGTH || isCleared || isFromFilter) {
                    return this.filterClients({ query, params: nextParams })
                }
            }
        )
    }

    handleSort = key => {
        const { query } = this.props.searchBarFilters
        this.setState(
            prevState => {
                const isNewKey = prevState.params.sort_by !== key
                return {
                    params: {
                        ...prevState.params,
                        sort_by: key,
                        sort_direction: isNewKey ? "asc" : prevState.params.sort_direction === "asc" ? "desc" : "asc",
                        page: query ? 0 : 1
                    }
                }
            },
            () => {
                this.filterClients({
                    params: this.state.params,
                    query,
                    length: this.state.params.page * SHOWN_CLIENTS_COUNT
                })
            }
        )
    }

    handleLoadMoreClients = () => {
        const { searchBarFilters, clientsList, handleDebounce, getClients } = this.props
        const { query } = searchBarFilters
        const { params } = this.state

        if (!clientsList.nextFetchStatus.isLoading && clientsList.meta.current_page < clientsList.meta.last_page) {
            handleDebounce(() => {
                getClients({
                    query,
                    length: SHOWN_CLIENTS_COUNT,
                    params: {
                        ...params,
                        page: clientsList.meta.current_page + 1
                    }
                })
            })
        }
    }

    filterClients = ({ query, params, length = SHOWN_CLIENTS_COUNT }) => {
        const { handleDebounce, getClients } = this.props
        const lengthValue = length > 0 ? length : SHOWN_CLIENTS_COUNT
        handleDebounce(() => getClients({ query, params, length: lengthValue }))
    }

    handleExport = value => {
        const { params } = this.state
        const { query } = this.props.searchBarFilters
        const exportType = value.event.target.value

        this.setState({ isExporting: true })

        const iframe = document.querySelector("iframe[data-pdf], a[data-pdf]")
        iframe && iframe.remove()

        clientActions
            .getClientsRAW({ query, params: { ...params, export: exportType } })
            .then(response => response.blob())
            .then(blob => {
                const newBlob = new Blob([blob], { type: "application/pdf" })

                let iFrame = document.createElement("iframe")
                iFrame.name = "pdfIframe"
                iFrame.setAttribute("data-pdf", true)
                iFrame.style = "visibility: hidden;"
                iFrame.src = URL.createObjectURL(newBlob)
                document.body.appendChild(iFrame)

                let a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.setAttribute("data-pdf", true)
                a.href = iFrame.src
                a.download = "clients." + exportType
                a.click()
            })
            .finally(() => {
                this.setState({ isExporting: false })
            })
    }

    clearInput = () => {
        const { setFilters } = this.props
        const { params } = this.state

        this.setState({ query: "" }, () => {
            setFilters({ query: "", params: { ...params, page: 0 } })
            this.filterClients({ query: "", params })
        })
    }

    deleteNote = ({ noteId, contextId }) => {
        this.props.deleteNote({
            noteId,
            successAction: clientActions.getClientById(contextId)
        })
    }

    getSpreadsheetData = () => {
        const { params } = this.state

        this.setState({ isSpreadsheetLoading: true })
        fetch
            .post(
                `/reviews?query=${this.props.searchBarFilters.query}&object_type=clients${
                    params && Object.keys(params).length > 0 ? "&" + objToQueryString(params) : ""
                }`
            )
            .then(({ data }) =>
                this.setState({ isSpreadsheetLoading: false, isSpreadsheetModalOpen: true, spreadsheetData: data })
            )
            .catch(() => {
                this.setState({ isSpreadsheetLoading: false })
                this.props.showErrorNotification()
            })
    }

    saveSpreadsheet = () => {
        this.setState({ isSpreadsheetSaving: true })
        fetch
            .patchRAW(`/reviews/${this.state.spreadsheetData.id}`)
            .then(() => {
                this.handleCloseSpreadsheetModal()
                this.props.showSuccessNotification()
            })
            .catch(({ errors }) => {
                this.setState(prevState => ({
                    spreadsheetData: { ...prevState.spreadsheetData, errorsCount: errors.errorsCount[0] }
                }))
            })
            .finally(() => this.setState({ isSpreadsheetSaving: false }))
    }

    handleCloseSpreadsheetModal = () =>
        this.setState({ isSpreadsheetModalOpen: false, isSpreadsheetLoading: false, spreadsheetData: {} })

    render() {
        const {
            clientsList,
            db,
            searchBarFilters,
            clientStore,
            getClientById,
            canView,
            canList,
            canEdit,
            canAdd
        } = this.props
        const {
            params,
            isSpreadsheetLoading,
            isSpreadsheetModalOpen,
            spreadsheetData,
            isSpreadsheetSaving,
            autoSelectMyClients
        } = this.state
        const { sort_by, sort_direction } = params
        const { query } = searchBarFilters

        return (
            <Switch>
                {canAdd() && (
                    <Route
                        path={"/contact/add"}
                        render={() => <Edit canView={canView} PERMISSIONS={PERMISSIONS} isNewProfile={true} />}
                    />
                )}
                {canEdit() && (
                    <Route
                        path={"/contact/:clientId/profile/edit"}
                        render={() => (
                            <Edit
                                isEdit={true}
                                isNewProfile={false}
                                deleteNote={this.deleteNote}
                                canView={canView}
                                PERMISSIONS={PERMISSIONS}
                            />
                        )}
                    />
                )}
                {canView() && (
                    <Route
                        path={"/contact/:clientId/profile/:sectionName?"}
                        render={props => (
                            <Overview
                                {...props}
                                getClientById={getClientById}
                                client={clientStore.client}
                                clientTypesList={db.clientTypes.list.data}
                                deleteNote={this.deleteNote}
                            />
                        )}
                    />
                )}
                {canList() && <Route exact path="/contact/awaiting-approval" component={AwaitingList} />}
                {canList() && (
                    <Route
                        path={"/contact"}
                        render={() => (
                            <List
                                autoSelectMyClients={autoSelectMyClients}
                                handleSearch={this.handleSearch}
                                handleSort={this.handleSort}
                                handleLoadMoreClients={this.handleLoadMoreClients}
                                handleExport={this.handleExport}
                                isExporting={this.state.isExporting}
                                sortDirection={sort_direction}
                                sortBy={sort_by}
                                isLoaded={this.state.isLoaded}
                                currentPage={clientsList.meta.current_page}
                                lastPage={clientsList.meta.last_page}
                                clearInput={this.clearInput}
                                query={query}
                                params={params}
                                total={clientsList.meta.total}
                                clientsCount={clientsList.meta.client_count}
                                clientsList={clientsList}
                                getClientById={getClientById}
                                isSpreadsheetLoading={isSpreadsheetLoading}
                                isSpreadsheetModalOpen={isSpreadsheetModalOpen}
                                openSpreadsheetModal={this.getSpreadsheetData}
                                spreadsheetData={spreadsheetData}
                                closeSpreadsheetModal={this.handleCloseSpreadsheetModal}
                                saveSpreadsheet={this.saveSpreadsheet}
                                isSpreadsheetSaving={isSpreadsheetSaving}
                                canAdd={canAdd}
                                sortKeys={this.sortKeys}
                            />
                        )}
                    />
                )}
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        clientsList: state.clientStore.clients,
        client: state.clientStore.client,
        users: state.userStore.users,
        searchBarFilters: state.ui.searchBar,
        permissions: state.loginStore.permissions,
        currentUserId: parseInt(state.loginStore.userId, 10)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getClients: params => dispatch(clientActions.getClients(params)),
        getClientById: id => dispatch(clientActions.getClientById(id)),
        deleteNote: ({ noteId, successAction }) => dispatch(noteActions.deleteNote({ noteId, successAction })),
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification()),
        setFilters: payload => dispatch(searchBar.setFilters(payload))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withDebounce(withPermissions(Clients, PERMISSIONS.context.CLIENTS)))
