import React, { Component } from "react"

import Layout from "components/Reusable/Layout/index"

class ListingLayout extends Component {
    render() {
        const { children, isBlog, isPortfolio } = this.props

        let title = "News"
        if (isBlog) {
            title = "Blog"
        }
        if (isPortfolio) {
            title = "Portfolio"
        }

        return (
            <Layout {...this.props} title={title}>
                {children}
            </Layout>
        )
    }
}

export default ListingLayout
