import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"

export const checkIfCanGenerateConsignmentNote = shipment => {
    const isShipmentToClient = shipment.type === SHIPMENT_TYPE_KEYS.SHIPMENT && !!shipment.order

    return shipment.type === SHIPMENT_TYPE_KEYS.TRANSFER || isShipmentToClient
}

export const getShipmentsAvailableForConsignmentNote = shipments =>
    shipments.filter(checkIfCanGenerateConsignmentNote).map(({ id }) => id)
