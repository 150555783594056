import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import inputEmailStyles from "./overrides/InputEmail.css"
import styles from "./Receiver.module.css"
import closeBlueIcon from "assets/close-blue.svg"
import { Input } from "ui"

const Receiver = ({ cx, error, errors, index, isTouched, receivers, setFieldTouched, setFieldValue, value }) => {
    const handleDelete = index => {
        setFieldTouched(`receivers["${index}"].email`, false)
        setFieldValue("receivers", [...receivers.filter((_, _index) => _index !== index)])
    }

    const handleChange = value => setFieldValue(`receivers[${index}].email`, value.event.target.value)

    return (
        <div className={cx("inputWrapper")}>
            <Input
                customStyles={inputEmailStyles}
                error={isTouched && (error || errors["receivers." + index + ".email"])}
                isPlaceholder={true}
                label="E-mail address"
                name={"receivers[" + index + "]"}
                onChange={value => handleChange(value)}
                value={value.email}
            />
            {!!index && (
                <div className={cx("removeEmail")} onClick={() => handleDelete(index)}>
                    <img alt="remove" src={closeBlueIcon} />
                    Delete
                </div>
            )}
        </div>
    )
}

Receiver.defaultProps = {
    error: "",
    errors: {},
    isError: false,
    isTouched: false,
    receivers: []
}

Receiver.propTypes = {
    error: PropTypes.string,
    errors: PropTypes.object,
    index: PropTypes.number.isRequired,
    isTouched: PropTypes.bool,
    receivers: PropTypes.array,
    setFieldTouched: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired
}

export default withStyles(Receiver, styles)
