import React, { useState } from "react"
import { Formik, Form as FormikForm, FieldArray } from "formik"
import PropTypes from "prop-types"
import merge from "lodash/merge"

import SectionModalGallery from "./SectionModalGallery"
import {
    getInitialValues as getProductLinkInitialValues,
    getSaveValues as getProductLinkSaveValues
} from "../ShowProductLink/values"
import { section as sectionSchema } from "./schema"

const Container = ({ onClose, onSave, data }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleUploadSuccess = (arrayHelper, files) => {
        files.forEach(({ id, title, url }) => {
            arrayHelper.push({ id, name: title, url })
        })
    }

    const initialValues = merge(data, { gallery: data.gallery || [] }, getProductLinkInitialValues(data))

    const onSubmit = async (values, formikActions) => {
        try {
            setIsSubmitting(true)

            await onSave(
                merge(values, { gallery: values.gallery.map(item => item.id) }, getProductLinkSaveValues(values))
            )

            onClose()
        } catch (error) {
            setIsSubmitting(false)
            formikActions.setErrors(error)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={sectionSchema}
            enableReinitialize
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {propsForm => (
                <FormikForm>
                    <FieldArray name="gallery">
                        {arrayHelpers => (
                            <SectionModalGallery
                                onClose={onClose}
                                images={propsForm.values.gallery}
                                form={propsForm}
                                handleUploadSuccess={files => handleUploadSuccess(arrayHelpers, files)}
                                handleRemove={index => arrayHelpers.remove(index)}
                                handleReorder={images => propsForm.setFieldValue("gallery", images)}
                                isSubmitting={isSubmitting}
                            />
                        )}
                    </FieldArray>
                </FormikForm>
            )}
        </Formik>
    )
}

Container.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default Container
