import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import withStyles from "HOC/withStyles"
import fetch from "helpers/fetch"
import * as notificationActions from "actions/notification"

import schema from "./schema"

import Settings from "./Settings"

import styles from "./Settings.css"

const SettingsContainer = ({ showErrorNotification, showSuccessNotification }) => {
    const [calendars, setCalendars] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        getCalendars()
    }, [])

    const getCalendars = async () => {
        setIsLoading(true)

        const calendars = await fetch.get(
            `/calendars?length=9999&withNotVisible=true&is_system=true&withDaysOff=true&withClientMeeting=true&sort_by=title&sort_direction=asc`
        )
        setCalendars(calendars.data)

        setIsLoading(false)
    }

    const handleSubmit = async (values, formikActions) => {
        setIsSubmitting(true)

        try {
            await schema.validate(values, { abortEarly: false })

            const changedCalendars = values.calendars.filter(calendar => {
                return calendars.find(
                    item =>
                        item.id === calendar.id &&
                        (item.title !== calendar.title ||
                            item.font_color !== calendar.font_color ||
                            item.color !== calendar.color ||
                            item.is_visible !== calendar.is_visible)
                )
            })

            await Promise.all(
                changedCalendars.map(
                    async calendar =>
                        await fetch.patch(`/calendars/${calendar.id}`, {
                            title: calendar.title,
                            color: calendar.color,
                            font_color: calendar.font_color,
                            is_visible: calendar.is_visible
                        })
                )
            )

            getCalendars()

            showSuccessNotification({ title: "System events were successfully saved" })
        } catch (error) {
            const formErrors =
                error.name === "ValidationError"
                    ? (error.inner || []).reduce(
                          (acc, item) => ({
                              ...acc,
                              [item.path]: item.message
                          }),
                          {}
                      )
                    : Object.keys(error.errors || []).reduce(
                          (acc, field) => ({
                              ...acc,
                              [field]: error.errors[field][0]
                          }),
                          {}
                      )

            showErrorNotification()
            formikActions.setErrors(formErrors)
        }

        setIsSubmitting(false)
    }

    return (
        <Settings isLoading={isLoading} isSubmitting={isSubmitting} calendars={calendars} handleSubmit={handleSubmit} />
    )
}

const mapDispatchToProps = dispatch => {
    return {
        showErrorNotification: data => dispatch(notificationActions.showErrorNotification(data)),
        showSuccessNotification: data => dispatch(notificationActions.showSuccessNotification(data))
    }
}

export default connect(
    null,
    mapDispatchToProps
)(withStyles(SettingsContainer, styles))
