import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"

export function getServicesStart() {
    return {
        type: constants.GET_SERVICES_START
    }
}
export function getServicesSuccess(data) {
    return {
        type: constants.GET_SERVICES_SUCCESS,
        payload: {
            data
        }
    }
}
export function getServicesError(error) {
    return {
        type: constants.GET_SERVICES_ERROR,
        payload: {
            error
        }
    }
}
export function getServices() {
    return dispatch => {
        dispatch(getServicesStart())

        fetch
            .get(`/services?length=${LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getServicesSuccess(data)))
            .catch(error => {
                dispatch(getServicesError(error))
            })
    }
}

function getServiceStart() {
    return {
        type: constants.GET_SERVICE_START
    }
}
function getServiceSuccess(data) {
    return {
        type: constants.GET_SERVICE_SUCCESS,
        payload: {
            data
        }
    }
}
function getServiceError(error) {
    return {
        type: constants.GET_SERVICE_ERROR,
        payload: {
            error
        }
    }
}
export function createService(name) {
    return dispatch => {
        fetch
            .post(`/services`, { name })
            .then(data => dispatch(getServiceById(data[data.length - 1].id)))
            .catch(error => dispatch(getServiceError(error)))
    }
}

export function editService(id, name) {
    return dispatch => {
        fetch
            .patch(`/services/${id}`, { name })
            .then(() => dispatch(getServiceById(id)))
            .catch(error => dispatch(getServiceError(error)))
    }
}

export function getServiceById(id) {
    return dispatch => {
        dispatch(getServiceStart())

        fetch
            .get(`/services/${id}`)
            .then(data => dispatch(getServiceSuccess(data)))
            .catch(error => dispatch(getServiceError(error)))
    }
}

export function deleteServiceStart() {
    return {
        type: constants.DELETE_SERVICE_START
    }
}

export function deleteServiceError(error) {
    return {
        type: constants.DELETE_SERVICE_ERROR,
        payload: {
            error
        }
    }
}

export function deleteServiceSuccess(id) {
    return {
        type: constants.DELETE_SERVICE_SUCCESS,
        payload: id
    }
}

export function deleteService(id) {
    return dispatch => {
        dispatch(deleteServiceStart())

        fetch
            .deleteRAW(`/services/${id}`)
            .then(() => dispatch(deleteServiceSuccess(id)))
            .catch(error => dispatch(deleteServiceError(error)))
    }
}
