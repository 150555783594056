import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { Field, FieldArray } from "formik"

import { Input, DatePickerWithInput, Textarea, SelectWithSearch } from "ui"
import withStyles from "HOC/withStyles"
import withMultipleUploader from "HOC/withMultipleUploader"

import FilesUploader from "components/Reusable/FilesUploader"
import SelectClient from "../SelectClient"

import styles from "./styles.css"
import inputStyles from "../../overrides/input.css"
import datepickerStyles from "../../overrides/datepicker.css"
import textareaStyles from "../../overrides/textarea.css"

const GeneralInfoSection = props => {
    const { cx, states, countries, form, handleUploadSuccess, handleUploadStart, handleSelectClient } = props

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <h2 className={cx("header")}>Project Details</h2>
                <ul className={cx("projectDetails")}>
                    <li className={cx("projectName")}>
                        <Field name="name">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Project name"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.touched[field.name] && form.errors[field.name]}
                                    customStyles={inputStyles}
                                    attributes={{ required: true }}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("client")}>
                        <Field name="client">
                            {({ field, form }) => (
                                <SelectClient
                                    form={form}
                                    field={field}
                                    name={field.name}
                                    value={field.value}
                                    handleSelect={handleSelectClient}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("squareFootage")}>
                        <Field name="footage">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    type="number"
                                    label="Square footage"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                    innerLabel={
                                        <span>
                                            ft<sup>2</sup>
                                        </span>
                                    }
                                    isInnerLabelRight
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("orderDeadline")}>
                        <Field name="deadline_at">
                            {({ field }) => (
                                <DatePickerWithInput
                                    date={field.value}
                                    minDate={new Date()}
                                    label="Order deadline:"
                                    handleSelectDate={date =>
                                        field.onChange({ target: { name: field.name, value: date } })
                                    }
                                    customStyles={datepickerStyles}
                                    disableClear
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("installationDate")}>
                        <Field name="installation_at">
                            {({ field }) => (
                                <DatePickerWithInput
                                    date={field.value}
                                    minDate={new Date()}
                                    label="Installation date:"
                                    handleSelectDate={date =>
                                        field.onChange({ target: { name: field.name, value: date } })
                                    }
                                    customStyles={datepickerStyles}
                                    disableClear
                                />
                            )}
                        </Field>
                    </li>
                </ul>
            </div>
            <div className={cx("section")}>
                <h2 className={cx("header")}>Project Address</h2>
                <ul className={cx("projectAddress")}>
                    <li className={cx("companyName")}>
                        <Field name="address.company_name">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Company name"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("country")}>
                        <Field name="address.country">
                            {({ field }) => (
                                <SelectWithSearch
                                    {...field}
                                    label="Country"
                                    values={countries.map(country => ({ label: country, value: country }))}
                                    setValue={({ value }) => {
                                        if (value !== field.value) {
                                            form.setFieldValue("address.state", "")
                                            field.onChange({ target: { name: field.name, value } })
                                        }
                                    }}
                                    withoutFetch
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("address")}>
                        <Field name="address.address">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Address (Street and No.)"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("zipCode")}>
                        <Field name="address.post_code">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="ZIP Code"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("city")}>
                        <Field name="address.city">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="City"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("state")}>
                        <Field name="address.state">
                            {({ field }) =>
                                form.values.address && form.values.address.country === "United States of America" ? (
                                    <SelectWithSearch
                                        {...field}
                                        label="State"
                                        values={states.map(states => ({ label: states, value: states }))}
                                        setValue={({ value }) =>
                                            field.onChange({ target: { name: field.name, value } })
                                        }
                                        placeholder="- Please select -"
                                        withoutFetch
                                        isListOnTop
                                    />
                                ) : (
                                    <Input
                                        {...field}
                                        label="State"
                                        onChange={({ event }) => field.onChange(event)}
                                        error={form.errors[field.name]}
                                        customStyles={inputStyles}
                                    />
                                )
                            }
                        </Field>
                    </li>
                </ul>
            </div>
            <div className={cx("section")}>
                <h2 className={cx("header")}>Description</h2>
                <Field name="description">
                    {({ field }) => (
                        <Textarea
                            value={field.value || ""}
                            name={field.name}
                            label="Click here to add a description..."
                            onChange={({ event }) => field.onChange(event)}
                            error={form.errors[field.name]}
                            isPlaceholder
                            customStyles={textareaStyles}
                        />
                    )}
                </Field>

                <FieldArray name="attachments">
                    {arrayHelpers => (
                        <FilesUploader
                            handleRemove={index => arrayHelpers.remove(index)}
                            attachments={form.values.attachments}
                            handleUploadFile={handleUploadStart}
                            handleUploadSuccess={addedFiles => handleUploadSuccess(arrayHelpers, addedFiles)}
                        />
                    )}
                </FieldArray>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        states: state.clientStore.statesList,
        countries: state.clientStore.countriesList
    }
}

export default compose(
    withMultipleUploader,
    connect(
        mapStateToProps,
        null
    )
)(withStyles(GeneralInfoSection, styles))
