import { useMemo } from "react"
import { useLocation } from "react-router-dom"
import qs from "qs"
import _get from "lodash/get"

const useNumericValueFromQueryString = ({ key }) => {
    const routerLocation = useLocation()

    return useMemo(() => {
        const values = qs.parse(routerLocation.search, { ignoreQueryPrefix: true })
        const parsedValue = parseInt(_get(values, key), 10)

        return Number.isNaN(parsedValue) ? undefined : parsedValue
    }, [routerLocation.search])
}

export default useNumericValueFromQueryString
