import * as Yup from "yup"

import { requiredSelectOptionFactory } from "modules/WmsModule/helpers/schema"
import { IMAGES_SCHEMA, REQUIRED_IMAGES_SCHEMA } from "modules/WmsModule/constants/schema"

const conditionOptionSchema = Yup.object().shape({
    id: Yup.number()
})

const conditionImagesSchema = IMAGES_SCHEMA.when("condition.value.require_images", {
    is: true,
    then: REQUIRED_IMAGES_SCHEMA
})

export default Yup.object().shape({
    condition: requiredSelectOptionFactory(conditionOptionSchema),
    conditionImages: conditionImagesSchema
})
