import React from "react"
import PropTypes from "prop-types"

import { Button } from "@butterfly-frontend/ui"
import useReturnButton from "modules/OrdersModule/hooks/useReturnButton"

const ReturnButton = ({ id, uuid, allOrderItems, selectedOrderItemsIds }) => {
    const returnButtonProps = useReturnButton({
        id,
        uuid,
        allOrderItems,
        selectedOrderItemsIds
    })

    return <Button {...returnButtonProps}>Return</Button>
}

ReturnButton.propTypes = {
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    allOrderItems: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    selectedOrderItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
}

export default ReturnButton
