import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import Input from "components/Reusable/Form/Input/index"
import Button from "components/Reusable/Form/Button/index"
import styles from "./ResetPassword.css"

class ResetPassword extends Component {
    render() {
        const { isSaving, formFields, cx, handleSubmitAction } = this.props
        return (
            <form>
                <div className={cx("input-field")}>
                    {this.renderInput({
                        field: formFields.password,
                        className: "input"
                    })}
                </div>

                <div className={cx("input-field")}>
                    {this.renderInput({
                        field: formFields.repeat_password,
                        className: "input"
                    })}
                </div>

                <Button
                    className={["second-button-big", "button"]}
                    customStyles={styles}
                    onClick={handleSubmitAction}
                    label="Done"
                    isLoading={isSaving}
                />
                <div className="clear" />
            </form>
        )
    }

    renderInput({ field, className }) {
        const { handleFieldChange, handleSetPasswordInState } = this.props
        return (
            <Input
                {...field}
                customStyles={styles}
                className={className}
                isPlaceholder={true}
                onChange={params => {
                    handleSetPasswordInState(params)
                    handleFieldChange(params)
                }}
            />
        )
    }
}

export default withStyles(ResetPassword, styles)
