import React, { Fragment, useMemo, useState } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"
import { useLocation } from "react-router-dom"

import {
    Table,
    H1,
    Panel,
    EmptyList,
    TableHead,
    TableHeadCell,
    useTableSort,
    TableSortableHeadCell,
    TableBody,
    TableRowWithExpandedDetails,
    TableCell,
    Status,
    TableDateCell,
    GridIcon,
    AlertErrorIcon,
    TableCellWithExpandButton,
    useTableFilterBarSearch,
    useTableFilterBarMultiselect,
    TableFilterBar,
    TableFilterBarSearch,
    TableFilterBarSelect,
    useTableFilterBarMoreFilters,
    TableFilterBarMoreFiltersButton,
    TableFilterBarMoreFilters,
    TableFilterBarMoreFiltersGrid,
    TableFilterBarMoreFiltersGridItem,
    TableFilterBarDatepicker,
    useTableFilterBarDatepicker
} from "@butterfly-frontend/ui"

import { Dropdown, Pagination } from "ui"
import { SnakeLoader } from "ui/Skeleton"
import { SelectClient, SelectWarehouse } from "ui/Filters"
import { formatDate, toApiDate } from "helpers/date"
import { Header } from "modules/WmsModule/components"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import { useShipmentList } from "modules/WmsModule/hooks/api/useShipment"
import CLIENT_RETURN_SHIPMENT_STATUSES from "modules/WmsModule/constants/clientReturnShipmentStatuses"
import { checkIfShipmentIsCancelled } from "modules/WmsModule/helpers/shipment"
import CLIENT_RETURN_SHIPMENT_STATUS_FILTER_OPTIONS from "modules/WmsModule/constants/clientReturnShipmentStatusFilterOptions"
import DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT from "modules/OrdersModule/constants/dropShipSelectOptionOfDestinationPoint"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import SalesOrderReturnDetails from "modules/OrdersModule/components/SalesOrderReturnDetails"
import useChangeShipmentStatusOnListCache from "modules/WmsModule/hooks/cache/useChangeShipmentStatusOnListCache"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import TABLE_COLUMNS from "./constants/tableColumns"
import styles from "./SalesOrderReturnListPage.module.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.css"

const SalesOrderReturnListPage = ({ canEdit }) => {
    const { search } = useLocation()
    const [expandedRowId, setExpandedRowId] = useState(null)
    const { sortState, onChangeSort } = useTableSort({ initialState: { direction: "desc", by: "created_at" } })

    const filterBarSearchProps = useTableFilterBarSearch({
        initialValue: new URLSearchParams(search).get("query")
    })
    const statusFilterBarMultiselectProps = useTableFilterBarMultiselect({
        initialOptions: CLIENT_RETURN_SHIPMENT_STATUS_FILTER_OPTIONS
    })

    const dateRangeFilterBarMultiselectProps = useTableFilterBarDatepicker()

    const selectedStatuses = statusFilterBarMultiselectProps.selectedValues
    const querySearch = filterBarSearchProps.debouncedValue

    const {
        moreFiltersButtonProps,
        moreFiltersComponentProps,
        ...filterBarMoreFiltersProps
    } = useTableFilterBarMoreFilters({
        emptyFilterValues: {
            origin: undefined,
            destination: undefined,
            client: undefined
        },
        canClear: true
    })

    const filters = useMemo(() => {
        const date = dateRangeFilterBarMultiselectProps.values
        const { origin: originFilter, client, destination } = filterBarMoreFiltersProps.savedFilterValues
        const mapToCommaSeparatedIdsString = array => array.map(({ id }) => id).join(",")

        const origin = get(originFilter, "value", undefined)
        const isOriginDropType = origin && origin.type === DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT.value.type

        return {
            query: querySearch,
            statuses: mapToCommaSeparatedIdsString(selectedStatuses),
            destination_warehouses: get(destination, "id", undefined),
            source_warehouses: !isOriginDropType ? origin : undefined,
            dropship: isOriginDropType || undefined,
            clients: get(client, "id", undefined),
            type: SHIPMENT_TYPE_KEYS.CLIENT_RETURN,
            ...(date
                ? {
                      created_from: toApiDate(date.start),
                      created_to: toApiDate(date.end)
                  }
                : {})
        }
    }, [
        selectedStatuses,
        querySearch,
        dateRangeFilterBarMultiselectProps.values,
        filterBarMoreFiltersProps.savedFilterValues
    ])

    const { data, fetchStatus, fetchFlags, query, pagination } = useTableLogic({
        filters,
        sort: sortState,
        useQueryList: useShipmentList
    })

    const changeShipmentStatusOnListCache = useChangeShipmentStatusOnListCache({ queryKey: query.queryKey })

    return (
        <Fragment>
            <Header>
                <H1 withLeftBorder>Returns</H1>
                <Dropdown label="Export all" className={styles.exportAllDropdown} options={[]} />
            </Header>
            <Panel>
                <TableFilterBar
                    moreFiltersComponent={
                        <TableFilterBarMoreFilters {...moreFiltersComponentProps}>
                            <TableFilterBarMoreFiltersGrid>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectWarehouse
                                        setValue={value => {
                                            filterBarMoreFiltersProps.onChange("origin", value)
                                        }}
                                        value={get(
                                            filterBarMoreFiltersProps.selectedFilterValues.origin,
                                            "label",
                                            null
                                        )}
                                        label="Origin"
                                        placeholder="All"
                                        warehouseType="active"
                                        additionalValues={[DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT]}
                                        customStyles={selectWithSearchStyles}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectClient
                                        placeholder="All"
                                        customStyles={selectWithSearchStyles}
                                        label="Client"
                                        name="client"
                                        value={filterBarMoreFiltersProps.selectedFilterValues.client}
                                        handleSelect={value => filterBarMoreFiltersProps.onChange("client", value)}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectWarehouse
                                        setValue={value => filterBarMoreFiltersProps.onChange("destination", value)}
                                        value={get(
                                            filterBarMoreFiltersProps.selectedFilterValues.destination,
                                            "label",
                                            null
                                        )}
                                        label="To"
                                        placeholder="All"
                                        warehouseType="active"
                                        customStyles={selectWithSearchStyles}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                            </TableFilterBarMoreFiltersGrid>
                        </TableFilterBarMoreFilters>
                    }
                >
                    <TableFilterBarSearch placeholder="Search..." {...filterBarSearchProps} />
                    <TableFilterBarSelect
                        labelSelectAll="All statuses"
                        placeholder="Status"
                        {...statusFilterBarMultiselectProps.componentProps}
                    />
                    <TableFilterBarDatepicker
                        labelSelectAll="All range"
                        placeholder="Date"
                        {...dateRangeFilterBarMultiselectProps}
                    />
                    <TableFilterBarMoreFiltersButton {...moreFiltersButtonProps} />
                </TableFilterBar>
                <Table classes={{ root: styles.table }}>
                    <TableHead>
                        <TableSortableHeadCell
                            {...TABLE_COLUMNS.id}
                            sortState={sortState}
                            columnKey="id"
                            onClick={onChangeSort}
                        >
                            ID
                        </TableSortableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.origin}>ORIGIN</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.client}>CLIENT</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.shipTo}>SHIP TO</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.status}>STATUS</TableHeadCell>
                        <TableSortableHeadCell
                            {...TABLE_COLUMNS.createdAt}
                            sortState={sortState}
                            columnKey="created_at"
                            onClick={onChangeSort}
                        >
                            CREATED
                        </TableSortableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.detailsButton} />
                    </TableHead>
                    <TableBody>
                        {fetchStatus.isLoading && <SnakeLoader />}
                        {fetchFlags.isEmptyList && <EmptyList Icon={GridIcon} label="List is empty." />}
                        {fetchFlags.isEmptyListWithFilter && (
                            <EmptyList Icon={GridIcon} label="No records found for selected filters." />
                        )}
                        {fetchStatus.isLoaded &&
                            data.map(returnItem => {
                                const status = CLIENT_RETURN_SHIPMENT_STATUSES[returnItem.status.status]
                                const isStatusCancelled = checkIfShipmentIsCancelled({
                                    shipmentStatus: status.value,
                                    isClientShipment: true
                                })

                                const rowClasses = { root: { [styles.inactiveRow]: isStatusCancelled } }
                                const cellClasses = {
                                    root: [styles.cell, { [styles.inactiveCell]: isStatusCancelled }]
                                }
                                const idCellClasses = {
                                    root: [styles.idCell, { [styles.alertCell]: isStatusCancelled }]
                                }

                                return (
                                    <TableRowWithExpandedDetails
                                        key={returnItem.id}
                                        isExpanded={expandedRowId === returnItem.id}
                                        classes={rowClasses}
                                        detailsComponent={
                                            <SalesOrderReturnDetails
                                                shipmentId={returnItem.id}
                                                onStatusChanged={changeShipmentStatusOnListCache}
                                                isEditingStatusEnabled={canEdit()}
                                            />
                                        }
                                    >
                                        <TableCell {...TABLE_COLUMNS.id} classes={idCellClasses}>
                                            {isStatusCancelled && <AlertErrorIcon className={styles.alertErrorIcon} />}
                                            {returnItem.uuid}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.origin} classes={cellClasses}>
                                            {returnItem.origins}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.client} classes={cellClasses}>
                                            {returnItem.client.full_name}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.shipTo} classes={cellClasses}>
                                            {returnItem.destination_warehouse.name}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.status} classes={cellClasses}>
                                            <Status theme={status.theme} color={status.color}>
                                                {status.label}
                                            </Status>
                                        </TableCell>
                                        <TableDateCell
                                            {...TABLE_COLUMNS.createdAt}
                                            classes={{
                                                root: [styles.dateCell, { [styles.inactiveCell]: isStatusCancelled }]
                                            }}
                                        >
                                            {formatDate(returnItem.created_at)}
                                        </TableDateCell>
                                        <TableCellWithExpandButton
                                            isExpanded={expandedRowId === returnItem.id}
                                            classes={cellClasses}
                                            onClick={() =>
                                                setExpandedRowId(prevState =>
                                                    prevState !== returnItem.id ? returnItem.id : null
                                                )
                                            }
                                        />
                                    </TableRowWithExpandedDetails>
                                )
                            })}
                    </TableBody>
                    {pagination.canDisplay && (
                        <div className={styles.paginationWrapper}>
                            <Pagination {...pagination} />
                        </div>
                    )}
                </Table>
            </Panel>
        </Fragment>
    )
}

SalesOrderReturnListPage.propTypes = {
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(SalesOrderReturnListPage, PERMISSIONS.context.ORDER_SHIPMENTS)
