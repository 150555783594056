import React from "react"
import { SnakeLoader, KanbanEmptyColumn } from "@butterfly-frontend/ui"

const KanbanColumnFetchStatus = ({ fetchStatus, isEmpty, accessDenied = false, children }) => {
    if (accessDenied) {
        return <KanbanEmptyColumn message="You don't have an access to this resource." />
    }

    if (fetchStatus.isLoading) {
        return <SnakeLoader />
    }

    if (fetchStatus.isLoaded && isEmpty) {
        return <KanbanEmptyColumn />
    }

    return children
}

export default KanbanColumnFetchStatus
