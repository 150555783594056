import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"

import { showErrorNotification } from "actions/notification"
import fetch from "helpers/fetch"

import MergeDataModal from "./MergeDataModal"

const initialValues = {
    first_name: "new",
    last_name: "new",
    company: "new",
    phone: "new",
    types: "new"
}

const MergeDataModalContainer = props => {
    const { isVerifying, verifyData, onClose, onSuccess } = props

    const dispatch = useDispatch()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const onSubmit = values => {
        const { partnerId, data } = verifyData

        setIsSubmitting(true)
        const body = Object.entries(values).reduce((acc, [key, value]) => ({ ...acc, [key]: data[value][key] }), {})

        fetch
            .patch(`/partners/${partnerId}`, body)
            .then(() => onSuccess(partnerId))
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => setIsSubmitting(false))
    }

    const formik = useFormik({ initialValues, onSubmit })
    const { values, handleSubmit, handleChange, setValues } = formik

    const handleSelect = (name, value) => {
        if (name === "all") {
            const allValues = Object.keys(values).reduce((acc, key) => ({ ...acc, [key]: value }), {})
            return setValues(allValues)
        }
        handleChange({ target: { name, value } })
    }

    const formattedData = useMemo(() => {
        const { data } = verifyData
        return Object.entries(data.new).reduce((acc, [key, newData]) => {
            const oldData = data.old[key]
            return { ...acc, [key]: { new: newData, old: oldData } }
        }, {})
    }, [verifyData.data])

    return (
        <MergeDataModal
            isSubmitting={isSubmitting || isVerifying}
            data={formattedData}
            values={values}
            handleSelect={handleSelect}
            onClose={onClose}
            onSubmit={handleSubmit}
        />
    )
}

const dataPropTypes = PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    phone: PropTypes.string,
    company: PropTypes.string,
    types: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        })
    )
})

MergeDataModalContainer.propTypes = {
    isVerifying: PropTypes.bool.isRequired,
    verifyData: PropTypes.shape({
        partnerId: PropTypes.number.isRequired,
        data: PropTypes.shape({
            new: dataPropTypes,
            old: dataPropTypes
        }).isRequired
    }),
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default MergeDataModalContainer
