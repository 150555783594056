import React, { Fragment } from "react"
import { Field, Formik } from "formik"

import env from "env"
import withStyles from "HOC/withStyles"
import { formatPrice } from "helpers/units"
import { createProduct } from "actions/product"

import { Input, Textarea, Quantity, Button, VendorSelect, PhotoUploader } from "ui"

import customProductSchema from "./schema"

import styles from "./AddManualEntry.css"
import fieldsStyles from "./overrides/fieldsStyles.css"
import PhotoUploaderStyles from "./overrides/PhotoUploaderStyles.css"

const API = env.API

const AddManualEntry = props => {
    const { cx, onClose, onSuccess, tax_code, discount, selectedBrand } = props

    const onSubmit = async (values, formikActions) => {
        const transformedValues = {
            fake_name: values.name,
            price: values.price.replace(",", "."),
            name: values.name,
            brand_id: values.vendor ? values.vendor.id : undefined,
            images: values.image ? [values.image.id] : undefined,
            short_note: values.note,
            manual_entry: true
        }

        try {
            const newManualEntry = await createProduct(transformedValues)

            await onSuccess({
                id: newManualEntry.data.id,
                note: values.note,
                quantity: values.quantity,
                discount: values.discount,
                tax_code:
                    Number(
                        values.tax_code
                            .toString()
                            .replace(",", ".")
                            .replace("-", "") || 0
                    ) / 100 || null,
                manual_entry: true
            })

            onClose()
        } catch (error) {
            formikActions.setErrors(
                (error.inner || []).reduce((acc, item) => {
                    acc[item.path] = item.message
                    return acc
                }, {})
            )
        }
    }

    const calculateTotal = ({ price, quantity, discount, tax_code }) => {
        return (
            (Number(price || 0) *
                Number(quantity || 0) *
                (100 - Number(discount || 0)) *
                (1 + Number(tax_code || 0) / 100)) /
            100
        )
    }

    const initialValues = {
        image: null,
        name: "",
        vendor: selectedBrand || null,
        note: "",
        price: "",
        quantity: 1,
        discount: discount || 0,
        tax_code: tax_code || ""
    }

    return (
        <Formik initialValues={initialValues} validationSchema={customProductSchema} onSubmit={onSubmit}>
            {({ handleSubmit, values, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
                const handleChangeField = async (name, value) => {
                    await setFieldValue(name, value)
                    setFieldTouched(name, true)
                }

                return (
                    <form className={cx("form")} onSubmit={handleSubmit}>
                        <div className={cx("row")}>
                            <div className={cx("left-side")}>
                                <div className={cx(["column", "image-wrapper"])}>
                                    <Field name="image">
                                        {({ field }) => {
                                            return (
                                                <Fragment>
                                                    <label>Image:</label>
                                                    <PhotoUploader
                                                        customStyles={PhotoUploaderStyles}
                                                        avatar={values.image && values.image.url}
                                                        endpoint={`${API.baseUrl}/media`}
                                                        handleRemovePhoto={() => setFieldValue(field.name, null)}
                                                        handleUploadSuccess={state =>
                                                            handleChangeField(field.name, state.addedFile)
                                                        }
                                                    />
                                                </Fragment>
                                            )
                                        }}
                                    </Field>
                                </div>

                                <div className={cx(["column", "information-wrapper"])}>
                                    <Field name="name">
                                        {({ field, meta: { touched } }) => {
                                            return (
                                                <Input
                                                    value={values[field.name] || ""}
                                                    onChange={value =>
                                                        handleChangeField(field.name, value.event.target.value)
                                                    }
                                                    attributes={{ required: true }}
                                                    label="Product name"
                                                    className={cx("name")}
                                                    error={touched && errors[field.name]}
                                                    customStyles={fieldsStyles}
                                                />
                                            )
                                        }}
                                    </Field>

                                    <Field name="vendor">
                                        {({ field }) => (
                                            <div className={cx("vendor")}>
                                                <VendorSelect
                                                    name={field.name}
                                                    value={(field.value || {}).name}
                                                    label="Vendor"
                                                    setValue={value => handleChangeField(field.name, value)}
                                                    isClearBlocked={false}
                                                    isDisabled={!!selectedBrand}
                                                    isRequired
                                                />
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="note">
                                        {({ field, meta: { touched } }) => {
                                            return (
                                                <Textarea
                                                    value={values[field.name]}
                                                    name={field.name}
                                                    type={"text"}
                                                    onChange={value =>
                                                        handleChangeField(field.name, value.event.target.value)
                                                    }
                                                    label="Note"
                                                    placeholder="Click here to add a note..."
                                                    className={cx("note")}
                                                    error={touched && errors[field.name]}
                                                    customStyles={fieldsStyles}
                                                />
                                            )
                                        }}
                                    </Field>
                                </div>
                            </div>

                            <div className={cx("right-side")}>
                                <div>
                                    <div className={cx(["column", "item-price-column"])}>
                                        <Field name="price">
                                            {({ field, meta: { touched } }) => {
                                                return (
                                                    <Input
                                                        type="number"
                                                        value={values[field.name] || ""}
                                                        onChange={value =>
                                                            handleChangeField(field.name, value.event.target.value)
                                                        }
                                                        label="Item price"
                                                        className={cx("price")}
                                                        error={touched && errors[field.name]}
                                                        customStyles={fieldsStyles}
                                                        innerLabel={"$"}
                                                        isInnerLabelLeft={true}
                                                        attributes={{ required: true }}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>

                                    <div className={cx(["column", "item-qty-column", "quantity"])}>
                                        <Field name="quantity">
                                            {({ field, meta: { touched } }) => {
                                                return (
                                                    <Fragment>
                                                        <label>Qty:</label>
                                                        <Quantity
                                                            value={values[field.name]}
                                                            min={1}
                                                            onChange={value => handleChangeField(field.name, value)}
                                                            error={touched && errors[field.name]}
                                                            customStyles={fieldsStyles}
                                                        />
                                                    </Fragment>
                                                )
                                            }}
                                        </Field>
                                    </div>

                                    <div className={cx(["column", "item-discount-column"])}>
                                        <Field name="discount">
                                            {({ field, meta: { touched } }) => {
                                                return (
                                                    <Input
                                                        type="number"
                                                        value={values[field.name]}
                                                        onChange={value =>
                                                            handleChangeField(field.name, value.event.target.value)
                                                        }
                                                        label="Discount"
                                                        className={cx("discount")}
                                                        error={touched && errors[field.name]}
                                                        customPlaceholder={"0"}
                                                        customStyles={fieldsStyles}
                                                        innerLabel={"%"}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>

                                    <div className={cx(["column", "item-total"])}>
                                        <label>Item total:</label>
                                        {formatPrice(calculateTotal(values))}
                                    </div>
                                </div>
                                <div className={cx("form-buttons")}>
                                    <button type="button" className={cx("cancelButton")} onClick={onClose}>
                                        Cancel
                                    </button>
                                    <Button
                                        className={`first-button ${cx("first-button")}`}
                                        label="Add entry"
                                        isLoading={isSubmitting}
                                        isDisabled={!values.name || !values.price || !values.quantity || !values.vendor}
                                        type="submit"
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

export default withStyles(AddManualEntry, styles)
