import * as constants from "../constants"

export function MenuReducer(state = {}, action) {
    switch (action.type) {
        case constants.TOGGLE_MENU:
            return { ...state, isSidebarMenuWide: !state.isSidebarMenuWide }

        default:
            return state
    }
}
