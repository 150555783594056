import React, { forwardRef } from "react"

import InfiniteScroll from "ui/InfiniteScroll"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import withStyles from "HOC/withStyles"
import styles from "./List.css"
import { shortenString } from "helpers/string"

const Loader = ({ cx }) => (
    <div className={cx("loader")}>
        <SnakeLoader />
    </div>
)

const List = forwardRef((props, ref) => {
    const {
        cx,
        isValue,
        value,
        children,
        hasMorePages,
        loadMore,
        withoutFetch,
        additionalButtonIcon,
        additionalButtonLabel,
        valueMaxLength,
        appendValueToBtnLabel,
        disableBtnForEmptyValue,
        disabledBtn,
        additionalButtonAction,
        isListOnTop,
        extraInput
    } = props

    return (
        <div
            className={cx("root", {
                isListOnTop,
                isValue,
                isExtraInput: !!extraInput,
                scrollWithNoButton: !additionalButtonAction && isListOnTop
            })}
        >
            {extraInput}
            <div className={cx("scrollWrapper")} ref={ref}>
                {withoutFetch ? (
                    <ul>{children}</ul>
                ) : (
                    <InfiniteScroll
                        hasMore={hasMorePages}
                        loader={<Loader key={"loader"} cx={cx} />}
                        loadMore={loadMore}
                        pageStart={1}
                        threshold={200}
                        useWindow={false}
                        element="ul"
                    >
                        {children}
                    </InfiniteScroll>
                )}
            </div>
            {additionalButtonAction && (
                <button
                    className={cx("additionalButton")}
                    disabled={(disableBtnForEmptyValue && !value) || disabledBtn}
                    type="button"
                    onClick={additionalButtonAction}
                >
                    <img src={additionalButtonIcon} alt="" />
                    {additionalButtonLabel}{" "}
                    {!!appendValueToBtnLabel && (valueMaxLength ? shortenString(value, valueMaxLength) : value)}
                </button>
            )}
        </div>
    )
})

export default withStyles(List, styles)
