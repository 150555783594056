import chart from "./icons/chart.svg"
import calendar from "./icons/ico-calendar.svg"
import user from "./icons/user.svg"
import news from "./icons/news.svg"
import orders from "./icons/order.svg"
import folder from "./icons/folder.svg"
import upload from "./icons/upload.svg"
import apps from "./icons/apps.svg"
import support from "./icons/support.svg"
import content from "./icons/content.svg"
import projects from "./icons/projects.svg"
import store from "./icons/store.svg"
import warehouse from "./icons/warehouse.svg"

import maintenance from "./icons/maintenance.svg"
import { MENU, PERMISSIONS } from "constants/index"

export default [
    {
        name: MENU.DASHBOARD,
        icon: chart,
        seeMore: false,
        permissionContext: ""
    },
    {
        name: MENU.CALENDAR,
        icon: calendar,
        seeMore: false,
        permissionContext: ""
    },
    {
        name: MENU.NEWS,
        icon: news,
        seeMore: false,
        permissionContext: PERMISSIONS.context.NEWS
    },
    {
        name: MENU.ORDERS,
        icon: orders,
        seeMore: true,
        path: "/orders",
        children: [
            {
                name: MENU.ORDERS_SALES_ORDERS,
                icon: null,
                seeMore: false,
                path: "/orders/sales-orders",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.ORDERS_INVOICES,
                icon: null,
                seeMore: false,
                path: "/orders/invoices",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.ORDERS_PURCHASE_ORDERS,
                icon: null,
                seeMore: false,
                path: "/orders/purchase-orders",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.ORDERS_RETURNS,
                icon: null,
                seeMore: false,
                path: "/orders/returns",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"],
                hasBorder: true
            },
            {
                name: MENU.ORDERS_MEMO,
                icon: null,
                seeMore: false,
                path: "/orders/memo",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.ORDERS_RENTAL_AGREEMENTS,
                icon: null,
                seeMore: false,
                path: "/orders/rental-agreements",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"],
                hasBorder: true
            },
            {
                name: MENU.ORDERS_PAYMENTS,
                icon: null,
                seeMore: false,
                path: "/orders/payments",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.ORDERS_STORE_CREDITS,
                icon: null,
                seeMore: false,
                path: "/orders/store-credits",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.ORDERS_SERVICES,
                icon: null,
                seeMore: false,
                path: "/orders/services",
                permissionContext: PERMISSIONS.context.PRODUCTS,
                permissions: ["canList", "canEdit"]
            }
        ]
    },
    {
        name: MENU.CLIENTS,
        icon: user,
        seeMore: true,
        permissionContext: PERMISSIONS.context.CLIENTS,
        children: [
            {
                name: MENU.CLIENTS_ALL,
                icon: null,
                seeMore: false,
                path: "/contact",
                permissionContext: PERMISSIONS.context.CLIENTS,
                permissions: ["canList", "canEdit"],
                exceptions: ["/contact/awaiting-approval"]
            },
            {
                name: MENU.CLIENTS_AWAITING,
                icon: null,
                seeMore: false,
                path: "/contact/awaiting-approval",
                permissionContext: PERMISSIONS.context.CLIENTS,
                permissions: ["canList", "canEdit"]
            }
        ]
    },
    {
        name: MENU.PRODUCTS,
        customName: "Catalog",
        icon: folder,
        seeMore: true,
        permissionContext: PERMISSIONS.context.PRODUCTS,
        permissions: ["canList", "canEdit"],
        children: [
            {
                name: MENU.PRODUCTS,
                icon: null,
                seeMore: false,
                path: "/products",
                permissionContext: PERMISSIONS.context.PRODUCTS,
                permissions: ["canList", "canEdit"],
                exceptions: ["/products/selections", "/products/manual-entries"]
            },
            {
                name: MENU.PRODUCTS_MANUAL_ENTRIES,
                icon: null,
                seeMore: false,
                path: "/products/manual-entries",
                permissionContext: PERMISSIONS.context.PRODUCTS,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.PRODUCTS_SELECTIONS,
                icon: null,
                seeMore: false,
                path: "/products/selections",
                permissionContext: PERMISSIONS.context.PRODUCTS,
                permissions: ["canList", "canEdit"]
            }
        ]
    },
    {
        id: "projects",
        name: MENU.PROJECTS,
        icon: projects,
        seeMore: false,
        permissionContext: ""
    },
    {
        id: "wms",
        customName: "Stock Control",
        name: MENU.WMS,
        icon: warehouse,
        seeMore: true,
        permissionContext: PERMISSIONS.context.WMS,
        permissions: ["canAccess"],
        children: [
            {
                name: MENU.WMS_WAREHOUSES,
                icon: null,
                seeMore: false,
                path: "/wms/warehouses",
                permissionContext: PERMISSIONS.context.WMS,
                permissions: ["canList"]
            },
            {
                name: MENU.WMS_STOCK_MANAGEMENT,
                icon: null,
                seeMore: false,
                path: "/wms/stock-management",
                permissionContext: PERMISSIONS.context.WMS,
                permissions: ["canAccess"]
            },
            {
                name: MENU.WMS_STOCK_TRANSFERS,
                icon: null,
                seeMore: false,
                path: "/wms/stock-transfers",
                permissionContext: PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS,
                permissions: ["canAccess"]
            },
            {
                name: MENU.WMS_STOCK_ADJUSTMENTS,
                icon: null,
                seeMore: false,
                path: "/wms/stock-adjustments",
                permissionContext: PERMISSIONS.context.WMS,
                permissions: ["canAccess"]
            },
            {
                name: MENU.WMS_RECEIVING,
                icon: null,
                seeMore: false,
                path: "/wms/receivings",
                permissionContext: PERMISSIONS.context.WMS,
                permissions: ["canAccess"]
            },
            {
                name: MENU.WMS_SHIPMENTS,
                icon: null,
                seeMore: false,
                path: "/wms/shipments",
                permissionContext: PERMISSIONS.context.WMS,
                permissions: ["canAccess"]
            },
            {
                name: MENU.WMS_LOGISTICS,
                icon: null,
                seeMore: false,
                path: "/wms/logistics",
                permissionContext: PERMISSIONS.context.WMS,
                permissions: ["canAccess"]
            }
        ]
    },
    {
        name: MENU.ONLINE_STORE,
        customName: "Online Store",
        icon: store,
        seeMore: true,
        permissionContext: PERMISSIONS.context.PRODUCTS,
        permissions: ["canList", "canEdit"],
        children: [
            {
                name: MENU.MANAGE_BRANDS,
                icon: null,
                seeMore: false,
                path: "/online-store/brands",
                permissionContext: PERMISSIONS.context.PRODUCTS,
                permissions: ["canList", "canEdit"]
            }
        ]
    },
    {
        name: MENU.CONTENT,
        icon: content,
        seeMore: true,
        path: "/content",
        children: [
            {
                name: MENU.CONTENT_MAIN_PAGE,
                icon: null,
                seeMore: false,
                path: "/content/main-page/image-with-pins",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.CONTENT_INSPIRATIONS,
                icon: null,
                seeMore: false,
                path: "/content/inspirations",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.CONTENT_NEWS,
                icon: null,
                seeMore: false,
                path: "/content/news",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.CONTENT_TRADE,
                icon: null,
                seeMore: false,
                path: "/content/trades",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.CONTENT_NAVIGATION,
                icon: null,
                seeMore: false,
                path: "/content/navigation",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.CONTENT_PORTFOLIO,
                icon: null,
                seeMore: false,
                path: "/content/portfolio",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.CONTENT_PRESS,
                icon: null,
                seeMore: false,
                path: "/content/press",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.CONTENT_WEB_EDITOR,
                icon: null,
                seeMore: false,
                type: MENU.BUTTON,
                permissionContext: PERMISSIONS.context.WEB_EDITOR,
                permissions: []
            }
        ]
    },
    {
        name: MENU.SETTINGS,
        icon: maintenance,
        seeMore: true,
        path: "/settings",
        children: [
            {
                name: MENU.SETTINGS_ORDERS,
                icon: null,
                seeMore: false,
                path: "/settings/orders",
                permissionContext: PERMISSIONS.context.CONTENT,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.SETTINGS_PRODUCTS,
                icon: null,
                seeMore: false,
                path: "/settings/products",
                permissionContext: PERMISSIONS.context.PRODUCTS,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.SETTINGS_USERS_MANAGEMENT,
                icon: null,
                seeMore: false,
                path: "/settings/users",
                permissionContext: PERMISSIONS.context.USERS,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.SETTINGS_OTHER,
                icon: null,
                seeMore: false,
                path: "/settings/other",
                permissionContext: PERMISSIONS.context.USERS,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.SYSTEM_EVENTS,
                icon: null,
                seeMore: false,
                path: "/settings/system-events",
                permissionContext: PERMISSIONS.context.USERS,
                permissions: ["canList", "canEdit"]
            }
        ]
    },
    {
        name: "line1",
        type: MENU.LINE
    },
    {
        name: MENU.UPLOAD,
        icon: upload,
        seeMore: true,
        path: "/upload",
        globalPermissionContext: PERMISSIONS.context.UPLOAD,
        children: [
            {
                name: MENU.PRODUCTS,
                icon: null,
                seeMore: false,
                path: "/upload/products",
                permissionContext: PERMISSIONS.context.PRODUCTS,
                permissions: ["canList", "canEdit"]
            },
            {
                name: MENU.CLIENTS,
                icon: null,
                seeMore: false,
                path: "/upload/contact",
                permissionContext: PERMISSIONS.context.CLIENTS,
                permissions: ["canList", "canEdit"]
            }
        ]
    },
    {
        name: MENU.APPS,
        icon: apps,
        path: "/apps",
        permissionContext: ""
    },
    {
        name: MENU.SUPPORT,
        icon: support,
        seeMore: false,
        customClassName: "nav-tab-support",
        isExternal: true,
        path: "https://mindz-it.atlassian.net/servicedesk/customer/portals/",
        isNewWindow: true,
        globalPermissionContext: ""
    }
]
