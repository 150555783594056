import PropTypes from "prop-types"

const ADDRESS_PROP_TYPE = PropTypes.shape({
    address: PropTypes.string,
    company: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    post_code: PropTypes.string,
    state: PropTypes.string
})

export default ADDRESS_PROP_TYPE
