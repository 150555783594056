import React, { Fragment } from "react"
import PropTypes from "prop-types"

import SubmitModal from "ui/SubmitModal"

const DeleteModal = props => {
    const { isOpen, isLoading, onClose, onSubmit, projectName } = props

    return (
        <SubmitModal
            isOpen={isOpen}
            isLoading={isLoading}
            redButton
            header="Delete project?"
            body={
                <Fragment>
                    Are you sure you want to delete project <strong>{projectName}</strong>?
                </Fragment>
            }
            submitText="Delete"
            onClose={onClose}
            onSubmit={onSubmit}
        />
    )
}

DeleteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    projectName: PropTypes.string.isRequired
}

export default DeleteModal
