import * as Yup from "yup"

import { DESCRIPTION_SCHEMA } from "modules/WmsModule/constants/schema"
import { requiredSelectOptionFactory } from "modules/WmsModule/helpers/schema"
import REFUND_METHOD_KEYS from "modules/OrdersModule/constants/refundMethodKeys"
import REFUND_RATE_KEYS from "modules/OrdersModule/constants/refundRateKeys"

export default Yup.object().shape({
    returnTo: requiredSelectOptionFactory(Yup.number()),
    refundMethod: requiredSelectOptionFactory(Yup.mixed().oneOf(Object.values(REFUND_METHOD_KEYS))),
    refundRate: requiredSelectOptionFactory(Yup.mixed().oneOf(Object.values(REFUND_RATE_KEYS))),
    description: DESCRIPTION_SCHEMA
})
