import * as constants from "../constants"
import fetch from "../helpers/fetch"

const createSendableStart = () => ({ type: constants.CREATE_SENDABLE_START })
const createSendableSuccess = data => ({ type: constants.CREATE_SENDABLE_SUCCESS, payload: data })
const createSendableError = error => ({ type: constants.CREATE_SENDABLE_ERROR, payload: error })

export const createSendable = body => dispatch => {
    dispatch(createSendableStart())

    return new Promise((resolve, reject) => {
        fetch
            .postRAW("/sendables", body)
            .then(data => {
                dispatch(createSendableSuccess(data.status === 204 ? { data: {} } : data))
                resolve(data)
            })
            .catch(error => {
                dispatch(createSendableError(error))
                reject(error)
            })
    })
}
