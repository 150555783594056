export const secondLink = "/products"
export default {
    headerColumns: [
        {
            name: "NO.",
            width: "5%"
        },
        {
            name: "FIRST NAME",
            width: "30%"
        },
        {
            name: "LAST NAME",
            width: "30%"
        },
        {
            name: "EMAIL",
            width: "20%"
        }
    ],
    dataColumns: [
        {
            name: "first_name",
            width: "30%"
        },
        {
            name: "last_name",
            width: "30%"
        },
        {
            name: "email",
            width: "20%"
        }
    ]
}
