import * as constants from "constants/index"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"

export default (state = initialState(), action) => {
    if (constants.CREATE_ORDER_FROM_PROJECT_START === action.type) {
        return requestState()
    }

    if (constants.CREATE_ORDER_FROM_PROJECT_SUCCESS === action.type) {
        return successState()
    }

    if (constants.CREATE_ORDER_FROM_PROJECT_ERROR === action.type) {
        return failureState()
    }

    return state
}
