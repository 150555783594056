import React from "react"
import PropTypes from "prop-types"
import ReactSvg from "react-svg"

import withStyles from "HOC/withStyles"
import styles from "./WarningBar.css"

import AlertInfoIcon from "assets/alert-info.svg"

const WarningBar = props => {
    const { cx, text, icon } = props

    return (
        <div className={cx("root")}>
            <ReactSvg src={icon} />
            {text}
        </div>
    )
}

WarningBar.defaultProps = {
    icon: AlertInfoIcon
}

WarningBar.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.string
}

export default withStyles(WarningBar, styles)
