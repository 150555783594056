import CLIENT_RETURN_SHIPMENT_STATUSES from "./clientReturnShipmentStatuses"
import CLIENT_RETURN_SHIPMENT_STATUS_KEYS from "./clientReturnShipmentStatusKeys"

const CLIENT_RETURN_SHIPMENT_STATUS_SETTER_OPTIONS = [
    CLIENT_RETURN_SHIPMENT_STATUSES[CLIENT_RETURN_SHIPMENT_STATUS_KEYS.NEW],
    CLIENT_RETURN_SHIPMENT_STATUSES[CLIENT_RETURN_SHIPMENT_STATUS_KEYS.IN_PROGRESS],
    CLIENT_RETURN_SHIPMENT_STATUSES[CLIENT_RETURN_SHIPMENT_STATUS_KEYS.SENT],
    CLIENT_RETURN_SHIPMENT_STATUSES[CLIENT_RETURN_SHIPMENT_STATUS_KEYS.RECEIVED],
    CLIENT_RETURN_SHIPMENT_STATUSES[CLIENT_RETURN_SHIPMENT_STATUS_KEYS.CANCELLED]
]

export default CLIENT_RETURN_SHIPMENT_STATUS_SETTER_OPTIONS
