import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import style from "./Pagination.css"
import arrow from "assets/path.svg"

class Pagination extends Component {
    render() {
        const { cx, pagesCount, currentPage, handleClick } = this.props

        if (!pagesCount || pagesCount === 1) {
            return null
        }

        return (
            <div className={cx("pagination-cover")}>
                <div
                    className={cx(currentPage === 0 ? "box-disabled" : "box")}
                    onClick={() => handleClick(currentPage > 0 ? currentPage - 1 : 0)}
                >
                    <img src={arrow} alt="left" className={cx("arrow-left")} />
                </div>

                {Array(pagesCount)
                    .fill()
                    .map((e, i) => i + 1)
                    .map((value, index) => {
                        return (
                            <div
                                key={index}
                                className={cx(currentPage === index ? "box-active" : "box")}
                                onClick={() => handleClick(index)}
                            >
                                <h5 className={cx("page-no")}>{index + 1}</h5>
                            </div>
                        )
                    })}

                <div
                    className={cx(currentPage === pagesCount - 1 ? "box-disabled" : "box")}
                    onClick={() => handleClick(currentPage < pagesCount - 1 ? currentPage + 1 : pagesCount - 1)}
                >
                    <img src={arrow} alt="right" className={cx("arrow-right")} />
                </div>
            </div>
        )
    }
}

export default withStyles(Pagination, style)
