import React from "react"
import PropTypes from "prop-types"

import { SnakeLoader } from "ui/Skeleton"
import StockTransferDetailsHeader from "modules/WmsModule/components/StockTransferDetailsHeader"
import StockTransferDetailsItemsList from "modules/WmsModule/components/StockTransferDetailsItemsList"
import { useShipmentDetailsWithItems } from "modules/WmsModule/hooks"

import styles from "./StockTransferDetails.module.css"

const StockTransferDetails = ({ stockTransferId, onStatusChanged }) => {
    const {
        shipment: stockTransfer,
        stockItems,
        transferFetchStatus,
        stockItemsFetchStatus,
        stockItemsSortState,
        onChangeStockItemsSortState,
        stockItemsPagination
    } = useShipmentDetailsWithItems({ shipmentId: stockTransferId })

    if (transferFetchStatus.isLoading) {
        return (
            <div className={styles.loader}>
                <SnakeLoader customStyles={styles.loader} />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <StockTransferDetailsHeader stockTransfer={stockTransfer} onStatusChanged={onStatusChanged} />
            <StockTransferDetailsItemsList
                transferredItems={stockItems}
                sortState={stockItemsSortState}
                onChangeSort={onChangeStockItemsSortState}
                pagination={stockItemsPagination}
                isLoading={stockItemsFetchStatus.isLoading}
            />
        </div>
    )
}

StockTransferDetails.propTypes = {
    stockTransferId: PropTypes.number.isRequired,
    onStatusChanged: PropTypes.func
}

export default StockTransferDetails
