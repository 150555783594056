import * as yup from "yup"

const QUESTION_MAX = 500
const ANSWER_MAX = 1500

export default yup.object({
    question: yup
        .string()
        .max(QUESTION_MAX, `Question may not be greater than ${QUESTION_MAX} characters.`)
        .required("Question is required."),
    answer: yup
        .string()
        .max(ANSWER_MAX, `Answer may not be greater than ${ANSWER_MAX} characters.`)
        .required("Answer is required.")
})
