import React, { useEffect } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"
import { createHintText } from "modules/ContentModule/helpers"

import { Dropzone } from "ui"

import cloudIcon from "./assets/cloud.svg"
import closeIcon from "assets/close-blue.svg"

import styles from "./styles.css"
import dropzoneStyles from "./overrides/dropzoneStyles.css"

const Image = ({
    cx,
    uploader: { state: uploadState, actions: uploadActions },
    form,
    imageDimensions,
    fieldName = "product_image"
}) => {
    useEffect(() => {
        if (idx(uploadState, _ => _.addedFile.url)) {
            form.setFieldValue(fieldName, {
                id: uploadState.addedFile.id,
                name: uploadState.addedFile.title,
                url: uploadState.addedFile.url
            })
        }
    }, [uploadState.isUploading, uploadState.isSuccess, uploadState.addedFile])

    const removeImage = () => {
        form.setFieldValue(fieldName, null)
    }

    const renderUpladingState = progress => (
        <div className={cx("dropzoneStateUploading")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    )

    const renderEmptyState = () => (
        <Dropzone
            isUploading={uploadState.isUploading}
            isSuccess={uploadState.isSuccess}
            progress={uploadState.progress}
            handleUpload={uploadActions.handleUpload}
            handleRemove={uploadActions.handleRemove}
            isMultiple={false}
            customStyles={dropzoneStyles}
        >
            <p className={cx("dropzoneStateEmpty")}>
                <img src={cloudIcon} alt="cloud" />
                Add image <span>{createHintText(imageDimensions)}</span>
            </p>
        </Dropzone>
    )

    const renderImageState = () => (
        <div className={cx("dropzoneStateImage")}>
            <div className={cx("imageWrapper")}>
                {form.values[fieldName].url && <img src={form.values[fieldName].url} alt="preview" />}
            </div>
            <div className={cx("bottomInfoWrapper")}>
                <span>{form.values[fieldName].name}</span>
                <button onClick={removeImage}>
                    <img src={closeIcon} alt="close" />
                </button>
            </div>
        </div>
    )

    return (
        <div className={cx("dropzoneWrapper", { halfWidth: form.values.type === "double" })}>
            {uploadState.isUploading
                ? renderUpladingState(uploadState.progress)
                : !!form.values[fieldName]
                ? renderImageState({ ...form.values[fieldName] })
                : renderEmptyState()}
        </div>
    )
}

Image.propTypes = {
    uploader: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    cx: PropTypes.func.isRequired,
    imageDimensions: PropTypes.object
}

export default withUploader(withStyles(Image, styles))
