import React, { Component, Fragment } from "react"
import withStyles from "HOC/withStyles"

import styles from "./Layout.css"

class Layout extends Component {
    render() {
        const { children, title, cx } = this.props
        return (
            <Fragment>
                <div className={cx("top-background")}>
                    <div className={cx("logo")}>
                        <h1 style={{ marginTop: -2 }}>Dragonfly</h1>
                    </div>
                </div>

                <div className={cx("login-cover")}>
                    {title && <h1 className={cx("login-title")}>{title}</h1>}
                    {children}
                </div>
            </Fragment>
        )
    }
}

export default withStyles(Layout, styles)
