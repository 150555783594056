import React from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import withStyles from "HOC/withStyles"
import { formatPrice } from "helpers/units"
import walletIcon from "assets/ico-wallet.svg"
import clockIcon from "assets/clock.svg"
import styles from "./List.module.css"
import { EmptyList } from "ui"
import FilterableTable, { Sort, Table } from "ui/FilterableTable"

const List = ({
    cx,
    handleEditPayment,
    handleTableChange,
    setPaymentToDelete,
    orderId,
    payments,
    fetchStatus,
    meta,
    canEdit,
    canDelete
}) => {
    const renderTableHeader = () => (
        <Table.Head>
            <Table.Tr>
                <Table.Th>
                    <Sort sortBy="amount">amount</Sort>
                </Table.Th>
                <Table.Th>
                    <Sort sortBy="created_at">date</Sort>
                </Table.Th>
                <Table.Th>
                    <Sort sortBy="payment_method_id">method</Sort>
                </Table.Th>
                <Table.Th>invoice</Table.Th>
                <Table.Th>payment note</Table.Th>
                <Table.Th />
            </Table.Tr>
        </Table.Head>
    )

    const renderTableBody = items => {
        const rows = items.map(({ amount, date, id, invoice, method, note, order, refund, return_shipment }) => {
            const methodName = method ? method.name.replace("-", " ") : ""

            return (
                <Table.Tr key={id}>
                    <Table.Td>
                        <p className={cx("table-amount", { refund })}>{formatPrice(refund ? -amount : amount)}</p>
                    </Table.Td>
                    <Table.Td>
                        <img className={cx("clock-icon")} src={clockIcon} alt="icon" />
                        <p className={cx("table-date")}>{moment(date).format("DD MMM YYYY")}</p>
                    </Table.Td>
                    <Table.Td>
                        <p className={cx("table-method")}>{methodName}</p>
                    </Table.Td>
                    <Table.Td>
                        <p className={cx("table-invoice")}>
                            {invoice ? (
                                <Link
                                    to={{
                                        pathname: `/orders/sales-orders/${!!orderId ? orderId : order.id}/invoices`,
                                        state: { invoiceId: invoice.id }
                                    }}
                                >
                                    {invoice.uuid}
                                </Link>
                            ) : refund && return_shipment.uuid ? (
                                <Link to={`/orders/returns?query=${return_shipment.uuid}`}>{return_shipment.uuid}</Link>
                            ) : (
                                "No invoice"
                            )}
                        </p>
                    </Table.Td>
                    <Table.Td>
                        <p className={cx("table-note")}>{note}</p>
                    </Table.Td>
                    <Table.Td className="textRight">
                        {canDelete() && (
                            <div className={cx("delete")} onClick={() => setPaymentToDelete({ id, amount })}>
                                Delete
                            </div>
                        )}
                        {canEdit() && (
                            <div className={cx("edit")} onClick={() => handleEditPayment(id)}>
                                Edit
                            </div>
                        )}
                    </Table.Td>
                </Table.Tr>
            )
        })

        return <Table.Body>{rows}</Table.Body>
    }

    return (
        <div className={cx("root")}>
            <FilterableTable fetchStatus={fetchStatus} items={payments} meta={meta} handleChange={handleTableChange}>
                <Table
                    className={cx("table")}
                    renderHeader={renderTableHeader}
                    renderBody={renderTableBody}
                    customEmptyMessage={() => <EmptyList message="No payments added" icon={walletIcon} />}
                />
            </FilterableTable>
        </div>
    )
}

export default withStyles(List, styles)
