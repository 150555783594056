import React from "react"
import PropTypes from "prop-types"
import { ConfirmModal } from "@butterfly-frontend/ui"

const DeleteWarehouse = ({ onCancel, onDelete, isLoading, name }) => (
    <ConfirmModal
        onCancel={onCancel}
        onAction={onDelete}
        isLoading={isLoading}
        title="Delete this warehouse"
        buttonLabel="Delete"
        buttonColor="red"
    >
        Are you sure you want to delete the <b>{name}</b>? This action cannot be reversed.
    </ConfirmModal>
)

DeleteWarehouse.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    name: PropTypes.string.isRequired
}

export default DeleteWarehouse
