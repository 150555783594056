import React, { useState, Fragment, useEffect } from "react"

import { default as ModalComponent } from "ui/Modal"
import Modal from "./modal"
import withStyles from "HOC/withStyles"
import styles from "./styles/modal.css"
import modalStyles from "./overrides/modal.css"

const ModalContainer = props => {
    const { isOpen, handleClose, setError, submit, cx, error } = props

    const service = props.service || {}

    const [serviceName, setServiceName] = useState(service.name || "")
    const [itemPrice, setItemPrice] = useState(service.price || "")
    const [exemptCode, setExemptCode] = useState(service.exempt_code || "")
    const [description, setDescription] = useState(service.price || "")
    const [purge, setPurge] = useState(false)

    useEffect(() => {
        setServiceName(service.name || "")
        setItemPrice(service.price || "")
        setExemptCode(service.exempt_code || "")
        setDescription(service.description || "")
    }, [service.name, service.description, service.price])

    const clearInputs = () => {
        setServiceName("")
        setItemPrice("")
        setExemptCode("")
        setDescription("")
    }

    // TODO: Remove this workaround and fix problem
    useEffect(() => {
        if (purge && !error) {
            clearInputs()
        }
        setPurge(false)
    }, [purge])

    const fieldsProps = {
        serviceName: {
            label: (
                <Fragment>
                    {"Service name"}
                    <p className={cx("red-asterix")}>&nbsp;*</p>
                </Fragment>
            ),
            value: serviceName,
            onChange: e => {
                setServiceName(e.event.target.value)
                if (error && error.name) {
                    setError({ ...error, name: null })
                }
            },
            error: error && error.name,
            onFocus: () => error && setError({ ...error, name: null })
        },
        itemPrice: {
            label: (
                <Fragment>
                    {"Item price"}
                    <p className={cx("red-asterix")}>&nbsp;*</p>
                </Fragment>
            ),
            value: itemPrice,
            onChange: e => {
                setItemPrice(e.event.target.value)
                if (error && error.price) {
                    setError({ ...error, price: null })
                }
            },
            error: error && error.price,
            innerLabel: "$",
            isInnerLabelLeft: true
        },
        exemptCode: {
            label: (
                <Fragment>
                    {"Service exempt code"}
                    <p className={cx("red-asterix")}>&nbsp;*</p>
                </Fragment>
            ),
            value: exemptCode,
            onChange: e => {
                setExemptCode(e.event.target.value)
                if (error && error.exempt_code) {
                    setError({ ...error, exempt_code: null })
                }
            },
            error: error && error.exempt_code
        },
        description: {
            label: "Description",
            value: description,
            onChange: e => {
                setDescription(e.event.target.value)
                if (error && error.description) {
                    setError({ ...error, description: null })
                }
            },
            error: error && error.description,
            placeholder: "Click here to add a description..."
        }
    }

    const handleButton = () => {
        submit({
            id: service.id,
            description: description,
            name: serviceName,
            exempt_code: exemptCode,
            price: itemPrice
        }).then(() => setPurge(true))
    }

    return (
        <ModalComponent customStyles={modalStyles} isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <Modal {...props} handleButton={handleButton} closeModal={handleClose} fieldsProps={fieldsProps} />
        </ModalComponent>
    )
}

export default withStyles(ModalContainer, styles)
