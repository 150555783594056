import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"

export function getChannelsStart() {
    return {
        type: constants.GET_CHANNELS_START
    }
}
export function getChannelsSuccess(data) {
    return {
        type: constants.GET_CHANNELS_SUCCESS,
        payload: {
            data
        }
    }
}
export function getChannelsError(error) {
    return {
        type: constants.GET_CHANNELS_ERROR,
        payload: {
            error
        }
    }
}
export function getChannels() {
    return dispatch => {
        dispatch(getChannelsStart())
        dispatch(getChannelStart())

        fetch
            .get(`/communication-channels?length=${LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getChannelsSuccess(data)))
            .catch(error => {
                dispatch(getChannelsError(error))
            })
    }
}

function getChannelStart() {
    return {
        type: constants.GET_CHANNEL_START
    }
}
function getChannelSuccess(data) {
    return {
        type: constants.GET_CHANNEL_SUCCESS,
        payload: {
            data
        }
    }
}
function getChannelError(error) {
    return {
        type: constants.GET_CHANNEL_ERROR,
        payload: {
            error
        }
    }
}

export function createChannel(name) {
    return dispatch => {
        fetch
            .post(`/communication-channels`, { name })
            .then(data => dispatch(getChannelById(data[data.length - 1].id)))
            .catch(error => dispatch(getChannelError(error)))
    }
}

export function editChannel(id, name) {
    return dispatch => {
        fetch
            .patch(`/communication-channels/${id}`, { name })
            .then(() => dispatch(getChannelById(id)))
            .catch(error => dispatch(getChannelError(error)))
    }
}

export function getChannelById(id) {
    return dispatch => {
        dispatch(getChannelStart())
        fetch
            .get(`/communication-channels/${id}`)
            .then(data => dispatch(getChannelSuccess(data)))
            .catch(error => dispatch(getChannelError(error)))
    }
}

export function deleteChannelStart() {
    return {
        type: constants.DELETE_CHANNEL_START
    }
}

export function deleteChannelError(error) {
    return {
        type: constants.DELETE_CHANNEL_ERROR,
        payload: {
            error
        }
    }
}

export function deleteChannelSuccess(id) {
    return {
        type: constants.DELETE_CHANNEL_SUCCESS,
        payload: id
    }
}

export function deleteChannel(id) {
    return dispatch => {
        dispatch(deleteChannelStart())

        fetch
            .deleteRAW(`/communication-channels/${id}`)
            .then(() => dispatch(deleteChannelSuccess(id)))
            .catch(error => dispatch(deleteChannelError(error)))
    }
}
