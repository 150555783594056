import React, { Component } from "react"

import Layout from "components/Reusable/Layout/index"
import layoutStyles from "./styles.css"

class ListingLayout extends Component {
    render() {
        const { children } = this.props
        return (
            <Layout
                {...this.props}
                title={"Main Page"}
                tabs={[
                    {
                        url: "/content/main-page/image-with-pins",
                        label: "Image with pins"
                    },
                    {
                        url: "/content/main-page/collections",
                        label: "Collections"
                    },
                    {
                        url: "/content/main-page/realizations",
                        label: "Projects"
                    },
                    {
                        url: "/content/main-page/our-favourites",
                        label: "Our favourites"
                    },
                    {
                        url: "/content/main-page/immediate-delivery",
                        label: "Immediate delivery"
                    },
                    {
                        url: "/content/main-page/news",
                        label: "News"
                    },
                    {
                        url: "/content/main-page/link",
                        label: "Link"
                    }
                ]}
                customStyles={layoutStyles}
            >
                {children}
            </Layout>
        )
    }
}

export default ListingLayout
