import React, { useState, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"

import { ColorTile, Zoom } from "ui/ColorPicker/components"
import CloseIcon from "assets/close.svg"
import { getCombinationById } from "actions/combinations"
import { SnakeLoader } from "ui/Skeleton"
import getStyles from "helpers/getCxFromStyles"

import styles from "./styles.css"

const CombinationDetails = ({ combinationId, onClose }) => {
    const dispatch = useDispatch()
    const { data, isLoading } = useSelector(state => state.db.combinations.current)
    const [zoomValues, setZoomValues] = useState({
        isZoomed: false,
        background: "",
        name: ""
    })
    const cx = getStyles(styles)

    useEffect(() => {
        dispatch(getCombinationById(combinationId))
    }, [])

    const handleColorHover = event => {
        setZoomValues({
            isZoomed: true,
            background: event.background,
            name: event.name
        })
    }

    const handleColorMouseOut = () => {
        setZoomValues({ ...zoomValues, isZoomed: false })
    }

    return (
        <div className={cx("root")}>
            <img className={cx("closeButton")} src={CloseIcon} alt="close" onClick={onClose} />
            {isLoading && <SnakeLoader />}
            {!isLoading && data && (
                <Fragment>
                    <span className={cx("id")}>{data.id}</span>
                    {data.attribute_values.map(attribute => (
                        <Fragment key={attribute.id}>
                            <span className={cx("header")}>{attribute.attribute_name}:</span>
                            <span className={cx("value")}>
                                {attribute.value}
                                <div className={cx("colorTileWrapper")}>
                                    {(attribute.color || attribute.texture) && (
                                        <ColorTile
                                            small
                                            handleHover={handleColorHover}
                                            handleMouseOut={handleColorMouseOut}
                                            handleSelectTile={() => {}}
                                            key={attribute.id}
                                            id={attribute.id}
                                            name={attribute.value}
                                            color={attribute.color}
                                            texture={attribute.texture_preview}
                                        />
                                    )}
                                </div>
                            </span>
                        </Fragment>
                    ))}
                    {zoomValues.isZoomed && <Zoom zoomDirection="left" zoomValues={zoomValues} cx={cx} />}
                </Fragment>
            )}
        </div>
    )
}

CombinationDetails.propTypes = {
    combinationId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
}

export default CombinationDetails
