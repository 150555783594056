import React from "react"
import idx from "idx"

import withStyles from "HOC/withStyles"
import * as fetchStatusHelper from "helpers/fetchStatus"
import { Modal, Button, Quantity, CombinationAttributeSelect } from "ui"
import Loader from "components/Reusable/Loader"
import Skeleton from "components/Skeleton"

import styles from "./Combinations.css"
import primaryButtonStyles from "./overrides/combinations/PrimaryButton.css"
import secondaryButtonStyles from "./overrides/combinations/SecondaryButton.css"
import quantityStyles from "./overrides/Quantity.css"

const Combinations = props => {
    const {
        cx,
        product,
        productImage,
        combinationId,
        quantity,
        handleChangeQuantity,
        handleChangeAttribute,
        handleSelectProduct,
        handleSelectProductCombination,
        itemsState,
        handleChangeIsLoading,
        isFetchingCombination,
        productAttributes
    } = props

    return <Modal.Body>{renderBody()}</Modal.Body>

    function renderBody() {
        return (
            <div className={cx("body")}>
                <header className={cx("header")}>
                    <span>Choose an option</span>
                </header>
                <Skeleton
                    fetchStatus={fetchStatusHelper.fromResource(product)}
                    fallback={() => <div>An error occurred, please try again.</div>}
                    component={
                        <div className={cx("loader")}>
                            <Loader />
                        </div>
                    }
                    render={() => {
                        if (!hasCombinations()) {
                            handleSelectProduct(null)
                        }

                        return (
                            <div>
                                <div className={cx("productInfoContainer")}>
                                    <div className={cx("photoContainer")}>
                                        <div
                                            className={cx("photo")}
                                            style={{
                                                backgroundImage: "url(" + productImage + ")"
                                            }}
                                        />
                                    </div>
                                    <div className={cx("nameContainer")}>
                                        <div className={cx("name")}>{product.data.name}</div>
                                        <div className={cx("brand")}>{(product.data.brand || {}).name || ""}</div>
                                    </div>
                                </div>

                                {renderAttributeList()}

                                <footer className={cx("footer")}>
                                    <div>
                                        <Button
                                            customStyles={secondaryButtonStyles}
                                            label="Back"
                                            onClick={() => handleSelectProduct(null)}
                                        />
                                    </div>
                                    <div className={cx("right-side")}>
                                        <Quantity
                                            customStyles={quantityStyles}
                                            value={quantity}
                                            min={1}
                                            onChange={handleChangeQuantity}
                                        />
                                        <Button
                                            customStyles={primaryButtonStyles}
                                            label="Add Product"
                                            isDisabled={!combinationId}
                                            isLoading={
                                                isFetchingCombination ||
                                                idx(itemsState, _ => _[product.data.id].isLoading)
                                            }
                                            onClick={() => {
                                                handleChangeIsLoading(product.data.id, true)
                                                handleSelectProductCombination({
                                                    product_id: product.data.id,
                                                    combination_id: combinationId,
                                                    quantity: quantity
                                                })
                                            }}
                                        />
                                    </div>
                                </footer>
                            </div>
                        )
                    }}
                />
            </div>
        )
    }

    function renderAttributeList() {
        return (
            <div className={cx("attributeListContainer")}>
                {productAttributes.map(attribute => (
                    <CombinationAttributeSelect
                        {...attribute}
                        key={attribute.id}
                        selectAttribute={handleChangeAttribute}
                        zoomDirection="right"
                    />
                ))}
            </div>
        )
    }

    function hasCombinations() {
        return idx(product, _ => _.data.has_combinations) && (idx(product, _ => _.data.attributes) || []).length
    }
}

export default withStyles(Combinations, styles)
