import React, { Fragment } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import TruncateMarkup from "react-truncate-markup"

import { AlertInfoIcon } from "@butterfly-frontend/ui"
import { ProductDetailsPopup } from "modules/WmsModule/modals"

import styles from "./ProductNameCell.module.css"

const ProductNameCell = ({ stockItem: { product, combination }, onPopupOpen, isPopupOpen, onPopupClose }) => {
    const productHasCombination = combination && combination.attribute_values

    return (
        <Fragment>
            <div>
                <TruncateMarkup lines={2}>
                    <span className={styles.productName}>{product.name}</span>
                </TruncateMarkup>
                {(productHasCombination || product.technical_description) && (
                    <TruncateMarkup lines={3}>
                        <span className={styles.productDescription}>
                            {productHasCombination ? combination.name : product.technical_description}
                        </span>
                    </TruncateMarkup>
                )}
            </div>
            {(productHasCombination || product.technical_description) && (
                <ProductDetailsPopup
                    trigger={
                        <AlertInfoIcon
                            className={classNames(styles.helpIcon, {
                                [styles.active]: isPopupOpen
                            })}
                            onClick={onPopupOpen}
                        />
                    }
                    position="right"
                    renderModalOnTablet
                    isOpen={isPopupOpen}
                    onClose={onPopupClose}
                    productName={product.name}
                    productImageSrc={combination ? combination.image || product.image : null}
                    combination={combination}
                    description={product.technical_description}
                />
            )}
        </Fragment>
    )
}

ProductNameCell.propTypes = {
    stockItem: PropTypes.shape({
        product: PropTypes.shape({
            name: PropTypes.string.isRequired,
            technical_description: PropTypes.string,
            image: PropTypes.string
        }).isRequired,
        combination: PropTypes.shape({
            name: PropTypes.string.isRequired,
            attribute_values: PropTypes.array,
            image: PropTypes.string
        })
    }).isRequired,
    isPopupOpen: PropTypes.bool.isRequired,
    onPopupOpen: PropTypes.func.isRequired,
    onPopupClose: PropTypes.func.isRequired
}

export default ProductNameCell
