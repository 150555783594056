import Client from "./Client"
import ClientAddress from "./ClientAddress"
import ClientType from "./ClientType"
import Channel from "./Channel"
import Source from "./Source"
import Service from "./Service"
import Role from "./Role"
import User from "./User"
import Product from "./Product"
import ProductCondition from "./ProductCondition"
import Attribute from "./Attribute"
import AttributeValue from "./AttributeValue"
import AttributeGroup from "./AttributeGroup"
import Brand from "./Brand"
import Category from "./Category"
import Feature from "./Feature"
import FeatureValue from "./FeatureValue"
import Login from "./LoginPage/Login"
import News from "./News"
import Blog from "./Blog"
import Portfolio from "./Portfolio"
import ForgotPassword from "./LoginPage/ForgotPassword"
import SetPassword from "./LoginPage/SetPassword"
import ResetPassword from "./LoginPage/ResetPassword"
import ClientSearch from "./ClientSearch"
import CompanyClient from "./CompanyClient"
import CombinationTemplate from "./CombinationTemplate"

const models = {
    Attribute,
    AttributeValue,
    AttributeGroup,
    Brand,
    Category,
    ClientSearch,
    Feature,
    FeatureValue,
    CompanyClient,
    Client,
    ClientAddress,
    ClientType,
    Source,
    Service,
    Channel,
    Role,
    User,
    Login,
    News,
    Blog,
    Portfolio,
    ForgotPassword,
    SetPassword,
    ResetPassword,
    Product,
    ProductCondition,
    CombinationTemplate
}

export default models
