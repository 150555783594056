import React, { useState, useEffect, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { useField, useFormikContext } from "formik"
import _get from "lodash/get"
import _pick from "lodash/pick"
import { H2Counter, Button, AddIcon } from "@butterfly-frontend/ui"

import { roundNumber } from "helpers/units"
import SpecialInstructionAndBalanceSection from "modules/OrdersModule/pages/StockPurchaseOrderFormPage/SpecialInstructionAndBalanceSection"
import { SelectDiscountSection } from "modules/OrdersModule/components"
import { DISCOUNT_LIST_PROP_TYPE } from "modules/OrdersModule/propTypes"
import ItemList from "modules/OrdersModule/pages/StockPurchaseOrderFormPage/ItemList"
import ChooseItemModal from "modules/OrdersModule/pages/StockPurchaseOrderFormPage/ChooseItemModal"
import calculateTotalPrice from "helpers/calculateTotalPrice"
import { useAddPOProduct } from "modules/OrdersModule/pages/StockPurchaseOrderFormPage/hooks"

import styles from "./ItemsTab.module.css"

const extractSelectedBrand = (selectedProducts = []) => _get(selectedProducts, "[0].brand", null)

const productBrandAdapter = brand => (brand ? _pick(brand, ["name", "id"]) : null)

const ItemsTab = props => {
    const { isEdit, discountBrandList, isSubmitting, isConfirmed, setEditProductRowId, editProductRowId } = props
    const [isAddItemModalOpen, setIsAddItemModalOpen] = useState(false)

    const [itemsField] = useField("items")
    const [discountField] = useField("discount")
    const [brandField] = useField("brand")
    const [{ value: specialInstructionValue }] = useField("special_instruction")
    const { setFieldValue } = useFormikContext()

    const brandDiscountValue = useMemo(() => _get(discountField.value, "value.value", null), [discountField.value])

    const { calculatedSubtotal, calculatedDiscount } = useMemo(() => {
        const result = itemsField.value.reduce(
            (aggregate, item) => {
                const totalPriceItem = calculateTotalPrice({
                    price: item.wholesale_cost,
                    quantity: item.quantity,
                    discount: typeof item.wholesale_discount === "number" ? item.wholesale_discount : brandDiscountValue
                })

                return {
                    calculatedSubtotal: aggregate.calculatedSubtotal + item.wholesale_cost * item.quantity,
                    calculatedDiscount:
                        aggregate.calculatedDiscount + item.wholesale_cost * item.quantity - totalPriceItem
                }
            },
            { calculatedSubtotal: 0, calculatedDiscount: 0 }
        )

        return {
            calculatedSubtotal: roundNumber(result.calculatedSubtotal),
            calculatedDiscount: roundNumber(result.calculatedDiscount)
        }
    }, [itemsField.value, brandDiscountValue])

    const calculatedTotalAmount = useMemo(() => roundNumber(calculatedSubtotal - calculatedDiscount), [
        calculatedDiscount,
        calculatedSubtotal
    ])

    const addOrderItem = useCallback(orderItem => setFieldValue("items", [...itemsField.value, orderItem]), [
        itemsField
    ])

    const removeOrderItem = useCallback(
        itemToRemove => {
            const newItemsFieldValue = itemsField.value.filter(({ id, draftId }) =>
                itemToRemove.draftId ? draftId !== itemToRemove.draftId : id !== itemToRemove.id
            )

            setFieldValue("items", newItemsFieldValue)
        },
        [setFieldValue, itemsField]
    )

    const editOrderItem = useCallback(
        ({ id, draftId, ...editedData }) => {
            const newItemsFieldValue = itemsField.value.map(orderItem => {
                const isEditedItem = orderItem.draftId ? draftId === orderItem.draftId : id === orderItem.id

                return isEditedItem ? { ...orderItem, ...editedData } : orderItem
            })

            setFieldValue("items", newItemsFieldValue)
        },
        [setFieldValue, itemsField]
    )

    const { handleProductSelect, productFetchStatuses, isAnyProductLoading } = useAddPOProduct({ addOrderItem })

    useEffect(() => {
        const newSelectedBrand = extractSelectedBrand(itemsField.value)
        if (!isEdit && _get(newSelectedBrand, "id") !== _get(brandField.value, "id")) {
            setFieldValue("brand", newSelectedBrand)
        }
    }, [itemsField.value])

    const lockedFilters = useMemo(() => ({ brand: productBrandAdapter(brandField.value) }), [brandField.value])

    return (
        <div className={styles.root}>
            <SelectDiscountSection
                selectedDiscount={discountField.value}
                discountList={discountBrandList}
                onChange={value =>
                    discountField.onChange({ target: { ...discountField, value: value.value ? value : null } })
                }
                isDisabled={isSubmitting}
            />
            <div className={styles.addItemBar}>
                <H2Counter counterValue={itemsField.value.length}>Items</H2Counter>
                <Button
                    Icon={AddIcon}
                    onClick={() => setIsAddItemModalOpen(true)}
                    disabled={isConfirmed || isSubmitting}
                >
                    Add item
                </Button>
            </div>
            <ItemList
                items={itemsField.value}
                removeOrderItem={removeOrderItem}
                editOrderItem={editOrderItem}
                brandName={_get(brandField.value, "name")}
                brandDiscountValue={brandDiscountValue}
                disabledEditAndDeleteItem={isConfirmed || isSubmitting}
                setEditProductRowId={setEditProductRowId}
                editProductRowId={editProductRowId}
            />
            <SpecialInstructionAndBalanceSection
                specialInstruction={specialInstructionValue}
                subtotal={calculatedSubtotal}
                discount={calculatedDiscount}
                totalAmount={calculatedTotalAmount}
            />
            <ChooseItemModal
                isOpen={isAddItemModalOpen}
                onClose={() => setIsAddItemModalOpen(false)}
                lockedFilters={lockedFilters}
                handleSelect={handleProductSelect}
                isDisabledAddItems={isAnyProductLoading}
                fetchStatuses={productFetchStatuses}
            />
        </div>
    )
}

ItemsTab.propTypes = {
    purchaseOrderId: PropTypes.string,
    discountBrandList: DISCOUNT_LIST_PROP_TYPE.isRequired,
    isEdit: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    setEditProductRowId: PropTypes.func.isRequired,
    editProductRowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default ItemsTab
