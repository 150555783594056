import React, { Component } from "react"
import { connect } from "react-redux"
import * as brandActions from "actions/brand"
import * as categoryActions from "actions/category"
import * as productActions from "actions/product"
import * as notificationActions from "actions/notification"
import _ from "lodash"

import withPermissions from "HOC/withPermissions"

import MainPage from "./MainPage"
import config from "./config"

const isIframe = window.top !== window.self
const LOCALHOST = "localhost"

class MainPageContainer extends Component {
    state = {
        isLoaded: !isIframe,
        chromeExtension: null,
        fields: {},
        activeTake: null,
        fieldsErrors: {},
        currentProductId: null,
        isSavingOnDisk: false
    }

    componentDidMount() {
        const { getBrands, getCategories, canList, canView, PERMISSIONS } = this.props

        if (!canList(PERMISSIONS.context.PRODUCTS) && !canView(PERMISSIONS.context.PRODUCTS)) {
            this.setState(
                {
                    isLoaded: false
                },
                () => {
                    this.sendMessage({ action: "LOGOUT" })
                    window.localStorage.clear()
                    window.location.href = "/scraper"
                }
            )
            return
        }

        getBrands()
        getCategories()

        window.addEventListener("message", this.messageListener, false)
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.messageListener, false)
    }

    componentDidUpdate(prevProps) {
        const { product: prevProduct } = prevProps
        const { product } = this.props

        if (prevProduct.isLoading && !product.isLoading && product.data) {
            this.fillState()
        }
    }

    render() {
        return (
            <MainPage
                {...this.props}
                {...this.state}
                config={config}
                handleTake={this.handleTake}
                handleDeleteImage={this.handleDeleteImage}
                handleChangeField={this.handleChangeField}
                handleCreateProduct={this.handleCreateProduct}
                handleClose={this.handleClose}
                handleSelectProduct={this.handleSelectProduct}
                handleResetState={this.handleResetState}
                handleToggleSaveOnDisk={this.handleToggleSaveOnDisk}
                sendMessage={this.sendMessage}
            />
        )
    }

    fillState = () => {
        const { product } = this.props
        const { fields } = this.state

        if (!product.data) {
            return
        }

        let newFields = {
            ...fields
        }

        config.map((field, index) => {
            let value = _.get(product.data, field.apiPath)

            if (field.type === "img") {
                value = product.data.media
                    ? product.data.media.reduce((output, image) => {
                          output[image.url] = LOCALHOST
                          return output
                      }, {})
                    : {}
            }

            newFields[field.name] = value

            return null
        })

        this.setState({
            fields: {
                ...newFields
            },
            activeTake: null,
            fieldsErrors: {}
        })
    }

    handleResetState = () => {
        this.setState({
            fields: {},
            activeTake: null,
            fieldsErrors: {},
            currentProductId: null,
            isSavingOnDisk: false
        })
    }

    handleToggleSaveOnDisk = isSavingOnDisk => {
        this.setState(
            {
                isSavingOnDisk,
                fields: {},
                activeTake: null,
                fieldsErrors: {},
                currentProductId: null
            },
            () => {
                this.sendMessage({
                    action: this.state.isSavingOnDisk ? "ENABLE_SAVING_ON_DISK" : "DISABLE_SAVING_ON_DISK"
                })
            }
        )
    }

    sendMessage = message => {
        const { chromeExtension } = this.state

        if (window.chrome && chromeExtension) {
            window.chrome.runtime.sendMessage(chromeExtension.id, message)
        }
    }

    handleClose = () => {
        this.sendMessage({ action: "DISABLE_SCRAPER" })
    }

    handleTake = ({ type, field }) => {
        this.setState({
            activeTake: field
        })
        this.sendMessage({ action: "TAKE_VALUE", type, field })
    }

    handleDeleteImage = url => {
        const { fields } = this.state

        if (!fields.img) {
            return
        }

        const newImg = { ...fields.img }
        delete newImg[url]

        this.setState({
            fields: {
                ...fields,
                img: {
                    ...newImg
                }
            }
        })
    }

    handleChangeField = ({ name, value }) => {
        const { fields } = this.state

        this.setState({
            fields: {
                ...fields,
                [name]: value
            }
        })
    }

    handleCreateProduct = () => {
        const { fields, currentProductId } = this.state
        const data = {
            ...fields,
            draft: 0,
            scraper: true,
            brand_id: fields.brand,
            height: fields.height ? Number(fields.height) : 0,
            width: fields.width ? Number(fields.width) : 0,
            depth: fields.depth ? Number(fields.depth) : 0,
            price: (fields.price || "").replace(",", ".")
        }

        this.setState({
            isSending: true
        })

        const productAction = currentProductId
            ? () => productActions.updateProduct({ id: currentProductId, data })
            : () => productActions.createProduct(data)

        productAction()
            .then(response => {
                const newImages = fields.img
                    ? Object.keys(fields.img).reduce((output, url) => {
                          if (fields.img[url] && fields.img[url] !== LOCALHOST) {
                              output[url] = fields.img[url]
                          }
                          return output
                      }, {})
                    : null

                if (!newImages || (newImages && Object.keys(newImages).length === 0)) {
                    this.createProductSuccess()
                    return
                }

                const productId = response.data && response.data.id ? response.data.id : 0
                const urls = Object.keys(newImages)

                Promise.all(urls.map(url => productActions.uploadUrl(url, productId)))
                    .then(() => this.createProductSuccess())
                    .catch(() => this.createProductError({}))
            })
            .catch(response => this.createProductError(response.errors ? response.errors : {}))
    }

    handleSelectProduct = id => {
        const { getProductById } = this.props

        this.setState(
            {
                currentProductId: id,
                isSavingOnDisk: false
            },
            () => {
                getProductById(id)
            }
        )
    }

    createProductError = errors => {
        const { showErrorNotification } = this.props
        this.setState(
            {
                isSending: false,
                fieldsErrors: errors
            },
            () => {
                showErrorNotification()
            }
        )
    }

    createProductSuccess = () => {
        const { showSuccessNotification } = this.props

        this.setState(
            {
                fieldsErrors: {},
                fields: {},
                isSending: false,
                currentProductId: null
            },
            () => {
                showSuccessNotification()
            }
        )
    }

    messageListener = event => {
        const { fields } = this.state
        if (event && event.data) {
            try {
                const json = JSON.parse(event.data)
                if (!json) {
                    return
                }

                if (json.chromeExtension && json.chromeExtension.id) {
                    this.setState(
                        {
                            chromeExtension: json.chromeExtension,
                            isLoaded: true
                        },
                        () => {
                            this.sendMessage({ action: "WEB_IS_READY" })
                        }
                    )
                    return
                }

                if (json.type && json.field && json.type === "TEXT") {
                    this.setState({
                        fields: {
                            ...fields,
                            [json.field]: json.content.trim()
                        },
                        activeTake: null
                    })
                }

                if (json.type && json.field && json.type === "IMAGE") {
                    const prevImages = fields[json.field] ? fields[json.field] : {}

                    this.setState({
                        fields: {
                            ...fields,
                            [json.field]: {
                                ...prevImages,
                                [json.content]: json.sourceUrl
                            }
                        },
                        activeTake: null
                    })
                }
            } catch (e) {}
        }
    }
}

const mapStateToProps = state => {
    return {
        email: state.loginStore.email,
        brands: state.db.brandsScraper.list.data,
        categories: state.db.categories.list.data,
        notification: state.ui.notification,
        product: state.db.products.current
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBrands: () => dispatch(brandActions.getBrands()),
        getCategories: () => dispatch(categoryActions.getCategories()),
        getProductById: id => dispatch(productActions.getProductById(id)),
        showSuccessNotification: data => dispatch(notificationActions.showSuccessNotification(data)),
        showErrorNotification: data => dispatch(notificationActions.showErrorNotification(data))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPermissions(MainPageContainer))
