import React, { Component, Fragment } from "react"
import CSSModules from "react-css-modules"
import Client from "./Client"
import style from "./styles.css"

class ClientsListWithButton extends Component {
    render() {
        const { clients, isLoaded, handleWorkWith } = this.props
        return (
            <Fragment>
                <div styleName="list-cover">
                    <ul>
                        {isLoaded &&
                            clients &&
                            clients.data &&
                            clients.data.map(({ id, first_name, last_name, email }) => (
                                <Client
                                    key={email}
                                    id={id}
                                    email={email}
                                    first_name={first_name}
                                    last_name={last_name}
                                    handleWorkWith={handleWorkWith}
                                />
                            ))}
                        {!isLoaded &&
                            Array(clients && clients.data ? clients.data.length : 9)
                                .fill()
                                .map((e, i) => i + 1)
                                .map(value => <div key={value} styleName="loading-placeholder" />)}
                    </ul>
                </div>
            </Fragment>
        )
    }
}

export default CSSModules(ClientsListWithButton, style)
