import React, { useState, useEffect, useLayoutEffect, useCallback, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"

import { InputButton, List, ListItem, EmptyList, ErrorLabel } from "./components"

import withStyles from "HOC/withStyles"
import { SnakeLoader } from "ui/Skeleton"

import styles from "./SelectWithSearch.css"
import selectAllStyles from "./overrides/selectAll.css"
import loaderStyles from "./overrides/SnakeLoader.css"

import addIcon from "assets/add-white.svg"

const RIGHT_INPUT_PADDING_IN_PIXELS = 30

const SelectWithSearch = props => {
    const {
        cx,
        multiselect,
        withoutFetch,
        withoutSearch,
        isListOnTop,
        isBlocked,
        isDisabled,
        showLoadingIcon,
        isClearBlocked,
        label,
        name,
        type,
        placeholder,
        setValue,
        onInputChange,
        values,
        value,
        fetchValues,
        error,
        pagination,
        customErrorStyles,
        emptyListLabel,
        allSelectedValueLabel,
        selectAllLabel,
        showSelectAllLabel,
        handleScrollToError,
        handleClear,
        additionalButtonIcon,
        additionalButtonLabel,
        appendValueToBtnLabel,
        disableBtnForEmptyValue,
        disableBtnForDuplicateLabel,
        additionalButtonAction,
        valueMaxLength,
        listValueMaxLength,
        isRequired,
        isAllSelected,
        renderSticker
    } = props

    const listRef = useRef(null)
    const rootRef = useRef(null)
    const stickerRef = useRef(null)
    const inputWrapperRef = useRef(null)
    const multiselectExtraInputRef = useRef(null)
    const debounceRef = useRef(null)

    const isSelectAllVisible = showSelectAllLabel && multiselect && withoutFetch

    const [isExpanded, setIsExpanded] = useState(false)
    const [writtenValue, setWrittenValue] = useState("")
    const [multiselectExtraInputValue, setMultiselectExtraInputValue] = useState("")
    const [listCursor, setListCursor] = useState(isSelectAllVisible ? -2 : -1)
    const [innerValues, setInnerValues] = useState(values)
    const [shouldFetchAll, setShouldFetchAll] = useState(false)
    const [isSelectedAll, setIsSelectedAll] = useState(false)

    useEffect(() => {
        const shouldSetIsSelectedAllToFalse = isSelectedAll && value.length !== values.length

        shouldSetIsSelectedAllToFalse && toggleSelectAll()
        handleSetWrittenValue(shouldSetIsSelectedAllToFalse ? "" : value)
    }, [value])

    useEffect(() => {
        setInnerValues(values)
    }, [values])

    useEffect(() => {
        const listElement = listRef.current
        if (!multiselect && listElement && listElement.scrollTo) {
            setListCursor(0)
            listElement.scrollTo(0, 0)
        }
    }, [isExpanded, writtenValue])

    useEffect(() => {
        if (isExpanded && value) {
            !multiselect && resetValues()
            setShouldFetchAll(true)
        }
    }, [isExpanded, value])

    useEffect(() => {
        !isExpanded && setMultiselectExtraInputValue("")
        isExpanded && isValue && multiselectExtraInputRef.current && multiselectExtraInputRef.current.focus()
        if (!isExpanded && !isValue) {
            handleSetWrittenValue("")
            resetValues()
        }
    }, [isExpanded])

    useLayoutEffect(() => {
        document.addEventListener("click", handleClickOutside)
        return () => document.removeEventListener("click", handleClickOutside)
    }, [])

    useEffect(() => {
        isAllSelected && setIsSelectedAll(true)
    }, [isAllSelected])

    const resetValues = useCallback(() => (withoutFetch ? setInnerValues(values) : fetchValues("", 1)), [
        values,
        withoutFetch,
        fetchValues
    ])

    const handleClickOutside = event =>
        (multiselect
            ? rootRef.current && !rootRef.current.contains(event.target)
            : inputWrapperRef.current && !inputWrapperRef.current.contains(event.target)) && setIsExpanded(false)

    const toggleSelectAll = () => isSelectAllVisible && setIsSelectedAll(wasSelected => !wasSelected)

    const getMultiselectValue = value =>
        !isSelectedAll ? value.map(({ label }) => label).join(", ") : allSelectedValueLabel

    const handleSetWrittenValue = value => setWrittenValue(Array.isArray(value) ? getMultiselectValue(value) : value)

    const debouncedFetch = (func, time) => {
        clearTimeout(debounceRef.current)
        debounceRef.current = setTimeout(func, time)
    }

    const handleOnChangeInput = (event, isMultiselectValue) => {
        const inputValue = event.target.value
        onInputChange && onInputChange(inputValue)
        const getFilteredValues = query => values.filter(v => v.label.toLowerCase().includes(query.toLowerCase()))

        isMultiselectValue ? setMultiselectExtraInputValue(inputValue) : handleSetWrittenValue(inputValue)
        setShouldFetchAll(false)
        withoutFetch
            ? setInnerValues(getFilteredValues(inputValue))
            : debouncedFetch(() => fetchValues(inputValue, 1), 250)
    }

    const handleSetValue = newValue => {
        const newMultiselectValue = multiselect
            ? value.some(v => v.id === newValue.id)
                ? value.filter(v => v.id !== newValue.id)
                : [...value, newValue]
            : null

        const newMultiselectValueIfIsSelectedAll = isSelectedAll ? [newValue] : newMultiselectValue

        isSelectedAll && toggleSelectAll()
        setValue(multiselect ? newMultiselectValueIfIsSelectedAll : newValue)
        multiselectExtraInputRef.current && multiselectExtraInputRef.current.focus()

        if (!multiselect) {
            toggleList()
        }
    }

    const handleSelectAll = () => {
        isSelectedAll ? setValue([]) : setValue(values)
        toggleSelectAll()
    }

    const resetValue = () => {
        setValue(multiselect ? [] : { value: "", label: "" })
        onInputChange && onInputChange("")
        setWrittenValue("")
    }

    const expandList = () => setIsExpanded(true)
    const collapseList = () => setIsExpanded(false)
    const toggleList = () => setIsExpanded(wasExpanded => !wasExpanded)

    const handleClickInput = () => (withoutSearch && isExpanded ? collapseList() : expandList())

    const handleClearButton = () => {
        resetValue()
        resetValues()
        multiselect && setMultiselectExtraInputValue("")
        isSelectedAll && toggleSelectAll()
        handleClear && handleClear()
    }

    const handleLoadMoreValues = () => {
        const nextPage = pagination.current_page + 1
        fetchValues(shouldFetchAll ? "" : writtenValue, nextPage)
    }

    const shouldScrollList = () => {
        const listElement = listRef.current

        const listChildHeight = 27
        const childrenInBox = isSelectAllVisible ? 5 : 7
        const currentChildTop = listCursor * listChildHeight

        const listHeight = listChildHeight * childrenInBox
        const listScroll = listElement.scrollTop

        const relativeTopPosition = currentChildTop - listScroll
        const shouldScrollTop = !!listCursor && relativeTopPosition < listChildHeight

        const relativeBottomPosition = listScroll + listHeight - currentChildTop
        const shouldScrollBottom = listCursor < innerValues.length - 1 && relativeBottomPosition < 2 * listChildHeight

        const topPosition = currentChildTop - listChildHeight
        const bottomPosition = currentChildTop - listHeight + 2 * listChildHeight

        return { top: [shouldScrollTop, topPosition], bottom: [shouldScrollBottom, bottomPosition] }
    }

    const scrollList = direction => {
        const listElement = listRef.current

        if (!listElement) {
            return
        }

        const scrollData = shouldScrollList()[direction]
        scrollData[0] &&
            listElement &&
            listElement.scrollTo &&
            listElement.scrollTo({ behavior: "smooth", top: scrollData[1] })
    }

    const navigateListWithKeys = event => {
        const { keyCode, target } = event
        const up = 38
        const down = 40
        const enter = 13
        const esc = 27

        if (keyCode === up && listCursor > (isSelectAllVisible ? -1 : 0)) {
            setListCursor(prevListCursor => prevListCursor - 1)
            scrollList("top")
        }

        if (keyCode === down && listCursor < innerValues.length - 1) {
            setListCursor(prevListCursor => prevListCursor + 1)
            scrollList("bottom")
        }

        if (keyCode === enter && listCursor > -1) {
            const currentItem = innerValues.find((_, index) => index === listCursor)
            if (!currentItem && additionalButtonAction) {
                additionalButtonAction()
                if (!multiselect) {
                    toggleList()
                }
            } else {
                !!currentItem && handleSetValue(currentItem)
            }
            !multiselect && !!currentItem && target.blur()
        }

        if (keyCode === esc) {
            target.blur()
            setIsExpanded(false)
        }
    }

    const isItemChosen = item =>
        multiselect ? !isSelectedAll && value.some(v => item.id === v.id) : value === item.label

    const hasMorePages = !withoutFetch && pagination.current_page < pagination.last_page
    const isValue = Array.isArray(value) ? !!value.length : !!value
    const areListElements = (innerValues && innerValues.length) || hasMorePages
    const isExtraInputVisible = !withoutSearch && multiselect && isValue
    const formattedLabel = (
        <div>
            {label} {isRequired && <span>*</span>}:
        </div>
    )

    const readOnly = isValue || withoutSearch
    const withSticker = renderSticker && readOnly && !multiselect
    const stickerWidth = _get(stickerRef, "current.clientWidth")
    const stickerInputStyle = stickerWidth
        ? { maxWidth: `calc(100% - ${RIGHT_INPUT_PADDING_IN_PIXELS + stickerWidth}px)` }
        : undefined

    const renderInput = () => (
        <div className={cx("inputWrapper", { withSticker })} ref={inputWrapperRef}>
            <input
                className={cx("input")}
                name={name}
                type={type}
                value={writtenValue || ""}
                placeholder={placeholder}
                onChange={handleOnChangeInput}
                onClick={handleClickInput}
                readOnly={readOnly}
                autoComplete="off"
                style={stickerInputStyle}
            />
            {withSticker && (
                <div className={cx("stickerWrapper")}>
                    <div className={cx("stickerSpacer")} style={stickerInputStyle}>
                        {writtenValue}
                    </div>
                    <div ref={stickerRef}>{renderSticker && renderSticker()}</div>
                </div>
            )}
            {showLoadingIcon && <SnakeLoader customStyles={loaderStyles} />}
            {!isBlocked && (
                <InputButton
                    isExpanded={isExpanded}
                    isClearBlocked={isClearBlocked}
                    isValue={!!writtenValue}
                    onClear={handleClearButton}
                    onToggle={toggleList}
                />
            )}
        </div>
    )

    const renderList = () =>
        !isBlocked &&
        isExpanded && (
            <List
                isValue={isValue}
                value={writtenValue}
                appendValueToBtnLabel={appendValueToBtnLabel}
                valueMaxLength={valueMaxLength}
                disabledBtn={disableBtnForDuplicateLabel && (values || []).some(item => item.label === writtenValue)}
                disableBtnForEmptyValue={disableBtnForEmptyValue}
                hasMorePages={hasMorePages}
                loadMore={handleLoadMoreValues}
                ref={listRef}
                withoutFetch={withoutFetch}
                isListOnTop={isListOnTop}
                additionalButtonIcon={additionalButtonIcon}
                additionalButtonLabel={additionalButtonLabel}
                additionalButtonAction={additionalButtonAction}
                extraInput={
                    isExtraInputVisible && (
                        <input
                            ref={multiselectExtraInputRef}
                            className={cx("input", "multiselect")}
                            type={type}
                            value={multiselectExtraInputValue}
                            onChange={event => handleOnChangeInput(event, true)}
                            placeholder={placeholder}
                        />
                    )
                }
            >
                {areListElements ? (
                    <Fragment>
                        {isSelectAllVisible && (
                            <ListItem
                                multiselect
                                item={{ label: selectAllLabel }}
                                listValueMaxLength={listValueMaxLength}
                                active={listCursor === -1}
                                chosen={isSelectedAll}
                                setValue={handleSelectAll}
                                setListCursor={() => setListCursor(-1)}
                                customStyles={selectAllStyles}
                            />
                        )}
                        {innerValues.map((item, index) => (
                            <ListItem
                                key={index}
                                multiselect={multiselect}
                                listValueMaxLength={listValueMaxLength}
                                item={item}
                                active={index === listCursor}
                                chosen={isItemChosen(item)}
                                setValue={() => handleSetValue(item)}
                                setListCursor={() => setListCursor(index)}
                            />
                        ))}
                    </Fragment>
                ) : (
                    <EmptyList label={emptyListLabel} />
                )}
            </List>
        )

    return (
        <div
            className={cx("root", {
                isValue,
                isExpanded,
                isError: error && !isExpanded,
                isBlocked: isClearBlocked || isBlocked,
                isDisabled,
                isListOnTop
            })}
            ref={rootRef}
            onKeyDown={navigateListWithKeys}
        >
            {label && <label htmlFor={name}>{formattedLabel}</label>}
            <div className={cx("border")}>
                {renderInput()}
                {renderList()}
            </div>
            {error && <ErrorLabel error={error} customStyles={customErrorStyles} />}
            {handleScrollToError && handleScrollToError(rootRef.current)}
        </div>
    )
}

SelectWithSearch.defaultProps = {
    value: "",
    type: "text",
    placeholder: "- Please select -",
    emptyListLabel: "Empty list",
    allSelectedValueLabel: "All selected",
    selectAllLabel: "Select all",
    showSelectAllLabel: true,
    additionalButtonIcon: addIcon,
    additionalButtonLabel: "Create new",
    appendValueToBtnLabel: false,
    isListOnTop: false,
    pagination: {
        current_page: 0,
        last_page: 1
    }
}

export const valuesElementShape = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    secondLabel: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
})

export const selectWithSearchPropTypes = {
    multiselect: PropTypes.bool,
    withoutFetch: PropTypes.bool,
    withoutSearch: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    setValue: PropTypes.func.isRequired, // (multiselect ? Array<{ value: number | string | object, label: string }> : { value: number | string | object, label: string }) => {}
    onInputChange: PropTypes.func,
    placeholder: PropTypes.string,
    isListOnTop: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(valuesElementShape)]),
    isBlocked: PropTypes.bool,
    showLoadingIcon: PropTypes.bool,
    isClearBlocked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    handleScrollToError: PropTypes.func,
    handleClear: PropTypes.func,
    values: PropTypes.arrayOf(valuesElementShape).isRequired,
    fetchValues: PropTypes.func, // (query: string, page: number) => {}
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    pagination: PropTypes.shape({
        current_page: PropTypes.number,
        last_page: PropTypes.number
    }),
    emptyListLabel: PropTypes.string,
    allSelectedValueLabel: PropTypes.string,
    selectAllLabel: PropTypes.string,
    showSelectAllLabel: PropTypes.bool,
    additionalButtonIcon: PropTypes.node,
    additionalButtonLabel: PropTypes.string,
    additionalButtonAction: PropTypes.func,
    appendValueToBtnLabel: PropTypes.bool
}

SelectWithSearch.propTypes = selectWithSearchPropTypes

export default withStyles(SelectWithSearch, styles)
