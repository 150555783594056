import React, { useMemo } from "react"
import PropTypes from "prop-types"

import { H2, Checkbox, SnakeLoader } from "@butterfly-frontend/ui"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"

import styles from "./WarehousesSection.module.css"

const WarehousesSection = ({ form }) => {
    const { data: warehouses, fetchStatus } = useWarehouseList()

    const userWarehouseIds = useMemo(() => form.values.warehouses.map(({ id }) => id), [form.values.warehouses])

    return !fetchStatus.isLoaded ? (
        <SnakeLoader />
    ) : (
        <div>
            <H2 classes={{ h2: styles.header }}>Warehouses</H2>
            <div className={styles.warehousesSelectSection}>
                {warehouses.map(warehouse => {
                    const isChecked = userWarehouseIds.includes(warehouse.id)

                    return (
                        <Checkbox
                            id={warehouse.id}
                            key={warehouse.id}
                            label={warehouse.name}
                            checked={isChecked}
                            onChange={id =>
                                form.setFieldValue(
                                    "warehouses",
                                    isChecked
                                        ? form.values.warehouses.filter(warehouse => warehouse.id !== id)
                                        : [...form.values.warehouses, { id }]
                                )
                            }
                            classes={{ checkbox: styles.checkbox, label: styles.checkboxLabel }}
                        />
                    )
                })}
            </div>
        </div>
    )
}

WarehousesSection.propTypes = {
    form: PropTypes.shape({
        warehouses: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired
            })
        ).isRequired
    }).isRequired
}

export default WarehousesSection
