import React, { useState, useMemo, useEffect } from "react"
import ReactSVG from "react-svg"
import PropTypes from "prop-types"
import debounce from "lodash.debounce"

import fetch from "helpers/fetch"
import useStyles from "helpers/getCxFromStyles"
import { Modal, SearchBar } from "ui"
import Loader from "components/Reusable/Loader"
import { List } from "./components"

import styles from "./ChooseSelectionsModal.css"
import modalStyles from "./overrides/modalStyles.css"
import modalBodyStyles from "./overrides/modalBodyStyles.css"
import loaderStyles from "./overrides/Loader.css"
import searchBarStyles from "./overrides/SearchBar.css"

import addIcon from "assets/add.svg"
import selectionIcon from "assets/ico-lightbox-all.svg"

let debounceFilterSearchPhrase

const ChooseSelectionsModal = props => {
    const cx = useStyles(styles)
    const {
        isOpen,
        onClose,
        handleCreateNewSelection,
        activeSelectionId,
        isVisibleActiveSelection,
        loadingSelectionId,
        title,
        handleSelect
    } = props

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const [hasMore, setHasMore] = useState(false)
    const [selections, setSelelections] = useState([])
    const [query, setQuery] = useState("")
    const [currentPage, setCurrentPage] = useState(1)

    useEffect(() => {
        debounceFilterSearchPhrase = debounce(setQuery, 350)

        return () => {
            debounceFilterSearchPhrase.cancel()
        }
    }, [])

    useEffect(() => {
        fetchSelections(1)
    }, [query])

    const fetchSelections = page => {
        page > 1 ? setIsLoadingMore(true) : setIsLoading(true)
        fetch
            .get(`/selections?query=${query}&page=${page}`)
            .then(result => {
                setHasMore(result.meta.current_page < result.meta.last_page)
                setCurrentPage(page)
                page > 1 ? setSelelections([...selections, ...result.data]) : setSelelections(result.data)
            })
            .finally(() => (page > 1 ? setIsLoadingMore(false) : setIsLoading(false)))
    }

    const [privateSelections, publicSelections] = useMemo(
        () =>
            selections
                .filter(({ id }) => !(id === activeSelectionId && !isVisibleActiveSelection))
                .reduce(
                    (acc, selection) => {
                        return selection.type === "private"
                            ? [[...acc[0], selection], acc[1]]
                            : [acc[0], [...acc[1], selection]]
                    },
                    [[], []]
                ),
        [selections]
    )

    const handleLoadMore = page => !isLoadingMore && fetchSelections(currentPage + 1)

    return (
        <Modal isOpen={isOpen} closeModal={onClose} customStyles={modalStyles} preventClickOutside={true}>
            <Modal.Header>{title}</Modal.Header>
            <Modal.Body customStyles={modalBodyStyles}>
                <SearchBar
                    placeholder="Search..."
                    handleSearch={debounceFilterSearchPhrase}
                    customStyles={searchBarStyles}
                />
                {isLoading && (
                    <div className={cx("loader")}>
                        <Loader customStyles={loaderStyles} />
                    </div>
                )}
                {!isLoading &&
                    (!!selections.length ? (
                        <List
                            privateSelections={privateSelections}
                            publicSelections={publicSelections}
                            chooseSelection={handleSelect}
                            isResultSearch={query}
                            hasMore={hasMore}
                            handleLoadMore={handleLoadMore}
                            activeSelectionId={activeSelectionId}
                            isVisibleActiveSelection={isVisibleActiveSelection}
                            loadingSelectionId={loadingSelectionId}
                        />
                    ) : (
                        <div className={cx("emptyList")}>
                            <ReactSVG src={selectionIcon} className={cx("emptyListIcon")} />
                            <h3>No selection found.</h3>
                            <span>Please check the spelling or create new selection.</span>
                        </div>
                    ))}
                <button type="button" className={cx("createSelection")} onClick={handleCreateNewSelection}>
                    <img src={addIcon} alt="addImage" />
                    Create new selection
                </button>
            </Modal.Body>
        </Modal>
    )
}

ChooseSelectionsModal.propTypes = {
    title: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    selectedSelectionIds: PropTypes.arrayOf(PropTypes.number),
    onClose: PropTypes.func.isRequired,
    handleCreateNewSelection: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    isVisibleActiveSelection: PropTypes.bool,
    activeSelectionId: PropTypes.number,
    loadingSelectionId: PropTypes.number
}

ChooseSelectionsModal.defaultProps = {
    loadingSelectionId: null
}

export default ChooseSelectionsModal
