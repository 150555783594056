import React, { Fragment, useState, useRef } from "react"
import { withRouter } from "react-router-dom"
import ReactSVG from "react-svg"

import withStyles from "HOC/withStyles"
import { Checkbox, DropList } from "ui"
import DiscountLabel from "../DiscountLabel"
import SoldLabel from "../SoldLabel"

import styles from "./ListItem.css"
import stylesCheckbox from "../../overrides/Checkbox.css"
import stylesDropList from "./overrides/droplist.css"

import selectionIcon from "assets/ico-lightbox-add.svg"
import AddToSelectionButtonContainer from "modules/SelectionsWidgetModule/components/AddToSelectionButtonContainer"

const ListItem = props => {
    const {
        cx,
        history,
        product,
        isChecked,
        checkProduct,
        children,
        deleteProduct,
        editProduct,
        renderPrice,
        index
    } = props
    const [isHoverImage, setIsHoverImage] = useState(false)
    const timeoutId = useRef(0)

    if (typeof children === "function") {
        return children(props)
    }

    const id = product.id
    const isSold = !product.reorderable && !product.quantity
    const hasDiscount = !isSold && !!product.discount

    const onImageMouseEnter = () => {
        timeoutId.current = setTimeout(() => {
            setIsHoverImage(true)
        }, 300)
    }

    const onImageMouseLeave = () => {
        clearTimeout(timeoutId.current)
        setIsHoverImage(false)
    }

    return (
        <div
            className={cx("listItem")}
            onClick={() => {
                history.push("/products/" + product.id)
            }}
        >
            <div className={cx("leftContainer")}>
                <div className={cx("checkboxContainer")} onClick={e => e.stopPropagation()}>
                    <Checkbox
                        checked={isChecked}
                        onChange={() => checkProduct(product, !isChecked)}
                        onClick={e => e.stopPropagation()}
                        customStyles={stylesCheckbox}
                    />
                </div>
                <div className={cx("photoContainer")}>
                    <img
                        src={props.getImagePath()}
                        alt=""
                        className={cx("photo")}
                        onMouseEnter={onImageMouseEnter}
                        onMouseLeave={onImageMouseLeave}
                    />
                    <div className={cx("hoverImageContainer", { active: isHoverImage, isUp: index > 15 })}>
                        <img src={props.getImagePath()} alt="" />
                    </div>
                </div>
                <div className={cx("nameContainer")}>
                    <div className={cx("name")}>
                        <span>{product.name}</span>
                    </div>
                </div>
            </div>
            <div className={cx("brandContainer")}>
                <span>{props.displayBrand()}</span>
            </div>
            <div className={cx("inStockContainer")}>
                <span>{product.quantity || ""}</span>
            </div>
            <div className={cx("priceContainer")}>
                <div>
                    {renderPrice(true)}
                    {isSold && <SoldLabel customStyles={styles} />}
                </div>
                {hasDiscount && (
                    <Fragment>
                        <span className={cx("normalPrice")}>{renderPrice()}</span>
                        <DiscountLabel discount={product.discount} customStyles={styles} />
                    </Fragment>
                )}
            </div>
            {renderDropList()}
        </div>
    )

    function renderDropList(room) {
        return (
            <div className={cx("moreActions")} onClick={e => e.stopPropagation()}>
                <AddToSelectionButtonContainer product={product}>
                    {({ handleClick, isInSelection, lock }) => (
                        <div
                            className={cx("selectionsButton", { active: isInSelection })}
                            onClick={() => handleClick()}
                        >
                            <ReactSVG src={selectionIcon} className={cx("selectionsButtonIcon")} />
                        </div>
                    )}
                </AddToSelectionButtonContainer>
                <div className={cx("devider")} />
                <DropList
                    customStyles={stylesDropList}
                    trigger={
                        <div className={cx("moreActionsButton")}>
                            <span />
                            <span />
                            <span />
                        </div>
                    }
                >
                    <DropList.List customStyles={stylesDropList}>
                        <DropList.Item onClick={() => editProduct(id)}>Edit</DropList.Item>
                        {!product.deleted_at && (
                            <Fragment>
                                <DropList.Divider />
                                <DropList.Item className="danger" onClick={() => deleteProduct(id)}>
                                    Delete
                                </DropList.Item>
                            </Fragment>
                        )}
                    </DropList.List>
                </DropList>
            </div>
        )
    }
}

export default withStyles(withRouter(ListItem), styles)
