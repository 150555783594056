import React, { useState, useEffect, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"

import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"
import List from "./List"
import Modal from "./components/modal"
import DeleteModal from "./components/deleteModal"
import useDebounce from "hooks/useDebounce"
import { SORT_OPTIONS } from "./configs"

import * as serviceActions from "../../actions"
import withStyles from "HOC/withStyles"
import styles from "./styles/list.css"
import useNonmutableState from "hooks/useNonmutableState"
import { showErrorNotification, showSuccessNotification } from "actions/notification"

const ListContainer = props => {
    const dispatch = useDispatch()
    const services = useSelector(state => state.db.assistances.list)
    const service = useSelector(state => state.db.assistances.current)

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [isModalFetching, setIsModalFetching] = useState(false)
    const [deleteModalIsOpen, setdDeleteModalIsOpen] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [filterInitialSearchPhrase, filterInitialSearchPhraseRef, filterSetInitalSearchPhrase] = useNonmutableState(
        ""
    )
    const [filterSortingIndex, setFilterSortingIndex] = useState(0)
    const [deleteModalParams, setDeleteModalParams] = useState({ id: -1, name: "" })
    const [error, setError] = useState(null)
    const [emptyFilters, setEmptyFilters] = useState(false)

    useEffect(() => {
        fetchServices()
    }, [])

    useDebounce(
        () => {
            fetchServices()
        },
        400,
        [searchValue, filterSortingIndex]
    )

    useEffect(() => {
        setEmptyFilters(searchValue.length === 0)
    }, [services])

    return (
        <Fragment>
            <List
                services={services}
                emptyFilters={emptyFilters}
                openEditModal={openEditModal}
                filterSortingIndex={filterSortingIndex}
                filterSortingHandleChange={filterSortingHandleChange}
                setCreateModalIsOpen={setCreateModalIsOpen}
                openDeleteModal={openDeleteModal}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handleClearAll={clearAll}
                filterInitialSearchPhrase={filterInitialSearchPhrase}
                filterInitialSearchPhraseRef={filterInitialSearchPhraseRef}
                {...props}
            />
            {renderCreateModal()}
            {renderEditModal()}
            {renderDeleteModal()}
        </Fragment>
    )

    function clearAll() {
        setSearchValue("")
        filterSetInitalSearchPhrase("")
    }

    function openDeleteModal(name, id) {
        setDeleteModalParams({ id, name })
        setdDeleteModalIsOpen(true)
    }

    function openEditModal(id) {
        fetchServiceById(id).then(() => setEditModalIsOpen(true))
    }

    function closeEditModal() {
        setError(null)
        setEditModalIsOpen(false)
    }

    function closeCreateModal() {
        setError(null)
        setCreateModalIsOpen(false)
    }

    function getCurrentParams() {
        return {
            query: searchValue,
            ...SORT_OPTIONS[filterSortingIndex].forApi
        }
    }

    function filterSortingHandleChange(index) {
        setFilterSortingIndex(index || 0)
    }

    function fetchServiceById(id) {
        return dispatch(serviceActions.getServiceById(id))
    }

    function fetchServices() {
        return dispatch(serviceActions.getServices(getCurrentParams()))
    }

    function deleteService(id) {
        setIsModalFetching(true)
        return serviceActions
            .deleteServiceById(id)
            .then(() => {
                dispatch(showSuccessNotification())
                setdDeleteModalIsOpen(false)
                fetchServices()
            })
            .catch(() => {
                dispatch(showErrorNotification())
                setdDeleteModalIsOpen(false)
                fetchServices()
            })
            .finally(() => setIsModalFetching(false))
    }

    function createNewService(body) {
        setIsModalFetching(true)
        return serviceActions
            .createService(body)
            .then(() => {
                dispatch(showSuccessNotification())
                setCreateModalIsOpen(false)
                fetchServices()
            })
            .catch(err => {
                setError(err.errors)
            })
            .finally(() => {
                setIsModalFetching(false)
            })
    }

    function editService(id, body) {
        setIsModalFetching(true)
        return serviceActions
            .editService(id, body)
            .then(() => {
                dispatch(showSuccessNotification())
                setEditModalIsOpen(false)
                fetchServices()
            })
            .catch(err => {
                setError(err.errors)
            })
            .finally(() => setIsModalFetching(false))
    }

    function renderCreateModal() {
        return (
            <Modal
                title={"New service"}
                saveButtonLabel={"Create"}
                submit={data => createNewService(data)}
                service={{ name: "", description: "", price: "" }}
                isFetching={isModalFetching}
                isOpen={createModalIsOpen}
                error={error}
                setError={setError}
                handleClose={closeCreateModal}
            />
        )
    }

    function renderEditModal() {
        return (
            <Modal
                title={"Edit service"}
                saveButtonLabel={"Save"}
                submit={data => editService(data.id, data)}
                isFetching={isModalFetching}
                isLoading={service.isLoading}
                isOpen={editModalIsOpen}
                service={service.data}
                error={error}
                setError={setError}
                handleClose={closeEditModal}
            />
        )
    }

    function renderDeleteModal() {
        return (
            <DeleteModal
                isOpen={deleteModalIsOpen}
                serviceName={deleteModalParams.name}
                isLoading={isModalFetching}
                onCancel={() => setdDeleteModalIsOpen(false)}
                onSubmit={() => deleteService(deleteModalParams.id)}
            />
        )
    }
}

export default withStyles(withPermissions(ListContainer, PERMISSIONS.context.PRODUCTS), styles)
