import React from "react"
import CollectionList from "modules/ContentModule/components/CollectionList"

const List = () => {
    return (
        <CollectionList
            collectionLabel="Inspiration"
            collectionLabelPlural="Inspirations"
            basePath="/content/inspirations"
            apiUrl="/inspirations"
            collectionModel="inspirations"
            canChangeLayout
        />
    )
}

export default List
