import { INPUT_TYPES } from "constants/index"

export default {
    name: "Source",
    endpoint: "/sources",
    fields: [
        {
            name: "name",
            label: "Name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        }
    ]
}
