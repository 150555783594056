import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./IncrementalCounter.css"

const IncrementalCounter = ({ quantity, handleChange, label, isDisabled = false }) => {
    const onValueIncrement = () => {
        const nextValue = quantity + 1
        handleChange(nextValue)
    }

    return (
        <div className={classNames(styles.root, { [styles.disabled]: isDisabled })}>
            {label && <span className={styles.label}>{label}</span>}
            <div className={styles.container}>
                <div className={styles.value}>
                    <span>{quantity.toString()}</span>
                </div>
                <div className={styles.plus} onClick={isDisabled ? undefined : onValueIncrement} />
            </div>
        </div>
    )
}

IncrementalCounter.propTypes = {
    quantity: PropTypes.number.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    isDisabled: PropTypes.bool
}

export default IncrementalCounter
