const handleGallerySetup = width => {
    let colsNumber = 3
    const tileWidth = 191
    let config = {
        COLS: colsNumber,
        WIDTH: colsNumber * tileWidth
    }

    if (width > 936) {
        config.COLS = colsNumber + 1
    }

    if (width > 1126) {
        config.COLS = colsNumber + 2
    }

    if (width > 1199) {
        config.COLS = colsNumber + 1
    }

    if (width > 1262) {
        const addColParam = Math.ceil((width - 1262) / 191)
        config.COLS = colsNumber + 1 + addColParam
    }

    config.WIDTH = config.COLS * tileWidth

    return config
}

export default handleGallerySetup
