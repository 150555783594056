import React, { Fragment, useEffect, useState, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import moment from "moment"
import { stringify, parse } from "qs"

import { CLEAR_SPREADSHEET_ID, PERMISSIONS } from "constants/index"
import { objToQueryString } from "helpers/urls"
import useDebounce from "hooks/useDebounce"
import { scrollToTop } from "helpers/scrollTo"
import * as productActions from "actions/product"
import * as notificationActions from "actions/notification"
import withPermissions from "HOC/withPermissions"
import { SORT_OPTIONS } from "./configs"
import Page from "./Page"
import { INITIAL_STATE } from "reducers/db/products/filters"
import isEqual from "lodash/isEqual"
import Widget from "modules/SelectionsWidgetModule/components/WidgetContainer"
import { downloadBlobFile, showPrintWindow } from "actions/print"

const DEFAULT_PER_PAGE = 20

const PageContainer = props => {
    const { canAdd } = props

    const [isFirstTime, setIsFirstTime] = useState(true)
    const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE)
    const [fastReviewIsSaving, fastReviewSetIsSaving] = useState(false)
    const [fastReviewIsLoading, fastReviewSetIsLoading] = useState(false)
    const [checkedProducts, setCheckedProducts] = useState([])
    const [hasRestoreFilters, setHasRestoreFilters] = useState(false)
    const [isExporting, setIsExporting] = useState(false)
    const [isPrinting, setIsPrinting] = useState(false)
    const [toDeleteProduct, setToDeleteProduct] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [idProductForChooseSelectionsModal, setIdProductForChooseSelectionsModal] = useState(null)
    const [isSendableModalOpenFor, setIsSendableModalOpenFor] = useState("")
    const [isOpenAddToProjectModal, setIsOpenAddToProjectModal] = useState(false)

    const [multiProductDeleteState, setMultiProductDeleteState] = useState({
        isOpen: false,
        isSaving: false,
        productIds: []
    })

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    const products = useSelector(state => state.db.products.list)
    const filterSearchPhrase = useSelector(state => state.db.products.filters.query) || ""
    const filters = useSelector(state => state.db.products.filters)
    const dateRange = useSelector(state => state.db.products.filters.dateRange)
    const filterSortingIndex = useSelector(state => state.db.products.filters.sort)
    const currentPage = useSelector(state => state.db.products.filters.pagination)
    const fastReviewFileData = useSelector(state => state.db.products.createFastReview.data.data)
    const fastReviewIsModalOpen = useSelector(state => state.db.products.createFastReview.isShown)
    const brands = useSelector(state => state.db.products.filters.filters.brands)
    const categories = useSelector(state => state.db.products.filters.categories)
    const additionalFilters = useSelector(state => state.db.products.filters.filters)

    const priceRange = useSelector(state => state.db.products.filters.filters.priceRange)
    const discount = useSelector(state => state.db.products.filters.filters.discount)
    const manufacturingTime = useSelector(state => state.db.products.filters.filters.manufacturingTime)
    const productionYear = useSelector(state => state.db.products.filters.filters.productionYear)
    const deliveringTime = useSelector(state => state.db.products.filters.filters.deliveringTime)
    const availability = useSelector(state => state.db.products.filters.filters.availability)
    const reorderable = useSelector(state => state.db.products.filters.filters.reorderable)
    const verification = useSelector(state => state.db.products.filters.filters.verification)
    const availableOnWeb = useSelector(state => state.db.products.filters.filters.availableOnWeb)
    const withoutPhotos = useSelector(state => state.db.products.filters.filters.withoutPhotos)
    const deleted = useSelector(state => state.db.products.filters.filters.deleted)
    const clearance = useSelector(state => state.db.products.filters.filters.clearance)
    const locations = useSelector(state => state.db.products.filters.filters.locations)
    const selections = useSelector(state => state.db.products.filters.filters.selections)
    const userId = useSelector(state => state.loginStore.userId)

    const topBarFiltersUsed =
        (dateRange.from && dateRange.from.length > 0) ||
        (dateRange.to && dateRange.to.length > 0) ||
        (filterSearchPhrase && filterSearchPhrase.length > 0) ||
        (categories && categories.length > 0)

    const sideBarFilterUsed = !isEqual(INITIAL_STATE.filters, additionalFilters)

    const setSalesToEnd = categories => {
        const salesIndex = categories.findIndex(cat => cat.name === "Sale")
        return salesIndex !== -1 ? categories.push(categories.splice(salesIndex, 1).pop()) && categories : categories
    }

    const productCount = checkedProducts.length
    const hasCount = productCount > 0
    const filtersUsed = sideBarFilterUsed || topBarFiltersUsed
    const filtersArray = Object.keys(additionalFilters).map(key => additionalFilters[key])

    const printAndSendItems = useCallback(
        func => [
            { action: () => func("full"), text: "Full product preview" },
            { action: () => func("full_with_location"), text: "Full with location" },
            { action: () => func("product_name_only"), text: "Product name only" },
            { action: () => func("product_name_and_finish"), text: "Product name & finish" }
        ],
        []
    )

    const dropDownItems = [
        { action: () => fastReviewHandleCreate(), text: "Edit", disabled: !canAdd() || !(hasCount || filtersUsed) },
        { action: () => handleMultiDelete(), text: "Delete", disabled: !hasCount, styles: ["orange"] }
    ]

    useDebounce(
        () => {
            if (!isFirstTime) {
                currentPage !== 1 ? dispatch(productActions.changePagination(1)) : fetchProducts()
            }
        },
        400,
        [filterSearchPhrase, dateRange, categories, ...filtersArray]
    )

    useEffect(() => {
        if (!isFirstTime) {
            currentPage !== 1 ? dispatch(productActions.changePagination(1)) : fetchProducts(true)
        }
    }, [filterSortingIndex, perPage])

    useEffect(() => {
        if (!isFirstTime) {
            fetchProducts(currentPage !== 1)
        }
    }, [currentPage])

    useEffect(() => {
        scrollToTop({ smooth: false })
        restoreFiltersFromQueryString()

        return () => {
            dispatch({ type: "RESET_PRODUCTS" })
        }
    }, [])

    useEffect(() => {
        hasRestoreFilters && fetchProducts()
    }, [hasRestoreFilters])

    useEffect(() => {
        if (products && products.meta) {
            const meta = products.meta

            if (meta.total > 0 && !isFirstTime) {
                if (meta.current_page > meta.last_page) {
                    dispatch(productActions.changePagination(meta.last_page))
                } else if (meta.current_page < 1) {
                    dispatch(productActions.changePagination(1))
                }
            }
        }
    }, [products, isFirstTime])

    useEffect(() => {
        if (products && products.meta && products.isLoaded) {
            const meta = products.meta

            if (meta.aggregates) {
                dispatch(
                    productActions.updateProductsAggregates({
                        categories: setSalesToEnd(meta.aggregates.categories),
                        brands: meta.aggregates.brands,
                        locations: meta.aggregates.locations,
                        selections:
                            meta.aggregates.selections && meta.aggregates.selections.length > 0
                                ? [
                                      ...meta.aggregates.selections.filter(select => select.type === "private"),
                                      ...meta.aggregates.selections.filter(select => select.type !== "private")
                                  ]
                                : []
                    })
                )
            }
        }
    }, [products])

    const fetchProducts = withoutAggregates => {
        return dispatch(
            productActions.getProducts({ ...getCurrentParams(), without_aggregates: withoutAggregates })
        ).finally(() => {
            setIsFirstTime(false)
            setQueryString()
        })
    }

    const deepCleaning = obj =>
        Object.keys(obj).reduce(
            (acc, key) => ({
                ...acc,
                [key]:
                    typeof obj[key] !== "boolean"
                        ? (typeof obj[key] === "object" ? deepCleaning(obj[key]) : obj[key]) || undefined
                        : obj[key]
            }),
            {}
        )

    const setQueryString = () => {
        const queryString = stringify(deepCleaning(filters))
        history.replace(`/products?${queryString}`)
    }

    return (
        <Fragment>
            <Page
                {...props}
                currentPage={currentPage}
                perPage={perPage}
                setPerPage={setPerPage}
                dateRange={dateRange}
                dateRangeHandleChange={dateRangeHandleChange}
                checkedProducts={checkedProducts}
                setCheckedProducts={setCheckedProducts}
                isFirstTime={isFirstTime}
                products={products}
                filterSearchPhrase={filterSearchPhrase}
                filterSortingIndex={filterSortingIndex}
                filterSortingHandleChange={filterSortingHandleChange}
                filterSearchPhraseHandleChange={filterSearchPhraseHandleChange}
                fastReviewFileData={fastReviewFileData}
                fastReviewIsModalOpen={fastReviewIsModalOpen}
                fastReviewIsSaving={fastReviewIsSaving}
                fastReviewHandleHideModal={fastReviewHandleHideModal}
                fastReviewHandleCreate={fastReviewHandleCreate}
                fastReviewHandleProductsUpdate={fastReviewHandleProductsUpdate}
                handleExport={handleExport}
                handleMultiDelete={handleMultiDelete}
                multiProductDeleteState={multiProductDeleteState}
                toggleMultiProductDelete={toggleMultiProductDelete}
                handleMultiProductDelete={handleMultiProductDelete}
                fastReviewIsLoading={fastReviewIsLoading}
                isExporting={isExporting}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleAddToSelection={handleAddToSelection}
                setToDeleteProduct={setToDeleteProduct}
                toDeleteProduct={toDeleteProduct}
                handleConfirmDelete={handleConfirmDelete}
                isDeleting={isDeleting}
                idProductForChooseSelectionsModal={idProductForChooseSelectionsModal}
                setIdProductForChooseSelectionsModal={setIdProductForChooseSelectionsModal}
                printAndSendItems={printAndSendItems}
                isSendableModalOpenFor={isSendableModalOpenFor}
                setIsSendableModalOpenFor={setIsSendableModalOpenFor}
                isPrinting={isPrinting}
                handlePrint={handlePrint}
                dropDownItems={dropDownItems}
                handleSend={handleSend}
                resetCheckedProducts={resetCheckedProducts}
                handlePrintForSendable={handlePrintForSendable}
                setIsOpenAddToProjectModal={setIsOpenAddToProjectModal}
                isOpenAddToProjectModal={isOpenAddToProjectModal}
                handleAddToProject={handleAddToProject}
                userId={userId}
            />
            <Widget />
        </Fragment>
    )

    function getCurrentParams() {
        return {
            query: filterSearchPhrase || undefined,
            created_from: dateRange.from || undefined,
            created_to: dateRange.to || undefined,
            page: currentPage,
            length: perPage || DEFAULT_PER_PAGE,
            categories: (categories && categories.slice(-1).pop()) || undefined,
            brands: (brands && brands.join(",")) || undefined,
            locations: (locations && locations.join(",")) || undefined,
            selections: (selections && selections.join(",")) || undefined,
            price_min: priceRange.leftValue || undefined,
            price_max: priceRange.rightValue || undefined,
            discount_min: discount.leftValue || undefined,
            discount_max: discount.rightValue || undefined,
            manufacturing_time_min: manufacturingTime.leftValue || undefined,
            manufacturing_time_max: manufacturingTime.rightValue || undefined,
            production_year_min: productionYear.leftValue || undefined,
            production_year_max: productionYear.rightValue || undefined,
            delivery_time_min: deliveringTime.leftValue || undefined,
            delivery_time_max: deliveringTime.rightValue || undefined,
            available: typeof availability === "boolean" ? availability : undefined,
            verified: typeof verification !== "string" ? verification : undefined,
            without_photos: withoutPhotos || undefined,
            deleted: deleted || undefined,
            available_on_web: availableOnWeb || undefined,
            availability_status: availability === "incoming" ? "incoming" : undefined,
            reorderable,
            clearance: typeof clearance !== "string" ? clearance : undefined,
            ...SORT_OPTIONS[filterSortingIndex].forApi
        }
    }

    function filterSortingHandleChange(index) {
        dispatch(productActions.setSortType(index || 0))
    }

    function filterSearchPhraseHandleChange(value) {
        dispatch(productActions.changeQuery(value))
    }

    function dateRangeHandleChange(value) {
        dispatch(productActions.changeDateRange(value))
    }

    function checkRange(obj) {
        return (
            typeof obj === "object" &&
            ((obj.leftValue && !isNaN(obj.leftValue)) || (obj.rightValue && !isNaN(obj.rightValue))) &&
            (!(obj.leftValue && obj.rightValue) || parseFloat(obj.leftValue) <= parseFloat(obj.rightValue))
        )
    }

    function restoreFiltersFromQueryString() {
        const filters = location.search ? parse(location.search.slice(1)) : {}
        const parsedFilters = {}

        const defaultRange = { leftValue: "", rightValue: "" }

        const parseBoolean = value => (value === "true" ? true : value === "false" ? false : undefined)
        const parseCheckbox = value => (value === "true" ? true : "")
        const parseRange = value =>
            value ? { leftValue: value.leftValue || "", rightValue: value.rightValue || "" } : defaultRange
        const parseReorderable = value => (value === "true" ? true : value === "false" ? false : "all")

        if (!isNaN(filters.pagination)) {
            parsedFilters.pagination = parseInt(filters.pagination, 10)
        }

        if (!isNaN(filters.sort)) {
            parsedFilters.sort = parseInt(filters.sort, 10)
        }

        if (filters.dateRange && moment(filters.dateRange.from).isValid() && moment(filters.dateRange.to).isValid()) {
            parsedFilters.dateRange = filters.dateRange
        }

        parsedFilters.categories = Array.isArray(filters.categories)
            ? filters.categories.map(category => parseInt(category, 10))
            : []

        if (filters.filters) {
            const {
                brands,
                selections,
                priceRange,
                discount,
                manufacturingTime,
                productionYear,
                deliveringTime,
                availability,
                locations,
                verification,
                withoutPhotos,
                availableOnWeb,
                reorderable,
                deleted
            } = filters.filters

            const parsedAdditionalFilters = {
                brands: Array.isArray(brands) ? brands.map(brand => parseInt(brand, 10)) : [],
                selections: Array.isArray(selections) ? selections.map(selection => parseInt(selection, 10)) : [],
                priceRange: checkRange(priceRange) ? parseRange(priceRange) : defaultRange,
                discount: checkRange(discount) ? parseRange(discount) : defaultRange,
                manufacturingTime: checkRange(manufacturingTime) ? parseRange(manufacturingTime) : defaultRange,
                productionYear: checkRange(productionYear) ? parseRange(productionYear) : defaultRange,
                deliveringTime: checkRange(deliveringTime) ? parseRange(deliveringTime) : defaultRange,
                availability: parseBoolean(availability),
                verification: parseBoolean(verification),
                withoutPhotos: parseCheckbox(withoutPhotos) || undefined,
                deleted: parseCheckbox(deleted) || undefined,
                availableOnWeb: parseCheckbox(availableOnWeb) || undefined,
                reorderable: parseReorderable(reorderable),
                locations: Array.isArray(locations) ? locations.map(loc => parseInt(loc, 10)) : []
            }

            if (Object.keys(parsedAdditionalFilters).length > 0) {
                parsedFilters.filters = parsedAdditionalFilters
            }
        }

        parsedFilters.query = filters.query

        dispatch(productActions.replaceFiltersGroup(parsedFilters))
        setHasRestoreFilters(true)
    }

    function clearSpreadsheetId() {
        return dispatch({ type: CLEAR_SPREADSHEET_ID })
    }

    function showErrorNotification(data) {
        return dispatch(notificationActions.showErrorNotification(data))
    }

    function showSuccessNotification(data) {
        return dispatch(notificationActions.showSuccessNotification(data))
    }

    function handleExport(value) {
        setIsExporting(true)
        const exportType = value.event.target.value

        const iframe = document.querySelector("iframe[data-pdf], a[data-pdf]")
        iframe && iframe.remove()

        const preparedFilters = { ...getCurrentParams(true), export: exportType }

        const method =
            checkedProducts.length === 0
                ? productActions.getProductsByFilterRAW(`&${objToQueryString(preparedFilters)}`)
                : productActions.multiSelectExport(checkedProducts.map(product => product.id), exportType)

        method
            .then(response => response.blob())
            .then(blob => {
                setIsExporting(false)
                const newBlob = new Blob([blob], { type: "application/pdf" })

                let iFrame = document.createElement("iframe")
                iFrame.name = "pdfIframe"
                iFrame.setAttribute("data-pdf", true)
                iFrame.style = "visibility: hidden;"
                iFrame.src = URL.createObjectURL(newBlob)
                document.body.appendChild(iFrame)

                let a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.setAttribute("data-pdf", true)
                a.href = iFrame.src
                a.download = "products." + exportType
                a.click()
            })
            .catch(err => {
                setIsExporting(false)
                showErrorNotification(err.message)
            })
    }

    function fastReviewHandleCreate() {
        fastReviewSetIsLoading(true)

        dispatch(
            checkedProducts.length > 0
                ? productActions.createMultiSelectFastReview({ ids: checkedProducts.map(product => product.id) })
                : productActions.createFastReview(getCurrentParams())
        )
    }

    function fastReviewHandleHideModal() {
        fastReviewSetIsLoading(false)
        fastReviewSetIsSaving(false)
        clearSpreadsheetId()
        resetCheckedProducts()
        fetchProducts()
    }

    function fastReviewHandleProductsUpdate(spreadsheetId) {
        fastReviewSetIsSaving(true)
        productActions
            .updateFastReview(spreadsheetId)
            .then(() => {
                fastReviewHandleHideModal()
                showSuccessNotification()
            })
            .catch(error => {
                dispatch(notificationActions.showErrorNotification(error.message))
                fastReviewSetIsSaving(false)
            })
    }

    function resetCheckedProducts() {
        setCheckedProducts([])
    }

    function toggleMultiProductDelete(products) {
        setMultiProductDeleteState({
            ...multiProductDeleteState,
            isOpen: !multiProductDeleteState.isOpen,
            productIds: products.map(id => parseInt(id, 10))
        })
    }

    function handleMultiDelete() {
        toggleMultiProductDelete(checkedProducts.map(product => product.id))
    }

    function handleMultiProductDelete() {
        productActions
            .multiSelectDelete(multiProductDeleteState.productIds)
            .then(response => {
                if (response.status === 204) {
                    resetCheckedProducts()
                    toggleMultiProductDelete([])
                    showSuccessNotification({ title: "Deleted" })
                    fetchProducts()
                } else {
                    showErrorNotification(response)
                }
            })
            .catch(err => showErrorNotification(err.message))
    }

    function handleAddToSelection(id) {
        setIdProductForChooseSelectionsModal(id)
    }

    function handleEdit(id) {
        history.push(`/products/${id}/edit`)
    }

    function handleDelete(id) {
        setToDeleteProduct(id)
    }

    function handleConfirmDelete(id) {
        setIsDeleting(true)

        productActions
            .deleteProduct(toDeleteProduct)
            .then(response => {
                if (response.status === 204) {
                    setTimeout(() => {
                        setIsDeleting(false)
                        setToDeleteProduct(null)
                        showSuccessNotification({ title: "Deleted" })
                        fetchProducts()
                    }, 1000)
                } else {
                    showErrorNotification(response)
                }
            })
            .catch(err => {
                setIsDeleting(false)
                showErrorNotification(err.message)
            })
    }

    function handlePrintForSendable(type) {
        return productActions.multiSelectPrint(checkedProducts.map(product => product.id), type).then(downloadBlobFile)
    }

    function handlePrint(type) {
        setIsPrinting(true)
        return productActions
            .multiSelectPrint(checkedProducts.map(product => product.id), type)
            .then(showPrintWindow)
            .finally(() => {
                setIsPrinting(false)
            })
    }

    function handleSend(type) {
        setIsSendableModalOpenFor(type)
    }

    function handleAddToProject(project) {
        productActions
            .addProductsToProject({
                productIds: checkedProducts.map(product => product.id),
                projectId: project.project_id,
                roomId: project.room_id
            })
            .then(response => {
                if (response.status === 204) {
                    showSuccessNotification({ title: "Successful added" })
                    history.push(`/projects/${project.project_id}`)
                } else {
                    showErrorNotification(response)
                }
            })
            .catch(err => {
                showErrorNotification(err.message)
            })
    }
}

export default withPermissions(PageContainer, PERMISSIONS.context.PRODUCTS)
