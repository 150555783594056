import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { KANBAN_ROW_TYPE } from "@butterfly-frontend/ui"

import { PERMISSIONS } from "constants/index"
import withPermissions from "HOC/withPermissions"
import { ShipmentKanbanRow } from "modules/WmsModule/components"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import useConsignmentNoteOptions from "modules/WmsModule/hooks/useConsignmentNoteOptions"
import { checkIfCanGenerateConsignmentNote } from "modules/WmsModule/helpers/consignmentNote"

import { SHIPMENT_LIST_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"

const ShipmentReadyForShippingRow = ({
    shipment,
    rowType,
    changeShipmentStatusToSent,
    getShipmentHref,
    isLoading,
    canEdit
}) => {
    const disabled = rowType === KANBAN_ROW_TYPE.DANGER

    const { options: boxLabelsOptions, isLoading: isBoxLabelOptionsLoading } = useBoxLabelOptions({
        ids: shipment.stock_items_ids
    })

    const { options: consignmentNoteOptions, isLoading: isConsignmentNoteOptionsLoading } = useConsignmentNoteOptions({
        ids: [shipment.id]
    })

    const actions = useMemo(() => {
        const shipAction = {
            onClick: () => changeShipmentStatusToSent(shipment),
            label: "Ship"
        }

        return [
            ...(canEdit() ? [shipAction] : []),
            ...(boxLabelsOptions || []),
            ...(checkIfCanGenerateConsignmentNote(shipment) ? consignmentNoteOptions : [])
        ]
    }, [consignmentNoteOptions, boxLabelsOptions, shipment, changeShipmentStatusToSent])

    return (
        <ShipmentKanbanRow
            rowType={rowType}
            shipment={shipment}
            actions={actions}
            isLoading={isLoading || isBoxLabelOptionsLoading || isConsignmentNoteOptionsLoading}
            disabled={disabled}
            shipmentHref={getShipmentHref(shipment)}
        />
    )
}

ShipmentReadyForShippingRow.propTypes = {
    shipment: SHIPMENT_LIST_ITEM_PROP_TYPE,
    rowType: PropTypes.oneOf(Object.values(KANBAN_ROW_TYPE)),
    changeShipmentStatusToSent: PropTypes.func.isRequired,
    getShipmentHref: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(ShipmentReadyForShippingRow, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
