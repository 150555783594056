import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"

import withStyles from "HOC/withStyles"

import { MultipleRadio } from "ui"

import Image from "./components/Image"

import styles from "./styles.css"

const Sidebar = ({ cx, form }) => {
    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <h2 className={cx("header")}>Type</h2>

                <div className={cx("type")}>
                    <Field name="type">
                        {({ field }) => (
                            <MultipleRadio
                                name={field.name}
                                checked={field.value}
                                config={[
                                    { value: "standard_vendor", label: "Standard Supplier" },
                                    { value: "custom_vendor", label: "Custom Supplier" }
                                ]}
                                handleSelect={value => form.setFieldValue(field.name, value)}
                            />
                        )}
                    </Field>
                </div>

                <h2 className={cx("header")}>Brand logo</h2>
                <Image form={form} fieldName="logo" />

                <h2 className={cx("header")}>Brand picture</h2>
                <Image form={form} fieldName="image" />
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired
}

export default withStyles(Sidebar, styles)
