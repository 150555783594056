import React, { Component } from "react"
import style from "./Textarea.css"
import withStyles from "HOC/withStyles"

class Textarea extends Component {
    render() {
        const {
            name,
            value,
            type,
            label,
            attributes,
            fieldPath,
            error,
            onChange,
            onBlur,
            isPlaceholder,
            isInline,
            className,
            cx,
            handleScrollToError,
            placeholderText
        } = this.props

        let formattedLabel = `${label}${attributes && attributes.required ? " *" : ""}`

        return (
            <div className={cx("root", { error: error, inline: isInline })}>
                {!isPlaceholder && <label htmlFor={`${name}_${type}`}>{formattedLabel}</label>}

                <textarea
                    type={type}
                    maxLength={10000}
                    id={`${name}_${type}`}
                    name={name}
                    placeholder={placeholderText || (isPlaceholder ? formattedLabel : "")}
                    value={value}
                    className={cx("textarea", className)}
                    required={attributes && attributes.required}
                    onChange={event => onChange && onChange({ event, fieldPath })}
                    onBlur={onBlur}
                />

                {error && (
                    <h2
                        className={cx("error-message")}
                        ref={el => {
                            this.errorInput = el
                        }}
                    >
                        {error}
                        {handleScrollToError && handleScrollToError(this.errorInput)}
                    </h2>
                )}
            </div>
        )
    }
}

export default withStyles(Textarea, style)
