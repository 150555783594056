import React, { Fragment } from "react"
import icon from "./icon.png"
import file from "./Dragonfly_Scraper_Chrome_Extension.zip"

export default {
    icon,
    name: "Scraper",
    version: "1.0",
    file,
    description:
        "Chrome extension that allows scraping product information (images, description, dimensions) and downloading selected images from webpages directly to your local disk.",
    instruction: [
        {
            header: (
                <Fragment>
                    Open the <strong>Extension Management</strong> page by navigating to{" "}
                    <span className="italic">chrome://extensions</span>.
                </Fragment>
            ),
            description: (
                <Fragment>
                    The Extension Management page can also be opened by clicking on the Chrome menu, hovering over{" "}
                    <strong>More Tools</strong> and then selecting <strong>Extensions</strong>.
                </Fragment>
            )
        },
        {
            header: (
                <Fragment>
                    Enable Developer Mode by clicking the toggle switch next to <strong>Developer mode</strong>.
                </Fragment>
            )
        },
        {
            header: (
                <Fragment>
                    Click the <strong>LOAD UNPACKED</strong> button and select the extension folder.
                </Fragment>
            ),
            description: "The extension has been successfully installed."
        }
    ],
    lastUpdate: "Feb 27, 2020"
}
