import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { showErrorNotification, showSuccessNotification } from "actions/notification"
import { getOrderSuccess } from "actions/orders"
import { useFetch, useActions } from "hooks"
import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"

import { Header, List, Summary } from "./components"
import { AddEditPayment, DeletePayment } from "modules/OrdersModule/components"

import withPermissions from "HOC/withPermissions"
import withStyles from "HOC/withStyles"
import styles from "./Payments.module.css"

import { LENGTH_WITHOUT_PAGINATION, PERMISSIONS } from "constants/index"

const Payments = props => {
    const { cx, order = {}, projectId, clientId, canEdit, canDelete } = props
    const { calculations, id: orderId } = order

    const actions = useActions({ showErrorNotification, showSuccessNotification, getOrderSuccess })

    const defaultSearchParams = {
        order_id: orderId,
        project_id: projectId,
        client_id: clientId,
        sort_by: "created_at",
        sort_direction: "desc",
        page: 1,
        length: LENGTH_WITHOUT_PAGINATION
    }

    const [{ data: payments, meta }, setPayments] = useState({ data: [], meta: {} })
    const [searchParams, setSearchParams] = useState(defaultSearchParams)
    const [addEditPaymentModal, setAddEditPaymentModal] = useState({ isOpen: false, payment: null })
    const [paymentToDelete, setPaymentToDelete] = useState(null)
    const [collectionType, collectionId] = orderId ? ["order", Number(orderId)] : ["project", Number(projectId)]

    useEffect(() => {
        getPayments(searchParams)
    }, [searchParams])

    const [getPayments, fetchStatus] = useFetch({
        action: params => fetch.get(`/payments?${objToQueryString(params, true)}`),
        onSuccess: setPayments,
        onError: error => actions.showErrorNotification(error.message)
    })

    const getOrderForCalculations = () =>
        !!orderId ? fetch.get(`/orders/${orderId}`).then(actions.getOrderSuccess) : () => {}

    const changeFilter = (key, value) => {
        setSearchParams(prevSearchParams => ({
            ...prevSearchParams,
            [key]: value
        }))
    }

    const handleTableChange = (tableParams, isInitialCallback) => {
        if (!isInitialCallback) {
            setSearchParams(prevSearchParams => ({
                ...prevSearchParams,
                page: 1,
                sort_by: tableParams.sorting.by,
                sort_direction: tableParams.sorting.direction
            }))
        }
    }

    const { isLoaded } = fetchStatus

    const toggleAddEditPaymentModal = paymentIdToEdit => {
        const newPaymentToEdit = paymentIdToEdit && payments.find(({ id }) => id === paymentIdToEdit)
        setAddEditPaymentModal(({ isOpen: wasOpen }) => ({ isOpen: !wasOpen, payment: newPaymentToEdit }))
    }

    const onAddSuccess = () => getPayments(searchParams)

    const onEditSuccess = ({ data: editedPayment }) =>
        setPayments(oldPayments => ({
            ...oldPayments,
            data: oldPayments.data.map(oldPayment => (oldPayment.id === editedPayment.id ? editedPayment : oldPayment))
        }))

    const onDelete = idToDelete =>
        setPayments(oldPayments => ({ ...oldPayments, data: oldPayments.data.filter(({ id }) => id !== idToDelete) }))

    const isOrderTab = !!orderId && !projectId

    return (
        <section className={cx("root")}>
            <Header
                count={payments.length}
                isLoaded={isLoaded}
                handleAddPayment={collectionId ? toggleAddEditPaymentModal : undefined}
            />
            {isLoaded && payments.length > 0 && meta.calculations && (
                <Summary
                    calculations={
                        isOrderTab
                            ? calculations
                            : {
                                  applied: meta.calculations.payment_applied,
                                  balance: meta.calculations.balance,
                                  total: meta.calculations.total_amount
                              }
                    }
                />
            )}
            {!isOrderTab && <div className={cx("separator")} />}
            <List
                changeFilter={changeFilter}
                handleEditPayment={toggleAddEditPaymentModal}
                handleTableChange={handleTableChange}
                setPaymentToDelete={setPaymentToDelete}
                orderId={orderId}
                payments={payments}
                fetchStatus={fetchStatus}
                meta={meta}
                canEdit={canEdit}
                canDelete={canDelete}
            />
            {addEditPaymentModal.isOpen && (
                <AddEditPayment
                    handleClose={toggleAddEditPaymentModal}
                    collectionId={collectionId}
                    collectionType={collectionType}
                    calculations={calculations}
                    payment={addEditPaymentModal.payment}
                    onAddSuccess={onAddSuccess}
                    onEditSuccess={onEditSuccess}
                    getOrderForCalculations={getOrderForCalculations}
                />
            )}
            {!!paymentToDelete && (
                <DeletePayment
                    amount={paymentToDelete.amount}
                    handleClose={() => setPaymentToDelete(null)}
                    paymentId={paymentToDelete.id}
                    onDelete={onDelete}
                    getOrderForCalculations={getOrderForCalculations}
                />
            )}
        </section>
    )
}

Payments.propTypes = {
    cx: PropTypes.func.isRequired,
    order: PropTypes.object,
    projectId: PropTypes.number,
    clientId: PropTypes.number,
    canEdit: PropTypes.func.isRequired,
    canDelete: PropTypes.func.isRequired
}

export default withPermissions(withStyles(Payments, styles), PERMISSIONS.context.PAYMENTS)
