import React, { useEffect } from "react"
import { useFormik } from "formik"
import { useSelector } from "react-redux"
import * as Yup from "yup"

import { Modal, Button, Input, SelectWithSearch } from "ui"
import { ADDRESS_TYPES, DEFAULT_COUNTRY } from "constants/index"

import fetch from "helpers/fetch"
import { useFetch, useActions } from "hooks"

import { showErrorNotification, showSuccessNotification } from "actions/notification"

import withStyles from "HOC/withStyles"
import styles from "./AddClientModal.css"
import inputStyles from "./overrides/inputStyles.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.css"
import statesSelectStyles from "./overrides/statesSelectStyles.css"
import cityInputStyles from "./overrides/cityInputStyles.css"
import modalStyles from "./overrides/modalStyles.css"
import statesInputStyles from "./overrides/statesInputStyles.css"

const initialValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    country: "",
    company: "",
    post_code: "",
    address: "",
    state: "",
    city: ""
}

const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required."),
    last_name: Yup.string().required("Last name is required."),
    email: Yup.string()
        .email("Email must be a valid email.")
        .required("Email is required."),
    country: Yup.string().required("Country is required."),
    city: Yup.string().required("City is required."),
    company: Yup.string(),
    post_code: Yup.string().required("Post code is required."),
    address: Yup.string().required("Address is required."),
    state: Yup.string()
        .when("country", {
            is: DEFAULT_COUNTRY,
            then: Yup.string().required("State is required.")
        })
        .nullable(),
    phone: Yup.string()
        .required("Phone number is required.")
        .matches(/(?:\D*\d){7}/, "Phone must be at least 7 characters.")
})

const AddClientModal = props => {
    const { cx, isOpen, closeModal, onAddClient } = props
    const countries = useSelector(state => state.clientStore.countriesList)
    const states = useSelector(state => state.clientStore.statesList)

    useEffect(() => {
        if (!isOpen) {
            setValues(initialValues)
            setErrors({})
            setTouched({})
        }
    }, [isOpen])

    const action = useActions({ showErrorNotification, showSuccessNotification })

    const [onSubmit, { isLoading: isSubmitting }] = useFetch({
        action: ({ first_name, last_name, phone, email, country, company, post_code, address, state, city }) => {
            const addressData = {
                city,
                post_code,
                state,
                country,
                address,
                company,
                first_name,
                last_name,
                phone
            }

            return fetch.post("/clients?fast_create=true", {
                first_name,
                last_name,
                phone,
                email,
                addresses: [
                    {
                        name: ADDRESS_TYPES.invoice,
                        type: "invoice",
                        ...addressData
                    },
                    {
                        name: ADDRESS_TYPES.delivery,
                        type: "delivery",
                        ...addressData
                    }
                ]
            })
        },
        onSuccess: ({ data }) => {
            onAddClient(data)
            action.showSuccessNotification()
            closeModal()
        },
        onError: error => {
            setErrors(error.errors)
            action.showErrorNotification(error.message)
        }
    })

    const {
        handleChange,
        handleSubmit,
        setErrors,
        setTouched,
        values,
        setValues,
        touched,
        errors,
        setFieldValue,
        setFieldError
    } = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    const field = name => ({
        name,
        value: values[name],
        onChange: ({ event }) => handleChange(event),
        error: touched && touched[name] && errors && errors[name]
    })

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} preventClickOutside customStyles={modalStyles}>
            <Modal.Header>Create new client</Modal.Header>
            <Modal.Body className={cx("body")}>
                <div className={cx("wrapper")}>
                    <h2>Basic info</h2>
                    <div className={cx("row")}>
                        <Input
                            label="First name"
                            {...field("first_name")}
                            customStyles={inputStyles}
                            customPlaceholder="First name"
                        />
                        <Input
                            label="Last name"
                            {...field("last_name")}
                            customStyles={inputStyles}
                            customPlaceholder="Last name"
                        />
                    </div>
                    <div className={cx("row")}>
                        <Input
                            label="E-mail address"
                            {...field("email")}
                            customStyles={inputStyles}
                            customPlaceholder="E-mail address"
                        />
                        <Input
                            label="Phone number"
                            error={touched && touched["phone"] && errors && errors["phone"]}
                            value={values.phone}
                            onChange={({ event }) => setFieldValue("phone", event.target.value)}
                            isPhoneInput
                            customStyles={inputStyles}
                            customPlaceholder="Phone number"
                        />
                    </div>
                </div>
                <div className={cx("wrapper")}>
                    <h2>Billing Address</h2>
                    <div className={cx("row")}>
                        <Input
                            label="Address (Street and NO.)"
                            customPlaceholder={"Address (Street and NO.)"}
                            {...field("address")}
                            customStyles={inputStyles}
                        />
                        <SelectWithSearch
                            label="Country"
                            error={touched && touched["country"] && errors && errors["country"]}
                            value={values.country}
                            customStyles={selectWithSearchStyles}
                            values={countries.map(country => ({ label: country, value: country }))}
                            setValue={value => {
                                setFieldValue("country", value.value)
                                setFieldError("country", null)
                                setFieldValue("state", null)
                            }}
                            withoutFetch
                            placeholder="Country"
                        />
                    </div>
                    <div className={cx("row")}>
                        <div className={cx("postal")}>
                            <Input
                                label="City"
                                {...field("city")}
                                customStyles={cityInputStyles}
                                customPlaceholder="City"
                            />
                            {values.country === DEFAULT_COUNTRY ? (
                                <SelectWithSearch
                                    label="State"
                                    error={touched && touched["state"] && errors && errors["state"]}
                                    value={values.state}
                                    customStyles={statesSelectStyles}
                                    values={states.map(states => ({ label: states, value: states }))}
                                    setValue={value => {
                                        setFieldValue("state", value.value)
                                        setFieldError("state", null)
                                    }}
                                    withoutFetch
                                    placeholder="State"
                                />
                            ) : (
                                <Input
                                    label="State "
                                    {...field("state")}
                                    customStyles={statesInputStyles}
                                    customPlaceholder="State"
                                />
                            )}
                            <Input
                                label="Zip Code"
                                {...field("post_code")}
                                customStyles={inputStyles}
                                customPlaceholder="Zip Code"
                            />
                        </div>
                        <Input
                            label="Company"
                            {...field("company")}
                            customStyles={inputStyles}
                            customPlaceholder="Company"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Actions>
                <button type="button" className={cx("cancelButton")} onClick={closeModal}>
                    Cancel
                </button>
                <Button
                    type="button"
                    className={cx("first-button", "submitButton")}
                    label="Create"
                    onClick={handleSubmit}
                    isLoading={isSubmitting}
                />
            </Modal.Actions>
        </Modal>
    )
}

export default withStyles(AddClientModal, styles)
