import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { H1, Modal, Button, AddIcon } from "@butterfly-frontend/ui"

import stockItemStatusKeys from "modules/WmsModule/constants/stockItemStatusKeys"
import formikPropTypesFactory from "modules/WmsModule/helpers/formikPropTypesFactory"

import StockItemsList from "./StockItemsList"
import StockItemsListWarnings from "./StockItemsListWarnings"
import WarehouseTransferForm from "./WarehouseTransferForm"
import useShippingDate from "./hooks/useShippingDate"
import selectedItemsPropType from "./selectedItemsPropType"
import styles from "./StockTransfer.module.css"

const splitStockItemsBasedOnTransferAvailability = (selectedItems, shippingToWarehouseId, apiStockItemsErrors) =>
    selectedItems.reduce(
        (
            {
                availableToTransferStockItems,
                alreadyInTargetWarehouseStockItems,
                onHoldStockItems,
                alreadyInShipmentStockItems
            },
            currentSelectedStockItem
        ) => {
            const isItemAlreadyInTargetWarehouse =
                currentSelectedStockItem.warehouse.id === shippingToWarehouseId ||
                _get(apiStockItemsErrors, `${currentSelectedStockItem.id}.isAlreadyInTargetWarehouse`)
            const isItemOnHold =
                currentSelectedStockItem.status === stockItemStatusKeys.ON_HOLD ||
                _get(apiStockItemsErrors, `${currentSelectedStockItem.id}.isOnHold`)
            const isItemInShipment = _get(apiStockItemsErrors, `${currentSelectedStockItem.id}.isInShipment`)
            const isItemAvailableToTransfer = !isItemAlreadyInTargetWarehouse && !isItemOnHold && !isItemInShipment

            return {
                alreadyInTargetWarehouseStockItems: isItemAlreadyInTargetWarehouse
                    ? [...alreadyInTargetWarehouseStockItems, currentSelectedStockItem]
                    : alreadyInTargetWarehouseStockItems,

                onHoldStockItems: isItemOnHold ? [...onHoldStockItems, currentSelectedStockItem] : onHoldStockItems,

                availableToTransferStockItems: isItemAvailableToTransfer
                    ? [...availableToTransferStockItems, currentSelectedStockItem]
                    : availableToTransferStockItems,

                alreadyInShipmentStockItems: isItemInShipment
                    ? [...alreadyInShipmentStockItems, currentSelectedStockItem]
                    : alreadyInShipmentStockItems
            }
        },
        {
            alreadyInTargetWarehouseStockItems: [],
            onHoldStockItems: [],
            availableToTransferStockItems: [],
            alreadyInShipmentStockItems: []
        }
    )

const StockTransfer = ({
    selectedItems,
    onRemoveItem,
    onClose,
    formik,
    apiStockItemsErrors,
    clearAlreadyInTargetWarehouseApiErrors
}) => {
    const shippingDate = useShippingDate()

    const {
        availableToTransferStockItems,
        alreadyInTargetWarehouseStockItems,
        onHoldStockItems,
        alreadyInShipmentStockItems
    } = useMemo(
        () =>
            splitStockItemsBasedOnTransferAvailability(
                selectedItems,
                _get(formik.values, "shippingTo.value"),
                apiStockItemsErrors
            ),
        [selectedItems, formik.values.shippingTo, apiStockItemsErrors]
    )

    useEffect(() => {
        if (selectedItems.length === 0) {
            onClose()
            return
        }

        formik.setFieldValue("ids", availableToTransferStockItems.map(({ id }) => id), false)
    }, [availableToTransferStockItems, selectedItems])

    return (
        <Modal
            onClose={onClose}
            title={
                <div>
                    <H1>Stock transfer</H1>
                    <p className={styles.subtitle}>
                        Selected products for transfer (products added by 3PM shipped next day)
                    </p>
                </div>
            }
        >
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <WarehouseTransferForm
                    formik={formik}
                    selectedItems={selectedItems}
                    shippingDate={shippingDate}
                    clearAlreadyInTargetWarehouseApiErrors={clearAlreadyInTargetWarehouseApiErrors}
                />
                {(onHoldStockItems.length > 0 ||
                    alreadyInTargetWarehouseStockItems.length > 0 ||
                    alreadyInShipmentStockItems.length > 0) && (
                    <StockItemsListWarnings
                        alreadyInTargetWarehouseStockItems={alreadyInTargetWarehouseStockItems}
                        onHoldStockItems={onHoldStockItems}
                        alreadyInShipmentStockItems={alreadyInShipmentStockItems}
                    />
                )}
                <StockItemsList
                    selectedItems={availableToTransferStockItems}
                    onRemoveItem={onRemoveItem}
                    isRemovingDisabled={formik.isSubmitting}
                />
                <div className={styles.buttonsWrapper}>
                    <Button Icon={AddIcon} onClick={onClose} variant="flat">
                        Add more items
                    </Button>
                    <Button
                        disabled={availableToTransferStockItems.length === 0}
                        isLoading={formik.isSubmitting}
                        type="submit"
                        color="blue"
                    >
                        Create new stock transfer
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

StockTransfer.propTypes = {
    onClose: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    selectedItems: selectedItemsPropType,
    formik: formikPropTypesFactory({
        shippingTo: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })
    }),
    clearAlreadyInTargetWarehouseApiErrors: PropTypes.func.isRequired,
    apiStockItemsErrors: PropTypes.objectOf(
        PropTypes.shape({
            isAlreadyInTargetWarehouse: PropTypes.bool,
            isOnHold: PropTypes.bool,
            isInShipment: PropTypes.bool
        })
    )
}

export default StockTransfer
