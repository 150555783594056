import React from "react"
import PropTypes from "prop-types"

import Dropzone from "ui/Dropzone"

import withMultipleUploader from "HOC/withMultipleUploader"
import withStyles from "HOC/withStyles"
import styles from "./dropzone.css"
import dropzoneStyles from "./overrides/dropzoneStyles.module.css"

const AttachmentDropzone = props => {
    const {
        cx,
        uploader: { state, actions },
        isMultiple,
        acceptFormats
    } = props

    return (
        <Dropzone
            acceptFormats={acceptFormats}
            isMultiple={isMultiple}
            customStyles={dropzoneStyles}
            {...state}
            {...actions}
            disabled={state.isUploading}
            disableClick={state.isUploading}
        >
            <div className={cx("uploadZone", { isUploading: state.isUploading })}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
                    <g fill="none" fillRule="evenodd">
                        <path d="M3 3.303h18v18H3z" />
                        <path
                            fill="#dee1e4"
                            fillRule="nonzero"
                            d="M17.512 10.833A5.617 5.617 0 0 0 12 6.303a5.622 5.622 0 0 0-4.987 3.03A4.496 4.496 0 0 0 3 13.803c0 2.482 2.018 4.5 4.5 4.5h9.75c2.07 0 3.75-1.68 3.75-3.75a3.732 3.732 0 0 0-3.488-3.72zm-4.012 2.22v3h-3v-3H8.25L12 9.303l3.75 3.75H13.5z"
                        />
                    </g>
                </svg>
                <h3>Add file</h3>
                <p>(browse or drop your files here)</p>
            </div>
        </Dropzone>
    )
}

AttachmentDropzone.defaultProps = {
    isMultiple: true,
    acceptFormats: ""
}

AttachmentDropzone.propTypes = {
    endpoint: PropTypes.string,
    isMultiple: PropTypes.bool,
    acceptFormats: PropTypes.string,
    handleUploadSuccess: PropTypes.func.isRequired,
    handleUploadProgress: PropTypes.func
}

export default withMultipleUploader(withStyles(AttachmentDropzone, styles))
