import * as yup from "yup"

export default yup.object().shape({
    name: yup
        .string()
        .max(250, "Vendor name may not be greater than 250 characters")
        .required("Vendor name is required"),
    po_email: yup
        .string()
        .email("Email is incorrect")
        .max(250, "Email may not be greater than 250 characters")
        .required("Email is required"),
    company_name: yup
        .string()
        .max(250, "Company name may not be greater than 250 characters")
        .nullable(),
    country: yup
        .string()
        .max(250, "Country may not be greater than 250 characters")
        .nullable(),
    address: yup
        .string()
        .max(250, "Address may not be greater than 250 characters")
        .nullable(),
    city: yup
        .string()
        .max(250, "City may not be greater than 250 characters")
        .nullable(),
    state: yup
        .string()
        .max(250, "State may not be greater than 250 characters")
        .nullable(),
    post_code: yup
        .string()
        .max(250, "Zip Code may not be greater than 250 characters")
        .nullable()
})
