import { useCallback } from "react"
import _get from "lodash/get"

import { useStockItemBoxEdit } from "modules/WmsModule/hooks/api/useStockItemBox"
import { useReplaceStockItemInShipmentCache } from "modules/WmsModule/hooks/cache"
import decodeStockItemBoxSku from "modules/WmsModule/helpers/decodeStockItemBoxSku"

const getError = ({ box, stockItem, sku }) => {
    if (_get(box, "packed") || _get(stockItem, "packed")) {
        return `The box with sku ${sku} has already been packed.`
    }

    return `The box with sku ${sku} does not belong to any package in this shipment.`
}

const usePackBox = ({ shipment, onPackError, onPackSuccess }) => {
    const replaceShipmentItemInCache = useReplaceStockItemInShipmentCache({ shipmentId: shipment.id })
    const boxPackMutation = useStockItemBoxEdit()

    const packStockItem = useCallback(
        ({ stockItem }) => {
            replaceShipmentItemInCache({
                ...stockItem,
                entireItemBarcodeRead: true
            })
            onPackSuccess()
        },
        [replaceShipmentItemInCache, onPackSuccess]
    )

    const packStockItemBox = useCallback(
        ({ stockItem, box }) =>
            boxPackMutation.mutate(
                {
                    id: box.id,
                    data: {
                        packed: true
                    }
                },
                {
                    onSuccess: ({ data: updatedStockItemBox }) => {
                        replaceShipmentItemInCache({
                            ...stockItem,
                            boxes: stockItem.boxes.map(itemStockItemBox =>
                                itemStockItemBox.id === updatedStockItemBox.id
                                    ? { ...itemStockItemBox, packed: updatedStockItemBox.packed }
                                    : itemStockItemBox
                            )
                        })
                        onPackSuccess()
                    }
                }
            ),
        [boxPackMutation.mutate, replaceShipmentItemInCache, onPackSuccess]
    )

    const onPackBox = useCallback(
        sku => {
            const { stockItemId, boxNumber } = decodeStockItemBoxSku(sku)
            const stockItem = shipment.items.find(currentItem => currentItem.id === stockItemId)
            const shouldAcceptStockItem = stockItem && !boxNumber && stockItem.boxes.length === 0 && !stockItem.packed

            if (shouldAcceptStockItem) {
                return packStockItem({ stockItem })
            }

            const box = stockItem ? stockItem.boxes.find(box => box.sku === sku) : undefined

            if (box && !box.packed) {
                return packStockItemBox({ stockItem, box })
            }

            onPackError(getError({ box, sku, stockItem }))
        },
        [shipment, packStockItem, packStockItemBox, onPackError, getError, decodeStockItemBoxSku]
    )

    return { onPackBox, isLoading: boxPackMutation.isLoading }
}

export default usePackBox
