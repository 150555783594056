import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./UserData.css"
import pencil from "assets/pencil.svg"
import remove from "assets/close.svg"
import { Link } from "react-router-dom"
import Confirm from "components/Modal/Confirm/Confirm"
import Modal from "components/Modal/Modal"
import withPermissions from "HOC/withPermissions"

class UserData extends Component {
    state = {
        isConfirmOpen: false
    }

    render() {
        const {
            cx,
            index,
            id,
            email,
            first_name,
            last_name,
            permissions_count,
            total_permissions_count,
            role,
            canList,
            canEdit,
            canDelete,
            PERMISSIONS
        } = this.props
        const { isConfirmOpen } = this.state
        return (
            <li className={cx("user-data-li")}>
                <h4 className={cx("no")}>{index + 1}.</h4>
                <h4 className={cx("email")}>{email}</h4>
                <h4 className={cx("name")}>{first_name}</h4>
                <h4 className={cx("surname")}>{last_name}</h4>
                <h4 className={cx("role")}>{role.length > 0 ? role.map(role => role.name + " ") : "-"}</h4>
                <h4 className={cx("permissions")}>
                    {permissions_count}/{total_permissions_count}
                </h4>
                <h4 className={cx("edit")}>
                    {canList(PERMISSIONS.context.ROLES) && canEdit(PERMISSIONS.context.USERS) && (
                        <Link to={"/settings/users/edit/" + id} className={cx("edit-text")}>
                            <img src={pencil} alt="edit" className={cx("edit-icon")} />
                            Edit
                        </Link>
                    )}
                </h4>
                <h4 className={cx("delete")}>
                    {isConfirmOpen && (
                        <Modal>
                            <Confirm
                                confirmModalTitle="Are you sure you want to delete this user?"
                                firstName={first_name}
                                lastName={last_name}
                                id={id}
                                handleHideModal={this.onCancel}
                                handleDelete={this.onConfirm}
                            />
                        </Modal>
                    )}

                    {canDelete(PERMISSIONS.context.USERS) && (
                        <span className={cx("delete-text")} onClick={this.handleConfirm}>
                            <img src={remove} alt="edit" className={cx("remove-icon")} />
                            Delete
                        </span>
                    )}
                </h4>
            </li>
        )
    }

    handleConfirm = () => {
        this.setState({ isConfirmOpen: true })
    }

    onCancel = () => {
        this.setState({ isConfirmOpen: false })
    }

    onConfirm = id => {
        this.props.deleteThisUser(id)
        this.setState({ isConfirmOpen: false })
    }
}

export default withPermissions(withStyles(UserData, styles))
