import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import moment from "moment"
import idx from "idx"
import ReactSvg from "react-svg"
import pick from "lodash/pick"

import { useActions } from "hooks"
import withStyles from "HOC/withStyles"
import withPermissions from "HOC/withPermissions"
import { getPaymentMethods } from "actions/paymentMethods"

import { AddEditPayment, DeletePayment } from "modules/OrdersModule/components"
import FilterableTable, { Sort, Table } from "ui/FilterableTable"

import { LENGTH_WITHOUT_PAGINATION, PERMISSIONS } from "constants/index"
import { formatPrice } from "helpers/units"

import styles from "./PaymentsSection.css"

import addIcon from "assets/add.svg"
import clockIcon from "assets/clock.svg"

const PaymentsSection = props => {
    const { cx, payments = [], calculations, invoice, onPaymentChange, canAdd, canEdit, canDelete } = props
    const { byId: paymentMethods, fetchStatus } = useSelector(state => state.db.paymentMethods)
    const actions = useActions({ getPaymentMethods })

    const [sortedPayments, setSortedPayments] = useState(payments)
    const [sorting, setSorting] = useState({ by: "date", direction: "desc" })
    const [addEditPaymentModal, setAddEditPaymentModal] = useState({ isOpen: false, payment: null })
    const [paymentToDelete, setPaymentToDelete] = useState(null)

    useEffect(() => {
        actions.getPaymentMethods(
            {
                sort_by: "date",
                page: 1,
                length: LENGTH_WITHOUT_PAGINATION
            },
            true
        )
    }, [])

    useEffect(() => {
        setSortedPayments(prevPayments => sortPayments(prevPayments, sorting))
    }, [sorting])

    return (
        <div className={cx("root", { emptyList: sortedPayments.length === 0 })}>
            <div className={cx("header")}>
                <h2>Payments</h2>
                {canAdd() && (
                    <button
                        className={cx("button")}
                        onClick={() => setAddEditPaymentModal({ isOpen: true, payment: null })}
                    >
                        <ReactSvg src={addIcon} />
                        Add new payment
                    </button>
                )}
            </div>
            <FilterableTable fetchStatus={fetchStatus} items={sortedPayments} handleChange={handleTableChange}>
                <Table
                    className={cx("table")}
                    renderHeader={renderTableHeader}
                    renderBody={renderTableBody}
                    customEmptyMessage={() => null}
                />
            </FilterableTable>
            {addEditPaymentModal.isOpen && (
                <AddEditPayment
                    handleClose={() => setAddEditPaymentModal(state => ({ ...state, isOpen: false }))}
                    orderId={invoice.order.id}
                    calculations={calculations}
                    collectionId={invoice.order.id}
                    collectionType="order"
                    invoice={pick(invoice, ["id", "uuid"])}
                    payment={addEditPaymentModal.payment}
                    onAddSuccess={({ data }) => onChange(data, "add")}
                    onEditSuccess={({ data }) => onChange(data, "edit")}
                />
            )}
            {!!paymentToDelete && (
                <DeletePayment
                    amount={paymentToDelete.amount}
                    handleClose={() => setPaymentToDelete(null)}
                    paymentId={paymentToDelete.id}
                    onDelete={id => onChange(id, "delete")}
                />
            )}
        </div>
    )

    function renderTableHeader() {
        return (
            <Table.Head>
                <Table.Tr>
                    <Table.Th>
                        <Sort sortBy="amount">amount</Sort>
                    </Table.Th>
                    <Table.Th>
                        <Sort isDefault sortBy="date" sortDirection="desc">
                            date
                        </Sort>
                    </Table.Th>
                    <Table.Th>
                        <Sort sortBy="payment_method_id">method</Sort>
                    </Table.Th>
                    <Table.Th>note</Table.Th>
                    <Table.Th />
                </Table.Tr>
            </Table.Head>
        )
    }

    function renderTableBody(items) {
        const rows = items.map(payment => {
            const { id, amount, date, payment_method_id, note } = payment
            const methodName = idx(paymentMethods, _ => _[payment_method_id].name) || "-"

            return (
                <Table.Tr key={id}>
                    <Table.Td>
                        <p className={cx("table-amount")}>{formatPrice(amount)}</p>
                    </Table.Td>
                    <Table.Td>
                        <img className={cx("clock-icon")} src={clockIcon} alt="icon" />
                        <p className={cx("table-date")}>{moment(date).format("DD MMM YYYY")}</p>
                    </Table.Td>
                    <Table.Td>
                        <p className={cx("table-method")}>{methodName}</p>
                    </Table.Td>
                    <Table.Td>
                        <p className={cx("table-note")}>{note || "-"}</p>
                    </Table.Td>
                    <Table.Td className="textRight">
                        {canDelete() && (
                            <div
                                className={cx("delete")}
                                onClick={() => {
                                    setPaymentToDelete(payment)
                                }}
                            >
                                Delete
                            </div>
                        )}
                        {canEdit() && (
                            <div
                                className={cx("edit")}
                                onClick={() => {
                                    setAddEditPaymentModal({
                                        isOpen: true,
                                        payment: { ...payment, method: paymentMethods[payment_method_id] }
                                    })
                                }}
                            >
                                Edit
                            </div>
                        )}
                    </Table.Td>
                </Table.Tr>
            )
        })

        return <Table.Body>{rows}</Table.Body>
    }

    function handleTableChange(params) {
        const { by, direction } = params.sorting
        const sorting = { by: by || "date", direction: direction || "desc" }

        setSorting(sorting)
    }

    function onChange(data, type) {
        const isValidType = ["add", "edit", "delete"].includes(type)
        if (!isValidType) {
            return
        }

        const input = ["add", "edit"].includes(type) ? formatPayment(data) : data
        const newSorted = {
            add: () => sortPayments([...sortedPayments, input], sorting),
            edit: () => sortedPayments.map(payment => (payment.id === input.id ? input : payment)),
            delete: () => sortedPayments.filter(({ id }) => id !== input)
        }[type]()
        onPaymentChange(newSorted)
        setSortedPayments(newSorted)
    }
}

function sortPayments(payments, sorting) {
    return payments.slice().sort((a, b) => {
        if (["amount", "payment_method_id"].includes(sorting.by)) {
            const [numA, numB] = [Number(a[sorting.by]), Number(b[sorting.by])]
            const condition = sorting.direction === "asc" ? numA > numB : numA < numB
            return numA === numB ? 0 : condition ? 1 : -1
        }

        if (sorting.by === "date") {
            const [dateA, dateB] = [moment(a.date), moment(b.date)]
            const method = sorting.direction === "asc" ? "isAfter" : "isBefore"
            return dateA.isSame(dateB) ? 0 : dateA[method](dateB) ? 1 : -1
        }

        return 0
    })
}

function formatPayment(payment) {
    const { id, amount, date, note, method } = payment
    return { id, amount: amount.toString(), date, note, payment_method_id: method.id }
}

export default withPermissions(withStyles(PaymentsSection, styles), PERMISSIONS.context.PAYMENTS)
