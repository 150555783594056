import React, { useEffect } from "react"
import { connect } from "react-redux"

import * as projectActions from "actions/projects"

import ShareProject from "./ShareProject"

export const ShareProjectContainer = ({ match, getProject, data }) => {
    useEffect(() => {
        fetchProject()
    }, [])

    const fetchProject = () => {
        getProject(match.params.token)
    }

    return <ShareProject data={data} getProject={fetchProject} />
}

const mapStateToProps = state => {
    return {
        data: state.db.projects.current.current
    }
}

const mapDispatchToProps = dispatch => ({
    getProject: token => dispatch(projectActions.getProject(null, token))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShareProjectContainer)
