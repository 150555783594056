import React, { useReducer, useState } from "react"
import idx from "idx"
import { Decimal } from "decimal.js"
import { Input, Textarea, Toggle, Quantity, Button, VendorSelect, PhotoUploader } from "ui"

import { formatPrice, roundNumber } from "helpers/units"
import { checkIfNumericValue } from "helpers/number"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"
import withStyles from "HOC/withStyles"

import styles from "./edit.css"
import toggleStyles from "./overrides/toggleStyles.css"
import inputAndTextareaStyles from "./overrides/inputAndTextareaStyles.css"
import quantityStyles from "./overrides/quantity.css"
import photoUploaderStyles from "./overrides/photoUploaderStyles.css"

const defaultValues = {
    quantity: 1,
    discount: null,
    note: ""
}

const MAX_INPUT_QUANTITY = 999

const parsed = (field, value) => {
    if (field === "quantity") {
        return typeof value === "number"
            ? value > MAX_INPUT_QUANTITY
                ? MAX_INPUT_QUANTITY
                : value
            : defaultValues.quantity
    }

    if (field === "tax_code") {
        return typeof value === "number" ? value : null
    }

    return value || defaultValues[field]
}

const productReducer = (state, { field, value }) => {
    if (Object.keys(state).includes(field)) {
        return { ...state, [field]: parsed(field, value) }
    }

    return state
}

const Edit = props => {
    const { cx, isSaving, item, onToggleEdit, saveProduct, errors, isService, parent, showTax } = props
    const itemTaxCode = idx(item, _ => _.tax_code) && roundNumber(new Decimal(item.tax_code).times(100), 6)

    const [vendor, setVendor] = useState(item.brand)

    const isConsignment = ![undefined, null].includes(item.consignment)

    const [product, handleFieldChange] = useReducer(productReducer, {
        fake_name: item.fake_name || "",
        price: Number(item.price),
        quantity: item.quantity,
        discount: item.discount,
        tax_code: item.tax_code,
        consignment: item.consignment,
        note: item.note || "",
        image: item.image
    })

    const handleChange = (field, value) => {
        handleFieldChange({ field, value })
    }

    const { fake_name, price, quantity, discount, consignment, note, tax_code, image } = product
    const taxProduct = isService ? 0 : checkIfNumericValue(tax_code) ? tax_code : idx(parent, _ => _.tax_code) || 0
    const discountProduct = checkIfNumericValue(discount) ? discount : idx(parent, _ => _.discount) || 0
    const productDiscount = new Decimal(100).minus(discountProduct)
    const productTax = new Decimal(1).plus(Number(taxProduct))
    const productPrice = price
        ? new Decimal(price)
              .times(quantity)
              .times(productDiscount)
              .times(productTax)
        : 0
    const totalPrice = new Decimal(productPrice).div(100).toDecimalPlaces(2)
    const discountPlaceholder = parent === undefined ? "-" : parent.discount === null ? "-" : parent.discount
    const taxPlaceholder =
        parent && typeof parent.tax_code === "number" ? roundNumber(new Decimal(parent.tax_code).times(100), 6) : "-"

    const onSave = (id, product) => {
        saveProduct(id, {
            ...product,
            image: typeof image === "object" && image ? { id: image.id } : undefined,
            brand_id: !item.manual_entry ? undefined : vendor ? vendor.id : null
        })
    }

    const isSubmitDisabled = !product.fake_name || (item.manual_entry && !vendor)

    return (
        <div className={cx("root")}>
            <div className={cx("column", "left")}>
                {!isService && (
                    <PhotoUploader
                        customStyles={photoUploaderStyles}
                        avatar={typeof image === "object" && image ? image.url : image}
                        endpoint={DEFAULT_FILE_UPLOAD_ENPOINT}
                        handleRemovePhoto={() => handleChange("image", { id: undefined })}
                        handleUploadSuccess={state => handleChange("image", state.addedFile)}
                        className={cx("photoUploader")}
                    />
                )}
                <div className={cx("nameNoteConsignWrapper")}>
                    <Input
                        value={fake_name || ""}
                        onChange={({ event }) => handleChange("fake_name", event.target.value)}
                        attributes={{ required: true }}
                        label="Product name"
                        className={cx("name")}
                        error={errors.fake_name}
                        customStyles={inputAndTextareaStyles}
                    />

                    {item.manual_entry && (
                        <div className={cx("vendorWrapper")}>
                            <VendorSelect
                                name="vendor"
                                value={(vendor || {}).name}
                                label="Vendor"
                                setValue={value => setVendor(value)}
                                isClearBlocked={false}
                                isRequired
                            />
                        </div>
                    )}

                    <Textarea
                        value={note || ""}
                        onChange={({ event }) => handleChange("note", event.target.value)}
                        label="Note"
                        placeholder="Click here to add a note..."
                        className={cx("note")}
                        error={errors.note}
                        customStyles={inputAndTextareaStyles}
                    />
                    {isConsignment && (
                        <div className={cx("consignment")}>
                            <label>Consignment</label>
                            <Toggle
                                customStyles={toggleStyles}
                                isOn={consignment}
                                handleChange={value => handleChange("consignment", value)}
                                label={{ on: "Yes", off: "No" }}
                                labelPosition="left"
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={cx("column", "right")}>
                <div>
                    <Input
                        type="number"
                        min={0}
                        attributes={{ required: true }}
                        value={price}
                        onChange={({ event }) => handleChange("price", Number(event.target.value))}
                        label="Item price"
                        className={cx("price")}
                        error={errors.price}
                        customStyles={inputAndTextareaStyles}
                    />
                </div>
                <div className={cx("quantityWrapper")}>
                    <div>
                        <label>Qty:</label>
                        <Quantity
                            value={quantity}
                            min={0}
                            onChange={value => handleChange("quantity", value)}
                            error={errors.quantity}
                            customStyles={quantityStyles}
                            isInputDisabled={false}
                        />
                    </div>
                </div>
                <div className={cx("discountAndTaxWrapper")}>
                    <Input
                        type="number"
                        min={0}
                        max={100}
                        value={discount}
                        onChange={({ event }) => handleChange("discount", event.target.value)}
                        label="Discount"
                        className={cx("discountAndTax")}
                        error={errors.discount}
                        customStyles={inputAndTextareaStyles}
                        customPlaceholder={discountPlaceholder}
                    />
                </div>
                {showTax && (
                    <div className={cx("discountAndTaxWrapper")}>
                        <Input
                            label="Tax"
                            value={isService ? 0 : itemTaxCode}
                            className={cx("discountAndTax")}
                            customStyles={inputAndTextareaStyles}
                            customPlaceholder={taxPlaceholder}
                            isDisabled
                        />
                    </div>
                )}
                <div className={cx("totalPrice")}>
                    <label>Item total:</label>
                    {formatPrice(totalPrice)}
                </div>
            </div>
            <div className={cx("actionsBar")}>
                <button className={cx("cancelButton")} onClick={onToggleEdit}>
                    Cancel
                </button>
                <Button
                    className="first-button"
                    label="Save"
                    isLoading={isSaving}
                    isDisabled={isSubmitDisabled}
                    onClick={() => onSave(item.id, product)}
                />
            </div>
        </div>
    )
}

export default withStyles(Edit, styles)
