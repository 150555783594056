import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"

export const checkIfCanCreateStockAdjustment = ({ status, condition, isDuringShipment }) =>
    status === STOCK_ITEM_STATUS_KEYS.SOLD || condition === CONDITION_TYPES.REMOVED || isDuringShipment

export const filterStockItemsForSale = stockItems =>
    stockItems.filter(
        stockItem =>
            stockItem.status === STOCK_ITEM_STATUS_KEYS.IN_STOCK && stockItem.condition.type !== CONDITION_TYPES.DAMAGED
    )
