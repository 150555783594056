import React from "react"
import { Route, Switch } from "react-router-dom"

import { Form, List, Overview } from "../pages"

import withPermissions from "HOC/withPermissions"

const Routes = () => {
    return (
        <Switch>
            <Route path="/settings/brands" exact component={List} />
            <Route path="/settings/brands/:brandId/edit/:sectionName?" component={Form} />
            <Route path="/settings/brands/add/:sectionName?" component={Form} />
            <Route path="/settings/brands/:brandId/:sectionName?" component={Overview} />
        </Switch>
    )
}

export default withPermissions(Routes)
