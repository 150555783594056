import React, { useState, useEffect, useMemo } from "react"
import { Modal as ModalComponent } from "ui"
import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"
import TimeAgo from "react-timeago"

import SelectFromListModal from "components/Modal/SelectFromList/SelectFromListContainer"

import styles from "./styles.css"

const Modal = props => {
    const { isOpen, handleClose, onSubmit, data } = props

    const [isSaving, SetIsSaving] = useState(false)

    useEffect(() => {
        SetIsSaving(false)
    }, [isOpen])

    const fetchItemsList = (query, { page, length, exclude = "" }) =>
        fetch
            .get(`/posts?query=${query}&page=${page}&length=${length}&exclude=${exclude}`)
            .then(data => ({ payload: { data } }))

    const handlePrepareItem = item => {
        return {
            name: item.title,
            img: item.cover,
            right: <TimeAgo date={item.updated_at} />
        }
    }

    const handleSubmit = posts => {
        SetIsSaving(true)
        onSubmit(
            posts.map(element => {
                return { id: element.id }
            })
        )
    }

    const excludedList = useMemo(() => {
        console.log(data)
        return (data.posts || []).map(element => element.post_id)
    }, [data])

    return (
        <ModalComponent isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <SelectFromListModal
                isSaving={isSaving}
                handleClose={handleClose}
                selectedItems={[]}
                exclude={excludedList}
                typeItems="posts"
                prepareItem={handlePrepareItem}
                handleSubmit={handleSubmit}
                fetchItemsList={fetchItemsList}
            />
        </ModalComponent>
    )
}

export default withStyles(Modal, styles)
