import React from "react"

import withStyles from "HOC/withStyles"
import styles from "./FilterCount.css"

const FilterCount = withStyles(({ cx, count }) => {
    if (!count) {
        return null
    }

    return <span className={cx("count")}>{count}</span>
}, styles)

export default FilterCount
