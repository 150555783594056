import React, { Component } from "react"

import { withStyles } from "ui/FilterableTable/HOC"

import helpers from "./helpers"
import FilterableTable from "./FilterableTable"
import Header from "./Header"
import Content from "./Content"
import Footer from "./Footer"
import Filter from "./Filter"
import Sort from "./Sort"
import Pagination from "./Pagination"
import MoreFilters from "./MoreFilters"
import PerPage from "./PerPage"
import SearchBar from "./SearchBar"
import Checkbox from "./Checkbox"
import FetchStatus from "./FetchStatus"
import Table from "./Table"
import Link from "./Link"
import DatePicker from "./DatePicker"
import TableBodySkeleton from "./skeletons/TableBody"

import headerStyles from "./Header/Header.css"

const AppContext = React.createContext()

class AppProvider extends Component {
    state = {
        init: false
    }

    data = { initFilters: {}, initSorting: {}, ...this.clearData() }

    constructor(props) {
        super(props)
        this.reset = this.reset.bind(this)
    }

    componentDidMount() {
        this.handleChange({ init: true })
        this.setState({ init: true })
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const resource = {
            fetchStatus: nextProps.fetchStatus
        }

        if (
            (!nextProps.location.search && this.props.location.search) ||
            helpers.fetchStatus.isResourceNotInit(resource)
        ) {
            this.data = { ...this.data, ...this.clearData() }

            this.handleChange({ init: true })
        }
    }

    render() {
        const { fetchStatus, meta, perPage, items } = this.props

        return (
            <AppContext.Provider
                value={{
                    ...this.state,
                    data: { ...this.data },
                    initSorting: this.initSorting,
                    changeSorting: this.changeSorting,
                    initFilter: this.initFilter,
                    changeFilter: this.changeFilter,
                    initPage: this.initPage,
                    changePage: this.changePage,
                    onSearch: this.onSearch,
                    changeDate: this.changeDate,
                    reset: this.reset,
                    fetchStatus,
                    meta,
                    perPage,
                    items
                }}
            >
                {this.props.children}
            </AppContext.Provider>
        )
    }

    reset() {
        this.data = { initFilters: {}, initSorting: {}, ...this.clearData() }

        this.handleChange()
    }

    clearData() {
        const defaultSorting = { by: null, direction: null }

        const initFilters = this.data ? this.data.initFilters || {} : {}
        const initSorting = this.data ? this.data.initSorting || defaultSorting : defaultSorting

        return {
            filters: Object.keys(initFilters).map(key => ({
                name: key,
                value: initFilters[key]
            })),
            sorting: initSorting,
            page: 1,
            search: "",
            dateFrom: null,
            dateTo: null
        }
    }

    getQueryString() {
        return this.props.location.search.indexOf("?") === 0
            ? this.props.location.search.slice(1)
            : this.props.location.search
    }

    initFilter = (name, value) => {
        if (this.data.filters.findIndex(item => item.name === name) === -1) {
            this.setInitFilter(name, value)
            this.forceUpdate()
        }
    }

    changeFilter = (name, value) => {
        this.setFilter(name, value)
        this.handleChange()
    }

    setFilter = (name, value) => {
        const nextData = { ...this.data }

        const foundFilterIndex = this.data.filters.findIndex(item => item.name === name)

        if (foundFilterIndex === -1) {
            nextData.filters.push({ name, value })
        } else {
            nextData.filters[foundFilterIndex] = { name, value }
        }

        nextData.page = 1

        this.data = nextData
    }

    setInitFilter = (name, value) => {
        this.setFilter(name, value)
        this.data.initFilters[name] = value
    }

    initSorting = (by, direction) => {
        this.data = {
            ...this.data,
            initSorting: { by, direction },
            sorting: { by, direction }
        }
    }

    setSorting = (by, direction, callback) => {
        this.data = {
            ...this.data,
            sorting: {
                by,
                direction: direction
            }
        }

        if (typeof callback === "function") {
            callback()
        }
    }

    changeSorting = (by, direction) => {
        this.setSorting(by, direction)
        this.handleChange()
    }

    initPage = page => {
        this.data = {
            ...this.data,
            page
        }
    }

    changePage = page => {
        this.data = {
            ...this.data,
            page
        }

        this.handleChange()
    }

    onSearch = phrase => {
        this.data = {
            ...this.data,
            page: 1,
            search: phrase
        }

        this.handleChange()
    }

    changeDate = (dateFrom, dateTo) => {
        this.data.dateFrom = dateFrom
        this.data.dateTo = dateTo
        this.data.page = 1

        this.handleChange()
    }

    getLengthFilter() {
        return this.data.filters.find(item => item.name === "length")
    }

    getFilters() {
        const lengthFilter = this.getLengthFilter()

        return lengthFilter ? this.data.filters.filter(item => item.name !== lengthFilter.name) : this.data.filters
    }

    getLength() {
        const lengthFilter = this.getLengthFilter()

        return lengthFilter ? lengthFilter.value : this.props.perPage
    }

    handleChange = ({ init } = { init: false }) => {
        const { sorting, search, page, dateFrom, dateTo } = this.data

        this.props.handleChange(
            {
                sorting,
                filters: this.getFilters(),
                search,
                page,
                dateFrom,
                dateTo,
                length: this.getLength()
            },
            init
        )
    }
}

const Filters = withStyles(headerStyles)(({ cx, children, customClass }) => (
    <div className={cx("filters", customClass)}>{children}</div>
))

FilterableTable.Header = Header
FilterableTable.Content = Content
FilterableTable.Footer = Footer
FilterableTable.SearchBar = SearchBar
FilterableTable.Filter = Filter
FilterableTable.Sort = Sort
FilterableTable.Pagination = Pagination
FilterableTable.MoreFilters = MoreFilters
FilterableTable.PerPage = PerPage
FilterableTable.SearchBar = SearchBar
FilterableTable.Checkbox = Checkbox
FilterableTable.Table = Table
FilterableTable.FetchStatus = FetchStatus
FilterableTable.TableBodySkeleton = TableBodySkeleton
FilterableTable.Link = Link
FilterableTable.DatePicker = DatePicker

export default FilterableTable
export {
    Header,
    Content,
    Footer,
    Filters,
    Filter,
    PerPage,
    Pagination,
    MoreFilters,
    Sort,
    SearchBar,
    Checkbox,
    FetchStatus,
    TableBodySkeleton,
    Table,
    Link,
    DatePicker,
    AppContext,
    AppProvider
}
