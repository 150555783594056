import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"

export function getCompaniesStart() {
    return {
        type: constants.GET_COMPANIES_START
    }
}
export function getCompaniesSuccess(data) {
    return {
        type: constants.GET_COMPANIES_SUCCESS,
        payload: {
            data
        }
    }
}
export function getCompaniesError(error) {
    return {
        type: constants.GET_COMPANIES_ERROR,
        payload: {
            error
        }
    }
}
export function getCompanies() {
    return dispatch => {
        dispatch(getCompaniesStart())

        fetch
            .get(`/companies?length=${LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getCompaniesSuccess(data)))
            .catch(error => {
                dispatch(getCompaniesError(error))
            })
    }
}

function getCompanyStart() {
    return {
        type: constants.GET_COMPANY_START
    }
}
function getCompanySuccess(data) {
    return {
        type: constants.GET_COMPANY_SUCCESS,
        payload: {
            data
        }
    }
}
function getCompanyError(error) {
    return {
        type: constants.GET_COMPANY_ERROR,
        payload: {
            error
        }
    }
}
export function createCompany(name) {
    return dispatch => {
        fetch
            .post(`/companies`, { name })
            .then(data => dispatch(getCompanyById(data[data.length - 1].id)))
            .catch(error => dispatch(getCompanyError(error)))
    }
}

export function editCompany(id, name) {
    return dispatch => {
        fetch
            .patch(`/companies/${id}`, { name })
            .then(() => dispatch(getCompanyById(id)))
            .catch(error => dispatch(getCompanyError(error)))
    }
}

export function getCompanyById(id) {
    return dispatch => {
        dispatch(getCompanyStart())

        fetch
            .get(`/companies/${id}`)
            .then(data => dispatch(getCompanySuccess(data)))
            .catch(error => dispatch(getCompanyError(error)))
    }
}

export function deleteCompanyStart() {
    return {
        type: constants.DELETE_COMPANY_START
    }
}

export function deleteCompanyError(error) {
    return {
        type: constants.DELETE_COMPANY_ERROR,
        payload: {
            error
        }
    }
}

export function deleteCompanySuccess(id) {
    return {
        type: constants.DELETE_COMPANY_SUCCESS,
        payload: id
    }
}

export function deleteCompany(id) {
    return dispatch => {
        dispatch(deleteCompanyStart())

        fetch
            .deleteRAW(`/companies/${id}`)
            .then(() => dispatch(deleteCompanySuccess(id)))
            .catch(error => dispatch(deleteCompanyError(error)))
    }
}
