import { combineReducers } from "redux"

import list from "./list"
import current from "./current"
import filters from "./filters"

const selectionsReducer = combineReducers({
    list,
    current,
    filters
})

export default selectionsReducer
