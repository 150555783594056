import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./Section.css"
import arrow from "../icons/arrow.svg"
import { NavLink, withRouter } from "react-router-dom"
import { MENU, PERMISSIONS } from "constants/index"
import NavButton from "./NavButton"

class Section extends Component {
    render() {
        const {
            details: { icon, name, type, seeMore, isExternal, permissionContext, globalPermissionContext, isAccented },
            isSidebarMenuWide,
            handleClose,
            canAccess,
            canList,
            checkGlobalPermission
        } = this.props

        const routeName = name.toLowerCase()

        const hasNoPermission =
            (permissionContext && !canList(permissionContext)) ||
            (globalPermissionContext && !checkGlobalPermission(globalPermissionContext))

        if (hasNoPermission && !canAccess(permissionContext)) {
            return null
        }

        if (isExternal) {
            return this.renderExternalLink()
        }

        if (type === MENU.LINE) {
            return this.renderLine()
        }

        if (seeMore) {
            return this.renderGroupNavLinks()
        }

        return (
            <div onClick={() => handleClose(false)}>
                <NavLink
                    to={"/" + routeName}
                    className={["nav-tab", isAccented ? "nav-tab-accented" : ""].join(" ")}
                    activeClassName="active-nav-tab"
                >
                    <div
                        className={this.props.details.id && "sidebar-nav-" + this.props.details.id}
                        styleName={isSidebarMenuWide ? "sidebar-nav-li" : "sidebar-nav-li-slim"}
                    >
                        <img src={icon} alt={name} styleName="section-icon" />
                        <h3 styleName="section-name">{name}</h3>
                    </div>
                </NavLink>
            </div>
        )
    }

    renderExternalLink() {
        const { details, isSidebarMenuWide } = this.props
        const { icon, name, path, isNewWindow, customClassName = "" } = details

        return (
            <a href={path} target={isNewWindow ? "_blank" : "_self"} className={["nav-tab", customClassName].join(" ")}>
                <div
                    className={this.props.details.id && "sidebar-nav-" + this.props.details.id}
                    styleName={isSidebarMenuWide ? "sidebar-nav-li" : "sidebar-nav-li-slim"}
                >
                    <img src={icon} alt={name} styleName="section-icon" />
                    <h3 styleName="section-name">{name}</h3>
                </div>
            </a>
        )
    }

    renderLine() {
        return <hr className="line" />
    }

    checkExceptions(item, location) {
        const { exceptions } = item

        if (!exceptions || exceptions.length === 0) {
            return true
        }

        return exceptions.reduce((acc, exception) => acc && location.pathname.search(exception) === -1, true)
    }

    renderGroupNavLinks() {
        const {
            details: { icon, name, customName, seeMore, children, customClassName = "" },
            expandedSection,
            isSidebarMenuWide,
            handleToggle,
            handleClose,
            location,
            canList,
            canEdit,
            canAccess,
            checkGlobalPermission
        } = this.props

        const filteredChildren = children.filter(item => {
            const canListCondition = item.permissions.includes("canList") && canList(item.permissionContext)
            const canAccessCondition = item.permissions.includes("canAccess") && canAccess(item.permissionContext)
            const canEditCondition = item.permissions.includes("canEdit") ? canEdit(item.permissionContext) : true
            const isWebEditor =
                item.permissionContext === PERMISSIONS.context.WEB_EDITOR &&
                checkGlobalPermission(PERMISSIONS.context.WEB_EDITOR)

            return (canEditCondition && canListCondition) || isWebEditor || canAccessCondition
        })

        const routeName = name.toLowerCase()
        const activeTabConfig = {
            orders: [
                ["/orders/sales-orders"],
                ["/orders/invoices"],
                ["/orders/purchase-orders"],
                ["/orders/returns"],
                ["/orders/memo"],
                ["/orders/rental-agreements"],
                ["/orders/payments"],
                ["/orders/store-credits"],
                ["/orders/services"]
            ],
            contact: [["/contact"], ["/contact/awaiting-approval"]],
            products: [["/products"], ["/products/manual-entries"], ["/products/selections"]],
            "online-store": [["/online-store/brands"]],
            content: [
                [
                    "/content/main-page/image-with-pins",
                    "/content/main-page/collections",
                    "/content/main-page/projects",
                    "/content/main-page/our-favourites",
                    "/content/main-page/immediate-delivery"
                ],
                ["/content/inspirations"],
                ["/content/news"],
                ["/content/trades"],
                ["/content/categories-menu", "/content/default-filters"],
                ["/content/portfolio"],
                ["/content/press"]
            ],
            settings: [
                [
                    "/settings/orders/payment-methods",
                    "/settings/orders/email-templates",
                    "/settings/orders/docs-templates"
                ],
                [
                    "/settings/categories",
                    "/settings/brands",
                    "/settings/attributes",
                    "/settings/attribute-groups",
                    "/settings/features",
                    "/settings/combination-template"
                ],
                ["/settings/users", "/settings/users/roles"],
                ["/settings/types", "/settings/client-groups", "/settings/conditions", "/settings/channels"],
                ["/settings/system-events"],
                [
                    "/settings/types",
                    "/settings/interactions",
                    "/settings/conditions",
                    "/settings/channels",
                    "/settings/divisions",
                    "/settings/sources",
                    "/settings/services"
                ]
            ],
            upload: [["/upload/products"], ["/upload/clients"]],
            wms: [
                ["/wms/warehouses"],
                ["/wms/stock-management"],
                ["/wms/stock-transfers"],
                ["/wms/stock-adjustments"],
                ["/wms/receivings"],
                ["/wms/shipments"],
                ["/wms/logistics"]
            ]
        }

        if (filteredChildren.length === 0) {
            return null
        }

        return (
            <div
                onClick={() => handleToggle(routeName)}
                className={[
                    expandedSection === routeName ? "active-nav-tab" : "",
                    isSidebarMenuWide ? "sidebar-menu-wide" : "sidebar-menu-slim"
                ].join(" ")}
            >
                <NavLink
                    to={location.pathname}
                    className={["nav-tab", customClassName].join(" ")}
                    activeClassName={
                        !seeMore || filteredChildren.map(child => child.path).includes(location.pathname)
                            ? "active-nav-tab"
                            : ""
                    }
                >
                    <div styleName={isSidebarMenuWide ? "sidebar-nav-li" : "sidebar-nav-li-slim"}>
                        <img src={icon} alt={name} styleName="section-icon" />
                        <h3 styleName="section-name">{customName ? customName : name}</h3>
                        {seeMore && <img src={arrow} alt="see more" styleName="arrow" />}
                    </div>
                </NavLink>
                <div onClick={e => e.stopPropagation()}>
                    <ul styleName="sub-list">
                        {filteredChildren.map((item, index) => (
                            <li
                                key={index}
                                onClick={() => handleClose(true)}
                                styleName={item.hasBorder ? "hasBorder" : ""}
                            >
                                {item.type === MENU.BUTTON ? (
                                    <NavButton isSidebarMenuWide={isSidebarMenuWide} {...item} />
                                ) : (
                                    <NavLink
                                        to={item.path}
                                        className={`nav-tab ${item.disabled ? "disabled" : ""}`}
                                        isActive={() => {
                                            const currentIndex = children.indexOf(item)
                                            return activeTabConfig[routeName][currentIndex].some(
                                                path =>
                                                    location.pathname.search(path) > -1 &&
                                                    this.checkExceptions(item, location)
                                            )
                                        }}
                                        activeClassName="active-nav-tab"
                                    >
                                        <div styleName={isSidebarMenuWide ? "sidebar-nav-li" : "sidebar-nav-li-slim"}>
                                            <h3 styleName="subsection-name">{item.name}</h3>
                                        </div>
                                    </NavLink>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default withRouter(CSSModules(Section, style))
