import React, { Fragment, useCallback, useMemo, useState, useEffect } from "react"
import { useParams, useHistory } from "react-router-dom"
import { useFormik } from "formik"
import _get from "lodash/get"

import { DetailsCard, H1, H3, Panel, SnakeLoader, Status, Button } from "@butterfly-frontend/ui"
import { Dropdown, SaveBar, SelectWithSearch, Textarea } from "ui"
import { useActions } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useStockItemList } from "modules/WmsModule/hooks/api/useStockItem"
import { useOrderDetails } from "modules/WmsModule/hooks/api/useOrder"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import { useClientShipmentCreate, fetchClientShipmentCreate } from "modules/WmsModule/hooks/api/useShipment"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import { PrimaryLabel, ShipmentAddress } from "modules/WmsModule/components"
import { checkIfCanCreateOrderShipment } from "modules/OrdersModule/helpers/orderShipment"

import StockItemTable from "./StockItemTable"
import CarrierSection from "./CarrierSection"
import useShipmentMultiplePackage from "./useMultipleShipmentPackage"
import validationSchema from "./validationSchema"
import styles from "./CreateShipmentPage.module.css"
import textareaStyles from "./Textarea.module.css"

const MULTIPLE_SHIPMENT_PACKAGE = { id: 1, label: "Send in multiple packages", value: "multiple" }
const ONE_SHIPMENT_PACKAGE = { id: 0, label: "Send in one package", value: "one" }
const SHIPMENT_OPTIONS = [ONE_SHIPMENT_PACKAGE, MULTIPLE_SHIPMENT_PACKAGE]

const INITIAL_PACKAGE_VALUE = {
    carrier: "",
    trackingNumber: "",
    stockItemList: []
}

const CreateShipmentPage = () => {
    const { orderId } = useParams()
    const routerHistory = useHistory()

    const actions = useActions({ showSuccessNotification, showErrorNotification })

    const stockItemList = useStockItemList({ params: { order_id: orderId, invoiced: true } })
    const order = useOrderDetails({ id: orderId })
    const primaryWarehouseList = useWarehouseList({ params: { primary: true } })
    const clientShipmentCreate = useClientShipmentCreate()

    const [selectedShipmentOption, setSelectedShipmentOption] = useState(ONE_SHIPMENT_PACKAGE)
    const [isFetchingMultiplePackages, setIsFetchingMultiplePackages] = useState(false)

    const orderUrl = useMemo(() => `/orders/sales-orders/${orderId}/shipments`, [orderId])

    const stockItemListWithoutShipment = useMemo(
        () => stockItemList.data.filter(stockItem => !stockItem.is_in_client_shipment),
        [stockItemList.data]
    )

    const primaryWarehouse = useMemo(
        () => (primaryWarehouseList.data.length === 1 ? primaryWarehouseList.data[0] : null),
        [primaryWarehouseList]
    )

    const isOnePackageMode = useMemo(() => selectedShipmentOption.value === ONE_SHIPMENT_PACKAGE.value, [
        selectedShipmentOption
    ])

    const calculateShipmentStatusByStockItemStatus = useCallback(stockItemList => {
        return stockItemList.some(stockItem => _get(stockItem, "purchase_order.during_shipment", false))
            ? SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.ON_HOLD]
            : SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.IN_PROGRESS]
    }, [])

    const onCancel = useCallback(() => routerHistory.push(orderUrl), [routerHistory])

    const onSubmit = useCallback(
        values => {
            const { packages, description } = values

            if (isOnePackageMode) {
                const { carrier, trackingNumber, stockItemList } = packages[0]

                return clientShipmentCreate
                    .mutate({
                        stockItemIds: stockItemList.map(({ id }) => id),
                        orderId,
                        trackingNumber,
                        carrier,
                        description
                    })
                    .then(() => {
                        actions.showSuccessNotification()
                        routerHistory.push(orderUrl)
                    })
                    .catch(actions.showErrorNotification)
            }

            setIsFetchingMultiplePackages(true)

            Promise.all(
                packages.map(currentPackage =>
                    fetchClientShipmentCreate({
                        stockItemIds: currentPackage.stockItemList.map(({ id }) => id),
                        carrier: currentPackage.carrier,
                        trackingNumber: currentPackage.trackingNumber,
                        description,
                        orderId
                    })
                )
            )
                .then(() => {
                    actions.showSuccessNotification()
                    routerHistory.push(orderUrl)
                })
                .catch(() => {
                    setIsFetchingMultiplePackages(false)
                    actions.showErrorNotification()
                })
        },
        [selectedShipmentOption, orderId]
    )

    const formik = useFormik({
        initialValues: {
            description: "",
            packages: [INITIAL_PACKAGE_VALUE]
        },
        validationSchema,
        onSubmit
    })

    const {
        notAddedStockItemToPackages,
        checkIsRowSelected,
        handleChangeFieldForOneOfManyPackages,
        handleRemoveStockItemFromShipmentPackage,
        handleCreateShipmentPackage,
        handleAddStockItemToSelectedPackage,
        toggleAllRowSelection,
        toggleRowSelection,
        isAllRowSelected,
        isAnyRowSelected,
        packages,
        selectedRows
    } = useShipmentMultiplePackage({ isOnePackageMode, stockItemList: stockItemListWithoutShipment, formik })

    const shipmentStatusForAllPackages = useMemo(
        () => calculateShipmentStatusByStockItemStatus(stockItemListWithoutShipment),
        [stockItemList, calculateShipmentStatusByStockItemStatus]
    )

    const canCreateShipment = useMemo(
        () => checkIfCanCreateOrderShipment({ orderStatus: _get(order.data, "status") }),
        [order.data]
    )

    useEffect(() => {
        if (isOnePackageMode) {
            formik.setFieldValue("packages", [
                {
                    ...INITIAL_PACKAGE_VALUE,
                    stockItemList: stockItemListWithoutShipment
                }
            ])
        }
    }, [formik.setFieldValue, stockItemListWithoutShipment, isOnePackageMode])

    if (
        stockItemList.fetchStatus.isLoading ||
        order.fetchStatus.isLoading ||
        primaryWarehouseList.fetchStatus.isLoading
    ) {
        return (
            <div className={styles.root}>
                <Panel classes={{ panel: styles.panel }}>
                    <SnakeLoader />
                </Panel>
            </div>
        )
    }

    if (
        stockItemList.fetchStatus.isError ||
        order.fetchStatus.isError ||
        primaryWarehouseList.fetchStatus.isError ||
        stockItemList.data.length === 0 ||
        stockItemListWithoutShipment.length === 0 ||
        !canCreateShipment
    ) {
        return (
            <div className={styles.root}>
                <Panel classes={{ panel: styles.panel }}>
                    <p className={styles.errorMessage}>
                        {stockItemList.data.length === 0
                            ? "There are no stock items available for shipping"
                            : stockItemListWithoutShipment.length === 0
                            ? "All stock items have been added to the shipment"
                            : !canCreateShipment
                            ? "Invalid status order"
                            : "Order not found."}
                    </p>
                </Panel>
            </div>
        )
    }

    return (
        <div className={styles.root}>
            <Panel classes={{ panel: styles.panel }}>
                <H1 withLeftBorder>New shipment</H1>
                <div className={styles.hero}>
                    <DetailsCard header="SO">{order.data.uuid}</DetailsCard>
                    <DetailsCard header="SHIP FROM" classes={{ root: styles.shipFromDetailsCard }}>
                        {primaryWarehouse ? (
                            <span>
                                {primaryWarehouse.name}
                                <PrimaryLabel classes={{ root: styles.primaryLabel }} />
                            </span>
                        ) : (
                            "N/A"
                        )}
                    </DetailsCard>
                    <DetailsCard header="SHIP TO">
                        <ShipmentAddress shipmentAddress={order.data.shipping_address} />
                    </DetailsCard>
                    {isOnePackageMode && (
                        <DetailsCard header="SHIPPING STATUS">
                            <Status color={shipmentStatusForAllPackages.color} size="small" withCircle>
                                {shipmentStatusForAllPackages.label}
                            </Status>
                        </DetailsCard>
                    )}
                    <div className={styles.selectContainer}>
                        <SelectWithSearch
                            label="Shipment"
                            values={SHIPMENT_OPTIONS}
                            value={selectedShipmentOption.label}
                            setValue={setSelectedShipmentOption}
                            multiselect={false}
                            withoutFetch
                            withoutSearch
                            isClearBlocked
                        />
                    </div>
                </div>
                {isOnePackageMode && formik.values.packages.length === 1 ? (
                    <Fragment>
                        <CarrierSection
                            carrier={formik.values.packages[0].carrier}
                            carrierError={_get(formik.errors, "packages[0].carrier")}
                            trackingNumber={formik.values.packages[0].trackingNumber}
                            trackingNumberError={_get(formik.errors, "packages[0].trackingNumber")}
                            onChangeCarrier={value => formik.setFieldValue("packages[0].carrier", value)}
                            onChangeTrackingNumber={value => formik.setFieldValue("packages[0].trackingNumber", value)}
                        />
                        <div className={styles.packageContainer}>
                            <Panel classes={{ panel: styles.packagePanel }}>
                                <div className={styles.packageHeader}>
                                    <H3>Items</H3>
                                </div>
                                <StockItemTable stockItemList={formik.values.packages[0].stockItemList} />
                            </Panel>
                        </div>
                    </Fragment>
                ) : (
                    <div>
                        {notAddedStockItemToPackages.length > 0 && (
                            <div className={styles.packageContainer}>
                                <Panel classes={{ panel: styles.packagePanel }}>
                                    <div className={styles.packageHeader}>
                                        <H3>Items</H3>
                                        <div className={styles.packageHeaderAction}>
                                            {packages.length > 0 && (
                                                <Dropdown
                                                    label="Add to"
                                                    className={styles.addToPackageDropdown}
                                                    options={packages.map((_, index) => ({
                                                        label: `Shipment package ${index + 1}`,
                                                        onClick: () => handleAddStockItemToSelectedPackage(index)
                                                    }))}
                                                    disabled={selectedRows.length === 0}
                                                />
                                            )}
                                            <Button
                                                type="button"
                                                color="blue"
                                                variant="normal"
                                                size="small"
                                                onClick={handleCreateShipmentPackage}
                                                disabled={selectedRows.length === 0}
                                            >
                                                Create shipment package
                                            </Button>
                                        </div>
                                    </div>
                                    <StockItemTable
                                        stockItemList={notAddedStockItemToPackages}
                                        isAllRowSelected={isAllRowSelected}
                                        isAnyRowSelected={isAnyRowSelected}
                                        checkIsRowSelected={checkIsRowSelected}
                                        toggleRowSelection={toggleRowSelection}
                                        toggleAllRowSelection={toggleAllRowSelection}
                                    />
                                </Panel>
                            </div>
                        )}
                        {packages.map((currentPackage, index) => {
                            const packageStatus = calculateShipmentStatusByStockItemStatus(currentPackage.stockItemList)

                            return (
                                <div className={styles.packageContainer} key={index}>
                                    <Panel classes={{ panel: styles.packagePanel }}>
                                        <div className={styles.packageHeader}>
                                            <H3>Shipment package {index + 1}</H3>
                                            <Status color={packageStatus.color} size="regular" withCircle>
                                                {packageStatus.label}
                                            </Status>
                                        </div>
                                        <CarrierSection
                                            carrier={currentPackage.carrier}
                                            carrierError={_get(formik.errors, `packages[${index}].carrier`)}
                                            trackingNumber={currentPackage.trackingNumber}
                                            trackingNumberError={_get(
                                                formik.errors,
                                                `packages[${index}].trackingNumber`
                                            )}
                                            onChangeCarrier={value =>
                                                handleChangeFieldForOneOfManyPackages(index, "carrier", value)
                                            }
                                            onChangeTrackingNumber={value =>
                                                handleChangeFieldForOneOfManyPackages(index, "trackingNumber", value)
                                            }
                                        />
                                        <StockItemTable
                                            stockItemList={currentPackage.stockItemList}
                                            onRemoveStockItemFromPackage={stockItem =>
                                                handleRemoveStockItemFromShipmentPackage(index, stockItem)
                                            }
                                        />
                                    </Panel>
                                </div>
                            )
                        })}
                    </div>
                )}
                <div className={styles.description}>
                    <Textarea
                        label="Description"
                        placeholder="Click here to add a description..."
                        value={formik.values.description}
                        name="description"
                        onChange={({ event }) => formik.handleChange(event)}
                        customStyles={textareaStyles}
                        error={formik.errors.description}
                    />
                </div>
            </Panel>
            <SaveBar
                isShown
                isDisabled={!isOnePackageMode && notAddedStockItemToPackages.length > 0}
                isDisabledCancel={clientShipmentCreate.fetchStatus.isLoading || isFetchingMultiplePackages}
                isSaving={clientShipmentCreate.fetchStatus.isLoading || isFetchingMultiplePackages}
                isSubmit
                onCancel={onCancel}
                onSubmit={formik.submitForm}
                submitLabel="Create shipment"
            />
        </div>
    )
}

export default CreateShipmentPage
