import * as fetchStatus from "./fetchStatus"
import { ITEMS_PER_PAGE } from "constants/index"
import { objToQueryString } from "helpers/queryParams"

export const transformParamsToQueryString = params => {
    const { sortBy, sortDirection, search, filters, dateFrom, dateTo, page, length, ...rest } = params

    const queryStringParams = { ...rest }

    if (sortBy && sortDirection) {
        queryStringParams.sort_by = sortBy
        queryStringParams.sort_direction = sortDirection
    }

    if (search) {
        queryStringParams.query = search
    }

    if (Array.isArray(filters)) {
        filters
            .filter(item => item.value !== "all")
            .filter(item => item.value !== "any")
            .filter(item => item.value !== "")
            .forEach(value => {
                queryStringParams[value.name] = value.value
            })
    }

    dateFrom && (queryStringParams.from = dateFrom)
    dateTo && (queryStringParams.to = dateTo)
    queryStringParams.page = page || 1
    queryStringParams.length = length || ITEMS_PER_PAGE

    return objToQueryString(queryStringParams)
}

export const queryStringFromParams = (
    { dateFrom, dateTo, sorting, filters, page, length, search, ...rest } = {
        dateFrom: null,
        dateTo: null
    },
    nextMapKeys = {}
) => {
    const obj = { ...rest }
    const mapKeys = {
        query: "query",
        sort_by: "sort_by",
        sort_direction: "sort_direction",
        from: "from",
        to: "to",
        page: "page",
        length: "length",
        ...nextMapKeys
    }

    if (sorting && sorting.by && sorting.direction) {
        obj[mapKeys.sort_by] = sorting.by
        obj[mapKeys.sort_direction] = sorting.direction
    }

    if (search) {
        obj[mapKeys.query] = search
    }

    if (Array.isArray(filters)) {
        filters
            .filter(item => item.value !== "all")
            .filter(item => item.value !== "any")
            .filter(item => item.value !== "")
            .forEach(value => {
                obj[value.name] = value.value
            })
    }

    if (dateFrom) {
        obj[mapKeys.from] = dateFrom
    }

    if (dateTo) {
        obj[mapKeys.to] = dateTo
    }

    obj[mapKeys.page] = page || 1
    obj[mapKeys.length] = length || ITEMS_PER_PAGE

    return objToQueryString(obj)
}

export default {
    fetchStatus
}
