import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./Client.css"

class Client extends Component {
    render() {
        const { id, first_name, last_name, email, handleWorkWith } = this.props
        return (
            <li styleName="client-li">
                <span styleName="name-cover" title={`${first_name || "-"} ${last_name}`}>
                    <h2 styleName="name">{first_name || "-"}</h2>
                    {last_name && <h2 styleName="surname">{last_name}</h2>}
                </span>
                <h3 styleName="email" title={email}>
                    {email}
                </h3>
                <button className="second-button" onClick={() => handleWorkWith(id)} styleName="work-with-button">
                    Work With
                </button>
                <div className="clear" />
            </li>
        )
    }
}

export default CSSModules(Client, style)
