import React, { Fragment, useState } from "react"
import { Formik, Form as FormikForm, FieldArray, Field } from "formik"
import PropTypes from "prop-types"
import * as yup from "yup"

import withStyles from "HOC/withStyles"
import { Modal, Button, Input, Checkbox } from "ui"

import styles from "./ShareModal.css"
import modalStyles from "./overrides/modalStyles.css"
import modalBodyStyles from "./overrides/modalBodyStyles.css"
import modalActionsStyles from "./overrides/modalActions.css"
import checkboxStyles from "./overrides/Checkbox.css"
import inputStyles from "./overrides/Input.css"

const ShareModal = props => {
    const { cx, isOpen, handleClose, rooms, emails, handleSubmit, removeShareEmail, errors } = props

    const [hideDetails, setHideDetails] = useState(true)
    const [saving, setSaving] = useState(false)

    const toggleHideDetails = () => {
        setHideDetails(!hideDetails)
    }

    const toggleAllCheckboxes = (form, field) => {
        const shouldCheck = form.values.rooms.filter(item => !item[field]).length > 0

        form.setFieldValue("rooms", [
            ...form.values.rooms.map(item => ({
                ...item,
                [field]: shouldCheck
            }))
        ])
    }

    const addEmail = form => {
        form.setFieldValue("emails", [
            ...form.values.emails,
            {
                email: ""
            }
        ])

        setTimeout(() => {
            const modalBodyWrapper = document.getElementById("modalBodyWrapper")
            modalBodyWrapper.scrollTop = modalBodyWrapper.offsetHeight
        }, 0)
    }

    const removeEmail = async (form, index) => {
        if (form.values.emails[index].id) {
            setSaving(true)
            await removeShareEmail(form.values.emails[index].id)
            setSaving(false)
        }

        const emails = form.values.emails.filter((item, itemIndex) => index !== itemIndex)

        form.setFieldValue(
            "emails",
            emails.length === 0
                ? [
                      {
                          email: ""
                      }
                  ]
                : emails
        )
    }

    const onSubmit = async values => {
        setSaving(true)
        await handleSubmit({
            emails: values.emails.map(item => ({
                email: item.email
            })),
            options: values.rooms.map(item => ({
                room_id: item.room_id,
                pricing_visibility: item.price,
                naming_visibility: item.product,
                vendor_visibility: item.brand
            }))
        })
        setSaving(false)
    }

    const initialValues = {
        rooms,
        emails:
            emails.length === 0
                ? [
                      {
                          email: ""
                      }
                  ]
                : emails
    }

    return (
        <Modal isOpen={isOpen} closeModal={handleClose} customStyles={modalStyles} preventClickOutside={true}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                validationSchema={yup.object().shape({
                    emails: yup.array().of(
                        yup.object({
                            email: yup
                                .string()
                                .email("Incorrect email address")
                                .required("Incorrect email address")
                        })
                    )
                })}
            >
                {propsForm => {
                    const canAddNewEmail = propsForm.values.emails.filter(item => !item.email).length === 0
                    return (
                        <FormikForm>
                            <Modal.Header>Share Project</Modal.Header>
                            <Modal.Body customStyles={modalBodyStyles}>
                                <div id="modalBodyWrapper" className={cx("modalBodyWrapper")}>
                                    <FieldArray
                                        name="rooms"
                                        render={arrayHelpers => (
                                            <ul className={cx("rooms")}>
                                                <li className={cx("singleRoom")}>
                                                    <span className={cx("roomName")}>All rooms</span>
                                                    <span className={cx("checkboxes")}>
                                                        <Checkbox
                                                            name="Brand"
                                                            onChange={() => toggleAllCheckboxes(propsForm, "brand")}
                                                            checked={propsForm.values.rooms.some(item => item.brand)}
                                                            isMinusIcon={propsForm.values.rooms.some(
                                                                item => !item.brand
                                                            )}
                                                            customStyles={checkboxStyles}
                                                        />
                                                        <Checkbox
                                                            name="Product name"
                                                            onChange={() => toggleAllCheckboxes(propsForm, "product")}
                                                            checked={propsForm.values.rooms.some(item => item.product)}
                                                            isMinusIcon={propsForm.values.rooms.some(
                                                                item => !item.product
                                                            )}
                                                            customStyles={checkboxStyles}
                                                        />
                                                        <Checkbox
                                                            name="Price"
                                                            customStyles={checkboxStyles}
                                                            onChange={() => toggleAllCheckboxes(propsForm, "price")}
                                                            checked={propsForm.values.rooms.some(item => item.price)}
                                                            isMinusIcon={propsForm.values.rooms.some(
                                                                item => !item.price
                                                            )}
                                                        />
                                                    </span>
                                                </li>
                                                {!hideDetails && (
                                                    <Fragment>
                                                        {propsForm.values.rooms.map((room, index) => (
                                                            <li
                                                                className={cx("singleRoom")}
                                                                key={`${index}-${room.id}`}
                                                            >
                                                                <span className={cx("roomName")}>{room.name}</span>
                                                                <span className={cx("checkboxes")}>
                                                                    <Checkbox
                                                                        name="Brand"
                                                                        customStyles={checkboxStyles}
                                                                        onChange={() =>
                                                                            arrayHelpers.replace(index, {
                                                                                ...room,
                                                                                brand: !room.brand
                                                                            })
                                                                        }
                                                                        checked={room.brand}
                                                                    />
                                                                    <Checkbox
                                                                        name="Product name"
                                                                        customStyles={checkboxStyles}
                                                                        onChange={() =>
                                                                            arrayHelpers.replace(index, {
                                                                                ...room,
                                                                                product: !room.product
                                                                            })
                                                                        }
                                                                        checked={room.product}
                                                                    />
                                                                    <Checkbox
                                                                        name="Price"
                                                                        customStyles={checkboxStyles}
                                                                        onChange={() =>
                                                                            arrayHelpers.replace(index, {
                                                                                ...room,
                                                                                price: !room.price
                                                                            })
                                                                        }
                                                                        checked={room.price}
                                                                    />
                                                                </span>
                                                            </li>
                                                        ))}
                                                    </Fragment>
                                                )}
                                                <li className={cx("singleRoom")}>
                                                    <span className={cx("hideButton")} onClick={toggleHideDetails}>
                                                        <span className={cx("ico")}>
                                                            <span
                                                                className={cx("triangle", {
                                                                    "triangle-up": !hideDetails
                                                                })}
                                                            />
                                                        </span>
                                                        {hideDetails ? "Show more" : "Hide"}
                                                    </span>
                                                </li>
                                            </ul>
                                        )}
                                    />

                                    <FieldArray
                                        name="emails"
                                        render={arrayHelpers => (
                                            <div className={cx("clients")}>
                                                {propsForm.values.emails.map((email, index) => (
                                                    <div className={cx("client")} key={`${index}-${email}`}>
                                                        <Field name={`emails[${index}].email`}>
                                                            {({ field }) => {
                                                                return (
                                                                    <Fragment>
                                                                        <Input
                                                                            {...field}
                                                                            label={
                                                                                index === 0
                                                                                    ? "Client's email"
                                                                                    : "E-mail Address"
                                                                            }
                                                                            readOnly={!!email.id}
                                                                            onChange={({ event }) => {
                                                                                propsForm.setErrors([])
                                                                                field.onChange(event)
                                                                            }}
                                                                            isPlaceholder={index !== 0}
                                                                            error={
                                                                                (
                                                                                    (propsForm.errors.emails || [])[
                                                                                        index
                                                                                    ] || {}
                                                                                ).email ||
                                                                                (errors || {})[`emails.${index}.email`]
                                                                            }
                                                                            customStyles={inputStyles}
                                                                        />
                                                                        {field.value && (
                                                                            <span
                                                                                className={cx("delete")}
                                                                                onClick={() =>
                                                                                    removeEmail(propsForm, index)
                                                                                }
                                                                            >
                                                                                Delete
                                                                            </span>
                                                                        )}
                                                                    </Fragment>
                                                                )
                                                            }}
                                                        </Field>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    />
                                </div>
                            </Modal.Body>

                            <Modal.Actions customStyles={modalActionsStyles}>
                                <span
                                    onClick={() => (canAddNewEmail ? addEmail(propsForm) : null)}
                                    className={cx("addEmailButton", {
                                        isDisabled: !canAddNewEmail
                                    })}
                                >
                                    Add {propsForm.values.emails.length > 0 && "more"} email
                                </span>
                                <Button className={cx("first-button")} label="Send" isLoading={saving} />
                            </Modal.Actions>
                        </FormikForm>
                    )
                }}
            </Formik>
        </Modal>
    )
}

ShareModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    rooms: PropTypes.array.isRequired,
    emails: PropTypes.array.isRequired,
    removeShareEmail: PropTypes.func.isRequired
}

export default withStyles(ShareModal, styles)
