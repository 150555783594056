import React, { Component } from "react"

import withStyles from "HOC/withStyles"
import InfiniteScroll from "ui/InfiniteScroll"
import { SearchBar } from "ui"
import Checkbox from "ui/Checkbox"
import Button from "components/Reusable/Form/Button"
import Image from "modules/ContentModule/components/Image"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import style from "./SelectFromList.css"
import checkboxStyles from "./overrides/Checkbox.css"
import close from "assets/close.svg"
import iconImage from "assets/ico-image.svg"

class SelectFromList extends Component {
    render() {
        const {
            children,
            cx,
            meta,
            query,
            typeItems,
            isLoading,
            maxSelectedItems,
            selectedItems,
            displayedItems,
            handleSelect,
            handleSubmit,
            handleSearch,
            handleLoadMore,
            handleClose,
            prepareItem,
            isSaving
        } = this.props

        const isDisabledItems = selectedItems.length >= maxSelectedItems

        if (typeof children === "function") {
            return children({
                ...this.props,
                isDisabledItems
            })
        }

        return (
            <div className={cx("modal-background")}>
                <div className={cx("modal-cover")}>
                    <img src={close} alt="close" onClick={() => handleClose()} />
                    <div className={cx("header")}>
                        <h2>{selectedItems.length ? `${selectedItems.length} selected` : `Add ${typeItems}`}</h2>
                        {maxSelectedItems && <span className={cx("maxItems")}>(max. {maxSelectedItems} items)</span>}
                    </div>
                    <div className={cx("searchbar")}>
                        <SearchBar
                            placeholder={`Search and add ${typeItems}…`}
                            handleSearch={handleSearch}
                            clearInput={() => handleSearch("")}
                            filter={query}
                        />
                    </div>
                    {isLoading && (
                        <div className={cx("loader")}>
                            <SnakeLoader />
                        </div>
                    )}

                    {!isLoading && !displayedItems.length && (
                        <div className={cx("emptyList")}>
                            <h3>Sorry, no results found!</h3>
                            <p>Please check the spelling or try searching for something else.</p>
                        </div>
                    )}

                    {!isLoading && !!displayedItems.length && (
                        <div className={cx("list-wrapper")}>
                            <InfiniteScroll
                                loadMore={handleLoadMore}
                                hasMore={meta.current_page < meta.last_page}
                                loader={
                                    <div className={cx("loader")} key={0}>
                                        <SnakeLoader />
                                    </div>
                                }
                                useWindow={false}
                            >
                                <ul className={cx("list")}>
                                    {displayedItems.map(item => {
                                        const selected = selectedItems.some(selectedItem => selectedItem.id === item.id)
                                        const preparedItem = prepareItem(item)

                                        return (
                                            <li
                                                key={item.id}
                                                className={cx({ disabled: !selected && isDisabledItems, selected })}
                                            >
                                                <Checkbox
                                                    checked={selected}
                                                    name={preparedItem.name}
                                                    onChange={() => handleSelect(item)}
                                                    customStyles={checkboxStyles}
                                                    disabled={!selected && isDisabledItems}
                                                />
                                                {preparedItem.img ? (
                                                    <div className={cx("image")}>
                                                        <Image src={preparedItem.img} />
                                                    </div>
                                                ) : (
                                                    <div className={cx("imagePlaceholder")}>
                                                        <img src={iconImage} alt="cover placeholder" />
                                                    </div>
                                                )}
                                                <h4>{preparedItem.left}</h4>
                                                <h5>{preparedItem.right}</h5>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </InfiniteScroll>
                        </div>
                    )}
                    <div className={cx("buttons")}>
                        <Button className={cx("cancel-button")} onClick={() => handleClose()} label="Cancel" />
                        <Button
                            className={cx("first-button")}
                            isLoading={isSaving}
                            isDisabled={selectedItems.length === 0 || !displayedItems.length}
                            onClick={handleSubmit}
                            label="Done"
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(SelectFromList, style)
