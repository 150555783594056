const ns = "modals/chooseProject/"

export const GET_PROJECTS_REQUEST = ns + "GET_PROJECTS_REQUEST"
export const GET_PROJECTS_SUCCESS = ns + "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_FAILURE = ns + "GET_PROJECTS_FAILURE"
export const RESET_PROJECTS = ns + "RESET_PROJECTS"

export const GET_MORE_PROJECTS_REQUEST = ns + "GET_MORE_PROJECTS_REQUEST"
export const GET_MORE_PROJECTS_SUCCESS = ns + "GET_MORE_PROJECTS_SUCCESS"
export const GET_MORE_PROJECTS_FAILURE = ns + "GET_MORE_PROJECTS_FAILURE"
