import React, { Fragment } from "react"
import withStyles from "HOC/withStyles"
import styles from "./Image.css"

const Image = ({ cx, id, path, title, mainImageLabel, handleDeleteImage, onCrop }) => {
    return (
        <Fragment>
            <div
                className={cx("tile", {
                    isTooltip: title ? title.length > 30 : false
                })}
            >
                <svg className={cx("drag")} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g fill="none" fillRule="evenodd">
                        <path d="M3 3h18v18H3z" />
                        <path
                            fill="#909090"
                            fillRule="nonzero"
                            d="M9.5 6C8.675 6 8 6.675 8 7.5S8.675 9 9.5 9 11 8.325 11 7.5 10.325 6 9.5 6zm0 4.5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5S11 12.825 11 12s-.675-1.5-1.5-1.5zm0 4.5c-.825 0-1.5.675-1.5 1.5S8.675 18 9.5 18s1.5-.675 1.5-1.5-.675-1.5-1.5-1.5zm4.5 0c-.825 0-1.5.675-1.5 1.5S13.175 18 14 18s1.5-.675 1.5-1.5S14.825 15 14 15zm0-4.5c-.825 0-1.5.675-1.5 1.5s.675 1.5 1.5 1.5 1.5-.675 1.5-1.5-.675-1.5-1.5-1.5zM14 6c-.825 0-1.5.675-1.5 1.5S13.175 9 14 9s1.5-.675 1.5-1.5S14.825 6 14 6z"
                        />
                    </g>
                </svg>

                {mainImageLabel && <div className={cx("mainImageLabel")}>Main image</div>}

                {!!onCrop && (
                    <div className={cx("crop")} onClick={onCrop}>
                        <div className={cx("cropIcon")} />
                    </div>
                )}

                {handleDeleteImage && (
                    <div className={cx("delete")} onClick={() => handleDeleteImage(id, path)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8">
                            <path
                                fill="none"
                                fillRule="evenodd"
                                stroke="#788195"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M5 4L1 0l4 4 4-4-4 4zm0 0l4 4-4-4-4 4 4-4z"
                            />
                        </svg>
                    </div>
                )}
                <div className={cx("img-wrapper")}>
                    <div className={cx("img")} style={{ backgroundImage: `url(${path})` }}></div>
                </div>
                <p className={cx("title")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <g fill="none" fillRule="evenodd">
                            <path d="M3 3h18v18H3z" />
                            <path
                                fill="#909090"
                                fillRule="nonzero"
                                d="M18.75 17.25V6.75c0-.825-.675-1.5-1.5-1.5H6.75c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5zm-9.375-4.125l1.875 2.258L13.875 12l3.375 4.5H6.75l2.625-3.375z"
                            />
                        </g>
                    </svg>
                    {title}
                </p>
                <span className={cx("tooltip")}>{title}</span>
            </div>
        </Fragment>
    )
}

export default withStyles(Image, styles)
