import React, { Fragment, useEffect, useState } from "react"
import { connect } from "react-redux"

import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"
import * as notificationActions from "actions/notification"
import { queryStringFromParams } from "ui/FilterableTable/helpers"

import ListingLayout from "components/Content/ContentMain/Settings/ListingLayout"
import EditableList from "./components/EditableList"
import Header from "./components/Header"

import styles from "./styles.css"

const List = ({ showErrorNotification, cx }) => {
    const [callParams, setCallParams] = useState({
        page: 1,
        sort_by: "name",
        sort_direction: "asc",
        query: "",
        custom_vendor: false
    })
    const [brands, setBrands] = useState({
        data: [],
        meta: {}
    })
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetchBrands()
    }, [callParams])

    const fetchBrands = async () => {
        setIsLoading(true)

        try {
            const brandsResult = await fetch.get(`/brands?${queryStringFromParams(callParams)}`)

            setBrands(brandsResult)
        } catch (error) {
            showErrorNotification()
        }

        setIsLoading(false)
    }

    const handleDeleteBrand = id => {
        fetch.deleteRAW(`/brands/${id}`).then(() => {
            fetchBrands()
        })
    }

    const handleChangeTable = (params, isInit) => {
        !isInit &&
            setCallParams({
                ...callParams,
                sort_by: params.sorting.by || "name",
                sort_direction: params.sorting.direction || "asc",
                query: params.search,
                page: 1
            })
    }

    const changeCustomVendor = value => {
        setCallParams({
            ...callParams,
            custom_vendor: value,
            page: 1
        })
    }

    const changePage = page => {
        setCallParams({
            ...callParams,
            page
        })
    }

    return (
        <Fragment>
            <div className={cx("listingWrapper")}>
                <ListingLayout />
            </div>

            <Header addUrl={"/settings/brands/add"} count={(brands.meta || {}).total || 0} />

            <EditableList
                brands={brands}
                listTitle={"Brands"}
                confirmModalTitle={"Are you sure you want to delete this brand?"}
                editUrlPrefix={"/settings/brands"}
                isLoading={isLoading}
                deleteElement={handleDeleteBrand}
                handleChangeTable={handleChangeTable}
                changeCustomVendor={changeCustomVendor}
                customVendor={callParams.custom_vendor}
                changePage={changePage}
                page={callParams.page}
            />
        </Fragment>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
    }
}

export default connect(mapDispatchToProps)(withStyles(List, styles))
