import React, { Fragment, useEffect, useMemo, useState, useCallback } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useFormik } from "formik"
import _isEqual from "lodash/isEqual"
import _pick from "lodash/pick"
import _omit from "lodash/omit"
import {
    TableRowWithExpandedDetails,
    TableCell,
    Button,
    Loader,
    ImageTile,
    ActionsDropList,
    DeleteIcon,
    AcceptedIcon,
    CloudIcon,
    PrinterIcon
} from "@butterfly-frontend/ui"

import { PERMISSIONS } from "constants/index"
import withPermissions from "HOC/withPermissions"
import { StatusSelectProductConditions } from "ui/Filters/SelectProductConditions"
import UploadImages from "modules/WmsModule/modals/UploadImages"
import { useConditionPhotosModal, useStockItemConditionChange } from "modules/WmsModule/hooks"
import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"
import { StockItemDetailsBoxList, IncrementalCounter } from "modules/WmsModule/components"
import { BoxListFormTable, useBoxListForm, BOX_LIST_FORM_TABLE_COLUMNS } from "modules/WmsModule/components/BoxListForm"
import { STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS } from "modules/WmsModule/components/StockItemDetailsBoxList"
import { SHIPMENT_PACK_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"
import TABLE_COLUMNS from "modules/WmsModule/pages/ReceivingDetailsPage/constants/tableColumns"
import { mapItemConditionToSelect } from "modules/WmsModule/pages/ReceivingDetailsPage/helpers"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import {
    useReceivingShipmentEdit,
    useShipmentStockItemEdit
} from "modules/WmsModule/pages/ReceivingDetailsPage/ReceivePackagePage/hooks"
import useDocument, { DOCUMENT_ACTIONS } from "modules/WmsModule/hooks/useDocument"
import BOX_LABEL_TYPES from "modules/WmsModule/constants/boxLabelsTypes"
import BOX_LABEL_TEMPLATES from "modules/WmsModule/constants/boxLabelsTemplates"

import validationSchema from "./validationSchema"
import styles from "./ReceivePackageTableRow.module.css"

const CUSTOM_STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS = {
    ...STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS,
    NAME: {
        ...STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS.NAME,
        width: "22%",
        minWidth: 100,
        classes: {
            root: styles.boxesNameCell
        }
    },
    LABEL: {
        ...STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS.LABEL,
        width: "28%",
        compactWidth: "53%"
    }
}

const CUSTOM_BOX_LIST_FORM_TABLE_COLUMNS = {
    ...BOX_LIST_FORM_TABLE_COLUMNS,
    NAME: {
        ...BOX_LIST_FORM_TABLE_COLUMNS.NAME,
        width: "22%",
        minWidth: 100,
        classes: {
            root: styles.boxesNameCell
        }
    },
    INPUT: {
        ...BOX_LIST_FORM_TABLE_COLUMNS.INPUT,
        width: "28%"
    }
}

const BOX_FIELDS_TO_COMPARE = ["id", "label", "sku"]

const checkItemIsDamaged = type => type === CONDITION_TYPES.DAMAGED

const checkIfAllBoxesAreAccepted = boxes => (boxes.length > 0 ? boxes.every(box => box.accepted) : false)

const mapBoxesToCompare = boxes => boxes.map(box => _pick(box, BOX_FIELDS_TO_COMPARE))

const areBoxesEqual = (stockItemBoxes, formBoxes) =>
    _isEqual(mapBoxesToCompare(stockItemBoxes), mapBoxesToCompare(formBoxes))

const validateCondition = ({ condition, conditionImages }) => validationSchema.validate({ condition, conditionImages })

const ReceivePackageTableRow = props => {
    const { index, item, shipment, defaultCondition, isBarcodeReaderEnabled, canEdit } = props

    const initialItemCondition = useMemo(
        () =>
            shipment.isFromPO && defaultCondition && item.condition.type === CONDITION_TYPES.NEW
                ? defaultCondition
                : mapItemConditionToSelect(item.condition),
        [shipment.isFromPO, defaultCondition, item.condition]
    )

    const [selectedCondition, setSelectedCondition] = useState(initialItemCondition)

    const [conditionPhotos, setConditionPhotos] = useState(item.condition.images || [])
    const [rowErrors, setRowErrors] = useState([])

    const { shipmentStockItemEdit } = useShipmentStockItemEdit({ shipment, item })
    const { receivingShipmentEdit } = useReceivingShipmentEdit({ shipment, item })

    const isStockItemReceived = typeof item.acceptance === "boolean"

    const acceptRow = useCallback(
        () =>
            receivingShipmentEdit.mutate({
                id: shipment.id,
                data: {
                    [checkItemIsDamaged(selectedCondition.value.type)
                        ? "items_to_be_rejected"
                        : "items_to_be_accepted"]: [item.shipment_stock_item_id]
                }
            }),
        [receivingShipmentEdit.mutate, shipment.id, item.shipment_stock_item_id, selectedCondition]
    )

    const onChangeAcceptedStatus = useCallback(
        () =>
            validateCondition({ condition: selectedCondition, conditionImages: conditionPhotos })
                .then(acceptRow)
                .catch(({ errors }) => setRowErrors(errors)),
        [selectedCondition, conditionPhotos, acceptRow]
    )

    const updateConditionPhotos = photos => {
        setConditionPhotos(photos)

        return shipmentStockItemEdit
            .mutate({
                id: item.id,
                data: {
                    condition: {
                        product_condition_id: selectedCondition.id,
                        images_ids: photos.map(({ id }) => id)
                    }
                }
            })
            .catch(() => {
                setSelectedCondition(initialItemCondition)
                setConditionPhotos(item.condition.images)
            })
    }

    const { mutate: printAllBoxLabels, isLoading: isPrintingAllBoxes } = useDocument({
        actionType: DOCUMENT_ACTIONS.PRINT,
        documentType: BOX_LABEL_TYPES.STOCK_ITEM,
        templateType: BOX_LABEL_TEMPLATES.WITHOUT_TEMPLATE
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            boxes: item.boxes || []
        },
        onSubmit: ({ boxes }) =>
            shipmentStockItemEdit
                .mutate({
                    id: item.id,
                    data: {
                        boxes: boxesHasBeenChanged ? boxes : undefined
                    }
                })
                .then(() => printAllBoxLabels({ ids: item.id }))
    })

    const { boxCounterProps, boxTableProps, boxes } = useBoxListForm({ formik, isDisabled: isPrintingAllBoxes })
    const { boxes: boxesForListForm, ...restBoxTableProps } = boxTableProps

    const {
        isPhotosModalOpen,
        onOpenUploadConditionPhotosModal,
        onCloseUploadConditionPhotosModal,
        onUploadConditionPhotos,
        onRemoveConditionPhoto
    } = useConditionPhotosModal({ conditionPhotos, setConditionPhotos: updateConditionPhotos })

    const { onSelectCondition, isLoading: isConditionLoading } = useStockItemConditionChange({
        stockItemEditMutation: shipmentStockItemEdit,
        item,
        checkItemIsDamaged,
        selectedCondition,
        setSelectedCondition,
        initialItemCondition
    })

    const onChangeCondition = value =>
        validateCondition({ condition: value, conditionImages: conditionPhotos })
            .then(() => onSelectCondition(value))
            .catch(({ errors }) => {
                setSelectedCondition(value)
                setRowErrors(errors)
            })

    const { options: boxOptions, isLoading: isBoxOptionsLoading } = useBoxLabelOptions({
        ids: [item.id]
    })

    useEffect(() => {
        if (!isStockItemReceived && checkIfAllBoxesAreAccepted(item.boxes)) {
            onChangeAcceptedStatus()
        }
    }, [item.boxes, isStockItemReceived, onChangeAcceptedStatus])

    useEffect(() => {
        if (!isStockItemReceived && item.entireItemBarcodeRead) {
            onChangeAcceptedStatus()
        }
    }, [item.entireItemBarcodeRead, isStockItemReceived, onChangeAcceptedStatus])

    useEffect(() => {
        if (rowErrors.length > 0) {
            setRowErrors([])
        }
    }, [selectedCondition, conditionPhotos])

    useEffect(() => {
        setConditionPhotos(item.condition.images || [])
    }, [item.condition.images])

    const isChosenToDamagedItem = checkItemIsDamaged(selectedCondition.value.type)
    const isDamagedItem = checkItemIsDamaged(item.condition.type)
    const isRejectedItem = item.acceptance === false
    const isDisabledRow = isStockItemReceived || shipment.isShipmentDelivered
    const boxesHasBeenChanged = useMemo(() => !areBoxesEqual(item.boxes, formik.values.boxes), [
        item.boxes,
        formik.values.boxes
    ])

    const saveAndPrintBoxes = useCallback(
        () => (boxesHasBeenChanged ? formik.handleSubmit() : printAllBoxLabels({ ids: item.id })),
        [boxesHasBeenChanged, formik.handleSubmit, printAllBoxLabels, item.id]
    )

    return (
        <Fragment>
            <TableRowWithExpandedDetails
                classes={{
                    root: classNames(styles.rowRoot, { [styles.disabled]: isDisabledRow }),
                    detailsComponent: styles.rowDetails
                }}
                detailsComponent={
                    <Fragment>
                        {rowErrors.length > 0 && (
                            <div className={styles.noConditionPhotosError}>{rowErrors.join(", ")}</div>
                        )}

                        {conditionPhotos.length > 0 && (
                            <div className={classNames(styles.conditionImageList)}>
                                {conditionPhotos.map(image => (
                                    <ImageTile
                                        key={image.id}
                                        onDelete={() => onRemoveConditionPhoto(image.id)}
                                        image={image}
                                        classes={{ imageBox: styles.conditionImage }}
                                    />
                                ))}
                            </div>
                        )}

                        {boxes.length > 0 && (
                            <div className={styles.boxes}>
                                {item.acceptance === null && canEdit() ? (
                                    <BoxListFormTable
                                        {...restBoxTableProps}
                                        boxes={boxesForListForm.map(box => ({
                                            ..._omit(box, "location"),
                                            selected: box.accepted
                                        }))}
                                        customColumns={CUSTOM_BOX_LIST_FORM_TABLE_COLUMNS}
                                        compactView
                                    />
                                ) : (
                                    <StockItemDetailsBoxList
                                        boxes={item.boxes}
                                        customColumns={CUSTOM_STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS}
                                        compactView
                                    />
                                )}
                            </div>
                        )}

                        <div className={styles.rowActions}>
                            {boxes.length > 0 && (
                                <Fragment>
                                    <Button
                                        size="small"
                                        color="blue"
                                        Icon={PrinterIcon}
                                        onClick={saveAndPrintBoxes}
                                        classes={{ button: styles.saveBoxesButton }}
                                        isLoading={shipmentStockItemEdit.fetchStatus.isLoading || isPrintingAllBoxes}
                                    >
                                        {boxesHasBeenChanged ? "Save & Print box labels" : "Print box labels"}
                                    </Button>
                                </Fragment>
                            )}
                        </div>
                    </Fragment>
                }
                isExpanded={
                    boxes.length > 0 ||
                    conditionPhotos.length > 0 ||
                    item.condition.images.length > 0 ||
                    rowErrors.length > 0 ||
                    boxesHasBeenChanged
                }
            >
                <TableCell {...TABLE_COLUMNS.no} classes={{ root: styles.cell }}>
                    {index + 1}
                </TableCell>
                <TableCell {...TABLE_COLUMNS.product} classes={{ root: styles.cell }}>
                    <div className={styles.productName}>{item.name}</div>
                </TableCell>
                <TableCell {...TABLE_COLUMNS.id} classes={{ root: styles.cell }}>
                    {item.id}
                </TableCell>
                <TableCell {...TABLE_COLUMNS.condition} classes={{ root: styles.conditionCell }}>
                    <StatusSelectProductConditions
                        excludedTypes={[CONDITION_TYPES.REMOVED]}
                        value={selectedCondition}
                        onChange={onChangeCondition}
                        disabled={isStockItemReceived || shipmentStockItemEdit.isLoading || !canEdit()}
                        isLoading={isConditionLoading}
                    />
                    {shipmentStockItemEdit.isLoading && !checkItemIsDamaged(selectedCondition.type) && (
                        <Loader classes={styles.conditionLoader} />
                    )}
                </TableCell>
                <TableCell {...TABLE_COLUMNS.packAction} classes={{ root: styles.packActionCell }}>
                    <Button
                        variant="flat"
                        size="small"
                        color={isChosenToDamagedItem || isDamagedItem ? "red" : "white"}
                        Icon={CloudIcon}
                        onClick={onOpenUploadConditionPhotosModal}
                        disabled={shipmentStockItemEdit.fetchStatus.isLoading || isStockItemReceived || !canEdit()}
                    >
                        Add photos
                    </Button>
                    <div className={styles.buttonDivider} />
                    <IncrementalCounter
                        {...boxCounterProps}
                        isDisabled={
                            shipmentStockItemEdit.fetchStatus.isLoading ||
                            isStockItemReceived ||
                            isPrintingAllBoxes ||
                            !canEdit()
                        }
                    />
                    <div className={styles.buttonDivider} />
                    <Button
                        size="small"
                        iconPosition="right"
                        Icon={isChosenToDamagedItem || isRejectedItem || isDamagedItem ? DeleteIcon : AcceptedIcon}
                        disabled={
                            shipmentStockItemEdit.fetchStatus.isLoading ||
                            shipment.isShipmentDelivered ||
                            boxesHasBeenChanged ||
                            !canEdit()
                        }
                        isLoading={receivingShipmentEdit.fetchStatus.isLoading}
                        onClick={onChangeAcceptedStatus}
                        color={isRejectedItem ? "red" : item.acceptance ? "green" : "white"}
                    >
                        {isBarcodeReaderEnabled
                            ? undefined
                            : isChosenToDamagedItem || isRejectedItem || isDamagedItem
                            ? "Rejected"
                            : "Accepted"}
                    </Button>
                    <div className={styles.buttonDivider} />
                    <ActionsDropList
                        actions={boxOptions}
                        isLoading={isBoxOptionsLoading}
                        classes={{ dots: styles.threeDots }}
                    />
                </TableCell>
            </TableRowWithExpandedDetails>
            {isPhotosModalOpen && (
                <UploadImages onClose={onCloseUploadConditionPhotosModal} onSubmit={onUploadConditionPhotos} />
            )}
        </Fragment>
    )
}

ReceivePackageTableRow.propTypes = {
    index: PropTypes.number.isRequired,
    shipment: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isShipmentDelivered: PropTypes.bool,
        isFromPO: PropTypes.bool.isRequired
    }).isRequired,
    item: SHIPMENT_PACK_ITEM_PROP_TYPE,
    defaultCondition: PropTypes.shape({
        value: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            color: PropTypes.string.isRequired,
            type: PropTypes.string
        }).isRequired
    }),
    isBarcodeReaderEnabled: PropTypes.bool,
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(ReceivePackageTableRow, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
