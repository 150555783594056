import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"

import { Button, Input, Modal, Textarea } from "ui"

import styles from "./AddEditModal.css"
import inputStyles from "./overrides/Input.css"
import textareaStyles from "./overrides/Textarea.css"

const AddEditModal = ({ onClose, initialValues, onSave, errors, setErrors, isSubmitting }) => {
    const cx = useStyles(styles)

    const [question, setQuestion] = useState(initialValues.question || "")
    const [answer, setAnswer] = useState(initialValues.answer || "")

    const handleSave = async () => {
        onSave({
            id: initialValues.id,
            question,
            answer
        })
    }

    const handleChangeFactory = (fieldName, setter) => ({ event }) => {
        setter(event.target.value)
        setErrors(oldErrors => ({ ...oldErrors, [fieldName]: undefined }))
    }
    const handleSetQuestion = handleChangeFactory("question", setQuestion)
    const handleSetAnswer = handleChangeFactory("answer", setAnswer)

    return (
        <Modal isOpen closeModal={onClose} preventClickOutside>
            <p className={cx("header")}>{initialValues.id ? "Edit a" : "Add new"} Item</p>

            <Input
                label="Question"
                value={question}
                onChange={handleSetQuestion}
                customStyles={inputStyles}
                error={errors.question}
                attributes={{
                    required: true
                }}
            />

            <Textarea
                label="Answer"
                value={answer}
                onChange={handleSetAnswer}
                error={errors.answer}
                customStyles={textareaStyles}
                attributes={{
                    required: true
                }}
            />

            <div className={cx("actions")}>
                <Button onClick={onClose} label="Cancel" className={cx("link-button")} />
                <Button
                    label="Save"
                    className={cx("first-button")}
                    isDisabled={!question || !answer}
                    onClick={handleSave}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

AddEditModal.defaultProps = {
    onSave: () => {},
    onClose: () => {}
}

AddEditModal.propTypes = {
    onSave: PropTypes.func,
    onClose: PropTypes.func,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        question: PropTypes.string,
        answer: PropTypes.string
    }).isRequired,
    errors: PropTypes.object,
    setErrors: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool
}

export default AddEditModal
