import React, { useState } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import { Dropzone, ListItem } from "ui/Attachments"

import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"

import styles from "./SpecificationsUploader.css"
import withStyles from "HOC/withStyles"

const SpecificationsUploader = props => {
    const { cx, value, error, onChange, setError } = props

    const initialState = { isUploading: false, progress: 0, name: "" }
    const [uploadState, setUploadState] = useState(initialState)
    const { isUploading, progress, name: uploadingName } = uploadState

    const handleUploadProgress = data => {
        setError(null)
        setUploadState({
            isUploading: data.isUploading,
            progress: data.progress,
            name: data.addedFile[0].title
        })
    }

    const handleUploadSuccess = ([data]) => {
        onChange({ id: data.id, url: data.url, name: data.title, size: data.size, created_at: data.file.lastModified })
        setUploadState(initialState)
    }

    const handleUploadFailure = ({ error }) => setError(Array.isArray(error) ? error[0] : error)

    const onDelete = () => onChange(null)

    const fileName = uploadingName || idx(value, _ => _.name)

    return (
        <div className={cx("root")}>
            <label className={cx("header")}>Download data sheet:</label>
            {!!fileName && (
                <ListItem
                    isUploading={isUploading}
                    url={idx(value, _ => _.url)}
                    name={fileName}
                    size={idx(value, _ => _.size)}
                    progress={progress}
                    handleDelete={onDelete}
                />
            )}
            <div className={cx({ hideDropzone: !!fileName })}>
                <Dropzone
                    isMultiple={false}
                    endpoint={DEFAULT_FILE_UPLOAD_ENPOINT}
                    handleUploadSuccess={handleUploadSuccess}
                    handleUploadProgress={handleUploadProgress}
                    handleUploadFailure={handleUploadFailure}
                />
            </div>
            {error && <p className={cx("error")}>{error}</p>}
        </div>
    )
}

SpecificationsUploader.propTypes = {
    value: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string,
        url: PropTypes.string,
        size: PropTypes.number
    }),
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired
}

export default withStyles(SpecificationsUploader, styles)
