import React from "react"
import PropTypes from "prop-types"
import getReadableFileSize from "helpers/getReadableFileSize"
import downloadIcon from "assets/download.svg"
import withStyles from "HOC/withStyles"
import styles from "./DownloadFile.module.css"

const DownloadFile = ({ cx, file }) => {
    const { name, size, url } = file
    return (
        <a className={cx("download-item")} download href={url}>
            <img src={downloadIcon} alt="Download" />
            <span className={cx("file-name")}>{name}</span>
            <span className={cx("file-size")}>{getReadableFileSize(size)}</span>
        </a>
    )
}

DownloadFile.propTypes = {
    file: PropTypes.shape({
        name: PropTypes.string.isRequired,
        size: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired
    }).isRequired
}

export default withStyles(DownloadFile, styles)
