import { combineReducers } from "redux"

import productReducers from "./products"
import brandReducers from "./brands"
import categoryReducers from "./categories"
import servicesReducers from "./services"

export default combineReducers({
    products: productReducers,
    brands: brandReducers,
    categories: categoryReducers,
    services: servicesReducers
})
