import React, { useState, Fragment } from "react"

import { Link } from "react-router-dom"
import ReactSvg from "react-svg"

import { AddressesAndFinancials, DesignTeam, ProductList } from "./components"

import { formatDate } from "helpers/date"

import withStyles from "HOC/withStyles"
import styles from "./projectInfo.css"

import pencilIcon from "assets/pencil.svg"
import expandIcon from "assets/expand.svg"

const GeneralInfo = props => {
    const { cx, project, projectId } = props
    const { id, footage, deadline_at, installation_at, company, users } = project.data

    const leader = users.find(user => user.leader)

    const [isExpanded, setIsExpanded] = useState(false)

    const toggleExpand = () => setIsExpanded(wasExpanded => !wasExpanded)
    const formatUserName = user => (user && `${user.first_name} ${user.last_name}`) || "-"

    return (
        <Fragment>
            <div className={cx("root")}>
                <div className={cx("headerWrapper")}>
                    <h2 className={cx("header")}>Project Details</h2>
                    <Link to={`/projects/${id}/edit`} className={cx("edit")}>
                        <ReactSvg src={pencilIcon} />
                        Edit Project
                    </Link>
                </div>
                <ul className={cx("table")}>
                    <li>
                        <h3 className={cx("label")}>Square footage:</h3>
                        <p className={cx("value")}>
                            {footage ? (
                                <span className={cx("footage")}>
                                    {footage} ft<sup>2</sup>
                                </span>
                            ) : (
                                "-"
                            )}
                        </p>
                    </li>
                    <li>
                        <h3 className={cx("label")}>Order deadline:</h3>
                        <p className={cx("value")}>{formatDate(deadline_at)}</p>
                    </li>
                    <li>
                        <h3 className={cx("label")}>Installation date:</h3>
                        <p className={cx("value")}>{formatDate(installation_at)}</p>
                    </li>
                    <li>
                        <div>
                            <h3 className={cx("label")}>House:</h3>
                            <p className={cx("value")}>{(company || {}).name || "-"}</p>
                        </div>
                    </li>
                    <li>
                        <div>
                            <h3 className={cx("label")}>Leader:</h3>
                            <p className={cx("value")}>{formatUserName(leader)}</p>
                        </div>
                    </li>
                </ul>

                <button className={cx("expand", { isExpanded })} onClick={toggleExpand}>
                    <ReactSvg src={expandIcon} />
                    {isExpanded ? "Less details" : "See more"}
                </button>

                <div className={cx("line")} />

                {isExpanded && (
                    <Fragment>
                        <AddressesAndFinancials project={project.data} />
                        <div className={cx("line")} />
                        <DesignTeam users={users} />
                        <div className={cx("line")} />
                    </Fragment>
                )}
            </div>
            <ProductList project={project} projectId={projectId} />
        </Fragment>
    )
}

export default withStyles(GeneralInfo, styles)
