import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import ImageFormUploader from "modules/ContentModule/components/ImageFormUploader"
import { Input } from "ui"

import styles from "./styles.css"
import inputStyles from "../../overrides/input.css"

const numberFields = [{ label: "Latitude", fieldName: "latitude" }, { label: "Longitude", fieldName: "longitude" }]

const Sidebar = ({ cx, form, imagesDimensions }) => {
    const addFile = addedFile => form.setFieldValue("thumbnail", addedFile)
    const removeFile = () => {
        form.setFieldValue("thumbnail", null)
    }

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <h2 className={cx("header")}>Options</h2>
                <span className={cx("label")}>Work thumbnail:</span>
                <ImageFormUploader
                    value={form.values.thumbnail}
                    onSuccess={addFile}
                    removeFile={removeFile}
                    imageDimensions={imagesDimensions.thumbnail}
                />

                {numberFields.map(({ label, fieldName }) => (
                    <div className={cx("numberInput")}>
                        <Input
                            type="number"
                            label={label}
                            value={form.values[fieldName]}
                            onChange={({ event }) => form.setFieldValue(fieldName, Number(event.target.value) || null)}
                            error={form.touched[fieldName] && form.errors[fieldName]}
                            customStyles={inputStyles}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    imagesDimensions: PropTypes.shape({
        thumbnail: PropTypes.object
    })
}

export default withStyles(Sidebar, styles)
