import React, { useCallback, useMemo } from "react"
import PropTypes from "prop-types"

import { ConfirmModal } from "@butterfly-frontend/ui"
import { useStoreCreditDelete } from "modules/ClientModule/hooks/api"
import { usePaymentDelete } from "modules/DashboardModule/hooks/api/usePayments"
import { useActions } from "hooks"
import { showErrorNotification, showSuccessNotification } from "actions/notification"

const DeleteStoreCreditModal = ({ storeCredit, onClose }) => {
    const deleteStoreCredit = useStoreCreditDelete()
    const deletePayment = usePaymentDelete()

    const actions = useActions({ showErrorNotification, showSuccessNotification })

    const isStoreCreditPayment = useMemo(() => storeCredit.store_credit_payment, [storeCredit])

    const handleDelete = useCallback(() => {
        const deleteMutation = isStoreCreditPayment ? deletePayment : deleteStoreCredit

        return deleteMutation
            .mutate({ id: storeCredit.id })
            .then(() => {
                onClose()
                actions.showSuccessNotification({ title: "Store credit deleted successfully" })
            })
            .catch(actions.showErrorNotification)
    }, [isStoreCreditPayment])

    return (
        <ConfirmModal
            onCancel={onClose}
            onAction={handleDelete}
            title="Delete payment"
            buttonColor="red"
            buttonLabel="Delete"
            isLoading={
                isStoreCreditPayment ? deletePayment.fetchStatus.isLoading : deleteStoreCredit.fetchStatus.isLoading
            }
        >
            Are you sure you want to delete the payment? This action cannot be reversed.
        </ConfirmModal>
    )
}

DeleteStoreCreditModal.propTypes = {
    storeCredit: PropTypes.shape({
        id: PropTypes.number.isRequired,
        store_credit_payment: PropTypes.bool.isRequired
    }).isRequired,
    onClose: PropTypes.func.isRequired
}

export default DeleteStoreCreditModal
