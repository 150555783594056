import * as constants from "constants/index"

export const initialActiveClient = {
    id: 0,
    name: "",
    email: "",
    active: false,
    isTemporary: false
}

function session(state = {}, action) {
    switch (action.type) {
        case constants.TOGGLE_SESSION:
            window.localStorage.setItem("activeSession", !state.activeSession)
            window.localStorage.setItem("activeClient", JSON.stringify(initialActiveClient))
            return {
                ...state,
                activeSession: !state.activeSession,
                activeClient: {
                    ...initialActiveClient
                }
            }

        case constants.ASSIGN_TEMPORARY_CLIENT:
            const temporaryClientData = {
                ...action.payload.data,
                active: false,
                isTemporary: true
            }

            window.localStorage.setItem("activeClient", JSON.stringify(temporaryClientData))

            return {
                ...state,
                activeClient: {
                    ...temporaryClientData
                }
            }

        case constants.ASSIGN_CLIENT:
            const clientData = {
                ...action.payload.data,
                active: true,
                isTemporary: false
            }
            window.localStorage.setItem("activeSession", true)
            window.localStorage.setItem("activeClient", JSON.stringify(clientData))

            return {
                ...state,
                activeSession: true,
                activeClient: {
                    ...clientData
                }
            }
        case constants.DISMISS_CLIENT:
            window.localStorage.setItem("activeSession", false)
            window.localStorage.setItem("activeClient", JSON.stringify(initialActiveClient))
            return {
                ...state,
                activeSession: false,
                activeClient: {
                    ...initialActiveClient
                }
            }

        default:
            return state
    }
}

export default session
