import React, { Fragment, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import ChooseSelectionsFlow from "./ChooseSelectionsFlow"
import { endModalLogic } from "./../../actions"

const ChooseSelectionsFlowContainer = props => {
    const dispatch = useDispatch()

    const chooseSelectionFlow = useSelector(state => state.db.selectionsWidget.chooseSelectionFlow)
    const { shouldStart } = chooseSelectionFlow

    const [isOpen, setIsOpen] = useState(shouldStart)

    useEffect(() => {
        setIsOpen(shouldStart)
    }, [shouldStart])

    return (
        <Fragment>
            {!!isOpen && (
                <ChooseSelectionsFlow
                    chooseSelectionFlow={chooseSelectionFlow}
                    close={() => dispatch(endModalLogic())}
                />
            )}
        </Fragment>
    )
}

export default ChooseSelectionsFlowContainer
