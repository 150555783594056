import React, { Fragment } from "react"
import { useSelector } from "react-redux"

import ReactSvg from "react-svg"
import Button from "ui/Button"
import CreateSalesOrderButton from "../CreateSalesOrderButton"

import withStyles from "HOC/withStyles"
import styles from "./multiSelectActions.css"

import deleteIcon from "assets/close-blue.svg"
import addRoomIcon from "assets/room-move.svg"

const MultiSelectActions = props => {
    const { cx, onDelete, onAddToRoom, onCopyToRoom, onAddToSalesOrder } = props
    const { isLoading } = useSelector(state => state.db.projects.current.orders)

    return (
        <div className={cx("root")}>
            <button className={cx("deleteButton")} onClick={onDelete}>
                <ReactSvg src={deleteIcon} /> Delete
            </button>
            <Button
                className={`first-button ${cx("addToRoomButton")}`}
                onClick={onAddToRoom}
                label={
                    <Fragment>
                        <ReactSvg src={addRoomIcon} /> Add to room
                    </Fragment>
                }
            />
            <Button
                className={`first-button ${cx("addToRoomButton")}`}
                onClick={onCopyToRoom}
                label={
                    <Fragment>
                        <ReactSvg src={addRoomIcon} /> Copy to room
                    </Fragment>
                }
            />
            <CreateSalesOrderButton
                className={cx("createSalesOrderButton")}
                onClick={onAddToSalesOrder}
                isDisabled={isLoading}
                isLoading={isLoading}
            />
        </div>
    )
}

export default withStyles(MultiSelectActions, styles)
