import React, { Component } from "react"
import { connect } from "react-redux"
import Header from "./Header"
import { WAIT_LOAD_CLIENT_TIME } from "constants/index"
import { getClientTypes, getClientById } from "actions/client"
import { getCompanies } from "actions/company"
import { getSources } from "actions/source"
import { getClientGroups } from "actions/clientGroups"
import { getServices } from "actions/service"
import { addNote } from "actions/nextNote"

class HeaderContainer extends Component {
    state = {
        headerInfo: {
            types: {
                title: "Type of Contact",
                list: ""
            },
            sources: {
                title: "Source",
                list: ""
            },
            groups: {
                title: "Interaction",
                list: ""
            },
            services: {
                title: "Services",
                list: ""
            },
            companies: {
                title: "Division",
                list: ""
            }
        },
        users: [],
        isLoaded: false,
        isAddNoteModalShown: false,
        isNoteAdding: false
    }

    componentDidMount() {
        const { getCompanies, getClientTypes, getServices, getSources, getClientGroups } = this.props
        getCompanies()
        getClientTypes()
        getSources()
        getClientGroups()
        getServices()
    }

    componentDidUpdate() {
        if (!this.state.isLoaded) {
            let isLoaded = true
            Object.keys(this.props.info).forEach(key => {
                if (this.props.info[key].isLoading) {
                    isLoaded = false
                }
            })
            if (isLoaded) {
                this.setState({ isLoaded }, () => {
                    this.prepareUsers()
                    this.prepareHeaderInfo()
                })
            }
        }
    }

    prepareUsers() {
        const users = this.props.users.map(({ id, first_name, last_name }) => ({
            id,
            name: `${first_name} ${last_name}`
        }))

        this.setState({ users })
    }

    prepareHeaderInfo() {
        Object.keys(this.state.headerInfo).forEach(key => {
            const ids = (this.props[key] && this.props[key].map(el => el.id)) || []
            const values = ids
                .map(id => this.props.info[key].data.find(el => el.id === id))
                .map(el => (el ? el.name : ""))

            this.setState(prevState => {
                return {
                    headerInfo: {
                        ...prevState.headerInfo,
                        [key]: {
                            ...prevState.headerInfo[key],
                            list: ids.length === 0 ? "-" : values.join(", ")
                        }
                    }
                }
            })
        })
    }

    handleAddNoteModal = (isShown, isSubmit) =>
        this.setState(
            prevState => {
                return {
                    isAddNoteModalShown: isShown === undefined ? !prevState.isAddNoteModalShown : isShown,
                    isNoteAdding: false
                }
            },
            () => {
                if (isSubmit) {
                    setTimeout(() => {
                        const { getAttachmentsCount } = this.props
                        getAttachmentsCount()
                    }, WAIT_LOAD_CLIENT_TIME)
                }
            }
        )

    handleSubmitNote = data => {
        const { id, addNote } = this.props

        const payload = {
            ...data,
            successAction: () => this.handleAddNoteModal(false, true),
            type: "client",
            id
        }

        this.setState({ isNoteAdding: true }, () => addNote(payload))
    }

    render() {
        const { headerInfo, users, isAddNoteModalShown, isNoteAdding } = this.state
        return (
            <Header
                {...this.props}
                assignedUsers={users}
                isAddNoteModalShown={isAddNoteModalShown}
                isNoteAdding={isNoteAdding}
                handleAddNoteModal={this.handleAddNoteModal}
                handleSubmitNote={this.handleSubmitNote}
                headerInfo={headerInfo}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        info: {
            types: state.db.clientTypes.list,
            groups: state.db.clientGroups.list,
            sources: state.db.sources.list,
            services: state.db.services.list,
            companies: state.db.companies.list
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getClientTypes: () => dispatch(getClientTypes()),
        getCompanies: () => dispatch(getCompanies()),
        getSources: () => dispatch(getSources()),
        getClientGroups: () => dispatch(getClientGroups()),
        getServices: () => dispatch(getServices()),
        getClientById: id => dispatch(getClientById(id)),
        addNote: payload => dispatch(addNote(payload))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderContainer)
