import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "ui/FilterableTable/HOC"
import styles from "./MoreFilters.css"
import filterIcon from "assets/filter-blue.svg"

const MoreFilters = props => {
    const { cx, children, numberOfSelectedFilters, toggleVisible, isVisible, seamlessButtonTransition = false } = props
    const areChildrenVisible = React.Children.count(children) > 0 && isVisible

    return (
        <div className={cx("root", { isVisible, isSelectedFilters: !!numberOfSelectedFilters })}>
            <div className={cx("button", { seamlessButtonTransition })} onClick={toggleVisible}>
                <img src={filterIcon} alt="icon" />
                <p>
                    {displayName()}
                    {numberOfSelectedFilters > 0 && (
                        <span className={cx("numberOfSelectedFilters")}>{numberOfSelectedFilters}</span>
                    )}
                </p>
            </div>
            {areChildrenVisible && (
                <div className={cx("content-wrapper")}>
                    <div className={cx("content")}>{children}</div>
                </div>
            )}
        </div>
    )

    function displayName() {
        const { moreFiltersText } = props

        if (!isVisible && moreFiltersText) {
            return moreFiltersText
        }

        return (isVisible ? "Hide" : "More") + " filters"
    }
}

MoreFilters.propTypes = {
    toggleVisible: PropTypes.func,
    isVisible: PropTypes.bool,
    numberOfSelectedFilters: PropTypes.number
}

export default withStyles(styles)(MoreFilters)
