import React, { useState, useEffect, useMemo, Fragment } from "react"
import { useHistory, useParams } from "react-router-dom"

import Tabs from "ui/Tabs"
import { GeneralInfo, Financials, Attachments, Notes, Faq } from "./Tabs"
import PropTypes from "prop-types"

const CurrentDetail = React.memo(({ Component, brand, fetchBrand }) => {
    return (
        <Component
            brand={brand}
            faqs={brand.faqs || []}
            fetchParent={fetchBrand}
            faqableId={brand.id}
            faqableType="brand"
        />
    )
})

const Details = props => {
    const { brand, fetchBrand } = props
    const { notes, attachments, faqs, id: brandId } = brand

    const history = useHistory()
    const { sectionName } = useParams()

    const tabs = useMemo(
        () => ({
            "general-info": { label: "GENERAL INFO", Component: GeneralInfo, disabled: false },
            financials: { label: "FINANCIALS", Component: Financials, disabled: false },
            notes: {
                label: "NOTES",
                Component: Notes,
                disabled: false,
                count: Array.isArray(notes) ? notes.length : 0
            },
            attachments: {
                label: "DOCUMENTS",
                Component: Attachments,
                disabled: false,
                count: Array.isArray(attachments) ? attachments.length : 0
            },
            faq: {
                label: "FAQ",
                Component: Faq,
                disabled: false,
                count: Array.isArray(faqs) ? faqs.length : 0
            }
        }),
        [notes, attachments, faqs]
    )
    const tabsKeys = Object.keys(tabs)

    const [activeTabIndex, setActiveTabIndex] = useState(tabsKeys[0])

    useEffect(() => {
        setActiveTabIndex(sectionName || tabsKeys[0])

        const isTabDisabled = tabs[sectionName] && tabs[sectionName].disabled
        isTabDisabled && history.push(`/settings/brands/${brandId}`)
    }, [sectionName])

    const onTabChange = key => history.push(`/settings/brands/${brandId}/${key}`)

    const currentComponent = tabs[sectionName] ? tabs[sectionName].Component : Object.values(tabs)[0].Component

    return (
        <Fragment>
            <Tabs tabs={tabs} activeTabKey={activeTabIndex} onTabChange={onTabChange} />
            <CurrentDetail Component={currentComponent} brand={brand} fetchBrand={fetchBrand} />
        </Fragment>
    )
}

Details.propTypes = {
    brand: PropTypes.object.isRequired
}

export default Details
