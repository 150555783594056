import React, { Fragment, useState, useEffect, useRef, useMemo } from "react"
import ReactSvg from "react-svg"
import { useDispatch, useSelector } from "react-redux"

import fetch from "helpers/fetch"
import { getUsersList } from "actions/users"
import { getClients } from "actions/client"
import { showErrorNotification } from "actions/notification"
import { useIsTablet } from "helpers/rwd"
import * as calendarActions from "modules/CalendarModule/actions"

import EventList from "modules/CalendarModule/components/EventList"
import CalendarEntryDetails from "modules/CalendarModule/components/CalendarEntryDetails/CalendarEntryDetails"
import EditEventCalendarModala from "components/Modal/Calendar/AddEditEventModal"
import DeleteEventCalendarModal from "components/Modal/Calendar/DeleteEventModal"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import useStyles from "helpers/getCxFromStyles"
import styles from "./SearchList.css"

import clockIcon from "assets/ico-deadline.svg"

const SearchList = () => {
    const isTablet = useIsTablet()
    const cx = useStyles(styles)
    const eventDetailsRef = useRef()
    const timeoutRef = useRef()

    const [page, setPage] = useState(1)
    const [sort, setSort] = useState({ direction: "desc", by: "starts_at" })
    const [isLoading, setIsLoading] = useState(true)

    const [isOpenEditMeetingModal, setIsOpenEditMeetingModal] = useState(false)
    const [isOpenDeleteMeetingModal, setIsOpenDeleteMeetingModal] = useState(false)
    const [numberOfLoadedEntries, setNumberOfLoadedEntries] = useState(0)

    const [isShownDetailsEvent, setIsShownDetailsEvent] = useState(null)
    const [currentFocusEvent, setCurrentFocusEvent] = useState(null)

    const dispatch = useDispatch()
    const userList = useSelector(state => state.db.users)
    const clientList = useSelector(state => state.clientStore.clients)
    const calendarStore = useSelector(state => state.db.calendar)
    const calendarList = calendarStore.list.data
    const filters = { client: "", project: "", users: [], divisions: [], sources: [], ...calendarStore.entryFilters }
    const query = calendarStore.entryQuery
    const isFetchingFromStore = calendarStore.entries.isLoading
    const isLoadingMore = (isLoading || isFetchingFromStore) && page > 1
    const events = calendarStore.entries.data
    const meta = calendarStore.entries.meta

    const calendarIdList = useMemo(
        () =>
            [...calendarList.private, ...calendarList.public]
                .filter(calendar => calendar.is_selected)
                .map(calendar => calendar.id),
        [calendarList]
    )

    useEffect(() => {
        clearTimeout(timeoutRef.current)

        timeoutRef.current = setTimeout(() => fetchEvents(1), 300)
    }, [
        query,
        sort,
        calendarIdList,
        filters.client,
        filters.users,
        filters.project,
        filters.sources,
        filters.divisions
    ])

    useEffect(() => {
        const afterFetchNumberOfLoadedEntries = numberOfLoadedEntries + ((meta.to || 0) - (meta.from || 0))
        const canLoadMoreData = afterFetchNumberOfLoadedEntries < 10 && meta.to < meta.total

        canLoadMoreData && handleSelectPage(page + 1)
        setNumberOfLoadedEntries(canLoadMoreData ? afterFetchNumberOfLoadedEntries : 0)
    }, [meta])

    const fetchEvents = (page, hasLoadMore = false) => {
        const fetchAction = hasLoadMore ? calendarActions.getMoreEntries : calendarActions.getEntries
        setIsLoading(true)
        setPage(page)

        dispatch(calendarActions.setEntryParams({ page, length: 20, sort_by: sort.by, sort_direction: sort.direction }))
        dispatch(fetchAction(calendarIdList))
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => setIsLoading(false))
    }

    const fetchEditMeeting = (eventId, payload) => fetch.patch(`/calendars-entries/${eventId}?meetings`, payload)
    const fetchDeleteMeeting = eventId => fetch.delete(`/calendars-entries/${eventId}?meetings`)
    const fetchUserList = () => dispatch(getUsersList())
    const fetchClientList = data => dispatch(getClients(data))

    const toggleShowDetails = (event, jsEvent) => {
        setCurrentFocusEvent({
            event: { extendedProps: { entry: event } },
            jsEvent
        })
        setIsShownDetailsEvent(true)
    }

    const handleHideModal = result => {
        setIsOpenEditMeetingModal(false)
        setIsOpenDeleteMeetingModal(false)
        setCurrentFocusEvent(null)

        if (result && result.success) {
            setPage(1)
            fetchEvents(1)
        }
    }

    const handleEditAction = event => {
        setCurrentFocusEvent({ event: { extendedProps: { entry: event } } })
        setIsShownDetailsEvent(false)
        setIsOpenEditMeetingModal(true)
    }

    const handleDeleteAction = () => {
        setIsOpenDeleteMeetingModal(true)
        setIsShownDetailsEvent(false)
    }

    const handleSelectPage = page => {
        page <= meta.last_page && fetchEvents(page, true)
    }

    const handleSuccessModerationEntry = () => {
        setIsShownDetailsEvent(false)
        setCurrentFocusEvent(null)
        fetchEvents(1)
    }

    const sortingOptions = [
        { label: "DATE", key: "starts_at", isSortable: true },
        { label: "EVENT NAME", key: "title", isSortable: true },
        { label: "DESCRIPTION", key: "description", isSortable: false },
        { label: "CREATED BY", key: "creator", isSortable: true }
    ]

    return (
        <div className={cx("root")}>
            {(isLoading || isFetchingFromStore) && page === 1 ? (
                <div className={cx("loaderWrapper")}>
                    <SnakeLoader />
                </div>
            ) : events.length > 0 ? (
                <Fragment>
                    <EventList
                        page={page}
                        pages={meta.last_page}
                        sort={sort}
                        list={events}
                        handleSort={setSort}
                        handleSelectPage={handleSelectPage}
                        toggleShowDetails={toggleShowDetails}
                        isLoadingMore={isLoadingMore}
                        sortingOptions={
                            isTablet ? sortingOptions.filter(item => item.key !== "description") : sortingOptions
                        }
                        PaginationComponent={() =>
                            page < meta.last_page && (
                                <button
                                    type="button"
                                    className={cx("pagination")}
                                    onClick={() => !isLoading && handleSelectPage(page + 1)}
                                >
                                    Show more
                                </button>
                            )
                        }
                    />
                </Fragment>
            ) : (
                <div className={cx("emptyList")}>
                    <ReactSvg src={clockIcon} /> No found events
                </div>
            )}
            {isShownDetailsEvent && (
                <div ref={eventDetailsRef}>
                    <CalendarEntryDetails
                        visible={isShownDetailsEvent}
                        data={currentFocusEvent}
                        handleEventClose={() => setIsShownDetailsEvent(false)}
                        handleDeleteAction={handleDeleteAction}
                        handleEditAction={handleEditAction}
                        handleSuccessModeration={handleSuccessModerationEntry}
                    />
                </div>
            )}
            {isOpenEditMeetingModal && (
                <EditEventCalendarModala
                    handleHideModal={handleHideModal}
                    handleEdit={fetchEditMeeting}
                    showErrorNotification={showErrorNotification}
                    title="Edit meeting"
                    getClients={fetchClientList}
                    clients={clientList.data}
                    getUsersList={fetchUserList}
                    users={userList.list}
                    entryDetails={currentFocusEvent}
                />
            )}
            {isOpenDeleteMeetingModal && (
                <DeleteEventCalendarModal
                    handleHideModal={handleHideModal}
                    showErrorNotification={showErrorNotification}
                    title="Delete this meeting?"
                    entry={currentFocusEvent}
                    handleDelete={fetchDeleteMeeting}
                />
            )}
        </div>
    )
}

export default SearchList
