export default {
    sales: {
        name: "Order",
        pathName: "sales-orders",
        dateLabel: "Sales order",
        hasBillingAddress: true,
        hasRevisedDate: true
    },
    memo: {
        name: "Memo",
        pathName: "memo"
    },
    rental: {
        name: "Rental",
        pathName: "rental-agreements",
        dateLabel: "Rental start",
        hasBillingAddress: true
    }
}
