import React, { useEffect, useMemo } from "react"
import { useSelector, useDispatch } from "react-redux"
import withStyles from "HOC/withStyles"
import styles from "./Notification.css"
import close from "assets/close.svg"
import iconError from "assets/alert-error.svg"
import iconInfo from "assets/alert-info.svg"
import iconSuccess from "assets/alert-ok.svg"
import * as constants from "constants/index"

let timeout = null

const Notification = props => {
    const icons = useMemo(
        () => ({
            error: iconError,
            info: iconInfo,
            success: iconSuccess
        }),
        []
    )

    const { cx } = props

    const dispatch = useDispatch()

    const { type, title, message } = useSelector(state => state.ui.notification)

    const hideNotification = () => dispatch({ type: constants.HIDE_NOTIFICATION })

    useEffect(() => {
        const { miliseconds } = props
        timeout = setTimeout(() => {
            hideNotification()
        }, miliseconds || 4000)
        return () => {
            clearInterval(timeout)
        }
    }, [])

    return (
        <div className={cx("modal-cover", "fading", type || "info")}>
            <div className={cx("title-container")}>
                <img src={icon()} alt="close" className={cx("type-icon")} />
                <span className={cx("title")}>{title}</span>
                <img src={close} alt="close" className={cx("close-icon")} onClick={handleHide} />
            </div>
            {message && <p className={cx("message")}>{message}</p>}
        </div>
    )

    function handleHide() {
        hideNotification()
    }

    function icon() {
        return icons[type] || iconInfo
    }
}

export default withStyles(Notification, styles)
