import React from "react"
import { Switch, Route } from "react-router-dom"

import Form from "./Form"
import List from "./List"

const Inspirations = () => {
    return (
        <Switch>
            <Route path="/content/inspirations/add" component={Form} />
            <Route path="/content/inspirations/:inspirationId/edit" component={Form} />
            <Route path="/content/inspirations" component={List} />
        </Switch>
    )
}

export default Inspirations
