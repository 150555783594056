import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { Portal } from "./components"

import withStyles from "HOC/withStyles"
import styles from "./modal.css"

import closeIcon from "assets/delete-grey.svg"

const Modal = props => {
    const { cx, isOpen, children, render, closeModal, preventClickOutside } = props

    useEffect(() => {
        isOpen && window.addEventListener("keydown", handleKeyDown)
        return () => window.removeEventListener("keydown", handleKeyDown)
    }, [isOpen])

    const handleKeyDown = event => {
        const escKey = 27
        event.keyCode === escKey && closeModal()
    }

    const stopPropagation = event => props.stopPropagation && event.stopPropagation()

    if (!isOpen) {
        return null
    }

    return (
        <Portal>
            <div className={cx("root")} onClick={preventClickOutside ? null : closeModal}>
                <div className={cx("content")} onClick={stopPropagation}>
                    <div className={cx("closeBar")}>
                        {preventClickOutside && <img src={closeIcon} alt="" onClick={closeModal} />}
                    </div>
                    {renderBody()}
                </div>
            </div>
        </Portal>
    )

    function renderBody() {
        if (typeof render === "function") {
            return render(props)
        }

        return children
    }
}

Modal.defaultProps = {
    stopPropagation: true
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    preventClickOutside: PropTypes.bool
}

export default withStyles(Modal, styles)
