import * as constants from "constants/index"

function brands(state = {}, action) {
    switch (action.type) {
        case constants.CLEAR_AVAILABLE_FILTERS:
            return {}
        case constants.GET_PRODUCTS_SUCCESS:
            if (
                action.payload.data &&
                action.payload.data.meta.aggregates &&
                action.payload.data.meta.aggregates.brands_count
            ) {
                return { ...action.payload.data.meta.aggregates.brands_count }
            }
            return {}
        default:
            return state
    }
}

export default brands
