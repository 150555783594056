import React, { useState, useLayoutEffect, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"

import { formatNotes } from "helpers/notes"

import withStyles from "HOC/withStyles"
import styles from "./CollapsingText.css"

const CollapsingText = props => {
    const { cx, className, initiallyExpanded, text, collapsedTextLength, buttonLabels, wrapperProps } = props

    const [isExpanded, setIsExpanded] = useState(initiallyExpanded)
    const [currentCollapsedTextLength, setCurrentCollapsedTextLength] = useState(0)

    useLayoutEffect(() => {
        if (typeof collapsedTextLength === "number") {
            setCurrentCollapsedTextLength(collapsedTextLength)
            return
        }

        handleSetCollapsedTextLength()
        window.addEventListener("resize", debouncedHandleSetCollapsedTextLength)
        return () => window.removeEventListener("resize", debouncedHandleSetCollapsedTextLength)
    }, [])

    const handleSetCollapsedTextLength = useCallback(() => {
        const width = window.innerWidth
        const currentLength = Object.entries(collapsedTextLength)
            .reverse()
            .find(([breakpoint]) => width >= Number(breakpoint))[1]

        setCurrentCollapsedTextLength(currentLength)
    }, [window.innerWidth, collapsedTextLength])

    const debouncedHandleSetCollapsedTextLength = debounce(handleSetCollapsedTextLength, 100)

    const toggleExpanded = () => setIsExpanded(wasExpanded => !wasExpanded)

    const formattedText = useMemo(
        () => (isExpanded ? formatNotes(text) : formatNotes(text, currentCollapsedTextLength)),
        [text, isExpanded, currentCollapsedTextLength]
    )

    const canTextCollapse = text && text.length > currentCollapsedTextLength
    const buttonLabel = isExpanded ? buttonLabels.collapse : buttonLabels.expand

    return (
        <props.wrapper {...wrapperProps} className={cx("root", className)}>
            {formattedText}
            {canTextCollapse && !isExpanded && "..."}
            {canTextCollapse && <button onClick={toggleExpanded}>{buttonLabel}</button>}
        </props.wrapper>
    )
}

CollapsingText.defaultProps = {
    wrapper: "p",
    wrapperProps: {},
    text: "",
    initiallyExpanded: false,
    collapsedTextLength: 250,
    buttonLabels: {
        expand: "Read more",
        collapse: "Read less"
    }
}

const HTMLElement = PropTypes.oneOf(["p", "span", "div"])
const JSXComponent = PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func])

CollapsingText.propTypes = {
    className: PropTypes.string,
    wrapper: PropTypes.oneOfType([HTMLElement, JSXComponent]),
    wrapperProps: PropTypes.object,
    initiallyExpanded: PropTypes.bool,
    text: PropTypes.string.isRequired,
    collapsedTextLength: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    buttonLabels: PropTypes.shape({
        expand: PropTypes.string,
        collapse: PropTypes.string
    })
}

export default withStyles(CollapsingText, styles)
