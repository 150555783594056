const STOCK_ITEM_STATUS_KEYS = {
    AVAILABLE: "available",
    COMMITTED: "committed",
    IN_STOCK: "in_stock",
    ON_HOLD: "on_hold",
    SOLD: "sold",
    NOT_FOR_SALE: "not_for_sale"
}

export default STOCK_ITEM_STATUS_KEYS
