import React, { Fragment, useMemo } from "react"
import { useHistory } from "react-router-dom"

import { EditableList } from "ui"

import ListingLayout from "../../OtherListingLayout/index"
import ClientGroupsModal from "../ClientGroupsModal"

const ListingGroups = ({
    clientGroups,
    deleteClientGroup,
    isClientGroupsModalOpen,
    setGroupsModalOpen,
    getClientGroups,
    createOrUpdateClientGroup,
    currentEditGroup
}) => {
    const history = useHistory()

    const listDataWithPercent = useMemo(
        () => clientGroups.data.map(group => ({ ...group, discount: group.discount + "%" })),
        [clientGroups]
    )

    const columns = [
        {
            name: "name",
            field: "name",
            onClick: item => history.push(`/settings/interactions/${item.id}`)
        },
        {
            name: "clients in group",
            field: "clients_count",
            align: "center"
        },
        {
            name: "discount",
            field: "discount",
            align: "center"
        }
    ]

    return (
        <Fragment>
            <ListingLayout>
                <EditableList
                    title="Interactions"
                    isLoading={clientGroups.isLoading}
                    columns={columns}
                    data={listDataWithPercent}
                    dataModel="ClientGroups"
                    fetchData={getClientGroups}
                    isIndexColumn
                    onAdd={() => setGroupsModalOpen(true)}
                    onEdit={item => setGroupsModalOpen(true, item.id)}
                    onDelete={item => deleteClientGroup(item.id)}
                    confirmModalTitle={"Are you sure you want to delete this client group?"}
                    showDeleteCondition={values => values.clients_count === 0}
                />
            </ListingLayout>
            {isClientGroupsModalOpen && (
                <ClientGroupsModal
                    createOrUpdateClientGroup={createOrUpdateClientGroup}
                    currentEditGroup={currentEditGroup}
                    handleClose={() => setGroupsModalOpen(false)}
                />
            )}
        </Fragment>
    )
}

export default ListingGroups
