import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import * as yup from "yup"

import { Modal, Input, Button, ActionButton } from "ui"

import useFetch from "hooks/useFetch"
import { addTag } from "actions/tags"

import withStyles from "HOC/withStyles"
import styles from "./AddTagModal.css"
import inputStyles from "./overrides/input.css"

import { INPUT_MAX_LENGTH } from "constants/index"

const { Header, Body, Actions } = Modal

const ENTER_KEY = 13
const valueSchema = yup.string().max(INPUT_MAX_LENGTH, `Tag may not be greater than ${INPUT_MAX_LENGTH} characters`)

const AddTagModal = props => {
    const { cx, closeModal, onSuccess } = props

    const dispatch = useDispatch()

    const [value, setValue] = useState("")
    const [error, setError] = useState(null)

    const [onSubmit, { isLoading }] = useFetch({
        action: async () => {
            await valueSchema.validate(value)
            const data = await dispatch(addTag({ name: value }))
            return data
        },
        onSuccess: ({ data }) => {
            onSuccess(data.id)
            closeModal()
        },
        onError: ({ errors, message }) => setError(errors.name || message)
    })

    const onChange = ({ event }) => {
        setValue(event.target.value)
        setError(null)
    }

    const focusOnMount = node => node.focus()
    const onKeyDown = event => event.keyCode === ENTER_KEY && onSubmit()

    return (
        <Modal isOpen preventClickOutside closeModal={closeModal}>
            <Header>Add new tag</Header>
            <Body>
                <Input
                    isPlaceholder
                    label="Your tag..."
                    customStyles={inputStyles}
                    handleInputRefOnInit={focusOnMount}
                    value={value}
                    error={error}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                />
            </Body>
            <Actions>
                <ActionButton
                    className={cx("cancelButton")}
                    label="Cancel"
                    handleClick={closeModal}
                    isDisabled={isLoading}
                />
                <Button
                    className={cx("first-button", "submitButton")}
                    label="Add tag"
                    onClick={onSubmit}
                    isLoading={isLoading}
                    isDisabled={error || !value}
                />
            </Actions>
        </Modal>
    )
}

AddTagModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default withStyles(AddTagModal, styles)
