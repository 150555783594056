import React from "react"
import PropTypes from "prop-types"
import { PriceElements } from "ui"

import styles from "./SpecialInstructionAndBalanceSection.module.css"

const SpecialInstructionAndBalanceSection = props => {
    const { specialInstruction, discount, subtotal, totalAmount } = props
    return (
        <div className={styles.root}>
            <div>
                <h4 className={styles.specialInstructionsHeader}>Special instructions:</h4>
                <p className={styles.specialInstructionsValue}>{specialInstruction}</p>
            </div>
            <div className={styles.balance}>
                <PriceElements
                    config={[
                        [
                            { label: "Subtotal", value: subtotal, isBig: false, isColor: false },
                            { label: "Discount", value: -discount, isBig: false, isColor: false }
                        ],
                        [{ label: "Total amount", value: totalAmount, isBig: true, isColor: true }]
                    ]}
                />
            </div>
        </div>
    )
}

SpecialInstructionAndBalanceSection.propTypes = {
    specialInstruction: PropTypes.string,
    subtotal: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired
}

export default SpecialInstructionAndBalanceSection
