import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { objToQueryString } from "helpers/urls"
export { shouldFetch } from "helpers/fetchStatus"

export function getProductsStart() {
    return {
        type: constants.GET_PRODUCTS_START
    }
}

export function getProductsSuccess(data) {
    return {
        type: constants.GET_PRODUCTS_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProductsError(error) {
    return {
        type: constants.GET_PRODUCTS_ERROR,
        payload: {
            error
        }
    }
}

export function getProducts(params = {}) {
    return dispatch => {
        dispatch(getProductsStart())
        return fetch
            .get(`/products?${objToQueryString({ page: 1, length: constants.LENGTH_WITHOUT_PAGINATION, ...params })}`)
            .then(data => dispatch(getProductsSuccess(data)))
            .catch(error => dispatch(getProductsError(error)))
    }
}

export function resetProducts() {
    return {
        type: constants.RESET_PRODUCTS
    }
}

export function getProductsByFilterRAW(filter, pagination = { length: constants.LENGTH_WITHOUT_PAGINATION, page: 1 }) {
    return fetch.getRAW(`/products/export?length=${pagination.length}&page=${pagination.page}${filter}`)
}

export function getProductForRelatedByIdStart() {
    return {
        type: constants.GET_PRODUCT_FOR_RELATED_BY_ID_START
    }
}

export function getProductForRelatedByIdSuccess(data) {
    return {
        type: constants.GET_PRODUCT_FOR_RELATED_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProductForRelatedByIdError(error) {
    return {
        type: constants.GET_PRODUCT_FOR_RELATED_BY_ID_ERROR,
        payload: {
            error
        }
    }
}

export function getProductForRelatedById(id) {
    return dispatch => {
        dispatch(getProductForRelatedByIdStart())
        return fetch
            .get(`/products/${id}`)
            .then(data => {
                dispatch(getProductForRelatedByIdSuccess(data))
            })
            .catch(error => {
                dispatch(getProductForRelatedByIdError(error))
            })
    }
}

export function getProductByIdStart() {
    return {
        type: constants.GET_PRODUCT_BY_ID_START
    }
}

export function getProductByIdSuccess(data) {
    return {
        type: constants.GET_PRODUCT_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProductByIdError(error) {
    return {
        type: constants.GET_PRODUCT_BY_ID_ERROR,
        payload: {
            error
        }
    }
}

export function getProductById(id, forceFetch = false) {
    return (dispatch, getState) => {
        const currentProduct = getState().db.products.current

        if (!forceFetch && currentProduct.data.id === Number(id)) {
            return Promise.resolve(currentProduct)
        }

        dispatch(getProductByIdStart())

        return new Promise((resolve, reject) => {
            fetch
                .get(`/products/${id}`)
                .then(data => {
                    dispatch(getProductByIdSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(getProductByIdError(error))
                    reject(error)
                })
        })
    }
}

export function deleteProduct(id) {
    return fetch.deleteRAW(`/products/${id}`)
}

export function getProductCombinationsStart() {
    return {
        type: constants.GET_PRODUCT_COMBINATIONS_START
    }
}

export function getProductCombinationsSuccess(data) {
    return {
        type: constants.GET_PRODUCT_COMBINATIONS_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProductCombinationsError(error) {
    return {
        type: constants.GET_PRODUCT_COMBINATIONS_ERROR,
        payload: {
            error
        }
    }
}

export function getProductCombinations({ productId, attributeValueIds }) {
    return dispatch => {
        dispatch(getProductCombinationsStart())
        fetch
            .get(`/combination/find?productId=${productId}&attributeIds=${attributeValueIds.join(",")}`)
            .then(data => {
                dispatch(getProductCombinationsSuccess(data))
            })
            .catch(error => {
                dispatch(getProductCombinationsError(error))
            })
    }
}

export const uploadUrl = (url, productId) => {
    return fetch.post(`/media`, {
        url,
        model_type: `\\App\\Models\\Product\\Product`,
        model_id: productId,
        model_collection: "images"
    })
}

export const addImageToProduct = (productId, data) => {
    return fetch.post(`/product/${productId}/media`, {
        type: "image",
        ...data
    })
}

export function getProductConditionsStart() {
    return {
        type: constants.GET_PRODUCT_CONDITIONS_START
    }
}

export function getProductConditionsSuccess(data) {
    return {
        type: constants.GET_PRODUCT_CONDITIONS_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProductConditionsError(error) {
    return {
        type: constants.GET_PRODUCT_CONDITIONS_ERROR,
        payload: {
            error
        }
    }
}

export function getProductConditions() {
    return dispatch => {
        dispatch(getProductConditionsStart())
        fetch
            .get(`/product-conditions?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => {
                dispatch(getProductConditionsSuccess(data))
            })
            .catch(error => {
                dispatch(getProductConditionsError(error))
            })
    }
}

export function getProductConditionByIdStart() {
    return {
        type: constants.GET_PRODUCT_CONDITION_BY_ID_START
    }
}

export function getProductConditionByIdSuccess(data) {
    return {
        type: constants.GET_PRODUCT_CONDITION_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProductConditionByIdError(error) {
    return {
        type: constants.GET_PRODUCT_CONDITION_BY_ID_ERROR,
        payload: {
            error
        }
    }
}

export function getProductConditionById(id) {
    return dispatch => {
        dispatch(getProductConditionByIdStart())
        fetch
            .get(`/product-conditions/${id}`)
            .then(data => {
                dispatch(getProductConditionByIdSuccess(data))
            })
            .catch(error => {
                dispatch(getProductConditionByIdError(error))
            })
    }
}

export function resetCurrentProducts() {
    return { type: constants.RESET_CURRENT_PRODUCT }
}

export function setSortType(type) {
    return {
        type: constants.SET_SORT_TYPE,
        payload: type
    }
}

export function getProductsSearchAndSortStart(data) {
    return {
        type: constants.GET_PRODUCTS_SEARCH_AND_SORT_START,
        payload: {
            data
        }
    }
}

export function getProductsSearchAndSortSuccess(data) {
    return {
        type: constants.GET_PRODUCTS_SEARCH_AND_SORT_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProductsSearchAndSortError(error) {
    return {
        type: constants.GET_PRODUCTS_SEARCH_AND_SORT_ERROR,
        payload: {
            error
        }
    }
}

export function getProductsSearchAndSort(body) {
    return dispatch => {
        dispatch(getProductsSearchAndSortStart(body))
        fetch
            .post(`/product/searchAndSort`, body)
            .then(data => {
                dispatch(getProductsSearchAndSortSuccess(data))
            })
            .catch(error => {
                dispatch(getProductsSearchAndSortError(error))
            })
    }
}

export function changeNumberOfImagesInProduct(data = {}) {
    return {
        type: constants.CHANGE_PRODUCT_IMAGES_COUNT,
        payload: {
            ...data
        }
    }
}

export const createProduct = data => {
    return fetch.post(`/product`, data)
}

export const updateProduct = ({ data, id }) => {
    return fetch.patch(`/product/${id}`, data)
}

export const attachRelatedProducts = ({ data, id }) => {
    return fetch.patch(`/product/${id}`, { relatedProducts_attach: data })
}

export const detachRelatedProducts = ({ data, id }) => {
    return fetch.patch(`/product/${id}`, { relatedProducts_detach: data })
}

export const detachAllRelatedProducts = ({ id }) => {
    return fetch.patch(`/product/${id}`, { relatedProducts: [] })
}

export function transferImages(productId, folderId, imagesIds = [], toProduct = true) {
    const [action, target] = toProduct ? ["products", productId] : ["folders", folderId]
    return fetch.patchRAW(`/${action}/${target}`, { images: { add: imagesIds } })
}

export const updateImportProducts = spreadsheetId => {
    return fetch.patchRAW(`/imports/${spreadsheetId}`)
}

export const updateFastReview = spreadsheetId => {
    return fetch.patchRAW(`/reviews/${spreadsheetId}`)
}

export function createFastReviewStart(data) {
    return {
        type: constants.CREATE_FAST_REVIEW_START,
        payload: {
            data
        }
    }
}

export function createFastReviewSuccess(data) {
    return {
        type: constants.CREATE_FAST_REVIEW_SUCCESS,
        payload: {
            data
        }
    }
}

export function createFastReviewError(error) {
    return {
        type: constants.CREATE_FAST_REVIEW_ERROR,
        payload: {
            error
        }
    }
}

export function createFastReview(body) {
    return dispatch => {
        dispatch(createFastReviewStart(body))
        fetch
            .postRAW("/reviews?" + objToQueryString(body), { object_type: "products" })
            .then(data => data.json())
            .then(data => {
                dispatch(createFastReviewSuccess(data))
            })
            .catch(error => {
                dispatch(createFastReviewError(error))
            })
    }
}

export function multiSelectReviewFetch(ids) {
    return fetch.postRAW("/multi-actions", {
        ids,
        model: "products",
        action: "review"
    })
}

export function createMultiSelectFastReview(body) {
    return dispatch => {
        dispatch(createFastReviewStart(body))
        multiSelectReviewFetch(body.ids)
            .then(data => {
                return data.json()
            })
            .then(data => {
                dispatch(createFastReviewSuccess(data))
            })
            .catch(error => {
                dispatch(createFastReviewError(error))
            })
    }
}

export function changePagination(page) {
    return {
        type: constants.CHANGE_PRODUCT_PAGINATION,
        payload: { page }
    }
}

export function changeQuery(query) {
    return {
        type: constants.CHANGE_QUERY_FIELD,
        payload: { value: query }
    }
}

export function changeDateRange(range) {
    return {
        type: constants.CHANGE_PRODUCT_DATERANGE,
        payload: { ...range }
    }
}

export function replaceFiltersGroup(newFiltersGroup) {
    return {
        type: constants.REPLACE_PRODUCT_FILTERS_GROUP,
        payload: { ...newFiltersGroup }
    }
}

export function multiSelectDelete(ids) {
    return fetch.postRAW("/multi-actions", {
        ids,
        model: "products",
        action: "delete"
    })
}

export function multiSelectExport(ids, exportType) {
    return fetch.postRAW("/multi-actions", {
        ids,
        model: "products",
        action: "export",
        payload: { export: exportType }
    })
}

export function clearCategoriesForFilters() {
    return {
        type: constants.CLEAR_CATEGORIES_FOR_FILTERS,
        payload: {}
    }
}

export function updateCategoriesForFilters(categories) {
    return {
        type: constants.PUT_CATEGORIES_FOR_FILTERS,
        payload: { value: categories }
    }
}

export function clearBrandsForFilters() {
    return {
        type: constants.CLEAR_BRANDS_FOR_FILTERS,
        payload: {}
    }
}

export function updateBrandsForFilters(brands) {
    return {
        type: constants.PUT_BRANDS_FOR_FILTERS,
        payload: { value: brands }
    }
}

export function clearSelectedCategories() {
    return {
        type: constants.CLEAR_CATEGORIES,
        payload: {}
    }
}

export function updateSelectedCategories(categories) {
    return {
        type: constants.CHANGE_CATEGORIES,
        payload: { value: categories }
    }
}

export function clearSelectedBrands() {
    return {
        type: constants.CLEAR_BRANDS,
        payload: {}
    }
}

export function toggleSelectedBrands(id) {
    return {
        type: constants.TOGGLE_BRANDS,
        payload: { value: id }
    }
}

export function updateSelectedBrands(categories) {
    return {
        type: constants.CHANGE_BRANDS,
        payload: { value: categories }
    }
}

export function clearAllSelectedFilters() {
    return {
        type: constants.CLEAR_PRODUCT_ADDITIONAL_FILTERS_GROUP,
        payload: {}
    }
}

export function updatePriceRange(priceRange) {
    return {
        type: constants.CHANGE_PRICERANGE,
        payload: { value: priceRange }
    }
}

export function clearPriceRange() {
    return {
        type: constants.CLEAR_PRICERANGE,
        payload: {}
    }
}

export function updateDiscount(discount) {
    return {
        type: constants.CHANGE_DISCOUNT,
        payload: { value: discount }
    }
}

export function clearDiscount() {
    return {
        type: constants.CLEAR_DISCOUNT,
        payload: {}
    }
}

export function updateManufacturingTime(manufacturingTime) {
    return {
        type: constants.CHANGE_MANUFACTURINGTIME,
        payload: { value: manufacturingTime }
    }
}

export function clearManufacturingTime() {
    return {
        type: constants.CLEAR_MANUFACTURINGTIME,
        payload: {}
    }
}

export function updateProductionYear(productionYear) {
    return {
        type: constants.CHANGE_PRODUCTIONYEAR,
        payload: { value: productionYear }
    }
}

export function clearProductionYear() {
    return {
        type: constants.CLEAR_PRODUCTIONYEAR,
        payload: {}
    }
}

export function updateDeliveringTime(deliveringTime) {
    return {
        type: constants.CHANGE_DELIVERINGTIME,
        payload: { value: deliveringTime }
    }
}

export function clearDeliveringTime() {
    return {
        type: constants.CLEAR_DELIVERINGTIME,
        payload: {}
    }
}

export function clearAvailability() {
    return {
        type: constants.CLEAR_AVAILABILITY,
        payload: {}
    }
}

export function updateAvailability(availability) {
    return {
        type: constants.CHANGE_AVAILABILITY,
        payload: { value: availability }
    }
}

export function clearReorderable() {
    return {
        type: constants.CLEAR_REORDERABLE,
        payload: {}
    }
}

export function updateReorderable(reorderable) {
    return {
        type: constants.CHANGE_REORDERABLE,
        payload: { value: reorderable }
    }
}

export function clearVerification() {
    return {
        type: constants.CLEAR_VERIFICATION,
        payload: {}
    }
}

export function updateVerification(verification) {
    return {
        type: constants.CHANGE_VERIFICATION,
        payload: { value: verification }
    }
}

export function clearAvailableOnWeb() {
    return {
        type: constants.CLEAR_AVAILABLE_ON_WEB,
        payload: {}
    }
}

export function updateAvailableOnWeb(availableOnWeb) {
    return {
        type: constants.CHANGE_AVAILABLE_ON_WEB,
        payload: { value: availableOnWeb }
    }
}

export function clearWithoutPhotos() {
    return {
        type: constants.CLEAR_WITHOUT_PHOTOS,
        payload: {}
    }
}

export function updateWithoutPhotos(withoutPhotos) {
    return {
        type: constants.CHANGE_WITHOUT_PHOTOS,
        payload: { value: withoutPhotos }
    }
}

export function updateClearance(clearance) {
    return {
        type: constants.CHANGE_CLEARANCE,
        payload: { value: clearance }
    }
}

export function clearDeleted() {
    return {
        type: constants.CLEAR_DELETED,
        payload: {}
    }
}

export function updateDeleted(deleted) {
    return {
        type: constants.CHANGE_DELETED,
        payload: { value: deleted }
    }
}

export function updateProductsAggregates(data) {
    return {
        type: constants.REPLACE_PRODUCT_AGGREGATES_GROUP,
        payload: { value: data }
    }
}

export function updateLocation(locations) {
    return {
        type: constants.CHANGE_LOCATION,
        payload: { value: locations }
    }
}

export function clearLocation() {
    return {
        type: constants.CLEAR_LOCATION,
        payload: {}
    }
}

export function clearConditions() {
    return {
        type: constants.CLEAR_CONDITIONS,
        payload: {}
    }
}

export function toggleSelectedSelections(id) {
    return {
        type: constants.TOGGLE_SELECTIONS,
        payload: { value: id }
    }
}

export function clearSelectedSelections() {
    return {
        type: constants.CLEAR_SELECTIONS,
        payload: {}
    }
}

export function multiSelectPrint(ids, print_type) {
    return fetch
        .postRAW("/multi-actions", {
            ids,
            model: "products",
            action: "print",
            payload: { print_type }
        })
        .then(response => response.blob())
}

export function addProductsToProject({ productIds, projectId, roomId }) {
    return fetch.postRAW("/multi-actions", {
        ids: productIds,
        model: "Products",
        action: "AddToProject",
        payload: {
            project_id: projectId,
            room_id: roomId
        }
    })
}
