import React from "react"
import PropTypes from "prop-types"

import { Button, BackIcon } from "@butterfly-frontend/ui"

import styles from "./StockItemModalButtonBar.module.css"

const StockItemModalButtonBar = ({ isSubmitting, onClose, withBackButton, disabled }) => (
    <div className={styles.buttonSection}>
        {withBackButton && (
            <Button variant="flat" onClick={onClose} disabled={isSubmitting} Icon={BackIcon}>
                Stock Management
            </Button>
        )}
        <Button variant="flat" onClick={onClose} disabled={isSubmitting} classes={{ button: styles.cancelButton }}>
            Cancel
        </Button>
        <Button
            type="submit"
            color="blue"
            disabled={disabled}
            isLoading={isSubmitting}
            classes={{ button: styles.saveButton }}
        >
            Save
        </Button>
    </div>
)

StockItemModalButtonBar.propTypes = {
    isSubmitting: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    withBackButton: PropTypes.bool
}

export default StockItemModalButtonBar
