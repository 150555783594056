import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import fetch from "helpers/fetch"

import * as sourceActions from "actions/source"

import { EditableList } from "ui"

import ListingLayout from "../../OtherListingLayout/index"

const Listing = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const getSources = () => dispatch(sourceActions.getSources())

    const sources = useSelector(state => state.db.sources.list.data)
    const isLoading = useSelector(state => state.db.sources.list.isLoading)

    useEffect(() => {
        getSources()
    }, [])

    const handleAdd = () => {
        history.push("/settings/sources/add")
    }

    const handleEdit = item => {
        history.push(`/settings/sources/card/${item.id}/edit`)
    }

    const handleDelete = item => {
        fetch.deleteRAW(`/sources/${item.id}`).then(() => {
            getSources()
        })
    }

    const columns = [
        {
            name: "name",
            field: "name"
        }
    ]

    return (
        <ListingLayout>
            <EditableList
                title="Sources"
                isLoading={isLoading}
                columns={columns}
                data={sources}
                dataModel="Sources"
                fetchData={getSources}
                isIndexColumn
                onAdd={handleAdd}
                onEdit={handleEdit}
                onDelete={handleDelete}
                confirmModalTitle="Are you sure you want to delete this source?"
            />
        </ListingLayout>
    )
}

export default Listing
