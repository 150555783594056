import React, { Fragment } from "react"
import idx from "idx"
import { Modal as ModalComponent, Button } from "ui"
import withStyles from "HOC/withStyles"

import Loader from "components/Reusable/Loader/Loader"
import Skeleton from "components/Skeleton"
import { Field, Formik } from "formik"
import { Input } from "components/Reusable/Form"

import validationSchema from "./schema"

import styles from "./styles.css"
import inputOverrides from "./overrides/inputOverrides.css"
import modalOverrides from "./overrides/modalOverrides.css"

const Modal = props => {
    const { cx, isOpen, isLoading, handleClose, onSubmit, extended, data, sectionName, defaultValues, isSaving } = props
    const formValues = idx(data, _ => _.settings.sections[sectionName]) || defaultValues
    const handleSubmit = values => {
        onSubmit({
            settings: {
                ...(data.settings || {}),
                sections: { ...((data.settings || {}).sections || {}), [sectionName]: values }
            }
        })
    }

    return (
        <ModalComponent isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <Formik
                initialValues={formValues}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={true}
                validationSchema={validationSchema}
            >
                {({ handleSubmit, isSubmitting, setFieldValue, errors, touched, values }) => {
                    return (
                        <form className={cx("form root")} onSubmit={handleSubmit}>
                            <ModalComponent.Header>Edit section</ModalComponent.Header>
                            <ModalComponent.Body>
                                <Skeleton
                                    isLoading={isLoading}
                                    isLoaded={!isLoading}
                                    component={
                                        <div className={cx("loader")}>
                                            <Loader />
                                        </div>
                                    }
                                    render={() => (
                                        <Fragment>
                                            <div className={cx("row")}>
                                                <Field name="label">
                                                    {({ field }) => {
                                                        return (
                                                            <Input
                                                                label="Section name"
                                                                customStyles={inputOverrides}
                                                                error={touched.label && errors.label}
                                                                value={values.label}
                                                                onChange={value => {
                                                                    setFieldValue(field.name, value.event.target.value)
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                </Field>
                                            </div>

                                            {extended && (
                                                <Fragment>
                                                    <hr className={cx("hr")} />
                                                    <div className={cx("row")}>
                                                        <Field name="heading">
                                                            {({ field }) => {
                                                                return (
                                                                    <Input
                                                                        label="Heading"
                                                                        customStyles={inputOverrides}
                                                                        error={touched.heading && errors.heading}
                                                                        value={values.heading}
                                                                        onChange={value => {
                                                                            setFieldValue(
                                                                                field.name,
                                                                                value.event.target.value
                                                                            )
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </div>

                                                    <div className={cx("row")}>
                                                        <Field name="text">
                                                            {({ field }) => {
                                                                return (
                                                                    <Input
                                                                        label="Text"
                                                                        customStyles={inputOverrides}
                                                                        error={touched.text && errors.text}
                                                                        value={values.text}
                                                                        onChange={value => {
                                                                            setFieldValue(
                                                                                field.name,
                                                                                value.event.target.value
                                                                            )
                                                                        }}
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </Fragment>
                                    )}
                                />
                            </ModalComponent.Body>
                            <ModalComponent.Actions customStyles={modalOverrides}>
                                <button className={cx("cancel-button")} type="button" onClick={handleClose}>
                                    Cancel
                                </button>
                                <Button
                                    className="first-button"
                                    type="submit"
                                    onClick={handleSubmit}
                                    label={"Save"}
                                    isLoading={isSaving}
                                />
                            </ModalComponent.Actions>
                        </form>
                    )
                }}
            </Formik>
        </ModalComponent>
    )
}

export default withStyles(Modal, styles)
