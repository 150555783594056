import React from "react"
import PropTypes from "prop-types"
import { Panel } from "@butterfly-frontend/ui"

import styles from "./PageError.module.css"

const PageError = ({ children }) => <Panel classes={{ panel: styles.errorWrapper }}>{children}</Panel>

PageError.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
        PropTypes.func
    ])
}

export default PageError
