import React, { Component } from "react"
import CSSModules from "react-css-modules"
import { Checkbox } from "@butterfly-frontend/ui"

import style from "./FormSection.css"

class FormSection extends Component {
    render() {
        const { allPermissions, permissions, details, choosePermission } = this.props
        const sectionName = details.name
        const customFilter = details.customFilter ? details.customFilter : null
        const filter = customFilter || (checkbox => checkbox.name.includes(sectionName))
        const thisSectionCheckboxes = allPermissions.filter(filter)
        return (
            <li styleName="form-section">
                <h3 styleName="section-title">{sectionName}</h3>
                <ul>
                    {thisSectionCheckboxes.map(({ id, name }) => (
                        <li key={id} styleName="action-checkbox-li">
                            <Checkbox
                                id={id}
                                label={name}
                                checked={permissions.includes(id)}
                                onChange={id => choosePermission({ id, permissionsIds: permissions })}
                                classes={{ checkbox: style.checkbox }}
                            />
                        </li>
                    ))}
                </ul>
            </li>
        )
    }
}

export default CSSModules(FormSection, style)
