import * as constants from "constants/index"

function list(state = {}, action) {
    switch (action.type) {
        case constants.GET_POSTS_START:
            return { data: [], meta: {}, isLoading: true }
        case constants.GET_POSTS_SUCCESS:
            return {
                data: action.payload.data.data,
                meta: action.payload.data.meta,
                isLoading: false
            }
        case constants.GET_POSTS_ERROR:
            return { data: [], meta: {}, isLoading: false }
        default:
            return state
    }
}

export default list
