import React from "react"
import { Switch, Route } from "react-router-dom"

import Form from "./Form"
import List from "./List"

const Trade = () => {
    return (
        <Switch>
            <Route path="/content/trades/add" component={Form} />
            <Route path="/content/trades/:tradeId/edit" component={Form} />
            <Route path="/content/trades" component={List} />
        </Switch>
    )
}

export default Trade
