import { getIn } from "formik"

const getInputPropsFactory = (formik, inputClass) => (key, { className, onChange } = {}) => ({
    className: className || inputClass,
    type: "text",
    id: key,
    name: key,
    value: getIn(formik.values, key),
    error: getIn(formik.touched, key) && getIn(formik.errors, key),
    onBlur: formik.handleBlur,
    onChange: onChange || (({ event }) => formik.handleChange(event)),
    isDisabled: formik.isSubmitting
})

export default getInputPropsFactory
