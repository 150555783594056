import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import idx from "idx"
import isEmpty from "lodash/isEmpty"

import { Table, TableBody, TableRow, TableCell, Checkbox, Status, Button, SnakeLoader } from "@butterfly-frontend/ui"
import { Quantity } from "ui"
import { SELECT_STOCK_ITEMS_TABLE_COLUMNS } from "ui/ChooseProduct/constants"
import { useStockItemList } from "modules/WmsModule/hooks/api/useStockItem"
import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import { filterStockItemsForSale } from "modules/WmsModule/helpers/stockItem"

import styles from "./SelectStockItemList.module.css"
import quantityStyles from "./overrides/Quantity.css"

const SelectStockItemList = ({ product, handleSelectStockItems, handleChangeIsLoading, itemsState, onCancel }) => {
    const { data: stockItems, fetchStatus } = useStockItemList({
        params: {
            products: product.id,
            combination_id: product.combination_id,
            statuses: [STOCK_ITEM_STATUS_KEYS.IN_STOCK, STOCK_ITEM_STATUS_KEYS.ON_HOLD],
            length: 999
        }
    })
    const [newItemsQuantity, setNewItemsQuantity] = useState(0)
    const [selectedItemIds, setSelectedItemIds] = useState([])

    const stockItemsAvailableToChoose = useMemo(() => filterStockItemsForSale(stockItems), [stockItems])

    useEffect(() => {
        const inStockItemIds = stockItemsAvailableToChoose
            .sort((stockItemA, stockItemB) => stockItemA.incoming - stockItemB.incoming)
            .map(stockItem => stockItem.id)

        if (inStockItemIds.length) {
            const stockItemsToSelect = [...inStockItemIds.slice(0, product.quantity)]
            setSelectedItemIds(stockItemsToSelect)
            setNewItemsQuantity(product.quantity - stockItemsToSelect.length)
        } else if (isEmpty(inStockItemIds)) {
            setNewItemsQuantity(product.quantity)
        }
    }, [stockItemsAvailableToChoose])

    const handleAddProducts = useCallback(() => {
        handleChangeIsLoading(product.id, true)
        handleSelectStockItems({
            ...product,
            stock_item_ids: selectedItemIds,
            quantity: selectedItemIds.length + newItemsQuantity
        })
    }, [selectedItemIds, itemsState, newItemsQuantity])

    return (
        <Fragment>
            <Table classes={{ root: styles.table }}>
                <TableBody>
                    {fetchStatus.isLoading ? (
                        <SnakeLoader />
                    ) : (
                        <Fragment>
                            {stockItemsAvailableToChoose.map(stockItem => (
                                <TableRow key={stockItem.id}>
                                    <TableCell {...SELECT_STOCK_ITEMS_TABLE_COLUMNS.checkbox}>
                                        <Checkbox
                                            id={stockItem.id}
                                            checked={selectedItemIds.includes(stockItem.id)}
                                            onChange={selectedId =>
                                                setSelectedItemIds(prevState =>
                                                    prevState.includes(selectedId)
                                                        ? prevState.filter(itemId => itemId !== selectedId)
                                                        : [...prevState, selectedId]
                                                )
                                            }
                                        />
                                    </TableCell>
                                    <TableCell {...SELECT_STOCK_ITEMS_TABLE_COLUMNS.productInfo}>
                                        {stockItem.id}
                                    </TableCell>
                                    <TableCell {...SELECT_STOCK_ITEMS_TABLE_COLUMNS.condition}>
                                        <Status color={stockItem.condition.color}>{stockItem.condition.name}</Status>
                                    </TableCell>
                                    <TableCell {...SELECT_STOCK_ITEMS_TABLE_COLUMNS.incoming}>
                                        {stockItem.incoming && (
                                            <Status
                                                theme="inactiveFillBorderless"
                                                classes={{
                                                    root: styles.incomingStatus,
                                                    content: styles.incomingStatusText
                                                }}
                                            >
                                                Incoming
                                            </Status>
                                        )}
                                    </TableCell>
                                    <TableCell {...SELECT_STOCK_ITEMS_TABLE_COLUMNS.warehouse}>
                                        {stockItem.warehouse.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </Fragment>
                    )}
                </TableBody>
            </Table>
            <footer className={styles.footer}>
                <div>
                    <Button classes={{ button: styles.button }} onClick={onCancel}>
                        Back
                    </Button>
                </div>
                <div className={styles.rightSide}>
                    <Quantity
                        customStyles={quantityStyles}
                        value={newItemsQuantity}
                        min={0}
                        onChange={setNewItemsQuantity}
                    />
                    <Button
                        color="blue"
                        classes={{ button: styles.button }}
                        isDisabled={selectedItemIds.length === 0 || fetchStatus.isLoading}
                        isLoading={idx(itemsState, _ => _[product.id].isLoading)}
                        onClick={handleAddProducts}
                    >
                        Add products
                    </Button>
                </div>
            </footer>
        </Fragment>
    )
}

SelectStockItemList.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        combination_id: PropTypes.number,
        quantity: PropTypes.number.isRequired
    }).isRequired,
    handleSelectStockItems: PropTypes.func.isRequired,
    handleChangeIsLoading: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    itemsState: PropTypes.objectOf(
        PropTypes.shape({
            isLoading: PropTypes.bool
        })
    )
}

export default SelectStockItemList
