import React from "react"
import withStyles from "HOC/withStyles"
import styles from "./designTeam.css"

const DesignTeam = props => {
    const { cx, users } = props

    const leader = users.find(user => user.leader)
    const team = users.filter(user => !user.leader)

    const renderUser = (user, isLeader) => {
        return (
            user && (
                <div className={cx("person")}>
                    <div className={cx("shortcut")}>
                        {user.first_name[0]}
                        {user.last_name[0]}
                    </div>
                    <div className={cx("name")}>
                        {user.first_name} {user.last_name}
                        {isLeader && <div>Leader</div>}
                    </div>
                </div>
            )
        )
    }

    return (
        <div className={cx("root")}>
            <h2>Design Team</h2>
            {leader || !!team.length ? (
                <div className={cx("designTeam")}>
                    {leader && (
                        <div className={cx("leader", { isTeam: !!team.length })}>{renderUser(leader, true)}</div>
                    )}
                    <div className={cx("team", { isLeader: !!leader })}>
                        <ul className={cx("list")}>
                            {team.map(user => (
                                <li key={user.id}>{renderUser(user)}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            ) : (
                <div className={cx("noMembers")}>No members added yet.</div>
            )}
        </div>
    )
}

export default withStyles(DesignTeam, styles)
