import React from "react"
import PropTypes from "prop-types"

import Layout from "components/Reusable/Layout/index"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import { Button } from "ui"
import ListRow from "./ListRow"
import EmptyList from "../EmptyList"
import DragAndDropList from "../DragAndDropList"

import withStyles from "HOC/withStyles"
import styles from "./CollectionList.css"
import whiteButtonStyles from "./overrides/whiteButtonStyles.css"
import viewListIcon from "assets/view-list.svg"
import settingsIcon from "assets/ico-settings-blue.svg"

const CollectionList = props => {
    const {
        cx,
        isLoading,
        isUpdatingOrder,
        collectionLabel,
        collectionLabelPlural,
        collectionModel,
        list,
        listMeta,
        getList,
        toggleCollection,
        onCreateCollection,
        onEditCollection,
        onDeleteCollection,
        onPreviewCollection,
        onReorderCollection,
        onChangeLayout,
        canChangeLayout
    } = props

    return (
        <Layout
            title={collectionLabelPlural}
            cta={[
                null,
                canChangeLayout && (
                    <Button
                        label="Change layout"
                        icon={settingsIcon}
                        onClick={onChangeLayout}
                        customStyles={whiteButtonStyles}
                    />
                ),
                <Button
                    className={cx("first-button", "createNewButton")}
                    label="Create New"
                    onClick={onCreateCollection}
                />
            ]}
        >
            {list.length === 0 && isLoading ? (
                <div className={cx("loaderWrapper")}>
                    <SnakeLoader />
                </div>
            ) : list.length === 0 ? (
                <EmptyList message={`No ${collectionLabel} added`} icon={viewListIcon} />
            ) : (
                <div className={cx("contentWrapper")}>
                    <div className={cx("counter")}>
                        {`${listMeta.total} ${(listMeta.total !== 1
                            ? collectionLabelPlural
                            : collectionLabel
                        ).toLowerCase()} added`}
                        <span className={cx("orderLoader", { visible: isUpdatingOrder })}>
                            <SnakeLoader />
                        </span>
                    </div>

                    <div className={cx("list")}>
                        <DragAndDropList itemsList={list} handleReorder={onReorderCollection}>
                            {list.map(collection => (
                                <ListRow
                                    key={collection.id}
                                    cx={cx}
                                    collectionModel={collectionModel}
                                    collection={collection}
                                    toggleCollection={toggleCollection}
                                    onEditCollection={onEditCollection}
                                    onDeleteCollection={onDeleteCollection}
                                    onPreviewCollection={onPreviewCollection}
                                />
                            ))}
                        </DragAndDropList>
                    </div>

                    {listMeta.current_page < listMeta.last_page && (
                        <Button
                            label="Show more"
                            className={cx("first-button", "showMoreButton")}
                            onClick={() => getList()}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            )}
        </Layout>
    )
}

CollectionList.propTypes = {
    isLoading: PropTypes.bool,
    isUpdatingOrder: PropTypes.bool,
    collectionLabel: PropTypes.string.isRequired,
    collectionLabelPlural: PropTypes.string.isRequired,
    collectionModel: PropTypes.string,
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            title: PropTypes.string.isRequired,
            thumbnail: PropTypes.string,
            active: PropTypes.bool.isRequired
        })
    ).isRequired,
    listMeta: PropTypes.shape({
        current_page: PropTypes.number,
        last_page: PropTypes.number,
        total: PropTypes.number
    }).isRequired,
    getList: PropTypes.func.isRequired,
    toggleCollection: PropTypes.func.isRequired,
    onCreateCollection: PropTypes.func.isRequired,
    onEditCollection: PropTypes.func.isRequired,
    onDeleteCollection: PropTypes.func.isRequired,
    onPreviewCollection: PropTypes.func.isRequired,
    onReorderCollection: PropTypes.func.isRequired,
    canChangeLayout: PropTypes.bool
}

export default withStyles(CollectionList, styles)
