import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./MultipleRadio.css"

class MultipleRadio extends Component {
    render() {
        const exampleConfig = [
            { value: "default-value", label: "Default Value" },
            { value: "second", label: "Second" },
            { value: "third", label: "Third" }
        ]
        const { cx, config = exampleConfig, name, checked = "default-value", isHorizontal, handleSelect } = this.props

        return (
            <div className={cx("root")}>
                {config.map(({ value, label }) => {
                    const isChecked = value === checked

                    return (
                        <label key={value} className={cx("label", { isHorizontal, isChecked })}>
                            <input
                                name={name}
                                type="radio"
                                onChange={() => handleSelect(value, name)}
                                checked={isChecked}
                            />
                            <div />
                            <span>{label}</span>
                        </label>
                    )
                })}
            </div>
        )
    }
}

export default withStyles(MultipleRadio, styles)
