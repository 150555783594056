import React from "react"
import PropTypes from "prop-types"

import { IncrementalCounter } from "modules/WmsModule/components"

import { useBoxListForm } from "./hooks"
import { BoxListFormTable, BoxLocationSelect } from "./components"

import styles from "./BoxListForm.module.css"

const BoxListForm = ({ formik, showSelectLocation = false }) => {
    const { boxCounterProps, boxTableProps, selectLocationProps, boxes } = useBoxListForm({ formik })

    return (
        <div className={styles.root}>
            <div className={styles.headerContainer}>
                <h2 className={styles.title}>Item pack</h2>
                {showSelectLocation && (
                    <div className={styles.locationContainer}>
                        <BoxLocationSelect {...selectLocationProps} />
                    </div>
                )}
                <div className={styles.counterContainer}>
                    <IncrementalCounter {...boxCounterProps} isDisabled={formik.isSubmitting} />
                </div>
            </div>
            {boxes.length > 0 && <BoxListFormTable {...boxTableProps} />}
        </div>
    )
}

BoxListForm.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.shape({
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string.isRequired
            }),
            boxes: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number,
                    location: PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string.isRequired
                    })
                }).isRequired
            ).isRequired
        }).isRequired,
        isSubmitting: PropTypes.bool
    }).isRequired,
    showSelectLocation: PropTypes.bool
}

export default BoxListForm
