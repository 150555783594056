import { INPUT_TYPES } from "constants/index"

export default {
    name: "Client Search",
    endpoint: "/clients",
    fields: [
        {
            name: "assigned_to",
            label: "Assigned To",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "active",
            label: "Active",
            type: INPUT_TYPES.TEXT,
            defaultValue: false,
            attributes: {}
        },
        {
            name: "verified",
            label: "Verified",
            type: INPUT_TYPES.TEXT,
            defaultValue: false,
            attributes: {}
        },
        {
            name: "types",
            label: "Contact Types",
            type: INPUT_TYPES.ARRAY,
            defaultValue: [],
            attributes: {}
        },
        {
            name: "post_code",
            label: "ZIP Code",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "city",
            label: "City",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "state",
            label: "State",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "country",
            label: "Country",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "group_id",
            label: "Group",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "channel_id",
            label: "Communication channel",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "verified",
            label: "Verification",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "company_client_id",
            label: "Division",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "service_id",
            label: "Service",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "deleted",
            label: "Deleted",
            type: INPUT_TYPES.CHECKBOX,
            defaultValue: false,
            attributes: {}
        },
        {
            name: "online_enabled",
            label: "Web enabled",
            type: INPUT_TYPES.CHECKBOX,
            defaultValue: false,
            attributes: {}
        }
    ]
}
