import env from "../env"
import fetch from "isomorphic-fetch"
import { from, throwError } from "rxjs"
import { fromFetch } from "rxjs/fetch"
import { switchMap } from "rxjs/operators"

const { API } = env

const getRequestOptions = ({ method, body, headers = {} }) => {
    const loggedUserHeaders = {
        "X-Authorization": window.localStorage.getItem("accessToken", null)
    }

    const sessionToken = window.localStorage.getItem("sessionToken", null)
    const sessionHeaders = sessionToken
        ? {
              "X-Session": sessionToken
          }
        : {}

    return {
        method,
        ...(body ? { body: JSON.stringify(body) } : {}),
        headers: {
            ...headers,
            ...API.authorizationHeaders,
            ...loggedUserHeaders,
            ...sessionHeaders,
            "Content-Type": "application/json",
            Accept: "application/json"
        }
    }
}

const request = ({ endpoint, method, headers, body = null, raw = false }) => {
    return baseRequest({ endpoint: API.baseUrl + endpoint, method, headers, body, raw })
}

const baseRequest = ({ endpoint, method, headers, body = null, raw = false }) => {
    const options = getRequestOptions({ method, headers, body })

    return fetch(endpoint, options).then(response => {
        if (!response.ok) {
            if (response.status === 401) {
                window.location.href = "/logout"
            }
            if (response.status === 404) {
                return Promise.reject(response)
            }
            if (response.status === 422 || response.status === 400) {
                return response.json().then(json => Promise.reject(json))
            }
            throw new DOMException(response.statusText)
        }
        return !raw ? response.json() : response
    })
}

const request$ = ({ endpoint, method, headers }) => {
    const options = getRequestOptions({ method, headers })

    return fromFetch(API.baseUrl + endpoint, options).pipe(
        switchMap(response => {
            if (!response.ok) {
                if (response.status === 401) {
                    window.location.href = "/logout"
                    return throwError("Unauthorized")
                }
                if (response.status === 404) {
                    return throwError(response)
                }
                if (response.status === 422 || response.status === 400) {
                    return throwError(response.json())
                }
                throw new DOMException(response.statusText)
            }

            return from(response.json())
        })
    )
}

export default {
    get: endpoint => {
        return request({ endpoint, method: "GET" })
    },
    getRAW: endpoint => {
        return request({ endpoint, method: "GET", raw: true })
    },
    post: (endpoint, body) => {
        return request({ endpoint, method: "POST", body })
    },
    postRAW: (endpoint, body) => {
        return request({ endpoint, method: "POST", body, raw: true })
    },
    patch: (endpoint, body) => {
        return request({ endpoint, method: "PATCH", body })
    },
    patchRAW: (endpoint, body) => {
        return request({ endpoint, method: "PATCH", body, raw: true })
    },
    put: (endpoint, body) => {
        return request({ endpoint, method: "PUT", body })
    },
    delete: (endpoint, body) => {
        return request({ endpoint, method: "DELETE", body })
    },
    deleteRAW: (endpoint, body) => {
        return request({ endpoint, method: "DELETE", body, raw: true })
    },
    get$: endpoint => {
        return request$({ endpoint, method: "GET" })
    },
    baseGetRAW: endpoint => {
        return baseRequest({ endpoint, method: "GET", raw: true })
    }
}
