import React, { useMemo } from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"

import styles from "./Tabs.css"

import alertIcon from "assets/alert-error.svg"

const Tabs = props => {
    const { cx, tabs, activeTabKey, onTabChange } = props

    const mappedTabs = useMemo(() => {
        const toObject = (acc, tab) => ({ ...acc, [tab]: { label: tab } })
        const mappedToObject = Array.isArray(tabs) ? tabs.reduce(toObject, {}) : tabs

        return Object.entries(mappedToObject)
    }, [tabs])

    return (
        <nav className={cx("root")}>
            <ul>
                {mappedTabs.map(([key, { label, count, disabled, errorCount }]) => {
                    const isCount = typeof count === "number" && count >= 0
                    const isErrorCount = typeof errorCount === "number" && errorCount >= 0

                    return (
                        <li
                            key={label}
                            className={cx("tab", { active: activeTabKey === key, disabled })}
                            onClick={() => onTabChange(key)}
                        >
                            <div>
                                {label}
                                {isCount && <span>{count}</span>}
                                {isErrorCount && <img src={alertIcon} alt="" />}
                            </div>
                        </li>
                    )
                })}
            </ul>
        </nav>
    )
}

Tabs.propTypes = {
    tabs: PropTypes.objectOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            count: PropTypes.number,
            disabled: PropTypes.bool
        })
    ).isRequired,
    activeTabKey: PropTypes.string.isRequired,
    onTabChange: PropTypes.func.isRequired
}

export default withStyles(Tabs, styles)
