import React, { Component } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import withStyles from "HOC/withStyles"
import styles from "./list.module.css"
import { READABLE_STANDARD_DATE } from "constants/index"

class List extends Component {
    render() {
        const { cx, list, handleOpenModal } = this.props
        return (
            <ul className={cx("list")}>
                {list.map(el => {
                    const { id, first_name, last_name, updated_at, created_at, email, phone } = el
                    const profileUrl = `/contact/${id}/profile`

                    return (
                        <li key={id}>
                            <ul className={cx("row", "likeTable")}>
                                <li>
                                    <Link to={profileUrl}>
                                        {first_name} {last_name}
                                    </Link>
                                </li>
                                <li>{email}</li>
                                <li>{phone}</li>
                                <li className={cx("date")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M3 3h18v18H3z" />
                                            <path
                                                className={cx("withFill")}
                                                fillRule="nonzero"
                                                d="M11.992 4.5C16.14 4.5 19.5 7.86 19.5 12c0 4.14-3.36 7.5-7.508 7.5-4.14 0-7.492-3.36-7.492-7.5 0-4.14 3.353-7.5 7.492-7.5zM12 18c3.315 0 6-2.685 6-6s-2.685-6-6-6-6 2.685-6 6 2.685 6 6 6zm.375-9.75v3.938l3.375 2.002-.563.923-3.937-2.363v-4.5h1.125z"
                                            />
                                        </g>
                                    </svg>
                                    <span>{moment(created_at).format(READABLE_STANDARD_DATE)}</span>
                                </li>
                                <li className={cx("date")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M3 3h18v18H3z" />
                                            <path
                                                className={cx("withFill")}
                                                fillRule="nonzero"
                                                d="M11.992 4.5C16.14 4.5 19.5 7.86 19.5 12c0 4.14-3.36 7.5-7.508 7.5-4.14 0-7.492-3.36-7.492-7.5 0-4.14 3.353-7.5 7.492-7.5zM12 18c3.315 0 6-2.685 6-6s-2.685-6-6-6-6 2.685-6 6 2.685 6 6 6zm.375-9.75v3.938l3.375 2.002-.563.923-3.937-2.363v-4.5h1.125z"
                                            />
                                        </g>
                                    </svg>
                                    <span>{moment(updated_at).format(READABLE_STANDARD_DATE)}</span>
                                </li>
                                <li>
                                    <span onClick={() => handleOpenModal(id)} className={cx("action")}>
                                        <span>Delete</span>
                                    </span>
                                </li>
                            </ul>
                        </li>
                    )
                })}
            </ul>
        )
    }
}

export default withStyles(List, styles)
