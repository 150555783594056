import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"

import { Modal, Button, Textarea } from "ui"
import { Attachments } from "./components"

import withStyles from "HOC/withStyles"
import styles from "./AddEditModal.css"
import textareaStyles from "./overrides/Textarea.css"

import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"

const { Header, Body, Actions } = Modal

const ENTER_KEY = 13

const AddEditModal = props => {
    const { cx, isEdit, isOpen, isLoading, hasAttachments, type, onClose, onSubmit, text, attachments } = props

    const [value, setValue] = useState("")
    const [currentAttachments, setCurrentAttachments] = useState([])
    const [isUploading, setIsUploading] = useState(false)

    const isEnabled = useMemo(() => {
        const attachmentsHaveChanged = hasAttachments ? !isEqual(attachments, currentAttachments) : false
        return !isUploading && (isEdit ? !!value && (value !== text || attachmentsHaveChanged) : !!value)
    }, [value, text, isUploading, hasAttachments, attachments, currentAttachments, isEdit])

    useEffect(() => {
        if (isOpen && isEdit) {
            setValue(text)
            setCurrentAttachments(attachments)
        }

        if (!isOpen) {
            setValue("")
            setCurrentAttachments([])
        }
    }, [isOpen])

    const handleOnSubmit = () =>
        onSubmit(hasAttachments ? { text: value, attachments: currentAttachments.map(a => a.id) } : value)

    const onChange = ({ event }) => setValue(event.target.value)
    const onKeyDown = event => event.keyCode === ENTER_KEY && event.ctrlKey && isEnabled && handleOnSubmit()

    const handleUploadSuccess = uploadedFiles => {
        const formattedUploadedFiles = uploadedFiles.map(({ title, file, id }) => ({
            id,
            name: title,
            size: file.size
        }))

        setIsUploading(false)
        setCurrentAttachments(oldAttachments => [...oldAttachments, ...formattedUploadedFiles])
    }

    const handleRemove = attachmentId => {
        setCurrentAttachments(oldAttachments => oldAttachments.filter(a => a.id !== attachmentId))
    }

    return (
        <Modal preventClickOutside={isEnabled} isOpen={isOpen} closeModal={onClose}>
            <Header>
                {isEdit ? "Edit" : "Leave a"} {type}
            </Header>
            <Body>
                <Textarea
                    className={cx("textArea")}
                    value={value}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    isPlaceholder
                    label={`Leave your ${type}...`}
                    customStyles={textareaStyles}
                />
                {hasAttachments && (
                    <Attachments
                        endpoint={DEFAULT_FILE_UPLOAD_ENPOINT}
                        currentAttachments={currentAttachments}
                        handleUploadSuccess={handleUploadSuccess}
                        handleLoading={setIsUploading}
                        handleRemove={handleRemove}
                    />
                )}
            </Body>
            <Actions>
                <button className={cx("closeButton")} onClick={onClose}>
                    Cancel
                </button>
                <Button
                    className="first-button"
                    onClick={handleOnSubmit}
                    label={isEdit ? "Edit" : "Add"}
                    isLoading={isLoading}
                    isDisabled={!isEnabled}
                />
            </Actions>
        </Modal>
    )
}

AddEditModal.defaultProps = {
    hasAttachments: false,
    type: "note",
    attachments: []
}

AddEditModal.propTypes = {
    isEdit: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    hasAttachments: PropTypes.bool,
    type: PropTypes.string,
    text: PropTypes.string,
    attachments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            url: PropTypes.string,
            size: PropTypes.number
        })
    ),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default withStyles(AddEditModal, styles)
