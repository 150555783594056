import React, { useCallback, Fragment } from "react"
import _get from "lodash/get"
import { useQueryClient } from "react-query"
import {
    Modal,
    SnakeLoader,
    useTableFilterBarSearch,
    Button,
    EmptyInternalList,
    ListIcon,
    AlertErrorIcon
} from "@butterfly-frontend/ui"

import { Input, InfiniteScroll } from "ui"
import { useUserInfiniteList } from "modules/WmsModule/hooks/api/useUser"

import useAssignUserToWarehouse from "./useAssignUserToWarehouse"
import inputStyles from "./overrides/Input.module.css"
import styles from "./AssignUserModal.module.css"

const AssignUserModal = ({ onClose, selectedWarehouses }) => {
    const queryClient = useQueryClient()

    const {
        value: filterSearchPhrase,
        debouncedValue: query,
        onChange: handleChangeFilterSearchPhrase
    } = useTableFilterBarSearch("")

    const { pages, meta, hasNextPage, isFetchingNextPage, fetchNextPage, queryKey, fetchStatus } = useUserInfiniteList({
        params: { query }
    })

    const { checkIfShouldAssign, onAssign, onUnassign } = useAssignUserToWarehouse({ selectedWarehouses, queryKey })

    const checkIfUserIsMutating = useCallback(
        userId =>
            queryClient.isMutating({
                mutationKey: queryKey,
                predicate: mutation => userId === _get(mutation, "options.variables.id")
            }) > 0,
        [queryClient.isMutating, queryKey]
    )

    const totalUsersCount = _get(meta, "total", 0)

    return (
        <Modal title="Assign" onClose={onClose}>
            <div className={styles.assignUserModal}>
                <div className={styles.searchBar}>
                    <Input
                        name="search"
                        label="Find user"
                        isPlaceholder={true}
                        value={filterSearchPhrase}
                        onChange={({ event }) => handleChangeFilterSearchPhrase(event)}
                        autoComplete="off"
                        customStyles={inputStyles}
                        isClear
                    />
                </div>

                <div className={styles.listContainer}>
                    {fetchStatus.isError ? (
                        <EmptyInternalList text="Unknown error" Icon={AlertErrorIcon} />
                    ) : fetchStatus.isLoading && !fetchStatus.isLoaded ? (
                        <SnakeLoader />
                    ) : (
                        fetchStatus.isLoaded &&
                        (totalUsersCount ? (
                            <Fragment>
                                {!!totalUsersCount && (
                                    <div className={styles.userListInfo}>{totalUsersCount} users</div>
                                )}

                                <div className={styles.usersListInfiniteScrollContainer}>
                                    <InfiniteScroll
                                        className={styles.userList}
                                        hasMore={hasNextPage}
                                        loader={<SnakeLoader key="loader" />}
                                        loadMore={() => {
                                            if (hasNextPage || !isFetchingNextPage) {
                                                return fetchNextPage()
                                            }
                                        }}
                                        threshold={200}
                                        useWindow={false}
                                        element="ul"
                                    >
                                        {pages &&
                                            pages.map((page, i) => (
                                                <Fragment key={i}>
                                                    {page.data.map(user => (
                                                        <li key={user.id}>
                                                            <div className={styles.userInfo}>
                                                                <span
                                                                    className={styles.userName}
                                                                >{`${user.first_name} ${user.last_name}`}</span>
                                                                <span className={styles.userEmail}>{user.email}</span>
                                                            </div>
                                                            {checkIfShouldAssign(user) ? (
                                                                <Button
                                                                    size="small"
                                                                    color="blue"
                                                                    isLoading={checkIfUserIsMutating(user.id)}
                                                                    onClick={() => onAssign(user)}
                                                                >
                                                                    Assign
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    size="small"
                                                                    variant="flat"
                                                                    classes={{ button: styles.unassignButton }}
                                                                    isLoading={checkIfUserIsMutating(user.id)}
                                                                    onClick={() => onUnassign(user)}
                                                                >
                                                                    Unassign
                                                                </Button>
                                                            )}
                                                        </li>
                                                    ))}
                                                </Fragment>
                                            ))}
                                    </InfiniteScroll>
                                </div>
                            </Fragment>
                        ) : (
                            <EmptyInternalList text="Sorry, no results found" Icon={ListIcon} />
                        ))
                    )}
                </div>
            </div>
        </Modal>
    )
}

export default AssignUserModal
