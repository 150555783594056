import React, { useMemo, Fragment } from "react"
import { useParams, Link } from "react-router-dom"
import getStyles from "helpers/getCxFromStyles"
import { useIsTablet } from "helpers/rwd"
import { stringify } from "qs"
import { getProductByIdSuccess } from "actions/product"
import { showErrorNotification } from "actions/notification"
import fetch from "helpers/fetch"
import { useActions } from "hooks"

import { Skeleton } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import {
    Breadcrumbs,
    Hero,
    GeneralInfo,
    Notes,
    Attachments,
    RelatedProducts,
    Download,
    BindedProducts
} from "./Components"
import Widget from "modules/SelectionsWidgetModule/components/WidgetContainer"
import Faq from "modules/BrandsModule/pages/Overview/Details/Tabs/Faq"

import styles from "./styles.css"
import arrowIcon from "assets/arrow-blue.svg"

const CurrentDetail = React.memo(({ Component, product, productId, fetchProduct, selectedCombination }) => {
    return (
        <Component
            product={product}
            productId={productId}
            faqs={product.faqs || []}
            fetchParent={fetchProduct}
            faqableId={product.id}
            faqableType="product"
            selectedCombination={selectedCombination}
        />
    )
})

const Details = props => {
    const { product, fetchStatus, openSelectionModal, selectedCombination, setSelectedCombination } = props
    const { notes, attachments, faqs, public_attachments } = product
    const actions = useActions({ getProductByIdSuccess, showErrorNotification })

    const isTablet = useIsTablet()
    const { productId, sectionName } = useParams()

    const cx = getStyles(styles)

    const breadcrumbs = useMemo(() => {
        const breadcrumbs = [
            {
                link: "/products",
                text: (
                    <span>
                        <img src={arrowIcon} alt="Back" /> All products
                    </span>
                )
            }
        ]

        if (product.categories && product.categories.length) {
            breadcrumbs.splice(
                1,
                0,
                ...product.categories[0].map((category, index) => ({
                    link: `/products?${stringify({
                        categories: product.categories[0].slice(0, index + 1).map(cat => cat.id)
                    })}`,
                    text: category.name
                }))
            )
        }

        return breadcrumbs
    }, [product])

    const TABS = useMemo(
        () => [
            { key: "general-info", label: "GENERAL INFO", Component: GeneralInfo, enabled: true, isDefault: true },
            {
                key: "notes",
                label: "NOTES",
                Component: Notes,
                enabled: true,
                count: Array.isArray(notes) ? notes.length : 0
            },
            {
                key: "attachments",
                label: "ATTACHMENTS",
                Component: Attachments,
                enabled: true,
                count: Array.isArray(attachments) ? attachments.length : 0
            },
            {
                key: "faq",
                label: "FAQ",
                Component: Faq,
                enabled: true,
                count: Array.isArray(faqs) ? faqs.length : 0
            },
            {
                key: "download",
                label: "DOWNLOAD",
                Component: Download,
                enabled: true,
                count: Array.isArray(public_attachments) ? public_attachments.length : 0
            }
        ],
        [notes, attachments, faqs, public_attachments]
    )

    const currentTab = useMemo(() => TABS.find(tab => (sectionName ? tab.key === sectionName : tab.isDefault)), [
        sectionName
    ])

    const fetchProduct = () =>
        fetch
            .get(`/products/${productId}`)
            .then(data => actions.getProductByIdSuccess(data))
            .catch(() => actions.showErrorNotification())

    return (
        <Fragment>
            <Skeleton
                fetchStatus={fetchStatus}
                SkeletonComponent={() => (
                    <div className={cx("loaderContainer")}>
                        <SnakeLoader />
                    </div>
                )}
            >
                <div className={cx("root")}>
                    <div className={cx("breadcrumbs")}>
                        <Breadcrumbs items={isTablet ? breadcrumbs.slice(0, 1) : breadcrumbs} />
                    </div>
                    <div className={cx("section")}>
                        <Hero
                            {...props}
                            combination={selectedCombination}
                            setCombination={setSelectedCombination}
                            product={product}
                            openSelectionModal={openSelectionModal}
                        />
                    </div>
                    <div className={cx("section")}>
                        <div className={cx("header")}>
                            <ul className={cx("tabs")}>
                                {TABS.map(({ key, label, enabled, count, isDefault }) => {
                                    const active = sectionName ? sectionName === key : isDefault

                                    return (
                                        <li key={key}>
                                            <Link
                                                className={cx("tab", { active, disabled: !enabled })}
                                                to={`/products/${productId}/${key}`}
                                            >
                                                {label}
                                                {typeof count === "number" && <span>{count}</span>}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className={cx("currentDetailContainer")}>
                            <CurrentDetail
                                Component={currentTab.Component}
                                product={product}
                                productId={productId}
                                fetchProduct={fetchProduct}
                                selectedCombination={selectedCombination}
                            />
                        </div>
                    </div>
                    <div className={cx("section")}>
                        <RelatedProducts product={product} />
                    </div>
                    <div className={cx("section")}>
                        <BindedProducts product={product} fetchProduct={fetchProduct} />
                    </div>
                </div>
            </Skeleton>
            <Widget />
        </Fragment>
    )
}

export default Details
