import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Skeleton, EmptyList, Checkbox } from "ui"
import ListItem from "./ListItem"

import { fetchStatusPropTypes } from "helpers/fetchStatus"

import withStyles from "HOC/withStyles"
import styles from "./List.css"

import productIcon from "assets/ico-projects.svg"

const EmptyListMessage = () => <EmptyList message="Sorry, no results found!" icon={productIcon} />

const List = props => {
    const {
        cx,
        fetchStatus,
        manualEntries,
        meta,
        selected,
        areSomeSelected,
        onClickAll,
        onClickListItem,
        convertToProduct,
        editManualEntry,
        deleteManualEntry
    } = props

    return (
        <div className={cx("root")}>
            <Skeleton fetchStatus={fetchStatus} fallback={EmptyListMessage}>
                {manualEntries.length ? (
                    <Fragment>
                        <div className={cx("list-meta")}>
                            <strong>
                                {meta.from}-{meta.to}
                            </strong>{" "}
                            over {meta.total} results
                        </div>

                        <div className={cx("row", "list-header")}>
                            <Checkbox checked={selected.length} isMinusIcon={areSomeSelected} onChange={onClickAll} />
                            <p />
                            <p>Name</p>
                            <p>Vendor</p>
                            <p>Retail Price</p>
                            <p />
                        </div>
                        <ul>
                            {manualEntries.map(manualEntry => (
                                <ListItem
                                    key={manualEntry.id}
                                    data={manualEntry}
                                    selected={selected.includes(manualEntry.id)}
                                    onSelect={onClickListItem}
                                    convertToProduct={convertToProduct}
                                    edit={editManualEntry}
                                    remove={deleteManualEntry}
                                />
                            ))}
                        </ul>
                    </Fragment>
                ) : (
                    <EmptyListMessage />
                )}
            </Skeleton>
        </div>
    )
}

List.propTypes = {
    fetchStatus: fetchStatusPropTypes,
    manualEntries: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            media: PropTypes.shape({
                url: PropTypes.string
            }),
            name: PropTypes.string.isRequired,
            brand: PropTypes.string.isRequired,
            lowest_price: PropTypes.number.isRequired
        })
    ).isRequired,
    meta: PropTypes.shape({
        from: PropTypes.number,
        to: PropTypes.number,
        total: PropTypes.number
    }).isRequired,
    selected: PropTypes.arrayOf(PropTypes.number).isRequired,
    areSomeSelected: PropTypes.bool.isRequired,
    onClickAll: PropTypes.func.isRequired,
    onClickListItem: PropTypes.func.isRequired,
    convertToProduct: PropTypes.func.isRequired,
    editManualEntry: PropTypes.func.isRequired,
    deleteManualEntry: PropTypes.func.isRequired
}

export default withStyles(List, styles)
