import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import * as defaultComponents from "./defaultComponents"

import { getComponents } from "helpers/overridesPattern"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"

const Attachments = props => {
    const { isUploading, attachments, sorting, handleUpload, handleSetSorting, handleDelete, overrides } = props

    const [uploadingAttachments, setUploadingAttachments] = useState([])

    useEffect(() => {
        !isUploading && setUploadingAttachments([])
    }, [isUploading])

    const setUploadingStatus = ({ addedFile }) => setUploadingAttachments(addedFile)

    const {
        Root: { component: Root, props: rootProps },
        Dropzone: { component: Dropzone, props: dropzoneProps },
        List: { component: List, props: listProps },
        Header: { component: Header, props: headerProps },
        ListItem: { component: ListItem, props: listItemProps }
    } = useMemo(() => getComponents(defaultComponents, overrides), [overrides])

    const isListVisible = !!attachments.length || !!uploadingAttachments.length

    return (
        <Root {...rootProps}>
            <Dropzone
                handleUploadSuccess={handleUpload}
                endpoint={DEFAULT_FILE_UPLOAD_ENPOINT}
                handleUploadProgress={setUploadingStatus}
                {...dropzoneProps}
            />
            <List isVisible={isListVisible} {...listProps}>
                <Header sorting={sorting} handleSetSorting={handleSetSorting} {...headerProps} />
                {attachments.map((attachment, index) => (
                    <ListItem
                        key={`${attachment.id}-${index}`}
                        {...attachment}
                        handleDelete={handleDelete}
                        {...listItemProps}
                    />
                ))}
                {uploadingAttachments.map((attachment, index) => (
                    <ListItem
                        isUploading
                        key={`${attachment.name}-${index}`}
                        {...attachment}
                        name={attachment.title}
                        {...listItemProps}
                    />
                ))}
            </List>
        </Root>
    )
}

Attachments.defaultProps = {
    overrides: {}
}

const overrideShape = PropTypes.shape({
    props: PropTypes.object,
    styles: PropTypes.object,
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.object])
})

Attachments.propTypes = {
    isUploading: PropTypes.bool.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    sorting: PropTypes.shape({
        by: PropTypes.string,
        direction: PropTypes.oneOf(["asc", "desc"])
    }),
    handleUpload: PropTypes.func.isRequired,
    handleSetSorting: PropTypes.func,
    handleDelete: PropTypes.func,
    overrides: PropTypes.shape({
        Root: overrideShape,
        Dropzone: overrideShape,
        List: overrideShape,
        Header: overrideShape,
        ListItem: overrideShape
    })
}

export default Attachments
