import React, { useState, useEffect, useReducer, useMemo } from "react"
import { withRouter } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import fetch from "helpers/fetch"
import { getProductById } from "actions/product"
import { scrollToTop } from "helpers/scrollTo"
import { combineFetchStatuses } from "helpers/fetchStatus"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import useNumericValueFromQueryString from "modules/WmsModule/hooks/useNumericValueFromQueryString"

import Details from "./Details"

const chooseOrderReducer = (state, action) => {
    if (action.type === "toggleModal") {
        return {
            ...state,
            isOpen: !state.isOpen,
            orderId: null,
            isLoading: false
        }
    }

    if (action.type === "setOrderId") {
        return {
            ...state,
            orderId: action.payload,
            isLoading: true
        }
    }

    if (action.type === "setValues") {
        return {
            ...state,
            ...action.payload
        }
    }

    return state
}

const chooseProjectReducer = (state, action) => {
    if (action.type === "toggleModal") {
        return {
            ...state,
            isOpen: !state.isOpen,
            id: null,
            isLoading: false
        }
    }

    if (action.type === "setValues") {
        return {
            ...state,
            ...action.payload
        }
    }

    return state
}

const fetchCombination = ({ id }) => fetch.get(`/combinations/${id}`)

const DetailsContainer = props => {
    const {
        match: {
            params: { productId }
        },
        history: routerHistory
    } = props

    const combinationId = useNumericValueFromQueryString({ key: "combination_id" })
    const product = useSelector(store => store.db.products.current)
    const userId = useSelector(store => store.loginStore.userId)
    const dispatch = useDispatch()

    const [selectedAttributes, setSelectedAttributes] = useState([])
    const [isOpenDuplicateModal, setIsOpenDuplicateModal] = useState(false)
    const [combinationFetchStatus, setCombinationFetchStatus] = useState({ isLoading: false, isLoaded: false })
    const [selectedCombination, setSelectedCombination] = useState()
    const fetchStatus = useMemo(() => combineFetchStatuses([product.fetchStatus, combinationFetchStatus]), [
        product.fetchStatus,
        combinationFetchStatus
    ])

    const [chooseOrderState, dispatchChooseOrder] = useReducer(chooseOrderReducer, {
        isOpen: false,
        orderId: null,
        isLoading: false
    })

    const [chooseProjectState, dispatchChooseProject] = useReducer(chooseProjectReducer, {
        isOpen: false,
        id: null,
        isLoading: false
    })

    useEffect(() => {
        if (combinationId) {
            setCombinationFetchStatus({ isLoading: true, isLoaded: false })

            fetchCombination({ id: combinationId })
                .then(response => setSelectedAttributes(response.data.attribute_values.map(({ id }) => id)))
                .catch(() => routerHistory.replace({ search: "" }))
                .finally(() => setCombinationFetchStatus({ isLoaded: true, isLoading: false }))
        } else {
            setCombinationFetchStatus({ isLoaded: true, isLoading: false })
        }
    }, [])

    useEffect(() => {
        productId && dispatch(getProductById(productId))
        scrollToTop()
    }, [productId])

    useEffect(() => {
        if (chooseOrderState.orderId) {
            fetch
                .postRAW("/order-items", {
                    product_id: productId,
                    order_id: chooseOrderState.orderId,
                    combination_id: chooseOrderState.combinationId,
                    quantity: chooseOrderState.quantity
                })
                .then(
                    () => {
                        dispatch(showSuccessNotification())
                    },
                    () => {
                        dispatch(showErrorNotification())
                    }
                )
                .finally(() => {
                    dispatchChooseOrder({
                        type: "setValues",
                        payload: {
                            isLoading: false,
                            orderId: null
                        }
                    })
                })
        }
    }, [chooseOrderState.orderId])

    useEffect(() => {
        if (chooseProjectState.id) {
            dispatchChooseProject({
                type: "setValues",
                payload: {
                    isLoading: true
                }
            })

            fetch
                .postRAW("/project-items", {
                    product_id: productId,
                    project_id: chooseProjectState.id,
                    combination_id: chooseProjectState.combinationId,
                    quantity: chooseProjectState.quantity,
                    room_id: chooseProjectState.roomId
                })
                .then(
                    () => {
                        dispatch(showSuccessNotification())
                    },
                    () => {
                        dispatch(showErrorNotification())
                    }
                )
                .finally(() => {
                    dispatchChooseProject({
                        type: "setValues",
                        payload: {
                            id: null,
                            isLoading: false
                        }
                    })
                })
        }
    }, [chooseProjectState.id])

    return (
        <Details
            product={product.data}
            userId={userId}
            fetchStatus={fetchStatus}
            chooseOrderState={chooseOrderState}
            dispatchChooseOrder={dispatchChooseOrder}
            chooseProjectState={chooseProjectState}
            dispatchChooseProject={dispatchChooseProject}
            setSelectedAttributes={setSelectedAttributes}
            selectedAttributes={selectedAttributes}
            isOpenDuplicateModal={isOpenDuplicateModal}
            setIsOpenDuplicateModal={setIsOpenDuplicateModal}
            openSelectionModal={openSelectionModal}
            selectedCombination={selectedCombination}
            setSelectedCombination={setSelectedCombination}
        />
    )

    function openSelectionModal() {}
}

export default withRouter(DetailsContainer)
