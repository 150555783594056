import * as constants from "./../../../constants/index"

function assignedTo(state = {}, action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_ASSIGNEDTO:
            return {}
        case constants.CHANGE_ASSIGNEDTO:
            return value
        default:
            return state
    }
}

export default assignedTo
