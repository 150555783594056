import React from "react"
import PropTypes from "prop-types"

import { Modal, Button } from "ui"

import withStyles from "HOC/withStyles"
import styles from "./submitModal.css"

const SubmitModal = props => {
    const {
        cx,
        isOpen,
        isLoading,
        isSubmitDisabled,
        redButton,
        preventClickOutside,
        withLine,
        header,
        body,
        cancelText,
        submitText,
        onClose,
        onSubmit
    } = props

    return (
        <Modal isOpen={isOpen} closeModal={onClose} preventClickOutside={preventClickOutside}>
            {header && <Modal.Header>{header}</Modal.Header>}
            <Modal.Body>{body}</Modal.Body>
            <Modal.Actions withLine={withLine}>
                <button className={cx("cancelButton")} onClick={onClose}>
                    {cancelText}
                </button>
                <Button
                    className={`first-button ${cx({ redButton })}`}
                    isLoading={isLoading}
                    isDisabled={isSubmitDisabled}
                    label={submitText}
                    onClick={onSubmit}
                />
            </Modal.Actions>
        </Modal>
    )
}

SubmitModal.defaultProps = {
    withLine: true,
    cancelText: "Cancel",
    submitText: "Submit"
}

SubmitModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    isSubmitDisabled: PropTypes.bool,
    redButton: PropTypes.bool,
    preventClickOutside: PropTypes.bool,
    withLine: PropTypes.bool,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    cancelText: PropTypes.string,
    submitText: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: (props, propName, componentName) => {
        if (props.isOpen && !props.isSubmitDisabled && typeof props.onSubmit !== "function") {
            return new Error(
                `Prop ${propName} of ${componentName} should be function. ${propName} is required if modal is open and submit action is not disabled.`
            )
        }
    }
}

export default withStyles(SubmitModal, styles)
