import React, { Component } from "react"

import withStyles from "HOC/withStyles"
import withShowScrollBar from "HOC/withShowScrollBar"

import styles from "./styles.css"
import Button from "ui/Button"

class ReviewModal extends Component {
    state = {
        isShownError: true
    }

    componentDidUpdate(prevProps) {
        const {
            fileData: { errorsCount },
            isSaving
        } = this.props

        if (prevProps.isSaving && !isSaving && errorsCount) this.setState({ isShownError: true })
    }

    render() {
        const { cx, handleHide, handleSave, fileData, isSaving, handleShowScrollBar, title } = this.props
        const { isShownError } = this.state
        const { errorsCount } = fileData

        return (
            <div className={cx("modal-background")} ref={node => (this.node = node)}>
                <div className={cx("root")}>
                    <iframe
                        className={cx("iframe")}
                        src={`//docs.google.com/spreadsheets/d/${fileData.spreadsheet}/edit?ui=2&rm=demo`}
                        title={title || "Products"}
                        frameBorder="0"
                    />
                    <div className={cx("errors", { isShown: isShownError && errorsCount })}>
                        {errorsCount > 0 && <b>Errors count: {fileData.errorsCount}</b>}
                        <div
                            className={cx("icon-close")}
                            alt="close"
                            onClick={() => {
                                this.setState({ isShownError: false })
                            }}
                        />
                    </div>
                    <div className={cx("footer")}>
                        <div className={cx("add-note")}>
                            <a
                                className={cx("button")}
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://docs.google.com/document/d/1ia43R3KZJA8H0TH3OsH6EhGl6vbtJOGRChXsMnR-rmw/edit?usp=sharing"
                            >
                                Add note
                            </a>
                            <span>Documentation in Google Docs</span>
                        </div>
                        <div className={cx("actions")}>
                            <span
                                className={cx("cancel")}
                                onClick={() => {
                                    handleShowScrollBar()
                                    handleHide()
                                    document.removeEventListener("keydown", this.handleKeyboardArrows, false)
                                }}
                            >
                                Cancel
                            </span>
                            <Button
                                className={["first-button"]}
                                label="Save"
                                isLoading={isSaving}
                                onClick={() => {
                                    handleSave(fileData.id)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withShowScrollBar(withStyles(ReviewModal, styles))
