import React, { Component } from "react"
import { compose } from "redux"

import { withStyles } from "ui/FilterableTable/HOC"
import styles from "./Footer.css"
import { AppContext } from "ui/FilterableTable"

const Left = withStyles(styles)(({ cx, children }) => <div className={cx("left")}>{children}</div>)

const Center = withStyles(styles)(({ cx, children }) => <div className={cx("center")}>{children}</div>)

const Right = withStyles(styles)(({ cx, children }) => <div className={cx("right")}>{children}</div>)

class FooterConsumer extends Component {
    render() {
        return <AppContext.Consumer>{context => <Footer {...this.props} context={context} />}</AppContext.Consumer>
    }
}

class Footer extends Component {
    render() {
        const { cx, children, render, context } = this.props
        const { fetchStatus, items, meta } = context

        if (!fetchStatus.isLoaded) {
            return null
        }

        if (!items.length) {
            return null
        }

        if (typeof render === "function") {
            return render(meta)
        }

        return <div className={cx("root")}>{children}</div>
    }
}

export { Left, Center, Right }

const enhancer = compose(withStyles(styles))

export default enhancer(FooterConsumer)
