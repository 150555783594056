import * as constants from "constants/index"

function colors(state = [], action) {
    switch (action.type) {
        case constants.CLEAR_AVAILABLE_FILTERS:
            return []
        case constants.GET_PRODUCTS_SUCCESS:
            if (action.payload.data && action.payload.data.colors) {
                return [...Object.keys(action.payload.data.colors).map(key => action.payload.data.colors[key])]
            }
            return []
        default:
            return state
    }
}

export default colors
