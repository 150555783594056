import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import { Link } from "react-router-dom"

import styles from "./LinkWithIcon.css"

class LinkWithIcon extends Component {
    render() {
        const { link, nativeLink, icon, children, cx, onClick, isLoading } = this.props

        if (nativeLink) {
            return (
                <a href={link} className={cx("root", { isLoading })}>
                    <img src={icon} className={cx("icon")} alt="icon" />
                    <span className={cx("label")}>{children}</span>
                </a>
            )
        }

        return (
            <Link to={link} onClick={onClick} className={cx("root", { isLoading })}>
                <img src={icon} className={cx("icon")} alt="icon" />
                <span className={cx("label")}>{children}</span>
            </Link>
        )
    }
}
export default withStyles(LinkWithIcon, styles)
