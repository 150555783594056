import React, { useState } from "react"
import SaveBar from "ui/SaveBar"
import ListingLayout from "../MainPageListingLayout"
import Header from "../../components/Header"
import Listing from "../../components/MainPageListings"
import Modal from "components/Modal/Modal"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"

import SectionNameEditModal from "../../components/MainPageNameSectionEditModal"
import AddRealizationsModal from "../../components/MainPageAddRealizationsModal"
import EditRealizationModal from "../../components/MainPageEditRealizationModal"

const MainPageRealizations = props => {
    const {
        mainPages: { data, isLoading },
        modifiedData,
        isModified,
        handleSubmit,
        handleUpdate,
        handleClearChanges,
        isSaving,
        getRealization,
        realization,
        handleRealizationUpdate,
        handleRealizationCreate,
        handleRealizationDelete,
        handleDeleteAllFromList,
        showErrorNotification
    } = props

    const [sectionNameEditModalState, setSectionNameEditModalState] = useState(false)
    const [addRealizationModalState, setAddRealizationModalState] = useState(false)
    const [editRealizationModalState, setEditRealizationModalState] = useState(false)
    const [editingRealization, setEditingRealization] = useState(null)
    const [isDeleteRealizationOpen, setIsDeleteRealizationOpen] = useState(null)
    const [isDeleteAllRealizationsOpen, setIsDeleteAllRealizationsOpen] = useState(false)
    const [deletingRealization, setDeletingRealization] = useState(null)

    const displayTitle = () =>
        (data.settings &&
            data.settings.sections &&
            data.settings.sections.realization &&
            data.settings.sections.realization.label) ||
        "Projects"

    const listMapping = {
        id: "id",
        source_id: "realization_id",
        name: "title",
        image: "cover_desktop",
        active: "active"
    }

    const handleReorder = realizations => {
        handleUpdate({ ...data, realizations })
    }

    const handleSectionNameEditClose = () => {
        setSectionNameEditModalState(false)
    }

    const handleSectionNameEditOpen = () => {
        setSectionNameEditModalState(true)
    }

    const handleSectionNameEdit = data => {
        handleSubmit(data, handleSectionNameEditClose)
    }

    const handleAddRealizationClose = () => {
        setAddRealizationModalState(false)
    }

    const handleAddRealizationOpen = () => {
        setAddRealizationModalState(true)
    }

    const handleAddRealization = data => {
        handleRealizationCreate(data, handleAddRealizationClose)
    }

    const handleEditRealizationClose = () => {
        setEditingRealization(null)
        setEditRealizationModalState(false)
    }

    const handleEditRealizationOpen = realizationId => {
        setEditingRealization(realizationId)
        setEditRealizationModalState(true)
    }

    const handleEditRealization = (id, data, onSuccess = null, onError) => {
        handleRealizationUpdate(id, data, handleEditRealizationClose, onError)
    }

    const handleDeleteRealizationClose = () => {
        setIsDeleteRealizationOpen(false)
        setDeletingRealization(null)
    }

    const handleDeleteRealizationOpen = realization => {
        setIsDeleteRealizationOpen(true)
        setDeletingRealization(realization)
    }

    const handleDeleteRealization = () => {
        handleRealizationDelete(deletingRealization.id, handleDeleteRealizationClose)
    }

    const handleDeleteAllRealizationsClose = () => {
        setIsDeleteAllRealizationsOpen(false)
    }

    const handleDeleteAllRealizationsOpen = () => {
        setIsDeleteAllRealizationsOpen(true)
    }

    const handleDeleteAllRealizations = () => {
        handleDeleteAllFromList(
            { settings: data.settings, header: data.header, realizations: [] },
            handleDeleteAllRealizationsClose
        )
    }

    return (
        <ListingLayout>
            <Header
                title={displayTitle()}
                editButton={{
                    label: "Edit",
                    handler: handleSectionNameEditOpen,
                    isDisabled: false,
                    order: 1
                }}
                addButton={{
                    label: "Add Realization",
                    handler: handleAddRealizationOpen,
                    isDisabled: false,
                    order: 2
                }}
            />

            <Listing
                isLoading={isLoading}
                handleDelete={handleDeleteRealizationOpen}
                handleDeleteAll={handleDeleteAllRealizationsOpen}
                handleEdit={id => handleEditRealizationOpen(id)}
                listingType="projects"
                handleReorder={handleReorder}
                list={modifiedData ? modifiedData.realizations : data.realizations || []}
                listMapping={listMapping}
            />

            <SectionNameEditModal
                data={modifiedData || data}
                sectionName="realization"
                defaultValues={{ label: "Realizations" }}
                handleClose={handleSectionNameEditClose}
                onSubmit={handleSectionNameEdit}
                isOpen={sectionNameEditModalState}
                isSaving={isSaving}
            />

            <AddRealizationsModal
                data={modifiedData || data}
                handleClose={handleAddRealizationClose}
                onSubmit={handleAddRealization}
                isOpen={addRealizationModalState}
                isSaving={isSaving}
            />

            {editRealizationModalState && (
                <EditRealizationModal
                    data={realization}
                    realizationId={editingRealization}
                    getRealization={getRealization}
                    handleClose={handleEditRealizationClose}
                    handleError={showErrorNotification}
                    onSubmit={handleEditRealization}
                    isOpen={true}
                    isSaving={isSaving}
                />
            )}

            {isDeleteRealizationOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete this realization?"
                        description="Are you sure you want to delete the realization?"
                        subject={deletingRealization.title}
                        descriptionClosure="This action cannot be reversed."
                        handleHideModal={handleDeleteRealizationClose}
                        handleDelete={handleDeleteRealization}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            {isDeleteAllRealizationsOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete all realizations?"
                        description="Are you sure you want to delete all of the realizations?"
                        descriptionClosure="This action cannot be reversed."
                        handleHideModal={handleDeleteAllRealizationsClose}
                        handleDelete={handleDeleteAllRealizations}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            <SaveBar
                message="Unpublished changes"
                submitLabel="Publish"
                isShown={isModified}
                isSaving={isSaving}
                onSubmit={handleSubmit}
                onCancel={handleClearChanges}
            />
        </ListingLayout>
    )
}

export default MainPageRealizations
