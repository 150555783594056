import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./ColorBox.css"

class ColorBox extends Component {
    render() {
        const { cx, checked, handleColor, id, color, texture } = this.props
        const style = texture ? { backgroundImage: `url(${texture})` } : { backgroundColor: color }

        return <div className={cx("color", { checked })} style={style} onClick={() => handleColor && handleColor(id)} />
    }
}

export default withStyles(ColorBox, styles)
