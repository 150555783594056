import React, { Component } from "react"
import { Input } from "ui"

class Contact extends Component {
    render() {
        const { className, errors, setFieldValue, values, handleEdit } = this.props
        const { email, alternative_email, facebook, other_social, phone, mobile, twitter, instagram, website } = values

        const setValue = (key, val) => {
            handleEdit(true)
            setFieldValue(key, val)
        }

        return (
            <section className={className}>
                <h2>Contact</h2>
                <ul>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={email}
                            name="email"
                            id="email"
                            label="E-mail address"
                            error={errors.email}
                            onChange={params => setValue("email", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={alternative_email}
                            name="alternative_email"
                            id="alternative_email"
                            label="Alternative e-mail"
                            error={errors.alternative_email}
                            onChange={params => setValue("alternative_email", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={phone}
                            name="phone"
                            id="phone"
                            label="Phone Number"
                            isPhoneInput={true}
                            error={errors.phone}
                            onChange={params => setValue("phone", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={mobile}
                            name="mobile"
                            id="mobile"
                            label="Mobile Phone"
                            isPhoneInput={true}
                            error={errors.mobile}
                            onChange={params => setValue("mobile", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={twitter}
                            name="twitter"
                            id="twitter"
                            label="Twitter"
                            onChange={params => setValue("twitter", params.event.target.value)}
                            innerLabel="@"
                            isInnerLabelLeft
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={instagram}
                            name="instagram"
                            id="instagram"
                            label="Instagram"
                            onChange={params => setValue("instagram", params.event.target.value)}
                            innerLabel="@"
                            isInnerLabelLeft
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={website}
                            name="website"
                            id="website"
                            label="Website"
                            error={errors.website}
                            onChange={params => setValue("website", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={facebook}
                            name="facebook"
                            id="facebook"
                            label="Facebook"
                            error={errors.facebook}
                            innerLabel="@"
                            isInnerLabelLeft
                            onChange={params => setValue("facebook", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={other_social}
                            name="other_social"
                            id="other_social"
                            label="Other social"
                            error={errors.other_social}
                            onChange={params => setValue("other_social", params.event.target.value)}
                        />
                    </li>
                </ul>
            </section>
        )
    }
}

export default Contact
