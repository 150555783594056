import React from "react"
import PropTypes from "prop-types"
import ReactSvg from "react-svg"

import { formatDate } from "helpers/date"

import Button from "ui/Button"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader/SnakeLoader"

import withStyles from "HOC/withStyles"
import styles from "./ListRow.css"

import clockIcon from "assets/clock.svg"

const ListRow = props => {
    const {
        cx,
        partner,
        activateAccount,
        verifyAcount,
        isActivating,
        isVerifying,
        actionDisabled,
        openNoteModal,
        handleEmailConfirmationResend,
        resendingEmailsIds
    } = props
    const { id, client_id, full_name, types, email, phone, existing, created_at, note } = partner

    const name = full_name || email || "-"
    const typeOfContact = types.reduce((acc, { name }) => (acc ? `${acc}, ${name}` : name), "")
    const statusLabel = existing ? "Existing" : "New"
    const formattedDate = formatDate(created_at, "MMM D, YYYY")

    return (
        <tr className={cx("root")}>
            <td className={cx("name")}>{name}</td>
            <td>{typeOfContact || "-"}</td>
            <td>{email || "-"} </td>
            <td className={cx("phone")}>{phone || ""}</td>
            <td className={cx("statusWrapper")}>
                <div className={cx("status", { existing })}>{statusLabel}</div>
            </td>
            <td>
                <span onClick={openNoteModal} className={cx("note", { hasNote: !!note })}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            className={cx("withFill")}
                            fillRule="nonzero"
                            d="M16.207 9.207V7.793H7.793v1.414h8.414zm0 2.103V9.897H7.793v1.413h8.414zm0 2.103V12H7.793v1.413h8.414zM17.62 5c.382 0 .707.135.976.404.27.27.404.594.404.976V19l-2.793-2.793H6.38c-.382 0-.707-.14-.976-.42A1.386 1.386 0 0 1 5 14.792V6.38c0-.382.135-.707.404-.976.27-.27.594-.404.976-.404h11.24z"
                        />
                    </svg>
                </span>
            </td>
            <td>
                <div className={cx("createdAt")}>
                    <ReactSvg src={clockIcon} />
                    {formattedDate}
                </div>
            </td>
            <td className={cx("buttonTd")}>
                {partner.verified ? (
                    <Button
                        className={cx("button", "first-button")}
                        type="button"
                        label="Verify account"
                        isLoading={isVerifying}
                        isDisabled={actionDisabled}
                        onClick={() => verifyAcount(id, client_id)}
                    />
                ) : (
                    email && (
                        <div className={cx("actionButtonsWrapper")}>
                            <button className={cx("resendMailButton")}>
                                {!resendingEmailsIds.includes(partner.id) ? (
                                    <span className={cx("link")} onClick={() => handleEmailConfirmationResend(id)}>
                                        Resend confirmation
                                    </span>
                                ) : (
                                    <SnakeLoader type="small" />
                                )}
                            </button>
                            <Button
                                className={cx("button", "first-button")}
                                type="button"
                                label="Activate email"
                                isLoading={isActivating}
                                isDisabled={actionDisabled}
                                onClick={() => activateAccount(id)}
                            />
                        </div>
                    )
                )}
            </td>
        </tr>
    )
}

ListRow.propTypes = {
    partner: PropTypes.shape({
        id: PropTypes.number.isRequired,
        client_id: PropTypes.number,
        full_name: PropTypes.string,
        note: PropTypes.string,
        types: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string
            })
        ).isRequired,
        email: PropTypes.string,
        phone: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        existing: PropTypes.bool.isRequired,
        created_at: PropTypes.string
    }).isRequired,
    verifyAcount: PropTypes.func.isRequired,
    activateAccount: PropTypes.func.isRequired,
    openNoteModal: PropTypes.func.isRequired,
    handleEmailConfirmationResend: PropTypes.func.isRequired,
    resendingEmailsIds: PropTypes.array.isRequired,
    isVerifying: PropTypes.bool,
    isActivating: PropTypes.bool,
    actionDisabled: PropTypes.bool
}

export default withStyles(ListRow, styles)
