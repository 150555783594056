import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"

import { useActions } from "hooks"
import { updateDocumentTemplate } from "actions/docs-templates"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import textareaStyles from "./overrides/Textarea.css"
import submitButtonStyles from "./overrides/SubmitButton.css"
import styles from "./EditDocsTemplate.module.css"
import withStyles from "HOC/withStyles"

import { Button, Textarea, Modal } from "ui"

const schema = Yup.object().shape({
    terms: Yup.string().required("This field is required.")
})

const EditDocsTemplate = ({ cx, handleClose, docsTemplateToEdit: { id, name, terms } }) => {
    const actions = useActions({ showErrorNotification, updateDocumentTemplate, showSuccessNotification })

    const handleSubmit = async (values, { setErrors, setSubmitting }) => {
        const { terms } = values

        const data = { terms }

        setSubmitting(true)

        try {
            await actions.updateDocumentTemplate(id, data)
            actions.showSuccessNotification()
            handleClose()
        } catch (data) {
            if (data) {
                const { errors: dataErrors, message } = data
                const errors = dataErrors || (message ? { message } : null)

                errors && setErrors(errors)
            }
            actions.showErrorNotification()
            setSubmitting(false)
        }
    }

    const renderBody = () => {
        return (
            <Formik initialValues={{ terms: terms }} onSubmit={handleSubmit} validationSchema={schema}>
                {({ errors, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
                    return (
                        <form className={cx("form")} onSubmit={handleSubmit}>
                            <div className={cx("row", "type-container")}>
                                <label className={cx("label")}>Docs type:</label>
                                <select className={cx("type")} disabled>
                                    <option>{name}</option>
                                </select>
                            </div>
                            <div className={cx("row")}>
                                <Textarea
                                    value={values.terms}
                                    name="terms"
                                    label="Terms"
                                    onChange={value => {
                                        setFieldValue("terms", value.event.target.value)
                                    }}
                                    error={touched.terms && errors.terms}
                                    customStyles={textareaStyles}
                                />
                            </div>
                            <div className={cx("buttons")}>
                                <span className={cx("form-cancel")} onClick={handleClose}>
                                    Cancel
                                </span>
                                <Button
                                    type="submit"
                                    className={"first-button"}
                                    customStyles={submitButtonStyles}
                                    label="Save"
                                    isDisabled={isSubmitting}
                                    isLoading={isSubmitting}
                                />
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }

    return (
        <Modal isOpen={true} closeModal={handleClose} preventClickOutside={true}>
            <Modal.Header>Edit document template</Modal.Header>
            <Modal.Body>{renderBody()}</Modal.Body>
        </Modal>
    )
}

EditDocsTemplate.propTypes = {
    docsTemplateToEdit: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        terms: PropTypes.string
    }).isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withStyles(EditDocsTemplate, styles)
