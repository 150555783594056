import fetch from "helpers/fetch"
import { serializeQuery } from "helpers/urls"
import useCrudFactory from "../../../WmsModule/hooks/factories/useCrudFactory"

export const KEY = "store-credits"

const defaultListParams = {
    page: 1,
    length: 9999,
    sort_by: "id",
    sort_direction: "asc",
    query: "",
    order_id: undefined,
    depositor_id: undefined,
    payment_method_id: undefined,
    date_from: undefined,
    date_to: undefined,
    created_from: undefined,
    created_to: undefined,
    amount_min: undefined,
    amount_max: undefined
}

const fetchStoreCreditList = params => {
    const queryString = serializeQuery(params, "", true)

    return fetch.get(`/store-credits?${queryString}`)
}
const fetchStoreCreditDetails = id => fetch.get(`/store-credits/${id}`)
const fetchStoreCreditCreate = ({ data }) => fetch.post("/store-credits", data)
const fetchStoreCreditEdit = ({ id, data }) => fetch.patch(`/store-credits/${id}`, data)
const fetchStoreCreditDelete = ({ id }) => fetch.deleteRAW(`/store-credits/${id}`)

const fetchFunctions = {
    list: fetchStoreCreditList,
    details: fetchStoreCreditDetails,
    create: fetchStoreCreditCreate,
    edit: fetchStoreCreditEdit,
    delete: fetchStoreCreditDelete
}

const storeCreditCrud = useCrudFactory({ key: KEY, fetchFunctions, defaultListParams })

export const useStoreCreditList = storeCreditCrud.list
export const useStoreCreditDetails = storeCreditCrud.details
export const useStoreCreditCreate = storeCreditCrud.create
export const useStoreCreditEdit = storeCreditCrud.edit
export const useStoreCreditDelete = storeCreditCrud.delete
