import SHIPMENT_STATUS_KEYS from "./shipmentStatusKeys"
import STATUS_COLORS from "./statusColors"

const SHIPMENT_STATUSES = {
    [SHIPMENT_STATUS_KEYS.PACKED]: {
        id: SHIPMENT_STATUS_KEYS.PACKED,
        label: "Packed",
        color: STATUS_COLORS.ORANGE,
        value: SHIPMENT_STATUS_KEYS.PACKED,
        theme: "outline"
    },
    [SHIPMENT_STATUS_KEYS.SENT]: {
        id: SHIPMENT_STATUS_KEYS.SENT,
        label: "Sent",
        color: STATUS_COLORS.ORANGE,
        value: SHIPMENT_STATUS_KEYS.SENT,
        theme: "outline"
    },
    [SHIPMENT_STATUS_KEYS.ON_HOLD]: {
        id: SHIPMENT_STATUS_KEYS.ON_HOLD,
        label: "On hold",
        color: STATUS_COLORS.ORANGE,
        value: SHIPMENT_STATUS_KEYS.ON_HOLD,
        theme: "outline"
    },
    [SHIPMENT_STATUS_KEYS.IN_PROGRESS]: {
        id: SHIPMENT_STATUS_KEYS.IN_PROGRESS,
        label: "In progress",
        color: STATUS_COLORS.ORANGE,
        value: SHIPMENT_STATUS_KEYS.IN_PROGRESS,
        theme: "outline"
    },
    [SHIPMENT_STATUS_KEYS.DELIVERED]: {
        id: SHIPMENT_STATUS_KEYS.DELIVERED,
        label: "Delivered",
        color: STATUS_COLORS.GREEN,
        value: SHIPMENT_STATUS_KEYS.DELIVERED,
        theme: "fill"
    },
    [SHIPMENT_STATUS_KEYS.CANCELLED]: {
        id: SHIPMENT_STATUS_KEYS.CANCELLED,
        label: "Cancelled",
        color: STATUS_COLORS.RED,
        value: SHIPMENT_STATUS_KEYS.CANCELLED,
        theme: "fill",
        type: "danger"
    }
}

export default SHIPMENT_STATUSES
