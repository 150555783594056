import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useDispatch } from "react-redux"

import { useStockItemTransfer } from "modules/WmsModule/hooks/api/useStockItem"
import { requiredSelectOptionFactory } from "modules/WmsModule/helpers/schema"
import * as notificationActions from "actions/notification"

import selectedItemsPropType from "./selectedItemsPropType"
import useStockTransferItemsApiErrors from "./hooks/useStockTransferItemsApiErrors"
import StockTransfer from "./StockTransfer"

const StockTransferContainer = ({ isOpen, selectedItems, onRemoveItem, onClose, onSuccess }) => {
    const dispatch = useDispatch()
    const stockItemTransfer = useStockItemTransfer()
    const {
        apiStockItemsErrors,
        addApiStockItemsErrors,
        clearApiStockItemsErrors,
        clearAlreadyInTargetWarehouseApiErrors
    } = useStockTransferItemsApiErrors()

    const formik = useFormik({
        initialValues: {
            shippingTo: null,
            ids: []
        },
        validationSchema: Yup.object().shape({
            shippingTo: requiredSelectOptionFactory(Yup.number())
        }),
        onSubmit: ({ ids, shippingTo }, { setFieldError }) =>
            stockItemTransfer
                .mutate({ ids, warehouse_id: shippingTo.value })
                .then(() => {
                    onSuccess()
                    clearApiStockItemsErrors()
                    dispatch(
                        notificationActions.showSuccessNotification({ title: "Stock transfer added successfully" })
                    )
                })
                .catch(error => {
                    addApiStockItemsErrors(error.errors, ids)
                    if (error.errors.warehouse_id) {
                        setFieldError("shippingTo", error.errors.warehouse_id)
                    }
                    dispatch(notificationActions.showErrorNotification())
                })
    })

    useEffect(() => {
        if (!isOpen) {
            clearApiStockItemsErrors()
        }
    }, [isOpen])

    if (isOpen) {
        return (
            <StockTransfer
                onClose={onClose}
                onSuccess={onSuccess}
                onRemoveItem={onRemoveItem}
                selectedItems={selectedItems}
                formik={formik}
                apiStockItemsErrors={apiStockItemsErrors}
                clearAlreadyInTargetWarehouseApiErrors={clearAlreadyInTargetWarehouseApiErrors}
            />
        )
    }

    return null
}

StockTransferContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onRemoveItem: PropTypes.func.isRequired,
    selectedItems: selectedItemsPropType
}

export default StockTransferContainer
