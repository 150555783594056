import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import {
    Table,
    TableHead,
    TableBody,
    TableHeadCell,
    TableCell,
    TableDateCell,
    TableRow,
    EmptyInternalList,
    ListIcon
} from "@butterfly-frontend/ui"
import { formatDate } from "helpers/date"

import styles from "./StockItemDetailsStockAdjustments.module.css"

const StockItemDetailsStockAdjustments = props => {
    const { stockAdjustmentsData } = props

    return (
        <div className={styles.root}>
            <h2 className={styles.title}>Stock adjustments</h2>
            {stockAdjustmentsData.length ? (
                <Table>
                    <TableHead>
                        <TableHeadCell minWidth={100} width="35%">
                            ID
                        </TableHeadCell>
                        <TableHeadCell minWidth={100} width="20%">
                            WAREHOUSE
                        </TableHeadCell>
                        <TableHeadCell minWidth={200} width="20%">
                            REASON
                        </TableHeadCell>
                        <TableHeadCell minWidth={200} width="15%">
                            DATE
                        </TableHeadCell>
                        <TableHeadCell minWidth={200} width="15%">
                            BY
                        </TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {stockAdjustmentsData.map(stockAdjustment => (
                            <TableRow key={stockAdjustment.id}>
                                <TableCell minWidth={100} width="35%">
                                    <Link
                                        to={`/wms/stock-adjustments?id=${stockAdjustment.id}`}
                                        className={styles.link}
                                    >
                                        {stockAdjustment.display_id}
                                    </Link>
                                </TableCell>
                                <TableCell minWidth={100} width="20%">
                                    {stockAdjustment.warehouse || "N/A"}
                                </TableCell>
                                <TableCell minWidth={200} width="20%">
                                    {stockAdjustment.reason ? stockAdjustment.reason.reason : "N/A"}
                                </TableCell>
                                <TableDateCell minWidth={200} width="15%">
                                    {formatDate(stockAdjustment.created_at)}
                                </TableDateCell>
                                <TableCell minWidth={200} width="15%">
                                    {stockAdjustment.created_by ? stockAdjustment.created_by.full_name : "N/A"}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyInternalList text="It has no Stock Adjustments" Icon={ListIcon} />
            )}
        </div>
    )
}

StockItemDetailsStockAdjustments.propTypes = {
    stockAdjustmentsData: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            display_id: PropTypes.string.isRequired,
            warehouse: PropTypes.string,
            reason: PropTypes.shape({
                type: PropTypes.string.isRequired,
                reason: PropTypes.string.isRequired
            }),
            created_at: PropTypes.string.isRequired
        })
    ).isRequired
}

export default StockItemDetailsStockAdjustments
