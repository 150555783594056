import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"

import { H1 } from "@butterfly-frontend/ui"
import { DateRangePicker } from "ui"
import ListFiltersContext from "modules/DashboardModule/contexts/ListFiltersContext"
import { Header as TitleHeader } from "modules/WmsModule/components"
import dateRangePickerStyles from "modules/DashboardModule/components/Header/overrides/dateRangePicker.module.css"
import calendarIcon from "assets/calendar-blue.svg"

const Header = ({ title }) => {
    const {
        set: setFiltersContext,
        values: { dashboard: filtersFromContext }
    } = useContext(ListFiltersContext)

    const [selectedRange, setSelectedRange] = useState({
        startDate: filtersFromContext.date.startDate,
        endDate: filtersFromContext.date.endDate
    })

    useEffect(() => {
        setFiltersContext(prevState => ({
            ...prevState,
            dashboard: {
                date: selectedRange
            }
        }))
    }, [selectedRange])

    return (
        <TitleHeader>
            <H1 withLeftBorder>{title}</H1>
            <DateRangePicker
                ranges={selectedRange}
                onChange={({ range1 }) => setSelectedRange(range1)}
                customStyles={dateRangePickerStyles}
                resetRanges={() => setSelectedRange({ startDate: "", endDate: "" })}
                icon={calendarIcon}
            />
        </TitleHeader>
    )
}

Header.propTypes = {
    title: PropTypes.string.isRequired
}

export default Header
