import { useState, useRef, useEffect, useCallback } from "react"

const useBarcodeReader = ({ isInitiallyEnabled = false } = {}) => {
    const [isBarcodeReaderEnabled, setIsBarcodeReaderEnabled] = useState(isInitiallyEnabled)
    const barcodeInputRef = useRef("")

    const toggleBarcodeReaderEnable = useCallback(
        isBarcodeReaderEnabled => {
            if (isBarcodeReaderEnabled) {
                barcodeInputRef.current.focus()
            }
            setIsBarcodeReaderEnabled(isBarcodeReaderEnabled)
        },
        [barcodeInputRef, setIsBarcodeReaderEnabled]
    )

    const setBarcodeInputRef = useCallback(node => (barcodeInputRef.current = node), [barcodeInputRef])
    const clearBarcodeInput = useCallback(() => (barcodeInputRef.current.value = ""), [barcodeInputRef])

    useEffect(() => {
        if (isBarcodeReaderEnabled) {
            barcodeInputRef.current.focus()
        }
    }, [isBarcodeReaderEnabled])

    return {
        isBarcodeReaderEnabled,
        toggleBarcodeReaderEnable,
        barcodeInputRef,
        setBarcodeInputRef,
        clearBarcodeInput,
        enableBarcodeInputToggleProps: {
            isOn: isBarcodeReaderEnabled,
            handleChange: toggleBarcodeReaderEnable
        },
        barcodeInputProps: {
            isDisabled: !isBarcodeReaderEnabled,
            handleInputRefOnInit: setBarcodeInputRef
        }
    }
}

export default useBarcodeReader
