import React, { useState } from "react"
import idx from "idx"

import useStyles from "helpers/getCxFromStyles"

import { Button } from "ui"
import ManageModal from "./ManageModal"

import styles from "./BindedProducts.css"

const BindedProducts = ({ product, fetchProduct }) => {
    const cx = useStyles(styles)
    const bindedProducts = idx(product, _ => _.group.products) || []
    const [isManageModal, setIsManageModal] = useState(false)

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <span className={cx("title")}>
                    <b>Typology</b>
                    <span className={cx("counter")}>{bindedProducts.length}</span>
                </span>

                <Button className={cx("first-button", "white")} label="Manage" onClick={() => setIsManageModal(true)} />
            </div>

            {bindedProducts.length > 0 && (
                <div className={cx("list")}>
                    {bindedProducts.map(item => (
                        <div className={cx("product")} key={item.name}>
                            <div className={cx("image-wrapper")}>
                                <img src={item.media} alt="product" />
                            </div>
                            <p className={cx("title", { isTooltip: item.name ? item.name.length > 20 : false })}>
                                <span className={cx("name")}>{item.name}</span>
                                <span className={cx("tooltip")}>{item.name}</span>
                            </p>
                        </div>
                    ))}
                </div>
            )}

            {isManageModal && (
                <ManageModal onClose={() => setIsManageModal(false)} product={product} onSuccess={fetchProduct} />
            )}
        </div>
    )
}

export default BindedProducts
