import React, { useState, useRef, useEffect } from "react"
import withStyles from "HOC/withStyles"
import { Link } from "react-router-dom"

import styles from "../CalendarList.css"
import PropTypes from "prop-types"

import { useOutsideClick } from "hooks"

const CalendarSubmenu = ({ cx, menuLinks, id }) => {
    const ref = useRef()
    const windowHeight = window.innerHeight

    const [offsetTop, setOffsetTop] = useState(0)
    const [height, setHeight] = useState(windowHeight)
    const [open, setOpen] = useState(false)

    const isOpen = open ? "open" : ""
    const posDropdown = offsetTop + 170 > height ? "top" : "bottom"
    const handleResize = () => setHeight(window.innerHeight)
    const handleOffset = () => {
        const { current } = ref
        const { top } = current.getBoundingClientRect()
        setOffsetTop(top)
    }

    const toggle = () => {
        setOpen(prev => !prev)
        handleResize()
        handleOffset()
    }

    useEffect(() => {
        window.addEventListener("scroll", handleOffset)
        return () => {
            window.removeEventListener("scroll", handleOffset)
        }
    }, [ref])

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    useOutsideClick(ref, () => {
        if (open) setOpen(false)
    })

    const menuLink = (menuLinks || [])
        .filter(link => !link.hidden)
        .map((link, key) => (
            <li
                key={`${id}-item_${key}`}
                className={cx(link.name === "Delete" ? "delete" : "", link.disabled === true ? "disabled-element" : "")}
                onClick={toggle}
            >
                {link.href && <Link to={link.href}>{link.name}</Link>}

                {link.onClick && <a onClick={link.onClick}>{link.name}</a>}
            </li>
        ))

    return (
        <div ref={ref} className={cx("submenu", isOpen, posDropdown)}>
            <span className={cx("dots")} onClick={toggle}>
                <span />
            </span>
            {open && <ul className={cx("submenu--dropdown")}>{menuLink}</ul>}
        </div>
    )
}

CalendarSubmenu.propTypes = {
    id: PropTypes.string,
    menuLinks: PropTypes.array
}

export default withStyles(CalendarSubmenu, styles)
