import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import _pick from "lodash/pick"
import moment from "moment"

import { Modal, Button } from "@butterfly-frontend/ui"
import { DatePickerWithInput, Input } from "ui"
import { SelectPaymentMethod } from "ui/Filters"
import { API_DATE_FORMAT } from "constants/index"
import { useActions } from "hooks"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import { usePaymentEdit } from "modules/DashboardModule/hooks/api/usePayments"
import getInputPropsFactory from "modules/WmsModule/helpers/getInputPropsFactory"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import { useStoreCreditCreate, useStoreCreditEdit } from "modules/ClientModule/hooks/api"

import validationSchema from "./validationSchema"
import styles from "./AddEditStoreCreditModal.module.css"
import inputStyles from "./overrides/inputStyles.module.css"
import selectStyles from "./overrides/selectStyles.module.css"
import customErrorStyles from "./overrides/customError.module.css"

const INITIAL_VALUES = {
    amount: "",
    payment_method: {},
    date: moment(new Date()).format(API_DATE_FORMAT),
    note: ""
}

const AddEditStoreCreditModal = ({ clientId, payment, handleClose }) => {
    const isNew = useMemo(() => !payment, [payment])
    const editPayment = usePaymentEdit()
    const editStoreCredit = useStoreCreditEdit()
    const create = useStoreCreditCreate()
    const actions = useActions({ showErrorNotification, showSuccessNotification })
    const formik = useFormik({
        initialValues: payment || INITIAL_VALUES,
        validationSchema,
        onSubmit: values => {
            const submitValues = {
                ..._pick(values, ["amount", "note", "date"]),
                depositor_id: clientId,
                payment_method_id: values.payment_method.id
            }
            const mutationFunc = isNew ? create : payment.store_credit_payment ? editPayment : editStoreCredit
            const mutationFuncParams = isNew ? { data: submitValues } : { id: payment.id, data: submitValues }
            return mutationFunc
                .mutate(mutationFuncParams)
                .then(() => {
                    handleClose()
                    actions.showSuccessNotification()
                })
                .catch(actions.showErrorNotification)
        }
    })

    const getInputProps = getInputPropsFactory(formik)
    const getSelectProps = getSelectPropsFactory(formik)

    return (
        <Modal title={`${isNew ? "New" : "Edit"} Payment`} onClose={handleClose}>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <div className={styles.row}>
                    <Input
                        {...getInputProps("amount")}
                        label="USD"
                        customStyles={inputStyles}
                        skipLabelColon
                        isDisabled={formik.isSubmitting}
                    />
                    <DatePickerWithInput
                        label="Payment date:"
                        customStyles={selectStyles}
                        disableClear
                        handleSelectDate={value => formik.setFieldValue("date", value)}
                        date={formik.values.date}
                        {...getSelectProps("date")}
                    />
                </div>
                <div className={styles.row}>
                    <SelectPaymentMethod
                        label="Payment method"
                        customErrorStyles={customErrorStyles}
                        customStyles={selectStyles}
                        handleSelect={value => formik.setFieldValue("payment_method", value)}
                        filterStoreCredit
                        {...getSelectProps("payment_method")}
                    />
                    <Input
                        {...getInputProps("note")}
                        label="Staff note"
                        customStyles={inputStyles}
                        isDisabled={formik.isSubmitting}
                        customPlaceholder="Click here to add a note..."
                    />
                </div>
                <div className={styles.buttonSection}>
                    <Button variant="flat" onClick={handleClose} disabled={formik.isSubmitting}>
                        Cancel
                    </Button>
                    <Button type="submit" color="blue" isLoading={formik.isSubmitting}>
                        Save
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

AddEditStoreCreditModal.propTypes = {
    clientId: PropTypes.number.isRequired,
    payment: PropTypes.shape({
        id: PropTypes.number.isRequired,
        amount: PropTypes.number.isRequired,
        payment_method: PropTypes.shape({
            id: PropTypes.number.isRequired
        }).isRequired,
        note: PropTypes.string
    }),
    handleClose: PropTypes.func.isRequired
}

export default AddEditStoreCreditModal
