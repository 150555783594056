import React, { useState, useMemo } from "react"

import ReactSvg from "react-svg"

import { formatDimension } from "helpers/units"
import { formatDate } from "helpers/date"

import withStyles from "HOC/withStyles"
import styles from "./details.css"

import dimensionIcon from "assets/dimension-length.svg"

const dimensionsKeys = ["width", "height", "depth"]

const Details = props => {
    const { cx, vendor, categories, name, dimensions, addedByFullName, created_at } = props

    const [areImperialUnits, setAreImperialUnits] = useState(false)

    const toggleUnits = () => setAreImperialUnits(wasImperialUnits => !wasImperialUnits)

    const formattedDimensions = useMemo(
        () =>
            Object.entries(dimensions).reduce(
                (acc, [key, value]) => ({ ...acc, [key]: formatDimension(value, areImperialUnits) }),
                {}
            ),
        [dimensions, areImperialUnits]
    )

    return (
        <div className={cx("root")}>
            <div className={cx("row", "top")}>
                <div>
                    <label>Vendor:</label>
                    <p className={cx("value")}>{vendor}</p>
                </div>
                <div>
                    <label>Category:</label>
                    <p className={cx("value")}>
                        {categories.map((cat, index, arr) => (index === arr.length - 1 ? cat : `${cat}, `))}
                    </p>
                </div>
                <div>
                    <label>Original product name:</label>
                    <p className={cx("value")}>{name}</p>
                </div>
                <div>
                    <label>
                        Dimension:
                        <button className={cx("toggleUnits")} onClick={toggleUnits}>
                            {areImperialUnits ? "Metric" : "Imperial"} units
                        </button>
                    </label>
                    <div className={cx("value", "dimensions")}>
                        {dimensionsKeys.map(key => (
                            <span className={cx("dimension")} key={key}>
                                <ReactSvg src={dimensionIcon} className={cx(key)} />
                                {formattedDimensions[key]}
                            </span>
                        ))}
                    </div>
                </div>
            </div>
            <div className={cx("row", "bottom")}>
                <div>
                    <label>Product added by:</label> {addedByFullName}
                </div>
                <div>
                    <label>Date added:</label>
                    {formatDate(created_at)}
                </div>
            </div>
        </div>
    )
}

export default withStyles(Details, styles)
