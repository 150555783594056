import { combineReducers } from "redux"

import colors from "./colors"
import brands from "./brands"
import chosenLeafs from "./chosenLeafs"
import categoryRoute from "./categoryRoute"
import category from "./category"
import list from "./list"

const productsReducer = combineReducers({
    colors,
    brands,
    chosenLeafs,
    categoryRoute,
    category,
    list
})

export default productsReducer
