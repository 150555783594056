import React, { useCallback, useMemo } from "react"
import { useQueryClient } from "react-query"
import PropTypes from "prop-types"
import { SnakeLoader } from "@butterfly-frontend/ui"

import { useShipmentList } from "modules/WmsModule/hooks/api/useShipment"
import { useStockItemList } from "modules/WmsModule/hooks/api/useStockItem"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import { checkIfCanCreateOrderShipment } from "modules/OrdersModule/helpers/orderShipment"

import { Header, List } from "./components"
import styles from "./Shipments.module.css"

const Shipments = props => {
    const { order } = props

    const queryClient = useQueryClient()
    const shipmentList = useShipmentList({
        params: {
            orders: order.id,
            type: SHIPMENT_TYPE_KEYS.SHIPMENT,
            length: 9999
        }
    })
    const stockItemList = useStockItemList({ params: { order_id: order.id, invoiced: true } })

    const changeShipmentStatusCacheOnList = useCallback(
        (stockTransferId, updatedShipment) => {
            queryClient.setQueryData(shipmentList.queryKey, prevState => ({
                ...prevState,
                data: prevState.data.map(item =>
                    item.id === stockTransferId
                        ? {
                              ...item,
                              status: updatedShipment.status,
                              processing_states: updatedShipment.processing_states
                          }
                        : item
                )
            }))
        },
        [queryClient, shipmentList.queryKey]
    )

    const canCreateShipment = useMemo(
        () =>
            shipmentList.fetchStatus.isLoaded &&
            stockItemList.fetchStatus.isLoaded &&
            checkIfCanCreateOrderShipment({ orderStatus: order.status }) &&
            stockItemList.data.filter(stockItem => !stockItem.is_in_client_shipment).length > 0,
        [shipmentList.fetchStatus, stockItemList.fetchStatus, stockItemList.data, order.status]
    )

    return (
        <section className={styles.root}>
            <Header
                orderId={order.id}
                numberOfItems={shipmentList.data.length}
                isLoaded={shipmentList.fetchStatus.isLoaded && stockItemList.fetchStatus.isLoaded}
                canCreateShipment={canCreateShipment}
            />
            {shipmentList.fetchStatus.isLoading ? (
                <SnakeLoader />
            ) : (
                shipmentList.fetchStatus.isLoaded &&
                shipmentList.data.length > 0 && (
                    <List onStatusChanged={changeShipmentStatusCacheOnList} data={shipmentList.data} />
                )
            )}
        </section>
    )
}

Shipments.propTypes = {
    order: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.string.isRequired
    })
}

export default Shipments
