import PropTypes from "prop-types"

const BASE_ATTRIBUTE_VALUE_PROP_TYPE = {
    id: PropTypes.number.isRequired,
    color: PropTypes.string,
    texture: PropTypes.string,
    value: PropTypes.string.isRequired
}

const COMBINATION_PROP_TYPE = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    attribute_values: PropTypes.arrayOf(
        PropTypes.shape({
            ...BASE_ATTRIBUTE_VALUE_PROP_TYPE,
            attribute_name: PropTypes.string.isRequired
        }).isRequired
    )
})

export const AVAILABLE_ATTRIBUTES_VALUE_PROP_TYPE = PropTypes.shape(BASE_ATTRIBUTE_VALUE_PROP_TYPE)

export const AVAILABLE_ATTRIBUTES_VALUES_PROP_TYPE = PropTypes.arrayOf(AVAILABLE_ATTRIBUTES_VALUE_PROP_TYPE)

export const COMBINATION_ATTRIBUTE_PROP_TYPE = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    attribute_values: AVAILABLE_ATTRIBUTES_VALUES_PROP_TYPE
})

export const COMBINATION_ATTRIBUTES_PROP_TYPE = PropTypes.arrayOf(COMBINATION_ATTRIBUTE_PROP_TYPE.isRequired)

export const COMBINATION_PATTERN_PROP_TYPE = PropTypes.shape({
    id: PropTypes.number.isRequired,
    attribute_value_id: PropTypes.number.isRequired,
    attribute_value: PropTypes.string.isRequired,
    level: PropTypes.number.isRequired,
    payload: PropTypes.shape({
        active: PropTypes.bool.isRequired,
        impact: PropTypes.string.isRequired,
        default: PropTypes.bool.isRequired,
        media_id: PropTypes.string,
        sku: PropTypes.string.isRequired
    }).isRequired
})

export const COMBINATION_PATTERNS_PROP_TYPE = PropTypes.arrayOf(COMBINATION_PATTERN_PROP_TYPE.isRequired)

export default COMBINATION_PROP_TYPE
