import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { useField } from "formik"
import { Decimal } from "decimal.js"

import * as clientActions from "actions/client"
import { DatePickerWithInput, Textarea, Attachments } from "ui"
import { Section } from "ui/AddEditForm"
import PersonSelect from "modules/OrdersModule/components/PersonSelect"
import AddClientModal from "./AddClientModal"

import { roundNumber } from "helpers/units"

import withStyles from "HOC/withStyles"
import styles from "./GeneralInfo.css"
import clientSelectStyles from "./overrides/clientSelect.css"
import datePickerStyles from "./overrides/datePickerStyles.css"
import deliveryDatePickerStyles from "./overrides/deliveryDatePickerStyles.css"
import textareaStyles from "./overrides/textareaStyles.css"

const GeneralInfo = props => {
    const { cx, formik, formConfig } = props
    const { name, dateLabel, hasRevisedDate } = formConfig
    const dispatch = useDispatch()

    const [isUploadingAttachments, setIsUploadingAttachments] = useState(false)
    const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false)

    const [issuedAt, issuedAtMeta] = useField("issued_at")
    const [revisedAt, revisedAtMeta] = useField("revised_at")
    const [validDate, validDateMeta] = useField("valid_date")
    const [deliveryDate, deliveryDateMeta] = useField("delivery_date")
    const [description, descriptionMeta] = useField("description")
    const [instructions, instructionsMeta] = useField("instructions")
    const [attachments] = useField("attachments")

    const handleUploadAttachments = async newAttachments => {
        await setIsUploadingAttachments(true)
        attachments.onChange({ target: { ...attachments, value: [...attachments.value, ...newAttachments] } })
        setIsUploadingAttachments(false)
    }

    const handleDeleteAttachments = attachmentId => {
        attachments.onChange({
            target: { ...attachments, value: attachments.value.filter(({ id }) => id !== attachmentId) }
        })
    }

    const handleSetClient = ({ value }) => {
        if (!!value) {
            dispatch(clientActions.getClientById(value))
                .then(({ data: { id, full_name, tax_code } }) => {
                    formik.setFieldValue("client_id", { id, value: id, label: full_name })
                    formik.setFieldValue(
                        "tax_code",
                        tax_code !== null ? roundNumber(new Decimal(tax_code).times(100), 6) : null
                    )
                })
                .catch(() => formik.setFieldValue("tax_code", null))
        } else {
            formik.setFieldValue("tax_code", null)
        }
    }

    const openAddClientModal = () => setIsAddClientModalOpen(true)
    const closeAddClientModal = () => setIsAddClientModalOpen(false)

    return (
        <div className={cx("root")}>
            <div className={cx("content")}>
                <Section label={`${name} Details`}>
                    <div className={cx("orderDetailsWrapper")}>
                        <PersonSelect
                            withPagination
                            isRequired
                            name="client_id"
                            label="Client"
                            fetchPath="/clients"
                            handleSetValue={handleSetClient}
                            additionalButtonLabel="Create new client"
                            additionalButtonAction={openAddClientModal}
                            className={cx("clientSelect")}
                            customStyles={clientSelectStyles}
                        />
                        <DatePickerWithInput
                            label={`${dateLabel || name} date`}
                            date={issuedAt.value}
                            handleSelectDate={value => issuedAt.onChange({ target: { ...issuedAt, value } })}
                            error={issuedAtMeta.error}
                            customStyles={datePickerStyles}
                        />
                        {hasRevisedDate && (
                            <DatePickerWithInput
                                label="Revised date"
                                date={revisedAt.value}
                                handleSelectDate={value => revisedAt.onChange({ target: { ...revisedAt, value } })}
                                error={revisedAtMeta.error}
                                customStyles={datePickerStyles}
                            />
                        )}
                        <DatePickerWithInput
                            label="Valid date"
                            date={validDate.value}
                            handleSelectDate={value => validDate.onChange({ target: { ...validDate, value } })}
                            error={validDateMeta.error}
                            customStyles={datePickerStyles}
                        />
                    </div>
                    <div className={cx("orderDetailsWrapper")}>
                        <DatePickerWithInput
                            label="Delivery date"
                            date={deliveryDate.value}
                            handleSelectDate={value => deliveryDate.onChange({ target: { ...deliveryDate, value } })}
                            error={deliveryDateMeta.error}
                            customStyles={deliveryDatePickerStyles}
                        />
                    </div>
                </Section>
                <Section label="Description">
                    <Textarea
                        {...description}
                        onChange={({ event }) => description.onChange(event)}
                        error={descriptionMeta.error}
                        customStyles={textareaStyles}
                        placeholder="Click here to add a description..."
                    />
                </Section>
                <Section label="Special instructions">
                    <Textarea
                        {...instructions}
                        onChange={({ event }) => instructions.onChange(event)}
                        error={instructionsMeta.error}
                        customStyles={textareaStyles}
                        placeholder="Click here to add a special instructions..."
                    />
                </Section>
                <Section label="Attachments">
                    <Attachments
                        isUploading={isUploadingAttachments}
                        attachments={attachments.value}
                        handleUpload={handleUploadAttachments}
                        handleDelete={handleDeleteAttachments}
                        overrides={{
                            Header: { component: () => null },
                            ListItem: { props: { employee: " " } }
                        }}
                    />
                </Section>
            </div>
            <AddClientModal
                isOpen={isAddClientModalOpen}
                closeModal={closeAddClientModal}
                onAddClient={({ id }) => handleSetClient({ value: id })}
            />
        </div>
    )
}

export default withStyles(GeneralInfo, styles)
