import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import orderBy from "lodash/orderBy"
import withStyles from "HOC/withStyles"
import ListingLayout from "../ListingLayout"
import Header from "../TemplatesHeader"
import { ActionButton, EmptyList } from "ui"
import FilterableTable, { Sort, Table } from "ui/FilterableTable"
import AddEditShippingMethod from "./components/AddEditShippingMethod"
import DeleteShippingMethod from "./components/DeleteShippingMethod"

import styles from "./ShippingMethods.module.css"
import actionButtonStyles from "./overrides/ActionButton.css"
import addIcon from "assets/add.svg"
import deleteIcon from "assets/close-blue.svg"
import editIcon from "assets/pencil.svg"
import truckIcon from "assets/shipping.svg"

const ShippingMethods = ({ cx, shippingMethods }) => {
    const [isModalOpen, setModalOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [shippingMethodToEdit, setShippingMethodToEdit] = useState(null)
    const [shippingMethodToDelete, setShippingMethodToDelete] = useState(null)
    const [list, setList] = useState([])
    const [ascSort, setAscSort] = useState(true)
    const [isSorting, setIsSorting] = useState(false)

    useEffect(() => {
        setList(shippingMethods.data)
    }, [shippingMethods.data])

    useEffect(() => {
        const sortedList = orderBy(list, "name", ascSort ? "asc" : "desc")
        setList(sortedList)
        setIsSorting(false)
    }, [ascSort])

    const headerActionButton = (
        <ActionButton
            handleClick={() => setModalOpen(true)}
            label="Add new"
            icon={addIcon}
            customStyles={actionButtonStyles}
        />
    )

    const renderTableButtonsActions = ({ id, name, description }) => {
        const handleEdit = () => {
            setModalOpen(true)
            setIsEdit(true)
            setShippingMethodToEdit({ id, name, description })
        }
        return (
            <Fragment>
                <ActionButton label="Edit" icon={editIcon} handleClick={() => handleEdit()} />
                <ActionButton
                    label="Delete"
                    icon={deleteIcon}
                    handleClick={() => setShippingMethodToDelete({ id, name })}
                    className={cx("deleteButton")}
                />
            </Fragment>
        )
    }

    const renderTableHeader = () => (
        <Table.Head>
            <Table.Tr>
                <Table.Th>{<Sort sortBy="name">Method Name</Sort>}</Table.Th>
                <Table.Th>Description</Table.Th>
                <Table.Th />
            </Table.Tr>
        </Table.Head>
    )

    const renderTableBody = items => {
        const rows = items.map(item => {
            return (
                <Table.Tr key={item.id}>
                    <Table.Td>
                        <p className={cx("table-name")}>{item.name}</p>
                    </Table.Td>
                    <Table.Td>
                        <p className={cx("table-description")}>{item.description}</p>
                    </Table.Td>
                    <Table.Td className="textRight">{renderTableButtonsActions(item)}</Table.Td>
                </Table.Tr>
            )
        })

        return <Table.Body>{rows}</Table.Body>
    }

    const renderEmptyList = () => <EmptyList message="No shipping method added." icon={truckIcon} />

    const handleCloseModal = () => {
        setModalOpen(false)
        if (isEdit) {
            setIsEdit(false)
        }
    }

    const handleTableChange = () => {
        setAscSort(!ascSort)
        setIsSorting(true)
    }

    return (
        <Fragment>
            <ListingLayout>
                <section className={cx("root")}>
                    <Header title={"Shipping methods"} actionButton={headerActionButton} />
                    <div className={cx("wrapper")}>
                        <FilterableTable
                            fetchStatus={shippingMethods.fetchStatus}
                            items={list}
                            meta={shippingMethods.meta}
                            handleChange={handleTableChange}
                        >
                            {!isSorting && (
                                <Table
                                    className={cx("table")}
                                    renderHeader={renderTableHeader}
                                    renderBody={renderTableBody}
                                    customEmptyMessage={renderEmptyList}
                                />
                            )}
                        </FilterableTable>
                    </div>
                </section>
            </ListingLayout>
            {isModalOpen && (
                <AddEditShippingMethod
                    handleClose={handleCloseModal}
                    edit={isEdit}
                    initialValues={isEdit ? shippingMethodToEdit : {}}
                />
            )}
            {shippingMethodToDelete && (
                <DeleteShippingMethod
                    handleClose={() => setShippingMethodToDelete(null)}
                    id={shippingMethodToDelete.id}
                    name={shippingMethodToDelete.name}
                />
            )}
        </Fragment>
    )
}

ShippingMethods.propTypes = {
    shippingMethods: PropTypes.object.isRequired
}

export default withStyles(ShippingMethods, styles)
