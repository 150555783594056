import React from "react"
import { Link } from "react-router-dom"

import useStyles from "helpers/getCxFromStyles"
import { DataLayer, ItemList, Navigation } from "modules/AttributeGroupsModules/components"
import { LIST_TYPES } from "modules/AttributeGroupsModules/constants"

import styles from "./GroupList.css"

const NAVIGATION_TABS = [
    { label: "Categories", url: "/settings/categories" },
    { label: "Brands", url: "/settings/brands" },
    { label: "Attribute Groups", url: "/settings/attribute-groups" },
    { label: "Features", url: "/settings/features" },
    { label: "Combination Templates", url: "/settings/combination-template" }
]

const GroupList = () => {
    const cx = useStyles(styles)

    const getHeaderTitle = () => "Attribute Groups"
    const parseItem = group => ({
        id: group.id,
        name: group.name,
        quantity: group.children_count,
        checked: false
    })

    return (
        <DataLayer
            type={LIST_TYPES.GROUP}
            getHeaderTitle={getHeaderTitle}
            placeholderSearch="Search for Attribute Group…"
            parseItem={parseItem}
            resourceChildrenUrl="/attribute-groups"
            resourceChildrenModelName="AttributeGroups"
            isMovementActionsDisabled
            HeaderComponent={() => (
                <div className={cx("headerList")}>
                    <div className={cx("headerListLabel", "name")}>GROUP NAME</div>
                    <div className={cx("headerListLabel", "subgroups")}>SUBGROUPS</div>
                </div>
            )}
            ItemComponent={({ item, handleAction, handleCheck, isDragDisabled }) => (
                <ItemList
                    item={item}
                    onCheck={() => handleCheck(item.id)}
                    onAction={handleAction}
                    key={item.id}
                    isDragDisabled={isDragDisabled}
                    isMovementActionsDisabled
                >
                    <div className={cx("item")}>
                        <div className={cx("name")}>
                            <Link to={`/settings/attribute-groups/${item.id}/subgroups`}>{item.name}</Link>
                        </div>
                        <div className={cx("quantity")}>{item.quantity}</div>
                    </div>
                </ItemList>
            )}
            Navigation={() => <Navigation.Tabs label="Products Management" activeTabId={2} tabs={NAVIGATION_TABS} />}
        />
    )
}

export default GroupList
