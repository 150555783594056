import React, { useState, Fragment } from "react"
import ReactSVG from "react-svg"
import PropTypes from "prop-types"
import { useFormik } from "formik"

import fetch from "helpers/fetch"
import { FileUploader, Button, Input, Modal, SketchColorPicker } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import useStyles from "helpers/getCxFromStyles"

import styles from "./AttributeValuesFormModal.css"
import modalActionsStyles from "./overrides/ModalActions.css"
import modalBodyStyles from "./overrides/ModalBody.css"
import inputStyles from "./overrides/Input.css"

import uploadIcon from "assets/ico-upload.svg"
import deleteIcon from "assets/delete.svg"

const INITIAL_VALUES = { value: "", color: "#f0f0f0", texture: "" }

const AttributeValuesFormModal = props => {
    const { onClose, isOpen, attribute, initialValues, handleSuccess, canResetAfterSuccess } = props

    const cx = useStyles(styles)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isUploadingTexture, setIsUploadingTexture] = useState(false)

    const handleSubmit = (values, formikActions) => {
        setIsSubmitting(true)

        const body = {
            value: values.value,
            color: values.color,
            texture: values.texture,
            attribute_id: attribute.id
        }

        const action = initialValues
            ? fetch.patchRAW(`/attribute-values/${initialValues.id}`, body)
            : fetch.postRAW("/attribute-values", body)

        action
            .finally(() => setIsSubmitting(false))
            .then(result => {
                canResetAfterSuccess && formik.setValues(INITIAL_VALUES)
                handleSuccess(result)
                handleClose()
            })
            .catch(({ errors }) => {
                Object.keys(errors).forEach(
                    fieldKey =>
                        errors[fieldKey].length > 0 && formikActions.setFieldError(fieldKey, errors[fieldKey][0])
                )
            })
    }

    const formik = useFormik({
        initialValues: initialValues || INITIAL_VALUES,
        enableReinitialize: true,
        onSubmit: handleSubmit
    })

    const handleUploadSuccessTexture = result => {
        if (result.length > 0) {
            setIsUploadingTexture(false)
            formik.setFieldValue("texture", result[0])
        }
    }

    const handleUploadProgressTexture = () => {
        setIsUploadingTexture(true)
    }

    const handleRemoveTexture = () => {
        formik.setFieldValue("texture", "")
    }

    const textureUrl = formik.values.texture
        ? formik.values.texture.file
            ? formik.values.texture.file.preview
            : formik.values.texture
        : ""

    const handleClose = () => {
        onClose()
        formik.setValues(INITIAL_VALUES)
    }

    return (
        <Modal isOpen={isOpen} closeModal={handleClose} preventClickOutside>
            <Modal.Header>{initialValues ? "Edit attribute value" : "Create attribute value"}</Modal.Header>
            <Modal.Body customStyles={modalBodyStyles}>
                <p className={cx("attribute")}>
                    <b>Attribute:</b> {attribute.name}
                </p>
                <Input
                    name="value"
                    label="Value"
                    value={formik.values.value}
                    error={formik.errors.value}
                    onChange={({ event }) => formik.handleChange(event)}
                    customStyles={inputStyles}
                    attributes={{ required: true }}
                />
                {attribute.type === "color" && (
                    <Fragment>
                        <div className={cx("color")}>
                            <span className={cx("label")}>Color:</span>
                            <SketchColorPicker
                                value={formik.values.color || ""}
                                onChange={color => formik.setFieldValue("color", color)}
                            />
                        </div>
                        <div className={cx("texture")}>
                            <span className={cx("label")}>Texture:</span>
                            <div className={cx("wrapper")}>
                                {textureUrl && (
                                    <div className={cx("value")} style={{ backgroundImage: `url(${textureUrl})` }}>
                                        <div className={cx("delete")} onClick={handleRemoveTexture}>
                                            <ReactSVG src={deleteIcon} />
                                        </div>
                                    </div>
                                )}
                                <FileUploader
                                    handleUploadSuccess={handleUploadSuccessTexture}
                                    handleUploadProgress={handleUploadProgressTexture}
                                    isUploading={isUploadingTexture}
                                    isMultiple={false}
                                    label="Texture"
                                >
                                    <div className={cx("dropzone", { isUploading: isUploadingTexture })}>
                                        {isUploadingTexture ? (
                                            <SnakeLoader customStyles={styles} />
                                        ) : (
                                            <ReactSVG src={uploadIcon} />
                                        )}
                                    </div>
                                </FileUploader>
                            </div>
                        </div>
                    </Fragment>
                )}
            </Modal.Body>
            <Modal.Actions customStyles={modalActionsStyles}>
                <Button className="close-button" label="Cancel" onClick={handleClose} />
                <Button
                    className="first-button"
                    label={initialValues ? "Save" : "Create"}
                    type="submit"
                    onClick={formik.handleSubmit}
                    isLoading={isSubmitting}
                />
            </Modal.Actions>
        </Modal>
    )
}

AttributeValuesFormModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    attribute: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.oneOf(["color", "dropdown"])
    }).isRequired,
    handleSuccess: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.number.isRequired,
        value: PropTypes.string.isRequired,
        color: PropTypes.string,
        texture: PropTypes.string
    }),
    canResetAfterSuccess: PropTypes.bool
}

export default AttributeValuesFormModal
