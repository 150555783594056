import fetchHelper from "helpers/fetch"
import * as actionTypes from "./actionTypes"
import { serializeQuery } from "helpers/urls"

export { shouldFetch } from "helpers/fetchStatus"

const actionRequest = params => {
    params = {
        method: "get",
        body: {},
        ...params
    }

    return dispatch => {
        dispatch({
            type: params.types[0]
        })

        return new Promise((resolve, reject) => {
            fetchHelper[params.method](params.endpoint, params.body).then(
                data => {
                    dispatch({
                        type: params.types[1],
                        payload: data
                    })

                    resolve(data)
                },
                error => {
                    dispatch({
                        type: params.types[2],
                        payload: {
                            error
                        }
                    })

                    reject(error)
                }
            )
        })
    }
}

export const fetchProjects = (
    params = {},
    types = [actionTypes.GET_PROJECTS_REQUEST, actionTypes.GET_PROJECTS_SUCCESS, actionTypes.GET_PROJECTS_FAILURE]
) => {
    params = {
        page: 1,
        length: 20,
        ...params
    }

    return actionRequest({
        types: types,
        method: "get",
        endpoint: "/projects?" + serializeQuery(params)
    })
}

export const fetchMoreProjects = (params = {}) => {
    return fetchProjects(params, [
        actionTypes.GET_MORE_PROJECTS_REQUEST,
        actionTypes.GET_MORE_PROJECTS_SUCCESS,
        actionTypes.GET_MORE_PROJECTS_FAILURE
    ])
}

export const clearState = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_PROJECTS
    })
}
