import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import _get from "lodash/get"

import { useOrderDetails } from "modules/WmsModule/hooks/api/useOrder"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import useActions from "hooks/useActions"
import useFetch from "hooks/useFetch"
import fetch from "helpers/fetch"
import { API_DATE_FORMAT } from "constants/index"

import AddEditPayment from "./AddEditPayment"

const AddEditPaymentContainer = ({
    handleClose,
    collectionType,
    collectionId,
    invoice,
    calculations,
    payment,
    onAddSuccess,
    onEditSuccess,
    getOrderForCalculations
}) => {
    const isEdit = !!payment
    const paymentOrderId = _get(payment, "order.id")
    const isPO = _get(invoice, "uuid", "").includes("PO")

    const calculatedCollectionId = collectionId || paymentOrderId
    const calculatedCollectionType = collectionId ? collectionType : "order"

    const order = useOrderDetails({
        id: calculatedCollectionType === "order" ? calculatedCollectionId : undefined
    })

    const actions = useActions({
        showSuccessNotification,
        showErrorNotification
    })

    const initialValues = {
        amount: "",
        method: {},
        date: moment(new Date()).format(API_DATE_FORMAT),
        invoice: invoice || {},
        note: ""
    }

    const formValues = Object.entries(initialValues).reduce(
        (acc, [key, initialValue]) => ({ ...acc, [key]: (payment && payment[key]) || initialValue }),
        {}
    )

    const [handleSubmit, { isLoading: isSubmitting }] = useFetch({
        action: async values => {
            const {
                amount,
                invoice: { id: invoice_id },
                method: { id: payment_method_id },
                ...restValues
            } = values

            const body = {
                amount: Number(amount),
                [`${calculatedCollectionType}_id`]: calculatedCollectionId,
                invoice_id: invoice_id || null,
                payment_method_id,
                ...restValues
            }

            const [method, url] = isEdit ? ["patch", `/${payment.id}`] : ["post", ""]

            const data = await fetch[method](`/payments${url}`, body)
            await (typeof getOrderForCalculations === "function" ? getOrderForCalculations() : Promise.resolve())
            return data
        },
        onSuccess: data => {
            actions.showSuccessNotification()
            isEdit ? onEditSuccess(data) : onAddSuccess(data)
            handleClose()
        },
        onError: (data, _, formikProps) => {
            data && data.errors && formikProps.setErrors({ ...data.errors, method: data.errors.payment_method_id })
            actions.showErrorNotification()
        }
    })

    return (
        <AddEditPayment
            calculations={calculations || (order.data ? order.data.calculations : undefined)}
            formValues={formValues}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isEdit={isEdit}
            isSubmitting={isSubmitting}
            collectionId={calculatedCollectionId}
            collectionType={calculatedCollectionType}
            invoice={invoice}
            isLoading={order.fetchStatus.isLoading}
            isPO={isPO}
            availableStoreCreditAmount={order.data ? order.data.client.store_credits : 0}
        />
    )
}

AddEditPaymentContainer.propTypes = {
    handleClose: PropTypes.func.isRequired,
    collectionId: PropTypes.number.isRequired,
    collectionType: PropTypes.oneOf(["order", "project"]).isRequired,
    payment: PropTypes.object,
    invoice: PropTypes.object,
    calculations: PropTypes.shape({
        applied: PropTypes.number,
        balance: PropTypes.number,
        total: PropTypes.number,
        wholesale_total: PropTypes.number
    }).isRequired,
    onAddSuccess: PropTypes.func.isRequired,
    onEditSuccess: PropTypes.func.isRequired,
    getOrderForCalculations: PropTypes.func.isRequired
}

export default AddEditPaymentContainer
