import { INPUT_TYPES } from "constants/index"

export default {
    name: "Forgot Password",
    endpoint: "/user/remind-password",
    fields: [
        {
            name: "email",
            label: "E-mail address",
            type: INPUT_TYPES.EMAIL,
            defaultValue: "",
            attributes: {
                required: true
            }
        }
    ]
}
