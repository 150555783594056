import { SET_LAST_FETCHED_ORDER } from "constants/index"

const initialState = {
    data: null
}

function lastFetched(state = initialState, action) {
    switch (action.type) {
        case SET_LAST_FETCHED_ORDER:
            return {
                ...state,
                data: action.payload
            }

        default:
            return state
    }
}

export default lastFetched
