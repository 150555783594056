import React, { Fragment, useEffect, useMemo, useState } from "react"
import { Formik, Form as FormikForm } from "formik"
import _ from "lodash/core"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"
import withPermissions from "HOC/withPermissions"

import { scrollToTop } from "helpers/scrollTo"

import { SaveBar, Tabs } from "ui"
import Layout from "components/Reusable/Layout"
import ProfileSection from "./components/ProfileSection"
import RolesSection from "./components/RolesSection"
import NotificationsSection from "./components/NotificationsSection"
import WarehousesSection from "./components/WarehousesSection"
import Sidebar from "./components/Sidebar"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import styles from "./styles.css"
import layoutStyles from "./overrides/layout.css"
import tabsStyles from "./overrides/tabs.css"

const Form = ({
    cx,
    initValues,
    handleSubmit,
    isSubmitting,
    uploader,
    isMyProfile,
    roles,
    calendars,
    isLoading,
    countErrors,
    calculateErrorCounts,
    handleResetPassword,
    canEdit,
    checkPermission,
    PERMISSIONS
}) => {
    const tabs = useMemo(
        () =>
            Object.assign(
                {
                    profile: {
                        label: "PROFILE",
                        Component: ProfileSection,
                        errorCount: countErrors.profile || null
                    }
                },
                !isMyProfile && {
                    roles: {
                        label: "ROLES",
                        Component: RolesSection,
                        errorCount: countErrors.roles || null
                    }
                },
                {
                    notifications: {
                        label: "NOTIFICATIONS",
                        Component: NotificationsSection,
                        errorCount: countErrors.notifications || null
                    }
                },
                canEdit(PERMISSIONS.context.USERS) &&
                    checkPermission(PERMISSIONS.types.ASSIGN_USER_TO, PERMISSIONS.context.WMS)
                    ? {
                          warehouses: {
                              label: "WAREHOUSES",
                              Component: WarehousesSection,
                              errorCount: countErrors.warehouses || null
                          }
                      }
                    : {}
            ),
        [countErrors]
    )

    const [activeTabIndex, setActiveTabIndex] = useState("profile")

    useEffect(() => {
        scrollToTop()
    }, [])

    const TabContent = tabs[activeTabIndex].Component

    return (
        <Formik initialValues={initValues} enableReinitialize onSubmit={handleSubmit}>
            {propsForm => {
                calculateErrorCounts(propsForm.errors)

                return (
                    <FormikForm noValidate={true}>
                        <Layout title="Account settings" customStyles={layoutStyles}>
                            <Fragment>
                                <div className={cx("contentWrapper")}>
                                    <Tabs
                                        customStyles={tabsStyles}
                                        tabs={tabs}
                                        activeTabKey={activeTabIndex}
                                        onTabChange={setActiveTabIndex}
                                    />

                                    {isLoading ? (
                                        <div className={cx("loader")}>
                                            <SnakeLoader />
                                        </div>
                                    ) : (
                                        <div className={cx("section")}>
                                            <TabContent
                                                form={propsForm}
                                                isMyProfile={isMyProfile}
                                                roles={roles}
                                                calendars={calendars}
                                                handleResetPassword={handleResetPassword}
                                            />
                                        </div>
                                    )}
                                </div>
                                <Sidebar form={propsForm} uploader={uploader} />
                            </Fragment>
                        </Layout>
                        <SaveBar
                            isSaving={isSubmitting}
                            isShown={!_.isEqual(initValues, propsForm.values)}
                            isDisabled={uploader && uploader.state.isUploading}
                            submitLabel="Save"
                            message="Unsaved changes"
                            onCancel={propsForm.resetForm}
                            isSubmit
                        />
                    </FormikForm>
                )
            }}
        </Formik>
    )
}

Form.propTypes = {
    cx: PropTypes.func.isRequired,
    initValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    uploader: PropTypes.object.isRequired
}

export default withPermissions(withUploader(withStyles(Form, styles)))
