import React, { Fragment } from "react"
import moment from "moment"
import { withRouter } from "react-router-dom"

import withStyles from "HOC/withStyles"
import * as fetchStatusHelper from "helpers/fetchStatus"
import { Modal, Input, Button, EmptyList } from "ui"
import InfiniteScroll from "ui/InfiniteScroll"
import Loader from "components/Reusable/Loader"
import Skeleton from "components/Skeleton"

import styles from "./ChooseOrder.css"
import inputStyles from "./overrides/Input.css"
import addProductButton from "./overrides/AddProductButton.css"
import stylesCreateButton from "./overrides/CreateButton.css"

import orderIcon from "assets/order.svg"

const ChooseOrder = props => {
    const {
        cx,
        selectedOrder,
        isSaving,
        setIsSaving,
        orders,
        filterSearchPhrase,
        hasMoreOrders,
        handleLoadMoreOrders,
        handleChangeFilterSearchPhrase,
        handleSelectOrder
    } = props

    return (
        <Fragment>
            <Modal.Header>Add this product to a order</Modal.Header>
            <Modal.Body>{renderBody()}</Modal.Body>
        </Fragment>
    )

    function renderBody() {
        return (
            <Skeleton
                fetchStatus={fetchStatusHelper.fromResources(orders)}
                fallback={() => <div>An error occurred, please try again.</div>}
                component={
                    <div className={cx("loader")}>
                        <Loader />
                    </div>
                }
                firstTime={true}
            >
                <div className={cx("searchContainer")}>
                    <div className={cx("searchBar")}>
                        <Input
                            name="search"
                            label="Search for order..."
                            isPlaceholder={true}
                            customStyles={inputStyles}
                            value={filterSearchPhrase}
                            onChange={({
                                event: {
                                    target: { value }
                                }
                            }) => handleChangeFilterSearchPhrase(value)}
                            autoComplete="off"
                        />
                    </div>
                </div>
                {renderProductListContainer()}
            </Skeleton>
        )
    }

    function renderProductListContainer() {
        return (
            <div className={cx("productListContainer")}>
                <Skeleton
                    fetchStatus={fetchStatusHelper.fromResources(orders)}
                    component={
                        <div className={cx("loader")}>
                            <Loader />
                        </div>
                    }
                >
                    {renderProductList()}
                </Skeleton>
            </div>
        )
    }

    function renderProductList() {
        const { history } = props

        if (!orders.meta.total) {
            return (
                <EmptyList
                    message="No order found."
                    line2="Please check the spelling or create new order."
                    icon={orderIcon}
                >
                    <div className={cx("actionContainer")}>
                        <Button
                            customStyles={stylesCreateButton}
                            className={["first-button", "white"]}
                            label="Create New Order"
                            onClick={() => history.push("/orders/sales-orders/add")}
                        />
                    </div>
                </EmptyList>
            )
        }

        return (
            <Fragment>
                <div className={cx("productListInfo")}>My clients orders</div>
                <div className={cx("productListInfiniteScrollContainer")}>
                    <InfiniteScroll
                        className={cx("productList")}
                        hasMore={hasMoreOrders}
                        loader={
                            <div key="loader" className={cx("loader")}>
                                <Loader />
                            </div>
                        }
                        loadMore={handleLoadMoreOrders}
                        threshold={200}
                        useWindow={false}
                        element="ul"
                    >
                        {orders.data.map((order, index) => {
                            return (
                                <li key={index} className={cx("item")}>
                                    <div className={cx("side")}>
                                        <div className={cx("nameContainer")}>
                                            <div className={cx("name")}>{order.uuid}</div>
                                            <div className={cx("company")}>{renderNameAndCompany(order)}</div>
                                        </div>
                                    </div>
                                    <div className={cx("side")}>
                                        <div className={cx("updated")}>
                                            updated {moment(order.updated_at).fromNow()}
                                        </div>
                                        <Button
                                            label="Add product"
                                            isLoading={isSaving && selectedOrder && selectedOrder.id === order.id}
                                            customStyles={addProductButton}
                                            onClick={() => {
                                                setIsSaving(true)
                                                handleSelectOrder(order)
                                            }}
                                        />
                                    </div>
                                </li>
                            )
                        })}
                    </InfiniteScroll>
                </div>
            </Fragment>
        )
    }

    function renderNameAndCompany(order) {
        const client = order.client || null

        if (!client) {
            return null
        }

        const firstName = client.first_name || null
        const lastName = client.last_name || null

        const name = [firstName, lastName].filter(i => !!i).join(" ")
        const company = ((client.addresses || []).find(item => item.type === "personal") || {}).company || null

        return [name, company].filter(i => !!i).join(", ")
    }
}

export default withStyles(withRouter(ChooseOrder), styles)
