import React, { useEffect, useState } from "react"
import idx from "idx"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import fetch from "helpers/fetch"
import useSelectCombination from "hooks/useSelectCombination"

import * as productActions from "./actions"
import Combinations from "./Combinations"

const CombinationsContainer = props => {
    const {
        actions,
        productId,
        productImage,
        product,
        handleSelectProduct,
        handleSelectProductCombination,
        itemsState,
        handleChangeIsLoading
    } = props

    const { selectedAttributes, selectAttribute, attributesForComponent } = useSelectCombination({
        combinationPatterns: idx(product, _ => _.data.combination_patterns),
        attributes: idx(product, _ => _.data.attributes),
        enabled: product.fetchStatus.isLoaded
    })
    const [combinationId, setCombinationId] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [isFetchingCombination, setIsFetchingCombination] = useState(false)

    useEffect(() => {
        if (actions.shouldFetch(product)) {
            actions.fetchProduct(productId)
        }
    }, [product])

    useEffect(() => {
        const attributeValueIds = selectedAttributes.map(selectedAttribute => selectedAttribute.value.id)
        const isCombinationSelected = attributeValueIds.length > 0 && attributeValueIds.every(id => id)

        if (isCombinationSelected && product.fetchStatus.isLoaded) {
            setIsFetchingCombination(true)
            fetch
                .get(`/combinations/find?productId=${product.data.id}&attributeValueIds=${attributeValueIds.join(",")}`)
                .then(({ data: combination }) => {
                    setCombinationId(combination.id)
                })
                .catch(() => setCombinationId(null))
                .finally(() => setIsFetchingCombination(false))
        } else {
            setCombinationId(null)
        }
    }, [selectedAttributes, product])

    useEffect(() => {
        return () => {
            actions.clearProductState()
        }
    }, [])

    function handleChangeQuantity(value) {
        setQuantity(value)
    }

    return (
        <Combinations
            product={product}
            productImage={productImage}
            productAttributes={attributesForComponent}
            combinationId={combinationId}
            quantity={quantity}
            handleChangeAttribute={selectAttribute}
            handleSelectProduct={handleSelectProduct}
            handleSelectProductCombination={handleSelectProductCombination}
            handleChangeQuantity={handleChangeQuantity}
            itemsState={itemsState}
            handleChangeIsLoading={handleChangeIsLoading}
            isFetchingCombination={isFetchingCombination}
        />
    )
}

const mapStateToProps = state => {
    return {
        product: state.ui.modals.chooseProduct.products.current
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...productActions
            },
            dispatch
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CombinationsContainer)
