import React, { Fragment } from "react"
import {
    useTableFilterBarSearch,
    SnakeLoader,
    EmptyInternalList,
    ListIcon,
    AlertErrorIcon
} from "@butterfly-frontend/ui"
import SearchBar from "components/Reusable/SearchBar"
import { InfiniteScroll } from "ui"
import withStyles from "HOC/withStyles"

import { useProductInfiniteList } from "./hooks"

import styles from "./ProductSearch.css"

const ProductSearch = ({ cx, productId, handleSelectProduct, placeholder, availableOnWeb }) => {
    const {
        value: filter,
        debouncedValue: query,
        onChange: handleSearch,
        onClear: clearInput
    } = useTableFilterBarSearch("")

    const { pages, meta, hasNextPage, isFetchingNextPage, fetchNextPage, fetchStatus } = useProductInfiniteList({
        params: { query, availableOnWeb },
        reactQueryProps: {
            enabled: !!query
        }
    })

    return (
        <div className={cx("root")}>
            <div className={cx("search-bar")}>
                <SearchBar
                    placeholder={placeholder}
                    handleSearch={({ query }) => handleSearch({ target: { value: query } })}
                    clearInput={clearInput}
                    filter={filter}
                />
            </div>

            {!!filter && (
                <div className={cx("dropdown-list")}>
                    {fetchStatus.isError ? (
                        <EmptyInternalList text="Unknown error" Icon={AlertErrorIcon} />
                    ) : fetchStatus.isLoading && !fetchStatus.isLoaded ? (
                        <SnakeLoader />
                    ) : (
                        fetchStatus.isLoaded &&
                        (meta.total > 0 ? (
                            <InfiniteScroll
                                className={styles.userList}
                                hasMore={hasNextPage}
                                loader={<SnakeLoader key="loader" />}
                                loadMore={() => {
                                    if (hasNextPage || !isFetchingNextPage) {
                                        return fetchNextPage()
                                    }
                                }}
                                threshold={200}
                                useWindow={false}
                                element="ul"
                            >
                                {pages &&
                                    pages
                                        .filter(product => Number(product.id) !== Number(productId))
                                        .map((page, i) => (
                                            <Fragment key={i}>
                                                {page.data.map(product => (
                                                    <li
                                                        key={product.id}
                                                        onClick={() => {
                                                            handleSelectProduct && handleSelectProduct(product.id)
                                                        }}
                                                    >
                                                        <span className={cx("dropdown-img")}>
                                                            <img
                                                                src={
                                                                    product.media && product.media.url
                                                                        ? `${product.media.url}?type=related`
                                                                        : null
                                                                }
                                                                alt=""
                                                            />
                                                        </span>
                                                        <span className={cx("dropdown-title")}>{product.name}</span>
                                                        <span>{product.brand}</span>
                                                    </li>
                                                ))}
                                            </Fragment>
                                        ))}
                            </InfiniteScroll>
                        ) : (
                            <EmptyInternalList text="Sorry, no results found" Icon={ListIcon} />
                        ))
                    )}
                </div>
            )}
        </div>
    )
}

export default withStyles(ProductSearch, styles)
