export const buildLink = ({ groupId, subgroupId, attributeId } = {}) => {
    let link = "settings/attribute-groups"

    if (groupId) {
        link += `/${groupId}/subgroups`

        if (subgroupId) {
            link += `/${subgroupId}/attributes`

            if (attributeId) {
                link += `/${attributeId}/values`
            }
        }
    }

    return link
}
