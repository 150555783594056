import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import fetch from "helpers/fetch"
import * as clientGroupsActions from "actions/clientGroups"
import * as clientActions from "actions/client"
import * as notificationActions from "actions/notification"

import ListingGroups from "./ListingGroups"
import ListingClients from "./ListingClients"

class ListingContainer extends Component {
    state = {
        currentEditGroup: null,
        isClientGroupsModalOpen: false
    }

    setGroupsModalOpen = (value, currentEditGroupId = -1) => {
        const { clientGroups } = this.props
        let currentEditGroup = null
        if (currentEditGroupId !== -1) {
            currentEditGroup = clientGroups.data.find(c => c.id === currentEditGroupId)
        }
        this.setState({ isClientGroupsModalOpen: value, currentEditGroup, formErrors: {} })
    }

    componentDidMount() {
        const { getClientGroups } = this.props
        getClientGroups()
    }

    componentDidUpdate(prevProps) {
        const { getClientGroups, clientGroupId } = this.props
        if (prevProps.clientGroupId && !clientGroupId) {
            getClientGroups()
        }
    }

    createOrUpdateClientGroup = (method, { name, description, discount }, callbackOnError = () => {}) => {
        const { getClientGroups, showSuccessNotification, showErrorNotification } = this.props
        const { currentEditGroup } = this.state

        if (method !== "post" && method !== "patch") {
            throw new Error("Method can be only 'post' or 'patch'")
        }

        if (!currentEditGroup && method === "patch") {
            return showErrorNotification()
        }

        const endpoint = `/client-groups${method === "patch" ? `/${currentEditGroup.id}` : ""}`

        fetch[`${method}RAW`](endpoint, { name, description, discount: Number(discount) })
            .then(() => {
                const verb = method === "post" ? "created" : "updated"
                showSuccessNotification({ title: `Interaction has been ${verb}.` })
                getClientGroups()
                this.setGroupsModalOpen(false)
            })
            .catch(res => {
                res && res.errors && callbackOnError(res.errors)
            })
    }

    deleteClientGroup = id => {
        const { getClientGroups, showSuccessNotification, showErrorNotification } = this.props
        fetch
            .deleteRAW(`/client-groups/${id}`)
            .then(() => {
                showSuccessNotification({ title: "Interaction has been deleted." })
                getClientGroups()
            })
            .catch(() => showErrorNotification())
    }

    render() {
        return (
            <Fragment>
                {this.props.clientGroupId ? (
                    <ListingClients {...this.props} />
                ) : (
                    <ListingGroups
                        {...this.props}
                        {...this.state}
                        setGroupsModalOpen={this.setGroupsModalOpen}
                        createOrUpdateClientGroup={this.createOrUpdateClientGroup}
                        deleteClientGroup={this.deleteClientGroup}
                    />
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const clientGroupId = parseInt(ownProps.match.params.clientGroupId, 10) || null

    return {
        clientGroupId,
        clientGroups: state.db.clientGroups.list,
        currentClientGroup: state.db.clientGroups.current,
        clients: state.clientStore.clients
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getClientGroups: () => dispatch(clientGroupsActions.getClientGroups()),
        getClientGroup: id => dispatch(clientGroupsActions.getClientGroupById(id)),
        getClientGroupSuccess: data => dispatch(clientGroupsActions.getClientGroupByIdSuccess(data)),
        getClients: params => dispatch(clientActions.getClients(params)),
        getClientsSuccess: data => dispatch(clientActions.getClientsSuccess(data)),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification()),
        showSuccessNotification: data => dispatch(notificationActions.showSuccessNotification(data))
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingContainer)
