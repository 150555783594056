import React, { useMemo } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { KANBAN_ROW_TYPE } from "@butterfly-frontend/ui"

import { ShipmentKanbanRow } from "modules/WmsModule/components"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import useConsignmentNoteOptions from "modules/WmsModule/hooks/useConsignmentNoteOptions"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import { SHIPMENT_LIST_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"
import { checkIfCanGenerateConsignmentNote } from "modules/WmsModule/helpers/consignmentNote"

const PICK_UP_LABEL = "Pick up"
const RETURN_LABEL = "Return"

const UnpackedPackageRow = ({ shipment, rowType, getReceivingHref, redirectToReceivingDetails, openReturnModal }) => {
    const { options: boxLabelsOptions, isLoading: isBoxLabelOptionsLoading } = useBoxLabelOptions({
        ids: shipment.stock_items_ids
    })

    const { options: consignmentNoteOptions, isLoading: isConsignmentNoteOptionsLoading } = useConsignmentNoteOptions({
        ids: [shipment.id]
    })

    const areRejectedItemsAvailableToReturn = useMemo(() => {
        const returnedStockItemsCount = _get(shipment, "returned_count", null)
        const rejectedStockItemsCount = _get(shipment, "shipment_items_acceptance_counts.rejected_items", null)

        return [returnedStockItemsCount, rejectedStockItemsCount].every(Number.isInteger)
            ? returnedStockItemsCount < rejectedStockItemsCount && rejectedStockItemsCount > 0
            : false
    }, [shipment])

    const rowOptions = useMemo(() => {
        const documentOptions = [
            ...(boxLabelsOptions || []),
            ...(checkIfCanGenerateConsignmentNote(shipment) ? consignmentNoteOptions : [])
        ]

        if (shipment.status === SHIPMENT_STATUS_KEYS.SENT) {
            return [
                {
                    onClick: () => redirectToReceivingDetails(shipment),
                    label: PICK_UP_LABEL
                },
                ...documentOptions
            ]
        }

        if (areRejectedItemsAvailableToReturn && !shipment.is_client_return) {
            return [
                {
                    onClick: () => openReturnModal(shipment),
                    label: RETURN_LABEL
                },
                ...documentOptions
            ]
        }

        return documentOptions
    }, [areRejectedItemsAvailableToReturn, boxLabelsOptions, redirectToReceivingDetails, openReturnModal, shipment])

    const calculatedRowType = areRejectedItemsAvailableToReturn ? KANBAN_ROW_TYPE.WARNING : rowType
    const disabled = calculatedRowType === KANBAN_ROW_TYPE.DANGER

    return (
        <ShipmentKanbanRow
            rowType={calculatedRowType}
            shipment={shipment}
            actions={rowOptions}
            isLoading={isBoxLabelOptionsLoading || isConsignmentNoteOptionsLoading}
            disabled={disabled}
            shipmentHref={getReceivingHref(shipment)}
        />
    )
}

UnpackedPackageRow.propTypes = {
    shipment: SHIPMENT_LIST_ITEM_PROP_TYPE.isRequired,
    rowType: PropTypes.oneOf(Object.values(KANBAN_ROW_TYPE)),
    redirectToReceivingDetails: PropTypes.func.isRequired,
    getReceivingHref: PropTypes.func.isRequired,
    openReturnModal: PropTypes.func.isRequired
}

export default UnpackedPackageRow
