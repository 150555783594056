import jwt from "jsonwebtoken"

export const isTokenValid = token => {
    if (!token || token === "null") {
        return false
    }

    const decodedAccessToken = jwt.decode(token)
    if (decodedAccessToken) {
        const now = new Date()
        return now.getTime() < Number(decodedAccessToken.exp) * 1000
    }

    return false
}
