import React, { useMemo } from "react"
import { useFormikContext } from "formik"

import { useSelector } from "react-redux"
import { Field } from "formik"

import { Input, Select } from "ui"

import inputStyles from "../overrides/input.css"
import selectStyles from "../overrides/select.css"

const AddressForm = props => {
    const { cx, config, isDisabled } = props
    const { addressName, formFields } = config

    const countries = useSelector(state => state.clientStore.countriesList)
    const states = useSelector(state => state.clientStore.statesList)

    const selectValues = { countries, states }
    const { values } = useFormikContext()
    const addressValue = values[addressName]

    const render = useMemo(
        () => ({
            input: (formikField, config) => {
                const { field, meta } = formikField
                const { label } = config

                return (
                    <Input
                        {...field}
                        label={label}
                        onChange={({ event }) => field.onChange(event)}
                        error={meta.error}
                        customStyles={inputStyles}
                        isDisabled={isDisabled}
                    />
                )
            },
            select: (formikField, config) => {
                const { field, meta } = formikField
                const { label, selectValuesKey } = config
                const values = selectValues[selectValuesKey] || []

                return (
                    <Select
                        {...field}
                        value={field.value || ""}
                        label={label}
                        values={values.map(selectValue => ({ label: selectValue, value: selectValue }))}
                        setValue={({ value }) =>
                            value !== field.value && field.onChange({ target: { name: field.name, value } })
                        }
                        error={meta.touched && meta.error}
                        withSearch
                        customStyles={selectStyles}
                        isDisabled={isDisabled}
                    />
                )
            },
            state: (formikField, config) => {
                return addressValue.country === "United States of America"
                    ? render.select(formikField, { ...config, selectValuesKey: "states" })
                    : render.input(formikField, config)
            }
        }),
        [addressValue.country, isDisabled]
    )

    return (
        <ul className={cx("address")}>
            {Object.entries(formFields).map(([key, fieldConfig]) => {
                const { componentType, width } = fieldConfig

                return (
                    <li key={key} style={{ width: `calc(${width})` }}>
                        <Field name={`${addressName}.${key}`}>
                            {field =>
                                typeof render[componentType] === "function" && render[componentType](field, fieldConfig)
                            }
                        </Field>
                    </li>
                )
            })}
        </ul>
    )
}

export default AddressForm
