import React, { useMemo } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { CalendarIcon } from "@butterfly-frontend/ui"

import { Input } from "ui"
import { formatDate } from "helpers/date"
import SelectWarehouse from "ui/Filters/SelectWarehouse/SelectWarehouse"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import formikPropTypesFactory from "modules/WmsModule/helpers/formikPropTypesFactory"
import selectedItemsPropType from "modules/WmsModule/modals/StockTransfer/selectedItemsPropType"

import styles from "./WarehouseTransferForm.module.css"

const uniqueWarehousesFilter = (warehouseName, warehouseNameIndex, warehouseNames) =>
    warehouseNames.indexOf(warehouseName) === warehouseNameIndex

const getShippingFromWarehouseNames = selectedItems =>
    selectedItems
        .map(selectedItem => selectedItem.warehouse.name)
        .filter(uniqueWarehousesFilter)
        .join(", ")

const WarehouseTransferForm = ({ formik, selectedItems, shippingDate, clearAlreadyInTargetWarehouseApiErrors }) => {
    const shippingFrom = useMemo(() => getShippingFromWarehouseNames(selectedItems), [selectedItems])
    const getSelectProps = getSelectPropsFactory(formik)

    const setTargetWarehouse = async value => {
        const selectValueToSet = _get(value, "value")

        if (_get(formik.values, "shippingTo.value") !== selectValueToSet) {
            clearAlreadyInTargetWarehouseApiErrors()
        }

        const valueToSet = selectValueToSet ? value : null

        await formik.setFieldValue("shippingTo", valueToSet)
        formik.setFieldTouched("shippingTo")
    }

    return (
        <div className={styles.container}>
            <Input
                title={shippingFrom}
                label="From warehouse"
                value={shippingFrom}
                className={styles.input}
                isDisabled
            />
            <SelectWarehouse
                {...getSelectProps("shippingTo", { setValue: setTargetWarehouse })}
                label="To warehouse"
                isRequired
                withPrimaryLabel
                warehouseType="active"
            />
            <div className={styles.calendarInput}>
                <Input label="Transfer date" value={formatDate(shippingDate)} className={styles.input} isDisabled />
                <CalendarIcon className={styles.icon} />
            </div>
        </div>
    )
}

WarehouseTransferForm.propTypes = {
    formik: formikPropTypesFactory({
        shippingTo: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })
    }),
    selectedItems: selectedItemsPropType,
    shippingDate: PropTypes.instanceOf(Date).isRequired,
    clearAlreadyInTargetWarehouseApiErrors: PropTypes.func.isRequired
}

export default WarehouseTransferForm
