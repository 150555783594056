import React, { useState } from "react"
import PropTypes from "prop-types"

import SendableModal from "ui/SendableModal"

import fetch from "helpers/fetch"
import useActions from "hooks/useActions"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

const Sendable = props => {
    const { printType, ids, handleClose, handlePrint, uuid } = props

    const actions = useActions({ showSuccessNotification, showErrorNotification })

    const [isPrinting, setIsPrinting] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)

    const handleSubmit = (data, { setErrors }) => {
        const { subject, message, receivers } = data
        const emails = receivers.map(item => item.email)

        const body = {
            ids,
            model: "products",
            action: "send",
            payload: { emails, message, subject, print_type: printType }
        }

        setSubmitting(true)
        fetch
            .postRAW(`/multi-actions`, body)
            .then(() => {
                actions.showSuccessNotification({ title: "Sent" })
                handleClose()
            })
            .catch(data => {
                data && data.errors && setErrors(data.errors)
                actions.showErrorNotification(data.message)
            })
            .finally(() => setSubmitting(false))
    }

    const print = () => setIsPrinting(true) || handlePrint(ids, printType).finally(() => setIsPrinting(false))

    return (
        <SendableModal
            handleClose={handleClose}
            isPrinting={isPrinting}
            id={-1}
            ids={ids}
            isSubmitting={isSubmitting}
            uuid={uuid}
            printType={printType}
            handleSubmit={handleSubmit}
            handlePrint={print}
        />
    )
}

Sendable.propTypes = {
    isPrinting: PropTypes.bool,
    printType: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handlePrint: PropTypes.func.isRequired,
    uuid: PropTypes.string
}

Sendable.defaultProps = {
    uuid: "products cards"
}

export default Sendable
