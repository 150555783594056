import React, { Component } from "react"

import { withStyles } from "ui/FilterableTable/HOC"
import styles from "./Header.css"

class Header extends Component {
    render() {
        const { cx, createNewButton: CreateNewButton, name } = this.props

        return (
            <div className={cx("root")}>
                <div className={cx("title")}>
                    <h2>{name}</h2>
                    {CreateNewButton && (
                        <div className={cx("buttonHolder")}>
                            <CreateNewButton />
                        </div>
                    )}
                </div>
                {this.renderContent()}
            </div>
        )
    }

    renderContent() {
        const { cx, children, MoreFiltersComponent = null } = this.props

        if (React.Children.count(children)) {
            return (
                <div className={cx("content", { areMoreFilters: !!MoreFiltersComponent })}>
                    {children}
                    {!!MoreFiltersComponent && <div className={cx("moreFilters")}>{MoreFiltersComponent}</div>}
                </div>
            )
        }

        return null
    }
}

export default withStyles(styles)(Header)
