import React from "react"

import withStyles from "HOC/withStyles"

const Root = props => {
    const { cx, children } = props

    return <div className={cx("root")}>{children}</div>
}

export default withStyles(Root)
