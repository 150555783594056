import { useState } from "react"
import xor from "lodash/xor"

const useMultiselect = (listLength, initialSelected = []) => {
    const [selected, setSelected] = useState(initialSelected)

    const areAllSelected = !!listLength && selected.length === listLength
    const areSomeSelected = !!listLength && !!selected.length && !areAllSelected

    const toggleSelected = value => setSelected(oldSelected => xor([value], oldSelected))
    const setAllSelected = allSelected => setSelected(allSelected)
    const clearAllSelected = () => setSelected([])

    return { selected, areSomeSelected, areAllSelected, toggleSelected, setAllSelected, clearAllSelected }
}

export default useMultiselect
