import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import _get from "lodash/get"

import { Input } from "ui"
import { PRODUCT_TYPE_KEYS } from "ui/ChooseProduct/constants"

import styles from "./SearchBar.module.css"
import inputStyles from "./overrides/Input.css"

const SEARCH_INPUT_PLACEHOLDERS = {
    [PRODUCT_TYPE_KEYS.SERVICES]: "Search and add service...",
    [PRODUCT_TYPE_KEYS.PRODUCTS]: "Search and add product..."
}

const SearchBar = ({
    type,
    filterCount,
    filterSearchPhrase,
    handleChangeFilterSearchPhrase,
    setIsFilterExpanded,
    isFilterExpanded,
    disabled
}) => {
    return (
        <div className={styles.searchBar}>
            <Input
                name="search"
                label={_get(SEARCH_INPUT_PLACEHOLDERS, type, "")}
                isPlaceholder={true}
                customStyles={inputStyles}
                value={filterSearchPhrase}
                onChange={({ event }) => handleChangeFilterSearchPhrase(event)}
                autoComplete="off"
                readOnly={disabled}
                isClear
            />
            {type !== "services" && (
                <div
                    className={classNames(styles.searchFilterToggle, { [styles.expanded]: isFilterExpanded })}
                    onClick={() => setIsFilterExpanded(prevState => !prevState)}
                >
                    <span className={styles.label}>Filters</span>
                    {filterCount > 0 && <span className={styles.count}>{filterCount}</span>}
                </div>
            )}
        </div>
    )
}

SearchBar.propTypes = {
    type: PropTypes.string.isRequired,
    filterCount: PropTypes.number,
    filterSearchPhrase: PropTypes.string.isRequired,
    handleChangeFilterSearchPhrase: PropTypes.func.isRequired,
    setIsFilterExpanded: PropTypes.func.isRequired,
    isFilterExpanded: PropTypes.bool,
    disabled: PropTypes.bool
}

export default SearchBar
