import React from "react"
import withStyles from "HOC/withStyles"
import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"
import styles from "./ConfirmModal.css"

export const ConfirmModal = ({ isOpen, item, type, handleClose, handleConfirm, actionButtonLabel, isLoading }) => {
    return (
        isOpen && (
            <Modal>
                <Confirm
                    id={item.id}
                    actionButtonLabel={actionButtonLabel}
                    customStyles={styles}
                    confirmModalTitle="Delete this page?"
                    titleSecondLine={`Are you sure you want to delete the ${type} "${item.title}"? This action cannot be reversed.`}
                    handleHideModal={handleClose}
                    handleDelete={() => handleConfirm(item.id)}
                    isLoading={isLoading}
                />
            </Modal>
        )
    )
}

export default withStyles(ConfirmModal, styles)
