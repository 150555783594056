import React, { useEffect, Fragment, useState } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"

import { Dropzone } from "ui"

import styles from "./styles.css"
import dropzoneStyles from "../../../../overrides/dropzone.css"
import cloudSvg from "../../assets/cloud.svg"
import deleteSvg from "assets/delete.svg"

const Image = ({ cx, form, fieldName, uploader: { actions, state } }) => {
    const [filename, setFileName] = useState(null)

    useEffect(() => {
        if (idx(state, _ => _.addedFile.url)) {
            setFileName(state.addedFile.title)
            form.setFieldValue(fieldName, state.addedFile.url)
            form.setFieldValue(`${fieldName}Id`, state.addedFile.id)
        }
    }, [state.isUploading, state.isSuccess, state.addedFile])

    const removeLogo = () => {
        form.setFieldValue(fieldName, "")
        form.setFieldValue(`${fieldName}Id`, null)
        setFileName(null)
    }

    return (
        <div className={cx("root")}>
            {form.values[fieldName] ? (
                <div className={cx("imagePlaceholder")}>
                    <img src={form.values[fieldName]} alt="" className={cx("image")} />
                    <div className={cx("bar")}>
                        <span className={cx("filename")}>{filename}</span>
                        <span className={cx("delete")} onClick={removeLogo}>
                            <img src={deleteSvg} alt="remove" />
                        </span>
                    </div>
                </div>
            ) : (
                <Fragment>
                    <div className={cx(form.errors[fieldName] ? "error" : "")}>
                        <Dropzone
                            isUploading={state.isUploading}
                            handleUpload={actions.handleUpload}
                            customStyles={dropzoneStyles}
                        >
                            <img className={cx("cloudImage")} src={cloudSvg} alt="cloud" />
                            <p className={cx("dropzoneHeader")}>Upload image</p>
                            <p className={cx("dropzoneSubheader")}>or drop image to upload</p>
                        </Dropzone>
                    </div>
                    {state.isUploading && (
                        <div className={cx("progressBar")}>
                            <div style={{ width: `${state.progress}%` }} />
                        </div>
                    )}
                    {form.errors[fieldName] && <p className={cx("errorText")}>{form.errors[fieldName]}</p>}
                </Fragment>
            )}
        </div>
    )
}

Image.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    fieldName: PropTypes.string.isRequired,
    uploader: PropTypes.object.isRequired
}

export default withUploader(withStyles(Image, styles))
