import React, { useMemo } from "react"

import Select from "components/Reusable/Form/Select"
import { Input, Toggle } from "ui"

import withStyles from "HOC/withStyles"
import styles from "./Block.css"
import selectStyles from "./overrides/selectStyles.css"
import inputStyles from "./overrides/inputStyles.css"
import toggleStyles from "./overrides/toggleStyles.css"

import editIcon from "assets/pencil.svg"
import deleteIcon from "assets/close-blue.svg"

const impactSymbolValues = ["+", "=", "-"].map(symbol => ({ value: symbol, label: symbol }))

const Block = ({
    cx,
    level,
    attribute,
    updateCombination,
    deleteCombination,
    onEditClick,
    blockInputs,
    isTemplate
}) => {
    const { active, impact } = attribute.payload
    const [impactSymbol, impactValue] = useMemo(() => [impact[0], impact.slice(1)], [impact])

    return (
        <div className={cx("root")} style={{ marginLeft: level * 44 }}>
            {attribute.name}
            <div className={cx("optionsWrapper")}>
                <Select
                    isDisabled={blockInputs}
                    values={impactSymbolValues}
                    value={impactSymbol}
                    onClick={({ event }) => updateCombination({ impact: `${event.target.value}${impactValue}` })}
                    customStyles={selectStyles}
                />
                <Input
                    type="number"
                    isDisabled={blockInputs}
                    customStyles={inputStyles}
                    value={impactValue}
                    onChange={({ event }) => updateCombination({ impact: `${impactSymbol}${event.target.value}` })}
                />
                {!isTemplate ? (
                    <button type="button" className={cx("button", "edit")} disabled={blockInputs} onClick={onEditClick}>
                        <img src={editIcon} alt="" />
                    </button>
                ) : (
                    <div className={cx("edit")} />
                )}
                <button type="button" className={cx("button")} disabled={blockInputs} onClick={deleteCombination}>
                    <img src={deleteIcon} alt="" />
                </button>
                <Toggle
                    isDisabled={blockInputs}
                    customStyles={toggleStyles}
                    isOn={active}
                    handleChange={() => updateCombination({ active: !active })}
                />
            </div>
        </div>
    )
}

export default withStyles(Block, styles)
