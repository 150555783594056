export const ON_HOLD_FOR = {
    ONE_DAY: 1,
    TWO_DAYS: 2,
    SEVEN_DAYS: 7
}

const ON_HOLD_FOR_SELECT_OPTIONS = [
    { label: "24 hours", value: ON_HOLD_FOR.ONE_DAY },
    { label: "48 hours", value: ON_HOLD_FOR.TWO_DAYS },
    { label: "7 days", value: ON_HOLD_FOR.SEVEN_DAYS }
]

export default ON_HOLD_FOR_SELECT_OPTIONS
