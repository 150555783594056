import React, { useState, useEffect, Fragment } from "react"
import { connect } from "react-redux"
import { Switch, Route } from "react-router-dom"

import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"

import * as actions from "actions/homePage"
import * as notificationActions from "actions/notification"

import {
    NavigationList,
    CategoriesListHeader,
    CategoriesListRow,
    FiltersListHeader,
    FiltersListRow
} from "modules/ContentModule/components"
import Layout from "./components/Layout"
import Modal from "components/Modal/Modal"
import ConfirmModal from "components/Modal/Confirm/Confirm"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import confirmModalStyles from "./overrides/confirmModalStyles.css"
import styles from "./loader.css"

const Listing = props => {
    const {
        history,
        data,
        getData,
        match,
        isEdited,
        showSuccessNotification,
        showErrorNotification,
        updateCategories,
        cx
    } = props

    const { isLoading, data: categories } = data
    const { categoryId } = match.params

    const [isSaving, setIsSaving] = useState(false)
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)
    const [isResetToDefaultModalOpen, setIsResetToDefaultModalOpen] = useState(false)
    const [defaultFilters, setDefaultFilters] = useState(null)
    const [isUploadModelOpen, setIsUploadModelOpen] = useState(false)
    const [categoriesModal, setCategoriesModal] = useState({})
    const [currentCategory, setCurrentCategory] = useState({})
    const [parentCategory, setParentCategory] = useState({})
    const [isLoaded, setIsLoaded] = useState(false)

    const getCurrentCategoryCallback = data => {
        setCurrentCategory(data.data)
        if (!data.parentCategory) {
            setIsLoaded(true)
        }
    }

    const getParentCategoryCallback = data => {
        setParentCategory(data.data)
        setIsLoaded(true)
    }

    useEffect(() => {
        setIsLoaded(false)
        categoryId && getData(categoryId) && getCategory(categoryId, getCurrentCategoryCallback)
    }, [categoryId])

    useEffect(() => {
        if (categories.length) {
            const prepareCategories = categories.map(({ id, active }) => ({ id, active }))
            setCategoriesModal(prepareCategories)
        }
    }, [categories])

    useEffect(() => {
        if (currentCategory.parent_category) {
            getCategory(currentCategory.parent_category, getParentCategoryCallback)
        } else {
            setParentCategory({})
        }
    }, [currentCategory.parent_category])

    const toggleAddModal = () => setIsAddModalOpen(wasOpen => !wasOpen)
    const toggleResetToDefaultModal = () => setIsResetToDefaultModalOpen(wasOpen => !wasOpen)

    const handleSubmitSubcategories = list => {
        const body = list.map(({ id, active }) => ({ id, active }))
        updateCategoriesMethod({ body })
    }

    const handleSubmitFilters = list => {
        const { categoryId } = match.params
        const body = { name: currentCategory.name, filters: list.map(({ id }) => ({ id })) }

        setIsSaving(true)
        fetch
            .patch(`/categories/${categoryId}`, body)
            .then(() => getData(categoryId))
            .catch(() => showErrorNotification())
            .finally(() => setIsSaving(false))
    }

    const getDefaultFilters = () =>
        fetch
            .get("/filters?is_default=true")
            .then(data => setDefaultFilters(data.data))
            .catch(() => showErrorNotification())
            .finally(toggleResetToDefaultModal)

    const resetExternalList = () => setDefaultFilters(null)

    const handleUpdateCategories = category => {
        updateCategoriesMethod({ body: category })
    }

    const updateCategoriesMethod = body => {
        setIsSaving(true)
        updateCategories(body)
            .then(() => {
                showSuccessNotification()
                getData(categoryId)
            })
            .catch(() => showErrorNotification())
            .finally(() => {
                setIsSaving(false)
                isUploadModelOpen && setIsUploadModelOpen(false)
            })
    }

    const getCategory = (categoryId, callback) => {
        setIsLoaded(false)
        fetch
            .get(`/categories/${categoryId}`)
            .then(data => callback(data))
            .catch(() => showErrorNotification())
    }

    const renderList = () => (
        <Layout
            history={history}
            match={match}
            parentId={currentCategory && currentCategory.parent_category}
            title={currentCategory && currentCategory.name}
            clickAddButton={toggleAddModal}
            resetFilters={toggleResetToDefaultModal}
            parentCategoryTitle={parentCategory.name}
        >
            <Switch>
                <Route
                    path="/content/categories-menu/:categoryId/filters"
                    render={routeProps => (
                        <NavigationList
                            {...routeProps}
                            isLoading={isLoading}
                            storeList={currentCategory && currentCategory.filters}
                            isSaving={isSaving}
                            isEdited={isEdited}
                            handleSubmit={handleSubmitFilters}
                            ListHeader={FiltersListHeader}
                            ListRow={FiltersListRow}
                            isAddModalOpen={isAddModalOpen}
                            closeAddModal={toggleAddModal}
                            externalList={defaultFilters}
                            resetExternalList={resetExternalList}
                            isFilters
                        />
                    )}
                />
                <Route
                    render={routeProps => (
                        <NavigationList
                            {...routeProps}
                            isLoading={isLoading}
                            storeList={categories}
                            isSaving={isSaving}
                            isEdited={isEdited}
                            handleSubmit={handleSubmitSubcategories}
                            ListHeader={CategoriesListHeader}
                            ListRow={CategoriesListRow}
                            setIsUploadModelOpen={setIsUploadModelOpen}
                            isUploadModalOpen={isUploadModelOpen}
                            handleUpdateCategories={handleUpdateCategories}
                            setCategoriesModal={setCategoriesModal}
                            categoriesModal={categoriesModal}
                            isMainCategory={false}
                        />
                    )}
                />
            </Switch>
            {isResetToDefaultModalOpen && (
                <Modal>
                    <ConfirmModal
                        customStyles={confirmModalStyles}
                        handleHideModal={toggleResetToDefaultModal}
                        handleDelete={getDefaultFilters}
                        confirmModalTitle="Reset filters to default."
                        titleSecondLine="Do you want to reset filters to default?"
                        actionButtonLabel="Reset"
                    />
                </Modal>
            )}
        </Layout>
    )

    return (
        <Fragment>
            {!isLoaded && (
                <div className={cx("loader-wrapper")}>
                    <SnakeLoader />
                </div>
            )}
            {isLoaded && renderList()}
        </Fragment>
    )
}

const mapStateToProps = state => ({
    data: state.db.menuCategories.current
})

const mapDispatchToProps = dispatch => ({
    getData: parentCategoryId => dispatch(actions.getCategories({ parentCategoryId })),
    updateCategories: data => dispatch(actions.updateCategories(data)),
    getDataSuccess: data => dispatch(actions.getHomePageSuccess(data)),
    updateHomePage: data => dispatch(actions.updateHomePage(data)),
    showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
    showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(Listing, styles))
