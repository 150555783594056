import React, { Component } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withFormik } from "formik"
import idx from "idx"
import { Button } from "ui"
import { toApiDate } from "helpers/date"

import ListingLayout from "components/Reusable/Layout/index"
import List from "./List"

import * as projectActions from "actions/projects"

import createNewButtonStyles from "./overrides/CreateNewButton.css"
import layoutStyles from "./overrides/Layout.css"

import { LENGTH_WITHOUT_PAGINATION } from "constants/index"
const INIT_MORE_FILTERS = {
    users: [],
    deadline: "",
    showrooms: [],
    installation: "",
    client: "",
    consignment: "",
    brands: [],
    isOnlyMyProjects: false
}

class ListContainer extends Component {
    state = {
        query: "",
        statuses: [],
        created_at: { startDate: "", endDate: "" },
        page: 1,
        sort: {
            by: "name",
            direction: "asc"
        },
        isVisibleMoreFilters: false,
        isRestored: false
    }

    componentDidMount() {
        this.restoreFilters()
    }

    render() {
        const { created_at, statuses, page, isVisibleMoreFilters, query, isRestored } = this.state
        const { projects, values: moreFilters, isTabView } = this.props

        if (isTabView && isRestored) {
            return (
                <List
                    page={page}
                    query={query}
                    statuses={statuses}
                    projects={projects}
                    created_at={created_at}
                    moreFilters={moreFilters}
                    handleChangePage={this.handleChangePage}
                    handleChangeTable={this.handleChangeTable}
                    handleChangeParam={this.handleChangeParam}
                    handleActionMoreFilters={this.handleActionMoreFilters}
                    isVisibleMoreFilters={isVisibleMoreFilters}
                    toggleVisibleMoreFilters={this.toggleVisibleMoreFilters}
                    clearAllFilters={this.clearAllFilters}
                    handleOnlyMyProjects={this.handleOnlyMyProjects}
                    isTabView
                />
            )
        }

        return (
            <ListingLayout
                title="Projects"
                cta={[
                    <Link to="/projects/add">
                        <Button label={"Create New"} customStyles={createNewButtonStyles} />
                    </Link>
                ]}
                customStyles={layoutStyles}
            >
                {isRestored && (
                    <List
                        page={page}
                        query={query}
                        statuses={statuses}
                        projects={projects}
                        created_at={created_at}
                        moreFilters={moreFilters}
                        handleChangePage={this.handleChangePage}
                        handleChangeTable={this.handleChangeTable}
                        handleChangeParam={this.handleChangeParam}
                        handleActionMoreFilters={this.handleActionMoreFilters}
                        isVisibleMoreFilters={isVisibleMoreFilters}
                        toggleVisibleMoreFilters={this.toggleVisibleMoreFilters}
                        clearAllFilters={this.clearAllFilters}
                        handleOnlyMyProjects={this.handleOnlyMyProjects}
                    />
                )}
            </ListingLayout>
        )
    }

    restoreFilters = async () => {
        const { location, activeClient, isActiveSession, setFieldValue, initialFilters, setValues } = this.props

        if (location.state && location.state.fromOverview) {
            // TODO: dodac kiedy filterableTable bedzie pozwalalo przekazac initValues
            // this.setState({ query: filtersFromStore.query})
            // await setValues({ ...INIT_MORE_FILTERS, ...filtersFromStore.moreFilters })
        }

        if (initialFilters) {
            await setValues({ ...INIT_MORE_FILTERS, ...initialFilters })
        }

        if ((!location.state || !location.state.fromOverview) && isActiveSession) {
            await setFieldValue("client", { id: activeClient.id, first_name: activeClient.name, last_name: "" })
        }

        this.setState({ isRestored: true })
    }

    handleActionMoreFilters = async type => {
        const { isVisibleMoreFilters } = this.state

        if (type === "submit") {
            this.fetchProjects()
        }

        if (type === "unsave" && isVisibleMoreFilters) {
            this.resetForm()
        }

        if (type === "clear") {
            await this.clearForm()
            this.fetchProjects()
        }

        this.toggleVisibleMoreFilters()
    }

    clearForm = async () => {
        const { setValues } = this.props

        await setValues(INIT_MORE_FILTERS)
    }

    resetForm = async () => {
        const { setValues, filters: filtersFromStore } = this.props

        setValues(filtersFromStore.moreFilters)
    }

    toggleVisibleMoreFilters = () => {
        this.setState(prevState => ({ isVisibleMoreFilters: !prevState.isVisibleMoreFilters }))
    }

    clearAllFilters = () => {
        const { reloadProjects } = this.props

        this.setState(
            {
                query: "",
                statuses: [],
                created_at: { startDate: "", endDate: "" }
            },
            async () => {
                await this.clearForm()
                reloadProjects()
            }
        )
    }

    fetchProjects = () => {
        const { getProjects, saveFilters, values: moreFilters, isTabView } = this.props
        const { page, sort, query, statuses, created_at } = this.state

        const toApiArray = (values, onlyId) =>
            Array.isArray(values) && values.map(item => (onlyId ? item.id : item)).join(",")
        const toApiDateRange = (key, date) => ({
            [key + "_from"]: date && date.startDate && toApiDate(date.startDate),
            [key + "_to"]: date && date.endDate && toApiDate(date.endDate)
        })
        const filterNull = values =>
            Object.keys(values).reduce((agg, key) => {
                return values[key] ? { ...agg, [key]: values[key] } : agg
            }, {})

        const body = {
            page,
            length: isTabView ? LENGTH_WITHOUT_PAGINATION : 20,
            sort_by: sort.by,
            sort_direction: sort.direction,
            query,
            statuses: toApiArray(statuses, true),
            users: toApiArray(moreFilters.users, true),
            showrooms: toApiArray(moreFilters.showrooms, true),
            brands: toApiArray(moreFilters.brands, true),
            client_id: idx(moreFilters.client, _ => _.id),
            consignment: moreFilters.consignment,
            ...toApiDateRange("created", created_at),
            ...toApiDateRange("deadline", moreFilters.deadline),
            ...toApiDateRange("installation", moreFilters.installation)
        }
        const bodyWithoutNull = filterNull(body)

        saveFilters({ ...this.state, moreFilters })
        getProjects(bodyWithoutNull)
    }

    handleChangeParam = param => {
        this.setState({ page: 1, ...param }, () => this.fetchProjects())
    }

    handleChangePage = page => {
        this.setState({ page }, () => this.fetchProjects())
    }

    handleChangeTable = (params, isInit) => {
        this.setState(
            {
                sort: params.sorting,
                query: params.search,
                page: 1
            },
            () => this.fetchProjects(isInit)
        )
    }

    handleOnlyMyProjects = async (state, isFromToggle) => {
        const { setFieldValue, userId, userName } = this.props

        await setFieldValue("isOnlyMyProjects", state)

        if (isFromToggle) {
            await setFieldValue(
                "users",
                state ? [{ id: parseInt(userId, 10), label: userName, value: {}, secondLabel: "" }] : []
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        projects: state.db.projects.list,
        filters: state.ui.projects.list.filters,
        activeClient: state.ui.session.activeClient,
        isActiveSession: state.ui.session.activeSession,
        userRoles: state.loginStore.roles,
        userId: state.loginStore.userId,
        userName: state.loginStore.userName
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProjects: params => dispatch(projectActions.getProjects(params)),
        saveFilters: filters => dispatch(projectActions.saveFiltersListProjects(filters)),
        reloadProjects: () => dispatch(projectActions.reloadProjects())
    }
}

export default compose(
    withRouter,
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    withFormik({
        displayName: "Filters projects list",
        mapPropsToValues: () => INIT_MORE_FILTERS,
        handleSubmit: () => {}
    })
)(ListContainer)
