import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import debounce from "lodash.debounce"

import * as fetchStatusHelper from "helpers/fetchStatus"
import * as orderActions from "./actions"
import ChooseOrder from "./ChooseOrder"

let debounceFilterSearchPhrase

const ChooseOrderContainer = props => {
    const { orders, filters, actions, handleSelect } = props

    const [filterSearchPhrase, setFilterSearchPhrase] = useState("")
    const [filterDebouncedSearchPhrase, setFilterDebouncedSearchPhrase] = useState("")
    const [selectedOrder, setSelectedOrder] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        debounceFilterSearchPhrase = debounce(setFilterDebouncedSearchPhrase, 350)

        return () => {
            debounceFilterSearchPhrase.cancel()
        }
    }, [])

    useEffect(() => {
        if (actions.shouldFetch(orders)) {
            actions.fetchOrders(getCurrentFilters())
        }
    }, [orders])

    useEffect(() => {
        debounceFilterSearchPhrase(filterSearchPhrase)
    }, [filterSearchPhrase])

    useEffect(() => {
        actions.fetchOrders(getCurrentFilters())
    }, [filterDebouncedSearchPhrase])

    useEffect(() => {
        return () => {
            actions.clearState()
        }
    }, [])

    const handleLoadMoreOrders = () => {
        const canLoadMore = !fetchStatusHelper.fromResource({ fetchStatus: orders.nextFetchStatus }).isLoading

        if (canLoadMore) {
            const params = { ...getCurrentFilters(), page: orders.meta.current_page + 1 }
            actions.fetchMoreOrders(params)
        }
    }

    function getCurrentFilters() {
        const params = {}

        if (filterSearchPhrase) {
            params.query = filterDebouncedSearchPhrase
        }

        params.type = "sales"

        return { ...params, ...filters }
    }

    const handleSelectOrder = order => {
        if (!order) {
            return setSelectedOrder(null)
        }

        setSelectedOrder(order)
        handleSelect({ order_id: order.id })
    }

    return (
        <ChooseOrder
            selectedOrder={selectedOrder}
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            orders={orders}
            filterSearchPhrase={filterSearchPhrase}
            hasMoreOrders={orders.meta.last_page !== orders.meta.current_page}
            handleChangeFilterSearchPhrase={setFilterSearchPhrase}
            handleLoadMoreOrders={handleLoadMoreOrders}
            handleSelectOrder={handleSelectOrder}
        />
    )
}

const mapStateToProps = state => {
    return {
        orders: state.ui.modals.chooseOrder.orders.list
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...orderActions
            },
            dispatch
        )
    }
}

ChooseOrderContainer.defaultProps = {
    filters: {}
}

ChooseOrderContainer.propTypes = {
    handleSelect: PropTypes.func,
    filters: PropTypes.object
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseOrderContainer)
