import React, { Fragment, useEffect } from "react"
import { Field, Formik } from "formik"
import * as Yup from "yup"

import withStyles from "HOC/withStyles"

import { Modal as ModalComponent, Button } from "ui"

import { Input } from "components/Reusable/Form"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import Image from "../MainPageImmediateDeliveryProductUpload"

import styles from "./styles.css"
import inputOverrides from "./overrides/input.css"
import modalOverrides from "../MainPageNameSectionEditModal/overrides/modalOverrides.css"

import { INPUT_MAX_LENGTH } from "constants/index"

const IMAGE_DIMENSIONS = {
    width: 1390,
    height: 782
}

const schema = Yup.object().shape({
    title: Yup.string()
        .max(INPUT_MAX_LENGTH, `Title may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .required("This field is required"),
    location: Yup.string()
        .max(INPUT_MAX_LENGTH, `Location may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .nullable(),
    cover_desktop: Yup.object()
        .required("Desktop cover is required")
        .typeError("Desktop cover is required")
})

const Modal = props => {
    const { cx, handleClose, data, onSubmit, handleError, isSaving } = props

    useEffect(() => {
        const { getRealization, realizationId } = props
        getRealization(realizationId)
    }, [])

    const handleErrors = (form, errors) => {
        form.setErrors(errors.errors)
        handleError()
    }

    const handleSubmit = (values, form) => {
        const parsedValues = {
            ...values,
            cover_desktop: values.cover_desktop && values.cover_desktop.id,
            cover_tablet: values.cover_tablet && values.cover_tablet.id,
            cover_mobile: values.cover_mobile && values.cover_mobile.id
        }
        onSubmit(data.data.id, parsedValues, null, errors => handleErrors(form, errors))
    }

    return (
        <ModalComponent isOpen={true} closeModal={handleClose} preventClickOutside={true}>
            <ModalComponent.Header>Edit project</ModalComponent.Header>
            {data.isLoading && (
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            )}

            {data.isLoading === false && (
                <Formik
                    initialValues={data.data}
                    validateOnChange={true}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {({ handleSubmit, isSubmitting, setFieldValue, errors, touched, values }) => {
                        return (
                            <Fragment>
                                <ModalComponent.Body>
                                    <Fragment>
                                        <div className={cx("row")}>
                                            <div className={cx("realizationCover")}>
                                                <span className={cx("label")}>Project cover:</span>
                                                <Image
                                                    fieldName="cover_desktop"
                                                    form={{
                                                        values,
                                                        errors,
                                                        setFieldValue
                                                    }}
                                                    imageDimensions={IMAGE_DIMENSIONS}
                                                />
                                            </div>
                                        </div>
                                        <div className={cx("row")}>
                                            <Field name="title">
                                                {({ field }) => {
                                                    return (
                                                        <Input
                                                            label="Project name"
                                                            customStyles={inputOverrides}
                                                            error={touched.title && errors.title}
                                                            value={values.title}
                                                            onChange={value => {
                                                                setFieldValue(field.name, value.event.target.value)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                        <div className={cx("row")}>
                                            <Field name="location">
                                                {({ field }) => {
                                                    return (
                                                        <Input
                                                            label="Location"
                                                            customStyles={inputOverrides}
                                                            error={touched.location && errors.location}
                                                            value={values.location}
                                                            onChange={value => {
                                                                setFieldValue(field.name, value.event.target.value)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                    </Fragment>
                                </ModalComponent.Body>
                                <ModalComponent.Actions customStyles={modalOverrides}>
                                    <button className={cx("cancel-button")} onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <Button
                                        className="first-button"
                                        onClick={handleSubmit}
                                        label={"Save"}
                                        isLoading={isSaving}
                                    />
                                </ModalComponent.Actions>
                            </Fragment>
                        )
                    }}
                </Formik>
            )}
        </ModalComponent>
    )
}

export default withStyles(Modal, styles)
