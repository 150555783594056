import React, { Component } from "react"
import { connect } from "react-redux"
import fetch from "helpers/fetch"

import EditableList from "components/Reusable/EditableList"
import config from "./config"

import ListingLayout from "../../ListingLayout/index"

import * as actions from "actions/combinationTemplates"

class Listing extends Component {
    componentDidMount() {
        const { getCombinations } = this.props
        getCombinations()
    }

    handleDeleteCombinationTemplate = id => {
        const { getCombinations } = this.props
        fetch.deleteRAW(`/combination-templates/${id}`).then(() => {
            getCombinations()
        })
    }

    render() {
        const { combinationTemplates, isLoading } = this.props
        return (
            <ListingLayout>
                <EditableList
                    config={config}
                    list={combinationTemplates}
                    listTitle={"Combinations Templates"}
                    confirmModalTitle={"Are you sure you want to delete this combinations?"}
                    editUrlPrefix={"/settings/combination-template/card"}
                    addUrl={"/settings/combination-template/add"}
                    handleDeleteEnableCondition={values => true}
                    isLoading={isLoading}
                    deleteElement={this.handleDeleteCombinationTemplate}
                    handleContentLink={id => `/settings/combination-template/card/${id}`}
                />
            </ListingLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        combinationTemplates: state.db.combinationTemplates.list.data,
        isLoading: state.db.combinationTemplates.list.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCombinations: () => dispatch(actions.getCombinationTemplates())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing)
