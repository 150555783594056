import React, { Component, Fragment } from "react"
import ReactGridLayout from "react-grid-layout"

import Dropzone from "ui/Dropzone/index"
import withStyles from "HOC/withStyles"
import { Button, Cropper, ReloadableImageWrap } from "ui"
import Image from "../Image"
import { UploadBar } from "components/Reusable/FilesUploader/FilesUploader"
import { IMAGE_ACCEPTED_FORMATS } from "constants/index"

import styles from "./GalleryUploader.css"
import uploadButtonStyles from "./overrides/UploadButton.css"
import uploadDropzoneStyles from "./overrides/UploadDropzone.css"
import topDropdownStyles from "./overrides/TopDropdown.css"

import cloud from "assets/cloud.svg"

class GalleryUploader extends Component {
    constructor(props) {
        super(props)
        this.state = { cropImageId: null }
    }

    static defaultProps = {
        acceptFormats: `${IMAGE_ACCEPTED_FORMATS},video/*`
    }

    render() {
        const {
            cx,
            className,
            uploader,
            allFiles,
            withoutButton,
            withTopDropdown,
            isImageDragging,
            acceptFormats,
            onCropSuccess,
            uploader: { state, actions }
        } = this.props
        const isNotEmptyGallery = allFiles.length > 0 || uploader.state.isUploading

        return (
            <div className={cx("root", className, { isNotEmptyGallery })}>
                {withTopDropdown && isNotEmptyGallery && (
                    <Dropzone
                        disabled={isImageDragging}
                        isUploading={state.isUploading}
                        isSuccess={state.isSuccess}
                        progress={state.progress}
                        handleUpload={actions.handleUpload}
                        handleRemove={actions.handleRemove}
                        isMultiple={true}
                        activeClassName={cx("canDrop")}
                        acceptFormats={acceptFormats}
                        customStyles={topDropdownStyles}
                    >
                        <UploadBar header="Add images" />
                    </Dropzone>
                )}
                {isNotEmptyGallery && this.renderHeader()}
                <div className={cx("cover", { isNotEmptyGallery })}>
                    {!withoutButton && this.renderAddMediaButton()}
                    {this.renderDropZone()}
                </div>

                {!!this.state.cropImageId && (
                    <Cropper
                        onClose={() => this.setState({ cropImageId: null })}
                        mediaId={this.state.cropImageId}
                        onSuccess={onCropSuccess}
                    />
                )}
            </div>
        )
    }

    renderHeader = () => {
        const { cx, allFiles, handleDeleteImage } = this.props

        return (
            <div className={cx("images-info")}>
                {allFiles.length > 0 && (
                    <Fragment>
                        <p className={cx("images-count")}>{allFiles.length} media added</p>
                        <p className={cx("delete-images")} onClick={() => handleDeleteImage("all")}>
                            Delete all
                        </p>
                    </Fragment>
                )}
            </div>
        )
    }

    renderAddMediaButton = () => {
        const {
            cx,
            allFiles,
            uploader,
            acceptFormats,
            isImageDragging,
            uploader: { state, actions }
        } = this.props
        const isAnyPhoto = allFiles.length > 0
        const isLoadingPhoto = uploader.state.isUploading

        return (
            <Dropzone
                disabled={isImageDragging}
                isUploading={state.isUploading}
                isSuccess={state.isSuccess}
                progress={state.progress}
                handleUpload={actions.handleUpload}
                handleRemove={actions.handleRemove}
                isMultiple={true}
                acceptFormats={acceptFormats}
                customStyles={uploadButtonStyles}
            >
                <Button className={cx("first-button", { isLoadingPhoto, isAnyPhoto })} label="Add Media" />
            </Dropzone>
        )
    }

    renderDropZone = () => {
        const {
            cx,
            allFiles,
            isImageDragging,
            acceptFormats,
            uploader: { state, actions }
        } = this.props

        return (
            <Dropzone
                disabled={isImageDragging}
                isUploading={state.isUploading}
                isSuccess={state.isSuccess}
                progress={state.progress}
                handleUpload={actions.handleUpload}
                handleRemove={actions.handleRemove}
                disableClick={allFiles.length !== 0}
                isMultiple={true}
                activeClassName={cx("canDrop")}
                acceptFormats={acceptFormats}
                customStyles={uploadDropzoneStyles}
            >
                {allFiles.length === 0 && this.renderPlaceholder()}
                {this.renderGrid()}
            </Dropzone>
        )
    }

    renderPlaceholder = () => {
        const {
            cx,
            placeholder,
            uploader: { state }
        } = this.props

        return (
            <div className={cx("images")}>
                {!state.isUploading && (
                    <div className={cx("imagesEmptyInfo")}>
                        <img src={cloud} alt="move" />
                        <p>
                            {(placeholder && placeholder.title) ||
                                "Drag and drop your files here or click button Add Media"}
                        </p>
                        <p>{(placeholder && placeholder.subtitle) || "(only .jpg, .png, .tiff or video files)"}</p>
                    </div>
                )}
            </div>
        )
    }

    renderGrid = () => {
        const { gridLayout, handleLayoutChange, gallerySetup } = this.props

        return (
            <ReactGridLayout
                className="layout"
                onLayoutChange={handleLayoutChange}
                cols={gallerySetup.COLS}
                layout={gridLayout}
                rowHeight={191}
                width={gallerySetup.WIDTH}
                compactType="horizontal"
                isResizable={false}
            >
                {this.renderUploadedFiles()}
                {this.renderUploadingFiles()}
            </ReactGridLayout>
        )
    }

    renderUploadedFiles = () => {
        const { allFiles, handlePassUploadedImages, handleDeleteImage, mainImageId } = this.props

        return allFiles.map(({ title, id, url, name }) => (
            <div key={id}>
                <ReloadableImageWrap image={url}>
                    {({ image }) => (
                        <Image
                            onCrop={() => this.setState({ cropImageId: id })}
                            mainImageLabel={Number(mainImageId) === id}
                            handlePassUploadedImages={handlePassUploadedImages}
                            id={id}
                            path={image}
                            title={title || name}
                            handleDeleteImage={handleDeleteImage}
                        />
                    )}
                </ReloadableImageWrap>
            </div>
        ))
    }

    renderUploadingFiles = () => {
        const {
            cx,
            allFiles,
            uploadingFiles,
            uploader: { state },
            createGridLayoutItem
        } = this.props

        const addedFile = state.addedFile

        return addedFile.map((file, fileIndex) => {
            const key = "dropzone-" + file.title
            const index = allFiles.length + fileIndex

            if (file.progress === 100 && uploadingFiles[fileIndex]) {
                return (
                    <div key={key}>
                        <Image key={key} path={uploadingFiles[fileIndex].path} title={file.title} />
                    </div>
                )
            }

            return (
                <div key={key} data-grid={createGridLayoutItem(key, index)}>
                    <div className={cx("images")}>
                        {(file.progress <= 100 || !uploadingFiles[fileIndex]) && (
                            <div key={file.title} className={cx("progress-background")}>
                                <div className={cx("progress")}>
                                    <p>{file.progress}%</p>
                                    <span style={{ width: `${file.progress}%` }} />
                                </div>
                                <h6>{file.title}</h6>
                            </div>
                        )}
                    </div>
                </div>
            )
        })
    }
}

export default withStyles(GalleryUploader, styles)
