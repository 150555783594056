import React, { Component } from "react"
import { connect } from "react-redux"
import moment from "moment"
import * as calendarActions from "modules/CalendarModule/actions"
import * as userActions from "actions/users"
import * as clientActions from "actions/client"
import { scrollToTop } from "helpers/scrollTo"
import * as notificationActions from "actions/notification"

import Calendar from "./Calendar"

class CalendarContainer extends Component {
    constructor(props) {
        super(props)
        this.calendarRef = React.createRef()
    }

    componentDidMount() {
        const { getCalendarsList, setEntryFilters, setEntryQuery, calendar, userId, userName, userRoles } = this.props
        const hasSuperAdmin = userRoles.some(role => role.name === "SuperAdmin")

        if (calendar.entryFilters.isInitial) {
            setEntryFilters({
                users: hasSuperAdmin ? [] : [{ id: parseInt(userId, 10), label: userName, value: {}, secondLabel: "" }],
                client: "",
                project: "",
                divisions: [],
                sources: [],
                isOnlyMine: !hasSuperAdmin
            })
        }

        setEntryQuery("")
        getCalendarsList()
        scrollToTop()
    }

    componentWillUnmount() {
        this.props.clearCalendarEntries()
    }

    handleChangeDateEvent = (event, newDate) => {
        const {
            editCalendarEntry,
            showErrorNotification,
            calendar: {
                list: { data }
            }
        } = this.props

        const selectedCalendars = [...data.private, ...data.public]
            .filter(calendar => calendar.is_selected)
            .map(calendar => calendar.id)

        const body = {
            id: event.id,
            calendar_id: event.calendar_id,
            starts_at: moment(newDate.starts_at, "YYYY-MM-DD HH:mm:ss a").format("YYYY-MM-DD HH:mm:ss"),
            is_all_day: newDate.isAllDay
        }

        if (newDate.ends_at) {
            body.ends_at = moment(newDate.ends_at, "YYYY-MM-DD HH:mm:ss a").format("YYYY-MM-DD HH:mm:ss")
        }

        if (!newDate.ends_at && event.ends_at) {
            body.ends_at = moment(newDate.starts_at, "YYYY-MM-DD HH:mm:ss a").format("YYYY-MM-DD HH:mm:ss")
        }

        editCalendarEntry(event.id, body, selectedCalendars, false).catch(() =>
            showErrorNotification("Error changing the date of the event")
        )
    }

    render() {
        return (
            <Calendar
                {...this.props}
                calendarRef={this.calendarRef}
                handleChangeDateEvent={this.handleChangeDateEvent}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        calendar: state.db.calendar,
        users: state.db.users,
        clients: state.clientStore.clients,
        userId: state.loginStore.userId,
        userName: state.loginStore.userName,
        userRoles: state.loginStore.roles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCalendarsList: () => dispatch(calendarActions.getCalendarsList()),
        createCalendar: data => dispatch(calendarActions.createCalendar(data)),
        editCalendar: (id, data) => dispatch(calendarActions.editCalendar(id, data)),
        getUsersList: () => dispatch(userActions.getUsersList()),
        getEntries: data => dispatch(calendarActions.getEntries(data)),
        setEntryQuery: query => dispatch(calendarActions.setEntryQuery(query)),
        setEntryParams: params => dispatch(calendarActions.setEntryParams(params)),
        setEntryFilters: filters => dispatch(calendarActions.setEntryFilters(filters)),
        showErrorNotification: data => dispatch(notificationActions.showErrorNotification(data)),
        createCalendarEntry: (data, calendars, isWillRefreshAfter) =>
            dispatch(calendarActions.createCalendarEntry(data, calendars, isWillRefreshAfter)),
        editCalendarEntry: (id, data, calendars, isClearPrevData) =>
            dispatch(calendarActions.editCalendarEntry(id, data, calendars, isClearPrevData)),
        getClients: data => dispatch(clientActions.getClients(data)),
        deleteCalendarEntry: (calendarId, entryId, calendars) =>
            dispatch(calendarActions.deleteCalendarEntry(calendarId, entryId, calendars)),
        deleteCalendar: (id, isPublic) => dispatch(calendarActions.deleteCalendar(id, isPublic)),
        clearCalendarEntries: () => dispatch(calendarActions.clearCalendarEntries())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CalendarContainer)
