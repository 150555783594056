import { useActions } from "hooks"
import { useSelector } from "react-redux"
import { shouldFetch, fetchProduct, clearProductState } from "components/Modal/ChooseProduct/actions"

const useProduct = () => {
    const chooseProductModalStore = useSelector(state => state.ui.modals.chooseProduct)
    const actions = useActions({ shouldFetch, fetchProduct, clearProductState })

    return { ...actions, product: chooseProductModalStore.products.current }
}

export default useProduct
