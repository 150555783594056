import { useState, useEffect } from "react"

const BREAK_POINT_TABLET = 1024

export const useIsBreakpoint = breakpoint => {
    const [isBreakpoint, setIsBreakpoint] = useState(window.innerWidth <= breakpoint)

    const mediaQuery = window.matchMedia(`(max-width: ${breakpoint}px)`)

    useEffect(() => {
        const mediaQueryListener = e => setIsBreakpoint(e.matches)

        setIsBreakpoint(mediaQuery.matches)

        typeof mediaQuery.addEventListener === "function"
            ? mediaQuery.addEventListener("change", mediaQueryListener)
            : mediaQuery.addListener(mediaQueryListener)

        return () =>
            typeof mediaQuery.removeEventListener === "function"
                ? mediaQuery.removeEventListener("change", mediaQueryListener)
                : mediaQuery.removeListener(mediaQueryListener)
    }, [])

    return isBreakpoint
}

export const useIsTablet = () => useIsBreakpoint(BREAK_POINT_TABLET)
