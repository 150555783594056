import React, { Fragment } from "react"

import { Block } from "./components"

import { useCombinationsCreator } from "hooks"

const Tree = ({ isTemplate, blockInputs }) => {
    const [{ tree }, actions] = useCombinationsCreator()
    return <div>{renderTreeBranches(tree)}</div>

    function renderTreeBranches(tree, level = 0, parentAccessor = []) {
        if (!tree) {
            return null
        }

        return (
            <Fragment>
                {Object.entries(tree).map(([attributeId, attribute]) => {
                    const accessor = [...parentAccessor, attributeId]
                    const updateCombination = newValue => actions.updateCombination({ accessor, newValue })
                    const deleteCombination = () => actions.deleteCombination(accessor)
                    const onEditClick = () => actions.setEditedCombination(accessor)
                    return (
                        <Fragment key={attributeId + "_" + level}>
                            <Block
                                attribute={attribute}
                                level={level}
                                isCombinationTemplate={false}
                                updateCombination={updateCombination}
                                deleteCombination={deleteCombination}
                                onEditClick={onEditClick}
                                isTemplate={isTemplate}
                                blockInputs={blockInputs}
                            />
                            {renderTreeBranches(attribute.children, level + 1, accessor)}
                        </Fragment>
                    )
                })}
            </Fragment>
        )
    }
}

export default Tree
