import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getBlogPostStart() {
    return {
        type: constants.GET_BLOG_POST_START
    }
}
export function getBlogPostSuccess(data) {
    return {
        type: constants.GET_BLOG_POST_SUCCESS,
        payload: {
            data
        }
    }
}
export function getBlogPostError(error) {
    return {
        type: constants.GET_BLOG_POST_ERROR,
        payload: {
            error
        }
    }
}
export function getBlogPost(id) {
    return dispatch => {
        dispatch(getBlogPostStart())
        fetch
            .get(`/posts/${id}`)
            .then(data => dispatch(getBlogPostSuccess(data)))
            .catch(error => dispatch(getBlogPostError(error)))
    }
}

export function clearBlogPost() {
    return {
        type: constants.CLEAR_BLOG_POST
    }
}
