import React from "react"
import { Link } from "react-router-dom"
import getStyles from "helpers/getCxFromStyles"
import styles from "./styles.css"

const Breadcrumbs = ({ items }) => {
    const cx = getStyles(styles)

    return (
        <ul className={cx("root")}>
            {items.map(({ link, text }, index) => (
                <li className={cx("breadcrumb", { disabled: !link })} key={index}>
                    {link ? <Link to={link}>{text}</Link> : <span>{text}</span>}
                </li>
            ))}
        </ul>
    )
}

export default Breadcrumbs
