import * as constants from "constants/index"

function productionYear(state = { leftValue: "", rightValue: "" }, action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_PRODUCTIONYEAR:
            return { leftValue: "", rightValue: "" }
        case constants.CHANGE_PRODUCTIONYEAR:
            return { ...value }
        default:
            return state
    }
}

export default productionYear
