import React, { useState, useEffect, useMemo, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import PropTypes from "prop-types"

import Select from "ui/Select"
import AddTagModal from "./AddTagModal"

import { getTags } from "actions/tags"

const TagsSelect = props => {
    const { isListOnTop, label, tagId, error, setTag } = props

    const dispatch = useDispatch()

    const [isModalOpen, setIsModalOpen] = useState(false)
    const { data, isLoading } = useSelector(state => state.db.tags.list)

    useEffect(() => {
        dispatch(getTags())
    }, [])

    const toggleModal = () => setIsModalOpen(wasOpen => !wasOpen)

    const value = useMemo(() => (data.find(({ id }) => id === tagId) || {}).name, [data, tagId])
    const values = useMemo(() => data.map(({ id, name }) => ({ value: id, label: name })), [data])

    return (
        <Fragment>
            <Select
                withSearch
                isListOnTop={isListOnTop}
                isDisabled={isLoading}
                label={label}
                placeholder="- Select tag -"
                emptyListLabel="No tags"
                value={value}
                values={values}
                error={error}
                setValue={({ value }) => setTag(value)}
                additionalButtonLabel="Add tag"
                additionalButtonAction={toggleModal}
            />
            {isModalOpen && <AddTagModal closeModal={toggleModal} onSuccess={setTag} />}
        </Fragment>
    )
}

TagsSelect.propTypes = {
    isListOnTop: PropTypes.bool,
    label: PropTypes.string,
    tagId: PropTypes.number,
    error: PropTypes.string,
    setTag: PropTypes.func.isRequired
}

export default TagsSelect
