import React from "react"
import { useLocation, useHistory } from "react-router-dom"
import _get from "lodash/get"
import { H1, Panel, SnakeLoader } from "@butterfly-frontend/ui"

import { useActions } from "hooks"
import { showSuccessNotification } from "actions/notification"
import { Header } from "modules/WmsModule/components"
import { useStockItemDetails } from "modules/WmsModule/hooks/api/useStockItem"

import StockItemAdjustmentForm from "./StockItemAdjustmentForm"
import styles from "./StockAdjustmentAddPage.module.css"

const DEFAULT_RETURN_PATH = "/wms/stock-management"

const StockAdjustmentAddPage = () => {
    const location = useLocation()
    const history = useHistory()
    const actions = useActions({ showSuccessNotification })

    const redirectToSourcePage = () => history.push(_get(location, "state.returnPath", DEFAULT_RETURN_PATH))
    const stockItemsToAdjust = _get(location, "state.stockItemsToAdjust", [])

    const { data, fetchStatus } = useStockItemDetails({ id: stockItemsToAdjust[0].id })

    return (
        <div className={styles.stockAdjustmentPage}>
            <Header>
                <H1 withLeftBorder>Stock Adjustment</H1>
            </Header>
            <Panel>
                <div className={styles.panel}>
                    {fetchStatus.isLoaded ? (
                        <StockItemAdjustmentForm
                            stockItem={data}
                            onSuccess={() => {
                                actions.showSuccessNotification({ title: "Stock adjustment added successfully" })
                                redirectToSourcePage()
                            }}
                            onCancel={redirectToSourcePage}
                        />
                    ) : (
                        <SnakeLoader />
                    )}
                </div>
            </Panel>
        </div>
    )
}

export default StockAdjustmentAddPage
