import React, { useMemo } from "react"
import _get from "lodash/get"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { H2Counter } from "@butterfly-frontend/ui"

import { useShipmentList, KEY as SHIPMENT_QUERY_KEY } from "modules/WmsModule/hooks/api/useShipment"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import useChangeShipmentStatusOnListCache from "modules/WmsModule/hooks/cache/useChangeShipmentStatusOnListCache"

import { List } from "./components"
import styles from "./Returns.module.css"
import useRemoveReturnedStockItemsFromOrder from "./hooks/useRemoveReturnedStockItemsFromOrder"

const Returns = ({ order }) => {
    const { state } = useLocation()
    const shipmentListParams = useMemo(
        () => ({
            orders: order.id,
            type: SHIPMENT_TYPE_KEYS.CLIENT_RETURN,
            length: 9999
        }),
        [order.id]
    )
    const shipmentList = useShipmentList({
        params: shipmentListParams
    })
    const changeShipmentStatusOnListCache = useChangeShipmentStatusOnListCache({
        queryKey: [SHIPMENT_QUERY_KEY, shipmentListParams]
    })
    const removeReturnedStockItemsFromOrder = useRemoveReturnedStockItemsFromOrder(order)

    return (
        <section className={styles.root}>
            <H2Counter counterValue={shipmentList.data.length} classes={{ h2: styles.h2, counter: styles.counter }}>
                Returns
            </H2Counter>
            <List
                data={shipmentList.data}
                fetchStatus={shipmentList.fetchStatus}
                onShipmentStatusChanged={(...args) => {
                    removeReturnedStockItemsFromOrder(...args)
                    changeShipmentStatusOnListCache(...args)
                }}
                initialExpandedShipmentId={_get(state, "shipmentId", undefined)}
            />
        </section>
    )
}

Returns.propTypes = {
    order: PropTypes.shape({
        id: PropTypes.number.isRequired,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                returned_stock_items: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired
            }).isRequired
        ).isRequired
    }).isRequired
}

export default Returns
