import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import _get from "lodash/get"

import { H1, KanbanBoard, KanbanColumn, ConfirmModal, KANBAN_ROW_TYPE, HeaderSelect } from "@butterfly-frontend/ui"
import { Header, KanbanColumnFetchStatus } from "modules/WmsModule/components"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import { useShipmentList } from "modules/WmsModule/hooks/api/useShipment"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import useConsignmentNoteOptions from "modules/WmsModule/hooks/useConsignmentNoteOptions"
import { combineFetchStatuses } from "helpers/fetchStatus"
import ListFiltersContext from "modules/WmsModule/contexts/ListFiltersContext"
import { getShipmentsAvailableForConsignmentNote } from "modules/WmsModule/helpers/consignmentNote"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"
import { checkWhatTypeOfShipmentCanBeSeen } from "modules/WmsModule/helpers/shipment"

import PendingPackageRow from "./PendingPackageRow"
import UnpackedPackageRow from "./UnpackedPackageRow"
import ReturnedPackageRow from "./ReturnedPackageRow"

import styles from "./ReceivingListPage.module.css"

const COMMON_SHIPMENT_QUERY_PARAMS = { today: true, length: 9999 }
const PENDING_PACKAGES_QUERY_PARAMS = { pending: true, ...COMMON_SHIPMENT_QUERY_PARAMS }
const UNPACKED_PACKAGES_QUERY_PARAMS = { pickup: true, ...COMMON_SHIPMENT_QUERY_PARAMS }
const RETURNED_PACKAGES_QUERY_PARAMS = { returned: true, ...COMMON_SHIPMENT_QUERY_PARAMS }

const DANGER_STATUSES = [SHIPMENT_STATUS_KEYS.ON_HOLD, SHIPMENT_STATUS_KEYS.CANCELLED]
const SUCCESS_STATUSES = [SHIPMENT_STATUS_KEYS.DELIVERED]

const getRowType = shipment =>
    DANGER_STATUSES.includes(shipment.status.status)
        ? KANBAN_ROW_TYPE.DANGER
        : SUCCESS_STATUSES.includes(shipment.status.status)
        ? KANBAN_ROW_TYPE.SUCCESS
        : KANBAN_ROW_TYPE.DEFAULT

const KANBAN_BOX_OPTIONS_LABELS = {
    print: "Print all package labels",
    download: "Download all package labels"
}

const KANBAN_CONSIGNMENT_NOTE_OPTIONS_LABELS = {
    print: "Print all shipping bills of landing",
    download: "Download all shipping bills of landing"
}

const ALL_WAREHOUSES_SELECT_OPTION = { id: "0", name: "All" }

const getStockItemIdsFromShipments = (shipments = []) =>
    shipments.reduce(
        (stockItemsIds, { status, stock_items_ids }) =>
            DANGER_STATUSES.includes(status.status) ? stockItemsIds : [...stockItemsIds, ...stock_items_ids],
        []
    )

const getReceivingHref = shipment => `/wms/receivings/${shipment.id}`

const ReceivingDetailsList = ({ canAccess }) => {
    const [returnShipmentId, setReturnShipmentId] = useState(null)
    const history = useHistory()
    const {
        set: setFiltersContext,
        values: { receivingList: filtersFromContext }
    } = useContext(ListFiltersContext)
    const [selectedWarehouse, setSelectedWarehouse] = useState(
        filtersFromContext.destinationWarehouse || ALL_WAREHOUSES_SELECT_OPTION
    )
    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)
    const canAccessIncomingStockItemShipments = useMemo(() => canAccess(PERMISSIONS.context.INCOMING_SHIPMENTS), [])
    const canAccessShipmentsAndReceivings = useMemo(() => canAccess(PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS), [])
    const filteredShipmentTypes = useMemo(
        () =>
            checkWhatTypeOfShipmentCanBeSeen({
                canSeeShipmentsFromPO: canAccessIncomingStockItemShipments,
                canSeeRegularShipments: canAccessShipmentsAndReceivings
            }),
        []
    )

    useEffect(() => {
        setFiltersContext(prevState => ({
            ...prevState,
            receivingList: {
                destinationWarehouse: selectedWarehouse
            }
        }))
    }, [selectedWarehouse])

    const openReturnModal = shipment => setReturnShipmentId(shipment.id)

    const redirectToReceivingDetails = useCallback(shipment => history.push(`/wms/receivings/${shipment.id}`), [
        history.push
    ])

    const shipmentListProps = useMemo(
        () => ({
            params: {
                destination_warehouses: _get(selectedWarehouse, "id", undefined),
                type: filteredShipmentTypes.join(",")
            },
            reactQueryProps: {
                enabled: !!filteredShipmentTypes
            }
        }),
        [selectedWarehouse, filteredShipmentTypes]
    )

    const { data: warehouseList, fetchStatus: warehouseListFetchStatus } = useWarehouseList({
        params: {
            warehouses: assignedWarehouseIds
        }
    })

    const { data: pendingPackages, fetchStatus: pendingPackagesFetchStatus } = useShipmentList({
        ...shipmentListProps,
        params: { ...shipmentListProps.params, ...PENDING_PACKAGES_QUERY_PARAMS }
    })

    const { data: unpackedPackages, fetchStatus: unpackedPackagesFetchStatus } = useShipmentList({
        ...shipmentListProps,
        params: { ...shipmentListProps.params, ...UNPACKED_PACKAGES_QUERY_PARAMS }
    })

    const { data: returnedPackages, fetchStatus: returnedPackagesFetchStatus } = useShipmentList({
        ...shipmentListProps,
        params: { ...shipmentListProps.params, ...RETURNED_PACKAGES_QUERY_PARAMS }
    })

    const { options: pendingPackagesBoxOptions, isLoading: isPendingPackagesBoxOptionsLoading } = useBoxLabelOptions({
        ids: getStockItemIdsFromShipments(pendingPackages),
        labels: KANBAN_BOX_OPTIONS_LABELS
    })

    const { options: unpackedPackagesBoxOptions, isLoading: isUnpackedPackagesBoxOptionsLoading } = useBoxLabelOptions({
        ids: getStockItemIdsFromShipments(unpackedPackages),
        labels: KANBAN_BOX_OPTIONS_LABELS
    })

    const { options: returnedPackagesBoxOptions, isLoading: isReturnedPackagesBoxOptionsLoading } = useBoxLabelOptions({
        ids: getStockItemIdsFromShipments(returnedPackages),
        labels: KANBAN_BOX_OPTIONS_LABELS
    })

    const {
        options: pendingPackagesConsignmentNoteOptions,
        isLoading: isPendingPackagesConsignmentNoteOptionsLoading
    } = useConsignmentNoteOptions({
        ids: getShipmentsAvailableForConsignmentNote(pendingPackages),
        labels: KANBAN_CONSIGNMENT_NOTE_OPTIONS_LABELS
    })

    const {
        options: unpackedPackagesConsignmentNoteOptions,
        isLoading: isUnpackedPackagesConsignmentNoteOptionsLoading
    } = useConsignmentNoteOptions({
        ids: getShipmentsAvailableForConsignmentNote(unpackedPackages),
        labels: KANBAN_CONSIGNMENT_NOTE_OPTIONS_LABELS
    })

    const {
        options: returnedPackagesConsignmentNoteOptions,
        isLoading: isReturnPackagesConsignmentNoteOptionsLoading
    } = useConsignmentNoteOptions({
        ids: getShipmentsAvailableForConsignmentNote(returnedPackages),
        labels: KANBAN_CONSIGNMENT_NOTE_OPTIONS_LABELS
    })

    const pendingPackagesAndWarehousesFetchStatuses = combineFetchStatuses([
        warehouseListFetchStatus,
        pendingPackagesFetchStatus
    ])
    const unpackedPackagesAndWarehousesFetchStatuses = combineFetchStatuses([
        warehouseListFetchStatus,
        unpackedPackagesFetchStatus
    ])
    const returnedPackagesAndWarehousesFetchStatuses = combineFetchStatuses([
        warehouseListFetchStatus,
        returnedPackagesFetchStatus
    ])

    return (
        <div className={styles.page}>
            <Header>
                <H1 withLeftBorder>Receiving:</H1>
                <HeaderSelect
                    options={[ALL_WAREHOUSES_SELECT_OPTION, ...warehouseList].map(({ id, name }) => ({
                        id,
                        label: name
                    }))}
                    value={{ id: selectedWarehouse.id, label: selectedWarehouse.name }}
                    onChange={option => setSelectedWarehouse({ name: option.label, ...option })}
                    isLoading={warehouseListFetchStatus.isLoading}
                    disabled={!warehouseList.length}
                />
            </Header>
            <KanbanBoard>
                <KanbanColumn
                    header="Pending parcels"
                    headerColor="rgba(243, 202, 134, 0.8)"
                    actions={[...pendingPackagesBoxOptions, ...pendingPackagesConsignmentNoteOptions]}
                    isLoading={
                        isPendingPackagesBoxOptionsLoading ||
                        pendingPackagesAndWarehousesFetchStatuses.isLoading ||
                        isPendingPackagesConsignmentNoteOptionsLoading
                    }
                    disabled={pendingPackages.length === 0}
                >
                    <KanbanColumnFetchStatus
                        fetchStatus={pendingPackagesAndWarehousesFetchStatuses}
                        isEmpty={pendingPackages.length === 0}
                        accessDenied={filteredShipmentTypes.length === 0}
                    >
                        {pendingPackages.map(shipment => (
                            <PendingPackageRow
                                shipment={shipment}
                                rowType={getRowType(shipment)}
                                getReceivingHref={getReceivingHref}
                                redirectToReceivingDetails={redirectToReceivingDetails}
                                key={shipment.id}
                            />
                        ))}
                    </KanbanColumnFetchStatus>
                </KanbanColumn>

                <KanbanColumn
                    header="Package pickup"
                    headerColor="rgba(171, 214, 78, 0.5)"
                    actions={[...unpackedPackagesBoxOptions, ...unpackedPackagesConsignmentNoteOptions]}
                    isLoading={
                        isUnpackedPackagesBoxOptionsLoading ||
                        unpackedPackagesAndWarehousesFetchStatuses.isLoading ||
                        isUnpackedPackagesConsignmentNoteOptionsLoading
                    }
                    disabled={unpackedPackages.length === 0}
                >
                    <KanbanColumnFetchStatus
                        fetchStatus={unpackedPackagesAndWarehousesFetchStatuses}
                        isEmpty={unpackedPackages.length === 0}
                        accessDenied={filteredShipmentTypes.length === 0}
                    >
                        {unpackedPackages.map(shipment => (
                            <UnpackedPackageRow
                                shipment={shipment}
                                rowType={getRowType(shipment)}
                                getReceivingHref={getReceivingHref}
                                redirectToReceivingDetails={redirectToReceivingDetails}
                                openReturnModal={openReturnModal}
                                key={shipment.id}
                            />
                        ))}
                    </KanbanColumnFetchStatus>
                </KanbanColumn>

                <KanbanColumn
                    header="Package returned"
                    headerColor="#DADEE7"
                    actions={[...returnedPackagesBoxOptions, ...returnedPackagesConsignmentNoteOptions]}
                    isLoading={
                        isReturnedPackagesBoxOptionsLoading ||
                        returnedPackagesAndWarehousesFetchStatuses.isLoading ||
                        isReturnPackagesConsignmentNoteOptionsLoading
                    }
                    disabled={returnedPackages.length === 0}
                >
                    <KanbanColumnFetchStatus
                        fetchStatus={returnedPackagesAndWarehousesFetchStatuses}
                        isEmpty={returnedPackages.length === 0}
                        accessDenied={filteredShipmentTypes.length === 0}
                    >
                        {returnedPackages.map(shipment => (
                            <ReturnedPackageRow
                                shipment={shipment}
                                rowType={getRowType(shipment)}
                                getReceivingHref={getReceivingHref}
                                key={shipment.id}
                            />
                        ))}
                    </KanbanColumnFetchStatus>
                </KanbanColumn>
            </KanbanBoard>

            {returnShipmentId && (
                <ConfirmModal
                    onCancel={() => setReturnShipmentId(null)}
                    onAction={() => redirectToReceivingDetails({ id: returnShipmentId })}
                    title="Do you want to make a partial Return?"
                    buttonLabel="Accept"
                >
                    Do you want to return damaged items to the sender?
                </ConfirmModal>
            )}
        </div>
    )
}

ReceivingDetailsList.propTypes = {
    canAccess: PropTypes.func.isRequired
}

export default withPermissions(ReceivingDetailsList)
