import React, { useContext } from "react"

import { useDashboard } from "modules/DashboardModule/hooks/api/useDashboard"
import ListFiltersContext from "modules/DashboardModule/contexts/ListFiltersContext"
import { formatDateToApi } from "modules/WmsModule/helpers/date"

import SellersPage from "./SellersPage"

export const SellersPageContainer = () => {
    const {
        values: { dashboard: filtersFromContext }
    } = useContext(ListFiltersContext)

    const { data, isLoading } = useDashboard({
        date_from: filtersFromContext.date.startDate ? formatDateToApi(filtersFromContext.date.startDate) : null,
        date_to: filtersFromContext.date.endDate ? formatDateToApi(filtersFromContext.date.endDate) : null
    })

    return <SellersPage data={data ? data.data : {}} selectedRange={filtersFromContext.date} isLoading={isLoading} />
}

export default SellersPageContainer
