import React from "react"
import { Route, Switch, useRouteMatch } from "react-router-dom"

import withPermissions from "HOC/withPermissions"
import { GroupList, SubgroupList, AttributeList, ValueList } from "../pages"

const Routes = () => {
    const match = useRouteMatch()

    return (
        <Switch>
            <Route
                path={`${match.url}/:groupId/subgroups/:subgroupId/attributes/:attributeId/values`}
                component={ValueList}
                exact
            />
            <Route path={`${match.url}/:groupId/subgroups/:subgroupId/attributes`} component={AttributeList} exact />
            <Route path={`${match.url}/:groupId/subgroups`} component={SubgroupList} exact />
            <Route path={`${match.url}/`} component={GroupList} />
        </Switch>
    )
}

export default withPermissions(Routes)
