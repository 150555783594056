import React, { useReducer, useState, useEffect, Fragment, useRef, useCallback, useMemo, useContext } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { ITEMS_PER_PAGE, LENGTH_WITHOUT_PAGINATION, PERMISSIONS } from "constants/index"

import { Button, DateRangePicker, Pagination, SubmitModal, Status } from "ui"
import FilterableTable, { Content, SearchBar, Header } from "ui/FilterableTable"

import { useActions, useExportFile } from "hooks"
import withStyles from "HOC/withStyles"
import { toApiDate } from "helpers/date"
import { getRouteByOrderType } from "helpers/urls"

import { debounce, isEqual } from "lodash"

import ListingLayout from "modules/OrdersModule/pages/ListingLayout"
import List from "./List"
import Filters from "./components/Filters"
import Sendable from "modules/OrdersModule/components/Sendable"
import Summary from "./components/Summary"
import { Dropdown } from "ui"
import ReactSVG from "react-svg"

import { showSuccessNotification, showErrorNotification } from "actions/notification"
import withPermissions from "HOC/withPermissions"

import layoutStyles from "./overrides/Layout.css"
import dateRangePickerStyles from "./overrides/DateRangePicker.css"
import paginationStyles from "./overrides/Pagination.css"
import tableHeaderStyles from "./overrides/Header.css"
import createNewButtonStyles from "./overrides/CreateNewButton.css"
import printDropdownStyles from "./overrides/PrintDropdown.css"

import styles from "./ListContainer.module.css"

import closeIcon from "assets/close-blue.svg"
import printerIcon from "assets/printer.svg"
import calendarIcon from "assets/calendar.svg"

import listConfig, { toApiFormat } from "./listConfig"
import { defaultUserStatuses } from "ui/Status/config"
import getPrintBlob, { showPrintWindow } from "actions/print"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader/SnakeLoader"

export const userStateFromLoginStore = loginStore => {
    const { userId, userName, ...currentUser } = loginStore

    return { ...currentUser, id: Number(userId), label: userName }
}

const ListContext = React.createContext({})

function ListContainer({
    cx,
    type,
    multiselect,
    order = {},
    isTabView = false,
    tabViewConfig = {},
    customConfig,
    orderId,
    additionalParams = {},
    canDelete
}) {
    const loginStore = useSelector(state => state.loginStore)
    const config = useMemo(() => customConfig || listConfig[type] || {}, [type, customConfig])
    const [modal, setModal] = useReducer((state, newState) => ({ ...state, ...newState }), {
        isOpen: false,
        header: `Delete selected ${config.name}?`,
        body: `Are you sure you want to delete selected ${config.name}? This action cannot be reversed.`,
        onClose: () => setModal({ isOpen: false }),
        onSubmit: handleDeleteMany,
        isLoading: false
    })
    const [searchParams, setSearchParams] = useReducer((state, newState) => ({ ...state, ...newState }), {
        type,
        query: "",
        statuses: [],
        created_from: null,
        created_to: null,
        date_from: null,
        date_to: null,
        sort_by: "created_at",
        sort_direction: "desc",
        page: 1,
        length: isTabView ? LENGTH_WITHOUT_PAGINATION : ITEMS_PER_PAGE,
        order_id: isTabView ? order.id || orderId : null,
        moreFilters: {
            user: null,
            client: null,
            brand: null,
            project_id: isTabView ? (!!additionalParams.projectId ? additionalParams.projectId : null) : null,
            client_id: isTabView ? (!!additionalParams.clientId ? additionalParams.clientId : null) : null,
            amount: null,
            amount_min: null,
            amount_max: null
        }
    })

    const actions = useActions({
        getResource: config.getResource,
        delete: config.delete,
        showSuccessNotification,
        showErrorNotification
    })

    const resource = useSelector(config.dbSelector)
    const { checkedList, clearCheckboxes } = multiselect
    const areSelected = checkedList.length > 0
    const prevStatuses = useRef(searchParams.statuses)
    const debouncedGetResource = useCallback(debounce(actions.getResource, 400), [])
    const [isLoadingPrint, setIsLoadingPrint] = useState(false)
    const initialSendableModalData = { isOpen: false, id: -1, uuid: -1, clientEmails: [], printTemplateType: "" }
    const [sendableModal, setSendableModal] = useState(initialSendableModalData)
    const [isDefaultStatus, setIsDefaultStatus] = useState(true)

    const [handleExportFile, isExporting] = useExportFile({
        exportData: { ...config.export, ...toApiFormat(searchParams.moreFilters) },
        checkedList,
        onError: ({ message }) => actions.showErrorNotification(message)
    })

    const dropdown = resource.data.length > 0 && {
        elements: [{ value: "csv", label: "Export to CSV" }, { value: "xls", label: "Export to XLS" }],
        onClick: ({
            event: {
                target: { value }
            }
        }) => handleExportFile({ export: value }),
        label: `Export${areSelected && checkedList.length < resource.data.length ? "" : " all"}`,
        isLoading: isExporting
    }

    const openSendableModal = ({ id, uuid, clientEmails, reloadDocument, printTemplateType }) =>
        setSendableModal({ id, uuid, isOpen: true, clientEmails, reloadDocument, printTemplateType })
    const closeSendableModal = () => setSendableModal(initialSendableModalData)

    useEffect(() => {
        const statusesHaveChanged = !isEqual(prevStatuses.current, searchParams.statuses)
        const fetchAction = statusesHaveChanged ? debouncedGetResource : actions.getResource
        prevStatuses.current = searchParams.statuses
        clearCheckboxes()
        fetchAction(searchParams)

        return function() {
            clearCheckboxes()
            debouncedGetResource.cancel()
        }
    }, [searchParams])

    const isAdmin = useMemo(() => loginStore && loginStore.roles && loginStore.roles[0].name === "SuperAdmin", [
        loginStore
    ])

    const isMultiDeleteButtonDisabled = useMemo(
        () => type === "invoices" && getSelectedUnpaidInvoices(resource.data, checkedList).length === 0,
        [type, checkedList, resource.data]
    )

    const selectStatuses = useMemo(() => {
        return !isAdmin && isDefaultStatus && searchParams.statuses.length === 0
            ? defaultUserStatuses[config.statusType]
            : searchParams.statuses
    }, [searchParams, isDefaultStatus])

    useEffect(() => {
        if (config.name === "payments" && resource.fetchStatus.error) {
            actions.showErrorNotification(resource.fetchStatus.error.message)
        }
    }, [resource.fetchStatus.error])

    const isValidType = Object.keys(listConfig).includes(type)

    if (!isValidType) {
        return null
    }

    return (
        <ListContext.Provider value={config}>
            {isTabView ? renderTabView() : renderListingLayout()}
        </ListContext.Provider>
    )

    function renderTabView() {
        return (
            <div className={cx("tabView")}>
                <div className={cx("multiselect")}>
                    <h3 style={{ textTransform: "capitalize" }}>
                        {areSelected ? "Selected" : config.name}
                        <span className={cx("tabViewCount", { areSelected })}>
                            {areSelected ? checkedList.length : resource.data.length}
                        </span>
                    </h3>
                    {renderTabCta()}
                </div>
                {renderTableWithModal()}
            </div>
        )
    }

    function renderTabCta() {
        if (isTabView && tabViewConfig.isExportPrint) {
            const deleteButton =
                ["payments", "invoices", "purchase-orders"].includes(type) && areSelected ? (
                    <button
                        className={cx("headerButton", "closeButton", "non-absolute")}
                        onClick={openMuliselectDeleteModal}
                    >
                        <img src={closeIcon} alt="" className={cx("closeIcon")} />
                        Delete
                    </button>
                ) : null

            const drop = dropdown ? (
                <Dropdown
                    options={dropdown.elements.map(element => ({
                        value: element.value,
                        label: element.label,
                        onClick: () => dropdown.onClick({ event: { target: { value: element.value } } })
                    }))}
                    label={dropdown.isLoading ? <SnakeLoader type="small" /> : dropdown.label || "Export"}
                    isDisabled={!dropdown.isLoading}
                    isListOnRight
                />
            ) : null

            const print = areSelected ? (
                <PrintButton cx={cx} isLoading={isLoadingPrint} handlePrint={handlePrint} />
            ) : null

            return (
                <div className={cx("actions")}>
                    {deleteButton}
                    {drop}
                    {print}
                </div>
            )
        }

        return (
            <Fragment>
                {areSelected && (
                    <div className={cx("actions")}>
                        {(type !== "invoices" || canDelete(PERMISSIONS.context.INVOICES)) && (
                            <Button
                                className={cx("deleteButton")}
                                icon={closeIcon}
                                label="Delete"
                                onClick={openMuliselectDeleteModal}
                                isDisabled={isMultiDeleteButtonDisabled}
                            />
                        )}
                        <PrintButton cx={cx} isLoading={isLoadingPrint} handlePrint={handlePrint} />
                        <Button
                            isDisabled
                            className={`first-button ${cx("sendButton")}`}
                            label={`Send ${config.abbreviation}`}
                            onClick={handleSend}
                        />
                    </div>
                )}
            </Fragment>
        )
    }

    function renderListingLayout() {
        return (
            <ListingLayout
                title={() => (
                    <span style={{ textTransform: "capitalize" }}>
                        {config.name}
                        {areSelected && ":"}{" "}
                        {areSelected && (
                            <span style={{ fontWeight: 300 }}>
                                Selected<span className={cx("multiselectCount")}>{checkedList.length}</span>
                            </span>
                        )}
                    </span>
                )}
                cta={renderCta()}
                dropdown={dropdown}
                customStyles={layoutStyles}
            >
                {renderTableWithModal()}
                {resource.fetchStatus.isLoaded && (
                    <Pagination
                        customStyles={paginationStyles}
                        currentPage={!isNaN(Number(resource.meta.current_page)) ? resource.meta.current_page : 1}
                        pages={resource.meta.last_page || 1}
                        onChange={page => setSearchParams({ page })}
                    />
                )}
            </ListingLayout>
        )
    }

    function renderCta() {
        if (["sales", "memo", "rental"].includes(type)) {
            const route = getRouteByOrderType(type)

            return [
                areSelected ? (
                    <PrintButton cx={cx} isLoading={isLoadingPrint} handlePrint={handlePrint} />
                ) : (
                    <Link to={`/orders/${route}/add`}>
                        <Button label="Create New" customStyles={createNewButtonStyles} />
                    </Link>
                )
            ]
        }

        if (areSelected && ["invoices", "purchase-orders"].includes(type)) {
            const cta = [<PrintButton cx={cx} isLoading={isLoadingPrint} handlePrint={handlePrint} />]

            if (type !== "invoices" || canDelete(PERMISSIONS.context.INVOICES)) {
                cta.push(
                    <button
                        className={cx("headerButton", "closeButton")}
                        onClick={openMuliselectDeleteModal}
                        disabled={isMultiDeleteButtonDisabled}
                    >
                        <img src={closeIcon} alt="" className={cx("closeIcon")} />
                        Delete
                    </button>
                )
            }

            return cta
        }

        if (!areSelected && type === "purchase-orders") {
            return [
                <Link to={`/orders/purchase-orders/add`}>
                    <Button label="Create New" customStyles={createNewButtonStyles} />
                </Link>
            ]
        }

        return []
    }

    function renderTableWithModal() {
        const { date_from, date_to, created_from, created_to } = searchParams
        const startDateParams = type === "payments" ? date_from : created_from
        const endDateParams = type === "payments" ? date_to : created_to
        const dataRangeTitle = type === "payments" ? "Any date added" : "Any date created"

        return (
            <Fragment>
                <FilterableTable
                    fetchStatus={resource.fetchStatus}
                    items={resource.data}
                    meta={resource.meta}
                    handleChange={handleTableChange}
                >
                    {!isTabView && (
                        <Header
                            customStyles={tableHeaderStyles}
                            MoreFiltersComponent={
                                <Filters
                                    moreFilters={searchParams.moreFilters}
                                    setMoreFilters={moreFilters => setSearchParams({ moreFilters })}
                                    name={config.filterName}
                                    isProjectsFilterHidden={["memo", "rental"].includes(config.singleName)}
                                    isPaymentsFilters={type === "payments"}
                                    isInvoicesFilters={type === "invoices"}
                                />
                            }
                        >
                            <SearchBar placeholder={`Search for ${config.singleName}...`} />
                            {config.statusType && (
                                <Status.Select
                                    allSelectedText="All statuses"
                                    type={config.statusType}
                                    value={selectStatuses}
                                    canSelectAll={isAdmin}
                                    isDefaultStatus={isDefaultStatus}
                                    onChange={statuses => {
                                        isDefaultStatus && setIsDefaultStatus(false)
                                        setSearchParams({ statuses })
                                    }}
                                />
                            )}
                            <DateRangePicker
                                ranges={{
                                    startDate: startDateParams,
                                    endDate: endDateParams
                                }}
                                onChange={onDateChange}
                                resetRanges={onDateChange}
                                format="DD MMM YYYY"
                                title={dataRangeTitle}
                                icon={calendarIcon}
                                customStyles={dateRangePickerStyles}
                            />
                        </Header>
                    )}
                    {type === "payments" &&
                        !tabViewConfig.noSummary &&
                        resource.meta &&
                        resource.meta.calculations &&
                        resource.fetchStatus.isLoaded && <Summary calculations={resource.meta.calculations} />}
                    <Content className={cx("content")}>
                        <List
                            isTabView={isTabView}
                            searchParams={searchParams}
                            multiselect={multiselect}
                            order={order}
                            onDelete={handleDeleteOne}
                            handleOpenModal={openSendableModal}
                        />
                    </Content>
                </FilterableTable>

                <SubmitModal redButton submitText="Delete" {...modal} />
                {sendableModal.isOpen && (
                    <Sendable {...sendableModal} handleClose={closeSendableModal} type={config.notesType}></Sendable>
                )}
            </Fragment>
        )
    }

    function handlePrint(templateType) {
        setIsLoadingPrint(true)
        getPrintBlob(checkedList, config.printType, templateType)
            .then(showPrintWindow)
            .finally(() => setIsLoadingPrint(false))
    }

    function handleSend() {}

    function openMuliselectDeleteModal() {
        setModal({
            isOpen: true,
            isLoading: false,
            header: `Delete selected ${config.name}?`,
            body: `Are you sure you want to delete selected ${config.name}? This action cannot be reversed.`,
            onSubmit: handleDeleteMany
        })
    }

    function handleDeleteMany(id) {
        const [ids, suffix] = typeof id === "number" ? [[id], ""] : [multiselect.checkedList, "s"]

        setModal({ isLoading: true })
        Promise.all(ids.map(id => actions.delete(id)))
            .then(() => {
                clearCheckboxes()
                actions.getResource(searchParams)
                actions.showSuccessNotification(`Invoice${suffix} deleted successfully`)
            })
            .catch(() => actions.showErrorNotification())
            .finally(() => {
                setModal({ isOpen: false, isLoading: false })
            })
    }

    function handleDeleteOne(id) {
        if (typeof id !== "number") {
            return
        }

        setModal({
            isOpen: true,
            isLoading: false,
            header: `Delete this ${config.singleName}?`,
            body: `Are you sure you want to delete this ${config.singleName}? This action cannot be reversed.`,
            onSubmit: handleDeleteMany.bind(null, id)
        })
    }

    function handleTableChange(tableParams, isInitialCallback) {
        if (!isInitialCallback) {
            setSearchParams({
                page: 1,
                query: tableParams.search,
                sort_by: tableParams.sorting.by,
                sort_direction: tableParams.sorting.direction
            })
        }
    }

    function onDateChange(value) {
        const dateRange = value.range1
        const created_from = dateRange ? toApiDate(dateRange.startDate) : null
        const created_to = dateRange ? toApiDate(dateRange.endDate) : null
        const dateParams =
            type === "payments" ? { date_from: created_from, date_to: created_to } : { created_from, created_to }

        setSearchParams({ page: 1, ...dateParams })
    }
}

function getSelectedUnpaidInvoices(invoices = [], checkedList = []) {
    return invoices.filter(({ id, status }) => checkedList.includes(id) && status === "new")
}

function PrintButton({ cx, handlePrint, isLoading }) {
    return (
        <Dropdown
            className={`first-button white ${cx("printButton")}`}
            label={
                <Fragment>
                    {isLoading ? (
                        <SnakeLoader type="small" />
                    ) : (
                        <Fragment>
                            <ReactSVG src={printerIcon} alt="Print" className={cx("printButtonIcon")} />
                            <span>Print</span>
                        </Fragment>
                    )}
                </Fragment>
            }
            options={[
                { label: "With cover", onClick: () => handlePrint("with_cover") },
                { label: "Bigger pictures", onClick: () => handlePrint("bigger_pictures") },
                { label: "Smaller pictures", onClick: () => handlePrint("smaller_pictures") }
            ]}
            customStyles={printDropdownStyles}
        />
    )
}

export function withListContext(WrappedComponent) {
    const ListConsumer = props => {
        const listConfig = useContext(ListContext)

        return <WrappedComponent {...props} listConfig={listConfig} />
    }

    return ListConsumer
}

export default withPermissions(withStyles(ListContainer, styles))
