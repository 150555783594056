import React, { useState, useEffect } from "react"
import ReactSVG from "react-svg"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import styles from "./Pagination.css"
import arrow from "assets/arrow.svg"

const nonNegative = ({ left, right }) => ({ left: Math.max(left, 0), right: Math.max(right, 0) })

const checkRange = (visiblePages, currentPage, pages) => {
    const visiblePagesInRange = visiblePages

    if (visiblePages.left > currentPage) {
        visiblePagesInRange.left = currentPage - 1
    }

    if (visiblePages.right > pages - currentPage) {
        visiblePagesInRange.right = pages - currentPage
    }

    return nonNegative(visiblePagesInRange)
}

const calculateVisiblePages = (numberOfVisiblePages, currentPage, pages) => {
    const halfVisiblePage = Math.floor(numberOfVisiblePages / 2)

    if (pages - currentPage < halfVisiblePage) {
        return {
            left: halfVisiblePage + (halfVisiblePage - (pages - currentPage)),
            right: halfVisiblePage - (halfVisiblePage - (pages - currentPage))
        }
    }

    if (currentPage < halfVisiblePage + 1) {
        return {
            left: halfVisiblePage - (halfVisiblePage + 1 - currentPage),
            right: halfVisiblePage + (halfVisiblePage + 1 - currentPage)
        }
    }

    return { left: halfVisiblePage, right: halfVisiblePage }
}

const Pagination = React.memo(({ currentPage, pages, onChange, numberOfVisiblePages, breakPointTablet, cx }) => {
    const [isTablet, setIsTablet] = useState(false)
    const mediaQuery = window.matchMedia(`(max-width: ${breakPointTablet}px)`)
    const visiblePages = checkRange(calculateVisiblePages(numberOfVisiblePages, currentPage, pages), currentPage, pages)

    useEffect(() => {
        const mediaQueryListener = e => setIsTablet(e.matches)

        setIsTablet(mediaQuery.matches)
        mediaQuery.addListener(mediaQueryListener)

        return () => mediaQuery.removeListener(mediaQueryListener)
    }, [])

    const renderPage = (numberPage, isActive) => (
        <li key={numberPage} className={cx({ active: isActive })} onClick={() => !isActive && onChange(numberPage)}>
            {numberPage}
        </li>
    )

    const renderPages = type => {
        const isValidType = ["left", "right"].includes(type)
        if (!isValidType) {
            return
        }

        const evaluation = index =>
            type === "left" ? currentPage - visiblePages.left + index : currentPage + index + 1

        return Array(visiblePages[type])
            .fill()
            .map((_, index) => evaluation(index))
            .filter(number => number > 0 && number <= pages)
            .map(number => renderPage(number, false))
    }

    if (pages <= 1) {
        return null
    }

    return (
        <div className={cx("root")}>
            {!isTablet ? (
                <div className={cx("desktop")}>
                    {currentPage !== 1 && (
                        <div className={cx("button")} onClick={() => onChange(currentPage - 1)}>
                            <ReactSVG className={cx("arrow")} src={arrow} alt="arrow" />
                        </div>
                    )}
                    <ul className={cx("pages")}>
                        {currentPage - visiblePages.left > 1 && renderPage(1, false)}

                        {currentPage - visiblePages.left > 2 && <span className={cx("ellipsis")}>...</span>}

                        {renderPages("left")}
                        {renderPage(currentPage, true)}
                        {renderPages("right")}

                        {pages - currentPage - visiblePages.right > 1 && <span className={cx("ellipsis")}>...</span>}

                        {pages - currentPage - visiblePages.right > 0 && renderPage(pages, false)}
                    </ul>
                    {currentPage !== pages && (
                        <div className={cx("button", "right")} onClick={() => onChange(currentPage + 1)}>
                            <span>Next page</span>
                            <ReactSVG className={cx("arrow")} src={arrow} alt="arrow" />
                        </div>
                    )}
                </div>
            ) : (
                <div className={cx("tablet")}>
                    <div
                        className={cx("button", "left", { disable: currentPage === 1 })}
                        onClick={() => currentPage !== 1 && onChange(currentPage - 1)}
                    >
                        <ReactSVG className={cx("arrow")} src={arrow} alt="arrow" />
                        <span>Prev</span>
                    </div>
                    <span className={cx("pages")}>{`${currentPage} of ${pages}`}</span>
                    <div
                        className={cx("button", "right", { disable: currentPage === pages })}
                        onClick={() => currentPage !== pages && onChange(currentPage + 1)}
                    >
                        <span>Next</span>
                        <ReactSVG className={cx("arrow")} src={arrow} alt="arrow" />
                    </div>
                </div>
            )}
        </div>
    )
})

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    numberOfVisiblePages: PropTypes.number,
    breakPointTablet: PropTypes.number,
    cx: PropTypes.func
}

Pagination.defaultProps = {
    numberOfVisiblePages: 3,
    breakPointTablet: 991
}

export default withStyles(Pagination, styles)
