import CLIENT_RETURN_SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/clientReturnShipmentStatusKeys"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"

export const checkIfCanReturnShipment = ({ shipmentType, hasOrder = false }) =>
    shipmentType !== SHIPMENT_TYPE_KEYS.RETURN && !hasOrder

export const checkIfShipmentIsCancelled = ({ shipmentStatus, isClientReturn = false }) =>
    isClientReturn
        ? shipmentStatus === CLIENT_RETURN_SHIPMENT_STATUS_KEYS.CANCELLED
        : shipmentStatus === SHIPMENT_STATUS_KEYS.CANCELLED

export const checkIfCanChangeClientReturnShipmentStatus = ({ shipmentStatus }) =>
    ![CLIENT_RETURN_SHIPMENT_STATUS_KEYS.CANCELLED, CLIENT_RETURN_SHIPMENT_STATUS_KEYS.RECEIVED].includes(
        shipmentStatus
    )

export const checkWhatTypeOfShipmentCanBeSeen = ({ canSeeRegularShipments, canSeeShipmentsFromPO }) => {
    const shipmentTypes = Object.values(SHIPMENT_TYPE_KEYS)

    return shipmentTypes.filter(shipmentType =>
        shipmentType === SHIPMENT_TYPE_KEYS.PO ? canSeeShipmentsFromPO : canSeeRegularShipments
    )
}
