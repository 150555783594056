import React, { Component, Fragment, forwardRef } from "react"
import style from "./Textarea.css"
import withStyles from "HOC/withStyles"

class Textarea extends Component {
    render() {
        const {
            name,
            value,
            type,
            label,
            attributes,
            fieldPath,
            error,
            onChange,
            onBlur,
            onKeyDown,
            isPlaceholder,
            placeholder,
            isInline,
            className,
            cx,
            handleScrollToError,
            maxLength,
            isDisabled,
            innerRef,
            defaultValue
        } = this.props

        const formattedLabel = (
            <Fragment>
                {label}
                {attributes && attributes.required ? <p>&nbsp;*</p> : ""}
            </Fragment>
        )
        const isValue = (!!value || value === 0) && !error

        const additionalProps = {}

        if (maxLength) {
            additionalProps.maxLength = maxLength
        }

        return (
            <div className={cx("root", { error: error, inline: isInline, isValue })}>
                {!isPlaceholder && !!label && <label htmlFor={`${name}_${type}`}>{formattedLabel}:</label>}

                <textarea
                    ref={innerRef}
                    defaultValue={defaultValue}
                    type={type}
                    id={`${name}_${type}`}
                    name={name}
                    placeholder={isPlaceholder ? label : placeholder || ""}
                    value={value}
                    className={cx("textarea", className)}
                    required={attributes && attributes.required}
                    onChange={event => onChange && onChange({ event, fieldPath })}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    {...additionalProps}
                    disabled={isDisabled}
                />

                {error && (
                    <h2
                        className={cx("error-message")}
                        ref={el => {
                            this.errorInput = el
                        }}
                    >
                        {error}
                        {handleScrollToError && handleScrollToError(this.errorInput)}
                    </h2>
                )}
            </div>
        )
    }
}

Textarea.defaultProps = {
    maxLength: 10000
}

export default withStyles(forwardRef((props, ref) => <Textarea innerRef={ref} {...props} />), style)
