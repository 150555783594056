import React, { useState, useCallback } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import {
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRowWithExpandedDetails,
    TableCell,
    Panel,
    EmptyInternalList,
    BagIcon
} from "@butterfly-frontend/ui"

import { formatPrice } from "helpers/units"
import ItemInfo from "modules/OrdersModule/pages/StockPurchaseOrderFormPage/ItemInfo"
import ItemEdit from "modules/OrdersModule/pages/StockPurchaseOrderFormPage/ItemEdit"
import calculateTotalPrice from "helpers/calculateTotalPrice"

import TABLE_COLUMNS from "./tableColumns"
import styles from "./ItemList.module.css"

const ItemList = ({
    items,
    removeOrderItem,
    editOrderItem,
    brandName,
    brandDiscountValue,
    disabledEditAndDeleteItem,
    setEditProductRowId,
    editProductRowId
}) => {
    const [expandedDetailsRowIds, setExpandedDetailsRowIds] = useState(new Map())

    const toggleExpandedRow = useCallback(
        rowId => {
            const newExpandedDetailsRowIds = new Map(expandedDetailsRowIds)

            newExpandedDetailsRowIds.get(rowId)
                ? newExpandedDetailsRowIds.delete(rowId)
                : newExpandedDetailsRowIds.set(rowId, true)

            setExpandedDetailsRowIds(newExpandedDetailsRowIds)
        },
        [expandedDetailsRowIds]
    )

    if (items.length === 0) {
        return (
            <Panel>
                <EmptyInternalList text="No products added" Icon={BagIcon} />
            </Panel>
        )
    }

    return (
        <Table classes={{ root: styles.table }}>
            <TableHead>
                <TableHeadCell {...TABLE_COLUMNS.product} classes={{ root: styles.productCell }}>
                    ITEM NAME
                </TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.wholeSaleCost}>ITEM WHOLESALE PRICE</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.quantity}>QTY</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.discount}>DISCOUNT</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.total}>ITEM TOTAL</TableHeadCell>
            </TableHead>
            <TableBody>
                {items.map(row => {
                    const rowId = row.id || row.draftId
                    const isExpanded = expandedDetailsRowIds.get(rowId)

                    return editProductRowId === rowId ? (
                        <ItemEdit
                            key={rowId}
                            orderItem={row}
                            editOrderItem={editOrderItem}
                            closeEditMode={() => setEditProductRowId(null)}
                            brandDiscountValue={brandDiscountValue}
                        />
                    ) : (
                        <TableRowWithExpandedDetails
                            key={rowId}
                            isExpanded={isExpanded}
                            detailsComponent={
                                <div className={styles.rowDetails}>
                                    <div className={styles.rowDetail}>
                                        <p className={styles.label}>Vendor:</p>
                                        <p>{brandName}</p>
                                    </div>
                                    <div className={styles.rowDetail}>
                                        <p className={styles.label}>Original product name:</p>
                                        <p>{row.name}</p>
                                    </div>
                                </div>
                            }
                        >
                            <TableCell
                                {...TABLE_COLUMNS.product}
                                classes={{ root: classNames(styles.autoHeightCell, styles.productCell) }}
                            >
                                <ItemInfo
                                    orderItem={row}
                                    isExpanded={isExpanded}
                                    removeOrderItem={removeOrderItem}
                                    toggleDetails={() => toggleExpandedRow(rowId)}
                                    openEditMode={() => setEditProductRowId(rowId)}
                                    disabledEditAndDeleteItem={disabledEditAndDeleteItem}
                                />
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.wholeSaleCost} classes={{ root: styles.alignToTopCell }}>
                                {formatPrice(row.wholesale_cost)}
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.quantity} classes={{ root: styles.alignToTopCell }}>
                                {row.quantity}
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.discount} classes={{ root: styles.alignToTopCell }}>
                                {typeof row.wholesale_discount === "number"
                                    ? `${row.wholesale_discount} %`
                                    : brandDiscountValue
                                    ? `${brandDiscountValue} %`
                                    : "-"}
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.total} classes={{ root: styles.totalCell }}>
                                {formatPrice(
                                    calculateTotalPrice({
                                        price: row.wholesale_cost,
                                        discount:
                                            typeof row.wholesale_discount === "number"
                                                ? row.wholesale_discount
                                                : brandDiscountValue,
                                        quantity: row.quantity
                                    })
                                )}
                            </TableCell>
                        </TableRowWithExpandedDetails>
                    )
                })}
            </TableBody>
        </Table>
    )
}

ItemList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            draftId: PropTypes.string,
            quantity: PropTypes.number.isRequired,
            wholesale_cost: PropTypes.number.isRequired,
            wholesale_discount: PropTypes.number,
            name: PropTypes.string.isRequired
        }).isRequired
    ).isRequired,
    removeOrderItem: PropTypes.func.isRequired,
    editOrderItem: PropTypes.func.isRequired,
    brandName: PropTypes.string,
    brandDiscountValue: PropTypes.number,
    disabledEditAndDeleteItem: PropTypes.bool,
    setEditProductRowId: PropTypes.func.isRequired,
    editProductRowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
}

export default ItemList
