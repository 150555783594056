import React, { useEffect, useMemo, useReducer } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import idx from "idx"

import fetch from "helpers/fetch"
import { useActions, useFetch } from "hooks"

import * as notificationActions from "actions/notification"

import Form from "./Form"

import { reducer, initialState, getCollectionById } from "./reducer"
import { transformError } from "modules/ContentModule/helpers"
import { INIT_VALUES } from "./constants"

const FormContainer = ({
    withHomepagePicture,
    withHomepageVisibility,
    collectionLabel,
    sectionableType,
    collectionId,
    basePath,
    apiUrl,
    sidebarImagesDimensions
}) => {
    const history = useHistory()
    const actions = useActions({ ...notificationActions })

    const [{ data: collection, isLoading }, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        collectionId &&
            getCollectionById(`${apiUrl}/${collectionId}`, dispatch).catch(() => {
                actions.showErrorNotification()
                redirectToList()
            })
    }, [collectionId])

    const initialValues = useMemo(
        () => (collection.id ? { ...INIT_VALUES, ...collection, tag: idx(collection, _ => _.tag.id) } : INIT_VALUES),
        [collection.id]
    )

    const scrollToError = errors => {
        const errorsKeys = Object.keys(errors)
        if (errorsKeys.length > 0) {
            const firstErrorKey = errorsKeys[0]
            const elementToScroll =
                document.getElementsByName(firstErrorKey)[0] || document.getElementsByClassName(firstErrorKey)[0]

            if (elementToScroll) {
                elementToScroll && elementToScroll.scrollIntoView()
                window.scrollTo(0, window.scrollY - 200)
            }
        }
    }

    const redirectToList = () => history.push(basePath)

    const [handleSubmit, { isLoading: isSubmitting }] = useFetch({
        action: values => {
            const getId = field => idx(values, _ => _[field].id)
            const collectionValues = {
                ...values,
                cover_desktop: getId("cover_desktop"),
                cover_tablet: getId("cover_tablet"),
                cover_mobile: getId("cover_mobile"),
                thumbnail_horizontal: getId("thumbnail_horizontal"),
                thumbnail_vertical: getId("thumbnail_vertical"),
                homepage_image: getId("homepage_image")
            }
            const [method, url] = collectionId ? ["patch", `${apiUrl}/${collectionId}`] : ["post", apiUrl]
            return fetch[method](url, collectionValues)
        },
        onSuccess: () => {
            redirectToList()
            actions.showSuccessNotification()
        },
        onError: (error, _, formikActions) => {
            const errors = transformError(error)
            formikActions.setErrors(errors)
            scrollToError(errors)
        }
    })

    return (
        <div>
            <Form
                withHomepagePicture={withHomepagePicture}
                withHomepageVisibility={withHomepageVisibility}
                collectionLabel={collectionLabel}
                sectionableType={sectionableType}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                handleCancel={redirectToList}
                isSubmitting={isSubmitting}
                isLoading={isLoading}
                showErrorNotification={actions.showErrorNotification}
                showSuccessNotification={actions.showSuccessNotification}
                sidebarImagesDimensions={sidebarImagesDimensions}
            />
        </div>
    )
}

FormContainer.propTypes = {
    withHomepagePicture: PropTypes.bool,
    withHomepageVisibility: PropTypes.bool,
    collectionLabel: PropTypes.string.isRequired,
    sectionableType: PropTypes.string.isRequired,
    collectionId: PropTypes.number,
    basePath: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
    sidebarImagesDimensions: PropTypes.object.isRequired
}

export default FormContainer
