import React, { Fragment } from "react"
import CollapsingText from "ui/CollapsingText"
import getStyles from "helpers/getCxFromStyles"
import styles from "./details.module.css"

const collapsedDescriptionTextLength = {
    0: 100,
    1024: 200,
    1440: 250
}

const depositUnitLabels = { percent: "%", value: "$" }

const renderAddress = ({ first_name, last_name, country, address, company, post_code, state, city }) => {
    const addressFields = { first_name, last_name, country, address, company, post_code, state, city }
    const hasAnyValueFilled = !!Object.values(addressFields).find(val => typeof val === "string" && val.length > 0)

    if (!hasAnyValueFilled) {
        return <p>-</p>
    }

    return (
        <Fragment>
            <strong>
                {company
                    ? `${company}, ${first_name || ""} ${last_name || ""}`
                    : `${first_name || ""} ${last_name || ""}`}
            </strong>
            <p>{address}</p>
            <p>
                {city ? `${city}, ` : ""}
                {state ? `${state} ` : ""}
                {post_code ? `${post_code} ` : ""}
            </p>
            <p>{country}</p>
        </Fragment>
    )
}

const handleAddress = addr => {
    if (!addr) {
        return "-"
    }

    return renderAddress(addr)
}

const getUserLabel = user => {
    return user.full_name.split(" ").map(value => value[0])
}

const Details = props => {
    const cx = getStyles(styles)
    const {
        shipping_address,
        billing_address,
        areDetailsShown,
        deposit_value,
        deposit_unit,
        description,
        type,
        users
    } = props
    const commissionTeam = users.filter(user => !user.sales_associate)

    return (
        <section className={cx("details", { active: areDetailsShown })}>
            <div className={cx("detailsHolder")}>
                <h4>Additional informations</h4>
                <ul className={cx("detailsList", "mainDetails")}>
                    {type !== "memo" && (
                        <li>
                            <span className={cx("label")}>Billing address:</span>
                            {handleAddress(billing_address)}
                        </li>
                    )}
                    <li>
                        <span className={cx("label")}>Shipping address:</span>
                        {handleAddress(shipping_address)}
                    </li>
                    <li>
                        <span className={cx("label")}>Description:</span>
                        <CollapsingText
                            text={description || "-"}
                            collapsedTextLength={collapsedDescriptionTextLength}
                        />
                    </li>
                </ul>
                <ul className={cx("detailsList", "finances")}>
                    <li>
                        <span className={cx("label")}>Deposit requested:</span>
                        <p>
                            {deposit_value || "0.00"} {depositUnitLabels[deposit_unit] || depositUnitLabels.percent}
                        </p>
                    </li>
                    <li>
                        <span className={cx("label")}>Currency:</span>
                        <p>United States dollar - $</p>
                    </li>
                </ul>

                <h4>Commission team</h4>
                <ul className={cx("commissionTeam")}>
                    {!!commissionTeam.length ? (
                        commissionTeam.map((member, key) => (
                            <li key={key}>
                                <div className={cx("commissionLine")}>
                                    <div className={cx("userLabel")}>{getUserLabel(member)}</div>

                                    <div className={cx("userName")}>{member.full_name}</div>
                                </div>
                            </li>
                        ))
                    ) : (
                        <p className={cx("emptyList")}>No members added yet.</p>
                    )}
                </ul>
            </div>
        </section>
    )
}

export default Details
