import React, { Component } from "react"
import styles from "./duplicateproduct.module.css"
import withStyles from "HOC/withStyles"
import fetch from "helpers/fetch"
import { withRouter } from "react-router-dom"
import Button from "components/Reusable/Form/Button/index"

class DuplicateProduct extends Component {
    state = {
        inputValue: `Copy of ${this.props.productName}`,
        isLoading: false,
        error: ""
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.error && this.props.error !== nextProps.error) {
            this.setState({
                isLoading: false
            })
        }
    }

    render() {
        const { inputValue, error, isLoading } = this.state
        const { cx } = this.props

        return (
            <div className={cx("modal-background", { loading: isLoading })}>
                <div className={cx("modal-cover")}>
                    <h1>Duplicate this product?</h1>
                    <label htmlFor="duplicateProductModal">Provide a name for your new product</label>
                    <input
                        id="duplicateProductModal"
                        type="text"
                        className={cx("input", { isError: !!error })}
                        value={inputValue}
                        onChange={this.handleChangeInput}
                        disabled={isLoading}
                    />
                    {error && <span className={cx("error-message")}>{error}</span>}
                    <footer className={cx("footer")}>
                        <span className={cx("cancel")} onClick={this.handleHide}>
                            Cancel
                        </span>
                        <Button
                            className={[cx("first-button"), cx("button")]}
                            onClick={() => {
                                this.handleSubmit()
                            }}
                            label="Duplicate"
                            isLoading={isLoading}
                        />
                    </footer>
                </div>
            </div>
        )
    }

    handleHide = () => {
        const { handleSetModal } = this.props
        const { isLoading } = this.state

        if (!isLoading) {
            handleSetModal(false)
        }
    }

    handleSubmit() {
        const { productId } = this.props
        const { inputValue, isLoading } = this.state

        if (!isLoading) {
            this.setState({
                isLoading: true
            })

            fetch
                .post(`/products/${productId}/duplicate`, {
                    name: inputValue
                })
                .then(data => {
                    this.setState(
                        {
                            isLoading: false,
                            error: ""
                        },
                        () => {
                            this.handleHide()
                            this.props.history.push(`/products/${data.data.id}`)
                        }
                    )
                })
                .catch(() => {
                    this.setState({
                        isLoading: false,
                        error: "There was a problem with duplicating this product."
                    })
                })
        }
    }

    handleChangeInput = event => {
        this.setState({
            inputValue: event.target.value
        })
    }
}

export default withRouter(withStyles(DuplicateProduct, styles))
