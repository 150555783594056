import React, { Component } from "react"
import { connect } from "react-redux"
import models from "models/index"
import withUploader from "HOC/withUploader"
import * as actions from "actions/category"
import * as notificationActions from "actions/notification"
import isEqual from "lodash/isEqual"

import Form from "./Form"
import FormAbstractContainer from "../../FormContainer"

import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"

const FormWithUploader = withUploader(Form)

class FormContainer extends Component {
    state = {
        imagePath: ""
    }

    componentDidMount() {
        this.setImagePath({})
    }

    componentDidUpdate(prevProps) {
        this.setImagePath(prevProps)
    }

    setImagePath = prevProps => {
        const { data } = this.props
        const dataHasChanged = !isEqual(prevProps.data, data)
        if (dataHasChanged) {
            this.setState({ imagePath: data ? data.image_path : "" })
        }
    }

    render() {
        return (
            <FormWithUploader
                {...this.props}
                imagePath={this.state.imagePath}
                endpoint={DEFAULT_FILE_UPLOAD_ENPOINT}
                handleUploadSuccess={this.handleUploadSuccess}
                handleUploadFailure={this.handleUploadFailure}
                handleUploadReject={this.handleUploadFailure}
            />
        )
    }

    handleUploadSuccess = state => {
        const { handleFieldChange, toRender } = this.props
        const formFields = toRender()
        const { url, id } = state.addedFile

        this.setState({ imagePath: url }, () => {
            handleFieldChange({
                event: {
                    target: {
                        value: id
                    }
                },
                fieldPath: formFields.image_path.fieldPath
            })
        })
    }

    handleUploadFailure = state => {}
}

const mapStateToProps = (state, ownProps) => {
    const data = state.db.categories.current.data
    const categoriesList = state.db.categories.list

    if (ownProps.isNew && data && data.id) {
        return {
            data: {
                parent_category_name: data.name,
                parent_category_id: data.id
            },
            categoriesList
        }
    }

    if (data && data.id) {
        return {
            data: {
                ...data,
                parent_category_id: data.parent_category ? data.parent_category.id : 0,
                parent_category_name: data.parent_category ? data.parent_category.name : ""
            },
            categoriesList
        }
    }

    return {
        data: data,
        categoriesList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: id => dispatch(actions.getCategoryById(id)),
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    FormAbstractContainer(FormContainer, {
        mapStateToProps,
        mapDispatchToProps,
        idParamName: "categoryId",
        model: models.Category
    })
)
