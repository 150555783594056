import React, { useState, useEffect } from "react"
import fetch from "helpers/fetch"
import { SelectWithSearch } from "ui"

const SelectInvoice = ({
    customStyles,
    errors,
    handleSelect,
    name,
    label,
    collectionId,
    collectionType,
    isDisabled,
    value: defaultValue
}) => {
    const [value, setValue] = useState({ label: "" })
    const [values, setValues] = useState([])
    const [pagination, setPagination] = useState({ current_page: 0, last_page: 1 })
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        setValue({ label: defaultValue ? defaultValue.uuid : "", value: defaultValue })
    }, [defaultValue])

    const fetchInvoices = (query, page) => {
        if (isLoading) {
            return
        }

        const formatData = data => {
            return data.map(item => {
                const { uuid: label } = item
                return { value: item, label, secondLabel: "" }
            })
        }

        setLoading(true)

        fetch
            .get(`/invoices?query=${query}&${collectionType}_id=${collectionId}&page=${page}&length=15`)
            .then(data => {
                setValues(page === 1 ? formatData(data.data) : [...values, ...formatData(data.data)])
                setPagination({ ...data.meta, current_page: page })
            })
            .finally(() => setLoading(false))
    }

    return (
        <SelectWithSearch
            name={name}
            label={label}
            value={value.label}
            values={values}
            pagination={pagination}
            setValue={({ value }) => handleSelect(value)}
            placeholder="- Please select -"
            fetchValues={fetchInvoices}
            customStyles={customStyles}
            isDisabled={isDisabled}
        />
    )
}

export default SelectInvoice
