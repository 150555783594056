import React, { Component } from "react"
import { connect } from "react-redux"
import fetch from "helpers/fetch"
import * as featureActions from "actions/feature"

import { EditableList } from "ui"

import ListingLayout from "../../ListingLayout/index"

class Listing extends Component {
    componentDidMount() {
        const { getFeatures } = this.props
        getFeatures()
    }

    handleAdd = () => {
        this.props.history.push("/settings/features/add")
    }

    handleEdit = item => {
        this.props.history.push(`/settings/features/card/${item.id}/edit`)
    }

    handleDeleteFeature = item => {
        const { getFeatures } = this.props
        fetch.deleteRAW(`/product-features/${item.id}`).then(() => {
            getFeatures()
        })
    }

    render() {
        const { features, isLoading, getFeatures } = this.props
        const COLUMNS = [
            {
                name: "name",
                field: "name",
                onClick: item => this.props.history.push(`/settings/features/${item.id}/values`)
            },
            {
                name: "values",
                field: "values"
            }
        ]
        return (
            <ListingLayout>
                <EditableList
                    title="Features"
                    isLoading={isLoading}
                    columns={COLUMNS}
                    data={features.map(feature => ({
                        ...feature,
                        values: feature.values.length
                    }))}
                    dataModel="product_features"
                    fetchData={getFeatures}
                    isIndexColumn
                    onAdd={this.handleAdd}
                    onEdit={this.handleEdit}
                    onDelete={this.handleDeleteFeature}
                    confirmModalTitle="Are you sure you want to delete this feature?"
                    showDeleteCondition={item => item.values === 0}
                />
            </ListingLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        features: state.db.features.list.data,
        isLoading: state.db.features.list.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFeatures: () => dispatch(featureActions.getFeatures())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing)
