import _merge from "lodash/merge"
import _set from "lodash/set"

export default function apiErrorsAdapter(apiErrors) {
    return Object.entries(apiErrors).reduce((errors, [path, fieldErrors]) => {
        if (Array.isArray(fieldErrors) && fieldErrors[0]) {
            return _merge(errors, _set({}, path, fieldErrors[0]))
        }

        return errors
    }, {})
}
