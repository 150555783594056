import React, { Component } from "react"

import Layout from "components/Reusable/Layout/index"

class ListingLayout extends Component {
    render() {
        const { children } = this.props
        return (
            <Layout
                {...this.props}
                title={"Product Management"}
                tabs={[
                    {
                        url: "/settings/categories",
                        label: "Categories"
                    },
                    {
                        url: "/settings/brands",
                        label: "Brands"
                    },
                    {
                        url: "/settings/attribute-groups",
                        label: "Attribute Groups"
                    },
                    {
                        url: "/settings/features",
                        label: "Features"
                    },
                    {
                        url: "/settings/combination-template",
                        label: "Combination Templates"
                    },
                    {
                        url: "/settings/locations",
                        label: "Locations"
                    }
                ]}
            >
                {children}
            </Layout>
        )
    }
}

export default ListingLayout
