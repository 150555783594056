import React, { useState } from "react"
import { useField, useFormikContext } from "formik"
import fetch from "helpers/fetch"

import ShowProductLink from "./ShowProductLink"

const ShowProductLinkContainer = () => {
    const [isLoading, setIsLoading] = useState({ product: false, second_product: false })

    const { setFieldError, values } = useFormikContext()
    const [showLink] = useField("settings.showLink")
    const [productLinkType] = useField("settings.product_link_type")
    const [product, productMeta] = useField("product")
    const [linkPrefix, linkPrefixMeta] = useField("settings.link_prefix")
    const [linkText, linkTextMeta] = useField("settings.link_text")
    const [linkExternal, linkExternalMeta] = useField("settings.link_external")
    const [secondLinkPrefix, secondLinkPrefixMeta] = useField("settings.second_link_prefix")
    const [secondLinkText, secondLinkTextMeta] = useField("settings.second_link_text")
    const [secondLinkExternal, secondLinkExternalMeta] = useField("settings.second_link_external")
    const [secondProduct, secondProductMeta] = useField("second_product")
    const [secondProductLinkType] = useField("settings.second_product_link_type")

    const selectProduct = async (id, productField) => {
        setFieldError(productField.name, null)

        if (!id) {
            return productField.onChange({ target: { ...productField, value: undefined } })
        }

        const updateLoading = loading => setIsLoading(wasLoading => ({ ...wasLoading, [productField.name]: loading }))

        updateLoading(true)

        try {
            const newProduct = await fetch.get(`/products/${id}`)

            productField.onChange({
                target: {
                    ...productField,
                    value: {
                        id: newProduct.data.id,
                        name: newProduct.data.name,
                        image: newProduct.data.images.thumb[0].url
                    }
                }
            })
        } catch (error) {
            productField.onChange({ target: { ...productField, value: undefined } })
        }

        updateLoading(false)
    }

    return (
        <ShowProductLink
            showLink={showLink}
            productLinkType={productLinkType}
            secondProductLinkType={secondProductLinkType}
            product={{ ...product, ...productMeta }}
            linkPrefix={{ ...linkPrefix, ...linkPrefixMeta }}
            linkText={{ ...linkText, ...linkTextMeta }}
            linkExternal={{ ...linkExternal, ...linkExternalMeta }}
            secondLinkPrefix={{ ...secondLinkPrefix, ...secondLinkPrefixMeta }}
            secondLinkText={{ ...secondLinkText, ...secondLinkTextMeta }}
            secondLinkExternal={{ ...secondLinkExternal, ...secondLinkExternalMeta }}
            secondProduct={{ ...secondProduct, ...secondProductMeta }}
            isSecondProductShown={values.settings.type === "double"}
            isLoading={isLoading}
            selectProduct={selectProduct}
        />
    )
}

export default ShowProductLinkContainer
