import React, { Fragment } from "react"
import moment from "moment"

import { Skeleton } from "ui"
import withStyles from "HOC/withStyles"
import ReturnHeader from "components/Reusable/ReturnHeader/ReturnHeader"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import { Head, SelectionList } from "./components"

import styles from "./Details.css"
import stylesReturnHeader from "./overrides/ReturnHeader.css"

const Details = props => {
    const {
        cx,
        selectionId,
        selection,
        openEditModal,
        openDeleteModal,
        fetchStatus,
        selectedProducts,
        setSelectedProducts,
        handleExport,
        isExporting
    } = props

    return (
        <Fragment>
            <Skeleton
                fetchStatus={fetchStatus}
                fallback={() => <div>An error occurred, please try again.</div>}
                SkeletonComponent={() => (
                    <div className={cx("loaderContainer")}>
                        <SnakeLoader />
                    </div>
                )}
                firstTime={true}
            >
                <ReturnHeader
                    link="/products/selections"
                    label="All selections"
                    isArrow={true}
                    customStyles={stylesReturnHeader}
                />

                <section className={cx("section")}>
                    <Head
                        openEditModal={openEditModal}
                        openDeleteModal={openDeleteModal}
                        item={{
                            name: selection.name,
                            client: selection.user,
                            isPrivate: selection.type === "private",
                            description: selection.description,
                            created_at: moment(selection.created_at).format("DD MMM YYYY"),
                            updated_at: moment(selection.updated_at).format("DD MMM YYYY")
                        }}
                        selectedProducts={selectedProducts}
                        handleExport={handleExport}
                        isExporting={isExporting}
                    />
                </section>

                <section className={cx("section")}>
                    <SelectionList
                        selectionId={selectionId}
                        selection={selection}
                        selectedProducts={selectedProducts}
                        setSelectedProducts={setSelectedProducts}
                    />
                </section>
            </Skeleton>
        </Fragment>
    )
}

export default withStyles(Details, styles)
