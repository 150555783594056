import { combineReducers } from "redux"

import list from "./list"
import current from "./current"

const uploadModuleReducer = combineReducers({
    list,
    current
})

export default uploadModuleReducer
