import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./UsersList.css"
import UserData from "../UserData/UserData"

class UsersList extends Component {
    render() {
        const { cx, users, isLoaded } = this.props

        return (
            <div className={cx("users-list-cover")}>
                <div>
                    <h4 className={cx("no")}>NO.</h4>
                    <h4 className={cx("email")}>E-MAIL ADDRESS</h4>
                    <h4 className={cx("name")}>NAME</h4>
                    <h4 className={cx("surname")}>SURNAME</h4>
                    <h4 className={cx("role")}>ROLE</h4>
                    <h4 className={cx("permissions")}>PERMISSIONS</h4>
                    <ul>
                        {isLoaded &&
                            users.map(
                                (
                                    {
                                        id,
                                        email,
                                        first_name,
                                        last_name,
                                        permissions_count,
                                        total_permissions_count,
                                        role
                                    },
                                    index
                                ) => (
                                    <UserData
                                        key={email}
                                        id={id}
                                        email={email}
                                        first_name={first_name}
                                        last_name={last_name}
                                        permissions_count={permissions_count}
                                        total_permissions_count={total_permissions_count}
                                        role={role}
                                        index={index}
                                        deleteThisUser={this.props.deleteThisUser}
                                    />
                                )
                            )}
                    </ul>
                    <ul className={cx(isLoaded ? "none" : null)}>
                        {Array(10)
                            .fill()
                            .map((e, i) => i + 1)
                            .map(key => (
                                <li key={key} className={cx("loading-placeholder")}>
                                    <div className={cx("no-placeholder")} />
                                    <div className={cx("email-placeholder")} />
                                    <div className={cx("name-placeholder")} />
                                    <div className={cx("surname-placeholder")} />
                                    <div className={cx("role-placeholder")} />
                                    <div className={cx("permissions-placeholder")} />
                                    <div className={cx("edit-placeholder")} />
                                    <div className={cx("delete-placeholder")} />
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default withStyles(UsersList, styles)
