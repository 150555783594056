import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import idx from "idx"

import { getBrandById, resetCurrentBrand } from "actions/brand"
import Overview from "./Overview"
import PropTypes from "prop-types"

const OverviewContainer = props => {
    const { match, history } = props
    const brandId = match.params.brandId
    const sectionName = match.params.sectionName

    const dispatch = useDispatch()
    const brand = useSelector(state => state.db.brands.current)

    useEffect(() => {
        dispatch(getBrandById(brandId))
    }, [brandId])

    useEffect(() => {
        return () => {
            dispatch(resetCurrentBrand())
        }
    }, [])

    useEffect(() => {
        if (idx(brand, _ => _.fetchStatus.isError)) {
            history.push("/settings/brands")
        }
    }, [brand])

    return <Overview brand={brand} sectionName={sectionName} fetchBrand={() => dispatch(getBrandById(brandId))} />
}

OverviewContainer.propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

export default OverviewContainer
