import React, { useState, useCallback, useMemo, useEffect, useContext } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import { useQueryClient } from "react-query"
import PropTypes from "prop-types"
import _get from "lodash/get"

import { H1, KanbanBoard, KanbanColumn, KANBAN_ROW_TYPE, HeaderSelect } from "@butterfly-frontend/ui"
import { Header, KanbanColumnFetchStatus } from "modules/WmsModule/components"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import { useShipmentEdit, KEY as SHIPMENTS_KEY } from "modules/WmsModule/hooks/api/useShipment"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import useConsignmentNoteOptions from "modules/WmsModule/hooks/useConsignmentNoteOptions"
import { useShipmentList } from "modules/WmsModule/hooks/api/useShipment"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import { useActions } from "hooks"
import { combineFetchStatuses } from "helpers/fetchStatus"
import ListFiltersContext from "modules/WmsModule/contexts/ListFiltersContext"
import { getShipmentsAvailableForConsignmentNote } from "modules/WmsModule/helpers/consignmentNote"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"
import { checkWhatTypeOfShipmentCanBeSeen } from "modules/WmsModule/helpers/shipment"

import ShipmentToPackRow from "./ShipmentToPackRow"
import ShipmentReadyForShippingRow from "./ShipmentReadyForShippingRow"
import ShipmentAlreadyShippedRow from "./ShipmentAlreadyShippedRow"

import styles from "./ShipmentListPage.module.css"

const COMMON_SHIPMENT_QUERY_PARAMS = { today: true, length: 9999, sort_by: "status_updated_at" }
const SHIPMENTS_TO_PACK_QUERY_PARAMS = { to_package: true, sort_direction: "asc", ...COMMON_SHIPMENT_QUERY_PARAMS }
const SHIPMENTS_READY_FOR_SHIPPING_QUERY_PARAMS = {
    ready_for_shipping: true,
    sort_direction: "asc",
    ...COMMON_SHIPMENT_QUERY_PARAMS
}
const SHIPMENTS_SHIPPED_QUERY_PARAMS = { shipped: true, sort_direction: "desc", ...COMMON_SHIPMENT_QUERY_PARAMS }

const DANGER_STATUSES = [SHIPMENT_STATUS_KEYS.ON_HOLD, SHIPMENT_STATUS_KEYS.CANCELLED]
const SUCCESS_STATUSES = [SHIPMENT_STATUS_KEYS.SENT]

const KANBAN_BOX_OPTIONS_LABELS = {
    print: "Print all package labels",
    download: "Download all package labels"
}

const KANBAN_CONSIGNMENT_NOTE_OPTIONS_LABELS = {
    print: "Print all shipping bills of landing",
    download: "Download all shipping bills of landing"
}

const ALL_WAREHOUSES_SELECT_OPTION = { id: "0", name: "All" }

const getRowType = shipment =>
    DANGER_STATUSES.includes(shipment.status.status)
        ? KANBAN_ROW_TYPE.DANGER
        : SUCCESS_STATUSES.includes(shipment.status.status)
        ? KANBAN_ROW_TYPE.SUCCESS
        : KANBAN_ROW_TYPE.DEFAULT

const getShipmentHref = shipment => `/wms/shipments/${shipment.id}`

const getStockItemIdsFromShipments = (shipments = []) =>
    shipments.reduce(
        (stockItemsIds, { status, stock_items_ids }) =>
            DANGER_STATUSES.includes(status.status) ? stockItemsIds : [...stockItemsIds, ...stock_items_ids],
        []
    )

const ShipmentListPage = ({ canAccess }) => {
    const history = useHistory()
    const queryClient = useQueryClient()
    const shipmentEdit = useShipmentEdit({ skipInvalidateQueries: true })
    const actions = useActions({ showErrorNotification, showSuccessNotification })
    const [shippingIds, setShippingIds] = useState([])
    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)
    const canAccessIncomingStockItemShipments = useMemo(() => canAccess(PERMISSIONS.context.INCOMING_SHIPMENTS), [])
    const canAccessShipmentsAndReceivings = useMemo(() => canAccess(PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS), [])
    const filteredShipmentTypes = useMemo(
        () =>
            checkWhatTypeOfShipmentCanBeSeen({
                canSeeShipmentsFromPO: canAccessIncomingStockItemShipments,
                canSeeRegularShipments: canAccessShipmentsAndReceivings
            }),
        []
    )
    const {
        set: setFiltersContext,
        values: { shipmentsList: filtersFromContext }
    } = useContext(ListFiltersContext)
    const [selectedWarehouse, setSelectedWarehouse] = useState(
        filtersFromContext.sourceWarehouse || ALL_WAREHOUSES_SELECT_OPTION
    )

    useEffect(() => {
        setFiltersContext(prevState => ({
            ...prevState,
            shipmentsList: {
                sourceWarehouse: selectedWarehouse
            }
        }))
    }, [selectedWarehouse])

    const shipmentListProps = useMemo(
        () => ({
            params: {
                source_warehouses: _get(selectedWarehouse, "id", undefined),
                type: filteredShipmentTypes.join(",")
            },
            reactQueryProps: {
                enabled: !!filteredShipmentTypes
            }
        }),
        [selectedWarehouse, filteredShipmentTypes]
    )

    const redirectToShipmentDetails = useCallback(shipment => history.push(`/wms/shipments/${shipment.id}`), [
        history.push
    ])

    const shipmentToPackListParams = useMemo(
        () => ({ ...SHIPMENTS_TO_PACK_QUERY_PARAMS, ...shipmentListProps.params }),
        [shipmentListProps.params]
    )
    const shipmentReadyForShippingListParams = useMemo(
        () => ({ ...SHIPMENTS_READY_FOR_SHIPPING_QUERY_PARAMS, ...shipmentListProps.params }),
        [shipmentListProps.params]
    )
    const shipmentShippedListParams = useMemo(
        () => ({ ...SHIPMENTS_SHIPPED_QUERY_PARAMS, ...shipmentListProps.params }),
        [shipmentListProps.params]
    )

    const changeShipmentStatusToSent = shipment => {
        const { id } = shipment

        setShippingIds(prevState => [...prevState, id])
        shipmentEdit
            .mutate({
                id,
                data: { status: "sent" }
            })
            .then(({ data: mutationData }) => {
                setShippingIds(prevState => prevState.filter(shippingId => shippingId !== id))

                queryClient.setQueryData([SHIPMENTS_KEY, shipmentShippedListParams], ({ data }) => ({
                    data: [{ ...shipment, status: mutationData.status }, ...data]
                }))

                queryClient.setQueryData([SHIPMENTS_KEY, shipmentReadyForShippingListParams], ({ data }) => ({
                    data: data.filter(item => item.id !== id)
                }))

                actions.showSuccessNotification({ title: "Shipment sent successfully" })
            })
            .catch(actions.showErrorNotification)
    }

    const { data: warehouseList, fetchStatus: warehouseListFetchStatus } = useWarehouseList({
        params: {
            warehouses: assignedWarehouseIds
        }
    })

    const { data: shipmentsToPack, fetchStatus: shipmentsToPackFetchStatus } = useShipmentList({
        ...shipmentListProps,
        params: shipmentToPackListParams
    })

    const { data: shipmentsReadyForShipping, fetchStatus: shipmentsReadyForShippingFetchStatus } = useShipmentList({
        ...shipmentListProps,
        params: shipmentReadyForShippingListParams
    })

    const { data: shipmentsAlreadyShipped, fetchStatus: shipmentsAlreadyShippedFetchStatus } = useShipmentList({
        ...shipmentListProps,
        params: shipmentShippedListParams
    })

    const { options: shipmentsToPackBoxOptions, isLoading: isShipmentsToPackBoxOptionsLoading } = useBoxLabelOptions({
        ids: getStockItemIdsFromShipments(shipmentsToPack),
        labels: KANBAN_BOX_OPTIONS_LABELS
    })
    const {
        options: shipmentsReadyForShippingBoxOptions,
        isLoading: isShipmentsReadyForShippingBoxOptionsLoading
    } = useBoxLabelOptions({
        ids: getStockItemIdsFromShipments(shipmentsReadyForShipping),
        labels: KANBAN_BOX_OPTIONS_LABELS
    })
    const {
        options: shipmentsAlreadyShippedBoxOptions,
        isLoading: isShipmentsAlreadyShippedBoxOptionsLoading
    } = useBoxLabelOptions({
        ids: getStockItemIdsFromShipments(shipmentsAlreadyShipped),
        labels: KANBAN_BOX_OPTIONS_LABELS
    })

    const {
        options: shipmentsToPackConsignmentNoteOptions,
        isLoading: isShipmentsToPackConsignmentNoteOptionsLoading
    } = useConsignmentNoteOptions({
        ids: getShipmentsAvailableForConsignmentNote(shipmentsToPack),
        labels: KANBAN_CONSIGNMENT_NOTE_OPTIONS_LABELS
    })

    const {
        options: shipmentsReadyForShippingConsignmentNoteOptions,
        isLoading: isShipmentsReadyForShippingConsignmentNoteOptionsLoading
    } = useConsignmentNoteOptions({
        ids: getShipmentsAvailableForConsignmentNote(shipmentsReadyForShipping),
        labels: KANBAN_CONSIGNMENT_NOTE_OPTIONS_LABELS
    })

    const {
        options: shipmentsAlreadyShippedConsignmentNoteOptions,
        isLoading: isShipmentsAlreadyShippedConsignmentNoteOptionsLoading
    } = useConsignmentNoteOptions({
        ids: getShipmentsAvailableForConsignmentNote(shipmentsAlreadyShipped),
        labels: KANBAN_CONSIGNMENT_NOTE_OPTIONS_LABELS
    })

    const shipmentsToPackAndWarehousesFetchStatuses = combineFetchStatuses([
        warehouseListFetchStatus,
        shipmentsToPackFetchStatus
    ])
    const shipmentsReadyToShippingAndWarehousesFetchStatuses = combineFetchStatuses([
        warehouseListFetchStatus,
        shipmentsReadyForShippingFetchStatus
    ])
    const shipmentsAlreadyShippedAndWarehousesFetchStatuses = combineFetchStatuses([
        warehouseListFetchStatus,
        shipmentsAlreadyShippedFetchStatus
    ])

    return (
        <div className={styles.page}>
            <Header>
                <H1 withLeftBorder>Shipments:</H1>
                <HeaderSelect
                    options={[ALL_WAREHOUSES_SELECT_OPTION, ...warehouseList].map(({ id, name }) => ({
                        id,
                        label: name
                    }))}
                    value={{ id: selectedWarehouse.id, label: selectedWarehouse.name }}
                    onChange={option => setSelectedWarehouse({ name: option.label, ...option })}
                    isLoading={warehouseListFetchStatus.isLoading}
                    disabled={!warehouseList.length}
                />
            </Header>
            <KanbanBoard>
                <KanbanColumn
                    header="Package slips"
                    headerColor="rgba(239, 121, 103, 0.5)"
                    actions={[...shipmentsToPackBoxOptions, ...shipmentsToPackConsignmentNoteOptions]}
                    isLoading={
                        isShipmentsToPackBoxOptionsLoading ||
                        shipmentsToPackAndWarehousesFetchStatuses.isLoading ||
                        isShipmentsToPackConsignmentNoteOptionsLoading
                    }
                    disabled={shipmentsToPack.length === 0}
                >
                    <KanbanColumnFetchStatus
                        fetchStatus={shipmentsToPackAndWarehousesFetchStatuses}
                        isEmpty={shipmentsToPack.length === 0}
                        accessDenied={filteredShipmentTypes.length === 0}
                    >
                        {shipmentsToPack.map(shipment => (
                            <ShipmentToPackRow
                                shipment={shipment}
                                rowType={getRowType(shipment)}
                                getShipmentHref={getShipmentHref}
                                redirectToShipmentDetails={redirectToShipmentDetails}
                                key={shipment.id}
                            />
                        ))}
                    </KanbanColumnFetchStatus>
                </KanbanColumn>

                <KanbanColumn
                    header="Packed packages"
                    headerColor="rgba(243, 202, 134, 0.8)"
                    actions={[
                        ...shipmentsReadyForShippingBoxOptions,
                        ...shipmentsReadyForShippingConsignmentNoteOptions
                    ]}
                    isLoading={
                        isShipmentsReadyForShippingBoxOptionsLoading ||
                        shipmentsReadyToShippingAndWarehousesFetchStatuses.isLoading ||
                        isShipmentsReadyForShippingConsignmentNoteOptionsLoading
                    }
                    disabled={shipmentsReadyForShipping.length === 0}
                >
                    <KanbanColumnFetchStatus
                        fetchStatus={shipmentsReadyToShippingAndWarehousesFetchStatuses}
                        isEmpty={shipmentsReadyForShipping.length === 0}
                        accessDenied={filteredShipmentTypes.length === 0}
                    >
                        {shipmentsReadyForShipping.map(shipment => (
                            <ShipmentReadyForShippingRow
                                shipment={shipment}
                                rowType={getRowType(shipment)}
                                getShipmentHref={getShipmentHref}
                                changeShipmentStatusToSent={changeShipmentStatusToSent}
                                isLoading={shippingIds.includes(shipment.id)}
                                key={shipment.id}
                            />
                        ))}
                    </KanbanColumnFetchStatus>
                </KanbanColumn>

                <KanbanColumn
                    header="Shipped packages"
                    headerColor="rgba(171, 214, 78, 0.5)"
                    actions={[...shipmentsAlreadyShippedBoxOptions, ...shipmentsAlreadyShippedConsignmentNoteOptions]}
                    isLoading={
                        isShipmentsAlreadyShippedBoxOptionsLoading ||
                        shipmentsAlreadyShippedAndWarehousesFetchStatuses.isLoading ||
                        isShipmentsAlreadyShippedConsignmentNoteOptionsLoading
                    }
                    disabled={shipmentsAlreadyShipped.length === 0}
                >
                    <KanbanColumnFetchStatus
                        fetchStatus={shipmentsAlreadyShippedAndWarehousesFetchStatuses}
                        isEmpty={shipmentsAlreadyShipped.length === 0}
                        accessDenied={filteredShipmentTypes.length === 0}
                    >
                        {shipmentsAlreadyShipped.map(shipment => (
                            <ShipmentAlreadyShippedRow
                                shipment={shipment}
                                rowType={getRowType(shipment)}
                                getShipmentHref={getShipmentHref}
                                key={shipment.id}
                            />
                        ))}
                    </KanbanColumnFetchStatus>
                </KanbanColumn>
            </KanbanBoard>
        </div>
    )
}

ShipmentListPage.propTypes = {
    canAccess: PropTypes.func.isRequired
}

export default withPermissions(ShipmentListPage)
