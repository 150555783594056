import React from "react"
import { useField } from "formik"

import { Textarea, Input } from "ui"
import { Address, Section as FormSection } from "ui/AddEditForm"
import withStyles from "HOC/withStyles"

import styles from "./styles.css"
import textareaStyles from "./overrides/textarea.css"
import inputStyles from "./overrides/inputStyles.css"

import addressesConfig from "./config"

const GeneralInfoSection = props => {
    const { cx, isRental, labelsOfType, copyAddress } = props

    const [description, descriptionMeta] = useField("description")
    const [instructions, instructionsMeta] = useField("instructions")
    const [totalRent, totalRentMeta] = useField("total_rent")

    return (
        <div className={cx("root")}>
            <div className={cx("wrapper")}>
                <Address
                    label="Billing Address"
                    copyFrom={labelsOfType.copyAddress}
                    copyAddress={copyAddress.billing_address}
                    config={addressesConfig.billing_address}
                />
                <Address
                    label="Shipping Address"
                    copyFrom={labelsOfType.copyAddress}
                    copyAddress={copyAddress.shipping_address}
                    config={addressesConfig.shipping_address}
                />
                <FormSection label="Invoice Description">
                    <Textarea
                        {...description}
                        value={description.value || ""}
                        name={description.name}
                        label="Click here to add a description..."
                        onChange={({ event }) => description.onChange(event)}
                        error={descriptionMeta.error}
                        isPlaceholder
                        customStyles={textareaStyles}
                    />
                </FormSection>
                <div className={cx("flexWrapper")}>
                    <FormSection className={cx("specialInstructions")} label="Special instructions">
                        <Textarea
                            {...instructions}
                            value={instructions.value || ""}
                            name={instructions.name}
                            label="Click here to add special instructions..."
                            onChange={({ event }) => instructions.onChange(event)}
                            error={instructionsMeta.error}
                            isPlaceholder
                            customStyles={textareaStyles}
                        />
                    </FormSection>
                    {isRental && (
                        <FormSection className={cx("totalRent")} label="Total rental">
                            <Input
                                {...totalRent}
                                error={totalRentMeta.error}
                                onChange={({ event }) => totalRent.onChange(event)}
                                customStyles={inputStyles}
                                type="number"
                            />
                        </FormSection>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withStyles(GeneralInfoSection, styles)
