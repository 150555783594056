import STOCK_ITEM_STATUS_KEYS from "./stockItemStatusKeys"
import STATUS_COLORS from "./statusColors"

const STOCK_ITEM_STATUSES = {
    [STOCK_ITEM_STATUS_KEYS.AVAILABLE]: {
        id: STOCK_ITEM_STATUS_KEYS.AVAILABLE,
        label: "Available",
        color: STATUS_COLORS.GREEN,
        value: STOCK_ITEM_STATUS_KEYS.AVAILABLE,
        theme: "outline"
    },
    [STOCK_ITEM_STATUS_KEYS.COMMITTED]: {
        id: STOCK_ITEM_STATUS_KEYS.COMMITTED,
        label: "Committed",
        color: STATUS_COLORS.RED,
        value: STOCK_ITEM_STATUS_KEYS.COMMITTED,
        theme: "outline"
    },
    [STOCK_ITEM_STATUS_KEYS.IN_STOCK]: {
        id: STOCK_ITEM_STATUS_KEYS.IN_STOCK,
        label: "In stock",
        color: STATUS_COLORS.GREEN,
        value: STOCK_ITEM_STATUS_KEYS.IN_STOCK,
        theme: "outline"
    },
    [STOCK_ITEM_STATUS_KEYS.ON_HOLD]: {
        id: STOCK_ITEM_STATUS_KEYS.ON_HOLD,
        label: "On hold",
        color: STATUS_COLORS.ORANGE,
        value: STOCK_ITEM_STATUS_KEYS.ON_HOLD,
        theme: "outline"
    },
    [STOCK_ITEM_STATUS_KEYS.SOLD]: {
        id: STOCK_ITEM_STATUS_KEYS.SOLD,
        label: "Sold",
        color: STATUS_COLORS.GRAY,
        value: STOCK_ITEM_STATUS_KEYS.SOLD,
        theme: "inactiveFill"
    },
    [STOCK_ITEM_STATUS_KEYS.NOT_FOR_SALE]: {
        id: STOCK_ITEM_STATUS_KEYS.NOT_FOR_SALE,
        label: "Not for sale",
        color: STATUS_COLORS.GRAY,
        value: STOCK_ITEM_STATUS_KEYS.NOT_FOR_SALE,
        theme: "inactiveFill"
    }
}

export default STOCK_ITEM_STATUSES
