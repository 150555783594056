import React from "react"

import { withStyles } from "ui/FilterableTable/HOC"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import styles from "./TableBody.css"

export default withStyles(styles)(({ cx, colSpan }) => (
    <tr>
        <td className={cx("td")} colSpan={colSpan}>
            <SnakeLoader />
        </td>
    </tr>
))
