import React, { useState, useEffect, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"

import withStyles from "HOC/withStyles"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import * as selectionActions from "modules/SelectionsModule/actions"
import { setChoosenSelectionInLocalStore, attachSelectionProductsById, setChoosenSelection } from "./../../actions"
import ChooseSelectionsModal from "modules/SelectionsModule/components/ChooseSelectionsModal"
import Loader from "components/Reusable/Loader/Loader"
import Modal from "modules/SelectionsModule/components/modal"
import { Skeleton, Modal as ModalComponent } from "ui"

import loadingModalStyles from "./loadingModalStyles.css"
import styles from "./styles.css"

const ChooseSelectionsFlow = props => {
    const { close, chooseSelectionFlow } = props

    const { ids: productsIds } = chooseSelectionFlow

    const [isModalFetching, setIsModalFetching] = useState(false)
    const [error, setError] = useState(null)

    const [isSelectModalOpen, setIsSelectModalOpen] = useState(false)
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
    const [selectedId, setSelectedId] = useState(null)

    const dispatch = useDispatch()

    const selectionId = useSelector(state => state.db.selectionsWidget.current.choosenSelection)
    const selections = useSelector(state => state.db.selections.list)
    const { isLoaded, isError } = selections.fetchStatus

    const openSelectModal = () => setIsSelectModalOpen(true)
    const openCreateModal = () => setIsSelectModalOpen(false) || setIsCreateModalOpen(true)

    useEffect(() => {
        fetchSelections()
    }, [])

    useEffect(() => {
        !!isError && dispatch(showErrorNotification())

        const empty = selections && selections.meta && selections.meta.total === 0

        !!isLoaded && (empty ? openCreateModal() : openSelectModal())
    }, [selections.fetchStatus])

    return (
        <Fragment>
            <Skeleton
                fetchStatus={selections.fetchStatus}
                fallback={() => <div>An error occurred, please try again.</div>}
                SkeletonComponent={() => renderLoadingModal()}
                firstTime={true}
            >
                {renderCreateModal()}
                {renderSelectModal()}
            </Skeleton>
        </Fragment>
    )

    function renderCreateModal() {
        return (
            <Modal
                title={"Create new selection"}
                saveButtonLabel={"Create"}
                selection={{ name: "", description: "", type: "private" }}
                submit={createNewSelection}
                isFetching={isModalFetching}
                handleClose={close}
                isOpen={isCreateModalOpen}
                error={error}
                setError={setError}
            />
        )
    }

    function renderSelectModal() {
        return (
            <ChooseSelectionsModal
                title="Choose a selection:"
                isOpen={isSelectModalOpen}
                onClose={close}
                handleCreateNewSelection={openCreateModal}
                handleSelect={handleSelect}
                isVisibleActiveSelection
                activeSelectionId={parseInt(selectionId, 10) || -1}
                loadingSelectionId={selectedId}
            />
        )
    }

    function renderLoadingModal() {
        const { cx } = props

        return (
            <ModalComponent customStyles={loadingModalStyles} isOpen={true} closeModal={() => {}}>
                <div className={cx("loader")}>
                    <Loader />
                </div>
            </ModalComponent>
        )
    }

    function fetchSelections() {
        dispatch(selectionActions.getSelections({ length: 1 }))
    }

    function createNewSelection(body) {
        setIsModalFetching(true)
        return selectionActions
            .createSelection({
                ...body,
                products: productsIds ? productsIds.map(productId => ({ id: productId })) : []
            })
            .then(data => {
                dispatch(showSuccessNotification())
                setChoosenSelectionInLocalStore(data.data.id)
                dispatch(setChoosenSelection(data.data.id))
                close()
            })
            .catch(err => {
                setError(err.errors)
                setIsModalFetching(false)
            })
    }

    function handleSelect(id) {
        setSelectedId(id)
        if (productsIds && productsIds.length) {
            attachSelectionProductsById(productsIds.map(productId => ({ id: productId })), id)
                .then(() => {
                    dispatch(showSuccessNotification())
                    setChoosenSelectionInLocalStore(id)
                    dispatch(setChoosenSelection(id))
                    close()
                })
                .catch(() => {
                    dispatch(showErrorNotification())
                })
        } else {
            setChoosenSelectionInLocalStore(id)
            dispatch(setChoosenSelection(id))
            close()
        }
    }
}

export default withStyles(ChooseSelectionsFlow, styles)
