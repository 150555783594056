import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./ClientCard.css"
import * as clientActions from "actions/client"
import ClientCardHeader from "./ClientCardHeader/ClientCardHeader"
import ClientCardAddresses from "./ClientCardAddresses/ClientCardAddresses"
import ClientCardFavorites from "./ClientCardFavorites/index"
import Notes from "components/Reusable/Notes/index"
import ActivityLog from "components/Reusable/ActivityLog/index"
import { withRouter } from "react-router-dom"

class ClientCard extends Component {
    componentDidMount() {
        this.resolveClient(this.props)
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { match } = this.props
        const prevId = match && match.params.clientId ? match.params.clientId : null
        this.resolveClient(nextProps, prevId)
    }

    resolveClient(props, prevId = null) {
        const { match, getClientById } = props

        if (match && match.params && match.params.clientId && match.params.clientId !== prevId) {
            getClientById(match.params.clientId)
        }
    }

    render() {
        const { fullProfile, client, deleteNote } = this.props
        return (
            <div styleName={fullProfile ? "full-profile" : "client-card-cover"}>
                <div styleName={"left-margin"}>
                    <ClientCardHeader {...this.props} />
                    <ClientCardAddresses {...this.props} />
                    <ClientCardFavorites {...this.props} />
                </div>
                <Notes
                    notes={client.data.notes}
                    contextId={client.data.id}
                    deleteNote={deleteNote}
                    addNoteParams={{
                        id: client.data.id,
                        type: "client",
                        successAction: clientActions.getClientById(client.data.id)
                    }}
                />
                <ActivityLog activities={client.data.activities} />
            </div>
        )
    }
}

export default withRouter(CSSModules(ClientCard, style))
