import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./Categories.css"

import removeIcon from "assets/close.svg"

import selectStyles from "../overrides/Select.css"
import { SelectWithSearch } from "ui"

class Categories extends Component {
    render() {
        const { cx, categories, selectedCategories } = this.props
        return (
            <div className={cx("root")}>
                {this.renderCategorySelect({
                    level: 0,
                    categories: categories.filter(category => {
                        return category.parent_category === null
                    })
                })}
                {selectedCategories &&
                    selectedCategories.map((id, index) => {
                        const filteredCategory = categories.filter(category => category.id === id)

                        if (filteredCategory && filteredCategory[0] && filteredCategory[0].subcategories.length > 0) {
                            return this.renderCategorySelect({
                                level: index + 1,
                                categories: filteredCategory[0].subcategories
                            })
                        }

                        return null
                    })}
            </div>
        )
    }

    renderCategorySelect = ({ level, categories }) => {
        const {
            cx,
            handleSelectCategory,
            selectedCategories,
            categoryIndex,
            handleRemoveCategory,
            isTablet
        } = this.props

        const isListOnTop = isTablet

        let currentCategory = categories.find(el => el.id === selectedCategories[level])
        currentCategory = currentCategory ? currentCategory.name : ""

        return (
            <div key={level} className={cx("category")}>
                <SelectWithSearch
                    label={level ? null : "Category " + (categoryIndex + 1)}
                    value={currentCategory}
                    error={!!level && !!!currentCategory}
                    values={categories.map(el => ({ value: el.id, label: el.name }))}
                    setValue={({ value }) => handleSelectCategory({ level, id: value })}
                    customStyles={selectStyles}
                    isListOnTop={isListOnTop}
                    placeholder="-"
                    withoutFetch
                />
                {level === 0 && categoryIndex > 0 && (
                    <img
                        className={cx("remove")}
                        src={removeIcon}
                        alt="remove"
                        onClick={() => handleRemoveCategory({ categoryIndex })}
                    />
                )}
            </div>
        )
    }
}

export default withStyles(Categories, styles)
