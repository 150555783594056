import React, { Component } from "react"

import Skeleton from "ui/FilterableTable/components/Skeleton"

class FetchStatus extends Component {
    render() {
        const { children, skeleton, ...props } = this.props

        return (
            <Skeleton {...props} component={skeleton}>
                {children}
            </Skeleton>
        )
    }
}

export default FetchStatus
