import React, { Component } from "react"
import * as PropTypes from "prop-types"

import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"

import "@fullcalendar/core/main.css?global"
import "@fullcalendar/daygrid/main.css?global"
import "@fullcalendar/timegrid/main.css?global"
import "./FullCalendarWrapper.css?global"

const DAYS = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"]

class FullCalendarWrapper extends Component {
    shouldComponentUpdate(nextProps) {
        return JSON.stringify(nextProps.events) !== JSON.stringify(this.props.events)
    }

    render() {
        const {
            events,
            calendarRef,
            handleEventAllow,
            handleDateClick,
            handleEventClick,
            handleEventDrop,
            handleEventResize,
            handleEventPositioned
        } = this.props
        return (
            <FullCalendar
                ref={calendarRef}
                firstDay={1}
                weekNumbers
                allDayText="All day"
                height="parent"
                defaultView="dayGridMonth"
                slotDuration="00:30:00"
                slotLabelInterval="01:00:00"
                header={false}
                events={events}
                views={{
                    dayGrid: { eventLimit: 4 },
                    timeGridWeek: {
                        columnHeaderFormat: {
                            month: "short",
                            day: "numeric"
                        },
                        columnHeaderHtml: date => `<span>${date.getDate()}</span><span>${DAYS[date.getDay()]}</span>`
                    }
                }}
                eventTimeFormat={{
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true
                }}
                slotLabelFormat={{
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true
                }}
                timeFormat="HH:mm"
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                droppable
                editable
                eventPositioned={handleEventPositioned}
                eventClick={handleEventClick}
                dateClick={handleDateClick}
                eventResize={handleEventResize}
                eventDrop={handleEventDrop}
                eventAllow={handleEventAllow}
            />
        )
    }
}

FullCalendarWrapper.propTypes = {
    ref: PropTypes.any,
    events: PropTypes.array,
    handleEventClick: PropTypes.func,
    handleDateClick: PropTypes.func,
    handleEventResize: PropTypes.func,
    handleEventDrop: PropTypes.func,
    handleEventAllow: PropTypes.func,
    handleEventPositioned: PropTypes.func
}

export default FullCalendarWrapper
