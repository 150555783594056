import React, { Fragment } from "react"
import PropTypes from "prop-types"
import get from "lodash/get"

import { SelectWithSearch } from "ui"
import { DISCOUNT_LIST_PROP_TYPE } from "modules/OrdersModule/propTypes"

import styles from "./SelectDiscountSection.module.css"

const SelectDiscountSection = ({ selectedDiscount, discountList, onChange, isDisabled }) => (
    <div className={styles.root}>
        <h4 className={styles.discountHeader}>DISCOUNT</h4>
        <div className={styles.discountContainer}>
            <div className={styles.selectDiscount}>
                <SelectWithSearch
                    label="Select discounts"
                    values={discountList}
                    value={get(selectedDiscount, "label", "")}
                    setValue={onChange}
                    withoutFetch
                    isDisabled={isDisabled}
                    withoutSearch
                />
            </div>
            <div className={styles.divider} />
            <div className={styles.selectedDiscount}>
                <h5 className={styles.discountHeader}>SELECTED DISCOUNT</h5>
                {selectedDiscount && (
                    <Fragment>
                        <span>{selectedDiscount.label}</span>
                        <span>{selectedDiscount.value.description}</span>
                    </Fragment>
                )}
            </div>
        </div>
    </div>
)

SelectDiscountSection.propTypes = {
    selectedDiscount: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.shape({
            description: PropTypes.string
        }).isRequired
    }),
    discountList: DISCOUNT_LIST_PROP_TYPE.isRequired,
    onChange: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool
}

export default SelectDiscountSection
