import * as constants from "constants/index"

function reducer(state = { isLoading: false, data: {} }, action) {
    switch (action.type) {
        case constants.GET_HOME_PAGE_POST_START:
            return { ...state, isLoading: true, data: {} }
        case constants.GET_HOME_PAGE_POST_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data.data }
        case constants.GET_HOME_PAGE_POST_ERROR:
            return { ...state, isLoading: false, data: {} }

        default:
            return state
    }
}

export default reducer
