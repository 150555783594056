import React from "react"
import PropTypes from "prop-types"

import { DetailsCard, Status, ImageTile } from "@butterfly-frontend/ui"
import { DateLabel } from "modules/WmsModule/components"

import styles from "./ProductCondition.module.css"

const ProductCondition = ({ productConditionData, title }) => {
    return (
        <div className={styles.root}>
            {title && <h2 className={styles.title}>{title}</h2>}
            <div className={styles.productInfoContainer}>
                <DetailsCard header="CREATE DATE">
                    <DateLabel date={productConditionData.created_at} />
                </DetailsCard>
                <DetailsCard header="CONDITION">
                    {productConditionData.name && productConditionData.color ? (
                        <Status theme="outline" color={productConditionData.color} size="small" withCircle>
                            {productConditionData.name}
                        </Status>
                    ) : (
                        <span>Condition not selected</span>
                    )}
                </DetailsCard>
                <DetailsCard header="FROM WAREHOUSE">
                    <div className={styles.imagesContainer}>
                        {productConditionData.images.map(image => (
                            <ImageTile key={image.id} image={image} />
                        ))}
                        {productConditionData.images.length === 0 && (
                            <div className={styles.noImagesInfo}>
                                <span>No images</span>
                            </div>
                        )}
                    </div>
                </DetailsCard>
            </div>
        </div>
    )
}

ProductCondition.propTypes = {
    productConditionData: PropTypes.shape({
        name: PropTypes.string,
        created_at: PropTypes.string.isRequired,
        color: PropTypes.string,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string.isRequired
            })
        ).isRequired
    }),
    title: PropTypes.string
}

export default ProductCondition
