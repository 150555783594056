import STOCK_ITEM_COUNTER_KEYS from "./stockItemCounterKeys"

const STOCK_ITEM_COUNTERS = {
    [STOCK_ITEM_COUNTER_KEYS.AVAILABLE]: {
        id: STOCK_ITEM_COUNTER_KEYS.AVAILABLE,
        label: "Available"
    },
    [STOCK_ITEM_COUNTER_KEYS.COMMITTED]: {
        id: STOCK_ITEM_COUNTER_KEYS.COMMITTED,
        label: "Committed"
    },
    [STOCK_ITEM_COUNTER_KEYS.IN_STOCK]: {
        id: STOCK_ITEM_COUNTER_KEYS.IN_STOCK,
        label: "In stock"
    },
    [STOCK_ITEM_COUNTER_KEYS.INCOMING]: {
        id: STOCK_ITEM_COUNTER_KEYS.INCOMING,
        label: "Incoming"
    },
    [STOCK_ITEM_COUNTER_KEYS.NOT_FOR_SALE]: {
        id: STOCK_ITEM_COUNTER_KEYS.NOT_FOR_SALE,
        label: "Not for sale"
    },
    [STOCK_ITEM_COUNTER_KEYS.ON_HOLD]: {
        id: STOCK_ITEM_COUNTER_KEYS.ON_HOLD,
        label: "On hold"
    },
    [STOCK_ITEM_COUNTER_KEYS.SOLD]: {
        id: STOCK_ITEM_COUNTER_KEYS.SOLD,
        label: "Sold"
    }
}

export default STOCK_ITEM_COUNTERS
