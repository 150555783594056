import { useMemo } from "react"

import { useTableSort } from "@butterfly-frontend/ui"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import { useShipmentDetails } from "modules/WmsModule/hooks/api/useShipment"
import { useStockItemList } from "modules/WmsModule/hooks/api/useStockItem"

const useShipmentDetailsWithItems = ({ shipmentId }) => {
    const { data: shipment, fetchStatus: transferFetchStatus } = useShipmentDetails({ id: shipmentId })

    const { sortState: stockItemsSortState, onChangeSort: onChangeStockItemsSortState } = useTableSort({
        initialState: { direction: "asc", by: "id" }
    })

    const filters = useMemo(() => ({ shipment_id: shipmentId, show_dropshipped: true }), [shipmentId])

    const { data: stockItems, fetchStatus: stockItemsFetchStatus, pagination: stockItemsPagination } = useTableLogic({
        sort: stockItemsSortState,
        filters,
        useQueryList: useStockItemList
    })

    return {
        shipment,
        stockItems,
        stockItemsSortState,
        onChangeStockItemsSortState,
        stockItemsPagination,
        transferFetchStatus,
        stockItemsFetchStatus
    }
}

export default useShipmentDetailsWithItems
