import { useMemo } from "react"

import { getConsignmentNotes } from "modules/WmsModule/hooks/api/useShipment"
import useDocument, { DOCUMENT_ACTIONS } from "modules/WmsModule/hooks/useDocument"

const DEFAULT_OPTIONS_LABELS = {
    print: "Print shipping bill of landing",
    download: "Download shipping bill of landing"
}

const useConsignmentNoteOptions = ({ ids, labels = {} }) => {
    const optionLabels = useMemo(() => ({ ...DEFAULT_OPTIONS_LABELS, ...labels }), [labels])

    const { mutate: printConsignmentNotes, isLoading: isPrintingConsignmentNote } = useDocument({
        actionType: DOCUMENT_ACTIONS.PRINT,
        customFetch: getConsignmentNotes
    })

    const { mutate: downloadConsignmentNote, isLoading: isDownloadingConsignmentNote } = useDocument({
        actionType: DOCUMENT_ACTIONS.DOWNLOAD,
        fileName: "Shipping bill of landing",
        customFetch: getConsignmentNotes
    })

    const options = useMemo(
        () =>
            ids.length > 0
                ? [
                      {
                          onClick: () => printConsignmentNotes({ ids }),
                          label: optionLabels.print
                      },
                      {
                          onClick: () => downloadConsignmentNote({ ids }),
                          label: optionLabels.download
                      }
                  ]
                : [],
        [ids, printConsignmentNotes, downloadConsignmentNote, optionLabels]
    )

    return {
        options,
        isLoading: isPrintingConsignmentNote || isDownloadingConsignmentNote
    }
}

export default useConsignmentNoteOptions
