import { useMemo } from "react"
import { useHistory } from "react-router-dom"
import _get from "lodash/get"
import _pick from "lodash/pick"

import { roundNumber } from "helpers/units"
import { createLinkToReturnForm } from "modules/OrdersModule/helpers/createLinkToReturn"
import SALES_ORDER_ITEM_STATUSES from "modules/OrdersModule/constants/salesOrderItemStatusKeys"
import PURCHASE_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/purchaseOrderStatusKeys"

const checkIfStockItemHasConfirmedPO = stockItem =>
    _get(stockItem, "purchase_order.status", null) === PURCHASE_ORDER_STATUS_KEYS.confirmed

const extractStockItemsFromOrderItems = orderItems =>
    orderItems.reduce(
        (stockItems, orderItem) => {
            const stockItemTotalPrice =
                orderItem.quantity > 0 ? roundNumber(orderItem.total_price / orderItem.quantity) : 0
            const returnedStockItemsIds = _get(orderItem, "returned_stock_items", [])
            const hasInvoice = !!_get(orderItem, "invoice", null)
            const isNewOrder = _get(orderItem, "type", null) === SALES_ORDER_ITEM_STATUSES.NEW_ORDER

            const checkIfCanReturnStockItem = stockItem => {
                const stockItemIsNotReturned = !returnedStockItemsIds.includes(stockItem.id)

                if (isNewOrder) {
                    return stockItemIsNotReturned && checkIfStockItemHasConfirmedPO(stockItem)
                }

                return stockItemIsNotReturned
            }

            const mapOrderStockItemForReturnForm = stockItem => ({
                ..._pick(stockItem, ["id", "product", "warehouse", "condition"]),
                totalPrice: stockItemTotalPrice
            })

            const orderItemStockItems = hasInvoice ? _get(orderItem, "stock_items", []) : []

            const { availableOrderItemStockItems, unavailableOrderItemStockItems } = orderItemStockItems.reduce(
                (stockItems, item) => {
                    const availabilityKey = checkIfCanReturnStockItem(item)
                        ? "availableOrderItemStockItems"
                        : "unavailableOrderItemStockItems"
                    return {
                        ...stockItems,
                        [availabilityKey]: [...stockItems[availabilityKey], mapOrderStockItemForReturnForm(item)]
                    }
                },
                { availableOrderItemStockItems: [], unavailableOrderItemStockItems: [] }
            )

            return {
                availableOrderItemStockItems:
                    availableOrderItemStockItems.length > 0
                        ? [...stockItems.availableOrderItemStockItems, ...availableOrderItemStockItems]
                        : stockItems.availableOrderItemStockItems,
                unavailableOrderItemStockItems:
                    unavailableOrderItemStockItems.length > 0
                        ? [...stockItems.unavailableOrderItemStockItems, ...unavailableOrderItemStockItems]
                        : stockItems.unavailableOrderItemStockItems
            }
        },
        { availableOrderItemStockItems: [], unavailableOrderItemStockItems: [] }
    )

const getOnlySelectedOrderItems = (allOrderItems, selectedOrderItemsIds) =>
    selectedOrderItemsIds.reduce((selectedOrderItems, id) => {
        const selectedOrderItem = allOrderItems.find(orderItem => orderItem.id === id)

        return selectedOrderItem ? [...selectedOrderItems, selectedOrderItem] : selectedOrderItems
    }, [])

const useReturnButton = ({ id, uuid, allOrderItems, selectedOrderItemsIds }) => {
    const { push: historyPush } = useHistory()

    const allOrderStockItemsForReturn = useMemo(() => extractStockItemsFromOrderItems(allOrderItems), [allOrderItems])
    const selectedOrderItems = useMemo(() => getOnlySelectedOrderItems(allOrderItems, selectedOrderItemsIds), [
        allOrderItems,
        selectedOrderItemsIds
    ])
    const selectedOrderStockItemsForReturn = useMemo(() => extractStockItemsFromOrderItems(selectedOrderItems), [
        allOrderItems,
        selectedOrderItemsIds
    ])

    const returnButtonProps = useMemo(() => {
        const areItemsSelected = selectedOrderItemsIds.length > 0
        const stockItemsForReturn = areItemsSelected
            ? selectedOrderStockItemsForReturn.availableOrderItemStockItems
            : allOrderStockItemsForReturn.availableOrderItemStockItems
        const stockItemsUnavailableForReturn = areItemsSelected
            ? selectedOrderStockItemsForReturn.unavailableOrderItemStockItems
            : allOrderStockItemsForReturn.unavailableOrderItemStockItems
        const orderItems = areItemsSelected ? selectedOrderItems : allOrderItems
        const orderItemsUnavailableForReturn = orderItems.filter(orderItem => {
            const orderItemStockItemIds = _get(orderItem, "stock_items", []).map(stockItem => stockItem.id)
            return (
                orderItemStockItemIds.length === 0 ||
                stockItemsUnavailableForReturn
                    .map(stockItem => stockItem.id)
                    .some(stockItemId => orderItemStockItemIds.includes(stockItemId))
            )
        })

        return {
            onClick: () =>
                historyPush(
                    createLinkToReturnForm({
                        id,
                        uuid,
                        stockItems: stockItemsForReturn,
                        orderItemsUnavailableForReturn
                    })
                ),
            disabled: stockItemsForReturn.length === 0
        }
    }, [
        id,
        uuid,
        allOrderStockItemsForReturn,
        selectedOrderStockItemsForReturn,
        selectedOrderItemsIds,
        selectedOrderItems,
        allOrderItems
    ])

    return returnButtonProps
}

export default useReturnButton
