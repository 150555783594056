import * as fetchStatus from "helpers/fetchStatus"
import * as constants from "./../constants/index"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"
import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"

export const shouldFetchSelections = resource => (dispatch, getState) => {
    return fetchStatus.isResourceNotInit(resource)
}

export function getSelectionStart() {
    return {
        type: constants.GET_BY_ID_START
    }
}

export function getSelectionSuccess(data) {
    return {
        type: constants.GET_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function getSelectionError(error) {
    return {
        type: constants.GET_BY_ID_ERROR,
        payload: {
            error
        }
    }
}

export function getSelectionById(id) {
    return dispatch => {
        dispatch(getSelectionStart())
        return fetch
            .get(`/selections/${id}`)
            .then(data => dispatch(getSelectionSuccess(data)))
            .catch(error => dispatch(getSelectionError(error)))
    }
}

export function setChoosenSelectionInLocalStore(id) {
    window.localStorage.setItem("choosenSelection", id)
}

export function getChoosenSelectionFromLocalStore() {
    return window.localStorage.getItem("choosenSelection", null)
}

export function setChoosenSelection(id) {
    return {
        type: constants.SET_CHOOSEN_SELECTION,
        payload: {
            id
        }
    }
}

export function removeChoosenSelectionFromLocalStore() {
    window.localStorage.removeItem("choosenSelection")
}

export function resetChoosenSelection() {
    return {
        type: constants.RESET_CURRENT
    }
}

export function getProductsStart() {
    return {
        type: constants.GET_PRODUCTS_START
    }
}

export function getProductsSuccess(data) {
    return {
        type: constants.GET_PRODUCTS_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProductsError(error) {
    return {
        type: constants.GET_PRODUCTS_ERROR,
        payload: {
            error
        }
    }
}

export function resetProducts() {
    return {
        type: constants.RESET_PRODUCTS,
        payload: {}
    }
}

export function getProducts(
    selections = getChoosenSelectionFromLocalStore(),
    params = { page: 1, sort_by: "selections.pivot.id", sort_direction: "asc", length: LENGTH_WITHOUT_PAGINATION }
) {
    return dispatch => {
        dispatch(getProductsStart())
        return fetch
            .get(`/products?${objToQueryString({ ...params, selections })}`)
            .then(data => dispatch(getProductsSuccess(data)))
            .catch(error => dispatch(getProductsError(error)))
    }
}

export function addProductToSelection(product, additionalParams = {}) {
    return {
        type: constants.ADD_PRODUCT,
        payload: {
            product,
            ...additionalParams
        }
    }
}

export function removeProductFromSelection(product, additionalParams = {}) {
    return {
        type: constants.REMOVE_PRODUCT,
        payload: {
            product,
            ...additionalParams
        }
    }
}

export function addProductsToSelection(products = [], additionalParams = {}) {
    return {
        type: constants.ADD_PRODUCTS,
        payload: {
            products,
            ...additionalParams
        }
    }
}

export function startModalLogic(ids) {
    return {
        type: constants.START_MODAL_LOGIC,
        payload: { ids }
    }
}

export function endModalLogic() {
    return {
        type: constants.END_MODAL_LOGIC,
        payload: {}
    }
}

export function patchSelectionProductsById(data, id = getChoosenSelectionFromLocalStore()) {
    return fetch.patch(`/selections/${id}`, { products: data })
}

export function attachSelectionProductsById(data, id = getChoosenSelectionFromLocalStore()) {
    return fetch.patch(`/selections/${id}`, { products_attach: data })
}

export function incrementLock() {
    return {
        type: constants.INCREMENT_COUNTER,
        payload: {}
    }
}

export function decrementLock() {
    return {
        type: constants.DECREMENT_COUNTER,
        payload: {}
    }
}

export function resetLock() {
    return {
        type: constants.RESET_LOCK,
        payload: {}
    }
}

export function showWidget() {
    return { type: constants.SHOW }
}

export function hideWidget() {
    return { type: constants.HIDE }
}

export function setLastServerUpdatedAt(timestamp) {
    return {
        type: constants.SET_LAST_SERVER_UPDATED_AT,
        payload: timestamp
    }
}

export function setLastServerCompletedAt(timestamp) {
    return {
        type: constants.SET_LAST_SERVER_COMPLETED_AT,
        payload: timestamp
    }
}

export function setLastModifiedAt(timestamp) {
    return {
        type: constants.SET_LAST_MODIFIED_AT,
        payload: timestamp
    }
}
