import React, { useState, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"

import defaultConfig from "./config"

import withStyles from "HOC/withStyles"
import styles from "./header.css"

const HeaderItem = ({ className, width, label, onClick, isSorting }) => {
    return (
        <div className={className} style={{ width }} onClick={onClick}>
            {label}
            {isSorting && (
                <div>
                    <svg data-asc xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5">
                        <path
                            className="svg-icon-sort-asc"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth=".64"
                            d="M3.5 0L6 4H1z"
                        />
                    </svg>
                    <svg data-desc xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5">
                        <path
                            className="svg-icon-sort-desc"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth=".64"
                            d="M3.5 5L6 1H1z"
                        />
                    </svg>
                </div>
            )}
        </div>
    )
}

const Header = props => {
    const { cx, sorting, handleSetSorting, config } = props
    const { items, breakpoints } = config

    const defaultRwdElement = !!breakpoints ? breakpoints.length : 0
    const [rwdElement, setRwdElement] = useState(defaultRwdElement)

    useLayoutEffect(() => {
        handleSetRwdElement()
        window.addEventListener("resize", handleSetRwdElement)
        return () => window.removeEventListener("resize", handleSetRwdElement)
    }, [])

    const handleSetRwdElement = debounce(() => {
        const width = window.innerWidth
        const breakpointIndex = breakpoints.reduce(
            (acc, curr, index) => (width <= curr ? index : acc),
            breakpoints.length
        )
        setRwdElement(breakpointIndex)
    }, 200)

    const getWidth = width => (Array.isArray(width) ? width[rwdElement] : width)

    const setSorting = by => {
        const directions = ["asc", "desc"]
        const direction = !!sorting && sorting.by === by ? directions.find(dir => dir !== sorting.direction) : "desc"
        !!handleSetSorting && handleSetSorting({ by, direction })
    }

    return (
        <ul className={cx("root")}>
            {items.map(({ value, label, width, isSorting }) => {
                const isActive = !!sorting && sorting.by === value

                return (
                    <HeaderItem
                        key={value}
                        className={cx("headerItem", { isSorting, isActive, [!!sorting && sorting.direction]: true })}
                        label={label}
                        width={getWidth(width)}
                        isSorting={isSorting}
                        onClick={() => setSorting(value)}
                    />
                )
            })}
        </ul>
    )
}

Header.defaultProps = {
    config: defaultConfig
}

Header.propTypes = {
    sorting: PropTypes.shape({
        by: PropTypes.string,
        direction: PropTypes.oneOf(["asc", "desc"])
    }),
    handleSetSort: PropTypes.func,
    config: PropTypes.shape({
        breakpoints: PropTypes.arrayOf(PropTypes.number),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.string,
                label: PropTypes.string,
                width: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
                isSorting: PropTypes.bool
            })
        )
    })
}

export default withStyles(Header, styles)
