export const transformError = error =>
    error.inner
        ? (error.inner || []).reduce((acc, item) => {
              acc[item.path] = item.message
              return acc
          }, {})
        : Object.keys(error.errors || []).reduce((acc, field) => {
              acc[field] = error.errors[field][0]
              return acc
          }, {})

export const createHintText = ({ width, height } = {}) => {
    if (!!width && !!height) {
        return `(${width}x${height}px)`
    }

    if (!!width) {
        return `(width: ${width}px)`
    }

    if (!!height) {
        return `(height: ${height}px)`
    }

    return "(browse or drop your file here)"
}
