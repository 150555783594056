import { useState, useMemo, useCallback } from "react"

const useProductFetchStatuses = () => {
    const [productFetchStatuses, setProductFetchStatuses] = useState({})

    const setProductFetchStatus = useCallback((productId, value) => {
        setProductFetchStatuses(prevState => ({
            ...prevState,
            [productId]: {
                ...prevState[productId],
                isLoading: value
            }
        }))
    }, [])

    const isAnyProductLoading = useMemo(() => Object.values(productFetchStatuses).some(({ isLoading }) => isLoading), [
        productFetchStatuses
    ])

    return {
        productFetchStatuses,
        setProductFetchStatus,
        isAnyProductLoading
    }
}

export default useProductFetchStatuses
