import React, { Fragment } from "react"

import DropzoneWithUpload from "../DropzoneWithUpload"
import { createHintText } from "modules/ContentModule/helpers"

import uploadSvg from "assets/ico-upload-cloud.svg"
import imageSvg from "assets/ico-image.svg"
import closeSvg from "assets/close.svg"
import videoSvg from "assets/video.svg"

import withStyles from "HOC/withStyles"
import styles from "./Dropzone.css"

const Dropzone = ({
    cx,
    isCover,
    isVideo,
    isThumbnail,
    acceptFormats,
    handleUploadSuccess,
    handleUploadFailure,
    handleRemoveFile,
    imageData,
    previewData,
    customLabel,
    setIsLoading,
    rootStyles,
    imageDimensions,
    isCoverWithImageDimensions
}) => {
    const label = customLabel ? customLabel : isVideo ? " Add video " : " Add cover "
    const browseLabel = customLabel ? "your file here" : isVideo ? "video" : "image 2560x680px"

    return (
        <DropzoneWithUpload
            {...imageData}
            handleUploadSuccess={state => {
                handleUploadSuccess(state, imageData.name)
            }}
            handleUploadFailure={handleUploadFailure}
            handleUploadReject={handleUploadFailure}
            acceptFormats={acceptFormats}
            customStyles={rootStyles}
        >
            {uploadState =>
                imageData.value ? (
                    <div className={cx("dropzone", "isValue", { isThumbnail })}>
                        {(isCoverWithImageDimensions || isCover || isVideo) && (
                            <Fragment>
                                <div className={cx("imageWrapper")}>
                                    <div
                                        className={cx("image")}
                                        style={{ backgroundImage: !isVideo && `url(${imageData.value})` }}
                                    >
                                        {isVideo && !imageData.thumbnail && <img src={videoSvg} alt="video" />}
                                    </div>
                                    <span className={cx("name")}>{previewData && previewData.name}</span>
                                </div>
                                <button onClick={event => handleRemoveFile(event, imageData.name)}>
                                    <img src={closeSvg} alt="close" />
                                </button>
                            </Fragment>
                        )}
                        {isThumbnail && (
                            <Fragment>
                                <div className={cx("image")} style={{ backgroundImage: `url(${imageData.value})` }} />
                                <div className={cx("nameWrapper")}>
                                    {previewData.name}
                                    <button onClick={event => handleRemoveFile(event, imageData.name)}>
                                        <img src={closeSvg} alt="close" />
                                    </button>
                                </div>
                            </Fragment>
                        )}
                    </div>
                ) : (
                    <div className={cx("dropzone", { isThumbnail })}>
                        {typeof setIsLoading === "function" && setIsLoading(uploadState.isUploading)}
                        {uploadState.isUploading ? (
                            <div className={cx("loading")}>
                                <div style={{ width: `${uploadState.progress}%` }} />
                                <p>{uploadState.progress} %</p>
                            </div>
                        ) : (
                            <Fragment>
                                {(isCover || isVideo) && (
                                    <Fragment>
                                        <img src={isVideo ? videoSvg : imageSvg} alt="" />
                                        {label}
                                        <span>(browse or drop {browseLabel})</span>
                                    </Fragment>
                                )}
                                {isThumbnail && (
                                    <Fragment>
                                        <img src={uploadSvg} alt="" />
                                        <div className={cx("title")}>Upload Image</div>
                                        <div className={cx("description")}>or drop image to upload</div>
                                    </Fragment>
                                )}
                                {isCoverWithImageDimensions && (
                                    <Fragment>
                                        <img src={imageSvg} alt="" />
                                        {label}
                                        <span>{createHintText(imageDimensions)}</span>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </div>
                )
            }
        </DropzoneWithUpload>
    )
}

export default withStyles(Dropzone, styles)
