import React, { useState, useEffect, useMemo, useCallback } from "react"
import { useSelector, useDispatch } from "react-redux"

import Layout from "components/Reusable/Layout"
import { SearchBar, Pagination } from "ui"
import { Title, HeaderActions, List, ActionModal, AddEditModal } from "./components"

import { useActions, useMultiselect, useFetch } from "hooks"

import {
    getManualEntries,
    getManualEntriesSuccess,
    createManualEntry,
    updateManualEntry,
    deleteManualEntry,
    convertManualEntryToProduct,
    multiSelectDelete,
    multiSelectConvertManualEntriesToProduct,
    setLastFetchedManualEntry
} from "./actions"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import SearchBarStyles from "./overrides/SearchBarStyles.css"
import PaginationStyles from "./overrides/PaginationStyles.css"

const actionModalInitialData = { isOpen: false, type: null, selectedId: null }
const addEditModalInitialData = { isOpen: false, selectedId: null }

const sortOptions = { sort_by: "created_at", sort_direction: "desc" }

const MANUAL_ENTRIES_ON_LIST = 20

const ManualEntriesList = () => {
    const dispatch = useDispatch()

    const actions = useActions({
        getManualEntries,
        getManualEntriesSuccess,
        showSuccessNotification,
        showErrorNotification
    })

    const manualEntryState = useSelector(state => state.db.manualEntries.list)
    const { data: manualEntries, meta, fetchStatus } = manualEntryState

    const [debouncedSearchPhrase, setDebouncedSearchPhrase] = useState("")
    const [currentPage, setCurrentPage] = useState(1)

    const [actionModalData, setActionModalData] = useState(actionModalInitialData)
    const [addEditModalData, setAddEditModalData] = useState(addEditModalInitialData)

    const {
        selected,
        areSomeSelected,
        areAllSelected,
        toggleSelected,
        setAllSelected,
        clearAllSelected
    } = useMultiselect(manualEntries.length)

    useEffect(() => {
        fetchManualEntries(currentPage)
    }, [currentPage, debouncedSearchPhrase])

    const fetchManualEntries = page => {
        const queryObj = debouncedSearchPhrase ? { query: debouncedSearchPhrase } : {}

        return actions
            .getManualEntries({ length: MANUAL_ENTRIES_ON_LIST, page, ...queryObj, ...sortOptions })
            .catch(() => actions.showErrorNotification())
    }

    const onClickAllItems = useCallback(
        () => (selected.length ? clearAllSelected() : setAllSelected(manualEntries.map(({ id }) => id))),
        [selected.length, manualEntries]
    )

    const openActionModal = type => selectedId => setActionModalData({ isOpen: true, type, selectedId })
    const openDeleteModal = openActionModal("delete")
    const openConvertModal = openActionModal("convert")
    const closeActionModal = () => setActionModalData(actionModalInitialData)

    const openAddEditModal = selectedId => setAddEditModalData({ isOpen: true, selectedId })
    const closeAddEditModal = () => setAddEditModalData(addEditModalInitialData)

    const deleteOrConvert = useCallback(
        () =>
            ({
                delete: actionModalData.selectedId
                    ? () => deleteManualEntry(actionModalData.selectedId)
                    : () => multiSelectDelete(selected),
                convert: actionModalData.selectedId
                    ? () => convertManualEntryToProduct(actionModalData.selectedId)
                    : () => multiSelectConvertManualEntriesToProduct(selected)
            }[actionModalData.type]()),
        [actionModalData.selectedId, actionModalData.type, selected]
    )

    const areDeletedOrConvertedAllFromPage = actionModalData.selectedId ? manualEntries.length === 1 : areAllSelected

    const [handleDeleteOrConvert, { isLoading: isDeletingOrConverting }] = useFetch({
        action: deleteOrConvert,
        onSuccess: () => {
            closeActionModal()
            actions.showSuccessNotification()

            if (!actionModalData.selectedId) {
                clearAllSelected()
            }

            if (areDeletedOrConvertedAllFromPage && currentPage === meta.last_page) {
                return setCurrentPage(page => page - 1)
            }

            fetchManualEntries(currentPage)
        },
        onError: () => actions.showErrorNotification()
    })

    const handleAddEdit = useCallback(
        (values, { setErrors }) =>
            (addEditModalData.selectedId
                ? updateManualEntry({ id: addEditModalData.selectedId, data: values })
                : createManualEntry({ ...values, manual_entry: true })
            )
                .then(response => {
                    closeAddEditModal()
                    actions.showSuccessNotification()

                    dispatch(setLastFetchedManualEntry(response.data))

                    if (addEditModalData.selectedId || currentPage === 1) {
                        return fetchManualEntries(currentPage)
                    }

                    setCurrentPage(1)
                })
                .catch(error => {
                    actions.showErrorNotification(error.message)
                    if (error.errors) {
                        const { name, price, brand_id } = error.errors
                        const getError = fieldError => (Array.isArray(fieldError) ? fieldError[0] : fieldError)
                        setErrors({
                            name: getError(name),
                            price: getError(price),
                            brand: getError(brand_id)
                        })
                    }
                }),
        [addEditModalData.selectedId, currentPage, fetchManualEntries]
    )

    const manualEntryForAction = useMemo(
        () => manualEntries.find(({ id }) => id === (actionModalData.selectedId || addEditModalData.selectedId)),
        [manualEntries, actionModalData.selectedId, addEditModalData.selectedId]
    )

    return (
        <Layout
            title={() => <Title selectedCount={selected.length} />}
            renderActions={() => (
                <HeaderActions
                    showSelectedActions={!!selected.length}
                    createNew={openAddEditModal}
                    deleteSelected={openDeleteModal}
                    convertSelected={openConvertModal}
                />
            )}
        >
            <SearchBar
                customStyles={SearchBarStyles}
                placeholder="Search for manual entries..."
                delay={400}
                value={debouncedSearchPhrase}
                handleSearch={setDebouncedSearchPhrase}
            />

            <List
                fetchStatus={fetchStatus}
                manualEntries={manualEntries}
                meta={meta}
                selected={selected}
                areSomeSelected={areSomeSelected}
                onClickAll={onClickAllItems}
                onClickListItem={toggleSelected}
                convertToProduct={openConvertModal}
                editManualEntry={openAddEditModal}
                deleteManualEntry={openDeleteModal}
            />

            {fetchStatus.isLoaded && (
                <Pagination
                    customStyles={PaginationStyles}
                    currentPage={currentPage}
                    pages={meta.last_page}
                    onChange={setCurrentPage}
                />
            )}

            <ActionModal
                isOpen={actionModalData.isOpen}
                isLoading={isDeletingOrConverting}
                type={actionModalData.type}
                manualEntry={manualEntryForAction}
                selectedCount={selected.length}
                onClose={closeActionModal}
                onSubmit={handleDeleteOrConvert}
            />

            {addEditModalData.isOpen && (
                <AddEditModal manualEntry={manualEntryForAction} onClose={closeAddEditModal} onSubmit={handleAddEdit} />
            )}
        </Layout>
    )
}

export default ManualEntriesList
