import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getPaymentMethods } from "actions/paymentMethods"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"
import PaymentMethods from "./PaymentMethods"

const PaymentMethodsContainer = () => {
    const dispatch = useDispatch()

    const paymentMethods = useSelector(state => state.db.paymentMethods)

    useEffect(() => {
        dispatch(
            getPaymentMethods({
                query: "",
                sort_by: "created_at",
                sort_direction: "asc",
                page: 1,
                length: LENGTH_WITHOUT_PAGINATION
            })
        )
    }, [])

    return <PaymentMethods paymentMethods={paymentMethods} />
}

export default PaymentMethodsContainer
