import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import style from "./Checkbox.css"

class Checkbox extends Component {
    render() {
        const {
            id,
            onClick,
            name,
            checked,
            onChange,
            cx,
            attributes,
            disabled,
            isMinusIcon,
            title,
            color = ""
        } = this.props

        const backgroundColorRgb = {
            red: parseInt(color.substr(1, 2), 16) * 0.2126,
            green: parseInt(color.substr(3, 2), 16) * 0.7152,
            blue: parseInt(color.substr(5, 2), 16) * 0.0722
        }

        const backgroundLunaBrightness =
            (backgroundColorRgb.red + backgroundColorRgb.blue + backgroundColorRgb.green) / 255

        const isBrightBackground = backgroundLunaBrightness > 0.95

        const checkboxBg = {
            borderColor: color ? color : "#a1c1e6",
            backgroundColor: color ? color : "#4383cd"
        }
        const checkbox = {
            borderColor: color ? color : "#a1c1e6"
        }
        const changeColor = checked ? checkboxBg : checkbox
        const isChecked = checked ? "checked" : ""

        return (
            <span className={cx("root")} onClick={onClick}>
                <label htmlFor={id} className={cx("checkbox-label", { disabled })} title={title}>
                    <input
                        tabIndex="-1"
                        type="checkbox"
                        id={id}
                        className={cx("checkbox")}
                        checked={checked}
                        disabled={disabled}
                        onChange={onChange && !disabled ? () => onChange(id) : null}
                    />
                    <span className={cx("label", { brightColor: isBrightBackground })}>
                        <span className={cx(isChecked, { minus: isMinusIcon })} style={changeColor} />
                        {name}
                        {attributes && attributes.required ? "*" : ""}
                    </span>
                </label>
            </span>
        )
    }
}

Checkbox.defaultProps = {
    onClick: () => {}
}

export default withStyles(Checkbox, style)
