import React, { useState } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import idx from "idx"

import fetch from "helpers/fetch"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import { setLastFetchedOrder } from "actions/orders"
import { useActions } from "hooks"
import useStyles from "helpers/getCxFromStyles"

import { Button, Modal, SelectWithSearch } from "ui"

import styles from "./AddToSalesOrderModal.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.css"

const statuses = {
    new: "Proposal",
    in_progress: "In progress",
    awaiting_payment: "Payment",
    on_hold: "On hold",
    sent: "Sent",
    cancelled: "Cancelled",
    completed: "Delivered",
    returned: "Returned"
}

const AddToSalesOrderModal = props => {
    const { isOpen, onClose, projectId, products } = props
    const cx = useStyles(styles)
    const actions = useActions({ showSuccessNotification, showErrorNotification, setLastFetchedOrder })
    const history = useHistory()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [orders, setOrders] = useState([])
    const [ordersPagination, setOrdersPagination] = useState({ current_page: 0, last_page: 1 })
    const [order, setOrder] = useState(null)

    const generateGroupLabel = item => `${item.uuid} [${statuses[item.status] || item.status}]`

    const handleSave = async () => {
        setIsSubmitting(true)

        try {
            const { data } = await fetch.post(`/projects/${projectId}/order`, {
                project_items: products.map(item => item.id),
                order_id: order ? order.id : null
            })
            actions.setLastFetchedOrder(data)
            actions.showSuccessNotification()
            history.push(`/orders/sales-orders/${data.id}`)
        } catch (error) {
            const message = idx(error, _ => _.errors.project[0]) || error.message
            actions.showErrorNotification({ message })
        } finally {
            setIsSubmitting(false)
        }
    }

    const fetchOrders = (query, page) => {
        const formatData = data => {
            return data.map(item => ({
                value: item,
                label: generateGroupLabel(item)
            }))
        }

        fetch
            .get(
                `/orders?type=sales&sort_by=created_at&sort_direction=desc&project_id=${projectId}&query=${query}&page=${page}&length=15`
            )
            .then(data => {
                setOrdersPagination({ ...data.meta, current_page: page })
                setOrders(page === 1 ? formatData(data.data) : [...orders, ...formatData(data.data)])
            })
    }

    return (
        <Modal isOpen={isOpen} closeModal={onClose} preventClickOutside={true}>
            <Modal.Header>Add to SO:</Modal.Header>
            <Modal.Body>
                <SelectWithSearch
                    value={!!order ? generateGroupLabel(order) : null}
                    values={orders}
                    pagination={ordersPagination}
                    setValue={({ value }) => setOrder(value)}
                    placeholder="-- Create new order --"
                    fetchValues={fetchOrders}
                    customStyles={selectWithSearchStyles}
                />
            </Modal.Body>
            <Modal.Actions>
                <Button onClick={onClose} label="Cancel" className={cx("link-button")} />
                <Button label="Save" className={cx("first-button")} onClick={handleSave} isLoading={isSubmitting} />
            </Modal.Actions>
        </Modal>
    )
}

AddToSalesOrderModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    projectId: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        })
    ).isRequired
}

export default AddToSalesOrderModal
