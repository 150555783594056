import { useState } from "react"

const useNonmutableState = initialValue => {
    const [value, setValue] = useState({ value: initialValue })

    const handleSet = newValue => {
        setValue({ value: newValue })
    }

    return [value.value, value, handleSet]
}

export default useNonmutableState
