import React from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"

import withStyles from "HOC/withStyles"
import styles from "./image.css"

import defaultImage from "assets/image.svg"

const Image = props => {
    const { cx, className, fallbackClassName, src, fallbackSvg } = props

    return (
        <div className={`${cx("root")} ${className}`} style={src ? { backgroundImage: `url("${src}")` } : {}}>
            {!src && (
                <ReactSVG className={`${cx("fallbackSvg")} ${fallbackClassName}`} src={fallbackSvg || defaultImage} />
            )}
        </div>
    )
}

Image.propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    fallbackClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    src: PropTypes.string,
    fallbackSvg: PropTypes.node
}

export default withStyles(Image, styles)
