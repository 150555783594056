import React, { useState, useRef, useMemo } from "react"

import { SelectableGroup } from "react-selectable-fast"
import { sortBy, partition } from "lodash"

import withStyles from "HOC/withStyles"
import styles from "./CombinationSelect.css"
import { Attribute } from "./components"

import { useCombinationsCreator } from "hooks"

const CombinationsSelect = ({ cx, blockInputs }) => {
    const selectableRef = useRef(null)
    const [{ resources, pickedAttributes }, actions] = useCombinationsCreator()

    const [selectedAttributes, setSelectedAttributes] = useState([])

    const handleSelectAttributes = attributes => {
        const selectedValues = attributes.map(attribute => attribute.props.value)
        setSelectedAttributes(selectedValues)
    }

    const handlePreparedAttributesAndClear = () => {
        selectableRef.current && selectableRef.current.clearSelection()
        actions.addCombinations(selectedAttributes)
    }

    const pickedAttributesIds = useMemo(() => pickedAttributes.map(({ value }) => value), [pickedAttributes])
    const sortedAttributes = useMemo(() => {
        const [selected, notSelected] = partition(resources.attributes.data, ({ value }) =>
            pickedAttributesIds.includes(value)
        )
        const sortedSelected = sortBy(selected, ({ value }) => pickedAttributesIds.indexOf(value))
        return [...sortedSelected, ...notSelected]
    }, [resources.attributes.data, pickedAttributesIds])

    return (
        <div className={cx("root")}>
            <ul className={cx("attributesWrapper")}>
                <SelectableGroup
                    onSelectionFinish={handleSelectAttributes}
                    enableDeselect
                    ref={selectableRef}
                    ignoreList={["button", "input"]}
                >
                    {sortedAttributes.map(attribute => {
                        return (
                            <Attribute
                                key={attribute.value}
                                attribute={attribute}
                                isSelectable={pickedAttributesIds.includes(attribute.value)}
                                handleToggleAddAttribute={() =>
                                    console.log("Todo: integrate add attribute modal for attribute " + attribute.value)
                                }
                            />
                        )
                    })}
                </SelectableGroup>
            </ul>
            <div className={cx("buttonsWrapper")}>
                <button
                    type="button"
                    disabled={blockInputs || selectedAttributes.length === 0}
                    onClick={handlePreparedAttributesAndClear}
                >
                    +
                </button>
            </div>
        </div>
    )
}

export default withStyles(CombinationsSelect, styles)
