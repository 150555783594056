import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"

import { PERMISSIONS } from "constants/index"
import withPermissions from "HOC/withPermissions"

import ListFiltersContextProvider from "modules/WmsModule/contexts/ListFiltersContextProvider"
import WarehouseListPage from "modules/WmsModule/pages/WarehouseListPage"
import StockManagementListPage from "modules/WmsModule/pages/StockManagementListPage"
import StockItemDetailsPage from "modules/WmsModule/pages/StockItemDetailsPage"
import StockItemAddPage from "modules/WmsModule/pages/StockItemAddPage"
import StockAdjustmentListPage from "modules/WmsModule/pages/StockAdjustmentListPage"
import StockTransferListPage from "modules/WmsModule/pages/StockTransferListPage"
import ShipmentListPage from "modules/WmsModule/pages/ShipmentListPage"
import ShipmentDetailsPage from "modules/WmsModule/pages/ShipmentDetailsPage"
import ReceivingListPage from "modules/WmsModule/pages/ReceivingListPage"
import ReceivingDetailsPage from "modules/WmsModule/pages/ReceivingDetailsPage"
import LogisticsListPage from "modules/WmsModule/pages/LogisticsListPage"

import { StockAdjustmentRoute } from "./components"

const Routes = props => {
    const match = useRouteMatch()
    const { canView, canList, canAdd, canEdit } = props

    if (!canList() && !canView() && !canEdit() && !canAdd()) {
        return <Redirect to="/dashboard" />
    }

    return (
        <ListFiltersContextProvider>
            <Switch>
                <Route path={`${match.url}/warehouses`} component={WarehouseListPage} />
                <Route path={`${match.url}/stock-management/add`} exact component={StockItemAddPage} />
                <StockAdjustmentRoute path={`${match.url}/stock-management/adjustment`} exact />
                <Route path={`${match.url}/stock-management/:stockItemId`} component={StockItemDetailsPage} />
                <Route path={`${match.url}/stock-management`} component={StockManagementListPage} />
                <Route path={`${match.url}/stock-adjustments`} component={StockAdjustmentListPage} />
                <Route path={`${match.url}/stock-transfers`} component={StockTransferListPage} />
                <Route path={`${match.url}/shipments/:shipmentId`} component={ShipmentDetailsPage} />
                <Route path={`${match.url}/shipments`} component={ShipmentListPage} />
                <Route path={`${match.url}/receivings/:receivingId`} component={ReceivingDetailsPage} />
                <Route path={`${match.url}/receivings`} component={ReceivingListPage} />
                <Route path={`${match.url}/logistics`} component={LogisticsListPage} />
                <Redirect to={`${match.url}/warehouses`} />
            </Switch>
        </ListFiltersContextProvider>
    )
}

export default withPermissions(Routes, PERMISSIONS.context.WMS)
