import React, { useEffect, useState } from "react"
import _get from "lodash/get"
import withStyles from "HOC/withStyles"

import styles from "./DropList.css"

const DropList = withStyles(({ cx, children, trigger }) => {
    const [isOpen, setIsOpen] = useState(false)

    let triggerRef = null
    let dropContentRef = null

    const handleClickOutside = event => {
        if (isOpen) {
            if (event.target instanceof Node) {
                const withinTrigger = triggerRef && triggerRef.contains(event.target)

                if (!withinTrigger) {
                    setIsOpen(prevState => !prevState)
                }
            }
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true)

        return () => {
            document.removeEventListener("click", handleClickOutside, true)
        }
    }, [triggerRef, dropContentRef, isOpen])

    function handleContentRef(ref) {
        dropContentRef = ref

        if (ref) {
            ref.focus()
        }
    }

    return (
        <div className={cx("root", { open: isOpen })}>
            <div ref={ref => (triggerRef = ref)} onClick={() => setIsOpen(prevState => !prevState)}>
                {trigger}
            </div>
            <div ref={handleContentRef}>{children}</div>
        </div>
    )
}, styles)

DropList.List = withStyles(({ cx, children, classes }) => {
    return <div className={cx("list", _get(classes, "list"))}>{children}</div>
}, styles)

DropList.Item = withStyles(({ cx, children, className, onClick, isActive = false }) => {
    return (
        <div className={cx("item", className, { isActive })} onClick={onClick}>
            {children}
        </div>
    )
}, styles)

DropList.Divider = withStyles(({ cx, children }) => {
    return <div className={cx("divider")} />
}, styles)

export default DropList
