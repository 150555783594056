import React, { Fragment } from "react"
import { Field, FieldArray } from "formik"
import withStyles from "HOC/withStyles"
import SelectUser from "./SelectUser"
import SelectShowroom from "./SelectShowroom"
import styles from "./styles.css"
import addIcon from "assets/add.svg"

const Sidebar = props => {
    const { cx, form } = props

    const isAddMemberDisabled = form.values.designTeam.some(member => member === "")

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <h3>Company Client</h3>
                <div className={cx("showroom")}>
                    <label>Division:</label>
                    <Field name="company.id">
                        {({ field }) => (
                            <SelectShowroom
                                name="company.id"
                                value={field.value}
                                handleSelect={event => field.onChange(event)}
                            />
                        )}
                    </Field>
                </div>
            </div>
            <div className={cx("section")}>
                <h3>Person assigned</h3>
                <div className={cx("leader")}>
                    <label>Leader:</label>
                    <Field name="leader">
                        {({ field }) => (
                            <SelectUser
                                name="leader"
                                value={field.value || ""}
                                users={[form.values.leader, ...form.values.designTeam]}
                                handleSelect={event => field.onChange(event)}
                            />
                        )}
                    </Field>
                </div>
                <div className={cx("designTeam")}>
                    <label>Design team:</label>
                    <FieldArray name="designTeam">
                        {arrayHelpers => (
                            <Fragment>
                                {form.values.designTeam.map((user, index) => (
                                    <div className={cx("member")} key={index}>
                                        <Field name={`users.${index}`}>
                                            {() => (
                                                <SelectUser
                                                    name={`users.${index}`}
                                                    users={[form.values.leader, ...form.values.designTeam]}
                                                    value={user}
                                                    handleSelect={event => {
                                                        const value = event.target.value

                                                        if (value === "") {
                                                            return arrayHelpers.remove(index)
                                                        }
                                                        arrayHelpers.replace(index, event.target.value)
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    className={cx("addMember")}
                                    onClick={() => arrayHelpers.push("")}
                                    disabled={isAddMemberDisabled}
                                >
                                    <img src={addIcon} alt="addImage" />
                                    Add member
                                </button>
                            </Fragment>
                        )}
                    </FieldArray>
                </div>
            </div>
        </div>
    )
}

export default withStyles(Sidebar, styles)
