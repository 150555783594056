import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    fetchStatus: fetchStatus.initialState()
}

function current(state = initialState, action) {
    switch (action.type) {
        case constants.GET_PRESS_START:
            return { ...state, fetchStatus: fetchStatus.requestState() }
        case constants.GET_PRESS_SUCCESS:
            return {
                data:
                    action.payload.meta.current_page === 1
                        ? action.payload.data
                        : [...state.data, ...action.payload.data],
                meta: action.payload.meta,
                fetchStatus: fetchStatus.successState()
            }
        case constants.GET_PRESS_ERROR:
            return { ...initialState, fetchStatus: fetchStatus.failureState() }
        default:
            return state
    }
}

export default current
