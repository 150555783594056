import React, { useState, useMemo, useCallback } from "react"
import { useSelector } from "react-redux"

import withStyles from "HOC/withStyles"
import { displayUserName } from "helpers/user"

import { SelectWithSearch } from "ui"

import addIcon from "assets/add.svg"

import styles from "./styles.css"

const Assignees = ({ cx, setFieldValue, selectedUsers, handleEdit }) => {
    const users = useSelector(state => state.userStore.users)

    const [isAddNextSelectVisible, setIsAddNextSelectVisible] = useState(false)

    const showAddNextSelect = () => {
        setIsAddNextSelectVisible(true)
    }

    const setValue = useCallback(
        (key, newValue) => {
            const isDelete = !newValue.id
            const isEdit = !!selectedUsers[key]

            const deleteValue = isDelete && selectedUsers.filter((_, index) => key !== index)
            const editValue = isEdit && selectedUsers.map((user, index) => (index === key ? newValue : user))
            const addValue = [...selectedUsers, newValue]

            const value = deleteValue || editValue || addValue

            setFieldValue("users", value)
            handleEdit(true)
            !isDelete && !isEdit && setIsAddNextSelectVisible(false)
        },
        [selectedUsers]
    )

    const values = useMemo(
        () =>
            users
                .map(value => ({ ...value, label: displayUserName(value) }))
                .filter(({ id }) => !selectedUsers.some(selected => selected.id === id)),
        [selectedUsers, users]
    )

    const visibleSelects = useMemo(() => {
        return isAddNextSelectVisible || !selectedUsers.length ? [...selectedUsers, {}] : selectedUsers
    }, [isAddNextSelectVisible, selectedUsers])

    const isAddButtonDisabled = useMemo(
        () => isAddNextSelectVisible || !selectedUsers.length || selectedUsers.some(({ id }) => !id),
        [isAddNextSelectVisible, selectedUsers]
    )

    return (
        <div className={cx("root")}>
            <label>Assign to:</label>
            {visibleSelects.map((user, key) => (
                <div key={key} className={cx("dropdown")}>
                    <SelectWithSearch
                        values={values}
                        value={user.full_name}
                        setValue={value => setValue(key, value)}
                        placeholder="- Please select -"
                        withoutFetch
                    />
                </div>
            ))}
            <div className={cx("add-more", { disabled: isAddButtonDisabled })} onClick={showAddNextSelect}>
                <img src={addIcon} alt="addImage" />
                <span>Add more</span>
            </div>
        </div>
    )
}

export default withStyles(Assignees, styles)
