import * as constants from "constants/index"

function brands(state = false, action) {
    switch (action.type) {
        case constants.CLEAR_AVAILABLE_FILTERS:
            return false
        case constants.GET_PRODUCTS_SUCCESS:
            if (
                action.payload.data &&
                action.payload.data.meta.aggregates &&
                (action.payload.data.meta.aggregates.brands_count ||
                    action.payload.data.meta.aggregates.categories_count)
            ) {
                return true
            }
            return false
        default:
            return state
    }
}

export default brands
