import * as constants from "./../constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = () => {
    const choosenSelection = window.localStorage.getItem("choosenSelection", null)

    return {
        data: {},
        isShown: !!choosenSelection,
        choosenSelection: choosenSelection,
        fetchStatus: fetchStatus.initialState()
    }
}

function current(state = initialState(), action) {
    switch (action.type) {
        case constants.GET_BY_ID_START:
            return {
                ...state,
                fetchStatus: fetchStatus.requestState()
            }

        case constants.GET_BY_ID_SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                fetchStatus: fetchStatus.successState()
            }

        case constants.GET_BY_ID_ERROR:
            return {
                ...state,
                data: {},
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }

        case constants.RESET_CURRENT:
            return {
                ...initialState()
            }

        case constants.SET_CHOOSEN_SELECTION:
            return {
                ...state,
                isShown: action.payload.id === null ? false : true,
                choosenSelection: action.payload.id
            }

        case constants.ADD_PRODUCT:
            return {
                ...state,
                isShown: true
            }

        case constants.SHOW:
            return {
                ...state,
                isShown: true
            }

        case constants.HIDE:
            return {
                ...state,
                isShown: false
            }

        default:
            return state
    }
}

export default current
