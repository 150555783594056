import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"

import { Button, Input, Modal, SelectWithSearch } from "ui"
import File from "./components/File"

import styles from "./AddEditModal.css"
import inputStyles from "./overrides/Input.css"
import dropdownStyles from "./overrides/Dropdown.css"

import { DOCUMENT_AVAILABILITY_LEVELS } from "constants/index"

const AddEditModal = ({ onClose, initialValues, onSave, errors, isSubmitting }) => {
    const cx = useStyles(styles)

    const [file, setFile] = useState(initialValues.file)
    const [level, setLevel] = useState(initialValues.level)
    const [description, setDescription] = useState(initialValues.description || "")

    const handleSave = async () => {
        onSave({
            id: initialValues.id,
            attachment: file.id,
            level,
            description
        })
    }

    return (
        <Modal isOpen closeModal={onClose} preventClickOutside>
            <p className={cx("header")}>{initialValues.id ? "Edit a" : "Add new"} Download</p>

            <File file={file} setFile={setFile} error={errors.attachment} />

            <Input
                label="Description"
                value={description}
                onChange={({ event }) => setDescription(event.target.value)}
                customStyles={inputStyles}
                error={errors.description}
            />

            <SelectWithSearch
                value={(DOCUMENT_AVAILABILITY_LEVELS.find(item => item.value === level) || {}).label}
                label="Level"
                values={DOCUMENT_AVAILABILITY_LEVELS}
                setValue={({ value }) => {
                    setLevel(value)
                }}
                customStyles={dropdownStyles}
                isRequired
                withoutFetch
                error={errors.level}
            />

            <div className={cx("actions")}>
                <Button onClick={onClose} label="Cancel" className={cx("link-button")} />
                <Button
                    label="Save"
                    className={cx("first-button")}
                    isDisabled={!level || !file}
                    onClick={handleSave}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

AddEditModal.propTypes = {
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        level: PropTypes.string,
        description: PropTypes.string
    }).isRequired,
    errors: PropTypes.object,
    isSubmitting: PropTypes.bool
}

export default AddEditModal
