import React from "react"

import withStyles from "HOC/withStyles"
import styles from "./InputButton.css"

import clearIcon from "assets/close-blue.svg"
import arrowIcon from "assets/arrow_down_blue.svg"

const InputButton = props => {
    const { cx, isExpanded, isValue, isClearBlocked, onClear, onToggle } = props

    const icon = isValue && !isClearBlocked ? clearIcon : arrowIcon
    const isCloseList = !isValue && isExpanded
    const onClick = isValue && !isClearBlocked ? onClear : onToggle

    return (
        <button className={cx("root", { isCloseList })} type="button" onClick={onClick}>
            <img src={icon} alt="" />
        </button>
    )
}

export default withStyles(InputButton, styles)
