import get from "lodash/get"

import PURCHASE_ORDER_TYPE_KEYS from "modules/OrdersModule/constants/purchaseOrderTypeKeys"

export const checkIfCanEditPurchaseOrder = ({ type, warehouse, is_any_item_received }) => {
    if (type === PURCHASE_ORDER_TYPE_KEYS.DROPSHIP) {
        return true
    }

    if (get(warehouse, "autonomous")) {
        return true
    }

    return !is_any_item_received
}
