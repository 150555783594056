import React, { useState } from "react"

import fetch from "helpers/fetch"

import { Modal as ModalComponent } from "ui"
import Modal from "./AddVendorModal"

const ModalContainer = props => {
    const { handleClose, isOpen, setVendor, showSuccessNotification, showErrorNotification } = props

    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = {
        custom_vendor: true,
        name: "",
        po_email: "",
        logo: null,
        country: "",
        address: "",
        city: "",
        state: "",
        post_code: ""
    }

    const title = "Quickly create new vendor"

    const handleSubmit = async values => {
        const brandValues = {
            ...values,
            po_email: [values.po_email],
            logo: values.logo ? values.logo.id : undefined
        }

        setIsSubmitting(true)
        try {
            const brandResult = await fetch.post("/brands", brandValues)

            showSuccessNotification()
            setVendor(brandResult.data)
            handleClose()
        } catch (error) {
            showErrorNotification()
            setIsSubmitting(false)

            throw error
        }
    }

    return (
        <ModalComponent closeModal={handleClose} isOpen={isOpen} preventClickOutside={true}>
            <Modal
                {...props}
                title={title}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
            />
        </ModalComponent>
    )
}

export default ModalContainer
