import React from "react"
import PropTypes from "prop-types"

import { usePricingLogsList } from "modules/ProductsModule/hooks/api/usePricingLogs"

import PricingList from "./PricingList"

const PricingListContainer = ({ productId, listData = [], onRemoveRow }) => {
    const { data: fetchedData, fetchStatus } = usePricingLogsList({
        params: {
            product_id: productId
        },
        reactQueryProps: {
            enabled: !!productId
        }
    })

    return (
        <PricingList
            listData={!!productId ? fetchedData : listData}
            isLoading={fetchStatus.isLoading}
            onRemoveRow={onRemoveRow}
            showPrice={!!productId}
            emptyListMessage={
                productId ? "There is no price history for this item." : "There is no cost effects for this brand."
            }
        />
    )
}

PricingListContainer.propTypes = {
    listData: PropTypes.arrayOf(
        PropTypes.shape({
            effect_date: PropTypes.string.isRequired,
            value_percentage: PropTypes.string,
            created_at: PropTypes.string.isRequired,
            created_by: PropTypes.shape({
                full_name: PropTypes.string
            })
        })
    ),
    productId: PropTypes.number,
    onRemoveRow: PropTypes.func
}

export default PricingListContainer
