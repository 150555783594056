export const objToQueryString = (obj, omitConvertableToFalse = false) => {
    const params = JSON.parse(JSON.stringify(obj))

    if (omitConvertableToFalse) {
        Object.keys(params).forEach(key => (params[key] == null || params[key] === false) && delete params[key])
    }

    return Object.entries(params)
        .map(i => [i[0], encodeURIComponent(i[1])].join("="))
        .join("&")
}

export const serializeQuery = (params, prefix, removeEmpty = false) => {
    const query = Object.keys(params)
        .map(key => {
            const value = params[key]

            if (removeEmpty && !value) {
                return null
            }

            if (params.constructor === Array) key = `${prefix}[]`
            else if (params.constructor === Object) key = prefix ? `${prefix}[${key}]` : key

            if (!!value && typeof value === "object") return serializeQuery(value, key, removeEmpty)
            else return `${key}=${encodeURIComponent(value)}`
        })
        .filter(item => !!item)

    return [].concat.apply([], query).join("&")
}

export const getRouteByOrderType = orderType => {
    return {
        sales: "sales-orders",
        memo: "memo",
        rental: "rental-agreements"
    }[orderType]
}
