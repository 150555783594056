import * as constants from "constants/index"

function selections(state = [], action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_SELECTIONS:
            return []
        case constants.CHANGE_SELECTIONS:
            return [...value]
        case constants.TOGGLE_SELECTIONS:
            return state.indexOf(value) !== -1 ? state.filter(selectedId => selectedId !== value) : [...state, value]
        default:
            return state
    }
}

export default selections
