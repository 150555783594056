import React, { useEffect } from "react"
import PropTypes from "prop-types"
import idx from "idx"
import ReactSvg from "react-svg"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"

import { Dropzone } from "ui"

import styles from "./File.css"
import dropzoneStyles from "./overrides/dropzone.css"

import cloudIcon from "./assets/cloud.svg"
import deleteIcon from "assets/delete.svg"
import downloadIcon from "assets/download.svg"

const File = ({ cx, uploader: { actions, state }, file, setFile, error }) => {
    useEffect(() => {
        if (idx(state, _ => _.addedFile.url)) {
            setFile({
                id: state.addedFile.id,
                name: state.addedFile.title,
                url: state.addedFile.url
            })
        }
    }, [state.isUploading, state.isSuccess, state.addedFile])

    const remove = () => {
        setFile(null)
    }

    const ACCEPTED_FORMATS = [
        "application/acad",
        "image/vnd.dwg",
        "image/x-dwg",
        ".dwg",
        "video/mp4",
        "video/webm",
        "video/ogg",
        "image/jpeg",
        "image/png",
        "image/tiff",
        ".xlsx",
        ".xls",
        "application/pdf",
        "application/zip",
        ".zip"
    ]

    const renderDropzone = () => (
        <Dropzone
            isUploading={state.isUploading}
            handleUpload={actions.handleUpload}
            customStyles={dropzoneStyles}
            acceptFormats={ACCEPTED_FORMATS}
        >
            <img className={cx("cloudImage")} src={cloudIcon} alt="cloud" />
            <p className={cx("dropzoneHeader")}>Upload file</p>
            <p className={cx("dropzoneSubheader")}>(or drop file to upload)</p>
        </Dropzone>
    )

    const renderFile = file => (
        <div className={cx("filePlaceholder")}>
            <a href={file.url} rel="noopener noreferrer" target="_blank" download={file.name}>
                <ReactSvg src={downloadIcon} />
                <span>{file.name}</span>
            </a>
            <span className={cx("delete")} onClick={remove}>
                <img src={deleteIcon} alt="remove" />
            </span>
        </div>
    )

    const renderUploading = progress => (
        <div className={cx("progressBar")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    )

    return (
        <div className={cx("root", { isError: !!error })}>
            {!!file ? renderFile(file) : state.isUploading ? renderUploading(state.progress) : renderDropzone()}
        </div>
    )
}

File.propTypes = {
    cx: PropTypes.func.isRequired,
    uploader: PropTypes.object.isRequired
}

export default withUploader(withStyles(File, styles))
