import React, { useRef, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import qs from "querystring"

import useStyles from "helpers/getCxFromStyles"
import { formatDate } from "helpers/date"
import { getInitials } from "helpers/string"
import fetch from "helpers/fetch"
import { useFetch, useOutsideClick, useActions } from "hooks"
import { showErrorNotification } from "actions/notification"

import { Button, InfiniteScroll } from "ui"
import { SnakeLoader } from "ui/Skeleton"

import styles from "./NotificationsDropdown.css"
import loaderStyles from "./overrides/loader.css"

import notificationsIcon from "./assets/notifications.svg"
import clockIcon from "assets/clock.svg"

const NotificationsDropdown = () => {
    const cx = useStyles(styles)
    const componentRef = useRef(null)
    const maxIdRef = useRef(null)
    const minIdRef = useRef(null)
    const actions = useActions({ showErrorNotification })
    const history = useHistory()

    useOutsideClick(componentRef, () => {
        setIsOpen(false)
    })

    const [isOpen, setIsOpen] = useState(false)
    const [pocessingItems, setPocessingItems] = useState([])
    const [onlyUnread, setOnlyUnread] = useState(false)
    const [unreadCount, setUnreadCount] = useState(null)
    const [items, setItems] = useState([])
    const [meta, setMeta] = useState({
        total: 0,
        current_page: 1,
        last_page: 1
    })

    useEffect(() => {
        const interval = setInterval(() => {
            fetchNewest()
        }, 60000)
        return () => clearInterval(interval)
    }, [])

    const fetchNewest = async () => {
        if (minIdRef.current !== null) {
            const params = {
                page: 1,
                length: 9999,
                min_id: minIdRef.current
            }

            try {
                const response = await fetch.get(`/notifications?${qs.stringify(params)}`)
                if (response.data.length > 0) {
                    setItems(oldItems => [...response.data, ...oldItems])
                    setUnreadCount(response.meta.unread_count)
                    minIdRef.current = response.data[0].identifier
                }
            } catch (error) {
                actions.showErrorNotification()
            }
        }
    }

    const toggleIsOpen = () => setIsOpen(wasOpen => !wasOpen)
    const onClickNotification = async notification => {
        if (!!notification.read_at) {
            return redirect(notification)
        }

        try {
            await fetch.patchRAW(`/notifications/${notification.id}`)

            setItems(oldItems =>
                oldItems.map(item =>
                    item.id === notification.id ? { ...item, read_at: new Date().toISOString() } : item
                )
            )
            setUnreadCount(oldUnreadCount => oldUnreadCount - 1)

            redirect(notification)
        } catch (error) {
            actions.showErrorNotification()
        }
    }
    const markAllRead = async () => {
        try {
            const response = await fetch.patch(`/notifications`)

            setItems(oldItems =>
                oldItems.map(item =>
                    (item.data.actions || []).length > 0 ? item : { ...item, read_at: new Date().toISOString() }
                )
            )
            setUnreadCount(oldUnreadCount => oldUnreadCount - response.data.marked_as_read)
        } catch (error) {
            actions.showErrorNotification()
        }
    }

    const onCalendarAnswer = async (notification, accept) => {
        setPocessingItems(oldItems => [...oldItems, notification.id])

        try {
            const url = (notification.data.actions.find(item => item.label === (accept ? "Accept" : "Reject")) || {})
                .href

            await fetch.patchRAW(url)

            setItems(oldIems =>
                oldIems.map(item =>
                    item.id === notification.id
                        ? {
                              ...item,
                              read_at: new Date().toISOString(),
                              approval_status: accept ? "accept" : "reject"
                          }
                        : item
                )
            )

            setUnreadCount(oldUnreadCount => oldUnreadCount - 1)
        } catch (error) {
            if (error.status === 404) {
                actions.showErrorNotification({ title: "Notification has been removed" })
                setItems(oldIems => oldIems.filter(item => item.id !== notification.id))
            } else {
                actions.showErrorNotification({ title: error.message })
                const newNotification = await fetch.get(`/notifications/${notification.id}`)
                setItems(oldIems => oldIems.map(item => (item.id === notification.id ? newNotification : item)))
            }
        } finally {
            setPocessingItems(oldItems => oldItems.filter(item => item !== notification.id))
        }
    }

    const redirect = notification => {
        const notificationType = notification.type.split("\\").pop()

        const orderPath =
            notification.data.meta.order &&
            `/orders/${
                notification.data.meta.order.type === "memo"
                    ? "memo"
                    : notification.data.meta.order.type === "rental"
                    ? "rental-agreements"
                    : "sales-orders"
            }/${notification.data.meta.order.id}`

        const projectPath = notification.data.meta.project && `/projects/${notification.data.meta.project.id}`
        const urlPath = projectPath || orderPath

        switch (notificationType) {
            case "DaysOffConfirmationNotification":
            case "DaysOffRequestNotification":
            case "SharePrivateCalendarNotification":
            case "ShareCalendarEntryNotification":
                history.push("/calendar")
                break
            case "InvoiceCreatedNotification":
                history.push(`${urlPath}/invoices`)
                break
            case "PurchaseOrderCreatedNotification":
                history.push(`${urlPath}/purchase-orders`)
                break
            case "AddPaymentToOrderNotification":
            case "AddPaymentToProjectNotification":
                history.push(`${urlPath}/payments`)
                break
            case "OrderChangedStatusNotification":
            case "UserAddedToCommissionTeamNotification":
            case "UserAddedToProjectTeamNotification":
            case "ProjectChangedStatusNotification":
            case "ClientAddedNoteToSharedProjectNotification":
                history.push(urlPath)
                break
            case "SaleOrderCreatedNotification":
                history.push(projectPath ? `${projectPath}/sale-orders` : orderPath)
                break
            case "PurchaseOrderStatusChangedNotification":
                history.push("/orders/purchase-orders")
                break
            case "CreateRentalFromMemoNotification":
                history.push(`/orders/rental-agreements/${notification.data.meta.rental.id}`)
                break
            case "PaymentDeadlineNotification":
                history.push(`/orders/invoices`)
                break
            case "ClientBirthdayNotification":
                history.push(`/contact/${notification.data.meta.client.id}/profile`)
                break
            case "AwaitingApprovalNotification":
                history.push("/contact/awaiting-approval")
                break
            default:
                break
        }

        setIsOpen(false)
    }

    useEffect(() => {
        if (unreadCount === 0) {
            setOnlyUnread(false)
        }
    }, [unreadCount])

    const [fetchItems, fetchStatus] = useFetch({
        action: () => {
            const params = {
                page: 1,
                length: 20,
                max_id: maxIdRef.current
            }

            if (onlyUnread) {
                params.unread_only = true
            }

            return fetch.get(`/notifications?${qs.stringify(params)}`)
        },
        onSuccess: ({ data, meta }) => {
            setMeta(meta)
            unreadCount === null && setUnreadCount(meta.unread_count)
            if (minIdRef.current === null) {
                minIdRef.current = (data[0] || {}).identifier || 0
            }
            if (data.length > 0) {
                setItems(oldItems => [...oldItems, ...data])
                maxIdRef.current = data[data.length - 1].identifier
            }
        }
    })
    useEffect(() => {
        maxIdRef.current = null
        setItems([])
        fetchItems()
    }, [onlyUnread])

    return (
        <div className={cx("root", { isOpen })} ref={componentRef}>
            <button className={cx("label")} onClick={toggleIsOpen}>
                {unreadCount > 0 && <span className={cx("badge")} />}
                <img src={notificationsIcon} alt="notifications" />
            </button>
            {isOpen && (
                <div className={cx("details")}>
                    <div className={cx("item")}>
                        <div className={cx("header")}>
                            <span>
                                Notifications
                                {unreadCount > 0 && (
                                    <span
                                        className={cx("counter")}
                                        onClick={() => fetchStatus.isLoaded && setOnlyUnread(!onlyUnread)}
                                    >
                                        {unreadCount}
                                    </span>
                                )}
                            </span>
                            <Button label="Mark all as read" onClick={markAllRead} isDisabled={unreadCount === 0} />
                        </div>
                    </div>

                    <div className={cx("notifications")}>
                        {items.length === 0 && !fetchStatus.isLoaded && (
                            <div className={cx("loader")} key={0}>
                                <SnakeLoader customStyles={loaderStyles} />
                            </div>
                        )}
                        {items.length === 0 && fetchStatus.isLoaded && (
                            <div className={cx("placeholder")}>
                                <img src={notificationsIcon} alt="notifications" />
                                <span>No notifications</span>
                            </div>
                        )}
                        {items.length > 0 && (
                            <InfiniteScroll
                                loadMore={() => fetchStatus.isLoaded && fetchItems()}
                                initialLoad={false}
                                hasMore={meta.current_page < meta.last_page}
                                loader={
                                    <div className={cx("loader")} key={0}>
                                        <SnakeLoader customStyles={loaderStyles} />
                                    </div>
                                }
                                useWindow={false}
                            >
                                {items.map(item => (
                                    <div
                                        className={cx("item", {
                                            new: !item.read_at,
                                            withoutClick: (item.data.actions || []).length > 0
                                        })}
                                        key={item.id}
                                        onClick={() =>
                                            (item.data.actions || []).length > 0 && !item.read_at
                                                ? null
                                                : onClickNotification(item)
                                        }
                                    >
                                        {pocessingItems.includes(item.id) && (
                                            <div className={cx("loaderOverlay")}>
                                                <SnakeLoader customStyles={loaderStyles} />
                                            </div>
                                        )}
                                        <div className={cx("notification")}>
                                            <span className={cx("avatar", { client: !!item.data.meta.client })}>
                                                {getInitials(
                                                    item.data.meta.client
                                                        ? `${item.data.meta.client.first_name} ${item.data.meta.client.last_name}`
                                                        : item.data.meta.user
                                                        ? `${item.data.meta.user.first_name} ${item.data.meta.user.last_name}`
                                                        : ""
                                                )}
                                            </span>
                                            <div className={cx("notification-content")}>
                                                <span dangerouslySetInnerHTML={{ __html: item.data.title }} />
                                                <span className={cx("date")}>
                                                    <img src={clockIcon} alt="" /> {formatDate(item.created_at)}
                                                </span>
                                                {(item.data.actions || []).length > 0 &&
                                                    (item.approval_status === null ? (
                                                        <div className={cx("buttons")}>
                                                            <Button
                                                                className={cx("secondary")}
                                                                label="Reject"
                                                                onClick={() => onCalendarAnswer(item, false)}
                                                            />
                                                            <Button
                                                                className={cx("primary")}
                                                                label="Accept"
                                                                onClick={() => onCalendarAnswer(item, true)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={cx("calendar-result", {
                                                                accepted: item.approval_status === "accept"
                                                            })}
                                                        >
                                                            {item.approval_status === "accept"
                                                                ? "Accepted"
                                                                : "Rejected"}
                                                        </div>
                                                    ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </InfiniteScroll>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

NotificationsDropdown.propTypes = {
    notifications: PropTypes.array.isRequired
}

export default NotificationsDropdown
