import React, { Fragment, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import ReactSVG from "react-svg"
import qs from "querystring"

import useStyles from "helpers/getCxFromStyles"
import fetch from "helpers/fetch"
import { showErrorNotification } from "actions/notification"

import { useFetch } from "hooks"
import { Modal, Button, InfiniteScroll, EmptyList, Checkbox } from "ui"

import { SnakeLoader } from "ui/Skeleton"

import GroupModal from "modules/AttributeGroupsModules/components/Modals/GroupModal"
import AttributeModal from "modules/AttributeGroupsModules/components/Modals/AttributeModal"

import styles from "./AttributeChooserModal.css"
import modalStyles from "./overrides/modal.css"
import loaderStyles from "./overrides/loader.css"
import arrowIcon from "assets/arrow-blue.svg"
import arrowDownIcon from "assets/arrow_down_blue.svg"
import searchIcon from "assets/ico-search.svg"
import addIcon from "assets/add.svg"
import documentIcon from "assets/document.svg"
import checkedIcon from "assets/checked.svg"

const STEP_TEXTS = {
    1: {
        hint: "Select an Attribute Group",
        search: "Search group...",
        linkButton: "New Group"
    },
    2: {
        hint: "Select a Subgroup",
        back: "Back to Attribute Group",
        search: "Search subgroup...",
        linkButton: "New Subgroup"
    },
    3: {
        hint: "Select an attribute",
        back: "Back to Subgroup",
        search: "Search attribute...",
        linkButton: "New Attribute"
    }
}

const AttributeChooserModal = ({ onClose, onSubmit, actionType, maxStep, isSubmitting, handleOpenAttributeModal }) => {
    const cx = useStyles(styles)
    const dispatch = useDispatch()

    const [step, setStep] = useState(1)
    const [query, setQuery] = useState("")
    const [items, setItems] = useState([])
    const [meta, setMeta] = useState({
        total: 0,
        current_page: 1,
        last_page: 1
    })

    const [selectedItems, setSelectedItems] = useState([])
    const [groupId, setGroupId] = useState(null)
    const [subgroupId, setSubgroupId] = useState(null)
    const [isNewGroupOpened, setIsNewGroupOpened] = useState(false)
    const [isNewAttributeOpened, setIsNewAttributeOpened] = useState(false)

    const debounceRef = useRef(0)
    useEffect(() => clearTimeout(debounceRef.current), [])
    useEffect(() => {
        clearTimeout(debounceRef.current)

        debounceRef.current = setTimeout(() => {
            setMeta({ ...meta, current_page: 1 })
            fetchItems({ page: 1 })
        }, 300)
    }, [query])

    const [fetchItems, fetchStatus] = useFetch({
        action: ({ page }) => {
            if (page === 1) {
                setItems([])
                setSelectedItems([])
            }

            const extraParams = Object.assign(
                {},
                (step === 1 || step === 2) && isAddAttribute && { with_attributes: true },
                step === 2 && { parent_attribute_group_id: groupId },
                step === 3 && { group_id: subgroupId }
            )

            return fetch.get(
                `/${step < 3 ? "attribute-groups" : "attributes"}?query=${query}&page=${page}&length=20&${qs.stringify(
                    extraParams
                )}`
            )
        },
        onSuccess: ({ data, meta }) => {
            setItems(meta.current_page === 1 ? data : [...items, ...data])
            setMeta(meta)
        },
        onError: () => dispatch(showErrorNotification())
    })

    useEffect(() => {
        setQuery("")
        fetchItems({ page: 1 })
    }, [step])

    const onModalClose = () => {
        setIsNewGroupOpened(false)
        setIsNewAttributeOpened(false)
    }

    const handleCreateSuccessGroup = response => {
        const createdItem = response.data

        if (isLastStep) {
            setItems([createdItem, ...items.filter(item => item.id !== createdItem.id)])
            setSelectedItems([createdItem, ...selectedItems])
        } else {
            step === 1 ? setGroupId(createdItem.id) : setSubgroupId(createdItem.id)
            setStep(step + 1)
        }
    }

    const isCopying = actionType === "copy"
    const isAddAttribute = actionType === "addAttribute"
    const isLastStep = step === maxStep

    const onRowClick = item => {
        if (isLastStep) {
            const isSelected = selectedItems.some(({ id }) => id === item.id)
            return setSelectedItems(
                isSelected
                    ? selectedItems.filter(({ id }) => id !== item.id)
                    : isAddAttribute
                    ? [...selectedItems, item]
                    : [item]
            )
        }

        step === 1 ? setGroupId(item.id) : setSubgroupId(item.id)
        setStep(step + 1)
    }

    const renderHeader = () =>
        step === 1 ? (
            isCopying ? (
                "Copy to Attribute Group"
            ) : isAddAttribute ? (
                "Add Attribute(s)"
            ) : (
                "Move to Attribute Group"
            )
        ) : (
            <Fragment>
                <img src={arrowIcon} alt="arrow" className={cx("back")} onClick={() => setStep(step - 1)} />
                {STEP_TEXTS[step]["back"]}
            </Fragment>
        )

    return (
        <Fragment>
            <Modal isOpen closeModal={onClose} preventClickOutside customStyles={modalStyles}>
                <p className={cx("header")}>{renderHeader()}</p>

                <div className={cx("search", { hasValue: !!query })}>
                    <img src={searchIcon} alt="zoom" className={cx("ico")} />
                    <input
                        value={query}
                        onChange={event => setQuery(event.target.value)}
                        placeholder={STEP_TEXTS[step]["search"]}
                        className={cx("input")}
                    />
                </div>

                {items.length === 0 ? (
                    fetchStatus.isLoading ? (
                        <SnakeLoader />
                    ) : (
                        <EmptyList icon={documentIcon} message="Empty list" />
                    )
                ) : (
                    <Fragment>
                        <p className={cx("hint")}>{STEP_TEXTS[step]["hint"]}</p>

                        <ul className={cx("list")}>
                            {isAddAttribute && isLastStep && (
                                <li
                                    className={cx("row", "thickBorder")}
                                    onClick={() => setSelectedItems(selectedItems.length === items.length ? [] : items)}
                                >
                                    <span className={cx("checkbox")}>
                                        <Checkbox
                                            checked={selectedItems.length > 0}
                                            onChange={() => {}}
                                            isMinusIcon={selectedItems.length !== items.length}
                                            disabled
                                        />
                                    </span>
                                    <span className={cx("name")}>
                                        {selectedItems.length === items.length ? "Unselect All" : "Select All"}
                                    </span>
                                </li>
                            )}
                            <InfiniteScroll
                                loadMore={() => fetchStatus.isLoaded && fetchItems({ page: meta.current_page + 1 })}
                                initialLoad={false}
                                hasMore={meta.current_page < meta.last_page}
                                loader={
                                    <div className={cx("loader")} key={0}>
                                        <SnakeLoader customStyles={loaderStyles} />
                                    </div>
                                }
                                useWindow={false}
                            >
                                {items.map(item => {
                                    const isSelected = selectedItems.some(({ id }) => id === item.id)
                                    return (
                                        <li
                                            className={cx("row", {
                                                selected: isSelected
                                            })}
                                            key={item.id}
                                            onClick={() => onRowClick(item)}
                                        >
                                            {isAddAttribute && isLastStep && (
                                                <span className={cx("checkbox")}>
                                                    <Checkbox checked={isSelected} onChange={() => {}} disabled />
                                                </span>
                                            )}
                                            <span className={cx("name")}>{item.name}</span>
                                            {isAddAttribute && isLastStep && (
                                                <span className={cx("values")}>
                                                    {item.attribute_values_count} values
                                                </span>
                                            )}
                                            {isSelected && !isAddAttribute && (
                                                <ReactSVG src={checkedIcon} className={cx("checkedIcon")} />
                                            )}
                                            {!isLastStep && (
                                                <img src={arrowDownIcon} alt="arrow" className={cx("arrow")} />
                                            )}
                                        </li>
                                    )
                                })}
                            </InfiniteScroll>
                        </ul>
                    </Fragment>
                )}

                <div className={cx("actions")}>
                    <Button
                        onClick={() =>
                            isAddAttribute
                                ? handleOpenAttributeModal()
                                : step === 3
                                ? setIsNewAttributeOpened(true)
                                : setIsNewGroupOpened(true)
                        }
                        label={STEP_TEXTS[isAddAttribute ? 3 : step]["linkButton"]}
                        className={cx("button-link")}
                        icon={addIcon}
                    />
                    {isLastStep && (
                        <Button
                            label={isCopying ? "Copy to" : isAddAttribute ? "Add Attribute(s)" : "Move to"}
                            className={cx("button-primary")}
                            isDisabled={!selectedItems.length}
                            onClick={() => onSubmit(selectedItems)}
                            isLoading={isSubmitting}
                        />
                    )}
                </div>
            </Modal>

            {isNewGroupOpened && (
                <GroupModal
                    onClose={onModalClose}
                    onSuccess={handleCreateSuccessGroup}
                    initialValues={{
                        parent_attribute_group_id: groupId
                    }}
                />
            )}
            {isNewAttributeOpened && (
                <AttributeModal
                    onClose={onModalClose}
                    onSuccess={() => fetchItems({ page: 1 })}
                    initialValues={{ group_id: subgroupId, type: "dropdown" }}
                />
            )}
        </Fragment>
    )
}

AttributeChooserModal.defaultProps = {
    maxStep: 3,
    actionType: "move",
    onClose: () => {},
    onSubmit: () => {},
    handleOpenAttributeModal: () => {}
}

AttributeChooserModal.propTypes = {
    handleOpenAttributeModal: PropTypes.func,
    maxStep: PropTypes.number,
    actionType: PropTypes.string,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    isSubmitting: PropTypes.bool
}

export default AttributeChooserModal
