import React from "react"

import withStyles from "HOC/withStyles"

import Layout from "components/Reusable/Layout"

import styles from "./Info.css"
import layoutStyles from "../../overrides/Layout.css"

export const Info = ({ cx, project }) => {
    const leader = project.users.filter(item => item.leader)[0]

    return (
        <div className={cx("root")}>
            <Layout customStyles={layoutStyles}>
                <h1>{project.name}</h1>
                <p>
                    <span className={cx("client")}>
                        Client:
                        <span>{!!project.client && `${project.client.first_name} ${project.client.last_name}`}</span>
                    </span>
                    <span className={cx("designer")}>
                        Lead Designer: <span>{!!leader && `${leader.first_name} ${leader.last_name}`}</span>
                    </span>
                </p>
            </Layout>
        </div>
    )
}

export default withStyles(Info, styles)
