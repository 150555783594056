import * as constants from "../constants"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    isLoading: false,
    isLoaded: false,
    isError: false,
    requestCount: 0,
    fetchStatus: fetchStatus.initialState()
}

function list(state = initialState, action) {
    switch (action.type) {
        case constants.GET_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isError: false,
                requestCount: state.requestCount + 1,
                fetchStatus: fetchStatus.requestState()
            }

        case constants.GET_SUCCESS:
            let productsList = action.payload.data.data ? action.payload.data.data : []
            let productsMeta = action.payload.data.meta ? action.payload.data.meta : {}

            if (action.payload.data.products && action.payload.data.products.data) {
                productsList = action.payload.data.products.data
                productsMeta = action.payload.data.products.meta
            }

            return {
                ...state,
                data: productsList,
                meta: productsMeta,
                isLoading: false,
                isLoaded: true,
                requestCount: state.requestCount - 1,
                fetchStatus: state.requestCount > 1 ? fetchStatus.requestState() : fetchStatus.successState()
            }

        case constants.GET_ERROR:
            return {
                ...state,
                data: [],
                meta: {},
                isLoading: false,
                isLoaded: false,
                requestCount: state.requestCount - 1,
                fetchStatus:
                    state.requestCount > 1
                        ? fetchStatus.requestState()
                        : { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }

        case constants.RESET:
            return initialState

        default:
            return state
    }
}

export default list
