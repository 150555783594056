import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./ColorPicker.css"
import { Button, Zoom, List } from "./components"
import { getBackground } from "./helpers"

function ColorPicker(props) {
    const {
        cx,
        tiles,
        handleSelectTile,
        handleButton,
        selectedTile,
        placeholder,
        zoomDirection,
        customStyles,
        isDisabled
    } = props

    const containerRef = useRef(null)
    const [isExpanded, setIsExpanded] = useState(false)
    const [zoomValues, setZoomValues] = useState({
        isZoomed: false,
        background: "",
        name: ""
    })
    const [selected, setSelected] = useState(selectedTile)

    const handleHover = event => {
        setZoomValues({
            isZoomed: true,
            background: event.background,
            name: event.name
        })
    }

    const handleMouseOut = () => {
        setZoomValues({ ...zoomValues, isZoomed: false })
    }

    const handleClickOutside = event => {
        containerRef.current &&
            !containerRef.current.contains(event.target) &&
            setIsExpanded(false) &&
            setZoomValues({ ...zoomValues, isZoomed: false })
    }

    const handleSelect = value => {
        setIsExpanded(!isExpanded)
        handleMouseOut()
        handleSelectTile(value)
    }

    useLayoutEffect(() => {
        window.addEventListener("click", handleClickOutside)
        return () => window.removeEventListener("click", handleClickOutside)
    }, [])

    useEffect(() => {
        setSelected(tiles.find(tile => tile.id === selectedTile))
    }, [tiles, selectedTile])

    return (
        <div ref={containerRef} className={cx("main", { disabled: isDisabled })}>
            <span
                onClick={() => !isDisabled && setIsExpanded(!isExpanded)}
                className={cx("container", { expanded: isExpanded, selected })}
            >
                {!selected && <span className={cx("select")}>{placeholder}</span>}
                {selected && (
                    <div className={cx("selected-block")}>
                        <span className={cx("color-block")} style={getBackground(selected)}></span>
                        <span className={cx("color-name")}>{selected.name}</span>
                    </div>
                )}
                <Button
                    cx={cx}
                    isExpanded={isExpanded}
                    selected={!!selected}
                    handler={handleButton}
                    isDisabled={isDisabled}
                />
            </span>
            {isExpanded && (
                <List
                    selectedTile={selectedTile}
                    handleSelectTile={handleSelect}
                    handleHover={handleHover}
                    handleMouseOut={handleMouseOut}
                    tiles={tiles}
                    cx={cx}
                />
            )}
            {zoomValues.isZoomed && (
                <Zoom zoomDirection={zoomDirection} zoomValues={zoomValues} cx={cx} customStyles={customStyles} />
            )}
        </div>
    )
}

const tileValues = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string,
    color: PropTypes.string,
    texture: PropTypes.string,
    disabled: PropTypes.bool
})

tileValues.defaultProps = {
    name: "",
    zoomDirection: "left"
}

ColorPicker.propTypes = {
    tiles: PropTypes.arrayOf(tileValues),
    handleSelectTile: PropTypes.func.isRequired,
    handleButton: PropTypes.func,
    selectedTile: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    placeholder: PropTypes.string,
    zoomDirection: PropTypes.oneOf(["left", "right"]),
    customStyles: PropTypes.object,
    isDisabled: PropTypes.bool
}

ColorPicker.defaultProps = {
    tiles: [],
    placeholder: "- Please select -",
    zoomDirection: "left"
}

export default withStyles(ColorPicker, styles)
