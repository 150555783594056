import React, { useState, useEffect } from "react"
import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"

import { SelectWithSearch } from "ui"

import overrides from "./overrides"

const defaultQsParams = {}
const Selectable = ({
    endpoint = "",
    qsParams = defaultQsParams,
    placeholder = "",
    value = "",
    isBlocked = false,
    onChange = () => {}
}) => {
    const [values, setValues] = useState([])

    useEffect(() => {
        fetch
            .get(`${endpoint}?length=${LENGTH_WITHOUT_PAGINATION}&${objToQueryString(qsParams, true)}`)
            .then(({ data }) => {
                const mappedValues = data.map(({ id, name }) => ({ value: id, label: name }))
                setValues(mappedValues)
            })
    }, [endpoint, qsParams])

    return (
        <SelectWithSearch
            isBlocked={isBlocked}
            customStyles={overrides.dropdown}
            placeholder={placeholder}
            values={values}
            value={value}
            setValue={onChange}
            withoutFetch
        />
    )
}

export default Selectable
