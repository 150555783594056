import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import DisplayRoles from "./DisplayRoles/DisplayRoles"
import DisplayUsersInRole from "./DisplayUsersInRole/DisplayUsersInRole"
import Form from "./Form"

class RolesCover extends Component {
    render() {
        return (
            <Switch>
                <Route path="/settings/users/roles" exact={true} render={() => <DisplayRoles />} />
                <Route path="/settings/users/roles/newrole" exact={true} render={props => <Form {...props} />} />
                <Route path="/settings/users/roles/:id/users" render={props => <DisplayUsersInRole {...props} />} />
                <Route path="/settings/users/roles/edit/:id" render={props => <Form {...props} isEdit={true} />} />
            </Switch>
        )
    }
}

export default RolesCover
