import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./AddNewUserForm.css"
import Section from "components/Reusable/Form/Section/index"
import Input from "components/Reusable/Form/Input/index"
import Button from "components/Reusable/Form/Button/index"
import Checkbox from "components/Reusable/Form/Checkbox/index"
import { scrollToError } from "helpers/scrollTo"
import { SelectWithSearch } from "ui"
import moduleSectionStyles from "./ModuleSection.css"
import customSelectWithSearchStyles from "./overrides/SelectWithSearch.css"
class AddNewUserForm extends Component {
    render() {
        const {
            cx,
            roles,
            formFields,
            isEdit,
            onEdit,
            onSubmit,
            choosePermission,
            isSaving,
            isFilled,
            history,
            handleResetPassword,
            isPasswordReset,
            handleShowModal
        } = this.props
        return (
            <div className={cx("add-new-user-cover", "round-border", { "form-isFilling": isEdit && !isFilled })}>
                <form>
                    <Section withoutBorder={true}>
                        <ul className={cx("new-user-inputs-ul")}>
                            {["email", "first_name", "last_name", "phone"].map(key => {
                                if (formFields[key]) {
                                    return (
                                        <li key={key}>
                                            {this.renderInput({
                                                key,
                                                field: formFields[key]
                                            })}
                                        </li>
                                    )
                                }

                                return null
                            })}

                            <li>
                                <h4 className={cx("input-title")}>Role:</h4>
                                <ul className={cx("select-role-ul")}>
                                    {roles.map(({ id, name }) => (
                                        <Checkbox
                                            key={id}
                                            id={id}
                                            customStyles={styles}
                                            name={name}
                                            checked={formFields.permissionGroups.value.includes(id)}
                                            onChange={choosePermission}
                                        />
                                    ))}
                                </ul>
                            </li>
                        </ul>

                        <div className={cx("address")}>{this.renderAddress()}</div>
                    </Section>

                    <div className={cx("clear")} />

                    <Section customStyles={moduleSectionStyles}>
                        <Button
                            className={["first-button", "submit-button"]}
                            customStyles={styles}
                            onClick={event => (isEdit ? onEdit(event) : onSubmit(event))}
                            label={isEdit ? "Save" : "Add"}
                            isLoading={isSaving}
                        />
                        <span className={cx("form-cancel", "cancel")} onClick={() => history.goBack()}>
                            Cancel
                        </span>
                    </Section>

                    {isEdit && (
                        <div className={cx("links")}>
                            {!isPasswordReset ? (
                                <span onClick={event => handleResetPassword()}>Password Reset Link</span>
                            ) : (
                                <b>E-mail with link successfully sent</b>
                            )}
                            <span className={cx("link-red")} onClick={handleShowModal}>
                                Delete User
                            </span>
                        </div>
                    )}
                </form>
            </div>
        )
    }
    renderAddress() {
        const { cx, formFields, statesList, countriesList } = this.props

        return (
            <div>
                <h4 className={cx("address-title")}>Address:</h4>
                <div className={cx("address-cover")}>
                    {["address", "city", "state", "post_code", "country"].map(key => {
                        if (formFields) {
                            const isInline = ["post_code", "city", "state"].includes(key)
                            const className = `input-${key}`

                            const selectsConfig = {
                                state: {
                                    defaultValue: "State",
                                    isInline: true,
                                    list: statesList
                                },
                                country: {
                                    defaultValue: "Country",
                                    isInline: false,
                                    list: countriesList
                                }
                            }

                            if (["state", "country"].includes(key)) {
                                return this.renderSelect({
                                    key,
                                    className: key,
                                    field: formFields[key],
                                    ...selectsConfig[key]
                                })
                            }

                            return this.renderInput({
                                key,
                                field: formFields[key],
                                className,
                                isPlaceholder: true,
                                isInline
                            })
                        }
                        return null
                    })}
                </div>
            </div>
        )
    }

    renderInput({ field, className, key, isPlaceholder = false, isInline }) {
        const { handleFieldChange } = this.props
        return (
            <Input
                {...field}
                key={key}
                isPlaceholder={isPlaceholder}
                isInline={isInline}
                customStyles={styles}
                className={className}
                onChange={handleFieldChange}
                handleScrollToError={scrollToError}
                isPhoneInput={["phone"].includes(key)}
            />
        )
    }
    renderSelect({ key, field, className, list }) {
        const { cx, handleFieldChange } = this.props

        return (
            <div className={cx("select", className)} key={key}>
                <SelectWithSearch
                    {...field}
                    values={list.map(element => ({ value: element, label: element }))}
                    setValue={({ value }) =>
                        handleFieldChange({ event: { target: { value } }, fieldPath: field.fieldPath })
                    }
                    customStyles={customSelectWithSearchStyles}
                    withoutFetch
                />
            </div>
        )
    }
}

export default withStyles(AddNewUserForm, styles)
