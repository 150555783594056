import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import fetch from "helpers/fetch"
import { Modal, Button } from "ui"
import withStyles from "HOC/withStyles"
import style from "./Section.css"

import { showErrorNotification } from "actions/notification"

import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

const NavButton = props => {
    const { cx, isSidebarMenuWide, name } = props

    const dispatch = useDispatch()
    const [isLoading, setIsLoading] = useState(false)
    const [url, setUrl] = useState(null)

    const onClick = async () => {
        try {
            setIsLoading(true)

            const response = await fetch.post(`/phrases/access`)
            const openedWindow = window.open(response.data.url, "_blank")
            const cantOpenNewWindow =
                !openedWindow || openedWindow.closed || [undefined, null].includes(openedWindow.closed)

            if (cantOpenNewWindow) {
                setUrl(response.data.url)
            }
        } catch (error) {
            dispatch(showErrorNotification())
        } finally {
            setIsLoading(false)
        }
    }

    const closeModal = () => setUrl(null)

    return (
        <Fragment>
            <button className="nav-tab button" onClick={onClick}>
                <div className={cx(isSidebarMenuWide ? "sidebar-nav-li" : "sidebar-nav-li-slim")}>
                    <h3 className={cx("subsection-name")}>
                        {isLoading ? (
                            <div className={cx("loader-wrapper")}>
                                <SnakeLoader type="bright" />
                            </div>
                        ) : (
                            name
                        )}
                    </h3>
                </div>
            </button>

            <Modal isOpen={!!url} closeModal={closeModal}>
                <Modal.Header>Edit web page</Modal.Header>
                <Modal.Body>
                    If your browser prevent redirect{" "}
                    <a
                        className={cx("modal-link")}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={closeModal}
                    >
                        click here
                    </a>{" "}
                    to edit web page.
                </Modal.Body>
                <Modal.Actions>
                    <Button className={cx("first-button", "close-modal-button")} label="Close" onClick={closeModal} />
                </Modal.Actions>
            </Modal>
        </Fragment>
    )
}

NavButton.propTypes = {
    isSidebarMenuWide: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired
}

export default withStyles(NavButton, style)
