import React from "react"
import PropTypes from "prop-types"

import Dropzone from "ui/Dropzone"
import withMultipleUploader from "HOC/withMultipleUploader"

const FileUploader = props => {
    const {
        uploader: { state, actions },
        isMultiple,
        acceptFormats,
        children
    } = props

    return (
        <Dropzone
            acceptFormats={acceptFormats}
            isMultiple={isMultiple}
            {...state}
            {...actions}
            disabled={state.isUploading}
            disableClick={state.isUploading}
        >
            {children}
        </Dropzone>
    )
}

FileUploader.defaultProps = {
    isMultiple: false,
    acceptFormats: ""
}

FileUploader.propTypes = {
    endpoint: PropTypes.string,
    isMultiple: PropTypes.bool,
    acceptFormats: PropTypes.string,
    handleUploadSuccess: PropTypes.func.isRequired,
    handleUploadProgress: PropTypes.func,
    handleUploadFailure: PropTypes.func
}

export default withMultipleUploader(FileUploader)
