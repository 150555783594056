import React from "react"
import { Route, Switch } from "react-router-dom"
import withPermissions from "HOC/withPermissions"
import { Form } from "../pages"

const Routes = ({ canEdit, PERMISSIONS }) => {
    return (
        <Switch>
            <Route path="/profile/settings" component={Form} />
            {canEdit(PERMISSIONS.context.USERS) && <Route path={"/settings/users/edit/:id"} component={Form} />}
        </Switch>
    )
}

export default withPermissions(Routes)
