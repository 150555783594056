import React from "react"

import { Checkbox, Image, DropList, ReloadableImageWrap } from "ui"

import { formatPrice } from "helpers/units"

import withStyles from "HOC/withStyles"
import styles from "./List.css"
import DroplistStyles from "./overrides/DroplistStyles.css"

const ListItem = props => {
    const { cx, data, selected, onSelect, convertToProduct, edit, remove } = props
    const { id, media, name, brand, lowest_price } = data

    return (
        <li className={cx("row")}>
            <Checkbox checked={selected} onChange={() => onSelect(id)} />
            <ReloadableImageWrap image={media && media.url}>
                {({ image }) => <Image className={cx("image")} src={image} />}
            </ReloadableImageWrap>
            <p>{name}</p>
            <p>{brand}</p>
            <p>
                <strong>{formatPrice(lowest_price)}</strong>
            </p>
            <div>
                <DropList
                    customStyles={DroplistStyles}
                    trigger={
                        <div className={cx("droplist-trigger")}>
                            <div />
                            <div />
                            <div />
                        </div>
                    }
                >
                    <DropList.List>
                        <DropList.Item onClick={() => convertToProduct(id)}>Convert to product</DropList.Item>
                        <DropList.Item onClick={() => edit(id)}>Edit</DropList.Item>
                        <DropList.Divider />
                        <DropList.Item className={cx("delete-droplist-item")} onClick={() => remove(id)}>
                            Delete
                        </DropList.Item>
                    </DropList.List>
                </DropList>
            </div>
        </li>
    )
}

export default withStyles(ListItem, styles)
