import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import useStyles from "helpers/getCxFromStyles"
import styles from "./Tabs.css"

const Tabs = props => {
    const { label, activeTabId, tabs } = props
    const cx = useStyles(styles)

    return (
        <div className={cx("root")}>
            <h1 className={cx("label")}>{label}</h1>
            <div className={cx("tabs")}>
                {tabs.map((tab, id) => (
                    <Link key={id} to={tab.url} className={cx("tab", { active: activeTabId === id })}>
                        {tab.label}
                    </Link>
                ))}
            </div>
        </div>
    )
}

Tabs.propTypes = {
    label: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired
        })
    ),
    activeTabId: PropTypes.number
}

export default Tabs
