import { INPUT_TYPES } from "constants/index"

export default {
    name: "Login",
    endpoint: "/user/login",
    fields: [
        {
            name: "email",
            label: "E-mail address",
            type: INPUT_TYPES.EMAIL,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "password",
            label: "Password",
            type: INPUT_TYPES.PASSWORD,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "rememberMe",
            label: "Stay signed in",
            type: INPUT_TYPES.CHECKBOX,
            defaultValue: false,
            attributes: {}
        }
    ]
}
