import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./taxcode.module.css"

class TaxCode extends Component {
    static defaultProps = {
        taxCode: "-"
    }
    render() {
        const { cx, taxCode, reseller, taxable } = this.props
        const percentageTaxCode = parseFloat(taxCode * 100).toFixed(2) + "%"

        return (
            <section className={cx("root")}>
                <h2>Tax Details</h2>
                <ul>
                    <li>
                        <h3>Tax Code:</h3>
                        <p>{taxCode ? percentageTaxCode : "-"}</p>
                    </li>
                    <li>
                        <h3>Taxable:</h3>
                        <p>{taxable ? "Yes" : "No"}</p>
                    </li>
                    <li>
                        <h3>Resale certified:</h3>
                        <p>{reseller ? "Yes" : "No"}</p>
                    </li>
                </ul>
            </section>
        )
    }
}

export default withStyles(TaxCode, styles)
