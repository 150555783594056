import React from "react"
import PropTypes from "prop-types"

import { View, Edit } from "./components"

import withStyles from "HOC/withStyles"
import styles from "./EditableProductListItem.css"

const EditableProductListItem = props => {
    const {
        cx,
        isService,
        isChecked,
        isEdited,
        isSaving,
        isLast,
        isDuplicateDisabled,
        isEditDisabled,
        isDeleteDisabled,
        item,
        customParam,
        roomId,
        renderDetails,
        externalActionsConfig,
        onCheck,
        onToggleDetails,
        onAddToRoom,
        onCopyToRoom,
        onDuplicate,
        onToggleEdit,
        onDelete,
        onSubmitEdit,
        onShowComments,
        errors,
        parent,
        orderUuid,
        showTax = true,
        returnShipments,
        isReturnShipmentsLoading,
        isStockItemChangeEnabled
    } = props

    return (
        <div className={cx("root")}>
            {isEdited ? (
                <Edit
                    item={item}
                    onToggleEdit={onToggleEdit}
                    saveProduct={onSubmitEdit}
                    isSaving={isSaving}
                    errors={errors}
                    isService={isService}
                    parent={parent}
                    showTax={showTax}
                />
            ) : (
                <View
                    isService={isService}
                    isLast={isLast}
                    isChecked={isChecked}
                    areDetailsShown={!!renderDetails}
                    isDuplicateDisabled={isDuplicateDisabled}
                    isEditDisabled={isEditDisabled}
                    isDeleteDisabled={isDeleteDisabled}
                    item={item}
                    returnShipments={returnShipments}
                    isReturnShipmentsLoading={isReturnShipmentsLoading}
                    customParam={customParam}
                    roomId={roomId}
                    externalActionsConfig={externalActionsConfig}
                    onCheck={onCheck}
                    onToggleDetails={onToggleDetails}
                    onAddToRoom={onAddToRoom}
                    onCopyToRoom={onCopyToRoom}
                    onDuplicate={onDuplicate}
                    onToggleEdit={onToggleEdit}
                    onDelete={onDelete}
                    onShowComments={onShowComments}
                    parent={parent}
                    orderUuid={orderUuid}
                    showTax={showTax}
                    isStockItemChangeEnabled={isStockItemChangeEnabled}
                />
            )}
            {typeof renderDetails === "function" && renderDetails(props)}
        </div>
    )
}

EditableProductListItem.defaultProps = {
    errors: {}
}

const externalActionPropTypes = PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    stateBuilder: PropTypes.func
})

EditableProductListItem.propTypes = {
    isService: PropTypes.bool,
    isChecked: PropTypes.bool.isRequired,
    isEdited: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    isLast: PropTypes.bool,
    showTax: PropTypes.bool,
    isDuplicateDisabled: PropTypes.bool,
    isEditDisabled: PropTypes.bool,
    isDeleteDisabled: PropTypes.bool,
    item: PropTypes.shape({
        id: PropTypes.number,
        fake_name: PropTypes.string,
        name: PropTypes.string,
        image: PropTypes.string,
        attributes: PropTypes.objectOf(PropTypes.string),
        consignment: PropTypes.bool,
        note: PropTypes.string,
        type: PropTypes.string,
        notes: PropTypes.array,
        price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        quantity: PropTypes.number,
        discount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        tax_code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        total_price: PropTypes.number,
        manual_entry: PropTypes.bool
    }).isRequired,
    errors: PropTypes.object,
    customParam: PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    roomId: PropTypes.number,
    renderDetails: PropTypes.func,
    externalActionsConfig: PropTypes.shape({
        salesOrder: externalActionPropTypes,
        purchaseOrder: externalActionPropTypes,
        invoice: externalActionPropTypes
    }),
    onCheck: PropTypes.func,
    onToggleDetails: PropTypes.func,
    onAddToRoom: PropTypes.func,
    onCopyToRoom: PropTypes.func,
    onDuplicate: PropTypes.func,
    onToggleEdit: PropTypes.func,
    onDelete: PropTypes.func,
    onSubmitEdit: PropTypes.func,
    onShowComments: PropTypes.func,
    parent: PropTypes.object,
    returnShipments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired
        }).isRequired
    ),
    isReturnShipmentsLoading: PropTypes.bool,
    isStockItemChangeEnabled: PropTypes.bool
}

export default withStyles(EditableProductListItem, styles)
