import * as constants from "constants/index"

function list(state = { data: [], isLoading: false }, action) {
    switch (action.type) {
        case constants.GET_BRANDS_START:
            return { data: [], isLoading: true }
        case constants.GET_BRANDS_SUCCESS:
            return { data: action.payload.data, isLoading: false }
        case constants.GET_BRANDS_ERROR:
            return { data: [], isLoading: false }
        default:
            return state
    }
}

export default list
