import { createContext } from "react"
import { startOfMonth } from "date-fns"

export const INITIAL_DASHBOARD_FILTERS = {
    date: {
        startDate: startOfMonth(new Date()),
        endDate: new Date()
    }
}

export const INITIAL_FILTERS = {
    dashboard: INITIAL_DASHBOARD_FILTERS
}

const ListFiltersContext = createContext({
    values: INITIAL_FILTERS,
    set: () => {}
})

export default ListFiltersContext
