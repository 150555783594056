import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"

import withStyles from "HOC/withStyles"

import { Modal, Button, Input } from "ui"

import ShowProductLink from "../ShowProductLink"

import styles from "./styles.css"
import inputStyles from "./overrides/input.css"

const SectionModalMatterport = ({ onClose, cx, form, isSubmitting }) => {
    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Matterport visualization</p>

            <Field name="settings.matterport_link">
                {({ field, meta }) => (
                    <Input
                        {...field}
                        label="Link"
                        onChange={({ event }) => field.onChange(event)}
                        customStyles={inputStyles}
                        error={meta.error}
                    />
                )}
            </Field>

            <ShowProductLink />

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    label="Save"
                    onClick={form.submitForm}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

SectionModalMatterport.propTypes = {
    onClose: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(SectionModalMatterport, styles)
