import React from "react"
import { Route, Switch } from "react-router-dom"

import { List, Detail, Form } from "../pages"

import withPermissions from "HOC/withPermissions"
import { Selections, ManualEntries } from "modules"

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/products" component={List} />
            <Route path="/products/manual-entries" component={ManualEntries} />
            <Route path="/products/selections" component={Selections} />
            <Route
                path="/products/add/:sectionName?"
                render={props => <Form action="add" isNew={true} isEdit={false} {...props} />}
            />
            <Route
                path="/products/:productId/edit/:sectionName?"
                render={props => <Form action="edit" isNew={false} isEdit={true} {...props} />}
            />
            <Route path="/products/:productId/:sectionName?" component={Detail} />
        </Switch>
    )
}

export default withPermissions(Routes)
