import * as constants from "constants/index"

function category(state = [], action) {
    switch (action.type) {
        case constants.CLEAR_FILTERS:
            return []
        case constants.CHOSEN_CATEGORY:
            let filtered = action.payload.categories.filter(category => category.id === action.payload.id)
            return filtered[0]
        case constants.CHOSEN_CATEGORY_RESET:
            return []
        default:
            return state
    }
}

export default category
