import {
    GET_PAYMENT_METHODS_START,
    GET_PAYMENT_METHODS_SUCCESS,
    GET_PAYMENT_METHODS_ERROR,
    CREATE_PAYMENT_METHOD_START,
    CREATE_PAYMENT_METHOD_SUCCESS,
    CREATE_PAYMENT_METHOD_ERROR,
    UPDATE_PAYMENT_METHOD_START,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_ERROR,
    DELETE_PAYMENT_METHOD_START,
    DELETE_PAYMENT_METHOD_SUCCESS,
    DELETE_PAYMENT_METHOD_ERROR
} from "../constants"
import fetch from "../helpers/fetch"
import { objToQueryString } from "../helpers/urls"

const getPaymentMethodsStart = () => ({ type: GET_PAYMENT_METHODS_START })

const getPaymentMethodsSuccess = (data, fromCache) => ({ type: GET_PAYMENT_METHODS_SUCCESS, payload: data, fromCache })

const getPaymentMethodsError = error => ({ type: GET_PAYMENT_METHODS_ERROR, payload: error })

export const getPaymentMethods = (params = {}, fromCache = false) => {
    return async (dispatch, getState) => {
        const store = getState().db.paymentMethods

        if (fromCache && store.fromCache) {
            return store.data
        }

        dispatch(getPaymentMethodsStart())
        try {
            const data = await fetch.get(`/payment-methods?${objToQueryString(params, true)}`)
            dispatch(getPaymentMethodsSuccess(data, fromCache))
            return data
        } catch (error) {
            dispatch(getPaymentMethodsError(error))
            throw new Error(error)
        }
    }
}

const createPaymentMethodStart = () => ({ type: CREATE_PAYMENT_METHOD_START })

const createPaymentMethodSuccess = data => ({ type: CREATE_PAYMENT_METHOD_SUCCESS, payload: data })

const createPaymentMethodError = error => ({ type: CREATE_PAYMENT_METHOD_ERROR, payload: error })

export const createPaymentMethod = body => {
    return async dispatch => {
        dispatch(createPaymentMethodStart())
        try {
            const data = await fetch.post("/payment-methods", body)
            dispatch(createPaymentMethodSuccess(data))
            return data
        } catch (error) {
            dispatch(createPaymentMethodError(error))
            throw error
        }
    }
}

const updatePaymentMethodStart = () => ({ type: UPDATE_PAYMENT_METHOD_START })

const updatePaymentMethodSuccess = data => ({ type: UPDATE_PAYMENT_METHOD_SUCCESS, payload: data })

const updatePaymentMethodError = error => {
    return {
        type: UPDATE_PAYMENT_METHOD_ERROR,
        payload: {
            error
        }
    }
}

export const updatePaymentMethod = (paymentMethodId, body) => {
    return async dispatch => {
        dispatch(updatePaymentMethodStart())
        try {
            const data = await fetch.patch(`/payment-methods/${paymentMethodId}`, body)
            dispatch(updatePaymentMethodSuccess(data))
        } catch (error) {
            dispatch(updatePaymentMethodError(error))
            throw error
        }
    }
}

const deletePaymentMethodStart = () => ({ type: DELETE_PAYMENT_METHOD_START })

const deletePaymentMethodSuccess = data => ({ type: DELETE_PAYMENT_METHOD_SUCCESS, payload: data })

const deletePaymentMethodError = error => ({ type: DELETE_PAYMENT_METHOD_ERROR, payload: error })

export const deletePaymentMethod = paymentMethodId => {
    return async dispatch => {
        dispatch(deletePaymentMethodStart())
        try {
            await fetch.deleteRAW(`/payment-methods/${paymentMethodId}`)
            dispatch(deletePaymentMethodSuccess({ paymentMethodId }))
        } catch (error) {
            dispatch(deletePaymentMethodError(error))
            throw new Error(error)
        }
    }
}
