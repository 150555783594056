import * as constants from "constants/index"

function uploadList(state = {}, action) {
    switch (action.type) {
        case constants.GET_PRODUCTS_SEARCH_AND_SORT_START:
            const isNoScrollingUpload =
                !state.data || (state.meta && state.meta.current_page === action.payload.data.page)

            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isError: false,
                data: isNoScrollingUpload ? [] : state.data,
                meta: isNoScrollingUpload ? {} : state.meta
            }

        case constants.GET_PRODUCTS_SEARCH_AND_SORT_SUCCESS:
            let productsList = action.payload.data && action.payload.data.data ? action.payload.data.data : []
            let productsMeta = action.payload.data && action.payload.data.meta ? action.payload.data.meta : {}

            if (productsMeta && productsMeta.current_page > 1) {
                productsList = [...state.data, ...productsList]
            }

            return { ...state, isLoading: false, isLoaded: true, data: productsList, meta: productsMeta }

        case constants.CHANGE_PRODUCT_IMAGES_COUNT:
            let { productId, nextValue, transferFromProduct } = action.payload

            let changedCountProductList = state.data
                ? state.data.map(item => {
                      let media = item.media
                      if (transferFromProduct && nextValue < 0 && item.id === productId) {
                          media = media.filter(image => transferFromProduct.imageId !== image.id)
                      }

                      return {
                          ...item,
                          media,
                          images_count: item.id === productId ? item.images_count + nextValue : item.images_count
                      }
                  })
                : []

            return { ...state, data: changedCountProductList }

        case constants.GET_PRODUCTS_SEARCH_AND_SORT_ERROR:
            return { ...state, isLoading: false, isLoaded: false, data: [], meta: {} }
        default:
            return state
    }
}

export default uploadList
