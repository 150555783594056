import React, { Component, Fragment } from "react"

import { withStyles } from "ui/FilterableTable/HOC"
import { AppContext } from "ui/FilterableTable"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import TableBase from "ui/FilterableTable/components/Table"
import styles from "./Table.css"

class Table extends Component {
    render() {
        return (
            <AppContext.Consumer>{context => <WrappedTable {...this.props} context={context} />}</AppContext.Consumer>
        )
    }
}

class Body extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {context => <BodyWithConsumer {...this.props} context={context} />}
            </AppContext.Consumer>
        )
    }
}

class TableWithContext extends Component {
    render() {
        const { cx, children, className, renderHeader, renderBody, context } = this.props
        const { fetchStatus, meta } = context

        if (React.Children.count(children)) {
            if (fetchStatus.isLoaded && !meta.total) {
                return (
                    <Fragment>
                        <TableBase className={className}>{children}</TableBase>
                        <p>No content</p>
                    </Fragment>
                )
            }

            return <TableBase className={className}>{children}</TableBase>
        }

        if (typeof renderHeader === "function" && typeof renderBody === "function") {
            return (
                <div className={cx("wrapper")}>
                    {this.renderTable()}
                    {this.renderLoading()}
                    {this.renderEmptyMessage()}
                </div>
            )
        }

        return null
    }

    renderTable() {
        const { context, className } = this.props
        const { fetchStatus } = context

        if (!fetchStatus.isLoaded) {
            return null
        }

        if (this.isEmptyList()) {
            return null
        }

        return (
            <TableBase className={className}>
                {this.renderHeader()}
                {this.renderBody()}
            </TableBase>
        )
    }

    renderHeader() {
        const { renderHeader, context } = this.props
        const { items } = context

        return renderHeader(items || [])
    }

    renderBody() {
        const { context, renderBody } = this.props
        const { items } = context

        if (items && !items.length) {
            return null
        }

        return renderBody(items)
    }

    renderEmptyMessage() {
        const { cx, context, customEmptyMessage: EmptyMessage, ...restProps } = this.props
        const { fetchStatus, items } = context

        if (!fetchStatus.isLoaded || items.length) {
            return null
        }

        if (EmptyMessage) {
            if (React.isValidElement(EmptyMessage)) {
                return EmptyMessage
            }

            return <EmptyMessage {...restProps} context={context} />
        }

        return <div className={cx("empty")}>No data found.</div>
    }

    renderLoading() {
        const { cx, context } = this.props
        const { fetchStatus } = context

        if (!fetchStatus.isLoading) {
            return null
        }

        return (
            <div className={cx("loader")}>
                <SnakeLoader />
            </div>
        )
    }

    isEmptyList() {
        const { context } = this.props
        const { items } = context

        return items.length === 0
    }
}

const WrappedTable = withStyles(styles)(TableWithContext)

class BodyWithConsumer extends Component {
    render() {
        const { children } = this.props

        return <TableBase.Body>{children}</TableBase.Body>
    }
}

Table.Body = Body
Table.Head = TableBase.Head
Table.Tr = TableBase.Tr
Table.Th = TableBase.Th
Table.Td = TableBase.Td

export default Table
