import React, { Component } from "react"
import Personal from "./Personal"

class PersonalContainer extends Component {
    state = {
        selectedDate: null
    }

    componentDidMount() {
        const { values } = this.props
        if (values && values.birth_day) {
            this.setState({
                selectedDate: values.birth_day
            })
        }
    }

    render() {
        const { selectedDate } = this.state
        return <Personal {...this.props} selectedDate={selectedDate} handleSelectDate={this.handleSelectDate} />
    }

    handleSelectDate = date => {
        this.setState({
            selectedDate: date
        })
    }
}

export default PersonalContainer
