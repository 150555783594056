import {
    GET_PURCHASE_ORDERS_START,
    GET_PURCHASE_ORDERS_SUCCESS,
    GET_PURCHASE_ORDERS_ERROR,
    UPDATE_PURCHASE_ORDERS
} from "constants/index"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"
import idx from "idx"

function list(state = { data: [], meta: {}, fetchStatus: initialState() }, action) {
    switch (action.type) {
        case GET_PURCHASE_ORDERS_START:
            return { data: [], meta: {}, fetchStatus: requestState() }

        case GET_PURCHASE_ORDERS_SUCCESS:
            return {
                data: idx(action, _ => _.payload.data) || [],
                meta: idx(action, _ => _.payload.meta) || {},
                fetchStatus: successState()
            }

        case GET_PURCHASE_ORDERS_ERROR:
            return { data: [], meta: {}, fetchStatus: failureState() }

        case UPDATE_PURCHASE_ORDERS:
            const { id, updatedFields } = action.payload
            return {
                ...state,
                data: state.data.map(order => (order.id === id ? { ...order, ...updatedFields } : order))
            }

        default:
            return state
    }
}

export default list
