import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import style from "./header.module.css"
import { Link } from "react-router-dom"
import { AddEditModal } from "ui/Notes"
import { formatDate } from "helpers/date"

class Header extends Component {
    render() {
        const {
            cx,
            name,
            vip,
            isAddNoteModalShown,
            isNoteAdding,
            handleAddNoteModal,
            handleSubmitNote,
            job_title,
            addresses
        } = this.props
        const company = addresses && addresses.length > 0 && addresses[0].company

        return (
            <header className={cx("root")}>
                <h1>
                    {name}
                    {vip && <span className={cx("vip")}>VIP</span>}
                </h1>
                <div className={cx("companyAndJobTitle", { withCompany: company })}>
                    {company && <span className={cx("company")}>{company}</span>}
                    <span>
                        <span className={cx("label")}>Job Title:</span>
                        {job_title || "-"}
                    </span>
                </div>
                {this.renderLinks()}
                {this.renderStatuses()}
                {this.renderClientInfo()}
                <div className="clearfix">
                    {this.renderAssignedPeople()}
                    {this.renderDates()}
                </div>
                <AddEditModal
                    isOpen={isAddNoteModalShown}
                    hasAttachments
                    isLoading={isNoteAdding}
                    onClose={() => handleAddNoteModal(false)}
                    onSubmit={handleSubmitNote}
                />
            </header>
        )
    }

    renderLinks() {
        const { cx, url, handleAddNoteModal } = this.props
        return (
            <ul className={cx("links")}>
                <li>
                    <Link to={`${url}/edit`}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                                className={cx("withFill")}
                                fillRule="nonzero"
                                d="M6.02 17.656l-.001-.002.558-1.578 1.347 1.348-1.521.537a.255.255 0 0 1-.383-.306zM17 9.5L14.5 7l.707-.707a1 1 0 0 1 1.414 0l1.086 1.086a1 1 0 0 1 0 1.414L17 9.5zM13.5 8l2.5 2.5-6 6-.25-.75-1.25-.25-.25-1.25L7.5 14l6-6z"
                            />
                        </svg>
                        <span>Edit profile</span>
                    </Link>
                </li>
                <li onClick={() => handleAddNoteModal(true)}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            className={cx("withFill")}
                            fillRule="nonzero"
                            d="M16.207 9.207V7.793H7.793v1.414h8.414zm0 2.103V9.897H7.793v1.413h8.414zm0 2.103V12H7.793v1.413h8.414zM17.62 5c.382 0 .707.135.976.404.27.27.404.594.404.976V19l-2.793-2.793H6.38c-.382 0-.707-.14-.976-.42A1.386 1.386 0 0 1 5 14.792V6.38c0-.382.135-.707.404-.976.27-.27.594-.404.976-.404h11.24z"
                        />
                    </svg>
                    <span>Add Note</span>
                </li>
            </ul>
        )
    }

    renderStatuses() {
        const { cx, isActive, isVerified, isOnlineEnabled } = this.props
        return (
            <ul className={cx("statuses")}>
                <li className={cx("activeStatus", { isActive })}>
                    <div />
                    <span>{isActive ? "Active" : "Not active"}</span>
                </li>
                <li>
                    {isVerified && (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g fill="none" fillRule="evenodd">
                                <path d="M3 3h18v18H3z" />
                                <path
                                    className={cx("withFill")}
                                    d="M12 4.5c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zm-1.5 11.25L6.75 12l1.058-1.058 2.692 2.685 5.693-5.692L17.25 9l-6.75 6.75z"
                                />
                            </g>
                        </svg>
                    )}
                    <span>Verified</span>
                </li>
                <li>
                    {isOnlineEnabled && (
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <g fill="none" fillRule="evenodd">
                                <path d="M3 3h18v18H3z" />
                                <path
                                    className={cx("withFill")}
                                    d="M12 4.5c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zm-1.5 11.25L6.75 12l1.058-1.058 2.692 2.685 5.693-5.692L17.25 9l-6.75 6.75z"
                                />
                            </g>
                        </svg>
                    )}
                    <span>Online Enabled</span>
                </li>
            </ul>
        )
    }

    renderClientInfo() {
        const { cx, headerInfo } = this.props

        return (
            <ul className={cx("info")}>
                {Object.keys(headerInfo).map(key => {
                    const { title, list } = headerInfo[key]
                    return (
                        <li key={key}>
                            <h3>{title}</h3>
                            <p>{list}</p>
                        </li>
                    )
                })}
            </ul>
        )
    }

    renderAssignedPeople() {
        const { cx, assignedUsers } = this.props

        if (assignedUsers.length > 0) {
            return (
                <section className={cx("assignee")}>
                    <span>Assigned to:</span>
                    <ul>
                        {assignedUsers.map(user => {
                            const { id, name } = user
                            return (
                                <li key={id}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            className={cx("withFill")}
                                            fillRule="nonzero"
                                            d="M13.75 14.666v-1.083c.262-.12 1.013-.95 1.092-1.599.207-.015.53-.19.627-.886.05-.373-.153-.582-.277-.649 0 0 .308-.543.308-1.199 0-1.315-.556-2.437-1.75-2.437 0 0-.415-.813-1.75-.813-2.475 0-3.5 1.474-3.5 3.25 0 .598.308 1.2.308 1.2-.124.066-.328.276-.277.648.096.695.42.871.627.886.079.648.83 1.478 1.092 1.6v1.082C9.667 16.292 5 15.208 5 19h14c0-3.792-4.667-2.708-5.25-4.334z"
                                        />
                                    </svg>
                                    <span>{name}</span>
                                </li>
                            )
                        })}
                    </ul>
                </section>
            )
        }

        return (
            <section className={cx("assignee")}>
                <span>Assigned to: -</span>
            </section>
        )
    }

    renderDates() {
        const { cx, added, lastUpdated } = this.props

        return (
            <ul className={cx("dates")}>
                <li>
                    <h3>Added:</h3>
                    <p>{formatDate(added)}</p>
                </li>
                <li>
                    <h3>Last updated:</h3>
                    <p>{formatDate(lastUpdated)}</p>
                </li>
            </ul>
        )
    }
}

export default withStyles(Header, style)
