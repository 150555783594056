import { useMemo } from "react"
import { useQuery } from "react-query"

const mapReactQueryFetchStatus = query => ({
    isLoading: query.isFetching,
    isLoaded: query.isFetched,
    isError: query.isError,
    lastFetch: query.dataUpdatedAt
})

const useQueryDetailsFactory = ({ key, fetchFunction }) => {
    return function useQueryList(props = {}) {
        const { id, reactQueryProps = {} } = props

        const query = useQuery([key, { id }], () => fetchFunction(id), {
            refetchOnWindowFocus: false,
            retry: false,
            ...reactQueryProps
        })

        const fetchStatus = useMemo(() => mapReactQueryFetchStatus(query), [
            query.isError,
            query.isFetching,
            query.isFetched,
            query.dataUpdatedAt
        ])

        return {
            data: query.data ? query.data.data : null,
            refetch: query.refetch,
            fetchStatus,
            query
        }
    }
}

export default useQueryDetailsFactory
