import React, { Component, Fragment } from "react"
import ReturnHeader from "components/Reusable/ReturnHeader/ReturnHeader"
import CreateRole from "./CreateRole/CreateRole"

class Form extends Component {
    render() {
        const { isEdit } = this.props
        return (
            <Fragment>
                <ReturnHeader
                    link="/settings/users/roles"
                    label="Roles Management"
                    title={isEdit ? "Edit Role" : "New Role"}
                />
                <CreateRole {...this.props} />
            </Fragment>
        )
    }
}

export default Form
