import { useMemo } from "react"
import { useMutation as useReactQueryMutation, useQueryClient } from "react-query"

const mapReactQueryFetchStatus = mutation => ({
    isLoading: mutation.isLoading,
    isLoaded: mutation.isSuccess,
    isError: mutation.isError
})

const useMutationFactory = ({ key, fetchFunction }) => {
    return function useMutation(props = {}) {
        const queryClient = useQueryClient()
        const { reactQueryProps = {}, skipInvalidateQueries = false } = props

        const mutation = useReactQueryMutation(fetchFunction, {
            ...reactQueryProps,
            onSuccess: (result, variables, context) => {
                if (reactQueryProps.onSuccess) {
                    reactQueryProps.onSuccess(result, variables, context)
                }

                if (!skipInvalidateQueries) {
                    queryClient.invalidateQueries(key)
                }
            }
        })

        const fetchStatus = useMemo(() => mapReactQueryFetchStatus(mutation), [
            mutation.isError,
            mutation.isSuccess,
            mutation.isLoading
        ])

        return {
            fetchStatus,
            data: mutation.data,
            error: mutation.error,
            mutate: mutation.mutateAsync,
            mutation
        }
    }
}

export default useMutationFactory
