import React, { Component } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ReactSVG from "react-svg"

import withStyles from "HOC/withStyles"

import styles from "./DragAndDropList.css"
import dragItemIcon from "assets/drag-item.svg"

class DragAndDropList extends Component {
    render() {
        const { cx, children } = this.props

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <tbody
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={this.getListStyle(snapshot.isDraggingOver)}
                        >
                            {children.map((child, index) => (
                                <Draggable key={index} draggableId={index} index={index}>
                                    {(provided, snapshot) => (
                                        <tr
                                            className={cx("row", { isDragging: snapshot.isDragging })}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={this.getItemStyle(
                                                snapshot.isDragging,
                                                index === 0,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <td className={cx("dragHandler")} style={{ width: 30 }}>
                                                <ReactSVG
                                                    src={dragItemIcon}
                                                    alt="Drag and drop"
                                                    className={cx("dragItemIcon")}
                                                />
                                            </td>
                                            {child}
                                        </tr>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </tbody>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    onDragEnd = result => {
        const { itemsList, handleReorder } = this.props

        if (!result.destination) {
            return
        }

        const newItemsList = Array.from(itemsList)
        const [removed] = newItemsList.splice(result.source.index, 1)
        newItemsList.splice(result.destination.index, 0, removed)

        handleReorder(newItemsList)
    }

    getItemStyle = (isDragging, isFirstElement, draggableStyle) => ({
        userSelect: "none",
        borderTop: !isFirstElement || isDragging ? "1px solid #e6e8ed" : null,
        ...draggableStyle
    })

    getListStyle = () => ({
        width: "100%"
    })
}

export default withStyles(DragAndDropList, styles)
