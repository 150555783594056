import React, { Component, Fragment } from "react"
import { Route, Switch } from "react-router-dom"
import Dashboard from "modules/DashboardModule"
import Products from "modules/ProductsModule"
import { News, Orders, Clients, Content, Apps, Calendar } from "modules"
import Settings from "./Settings/Settings"
import Home from "./Home"
import { UploadModuleRoutes as Upload } from "modules/UploadModule/routes"
import Projects from "modules/ProjectsModule"
import Profile from "modules/ProfileModule"
import OnlineStore from "modules/OnlineStoreModule"
import Wms from "modules/WmsModule"

class ContentMain extends Component {
    render() {
        return (
            <Fragment>
                <Switch>
                    <Route exact path={"/"} component={Home} />
                    <Route path={"/dashboard"} component={Dashboard} />
                    <Route path={"/calendar"} component={Calendar} />
                    <Route path={"/news"} component={News} />
                    <Route path={"/orders"} component={Orders} />
                    <Route path={"/contact"} component={Clients} />
                    <Route path={"/products"} component={Products} />
                    <Route path={"/projects"} component={Projects} />
                    <Route path={"/content"} component={Content} />
                    <Route path={"/settings"} component={Settings} />
                    <Route path={"/upload"} component={Upload} />
                    <Route path={"/apps"} component={Apps} />
                    <Route path={"/profile"} component={Profile} />
                    <Route path={"/online-store"} component={OnlineStore} />
                    <Route path={"/wms"} component={Wms} />
                    <Route
                        render={props => {
                            props.history.push("/404")
                            return null
                        }}
                    />
                </Switch>
            </Fragment>
        )
    }
}

export default ContentMain
