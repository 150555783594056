import React, { Component } from "react"
import Purchases from "./Purchases"

class PurchasesContainer extends Component {
    state = {
        dates: {
            last_purchase_date: null,
            last_showroom_visits: null,
            last_online_visits: null
        }
    }

    componentDidMount() {
        const { values } = this.props
        if (values) {
            this.setState(prevState => {
                const nextDates = { ...prevState.dates }

                nextDates.last_purchase_date = values.last_purchase_date || null
                nextDates.last_showroom_visits = values.last_showroom_visits || null
                nextDates.last_online_visits = values.last_online_visits || null

                return {
                    dates: nextDates
                }
            })
        }
    }

    handleSelectDate = (key, date) => {
        this.setState(prevState => {
            return {
                dates: {
                    ...prevState.dates,
                    [key]: date
                }
            }
        })
    }

    setValue = (key, val) => {
        const { handleEdit, setFieldValue } = this.props

        handleEdit(true)
        setFieldValue(key, val)
    }

    render() {
        const { dates } = this.state
        const { values, errors } = this.props

        return (
            <Purchases
                errors={errors}
                dates={dates}
                setValue={this.setValue}
                values={values}
                handleSelectDate={this.handleSelectDate}
            />
        )
    }
}

export default PurchasesContainer
