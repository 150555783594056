import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"
import {
    GET_PAYMENTS_START,
    GET_PAYMENTS_SUCCESS,
    GET_PAYMENTS_ERROR,
    CREATE_PAYMENT_START,
    CREATE_PAYMENT_SUCCESS,
    CREATE_PAYMENT_ERROR,
    UPDATE_PAYMENT_START,
    UPDATE_PAYMENT_SUCCESS,
    UPDATE_PAYMENT_ERROR,
    DELETE_PAYMENT_START,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_ERROR
} from "constants/index"

const getPaymentsStart = () => ({ type: GET_PAYMENTS_START })
const getPaymentsSuccess = payload => ({ type: GET_PAYMENTS_SUCCESS, payload })
const getPaymentsError = error => ({ type: GET_PAYMENTS_ERROR, error })

export const getPayments = params => dispatch => {
    dispatch(getPaymentsStart())

    return new Promise((resolve, reject) => {
        fetch
            .get(`/payments?${objToQueryString(params)}`)
            .then(data => {
                dispatch(getPaymentsSuccess(data))
                resolve(data)
            })
            .catch(error => {
                dispatch(getPaymentsError(error))
                reject(error)
            })
    })
}

const createPaymentStart = () => ({ type: CREATE_PAYMENT_START })
const createPaymentSuccess = payload => ({ type: CREATE_PAYMENT_SUCCESS, payload })
const createPaymentError = error => ({ type: CREATE_PAYMENT_ERROR, error })

export const createPayment = body => dispatch => {
    dispatch(createPaymentStart())

    return new Promise((resolve, reject) => {
        fetch
            .post(`/payments`, body)
            .then(data => {
                dispatch(createPaymentSuccess(data))
                resolve(data)
            })
            .catch(error => {
                dispatch(createPaymentError(error))
                reject(error)
            })
    })
}

const updatePaymentStart = () => ({ type: UPDATE_PAYMENT_START })
export const updatePaymentSuccess = payload => ({ type: UPDATE_PAYMENT_SUCCESS, payload })
const updatePaymentError = error => ({ type: UPDATE_PAYMENT_ERROR, error })

export const updatePayment = (id, body) => dispatch => {
    dispatch(updatePaymentStart())

    return new Promise((resolve, reject) => {
        fetch
            .patch(`/payments/${id}`, body)
            .then(data => {
                dispatch(updatePaymentSuccess(data))
                resolve(data)
            })
            .catch(error => {
                dispatch(updatePaymentError(error))
                reject(error)
            })
    })
}

const deletePaymentStart = () => ({ type: DELETE_PAYMENT_START })
export const deletePaymentSuccess = id => ({ type: DELETE_PAYMENT_SUCCESS, id })
const deletePaymentError = error => ({ type: DELETE_PAYMENT_ERROR, error })

export const deletePayment = id => dispatch => {
    dispatch(deletePaymentStart())

    return new Promise((resolve, reject) => {
        fetch
            .deleteRAW(`/payments/${id}`)
            .then(() => {
                dispatch(deletePaymentSuccess(id))
                resolve(id)
            })
            .catch(error => {
                dispatch(deletePaymentError(error))
                reject(error)
            })
    })
}
