import React, { Component } from "react"
import { connect } from "react-redux"

import fetch from "helpers/fetch"
import * as actions from "actions/feature"
import EditableList from "components/Reusable/EditableList"
import config from "./config"

import Layout from "components/Reusable/Layout/index"

class Listing extends Component {
    render() {
        const { currentFeature } = this.props

        if (currentFeature.isLoading) {
            return null
        }

        return (
            <Layout
                {...this.props}
                returnLink={{
                    label: "Features",
                    link: "/settings/features"
                }}
            >
                <EditableList
                    config={config}
                    listTitle={currentFeature.name}
                    list={currentFeature.values}
                    editUrlPrefix={`/settings/features/${currentFeature.id}/values/card`}
                    addUrl={`/settings/features/${currentFeature.id}/values/add`}
                    title={"Are you sure you want to delete this attribute?"}
                    deleteElement={this.handleDeleteValue}
                />
            </Layout>
        )
    }

    handleDeleteValue = id => {
        const { currentFeature, getParentData } = this.props

        fetch.deleteRAW(`/product-feature-values/${id}`).then(() => {
            getParentData(currentFeature.id)
        })
    }
}

const mapStateToProps = state => {
    return {
        features: state.db.features.list.data,
        currentFeature: state.db.features.current.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getParentData: id => {
            dispatch(actions.getFeatureById(id))
            dispatch(actions.getFeatureValueByIdStart())
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing)
