import React, { useState } from "react"
import PropTypes from "prop-types"
import { ConfirmModal, Checkbox } from "@butterfly-frontend/ui"

import styles from "./DeleteNotEmptyWarehouse.module.css"

const DeleteNotEmptyWarehouse = ({ onCancel, onDelete, isLoading }) => {
    const [isChecked, setIsChecked] = useState(false)
    const toggleIsCheck = () => setIsChecked(!isChecked)

    return (
        <ConfirmModal
            onCancel={onCancel}
            onAction={onDelete}
            isLoading={isLoading}
            title="Delete this warehouse"
            buttonLabel="Delete"
            buttonColor="red"
            disabled={!isChecked}
        >
            <div>
                Warehouse cannot be removed until the last package has been picked up and transferred to another
                Warehouse.
            </div>
            <div className={styles.deleteAutomatically}>
                <Checkbox checked={isChecked} onChange={toggleIsCheck} label="Delete automatically" disabled />
            </div>
        </ConfirmModal>
    )
}

DeleteNotEmptyWarehouse.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default DeleteNotEmptyWarehouse
