import React, { useState, memo, useMemo, useEffect } from "react"
import ReactSVG from "react-svg"

import withStyles from "HOC/withStyles"
import { Checkbox } from "ui"
import { InputButton } from "ui/SelectWithSearch/components"

import styles from "./FilterCheckboxList.css"
import stylesCheckbox from "./overrides/Checkbox.css"
import lockIcon from "assets/black_lock.svg"

const CheckboxListItem = memo(
    withStyles(props => {
        const { cx, id, name, checked, handleChange, blanked, isPrivate } = props

        return (
            <div className={cx("listItem", { blanked })}>
                <Checkbox customStyles={stylesCheckbox} checked={checked} onClick={onClick} onChange={() => {}} />
                {isPrivate && <ReactSVG src={lockIcon} className={cx("lockIcon")} />}
                <span onClick={onClick} className={cx("name")}>
                    {name}
                </span>
            </div>
        )

        function onClick(event) {
            event.preventDefault()
            event.stopPropagation()
            handleChange(id)
        }
    }, styles),
    (prevProps, nextProps) => {
        return (
            prevProps.checked === nextProps.checked &&
            prevProps.id === nextProps.id &&
            prevProps.blanked === nextProps.blanked
        )
    }
)

const FilterCheckboxList = props => {
    const {
        cx,
        handleChange,
        searchPhrase,
        searchPhraseRef,
        data,
        selectedData,
        shouldHideOnDataChange,
        notFoundMessage
    } = props
    const [query, setQuery] = useState("")

    const [shouldHide, setShouldHide] = useState(false)
    const checked = id => selectedData.indexOf(id) !== -1

    useEffect(() => {
        setShouldHide(shouldHideOnDataChange)
    }, [data])

    useEffect(() => {
        setQuery(searchPhrase)
    }, [searchPhraseRef])

    const preparedData = shouldHide ? data.filter(row => row.count !== 0 || selectedData.includes(row.id)) : data

    const filteredData = useMemo(
        () =>
            query.length === 0
                ? preparedData
                : preparedData.filter(row => row.name.match(new RegExp(`.*${query}.*`, "i"))),
        [preparedData, query]
    )

    return (
        <div className={cx("root")}>
            <header className={cx("header")}>
                <div className={cx("inputWrapper")}>
                    <input
                        className={cx("input")}
                        value={query}
                        placeholder="Search..."
                        onChange={e => setQuery(e.target.value)}
                        onClick={() => {}}
                        autoComplete="off"
                    />
                    {query.length > 0 && (
                        <InputButton isClearBlocked={false} isValue={true} onClear={() => setQuery("")} />
                    )}
                </div>
            </header>

            {renderListOrEmptyList()}
        </div>
    )

    function renderListOrEmptyList() {
        if (!filteredData.length) {
            return <div className={cx("emptyList")}>{notFoundMessage || "Not found."}</div>
        }

        return (
            <div className={cx("list")}>
                {filteredData.map(row => {
                    const check = checked(row.id)
                    return (
                        <CheckboxListItem
                            id={row.id}
                            key={row.id}
                            checked={check}
                            isPrivate={row.type === "private"}
                            blanked={shouldHide && check && row.count === 0}
                            name={row.name}
                            handleChange={handleChange}
                        />
                    )
                })}
            </div>
        )
    }
}

export default withStyles(FilterCheckboxList, styles)
