import React, { useState } from "react"
import { Formik, Form as FormikForm, FieldArray } from "formik"
import PropTypes from "prop-types"
import fetch from "helpers/fetch"
import idx from "idx"

import SectionModalProducts from "./SectionModalProducts"
import { section as sectionSchema } from "./schema"

const Container = ({ onClose, onSave, data }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = {
        products: []
    }

    const onSubmit = async (values, formikActions) => {
        try {
            setIsSubmitting(true)

            await onSave({
                ...values,
                products: values.products.map(item => ({ id: item.id }))
            })

            onClose()
        } catch (error) {
            setIsSubmitting(false)
            formikActions.setErrors(error)
        }
    }

    const addProduct = (productID, arrayHelpers, setFieldError) => {
        fetch.get(`/products/${productID}`).then(product => {
            arrayHelpers.push({
                id: product.data.id,
                name: product.data.name,
                image: idx(product, _ => _.data.images.thumb[0].url)
            })

            setFieldError("products", null)
        })
    }

    return (
        <Formik
            initialValues={{ ...initialValues, ...data }}
            validationSchema={sectionSchema}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {propsForm => (
                <FormikForm>
                    <FieldArray name="products">
                        {arrayHelpers => (
                            <SectionModalProducts
                                onClose={onClose}
                                form={propsForm}
                                handleRemove={index => arrayHelpers.remove(index)}
                                handleReorder={products => propsForm.setFieldValue("products", products)}
                                handleAdd={productID => addProduct(productID, arrayHelpers, propsForm.setFieldError)}
                                isSubmitting={isSubmitting}
                            />
                        )}
                    </FieldArray>
                </FormikForm>
            )}
        </Formik>
    )
}

Container.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default Container
