import * as constants from "./../../constants/index"

const INITIAL_STATE = 1

function pagination(state = INITIAL_STATE, action) {
    const current_page = action.payload ? action.payload.value : INITIAL_STATE

    switch (action.type) {
        case constants.CLEAR_PAGINATION:
            return INITIAL_STATE
        case constants.CHANGE_PAGINATION:
            return current_page || INITIAL_STATE

        case constants.GET_SUCCESS:
            const meta = (action.payload.data || {}).meta

            if (meta && state > meta.last_page) {
                return meta.last_page
            }

            return state

        default:
            return state
    }
}

export default pagination
