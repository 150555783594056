import React, { Fragment } from "react"
import ReactSVG from "react-svg"

import useStyle from "helpers/getCxFromStyles"

import InfiniteScroll from "ui/InfiniteScroll"
import loaderStyles from "../../overrides/Loader.css"
import Loader from "components/Reusable/Loader"

import styles from "./list.css"
import checkIcon from "assets/checked.svg"
import lockIcon from "assets/ico-lock.svg"

const Li = props => {
    const { name, selected, onClick, type, loading } = props
    const cx = useStyle(styles)

    return (
        <li className={cx("listItem", { selected })} onClick={onClick}>
            {type === "private" && <ReactSVG src={lockIcon} className={cx("lockIcon")} />}
            <span className={cx("name")}>{name}</span>
            {selected && <img className={cx("selectedIcon")} src={checkIcon} alt="" />}
            {loading && (
                <div key="loader" className={cx("loader")}>
                    <Loader customStyles={loaderStyles} />
                </div>
            )}
        </li>
    )
}

const List = props => {
    const {
        privateSelections,
        publicSelections,
        chooseSelection,
        isResultSearch,
        handleLoadMore,
        hasMore,
        activeSelectionId,
        loadingSelectionId
    } = props
    const cx = useStyle(styles)

    const renderList = list =>
        list.map(({ id, name, type }) => (
            <Li
                key={id}
                name={name}
                type={type}
                loading={loadingSelectionId === id}
                selected={activeSelectionId === id}
                onClick={() => loadingSelectionId === null && activeSelectionId !== id && chooseSelection(id)}
            />
        ))

    const renderResultSearch = () => (
        <div className={cx("content")}>
            <h3 className={cx("labelList")}>{privateSelections.length + publicSelections.length} RESULTS:</h3>
            <ul className={cx("list")}>{renderList([...privateSelections, ...publicSelections])}</ul>
        </div>
    )

    const renderResultWithoutSearch = () => (
        <div className={cx("content")}>
            {!!privateSelections.length && (
                <Fragment>
                    <h3 className={cx("labelList")}>PRIVATE:</h3>
                    <ul className={cx("list")}>{renderList(privateSelections)}</ul>
                </Fragment>
            )}
            {!!publicSelections.length && (
                <Fragment>
                    <h3 className={cx("labelList")}>PUBLIC:</h3>
                    <ul className={cx("list")}>{renderList(publicSelections)}</ul>
                </Fragment>
            )}
        </div>
    )

    return (
        <div className={cx("root")}>
            <InfiniteScroll
                hasMore={hasMore}
                loader={
                    <div key="loader" className={cx("loader")}>
                        <Loader customStyles={loaderStyles} />
                    </div>
                }
                loadMore={handleLoadMore}
                threshold={100}
                useWindow={false}
            >
                {isResultSearch ? renderResultSearch() : renderResultWithoutSearch()}
            </InfiniteScroll>
        </div>
    )
}

export default List
