import React, { Component } from "react"

import Dropzone from "ui/Dropzone/index"
import withStyles from "HOC/withStyles"

import styles from "./styles.css"
import stylesFolderSelect from "./overrides/stylesFolderSelect.css"

import { Button, SearchBar, Checkbox, SelectWithSearch, Cropper, ReloadableImageWrap } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import { Image } from "modules/UploadModule/components"
import Input from "components/Reusable/Form/Input"
import CreateFolderModal from "../CreateFolderModal"
import customCheckboxStyles from "./overrides/Checkbox.css"
import customDropzoneStyles from "./overrides/Dropzone.css"

import { IMAGE_ACCEPTED_FORMATS } from "constants/index"

import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"

import cloud from "assets/cloud.svg"

class ImagesList extends Component {
    constructor(props) {
        super(props)
        this.state = { cropImageId: null }
    }

    render() {
        const {
            cx,
            foldersList,
            currentFolder,
            handleUploadSuccess,
            images,
            currentlySelectedImages,
            isImagesLoading,
            isFoldersLoading,
            triggerImageSelect,
            isImageDragging,
            uploader: { state, actions },
            isEditFolderName,
            isAddFolderName,
            isFixed,
            isDeleteModal,
            isImagesDeleting,
            handleToggleDeleteModal,
            handleDeleteChosenImages,
            newFolderName,
            setImageDraggingStatus,
            nameInputValue,
            handleToggleEdit,
            handleChangeFolderNameInputValue,
            handleChooseFolder,
            handleDeleteFolder,
            handleToggleAddFolderModal,
            handleChangeNewFolderName,
            handleAddFolder,
            imageSearchValue,
            handleSearch,
            handleFilterByUrlAndId,
            handleFilterByName,
            areAllImagesSelected,
            handleToggleImagesSelect
        } = this.props

        const filteredByUrlAndId = handleFilterByUrlAndId(images)
        const filteredList = handleFilterByName(filteredByUrlAndId)
        const isAddedAtList = file => file.id && images.map(image => image.id).includes(file.id)
        const isDeleteEnabled = images.length === 0 && foldersList.length > 1

        const modal = isDeleteModal ? (
            <Modal>
                <Confirm
                    handleHideModal={handleToggleDeleteModal}
                    confirmModalTitle="Are you sure you want to delete all the photos"
                    handleDelete={handleDeleteChosenImages}
                />
            </Modal>
        ) : null

        const isEditDisabled =
            !!currentFolder &&
            currentFolder.name === "Default" &&
            foldersList.filter(folder => folder.name === "Default").length <= 1

        const renderDropzone = () => {
            return (
                <Dropzone
                    disabled={isImageDragging}
                    isUploading={state.isUploading}
                    isSuccess={state.isSuccess}
                    progress={state.progress}
                    handleUpload={actions.handleUpload}
                    handleRemove={actions.handleRemove}
                    disableClick={true}
                    isMultiple={true}
                    customStyles={customDropzoneStyles}
                    activeClassName={cx("canDrop")}
                    acceptFormats={IMAGE_ACCEPTED_FORMATS}
                >
                    <div className={cx("images")}>
                        {(isImagesLoading || isImagesDeleting) && (
                            <span className={cx("loader", { isImagesLoading, isImagesDeleting })}>
                                <SnakeLoader />
                            </span>
                        )}
                        {!isImagesLoading && filteredList && filteredList.length === 0 && state.addedFile.length === 0 && (
                            <div className={cx("imagesEmptyInfo")}>
                                <img src={cloud} alt="move" />
                                <p>
                                    Drag and drop your files here or click button <span>Upload</span>
                                </p>
                                <p>(only .jpg or .png files)</p>
                            </div>
                        )}
                        {state.addedFile.map(file => {
                            return (
                                !isAddedAtList(file) &&
                                file.isUploading && (
                                    <div key={file.title} className={cx("progress-background")}>
                                        <div className={cx("progress")}>
                                            <p>{file.progress}%</p>
                                            <span style={{ width: `${file.progress}%` }} />
                                        </div>
                                        <h6>{file.title}</h6>
                                    </div>
                                )
                            )
                        })}
                        {filteredList &&
                            filteredList.length > 0 &&
                            !isImagesDeleting &&
                            filteredList.map(image => {
                                const { id, url, name } = image
                                return (
                                    <ReloadableImageWrap image={url}>
                                        {({ image }) => (
                                            <Image
                                                key={id}
                                                defaultChecked={areAllImagesSelected}
                                                setImageDraggingStatus={setImageDraggingStatus}
                                                isImageDisabled={!currentlySelectedImages.find(el => el.id === id)}
                                                triggerImageSelect={triggerImageSelect}
                                                isImageDragging={isImageDragging}
                                                currentlySelectedImagesLength={currentlySelectedImages.length}
                                                id={id}
                                                url={image}
                                                name={name}
                                                onCrop={() => this.setState({ cropImageId: id })}
                                            />
                                        )}
                                    </ReloadableImageWrap>
                                )
                            })}
                    </div>
                </Dropzone>
            )
        }

        return (
            <div className={cx("root")}>
                <div className={cx({ isFixed })}>
                    <div className={cx("cover")}>
                        <h1>Photos</h1>
                        <Dropzone
                            disabled={isImageDragging || !currentFolder}
                            isUploading={state.isUploading}
                            isSuccess={state.isSuccess}
                            progress={state.progress}
                            handleUpload={actions.handleUpload}
                            handleRemove={actions.handleRemove}
                            isMultiple={true}
                            acceptFormats={IMAGE_ACCEPTED_FORMATS}
                        >
                            <Button className={cx("first-button")} label="Upload" isDisabled={!currentFolder} />
                        </Dropzone>
                        <Button className={cx("first-button")} onClick={() => handleToggleAddFolderModal("open")} />
                        <SearchBar
                            handleSearch={handleSearch}
                            value={imageSearchValue}
                            placeholder="Search for images…"
                        />
                        <div className={cx("controlFolders")}>
                            <div style={isEditFolderName ? { visibility: "hidden" } : {}}>
                                <SelectWithSearch
                                    value={
                                        isFoldersLoading
                                            ? ""
                                            : currentFolder && currentFolder.name
                                            ? currentFolder.name
                                            : ""
                                    }
                                    values={foldersList.map(folder => ({ value: folder.id, label: folder.name }))}
                                    customStyles={stylesFolderSelect}
                                    setValue={({ value }) => handleChooseFolder({ id: value })}
                                    withoutFetch
                                />
                            </div>
                            <div className={cx("name-actions")}>
                                {isEditFolderName && (
                                    <div className={cx("save-cancel")}>
                                        <Input
                                            value={nameInputValue}
                                            label=""
                                            isPlaceholder={true}
                                            onChange={({ event }) =>
                                                handleChangeFolderNameInputValue({ value: event.target.value })
                                            }
                                        />
                                        <Button
                                            className={cx("first-button")}
                                            onClick={() =>
                                                handleToggleEdit(
                                                    currentFolder.id,
                                                    isEditFolderName ? "saveEdit" : "saveAdd"
                                                )
                                            }
                                            label="Save"
                                        />
                                        <span onClick={handleToggleEdit}>Cancel</span>
                                    </div>
                                )}
                                {!isEditFolderName && currentFolder && (
                                    <div className={cx("edit-delete")}>
                                        <span
                                            style={{ opacity: !isEditDisabled ? 1 : 0.5 }}
                                            className={cx("edit")}
                                            onClick={() => !isEditDisabled && handleToggleEdit(null, "openEdit")}
                                        >
                                            Edit
                                        </span>
                                        <span
                                            style={{ opacity: isDeleteEnabled && !isEditDisabled ? 1 : 0.5 }}
                                            onClick={() =>
                                                isDeleteEnabled &&
                                                !isEditDisabled &&
                                                handleDeleteFolder(currentFolder.id)
                                            }
                                        >
                                            Delete
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className={cx("delete-images")}>
                                {currentlySelectedImages.length > 0 && !isImagesDeleting && (
                                    <p onClick={handleToggleDeleteModal}>
                                        Delete {currentlySelectedImages.length} photo
                                        {currentlySelectedImages.length > 1 && "s"}
                                    </p>
                                )}
                            </div>
                        </div>
                        {!currentFolder ? renderPlaceholder() : renderDropzone()}
                        {isAddFolderName && (
                            <CreateFolderModal
                                handleHide={handleToggleAddFolderModal}
                                handleChange={handleChangeNewFolderName}
                                newFolderName={newFolderName}
                                handleAddFolder={handleAddFolder}
                            />
                        )}
                        {filteredList && filteredList.length > 0 && !isImagesDeleting && (
                            <div className={cx("checkboxHolder")}>
                                <Checkbox
                                    id="selectAllImages"
                                    name={"Select All"}
                                    customStyles={customCheckboxStyles}
                                    checked={areAllImagesSelected}
                                    onChange={() => {
                                        handleToggleImagesSelect()
                                        triggerImageSelect(areAllImagesSelected ? [] : filteredList)
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {modal}

                {!!this.state.cropImageId && (
                    <Cropper
                        onClose={() => this.setState({ cropImageId: null })}
                        mediaId={this.state.cropImageId}
                        onSuccess={handleUploadSuccess}
                    />
                )}
            </div>
        )

        function renderPlaceholder() {
            return (
                <div className={cx("imagesEmptyInfo")}>
                    <p className={cx("placeholderTxt")}>Select a folder to continue</p>
                </div>
            )
        }
    }
}

export default withStyles(ImagesList, styles)
