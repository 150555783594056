import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    fetchStatus: fetchStatus.initialState()
}

function list(state = initialState, action) {
    switch (action.type) {
        case constants.GET_PRODUCT_CONDITIONS_START:
            return { ...initialState, isLoading: true, fetchStatus: fetchStatus.requestState() }

        case constants.GET_PRODUCT_CONDITIONS_SUCCESS:
            let productConditionsList = action.payload.data.data ? action.payload.data.data : []
            if (action.payload.data.products) {
                productConditionsList = action.payload.data.conditions
            }

            return {
                data: productConditionsList,
                meta: action.payload.data.meta || {},
                fetchStatus: fetchStatus.successState(),
                isLoading: false
            }

        case constants.GET_PRODUCT_CONDITIONS_ERROR:
            return {
                data: [],
                meta: {},
                isLoading: false,
                fetchStatus: {
                    ...fetchStatus.failureState(),
                    error: (action.payload || {}).error
                }
            }

        default:
            return state
    }
}

export default list
