import React, { useEffect } from "react"
import PropTypes from "prop-types"
import idx from "idx"
import _capitalize from "lodash/capitalize"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"
import { createHintText } from "modules/ContentModule/helpers"

import { Dropzone } from "ui"

import styles from "./styles.css"

const Image = ({
    cx,
    imageType,
    image,
    error,
    setImage,
    removeImage,
    imageDimensions,
    uploader: { state: uploadState, actions: uploadActions }
}) => {
    useEffect(() => {
        if (idx(uploadState, _ => _.addedFile.url)) {
            setImage({
                id: uploadState.addedFile.id,
                name: uploadState.addedFile.title,
                url: uploadState.addedFile.url
            })
        }
    }, [uploadState.isUploading, uploadState.isSuccess, uploadState.addedFile])

    const onRemoveImage = () => {
        removeImage()
    }

    const renderUpladingState = progress => (
        <div className={cx("dropzoneStateUploading")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    )

    const renderEmptyState = () => (
        <Dropzone
            isUploading={uploadState.isUploading}
            isSuccess={uploadState.isSuccess}
            progress={uploadState.progress}
            handleUpload={uploadActions.handleUpload}
            handleRemove={uploadActions.handleRemove}
            isMultiple={false}
        >
            <div className={cx("dropzoneStateEmpty")}>
                <div className={cx("imageWrapper", `imageWrapper${_capitalize(imageType)}`)} />
                <span className={cx("typeName")}>{_capitalize(imageType)}</span>
                <span className={cx("typeHint")}>{createHintText(imageDimensions)}</span>
            </div>
        </Dropzone>
    )

    const renderImageState = () => (
        <div className={cx("dropzoneStateImage")}>
            <div className={cx("imageWrapper")}>
                <div className={cx("image")}>{image && <img src={image.url} alt="preview" />}</div>
                <span>{image.name}</span>
            </div>
            <div className={cx("bottomInfoWrapper")}>
                <div className={cx("icoWrapper", `icoWrapper${_capitalize(imageType)}`)} />
                <span>{_capitalize(imageType)}</span>
                <button onClick={onRemoveImage} />
            </div>
        </div>
    )

    return (
        <div
            className={cx("dropzoneWrapper", `cover_${imageType}`, {
                isError: !!error
            })}
        >
            {uploadState.isUploading
                ? renderUpladingState(uploadState.progress)
                : !!image
                ? renderImageState()
                : renderEmptyState()}
        </div>
    )
}

Image.propTypes = {
    uploader: PropTypes.object.isRequired,
    image: PropTypes.object,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    imageType: PropTypes.string.isRequired,
    setImage: PropTypes.func.isRequired,
    removeImage: PropTypes.func.isRequired,
    imageDimensions: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
    }).isRequired,
    cx: PropTypes.func.isRequired
}

export default withUploader(withStyles(Image, styles))
