import * as constants from "constants/index"

function locations(state = [], action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_LOCATIONS_FOR_FILTERS:
            return []
        case constants.PUT_LOCATIONS_FOR_FILTERS:
            return [...value]
        default:
            return state
    }
}

export default locations
