import React from "react"
import PropTypes from "prop-types"
import { ConfirmModal } from "@butterfly-frontend/ui"

const DeletePrimaryWarehouse = ({ onCancel, onDelete, isLoading }) => (
    <ConfirmModal
        onCancel={onCancel}
        onAction={onDelete}
        isLoading={isLoading}
        title="Delete this warehouse"
        buttonLabel="Delete"
        buttonColor="red"
        disabled
    >
        Primary Warehouse cannot be removed. Please <b>CHANGE</b> the Primary Warehouse to delete.
    </ConfirmModal>
)

DeletePrimaryWarehouse.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default DeletePrimaryWarehouse
