import React, { useState } from "react"
import PropTypes from "prop-types"
import { ConfirmModal, Checkbox } from "@butterfly-frontend/ui"

import styles from "./ChangeAvailableOrderItemToNewOrderModal.module.css"

const ChangeAvailableOrderItemToNewOrderModal = ({
    onCancel,
    changeAvailableOrderItemToNewOrder,
    isLoading,
    hasCombination
}) => {
    const [shouldKeepCombination, setShouldKeepCombination] = useState(false)
    const toggleIsCheck = () => setShouldKeepCombination(isChecked => !isChecked)

    return (
        <ConfirmModal
            onCancel={onCancel}
            onAction={() => changeAvailableOrderItemToNewOrder({ shouldKeepCombination })}
            title="Change current type to New order"
            buttonLabel="Accept"
            isLoading={isLoading}
        >
            <div>When you change item type, you can order new item with your customization.</div>
            {hasCombination && (
                <div className={styles.keepCombinationCheckbox}>
                    <Checkbox checked={shouldKeepCombination} onChange={toggleIsCheck} label="Keep the combination" />
                </div>
            )}
        </ConfirmModal>
    )
}

ChangeAvailableOrderItemToNewOrderModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    changeAvailableOrderItemToNewOrder: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    hasCombination: PropTypes.bool
}

export default ChangeAvailableOrderItemToNewOrderModal
