import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import SubmitModal from "ui/SubmitModal"
import styles from "./DeleteProductsModal.css"

const DeleteProductsModal = props => {
    const { isOpen, isLoading, productsCount, onCancel, onSubmit, cx } = props

    return (
        <SubmitModal
            redButton
            isOpen={isOpen}
            isLoading={isLoading}
            header={"Delete these products?"}
            body={
                <span>
                    {"Are you sure you want to delete "}
                    <span className={cx("delete-header-bold")}>
                        {`${productsCount} products`}
                        <span className={cx("delete-header-normal")}>?</span>
                    </span>
                    {" This action cannot be reversed."}
                </span>
            }
            submitText="Delete"
            onClose={onCancel}
            onSubmit={onSubmit}
        />
    )
}

DeleteProductsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    productsCount: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default withStyles(DeleteProductsModal, styles)
