import React, { useState, useEffect, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"

import moment from "moment"

import withStyles from "HOC/withStyles"

import calendar from "assets/calendar-blue.svg"
import close from "assets/close.svg"
import closeBlue from "assets/close-blue.svg"
import style from "./DatePicker.css"

import { Calendar } from "react-date-range"
import { READABLE_STANDARD_DATE, API_DATE_FORMAT } from "constants/index"

const DatePicker = props => {
    const {
        cx,
        date,
        label,
        placeholder,
        disableClear,
        minDate,
        maxDate,
        error,
        calendarAboveInput,
        isClearInLabel,
        calendarOnRight,
        noDatePlaceholder,
        handleSelectDate
    } = props

    const calendarWindow = useRef(null)

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

    useEffect(() => {
        if (isDatePickerOpen) {
            document.addEventListener("click", handleCloseCalendar)
            return () => document.removeEventListener("click", handleCloseCalendar)
        }
    }, [isDatePickerOpen])

    const handleOpenCalendar = () => setIsDatePickerOpen(true)

    const handleCloseCalendar = event => {
        event.stopPropagation()
        const isClickedDateBar = calendarWindow.current && !calendarWindow.current.contains(event.target)
        const isClickedDay = event.target.classList.contains("rdrDayNumber")

        if (isClickedDateBar || isClickedDay) {
            setIsDatePickerOpen(false)
        }
    }

    const preview = date ? moment(date).format(READABLE_STANDARD_DATE) : placeholder
    const isValue = !!date
    const formattedError = Array.isArray(error)
        ? error.map((er, index, arr) =>
              index < arr.length ? (
                  <Fragment>
                      {er}
                      <br />
                  </Fragment>
              ) : (
                  er
              )
          )
        : error

    return (
        <div>
            <div
                className={cx("root", {
                    onTop: calendarAboveInput,
                    onRight: calendarOnRight,
                    hasPlaceholder: !date,
                    isValue,
                    error: !!error
                })}
                onClick={handleOpenCalendar}
            >
                {(!noDatePlaceholder || date) && (
                    <Fragment>
                        {label && <div className={cx("label")}>{label}</div>}
                        <div className={cx("datepicker-placeholder")}>
                            {preview}
                            <ReactSVG src={calendar} className={cx("logo")} />
                            {!disableClear && date && date.length > 0 && (
                                <ReactSVG
                                    src={isClearInLabel ? closeBlue : close}
                                    className={cx("clear", { isClearInLabel })}
                                    onClick={event => {
                                        handleSelectDate(null)
                                        handleCloseCalendar(event)
                                    }}
                                />
                            )}
                        </div>
                    </Fragment>
                )}
                {!date && noDatePlaceholder && (
                    <div className={cx("no-date-placeholder")}>
                        <ReactSVG src={calendar} className={cx("logo")} />
                        <p>{noDatePlaceholder}</p>
                    </div>
                )}
                {isDatePickerOpen && (
                    <span className={cx("calendar")} ref={calendarWindow} onClick={handleCloseCalendar}>
                        <Calendar
                            date={date ? moment(date).toDate() : null}
                            onChange={date => handleSelectDate(moment(date).format(API_DATE_FORMAT))}
                            maxDate={maxDate}
                            minDate={minDate}
                        />
                    </span>
                )}
            </div>
            {error && <p className={cx("error")}>{formattedError}</p>}
        </div>
    )
}

DatePicker.defaultProps = {
    calendarAboveInput: false,
    disableClear: false,
    placeholder: "- Please select -"
}

DatePicker.propTypes = {
    disableClear: PropTypes.bool,
    calendarAboveInput: PropTypes.bool,
    isClearInLabel: PropTypes.bool,
    calendarOnRight: PropTypes.bool,
    date: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    noDatePlaceholder: PropTypes.string,
    minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    handleSelectDate: PropTypes.func
}

export default withStyles(DatePicker, style)
