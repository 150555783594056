import memoize from "fast-memoize"
import idx from "idx"

export const shouldFetch = state => {
    const fetchStatus = idx(state, _ => _.fetchStatus)

    if (!fetchStatus) {
        return true
    }

    if (fetchStatus.isError) {
        return false
    }

    if (fetchStatus.isLoading) {
        return false
    }

    return !fetchStatus.isLoaded
}

export const shouldRefresh = ({ fetchStatus }) =>
    fetchStatus.isLoaded && fetchStatus.lastFetch && (new Date().getTime() - fetchStatus.lastFetch) / 1000 > 180

export const fetchStatus = memoize((...resources) => {
    const isLoading = resources.reduce((acc, resource) => acc || resource.fetchStatus.isLoading, false)

    const isError = resources.reduce((acc, resource) => acc || resource.fetchStatus.isError, false)

    const isLoaded =
        !isLoading && !isError && resources.reduce((acc, resource) => acc && resource.fetchStatus.isLoaded, true)

    return { isLoading, isLoaded, isError }
})

export const fetchStatusFromResources = memoize((...resources) => {
    const isLoading = resources.reduce(
        (acc, resource) => acc || (resource.fetchStatus ? resource.fetchStatus.isLoading : false),
        false
    )

    const isError = resources.reduce(
        (acc, resource) => acc || (resource.fetchStatus ? resource.fetchStatus.isError : true),
        false
    )

    const isLoaded =
        !isLoading &&
        !isError &&
        resources.reduce((acc, resource) => acc && (resource.fetchStatus ? resource.fetchStatus.isLoaded : false), true)

    return { isLoading, isLoaded, isError }
})

export const fromResources = (...resources) => fetchStatusFromResources(...resources)

export const isResourceLoaded = (...resources) => {
    const fetchStatus = fromResources(...resources)

    return fetchStatus.isLoaded
}

export const httpStatus = resource =>
    resource.fetchStatus && resource.fetchStatus.response && resource.fetchStatus.response.status
        ? resource.fetchStatus.response.status
        : null

export const isResourceNotInit = (...resources) => {
    const fetchStatus = fromResources(...resources)

    return !fetchStatus.isLoading && !fetchStatus.isLoaded && !fetchStatus.isError
}

export const initialState = () => ({
    isLoading: false,
    isLoaded: false,
    isError: false,
    error: null,
    response: null,
    lastFetch: null
})

export const requestState = () => ({
    isLoading: true,
    isLoaded: false,
    isError: false,
    error: null,
    response: null,
    lastFetch: new Date().getTime()
})

export const successState = action => ({
    isLoading: false,
    isLoaded: true,
    isError: false,
    error: null,
    response:
        action && action.response
            ? {
                  status: action.response.status,
                  statusText: action.response.statusText
              }
            : null,
    lastFetch: new Date().getTime()
})

export const failureState = action => {
    return {
        isLoading: false,
        isLoaded: false,
        isError: true,
        error: null,
        response:
            action && action.response
                ? {
                      status: action.response.status,
                      statusText: action.response.statusText
                  }
                : null,
        lastFetch: new Date().getTime()
    }
}
