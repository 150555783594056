export const createLinkToReturnForm = ({ id, uuid, stockItems, orderItemsUnavailableForReturn }) => ({
    pathname: "/orders/returns/add",
    state: { id, uuid, stockItems, orderItemsUnavailableForReturn }
})

export const createLinkToReturnDetailsInSO = ({ orderId, shipmentId }) => ({
    pathname: `/orders/sales-orders/${orderId}/returns`,
    state: {
        shipmentId
    }
})
