import React from "react"
import Summary from "./Summary"

const SummaryContainer = props => {
    const {
        project: { calculations, budget }
    } = props

    return <Summary calculations={calculations} budget={budget} />
}

export default SummaryContainer
