import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import idx from "idx"

import withStyles from "HOC/withStyles"
import { Button, Toggle } from "ui"
import { MoreFilters } from "ui/FilterableTable"

import styles from "./styles.css"
import stylesSelect from "./overrides/Select.css"
import stylesButtonCancel from "./overrides/ButtonCancel.css"
import stylesButtonSubmit from "./overrides/ButtonSubmit.css"
import stylesToggle from "./overrides/Toggle.css"

import { SelectBrand, SelectUser } from "ui/Filters"

const Filters = props => {
    const { cx, clearAllFilters, moreFilters, setMoreFilters } = props

    const users = useSelector(state => state.userStore.users)

    const loginStore = useSelector(state => state.loginStore)

    const [isVisible, setIsVisible] = useState(false)
    const [localMoreFilters, setLocalMoreFilters] = useState(moreFilters)
    const setFilter = useCallback((name, value) => {
        setLocalMoreFilters(state => ({ ...state, [name]: value || null }), [setLocalMoreFilters])
    })

    const handleSelect = ({ target: { name, value } }) => setFilter(name, value)

    useEffect(() => {
        if (!isVisible) {
            setLocalMoreFilters(moreFilters)
        }
    }, [isVisible, moreFilters])

    const toggleLabel = "Only my lightbox"
    const countSelectedFilters = filters =>
        Object.keys(filters).reduce(
            (acc, key) => acc + (filters[key] && Object.keys(filters[key]).length > 0 ? 1 : 0),
            0
        )

    return (
        <MoreFilters
            seamlessButtonTransition
            numberOfSelectedFilters={countSelectedFilters(moreFilters)}
            isVisible={isVisible}
            toggleVisible={() => setIsVisible(visible => !visible)}
        >
            <div className={cx("root")}>
                <div className={cx("filters")}>
                    <div className={cx("column")}>
                        <SelectUser
                            customStyles={stylesSelect}
                            handleSelect={handleSelect}
                            label="Assigned to"
                            name="user"
                            value={idx(localMoreFilters, _ => _.user.label)}
                            multiselect={false}
                        />
                        <Toggle
                            isOn={idx(localMoreFilters, _ => _.user.id) === Number(loginStore.userId)}
                            label={{ on: toggleLabel, off: toggleLabel }}
                            labelPosition="left"
                            handleChange={isOn => {
                                const user = users && users.find(user => user.id === Number(loginStore.userId))
                                if (user) {
                                    setFilter(
                                        "user",
                                        isOn
                                            ? {
                                                  id: user.id,
                                                  value: user,
                                                  label: `${user.first_name} ${user.last_name}`,
                                                  secondLabel: ""
                                              }
                                            : null
                                    )
                                }
                            }}
                            customStyles={stylesToggle}
                        />
                    </div>
                    <div className={cx("column")}>
                        <SelectBrand
                            label="Vendor"
                            name="brand"
                            value={idx(localMoreFilters, _ => _.brand.label)}
                            handleSelect={handleSelect}
                            customStyles={stylesSelect}
                            multiselect={false}
                        />
                    </div>
                    <div className={cx("column")}></div>
                    <div className={cx("column")}></div>
                </div>
                <div className={cx("actions")}>
                    <Button
                        label="Clear all filters"
                        onClick={() => {
                            clearAllFilters()
                            setIsVisible(false)
                        }}
                        customStyles={stylesButtonCancel}
                    />
                    <Button
                        label="Show results"
                        onClick={() => {
                            setMoreFilters(localMoreFilters)
                            setIsVisible(false)
                        }}
                        className="first-button"
                        customStyles={stylesButtonSubmit}
                    />
                </div>
            </div>
        </MoreFilters>
    )
}

export default withStyles(Filters, styles)
