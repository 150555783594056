import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import style from "./ConfirmWithSubject.css"
import close from "assets/close.svg"

class ConfirmWithSubject extends Component {
    static defaultProps = {
        actionButtonLabel: "Delete"
    }

    render() {
        const {
            cx,
            id,
            title,
            description,
            subject,
            descriptionClosure,
            actionButtonLabel,
            isCloseIcon,
            handleHideModal,
            handleDelete,
            isLoading
        } = this.props

        return (
            <div className="modal-background">
                <div className={cx("modal-cover")}>
                    {isCloseIcon && (
                        <img src={close} alt="close" className={cx("close-icon")} onClick={handleHideModal} />
                    )}
                    <h1 className={cx("title")}>{title}</h1>
                    <p className={cx("description")}>
                        {description}
                        {subject && this.renderSubject()}
                        {descriptionClosure}
                    </p>
                    <div className={cx("buttons")}>
                        <button
                            className={cx("first-button", "action", { isLoading })}
                            onClick={handleDelete && handleDelete.bind(this, id)}
                            disabled={isLoading}
                        >
                            {!isLoading && actionButtonLabel}
                        </button>
                        <button className={cx("go-back-button")} onClick={handleHideModal}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    renderSubject = () => {
        const { subject, withQuotes = true } = this.props
        const isSubjectArray = Array.isArray(subject)

        return (
            <span>
                {" "}
                {withQuotes && '"'}
                {isSubjectArray ? subject.join('", "') : subject}
                {withQuotes && '"'}{" "}
            </span>
        )
    }
}

export default withStyles(ConfirmWithSubject, style)
