import * as fetchStatus from "helpers/fetchStatus"
import * as constants from "constants/index"

const initialState = {
    data: {},
    fetchStatus: fetchStatus.initialState()
}

function current(state = initialState, action) {
    if (constants.GET_PROJECT_ROOMS_REQUEST === action.type) {
        return {
            ...initialState,
            fetchStatus: fetchStatus.requestState(),
            roomsFetchStatus: fetchStatus.requestState()
        }
    }

    if (constants.GET_PROJECT_ROOMS_SUCCESS === action.type) {
        return {
            data: action.payload.data.data,
            fetchStatus: fetchStatus.successState()
        }
    }

    if (constants.GET_PROJECT_ROOMS_FAILURE === action.type) {
        return {
            data: {},
            fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
        }
    }

    return state
}

export default current
