import React from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"

import { Button, Modal } from "@butterfly-frontend/ui"
import { Textarea } from "ui"
import { useStockItemEdit } from "modules/WmsModule/hooks/api/useStockItem"

import styles from "./StockItemNoteModal.module.css"

const StockItemNoteModal = ({ stockItemId, stockItemNote = "", onClose, onSuccess }) => {
    const handleSuccess = ({ data }) => onSuccess(data)
    const stockItemEdit = useStockItemEdit()

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            note: stockItemNote
        },
        onSubmit: values => stockItemEdit.mutate({ id: stockItemId, data: { note: values.note } }).then(handleSuccess)
    })

    return (
        <Modal title={<h1 className={styles.header}>Add note</h1>} onClose={onClose}>
            <form className={styles.form} onSubmit={formik.handleSubmit}>
                <div className={styles.noteSection}>
                    <Textarea
                        label="Note on item"
                        className={styles.textarea}
                        name="note"
                        value={formik.values.note}
                        error={formik.errors.note}
                        onChange={({ event }) => formik.handleChange(event)}
                    />
                </div>
                <div className={styles.buttonSection}>
                    <Button variant="flat" onClick={onClose} disabled={formik.isSubmitting}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        color="blue"
                        isLoading={formik.isSubmitting}
                        classes={{ button: styles.submitButton }}
                    >
                        Send
                    </Button>
                </div>
            </form>
        </Modal>
    )
}

StockItemNoteModal.defaultProps = {
    onSuccess: () => {}
}

StockItemNoteModal.propTypes = {
    stockItemId: PropTypes.number.isRequired,
    stockItemNote: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func
}

export default StockItemNoteModal
