import { combineReducers } from "redux"

import products from "./products"
import current from "./current"
import chooseSelectionFlow from "./chooseSelectionFlow"

const selectionsWidgetReducer = combineReducers({
    current,
    products,
    chooseSelectionFlow
})

export default selectionsWidgetReducer
