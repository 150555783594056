import React, { useState, useEffect, useMemo, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import debounce from "lodash.debounce"

import env from "env"
import fetch from "helpers/fetch"
import { DEBOUNCE_DRAG_AND_DROP_REQUEST, ITEMS_PER_PAGE } from "constants/index"

import CollectionList from "./CollectionList"
import ActivateDeleteModal from "./ActivateDeleteModal"
import { ChangeLayoutInspirationsModal } from "modules/ContentModule/components"

import { useActions, useFetch } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

const CollectionListContainer = props => {
    const { collectionLabel, collectionLabelPlural, basePath, apiUrl, collectionModel, canChangeLayout } = props

    const [list, setList] = useState([])
    const [listMeta, setListMeta] = useState({
        current_page: 1,
        last_page: 1,
        total: 0
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false)

    const history = useHistory()
    const actions = useActions({ showSuccessNotification, showErrorNotification })

    const initialModalState = { collectionId: null, type: null }
    const [modalState, setModalState] = useState(initialModalState)

    const getList = async page => {
        setIsLoading(true)

        try {
            const result = await fetch.get(
                `${apiUrl}?length=${ITEMS_PER_PAGE}&page=${page || listMeta.current_page + 1}`
            )
            setList(page === 1 ? result.data : [...list, ...result.data])
            setListMeta(result.meta)
        } catch (error) {
            actions.showErrorNotification()
        }

        setIsLoading(false)
    }

    const debouncedUpdateListOrder = useRef(
        debounce(items => {
            setIsUpdatingOrder(true)
            fetch
                .postRAW("/multi-actions", {
                    ids: items.map(item => item.id),
                    model: collectionModel,
                    action: "sort"
                })
                .then(() => actions.showSuccessNotification())
                .catch(() => actions.showErrorNotification())
                .finally(() => setIsUpdatingOrder(false))
        }, DEBOUNCE_DRAG_AND_DROP_REQUEST)
    ).current

    useEffect(() => {
        getList(1)
    }, [])

    const onCreateCollection = () => history.push(`${basePath}/add`)
    const onEditCollection = collectionId => history.push(`${basePath}/${collectionId}/edit`)
    const onPreviewCollection = (model, id, path) =>
        fetch
            .get(`/preview?model=${model}&id=${id}`)
            .then(data => {
                if (data.data.preview_token) {
                    window.open(env.API.webUrl + "/" + path + "?preview_token=" + data.data.preview_token, "_blank")
                }
            })
            .catch(() => actions.showErrorNotification())
    const openModal = (collectionId, type) => setModalState({ collectionId, type })
    const closeModal = () => setModalState(initialModalState)

    const [submitModal, { isLoading: isModalLoading }] = useFetch({
        action: submitMethod => submitMethod(),
        onSuccess: () => actions.showSuccessNotification(modalState.type === modalTypes.delete && { title: "Deleted" }),
        onError: () => actions.showErrorNotification(),
        onFinish: closeModal
    })

    const lowerCaseLabel = collectionLabel.toLowerCase()
    const modalCollection = useMemo(() => list.find(({ id }) => id === modalState.collectionId) || {}, [
        modalState.collectionId,
        list
    ])

    const modalMethods = {
        activate: async () => {
            try {
                const body = { ...modalCollection, active: !modalCollection.active, thumbnail: undefined }
                const result = await fetch.patch(`${apiUrl}/${modalState.collectionId}`, body)

                setList(
                    list.map(collection =>
                        collection.id === result.data.id
                            ? {
                                  ...collection,
                                  active: result.data.active
                              }
                            : collection
                    )
                )
                actions.showSuccessNotification()
            } catch (error) {
                actions.showErrorNotification()
            }
        },
        delete: async () => {
            try {
                await fetch.deleteRAW(`${apiUrl}/${modalState.collectionId}`)

                setList(list.filter(collection => collection.id !== modalState.collectionId))
                actions.showSuccessNotification()
            } catch (error) {
                actions.showErrorNotification()
            }
        },
        changeLayout: () => {}
    }
    const modalTypes = Object.keys(modalMethods).reduce((acc, key) => ({ ...acc, [key]: key }), {})

    const onReorderCollection = items => {
        setList(items)
        debouncedUpdateListOrder(items)
    }

    return (
        <Fragment>
            <CollectionList
                isLoading={isLoading}
                isUpdatingOrder={isUpdatingOrder}
                collectionLabel={collectionLabel}
                collectionLabelPlural={collectionLabelPlural}
                collectionModel={collectionModel}
                lowerCaseLabel={lowerCaseLabel}
                canChangeLayout={canChangeLayout}
                basePath={basePath}
                list={list}
                listMeta={listMeta}
                getList={getList}
                toggleCollection={id => openModal(id, modalTypes.activate)}
                onCreateCollection={onCreateCollection}
                onEditCollection={onEditCollection}
                onDeleteCollection={id => openModal(id, modalTypes.delete)}
                onPreviewCollection={onPreviewCollection}
                onChangeLayout={() => openModal(null, modalTypes.changeLayout)}
                onReorderCollection={onReorderCollection}
            />
            {(modalState.type === "activate" || modalState.type === "delete") && (
                <ActivateDeleteModal
                    modalType={modalState.type}
                    isLoading={isModalLoading}
                    isCollectionActive={modalCollection.active}
                    collectoinTitle={modalCollection.title}
                    lowerCaseCollectionTypeLabel={lowerCaseLabel}
                    onClose={closeModal}
                    onSubmit={() => submitModal(modalMethods[modalState.type])}
                />
            )}
            {modalState.type === "changeLayout" && (
                <ChangeLayoutInspirationsModal
                    collectionLabel={collectionLabel}
                    collectionKey={lowerCaseLabel}
                    isOpen
                    handleCloseModal={closeModal}
                />
            )}
        </Fragment>
    )
}

CollectionListContainer.propTypes = {
    collectionLabel: PropTypes.string.isRequired,
    collectionLabelPlural: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    apiUrl: PropTypes.string.isRequired,
    canChangeLayout: PropTypes.bool,
    collectionModel: PropTypes.string.isRequired
}

export default CollectionListContainer
