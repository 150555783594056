import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import fetch from "helpers/fetch"

import * as channelActions from "actions/channel"

import { EditableList } from "ui"

import ListingLayout from "../../OtherListingLayout/index"

const Listing = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const getChannels = () => dispatch(channelActions.getChannels())

    const channels = useSelector(state => state.db.channels.list.data)
    const isLoading = useSelector(state => state.db.channels.list.isLoading)

    useEffect(() => {
        getChannels()
    }, [])

    const handleAdd = () => {
        history.push("/settings/channels/add")
    }

    const handleEdit = item => {
        history.push(`/settings/channels/card/${item.id}/edit`)
    }

    const handleDelete = item => {
        fetch.deleteRAW(`/communication-channels/${item.id}`).then(() => getChannels())
    }

    const columns = [
        {
            name: "name",
            field: "name"
        }
    ]

    return (
        <ListingLayout>
            <EditableList
                title="Preferred communication channels"
                isLoading={isLoading}
                columns={columns}
                data={channels}
                dataModel="CommunicationChannels"
                fetchData={getChannels}
                isIndexColumn
                onAdd={handleAdd}
                onEdit={handleEdit}
                onDelete={handleDelete}
                confirmModalTitle="Are you sure you want to delete this channel?"
            />
        </ListingLayout>
    )
}

export default Listing
