import React, { useMemo, useCallback, Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import {
    TableRowWithExpandedDetails,
    TableCell,
    Button,
    DeleteIcon,
    CloudIcon,
    ImageTile,
    ActionsDropList
} from "@butterfly-frontend/ui"

import { StatusSelectProductConditions } from "ui/Filters/SelectProductConditions"
import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"
import { StockItemDetailsBoxList, IncrementalCounter, PrintBoxLabels } from "modules/WmsModule/components"
import { STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS } from "modules/WmsModule/components/StockItemDetailsBoxList"
import { SHIPMENT_PACK_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"
import { mapItemConditionToSelect } from "modules/WmsModule/pages/ReceivingDetailsPage/helpers"
import TABLE_COLUMNS from "modules/WmsModule/pages/ReceivingDetailsPage/constants/tableColumns"
import formikPropTypesFactory from "modules/WmsModule/helpers/formikPropTypesFactory"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import styles from "./ReturnPackageTableRow.module.css"

const CUSTOM_STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS = {
    ...STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS,
    NAME: {
        ...STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS.NAME,
        width: "22%",
        minWidth: 100,
        classes: {
            root: styles.boxesNameCell
        }
    },
    LABEL: {
        ...STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS.LABEL,
        width: "28%",
        compactWidth: "53%"
    }
}

const ReturnPackageTableRow = props => {
    const { index, item, formik, canEdit } = props
    const {
        id: stockItemId,
        boxes,
        condition: { images }
    } = item

    const isMarkedAsReadyToReturn = useMemo(() => formik.values.ids.includes(stockItemId), [
        formik.values.ids,
        stockItemId
    ])

    const toggleMarkedAsReadyToReturn = useCallback(() => {
        const idsToSet = isMarkedAsReadyToReturn
            ? formik.values.ids.filter(id => id !== stockItemId)
            : [...formik.values.ids, stockItemId]

        formik.setFieldValue("ids", idsToSet)
    }, [formik.values.ids, isMarkedAsReadyToReturn])

    const { options: boxOptions, isLoading: isBoxOptionsLoading } = useBoxLabelOptions({
        ids: [item.id]
    })

    return (
        <Fragment>
            <TableRowWithExpandedDetails
                classes={{
                    root: classNames(styles.rowRoot),
                    detailsComponent: styles.rowDetails
                }}
                detailsComponent={
                    <Fragment>
                        {images.length > 0 && (
                            <div className={classNames(styles.conditionImageList)}>
                                {images.map(image => (
                                    <ImageTile
                                        key={image.id}
                                        image={image}
                                        classes={{ imageBox: styles.conditionImage }}
                                    />
                                ))}
                            </div>
                        )}

                        {boxes.length > 0 && (
                            <div className={styles.boxes}>
                                <StockItemDetailsBoxList
                                    boxes={item.boxes}
                                    customColumns={CUSTOM_STOCK_ITEM_DETAILS_BOX_LIST_TABLE_COLUMNS}
                                    compactView
                                />
                                <div className={styles.boxesPrintLabels}>
                                    <PrintBoxLabels boxes={boxes} stockItemId={item.id} />
                                </div>
                            </div>
                        )}
                    </Fragment>
                }
                isExpanded={boxes.length > 0 || images.length > 0}
            >
                <TableCell {...TABLE_COLUMNS.no} classes={{ root: styles.cell }}>
                    {index + 1}
                </TableCell>
                <TableCell {...TABLE_COLUMNS.product} classes={{ root: styles.cell }}>
                    <div className={styles.productName}>{item.name}</div>
                </TableCell>
                <TableCell {...TABLE_COLUMNS.id} classes={{ root: styles.cell }}>
                    {item.id}
                </TableCell>
                <TableCell {...TABLE_COLUMNS.condition} classes={{ root: styles.conditionCell }}>
                    <StatusSelectProductConditions
                        excludedTypes={[CONDITION_TYPES.REMOVED, CONDITION_TYPES.INCOMPLETE]}
                        value={mapItemConditionToSelect(item.condition)}
                        disabled
                    />
                </TableCell>
                <TableCell {...TABLE_COLUMNS.packAction} classes={{ root: styles.packActionCell }}>
                    <Button variant="flat" size="small" color="red" Icon={CloudIcon} disabled>
                        Add photos
                    </Button>
                    <div className={styles.buttonDivider} />
                    <IncrementalCounter quantity={boxes.length} label="Box: " isDisabled />
                    <div className={styles.buttonDivider} />
                    <Button
                        size="small"
                        iconPosition="right"
                        Icon={DeleteIcon}
                        disabled={formik.isSubmitting || !canEdit()}
                        isLoading={formik.isSubmitting}
                        color={isMarkedAsReadyToReturn ? "red" : "white"}
                        onClick={toggleMarkedAsReadyToReturn}
                    >
                        {isMarkedAsReadyToReturn ? "Returned" : "Return"}
                    </Button>
                    <div className={styles.buttonDivider} />
                    <ActionsDropList
                        actions={boxOptions}
                        isLoading={isBoxOptionsLoading}
                        classes={{ dots: styles.threeDots }}
                    />
                </TableCell>
            </TableRowWithExpandedDetails>
        </Fragment>
    )
}

ReturnPackageTableRow.propTypes = {
    index: PropTypes.number.isRequired,
    item: SHIPMENT_PACK_ITEM_PROP_TYPE,
    formik: formikPropTypesFactory({
        ids: PropTypes.arrayOf(PropTypes.number).isRequired
    }).isRequired,
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(ReturnPackageTableRow, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
