import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import { Link } from "react-router-dom"
import moment from "moment"
import style from "./ClientCardProjects.css"
import headerStyles from "./overrides/Header.css"
import contentStyles from "./overrides/Content.css"
import tableStyles from "./overrides/Table.css"
import FilterableTable, { Table, Content, Header } from "ui/FilterableTable"

class ClientCardProjects extends Component {
    render() {
        const { cx, list } = this.props

        return (
            list.length > 0 && (
                <div className={cx("root")}>
                    <FilterableTable fetchStatus={{}} items={list}>
                        <Header name="Projects" customStyles={headerStyles}>
                            {list.length}
                        </Header>
                        <Content customStyles={contentStyles}>
                            <Table
                                renderHeader={this.renderTableHeader}
                                renderBody={this.renderTableBody}
                                customStyles={tableStyles}
                            />
                        </Content>
                    </FilterableTable>
                </div>
            )
        )
    }

    renderTableHeader = () => {
        return (
            <Table.Head>
                <Table.Tr>
                    <Table.Th className="textLeft">Project Name</Table.Th>
                    <Table.Th className="textLeft">Products</Table.Th>
                    <Table.Th className="textLeft">Employee</Table.Th>
                    <Table.Th className="textLeft">Creation Date</Table.Th>
                </Table.Tr>
            </Table.Head>
        )
    }

    renderTableBody = items => {
        return (
            <Table.Body>
                {items.map((item, index) => (
                    <Table.Tr key={index}>
                        <Table.Td>
                            <Link to={"/projects/card/" + item.id}>{item.name}</Link>
                        </Table.Td>
                        <Table.Td>{item.products}</Table.Td>
                        <Table.Td>{item.employee}</Table.Td>
                        <Table.Td>{moment(item.created_at).format("DD MMM YYYY")}</Table.Td>
                    </Table.Tr>
                ))}
            </Table.Body>
        )
    }
}

export default withStyles(ClientCardProjects, style)
