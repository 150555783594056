import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import _get from "lodash/get"

import { SelectWithSearch, Textarea, SaveBar } from "ui"
import { SelectChangeReason } from "ui/Filters"
import { showErrorNotification } from "actions/notification"

import apiErrorsAdapter from "modules/WmsModule/helpers/apiErrorsAdapter"
import SelectedItemsList from "modules/WmsModule/pages/StockItemsChangeStatusPage/SelectedItemsList"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import { formatDatetimeToApi, addDaysFromNow } from "modules/WmsModule/helpers/date"
import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import STOCK_ITEM_STATUS_SETTER_OPTIONS from "modules/WmsModule/constants/stockItemStatusSetterOptions"
import ON_HOLD_FOR_SELECT_OPTIONS from "modules/WmsModule/constants/forSelectOptions"
import { useStockItemChangeStatus } from "modules/WmsModule/hooks/api/useStockItem"

import validationSchema from "./validationSchema"

import styles from "./ChangeItemsStatusForm.module.css"

const formValuesToApi = values => {
    const status = _get(values, "status.value")
    const onHoldForDays = _get(values, "for.value")
    const reason = _get(values, "reason.value")
    const valid_date =
        status === STOCK_ITEM_STATUS_KEYS.ON_HOLD && onHoldForDays
            ? formatDatetimeToApi(addDaysFromNow(onHoldForDays))
            : undefined

    return {
        ids: values.selectedItems.map(({ id }) => id),
        payload: {
            status,
            valid_date,
            reason,
            description: values.description
        }
    }
}

const ChangeItemsStatus = ({ onCancel, onSuccess, selectedItems }) => {
    const stockItemChangeStatus = useStockItemChangeStatus()

    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            selectedItems,
            status: null,
            for: null,
            reason: null,
            description: ""
        },
        validationSchema,
        onSubmit: (values, { setErrors }) =>
            stockItemChangeStatus
                .mutate({
                    data: formValuesToApi(values)
                })
                .then(onSuccess)
                .catch(error => {
                    const apiErrors = _get(error, "errors", null)
                    const errorMessage = _get(error, "message", null)
                    if (apiErrors) {
                        const errors = apiErrorsAdapter(apiErrors)
                        setErrors(errors)
                    }
                    dispatch(showErrorNotification(errorMessage))
                })
    })

    const setStatusValue = async value => {
        const selectedStatus = _get(value, "value")

        if (
            selectedStatus === STOCK_ITEM_STATUS_KEYS.IN_STOCK ||
            selectedStatus === STOCK_ITEM_STATUS_KEYS.NOT_FOR_SALE
        ) {
            formik.setFieldValue("for", null)
            formik.setFieldValue("reason", null)
        }

        await formik.setFieldValue("status", value)
        formik.setFieldTouched("status")
    }

    const getSelectProps = getSelectPropsFactory(formik)

    const isOnHoldStatusSelected = _get(formik, "values.status.value") === STOCK_ITEM_STATUS_KEYS.ON_HOLD

    useEffect(() => {
        if (formik.values.selectedItems.length === 0) {
            onCancel()
            return
        }
    }, [formik.values.selectedItems])

    const onRemoveStockItem = stockItemToRemove => {
        formik.setFieldValue(
            "selectedItems",
            formik.values.selectedItems.filter(({ id }) => id !== stockItemToRemove.id),
            false
        )
    }

    return (
        <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.selectSection}>
                <div className={styles.statusSelect}>
                    <SelectWithSearch
                        {...getSelectProps("status", {
                            setValue: setStatusValue
                        })}
                        label="Status"
                        values={STOCK_ITEM_STATUS_SETTER_OPTIONS}
                        isClearBlocked
                        withoutFetch
                    />
                </div>
                <div className={styles.forSelect}>
                    <SelectWithSearch
                        {...getSelectProps("for", {
                            isDisabled: !isOnHoldStatusSelected || formik.isSubmitting
                        })}
                        label="For"
                        values={ON_HOLD_FOR_SELECT_OPTIONS}
                        isClearBlocked
                        withoutFetch
                    />
                </div>
                <div className={styles.reasonSelect}>
                    <SelectChangeReason
                        {...getSelectProps("reason", {
                            isDisabled: !isOnHoldStatusSelected || formik.isSubmitting
                        })}
                        label="Reason"
                    />
                </div>
            </div>

            <SelectedItemsList selectedItems={formik.values.selectedItems} onRemoveItem={onRemoveStockItem} />

            <div className={styles.descriptionSection}>
                <Textarea
                    label="Description"
                    className={styles.textarea}
                    name="description"
                    placeholder="Click here to add a description..."
                    value={formik.values.description}
                    error={formik.errors.description}
                    onChange={({ event }) => formik.handleChange(event)}
                />
            </div>
            <SaveBar isShown isSaving={formik.isSubmitting} isSubmit submitLabel="Save" onCancel={onCancel} />
        </form>
    )
}

ChangeItemsStatus.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            product: PropTypes.shape({
                name: PropTypes.string
            }),
            condition: PropTypes.shape({
                name: PropTypes.string,
                color: PropTypes.string
            }),
            warehouse: PropTypes.shape({
                name: PropTypes.string
            }),
            status: PropTypes.string
        })
    ).isRequired
}

export default ChangeItemsStatus
