const SALES_ORDER_ITEM_STATUSES = {
    IN_STOCK: "in_stock",
    PARTIAL_ORDER: "partial_order",
    ORDER: "order",
    NEW_ORDER: "new_order",
    NOT_AVAILABLE: "not_available",
    INCOMING: "incoming"
}

export default SALES_ORDER_ITEM_STATUSES
