import React from "react"

import withStyles from "HOC/withStyles"
import { Radio } from "ui"

import styles from "./RadioItem.css"
import stylesRadio from "./overrides/Radio.css"

const RadioItem = withStyles(props => {
    const { cx, name, label, value, checked, handleChange } = props

    return (
        <div className={cx("item")}>
            <Radio
                name={name}
                label={label}
                checked={checked}
                value={value}
                handleSelect={(name, value) => handleChange({ name, value })}
                customStyles={stylesRadio}
            />
        </div>
    )
}, styles)

export default withStyles(RadioItem, styles)
