import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"

import styles from "./Toggle.css"

const Toggle = props => {
    const { cx, isOn, label, labelPosition, handleChange, isDisabled, isSmall = false } = props

    const handleToggle = () => handleChange(!isOn)

    return (
        <div className={cx("root", { on: isOn }, labelPosition)}>
            {labelPosition === "left" && renderLabel()}
            <span className={cx("toggle", { small: isSmall })} onClick={isDisabled ? () => {} : handleToggle} />
            {labelPosition === "right" && renderLabel()}
        </div>
    )

    function renderLabel() {
        return <span className={cx("label", { smallLabel: isSmall })}>{isOn ? label.on : label.off}</span>
    }
}

Toggle.defaultProps = {
    label: {
        on: "on",
        off: "off"
    },
    labelPosition: "right"
}

Toggle.propTypes = {
    cx: PropTypes.func.isRequired,
    isOn: PropTypes.bool.isRequired,
    label: PropTypes.shape({
        on: PropTypes.string,
        off: PropTypes.string
    }),
    labelPosition: PropTypes.oneOf(["left", "right"]),
    handleChange: PropTypes.func.isRequired,
    isSmall: PropTypes.bool,
    isDisabled: PropTypes.bool
}

export default withStyles(Toggle, styles)
