import _get from "lodash/get"
import _omitBy from "lodash/omitBy"

export const mapFiltersToApi = ({
    type,
    brand,
    category,
    subCategory,
    inStock,
    location,
    manualEntries: manual_entries,
    dateRange
}) =>
    _omitBy(
        {
            type,
            manual_entries,
            brands: _get(brand, "id"),
            categories: _get(category, "id"),
            subCategory: _get(subCategory, "id"),
            locations: _get(location, "id"),
            availability_status: _get(inStock, "value"),
            created_from: _get(dateRange, "startDate"),
            created_to: _get(dateRange, "endDate")
        },
        filter => filter === undefined
    )
