import React, { Component } from "react"
import * as actions from "actions/feature"
import { connect } from "react-redux"
import withCrud from "HOC/withCrud"

import Form from "./Form/index"
import Listing from "./Listing/index"

const Container = withCrud({
    routePrefix: "/settings/features/:parentId/values",
    idParamName: "valueId",
    Form,
    Listing
})

class AttributeValues extends Component {
    componentDidMount() {
        this.getParentData(this.props)
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { match } = nextProps

        if (match && match.params && match.params.parentId !== this.props.match.params.parentId) {
            return this.getParentData(nextProps)
        }

        if (match && match.params && match.isExact !== this.props.match.isExact) {
            return this.getParentData(nextProps)
        }
    }

    render() {
        return <Container />
    }

    getParentData(props) {
        const { match, getParentData } = props

        if (match && match.params && match.params.parentId) {
            getParentData(match.params.parentId)
        }
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        getParentData: id => dispatch(actions.getFeatureById(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AttributeValues)
