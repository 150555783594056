import React, { useState } from "react"
import PropTypes from "prop-types"
import { useField, useFormikContext, getIn } from "formik"
import { Button, DeleteIcon, AddIcon } from "@butterfly-frontend/ui"

import { WarningBar, Input } from "ui"
import AddProductModal from "components/Modal/ChooseProductFromExistingOrder"
import { SelectDiscountSection } from "modules/OrdersModule/components"
import { DISCOUNT_LIST_PROP_TYPE } from "modules/OrdersModule/propTypes"

import styles from "./ItemsTab.module.css"

const ItemsTab = props => {
    const { availableItemList, discountBrandList, isConfirmed, isSubmitting } = props

    const [itemsField] = useField("items")
    const [discountField] = useField("discount")
    const { handleChange, handleBlur, touched, errors } = useFormikContext()
    const [isOpenChooseItemModal, setIsOpenChooseItemModal] = useState(false)

    const handleDeleteItem = id => {
        const newItems = itemsField.value.filter(item => item.id !== id)
        itemsField.onChange({ target: { ...itemsField, value: newItems } })
    }

    const handleSelectItem = item => {
        itemsField.onChange({
            target: {
                ...itemsField,
                value: [...itemsField.value, item]
            }
        })
        setIsOpenChooseItemModal(false)
    }

    return (
        <div className={styles.root}>
            <SelectDiscountSection
                selectedDiscount={discountField.value}
                discountList={discountBrandList}
                onChange={value =>
                    discountField.onChange({ target: { ...discountField, value: value.value ? value : null } })
                }
                isDisabled={isSubmitting}
            />
            {itemsField.value.length > 0 ? (
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>item name</th>
                            <th>item wholesale price</th>
                            <th>qty</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {itemsField.value.map(({ id, name, attributes, quantity, wholesale_cost }, index) => {
                            const wholesaleCostInputKey = `items[${index}].wholesale_cost`

                            return (
                                <tr key={id}>
                                    <td>{index + 1}.</td>
                                    <td>
                                        <div className={styles.name}>{name || "-"}</div>
                                        {!!attributes && (
                                            <div className={styles.attributes}>
                                                {Object.entries(attributes).map(([key, value], attrIndex) => (
                                                    <div key={attrIndex} className={styles.attribute}>
                                                        <span>{key}:</span> {value}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <div className={styles.wholesalePriceInput}>
                                            <Input
                                                name={wholesaleCostInputKey}
                                                value={wholesale_cost}
                                                onChange={({ event }) => handleChange(event)}
                                                error={
                                                    getIn(touched, wholesaleCostInputKey) &&
                                                    getIn(errors, wholesaleCostInputKey)
                                                }
                                                onBlur={handleBlur}
                                                isDisabled={isConfirmed || isSubmitting}
                                                min={0}
                                                type="number"
                                            />
                                        </div>
                                    </td>
                                    <td>{quantity || 0}</td>
                                    <td>
                                        <Button
                                            variant="flat"
                                            onClick={() => handleDeleteItem(id)}
                                            Icon={DeleteIcon}
                                            disabled={isConfirmed || isSubmitting}
                                        >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            ) : (
                <div className={styles.warningBarWrapper}>
                    <WarningBar
                        text="This purchase order is currently empty. Add one or more items to this purchase order, and they will
                    be shown here."
                    />
                </div>
            )}
            <Button
                variant="flat"
                Icon={AddIcon}
                onClick={() => setIsOpenChooseItemModal(true)}
                disabled={isConfirmed || isSubmitting || availableItemList.length === 0}
            >
                Add item
            </Button>
            <AddProductModal
                isOpen={isOpenChooseItemModal}
                orderItems={availableItemList}
                handleClose={() => setIsOpenChooseItemModal(false)}
                handleSelect={handleSelectItem}
            />
        </div>
    )
}

ItemsTab.propTypes = {
    isConfirmed: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    availableItemList: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string,
            fake_name: PropTypes.string.isRequired,
            attributes: PropTypes.object,
            name: PropTypes.string,
            brand_name: PropTypes.string
        }).isRequired
    ).isRequired,
    discountBrandList: DISCOUNT_LIST_PROP_TYPE.isRequired
}

export default ItemsTab
