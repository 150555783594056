import React, { useState, useMemo } from "react"
import ListFiltersContext, { INITIAL_FILTERS } from "./ListFiltersContext"

const ListFiltersContextProvider = props => {
    const [filters, setFilters] = useState(INITIAL_FILTERS)

    const value = useMemo(
        () => ({
            values: filters,
            set: setFilters
        }),
        [filters]
    )

    return <ListFiltersContext.Provider value={value}>{props.children}</ListFiltersContext.Provider>
}

export default ListFiltersContextProvider
