import React, { Component, Fragment } from "react"
import withStyles from "HOC/withStyles"
import style from "./Notes.css"
import Note from "./Note/Note"
import Modal from "components/Modal/Modal"
import AddNoteCover from "components/Modal/AddNoteCover/AddNoteCover"
import Pagination from "components/Reusable/Pagination/index"
import pencil from "assets/pencil.svg"

class ClientCardNotes extends Component {
    perPage = 10

    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            currentPage: 0
        }

        this.handleShow = this.handleShow.bind(this)
        this.handleHide = this.handleHide.bind(this)
    }

    handleShow() {
        this.setState({ showModal: true })
    }

    handleHide() {
        this.setState({ showModal: false })
    }

    render() {
        const { cx, deleteNote, contextId, notes, addNoteParams, isLoading } = this.props
        const { currentPage, showModal } = this.state

        return (
            <Fragment>
                {showModal && (
                    <Modal>
                        <AddNoteCover
                            handleHide={this.handleHide}
                            type="product"
                            {...addNoteParams}
                            hasAttachments={false}
                        />
                    </Modal>
                )}
                <div className={cx({ isLoading: isLoading })}>
                    <div className={cx("notes-cover")}>
                        <div className={cx({ "notes-header": notes && notes.length })}>
                            <h3 className={cx("title")}>Notes</h3>
                            <div className={cx("edit-button")} onClick={this.handleShow}>
                                <img src={pencil} alt="add" className={cx("pencil")} />
                                <h3 className={cx("add-new")}>Add New</h3>
                            </div>
                        </div>
                        {notes &&
                            notes
                                .slice(currentPage * this.perPage, (currentPage + 1) * this.perPage)
                                .map(({ text, creator, id: noteId, created_at }, index) => (
                                    <Note
                                        key={index}
                                        text={text}
                                        creator={creator}
                                        noteId={noteId}
                                        contextId={contextId}
                                        created_at={created_at}
                                        deleteNote={deleteNote}
                                        removeable={true}
                                    />
                                ))}

                        <Pagination
                            handleSelectPage={this.handleSelectPage}
                            items={notes}
                            currentPage={currentPage}
                            perPage={this.perPage}
                        />
                    </div>
                </div>
            </Fragment>
        )
    }

    handleSelectPage = page => {
        this.setState({
            currentPage: page
        })
    }
}

export default withStyles(ClientCardNotes, style)
