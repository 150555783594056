import * as constants from "constants/index"
import fetch from "helpers/fetch"

export const getCombinationTemplatesStart = () => ({ type: constants.GET_COMBINATION_TEMPLATES_START })

export const getCombinationTemplatesSuccess = data => ({
    type: constants.GET_COMBINATION_TEMPLATES_SUCCESS,
    payload: { data }
})

export const getCombinationTemplatesError = error => ({
    type: constants.GET_COMBINATION_TEMPLATES_ERROR,
    payload: { error }
})

export const getCombinationTemplates = () => dispatch => {
    dispatch(getCombinationTemplatesStart())
    fetch
        .get("/combination-templates?length=9999")
        .then(data => dispatch(getCombinationTemplatesSuccess(data)))
        .catch(error => dispatch(getCombinationTemplatesError(error)))
}
