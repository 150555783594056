import React, { useState } from "react"
import { Formik, Form as FormikForm } from "formik"
import PropTypes from "prop-types"
import merge from "lodash/merge"

import SectionModalImageWithDescription from "./SectionModalImageWithDescription"
import {
    getInitialValues as getProductLinkInitialValues,
    getSaveValues as getProductLinkSaveValues
} from "../ShowProductLink/values"
import { section as sectionSchema } from "./schema"

const Container = ({ onClose, onSave, data }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = merge(data, getProductLinkInitialValues(data))

    const onSubmit = async (values, formikActions) => {
        try {
            setIsSubmitting(true)

            await onSave(
                merge(
                    values,
                    { description_image: values.description_image ? values.description_image.id : null },
                    getProductLinkSaveValues(values)
                )
            )

            onClose()
        } catch (error) {
            setIsSubmitting(false)
            formikActions.setErrors(error)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={sectionSchema}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {propsForm => (
                <FormikForm>
                    <SectionModalImageWithDescription onClose={onClose} form={propsForm} isSubmitting={isSubmitting} />
                </FormikForm>
            )}
        </Formik>
    )
}

Container.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default Container
