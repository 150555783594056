const ns = "selections/"

export const GET_BY_ID_START = ns + "GET_BY_ID_START"
export const GET_BY_ID_SUCCESS = ns + "GET_BY_ID_SUCCESS"
export const GET_BY_ID_ERROR = ns + "GET_BY_ID_ERROR"
export const RESET_CURRENT = ns + "RESET_CURRENT"

export const GET_START = ns + "GET_START"
export const GET_SUCCESS = ns + "GET_SUCCESS"
export const GET_ERROR = ns + "GET_ERROR"
export const RESET = ns + "RESET"

export const CLEAR_FILTERS_GROUP = ns + "CLEAR_FILTERS_GROUP"
export const REPLACE_FILTERS_GROUP = ns + "REPLACE_FILTERS_GROUP"

export const CLEAR_QUERY = ns + "CLEAR_QUERY"
export const CHANGE_QUERY = ns + "CHANGE_QUERY"

export const SET_SORT_TYPE = ns + "SET_SORT_TYPE"

export const CLEAR_PAGINATION = ns + "CLEAR_PAGINATION"
export const CHANGE_PAGINATION = ns + "CHANGE_PAGINATION"

export const CLEAR_TYPE = ns + "CLEAR_TYPE"
export const CHANGE_TYPE = ns + "CHANGE_TYPE"

export const CLEAR_DATERANGE = ns + "CLEAR_DATERANGE"
export const CHANGE_DATERANGE = ns + "CHANGE_DATERANGE"

export const CLEAR_MOREFILTERS_GROUP = ns + "CLEAR_MOREFILTERS_GROUP"
export const REPLACE_MOREFILTERS_GROUP = ns + "REPLACE_MOREFILTERS_GROUP"

export const CLEAR_VENDOR = ns + "CLEAR_VENDOR"
export const CHANGE_VENDOR = ns + "CHANGE_VENDOR"

export const CLEAR_ASSIGNEDTO = ns + "CLEAR_ASSIGNEDTO"
export const CHANGE_ASSIGNEDTO = ns + "CHANGE_ASSIGNEDTO"

export const CHANGE_BY_ID_START = ns + "CHANGE_BY_ID_START"
export const CHANGE_BY_ID_SUCCESS = ns + "CHANGE_BY_ID_SUCCESS"
export const CHANGE_BY_ID_FAILURE = ns + "CHANGE_BY_ID_FAILURE"

export const FETCH_PRODUCTS_REQUEST = ns + "FETCH_PRODUCTS_REQUEST"
export const FETCH_PRODUCTS_SUCCESS = ns + "FETCH_PRODUCTS_SUCCESS"
export const FETCH_PRODUCTS_FAILURE = ns + "FETCH_PRODUCTS_FAILURE"
export const RESET_PRODUCTS = ns + "RESET_PRODUCTS"

export const MOVE_PRODUCTS = ns + "MOVE_PRODUCTS"
export const DUPLICATE_PRODUCTS = ns + "DUPLICATE_PRODUCTS"
export const DELETE_PRODUCTS = ns + "DELETE_PRODUCTS"
