import { INPUT_TYPES } from "constants/index"
import ClientAddress from "./ClientAddress"

export default {
    name: "Client",
    endpoint: "/clients",
    fields: [
        {
            name: "first_name",
            label: "First name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "last_name",
            label: "Last name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "email",
            label: "E-mail Address",
            type: INPUT_TYPES.EMAIL,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "assigned_to",
            label: "Assigned To",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "assigned_to_2",
            label: "Assigned To",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "alternative_email",
            label: "Alternative e-mail",
            type: INPUT_TYPES.EMAIL,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "phone",
            label: "Phone Number",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "mobile",
            label: "Mobile Phone",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "referral",
            label: "Referral",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "types",
            label: "Type of Contact",
            type: INPUT_TYPES.ARRAY,
            defaultValue: [],
            attributes: {}
        },
        {
            name: "instagram",
            label: "Instagram",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "twitter",
            label: "Twitter",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "vip",
            label: "VIP",
            type: INPUT_TYPES.TEXT,
            defaultValue: 0,
            attributes: {}
        },
        {
            name: "active",
            label: "Active",
            type: INPUT_TYPES.TEXT,
            defaultValue: 0,
            attributes: {}
        },
        {
            name: "verified",
            label: "Verified",
            type: INPUT_TYPES.TEXT,
            defaultValue: 0,
            attributes: {}
        },
        {
            name: "addresses",
            label: "",
            type: INPUT_TYPES.MODEL_ARRAY,
            defaultValue: [
                {
                    name: "Main Address",
                    type: "personal"
                },
                {
                    name: "Shipping Address",
                    type: "delivery"
                },
                {
                    name: "Billing Address",
                    type: "invoice"
                }
            ],
            model: ClientAddress,
            attributes: {}
        }
    ]
}
