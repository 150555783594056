import React, { Fragment } from "react"
import PropTypes from "prop-types"

import * as fetchStatusHelper from "helpers/fetchStatus"

import Skeleton from "components/Skeleton"
import { InfiniteScroll } from "ui"
import { SnakeLoader } from "@butterfly-frontend/ui"

import styles from "./ProductList.module.css"

const ProductList = ({ products, handleLoadMoreProducts, children }) => {
    return (
        <div className={styles.productListContainer}>
            <Skeleton fetchStatus={fetchStatusHelper.fromResources(products)} component={<SnakeLoader />}>
                {products.meta.total ? (
                    <Fragment>
                        <div className={styles.productListInfo}>
                            1-{products.meta.to} over {products.meta.total} results
                        </div>
                        <div className={styles.productListInfiniteScrollContainer}>
                            <InfiniteScroll
                                className={styles.productList}
                                hasMore={products.meta.last_page !== products.meta.current_page}
                                loader={<SnakeLoader key="loader" />}
                                loadMore={handleLoadMoreProducts}
                                threshold={200}
                                useWindow={false}
                                element="ul"
                            >
                                {children}
                            </InfiniteScroll>
                        </div>
                    </Fragment>
                ) : (
                    <div className={styles.emptyList}>
                        <p>
                            <b>Sorry, no results found!</b>
                        </p>
                        <p>Please check the spelling or try searching for something else.</p>
                    </div>
                )}
            </Skeleton>
        </div>
    )
}

ProductList.propTypes = {
    products: PropTypes.shape({
        meta: PropTypes.shape({
            total: PropTypes.number,
            to: PropTypes.number,
            last_page: PropTypes.number,
            current_page: PropTypes.number
        })
    }).isRequired,
    handleLoadMoreProducts: PropTypes.func.isRequired,
    children: PropTypes.node
}

export default ProductList
