import React, { useEffect, useMemo } from "react"
import _get from "lodash/get"
import PropTypes from "prop-types"
import { Panel, H3, Image, Button, EditIcon } from "@butterfly-frontend/ui"

import useSelectCombination from "hooks/useSelectCombination"
import { ErrorLabel } from "ui/SelectWithSearch/components"
import { CombinationAttributeSelect } from "ui"
import formikPropTypesFactory from "modules/WmsModule/helpers/formikPropTypesFactory"
import { COMBINATION_PATTERNS_PROP_TYPE, COMBINATION_ATTRIBUTES_PROP_TYPE } from "propTypes/combinationPropType"

import styles from "./CombinationForm.module.css"

const CombinationForm = ({ formik, stockItem, isInEditMode, onSetEditMode }) => {
    const initialSelectedAttributeValueIds = useMemo(() => {
        const stockItemAttributeValues = _get(stockItem, "combination.attribute_values", [])
        return Array.isArray(stockItemAttributeValues) ? stockItemAttributeValues.map(({ id }) => id) : []
    }, [stockItem])

    const { selectAttribute, attributesForComponent, selectedAttributes } = useSelectCombination({
        combinationPatterns: _get(stockItem, "product.combination_patterns"),
        attributes: _get(stockItem, "product.attributes"),
        initialSelectedAttributeValueIds
    })

    useEffect(() => {
        formik.setFieldValue("selectedCombinationAttributes", selectedAttributes)
    }, [selectedAttributes])

    return (
        <div>
            <div className={styles.header}>
                <H3>Combination</H3>
                {!isInEditMode && (
                    <Button variant="flat" onClick={onSetEditMode} Icon={EditIcon} size="small">
                        Edit
                    </Button>
                )}
            </div>
            <Panel>
                <div className={styles.panel}>
                    <Image src={_get(stockItem, "product.image")} classes={{ root: styles.image }} />
                    <div className={styles.attributeSelects}>
                        {attributesForComponent.map(
                            ({ id, name, type, availableAttributeValues, isDisabled, selectedAttributeValue }) => (
                                <CombinationAttributeSelect
                                    id={id}
                                    name={name}
                                    selectAttribute={selectAttribute}
                                    selectedAttributeValue={selectedAttributeValue}
                                    availableAttributeValues={availableAttributeValues}
                                    type={type}
                                    key={id}
                                    isDisabled={isDisabled || !isInEditMode || formik.isSubmitting}
                                />
                            )
                        )}
                        {formik.errors.combination && (
                            <ErrorLabel error={formik.errors.combination} customStyles={styles.combinationError} />
                        )}
                    </div>
                </div>
            </Panel>
        </div>
    )
}

CombinationForm.propTypes = {
    formik: formikPropTypesFactory(
        {
            selectedCombinationAttributes: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number,
                    value: PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string
                    })
                })
            ).isRequired
        },
        {
            errors: PropTypes.shape({
                combination: PropTypes.string
            }).isRequired
        }
    ),

    stockItem: PropTypes.shape({
        combination: PropTypes.shape({
            attribute_values: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired
                }).isRequired
            ).isRequired
        }),

        product: PropTypes.shape({
            attributes: COMBINATION_ATTRIBUTES_PROP_TYPE.isRequired,
            combination_patterns: COMBINATION_PATTERNS_PROP_TYPE.isRequired
        }).isRequired
    }).isRequired,

    isInEditMode: PropTypes.bool,

    onSetEditMode: PropTypes.func.isRequired
}

export default CombinationForm
