import React, { useEffect } from "react"
import useSelectCombination from "hooks/useSelectCombination"
import Combinations from "./Combinations"

const CombinationsContainer = props => {
    const {
        attributes,
        setSelectedAttributes: handleSelectAttributes,
        initialSelectedAttributeValueIds = [],
        combinationPatterns
    } = props

    const { attributesForComponent, selectAttribute, selectedAttributes } = useSelectCombination({
        combinationPatterns,
        attributes,
        initialSelectedAttributeValueIds
    })

    useEffect(() => {
        handleSelectAttributes(
            selectedAttributes.reduce((agg, current) => (current.value.name ? [...agg, current.value.id] : agg), [])
        )
    }, [selectedAttributes])

    return <Combinations combinationsAttributes={attributesForComponent} selectAttribute={selectAttribute} />
}

export default CombinationsContainer
