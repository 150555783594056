import React, { Fragment, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { showErrorNotification, showSuccessNotification } from "actions/notification"
import ChooseSelectionsFlow from "./../ChooseSelectionsFlow"
import Widget from "./../Widget"
import {
    getSelectionById,
    getProducts,
    patchSelectionProductsById,
    incrementLock,
    resetLock,
    setLastServerUpdatedAt,
    setLastServerCompletedAt,
    resetChoosenSelection,
    removeChoosenSelectionFromLocalStore,
    resetProducts
} from "./../../actions"
import * as selectionActions from "modules/SelectionsModule/actions"

const WidgetContainer = props => {
    const dispatch = useDispatch()

    const current = useSelector(state => state.db.selectionsWidget.current)
    const products = useSelector(state => state.db.selectionsWidget.products)
    const selectionId = useSelector(state => state.db.selectionsWidget.current.choosenSelection)

    const [isModalFetching, setIsModalFetching] = useState(false)
    const [openModal, setOpenModal] = useState(null)
    const [errorEditSelection, setErrorEditSelection] = useState([])

    const { data: list, lock_counter, lastModifiedAt, lastServerUpdatedAt, lastServerCompletedAt } = products
    const isSelectionChoosen = !!selectionId || selectionId === 0

    const updateSelections = (list, selectionId) => {
        dispatch(incrementLock())
        dispatch(setLastServerUpdatedAt(new Date().getTime()))
        patchSelectionProductsById(list.map(product => ({ id: product.id })), selectionId)
            .then(() => {
                dispatch(resetLock())
                dispatch(setLastServerCompletedAt(new Date().getTime()))
                dispatch(showSuccessNotification())
            })
            .catch(() => {
                dispatch(showErrorNotification())
            })
    }

    useEffect(() => {
        if (isSelectionChoosen) {
            dispatch(getSelectionById(selectionId))
            dispatch(getProducts(selectionId))
        }
    }, [selectionId])

    useEffect(() => {
        if (current.fetchStatus.isError && current.fetchStatus.error && current.fetchStatus.error.status) {
            const status = current.fetchStatus.error.status
            if (status >= 400 && status < 500) {
                resetSelection()
            }
        }
    }, [current.fetchStatus.isError])

    useEffect(() => {
        if (!lock_counter) {
            if (lastModifiedAt > lastServerUpdatedAt) {
                updateSelections(list, selectionId)
            }
        }
    }, [lastModifiedAt, lastServerUpdatedAt, lastServerCompletedAt])

    const resetSelection = () => {
        removeChoosenSelectionFromLocalStore()
        dispatch(resetChoosenSelection())
        dispatch(resetProducts())
    }

    const deleteSelection = () => {
        setIsModalFetching(true)
        return selectionActions
            .deleteSelectionById(selectionId)
            .then(() => {
                dispatch(showSuccessNotification())
                resetSelection()
                setOpenModal(null)
            })
            .catch(() => {
                dispatch(showErrorNotification())
                setOpenModal(null)
            })
            .finally(() => setIsModalFetching(false))
    }

    const editSelection = data => {
        setIsModalFetching(true)
        return selectionActions
            .editSelection(selectionId, data)
            .then(() => {
                dispatch(showSuccessNotification())
                dispatch(getSelectionById(selectionId)).finally(() => {
                    setIsModalFetching(false)
                    setOpenModal(null)
                })
            })
            .catch(err => {
                setErrorEditSelection(err.errors)
                setIsModalFetching(false)
            })
    }

    return (
        <Fragment>
            <ChooseSelectionsFlow />
            <Widget
                isSelectionChoosen={isSelectionChoosen}
                current={current}
                products={products}
                isModalFetching={isModalFetching}
                openModal={openModal}
                setOpenModal={setOpenModal}
                handleDeleteSelection={deleteSelection}
                handleEditSelection={editSelection}
                errorEditSelection={errorEditSelection}
                setErrorEditSelection={setErrorEditSelection}
            />
        </Fragment>
    )
}

export default WidgetContainer
