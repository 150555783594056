import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import style from "./Checkbox.css"

class Checkbox extends Component {
    static defaultProps = {
        onClick: () => {},
        onChange: () => {}
    }

    render() {
        const { id, name, checked, onChange, onClick, cx, attributes } = this.props

        return (
            <span className={cx("root")}>
                <label htmlFor={id} className={cx("checkbox-label")}>
                    <input
                        tabIndex="-1"
                        type="checkbox"
                        id={id}
                        onClick={onClick}
                        className={cx("checkbox")}
                        checked={checked}
                        onChange={() => onChange(id)}
                    />
                    <span className={cx("label")}>
                        {name}
                        {attributes && attributes.required ? "*" : ""}
                    </span>
                </label>
            </span>
        )
    }
}

export default withStyles(Checkbox, style)
