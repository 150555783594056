import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./Dropdown.css"
import arrow from "assets/arrow.svg"

class Dropdown extends Component {
    state = {
        isExpanded: false
    }

    handleToggle = () => {
        this.setState(
            {
                isExpanded: !this.state.isExpanded
            },
            () => {
                document.addEventListener("click", this.handleHideDropdown)
            }
        )
    }

    handleHideDropdown = event => {
        if (this.statesDropdown && !this.statesDropdown.contains(event.target)) {
            this.setState(
                {
                    isExpanded: false
                },
                () => {
                    document.removeEventListener("click", this.handleHideDropdown)
                }
            )
        }
    }

    render() {
        const { isExpanded } = this.state
        const {
            fieldPath,
            defaultValue,
            isLabeledValue,
            isInline,
            value,
            values,
            onClick,
            label,
            cx,
            isLabel,
            error,
            handleScrollToError,
            attributes,
            isLoading
        } = this.props

        const arrowIco = this.props.arrow || arrow

        let visibleValue = !!value ? value : defaultValue
        if (isLabeledValue) {
            const searchLabelForValue = values.find(item => item.value === value)
            visibleValue = searchLabelForValue ? searchLabelForValue.label : visibleValue
        }

        const isEmpty = !values || (values && !values.length)
        let formattedLabel = `${label}${attributes && attributes.required ? " *" : ""}`

        return (
            <div className={cx("root", { error: error, isInline, isEmpty })}>
                {isLabel && label && <label onClick={() => !isEmpty && this.handleToggle()}>{formattedLabel}:</label>}
                <div
                    className={cx("input", { isLoading })}
                    onClick={() => !isEmpty && this.handleToggle()}
                    ref={element => {
                        this.statesDropdown = element
                    }}
                >
                    {!isLoading && <h2 className={cx("selected-value", { default: !value })}>{visibleValue}</h2>}
                    {!isLoading && (
                        <img src={arrowIco} alt="choose" className={cx(isExpanded ? "arrow-down" : "arrow-up")} />
                    )}
                    {isExpanded && !isLoading && (
                        <ul className={cx("tooltip")}>
                            {values.map((item, index) => (
                                <li
                                    key={index}
                                    className={cx("tooltip-li", { selected: value === item.value })}
                                    onClick={event =>
                                        onClick &&
                                        onClick({
                                            event: {
                                                target: {
                                                    value: item.value
                                                }
                                            },
                                            fieldPath
                                        })
                                    }
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {error && (
                    <h2
                        className={cx("error-message")}
                        ref={el => {
                            this.errorInput = el
                        }}
                    >
                        {error}
                        {handleScrollToError && handleScrollToError(this.errorInput)}
                    </h2>
                )}
            </div>
        )
    }
}

export default withStyles(Dropdown, styles)
