import React, { useState, useEffect } from "react"
import { SelectWithSearch } from "ui"
import fetch from "helpers/fetch"
import selectStyles from "../../../overrides/select.css"

const SelectShowroom = props => {
    const { name, value: showroomId, error } = props
    const [showrooms, setShowrooms] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showroomName, setShowroomName] = useState("")

    useEffect(() => {
        fetchCompanies()
    }, [])

    useEffect(() => {
        const showroom = showrooms.find(({ value }) => value === showroomId)

        setShowroomName(showroom ? showroom.label : "")
    }, [showrooms, showroomId])

    const fetchCompanies = () => {
        const formatData = data => {
            return data.map(item => ({ value: item.id, label: item.name, secondLabel: "" }))
        }

        setIsLoading(false)

        return fetch
            .get(`/companies?page=1&length=9999`)
            .then(payload => {
                setShowrooms(formatData(payload.data))
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <SelectWithSearch
            name={name}
            value={showroomName}
            values={showrooms}
            setValue={({ value }) => props.handleSelect({ target: { name, value } })}
            placeholder="- Please select -"
            customStyles={selectStyles}
            error={error}
            isDisabled={isLoading}
            withoutFetch
        />
    )
}

export default SelectShowroom
