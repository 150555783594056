function useLocationSelectWithBoxes(formik) {
    const { boxes } = formik.values

    const updateNewLocationToBoxesData = async newLocation => {
        const updatedBoxesData = boxes.map(box => ({ ...box, location: newLocation }))
        await formik.setFieldValue("boxes", updatedBoxesData)
        formik.setFieldTouched("boxes")
    }

    const onChangeSelectLocation = async ({ target: { value } }) => {
        const newLocation = value
            ? {
                  id: value.value,
                  name: value.label,
                  label: value.label
              }
            : null
        await formik.setFieldValue("location", newLocation)
        formik.setFieldTouched("location")
        updateNewLocationToBoxesData(newLocation)
    }

    const onCreateNewLocationSuccess = async data => {
        const newLocation = data
            ? {
                  id: data.id,
                  name: data.name,
                  label: data.name
              }
            : null
        await formik.setFieldValue("location", newLocation)
        formik.setFieldTouched("location")
        updateNewLocationToBoxesData(data)
    }

    return {
        onChangeSelectLocation,
        onCreateNewLocationSuccess
    }
}

export default useLocationSelectWithBoxes
