import React from "react"
import PropTypes from "prop-types"
import {
    Panel,
    H3,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableCell,
    TableRow,
    Status
} from "@butterfly-frontend/ui"

import { StockItemStatus } from "modules/WmsModule/components"

import styles from "./StockAdjustmentDetailsItemsList.module.css"

const CELL_WIDTH_12 = {
    width: "12%",
    minWidth: 110
}

const StockAdjustmentDetailsItemsList = ({ adjustmentItems }) => (
    <Panel>
        <div className={styles.container}>
            <H3 classes={{ h3: styles.h3 }}>Items</H3>
            <Table>
                <TableHead>
                    <TableHeadCell minWidth={200} width="40%">
                        PRODUCT NAME
                    </TableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_12}>FROM CONDITION</TableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_12}>TO CONDITION</TableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_12}>FROM STATUS</TableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_12}>TO STATUS</TableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_12}>WAREHOUSE</TableHeadCell>
                </TableHead>
                <TableBody>
                    {adjustmentItems.map(adjustmentItem => (
                        <TableRow classNames={{ root: styles.tableRow }} key={adjustmentItem.id}>
                            <TableCell minWidth={200} width="40%">
                                {adjustmentItem.product.name}
                            </TableCell>
                            <TableCell {...CELL_WIDTH_12}>
                                {adjustmentItem.conditionFrom ? (
                                    <Status color={adjustmentItem.conditionFrom.color}>
                                        {adjustmentItem.conditionFrom.name}
                                    </Status>
                                ) : (
                                    "-"
                                )}
                            </TableCell>
                            <TableCell {...CELL_WIDTH_12}>
                                {adjustmentItem.conditionTo ? (
                                    <Status color={adjustmentItem.conditionTo.color}>
                                        {adjustmentItem.conditionTo.name}
                                    </Status>
                                ) : (
                                    "-"
                                )}
                            </TableCell>
                            <TableCell {...CELL_WIDTH_12}>
                                {adjustmentItem.statusFrom ? (
                                    <StockItemStatus stockItemStatus={adjustmentItem.statusFrom.status} />
                                ) : (
                                    "-"
                                )}
                            </TableCell>
                            <TableCell {...CELL_WIDTH_12}>
                                {adjustmentItem.statusTo ? (
                                    <StockItemStatus stockItemStatus={adjustmentItem.statusTo.status} />
                                ) : (
                                    "-"
                                )}
                            </TableCell>
                            <TableCell {...CELL_WIDTH_12}>
                                {adjustmentItem.warehouse ? adjustmentItem.warehouse.name : "-"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    </Panel>
)

const conditionPropTypeShape = PropTypes.shape({
    color: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
})

const statusPropTypeShape = PropTypes.shape({
    status: PropTypes.string.isRequired
})

StockAdjustmentDetailsItemsList.propTypes = {
    adjustmentItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            product: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            conditionFrom: conditionPropTypeShape,
            conditionTo: conditionPropTypeShape,
            statusFrom: statusPropTypeShape,
            statusTo: statusPropTypeShape,
            warehouse: PropTypes.shape({
                name: PropTypes.string.isRequired
            })
        }).isRequired
    )
}

export default StockAdjustmentDetailsItemsList
