import React, { Fragment } from "react"
import { Redirect } from "react-router-dom"
import { Input, WysiwygEditor, SaveBar, DatePickerWithInput, Toggle, MultipleRadio } from "ui"
import { Header } from "modules/ContentModule/components"
import { Dropzone, GalleryUploader } from "../../../components"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import Modal from "components/Modal/Modal"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"
import Layout from "components/Reusable/Layout"

import withPermissions from "HOC/withPermissions"
import withStyles from "HOC/withStyles"

import styles from "./Form.css"
import titleInputStyles from "./overrides/TitleInput.css"
import subtitleInputStyles from "./overrides/SubtitleInput.css"
import verifiedStyles from "./overrides/Verified.css"
import tagsInputStyles from "./overrides/TagsInput.css"
import thumbnailDropzoneStyles from "./overrides/ThumbnailDropzone.css"
import datePickerStyles from "./overrides/DatePicker.css"
import galleryStyles from "./overrides/Gallery.css"

import eyeSvg from "assets/eye.svg"
import closeSvg from "assets/close-blue.svg"
import moment from "moment"

const Form = props => {
    const {
        cx,
        isBlog,
        isPortfolio,
        formFields,
        match,
        isEdit,
        addedFiles,
        isSaving,
        errorData,
        isUnsaved,
        canEdit,
        PERMISSIONS,
        isLoading,
        allTags,
        currentTagsWithName,
        searchTagValue,
        modalData,
        checkPermission,
        isConfirmModalOpen,
        isDeleteModalOpen,
        handleSelectDate,
        handleSelectRadio,
        handleToggleDeleteModal,
        handleChangeQuillValue,
        handleUploadFailure,
        handleUploadSuccess,
        handleRemoveFile,
        handleToggleConfirmModal,
        handleSubmitForm,
        handleDeleteTagFromPost,
        handleSelectTag,
        handleSetSearchTagValue,
        handleAddTag,
        handleToggleVerified,
        handlePassUploadedImages,
        handleDeleteFromSavedFiles,
        handleDndOrder
    } = props

    const { title, subtitle, cover, video, description, verified, thumbnail, publish_date, visible } = formFields
    const { newsId, blogId, portfolioId } = match.params
    const chosenTagsIds = currentTagsWithName.map(tag => tag.id)
    const allTagsWithoutAlreadyChosen = allTags.filter(tag => !chosenTagsIds.includes(tag.id))
    const descriptionError =
        errorData && errorData.errors && errorData.errors.description && errorData.errors.description[0]

    const tomorrowDate = new Date(moment().add(1, "days"))
    const maxDate = new Date(moment().add(1, "year"))
    const isVerificationDisabled = !checkPermission("allow blog posts", "verification")
    const isNotFutureDate =
        publish_date &&
        publish_date.value &&
        moment(new Date(publish_date.value)).format("YYYY-MM-DD") < moment(tomorrowDate).format("YYYY-MM-DD")
    const parsedFormFields = formFields.gallery
        ? formFields.gallery.value.map(el => {
              return { ...el, path: el.url }
          })
        : []
    const videoPreviewData = addedFiles.video || { name: (formFields.video_title || {}).value || "" }

    let viewPath = "/news"
    let pathId = newsId
    let userCanEdit = canEdit(PERMISSIONS.context.NEWS)
    let headerLabel = "Post"

    if (isBlog) {
        viewPath = "/content/blog"
        pathId = blogId
        userCanEdit = canEdit(PERMISSIONS.context.BLOG_POSTS)
    }
    if (isPortfolio) {
        viewPath = "/content/portfolio"
        pathId = portfolioId
        userCanEdit = canEdit(PERMISSIONS.context.PORTFOLIO_POSTS)
        headerLabel = "Project"
    }

    if (!userCanEdit) {
        return <Redirect to={viewPath} />
    }

    return (
        <Fragment>
            <Layout
                title={isEdit ? `Edit ${headerLabel}` : `New ${headerLabel}`}
                customStyles={styles}
                cta={
                    isEdit && [
                        <a
                            href={viewPath + "/card/" + pathId}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={cx("headerButton")}
                        >
                            <img src={eyeSvg} alt="" /> View
                        </a>,
                        <button
                            className={cx("headerButton", "closeButton")}
                            onClick={() => handleToggleDeleteModal("open")}
                        >
                            <img src={closeSvg} alt="" className={cx("closeIcon")} /> Delete
                        </button>
                    ]
                }
            >
                {isLoading && !!isEdit ? (
                    <div className={cx("snakeloader")}>
                        <SnakeLoader />
                    </div>
                ) : (
                    <Fragment>
                        <div className={cx("contentWrapper")}>
                            <div className={cx("inputWrapper")}>
                                <Input {...title} customStyles={titleInputStyles} />
                            </div>
                            {subtitle && (
                                <div className={cx("inputWrapper", "subtitle")}>
                                    <Input {...subtitle} customStyles={subtitleInputStyles} />
                                </div>
                            )}
                            <Dropzone
                                imageData={{ ...cover, value: addedFiles.cover.url || cover.value }}
                                previewData={addedFiles.cover}
                                handleUploadFailure={handleUploadFailure}
                                handleUploadSuccess={handleUploadSuccess}
                                handleRemoveFile={handleRemoveFile}
                                isCover={true}
                            />
                            <WysiwygEditor
                                label="Description:"
                                value={description.value ? description.value : ""}
                                onChange={handleChangeQuillValue}
                                error={descriptionError}
                            />
                            {isBlog && (
                                <Dropzone
                                    imageData={video}
                                    previewData={videoPreviewData}
                                    handleUploadFailure={handleUploadFailure}
                                    handleUploadSuccess={handleUploadSuccess}
                                    handleRemoveFile={handleRemoveFile}
                                    isVideo={true}
                                    acceptFormats="video/mp4,video/webm,video/ogg"
                                />
                            )}
                        </div>
                        <div className={cx("thumbnailWrapper")}>
                            <h3>Options</h3>
                            {isBlog && (
                                <div className={cx({ isVerificationDisabled })}>
                                    <div className={cx("verified")}>
                                        <label>Verified</label>
                                        <Toggle
                                            {...verified}
                                            customStyles={verifiedStyles}
                                            isOn={verified.value}
                                            handleChange={handleToggleVerified}
                                            labelPosition="left"
                                        />
                                    </div>
                                    {verified.value && (
                                        <div className={cx("visibility")}>
                                            <div className={cx("publish-date")}>
                                                <p>
                                                    {visible.value &&
                                                        (!publish_date.value
                                                            ? "(as of " + moment().format("DD/MM/YYYY") + ")"
                                                            : isNotFutureDate &&
                                                              "(as of " +
                                                                  moment(publish_date.value).format("DD/MM/YYYY") +
                                                                  ")")}
                                                </p>
                                                <p>
                                                    {publish_date.value &&
                                                        !isNotFutureDate &&
                                                        "(will be visible on " +
                                                            moment(publish_date.value).format("DD/MM/YYYY") +
                                                            ")"}
                                                </p>
                                            </div>
                                            <label>Visibility:</label>
                                            <MultipleRadio
                                                name="visibility"
                                                checked={visible.value}
                                                config={[
                                                    { value: true, label: "Visible" },
                                                    { value: false, label: "Hidden" }
                                                ]}
                                                handleSelect={handleSelectRadio}
                                            />
                                            <DatePickerWithInput
                                                date={isNotFutureDate ? null : publish_date.value}
                                                minDate={tomorrowDate}
                                                maxDate={maxDate}
                                                label="Publish Date:"
                                                handleSelectDate={date => {
                                                    handleSelectDate(date)
                                                }}
                                                isClearInLabel
                                                noDatePlaceholder="Set a specific publish date"
                                                customStyles={datePickerStyles}
                                            />
                                        </div>
                                    )}
                                </div>
                            )}
                            <label>Post thumbnail:</label>
                            <Dropzone
                                imageData={{ ...thumbnail, value: addedFiles.thumbnail.url || thumbnail.value }}
                                previewData={addedFiles.thumbnail}
                                handleUploadFailure={handleUploadFailure}
                                handleUploadSuccess={handleUploadSuccess}
                                handleRemoveFile={handleRemoveFile}
                                customStyles={thumbnailDropzoneStyles}
                                isThumbnail={true}
                            />
                            {!isPortfolio && (
                                <Fragment>
                                    <p className={cx("tags-label")}>Tags:</p>
                                    <Input
                                        value={searchTagValue}
                                        customStyles={tagsInputStyles}
                                        isPlaceholder={true}
                                        list={allTagsWithoutAlreadyChosen}
                                        label={"Add a tag..."}
                                        handleSelect={handleSelectTag}
                                        onChange={({ event }) => handleSetSearchTagValue(event.target.value)}
                                        handleCurrentValue={handleAddTag}
                                        maxValueAddLength={38}
                                    />
                                </Fragment>
                            )}
                            <div className={cx("current-tags")}>
                                {currentTagsWithName.map(({ id, name }, index) => {
                                    return (
                                        <div key={index} className={cx("tag")}>
                                            {name}
                                            <span onClick={() => handleDeleteTagFromPost(id)}>
                                                <img src={closeSvg} alt="close" className={cx("close-icon")} />
                                            </span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Fragment>
                )}
            </Layout>
            {isPortfolio && (
                <Layout customStyles={galleryStyles}>
                    <Header title="Gallery" />
                    {isLoading && !!isEdit ? (
                        <div className={cx("snakeloader", { isPortfolio })}>
                            <SnakeLoader />
                        </div>
                    ) : (
                        <GalleryUploader
                            savedFiles={parsedFormFields}
                            handlePassUploadedImages={handlePassUploadedImages}
                            handleDeleteFile={handleDeleteFromSavedFiles}
                            handleDndOrder={handleDndOrder}
                        />
                    )}
                </Layout>
            )}
            {isUnsaved && (
                <SaveBar
                    isShown={true}
                    onCancel={handleToggleConfirmModal}
                    onSubmit={handleSubmitForm}
                    isSaving={isSaving}
                    submitLabel="Save"
                    message="Unsaved changes"
                />
            )}
            {isDeleteModalOpen && (
                <Modal>
                    <ConfirmWithSubject {...modalData.delete} />
                </Modal>
            )}
            {isConfirmModalOpen && (
                <Modal>
                    <ConfirmWithSubject {...modalData.confirm} />
                </Modal>
            )}
        </Fragment>
    )
}

export default withPermissions(withStyles(Form, styles))
