import * as Yup from "yup"
import { ADDRESS_TYPES, DEFAULT_COUNTRY } from "constants/index"

export default {
    formEmpty: {
        first_name: "",
        last_name: "",
        birth_day: "",
        avatar: "",
        email: "",
        alternative_email: "",
        phone: "",
        mobile: "",
        twitter: "",
        instagram: "",
        website: "",
        job_title: "",
        facebook: "",
        other_social: "",
        general_note: "",
        purchase_history_amount: 0,
        total_purchase_amount: 0,
        average_purchase: 0,
        online_visits: 0,
        showroom_visits: 0,
        last_purchase_date: null,
        last_showroom_visits: null,
        last_online_vists: null,
        referral: "",
        active: false,
        verified: false,
        online_enabled: false,
        newsletter: false,
        newsletter_changed_at: null,
        types: [],
        users: [],
        mbid: 0,
        mlaid: 0,
        mla_sold_by: "",
        mb_sold_by: "",
        discount: null,
        addresses: [
            {
                name: ADDRESS_TYPES.personal,
                type: "personal",
                city: "",
                post_code: "",
                state: "",
                country: DEFAULT_COUNTRY,
                address: "",
                company: "",
                first_name: "",
                last_name: "",
                phone: ""
            },
            {
                name: ADDRESS_TYPES.delivery,
                type: "delivery",
                city: "",
                post_code: "",
                state: "",
                country: DEFAULT_COUNTRY,
                address: "",
                company: "",
                first_name: "",
                last_name: "",
                phone: ""
            },
            {
                name: ADDRESS_TYPES.invoice,
                type: "invoice",
                city: "",
                post_code: "",
                state: "",
                country: DEFAULT_COUNTRY,
                address: "",
                company: "",
                first_name: "",
                last_name: "",
                phone: ""
            }
        ]
    },
    schema: Yup.object().shape({
        first_name: Yup.string().nullable(),
        last_name: Yup.string().nullable(),
        birth_day: Yup.string().nullable(),
        email: Yup.string()
            .nullable()
            .email("This field must be a valid email."),
        alternative_email: Yup.string()
            .nullable()
            .email("This field must be a valid email."),
        phone: Yup.string().nullable(),
        mobile: Yup.string().nullable(),
        twitter: Yup.string().nullable(),
        instagram: Yup.string().nullable(),
        facebook: Yup.string().nullable(),
        other_social: Yup.string().nullable(),
        general_note: Yup.string().nullable(),
        website: Yup.string().nullable(),
        last_purchase_date: Yup.string().nullable(),
        purchase_history_amount: Yup.string().nullable(),
        total_purchase_amount: Yup.string().nullable(),
        average_purchase: Yup.string().nullable(),
        online_visits: Yup.string().nullable(),
        showroom_visits: Yup.string().nullable(),
        last_showroom_visits: Yup.string().nullable(),
        last_online_vists: Yup.string().nullable(),
        newsletter: Yup.string().nullable(),
        newsletter_changed_at: Yup.string().nullable(),
        mbid: Yup.string().nullable(),
        mlaid: Yup.string().nullable(),
        mla_sold_by: Yup.string().nullable(),
        mb_sold_by: Yup.string().nullable(),
        discount: Yup.number().nullable(),
        addresses: Yup.array().of(
            Yup.object().shape({
                company: Yup.string().nullable(),
                country: Yup.string().nullable(),
                address: Yup.string().nullable(),
                post_code: Yup.string().nullable(),
                city: Yup.string().nullable(),
                state: Yup.string().nullable()
            })
        )
    })
}
