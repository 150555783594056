import * as constants from "constants/index"
import fetch from "helpers/fetch"

export function getPressStart() {
    return {
        type: constants.GET_PRESS_START
    }
}

export function getPressSuccess(data) {
    return {
        type: constants.GET_PRESS_SUCCESS,
        payload: data
    }
}

export function getPressError(error) {
    return {
        type: constants.GET_PRESS_ERROR,
        payload: error
    }
}

export const getPresses = ({
    currentPage,
    pagination = { length: constants.ITEMS_PER_PAGE, page: currentPage }
}) => dispatch => {
    dispatch(getPressStart())

    return fetch
        .get(`/presses?length=${pagination.length}&page=${pagination.page}`)
        .then(data => dispatch(getPressSuccess(data)))
        .catch(error => dispatch(getPressError(error)))
}

export function createPressStart() {
    return {
        type: constants.CREATE_PRESS_START
    }
}

export function createPressSuccess(data) {
    return {
        type: constants.CREATE_PRESS_SUCCESS,
        payload: data
    }
}

export function createPressError(error) {
    return {
        type: constants.CREATE_PRESS_ERROR,
        payload: error
    }
}

export function createPress(press) {
    return dispatch => {
        dispatch(createPressStart())
        return new Promise((resolve, reject) => {
            return fetch
                .post("/presses", press)
                .then(data => {
                    dispatch(createPressSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(createPressError(error))
                    reject(error)
                })
        })
    }
}

export function updatePressStart() {
    return {
        type: constants.UPDATE_PRESS_START
    }
}

export function updatePressSuccess(data) {
    return {
        type: constants.UPDATE_PRESS_SUCCESS,
        payload: data
    }
}

export function updatePressError(error) {
    return {
        type: constants.UPDATE_PRESS_ERROR,
        payload: error
    }
}

export function updatePress(press) {
    return dispatch => {
        dispatch(updatePressStart())
        return new Promise((resolve, reject) => {
            return fetch
                .patch(`/presses/${press.id}`, press)
                .then(data => {
                    dispatch(updatePressSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(updatePressError(error))
                    reject(error)
                })
        })
    }
}

export function deletePressStart() {
    return {
        type: constants.DELETE_PRESS_START
    }
}

export function deletePressSuccess(data) {
    return {
        type: constants.DELETE_PRESS_SUCCESS,
        payload: data
    }
}

export function deletePressError(error) {
    return {
        type: constants.DELETE_PRESS_ERROR,
        payload: error
    }
}

export function deletePress(id) {
    return dispatch => {
        dispatch(deletePressStart())
        return new Promise((resolve, reject) => {
            return fetch
                .delete(`/presses/${id}`)
                .then(data => {
                    dispatch(deletePressSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(deletePressError(error))
                    reject(error)
                })
        })
    }
}
