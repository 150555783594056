import React, { Fragment } from "react"

import withStyles from "HOC/withStyles"
import * as fetchStatusHelper from "helpers/fetchStatus"
import { DateRangePicker, Modal, Input, SelectWithSearch, Button, Quantity, Image, DropList, Toggle } from "ui"
import InfiniteScroll from "ui/InfiniteScroll"
import Loader from "components/Reusable/Loader"
import Skeleton from "components/Skeleton"
import CombinationsContainer from "./CombinationsContainer"
import AddManualEntry from "./AddManualEntry"
import DiscountLabel from "modules/ProductsModule/pages/List/components/DiscountLabel"
import { formatPriceByComa } from "helpers/units"

import styles from "./ChooseProduct.css"
import inputStyles from "./overrides/Input.css"
import dateRangePickerStyles from "./overrides/DateRangePicker.css"
import selectWithSearchStyles from "./overrides/SelectWithSearch.css"
import addProductButton from "./overrides/AddProductButton.css"
import chooseOptionButton from "./overrides/ChooseOptionButton.css"
import stylesDropList from "./overrides/DropList.css"

const ChooseProduct = props => {
    const {
        cx,
        type,
        useServices,
        itemsState,
        selectedProduct,
        products,
        brands,
        categories,
        locations,
        availabilityStatuses,
        filterCount,
        filterSearchPhrase,
        filterBrand,
        filterCategory,
        filterSubCategory,
        filterDateRange,
        filterInstock,
        filterLocation,
        filterManualEntries,
        disableQuantity,
        hasMoreProducts,
        handleLoadMoreProducts,
        handleChangeFilterSearchPhrase,
        handleChangeFilterBrand,
        handleChangeFilterCategory,
        handleChangeFilterSubCategory,
        handleChangeFilterDateRange,
        handleChangeFilterInstock,
        handleChangeFilterLocation,
        handleChangeFilterManualEntries,
        handleResetFilterRangeDate,
        handleSelectProduct,
        handleSelectProductCombination,
        handleChangeQuantity,
        handleChangeIsLoading,
        mainTitle,
        disableCombinations,
        handleChangeType,
        isFilterExpanded,
        setIsFilterExpanded,
        isDatapickerOpen,
        isDatapickerOpenRef,
        setIsDatapickerOpen,
        areManualEntries,
        isAddManualEntryFormOpen,
        handleToggleManualEntryForm,
        selectedBrand,
        isDisabledAddItems,
        useWholesaleCost = false
    } = props

    if (selectedProduct && selectedProduct.has_combinations && !disableCombinations) {
        return (
            <CombinationsContainer
                productId={selectedProduct.id}
                productImage={productImage(selectedProduct)}
                handleSelectProduct={handleSelectProduct}
                handleSelectProductCombination={handleSelectProductCombination}
                itemsState={itemsState}
                handleChangeIsLoading={handleChangeIsLoading}
            />
        )
    }

    return (
        <Fragment>
            <Modal.Header>{renderHeaderTitle()}</Modal.Header>
            <Modal.Body>{renderBody()}</Modal.Body>
        </Fragment>
    )

    function renderHeaderTitle() {
        if (mainTitle) {
            return mainTitle
        }

        const title = type === "services" ? "Services" : "Products"

        return (
            <span>
                New Item:
                {useServices ? (
                    <DropList
                        customStyles={stylesDropList}
                        trigger={
                            <span className={cx("dropListLabel")}>
                                {title}
                                <span className={cx("dropListLabelArrow")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.847 451.847">
                                        <g>
                                            <path
                                                d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                                                className={cx("withFill")}
                                            />
                                        </g>
                                    </svg>
                                </span>
                            </span>
                        }
                    >
                        <DropList.List>
                            <DropList.Item
                                customStyles={stylesDropList}
                                isActive={type === "products"}
                                onClick={handleChangeType}
                            >
                                Product
                            </DropList.Item>
                            <DropList.Item
                                customStyles={stylesDropList}
                                isActive={type === "services"}
                                onClick={handleChangeType}
                            >
                                Service
                            </DropList.Item>
                        </DropList.List>
                    </DropList>
                ) : (
                    <span>&nbsp;{title}</span>
                )}
            </span>
        )
    }

    function renderBody() {
        return (
            <Skeleton
                fetchStatus={fetchStatusHelper.fromResources(products)}
                fallback={() => <div>An error occurred, please try again.</div>}
                component={
                    <div className={cx("loader")}>
                        <Loader />
                    </div>
                }
                firstTime={true}
            >
                <div className={cx("searchContainer")}>
                    <div className={cx("searchBar")}>
                        <Input
                            name="search"
                            label={displaySearchPlaceholder()}
                            isPlaceholder={true}
                            customStyles={inputStyles}
                            value={filterSearchPhrase}
                            onChange={({
                                event: {
                                    target: { value }
                                }
                            }) => handleChangeFilterSearchPhrase(value)}
                            autoComplete="off"
                            isClear
                        />
                        {renderFilterToggle()}
                    </div>
                    {renderFilters()}
                </div>
                {renderManualEntry()}
                {renderProductListContainer()}
            </Skeleton>
        )
    }

    function renderFilterToggle() {
        if (type === "services") {
            return null
        }

        return (
            <div
                className={cx("searchFilterToggle", { expanded: isFilterExpanded })}
                onClick={() => setIsFilterExpanded(prevState => !prevState)}
            >
                <span className={cx("label")}>Filters</span>
                {filterCount > 0 && <span className={cx("count")}>{filterCount}</span>}
            </div>
        )
    }

    function renderFilters() {
        if (!isFilterExpanded || type === "services") {
            return null
        }

        const getFilterData = (filterValues, filterId, defaultData) => {
            const filterValue = filterValues.find(item => item.id === filterId)
            if (!filterValue) {
                return defaultData
            }

            return Object.entries(defaultData).reduce(
                (acc, [key, value]) => ({ ...acc, [key]: filterValue[key] || value }),
                {}
            )
        }

        const subCategories = !!filterCategory ? filterCategory.children : []

        return (
            <Fragment>
                <div className={cx("searchFilterContainer")}>
                    <div>
                        <SelectWithSearch
                            placeholder="All brands"
                            value={!!filterBrand ? filterBrand.name : ""}
                            values={brands
                                .filter(item => !!item.count)
                                .map(item => ({ value: item.id, label: item.name }))}
                            setValue={({ value }) =>
                                handleChangeFilterBrand({ id: value, ...getFilterData(brands, value, { name: "" }) })
                            }
                            customStyles={selectWithSearchStyles}
                            withoutFetch
                            isDisabled={!!selectedBrand}
                        />
                    </div>
                    <div>
                        <SelectWithSearch
                            placeholder="All categories"
                            value={!!filterCategory ? filterCategory.name : ""}
                            values={categories
                                .filter(item => !!item.count)
                                .map(item => ({ value: item.id, label: item.name }))}
                            setValue={({ value }) =>
                                handleChangeFilterCategory({
                                    id: value,
                                    ...getFilterData(categories, value, { name: "", children: [] })
                                })
                            }
                            customStyles={selectWithSearchStyles}
                            handleClear={() => {
                                handleChangeFilterCategory(null)
                                handleChangeFilterSubCategory(null)
                            }}
                            withoutFetch
                        />
                    </div>
                    <div>
                        <SelectWithSearch
                            placeholder="Sub categories"
                            isDisabled={(!filterSubCategory || !filterSubCategory.id) && !subCategories.length}
                            value={!!filterSubCategory ? filterSubCategory.name : ""}
                            values={subCategories
                                .filter(item => !!item.count)
                                .map(item => ({ value: item.id, label: item.name }))}
                            setValue={({ value }) =>
                                handleChangeFilterSubCategory({
                                    id: value,
                                    ...getFilterData(subCategories, value, { name: "" })
                                })
                            }
                            customStyles={selectWithSearchStyles}
                            withoutFetch
                        />
                    </div>
                </div>
                <div className={cx("searchFilterContainer")}>
                    <div>
                        <SelectWithSearch
                            placeholder="All locations"
                            value={!!filterLocation ? filterLocation.name : ""}
                            values={locations.map(item => ({ value: item.id, label: item.name }))}
                            setValue={({ value }) =>
                                handleChangeFilterLocation({
                                    id: value,
                                    ...getFilterData(locations, value, { name: "" })
                                })
                            }
                            customStyles={selectWithSearchStyles}
                            withoutFetch
                        />
                    </div>
                    <div>
                        <SelectWithSearch
                            placeholder="Stock"
                            value={!!filterInstock ? filterInstock.label : ""}
                            values={availabilityStatuses}
                            setValue={({ value }) =>
                                handleChangeFilterInstock(
                                    availabilityStatuses.find(available => available.value === value)
                                )
                            }
                            customStyles={selectWithSearchStyles}
                            withoutFetch
                        />
                    </div>
                    <div>
                        <DateRangePicker
                            title="Any date"
                            customStyles={dateRangePickerStyles}
                            ranges={filterDateRange}
                            onChange={handleChangeFilterDateRange}
                            resetRanges={handleResetFilterRangeDate}
                            shownDate={new Date()}
                            isDatapickerOpen={isDatapickerOpen}
                            isDatapickerOpenRef={isDatapickerOpenRef}
                            setIsDatapickerOpen={setIsDatapickerOpen}
                        />
                    </div>
                </div>
                {areManualEntries && (
                    <div className={cx("searchFilterContainer")}>
                        <div>
                            <Toggle
                                label={{ on: "Manual entries", off: "Manual entries" }}
                                isOn={filterManualEntries}
                                handleChange={handleChangeFilterManualEntries}
                            />
                        </div>
                    </div>
                )}
            </Fragment>
        )
    }

    function renderManualEntry() {
        return (
            <div className={cx("manual-entry-wrapper")}>
                {type === "products" && areManualEntries && !isAddManualEntryFormOpen && (
                    <div className={cx("add-manual-entry-button-wrapper")}>
                        <Button
                            className="first-button"
                            label="Add Manual Entry"
                            onClick={handleToggleManualEntryForm}
                            customStyles={addProductButton}
                            isDisabled={isDisabledAddItems}
                        />
                    </div>
                )}
                {isAddManualEntryFormOpen && (
                    <AddManualEntry
                        tax_code={0}
                        discount={0}
                        onClose={handleToggleManualEntryForm}
                        onSuccess={handleSelectProduct}
                        selectedBrand={selectedBrand}
                    />
                )}
            </div>
        )
    }

    function renderProductListContainer() {
        return (
            <div className={cx("productListContainer")}>
                <Skeleton
                    fetchStatus={fetchStatusHelper.fromResources(products)}
                    component={
                        <div className={cx("loader")}>
                            <Loader />
                        </div>
                    }
                >
                    {renderProductList()}
                </Skeleton>
            </div>
        )
    }

    function renderProductList() {
        if (!products.meta.total) {
            return (
                <div className={cx("emptyList")}>
                    <p>
                        <b>Sorry, no results found!</b>
                    </p>
                    <p>Please check the spelling or try searching for something else.</p>
                </div>
            )
        }

        return (
            <Fragment>
                <div className={cx("productListInfo")}>
                    1-{products.meta.to} over {products.meta.total} results
                </div>
                <div className={cx("productListInfiniteScrollContainer")}>
                    <InfiniteScroll
                        className={cx("productList")}
                        hasMore={hasMoreProducts}
                        loader={
                            <div key="loader" className={cx("loader")}>
                                <Loader />
                            </div>
                        }
                        loadMore={handleLoadMoreProducts}
                        threshold={200}
                        useWindow={false}
                        element="ul"
                    >
                        {products.data.map((product, index) => {
                            const { lowest_price, has_combinations, discount, price, wholesale_cost } = product
                            const basePrice = lowest_price || price || 0

                            const productPrice = useWholesaleCost
                                ? wholesale_cost || 0
                                : (basePrice * (100 - (discount || 0))) / 100

                            return (
                                <li key={index} className={cx("item")}>
                                    <div className={cx("side")}>
                                        {renderImage(product)}
                                        <div className={cx("nameContainer")}>
                                            <div className={cx("name")}>{product.name}</div>
                                            <div className={cx("brand")}>{(product.brand || {}).name || ""}</div>
                                        </div>
                                    </div>
                                    <div className={cx("side", "side-price")}>
                                        <div className={cx("price-container")}>
                                            <Fragment>
                                                {has_combinations && <span className={cx("price")}>from</span>}{" "}
                                                <span className={cx("price")}>
                                                    $&nbsp;&nbsp;{formatPriceByComa(productPrice, true) || 0}.
                                                    {formatPriceByComa(productPrice, false) || "00"}
                                                </span>
                                            </Fragment>
                                            {discount > 0 && <DiscountLabel discount={discount} />}
                                        </div>
                                        <div className={cx("side")}>
                                            {(!has_combinations || disableCombinations) && !disableQuantity && (
                                                <Quantity
                                                    value={
                                                        (itemsState[product.id] && itemsState[product.id].quantity) || 1
                                                    }
                                                    min={1}
                                                    onChange={value => handleChangeQuantity(value, product.id)}
                                                />
                                            )}
                                            {(!has_combinations || disableCombinations) && (
                                                <Button
                                                    label={displayAddProductLabel()}
                                                    isLoading={
                                                        itemsState[product.id] && itemsState[product.id].isLoading
                                                    }
                                                    customStyles={addProductButton}
                                                    onClick={() => {
                                                        handleChangeIsLoading(product.id, true)
                                                        handleSelectProduct(product)
                                                    }}
                                                    isDisabled={isDisabledAddItems}
                                                />
                                            )}
                                            {has_combinations && !disableCombinations && (
                                                <Button
                                                    label="Choose an option"
                                                    customStyles={chooseOptionButton}
                                                    onClick={() => handleSelectProduct(product)}
                                                    isDisabled={isDisabledAddItems}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </InfiniteScroll>
                </div>
            </Fragment>
        )
    }

    function displaySearchPlaceholder() {
        if (type === "services") {
            return "Search and add service..."
        }

        return "Search and add product..."
    }

    function displayAddProductLabel() {
        if (type === "services") {
            return "Add service"
        }

        return "Add product"
    }

    function productImage(product) {
        return (product.media || {}).url || ""
    }

    function renderImage(product) {
        if (type === "services") {
            return null
        }

        return <Image className={cx("photo")} src={productImage(product)} />
    }
}

export default withStyles(ChooseProduct, styles)
