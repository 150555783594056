import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./RoleData.css"
import pencil from "assets/pencil.svg"
import remove from "assets/close.svg"
import { Link } from "react-router-dom"
import Confirm from "components/Modal/Confirm/Confirm"
import Modal from "components/Modal/Modal"
import withPermissions from "HOC/withPermissions"

class RoleData extends Component {
    state = {
        isConfirmOpen: false
    }

    render() {
        const {
            cx,
            id,
            index,
            name,
            permissions_count,
            total_permissions_count,
            users_in_group,
            canEdit,
            canDelete,
            PERMISSIONS
        } = this.props
        const { isConfirmOpen } = this.state
        return (
            <li className={cx("role-data-li")}>
                <h4 className={cx("no")}>{index + 1}.</h4>
                <h4 className={cx("name")}>{name}</h4>
                <h4 className={cx("permissions")}>
                    {permissions_count}/{total_permissions_count}
                </h4>
                <h4 className={cx("space")}> </h4>
                <h4 className={cx("users")}>
                    <Link to={"/settings/users/roles/" + id + "/users"} className={cx("cta", "link")}>
                        {users_in_group}
                    </Link>
                </h4>
                <h4 className={cx("edit")}>
                    {canEdit(PERMISSIONS.context.ROLES) && (
                        <Link to={"/settings/users/roles/edit/" + id} className={cx("edit-text")}>
                            <img src={pencil} alt="edit" className={cx("edit-icon")} />
                            Edit
                        </Link>
                    )}
                </h4>
                <h4 className={cx("delete")}>
                    {isConfirmOpen && users_in_group === 0 && (
                        <Modal>
                            <Confirm
                                confirmModalTitle="Are you sure you want to delete this role?"
                                id={id}
                                handleHideModal={this.onCancel}
                                handleDelete={this.onConfirm}
                            />
                        </Modal>
                    )}

                    {canDelete(PERMISSIONS.context.ROLES) && (
                        <span
                            className={cx(users_in_group === 0 ? "delete-text" : "delete-disabled")}
                            onClick={this.handleConfirm}
                        >
                            <img src={remove} alt="edit" className={cx("remove-icon")} />
                            Delete
                        </span>
                    )}
                </h4>
            </li>
        )
    }

    handleConfirm = () => {
        this.setState({ isConfirmOpen: true })
    }

    onCancel = () => {
        this.setState({ isConfirmOpen: false })
    }

    onConfirm = id => {
        this.props.deleteThisRole(id)
        this.setState({ isConfirmOpen: false })
    }
}

export default withPermissions(withStyles(RoleData, styles))
