import React, { useEffect, useRef, useState } from "react"
import debounce from "lodash.debounce"

import withStyles from "HOC/withStyles"
import fetch from "helpers/fetch"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useActions } from "hooks"

import { Button } from "ui"
import { DragAndDropList } from "modules/ContentModule/components"
import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"
import AddEditModal from "./components/AddEditModal"

import styles from "./Faq.css"

import { DEBOUNCE_DRAG_AND_DROP_REQUEST } from "constants/index"
import faqSchema from "./schema"

function Faq(props) {
    const { cx, faqs, fetchParent, faqableId, faqableType } = props

    const actions = useActions({ showSuccessNotification, showErrorNotification })

    const [list, setList] = useState([])
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(null)
    const [itemToEdit, setItemToEdit] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setList(faqs)
    }, [faqs])

    const remove = async id => {
        setIsDeleting(true)

        try {
            await fetch.deleteRAW(`/faqs/${id}`)
            fetchParent()
            actions.showSuccessNotification()
        } catch (error) {
            actions.showErrorNotification()
        }

        setIsDeleting(false)
        setItemToDelete(null)
    }

    const save = async data => {
        setIsSubmitting(true)
        setErrors({})

        try {
            await faqSchema.validate(data, { abortEarly: false })

            const body = {
                ...data,
                faqable_id: faqableId,
                faqable_type: faqableType
            }

            data.id ? await fetch.patch(`/faqs/${data.id}`, body) : await fetch.post("/faqs", body)
            fetchParent()
            actions.showSuccessNotification()
            setItemToEdit(null)
        } catch (error) {
            setErrors(oldErrors => ({
                ...oldErrors,
                ...handleErrors(error)
            }))

            actions.showErrorNotification()
        }

        setIsSubmitting(false)
    }

    const debouncedUpdateListOrder = useRef(
        debounce(items => {
            setIsUpdatingOrder(true)
            fetch
                .postRAW("/multi-actions", {
                    ids: items.map(item => item.id),
                    model: "faqs",
                    action: "sort"
                })
                .then(() => actions.showSuccessNotification())
                .catch(() => actions.showErrorNotification())
                .finally(() => setIsUpdatingOrder(false))
        }, DEBOUNCE_DRAG_AND_DROP_REQUEST)
    ).current

    const onReorder = items => {
        setList(items)
        debouncedUpdateListOrder(items)
    }

    const handleCloseAddEditModal = () => {
        setItemToEdit(null)
        setErrors({})
    }

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <Button
                    className={cx("first-button", "white", "addButton")}
                    label="Add"
                    onClick={() => setItemToEdit({})}
                />
            </div>

            {list.length > 0 && (
                <div className={cx("list")}>
                    <DragAndDropList itemsList={list} handleReorder={onReorder} disabled={isUpdatingOrder}>
                        {list.map(item => (
                            <div key={item.id} className={cx("row")}>
                                <div className={cx("desc")}>
                                    <span className={cx("question")}>{item.question}</span>
                                    <span className={cx("answer")}>{item.answer}</span>
                                </div>
                                <div className={cx("actions")}>
                                    <Button className={cx("edit")} label="Edit" onClick={() => setItemToEdit(item)} />
                                    <Button
                                        className={cx("delete")}
                                        label="Delete"
                                        onClick={() => setItemToDelete(item)}
                                    />
                                </div>
                            </div>
                        ))}
                    </DragAndDropList>
                </div>
            )}

            {!!itemToDelete && (
                <Modal>
                    <Confirm
                        confirmModalTitle={`Are you sure you want to delete? This action cannot be reversed.`}
                        handleHideModal={() => setItemToDelete(null)}
                        handleDelete={() => remove(itemToDelete.id)}
                        isLoading={isDeleting}
                    />
                </Modal>
            )}

            {!!itemToEdit && (
                <AddEditModal
                    initialValues={itemToEdit}
                    onClose={handleCloseAddEditModal}
                    onSave={save}
                    setErrors={setErrors}
                    isSubmitting={isSubmitting}
                    errors={errors}
                />
            )}
        </div>
    )
}

function handleErrors(errorObject) {
    if (Array.isArray(errorObject.inner)) {
        return errorObject.inner.reduce((acc, { path, message }) => ({ ...acc, [path]: message }), {})
    }
    return errorObject.errors
}

export default withStyles(Faq, styles)
