import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import withForm from "HOC/withForm"

const FormContainer = (
    WrappedComponent,
    { mapStateToProps, mapDispatchToProps, idParamName = "id", model, prepareDataBeforeSubmit }
) => {
    class Enhance extends Component {
        dataId = null
        componentDidMount() {
            const { match, getData } = this.props
            if (match && match.params && match.params[idParamName]) {
                this.dataId = match.params[idParamName]
                getData && getData(this.dataId)
            }
        }

        // eslint-disable-next-line
        UNSAFE_componentWillReceiveProps(nextProps) {
            const { isEdit, isNew, fillModel, data } = nextProps

            if ((isEdit || isNew) && data) {
                fillModel({
                    ...data
                })
            }

            if (!this.props.isError && nextProps.isError) {
                this.props.showErrorNotification()
            }
        }

        handleSubmitSuccess = isGoBack => {
            const { history, showSuccessNotification } = this.props
            showSuccessNotification && showSuccessNotification()
            if (isGoBack) {
                history.goBack()
            }
        }

        handleSubmitForm = (event, withHandleSuccess = true, isGoBack = true) => {
            event && event.preventDefault && event.preventDefault()
            const { handleSubmit, isEdit } = this.props
            handleSubmit({
                method: isEdit ? "patch" : "post",
                id: this.dataId,
                handleSuccess: () => withHandleSuccess && this.handleSubmitSuccess(isGoBack)
            })
        }

        handleSubmitFormWithAdditionalParams = (additionalParams, withHandleSuccess = true, isGoBack = true) => {
            const { fillModel, toApi } = this.props
            const dataToApi = toApi()

            fillModel(
                {
                    ...dataToApi,
                    ...additionalParams
                },
                true
            ).then(() => {
                this.handleSubmitForm(null, withHandleSuccess, isGoBack)
            })
        }

        render() {
            const { toRender, setCustomEndpoint } = this.props

            return (
                <WrappedComponent
                    {...this.props}
                    dataId={this.dataId}
                    formFields={toRender()}
                    setCustomEndpoint={setCustomEndpoint}
                    handleSubmitForm={this.handleSubmitForm}
                    handleSubmitFormWithAdditionalParams={this.handleSubmitFormWithAdditionalParams}
                />
            )
        }
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(withRouter(withForm(Enhance, { model }, prepareDataBeforeSubmit)))
}

export default FormContainer
