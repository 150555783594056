import React, { Fragment } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import _get from "lodash/get"

import { H2 } from "@butterfly-frontend/ui"
import { SelectWithSearch, Quantity } from "ui"
import { SelectWarehouse, SelectProductConditions, SelectLocation } from "ui/Filters"

import { FormSection, FormRow, FormColumn } from "modules/WmsModule/modals/components"
import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import STOCK_ITEM_STATUS_SETTER_OPTIONS from "modules/WmsModule/constants/stockItemStatusSetterOptions"
import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import { STOCK_ITEM_QUANTITY_TO_ADD } from "modules/WmsModule/constants/stockItemQuantity"
import { useLocationSelectWithBoxes } from "modules/WmsModule/hooks"

import styles from "./InventoryForm.module.css"
import quantityStyles from "./overrides/Quantity.module.css"

const EXCLUDED_CONDITION_FOR_SINGLE_ADD = [CONDITION_TYPES.REMOVED, CONDITION_TYPES.INCOMPLETE]
const EXCLUDED_CONDITION_FOR_MULTI_ADD = [CONDITION_TYPES.REMOVED, CONDITION_TYPES.INCOMPLETE, CONDITION_TYPES.DAMAGED]

const InventoryForm = ({ formik }) => {
    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)

    const getSelectProps = getSelectPropsFactory(formik)
    const { onChangeSelectLocation, onCreateNewLocationSuccess } = useLocationSelectWithBoxes(formik)

    const onQuantityChange = quantity => {
        if (formik.values.conditionImages.length > 0 && quantity > 1) {
            formik.setFieldValue("conditionImages", [])
        }

        if (_get(formik.values.condition, "value.type") === CONDITION_TYPES.DAMAGED) {
            formik.setFieldValue("condition", null)
        }

        formik.setFieldValue("quantity", quantity)
    }

    return (
        <FormSection title={<H2 classes={{ h2: styles.sectionTitle }}>Inventory</H2>}>
            <Fragment>
                <FormRow>
                    <FormColumn className={styles.column}>
                        <SelectWarehouse
                            {...getSelectProps("warehouse")}
                            label="Warehouse"
                            isRequired
                            warehouseType="active"
                            warehouseIds={assignedWarehouseIds}
                        />
                    </FormColumn>
                    <FormColumn className={styles.column}>
                        <SelectLocation
                            onCreateNewLocationSuccess={onCreateNewLocationSuccess}
                            selectWithSearchProps={getSelectProps("location")}
                            handleSelect={onChangeSelectLocation}
                        />
                    </FormColumn>
                </FormRow>
                <FormRow>
                    <FormColumn className={classNames(styles.column, styles.statusColumn)}>
                        <SelectProductConditions
                            selectWithSearchProps={getSelectProps("condition", {
                                isClearBlocked: true,
                                isRequired: true
                            })}
                            label="Condition"
                            excludedTypes={
                                formik.values.quantity > 1
                                    ? EXCLUDED_CONDITION_FOR_MULTI_ADD
                                    : EXCLUDED_CONDITION_FOR_SINGLE_ADD
                            }
                            multiselect={false}
                            handleSelect={({ target: { value } }) => formik.setFieldValue("condition", value)}
                        />
                    </FormColumn>

                    <FormColumn className={classNames(styles.column, styles.statusColumn)}>
                        <SelectWithSearch
                            {...getSelectProps("status")}
                            label="Status"
                            values={STOCK_ITEM_STATUS_SETTER_OPTIONS}
                            isClearBlocked
                            withoutFetch
                            isRequired
                        />
                    </FormColumn>

                    <FormColumn className={styles.quantityCounterColumn}>
                        <label
                            className={classNames(styles.quantityCounterLabel, {
                                [styles.disabled]: formik.isSubmitting
                            })}
                        >
                            Qty:
                        </label>
                        <div className={styles.quantityCounter}>
                            <Quantity
                                min={STOCK_ITEM_QUANTITY_TO_ADD.MIN}
                                max={STOCK_ITEM_QUANTITY_TO_ADD.MAX}
                                disabled={formik.isSubmitting}
                                value={formik.values.quantity}
                                onChange={onQuantityChange}
                                error={formik.errors.quantity}
                                isInputDisabled={false}
                                customStyles={quantityStyles}
                            />
                        </div>
                    </FormColumn>
                </FormRow>
            </Fragment>
        </FormSection>
    )
}

InventoryForm.propTypes = {
    formik: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.shape({
            warehouse: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired
            }),
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string.isRequired
            }),
            condition: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.shape({
                    id: PropTypes.number.isRequired
                }).isRequired
            }),
            status: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.oneOf(Object.values(STOCK_ITEM_STATUS_KEYS)).isRequired
            })
        }).isRequired
    }).isRequired
}

export default InventoryForm
