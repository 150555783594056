import { useMemo } from "react"

import { getStockItemsLabels } from "modules/WmsModule/hooks/api/useStockItem"
import useDocument, { DOCUMENT_ACTIONS } from "modules/WmsModule/hooks/useDocument"
import BOX_LABEL_TYPES from "modules/WmsModule/constants/boxLabelsTypes"
import BOX_LABELS_TEMPLATES from "modules/WmsModule/constants/boxLabelsTemplates"

const DEFAULT_OPTIONS_LABELS = {
    print: "Print package labels",
    download: "Download package labels"
}

const useBoxLabelOptions = ({
    ids,
    labels = {},
    documentType = BOX_LABEL_TYPES.STOCK_ITEM,
    templateType = BOX_LABELS_TEMPLATES.WITHOUT_TEMPLATE
}) => {
    const optionLabels = useMemo(() => ({ ...DEFAULT_OPTIONS_LABELS, ...labels }), [labels])

    const customFetch = documentType === BOX_LABEL_TYPES.STOCK_ITEM ? getStockItemsLabels : undefined

    const { mutate: printBoxesLabels, isLoading: isPrintingPackageSlips } = useDocument({
        actionType: DOCUMENT_ACTIONS.PRINT,
        documentType,
        templateType,
        customFetch
    })

    const { mutate: downloadBoxesLabels, isLoading: isDownloadingPackageSlips } = useDocument({
        actionType: DOCUMENT_ACTIONS.DOWNLOAD,
        documentType,
        templateType,
        fileName: "Box labels",
        customFetch
    })

    const options = useMemo(
        () => [
            {
                onClick: () => printBoxesLabels({ ids }),
                label: optionLabels.print
            },
            {
                onClick: () => downloadBoxesLabels({ ids }),
                label: optionLabels.download
            }
        ],
        [ids, printBoxesLabels, downloadBoxesLabels, optionLabels]
    )

    return {
        options,
        isLoading: isPrintingPackageSlips || isDownloadingPackageSlips
    }
}

export default useBoxLabelOptions
