import React from "react"
import PropTypes from "prop-types"
import { Field } from "formik"

import withStyles from "HOC/withStyles"

import { Modal, Button, Input } from "ui"

import Cover from "../../Cover"

import styles from "./styles.css"
import inputStyles from "../overrides/input.css"

const ChooseImageWithName = ({ isOpen, onClose, cx, propsForm, isSubmitting, initialStage, imageDimensions }) => {
    const isEdit = !!propsForm.values.id

    return (
        <Modal isOpen={isOpen} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>{isEdit ? "Edit image" : "Create image with pins"}</p>

            <Field name="name">
                {({ field }) => (
                    <Input
                        {...field}
                        value={field.value || ""}
                        label="Image name:"
                        onChange={({ event }) => field.onChange(event)}
                        customStyles={inputStyles}
                        error={propsForm.errors[field.name]}
                    />
                )}
            </Field>

            <label>Add images:</label>
            <Cover form={propsForm} imageDimensions={imageDimensions} />

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    type="button"
                    label={isEdit && initialStage === "chooseImage" ? "Save" : "Next"}
                    isDisabled={isDisabled()}
                    onClick={propsForm.handleSubmit}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )

    function isDisabled() {
        return !(propsForm.values.cover_desktop || {}).id || !propsForm.values.name
    }
}

ChooseImageWithName.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool,
    initialStage: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    imageDimensions: PropTypes.object.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(ChooseImageWithName, styles)
