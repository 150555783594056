import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import { Modal, Button, ProductSearch } from "ui"
import DragAndDropList from "../DragAndDropList/DragAndDropList"

import closeIcon from "assets/close-blue.svg"
import catalogIcon from "assets/ico-catalog.svg"

import styles from "./styles.css"
import productSearchStyles from "./overrides/ProductSearch.css"

const SectionModalProducts = ({ onClose, cx, form, handleRemove, handleReorder, handleAdd, isSubmitting }) => {
    const products = form.values.products || []

    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Products</p>

            <div
                className={cx("productSearchWrapper", {
                    isError: (form.errors.products || []).length > 0
                })}
            >
                <ProductSearch
                    customStyles={productSearchStyles}
                    handleSelectProduct={handleAdd}
                    placeholder="Search and add product..."
                    availableOnWeb
                />
            </div>

            {products.length > 0 ? (
                <Fragment>
                    <div className={cx("line")} />

                    <p className={cx("header-small")}>{products.length} products added</p>

                    <div className={cx("list")}>
                        <DragAndDropList itemsList={products} handleReorder={handleReorder}>
                            {products.map((product, index) => (
                                <div key={product.id} className={cx("productLine")}>
                                    <div className={cx("imageWrapper")}>
                                        {product.image && <img src={product.image} alt="preview" />}
                                    </div>
                                    <span>
                                        <Link className={cx("name")} to={`/products/${product.id}`}>
                                            {product.name}
                                        </Link>
                                    </span>
                                    <button onClick={() => handleRemove(index)}>
                                        <img src={closeIcon} alt="close" />
                                    </button>
                                </div>
                            ))}
                        </DragAndDropList>
                    </div>
                </Fragment>
            ) : (
                <div className={cx("empty-box")}>
                    <img src={catalogIcon} alt="emptyIcon" />
                    <p>No product added</p>
                </div>
            )}

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    label="Save"
                    onClick={form.submitForm}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

SectionModalProducts.propTypes = {
    onClose: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    handleRemove: PropTypes.func.isRequired,
    handleReorder: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(SectionModalProducts, styles)
