import * as constants from "constants/index"

import brands from "./brands"
import categories from "./categories"
import locations from "./locations"
import selections from "./selections"

const reducers = {
    brands,
    categories,
    locations,
    selections
}

export const INITIAL_STATE = Object.keys(reducers).reduce(
    (acc, name) => ({ ...acc, [name]: reducers[name](undefined, {}) }),
    {}
)

const initState = () => ({ ...INITIAL_STATE })

const aggregates = (state = initState(), action) => {
    switch (action.type) {
        case constants.CLEAR_PRODUCT_AGGREGATES_GROUP:
            return initState()

        case constants.REPLACE_PRODUCT_AGGREGATES_GROUP:
            return { ...state, ...action.payload.value }

        default:
            return Object.keys(reducers).reduce(
                (acc, name) => ({ ...acc, [name]: reducers[name](state[name], action) }),
                {}
            )
    }
}

export default aggregates
