import React, { Component } from "react"

import Layout from "components/Reusable/Layout/index"

class ListingLayout extends Component {
    render() {
        const { title, children } = this.props

        return (
            <Layout {...this.props} title={title}>
                {children}
            </Layout>
        )
    }
}

export default ListingLayout
