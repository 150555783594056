import React, { Fragment, useMemo } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import { formatPrice } from "helpers/units"
import {
    Table,
    EmptyList,
    SnakeLoader,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    GridIcon,
    useTableSort,
    TableSortableHeadCell
} from "@butterfly-frontend/ui"
import { Pagination } from "ui"

import { useOrdersList } from "modules/DashboardModule/hooks/api/useOrders"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import { formatDateToApi } from "modules/WmsModule/helpers/date"

import styles from "./OrdersList.module.css"

const TABLE_COLUMNS = {
    salesOrder: { minWidth: 100, width: "14%" },
    client: { minWidth: 100, width: "14%" },
    company: { minWidth: 100, width: "14%" },
    invoice: { minWidth: 80, width: "14%" },
    discount: { minWidth: 80, width: "14%" },
    totalAmount: { minWidth: 110, width: "14%" },
    totalAmountCollected: { minWidth: 120, width: "14%" }
}

const cellBold = {
    root: [styles.cellBold]
}

const cellGrey = {
    root: [styles.cellGrey]
}

const OrdersList = ({ selectedRange, user_id }) => {
    const { sortState, onChangeSort } = useTableSort({
        initialState: { direction: "desc", by: "created_at" }
    })

    const filters = useMemo(
        () => ({
            type: "sales",
            sale_person_id: user_id,
            payments_from: selectedRange.startDate ? formatDateToApi(selectedRange.startDate) : null,
            payments_to: selectedRange.endDate ? formatDateToApi(selectedRange.endDate) : null
        }),
        [selectedRange, user_id]
    )

    const { data, fetchStatus, fetchFlags, pagination } = useTableLogic({
        sort: sortState,
        useQueryList: useOrdersList,
        filters
    })

    return (
        <Fragment>
            {fetchStatus.isLoading && <SnakeLoader classes={{ root: styles.loader }} />}
            {fetchStatus.isLoaded && (
                <Table>
                    <div className={styles.table}>
                        <TableHead>
                            <TableSortableHeadCell
                                {...TABLE_COLUMNS.salesOrder}
                                sortState={sortState}
                                columnKey="id"
                                onClick={onChangeSort}
                            >
                                SALES ORDER
                            </TableSortableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.client}>CLIENT</TableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.company}>COMPANY</TableHeadCell>
                            <TableSortableHeadCell
                                {...TABLE_COLUMNS.invoice}
                                sortState={sortState}
                                columnKey="invoices_count"
                                onClick={onChangeSort}
                            >
                                INVOICE
                            </TableSortableHeadCell>
                            <TableSortableHeadCell
                                {...TABLE_COLUMNS.discount}
                                sortState={sortState}
                                columnKey="discount"
                                onClick={onChangeSort}
                            >
                                DISCOUNT
                            </TableSortableHeadCell>
                            <TableSortableHeadCell
                                {...TABLE_COLUMNS.totalAmount}
                                sortState={sortState}
                                columnKey="total"
                                onClick={onChangeSort}
                            >
                                TOTAL AMOUNT
                            </TableSortableHeadCell>
                            <TableSortableHeadCell
                                {...TABLE_COLUMNS.totalAmountCollected}
                                sortState={sortState}
                                columnKey="applied"
                                onClick={onChangeSort}
                            >
                                TOTAL AMOUNT COLLECTED
                            </TableSortableHeadCell>
                        </TableHead>
                        <TableBody>
                            {fetchFlags.isEmptyList && <EmptyList Icon={GridIcon} label="List is empty." />}
                            {fetchFlags.isEmptyListWithFilter && (
                                <EmptyList Icon={GridIcon} label="No records found for selected filters." />
                            )}
                            {fetchStatus.isLoaded &&
                                data.map(order => (
                                    <TableRow key={order.id}>
                                        <TableCell {...TABLE_COLUMNS.salesOrder}>
                                            <Link to={`/orders/sales-orders/${order.id}`} className={styles.link}>
                                                {order.uuid}
                                            </Link>
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.client}>{order.client.full_name}</TableCell>
                                        <TableCell {...TABLE_COLUMNS.company}>{order.client.company_name}</TableCell>
                                        <TableCell {...TABLE_COLUMNS.invoice} classes={cellGrey}>
                                            {order.invoices_count}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.discount} classes={cellGrey}>
                                            {formatPrice(order.calculations.discount)}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.totalAmount} classes={cellBold}>
                                            {formatPrice(order.calculations.total)}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.totalAmountCollected} classes={cellBold}>
                                            {formatPrice(order.calculations.applied)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                        {pagination.canDisplay && (
                            <div className={styles.paginationWrapper}>
                                <Pagination {...pagination} />
                            </div>
                        )}
                    </div>
                </Table>
            )}
        </Fragment>
    )
}

OrdersList.propTypes = {
    user_id: PropTypes.number,
    selectedRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired
    }).isRequired
}

export default OrdersList
