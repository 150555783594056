import { INPUT_TYPES } from "constants/index"

export default {
    name: "Attribute",
    endpoint: "/attribute-values",
    fields: [
        {
            name: "value",
            label: "Value",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "color",
            label: "Color",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "texture",
            label: "Texture",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: null,
            attributes: {}
        },
        {
            name: "attribute_id",
            label: "Attribute Id",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: "",
            attributes: {}
        }
    ]
}
