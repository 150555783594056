import React from "react"
import PropTypes from "prop-types"

import useStyle from "helpers/getCxFromStyles"
import SubmitModal from "ui/SubmitModal"
import styles from "./DeleteProductsModal.css"

const DeleteProductsModal = props => {
    const cx = useStyle(styles)
    const { title, highligthningBody, isOpen, isLoading, onCancel, onSubmit } = props

    return (
        <SubmitModal
            redButton
            isOpen={isOpen}
            isLoading={isLoading}
            header={title}
            body={
                <span>
                    {"Are you sure you want to delete "}
                    <span className={cx("delete-header-bold")}>
                        {highligthningBody}
                        <span className={cx("delete-header-normal")}>?</span>
                    </span>
                    {" This action cannot be reversed."}
                </span>
            }
            submitText="Delete"
            onClose={onCancel}
            onSubmit={onSubmit}
        />
    )
}

DeleteProductsModal.propTypes = {
    title: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    highligthningBody: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default DeleteProductsModal
