import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import Cover from "../../Cover"

import { Modal, Button } from "ui"

import styles from "./styles.css"

const ChooseImage = ({ isOpen, onClose, cx, propsForm, imageDimensions }) => {
    return (
        <Modal isOpen={isOpen} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Add Image</p>

            <Cover form={propsForm} imageDimensions={imageDimensions} />

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    type="button"
                    label="Next"
                    isDisabled={isDisabled()}
                    onClick={propsForm.handleSubmit}
                />
            </div>
        </Modal>
    )

    function isDisabled() {
        return !!!(propsForm.values.cover_desktop || {}).id
    }
}

ChooseImage.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    imageDimensions: PropTypes.object.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(ChooseImage, styles)
