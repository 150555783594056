import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import styles from "./TemplatesHeader.module.css"

const TemplatesHeader = ({ actionButton, cx, title }) => {
    return (
        <header className={cx("header")}>
            <h2 className={cx("title")}>{title}</h2>
            {actionButton && <div className={cx("buttons")}>{actionButton}</div>}
        </header>
    )
}

TemplatesHeader.propTypes = {
    actionButton: PropTypes.node,
    title: PropTypes.string.isRequired
}

export default withStyles(TemplatesHeader, styles)
