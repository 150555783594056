import React, { Fragment, useEffect, useRef, useState } from "react"
import Cropper from "cropperjs"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"
import fetch from "helpers/fetch"
import { useActions } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import { Button, Modal } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import styles from "./Cropper.css"

const CropperModal = ({ mediaId, onSuccess, onClose }) => {
    const cx = useStyles(styles)
    const imageRef = useRef(null)
    const actions = useActions({ showSuccessNotification, showErrorNotification })
    const [cropper, setCropper] = useState()
    const [media, setMedia] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        fetch
            .get(`/media/${mediaId}`)
            .then(({ data }) => setMedia(data))
            .catch(() => actions.showErrorNotification())
    }, [])

    useEffect(() => {
        !!media &&
            setCropper(
                new Cropper(imageRef.current, {
                    checkCrossOrigin: false,
                    checkOrientation: false,
                    zoomable: true,
                    scalable: false,
                    viewMode: 1
                })
            )
    }, [media])

    const handleSubmit = async () => {
        const { x, y, width, height } = cropper.getData(true)
        setIsSubmitting(true)

        try {
            await fetch.patchRAW(`/media/${media.id}`, {
                manipulations: [
                    {
                        type: "crop",
                        x,
                        y,
                        width: width - 1,
                        height: height - 1
                    }
                ]
            })
            actions.showSuccessNotification()
            onSuccess && onSuccess()
            onClose()
        } catch (error) {
            setIsSubmitting(false)
            actions.showErrorNotification()
        }
    }

    return (
        <Modal closeModal={onClose} isOpen preventClickOutside>
            <Modal.Header>Crop an image</Modal.Header>
            {!!media ? (
                <Fragment>
                    <Modal.Body>
                        <div className={cx("image-wrapper")}>
                            <img src={media.url} alt="" ref={imageRef} />
                        </div>
                    </Modal.Body>
                    <Modal.Actions>
                        <button className={cx("cancel-button")} onClick={onClose}>
                            Cancel
                        </button>
                        <Button
                            className="first-button"
                            onClick={handleSubmit}
                            label="Crop & Save"
                            isLoading={isSubmitting}
                            type="submit"
                        />
                    </Modal.Actions>
                </Fragment>
            ) : (
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            )}
        </Modal>
    )
}

CropperModal.propTypes = {
    mediaId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func
}

export default CropperModal
