import React, { Fragment } from "react"
import PropTypes from "prop-types"

import getCxFromStyles from "helpers/getCxFromStyles"
import { Panel, ShowroomIcon, OnlineIcon } from "@butterfly-frontend/ui"
import SummaryItem from "ui/PaymentSummaryItem"

import styles from "./Summary.module.css"

const Summary = ({ dashboardData, short }) => {
    const cx = getCxFromStyles(styles)

    const CountField = ({ label, count }) => (
        <div className={cx("counter-field")}>
            <span>{label}</span>
            <span>{count}</span>
        </div>
    )

    return (
        <Panel classes={{ panel: styles.summarySection }}>
            {!short && <h3 className={cx("subtitle")}>Sales</h3>}

            <div className={cx(["dashboard-row", "payments-summary"])}>
                <SummaryItem label="Total Invoice Amount:" amount={dashboardData.orders_totals.total_sales_amount} />
                <SummaryItem label="Total Refund Amount:" amount={dashboardData.orders_totals.total_returns_amount} />
                <SummaryItem label="Total Retainer Credit:" amount={dashboardData.orders_totals.total_store_credits} />
                <SummaryItem
                    label="Total Collected:"
                    amount={dashboardData.orders_totals.total_payments}
                    color="green"
                />
            </div>

            {!short && (
                <Fragment>
                    <div className={cx("dashboard-row")}>
                        <CountField count={dashboardData.invoiced.invoices_count} label="# of Invoices" />
                        <CountField count={dashboardData.clients.count} label="# of Clients" />
                        <CountField count={dashboardData.invoiced.fully_paid} label="# of Paid Invoices" />
                    </div>
                    <div className={cx("dashboard-row")}>
                        <CountField count={dashboardData.orders_by_completion.incomplete} label="# of Open SO" />
                        <CountField count={dashboardData.orders_by_completion.finished} label="# of Delivered SO" />
                    </div>
                    <h3 className={cx("subtitle")}>Sale channel</h3>
                    <div className={cx(["dashboard-row", "payments-summary"])}>
                        <SummaryItem
                            LabelComponent={() => (
                                <span className={cx("icon-label")}>
                                    <ShowroomIcon />
                                    <span>Showroom Sales</span>
                                </span>
                            )}
                            amount={dashboardData.sale_channels.showroom}
                        />
                        <SummaryItem
                            LabelComponent={() => (
                                <span className={cx("icon-label")}>
                                    <OnlineIcon />
                                    <span>Online Sales</span>
                                </span>
                            )}
                            amount={dashboardData.sale_channels.web}
                        />
                    </div>
                </Fragment>
            )}
        </Panel>
    )
}

Summary.propTypes = {
    dashboardData: PropTypes.shape({
        orders_totals: PropTypes.shape({
            total_sales_amount: PropTypes.number,
            total_returns_amount: PropTypes.number,
            total_store_credits: PropTypes.number,
            total_payments: PropTypes.number
        }).isRequired,
        invoiced: PropTypes.shape({
            invoices_count: PropTypes.number,
            fully_paid: PropTypes.number
        }).isRequired,
        orders_by_completion: PropTypes.shape({
            incomplete: PropTypes.number,
            finished: PropTypes.number
        }).isRequired,
        sale_channels: PropTypes.shape({
            showroom: PropTypes.number,
            web: PropTypes.number
        }).isRequired
    }).isRequired,
    short: PropTypes.bool
}

export default Summary
