import { ADDRESS_TYPES } from "constants/index"

export function getAddressLines(config, objectValues, separatorArray) {
    return config.map(lineConfig => getAddressLine(lineConfig, objectValues, separatorArray))
}

export function getAddressLine(lineConfig, objectValues, separatorArray = [", ", " "]) {
    const mappedToValues = mapConfigKeysToValues(lineConfig, objectValues)
    return concatWithSeparator(mappedToValues, separatorArray)
}

export function mapConfigKeysToValues(input, objectValues) {
    const isArray = Array.isArray(input)
    if (!objectValues || (typeof input !== "string" && !isArray)) {
        return []
    }

    if (!isArray) {
        return objectValues[input] || ""
    }

    return input.map(value => mapConfigKeysToValues(value, objectValues))
}

export function concatWithSeparator(input, separatorArray = []) {
    const [separator, ...separators] = separatorArray

    if (!Array.isArray(input)) {
        return input
    }

    return input.reduce((acc, value, index) => {
        const isArray = Array.isArray(value)
        if (typeof value !== "string" && !isArray) {
            return acc
        }

        const isLastElement = index === input.length - 1
        const string = typeof value === "string" ? value : concatWithSeparator(value, separators)
        const suffix = isLastElement || !string ? "" : separator || ", "

        return `${acc}${string}${suffix}`
    }, "")
}

export const findAddressByType = ({ type, addresses }) => addresses.find(address => address.type === type)

export const getAddresses = addresses => {
    return Object.keys(ADDRESS_TYPES).reduce((acc, key) => {
        acc[key] = findAddressByType({ type: key, addresses })
        return acc
    }, {})
}
