import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Star2Icon } from "@butterfly-frontend/ui"
import styles from "./PrimaryLabel.module.css"

const PrimaryLabel = ({ classes }) => (
    <div className={classNames(styles.root, classes ? classes.root : "")}>
        <Star2Icon className={classNames(styles.starIcon, classes ? classes.starIcon : "")} />
        PRIMARY
    </div>
)

PrimaryLabel.propTypes = {
    classes: PropTypes.shape({
        root: PropTypes.string,
        starIcon: PropTypes.string
    })
}

export default PrimaryLabel
