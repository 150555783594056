import React, { useState } from "react"
import idx from "idx"

import { SaveBar, Input } from "ui"

import ListingLayout from "../MainPageListingLayout"
import Header from "../../components/Header"
import SectionNameEditModal from "../../components/MainPageNameSectionEditModal"

import inputStyles from "./overrides/Input.css"

const MainPageLink = props => {
    const {
        mainPages: { data },
        modifiedData,
        isModified,
        handleUpdate,
        handleSubmit,
        handleClearChanges,
        isSaving
    } = props

    const bar = (modifiedData || data).bar || {}

    const [errors, setErrors] = useState({})
    const [sectionNameEditModalState, setSectionNameEditModalState] = useState(false)

    const displayTitle = () => idx(data, _ => _.settings.sections.link.label) || "Link"

    const handleUpdateField = (field, value) => {
        setErrors({ ...errors, [field]: null })
        handleUpdate({ ...(modifiedData || data), bar: { ...bar, [field]: value } })
    }

    const handleSectionNameEditClose = () => {
        setSectionNameEditModalState(false)
    }

    const handleSectionNameEditOpen = () => {
        setSectionNameEditModalState(true)
    }

    const handleSectionNameEdit = data => {
        handleSubmit(data, handleSectionNameEditClose)
    }

    const applyErrors = error => {
        setErrors(
            Object.entries(error.errors || {}).reduce((acc, item) => {
                const [field, value] = item

                return {
                    ...acc,
                    [field.replace("bar.", "")]: value[0].replace(/bar\./g, "")
                }
            }, {})
        )
    }

    return (
        <ListingLayout>
            <Header
                title={displayTitle()}
                editButton={{
                    label: "Edit",
                    handler: handleSectionNameEditOpen,
                    isDisabled: false,
                    order: 1
                }}
            />

            <SectionNameEditModal
                isSaving={isSaving}
                data={modifiedData || data}
                sectionName="link"
                defaultValues={{ label: "Link" }}
                handleClose={handleSectionNameEditClose}
                onSubmit={handleSectionNameEdit}
                isOpen={sectionNameEditModalState}
            />

            <Input
                value={bar.text || ""}
                label="Text"
                onChange={({ event }) => handleUpdateField("text", event.target.value)}
                error={errors.text}
                customStyles={inputStyles}
            />

            <Input
                value={bar.link_text || ""}
                label="Link text"
                onChange={({ event }) => handleUpdateField("link_text", event.target.value)}
                error={errors.link_text}
                customStyles={inputStyles}
            />

            <Input
                value={bar.link || ""}
                label="Link"
                onChange={({ event }) => handleUpdateField("link", event.target.value)}
                error={errors.link}
                customStyles={inputStyles}
            />

            <SaveBar
                message="Unpublished changes"
                submitLabel="Publish"
                isShown={isModified}
                isSaving={isSaving}
                onSubmit={() => handleSubmit(null, null, applyErrors)}
                onCancel={handleClearChanges}
            />
        </ListingLayout>
    )
}

export default MainPageLink
