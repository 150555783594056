import React from "react"
import { Link, useParams } from "react-router-dom"

import useStyles from "helpers/getCxFromStyles"
import { DataLayer, ItemList, Navigation } from "modules/AttributeGroupsModules/components"
import { LIST_TYPES } from "modules/AttributeGroupsModules/constants"

import styles from "./SubgroupList.css"

const SubgroupList = () => {
    const cx = useStyles(styles)
    const { groupId } = useParams()

    const getHeaderTitle = resource => (resource && resource.name) || "Attribute subgroups"
    const parseItem = subgroup => ({
        id: subgroup.id,
        name: subgroup.name,
        quantity: subgroup.attributes_count,
        checked: false
    })

    return (
        <DataLayer
            type={LIST_TYPES.SUBGROUP}
            getHeaderTitle={getHeaderTitle}
            placeholderSearch="Search for Attribute Subgroup…"
            parseItem={parseItem}
            resourceChildrenUrl="/attribute-groups"
            resourceChildrenParams={{ parent_attribute_group_id: groupId }}
            resourceChildrenModelName="AttributeGroups"
            resourceUrl={`/attribute-groups/${groupId}`}
            HeaderComponent={() => (
                <div className={cx("headerList")}>
                    <div className={cx("headerListLabel", "name")}>SUBGROUP NAME</div>
                    <div className={cx("headerListLabel", "subgroups")}>ATTRIBUTES</div>
                </div>
            )}
            ItemComponent={({ item, handleAction, handleCheck, isDragDisabled }) => (
                <ItemList
                    item={item}
                    onAction={handleAction}
                    onCheck={() => handleCheck(item.id)}
                    key={item.id}
                    isDragDisabled={isDragDisabled}
                >
                    <div className={cx("item")}>
                        <div className={cx("name")}>
                            <Link to={`/settings/attribute-groups/${groupId}/subgroups/${item.id}/attributes`}>
                                {item.name}
                            </Link>
                        </div>
                        <div className={cx("quantity")}>{item.quantity}</div>
                    </div>
                </ItemList>
            )}
            Navigation={() => (
                <Navigation.ArrowBackButton url="/settings/attribute-groups" label="All Attribute Groups" />
            )}
        />
    )
}

export default SubgroupList
