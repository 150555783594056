import React, { Component, Fragment } from "react"
import { withRouter } from "react-router-dom"
import withPermissions from "HOC/withPermissions"
import withTokenValidation from "HOC/withTokenValidation"

import Header from "./components/Header"
import Sidebar from "./components/Sidebar"
import Content from "./components/Content"
import Notification from "components/Modal/Notification"

import { connect } from "react-redux"
import * as menuActions from "./actions/menu"
import * as clientActions from "./actions/client"
import * as categoryActions from "./actions/category"
import * as brandActions from "./actions/brand"
import * as featureActions from "./actions/feature"

class App extends Component {
    isRedirecting = false

    constructor(props) {
        super(props)

        if (document.location.pathname.indexOf("/scraper") !== -1) {
            this.isRedirecting = true
            document.location.href = "/scraper/"
        }
    }

    componentDidMount() {
        const {
            getClientTypes,
            getCountries,
            getStates,
            getBrands,
            getCategories,
            getFeatures,
            canList,
            canView,
            PERMISSIONS
        } = this.props

        getCountries()
        getStates()

        if (canList(PERMISSIONS.context.CLIENTS) || canView(PERMISSIONS.context.CLIENTS)) {
            getClientTypes()
        }

        if (canList(PERMISSIONS.context.PRODUCTS) || canView(PERMISSIONS.context.PRODUCTS)) {
            getBrands()
            getCategories()
            getFeatures()
        }
    }

    render() {
        const { isSidebarMenuWide, toggleMenu, CustomContentComponent, notification } = this.props

        const ContentComponent = CustomContentComponent ? CustomContentComponent : Content

        if (this.isRedirecting) {
            return null
        }

        return (
            <Fragment>
                <Header changeSidebarMenuWidth={toggleMenu} isSidebarMenuWide={isSidebarMenuWide} />
                <Sidebar isSidebarMenuWide={isSidebarMenuWide} />
                <ContentComponent {...this.props} />
                {notification.isEnabled && <Notification />}
                <div style={{ clear: "both" }} />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSidebarMenuWide: state.menuStore.isSidebarMenuWide,
        notification: state.ui.notification
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleMenu: () => dispatch(menuActions.toggleMenu()),
        getClientTypes: () => dispatch(clientActions.getClientTypes()),
        getCountries: () => dispatch(clientActions.getCountries()),
        getStates: () => dispatch(clientActions.getStates()),
        getCategories: () => dispatch(categoryActions.getCategories()),
        getBrands: () => dispatch(brandActions.getBrands()),
        getFeatures: () => dispatch(featureActions.getFeatures())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTokenValidation(withRouter(withPermissions(App))))
