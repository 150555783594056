import { combineReducers } from "redux"

import attributes from "./attributes"
import attributeValues from "./attributeValues"
import attributesGroups from "./attributesGroups"
import channels from "./channels/index"
import docsTemplates from "./docs-templates"
import emailTemplates from "./email-templates"
import products from "./products/index"
import categories from "./categories"
import clientTypes from "./clientTypes"
import clientGroups from "./clientGroups"
import brands from "./brands"
import brandsScraper from "./brandsScraper"
import features from "./features"
import featureValues from "./featureValues"
import projects from "./projects"
import uploadModule from "./uploadModule"
import combinationTemplates from "./combinationTemplates"
import combinations from "./combinations"
import homePages from "./homePages"
import companies from "./companies/index"
import sendable from "./sendable"
import sources from "./sources/index"
import services from "./services/index"
import orders from "./orders"
import invoices from "./invoices"
import paymentMethods from "./paymentMethods"
import purchaseOrders from "./purchaseOrders"
import news from "./news"
import blog from "./blog"
import portfolio from "./portfolio"
import tags from "./tags"
import payments from "./payments"
import menuCategories from "./menuCategories"
import shippingMethods from "./shippingMethods"
import press from "./press"

import assistances from "modules/ServicesModule/reducers"
import selections from "modules/SelectionsModule/reducers"
import selectionsWidget from "modules/SelectionsWidgetModule/reducers"
import locations from "modules/LocationsModule/reducers"
import partners from "./partners"
import calendar from "modules/CalendarModule/reducers"
import manualEntries from "modules/ManualEntriesModule/reducers"
import users from "./users"

const dbReducer = combineReducers({
    attributes,
    attributeValues,
    attributesGroups,
    blog,
    brands,
    brandsScraper,
    categories,
    channels,
    clientGroups,
    clientTypes,
    combinations,
    combinationTemplates,
    companies,
    docsTemplates,
    emailTemplates,
    calendar,
    features,
    featureValues,
    homePages,
    menuCategories,
    news,
    orders,
    invoices,
    paymentMethods,
    purchaseOrders,
    portfolio,
    projects,
    sendable,
    shippingMethods,
    uploadModule,
    products,
    sources,
    services,
    tags,
    assistances,
    selections,
    selectionsWidget,
    payments,
    press,
    partners,
    users,
    locations,
    manualEntries
})

export default dbReducer
