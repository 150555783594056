import React from "react"

import CreateRoomModal from "./CreateRoomModal"

export default props => {
    if (props.isOpen) {
        return <CreateRoomModal {...props} />
    }

    return null
}
