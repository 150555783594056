import React from "react"

import Layout from "components/Reusable/Layout"
import { Scraper } from "./apps"

import LayoutStyles from "./overrides/layoutStyles.css"

const List = () => {
    return (
        <Layout title="Apps" customStyles={LayoutStyles}>
            <Scraper />
        </Layout>
    )
}

export default List
