import ORDER_TYPE_KEYS from "modules/OrdersModule/constants/orderTypeKeys"
import MEMO_AND_RENTAL_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/memoAndRentalOrderStatusKeys"
import SALES_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/salesOrderStatusKeys"
import ORDER_ITEM_TYPE_KEYS from "modules/OrdersModule/constants/orderItemTypeKeys"

export const checkIfCanCrudOrderItem = ({ orderStatus, orderType, hasInvoice, hasPurchaseOrder }) => {
    if (hasInvoice || hasPurchaseOrder) {
        return false
    }

    if (
        [ORDER_TYPE_KEYS.MEMO, ORDER_TYPE_KEYS.RENTAL].includes(orderType) &&
        orderStatus !== MEMO_AND_RENTAL_ORDER_STATUS_KEYS.NEW
    ) {
        return false
    }

    if (ORDER_TYPE_KEYS.SALES === orderType && SALES_ORDER_STATUS_KEYS.COMPLETED === orderStatus) {
        return false
    }

    return true
}

export const checkIfCanCreatePurchaseOrderForOrderItem = ({ orderItemType, orderStatus }) =>
    orderItemType !== ORDER_ITEM_TYPE_KEYS.IN_STOCK && orderStatus !== SALES_ORDER_STATUS_KEYS.COMPLETED

export const checkIfCanDeleteOrderItem = ({ invoice, purchase_order }) => invoice === null && purchase_order === null
