import React, { useState } from "react"
import { connect } from "react-redux"
import idx from "idx"

import * as notificationActions from "actions/notification"
import fetch from "helpers/fetch"

import Clients from "./Clients"

import env from "env"

const ClientsContainer = props => {
    const { history, showErrorNotification } = props

    const [isModalOpenFor, setIsModalOpenFor] = useState("")
    const [spreadsheetData, setSpreadsheetData] = useState({})
    const [isSpreadsheetSaving, setIsSpreadsheetSaving] = useState(false)

    const closeModal = () => {
        setIsModalOpenFor("")
        setSpreadsheetData({})
    }

    const handleUploadSuccess = data => {
        setSpreadsheetData(data.response)
        setIsModalOpenFor("spreadsheet")
    }

    const handleUploadReject = () => {}

    const handleSaveSpreadsheet = () => {
        setIsSpreadsheetSaving(true)
        fetch
            .patchRAW(`/imports/${spreadsheetData.id}`)
            .then(() => setIsModalOpenFor("confirm"))
            .catch(error => {
                const errorsCount = idx(error, _ => _.errors.errorsCount)
                errorsCount && setSpreadsheetData(oldSpreadsheetData => ({ ...oldSpreadsheetData, errorsCount }))
            })
            .finally(() => setIsSpreadsheetSaving(false))
    }

    const goToClients = () => history.push("/contact")

    return (
        <Clients
            isModalOpenFor={isModalOpenFor}
            closeModal={closeModal}
            goToClients={goToClients}
            endpoint={`${env.API.baseUrl}/imports`}
            importsType="clients"
            handleUploadSuccess={handleUploadSuccess}
            handleUploadFailure={() => showErrorNotification()}
            handleUploadReject={handleUploadReject}
            spreadsheetData={spreadsheetData}
            handleSaveSpreadsheet={handleSaveSpreadsheet}
            isSpreadsheetSaving={isSpreadsheetSaving}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
})

export default connect(
    null,
    mapDispatchToProps
)(ClientsContainer)
