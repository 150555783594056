import { useState } from "react"
import fetch from "helpers/fetch"

const useAddBrand = ({ onSuccess }) => {
    const [brandName, setBrandName] = useState("")
    const [isBrandAdding, setIsBrandAdding] = useState(false)

    const addBrand = () => {
        setIsBrandAdding(true)

        return fetch
            .post("/brands", { name: brandName, custom_vendor: true })
            .then(result => {
                onSuccess(result.data)
                setBrandName("")
            })
            .finally(() => setIsBrandAdding(false))
    }

    return {
        brandName,
        setBrandName,
        isBrandAdding,
        addBrand
    }
}

export default useAddBrand
