import React, { useState, useEffect, Fragment } from "react"

import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import fetch from "helpers/fetch"
import * as notificationActions from "actions/notification"

import * as newsActions from "actions/news"
import * as tagsActions from "actions/tags"

import models from "models"

import Form from "./Form"
import FormAbstractContainer from "components/Content/ContentMain/Settings/FormContainer"

const FormContainer = props => {
    const { isEdit, formFields, handleFieldChange, fillModel, getTags, addTag, createdTags, post, clearPost } = props

    const isPathError = props.post.error && props.post.error.status === 404
    const defaultAddedFile = { name: "", url: "" }
    const [addedFiles, setAddedFiles] = useState({ cover: defaultAddedFile, thumbnail: defaultAddedFile })
    const [quillValue, changeQuillValue] = useState("")
    const [searchTagValue, setSearchTagValue] = useState("")
    const [currentTagsWithName, changeCurrentTagsWithName] = useState([])
    const [isDeleteModalOpen, toggleDeleteModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isConfirmModalOpen, toggleConfirmModal] = useState(false)
    const [isRedirectToNews, redirectToNews] = useState(false)

    useEffect(() => {
        getTags()
    }, [])

    useEffect(() => {
        if (isEdit) {
            fillModel(post)
        }
    }, [post])

    useEffect(() => {
        if (createdTags.id) {
            const { id, name } = createdTags

            handleSelectTag(id, name)
        }
    }, [createdTags])

    useEffect(() => {
        const formFieldsTags = formFields.tags && formFields.tags.value

        if (JSON.stringify(formFieldsTags).includes("name")) {
            const newChosenTag = formFieldsTags.filter(tag => tag.name)
            const tagsWithIdsOnly = formFieldsTags.map(tag => {
                return { id: tag.id }
            })

            changeCurrentTagsWithName([...currentTagsWithName, ...newChosenTag])
            props.handleFieldChange({ event: { target: { value: tagsWithIdsOnly } }, fieldPath: "tags" })
        }
    }, [formFields])

    useEffect(() => () => clearPost(), [])

    const handleUploadSuccess = (state, fieldPath) => {
        const { id: value, url, file } = state.addedFile
        setAddedFiles(oldAddedFiles => ({ ...oldAddedFiles, [fieldPath]: { name: file.name, url } }))

        handleFieldChange({
            event: {
                target: {
                    value
                }
            },
            fieldPath
        })
    }

    const handleRemoveFile = (event, fieldPath) => {
        event.stopPropagation()
        setAddedFiles(oldAddedFiles => ({ ...oldAddedFiles, [fieldPath]: defaultAddedFile }))
        handleFieldChange({
            event: {
                target: {
                    value: null
                }
            },
            fieldPath
        })
    }

    const backToNewsList = () => props.history.push("/news")

    const handleToggleConfirmModal = () => {
        toggleConfirmModal(!isConfirmModalOpen)
    }

    const handleUploadFailure = () => {}

    const handleChangeQuillValue = eventTargetValue => {
        changeQuillValue()

        const value = eventTargetValue === "<p><br></p>" ? "" : eventTargetValue

        props.handleFieldChange({ event: { target: { value: value } }, fieldPath: "description" })
    }

    const handleDeleteTagFromPost = id => {
        const filterListById = list => list.filter(el => el.id !== id)
        const filteredTagsWithName = filterListById(currentTagsWithName)
        const filteredTagsWithoutName = filterListById(formFields.tags.value)

        changeCurrentTagsWithName(filteredTagsWithName)
        props.handleFieldChange({ event: { target: { value: filteredTagsWithoutName } }, fieldPath: "tags" })
    }

    const handleSelectTag = (id, name) => {
        const isTagAbsentInPost = formFields.tags.value.filter(tag => tag.id === id).length === 0

        if (isTagAbsentInPost) {
            props.handleFieldChange({
                event: { target: { value: [...formFields.tags.value, { id, name }] } },
                fieldPath: "tags"
            })
        }
    }

    const handleAddTag = tag => {
        addTag({ name: tag })
    }

    const handleToggleDeleteModal = () => {
        toggleDeleteModal(!isDeleteModalOpen)
    }

    const handleToggleModal = action => {
        if (action === "open") {
            return toggleDeleteModal(true)
        }
        return toggleDeleteModal(false)
    }

    const handleDeletePost = () => {
        setIsDeleting(true)
        fetch.deleteRAW(`/news/${props.match.params.newsId}`).then(() => {
            backToNewsList()
        })
    }

    const handleCancelEdit = () => {
        redirectToNews(true)
    }

    const modalData = {
        delete: {
            title: "Delete this post?",
            description: "Are you sure you want to delete the post",
            subject: props.post && props.post.title,
            descriptionClosure: "? This action cannot be reversed.",
            handleHideModal: handleToggleModal,
            handleDelete: handleDeletePost,
            isLoading: isDeleting
        },
        confirm: {
            title: "You have unsaved changes on this post",
            description:
                "If you leave this post, all unsaved changes will be lost. Are you sure you want to leave this post?",
            actionButtonLabel: "Leave Post",
            handleHideModal: handleToggleConfirmModal,
            handleDelete: handleCancelEdit
        }
    }

    return (
        <Fragment>
            {isPathError && <Redirect to="/404" />}
            {isRedirectToNews && <Redirect to="/news" />}
            <Form
                {...props}
                addedFiles={addedFiles}
                quillValue={quillValue}
                searchTagValue={searchTagValue}
                isDeleteModalOpen={isDeleteModalOpen}
                isConfirmModalOpen={isConfirmModalOpen}
                modalData={modalData}
                isUnsaved={true}
                handleToggleDeleteModal={handleToggleDeleteModal}
                handleChangeQuillValue={handleChangeQuillValue}
                currentTagsWithName={currentTagsWithName}
                handleUploadSuccess={handleUploadSuccess}
                handleRemoveFile={handleRemoveFile}
                handleToggleConfirmModal={handleToggleConfirmModal}
                handleUploadFailure={handleUploadFailure}
                handleDeleteTagFromPost={handleDeleteTagFromPost}
                handleSelectTag={handleSelectTag}
                handleSetSearchTagValue={setSearchTagValue}
                handleAddTag={handleAddTag}
            />
        </Fragment>
    )
}

const mapStateToProps = state => ({
    post: state.db.news.current.data,
    allTags: state.db.tags.list.data,
    createdTags: state.ui.newsModule.data
})

const mapDispatchToProps = dispatch => ({
    getData: id => dispatch(newsActions.getPost(id)),
    clearPost: () => dispatch({ type: "GET_POST_SUCCESS", payload: { data: { data: {} } } }),
    getTags: () => dispatch(tagsActions.getTags()),
    addTag: ({ name }) => dispatch(tagsActions.addTag({ name })),
    showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
    showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    FormAbstractContainer(FormContainer, {
        mapStateToProps,
        mapDispatchToProps,
        idParamName: "newsId",
        model: models.News
    })
)
