import * as constants from "../constants"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    isLoading: false,
    isLoaded: false,
    isError: false,
    fetchStatus: fetchStatus.initialState()
}

function list(state = initialState, action) {
    switch (action.type) {
        case constants.GET_LIST_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isError: false,
                fetchStatus: fetchStatus.requestState()
            }

        case constants.GET_LIST_SUCCESS:
            let locationsList = action.payload.data.data || []
            let locationsMeta = action.payload.data.meta || {}

            return {
                ...state,
                data: locationsList,
                meta: locationsMeta,
                isLoading: false,
                isLoaded: true,
                fetchStatus: fetchStatus.successState()
            }

        case constants.GET_LIST_ERROR:
            return {
                ...state,
                data: [],
                meta: {},
                isLoading: false,
                isLoaded: false,
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }

        case constants.RESET_LIST:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default list
