import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { useField } from "formik"
import idx from "idx"
import { Decimal } from "decimal.js"

import { Section, Address } from "ui/AddEditForm"
import { Input, Select } from "ui"
import SALES_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/salesOrderStatusKeys"
import { getClientAddress } from "modules/OrdersModule/components/Form/helpers/getClientAddress"

import withStyles from "HOC/withStyles"
import styles from "./Financials.css"
import inputStyles from "./overrides/inputStyles.css"
import depositSelect from "./overrides/depositSelect.css"
import currencyStyles from "./overrides/currencyStyles.css"

import {
    DEPOSIT_UNIT_LABELS,
    BILLING_ADDRESS_CONFIG,
    SHIPPING_ADDRESS_CONFIG,
    TAX_UNIT_LABELS,
    DISCOUNT_UNIT_LABELS
} from "./config"
import { DEPOSIT_UNIT_CONFIG } from "../../constants"
import { DEFAULT_CURRENCY_LABEL } from "constants/index"

const Financials = props => {
    const { cx, formik, formConfig, orderData } = props

    const orderIsDelivered = orderData.status === SALES_ORDER_STATUS_KEYS.COMPLETED
    const clientId = idx(formik, _ => _.values.client_id.value)
    const currentClient = useSelector(state => state.clientStore.client)

    const [depositUnit, depositUnitMeta] = useField("deposit_unit")
    const [depositValue, depositValueMeta] = useField("deposit_value")
    const [taxYear, taxYearMeta] = useField("tax_year")
    const [discount, discountMeta] = useField("discount")

    const setDepositUnit = value => depositUnit.onChange({ target: { ...depositUnit, value } })
    const setInputValue = field => ({ event }) => {
        const { name, value } = event.target
        const valueToSet = typeof value === "number" ? Number(value) : value
        field.onChange({ target: { name, value: valueToSet } })
    }

    const disabledAddressMessage = addressName =>
        clientId ? "" : `If you want to add a ${addressName} address, you have to select a client.`

    const clientBillingAddress = useMemo(() => getClientAddress(currentClient.data, "Billing Address"), [
        currentClient.data
    ])
    const clientShippingAddress = useMemo(() => getClientAddress(currentClient.data, "Shipping Address"), [
        currentClient.data
    ])
    const shippingAddress = orderData.shipping_address

    return (
        <div className={cx("root")}>
            <div className={cx("content")}>
                <Section label="Financial Details">
                    <div className={cx("financialsDetailsContent")}>
                        <div className={cx("depositWrapper")}>
                            <div>
                                <label className={cx("financialsDetailsLabel")}>Deposit requested:</label>
                                <Select
                                    isClearBlocked
                                    value={depositUnit.value.label}
                                    values={DEPOSIT_UNIT_CONFIG}
                                    error={depositUnitMeta.error}
                                    setValue={setDepositUnit}
                                    customStyles={depositSelect}
                                    isDisabled={orderIsDelivered}
                                />
                            </div>
                            <div className={cx("depositValueWrapper")}>
                                <Input
                                    type="number"
                                    {...depositValue}
                                    error={depositValueMeta.error}
                                    onChange={setInputValue(depositValue)}
                                    customStyles={inputStyles}
                                    isDisabled={orderIsDelivered}
                                />
                                <span className={cx("depositValueUnit")}>
                                    {DEPOSIT_UNIT_LABELS[depositUnit.value.value]}
                                </span>
                            </div>
                            <div className={cx("taxWrapper")}>
                                <label className={cx("financialsDetailsLabel")}>Tax:</label>
                                <div className={cx("taxCodeValueWrapper")}>
                                    <Input
                                        value={orderData.tax_code ? new Decimal(orderData.tax_code).times(100) : ""}
                                        customStyles={inputStyles}
                                        isDisabled
                                    />
                                    <span className={cx("depositValueUnit")}>{TAX_UNIT_LABELS.percent}</span>
                                </div>
                            </div>
                            <div className={cx("taxYear")}>
                                <label className={cx("financialsDetailsLabel")}>Tax year:</label>
                                <Input
                                    {...taxYear}
                                    type="number"
                                    onChange={setInputValue(taxYear)}
                                    customStyles={inputStyles}
                                    error={taxYearMeta.error}
                                    isDisabled={orderIsDelivered}
                                />
                            </div>
                        </div>
                        <div className={cx("row")}>
                            <div>
                                <label className={cx("financialsDetailsLabel")}>Currency:</label>
                                <Select
                                    isDisabled
                                    isClearBlocked
                                    value={DEFAULT_CURRENCY_LABEL}
                                    values={[]}
                                    setValue={() => {}}
                                    customStyles={currencyStyles}
                                />
                            </div>
                            <div className={cx("discountWrapper")}>
                                <label className={cx("financialsDetailsLabel")}>Discount:</label>
                                <div className={cx("discountValueWrapper")}>
                                    <Input
                                        {...discount}
                                        type="number"
                                        error={discountMeta.error}
                                        onChange={setInputValue(discount)}
                                        customStyles={inputStyles}
                                        isDisabled={orderIsDelivered}
                                    />
                                    <span className={cx("depositValueUnit")}>{DISCOUNT_UNIT_LABELS.percent}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Section>
                {formConfig.hasBillingAddress && (
                    <Address
                        label="Billing Address"
                        copyFrom="Client"
                        fetchStatus={currentClient}
                        copyAddress={clientBillingAddress}
                        config={BILLING_ADDRESS_CONFIG}
                        disabledMessage={disabledAddressMessage("billing")}
                        isDisabled={orderIsDelivered}
                    />
                )}
                {orderData.in_house_sale ? (
                    <Section label="Shipping Address" className={cx("showroomSaleAddress")}>
                        <strong>Showroom Sale</strong>
                        <br />
                        <br />
                        <p>{shippingAddress.address}</p>
                        <p>
                            {shippingAddress.city ? `${shippingAddress.city}, ` : ""}
                            {shippingAddress.state ? `${shippingAddress.state} ` : ""}
                            {shippingAddress.post_code ? `${shippingAddress.post_code} ` : ""}
                        </p>
                        <p>{shippingAddress.country}</p>
                    </Section>
                ) : (
                    <Address
                        label="Shipping Address"
                        copyFrom="Client"
                        fetchStatus={currentClient}
                        copyAddress={clientShippingAddress}
                        config={SHIPPING_ADDRESS_CONFIG}
                        disabledMessage={disabledAddressMessage("shipping")}
                        isDisabled={orderIsDelivered}
                    />
                )}
            </div>
        </div>
    )
}

export default withStyles(Financials, styles)
