const TABLE_COLUMNS = {
    no: { minWidth: 40, width: 40 },
    product: { minWidth: 100, width: "22%" },
    id: { minWidth: 50, width: "8%" },
    condition: { minWidth: 200, width: "47%" },
    location: { minWidth: 70, width: "8%" },
    packAction: { minWidth: 260, width: "20%" }
}

export default TABLE_COLUMNS
