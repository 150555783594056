import fetch from "helpers/fetch"
import { useQuery } from "react-query"
import { serializeQuery } from "helpers/urls"

export const KEY = "dashboard"

export const fetchDashboard = ({ sale_person_id = "", date_from = "", date_to = "" }) => {
    const queryString = serializeQuery({ sale_person_id, date_from, date_to }, "", true)

    return fetch.get(`/dashboard?${queryString}`)
}

export const useDashboard = filters => {
    return useQuery(
        [
            KEY,
            {
                ...filters
            }
        ],
        () => fetchDashboard(filters),
        {
            refetchOnWindowFocus: false,
            retry: false
        }
    )
}
