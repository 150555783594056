import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./Form.css"
import moduleSectionStyles from "./overrides/ModuleSection.css"

import Section from "components/Reusable/Form/Section/index"
import Layout from "components/Reusable/Layout/index"
import Input from "components/Reusable/Form/Input/index"
import Button from "components/Reusable/Form/Button/index"

class Form extends Component {
    render() {
        const { isEdit, cx, handleSubmitForm, isSaving, isFilled, history, formFields } = this.props
        const { name } = formFields

        return (
            <Layout title={isEdit ? "Edit Division" : "New Division"} customStyles={styles}>
                <div className={cx("form-container", { "form-isFilling": isEdit && !isFilled })}>
                    <Section>
                        <Input {...name} />
                    </Section>
                    <Section customStyles={moduleSectionStyles}>
                        <Button
                            className={["first-button", "submit-button"]}
                            customStyles={styles}
                            onClick={event => handleSubmitForm(event)}
                            label={isEdit ? "Save" : "Create"}
                            isLoading={isSaving}
                            isDisabled={name.value.length === 0}
                        />
                        <span className={cx("form-cancel")} onClick={() => history.goBack()}>
                            Cancel
                        </span>
                    </Section>
                </div>
            </Layout>
        )
    }
}

export default withStyles(Form, styles)
