import React, { Component, Fragment } from "react"
import withStyles from "HOC/withStyles"
import styles from "./Form.css"
import formSectionsData from "../config"
import Section from "components/Reusable/Form/Section/index"
import FormSection from "../FormSection/FormSection"
import Input from "components/Reusable/Form/Input/index"
import Button from "components/Reusable/Form/Button/index"
import { scrollToError } from "helpers/scrollTo"

class Form extends Component {
    render() {
        const formSectionsKeys = Object.keys(formSectionsData)
        const {
            formFields: { name, permissions },
            handleFieldChange,
            choosePermission,
            allPermissions,
            isEdit,
            isSaving,
            onSubmit,
            onEdit,
            history,
            cx
        } = this.props

        return (
            <Fragment>
                <form className={cx("permissions-form")}>
                    <Input
                        {...name}
                        isPlaceholder={false}
                        isInline={false}
                        customStyles={styles}
                        className={"name-input"}
                        onChange={handleFieldChange}
                        handleScrollToError={scrollToError}
                    />
                    <Section>
                        <h2 className={cx("permissions-title")}>{permissions.label}</h2>
                        <ul className={cx("permissions-list")}>
                            {formSectionsKeys.map(key => (
                                <FormSection
                                    key={key}
                                    details={formSectionsData[key]}
                                    allPermissions={allPermissions}
                                    permissions={permissions.value}
                                    choosePermission={choosePermission}
                                />
                            ))}
                        </ul>
                        <div className={cx("clear")} />
                    </Section>
                    <Button
                        className={["first-button", "save-button"]}
                        customStyles={styles}
                        onClick={event => (isEdit ? onEdit(event) : onSubmit(event))}
                        label={isEdit ? "Save" : "Create"}
                        isLoading={isSaving}
                    />
                    <span className={cx("form-cancel", "cancel")} onClick={() => history.goBack()}>
                        Cancel
                    </span>
                </form>
            </Fragment>
        )
    }
}

export default withStyles(Form, styles)
