import * as constants from "constants/index"

function clearance(state = "", action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_CLEARANCE:
            return ""
        case constants.CHANGE_CLEARANCE:
            return value
        default:
            return state
    }
}

export default clearance
