import SHIPMENT_TYPE_KEYS from "./shipmentTypeKeys"
import SHIPMENT_TYPES from "./shipmentTypes"

const SHIPMENT_TYPE_SELECT_OPTIONS = [
    SHIPMENT_TYPES[SHIPMENT_TYPE_KEYS.TRANSFER],
    SHIPMENT_TYPES[SHIPMENT_TYPE_KEYS.SHIPMENT],
    SHIPMENT_TYPES[SHIPMENT_TYPE_KEYS.RETURN],
    SHIPMENT_TYPES[SHIPMENT_TYPE_KEYS.PO],
    SHIPMENT_TYPES[SHIPMENT_TYPE_KEYS.CLIENT_RETURN]
]

export default SHIPMENT_TYPE_SELECT_OPTIONS
