import * as Yup from "yup"
import moment from "moment"
import { TEXTAREA_MAX_LENGTH, INPUT_MAX_LENGTH, ADDRESS_REGEX, API_DATE_FORMAT, API_YEAR_FORMAT } from "constants/index"

export const depositUnits = { percent: "percent", amount: "value" }
export const DEPOSIT_UNIT_CONFIG = [
    { label: "Percent", value: depositUnits.percent },
    { label: "Amount", value: depositUnits.amount }
]

const currency = "dollar"
const address = {
    copy: true,
    company: "",
    first_name: "",
    last_name: "",
    country: "",
    address: "",
    post_code: "",
    city: "",
    state: ""
}

export const INITIAL_VALUES = {
    client_id: {},
    issued_at: moment().format(API_DATE_FORMAT),
    revised_at: null,
    valid_date: null,
    delivery_date: null,
    description: "",
    instructions: "",
    attachments: [],
    deposit_unit: DEPOSIT_UNIT_CONFIG[0],
    deposit_value: 10,
    currency,
    billing_address: address,
    shipping_address: address,
    users: [],
    tax_code: null,
    tax_code_manually: true,
    tax_year: moment().format(API_YEAR_FORMAT),
    discount: null,
    company: {}
}

const personValidation = (valueMethod = "nullable", message) =>
    Yup.object().shape({
        value: Yup.number()[valueMethod](message),
        label: Yup.string()
    })
const inputValidation = Yup.string()
    .max(INPUT_MAX_LENGTH)
    .nullable()
const textareaValidation = Yup.string()
    .max(TEXTAREA_MAX_LENGTH)
    .nullable()
const depositUnitRegex = value => new RegExp(`(${DEPOSIT_UNIT_CONFIG.map(unit => unit[value]).join("|")})`)
const addressValidation = Yup.object().shape({
    copy: Yup.boolean(),
    company: inputValidation,
    first_name: inputValidation,
    last_name: inputValidation,
    country: inputValidation,
    address: inputValidation,
    post_code: inputValidation,
    city: inputValidation,
    state: inputValidation
})
const dateValidation = Yup.string()
    .matches(ADDRESS_REGEX, "Date format is invalid.")
    .nullable()

export const VALIDATION_SCHEMA = Yup.object().shape({
    client_id: personValidation("required", "Client is a required field."),
    issued_at: dateValidation,
    revised_at: dateValidation,
    valid_date: dateValidation,
    delivery_date: dateValidation,
    description: textareaValidation,
    instructions: textareaValidation,
    attachments: Yup.array().of(
        Yup.object().shape({
            id: Yup.number()
        })
    ),
    deposit_unit: Yup.object().shape({
        label: Yup.string()
            .matches(depositUnitRegex("label"), { excludeEmptyString: true })
            .nullable(),
        value: Yup.string()
            .matches(depositUnitRegex("value"), { excludeEmptyString: true })
            .nullable()
    }),
    deposit_value: Yup.number().nullable(),
    currency: Yup.string().matches(new RegExp(`(${currency})`)),
    billing_address: addressValidation,
    shipping_address: addressValidation
})
