import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import _capitalize from "lodash/capitalize"
import { getIn } from "formik"

import { DEFAULT_COUNTRY, PERMISSIONS } from "constants/index"
import { Input, Toggle, SelectWithSearch } from "ui"
import getInputPropsFactory from "modules/WmsModule/helpers/getInputPropsFactory"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import { FormSection, FormRow, FormColumn } from "modules/WmsModule/modals/components"
import withPermissions from "HOC/withPermissions"

import styles from "./ContactSectionForm.module.css"

const WAREHOUSE_TYPES_SELECT_VALUES = [{ label: "Normal", value: "normal" }, { label: "Virtual", value: "virtual" }]

const selectValuesAdapter = values => values.map(value => ({ value, label: value }))

const capitalizeValue = ({ value, ...restProps }) => ({
    value: _capitalize(value),
    ...restProps
})

const ContactSectionForm = ({ formik, countriesList, statesList, canEdit, checkPermission, isEdit }) => {
    const canSetPrimaryWarehouse = checkPermission(PERMISSIONS.types.SELECT_PRIMARY)

    const headerInputs = (
        <Toggle
            isOn={formik.values.primary}
            handleChange={value => {
                formik.setFieldValue("primary", value)
            }}
            labelPosition="left"
            label={{ on: "Primary", off: "Primary" }}
            isDisabled={formik.isSubmitting || formik.initialValues.primary || !canSetPrimaryWarehouse}
            isSmall
        />
    )

    return (
        <FormSection title="Contact" inputs={headerInputs} withBorder>
            {({ inputClass }) => {
                const getInputProps = getInputPropsFactory(formik, inputClass)
                const getSelectProps = getSelectPropsFactory(formik, styles.selectError)

                return (
                    <React.Fragment>
                        <FormRow>
                            <FormColumn>
                                <Input
                                    {...getInputProps("name")}
                                    label="Warehouse name"
                                    customPlaceholder="Name"
                                    isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                />
                            </FormColumn>
                            <FormColumn>
                                <Input
                                    {...getInputProps("code")}
                                    label="ID"
                                    customPlaceholder="ID"
                                    isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                />
                            </FormColumn>
                        </FormRow>
                        <FormRow>
                            <FormColumn>
                                <Input
                                    {...getInputProps("address.street")}
                                    label="Street and number"
                                    customPlaceholder="Address"
                                    isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                />
                            </FormColumn>
                            <FormColumn>
                                <Input
                                    {...getInputProps("phone", {
                                        onChange: ({ event }) => formik.setFieldValue("phone", event.target.value)
                                    })}
                                    label="Warehouse phone number"
                                    customPlaceholder="Phone number"
                                    isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                    isPhoneInput
                                />
                            </FormColumn>
                        </FormRow>
                        <FormRow>
                            <FormColumn className={styles.cityInputColumn}>
                                <div className={styles.cityInputContainer}>
                                    <Input
                                        {...getInputProps("address.city")}
                                        label="City"
                                        customPlaceholder="City"
                                        isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                    />
                                </div>
                                <div className={styles.zipSectionContainer}>
                                    <div className={styles.stateInputContainer}>
                                        {getIn(formik.values, "address.country") === DEFAULT_COUNTRY ? (
                                            <SelectWithSearch
                                                {...getSelectProps("address.state", {
                                                    setValue: async ({ value }) => {
                                                        await formik.setFieldValue("address.state", value)
                                                        formik.setFieldTouched("address.state")
                                                    }
                                                })}
                                                label="ZIP"
                                                placeholder="State"
                                                values={selectValuesAdapter(statesList)}
                                                isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                                withoutFetch
                                            />
                                        ) : (
                                            <Input
                                                {...getInputProps("address.state")}
                                                label="ZIP"
                                                customPlaceholder="State"
                                                isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                            />
                                        )}
                                    </div>
                                    <div className={styles.zipInputContainer}>
                                        <Input
                                            {...getInputProps("address[post_code]", {
                                                className: classNames(inputClass, styles.zipInput)
                                            })}
                                            customPlaceholder="ZIP"
                                            isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                        />
                                    </div>
                                </div>
                            </FormColumn>
                            <FormColumn>
                                <Input
                                    {...getInputProps("email")}
                                    label="Warehouse e-mail"
                                    customPlaceholder="E-mail"
                                    isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                />
                            </FormColumn>
                        </FormRow>
                        <FormRow>
                            <FormColumn>
                                <SelectWithSearch
                                    {...getSelectProps("address.country", {
                                        setValue: async ({ value }) => {
                                            await formik.setFieldValue("address.country", value)
                                            formik.setFieldTouched("address.country")
                                        }
                                    })}
                                    label="Country"
                                    placeholder="Country"
                                    values={selectValuesAdapter(countriesList)}
                                    isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                    withoutFetch
                                />
                            </FormColumn>
                            <FormColumn className={styles.selectInputColumn}>
                                <SelectWithSearch
                                    {...capitalizeValue(
                                        getSelectProps("type", {
                                            setValue: async ({ value }) => {
                                                await formik.setFieldValue("type", value)
                                                formik.setFieldTouched("type")
                                            }
                                        })
                                    )}
                                    label="Warehouse type"
                                    values={WAREHOUSE_TYPES_SELECT_VALUES}
                                    isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                    isClearBlocked
                                    withoutFetch
                                />
                            </FormColumn>
                        </FormRow>
                    </React.Fragment>
                )
            }}
        </FormSection>
    )
}

ContactSectionForm.propTypes = {
    formik: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool,
        values: PropTypes.shape({
            primary: PropTypes.bool,
            name: PropTypes.string,
            code: PropTypes.string,
            phone: PropTypes.string,
            email: PropTypes.string,
            type: PropTypes.oneOf(["virtual", "normal"]),
            address: PropTypes.shape({
                street: PropTypes.string,
                city: PropTypes.string,
                country: PropTypes.string,
                post_code: PropTypes.string,
                state: PropTypes.string
            })
        }),
        errors: PropTypes.shape({
            primary: PropTypes.string,
            name: PropTypes.string,
            code: PropTypes.string,
            phone: PropTypes.string,
            email: PropTypes.string,
            type: PropTypes.string,
            address: PropTypes.shape({
                street: PropTypes.string,
                city: PropTypes.string,
                country: PropTypes.string,
                post_code: PropTypes.string,
                state: PropTypes.string
            })
        })
    }),
    countriesList: PropTypes.arrayOf(PropTypes.string).isRequired,
    statesList: PropTypes.arrayOf(PropTypes.string).isRequired,
    isEdit: PropTypes.bool,
    canEdit: PropTypes.func.isRequired,
    checkPermission: PropTypes.func.isRequired
}

export default withPermissions(ContactSectionForm, PERMISSIONS.context.WMS)
