import React, { Component, createRef } from "react"
import styles from "./searchbar.module.css"
import search from "assets/search.svg"
import close from "assets/close.svg"
import arrow from "assets/arrow.svg"
import withStyles from "HOC/withStyles"
import Checkbox from "components/Reusable/Form/Checkbox"
import Button from "components/Reusable/Form/Button"
import Input from "components/Reusable/Form/Input"
import Combobox from "components/Reusable/Form/Combobox"

import { SelectWithSearch, Toggle } from "ui"
import { displayUserName } from "helpers/user"

import selectStyles from "./overrides/Select.css"
import inputStyles from "./overrides/Input.css"
import checkboxStyles from "./overrides/Checkbox.css"
import toggleStyles from "./overrides/Toggle.css"

class SearchBar extends Component {
    state = {
        query: this.props.filter
    }

    inputRef = createRef()

    handleClearInput = () => {
        this.props.clearInput()
        this.inputRef.current && this.inputRef.current.focus()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filter.length !== 0 && this.props.filter.length === 0) {
            this.setState({
                query: ""
            })
        }
    }

    render() {
        const {
            cx,
            filter = "",
            clearInput,
            placeholder,
            isExpanded,
            isAdvanced,
            handleToggleIsExpanded,
            handleSearch,
            filtersCount
        } = this.props

        const { query } = this.state

        return (
            <div className={cx("root", { isFilled: !!filter, isExpanded: isAdvanced && isExpanded, isAdvanced })}>
                <input
                    ref={this.inputRef}
                    tabIndex="-1"
                    type="text"
                    className={cx("input")}
                    placeholder=""
                    value={query}
                    onChange={event => this.setState({ query: event.target.value })}
                    onKeyUp={event => handleSearch(event)}
                    required="required"
                />
                <img src={search} alt="search" className={cx("search-icon")} />
                {!isAdvanced && filter.length > 0 && (
                    <img
                        src={close}
                        alt="close"
                        className={cx("close-icon", { isAdvanced })}
                        onClick={this.handleClearInput}
                    />
                )}
                {isAdvanced && (
                    <span
                        className={cx("arrow", { isUp: isExpanded, hasFilters: filtersCount > 0 })}
                        onClick={event => {
                            event.stopPropagation()
                            handleToggleIsExpanded()
                        }}
                    >
                        {filter.length > 0 && (
                            <img
                                src={close}
                                alt="close"
                                className={cx("close-icon", { isAdvanced })}
                                onClick={event => {
                                    event.stopPropagation()
                                    clearInput()
                                }}
                            />
                        )}
                        {filtersCount > 0 && <span>{filtersCount}</span>}
                        <span onClick={handleToggleIsExpanded}>
                            <img src={arrow} alt="expand" />
                        </span>
                    </span>
                )}
                {placeholder && (
                    <div className={cx("placeholder")}>
                        <h3>{placeholder}</h3>
                    </div>
                )}

                {isAdvanced && this.renderAdvanced()}
            </div>
        )
    }

    renderAdvanced() {
        const {
            cx,
            clientTypesList,
            statesList,
            countriesList,
            users,
            formFields,
            isAssignedToOn,
            handleToggleIsAssigned,
            handleToggleIsExpanded,
            handleChooseClientTypes,
            handleFieldChange,
            handleClearAllFilters,
            handleSearch,
            filtersCount,
            group_id,
            company_client_id,
            channel_id,
            service_id
        } = this.props

        const hasOtherFilter =
            group_id.data.length > 0 && service_id.data.length > 0 && company_client_id.data.length > 0

        let currentUser = users.find(el => el.id === formFields.assigned_to.value)
        currentUser = currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : ""

        return (
            <div className={cx("panel")}>
                <header className={cx("header")}>
                    <h3>
                        Filters
                        {filtersCount > 0 && <div>{filtersCount}</div>}
                    </h3>
                    {filtersCount > 0 && (
                        <span
                            className={cx("reset")}
                            onClick={() => {
                                handleClearAllFilters().then(() => {
                                    handleSearch()
                                })
                            }}
                        >
                            Clear all filters
                        </span>
                    )}
                </header>
                <div className={cx("filters")}>
                    <div className={cx("filterHolder")}>
                        <div className={cx("advanced-column")}>
                            <div className={cx("group", "assignedTo")}>
                                {users && !users.length && <label>Assigned to</label>}
                                <Toggle
                                    isOn={isAssignedToOn}
                                    label={{
                                        on: "Only mine",
                                        off: "Only mine"
                                    }}
                                    labelPosition="left"
                                    handleChange={handleToggleIsAssigned}
                                    customStyles={toggleStyles}
                                />
                                {users && !!users.length && (
                                    <SelectWithSearch
                                        {...formFields.assigned_to}
                                        customStyles={selectStyles}
                                        value={currentUser}
                                        values={
                                            users || !isAssignedToOn
                                                ? [
                                                      { value: null, label: "-" },
                                                      ...users.map(user => ({
                                                          value: user.id,
                                                          label: displayUserName(user)
                                                      }))
                                                  ]
                                                : []
                                        }
                                        label="Assigned to"
                                        setValue={({ value }) =>
                                            handleFieldChange({
                                                event: { target: { value } },
                                                fieldPath: formFields.assigned_to.fieldPath
                                            })
                                        }
                                        isClear={true}
                                        isDisabled={isAssignedToOn}
                                        withoutFetch
                                    />
                                )}
                            </div>
                            <div className={cx("group", "location")}>
                                <h3 className={cx("group-title", "group-title-first")}>Location:</h3>
                                <div className={cx("group-inputs", "location")}>
                                    <Input
                                        customStyles={inputStyles}
                                        {...formFields.city}
                                        isPlaceholder={true}
                                        label="City"
                                        onChange={handleFieldChange}
                                        isClear={true}
                                    />
                                    <div className={cx("zipState")}>
                                        <Input
                                            customStyles={inputStyles}
                                            {...formFields.post_code}
                                            isPlaceholder={true}
                                            label="ZIP code"
                                            onChange={handleFieldChange}
                                            isClear={true}
                                        />
                                        <Combobox
                                            {...formFields.state}
                                            defaultValue="State"
                                            isInline={true}
                                            values={statesList.map((element, index) => {
                                                return {
                                                    value: element,
                                                    label: element
                                                }
                                            })}
                                            onClick={handleFieldChange}
                                            isClear={true}
                                        />
                                    </div>
                                    <div className={cx(`country`)}>
                                        <Combobox
                                            {...formFields.country}
                                            defaultValue="Country"
                                            isInline={false}
                                            values={countriesList.map((element, index) => {
                                                return {
                                                    value: element,
                                                    label: element
                                                }
                                            })}
                                            onClick={handleFieldChange}
                                            isClear={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={cx("filterHolder")}>
                        <div className={cx("advanced-column", "type")}>
                            <div className={cx("group", "type")}>
                                <h3 className={cx("group-title", "group-title-first")}>Type Of Contact:</h3>
                                {clientTypesList &&
                                    clientTypesList.map(type => {
                                        return (
                                            <Checkbox
                                                key={type.id}
                                                id={"types_" + type.id}
                                                customStyles={checkboxStyles}
                                                name={type.name}
                                                checked={formFields.types.value.includes(type.id)}
                                                onChange={id =>
                                                    handleChooseClientTypes(parseInt(id.replace("types_", ""), 10))
                                                }
                                            />
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                    {hasOtherFilter && (
                        <div className={cx("filterHolder")}>
                            <div className={cx("advanced-column", "other")}>
                                {this.renderSelect("Interaction", group_id.data, "group_id", true)}
                                {this.renderSelect("Division", company_client_id.data, "company_client_id")}
                                {this.renderSelect("Service", service_id.data, "service_id")}
                                {this.renderSelect("Communication channel", channel_id.data, "channel_id")}
                            </div>
                        </div>
                    )}
                    <div className={cx("filterHolder")}>
                        <div className={cx("advanced-column", "status")}>
                            <div className={cx("group")}>
                                <h3 className={cx("group-title", "group-title-first")}>Client's status:</h3>
                                {["active", "deleted", "online_enabled"].map(key => (
                                    <Checkbox
                                        {...(formFields[key] ? formFields[key] : {})}
                                        key={key}
                                        id={key}
                                        customStyles={checkboxStyles}
                                        checked={formFields[key].value}
                                        name={formFields[key].label}
                                        onChange={() => {
                                            handleFieldChange({
                                                event: {
                                                    target: {
                                                        value: !formFields[key].value
                                                    }
                                                },
                                                fieldPath: formFields[key].fieldPath
                                            })
                                        }}
                                    />
                                ))}
                            </div>
                            <div className={cx("other-filters")}>
                                {this.renderSelect(
                                    "Verification",
                                    [{ id: "true", name: "Verified" }, { id: "false", name: "Not verified" }],
                                    "verified"
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cx("buttons")}>
                    <span
                        className={cx("form-cancel")}
                        onClick={() => {
                            handleToggleIsExpanded()
                        }}
                    >
                        Cancel
                    </span>
                    <Button
                        className={["first-button", "submit-button"]}
                        customStyles={styles}
                        onClick={() => {
                            handleSearch(null, true)
                            handleToggleIsExpanded()
                        }}
                        label="Search"
                        isLoading={false}
                    />
                </div>
            </div>
        )
    }

    renderSelect = (title, list = [], key, isFirst = false) => {
        const { cx, handleFieldChange, formFields } = this.props
        if (list.length === 0) {
            return null
        }

        let label = ""
        const foundSelectedEl = list.find(el => el.id === formFields[key].value)
        if (foundSelectedEl) {
            label = foundSelectedEl.name
        }

        return (
            <div className={cx("group")}>
                <h3 className={cx("group-title", isFirst ? "group-title-first" : "")}>{title}:</h3>
                <Combobox
                    {...formFields[key]}
                    values={list.map(el => {
                        const { id, name } = el
                        return {
                            id,
                            label: name
                        }
                    })}
                    defaultKey="id"
                    value={label}
                    defaultValue="- Please select -"
                    onClick={event =>
                        handleFieldChange({
                            event: {
                                target: {
                                    value: event.event.target.id
                                }
                            },
                            fieldPath: key
                        })
                    }
                    isClear={true}
                />
            </div>
        )
    }
}

export default withStyles(SearchBar, styles)
