import React, { useCallback } from "react"
import idx from "idx"

import {
    checkIfCanCreatePurchaseOrderForOrderItem,
    checkIfCanCrudOrderItem
} from "modules/OrdersModule/helpers/orderItem"
import { Checkbox, EditableProductListItem } from "ui"
import Info from "../../../Info"
import withStyles from "HOC/withStyles"
import styles from "./List.css"

const summaryConfig = isService =>
    isService
        ? {
              "Original service name:": ({ product }) => product.name,
              "Description:": ({ product }) => product.description
          }
        : {
              "Vendor:": ({ product }) => product.brand_name,
              "Original product name:": ({ product }) => product.name
          }

const List = props => {
    const {
        cx,
        isUpdating,
        editedId,
        expandedIds,
        order,
        checkedList,
        handleAction,
        updateErrors,
        canCreate,
        orderItemHasExternalActions,
        orderItems,
        returnShipmentList
    } = props

    const toggleCheckboxes = id => handleAction("toggleCheckboxes", id)
    const toggleAllCheckBox = () => handleAction("toggleAllCheckBox")
    const onToggleDetails = id => handleAction("toggleDetails", id)
    const onToggleEdit = id => handleAction("toggleEdit", id)
    const onDelete = id => handleAction("delete", id)
    const onSubmitEdit = (id, payload) => handleAction("submitEdit", id, payload)

    const stateBuilder = useCallback(productItem => ({ ...order, items: [productItem] }), [order])

    const isAllChecked = !!checkedList.length
    const isMinusIcon = isAllChecked && checkedList.length < order.items.length

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <div className={cx("column", "left")}>
                    <Checkbox checked={isAllChecked} isMinusIcon={isMinusIcon} onChange={toggleAllCheckBox} />
                    <div>Product name</div>
                </div>
                <div className={cx("column", "right")}>
                    <div>Type</div>
                    <div>Item price</div>
                    <div>Qty</div>
                    <div>Discount</div>
                    <div>Tax</div>
                    <div>Item total</div>
                </div>
            </div>
            <ul>
                {orderItems.map((product, index, array) => {
                    const { id, room_name, invoice, purchase_order, type } = product

                    const isService = !!idx(product, _ => _.product.is_assistance)
                    const isEdited = editedId === id
                    const isEditAndDeleteDisabled = !checkIfCanCrudOrderItem({
                        hasPurchaseOrder: !!purchase_order,
                        hasInvoice: !!invoice,
                        orderStatus: order.status,
                        orderType: order.type
                    })

                    const customParam = { key: "Room", value: room_name }
                    const renderDetails = expandedIds.includes(id)
                        ? () => <Info config={summaryConfig(isService)} product={product} />
                        : null

                    const getExternalActionValues = values => values || { id: null, uuid: null }
                    const externalActionsConfig = orderItemHasExternalActions && {
                        purchaseOrder: !isService && {
                            ...getExternalActionValues(purchase_order),
                            stateBuilder,
                            canCreate:
                                canCreate &&
                                checkIfCanCreatePurchaseOrderForOrderItem({
                                    orderItemType: type,
                                    orderStatus: order.status
                                })
                        },
                        invoice: { ...getExternalActionValues(invoice), stateBuilder, canCreate }
                    }

                    const returnShipments = returnShipmentList.data.filter(({ stock_items_ids }) =>
                        product.returned_stock_items.some(returnedStockItemId =>
                            stock_items_ids.includes(returnedStockItemId)
                        )
                    )

                    return (
                        <EditableProductListItem
                            key={index}
                            isService={isService}
                            isChecked={checkedList.includes(id)}
                            isEdited={isEdited}
                            isSaving={isUpdating && isEdited}
                            isLast={array.length === index + 1}
                            isEditDisabled={isEditAndDeleteDisabled}
                            isDeleteDisabled={isEditAndDeleteDisabled}
                            item={product}
                            returnShipments={returnShipments}
                            isReturnShipmentsLoading={returnShipmentList.fetchStatus.isLoading}
                            customParam={customParam}
                            renderDetails={renderDetails}
                            externalActionsConfig={externalActionsConfig}
                            onCheck={() => toggleCheckboxes(id)}
                            onToggleDetails={() => onToggleDetails(id)}
                            onToggleEdit={() => onToggleEdit(id)}
                            onDelete={() => onDelete(id)}
                            onSubmitEdit={onSubmitEdit}
                            errors={updateErrors}
                            parent={order}
                        />
                    )
                })}
            </ul>
        </div>
    )
}

export default withStyles(List, styles)
