import React, { Component } from "react"
import { connect } from "react-redux"

import { PERMISSIONS } from "constants/index"

const mapStateToProps = state => {
    return {
        permissions: state.loginStore.permissions
    }
}

const withPermissions = (WrappedComponent, containerContext) => {
    class Enhance extends Component {
        canAccess = context => {
            return this.checkPermission(PERMISSIONS.types.ACCESS, context)
        }

        canList = context => {
            return this.checkPermission(PERMISSIONS.types.LIST, context)
        }

        canView = context => {
            return this.checkPermission(PERMISSIONS.types.VIEW, context)
        }

        canEdit = context => {
            return this.checkPermission(PERMISSIONS.types.EDIT, context)
        }

        canAdd = context => {
            return this.checkPermission(PERMISSIONS.types.ADD, context)
        }

        canDelete = context => {
            return this.checkPermission(PERMISSIONS.types.DELETE, context)
        }

        canRestore = context => {
            return this.checkPermission(PERMISSIONS.types.RESTORE, context)
        }

        checkPermission = (type, context) => {
            const { permissions } = this.props
            return (
                permissions &&
                (permissions.includes(`${type} ${context}`) || permissions.includes(`${type} ${containerContext}`))
            )
        }

        checkGlobalPermission = context => {
            const { permissions } = this.props
            return permissions && (permissions.includes(context) || permissions.includes(containerContext))
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    canAccess={this.canAccess}
                    canList={this.canList}
                    canView={this.canView}
                    canEdit={this.canEdit}
                    canAdd={this.canAdd}
                    canRestore={this.canRestore}
                    checkGlobalPermission={this.checkGlobalPermission}
                    checkPermission={this.checkPermission}
                    PERMISSIONS={PERMISSIONS}
                    canDelete={this.canDelete}
                />
            )
        }
    }

    return connect(mapStateToProps)(Enhance)
}

export default withPermissions
