import React, { Component } from "react"

import withStyles from "HOC/withStyles"

import { Input, Toggle, SelectWithSearch } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import { DEFAULT_COUNTRY, ADDRESS_TYPES } from "constants/index"

import styles from "./address.module.css"
import customToggleStyles from "./overrides/toggle.module.css"

class Address extends Component {
    render() {
        const {
            cx,
            errors,
            setValues,
            setPostCode,
            setCity,
            setStreet,
            selectedCountry,
            postCode,
            city,
            countriesList,
            setCountry,
            setFieldValue,
            values,
            customInputStyles,
            shownAddresses,
            getPostCode,
            isPostCodeLoading
        } = this.props

        return values.addresses.map((addr, index) => {
            const { name, first_name, last_name, type, company, address, state } = addr
            const addressError = (errors.addresses && errors.addresses[index]) || {}
            const isAlternativeAddress = type !== "personal"

            if (isAlternativeAddress && !shownAddresses[type]) {
                return (
                    <section key={type}>
                        <h2>{name}</h2>
                        {this.renderToggle({ isOn: true, name, type, index, setFieldValue, values })}
                    </section>
                )
            }

            return (
                <section key={type} className={cx("address", { alternative: isAlternativeAddress })}>
                    {isAlternativeAddress && this.renderToggle({ isOn: false, name, type, index })}
                    <h2>{name}</h2>
                    <ul>
                        {isAlternativeAddress && (
                            <li className={cx({ company: !isAlternativeAddress })}>
                                <Input
                                    customStyles={customInputStyles}
                                    type="text"
                                    value={company}
                                    name={`company`}
                                    id="company"
                                    label="Company name"
                                    onChange={params => setValues("company", params.event.target.value, index)}
                                />
                            </li>
                        )}
                        {isAlternativeAddress && (
                            <li className={cx("centered")}>
                                <Input
                                    customStyles={customInputStyles}
                                    type="text"
                                    value={first_name}
                                    name={`first_name`}
                                    id={`${type}FirstName`}
                                    label="First name"
                                    onChange={params => setValues("first_name", params.event.target.value, index)}
                                />
                            </li>
                        )}
                        {isAlternativeAddress && (
                            <li>
                                <Input
                                    customStyles={customInputStyles}
                                    type="text"
                                    value={last_name}
                                    name={`last_name`}
                                    id={`${type}LastName`}
                                    label="Last name"
                                    onChange={params => setValues("last_name", params.event.target.value, index)}
                                />
                            </li>
                        )}
                        <li>
                            <SelectWithSearch
                                label="Country"
                                values={countriesList}
                                isListOnTop={name === ADDRESS_TYPES.invoice}
                                value={selectedCountry[index]}
                                setValue={({ value }) => setCountry(value, index)}
                                withoutFetch
                            />
                        </li>
                        <li className={cx("street")}>
                            <Input
                                customStyles={customInputStyles}
                                type="text"
                                value={address}
                                name={`address`}
                                id="address"
                                label="Address (Street and no.)"
                                error={addressError.address}
                                onChange={params => setStreet(params.event.target.value, index)}
                            />
                        </li>
                        <li>
                            <Input
                                customStyles={customInputStyles}
                                type="text"
                                value={city[index]}
                                name={`city`}
                                id="city"
                                label="City"
                                error={addressError.city}
                                onChange={params => setCity(params.event.target.value, index)}
                            />
                        </li>
                        {this.renderAmericanState({
                            index,
                            state,
                            addressError,
                            isAlternativeAddress
                        })}
                        <li className={cx("zip")}>
                            <Input
                                customStyles={customInputStyles}
                                type="text"
                                value={postCode[index]}
                                name={`post_code`}
                                id="post_code"
                                label="ZIP code"
                                error={addressError.address}
                                onChange={params => setPostCode(params.event.target.value, index)}
                                onFocus={() => getPostCode(index)}
                                isDisabled={isPostCodeLoading}
                            />
                            {isPostCodeLoading && (
                                <div className={cx("zipLoader")}>
                                    <SnakeLoader />
                                </div>
                            )}
                        </li>
                    </ul>
                </section>
            )
        })
    }

    renderAmericanState({ index, state, addressError, isAlternativeAddress = false }) {
        const {
            cx,
            setValues,
            selectedAmericanState,
            statesList,
            selectedCountry,
            setAmericanState,
            customInputStyles
        } = this.props

        if (selectedCountry[index] === DEFAULT_COUNTRY) {
            return (
                <li className={cx("centered")}>
                    <SelectWithSearch
                        label="State"
                        values={statesList}
                        isListOnTop={isAlternativeAddress}
                        value={selectedAmericanState[index]}
                        setValue={({ value }) => setAmericanState(value, index)}
                        withoutFetch
                    />
                </li>
            )
        }

        return (
            <li className={cx("centered")}>
                <Input
                    customStyles={customInputStyles}
                    type="text"
                    value={state}
                    name="state"
                    id="state"
                    label="State"
                    error={addressError.state}
                    onChange={params => setValues("state", params.event.target.value, index)}
                />
            </li>
        )
    }

    renderToggle({ isOn, type, name, index }) {
        const { setSameAddressAsMain } = this.props

        return (
            <Toggle
                key={`toggle-${type}-${isOn}`}
                labelPosition="left"
                isOn={isOn}
                customStyles={customToggleStyles}
                label={{ on: "Same as Main Address", off: "Not same as Main Address" }}
                handleChange={isSame => setSameAddressAsMain(type, name, index, isSame)}
            />
        )
    }
}

export default withStyles(Address, styles)
