import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import List from "../TemplatesList"
import Header from "../TemplatesHeader"
import ListingLayout from "../ListingLayout"
import AddEditPaymentMethod from "./components/AddEditPaymentMethod"
import DeletePaymentMethod from "./components/DeletePaymentMethod"
import { ActionButton, EmptyList } from "ui"
import CardChargeFeeContainer from "../CardChargeFee/CardChargeFeeContainer"
import styles from "./PaymentMethods.module.css"
import actionButtonStyles from "./overrides/ActionButton.css"
import addIcon from "assets/add.svg"
import editIcon from "assets/pencil.svg"
import deleteIcon from "assets/close-blue.svg"
import walletIcon from "assets/ico-wallet.svg"

const PaymentMethods = ({ cx, paymentMethods }) => {
    const [isModalToAddOpen, setModalToAddOpen] = useState(false)
    const [paymentMethodToEdit, setPaymentMethodToEdit] = useState(null)
    const [paymentMethodToDelete, setPaymentMethodToDelete] = useState(null)

    const handleCloseModalToAdd = () => setModalToAddOpen(false)

    const headerActionButton = (
        <ActionButton
            handleClick={() => setModalToAddOpen(true)}
            label="Add new"
            icon={addIcon}
            customStyles={actionButtonStyles}
        />
    )

    const renderTableButtonsActions = ({ id, in_use: inUse, name }) => {
        return (
            <Fragment>
                <ActionButton
                    label="Delete"
                    icon={deleteIcon}
                    disabled={inUse}
                    handleClick={() => setPaymentMethodToDelete({ id, name })}
                    className={cx("deleteButton")}
                />
                <ActionButton label="Edit" icon={editIcon} handleClick={() => setPaymentMethodToEdit({ id, name })} />
            </Fragment>
        )
    }

    return (
        <Fragment>
            <ListingLayout>
                <section className={cx("root")}>
                    <Header title={"Payments methods"} actionButton={headerActionButton} />
                    <List
                        renderActionsButtons={renderTableButtonsActions}
                        renderEmptyList={() => <EmptyList message="No payment method added." icon={walletIcon} />}
                        tableData={paymentMethods}
                        tableHeader="method name"
                    />
                </section>
            </ListingLayout>
            {isModalToAddOpen && <AddEditPaymentMethod handleClose={handleCloseModalToAdd} />}
            {paymentMethodToEdit && (
                <AddEditPaymentMethod
                    edit={true}
                    handleClose={() => setPaymentMethodToEdit(null)}
                    initialValues={paymentMethodToEdit}
                />
            )}
            {paymentMethodToDelete && (
                <DeletePaymentMethod
                    handleClose={() => setPaymentMethodToDelete(null)}
                    id={paymentMethodToDelete.id}
                    name={paymentMethodToDelete.name}
                />
            )}
            <CardChargeFeeContainer />
        </Fragment>
    )
}

PaymentMethods.propTypes = {
    paymentMethods: PropTypes.object.isRequired
}

export default withStyles(PaymentMethods, styles)
