import { useMemo } from "react"
import { useDispatch } from "react-redux"

const useActions = (actionObject, customDispatch) => {
    const reduxDispatch = useDispatch()
    const dispatch = useMemo(() => (typeof customDispatch === "function" ? customDispatch : reduxDispatch), [
        customDispatch,
        reduxDispatch
    ])
    const actions = useMemo(
        () =>
            Object.entries(actionObject).reduce((acc, [actionName, action]) => {
                return { ...acc, [actionName]: (...args) => dispatch(action(...args)) }
            }, {}),
        [dispatch]
    )

    return actions
}

export default useActions
