import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { AlertOkIcon, StateOffIcon } from "@butterfly-frontend/ui"
import styles from "./WarehouseStatus.module.css"

const WarehouseStatus = ({ active }) => (
    <div className={styles.status}>
        {active ? (
            <Fragment>
                <AlertOkIcon className={styles.activeIcon} />
                Active
            </Fragment>
        ) : (
            <Fragment>
                <StateOffIcon className={styles.inactiveIcon} />
                Inactive
            </Fragment>
        )}
    </div>
)

WarehouseStatus.defaultProps = {
    active: false
}

WarehouseStatus.propTypes = {
    active: PropTypes.bool
}

export default WarehouseStatus
