export const EVENT_TYPES = {
    ORDER: "order",
    PROJECT: "project",
    INVOICE: "invoice",
    PURCHASE_ORDER: "purchase_order",
    PAYMENT: "payment",
    CLIENT_BIRTHDAY: "client",
    CLIENT_MEETING: "meeting",
    DAYS_OFF: "days_off",
    EMPLOYEE_BIRTHDAY: "user",
    CUSTOM: "custom"
}

export const EVENT_TYPES_ORDER = {
    SALES: "sales",
    RENTAL: "rental",
    MEMO: "memo"
}

export const INVOICE_ORDER_TYPES = {
    SALES: "sales",
    RENTAL: "rental"
}
