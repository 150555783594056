import React, { useReducer } from "react"
import { connect } from "react-redux"

import fetch from "helpers/fetch"
import * as featureActions from "actions/feature"
import * as notificationActions from "actions/notification"
import Features from "./Features"

const itemsReducer = (state, action) => {
    if (action.type === "setValues") {
        const { index, values: nextValues } = action.payload

        return {
            ...state,
            [index]: {
                ...state[index],
                ...nextValues
            }
        }
    }

    if (action.type === "reset") {
        return {
            ...action.payload
        }
    }

    return state
}

const FeaturesContainer = props => {
    const [itemsState, dispatchItemAction] = useReducer(itemsReducer, {}, initItemsState)

    const featuresLength = props.values.features ? props.values.features.length : 0
    if (Object.keys(itemsState).length !== featuresLength) {
        dispatchItemAction({ type: "reset", payload: initItemsState({}) })
    }

    return (
        <Features
            {...props}
            itemsState={itemsState}
            dispatchItemAction={dispatchItemAction}
            handleSaveFeature={handleSaveFeature}
            handleSaveFeatureValue={handleSaveFeatureValue}
        />
    )

    function initItemsState(initialState) {
        return props.values.features
            ? props.values.features
                  .map(() => ({ featureQuery: "", featureValueQuery: "", isLoading: false }))
                  .reduce((acc, item, index) => {
                      acc[index] = item
                      return acc
                  }, initialState)
            : []
    }

    function handleSaveFeature(index) {
        showLoadingForItemAtIndex(index)

        return fetch
            .postRAW("/product-features", {
                name: itemsState[index].featureQuery
            })
            .then(
                data => {
                    props.getFeatures().finally(() => {
                        data.json()
                            .then(json => {
                                dispatchItemAction({
                                    type: "setValues",
                                    payload: {
                                        index,
                                        values: {
                                            featureQuery: "",
                                            featureValueQuery: ""
                                        }
                                    }
                                })
                                props.setFieldValue(
                                    "features",
                                    Object.assign([], props.values.features, {
                                        [index]: {
                                            ...props.values.features[index],
                                            feature_id: json.data.id
                                        }
                                    })
                                )
                            })
                            .finally(() => {
                                hideLoadingForItemAtIndex(index)
                                props.showSuccessNotification()
                            })
                    })
                },
                () => {
                    hideLoadingForItemAtIndex(index)
                    props.showErrorNotification()
                }
            )
    }

    function handleSaveFeatureValue(index, feature_id) {
        showLoadingForItemAtIndex(index)

        return fetch
            .postRAW("/product-feature-values", {
                product_feature_id: feature_id,
                value: itemsState[index].featureValueQuery
            })
            .then(
                data => {
                    props.getFeatures().finally(() => {
                        data.json()
                            .then(json => {
                                dispatchItemAction({
                                    type: "setValues",
                                    payload: {
                                        index,
                                        values: {
                                            featureQuery: "",
                                            featureValueQuery: ""
                                        }
                                    }
                                })
                                props.setFieldValue(
                                    "features",
                                    Object.assign([], props.values.features, {
                                        [index]: {
                                            ...props.values.features[index],
                                            feature_value_id: json.data.id
                                        }
                                    })
                                )
                            })
                            .finally(() => {
                                hideLoadingForItemAtIndex(index)
                                props.showSuccessNotification()
                            })
                    })
                },
                () => {
                    hideLoadingForItemAtIndex(index)
                    props.showErrorNotification()
                }
            )
    }

    function showLoadingForItemAtIndex(index) {
        dispatchItemAction({ type: "setValues", payload: { index, values: { isLoading: true } } })
    }

    function hideLoadingForItemAtIndex(index) {
        dispatchItemAction({ type: "setValues", payload: { index, values: { input: "", isLoading: false } } })
    }
}

const mapStateToProps = state => {
    return {
        features: state.db.features.list
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getFeatures: () => dispatch(featureActions.getFeatures()),
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FeaturesContainer)
