import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"

import rawFetch from "isomorphic-fetch"

import fetch from "helpers/fetch"
import env from "env"

const AWS_FETCH_OPTIONS = { method: "HEAD", cache: "no-cache" }
const RELOAD_TIMES = 3
const TIME_INTERVAL = 3000

const { API } = env

const NotAwsUrlHandlerWrap = ({ image, children, onError }) => {
    const isNotAws = typeof image === "string" && image.search(API.baseUrl) !== -1

    const [url, setUrl] = useState(isNotAws ? null : image)

    useEffect(() => {
        if (isNotAws) {
            fetchText(image)
                .then(awsUrl => setUrl(awsUrl))
                .catch(error => !!onError && onError(error))
        } else {
            setUrl(image)
        }
    }, [image])

    return <ReloadableImageWrap image={url} children={children} onError={onError} />

    function fetchText(url) {
        return fetch.baseGetRAW(url).then(data => data.text())
    }
}

const ReloadableImageWrap = ({ image, children, onError }) => {
    const [url, setUrl] = useState(image)

    useEffect(() => {
        let timeout = null

        if (!!image) {
            fetchImage(0)
        }

        function fetchHandler() {
            return awsFetch(image)
                .then(res => {
                    setUrl(res.ok ? image : null)
                    return res
                })
                .catch(error => clearTimeout(timeout) || (!!onError && onError(error)))
        }

        function fetchImage(counter) {
            if (counter > RELOAD_TIMES) {
                return
            }

            fetchHandler().then(res => {
                if (!res.ok) {
                    timeout = setTimeout(() => fetchImage(counter + 1), TIME_INTERVAL)
                }
            })
        }

        return () => clearTimeout(timeout)
    }, [image])

    return <Fragment>{children({ image: url })}</Fragment>

    function awsFetch(awsUrl) {
        return rawFetch(awsUrl, AWS_FETCH_OPTIONS)
    }
}

NotAwsUrlHandlerWrap.propTypes = {
    image: PropTypes.string,
    onError: PropTypes.func
}

export default NotAwsUrlHandlerWrap
