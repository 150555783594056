import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./Combobox.css"
import clear from "assets/delete.svg"

class Combobox extends Component {
    static defaultProps = {
        onClick: () => {},
        defaultValue: "-"
    }

    node = null
    state = {
        searchPhrase: this.props.value || "",
        isExpanded: false
    }

    render() {
        const { cx, defaultValue, isClear } = this.props
        const { searchPhrase, isExpanded } = this.state
        return (
            <div className={cx("root", { isExpanded: isExpanded })} ref={node => (this.node = node)}>
                <input
                    tabIndex="-1"
                    type="text"
                    placeholder={defaultValue}
                    value={searchPhrase}
                    onChange={e => this.handleChange(e)}
                />
                {((isClear && searchPhrase === "") || !isClear) && (
                    <span className={cx("trigger")} onClick={() => this.toggle()} />
                )}
                {isClear && searchPhrase !== "" && (
                    <img
                        src={clear}
                        alt="clear"
                        className={cx("clear")}
                        onClick={() => this.handleSelect({ label: "" })}
                    />
                )}
                {this.renderList()}
            </div>
        )
    }

    componentDidMount() {
        document.addEventListener("click", this.handleOutsideClick, false)
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({
                searchPhrase: nextProps.value
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener("click", this.handleOutsideClick, false)
    }

    toggle() {
        this.setState(prevState => {
            return {
                ...prevState,
                isExpanded: !prevState.isExpanded
            }
        })
    }

    handleOutsideClick = event => {
        if (!this.node.contains(event.target)) {
            this.setState({
                isExpanded: false
            })
        }
    }

    handleChange = e => {
        this.setState({
            searchPhrase: e.target.value,
            isExpanded: true
        })
    }

    renderList() {
        const { cx, values } = this.props
        const { searchPhrase } = this.state

        return (
            <ul className={cx("list")}>
                {values
                    .filter(
                        item =>
                            searchPhrase.length === 0 ||
                            (searchPhrase.length > 0 && item.label.toLowerCase().includes(searchPhrase.toLowerCase()))
                    )
                    .map((item, index) => {
                        return (
                            <li key={index} onClick={() => this.handleSelect(item)}>
                                {item.label}
                            </li>
                        )
                    })}
            </ul>
        )
    }

    handleSelect = item => {
        const { onClick, fieldPath } = this.props
        onClick &&
            onClick({
                event: {
                    target: {
                        value: item.label,
                        id: item.value
                    }
                },
                fieldPath
            })

        this.setState({
            searchPhrase: item.label,
            isExpanded: false
        })
    }
}

export default withStyles(Combobox, styles)
