import React, { useMemo } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import _capitalize from "lodash/capitalize"

import {
    Panel,
    H1,
    H3,
    DetailsCard,
    ItemsCounter,
    Button,
    DeleteIcon,
    EditIcon,
    BoxIcon,
    CheckboxSquare
} from "@butterfly-frontend/ui"
import { PrimaryLabel, WarehouseStatus, DateLabel } from "modules/WmsModule/components"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import styles from "./WarehouseDetails.module.css"

const WarehouseDetails = ({ warehouse, onEdit, canEdit, canView, checkPermission, canDelete, canAccess, onDelete }) => {
    const canEditDefaultForPO = checkPermission(PERMISSIONS.types.SELECT_DEFAULT_FOR_PO)
    const canSetPrimaryWarehouse = checkPermission(PERMISSIONS.types.SELECT_PRIMARY)

    const canEditWarehouse = useMemo(() => canEditDefaultForPO || canSetPrimaryWarehouse || canEdit(), [
        canEditDefaultForPO,
        canSetPrimaryWarehouse,
        canEdit
    ])

    return (
        <div className={styles.container}>
            <div className={styles.detailsHeader}>
                <H1>{warehouse.name}</H1>
                <span className={styles.warehouseId}>#{warehouse.code} </span>
                {warehouse.primary && <PrimaryLabel />}
                {canDelete() && (
                    <Button Icon={DeleteIcon} variant="flat" onClick={onDelete}>
                        Delete
                    </Button>
                )}
                {canEditWarehouse && canView() && (
                    <Button Icon={EditIcon} variant="flat" onClick={onEdit}>
                        Edit
                    </Button>
                )}
            </div>
            {canView() && (
                <Panel>
                    <div className={styles.detailsPanel}>
                        <DetailsCard header="CREATED DATE">
                            <DateLabel date={warehouse.created_at} />
                        </DetailsCard>
                        <div className={styles.column}>
                            <DetailsCard header="TYPE">{_capitalize(warehouse.type)}</DetailsCard>
                            <DetailsCard header="STATUS">
                                <WarehouseStatus active={warehouse.active} />
                            </DetailsCard>
                        </div>
                        <div className={styles.column}>
                            <DetailsCard header="PURPOSE">
                                {warehouse.is_default_for_po ? (
                                    <div className={styles.flagWithIcon}>
                                        <BoxIcon className={styles.boxIcon} />
                                        Default for PO
                                    </div>
                                ) : (
                                    "-"
                                )}
                            </DetailsCard>
                            <DetailsCard header="REMOTE">
                                {warehouse.autonomous ? (
                                    <div className={styles.flagWithIcon}>
                                        <CheckboxSquare checked />
                                        <span className={styles.remoteText}>Autonomous</span>
                                    </div>
                                ) : (
                                    "-"
                                )}
                            </DetailsCard>
                        </div>
                        <AddressDetails warehouse={warehouse} />
                        <ManagerDetails manager={warehouse.manager} />
                    </div>
                </Panel>
            )}
            <div className={styles.spacer} />
            <ItemsDetails
                counters={_get(warehouse, "items_count_per_status", {})}
                warehouseId={warehouse.id}
                canAccess={canAccess}
            />
        </div>
    )
}

const AddressDetails = ({ warehouse }) => (
    <DetailsCard header="WAREHOUSE ADDRESS">
        <p>{warehouse.address.street}</p>
        <p>
            {warehouse.address.city}, {warehouse.address.state} {warehouse.address.post_code}
        </p>
        <p>{warehouse.address.country}, </p>
        <br />
        <p>phone number: {_get(warehouse, "phone", "-")}, </p>
        <p>e-mail: {warehouse.email}, </p>
    </DetailsCard>
)

const ManagerDetails = ({ manager }) => (
    <DetailsCard header="WAREHOUSE MANAGER">
        <p>{manager.name}</p>
        <p>{manager.job_title}</p>
        <br />
        <p>mobile: {_get(manager, "mobile", "-")}, </p>
        <p>phone number: {_get(manager, "phone", "-")}, </p>
        <p>e-mail: {manager.email}, </p>
    </DetailsCard>
)

const ItemsDetails = ({ counters, warehouseId, canAccess }) => (
    <Panel>
        <div className={styles.countersPanel}>
            <div className={styles.countersHeader}>
                <H3>Items in warehouse</H3>
                {canAccess() && (
                    <Button
                        type="link"
                        variant="flat"
                        size="small"
                        href={`/wms/stock-management?warehouses=${warehouseId}`}
                    >
                        Stock Management
                    </Button>
                )}
            </div>
            <div className={styles.countersContainer}>
                <div className={styles.countersWrapper}>
                    <ItemsCounter header="Available" count={_get(counters, "available", 0)} />
                </div>
                <div className={styles.countersWrapper}>
                    <ItemsCounter header="In stock" count={_get(counters, "in_stock", 0)} />
                </div>
                <div className={styles.countersWrapper}>
                    <ItemsCounter header="Committed" count={_get(counters, "committed", 0)} />
                </div>
                <div className={styles.countersWrapper}>
                    <ItemsCounter header="Incoming" count={_get(counters, "incoming", 0)} />
                </div>
            </div>
        </div>
    </Panel>
)

WarehouseDetails.propTypes = {
    warehouse: PropTypes.shape({
        id: PropTypes.number.isRequired,
        primary: PropTypes.bool.isRequired,
        is_default_for_po: PropTypes.bool,
        autonomous: PropTypes.bool,
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        phone: PropTypes.string,
        email: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        type: PropTypes.oneOf(["virtual", "normal"]).isRequired,
        address: PropTypes.shape({
            street: PropTypes.string.isRequired,
            city: PropTypes.string.isRequired,
            country: PropTypes.string.isRequired,
            post_code: PropTypes.string.isRequired,
            state: PropTypes.string.isRequired
        }),
        manager: PropTypes.shape({
            name: PropTypes.string.isRequired,
            job_title: PropTypes.string.isRequired,
            phone: PropTypes.string,
            mobile: PropTypes.string,
            email: PropTypes.string.isRequired
        }),
        items_count_per_status: PropTypes.shape({
            available: PropTypes.number,
            in_stock: PropTypes.number,
            committed: PropTypes.number,
            incoming: PropTypes.number,
            on_hold: PropTypes.number
        })
    }),
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    canEdit: PropTypes.func.isRequired,
    canView: PropTypes.func.isRequired,
    checkPermission: PropTypes.func.isRequired,
    canDelete: PropTypes.func.isRequired
}

export default withPermissions(WarehouseDetails, PERMISSIONS.context.WMS)
