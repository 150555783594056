import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import fetch from "helpers/fetch"

import * as clientActions from "actions/client"

import { EditableList } from "ui"

import ListingLayout from "../../OtherListingLayout/index"

const Listing = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const getClientTypes = () => dispatch(clientActions.getClientTypes())

    const clientTypes = useSelector(state => state.db.clientTypes.list.data)
    const isLoading = useSelector(state => state.db.clientTypes.list.isLoading)

    useEffect(() => {
        getClientTypes()
    }, [])

    const handleAdd = () => {
        history.push("/settings/types/add")
    }

    const handleEdit = item => {
        history.push(`/settings/types/card/${item.id}/edit`)
    }

    const handleDelete = item => {
        fetch.deleteRAW(`/client-types/${item.id}`).then(() => {
            getClientTypes()
        })
    }

    const columns = [
        {
            name: "name",
            field: "name"
        }
    ]

    return (
        <ListingLayout>
            <EditableList
                title="Type of Contact"
                isLoading={isLoading}
                columns={columns}
                data={clientTypes}
                dataModel="ClientTypes"
                fetchData={getClientTypes}
                isIndexColumn
                onAdd={handleAdd}
                onEdit={handleEdit}
                onDelete={handleDelete}
                confirmModalTitle="Are you sure you want to delete this type?"
                showDeleteCondition={values => values.clients_count === 0}
            />
        </ListingLayout>
    )
}

export default Listing
