import React from "react"
import ChooseProductFromExistingOrder from "./ChooseProductFromExistingOrder"
import Modal from "ui/Modal"

import modalStyles from "./overrides/Modal.css"

const ChooseProductModal = props => {
    const { isOpen, handleClose } = props

    return (
        <Modal isOpen={isOpen} customStyles={modalStyles} closeModal={handleClose} preventClickOutside={true}>
            <ChooseProductFromExistingOrder {...props} />
        </Modal>
    )
}

export default ChooseProductModal
