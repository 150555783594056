const initialState = {
    partnerId: null,
    data: null
}

const setPartnertId = partnerId => ({ type: "setPartnertId", partnerId })
const setDataToVerify = data => ({ type: "setDataToVerify", data })
const clearVerifyState = () => ({ type: "clearVerifyState" })

const reducer = (state, action) => {
    switch (action.type) {
        case setPartnertId().type:
            return { ...state, partnerId: action.partnerId }
        case setDataToVerify().type:
            return { ...state, data: action.data }
        case clearVerifyState().type:
            return initialState
        default:
            return state
    }
}

export { initialState, reducer, setPartnertId, setDataToVerify, clearVerifyState }
