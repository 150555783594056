import React from "react"
import PropTypes from "prop-types"

import {
    Panel,
    H3,
    Table,
    TableHead,
    TableSortableHeadCell,
    TableHeadCell,
    TableBody,
    TableCell,
    TableRow,
    Status
} from "@butterfly-frontend/ui"
import { Pagination } from "ui"
import { SnakeLoader } from "ui/Skeleton"

import styles from "./StockTransferDetailsItemsList.module.css"

const CELL_WIDTH_12 = {
    width: "12%",
    minWidth: 100
}

const StockTransferDetailsItemsList = ({ transferredItems, sortState, onChangeSort, pagination, isLoading }) => (
    <div className={styles.container}>
        <Panel classes={{ panel: styles.panel }}>
            <H3 classes={{ h3: styles.h3 }}>Items</H3>
            <Table>
                <TableHead>
                    <TableSortableHeadCell
                        columnKey="product"
                        sortState={sortState}
                        onClick={onChangeSort}
                        minWidth={200}
                        width="40%"
                    >
                        PRODUCT NAME
                    </TableSortableHeadCell>
                    <TableSortableHeadCell
                        columnKey="brand"
                        sortState={sortState}
                        onClick={onChangeSort}
                        {...CELL_WIDTH_12}
                    >
                        BRAND NAME
                    </TableSortableHeadCell>
                    <TableSortableHeadCell
                        columnKey="id"
                        sortState={sortState}
                        onClick={onChangeSort}
                        {...CELL_WIDTH_12}
                    >
                        ID
                    </TableSortableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_12}>CONDITION</TableHeadCell>
                    <TableSortableHeadCell
                        columnKey="warehouse"
                        sortState={sortState}
                        onClick={onChangeSort}
                        {...CELL_WIDTH_12}
                    >
                        WAREHOUSE
                    </TableSortableHeadCell>
                    <TableSortableHeadCell
                        columnKey="location"
                        sortState={sortState}
                        onClick={onChangeSort}
                        {...CELL_WIDTH_12}
                    >
                        LOCATION
                    </TableSortableHeadCell>
                </TableHead>
                {isLoading ? (
                    <div className={styles.loader}>
                        <SnakeLoader customStyles={styles.loader} />
                    </div>
                ) : (
                    <TableBody>
                        {transferredItems.map(item => (
                            <TableRow classNames={{ root: styles.tableRow }} key={item.id}>
                                <TableCell minWidth={200} width="40%">
                                    {item.product.name}
                                </TableCell>
                                <TableCell {...CELL_WIDTH_12}>{item.brand.name}</TableCell>
                                <TableCell {...CELL_WIDTH_12}>{item.id}</TableCell>
                                <TableCell {...CELL_WIDTH_12}>
                                    <Status color={item.condition.color}>{item.condition.name}</Status>
                                </TableCell>
                                <TableCell {...CELL_WIDTH_12}>{item.warehouse.name}</TableCell>
                                <TableCell {...CELL_WIDTH_12}>{item.location ? item.location.name : "-"}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
                {pagination.canDisplay && (
                    <div className={styles.paginationWrapper}>
                        <Pagination {...pagination} />
                    </div>
                )}
            </Table>
        </Panel>
    </div>
)

StockTransferDetailsItemsList.propTypes = {
    transferredItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            product: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            brand: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            condition: PropTypes.shape({
                color: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired
            }).isRequired,
            warehouse: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            location: PropTypes.shape({
                name: PropTypes.string.isRequired
            })
        })
    ).isRequired,
    sortState: PropTypes.shape({
        by: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired
    }).isRequired,
    onChangeSort: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        canDisplay: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    }).isRequired,
    isLoading: PropTypes.bool.isRequired
}

export default StockTransferDetailsItemsList
