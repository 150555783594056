import * as yup from "yup"

import { INPUT_MAX_LENGTH } from "constants/index"

const LINK_TYPE = {
    LINK: "link",
    PRODUCT: "product"
}

const FORM_TYPE = {
    SINGLE: "single",
    DOUBLE: "double"
}

const verifyLinkType = yup.string().matches(/(link|product)/)

const verifyUrl = fieldName =>
    yup
        .string()
        .url(`${fieldName} is not correct url`)
        .nullable()

const verifyInput = fieldName =>
    yup
        .string()
        .max(INPUT_MAX_LENGTH, `${fieldName} may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .nullable()

const linkExternalSchema = verifyUrl("Link").when("product_link_type", {
    is: LINK_TYPE.LINK,
    then: verifyUrl("Link").required("This field is required")
})

const secondLinkExternalSchema = verifyUrl("Link").when(["second_product_link_type", "type"], {
    is: (linkType, formType) => linkType === LINK_TYPE.LINK && formType === FORM_TYPE.DOUBLE,
    then: verifyUrl("Link").required("This field is required")
})

export const settings = {
    showLink: yup.bool(),
    product_link_type: verifyLinkType,
    link_external: linkExternalSchema,
    link_prefix: verifyInput("Text"),
    link_text: verifyInput("Link name")
}

export const settingsExtraSecondLink = {
    second_product_link_type: verifyLinkType,
    second_link_external: secondLinkExternalSchema,
    second_link_prefix: verifyInput("Second Text"),
    second_link_text: verifyInput("Second Link name")
}

export const product = yup
    .object()
    .nullable()
    .when("settings.product_link_type", {
        is: LINK_TYPE.PRODUCT,
        then: yup
            .object()
            .nullable()
            .required("This field is required")
    })

export const secondProduct = yup
    .object()
    .nullable()
    .when(["settings.second_product_link_type", "settings.type"], {
        is: (linkType, formType) => linkType === LINK_TYPE.PRODUCT && formType === FORM_TYPE.DOUBLE,
        then: yup
            .object()
            .nullable()
            .required("This field is required")
    })
