import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./ClientCardFavorites.css"
import ProductsList from "components/Reusable/ProductsList/ProductsList"

import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"

import customModalStyles from "./overrides/modal.module.css"
import customProductListStyles from "./overrides/customproductlist.module.css"
import customProductStyles from "./overrides/customproduct.module.css"

class ClientCardFavorites extends Component {
    render() {
        const { handleRemove, isModalShown, handleModal, client } = this.props
        const { isLoaded, data } = client

        return (
            data.favorites &&
            data.favorites.length && (
                <div>
                    <div styleName="favorites-cover">
                        <h2 styleName="title">
                            <span>Favorites products</span>
                            <div>{data.favorites.length}</div>
                        </h2>
                        <span styleName="deleteAll" onClick={() => handleModal(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
                                <g
                                    fill="none"
                                    fillRule="evenodd"
                                    styleName="withStroke"
                                    strokeLinecap="round"
                                    strokeWidth="3.6"
                                >
                                    <path d="M2 2l24 24M26 2L2 26" />
                                </g>
                            </svg>
                            <span>Delete All</span>
                        </span>
                        <ProductsList
                            customStyles={customProductListStyles}
                            customProductStyles={customProductStyles}
                            isLoaded={isLoaded}
                            isOneLineGrid={true}
                            isBrandOnTop={true}
                            isRelated={true}
                            handleRemove={handleRemove}
                            products={data.favorites}
                        />
                        <div styleName="shadow" />
                    </div>
                    {isModalShown && this.renderModal()}
                </div>
            )
        )
    }

    renderModal() {
        const { handleRemoveAll, handleModal, client } = this.props
        const { id } = client.data

        return (
            <Modal>
                <Confirm
                    id={id}
                    customStyles={customModalStyles}
                    confirmModalTitle="Are you sure you want to remove all favorites products?"
                    titleSecondLine={`Are you sure you want to remove all favorites products? This action cannot be reversed.`}
                    handleHideModal={() => handleModal(false)}
                    handleDelete={() => {
                        handleRemoveAll(id)
                        handleModal(false)
                    }}
                />
            </Modal>
        )
    }
}

export default CSSModules(ClientCardFavorites, style)
