import React, { useState, useRef, useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import { DateRangePicker as DateRange } from "react-date-range"
import withStyles from "HOC/withStyles"

import calendar from "assets/calendar-blue.svg"
import arrow from "assets/arrow.svg"
import close from "assets/delete.svg"

import styles from "./styles.module.css"

const DateRangePicker = ({
    cx,
    ranges,
    format,
    onChange,
    resetRanges,
    toRight,
    title,
    label,
    icon,
    isDatapickerOpen,
    isDatapickerOpenRef,
    disabled
}) => {
    const { startDate, endDate } = ranges
    let closeRef = useRef(null)
    const [isOpen, setIsOpen] = useState(!!isDatapickerOpen)

    let timeOutId = null

    useEffect(() => {
        setIsOpen(isDatapickerOpen)
    }, [isDatapickerOpenRef])

    const toggleDateRangePicker = event => {
        if (!disabled && (!closeRef.current || !closeRef.current.contains(event.target))) {
            setIsOpen(wasOpen => !wasOpen)
        }
    }

    const handleOnFocus = () => {
        clearTimeout(timeOutId)
    }

    const handleOnBlur = () => {
        timeOutId = setTimeout(() => {
            setIsOpen(false)
        })
    }

    const displayDate = date => (date ? moment(date).format(format || "MM/DD/YYYY") : "")

    const getDate = date => (date ? new Date(date) : new Date())
    const dateRanges = { ...ranges, startDate: getDate(startDate), endDate: getDate(endDate) }

    const areDatesChosen = !!startDate && !!endDate

    return (
        <div className={cx("root", { disabled })} onFocus={handleOnFocus} onBlur={handleOnBlur} tabIndex={0}>
            {label && <span className={cx("label")}>{`${label}:`}</span>}
            <span className={cx("picker", { areDatesChosen, isLabel: label })} onClick={toggleDateRangePicker}>
                {areDatesChosen ? (
                    <Fragment>
                        <span>{displayDate(startDate)}</span> - <span>{displayDate(endDate)}</span>
                        {typeof resetRanges === "function" ? (
                            <img ref={closeRef} src={close} alt="close" className={cx("close")} onClick={resetRanges} />
                        ) : (
                            <img src={calendar} className={cx("calendar-icon")} alt="calendar" />
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {title || "Select date"}
                        <img
                            src={icon || arrow}
                            alt="choose"
                            className={cx("arrow", { isActive: isOpen, customIcon: icon })}
                        />
                    </Fragment>
                )}
            </span>

            <div className={cx("date-range", { isActive: isOpen, toRight })}>
                <DateRange ranges={[dateRanges]} onChange={onChange} disabled={disabled} />
            </div>
        </div>
    )
}

DateRangePicker.propTypes = {
    ranges: PropTypes.shape({
        startDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        endDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    resetRanges: PropTypes.func.isRequired,
    toRight: PropTypes.bool,
    disabled: PropTypes.bool
}

export default withStyles(DateRangePicker, styles)
