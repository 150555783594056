import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { compose } from "redux"
import ReactSVG from "react-svg"
import moment from "moment"

import withPermissions from "HOC/withPermissions"
import withStyles from "HOC/withStyles"
import fetch from "helpers/fetch"

import Button from "components/Reusable/Form/Button"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import styles from "./CalendarEntryDetails.css"

import closeIcon from "assets/close.svg"
import privateCalendarIcon from "assets/ico-lock.svg"
import publicCalendarIcon from "assets/ico-website.svg"
import sharedEntryIcon from "assets/ico-people.svg"
import deleteEntryIcon from "assets/delete.svg"
import editEntryIcon from "assets/pencil.svg"
import arrowIcon from "assets/arrow.svg"

import { EVENT_TYPES, EVENT_TYPES_ORDER, INVOICE_ORDER_TYPES } from "modules/CalendarModule/constants/eventTypes"
import * as calendarActions from "modules/CalendarModule/actions"

class CalendarEntryDetails extends Component {
    state = {
        isFetchingModeration: false,
        isLoadingDetails: true,
        details: {}
    }

    componentDidMount() {
        const { data } = this.props
        const event = data.event.extendedProps.entry

        fetch.get(`/calendar-entries/${event.id}`).then(({ data }) => {
            this.setState({
                details: data,
                isLoadingDetails: false
            })
        })
    }

    buildAdditionalInfo = event => {
        const { cx } = this.props
        const { object_type, client } = event
        switch (object_type) {
            case EVENT_TYPES.PROJECT:
                return (
                    <div className={cx("additional-info")}>
                        <span className={cx("label")}>Client: </span>
                        {client ? <span className={cx("client")}>{client.full_name}</span> : "-"}
                    </div>
                )
            case EVENT_TYPES.INVOICE:
            case EVENT_TYPES.PAYMENT:
            case EVENT_TYPES.PURCHASE_ORDER:
                return (
                    <div className={cx("additional-info")}>
                        <span className={cx("label")}>
                            {event.order.type === INVOICE_ORDER_TYPES.SALES
                                ? "Parent sales order: "
                                : "Parent rental agreement: "}
                        </span>
                        <Link
                            className={cx("link")}
                            to={`orders/${
                                event.order.type === INVOICE_ORDER_TYPES.SALES ? "sales-orders" : "rental-agreements"
                            }/${event.order.id}`}
                        >
                            {event.order.uuid}
                        </Link>
                    </div>
                )
            default:
                return ""
        }
    }

    buildLink = event => {
        const { cx } = this.props
        const { object_type, type } = event

        const CustomLink = ({ label, link }) => (
            <Link to={link} className={cx("link")}>
                {label} <ReactSVG src={arrowIcon} />
            </Link>
        )

        if (object_type === EVENT_TYPES.ORDER) {
            switch (type) {
                case EVENT_TYPES_ORDER.SALES:
                    return <CustomLink label="Go to the sales order" link={`orders/sales-orders/${event.object_id}`} />
                case EVENT_TYPES_ORDER.MEMO:
                    return <CustomLink label="Go to the memo" link={`orders/memo/${event.object_id}`} />
                case EVENT_TYPES_ORDER.RENTAL:
                    return <CustomLink label="Go to the rental" link={`orders/rental-agreements/${event.object_id}`} />
                default:
                    return ""
            }
        }

        switch (object_type) {
            case EVENT_TYPES.PROJECT:
                return <CustomLink label="Go to the project" link={`projects/${event.object_id}`} />
            case EVENT_TYPES.INVOICE:
                return (
                    <CustomLink
                        label="Go to the invoice"
                        link={{ pathname: `orders/invoices`, state: { invoiceId: event.object_id } }}
                    />
                )
            case EVENT_TYPES.PURCHASE_ORDER:
                return (
                    <CustomLink
                        label="Go to the PO"
                        link={{ pathname: `orders/purchase-orders`, state: { purchaseOrderId: event.object_id } }}
                    />
                )
            case EVENT_TYPES.PAYMENT:
                return (
                    <CustomLink
                        label="Go to the payment"
                        link={`orders/${
                            event.order.type === INVOICE_ORDER_TYPES.SALES ? "sales-orders" : "rental-agreements"
                        }/${event.order.id}/payments`}
                    />
                )
            case EVENT_TYPES.CLIENT_BIRTHDAY:
                return <CustomLink label="Go to the client" link={`contact/${event.object_id}/profile`} />
            case EVENT_TYPES.CLIENT_MEETING:
                return (
                    <CustomLink label="Go to the client" link={`contact/${event.client.id}/profile/client-meetings`} />
                )
            default:
                return ""
        }
    }

    handleModeration = (id, action) => {
        const { moderateEntry, handleSuccessModeration } = this.props

        this.setState({ isFetchingModeration: true })

        moderateEntry(id, action)
            .then(() => {
                this.setState({ isFetchingModeration: false })
                handleSuccessModeration(id, action)
            })
            .catch(() => this.setState({ isFetchingModeration: false }))
    }

    canDisplayFooter = event => {
        const { withoutLink } = this.props

        return (
            event.is_editable ||
            event.created_by ||
            (!withoutLink && !event.is_moderable && event.object_type !== EVENT_TYPES.EMPLOYEE_BIRTHDAY)
        )
    }

    render() {
        const { cx, data, visible, handleEventClose, handleDeleteAction, handleEditAction, withoutLink } = this.props
        const { isLoadingDetails, isFetchingModeration, details: event } = this.state
        const calendar = data.event.extendedProps.entry.calendar

        const windowWidth = (50 / 100) * window.innerWidth
        const posLeftRight = data && data.jsEvent.clientX > windowWidth ? "400" : "0"
        const isInRangeHeight = data && window.innerHeight - data.jsEvent.clientY > 300 // temporary fixed
        const x = data && data.jsEvent.pageX - posLeftRight
        const y = data && data.jsEvent.pageY

        const dateFormatOnlyDate = "MMM DD, YYYY"
        const dateFormatWithTime = "MMM DD, YYYY - hh:mm a"
        const dateFormat = event && event.is_all_day ? dateFormatOnlyDate : dateFormatWithTime
        const scrollHeight = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight
        )

        const { id, starts_at, ends_at, shares, project, is_editable, is_moderable, can_moderate } = event

        return (
            <div
                className={cx(["calendar-entry-details", visible ? "calendar-entry-details-visible" : ""])}
                style={{
                    top: isInRangeHeight ? y : "unset",
                    bottom: isInRangeHeight ? "unset" : scrollHeight - data.jsEvent.pageY,
                    left: x,
                    borderColor: calendar.color
                }}
            >
                <span className={cx("entry-close")} onClick={handleEventClose}>
                    <img src={closeIcon} alt={""} />
                </span>

                {isLoadingDetails ? (
                    <div className={cx("loader")}>
                        <SnakeLoader />
                    </div>
                ) : (
                    <Fragment>
                        <div className={cx("header")}>
                            <span className={cx("entry-color")} style={{ background: event.calendar.color }} />
                            <span
                                className={cx("entry-title")}
                                dangerouslySetInnerHTML={{ __html: event.title_html }}
                            />
                            <span className={cx("entry-dates")}>
                                {starts_at && <span>{moment(starts_at).format(dateFormat)}</span>}
                                {ends_at && ends_at !== starts_at && <span>{moment(ends_at).format(dateFormat)}</span>}
                            </span>
                        </div>
                        <div className={cx("content")}>
                            <div className={cx("tags")}>
                                <div className={cx(["calendar-type"])}>
                                    {calendar.is_public ? (
                                        <span>
                                            <img src={publicCalendarIcon} alt={""} />
                                            Public
                                        </span>
                                    ) : (
                                        <span>
                                            <img src={privateCalendarIcon} alt={""} />
                                            Private
                                        </span>
                                    )}
                                </div>

                                {shares && !!shares.length && (
                                    <div className={cx("entry-shared")}>
                                        <img src={sharedEntryIcon} alt={""} />
                                        Shared for {shares.length} people
                                    </div>
                                )}

                                {this.buildAdditionalInfo(event)}
                            </div>
                            {project && (
                                <div className={cx("connected-project")}>
                                    <span className={cx("label")}>Project: </span>
                                    <span className={cx("name")}>{project.name}</span>
                                </div>
                            )}
                            <p className={cx("description")}>{event && event.description}</p>
                        </div>

                        {this.canDisplayFooter(event) && (
                            <Fragment>
                                <div className={cx("separator")} />

                                <div className={cx("footer")}>
                                    {is_editable ? (
                                        <div>
                                            <span
                                                className={cx(["button-link"])}
                                                onClick={() => handleDeleteAction(event)}
                                            >
                                                <img src={deleteEntryIcon} alt={""} /> Delete
                                            </span>
                                            <span
                                                className={cx(["button-link"])}
                                                onClick={() => handleEditAction(event)}
                                            >
                                                <img src={editEntryIcon} alt={""} /> Edit
                                            </span>
                                        </div>
                                    ) : (
                                        <div className={cx("footer-author")}>
                                            {event.object_type !== EVENT_TYPES.CLIENT_BIRTHDAY &&
                                                event.object_type !== EVENT_TYPES.EMPLOYEE_BIRTHDAY && (
                                                    <Fragment>
                                                        <span className={cx("label")}>Created by: </span>
                                                        <span className={cx("created_by")}>
                                                            {event.created_by ? event.created_by.full_name : "-"}
                                                        </span>
                                                    </Fragment>
                                                )}
                                        </div>
                                    )}
                                    <div className={cx("footer-actions")}>
                                        {!withoutLink && !is_moderable && this.buildLink(event)}
                                        {can_moderate && (
                                            <div
                                                className={cx("moderation-buttons", {
                                                    isDisabled: isFetchingModeration
                                                })}
                                            >
                                                <span
                                                    className={cx(["button-link"])}
                                                    onClick={() =>
                                                        !isFetchingModeration && this.handleModeration(id, "reject")
                                                    }
                                                >
                                                    Reject
                                                </span>
                                                <Button
                                                    type="submit"
                                                    isLoading={false}
                                                    className={cx(["first-button", "button", "create-button"])}
                                                    label={"Accept"}
                                                    onClick={() =>
                                                        !isFetchingModeration && this.handleModeration(id, "accept")
                                                    }
                                                />
                                            </div>
                                        )}
                                        {/* <span className={cx(["button-link"])} onClick={() => handleCancelConfirmation(event)}>
                                                Cancel confirmation
                                            </span> */}
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        )
    }
}

CalendarEntryDetails.defaultProps = {
    handleSuccessModeration: () => {}
}

const mapDispatchToProps = dispatch => ({
    moderateEntry: (id, action) => dispatch(calendarActions.moderateEntry(id, action))
})

export default compose(
    withPermissions,
    connect(
        null,
        mapDispatchToProps
    )
)(withStyles(CalendarEntryDetails, styles))
