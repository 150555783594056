import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import * as userActions from "actions/user"
import * as rolesActions from "actions/role"
import Confirm from "components/Modal/Confirm/Confirm"
import Modal from "components/Modal/Modal"
import AddNewUserForm from "./AddNewUserForm/AddNewUserForm"
import withForm from "HOC/withForm"
import models from "models/index"
import * as notificationActions from "actions/notification"

class AddNewUser extends Component {
    isEdit = false

    state = {
        isPasswordReset: false,
        showModal: false
    }

    componentDidMount() {
        const { match, getRoles, getUserById } = this.props
        if (match && match.params && match.params.id) {
            this.isEdit = true
            getUserById(match.params.id)
        }

        getRoles()
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { userStore, fillModel } = nextProps

        if (this.isEdit && Object.keys(userStore.user).length > 0) {
            fillModel({
                ...userStore.user,
                permissionGroups: userStore.user.role
            })
        }
        if (!this.props.isError && nextProps.isError) {
            this.props.showErrorNotification()
        }
    }

    onEdit(event) {
        event.preventDefault()
        const { userStore } = this.props
        const { id } = userStore.user
        this.handleSubmit({ method: "patch", id })
    }

    onSubmit(event) {
        event.preventDefault()
        this.handleSubmit({ method: "post" })
    }

    handleSubmitSuccess() {
        const { history, showSuccessNotification } = this.props
        history.goBack()
        showSuccessNotification && showSuccessNotification()
    }

    handleSubmit({ method, id }) {
        const { handleSubmit } = this.props

        handleSubmit({ method, id, handleSuccess: this.handleSubmitSuccess.bind(this) })
    }

    handleResetPassword() {
        const { userStore, resetPassword } = this.props
        const { email } = userStore.user

        resetPassword(email)
        this.setState({
            isPasswordReset: true
        })
    }

    handleShowModal(event) {
        event.preventDefault()
        this.setState({ showModal: true })
        this.isDeleting = true
    }

    handleHideModal() {
        this.setState({ showModal: false })
    }

    handleDelete(id) {
        const { history, deleteUser } = this.props
        deleteUser(id)
        history.goBack()
    }

    choosePermission(id) {
        const { handleFieldChange, toRender } = this.props
        const permissionsIds = toRender().permissionGroups.value
        const permissionsWithoutThisId = permissionsIds.filter(element => element !== id)

        let newPermissions = [...permissionsWithoutThisId]
        if (permissionsIds.indexOf(id) < 0) {
            newPermissions = [...permissionsIds, id]
        }

        handleFieldChange({
            event: {
                target: {
                    value: newPermissions
                }
            },
            fieldPath: "permissionGroups"
        })
    }

    render() {
        const {
            userStore,
            roleStore,
            toRender,
            handleFieldChange,
            isSaving,
            isFilled,
            history,
            statesList,
            countriesList
        } = this.props
        const { isPasswordReset } = this.state

        const modal = this.state.showModal ? (
            <Modal>
                <Confirm
                    id={userStore.user.id}
                    firstName={userStore.user.first_name}
                    lastName={userStore.user.last_name}
                    confirmModalTitle="Are you sure you want to delete this user?"
                    handleHideModal={this.handleHideModal.bind(this)}
                    handleDelete={this.handleDelete.bind(this)}
                />
            </Modal>
        ) : null

        return (
            <Fragment>
                {modal}
                <AddNewUserForm
                    onEdit={this.onEdit.bind(this)}
                    onSubmit={this.onSubmit.bind(this)}
                    roles={roleStore.roles}
                    choosePermission={this.choosePermission.bind(this)}
                    isEdit={this.isEdit}
                    isFilled={isFilled}
                    isLoadedUser={userStore.isLoaded}
                    userData={userStore.user}
                    formFields={toRender()}
                    isSaving={isSaving}
                    handleFieldChange={handleFieldChange}
                    statesList={statesList}
                    handleResetPassword={this.handleResetPassword.bind(this)}
                    isPasswordReset={isPasswordReset}
                    handleShowModal={this.handleShowModal.bind(this)}
                    countriesList={countriesList}
                    history={history}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        statesList: state.clientStore.statesList,
        countriesList: state.clientStore.countriesList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUserById: id => dispatch(userActions.getUserById(id)),
        getRoles: () => dispatch(rolesActions.getRoles()),
        deleteUser: id => dispatch(userActions.deleteUser(id)),
        resetPassword: email => dispatch(userActions.resetPassword(email)),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification()),
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withForm(AddNewUser, { model: models.User })))
