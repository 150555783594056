import React from "react"
import PropTypes from "prop-types"

import { Input } from "ui"
import { SelectChangeReason, SelectWarehouse } from "ui/Filters"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import formikPropTypesFactory from "modules/WmsModule/helpers/formikPropTypesFactory"
import REASON_TYPES from "modules/WmsModule/constants/reasonTypes"

import inputStyles from "./overrides/Input.module.css"
import styles from "./ReturnDetailsHero.module.css"

const ReturnDetailsHero = ({ formik, salesOrderUuid, isDisabled }) => {
    const getSelectProps = getSelectPropsFactory(formik)

    return (
        <div className={styles.returnDetailsForm}>
            <Input label="SO" value={salesOrderUuid} customStyles={inputStyles} isDisabled />
            <div className={styles.selectWrapper}>
                <SelectWarehouse
                    {...getSelectProps("returnTo")}
                    label="To warehouse"
                    isRequired
                    withPrimaryLabel
                    warehouseType="active"
                    isDisabled={isDisabled}
                />
            </div>
            <div className={styles.selectWrapper}>
                <SelectChangeReason
                    {...getSelectProps("reason")}
                    label="Reason"
                    isDisabled={isDisabled}
                    reasonType={REASON_TYPES.CLIENT_RETURN}
                />
            </div>
        </div>
    )
}

ReturnDetailsHero.propTypes = {
    salesOrderUuid: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    formik: formikPropTypesFactory({
        returnTo: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        }),
        reason: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })
    })
}

export default ReturnDetailsHero
