import React, { useMemo } from "react"
import idx from "idx"

import useStyles from "helpers/getCxFromStyles"

import { Input, SelectWithSearch } from "ui"

import styles from "./Filters.css"
import inputStyles from "./overrides/Input.css"
import selectStyles from "./overrides/Select.css"

const Filters = ({ filters, brands, rooms, onUpdate }) => {
    const cx = useStyles(styles)

    const search = idx(filters, _ => _.search) || ""
    const room = idx(filters, _ => _.room.name)
    const brand = idx(filters, _ => _.brand.name)

    const brandsOptions = useMemo(
        () =>
            brands.map(brand => ({
                value: brand,
                label: brand.name,
                secondLabel: ""
            })),
        [brands]
    )
    const roomsOptions = useMemo(
        () =>
            rooms.map(room => ({
                value: room,
                label: room.name,
                secondLabel: ""
            })),
        [brands]
    )

    return (
        <div className={cx("root")}>
            <span className={cx("label")}>Filters:</span>
            <Input
                customStyles={inputStyles}
                label="Search product..."
                isPlaceholder
                value={search}
                onChange={({ event }) => onUpdate({ ...filters, search: event.target.value })}
            />
            <SelectWithSearch
                customStyles={selectStyles}
                placeholder="- All brands -"
                values={brandsOptions}
                value={brand}
                setValue={option => onUpdate({ ...filters, brand: option.value })}
                withoutFetch
            />
            <SelectWithSearch
                customStyles={selectStyles}
                placeholder="- All rooms -"
                values={roomsOptions}
                value={room}
                setValue={option => onUpdate({ ...filters, room: option.value })}
                withoutFetch
            />
        </div>
    )
}

export default Filters
