import React from "react"
import { Route, Switch } from "react-router-dom"
import withPermissions from "HOC/withPermissions"
import { Calendar } from "../pages"

const Routes = () => {
    return (
        <Switch>
            <Route exact path="" component={Calendar} />
        </Switch>
    )
}

export default withPermissions(Routes)
