import React, { useState, useEffect, Fragment, useCallback } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _isEqual from "lodash/isEqual"
import { Modal, useTableFilterBarSearch, Button } from "@butterfly-frontend/ui"

import { AddManualEntry } from "ui"
import {
    ProductHeader,
    ProductFilters,
    ProductSearchBar,
    ProductList,
    ProductListItem,
    ProductInfo,
    ProductActions,
    Combinations,
    useProductFilters,
    useProductList,
    useProductsState,
    mapFiltersToApi,
    checkIfDateRangeIsValid,
    INITIAL_FILTERS,
    PRODUCT_TYPE_KEYS
} from "ui/ChooseProduct"

import styles from "./ChooseItemModal.module.css"

const ChooseItemModal = ({ lockedFilters, onClose, isOpen, handleSelect, isDisabledAddItems, fetchStatuses }) => {
    const [isFilterExpanded, setIsFilterExpanded] = useState(true)
    const [isAddManualEntryFormOpen, setIsAddManualEntryFormOpen] = useState(false)
    const [productForChooseCombinationModal, setProductForChooseCombinationModal] = useState(null)
    const { brands, categories, locations, products, fetchProducts, handleLoadMoreProducts } = useProductList()
    const [isInit, setIsInit] = useState(true)

    const { itemsState, handleChangeQuantity, handleChangeIsLoading } = useProductsState({ fetchStatuses })

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        if (!isOpen) {
            setIsAddManualEntryFormOpen(false)
        }
    }, [isOpen])

    const {
        value: filterSearchPhrase,
        debouncedValue: query,
        onChange: handleChangeFilterSearchPhrase
    } = useTableFilterBarSearch("")

    const { setSelectedFilter, selectedFilterValues, activeFiltersCount } = useProductFilters({
        initialFilterValues: INITIAL_FILTERS,
        filtersExcludedFromCounter: ["type", "manualEntries"],
        lockedFilters
    })

    const refreshProductList = useCallback(() => {
        if (checkIfDateRangeIsValid(selectedFilterValues.dateRange)) {
            fetchProducts({ query, ...mapFiltersToApi(selectedFilterValues) })
        }
    }, [query, selectedFilterValues])

    useEffect(() => {
        if (!isInit || !_isEmpty(query) || !_isEqual(selectedFilterValues, INITIAL_FILTERS)) {
            refreshProductList()
            setIsInit(false)
        }
    }, [query, selectedFilterValues])

    return (
        isOpen && (
            <Modal
                onClose={onClose}
                title={<ProductHeader mainTitle={productForChooseCombinationModal ? "Choose an option" : "New Item"} />}
            >
                <div className={styles.modalContent}>
                    {productForChooseCombinationModal ? (
                        <Fragment>
                            <ProductInfo
                                name={productForChooseCombinationModal.name}
                                brand={_get(productForChooseCombinationModal, "brand.name", "")}
                                imageUrl={_get(productForChooseCombinationModal, "media.url", "")}
                            />
                            <div className={styles.combinationModalDivider}></div>
                            <Combinations
                                productId={productForChooseCombinationModal.id}
                                productImage={_get(productForChooseCombinationModal, "media.url", "")}
                                handleSelectProductCombination={handleSelect}
                                itemsState={itemsState}
                                clearSelectedProduct={() => setProductForChooseCombinationModal(null)}
                                handleChangeIsLoading={handleChangeIsLoading}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <ProductSearchBar
                                type={selectedFilterValues.type}
                                filterSearchPhrase={filterSearchPhrase}
                                handleChangeFilterSearchPhrase={handleChangeFilterSearchPhrase}
                                filterCount={activeFiltersCount}
                                isFilterExpanded={isFilterExpanded}
                                setIsFilterExpanded={setIsFilterExpanded}
                            />

                            {isFilterExpanded && selectedFilterValues.type !== PRODUCT_TYPE_KEYS.SERVICES && (
                                <ProductFilters
                                    selectedFilterValues={selectedFilterValues}
                                    setSelectedFilter={setSelectedFilter}
                                    brands={brands}
                                    categories={categories}
                                    locations={locations}
                                    lockedFilters={lockedFilters}
                                />
                            )}

                            <div className={styles.manualEntry}>
                                {isAddManualEntryFormOpen ? (
                                    <AddManualEntry
                                        tax_code={0}
                                        discount={0}
                                        onClose={() => setIsAddManualEntryFormOpen(false)}
                                        onSuccess={handleSelect}
                                        selectedBrand={lockedFilters.brand}
                                    />
                                ) : (
                                    <Button
                                        size="small"
                                        color="blue"
                                        onClick={() => setIsAddManualEntryFormOpen(true)}
                                        classes={{ button: styles.manualEntryButton }}
                                    >
                                        Add Manual Entry
                                    </Button>
                                )}
                            </div>

                            <ProductList
                                products={products}
                                handleLoadMoreProducts={() =>
                                    handleLoadMoreProducts({ query, ...mapFiltersToApi(selectedFilterValues) })
                                }
                            >
                                {products.data.map(product => (
                                    <ProductListItem key={product.id}>
                                        <ProductInfo
                                            name={product.name}
                                            brand={_get(product, "brand.name", "")}
                                            imageUrl={_get(product, "media.url", "")}
                                            type={selectedFilterValues.type}
                                        />
                                        <ProductActions
                                            type={selectedFilterValues.type}
                                            product={product}
                                            handleSelect={handleSelect}
                                            handleChooseCombination={() => setProductForChooseCombinationModal(product)}
                                            isWholesaleCost={true}
                                            itemsState={itemsState}
                                            isDisabledAddItems={isDisabledAddItems}
                                            handleChangeQuantity={handleChangeQuantity}
                                            handleChangeIsLoading={handleChangeIsLoading}
                                        />
                                    </ProductListItem>
                                ))}
                            </ProductList>
                        </Fragment>
                    )}
                </div>
            </Modal>
        )
    )
}

ChooseItemModal.propTypes = {
    lockedFilters: PropTypes.shape({
        brand: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        })
    }),
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    isDisabledAddItems: PropTypes.bool,
    fetchStatuses: PropTypes.objectOf(PropTypes.shape({ isLoading: PropTypes.bool }))
}

export default ChooseItemModal
