import React, { Component } from "react"
import { connect } from "react-redux"
import fetch from "helpers/fetch"
import * as productActions from "actions/product"

import EditableList from "components/Reusable/EditableList"
import config from "./config"

import ListingLayout from "../../OtherListingLayout/index"

class Listing extends Component {
    componentDidMount() {
        const { getProductConditions } = this.props
        getProductConditions()
    }

    handleDeleteProductCondition = id => {
        const { getProductConditions } = this.props
        fetch.deleteRAW(`/product-conditions/${id}`).then(data => {
            getProductConditions()
        })
    }

    render() {
        const { productConditions, isLoading, productConditionId } = this.props

        const addUrl = productConditionId
            ? `/settings/conditions/${productConditionId}/add`
            : "/settings/conditions/add"

        return (
            <ListingLayout>
                <EditableList
                    config={config}
                    list={productConditions}
                    listTitle="Product Conditions"
                    confirmModalTitle={"Are you sure you want to delete this condition?"}
                    editUrlPrefix={"/settings/conditions/card"}
                    addUrl={addUrl}
                    isLoading={isLoading}
                    handleDeleteEnableCondition={values => values.products_count === 0}
                    deleteElement={this.handleDeleteProductCondition}
                    handleContentLink={id => `/settings/conditions/${id}`}
                />
            </ListingLayout>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        productConditionId: ownProps.match.params.productConditionId || null,
        productConditions: state.db.products.conditions.list.data,
        isLoading: state.db.products.conditions.list.isLoading
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getProductConditions: () => dispatch(productActions.getProductConditions()),
        getProductConditionById: id => dispatch(productActions.getProductConditionById(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing)
