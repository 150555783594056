import { combineReducers } from "redux"

import current from "./current"
import comments from "./comments"
import rooms from "./rooms"
import orders from "./orders"

export default combineReducers({
    current,
    comments,
    rooms,
    orders
})
