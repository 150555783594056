import React from "react"
import { Modal as ModalComponent } from "ui"
import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"
import TimeAgo from "react-timeago"

import SelectFromListModal from "components/Modal/SelectFromList"

import styles from "./styles.css"

const Modal = props => {
    const { isOpen, handleClose, onSubmit, data, isSaving } = props

    const fetchItemsList = (query, { page, length, exclude }) =>
        fetch
            .get(`/realizations?query=${query}&page=${page}&length=${length}&exclude=${exclude}`)
            .then(data => ({ payload: { data } }))

    const handlePrepareItem = item => {
        return {
            name: item.title,
            img: item.thumbnail,
            right: <TimeAgo date={item.updated_at} />
        }
    }

    const handleSubmit = realization => {
        onSubmit(realization)
    }

    const excludedList = () => {
        return (data.realizations || []).map(element => element.realization_id)
    }

    return (
        <ModalComponent isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <SelectFromListModal
                handleClose={handleClose}
                selectedItems={[]}
                exclude={excludedList()}
                typeItems="realizations"
                prepareItem={handlePrepareItem}
                handleSubmit={handleSubmit}
                fetchItemsList={fetchItemsList}
                isSaving={isSaving}
            />
        </ModalComponent>
    )
}

export default withStyles(Modal, styles)
