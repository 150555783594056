import * as fetchStatus from "helpers/fetchStatus"
import * as constants from "./../constants/index"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"
import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"

export const shouldFetch = resource => {
    return fetchStatus.isResourceNotInit(resource)
}

export function getSelectionsStart() {
    return {
        type: constants.GET_START
    }
}

export function getSelectionsSuccess(data) {
    return {
        type: constants.GET_SUCCESS,
        payload: {
            data
        }
    }
}

export function getSelectionsError(error) {
    return {
        type: constants.GET_ERROR,
        payload: {
            error
        }
    }
}

export function getSelections(params = { page: 1, length: LENGTH_WITHOUT_PAGINATION }) {
    return dispatch => {
        dispatch(getSelectionsStart())
        return fetch
            .get(`/selections?${objToQueryString(params)}`)
            .then(data => dispatch(getSelectionsSuccess(data)))
            .catch(error => dispatch(getSelectionsError(error)))
    }
}

export function getSelectionStart() {
    return {
        type: constants.GET_BY_ID_START
    }
}

export function getSelectionSuccess(data) {
    return {
        type: constants.GET_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function getSelectionError(error) {
    return {
        type: constants.GET_BY_ID_ERROR,
        payload: {
            error
        }
    }
}

export function getSelectionById(id) {
    return dispatch => {
        dispatch(getSelectionStart())
        return fetch
            .get(`/selections/${id}`)
            .then(data => dispatch(getSelectionSuccess(data)))
            .catch(error => dispatch(getSelectionError(error)))
    }
}

export function deleteSelectionById(id) {
    return fetch.deleteRAW(`/selections/${id}`)
}

export function createSelection(body) {
    return fetch.post("/selections", body)
}

export function editSelection(id, body) {
    return fetch.patch(`/selections/${id}`, body)
}

export function setPagination(page) {
    return {
        type: constants.CHANGE_PAGINATION,
        payload: {
            value: page
        }
    }
}

export function setQuery(query) {
    return {
        type: constants.CHANGE_QUERY,
        payload: {
            value: query
        }
    }
}

export function replaceFilters(filters) {
    return {
        type: constants.REPLACE_FILTERS_GROUP,
        payload: { ...filters }
    }
}

export function setType(type) {
    return {
        type: constants.CHANGE_TYPE,
        payload: {
            value: type
        }
    }
}

export function setDateRange(dateRange) {
    const val = dateRange || {}

    return {
        type: constants.CHANGE_DATERANGE,
        payload: {
            from: val.from || "",
            to: val.to || ""
        }
    }
}

export function setMoreFilters(filters) {
    return {
        type: constants.REPLACE_MOREFILTERS_GROUP,
        payload: { ...filters }
    }
}

export function clearMoreFilters() {
    return {
        type: constants.CLEAR_MOREFILTERS_GROUP,
        payload: {}
    }
}

export function deleteSelectionMulti(ids) {
    return fetch.postRAW("/multi-actions", {
        ids,
        model: "selections",
        action: "delete"
    })
}

export function changeSelectionStart() {
    return {
        type: constants.CHANGE_BY_ID_START
    }
}

export function changeSelectionSuccess(data) {
    return {
        type: constants.CHANGE_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function changeSelectionError(error) {
    return {
        type: constants.CHANGE_BY_ID_FAILURE,
        payload: {
            error
        }
    }
}

export function changeSelection(id, body) {
    return dispatch => {
        dispatch(changeSelectionStart())
        return fetch
            .patch(`/selections/${id}`, body)
            .then(data => dispatch(changeSelectionSuccess(data)))
            .catch(error => dispatch(changeSelectionError(error)))
    }
}

export function fetchProductsBySelectionId(selectionId, page) {
    return dispatch => {
        dispatch({
            type: constants.FETCH_PRODUCTS_REQUEST
        })

        return fetch
            .get(`/products?selections=${selectionId}&page=${page}&length=20`)
            .then(data =>
                dispatch({
                    type: constants.FETCH_PRODUCTS_SUCCESS,
                    payload: {
                        data
                    }
                })
            )
            .catch(error =>
                dispatch({
                    type: constants.FETCH_PRODUCTS_FAILURE,
                    payload: {
                        error
                    }
                })
            )
    }
}

export function clearFilters() {
    return {
        type: constants.CLEAR_FILTERS_GROUP
    }
}
