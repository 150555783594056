import React from "react"
import _get from "lodash/get"
import PropTypes from "prop-types"
import {
    Button,
    Table,
    TableHead,
    TableBody,
    TableHeadCell,
    TableCell,
    TableRow,
    TrashIcon
} from "@butterfly-frontend/ui"

import { Input } from "ui"

import TABLE_COLUMNS from "./tableColumns"

import styles from "./BoxListFormTable.module.css"
import inputStyles from "./overrides/Input.css"

const BoxListFormTable = ({ boxes, onRemoveBox, getBoxInputProps, compactView = false, customColumns, isDisabled }) => {
    const columns = customColumns || TABLE_COLUMNS

    return (
        <Table>
            {!compactView && (
                <TableHead>
                    <TableHeadCell {...columns.NAME}>NAME</TableHeadCell>
                    <TableHeadCell {...columns.ID}>ID</TableHeadCell>
                    <TableHeadCell {...columns.LOCATION}>LOCATION</TableHeadCell>
                    <TableHeadCell {...columns.INPUT} />
                </TableHead>
            )}
            <TableBody>
                {boxes.map((box, index) => (
                    <TableRow key={index} classNames={{ root: box.selected ? styles.selectedBox : undefined }}>
                        <TableCell {...columns.NAME}>Box {index + 1}</TableCell>
                        <TableCell {...columns.ID}>{box.id}</TableCell>
                        <TableCell {...columns.LOCATION}>{_get(box, "location.name", "-")}</TableCell>
                        <TableCell {...columns.INPUT} classes={{ root: styles.boxName }}>
                            <Input
                                {...getBoxInputProps(index)}
                                customStyles={inputStyles}
                                required
                                isDisabled={isDisabled}
                            />
                            {boxes.length > 1 && (
                                <Button
                                    classes={{ button: styles.removeButton }}
                                    variant="flat"
                                    Icon={TrashIcon}
                                    onClick={() => onRemoveBox(index)}
                                    disabled={isDisabled}
                                />
                            )}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

const columnPropTypes = PropTypes.shape({
    minWidth: PropTypes.number,
    width: PropTypes.string,
    classes: PropTypes.shape({
        root: PropTypes.string
    })
}).isRequired

BoxListFormTable.propTypes = {
    boxes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string
            }),
            selected: PropTypes.bool
        }).isRequired
    ).isRequired,
    onRemoveBox: PropTypes.func.isRequired,
    getBoxInputProps: PropTypes.func.isRequired,
    compactView: PropTypes.bool,
    customColumns: PropTypes.shape({
        NAME: columnPropTypes,
        ID: columnPropTypes,
        LOCATION: columnPropTypes,
        INPUT: columnPropTypes
    })
}

export default BoxListFormTable
