import React, { useState } from "react"

import { Button, ActionButton } from "ui"
import data from "./data"

import withStyles from "HOC/withStyles"
import styles from "./scraper.css"

import downloadIcon from "assets/download-white.svg"
import expandIcon from "assets/expand.svg"

const { name, icon, version, file, description, instruction, lastUpdate } = data

const Scraper = props => {
    const { cx } = props

    const [isExpanded, setIsExpanded] = useState(false)
    const toggleExpand = () => setIsExpanded(wasExpanded => !wasExpanded)

    return (
        <div className={cx("root")}>
            <div className={cx("imageWrapper")}>
                <img src={icon} alt="Scraper" />
            </div>
            <div className={cx("contentWrapper")}>
                <div className={cx("headerWrapper")}>
                    <div>
                        <div className={cx("nameAndVersionWrapper")}>
                            <h2>{name}</h2>
                            <span className={cx("version")}>{version}</span>
                        </div>
                        <p>{description}</p>
                    </div>
                    <a className={cx("downloadButton")} href={file} download>
                        <Button className="first-button" icon={downloadIcon} label="Download" isLoading={false} />
                    </a>
                </div>
                <div className={cx("expandAndDateWrapper")}>
                    <ActionButton
                        className={cx("expandButton", { isExpanded })}
                        handleClick={toggleExpand}
                        icon={expandIcon}
                        label="How to add the extension to Chrome?"
                    />
                    <div className={cx("lastUpdate")}>
                        <span>Last updated:</span>
                        {lastUpdate}
                    </div>
                </div>
                {isExpanded && (
                    <div className={cx("instructionsWrapper")}>
                        {instruction.map(({ header, description }, index) => (
                            <div className={cx("instruction")} key={index}>
                                <div className={cx("instructionMark")}>{index + 1}</div>
                                <div>
                                    <h4>{header}</h4>
                                    <p className={cx("instructionDescription")}>{description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export default withStyles(Scraper, styles)
