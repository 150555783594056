import React, { useEffect } from "react"
import { useTableFilterBarSearch } from "@butterfly-frontend/ui"

import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"
import { useSelectData } from "ui/Filters/hooks"
import { PrimaryLabel } from "modules/WmsModule/components"

export const warehouseToValue = ({ id, name }) => ({ id, label: name, value: id })
export const warehouseToValueWithPrimaryLabel = ({ id, name, primary }) => ({
    id,
    label: name,
    value: id,
    renderSticker: primary ? () => <PrimaryLabel /> : undefined
})

const mapWarehouseTypeToParam = warehouseType => {
    if (warehouseType === "active") {
        return "true"
    }

    if (warehouseType === "inactive") {
        return "false"
    }

    return ""
}

const fetchSelectData = ({ pageParam = 1, queryKey: [_key, params] }) => {
    const { warehouseType, query, warehouseIds } = params

    return fetch.get(
        `/warehouses?${objToQueryString({
            active: mapWarehouseTypeToParam(warehouseType),
            query,
            page: pageParam,
            warehouses: warehouseIds
        })}`
    )
}

const useSelectWarehouse = ({ setValue, withPrimaryLabel, warehouseType, warehouseIds }) => {
    const { debouncedValue: query, onChange, onClear: clearQuery } = useTableFilterBarSearch("")

    const {
        selectData,
        values,
        fetchValues,
        isLoading,
        error,
        propsForSelectWithSearch,
        selectDataQuery
    } = useSelectData({
        key: "warehouses",
        params: { query, warehouseType, warehouseIds },
        fetchSelectData,
        selectOptionsMapper: withPrimaryLabel ? warehouseToValueWithPrimaryLabel : warehouseToValue,
        setValue
    })

    useEffect(() => {
        clearQuery()
    }, [withPrimaryLabel, warehouseType, warehouseIds])

    return {
        selectData,
        values,
        fetchValues,
        isLoading,
        error,
        propsForSelectWithSearch: {
            ...propsForSelectWithSearch,
            onInputChange: value => {
                onChange({ target: { value } })
            }
        },
        selectDataQuery
    }
}

export default useSelectWarehouse
