import React from "react"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"
import { DropList } from "ui"

import styles from "./DropdownThreeDots.css"

const DropdownThreeDots = props => {
    const { items, dangerItems } = props
    const cx = useStyles(styles)

    return (
        <div>
            <DropList
                trigger={
                    <div className={cx("button")}>
                        <span />
                        <span />
                        <span />
                    </div>
                }
            >
                <DropList.List>
                    {items.map(item => (
                        <DropList.Item key={item.label} onClick={() => item.onClick(item)}>
                            {item.label}
                        </DropList.Item>
                    ))}
                    {items.length > 0 && dangerItems.length > 0 && <DropList.Divider />}
                    {dangerItems.map(item => (
                        <DropList.Item key={item.label} className="danger" onClick={() => item.onClick(item)}>
                            {item.label}
                        </DropList.Item>
                    ))}
                </DropList.List>
            </DropList>
        </div>
    )
}

const ItemPropTypes = PropTypes.arrayOf(
    PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        label: PropTypes.string.isRequired
    })
).isRequired

DropdownThreeDots.propTypes = {
    items: ItemPropTypes,
    dangerItems: ItemPropTypes
}

export default DropdownThreeDots
