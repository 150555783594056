import * as constants from "constants/index"

function priceRange(state = { leftValue: "", rightValue: "" }, action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_PRICERANGE:
            return { leftValue: "", rightValue: "" }
        case constants.CHANGE_PRICERANGE:
            return { ...value }
        default:
            return state
    }
}

export default priceRange
