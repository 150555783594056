import idx from "idx"

export function updateManualEntryList(manualEntryState, manualEntry) {
    const { data: manualEntries, meta } = manualEntryState
    const manualEntriesOnList = meta ? parseInt(meta.per_page, 10) : 1

    const isManualEntryInList = !!manualEntries.find(entry => entry.id === manualEntry.id)
    const data = isManualEntryInList ? editEntry() : addEntry()

    return { ...manualEntryState, data, meta: calculateMeta() }

    function editEntry() {
        return manualEntries.map(currentEntry => {
            if (currentEntry.id === manualEntry.id) {
                return transformManualEntry(manualEntry)
            }

            return currentEntry
        })
    }

    function addEntry() {
        return [transformManualEntry(manualEntry), ...manualEntries].slice(0, 20)
    }

    function transformManualEntry(manualEntry) {
        const { id, images, name, brand, price } = manualEntry
        return { id, name, lowest_price: price, brand: brand.name, media: idx(images, _ => _.gallery[0]) }
    }

    function calculateMeta() {
        const to = meta.to !== manualEntriesOnList ? data.length : meta.to
        const total = isManualEntryInList ? meta.total : meta.total + 1
        const last_page = Math.ceil(total / manualEntriesOnList)

        return { ...meta, to, total, last_page }
    }
}
