import React, { Fragment } from "react"
import { Modal as ModalComponent, Input, Button, Textarea } from "ui"

import buttonStyles from "./styles/button.css"
import headerStyles from "./overrides/header.css"
import inputStyles from "./overrides/input.css"
import textareaStyles from "./overrides/textarea.css"
import actionStyles from "./overrides/action.css"

import Loader from "components/Reusable/Loader/Loader"
import Skeleton from "components/Skeleton"
import RadioItem from "../RadioItem"

const Modal = props => {
    const { title, cx, closeModal, handleButton, fieldsProps, saveButtonLabel, isFetching, isLoading } = props

    const renderInput = field => (
        <div className={cx(["input-container", field])}>
            <Input customStyles={inputStyles} type="text" {...fieldsProps[field]} />
        </div>
    )

    const renderTextarea = field => (
        <div className={cx(["input-container", field])}>
            <Textarea customStyles={textareaStyles} type="text" {...fieldsProps[field]} />
        </div>
    )

    const renderRadio = field => (
        <div className={cx(["radio-container", field])}>
            {fieldsProps[field].values.map(({ display, value }) => (
                <RadioItem
                    key={display}
                    name="verification"
                    label={display}
                    value={value}
                    checked={fieldsProps[field].value === value}
                    handleChange={fieldsProps[field].onChange}
                />
            ))}
        </div>
    )

    return (
        <Fragment>
            <ModalComponent.Header customStyles={headerStyles}>{title}</ModalComponent.Header>
            <ModalComponent.Body>
                <Skeleton
                    isLoading={isLoading}
                    isLoaded={!isLoading}
                    component={
                        <div className={cx("loader")}>
                            <Loader />
                        </div>
                    }
                    render={() => (
                        <Fragment>
                            {renderInput("name")}
                            {renderTextarea("description")}
                            {renderRadio("type")}
                        </Fragment>
                    )}
                />
            </ModalComponent.Body>
            <ModalComponent.Actions customStyles={actionStyles}>
                <button className={cx("cancel-button")} onClick={closeModal}>
                    Cancel
                </button>
                <Button
                    className="first-button"
                    customStyles={buttonStyles}
                    onClick={handleButton}
                    label={saveButtonLabel}
                    isLoading={isFetching}
                />
            </ModalComponent.Actions>
        </Fragment>
    )
}

export default Modal
