import React, { Component } from "react"
import styles from "./Layout.css"
import ContentHeader from "components/Content/ContentHeader/index"
import ReturnHeader from "components/Reusable/ReturnHeader/ReturnHeader"

import withStyles from "HOC/withStyles"

class Layout extends Component {
    render() {
        const { cx, children, title, returnLink, topLayout, contentHeaderClassName } = this.props
        return (
            <div className={cx("root")}>
                {title && !returnLink && (
                    <ContentHeader className={cx(contentHeaderClassName, "contentHeader")} {...this.props} />
                )}
                {returnLink && <ReturnHeader {...returnLink} isArrow={true} />}
                {topLayout && (
                    <div className={cx("layout", "topLayout", "round-border", { withoutTop: !returnLink && !title })}>
                        {topLayout}
                    </div>
                )}
                <div className={cx("layout", "round-border", { withoutTop: !returnLink && !title })}>{children}</div>
            </div>
        )
    }
}

export default withStyles(Layout, styles)
