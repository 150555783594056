import React from "react"
import { useActions } from "hooks"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"

import fetch from "helpers/fetch"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { Button, Modal, Input } from "ui"
import submitButtonStyles from "./overrides/SubmitButton.css"
import styles from "./EditChargeFeeModal.module.css"
import withStyles from "HOC/withStyles"

const validationSchema = Yup.object().shape({
    cardChargeFee: Yup.number().required("This field is required.")
})

const EditCardChargeFeeModal = ({ cx, handleClose, feeToEdit, handleSubmitSuccess }) => {
    const actions = useActions({ showErrorNotification, showSuccessNotification })

    const onSubmit = async (values, { setErrors, setSubmitting }) => {
        setSubmitting(true)

        const body = {
            key: feeToEdit.key,
            value: values.cardChargeFee
        }

        try {
            const response = await fetch.patch(`/settings/${feeToEdit.id}`, body)
            actions.showSuccessNotification()
            handleSubmitSuccess({
                id: response.data.id,
                key: response.data.key,
                percentValue: response.data.value
            })
            handleClose()
        } catch (error) {
            if (error) {
                const { errors: dataErrors, message } = error
                const errors = dataErrors || (message ? { message } : null)
                if (errors) {
                    setErrors(errors)
                }
            }
            actions.showErrorNotification()
            setSubmitting(false)
        }
    }

    const { values, errors, setFieldValue, isSubmitting, handleSubmit } = useFormik({
        initialValues: { cardChargeFee: feeToEdit.percentValue },
        validationSchema,
        onSubmit
    })

    const renderBody = () => {
        return (
            <form className={cx("form")} onSubmit={handleSubmit}>
                <div className={cx("row", "type-container")}>
                    <label className={cx("label")}>Card charge fee (%)</label>
                    <Input
                        error={errors.cardChargeFee}
                        value={values.cardChargeFee}
                        onChange={({ event }) => {
                            setFieldValue("cardChargeFee", event.target.value)
                        }}
                    />
                </div>
                <div className={cx("buttons")}>
                    <span className={cx("form-cancel")} onClick={handleClose}>
                        Cancel
                    </span>
                    <Button
                        type="submit"
                        className="first-button"
                        customStyles={submitButtonStyles}
                        label="Save"
                        isLoading={isSubmitting}
                    />
                </div>
            </form>
        )
    }

    return (
        <Modal isOpen closeModal={handleClose} preventClickOutside>
            <Modal.Header>Edit Card charge fee</Modal.Header>
            <Modal.Body>{renderBody()}</Modal.Body>
        </Modal>
    )
}

EditCardChargeFeeModal.propTypes = {
    feeToEdit: PropTypes.shape({
        id: PropTypes.number,
        key: PropTypes.string,
        percentValue: PropTypes.string
    }).isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withStyles(EditCardChargeFeeModal, styles)
