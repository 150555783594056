import React, { useState, useEffect, useMemo, useContext, Fragment } from "react"
import { Link, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import classNames from "classnames"
import _get from "lodash/get"
import { subMonths, subYears } from "date-fns"
import qs from "qs"

import {
    H1,
    Table,
    TableHead,
    TableHeadCell,
    TableSortableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    TableFilterBar,
    TableFilterBarMoreFiltersButton,
    TableFilterBarMoreFilters,
    TableFilterBarMoreFiltersGrid,
    TableFilterBarMoreFiltersGridItem,
    TableFilterBarSearch,
    TableFilterBarSelect,
    useTableFilterBarSearch,
    useTableFilterBarSelect,
    useTableSort,
    useTableSelectedRows,
    useTableFilterBarMultiselect,
    useTableFilterBarMoreFilters,
    Checkbox,
    EmptyList,
    GridIcon,
    Panel,
    Status,
    Button,
    EditIcon,
    SnakeLoader,
    ListSummary
} from "@butterfly-frontend/ui"
import { Dropdown, Input, Pagination, SelectWithSearch } from "ui"
import { SelectBrand, SelectProductConditions } from "ui/Filters"
import { useIsTablet } from "helpers/rwd"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import { Header } from "modules/WmsModule/components"
import { StockItemsReview, DeleteStockItem, StockTransferContainer } from "modules/WmsModule/modals"
import STOCK_ITEM_STATUS_FILTER_OPTIONS from "modules/WmsModule/constants/stockItemStatusFilterOptions"
import STOCK_ITEM_STATUSES from "modules/WmsModule/constants/stockItemStatuses"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import { useStockItemList, useStockItemReview } from "modules/WmsModule/hooks/api/useStockItem"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import ListFiltersContext, { INITIAL_STOCK_MANAGEMENT_FILTERS } from "modules/WmsModule/contexts/ListFiltersContext"
import { useCounters, useBoxLabelOptions } from "modules/WmsModule/hooks"
import { createLinkToStockAdjustment } from "modules/WmsModule/helpers/createLinkToStockAdjustment"
import { checkIfCanCreateStockAdjustment } from "modules/WmsModule/helpers/stockItem"

import ProductNameCell from "./ProductNameCell"
import TABLE_COLUMNS from "./constants/tableColumns"
import DISPLAYED_COUNTER_KEYS from "./constants/displayedCounterKeys"
import inputStyles from "./overrides/input.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.css"
import styles from "./StockManagementListPage.module.css"

const inStockFromOptionsFactory = () => {
    const now = new Date()
    const minDate = new Date(0)

    return [
        { id: 1, label: "<3 month", value: { createdFrom: subMonths(now, 3), createdTo: now } },
        { id: 2, label: "<6 month", value: { createdFrom: subMonths(now, 6), createdTo: now } },
        {
            id: 3,
            label: "<1 year",
            value: { createdFrom: subYears(now, 1), createdTo: now }
        },
        { id: 4, label: "+1 year", value: { createdFrom: minDate, createdTo: subYears(now, 1) } },
        { id: 5, label: "+2 year", value: { createdFrom: minDate, createdTo: subYears(now, 2) } },
        { id: 6, label: "+3 year", value: { createdFrom: minDate, createdTo: subYears(now, 3) } }
    ]
}

const SELECTED_BOX_OPTIONS_LABELS = {
    print: "Print selected labels",
    download: "Download selected labels"
}

const warehouseOptionsFactory = warehouseList => warehouseList.map(({ id, name: label }) => ({ id, label, value: id }))

const StockManagementListPage = ({ canDelete, checkPermission }) => {
    const [isReviewModalOpen, setIsReviewModalOpen] = useState(false)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isStockTransferModalOpen, setIsStockTransferModalOpen] = useState(false)
    const [itemIdForProductDetailsPopup, setItemIdForProductDetailsPopup] = useState(null)
    const [listLength, setListLength] = useState(20)
    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)
    const {
        set: setFiltersContext,
        values: { stockManagement: filtersFromContext }
    } = useContext(ListFiltersContext)

    const isTablet = useIsTablet()
    const warehouseList = useWarehouseList({
        params: {
            warehouses: assignedWarehouseIds
        }
    })

    const { location: routerLocation, replace: replaceUrl, push: historyPush } = useHistory()

    const {
        moreFiltersButtonProps,
        moreFiltersComponentProps,
        ...filterBarMoreFiltersProps
    } = useTableFilterBarMoreFilters({
        emptyFilterValues: INITIAL_STOCK_MANAGEMENT_FILTERS.moreFilters,
        initialFilterValues: filtersFromContext.moreFilters,
        canClear: true
    })

    const warehouseIdsFromQueryString = useMemo(() => {
        const queryObject = qs.parse(routerLocation.search, { ignoreQueryPrefix: true })

        if (queryObject.warehouses) {
            return queryObject.warehouses
                .split(",")
                .filter(id => !Number.isNaN(id))
                .map(id => parseInt(id, 10))
        }

        return []
    }, [routerLocation.search])

    const persistedWarehouseFiltersIds = useMemo(() => {
        const warehouseIdsFromExternalParams =
            warehouseIdsFromQueryString.length > 0
                ? warehouseIdsFromQueryString
                : filtersFromContext.warehouses.map(({ id }) => id)

        return assignedWarehouseIds.filter(assignedWarehouseId =>
            warehouseIdsFromExternalParams.includes(assignedWarehouseId)
        )
    }, [])

    const warehouseMultiselect = useTableFilterBarMultiselect({
        isEnabled: warehouseList.fetchStatus.isLoaded,
        initialOptions: warehouseOptionsFactory(warehouseList.data),
        initialSelectedIds: persistedWarehouseFiltersIds
    })
    const filterBarSearchProps = useTableFilterBarSearch({ initialValue: filtersFromContext.query })
    const inStockFromSelect = useTableFilterBarSelect({
        initialOptions: inStockFromOptionsFactory(),
        initialSelectedId: filtersFromContext.inStockFrom ? filtersFromContext.inStockFrom.id : undefined
    })
    const { sortState, onChangeSort } = useTableSort({ initialState: { direction: "asc", by: "product" } })

    const stockItemReview = useStockItemReview()
    const handleCreateStockItemReview = () => {
        stockItemReview.mutate({ ids: selectedRows.map(({ id }) => id) }).then(() => setIsReviewModalOpen(true))
    }

    const isRowDisabled = row =>
        checkIfCanCreateStockAdjustment({
            status: row.status,
            condition: _get(row, "condition.type", ""),
            isDuringShipment: row.during_shipment
        })

    useEffect(() => {
        setFiltersContext(prevState => ({
            ...prevState,
            stockManagement: {
                query: filterBarSearchProps.debouncedValue,
                inStockFrom: inStockFromSelect.selectedValue,
                moreFilters: filterBarMoreFiltersProps.savedFilterValues,
                warehouses: warehouseMultiselect.selectedValues
            }
        }))
    }, [
        inStockFromSelect.selectedValue,
        warehouseMultiselect.selectedValues,
        filterBarSearchProps.debouncedValue,
        filterBarMoreFiltersProps.savedFilterValues
    ])

    const filters = useMemo(() => {
        const {
            statuses,
            productConditions,
            brands,
            transfers_count,
            order_uuid,
            invoice_uuid,
            incoming
        } = filterBarMoreFiltersProps.savedFilterValues

        return {
            query: filterBarSearchProps.debouncedValue,
            warehouses: warehouseMultiselect.selectedValues.map(({ value }) => value),
            conditions: productConditions.map(({ id }) => id),
            brands: brands.map(({ id }) => id),
            statuses: statuses.map(({ id }) => id),
            transfers_count,
            order_uuid,
            invoice_uuid,
            incoming: incoming ? incoming.value : null,
            show_sold: false,
            created_to: inStockFromSelect.selectedValue ? inStockFromSelect.selectedValue.value.createdTo : "",
            created_from: inStockFromSelect.selectedValue ? inStockFromSelect.selectedValue.value.createdFrom : "",
            length: listLength
        }
    }, [
        inStockFromSelect.selectedValue,
        warehouseMultiselect.selectedValues,
        filterBarSearchProps.debouncedValue,
        filterBarMoreFiltersProps.savedFilterValues,
        listLength
    ])

    const { data, meta, fetchStatus, pagination, refetch, fetchFlags } = useTableLogic({
        sort: sortState,
        filters,
        isEnabled: warehouseMultiselect.isReady,
        useQueryList: useStockItemList
    })

    const {
        selectedRows,
        isAnyRowSelected,
        isAnyRowOnCurrentPageSelected,
        areAllRowsOnCurrentPageSelected,
        checkIsRowSelected,
        unselectRow,
        unselectAllRows,
        toggleRowSelection,
        toggleAllCurrentPageRowsSelection,
        selectedRowsCount
    } = useTableSelectedRows({
        initialSelections: [],
        currentPageRows: data,
        checkIfRowIsEnabled: row => !isRowDisabled(row)
    })

    const { options: selectedItemsBoxOptions, isLoading: isSelectedItemsBoxOptionsLoading } = useBoxLabelOptions({
        ids: selectedRows.map(({ id }) => id),
        labels: SELECTED_BOX_OPTIONS_LABELS
    })

    useEffect(() => {
        if (warehouseMultiselect.isReady) {
            replaceUrl(
                `${routerLocation.pathname}?warehouses=${warehouseMultiselect.selectedValues
                    .map(({ id }) => id)
                    .join(",")}`
            )
        }
    }, [warehouseMultiselect.selectedValues, warehouseMultiselect.isReady])

    const stockAdjustmentDropdownOptions = useMemo(
        () => [
            ...(checkPermission(PERMISSIONS.types.STOCK_ADJUSTMENT_ON, PERMISSIONS.context.STOCK_ITEMS)
                ? [
                      {
                          label: "Stock adjustment",
                          onClick: () =>
                              historyPush(
                                  createLinkToStockAdjustment({
                                      stockItems: selectedRows,
                                      returnPath: "/wms/stock-management"
                                  })
                              )
                      }
                  ]
                : []),
            ...(selectedRows.length > 0 ? selectedItemsBoxOptions : []),
            ...(selectedRows.length === 1 && canDelete(PERMISSIONS.context.STOCK_ITEMS)
                ? [
                      {
                          label: "Remove item",
                          onClick: () => setIsDeleteModalOpen(true)
                      }
                  ]
                : [])
        ],
        [selectedRows, selectedItemsBoxOptions, historyPush]
    )

    const renderUnselectAllButton = () => (
        <Button
            onClick={unselectAllRows}
            variant="flat"
            classes={{
                button: styles.unselectAllRowsButton
            }}
        >
            Unselect all
        </Button>
    )

    const counters = useCounters({ counterKeys: DISPLAYED_COUNTER_KEYS, countersFromApi: meta.counters })

    return (
        <React.Fragment>
            <Header classes={{ header: styles.header }}>
                <div className={styles.titleWrapper}>
                    <H1
                        selectedItemsCount={selectedRowsCount}
                        withLeftBorder
                        classes={{ selectedItemsCounter: styles.selectedItemsCounter }}
                    >
                        Stock Management
                    </H1>
                    {isTablet && isAnyRowSelected && renderUnselectAllButton()}
                </div>
                <div className={styles.buttonsWrapper}>
                    {!isTablet && isAnyRowSelected && renderUnselectAllButton()}
                    {selectedRows.length > 0 &&
                        checkPermission(PERMISSIONS.types.SPREADSHEET_EDIT, PERMISSIONS.context.STOCK_ITEMS) && (
                            <Button
                                Icon={EditIcon}
                                onClick={handleCreateStockItemReview}
                                isLoading={stockItemReview.fetchStatus.isLoading}
                            >
                                Edit
                            </Button>
                        )}
                    {stockAdjustmentDropdownOptions.length > 0 && (
                        <Dropdown
                            label={
                                <Fragment>
                                    Stock Adjustment
                                    {isSelectedItemsBoxOptionsLoading && (
                                        <SnakeLoader
                                            classes={{
                                                root: styles.dropdownLoaderWrapper,
                                                loader: styles.dropdownLoader
                                            }}
                                        />
                                    )}
                                </Fragment>
                            }
                            className={styles.stockAdjustmentDropdown}
                            options={stockAdjustmentDropdownOptions}
                            disabled={isSelectedItemsBoxOptionsLoading}
                        />
                    )}
                    {selectedRows.length > 0 && (
                        <Button onClick={() => setIsStockTransferModalOpen(true)} color="blue">
                            Stock transfer
                        </Button>
                    )}
                </div>
            </Header>

            {fetchStatus.isFirstFetch ? (
                <Panel>
                    <SnakeLoader />
                </Panel>
            ) : (
                <Panel>
                    <TableFilterBar
                        moreFiltersComponent={
                            <TableFilterBarMoreFilters {...moreFiltersComponentProps}>
                                <TableFilterBarMoreFiltersGrid>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <SelectProductConditions
                                            name="productConditions"
                                            label="Conditions"
                                            value={filterBarMoreFiltersProps.selectedFilterValues.productConditions}
                                            handleSelect={({ target: { value } }) =>
                                                filterBarMoreFiltersProps.onChange("productConditions", value)
                                            }
                                            customStyles={selectWithSearchStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <Input
                                            label="Transfers number"
                                            innerLabel=">"
                                            isInnerLabelLeft
                                            type="number"
                                            isInline
                                            value={filterBarMoreFiltersProps.selectedFilterValues.transfers_count}
                                            onChange={({
                                                event: {
                                                    target: { value }
                                                }
                                            }) => filterBarMoreFiltersProps.onChange("transfers_count", value)}
                                            customStyles={inputStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <SelectWithSearch
                                            value={filterBarMoreFiltersProps.selectedFilterValues.statuses}
                                            setValue={value => filterBarMoreFiltersProps.onChange("statuses", value)}
                                            label="Status"
                                            values={STOCK_ITEM_STATUS_FILTER_OPTIONS}
                                            isClearBlocked
                                            withoutFetch
                                            multiselect
                                            customStyles={selectWithSearchStyles}
                                            withoutSearch
                                            showSelectAllLabel={false}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <SelectBrand
                                            handleSelect={({ target: { value } }) =>
                                                filterBarMoreFiltersProps.onChange("brands", value)
                                            }
                                            name="brands"
                                            value={filterBarMoreFiltersProps.selectedFilterValues.brands}
                                            multiselect
                                            label="Brands"
                                            customStyles={selectWithSearchStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <Input
                                            label="Sales order"
                                            isInline
                                            customPlaceholder="SO-110000"
                                            value={filterBarMoreFiltersProps.selectedFilterValues.order_uuid}
                                            onChange={({
                                                event: {
                                                    target: { value }
                                                }
                                            }) => filterBarMoreFiltersProps.onChange("order_uuid", value)}
                                            customStyles={inputStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <Input
                                            label="Invoice"
                                            isInline
                                            customPlaceholder="IN-310000"
                                            value={filterBarMoreFiltersProps.selectedFilterValues.invoice_uuid}
                                            onChange={({
                                                event: {
                                                    target: { value }
                                                }
                                            }) => filterBarMoreFiltersProps.onChange("invoice_uuid", value)}
                                            customStyles={inputStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <SelectWithSearch
                                            value={_get(
                                                filterBarMoreFiltersProps.selectedFilterValues,
                                                "incoming.label"
                                            )}
                                            setValue={value =>
                                                filterBarMoreFiltersProps.onChange(
                                                    "incoming",
                                                    typeof value.value === "boolean" ? value : null
                                                )
                                            }
                                            label="Incoming"
                                            values={[
                                                { id: 0, label: "Yes", value: true },
                                                { id: 1, label: "No", value: false }
                                            ]}
                                            withoutFetch
                                            withoutSearch
                                            customStyles={selectWithSearchStyles}
                                            showSelectAllLabel={false}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                </TableFilterBarMoreFiltersGrid>
                            </TableFilterBarMoreFilters>
                        }
                    >
                        <TableFilterBarSearch placeholder="Search for product..." {...filterBarSearchProps} />
                        <TableFilterBarSelect
                            labelSelectAll="All warehouse"
                            placeholder="Warehouse"
                            numberOfDisplayedItems={6}
                            isLoading={warehouseList.fetchStatus.isLoading}
                            {...warehouseMultiselect.componentProps}
                        />
                        <TableFilterBarSelect
                            labelSelectAll="All time"
                            placeholder="In stock from"
                            {...inStockFromSelect.componentProps}
                        />
                        <TableFilterBarMoreFiltersButton {...moreFiltersButtonProps} />
                    </TableFilterBar>
                    <div className={styles.table}>
                        {fetchStatus.isLoading && !fetchStatus.isFirstFetch ? (
                            <SnakeLoader />
                        ) : (
                            <Table>
                                <ListSummary
                                    from={meta.from}
                                    to={meta.to}
                                    perPage={Number(meta.per_page)}
                                    total={meta.total}
                                    counters={counters}
                                    setLength={setListLength}
                                />
                                <TableHead>
                                    <TableHeadCell {...TABLE_COLUMNS.checkbox}>
                                        <Checkbox
                                            checked={areAllRowsOnCurrentPageSelected}
                                            partiallyChecked={isAnyRowOnCurrentPageSelected}
                                            onChange={toggleAllCurrentPageRowsSelection}
                                            disabled={fetchStatus.isLoading}
                                        />
                                    </TableHeadCell>
                                    <TableSortableHeadCell
                                        sortState={sortState}
                                        columnKey="product"
                                        onClick={onChangeSort}
                                        {...TABLE_COLUMNS.productName}
                                    >
                                        PRODUCT NAME
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        sortState={sortState}
                                        columnKey="brand"
                                        onClick={onChangeSort}
                                        {...TABLE_COLUMNS.brand}
                                    >
                                        BRAND NAME
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        sortState={sortState}
                                        columnKey="id"
                                        onClick={onChangeSort}
                                        {...TABLE_COLUMNS.id}
                                    >
                                        ID
                                    </TableSortableHeadCell>
                                    <TableHeadCell {...TABLE_COLUMNS.transfers}>TRF.</TableHeadCell>
                                    <TableHeadCell {...TABLE_COLUMNS.condition}>CONDITION</TableHeadCell>
                                    <TableHeadCell {...TABLE_COLUMNS.status}>STATUS</TableHeadCell>
                                    <TableSortableHeadCell
                                        sortState={sortState}
                                        columnKey="warehouse"
                                        onClick={onChangeSort}
                                        {...TABLE_COLUMNS.warehouse}
                                    >
                                        WH
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        sortState={sortState}
                                        columnKey="location"
                                        onClick={onChangeSort}
                                        {...TABLE_COLUMNS.location}
                                    >
                                        LOC
                                    </TableSortableHeadCell>
                                    <TableHeadCell {...TABLE_COLUMNS.action} />
                                </TableHead>
                                <TableBody>
                                    {fetchFlags.isEmptyList && (
                                        <EmptyList Icon={GridIcon} label="Warehouse is empty. Add products." />
                                    )}
                                    {fetchFlags.isEmptyListWithFilter && (
                                        <EmptyList Icon={GridIcon} label="No items found for selected filters." />
                                    )}
                                    {fetchStatus.isLoaded &&
                                        data.map(row => {
                                            const isDisabled = isRowDisabled(row)
                                            const status = STOCK_ITEM_STATUSES[row.status]

                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    classNames={{
                                                        root: classNames(styles.row, {
                                                            [styles.disabledRow]: isDisabled
                                                        })
                                                    }}
                                                >
                                                    <TableCell {...TABLE_COLUMNS.checkbox}>
                                                        <Checkbox
                                                            checked={checkIsRowSelected(row)}
                                                            onChange={() => toggleRowSelection(row)}
                                                            disabled={isDisabled}
                                                        />
                                                    </TableCell>
                                                    <TableCell {...TABLE_COLUMNS.productName}>
                                                        <ProductNameCell
                                                            stockItem={row}
                                                            isPopupOpen={itemIdForProductDetailsPopup === row.id}
                                                            onPopupOpen={() => setItemIdForProductDetailsPopup(row.id)}
                                                            onPopupClose={() => setItemIdForProductDetailsPopup(null)}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        {...TABLE_COLUMNS.brand}
                                                        classes={{ root: styles.grayColorCell }}
                                                    >
                                                        {row.brand.name}
                                                    </TableCell>
                                                    <TableCell
                                                        {...TABLE_COLUMNS.id}
                                                        classes={{ root: styles.grayColorCell }}
                                                    >
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell
                                                        {...TABLE_COLUMNS.transfers}
                                                        classes={{ root: styles.grayColorCell }}
                                                    >
                                                        {row.transfers_count || "-"}
                                                    </TableCell>
                                                    <TableCell {...TABLE_COLUMNS.condition}>
                                                        {row.condition ? (
                                                            <div className={styles.statusWrapper}>
                                                                <Status color={row.condition.color}>
                                                                    {row.condition.name}
                                                                </Status>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </TableCell>
                                                    <TableCell {...TABLE_COLUMNS.status}>
                                                        {row.status ? (
                                                            <div className={styles.statusWrapper}>
                                                                <Status color={status.color} theme={status.theme}>
                                                                    {status.label}
                                                                </Status>
                                                            </div>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </TableCell>
                                                    <TableCell
                                                        {...TABLE_COLUMNS.warehouse}
                                                        classes={{ root: styles.grayColorCell }}
                                                    >
                                                        {row.warehouse.name}
                                                    </TableCell>
                                                    <TableCell
                                                        {...TABLE_COLUMNS.location}
                                                        classes={{ root: styles.grayColorCell }}
                                                    >
                                                        {_get(row, "location.name", "-")}
                                                    </TableCell>
                                                    <TableCell
                                                        {...TABLE_COLUMNS.action}
                                                        classes={{ root: styles.detailsCell }}
                                                    >
                                                        <Link to={`/wms/stock-management/${row.id}`}>Details</Link>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>
                                {pagination.canDisplay && (
                                    <div className={styles.paginationWrapper}>
                                        <Pagination {...pagination} />
                                    </div>
                                )}
                            </Table>
                        )}
                    </div>
                </Panel>
            )}

            {isReviewModalOpen && stockItemReview.data && (
                <StockItemsReview
                    onClose={() => setIsReviewModalOpen(false)}
                    onSuccess={() => {
                        refetch()
                        setIsReviewModalOpen(false)
                        unselectAllRows()
                    }}
                    fileData={stockItemReview.data.data}
                />
            )}

            {isDeleteModalOpen && selectedRows.length > 0 && (
                <DeleteStockItem
                    onClose={() => setIsDeleteModalOpen(false)}
                    onSuccess={() => {
                        refetch()
                        setIsDeleteModalOpen(false)
                        unselectAllRows()
                    }}
                    stockItem={selectedRows[0]}
                />
            )}

            <StockTransferContainer
                isOpen={isStockTransferModalOpen}
                onClose={() => setIsStockTransferModalOpen(false)}
                onSuccess={() => {
                    refetch()
                    setIsStockTransferModalOpen(false)
                    unselectAllRows()
                }}
                onRemoveItem={unselectRow}
                selectedItems={selectedRows}
            />
        </React.Fragment>
    )
}

StockManagementListPage.propTypes = {
    canDelete: PropTypes.func.isRequired,
    checkPermission: PropTypes.func.isRequired
}

export default withPermissions(StockManagementListPage)
