import * as constants from "../constants"

export function LoginReducer(state = {}, action) {
    switch (action.type) {
        case constants.LOGIN_START:
            return {
                ...state,
                activeUser: false,
                errors: null,
                token: null,
                isError: false,
                permissions: [],
                assignedWarehouseIds: [],
                email: ""
            }
        case constants.LOGIN_SUCCESS:
            const { data, email } = action.payload.data
            return {
                ...state,
                activeUser: true,
                token: data.jwt_token,
                errors: null,
                userId: data.user_id,
                userName: data.user_name,
                userFirstName: data.first_name,
                userLastName: data.last_name,
                userAvatar: data.avatar,
                permissions: data.permissions,
                assignedWarehouseIds: data.warehouses ? data.warehouses.map(({ id }) => id) : undefined,
                email,
                roles: data.roles
            }
        case constants.LOGIN_ERROR:
            return {
                ...state,
                activeUser: false,
                isError: true,
                token: null,
                errors: action.payload.error,
                errorMessage: "Invalid email or password",
                permissions: [],
                assignedWarehouseIds: [],
                email: ""
            }

        case constants.SET_PASSWORD_START:
            return { ...state }
        case constants.SET_PASSWORD_SUCCESS:
            return { ...state }
        case constants.SET_PASSWORD_ERROR:
            return { ...state }

        default:
            return state
    }
}
