import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import { Button, Input } from "ui"
import ActionButton from "../ActionButton"
import editIcon from "assets/pencil.svg"
import _ from "lodash"

import editButtonStyles from "./overrides/EditButton.css"
import resetButtonStyles from "./overrides/ResetButton.css"
import addButtonStyles from "./overrides/AddButton.css"
import inputStyles from "./overrides/Input.css"
import styles from "./Header.css"

class Header extends Component {
    render() {
        const {
            cx,
            title,
            tabs,
            isEdit,
            addButton,
            editButton,
            resetButton,
            customButton,
            handleStartEdit,
            handleChangeName,
            isEnabledEdit,
            routePrefixParam,
            typeItems
        } = this.props
        const buttonsConfig = [
            {
                name: "addButton",
                label: addButton && addButton.label,
                handler: addButton && addButton.handler,
                alt: "Add",
                customStyles: addButtonStyles,
                isDisabled: addButton && addButton.isDisabled,
                order: (addButton && addButton.order) || 1
            },
            {
                name: "editButton",
                label: editButton && editButton.label,
                handler: editButton && editButton.handler,
                alt: "Edit",
                customStyles: editButtonStyles,
                isDisabled: editButton && editButton.isDisabled,
                order: (editButton && editButton.order) || 2
            },
            {
                name: "resetButton",
                label: resetButton && resetButton.label,
                handler: resetButton && resetButton.handler,
                alt: "Reset",
                customStyles: resetButtonStyles,
                isDisabled: resetButton && resetButton.isDisabled,
                order: (resetButton && resetButton.order) || 3
            }
        ]
        const sortedButtons = _.sortBy(buttonsConfig, button => button.order)

        return (
            <div className={cx("root", { isTabs: !!tabs })}>
                <div className={cx("hero")}>
                    {isEdit ? (
                        <Input
                            isPlaceholder
                            label={`${typeItems} ${routePrefixParam}`}
                            isInline
                            value={title}
                            onChange={handleChangeName}
                            customStyles={inputStyles}
                        />
                    ) : (
                        <h1 className={cx("title")}>{title}</h1>
                    )}
                    <div className={cx("actions")}>
                        {isEnabledEdit && !isEdit && (
                            <ActionButton icon={editIcon} text="Edit name" handleAction={handleStartEdit} />
                        )}

                        {sortedButtons.map(({ name, label, handler, alt, customStyles, isDisabled }) => {
                            if (this.props[name]) {
                                return (
                                    <Button
                                        className={cx("first-button", "white")}
                                        customStyles={customStyles}
                                        label={label || alt}
                                        onClick={handler}
                                        key={name}
                                        isDisabled={isDisabled}
                                    />
                                )
                            }
                            return null
                        })}
                        {customButton}
                    </div>
                </div>
                {tabs && (
                    <div className={cx("tabs")}>
                        {tabs.map(({ label, onClick, isActive }) => {
                            return (
                                <p key={label} onClick={onClick} className={cx({ isActive })}>
                                    {label}
                                </p>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(Header, styles)
