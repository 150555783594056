import React, { Fragment, useState } from "react"
import { Field } from "formik"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import { Input, SelectWithSearch, DatePickerWithInput, Button, Textarea } from "ui"

import styles from "./styles.css"
import inputStyles from "../../overrides/input.css"
import datePickerStyles from "../../overrides/date-picker.css"
import editIcon from "assets/pencil.svg"
import eyeIcon from "assets/eye.svg"
import mailIcon from "assets/ico-mail_blue.svg"

const ProfileSection = ({ cx, form, states, countries, isMyProfile, handleResetPassword }) => {
    const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false)
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
    const [isNewPasswordConfirmationVisible, setIsNewPasswordConfirmationVisible] = useState(false)
    const [isPasswordResetting, setIsPasswordResetting] = useState(false)

    const resetPassword = async () => {
        setIsPasswordResetting(true)

        await handleResetPassword()

        setIsPasswordResetting(false)
    }

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <h2 className={cx("header")}>General information</h2>
                <ul className={cx("generalInformationWrapper")}>
                    <li className={cx("firstName")}>
                        <Field name="first_name">
                            {({ field, meta }) => (
                                <Input
                                    {...field}
                                    label="First Name"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={meta.error}
                                    customStyles={inputStyles}
                                    attributes={{ required: true }}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("lastName")}>
                        <Field name="last_name">
                            {({ field, meta }) => (
                                <Input
                                    {...field}
                                    label="Last Name"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={meta.error}
                                    customStyles={inputStyles}
                                    attributes={{ required: true }}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("dateOfBirth")}>
                        <Field name="birthday">
                            {({ field, meta }) => (
                                <DatePickerWithInput
                                    {...field}
                                    label="Date of Birth:"
                                    date={field.value}
                                    handleSelectDate={date => form.setFieldValue(field.name, date)}
                                    error={meta.error}
                                    customStyles={datePickerStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("email")}>
                        <Field name="email">
                            {({ field, meta }) => (
                                <Input
                                    {...field}
                                    label="E-mail address"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={meta.error}
                                    customStyles={inputStyles}
                                    isDisabled={true}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("phone")}>
                        <Field name="phone">
                            {({ field, meta }) => (
                                <Input
                                    {...field}
                                    label="Phone number"
                                    onChange={({ event }) => form.setFieldValue(field.name, event.target.value)}
                                    error={meta.error}
                                    customStyles={inputStyles}
                                    isPhoneInput={true}
                                />
                            )}
                        </Field>
                    </li>
                </ul>
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>Address</h2>
                <ul className={cx("addressWrapper")}>
                    <li className={cx("country")}>
                        <Field name="country">
                            {({ field, meta }) => (
                                <SelectWithSearch
                                    {...field}
                                    label="Country"
                                    values={countries.map(country => ({ label: country, value: country }))}
                                    setValue={({ value }) => {
                                        if (value !== field.value) {
                                            form.setFieldValue("state", "")
                                            field.onChange({ target: { name: field.name, value } })
                                        }
                                    }}
                                    error={meta.error}
                                    withoutFetch
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("address")}>
                        <Field name="address">
                            {({ field, meta }) => (
                                <Input
                                    {...field}
                                    label="Address (Street and No.)"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={meta.error}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("city")}>
                        <Field name="city">
                            {({ field, meta }) => (
                                <Input
                                    {...field}
                                    label="City"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={meta.error}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("state")}>
                        <Field name="state">
                            {({ field, meta }) =>
                                form.values.country === "United States of America" ? (
                                    <SelectWithSearch
                                        {...field}
                                        label="State"
                                        values={states.map(states => ({ label: states, value: states }))}
                                        setValue={({ value }) =>
                                            field.onChange({ target: { name: field.name, value } })
                                        }
                                        placeholder="- Please select -"
                                        withoutFetch
                                        isListOnTop
                                    />
                                ) : (
                                    <Input
                                        {...field}
                                        label="State"
                                        onChange={({ event }) => field.onChange(event)}
                                        error={meta.error}
                                        customStyles={inputStyles}
                                    />
                                )
                            }
                        </Field>
                    </li>
                    <li className={cx("zipCode")}>
                        <Field name="post_code">
                            {({ field, meta }) => (
                                <Input
                                    {...field}
                                    label="ZIP Code"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={meta.error}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                </ul>
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>Signature</h2>
                <Field name="signature">
                    {({ field }) => (
                        <Textarea
                            {...field}
                            className={cx("signature-textarea")}
                            placeholder="Email signature"
                            onChange={({ event }) => field.onChange(event)}
                        />
                    )}
                </Field>
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>
                    Password
                    {form.values.isChangingPassword && (
                        <Button
                            className={cx("button")}
                            onClick={() => form.setFieldValue("isChangingPassword", false)}
                            label="Hide changing password"
                        />
                    )}
                </h2>

                {isMyProfile ? (
                    <ul className={cx("passwordWrapper")}>
                        {form.values.isChangingPassword ? (
                            <Fragment>
                                <li className={cx("currentPassword")}>
                                    <Field name="old_password">
                                        {({ field, meta }) => (
                                            <Input
                                                {...field}
                                                label="Current password"
                                                type={isCurrentPasswordVisible ? "text" : "password"}
                                                onChange={({ event }) => field.onChange(event)}
                                                error={meta.error}
                                                customStyles={inputStyles}
                                            />
                                        )}
                                    </Field>
                                    <img
                                        src={eyeIcon}
                                        alt=""
                                        onClick={() => {
                                            setIsCurrentPasswordVisible(!isCurrentPasswordVisible)
                                        }}
                                    />
                                </li>
                                <li className={cx("newPassword")}>
                                    <Field name="password">
                                        {({ field, meta }) => (
                                            <Input
                                                {...field}
                                                label="New password"
                                                type={isNewPasswordVisible ? "text" : "password"}
                                                onChange={({ event }) => field.onChange(event)}
                                                error={meta.error}
                                                customStyles={inputStyles}
                                            />
                                        )}
                                    </Field>
                                    <img
                                        src={eyeIcon}
                                        alt=""
                                        onClick={() => {
                                            setIsNewPasswordVisible(!isNewPasswordVisible)
                                        }}
                                    />
                                </li>
                                <li className={cx("newPasswordConfirmation")}>
                                    <Field name="password_confirmation">
                                        {({ field, meta }) => (
                                            <Input
                                                {...field}
                                                label="New password confirmation"
                                                type={isNewPasswordConfirmationVisible ? "text" : "password"}
                                                onChange={({ event }) => field.onChange(event)}
                                                error={meta.error}
                                                customStyles={inputStyles}
                                            />
                                        )}
                                    </Field>
                                    <img
                                        src={eyeIcon}
                                        alt=""
                                        onClick={() => {
                                            setIsNewPasswordConfirmationVisible(!isNewPasswordConfirmationVisible)
                                        }}
                                    />
                                </li>
                            </Fragment>
                        ) : (
                            <li className={cx("currentPasswordPlaceholder")}>
                                <Input
                                    value="******"
                                    type="password"
                                    label="Current password"
                                    isDisabled={true}
                                    customStyles={inputStyles}
                                />

                                <span
                                    className={cx("changePasswordButton")}
                                    onClick={() => form.setFieldValue("isChangingPassword", true)}
                                >
                                    <img src={editIcon} alt="" /> Change
                                </span>
                            </li>
                        )}
                    </ul>
                ) : (
                    <span className={cx("resetPasswordButton")} onClick={() => !isPasswordResetting && resetPassword()}>
                        <img src={mailIcon} alt="" /> Send e-mail with password reset link
                    </span>
                )}
            </div>
        </div>
    )
}

ProfileSection.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    states: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired
}

const mapStateToProps = state => {
    return {
        states: state.clientStore.statesList,
        countries: state.clientStore.countriesList
    }
}

export default compose(connect(mapStateToProps))(withStyles(ProfileSection, styles))
