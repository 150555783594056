import React, { Component } from "react"
import ReactDropzone from "react-dropzone"
import styles from "./Dropzone.css"
import withStyles from "HOC/withStyles"
import pencil from "assets/pencil.svg"

class Dropzone extends Component {
    static defaultProps = {
        disableClick: false,
        disabled: false
    }
    render() {
        const {
            cx,
            activeClassName,
            handleUpload,
            disabled,
            disableClick,
            isUploading,
            children,
            isMultiple,
            acceptFormats
        } = this.props

        return (
            <div className={cx("root")}>
                <ReactDropzone
                    activeClassName={activeClassName}
                    disableClick={disableClick}
                    className={cx("dropzone")}
                    accept={acceptFormats || "image/jpeg,image/png"}
                    onDrop={handleUpload}
                    maxSize={500 * 1024 * 1024}
                    multiple={isMultiple || false}
                    disabled={disabled ? disabled : isUploading}
                >
                    {children || this.renderPlaceholder()}
                </ReactDropzone>
            </div>
        )
    }

    renderPlaceholder() {
        const { cx, icon, label } = this.props

        return (
            <div className={cx("placeholder")}>
                <img src={icon || pencil} alt="add" />
                <span>{label || "Add New"}</span>
            </div>
        )
    }
}

export default withStyles(Dropzone, styles)
