const ns = "modals/chooseProduct/"

export const GET_ORDERS_REQUEST = ns + "GET_ORDERS_REQUEST"
export const GET_ORDERS_SUCCESS = ns + "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAILURE = ns + "GET_ORDERS_FAILURE"
export const RESET_ORDERS = ns + "RESET_PRODUCTS"

export const GET_MORE_ORDERS_REQUEST = ns + "GET_MORE_ORDERS_REQUEST"
export const GET_MORE_ORDERS_SUCCESS = ns + "GET_MORE_ORDERS_SUCCESS"
export const GET_MORE_ORDERS_FAILURE = ns + "GET_MORE_ORDERS_FAILURE"
