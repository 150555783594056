import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import * as notificationActions from "actions/notification"
import fetch from "helpers/fetch"

import { Attachments as AttachmentsList, SubmitModal } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import withStyles from "HOC/withStyles"
import styles from "./attachments.module.css"

const defaultSorting = { by: "created_at", direction: "asc" }

const Attachments = props => {
    const { cx, clientId, showErrorNotification, handleAttachmentsCount, showSuccessNotification } = props

    const [isLoading, setIsLoading] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [attachments, setAttachments] = useState([])
    const [sorting, setSorting] = useState(defaultSorting)
    const [deleteData, setDeleteData] = useState({ isOpen: false, id: null })

    useEffect(() => {
        getAttachments()
    }, [clientId, sorting])

    const getAttachments = upload => {
        !upload && setIsLoading(true)
        fetch
            .get(
                `/notes?object_type=client&object_id=${clientId}&with_attachments=true&sort_by=${sorting.by}&sort_direction=${sorting.direction}`
            )
            .then(data => {
                setAttachments(data.data)
                handleAttachmentsCount(data.data.length)
            })
            .catch(() => showErrorNotification())
            .finally(() => {
                !upload && setIsLoading(false)
                upload && setIsUploading(false)
            })
    }

    const handleUpload = uploadState => {
        const body = {
            only_attachment: true,
            object_type: "client",
            object_id: clientId,
            attachments: uploadState.map(attachment => attachment.id)
        }

        setIsUploading(true)
        fetch
            .post(`/notes`, body)
            .then(() => {
                showSuccessNotification()
                getAttachments(true)
            })
            .catch(() => {
                showErrorNotification()
                setIsUploading(false)
            })
    }

    const deleteAttachment = () => {
        setIsDeleting(true)
        fetch
            .deleteRAW(`/media/${deleteData.id}`)
            .then(() => {
                const newAttachments = attachments.filter(attachment => attachment.id !== deleteData.id)

                setAttachments(newAttachments)
                handleAttachmentsCount(newAttachments.length)
            })
            .catch(() => showErrorNotification())
            .finally(() => {
                setIsDeleting(false)
                closeModal()
            })
    }

    const openDeleteModal = id => setDeleteData({ id, isOpen: true })
    const closeModal = () => setDeleteData(oldDeleteData => ({ ...oldDeleteData, isOpen: false }))

    return (
        <div className={cx("root")}>
            {isLoading ? (
                <div className={cx("wrapper")}>
                    <SnakeLoader />
                </div>
            ) : (
                <AttachmentsList
                    isUploading={isUploading}
                    attachments={attachments}
                    sorting={sorting}
                    handleUpload={handleUpload}
                    handleSetSorting={setSorting}
                    handleDelete={openDeleteModal}
                />
            )}

            <SubmitModal
                isOpen={deleteData.isOpen}
                isLoading={isDeleting}
                redButton
                header="Are you sure you want to remove this attachment?"
                body="This action cannot be reversed."
                submitText="Delete"
                onClose={closeModal}
                onSubmit={deleteAttachment}
            />
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    showErrorNotification: () => dispatch(notificationActions.showErrorNotification()),
    showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification())
})

export default connect(
    null,
    mapDispatchToProps
)(withStyles(Attachments, styles))
