import React from "react"
import PropTypes from "prop-types"
import Layout from "components/Reusable/Layout"

const ListingLayout = ({ children, ...restProps }) => {
    return (
        <Layout
            {...restProps}
            title={"Orders Management"}
            tabs={[
                {
                    url: "/settings/orders/payment-methods",
                    label: "Payment methods"
                },
                {
                    url: "/settings/orders/shipping-methods",
                    label: "Shipping methods"
                },
                {
                    url: "/settings/orders/email-templates",
                    label: "Email templates"
                },
                {
                    url: "/settings/orders/docs-templates",
                    label: "Docs templates"
                },
                {
                    url: "/settings/orders/ecommerce",
                    label: "eCommerce"
                }
            ]}
        >
            {children}
        </Layout>
    )
}

ListingLayout.propTypes = {
    children: PropTypes.node.isRequired
}

export default ListingLayout
