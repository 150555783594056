import React, { Component } from "react"

import withPermissions from "HOC/withPermissions"

import News from "./pages/News"

class NewsPage extends Component {
    componentDidMount() {
        const { history, canList, PERMISSIONS } = this.props

        if (!canList(PERMISSIONS.context.NEWS)) {
            return history.push("/dashboard")
        }
    }

    render() {
        return <News {...this.props} />
    }
}

export default withPermissions(NewsPage)
