import React from "react"
import getStyles from "helpers/getCxFromStyles"
import styles from "./label.module.css"
import { statusesConfig, statusTypes, statusValues } from "../config"
import PropTypes from "prop-types"

const Label = props => {
    const cx = getStyles(styles)
    const { type, status, isAfterDue } = props
    const config = statusesConfig[type][status]

    if (!config || !config.label || !Object.keys(config.label).length) {
        return null
    }

    const { circle, icon, ...labelStyles } = config.getStyles(false, isAfterDue)

    return (
        <div className={cx("root")} style={labelStyles}>
            {!!circle && <span className={cx("circle")} style={{ backgroundColor: circle }} />}
            {!!icon && <img src={icon.src} className={cx(icon.class)} alt="" />}
            {config.label.text}
        </div>
    )
}

Label.propTypes = {
    type: PropTypes.oneOf(statusTypes).isRequired,
    status: PropTypes.oneOf(statusValues).isRequired,
    isAfterDue: PropTypes.bool
}

export default Label
