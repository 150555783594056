import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import style from "./ReturnHeader.css"
import { Link } from "react-router-dom"
import arrow from "assets/arrow-blue.svg"

class ReturnHeader extends Component {
    render() {
        const { cx, link, label, title, isArrow } = this.props
        return (
            <div className={cx("return-cover")}>
                {isArrow ? (
                    <Link to={link}>
                        <span>
                            <img src={arrow} alt="return" className={cx("return-arrow")} />
                            <h2 className={cx("return-text")}>{label}</h2>
                        </span>
                    </Link>
                ) : (
                    <h1 className={cx("title")}>{title}</h1>
                )}
            </div>
        )
    }
}

export default withStyles(ReturnHeader, style)
