import React, { useState } from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import {
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRowWithExpandedDetails,
    TableCell,
    ProgressDot,
    Status,
    TableCellWithExpandButton,
    AlertErrorIcon
} from "@butterfly-frontend/ui"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"
import { ShipmentDetails } from "modules/WmsModule/components"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import styles from "./List.module.css"

const TABLE_COLUMNS = {
    no: { minWidth: 40, width: 40 },
    id: { minWidth: 100, width: "35%" },
    incoming: { minWidth: 80, width: "12%" },
    packed: { minWidth: 80, width: "12%" },
    outgoing: { minWidth: 80, width: "12%" },
    status: { minWidth: 120, width: "10%" },
    detailsButton: { minWidth: 85, width: 85 }
}

const List = ({ data, onStatusChanged, canEdit }) => {
    const [expandedRowId, setExpandedRowId] = useState(null)

    return (
        <Table classes={{ root: styles.table }}>
            <TableHead>
                <TableHeadCell {...TABLE_COLUMNS.no}>#</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.id}>ID</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.incoming} classes={{ root: styles.progressIndicatorCell }}>
                    INCOMING
                </TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.packed} classes={{ root: styles.progressIndicatorCell }}>
                    PACKED
                </TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.outgoing} classes={{ root: styles.progressIndicatorCell }}>
                    OUTGOING
                </TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.status}>STATUS</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.detailsButton} />
            </TableHead>
            <TableBody>
                {data.map((shipmentItem, index) => {
                    const shipmentStatus = SHIPMENT_STATUSES[shipmentItem.status.status]
                    const isCancelled = shipmentStatus.value === SHIPMENT_STATUS_KEYS.CANCELLED

                    return (
                        <TableRowWithExpandedDetails
                            key={shipmentItem.id}
                            isExpanded={expandedRowId === shipmentItem.id}
                            classes={{
                                root: classNames({
                                    [styles.cancelledRow]: isCancelled
                                })
                            }}
                            detailsComponent={
                                <ShipmentDetails
                                    shipmentId={shipmentItem.id}
                                    isEditingStatusEnabled={canEdit()}
                                    onStatusChanged={onStatusChanged}
                                />
                            }
                        >
                            <TableCell {...TABLE_COLUMNS.no}>{index + 1}</TableCell>
                            <TableCell {...TABLE_COLUMNS.id} classes={{ root: styles.idCell }}>
                                {isCancelled && <AlertErrorIcon className={styles.alertErrorIcon} />}
                                {shipmentItem.uuid}
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.incoming} classes={{ root: styles.progressIndicatorCell }}>
                                <ProgressDot state={shipmentItem.processing_states.incoming || "empty"} />
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.packed} classes={{ root: styles.progressIndicatorCell }}>
                                <ProgressDot state={shipmentItem.processing_states.packed || "empty"} />
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.outgoing} classes={{ root: styles.progressIndicatorCell }}>
                                <ProgressDot state={shipmentItem.processing_states.outgoing || "empty"} />
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.status}>
                                <Status color={shipmentStatus.color} theme={shipmentStatus.theme}>
                                    {shipmentStatus.label}
                                </Status>
                            </TableCell>
                            <TableCellWithExpandButton
                                isExpanded={expandedRowId === shipmentItem.id}
                                onClick={() =>
                                    setExpandedRowId(prevState =>
                                        prevState !== shipmentItem.id ? shipmentItem.id : null
                                    )
                                }
                            />
                        </TableRowWithExpandedDetails>
                    )
                })}
            </TableBody>
        </Table>
    )
}

const ProcessingStatePropType = PropTypes.oneOf(["partially", "fully", "omitted"])

List.propTypes = {
    onStatusChanged: PropTypes.func,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            processing_states: PropTypes.shape({
                incoming: ProcessingStatePropType,
                packed: ProcessingStatePropType,
                outgoing: ProcessingStatePropType
            }),
            status: PropTypes.shape({
                status: PropTypes.string.isRequired
            }).isRequired
        })
    ).isRequired,
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(List, PERMISSIONS.context.ORDER_SHIPMENTS)
