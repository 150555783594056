import * as constants from "constants/index"

function createFastReview(state = {}, action) {
    switch (action.type) {
        case constants.CREATE_FAST_REVIEW_START:
            return { ...state, isLoading: true, isShown: false, data: {} }
        case constants.CREATE_FAST_REVIEW_SUCCESS:
            return { ...state, isLoading: false, isShown: true, data: action.payload.data }
        case constants.CREATE_FAST_REVIEW_ERROR:
        case constants.CLEAR_SPREADSHEET_ID:
            return { ...state, isLoading: false, isShown: false, data: {} }

        default:
            return state
    }
}

export default createFastReview
