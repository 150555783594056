import React, { useCallback, useState, useMemo } from "react"
import { useParams, useHistory, Link } from "react-router-dom"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { Panel, BackIcon } from "@butterfly-frontend/ui"

import { Dropdown } from "ui"
import { SnakeLoader } from "ui/Skeleton"
import { showSuccessNotification } from "actions/notification"
import { useActions } from "hooks"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"
import {
    StockItemDetailsHero,
    StockItemDetailsTransferHistory,
    StockItemDetailsBoxList,
    StockItemDetailsConditionHistory,
    StockItemDetailsAdjustments
} from "modules/WmsModule/components"
import { DeleteStockItem } from "modules/WmsModule/modals"
import { useStockItemDetails } from "modules/WmsModule/hooks/api/useStockItem"
import { createLinkToStockAdjustment } from "modules/WmsModule/helpers/createLinkToStockAdjustment"
import { checkIfCanCreateStockAdjustment } from "modules/WmsModule/helpers/stockItem"

import styles from "./StockItemDetailsPage.module.css"

const MODAL_TYPES = {
    STOCK_ADJUSTMENT: "STOCK_ADJUSTMENT",
    REMOVE_ITEM: "REMOVE_ITEM"
}

const StockItemDetailsPage = ({ canDelete, checkPermission }) => {
    const history = useHistory()
    const { stockItemId } = useParams()
    const [openModalType, setOpenModalType] = useState(null)
    const actions = useActions({ showSuccessNotification })

    const {
        data,
        fetchStatus,
        query: { isError }
    } = useStockItemDetails({ id: stockItemId })

    const onCloseModal = useCallback(() => setOpenModalType(null), [])

    const onSuccessRemove = () => {
        actions.showSuccessNotification({ title: "Stock item removed successfully" })
        history.push("/wms/stock-management")
    }

    const stockAdjustmentDropdownOptions = useMemo(
        () => [
            ...(checkPermission(PERMISSIONS.types.STOCK_ADJUSTMENT_ON, PERMISSIONS.context.STOCK_ITEMS)
                ? [
                      {
                          label: "Stock adjustment",
                          onClick: () =>
                              history.push(
                                  createLinkToStockAdjustment({
                                      stockItems: [
                                          {
                                              ...data,
                                              condition: data.condition.product_condition
                                          }
                                      ],
                                      returnPath: `/wms/stock-management/${data.id}`
                                  })
                              )
                      }
                  ]
                : []),
            ...(canDelete(PERMISSIONS.context.STOCK_ITEMS)
                ? [
                      {
                          label: "Remove item",
                          onClick: () => setOpenModalType(MODAL_TYPES.REMOVE_ITEM)
                      }
                  ]
                : [])
        ],
        [data, history.push]
    )

    if (fetchStatus.isLoading) {
        return (
            <div className={styles.loaderContainer}>
                <Panel>
                    <SnakeLoader />
                </Panel>
            </div>
        )
    }

    if (isError) {
        return (
            <div className={styles.loaderContainer}>
                <Panel>
                    <span className={styles.productNotFound}>Product not found</span>
                </Panel>
            </div>
        )
    }

    return (
        <div className={styles.root}>
            <div className={styles.headerContainer}>
                <div className={styles.breadcrumbs}>
                    <Link className={styles.breadcrumbsLink} to={"/wms/stock-management"}>
                        <BackIcon className={styles.breadcrumbsIcon} />
                        Stock Management
                    </Link>
                    <span className={styles.breadcrumbsSeparator}> / </span>
                    {data.product.name}
                </div>
                {stockAdjustmentDropdownOptions.length > 0 && (
                    <Dropdown
                        label="Stock Adjustment"
                        className={styles.stockAdjustmentDropdown}
                        disabled={checkIfCanCreateStockAdjustment({
                            status: data.status,
                            condition: _get(data, "condition.product_condition.type", ""),
                            isDuringShipment: data.during_shipment
                        })}
                        options={stockAdjustmentDropdownOptions}
                    />
                )}
            </div>
            <Panel>
                <div className={styles.content}>
                    <StockItemDetailsHero
                        id={data.id}
                        status={data.status}
                        condition={data.condition.product_condition}
                        product={{ ...data.product, brand: data.brand }}
                        warehouse={data.warehouse}
                        order={data.order}
                        purchaseOrder={data.purchase_order}
                        invoice={data.invoice}
                        combination={data.combination}
                    />
                    <StockItemDetailsBoxList boxes={data.boxes} stockItemId={data.id} showPrintButton />
                    <StockItemDetailsConditionHistory conditionHistory={data.condition_history} />
                    <StockItemDetailsTransferHistory transfers={data.transfers} />
                    <StockItemDetailsAdjustments stockAdjustmentsData={data.stock_adjustments} />
                </div>
            </Panel>
            {openModalType === MODAL_TYPES.REMOVE_ITEM && (
                <DeleteStockItem
                    onClose={onCloseModal}
                    onSuccess={onSuccessRemove}
                    stockItem={{
                        ...data,
                        condition: data.condition.product_condition
                    }}
                />
            )}
        </div>
    )
}

StockItemDetailsPage.propTypes = {
    canDelete: PropTypes.func.isRequired,
    checkPermission: PropTypes.func.isRequired
}

export default withPermissions(StockItemDetailsPage)
