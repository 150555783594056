import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import orderBy from "lodash/orderBy"
import fetch from "helpers/fetch"
import { formatPrice } from "helpers/units"
import { Select } from "ui"
import { showErrorNotification } from "actions/notification"

import styles from "./SelectPaymentMethod.module.css"

const SelectPaymentMethod = ({
    customErrorStyles,
    customStyles,
    error,
    handleSelect,
    name,
    label,
    value: defaultValue,
    availableStoreCreditAmount,
    filterStoreCredit,
    isRequired
}) => {
    const [value, setValue] = useState({ label: "" })
    const [values, setValues] = useState([])

    const showAvailableStoreCredit = typeof availableStoreCreditAmount !== "undefined"

    const dispatch = useDispatch()

    useEffect(() => {
        setValue({
            label: defaultValue
                ? defaultValue.store_credit && showAvailableStoreCredit
                    ? `Store Credit (Available: ${formatPrice(availableStoreCreditAmount)})`
                    : defaultValue.name
                : "",
            value: defaultValue
        })
    }, [defaultValue])

    useEffect(() => {
        const formatData = data => {
            const preparedData = filterStoreCredit
                ? data.filter(({ store_credit }) => !store_credit)
                : orderBy(data, ["store_credit"], ["desc"])

            return preparedData.map(item => {
                const { name: label, store_credit } = item
                return {
                    value: item,
                    label,
                    secondLabel: "",
                    renderSticker: () =>
                        store_credit && showAvailableStoreCredit ? (
                            <div className={styles.storeCreditAddon}>
                                Available:
                                <span className={styles.amount}>{formatPrice(availableStoreCreditAmount)}</span>
                            </div>
                        ) : (
                            ""
                        )
                }
            })
        }

        fetch
            .get(`/payment-methods`)
            .then(data => setValues(formatData(data.data)))
            .catch(() => dispatch(showErrorNotification("Error fetch payment methods")))
    }, [])

    return (
        <Select
            customErrorStyles={customErrorStyles}
            customStyles={customStyles}
            error={error}
            label={label}
            name={name}
            placeholder="- Please select -"
            setValue={({ value }) => handleSelect(value)}
            value={value.label}
            values={values}
            isRequired={isRequired}
        />
    )
}

export default SelectPaymentMethod
