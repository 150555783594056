import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./checkboxesholder.module.css"
import { Checkbox } from "ui"

class CheckboxesHolder extends Component {
    static defaultProps = {
        list: [],
        selectedIds: {},
        handleSelect: () => {},
        title: "",
        keyId: ""
    }

    render() {
        const { cx, list, title, keyId, handleSelect, selectedIds } = this.props
        return (
            <section className={cx("root")}>
                <h2>{title}</h2>
                {list.map(el => {
                    const { id, name } = el
                    return (
                        <div className={cx("checkboxHolder")} key={`${title}-${id}`}>
                            <Checkbox
                                id={`${title}-${id}`}
                                name={name}
                                checked={selectedIds[keyId].includes(id)}
                                onChange={() => handleSelect(keyId, id)}
                            />
                        </div>
                    )
                })}
            </section>
        )
    }
}

export default withStyles(CheckboxesHolder, styles)
