import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Formik, Field } from "formik"
import * as Yup from "yup"

import { Button, Input, Textarea } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader/SnakeLoader"
import Portal from "ui/Modal/components/Portal"
import Loader from "components/Reusable/Loader/Loader"
import Skeleton from "components/Skeleton"
import Receiver from "./components/Receiver"

import { successState, fetchStatusPropTypes } from "helpers/fetchStatus"
import withStyles from "HOC/withStyles"

import styles from "./Sendable.module.css"
import inputStyles from "./overrides/InputText.css"
import textareaStyles from "./overrides/Textarea.css"
import stylesSnakeLoader from "./overrides/SnakeLoader.css"
import addIcon from "assets/add.svg"
import closeIcon from "assets/close.svg"
import downloadIcon from "assets/download.svg"

const schema = Yup.object().shape({
    receivers: Yup.array().of(
        Yup.object().shape({
            email: Yup.string()
                .email("This field must be a valid email.")
                .required("This field is required.")
        })
    )
})

const DownloadFile = ({ cx, name, loading, handleDownload }) => (
    <a className={cx("download-item", { loading })} onClick={handleDownload}>
        {loading ? (
            <SnakeLoader customStyles={stylesSnakeLoader} />
        ) : (
            <Fragment>
                <img src={downloadIcon} alt="Download" />
                <span className={cx("file-name")}>{name}</span>
            </Fragment>
        )}
    </a>
)

const Sendable = ({
    cx,
    isSubmitting,
    handleClose,
    handleSubmit,
    id,
    uuid,
    printType,
    clientEmails,
    template,
    fetchStatus,
    isPrinting,
    handlePrint,
    printTemplateType,
    documents
}) => {
    const getUniqueEmails = (acc, currEmail) =>
        !currEmail || acc.find(({ email }) => email === currEmail) ? acc : [...acc, { email: currEmail }]
    const initialReceivers =
        Array.isArray(clientEmails) && !!clientEmails.length
            ? clientEmails.reduce(getUniqueEmails, [])
            : [{ email: "" }]

    return (
        <Portal>
            <div className={cx("modal-background")}>
                <div className={cx("modal-cover")}>
                    <Skeleton
                        fetchStatus={fetchStatus}
                        component={
                            <div className={cx("loader")}>
                                <Loader />
                            </div>
                        }
                        render={() => (
                            <Fragment>
                                <img src={closeIcon} alt="close" className={cx("close-icon")} onClick={handleClose} />

                                <h2 className={cx("modal-title")}>
                                    Send {template.name} {uuid && id !== -1 ? <span># {uuid}</span> : ""}
                                </h2>

                                {renderForm()}
                            </Fragment>
                        )}
                    />
                </div>
            </div>
        </Portal>
    )

    function renderForm() {
        return (
            <Formik
                initialValues={{
                    documents: documents || [{ id, name: uuid, type: printType, template_type: printTemplateType }],
                    message: template.message || "",
                    receivers: initialReceivers,
                    subject: template.subject || ""
                }}
                onSubmit={handleSubmit}
                validationSchema={schema}
            >
                {({ errors, handleSubmit, setFieldTouched, setFieldValue, touched, values }) => {
                    const canAddMore = !!values.receivers.find(item => !item.email)

                    return (
                        <form className={cx("form")} onSubmit={handleSubmit}>
                            <div className={cx("row")}>
                                <label className={cx("label")}>
                                    {printType === "purchase_order" ? "Supplier" : "Client"} email(s):
                                </label>
                                {values.receivers.map((value, index) => (
                                    <Receiver
                                        error={((errors.receivers || [])[index] || {}).email}
                                        index={index}
                                        isTouched={((touched.receivers || [])[index] || {}).email}
                                        key={index}
                                        receivers={values.receivers}
                                        setFieldTouched={setFieldTouched}
                                        setFieldValue={setFieldValue}
                                        value={value}
                                    />
                                ))}
                            </div>
                            <div className={cx("row")}>
                                <div className={cx("addEmail", { disabled: canAddMore })}>
                                    <div
                                        className={cx("addEmail__label")}
                                        onClick={() =>
                                            !canAddMore &&
                                            setFieldValue("receivers", [...values.receivers, { email: "" }])
                                        }
                                    >
                                        <img src={addIcon} alt="Add" /> Add more email
                                    </div>
                                </div>
                            </div>
                            <div className={cx("row")}>
                                <Field name="subject">
                                    {({ field }) => {
                                        return (
                                            <Input
                                                customStyles={inputStyles}
                                                error={touched.subject && errors.subject}
                                                label="Subject"
                                                onChange={value => {
                                                    setFieldValue(field.name, value.event.target.value)
                                                }}
                                                value={values.subject}
                                            />
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className={cx("row")}>
                                <label className={cx("label")}>Message:</label>
                                <Field name="message">
                                    {({ field }) => {
                                        return (
                                            <Textarea
                                                customStyles={textareaStyles}
                                                error={touched.message && errors.message}
                                                label="Click here to add a message..."
                                                isPlaceholder={true}
                                                onChange={value => {
                                                    setFieldValue(field.name, value.event.target.value)
                                                }}
                                                placeholder="Click here to add a message..."
                                                value={values.message}
                                            />
                                        )
                                    }}
                                </Field>
                            </div>
                            <div className={cx("row")}>
                                {values.documents.map(document => (
                                    <DownloadFile
                                        key={document.id}
                                        cx={cx}
                                        name={`${document.name}.pdf`}
                                        loading={checkIfIsPrinting(document.id)}
                                        handleDownload={
                                            !!documents
                                                ? () => handlePrint(document.id, document.type, printTemplateType)
                                                : handlePrint
                                        }
                                    />
                                ))}
                            </div>
                            <div className={cx("buttons")}>
                                <span className={cx("form-cancel")} onClick={handleClose}>
                                    Cancel
                                </span>
                                <Button
                                    type="submit"
                                    isLoading={isSubmitting}
                                    className={["first-button", "submit-button"]}
                                    label="Send"
                                    isDisabled={isSubmitting}
                                />
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }

    function checkIfIsPrinting(id) {
        return typeof isPrinting === "object" && isPrinting !== null ? isPrinting[id] : !!isPrinting
    }
}

Sendable.defaultProps = {
    fetchStatus: successState(),
    template: {}
}

Sendable.propTypes = {
    isSubmitting: PropTypes.bool,
    isPrinting: PropTypes.bool,
    id: PropTypes.number,
    uuid: PropTypes.string,
    printType: PropTypes.string.isRequired,
    clientEmails: PropTypes.arrayOf(PropTypes.string),
    fetchStatus: fetchStatusPropTypes,
    template: PropTypes.shape({
        name: PropTypes.string,
        message: PropTypes.string,
        subject: PropTypes.string
    }),
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handlePrint: PropTypes.func.isRequired
}

export default withStyles(Sendable, styles)
