import { combineReducers } from "redux"

import current from "./current"
import find from "./find"

const combinationsReducer = combineReducers({
    current,
    find
})

export default combinationsReducer
