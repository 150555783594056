import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import idx from "idx"

import withStyles from "HOC/withStyles"

import Button from "components/Reusable/Form/Button/index"
import Layout from "components/Reusable/Layout/index"
import { SearchBar, Toggle, SubmitModal } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import { ConfirmModal, DragAndDropList } from "modules/ContentModule/components"

import styles from "./styles.css"
import searchBarStyles from "./overrides/SearchBar.css"
import buttonStyles from "./overrides/button.css"
import viewButtonStyles from "./overrides/viewButton.css"
import toggleStyles from "./overrides/toggle.css"

import iconEmptyList from "./assets/ico-slides.svg"
import iconImage from "assets/ico-image.svg"

const Listing = props => {
    const {
        cx,
        query,
        handleSearch,
        list,
        listMeta,
        getList,
        deleteModalData,
        openDeleteModal,
        closeDeleteModal,
        deletePortfolio,
        changeActiveModalData,
        openChangeActiveModal,
        closeChangeActiveModal,
        changeActivePortfolio,
        history,
        isDeleting,
        isLoading,
        isUpdatingOrder,
        isChangingActive,
        onReorder,
        onPreviewPortfolio
    } = props

    const activateActionName = idx(changeActiveModalData, _ => _.active) ? "Deactivate" : "Activate"

    return (
        <Layout
            title="Portfolio"
            cta={[
                null,
                <Link to="/content/portfolio/add">
                    <Button className="first-button" label="Add New" customStyles={buttonStyles} />
                </Link>
            ]}
        >
            <SearchBar
                placeholder="Search for work..."
                handleSearch={handleSearch}
                customStyles={searchBarStyles}
                value={query}
            />

            {list.length === 0 && isLoading ? (
                <div className={cx("loaderWrapper")}>
                    <SnakeLoader />
                </div>
            ) : list.length === 0 ? (
                <div className={cx("emptyListPlaceholder")}>
                    <img src={iconEmptyList} alt="empty list" />
                    <p>No work added</p>
                </div>
            ) : (
                <div className={cx("contentWrapper")}>
                    <div className={cx("counter")}>
                        {`${listMeta.total} portfolio added`}
                        <span className={cx("orderLoader", { visible: isUpdatingOrder })}>
                            <SnakeLoader />
                        </span>
                    </div>

                    <div className={cx("list")}>
                        <div className={cx("listHeader")}>
                            <div className={cx("titleWrapper")}>TITLE</div>
                            <div className={cx("lastModifiedWrapper")}>LAST MODIFIED</div>
                        </div>

                        <DragAndDropList itemsList={list} handleReorder={onReorder} disabled={!!query}>
                            {list.map(renderRow)}
                        </DragAndDropList>
                    </div>

                    {listMeta.current_page < listMeta.last_page && (
                        <Button
                            label="Show more"
                            className={cx("first-button", "showMoreButton")}
                            onClick={() => getList()}
                            isLoading={isLoading}
                        />
                    )}
                </div>
            )}

            <ConfirmModal
                isOpen={!!deleteModalData}
                item={deleteModalData}
                type="portfolio"
                handleClose={closeDeleteModal}
                handleConfirm={deletePortfolio}
                actionButtonLabel={"Delete"}
                isLoading={isDeleting}
            />
            {changeActiveModalData && (
                <SubmitModal
                    isOpen
                    withLine
                    isLoading={isChangingActive}
                    onClose={closeChangeActiveModal}
                    onSubmit={() => changeActivePortfolio(changeActiveModalData)}
                    redButton={false}
                    header={`${activateActionName} this portfolio?`}
                    body={
                        <Fragment>
                            Are you sure you want to {activateActionName.toLowerCase()} the portfolio{" "}
                            <strong>"{changeActiveModalData.title}"</strong>?
                            <br />
                            <br />
                            Your change will be visible on the website.
                        </Fragment>
                    }
                    submitText={activateActionName}
                />
            )}
        </Layout>
    )

    function renderRow(item) {
        return (
            <div className={cx("listRow")} key={item.id}>
                <div className={cx("titleWrapper")}>
                    {!!item.thumbnail ? (
                        <div className={cx("imageWrapper")}>
                            <img src={item.thumbnail} alt="cover" />
                        </div>
                    ) : (
                        <div className={cx("placeholderWrapper")}>
                            <img src={iconImage} alt="cover placeholder" />
                        </div>
                    )}

                    {item.title}
                </div>

                <div className={cx("lastModifiedWrapper")}>{moment(item.updated_at).format("MM/DD/YYYY")}</div>

                <div className={cx("actionsWrapper")}>
                    <Toggle
                        handleChange={() => openChangeActiveModal(item)}
                        customStyles={toggleStyles}
                        isOn={!!item.active}
                        label={{
                            on: "active",
                            off: "inactive"
                        }}
                    />
                    <div
                        className={cx("edit-cover")}
                        onClick={() => history.push(`/content/portfolio/${item.id}/edit`)}
                    >
                        <span>Edit</span>
                    </div>
                    <div className={cx("delete-cover")} onClick={() => openDeleteModal(item)}>
                        <span>Delete</span>
                    </div>
                    <Button
                        className="first-button"
                        onClick={() => onPreviewPortfolio(item)}
                        label={item.active ? "View" : "Preview"}
                        customStyles={viewButtonStyles}
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(Listing, styles)
