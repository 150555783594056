import React from "react"
import PropTypes from "prop-types"

import {
    Button,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    TrashIcon,
    AlertOkIcon,
    Image
} from "@butterfly-frontend/ui"

import styles from "./StockItemTable.module.css"

const STOCK_ITEM_TABLE = {
    no: { minWidth: 27, width: 27 },
    product: { minWidth: 200, width: "50%" },
    status: { minWidth: 100, width: "10%" },
    warehouse: { minWidth: 100, width: "10%" },
    po: { minWidth: 110, width: "calc(20% - 30px)" },
    removeAction: { minWidth: 30, width: 30 }
}

const StockItemTable = props => {
    const {
        stockItemList,
        isAnyRowSelected,
        isAllRowSelected,
        toggleRowSelection,
        toggleAllRowSelection,
        checkIsRowSelected,
        onRemoveStockItemFromPackage
    } = props

    return (
        <Table>
            <TableHead>
                <TableHeadCell {...STOCK_ITEM_TABLE.no}>
                    {toggleAllRowSelection ? (
                        <Checkbox
                            checked={isAllRowSelected}
                            partiallyChecked={isAnyRowSelected}
                            onChange={toggleAllRowSelection}
                        />
                    ) : (
                        "#"
                    )}
                </TableHeadCell>
                <TableHeadCell {...STOCK_ITEM_TABLE.product}>PRODUCT NAME</TableHeadCell>
                <TableHeadCell {...STOCK_ITEM_TABLE.status}>STATUS</TableHeadCell>
                <TableHeadCell {...STOCK_ITEM_TABLE.warehouse}>WAREHOUSE</TableHeadCell>
                <TableHeadCell {...STOCK_ITEM_TABLE.po}>PO</TableHeadCell>
                {onRemoveStockItemFromPackage && <TableHeadCell {...STOCK_ITEM_TABLE.removeAction} />}
            </TableHead>
            <TableBody>
                {stockItemList.map((stockItem, index) => (
                    <TableRow key={stockItem.id}>
                        <TableCell {...STOCK_ITEM_TABLE.no}>
                            {toggleRowSelection ? (
                                <Checkbox
                                    checked={checkIsRowSelected(stockItem)}
                                    onChange={() => toggleRowSelection(stockItem)}
                                />
                            ) : (
                                index + 1
                            )}
                        </TableCell>
                        <TableCell {...STOCK_ITEM_TABLE.product} classes={{ root: styles.productCell }}>
                            <Image src={stockItem.product.image} classes={{ root: styles.productThumb }} />
                            {stockItem.product.name}
                        </TableCell>
                        <TableCell {...STOCK_ITEM_TABLE.status}>
                            <div className={styles.stockItemStatus}>
                                {stockItem.purchase_order && stockItem.purchase_order.during_shipment
                                    ? "New Order"
                                    : "In Stock"}
                            </div>
                        </TableCell>
                        <TableCell {...STOCK_ITEM_TABLE.warehouse}>{stockItem.warehouse.name}</TableCell>
                        <TableCell {...STOCK_ITEM_TABLE.po}>
                            {stockItem.purchase_order ? (
                                <div className={styles.purchaseOrder}>
                                    <AlertOkIcon />
                                    {stockItem.purchase_order.uuid}
                                </div>
                            ) : (
                                "-"
                            )}
                        </TableCell>
                        {onRemoveStockItemFromPackage && (
                            <TableCell {...STOCK_ITEM_TABLE.removeAction} classes={{ root: styles.removeActionCell }}>
                                <Button
                                    variant="flat"
                                    size="normal"
                                    onClick={() => onRemoveStockItemFromPackage(stockItem)}
                                >
                                    <TrashIcon />
                                </Button>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

StockItemTable.propTypes = {
    stockItemList: PropTypes.arrayOf(
        PropTypes.shape({
            product: PropTypes.shape({
                name: PropTypes.string,
                image: PropTypes.string
            }).isRequired,
            condition: PropTypes.shape({
                id: PropTypes.number
            }).isRequired,
            warehouse: PropTypes.shape({
                name: PropTypes.string
            }).isRequired,
            purchase_order: PropTypes.shape({
                id: PropTypes.number,
                status: PropTypes.string,
                uuid: PropTypes.string.isRequired,
                during_shipment: PropTypes.bool.isRequired
            })
        }).isRequired
    ).isRequired,
    isAllRowSelected: PropTypes.bool,
    isAnyRowSelected: PropTypes.bool,
    checkIsRowSelected: PropTypes.func,
    toggleRowSelection: PropTypes.func,
    toggleAllRowSelection: PropTypes.func,
    onRemoveStockItemFromPackage: PropTypes.func
}

export default StockItemTable
