import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { connect } from "react-redux"
import withForm from "HOC/withForm"
import models from "models/index"
import { isTokenValid } from "helpers/jwt"

import * as loginActions from "actions/login"
import Login from "./Login"

class LoginContainer extends Component {
    componentDidMount() {
        const { history } = this.props
        if (isTokenValid(window.localStorage.getItem("accessToken"))) {
            history.push("/")
            return false
        }
    }

    render() {
        const { isError, isSaving, errorMessage, toRender, handleFieldChange } = this.props
        return (
            <Login
                isError={isError}
                isSaving={isSaving}
                errorMessage={errorMessage}
                formFields={toRender()}
                handleFieldChange={handleFieldChange}
                handleSubmitAction={this.handleSubmitAction.bind(this)}
            />
        )
    }

    handleSubmitSuccess(data) {
        const { history, loginUserSuccess, toRender } = this.props
        const {
            jwt_token,
            user_id,
            user_name,
            first_name,
            last_name,
            avatar,
            permissions,
            roles,
            warehouses = []
        } = data.data
        const formFields = toRender()
        const email = formFields.email.value

        window.localStorage.setItem("accessToken", jwt_token)
        window.localStorage.setItem("userId", user_id)
        window.localStorage.setItem("userName", user_name)
        window.localStorage.setItem("userFirstName", first_name)
        window.localStorage.setItem("userLastName", last_name)
        window.localStorage.setItem("userAvatar", avatar)
        window.localStorage.setItem("permissions", JSON.stringify(permissions))
        window.localStorage.setItem("email", email)
        window.localStorage.setItem("roles", JSON.stringify(roles))
        window.localStorage.setItem("assignedWarehouseIds", JSON.stringify(warehouses.map(({ id }) => id)))

        loginUserSuccess({
            email,
            ...data
        })

        setTimeout(() => {
            const redirectPath = window.localStorage.getItem("redirectPath")
            const isIframe = window.top !== window.self

            if (isIframe) {
                history.push("/scraper/")
                return
            }

            history.push(
                redirectPath && redirectPath.indexOf("/") !== -1 && redirectPath.indexOf("scraper") === -1
                    ? redirectPath
                    : "/"
            )
        }, 0)
    }

    handleSubmitAction(event) {
        const { handleSubmit } = this.props
        event.preventDefault()

        handleSubmit({ method: "post", handleSuccess: this.handleSubmitSuccess.bind(this) })
    }
}

const mapStateToProps = state => {
    return {}
}

const mapDispatchToProps = dispatch => {
    return {
        loginUserSuccess: data => dispatch(loginActions.loginUserSuccess(data))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withForm(withRouter(LoginContainer), { model: models.Login }))
