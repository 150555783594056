import { useCallback } from "react"
import _get from "lodash/get"

import { useActions } from "hooks"
import { showErrorNotification } from "actions/notification"
import { useShipmentEdit } from "modules/WmsModule/hooks/api/useShipment"
import { useReplaceStockItemInShipmentCache } from "modules/WmsModule/hooks/cache"

const useReceivingShipmentEdit = ({ shipment, item }) => {
    const replaceStockItemInShipmentCache = useReplaceStockItemInShipmentCache({ shipmentId: shipment.id })

    const actions = useActions({ showErrorNotification })

    const changeAcceptanceStatusInShipmentCache = useCallback(
        result => {
            const replacedItem = _get(result, "data.items", []).find(({ id }) => id === item.id)
            replaceStockItemInShipmentCache(replacedItem)
        },

        [item, replaceStockItemInShipmentCache]
    )

    const receivingShipmentEdit = useShipmentEdit({
        skipInvalidateQueries: true,
        reactQueryProps: {
            onSuccess: changeAcceptanceStatusInShipmentCache,
            onError: actions.showErrorNotification
        }
    })

    return { receivingShipmentEdit }
}

export default useReceivingShipmentEdit
