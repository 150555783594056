import STOCK_ITEM_STATUSES from "./stockItemStatuses"
import STOCK_ITEM_STATUS_KEYS from "./stockItemStatusKeys"

const STOCK_ITEM_STATUS_SETTER_OPTIONS = [
    STOCK_ITEM_STATUSES[STOCK_ITEM_STATUS_KEYS.ON_HOLD],
    STOCK_ITEM_STATUSES[STOCK_ITEM_STATUS_KEYS.IN_STOCK],
    STOCK_ITEM_STATUSES[STOCK_ITEM_STATUS_KEYS.NOT_FOR_SALE]
]

export default STOCK_ITEM_STATUS_SETTER_OPTIONS
