import React, { useState } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import idx from "idx"

import { SelectWithSearch } from "ui"
import { createLocation, getLocations } from "modules/LocationsModule/actions"
import * as notificationActions from "actions/notification"
import { selectWithSearchPropTypes } from "ui/SelectWithSearch/SelectWithSearch"

const SelectLocation = ({
    onCreateNewLocationSuccess = null,
    name,
    handleSelect,
    value,
    multiselect = false,
    selectWithSearchProps,
    customStyles
}) => {
    const [newLocationValue, setNewLocationValue] = useState("")
    const [isNewLocationLoading, setIsNewLocationLoading] = useState(false)
    const [isLoadingList, setIsLoadingList] = useState(false)
    const [locations, setLocations] = useState([])
    const [pagination, setPagination] = useState({ current_page: 0, last_page: 1 })
    const dispatch = useDispatch()

    const formatLocationsSelectData = data =>
        data.map(location => ({
            id: location.id,
            value: location.id,
            label: location.name
        }))

    const fetchLocationsValues = (query, page) => {
        if (isLoadingList) {
            return
        }

        setIsLoadingList(true)

        dispatch(getLocations({ page, length: 20, query }))
            .then(({ payload }) => {
                const formattedData = formatLocationsSelectData(payload.data.data)
                setLocations(page === 1 ? formattedData : [...locations, ...formattedData])
                setPagination({ ...payload.data.meta, current_page: page })
            })
            .finally(() => setIsLoadingList(false))
    }

    const createNewLocation = name => {
        setIsNewLocationLoading(true)
        return createLocation({ name })
            .then(({ data }) => {
                dispatch(notificationActions.showSuccessNotification())
                onCreateNewLocationSuccess(data)
            })
            .catch(err => {
                dispatch(
                    notificationActions.showErrorNotification({
                        title: !!idx(err, _ => _.errors.name[0]) && "Location already exists"
                    })
                )
            })
            .finally(() => setIsNewLocationLoading(false))
    }

    const createNewLocationProps = onCreateNewLocationSuccess
        ? {
              additionalButtonLabel: "Create new: ",
              additionalButtonAction: () => {
                  createNewLocation(newLocationValue)
              }
          }
        : {}

    const setValue = value => {
        handleSelect({
            target: {
                name,
                value: multiselect || value.value ? value : null
            }
        })
    }

    return (
        <SelectWithSearch
            label="Location"
            value={value}
            values={locations}
            pagination={pagination}
            onInputChange={setNewLocationValue}
            appendValueToBtnLabel
            disableBtnForEmptyValue
            fetchValues={fetchLocationsValues}
            showLoadingIcon={isNewLocationLoading}
            customStyles={customStyles}
            multiselect={multiselect}
            {...selectWithSearchProps}
            {...createNewLocationProps}
            setValue={setValue}
        />
    )
}

const { setValue, values, value, multiselect, ...restSelectWithSearchPropTypes } = selectWithSearchPropTypes

const valuesElementShape = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
})

SelectLocation.propTypes = {
    onCreateNewLocationSuccess: PropTypes.func,
    handleSelect: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(valuesElementShape)]),
    selectWithSearchProps: PropTypes.shape(restSelectWithSearchPropTypes),
    customStyles: PropTypes.object,
    multiselect: PropTypes.bool
}

export default SelectLocation
