import React from "react"

import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useActions, useFetch } from "hooks"
import fetch from "helpers/fetch"
import DeletePayment from "./DeletePayment"

const DeletePaymentContainer = ({ amount, handleClose, onDelete, paymentId, getOrderForCalculations }) => {
    const actions = useActions({
        showSuccessNotification,
        showErrorNotification
    })

    const [handleSubmit, { isLoading: isSubmitting }] = useFetch({
        action: async () => {
            await fetch.deleteRAW(`/payments/${paymentId}`)
            return typeof getOrderForCalculations === "function" ? getOrderForCalculations() : Promise.resolve()
        },
        onSuccess: () => {
            actions.showSuccessNotification({ title: "Deleted" })
            handleClose()
            onDelete(paymentId)
        },
        onError: () => actions.showErrorNotification()
    })

    return (
        <DeletePayment
            amount={amount}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
        />
    )
}

export default DeletePaymentContainer
