import React, { useMemo } from "react"
import PropTypes from "prop-types"

import DeletePrimaryWarehouse from "./DeletePrimaryWarehouse"
import DeleteNotEmptyWarehouse from "./DeleteNotEmptyWarehouse"
import DeleteWarehouse from "./DeleteWarehouse"

const DeleteWarehouseContainer = ({ warehouse, onDelete, onCancel, isLoading }) => {
    const { primary, items_count_per_status = {}, name } = warehouse
    const hasItems = useMemo(() => Object.values(items_count_per_status).some(count => count > 0), [
        items_count_per_status
    ])

    if (primary) {
        return <DeletePrimaryWarehouse onCancel={onCancel} onDelete={onDelete} isLoading={isLoading} />
    }

    if (hasItems) {
        return <DeleteNotEmptyWarehouse onCancel={onCancel} onDelete={onDelete} isLoading={isLoading} />
    }

    return <DeleteWarehouse onCancel={onCancel} onDelete={onDelete} isLoading={isLoading} name={name} />
}

DeleteWarehouseContainer.propTypes = {
    warehouse: PropTypes.shape({
        primary: PropTypes.bool,
        items_count_per_status: PropTypes.shape({
            available: PropTypes.number,
            in_stock: PropTypes.number,
            committed: PropTypes.number,
            incoming: PropTypes.number,
            on_hold: PropTypes.number
        }),
        name: PropTypes.string
    }),
    onDelete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default DeleteWarehouseContainer
