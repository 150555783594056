import React from "react"
import { Link } from "react-router-dom"

import withStyles from "HOC/withStyles"
import styles from "./head.css"

const Head = props => {
    const { cx, brand } = props

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <div className={cx("logo")}>
                    <img src={brand.logo} alt="logo" className={cx("image")} />
                </div>

                <div className={cx("brandTitle")}>
                    <h1>{brand.name}</h1>
                    <span>{brand.products_in_brand} products</span>
                </div>

                <Link to={`/settings/brands/${brand.id}/edit`} className={cx("editButton")}>
                    <div className={cx("edit-cover")}>Edit Brand</div>
                </Link>
            </div>
        </div>
    )
}

export default withStyles(Head, styles)
