import React, { Fragment, useEffect, useState } from "react"
import isEqual from "lodash/isEqual"

import withStyles from "HOC/withStyles"
import fetch from "helpers/fetch"

import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"
import { EmptyList, SaveBar } from "ui"

import DragAndDropList from "./components/DragAndDropList"

import styles from "./EditableList.css"

import emptyListIcon from "assets/ico-catalog.svg"

const EditableList = ({
    cx,
    onAdd,
    onEdit,
    onDelete,
    title,
    confirmModalTitle,
    isLoading,
    columns,
    data,
    dataModel,
    fetchData,
    isIndexColumn,
    showDeleteCondition
}) => {
    const [list, setList] = useState(data)
    const [isSaving, setIsSaving] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(null)

    useEffect(() => {
        setList(data)
    }, [data])

    const handleDelete = () => {
        onDelete(itemToDelete)
        setItemToDelete(null)
    }

    const handleSaveReorder = () => {
        setIsSaving(true)
        fetch
            .postRAW("/multi-actions", {
                ids: list.map(item => item.id),
                model: dataModel,
                action: "sort",
                payload: ""
            })
            .then(() => fetchData())
            .finally(() => setIsSaving(false))
    }

    const handleClearChanges = () => {
        setList(data)
    }

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <h1>{title}</h1>
                {!!onAdd && (
                    <button onClick={onAdd} className={cx("first-button")}>
                        Add New
                    </button>
                )}
            </div>
            {renderList()}
            {!!itemToDelete && (
                <Modal>
                    <Confirm
                        confirmModalTitle={confirmModalTitle}
                        handleHideModal={() => setItemToDelete(null)}
                        handleDelete={handleDelete}
                    />
                </Modal>
            )}
        </div>
    )

    function renderList() {
        if (!isLoading && Array.isArray(list) && !list.length) {
            return <div className={cx("emptyList")}>Empty list.</div>
        }

        return (
            <Fragment>
                <table className={cx("table")}>
                    <thead>
                        <tr>
                            <th style={{ width: 35 }} />
                            {!!isIndexColumn && (
                                <th className={cx("header-column")} style={{ width: 40 }}>
                                    No.
                                </th>
                            )}
                            {columns.map((column, index) => (
                                <th
                                    className={cx("header-column")}
                                    key={index}
                                    style={{ textAlign: column.align || "left" }}
                                >
                                    {column.name}
                                </th>
                            ))}
                            {!!onEdit && <th />}
                            {!!onDelete && <th />}
                        </tr>
                    </thead>

                    {isLoading ? (
                        <tbody>
                            <tr>
                                <td colSpan="99">
                                    <div className={cx("loading")}>
                                        <SnakeLoader />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    ) : list.length === 0 ? (
                        <tbody>
                            <tr>
                                <td colSpan="99">
                                    <EmptyList icon={emptyListIcon} message="Empty list." />
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <DragAndDropList itemsList={list} handleReorder={setList}>
                            {list.map((item, index) => {
                                const isDeleteEnabled = showDeleteCondition ? showDeleteCondition(item) : true

                                return (
                                    <Fragment key={item.id}>
                                        {!!isIndexColumn && <td style={{ width: 40 }}>{index + 1}.</td>}

                                        {columns.map(column => (
                                            <td
                                                key={`${item.id}-${column.field}`}
                                                style={{ textAlign: column.align || "left" }}
                                            >
                                                {typeof column.onClick === "function" ? (
                                                    <span className={cx("link")} onClick={() => column.onClick(item)}>
                                                        {item[column.field]}
                                                    </span>
                                                ) : (
                                                    item[column.field]
                                                )}
                                            </td>
                                        ))}

                                        {!!onEdit && (
                                            <td style={{ width: 80 }} className={cx("action")}>
                                                <div onClick={() => onEdit(item)} className={cx("edit-cover")}>
                                                    Edit
                                                </div>
                                            </td>
                                        )}

                                        {!!onDelete && (
                                            <td style={{ width: 80 }} className={cx("action")}>
                                                <div
                                                    className={cx("delete-cover", { isDeleteEnabled })}
                                                    onClick={() => isDeleteEnabled && setItemToDelete(item)}
                                                >
                                                    Delete
                                                </div>
                                            </td>
                                        )}
                                    </Fragment>
                                )
                            })}
                        </DragAndDropList>
                    )}
                </table>
                <SaveBar
                    isSaving={isSaving}
                    isShown={!isEqual(data, list)}
                    submitLabel="Save"
                    message="Unsaved changes"
                    onCancel={handleClearChanges}
                    onSubmit={handleSaveReorder}
                />
            </Fragment>
        )
    }
}

export default withStyles(EditableList, styles)
