import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import _get from "lodash/get"
import { KanbanRow, KanbanRowLink, KanbanRowEntry, KanbanRowCounter, KANBAN_ROW_TYPE } from "@butterfly-frontend/ui"

import { formatDate } from "helpers/date"
import { SHIPMENT_LIST_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"

const ShipmentKanbanRow = ({ rowType, shipment, actions, isLoading, disabled, shipmentHref }) => (
    <KanbanRow
        type={rowType}
        actions={actions}
        isLoading={isLoading}
        disabled={disabled}
        date={formatDate(shipment.status.updated_at)}
    >
        <KanbanRowEntry>
            <KanbanRowLink type={rowType} LinkComponent={Link} propsLinkComponent={{ to: shipmentHref }}>
                {shipment.uuid}
            </KanbanRowLink>
        </KanbanRowEntry>
        <KanbanRowEntry withSpacing disabled={disabled}>
            {_get(shipment, "order.uuid", "N/A")}
        </KanbanRowEntry>
        <KanbanRowEntry withSpacing disabled={disabled}>
            <KanbanRowCounter count={shipment.shipment_items_count} />
        </KanbanRowEntry>
    </KanbanRow>
)

ShipmentKanbanRow.propTypes = {
    shipment: SHIPMENT_LIST_ITEM_PROP_TYPE.isRequired,
    rowType: PropTypes.oneOf(Object.values(KANBAN_ROW_TYPE)),
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            onClick: PropTypes.func.isRequired,
            label: PropTypes.string.isRequired
        })
    ),
    isLoading: PropTypes.bool,
    disabled: PropTypes.bool,
    shipmentHref: PropTypes.string.isRequired
}

export default ShipmentKanbanRow
