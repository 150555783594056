import React, { useMemo } from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import SummaryItem from "ui/PaymentSummaryItem"

import styles from "./Summary.module.css"

const Summary = ({ calculations, cx }) => {
    const { applied, balance, total } = calculations
    const amounts = useMemo(
        () => [
            {
                amount: total,
                label: "Total amount",
                type: "total"
            },
            {
                amount: applied,
                label: "Payment applied ",
                type: "applied"
            },
            {
                amount: -balance,
                color: balance < 0 ? "red" : "green",
                label: "Balance Due",
                type: "balance"
            }
        ],
        [applied, balance, total]
    )

    return (
        <div className={cx("wrapper")}>
            {amounts.map(({ amount, color, label, type }) => (
                <SummaryItem amount={amount} color={color || "default"} key={type} label={label} type={type} />
            ))}
        </div>
    )
}

Summary.propTypes = {
    calculations: PropTypes.shape({
        applied: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        balance: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
}

export default withStyles(Summary, styles)
