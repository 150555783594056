import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { HeaderSelect, H2 } from "@butterfly-frontend/ui"
import { HEADER_SELECT_OPTIONS } from "ui/ChooseProduct/constants"

import styles from "./Header.module.css"

const Header = ({ mainTitle, productType, areServicesEnabled, handleChangeType }) => {
    return (
        <H2 classes={{ h2: styles.header }}>
            {mainTitle ? (
                mainTitle
            ) : (
                <Fragment>
                    New Item:
                    {areServicesEnabled && handleChangeType ? (
                        <HeaderSelect
                            options={Object.values(HEADER_SELECT_OPTIONS)}
                            onChange={({ id }) => handleChangeType(id)}
                            value={{ id: productType, label: productType }}
                            classes={{
                                headerSelect: styles.select,
                                value: styles.headerSelectValue
                            }}
                        />
                    ) : (
                        <span className={styles.capitalize}>&nbsp;{productType}</span>
                    )}
                </Fragment>
            )}
        </H2>
    )
}

Header.propTypes = {
    mainTitle: PropTypes.string,
    productType: PropTypes.string,
    areServicesEnabled: PropTypes.bool,
    handleChangeType: PropTypes.func
}

export default Header
