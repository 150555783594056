import { from } from "rxjs"
import fetchHelper from "helpers/fetch"
import * as actionTypes from "./actionTypes"
import { serializeQuery } from "helpers/urls"

export { shouldFetch } from "helpers/fetchStatus"

const actionRequest = params => {
    params = {
        method: "get",
        body: {},
        ...params
    }

    return dispatch => {
        dispatch({
            type: params.types[0]
        })

        return new Promise((resolve, reject) => {
            fetchHelper[params.method](params.endpoint, params.body).then(
                data => {
                    dispatch({
                        type: params.types[1],
                        payload: data
                    })

                    resolve(data)
                },
                error => {
                    dispatch({
                        type: params.types[2],
                        payload: {
                            error
                        }
                    })

                    reject(error)
                }
            )
        })
    }
}

export const getProductsSubscriptionFactory = dispatch => (params = { page: 1, length: 20 }) => {
    dispatch({
        type: actionTypes.GET_PRODUCTS_REQUEST
    })

    const endpoint =
        params.type === "services"
            ? "/assistances?" + serializeQuery({ sort_by: "name", sort_direction: "asc", ...params })
            : "/products?" + serializeQuery(params)

    return from(fetchHelper.get(endpoint)).subscribe(
        data => {
            dispatch({
                type: actionTypes.GET_PRODUCTS_SUCCESS,
                payload: data
            })

            return data
        },
        error => {
            dispatch({
                type: actionTypes.GET_PRODUCTS_FAILURE,
                payload: {
                    error
                }
            })
        }
    )
}

export const fetchProducts = (
    params = {},
    types = [actionTypes.GET_PRODUCTS_REQUEST, actionTypes.GET_PRODUCTS_SUCCESS, actionTypes.GET_PRODUCTS_FAILURE]
) => {
    params = {
        page: 1,
        length: 20,
        ...params
    }

    if (params.type === "services") {
        return actionRequest({
            types: types,
            method: "get",
            endpoint: "/assistances?" + serializeQuery({ sort_by: "name", sort_direction: "asc", ...params })
        })
    }

    return actionRequest({
        types: types,
        method: "get",
        endpoint: "/products?" + serializeQuery(params)
    })
}

export const fetchMoreProducts = (params = {}) => {
    return fetchProducts(params, [
        actionTypes.GET_MORE_PRODUCTS_REQUEST,
        actionTypes.GET_MORE_PRODUCTS_SUCCESS,
        actionTypes.GET_MORE_PRODUCTS_FAILURE
    ])
}

export const fetchServices = (
    params = {},
    types = [actionTypes.GET_PRODUCTS_REQUEST, actionTypes.GET_PRODUCTS_SUCCESS, actionTypes.GET_PRODUCTS_FAILURE]
) => {
    params = {
        page: 1,
        length: 20,
        ...params
    }

    return actionRequest({
        types: types,
        method: "get",
        endpoint: "/assistances?" + serializeQuery(params)
    })
}

export const fetchMoreServices = (params = {}) => {
    return fetchServices(params, [
        actionTypes.GET_MORE_PRODUCTS_REQUEST,
        actionTypes.GET_MORE_PRODUCTS_SUCCESS,
        actionTypes.GET_MORE_PRODUCTS_FAILURE
    ])
}

export const fetchBrands = (params = {}) => {
    params = {
        length: 9999,
        custom_vendor: false,
        ...params
    }

    return actionRequest({
        types: [actionTypes.GET_BRANDS_REQUEST, actionTypes.GET_BRANDS_SUCCESS, actionTypes.GET_BRANDS_FAILURE],
        method: "get",
        endpoint: "/brands?" + serializeQuery(params)
    })
}

export const fetchCategories = (params = {}) => {
    params = {
        length: 9999,
        ...params
    }

    return actionRequest({
        types: [
            actionTypes.GET_CATEGORIES_REQUEST,
            actionTypes.GET_CATEGORIES_SUCCESS,
            actionTypes.GET_CATEGORIES_FAILURE
        ],
        method: "get",
        endpoint: "/categories?" + serializeQuery(params)
    })
}

export const fetchProduct = productId => {
    return actionRequest({
        types: [actionTypes.GET_PRODUCT_REQUEST, actionTypes.GET_PRODUCT_SUCCESS, actionTypes.GET_PRODUCT_FAILURE],
        method: "get",
        endpoint: "/products/" + productId
    })
}

export const clearState = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_PRODUCTS
    })
}

export const clearProductState = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_PRODUCT
    })
}
