import * as fetchStatus from "helpers/fetchStatus"
import * as constants from "constants/index"
import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"

export const shouldFetchServices = resource => (dispatch, getState) => {
    return fetchStatus.isResourceNotInit(resource)
}

export function getServicesStart() {
    return {
        type: constants.GET_ASSISTANCES_START
    }
}

export function getServicesSuccess(data) {
    return {
        type: constants.GET_ASSISTANCES_SUCCESS,
        payload: {
            data
        }
    }
}

export function getServicesError(error) {
    return {
        type: constants.GET_ASSISTANCES_ERROR,
        payload: {
            error
        }
    }
}

export function getServices(params = { page: 1, length: constants.LENGTH_WITHOUT_PAGINATION }) {
    return dispatch => {
        dispatch(getServicesStart())
        return fetch
            .get(`/assistances?${objToQueryString(params)}`)
            .then(data => dispatch(getServicesSuccess(data)))
            .catch(error => dispatch(getServicesError(error)))
    }
}

export function getServiceStart() {
    return {
        type: constants.GET_ASSISTANCE_BY_ID_START
    }
}

export function getServiceSuccess(data) {
    return {
        type: constants.GET_ASSISTANCE_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function getServiceError(error) {
    return {
        type: constants.GET_ASSISTANCE_BY_ID_ERROR,
        payload: {
            error
        }
    }
}

export function getServiceById(id) {
    return dispatch => {
        dispatch(getServiceStart())
        return fetch
            .get(`/assistances/${id}`)
            .then(data => dispatch(getServiceSuccess(data)))
            .catch(error => dispatch(getServiceError(error)))
    }
}

export function deleteServiceById(id) {
    return fetch.deleteRAW(`/assistances/${id}`)
}

export function createService(body) {
    return fetch.post("/assistances", body)
}

export function editService(id, body) {
    return fetch.patch(`/assistances/${id}`, body)
}
