import { INPUT_TYPES } from "constants/index"

export default {
    name: "Login",
    endpoint: "/user/reset-password",
    fields: [
        {
            name: "token",
            label: "Token",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "password",
            label: "Your New Password",
            type: INPUT_TYPES.PASSWORD,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "repeat_password",
            label: "Repeat the Password",
            type: INPUT_TYPES.PASSWORD,
            defaultValue: "",
            attributes: {
                required: true
            }
        }
    ]
}
