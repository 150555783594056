import React, { useMemo, Fragment } from "react"
import PropTypes from "prop-types"
import { useField, useFormikContext } from "formik"
import pick from "lodash/pick"

import PURCHASE_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/purchaseOrderStatusKeys"
import { Status, DatePickerWithInput, Input, SelectWithSearch } from "ui"

import styles from "./PurchaseOrderFormSidebar.module.css"
import inputStyles from "./overrides/inputStyles.module.css"

const PurchaseOrderFormSidebar = props => {
    const { isEdit, isConfirmed, isSubmitting, brand, shippingMethodList } = props

    const { setFieldValue, setFieldTouched } = useFormikContext()
    const [statusField] = useField("status")
    const [estimatedDeliveryField, estimatedDeliveryMeta] = useField("estimated_delivery")
    const [originField, originMeta] = useField("origin")
    const [shippingMethodField, shippingMethodMeta] = useField("shipping_method")

    const brandAddress = useMemo(
        () => (brand ? pick(brand, ["company_name", "address", "city", "post_code", "country", "state"]) : null),
        [brand]
    )

    const hasBrandAddress = useMemo(() => brandAddress && Object.values(brandAddress).some(value => !!value), [
        brandAddress
    ])

    const secondAddressLine = hasBrandAddress
        ? [brandAddress.city, brandAddress.post_code, brandAddress.state].filter(value => !!value)
        : []

    const isConfirmedStatusSelected = statusField.value === PURCHASE_ORDER_STATUS_KEYS.confirmed

    return (
        <div className={styles.root}>
            <h2 className={styles.brandName}>{brand ? brand.name : "-"}</h2>
            {hasBrandAddress && (
                <div className={styles.brandAddress}>
                    <h3>Vendor Address: </h3>
                    {brandAddress.company_name && (
                        <span className={styles.companyName}>{brandAddress.company_name}</span>
                    )}
                    {brandAddress.address && <span>{brandAddress.address}</span>}
                    {secondAddressLine.length > 0 && <span>{secondAddressLine.join(", ")}</span>}
                    {brandAddress.country && <span>{brandAddress.country}</span>}
                </div>
            )}
            {isEdit && (
                <Fragment>
                    <Status.Dropdown
                        type="purchase_order"
                        status={statusField.value}
                        onChange={value => statusField.onChange({ target: { ...statusField, value } })}
                        isDisabled={isConfirmed || isSubmitting}
                    />
                    {isConfirmedStatusSelected && (
                        <Fragment>
                            <div className={styles.datePickerWrapper}>
                                <DatePickerWithInput
                                    disableClear
                                    label="Arrival date"
                                    calendarOnRight
                                    date={estimatedDeliveryField.value}
                                    error={estimatedDeliveryMeta.touched ? estimatedDeliveryMeta.error : ""}
                                    minDate={new Date()}
                                    isDisabled={isSubmitting}
                                    isRequired
                                    handleSelectDate={value => {
                                        setFieldTouched("estimated_delivery", true)
                                        setFieldValue("estimated_delivery", value)
                                    }}
                                />
                            </div>
                            <div className={styles.shippingMethods}>
                                <SelectWithSearch
                                    label="Ship via"
                                    values={shippingMethodList}
                                    value={shippingMethodField.value ? shippingMethodField.value.name : undefined}
                                    error={shippingMethodMeta.error}
                                    type="text"
                                    setValue={({ value }) =>
                                        shippingMethodField.onChange({
                                            target: {
                                                ...shippingMethodField,
                                                value
                                            }
                                        })
                                    }
                                    withoutFetch
                                    withoutSearch
                                    isDisabled={isSubmitting}
                                />
                            </div>
                            <Input
                                label="Origin"
                                {...originField}
                                error={originMeta.error}
                                onChange={({ event }) => originField.onChange(event)}
                                customStyles={inputStyles}
                                isDisabled={isSubmitting}
                            />
                        </Fragment>
                    )}
                </Fragment>
            )}
        </div>
    )
}

PurchaseOrderFormSidebar.propTypes = {
    isEdit: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    brand: PropTypes.shape({
        name: PropTypes.string.isRequired,
        company_name: PropTypes.string,
        address: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        post_code: PropTypes.string,
        country: PropTypes.string
    }),
    shippingMethodList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.object.isRequired
        }).isRequired
    ).isRequired
}

export default PurchaseOrderFormSidebar
