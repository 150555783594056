import React from "react"
import getStyles from "helpers/getCxFromStyles"
import styles from "./toggledetails.module.css"

const ToggleDetails = props => {
    const { areDetailsShown, setAreDetailsShown } = props
    const cx = getStyles(styles)

    return (
        <span
            className={cx("toggleDetails", { active: areDetailsShown })}
            onClick={() => setAreDetailsShown(!areDetailsShown)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                    <path d="M3 3h18v18H3z" />
                    <path
                        className={cx("withFill")}
                        fillRule="nonzero"
                        d="M12 4.5c-4.14 0-7.5 3.36-7.5 7.5 0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zm0 9l-3-3h6l-3 3z"
                    />
                </g>
            </svg>
            <span>{areDetailsShown ? "Less details" : "See more"}</span>
        </span>
    )
}

export default ToggleDetails
