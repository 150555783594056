import * as constants from "constants/index"

function list(state = {}, action) {
    switch (action.type) {
        case constants.GET_TAGS_START:
            return { data: [], isLoading: true }
        case constants.GET_TAGS_SUCCESS:
            return { data: action.payload.data.data, isLoading: false }
        case constants.GET_TAGS_ERROR:
            return { data: [], isLoading: false }
        case constants.ADD_TAG_SUCCESS:
            return { data: [action.payload.data, ...state.data], isLoading: false }
        default:
            return state
    }
}

export default list
