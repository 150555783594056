import * as Yup from "yup"
import { DEFAULT_COUNTRY, TEXTAREA_MAX_LENGTH, INPUT_MAX_LENGTH } from "constants/index"
import PURCHASE_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/purchaseOrderStatusKeys"

const statusRegex = new RegExp(`(${Object.values(PURCHASE_ORDER_STATUS_KEYS).join("|")})`)

const checkAddressIsValid = address => {
    if (!address) {
        return false
    }

    const requiredFieldKeys = ["address", "city", "post_code", "country", "first_name", "last_name"]
    const someFieldIsEmpty = requiredFieldKeys.some(key => !address[key])

    if (someFieldIsEmpty) {
        return false
    }

    return address.country === DEFAULT_COUNTRY ? !!address.state : true
}

const VALIDATION_SCHEMA = Yup.object().shape({
    shipping_address: Yup.object()
        .test(
            "valid-address",
            "No customer address, fill in the details before confirming the PO",
            address => !address || checkAddressIsValid(address)
        )
        .nullable(),
    description: Yup.string()
        .max(TEXTAREA_MAX_LENGTH)
        .nullable(),
    special_instruction: Yup.string()
        .max(TEXTAREA_MAX_LENGTH)
        .nullable(),
    items: Yup.array()
        .of(
            Yup.object().shape({
                wholesale_cost: Yup.number().min(0, "Wholesale price may not be lower than 0")
            })
        )
        .min(1),
    status: Yup.string()
        .matches(statusRegex, { excludeEmptyString: true })
        .nullable(),
    estimated_delivery: Yup.mixed().when("status", {
        is: status => status === PURCHASE_ORDER_STATUS_KEYS.confirmed,
        then: Yup.string().required("Arrival date is required"),
        otherwise: Yup.string().nullable()
    }),
    origin: Yup.string()
        .max(INPUT_MAX_LENGTH)
        .nullable(),
    shipping_method: Yup.object().nullable()
})

export default VALIDATION_SCHEMA
