import React, { useState, useMemo } from "react"
import { useField, useFormikContext } from "formik"
import ReactSvg from "react-svg"

import AddProductModal from "components/Modal/ChooseProductFromExistingOrder"

import plusIcon from "assets/add.svg"
import closeIcon from "assets/close-blue.svg"

import withStyles from "HOC/withStyles"
import styles from "./ItemsSection.css"

import alertIcon from "assets/alert-info.svg"

const ItemsSection = props => {
    const { cx, canEditItems, orderItems } = props

    const [isModalOpen, setIsModalOpen] = useState(false)

    const { initialValues } = useFormikContext()
    const [items] = useField("items")

    const openModal = () => setIsModalOpen(true)
    const closeModal = () => setIsModalOpen(false)

    const changeItems = value => items.onChange({ target: { value, name: "items" } })

    const handleSelect = newItem => {
        changeItems([...items.value, newItem])
        closeModal()
    }

    const handleDelete = id => changeItems(items.value.filter(item => item.id !== id))

    const filteredOrderItems = useMemo(() => {
        const itemIsNotOnList = item => !items.value.some(itemValue => itemValue.id === item.id)

        const itemsRemovedFromThisInvoice = Array.isArray(initialValues.items)
            ? initialValues.items.filter(itemIsNotOnList)
            : []
        const orderItemsNotFromThisInvoice = orderItems.filter(itemIsNotOnList)

        return [...itemsRemovedFromThisInvoice, ...orderItemsNotFromThisInvoice]
    }, [initialValues.items, items.value, orderItems])

    const isAddButtonVisible = canEditItems && !!filteredOrderItems.length

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                {!!items.value.length ? (
                    <table>
                        <thead className={cx("table-head")}>
                            <tr>
                                <th>#</th>
                                <th>item name</th>
                                <th>room name </th>
                                <th>qty</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody className={cx("table-body")}>
                            {items.value.map(({ id, fake_name, room_name, quantity }, index) => (
                                <tr key={id}>
                                    <td>{index + 1}.</td>
                                    <td>{fake_name || "-"}</td>
                                    <td>{room_name || "-"}</td>
                                    <td>{quantity || 0}</td>
                                    <td>
                                        <button disabled={!canEditItems} type="button" onClick={() => handleDelete(id)}>
                                            <img src={closeIcon} alt="close" />
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <div className={cx("emptyWarning")}>
                        <ReactSvg src={alertIcon} />
                        This invoice is currently empty. Add one or more items to this invoice, and they will be shown
                        here.
                    </div>
                )}

                {isAddButtonVisible && (
                    <button type="button" onClick={openModal}>
                        <img src={plusIcon} alt="plus" />
                        Add item
                    </button>
                )}
            </div>
            <AddProductModal
                isOpen={isModalOpen}
                orderItems={filteredOrderItems}
                handleClose={closeModal}
                handleSelect={handleSelect}
            />
        </div>
    )
}

export default withStyles(ItemsSection, styles)
