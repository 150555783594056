import { useEffect, useRef } from "react"

function useDebounce(fn, delay = 200, deps = []) {
    const isInitialMount = useRef(true)

    const handleInitialCall = () => {
        isInitialMount.current = false
    }

    const debounce = () => {
        const timeout = setTimeout(fn, delay)

        return () => {
            clearTimeout(timeout)
        }
    }

    useEffect(isInitialMount.current ? handleInitialCall : debounce, deps)
}

export default useDebounce
