import * as constants from "../constants"

export function RoleReducer(state = {}, action) {
    switch (action.type) {
        case constants.GET_ROLE_START:
            return { ...state, role: {}, isLoaded: false, isError: false }
        case constants.GET_ROLES_START:
            return { ...state, isLoaded: false, isError: false }
        case constants.CREATE_ROLE_START:
        case constants.GET_PERMISSION_START:
            return { ...state, isLoaded: false, isError: false }

        case constants.GET_ROLES_SUCCESS:
            const { data, links, meta } = action.payload.data
            return {
                ...state,
                isLoaded: true,
                roles: data,
                links: links,
                meta: meta
            }
        case constants.CREATE_ROLE_SUCCESS:
            return { ...state, isLoaded: true, roles: action.payload.data.data }
        case constants.GET_PERMISSION_SUCCESS:
            return {
                ...state,
                isLoaded: true,
                permissions: action.payload.data.data
            }
        case constants.GET_ROLE_SUCCESS:
            return { ...state, isLoaded: true, role: action.payload.data.data }

        case constants.GET_ROLE_ERROR:
        case constants.GET_ROLES_ERROR:
        case constants.GET_PERMISSION_ERROR:
        case constants.CREATE_ROLE_ERROR:
            return { ...state, isLoaded: false, isError: true }

        case constants.DELETE_ROLE_START:
            return { ...state, isDeleted: false }
        case constants.DELETE_ROLE_ERROR:
            return { ...state, isDeleted: false }

        default:
            return state
    }
}
