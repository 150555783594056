import React, { useMemo, Fragment, useEffect } from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import { Switch, Route, Redirect, useParams, useHistory } from "react-router-dom"
import { Formik, Form } from "formik"

import { Skeleton, Tabs, SaveBar } from "ui"

import { fetchStatusPropTypes } from "helpers/fetchStatus"

import withStyles from "HOC/withStyles"
import styles from "./addEditForm.css"

const AddEditForm = props => {
    const { fetchStatus, initialValues, validationSchema, onSubmit } = props

    return (
        <Skeleton
            fetchStatus={fetchStatus}
            render={() => (
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {formikProps => <FormikForm formik={formikProps} {...props} />}
                </Formik>
            )}
        />
    )
}

function FormikForm(props) {
    const { cx, isSubmitting, basePath, formik, initialValues, config, onCancel, onChangeMetaFields } = props
    const { tabs, defaultAside } = config

    const history = useHistory()
    const { sectionName } = useParams()

    const tabsKeys = !!tabs ? Object.keys(tabs) : []
    const defaultTabKey = tabsKeys[0]
    const activeTabKey = tabsKeys.includes(sectionName) ? sectionName : defaultTabKey
    const AsideComponent = (!!tabs && tabs[activeTabKey].aside) || defaultAside
    const isEdited = useMemo(() => !isEqual(initialValues, formik.values), [initialValues, formik.values])

    const setActiveTabKey = activeTab => basePath && activeTab && history.push(`${basePath}/${activeTab}`)

    useEffect(() => {
        onChangeMetaFields({ errors: formik.errors, touched: formik.touched })
    }, [formik.errors, formik.touched])

    return (
        <Form className={cx("root")}>
            <div className={cx("leftColumn")}>
                {!!tabs ? (
                    <Fragment>
                        <Tabs tabs={tabs} activeTabKey={activeTabKey} onTabChange={setActiveTabKey} />
                        <Switch>
                            {Object.entries(tabs).map(([route, { render: TabComponent }]) => (
                                <Route
                                    key={route}
                                    exact
                                    path={`${basePath}/${route}`}
                                    render={routerProps => <TabComponent {...props} {...routerProps} />}
                                />
                            ))}
                            <Route path={basePath} render={() => <Redirect to={`${basePath}/${defaultTabKey}`} />} />
                        </Switch>
                    </Fragment>
                ) : (
                    <config.defaultLeftColumn {...props} />
                )}
            </div>
            {!!AsideComponent && <AsideComponent {...props} formik={formik} />}
            <SaveBar
                isShown
                isSaving={isSubmitting}
                isSubmit
                submitLabel="Save"
                message={isEdited ? "Unsaved changes" : ""}
                onCancel={onCancel}
            />
        </Form>
    )
}

AddEditForm.defaultProps = {
    config: { defaultLeftColumn: () => null },
    onChangeMetaFields: () => {}
}

const JSXPropType = PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.array])

AddEditForm.propTypes = {
    isSubmitting: PropTypes.bool,
    fetchStatus: fetchStatusPropTypes.isRequired,
    basePath: PropTypes.string,
    initialValues: PropTypes.object.isRequired,
    config: PropTypes.shape({
        defaultAside: JSXPropType,
        defaultLeftColumn: JSXPropType,
        tabs: PropTypes.objectOf(
            PropTypes.shape({
                label: PropTypes.string.isRequired,
                render: JSXPropType.isRequired,
                aside: JSXPropType
            })
        )
    }).isRequired,
    validationSchema: PropTypes.object,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired
}

export default withStyles(AddEditForm, styles)
