import React, { Fragment, useEffect, useState } from "react"
import moment from "moment"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import * as sessionActions from "actions/session"
import * as clientActions from "actions/client"
import withSession from "HOC/withSession"
import withStyles from "HOC/withStyles"
import withPermissions from "HOC/withPermissions"

import { Dropdown } from "ui"
import AddNoteCover from "components/Modal/AddNoteCover/AddNoteCover"
import Modal from "components/Modal/Modal"
import NewSession from "./NewSession"
import AccountDropdown from "./AccountDropdown"
import NotificationsDropdown from "./NotificationsDropdown"

import style from "./Header.css"
import quickCreateDropdownStyles from "./overrides/quickCreateDropdown.css"

import selectionsIcon from "./assets/selections.svg"
import menuIcon from "assets/menu.svg"
import powerIcon from "assets/ico-power-red.svg"

const Header = ({
    activeSession,
    activeClient,
    changeSidebarMenuWidth,
    isSidebarMenuWide,
    canList,
    PERMISSIONS,
    cx,
    dismissClient,
    loginStore
}) => {
    const [elapsedSeconds, setElapsedSeconds] = useState(0)
    const [showModal, setShowModal] = useState(false)
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false)

    useEffect(() => {
        const interval = setInterval(() => {
            if (activeClient) {
                setElapsedSeconds(value => value + 1)
            }
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    const renderClientCover = () => {
        const seconds = moment.utc(elapsedSeconds * 1000).format("mm:ss")
        const isActiveClient = activeClient.active

        return (
            <Fragment>
                {activeSession ? (
                    <div className={cx("sessionBox")}>
                        <p className={cx("userInfo")}>
                            <span className={cx("name")}>
                                {isActiveClient ? (
                                    <Link to={`/clients/${activeClient.id}/profile`} className={cx("profile")}>
                                        {activeClient.name}
                                    </Link>
                                ) : (
                                    "No Client Assigned"
                                )}
                            </span>
                            <span className={cx("time")}>{seconds}</span>
                        </p>

                        {isActiveClient ? (
                            <button className={cx("noteButton")} onClick={() => setIsNoteModalOpen(true)}>
                                <span>Add note</span>
                            </button>
                        ) : (
                            <button className={cx("assignUserButton")} onClick={() => setShowModal(true)}>
                                <span>Assign user</span>
                            </button>
                        )}

                        <div className={cx("separator")} />

                        <button className={cx("quitButton")} onClick={dismissClient}>
                            <img src={powerIcon} alt="quit" />
                        </button>
                    </div>
                ) : (
                    <button className={cx("newSessionButton")} onClick={() => setShowModal(true)} disabled>
                        New Session
                    </button>
                )}

                {isNoteModalOpen && (
                    <Modal>
                        <AddNoteCover
                            handleHide={() => setIsNoteModalOpen(false)}
                            type="client"
                            id={activeClient.id}
                            successAction={() => clientActions.getClientById(activeClient.id)}
                        />
                    </Modal>
                )}
            </Fragment>
        )
    }

    return (
        <header className={cx(isSidebarMenuWide ? "headerFixed" : "headerFixedWide")}>
            <button className={cx("menuButton")} onClick={() => changeSidebarMenuWidth()}>
                <img src={menuIcon} alt="menu" />
            </button>

            <div className={cx("sessionWrapper")}>{canList(PERMISSIONS.context.CLIENTS) && renderClientCover()}</div>

            <Dropdown
                customStyles={quickCreateDropdownStyles}
                label="Quick Create"
                disabled={true}
                isListOnRight={true}
                options={[
                    { label: "New Sales Order", onClick: () => {} },
                    { label: "New Invoice", onClick: () => {} },
                    { label: "New Purchase Order", onClick: () => {} },
                    { type: "separator", label: "" },
                    { label: "New Client", onClick: () => {} }
                ]}
            />

            <button className={cx("selectionsButton")} onClick={() => {}}>
                <img src={selectionsIcon} alt="selections" />
            </button>

            <div className={cx("notificationsWrapper")}>
                <NotificationsDropdown notifications={[]} />
            </div>

            <div className={cx("separator")} />

            <div className={cx("accountWrapper")}>
                <AccountDropdown loginStore={loginStore} />
            </div>

            {showModal && <NewSession isActiveSession={activeSession} handleHide={() => setShowModal(false)} />}
        </header>
    )
}

const mapStateToProps = state => {
    return {
        loginStore: state.loginStore,
        activeClient: state.ui.session.activeClient,
        activeSession: state.ui.session.activeSession
    }
}

const mapDispatchToProps = dispatch => {
    return {
        dismissClient: () => dispatch(sessionActions.dismissClient()),
        toggleSession: () => dispatch(sessionActions.toggleSession())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSession(withPermissions(withStyles(Header, style))))
