import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./Form.css"
import moduleSectionStyles from "./overrides/ModuleSection.css"

import Section from "components/Reusable/Form/Section"
import Layout from "components/Reusable/Layout"
import Input from "components/Reusable/Form/Input"
import Button from "components/Reusable/Form/Button"

class Form extends Component {
    render() {
        const { isEdit, cx, formFields, handleSubmitForm, isSaving, isFilled, history } = this.props
        const { name } = formFields

        return (
            <Layout title={isEdit ? "Edit Combination Template" : "New Combination Template"} customStyles={styles}>
                <div className={cx("form-container", { "form-isFilling": isEdit && !isFilled })}>
                    <Section>
                        <Input {...name} />
                    </Section>
                    <Section customStyles={moduleSectionStyles}>
                        <Button
                            className={["first-button", "submit-button"]}
                            customStyles={styles}
                            onClick={event => handleSubmitForm(event)}
                            label={isEdit ? "Save" : "Create"}
                            isLoading={isSaving}
                        />
                        <span className={cx("form-cancel")} onClick={() => history.goBack()}>
                            Cancel
                        </span>
                    </Section>
                </div>
            </Layout>
        )
    }
}

export default withStyles(Form, styles)
