import { depositUnits } from "../../constants"

const ADDRESS_CONFIG = {
    formFields: {
        company: {
            componentType: "input",
            width: "450px",
            label: "Company name"
        },
        first_name: {
            componentType: "input",
            width: "50%",
            label: "First name"
        },
        last_name: {
            componentType: "input",
            width: "50%",
            label: "Last name"
        },
        country: {
            componentType: "select",
            selectValuesKey: "countries",
            label: "Country"
        },
        address: {
            componentType: "input",
            width: "2 / 3 * 100%",
            label: "Address (Street and No.)"
        },
        city: {
            componentType: "input",
            label: "City"
        },
        state: {
            componentType: "state",
            label: "State"
        },
        post_code: {
            componentType: "input",
            label: "ZIP code"
        }
    },
    displayFormat: {
        strong: ["company", ["first_name", "last_name"]],
        address: ["address", "city", "state", "post_code", "country"]
    }
}

export const BILLING_ADDRESS_CONFIG = {
    ...ADDRESS_CONFIG,
    addressName: "billing_address"
}

export const SHIPPING_ADDRESS_CONFIG = {
    ...ADDRESS_CONFIG,
    addressName: "shipping_address"
}

export const DEPOSIT_UNIT_LABELS = {
    [depositUnits.percent]: "%",
    [depositUnits.amount]: "$"
}

export const TAX_UNIT_LABELS = {
    percent: "%"
}

export const DISCOUNT_UNIT_LABELS = {
    percent: "%"
}
