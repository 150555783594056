import React from "react"
import { Link, withRouter } from "react-router-dom"

import ReactSvg from "react-svg"
import Status from "ui/Status"

import { formatDate } from "helpers/date"

import withStyles from "HOC/withStyles"
import styles from "./head.css"

import personIcon from "assets/person.svg"

const Head = props => {
    const { cx, isStatusChanging, project, handleChangeStatus } = props
    const { status, client, created_at, updated_at, company } = project

    const formatUserName = user => (user ? `${user.first_name || ""} ${user.last_name || ""}` : "-")

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <h1>{project.name}</h1>
                <div className={cx("status")}>
                    <span className={cx("label")}>Project status:</span>
                    <Status.Dropdown
                        status={status}
                        type="project"
                        onChange={handleChangeStatus}
                        isDisabled={isStatusChanging}
                    />
                </div>
            </div>
            <div className={cx("line")} />
            <div className={cx("footer")}>
                <ul>
                    <li>
                        <span className={cx("label")}>Client:</span>
                        <Link
                            to={`/contact/${client && client.id}/profile`}
                            className={cx("clientValue", { isDisabled: !client || client.deleted_at })}
                        >
                            {client && <ReactSvg src={personIcon} />}
                            {formatUserName(client)}
                        </Link>
                    </li>
                    <li>
                        <span className={cx("label")}>Division:</span>
                        <span className={cx("value")}>{company ? company.name : "-"}</span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span className={cx("label")}>Created:</span>
                        <span className={cx("value")}>{formatDate(created_at)}</span>
                    </li>
                    <li>
                        <span className={cx("label")}>Last updated:</span>
                        <span className={cx("value")}>{formatDate(updated_at)}</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default withRouter(withStyles(Head, styles))
