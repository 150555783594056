import React from "react"
import PropTypes from "prop-types"

import {
    Table,
    TableHead,
    TableBody,
    TableHeadCell,
    TableCell,
    TableDateCell,
    TableRow,
    Status,
    EmptyInternalList,
    ListIcon
} from "@butterfly-frontend/ui"
import { formatDate } from "helpers/date"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"

import styles from "./StockItemDetailsTransferHistory.module.css"

const StockItemDetailsTransferHistory = ({ transfers }) => {
    return (
        <div className={styles.root}>
            <h2 className={styles.title}>Transfer history</h2>
            {transfers.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableHeadCell minWidth={150} width="20%">
                            ID
                        </TableHeadCell>
                        <TableHeadCell minWidth={100} width="20%">
                            FROM
                        </TableHeadCell>
                        <TableHeadCell minWidth={100} width="15%">
                            TO
                        </TableHeadCell>
                        <TableHeadCell minWidth={150} width="15%">
                            STATUS
                        </TableHeadCell>
                        <TableHeadCell minWidth={120} width="15%">
                            DATE
                        </TableHeadCell>
                        <TableHeadCell minWidth={100} width="15%">
                            BY
                        </TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {transfers.map(transfer => {
                            const status = SHIPMENT_STATUSES[transfer.status]

                            return (
                                <TableRow key={transfer.id}>
                                    <TableCell minWidth={150} width="20%">
                                        {transfer.uuid}
                                    </TableCell>
                                    <TableCell minWidth={100} width="20%">
                                        {transfer.source_warehouses.map(warehouse => warehouse.name).join(", ")}
                                    </TableCell>
                                    <TableCell minWidth={100} width="15%">
                                        {transfer.destination_warehouse.name}
                                    </TableCell>
                                    <TableCell minWidth={150} width="15%">
                                        <Status theme={status.theme} color={status.color} size="small" withCircle>
                                            {status.label}
                                        </Status>
                                    </TableCell>
                                    <TableDateCell minWidth={120} width="15%">
                                        {formatDate(transfer.created_at)}
                                    </TableDateCell>
                                    <TableCell minWidth={100} width="15%">
                                        {transfer.created_by ? transfer.created_by.full_name : "N/A"}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            ) : (
                <EmptyInternalList text="It has no Transfer History" Icon={ListIcon} />
            )}
        </div>
    )
}

StockItemDetailsTransferHistory.propTypes = {
    transfers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            created_at: PropTypes.string.isRequired,
            created_by: PropTypes.shape({
                full_name: PropTypes.string.isRequired
            }),
            destination_warehouse: PropTypes.shape({
                name: PropTypes.string.isRequired
            }),
            source_warehouses: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired
                })
            ),
            status: PropTypes.string.isRequired
        }).isRequired
    ).isRequired
}

export default StockItemDetailsTransferHistory
