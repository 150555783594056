import React, { useState, useEffect } from "react"
import { compose } from "redux"
import style from "./Sidebar.css"
import Section from "./Section"
import sidebarNav from "./config"
import { Link, withRouter } from "react-router-dom"
import withPermissions from "HOC/withPermissions"
import withStyles from "HOC/withStyles"
import { useIsTablet } from "helpers/rwd"

const expandingSections = sidebarNav.filter(navItem => navItem.seeMore).map(navItem => navItem.name.toLowerCase())

const Sidebar = props => {
    const { cx, location, isSidebarMenuWide } = props

    const isTablet = useIsTablet()
    const currentSection = location.pathname.split("/")[1]
    const canSectionExpand = !!currentSection && expandingSections.includes(currentSection) && !isTablet
    const currentExpandingSection = canSectionExpand ? currentSection : null

    let timeOutId = null

    const [isMenuWide, setIsMenuWide] = useState(false)
    const [expandedSection, setExpandedSection] = useState(null)

    useEffect(() => {
        setIsMenuWide(canSectionExpand)
        setExpandedSection(currentExpandingSection)
    }, [])

    useEffect(() => {
        !isSidebarMenuWide && setExpandedSection(null)
    }, [isSidebarMenuWide])

    useEffect(() => {
        if (window.innerWidth >= 1200 && expandedSection !== currentExpandingSection) {
            setExpandedSection(currentExpandingSection)
        }
    }, [location.pathname])

    const handleClose = isFromSubItem => {
        setIsMenuWide(false)

        if (!isFromSubItem && window.innerWidth >= 1200) {
            setExpandedSection(currentExpandingSection)
            return
        }

        if (window.innerWidth < 1200 || !isFromSubItem) {
            setExpandedSection(null)
        }
    }

    const handleToggle = stateKey => {
        const shouldCollapse = expandedSection === stateKey
        const newExpandedSection = !shouldCollapse && stateKey ? stateKey : null

        setIsMenuWide(!shouldCollapse)
        setExpandedSection(newExpandedSection)
    }

    const handleOnFocus = () => clearTimeout(timeOutId)

    const handleOnBlur = () => (timeOutId = setTimeout(() => isMenuWide && handleClose()))

    return (
        <nav
            className={cx({
                "sidebar-nav": isSidebarMenuWide,
                "sidebar-nav-slim": !isSidebarMenuWide,
                "sidebar-nav-expanded": isMenuWide
            })}
        >
            <div className={cx("logo-cover")}>
                <Link to="/" style={{ textDecoration: "none" }}>
                    <h1 style={{ paddingTop: 3 }}>Dragonfly</h1>
                </Link>
            </div>
            <ul className={cx("sidebar-nav-ul")} onFocus={handleOnFocus} onBlur={handleOnBlur}>
                {sidebarNav.map(section => (
                    <li key={section.name}>
                        <Section
                            {...props}
                            details={section}
                            expandedSection={expandedSection}
                            handleClose={handleClose}
                            handleToggle={handleToggle}
                            isSidebarMenuWide={isSidebarMenuWide}
                        />
                    </li>
                ))}
            </ul>
        </nav>
    )
}

const enhance = compose(
    withRouter,
    withPermissions
)

export default enhance(withStyles(Sidebar, style))
