import React, { useState, useEffect, useMemo, Fragment } from "react"
import { useHistory, useParams } from "react-router-dom"

import { withMultiselectProvider } from "HOC/withMultiselect"

import Tabs from "ui/Tabs"
import { ProjectInfo, Notes, Attachments, SaleOrders, PurchaseOrders, Invoices, Payments } from "./Tabs"

const CurrentDetail = React.memo(({ Component, project, projectId }) => {
    return <Component project={project} projectId={projectId} />
})

const Details = props => {
    const { projectId, project } = props
    const { notes, attachments } = project.data

    const history = useHistory()
    const { sectionName } = useParams()

    const tabs = useMemo(
        () => ({
            "project-info": { label: "PROJECT INFO", Component: ProjectInfo, disabled: false },
            notes: {
                label: "NOTES",
                Component: Notes,
                disabled: false,
                count: Array.isArray(notes) ? notes.length : 0
            },
            attachments: {
                label: "ATTACHMENTS",
                Component: Attachments,
                disabled: false,
                count: Array.isArray(attachments) ? attachments.length : 0
            },
            "sale-orders": { label: "SALES ORDERS", Component: SaleOrders, disabled: false },
            "purchase-orders": { label: "PURCHASE ORDERS", Component: PurchaseOrders, disabled: false },
            invoices: { label: "INVOICES", Component: Invoices, disabled: false },
            payments: { label: "PAYMENTS", Component: Payments, disabled: false }
        }),
        [notes, attachments]
    )
    const tabsKeys = Object.keys(tabs)

    const [activeTabIndex, setActiveTabIndex] = useState(tabsKeys[0])

    useEffect(() => {
        setActiveTabIndex(sectionName || tabsKeys[0])

        const isTabDisabled = tabs[sectionName] && tabs[sectionName].disabled
        isTabDisabled && history.push(`/projects/${projectId}`)
    }, [sectionName])

    const onTabChange = key => history.push(`/projects/${projectId}/${key}`)

    const currentComponent = tabs[sectionName] ? tabs[sectionName].Component : Object.values(tabs)[0].Component

    return (
        <Fragment>
            <Tabs tabs={tabs} activeTabKey={activeTabIndex} onTabChange={onTabChange} />
            <CurrentDetail Component={currentComponent} project={project} projectId={projectId} />
        </Fragment>
    )
}

export default withMultiselectProvider("PROJECT_INFO")(Details)
