import React from "react"
import PropTypes from "prop-types"

import { SelectLocation } from "ui/Filters"
import { selectWithSearchPropTypes } from "ui/SelectWithSearch/SelectWithSearch"

import selectLocationStyles from "./overrides/SelectLocation.css"

const BoxLocationSelect = ({ handleSelect, onCreateNewLocationSuccess, value, selectWithSearchProps }) => (
    <SelectLocation
        handleSelect={handleSelect}
        onCreateNewLocationSuccess={onCreateNewLocationSuccess}
        value={value}
        selectWithSearchProps={selectWithSearchProps}
        customStyles={selectLocationStyles}
    />
)

const { setValue, values, ...restSelectWithSearchPropTypes } = selectWithSearchPropTypes

BoxLocationSelect.propTypes = {
    handleSelect: PropTypes.func.isRequired,
    onCreateNewLocationSuccess: PropTypes.func.isRequired,
    value: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    selectWithSearchProps: PropTypes.shape(restSelectWithSearchPropTypes)
}

export default BoxLocationSelect
