import React from "react"

import withStyles from "HOC/withStyles"
import styles from "./filtersListHeader.css"

const ListHeader = props => {
    const { cx } = props

    return (
        <div className={cx("root")}>
            <div>NAME</div>
            <div>Insights</div>
        </div>
    )
}

export default withStyles(ListHeader, styles)
