import React, { useState } from "react"
import { Modal, Button, Input } from "ui"
import useStyles from "helpers/getCxFromStyles"
import styles from "./ImportCalendarModal.css"
import inputStyles from "./overrides/Input.css"
import modalBodyStyles from "./overrides/ModalBody.css"
import modalActionsStyles from "./overrides/ModalActions.css"

const ImportCalendarModal = props => {
    const { isOpen, onClose, isSubmitting } = props
    const cx = useStyles(styles)
    const [name, setName] = useState("")
    const [url, setUrl] = useState("")
    const [errors] = useState({ name: "", url: "" })

    const handleSubmit = () => {}

    return (
        <Modal isOpen={isOpen} closeModal={onClose} preventClickOutside>
            <Modal.Header>Import calendar from Internet</Modal.Header>
            <Modal.Body customStyles={modalBodyStyles}>
                <p className={cx("message")}>You can add an external calendar using the iCal format by its address.</p>
                <Input
                    name="url"
                    label="URL of calendar"
                    value={url}
                    onChange={({ event }) => setUrl(event.target.value)}
                    customStyles={inputStyles}
                    error={errors.url}
                />
                <div className={cx("divider")} />
                <Input
                    name="name"
                    label="Calendar name"
                    value={name}
                    onChange={({ event }) => setName(event.target.value)}
                    customStyles={inputStyles}
                    error={errors.name}
                />
            </Modal.Body>
            <Modal.Actions customStyles={modalActionsStyles}>
                <button className={cx("cancelButton")} onClick={onClose}>
                    Cancel
                </button>
                <Button className="first-button" label="Import" isLoading={isSubmitting} onClick={handleSubmit} />
            </Modal.Actions>
        </Modal>
    )
}

export default ImportCalendarModal
