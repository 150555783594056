import React, { useMemo } from "react"
import { Field } from "formik"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import * as moment from "moment"
import { uniq } from "lodash"

import withStyles from "HOC/withStyles"

import { Checkbox, Toggle, TimePicker } from "ui"

import styles from "./styles.css"
import checkboxStyles from "../../overrides/checkbox.css"
import toggleStyles from "../../overrides/toggle.css"

const NotificationsSection = ({ cx, form, calendars }) => {
    const renderCalendarsSection = (calendars, title) => {
        const allSelected = calendars.every(item => !form.values.disabled_calendars.includes(item.id))
        const noneSelected = calendars.every(item => form.values.disabled_calendars.includes(item.id))

        return (
            <div className={cx("section")}>
                <div className={cx("header")}>
                    <h2>{title}</h2>
                    <div className={cx("buttons")}>
                        <span
                            className={cx("button", { inactive: allSelected })}
                            onClick={() =>
                                !allSelected &&
                                form.setFieldValue(
                                    "disabled_calendars",
                                    form.values.disabled_calendars.filter(
                                        calendarID => !calendars.find(item => item.id === calendarID)
                                    )
                                )
                            }
                        >
                            Select all
                        </span>
                        <span
                            className={cx("button", { inactive: noneSelected })}
                            onClick={() =>
                                !noneSelected &&
                                form.setFieldValue(
                                    "disabled_calendars",
                                    uniq([...form.values.disabled_calendars, ...calendars.map(item => item.id)])
                                )
                            }
                        >
                            Deselect all
                        </span>
                    </div>
                </div>

                <ul className={cx("calendarsWrapper")}>
                    {calendars.map(item => (
                        <li key={item.id}>
                            <Checkbox
                                id={item.id}
                                name={item.title}
                                checked={!form.values.disabled_calendars.includes(item.id)}
                                onChange={() =>
                                    form.values.disabled_calendars.includes(item.id)
                                        ? form.setFieldValue(
                                              "disabled_calendars",
                                              form.values.disabled_calendars.filter(
                                                  calendarID => calendarID !== item.id
                                              )
                                          )
                                        : form.setFieldValue("disabled_calendars", [
                                              ...form.values.disabled_calendars,
                                              item.id
                                          ])
                                }
                                customStyles={checkboxStyles}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        )
    }

    const publicCalendars = useMemo(() => calendars.filter(item => item.is_public), [calendars])
    const privateCalendars = useMemo(() => calendars.filter(item => !item.is_public), [calendars])

    return (
        <div className={cx("root")}>
            {renderCalendarsSection(publicCalendars, "Public calendars")}
            {renderCalendarsSection(privateCalendars, "Private calendars")}

            <div className={cx("section")}>
                <div className={cx("header")}>
                    <h2>Daily mail</h2>
                    <div className={cx("mailSettings")}>
                        <Field name="daily_mail">
                            {({ field }) => (
                                <Toggle
                                    isOn={!!field.value}
                                    label={{
                                        on: "active",
                                        off: "inactive"
                                    }}
                                    labelPosition="left"
                                    handleChange={value => form.setFieldValue(field.name, value)}
                                    customStyles={toggleStyles}
                                />
                            )}
                        </Field>

                        <div className={cx("separator")} />

                        <span className={cx("label")}>Sending hour:</span>
                        <Field name="daily_mail_time">
                            {({ field }) => (
                                <TimePicker
                                    onChange={value => form.setFieldValue(field.name, value)}
                                    value={
                                        field.value ||
                                        moment()
                                            .hour(0)
                                            .minute(0)
                                            .format("h:mm a")
                                    }
                                    disabled={!form.values.daily_mail}
                                    isListOnTop
                                />
                            )}
                        </Field>
                    </div>
                </div>
            </div>
        </div>
    )
}

NotificationsSection.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    states: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired
}

const mapStateToProps = state => {
    return {
        states: state.clientStore.statesList,
        countries: state.clientStore.countriesList
    }
}

export default compose(connect(mapStateToProps))(withStyles(NotificationsSection, styles))
