import fetch from "helpers/fetch"
import { useQuery } from "react-query"

export const KEY = "user"

export const fetchSeller = (id = "") => fetch.get(`/user/${id}`)

export const useSeller = id => {
    return useQuery([KEY, id], () => fetchSeller(id), {
        refetchOnWindowFocus: false,
        retry: false
    })
}
