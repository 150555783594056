import React from "react"
import PropTypes from "prop-types"

import SelectWithSearch from "ui/SelectWithSearch/SelectWithSearch"

const Select = props => {
    const { withSearch, withFetch } = props

    return <SelectWithSearch {...props} withoutFetch={!withFetch} withoutSearch={!withSearch} />
}

Select.defaultProps = {
    value: "",
    type: "text",
    placeholder: "- Please select -",
    emptyListLabel: "Empty list",
    additionalButtonLabel: "Create new",
    pagination: {
        current_page: 0,
        last_page: 1
    }
}

const valuesElementShape = PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object, PropTypes.bool]),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    secondLabel: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
})

Select.propTypes = {
    multiselect: PropTypes.bool,
    withSearch: PropTypes.bool,
    withFetch: PropTypes.bool,
    isListOnTop: PropTypes.bool,
    isBlocked: PropTypes.bool,
    isClearBlocked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(valuesElementShape)]),
    values: PropTypes.arrayOf(valuesElementShape).isRequired,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string), PropTypes.bool]),
    pagination: PropTypes.shape({
        current_page: PropTypes.number,
        last_page: PropTypes.number
    }),
    emptyListLabel: PropTypes.string,
    allSelectedValueLabel: PropTypes.string,
    selectAllLabel: PropTypes.string,
    setValue: PropTypes.func.isRequired, // (multiselect ? Array<{ value: number | string | object, label: string }> : { value: number | string | object, label: string }) => {}
    fetchValues: PropTypes.func, // (query: string, page: number) => {}
    handleScrollToError: PropTypes.func,
    additionalButtonIcon: PropTypes.node,
    additionalButtonLabel: PropTypes.string,
    additionalButtonAction: PropTypes.func,
    customStyles: PropTypes.any,
    customErrorStyles: PropTypes.any
}

export default Select
