import React, { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import idx from "idx"
import withStyles from "HOC/withStyles"
import { countSelectedFilters } from "helpers/form"
import { escapeAmountValue } from "helpers/units"

import { userStateFromLoginStore } from "../../ListContainer"
import { MAX_AMOUNT_FILTER_INPUT_VALUE } from "constants/index"

import { Button, Toggle, Input } from "ui"
import { MoreFilters } from "ui/FilterableTable"

import styles from "./styles.css"
import selectStyles from "./overrides/selectStyles.css"
import cancelButtonStyles from "./overrides/cancelButton.css"
import submitButtonStyles from "./overrides/submitButton.css"
import toggleStyles from "./overrides/toggleStyles.css"
import inputStyles from "./overrides/inputStyles.css"

import { SelectBrand, SelectClient, SelectProject, SelectUser, SelectPaymentMethod, InputRangeAmount } from "ui/Filters"

const Filters = props => {
    const {
        cx,
        moreFilters,
        setMoreFilters,
        name,
        isProjectsFilterHidden,
        isPaymentsFilters,
        isInvoicesFilters
    } = props

    const loginStore = useSelector(state => state.loginStore)

    const [isVisible, setIsVisible] = useState(false)
    const [localMoreFilters, setLocalMoreFilters] = useState(moreFilters)
    const setFilter = useCallback((name, value) => {
        setLocalMoreFilters(state => ({ ...state, [name]: value || null }), [setLocalMoreFilters])
    })
    const handleSelect = ({ target: { name, value } }) => setFilter(name, value)
    const [amountError, setAmountError] = useState(null)

    useEffect(() => {
        if (!isVisible) {
            setLocalMoreFilters(moreFilters)
        }
    }, [isVisible, moreFilters])

    const toggleLabel = `Only my ${name}s`

    const toggleErrorsOnAmountFilter = name => {
        const filter = Number(localMoreFilters[name])
        if (filter < 0 || filter > MAX_AMOUNT_FILTER_INPUT_VALUE || typeof filter !== "number") {
            setAmountError(prevState => ({ ...prevState, [name]: "Please insert correct value" }))
        } else {
            setAmountError(prevState => ({ ...prevState, [name]: null }))
        }
    }

    const checkAmountFilers = moreFilters => {
        const { amount_min, amount_max } = localMoreFilters
        const isGreaterThanZero = Number(amount_min) >= 0 && Number(amount_max) >= 0
        const isLessThanBig =
            Number(amount_min) <= MAX_AMOUNT_FILTER_INPUT_VALUE && Number(amount_max) <= MAX_AMOUNT_FILTER_INPUT_VALUE
        const isNumber = !isNaN(amount_min) || !isNaN(amount_max)

        if (isGreaterThanZero && isLessThanBig && isNumber) {
            setAmountError(null)
            setIsVisible(false)
            setMoreFilters({
                ...localMoreFilters,
                amount:
                    isInvoicesFilters && localMoreFilters.amount
                        ? escapeAmountValue(localMoreFilters.amount)
                        : localMoreFilters.amount
            })
        } else {
            toggleErrorsOnAmountFilter("amount_min")
            toggleErrorsOnAmountFilter("amount_max")
        }
    }

    return (
        <MoreFilters
            seamlessButtonTransition
            numberOfSelectedFilters={countSelectedFilters(moreFilters)}
            isVisible={isVisible}
            toggleVisible={() => setIsVisible(visible => !visible)}
        >
            <div className={cx("root")}>
                <div className={cx("filters")}>
                    <div className={cx("row")}>
                        <div className={cx("column")}>
                            <SelectUser
                                customStyles={selectStyles}
                                handleSelect={handleSelect}
                                label="Assigned to"
                                name="user"
                                value={idx(localMoreFilters, _ => _.user.label)}
                                multiselect={false}
                            />
                            <Toggle
                                isOn={idx(localMoreFilters, _ => _.user.id) === Number(loginStore.userId)}
                                label={{ on: toggleLabel, off: toggleLabel }}
                                labelPosition="left"
                                handleChange={isOn =>
                                    setFilter("user", isOn ? userStateFromLoginStore(loginStore) : null)
                                }
                                customStyles={toggleStyles}
                            />
                        </div>
                        <div className={cx("column")}>
                            <SelectClient
                                customStyles={selectStyles}
                                handleSelect={value => setFilter("client", value)}
                                label="Client"
                                name="client"
                                value={localMoreFilters.client}
                            />
                        </div>
                        {!isPaymentsFilters && (
                            <div className={cx("column")}>
                                <SelectBrand
                                    label="Vendor"
                                    name="brand"
                                    value={idx(localMoreFilters, _ => _.brand.label)}
                                    handleSelect={handleSelect}
                                    customStyles={selectStyles}
                                    multiselect={false}
                                />
                            </div>
                        )}
                        {!isProjectsFilterHidden && (
                            <div className={cx("column")}>
                                <SelectProject
                                    label="Project"
                                    name="project"
                                    value={idx(localMoreFilters, _ => _.project.label)}
                                    handleSelect={handleSelect}
                                    customStyles={selectStyles}
                                    multiselect={false}
                                />
                            </div>
                        )}
                        {isPaymentsFilters && (
                            <div className={cx("column")}>
                                <SelectPaymentMethod
                                    label="Method"
                                    name="payment_method"
                                    value={idx(localMoreFilters, _ => _.payment_method)}
                                    handleSelect={value => setFilter("payment_method", value)}
                                    customStyles={selectStyles}
                                />
                            </div>
                        )}
                    </div>
                    <div className={cx("row", "rowWithActions")}>
                        {isInvoicesFilters && (
                            <div className={cx("column", "amount")}>
                                <Input
                                    label="Amount"
                                    innerLabel="$"
                                    isInnerLabelLeft
                                    isInline
                                    value={idx(localMoreFilters, _ => _.amount)}
                                    onChange={({
                                        event: {
                                            target: { value }
                                        }
                                    }) => setFilter("amount", value)}
                                    customStyles={inputStyles}
                                />
                            </div>
                        )}
                        {isPaymentsFilters && (
                            <div className={cx("column", "range")}>
                                <InputRangeAmount
                                    values={localMoreFilters}
                                    handleSelect={setFilter}
                                    error={amountError}
                                />
                            </div>
                        )}
                        <div className={cx("actions")}>
                            <Button
                                label="Clear all filters"
                                onClick={() => {
                                    setIsVisible(false)
                                    setMoreFilters(
                                        Object.keys(moreFilters).reduce((acc, key) => ({ ...acc, [key]: null }), {})
                                    )
                                }}
                                customStyles={cancelButtonStyles}
                            />
                            <Button
                                label="Show results"
                                onClick={() => {
                                    checkAmountFilers()
                                }}
                                className="first-button"
                                customStyles={submitButtonStyles}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </MoreFilters>
    )
}

export default withStyles(Filters, styles)
