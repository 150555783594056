import { combineReducers } from "redux"

import current from "./current"
import list from "./list"
import priceStructure from "./priceStructure"

const brandsReducer = combineReducers({
    current,
    priceStructure,
    list
})

export default brandsReducer
