import React, { Fragment } from "react"
import ReactSVG from "react-svg"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"
import { DragAndDropList } from "modules/ContentModule/components"
import { EmptyList, Input } from "ui"
import { Checkbox } from "ui/FilterableTable"
import { SnakeLoader } from "ui/Skeleton"

import styles from "./List.css"
import dragAndDropListStyles from "./overrides/DragAndDropList.css"
import checkboxStyles from "./overrides/Checkbox.css"
import inputStyles from "./overrides/Input.css"
import loaderStyles from "./overrides/Loader.css"
import searchIcon from "assets/search.svg"
import documentIcon from "assets/document.svg"

const List = props => {
    const {
        items,
        query,
        areSomeChecked,
        areAllChecked,
        isDragDisabled,
        placeholderSearch,
        handleReorder,
        handleSearch,
        handleSelectPage,
        handleCheckAll,
        metaItems,
        fetchStatusItems,
        HeaderComponent,
        children
    } = props
    const { current_page, last_page } = metaItems
    const { isLoading, isLoaded } = fetchStatusItems
    const cx = useStyles(styles)

    return (
        <div className={cx("root")}>
            <div className={cx("search")}>
                <ReactSVG src={searchIcon} className={cx("searchIcon")} />
                <Input
                    isPlaceholder
                    value={query}
                    label={placeholderSearch}
                    onChange={handleSearch}
                    customStyles={inputStyles}
                />
            </div>
            {(isLoaded || current_page > 1) && (
                <div className={cx("list")}>
                    {isLoaded && !metaItems.total ? (
                        <EmptyList icon={documentIcon} message="Empty list" />
                    ) : (
                        <Fragment>
                            <div className={cx("header")}>
                                <Checkbox
                                    checked={areSomeChecked}
                                    isMinusIcon={areSomeChecked && !areAllChecked}
                                    onChange={handleCheckAll}
                                    customStyles={checkboxStyles}
                                />
                                <HeaderComponent />
                            </div>
                            <div className={cx("items")}>
                                <DragAndDropList
                                    itemsList={items}
                                    handleReorder={handleReorder}
                                    customStyles={dragAndDropListStyles}
                                    disabled={isDragDisabled}
                                >
                                    {children}
                                </DragAndDropList>
                            </div>
                        </Fragment>
                    )}
                    {current_page < last_page && isLoaded && (
                        <div className={cx("footer")}>
                            <button
                                type="button"
                                className={cx("pagination")}
                                onClick={() => isLoaded && handleSelectPage(current_page + 1)}
                            >
                                Show more
                            </button>
                        </div>
                    )}
                    {isLoading && <SnakeLoader customStyles={loaderStyles} />}
                </div>
            )}
            {isLoading && current_page === 1 && (
                <div className={cx("loaderWrapper")}>
                    <SnakeLoader customStyles={loaderStyles} />
                </div>
            )}
        </div>
    )
}

const FetchStatusPropTypes = PropTypes.shape({
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isError: PropTypes.bool,
    error: PropTypes.string
}).isRequired

List.propTypes = {
    items: PropTypes.array.isRequired,
    fetchStatusItems: FetchStatusPropTypes,
    metaItems: PropTypes.shape({
        total: PropTypes.number.isRequired,
        current_page: PropTypes.number.isRequired,
        last_page: PropTypes.number.isRequired
    }).isRequired,
    handleReorder: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    handleSelectPage: PropTypes.func.isRequired,
    handleCheckAll: PropTypes.func.isRequired,
    query: PropTypes.string,
    areAllChecked: PropTypes.bool,
    areSomeChecked: PropTypes.bool,
    isDragDisabled: PropTypes.bool,
    placeholderSearch: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.element)
}

export default List
