import * as fetchStatusHelper from "helpers/fetchStatus"
import * as types from "../../../actionTypes"

const initialState = {
    data: {},
    fetchStatus: fetchStatusHelper.initialState()
}

function current(state = initialState, action) {
    if (types.GET_PRODUCT_REQUEST === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.requestState() }
    }

    if (types.GET_PRODUCT_SUCCESS === action.type) {
        return {
            ...initialState,
            data: action.payload.data || {},
            fetchStatus: fetchStatusHelper.successState()
        }
    }

    if (types.GET_PRODUCT_FAILURE === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.failureState() }
    }

    if (types.RESET_PRODUCT === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.initialState() }
    }

    return state
}

export default current
