import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./UsersManagement.css"
import { Link } from "react-router-dom"
import UsersListCover from "./UsersListCover/UsersListCover"
import Button from "components/Reusable/Form/Button/index"

class UsersManagement extends Component {
    render() {
        const { cx, canList, canAdd, PERMISSIONS } = this.props
        return (
            <div className={cx("round-border", "user-management-cover")}>
                <h1 className={cx("users-title")}>Users</h1>
                {canList(PERMISSIONS.context.ROLES) && canAdd(PERMISSIONS.context.USERS) && (
                    <Link to="/settings/users/newuser">
                        <Button className={["first-button", "add-button"]} customStyles={styles} label="Add New" />
                    </Link>
                )}
                <div className={cx("clear")} />
                <UsersListCover />
            </div>
        )
    }
}

export default withStyles(UsersManagement, styles)
