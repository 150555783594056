import { useEffect, useState } from "react"
import {
    isAfter,
    setHours,
    startOfHour,
    startOfTomorrow,
    startOfToday,
    differenceInMilliseconds,
    addDays
} from "date-fns"

const SHIPPING_HOUR_THRESHOLD = 15

const getShippingDateThreshold = currentDate => startOfHour(setHours(currentDate, SHIPPING_HOUR_THRESHOLD))

const getShippingDate = currentDate =>
    isAfter(currentDate, getShippingDateThreshold(currentDate)) ? startOfTomorrow() : startOfToday()

const getMillisecondsToNextThresholdDate = currentDate => {
    const todaysThresholdDate = getShippingDateThreshold(currentDate)

    return isAfter(currentDate, todaysThresholdDate)
        ? differenceInMilliseconds(addDays(todaysThresholdDate, 1), currentDate)
        : differenceInMilliseconds(todaysThresholdDate, currentDate)
}

const useShippingDate = () => {
    const [shippingDate, setShippingDate] = useState(getShippingDate(new Date()))

    useEffect(() => {
        let countToThresholdDate = setTimeout(
            () => setShippingDate(getShippingDate(new Date())),
            getMillisecondsToNextThresholdDate(new Date())
        )

        return () => clearTimeout(countToThresholdDate)
    }, [shippingDate])

    return shippingDate
}

export default useShippingDate
