import React, { useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { MULTISELECT_TOGGLE, MULTISELECT_CLEAR, MULTISELECT_SET_STATE } from "constants/index"

const MultiselectContext = React.createContext({ multiselectId: "", state: [] })

const getActions = (dispatch, id, tableId) => ({
    toggleCheckboxes: payload => {
        dispatch({ type: MULTISELECT_TOGGLE, payload, meta: { id, tableId } })
    },
    clearCheckboxes: () => {
        dispatch({ type: MULTISELECT_CLEAR, payload: [], meta: { id, tableId } })
    },
    setCheckboxes: payload => {
        dispatch({ type: MULTISELECT_SET_STATE, payload, meta: { id, tableId } })
    }
})

const getStateWithValidation = (state, multiselectId, tableId) => {
    if (!state || typeof state !== "object") {
        throw new Error(`Field with id '${multiselectId}' does not exist in multiselect state.`)
    }

    const stateIs = Array.isArray(state) ? "array" : Array.isArray(state[tableId]) ? "object" : "invalid"

    if (stateIs === "invalid") {
        throw new Error(`Array with id '${tableId}' does not exist in '${multiselectId}' field of multiselect state.`)
    }

    return stateIs === "array" ? state : state[tableId]
}

export const withMultiselectProvider = multiselectId => WrappedComponent => {
    const MultiselectProvider = props => {
        const state = useSelector(state => state.ui.multiselect[multiselectId])

        if (!state || typeof state !== "object") {
            throw new Error(`Field with id '${multiselectId}' does not exist in multiselect state.`)
        }

        return (
            <MultiselectContext.Provider value={{ multiselectId, state }}>
                <WrappedComponent {...props} />
            </MultiselectContext.Provider>
        )
    }

    return MultiselectProvider
}

export const withMultiselectConsumer = tableId => WrappedComponent => {
    const MultiselectConsumer = props => {
        const { multiselectId, state } = useContext(MultiselectContext)
        const dispatch = useDispatch()
        const multiselectActions = getActions(dispatch, multiselectId, tableId)

        return (
            <WrappedComponent
                {...props}
                multiselect={{
                    checkedList: getStateWithValidation(state, multiselectId, tableId),
                    ...multiselectActions
                }}
            />
        )
    }

    return MultiselectConsumer
}

const withMultiselect = identifier => WrappedComponent => {
    const [multiselectId, tableId] = identifier.split(".")

    const Multiselect = props => {
        const state = useSelector(state => state.ui.multiselect[multiselectId])
        const dispatch = useDispatch()
        const multiselectActions = getActions(dispatch, multiselectId, tableId)

        return (
            <WrappedComponent
                {...props}
                multiselect={{
                    checkedList: getStateWithValidation(state, multiselectId, tableId),
                    ...multiselectActions
                }}
            />
        )
    }

    return Multiselect
}

export default withMultiselect
