import React, { Fragment, useMemo } from "react"
import { Link } from "react-router-dom"
import ReactSvg from "react-svg"
import PropTypes from "prop-types"
import { format } from "date-fns"

import { getRouteByOrderType } from "helpers/urls"
import { formatPrice } from "helpers/units"
import {
    Table,
    EmptyList,
    SnakeLoader,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    GridIcon,
    useTableSort,
    TableSortableHeadCell
} from "@butterfly-frontend/ui"
import { Pagination } from "ui"

import { usePaymentsList } from "modules/DashboardModule/hooks/api/usePayments"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import { formatDateToApi } from "modules/WmsModule/helpers/date"

import clockIcon from "assets/clock.svg"
import styles from "./PaymentsList.module.css"

const TABLE_COLUMNS = {
    amount: { minWidth: 100, width: "20%" },
    client: { minWidth: 100, width: "20%" },
    paymentDate: { minWidth: 100, width: "20%" },
    source: { minWidth: 120, width: "20%" },
    invoice: { minWidth: 100, width: "20%" }
}

const cellBold = {
    root: [styles.cellBold]
}

const cellGrey = {
    root: [styles.cellGrey]
}

const PaymentsList = ({ selectedRange }) => {
    const { sortState, onChangeSort } = useTableSort({
        initialState: { direction: "desc", by: "created_at" }
    })

    const filters = useMemo(
        () => ({
            created_from: selectedRange.startDate ? formatDateToApi(selectedRange.startDate) : null,
            created_to: selectedRange.endDate ? formatDateToApi(selectedRange.endDate) : null
        }),
        [selectedRange]
    )

    const { data, fetchStatus, fetchFlags, pagination } = useTableLogic({
        sort: sortState,
        useQueryList: usePaymentsList,
        filters
    })

    return (
        <Fragment>
            {fetchStatus.isLoading && <SnakeLoader classes={{ root: styles.loader }} />}
            {fetchStatus.isLoaded && (
                <Table>
                    <div className={styles.table}>
                        <TableHead>
                            <TableSortableHeadCell
                                {...TABLE_COLUMNS.amount}
                                sortState={sortState}
                                columnKey="amount"
                                onClick={onChangeSort}
                            >
                                AMOUNT
                            </TableSortableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.client}>CLIENT</TableHeadCell>
                            <TableSortableHeadCell
                                {...TABLE_COLUMNS.paymentDate}
                                sortState={sortState}
                                columnKey="date"
                                onClick={onChangeSort}
                            >
                                PAYMENT DATE
                            </TableSortableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.source}>SOURCE</TableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.invoice}>INVOICE</TableHeadCell>
                        </TableHead>
                        <TableBody>
                            {fetchFlags.isEmptyList && <EmptyList Icon={GridIcon} label="List is empty." />}
                            {fetchFlags.isEmptyListWithFilter && (
                                <EmptyList Icon={GridIcon} label="No records found for selected filters." />
                            )}
                            {fetchStatus.isLoaded &&
                                data.map(payment => (
                                    <TableRow key={payment.id}>
                                        <TableCell {...TABLE_COLUMNS.amount} classes={cellBold}>
                                            {formatPrice(payment.amount)}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.client}>{payment.client.full_name}</TableCell>
                                        <TableCell {...TABLE_COLUMNS.paymentDate} classes={cellGrey}>
                                            <span className={styles.date}>
                                                <ReactSvg src={clockIcon} />
                                                {format(new Date(payment.date), "dd MMM yyyy")}
                                            </span>
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.source}>
                                            {(payment.order || payment.project) && (
                                                <Link
                                                    className={styles.link}
                                                    to={{
                                                        pathname: payment.project
                                                            ? `/projects/${payment.project}`
                                                            : `/orders/${getRouteByOrderType(payment.order.type)}/${
                                                                  payment.order.id
                                                              }`
                                                    }}
                                                >
                                                    {payment.project ? "Project" : payment.order.uuid}
                                                </Link>
                                            )}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.invoice}>
                                            {!!payment.invoice && (
                                                <Link
                                                    className={styles.link}
                                                    to={{
                                                        pathname: `/orders/${getRouteByOrderType(payment.order.type)}/${
                                                            payment.order.id
                                                        }/invoices`,
                                                        state: { invoiceId: payment.invoice.id }
                                                    }}
                                                >
                                                    {payment.invoice.uuid}
                                                </Link>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                        {pagination.canDisplay && (
                            <div className={styles.paginationWrapper}>
                                <Pagination {...pagination} />
                            </div>
                        )}
                    </div>
                </Table>
            )}
        </Fragment>
    )
}

PaymentsList.propTypes = {
    selectedRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired
    }).isRequired
}

export default PaymentsList
