import env from "env"

const API = env.API

export const TOGGLE_MENU = "TOGGLE_MENU"
export const TOGGLE_CLIENT_DROPDOWN = "TOGGLE_CLIENT_DROPDOWN"
export const TOGGLE_LOGOUT_DROPDOWN = "TOGGLE_LOGOUT_DROPDOWN"
export const TOGGLE_SESSION = "TOGGLE_SESSION"
export const ASSIGN_CLIENT = "ASSIGN_CLIENT"
export const DISMISS_CLIENT = "DISMISS_CLIENT"
export const ASSIGN_TEMPORARY_CLIENT = "ASSIGN_TEMPORARY_CLIENT"

export const SHOW_ERROR_NOTIFICATION = "SHOW_ERROR_NOTIFICATION"
export const SHOW_SUCCESS_NOTIFICATION = "SHOW_SUCCESS_NOTIFICATION"
export const SHOW_INFO_NOTIFICATION = "SHOW_INFO_NOTIFICATION"
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION"

export const GET_USERS_START = "GET_USERS_START"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_ERROR = "GET_USERS_ERROR"

export const GET_USER_START = "GET_USER_START"
export const GET_USER_SUCCESS = "GET_USER_SUCCESS"
export const GET_USER_ERROR = "GET_USER_ERROR"

export const ADD_USER_START = "ADD_USER_START"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_ERROR = "ADD_USER_ERROR"

export const CREATE_SENDABLE_START = "CREATE_SENDABLE_START"
export const CREATE_SENDABLE_SUCCESS = "CREATE_SENDABLE_SUCCESS"
export const CREATE_SENDABLE_ERROR = "CREATE_SENDABLE_ERROR"

export const DELETE_USER_START = "DELETE_USER_START"
export const DELETE_USER_ERROR = "DELETE_USER_ERROR"

export const GET_SOURCES_START = "GET_SOURCES_START"
export const GET_SOURCES_SUCCESS = "GET_SOURCES_SUCCESS"
export const GET_SOURCES_ERROR = "GET_SOURCES_ERROR"

export const GET_SOURCE_START = "GET_SOURCE_START"
export const GET_SOURCE_SUCCESS = "GET_SOURCE_SUCCESS"
export const GET_SOURCE_ERROR = "GET_SOURCE_ERROR"

export const DELETE_SOURCE_START = "DELETE_SOURCE_START"
export const DELETE_SOURCE_SUCCESS = "DELETE_SOURCE_SUCCESS"
export const DELETE_SOURCE_ERROR = "DELETE_SOURCE_ERROR"

export const GET_SERVICES_START = "GET_SERVICES_START"
export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS"
export const GET_SERVICES_ERROR = "GET_SERVICES_ERROR"

export const DELETE_SERVICE_START = "DELETE_SERVICE_START"
export const DELETE_SERVICE_SUCCESS = "DELETE_SERVICE_SUCCESS"
export const DELETE_SERVICE_ERROR = "DELETE_SERVICE_ERROR"

export const GET_SERVICE_START = "GET_SERVICE_START"
export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS"
export const GET_SERVICE_ERROR = "GET_SERVICE_ERROR"

export const GET_DOCS_TEMPLATES_START = "GET_DOCS_TEMPLATES_START"
export const GET_DOCS_TEMPLATES_SUCCESS = "GET_DOCS_TEMPLATES_SUCCESS"
export const GET_DOCS_TEMPLATES_ERROR = "GET_DOCS_TEMPLATES_ERROR"

export const UPDATE_DOCUMENT_TEMPLATE_START = "UPDATE_DOCUMENT_TEMPLATE_START"
export const UPDATE_DOCUMENT_TEMPLATE_SUCCESS = "UPDATE_DOCUMENT_TEMPLATE_SUCCESS"
export const UPDATE_DOCUMENT_TEMPLATE_ERROR = "UPDATE_DOCUMENT_TEMPLATE_ERROR"

export const GET_EMAIL_TEMPLATES_START = "GET_EMAIL_TEMPLATES_START"
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS"
export const GET_EMAIL_TEMPLATES_ERROR = "GET_EMAIL_TEMPLATES_ERROR"

export const UPDATE_EMAIL_TEMPLATE_START = "UPDATE_EMAIL_TEMPLATE_START"
export const UPDATE_EMAIL_TEMPLATE_SUCCESS = "UPDATE_EMAIL_TEMPLATE_SUCCESS"
export const UPDATE_EMAIL_TEMPLATE_ERROR = "UPDATE_EMAIL_TEMPLATE_ERROR"

export const GET_PAYMENT_METHODS_START = "GET_PAYMENT_METHODS_START"
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS"
export const GET_PAYMENT_METHODS_ERROR = "GET_PAYMENT_METHODS_ERROR"

export const CREATE_PAYMENT_METHOD_START = "CREATE_PAYMENT_METHOD_START"
export const CREATE_PAYMENT_METHOD_SUCCESS = "CREATE_PAYMENT_METHOD_SUCCESS"
export const CREATE_PAYMENT_METHOD_ERROR = "CREATE_PAYMENT_METHOD_ERROR"

export const UPDATE_PAYMENT_METHOD_START = "UPDATE_PAYMENT_METHOD_START"
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS"
export const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR"

export const DELETE_PAYMENT_METHOD_START = "DELETE_PAYMENT_METHOD_START"
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS"
export const DELETE_PAYMENT_METHOD_ERROR = "DELETE_PAYMENT_METHOD_ERROR"

export const GET_PLACE_START = "GET_PLACE_START"
export const GET_PLACE_SUCCESS = "GET_PLACE_SUCCESS"
export const GET_PLACE_ERROR = "GET_PLACE_ERROR"

export const GET_CHANNELS_START = "GET_CHANNELS_START"
export const GET_CHANNELS_SUCCESS = "GET_CHANNELS_SUCCESS"
export const GET_CHANNELS_ERROR = "GET_CHANNELS_ERROR"

export const DELETE_CHANNEL_START = "DELETE_CHANNEL_START"
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS"
export const DELETE_CHANNEL_ERROR = "DELETE_CHANNEL_ERROR"

export const GET_CHANNEL_START = "GET_CHANNEL_START"
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS"
export const GET_CHANNEL_ERROR = "GET_CHANNEL_ERROR"

export const GET_COMPANIES_START = "GET_COMPANIES_START"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_ERROR = "GET_COMPANIES_ERROR"

export const DELETE_COMPANY_START = "DELETE_COMPANY_START"
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS"
export const DELETE_COMPANY_ERROR = "DELETE_COMPANY_ERROR"

export const GET_COMPANY_START = "GET_COMPANY_START"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_ERROR = "GET_COMPANY_ERROR"

export const GET_ROLES_START = "GET_ROLES_START"
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS"
export const GET_ROLES_ERROR = "GET_ROLES_ERROR"

export const GET_ROLE_START = "GET_ROLE_START"
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS"
export const GET_ROLE_ERROR = "GET_ROLE_ERROR"

export const GET_PERMISSION_START = "GET_PERMISSION_START"
export const GET_PERMISSION_SUCCESS = "GET_PERMISSION_SUCCESS"
export const GET_PERMISSION_ERROR = "GET_PERMISSION_ERROR"

export const CREATE_ROLE_START = "CREATE_ROLE_START"
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS"
export const CREATE_ROLE_ERROR = "CREATE_ROLE_ERROR"

export const DELETE_ROLE_START = "DELETE_ROLE_START"
export const DELETE_ROLE_ERROR = "DELETE_ROLE_ERROR"

export const LOGIN_START = "LOGIN_START"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

export const SET_PASSWORD_START = "SET_PASSWORD_START"
export const SET_PASSWORD_SUCCESS = "SET_PASSWORD_SUCCESS"
export const SET_PASSWORD_ERROR = "SET_PASSWORD_ERROR"

export const GET_CLIENTS_START = "GET_CLIENTS_START"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_ERROR = "GET_CLIENTS_ERROR"

export const REMOVE_FAVORITE_SUCCESS = "REMOVE_FAVORITE_SUCCESS"
export const REMOVE_ALL_FAVORITES_SUCCESS = "REMOVE_ALL_FAVORITES_SUCCESS"

export const GET_CLIENT_BY_ID_START = "GET_CLIENT_BY_ID_START"
export const GET_CLIENT_BY_ID_SUCCESS = "GET_CLIENT_BY_ID_SUCCESS"
export const GET_CLIENT_BY_ID_ERROR = "GET_CLIENT_BY_ID_ERROR"

export const GET_STATES_START = "GET_STATES_START"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_ERROR = "GET_STATES_ERROR"

export const GET_CLIENT_TYPES_START = "GET_CLIENT_TYPES_START"
export const GET_CLIENT_TYPES_SUCCESS = "GET_CLIENT_TYPES_SUCCESS"
export const GET_CLIENT_TYPES_ERROR = "GET_CLIENT_TYPES_ERROR"

export const GET_CLIENT_TYPE_BY_ID_START = "GET_CLIENT_TYPE_BY_ID_START"
export const GET_CLIENT_TYPE_BY_ID_SUCCESS = "GET_CLIENT_TYPE_BY_ID_SUCCESS"
export const GET_CLIENT_TYPE_BY_ID_ERROR = "GET_CLIENT_TYPE_BY_ID_ERROR"

export const GET_CLIENT_GROUPS_START = "GET_CLIENT_GROUPS_START"
export const GET_CLIENT_GROUPS_SUCCESS = "GET_CLIENT_GROUPS_SUCCESS"
export const GET_CLIENT_GROUPS_ERROR = "GET_CLIENT_GROUPS_ERROR"

export const GET_CLIENT_GROUP_BY_ID_START = "GET_CLIENT_GROUP_BY_ID_START"
export const GET_CLIENT_GROUP_BY_ID_SUCCESS = "GET_CLIENT_GROUP_BY_ID_SUCCESS"
export const GET_CLIENT_GROUP_BY_ID_ERROR = "GET_CLIENT_GROUP_BY_ID_ERROR"

export const GET_COUNTRIES_START = "GET_COUNTRIES_START"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"
export const GET_COUNTRIES_ERROR = "GET_COUNTRIES_ERROR"

export const GET_PRODUCTS_START = "GET_PRODUCTS_START"
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR"

export const RESET_PRODUCTS = "RESET_PRODUCTS"

export const GET_PRODUCTS_SEARCH_AND_SORT_START = "GET_PRODUCTS_SEARCH_AND_SORT_START"
export const GET_PRODUCTS_SEARCH_AND_SORT_SUCCESS = "GET_PRODUCTS_SEARCH_AND_SORT_SUCCESS"
export const GET_PRODUCTS_SEARCH_AND_SORT_ERROR = "GET_PRODUCTS_SEARCH_AND_SORT_ERROR"

export const GET_PRODUCT_BY_ID_START = "GET_PRODUCT_BY_ID_START"
export const GET_PRODUCT_BY_ID_SUCCESS = "GET_PRODUCT_BY_ID_SUCCESS"
export const GET_PRODUCT_BY_ID_ERROR = "GET_PRODUCT_BY_ID_ERROR"
export const RESET_CURRENT_PRODUCT = "RESET_CURRENT_PRODUCT"

export const GET_PRODUCT_FOR_RELATED_BY_ID_START = "GET_PRODUCT_FOR_RELATED_BY_ID_START"
export const GET_PRODUCT_FOR_RELATED_BY_ID_SUCCESS = "GET_PRODUCT_FOR_RELATED_BY_ID_SUCCESS"
export const GET_PRODUCT_FOR_RELATED_BY_ID_ERROR = "GET_PRODUCT_FOR_RELATED_BY_ID_ERROR"

export const GET_PRODUCT_COMBINATIONS_START = "GET_PRODUCT_COMBINATIONS_START"
export const GET_PRODUCT_COMBINATIONS_SUCCESS = "GET_PRODUCT_COMBINATIONS_SUCCESS"
export const GET_PRODUCT_COMBINATIONS_ERROR = "GET_PRODUCT_COMBINATIONS_ERROR"

export const GET_PRODUCT_CONDITIONS_START = "GET_PRODUCT_CONDITIONS_START"
export const GET_PRODUCT_CONDITIONS_SUCCESS = "GET_PRODUCT_CONDITIONS_SUCCESS"
export const GET_PRODUCT_CONDITIONS_ERROR = "GET_PRODUCT_CONDITIONS_ERROR"

export const GET_PRODUCT_CONDITION_BY_ID_START = "GET_PRODUCT_CONDITION_BY_ID_START"
export const GET_PRODUCT_CONDITION_BY_ID_SUCCESS = "GET_PRODUCT_CONDITION_BY_ID_SUCCESS"
export const GET_PRODUCT_CONDITION_BY_ID_ERROR = "GET_PRODUCT_CONDITION_BY_ID_ERROR"

export const CREATE_FAST_REVIEW_START = "CREATE_FAST_REVIEW_START"
export const CREATE_FAST_REVIEW_SUCCESS = "CREATE_FAST_REVIEW_SUCCESS"
export const CREATE_FAST_REVIEW_ERROR = "CREATE_FAST_REVIEW_ERROR"

export const CLEAR_SPREADSHEET_ID = "CLEAR_SPREADSHEET_ID"

export const GET_CATEGORIES_START = "GET_CATEGORIES_START"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR"

export const GET_CATEGORY_BY_ID_START = "GET_CATEGORY_BY_ID_START"
export const GET_CATEGORY_BY_ID_SUCCESS = "GET_CATEGORY_BY_ID_SUCCESS"
export const GET_CATEGORY_BY_ID_ERROR = "GET_CATEGORY_BY_ID_ERROR"

export const GET_BRANDS_START = "GET_BRANDS_START"
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS"
export const GET_BRANDS_ERROR = "GET_BRANDS_ERROR"

export const GET_BRAND_BY_ID_START = "GET_BRAND_BY_ID_START"
export const GET_BRAND_BY_ID_SUCCESS = "GET_BRAND_BY_ID_SUCCESS"
export const GET_BRAND_BY_ID_ERROR = "GET_BRAND_BY_ID_ERROR"
export const RESET_CURRENT_BRAND = "RESET_CURRENT_BRAND"

export const GET_PRICE_STRUCTURES_START = "GET_PRICE_STRUCTURES_START"
export const GET_PRICE_STRUCTURES_SUCCESS = "GET_PRICE_STRUCTURES_SUCCESS"
export const GET_PRICE_STRUCTURES_ERROR = "GET_PRICE_STRUCTURES_ERROR"

export const GET_ATTRIBUTES_GROUPS_START = "GET_ATTRIBUTES_GROUPS_START"
export const GET_ATTRIBUTES_GROUPS_SUCCESS = "GET_ATTRIBUTES_GROUPS_SUCCESS"
export const GET_ATTRIBUTES_GROUPS_ERROR = "GET_ATTRIBUTES_GROUPS_ERROR"

export const GET_ATTRIBUTES_GROUP_BY_ID_START = "GET_ATTRIBUTES_GROUPS_BY_ID_START"
export const GET_ATTRIBUTES_GROUP_BY_ID_SUCCESS = "GET_ATTRIBUTES_GROUPS_BY_ID_SUCCESS"
export const GET_ATTRIBUTES_GROUP_BY_ID_ERROR = "GET_ATTRIBUTES_GROUPS_BY_ID_ERROR"

export const GET_ATTRIBUTES_START = "GET_ATTRIBUTES_START"
export const GET_ATTRIBUTES_SUCCESS = "GET_ATTRIBUTES_SUCCESS"
export const GET_ATTRIBUTES_ERROR = "GET_ATTRIBUTES_ERROR"

export const GET_ATTRIBUTE_BY_ID_START = "GET_ATTRIBUTE_BY_ID_START"
export const GET_ATTRIBUTE_BY_ID_SUCCESS = "GET_ATTRIBUTE_BY_ID_SUCCESS"
export const GET_ATTRIBUTE_BY_ID_ERROR = "GET_ATTRIBUTE_BY_ID_ERROR"

export const GET_ATTRIBUTE_VALUE_BY_ID_START = "GET_ATTRIBUTE_VALUE_BY_ID_START"
export const GET_ATTRIBUTE_VALUE_BY_ID_SUCCESS = "GET_ATTRIBUTE_VALUE_BY_ID_SUCCESS"
export const GET_ATTRIBUTE_VALUE_BY_ID_ERROR = "GET_ATTRIBUTE_VALUE_BY_ID_ERROR"

export const GET_CALENDARS_LIST_DATA_START = "GET_CALENDARS_LIST_DATA_START"
export const GET_CALENDARS_LIST_DATA_SUCCESS = "GET_CALENDARS_LIST_DATA_SUCCESS"
export const GET_CALENDARS_LIST_DATA_ERROR = "GET_CALENDARS_LIST_DATA_ERROR"

export const DELETE_CALENDAR_SUCCESS = "DELETE_CALENDAR_SUCCESS"

export const GET_CALENDARS_ENTRIES_DATA_START = "GET_CALENDARS_ENTRIES_DATA_START"
export const GET_CALENDARS_ENTRIES_DATA_SUCCESS = "GET_CALENDARS_ENTRIES_DATA_SUCCESS"
export const GET_CALENDARS_ENTRIES_DATA_ERROR = "GET_CALENDARS_ENTRIES_DATA_ERROR"

export const DELETE_CALENDAR_ENTRY_SUCCESS = "DELETE_CALENDAR_ENTRY_SUCCESS"

export const SELECT_CALENDAR_TYPE = "SELECT_CALENDAR_TYPE"
export const UNSELECT_CALENDAR_TYPE = "UNSELECT_CALENDAR_TYPE"

export const GET_USERS_LIST_DATA_START = "GET_USERS_LIST_DATA_START"
export const GET_USERS_LIST_DATA_SUCCESS = "GET_USERS_LIST_DATA_SUCCESS"
export const GET_USERS_LIST_DATA_ERROR = "GET_USERS_LIST_DATA_ERROR"

export const ADD_BRAND_FILTER = "ADD_BRAND_FILTER"
export const REMOVE_BRAND_FILTER = "REMOVE_BRAND_FILTER"

export const ADD_COLOR_FILTER = "ADD_COLOR_FILTER"
export const REMOVE_COLOR_FILTER = "REMOVE_COLOR_FILTER"

export const CHOSEN_CATEGORY_ROUTE = "CHOSEN_CATEGORY_ROUTE"
export const CHOSEN_CATEGORY_ROUTE_INIT = "CHOSEN_CATEGORY_ROUTE_INIT"

export const CHOSEN_CATEGORY = "CHOSEN_CATEGORY"
export const CHOSEN_LEAFS = "CHOSEN_LEAFS"
export const CLEAR_CHOSEN_LEAFS = "CLEAR_CHOSEN_LEAFS"
export const CHOSEN_LEAFS_INIT = "CHOSEN_LEAFS_INIT"

export const CLEAR_AVAILABLE_FILTERS = "CLEAR_AVAILABLE_FILTERS"
export const CLEAR_FILTERS = "CLEAR_FILTERS"
export const CLEAR_FILTER = "CLEAR_FILTER"

export const CLEAR_PRODUCT_PAGINATION = "CLEAR_PRODUCT_PAGINATION"
export const CHANGE_PRODUCT_PAGINATION = "CHANGE_PRODUCT_PAGINATION"

export const REPLACE_PRODUCT_FILTERS_GROUP = "REPLACE_PRODUCT_FILTERS_GROUP"
export const CLEAR_PRODUCT_FILTERS_GROUP = "CLEAR_PRODUCT_FILTERS_GROUP"

export const CLEAR_PRODUCT_DATERANGE = "CLEAR_PRODUCT_DATERANGE"
export const CHANGE_PRODUCT_DATERANGE = "CHANGE_PRODUCT_DATERANGE"

export const CHOSEN_CATEGORY_RESET = "CHOSEN_CATEGORY_RESET"
export const CHANGE_QUERY_FIELD = "CHANGE_QUERY_FIELD"
export const CHANGE_FILTER = "CHANGE_FILTER"

export const GET_COLORS_START = "GET_COLORS_START"
export const GET_COLORS_SUCCESS = "GET_COLORS_SUCCESS"
export const GET_COLORS_ERROR = "GET_COLORS_ERROR"

export const ADD_NOTE_START = "ADD_NOTE_START"
export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS"
export const ADD_NOTE_ERROR = "ADD_NOTE_ERROR"

export const EDIT_NOTE_START = "EDIT_NOTE_START"
export const EDIT_NOTE_SUCCESS = "EDIT_NOTE_SUCCESS"

export const DELETE_NOTE_START = "DELETE_NOTE_START"
export const DELETE_NOTE_ERROR = "DELETE_NOTE_ERROR"
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS"

export const DELETE_CLIENT_START = "DELETE_CLIENT_START"
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS"
export const DELETE_CLIENT_ERROR = "DELETE_CLIENT_ERROR"

export const GET_FEATURES_START = "GET_FEATURES_START"
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS"
export const GET_FEATURES_ERROR = "GET_FEATURES_ERROR"

export const GET_FEATURE_BY_ID_START = "GET_FEATURE_BY_ID_START"
export const GET_FEATURE_BY_ID_SUCCESS = "GET_FEATURE_BY_ID_SUCCESS"
export const GET_FEATURE_BY_ID_ERROR = "GET_FEATURE_BY_ID_ERROR"

export const GET_FEATURE_VALUE_BY_ID_START = "GET_FEATURE_VALUE_BY_ID_START"
export const GET_FEATURE_VALUE_BY_ID_SUCCESS = "GET_FEATURE_VALUE_BY_ID_SUCCESS"
export const GET_FEATURE_VALUE_BY_ID_ERROR = "GET_FEATURE_VALUE_BY_ID_ERROR"

export const GET_COMBINATION_TEMPLATES_START = "GET_COMBINATION_TEMPLATES_START"
export const GET_COMBINATION_TEMPLATES_SUCCESS = "GET_COMBINATION_TEMPLATES_SUCCESS"
export const GET_COMBINATION_TEMPLATES_ERROR = "GET_COMBINATION_TEMPLATES_ERROR"

export const GET_COMBINATION_FIND_START = "GET_COMBINATION_FIND_START"
export const GET_COMBINATION_FIND_SUCCESS = "GET_COMBINATION_FIND_SUCCESS"
export const GET_COMBINATION_FIND_ERROR = "GET_COMBINATION_FIND_ERROR"

export const GET_COMBINATION_BY_ID_START = "GET_COMBINATION_BY_ID_START"
export const GET_COMBINATION_BY_ID_SUCCESS = "GET_COMBINATION_BY_ID_SUCCESS"
export const GET_COMBINATION_BY_ID_ERROR = "GET_COMBINATION_BY_ID_ERROR"

export const TOGGLE_SHOW_DROPDOWN_HEADER = "TOGGLE_SHOW_DROPDOWN_HEADER"

export const SET_SORT_TYPE = "SET_SORT_TYPE"

export const SAVE_FILTERS_LIST_PROJECTS = "SAVE_FILTERS_LIST_PROJECTS"

export const GET_PROJECTS_START = "GET_PROJECTS_START"
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS"
export const GET_PROJECTS_ERROR = "GET_PROJECTS_ERROR"

export const GET_MORE_PROJECTS_START = "GET_MORE_PROJECTS_START"
export const GET_MORE_PROJECTS_SUCCESS = "GET_MORE_PROJECTS_SUCCESS"
export const GET_MORE_PROJECTS_ERROR = "GET_MORE_PROJECTS_ERROR"

export const GET_PRODUCTS_PROJECTS_START = "GET_PRODUCTS_PROJECTS_START"
export const GET_PRODUCTS_PROJECTS_SUCCESS = "GET_PRODUCTS_PROJECTS_SUCCESS"
export const GET_PRODUCTS_PROJECTS_ERROR = "GET_PRODUCTS_PROJECTS_ERROR"

export const GET_PROJECT_START = "GET_PROJECT_START"
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS"
export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR"

export const GET_POSTS_START = "GET_POSTS_START"
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS"
export const GET_POSTS_ERROR = "GET_POSTS_ERROR"

export const GET_ORDERS_START = "GET_ORDERS_START"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR"

export const CREATE_ORDER_START = "CREATE_ORDER_START"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR"

export const UPDATE_ORDER_START = "UPDATE_ORDER_START"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR"

export const SET_LAST_FETCHED_ORDER = "SET_LAST_FETCHED_ORDER"

export const GET_ORDER_START = "GET_ORDER_START"
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS"
export const GET_ORDER_ERROR = "GET_ORDER_ERROR"

export const DELETE_ORDER_START = "DELETE_ORDER_START"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_ERROR = "DELETE_ORDER_ERROR"

export const GET_INVOICES_START = "GET_INVOICES_START"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_ERROR = "GET_INVOICES_ERROR"

export const GET_INVOICE_START = "GET_INVOICE_START"
export const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS"
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR"

export const DELETE_INVOICE_START = "DELETE_INVOICE_START"
export const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS"
export const DELETE_INVOICE_ERROR = "DELETE_INVOICE_ERROR"

export const GET_PURCHASE_ORDERS_START = "GET_PURCHASE_ORDERS_START"
export const GET_PURCHASE_ORDERS_SUCCESS = "GET_PURCHASE_ORDERS_SUCCESS"
export const GET_PURCHASE_ORDERS_ERROR = "GET_PURCHASE_ORDERS_ERROR"

export const GET_PURCHASE_ORDER_START = "GET_PURCHASE_ORDER_START"
export const GET_PURCHASE_ORDER_SUCCESS = "GET_PURCHASE_ORDER_SUCCESS"
export const GET_PURCHASE_ORDER_ERROR = "GET_PURCHASE_ORDER_ERROR"

export const DELETE_PURCHASE_ORDER_START = "DELETE_PURCHASE_ORDER_START"
export const DELETE_PURCHASE_ORDER_SUCCESS = "DELETE_PURCHASE_ORDER_SUCCESS"
export const DELETE_PURCHASE_ORDER_ERROR = "DELETE_PURCHASE_ORDER_ERROR"

export const UPDATE_PURCHASE_ORDERS = "UPDATE_PURCHASE_ORDERS"

export const GET_ORDER_ITEMS_START = "GET_ORDER_ITEMS_START"
export const GET_ORDER_ITEMS_SUCCESS = "GET_ORDER_ITEMS_SUCCESS"
export const GET_ORDER_ITEMS_ERROR = "GET_ORDER_ITEMS_ERROR"

export const CREATE_ORDER_ITEMS_START = "CREATE_ORDER_ITEMS_START"
export const CREATE_ORDER_ITEMS_SUCCESS = "CREATE_ORDER_ITEMS_SUCCESS"
export const CREATE_ORDER_ITEMS_ERRORS = "CREATE_ORDER_ITEMS_ERRORS"

export const UPDATE_ORDER_ITEM_START = "UPDATE_ORDER_ITEM_START"
export const UPDATE_ORDER_ITEM_SUCCESS = "UPDATE_ORDER_ITEM_SUCCESS"
export const UPDATE_ORDER_ITEM_ERROR = "UPDATE_ORDER_ITEM_ERROR"

export const DELETE_ORDER_ITEM_START = "DELETE_ORDER_ITEM_START"
export const DELETE_ORDER_ITEM_SUCCESS = "DELETE_ORDER_ITEM_SUCCESS"
export const DELETE_ORDER_ITEM_ERROR = "DELETE_ORDER_ITEM_ERROR"

export const GET_PAYMENTS_START = "GET_PAYMENTS_START"
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS"
export const GET_PAYMENTS_ERROR = "GET_PAYMENTS_ERROR"

export const CREATE_PAYMENT_START = "CREATE_PAYMENT_START"
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS"
export const CREATE_PAYMENT_ERROR = "CREATE_PAYMENT_ERROR"

export const UPDATE_PAYMENT_START = "UPDATE_PAYMENT_START"
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS"
export const UPDATE_PAYMENT_ERROR = "UPDATE_PAYMENT_ERROR"

export const DELETE_PAYMENT_START = "DELETE_PAYMENT_START"
export const DELETE_PAYMENT_SUCCESS = "DELETE_PAYMENT_SUCCESS"
export const DELETE_PAYMENT_ERROR = "DELETE_PAYMENT_ERROR"

export const GET_POST_START = "GET_POST_START"
export const GET_POST_SUCCESS = "GET_POST_SUCCESS"
export const GET_POST_ERROR = "GET_POST_ERROR"

export const CLEAR_POST = "CLEAR_POST"

export const GET_BLOG_POST_START = "GET_BLOG_POST_START"
export const GET_BLOG_POST_SUCCESS = "GET_BLOG_POST_SUCCESS"
export const GET_BLOG_POST_ERROR = "GET_BLOG_POST_ERROR"
export const CLEAR_BLOG_POST = "CLEAR_BLOG_POST"

export const GET_PORTFOLIO_POST_START = "GET_PORTFOLIO_POST_START"
export const GET_PORTFOLIO_POST_SUCCESS = "GET_PORTFOLIO_POST_SUCCESS"
export const GET_PORTFOLIO_POST_ERROR = "GET_PORTFOLIO_POST_ERROR"
export const CLEAR_PORTFOLIO_POST = "CLEAR_PORTFOLIO_POST"

export const GET_PORTFOLIO_START = "GET_PORTFOLIO_START"
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS"
export const GET_PORTFOLIO_ERROR = "GET_PORTFOLIO_ERROR"

export const TAG_TYPES = {
    BLOG: "posts",
    NEWS: "news"
}

export const GET_TAGS_START = "GET_TAGS_START"
export const GET_TAGS_SUCCESS = "GET_TAGS_SUCCESS"
export const GET_TAGS_ERROR = "GET_TAGS_ERROR"

export const ADD_TAG_START = "ADD_TAG_START"
export const ADD_TAG_SUCCESS = "ADD_TAG_SUCCESS"
export const ADD_TAG_ERROR = "ADD_TAG_ERROR"

export const CREATE_PROJECT_START = "CREATE_PROJECT_START"
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS"
export const CREATE_PROJECT_ERROR = "CREATE_PROJECT_ERROR"

export const UPDATE_PROJECT_START = "UPDATE_PROJECT_START"
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS"
export const UPDATE_PROJECT_ERROR = "UPDATE_PROJECT_ERROR"

export const DELETE_PROJECT_START = "DELETE_PROJECT_START"
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS"
export const DELETE_PROJECT_ERROR = "DELETE_PROJECT_ERROR"

export const ADD_PRODUCT_TO_PROJECT_REQUEST = "ADD_PRODUCT_TO_PROJECT_REQUEST"
export const ADD_PRODUCT_TO_PROJECT_SUCCESS = "ADD_PRODUCT_TO_PROJECT_SUCCESS"
export const ADD_PRODUCT_TO_PROJECT_FAILURE = "ADD_PRODUCT_TO_PROJECT_FAILURE"

export const REMOVE_PRODUCT_FROM_PROJECT_START = "REMOVE_PRODUCT_FROM_PROJECT_START"
export const REMOVE_PRODUCT_FROM_PROJECT_SUCCESS = "REMOVE_PRODUCT_FROM_PROJECT_SUCCESS"
export const REMOVE_PRODUCT_FROM_PROJECT_ERROR = "REMOVE_PRODUCT_FROM_PROJECT_ERROR"

export const GET_PROJECT_ROOMS_REQUEST = "GET_PROJECT_ROOMS_REQUEST"
export const GET_PROJECT_ROOMS_SUCCESS = "GET_PROJECT_ROOMS_SUCCESS"
export const GET_PROJECT_ROOMS_FAILURE = "GET_PROJECT_ROOMS_FAILURE"

export const RESET_PROJECT = "RESET_PROJECT"
export const RESET_ROOMS_FETCH_STATUS = "RESET_ROOMS_FETCH_STATUS"

export const PROJECT_UPDATE_PRODUCT_REQUEST = "PROJECT_UPDATE_PRODUCT_REQUEST"
export const PROJECT_UPDATE_PRODUCT_SUCCESS = "PROJECT_UPDATE_PRODUCT_SUCCESS"
export const PROJECT_UPDATE_PRODUCT_FAILURE = "PROJECT_UPDATE_PRODUCT_FAILURE"

export const GET_COMMENTS_START = "GET_COMMENTS_START"
export const GET_COMMENTS_SUCCESS = "GET_COMMENTS_SUCCESS"
export const GET_COMMENTS_ERROR = "GET_COMMENTS_ERROR"

export const CREATE_COMMENT_START = "CREATE_COMMENT_START"
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS"
export const CREATE_COMMENT_ERROR = "CREATE_COMMENT_ERROR"

export const RESET_COMMENTS = "RESET_COMMENTS"

export const SHARE_PROJECT_START = "SHARE_PROJECT_START"
export const SHARE_PROJECT_SUCCESS = "SHARE_COMMENT_SUCCESS"
export const SHARE_PROJECT_ERROR = "SHARE_COMMENT_ERROR"

export const CREATE_ORDER_FROM_PROJECT_START = "CREATE_ORDER_FROM_PROJECT_START"
export const CREATE_ORDER_FROM_PROJECT_SUCCESS = "CREATE_ORDER_FROM_PROJECT_SUCCESS"
export const CREATE_ORDER_FROM_PROJECT_ERROR = "CREATE_ORDER_FROM_PROJECT_ERROR"

export const GET_FOLDERS_LIST_START = "GET_FOLDERS_LIST_START"
export const GET_FOLDERS_LIST_SUCCESS = "GET_FOLDERS_LIST_SUCCESS"
export const GET_FOLDERS_LIST_ERROR = "GET_FOLDERS_LIST_ERROR"

export const GET_CURRENT_FOLDER_IMAGES_START = "GET_CURRENT_FOLDER_IMAGES_START"
export const GET_CURRENT_FOLDER_IMAGES_SUCCESS = "GET_CURRENT_FOLDER_IMAGES_SUCCESS"
export const GET_CURRENT_FOLDER_IMAGES_ERROR = "GET_CURRENT_FOLDER_IMAGES_ERROR"

export const CHANGE_PRODUCT_IMAGES_COUNT = "CHANGE_PRODUCT_IMAGES_COUNT"

export const SET_CURRENT_FOLDER = "SET_CURRENT_FOLDER"
export const SET_IMAGE_DRAGGING_STATUS = "SET_IMAGE_DRAGGING_STATUS"
export const SELECT_IMAGES = "SELECT_IMAGES"
export const TRANSFER_FROM_PRODUCT = "TRANSFER_FROM_PRODUCT"
export const SAVE_TRANSFERED_IMAGES_LOCALLY = "SAVE_TRANSFERED_IMAGES_LOCALLY"

export const MENU = {
    DASHBOARD: "Dashboard",
    CALENDAR: "Calendar",
    NEWS: "News",
    ORDERS: "Orders",
    ORDERS_SALES_ORDERS: "Sales Orders",
    ORDERS_INVOICES: "Invoices",
    ORDERS_PURCHASE_ORDERS: "Purchase Orders",
    ORDERS_RETURNS: "Returns",
    ORDERS_MEMO: "Memo",
    ORDERS_RENTAL_AGREEMENTS: "Rental Agreements",
    ORDERS_PAYMENTS: "Payments",
    ORDERS_STORE_CREDITS: "Store Credits",
    ORDERS_SERVICES: "Services",
    CLIENTS: "Contact",
    CLIENTS_ALL: "All Contacts",
    CLIENTS_AWAITING: "Awaiting Approval",
    PRODUCTS: "Products",
    PRODUCTS_MANUAL_ENTRIES: "Manual Entries",
    PRODUCTS_SELECTIONS: "Selections",
    CONTENT: "Content",
    CONTENT_MAIN_PAGE: "Home Page",
    CONTENT_INSPIRATIONS: "Inspirations",
    CONTENT_NEWS: "News",
    CONTENT_TRADE: "Trade",
    CONTENT_PORTFOLIO: "Portfolio",
    CONTENT_NAVIGATION: "Menu Categories",
    CONTENT_PRESS: "Press",
    CONTENT_WEB_EDITOR: "Edit Web",
    SETTINGS: "Settings",
    SETTINGS_ORDERS: "Orders",
    SETTINGS_PRODUCTS: "Products",
    SETTINGS_USERS_MANAGEMENT: "User Management",
    SETTINGS_ROLES_MANAGEMENT: "Roles Management",
    SETTINGS_CHANNELS_MANAGEMENT: "Channels Management",
    SETTINGS_OTHER: "Other",
    PROJECTS: "Projects",
    UPLOAD: "Upload",
    APPS: "Apps",
    SUPPORT: "Support",
    LINE: "line",
    BUTTON: "button",
    SYSTEM_EVENTS: "System events",
    ONLINE_STORE: "Online-Store",
    MANAGE_BRANDS: "Manage Brands",
    WMS: "WMS",
    WMS_WAREHOUSES: "Warehouses",
    WMS_STOCK_MANAGEMENT: "Stock Management",
    WMS_STOCK_TRANSFERS: "Stock Transfers",
    WMS_STOCK_ADJUSTMENTS: "Stock Adjustments",
    WMS_RECEIVING: "Receiving",
    WMS_SHIPMENTS: "Shipments",
    WMS_LOGISTICS: "Logistics"
}

export const SALES_PERSON_ROLE_NAME = "Sales Person"

export const LENGTH_WITHOUT_PAGINATION = 9999
export const SHOWN_CLIENTS_COUNT = 20
export const ITEMS_PER_PAGE = 20

export const HIDE_SIDEBAR = "HIDE_SIDEBAR"
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"

export const INPUT_TYPES = {
    TEXT: "text",
    EMAIL: "email",
    DATE: "date",
    CHECKBOX: "checkbox",
    ARRAY: "array",
    MODEL_ARRAY: "model_array",
    HIDDEN: "hidden",
    PASSWORD: "password",
    TEXTAREA: "textarea",
    SUBMIT: "submit",
    SELECT: "select",
    RADIO: "radio"
}

export const ATTRIBUTE_TYPES = {
    DROPDOWN: "dropdown",
    RADIO: "radiobutton",
    COLOR: "color"
}

export const PERMISSIONS = {
    context: {
        USERS: "users",
        ROLES: "roles",
        OTHER: "other",
        CLIENTS: "clients",
        DASHBOARD: "full statistics",
        PRODUCTS: "products",
        UPLOAD: "upload content",
        PROJECTS: "projects",
        CONTENT: "web page",
        NEWS: "news",
        BLOG_POSTS: "blog posts",
        PORTFOLIO_POSTS: "portfolio posts",
        ORDER_PAYMENTS: "order payments",
        TMP: "tmp tab",
        WEB_EDITOR: "web editor",
        WMS: "warehouses",
        SHIPMENTS_AND_RECEIVINGS: "shipments and receivings",
        INCOMING_SHIPMENTS: "incoming shipments",
        ORDER_SHIPMENTS: "order shipments",
        PAYMENTS: "payments",
        STOCK_ITEMS: "stock items",
        INVOICES: "invoices"
    },
    types: {
        ACCESS: "access",
        LIST: "list",
        ADD: "add",
        VIEW: "view",
        EDIT: "edit",
        DELETE: "delete",
        RESTORE: "restore",
        SPREADSHEET_EDIT: "spreadsheet edit",
        STOCK_ADJUSTMENT_ON: "stock adjustment on",
        ASSIGN_USER_TO: "assign user to",
        SELECT_DEFAULT_FOR_PO: "select default for po",
        SELECT_PRIMARY: "select primary"
    }
}

export const ADDRESS_TYPES = {
    personal: "Main Address",
    delivery: "Shipping Address",
    invoice: "Billing Address"
}

export const DOCUMENT_AVAILABILITY_LEVELS = [
    { label: "Public", value: "all" },
    { label: "After login", value: "login" },
    { label: "Email", value: "contact_us" }
]

export const DEFAULT_CURRENCY = "$"
export const LOAD_THUMBNAIL_IMAGE = "?type=listing"
export const DRAGDROP_ACTION_TRANSFER_IMAGE_PRODUCT = "Image"

export const MINIMAL_SEARCH_WORD_LENGTH = 3

export const DEFAULT_SIGNATURE_KEY = "default_signature"

export const GET_HOME_PAGE_START = "GET_HOME_PAGE_START"
export const GET_HOME_PAGE_SUCCESS = "GET_HOME_PAGE_SUCCESS"
export const GET_HOME_PAGE_ERROR = "GET_HOME_PAGE_ERROR"

export const GET_MENU_CATEGORIES_START = "GET_MENU_CATEGORIES_START"
export const GET_MENU_CATEGORIES_SUCCESS = "GET_MENU_CATEGORIES_SUCCESS"
export const GET_MENU_CATEGORIES_ERROR = "GET_MENU_CATEGORIES_ERROR"

export const UPDATE_HOME_PAGE_START = "UPDATE_HOME_PAGE_START"
export const UPDATE_HOME_PAGE_SUCCESS = "UPDATE_HOME_PAGE_SUCCESS"
export const UPDATE_HOME_PAGE_ERROR = "UPDATE_HOME_PAGE_ERROR"

export const GET_HOME_PAGE_COLLECTION_START = "GET_HOME_PAGE_COLLECTION_START"
export const GET_HOME_PAGE_COLLECTION_SUCCESS = "GET_HOME_PAGE_COLLECTION_SUCCESS"
export const GET_HOME_PAGE_COLLECTION_ERROR = "GET_HOME_PAGE_COLLECTION_ERROR"

export const UPDATE_HOME_PAGE_COLLECTION_START = "UPDATE_HOME_PAGE_COLLECTION_START"
export const UPDATE_HOME_PAGE_COLLECTION_SUCCESS = "UPDATE_HOME_PAGE_COLLECTION_SUCCESS"
export const UPDATE_HOME_PAGE_COLLECTION_ERROR = "UPDATE_HOME_PAGE_COLLECTION_ERROR"

export const DELETE_HOME_PAGE_COLLECTION_START = "DELETE_HOME_PAGE_COLLECTION_START"
export const DELETE_HOME_PAGE_COLLECTION_SUCCESS = "DELETE_HOME_PAGE_COLLECTION_SUCCESS"
export const DELETE_HOME_PAGE_COLLECTION_ERROR = "DELETE_HOME_PAGE_COLLECTION_ERROR"

export const CREATE_HOME_PAGE_COLLECTION_START = "CREATE_HOME_PAGE_COLLECTION_START"
export const CREATE_HOME_PAGE_COLLECTION_SUCCESS = "CREATE_HOME_PAGE_COLLECTION_SUCCESS"
export const CREATE_HOME_PAGE_COLLECTION_ERROR = "CREATE_HOME_PAGE_COLLECTION_ERROR"

export const GET_HOME_PAGE_POST_START = "GET_HOME_PAGE_POST_START"
export const GET_HOME_PAGE_POST_SUCCESS = "GET_HOME_PAGE_POST_SUCCESS"
export const GET_HOME_PAGE_POST_ERROR = "GET_HOME_PAGE_POST_ERROR"

export const UPDATE_HOME_PAGE_POST_START = "UPDATE_HOME_PAGE_POST_START"
export const UPDATE_HOME_PAGE_POST_SUCCESS = "UPDATE_HOME_PAGE_POST_SUCCESS"
export const UPDATE_HOME_PAGE_POST_ERROR = "UPDATE_HOME_PAGE_POST_ERROR"

export const DELETE_HOME_PAGE_POST_START = "DELETE_HOME_PAGE_POST_START"
export const DELETE_HOME_PAGE_POST_SUCCESS = "DELETE_HOME_PAGE_POST_SUCCESS"
export const DELETE_HOME_PAGE_POST_ERROR = "DELETE_HOME_PAGE_POST_ERROR"

export const CREATE_HOME_PAGE_POST_START = "CREATE_HOME_PAGE_POST_START"
export const CREATE_HOME_PAGE_POST_SUCCESS = "CREATE_HOME_PAGE_POST_SUCCESS"
export const CREATE_HOME_PAGE_POST_ERROR = "CREATE_HOME_PAGE_POST_ERROR"

export const GET_HOME_PAGE_REALIZATION_START = "GET_HOME_PAGE_REALIZATION_START"
export const GET_HOME_PAGE_REALIZATION_SUCCESS = "GET_HOME_PAGE_REALIZATION_SUCCESS"
export const GET_HOME_PAGE_REALIZATION_ERROR = "GET_HOME_PAGE_REALIZATION_ERROR"

export const CREATE_HOME_PAGE_REALIZATION_START = "CREATE_HOME_PAGE_REALIZATION_START"
export const CREATE_HOME_PAGE_REALIZATION_SUCCESS = "CREATE_HOME_PAGE_REALIZATION_SUCCESS"
export const CREATE_HOME_PAGE_REALIZATION_ERROR = "CREATE_HOME_PAGE_REALIZATION_ERROR"

export const UPDATE_HOME_PAGE_REALIZATION_START = "UPDATE_HOME_PAGE_REALIZATION_START"
export const UPDATE_HOME_PAGE_REALIZATION_SUCCESS = "UPDATE_HOME_PAGE_REALIZATION_SUCCESS"
export const UPDATE_HOME_PAGE_REALIZATION_ERROR = "UPDATE_HOME_PAGE_REALIZATION_ERROR"

export const DELETE_HOME_PAGE_REALIZATION_START = "DELETE_HOME_PAGE_REALIZATION_START"
export const DELETE_HOME_PAGE_REALIZATION_SUCCESS = "DELETE_HOME_PAGE_REALIZATION_SUCCESS"
export const DELETE_HOME_PAGE_REALIZATION_ERROR = "DELETE_HOME_PAGE_REALIZATION_ERROR"

export const CREATE_HOME_PAGE_IMMEDIATE_DELIVERY_START = "CREATE_HOME_PAGE_IMMEDIATE_DELIVERYSTART"
export const CREATE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS = "CREATE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS"
export const CREATE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR = "CREATE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR"

export const DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_START = "DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_START"
export const DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS = "DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS"
export const DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR = "DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR"

export const GET_HOME_PAGE_IMMEDIATE_DELIVERY_START = "GET_HOME_PAGE_IMMEDIATE_DELIVERY_START"
export const GET_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS = "GET_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS"
export const GET_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR = "GET_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR"

export const UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_START = "UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_START"
export const UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS = "UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS"
export const UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR = "UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR"

export const CREATE_HOME_PAGE_OUR_FAVOURITES_START = "CREATE_HOME_PAGE_OUR_FAVOURITES_START"
export const CREATE_HOME_PAGE_OUR_FAVOURITES_SUCCESS = "CREATE_HOME_PAGE_OUR_FAVOURITES_SUCCESS"
export const CREATE_HOME_PAGE_OUR_FAVOURITES_ERROR = "CREATE_HOME_PAGE_OUR_FAVOURITES_ERROR"

export const GET_HOME_PAGE_OUR_FAVOURITE_START = "GET_HOME_PAGE_OUR_FAVOURITE_START"
export const GET_HOME_PAGE_OUR_FAVOURITE_SUCCESS = "GET_HOME_PAGE_OUR_FAVOURITE_SUCCESS"
export const GET_HOME_PAGE_OUR_FAVOURITE_ERROR = "GET_HOME_PAGE_OUR_FAVOURITE_ERROR"

export const UPDATE_HOME_PAGE_OUR_FAVOURITE_START = "UPDATE_HOME_PAGE_OUR_FAVOURITE_START"
export const UPDATE_HOME_PAGE_OUR_FAVOURITE_SUCCESS = "UPDATE_HOME_PAGE_OUR_FAVOURITE_SUCCESS"
export const UPDATE_HOME_PAGE_OUR_FAVOURITE_ERROR = "UPDATE_HOME_PAGE_OUR_FAVOURITE_ERROR"

export const DELETE_HOME_PAGE_OUR_FAVOURITE_START = "DELETE_HOME_PAGE_OUR_FAVOURITE_START"
export const DELETE_HOME_PAGE_OUR_FAVOURITE_SUCCESS = "DELETE_HOME_PAGE_OUR_FAVOURITE_SUCCESS"
export const DELETE_HOME_PAGE_OUR_FAVOURITE_ERROR = "DELETE_HOME_PAGE_OUR_FAVOURITE_ERROR"

export const CREATE_HOME_PAGE_HEADER_START = "CREATE_HOME_PAGE_HEADER_START"
export const CREATE_HOME_PAGE_HEADER_SUCCESS = "CREATE_HOME_PAGE_HEADER_SUCCESS"
export const CREATE_HOME_PAGE_HEADER_ERROR = "CREATE_HOME_PAGE_HEADER_ERROR"

export const UPDATE_HOME_PAGE_HEADER_START = "UPDATE_HOME_PAGE_HEADER_START"
export const UPDATE_HOME_PAGE_HEADER_SUCCESS = "UPDATE_HOME_PAGE_HEADER_SUCCESS"
export const UPDATE_HOME_PAGE_HEADER_ERROR = "UPDATE_HOME_PAGE_HEADER_ERROR"

export const DELETE_HOME_PAGE_HEADER_START = "DELETE_HOME_PAGE_HEADER_START"
export const DELETE_HOME_PAGE_HEADER_SUCCESS = "DELETE_HOME_PAGE_HEADER_SUCCESS"
export const DELETE_HOME_PAGE_HEADER_ERROR = "DELETE_HOME_PAGE_HEADER_ERROR"

export const GET_HOME_PAGE_HEADER_START = "GET_HOME_PAGE_HEADER_START"
export const GET_HOME_PAGE_HEADER_SUCCESS = "GET_HOME_PAGE_HEADER_SUCCESS"
export const GET_HOME_PAGE_HEADER_ERROR = "GET_HOME_PAGE_HEADER_ERROR"

export const SET_SEARCH_BAR = "SET_SEARCH_BAR"

export const DEFAULT_COUNTRY = "United States of America"
export const DEFAULT_CURRENCY_LABEL = "United States dollar - $"
export const MAX_CHARS_LENGTH_PROFILE_NOTE = 250
export const MAX_ITEMS_PER_PAGE_PAGINATION = 25
export const WAIT_LOAD_CLIENT_TIME = 750
export const WAIT_LOAD_LIST = 500
export const DEBOUNCE_DRAG_AND_DROP_REQUEST = 1500
export const READABLE_STANDARD_DATE = "MMM DD, YYYY"
export const API_DATE_FORMAT = "YYYY-MM-DD"
export const API_YEAR_FORMAT = "YYYY"
export const FILENAME_DATETIME_FORMAT = "YYYY-MM-DD-hh-mm-ss"

export const IMAGE_ACCEPTED_FORMATS = "image/jpeg,image/png,image/tiff"
export const DEFAULT_FILE_UPLOAD_ENPOINT = `${API.baseUrl}/media`

export const MULTISELECT_TOGGLE = "MULTISELECT_TOGGLE"
export const MULTISELECT_CLEAR = "MULTISELECT_CLEAR"
export const MULTISELECT_SET_STATE = "MULTISELECT_SET_STATE"

export const TEXTAREA_MAX_LENGTH = 9999
export const WYSIWYG_MAX_LENGTH = 5000
export const INPUT_MAX_LENGTH = 250

export const ADDRESS_REGEX = /^(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/

export const CHANGE_CATEGORIES = "CHANGE_CATEGORIES"
export const CLEAR_CATEGORIES = "CLEAR_CATEGORIES"

export const CLEAR_BRANDS = "CLEAR_BRANDS"
export const CHANGE_BRANDS = "CHANGE_BRANDS"

export const CLEAR_BRANDS_FOR_FILTERS = "CLEAR_BRANDS_FOR_FILTERS"
export const PUT_BRANDS_FOR_FILTERS = "PUT_BRANDS_FOR_FILTERS"

export const TOGGLE_BRANDS = "TOGGLE_BRANDS"

export const CLEAR_CATEGORIES_FOR_FILTERS = "CLEAR_CATEGORIES_FOR_FILTERS"
export const PUT_CATEGORIES_FOR_FILTERS = "PUT_CATEGORIES_FOR_FILTERS"

export const CHANGE_CONDITIONS = "CHANGE_CONDITIONS"
export const CLEAR_CONDITIONS = "CLEAR_CONDITIONS"

export const CHANGE_AVAILABILITY = "CHANGE_AVAILABILITY"
export const CLEAR_AVAILABILITY = "CLEAR_AVAILABILITY"

export const CHANGE_REORDERABLE = "CHANGE_REORDERABLE"
export const CLEAR_REORDERABLE = "CLEAR_REORDERABLE"

export const CHANGE_AVAILABLE_ON_WEB = "CHANGE_AVAILABLE_ON_WEB"
export const CLEAR_AVAILABLE_ON_WEB = "CLEAR_AVAILABLE_ON_WEB"

export const CHANGE_WITHOUT_PHOTOS = "CHANGE_WITHOUT_PHOTOS"
export const CLEAR_WITHOUT_PHOTOS = "CLEAR_WITHOUT_PHOTOS"

export const CHANGE_CLEARANCE = "CHANGE_CLEARANCE"
export const CLEAR_CLEARANCE = "CLEAR_CLEARANCE"

export const CHANGE_DELETED = "CHANGE_DELETED"
export const CLEAR_DELETED = "CLEAR_DELETED"

export const CHANGE_PRICERANGE = "CHANGE_PRICERANGE"
export const CLEAR_PRICERANGE = "CLEAR_PRICERANGE"

export const CHANGE_DISCOUNT = "CHANGE_DISCOUNT"
export const CLEAR_DISCOUNT = "CLEAR_DISCOUNT"

export const CHANGE_MANUFACTURINGTIME = "CHANGE_MANUFACTURINGTIME"
export const CLEAR_MANUFACTURINGTIME = "CLEAR_MANUFACTURINGTIME"

export const CHANGE_PRODUCTIONYEAR = "CHANGE_PRODUCTIONYEAR"
export const CLEAR_PRODUCTIONYEAR = "CLEAR_PRODUCTIONYEAR"

export const CHANGE_DELIVERINGTIME = "CHANGE_DELIVERINGTIME"
export const CLEAR_DELIVERINGTIME = "CLEAR_DELIVERINGTIME"

export const CHANGE_LOCATION = "CHANGE_LOCATION"
export const CLEAR_LOCATION = "CLEAR_LOCATION"

export const CHANGE_OTHER = "CHANGE_OTHER"
export const CLEAR_OTHER = "CLEAR_OTHER"

export const CHANGE_VERIFICATION = "CHANGE_VERIFICATION"
export const CLEAR_VERIFICATION = "CLEAR_VERIFICATION"

export const CLEAR_PRODUCT_ADDITIONAL_FILTERS_GROUP = "CLEAR_PRODUCT_ADDITIONAL_FILTERS_GROUP"
export const REPLACE_PRODUCT_ADDITIONAL_FILTERS_GROUP = "REPLACE_PRODUCT_ADDITIONAL_FILTERS_GROUP"

export const CLEAR_CONDITIONS_FOR_FILTERS = "CLEAR_CONDITIONS_FOR_FILTERS"
export const PUT_CONDITIONS_FOR_FILTERS = "PUT_CONDITIONS_FOR_FILTERS"

export const CLEAR_LOCATIONS_FOR_FILTERS = "CLEAR_LOCATIONS_FOR_FILTERS"
export const PUT_LOCATIONS_FOR_FILTERS = "PUT_LOCATIONS_FOR_FILTERS"

export const CLEAR_PRODUCT_AGGREGATES_GROUP = "CLEAR_PRODUCT_AGGREGATES_GROUP"
export const REPLACE_PRODUCT_AGGREGATES_GROUP = "REPLACE_PRODUCT_AGGREGATES_GROUP"

export const GET_ASSISTANCES_START = "GET_ASSISTANCES_START"
export const GET_ASSISTANCES_SUCCESS = "GET_ASSISTANCES_SUCCESS"
export const GET_ASSISTANCES_ERROR = "GET_ASSISTANCES_ERROR"
export const RESET_ASSISTANCES = "RESET_ASSISTANCES"

export const GET_ASSISTANCE_BY_ID_START = "GET_ASSISTANCE_BY_ID_START"
export const GET_ASSISTANCE_BY_ID_SUCCESS = "GET_ASSISTANCE_BY_ID_SUCCESS"
export const GET_ASSISTANCE_BY_ID_ERROR = "GET_ASSISTANCE_BY_ID_ERROR"
export const RESET_CURRENT_ASSISTANCE = "RESET_CURRENT_ASSISTANCE"

export const CLEAR_SELECTIONS_FOR_FILTERS = "CLEAR_SELECTIONS_FOR_FILTERS"
export const PUT_SELECTIONS_FOR_FILTERS = "PUT_SELECTIONS_FOR_FILTERS"

export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS"
export const CHANGE_SELECTIONS = "CHANGE_SELECTIONS"
export const TOGGLE_SELECTIONS = "TOGGLE_SELECTIONS"

export const MAX_AMOUNT_FILTER_INPUT_VALUE = 999999999

export const GET_SHIPPING_METHODS_SUCCESS = "GET_SHIPPING_METHODS_SUCCESS"
export const GET_SHIPPING_METHODS_ERROR = "GET_SHIPPING_METHODS_ERROR"
export const GET_SHIPPING_METHODS_START = "GET_SHIPPING_METHODS_START"

export const CREATE_SHIPPING_METHOD_START = "CREATE_SHIPPING_METHOD_START"
export const CREATE_SHIPPING_METHOD_SUCCESS = "CREATE_SHIPPING_METHOD_SUCCESS"
export const CREATE_SHIPPING_METHOD_ERROR = "CREATE_SHIPPING_METHOD_ERROR"

export const UPDATE_SHIPPING_METHOD_START = "UPDATE_SHIPPING_METHOD_START"
export const UPDATE_SHIPPING_METHOD_SUCCESS = "UPDATE_SHIPPING_METHOD_SUCCESS"
export const UPDATE_SHIPPING_METHOD_ERROR = "UPDATE_SHIPPING_METHOD_ERROR"

export const DELETE_SHIPPING_METHOD_START = "DELETE_SHIPPING_METHOD_START"
export const DELETE_SHIPPING_METHOD_SUCCESS = "DELETE_SHIPPING_METHOD_SUCCESS"
export const DELETE_SHIPPING_METHOD_ERROR = "DELETE_SHIPPING_METHOD_ERROR"

export const GET_PRESS_START = "GET_PRESS_START"
export const GET_PRESS_SUCCESS = "GET_PRESS_SUCCESS"
export const GET_PRESS_ERROR = "GET_PRESS_ERROR"

export const CREATE_PRESS_START = "CREATE_PRESS_START"
export const CREATE_PRESS_SUCCESS = "CREATE_PRESS_SUCCESS"
export const CREATE_PRESS_ERROR = "CREATE_PRESS_ERROR"

export const DELETE_PRESS_START = "DELETE_PRESS_START"
export const DELETE_PRESS_SUCCESS = "DELETE_PRESS_SUCCESS"
export const DELETE_PRESS_ERROR = "DELETE_PRESS_ERROR"

export const UPDATE_PRESS_START = "UPDATE_PRESS_START"
export const UPDATE_PRESS_SUCCESS = "UPDATE_PRESS_SUCCESS"
export const UPDATE_PRESS_ERROR = "UPDATE_PRESS_ERROR"

export const GET_PARTNERS_START = "GET_PARTNERS_START"
export const GET_PARTNERS_SUCCESS = "GET_PARTNERS_SUCCESS"
export const GET_PARTNERS_ERROR = "GET_PARTNERS_ERROR"

export const SALE_CHANNELS = {
    SHOWROOM: "showroom",
    WEB: "web"
}
