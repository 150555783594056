import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { useActions } from "hooks"
import { createPaymentMethod, updatePaymentMethod } from "actions/paymentMethods"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import closeIcon from "assets/close.svg"
import inputStyles from "./overrides/InputText.css"
import styles from "./AddEditPaymentMethod.module.css"
import withStyles from "HOC/withStyles"
import Button from "components/Reusable/Form/Button"
import Input from "components/Reusable/Form/Input"

const schema = Yup.object().shape({
    name: Yup.string().required("This field is required.")
})

const AddEditPaymentMethod = ({ cx, edit, handleClose, initialValues }) => {
    const actions = useActions({
        createPaymentMethod,
        showSuccessNotification,
        showErrorNotification,
        updatePaymentMethod
    })

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async (values, { setErrors }) => {
        const { name, id } = values

        const data = { name }

        const action = id ? () => actions.updatePaymentMethod(id, data) : () => actions.createPaymentMethod(data)

        setIsSubmitting(true)

        try {
            await action()

            actions.showSuccessNotification()
            handleClose()
        } catch (data) {
            if (data) {
                const { errors: dataErrors, message } = data
                const errors = dataErrors || (message ? { message } : null)

                errors && setErrors(errors)
            }
            actions.showErrorNotification()
            setIsSubmitting(false)
        }
    }

    return (
        <div className={cx("modal-background")}>
            <div className={cx("modal-cover")}>
                <img src={closeIcon} alt="close" className={cx("close-icon")} onClick={handleClose} />

                <h2 className={cx("modal-title")}>{edit ? "Edit" : "Add"} payment method</h2>

                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {({ errors, handleSubmit, setFieldValue, touched, values }) => {
                        return (
                            <form className={cx("form")} onSubmit={handleSubmit}>
                                <div className={cx("row")}>
                                    <Field name="name">
                                        {({ field }) => {
                                            return (
                                                <Input
                                                    customStyles={inputStyles}
                                                    error={touched.name && errors.name}
                                                    label="Payment method name"
                                                    isPlaceholder={true}
                                                    onChange={value => {
                                                        setFieldValue(field.name, value.event.target.value)
                                                    }}
                                                    value={values.name}
                                                />
                                            )
                                        }}
                                    </Field>
                                </div>
                                <div className={cx("buttons")}>
                                    <span className={cx("form-cancel")} onClick={handleClose}>
                                        Cancel
                                    </span>
                                    <Button
                                        type="submit"
                                        className={["first-button", "submit-button"]}
                                        label="Save"
                                        isLoading={isSubmitting}
                                    />
                                </div>
                            </form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

AddEditPaymentMethod.defaultProps = {
    edit: false,
    initialValues: {
        method: ""
    }
}

AddEditPaymentMethod.propTypes = {
    edit: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    initialValues: PropTypes.object
}

export default withStyles(AddEditPaymentMethod, styles)
