export const getUserManagementTabs = props => {
    const { canList, canEdit, checkPermission, PERMISSIONS } = props

    const tabs = [
        [
            canList(PERMISSIONS.context.USERS),
            {
                url: "/settings/users",
                label: "Users"
            }
        ],
        [
            canList(PERMISSIONS.context.ROLES),
            {
                url: "/settings/users/roles",
                label: "Roles"
            }
        ],
        [
            canEdit(PERMISSIONS.context.USERS) &&
                checkPermission(PERMISSIONS.types.ASSIGN_USER_TO, PERMISSIONS.context.WMS),
            {
                url: "/settings/users/warehouses",
                label: "Warehouses"
            }
        ],
        [
            canEdit(PERMISSIONS.context.USERS),
            {
                url: "/settings/users/signatures",
                label: "Signatures"
            }
        ]
    ]

    return tabs.reduce((acc, [hasPermission, tab]) => (hasPermission ? [...acc, tab] : acc), [])
}
