import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import moment from "moment"

import * as projectActions from "actions/projects"
import * as notificationActions from "actions/notification"
import * as clientActions from "actions/client"

import Form from "./Form"
import { INIT_VALUES, FIELDS_IN_TABS, TABS } from "./constants"

const FormContainer = props => {
    const { match, history, project, actions, activeSession, activeClient } = props
    const isFinancialsTab = match.params.sectionName === "financials"
    const projectId = match.params.projectId
    const isEdit = !!projectId

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [openModal, setOpenModal] = useState()
    const [isLoadingClient, setIsLoadingClient] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [projectData, setProjectData] = useState({})
    const [isUploading, setIsUploading] = useState(false)
    const [countErrors, setCountErrors] = useState({ [TABS.GENERAL]: 0, [TABS.FINANCIALS]: 0 })

    useEffect(() => {
        return () => {
            actions.resetCurrentProject()
        }
    }, [])

    useEffect(() => {
        setProjectData(
            Object.keys(project.data).reduce((agg, key) => {
                const value = project.data[key]

                if (key === "users") {
                    return {
                        ...agg,
                        leader: value.find(user => user.leader),
                        designTeam: value.filter(user => !user.leader)
                    }
                }
                return { ...agg, [key]: value }
            }, {})
        )
    }, [project])

    useEffect(() => {
        if (isEdit) {
            setIsLoading(true)
            actions.getProject(projectId).then(() => {
                setIsLoading(false)
            })
        }
    }, [])

    useEffect(() => {
        if (activeSession) {
            handleSelectClient(activeClient)
        }
    }, [activeSession])

    const goToProject = id => history.push(`/projects/${id}`)
    const handleSubmit = (values, formikActions) => {
        const body = Object.entries(values).reduce((agg, [key, value]) => {
            if (key === "deadline_at" || key === "installation_at" || key === "rental_starts_at") {
                return { ...agg, [key]: moment(value || new Date()).format("YYYY-MM-DD") }
            }

            if (key === "company" && value) {
                return { ...agg, company_id: value.id }
            }

            if (key === "client") {
                return { ...agg, client_id: value ? value.id : null }
            }

            if (key === "attachments" && value) {
                return { ...agg, attachments: value.map(({ id }) => id) }
            }

            return !value && ["number", "string"].every(type => typeof value !== type) ? agg : { ...agg, [key]: value }
        }, {})

        body.users = values.designTeam.filter(user => user.id).map(user => ({ id: user.id, leader: false }))
        values.leader && values.leader.id && body.users.push({ id: values.leader.id, leader: true })

        setIsSubmitting(true)

        const resultSavedForm = isEdit ? actions.updateProject(projectId, body) : actions.createProject(body)

        resultSavedForm
            .then(result => {
                goToProject(result.data.id)
            })
            .catch(errors => {
                const parsedErrors = transformApiErrors(errors.errors)
                const countErrors = Object.keys(parsedErrors).reduce(
                    (counter, fieldKey) => {
                        const tab = FIELDS_IN_TABS[fieldKey]
                        return { ...counter, [tab]: counter[tab] + 1 }
                    },
                    { [TABS.GENERAL]: 0, [TABS.FINANCIALS]: 0 }
                )

                setCountErrors(countErrors)
                setIsSubmitting(false)
                formikActions.setErrors(parsedErrors)
                actions.showErrorNotification()

                Object.keys(parsedErrors).length > 0 &&
                    history.push(isEdit ? `/projects/${projectId}/edit` : "/projects/add")
            })
    }

    const transformApiErrors = apiErrors => {
        if (!apiErrors) {
            return {}
        }
        return Object.keys(apiErrors).reduce((errors, key) => {
            return apiErrors[key] && apiErrors[key].length > 0 ? { ...errors, [key]: apiErrors[key][0] } : errors
        }, {})
    }

    const handleSelectClient = (value, formik) => {
        if (!formik) {
            return
        }

        if (!value) {
            return formik.setFieldValue("client", "")
        }

        setIsLoadingClient(true)

        actions
            .getClientById(value.id)
            .then(({ data }) => {
                const billing_address = data.addresses.find(address => address.type === "invoice")
                const client = {
                    id: data.id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    billing_address
                }

                formik.setFieldValue("billing_address.copy", !!billing_address)
                formik.setFieldValue("client", client)
            })
            .catch(() => actions.showErrorNotification())
            .finally(() => setIsLoadingClient(false))
    }

    const handleCancel = () => history.push("/projects")
    const handleUploadStart = () => setIsUploading(true)
    const handleUploadSuccess = (arrayHelper, files) => {
        files.forEach(({ id, title, file, path }) => {
            arrayHelper.push({ id, name: title, size: file.size, url: path })
        })
        setIsUploading(false)
    }

    const handleDeleteProject = () => {
        setIsDeleting(true)
        actions
            .deleteProject(projectId)
            .then(() => history.push("/projects"))
            .catch(() => {
                actions.showErrorNotification()
                setIsDeleting(false)
            })
    }

    return (
        <div>
            <Form
                initValues={isEdit ? { ...INIT_VALUES, ...projectData } : INIT_VALUES}
                isEdit={isEdit}
                isLoading={isLoading}
                isSubmitting={isSubmitting}
                isUploading={isUploading}
                isDeleting={isDeleting}
                isLoadingClient={isLoadingClient}
                isFinancialsTab={isFinancialsTab}
                openModal={openModal}
                setOpenModal={setOpenModal}
                countErrors={countErrors}
                project={project}
                handleSelectClient={handleSelectClient}
                handleDeleteProject={handleDeleteProject}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                handleUploadSuccess={handleUploadSuccess}
                handleUploadStart={handleUploadStart}
            />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        project: state.db.projects.current.current,
        activeSession: state.ui.session.activeSession,
        activeClient: state.ui.session.activeClient
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...projectActions,
                ...notificationActions,
                ...clientActions
            },
            dispatch
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormContainer)
