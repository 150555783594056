import React, { useCallback, Fragment, useState, useMemo } from "react"
import { useParams, useHistory, Link } from "react-router-dom"
import { FormikProvider } from "formik"
import get from "lodash/get"
import pick from "lodash/pick"

import { Button, H1, PreviewIcon, SnakeLoader } from "@butterfly-frontend/ui"
import { SaveBar, Tabs } from "ui"
import { combineFetchStatuses, successState } from "helpers/fetchStatus"
import {
    usePurchaseOrderDetails,
    usePurchaseOrderOrderCreate,
    usePurchaseOrderOrderEdit
} from "modules/WmsModule/hooks/api/usePurchaseOrder"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import { createLinkToPurchaseOrderList } from "modules/OrdersModule/helpers/createLinkToPurchaseOrder"
import PURCHASE_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/purchaseOrderStatusKeys"
import { useShippingMethodList } from "modules/WmsModule/hooks/api/useShippingMethod"
import GeneralInfoTab from "modules/OrdersModule/components/PurchaseOrderFormGeneralInfoTab"
import Sidebar from "modules/OrdersModule/components/PurchaseOrderFormSidebar/PurchaseOrderFormSidebar"
import { useDiscountList } from "modules/OrdersModule/components/SelectDiscountSection"
import { PageError } from "modules/OrdersModule/components"
import usePurchaseOrderForm from "modules/OrdersModule/hooks/usePurchaseOrderForm"
import useDefaultWarehouse from "modules/WmsModule/hooks/useDefaultWarehouse"
import { checkIfCanEditPurchaseOrder } from "modules/OrdersModule/helpers/purchaseOrderEdit"

import ItemsTab from "./ItemsTab"
import styles from "./StockPurchaseOrderFormPage.module.css"

const TABS_KEYS = { ITEMS: "items", GENERAL_INFO: "general-info" }

const TABS = {
    [TABS_KEYS.ITEMS]: { label: "Items", isSidebar: false },
    [TABS_KEYS.GENERAL_INFO]: { label: "General Info", isSidebar: true }
}

const StockPurchaseOrderFormPage = () => {
    const { push: routerPush } = useHistory()
    const { purchaseOrderId } = useParams()
    const isEdit = !!purchaseOrderId

    const [activeTabKey, setActiveTabKey] = useState(TABS_KEYS.ITEMS)
    const [editProductRowId, setEditProductRowId] = useState(null)

    const purchaseOrderCreate = usePurchaseOrderOrderCreate()
    const purchaseOrderEdit = usePurchaseOrderOrderEdit()
    const shippingMethodListResource = useShippingMethodList({ params: { length: 9999 } })
    const purchaseOrderDetailsResource = usePurchaseOrderDetails({
        id: purchaseOrderId,
        reactQueryProps: { enabled: !!purchaseOrderId }
    })
    const warehouseListResource = useWarehouseList()
    const { value: defaultWarehouseForPO } = useDefaultWarehouse()

    const isSubmitting = purchaseOrderEdit.fetchStatus.isLoading || purchaseOrderCreate.fetchStatus.isLoading

    const redirectToPurchaseOrderList = useCallback(() => routerPush(createLinkToPurchaseOrderList()), [routerPush])

    const initialItems = useMemo(() => {
        if (purchaseOrderDetailsResource.data) {
            return purchaseOrderDetailsResource.data.items
        }

        return []
    }, [purchaseOrderDetailsResource.data])

    const isConfirmed = useMemo(
        () =>
            !!purchaseOrderDetailsResource.data &&
            purchaseOrderDetailsResource.data.status === PURCHASE_ORDER_STATUS_KEYS.confirmed,
        [purchaseOrderDetailsResource.data]
    )

    const destinationPointList = useMemo(
        () => [
            ...(warehouseListResource.data
                ? warehouseListResource.data.map(warehouse => ({
                      label: warehouse.name,
                      id: warehouse.id,
                      value: warehouse
                  }))
                : [])
        ],
        [warehouseListResource.data]
    )

    const shippingMethodList = useMemo(() => {
        if (!shippingMethodListResource.data) {
            return []
        }

        return shippingMethodListResource.data.map(shippingMethod => ({
            id: shippingMethod.id,
            label: shippingMethod.name,
            value: shippingMethod
        }))
    }, [shippingMethodListResource.data])

    const combinedFetchStatus = combineFetchStatuses([
        purchaseOrderId ? purchaseOrderDetailsResource.fetchStatus : successState()
    ])

    const { formik } = usePurchaseOrderForm({
        orderId: null,
        purchaseOrderId,
        isEdit,
        isConfirmed,
        initialItems,
        defaultWarehouseForPO,
        purchaseOrderDetailsResource,
        purchaseOrderEdit,
        purchaseOrderCreate,
        successSubmitAction: redirectToPurchaseOrderList
    })

    const { mappedDiscountList: discountBrandList } = useDiscountList({
        currentDiscountResource: get(purchaseOrderDetailsResource.data, "discount"),
        discountList: get(formik.values.brand, "discounts", [])
    })

    const handleChangeDestinationPoint = useCallback(
        ({ value }) => {
            formik.setFieldValue("warehouse", value)
        },
        [formik.setFieldValue]
    )

    if (!combinedFetchStatus.isLoaded) {
        return <SnakeLoader />
    }

    if (purchaseOrderDetailsResource.fetchStatus.isError) {
        return <PageError>Unknown error</PageError>
    }

    if (
        isEdit &&
        !checkIfCanEditPurchaseOrder(
            pick(purchaseOrderDetailsResource.data, ["is_any_item_received", "warehouse", "type"])
        )
    ) {
        return <PageError>This purchase order has been already delivered.</PageError>
    }

    return (
        <FormikProvider value={formik}>
            <div className={styles.root}>
                <div className={styles.header}>
                    <H1 withLeftBorder>
                        {isEdit ? "Edit" : "New"} Stock Purchase Order{isEdit && ":"}
                    </H1>
                    {isEdit && (
                        <Fragment>
                            <span className={styles.uuidOfPO}>{purchaseOrderDetailsResource.data.uuid}</span>
                            <Button
                                Icon={PreviewIcon}
                                variant="flat"
                                type="link"
                                LinkComponent={Link}
                                propsLinkComponent={{ to: createLinkToPurchaseOrderList() }}
                                classes={{ button: styles.previewButton }}
                            >
                                View PO
                            </Button>
                        </Fragment>
                    )}
                </div>
                <div className={styles.container}>
                    <div className={styles.leftSide}>
                        <Tabs tabs={TABS} activeTabKey={activeTabKey} onTabChange={setActiveTabKey} />
                        <div className={styles.tabContainer}>
                            {activeTabKey === TABS_KEYS.GENERAL_INFO && (
                                <GeneralInfoTab
                                    isSubmitting={isSubmitting}
                                    isConfirmed={isConfirmed}
                                    destinationPointList={destinationPointList}
                                    handleChangeDestinationPoint={handleChangeDestinationPoint}
                                />
                            )}
                            {activeTabKey === TABS_KEYS.ITEMS && (
                                <ItemsTab
                                    purchaseOrderId={purchaseOrderId}
                                    discountBrandList={discountBrandList}
                                    isEdit={isEdit}
                                    isSubmitting={combinedFetchStatus.isLoading}
                                    isConfirmed={isConfirmed}
                                    editProductRowId={editProductRowId}
                                    setEditProductRowId={setEditProductRowId}
                                />
                            )}
                        </div>
                    </div>
                    {TABS[activeTabKey].isSidebar && (
                        <div className={styles.rightSide}>
                            <Sidebar
                                isSubmitting={isSubmitting}
                                isEdit={isEdit}
                                brand={formik.values.brand}
                                isConfirmed={isConfirmed}
                                shippingMethodList={shippingMethodList}
                            />
                        </div>
                    )}
                </div>
            </div>
            <SaveBar
                isShown
                onCancel={redirectToPurchaseOrderList}
                onSubmit={formik.submitForm}
                isDisabled={!formik.dirty || !formik.isValid || !!editProductRowId}
                isSaving={isSubmitting}
                isDisabledCancel={isSubmitting}
                submitLabel="Save"
            />
        </FormikProvider>
    )
}

export default StockPurchaseOrderFormPage
