import * as constants from "constants/index"

function list(state = [], action) {
    switch (action.type) {
        case constants.GET_FOLDERS_LIST_START:
            return { ...state, isLoading: true, data: [] }
        case constants.GET_FOLDERS_LIST_SUCCESS:
            return { ...state, isLoading: false, data: [...action.payload.data.data] }
        case constants.GET_FOLDERS_LIST_ERROR:
            return { ...state, isLoading: false, data: [] }

        default:
            return state
    }
}

export default list
