import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import withShowScrollBar from "HOC/withShowScrollBar"
import styles from "./styles.module.css"
import inputStyles from "./overrides/input.module.css"
import close from "assets/close.svg"
import Button from "ui/Button"
import Input from "components/Reusable/Form/Input"
import Modal from "components/Modal/Modal"

class CreateFolderModal extends Component {
    state = {
        processing: false
    }
    render() {
        const { cx, handleHide, handleAddFolder, handleChange, newFolderName, handleShowScrollBar } = this.props

        return (
            <Modal>
                <div className={cx("modal-background")}>
                    <div className={cx("modal-cover")}>
                        <img
                            src={close}
                            alt="close"
                            className={cx("close-icon")}
                            onClick={() => {
                                handleShowScrollBar()
                                handleHide()
                                document.removeEventListener("keydown", this.handleKeyboardArrows, false)
                            }}
                        />
                        <p className={cx("title")}>Create new catalog</p>
                        <Input
                            value={newFolderName}
                            isInline={true}
                            customStyles={inputStyles}
                            label="Catalog name"
                            onChange={({ event }) => handleChange(event.target.value)}
                        />
                        <div className={cx("footer")}>
                            <Button
                                className={["first-button"]}
                                label="Create"
                                isLoading={this.state.processing}
                                onClick={() => {
                                    this.setState({
                                        processing: true
                                    })
                                    handleAddFolder()
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withShowScrollBar(withStyles(CreateFolderModal, styles))
