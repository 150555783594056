import React, { useState, useEffect } from "react"

import withStyles from "HOC/withStyles"
import styles from "./WysiwygEditor.css"
import ReactQuill, { Quill } from "react-quill"
import ImageResize from "quill-image-resize-module-react"

Quill.register("modules/imageResize", ImageResize)
const Parchment = Quill.import("parchment")

const ATTRIBUTES = ["alt", "height", "width", "style", "class"]
class Image extends Parchment.Embed {
    static create(value) {
        let node = super.create(value)

        if (typeof value === "string") {
            node.setAttribute("src", this.sanitize(value))
        }

        if (value && typeof value.src === "string") {
            node.setAttribute("src", this.sanitize(value.src))
        }

        if (value && typeof value.width === "string") {
            node.setAttribute("width", value.width)
        }

        if (value && typeof value.style === "string") {
            node.setAttribute("style", value.style)
        }
        return node
    }

    static formats(domNode) {
        return ATTRIBUTES.reduce(function(formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
                formats[attribute] = domNode.getAttribute(attribute)
            }
            return formats
        }, {})
    }

    static match(url) {
        return /\.(jpe?g|gif|png)$/.test(url) || /^data:image\/.+;base64/.test(url)
    }

    static isSanitized(url, protocols) {
        let anchor = document.createElement("a")
        anchor.href = url
        let protocol = anchor.href.slice(0, anchor.href.indexOf(":"))
        return protocols.indexOf(protocol) > -1
    }

    static sanitize(url) {
        return this.isSanitized(url, ["http", "https", "data"]) ? url : "//:0"
    }

    static value(domNode) {
        return {
            src: domNode.getAttribute("src"),
            width: domNode.getAttribute("width"),
            style: domNode.getAttribute("style")
        }
    }

    format(name, value) {
        if (ATTRIBUTES.indexOf(name) > -1) {
            if (value) {
                this.domNode.setAttribute(name, value)
            } else {
                this.domNode.removeAttribute(name)
            }
        } else {
            super.format(name, value)
        }
    }
}
Image.blotName = "image"
Image.tagName = "IMG"

Parchment.register(Image)

const WysiwygEditor = props => {
    const { cx, value, label, onChange, customModules, customFormats, error, bounds } = props
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        if (isError) {
            setIsError(false)
        }
    }, [value])

    useEffect(() => {
        if (error) {
            setIsError(true)
        }
    }, [error])

    let Width = new Parchment.Attributor.Attribute("width", "width")
    Parchment.register(Width)

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ align: [] }],
            ["link", "image"],
            [{ color: ["#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2"] }],
            ["clean"]
        ],
        imageResize: {
            parchment: Parchment
        }
    }

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "direction",
        "align",
        "link",
        "image",
        "color"
    ]

    const renderReactQuill = () => {
        const nextValue = value.replace(/\\/g, "")
        const extraProps = bounds ? { bounds } : {}

        if (!nextValue) {
            return (
                <ReactQuill
                    theme="snow"
                    className={cx("editor")}
                    defaultValue={nextValue}
                    onChange={onChange}
                    modules={customModules || modules}
                    formats={customFormats || formats}
                    {...extraProps}
                />
            )
        }

        return (
            <ReactQuill
                theme="snow"
                className={cx("editor")}
                value={value}
                onChange={onChange}
                modules={customModules || modules}
                formats={customFormats || formats}
                {...extraProps}
            />
        )
    }

    return (
        <div className={cx("root", { isError })}>
            {label && <p className={cx("label")}>{label}</p>}
            {renderReactQuill()}
            {isError && <div className={cx("error")}>{error}</div>}
        </div>
    )
}

export default withStyles(WysiwygEditor, styles)
