import * as constants from "constants/index"
import fetch from "helpers/fetch"

const getClientGroupsStart = () => ({ type: constants.GET_CLIENT_GROUPS_START })

const getClientGroupsSuccess = data => ({
    type: constants.GET_CLIENT_GROUPS_SUCCESS,
    payload: data
})

const getClientGroupsError = error => ({
    type: constants.GET_CLIENT_GROUPS_ERROR,
    payload: { error }
})

export const getClientGroups = () => dispatch => {
    dispatch(getClientGroupsStart())
    fetch
        .get("/client-groups?length=9999")
        .then(data => dispatch(getClientGroupsSuccess(data)))
        .catch(error => dispatch(getClientGroupsError(error)))
}

export const getClientGroupByIdStart = () => ({ type: constants.GET_CLIENT_GROUP_BY_ID_START })

export const getClientGroupByIdSuccess = data => ({
    type: constants.GET_CLIENT_GROUP_BY_ID_SUCCESS,
    payload: { data }
})

export const getClientGroupByIdError = error => ({
    type: constants.GET_CLIENT_GROUP_BY_ID_ERROR,
    payload: { error }
})

export const getClientGroupById = id => dispatch => {
    dispatch(getClientGroupByIdStart())
    fetch
        .get(`/client-groups/${id}`)
        .then(data => dispatch(getClientGroupByIdSuccess(data)))
        .catch(error => dispatch(getClientGroupByIdError(error)))
}
