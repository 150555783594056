import React, { useMemo, useState, Fragment } from "react"
import { Redirect, useParams } from "react-router-dom"
import { useQueryClient } from "react-query"
import _get from "lodash/get"
import _first from "lodash/first"
import { ConfirmModal, EmptyInternalList, AlertErrorIcon } from "@butterfly-frontend/ui"

import { useActions } from "hooks"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import Layout from "components/Reusable/Layout"
import EditableList from "components/Reusable/EditableList"
import config from "components/Content/ContentMain/Settings/RolesCover/DisplayUsersInRole/config"
import { useUserList } from "modules/WmsModule/hooks/api/useUser"
import { useUserWarehousesEdit, KEY as USERS_KEY } from "modules/WmsModule/hooks/api/useUser"
import { useCallback } from "react"

const RETURN_LINK = {
    label: "Warehouses",
    link: `/settings/users/warehouses`
}

const getWarehouseById = (warehouses = [], warehouseId) => warehouses.find(({ id }) => id === warehouseId)

const UsersAssignedToWarehouse = () => {
    const { warehouseId } = useParams()
    const [currentPage, setCurrentPage] = useState(1)
    const [userToUnassign, setUserToUnassign] = useState(null)

    const actions = useActions({ showErrorNotification, showSuccessNotification })
    const queryClient = useQueryClient()

    const { data: users, fetchStatus: queryFetchStatus, meta } = useUserList({
        params: { warehouses: [warehouseId], page: currentPage }
    })

    const warehouseName = useMemo(() => {
        const firstUser = _first(users)
        const firstUserWarehouses = _get(firstUser, "warehouses", [])
        const currentWarehouse = getWarehouseById(firstUserWarehouses, Number(warehouseId))

        return _get(currentWarehouse, "name")
    }, [users, warehouseId])

    const { mutate, fetchStatus: mutationFetchStatus } = useUserWarehousesEdit({
        reactQueryProps: {
            mutationKey: USERS_KEY,
            onError: actions.showErrorNotification,
            onSuccess: () => {
                actions.showSuccessNotification({ title: `Unassigned from: ${warehouseName}` })
                queryClient.invalidateQueries("warehouses", { refetchInactive: true })
                setUserToUnassign(null)
            }
        }
    })

    const unassignUser = useCallback(
        user =>
            mutate({
                id: user.id,
                warehouseIds: user.warehouses.reduce(
                    (warehouses, warehouse) =>
                        warehouse.id === Number(warehouseId) ? warehouses : [...warehouses, warehouse.id],
                    []
                )
            }),
        [warehouseId, mutate]
    )

    if (!Number.isInteger(Number(warehouseId))) {
        return <Redirect to={RETURN_LINK.link} />
    }

    return (
        <Fragment>
            <Layout returnLink={RETURN_LINK}>
                {queryFetchStatus.isError ? (
                    <EmptyInternalList text="Unknown error" Icon={AlertErrorIcon} />
                ) : (
                    <EditableList
                        listTitle={warehouseName ? `Users assigned to ${warehouseName}` : ""}
                        isLoading={queryFetchStatus.isLoading && !queryFetchStatus.isLoaded}
                        config={config}
                        list={users}
                        currentPage={meta.current_page - 1}
                        pagesCount={meta.last_page}
                        perPage={meta.per_page}
                        handleSelectPage={page => setCurrentPage(page + 1)}
                        isDisabledLocalPagination
                        handleToggleModal={user => setUserToUnassign(user)}
                        isNoEdit
                    />
                )}
            </Layout>
            {userToUnassign && (
                <ConfirmModal
                    onCancel={() => setUserToUnassign(null)}
                    onAction={() => unassignUser(userToUnassign)}
                    isLoading={mutationFetchStatus.isLoading}
                    title="Unassign user"
                    buttonLabel="Unassign"
                    buttonColor="red"
                >
                    <div>
                        Are you sure you want to unassign
                        <b>{` ${userToUnassign.first_name} ${userToUnassign.last_name} `}</b>
                        {"from "}
                        <b>{warehouseName}</b>?
                    </div>
                </ConfirmModal>
            )}
        </Fragment>
    )
}

export default UsersAssignedToWarehouse
