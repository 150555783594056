import React, { useState } from "react"
import SaveBar from "ui/SaveBar"
import ListingLayout from "../MainPageListingLayout"
import Header from "../../components/Header"
import Listing from "../../components/MainPageListings"
import Modal from "components/Modal/Modal"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"

import SectionNameEditModal from "../../components/MainPageNameSectionEditModal"
import AddCollectionModal from "../../components/MainPageAddCollectionModal"
import EditCollectionModal from "../../components/MainPageEditCollectionModal"

const MainPageCollections = props => {
    const {
        mainPages: { data, isLoading },
        modifiedData,
        isModified,
        handleUpdate,
        handleSubmit,
        handleClearChanges,
        isSaving,
        getCollection,
        collection,
        handleCollectionUpdate,
        handleCollectionCreate,
        showErrorNotification,
        handleDeleteAllFromList
    } = props

    const [sectionNameEditModalState, setSectionNameEditModalState] = useState(false)
    const [addCollectionModalState, setAddCollectionModalState] = useState(false)
    const [editCollectionModalState, setEditCollectionModalState] = useState(false)
    const [editingCollection, setEditingCollection] = useState(null)
    const [isDeleteCollectionOpen, setIsDeleteCollectionOpen] = useState(null)
    const [isDeleteAllCollectionsOpen, setIsDeleteAllCollectionsOpen] = useState(false)
    const [deletingCollection, setDeletingCollection] = useState(null)

    const listMapping = {
        id: "id",
        source_id: "collection_id",
        name: "title",
        image: "cover_desktop",
        active: "active"
    }

    const displayTitle = () =>
        (data.settings &&
            data.settings.sections &&
            data.settings.sections.collection &&
            data.settings.sections.collection.label) ||
        "Collections"

    const handleToggle = itemId => {
        const list = isModified ? modifiedData : data
        handleUpdate({
            ...list,
            collections: list.collections.map(item => ({
                ...item,
                active: item.id === itemId ? !item.active : false
            }))
        })
    }

    const handleReorder = collections => {
        handleUpdate({ ...data, collections })
    }

    const handleSectionNameEditClose = () => {
        setSectionNameEditModalState(false)
    }

    const handleSectionNameEditOpen = () => {
        setSectionNameEditModalState(true)
    }

    const handleSectionNameEdit = data => {
        handleSubmit(data, handleSectionNameEditClose)
    }

    const handleAddCollectionClose = () => {
        setAddCollectionModalState(false)
    }

    const handleAddCollectionOpen = () => {
        setAddCollectionModalState(true)
    }

    const handleAddCollection = data => {
        handleCollectionCreate(data, handleAddCollectionClose)
    }

    const handleEditCollectionClose = () => {
        setEditingCollection(null)
        setEditCollectionModalState(false)
    }

    const handleEditCollectionOpen = collectionId => {
        setEditingCollection(collectionId)
        setEditCollectionModalState(true)
    }

    const handleEditCollection = (id, data, onSuccess = null, onError) => {
        handleCollectionUpdate(id, data, handleEditCollectionClose, onError)
    }

    const handleDeleteCollectionClose = () => {
        setIsDeleteCollectionOpen(false)
        setDeletingCollection(null)
    }

    const handleDeleteCollectionOpen = collection => {
        setIsDeleteCollectionOpen(true)
        setDeletingCollection(collection)
    }

    const handleDeleteCollection = () => {
        const { handleCollectionDelete } = props
        handleCollectionDelete(deletingCollection.id, handleDeleteCollectionClose)
    }

    const handleDeleteAllCollectionsClose = () => {
        setIsDeleteAllCollectionsOpen(false)
    }

    const handleDeleteAllCollectionsOpen = () => {
        setIsDeleteAllCollectionsOpen(true)
    }

    const handleDeleteAllCollections = () => {
        handleDeleteAllFromList(
            { settings: data.settings, header: data.header, collections: [] },
            handleDeleteAllCollectionsClose
        )
    }

    return (
        <ListingLayout>
            <Header
                title={displayTitle()}
                editButton={{
                    label: "Edit",
                    handler: handleSectionNameEditOpen,
                    isDisabled: false,
                    order: 1
                }}
                addButton={{
                    label: "Add Collection",
                    handler: handleAddCollectionOpen,
                    isDisabled: false,
                    order: 2
                }}
            />

            <Listing
                isLoading={isLoading}
                handleDelete={handleDeleteCollectionOpen}
                handleDeleteAll={handleDeleteAllCollectionsOpen}
                handleEdit={id => handleEditCollectionOpen(id)}
                handleToggle={handleToggle}
                listMapping={listMapping}
                listingType="collections"
                handleReorder={handleReorder}
                list={modifiedData ? modifiedData.collections : data.collections || []}
                customToggleLabel={{
                    on: "active",
                    off: "inactive"
                }}
            />

            <SectionNameEditModal
                isSaving={isSaving}
                data={modifiedData || data}
                sectionName="collection"
                defaultValues={{ label: "Collections" }}
                handleClose={handleSectionNameEditClose}
                onSubmit={handleSectionNameEdit}
                isOpen={sectionNameEditModalState}
            />

            <AddCollectionModal
                data={modifiedData || data}
                handleClose={handleAddCollectionClose}
                onSubmit={handleAddCollection}
                isOpen={addCollectionModalState}
            />

            <EditCollectionModal
                data={collection}
                collectionId={editingCollection}
                getCollection={getCollection}
                handleClose={handleEditCollectionClose}
                handleError={showErrorNotification}
                onSubmit={handleEditCollection}
                isOpen={editCollectionModalState}
            />

            {isDeleteCollectionOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete this collection?"
                        description="Are you sure you want to delete the collection"
                        subject={deletingCollection.title}
                        descriptionClosure="? This action cannot be reversed."
                        handleHideModal={handleDeleteCollectionClose}
                        handleDelete={handleDeleteCollection}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            {isDeleteAllCollectionsOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete all collections?"
                        description="Are you sure you want to delete all of the collections?"
                        handleHideModal={handleDeleteAllCollectionsClose}
                        handleDelete={handleDeleteAllCollections}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            <SaveBar
                message="Unpublished changes"
                submitLabel="Publish"
                isShown={isModified}
                isSaving={isSaving}
                onSubmit={handleSubmit}
                onCancel={handleClearChanges}
            />
        </ListingLayout>
    )
}

export default MainPageCollections
