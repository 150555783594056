import React from "react"
import { Button, Modal } from "ui"
import { formatPrice } from "helpers/units"
import withStyles from "HOC/withStyles"
import styles from "./DeletePayment.module.css"

const { Actions, Body, Header } = Modal

const DeletePayment = ({ amount, cx, handleClose, handleSubmit, isSubmitting }) => {
    return (
        <Modal closeModal={handleClose} isOpen={true} preventClickOutside={true}>
            <Header>Delete this payment?</Header>
            <Body>
                <div className={cx("content")}>
                    <p>
                        Are you sure you want to delete the payment{" "}
                        <span className={cx("amount")}>{formatPrice(amount)}</span>? This action cannot be reversed.
                    </p>
                </div>
            </Body>
            <Actions>
                <button className={cx("closeButton")} onClick={handleClose}>
                    Cancel
                </button>
                <Button
                    className="warning-button"
                    label="Delete"
                    isLoading={isSubmitting}
                    onClick={handleSubmit}
                    type="submit"
                />
            </Actions>
        </Modal>
    )
}

export default withStyles(DeletePayment, styles)
