import React, { Component } from "react"
import SelectFromList from "./SelectFromList"

class SelectFromListContainer extends Component {
    debounce = null

    state = {
        isLoading: false,
        isLoaded: false,
        isLoadingMore: false,
        selectedItems: [],
        displayedItems: [],
        query: "",
        meta: {
            total: 0,
            pages: 0,
            current_page: 0
        }
    }

    componentDidMount() {
        const { selectedItems, loadOnInit } = this.props

        if (loadOnInit) {
            this.setState(
                {
                    isLoading: true,
                    selectedItems
                },
                () => {
                    this.fetchItemsList()
                }
            )
        }

        if (!loadOnInit) {
        }
    }

    render() {
        const {
            children,
            handleClose,
            isSubmitting,
            typeItems,
            maxSelectedItems,
            prepareItem,
            customStyles,
            isSaving
        } = this.props
        const { isLoading, isLoaded, query, selectedItems, displayedItems, meta, isLoadingMore } = this.state

        return (
            <SelectFromList
                children={children}
                meta={meta}
                isLoading={isLoading}
                isLoaded={isLoaded}
                isSubmitting={isSubmitting}
                maxSelectedItems={maxSelectedItems}
                query={query}
                typeItems={typeItems}
                displayedItems={displayedItems}
                selectedItems={selectedItems}
                handleSearch={this.handleSearch}
                handleSelect={this.handleSelect}
                handleSubmit={this.handleSubmit}
                handleLoadMore={this.handleLoadMore}
                handleClose={handleClose}
                prepareItem={prepareItem}
                isLoadingMore={isLoadingMore}
                customStyles={customStyles}
                isSaving={isSaving}
            />
        )
    }

    handleSubmit = () => {
        const { selectedItems } = this.state
        const { handleSubmit } = this.props

        handleSubmit(selectedItems)
    }

    fetchItemsList = () => {
        this.setState({
            isLoading: true,
            isLoaded: false
        })

        const { fetchItemsList, exclude } = this.props
        const { query } = this.state

        fetchItemsList(query, { page: 1, length: 10, exclude }).then(({ payload }) => {
            this.setState({
                meta: payload.data.meta,
                displayedItems: this.getItemsToDisplay(payload),
                isLoading: false,
                isLoaded: true
            })
        })
    }

    getItemsToDisplay = payload => {
        const { selectedItems } = this.state
        return this.concatArrayWithoutDuplicate(selectedItems, payload.data.data)
    }

    concatArrayWithoutDuplicate = (firstArray, secondArray) => {
        return firstArray.concat(
            secondArray.filter(
                secondArrayItem => !firstArray.some(firstArrayItem => firstArrayItem.id === secondArrayItem.id)
            )
        )
    }

    handleSelect = checkedItem => {
        const { selectedItems } = this.state

        this.setState({
            selectedItems: selectedItems.some(selectedItem => selectedItem.id === checkedItem.id)
                ? selectedItems.filter(selectedItem => selectedItem.id !== checkedItem.id)
                : [...selectedItems, checkedItem]
        })
    }

    handleLoadMore = () => {
        const { fetchItemsList, exclude } = this.props
        const { query, meta, displayedItems, isLoadingMore } = this.state

        if (isLoadingMore) {
            return
        }

        this.setState({ isLoadingMore: true }, () => {
            fetchItemsList(query, { page: meta.current_page + 1, length: 10, exclude }).then(({ payload }) => {
                const newDisplayedItems = this.concatArrayWithoutDuplicate(displayedItems, payload.data.data)

                this.setState({
                    meta: payload.data.meta,
                    displayedItems: newDisplayedItems,
                    isLoadingMore: false
                })
            })
        })
    }

    handleSearch = query => {
        this.setState({ query }, () => {
            if (this.debounce) {
                clearInterval(this.debounce)
            }

            this.debounce = setTimeout(() => {
                this.fetchItemsList()
            }, 500)
        })
    }
}

SelectFromListContainer.defaultProps = {
    loadOnInit: true
}

export default SelectFromListContainer
