import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./Title.css"

const Title = props => {
    const { cx, selectedCount } = props

    return (
        <div className={cx("root")}>
            Manual Entries{!!selectedCount && ":"}
            {!!selectedCount && (
                <span className={cx("selected-wrapper")}>
                    Selected <span className={cx("counter")}>{selectedCount}</span>
                </span>
            )}
        </div>
    )
}

Title.propTypes = {
    selectedCount: PropTypes.number
}

export default withStyles(Title, styles)
