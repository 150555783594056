import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Panel, SnakeLoader } from "@butterfly-frontend/ui"
import { Summary, OrdersList, Header } from "modules/DashboardModule/components"

import styles from "./SellerDetailsPage.module.css"

const SellerDetailsPage = ({ data, selectedRange, seller, isLoading }) => {
    return (
        <Fragment>
            <Header
                title={!!seller.first_name && !!seller.last_name ? `${seller.first_name} ${seller.last_name}` : ""}
            />

            {isLoading ? (
                <SnakeLoader />
            ) : (
                <Fragment>
                    <Summary dashboardData={data} />

                    <Panel classes={{ panel: styles.panel }}>
                        <OrdersList selectedRange={selectedRange} user_id={seller.id} />
                    </Panel>
                </Fragment>
            )}
        </Fragment>
    )
}

SellerDetailsPage.propTypes = {
    data: PropTypes.shape({
        orders_totals: PropTypes.shape({
            total_sales_amount: PropTypes.number,
            total_returns_amount: PropTypes.number,
            total_store_credits: PropTypes.number,
            total_payments: PropTypes.number
        }).isRequired,
        invoiced: PropTypes.shape({
            invoices_count: PropTypes.number,
            fully_paid: PropTypes.number
        }).isRequired,
        orders_by_completion: PropTypes.shape({
            incomplete: PropTypes.number,
            finished: PropTypes.number
        }).isRequired,
        sale_channels: PropTypes.shape({
            showroom: PropTypes.number,
            web: PropTypes.number
        }).isRequired
    }),
    selectedRange: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string
    }).isRequired,
    seller: PropTypes.shape({
        id: PropTypes.number,
        first_name: PropTypes.string,
        last_name: PropTypes.string
    }).isRequired,
    isLoading: PropTypes.bool
}

export default SellerDetailsPage
