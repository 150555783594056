import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import Layout from "./Layout"
import Login from "./Login/index"
import ForgotPassword from "./ForgotPassword/index"
import ResetPassword from "./ResetPassword/index"

const RESET_TYPE = "reset"
const SET_TYPE = "set"
const REMIND_TYPE = "remind"

class LoginPageContainer extends Component {
    state = {
        token: ""
    }

    componentDidMount() {
        const { match } = this.props
        if (match && match.params && match.params.token) {
            this.setState({
                token: match.params.token
            })
        }
    }

    render() {
        const { token } = this.state
        const { pageType } = this.props

        let title = "Sign In"
        let Page = Login
        if (pageType === REMIND_TYPE) {
            title = "Forgot Password"
            Page = ForgotPassword
        }

        if (pageType === SET_TYPE || pageType === RESET_TYPE) {
            title = "Reset Your Password"
            Page = ResetPassword
        }

        if (pageType === SET_TYPE) {
            title = "Set Up Your Password"
        }

        return (
            <Layout title={title}>
                <Page {...this.props} token={token} />
            </Layout>
        )
    }
}

LoginPageContainer.SET_TYPE = SET_TYPE
LoginPageContainer.RESET_TYPE = RESET_TYPE
LoginPageContainer.REMIND_TYPE = REMIND_TYPE

export default withRouter(LoginPageContainer)
