import React from "react"
import PropTypes from "prop-types"

import { Image, Toggle, Button, ActionButton } from "ui"
import { slugify } from "helpers/slugify"

import toggleStyles from "./overrides/toggleStyles.css"
import viewButtonStyles from "./overrides/viewButtonStyles.css"

import pencilIcon from "assets/pencil.svg"
import deleteIcon from "assets/delete.svg"

const ListRow = props => {
    const {
        cx,
        collection,
        collectionModel,
        toggleCollection,
        onEditCollection,
        onDeleteCollection,
        onPreviewCollection
    } = props
    const { id, title, thumbnail, active } = collection

    return (
        <div className={cx("listRow")}>
            <div className={cx("titleWrapper")}>
                <Image className={cx("image")} src={thumbnail} />
                {title}
            </div>
            <div className={cx("toggleWrapper")}>
                <Toggle
                    labelPosition="left"
                    label={{ on: "active", off: "inactive" }}
                    isOn={active}
                    handleChange={() => toggleCollection(id)}
                    customStyles={toggleStyles}
                />
            </div>
            <div className={cx("actionsWrapper")}>
                <ActionButton
                    className={cx("actionButton")}
                    handleClick={() => onEditCollection(id, collection)}
                    icon={pencilIcon}
                    label="Edit"
                />
                <ActionButton
                    className={cx("actionButton")}
                    handleClick={() => onDeleteCollection(id)}
                    icon={deleteIcon}
                    label="Delete"
                />
                {renderPreviewButton(active)}
            </div>
        </div>
    )

    function renderPreviewButton(active) {
        if (!collectionModel) {
            return null
        }

        const webResource = collectionModel.replace(["posts"], ["news"])

        return (
            <Button
                className="first-button"
                customStyles={viewButtonStyles}
                onClick={() =>
                    onPreviewCollection(collectionModel.slice(0, -1), id, webResource + "/" + slugify(title) + "-" + id)
                }
                label={active ? "View" : "Preview"}
            />
        )
    }
}

ListRow.propTypes = {
    cx: PropTypes.func.isRequired,
    collection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
        thumbnail: PropTypes.string,
        active: PropTypes.bool.isRequired
    }),
    toggleCollection: PropTypes.func.isRequired,
    onEditCollection: PropTypes.func.isRequired,
    onDeleteCollection: PropTypes.func.isRequired
}

export default ListRow
