import union from "lodash/union"

import iconLock from "assets/ico-lock.svg"
import iconWebsite from "assets/ico-website.svg"

export const statusesConfig = {
    sales: {
        new: {
            getStyles: (isDropdown = true) => ({
                borderColor: isDropdown ? "#dadee7" : "#f1f3f6",
                backgroundColor: "#f1f3f6",
                color: "#585c67",
                circle: "#dadee7"
            }),
            dropdown: {
                text: "Proposal",
                canChangeTo: ["in_progress", "awaiting_payment", "on_hold", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Proposal"
            },
            defaultUserValue: {
                id: "new",
                value: "new",
                label: "Proposal"
            }
        },
        in_progress: {
            getStyles: () => ({
                borderColor: "#ffb000",
                circle: "#ffb000",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "In progress",
                canChangeTo: ["awaiting_payment", "sent", "completed", "on_hold", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "In progress"
            },
            defaultUserValue: {
                id: "in_progress",
                value: "in_progress",
                label: "In progress"
            }
        },
        awaiting_payment: {
            getStyles: () => ({
                borderColor: "#ffb000",
                circle: "#ffb000",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "Payment",
                canChangeTo: ["in_progress", "sent", "completed", "on_hold", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Payment"
            },
            defaultUserValue: {
                id: "awaiting_payment",
                value: "awaiting_payment",
                label: "Payment"
            }
        },
        on_hold: {
            getStyles: () => ({
                borderColor: "#ffb000",
                circle: "#ffb000",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "On hold",
                canChangeTo: ["in_progress", "awaiting_payment", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "On hold"
            }
        },
        sent: {
            getStyles: () => ({
                borderColor: "#abd64e",
                circle: "#abd64e",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "Sent",
                canChangeTo: ["completed", "in_progress", "awaiting_payment", "on_hold", "returned"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Sent"
            }
        },
        cancelled: {
            getStyles: () => ({
                borderColor: "#ef7967",
                circle: "#ef7967",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "Cancelled",
                canChangeTo: ["in_progress", "awaiting_payment", "on_hold"],
                hasLastItemListBorder: false,
                isConfirmRequired: true
            },
            label: {
                text: "Cancelled"
            }
        },
        completed: {
            getStyles: () => ({
                borderColor: "#8bbd1f",
                circle: "#fff",
                backgroundColor: "#8bbd1f",
                color: "#fff"
            }),
            dropdown: {
                text: "Delivered",
                canChangeTo: ["in_progress", "awaiting_payment", "on_hold", "sent", "returned"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Delivered"
            }
        },
        returned: {
            getStyles: () => ({
                borderColor: "#ef7967",
                circle: "#ef7967",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "Returned",
                canChangeTo: ["in_progress", "awaiting_payment", "on_hold"],
                hasLastItemListBorder: false,
                isConfirmRequired: true
            },
            label: {
                text: "Returned"
            }
        }
    },
    memo: {
        new: {
            getStyles: (isDropdown = true) => ({
                borderColor: isDropdown ? "#dadee7" : "#f1f3f6",
                backgroundColor: "#f1f3f6",
                color: "#585c67",
                circle: "#dadee7"
            }),
            dropdown: {
                text: "New Memo",
                canChangeTo: ["in_progress", "completed", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "New Memo"
            },
            defaultUserValue: {
                id: "new",
                value: "new",
                label: "New Memo"
            }
        },
        in_progress: {
            getStyles: () => ({
                borderColor: "#abd64e",
                backgroundColor: "#fff",
                color: "#585c67",
                circle: "#abd64e"
            }),
            dropdown: {
                text: "Rented",
                canChangeTo: ["new", "completed", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Rented"
            },
            defaultUserValue: {
                id: "in_progress",
                value: "in_progress",
                label: "Rented"
            }
        },
        completed: {
            getStyles: () => ({
                borderColor: "#8bbd1f",
                backgroundColor: "#8bbd1f",
                color: "#fff",
                circle: "#fff"
            }),
            dropdown: {
                text: "Returned",
                canChangeTo: ["new", "in_progress", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Returned"
            }
        },
        cancelled: {
            getStyles: () => ({
                borderColor: "#ef7967",
                circle: "#ef7967",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "Cancelled",
                canChangeTo: ["new", "in_progress", "completed"],
                hasLastItemListBorder: false,
                isConfirmRequired: true
            },
            label: {
                text: "Cancelled"
            }
        }
    },
    rental: {
        new: {
            getStyles: (isDropdown = true) => ({
                borderColor: isDropdown ? "#dadee7" : "#f1f3f6",
                backgroundColor: "#f1f3f6",
                color: "#585c67",
                circle: "#dadee7"
            }),
            dropdown: {
                text: "New Rental",
                canChangeTo: ["in_progress", "completed", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "New Rental"
            },
            defaultUserValue: {
                id: "new",
                value: "new",
                label: "New Rental"
            }
        },
        in_progress: {
            getStyles: () => ({
                borderColor: "#abd64e",
                backgroundColor: "#fff",
                color: "#585c67",
                circle: "#abd64e"
            }),
            dropdown: {
                text: "Rented",
                canChangeTo: ["new", "completed", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Rented"
            },
            defaultUserValue: {
                id: "in_progress",
                value: "in_progress",
                label: "Rented"
            }
        },
        completed: {
            getStyles: () => ({
                borderColor: "#8bbd1f",
                backgroundColor: "#8bbd1f",
                color: "#fff",
                circle: "#fff"
            }),
            dropdown: {
                text: "Returned",
                canChangeTo: ["new", "in_progress", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Returned"
            }
        },
        cancelled: {
            getStyles: () => ({
                borderColor: "#ef7967",
                circle: "#ef7967",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "Cancelled",
                canChangeTo: ["new", "in_progress", "completed"],
                hasLastItemListBorder: false,
                isConfirmRequired: true
            },
            label: {
                text: "Cancelled"
            }
        }
    },
    payment: {
        new: {
            getStyles: (isDropdown = true, isAfterDue = false) => ({
                borderColor: isDropdown ? "#dadee7" : "#f1f3f6",
                backgroundColor: "#f1f3f6",
                color: isAfterDue ? "#ef7967" : "#585c67",
                circle: "#dadee7"
            }),
            label: {
                text: "Unpaid"
            },
            defaultUserValue: {
                id: "new",
                value: "new",
                label: "Unpaid"
            }
        },
        partially_paid: {
            getStyles: (_, isAfterDue = false) => ({
                borderColor: "#ffb000",
                backgroundColor: "#fff",
                color: isAfterDue ? "#ef7967" : "#585c67",
                circle: "#ffb000"
            }),
            label: {
                text: "Partially paid"
            },
            defaultUserValue: {
                id: "partially_paid",
                value: "partially_paid",
                label: "Partially paid"
            }
        },
        paid: {
            getStyles: () => ({
                borderColor: "#abd64e",
                backgroundColor: "#fff",
                color: "#585c67",
                circle: "#abd64e"
            }),
            label: {
                text: "Paid"
            }
        }
    },
    purchase_order: {
        not_confirmed: {
            getStyles: (isDropdown = true) => ({
                borderColor: isDropdown ? "#dadee7" : "#f1f3f6",
                backgroundColor: "#f1f3f6",
                color: "#585c67",
                circle: "#dadee7"
            }),
            dropdown: {
                text: "Not confirmed",
                canChangeTo: ["confirmed"]
            },
            label: {
                text: "Not confirmed"
            },
            defaultUserValue: {
                id: "not_confirmed",
                value: "not_confirmed",
                label: "Not confirmed"
            }
        },
        confirmed: {
            getStyles: () => ({
                borderColor: "#abd64e",
                backgroundColor: "#fff",
                color: "#585c67",
                circle: "#abd64e"
            }),
            dropdown: {
                text: "Confirmed",
                canChangeTo: ["not_confirmed"]
            },
            label: {
                text: "Confirmed"
            }
        }
    },
    project: {
        new: {
            getStyles: (isDropdown = true) => ({
                borderColor: isDropdown ? "#dadee7" : "#f1f3f6",
                backgroundColor: "#f1f3f6",
                color: "#585c67",
                circle: "#dadee7"
            }),
            dropdown: {
                text: "New project",
                canChangeTo: ["in_progress", "on_hold", "completed", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "New project"
            }
        },
        in_progress: {
            getStyles: () => ({
                borderColor: "#abd64e",
                circle: "#abd64e",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "Ongoing",
                canChangeTo: ["new", "on_hold", "completed", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Ongoing"
            }
        },
        on_hold: {
            getStyles: () => ({
                borderColor: "#ffb000",
                circle: "#ffb000",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "On hold",
                canChangeTo: ["new", "in_progress", "completed", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "On hold"
            }
        },
        completed: {
            getStyles: () => ({
                borderColor: "#8bbd1f",
                circle: "#fff",
                backgroundColor: "#8bbd1f",
                color: "#fff"
            }),
            dropdown: {
                text: "Closed",
                canChangeTo: ["new", "in_progress", "on_hold", "cancelled"],
                hasLastItemListBorder: true
            },
            label: {
                text: "Closed"
            }
        },
        cancelled: {
            getStyles: () => ({
                borderColor: "#ef7967",
                circle: "#ef7967",
                backgroundColor: "#fff",
                color: "#585c67"
            }),
            dropdown: {
                text: "Cancelled",
                canChangeTo: ["new", "in_progress", "on_hold", "completed"]
            },
            label: {
                text: "Cancelled"
            }
        }
    },
    selection: {
        private: {
            getStyles: () => ({
                borderColor: "#f1f3f6",
                backgroundColor: "#f1f3f6",
                color: "#585c67",
                icon: {
                    src: iconLock,
                    class: "iconLock"
                }
            }),
            dropdown: {
                text: "Private",
                canChangeTo: [],
                hasLastItemListBorder: true
            },
            label: {
                text: "Private"
            }
        },
        public: {
            getStyles: () => ({
                borderColor: "#f1f3f6",
                backgroundColor: "#f1f3f6",
                color: "#585c67",
                icon: {
                    src: iconWebsite,
                    class: "iconWebsite"
                }
            }),
            dropdown: {
                text: "Public",
                canChangeTo: [],
                hasLastItemListBorder: true
            },
            label: {
                text: "Public"
            }
        }
    }
}

export const statusTypes = Object.keys(statusesConfig)
export const statusValues = Object.values(statusesConfig).reduce((acc, o) => union(acc, Object.keys(o)), [])
export const defaultUserStatuses = {
    sales: [
        statusesConfig.sales.new.defaultUserValue,
        statusesConfig.sales.in_progress.defaultUserValue,
        statusesConfig.sales.awaiting_payment.defaultUserValue
    ],
    memo: [statusesConfig.memo.new.defaultUserValue, statusesConfig.memo.in_progress.defaultUserValue],
    rental: [statusesConfig.rental.new.defaultUserValue, statusesConfig.rental.in_progress.defaultUserValue],
    payment: [statusesConfig.payment.new.defaultUserValue, statusesConfig.payment.partially_paid.defaultUserValue],
    purchase_order: [statusesConfig.purchase_order.not_confirmed.defaultUserValue]
}
