import React, { useState, memo, useEffect, Fragment } from "react"

import withStyles from "HOC/withStyles"
import { Input } from "ui"
import FilterCheckboxList from "../FilterCheckboxList"
import useDebounce from "hooks/useDebounce"
import CheckboxItem from "../CheckboxItem"
import RadioItem from "../RadioItem"
import isEqual from "lodash/isEqual"

import styles from "./FilterList.css"
import stylesCategoryList from "./CategoryList.css"
import stylesInput from "./overrides/Input.css"
import stylesInputPriceRange from "./overrides/InputPriceRange.css"

import arrowIcon from "assets/arrow-black.svg"
import closeIcon from "assets/close-blue.svg"

const AVAILABILITY_OPTIONS = [
    { key: 1, display: "All", value: "" },
    { key: 2, display: "In Stock", value: true },
    { key: 3, display: "Not In Stock", value: false },
    { key: 4, display: "Incoming", value: "incoming" }
]

const REORDERABLE_OPTIONS = [
    { key: 1, display: "All", value: "all" },
    { key: 2, display: "Yes", value: true },
    { key: 3, display: "No", value: false }
]

const VERIFICATION_OPTIONS = [
    { key: 1, display: "All", value: "" },
    { key: 2, display: "Verified", value: true },
    { key: 3, display: "Not verified", value: false }
]

const FilterGroup = withStyles(props => {
    const { cx, name, children, showClearAll, handleClearAll } = props

    return (
        <div className={cx("filtersContainer")}>
            <header className={cx("filtersHeaderContainer")}>
                <span className={cx("filtersHeaderLeft")}>
                    <span className={cx("filtersHeaderName")}>{name}</span>
                </span>
                <span className={cx("filtersHeaderRight")}>
                    <ClearAll showClearAll={showClearAll} handleClearAll={handleClearAll} />
                </span>
            </header>
            <div className={cx("filtersBody")}>{children}</div>
        </div>
    )
}, styles)

const ClearAll = withStyles(props => {
    const { cx, size, showClearAll, handleClearAll, disabled, label } = props

    return (
        showClearAll && (
            <span className={cx("clearAll", size, { disabled })} onClick={!disabled ? handleClearAll : null}>
                {!!label ? label : "Clear All"}
            </span>
        )
    )
}, styles)

const Filter = withStyles(props => {
    const { cx, name, count, children, disabled, setIsExpanded } = props

    const isHeader = !!name
    const isExpanded = isHeader ? props.isExpanded : true

    return (
        <div className={cx("filtersFilter", { disabled })}>
            {renderHeader()}
            {renderContent()}
        </div>
    )

    function renderHeader() {
        if (!isHeader) {
            return null
        }

        return (
            <div
                className={cx("filtersFilterHeader", { disabled })}
                onClick={disabled ? () => {} : () => setIsExpanded({ type: "TOGGLE" })}
            >
                <div className={cx("filtersFilterHeaderLeft")}>
                    <span className={cx("filtersFilterName")}>{name}</span>
                </div>
                <div className={cx("filtersFilterHeaderRight")}>
                    {!!count && <div className={cx("filtersFilterCount")}>{count}</div>}
                    <div className={cx("filtersFilterToggle", { isExpanded })}>
                        <img src={arrowIcon} alt="" />
                    </div>
                </div>
            </div>
        )
    }

    function renderContent() {
        if (!isExpanded) {
            return null
        }

        return (
            <div className={cx("filtersFilterInner")}>
                {renderBody()}
                {renderFooter()}
            </div>
        )
    }

    function renderBody() {
        return <div className={cx("filtersFilterBody")}>{children}</div>
    }

    function renderFooter() {
        const { showClear, handleClear } = props

        if (!showClear) {
            return null
        }

        return (
            <div className={cx("filtersFilterFooter")}>
                <InputsClear onClick={handleClear} />
            </div>
        )
    }
}, styles)

const CategoryList = memo(
    withStyles(props => {
        const { cx, selectedCategories, setSelectedCategories, level, brandsSelected, categories } = props

        const topLevelCategories = categories.reduce((topLevel, category) => {
            const newTopLevel = category.parent_category ? topLevel : [...topLevel, category]
            return newTopLevel
        }, [])

        const categoriesNotEmpty = !brandsSelected || topLevelCategories.some(cat => cat.count)

        return (
            <div className={cx("root")}>
                {categoriesNotEmpty && (
                    <Fragment>
                        {level > 0 && renderAllCategories()}
                        {topLevelCategories.map(item => renderItem(item))}
                    </Fragment>
                )}
                {!categoriesNotEmpty && (
                    <CategoryListItem
                        shouldHide={brandsSelected}
                        key={-1}
                        id={-1}
                        isPlaceholder={true}
                        name={"All categories"}
                        selectedCategories={[]}
                        setSelectedCategories={() => {}}
                        count={0}
                        categories={[]}
                    />
                )}
            </div>
        )

        function renderAllCategories() {
            return (
                <div
                    className={cx("return")}
                    onClick={() => {
                        setSelectedCategories([])
                    }}
                >
                    <img src={arrowIcon} alt="" />
                    <span>All categories</span>
                </div>
            )
        }

        function renderItem(item, additionalProps = {}) {
            return (
                (!brandsSelected || item.count > 0 || selectedCategories.includes(item.id)) && (
                    <CategoryListItem
                        shouldHide={brandsSelected}
                        level={level}
                        key={item.id}
                        id={item.id}
                        name={item.name}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        count={item.count}
                        categories={item.children}
                        {...additionalProps}
                    />
                )
            )
        }
    }, stylesCategoryList),
    (prevProps, nextProps) =>
        isEqual(prevProps.categories, nextProps.categories) &&
        isEqual(prevProps.selectedCategories, nextProps.selectedCategories)
)

const ActionButtons = withStyles(props => {
    const { cx, filterHandleFilterToggle, isSelectedCategory, isSelectAnyFilter, handleClearAll } = props

    return (
        <div className={cx("actionButtons")}>
            <ClearAll
                showClearAll
                disabled={!isSelectAnyFilter}
                label="Clear filters"
                handleClearAll={handleClearAll}
            />
            {renderButton()}
        </div>
    )

    function renderButton() {
        if (!isSelectAnyFilter && !isSelectedCategory) {
            return (
                <div className={cx("button", "close")} onClick={filterHandleFilterToggle}>
                    Close
                </div>
            )
        }

        return (
            <div className={cx("button", "show")} onClick={filterHandleFilterToggle}>
                Show
            </div>
        )
    }
}, styles)

const CategoryListItem = withStyles(props => {
    const { cx, id, name, selectedCategories, setSelectedCategories, level, count, isSale, isPlaceholder } = props

    return (
        <div className={cx("item")}>
            <div
                className={cx("header", {
                    active: selectedCategories.includes(id),
                    isEmpty: !count && !isPlaceholder,
                    isSale,
                    placeholder: isPlaceholder
                })}
                onClick={() =>
                    selectedCategories.includes(id)
                        ? setSelectedCategories([...selectedCategories.slice(0, level - selectedCategories.length)])
                        : selectedCategories.length > level
                        ? setSelectedCategories([...selectedCategories.slice(0, level - selectedCategories.length), id])
                        : setSelectedCategories([...selectedCategories, id])
                }
            >
                <div className={cx("headerLeft")}>
                    <span className={cx("name")}>{name}</span>
                </div>
                <div className={cx("headerRight")}>{renderCount()}</div>
            </div>
            {renderBody()}
        </div>
    )

    function renderCount() {
        const { cx, count } = props

        return <div className={cx("count")}>{count}</div>
    }

    function renderBody() {
        const { id, categories, selectedCategories, setSelectedCategories, level, shouldHide } = props

        if (!selectedCategories.includes(id)) {
            return null
        }

        if (!categories || !categories.length) {
            return null
        }

        return (
            <div className={cx("body")}>
                {categories.map(
                    item =>
                        (!shouldHide || item.count > 0 || selectedCategories.includes(item.id)) && (
                            <CategoryListItem
                                level={level + 1}
                                key={item.id}
                                id={item.id}
                                name={item.name}
                                selectedCategories={selectedCategories}
                                setSelectedCategories={setSelectedCategories}
                                count={item.count}
                                categories={item.children}
                            />
                        )
                )}
            </div>
        )
    }
}, stylesCategoryList)

const InputsClear = withStyles(props => {
    const { cx, onClick } = props
    return (
        <span onClick={onClick} className={cx("inputsClear")}>
            <img src={closeIcon} alt="" />
            <span>Clear</span>
        </span>
    )
}, styles)

const InputRange = memo(
    props => {
        const initialErrorState = { leftValue: "", rightValue: "" }

        const {
            value,
            cx,
            onChange,
            onClear,
            name,
            innerLabel,
            customStyles,
            postFix,
            disabled,
            isExpanded,
            setIsExpanded,
            count,
            minValue,
            errors
        } = props

        const [showValue, setShowValue] = useState({ ...value })
        const [error, setError] = useState({ ...initialErrorState })
        const { leftValue, rightValue } = showValue
        const selected = !!((leftValue.length && leftValue.length > 0) || (rightValue.length && rightValue.length > 0))

        useDebounce(
            () => {
                const balanceCheck = parseFloat(leftValue) > parseFloat(rightValue)
                const fetchError = errors || {}

                const leftCheck =
                    leftValue &&
                    ((isNaN(leftValue) && "Invalid data") || (rightValue && balanceCheck) || leftValue < minValue)
                const rightCheck =
                    rightValue &&
                    ((isNaN(rightValue) && "Invalid data") || (leftValue && balanceCheck) || rightValue < minValue)

                if (leftCheck || rightCheck) {
                    setError({
                        leftValue: leftCheck || fetchError.leftValue || "",
                        rightValue: rightCheck || fetchError.rightValue || ""
                    })
                } else {
                    setError({ ...initialErrorState })
                }

                if (!leftCheck && !rightCheck && (leftValue !== value.leftValue || rightValue !== value.rightValue)) {
                    onChange(showValue)
                }
            },
            100,
            [showValue]
        )

        useEffect(() => {
            const fetchError = errors || {}

            const leftCheck = leftValue && ((isNaN(leftValue) && "Invalid data") || fetchError.leftValue)
            const rightCheck = rightValue && ((isNaN(rightValue) && "Invalid data") || fetchError.rightValue)

            if (leftCheck || rightCheck) {
                setError({ ...error, leftValue: leftCheck || "", rightValue: rightCheck || "" })
            } else {
                setError({ ...initialErrorState })
            }
        }, [errors])

        useEffect(() => {
            if (!value.leftValue && !value.rightValue) {
                setShowValue(value)
            }
        }, [value])

        return (
            <Filter
                name={name}
                disabled={disabled}
                count={count || selected ? 1 : undefined}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
            >
                <div className={cx("inputs")}>
                    <Input
                        isPlaceholder={true}
                        value={leftValue}
                        error={error.leftValue}
                        label="min"
                        innerLabel={innerLabel}
                        isInnerLabelLeft={!!innerLabel}
                        customStyles={{ ...stylesInput, ...customStyles }}
                        onChange={({ event }) => {
                            setShowValue({ ...showValue, leftValue: event.target.value })
                        }}
                    />
                    <span className={cx("inputsSeparator")}>-</span>
                    <Input
                        isPlaceholder={true}
                        value={rightValue}
                        error={error.rightValue}
                        label="max"
                        innerLabel={innerLabel}
                        isInnerLabelLeft={!!innerLabel}
                        customStyles={{ ...stylesInput, ...customStyles }}
                        onChange={({ event }) => {
                            setShowValue({ ...showValue, rightValue: event.target.value })
                        }}
                    />
                    {postFix && <span className={cx("inputsPostfix")}>{postFix}</span>}
                </div>
                {selected && (
                    <div className={cx("inputsActions")}>
                        <InputsClear
                            onClick={() => {
                                onClear()
                            }}
                        />
                    </div>
                )}
            </Filter>
        )
    },
    (prevProps, nextProps) =>
        prevProps.value.leftValue === nextProps.value.leftValue &&
        prevProps.value.rightValue === nextProps.value.rightValue &&
        prevProps.disabled === nextProps.disabled &&
        prevProps.isExpanded === nextProps.isExpanded &&
        prevProps.errors === nextProps.errors
)

InputRange.defaultProps = {
    customStyles: {},
    minValue: 0
}

const FilterList = props => {
    const {
        cx,
        categories,
        filterHandleFilterToggle,
        isSelectAnyFilter,
        categoryLevel,
        brands,
        locationsList,
        selected,
        handleClearAll,
        actions,
        isExpanded,
        setIsExpanded,
        disabled,
        errors,
        selections,
        brandsSearchPhrase,
        brandsSearchPhraseRef,
        selectionsSearchPhrase,
        selectionsSearchPhraseRef
    } = props

    const categorySelected = selected.categories && selected.categories.length > 0
    const brandsSelected = selected.brands && selected.brands.length > 0

    const toggleLocation = id => {
        selected.locations.includes(id)
            ? actions.locations.update(selected.locations.filter(loc => loc !== id))
            : actions.locations.update([...selected.locations, id])
    }

    return (
        <div className={cx("root")}>
            <FilterGroup name="Categories" showClearAll={false}>
                <CategoryList
                    brandsSelected={brandsSelected}
                    level={categoryLevel}
                    categories={categories}
                    selectedCategories={selected.categories}
                    setSelectedCategories={actions.categories.update}
                />
            </FilterGroup>

            <FilterGroup name="Filters" handleClearAll={handleClearAll} showClearAll={isSelectAnyFilter}>
                <Filter
                    disabled={disabled.brands}
                    name="Brand"
                    count={selected.brands.length}
                    isExpanded={isExpanded.brands}
                    showClear={selected.brands.length > 0}
                    handleClear={actions.brands.clear}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "brands", value: action.value }, type: action.type })
                    }
                >
                    <FilterCheckboxList
                        shouldHideOnDataChange={categorySelected}
                        data={brands}
                        notFoundMessage={"No brand found."}
                        selectedData={selected.brands}
                        handleChange={actions.brands.update}
                        searchPhrase={brandsSearchPhrase}
                        searchPhraseRef={brandsSearchPhraseRef}
                    />
                </Filter>
                <Filter
                    disabled={disabled.selections}
                    name="Selections"
                    count={selected.selections.length}
                    isExpanded={isExpanded.selections}
                    showClear={selected.selections.length > 0}
                    handleClear={actions.selections.clear}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "selections", value: action.value }, type: action.type })
                    }
                >
                    <FilterCheckboxList
                        data={selections}
                        notFoundMessage={"No selections found."}
                        selectedData={selected.selections}
                        handleChange={actions.selections.update}
                        searchPhrase={selectionsSearchPhrase}
                        searchPhraseRef={selectionsSearchPhraseRef}
                    />
                </Filter>
                <Filter
                    name="Availability"
                    disabled={disabled.availability}
                    count={selected.availability !== "" && 1}
                    isExpanded={isExpanded.availability}
                    handleClear={actions.availability.clear}
                    showClear={selected.availability !== ""}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "availability", value: action.value }, type: action.type })
                    }
                >
                    {AVAILABILITY_OPTIONS.map(({ display, value }) => (
                        <RadioItem
                            key={display}
                            name="availability"
                            label={display}
                            value={value}
                            checked={selected.availability === value}
                            handleChange={el => actions.availability.update(el.value)}
                        />
                    ))}
                </Filter>
                <Filter
                    name="Reorderable"
                    disabled={disabled.reorderable}
                    count={selected.reorderable !== "all" && 1}
                    showClear={selected.reorderable !== "all"}
                    handleClear={actions.reorderable.clear}
                    isExpanded={isExpanded.reorderable}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "reorderable", value: action.value }, type: action.type })
                    }
                >
                    {REORDERABLE_OPTIONS.map(({ display, value }) => (
                        <RadioItem
                            key={display}
                            name="reorderable"
                            label={display}
                            value={value}
                            checked={selected.reorderable === value}
                            handleChange={el => actions.reorderable.update(el.value)}
                        />
                    ))}
                </Filter>
                <div className={cx("filtersBodyDivider")}></div>
                <InputRange
                    cx={cx}
                    name="Price Range"
                    value={selected.priceRange}
                    disabled={disabled.priceRange}
                    errors={errors.priceRange}
                    innerLabel={"$"}
                    customStyles={stylesInputPriceRange}
                    onChange={actions.priceRange.update}
                    onClear={actions.priceRange.clear}
                    isExpanded={isExpanded.priceRange}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "priceRange", value: action.value }, type: action.type })
                    }
                />
                <InputRange
                    cx={cx}
                    name="Discount"
                    value={selected.discount}
                    disabled={disabled.discount}
                    errors={errors.discount}
                    onChange={actions.discount.update}
                    onClear={actions.discount.clear}
                    postFix="%"
                    isExpanded={isExpanded.discount}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "discount", value: action.value }, type: action.type })
                    }
                />
                <div className={cx("filtersBodyDivider")}></div>
                <InputRange
                    cx={cx}
                    name="Manufacturing time"
                    value={selected.manufacturingTime}
                    disabled={disabled.manufacturingTime}
                    errors={errors.manufacturingTime}
                    onChange={actions.manufacturingTime.update}
                    onClear={actions.manufacturingTime.clear}
                    postFix="weeks"
                    isExpanded={isExpanded.manufacturingTime}
                    setIsExpanded={action =>
                        setIsExpanded({
                            payload: { name: "manufacturingTime", value: action.value },
                            type: action.type
                        })
                    }
                />
                <InputRange
                    cx={cx}
                    name="Production year"
                    value={selected.productionYear}
                    disabled={disabled.productionYear}
                    errors={errors.productionYear}
                    onChange={actions.productionYear.update}
                    onClear={actions.productionYear.clear}
                    minValue={1900}
                    postFix="years"
                    isExpanded={isExpanded.productionYear}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "productionYear", value: action.value }, type: action.type })
                    }
                />
                <InputRange
                    cx={cx}
                    name="Delivering time"
                    value={selected.deliveringTime}
                    disabled={disabled.deliveringTime}
                    errors={errors.deliveringTime}
                    onChange={actions.deliveringTime.update}
                    onClear={actions.deliveringTime.clear}
                    postFix="weeks"
                    isExpanded={isExpanded.deliveringTime}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "deliveringTime", value: action.value }, type: action.type })
                    }
                />
                <Filter
                    name="Location"
                    disabled={disabled.location}
                    isExpanded={isExpanded.location}
                    count={selected.locations.length}
                    handleClear={actions.locations.clear}
                    showClear={selected.locations.length !== 0}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "location", value: action.value }, type: action.type })
                    }
                >
                    <FilterCheckboxList
                        shouldHideOnDataChange={false}
                        data={locationsList}
                        notFoundMessage={"No location found."}
                        selectedData={selected.locations}
                        handleChange={id => toggleLocation(id)}
                        searchPhrase={brandsSearchPhrase}
                        searchPhraseRef={brandsSearchPhraseRef}
                    />
                </Filter>
                <div className={cx("filtersBodyDivider")}></div>
                <Filter setIsExpanded={() => {}}>
                    <CheckboxItem
                        id="withoutPhotos"
                        name="Without photos"
                        checked={selected.withoutPhotos}
                        disabled={disabled.withoutPhotos}
                        handleChange={() => actions.withoutPhotos.update(selected.withoutPhotos ? "" : true)}
                    />
                    <CheckboxItem
                        id="availableOnWeb"
                        name="Available on web"
                        checked={selected.availableOnWeb}
                        disabled={disabled.availableOnWeb}
                        handleChange={() => actions.availableOnWeb.update(selected.availableOnWeb ? "" : true)}
                    />
                    <CheckboxItem
                        id="deleted"
                        name="Deleted"
                        checked={selected.deleted}
                        disabled={disabled.deleted}
                        handleChange={() => actions.deleted.update(selected.deleted ? "" : true)}
                    />
                    <CheckboxItem
                        id="clearance"
                        name="Clearance"
                        checked={selected.clearance}
                        disabled={disabled.clearance}
                        handleChange={() => actions.clearance.update(selected.clearance ? "" : true)}
                    />
                </Filter>
                <Filter
                    name="Verification"
                    disabled={disabled.verification}
                    isExpanded={isExpanded.verification}
                    count={selected.verification !== "" && 1}
                    handleClear={actions.verification.clear}
                    showClear={selected.verification !== ""}
                    setIsExpanded={action =>
                        setIsExpanded({ payload: { name: "verification", value: action.value }, type: action.type })
                    }
                >
                    {VERIFICATION_OPTIONS.map(({ display, value }) => (
                        <RadioItem
                            key={display}
                            name="verification"
                            label={display}
                            value={value}
                            checked={selected.verification === value}
                            handleChange={el => actions.verification.update(el.value)}
                        />
                    ))}
                </Filter>
            </FilterGroup>
            <ActionButtons
                filterHandleFilterToggle={filterHandleFilterToggle}
                isSelectAnyFilter={isSelectAnyFilter}
                isSelectedCategory={!!selected.categories.length}
                handleClearAll={handleClearAll}
            />
        </div>
    )
}

export default withStyles(FilterList, styles)
