import React, { useState, useEffect, useMemo, Fragment } from "react"
import { connect } from "react-redux"

import fetch from "helpers/fetch"
import * as actions from "actions/press"
import * as notificationActions from "actions/notification"

import CollectionList from "modules/ContentModule/components/CollectionList/CollectionList"
import ActivateDeleteModal from "modules/ContentModule/components/CollectionList/ActivateDeleteModal"
import PressModal from "modules/ContentModule/components/PressModal"

const Listing = props => {
    const {
        data,
        getData,
        showSuccessNotification,
        showErrorNotification,
        createPress,
        updatePress,
        deletePress,
        replaceList
    } = props

    const {
        fetchStatus: { isLoading },
        data: press,
        meta
    } = data
    const [isAddEditModalOpen, setAddEditIsModalOpen] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const initialModalState = { pressId: null, type: null }
    const [modalState, setModalState] = useState(initialModalState)
    const [isModalLoading, setIsModalLoading] = useState(false)
    const [currentPress, setCurrentPress] = useState({})

    useEffect(() => {
        getList()
    }, [currentPage])

    const getList = () => getData({ currentPage })
    const pressList = useMemo(() => press.map(item => ({ ...item, thumbnail: item.cover_desktop.url })), [press])

    const refreshList = () => {
        setCurrentPage(1)
        currentPage === 1 && getList()
    }

    const handleDeletePress = id => {
        deletePress(id)
            .then(() => showSuccessNotification())
            .catch(() => showErrorNotification())
            .finally(() => finalModalMethod())
    }

    const openActivateDeleteModal = (pressId, type) => setModalState({ pressId, type })
    const closeActivateDeleteModal = () => setModalState(initialModalState)
    const isActivateDeleteModalOpen = !!(modalState.pressId && modalState.type)
    const modalCollection = useMemo(() => pressList.find(({ id }) => id === modalState.pressId) || {}, [
        modalState.pressId,
        pressList
    ])

    const submitModal = action => {
        setIsModalLoading(true)
        action()
    }

    const finalModalMethod = () => {
        setIsModalLoading(false)
        refreshList()
        closeActivateDeleteModal()
    }

    const modalMethods = {
        activate: () => {
            const body = { id: modalState.pressId, active: !modalCollection.active }
            updatePress(body)
                .then(() => showSuccessNotification())
                .catch(() => showErrorNotification())
                .finally(() => finalModalMethod())
        },
        delete: () => handleDeletePress(modalState.pressId)
    }
    const modalTypes = Object.keys(modalMethods).reduce((acc, key) => ({ ...acc, [key]: key }), {})

    const handleCloseAddEditModal = () => {
        setCurrentPress({})
        setAddEditIsModalOpen(false)
    }

    const handleSubmitAddEditModal = (pressData, coverImage, hoverImage, pdfFile, formikActions) => {
        setIsModalLoading(true)

        const pressToChange = {
            ...pressData,
            cover_desktop: Object.keys(coverImage).length && coverImage.id,
            hover_desktop: Object.keys(hoverImage).length && hoverImage.id,
            attachment: Object.keys(pdfFile).length ? pdfFile.id : undefined,
            url: pressData.url || undefined
        }

        const method = pressData.id ? updatePress : createPress

        method(pressToChange)
            .then(() => {
                showSuccessNotification()
                refreshList()
                setCurrentPress({})
                setAddEditIsModalOpen(false)
            })
            .catch(error => {
                formikActions.setErrors(
                    Object.keys(error.errors || []).reduce((acc, field) => {
                        acc[field] = error.errors[field][0]
                        return acc
                    }, {})
                )
                showErrorNotification()
            })
            .finally(() => {
                setIsModalLoading(false)
            })
    }

    const handleEditPress = (pressId, pressProps) => {
        setAddEditIsModalOpen(true)
        setCurrentPress(pressProps)
    }

    const onReorderList = async items => {
        try {
            replaceList({
                ...data,
                data: items
            })

            await fetch.postRAW("/multi-actions", {
                ids: items.map(item => item.id),
                model: "presses",
                action: "sort"
            })

            showSuccessNotification()
        } catch (error) {
            showErrorNotification()
        }
    }

    return (
        <Fragment>
            <CollectionList
                isLoading={isLoading}
                collectionLabel="Press"
                collectionLabelPlural="Press"
                lowerCaseLabel="press"
                list={pressList}
                listMeta={meta}
                getList={() => setCurrentPage(currentPage + 1)}
                toggleCollection={id => openActivateDeleteModal(id, modalTypes.activate)}
                onCreateCollection={() => setAddEditIsModalOpen(true)}
                onEditCollection={handleEditPress}
                onDeleteCollection={id => openActivateDeleteModal(id, modalTypes.delete)}
                onReorderCollection={onReorderList}
            />
            {isActivateDeleteModalOpen && (
                <ActivateDeleteModal
                    modalType={modalState.type}
                    isLoading={isModalLoading}
                    isCollectionActive={modalCollection.active}
                    collectoinTitle="Press"
                    lowerCaseCollectionTypeLabel="press"
                    onClose={closeActivateDeleteModal}
                    onSubmit={() => submitModal(modalMethods[modalState.type])}
                />
            )}

            {isAddEditModalOpen && (
                <PressModal
                    isOpen={isAddEditModalOpen}
                    handleClose={handleCloseAddEditModal}
                    handleSubmitAddEditModal={handleSubmitAddEditModal}
                    press={currentPress}
                    isSaving={isModalLoading}
                />
            )}
        </Fragment>
    )
}

const mapStateToProps = state => ({
    data: state.db.press.list
})

const mapDispatchToProps = dispatch => ({
    getData: data => dispatch(actions.getPresses(data)),
    createPress: data => dispatch(actions.createPress(data)),
    updatePress: data => dispatch(actions.updatePress(data)),
    deletePress: id => dispatch(actions.deletePress(id)),
    replaceList: data => dispatch(actions.getPressSuccess(data)),
    showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
    showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing)
