import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import SubmitModal from "ui/SubmitModal"
import styles from "./DeleteProductsModal.css"

const DeleteSingleProductModal = props => {
    const { isOpen, isLoading, productName, onCancel, onSubmit, cx } = props

    return (
        <SubmitModal
            redButton
            isOpen={isOpen}
            isLoading={isLoading}
            header={"Delete this products?"}
            body={
                <span>
                    {"Are you sure you want to delete the product "}
                    <span className={cx("delete-header-bold")}>
                        {`${productName}`}
                        <span className={cx("delete-header-normal")}>?</span>
                    </span>
                    {" This action cannot be reversed."}
                </span>
            }
            submitText="Delete"
            onClose={onCancel}
            onSubmit={onSubmit}
        />
    )
}

DeleteSingleProductModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    productName: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default withStyles(DeleteSingleProductModal, styles)
