import React, { useEffect, useState, Fragment, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"
import _isEqual from "lodash/isEqual"

import { Modal, useTableFilterBarSearch, Button } from "@butterfly-frontend/ui"

import { AddManualEntry } from "ui"
import {
    ProductHeader,
    ProductFilters,
    ProductList,
    ProductInfo,
    ProductSearchBar,
    ProductListItem,
    ProductActions,
    Combinations,
    SelectStockItemList,
    mapFiltersToApi,
    checkIfDateRangeIsValid,
    PRODUCT_TYPE_KEYS
} from "ui/ChooseProduct"
import { INITIAL_FILTERS } from "ui/ChooseProduct/constants"
import { useProductFilters, useProductList, useProductsState } from "ui/ChooseProduct/hooks"

import styles from "./ChooseProductForSalesOrderModal.module.css"

const ChooseProductForSalesOrderModal = ({
    onClose,
    handleSelect,
    isDisabledAddItems = false,
    isOpen,
    fetchStatuses
}) => {
    const [isFilterExpanded, setIsFilterExpanded] = useState(true)
    const [isAddManualEntryFormOpen, setIsAddManualEntryFormOpen] = useState(false)
    const [selectedProduct, setSelectedProduct] = useState()
    const [productForChooseCombinationModal, setProductForChooseCombinationModal] = useState(null)
    const [isInit, setIsInit] = useState(true)

    const { setSelectedFilter, selectedFilterValues, activeFiltersCount } = useProductFilters({
        initialFilterValues: INITIAL_FILTERS,
        filtersExcludedFromCounter: ["type"]
    })

    const isChoosingProducts = useMemo(() => selectedFilterValues.type === PRODUCT_TYPE_KEYS.PRODUCTS, [
        selectedFilterValues.type
    ])

    const { itemsState, handleChangeIsLoading } = useProductsState({ fetchStatuses })

    const {
        value: filterSearchPhrase,
        debouncedValue: query,
        onChange: handleChangeFilterSearchPhrase
    } = useTableFilterBarSearch("")

    const { products, brands, categories, locations, fetchProducts, handleLoadMoreProducts } = useProductList()

    const refreshProductList = useCallback(() => {
        if (checkIfDateRangeIsValid(selectedFilterValues.dateRange)) {
            fetchProducts({ query, with_items_counts: true, ...mapFiltersToApi(selectedFilterValues) })
        }
    }, [query, selectedFilterValues])

    useEffect(() => {
        if (!isInit || !_isEmpty(query) || !_isEqual(selectedFilterValues, INITIAL_FILTERS)) {
            refreshProductList()
            setIsInit(false)
        }
    }, [query, selectedFilterValues])

    useEffect(() => {
        if (!isOpen || !isChoosingProducts) {
            setIsAddManualEntryFormOpen(false)
        }
    }, [isOpen, isChoosingProducts])

    const renderProductInfo = product => (
        <ProductInfo
            name={product.name}
            brand={(product.brand || {}).name || ""}
            type={product.type}
            combination={product.combination}
            imageUrl={(product.media || {}).url || _get(product, "images.thumb[0].url") || ""}
            inStockCounter={_get(product, "items_count_per_status.in_stock", null)}
            incomingCounter={_get(product, "items_count_per_status.incoming", null)}
        />
    )

    const clearSelectedProduct = () => {
        handleChangeIsLoading(selectedProduct.product_id, false)
        setSelectedProduct(null)
    }

    return (
        isOpen && (
            <Modal
                onClose={onClose}
                title={
                    selectedProduct ? (
                        <ProductHeader mainTitle="Select in Stock Item" />
                    ) : productForChooseCombinationModal ? (
                        <ProductHeader mainTitle="Choose an option" />
                    ) : (
                        <ProductHeader
                            productType={selectedFilterValues.type}
                            handleChangeType={value => setSelectedFilter("type", value)}
                            areServicesEnabled
                        />
                    )
                }
            >
                <div className={styles.modalContent}>
                    {selectedProduct ? (
                        <Fragment>
                            {renderProductInfo(selectedProduct)}
                            <SelectStockItemList
                                product={selectedProduct}
                                onCancel={clearSelectedProduct}
                                handleSelectStockItems={chosenStockItems => {
                                    setProductForChooseCombinationModal(null)
                                    handleSelect(chosenStockItems, () => {
                                        clearSelectedProduct()
                                        refreshProductList()
                                    })
                                }}
                                handleChangeIsLoading={handleChangeIsLoading}
                                itemsState={itemsState}
                            />
                        </Fragment>
                    ) : productForChooseCombinationModal ? (
                        <Fragment>
                            {renderProductInfo(productForChooseCombinationModal)}
                            <div className={styles.combinationModalDivider} />
                            <Combinations
                                productId={productForChooseCombinationModal.id}
                                productImage={(productForChooseCombinationModal.media || {}).url || ""}
                                handleSelectProductCombination={product => {
                                    setSelectedProduct(product)
                                    handleChangeIsLoading(product.id, false)
                                }}
                                itemsState={itemsState}
                                handleChangeIsLoading={handleChangeIsLoading}
                                clearSelectedProduct={() => setProductForChooseCombinationModal(null)}
                            />
                        </Fragment>
                    ) : (
                        <Fragment>
                            <ProductSearchBar
                                type={selectedFilterValues.type}
                                filterSearchPhrase={filterSearchPhrase}
                                handleChangeFilterSearchPhrase={handleChangeFilterSearchPhrase}
                                filterCount={activeFiltersCount}
                                isFilterExpanded={isFilterExpanded}
                                setIsFilterExpanded={setIsFilterExpanded}
                            />
                            {isChoosingProducts && isFilterExpanded && (
                                <ProductFilters
                                    setSelectedFilter={setSelectedFilter}
                                    selectedFilterValues={selectedFilterValues}
                                    locations={locations}
                                    categories={categories}
                                    brands={brands}
                                    areManualEntriesEnabled={isChoosingProducts}
                                />
                            )}
                            {isChoosingProducts && (
                                <div className={styles.manualEntry}>
                                    {isAddManualEntryFormOpen ? (
                                        <AddManualEntry
                                            tax_code={0}
                                            discount={0}
                                            onClose={() => setIsAddManualEntryFormOpen(false)}
                                            onSuccess={handleSelect}
                                        />
                                    ) : (
                                        <Button
                                            size="small"
                                            color="blue"
                                            onClick={() => setIsAddManualEntryFormOpen(true)}
                                            classes={{
                                                button: classNames(styles.manualEntryButton, {
                                                    [styles.manualEntryButtonMovedUp]: isFilterExpanded
                                                })
                                            }}
                                        >
                                            Add Manual Entry
                                        </Button>
                                    )}
                                </div>
                            )}
                            <ProductList
                                handleLoadMoreProducts={() =>
                                    handleLoadMoreProducts({ query, ...mapFiltersToApi(selectedFilterValues) })
                                }
                                products={products}
                                type={selectedFilterValues.type}
                            >
                                {products.data.map(product => (
                                    <ProductListItem key={product.id}>
                                        {renderProductInfo(product)}
                                        <ProductActions
                                            type={selectedFilterValues.type}
                                            product={product}
                                            handleSelect={product => {
                                                if (selectedFilterValues.type === PRODUCT_TYPE_KEYS.SERVICES) {
                                                    handleSelect(product)
                                                    return
                                                }
                                                setSelectedProduct(product)
                                                handleChangeIsLoading(product.id, false)
                                            }}
                                            handleChooseCombination={product =>
                                                setProductForChooseCombinationModal(product)
                                            }
                                            itemsState={itemsState}
                                            isDisabledAddItems={isDisabledAddItems}
                                            handleChangeIsLoading={handleChangeIsLoading}
                                            disableQuantity
                                        />
                                    </ProductListItem>
                                ))}
                            </ProductList>
                        </Fragment>
                    )}
                </div>
            </Modal>
        )
    )
}

ChooseProductForSalesOrderModal.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    isDisabledAddItems: PropTypes.bool,
    fetchStatuses: PropTypes.objectOf(PropTypes.shape({ isLoading: PropTypes.bool }))
}

export default ChooseProductForSalesOrderModal
