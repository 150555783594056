import React, { Component } from "react"

import ActionButton from "../ActionButton"
import Image from "../Image"
import { Toggle } from "ui"
import toggleStylesOverride from "./MainPageListingsRowToggleOverride.css"

import withStyles from "HOC/withStyles"
import styles from "./MainPageListingsRow.css"

import deleteIcon from "assets/delete.svg"
import editIcon from "assets/pencil.svg"

class MainPageListingsRow extends Component {
    render() {
        const {
            cx,
            item,
            isLastElement,
            handleDelete,
            handleEdit,
            handleToggle,
            customActions,
            customColumns,
            customToggleLabel,
            listMapping
        } = this.props

        const customActionsRendered = (customActions || []).map((button, key) => {
            return (
                <ActionButton
                    icon={button.icon}
                    text={button.name}
                    handleAction={() => button.action(item[listMapping.id])}
                    key={key}
                />
            )
        })

        const customColumnsRendered = (customColumns || []).map((column, key) => {
            return (
                <span className={cx(column.name)} key={key}>
                    {item[listMapping.pins]} {item[listMapping.pins] > 1 ? column.label[1] : column.label[0]}
                </span>
            )
        })

        return (
            <li className={cx("itemList", { lastChild: isLastElement })} key={item[listMapping.id]}>
                <div className={cx("column-1")}>
                    <div className={cx("image")}>
                        <Image src={item[listMapping.image]} />
                    </div>
                    <span className={cx("name")}>{item[listMapping.name]}</span>
                    {customColumnsRendered}
                </div>
                <div className={cx("actions")}>
                    <span className={cx("displayOrder")}>{item.displayOrder && `# ${item.displayOrder}`}</span>
                    {handleToggle && (
                        <Toggle
                            handleChange={() => handleToggle(item[listMapping.id])}
                            customStyles={toggleStylesOverride}
                            labelPosition={!!customToggleLabel ? "left" : "right"}
                            isOn={!!item[listMapping.active]}
                            label={customToggleLabel}
                        />
                    )}
                    {customActionsRendered}
                    {handleEdit && (
                        <ActionButton
                            icon={editIcon}
                            text="Edit"
                            handleAction={() => handleEdit(item[listMapping.id])}
                        />
                    )}
                    {handleDelete && (
                        <ActionButton icon={deleteIcon} text="Delete" handleAction={() => handleDelete(item)} />
                    )}
                </div>
            </li>
        )
    }
}

export default withStyles(MainPageListingsRow, styles)
