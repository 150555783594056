import React from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"

import {
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableCell,
    TableRow,
    Button,
    TrashIcon,
    Status
} from "@butterfly-frontend/ui"
import { StockItemStatus } from "modules/WmsModule/components"

import styles from "./SelectedItemsList.module.css"

const CELL_15 = {
    width: "15%",
    minWidth: 110
}

const SelectedItemsList = ({ selectedItems, onRemoveItem }) => (
    <div>
        <Table>
            <TableHead>
                <TableHeadCell minWidth={200} width="40%">
                    PRODUCT NAME
                </TableHeadCell>
                <TableHeadCell {...CELL_15}>ID</TableHeadCell>
                <TableHeadCell minWidth={114} width="15%">
                    CONDITION
                </TableHeadCell>
                <TableHeadCell {...CELL_15}>WAREHOUSE</TableHeadCell>
                <TableHeadCell {...CELL_15}>STATUS</TableHeadCell>
                <TableHeadCell width={32} minWidth={32} />
            </TableHead>
            <TableBody>
                {selectedItems.map(selectedItem => (
                    <TableRow key={selectedItem.id}>
                        <TableCell minWidth={200} width="40%">
                            {selectedItem.product.name}
                        </TableCell>
                        <TableCell {...CELL_15}>{selectedItem.id}</TableCell>
                        <TableCell minWidth={114} width="15%">
                            {selectedItem.condition ? (
                                <div className={styles.statusWrapper}>
                                    <Status color={selectedItem.condition.color}>{selectedItem.condition.name}</Status>
                                </div>
                            ) : (
                                "-"
                            )}
                        </TableCell>
                        <TableCell {...CELL_15}>{selectedItem.warehouse.name}</TableCell>
                        <TableCell {...CELL_15}>
                            <div className={styles.statusWrapper}>
                                <StockItemStatus stockItemStatus={_get(selectedItem, "status")} />
                            </div>
                        </TableCell>
                        <TableCell width={32} minWidth={32}>
                            <Button variant="flat" Icon={TrashIcon} onClick={() => onRemoveItem(selectedItem)} />
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </div>
)

SelectedItemsList.propTypes = {
    onRemoveItem: PropTypes.func.isRequired,
    selectedItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            product: PropTypes.shape({
                name: PropTypes.string
            }),
            condition: PropTypes.shape({
                name: PropTypes.string,
                color: PropTypes.string
            }),
            warehouse: PropTypes.shape({
                name: PropTypes.string
            }),
            status: PropTypes.string
        }).isRequired
    ).isRequired
}

export default SelectedItemsList
