import * as constants from "constants/index"

function current(state = {}, action) {
    switch (action.type) {
        case constants.GET_BLOG_POST_START:
            return { ...state, isLoading: true, data: {} }
        case constants.GET_BLOG_POST_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data.data }
        case constants.GET_BLOG_POST_ERROR:
            return { ...state, isLoading: false, data: action.payload }
        case constants.CLEAR_BLOG_POST:
            return { ...state, isLoading: false, data: {} }

        default:
            return state
    }
}

export default current
