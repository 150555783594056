const TABLE_COLUMNS = {
    name: { minWidth: 200, width: "42%" },
    id: { minWidth: 60, width: "13%" },
    condition: { minWidth: 125, width: "14%" },
    origin: { minWidth: 100, width: "13%" },
    total: { minWidth: 154, width: "14%" },
    remove: { minWidth: 32, width: 32 }
}

export default TABLE_COLUMNS
