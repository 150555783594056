import fetch from "helpers/fetch"

const downloadXlsx = (path = "") => {
    const iframe = document.querySelector("iframe[data-pdf], a[data-pdf]")
    iframe && iframe.remove()

    fetch
        .getRAW(`/import/${path}`)
        .then(response => response.blob())
        .then(blob => {
            const newBlob = new Blob([blob], { type: "application/pdf" })

            let iFrame = document.createElement("iframe")
            iFrame.name = "pdfIframe"
            iFrame.setAttribute("data-pdf", true)
            iFrame.style = "visibility: hidden;"
            iFrame.src = URL.createObjectURL(newBlob)
            document.body.appendChild(iFrame)

            let a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            a.setAttribute("data-pdf", true)
            a.href = iFrame.src
            a.download = path + ".xlsx"
            a.click()
        })
}

export default downloadXlsx
