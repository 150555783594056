import React, { Fragment } from "react"
import { connect } from "react-redux"
import { Field } from "formik"

import withStyles from "HOC/withStyles"
import { Input, Toggle, SelectWithSearch } from "ui"

import toggleStyles from "../../overrides/toggle.css"
import inputStyles from "../../overrides/input.css"
import styles from "./styles.css"

const BillingAddress = props => {
    const { cx, form, countries, states } = props

    return (
        <div className={cx("section")}>
            <h2 className={cx("header")}>Billing Address</h2>
            {form.values.client ? (
                <Fragment>
                    {form.values.client && form.values.client.billing_address && (
                        <Field name="billing_address.copy">
                            {({ field }) => (
                                <Toggle
                                    isOn={field.value}
                                    label={{
                                        on: "Same as Client’s Billing Address",
                                        off: "Not same as Client’s Billing Address"
                                    }}
                                    labelPosition="left"
                                    handleChange={isOn => field.onChange({ target: { name: field.name, value: isOn } })}
                                    customStyles={toggleStyles}
                                />
                            )}
                        </Field>
                    )}

                    {(!form.values.billing_address || !form.values.billing_address.copy) && (
                        <ul className={cx("billingAddress")}>
                            <li className={cx("companyName")}>
                                <Field name="billing_address.company_name">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            label="Company name"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("firstName")}>
                                <Field name="billing_address.first_name">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            label="First name"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("lastName")}>
                                <Field name="billing_address.last_name">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            label="Last name"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("country")}>
                                <Field name="billing_address.country">
                                    {({ field }) => (
                                        <SelectWithSearch
                                            {...field}
                                            label="Country"
                                            values={countries.map(country => ({ label: country, value: country }))}
                                            setValue={({ value }) => {
                                                if (value !== field.value) {
                                                    form.setFieldValue("billing_address.state", "")
                                                    field.onChange({ target: { name: field.name, value } })
                                                }
                                            }}
                                            withoutFetch
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("address")}>
                                <Field name="billing_address.address">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            label="Address (Street and No.)"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("zipCode")}>
                                <Field name="billing_address.post_code">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            label="ZIP code"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("city")}>
                                <Field name="billing_address.city">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            label="City"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("state")}>
                                <Field name="billing_address.state">
                                    {({ field }) =>
                                        form.values.billing_address &&
                                        form.values.billing_address.country === "United States of America" ? (
                                            <SelectWithSearch
                                                {...field}
                                                label="State"
                                                values={states.map(states => ({ label: states, value: states }))}
                                                setValue={({ value }) =>
                                                    field.onChange({ target: { name: field.name, value } })
                                                }
                                                placeholder="- Please select -"
                                                withoutFetch
                                                isListOnTop
                                            />
                                        ) : (
                                            <Input
                                                {...field}
                                                label="State"
                                                onChange={({ event }) => field.onChange(event)}
                                                error={form.errors[field.name]}
                                                customStyles={inputStyles}
                                            />
                                        )
                                    }
                                </Field>
                            </li>
                        </ul>
                    )}
                </Fragment>
            ) : (
                <span className={cx("warning")}>
                    If you want to add a billing address, you have to select a client.
                </span>
            )}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        states: state.clientStore.statesList,
        countries: state.clientStore.countriesList
    }
}

export default connect(
    mapStateToProps,
    null
)(withStyles(BillingAddress, styles))
