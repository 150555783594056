import fetch from "helpers/fetch"

import useMutationFactory from "modules/WmsModule/hooks/factories/useMutationFactory"
import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"
import useInfiniteQueryListFactory from "modules/WmsModule/hooks/factories/useInfiniteQueryListFactory"

export const KEY = "users"

const fetchUsersList = ({ page = 1, length = 20, query = "", warehouses = [] }) =>
    fetch.get(`/user?page=${page}&length=${length}&query=${query}&warehouses=${warehouses.join(",")}`)

const fetchUsersInfiniteList = ({ pageParam = 1, queryKey: [_key, params = {}] }) => {
    const { length = 20, query = "" } = params
    return fetchUsersList({ page: pageParam, length, query })
}

const fetchUserEditAssignWarehouses = ({ id, warehouseIds }) =>
    fetch.patch(`/user/${id}`, { warehouses: warehouseIds.map(id => ({ id })) })

export const useUserWarehousesEdit = useMutationFactory({ key: KEY, fetchFunction: fetchUserEditAssignWarehouses })

export const useUserList = useQueryListFactory({
    key: KEY,
    fetchFunction: fetchUsersList
})

export const useUserInfiniteList = useInfiniteQueryListFactory({
    key: KEY,
    fetchFunction: fetchUsersInfiniteList
})
