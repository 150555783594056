import React, { useEffect } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"

import { Dropzone } from "ui"
import { createHintText } from "modules/ContentModule/helpers"

import cloudIcon from "./assets/cloud.svg"
import closeIcon from "assets/close-blue.svg"

import styles from "./styles.css"
import dropzoneStyles from "./overrides/dropzoneStyles.css"

const Image = ({
    cx,
    uploader: { state: uploadState, actions: uploadActions },
    setImage,
    image,
    isError,
    imageDimensions
}) => {
    useEffect(() => {
        if (idx(uploadState, _ => _.addedFile.url)) {
            setImage({
                id: uploadState.addedFile.id,
                name: uploadState.addedFile.title,
                url: uploadState.addedFile.url
            })
        }
    }, [uploadState.isUploading, uploadState.isSuccess, uploadState.addedFile])

    const removeImage = () => {
        setImage(null)
    }

    const renderUpladingState = progress => (
        <div className={cx("dropzoneStateUploading")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    )

    const renderEmptyState = () => (
        <Dropzone
            isUploading={uploadState.isUploading}
            isSuccess={uploadState.isSuccess}
            progress={uploadState.progress}
            handleUpload={uploadActions.handleUpload}
            handleRemove={uploadActions.handleRemove}
            isMultiple={false}
            customStyles={dropzoneStyles}
        >
            <p className={cx("dropzoneStateEmpty")}>
                <img src={cloudIcon} alt="cloud" />
                Add image <span>{createHintText(imageDimensions)}</span>
            </p>
        </Dropzone>
    )

    const renderImageState = () => (
        <div className={cx("dropzoneStateImage")}>
            <div className={cx("imageWrapper")}>{image.url && <img src={image.url} alt="preview" />}</div>
            <span>{image.name}</span>

            <button onClick={removeImage}>
                <img src={closeIcon} alt="close" />
            </button>
        </div>
    )

    return (
        <div
            className={cx("dropzoneWrapper", {
                dropzoneWrapperError: isError
            })}
        >
            {uploadState.isUploading
                ? renderUpladingState(uploadState.progress)
                : !!image
                ? renderImageState()
                : renderEmptyState()}
        </div>
    )
}

Image.propTypes = {
    uploader: PropTypes.object.isRequired,
    setImage: PropTypes.func.isRequired,
    image: PropTypes.object,
    isError: PropTypes.bool.isRequired,
    imageDimensions: PropTypes.shape({
        width: PropTypes.number
    })
}

export default withUploader(withStyles(Image, styles))
