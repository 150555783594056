import PropTypes from "prop-types"

const SHIPMENT_LIST_ITEM_PROP_TYPE = PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.shape({
        updated_at: PropTypes.string.isRequired
    }).isRequired,
    uuid: PropTypes.string.isRequired,
    order: PropTypes.shape({
        uuid: PropTypes.string
    }),
    shipment_items_count: PropTypes.number.isRequired,
    stock_items_ids: PropTypes.arrayOf(PropTypes.number).isRequired,
    returned_count: PropTypes.number,
    shipment_items_acceptance_counts: PropTypes.shape({
        rejected_items: PropTypes.number,
        accepted_items: PropTypes.number
    })
})

export default SHIPMENT_LIST_ITEM_PROP_TYPE
