import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    fetchStatus: fetchStatus.initialState()
}

function list(state = initialState, action) {
    switch (action.type) {
        case constants.GET_BRANDS_START:
            return { ...initialState, fetchStatus: fetchStatus.requestState() }
        case constants.GET_BRANDS_SUCCESS:
            return {
                data: action.payload.data || [],
                meta: action.payload.meta || {},
                fetchStatus: fetchStatus.successState()
            }
        case constants.GET_BRANDS_ERROR:
            return {
                data: [],
                meta: {},
                fetchStatus: {
                    ...fetchStatus.failureState(),
                    error: action.payload && action.payload.error
                }
            }
        default:
            return state
    }
}

export default list
