import React, { useState, useRef, useEffect, Fragment, useCallback } from "react"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import ReactSVG from "react-svg"
import get from "lodash/get"

import useFetch from "hooks/useFetch"
import print, { showPrintWindow } from "actions/print"
import fetch from "helpers/fetch"
import { updateOrder } from "actions/orders"
import { PERMISSIONS } from "constants/index"

import withStyles from "HOC/withStyles"
import withPermissions from "HOC/withPermissions"

import { Button, Dropdown } from "ui"
import Skeleton from "ui/Skeleton"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import { NotesSection } from "ui/Notes"
import { withListContext } from "modules/OrdersModule/components/List/ListContainer"
import { createLinkToEditPurchaseOrder } from "modules/OrdersModule/helpers/createLinkToPurchaseOrder"
import { checkIfCanEditPurchaseOrder } from "modules/OrdersModule/helpers/purchaseOrderEdit"

import GrayDetails from "./GrayDetails"
import TransparentDetails from "./TransparentDetails"
import ItemsTable from "./ItemsTable"
import PaymentsSection from "./PaymentsSection"
import Summary from "./Summary"

import styles from "./DocumentDetails.module.css"
import notesStyles from "./overrides/NotesSection.module.css"
import printIcon from "assets/print.svg"
import editIcon from "assets/pencil.svg"
import deleteIcon from "assets/close-blue.svg"
import printDropdownStyles from "./overrides/PrintDropdown.css"
import sendDropdownStyles from "./overrides/SendDropdown.css"

function DocumentDetails({ cx, documentId, uuid, hideItself, onDelete, listConfig, handleOpenModal, canDelete }) {
    const dispatch = useDispatch()
    const { documentEndpoint, abbreviation, name, notesType, printType, getSendableEmail } = listConfig
    const [documentData, setDocumentData] = useState({})
    const areValidProps = useRef(typeof documentId === "number" && typeof hideItself === "function").current
    const [fetchAction, fetchStatus] = useFetch({
        action: () => fetch.get(`/${documentEndpoint}/${documentId}`),
        onSuccess: ({ data }) => setDocumentData(data),
        onError: () => hideItself()
    })
    const isInvoice = abbreviation === "Invoice"

    const [printLoading, setPrintLoading] = useState(false)

    useEffect(() => {
        areValidProps && fetchAction()
    }, [])

    const onPaymentChange = useCallback(
        payments => {
            const { total } = documentData.calculations
            const applied = payments.reduce((sum, { amount }) => sum + Number(amount), 0)
            const balance = applied - total

            setDocumentData({
                ...documentData,
                calculations: { ...documentData.calculations, applied, balance }
            })

            if (documentData.order) {
                dispatch(updateOrder(documentData.order.id))
            }
        },
        [documentData]
    )

    if (!areValidProps) {
        return null
    }

    return (
        <Skeleton
            fetchStatus={fetchStatus}
            SkeletonComponent={() => (
                <div className={cx("root")}>
                    <h2 className={cx("title")}>
                        {name}
                        <span>{uuid}</span>
                    </h2>
                    <div className={cx("loader")}>
                        <SnakeLoader />
                    </div>
                </div>
            )}
            render={renderDetails}
        />
    )

    function handlePrint(templateType) {
        setPrintLoading(true)
        print(documentId, printType, templateType)
            .then(data => {
                showPrintWindow(data)
                fetchAction()
            })
            .finally(() => {
                setPrintLoading(false)
            })
    }

    function onNoteModification(noteData, method) {
        const oldNotes = documentData.notes || []
        const isMethodValid = ["add", "edit", "delete"].includes(method)

        if (!isMethodValid) {
            return
        }

        const newNotesGenerator = {
            add: () => [noteData, ...oldNotes],
            edit: () => oldNotes.map(note => (note.id === noteData.id ? { ...note, ...noteData } : note)),
            delete: () => oldNotes.filter(note => note.id !== noteData.id)
        }

        setDocumentData({
            ...documentData,
            notes: newNotesGenerator[method]()
        })
    }

    function renderDetails() {
        const {
            id,
            uuid,
            status,
            items,
            instructions,
            special_instruction,
            calculations,
            notes,
            payments,
            client,
            total_rent,
            order,
            is_any_item_received,
            warehouse,
            type
        } = documentData

        const clientEmails =
            typeof getSendableEmail === "function"
                ? getSendableEmail(documentData)
                : [client.email, client.alternative_email]

        return (
            <div className={cx("root")}>
                <h2 className={cx("title")}>
                    {name}
                    <span>{uuid}</span>
                </h2>

                <div className={cx("buttons")}>
                    {documentEndpoint === "purchase-orders" ? (
                        checkIfCanEditPurchaseOrder({ is_any_item_received, warehouse, type }) ? (
                            <Link
                                className={cx("editButton")}
                                to={createLinkToEditPurchaseOrder({
                                    orderId: get(order, "id", null),
                                    purchaseOrderId: id
                                })}
                            >
                                <img src={editIcon} alt="" />
                                Edit
                            </Link>
                        ) : (
                            <span className={cx("editButton", "disabled")}>
                                <img src={editIcon} alt="" />
                                Edit
                            </span>
                        )
                    ) : (
                        <Link className={cx("editButton")} to={`/orders/${documentEndpoint}/${id}/edit`}>
                            <img src={editIcon} alt="" />
                            Edit
                        </Link>
                    )}
                    {(documentEndpoint !== "invoices" || canDelete(PERMISSIONS.context.INVOICES)) && (
                        <Button
                            isDisabled={isInvoice && status !== "new"}
                            className={cx("deleteButton")}
                            icon={deleteIcon}
                            label="Delete"
                            onClick={() => onDelete(id)}
                        />
                    )}
                    <Dropdown
                        className={cx("printSelectDropdown")}
                        label={
                            <Fragment>
                                <ReactSVG src={printIcon} alt="Print" className={cx("printButtonIcon")} />
                                <span className={cx("printButtonLabel")}>
                                    {printLoading ? <SnakeLoader type="small" /> : <span>Print</span>}
                                </span>
                            </Fragment>
                        }
                        options={[
                            {
                                label: abbreviation === "PO" ? "Cover - No prices" : "With cover",
                                onClick: () => handlePrint("with_cover")
                            },
                            {
                                label: abbreviation === "PO" ? "With prices" : "Bigger pictures",
                                onClick: () => handlePrint("bigger_pictures")
                            },
                            {
                                label: abbreviation === "PO" ? "No cover - no prices" : "Smaller pictures",
                                onClick: () => handlePrint("smaller_pictures")
                            }
                        ]}
                        customStyles={printDropdownStyles}
                    />
                    <Dropdown
                        className={cx("sendButton")}
                        label={`Send ${abbreviation}`}
                        options={[
                            {
                                label: abbreviation === "PO" ? "Cover - No prices" : "With cover",
                                onClick: () =>
                                    handleOpenModal({
                                        id,
                                        uuid,
                                        clientEmails,
                                        reloadDocument: fetchAction,
                                        printTemplateType: "with_cover"
                                    })
                            },
                            {
                                label: abbreviation === "PO" ? "With prices" : "Bigger pictures",
                                onClick: () =>
                                    handleOpenModal({
                                        id,
                                        uuid,
                                        clientEmails,
                                        reloadDocument: fetchAction,
                                        printTemplateType: "bigger_pictures"
                                    })
                            },
                            {
                                label: abbreviation === "PO" ? "No cover - no prices" : "Smaller pictures",
                                onClick: () =>
                                    handleOpenModal({
                                        id,
                                        uuid,
                                        clientEmails,
                                        reloadDocument: fetchAction,
                                        printTemplateType: "smaller_pictures"
                                    })
                            }
                        ]}
                        customStyles={sendDropdownStyles}
                        arrowIconColor="white"
                    />
                </div>
                <GrayDetails cx={cx} documentData={documentData} />
                <TransparentDetails cx={cx} documentData={documentData} />
                <ItemsTable items={items} order={order} totalRent={total_rent} />
                <Fragment>
                    <Summary
                        cx={cx}
                        parentOrderType={get(order, "type", null)}
                        documentType={abbreviation}
                        instructions={instructions || special_instruction || null}
                        calculations={calculations}
                    />
                    {listConfig.abbreviation !== "PO" && (
                        <PaymentsSection
                            payments={payments}
                            calculations={calculations}
                            invoice={documentData}
                            onPaymentChange={onPaymentChange}
                        />
                    )}
                </Fragment>
                <NotesSection
                    notes={notes || []}
                    type={notesType}
                    id={id}
                    onNoteModification={onNoteModification}
                    customStyles={notesStyles}
                />
            </div>
        )
    }
}

DocumentDetails.propTypes = {
    uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    documentId: PropTypes.number.isRequired,
    hideItself: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
}

export default withPermissions(withListContext(withStyles(DocumentDetails, styles)))
