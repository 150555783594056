import fetch from "helpers/fetch"
import { serializeQuery } from "helpers/urls"
import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"

export const KEY = "orders"

const fetchOrdersList = ({
    type = "sales",
    sale_person_id = "",
    payments_from = "",
    payments_to = "",
    sort_by = "id",
    sort_direction = "asc",
    page = 1,
    length = 20
}) => {
    const queryString = serializeQuery(
        {
            type,
            sale_person_id,
            payments_from,
            payments_to,
            sort_by,
            sort_direction,
            page,
            length
        },
        "",
        true
    )
    return fetch.get(`/orders?${queryString}`)
}

export const useOrdersList = useQueryListFactory({
    key: KEY,
    fetchFunction: fetchOrdersList
})
