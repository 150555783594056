import REFUND_METHOD_KEYS from "./refundMethodKeys"

const REFUND_METHOD_OPTIONS = [
    {
        id: REFUND_METHOD_KEYS.CASH,
        label: "Cash return",
        value: REFUND_METHOD_KEYS.CASH
    },
    {
        id: REFUND_METHOD_KEYS.STORE_CREDIT,
        label: "Store credit",
        value: REFUND_METHOD_KEYS.STORE_CREDIT
    }
]

export default REFUND_METHOD_OPTIONS
