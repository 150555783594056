import React, { useMemo } from "react"
import withStyles from "HOC/withStyles"
import styles from "./AttributePicker.css"

import { SelectWithSearch } from "ui"
import customDropdownStyles from "./overrides/DropdownStyles.css"

import { useCombinationsCreator } from "hooks"

const AttributePicker = ({ cx, withWarningModal, blockInputs }) => {
    const [{ pickedAttributes, treeLevel, resources }, actions] = useCombinationsCreator()

    const lastAttribute = pickedAttributes[pickedAttributes.length - 1]
    const pickedAttributesIds = useMemo(() => pickedAttributes.map(({ value }) => value), [pickedAttributes])
    const filteredAttributes = useMemo(
        () => resources.attributes.data.filter(({ value }) => !pickedAttributesIds.includes(value)),
        [resources.attributes.data, pickedAttributesIds]
    )
    const showAddButton = !!lastAttribute.value && pickedAttributes.length !== resources.attributes.data.length
    const showRemoveButton = pickedAttributes.length > 1
    const [setAttribute, removeAttribute] = useMemo(() => {
        const treeWillBeResetAfterAttributeChange = pickedAttributes.length <= treeLevel
        return treeWillBeResetAfterAttributeChange
            ? [withWarningModal(actions.setAttribute), withWarningModal(actions.removeAttribute)]
            : [actions.setAttribute, actions.removeAttribute]
    }, [pickedAttributes, treeLevel])

    return (
        <div className={cx("root")}>
            {pickedAttributes.map((attribute, index, attributes) => {
                const isLastAttribute = index === attributes.length - 1
                return (
                    <SelectWithSearch
                        key={index}
                        value={attribute.label}
                        values={filteredAttributes}
                        placeholder="Choose attribute"
                        customStyles={customDropdownStyles}
                        setValue={setAttribute}
                        isBlocked={!isLastAttribute || blockInputs}
                        isClearBlocked
                        withoutFetch
                    />
                )
            })}
            {showRemoveButton && (
                <button type="button" disabled={blockInputs} className={cx("button")} onClick={removeAttribute}>
                    -
                </button>
            )}
            {showAddButton && (
                <button type="button" disabled={blockInputs} className={cx("button")} onClick={actions.addAttribute}>
                    +
                </button>
            )}
        </div>
    )
}

export default withStyles(AttributePicker, styles)
