import React, { Fragment, useState, useMemo, useEffect } from "react"
import getStyles from "helpers/getCxFromStyles"
import styles from "./styles.css"

import defaultImage from "assets/image.svg"
import ReactSVG from "react-svg"
import Modal from "components/Modal/Modal"
import GalleryModal from "components/Modal/Gallery/index"
import { ReloadableImageWrap } from "ui"

import Slider from "react-slick"
import GalleryArrow from "./components/GalleryArrow"

const ImageGallery = ({ images, activeImageId, setActiveImageId }) => {
    const cx = getStyles(styles)
    const [isGalleryModalOpen, setIsGalleryModalOpen] = useState(false)
    const slidesToShow = useMemo(() => (images.thumb.length > 3 ? 4 : images.thumb.length), [images.thumb])
    const sliderSettings = useMemo(
        () => ({
            dots: false,
            infinite: true,
            nextArrow: <GalleryArrow customClass={cx("slider-next", "slider-arrow")} />,
            prevArrow: <GalleryArrow customClass={cx("slider-prev", "slider-arrow")} />,
            slidesToScroll: 1,
            slidesToShow: slidesToShow,
            speed: 500,
            vertical: true,
            verticalSwiping: true
        }),
        [slidesToShow]
    )

    const activeImage = useMemo(
        () => images.preview.find(imagePrev => imagePrev.id === activeImageId) || { url: "", name: "" },
        [activeImageId, images]
    )

    const toggleShownGalleryModal = () => setIsGalleryModalOpen(!isGalleryModalOpen)

    useEffect(() => {
        if (images && images.preview.length > 0 && images.preview.findIndex(img => img.id === activeImageId) === -1) {
            setActiveImageId(images && images.preview.length > 0 ? images.preview[0].id : -1)
        }
    }, [images])

    return (
        <Fragment>
            <div className={cx("slider")}>
                <Slider {...sliderSettings}>
                    {images.thumb.map(({ id, url, name }) => (
                        <div key={id}>
                            <div
                                className={cx("thumbnail", { active: activeImageId === id })}
                                onClick={() => setActiveImageId(id)}
                            >
                                <ReloadableImageWrap image={url}>
                                    {({ image }) =>
                                        image ? (
                                            <img src={image} alt={name} />
                                        ) : (
                                            <ReactSVG className={cx("fallbackSvg")} src={defaultImage} />
                                        )
                                    }
                                </ReloadableImageWrap>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
            <div className={cx("image")}>
                {activeImage.url && (
                    <ReloadableImageWrap image={activeImage.url}>
                        {({ image }) =>
                            image ? (
                                <img src={image} alt={activeImage.name} onClick={toggleShownGalleryModal} />
                            ) : (
                                <ReactSVG className={`${cx("fallbackSvg")}`} src={defaultImage} />
                            )
                        }
                    </ReloadableImageWrap>
                )}
            </div>
            {isGalleryModalOpen && (
                <Modal>
                    <GalleryModal
                        handleHide={toggleShownGalleryModal}
                        images={images.gallery}
                        initImageIndex={images.gallery.findIndex(image => image.id === activeImageId)}
                    />
                </Modal>
            )}
        </Fragment>
    )
}

export default ImageGallery
