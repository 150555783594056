import { INPUT_MAX_LENGTH, TEXTAREA_MAX_LENGTH } from "constants/index"
import * as Yup from "yup"

const MIN_QUANTITY = 1
const MAX_QUANTITY = 999
const MIN_DISCOUNT_IN_PERCENTS = 0
const MAX_DISCOUNT_IN_PERCENTS = 100

export default Yup.object().shape({
    manufacturer_id: Yup.string()
        .max(INPUT_MAX_LENGTH, `Manufacture id may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .nullable(),
    note: Yup.string()
        .max(TEXTAREA_MAX_LENGTH, `Instruction may not be greater than ${TEXTAREA_MAX_LENGTH} characters`)
        .nullable(),
    wholesale_cost: Yup.number(),
    quantity: Yup.number()
        .min(MIN_QUANTITY, `Quantity may not be lower than ${MIN_QUANTITY}`)
        .max(MAX_QUANTITY, `Quantity may not be greater than ${MAX_QUANTITY}`),
    wholesale_discount: Yup.number()
        .min(MIN_DISCOUNT_IN_PERCENTS, `Discount may not be lower than ${MIN_DISCOUNT_IN_PERCENTS}`)
        .max(MAX_DISCOUNT_IN_PERCENTS, `Discount may not be greater than ${MAX_DISCOUNT_IN_PERCENTS}`)
        .nullable()
})
