import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import AddEditEventModal from "./AddEditEventModal"

const AddEditEventModalContainer = props => {
    const calendars = useSelector(state => state.db.calendar.list.data)
    const currentUser = useSelector(state => state.loginStore)

    const availableCalendarList = useMemo(
        () => [...calendars.public, ...calendars.private].filter(calendar => calendar.is_addable),
        [calendars]
    )
    const allCalendarList = useMemo(() => [...calendars.public, ...calendars.private], [calendars])

    return (
        <AddEditEventModal
            availableCalendarList={availableCalendarList}
            allCalendarList={allCalendarList}
            defaultDate={props.defaultDate}
            predefinedClient={props.predefinedClient}
            currentUser={currentUser}
            {...props}
        />
    )
}

export default AddEditEventModalContainer
