import * as fetchStatus from "helpers/fetchStatus"
import * as constants from "constants/index"

const initialState = {
    data: {},
    fetchStatus: fetchStatus.initialState(),
    roomsFetchStatus: fetchStatus.initialState()
}

function current(state = initialState, action) {
    if (constants.GET_PROJECT_START === action.type) {
        return {
            ...state,
            fetchStatus: fetchStatus.requestState(),
            roomsFetchStatus: fetchStatus.requestState()
        }
    }

    if (constants.GET_PROJECT_SUCCESS === action.type) {
        return {
            data: {
                ...action.payload.data.data,
                rooms: action.payload.data.data.rooms
            },
            fetchStatus: fetchStatus.successState(),
            roomsFetchStatus: fetchStatus.successState()
        }
    }

    if (constants.GET_PROJECT_ERROR === action.type) {
        return {
            data: {},
            fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null },
            roomsFetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
        }
    }

    if (constants.RESET_ROOMS_FETCH_STATUS === action.type) {
        return {
            ...state,
            roomsFetchStatus: fetchStatus.initialState()
        }
    }

    if ("RESET_CURRENT_PROJECT" === action.type) {
        return {
            ...initialState
        }
    }

    return state
}

export default current
