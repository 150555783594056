import * as yup from "yup"
import { INPUT_MAX_LENGTH } from "constants/index"

const validateConditionalParam = ({ testName, siblingParam }) =>
    yup
        .string()
        .test(testName, "Link or attachment is required", function(value) {
            return !this.parent[siblingParam] ? !!value : true
        })
        .nullable()

export const press = yup.object().shape({
    title: yup
        .string()
        .max(INPUT_MAX_LENGTH, `Title may not be longer than ${INPUT_MAX_LENGTH} characters`)
        .required("Press title is required"),
    subtitle: yup
        .string()
        .max(INPUT_MAX_LENGTH, `Subtitle may not be longer than ${INPUT_MAX_LENGTH} characters`)
        .nullable(),
    cover_desktop: yup.string("Cover image is required").required("Cover image is required"),
    hover_desktop: yup.string("Hover image is required").required("Hover image is required"),
    url: validateConditionalParam({ testName: "urlValidation", siblingParam: "attachment" }).url(),
    attachment: validateConditionalParam({ testName: "attachmentValidation", siblingParam: "url" })
})
