import React from "react"
import PropTypes from "prop-types"

import { fetchStatusPropTypes } from "helpers/fetchStatus"

import { Textarea } from "ui"
import AddEditForm, { Section, Aside } from "ui/AddEditForm"

import withStyles from "HOC/withStyles"
import styles from "./EmailSignature.css"

const signatureAvailableTags = [
    "first_name",
    "last_name",
    "full_name",
    "email",
    "city",
    "post_code",
    "state",
    "address",
    "country",
    "phone"
]

const Form = props => {
    const { cx, formik } = props
    const { values, handleChange } = formik

    return (
        <Section className={cx("form")} label="Default email signature">
            <Textarea
                className={cx("textarea")}
                name="defaultSignature"
                placeholder="Default email signature for all users"
                value={values.defaultSignature}
                onChange={({ event }) => handleChange(event)}
            />
        </Section>
    )
}

const AvailableTags = props => {
    const { cx } = props
    return (
        <Aside label="Available tags:">
            <ul>
                {signatureAvailableTags.map(tag => (
                    <li key={tag} className={cx("tag")}>
                        &lt;{tag}&gt;
                    </li>
                ))}
            </ul>
        </Aside>
    )
}

const EmailSignature = props => {
    const { cx, fetchStatus, isSubmitting, defaultSignature, onSubmit } = props

    return (
        <div className={cx("root", "round-border")}>
            <AddEditForm
                isSubmitting={isSubmitting}
                fetchStatus={fetchStatus}
                initialValues={{ defaultSignature }}
                onSubmit={onSubmit}
                config={{
                    defaultLeftColumn: formProps => <Form {...formProps} cx={cx} />,
                    defaultAside: formProps => <AvailableTags {...formProps} cx={cx} />
                }}
            />
        </div>
    )
}

EmailSignature.propTypes = {
    fetchStatus: fetchStatusPropTypes,
    isSubmitting: PropTypes.bool,
    defaultSignature: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default withStyles(EmailSignature, styles)
