import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import classNames from "classnames"

import {
    Panel,
    H2Counter,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    TableDateCell,
    SnakeLoader,
    useTableSort,
    TableSortableHeadCell,
    Button,
    DeleteIcon,
    AddIcon
} from "@butterfly-frontend/ui"

import { useStoreCreditList } from "modules/ClientModule/hooks/api"
import AddEditStoreCreditModal from "modules/OrdersModule/components/AddEditStoreCreditModal"
import { DeleteStoreCredit } from "modules/OrdersModule/components"
import { Dropdown } from "ui"
import { formatPrice } from "helpers/units"
import { formatDate } from "helpers/date"
import PaymentSummaryItem from "ui/PaymentSummaryItem"
import { useActions, useExportFile } from "hooks"
import { showErrorNotification } from "actions/notification"

import styles from "./StoreCredit.module.css"

const TABLE_COLUMNS = {
    amount: { minWidth: 120, width: "55%" },
    date: { minWidth: 100, width: "15%" },
    source: { minWidth: 80, width: "10%" },
    actions: { minWidth: 120, width: "20%" }
}

const StoreCredit = ({ clientId }) => {
    const [addEditStoreCreditModal, setAddEditStoreCreditModal] = useState({ isOpen: false, payment: null })
    const [paymentToDelete, setPaymentToDelete] = useState(null)
    const { onChangeSort, sortState } = useTableSort({ initialState: { by: "date", direction: "desc" } })

    const { data, fetchStatus, refetch, meta } = useStoreCreditList({
        params: {
            depositor_id: clientId,
            sort_by: sortState.by,
            sort_direction: sortState.direction
        }
    })

    const actions = useActions({ showErrorNotification })

    const [handleExport, isExporting] = useExportFile({
        exportData: { objectType: "store-credits", depositor_id: clientId },
        onError: actions.showErrorNotification
    })

    const dropdownElements = [
        { label: "Export to CSV", value: "csv", onClick: () => handleExport({ export: "csv" }) },
        { label: "Export to XLS", value: "xls", onClick: () => handleExport({ export: "xls" }) }
    ]

    return (
        <Panel classes={{ panel: styles.panel }}>
            <div className={styles.headerWrapper}>
                <H2Counter counterValue={data.length} classes={{ root: styles.header, h2: styles.headerText }}>
                    Store credit
                </H2Counter>
                <Dropdown
                    label={
                        <Fragment>
                            {isExporting ? (
                                <SnakeLoader
                                    classes={{
                                        root: styles.dropdownLoaderWrapper,
                                        loader: styles.dropdownLoader
                                    }}
                                />
                            ) : (
                                "Export all"
                            )}
                        </Fragment>
                    }
                    options={dropdownElements}
                    disabled={isExporting}
                    className={styles.exportAllDropdown}
                    onClick={handleExport}
                    isListOnRight
                />
                <Button
                    onClick={() => setAddEditStoreCreditModal({ payment: null, isOpen: true })}
                    Icon={AddIcon}
                    classes={{
                        button: styles.addNewButtonLabel
                    }}
                >
                    Add new
                </Button>
            </div>
            {fetchStatus.isLoading ? (
                <SnakeLoader classes={{ loader: styles.loader }} />
            ) : (
                fetchStatus.isLoaded && (
                    <Fragment>
                        <div className={styles.summaryWrapper}>
                            <PaymentSummaryItem amount={meta.calculations.total_store_credits} label="Store credit" />
                            <PaymentSummaryItem amount={meta.calculations.refunds} label="Return" />
                            <PaymentSummaryItem amount={meta.calculations.total} label="Total amount" color="orange" />
                        </div>
                        <Table>
                            <TableHead>
                                <TableSortableHeadCell
                                    sortState={sortState}
                                    onClick={onChangeSort}
                                    columnKey="amount"
                                    {...TABLE_COLUMNS.amount}
                                >
                                    AMOUNT
                                </TableSortableHeadCell>
                                <TableSortableHeadCell
                                    sortState={sortState}
                                    onClick={onChangeSort}
                                    columnKey="date"
                                    {...TABLE_COLUMNS.date}
                                >
                                    DATE
                                </TableSortableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.source}>SOURCE</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.actions} />
                            </TableHead>
                            <TableBody>
                                {data.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            {...TABLE_COLUMNS.amount}
                                            classes={{
                                                root: classNames([
                                                    styles.amountCell,
                                                    { [styles.redFont]: row.store_credit_payment }
                                                ])
                                            }}
                                        >
                                            {formatPrice(row.store_credit_payment ? -row.amount : row.amount)}
                                        </TableCell>
                                        <TableDateCell {...TABLE_COLUMNS.date}>{formatDate(row.date)}</TableDateCell>
                                        <TableCell {...TABLE_COLUMNS.source}>
                                            {!!row.invoice ? (
                                                <Link
                                                    to={`/orders/invoices/${row.invoice.id}/edit`}
                                                    className={styles.link}
                                                >
                                                    {row.invoice.uuid}
                                                </Link>
                                            ) : row.refund && row.return_shipment && row.return_shipment.uuid ? (
                                                <Link
                                                    to={`/orders/returns?query=${row.return_shipment.uuid}`}
                                                    className={styles.link}
                                                >
                                                    {row.return_shipment.uuid}
                                                </Link>
                                            ) : (
                                                "Store Credit"
                                            )}
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.actions} classes={{ root: styles.actionsCell }}>
                                            <Button
                                                classes={{
                                                    content: styles.actionButtonLabel,
                                                    icon: styles.actionButtonIcon
                                                }}
                                                Icon={DeleteIcon}
                                                variant="flat"
                                                onClick={() => setPaymentToDelete(row)}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Fragment>
                )
            )}
            {addEditStoreCreditModal.isOpen && (
                <AddEditStoreCreditModal
                    clientId={clientId}
                    payment={addEditStoreCreditModal.payment}
                    handleClose={() => setAddEditStoreCreditModal(state => ({ ...state, isOpen: false }))}
                />
            )}
            {paymentToDelete && (
                <DeleteStoreCredit
                    storeCredit={paymentToDelete}
                    onClose={() => {
                        refetch()
                        setPaymentToDelete(null)
                    }}
                />
            )}
        </Panel>
    )
}

StoreCredit.propTypes = {
    clientId: PropTypes.number.isRequired
}

export default StoreCredit
