import React, { useMemo } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import * as fetchStatusHelper from "helpers/fetchStatus"
import { Quantity, CombinationAttributeSelect } from "ui"
import { SnakeLoader, Button } from "@butterfly-frontend/ui"
import Skeleton from "components/Skeleton"
import { COMBINATION_ATTRIBUTES_PROP_TYPE } from "propTypes/combinationPropType"

import styles from "./Combinations.module.css"
import quantityStyles from "./overrides/Quantity.css"

const Combinations = props => {
    const {
        product,
        combination,
        quantity,
        handleChangeQuantity,
        handleChangeAttribute,
        handleSelectProductCombination,
        itemsState,
        handleChangeIsLoading,
        clearSelectedProduct,
        isFetchingCombination,
        productAttributes
    } = props

    const hasCombinations = useMemo(
        () => idx(product, _ => _.data.has_combinations) && (idx(product, _ => _.data.attributes) || []).length,
        [product.data]
    )

    return (
        <Skeleton
            fetchStatus={fetchStatusHelper.fromResource(product)}
            fallback={() => <div>An error occurred, please try again.</div>}
            component={<SnakeLoader />}
            render={() => {
                if (!hasCombinations) {
                    clearSelectedProduct()
                }

                return (
                    <div>
                        <div className={styles.attributeListContainer}>
                            {productAttributes.map(attribute => (
                                <CombinationAttributeSelect
                                    {...attribute}
                                    key={attribute.id}
                                    selectAttribute={handleChangeAttribute}
                                    zoomDirection="right"
                                />
                            ))}
                            <Quantity
                                customStyles={quantityStyles}
                                value={quantity}
                                min={1}
                                onChange={handleChangeQuantity}
                            />
                        </div>

                        <footer className={styles.footer}>
                            <div>
                                <Button onClick={clearSelectedProduct}>Back</Button>
                            </div>
                            <div className={styles.rightSide}>
                                <Button
                                    disabled={!combination}
                                    isLoading={
                                        isFetchingCombination || idx(itemsState, _ => _[product.data.id].isLoading)
                                    }
                                    onClick={() => {
                                        handleChangeIsLoading(product.data.id, true)
                                        handleSelectProductCombination({
                                            ...product.data,
                                            product_id: product.data.id,
                                            combination_id: combination.id,
                                            combination,
                                            quantity
                                        })
                                    }}
                                >
                                    Choose an option
                                </Button>
                            </div>
                        </footer>
                    </div>
                )
            }}
        />
    )
}

Combinations.propTypes = {
    product: PropTypes.shape({
        data: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }).isRequired
    }),
    combination: PropTypes.shape({
        id: PropTypes.number.isRequired
    }),
    quantity: PropTypes.number.isRequired,
    handleChangeQuantity: PropTypes.func.isRequired,
    handleChangeAttribute: PropTypes.func.isRequired,
    clearSelectedProduct: PropTypes.func.isRequired,
    handleSelectProductCombination: PropTypes.func.isRequired,
    itemsState: PropTypes.objectOf(
        PropTypes.shape({
            isLoading: PropTypes.bool
        })
    ),
    handleChangeIsLoading: PropTypes.func.isRequired,
    isFetchingCombination: PropTypes.bool,
    productAttributes: COMBINATION_ATTRIBUTES_PROP_TYPE
}

export default Combinations
