import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import fetch from "helpers/fetch"

import * as companyActions from "actions/company"

import { EditableList } from "ui"

import ListingLayout from "../../OtherListingLayout/index"

const Listing = () => {
    const history = useHistory()
    const dispatch = useDispatch()

    const getCompanies = () => dispatch(companyActions.getCompanies())

    const companies = useSelector(state => state.db.companies.list.data)
    const isLoading = useSelector(state => state.db.companies.list.isLoading)

    useEffect(() => {
        getCompanies()
    }, [])

    const handleAdd = () => {
        history.push("/settings/divisions/add")
    }

    const handleEdit = item => {
        history.push(`/settings/divisions/card/${item.id}/edit`)
    }

    const handleDelete = item => {
        fetch.deleteRAW(`/companies/${item.id}`).then(() => {
            getCompanies()
        })
    }

    const columns = [
        {
            name: "name",
            field: "name"
        }
    ]

    return (
        <ListingLayout>
            <EditableList
                title="Divisions"
                isLoading={isLoading}
                columns={columns}
                data={companies}
                dataModel="Companies"
                fetchData={getCompanies}
                isIndexColumn
                onAdd={handleAdd}
                onEdit={handleEdit}
                onDelete={handleDelete}
                confirmModalTitle="Are you sure you want to delete this company?"
            />
        </ListingLayout>
    )
}

export default Listing
