import React from "react"

import withStyles from "HOC/withStyles"

import Layout from "components/Reusable/Layout"
import Room from "./components/Room"

import styles from "./Rooms.css"
import layoutStyles from "../../overrides/Layout.css"

export const Rooms = ({ cx, project, getProject }) => {
    const productsCount = project.rooms.reduce((acc, room) => {
        return acc + (room.items || []).length
    }, 0)

    let lastProductNo = 1

    return (
        <div className={cx("root")}>
            <Layout customStyles={layoutStyles}>
                <h2>
                    Products <span>{productsCount}</span>
                </h2>

                {project.rooms.map(room => {
                    const options = (project.options || []).filter(item => item.room_id === room.id)[0]
                    const updatedRoom = (options || {}).naming_visibility
                        ? room
                        : {
                              ...room,
                              items: room.items.map(roomItem => ({
                                  ...roomItem,
                                  fake_name: `Product ${lastProductNo++}`
                              }))
                          }

                    return <Room key={room.id} room={updatedRoom} options={options} getProject={getProject} />
                })}
            </Layout>
        </div>
    )
}

export default withStyles(Rooms, styles)
