import React from "react"

import withUploader from "HOC/withUploader"
import { Dropzone } from "ui"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"

import dropzoneStyles from "./overrides/dropzoneStyles.css"

const DropzoneWithUpload = withUploader(props => {
    const {
        uploader: { actions, state }
    } = props

    return (
        <Dropzone
            {...props}
            isUploading={state.isUploading}
            isSuccess={state.isSuccess}
            progress={state.progress}
            handleUpload={actions.handleUpload}
            handleRemove={actions.handleRemove}
            customStyles={dropzoneStyles}
        >
            {props.children(state)}
        </Dropzone>
    )
})

DropzoneWithUpload.defaultProps = {
    endpoint: DEFAULT_FILE_UPLOAD_ENPOINT
}

export default DropzoneWithUpload
