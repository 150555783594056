import * as constants from "constants/index"

function users(state = {}, action) {
    switch (action.type) {
        case constants.GET_USERS_LIST_DATA_START:
            return { ...state, isLoading: true, data: {} }
        case constants.GET_USERS_LIST_DATA_SUCCESS:
            return { ...state, isLoading: false, list: action.payload.data.data }
        case constants.GET_USERS_LIST_DATA_ERROR:
            return { ...state, isLoading: false, data: {} }

        default:
            return state
    }
}

export default users
