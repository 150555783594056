import React from "react"
import arrowIcon from "assets/arrow_down_blue.svg"
import clearIcon from "assets/close-blue.svg"

function Button(props) {
    const { cx, isExpanded, handler, selected, isDisabled } = props

    const isClear = selected && handler

    const icon = isClear ? clearIcon : arrowIcon

    const handle = event => {
        if (isClear && !isDisabled) {
            event.stopPropagation()
            handler()
        }
    }

    return (
        <button className={cx("btn", { disabled: isDisabled })} onClick={handle} type="button">
            <img src={icon} className={cx("ico", { rotated: isExpanded && !isClear, isClear })} alt="" />
        </button>
    )
}

export default Button
