import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import Modal from "components/Modal/Modal"
import ReviewModal from "components/Reusable/ReviewModal"

import { useStockItemReviewUpdate } from "modules/WmsModule/hooks/api/useStockItem"
import * as notificationActions from "actions/notification"

const StockItemsReview = ({ fileData, onClose, onSuccess }) => {
    const dispatch = useDispatch()
    const [isSaving, setIsSaving] = useState(false)
    const stockItemReviewUpdate = useStockItemReviewUpdate()

    const handleSave = spreadsheetId => {
        setIsSaving(true)
        return stockItemReviewUpdate
            .mutate({ spreadsheetId })
            .then(() => {
                onSuccess()
                dispatch(notificationActions.showSuccessNotification())
            })
            .catch(error => {
                setIsSaving(false)
                dispatch(notificationActions.showErrorNotification(error.message))
            })
    }

    return (
        <Modal>
            <ReviewModal handleHide={onClose} fileData={fileData} isSaving={isSaving} handleSave={handleSave} />
        </Modal>
    )
}

StockItemsReview.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    fileData: PropTypes.shape({
        id: PropTypes.number.isRequired,
        rows: PropTypes.number.isRequired,
        spreadsheet: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    }).isRequired
}

export default StockItemsReview
