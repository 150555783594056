export default (stockItemBoxSku = "") => {
    const [brandNameShortcut, brandIdHex, stockItemId, boxNumber] = stockItemBoxSku.split("-")

    return {
        sku: stockItemBoxSku,
        brandNameShortcut,
        brandId: brandIdHex ? parseInt(brandIdHex, 16) : undefined,
        stockItemId: stockItemId ? parseInt(stockItemId, 10) : undefined,
        boxNumber: boxNumber ? parseInt(boxNumber, 10) : undefined
    }
}
