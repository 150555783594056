import fetch from "helpers/fetch"
import { useSelectData } from "ui/Filters/hooks"
import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"

export const conditionToValue = condition => ({
    id: condition.id,
    label: condition.name,
    color: condition.color,
    type: [CONDITION_TYPES.DAMAGED, CONDITION_TYPES.REMOVED].includes(condition.type) ? "danger" : condition.type,
    secondLabel: "",
    value: condition,
    theme: condition.type === CONDITION_TYPES.INCOMPLETE ? "inactiveFill" : undefined
})

const fetchSelectData = ({ queryKey: [_key, params] }) => {
    const { conditionType, excludedTypes = [] } = params

    return fetch.get(
        `/product-conditions?page=1&length=9999&type=${conditionType || ""}&excluded_types=${excludedTypes.join(",")}`
    )
}

const useSelectProductConditions = ({ conditionType, setValue, excludedTypes = [] } = {}) => {
    const {
        selectData,
        values,
        fetchValues,
        isLoading,
        error,
        propsForSelectWithSearch,
        selectDataQuery
    } = useSelectData({
        key: "conditions",
        params: { conditionType, excludedTypes },
        fetchSelectData,
        selectOptionsMapper: conditionToValue,
        setValue
    })

    return { selectData, values, fetchValues, isLoading, error, propsForSelectWithSearch, selectDataQuery }
}

export default useSelectProductConditions
