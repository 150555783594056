import * as Yup from "yup"

import { escapeAmountValue } from "helpers/units"

const schema = Yup.object().shape({
    amount: Yup.string()
        .transform(escapeAmountValue)
        .required("This field is required and must be a number."),
    date: Yup.string().required("This field is required."),
    payment_method: Yup.object().test({
        message: "This field is required",
        test: value => value && value.id && value.name
    }),
    note: Yup.string().nullable()
})

export default schema
