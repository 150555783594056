export const createLinkToNewPurchaseOrder = ({ orderId, itemIds }) => ({
    pathname: `/orders/sales-orders/${orderId}/purchase-orders/add`,
    state: { itemIds }
})

export const createLinkToEditPurchaseOrder = ({ orderId, purchaseOrderId }) =>
    orderId
        ? `/orders/sales-orders/${orderId}/purchase-orders/${purchaseOrderId}/edit`
        : `/orders/purchase-orders/${purchaseOrderId}/edit`

export const createLinkToPurchaseOrderListInSO = ({ orderId, forceFetchOrder = false }) => ({
    pathname: `/orders/sales-orders/${orderId}/purchase-orders`,
    state: {
        forceFetchOrder
    }
})

export const createLinkToPurchaseOrderList = () => ({
    pathname: `/orders/purchase-orders`
})
