import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import { formatPrice } from "helpers/units"
import styles from "./PaymentSummaryItem.module.css"

const PaymentSummaryItem = ({ amount, color = "default", cx, label, LabelComponent, type }) => {
    return (
        <div className={cx("wrapper")}>
            <div className={`${cx("box")} ${cx(type)} ${cx(color)}`}>
                <p className={cx("label")}>{!!LabelComponent ? <LabelComponent /> : label}</p>
                <p className={cx("amount")}>{formatPrice(amount) || "$ 0"}</p>
            </div>
        </div>
    )
}

PaymentSummaryItem.propTypes = {
    color: "default"
}

PaymentSummaryItem.propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    color: PropTypes.oneOf(["default", "green", "red", "orange"]),
    label: PropTypes.string,
    LabelComponent: PropTypes.func,
    type: PropTypes.string
}

export default withStyles(PaymentSummaryItem, styles)
