import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import ReactSVG from "react-svg"

import { Checkbox, DropList } from "ui"
import DiscountLabel from "../DiscountLabel"
import SoldLabel from "../SoldLabel"

import withStyles from "HOC/withStyles"
import { formatPriceByComa } from "helpers/units"

import styles from "./Product.css"
import checkboxStyles from "./overrides/checkbox.css"
import droplistStyles from "./overrides/droplist.css"

import placeholderImage from "./assets/placeholder.svg"
import selectionIcon from "assets/ico-lightbox-add.svg"
import closeIcon from "assets/close-blue.svg"

import AddToSelectionButtonContainer from "modules/SelectionsWidgetModule/components/AddToSelectionButtonContainer"

const Product = props => {
    const {
        cx,
        product,
        isChecked,
        checkProduct,
        children,
        addToSelection,
        deleteProduct,
        editProduct,
        canAddToSelection,
        canDelete,
        ...rest
    } = props

    const id = product.id
    const isSold = !product.reorderable && !product.quantity

    if (typeof children === "function") {
        return children({
            ...rest,
            isChecked,
            product,
            checkProduct,
            getImagePath,
            displayName,
            displayBrand,
            renderPrice,
            shortenString,
            addToSelection,
            deleteProduct,
            editProduct
        })
    }

    return (
        <div className={cx("root")}>
            <div className={cx("checkbox-container")}>
                <Checkbox
                    checked={isChecked}
                    onChange={() => checkProduct(product, !isChecked)}
                    customStyles={checkboxStyles}
                />
            </div>
            {renderDropList()}
            {renderActionDelete()}
            <Link to={`/products/${id}`}>
                <div className={cx("photo-cover")}>
                    <span>{renderImage()}</span>
                </div>
                <div className={cx("discount-wrapper")}>
                    {renderDiscount()}
                    {renderSoldLabel()}
                </div>
                <h4 className={cx("brand")}>{displayBrand()}</h4>
                <p className={cx("description")}>
                    {renderName()}
                    {renderDescription()}
                </p>
                <p className={cx("price")}>{renderPrice(true)}</p>
            </Link>
        </div>
    )

    function getImagePath() {
        const { media } = product

        return media && media.url ? `${media.url}?type=listing` : placeholderImage
    }

    function renderImage() {
        return <img src={getImagePath()} alt="product" className={cx("photo")} />
    }

    function displayName() {
        const name = product.name || ""

        return name
    }

    function renderDescription() {
        const { nameMaxLength } = props
        const name = displayName()

        if (!name) {
            return null
        }

        if (!(name.length > nameMaxLength)) {
            return null
        }

        return renderSpanWithShy({ text: name, props: { className: "tooltip" } })
    }

    function renderName() {
        const { nameMaxLength } = props
        const name = displayName()

        return shortenString(name, nameMaxLength)
    }

    function renderPrice(withDiscount = false) {
        const { has_combinations: hasCombinations, lowest_price: lowestPrice, discount } = product
        const priceFactor = withDiscount && discount ? (100 - discount) / 100 : 1
        const price = lowestPrice * priceFactor

        const priceToShow = price || 0

        return (
            <Fragment>
                {hasCombinations && "from"}{" "}
                <strong>
                    $&nbsp;&nbsp;{formatPriceByComa(priceToShow, true)}.{formatPriceByComa(priceToShow, false)}
                </strong>
            </Fragment>
        )
    }

    function displayBrand() {
        const brand = product.brand || ""

        return brand
    }

    function shortenString(name = "", maxLength = 40, endOfShortenStringSymbol = "...") {
        if (name && name.length > maxLength) {
            return renderSpanWithShy({ text: name.slice(0, maxLength) + endOfShortenStringSymbol })
        }

        return renderSpanWithShy({ text: name })
    }

    function renderSpanWithShy({ text, props = {} }) {
        return (
            <span
                {...props}
                dangerouslySetInnerHTML={{
                    __html: text && text.replace(/_/gi, "_&shy;")
                }}
            />
        )
    }

    function renderDiscount() {
        return !isSold && !!product.discount && <DiscountLabel discount={product.discount} />
    }

    function renderSoldLabel() {
        return isSold && <SoldLabel />
    }

    function renderDropList() {
        if (!canAddToSelection) {
            return null
        }

        return (
            <div className={cx("moreActions")}>
                <AddToSelectionButtonContainer product={product}>
                    {({ handleClick, isInSelection, lock }) => (
                        <div
                            className={cx("selectionsButton", { active: isInSelection })}
                            onClick={() => handleClick()}
                        >
                            <ReactSVG src={selectionIcon} className={cx("selectionsButtonIcon")} />
                        </div>
                    )}
                </AddToSelectionButtonContainer>
                <div className={cx("devider")} />
                <DropList
                    customStyles={droplistStyles}
                    trigger={
                        <div className={cx("moreActionsButton")}>
                            <span />
                            <span />
                            <span />
                        </div>
                    }
                >
                    <DropList.List customStyles={droplistStyles}>
                        <DropList.Item onClick={() => editProduct(id)}>Edit</DropList.Item>
                        {!product.deleted_at && (
                            <Fragment>
                                <DropList.Divider />
                                <DropList.Item className="danger" onClick={() => deleteProduct(id)}>
                                    Delete
                                </DropList.Item>
                            </Fragment>
                        )}
                    </DropList.List>
                </DropList>
            </div>
        )
    }

    function renderActionDelete() {
        if (!canDelete) {
            return null
        }

        return (
            <div className={cx("moreActions", "deleteAction")}>
                <div className={cx("buttonDelete")} onClick={() => deleteProduct(id)}>
                    <ReactSVG src={closeIcon} className={cx("buttonDeleteIcon")} />
                </div>
            </div>
        )
    }
}

Product.defaultProps = {
    nameMaxLength: 40,
    canAddToSelection: true,
    canDelete: false
}

export default withStyles(Product, styles)
