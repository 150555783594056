import React from "react"
import PropTypes from "prop-types"
import { Panel, DetailsCard } from "@butterfly-frontend/ui"

import styles from "./StockAdjustmentDetailsDescription.module.css"

const StockAdjustmentDetailsDescription = ({ description }) => (
    <div className={styles.container}>
        <Panel>
            <DetailsCard header="Description" classes={{ root: styles.descriptionCard }}>
                {description}
            </DetailsCard>
        </Panel>
    </div>
)

StockAdjustmentDetailsDescription.propTypes = {
    description: PropTypes.string.isRequired
}

export default StockAdjustmentDetailsDescription
