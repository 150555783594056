import React from "react"
import PropTypes from "prop-types"

import { Input } from "ui"

import styles from "./CarrierSection.module.css"
import inputStyles from "./Input.module.css"

const CarrierSection = ({
    onChangeCarrier,
    onChangeTrackingNumber,
    carrier,
    trackingNumber,
    carrierError,
    trackingNumberError
}) => (
    <div className={styles.root}>
        <Input
            label="Carrier"
            value={carrier}
            onChange={({ event }) => onChangeCarrier(event.target.value)}
            customStyles={inputStyles}
            error={carrierError}
        />
        <Input
            label="Tracking number"
            value={trackingNumber}
            onChange={({ event }) => onChangeTrackingNumber(event.target.value)}
            customStyles={inputStyles}
            error={trackingNumberError}
        />
    </div>
)

CarrierSection.propTypes = {
    carrier: PropTypes.string,
    carrierError: PropTypes.string,
    trackingNumber: PropTypes.string,
    trackingNumberError: PropTypes.string,
    onChangeCarrier: PropTypes.func.isRequired,
    onChangeTrackingNumber: PropTypes.func.isRequired
}

export default CarrierSection
