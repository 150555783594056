import React from "react"
import PropTypes from "prop-types"
import { Checkbox } from "@butterfly-frontend/ui"

import { Radio, Toggle } from "ui"
import { FormSection } from "modules/WmsModule/modals/components"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import styles from "./ManagerActivitySectionForm.module.css"
import toggleStyles from "./Toggle.module.css"

const ManagerActivitySectionForm = ({ formik, checkPermission, canEdit, isEdit }) => {
    const canEditDefaultForPO = checkPermission(PERMISSIONS.types.SELECT_DEFAULT_FOR_PO)

    return (
        <FormSection title="Warehouse management">
            {() => (
                <div className={styles.section}>
                    <div className={styles.row}>
                        <h3>Warehouse status:</h3>
                        <Radio
                            label="Active"
                            name="active"
                            value={true}
                            handleSelect={formik.setFieldValue}
                            className={styles.radio}
                            checked={formik.values.active}
                            isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                        />
                        <Radio
                            label="Inactive"
                            name="active"
                            value={false}
                            handleSelect={formik.setFieldValue}
                            className={styles.radio}
                            checked={!formik.values.active}
                            isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                        />
                    </div>
                    <div className={styles.row}>
                        <h3>Purpose of warehouse:</h3>
                        <div className={styles.insideRow}>
                            <Checkbox
                                id="autonomous"
                                label="Autonomous"
                                checked={formik.values.autonomous}
                                onChange={value => formik.setFieldValue(value, !formik.values.autonomous)}
                                classes={{ checkbox: styles.autonomousCheckbox }}
                                disabled={formik.isSubmitting || (isEdit && !canEdit())}
                            />
                            <Toggle
                                isOn={formik.values.is_default_for_po}
                                label={{ on: "Default destination for PO", off: "Default destination for PO" }}
                                handleChange={value => formik.setFieldValue("is_default_for_po", value)}
                                labelPosition="left"
                                isSmall
                                customStyles={toggleStyles}
                                isDisabled={
                                    formik.isSubmitting ||
                                    formik.initialValues.is_default_for_po ||
                                    !canEditDefaultForPO
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </FormSection>
    )
}

ManagerActivitySectionForm.propTypes = {
    formik: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool,
        values: PropTypes.shape({
            active: PropTypes.bool,
            is_default_for_po: PropTypes.bool,
            autonomous: PropTypes.bool
        }),
        errors: PropTypes.shape({
            active: PropTypes.string
        })
    }),
    checkPermission: PropTypes.func.isRequired,
    isEdit: PropTypes.bool,
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(ManagerActivitySectionForm, PERMISSIONS.context.WMS)
