const SHIPMENT_STATUS_KEYS = {
    PACKED: "packed",
    SENT: "sent",
    DELIVERED: "delivered",
    IN_PROGRESS: "in_progress",
    ON_HOLD: "on_hold",
    CANCELLED: "cancelled"
}

export default SHIPMENT_STATUS_KEYS
