import React, { useMemo } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useFormik } from "formik"
import _get from "lodash/get"
import { Panel, H1 } from "@butterfly-frontend/ui"

import fetch from "helpers/fetch"
import { SaveBar, Textarea } from "ui"
import { BoxListForm, ProductConditionForm } from "modules/WmsModule/components"
import apiErrorsAdapter from "modules/WmsModule/helpers/apiErrorsAdapter"
import { useStockItemCreate } from "modules/WmsModule/hooks/api/useStockItem"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import AddNewItemBasicInfoForm from "./AddNewItemBasicInfoForm"
import DimensionsForm from "./DimensionsForm"
import InventoryForm from "./InventoryForm"
import CombinationForm from "./CombinationForm"
import initialValues from "./initialValues"
import validationSchema from "./validationSchema"
import styles from "./StockItemAddPage.module.css"

const valuesToApi = values => {
    const {
        product,
        brand,
        description,
        length,
        width,
        height,
        weight,
        warehouse,
        location,
        condition,
        status,
        conditionImages,
        boxes,
        combination_id,
        addStockItemFromExistingProducts,
        quantity
    } = values

    const location_id = _get(location, "id")

    return {
        name: addStockItemFromExistingProducts ? undefined : product,
        product_id: addStockItemFromExistingProducts ? product.id : undefined,
        brand,
        description,
        height,
        width,
        weight,
        length,
        brand_id: _get(brand, "value"),
        warehouse_id: _get(warehouse, "value"),
        location_id,
        boxes: boxes.map(box => ({ ...box, location_id })),
        condition: {
            product_condition_id: _get(condition, "value.id"),
            images_ids: quantity > 1 ? [] : conditionImages.map(image => image.id)
        },
        status: {
            name: _get(status, "value")
        },
        combination_id,
        quantity
    }
}

const getApiErrors = errors => {
    const {
        condition,
        status,
        brand_id: brand,
        warehouse_id: warehouse,
        location_id: location,
        boxes,
        ...restApiErrors
    } = apiErrorsAdapter(errors)

    return {
        condition: _get(condition, "product_condition_id"),
        status: _get(status, "name"),
        brand,
        location: _get(location, "name"),
        boxes,
        warehouse,
        ...restApiErrors
    }
}

const fetchCombinationByAttributeValues = (productId, selectedCombinationAttributes = {}) => {
    const selectedAttributesIds = Object.values(selectedCombinationAttributes)
        .map(attribute => _get(attribute, "value.id"))
        .join(",")

    return fetch.get(`/combinations/find?productId=${productId}&attributeValueIds=${selectedAttributesIds}`)
}

const StockItemAddPage = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const redirectToStockManagement = () => history.push("/wms/stock-management")

    const stockItemCreate = useStockItemCreate()

    const onSubmit = async (values, { setErrors, setFieldError }) => {
        const { product, selectedCombinationAttributes } = values

        let combination_id

        try {
            if (_get(product, "value.has_combinations")) {
                const combination = await fetchCombinationByAttributeValues(product.id, selectedCombinationAttributes)
                combination_id = _get(combination, "data.id")
            }
        } catch (err) {
            setFieldError("combination", "The selected combination is invalid")
            return
        }

        return stockItemCreate
            .mutate({ data: valuesToApi({ ...values, combination_id }) })
            .then(() => {
                dispatch(showSuccessNotification({ title: "Item added successfully" }))
                redirectToStockManagement()
            })
            .catch(error => {
                const apiErrors = _get(error, "errors", null)
                apiErrors ? setErrors(getApiErrors(apiErrors)) : dispatch(showErrorNotification())
            })
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    const { addStockItemFromExistingProducts, product } = formik.values
    const showCombinationForm = useMemo(
        () => addStockItemFromExistingProducts && _get(product, "value.has_combinations"),
        [addStockItemFromExistingProducts, product]
    )

    return (
        <div className={styles.root}>
            <Panel>
                <div className={styles.panelContainer}>
                    <H1 withLeftBorder>New item</H1>
                    <form onSubmit={formik.handleSubmit} noValidate>
                        <AddNewItemBasicInfoForm formik={formik} />
                        {showCombinationForm && <CombinationForm productId={product.id} formik={formik} />}
                        <div className={styles.descriptionSection}>
                            <Textarea
                                label="Description"
                                className={styles.textarea}
                                name="description"
                                placeholder="Click here to add a description..."
                                value={formik.values.description}
                                error={formik.errors.description}
                                onBlur={formik.handleBlur}
                                onChange={({ event }) => formik.handleChange(event)}
                                isDisabled={formik.isSubmitting}
                            />
                        </div>
                        {!addStockItemFromExistingProducts && <DimensionsForm formik={formik} />}
                        <InventoryForm formik={formik} />
                        <BoxListForm formik={formik} />
                        <ProductConditionForm
                            formik={formik}
                            onlyImages
                            addingImagesDisabled={formik.isSubmitting || formik.values.quantity > 1}
                        />
                        <SaveBar
                            isShown
                            isSaving={formik.isSubmitting}
                            isSubmit
                            submitLabel="Save"
                            onCancel={redirectToStockManagement}
                        />
                    </form>
                </div>
            </Panel>
        </div>
    )
}

export default StockItemAddPage
