import * as yup from "yup"
import { INPUT_MAX_LENGTH } from "constants/index"

export default yup.object().shape({
    name: yup
        .string()
        .max(INPUT_MAX_LENGTH, `Name may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .required("Name is required"),
    price: yup
        .number()
        .min(0, "Price must be grater than 0")
        .nullable()
        .required("Price is required"),
    brand: yup
        .object({ name: yup.string() })
        .nullable()
        .required("Brand is  required")
})
