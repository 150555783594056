import React, { Component } from "react"
import classNames from "classnames/bind"

import { mergeCx } from "../helpers/styles"

const withStyles = styles => {
    return WrappedComponent => {
        class Enhance extends Component {
            state = {
                cx: classNames.bind(styles)
            }

            componentDidMount() {
                const { customStyles } = this.props

                if (customStyles) {
                    const customCx = classNames.bind(customStyles)
                    this.setState(prevState => ({
                        cx: mergeCx(prevState.cx, customCx)
                    }))
                }

                if (this.props.cx) {
                    this.setState(prevState => ({
                        cx: mergeCx(prevState.cx, this.props.cx)
                    }))
                }
            }

            UNSAFE_componentWillReceiveProps(nextProps) {
                const { customStyles } = this.props

                if (nextProps.customStyles !== customStyles) {
                    const customCx = classNames.bind(nextProps.customStyles)
                    this.setState(prevState => ({
                        cx: mergeCx(prevState.cx, customCx)
                    }))
                }

                if (nextProps.cx !== this.props.cx) {
                    this.setState(prevState => ({
                        cx: mergeCx(prevState.cx, nextProps.cx)
                    }))
                }
            }

            render() {
                const { cx } = this.state
                const { customStyles, ...props } = this.props

                return <WrappedComponent {...props} cx={cx} />
            }
        }

        return Enhance
    }
}

export default withStyles
