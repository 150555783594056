import React, { Fragment, useMemo, useState } from "react"
import { useQuery } from "react-query"
import _get from "lodash/get"
import fetch from "helpers/fetch"
import { noop } from "rxjs"
import TERMS_TYPE_KEYS from "constants/termsType"

import ListingLayout from "../ListingLayout"
import Header from "../TemplatesHeader"
import List from "../TemplatesList"
import EditTermsAndConditionsTemplate from "./components/EditTermsAndConditionsTemplate"
import { ActionButton, EmptyList } from "ui"

import editIcon from "assets/pencil.svg"
import documentIcon from "assets/ico-document.svg"
import documentIconDark from "assets/ico-document_dark.svg"

const TERMS_TYPE_KEYS_VALUES = Object.values(TERMS_TYPE_KEYS)

const mapReactQueryFetchStatus = query => ({
    isLoading: query.isFetching,
    isLoaded: query.isFetched,
    isError: query.isError,
    lastFetch: query.dataUpdatedAt
})

const Ecommerce = () => {
    const [termsTemplateToEdit, setTermsTemplateToEdit] = useState(undefined)

    const closeEditModal = () => {
        setTermsTemplateToEdit(undefined)
    }

    const openEditModal = termsTemplate => {
        setTermsTemplateToEdit(termsTemplate)
    }

    const settingsQuery = useQuery("settings", () => fetch.get(`/settings`), { initialData: { data: [] } })

    const termSettings = useMemo(
        () =>
            settingsQuery.data.data
                .filter(setting => TERMS_TYPE_KEYS_VALUES.includes(setting.key))
                .map(termSetting => ({
                    ...termSetting,
                    name: _get(termSetting, "value.title", termSetting.key.replace("_", " "))
                })),
        [settingsQuery.data]
    )

    const fetchStatus = useMemo(() => mapReactQueryFetchStatus(settingsQuery), [settingsQuery])

    const renderTableButtonsActions = termsTemplate => (
        <ActionButton handleClick={() => openEditModal(termsTemplate)} label="Edit" icon={editIcon} />
    )

    return (
        <Fragment>
            <ListingLayout>
                <section>
                    <Header title="Ecommerce" />
                    <List
                        sort
                        renderActionsButtons={renderTableButtonsActions}
                        renderEmptyList={() => <EmptyList message="No terms data added" icon={documentIcon} />}
                        handleTableChange={noop}
                        itemIcon={documentIconDark}
                        tableData={{
                            data: termSettings,
                            fetchStatus
                        }}
                        tableHeader="Document type"
                    />
                </section>
            </ListingLayout>
            {termsTemplateToEdit && (
                <EditTermsAndConditionsTemplate termsToEdit={termsTemplateToEdit} handleClose={closeEditModal} />
            )}
        </Fragment>
    )
}

export default Ecommerce
