export const getComponents = (defaultComponents, overrides) => {
    return Object.keys(defaultComponents).reduce((acc, name) => {
        const override = overrides[name] || {}
        acc[name] = {
            component: override.component || defaultComponents[name],
            props: { customStyles: override.styles, ...override.props }
        }
        return acc
    }, {})
}
