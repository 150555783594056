import React, { Component, Fragment } from "react"
import { compose } from "redux"

import { withStyles } from "ui/FilterableTable/HOC"
import styles from "./PerPage.css"
import { AppContext, Filters, Filter } from "ui/FilterableTable"

class PerPageConsumer extends Component {
    render() {
        return <AppContext.Consumer>{context => <PerPage {...this.props} context={context} />}</AppContext.Consumer>
    }
}

class PerPage extends Component {
    static defaultProps = {
        label: "Items Per Page",
        name: "length"
    }

    render() {
        const { cx, label, name, context } = this.props
        return (
            <Fragment>
                {context.meta.total > 15 && (
                    <div className={cx("root")}>
                        <Filters customClass={cx("filters")}>
                            <Filter label={label} name={name} direction="up">
                                {[15, 30, 45, 60].map(value => {
                                    return (
                                        <Filter.Item key={value} value={value} selected={context.perPage === value}>
                                            {value}
                                        </Filter.Item>
                                    )
                                })}
                            </Filter>
                        </Filters>
                    </div>
                )}
            </Fragment>
        )
    }
}

const enhancer = compose(withStyles(styles))

export default enhancer(PerPageConsumer)
