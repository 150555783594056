import React from "react"
import { Formik, Form as FormikForm } from "formik"
import _ from "lodash"

import withStyles from "HOC/withStyles"

import Layout from "components/Reusable/Layout"
import FilterableTable, { Content, Table } from "ui/FilterableTable"
import { SaveBar } from "ui"
import ListRow from "./components/ListRow"

import styles from "./Settings.css"

const Settings = ({ cx, isLoading, calendars, handleSubmit, isSubmitting }) => {
    const renderTableHeader = () => {
        return (
            <Table.Head>
                <Table.Tr>
                    <Table.Th>COLOR</Table.Th>
                    <Table.Th>CALENDAR NAME</Table.Th>
                    <Table.Th>ORIGINAL NAME</Table.Th>
                    <Table.Th>
                        <span>VISIBILITY</span>
                    </Table.Th>
                </Table.Tr>
            </Table.Head>
        )
    }

    const renderTableBody = (items, form) => (
        <Table.Body>
            {items.map((item, index) => (
                <ListRow key={item.id} item={item} form={form} index={index} />
            ))}
        </Table.Body>
    )

    return (
        <Formik initialValues={{ calendars: calendars }} enableReinitialize onSubmit={handleSubmit}>
            {propsForm => (
                <FormikForm>
                    <Layout title="System events">
                        <FilterableTable
                            fetchStatus={{ isLoading, isLoaded: !isLoading }}
                            items={propsForm.values.calendars}
                        >
                            <Content>
                                <Table
                                    className={cx("table")}
                                    renderHeader={renderTableHeader}
                                    renderBody={items => renderTableBody(items, propsForm)}
                                />
                            </Content>
                        </FilterableTable>
                    </Layout>
                    <SaveBar
                        isSaving={isSubmitting}
                        isShown={!_.isEqual(calendars, propsForm.values.calendars)}
                        submitLabel="Save"
                        message="Unsaved changes"
                        onCancel={propsForm.resetForm}
                        isSubmit
                    />
                </FormikForm>
            )}
        </Formik>
    )
}

export default withStyles(Settings, styles)
