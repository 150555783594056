import React, { Component } from "react"
import * as formHelpers from "helpers/form"
import fetch from "helpers/fetch"
import _ from "lodash"

const yup = require("yup")

const withForm = (WrappedComponent, config = {}, prepareDataBeforeSubmit) => {
    class Enhance extends Component {
        state = {
            isSaving: false,
            isError: false,
            isFilled: false,
            fieldValues: {},
            fieldErrors: {},
            errorMessage: "",
            errorData: "",
            customEndpoint: null,
            customEndpointData: {},
            isEdited: false
        }

        componentDidMount() {
            const { model } = config

            this.setState({
                fieldValues: formHelpers.getFieldsFromModel({ model }),
                fieldErrors: formHelpers.getFieldsFromModel({ model, withDefaultValue: false })
            })
        }

        startSaving() {
            const { model } = config

            this.setState({
                fieldErrors: formHelpers.getFieldsFromModel({ model, withDefaultValue: false }),
                isError: false,
                isSaving: true
            })
        }

        fillModel = (data, force = false) => {
            const { model } = config
            const { isFilled } = this.state

            if ((!isFilled || force) && Object.keys(data).length > 0) {
                return new Promise(resolve => {
                    this.setState(
                        {
                            isFilled: true,
                            isEdited: false,
                            fieldValues: formHelpers.getFieldsFromModel({ model, stateFieldValues: data })
                        },
                        () => {
                            resolve()
                        }
                    )
                })
            }

            return Promise.resolve()
        }

        setCustomEndpoint = (value, data) => {
            this.setState({
                customEndpoint: value,
                customEndpointData: data
            })
        }

        handleFieldChange = ({ event, fieldPath, callback }) => {
            if (event && event.target) {
                const value = event.target.value
                const { fieldValues, fieldErrors } = this.state

                const newFieldValues = { ...fieldValues }
                if (_.has(newFieldValues, fieldPath)) {
                    _.set(newFieldValues, fieldPath, value)
                }

                const newFieldErrors = { ...fieldErrors }
                if (_.has(newFieldErrors, fieldPath)) {
                    _.set(newFieldErrors, fieldPath, "")
                }

                this.setState(
                    {
                        isEdited: true,
                        fieldValues: newFieldValues,
                        fieldErrors: newFieldErrors
                    },
                    callback
                )
            }
        }

        handleFieldBlur = fieldPath => {
            if (_.has(this.state.fieldValues, fieldPath)) {
                const value = _.get(this.state.fieldValues, fieldPath)
                this.validate(_.set({}, fieldPath, value))
            }
        }

        validate = values => {
            const { model } = config
            const { fieldErrors } = this.state

            this.toYup(values)
                .validate(values, { abortEarly: false })
                .then(valid => {
                    if (valid) {
                        Object.keys(values).map(key => {
                            delete fieldErrors[key]
                            return null
                        })

                        this.setState({
                            fieldErrors: {
                                ...formHelpers.getFieldsFromModel({ model, withDefaultValue: false }),
                                ...fieldErrors
                            }
                        })
                    }
                })
                .catch(error => {
                    const newFieldErrors = { ...fieldErrors }
                    error.inner.map(item => {
                        if (newFieldErrors[item.path] !== undefined) {
                            newFieldErrors[item.path] = item.message
                        }
                        return null
                    })

                    this.setState({
                        fieldErrors: newFieldErrors
                    })
                })
        }

        handleCustomError = ({ error, fieldPath }) => {
            const { fieldErrors } = this.state
            const newFieldErrors = { ...fieldErrors }
            if (_.has(newFieldErrors, fieldPath)) {
                _.set(newFieldErrors, fieldPath, error)
            }
            this.setState({
                fieldErrors: newFieldErrors
            })
        }

        handleSubmit = ({ method = "post", handleSuccess, id }) => {
            const { model } = config
            const { endpoint } = model

            if (endpoint && method) {
                this.startSaving()
                this.submitModel({ method, handleSuccess, id })
            }
        }

        submitModel = ({ method, handleSuccess, id }) => {
            const { model } = config
            const { endpoint } = model
            const { customEndpoint, fieldErrors } = this.state
            const data = prepareDataBeforeSubmit
                ? prepareDataBeforeSubmit({
                      ...this.state.customEndpointData,
                      ...this.toApi()
                  })
                : this.toApi()

            const modelEndpoint = customEndpoint ? customEndpoint : endpoint

            const promise =
                method === "post" ? fetch.post(modelEndpoint, data) : fetch.patch(`${modelEndpoint}/${id}`, data)

            promise
                .then(
                    data => {
                        handleSuccess && handleSuccess(data)
                        this.setState({
                            isSaving: false,
                            isError: false
                        })
                    },
                    data => {
                        this.setState({
                            fieldErrors: {
                                ...fieldErrors,
                                ...data.errors
                            },
                            isError: true,
                            isSaving: false,
                            errorMessage: data.message || "",
                            errorData: data || ""
                        })
                    }
                )
                .catch(error => {
                    this.setState({
                        isError: true,
                        isSaving: false
                    })
                })
        }

        toApi = () => {
            const { model } = config
            const { fieldValues } = this.state

            const values = formHelpers.getFieldsFromModel({ model, stateFieldValues: fieldValues })
            return {
                ...values
            }
        }

        toYup = whiteList => {
            const { model } = config
            const schema = formHelpers.getFieldsForYup({ model, whiteList })
            return yup.object().shape(schema)
        }

        toRender = () => {
            const { model } = config
            const { fieldValues, fieldErrors } = this.state

            return formHelpers.getFieldsForRender({
                model,
                stateFieldValues: fieldValues,
                stateFieldErrors: fieldErrors,
                handleFieldBlur: this.handleFieldBlur,
                handleFieldChange: this.handleFieldChange
            })
        }

        render() {
            const { isSaving, isError, isFilled, errorMessage, isEdited, errorData } = this.state
            return (
                <WrappedComponent
                    {...this.props}
                    isSaving={isSaving}
                    isError={isError}
                    isFilled={isFilled}
                    toApi={this.toApi}
                    toYup={this.toYup}
                    toRender={this.toRender}
                    handleSubmit={this.handleSubmit}
                    handleFieldChange={this.handleFieldChange}
                    handleCustomError={this.handleCustomError}
                    setCustomEndpoint={this.setCustomEndpoint}
                    fillModel={this.fillModel}
                    errorMessage={errorMessage}
                    errorData={errorData}
                    isEdited={isEdited}
                />
            )
        }
    }

    return Enhance
}

export default withForm
