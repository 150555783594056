import { INPUT_TYPES } from "constants/index"

export default {
    name: "Category",
    endpoint: "/categories",
    fields: [
        {
            name: "name",
            label: "Name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "parent_category_id",
            label: "Parent category",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: null,
            attributes: {
                required: true
            }
        },
        {
            name: "image_path",
            label: "Image",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: "",
            attributes: {
                required: true
            }
        }
    ]
}
