const ns = "selectionsWidget/"

export const GET_BY_ID_START = ns + "GET_BY_ID_START"
export const GET_BY_ID_SUCCESS = ns + "GET_BY_ID_SUCCESS"
export const GET_BY_ID_ERROR = ns + "GET_BY_ID_ERROR"
export const RESET_CURRENT = ns + "RESET_CURRENT"

export const GET_PRODUCTS_START = ns + "GET_PRODUCTS_START"
export const GET_PRODUCTS_SUCCESS = ns + "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_ERROR = ns + "GET_PRODUCTS_ERROR"
export const RESET_PRODUCTS = ns + "RESET_PRODUCTS"

export const ADD_PRODUCT = ns + "ADD_PRODUCT"
export const REMOVE_PRODUCT = ns + "REMOVE_PRODUCT"
export const ADD_PRODUCTS = ns + "ADD_PRODUCTS"

export const START_MODAL_LOGIC = ns + "START_MODAL_LOGIC"
export const END_MODAL_LOGIC = ns + "END_MODAL_LOGIC"

export const RESET_PRODUCTS_FETCH_STATUS = ns + "RESET_PRODUCTS_FETCH_STATUS"
export const SET_SHOULD_PATCH = ns + "SET_SHOULD_PATCH"

export const INCREMENT_COUNTER = ns + "INCREMENT_COUNTER"
export const DECREMENT_COUNTER = ns + "DECREMENT_COUNTER"
export const RESET_LOCK = ns + "RESET_LOCK"

export const SET_LAST_SERVER_UPDATED_AT = ns + "SET_LAST_SERVER_UPDATED_AT"
export const SET_LAST_SERVER_COMPLETED_AT = ns + "SET_LAST_SERVER_COMPLETED_AT"
export const SET_LAST_MODIFIED_AT = ns + "SET_LAST_MODIFIED_AT"

export const SET_CHOOSEN_SELECTION = ns + "SET_CHOOSEN_SELECTION"

export const SHOW = ns + "SHOW"
export const HIDE = ns + "HIDE"
