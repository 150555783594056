import * as Yup from "yup"
import compose from "lodash/flow"

import { selectOptionSchemaFactory, requiredSelectOptionFactory } from "modules/WmsModule/helpers/schema"
import { REQUIRED_MESSAGE } from "modules/WmsModule/constants/validationMessages"
import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import { DESCRIPTION_SCHEMA } from "modules/WmsModule/constants/schema"

const onHoldRequiredSchemaFactory = schema =>
    schema.when("status.value", {
        is: STOCK_ITEM_STATUS_KEYS.ON_HOLD,
        then: schema.required(REQUIRED_MESSAGE)
    })

const onHoldRequiredSelectOptionFactory = compose([selectOptionSchemaFactory, onHoldRequiredSchemaFactory])

export default Yup.object().shape({
    status: requiredSelectOptionFactory(Yup.string()),
    for: onHoldRequiredSelectOptionFactory(Yup.number().nullable(true)),
    reason: onHoldRequiredSelectOptionFactory(Yup.string()),
    description: DESCRIPTION_SCHEMA
})
