import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { ActionButton, Button } from "ui"

import withStyles from "HOC/withStyles"
import styles from "./HeaderActions.css"

import deleteIcon from "assets/close-blue.svg"

const HeaderActions = props => {
    const { cx, showSelectedActions, deleteSelected, convertSelected, createNew } = props

    return (
        <div>
            {showSelectedActions ? (
                <Fragment>
                    <ActionButton
                        className={cx("delete-button")}
                        icon={deleteIcon}
                        label="Delete"
                        handleClick={() => deleteSelected()}
                    />
                    <Button
                        className={cx("button", "first-button")}
                        label="Convert to Products"
                        onClick={() => convertSelected()}
                    />
                </Fragment>
            ) : (
                <Button className={cx("button", "first-button")} label="Create New" onClick={() => createNew()} />
            )}
        </div>
    )
}

HeaderActions.propTypes = {
    showSelectedActions: PropTypes.bool.isRequired,
    deleteSelected: PropTypes.func.isRequired,
    convertSelected: PropTypes.func.isRequired,
    createNew: PropTypes.func.isRequired
}

export default withStyles(HeaderActions, styles)
