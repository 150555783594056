import { INPUT_TYPES } from "constants/index"

export default {
    name: "News",
    endpoint: "/news",
    fields: [
        {
            name: "title",
            label: "Title",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "cover",
            label: "Cover",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: null
        },
        {
            name: "thumbnail",
            label: "Post thumbnail:",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: null
        },
        {
            name: "description",
            label: "",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "tags",
            label: "Add a tag...",
            type: INPUT_TYPES.TEXT,
            defaultValue: [],
            attributes: {}
        }
    ]
}
