import React, { Fragment } from "react"
import moment from "moment"
import { withRouter } from "react-router-dom"

import withStyles from "HOC/withStyles"
import * as fetchStatusHelper from "helpers/fetchStatus"
import { Modal, Input, Button, EmptyList } from "ui"
import { SelectProjectRoom } from "ui/Filters"
import InfiniteScroll from "ui/InfiniteScroll"
import Loader from "components/Reusable/Loader"
import Skeleton from "components/Skeleton"

import styles from "./ChooseProject.css"
import inputStyles from "./overrides/Input.css"
import addProductButton from "./overrides/AddProductButton.css"
import stylesCreateButton from "./overrides/CreateButton.css"

import projectIcon from "assets/ico-projects.svg"

const ChooseProject = props => {
    const {
        cx,
        selectedProject,
        isSaving,
        setIsSaving,
        projects,
        projectList,
        handleSelectRoom,
        filterSearchPhrase,
        hasMoreProjects,
        handleLoadMoreProjects,
        handleChangeFilterSearchPhrase,
        handleSelect,
        headerText,
        labelChooseButton,
        withSelectRoom
    } = props

    return (
        <Fragment>
            <Modal.Header>{headerText || "Add this product to a project"}</Modal.Header>
            <Modal.Body>{renderBody()}</Modal.Body>
        </Fragment>
    )

    function renderBody() {
        return (
            <Skeleton
                fetchStatus={fetchStatusHelper.fromResources(projects)}
                fallback={() => <div>An error occurred, please try again.</div>}
                component={
                    <div className={cx("loader")}>
                        <Loader />
                    </div>
                }
                firstTime={true}
            >
                <div className={cx("searchContainer")}>
                    <div className={cx("searchBar")}>
                        <Input
                            name="search"
                            label="Search for project..."
                            isPlaceholder={true}
                            customStyles={inputStyles}
                            value={filterSearchPhrase}
                            onChange={({
                                event: {
                                    target: { value }
                                }
                            }) => handleChangeFilterSearchPhrase(value)}
                            autoComplete="off"
                        />
                    </div>
                </div>
                {renderProductListContainer()}
            </Skeleton>
        )
    }

    function renderProductListContainer() {
        return (
            <div className={cx("productListContainer")}>
                <Skeleton
                    fetchStatus={fetchStatusHelper.fromResources(projects)}
                    component={
                        <div className={cx("loader")}>
                            <Loader />
                        </div>
                    }
                >
                    {renderProductList()}
                </Skeleton>
            </div>
        )
    }

    function renderProductList() {
        const { history } = props
        if (!projects.meta.total) {
            return (
                <EmptyList
                    message="No project found."
                    line2="Please check the spelling or create new project."
                    icon={projectIcon}
                >
                    <div className={cx("actionContainer")}>
                        <Button
                            customStyles={stylesCreateButton}
                            className={["first-button", "white"]}
                            label="Create New Project"
                            onClick={() => history.push("/projects/add")}
                        />
                    </div>
                </EmptyList>
            )
        }

        return (
            <Fragment>
                <div className={cx("productListInfo")}>My clients projects</div>
                <div className={cx("productListInfiniteScrollContainer")}>
                    <InfiniteScroll
                        className={cx("productList")}
                        hasMore={hasMoreProjects}
                        loader={
                            <div key="loader" className={cx("loader")}>
                                <Loader />
                            </div>
                        }
                        loadMore={handleLoadMoreProjects}
                        threshold={200}
                        useWindow={false}
                        element="ul"
                    >
                        {projectList.map((project, index) => {
                            return (
                                <li key={index} className={cx("item")}>
                                    <div className={cx("side")}>
                                        <div className={cx("nameContainer")}>
                                            <div className={cx("name")}>{project.name}</div>
                                            <div className={cx("company")}>{renderNameAndCompany(project)}</div>
                                        </div>
                                    </div>
                                    <div className={cx("side")}>
                                        {withSelectRoom ? (
                                            <SelectProjectRoom
                                                projectId={project.id}
                                                value={project.selectedRoom.label}
                                                handleSelect={({ target: { value } }) =>
                                                    handleSelectRoom({ projectId: project.id, selectedRoom: value })
                                                }
                                                withoutSearch
                                                selectWithSearchProps={{
                                                    isClearBlocked: true
                                                }}
                                            />
                                        ) : (
                                            <div className={cx("updated")}>
                                                updated {moment(project.updated_at).fromNow()}
                                            </div>
                                        )}
                                        <Button
                                            label={labelChooseButton || "Add product"}
                                            isLoading={isSaving && selectedProject && selectedProject.id === project.id}
                                            customStyles={addProductButton}
                                            onClick={() => {
                                                setIsSaving(true)
                                                handleSelect(project)
                                            }}
                                        />
                                    </div>
                                </li>
                            )
                        })}
                    </InfiniteScroll>
                </div>
            </Fragment>
        )
    }

    function renderNameAndCompany(project) {
        const client = project.client || null

        if (!client) {
            return null
        }

        const firstName = client.first_name || null
        const lastName = client.last_name || null

        const name = [firstName, lastName].filter(i => !!i).join(" ")
        const company = (client.main_address || {}).company || null

        return [name, company].filter(i => !!i).join(", ")
    }
}

export default withStyles(withRouter(ChooseProject), styles)
