import { GET_PARTNERS_START, GET_PARTNERS_SUCCESS, GET_PARTNERS_ERROR, LENGTH_WITHOUT_PAGINATION } from "../constants"
import fetch from "../helpers/fetch"

const getPartnersStart = () => ({ type: GET_PARTNERS_START })
const getPartnersSuccess = payload => ({ type: GET_PARTNERS_SUCCESS, payload })
const getPartnersError = payload => ({ type: GET_PARTNERS_ERROR, payload })

export const getPartners = () => async dispatch => {
    dispatch(getPartnersStart())
    try {
        const payload = await fetch.get(`/partners?length=${LENGTH_WITHOUT_PAGINATION}`)
        dispatch(getPartnersSuccess(payload))
        return payload
    } catch (error) {
        dispatch(getPartnersError(error))
        return error
    }
}
