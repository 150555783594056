import React, { useMemo } from "react"
import CollapsingText from "ui/CollapsingText"
import { getAddressLines } from "helpers/address"
import _get from "lodash/get"

import { withListContext } from "modules/OrdersModule/components/List/ListContainer"

const getLines = getAddressLines.bind(null, [
    ["company", ["first_name", "last_name"]],
    ["address"],
    ["city", ["state", "post_code"]],
    ["country"]
])

const TransparentDetails = ({ cx, documentData, listConfig }) => {
    const { billing_address, shipping_address, warehouse, description } = documentData
    const { abbreviation } = listConfig

    const [billingAddressLines, shippingAddressLines] = useMemo(
        () => [getLines(billing_address), getLines(shipping_address)],
        [billing_address, shipping_address]
    )

    return (
        <div className={cx("transparentDetails")}>
            {abbreviation === "Invoice" && (
                <div className={cx("detailsElement")}>
                    <label>Billing Address:</label>
                    {renderAddress(billingAddressLines)}
                </div>
            )}
            <div className={cx("detailsElement", { shippingAdress: abbreviation === "PO" })}>
                <label>Shipping Address:</label>
                {shipping_address ? renderAddress(shippingAddressLines) : _get(warehouse, "name", "-")}
            </div>
            <div className={cx("detailsElement", "description")}>
                <label>{abbreviation} description:</label>
                <CollapsingText wrapper="span" text={description || "-"} />
            </div>
        </div>
    )

    function renderAddress(lines) {
        if (lines.every(line => !line)) {
            return <span>-</span>
        }

        return (
            <span>
                {lines.map((line, index) => (
                    <div key={index} className={cx({ strong: index === 0 })}>
                        {line}
                    </div>
                ))}
            </span>
        )
    }
}

export default withListContext(TransparentDetails)
