import React, { Fragment, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import _isNumber from "lodash/isNumber"

import { formatPriceByComa } from "helpers/units"

import { Quantity } from "ui"
import { Button } from "@butterfly-frontend/ui"

import { PRODUCT_TYPE_KEYS } from "ui/ChooseProduct/constants/productTypeKeys"

import styles from "./ProductActions.module.css"

const ProductActions = ({
    product,
    type,
    isWholesaleCost,
    disableCombinations,
    disableQuantity,
    isDisabledAddItems,
    handleSelect,
    handleChooseCombination,
    handleChangeQuantity,
    handleChangeIsLoading,
    itemsState
}) => {
    const addProductLabel = useMemo(() => (type === "services" ? "Add service" : "Add product"), [type])

    const { lowest_price, has_combinations, discount, price, wholesale_cost } = product
    const basePrice = lowest_price || price || 0

    const productPrice = isWholesaleCost ? wholesale_cost || 0 : (basePrice * (100 - (discount || 0))) / 100

    const handleSelectProduct = useCallback(() => {
        handleChangeIsLoading(product.id, true)
        return handleSelect({
            ...product,
            product_id: product.id,
            quantity:
                itemsState[product.id] && _isNumber(itemsState[product.id].quantity)
                    ? itemsState[product.id].quantity
                    : 1
        })
    }, [handleSelect, itemsState, handleChangeIsLoading])

    return (
        <div className={`${styles.productListItemSide} ${styles.productListItemSidePrice}`}>
            <div className={styles.priceContainer}>
                <Fragment>
                    {has_combinations && <span className={styles.price}>from</span>}{" "}
                    <span className={styles.price}>
                        $ {formatPriceByComa(productPrice, true) || 0}.{formatPriceByComa(productPrice, false) || "00"}
                    </span>
                </Fragment>
                {discount > 0 && <span className={styles.discount}>{`- ${discount}%`}</span>}
            </div>
            <div className={styles.productListItemSide}>
                {(!has_combinations || disableCombinations) && !disableQuantity && (
                    <Quantity
                        value={(itemsState[product.id] && itemsState[product.id].quantity) || 1}
                        min={1}
                        onChange={value => handleChangeQuantity(product.id, value)}
                    />
                )}
                {has_combinations && !disableCombinations ? (
                    <Button
                        classes={{ button: styles.button }}
                        onClick={() => handleChooseCombination(product)}
                        disabled={isDisabledAddItems}
                        size="small"
                    >
                        Choose an option
                    </Button>
                ) : (
                    <Button
                        isLoading={itemsState[product.id] && itemsState[product.id].isLoading}
                        classes={{ button: styles.button }}
                        onClick={handleSelectProduct}
                        disabled={isDisabledAddItems}
                        size="small"
                        color="blue"
                    >
                        {addProductLabel}
                    </Button>
                )}
            </div>
        </div>
    )
}

ProductActions.propTypes = {
    product: PropTypes.shape({
        id: PropTypes.number.isRequired,
        lowest_price: PropTypes.number,
        has_combinations: PropTypes.bool,
        discount: PropTypes.number,
        price: PropTypes.number,
        wholesale_cost: PropTypes.number,
        brand: PropTypes.string,
        media: PropTypes.shape({ url: PropTypes.string }),
        name: PropTypes.string.isRequired
    }),
    type: PropTypes.oneOf(Object.values(PRODUCT_TYPE_KEYS)),
    isWholesaleCost: PropTypes.bool,
    disableCombinations: PropTypes.bool,
    disableQuantity: PropTypes.bool,
    isDisabledAddItems: PropTypes.bool,
    handleSelect: PropTypes.func,
    handleChangeQuantity: PropTypes.func,
    handleChangeIsLoading: PropTypes.func,
    itemsState: PropTypes.objectOf(
        PropTypes.shape({
            quantity: PropTypes.number,
            isLoading: PropTypes.bool
        })
    ).isRequired
}

export default ProductActions
