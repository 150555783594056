import React from "react"
import { useField } from "formik"
import { useMediaQuery } from "react-responsive"

import { DatePickerWithInput } from "ui"
import { Aside } from "ui/AddEditForm"

import withStyles from "HOC/withStyles"
import styles from "./styles.css"

const PaymentSection = ({ cx }) => {
    const [paymentDate, paymentDateMeta] = useField("payment_due_at")

    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" })

    return (
        <Aside label="Options">
            <div className={cx("root")}>
                <label>
                    Payment due date <span className={cx("required")}>*</span>:
                </label>
                <DatePickerWithInput
                    date={paymentDate.value}
                    handleSelectDate={date =>
                        paymentDate.onChange({ target: { name: paymentDate.name, value: date || "" } })
                    }
                    calendarOnRight
                    calendarAboveInput={isMobile}
                    error={paymentDateMeta.error}
                />
            </div>
        </Aside>
    )
}

export default withStyles(PaymentSection, styles)
