import React, { useState } from "react"
import PropTypes from "prop-types"

import { Panel, DetailsCard, Status, StatusSelect } from "@butterfly-frontend/ui"
import { DateLabel } from "modules/WmsModule/components"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import STATUS_SELECT_OPTIONS from "modules/WmsModule/constants/shipmentStatusSelectOptions"
import { CancelShipmentModal } from "modules/WmsModule/modals"
import { useShipmentStatusEdit } from "modules/WmsModule/hooks"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import styles from "./StockTransferDetailsHeader.module.css"

const StockTransferDetailsHeader = ({ stockTransfer, onStatusChanged, canEdit }) => {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [newStatus, setNewStatus] = useState(null)

    const { handleUpdateShipmentStatus, isStatusLoading } = useShipmentStatusEdit({
        id: stockTransfer.id,
        newStatus,
        setIsConfirmationModalOpen,
        onStatusChanged: (...args) => {
            setIsConfirmationModalOpen(false)
            if (onStatusChanged) {
                onStatusChanged(...args)
            }
        },
        isStockTransfer: true
    })

    const status = stockTransfer.status.status
    const filteredStatusesSelectOptions = STATUS_SELECT_OPTIONS.filter(
        status => status !== SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.DELIVERED]
    )
    const isStatusCancelled = status === SHIPMENT_STATUS_KEYS.CANCELLED
    const detailsCardClasses = { root: [styles.detailsCard, { [styles.inactiveCard]: isStatusCancelled }] }

    const isStatusSelectionEnabled =
        status !== SHIPMENT_STATUS_KEYS.DELIVERED && status !== SHIPMENT_STATUS_KEYS.CANCELLED && canEdit()

    const onConfirmationDenied = () => {
        setIsConfirmationModalOpen(false)
        setNewStatus(null)
    }

    return (
        <Panel>
            <div className={styles.container}>
                <DetailsCard header="FROM WAREHOUSE" classes={detailsCardClasses}>
                    {stockTransfer.source_warehouses.map(warehouse => warehouse.name).join(", ")}
                </DetailsCard>
                <DetailsCard header="TO LOCATION" classes={detailsCardClasses}>
                    {stockTransfer.destination_warehouse.name}
                </DetailsCard>
                <DetailsCard header="TRANSFER DATE" classes={detailsCardClasses}>
                    <DateLabel date={stockTransfer.created_at} />
                </DetailsCard>
                <DetailsCard header="BY" classes={detailsCardClasses}>
                    {stockTransfer.created_by ? stockTransfer.created_by.full_name : "N/A"}
                </DetailsCard>
                <DetailsCard header="STATUS" classes={{ root: [styles.detailsCard, styles.shipmentStatus] }}>
                    {isStatusSelectionEnabled ? (
                        <StatusSelect
                            value={SHIPMENT_STATUSES[status]}
                            options={filteredStatusesSelectOptions}
                            onChange={({ value }) => setNewStatus(value)}
                            isLoading={isStatusLoading}
                        />
                    ) : (
                        <Status theme={SHIPMENT_STATUSES[status].theme} color={SHIPMENT_STATUSES[status].color}>
                            {SHIPMENT_STATUSES[status].label}
                        </Status>
                    )}
                </DetailsCard>
            </div>
            {isConfirmationModalOpen && (
                <CancelShipmentModal
                    onCancel={onConfirmationDenied}
                    onAction={handleUpdateShipmentStatus}
                    isLoading={isStatusLoading}
                    shipmentType={SHIPMENT_TYPE_KEYS.TRANSFER}
                />
            )}
        </Panel>
    )
}

StockTransferDetailsHeader.propTypes = {
    stockTransfer: PropTypes.shape({
        source_warehouses: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired
        ).isRequired,
        destination_warehouse: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired,
        created_at: PropTypes.string.isRequired,
        status: PropTypes.shape({
            status: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    onStatusChanged: PropTypes.func
}

export default withPermissions(StockTransferDetailsHeader, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
