import React, { useEffect, useState, useReducer } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as yup from "yup"

import fetch from "helpers/fetch"
import * as projectActions from "actions/projects"
import * as notificationActions from "actions/notification"
import ProductList from "./ProductList"

const deleteRoomReducer = (state, action) => {
    switch (action.type) {
        case "deleting":
            return {
                ...state,
                isSaving: true
            }

        case "error":
            return {
                ...state,
                isSaving: false
            }

        case "reset":
            return {
                ...deleteRoomInitialState
            }

        case "toggleModal":
            if (state.isOpen) {
                return {
                    isOpen: false,
                    roomId: null,
                    isSaving: false
                }
            }

            return {
                isOpen: true,
                roomId: action.payload.roomId,
                isSaving: false
            }

        default:
            throw new Error()
    }
}

const deleteRoomInitialState = {
    isOpen: false,
    roomId: null,
    isSaving: false
}

const filtersSchema = yup.object().shape({
    project: yup.string().required(),
    data: yup
        .object()
        .shape({
            search: yup.string().nullable(),
            brand: yup.object().shape({
                id: yup.number(),
                name: yup.string()
            }),
            room: yup.object().shape({
                id: yup.number().nullable(),
                name: yup.string()
            })
        })
        .required()
})

const ProductListContainer = props => {
    const { projectId, project, actions } = props

    const [filters, setFilters] = useState({})
    const [itemsState, setItemsState] = useState({})
    const [productFetchStatuses, setProductFetchStatuses] = useState({})
    const [productModalIsOpen, setProductModalIsOpen] = useState(false)
    const [productDeleteModalIsOpen, setProductDeleteModalIsOpen] = useState(false)
    const [productToDelete, setProductToDelete] = useState(null)
    const [productIsDeleting, setProductIsDeleting] = useState(false)
    const [roomModalIsOpen, setRoomModalIsOpen] = useState(false)
    const [roomModalCurrentItem, setRoomModalCurrentItem] = useState(null)
    const [addToRoomModalState, setAddToRoomModalState] = useState({
        isOpen: false,
        productIds: [],
        isCopy: false
    })
    const [multiProductDeleteState, setMultiProductDeleteState] = useState({
        isOpen: false,
        isSaving: false,
        productIds: []
    })
    const [salesOrderModalState, setSalesOrderModalState] = useState({
        isOpen: false,
        productIds: []
    })
    const [shareModalIsOpen, setShareModalIsOpen] = useState(false)
    const [shareProjectErrors, setShareProjectErrors] = useState(null)

    const [deleteRoomModalState, deleteRoomModalDispatch] = useReducer(deleteRoomReducer, deleteRoomInitialState)

    const [wasProductAdded, setWasProductAdded] = useState(false)

    useEffect(() => {
        assignFiltersFromLocalStorage(projectId)
    }, [projectId])

    useEffect(() => {
        localStorage.setItem(
            "projectFilters",
            JSON.stringify({
                project: projectId,
                data: filters
            })
        )
    }, [projectId, filters])

    useEffect(() => {
        if (actions.shouldFetch(project, project.roomsFetchStatus)) {
            actions.getProject(projectId)
        }

        if (project.fetchStatus.isLoaded) {
            updateItemsState()
        }
    }, [project])

    useEffect(() => {
        if (!productModalIsOpen && wasProductAdded) {
            actions.resetRoomsFetchStatus()
            setWasProductAdded(false)
        }
    }, [wasProductAdded, productModalIsOpen])

    const handleChooseProduct = params => {
        params = {
            project_id: projectId,
            ...params
        }

        handleProductFetchStatus(params.product_id, true)
        actions
            .addProductToProject(params)
            .then(data => {
                setWasProductAdded(true)
                actions.showSuccessNotification()
            })
            .catch(() => actions.showErrorNotification())
            .finally(() =>
                setTimeout(() => {
                    handleProductFetchStatus(params.product_id, false)
                }, 350)
            )
    }

    const handleProductFetchStatus = (product_id, value) =>
        setProductFetchStatuses(prevState => ({
            ...prevState,
            [product_id]: {
                ...prevState[product_id],
                isLoading: value
            }
        }))

    const getProductList = () => {
        return project.data.rooms.reduce((acc, room) => acc.concat(room.items), [])
    }

    const updateItemsState = () => {
        const productList = getProductList()

        const items = prevState => {
            return productList
                .filter(product => prevState[product.id])
                .reduce((acc, product) => {
                    acc[product.id] = {
                        ...prevState[product.id],
                        isEdited: false,
                        isChecked: false
                    }

                    return acc
                }, {})
        }

        const newItems = prevState => {
            return productList
                .filter(product => !prevState[product.id])
                .reduce((acc, product) => {
                    acc[product.id] = {
                        isEdited: false,
                        isSaving: false,
                        isChecked: false,
                        areDetailsShown: false,
                        currentTab: "details"
                    }

                    return acc
                }, {})
        }

        setItemsState(prevState => {
            return {
                ...items(prevState),
                ...newItems(prevState)
            }
        })
    }

    const toggleProductModalIsOpen = () => {
        productModalIsOpen && wasProductAdded && actions.resetRoomsFetchStatus()
        setWasProductAdded(false)
        setProductModalIsOpen(prevState => !prevState)
    }

    const itemState = id => {
        return itemsState[id] || {}
    }

    const setProductItemState = (id, nextItemState = {}) => {
        setItemsState(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                ...nextItemState
            }
        }))
    }

    const findProductInRoomsById = id => {
        return getProductList().find(item => item.id === id)
    }

    const toggleProductDelete = id => {
        if (!id) {
            setProductDeleteModalIsOpen(false)
            setProductToDelete(null)

            return false
        }

        const product = findProductInRoomsById(id)

        if (!product) {
            return false
        }

        setProductToDelete(product)
        setProductDeleteModalIsOpen(prevState => !prevState)
    }

    const toggleProductAddToRoom = (id, ids, isCopy) => {
        if (!id && !ids) {
            return setAddToRoomModalState({
                isOpen: false,
                productIds: [],
                isCopy
            })
        }

        if (id) {
            const product = findProductInRoomsById(id)

            if (!product) {
                return false
            }

            return setAddToRoomModalState(prevState => ({
                isOpen: !prevState.isOpen,
                productIds: [
                    {
                        id: product.id
                    }
                ],
                isCopy
            }))
        }

        if (ids) {
            return setAddToRoomModalState(prevState => ({
                isOpen: !prevState.isOpen,
                productIds: ids
            }))
        }
    }

    const toggleMultiProductDelete = ids => {
        return setMultiProductDeleteState(prevState => ({
            isOpen: !prevState.isOpen,
            productIds: ids
        }))
    }

    const toggleSalesOrderModal = ids => {
        return setSalesOrderModalState(prevState => ({
            isOpen: !prevState.isOpen,
            productIds: ids
        }))
    }

    const toggleRoomModal = (room = null) => {
        setRoomModalIsOpen(prevState => !prevState)
        setRoomModalCurrentItem(room)
    }

    const handleActionProduct = ({ actionType, id, payload }) => {
        switch (actionType) {
            case "check":
                setItemsState(prevState => ({
                    ...prevState,
                    [id]: {
                        ...prevState[id],
                        isChecked: !itemState(id).isChecked
                    }
                }))

                break

            case "toggleEdit":
                setItemsState(prevState => ({
                    ...Object.keys(prevState)
                        .filter(key => key !== id)
                        .reduce((acc, key) => {
                            acc[key] = {
                                ...prevState[key],
                                isEdited: false,
                                areDetailsShown: false
                            }

                            return acc
                        }, {}),
                    [id]: {
                        ...prevState[id],
                        isEdited: !itemState(id).isEdited,
                        areDetailsShown: false,
                        errors: {}
                    }
                }))

                break

            case "toggleDetails":
                setItemsState(prevState => ({
                    ...Object.keys(prevState)
                        .filter(key => key !== id)
                        .reduce((acc, key) => {
                            acc[key] = {
                                ...prevState[key],
                                isEdited: false,
                                areDetailsShown: false
                            }

                            return acc
                        }, {}),
                    [id]: {
                        ...prevState[id],
                        areDetailsShown: !itemState(id).areDetailsShown
                    }
                }))

                break

            case "changeTab":
                setItemsState(prevState => ({
                    ...prevState,
                    [id]: {
                        ...prevState[id],
                        currentTab: payload
                    }
                }))
                break

            case "showComments":
                setItemsState(prevState => ({
                    ...Object.keys(prevState)
                        .filter(key => key !== id)
                        .reduce((acc, key) => {
                            acc[key] = {
                                ...prevState[key],
                                isEdited: false,
                                areDetailsShown: false,
                                currentTab: "details"
                            }

                            return acc
                        }, {}),
                    [id]: {
                        ...prevState[id],
                        areDetailsShown: true,
                        currentTab: "clientCommunication"
                    }
                }))
                break

            case "save":
                setProductItemState(id, {
                    errors: {},
                    isSaving: true
                })

                actions.updateProduct(id, payload).then(
                    () => {
                        setTimeout(() => {
                            setProductItemState(id, {
                                isEdited: false,
                                isSaving: false
                            })
                            actions.resetRoomsFetchStatus()
                        }, 350)
                    },
                    error => {
                        actions.showErrorNotification()
                        setProductItemState(id, {
                            isSaving: false,
                            errors: Object.keys(error.errors || []).reduce((acc, field) => {
                                acc[field] = error.errors[field][0]
                                return acc
                            }, {})
                        })
                    }
                )

                break

            case "delete":
                toggleProductDelete(id)
                break

            case "addToRoom":
                toggleProductAddToRoom(id)
                break

            case "copyToRoom":
                toggleProductAddToRoom(id, null, true)
                break

            case "editRoom":
                toggleRoomModal(payload)
                break

            case "deleteRoom":
                deleteRoomModalDispatch({ type: "toggleModal", payload: { roomId: id } })
                break

            default:
                break
        }
    }

    const handleToggleSelectAllProductsInRoom = roomId => {
        setItemsState(prevState => ({
            ...prevState,
            ...project.data.rooms
                .find(room => room.id === roomId)
                .items.reduce((acc, item) => {
                    acc[item.id] = {
                        ...prevState[item.id],
                        isChecked: !areAllProductsInRoomChecked(roomId)
                    }

                    return acc
                }, {})
        }))
    }

    const handleMultiProductDelete = () => {
        setMultiProductDeleteState(prevState => ({
            ...prevState,
            isSaving: true
        }))

        Promise.all(
            multiProductDeleteState.productIds.map(item => {
                return actions.removeProductFromProject(item.id)
            })
        ).then(
            () => {
                setTimeout(() => {
                    setMultiProductDeleteState(prevState => ({
                        ...prevState,
                        isSaving: false,
                        isOpen: false
                    }))

                    actions.resetRoomsFetchStatus()
                }, 350)
            },
            () => {
                setMultiProductDeleteState(prevState => ({
                    ...prevState,
                    isSaving: false
                }))
            }
        )
    }

    const selectedProductIdInRoom = roomId => {
        const room = project.data.rooms.find(room => room.id === roomId)

        if (!room) {
            return []
        }

        return room.items
            .filter(item => {
                if (!itemsState[item.id]) {
                    return false
                }

                if (!itemsState[item.id].isChecked) {
                    return false
                }

                return true
            })
            .map(item => item.id)
    }

    const areAllProductsInRoomChecked = roomId => {
        return (
            selectedProductIdInRoom(roomId).length &&
            project.data.rooms.find(room => room.id === roomId).items.length === selectedProductIdInRoom(roomId).length
        )
    }

    const checkedProductCount = Object.values(itemsState).filter(item => item.isChecked).length
    const allProductsCount = project.data.rooms.reduce((acc, room) => acc + room.items.length, 0)
    const areAllProductsChecked = checkedProductCount === allProductsCount
    const productCount = !!checkedProductCount ? checkedProductCount : allProductsCount

    const handleDeleteProduct = () => {
        if (!productToDelete) {
            return false
        }

        setProductIsDeleting(true)

        actions.removeProductFromProject(productToDelete.id).then(
            () => {
                setTimeout(() => {
                    setProductIsDeleting(false)
                    toggleProductDelete(null)
                    actions.resetRoomsFetchStatus()
                }, 350)
            },
            () => {
                setProductIsDeleting(false)
            }
        )
    }

    const handleBarAction = name => {
        const checkedItems = Object.keys(itemsState)
            .filter(id => itemsState[id].isChecked)
            .map(id => ({ id }))

        switch (name) {
            case "newRoom":
                setRoomModalIsOpen(prevState => !prevState)
                break

            case "addToRoom":
                toggleProductAddToRoom(null, checkedItems)
                break

            case "copyToRoom":
                toggleProductAddToRoom(null, checkedItems, true)
                break

            case "addProduct":
                toggleProductModalIsOpen()
                break

            case "delete":
                toggleMultiProductDelete(checkedItems)
                break

            case "addToSalesOrder":
                toggleSalesOrderModal(checkedItems)
                break

            default:
                break
        }
    }

    const handleDeleteRoom = id => {
        if (!id) {
            return false
        }

        deleteRoomModalDispatch({ type: "deleting" })

        fetch
            .deleteRAW("/rooms/" + id)
            .then(() => {
                setTimeout(() => {
                    deleteRoomModalDispatch({ type: "reset" })
                    actions.resetRoomsFetchStatus()
                    actions.showSuccessNotification()
                }, 350)
            })
            .catch(() => {
                deleteRoomModalDispatch({ type: "error" })
                actions.showErrorNotification()
            })
    }

    const toggleShareModalIsOpen = () => {
        if (shareModalIsOpen) {
            actions.getProject(projectId)
        }
        setShareModalIsOpen(!shareModalIsOpen)
    }

    const shareProject = async data => {
        setShareProjectErrors(null)

        try {
            await fetch.postRAW("/project-shares", {
                ...data,
                project_id: projectId
            })

            actions.getProject(projectId)
            setShareModalIsOpen(false)

            actions.showSuccessNotification()
        } catch (error) {
            setShareProjectErrors(
                Object.entries(error.errors || {}).reduce((acc, item) => {
                    const [field, message] = item
                    return {
                        ...acc,
                        [field]: message[0].replace(field, "Field")
                    }
                }, {})
            )
            actions.showErrorNotification()
        }
    }

    const removeShareEmail = async id => {
        try {
            await fetch.deleteRAW(`/project-shares/${id}`)
        } catch (error) {
            actions.showErrorNotification()
        }
    }

    return (
        <ProductList
            project={project}
            itemsState={itemsState}
            productFetchStatuses={productFetchStatuses}
            productCount={productCount}
            productModalIsOpen={productModalIsOpen}
            roomModalIsOpen={roomModalIsOpen}
            roomModalCurrentItem={roomModalCurrentItem}
            productDeleteModalIsOpen={productDeleteModalIsOpen}
            productToDelete={productToDelete}
            productIsDeleting={productIsDeleting}
            deleteRoomModalState={deleteRoomModalState}
            deleteRoomModalDispatch={deleteRoomModalDispatch}
            handleDeleteRoom={handleDeleteRoom}
            checkedProductCount={checkedProductCount}
            addToRoomModalState={addToRoomModalState}
            multiProductDeleteState={multiProductDeleteState}
            salesOrderModalState={salesOrderModalState}
            toggleMultiProductDelete={toggleMultiProductDelete}
            toggleSalesOrderModal={toggleSalesOrderModal}
            toggleProductDelete={toggleProductDelete}
            toggleProductModalIsOpen={toggleProductModalIsOpen}
            toggleProductAddToRoom={toggleProductAddToRoom}
            toggleRoomModal={toggleRoomModal}
            handleChooseProduct={handleChooseProduct}
            handleActionProduct={handleActionProduct}
            handleDeleteProduct={handleDeleteProduct}
            handleToggleSelectAllProductsInRoom={handleToggleSelectAllProductsInRoom}
            handleBarAction={handleBarAction}
            handleMultiProductDelete={handleMultiProductDelete}
            areAllProductsInRoomChecked={areAllProductsInRoomChecked}
            areProductsChecked={!!checkedProductCount}
            areAllProductsChecked={areAllProductsChecked}
            toggleShareModalIsOpen={toggleShareModalIsOpen}
            shareModalIsOpen={shareModalIsOpen}
            shareProject={shareProject}
            shareProjectErrors={shareProjectErrors}
            removeShareEmail={removeShareEmail}
            filters={filters}
            setFilters={setFilters}
        />
    )

    async function assignFiltersFromLocalStorage(projectId) {
        try {
            const savedFilters = localStorage.getItem("projectFilters")

            if (savedFilters) {
                const storedFilters = JSON.parse(savedFilters)

                await filtersSchema.validate(storedFilters)

                if (storedFilters.project !== projectId) {
                    throw new Error("Incorrect project")
                }

                setFilters(storedFilters.data)
            }
        } catch (error) {
            localStorage.removeItem("projectFilters")
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators({ ...projectActions, ...notificationActions }, dispatch)
    }
}

export default connect(
    null,
    mapDispatchToProps
)(ProductListContainer)
