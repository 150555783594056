import React from "react"
import PropTypes from "prop-types"
import { Select } from "ui"
import { statusesConfig, statusTypes } from "ui/Status/config"
import styles from "./statusSelect.css"

const StatusSelect = props => {
    const { type, onChange, customStyles, canSelectAll, value, isDefaultStatus } = props

    const selectValues = Object.entries(statusesConfig[type]).map(([value, { label: { text } }]) => ({
        id: value,
        value,
        label: text
    }))
    const shouldSelectAll = canSelectAll && value.length === 0 && isDefaultStatus
    const selectValue = shouldSelectAll ? selectValues : value

    return (
        <Select
            multiselect
            allSelectedValueLabel="All statuses"
            selectAllLabel="All statuses"
            {...props}
            customStyles={customStyles || styles}
            values={selectValues}
            setValue={onChange}
            isAllSelected={shouldSelectAll}
            value={selectValue}
        />
    )
}

StatusSelect.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            label: PropTypes.string
        })
    ).isRequired,
    type: PropTypes.oneOf(statusTypes).isRequired,
    onChange: PropTypes.func.isRequired,
    customStyles: PropTypes.any
}

export default StatusSelect
