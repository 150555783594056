import React, { Fragment, useCallback, useMemo, useState } from "react"
import { Field, Formik } from "formik"
import PropTypes from "prop-types"
import * as Yup from "yup"

import { Loader } from "@butterfly-frontend/ui"
import withStyles from "HOC/withStyles"
import { DatePickerWithInput, Input, Button, Modal, Radio, Checkbox } from "ui"
import { SelectInvoice, SelectPaymentMethod } from "ui/Filters"
import { formatPrice, escapeAmountValue, roundNumber } from "helpers/units"

import styles from "./styles.css"
import inputAmountStyles from "./overrides/inputAmount.css"
import inputPercentageStyles from "./overrides/inputPercentage.css"
import datePickerStyles from "./overrides/datePicker.css"
import inputStaffNoteStyles from "./overrides/inputStaffNote.css"
import customErrorStyles from "./overrides/customError.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.css"
import radioStyles from "./overrides/radio.css"
import modalStyles from "./overrides/modal.css"
import invoiceSelectStyles from "./overrides/invoiceSelect.css"
import checkboxStyles from "./overrides/checkbox.css"

const { Header, Body, Actions } = Modal

const schema = Yup.object().shape({
    amount: Yup.string()
        .transform(escapeAmountValue)
        .required("This field is required and must be a number."),
    percentage: Yup.number().typeError("This field must be a number."),
    date: Yup.string().required("This field is required."),
    method: Yup.object().test({
        message: "This field is required",
        test: value => value && value.id && value.name
    })
})

const AMOUNT_TYPES = { FULL: "full", HALF: "half", OTHER: "other" }

const AddEditPayment = ({
    cx,
    formValues,
    handleSubmit,
    handleClose,
    isEdit,
    isSubmitting,
    collectionId,
    collectionType = "order",
    invoice,
    calculations,
    isLoading,
    isPO,
    availableStoreCreditAmount
}) => {
    const [isApplyStoreCreditsChecked, setIsApplyStoreCreditsChecked] = useState(false)

    const total = useMemo(() => {
        if (calculations) {
            return calculations.total || calculations.wholesale_total
        }
    }, [calculations])

    const totalPriceToPay = useMemo(() => {
        if (calculations) {
            return !isNaN(calculations.balance)
                ? isEdit
                    ? total
                    : calculations.balance >= 0
                    ? 0
                    : Math.abs(calculations.balance)
                : total
        }
    }, [calculations, total])

    const getPercentageAmountFromTotal = useCallback(
        value => {
            const escapedValue = escapeAmountValue(value)
            const percentage = (escapedValue / total) * 100
            return total === 0 || percentage === 0 || isNaN(percentage) ? undefined : percentage.toFixed(2)
        },
        [total]
    )

    const amountOptions = useMemo(() => {
        if (isNaN(totalPriceToPay)) {
            return
        }

        const halfTotalPrice = roundNumber(totalPriceToPay / 2)

        return {
            [AMOUNT_TYPES.OTHER]: {
                type: AMOUNT_TYPES.OTHER,
                displayedValue: "Other"
            },
            [AMOUNT_TYPES.FULL]: {
                type: AMOUNT_TYPES.FULL,
                displayedValue: `100% - ${formatPrice(totalPriceToPay)}`,
                value: totalPriceToPay.toString()
            },
            [AMOUNT_TYPES.HALF]: {
                type: AMOUNT_TYPES.HALF,
                displayedValue: `50% - ${formatPrice(halfTotalPrice)}`,
                value: halfTotalPrice.toString()
            }
        }
    }, [totalPriceToPay])

    const onPaymentOptionChange = useCallback(
        (option, setFieldValue, setFieldError) => {
            setFieldValue("amountType", option)
            setFieldError("amount", null)
            setFieldValue("amount", option === AMOUNT_TYPES.OTHER ? "" : amountOptions[option].value)
        },
        [amountOptions]
    )

    const onPercentageChange = useCallback(
        (percentageValue, setFieldValue, setFieldError) => {
            setFieldValue("percentage", percentageValue > 100 ? 100 : percentageValue)
            const amountValue = ((percentageValue > 100 ? 100 : percentageValue) * totalPriceToPay) / 100
            if (isNaN(amountValue)) {
                return
            }
            setFieldError("amount", null)
            setFieldValue("amount", amountValue.toFixed(2))
        },
        [totalPriceToPay]
    )

    const initialFormValues = useMemo(() => {
        const amount = formValues.amount.toString()

        return {
            ...formValues,
            percentage: getPercentageAmountFromTotal(amount),
            amountType: AMOUNT_TYPES.OTHER,
            amount
        }
    }, [formValues, amountOptions])

    const onSubmit = (values, formik) => {
        const { percentage, amountType, amount, ...filteredValues } = values
        const escapedAmountValue = escapeAmountValue(amount)

        if (isPO) {
            return handleSubmit({ ...filteredValues, ...{ amount: escapedAmountValue, invoice: {} } }, formik)
        }
        return handleSubmit({ ...filteredValues, ...{ amount: escapedAmountValue } }, formik)
    }

    return (
        <Modal isOpen={true} closeModal={handleClose} preventClickOutside={true} customStyles={modalStyles}>
            <Header className={cx("title")}>{isEdit ? "Edit" : "New"} Payment</Header>
            {isLoading ? (
                <div className={styles.loaderContainer}>
                    <Loader classes={styles.loader} />
                </div>
            ) : (
                <Formik
                    initialValues={initialFormValues}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                    enableReinitialize={true}
                >
                    {({ handleSubmit, values, touched, errors, setFieldValue, setFieldError }) => {
                        return (
                            <Fragment>
                                <form onSubmit={handleSubmit} autoComplete="off">
                                    <Body className={cx("body")}>
                                        <div className={cx("headerRow")}>
                                            <div className={cx("calculations")}>
                                                <div className={cx("totalValue")}>
                                                    <span className={cx("calculationsLabel")}>TOTAL:</span>
                                                    <span className={cx("amountValue")}>{formatPrice(total)}</span>
                                                </div>
                                                {calculations && calculations.applied > 0 && (
                                                    <div>
                                                        <span className={cx("calculationsLabel")}>PAID:</span>
                                                        <span className={cx("amountValue")}>
                                                            {formatPrice(calculations.applied)}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                            {amountOptions && (
                                                <div className={cx("amountOptions")}>
                                                    <Field name="percentage">
                                                        {() =>
                                                            Object.values(amountOptions).map(
                                                                ({ type, displayedValue }) => (
                                                                    <Radio
                                                                        key={type}
                                                                        name={type}
                                                                        label={displayedValue}
                                                                        value={type}
                                                                        checked={values.amountType === type}
                                                                        handleSelect={val =>
                                                                            onPaymentOptionChange(
                                                                                val,
                                                                                setFieldValue,
                                                                                setFieldError
                                                                            )
                                                                        }
                                                                        customStyles={radioStyles}
                                                                    />
                                                                )
                                                            )
                                                        }
                                                    </Field>
                                                </div>
                                            )}
                                        </div>
                                        <div className={cx("formContent")}>
                                            {values.amountType === AMOUNT_TYPES.OTHER && (
                                                <div className={cx("row")}>
                                                    <div className={cx("column")}>
                                                        <Field name="amount">
                                                            {({ field: { name } }) => {
                                                                return (
                                                                    <Input
                                                                        label="USD"
                                                                        onChange={value => {
                                                                            setFieldValue(
                                                                                name,
                                                                                value.event.target.value
                                                                            )
                                                                            setFieldValue(
                                                                                "percentage",
                                                                                getPercentageAmountFromTotal(
                                                                                    value.event.target.value
                                                                                )
                                                                            )
                                                                        }}
                                                                        error={touched.amount && errors.amount}
                                                                        value={values.amount}
                                                                        customStyles={inputAmountStyles}
                                                                        attributes={{
                                                                            required: true
                                                                        }}
                                                                        disableBrowserValidation
                                                                        skipLabelColon
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </div>
                                                    <div className={cx("column")}>
                                                        <Field name="percentage">
                                                            {() => {
                                                                return (
                                                                    <Input
                                                                        label="%"
                                                                        onChange={value =>
                                                                            onPercentageChange(
                                                                                value.event.target.value,
                                                                                setFieldValue,
                                                                                setFieldError
                                                                            )
                                                                        }
                                                                        value={values.percentage}
                                                                        error={touched.percentage && errors.percentage}
                                                                        customStyles={inputPercentageStyles}
                                                                        skipLabelColon
                                                                    />
                                                                )
                                                            }}
                                                        </Field>
                                                    </div>
                                                </div>
                                            )}
                                            <div className={cx("row")}>
                                                <div className={cx("column")}>
                                                    <Field name="method">
                                                        {({ field: { name, onChange, value } }) => {
                                                            return (
                                                                <SelectPaymentMethod
                                                                    customErrorStyles={customErrorStyles}
                                                                    customStyles={selectWithSearchStyles}
                                                                    error={touched.method && errors.method}
                                                                    handleSelect={value => {
                                                                        onChange({ target: { name, value } })
                                                                        setIsApplyStoreCreditsChecked(false)
                                                                    }}
                                                                    label="Payment method"
                                                                    name={name}
                                                                    value={value}
                                                                    availableStoreCreditAmount={
                                                                        availableStoreCreditAmount
                                                                    }
                                                                    isRequired
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                    {values.method.store_credit && (
                                                        <Checkbox
                                                            name="Apply store credit"
                                                            checked={isApplyStoreCreditsChecked}
                                                            onChange={() =>
                                                                setIsApplyStoreCreditsChecked(
                                                                    !isApplyStoreCreditsChecked
                                                                )
                                                            }
                                                            customStyles={checkboxStyles}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                            <div className={cx("row")}>
                                                <div className={cx("column")}>
                                                    <Field name="date">
                                                        {({ field: { name, onChange, value } }) => {
                                                            return (
                                                                <DatePickerWithInput
                                                                    date={value}
                                                                    label="Payment date:"
                                                                    disableClear
                                                                    handleSelectDate={date => {
                                                                        onChange({
                                                                            target: {
                                                                                name,
                                                                                value: date
                                                                            }
                                                                        })
                                                                    }}
                                                                    error={touched.date && errors.date}
                                                                    customStyles={datePickerStyles}
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                </div>
                                                <div className={cx("column")}>
                                                    <Field name="invoice">
                                                        {({ field: { name, onChange, value } }) => {
                                                            return (
                                                                <SelectInvoice
                                                                    handleSelect={value =>
                                                                        onChange({ target: { name, value } })
                                                                    }
                                                                    label="Invoice"
                                                                    name={name}
                                                                    collectionId={collectionId}
                                                                    collectionType={collectionType}
                                                                    value={invoice || value}
                                                                    isDisabled={!!invoice}
                                                                    customStyles={invoiceSelectStyles}
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                </div>
                                            </div>
                                            <div className={cx("row")}>
                                                <div className={cx("column")}>
                                                    <Field name="note">
                                                        {({ field: { name } }) => {
                                                            return (
                                                                <Input
                                                                    label="Staff note"
                                                                    error={touched.note && errors.note}
                                                                    value={values.note}
                                                                    onChange={value => {
                                                                        setFieldValue(name, value.event.target.value)
                                                                    }}
                                                                    customPlaceholder="Click here to add a note..."
                                                                    customStyles={inputStaffNoteStyles}
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                </div>
                                            </div>
                                        </div>
                                    </Body>
                                    <Actions className={cx("actionBar")}>
                                        <button type="button" className={cx("closeButton")} onClick={handleClose}>
                                            Cancel
                                        </button>
                                        <Button
                                            type="submit"
                                            className="first-button"
                                            label="Save"
                                            isLoading={isSubmitting}
                                            isDisabled={
                                                isSubmitting ||
                                                (values.method.store_credit && !isApplyStoreCreditsChecked)
                                            }
                                        />
                                    </Actions>
                                </form>
                            </Fragment>
                        )
                    }}
                </Formik>
            )}
        </Modal>
    )
}

AddEditPayment.defaultProps = {
    isLoading: false
}

AddEditPayment.propTypes = {
    cx: PropTypes.func.isRequired,
    calculations: PropTypes.shape({
        applied: PropTypes.number,
        balance: PropTypes.number,
        total: PropTypes.number,
        wholesale_total: PropTypes.number
    }).isRequired,
    formValues: PropTypes.shape({
        amount: PropTypes.string.isRequired,
        method: PropTypes.object.isRequired,
        date: PropTypes.string.isRequired,
        invoice: PropTypes.object.isRequired,
        note: PropTypes.string.isRequired
    }).isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    isSubmitting: PropTypes.bool,
    isLoading: PropTypes.bool,
    collectionId: PropTypes.number.isRequired,
    collectionType: PropTypes.oneOf(["order", "project"]).isRequired,
    isPO: PropTypes.bool
}

export default withStyles(AddEditPayment, styles)
