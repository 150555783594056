import { combineReducers } from "redux"

import current from "./current"
import list from "./list"

const companiesReducer = combineReducers({
    current,
    list
})

export default companiesReducer
