import React, { useEffect, useMemo } from "react"
import { Link, useLocation, useHistory } from "react-router-dom"
import { useFormik } from "formik"
import _get from "lodash/get"
import { Button, H1, Panel, BackIcon } from "@butterfly-frontend/ui"

import { SaveBar, Textarea, WarningBar } from "ui"
import { useActions } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useStockItemClientReturn } from "modules/WmsModule/hooks/api/useStockItem"
import { scrollToTop } from "helpers/scrollTo"

import ReturnItemList from "./ReturnItemList"
import ReturnDetailsHero from "./ReturnDetailsHero"
import ReturnFinancialTerms from "./ReturnFinancialTerms"
import validationSchema from "./validationSchema"
import textareaStyles from "./overrides/Textarea.module.css"
import styles from "./SalesOrderReturnAddPage.module.css"
import customWarningStyles from "./overrides/WarningBar.module.css"

const SalesOrderReturnAddPage = () => {
    const { state } = useLocation()
    const history = useHistory()
    const actions = useActions({ showErrorNotification, showSuccessNotification })
    const stockItemClientReturn = useStockItemClientReturn()

    useEffect(() => {
        scrollToTop()
    }, [])

    const initialState = useMemo(() => state, [])
    const orderId = useMemo(() => _get(initialState, "id"), [initialState])
    const returnLink = useMemo(() => `/orders/sales-orders/${orderId}`, [orderId])
    const itemsUnavailableNames = useMemo(
        () => _get(initialState, "orderItemsUnavailableForReturn", []).map(item => item.name),
        [initialState.orderItemsUnavailableForReturn]
    )

    const formik = useFormik({
        initialValues: {
            orderId,
            returnTo: null,
            refundMethod: null,
            refundRate: null,
            reason: null,
            description: "",
            items: _get(initialState, "stockItems", [])
        },
        validationSchema,
        onSubmit: ({ orderId, returnTo, reason, description, items, refundMethod, refundRate }) =>
            stockItemClientReturn
                .mutate({
                    ids: items.map(({ id }) => id),
                    orderId,
                    warehouseId: _get(returnTo, "value"),
                    reasonId: _get(reason, "value"),
                    description,
                    refundMethod: _get(refundMethod, "value"),
                    refundRate: _get(refundRate, "value")
                })
                .then(() => {
                    actions.showSuccessNotification({ title: "The selected items has been returned" })
                    history.push({
                        pathname: returnLink,
                        state: {
                            forceFetchOrder: true
                        }
                    })
                })
                .catch(actions.showErrorNotification)
    })

    const isFormDisabled = useMemo(() => formik.isSubmitting || formik.values.items.length === 0, [
        formik.isSubmitting,
        formik.values.items
    ])

    if (!orderId) {
        return <Panel classes={{ panel: styles.errorWrapper }}>Sales Order is not selected</Panel>
    }

    return (
        <form className={styles.root} onSubmit={formik.handleSubmit}>
            <Panel classes={{ panel: styles.panel }}>
                <H1 withLeftBorder>Return</H1>
                {itemsUnavailableNames.length > 0 && (
                    <WarningBar
                        customStyles={customWarningStyles}
                        text={`Selected items cannot be returned: ${itemsUnavailableNames.join(", ")}`}
                    />
                )}
                <ReturnFinancialTerms formik={formik} isDisabled={isFormDisabled} />
                <ReturnDetailsHero
                    salesOrderUuid={_get(initialState, "uuid")}
                    formik={formik}
                    isDisabled={isFormDisabled}
                />
                <ReturnItemList formik={formik} />
                <Textarea
                    label="Description"
                    customStyles={textareaStyles}
                    name="description"
                    placeholder="Click here to add a description..."
                    value={formik.values.description}
                    error={formik.errors.description}
                    onBlur={formik.handleBlur}
                    onChange={({ event }) => formik.handleChange(event)}
                    isDisabled={isFormDisabled}
                />
                <div className={styles.buttonBar}>
                    <Button
                        Icon={BackIcon}
                        type="link"
                        variant="flat"
                        LinkComponent={Link}
                        propsLinkComponent={{ to: returnLink }}
                    >
                        Sales Orders
                    </Button>
                </div>
            </Panel>
            <SaveBar
                isShown
                submitLabel="Save"
                onCancel={() => history.push(returnLink)}
                isDisabled={isFormDisabled}
                isSaving={formik.isSubmitting}
                isSubmit
            />
        </form>
    )
}

export default SalesOrderReturnAddPage
