import React, { useState, useEffect } from "react"
import { connect } from "react-redux"

import {
    Header,
    NavigationList,
    NavigationListingLayout,
    CategoriesListHeader,
    CategoriesListRow
} from "modules/ContentModule/components"

import * as actions from "actions/homePage"
import * as notificationActions from "actions/notification"

const Listing = props => {
    const { isEdited, data, getData, showSuccessNotification, showErrorNotification, match, updateCategories } = props
    const { isLoading, data: categories } = data

    const [isSaving, setIsSaving] = useState(false)
    const [isUploadModelOpen, setIsUploadModelOpen] = useState(false)
    const [categoriesModal, setCategoriesModal] = useState({})

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (categories.length) {
            const prepareCategories = categories.map(({ id, active }) => ({ id, active }))
            setCategoriesModal(prepareCategories)
        }
    }, [categories])

    const updateCategoriesMethod = body => {
        setIsSaving(true)
        updateCategories({ body })
            .then(data => {
                showSuccessNotification()
                getData()
            })
            .catch(() => showErrorNotification())
            .finally(() => {
                setIsSaving(false)
                isUploadModelOpen && setIsUploadModelOpen(false)
            })
    }

    const handleSubmitCatagories = categories => {
        const body = categories.map(({ id, active }) => ({ id, active }))
        updateCategoriesMethod(body)
    }

    const handleUpdateCategories = category => {
        updateCategoriesMethod(category)
    }

    return (
        <NavigationListingLayout>
            <Header title="Menu Categories" />
            <NavigationList
                isLoading={isLoading}
                storeList={categories}
                isSaving={isSaving}
                isEdited={isEdited}
                handleSubmit={handleSubmitCatagories}
                ListHeader={CategoriesListHeader}
                ListRow={CategoriesListRow}
                match={match}
                isUploadModalOpen={isUploadModelOpen}
                setIsUploadModelOpen={setIsUploadModelOpen}
                setCategoriesModal={setCategoriesModal}
                categoriesModal={categoriesModal}
                handleUpdateCategories={handleUpdateCategories}
            />
        </NavigationListingLayout>
    )
}

const mapStateToProps = state => ({
    data: state.db.menuCategories.current
})

const mapDispatchToProps = dispatch => ({
    getData: () => dispatch(actions.getCategories()),
    updateCategories: data => dispatch(actions.updateCategories(data)),
    updateHomePage: data => dispatch(actions.updateHomePage(data)),
    showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
    showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing)
