import * as yup from "yup"

import { INPUT_MAX_LENGTH } from "constants/index"

export const schema = yup.object().shape({
    name: yup
        .string()
        .max(INPUT_MAX_LENGTH, `Name may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .nullable(),
    cover_desktop: yup.object().required("Field is required")
})
