import React, { Fragment } from "react"
import { Field } from "formik"

import withStyles from "HOC/withStyles"
import { Input, DatePickerWithInput } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import BillingAddress from "../BillingAddress"

import inputStyles from "../../overrides/input.css"
import datepickerStyles from "../../overrides/datepicker.css"
import styles from "./styles.css"

const FinancialsSection = props => {
    const { cx, form, isLoadingClient } = props

    return (
        <div className={cx("root")}>
            {isLoadingClient ? (
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            ) : (
                <Fragment>
                    <div className={cx("section")}>
                        <h2 className={cx("header")}>Financial Details</h2>
                        <ul className={cx("financialDetails")}>
                            <li className={cx("budget")}>
                                <Field name="budget">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            type="number"
                                            label="Budget"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                            innerLabel="$"
                                            isInnerLabelLeft
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("minimumPurchase")}>
                                <Field name="minimum_purchase">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            type="number"
                                            label="Minimum purchase"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                            innerLabel="$"
                                            isInnerLabelLeft
                                        />
                                    )}
                                </Field>
                            </li>
                            <li className={cx("discount")}>
                                <Field name="discount">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            type="number"
                                            label="Discount"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                            innerLabel="%"
                                            isInnerLabelRight
                                        />
                                    )}
                                </Field>
                            </li>
                        </ul>
                    </div>
                    <div className={cx("section")}>
                        <h2 className={cx("header")}>Rental</h2>
                        <ul className={cx("rental")}>
                            <li>
                                <Field name="total_rent">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            type="number"
                                            label="Total rental"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                            innerLabel="$"
                                            isInnerLabelLeft
                                        />
                                    )}
                                </Field>
                            </li>
                            <li>
                                <Field name="monthly_rent">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            type="number"
                                            label="Monthly rent"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                            innerLabel="$"
                                            isInnerLabelLeft
                                        />
                                    )}
                                </Field>
                            </li>
                            <li>
                                <Field name="rental_starts_at">
                                    {({ field }) => (
                                        <DatePickerWithInput
                                            date={field.value}
                                            minDate={new Date()}
                                            label="Rental start date:"
                                            handleSelectDate={date =>
                                                field.onChange({ target: { name: field.name, value: date } })
                                            }
                                            disableClear
                                            customStyles={datepickerStyles}
                                            calendarAboveInput
                                        />
                                    )}
                                </Field>
                            </li>
                        </ul>
                    </div>
                    <BillingAddress form={form} />
                </Fragment>
            )}
        </div>
    )
}

export default withStyles(FinancialsSection, styles)
