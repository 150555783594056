import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./MainPage.css"
import stylesProductSearch from "./overrides/ProductSearch.module.css"
import stylesToggle from "./overrides/Toggle.css"
import stylesImagesSection from "./overrides/ImagesSection.css"

import closeIcon from "assets/close.svg"

import { ProductForm } from "../../components"
import { Button, ProductSearch, Toggle } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import Notification from "components/Modal/Notification"
import ImagesSection from "modules/ScraperModule/components/ImagesSection"

class MainPage extends Component {
    render() {
        const {
            cx,
            email,
            activeTake,
            fields,
            handleTake,
            notification,
            handleClose,
            isLoaded,
            currentProductId,
            handleSelectProduct,
            handleResetState,
            handleToggleSaveOnDisk,
            handleDeleteImage,
            isSavingOnDisk,
            sendMessage,
            product
        } = this.props

        if (!isLoaded) {
            return (
                <main className={cx("root", "isLoading")}>
                    <SnakeLoader />
                </main>
            )
        }

        return (
            <main className={cx("root")}>
                <span className={cx("close")} onClick={handleClose}>
                    <img src={closeIcon} alt="close" />
                </span>

                <div className={cx("productSearch")}>
                    <ProductSearch
                        productId={0}
                        customStyles={stylesProductSearch}
                        handleSelectProduct={handleSelectProduct}
                        placeholder="Search product..."
                    />

                    <Button
                        className={cx("second-button", "clearForm")}
                        onClick={() => {
                            window.location.reload()
                        }}
                        label="Clear Form"
                    />

                    {currentProductId && (
                        <Button
                            className={cx("second-button", "resetSelection")}
                            onClick={() => {
                                handleResetState()
                            }}
                            label="Reset Selection"
                        />
                    )}
                </div>

                {currentProductId && product.isLoading && <SnakeLoader />}

                {((currentProductId && !product.isLoading) || !currentProductId) && !isSavingOnDisk && (
                    <ProductForm {...this.props} />
                )}
                {isSavingOnDisk && (
                    <ImagesSection
                        fields={fields}
                        customStyles={stylesImagesSection}
                        activeTake={activeTake}
                        handleTake={handleTake}
                        handleDeleteImage={handleDeleteImage}
                    />
                )}

                {notification.isEnabled && <Notification />}

                <div className={cx("footer", "toggle")}>
                    <span>Save on disk</span>
                    <Toggle customStyles={stylesToggle} isOn={isSavingOnDisk} handleChange={handleToggleSaveOnDisk} />
                </div>

                <div className={cx("footer")}>
                    <span>{email}</span>
                    <Button
                        className={cx("first-button")}
                        onClick={() => {
                            sendMessage({ action: "LOGOUT" })
                            window.localStorage.clear()
                            window.location.href = "/scraper"
                        }}
                        label="Log out"
                    />
                </div>
            </main>
        )
    }
}

export default withStyles(MainPage, styles)
