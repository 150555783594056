import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import idx from "idx"
import moment from "moment"
import _ from "lodash"

import fetch from "helpers/fetch"
import * as rolesActions from "actions/role"
import * as notificationActions from "actions/notification"
import * as loginActions from "actions/login"

import Form from "./Form"

import { INIT_VALUES, TABS, FIELDS_IN_TABS } from "./constants"
import { schema as profileSchema } from "./schema"

const FormContainer = ({ match }) => {
    const dispatch = useDispatch()
    const loginStore = useSelector(state => state.loginStore)
    const roles = useSelector(state => state.roleStore.roles)

    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [countErrors, setCountErrors] = useState({ [TABS.PROFILE]: 0, [TABS.ROLES]: 0, [TABS.NOTIFICATIONS]: 0 })

    const isMyProfile = match.path === "/profile/settings"

    useEffect(() => {
        fetchUser()
        !isMyProfile && dispatch(rolesActions.getRoles())
    }, [])

    const fetchUser = async () => {
        setIsLoading(true)

        try {
            const user = await fetch.get(isMyProfile ? "/profile" : `/user/${match.params.id}`)
            setUser(user.data)
        } catch (error) {
            dispatch(notificationActions.showErrorNotification())
        }

        setIsLoading(false)
    }

    const handleSubmit = async (values, formikActions) => {
        setCountErrors({ [TABS.PROFILE]: 0, [TABS.ROLES]: 0, [TABS.NOTIFICATIONS]: 0 })

        try {
            await profileSchema.validate(values, {
                abortEarly: false
            })

            setIsSubmitting(true)

            const payload = {
                first_name: values.first_name,
                last_name: values.last_name,
                email: user.email,
                birthday: values.birthday,
                phone: values.phone,
                country: values.country,
                address: values.address,
                city: values.city,
                state: values.state,
                post_code: values.post_code,
                old_password: values.isChangingPassword ? values.old_password : undefined,
                password: values.isChangingPassword ? values.password : undefined,
                avatar: values.avatarID,
                permissionGroups: values.roles,
                settings: {
                    notifications: {
                        disabled_calendars: (values.disabled_calendars || []).join(",")
                    },
                    daily_mail: {
                        active: values.daily_mail,
                        mail_at: moment(values.daily_mail_time, "h:mm a").format("HH:mm")
                    },
                    signature: values.signature === idx(user, _ => _.settings.signature) ? undefined : values.signature
                },
                warehouses: values.warehouses ? values.warehouses.map(({ id }) => ({ id })) : undefined
            }

            const saveResult = isMyProfile
                ? await fetch.post("/profile", payload)
                : await fetch.patch(`/user/${match.params.id}`, payload)

            setUser(saveResult.data)

            isMyProfile &&
                updateLoginStore({
                    first_name: saveResult.data.first_name,
                    last_name: saveResult.data.last_name,
                    avatar: saveResult.data.avatar
                })

            dispatch(notificationActions.showSuccessNotification())
            formikActions.resetForm()
        } catch (error) {
            const formErrors =
                error.name === "ValidationError"
                    ? (error.inner || []).reduce((acc, item) => {
                          acc[item.path] = item.message
                          return acc
                      }, {})
                    : Object.keys(error.errors || []).reduce((acc, field) => {
                          acc[field] = error.errors[field][0]
                          return acc
                      }, {})

            dispatch(notificationActions.showErrorNotification())
            calculateErrorCounts(formErrors)
            formikActions.setErrors(formErrors)
        }

        setIsSubmitting(false)
    }

    const updateLoginStore = data => {
        window.localStorage.setItem("userFirstName", data.first_name)
        window.localStorage.setItem("userLastName", data.last_name)
        window.localStorage.setItem("userAvatar", data.avatar)

        dispatch(
            loginActions.loginUserSuccess({
                email: loginStore.email,
                data: {
                    ...data,
                    permissions: loginStore.permissions,
                    roles: loginStore.roles,
                    jwt_token: loginStore.token,
                    user_id: loginStore.userId
                }
            })
        )
    }

    const calculateErrorCounts = errors => {
        const currentErrors = Object.keys(errors).reduce(
            (counter, fieldKey) => ({ ...counter, [FIELDS_IN_TABS[fieldKey]]: counter[FIELDS_IN_TABS[fieldKey]] + 1 }),
            { [TABS.PROFILE]: 0, [TABS.ROLES]: 0, [TABS.NOTIFICATIONS]: 0 }
        )

        if (!_.isEqual(currentErrors, countErrors)) {
            setCountErrors(currentErrors)
        }
    }

    const handleResetPassword = async () => {
        try {
            await fetch.post(`/user/remind-password`, { email: user.email })
            dispatch(notificationActions.showSuccessNotification())
        } catch (error) {
            dispatch(notificationActions.showErrorNotification())
        }
    }

    const calendars = (user || {}).calendars

    const initValues = useMemo(() => {
        if (!user) {
            return INIT_VALUES
        }

        return {
            first_name: user.first_name,
            last_name: user.last_name,
            birthday: user.birthday,
            email: user.email,
            phone: user.phone,

            country: user.country,
            address: user.address,
            post_code: user.post_code,
            city: user.city,
            state: user.state,

            avatar: user.avatar,

            old_password: "",
            password: "",

            roles: user.role || [],

            disabled_calendars: (idx(user, _ => _.settings.notifications.disabled_calendars) || "")
                .split(",")
                .map(item => parseInt(item, 10))
                .filter(number => !isNaN(number) && calendars.some(calendar => calendar.id === number)),

            daily_mail: idx(user, _ => _.settings.daily_mail.active) || false,
            daily_mail_time:
                idx(user, _ => _.settings.daily_mail.mail_at) &&
                moment(user.settings.daily_mail.mail_at, "HH:mm").isValid()
                    ? moment(user.settings.daily_mail.mail_at, "HH:mm").format("h:mm a")
                    : "6:00 am",
            signature: idx(user, _ => _.settings.signature) || "",
            warehouses: user.warehouses
        }
    }, [user])

    return (
        <Form
            initValues={initValues}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isLoading={isLoading}
            isMyProfile={isMyProfile}
            roles={roles}
            calendars={calendars}
            countErrors={countErrors}
            calculateErrorCounts={calculateErrorCounts}
            handleResetPassword={handleResetPassword}
        />
    )
}

export default FormContainer
