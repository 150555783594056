import React, { useState, useEffect } from "react"
import { SelectWithSearch } from "ui"
import fetch from "helpers/fetch"

const SelectSource = props => {
    const { name, value, label, error, customStyles } = props
    const [sources, setSources] = useState([])

    useEffect(() => {
        fetchSources()
    }, [])

    const fetchSources = () => {
        const formatData = data => {
            return data.map(item => ({ id: item.id, value: item.id, label: item.name, secondLabel: "" }))
        }

        fetch.get(`/sources?page=1&length=9999`).then(payload => {
            setSources(formatData(payload.data))
        })
    }

    return (
        <SelectWithSearch
            multiselect
            name={name}
            label={label}
            value={value}
            values={sources}
            error={error}
            setValue={value => props.handleSelect({ target: { name, value } })}
            placeholder="- Please select -"
            customStyles={customStyles}
            withoutFetch
        />
    )
}

export default SelectSource
