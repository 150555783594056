import React, { Component, Fragment } from "react"
import withStyles from "HOC/withStyles"
import styles from "./table.module.css"
import modalStyles from "./overrides/modal.modeule.css"
import Modal from "components/Modal/Modal"
import { List, Sort } from "./components"
import Confirm from "components/Modal/Confirm/Confirm"
import InfiniteScroll from "ui/InfiniteScroll"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

class Table extends Component {
    sortKeys = [
        { type: "last_name", title: "Contact Name" },
        { title: "Email" },
        { title: "Phone" },
        { type: "created_at", title: "Added" },
        { type: "updated_at", title: "Last Updated" }
    ]

    render() {
        const { cx, deleteModalOpen, currentClientDelete, handleSetSorting, currentSorting } = this.props

        return (
            <Fragment>
                {deleteModalOpen && currentClientDelete && this.renderModal()}
                <section className={cx("root")}>
                    <h2>Members of this client group</h2>
                    <Sort sortKeys={this.sortKeys} currentSorting={currentSorting} setSorting={handleSetSorting} />
                    {this.renderList()}
                </section>
            </Fragment>
        )
    }

    renderList = () => {
        const {
            cx,
            isLoading,
            list,
            handleOpenModal,
            clients,
            handleLoadMore,
            customSnakeLoaderStyles,
            isLoadingMore
        } = this.props
        const { current_page, last_page } = clients.meta

        if (isLoadingMore || (isLoading && !list.length)) {
            return <SnakeLoader customStyles={customSnakeLoaderStyles} />
        }

        if (!Array.isArray(list) || !list.length) {
            return <div className={cx("root", "empty")}>List is empty.</div>
        }

        return (
            <InfiniteScroll hasMore={!isLoading && current_page < last_page} loadMore={handleLoadMore}>
                <List list={list} handleOpenModal={handleOpenModal} />
            </InfiniteScroll>
        )
    }

    renderModal = () => {
        const { currentClientDelete, handleHideModal, handleDelete } = this.props
        const member = `${currentClientDelete.first_name} ${currentClientDelete.last_name}`

        return (
            <Modal>
                <Confirm
                    id={currentClientDelete.id}
                    customStyles={modalStyles}
                    confirmModalTitle={"Delete this member of this client group"}
                    titleSecondLine={
                        <span>
                            Are you sure you want to delete the member "<strong>{member}</strong>"? This action cannot
                            be reversed.
                        </span>
                    }
                    handleHideModal={() => handleHideModal()}
                    handleDelete={handleDelete}
                />
            </Modal>
        )
    }
}

export default withStyles(Table, styles)
