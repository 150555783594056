export const AVAILABILITY_STATUSES = [
    {
        label: "In Stock",
        value: "in_stock"
    },
    {
        label: "Available for order",
        value: "available_for_order"
    },
    {
        label: "Incoming",
        value: "incoming"
    }
]
