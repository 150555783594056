import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./Select.css"
import arrow from "assets/arrow.svg"
import clear from "assets/delete.svg"

class Select extends Component {
    state = {
        isExpanded: false
    }

    handleToggle = () => {
        this.setState(
            {
                isExpanded: !this.state.isExpanded
            },
            () => {
                document.addEventListener("click", this.handleHideDropdown)
            }
        )
    }

    handleHideDropdown = event => {
        if (this.statesDropdown && !this.statesDropdown.contains(event.target)) {
            this.setState(
                {
                    isExpanded: false
                },
                () => {
                    document.removeEventListener("click", this.handleHideDropdown)
                }
            )
        }
    }

    handleClear = event => {
        const { onClick, fieldPath } = this.props
        onClick &&
            onClick({
                event: {
                    target: {
                        value: ""
                    }
                },
                fieldPath
            })
        this.setState({
            searchPhrase: ""
        })
        event.stopPropagation()
    }

    render() {
        const { isExpanded } = this.state
        const {
            fieldPath,
            defaultValue,
            isLabeledValue,
            isInline,
            isDisabled,
            value,
            values,
            isClear,
            onClick,
            label,
            cx,
            isLabel,
            error,
            handleScrollToError,
            attributes
        } = this.props

        let visibleValue = !!value ? value : defaultValue
        if (isLabeledValue) {
            const searchLabelForValue = values.find(item => item.value === value)
            visibleValue = searchLabelForValue ? searchLabelForValue.label : visibleValue
        }

        const isEmpty = !values || (values && !values.length)
        const isChosenValue = typeof value === "number"
        let formattedLabel = `${label}${attributes && attributes.required ? " *" : ""}`

        return (
            <div className={cx("root", { error: error, isInline, isEmpty, isDisabled })}>
                {isLabel && label && <label onClick={() => !isEmpty && this.handleToggle()}>{formattedLabel}:</label>}
                <div
                    className={cx("input")}
                    onClick={() => !isEmpty && this.handleToggle()}
                    ref={element => {
                        this.statesDropdown = element
                    }}
                >
                    {isClear && isChosenValue && !isDisabled && (
                        <img
                            src={clear}
                            alt="clear"
                            className={cx("clear")}
                            onClick={event => this.handleClear(event)}
                        />
                    )}

                    <h2 className={cx("selected-value", { default: !value })}>{visibleValue}</h2>
                    {(!isClear || (isClear && !isChosenValue)) && (
                        <img src={arrow} alt="choose" className={cx(isExpanded ? "arrow-down" : "arrow-up")} />
                    )}
                    {isExpanded && (
                        <ul>
                            {values.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={event =>
                                        onClick &&
                                        onClick({
                                            event: {
                                                target: {
                                                    value: item.value
                                                }
                                            },
                                            fieldPath
                                        })
                                    }
                                >
                                    {item.label}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                {error && (
                    <h2
                        className={cx("error-message")}
                        ref={el => {
                            this.errorInput = el
                        }}
                    >
                        {error}
                        {handleScrollToError && handleScrollToError(this.errorInput)}
                    </h2>
                )}
            </div>
        )
    }
}

export default withStyles(Select, styles)
