import React, { useState } from "react"
import { Formik, Form as FormikForm } from "formik"
import PropTypes from "prop-types"

import SectionModalImageWithProduct from "./SectionModalImageWithProduct"
import { section as sectionSchema } from "./schema"

const Container = ({ onClose, onSave, data }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = {
        type: "image_with_product",
        settings: {
            alignment: "left",
            heading: "",
            subheading: ""
        },
        product_id: null,
        single_image: null,
        single_image_product: null
    }

    const onSubmit = async (values, formikActions) => {
        try {
            setIsSubmitting(true)

            await onSave(
                {
                    ...values,
                    single_image: values.single_image ? values.single_image.id : null,
                    single_image_product: values.single_image_product ? values.single_image_product.id : null
                },
                values
            )
            onClose()
        } catch (error) {
            setIsSubmitting(false)
            formikActions.setErrors(error)
        }
    }

    return (
        <Formik
            initialValues={{ ...initialValues, ...data }}
            validationSchema={sectionSchema}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {propsForm => (
                <FormikForm>
                    <SectionModalImageWithProduct onClose={onClose} form={propsForm} isSubmitting={isSubmitting} />
                </FormikForm>
            )}
        </Formik>
    )
}

Container.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default Container
