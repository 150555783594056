import withCrud from "HOC/withCrud"

import Form from "./Form/index"
import Listing from "./Listing/index"
import View from "./View/index"

export default withCrud({
    routePrefix: "/settings/combination-template",
    idParamName: "combinationTemplateId",
    Form,
    Listing,
    View
})
