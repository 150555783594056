import React from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"

import useStyles from "helpers/getCxFromStyles"

import { Checkbox } from "ui"
import { DropdownThreeDots } from "modules/AttributeGroupsModules/components"
import { ACTION_TYPES } from "modules/AttributeGroupsModules/constants"

import dragItemIcon from "assets/drag-item.svg"
import editIcon from "assets/pencil.svg"
import styles from "./ItemList.css"
import checkboxStyles from "./overrides/Checkbox.css"

const ItemList = props => {
    const { item, children, onCheck, onAction, isDragDisabled, isMovementActionsDisabled } = props
    const cx = useStyles(styles)

    return (
        <div className={cx("root")}>
            <Checkbox onChange={onCheck} checked={item.checked} customStyles={checkboxStyles} />
            <ReactSVG
                src={dragItemIcon}
                alt="Drag and drop"
                className={cx("dragItemIcon", { disabled: isDragDisabled })}
            />
            <div className={cx("data")}>{children}</div>
            <div className={cx("actions")}>
                <ReactSVG
                    src={editIcon}
                    alt="Edit"
                    className={cx("editIcon")}
                    onClick={() => onAction(ACTION_TYPES.EDIT, item)}
                />
                <div className={cx("divider")} />
                <DropdownThreeDots
                    items={
                        isMovementActionsDisabled
                            ? []
                            : [
                                  { label: "Move to", onClick: () => onAction(ACTION_TYPES.MOVE, item) },
                                  { label: "Copy to", onClick: () => onAction(ACTION_TYPES.COPY, item) }
                              ]
                    }
                    dangerItems={[{ label: "Delete", onClick: () => onAction(ACTION_TYPES.DELETE, item) }]}
                />
            </div>
        </div>
    )
}

ItemList.propTypes = {
    item: PropTypes.shape({
        id: PropTypes.number.isRequired,
        checked: PropTypes.bool.isRequired
    }).isRequired,
    children: PropTypes.element.isRequired,
    onCheck: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    isDragDisabled: PropTypes.bool,
    isMovementActionsDisabled: PropTypes.bool
}

export default ItemList
