import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"

import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"
import { Modals } from "modules/AttributeGroupsModules/components"
import { ACTION_TYPES, LIST_TYPES } from "modules/AttributeGroupsModules/constants"

const ModalsWrapper = props => {
    const {
        openModal,
        listType,
        handleAction,
        handleToggleModal,
        handleOpenAttributeModal,
        isLoadingAction,
        oneItem,
        manyItems,
        resource,
        fetchStatusResource
    } = props

    const params = useParams()
    const checkIsOpen = types => types.some(type => type === openModal)

    return (
        <Fragment>
            {checkIsOpen([ACTION_TYPES.DELETE]) && (
                <ConfirmWithSubject
                    isCloseIcon
                    title={oneItem ? "Delete this item?" : "Delete this items?"}
                    description="Are you sure you want to delete the"
                    subject={(oneItem && oneItem.name) || manyItems.map(item => item.name)}
                    descriptionClosure="? This action cannot be reversed."
                    handleHideModal={() => handleToggleModal()}
                    handleDelete={() => handleAction(ACTION_TYPES.DELETE)}
                    actionButtonLabel="Delete"
                    isLoading={isLoadingAction}
                />
            )}
            {listType === LIST_TYPES.ATTRIBUTE_VALUE && fetchStatusResource.isLoaded && (
                <Modals.AttributeValuesForm
                    isOpen={checkIsOpen([ACTION_TYPES.CREATE, ACTION_TYPES.EDIT])}
                    onClose={() => handleToggleModal()}
                    attribute={resource}
                    initialValues={oneItem}
                    handleSuccess={() => handleAction(ACTION_TYPES.CREATE)}
                />
            )}
            {listType === LIST_TYPES.ATTRIBUTE &&
                fetchStatusResource.isLoaded &&
                checkIsOpen([ACTION_TYPES.CREATE]) && (
                    <Modals.AttributeChooserModal
                        actionType="addAttribute"
                        onClose={() => handleToggleModal()}
                        onSubmit={items => handleAction(openModal, items)}
                        handleOpenAttributeModal={handleOpenAttributeModal}
                        isSubmitting={isLoadingAction}
                    />
                )}
            {(listType === LIST_TYPES.GROUP || listType === LIST_TYPES.SUBGROUP) &&
                checkIsOpen([ACTION_TYPES.CREATE, ACTION_TYPES.EDIT]) && (
                    <Modals.GroupModal
                        onClose={() => handleToggleModal()}
                        onSuccess={response => handleAction(openModal, response)}
                        initialValues={{
                            ...(oneItem || {}),
                            parent_attribute_group_id: params.groupId
                        }}
                    />
                )}
            {listType === LIST_TYPES.ATTRIBUTE && checkIsOpen([ACTION_TYPES.EDIT, ACTION_TYPES.CREATE_ATTRIBUTE]) && (
                <Modals.AttributeModal
                    onClose={() => handleToggleModal()}
                    onSuccess={() => handleAction(openModal)}
                    initialValues={{
                        ...(oneItem || {}),
                        group_id: params.subgroupId
                    }}
                />
            )}
            {checkIsOpen([ACTION_TYPES.COPY, ACTION_TYPES.MOVE]) && listType !== "group" && (
                <Modals.AttributeChooserModal
                    actionType={openModal}
                    maxStep={listType === LIST_TYPES.SUBGROUP ? 1 : listType === LIST_TYPES.ATTRIBUTE ? 2 : 3}
                    onClose={() => handleToggleModal()}
                    onSubmit={items => handleAction(openModal, items[0])}
                    isSubmitting={isLoadingAction}
                />
            )}
        </Fragment>
    )
}

ModalsWrapper.defaultProps = {
    additionalData: {}
}

ModalsWrapper.propTypes = {
    oneItem: PropTypes.object,
    manyItems: PropTypes.arrayOf(PropTypes.object),
    resource: PropTypes.object,
    fetchStatusResource: PropTypes.shape({
        isLoading: PropTypes.bool,
        isLoaded: PropTypes.bool
    }).isRequired,
    listType: PropTypes.string.isRequired,
    openModal: PropTypes.string.isRequired,
    isLoadingAction: PropTypes.bool.isRequired,
    handleAction: PropTypes.func.isRequired,
    handleToggleModal: PropTypes.func.isRequired,
    handleOpenAttributeModal: PropTypes.func.isRequired
}

export default ModalsWrapper
