import React, { Component } from "react"
import PropTypes from "prop-types"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import ReactSVG from "react-svg"

import withStyles from "HOC/withStyles"

import styles from "./DragAndDropList.css"
import dragItemIcon from "assets/drag-item.svg"

class DragAndDropList extends Component {
    render() {
        const { cx, children, disabled, withBorder } = this.props

        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={this.getListStyle(snapshot.isDraggingOver)}
                        >
                            {children.map((child, index) => (
                                <Draggable key={index} draggableId={index} index={index} isDragDisabled={disabled}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={this.getItemStyle(
                                                snapshot.isDragging,
                                                index === 0,
                                                withBorder,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div className={cx("row", { isDragging: snapshot.isDragging })}>
                                                <ReactSVG
                                                    src={dragItemIcon}
                                                    alt="Drag and drop"
                                                    className={cx("dragItemIcon", { disabled })}
                                                />
                                                {child}
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }

    onDragEnd = result => {
        const { itemsList, handleReorder } = this.props

        if (!result.destination) {
            return
        }

        const newItemsList = Array.from(itemsList)
        const [removed] = newItemsList.splice(result.source.index, 1)
        newItemsList.splice(result.destination.index, 0, removed)

        handleReorder(newItemsList)
    }

    getItemStyle = (isDragging, isFirstElement, withBorder, draggableStyle) => ({
        userSelect: "none",
        borderTop: (!isFirstElement || isDragging) && withBorder ? "1px solid #e6e8ed" : null,
        ...draggableStyle
    })

    getListStyle = () => ({
        width: "100%"
    })
}

DragAndDropList.defaultProps = {
    withBorder: true
}

DragAndDropList.propTypes = {
    handleReorder: PropTypes.func.isRequired,
    itemsList: PropTypes.array.isRequired,
    withBorder: PropTypes.bool,
    children: PropTypes.arrayOf(PropTypes.node).isRequired
}

export default withStyles(DragAndDropList, styles)
