import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import {
    formatDimensionToImperial,
    formatDimensionToMetric,
    formatWeightToImperial,
    formatWeightToMetric
} from "helpers/units"
import { Input, Toggle } from "ui"
import forceEmptyDecimalValue from "helpers/forceEmptyDecimalValue"
import { FormSection } from "modules/WmsModule/modals/components"
import { H2, LengthIcon, WidthIcon, HeightIcon } from "@butterfly-frontend/ui"

import styles from "./DimensionsForm.module.css"

const prepareNumber = value => (typeof value === "string" ? value.replace(",", ".") : value)

const getDimensionInputPropsFactory = formik => ({ name, conv_name, dimensionConverter = formatDimensionToMetric }) => {
    const { setFieldValue, values, errors } = formik

    return {
        name,
        value: forceEmptyDecimalValue(values[conv_name]),
        errors: errors[name],
        onChange: ({ event }) => {
            setFieldValue(conv_name, event.target.value)
            const preparedValue = prepareNumber(event.target.value)
            setFieldValue(name, values.useImperial ? dimensionConverter(preparedValue) : preparedValue)
        },
        innerLabel: values.useImperial ? "inch" : "cm"
    }
}

const DimensionsForm = ({ formik }) => {
    const { setFieldValue, values } = formik
    const getDimensionInputProps = getDimensionInputPropsFactory(formik)

    return (
        <FormSection
            withBorder
            title={<H2 classes={{ h2: styles.sectionTitle }}>Dimensions & Weight</H2>}
            inputs={
                <Toggle
                    isSmall
                    isOn={values.useImperial}
                    labelPosition="left"
                    label={{ on: "Use inches", off: "Use inches" }}
                    handleChange={() => {
                        const useImperial = !values.useImperial
                        setFieldValue("useImperial", useImperial)
                        setFieldValue(
                            "conv_width",
                            useImperial ? formatDimensionToImperial(values.width) : values.width
                        )
                        setFieldValue(
                            "conv_height",
                            useImperial ? formatDimensionToImperial(values.height) : values.height
                        )
                        setFieldValue(
                            "conv_length",
                            useImperial ? formatDimensionToImperial(values.length) : values.length
                        )
                        setFieldValue(
                            "conv_weight",
                            useImperial ? formatWeightToImperial(values.weight) : values.weight
                        )
                    }}
                />
            }
        >
            <div className={styles.dimensionInputs}>
                <Input
                    {...getDimensionInputProps({ name: "length", conv_name: "conv_length" })}
                    label="Length"
                    className={styles.dimensionInput}
                    icon={<LengthIcon className={styles.dimensionIcon} />}
                />
                <Input
                    {...getDimensionInputProps({ name: "width", conv_name: "conv_width" })}
                    label="Width"
                    className={classNames(styles.dimensionInput, styles["length-icon"])}
                    icon={<WidthIcon className={styles.dimensionIcon} />}
                />
                <Input
                    {...getDimensionInputProps({ name: "height", conv_name: "conv_height" })}
                    label="Height"
                    className={styles.dimensionInput}
                    icon={<HeightIcon className={styles.dimensionIcon} />}
                />
                <Input
                    {...getDimensionInputProps({
                        name: "weight",
                        conv_name: "conv_weight",
                        dimensionConverter: formatWeightToMetric
                    })}
                    label="Weight"
                    innerLabel={values.useImperial ? "lbs" : "kg"}
                />
            </div>
        </FormSection>
    )
}

DimensionsForm.propTypes = {
    formik: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.shape({
            useImperial: PropTypes.bool,
            length: PropTypes.number,
            width: PropTypes.number,
            height: PropTypes.number,
            weight: PropTypes.number,
            conv_length: PropTypes.number,
            conv_width: PropTypes.number,
            conv_height: PropTypes.number,
            conv_weight: PropTypes.number
        }).isRequired
    }).isRequired
}

export default DimensionsForm
