import React from "react"
import PropTypes from "prop-types"

import styles from "./ProductListItem.module.css"

const ProductListItem = ({ children }) => {
    return <li className={styles.productListItem}>{children}</li>
}

ProductListItem.propTypes = {
    children: PropTypes.node.isRequired
}

export default ProductListItem
