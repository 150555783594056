import React, { Fragment, useState, useMemo } from "react"
import PropTypes from "prop-types"

import {
    Button,
    CommentIcon,
    GridIcon,
    EmptyInternalList,
    Status,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeadCell,
    TableRow,
    TableSortableHeadCell,
    useTableSort
} from "@butterfly-frontend/ui"
import { useActions } from "hooks"
import { showSuccessNotification } from "actions/notification"
import { useStockItemList } from "modules/WmsModule/hooks/api/useStockItem"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import STOCK_ITEM_STATUSES from "modules/WmsModule/constants/stockItemStatuses"
import { SnakeLoader } from "ui/Skeleton"
import { Pagination } from "ui"

import CombinationDetails from "./CombinationDetails/CombinationDetails"
import styles from "./StockItemsList.module.css"
import StockItemNoteModal from "./StockItemNoteModal/StockItemNoteModal"

const StockItemsList = ({ productId, selectedCombination }) => {
    const [displayedCombinationDetailsId, setDisplayedCombinationDetailsId] = useState(null)
    const [stockItemForNote, setStockItemForNote] = useState(null)
    const actions = useActions({ showSuccessNotification })

    const { sortState: stockItemsSortState, onChangeSort: onChangeStockItemsSort } = useTableSort({
        initialState: { direction: "asc", by: "id" }
    })

    const handleCloseNoteModal = () => setStockItemForNote(null)

    const handleSuccessEditNote = () => {
        actions.showSuccessNotification({ title: "Note added successfully." })
        handleCloseNoteModal()
    }

    const filters = useMemo(
        () => ({
            products: productId,
            combination_id: selectedCombination ? selectedCombination.id : undefined
        }),
        [productId, selectedCombination]
    )

    const {
        data,
        meta,
        fetchStatus,
        pagination,
        query: { error }
    } = useTableLogic({
        filters,
        sort: stockItemsSortState,
        useQueryList: useStockItemList
    })

    if (fetchStatus.isError) {
        return <p>{error.statusText}</p>
    }

    if (fetchStatus.isLoading) {
        return <SnakeLoader />
    }

    return (
        <Fragment>
            <div className={styles.stockItemsList}>
                {data.length === 0 ? (
                    <EmptyInternalList text="Not found stock items" Icon={GridIcon} />
                ) : (
                    <Table>
                        <TableHead>
                            <TableHeadCell minWidth={40} width={40}>
                                NO.
                            </TableHeadCell>
                            <TableSortableHeadCell
                                columnKey="id"
                                sortState={stockItemsSortState}
                                onClick={onChangeStockItemsSort}
                                minWidth={120}
                                width="12%"
                            >
                                ID
                            </TableSortableHeadCell>
                            <TableSortableHeadCell
                                columnKey="warehouse"
                                sortState={stockItemsSortState}
                                onClick={onChangeStockItemsSort}
                                minWidth={120}
                                width="12%"
                            >
                                WAREHOUSE
                            </TableSortableHeadCell>
                            <TableHeadCell minWidth={120} width="12%">
                                CONDITION
                            </TableHeadCell>
                            <TableHeadCell minWidth={120} width="12%">
                                STATUS
                            </TableHeadCell>
                            <TableHeadCell minWidth={120} width="21%">
                                COMBINATION
                            </TableHeadCell>
                            <TableHeadCell minWidth={120} width="24%">
                                NOTE
                            </TableHeadCell>
                            <TableHeadCell minWidth={40} width={40} />
                        </TableHead>
                        <TableBody>
                            {data.map((stockItem, index) => {
                                const status = STOCK_ITEM_STATUSES[stockItem.status]

                                return (
                                    <TableRow key={stockItem.id}>
                                        <TableCell minWidth={40} width={40}>
                                            {meta.from + index}
                                        </TableCell>
                                        <TableCell minWidth={120} width="12%">
                                            {stockItem.id}
                                        </TableCell>
                                        <TableCell minWidth={120} width="12%">
                                            {stockItem.warehouse.name}
                                        </TableCell>
                                        <TableCell minWidth={120} width="12%">
                                            <Status
                                                theme="outline"
                                                color={stockItem.condition.color}
                                                size="small"
                                                withCircle
                                            >
                                                {stockItem.condition.name}
                                            </Status>
                                        </TableCell>
                                        <TableCell minWidth={120} width="12%">
                                            <Status theme="outline" color={status.color} size="small" withCircle>
                                                {status.label}
                                            </Status>
                                        </TableCell>
                                        <TableCell minWidth={120} width="21%">
                                            {stockItem.combination ? (
                                                <Fragment>
                                                    <Button
                                                        classes={{ button: styles.combinationButton }}
                                                        onClick={() => setDisplayedCombinationDetailsId(stockItem.id)}
                                                    >
                                                        Details
                                                    </Button>
                                                    {displayedCombinationDetailsId === stockItem.id && (
                                                        <CombinationDetails
                                                            onClose={() => setDisplayedCombinationDetailsId(null)}
                                                            combinationId={stockItem.combination.id}
                                                        />
                                                    )}
                                                </Fragment>
                                            ) : (
                                                <span className={styles.nonAvailableInfo}>N/A</span>
                                            )}
                                        </TableCell>
                                        <TableCell minWidth={120} width="24%">
                                            {stockItem.note || ""}
                                        </TableCell>
                                        <TableCell minWidth={40} width={40}>
                                            <Button
                                                Icon={CommentIcon}
                                                onClick={() => setStockItemForNote(stockItem)}
                                                classes={{ button: styles.addNoteButton }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        {pagination.canDisplay && (
                            <div className={styles.paginationWrapper}>
                                <Pagination {...pagination} />
                            </div>
                        )}
                    </Table>
                )}
            </div>
            {!!stockItemForNote && (
                <StockItemNoteModal
                    stockItemId={stockItemForNote.id}
                    stockItemNote={stockItemForNote.note}
                    onSuccess={handleSuccessEditNote}
                    onClose={handleCloseNoteModal}
                />
            )}
        </Fragment>
    )
}

StockItemsList.propTypes = {
    productId: PropTypes.number.isRequired,
    selectedCombination: PropTypes.shape({
        id: PropTypes.number.isRequired
    })
}

export default StockItemsList
