import React, { Fragment, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { addProductToSelection, removeProductFromSelection, addProductsToSelection } from "./../../actions"

const AddToSelectionButtonContainer = props => {
    const { product, isMulti } = props
    const { id } = product || { id: -1 }

    const [disabled, setDisabled] = useState(false)

    const dispatch = useDispatch()

    const products = useSelector(state => state.db.selectionsWidget.products)
    const current = useSelector(state => state.db.selectionsWidget.current)
    const chooseSelectionFlow = useSelector(state => state.db.selectionsWidget.chooseSelectionFlow)
    const { lock_counter } = products
    const { shouldStart: flowStatus } = chooseSelectionFlow
    const lock = products.lock_counter !== 0

    useEffect(() => {
        if (lock_counter === 0 || !flowStatus) {
            setDisabled(false)
        }
    }, [lock_counter, flowStatus])

    const isInSelection =
        current.choosenSelection !== null && products.fetchStatus.isLoaded
            ? !!products.data.find(product => product.id === id)
            : null

    const handleClick = selectedProducts => {
        dispatch(
            isMulti
                ? addProductsToSelection(selectedProducts)
                : isInSelection
                ? removeProductFromSelection(product)
                : addProductToSelection(product)
        )
    }

    return (
        <Fragment>
            {props.children({ handleClick, fetchStatus: products.fetchStatus, isInSelection, disabled, lock })}
        </Fragment>
    )
}

export default AddToSelectionButtonContainer
