import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"

import withStyles from "HOC/withStyles"

import { Modal, Button, Textarea, WysiwygEditor } from "ui"

import { Image } from "./components"
import ShowProductLink from "../ShowProductLink"

import styles from "./styles.css"
import headingTextareaStyles from "./overrides/headingTextarea.css"

const IMAGE_DIMENSIONS = {
    width: 685
}

const SectionModalImageWithDescription = ({ onClose, cx, form, isSubmitting }) => {
    const descriptionNode = useRef(null)
    const descriptionMoreNode = useRef(null)
    const setImage = (field, image) => {
        form.setFieldValue(field, image)
        form.setErrors({
            ...form.errors,
            [field]: undefined
        })
    }

    const [description, setDescription] = useState("")
    const [descriptionMore, setDescriptionMore] = useState("")

    useEffect(() => {
        setDescription(form.values.settings.description || "")
        setDescriptionMore(form.values.settings.description_more || "")
    }, [])

    const handleUpdateDescription = value => {
        setDescription(value)
        form.setFieldValue("settings.description", value)
    }

    const handleUpdateDescriptionMore = value => {
        setDescriptionMore(value)
        form.setFieldValue("settings.description_more", value)
    }

    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Image with description</p>

            <Image
                form={form}
                setImage={image => setImage("description_image", image)}
                image={form.values.description_image}
                isError={!!form.errors.description_image}
                imageDimensions={IMAGE_DIMENSIONS}
            />

            <Field name="settings.heading">
                {({ field, meta }) => (
                    <Textarea
                        {...field}
                        label="Heading"
                        onChange={({ event }) => {
                            form.setFieldError("settings.heading", null)
                            field.onChange(event)
                        }}
                        customStyles={headingTextareaStyles}
                        error={meta.error}
                    />
                )}
            </Field>

            <div className={cx("description")} ref={descriptionNode}>
                <span className={cx("label")}>Description:</span>
                <Field name="settings.description">
                    {({ field, meta }) => (
                        <WysiwygEditor
                            value={description}
                            onChange={handleUpdateDescription}
                            error={meta.error}
                            customModules={{
                                toolbar: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "link",
                                    "clean"
                                    // { color: ["#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2"] }
                                ]
                            }}
                            customFormats={["bold", "italic", "underline", "link"]}
                            bounds={descriptionNode.current}
                        />
                    )}
                </Field>
            </div>

            <div className={cx("description")} ref={descriptionMoreNode}>
                <span className={cx("label")}>Description more:</span>
                <Field name="settings.description_more">
                    {({ field, meta }) => (
                        <WysiwygEditor
                            value={descriptionMore}
                            onChange={handleUpdateDescriptionMore}
                            error={meta.error}
                            customModules={{
                                toolbar: ["bold", "italic", "underline", "link", "clean"]
                            }}
                            customFormats={["bold", "italic", "underline", "link"]}
                            bounds={descriptionMoreNode.current}
                        />
                    )}
                </Field>
            </div>

            <ShowProductLink />

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    label="Save"
                    onClick={form.submitForm}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

SectionModalImageWithDescription.propTypes = {
    onClose: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(SectionModalImageWithDescription, styles)
