import {
    DELETE_INVOICE_START,
    DELETE_INVOICE_SUCCESS,
    DELETE_INVOICE_ERROR,
    GET_INVOICES_START,
    GET_INVOICES_SUCCESS,
    GET_INVOICES_ERROR,
    GET_INVOICE_START,
    GET_INVOICE_SUCCESS,
    GET_INVOICE_ERROR
} from "../constants"
import fetch from "../helpers/fetch"
import { objToQueryString } from "../helpers/urls"

const getInvoicesStart = () => ({ type: GET_INVOICES_START })
const getInvoicesSuccess = data => ({ type: GET_INVOICES_SUCCESS, payload: data })
const getInvoicesError = error => ({ type: GET_INVOICES_ERROR, payload: error })

export const getInvoices = (params = {}) => {
    return dispatch => {
        dispatch(getInvoicesStart())
        return fetch
            .get(`/invoices?${objToQueryString(params, true)}`)
            .then(data => {
                dispatch(getInvoicesSuccess(data))
            })
            .catch(error => {
                dispatch(getInvoicesError(error))
            })
    }
}

const deleteInvoiceStart = () => ({ type: DELETE_INVOICE_START })
const deleteInvoiceSuccess = data => ({ type: DELETE_INVOICE_SUCCESS, payload: data })
const deleteInvoiceError = error => ({ type: DELETE_INVOICE_ERROR, payload: error })

export const deleteInvoice = id => {
    return dispatch => {
        dispatch(deleteInvoiceStart())
        return fetch
            .deleteRAW(`/invoices/${id}`)
            .then(() => {
                dispatch(deleteInvoiceSuccess(id))
            })
            .catch(error => {
                dispatch(deleteInvoiceError(error))
            })
    }
}

const getInvoiceStart = () => ({ type: GET_INVOICE_START })
const getInvoiceSuccess = data => ({ type: GET_INVOICE_SUCCESS, payload: data })
const getInvoiceError = error => ({ type: GET_INVOICE_ERROR, payload: error })
export const getInvoice = (id, forceFetch = false) => {
    return (dispatch, getState) => {
        const currentInvoice = getState().db.invoices.current

        if (!forceFetch && currentInvoice.data.id === Number(id)) {
            return Promise.resolve(currentInvoice)
        }

        dispatch(getInvoiceStart())
        return new Promise((resolve, reject) => {
            fetch
                .get(`/invoices/${id}`)
                .then(data => {
                    dispatch(getInvoiceSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(getInvoiceError(error))
                    reject(error)
                })
        })
    }
}

export const createInvoice = body => dispatch => {
    return new Promise((resolve, reject) => {
        fetch
            .post("/invoices", body)
            .then(data => {
                dispatch(getInvoiceSuccess(data))
                resolve(data)
            })
            .catch(reject)
    })
}

export const updateInvoice = (id, body) => dispatch => {
    return new Promise((resolve, reject) => {
        fetch
            .patch(`/invoices/${id}`, body)
            .then(data => {
                dispatch(getInvoiceSuccess(data))
                resolve(data)
            })
            .catch(reject)
    })
}
