export const scrollToError = errorInput => {
    return errorInput ? errorInput.scrollIntoView({ behavior: "smooth", block: "center" }) : null
}

export const scrollToTop = options => {
    const smooth = options ? (options.smooth ? options.smooth : true) : true
    const c = document.documentElement.scrollTop || document.body.scrollTop
    if (c > 0) {
        smooth && window.requestAnimationFrame(scrollToTop)
        window.scrollTo(0, c - c / 8)
    }
}
