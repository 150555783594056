import classNames from "classnames/bind"

const mergeCx = (baseCx, customCx) => {
    return (...styles) => {
        const classes = [baseCx(styles), customCx(styles)]
        return classes.join(" ")
    }
}

const getCxFromStyles = (styles, customStyles = {}) => {
    const cx = classNames.bind(styles)
    const customCx = classNames.bind(customStyles)
    const areCustomStyles = customStyles && typeof customStyles === "object" && Object.keys(customStyles).length > 0

    if (!areCustomStyles) {
        return cx
    }

    return mergeCx(cx, customCx)
}

export default getCxFromStyles
