import idx from "idx"
import * as constants from "constants/index"

function notification(state = {}, action) {
    switch (action.type) {
        case constants.SHOW_ERROR_NOTIFICATION:
            const title =
                typeof idx(action, _ => _.payload.data) === "string"
                    ? action.payload.data
                    : idx(action, _ => _.payload.data.title) || "Something went wrong"

            return {
                type: "error",
                isEnabled: true,
                title,
                message: idx(action, _ => _.payload.data.message) || ""
            }
        case constants.SHOW_SUCCESS_NOTIFICATION:
            return {
                type: "success",
                isEnabled: true,
                title: ((action.payload || {}).data || {}).title || "Saved",
                message: ""
            }
        case constants.SHOW_INFO_NOTIFICATION:
            return {
                type: "info",
                isEnabled: true,
                title: ((action.payload || {}).data || {}).title || "Saved",
                message: ""
            }
        case constants.HIDE_NOTIFICATION:
            return {
                ...state,
                isEnabled: false
            }
        default:
            return state
    }
}

export default notification
