import { useCallback, useMemo, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useActions } from "hooks"

import * as fetchStatusHelper from "helpers/fetchStatus"
import {
    fetchProducts as fetchProductsAction,
    fetchMoreProducts,
    getProductsSubscriptionFactory
} from "components/Modal/ChooseProduct/actions"

const useProductList = () => {
    const products = useSelector(state => state.ui.modals.chooseProduct.products.list)
    const actions = useActions({ fetchProductsAction, fetchMoreProducts })
    const dispatch = useDispatch()
    const fetchProductsSubscription = useRef(null)

    const { brands, categories, locations } = useMemo(
        () => products.meta.aggregates || { brands: [], categories: [], locations: [] },
        [products.meta.aggregates]
    )

    const getProductsSubscription = useMemo(() => getProductsSubscriptionFactory(dispatch), [dispatch])
    const clearProductsSubscription = useCallback(
        () => fetchProductsSubscription.current && fetchProductsSubscription.current.unsubscribe(),
        [fetchProductsSubscription.current]
    )

    const fetchProducts = useCallback(
        filters => {
            clearProductsSubscription()
            fetchProductsSubscription.current = getProductsSubscription({
                ...filters,
                with_items_counts: true,
                length: 10
            })
        },
        [actions.fetchProductsAction, fetchProductsSubscription.current, clearProductsSubscription]
    )

    const handleLoadMoreProducts = useCallback(
        filters => {
            const canLoadMore = !fetchStatusHelper.fromResource({ fetchStatus: products.nextFetchStatus }).isLoading

            if (canLoadMore) {
                return actions.fetchMoreProducts({
                    ...filters,
                    with_items_counts: true,
                    page: products.meta.current_page + 1
                })
            }
        },
        [actions.fetchMoreProducts, products]
    )

    useEffect(() => clearProductsSubscription, [])

    return { products, brands, categories, locations, fetchProducts, handleLoadMoreProducts }
}

export default useProductList
