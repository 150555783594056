import React, { Component } from "react"
import { connect } from "react-redux"
import fetch from "../helpers/fetch"
import * as sessionActions from "actions/session"
import moment from "moment"

const mapStateToProps = state => {
    return {
        userId: state.loginStore.userId,
        activeClient: state.ui.session.activeClient
    }
}

const mapDispatchToProps = dispatch => {
    return {
        assignClient: client => dispatch(sessionActions.assignClient(client)),
        assignTemporaryClient: id => dispatch(sessionActions.assignTemporaryClient(id))
    }
}

const withSession = WrappedComponent => {
    class Enhance extends Component {
        sendStartSession = data => {
            const { assignTemporaryClient, assignClient } = this.props
            fetch
                .post("/session", {
                    ...data
                })
                .then(json => {
                    if (!data || (data && !data.client_id)) {
                        assignTemporaryClient(json.data.client_id)
                    }
                    window.localStorage.setItem("sessionToken", json.data.session_guid)
                })

            if (data && data.client_id) {
                assignClient({
                    ...data
                })
            }
        }

        sendAssignClient = data => {
            const { assignClient } = this.props

            if (!data || (data && !data.id)) {
                return null
            }

            assignClient({
                ...data
            })

            fetch.patch("/session", {
                client_id: data.id
            })
        }

        sendEndSession = data => {
            const elapsedSeconds = parseInt(window.localStorage.getItem("elapsedSeconds"), 10)
            const seconds = moment.utc(elapsedSeconds * 1000).format("mm:ss")

            fetch
                .post("/session/finishSession", {
                    ...data,
                    duration: seconds
                })
                .then(() => {
                    window.localStorage.removeItem("sessionToken")
                })
        }

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    sendActivity={this.sendActivity}
                    sendStartSession={this.sendStartSession}
                    sendEndSession={this.sendEndSession}
                    sendAssignClient={this.sendAssignClient}
                />
            )
        }
    }

    return connect(
        mapStateToProps,
        mapDispatchToProps
    )(Enhance)
}

export default withSession
