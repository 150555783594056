import React from "react"
import alertIcon from "assets/alert-error.svg"
import { WarningBar } from "ui"

import warningBarStyles from "./overrides/warningBar.module.css"
import PropTypes from "prop-types"

const ErrorBar = ({ text, icon = alertIcon }) => <WarningBar icon={icon} text={text} customStyles={warningBarStyles} />

ErrorBar.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.node
}

export default ErrorBar
