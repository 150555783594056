import React, { useState, useMemo, Fragment, useCallback } from "react"
import { useHistory, Link } from "react-router-dom"
import ReactSvg from "react-svg"
import _get from "lodash/get"
import {
    Status,
    Tooltip,
    InteractiveTooltip,
    DeleteIcon,
    DropListDivider,
    DropListOption,
    DropListOptions,
    SnakeLoader
} from "@butterfly-frontend/ui"
import { Checkbox, Image, ReloadableImageWrap } from "ui"
import { Decimal } from "decimal.js"

import { useActions } from "hooks"
import { formatPrice, roundNumber } from "helpers/units"
import { ChangeAvailableOrderItemToNewOrderModal } from "modules/WmsModule/modals"
import { updateOrderItem, getOrder } from "actions/orders"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import SALES_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/salesOrderStatusKeys"
import SALES_ORDER_ITEM_STATUSES from "modules/OrdersModule/constants/salesOrderItemStatusKeys"
import { createLinkToNewPurchaseOrder } from "modules/OrdersModule/helpers/createLinkToPurchaseOrder"
import { createLinkToReturnDetailsInSO } from "modules/OrdersModule/helpers/createLinkToReturn"

import withStyles from "HOC/withStyles"
import styles from "./view.css"
import checkboxStyles from "./overrides/checkboxStyles.css"

import expandIcon from "assets/expand.svg"
import roomMoveIcon from "assets/room-move.svg"
import duplicateIcon from "assets/duplicate.svg"
import editIcon from "assets/pencil.svg"
import deleteIcon from "assets/close-blue.svg"
import commentIcon from "assets/comment.svg"
import alertOkIcon from "assets/alert-ok.svg"
import addIcon from "assets/add.svg"
import documentIcon from "assets/document.svg"

const TYPES = {
    in_stock: "In Stock",
    partial_order: "Partial Order",
    order: "Order",
    new_order: "New Order",
    not_available: "Not Available",
    incoming: "Incoming"
}

const AVAILABLE_ORDER_ITEM_STATUSES = [
    SALES_ORDER_ITEM_STATUSES.IN_STOCK,
    SALES_ORDER_ITEM_STATUSES.PARTIAL_ORDER,
    SALES_ORDER_ITEM_STATUSES.INCOMING
]
const IN_STOCK_CHANGE_ORDER_STATUSES = [SALES_ORDER_STATUS_KEYS.NEW, SALES_ORDER_STATUS_KEYS.IN_PROGRESS]

const View = props => {
    const {
        cx,
        isService,
        isLast,
        isChecked,
        areDetailsShown,
        isDuplicateDisabled,
        isEditDisabled,
        isDeleteDisabled,
        item,
        customParam,
        roomId,
        externalActionsConfig,
        onCheck,
        onToggleDetails,
        onAddToRoom,
        onCopyToRoom,
        onDuplicate,
        onToggleEdit,
        onDelete,
        onShowComments,
        orderUuid,
        parent,
        showTax,
        returnShipments,
        isReturnShipmentsLoading,
        isStockItemChangeEnabled = true
    } = props
    const {
        id: orderItemId,
        product_id: productId,
        image,
        fake_name,
        attributes,
        note,
        type,
        notes,
        price,
        quantity,
        discount,
        tax_code,
        calculations,
        total_price,
        manual_entry,
        returned_stock_items = []
    } = item

    const history = useHistory()
    const [isChangeAvailableOrderItemToNewOrderModalOpen, setIsChangeAvailableOrderItemToNewOrderModalOpen] = useState(
        false
    )
    const [isChangeAvailableOrderItemToNewOrderLoading, setIsChangeAvailableOrderItemToNewOrderLoading] = useState(
        false
    )

    const returnsDropdownOptions = useMemo(
        () => (Array.isArray(returnShipments) ? returnShipments.map(({ id, uuid }) => ({ id, label: uuid })) : []),
        [returnShipments]
    )

    const redirectToReturnDetails = useCallback(
        shipmentId => history.push(createLinkToReturnDetailsInSO({ orderId: parent.id, shipmentId })),
        [history.push]
    )

    const hasCombination = useMemo(() => !!_get(item, "combination.id", false), [item])

    const actions = useActions({
        updateOrderItem,
        getOrder,
        showSuccessNotification,
        showErrorNotification
    })

    const changeAvailableOrderItemToNewOrder = ({ shouldKeepCombination }) => {
        setIsChangeAvailableOrderItemToNewOrderLoading(true)

        return actions
            .updateOrderItem(orderItemId, {
                new_order: true,
                remove_combination: hasCombination ? !shouldKeepCombination : false
            })
            .then(() => {
                setIsChangeAvailableOrderItemToNewOrderModalOpen(false)
                actions.getOrder(_get(item, "order.id"), true)
                actions.showSuccessNotification()
            })
            .catch(() => actions.showErrorNotification())
            .finally(() => setIsChangeAvailableOrderItemToNewOrderLoading(false))
    }

    const shouldRenderCustomParam = customParam && (!!customParam.value || customParam.value === 0) && !isService

    const externalActions = {
        salesOrder: {},
        purchaseOrder: {
            icon: addIcon,
            label: "PO",
            goToAction: ({ id, stateBuilder }) =>
                history.push({
                    pathname: `/orders/sales-orders/${stateBuilder(item).id}/purchase-orders`,
                    state: { purchaseOrderId: id }
                }),
            createAction: () =>
                history.push(createLinkToNewPurchaseOrder({ orderId: _get(parent, "id"), itemIds: [item.id] }))
        },
        invoice: {
            icon: documentIcon,
            label: "Invoice",
            goToAction: ({ id, stateBuilder }) =>
                history.push({
                    pathname: `/orders/sales-orders/${stateBuilder(item).id}/invoices`,
                    state: { invoiceId: id }
                }),
            createAction: ({ stateBuilder }) =>
                history.push({ pathname: "/orders/invoices/add", state: { order: stateBuilder(item) } })
        }
    }

    const discountItem = discount !== null ? discount : parent && parent.discount
    const taxItem = isService ? null : tax_code !== null ? tax_code : parent && parent.tax_code
    const taxItemValue = taxItem && roundNumber(new Decimal(taxItem).times(100), 6)
    const totalPrice = calculations ? calculations.total : total_price

    return (
        <div className={cx("root", { bottomLine: !isLast && !areDetailsShown })}>
            <div className={cx("column", "left")}>
                <Checkbox checked={isChecked} onChange={onCheck} customStyles={checkboxStyles} />

                {!isService &&
                    (image ? (
                        <ReloadableImageWrap image={image}>
                            {({ image }) => <Image className={cx("image")} src={image} />}
                        </ReloadableImageWrap>
                    ) : (
                        <Image className={cx("image")} src={image} />
                    ))}

                <div className={cx("mainInfoWrapper", { isService })}>
                    <p className={cx("name")}>
                        {manual_entry || isService ? (
                            fake_name
                        ) : (
                            <Link className={cx("name")} to={`/products/${productId}`}>
                                {fake_name}
                            </Link>
                        )}
                    </p>
                    {renderAttributes()}
                    {manual_entry && <div className={cx("customParam")}>Manual Entry</div>}
                    {manual_entry && shouldRenderCustomParam && <br />}
                    {shouldRenderCustomParam && (
                        <div className={cx("customParam")}>
                            {customParam.key}: <span>{customParam.value}</span>
                        </div>
                    )}
                    {!!note && <p className={cx("note")}>NOTE: {note}</p>}
                </div>
            </div>
            <div className={cx("column", "right", { areSevenElements: !!notes })}>
                <div>
                    <div>{!isService && "Type"}</div>
                    {!!notes && <div>Comments</div>}
                    <div>Item price</div>
                    <div>Qty</div>
                    <div>Discount</div>
                    {showTax && <div>Tax</div>}
                    <div>Item total</div>
                </div>
                <div>
                    <div>{!isService && renderProductStatus()}</div>
                    {!!notes && (
                        <div className={cx("commentsCount")}>
                            <div onClick={() => onShowComments()}>
                                <ReactSvg src={commentIcon} /> {Array.isArray(notes) ? notes.length : 0}
                            </div>
                        </div>
                    )}
                    <div>{formatPrice(price)}</div>
                    <div>{quantity}</div>
                    <div>{discountItem === null ? "-" : `${Number(discountItem)} %`}</div>
                    {showTax && <div>{!!taxItem || taxItem === 0 ? `${taxItemValue} %` : "-"}</div>}
                    <div className={cx("totalPrice")}>{formatPrice(totalPrice)}</div>
                </div>
            </div>
            <div className={cx("actions", { isService })}>
                <div className={cx("basicActions")}>
                    {!!onToggleDetails && (
                        <button onClick={onToggleDetails}>
                            <ReactSvg src={expandIcon} className={cx("expandIcon", { areDetailsShown })} />{" "}
                            {areDetailsShown ? "Hide" : "See"} details
                        </button>
                    )}
                    {!!onAddToRoom && (
                        <button onClick={onAddToRoom}>
                            <ReactSvg src={roomMoveIcon} className={cx("roomMoveIcon")} />{" "}
                            {!!roomId ? "Change" : "Add to"} room
                        </button>
                    )}
                    {!!onCopyToRoom && (
                        <button onClick={onCopyToRoom}>
                            <ReactSvg src={roomMoveIcon} className={cx("roomMoveIcon")} /> Copy to room
                        </button>
                    )}
                    {!!onDuplicate && (
                        <button onClick={onDuplicate} disabled={isDuplicateDisabled}>
                            <ReactSvg src={duplicateIcon} className={cx("duplicateIcon")} />
                            Duplicate
                        </button>
                    )}
                    {!!onToggleEdit && (
                        <button onClick={onToggleEdit} disabled={isEditDisabled}>
                            <ReactSvg src={editIcon} className={cx("editIcon")} /> Edit
                        </button>
                    )}
                    {!!onDelete && (
                        <button onClick={onDelete} disabled={isDeleteDisabled}>
                            <ReactSvg src={deleteIcon} className={cx("deleteIcon")} /> Delete
                        </button>
                    )}
                </div>
                <div className={cx("externalActions")}>
                    {returned_stock_items.length > 0 && (
                        <InteractiveTooltip
                            content={
                                <DropListOptions>
                                    {isReturnShipmentsLoading ? (
                                        <SnakeLoader />
                                    ) : (
                                        returnsDropdownOptions.map(({ id, label }, index) => (
                                            <Fragment key={label}>
                                                {index !== 0 && <DropListDivider />}
                                                <DropListOption onClick={() => redirectToReturnDetails(id)}>
                                                    {label}
                                                </DropListOption>
                                            </Fragment>
                                        ))
                                    )}
                                </DropListOptions>
                            }
                            placement="top"
                            offset={[0, -8]}
                            classes={{ tooltip: styles.returnListDropdown, trigger: styles.returnListDropdownTrigger }}
                        >
                            <div className={cx("returnListDropdownButtonWrapper")}>
                                <span className={cx("returnListDropdownButton")}>
                                    Return: {returned_stock_items.length}
                                </span>
                            </div>
                        </InteractiveTooltip>
                    )}
                    {!!orderUuid && (
                        <div
                            className={cx("order-uuid")}
                            onClick={() => history.push(`/orders/sales-orders/${item.order_id}`)}
                        >
                            <ReactSvg src={alertOkIcon} className={cx("alertOkIcon")} />
                            {orderUuid}
                        </div>
                    )}
                    {externalActionsConfig && Object.entries(externalActionsConfig).map(renderExternalAction)}
                </div>
            </div>
            {isChangeAvailableOrderItemToNewOrderModalOpen && (
                <ChangeAvailableOrderItemToNewOrderModal
                    onCancel={() => setIsChangeAvailableOrderItemToNewOrderModalOpen(false)}
                    changeAvailableOrderItemToNewOrder={changeAvailableOrderItemToNewOrder}
                    hasCombination={hasCombination}
                    isLoading={isChangeAvailableOrderItemToNewOrderLoading}
                />
            )}
        </div>
    )

    function renderAttributes() {
        if (!attributes) {
            return null
        }

        return (
            <div className={cx("attributes")}>
                {Object.entries(attributes).map(([key, value]) => (
                    <span key={key} className={cx("attribute")}>
                        {key}: <span>{value}</span>
                    </span>
                ))}
            </div>
        )
    }

    function renderExternalAction([actionKey, actionData], index) {
        const { id, uuid, canCreate } = actionData
        const { icon, label, goToAction, createAction } = externalActions[actionKey]

        const externalExist = !!id && !!uuid

        return (
            <div key={index} className={cx("externalAction")}>
                {externalExist ? (
                    <button onClick={() => goToAction(actionData)}>
                        <ReactSvg src={alertOkIcon} />
                        {uuid}
                    </button>
                ) : (
                    canCreate && (
                        <button onClick={() => createAction(actionData)}>
                            <ReactSvg src={icon} />
                            Create {label}
                        </button>
                    )
                )}
            </div>
        )
    }

    function renderProductStatus() {
        const isInStockChangeAvailable =
            isStockItemChangeEnabled &&
            AVAILABLE_ORDER_ITEM_STATUSES.includes(type) &&
            IN_STOCK_CHANGE_ORDER_STATUSES.includes(_get(parent, "status")) &&
            !isEditDisabled

        return isInStockChangeAvailable ? (
            <Tooltip content="Change as a New Order" placement="right" offset={[-15, 16]}>
                <Status
                    withCircle={false}
                    ActionIcon={DeleteIcon}
                    theme="inactiveFill"
                    onClick={() => setIsChangeAvailableOrderItemToNewOrderModalOpen(true)}
                >
                    {TYPES[type] || type}
                </Status>
            </Tooltip>
        ) : (
            <Status withCircle={false} theme="inactiveFill">
                {TYPES[type] || type}
            </Status>
        )
    }
}

export default withStyles(View, styles)
