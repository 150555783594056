import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./FormSection.module.css"

const FormSection = ({ title, children, inputs, className, withBorder = false }) => (
    <section className={classNames(styles.formSection, className, { [styles.formSectionBorder]: withBorder })}>
        <div className={styles.header}>
            {typeof title === "string" ? <h2 className={styles.title}>{title}</h2> : title}
            {inputs && <div>{inputs}</div>}
        </div>
        {typeof children === "function" ? children({ inputClass: styles.input }) : children}
    </section>
)

FormSection.propTypes = {
    title: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.string]),
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element), PropTypes.func]),
    inputs: PropTypes.element,
    className: PropTypes.string,
    withBorder: PropTypes.bool
}

export default FormSection
