import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    fetchStatus: fetchStatus.initialState()
}

function list(state = initialState, action) {
    switch (action.type) {
        case constants.GET_CATEGORIES_START:
            return { ...initialState, fetchStatus: fetchStatus.requestState(), isLoading: true }

        case constants.GET_CATEGORIES_SUCCESS:
            return {
                data: action.payload.data.data || [],
                meta: action.payload.data.meta || {},
                fetchStatus: fetchStatus.successState(),
                isLoading: false
            }

        case constants.GET_CATEGORIES_ERROR:
            return {
                data: [],
                meta: {},
                isLoading: false,
                fetchStatus: {
                    ...fetchStatus.failureState(),
                    error: (action.payload || {}).error
                }
            }

        default:
            return state
    }
}

export default list
