import React, { useMemo, Fragment } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import classNames from "classnames"

import {
    SnakeLoader,
    Table,
    TableFilterBar,
    TableFilterBarSearch,
    TableHead,
    TableHeadCell,
    TableSortableHeadCell,
    TableBody,
    TableCell,
    TableRow,
    useTableFilterBarSearch,
    useTableSort
} from "@butterfly-frontend/ui"
import { Pagination } from "ui"
import { useSalesPersonList } from "modules/DashboardModule/hooks/api/useSalesPerson"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import { formatDateToApi } from "modules/WmsModule/helpers/date"
import { formatPrice } from "helpers/units"

import styles from "./SalesPersonList.module.css"

const TABLE_COLUMNS = {
    salesPerson: { minWidth: 100, width: "25%" },
    clientsCount: { minWidth: 70, width: "15%" },
    invoicesCount: { minWidth: 70, width: "15%" },
    totalAmount: { minWidth: 100, width: "20%" },
    totalAmountCollected: { minWidth: 100, width: "20%" },
    detailsButton: { minWidth: 80, width: "5%" }
}

const SalesPersonList = ({ selectedRange }) => {
    const filterBarSearchProps = useTableFilterBarSearch()
    const querySearch = filterBarSearchProps.debouncedValue

    const filters = useMemo(
        () => ({
            query: querySearch,
            date_from: selectedRange.startDate ? formatDateToApi(selectedRange.startDate) : null,
            date_to: selectedRange.endDate ? formatDateToApi(selectedRange.endDate) : null
        }),
        [selectedRange, querySearch]
    )

    const { sortState, onChangeSort } = useTableSort({
        initialState: { direction: "desc", by: "total_payments" }
    })

    const { data, fetchStatus, pagination } = useTableLogic({
        sort: sortState,
        useQueryList: useSalesPersonList,
        filters
    })

    return (
        <Fragment>
            <TableFilterBar>
                <TableFilterBarSearch placeholder="Search sales person..." {...filterBarSearchProps} />
            </TableFilterBar>
            <Table>
                <div className={styles.table}>
                    <TableHead>
                        <TableSortableHeadCell
                            {...TABLE_COLUMNS.salesPerson}
                            sortState={sortState}
                            columnKey="full_name"
                            onClick={onChangeSort}
                        >
                            SALES PERSON NAME
                        </TableSortableHeadCell>
                        <TableSortableHeadCell
                            {...TABLE_COLUMNS.clientsCount}
                            sortState={sortState}
                            columnKey="clients_count"
                            onClick={onChangeSort}
                        >
                            # OF CLIENTS
                        </TableSortableHeadCell>
                        <TableSortableHeadCell
                            {...TABLE_COLUMNS.invoicesCount}
                            sortState={sortState}
                            columnKey="invoices_count"
                            onClick={onChangeSort}
                        >
                            # OF INVOICES
                        </TableSortableHeadCell>
                        <TableSortableHeadCell
                            {...TABLE_COLUMNS.totalAmount}
                            sortState={sortState}
                            columnKey="total_payments"
                            onClick={onChangeSort}
                        >
                            TOTAL AMOUNT
                        </TableSortableHeadCell>
                        <TableSortableHeadCell
                            {...TABLE_COLUMNS.totalAmountCollected}
                            sortState={sortState}
                            columnKey="total_sales_amount"
                            onClick={onChangeSort}
                        >
                            TOTAL AMOUNT COLLECTED
                        </TableSortableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.detailsButton} />
                    </TableHead>
                    <TableBody>
                        {!fetchStatus.isLoaded ? (
                            <SnakeLoader />
                        ) : (
                            data.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell {...TABLE_COLUMNS.salesPerson} classes={{ root: styles.linkCell }}>
                                        <Link to={`/dashboard/sellers/${row.id}`}>{row.full_name}</Link>
                                    </TableCell>
                                    <TableCell {...TABLE_COLUMNS.clientsCount} classes={{ root: styles.cell }}>
                                        {row.clients_count}
                                    </TableCell>
                                    <TableCell {...TABLE_COLUMNS.invoicesCount} classes={{ root: styles.cell }}>
                                        {row.invoices_count}
                                    </TableCell>
                                    <TableCell {...TABLE_COLUMNS.totalAmount} classes={{ root: styles.priceCell }}>
                                        {formatPrice(row.total_sales_amount)}
                                    </TableCell>
                                    <TableCell
                                        {...TABLE_COLUMNS.totalAmountCollected}
                                        classes={{ root: styles.priceCell }}
                                    >
                                        {formatPrice(row.total_payments)}
                                    </TableCell>
                                    <TableCell
                                        {...TABLE_COLUMNS.detailsButton}
                                        classes={{ root: classNames(styles.linkCell, styles.detailsButtonCell) }}
                                    >
                                        <Link to={`/dashboard/sellers/${row.id}`}>Details</Link>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                    {pagination.canDisplay && (
                        <div className={styles.paginationWrapper}>
                            <Pagination {...pagination} />
                        </div>
                    )}
                </div>
            </Table>
        </Fragment>
    )
}

SalesPersonList.propTypes = {
    selectedRange: PropTypes.shape({
        startDate: PropTypes.string.isRequired,
        endDate: PropTypes.string.isRequired
    }).isRequired
}

export default SalesPersonList
