import React, { Component, Fragment, useEffect } from "react"
import { connect } from "react-redux"
import { Formik, Form as FormikForm } from "formik"
import { Route, Switch, generatePath, Link } from "react-router-dom"
import * as yup from "yup"
import { size } from "lodash"
import { CombinationsCreator } from "ui"

import { PERMISSIONS } from "constants/index"

import Layout from "components/Reusable/Layout/index"
import withPermissions from "HOC/withPermissions"
import withStyles from "HOC/withStyles"
import * as fetchStatus from "helpers/fetchStatus"
import GeneralInformations from "./GeneralInformations"
import Skeleton from "components/Skeleton"
import Modal from "components/Modal/Modal"
import Loader from "components/Reusable/Loader/Loader"
import DuplicateProduct from "components/Modal/DuplicateProduct"
import Sidebar from "./Sidebar"
import Pictures from "./Pictures"

import styles from "./styles.css"
import stylesLayout from "./overrides/Layout.css"
import stylesSidebar from "./overrides/Sidebar.css"
import eyeSvg from "assets/eye.svg"
import closeSvg from "assets/close-blue.svg"
import duplicateSvg from "assets/duplicate.svg"

const schema = yup.object().shape({
    name: yup.string().required("Name is a required field"),
    brand_id: yup.number().required("Brand is a required field")
})

class Form extends Component {
    state = {
        isDirty: false
    }

    setIsDirty = isDirty => this.setState({ isDirty })

    get routeIndex() {
        const { productId, action, isEdit } = this.props

        return generatePath("/products/:productId?/:action", {
            productId: isEdit ? productId : null,
            action
        })
    }

    get routeCombinations() {
        const { productId, action, isEdit } = this.props

        return generatePath("/products/:productId?/:action/combinations", {
            productId: isEdit ? productId : null,
            action
        })
    }

    get routePictures() {
        const { productId, action, isEdit } = this.props

        return generatePath("/products/:productId?/:action/pictures", {
            productId: isEdit ? productId : null,
            action
        })
    }

    render() {
        const {
            cx,
            isEdit,
            product,
            categories,
            handleSetDuplicateProductModal,
            isDuplicateProductModalShown,
            productId,
            productName
        } = this.props

        return (
            <Layout
                title={isEdit ? "Edit Product" : "New Product"}
                cta={this.renderHeaderActions()}
                customStyles={stylesLayout}
            >
                <Fragment>
                    {isEdit && isDuplicateProductModalShown && (
                        <Modal>
                            <DuplicateProduct
                                productId={productId}
                                handleSetModal={handleSetDuplicateProductModal}
                                productName={productName}
                            />
                        </Modal>
                    )}
                    <Skeleton
                        fetchStatus={fetchStatus.fromResources(isEdit ? product : null, categories)}
                        firstTime={true}
                        component={
                            <div className={cx("loader")}>
                                <Loader />
                            </div>
                        }
                        render={() => this.renderContent()}
                    />
                </Fragment>
            </Layout>
        )
    }

    renderContent() {
        const {
            cx,
            match,
            product,
            initialValues,
            setInitialValues,
            handleSubmit,
            isNew,
            handleShowModalIfCombinationEdited
        } = this.props

        return (
            <div className={cx("contentWrapper")}>
                <Formik
                    initialValues={setInitialValues(initialValues, isNew ? {} : product.data)}
                    enableReinitialize={true}
                    validationSchema={schema}
                    onSubmit={handleSubmit}
                >
                    {formikProps => {
                        const errors = formikProps.submitCount ? formikProps.errors : {}
                        const errorsSize = size(errors)
                        useEffect(() => {
                            this.setIsDirty(formikProps.dirty)
                        }, [formikProps.dirty])

                        return (
                            <FormikForm className={cx("formikForm")}>
                                <div className={cx("sectionContainer")}>
                                    <div className={cx("tabs")}>
                                        <Link
                                            className={cx("tab", {
                                                active: match.url === this.routeIndex
                                            })}
                                            to={this.routeIndex}
                                            onClick={handleShowModalIfCombinationEdited}
                                        >
                                            GENERAL INFO
                                            {errorsSize > 0 && (
                                                <span className={cx("errors-container")}>
                                                    <span className={cx("errors")}>{errorsSize}</span>
                                                </span>
                                            )}
                                        </Link>
                                        <Link
                                            className={cx("tab", {
                                                active: match.url === this.routePictures
                                            })}
                                            to={this.routePictures}
                                        >
                                            PICTURES
                                        </Link>
                                        <Link
                                            className={cx("tab", {
                                                active: match.url === this.routeCombinations
                                            })}
                                            to={this.routeCombinations}
                                        >
                                            COMBINATIONS
                                        </Link>
                                    </div>

                                    <div className={cx("section")}>
                                        <Switch>
                                            <Route
                                                path={"/products/:productId?/:action/combinations"}
                                                render={routingProps => {
                                                    return <CombinationsCreator match={routingProps.match} />
                                                }}
                                            />
                                            <Route
                                                path={"/products/:productId?/:action/pictures"}
                                                render={routingProps => {
                                                    return (
                                                        <Pictures
                                                            {...routingProps}
                                                            {...this.props}
                                                            {...formikProps}
                                                            isSubmitting={this.props.isSubmitting}
                                                        />
                                                    )
                                                }}
                                            />
                                            <Route
                                                path={"/products/:productId?/:action"}
                                                render={routingProps => {
                                                    return (
                                                        <GeneralInformations
                                                            {...this.props}
                                                            {...routingProps}
                                                            {...formikProps}
                                                            isSubmitting={this.props.isSubmitting}
                                                            errors={errors}
                                                            isMultipleCombinations={formikProps.values.has_combinations}
                                                        />
                                                    )
                                                }}
                                            />
                                        </Switch>
                                    </div>
                                </div>

                                {this.renderSidebar(formikProps)}
                            </FormikForm>
                        )
                    }}
                </Formik>
            </div>
        )
    }

    renderSidebar(formikProps) {
        const { match } = this.props

        if (match.url === this.routeCombinations) {
            return null
        }

        return (
            <Sidebar
                {...this.props}
                form={formikProps}
                customStyles={stylesSidebar}
                isMultipleCombinations={formikProps.values.has_combinations}
            />
        )
    }

    renderHeaderActions() {
        const {
            cx,
            isNew,
            product,
            handleShowDeleteModal,
            handleShowRestoreModal,
            canDelete,
            canAdd,
            canRestore,
            handleBackToProduct,
            handleSetDuplicateProductModal
        } = this.props

        if (isNew || product.isLoading) {
            return []
        }

        return [
            canAdd() && (
                <button onClick={() => handleSetDuplicateProductModal(true)} className={cx("headerButton")}>
                    <img src={duplicateSvg} alt="" /> Duplicate
                </button>
            ),
            <button
                onClick={() => handleBackToProduct(this.state.isDirty)}
                target="_blank"
                rel="noopener noreferrer"
                className={cx("headerButton")}
            >
                <img src={eyeSvg} alt="" /> View
            </button>,
            !!product.data.deleted_at
                ? canRestore() && (
                      <button className={cx("headerButton", "closeButton")} onClick={handleShowRestoreModal}>
                          Restore
                      </button>
                  )
                : canDelete() && (
                      <button className={cx("headerButton", "closeButton")} onClick={handleShowDeleteModal}>
                          <img src={closeSvg} alt="" className={cx("closeIcon")} /> Delete
                      </button>
                  )
        ]
    }
}

const ComponentWithPermission = withPermissions(withStyles(Form, styles), PERMISSIONS.context.PRODUCTS)
export default connect(
    null,
    null
)(ComponentWithPermission)
