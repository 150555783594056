import { useCallback, useMemo } from "react"
import get from "lodash/get"

import getDiscountLabel from "./getDiscountLabel"

const useDiscountList = ({ currentDiscountResource, discountList }) => {
    const checkIfCurrentDiscountOnList = useCallback(
        currentDiscount => {
            if (!discountList) {
                return false
            }

            const foundCurrentParentDiscount = currentDiscount
                ? discountList.find(discount => get(currentDiscount.parent_discount, "id") === discount.id)
                : null

            const wasChangeCurrentDiscount = foundCurrentParentDiscount
                ? foundCurrentParentDiscount.name !== currentDiscount.name ||
                  foundCurrentParentDiscount.value !== currentDiscount.value ||
                  foundCurrentParentDiscount.description !== currentDiscount.description
                : false

            return foundCurrentParentDiscount && !wasChangeCurrentDiscount
        },
        [discountList]
    )

    const mappedDiscountList = useMemo(() => {
        if (!discountList) {
            return []
        }

        const mapDiscount = discount => ({
            id: discount.id,
            label: getDiscountLabel(discount),
            value: discount
        })

        const mappedDiscountList = discountList.map(mapDiscount)

        if (currentDiscountResource && !checkIfCurrentDiscountOnList(currentDiscountResource)) {
            return [mapDiscount(currentDiscountResource), ...mappedDiscountList]
        }

        return mappedDiscountList
    }, [discountList, currentDiscountResource, checkIfCurrentDiscountOnList])

    return {
        mappedDiscountList
    }
}

export default useDiscountList
