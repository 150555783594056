import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./RolesList.css"
import RoleData from "../RoleData/RoleData"

class RolesList extends Component {
    render() {
        const { cx, roles, isLoaded, deleteRole } = this.props
        return (
            <div className={cx("roles-list-cover")}>
                <div>
                    <h4 className={cx("no")}>NO.</h4>
                    <h4 className={cx("name")}>NAME</h4>
                    <h4 className={cx("permissions")}>PERMISSIONS</h4>
                    <h4 className={cx("space")}> </h4>
                    <h4 className={cx("users")}>USERS</h4>

                    {isLoaded && (
                        <ul>
                            {roles.map((role, index) => (
                                <RoleData {...role} key={role.id} index={index} deleteThisRole={deleteRole} />
                            ))}
                        </ul>
                    )}

                    <ul className={cx(isLoaded ? "none" : null)}>
                        {Array(10)
                            .fill()
                            .map((_, i) => i + 1)
                            .map(key => (
                                <li key={key} data-key={key} className={cx("loading-placeholder")}>
                                    <div className={cx("no-placeholder")} />
                                    <div className={cx("name-placeholder")} />
                                    <div className={cx("permissions-placeholder")} />
                                    <div className={cx("space")} />
                                    <div className={cx("users-placeholder")} />
                                    <div className={cx("edit-placeholder")} />
                                    <div className={cx("delete-placeholder")} />
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        )
    }
}

export default withStyles(RolesList, styles)
