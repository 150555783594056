import React, { Component, Fragment } from "react"
import withStyles from "HOC/withStyles"
import styles from "./table.module.css"
import { SHOWN_CLIENTS_COUNT, WAIT_LOAD_LIST } from "constants/index"
import Modal from "components/Modal/Modal"
import AddNoteCover from "components/Modal/AddNoteCover/AddNoteCover"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import customSnakeLoaderStyles from "./overrides/snakeloader.module.css"
import { List, Sort } from "./components"

class Table extends Component {
    static defaultProps = {
        maxShownCount: SHOWN_CLIENTS_COUNT,
        length: 0
    }

    state = {
        noteModalClientId: -1
    }

    handleModal = (id = -1) => this.setState({ noteModalClientId: id })

    render() {
        const {
            cx,
            isLoaded,
            length,
            list,
            handleLoad,
            currentPage,
            lastPage,
            sortKeys,
            sortBy,
            sortDirection,
            total,
            clientsCount,
            handleSort
        } = this.props

        if (!isLoaded) {
            return <SnakeLoader customStyles={customSnakeLoaderStyles} />
        }

        if (length === 0) {
            return <section className={cx("root", "empty")}>List is empty.</section>
        }

        return (
            <Fragment>
                {this.renderModal()}
                <section className={cx("root")}>
                    <p className={cx("resultsCount")}>
                        {total} of {clientsCount} results.
                    </p>
                    <Sort sortKeys={sortKeys} sortBy={sortBy} sortDirection={sortDirection} handleSort={handleSort} />
                    <List
                        list={list}
                        currentPage={currentPage}
                        lastPage={lastPage}
                        handleLoad={handleLoad}
                        handleModal={this.handleModal}
                    />
                </section>
            </Fragment>
        )
    }

    renderModal = () => {
        const { getClientById } = this.props
        const { noteModalClientId } = this.state

        if (noteModalClientId !== -1) {
            return (
                <Modal>
                    <AddNoteCover
                        handleHide={() => this.handleModal()}
                        type="client"
                        id={noteModalClientId}
                        successAction={() => setTimeout(() => getClientById(noteModalClientId), WAIT_LOAD_LIST)}
                    />
                </Modal>
            )
        }

        return null
    }
}

export default withStyles(Table, styles)
