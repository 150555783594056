import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"

import withStyles from "HOC/withStyles"

import { Modal, Button, Radio, Input } from "ui"

import { Video, Image } from "./components"
import ShowProductLink from "../ShowProductLink"

import styles from "./styles.css"
import radioStyles from "./overrides/radio.css"
import inputStyles from "./overrides/input.css"

const SectionModalVideo = ({ onClose, cx, form, isSubmitting }) => {
    const setFieldValue = (field, video) => {
        form.setFieldValue(field, video)
        form.setErrors({
            ...form.errors,
            [field]: undefined
        })
    }

    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Video</p>

            <Field name="settings.video_type">
                {({ field }) => (
                    <Fragment>
                        <Radio
                            checked={field.value === "file"}
                            name={field.name}
                            value="file"
                            label="File"
                            handleSelect={form.setFieldValue}
                            customStyles={radioStyles}
                            isHorizonal={true}
                        />

                        <Radio
                            checked={field.value === "link"}
                            name={field.name}
                            value="link"
                            label="Link"
                            handleSelect={form.setFieldValue}
                            customStyles={radioStyles}
                            isHorizonal={true}
                        />
                    </Fragment>
                )}
            </Field>

            {form.values.settings.video_type === "file" && (
                <Video
                    form={form}
                    setVideo={video => setFieldValue("video", video)}
                    video={form.values.video}
                    isError={!!form.errors.video}
                />
            )}

            {form.values.settings.video_type === "link" && (
                <Field name="settings.external_link">
                    {({ field, meta }) => (
                        <Input
                            {...field}
                            label="Link"
                            onChange={({ event }) => field.onChange(event)}
                            customStyles={inputStyles}
                            error={meta.error}
                        />
                    )}
                </Field>
            )}

            <div className={cx("video_thumbnail")}>
                <label className={cx("label")}>Thumbnail:</label>
                <Image
                    form={form}
                    setImage={video_thumbnail => setFieldValue("video_thumbnail", video_thumbnail)}
                    image={form.values.video_thumbnail}
                    isError={!!form.errors.video_thumbnail}
                />
            </div>

            <ShowProductLink />

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    label="Save"
                    onClick={form.submitForm}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

SectionModalVideo.propTypes = {
    onClose: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(SectionModalVideo, styles)
