import React from "react"
import { useLocation, useHistory } from "react-router-dom"
import _get from "lodash/get"
import { H1, Panel } from "@butterfly-frontend/ui"

import { useActions } from "hooks"
import { Header } from "modules/WmsModule/components"
import { showSuccessNotification } from "actions/notification"

import ChangeItemsStatusForm from "./ChangeItemsStatusForm"
import styles from "./StockItemsChangeStatusPage.module.css"

const DEFAULT_RETURN_PATH = "/wms/stock-management"

const StockItemsChangeStatusPage = () => {
    const location = useLocation()
    const history = useHistory()
    const actions = useActions({ showSuccessNotification })

    const redirectToSourcePage = () => history.push(_get(location, "state.returnPath", DEFAULT_RETURN_PATH))
    const stockItemsToAdjust = _get(location, "state.stockItemsToAdjust", [])

    return (
        <div className={styles.stockItemsChangeStatusPage}>
            <Header>
                <H1 withLeftBorder>Stock Adjustment</H1>
            </Header>
            <Panel>
                <div className={styles.panel}>
                    <ChangeItemsStatusForm
                        selectedItems={stockItemsToAdjust}
                        onSuccess={() => {
                            actions.showSuccessNotification({ title: "Stock adjustment added successfully" })
                            redirectToSourcePage()
                        }}
                        onCancel={redirectToSourcePage}
                    />
                </div>
            </Panel>
        </div>
    )
}

export default StockItemsChangeStatusPage
