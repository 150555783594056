import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: {},
    fetchStatus: fetchStatus.initialState()
}

function current(state = initialState, action) {
    switch (action.type) {
        case constants.GET_ASSISTANCE_BY_ID_START:
            return {
                ...state,
                isLoading: true,
                fetchStatus: fetchStatus.requestState()
            }

        case constants.GET_ASSISTANCE_BY_ID_SUCCESS:
            return {
                data: action.payload.data.data,
                isLoading: false,
                fetchStatus: fetchStatus.successState()
            }

        case constants.GET_ASSISTANCE_BY_ID_ERROR:
            return {
                data: {},
                isLoading: false,
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }

        case constants.RESET_CURRENT_ASSISTANCE:
            return {
                ...initialState,
                isLoading: null
            }

        default:
            return state
    }
}

export default current
