import * as yup from "yup"

export const schema = yup.object().shape({
    first_name: yup
        .string()
        .max(250, "First Name may not be greater than 250 characters")
        .required("First Name is required"),
    last_name: yup
        .string()
        .max(250, "Last Name may not be greater than 250 characters")
        .required("Last Name is required"),
    phone: yup
        .string()
        .max(250, "Phone may not be greater than 250 characters")
        .nullable(),

    country: yup
        .string()
        .max(250, "Country may not be greater than 250 characters")
        .nullable(),
    address: yup
        .string()
        .max(250, "Address may not be greater than 250 characters")
        .nullable(),
    post_code: yup
        .string()
        .max(250, "ZIP code may not be greater than 250 characters")
        .nullable(),
    city: yup
        .string()
        .max(250, "City may not be greater than 250 characters")
        .nullable(),
    state: yup
        .string()
        .max(250, "State may not be greater than 250 characters")
        .nullable(),

    isChangingPassword: yup.boolean(),
    old_password: yup.string().when("isChangingPassword", {
        is: true,
        then: yup
            .string()
            .max(250, "Current password may not be greater than 250 characters")
            .required("Current password is required"),
        otherwise: yup.string().nullable()
    }),
    password: yup.string().when("isChangingPassword", {
        is: true,
        then: yup
            .string()
            .max(250, "New password may not be greater than 250 characters")
            .min(8, "Current password may not be lesser than 8 characters")
            .required("New password is required"),
        otherwise: yup.string().nullable()
    }),
    password_confirmation: yup.string().when("isChangingPassword", {
        is: true,
        then: yup
            .string()
            .oneOf([yup.ref("password")], "Passwords must match")
            .required("New password is required"),
        otherwise: yup.string().nullable()
    }),
    warehouses: yup.array().of(
        yup.object().shape({
            id: yup.number()
        })
    )
})
