import React from "react"
import { Route, Switch } from "react-router-dom"

import { List, Details } from "../pages"

import withPermissions from "HOC/withPermissions"

const Routes = () => {
    return (
        <Switch>
            <Route exact path="/products/selections" component={List} />
            <Route path="/products/selections/:selectionId/:sectionName?" component={Details} />
        </Switch>
    )
}

export default withPermissions(Routes)
