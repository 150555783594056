import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { createEpicMiddleware } from "redux-observable"
import middlewares from "middlewares/index"
import * as fetchStatus from "helpers/fetchStatus"

import rootReducer from "reducers/index"
import multiselectConfig from "reducers/ui/multiselect/config"
import { initialCombinationsCreatorState } from "reducers/ui/combinationsCreator"
import { initialActiveClient } from "reducers/ui/session"
import rootEpic from "epics/index"

const epicMiddleware = createEpicMiddleware()

const permissions = window.localStorage.getItem("permissions", null)
const roles = window.localStorage.getItem("roles", null)
const assignedWarehouseIds = window.localStorage.getItem("assignedWarehouseIds", [])

const getPersistedState = () => {
    return {
        loginStore: {
            activeUser: false,
            errorMessage: "",
            isError: false,
            token: window.localStorage.getItem("accessToken", null),
            userName: window.localStorage.getItem("userName", null),
            userId: window.localStorage.getItem("userId", null),
            userFirstName: window.localStorage.getItem("userFirstName", null),
            userLastName: window.localStorage.getItem("userLastName", null),
            userAvatar: window.localStorage.getItem("userAvatar", null),
            permissions: permissions && permissions !== "undefined" ? JSON.parse(permissions) : null,
            roles: roles && roles !== "undefined" ? JSON.parse(roles) : [],
            email: window.localStorage.getItem("email", null),
            assignedWarehouseIds:
                assignedWarehouseIds && assignedWarehouseIds !== "undefined" ? JSON.parse(assignedWarehouseIds) : []
        },
        menuStore: {
            isSidebarMenuWide: true
        },
        roleStore: {
            roles: [],
            links: {},
            meta: {},
            permissions: [],
            role: {},
            isLoaded: false,
            isError: false,
            isDeleted: false
        },
        userStore: {
            users: [],
            user: {},
            links: {},
            meta: {},
            isLoaded: false,
            isError: false,
            isDeleted: false
        },
        ui: {
            availableFilters: {
                brands: {},
                categories: {},
                colors: [],
                enabled: false
            },
            products: {
                brands: [],
                category: [],
                categoryRoute: [],
                chosenLeafs: [],
                colors: []
            },
            session: {
                activeSession: window.localStorage.getItem("activeSession") === "true",
                activeClient: JSON.parse(window.localStorage.getItem("activeClient")) || initialActiveClient
            },
            notification: {
                type: "",
                isEnabled: false,
                title: "",
                message: ""
            },
            uploadModule: {
                currentlySelectedImages: [],
                transferedImages: [],
                transferFromProduct: { productId: null, imageId: null, url: null, name: null },
                isImageDragging: false,
                currentFolderId: null
            },
            newsModule: {
                data: {}
            },
            searchBar: {
                params: [],
                query: ""
            },
            multiselect: multiselectConfig,
            combinationsCreator: initialCombinationsCreatorState
        },
        db: {
            companies: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            sources: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            services: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            clientGroups: {
                list: {
                    isLoading: true,
                    isLoaded: false,
                    isError: false,
                    data: [],
                    meta: {}
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            calendar: {
                list: {
                    isLoading: true,
                    data: {
                        private: [],
                        public: []
                    }
                },
                entries: {
                    isLoading: true,
                    meta: { last_page: 1, total: 0, current_page: 1, to: 0, from: 0 },
                    data: []
                },
                entryFilters: {
                    users: [],
                    client: "",
                    project: "",
                    sources: [],
                    divisions: [],
                    isInitial: !window.localStorage.getItem("calendarFilters"),
                    ...JSON.parse(window.localStorage.getItem("calendarFilters"))
                },
                entryQuery: "",
                entryParams: {
                    length: 9999,
                    page: 1
                },
                unselectedCalendars: JSON.parse(window.localStorage.getItem("unselectedCalendars")) || []
            },
            products: {
                current: {
                    fetchStatus: fetchStatus.initialState(),
                    data: {}
                },
                combinations: {
                    isLoading: true,
                    data: []
                },
                conditions: {
                    list: {
                        isLoading: true,
                        data: []
                    },
                    current: {
                        isLoading: true,
                        data: {}
                    }
                },
                createFastReview: {
                    isLoading: true,
                    isShown: false,
                    data: {}
                }
            },
            attributesGroups: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            attributes: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            attributeValues: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            brands: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            categories: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            clientTypes: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            channels: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            combinationTemplates: {
                list: {
                    isLoading: true,
                    data: []
                }
            },
            combinations: {
                current: {
                    isLoading: true,
                    data: {}
                },
                find: {
                    isLoading: false,
                    data: {}
                }
            },
            features: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            featureValues: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            uploadModule: {
                list: {
                    isLoading: true,
                    data: []
                },
                current: {
                    isLoading: true,
                    data: []
                }
            },
            orders: {
                list: {
                    data: [],
                    meta: {},
                    fetchStatus: fetchStatus.initialState()
                },
                current: {
                    data: {},
                    meta: {},
                    fetchStatus: fetchStatus.initialState()
                }
            },
            termsAndConditions: {
                current: {
                    data: {},
                    fetchStatus: fetchStatus.initialState()
                }
            },
            docsTemplates: {
                list: {
                    data: [],
                    meta: {},
                    fetchStatus: fetchStatus.initialState()
                }
            },
            emailTemplates: {
                list: {
                    data: [],
                    meta: {},
                    fetchStatus: fetchStatus.initialState()
                }
            },
            paymentMethods: {
                data: [],
                byId: {},
                meta: {},
                fetchStatus: fetchStatus.initialState(),
                fromCache: false
            },
            invoices: {
                list: {
                    data: [],
                    meta: {},
                    fetchStatus: fetchStatus.initialState()
                },
                current: {
                    data: {},
                    meta: {},
                    fetchStatus: fetchStatus.initialState()
                }
            },
            news: {
                list: {
                    isLoading: true,
                    data: [],
                    meta: {}
                },
                current: {
                    isLoading: true,
                    data: []
                }
            },
            blog: {
                current: {
                    isLoading: true,
                    data: []
                }
            },
            portfolio: {
                current: {
                    isLoading: true,
                    data: []
                }
            },
            tags: {
                list: {
                    isLoading: true,
                    data: []
                }
            },
            homePages: {
                current: {
                    isLoading: true,
                    data: {}
                },
                collection: {
                    isLoading: true,
                    data: {}
                },
                immediateDelivery: {
                    isLoading: true,
                    data: {}
                },
                ourFavourite: {
                    isLoading: true,
                    data: {}
                }
            },
            payments: {
                fetchStatus: fetchStatus.initialState(),
                data: [],
                meta: {}
            },
            menuCategories: {
                current: {
                    isLoading: true,
                    data: {}
                }
            },
            shippingMethods: {
                data: [],
                meta: {},
                fetchStatus: fetchStatus.initialState()
            },
            users: {
                list: []
            },
            partners: {
                list: {
                    data: [],
                    meta: {},
                    fetchStatus: fetchStatus.initialState()
                }
            }
        }
    }
}

const configureStore = () => {
    const composeEnhancers =
        process.env.NODE_ENV !== "production"
            ? typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
                ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                      name: "Mass Beverly",
                      actionsBlacklist: []
                  })
                : compose
            : compose

    return createStore(
        rootReducer,
        getPersistedState(),
        composeEnhancers(applyMiddleware(thunk, epicMiddleware, ...middlewares))
    )
}

const store = configureStore()

epicMiddleware.run(rootEpic)

export default store
