import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { Attachments, SubmitModal } from "ui"
import getStyles from "helpers/getCxFromStyles"
import styles from "./attachments.module.css"

import fetch from "helpers/fetch"
import { updateOrder } from "actions/orders"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

const headerConfig = {
    breakpoints: [1024],
    items: [
        { value: "name", label: "Attachment Name", width: ["35%", "50%"] },
        { value: "model.author.last_name", label: "", width: ["25%", "20%"] },
        { value: "created_at", label: "Added", width: "20%" }
    ]
}

const defaultDeleteData = { isOpen: false, id: null, isLoading: false }

const AttachmentsTab = props => {
    const cx = getStyles(styles)
    const { order } = props
    const { id, attachments } = order

    const dispatch = useDispatch()

    const [isUploading, setIsUploading] = useState(false)
    const [deleteData, setDeleteData] = useState(defaultDeleteData)

    const setIsDeleting = isDeleting => setDeleteData(oldDeleteData => ({ ...oldDeleteData, isLoading: isDeleting }))
    const openDeleteModal = attachmentId =>
        setDeleteData(oldDeleteData => ({ ...oldDeleteData, isOpen: !!attachmentId, id: attachmentId }))

    const closeDeleteModal = () => setDeleteData(defaultDeleteData)

    const handleUpload = addedFiles => {
        const body = {
            attachments: { add: addedFiles.map(({ id }) => id) }
        }

        setIsUploading(true)
        fetch
            .patchRAW("/orders/" + id, body)
            .then(() => {
                dispatch(updateOrder(id)).then(() => setIsUploading(false))
                dispatch(showSuccessNotification())
            })
            .catch(e => {
                dispatch(showErrorNotification())
                setIsUploading(false)
            })
    }

    const handleDelete = () => {
        setIsDeleting(true)
        fetch
            .deleteRAW(`/media/${deleteData.id}`)
            .then(() => {
                dispatch(updateOrder(id))
                dispatch(showSuccessNotification())
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(closeDeleteModal)
    }

    return (
        <div className={cx("root")}>
            <h2 className={cx("header")}>
                Attachments<span>(Not visible to client)</span>
            </h2>
            <Attachments
                isUploading={isUploading}
                attachments={attachments}
                handleUpload={handleUpload}
                handleDelete={openDeleteModal}
                overrides={{ Header: { props: { config: headerConfig } } }}
            />
            <SubmitModal
                {...deleteData}
                redButton
                header="Are you sure you want to remove this attachment?"
                body="This action cannot be reversed."
                submitText="Delete"
                onClose={closeDeleteModal}
                onSubmit={handleDelete}
            />
        </div>
    )
}

export default AttachmentsTab
