import React, { Component } from "react"
import Tags from "./Tags"

class TagsContainer extends Component {
    state = {
        activeTag: "All",
        isShortList: true
    }

    componentDidMount() {
        const { defaultButtonName } = this.props
        if (defaultButtonName) {
            this.setState({
                activeTag: defaultButtonName
            })
        }
    }

    render() {
        const { list, shortListLength, label, defaultButtonName } = this.props
        const { activeTag, isShortList } = this.state
        const defaultSliceParam = 5
        const shortList = list && list.slice(0, shortListLength || defaultSliceParam)

        return (
            <Tags
                list={isShortList ? shortList : list}
                toggleButtonName={isShortList ? "More..." : "Less"}
                label={label}
                activeTag={activeTag}
                isShortList={isShortList}
                defaultSliceParam={defaultSliceParam}
                defaultButtonName={defaultButtonName}
                handleToggleList={this.handleToggleList}
                handleSetActiveTag={this.handleSetActiveTag}
            />
        )
    }

    handleToggleList = () => {
        this.setState({
            isShortList: !this.state.isShortList
        })
    }

    handleSetActiveTag = ({ id, name }) => {
        const { handleRequest } = this.props
        this.setState({
            activeTag: name
        })
        handleRequest({ id })
    }
}

export default TagsContainer
