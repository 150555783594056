import React, { useEffect, useState } from "react"
import SaveBar from "ui/SaveBar"
import ListingLayout from "../MainPageListingLayout"
import Header from "../../components/Header"
import Listing from "../../components/MainPageListings"
import Modal from "components/Modal/Modal"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"

import SectionNameEditModal from "../../components/MainPageNameSectionEditModal"
import AddOurFavouritesModal from "../../components/MainPageAddOurFavouritesModal"
import EditOurFavouritesModal from "../../components/MainPageEditOurFavouritesModal"

const MainPageOurFavourites = props => {
    const {
        mainPages: { data, isLoading },
        modifiedData,
        isModified,
        handleSubmit,
        handleUpdate,
        handleClearChanges,
        getOurFavourite,
        ourFavourite,
        handleOurFavouritesCreate,
        handleOurFavouriteUpdate,
        isSaving,
        showErrorNotification,
        handleDeleteAllFromList
    } = props

    useEffect(() => {
        const list = (isModified ? modifiedData.favourites : data.favourites) || []
        setList(list)
    }, [data, modifiedData])

    const [list, setList] = useState([])
    const [sectionNameEditModalState, setSectionNameEditModalState] = useState(false)
    const [addOurFavouritesModalState, setAddOurFavouritesModalState] = useState(false)
    const [editOurFavouriteModalState, setEditOurFavouriteModalState] = useState(false)
    const [editingOurFavourite, setEditingOurFavourite] = useState(false)
    const [isDeleteOurFavouriteOpen, setIsDeleteOurFavouriteOpen] = useState(false)
    const [isDeleteAllOurFavouriteOpen, setIsDeleteAllOurFavouriteOpen] = useState(false)
    const [deletingOurFavourite, setDeletingOurFavourite] = useState(null)

    const displayTitle = () =>
        (data.settings &&
            data.settings.sections &&
            data.settings.sections.ourFavourites &&
            data.settings.sections.ourFavourites.label) ||
        "Our favourites"

    const listMapping = {
        id: "id",
        source_id: "product_id",
        name: "product_name",
        image: "product_image"
    }

    const handleReorder = favourites => {
        handleUpdate({ ...data, favourites })
    }

    const handleSectionNameEditClose = () => {
        setSectionNameEditModalState(false)
    }

    const handleSectionNameEditOpen = () => {
        setSectionNameEditModalState(true)
    }

    const handleSectionNameEdit = data => {
        handleSubmit(data, handleSectionNameEditClose)
    }

    const handleAddFavouritesClose = () => {
        setAddOurFavouritesModalState(false)
    }

    const handleAddFavouritesOpen = () => {
        setAddOurFavouritesModalState(true)
    }

    const handleAddFavourites = data => {
        handleOurFavouritesCreate(data, handleAddFavouritesClose)
    }

    const handleEditOurFavouriteClose = () => {
        setEditingOurFavourite(null)
        setEditOurFavouriteModalState(false)
    }

    const handleEditOurFavouriteOpen = ourFavouriteId => {
        setEditingOurFavourite(ourFavouriteId)
        setEditOurFavouriteModalState(true)
    }

    const handleEditOurFavourite = (id, data, onSuccess = null, onError) => {
        handleOurFavouriteUpdate(id, data, handleEditOurFavouriteClose, onError)
    }

    const handleDeleteOurFavouriteClose = () => {
        setIsDeleteOurFavouriteOpen(false)
        setDeletingOurFavourite(null)
    }

    const handleDeleteOurFavouriteOpen = ourFavourite => {
        setIsDeleteOurFavouriteOpen(true)
        setDeletingOurFavourite(ourFavourite)
    }

    const handleDeleteOurFavourite = () => {
        const { handleDeleteOurFavourite } = props
        handleDeleteOurFavourite(deletingOurFavourite.id, handleDeleteOurFavouriteClose)
    }

    const handleDeleteAllOurFavouriteClose = () => {
        setIsDeleteAllOurFavouriteOpen(false)
    }

    const handleDeleteAllOurFavouriteOpen = () => {
        setIsDeleteAllOurFavouriteOpen(true)
    }

    const handleDeleteAllOurFavourite = () => {
        handleDeleteAllFromList(
            { settings: data.settings, header: data.header, favourites: [] },
            handleDeleteAllOurFavouriteClose
        )
    }

    return (
        <ListingLayout>
            <Header
                title={displayTitle()}
                editButton={{
                    label: "Edit",
                    handler: handleSectionNameEditOpen,
                    isDisabled: false,
                    order: 1
                }}
                addButton={{
                    label: "Add Product",
                    handler: handleAddFavouritesOpen,
                    isDisabled: false,
                    order: 2
                }}
                onClickActionButton={null}
            />

            <Listing
                isLoading={isLoading}
                handleDelete={handleDeleteOurFavouriteOpen}
                handleDeleteAll={handleDeleteAllOurFavouriteOpen}
                handleEdit={handleEditOurFavouriteOpen}
                listMapping={listMapping}
                listingType="products"
                handleReorder={handleReorder}
                list={list}
            />

            <SectionNameEditModal
                isSaving={isSaving}
                data={modifiedData || data}
                sectionName="ourFavourites"
                defaultValues={{ label: "Our favourites" }}
                handleClose={handleSectionNameEditClose}
                onSubmit={handleSectionNameEdit}
                isOpen={sectionNameEditModalState}
            />

            <EditOurFavouritesModal
                data={ourFavourite}
                immediateDeliveryId={editingOurFavourite}
                getImmediateDelivery={getOurFavourite}
                handleClose={handleEditOurFavouriteClose}
                handleError={showErrorNotification}
                onSubmit={handleEditOurFavourite}
                isOpen={editOurFavouriteModalState}
            />

            <AddOurFavouritesModal
                data={modifiedData || data}
                handleClose={handleAddFavouritesClose}
                onSubmit={handleAddFavourites}
                isOpen={addOurFavouritesModalState}
            />

            {isDeleteOurFavouriteOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete this favourite product?"
                        description="Are you sure you want to delete the"
                        subject={deletingOurFavourite.name}
                        descriptionClosure="? This action cannot be reversed."
                        handleHideModal={handleDeleteOurFavouriteClose}
                        handleDelete={handleDeleteOurFavourite}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            {isDeleteAllOurFavouriteOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete all products?"
                        description="Are you sure you want to delete all of the products?"
                        handleHideModal={handleDeleteAllOurFavouriteClose}
                        handleDelete={handleDeleteAllOurFavourite}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            <SaveBar
                message="Unpublished changes"
                submitLabel="Publish"
                isShown={isModified}
                isSaving={isSaving}
                onSubmit={handleSubmit}
                onCancel={handleClearChanges}
            />
        </ListingLayout>
    )
}

export default MainPageOurFavourites
