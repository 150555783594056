import fetch from "helpers/fetch"
import * as fetchStatus from "helpers/fetchStatus"

export const initialState = { data: {}, isLoading: fetchStatus.initialState().isLoading }

const getCollectionByIdStart = () => ({ type: "GET_COLLECTION_BY_ID_START" })
const getCollectionByIdSuccess = payload => ({ type: "GET_COLLECTION_BY_ID_SUCCESS", payload })
const getCollectionByIdError = paylaod => ({ type: "GET_COLLECTION_BY_ID_ERROR", paylaod })

export function reducer(state = initialState, action) {
    switch (action.type) {
        case getCollectionByIdStart().type:
            return { ...state, isLoading: fetchStatus.requestState().isLoading, data: {} }
        case getCollectionByIdSuccess().type:
            return { ...state, isLoading: fetchStatus.successState().isLoading, data: action.payload.data }
        case getCollectionByIdError().type:
            return { ...state, isLoading: fetchStatus.failureState().isLoading, data: {} }
        default:
            return state
    }
}

export const getCollectionById = async (apiUrl, dispatch) => {
    dispatch(getCollectionByIdStart())

    try {
        const payload = await fetch.get(apiUrl)
        dispatch(getCollectionByIdSuccess(payload))
        return payload
    } catch (error) {
        dispatch(getCollectionByIdError(error))
        return Promise.reject(error)
    }
}
