import fetch from "helpers/fetch"
import store from "store/index"
import { showErrorNotification } from "actions/notification"

const DEFAULT_DOWNLOAD_OPTIONS = { fileName: "Document", type: "application/pdf" }

const getDocumentObjectUrl = blob => {
    const newBlob = new Blob([blob], { type: "application/pdf" })
    return URL.createObjectURL(newBlob)
}

const checkIfSafariBrowser = () => {
    const userAgent = navigator.userAgent.toLowerCase()

    return (
        userAgent.indexOf("safari") !== -1 &&
        userAgent.indexOf("chrome") === -1 &&
        userAgent.indexOf("edg") === -1 &&
        userAgent.indexOf("opr") === -1
    )
}

const setDocumentTitle = (document, title) => {
    const head = document.createElement("head")
    head.innerHTML = `<title>${title}</title>`
    document.documentElement.prepend(head)
}

const printInCurrentTab = blob => {
    const iFrame = document.createElement("iframe")
    iFrame.name = "pdfIframe"
    iFrame.setAttribute("data-pdf", true)
    iFrame.style = "visibility: hidden;"
    iFrame.src = getDocumentObjectUrl(blob)
    document.body.appendChild(iFrame)

    setTimeout(() => {
        try {
            iFrame.contentWindow.print()
        } catch (error) {
            const a = document.createElement("a")
            document.body.appendChild(a)
            a.style = "display: none"
            a.setAttribute("data-pdf", true)
            a.href = iFrame.src
            a.click()
        }
    }, 1000)
}

const printInNewTab = blob => {
    const printTab = window.open(getDocumentObjectUrl(blob))

    const isNewTabOpened = !!printTab && !printTab.closed && typeof printTab.closed !== "undefined"

    if (isNewTabOpened) {
        printTab.addEventListener(
            "load",
            () => {
                setDocumentTitle(printTab.document, "Print document")
                setTimeout(printTab.print, 100)
            },
            false
        )
    } else {
        printInCurrentTab(blob)
    }
}

export const showPrintWindow = blob => {
    try {
        checkIfSafariBrowser() ? printInNewTab(blob) : printInCurrentTab(blob)
    } catch (e) {
        store.dispatch(
            showErrorNotification({
                title: "An error occurred. Try to disable pop-ups blockers."
            })
        )
    }
}

export const downloadBlobFile = (blob, options = {}) => {
    const { fileName, type } = { ...DEFAULT_DOWNLOAD_OPTIONS, ...options }
    const downloadBlob = new Blob([blob], { type })
    const link = document.createElement("a")
    link.href = URL.createObjectURL(downloadBlob)
    link.download = fileName
    link.style.display = "none"
    link.click()
}

const getPrintBlob = (id, printType = "order", templateType) => {
    const [isArray, isNumber] = [Array.isArray(id), typeof id === "number"]
    const isValidId = isArray || isNumber

    if (!isValidId) {
        return Promise.reject("id parameter in getPrintBlob must be number or number[]")
    }

    const ids = isNumber ? [id] : id

    return new Promise((resolve, reject) => {
        fetch
            .postRAW("/printables", {
                documents: ids.map(id => ({ id, type: printType, template_type: templateType }))
            })
            .then(response => response.blob())
            .then(blob => resolve(blob))
            .catch(error => reject(error))
    })
}

export default getPrintBlob
