import React, { Fragment, forwardRef, useEffect, useState } from "react"
import { Formik, Field } from "formik"
import _ from "lodash/core"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import { scrollToTop } from "helpers/scrollTo"

import Layout from "components/Reusable/Layout"
import Sidebar from "./components/Sidebar"
import Cover from "../../../components/Cover"
import SectionList from "../../../components/SectionList"
import { SaveBar, Input, Textarea } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import Modal from "components/Modal/Modal"
import ConfirmModal from "components/Modal/Confirm/Confirm"

import styles from "./styles.css"
import layoutStyles from "./overrides/layout.css"
import inputStyles from "./overrides/input.css"
import textareaStyles from "./overrides/textarea.css"
import modalStyles from "./overrides/ConfirmModal.css"

const IMAGE_COVER_DIMENSIONS = {
    desktop: {
        width: 2560
    },
    tablet: {
        width: 1024
    },
    mobile: {
        width: 735
    }
}

const SIDEBAR_IMAGES_DIMENSIONS = {
    thumbnail: {
        width: 1855
    }
}

const Form = forwardRef(
    (
        {
            cx,
            initValues,
            handleSubmit,
            handleCancel,
            isSubmitting,
            isLoading,
            showSuccessNotification,
            showErrorNotification
        },
        ref
    ) => {
        const [showConfirmationModal, setShowConfirmationModal] = useState(false)

        useEffect(() => {
            scrollToTop()
        }, [])

        const onCancel = () => {
            setShowConfirmationModal(true)
        }

        if (isLoading) {
            return (
                <Layout>
                    <div className={cx("loader")}>
                        <SnakeLoader />
                    </div>
                </Layout>
            )
        }

        return (
            <Fragment>
                <Formik initialValues={initValues} enableReinitialize onSubmit={handleSubmit} ref={ref}>
                    {propsForm => {
                        propsForm.errors = propsForm.submitCount === 0 ? {} : propsForm.errors

                        return (
                            <Fragment>
                                <Layout
                                    title={`${initValues.id ? "Edit" : "New"} Portfolio`}
                                    customStyles={layoutStyles}
                                >
                                    <div className={cx("contentWrapper")}>
                                        <div className={cx("section")}>
                                            <ul>
                                                <li className={cx("portfolioTitle")}>
                                                    <Field name="title">
                                                        {({ field }) => (
                                                            <Input
                                                                {...field}
                                                                label="Title *"
                                                                onChange={({ event }) => field.onChange(event)}
                                                                error={
                                                                    propsForm.touched[field.name] &&
                                                                    propsForm.errors[field.name]
                                                                }
                                                                customStyles={inputStyles}
                                                            />
                                                        )}
                                                    </Field>
                                                </li>

                                                <li className={cx("portfolioLocation")}>
                                                    <Field name="location">
                                                        {({ field }) => (
                                                            <Input
                                                                {...field}
                                                                label="Location"
                                                                onChange={({ event }) => field.onChange(event)}
                                                                error={
                                                                    propsForm.touched[field.name] &&
                                                                    propsForm.errors[field.name]
                                                                }
                                                                customStyles={inputStyles}
                                                            />
                                                        )}
                                                    </Field>
                                                </li>

                                                <li className={cx("portfolioPhotographyBy")}>
                                                    <Field name="photography_by">
                                                        {({ field }) => (
                                                            <Input
                                                                {...field}
                                                                label="Photography By"
                                                                onChange={({ event }) => field.onChange(event)}
                                                                error={
                                                                    propsForm.touched[field.name] &&
                                                                    propsForm.errors[field.name]
                                                                }
                                                                customStyles={inputStyles}
                                                            />
                                                        )}
                                                    </Field>
                                                </li>

                                                <li className={cx("portfolioCover")}>
                                                    <span className={cx("label")}>Collection cover:</span>
                                                    <Cover form={propsForm} imageDimensions={IMAGE_COVER_DIMENSIONS} />
                                                </li>

                                                <li className={cx("portfolioDescription")}>
                                                    <span className={cx("label")}>Description:</span>
                                                    <Field name="description">
                                                        {({ field }) => (
                                                            <Textarea
                                                                {...field}
                                                                value={field.value || ""}
                                                                label="Click here to add description..."
                                                                onChange={({ event }) => field.onChange(event)}
                                                                isPlaceholder={true}
                                                                error={propsForm.errors[field.name]}
                                                                customStyles={textareaStyles}
                                                            />
                                                        )}
                                                    </Field>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Sidebar form={propsForm} imagesDimensions={SIDEBAR_IMAGES_DIMENSIONS} />
                                </Layout>

                                {!!propsForm.values.id && (
                                    <SectionList
                                        sections={propsForm.values.sections || []}
                                        setSections={sections => propsForm.setFieldValue("sections", sections)}
                                        sectionableID={propsForm.values.id}
                                        sectionableType="realization"
                                        showErrorNotification={showErrorNotification}
                                        showSuccessNotification={showSuccessNotification}
                                    />
                                )}

                                <SaveBar
                                    isSaving={isSubmitting}
                                    isShown={!_.isEqual(initValues, propsForm.values)}
                                    submitLabel="Save"
                                    message="Unsaved changes"
                                    onCancel={onCancel}
                                    onSubmit={propsForm.handleSubmit}
                                    isSubmit
                                />
                            </Fragment>
                        )
                    }}
                </Formik>

                {showConfirmationModal && (
                    <Modal>
                        <ConfirmModal
                            confirmModalTitle="You have unsaved changes on this portfolio"
                            titleSecondLine="If you leave this portfolio, all unsaved changes will be lost. Are you sure you want to leave this portfolio?"
                            actionButtonLabel="Leave Portfolio"
                            handleHideModal={() => setShowConfirmationModal(false)}
                            customStyles={modalStyles}
                            handleDelete={handleCancel}
                        />
                    </Modal>
                )}
            </Fragment>
        )
    }
)

Form.propTypes = {
    cx: PropTypes.func.isRequired,
    initValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func,
    isSubmitting: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
}

export default withStyles(Form, styles)
