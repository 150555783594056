import React, { useState } from "react"
import PropTypes from "prop-types"

import {
    Table,
    TableHead,
    TableBody,
    TableHeadCell,
    TableCell,
    TableDateCell,
    TableRowWithExpandedDetails,
    TableCellWithExpandButton,
    Status,
    EmptyInternalList,
    ListIcon
} from "@butterfly-frontend/ui"
import { formatDate } from "helpers/date"
import { ProductCondition } from "modules/WmsModule/components"

import styles from "./StockItemDetailsConditionHistory.module.css"

const StockItemDetailsConditionHistory = props => {
    const [expandedRowId, setExpandedRowId] = useState(null)
    const { conditionHistory } = props

    const setActiveRow = id => setExpandedRowId(prevState => (prevState !== id ? id : null))

    const prepareProductConditionData = conditionHistoryEvent => ({
        images: conditionHistoryEvent.images,
        ...conditionHistoryEvent.product_condition,
        created_at: conditionHistoryEvent.created_at
    })

    return (
        <div className={styles.root}>
            <h2 className={styles.title}>Condition History</h2>
            {conditionHistory.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableHeadCell minWidth={50} width="20%">
                            ID
                        </TableHeadCell>
                        <TableHeadCell minWidth={100} width="20%">
                            WAREHOUSE
                        </TableHeadCell>
                        <TableHeadCell minWidth={100} width="20%">
                            CONDITION
                        </TableHeadCell>
                        <TableHeadCell minWidth={100} width="20%">
                            DATE
                        </TableHeadCell>
                        <TableHeadCell minWidth={100} width="15%">
                            BY
                        </TableHeadCell>
                        <TableHeadCell width={85} minWidth={85} />
                    </TableHead>
                    <TableBody>
                        {conditionHistory.map(conditionHistoryEvent => (
                            <TableRowWithExpandedDetails
                                key={conditionHistoryEvent.id}
                                isExpanded={expandedRowId === conditionHistoryEvent.id}
                                detailsComponent={
                                    <ProductCondition
                                        productConditionData={prepareProductConditionData(conditionHistoryEvent)}
                                        title="Product condition"
                                    />
                                }
                            >
                                <TableCell minWidth={50} width="20%">
                                    {conditionHistoryEvent.id}
                                </TableCell>
                                <TableCell minWidth={100} width="20%">
                                    {conditionHistoryEvent.warehouse.name}
                                </TableCell>
                                <TableCell minWidth={100} width="20%">
                                    <Status
                                        theme="outline"
                                        color={conditionHistoryEvent.product_condition.color}
                                        size="small"
                                        withCircle
                                    >
                                        {conditionHistoryEvent.product_condition.name}
                                    </Status>
                                </TableCell>
                                <TableDateCell minWidth={100} width="20%">
                                    {formatDate(conditionHistoryEvent.created_at)}
                                </TableDateCell>
                                <TableCell minWidth={100} width="15%">
                                    {conditionHistoryEvent.created_by
                                        ? conditionHistoryEvent.created_by.full_name
                                        : "N/A"}
                                </TableCell>
                                <TableCellWithExpandButton
                                    isExpanded={expandedRowId === conditionHistoryEvent.id}
                                    onClick={() => setActiveRow(conditionHistoryEvent.id)}
                                />
                            </TableRowWithExpandedDetails>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyInternalList text="It has no Condition History" Icon={ListIcon} />
            )}
        </div>
    )
}

StockItemDetailsConditionHistory.propTypes = {
    conditionHistory: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            warehouse: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired
            }).isRequired,
            product_condition: PropTypes.shape({
                name: PropTypes.string.isRequired,
                color: PropTypes.string.isRequired
            }).isRequired,
            created_at: PropTypes.string.isRequired
        })
    )
}

export default StockItemDetailsConditionHistory
