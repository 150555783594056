import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./ClientCardAddresses.css"
import Address from "../ClientCardAddresses/Address/Address"

class ClientCardAddresses extends Component {
    render() {
        const { showAllAddresses, fullProfile, clientTypesList } = this.props
        const { data, isLoaded } = this.props.client
        const {
            email,
            alternative_email,
            phone,
            mobile,
            instagram,
            twitter,
            addresses,
            referral,
            active,
            vip,
            verified
        } = data
        return (
            <div styleName="addresses-cover">
                <div styleName={fullProfile ? "full-profile-email" : ""}>
                    <h5 styleName="bold">E-mail address: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>{email}</h5>
                </div>
                {alternative_email && (
                    <div styleName={fullProfile ? "full-profile-email" : ""}>
                        <h5 styleName="bold">Alternative e-mail: </h5>
                        <h5 styleName={isLoaded ? "property" : "property-placeholder"}>{alternative_email}</h5>
                    </div>
                )}
                {referral && (
                    <div styleName={fullProfile ? "full-profile-email" : ""}>
                        <h5 styleName="bold">Referral: </h5>
                        <h5 styleName={isLoaded ? "property" : "property-placeholder"}>{referral}</h5>
                    </div>
                )}
                {data.types && clientTypesList && (
                    <div styleName={fullProfile ? "full-profile-email" : ""}>
                        <h5 styleName="bold">Type Of Contact: </h5>
                        <h5 styleName={isLoaded ? "property" : "property-placeholder"}>
                            {data.types
                                .map(type => {
                                    const clientType = clientTypesList.find(item => item.id === type)
                                    if (!clientType) {
                                        return null
                                    }

                                    return clientType.name
                                })
                                .join(", ")}
                        </h5>
                    </div>
                )}

                <div styleName={fullProfile ? "full-profile-company" : ""}>
                    <h5 styleName="bold">Active: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>{active ? "Yes" : "No"}</h5>
                </div>
                <div styleName={fullProfile ? "full-profile-company" : ""}>
                    <h5 styleName="bold">VIP: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>{vip ? "Yes" : "No"}</h5>
                </div>
                <div styleName={fullProfile ? "full-profile-company" : ""}>
                    <h5 styleName="bold">Verified: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>{verified ? "Yes" : "No"}</h5>
                </div>
                <div styleName={fullProfile ? "full-profile-company" : ""}>
                    <h5 styleName="bold">Assigned To: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>{this.getAssignedToUser()}</h5>
                </div>
                <div styleName={fullProfile ? "full-profile-company" : ""}>
                    <h5 styleName="bold">Assigned To: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>
                        {this.getAssignedToUser("assigned_to_2")}
                    </h5>
                </div>
                <div styleName={fullProfile ? "full-profile-company" : ""}>
                    <h5 styleName="bold">Company Name: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>
                        {addresses && addresses[0] && addresses[0].company}
                    </h5>
                </div>
                <div styleName={fullProfile ? "full-profile-phone" : ""}>
                    <h5 styleName="bold">Phone Number: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>
                        {phone ? <a href={`tel:${phone}`}>{phone}</a> : "-"}
                    </h5>
                </div>
                <div styleName={fullProfile ? "full-profile-mobile" : ""}>
                    <h5 styleName="bold">Mobile Phone: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>
                        {mobile ? <a href={`tel:${mobile}`}>{mobile}</a> : "-"}
                    </h5>
                </div>
                <div styleName={fullProfile ? "full-profile-instagram" : ""}>
                    <h5 styleName="bold">Instagram: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>
                        {instagram ? (
                            <a href={`https://instagram.com/${instagram}`} target="blank">
                                @{instagram}
                            </a>
                        ) : (
                            "-"
                        )}
                    </h5>
                </div>
                <div styleName={fullProfile ? "full-profile-twitter" : ""}>
                    <h5 styleName="bold">Twitter: </h5>
                    <h5 styleName={isLoaded ? "property" : "property-placeholder"}>
                        {twitter ? (
                            <a href={`https://twitter.com/${twitter}`} target="blank">
                                @{twitter}
                            </a>
                        ) : (
                            "-"
                        )}
                    </h5>
                </div>

                <div styleName={isLoaded ? "" : "address-placeholder"}>
                    {addresses === undefined
                        ? null
                        : addresses.map((address, index) => {
                              if (!showAllAddresses && address.type !== "personal") {
                                  return null
                              }
                              return <Address isLoaded={isLoaded} key={index} {...address} fullProfile={fullProfile} />
                          })}
                </div>
                <div className="clear" />
            </div>
        )
    }

    getAssignedToUser(field = "assigned_to") {
        const { users, client } = this.props
        const { data } = client

        const assigned_to = data[field] ? data[field] : false

        if (!assigned_to || !users) {
            return "-"
        }

        const user = users.find(user => Number(user.id) === Number(assigned_to))

        if (!user) {
            return "-"
        }

        return `${user.first_name} ${user.last_name}`
    }
}

export default CSSModules(ClientCardAddresses, style)
