import React, { Component, Fragment } from "react"
import { Field, Formik, Form as FormikForm } from "formik"
import * as Yup from "yup"

import withStyles from "HOC/withStyles"
import { checkIsDark } from "helpers/color"

import { Checkbox, SelectWithSearch, SketchColorPicker } from "ui"
import { ErrorLabel } from "ui/SelectWithSearch/components"
import Modal from "components/Modal/Modal"
import Button from "components/Reusable/Form/Button"
import Input from "components/Reusable/Form/Input"

import styles from "./AddEditModal.css"
import checkboxStyles from "./overrides/Checkbox.css"

import close from "assets/close.svg"
import addModeratorIcon from "assets/add.svg"

const schema = Yup.lazy(form =>
    Yup.object().shape({
        title: Yup.string()
            .max(250, "Title may not be greater than 250 characters")
            .required("This field is required."),
        moderators: Yup.array().test("required", "This field is required.", value => {
            if (form.is_moderated) {
                return value && value[0] && Object.keys(value[0]).length
            }
            return true
        }),
        editors: Yup.array().test("required", "This field is required.", value => {
            if (form.only_editors) {
                return value && value[0] && Object.keys(value[0]).length
            }
            return true
        })
    })
)

const calendarColors = ["#cdd2de", "#e89230", "#6ba64d", "#57a476", "#546382", "#4d7aa6", "#bb7e8f", "#a66037"]
const calendarAccessOptions = [{ name: "private", label: "Private" }, { name: "public", label: "Public" }]

const INIT_VALUES_FORM = {
    title: "",
    color: "#CDD2DE",
    font_color: "#2e3545",
    calendarAccess: calendarAccessOptions[0],
    type: "common",
    is_moderated: false,
    moderators: [{}],
    recipients: [{}],
    only_editors: false,
    editors: [{}],
    shares: [{}]
}

class AddEditModal extends Component {
    state = {
        isSubmitting: false
    }

    componentDidMount() {
        this.props.getUsersList()
    }

    handleSubmit = (values, formikProps) => {
        const { handleHideModal, handleCreate, handleEdit, editedCalendar, showErrorNotification } = this.props

        this.setState({ isSubmitting: true })

        const isPublic = values.calendarAccess.name === "public"
        const payload = {
            title: values.title,
            color: values.color,
            font_color: values.font_color,
            is_moderated: !!values.is_moderated,
            only_editors: !!values.only_editors,
            type: values.type
        }

        if (isPublic) {
            values.is_moderated &&
                Object.assign(payload, {
                    moderators: values.moderators.filter(moderator => !!moderator.id).map(({ id }) => ({ id })),
                    recipients: values.recipients.filter(recipient => !!recipient.id).map(({ id }) => ({ id }))
                })
            values.only_editors &&
                Object.assign(payload, {
                    editors: values.editors.filter(editor => !!editor.id).map(({ id }) => ({ id }))
                })
        }

        if (!isPublic) {
            Object.assign(payload, { shares: values.shares.filter(share => !!share.id).map(({ id }) => ({ id })) })
        }

        if (!editedCalendar) {
            Object.assign(payload, { is_public: isPublic })
        }

        const action = editedCalendar ? handleEdit(editedCalendar.id, payload) : handleCreate(payload)

        action
            .then(data => {
                if (data && data.payload.error) {
                    showErrorNotification(data.payload.error.message)
                    this.setState({
                        isSubmitting: false
                    })
                } else {
                    handleHideModal()
                }
            })
            .catch(data => {
                if (data && data.errors) {
                    formikProps.setErrors(data.errors)
                }

                this.setState({
                    isSubmitting: false
                })
            })
    }

    render() {
        const { cx, handleHideModal, users, title, editedCalendar } = this.props
        const parsedUsers = users.map(user => ({ label: user.full_name, value: user }))

        if (editedCalendar) {
            Object.assign(editedCalendar, {
                calendarAccess: editedCalendar.is_public ? calendarAccessOptions[1] : calendarAccessOptions[0],
                shares: editedCalendar.shares.some(share => !!share.id) ? editedCalendar.shares : [{}],
                moderators: editedCalendar.moderators.length ? editedCalendar.moderators : [{}],
                recipients: editedCalendar.recipients.length ? editedCalendar.recipients : [{}],
                editors: editedCalendar.editors.length ? editedCalendar.editors : [{}],
                font_color: editedCalendar.font_color || (checkIsDark(editedCalendar.color) ? "#cdd2de" : "#2e3545")
            })
        }

        return (
            <Modal>
                <div className={cx("modal-background")}>
                    <div className={cx("modal-cover")}>
                        <img
                            src={close}
                            alt="close"
                            className={cx("close-icon")}
                            onClick={() => {
                                handleHideModal()
                            }}
                        />
                        <p className={cx("title")}>{title}</p>

                        <Formik
                            initialValues={{ ...INIT_VALUES_FORM, ...editedCalendar }}
                            onSubmit={this.handleSubmit}
                            validationSchema={schema}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {({ values, errors, setFieldValue, setFieldTouched, setFieldError }) => {
                                const isPublicSelected = values.calendarAccess.name === "public"

                                return (
                                    <FormikForm className={cx("form")} noValidate>
                                        {!values.is_days_off && (
                                            <Fragment>
                                                <div className={cx("input-field")}>
                                                    <Field name="title">
                                                        {({ field }) => {
                                                            return (
                                                                <Input
                                                                    label="Write a calendar name..."
                                                                    isPlaceholder={true}
                                                                    customStyles={styles}
                                                                    className={"input"}
                                                                    error={errors.title}
                                                                    value={values.title}
                                                                    attributes={{ required: true }}
                                                                    onChange={value => {
                                                                        setFieldError(field.name, null)
                                                                        setFieldValue(
                                                                            field.name,
                                                                            value.event.target.value
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                </div>
                                                <div className={cx("input-field", "input-field--dropdown")}>
                                                    <Field name="color">
                                                        {({ field }) => (
                                                            <SketchColorPicker
                                                                name="color"
                                                                value={values.color}
                                                                presetColors={calendarColors}
                                                                onChange={value => setFieldValue(field.name, value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <Field name="font_color">
                                                        {({ field }) => (
                                                            <SketchColorPicker
                                                                name="font_color"
                                                                value={values.font_color}
                                                                presetColors={calendarColors}
                                                                onChange={value => setFieldValue(field.name, value)}
                                                                backgroundColor={values.color}
                                                                isFontColor
                                                            />
                                                        )}
                                                    </Field>

                                                    <Field name="calendarAccess">
                                                        {({ field }) => {
                                                            return (
                                                                <SelectWithSearch
                                                                    customStyles={styles}
                                                                    values={calendarAccessOptions}
                                                                    value={values.calendarAccess.label}
                                                                    setValue={value => setFieldValue(field.name, value)}
                                                                    isClearBlocked={true}
                                                                    isDisabled={!!editedCalendar}
                                                                    withoutFetch
                                                                />
                                                            )
                                                        }}
                                                    </Field>
                                                </div>
                                            </Fragment>
                                        )}

                                        {isPublicSelected && (
                                            <Fragment>
                                                {!values.is_days_off && (
                                                    <div className={cx("input-field", "input-field--checkbox")}>
                                                        <Field name="is_moderated">
                                                            {({ field }) => (
                                                                <Checkbox
                                                                    checked={values.is_moderated}
                                                                    onChange={() =>
                                                                        setFieldValue(field.name, !field.value)
                                                                    }
                                                                    name="Events are moderated"
                                                                    customStyles={checkboxStyles}
                                                                />
                                                            )}
                                                        </Field>
                                                        <Field name="only_editors">
                                                            {({ field }) => (
                                                                <Checkbox
                                                                    checked={values.only_editors}
                                                                    onChange={() =>
                                                                        setFieldValue(field.name, !field.value)
                                                                    }
                                                                    name="Events are editable"
                                                                    customStyles={checkboxStyles}
                                                                />
                                                            )}
                                                        </Field>
                                                    </div>
                                                )}

                                                {values.is_moderated &&
                                                    renderMultipleUsers({
                                                        label: "Moderators",
                                                        canAdd: !values.moderators.some(obj => !obj || !obj.id),
                                                        onAdd: () =>
                                                            setFieldValue("moderators", [...values.moderators, {}]),
                                                        buttonLabel: "Add moderator",
                                                        errors: errors.moderators,
                                                        values: values.moderators,
                                                        onRemove: moderator =>
                                                            setFieldValue(
                                                                "moderators",
                                                                values.moderators.length > 1
                                                                    ? values.moderators.filter(
                                                                          ({ id }) => id !== moderator.id
                                                                      )
                                                                    : [{}]
                                                            ),
                                                        setValue: (fieldName, value) => setFieldValue(fieldName, value),
                                                        placeholder: "- Choose a moderator -",
                                                        fieldName: "moderators"
                                                    })}

                                                {values.is_moderated &&
                                                    renderMultipleUsers({
                                                        label: "Emails notifications",
                                                        canAdd: !values.recipients.some(obj => !obj || !obj.id),
                                                        onAdd: () =>
                                                            setFieldValue("recipients", [...values.recipients, {}]),
                                                        buttonLabel: "Add new",
                                                        errors: errors.recipients,
                                                        values: values.recipients,
                                                        onRemove: recipient =>
                                                            setFieldValue(
                                                                "recipients",
                                                                values.recipients.length > 1
                                                                    ? values.recipients.filter(
                                                                          ({ id }) => id !== recipient.id
                                                                      )
                                                                    : [{}]
                                                            ),
                                                        setValue: (fieldName, value) => setFieldValue(fieldName, value),
                                                        placeholder: "- Choose a user -",
                                                        fieldName: "recipients"
                                                    })}

                                                {values.only_editors &&
                                                    renderMultipleUsers({
                                                        label: "Editors",
                                                        canAdd: !values.editors.some(obj => !obj || !obj.id),
                                                        onAdd: () => setFieldValue("editors", [...values.editors, {}]),
                                                        buttonLabel: "Add editor",
                                                        errors: errors.editors,
                                                        values: values.editors,
                                                        onRemove: editor =>
                                                            setFieldValue(
                                                                "editors",
                                                                values.editors.length > 1
                                                                    ? values.editors.filter(
                                                                          ({ id }) => id !== editor.id
                                                                      )
                                                                    : [{}]
                                                            ),
                                                        setValue: (fieldName, value) => setFieldValue(fieldName, value),
                                                        placeholder: "- Choose an editor -",
                                                        fieldName: "editors"
                                                    })}
                                            </Fragment>
                                        )}

                                        {!isPublicSelected &&
                                            renderMultipleUsers({
                                                label: "Share calendar",
                                                canAdd: !values.shares.some(obj => !obj || !obj.id),
                                                onAdd: () => setFieldValue("shares", [...values.shares, {}]),
                                                buttonLabel: "Add employee",
                                                errors: errors.shares,
                                                values: values.shares,
                                                onRemove: share =>
                                                    setFieldValue(
                                                        "shares",
                                                        values.shares.length > 1
                                                            ? values.shares.filter(({ id }) => id !== share.id)
                                                            : [{}]
                                                    ),
                                                setValue: (fieldName, value) => setFieldValue(fieldName, value),
                                                placeholder: "- Choose an employee -",
                                                fieldName: "shares"
                                            })}

                                        <div className={cx("footer")}>
                                            <Button
                                                type="submit"
                                                isLoading={this.state.isSubmitting}
                                                isDisabled={this.state.isSubmitting}
                                                className={cx(["first-button", "button", "create-button"])}
                                                label={editedCalendar ? "Save changes" : "Create"}
                                            />
                                        </div>
                                    </FormikForm>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </Modal>
        )

        function renderMultipleUsers(params) {
            return (
                <div>
                    <div className={cx("separator")} />
                    <div className={cx("input-field", "input-field--dropdown", "input-field--full-width")}>
                        <span className={cx("input-field-title")}>{params.label}:</span>
                        {params.values.map((item, key) => {
                            return (
                                <Field name={`${params.fieldName}.${key}`} key={key}>
                                    {({ field }) => (
                                        <SelectWithSearch
                                            placeholder={params.placeholder}
                                            customStyles={styles}
                                            values={parsedUsers.filter(
                                                user =>
                                                    user.value.id === item.id ||
                                                    !params.values.some(
                                                        selectedUser => selectedUser.id === user.value.id
                                                    )
                                            )}
                                            value={item.full_name || null}
                                            setValue={({ value }) => params.setValue(field.name, value)}
                                            withoutFetch
                                            handleClear={() => params.onRemove(item)}
                                        />
                                    )}
                                </Field>
                            )
                        })}
                        {params.errors && <ErrorLabel error={params.errors} />}
                    </div>

                    <button
                        className={cx(["button", "add-user-button"])}
                        disabled={!params.canAdd}
                        onClick={params.onAdd}
                        type="button"
                    >
                        <img src={addModeratorIcon} alt="" />
                        {params.buttonLabel}
                    </button>
                </div>
            )
        }
    }
}

export default withStyles(AddEditModal, styles)
