import React, { useState, useEffect } from "react"
import { sortBy } from "lodash"
import { connect } from "react-redux"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"
import handleGallerySetupHelper from "./galleryHelper"

import withMultipleUploader from "HOC/withMultipleUploader"
import * as notificationActions from "actions/notification"
import * as uploadModuleActions from "actions/uploadModule"

import GalleryUploader from "./GalleryUploader"

const MultipleUploader = withMultipleUploader(GalleryUploader)

const GalleryUploaderContainer = props => {
    const {
        savedFiles,
        isImagesLoading,
        isImageDisabled,
        handlePassUploadedImages,
        handleDeleteFile,
        handleDndOrder,
        onCropSuccess
    } = props

    const [allFiles, setAllFiles] = useState([])
    const [uploadingFiles, setUploadingFiles] = useState({})
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [gridLayout, setGridLayout] = useState([])
    const [gallerySetup, setGallerySetup] = useState({})

    const createGridLayout = files => {
        return files.map((el, index) => createGridLayoutItem(el.path, index))
    }

    const createGridLayoutItem = (key, index) => {
        const cols = gallerySetup.COLS
        return { i: key, x: index % cols, y: (index - (index % cols)) / cols, w: 1, h: 1 }
    }

    useEffect(() => {
        setGallerySetup(props.handleGallerySetup())

        setTimeout(() => {
            setGallery()
        }, 0)

        window.addEventListener("resize", setGallery)

        return () => window.removeEventListener("resize", setGallery)
    }, [])

    useEffect(() => {
        if (allFiles.length !== savedFiles.length) {
            setAllFiles(savedFiles)
            setGridLayout(createGridLayout(savedFiles))
        }
    }, [savedFiles])

    const setGallery = () => {
        setGallerySetup(props.handleGallerySetup())
        setGridLayout(createGridLayout(savedFiles))
    }

    const handleToggleDeleteModal = () => {
        setIsDeleteModal(!isDeleteModal)
    }

    const handleUploadReject = data => {
        const { showErrorNotification } = props
        const error = data && data.error && data.error[0]
        showErrorNotification(error)
    }

    const handleUploadSuccess = addedFiles => {
        setAllFiles([...allFiles, ...addedFiles])
        setUploadingFiles({})
        handlePassUploadedImages(addedFiles)
    }

    function handleUploadFileSuccess(file, index) {
        setUploadingFiles(uploadingFiles => ({
            ...uploadingFiles,
            [index]: file
        }))
    }

    const handleLayoutChange = layout => {
        const sortedLayout = sortBy(layout, ["y", "x"]).map((item, index) => createGridLayoutItem(item.i, index))

        setGridLayout(sortedLayout)

        const dndFilesUrlOrder = sortedLayout.map(item => item.i)

        handleDndOrder(dndFilesUrlOrder)
    }

    return (
        <MultipleUploader
            {...props}
            allFiles={allFiles}
            uploadingFiles={uploadingFiles}
            handleSetList={setAllFiles}
            isImagesLoading={isImagesLoading}
            isImageDisabled={isImageDisabled}
            endpoint={DEFAULT_FILE_UPLOAD_ENPOINT}
            gridLayout={gridLayout}
            gallerySetup={gallerySetup}
            createGridLayoutItem={createGridLayoutItem}
            isDeleteModal={isDeleteModal}
            handleToggleDeleteModal={handleToggleDeleteModal}
            handleUploadReject={handleUploadReject}
            handleUploadSuccess={handleUploadSuccess}
            handleUploadFileSuccess={handleUploadFileSuccess}
            handleDeleteImage={handleDeleteFile}
            handleLayoutChange={handleLayoutChange}
            onCropSuccess={onCropSuccess}
        />
    )
}

GalleryUploaderContainer.defaultProps = {
    handleGallerySetup: () => handleGallerySetupHelper(window.innerWidth)
}

const mapStateToProps = state => {
    return {
        isImagesLoading: state.db.uploadModule.current.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setImageDraggingStatus: status => dispatch(uploadModuleActions.setImageDraggingStatus(status)),
        showErrorNotification: data => dispatch(notificationActions.showErrorNotification(data))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GalleryUploaderContainer)
