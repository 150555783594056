import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getCategoriesStart() {
    return {
        type: constants.GET_CATEGORIES_START
    }
}
export function getCategoriesSuccess(data) {
    return {
        type: constants.GET_CATEGORIES_SUCCESS,
        payload: {
            data
        }
    }
}
export function getCategoriesError(error) {
    return {
        type: constants.GET_CATEGORIES_ERROR,
        payload: {
            error
        }
    }
}
export function getCategories() {
    return dispatch => {
        dispatch(getCategoriesStart())
        fetch
            .get(`/categories?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => {
                dispatch(getCategoriesSuccess(data))
            })
            .catch(error => {
                dispatch(getCategoriesError(error))
            })
    }
}

export function getCategoryByIdStart() {
    return {
        type: constants.GET_CATEGORY_BY_ID_START
    }
}
export function getCategoryByIdSuccess(data) {
    return {
        type: constants.GET_CATEGORY_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}
export function getCategoryByIdError(error) {
    return {
        type: constants.GET_CATEGORY_BY_ID_ERROR,
        payload: {
            error
        }
    }
}
export function getCategoryById(id) {
    return dispatch => {
        dispatch(getCategoryByIdStart())
        fetch
            .get(`/categories/${id}`)
            .then(data => {
                dispatch(getCategoryByIdSuccess(data))
            })
            .catch(error => {
                dispatch(getCategoryByIdError(error))
            })
    }
}
