import React from "react"

import { Button, RefreshIcon, AddIcon } from "@butterfly-frontend/ui"
import SelectedActions from "../SelectedActions"
import ReturnButton from "../ReturnButton"

import withStyles from "HOC/withStyles"
import styles from "./TopBar.css"

const TopBar = props => {
    const {
        cx,
        productsCount,
        checkedCount,
        areAllChecked,
        handleAction,
        canCreate,
        canDelete,
        isCreating,
        selectedActions,
        buttonDisabled,
        shouldRenderReturnButton,
        returnButtonProps,
        isRefreshingTax
    } = props

    const areSelected = !!checkedCount
    const selectedLabel = `${areAllChecked ? "All s" : "S"}elected`

    const onAddProduct = () => handleAction("addProduct")
    const refreshTax = () => handleAction("refreshTax")

    return (
        <header className={cx("root")}>
            <h3>
                {areSelected ? selectedLabel : "Items"}
                <span className={cx("productsCount", { areSelected })}>
                    {areSelected ? checkedCount : productsCount}
                </span>
            </h3>
            {areSelected ? (
                <div className={styles.options}>
                    <SelectedActions
                        checkedCount={checkedCount}
                        handleAction={handleAction}
                        canCreate={canCreate}
                        canDelete={canDelete}
                        isCreating={isCreating}
                        selectedActions={selectedActions}
                    />
                    {shouldRenderReturnButton && <ReturnButton {...returnButtonProps} />}
                </div>
            ) : (
                <div className={styles.options}>
                    {shouldRenderReturnButton && <ReturnButton {...returnButtonProps} />}
                    <Button Icon={AddIcon} onClick={onAddProduct} disabled={buttonDisabled}>
                        Add Item
                    </Button>
                    <Button
                        Icon={RefreshIcon}
                        onClick={refreshTax}
                        isLoading={isRefreshingTax}
                        disabled={!productsCount}
                    >
                        Tax
                    </Button>
                </div>
            )}
        </header>
    )
}

export default withStyles(TopBar, styles)
