import React, { Component } from "react"
import ReactSVG from "react-svg"

import { withStyles } from "ui/FilterableTable/HOC"
import { AppContext } from "ui/FilterableTable"
import styles from "./Sort.css"

import ascIcon from "ui/FilterableTable/assets/asc.svg"
import descIcon from "ui/FilterableTable/assets/desc.svg"

class SortConsumer extends Component {
    render() {
        return <AppContext.Consumer>{context => <Sort {...this.props} context={context} />}</AppContext.Consumer>
    }
}

class Sort extends Component {
    componentDidMount() {
        const { sortBy, sortDirection, context, isDefault } = this.props

        if (isDefault) {
            context.initSorting(sortBy, sortDirection)
        }
    }

    render() {
        const { cx, children, sortBy, sortDirection, isDefault, context } = this.props

        const { changeSorting, data } = context
        const { by: ctxBy, direction: ctxDirection } = data.sorting || {}
        const { by, direction } = { by: isDefault ? ctxBy || sortBy : ctxBy, direction: ctxDirection || sortDirection }
        const [isAsc, isDesc] = by === sortBy ? [direction === "asc", direction === "desc"] : [false, false]

        return (
            <div
                className={cx("root", { isAsc, isDesc })}
                onClick={() => changeSorting(sortBy, direction === "asc" ? "desc" : "asc")}
            >
                <span className={cx("name")}>{children}</span>
                <span className={cx("icons")}>
                    <ReactSVG src={ascIcon} className="icon-asc" />
                    <ReactSVG src={descIcon} className="icon-desc" />
                </span>
            </div>
        )
    }
}

export default withStyles(styles)(SortConsumer)
