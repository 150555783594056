import React, { useEffect, useReducer, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { useField } from "formik"
import idx from "idx"

import Select from "ui/Select"

import { showErrorNotification } from "actions/notification"

import fetch from "helpers/fetch"
import { displayUserName } from "helpers/user"
import useFetch from "hooks/useFetch"

import { LENGTH_WITHOUT_PAGINATION, ITEMS_PER_PAGE } from "constants/index"

const listReducer = (state, newState) => ({
    ...state,
    ...newState,
    data: idx(newState, _ => _.meta.current_page) === 1 ? newState.data : [...state.data, ...newState.data]
})
const defaultListState = {
    data: [],
    meta: { current_page: 0, last_page: 1 }
}

const PersonSelect = props => {
    const {
        withPagination,
        isListOnTop,
        name,
        label,
        fetchPath,
        handleSetValue,
        exceptedValues,
        additionalButtonLabel,
        additionalButtonAction,
        customStyles,
        isRequired
    } = props

    const [list, setList] = useReducer(listReducer, defaultListState)
    const { data: values, meta: pagination } = list

    useEffect(() => {
        !withPagination && fetchValues("", 1)
    }, [])

    const [field, fieldMeta] = useField(name)

    const dispatch = useDispatch()

    const [fetchValues, { isLoading }] = useFetch({
        action: (query, page) =>
            fetch.get(
                `${fetchPath}?query=${query}&page=${page}&length=${
                    withPagination ? ITEMS_PER_PAGE : LENGTH_WITHOUT_PAGINATION
                }`
            ),
        onSuccess: setList,
        onError: err => dispatch(showErrorNotification(err.message))
    })
    const handleFetchValues = (query, page) => !isLoading && fetchValues(query, page)

    const setValue = value => {
        field.onChange({ target: { ...field, value } })
        typeof handleSetValue === "function" && handleSetValue(value)
    }

    const formattedValues = useMemo(
        () =>
            values.reduce((acc, item) => {
                const isChosenValue = idx(field, _ => _.value.value) === item.id
                const isExceptedValue = exceptedValues.some(({ id }) => id === item.id)

                return isChosenValue || isExceptedValue
                    ? acc
                    : [...acc, { id: item.id, value: item.id, label: displayUserName(item) }]
            }, []),
        [values, field.value, exceptedValues]
    )
    const currentValue = idx(field, _ => _.value.label) || ""

    const error = !!fieldMeta.error && (fieldMeta.error.value || fieldMeta.error)

    return (
        <Select
            withSearch
            withFetch={withPagination}
            isListOnTop={isListOnTop}
            isDisabled={!withPagination && isLoading}
            label={label}
            value={currentValue}
            error={error}
            values={formattedValues}
            pagination={pagination}
            setValue={setValue}
            fetchValues={handleFetchValues}
            customStyles={customStyles}
            additionalButtonLabel={additionalButtonLabel}
            additionalButtonAction={additionalButtonAction}
            isRequired={isRequired}
        />
    )
}

PersonSelect.defaultProps = {
    exceptedValues: []
}

PersonSelect.propTypes = {
    withPagination: PropTypes.bool,
    isListOnTop: PropTypes.bool,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fetchPath: PropTypes.string.isRequired,
    handleSetValue: PropTypes.func,
    exceptedValues: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number
        })
    ),
    additionalButtonLabel: PropTypes.string,
    additionalButtonAction: PropTypes.func,
    customStyles: PropTypes.object
}

export default PersonSelect
