import React, { Component } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import isEqual from "lodash/isEqual"
import withPermissions from "HOC/withPermissions"

import MainPage from "./pages/MainPage"
import Inspirations from "./pages/Inspirations"
import News from "./pages/News"
import Trade from "./pages/Trade"
import CategoriesMenu from "./pages/CategoriesMenu"
import SubcategoriesMenu from "./pages/SubcategoriesMenu"
import DefaultFilters from "./pages/DefaultFilters"
import Portfolio from "./pages/Portfolio"
import Press from "./pages/Press"

class Content extends Component {
    componentDidMount() {
        const { history, canList, canEdit, PERMISSIONS } = this.props

        if (!canList(PERMISSIONS.context.CONTENT) || !canEdit(PERMISSIONS.context.CONTENT)) {
            return history.push("/dashboard")
        }
    }

    shouldComponentUpdate(nextProps) {
        return !isEqual(nextProps, this.props)
    }

    render() {
        return (
            <Switch>
                <Route path="/content/main-page" component={MainPage} />
                <Route path="/content/inspirations" component={Inspirations} />
                <Route path="/content/news" component={News} />
                <Route path="/content/trades" component={Trade} />
                <Redirect from="/content/navigation" exact to="/content/categories-menu" />
                <Route path="/content/categories-menu" exact component={CategoriesMenu} />
                <Route path="/content/categories-menu/:categoryId" component={SubcategoriesMenu} />
                <Route path="/content/default-filters" component={DefaultFilters} />
                <Route path="/content/portfolio" component={Portfolio} />
                <Route path="/content/press" component={Press} />
            </Switch>
        )
    }
}

export default withPermissions(Content)
