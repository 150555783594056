import React, { Component } from "react"

import withStyles from "HOC/withStyles"
import style from "./ChangeableListingView.css"

class ChangeableListingView extends Component {
    render() {
        const { cx, children, listingActiveStyle, handleSetListingStyle } = this.props
        const isListView = listingActiveStyle === "list"
        const viewTileIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                    <path d="M3 3h18v18H3z" />
                    <path
                        className={cx("withFill")}
                        fillRule="nonzero"
                        d="M6 11h5V6H6v5zm7 0V6h5v5h-5zm-7 7v-5h5v5H6zm7 0v-5h5v5h-5z"
                    />
                </g>
            </svg>
        )
        const viewListIcon = (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                    <path d="M3 3h18v18H3z" />
                    <path
                        className={cx("withFill")}
                        fillRule="nonzero"
                        d="M5 9V6h3v3H5zm0 4.5v-3h3v3H5zM5 18v-3h3v3H5zm4-3h10v3H9v-3zm0-4.5h10v3H9v-3zM9 6h10v3H9V6z"
                    />
                </g>
            </svg>
        )

        const buttonsConfig = [{ name: "tiles", icon: viewTileIcon }, { name: "list", icon: viewListIcon }]

        const activeStyle = listingActiveStyle || "tiles"

        return (
            <div className={cx("root", { isListView })}>
                <div className={cx("buttons")}>
                    <p>View:</p>
                    {buttonsConfig.map(({ name, icon }) => {
                        return (
                            <button
                                key={name}
                                className={cx({ isActive: activeStyle === name })}
                                onClick={() => handleSetListingStyle(name)}
                            >
                                {icon}
                            </button>
                        )
                    })}
                </div>
                <ul>{children}</ul>
            </div>
        )
    }
}

export default withStyles(ChangeableListingView, style)
