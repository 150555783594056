import React, { Component, Fragment } from "react"
import ContentHeader from "components/Content/ContentHeader/index"
import RolesManagement from "./RolesManagement/RolesManagement"
import { MENU } from "constants/index"
import withPermissions from "HOC/withPermissions"
import { getUserManagementTabs } from "components/Content/ContentMain/Settings/helpers"

class RolesDisplay extends Component {
    render() {
        return (
            <Fragment>
                <ContentHeader title={MENU.SETTINGS_USERS_MANAGEMENT} tabs={getUserManagementTabs(this.props)} />
                <RolesManagement {...this.props} />
            </Fragment>
        )
    }
}

export default withPermissions(RolesDisplay)
