import React, { Fragment, Component } from "react"
import moment from "moment"
import * as Yup from "yup"

import withStyles from "HOC/withStyles"
import { displayUserName } from "helpers/user"
import fetch from "helpers/fetch"

import Modal from "components/Modal/Modal"
import Input from "components/Reusable/Form/Input"
import Textarea from "components/Reusable/Form/Textarea"
import { Checkbox, SelectWithSearch, DatePickerWithInput, TimePicker } from "ui"
import { SelectUser } from "ui/Filters"
import { ErrorLabel } from "ui/SelectWithSearch/components"
import { Field, Formik } from "formik"
import Button from "components/Reusable/Form/Button"

import styles from "./AddEditEventModal.css"
import datepickerStyles from "../../../../modules/ProjectsModule/pages/Form/overrides/datepicker.css"
import addModeratorIcon from "assets/add.svg"
import addIcon from "assets/add-white.svg"
import close from "assets/close.svg"
import arrow from "assets/arrow_down_blue.svg"

import {
    TYPE_CLIENT_MEETING,
    TYPE_DAYS_OFF,
    TYPE_MY_CALENDAR,
    TYPE_SELECTED_CLIENT_MEETING
} from "modules/CalendarModule/constants/calendarTypes"
import { EVENT_TYPES } from "modules/CalendarModule/constants/eventTypes"

const schema = Yup.lazy(form => {
    return Yup.object().shape({
        client: Yup.string().test("required", "This field is required", value => {
            const {
                calendarType: { type }
            } = form
            if (type === TYPE_CLIENT_MEETING) {
                return value && value.length
            }

            return true
        }),
        date: Yup.string().test(
            "required",
            "The start date of the event should be less than or equal to the end date.",
            () => {
                const { starts_at, ends_at } = form
                const dateStart = moment(starts_at)
                const dateEnd = moment(ends_at)

                return dateStart <= dateEnd
            }
        ),
        title: Yup.string()
            .test("required", "Title is required", value => {
                const {
                    calendarType: { type }
                } = form

                if (type === TYPE_MY_CALENDAR) {
                    return value && value.length
                }

                return true
            })
            .test("max-length", "Title may not be greater than 250 characters", value => {
                const {
                    calendarType: { type }
                } = form

                if (type === TYPE_MY_CALENDAR) {
                    return value && value.length <= 250
                }

                return true
            }),
        time: Yup.string().test(
            "required",
            "The start date of the event should be less than or equal to the end date.",
            () => {
                const { is_all_day, starts_at, starts_at_time, ends_at, ends_at_time } = form

                if (is_all_day) {
                    return true
                }

                const dateStart = moment(`${starts_at} ${starts_at_time}`, "YYYY-MM-DD h:mm a")
                const dateEnd = moment(`${ends_at} ${ends_at_time}`, "YYYY-MM-DD h:mm a")
                const diff = dateEnd.diff(dateStart, "minutes")

                return diff >= 0
            }
        ),
        description: Yup.string().test(
            "required",
            "You have reached your maximum limit of characters allowed.",
            value => !value || (value && value.length <= 5000)
        )
    })
})

class AddEditEventModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
            dropdown: false,
            clients: {
                isLoading: false,
                data: [],
                pagination: { current_page: 0, last_page: 1 }
            },
            isSubmitting: false
        }

        this.formikRef = React.createRef()
    }

    componentDidMount() {
        const { getUsersList, entryDetails } = this.props
        getUsersList()

        if (entryDetails) {
            this.setState({
                entry: {
                    ...this.state.entry,
                    calendarType: entryDetails.event.extendedProps.entry.calendar
                }
            })
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.newClient || {}).id !== (prevProps.newClient || {}).id) {
            this.formikRef.current.setFieldValue("client", {
                value: this.props.newClient,
                label: this.props.newClient.full_name,
                secondLabel: ""
            })
        }
    }

    handleSubmit = (values, formikProps) => {
        this.setState({
            isSubmitting: true
        })

        const {
            handleCreate,
            handleHideModal,
            showErrorNotification,
            allCalendarList,
            entryDetails,
            handleEdit,
            predefinedClient
        } = this.props

        const calendarsToRetrieve = allCalendarList
            .filter(calendar => calendar.is_selected)
            .map(calendar => calendar.id)
        const calendar_id = values.calendarType.id
        const type = values.calendarType.type
        const onlyClientMeeting = !!(predefinedClient && predefinedClient.id)

        const payload = {
            calendar_id,
            ...(![TYPE_CLIENT_MEETING, TYPE_DAYS_OFF, TYPE_SELECTED_CLIENT_MEETING].includes(type) && {
                title: values.title
            }),
            is_all_day: !!values.is_all_day,
            starts_at: moment(
                `${values.starts_at} ${
                    !!values.is_all_day ? "00:00:00" : moment(values.starts_at_time, "h:mm a").format("HH:mm:ss")
                }`
            ).format("YYYY-MM-DD HH:mm:ss"),
            ends_at: moment(
                `${values.ends_at} ${
                    !!values.is_all_day ? "00:00:00" : moment(values.ends_at_time, "h:mm a").format("HH:mm:ss")
                }`
            ).format("YYYY-MM-DD HH:mm:ss"),
            ...(!entryDetails && { type }),
            description: values.description,
            ...(type === TYPE_CLIENT_MEETING && values.client && { client_id: values.client.value.id }),
            ...((type === TYPE_CLIENT_MEETING || onlyClientMeeting) &&
                values.created_by && { created_by: values.created_by.id }),
            ...(predefinedClient && { client_id: predefinedClient.id }),
            ...(predefinedClient && !entryDetails && { type: "with-client" }),
            shares: values.shares.filter(user => user.id).map(user => ({ id: user.id }))
        }

        if (entryDetails) {
            const entryId = entryDetails.event.extendedProps.entry.id

            handleEdit(entryId, payload, calendarsToRetrieve)
                .then(data => {
                    if (data && data.payload && data.payload.error) {
                        showErrorNotification(data.payload.error.message)
                        this.setState({
                            isSubmitting: false
                        })
                    } else {
                        handleHideModal({ success: true })
                    }
                })
                .catch(data => {
                    if (data && data.errors) {
                        formikProps.setErrors(data.errors)
                    }

                    this.setState({
                        isSubmitting: false
                    })
                })
        } else {
            handleCreate(payload, calendarsToRetrieve)
                .then(data => {
                    if (data && data.payload && data.payload.error) {
                        showErrorNotification(data.payload.error.message)
                        this.setState({
                            isSubmitting: false
                        })
                    } else {
                        handleHideModal({ success: true })
                    }
                })
                .catch(data => {
                    if (data && data.errors) {
                        formikProps.setErrors(data.errors)
                    }

                    this.setState({
                        isSubmitting: false
                    })
                })
        }
    }

    handleDropDown = () => {
        const { dropdown } = this.state
        const { entryDetails } = this.props

        if (entryDetails) {
            return
        }

        if (!dropdown) {
            document.addEventListener("click", this.handleOutsideClick, false)
        } else {
            document.removeEventListener("click", this.handleOutsideClick, false)
        }

        this.setState(prevState => {
            const { dropdown } = prevState
            return {
                dropdown: !dropdown
            }
        })
    }

    handleOutsideClick = () => {
        this.handleDropDown()
    }

    fetchClients = (query, page) => {
        if (this.state.clients.isLoading) {
            return
        }

        const formatData = data => {
            return data.map(item => ({ value: item, label: displayUserName(item), secondLabel: "" }))
        }

        this.setState(state => ({
            clients: {
                ...state.clients,
                isLoading: true
            }
        }))

        fetch
            .get(`/clients?query=${query || ""}&page=${page}&length=15`)
            .then(data =>
                this.setState(state => ({
                    clients: {
                        data: page === 1 ? formatData(data.data) : [...state.clients.data, ...formatData(data.data)],
                        pagination: { ...data.meta, current_page: page },
                        isLoading: true
                    }
                }))
            )
            .finally(() =>
                this.setState(state => ({
                    clients: {
                        ...state.clients,
                        isLoading: false
                    }
                }))
            )
    }

    render() {
        const {
            cx,
            title,
            users,
            availableCalendarList,
            handleHideModal,
            handleAddClientModal,
            entryDetails,
            defaultDate,
            predefinedClient,
            newClient,
            currentUser
        } = this.props

        const { dropdown } = this.state

        let parsedEntry = null
        const format = "h:mm a"
        const timepickerDefaultValueStart = moment()
            .hours(0)
            .minutes(0)
        const timepickerDefaultValueEnd = moment()
            .hours(0)
            .minutes(30)

        const parsedUsers = (users || []).map(user => ({
            label: `${user.first_name} ${user.last_name}`,
            name: user.id
        }))

        if (entryDetails) {
            const entry = entryDetails.event.extendedProps.entry

            parsedEntry = {
                calendarType: entry.calendar,
                title: entry.title,
                shares: entry.shares.map(obj => ({ ...obj, label: obj.full_name })),
                is_all_day: !!entry.is_all_day,
                starts_at: moment(entry.starts_at).format("YYYY-MM-DD"),
                ends_at: moment(entry.ends_at).format("YYYY-MM-DD"),
                view_starts_at_time: moment(entry.starts_at),
                starts_at_time: moment(entry.starts_at).format("h:mm a"),
                view_ends_at_time: moment(entry.ends_at),
                ends_at_time: moment(entry.ends_at).format("h:mm a"),
                description: entry.description || "",
                ...(entry.object_type === EVENT_TYPES.CLIENT_MEETING && {
                    client: {
                        value: entry.client,
                        label: displayUserName(entry.client),
                        secondLabel: ""
                    }
                }),
                created_by: entry.created_by
            }
        }

        const prepareInitialValues = () => {
            const privateCalendar = availableCalendarList.find(
                item => !item.is_public && item.title === (item.created_by || {}).full_name
            )

            return {
                calendarType: predefinedClient
                    ? { type: TYPE_SELECTED_CLIENT_MEETING, is_public: true }
                    : parsedEntry
                    ? parsedEntry.calendarType
                    : privateCalendar || availableCalendarList[0],
                client: newClient
                    ? { value: newClient, label: newClient.full_name, secondLabel: "" }
                    : parsedEntry
                    ? parsedEntry.client
                    : undefined,
                title: parsedEntry ? parsedEntry.title : "",
                starts_at: parsedEntry
                    ? parsedEntry.starts_at
                    : defaultDate && defaultDate.starts_at
                    ? moment(defaultDate.starts_at).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD"),
                starts_at_time: parsedEntry
                    ? parsedEntry.starts_at_time
                    : defaultDate && defaultDate.starts_at
                    ? moment(defaultDate.starts_at).format("h:mm a")
                    : moment()
                          .hours(0)
                          .minutes(0)
                          .format("h:mm a"),
                view_starts_at_time: parsedEntry ? parsedEntry.view_starts_at_time : undefined,
                ends_at: parsedEntry
                    ? parsedEntry.ends_at
                    : defaultDate && defaultDate.ends_at
                    ? moment(defaultDate.ends_at).format("YYYY-MM-DD")
                    : moment().format("YYYY-MM-DD"),
                ends_at_time: parsedEntry
                    ? parsedEntry.is_all_day
                        ? moment(parsedEntry.ends_at_time, "h:mm a")
                              .add(30, "minutes")
                              .format("h:mm a")
                        : parsedEntry.ends_at_time
                    : defaultDate && defaultDate.ends_at
                    ? moment(defaultDate.ends_at)
                          .add(30, "minutes")
                          .format("h:mm a")
                    : moment()
                          .hours(0)
                          .minutes(30)
                          .format("h:mm a"),
                view_ends_at_time: parsedEntry ? parsedEntry.view_ends_at_time : undefined,
                is_all_day: parsedEntry ? parsedEntry.is_all_day : defaultDate ? defaultDate.allDay : false,
                description: parsedEntry ? parsedEntry.description : "",
                created_by: parsedEntry
                    ? parsedEntry.created_by
                    : { id: currentUser.userId, full_name: currentUser.userName },
                shares: parsedEntry && parsedEntry.shares.length ? parsedEntry.shares : [{}]
            }
        }

        const initialValues = prepareInitialValues()

        const isOpen = dropdown ? "open" : ""
        const onlyClientMeeting = !!(predefinedClient && predefinedClient.id)

        return (
            <Modal>
                <div className={cx("modal-background")}>
                    <div className={cx("modal-cover")}>
                        <img
                            alt="close"
                            className={cx("close-icon")}
                            onClick={() => {
                                handleHideModal()
                            }}
                            src={close}
                        />

                        <Formik
                            initialValues={initialValues}
                            onSubmit={this.handleSubmit}
                            validationSchema={schema}
                            validateOnChange={false}
                            validateOnBlur={false}
                        >
                            {({
                                handleSubmit,
                                values,
                                errors,
                                setFieldValue,
                                touched,
                                setFieldTouched,
                                setFieldError
                            }) => {
                                this.formikRef.current = { setFieldValue }
                                const dateTimeError = errors.date || errors.time
                                const isAllDay = values.is_all_day ? "all-day" : ""

                                const canAddEmployee = !values.shares.some(obj => !obj || !obj.label)

                                const dropdownMenu = field => {
                                    return availableCalendarList.map((item, key) => (
                                        <li key={key} onClick={() => handleChangeCalendar(item, field)}>
                                            {item.title}
                                        </li>
                                    ))
                                }

                                const handleChangeCalendar = (item, field) => setFieldValue(field.name, item)

                                return (
                                    <form className={cx("form")} onSubmit={handleSubmit}>
                                        <div className={cx("title-content", entryDetails && cx("disabled"))}>
                                            <p className={cx("title")}>{title}</p>

                                            {!onlyClientMeeting && (
                                                <div className={cx("dropdown")}>
                                                    <Field name="calendarType">
                                                        {({ field }) => (
                                                            <Fragment>
                                                                <input
                                                                    className={cx("dropdown-input")}
                                                                    onChange={() => null}
                                                                    type="text"
                                                                    value={values.calendarType}
                                                                />

                                                                <p onClick={this.handleDropDown}>
                                                                    {values.calendarType.title}{" "}
                                                                    {!entryDetails && <img src={arrow} alt={"arrow"} />}
                                                                </p>
                                                                <ul className={cx(isOpen)}>{dropdownMenu(field)}</ul>
                                                            </Fragment>
                                                        )}
                                                    </Field>
                                                </div>
                                            )}
                                        </div>
                                        {values.calendarType.type !== TYPE_DAYS_OFF && !onlyClientMeeting && (
                                            <Fragment>
                                                <div
                                                    className={cx(
                                                        "input-field",
                                                        "input-field--dropdown",
                                                        "input-field--full-width",
                                                        "select-client",
                                                        "mrg-b-0"
                                                    )}
                                                >
                                                    {values.calendarType.type === TYPE_CLIENT_MEETING ? (
                                                        <Field name={"client"}>
                                                            {({ field }) => (
                                                                <SelectWithSearch
                                                                    name={field.name}
                                                                    value={values.client ? values.client.label : null}
                                                                    values={this.state.clients.data}
                                                                    pagination={this.state.clients.pagination}
                                                                    setValue={value => {
                                                                        setFieldValue(
                                                                            field.name,
                                                                            value.label ? value : undefined
                                                                        )
                                                                        setFieldError(field.name, null)
                                                                    }}
                                                                    placeholder={"- Choose a client -"}
                                                                    fetchValues={this.fetchClients}
                                                                    additionalButtonLabel={"Create new client"}
                                                                    additionalButtonAction={handleAddClientModal}
                                                                    additionalButtonIcon={addIcon}
                                                                    error={errors.client}
                                                                />
                                                            )}
                                                        </Field>
                                                    ) : (
                                                        <Field name="title">
                                                            {({ field }) => (
                                                                <Input
                                                                    className={"input"}
                                                                    customStyles={styles}
                                                                    error={touched.title && errors.title}
                                                                    isPlaceholder={true}
                                                                    label="Write an event name..."
                                                                    onChange={value => {
                                                                        setFieldTouched("title")
                                                                        setFieldValue(
                                                                            field.name,
                                                                            value.event.target.value
                                                                        )
                                                                    }}
                                                                    value={values.title}
                                                                />
                                                            )}
                                                        </Field>
                                                    )}
                                                </div>

                                                <div className={cx("separator")} />
                                            </Fragment>
                                        )}

                                        <div className={cx("date-range", isAllDay)}>
                                            <div className={cx("start-date")}>
                                                <span className={cx("label")}>Start date:</span>
                                                <Field name="starts_at">
                                                    {({ field }) => (
                                                        <DatePickerWithInput
                                                            date={values.starts_at}
                                                            leftIcon={true}
                                                            handleSelectDate={value => {
                                                                setFieldValue(field.name, value)
                                                                setFieldValue("ends_at", value)
                                                            }}
                                                            customStyles={datepickerStyles}
                                                            minDate={moment()
                                                                .subtract(5, "years")
                                                                .toDate()}
                                                            maxDate={moment()
                                                                .add(5, "years")
                                                                .toDate()}
                                                            disableClear
                                                        />
                                                    )}
                                                </Field>
                                                {!isAllDay && (
                                                    <Field name="starts_at_time">
                                                        {({ field }) => (
                                                            <TimePicker
                                                                onChange={value => {
                                                                    if (value) {
                                                                        setFieldValue(field.name, value)
                                                                        setFieldValue(
                                                                            "ends_at_time",
                                                                            moment(value, "h:mm a")
                                                                                .add(30, "minutes")
                                                                                .format(format)
                                                                        )
                                                                    }
                                                                }}
                                                                value={
                                                                    field.value ||
                                                                    (parsedEntry
                                                                        ? parsedEntry.view_starts_at_time
                                                                        : timepickerDefaultValueStart)
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                            </div>
                                            <div className={cx("end-date")}>
                                                <span className={cx("label")}>End date:</span>
                                                <Field name="ends_at">
                                                    {({ field }) => (
                                                        <DatePickerWithInput
                                                            date={values.ends_at}
                                                            leftIcon={true}
                                                            handleSelectDate={value => setFieldValue(field.name, value)}
                                                            minDate={moment()
                                                                .subtract(5, "years")
                                                                .toDate()}
                                                            maxDate={moment()
                                                                .add(5, "years")
                                                                .toDate()}
                                                            customStyles={datepickerStyles}
                                                            disableClear
                                                        />
                                                    )}
                                                </Field>
                                                {!isAllDay && (
                                                    <Field name="ends_at_time">
                                                        {({ field }) => (
                                                            <TimePicker
                                                                onChange={value =>
                                                                    value && setFieldValue(field.name, value)
                                                                }
                                                                value={
                                                                    field.value ||
                                                                    (parsedEntry
                                                                        ? parsedEntry.view_ends_at_time
                                                                        : timepickerDefaultValueEnd)
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                )}
                                            </div>

                                            <div className={cx("input-field", "input-field--checkbox")}>
                                                <Field name="is_all_day">
                                                    {({ field }) => (
                                                        <Checkbox
                                                            checked={values.is_all_day}
                                                            onChange={() =>
                                                                setFieldValue(field.name, !values.is_all_day)
                                                            }
                                                            name="All day"
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            {dateTimeError && <h2 className={cx("error-message")}>{dateTimeError}</h2>}

                                            <div className={cx("input-field", "textarea-field")}>
                                                <span className={cx("label")}>Description:</span>
                                                <Field name="description">
                                                    {({ field }) => (
                                                        <Textarea
                                                            type={"text"}
                                                            isPlaceholder={true}
                                                            label={"Click here to add a short summary..."}
                                                            className={"textarea-event"}
                                                            customStyles={styles}
                                                            error={errors.description}
                                                            value={values.description}
                                                            onChange={value =>
                                                                setFieldValue(field.name, value.event.target.value)
                                                            }
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>

                                        {(values.calendarType.type === TYPE_CLIENT_MEETING || onlyClientMeeting) && (
                                            <div
                                                className={cx(
                                                    "input-field",
                                                    "input-field--dropdown",
                                                    "input-field--full-width",
                                                    "input-assign-to"
                                                )}
                                            >
                                                <span className={cx("label")}>Assign to:</span>
                                                <Field name="created_by">
                                                    {({ field }) => (
                                                        <SelectUser
                                                            name={field.name}
                                                            multiselect={false}
                                                            value={field.value ? field.value.full_name : ""}
                                                            handleSelect={({ target: { value } }) =>
                                                                setFieldValue(field.name, value.value)
                                                            }
                                                            customStyles={styles}
                                                            selectWithSearchProps={{
                                                                isClearBlocked: true
                                                            }}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        )}

                                        {!values.calendarType.is_public && (
                                            <Fragment>
                                                <div className={cx("separator")} />

                                                <div
                                                    className={cx(
                                                        "input-field",
                                                        "input-field--dropdown",
                                                        "input-field--full-width"
                                                    )}
                                                >
                                                    <span className={cx("input-field-title")}>Share event:</span>
                                                    {values.shares.map((user, key) => (
                                                        <Field key={key} name={`shares.${key}`}>
                                                            {({ field }) => (
                                                                <SelectWithSearch
                                                                    placeholder={"- Choose an employee -"}
                                                                    customStyles={styles}
                                                                    values={parsedUsers.filter(
                                                                        user =>
                                                                            !values.shares.some(
                                                                                share =>
                                                                                    share.name === user.name ||
                                                                                    share.id === user.name
                                                                            )
                                                                    )}
                                                                    value={
                                                                        values.shares[key]
                                                                            ? values.shares[key].label
                                                                            : null
                                                                    }
                                                                    setValue={value =>
                                                                        setFieldValue(field.name, {
                                                                            label: value.label,
                                                                            id: value.name
                                                                        })
                                                                    }
                                                                    handleClear={() =>
                                                                        setFieldValue(
                                                                            "shares",
                                                                            values.shares.length > 1
                                                                                ? values.shares.filter(
                                                                                      share => share.id !== user.id
                                                                                  )
                                                                                : [{}]
                                                                        )
                                                                    }
                                                                    isListOnTop={true}
                                                                    withoutFetch
                                                                />
                                                            )}
                                                        </Field>
                                                    ))}
                                                    {errors.shares && <ErrorLabel error={errors.shares} />}
                                                </div>
                                            </Fragment>
                                        )}

                                        <div className={cx("footer")}>
                                            {values.calendarType.is_public ? (
                                                <button
                                                    type="button"
                                                    className={cx("button__link")}
                                                    onClick={() => handleHideModal()}
                                                >
                                                    Cancel
                                                </button>
                                            ) : (
                                                <button
                                                    className={cx(["button", "add-moderator-button"])}
                                                    disabled={!canAddEmployee}
                                                    onClick={() => setFieldValue("shares", [...values.shares, {}])}
                                                    type="button"
                                                >
                                                    <img src={addModeratorIcon} alt={""} />
                                                    Add employee
                                                </button>
                                            )}

                                            <Button
                                                type="submit"
                                                isLoading={this.state.isSubmitting}
                                                isDisabled={this.state.isSubmitting}
                                                className={cx(["first-button", "button", "create-button"])}
                                                label={entryDetails ? "Save" : "Create"}
                                            />
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withStyles(AddEditEventModal, styles)
