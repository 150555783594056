import React, { useMemo } from "react"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import { Checkbox } from "ui"

import styles from "./styles.css"
import checkboxStyles from "../../overrides/checkbox.css"

const RolesSection = ({ cx, form, roles }) => {
    const superAdminRole = useMemo(() => (roles || []).filter(item => item.name === "SuperAdmin")[0], [roles])

    const renderCheckboxes = items =>
        items.map(item => (
            <li key={item.id}>
                <Checkbox
                    id={item.id}
                    name={item.name}
                    checked={form.values.roles.some(roleID => roleID === item.id || roleID === superAdminRole.id)}
                    onChange={() =>
                        form.values.roles.includes(item.id)
                            ? form.setFieldValue(
                                  "roles",
                                  item.id === superAdminRole.id
                                      ? roles.map(role => role.id).filter(roleID => roleID !== superAdminRole.id)
                                      : form.values.roles.filter(role => role !== item.id)
                              )
                            : form.setFieldValue(
                                  "roles",
                                  !hasSuperAdmin && item.id === superAdminRole.id
                                      ? [superAdminRole.id]
                                      : [...form.values.roles, item.id]
                              )
                    }
                    customStyles={checkboxStyles}
                    disabled={hasSuperAdmin && item.id !== superAdminRole.id}
                />
            </li>
        ))

    const hasSuperAdmin = superAdminRole && form.values.roles.includes(superAdminRole.id)

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <div className={cx("header")}>Roles</div>

                <ul className={cx("checkboxes")}>{superAdminRole && renderCheckboxes([superAdminRole])}</ul>
            </div>

            <div className={cx("section")}>
                <ul className={cx("checkboxes", { disabled: hasSuperAdmin })}>
                    {roles && renderCheckboxes(roles.filter(item => item.id !== superAdminRole.id))}
                </ul>
            </div>
        </div>
    )
}

RolesSection.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    states: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired
}

const mapStateToProps = state => {
    return {
        states: state.clientStore.statesList,
        countries: state.clientStore.countriesList
    }
}

export default compose(
    connect(
        mapStateToProps,
        null
    )
)(withStyles(RolesSection, styles))
