import * as fetchStatus from "helpers/fetchStatus"
import * as constants from "constants/index"

const initialState = {
    data: {},
    fetchStatus: fetchStatus.initialState()
}

function sendable(state = initialState, action) {
    switch (action.type) {
        case constants.CREATE_SENDABLE_START:
            return {
                ...state,
                fetchStatus: fetchStatus.requestState()
            }
        case constants.CREATE_SENDABLE_ERROR:
            return {
                data: {},
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }
        case constants.CREATE_SENDABLE_SUCCESS:
            return {
                data: action.payload.data.data,
                fetchStatus: fetchStatus.successState()
            }
        default:
            return state
    }
}

export default sendable
