import * as constants from "constants/index"

const initialState = { data: [], meta: {}, isLoading: false, isLoaded: false, isError: false }

export default (state = initialState, action) => {
    if (constants.GET_COMMENTS_START === action.type) {
        return { data: [], meta: {}, isLoading: true, isLoaded: false, isError: false }
    }

    if (constants.GET_COMMENTS_SUCCESS === action.type) {
        return {
            data: action.payload.data.data,
            meta: action.payload.data.meta,
            isLoading: false,
            isLoaded: true,
            isError: false
        }
    }

    if (constants.GET_COMMENTS_ERROR === action.type) {
        return { data: [], meta: {}, isLoading: false, isLoaded: false, isError: true }
    }

    if (constants.RESET_COMMENTS === action.type) {
        return { data: [], meta: {}, isLoading: false, isLoaded: false, isError: false }
    }

    return state
}
