import React from "react"
import { Field } from "formik"
import withStyles from "HOC/withStyles"
import { countSelectedFilters } from "helpers/form"

import { SelectWithSearch, Button, DateRangePicker, Toggle } from "ui"
import { MoreFilters } from "ui/FilterableTable"

import styles from "./styles.css"
import selectStyles from "./overrides/selectStyles.css"
import cancelButtonStyles from "./overrides/cancelButton.css"
import submitButtonStyles from "./overrides/submitButton.css"
import dateRangePickerStyles from "./overrides/dateRangePicker.css"
import moreFiltersStyles from "./overrides/moreFilters.css"
import toggleStyles from "./overrides/toggle.css"
import { SelectClient, SelectShowroom, SelectUser, SelectBrand } from "ui/Filters"

const CONSIGNMENT_OPTIONS = [
    { label: "True", secondLabel: "", value: "true" },
    { label: "False", secondLabel: "", value: "false" }
]

const Filters = props => {
    const { cx, values, handleAction, isVisible, handleOnlyMyProjects } = props
    const { isOnlyMyProjects, ...restValues } = values

    const getLabelById = (id, values) => {
        const value = values.find(value => value.value === id)

        return value ? value.label : ""
    }

    return (
        <MoreFilters
            numberOfSelectedFilters={countSelectedFilters(restValues)}
            isVisible={isVisible}
            toggleVisible={() => handleAction("unsave")}
            customStyles={moreFiltersStyles}
        >
            <div className={cx("root")}>
                <div className={cx("filters")}>
                    <div className={cx("column")}>
                        <Field className={cx("field")} name="users">
                            {({ field: { name, value, onChange } }) => (
                                <div className={cx("assignedTo")}>
                                    <Toggle
                                        isOn={isOnlyMyProjects}
                                        label={{
                                            on: "Only my projects",
                                            off: "Only my projects"
                                        }}
                                        labelPosition="left"
                                        handleChange={state => handleOnlyMyProjects(state, true)}
                                        customStyles={toggleStyles}
                                    />
                                    <SelectUser
                                        customStyles={selectStyles}
                                        handleSelect={event => {
                                            handleOnlyMyProjects(false, false)
                                            onChange(event)
                                        }}
                                        label="Assigned to"
                                        name={name}
                                        value={value}
                                    />
                                </div>
                            )}
                        </Field>

                        <Field name="deadline">
                            {({ field: { name, value, onChange } }) => (
                                <DateRangePicker
                                    title="- Please select -"
                                    label="Deadline"
                                    ranges={value || { startDate: "", endDate: "" }}
                                    resetRanges={() => onChange({ target: { name, value: "" } })}
                                    onChange={({ range1 }) => onChange({ target: { name, value: range1 } })}
                                    customStyles={dateRangePickerStyles}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={cx("column")}>
                        <Field name="showrooms">
                            {({ field: { name, value, onChange } }) => (
                                <SelectShowroom
                                    customStyles={selectStyles}
                                    label="Division"
                                    name={name}
                                    value={value}
                                    handleSelect={event => onChange(event)}
                                />
                            )}
                        </Field>

                        <Field name="installation">
                            {({ field: { name, value, onChange } }) => (
                                <DateRangePicker
                                    title="- Please select -"
                                    label="Installation date"
                                    ranges={value || { startDate: "", endDate: "" }}
                                    resetRanges={() => onChange({ target: { name, value: "" } })}
                                    onChange={({ range1 }) => onChange({ target: { name, value: range1 } })}
                                    customStyles={dateRangePickerStyles}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={cx("column")}>
                        <Field name="client">
                            {({ field, form }) => (
                                <SelectClient
                                    customStyles={selectStyles}
                                    form={form}
                                    field={field}
                                    label="Client"
                                    name={field.name}
                                    value={field.value}
                                    handleSelect={value => field.onChange({ target: { name: field.name, value } })}
                                />
                            )}
                        </Field>

                        <Field name="consignment">
                            {({ field: { name, value, onChange } }) => (
                                <SelectWithSearch
                                    label="Consignment"
                                    name={name}
                                    value={getLabelById(value, CONSIGNMENT_OPTIONS)}
                                    values={CONSIGNMENT_OPTIONS}
                                    setValue={({ value }) => onChange({ target: { name, value } })}
                                    placeholder="- Please select -"
                                    withoutFetch
                                    customStyles={selectStyles}
                                />
                            )}
                        </Field>
                    </div>
                    <div className={cx("column")}>
                        <Field name="brands">
                            {({ field: { name, value, onChange } }) => (
                                <SelectBrand
                                    label="Brand"
                                    name={name}
                                    value={(value || {}).label}
                                    handleSelect={event => onChange(event)}
                                    customStyles={selectStyles}
                                />
                            )}
                        </Field>
                    </div>
                </div>
                <div className={cx("actions")}>
                    <Button
                        label="Clear all filters"
                        onClick={() => handleAction("clear")}
                        customStyles={cancelButtonStyles}
                    />
                    <Button
                        label="Show results"
                        onClick={() => handleAction("submit")}
                        className="first-button"
                        customStyles={submitButtonStyles}
                    />
                </div>
            </div>
        </MoreFilters>
    )
}

export default withStyles(Filters, styles)
