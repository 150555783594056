import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./sort.module.css"

class Sort extends Component {
    render() {
        const { cx, sortKeys, currentSorting, setSorting } = this.props

        const handleSetSorting = type => {
            if (!type) {
                return null
            }

            const directions = ["asc", "desc"]

            const by = type
            const direction = type === currentSorting.by ? directions.find(d => d !== currentSorting.direction) : "asc"
            setSorting({ by, direction })
        }

        return (
            <nav className={cx("sort")}>
                <ul className={cx("likeTable")}>
                    {sortKeys.map(el => {
                        const { type, title } = el
                        const asc = currentSorting.by === type && currentSorting.direction === "asc"
                        const desc = currentSorting.by === type && currentSorting.direction === "desc"

                        return (
                            <li
                                key={title}
                                onClick={() => handleSetSorting(type)}
                                className={cx({ hasSort: type, asc, desc })}
                            >
                                <span>{title}</span>
                                <div className={cx("icons")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5">
                                        <path
                                            className="svg-icon-sort-asc"
                                            fillRule="evenodd"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth=".64"
                                            d="M3.5 0L6 4H1z"
                                        />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5">
                                        <path
                                            className="svg-icon-sort-desc"
                                            fillRule="evenodd"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth=".64"
                                            d="M3.5 5L6 1H1z"
                                        />
                                    </svg>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </nav>
        )
    }
}

export default withStyles(Sort, styles)
