import React, { useState } from "react"
import SaveBar from "ui/SaveBar"
import ListingLayout from "../MainPageListingLayout"
import Header from "../../components/Header"
import Listing from "../../components/MainPageListings"
import Modal from "components/Modal/Modal"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"

import SectionNameEditModal from "../../components/MainPageNameSectionEditModal"
import AddPostModal from "../../components/MainPageAddPostModal"
import EditPostModal from "../../components/MainPageEditPostModal"

const MainPageNews = props => {
    const {
        mainPages: { data, isLoading },
        modifiedData,
        isModified,
        handleUpdate,
        handleSubmit,
        handleClearChanges,
        isSaving,
        getPost,
        post,
        handlePostUpdate,
        handlePostCreate,
        showErrorNotification,
        handleDeleteAllFromList
    } = props

    const [sectionNameEditModalState, setSectionNameEditModalState] = useState(false)
    const [addPostModalState, setAddPostModalState] = useState(false)
    const [editPostModalState, setEditPostModalState] = useState(false)
    const [editingPost, setEditingPost] = useState(null)
    const [isDeletePostOpen, setIsDeletePostOpen] = useState(null)
    const [isDeleteAllPostsOpen, setIsDeleteAllPostsOpen] = useState(false)
    const [deletingPost, setDeletingPost] = useState(null)

    const listMapping = {
        id: "id",
        source_id: "post_id",
        name: "title",
        image: "cover_desktop",
        active: "active"
    }

    const displayTitle = () =>
        (data.settings &&
            data.settings.sections &&
            data.settings.sections.posts &&
            data.settings.sections.posts.label) ||
        "News"

    const handleToggle = itemId => {
        const list = isModified ? modifiedData : data
        handleUpdate({
            ...list,
            posts: list.posts.map(item => ({
                ...item,
                active: item.id === itemId ? !item.active : item.active
            }))
        })
    }

    const handleReorder = posts => {
        handleUpdate({ ...data, posts })
    }

    const handleSectionNameEditClose = () => {
        setSectionNameEditModalState(false)
    }

    const handleSectionNameEditOpen = () => {
        setSectionNameEditModalState(true)
    }

    const handleSectionNameEdit = data => {
        handleSubmit(data, handleSectionNameEditClose)
    }

    const handleAddPostClose = () => {
        setAddPostModalState(false)
    }

    const handleAddPostOpen = () => {
        setAddPostModalState(true)
    }

    const handleAddPost = data => {
        handlePostCreate(data, handleAddPostClose)
    }

    const handleEditPostClose = () => {
        setEditingPost(null)
        setEditPostModalState(false)
    }

    const handleEditPostOpen = postId => {
        setEditingPost(postId)
        setEditPostModalState(true)
    }

    const handleEditPost = (id, data, onSuccess = null, onError) => {
        handlePostUpdate(id, data, handleEditPostClose, onError)
    }

    const handleDeletePostClose = () => {
        setIsDeletePostOpen(false)
        setDeletingPost(null)
    }

    const handleDeletePostOpen = news => {
        setIsDeletePostOpen(true)
        setDeletingPost(news)
    }

    const handleDeletePost = () => {
        const { handlePostDelete } = props
        handlePostDelete(deletingPost.id, handleDeletePostClose)
    }

    const handleDeleteAllPostsClose = () => {
        setIsDeleteAllPostsOpen(false)
    }

    const handleDeleteAllPostsOpen = () => {
        setIsDeleteAllPostsOpen(true)
    }

    const handleDeleteAllPosts = () => {
        handleDeleteAllFromList({ settings: data.settings, header: data.header, posts: [] }, handleDeleteAllPostsClose)
    }

    return (
        <ListingLayout>
            <Header
                title={displayTitle()}
                editButton={{
                    label: "Edit",
                    handler: handleSectionNameEditOpen,
                    isDisabled: false,
                    order: 1
                }}
                addButton={{
                    label: "Add News",
                    handler: handleAddPostOpen,
                    isDisabled: false,
                    order: 2
                }}
            />

            <Listing
                isLoading={isLoading}
                handleDelete={handleDeletePostOpen}
                handleDeleteAll={handleDeleteAllPostsOpen}
                handleEdit={id => handleEditPostOpen(id)}
                handleToggle={handleToggle}
                listMapping={listMapping}
                listingType="posts"
                handleReorder={handleReorder}
                list={modifiedData ? modifiedData.posts : data.posts || []}
                customToggleLabel={{
                    on: "active",
                    off: "inactive"
                }}
            />

            <SectionNameEditModal
                isSaving={isSaving}
                data={modifiedData || data}
                sectionName="posts"
                defaultValues={{ label: "News" }}
                handleClose={handleSectionNameEditClose}
                onSubmit={handleSectionNameEdit}
                isOpen={sectionNameEditModalState}
            />

            <AddPostModal
                data={modifiedData || data}
                handleClose={handleAddPostClose}
                onSubmit={handleAddPost}
                isOpen={addPostModalState}
            />

            <EditPostModal
                data={post}
                postId={editingPost}
                getPost={getPost}
                handleClose={handleEditPostClose}
                handleError={showErrorNotification}
                onSubmit={handleEditPost}
                isOpen={editPostModalState}
            />

            {isDeletePostOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete this news?"
                        description="Are you sure you want to delete the news"
                        subject={deletingPost.title}
                        descriptionClosure="? This action cannot be reversed."
                        handleHideModal={handleDeletePostClose}
                        handleDelete={handleDeletePost}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            {isDeleteAllPostsOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete all news?"
                        description="Are you sure you want to delete all of the news?"
                        handleHideModal={handleDeleteAllPostsClose}
                        handleDelete={handleDeleteAllPosts}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            <SaveBar
                message="Unpublished changes"
                submitLabel="Publish"
                isShown={isModified}
                isSaving={isSaving}
                onSubmit={handleSubmit}
                onCancel={handleClearChanges}
            />
        </ListingLayout>
    )
}

export default MainPageNews
