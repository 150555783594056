import React, { Component } from "react"
import ChangeableListingView from "./ChangeableListingView"

class ChangeableListingViewContainer extends Component {
    render() {
        const { listingActiveStyle, handleSetListingStyle } = this.props
        return (
            <ChangeableListingView
                {...this.props}
                listingActiveStyle={listingActiveStyle}
                handleSetListingStyle={handleSetListingStyle}
            />
        )
    }
}

export default ChangeableListingViewContainer
