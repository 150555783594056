import React from "react"
import { useCombinationsCreator } from "hooks"
import Selectable from "./components/Selectable"
import withStyles from "HOC/withStyles"
import styles from "./CombinationsCreator.css"
import { AttributePicker, CombinationSelect, Tree, EditCombinationModal, overrides } from "./components"

import { SelectWithSearch, SubmitModal, SaveBar, Skeleton } from "ui"
const CombinationsCreator = ({
    cx,
    isSubmitting,
    submitCombinationPattern,
    withWarningModal,
    warningModal,
    isTemplate = false
}) => {
    const [
        { group, subgroup, treeLevel, unsavedChanges, editedCombination, resources },
        actions
    ] = useCombinationsCreator()
    const withoutWarningModal = action => payload => action(payload)
    const actionDecorator = treeLevel > 0 ? withWarningModal : withoutWarningModal

    return (
        <div className={cx("root", { paddingBottom: !subgroup.value })}>
            <div className={cx("header")}>
                <h1 className={cx("title")}>Combinations {isTemplate && "Template"}</h1>
                {!isTemplate && (
                    <Selectable
                        isBlocked={isSubmitting}
                        endpoint="/combination-templates"
                        placeholder="Choose template"
                        onChange={actionDecorator(({ value }) => {
                            actions.getInitialData({ type: "templates", id: value, omitId: true })
                            actions.setUnsavedChanges(true)
                        })}
                    />
                )}
            </div>

            {renderGroupSelects()}

            {subgroup.value && (
                <Skeleton fetchStatus={resources.attributes.fetchStatus}>
                    <div className={cx("content")}>
                        <div className={cx("leftPane")}>
                            <CombinationSelect blockInputs={isSubmitting} />
                        </div>

                        <div className={cx("mainContentWrapper")}>
                            <AttributePicker withWarningModal={withWarningModal} blockInputs={isSubmitting} />
                            <Tree isTemplate={isTemplate} blockInputs={isSubmitting} />
                        </div>
                    </div>
                </Skeleton>
            )}

            <SubmitModal
                submitText="Confirm"
                header="Are you sure?"
                body="This action will reset your combination settings."
                {...warningModal}
            />

            {!isTemplate && !!editedCombination && <EditCombinationModal />}

            <SaveBar
                message={unsavedChanges ? "Unsaved changes" : ""}
                isShown
                isDisabled={!unsavedChanges}
                onSubmit={submitCombinationPattern}
                isSaving={isSubmitting}
                submitLabel="Save"
            />
        </div>
    )

    function renderGroupSelects() {
        return (
            <div className={cx("leftPane")}>
                <SelectWithSearch
                    customStyles={overrides.dropdown}
                    placeholder="Choose group"
                    values={resources.groups.data}
                    isDisabled={resources.groups.fetchStatus.isLoading}
                    value={group.label}
                    label="Attribute group"
                    setValue={payload => {
                        if (payload.value !== group.value) {
                            actionDecorator(actions.setGroup)(payload)
                        }
                    }}
                    isBlocked={isSubmitting}
                    isClearBlocked
                    withoutFetch
                />
                <SelectWithSearch
                    customStyles={overrides.dropdown}
                    placeholder="Choose subgroup"
                    values={resources.subgroups.data}
                    isDisabled={!group.value || resources.subgroups.fetchStatus.isLoading}
                    isBlocked={isSubmitting || resources.subgroups.fetchStatus.isLoading}
                    showLoadingIcon={resources.subgroups.fetchStatus.isLoading}
                    value={subgroup.label}
                    label="Attribute subgroup"
                    setValue={payload => {
                        if (payload.value !== subgroup.value) {
                            actionDecorator(actions.setSubgroup)(payload)
                        }
                    }}
                    isClearBlocked
                    withoutFetch
                />
            </div>
        )
    }
}

export default withStyles(CombinationsCreator, styles)
