import * as constants from "./../constants/index"

function chooseSelectionFlow(
    state = {
        shouldStart: false,
        ids: []
    },
    action
) {
    switch (action.type) {
        case constants.START_MODAL_LOGIC:
            return {
                shouldStart: true,
                ids: action.payload.ids
            }

        case constants.END_MODAL_LOGIC:
            return {
                shouldStart: false,
                ids: []
            }

        default:
            return state
    }
}

export default chooseSelectionFlow
