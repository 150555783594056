const TABLE_COLUMNS = {
    NAME: {
        minWidth: 150,
        width: "25%"
    },
    ID: {
        minWidth: 100,
        width: "25%"
    },
    LOCATION: {
        minWidth: 100,
        width: "25%"
    },
    LABEL: {
        minWidth: 100,
        width: "25%",
        compactWidth: "50%"
    }
}

export default TABLE_COLUMNS
