import React from "react"
import PropTypes from "prop-types"

import { DetailsCard, Panel, SnakeLoader } from "@butterfly-frontend/ui"
import SalesOrderReturnDetailsHeader from "modules/OrdersModule/components/SalesOrderReturnDetailsHeader"
import SalesOrderReturnDetailsItemList from "modules/OrdersModule/components/SalesOrderReturnDetailsItemList"
import { useShipmentDetails } from "modules/WmsModule/hooks/api/useShipment"

import styles from "./SalesOrderReturnDetails.module.css"

const SalesOrderReturnDetails = ({ shipmentId, onStatusChanged, isEditingStatusEnabled }) => {
    const { data: shipment, fetchStatus } = useShipmentDetails({ id: shipmentId })

    if (fetchStatus.isLoading) {
        return <SnakeLoader />
    }

    return (
        <div className={styles.container}>
            <SalesOrderReturnDetailsHeader
                shipment={shipment}
                onStatusChanged={onStatusChanged}
                isEditingStatusEnabled={isEditingStatusEnabled}
            />
            <SalesOrderReturnDetailsItemList items={shipment.items} />
            <Panel classes={{ panel: styles.description }}>
                <DetailsCard header="DESCRIPTION">{shipment.description || "-"}</DetailsCard>
            </Panel>
        </div>
    )
}

SalesOrderReturnDetails.propTypes = {
    shipmentId: PropTypes.number.isRequired,
    onStatusChanged: PropTypes.func,
    isEditingStatusEnabled: PropTypes.bool
}

export default SalesOrderReturnDetails
