import { useCallback, useEffect } from "react"

import useNonmutableState from "hooks/useNonmutableState"
import { toApiDate } from "helpers/date"

import { checkIfDateRangeIsValid } from "ui/ChooseProduct/helpers"

const useDateRangePicker = ({ setDateRange, dateRange }) => {
    const [isDatepickerOpen, isDatepickerOpenRef, setIsDatepickerOpen] = useNonmutableState(false)

    const handleChangeFilterDateRange = useCallback(
        data => {
            const nextRange = {
                startDate: toApiDate(data.range.startDate),
                endDate: toApiDate(data.range.endDate)
            }

            setDateRange(nextRange)
        },
        [setDateRange]
    )

    const handleResetFilterDateRange = useCallback(() => {
        setDateRange(null)
    }, [setDateRange])

    useEffect(() => {
        if (checkIfDateRangeIsValid(dateRange)) {
            setIsDatepickerOpen(false)
        }
    }, [dateRange])

    return {
        isDatepickerOpen,
        isDatepickerOpenRef,
        setIsDatepickerOpen,
        handleChangeFilterDateRange,
        handleResetFilterDateRange
    }
}

export default useDateRangePicker
