import React, { Component } from "react"
import { connect } from "react-redux"

import MultiCategories from "./MultiCategories"

class MultiCategoriesContainer extends Component {
    state = {
        selectedCategories: [[]]
    }

    componentDidMount() {
        const nextSelectedCategories = this.props.form.values.categories.map(group =>
            group.map(category => category.id)
        )

        this.setState({
            selectedCategories: nextSelectedCategories
        })
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.form.values.categories !== nextProps.form.values.categories) {
            const nextSelectedCategories = nextProps.form.values.categories.map(group =>
                group.map(category => category.id)
            )

            this.setState({
                selectedCategories: nextSelectedCategories
            })
        }
    }

    render() {
        const { selectedCategories } = this.state
        const { isTablet } = this.props

        return (
            <MultiCategories
                {...this.props}
                selectedCategories={selectedCategories}
                categoriesNumber={selectedCategories.length}
                handleAddCategory={this.handleAddCategory}
                handleRemoveCategory={this.handleRemoveCategory}
                handleSaveCategories={this.handleSaveCategories}
                isTablet={isTablet}
            />
        )
    }

    handleAddCategory = () => {
        const { selectedCategories } = this.state

        this.setState({
            selectedCategories: [...selectedCategories, []]
        })
    }

    handleRemoveCategory = ({ categoryIndex }) => {
        const { form, selectedCategories } = this.state

        const nextCategories = [...selectedCategories].splice(categoryIndex, 1).map(group => group.map(id => ({ id })))

        form.setFieldValue("categories", nextCategories)
    }

    handleSaveCategories = ({ categories, categoryIndex }) => {
        const { form } = this.props
        const { selectedCategories } = this.state

        const newCategories = [...selectedCategories]

        newCategories[categoryIndex] = [...categories]

        const nextCategories = newCategories.map(group => group.map(id => ({ id })))

        form.setFieldValue("categories", nextCategories)
    }
}

const mapStateToProps = state => {
    return {
        categories: state.db.categories.list.data
    }
}

export default connect(mapStateToProps)(MultiCategoriesContainer)
