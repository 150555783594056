import React, { Fragment, useState } from "react"
import ReactSvg from "react-svg"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { formatPrice } from "helpers/units"
import fetch from "helpers/fetch"

import { showErrorNotification, showSuccessNotification } from "actions/notification"

import withStyles from "HOC/withStyles"

import { Image, Modal, Textarea, Button } from "ui"
import { DeleteModal } from "ui/Notes"
import ClientCommunication from "../ClientCommunication"

import styles from "./Product.css"
import stylesTextarea from "./overrides/Textarea.css"
import stylesModal from "./overrides/Modal.css"

import expandIcon from "assets/expand.svg"
import commentIcon from "assets/comment.svg"
import commentDisabledIcon from "assets/comment-gray.svg"
import backIcon from "assets/arrow-dark.svg"

const Product = props => {
    const { cx, product, options, getProject } = props
    const { id, image, fake_name, attributes, notes, quantity, total_price, vendor } = product

    const project = useSelector(state => state.db.projects.current.current.data)

    const [showComments, setShowComments] = useState(false)
    const [commentModalData, setCommentModalData] = useState({ opened: false, noteId: null, note: "" })
    const [deleteModalID, setDeleteModalID] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [draftComments, setDraftComments] = useState({})

    const dispatch = useDispatch()
    const { token } = useParams()

    const renderPrice = price => {
        const formattedPrice = formatPrice(price)

        return formattedPrice.replace(/\.([0-9]*)$/g, cents => `<span>${cents}</span>`)
    }

    const handleSubmitComment = () => {
        setSubmitting(true)

        const { noteId, note } = commentModalData
        const isEdit = !!noteId

        const [fetchMethod, url] = isEdit ? ["patch", `/${noteId}`] : ["post", ""]

        const body = {
            text: note,
            object_type: "projectItem",
            object_id: id,
            token
        }

        fetch[fetchMethod](`/notes${url}`, body)
            .then(() => {
                dispatch(showSuccessNotification())
                dispatch(getProject(project.id))
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                setSubmitting(false)
                closeCommentModal()
            })
    }

    const deleteComment = () => {
        setSubmitting(true)
        fetch
            .deleteRAW(`/notes/${deleteModalID}`)
            .then(() => {
                dispatch(showSuccessNotification())
                dispatch(getProject(project.id))
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                setDeleteModalID(null)
                setSubmitting(false)
            })
    }

    const renderAttributes = () => {
        if (!attributes) {
            return null
        }

        return (
            <div className={cx("attributes")}>
                {Object.entries(attributes).map(([key, value]) => (
                    <span key={key} className={cx("attribute")}>
                        {key}: <span>{value}</span>
                    </span>
                ))}
            </div>
        )
    }

    const showCommentModal = params => {
        setCommentModalData({
            ...params,
            note: draftComments[params.noteId] || params.note
        })
    }

    const closeCommentModal = () => {
        setDraftComments({
            ...draftComments,
            [commentModalData.noteId]: commentModalData.note
        })
        setCommentModalData({ opened: false, noteId: null, note: "" })
    }

    const hideComments = () => {
        setDraftComments({})
        setShowComments(false)
    }

    return (
        <div className={cx("root")}>
            <div className={cx("row")}>
                <div className={cx("column", "left")}>
                    <Image className={cx("image")} src={image} />
                    <div>
                        {(options || {}).vendor_visibility && <p className={cx("vendor")}>{vendor}</p>}
                        <p className={cx("name")}>{fake_name}</p>
                        {renderAttributes()}
                    </div>
                </div>
                <div className={cx("column", "right")}>
                    <div
                        className={cx("commentsCount", {
                            disabled: (notes || []).length === 0
                        })}
                    >
                        <span className={cx("columnTitle")}>Comments</span>
                        <ReactSvg
                            src={(notes || []).length === 0 ? commentDisabledIcon : commentIcon}
                            onClick={() => ((notes || []).length > 0 ? setShowComments(true) : null)}
                        />
                        {(notes || []).length}
                    </div>
                    <div>
                        <span className={cx("columnTitle")}>Qty</span>
                        {quantity}
                    </div>
                    <div>
                        {(options || {}).pricing_visibility && (
                            <Fragment>
                                <span className={cx("columnTitle")}>Price</span>
                                <span
                                    className={cx("totalPrice")}
                                    dangerouslySetInnerHTML={{
                                        __html: renderPrice(total_price)
                                    }}
                                />
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
            <div className={cx("actions")}>
                <button onClick={() => showCommentModal({ opened: true, noteId: null, note: "" })}>
                    <img src={commentIcon} alt="ico" /> Leave a comment
                </button>

                {(notes || []).length > 0 && (
                    <button onClick={() => (showComments ? hideComments() : setShowComments(true))}>
                        <img src={expandIcon} alt="ico" className={cx({ areDetailsShown: showComments })} />{" "}
                        {showComments ? "Less" : "See"} comments
                    </button>
                )}
            </div>

            {showComments && (
                <ClientCommunication
                    projectItemId={id}
                    notes={notes}
                    setDeleteModalID={setDeleteModalID}
                    showCommentModal={showCommentModal}
                    hideComments={hideComments}
                    productName={fake_name}
                />
            )}

            <Modal
                preventClickOutside={false}
                isOpen={commentModalData.opened}
                closeModal={closeCommentModal}
                customStyles={stylesModal}
            >
                <div className={cx("topBar")}>
                    <img src={backIcon} alt="back" className={cx("backIcon")} onClick={closeCommentModal} />
                    <span className={cx("title")}>{fake_name}</span>
                </div>

                <Modal.Header>{!!commentModalData.noteId ? "Edit" : "Leave a"} Comment</Modal.Header>
                <Modal.Body>
                    <Textarea
                        value={commentModalData.note}
                        onChange={({ event }) => setCommentModalData({ ...commentModalData, note: event.target.value })}
                        onKeyDown={event => event.keyCode === 13 && event.ctrlKey && handleSubmitComment()}
                        isPlaceholder
                        label={`Leave your comment...`}
                        customStyles={stylesTextarea}
                    />
                </Modal.Body>
                <Modal.Actions>
                    <button className={cx("closeButton")} onClick={closeCommentModal}>
                        Cancel
                    </button>
                    <Button
                        className="first-button"
                        onClick={handleSubmitComment}
                        label={!!commentModalData.noteId ? "Edit" : "Add"}
                        isLoading={submitting}
                        isDisabled={!commentModalData.note}
                    />
                </Modal.Actions>
            </Modal>

            <DeleteModal
                isOpen={!!deleteModalID}
                isLoading={submitting}
                onClose={() => setDeleteModalID(null)}
                onSubmit={deleteComment}
            />
        </div>
    )
}

export default withStyles(Product, styles)
