import React, { Component } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import withStyles from "HOC/withStyles"
import styles from "./list.module.css"
import InfiniteScroll from "components/InfiniteScroll"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import customSnakeLoaderStyles from "./overrides/snakeloader.module.css"
import { READABLE_STANDARD_DATE } from "constants/index"

class List extends Component {
    render() {
        const { cx, list, currentPage, lastPage, handleLoad, handleModal } = this.props
        return (
            <InfiniteScroll
                loadMore={handleLoad}
                hasMore={currentPage < lastPage}
                loader={<SnakeLoader customStyles={customSnakeLoaderStyles} key={0} />}
                pageStart={1}
                threshold={100}
                useWindow={false}
            >
                <ul className={cx("list")}>
                    {list.map(el => {
                        const { id, first_name, last_name, company, vip, updated_at, created_at, email, phone } = el
                        const profileUrl = `/contact/${id}/profile`

                        return (
                            <li key={id}>
                                <ul className={cx("row", "likeTable")}>
                                    <li>
                                        <Link to={profileUrl}>
                                            {first_name} {last_name}
                                            {vip && <span className={cx("vip")}>VIP</span>}
                                        </Link>
                                    </li>
                                    <li>{company}</li>
                                    <li>{email}</li>
                                    <li>{phone}</li>
                                    <li className={cx("date")}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <g fill="none" fillRule="evenodd">
                                                <path d="M3 3h18v18H3z" />
                                                <path
                                                    className={cx("withFill")}
                                                    fillRule="nonzero"
                                                    d="M11.992 4.5C16.14 4.5 19.5 7.86 19.5 12c0 4.14-3.36 7.5-7.508 7.5-4.14 0-7.492-3.36-7.492-7.5 0-4.14 3.353-7.5 7.492-7.5zM12 18c3.315 0 6-2.685 6-6s-2.685-6-6-6-6 2.685-6 6 2.685 6 6 6zm.375-9.75v3.938l3.375 2.002-.563.923-3.937-2.363v-4.5h1.125z"
                                                />
                                            </g>
                                        </svg>
                                        <span>{moment(created_at).format(READABLE_STANDARD_DATE)}</span>
                                    </li>
                                    <li className={cx("date")}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                            <g fill="none" fillRule="evenodd">
                                                <path d="M3 3h18v18H3z" />
                                                <path
                                                    className={cx("withFill")}
                                                    fillRule="nonzero"
                                                    d="M11.992 4.5C16.14 4.5 19.5 7.86 19.5 12c0 4.14-3.36 7.5-7.508 7.5-4.14 0-7.492-3.36-7.492-7.5 0-4.14 3.353-7.5 7.492-7.5zM12 18c3.315 0 6-2.685 6-6s-2.685-6-6-6-6 2.685-6 6 2.685 6 6 6zm.375-9.75v3.938l3.375 2.002-.563.923-3.937-2.363v-4.5h1.125z"
                                                />
                                            </g>
                                        </svg>
                                        <span>{moment(updated_at).format(READABLE_STANDARD_DATE)}</span>
                                    </li>
                                    <li>
                                        <Link to={`${profileUrl}/edit`} className={cx("action")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path
                                                    className={cx("withFill")}
                                                    fillRule="nonzero"
                                                    d="M6.02 17.656l-.001-.002.558-1.578 1.347 1.348-1.521.537a.255.255 0 0 1-.383-.306zM17 9.5L14.5 7l.707-.707a1 1 0 0 1 1.414 0l1.086 1.086a1 1 0 0 1 0 1.414L17 9.5zM13.5 8l2.5 2.5-6 6-.25-.75-1.25-.25-.25-1.25L7.5 14l6-6z"
                                                />
                                            </svg>
                                            <span>Edit</span>
                                        </Link>
                                        <span onClick={() => handleModal(id)} className={cx("action")}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path
                                                    className={cx("withFill")}
                                                    fillRule="nonzero"
                                                    d="M16.207 9.207V7.793H7.793v1.414h8.414zm0 2.103V9.897H7.793v1.413h8.414zm0 2.103V12H7.793v1.413h8.414zM17.62 5c.382 0 .707.135.976.404.27.27.404.594.404.976V19l-2.793-2.793H6.38c-.382 0-.707-.14-.976-.42A1.386 1.386 0 0 1 5 14.792V6.38c0-.382.135-.707.404-.976.27-.27.594-.404.976-.404h11.24z"
                                                />
                                            </svg>
                                            <span>Add Note</span>
                                        </span>
                                    </li>
                                </ul>
                            </li>
                        )
                    })}
                </ul>
            </InfiniteScroll>
        )
    }
}

export default withStyles(List, styles)
