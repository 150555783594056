import React, { useState, Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { showErrorNotification, showSuccessNotification } from "actions/notification"
import Modal from "./../../components/modal"
import DeleteModal from "./../../components/deleteModal"
import useDebounce from "hooks/useDebounce"
import { toApiDate } from "helpers/date"

import * as selectionActions from "../../actions"
import * as selectionWidgetActions from "modules/SelectionsWidgetModule/actions"

import List from "./List"

const ListContainer = () => {
    const dispatch = useDispatch()
    const selections = useSelector(state => state.db.selections.list)
    const choosenSelectionIdInWidget = useSelector(state => state.db.selectionsWidget.current.choosenSelection)

    const [isFirstTime, setIsFirstTime] = useState(true)

    const filters = useSelector(state => state.db.selections.filters)
    const moreFilters = useSelector(state => state.db.selections.filters.filters)

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
    const [selectedSelections, setSelectedSelections] = useState([])

    const [isModalFetching, setIsModalFetching] = useState(false)
    const [deleteModalIsOpen, setdDeleteModalIsOpen] = useState(false)
    const [error, setError] = useState(null)

    const loginStore = useSelector(state => state.loginStore)

    const isSuperAdmin = loginStore.roles && !!loginStore.roles.find(role => role.name === "SuperAdmin")

    const setPage = page => dispatch(selectionActions.setPagination(page))
    const setSearchValue = query => dispatch(selectionActions.setQuery(query))
    const setType = t => dispatch(selectionActions.setType(t))
    const setDateRange = dr => dispatch(selectionActions.setDateRange({ from: dr.startDate, to: dr.endDate }))
    const handleSetMoreFilters = mf =>
        dispatch(
            selectionActions.setMoreFilters({
                assignedTo: mf.user || {},
                vendors: mf.brand || {}
            })
        )

    const handleClearMoreFilters = () => dispatch(selectionActions.clearMoreFilters())

    const { vendors, assignedTo } = moreFilters
    const { type, sort, dateRange, query: searchValue, pagination } = filters

    const setFilters = (newFilters, isInitial) =>
        !isInitial &&
        (newFilters.sorting.by && newFilters.sorting.direction
            ? dispatch(
                  selectionActions.replaceFilters({
                      query: newFilters.search,
                      sort: newFilters.sorting,
                      dateRange: { from: newFilters.dateFrom, to: newFilters.dateTo }
                  })
              )
            : dispatch(
                  selectionActions.replaceFilters({
                      query: newFilters.search,
                      dateRange: { from: newFilters.dateFrom, to: newFilters.dateTo }
                  })
              ))

    useEffect(() => {
        dispatch(selectionActions.clearFilters())
        if (!isSuperAdmin) {
            setIsFirstTime(false)
            setType([{ id: "private", value: "private", label: "Private" }])
        } else {
            fetchSelections()
            setIsFirstTime(false)
        }
    }, [])

    useEffect(() => {
        !isFirstTime && fetchSelections()
    }, [pagination])

    useEffect(() => {
        selections.fetchStatus.isError && dispatch(showErrorNotification())
    }, [selections.fetchStatus])

    useDebounce(
        () => {
            !isFirstTime && (pagination !== 1 ? setPage(1) : fetchSelections())
        },
        400,
        [
            ...Object.keys(filters)
                .filter(key => key !== "pagination" && key !== "filters")
                .map(key => filters[key]),
            ...Object.keys(moreFilters).map(key => moreFilters[key])
        ]
    )

    return (
        <Fragment>
            <List
                handleChangeDateRange={setDateRange}
                dateRange={dateRange}
                setSelectedSelections={setSelectedSelections}
                selectedSelections={selectedSelections}
                handleMultiDelete={openDeleteModal}
                currentPage={pagination}
                query={searchValue}
                type={type}
                setType={setType}
                setFilters={setFilters}
                setPage={setPage}
                moreFilters={moreFilters}
                handleSetMoreFilters={handleSetMoreFilters}
                handleClearMoreFilters={handleClearMoreFilters}
                setSearchValue={setSearchValue}
                selections={selections}
                openCreateModal={openCreateModal}
                clearAll={clearAll}
            />
            {renderCreateModal()}
            {renderDeleteModal()}
        </Fragment>
    )

    function clearAll() {
        setSearchValue("")
    }

    function openCreateModal() {
        setCreateModalIsOpen(true)
    }

    function openDeleteModal() {
        setdDeleteModalIsOpen(true)
    }

    function closeCreateModal() {
        setError(null)
        setCreateModalIsOpen(false)
    }

    function getCurrentParams() {
        return {
            page: pagination || 1,
            query: searchValue || undefined,
            created_from: dateRange.from ? toApiDate(dateRange.from) : undefined,
            created_to: dateRange.to ? toApiDate(dateRange.to) : undefined,
            brand_id: vendors && vendors.id,
            user_id: assignedTo && assignedTo.id,
            types: type && type.length > 0 ? type.map(t => t.id).join(",") : undefined,
            sort_by: sort.by || "name",
            sort_direction: sort.direction || "asc",
            length: 10
        }
    }

    function fetchSelections() {
        setSelectedSelections([])
        dispatch(selectionActions.getSelections(getCurrentParams()))
    }

    function resetChoosenSelection() {
        selectionWidgetActions.removeChoosenSelectionFromLocalStore()
        dispatch(selectionWidgetActions.resetChoosenSelection())
    }

    function deleteSelections() {
        setIsModalFetching(true)

        return selectionActions
            .deleteSelectionMulti(selectedSelections)
            .then(() => {
                selectedSelections.some(id => id === parseInt(choosenSelectionIdInWidget, 10)) &&
                    resetChoosenSelection()
                dispatch(showSuccessNotification())
                setdDeleteModalIsOpen(false)
                fetchSelections()
            })
            .catch(() => {
                dispatch(showErrorNotification())
                setdDeleteModalIsOpen(false)
                fetchSelections()
            })
            .finally(() => setIsModalFetching(false))
    }

    function createNewSelection(body) {
        setIsModalFetching(true)
        return selectionActions
            .createSelection(body)
            .then(() => {
                setError(null)
                dispatch(showSuccessNotification())
                setCreateModalIsOpen(false)
                fetchSelections()
            })
            .catch(err => {
                setError(err.errors)
            })
            .finally(() => {
                setIsModalFetching(false)
            })
    }

    function renderCreateModal() {
        return (
            <Modal
                title={"Create new selection"}
                saveButtonLabel={"Create"}
                submit={data => createNewSelection(data)}
                selection={{ name: "", description: "", type: "private" }}
                isFetching={isModalFetching}
                isOpen={createModalIsOpen}
                error={error}
                setError={setError}
                handleClose={closeCreateModal}
            ></Modal>
        )
    }

    function renderDeleteModal() {
        return (
            <DeleteModal
                isOpen={deleteModalIsOpen}
                selectionName={
                    selectedSelections.length > 1
                        ? `${selectedSelections.length} selections`
                        : selectedSelections.length !== 0
                        ? selections.data.find(selection => [...selectedSelections].pop() === selection.id).name
                        : ""
                }
                isLoading={isModalFetching}
                onCancel={() => setdDeleteModalIsOpen(false)}
                onSubmit={() => deleteSelections()}
            ></DeleteModal>
        )
    }
}

export default ListContainer
