import React, { useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import { Button } from "@butterfly-frontend/ui"

import { Image, ReloadableImageWrap, Input, Textarea, Quantity } from "ui"
import { formatPrice } from "helpers/units"
import getInputPropsFactory from "modules/WmsModule/helpers/getInputPropsFactory"
import calculateTotalPrice from "helpers/calculateTotalPrice"

import validationSchema from "./validationSchema"
import styles from "./ItemEdit.module.css"
import quantityStyles from "./overrides/Quantity.module.css"

const ItemEdit = props => {
    const { orderItem, editOrderItem, closeEditMode, brandDiscountValue } = props
    const { id, draftId, image, name, note, manufacturer_id, wholesale_cost, quantity, wholesale_discount } = orderItem

    const editFormik = useFormik({
        initialValues: {
            id,
            draftId,
            note,
            manufacturer_id,
            wholesale_cost,
            quantity,
            wholesale_discount
        },
        validationSchema,
        onSubmit: values => {
            editOrderItem(values)
            closeEditMode()
        }
    })

    const onEditOrderItem = useCallback(async () => {
        if (typeof editFormik.values.wholesale_cost !== "number") {
            await editFormik.setFieldValue("wholesale_cost", 0)
        }

        if (typeof editFormik.values.wholesale_discount !== "number") {
            await editFormik.setFieldValue("wholesale_discount", null)
        }

        return editFormik.submitForm()
    }, [editFormik.submitForm, editFormik.setFieldValue, editFormik.values, closeEditMode])

    const getInputProps = getInputPropsFactory(editFormik)

    const wholesale_total = useMemo(
        () =>
            calculateTotalPrice({
                price: editFormik.values.wholesale_cost,
                quantity: editFormik.values.quantity,
                discount: editFormik.values.wholesale_discount || brandDiscountValue
            }),
        [editFormik.values]
    )

    return (
        <div className={styles.root}>
            <div className={styles.form}>
                <ReloadableImageWrap image={image}>
                    {({ image }) => <Image className={styles.image} src={image} />}
                </ReloadableImageWrap>
                <div className={styles.detailsColumn}>
                    <div className={styles.title}>{name}</div>
                    <Input {...getInputProps("manufacturer_id")} label="Manufacture ID" />
                    <Textarea
                        label="Instruction"
                        className={styles.textarea}
                        name="note"
                        value={editFormik.values.note}
                        error={editFormik.touched.note && editFormik.errors.note}
                        onChange={({ event }) => editFormik.handleChange(event)}
                        onBlur={editFormik.handleBlur}
                    />
                </div>
                <div className={styles.priceColumn}>
                    <div className={styles.wholesalePriceInput}>
                        <Input
                            {...getInputProps("wholesale_cost")}
                            min={0}
                            attributes={{ required: true }}
                            type="number"
                            label="Item price"
                        />
                    </div>
                    <div className={styles.quantityInput}>
                        <label>Qty:</label>
                        <Quantity
                            value={editFormik.values.quantity}
                            min={1}
                            max={999}
                            onChange={value => editFormik.setFieldValue("quantity", value)}
                            error={editFormik.touched.quantity && editFormik.errors.quantity}
                            isInputDisabled={false}
                            customStyles={quantityStyles}
                            onBlur={() => editFormik.setFieldTouched("quantity")}
                        />
                    </div>
                    <div className={styles.discountInput}>
                        <Input
                            {...getInputProps("wholesale_discount")}
                            customPlaceholder={brandDiscountValue}
                            type="number"
                            min={0}
                            max={100}
                            label="Discount"
                        />
                    </div>
                    <div className={styles.total}>
                        <label>Item total:</label>
                        {formatPrice(wholesale_total)}
                    </div>
                </div>
            </div>
            <div className={styles.buttonsBar}>
                <Button size="small" onClick={closeEditMode}>
                    Cancel
                </Button>
                <Button onClick={onEditOrderItem} color="blue" size="small">
                    Save
                </Button>
            </div>
        </div>
    )
}

ItemEdit.propTypes = {
    orderItem: PropTypes.shape({
        id: PropTypes.number,
        draftId: PropTypes.string,
        image: PropTypes.string,
        name: PropTypes.string.isRequired,
        note: PropTypes.string,
        manufacturer_id: PropTypes.string,
        wholesale_cost: PropTypes.number.isRequired,
        quantity: PropTypes.number.isRequired,
        wholesale_discount: PropTypes.number
    }).isRequired,
    editOrderItem: PropTypes.func.isRequired,
    closeEditMode: PropTypes.func.isRequired,
    brandDiscountValue: PropTypes.number
}

export default ItemEdit
