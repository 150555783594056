import { combineReducers } from "redux"

import { MenuReducer } from "./MenuReducer"
import { UserReducer } from "./UserReducer"
import { RoleReducer } from "./RoleReducer"
import { LoginReducer } from "./LoginReducer"
import { ClientReducer } from "./ClientReducer"
import uiReducer from "./ui/index"
import dbReducer from "./db/index"

const rootReducer = combineReducers({
    menuStore: MenuReducer,
    userStore: UserReducer,
    roleStore: RoleReducer,
    loginStore: LoginReducer,
    clientStore: ClientReducer,
    ui: uiReducer,
    db: dbReducer
})

export default rootReducer
