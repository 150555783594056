import React, { Component } from "react"
import { connect } from "react-redux"
import models from "models/index"
import * as actions from "actions/client"
import * as notificationActions from "actions/notification"

import Form from "./Form"
import FormAbstractContainer from "../../FormContainer"

class FormContainer extends Component {
    render() {
        return <Form {...this.props} />
    }
}

const mapStateToProps = state => {
    return {
        data: state.db.clientTypes.current.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getData: id => dispatch(actions.getClientTypeById(id)),
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    FormAbstractContainer(FormContainer, {
        mapStateToProps,
        mapDispatchToProps,
        idParamName: "clientTypeId",
        model: models.ClientType
    })
)
