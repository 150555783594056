import { useState, useCallback } from "react"
import _get from "lodash/get"
import _merge from "lodash/merge"

const ID_KEY_PREFIX = "ids."

const extractStockItemIdFromApiErrorKey = (errorKey, stockItemsIds) => {
    if (errorKey.startsWith(ID_KEY_PREFIX)) {
        const idIndex = parseInt(_get(errorKey.split(ID_KEY_PREFIX), "[1]"), 10)

        return !isNaN(idIndex) && idIndex < stockItemsIds.length ? stockItemsIds[idIndex] : null
    }

    return null
}

const reduceApiStockItemErrors = apiStockItemErrors =>
    apiStockItemErrors.reduce(
        (stockItemErrors, stockItemError) => ({
            isAlreadyInTargetWarehouse:
                stockItemError.includes("is currently in destination warehouse") ||
                stockItemErrors.isAlreadyInTargetWarehouse,
            isOnHold: stockItemError.includes("is on hold state") || stockItemErrors.isOnHold,
            isInShipment: stockItemError.includes("is already in shipment") || stockItemErrors.isInShipment
        }),
        { isAlreadyInTargetWarehouse: false, isOnHold: false, isInShipment: false }
    )

const apiStockItemsErrorsAdapter = (apiErrors, stockItemsIds) =>
    Object.entries(apiErrors).reduce((stockItemErrors, apiError) => {
        const [errorKey, errors] = apiError
        const stockItemId = extractStockItemIdFromApiErrorKey(errorKey, stockItemsIds)

        if (stockItemId) {
            return {
                ...stockItemErrors,
                [stockItemId]: reduceApiStockItemErrors(errors)
            }
        }

        return stockItemErrors
    }, {})

const useStockTransferItemsApiErrors = () => {
    const [apiStockItemsErrors, setApiStockItemsErrors] = useState(null)

    const addApiStockItemsErrors = useCallback(
        (apiErrors, stockItemsIds) =>
            setApiStockItemsErrors(oldErrors => {
                const newErrors = apiStockItemsErrorsAdapter(apiErrors, stockItemsIds)
                return oldErrors ? _merge({}, oldErrors, newErrors) : newErrors
            }),
        []
    )

    const clearAlreadyInTargetWarehouseApiErrors = useCallback(
        () =>
            setApiStockItemsErrors(apiStockItemsErrors => {
                if (apiStockItemsErrors) {
                    return Object.entries(apiStockItemsErrors).reduce((stockItemErrors, stockItemError) => {
                        const [stockItemId, errors] = stockItemError

                        return {
                            ...stockItemErrors,
                            [stockItemId]: {
                                ...errors,
                                isAlreadyInTargetWarehouse: false
                            }
                        }
                    }, {})
                }

                return apiStockItemsErrors
            }),
        []
    )

    return {
        apiStockItemsErrors,
        addApiStockItemsErrors,
        clearApiStockItemsErrors: () => setApiStockItemsErrors(null),
        clearAlreadyInTargetWarehouseApiErrors
    }
}

export default useStockTransferItemsApiErrors
