import { GET_INVOICES_START, GET_INVOICES_SUCCESS, GET_INVOICES_ERROR } from "constants/index"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"
import idx from "idx"

function list(state = { data: [], meta: {}, fetchStatus: initialState() }, action) {
    switch (action.type) {
        case GET_INVOICES_START:
            return { data: [], meta: {}, fetchStatus: requestState() }

        case GET_INVOICES_SUCCESS:
            return {
                data: idx(action.payload, _ => _.data) || [],
                meta: idx(action.payload, _ => _.meta) || {},
                fetchStatus: successState()
            }

        case GET_INVOICES_ERROR:
            return { data: [], meta: {}, fetchStatus: failureState() }

        default:
            return state
    }
}

export default list
