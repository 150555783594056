import React from "react"

export const formatNotes = (str, maxLength) => {
    if (!typeof str === "string") {
        console.error("Function argument should be a string.")
        return str
    }

    let text = str

    if (maxLength) {
        text = text.substring(0, maxLength)
    }

    let element = text.split("")

    element.forEach((sign, index) => {
        if (sign === "\n") {
            element[index] = <br key={index} />
        } else if (sign === " ") {
            element[index] = <span key={index} />
        }
    })

    return <span className="formattedText">{element}</span>
}

export const getNotesUpdater = ({ state, action, validType, key = "data" }) => dataGenerator => {
    const isValidType = action.payload && action.payload.type === validType

    if (!isValidType) {
        return state
    }

    return { ...state, [key]: dataGenerator(state, action) }
}
