import moment from "moment"
import { API_DATE_FORMAT, FILENAME_DATETIME_FORMAT, READABLE_STANDARD_DATE } from "constants/index"

export const toApiDate = date => {
    return moment(date).format(API_DATE_FORMAT)
}

export const formatDate = (date, format = READABLE_STANDARD_DATE) => (date ? moment(date).format(format) : "-")

export const toFilenameDateTime = (date, format = FILENAME_DATETIME_FORMAT) =>
    date ? moment(date).format(format) : "-"
