import React from "react"

import { formatDate } from "helpers/date"

import withStyles from "HOC/withStyles"
import styles from "./addressesAndFinancials.css"

const renderPrice = price => (price ? `$ ${price}` : "-")

const ProjectAddressAndDescription = props => {
    const { cx, address, description } = props
    return (
        <ul className={cx("table", "addressAndDescription")}>
            <li>
                <h3 className={cx("label")}>Project Address:</h3>
                {address && (
                    <div className={cx("value", "address")}>
                        <span>{address.company_name}</span>
                        <span>{address.address}</span>
                        <span>
                            {address.city ? `${address.city}, ` : ""}
                            {address.state ? `${address.state} ` : ""}
                            {address.post_code ? `${address.post_code}` : ""}
                        </span>
                        <span>{address.country}</span>
                    </div>
                )}
            </li>
            <li>
                <h3 className={cx("label")}>Description:</h3>
                <p className={cx("value")}>{description ? description : "-"}</p>
            </li>
        </ul>
    )
}

const FinancialDetails = props => {
    const { cx, project } = props
    const { budget, minimum_purchase, discount } = project

    return (
        <ul className={cx("table")}>
            <li>
                <h3 className={cx("label")}>Budget:</h3>
                <p className={cx("value")}>{renderPrice(budget)}</p>
            </li>
            <li>
                <h3 className={cx("label")}>Minimum purchase:</h3>
                <p className={cx("value")}>{minimum_purchase ? minimum_purchase : "-"}</p>
            </li>
            <li>
                <h3 className={cx("label")}>Discount:</h3>
                <p className={cx("value")}>{discount ? `${discount}%` : "-"}</p>
            </li>
        </ul>
    )
}

const Rental = props => {
    const { cx, total_rent, monthly_rent, rental_starts_at } = props

    return (
        <ul className={cx("table")}>
            <li>
                <h3 className={cx("label")}>Total rental:</h3>
                <p className={cx("value")}>{renderPrice(total_rent)}</p>
            </li>
            <li>
                <h3 className={cx("label")}>Monthly rent:</h3>
                <p className={cx("value")}>{renderPrice(monthly_rent)}</p>
            </li>
            <li>
                <h3 className={cx("label")}>Rental start date:</h3>
                <p className={cx("value")}>{formatDate(rental_starts_at)}</p>
            </li>
        </ul>
    )
}

const BillingAddress = props => {
    const { cx, first_name, last_name, company_name, city, state, address, country, post_code } = props

    return (
        <div className={cx("billingAddress")}>
            <h3 className={cx("label")}>Other than the client’s billing address:</h3>
            <div className={cx("value", "address")}>
                <span>
                    {company_name}
                    {company_name && (first_name || last_name) && ", "}
                    {first_name} {last_name}
                </span>
                <span>{[post_code, city, state].filter(item => item).join(", ")}</span>
                <span>{address}</span>
                <span>{country}</span>
            </div>
        </div>
    )
}

const AddressesAndFinancials = props => {
    const { cx, project } = props
    const { address, description, billing_address, total_rent, monthly_rent, rental_starts_at } = project

    return (
        <div className={cx("root")}>
            <h2>Project Address & Description</h2>
            <ProjectAddressAndDescription cx={cx} address={address} description={description} />

            <h2>Financial Details</h2>
            <FinancialDetails cx={cx} project={project} />

            <h2>Rental</h2>
            <Rental cx={cx} total_rent={total_rent} monthly_rent={monthly_rent} rental_starts_at={rental_starts_at} />

            <h2>Billing Address</h2>
            {billing_address && !billing_address.copy ? (
                <BillingAddress cx={cx} {...billing_address} />
            ) : (
                <p className={cx("label")}>Same as Client’s Billing Address</p>
            )}
        </div>
    )
}

export default withStyles(AddressesAndFinancials, styles)
