import idx from "idx"

export const getInitialValues = data => {
    const product = idx(data, _ => _.product)
    const second_product = idx(data, _ => _.second_product)
    const link_prefix = idx(data, _ => _.settings.link_prefix) || ""
    const link_text = idx(data, _ => _.settings.link_text) || ""
    const second_link_prefix = idx(data, _ => _.settings.second_link_prefix) || ""
    const second_link_text = idx(data, _ => _.settings.second_link_text) || ""
    const link_external = idx(data, _ => _.settings.link_external) || ""
    const second_link_external = idx(data, _ => _.settings.second_link_external) || ""

    return {
        settings: {
            showLink: [product, link_prefix, link_text, second_link_prefix, second_link_text, second_product].some(
                field => !!field
            ),
            product_link_type: !!product ? "product" : "link",
            second_product_link_type: !!second_product ? "product" : "link",
            link_prefix,
            link_text,
            second_link_prefix,
            second_link_text,
            link_external,
            second_link_external
        },
        product,
        second_product
    }
}

export const getSaveValues = data => {
    const getValue = (value, secondCondition = true) => (data.settings.showLink && secondCondition ? value : null)
    const getSecondValue = value => getValue(value, data.settings.type === "double")
    return {
        settings: {
            link_prefix: getValue(data.settings.link_prefix),
            link_text: getValue(data.settings.link_text),
            link_external: getValue(data.settings.link_external, data.settings.product_link_type === "link"),
            second_link_prefix: getSecondValue(data.settings.second_link_prefix),
            second_link_text: getSecondValue(data.settings.second_link_text),
            second_link_external: getValue(
                data.settings.second_link_external,
                data.settings.second_product_link_type === "link"
            ),
            second_product_id: getValue(
                idx(data, _ => _.second_product.id),
                data.settings.type === "double" && data.settings.second_product_link_type === "product"
            ),
            showLink: undefined,
            product_link_type: undefined,
            second_product_link_type: undefined
        },
        product_id: getValue(idx(data, _ => _.product.id), data.settings.product_link_type === "product"),
        product: undefined,
        second_product: undefined
    }
}
