import React, { useState, useEffect, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"

import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { showPrintWindow } from "actions/print"
import * as productActions from "actions/product"
import * as constants from "../../../../constants"

import SelectionList from "./SelectionList"
import {
    changeSelection,
    createSelection,
    shouldFetch,
    fetchProductsBySelectionId
} from "modules/SelectionsModule/actions"

const SelectionListContainer = props => {
    const { selectionId, selection, selectedProducts, setSelectedProducts } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [isFetching, setIsFetching] = useState(false)
    const [selectedAction, setSelectedAction] = useState(null)
    const [selectedSelection, setSelectedSelection] = useState(null)
    const [productToDelete, setProductToDelete] = useState({})
    const [errorCreateSelection, setErrorCreateSelection] = useState({})
    const [isSendableModalOpenFor, setIsSendableModalOpenFor] = useState("")
    const [isPrinting, setIsPrinting] = useState(false)
    const products = useSelector(state => state.db.selections.current.products)
    const dispatch = useDispatch()

    const printAndSendItems = useCallback(
        func => [
            { action: () => func("with_cover"), text: "With cover" },
            { action: () => func("bigger_pictures"), text: "Bigger pictures" },
            { action: () => func("smaller_pictures"), text: "Smaller pictures" }
        ],
        []
    )

    useEffect(() => {
        return () => dispatch({ type: constants.RESET_PRODUCTS })
    }, [])

    useEffect(() => {
        if (shouldFetch(products)) {
            dispatch(fetchProductsBySelectionId(selectionId, currentPage))
        }
    }, [products])

    useEffect(() => {
        dispatch({ type: constants.RESET_PRODUCTS })
    }, [currentPage])

    const handleMultiselectProduct = id => {
        selectedProducts.includes(id)
            ? setSelectedProducts(selectedProducts.filter(productId => productId !== id))
            : setSelectedProducts([...selectedProducts, id])
    }

    const handleMove = secondSelectionId => {
        setSelectedSelection(secondSelectionId)
        setIsFetching(true)
        addProducts(secondSelectionId, selectedProducts)
            .then(resultAddProducts =>
                deleteProducts(selectionId, selectedProducts).then(() => {
                    const secondSelectionName = resultAddProducts.payload.data.data.name
                    setSelectedProducts([])
                    dispatch(
                        showSuccessNotification({
                            title: `${selectedProducts.length} products moved to ${secondSelectionName}`
                        })
                    )
                    dispatch({ type: constants.MOVE_PRODUCTS })
                })
            )
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                setSelectedAction(null)
                setIsFetching(false)
                setSelectedSelection(null)
            })
    }

    const handleDuplicate = secondSelectionId => {
        setSelectedSelection(secondSelectionId)
        setIsFetching(true)
        addProducts(secondSelectionId, selectedProducts)
            .then(result => {
                const secondSelectionName = result.payload.data.data.name
                setSelectedProducts([])
                dispatch(
                    showSuccessNotification({
                        title: `${selectedProducts.length} products copied to ${secondSelectionName}`
                    })
                )
                dispatch({ type: constants.DUPLICATE_PRODUCTS })
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                setSelectedAction(null)
                setIsFetching(false)
                setSelectedSelection(null)
            })
    }

    const deleteProducts = (selectionId, productIds) =>
        dispatch(
            changeSelection(selectionId, {
                products_detach: productIds.map(id => ({ id }))
            })
        )

    const addProducts = (selectionId, productIds) =>
        dispatch(
            changeSelection(selectionId, {
                products_attach: productIds.map(id => ({ id }))
            })
        )

    const handleChooseProduct = data =>
        addProducts(selectionId, [data.product_id])
            .then(() => {
                dispatch(showSuccessNotification())
                dispatch({ type: "selections/ADD_PRODUCT_TO_SELECTION_SUCCESS" })
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => setSelectedAction(null))

    const handleDeleteAllProducts = () => {
        handleResultDeleteProducts(dispatch(changeSelection(selectionId, { products: [] })), "All products deleted")
    }

    const handleDeleteMultiselectProducts = () => {
        handleResultDeleteProducts(
            deleteProducts(selectionId, selectedProducts),
            `${selectedProducts.length} products deleted`
        )
    }

    const handleDeleteSingleProduct = () => {
        handleResultDeleteProducts(deleteProducts(selectionId, [productToDelete.id]), "Product deleted")
    }

    const handleCreateSelection = data => {
        setIsFetching(true)
        return createSelection(data)
            .then(({ data }) => {
                selectedAction === "CREATE_SELECTION_IN_MOVE" ? handleMove(data.id) : handleDuplicate(data.id)
            })
            .catch(err => {
                setIsFetching(false)
                setErrorCreateSelection(err.errors)
            })
    }

    const handleResultDeleteProducts = (action, message) => {
        setIsFetching(true)
        action
            .then(() => {
                setSelectedProducts([])
                dispatch(showSuccessNotification({ title: message }))
                dispatch({ type: constants.DELETE_PRODUCTS })
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                setProductToDelete({})
                setSelectedAction(null)
                setIsFetching(false)
            })
    }

    function handlePrintForSendable(type) {
        return productActions.multiSelectPrint(selectedProducts, type).then(showPrintWindow)
    }

    function handlePrint(type) {
        setIsPrinting(true)
        return productActions
            .multiSelectPrint(selectedProducts, type)
            .then(showPrintWindow)
            .finally(() => {
                setIsPrinting(false)
            })
    }

    function handleSend(type) {
        setIsSendableModalOpenFor(type)
    }

    function closeSendableModal() {
        setSelectedProducts([])
        setIsSendableModalOpenFor("")
    }

    return (
        <SelectionList
            isFetching={isFetching}
            selectionId={selectionId}
            productToDelete={productToDelete}
            products={products}
            currentPage={currentPage}
            selectedAction={selectedAction}
            selectedProducts={selectedProducts}
            selectedSelection={selectedSelection}
            setSelectedAction={setSelectedAction}
            setProductToDelete={setProductToDelete}
            setCurrentPage={setCurrentPage}
            handleMove={handleMove}
            handleDuplicate={handleDuplicate}
            handleChooseProduct={handleChooseProduct}
            handleMultiselectProduct={handleMultiselectProduct}
            handleDeleteAllProducts={handleDeleteAllProducts}
            handleDeleteSingleProduct={handleDeleteSingleProduct}
            handleDeleteMultiselectProducts={handleDeleteMultiselectProducts}
            handleCreateSelection={handleCreateSelection}
            errorCreateSelection={errorCreateSelection}
            setErrorCreateSelection={setErrorCreateSelection}
            printAndSendItems={printAndSendItems}
            isSendableModalOpenFor={isSendableModalOpenFor}
            setIsSendableModalOpenFor={setIsSendableModalOpenFor}
            isPrinting={isPrinting}
            handlePrint={handlePrint}
            handleSend={handleSend}
            handlePrintForSendable={handlePrintForSendable}
            closeSendableModal={closeSendableModal}
            selection={selection}
        />
    )
}

export default SelectionListContainer
