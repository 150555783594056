import * as constants from "constants/index"

function list(state = { filters: {} }, action) {
    switch (action.type) {
        case constants.SAVE_FILTERS_LIST_PROJECTS:
            return { filters: action.payload }

        default:
            return state
    }
}

export default list
