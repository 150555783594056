import CLIENT_RETURN_SHIPMENT_STATUS_KEYS from "./clientReturnShipmentStatusKeys"
import STATUS_COLORS from "./statusColors"

const CLIENT_RETURN_SHIPMENT_STATUSES = {
    [CLIENT_RETURN_SHIPMENT_STATUS_KEYS.NEW]: {
        id: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.NEW,
        label: "New",
        color: STATUS_COLORS.ORANGE,
        value: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.NEW,
        theme: "outline"
    },
    [CLIENT_RETURN_SHIPMENT_STATUS_KEYS.IN_PROGRESS]: {
        id: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.IN_PROGRESS,
        label: "In progress",
        color: STATUS_COLORS.ORANGE,
        value: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.IN_PROGRESS,
        theme: "outline"
    },
    [CLIENT_RETURN_SHIPMENT_STATUS_KEYS.SENT]: {
        id: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.SENT,
        label: "Sent",
        color: STATUS_COLORS.ORANGE,
        value: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.SENT,
        theme: "outline"
    },
    [CLIENT_RETURN_SHIPMENT_STATUS_KEYS.RECEIVED]: {
        id: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.RECEIVED,
        label: "Received",
        color: STATUS_COLORS.GREEN,
        value: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.RECEIVED,
        theme: "outline"
    },
    [CLIENT_RETURN_SHIPMENT_STATUS_KEYS.CANCELLED]: {
        id: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.CANCELLED,
        label: "Cancelled",
        color: STATUS_COLORS.RED,
        value: CLIENT_RETURN_SHIPMENT_STATUS_KEYS.CANCELLED,
        theme: "fill",
        type: "danger"
    }
}

export default CLIENT_RETURN_SHIPMENT_STATUSES
