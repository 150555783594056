import React, { useReducer, useEffect, useState, Fragment } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import Layout from "components/Reusable/Layout/index"
import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"
import { WarningBar, EmptyList, Toggle } from "ui"
import Loader from "ui/FilterableTable/components/SnakeLoader"
import ListHeader from "./ListHeader"
import ListRow from "./ListRow"
import MergeDataModal from "modules/ClientModule/components/MergeDataModal"
import NoteModal from "./NoteModal"

import { showErrorNotification } from "actions/notification"
import { getPartners } from "actions/partners"
import fetch from "helpers/fetch"
import { useFetch, useActions } from "hooks"
import * as verify from "./verifyReducer"

import withStyles from "HOC/withStyles"
import styles from "./AwaitingList.css"
import LayoutStyles from "./overrides/LayoutStyles.css"
import userIcon from "assets/ico-user.svg"

const { setPartnertId, setDataToVerify, clearVerifyState } = verify

const AwaitingList = props => {
    const { cx } = props

    const [partnerDataNoteModal, setPartnerDataNoteModal] = useState(null)
    const [showUnconfirmedEmails, setShowUnconfirmedEmails] = useState(false)
    const [filteredData, setFilteredData] = useState([])
    const [resendingEmailsIds, setResendingEmailsIds] = useState([])
    const [confirmActivationHandler, setConfirmActivationHandler] = useState(null)

    const history = useHistory()

    const actions = useActions({ getPartners, showErrorNotification })
    const {
        data,
        fetchStatus: { isLoading }
    } = useSelector(state => state.db.partners.list)

    const [verifyData, dispatchVerify] = useReducer(verify.reducer, verify.initialState)

    useEffect(() => {
        actions.getPartners()
    }, [])

    useEffect(() => {
        setFilteredData(
            data.filter(item => (showUnconfirmedEmails && !item.verified) || (!showUnconfirmedEmails && item.verified))
        )
    }, [data, showUnconfirmedEmails])

    const clearVerify = () => dispatchVerify(clearVerifyState())

    const [onVerifyAcount, { isLoading: isVerifying }] = useFetch({
        action: (partnerId, clientId) => {
            dispatchVerify(setPartnertId(partnerId))
            return fetch.put(`/partners/${partnerId}/activate`)
        },
        onSuccess: ({ data }) => history.push(`/contact/${data.client_id}/profile/edit`),
        onError: error => {
            if (error.data) {
                dispatchVerify(setDataToVerify(error.data))
            } else {
                clearVerify()
                actions.showErrorNotification()
            }
        }
    })

    const [activateAccount, { isLoading: isActivating }] = useFetch({
        action: partnerId => fetch.post(`/partners/${partnerId}/manual-confirmation`),
        onSuccess: () => {
            setConfirmActivationHandler(null)
            actions.getPartners()
        },
        onError: () => actions.showErrorNotification()
    })

    const [handleEmailConfirmationResend] = useFetch({
        action: partnerId => {
            setResendingEmailsIds(prevState => [...prevState, partnerId])
            return fetch.postRAW(`/partners/${partnerId}/resend-confirmation`)
        },
        onFinish: partnerId => {
            setResendingEmailsIds(prevState => prevState.filter(id => id !== partnerId))
        },
        onError: () => {
            actions.showErrorNotification()
        }
    })

    const handleActivateAccount = id => {
        setConfirmActivationHandler(() => () => activateAccount(id))
    }

    const handleShowUnconfirmedEmailsChange = () => {
        setShowUnconfirmedEmails(!showUnconfirmedEmails)
    }

    const resultsCount = `${filteredData.length} result${filteredData.length > 1 ? "s" : ""}`
    const isModalOpen = verifyData.partnerId && verifyData.data

    return (
        <Layout title="Awaiting Approval" customStyles={LayoutStyles}>
            <div className={cx("toggle")}>
                <Toggle
                    isOn={showUnconfirmedEmails}
                    label={{
                        on: "Show Partners with unconfirmed e-mail address",
                        off: "Show Partners with unconfirmed e-mail address"
                    }}
                    labelPosition="right"
                    handleChange={handleShowUnconfirmedEmailsChange}
                />
            </div>
            {isLoading ? (
                <div className={cx("loaderWrapper")}>
                    <Loader />
                </div>
            ) : filteredData.length ? (
                <Fragment>
                    <WarningBar text="B2B Partners need your approval to have more access to the online store." />
                    <div className={cx("countAndSort")}>
                        <div className={cx("resultsCount")}>{resultsCount}</div>
                    </div>
                    <table className={cx("table")}>
                        <ListHeader />
                        <tbody>
                            {filteredData.map(partner => (
                                <ListRow
                                    key={partner.id}
                                    partner={partner}
                                    activateAccount={id => handleActivateAccount(id)}
                                    verifyAcount={onVerifyAcount}
                                    handleEmailConfirmationResend={handleEmailConfirmationResend}
                                    resendingEmailsIds={resendingEmailsIds}
                                    openNoteModal={() => setPartnerDataNoteModal(partner)}
                                    isActivating={isActivating}
                                    isVerifying={isVerifying && verifyData.partnerId === partner.id}
                                    actionDisabled={!!verifyData.partnerId}
                                />
                            ))}
                        </tbody>
                    </table>

                    {isModalOpen && (
                        <MergeDataModal
                            onClose={clearVerify}
                            onSuccess={onVerifyAcount}
                            verifyData={verifyData}
                            isVerifying={isVerifying}
                        />
                    )}

                    {!!partnerDataNoteModal && (
                        <NoteModal
                            onClose={() => setPartnerDataNoteModal(null)}
                            partner={partnerDataNoteModal}
                            onSuccess={actions.getPartners}
                        />
                    )}
                </Fragment>
            ) : (
                <EmptyList icon={userIcon} message="Empty list" />
            )}
            {confirmActivationHandler && (
                <Modal>
                    <Confirm
                        confirmModalTitle={"Are you sure you want to manually activate this partner?"}
                        actionButtonLabel={"Activate"}
                        isLoading={isActivating}
                        handleHideModal={() => setConfirmActivationHandler(null)}
                        handleDelete={confirmActivationHandler}
                    />
                </Modal>
            )}
        </Layout>
    )
}

export default withStyles(AwaitingList, styles)
