import React, { useState, useEffect, useCallback, useReducer } from "react"
import { useSelector, useDispatch } from "react-redux"
import isEqual from "lodash/isEqual"
import { INITIAL_STATE } from "reducers/db/products/filters"
import * as productActions from "actions/product"
import useNonmutableState from "hooks/useNonmutableState"

import FilterList from "./FilterList"

function getLastNode(parent, node, idList, level) {
    const id = idList.shift()
    const newNode = node.children.find(item => item.id === id)
    return newNode
        ? idList.length > 0
            ? getLastNode(node, newNode, idList, level + 1)
            : newNode && newNode.children
            ? { node, level }
            : { node: parent, level: level - 1 }
        : { node, level }
}

function getLastTree(tree, idList) {
    const root = { id: -1, children: [...tree] }
    const node = getLastNode(root, root, [...idList], 0)
    return { categories: node.node.children || [], level: node.level >= 0 ? node.level : 0 }
}

const FilterListContainer = props => {
    const { filterHandleFilterToggle } = props
    const dispatch = useDispatch()
    const filters = useSelector(state => state.db.products.filters)

    const categories = useSelector(state => state.db.products.aggregates.categories)
    const brands = useSelector(state => state.db.products.aggregates.brands)
    const selections = useSelector(state => state.db.products.aggregates.selections)
    const locationsList = useSelector(state => state.db.products.aggregates.locations)

    const [currentCategories, setCurrentCategories] = useState({ categories: categories || [], level: 0 })

    const selected = {
        ...useSelector(state => state.db.products.filters.filters),
        categories: useSelector(state => state.db.products.filters.categories)
    }
    const products = useSelector(state => state.db.products.list)

    const initialExpands = {
        brands: false,
        selections: false,
        location: false,
        priceRange: false,
        discount: false,
        manufacturingTime: false,
        deliveringTime: false,
        availability: false,
        reorderable: false,
        verification: false,
        productionYear: false
    }

    const initialDisabled = {
        ...initialExpands,
        withoutPhotos: false,
        availableOnWeb: false,
        deleted: false
    }

    const expanderConstants = {
        SET: "SET",
        TOGGLE: "TOGGLE",
        REPLACE: "REPLACE",
        HIDEALL: "HIDEALL",
        PUT: "PUT"
    }

    const selectedReducer = (state, action) => {
        switch (action.type) {
            case expanderConstants.SET:
                return { ...state, [action.payload.name]: action.payload.value }

            case expanderConstants.TOGGLE:
                return { ...state, [action.payload.name]: !state[action.payload.name] }

            case expanderConstants.PUT:
                return { ...state, ...action.payload.value }

            case expanderConstants.REPLACE:
                return { ...action.payload.value }

            case expanderConstants.HIDEALL:
                return { ...initialExpands }

            default:
                return state
        }
    }

    const [isExpanded, setIsExpanded] = useReducer(selectedReducer, { ...initialExpands })
    const [prevExpanded, setPrevExpanded] = useReducer(selectedReducer, { ...initialExpands })
    const [disabled, setDisabled] = useReducer(selectedReducer, { ...initialDisabled })
    const [wasDisabled, setWasDisabled] = useState(false)
    const [errors, setErrors] = useState({})

    const [brandsSearchPhrase, brandsSearchPhraseRef, brandsSetSearchPhrase] = useNonmutableState("")
    const [selectionsSearchPhrase, selectionsSearchPhraseRef, selectionsSetSearchPhrase] = useNonmutableState("")

    const ERRORSMAP = {
        price_min: {
            name: "priceRange",
            val: "leftValue"
        },
        price_max: {
            name: "priceRange",
            val: "rightValue"
        },
        discount_min: {
            name: "discount",
            val: "leftValue"
        },
        discount_max: {
            name: "discount",
            val: "rightValue"
        },
        manufacturing_time_min: {
            name: "manufacturingTime",
            val: "leftValue"
        },
        manufacturing_time_max: {
            name: "manufacturingTime",
            val: "rightValue"
        },
        production_year_min: {
            name: "productionYear",
            val: "leftValue"
        },
        production_year_max: {
            name: "productionYear",
            val: "rightValue"
        },
        delivery_time_min: {
            name: "deliveringTime",
            val: "leftValue"
        },
        delivery_time_max: {
            name: "deliveringTime",
            val: "rightValue"
        }
    }

    const mapErorsFromFetch = fetchErrors =>
        fetchErrors === null
            ? {}
            : typeof fetchErrors === "object"
            ? Object.keys(fetchErrors).reduce((acc, error) => {
                  const errorObj = ERRORSMAP[error]

                  if (!errorObj) {
                      return { ...acc }
                  }

                  const { name, val } = errorObj
                  const obj = acc[name] ? { ...acc[name], [val]: fetchErrors[error] } : { [val]: fetchErrors[error] }

                  return { ...acc, [name]: obj }
              }, {})
            : {}

    const handleClearAll = () => {
        setIsExpanded({ type: "HIDEALL" })
        dispatch(productActions.clearAllSelectedFilters())
    }

    const brandsHandleChange = useCallback(id => {
        dispatch(productActions.toggleSelectedBrands(id))
    }, [])

    const selectionsHandleChange = useCallback(id => {
        dispatch(productActions.toggleSelectedSelections(id))
    }, [])

    function checkFilter(filter) {
        return (
            (typeof filter === "string" && filter === "") ||
            (Array.isArray(filter) && filter.length === 0) ||
            (typeof filter === "object" &&
                !Array.isArray(filter) &&
                Object.keys(filter).reduce((acc, key) => acc && !filter[key], true))
        )
    }

    /*
     * UseEffect uruchamiany dla sytuacji gdy lista produktów jest pusta.
     * Wyszarza nieużywane filtry.
     */
    useEffect(() => {
        if (products.data && products.data.length === 0) {
            setDisabled({
                type: expanderConstants.REPLACE,
                payload: {
                    value: Object.keys(initialDisabled).reduce(
                        (acc, key) => ({ ...acc, [key]: checkFilter(selected[key]) }),
                        {}
                    )
                }
            })
        }
    }, [products, ...Object.keys(selected).map(key => selected[key])])

    /*
     * UseEffect uruchamiany w sytuacji gdy filtry są wyszarzane bądź odszarzane.
     * Zapamiętuje poprzednio otwarte filtry i zamyka wyszarzone.
     * Po odszarzeniu wszystkich filtrów na nowo otwiera uprzednio otwarte.
     */
    useEffect(() => {
        if (products.data && products.data.length === 0) {
            setPrevExpanded({
                type: expanderConstants.REPLACE,
                payload: {
                    value: Object.keys(disabled).reduce(
                        (acc, key) => ({ ...acc, [key]: isExpanded[key] || prevExpanded[key] }),
                        {}
                    )
                }
            })
            setIsExpanded({
                type: expanderConstants.REPLACE,
                payload: {
                    value: Object.keys(disabled).reduce(
                        (acc, key) => ({ ...acc, [key]: isExpanded[key] && !disabled[key] }),
                        {}
                    )
                }
            })
            setWasDisabled(true)
        } else if (wasDisabled) {
            setIsExpanded({ type: expanderConstants.REPLACE, payload: { value: { ...prevExpanded } } })
            setPrevExpanded({ type: expanderConstants.REPLACE, payload: { value: { ...initialExpands } } })
            setWasDisabled(false)
            setDisabled({ type: expanderConstants.REPLACE, payload: { value: initialDisabled } })
        }
    }, [products, ...Object.keys(disabled).map(key => disabled[key])])

    useEffect(() => {
        setErrors(mapErorsFromFetch(products.fetchStatus.error ? products.fetchStatus.error.errors : {}))
    }, [products.fetchStatus.isError])

    const actions = {
        categories: {
            update: newSelectedCategories => dispatch(productActions.updateSelectedCategories(newSelectedCategories))
        },
        brands: {
            clear: () => brandsSetSearchPhrase("") || dispatch(productActions.clearSelectedBrands()),
            update: brandsHandleChange
        },
        selections: {
            clear: () => selectionsSetSearchPhrase("") || dispatch(productActions.clearSelectedSelections()),
            update: selectionsHandleChange
        },
        priceRange: {
            clear: () => dispatch(productActions.clearPriceRange()),
            update: val => dispatch(productActions.updatePriceRange(val))
        },
        discount: {
            clear: () => dispatch(productActions.clearDiscount()),
            update: val => dispatch(productActions.updateDiscount(val))
        },
        manufacturingTime: {
            clear: () => dispatch(productActions.clearManufacturingTime()),
            update: val => dispatch(productActions.updateManufacturingTime(val))
        },
        productionYear: {
            clear: () => dispatch(productActions.clearProductionYear()),
            update: val => dispatch(productActions.updateProductionYear(val))
        },
        deliveringTime: {
            clear: () => dispatch(productActions.clearDeliveringTime()),
            update: val => dispatch(productActions.updateDeliveringTime(val))
        },
        locations: {
            update: location => dispatch(productActions.updateLocation(location)),
            clear: () => dispatch(productActions.clearLocation())
        },
        availability: {
            update: value => dispatch(productActions.updateAvailability(value)),
            clear: () => dispatch(productActions.clearAvailability())
        },
        reorderable: {
            update: value => dispatch(productActions.updateReorderable(value)),
            clear: () => dispatch(productActions.clearReorderable())
        },
        verification: {
            update: value => dispatch(productActions.updateVerification(value)),
            clear: () => dispatch(productActions.clearVerification())
        },
        availableOnWeb: {
            update: value => dispatch(productActions.updateAvailableOnWeb(value))
        },
        withoutPhotos: {
            update: value => dispatch(productActions.updateWithoutPhotos(value))
        },
        deleted: {
            update: value => dispatch(productActions.updateDeleted(value))
        },
        clearance: {
            update: value => dispatch(productActions.updateClearance(value))
        }
    }

    useEffect(() => {
        setCurrentCategories(getLastTree(categories, selected.categories))
    }, [categories, selected.categories])

    return (
        <FilterList
            isSelectAnyFilter={!isEqual(INITIAL_STATE.filters, filters.filters)}
            categories={currentCategories.categories}
            categoryLevel={currentCategories.level}
            filterHandleFilterToggle={filterHandleFilterToggle}
            brands={brands}
            locationsList={locationsList}
            selections={selections}
            selected={selected}
            handleClearAll={handleClearAll}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            errors={errors}
            actions={actions}
            disabled={disabled}
            expanderConstants={expanderConstants}
            brandsSearchPhrase={brandsSearchPhrase}
            brandsSearchPhraseRef={brandsSearchPhraseRef}
            selectionsSearchPhrase={selectionsSearchPhrase}
            selectionsSearchPhraseRef={selectionsSearchPhraseRef}
        />
    )
}

export default FilterListContainer
