import React, { Fragment, useMemo } from "react"
import { sortBy } from "lodash"

import withStyles from "HOC/withStyles"
import ChooseProductModal from "components/Modal/ChooseProduct"
import {
    BottomBar,
    CreateRoomModal,
    ChangeRoomModal,
    DeleteOneProductModal,
    DeleteProductsModal,
    DeleteRoomConfirmModal,
    Room,
    TopBar,
    EmptyRoom,
    ShareModal,
    Filters,
    AddToSalesOrderModal
} from "./components"

import Skeleton from "components/Skeleton"
import Loader from "components/Reusable/Loader"

import styles from "./ProductList.css"

const ProductList = props => {
    const {
        cx,
        project,
        itemsState,
        productFetchStatuses,
        productCount,
        productModalIsOpen,
        productToDelete,
        toggleProductDelete,
        toggleProductModalIsOpen,
        handleChooseProduct,
        handleActionProduct,
        handleToggleSelectAllProductsInRoom,
        areAllProductsInRoomChecked,
        handleBarAction,
        areProductsChecked,
        areAllProductsChecked,
        toggleRoomModal,
        shareModalIsOpen,
        shareProject,
        shareProjectErrors,
        removeShareEmail,
        toggleShareModalIsOpen,
        filters,
        setFilters
    } = props

    const rooms = useMemo(() => project.data.rooms.map(({ id, name }) => ({ id, name })), [project.data.rooms])

    const brands = useMemo(
        () =>
            sortBy(
                Object.values(
                    project.data.rooms.reduce(
                        (acc, room) => ({
                            ...acc,
                            ...room.items.reduce(
                                (brands, product) => ({
                                    ...brands,
                                    [product.brand_id]: { id: product.brand_id, name: product.vendor }
                                }),
                                {}
                            )
                        }),
                        {}
                    )
                ),
                ["name"]
            ),
        [project.data.rooms]
    )

    const filteredRooms = useMemo(
        () =>
            project.data.rooms
                .filter(room => !filters.room || room.id === filters.room.id)
                .map(room => ({
                    ...room,
                    items: room.items.filter(
                        item =>
                            (!filters.brand || item.brand_id === filters.brand.id) &&
                            (!filters.search || item.name.toLowerCase().includes(filters.search.toLowerCase()))
                    )
                })),
        [project.data.rooms, filters]
    )

    return (
        <div className={cx("root")}>
            <Skeleton
                fetchStatus={project.roomsFetchStatus}
                component={
                    <div className={cx("section", "loader")}>
                        <Loader />
                    </div>
                }
                render={() => {
                    return (
                        <Fragment>
                            {renderHeader()}
                            {renderFilters()}
                            {renderRoomList()}
                            {productCount > 0 && renderBottomBar()}
                            {renderRoomModal()}
                            {renderAddToRoomModal()}
                            {renderAddToSalesOrderModal()}
                            {renderDeleteOneModal()}
                            {renderDeleteProductsConfirmModal()}
                            {renderDeleteRoomConfirmModal()}
                            {renderShareModal()}
                        </Fragment>
                    )
                }}
            />
            {renderChooseProductModal()}
        </div>
    )

    function renderHeader() {
        return (
            <TopBar
                isMultiSelect={areProductsChecked}
                areAllChecked={areAllProductsChecked}
                productCount={productCount}
                handleAction={handleBarAction}
            />
        )
    }

    function renderFilters() {
        return <Filters filters={filters} onUpdate={setFilters} brands={brands} rooms={rooms} />
    }

    function renderRoomList() {
        return isProjectEmpty() ? (
            <EmptyRoom />
        ) : (
            <ul className={cx("roomListContainer")}>
                {filteredRooms.map(room => (
                    <Room
                        key={room.id}
                        project={project}
                        room={room}
                        itemsState={itemsState}
                        areAllProductsInRoomChecked={areAllProductsInRoomChecked}
                        handleActionProduct={handleActionProduct}
                        handleToggleSelectAllProductsInRoom={handleToggleSelectAllProductsInRoom}
                    />
                ))}
            </ul>
        )
    }

    function renderBottomBar() {
        return (
            <BottomBar
                isMultiSelect={areProductsChecked}
                checkedItemsCount={productCount}
                sharePeopleCount={project.data.shares ? project.data.shares.mails.length : 0}
                handleAction={handleBarAction}
                toggleShareModalIsOpen={toggleShareModalIsOpen}
            />
        )
    }

    function renderChooseProductModal() {
        return (
            <ChooseProductModal
                areManualEntries
                isOpen={productModalIsOpen}
                handleClose={toggleProductModalIsOpen}
                handleSelect={handleChooseProduct}
                fetchStatuses={productFetchStatuses}
            />
        )
    }

    function renderRoomModal() {
        const { roomModalIsOpen, roomModalCurrentItem } = props

        return (
            <CreateRoomModal
                isOpen={roomModalIsOpen}
                room={roomModalCurrentItem}
                projectId={project.data.id}
                onClose={() => toggleRoomModal()}
            />
        )
    }

    function renderDeleteOneModal() {
        const { productIsDeleting, handleDeleteProduct, productDeleteModalIsOpen } = props

        return (
            <DeleteOneProductModal
                isOpen={productDeleteModalIsOpen}
                isLoading={productIsDeleting}
                onCancel={() => toggleProductDelete(false)}
                onSubmit={handleDeleteProduct}
                product={productToDelete}
            />
        )
    }

    function renderDeleteProductsConfirmModal() {
        const { multiProductDeleteState, toggleMultiProductDelete, handleMultiProductDelete } = props

        return (
            <DeleteProductsModal
                isOpen={multiProductDeleteState.isOpen}
                isLoading={multiProductDeleteState.isSaving}
                onCancel={() => toggleMultiProductDelete([])}
                onSubmit={() => handleMultiProductDelete()}
                productsCount={multiProductDeleteState.productIds.length}
            />
        )
    }

    function renderDeleteRoomConfirmModal() {
        const { deleteRoomModalState, deleteRoomModalDispatch, handleDeleteRoom } = props

        return (
            <DeleteRoomConfirmModal
                isOpen={deleteRoomModalState.isOpen}
                isLoading={deleteRoomModalState.isSaving}
                onCancel={() => deleteRoomModalDispatch({ type: "toggleModal" })}
                onSubmit={() => handleDeleteRoom(deleteRoomModalState.roomId)}
            />
        )
    }

    function renderAddToRoomModal() {
        const { addToRoomModalState, toggleProductAddToRoom } = props

        return (
            <ChangeRoomModal
                isOpen={addToRoomModalState.isOpen}
                projectId={project.data.id}
                products={addToRoomModalState.productIds}
                isCopy={addToRoomModalState.isCopy}
                rooms={project.data.rooms}
                handleCreateNewRoom={() => {
                    toggleProductAddToRoom(null)
                    toggleRoomModal()
                }}
                onClose={() => toggleProductAddToRoom(null)}
            />
        )
    }

    function renderAddToSalesOrderModal() {
        const { salesOrderModalState, toggleSalesOrderModal } = props

        return (
            <AddToSalesOrderModal
                isOpen={salesOrderModalState.isOpen}
                onClose={toggleSalesOrderModal}
                projectId={project.data.id}
                products={salesOrderModalState.productIds}
            />
        )
    }

    function renderShareModal() {
        const optionsByRoom = (project.data.shares.options || []).reduce((acc, room) => {
            return {
                ...acc,
                [room.room_id]: room
            }
        }, {})

        return (
            <ShareModal
                isOpen={shareModalIsOpen}
                handleClose={toggleShareModalIsOpen}
                handleSubmit={shareProject}
                rooms={(project.data.rooms || []).map(room => ({
                    room_id: room.id,
                    name: room.name,
                    brand: optionsByRoom[room.id] ? optionsByRoom[room.id].vendor_visibility : true,
                    product: optionsByRoom[room.id] ? optionsByRoom[room.id].naming_visibility : true,
                    price: optionsByRoom[room.id] ? optionsByRoom[room.id].pricing_visibility : true
                }))}
                emails={project.data.shares.mails || []}
                removeShareEmail={removeShareEmail}
                errors={shareProjectErrors}
            />
        )
    }

    function countRooms() {
        return (project.data.rooms || []).length
    }

    function hasProducts() {
        return !!productCount
    }

    function isProjectEmpty() {
        return !hasProducts() && countRooms() === 1
    }
}

export default withStyles(ProductList, styles)
