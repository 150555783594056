import React, { Component } from "react"
import { DragSource } from "react-dnd"
import { DRAGDROP_ACTION_TRANSFER_IMAGE_PRODUCT, LOAD_THUMBNAIL_IMAGE } from "constants/index"
import Checkbox from "components/Reusable/Form/Checkbox"
import withStyles from "HOC/withStyles"
import styles from "./styles.css"

class Image extends Component {
    state = {
        isChecked: false
    }

    componentDidUpdate(prevProps) {
        const { currentlySelectedImagesLength } = this.props
        const isDifferentListLength = prevProps.currentlySelectedImagesLength !== currentlySelectedImagesLength
        if (prevProps.defaultChecked !== this.props.defaultChecked && isDifferentListLength) {
            this.setState({
                isChecked: this.props.defaultChecked
            })
        }
    }

    render() {
        const { cx, isDisabled, dragSource, isDragging, isImageDragging, id, url, name, onCrop } = this.props
        const { isChecked } = this.state

        const handleOnCrop = event => {
            event.stopPropagation()
            onCrop && onCrop()
        }

        return dragSource(
            <div
                className={cx("tile", {
                    isMoving: isDragging || (isImageDragging && isChecked),
                    isDisabled: isImageDragging && isDisabled,
                    isChecked,
                    isTooltip: name ? name.length > 30 : false
                })}
                onClick={this.handleChange}
            >
                <div
                    className={cx("img", { isChecked })}
                    style={{ backgroundImage: `url(${url + LOAD_THUMBNAIL_IMAGE})` }}
                />
                <p>
                    <span className={cx("name")}>{name}</span>
                    <span className={cx("tooltip")}>{name}</span>
                </p>
                <Checkbox id={`photo${id}`} checked={isChecked} />
                {!!onCrop && (
                    <div className={cx("crop")} onClick={handleOnCrop}>
                        <div className={cx("cropIcon")} />
                    </div>
                )}
            </div>
        )
    }

    handleChange = () => {
        const { id, url, name, triggerImageSelect } = this.props
        const checked = !this.state.isChecked
        this.setState(
            {
                isChecked: checked
            },
            () => triggerImageSelect([{ id, url, name }])
        )
    }
}

function collect(connect, monitor) {
    return {
        dragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

const spec = {
    beginDrag: props => {
        const { setImageDraggingStatus, id, url, name } = props
        setImageDraggingStatus(true)
        return { id, url, name }
    },
    canDrag: props => !(props.isDisabled && props.isImageDragging),
    endDrag: props => props.setImageDraggingStatus(false)
}

export default DragSource(DRAGDROP_ACTION_TRANSFER_IMAGE_PRODUCT, spec, collect)(withStyles(Image, styles))
