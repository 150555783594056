import React, { useCallback } from "react"
import PropTypes from "prop-types"
import {
    H3,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    Panel,
    Button,
    TrashIcon,
    Status,
    EmptyInternalList,
    AlertErrorIcon
} from "@butterfly-frontend/ui"

import { formatPrice } from "helpers/units"
import formikPropTypesFactory from "modules/WmsModule/helpers/formikPropTypesFactory"

import TABLE_COLUMNS from "./tableColumns"
import styles from "./ReturnItemList.module.css"

const ReturnItemList = ({ formik }) => {
    const removeItem = useCallback(
        itemToRemove => {
            formik.setFieldValue("items", formik.values.items.filter(item => item.id !== itemToRemove.id))
        },
        [formik.setFieldValue, formik.values.items]
    )

    return (
        <Panel classes={{ panel: styles.panel }}>
            <div>
                <H3 classes={{ h3: styles.h3 }}>Items</H3>
                {formik.values.items.length > 0 ? (
                    <Table>
                        <TableHead>
                            <TableHeadCell {...TABLE_COLUMNS.name}>PRODUCT NAME</TableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.id}>ID</TableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.condition}>CONDITION</TableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.origin}>ORIGIN</TableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.total}>ITEM TOTAL</TableHeadCell>
                            <TableHeadCell {...TABLE_COLUMNS.remove} />
                        </TableHead>
                        <TableBody>
                            {formik.values.items.map(row => (
                                <TableRow key={row.id} classNames={{ root: styles.tableRow }}>
                                    <TableCell {...TABLE_COLUMNS.name} classes={{ root: styles.nameCell }}>
                                        {row.product.name}
                                    </TableCell>
                                    <TableCell {...TABLE_COLUMNS.id} classes={{ root: styles.grayColorCell }}>
                                        {row.id}
                                    </TableCell>
                                    <TableCell {...TABLE_COLUMNS.condition}>
                                        <Status color={row.condition.color}>{row.condition.name}</Status>
                                    </TableCell>
                                    <TableCell {...TABLE_COLUMNS.origin} classes={{ root: styles.grayColorCell }}>
                                        {row.warehouse.name}
                                    </TableCell>
                                    <TableCell {...TABLE_COLUMNS.total} classes={{ root: styles.grayColorCell }}>
                                        {formatPrice(row.totalPrice)}
                                    </TableCell>
                                    <TableCell {...TABLE_COLUMNS.remove} classes={{ root: styles.removeCell }}>
                                        <Button
                                            variant="flat"
                                            disabled={formik.isSubmitting}
                                            Icon={TrashIcon}
                                            onClick={() => removeItem(row)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <EmptyInternalList text="There are no items to return" Icon={AlertErrorIcon} />
                )}
            </div>
        </Panel>
    )
}

ReturnItemList.propTypes = {
    formik: formikPropTypesFactory({
        items: PropTypes.arrayOf(
            PropTypes.shape({
                product: PropTypes.shape({
                    name: PropTypes.string.isRequired
                }).isRequired,
                id: PropTypes.number.isRequired,
                condition: PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    color: PropTypes.string.isRequired
                }).isRequired,
                warehouse: PropTypes.shape({
                    name: PropTypes.string.isRequired
                }).isRequired,
                totalPrice: PropTypes.number.isRequired
            }).isRequired
        ).isRequired
    })
}

export default ReturnItemList
