import * as yup from "yup"

import { settings as settingsFieldsSchema, product as productSchema } from "../ShowProductLink/schemas"

import { TEXTAREA_MAX_LENGTH, WYSIWYG_MAX_LENGTH } from "constants/index"

export const section = yup.object().shape({
    settings: yup
        .object()
        .shape({
            heading: yup
                .string()
                .max(TEXTAREA_MAX_LENGTH, `Heading may not be greater than ${TEXTAREA_MAX_LENGTH} characters`),
            description: yup
                .string()
                .max(WYSIWYG_MAX_LENGTH, `Description may not be greater than ${WYSIWYG_MAX_LENGTH} characters`)
                .required("Description is required"),
            description_more: yup
                .string()
                .max(WYSIWYG_MAX_LENGTH, `Description more may not be greater than ${WYSIWYG_MAX_LENGTH} characters`),
            ...settingsFieldsSchema
        })
        .required(),
    product: productSchema,
    description_image: yup.object().required("Field is required")
})
