export const getInitials = string => {
    let names = string.split(" "),
        initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
}

export const shortenString = (name = "", maxLength = 40, endOfShortenStringSymbol = "...") => {
    if (name && name.length > maxLength) {
        return name.slice(0, maxLength) + endOfShortenStringSymbol
    }

    return name
}
