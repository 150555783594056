import React from "react"
import PropTypes from "prop-types"

import moment from "moment"

import withStyles from "HOC/withStyles"
import styles from "./styles.module.css"

import { Checkbox, SearchBar, Combobox, DateRangePicker } from "ui"
import comboboxStyles from "./overrides/Combobox.module.css"

const Filters = ({
    cx,
    query,
    setQuery,
    isWithoutImages,
    setWithoutImages,
    dateRange,
    setDateRange,
    brandList,
    brand,
    setBrand
}) => {
    const handleSearch = value => setQuery(value)

    const ranges = { ...dateRange, key: "selection" }

    const handleSetDateRange = data => {
        const formatDate = date => moment(date).format("YYYY-MM-DD")
        const newRanges = {
            startDate: formatDate(data.selection.startDate),
            endDate: formatDate(data.selection.endDate)
        }
        setDateRange(newRanges)
    }
    const handleResetRanges = () => setDateRange({ startDate: null, endDate: null })

    const handleSetWithoutImages = () => setWithoutImages(oldState => !oldState)

    return (
        <div className={cx("root")}>
            <SearchBar handleSearch={handleSearch} value={query} placeholder="Search for products…" />
            <Combobox
                isLabel={false}
                value={brand.label}
                values={brandList
                    .filter(brand => brand.products_in_brand > 0)
                    .map(brand => {
                        return {
                            value: brand.id,
                            label: brand.name
                        }
                    })}
                defaultValue="Brand"
                isLabeledValue={false}
                isClear={true}
                customStyles={comboboxStyles}
                onClick={({
                    event: {
                        target: { value, id }
                    }
                }) => {
                    setBrand({ id, label: value })
                }}
            />
            <DateRangePicker
                ranges={ranges}
                onChange={handleSetDateRange}
                resetRanges={handleResetRanges}
                shownDate={new Date()}
            />
            <Checkbox name="Without photo" checked={isWithoutImages} onChange={handleSetWithoutImages} />
        </div>
    )
}

Filters.propTypes = {
    query: PropTypes.string.isRequired,
    setQuery: PropTypes.func.isRequired,
    isWithoutImages: PropTypes.bool.isRequired,
    setWithoutImages: PropTypes.func.isRequired,
    dateRange: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string
    }).isRequired,
    setDateRange: PropTypes.func.isRequired
}

export default withStyles(Filters, styles)
