import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import { DateRangePicker as WrappedDatePicker } from "react-date-range"

import { withStyles } from "ui/FilterableTable/HOC"
import { AppContext } from "ui/FilterableTable"

import styles from "./DatePicker.css"

class DatePicker extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {context => <DatePickerWithContext {...this.props} context={context} />}
            </AppContext.Consumer>
        )
    }
}

class DatePickerWithContext extends Component {
    static defaultProps = {
        label: "Date"
    }

    render() {
        const { cx, customClass, context } = this.props
        return (
            <div className={[cx("root"), customClass].join(" ")}>
                <WrappedDatePicker
                    onChange={context.changeDate}
                    from={context.data.dateFrom}
                    to={context.data.dateTo}
                />
            </div>
        )
    }
}

export default withRouter(withStyles(styles)(DatePicker))
