import uniq from "lodash/uniq"
import * as constants from "../constants"

const saveUnselectedCalendarsInLocalStorage = unselectedCalendars =>
    window.localStorage.setItem("unselectedCalendars", JSON.stringify(unselectedCalendars))

function calendar(state = {}, action) {
    switch (action.type) {
        case constants.EDIT_CALENDAR_ENTRY_SUCCESS:
            return (() => {
                const { payload } = action

                return {
                    ...state,
                    entries: {
                        ...state.entries,
                        data: state.entries.data.map(entry => (entry.id === payload.data.id ? payload.data : entry))
                    }
                }
            })()
        case constants.GET_CALENDARS_LIST_DATA_START:
            return { ...state, ...{ list: { ...state.list, isLoading: true } } }
        case constants.GET_CALENDARS_LIST_DATA_SUCCESS:
            const {
                payload: { data: calendars }
            } = action

            const unselectedCalendars = state.unselectedCalendars.filter(calendarId =>
                calendars.some(calendar => calendar.id === calendarId)
            )
            const parsedCalendars = calendars.map(calendar => ({
                ...calendar,
                is_selected: !unselectedCalendars.some(calendarId => calendarId === calendar.id)
            }))

            saveUnselectedCalendarsInLocalStorage(unselectedCalendars)

            return {
                ...state,
                ...{
                    list: {
                        isLoading: false,
                        data: {
                            public: parsedCalendars.filter(calendar => calendar.is_public),
                            private: parsedCalendars.filter(calendar => !calendar.is_public)
                        }
                    }
                },
                unselectedCalendars
            }
        case constants.GET_CALENDARS_LIST_DATA_ERROR:
            return { ...state, ...{ list: { ...state.list, isLoading: false } } }
        case constants.DELETE_CALENDAR_SUCCESS:
            return (() => {
                const {
                    payload: { id, isPublic }
                } = action
                const type = isPublic ? "public" : "private"

                return {
                    ...state,
                    list: {
                        ...state.list,
                        data: {
                            ...state.list.data,
                            [type]: state.list.data[type].filter(obj => obj.id !== id)
                        }
                    }
                }
            })()
        case constants.GET_CALENDARS_ENTRIES_DATA_START:
            return (() => {
                const { payload } = action

                return {
                    ...state,
                    ...{
                        entries: {
                            ...state.entries,
                            data: payload.isClearPrevData ? [] : state.entries.data,
                            isLoading: true,
                            timeLastFetch: new Date()
                        }
                    }
                }
            })()
        case constants.GET_CALENDARS_ENTRIES_DATA_SUCCESS:
            return (() => {
                const {
                    payload: {
                        data: { data, meta, startTimeFetching }
                    }
                } = action

                if (startTimeFetching < state.entries.timeLastFetch) {
                    return state
                }

                return {
                    ...state,
                    ...{
                        entries: {
                            isLoading: false,
                            data: data,
                            meta: meta,
                            timeLastFetch: state.entries.timeLastFetch
                        }
                    }
                }
            })()
        case constants.GET_CALENDARS_ENTRIES_DATA_ERROR:
            return { ...state, ...{ entries: { ...state.entries, isLoading: true, data: [] } } }
        case constants.CLEAR_CALENDARS_ENTRIES:
            return { ...state, ...{ entries: { ...state.entries, isLoading: false, data: [], timeLastFetch: null } } }
        case constants.GET_MORE_CALENDARS_ENTRIES_DATA_START:
            return {
                ...state,
                ...{
                    entries: {
                        ...state.entries,
                        isLoadingMore: true
                    }
                }
            }
        case constants.GET_MORE_CALENDARS_ENTRIES_DATA_SUCCESS:
            const {
                payload: { data, meta }
            } = action

            return {
                ...state,
                ...{
                    entries: {
                        ...state.entries,
                        isLoadingMore: false,
                        data: [...state.entries.data, ...data],
                        meta: meta
                    }
                }
            }
        case constants.CREATE_CALENDAR_ENTRY_SUCCESS:
            return {
                ...state,
                entries: {
                    ...state.entries,
                    data: [...state.entries.data, action.payload.data]
                }
            }
        case constants.DELETE_CALENDAR_ENTRY_SUCCESS:
            return (() => {
                const {
                    payload: { id }
                } = action

                return {
                    ...state,
                    entries: {
                        ...state.entries,
                        data: state.entries.data.filter(obj => obj.id !== id)
                    }
                }
            })()
        case constants.SELECT_CALENDAR_TYPE:
            return (() => {
                const {
                    payload: { id, type }
                } = action

                const unselectedCalendars = uniq(
                    state.unselectedCalendars.filter(calendarId =>
                        id === null
                            ? !state.list.data[type].some(calendar => calendarId === calendar.id)
                            : calendarId !== id
                    )
                )

                saveUnselectedCalendarsInLocalStorage(unselectedCalendars)

                return {
                    ...state,
                    list: {
                        ...state.list,
                        data: {
                            ...state.list.data,
                            [type]: state.list.data[type].map(obj =>
                                obj.id === id || id === null ? { ...obj, is_selected: true } : obj
                            )
                        }
                    },
                    unselectedCalendars
                }
            })()
        case constants.UNSELECT_CALENDAR_TYPE:
            return (() => {
                const {
                    payload: { id, type }
                } = action

                const unselectedCalendars = uniq(
                    id === null
                        ? [...state.unselectedCalendars, ...state.list.data[type].map(calendar => calendar.id)]
                        : [...state.unselectedCalendars, id]
                )

                saveUnselectedCalendarsInLocalStorage(unselectedCalendars)

                return {
                    ...state,
                    list: {
                        ...state.list,
                        data: {
                            ...state.list.data,
                            [type]: state.list.data[type].map(obj =>
                                obj.id === id || id === null ? { ...obj, is_selected: false } : obj
                            )
                        }
                    },
                    unselectedCalendars
                }
            })()
        case constants.SET_ENTRY_FILTERS:
            return (() => {
                const { payload } = action

                window.localStorage.setItem("calendarFilters", JSON.stringify(payload))

                return { ...state, entryFilters: payload }
            })()
        case constants.SET_ENTRY_QUERY:
            return (() => {
                const { payload } = action

                return { ...state, entryQuery: payload }
            })()
        case constants.SET_ENTRY_PARAMS:
            return (() => {
                const { payload } = action

                return { ...state, entryParams: payload }
            })()
        case constants.SET_DISPLAY_ONLY_CALENDAR:
            return (() => {
                const { payload: id } = action

                const unselectedCalendars = [...state.list.data.public, ...state.list.data.private]
                    .filter(calendar => calendar.id !== id)
                    .map(calendar => calendar.id)

                saveUnselectedCalendarsInLocalStorage(unselectedCalendars)

                return {
                    ...state,
                    list: {
                        ...state.list,
                        data: {
                            public: state.list.data.public.map(calendar => ({
                                ...calendar,
                                is_selected: calendar.id === id
                            })),
                            private: state.list.data.private.map(calendar => ({
                                ...calendar,
                                is_selected: calendar.id === id
                            }))
                        }
                    },
                    unselectedCalendars
                }
            })()
        default:
            return state
    }
}

export default calendar
