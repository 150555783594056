import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getPostsStart() {
    return {
        type: constants.GET_POSTS_START
    }
}
export function getPostsSuccess(data) {
    return {
        type: constants.GET_POSTS_SUCCESS,
        payload: {
            data
        }
    }
}
export function getPostsError(error) {
    return {
        type: constants.GET_POSTS_ERROR,
        payload: {
            error
        }
    }
}
export function getPosts({ length = 32, page = 0, tagId, isBlog, isPortfolio, filterValue, searchParams }) {
    let path = "/news"
    if (isBlog) {
        path = "/posts"
    }
    if (isPortfolio) {
        path = "/realizations"
    }

    return dispatch => {
        dispatch(getPostsStart())
        fetch
            .get(
                `${path}?length=${length}&page=${page}${tagId ? "&tag_id=" + tagId : ""}${
                    filterValue === true || filterValue === false ? "&verified=" + filterValue : ""
                }${searchParams ? searchParams : ""}`
            )
            .then(data => dispatch(getPostsSuccess(data)))
            .catch(error => dispatch(getPostsError(error)))
    }
}

export function getPostStart() {
    return {
        type: constants.GET_POST_START
    }
}
export function getPostSuccess(data) {
    return {
        type: constants.GET_POST_SUCCESS,
        payload: {
            data
        }
    }
}
export function getPostError(error) {
    return {
        type: constants.GET_POST_ERROR,
        payload: {
            error
        }
    }
}
export function getPost(id) {
    return dispatch => {
        dispatch(getPostStart())
        fetch
            .get(`/news/${id}`)
            .then(data => dispatch(getPostSuccess(data)))
            .catch(error => dispatch(getPostError(error)))
    }
}

export function clearPost() {
    return {
        type: constants.CLEAR_POST
    }
}
