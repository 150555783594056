import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

import withStyles from "HOC/withStyles"

import styles from "./AccountDropdown.css"

import userIcon from "assets/ico-user.svg"
import settingsIcon from "assets/ico-settings.svg"
import logoutIcon from "assets/ico-logout.svg"

const AccountDropdown = ({ cx, loginStore }) => {
    const history = useHistory()
    const timeoutId = useRef(null)

    const [isOpen, setIsOpen] = useState(false)

    const toggleIsOpen = () => setIsOpen(wasOpen => !wasOpen)
    const closeOptions = () => setIsOpen(false)

    const onFocus = () => clearTimeout(timeoutId.current)
    const onBlur = () => (timeoutId.current = setTimeout(closeOptions))
    const onClickOption = action => {
        action()
        closeOptions()
    }

    const { userAvatar, userFirstName, userLastName, email } = loginStore

    const userInitials = `${(userFirstName || "").charAt(0).toUpperCase()}${(userLastName || "")
        .charAt(0)
        .toUpperCase()}`
    const avatarStyle = { backgroundImage: !!userAvatar && `url('${userAvatar}')` }

    return (
        <div className={cx("root", { isOpen })} onFocus={onFocus} onBlur={onBlur}>
            <button className={cx("label")} onClick={toggleIsOpen}>
                <span className={cx("avatar")} style={avatarStyle}>
                    {!userAvatar && userInitials}
                </span>
            </button>
            {isOpen && (
                <ul className={cx("details")}>
                    <li className={cx("user")}>
                        <span className={cx("avatar")} style={avatarStyle}>
                            {!userAvatar && userInitials}
                        </span>
                        <p className={cx("userInfo")}>
                            <span className={cx("userName")}>
                                {userFirstName} {userLastName}
                            </span>
                            <span className={cx("userEmail")}>{email}</span>
                        </p>
                    </li>
                    <li className={cx("separator")} />
                    <li>
                        <button onClick={() => onClickOption(() => {})} disabled>
                            <img src={userIcon} alt="ico" /> My profile
                        </button>
                    </li>
                    <li>
                        <button onClick={() => onClickOption(() => history.push("/profile/settings"))}>
                            <img src={settingsIcon} alt="ico" /> Account settings
                        </button>
                    </li>
                    <li className={cx("separator")} />
                    <li>
                        <button onClick={() => onClickOption(() => history.push("/logout"))}>
                            <img src={logoutIcon} alt="ico" /> Logout
                        </button>
                    </li>
                </ul>
            )}
        </div>
    )
}

AccountDropdown.defaultProps = {
    className: "",
    options: []
}

AccountDropdown.propTypes = {
    loginStore: PropTypes.object.isRequired
}

export default withStyles(AccountDropdown, styles)
