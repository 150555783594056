import React, { useMemo } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import {
    Button,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    ClockIcon,
    TrashIcon,
    ListIcon,
    EmptyInternalList,
    SnakeLoader
} from "@butterfly-frontend/ui"
import { formatDate } from "helpers/date"

import styles from "./PricingList.module.css"

const PricingList = ({ listData, isLoading, onRemoveRow, showPrice, emptyListMessage = "There is no data." }) => {
    const tableColumns = useMemo(
        () => ({
            effectDate: { minWidth: 70, width: showPrice ? "30%" : "45%" },
            price: { minWidth: 20, width: "15%" },
            value: { minWidth: 20, width: "15%" },
            date: { minWidth: 90, width: "20%" },
            user: { minWidth: 35, width: "15%" },
            action: { minWidth: 15, width: "5%" }
        }),
        [showPrice]
    )

    return (
        <Table>
            <TableHead>
                <TableHeadCell {...tableColumns.effectDate}>EFFECT DATE</TableHeadCell>
                <TableHeadCell {...tableColumns.value}>VALUE</TableHeadCell>
                {showPrice && <TableHeadCell {...tableColumns.value}>PRICE</TableHeadCell>}
                <TableHeadCell {...tableColumns.date}>DATE</TableHeadCell>
                <TableHeadCell {...tableColumns.user}>USER</TableHeadCell>
                {onRemoveRow && <TableHeadCell {...tableColumns.action} />}
            </TableHead>
            <TableBody>
                {listData.length === 0 && isLoading && <SnakeLoader />}
                {!isLoading && listData.length === 0 && (
                    <EmptyInternalList
                        text={emptyListMessage}
                        Icon={ListIcon}
                        classes={{ text: styles.emptyListInfo }}
                    />
                )}
                {listData.map(element => (
                    <TableRow key={element.id} classNames={{ root: styles.row }}>
                        <TableCell {...tableColumns.effectDate}>{formatDate(element.effect_date)}</TableCell>
                        <TableCell
                            classes={{ root: classNames([styles.textGray, styles.valueCell]) }}
                            {...tableColumns.value}
                        >
                            {element.value_percentage ? `${element.value_percentage}%` : "-"}
                        </TableCell>
                        {showPrice && (
                            <TableCell classes={{ root: styles.textGray }} {...tableColumns.price}>
                                $ {element.price}
                            </TableCell>
                        )}
                        <TableCell classes={{ root: styles.textGray }} {...tableColumns.date}>
                            <ClockIcon className={styles.clockIcon} />
                            {formatDate(element.created_at)}
                        </TableCell>
                        <TableCell classes={{ root: styles.textGray }} {...tableColumns.user}>
                            {element.created_by ? element.created_by.full_name : "-"}
                        </TableCell>
                        {onRemoveRow && new Date(element.effect_date) > new Date() && (
                            <TableCell {...tableColumns.action}>
                                <Button variant="flat" size="normal" onClick={() => onRemoveRow(element)}>
                                    <TrashIcon className={styles.trashIcon} />
                                </Button>
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

PricingList.propTypes = {
    listData: PropTypes.arrayOf(
        PropTypes.shape({
            effect_date: PropTypes.string.isRequired,
            value_percentage: PropTypes.string,
            created_at: PropTypes.string.isRequired,
            created_by: PropTypes.shape({
                full_name: PropTypes.string.isRequired
            })
        })
    ),
    onRemoveRow: PropTypes.func,
    emptyListMessage: PropTypes.string,
    showPrice: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool
}

export default PricingList
