import React, { useState, useEffect } from "react"

import withStyles from "HOC/withStyles"

import styles from "./Notification.css"

const Notification = props => {
    const { cx, isInSelection } = props

    let timeout = null
    const [initialIsInSelection, setInitialIsInSelection] = useState(isInSelection)
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        return () => {
            clearInterval(timeout)
        }
    }, [])

    useEffect(() => {
        if (initialIsInSelection === !isInSelection) {
            setIsShow(true)
            setInitialIsInSelection(!initialIsInSelection)
            timeout = setTimeout(() => {
                setIsShow(false)
            }, 4000)
        }
    }, [isInSelection])

    if (!isShow) {
        return null
    }

    if (!isInSelection) {
        return <div className={cx("root", "fading")}>Product removed from Selection</div>
    }

    return <div className={cx("root", "fading")}>Product added to Selection</div>
}

export default withStyles(Notification, styles)
