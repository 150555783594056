import React, { Component, Fragment } from "react"

import withStyles from "HOC/withStyles"
import styles from "./Gallery.css"
import close from "assets/close.svg"
import left from "assets/gallery_left.svg"
import right from "assets/gallery_right.svg"
import { ReloadableImageWrap } from "ui"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css?global"
import "slick-carousel/slick/slick-theme.css?global"

class Gallery extends Component {
    state = {
        currentImageIndex: 0,
        isImageChanging: false
    }

    componentDidMount() {
        const { initImageIndex } = this.props

        if (initImageIndex) {
            this.setState({ currentImageIndex: initImageIndex })
            this.slick.slickGoTo(initImageIndex, true)
        }
    }

    handleNext = () => {
        this.slick && this.slick.slickNext()
    }

    handlePrevious = () => {
        this.slick && this.slick.slickPrev()
    }

    handleAfterChange = index => {
        this.setState({
            currentImageIndex: index
        })
    }

    showScrollBar() {
        document.getElementsByTagName("body")[0].style.overflowY = "auto"
        document.getElementsByTagName("body")[0].style.position = "static"
    }

    render() {
        const { cx, images, handleHide } = this.props
        const { currentImageIndex } = this.state

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        }

        return (
            <Fragment>
                <div className={cx("modal-background")} ref={node => (this.node = node)}>
                    <div className={cx("hidden")}>
                        {images.map((item, index) => {
                            return <img key={index} src={item.url + "?type=gallery"} alt={item.title} />
                        })}
                    </div>
                    <div className={cx("root")}>
                        <img
                            src={close}
                            alt="close"
                            className={cx("close-icon")}
                            onClick={() => {
                                this.showScrollBar()
                                handleHide()
                                document.removeEventListener("keydown", this.handleKeyboardArrows, false)
                            }}
                        />
                        {images.length > 1 && (
                            <img className={cx("left")} src={left} alt="" onClick={this.handlePrevious} />
                        )}
                        {images.length > 1 && (
                            <img className={cx("right")} src={right} alt="" onClick={this.handleNext} />
                        )}

                        <Slider
                            {...settings}
                            className={cx("slick")}
                            ref={ref => (this.slick = ref)}
                            afterChange={this.handleAfterChange}
                            initialSlide={currentImageIndex}
                        >
                            {images &&
                                images.map(({ url }, index) => {
                                    return (
                                        <div key={index}>
                                            <ReloadableImageWrap image={url}>
                                                {({ image }) => (
                                                    <div
                                                        className={cx("image")}
                                                        style={{
                                                            backgroundImage: image
                                                                ? `url('${image}?type=gallery')`
                                                                : null
                                                        }}
                                                    />
                                                )}
                                            </ReloadableImageWrap>
                                        </div>
                                    )
                                })}
                        </Slider>
                        <span className={cx("counter")}>
                            {currentImageIndex + 1} / {images.length}
                        </span>
                    </div>
                </div>
            </Fragment>
        )
    }

    handleKeyboardArrows = event => {
        switch (event.keyCode) {
            case 37:
                this.handlePrevious()
                event.preventDefault()
                break
            case 39:
                this.handleNext()
                event.preventDefault()
                break
            default:
                break
        }
    }
}

export default withStyles(Gallery, styles)
