import React from "react"
import PropTypes from "prop-types"

import { DetailsCard, Status, Button, EditIcon } from "@butterfly-frontend/ui"
import { StockItemStatus } from "modules/WmsModule/components"

import styles from "./HeroDetails.module.css"

const HeroDetails = ({ onEdit, stockItem }) => {
    const {
        id,
        status,
        condition: { product_condition },
        warehouse
    } = stockItem

    return (
        <div className={styles.details}>
            <DetailsCard header="ID" classes={{ root: styles.idCard }}>
                {id}
            </DetailsCard>
            <DetailsCard header="WAREHOUSE">{warehouse.name}</DetailsCard>
            <DetailsCard header="CONDITION">
                <Status color={product_condition.color}>{product_condition.name}</Status>
            </DetailsCard>
            <DetailsCard header="STATUS">
                <StockItemStatus stockItemStatus={status} />
            </DetailsCard>
            <Button variant="flat" onClick={onEdit} Icon={EditIcon} size="small">
                Edit
            </Button>
        </div>
    )
}

HeroDetails.propTypes = {
    stockItem: PropTypes.shape({
        id: PropTypes.number.isRequired,
        condition: PropTypes.shape({
            product_condition: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                color: PropTypes.string.isRequired,
                require_images: PropTypes.bool.isRequired,
                require_reason: PropTypes.bool.isRequired,
                type: PropTypes.string
            }).isRequired
        }).isRequired,
        warehouse: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired,
        status: PropTypes.string.isRequired
    }).isRequired,
    onEdit: PropTypes.func.isRequired
}

export default HeroDetails
