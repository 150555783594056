import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    isLoading: false,
    isLoaded: false,
    isError: false,
    fetchStatus: fetchStatus.initialState()
}

function list(state = initialState, action) {
    switch (action.type) {
        case constants.GET_ASSISTANCES_START:
            return {
                ...state,
                isLoading: true,
                isLoaded: false,
                isError: false,
                fetchStatus: fetchStatus.requestState()
            }

        case constants.GET_ASSISTANCES_SUCCESS:
            let assistancesList = action.payload.data.data ? action.payload.data.data : []
            let assistancesMeta = action.payload.data.meta ? action.payload.data.meta : {}

            if (action.payload.data.assistances && action.payload.data.assistances.data) {
                assistancesList = action.payload.data.assistances.data
                assistancesMeta = action.payload.data.assistances.meta
            }

            return {
                ...state,
                data: assistancesList,
                meta: assistancesMeta,
                isLoading: false,
                isLoaded: true,
                fetchStatus: fetchStatus.successState()
            }

        case constants.GET_ASSISTANCES_ERROR:
            return {
                ...state,
                data: [],
                meta: {},
                isLoading: false,
                isLoaded: false,
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }

        case constants.RESET_ASSISTANCES:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default list
