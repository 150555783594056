import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import { render } from "react-dom"
import { Helmet } from "react-helmet"
import * as Sentry from "@sentry/react"
import { CaptureConsole } from "@sentry/integrations"
import { Integrations } from "@sentry/tracing"
import "./index.css"
import store from "store/index"
import { Provider } from "react-redux"
import { QueryClientProvider } from "react-query"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import App from "./App"
import LoginPage from "./components/LoginPage/index"
import Logout from "./components/Logout"
import NotFoundPage from "./components/NotFoundPage"
import { ShareProject } from "./modules/ProjectsModule/pages"

import { unregister } from "./registerServiceWorker"

import { ScraperModuleRoutes } from "modules/ScraperModule/routes"

import queryClient from "./queryClient"
import env from "env"

import "@butterfly-frontend/ui/dist/dts/index.css"

if (env.SENTRY_DSN_URL) {
    Sentry.init({
        dsn: env.SENTRY_DSN_URL,
        integrations: [
            new Integrations.BrowserTracing(),
            new CaptureConsole({
                levels: ["error"]
            })
        ],
        initialScope: {
            tags: { env: env.ENVIRONMENT }
        },
        tracesSampleRate: 1.0
    })
}

const renderModule = ({ store, Module, mountId }) => {
    if (!mountId || !document.getElementById(mountId)) {
        return
    }

    render(
        <Provider store={store}>
            {env.REACT_APP_LUCKYORANGE_SITE_ID && (
                <Helmet>
                    <script
                        async
                        defer
                        src={`https://tools.luckyorange.com/core/lo.js?site-id=${env.REACT_APP_LUCKYORANGE_SITE_ID}`}
                    ></script>
                </Helmet>
            )}
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Switch>
                        <Route path="/login" render={() => <LoginPage />} />
                        <Route path="/set-password/:token" render={() => <LoginPage pageType={LoginPage.SET_TYPE} />} />
                        <Route
                            path="/reset-password/:token"
                            render={() => <LoginPage pageType={LoginPage.RESET_TYPE} />}
                        />
                        <Route path="/forgot-password" render={() => <LoginPage pageType={LoginPage.REMIND_TYPE} />} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/404" component={NotFoundPage} />
                        <Route path="/share-project/:token" component={ShareProject} />
                        {Module ? <Module /> : <Route path="/" component={App} />}
                    </Switch>
                </Router>
            </QueryClientProvider>
        </Provider>,
        document.getElementById(mountId)
    )
}
renderModule({ store, mountId: "root" })

renderModule({
    store,
    Module: () => <Route path="/" render={props => <ScraperModuleRoutes {...props} />} />,
    mountId: "root-scraper"
})

unregister()
