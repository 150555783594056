import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import _get from "lodash/get"

import {
    Table,
    TableHead,
    TableBody,
    TableHeadCell,
    TableCell,
    TableRow,
    EmptyInternalList,
    ListIcon
} from "@butterfly-frontend/ui"
import { PrintBoxLabels } from "modules/WmsModule/components"

import TABLE_COLUMNS from "./tableColumns"

import styles from "./StockItemDetailsBoxList.module.css"

const StockItemDetailsBoxList = props => {
    const { boxes, stockItemId, compactView = false, showPrintButton = false, className, customColumns } = props

    const columns = customColumns || TABLE_COLUMNS

    return (
        <div className={classNames(styles.root, className, { [styles.compactView]: compactView })}>
            {!compactView && (
                <div className={styles.headerContainer}>
                    <h2 className={styles.title}>Item pack</h2>
                    {showPrintButton && stockItemId && <PrintBoxLabels boxes={boxes} stockItemId={stockItemId} />}
                </div>
            )}
            {boxes.length > 0 ? (
                <Table>
                    {!compactView && (
                        <TableHead>
                            <TableHeadCell {...columns.NAME}>NAME</TableHeadCell>
                            <TableHeadCell {...columns.ID}>ID</TableHeadCell>
                            <TableHeadCell {...columns.LOCATION}>LOCATION</TableHeadCell>
                            <TableHeadCell {...columns.LABEL} />
                        </TableHead>
                    )}
                    <TableBody>
                        {boxes.map((box, index) => (
                            <TableRow
                                key={box.id}
                                classNames={{ root: classNames(styles.row, { [styles.selectedRow]: box.selected }) }}
                            >
                                <TableCell {...columns.NAME}>Box {index + 1}</TableCell>
                                <TableCell {...columns.ID} classes={{ root: { [styles.previewCell]: compactView } }}>
                                    {box.id}
                                </TableCell>
                                {!compactView && (
                                    <TableCell {...columns.LOCATION}>{_get(box, "location.name")}</TableCell>
                                )}
                                <TableCell
                                    {...columns.LABEL}
                                    width={compactView ? columns.LABEL.compactWidth : columns.LABEL.width}
                                >
                                    <span
                                        className={classNames([
                                            styles.rectangleLabel,
                                            { [styles.previewCell]: compactView }
                                        ])}
                                        title={box.label}
                                    >
                                        {box.label}
                                    </span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            ) : (
                <EmptyInternalList text="It has no Item pack" Icon={ListIcon} />
            )}
        </div>
    )
}

const columnPropTypes = PropTypes.shape({
    minWidth: PropTypes.number,
    width: PropTypes.string,
    classes: PropTypes.shape({
        root: PropTypes.string
    })
}).isRequired

StockItemDetailsBoxList.propTypes = {
    boxes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string.isRequired,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string
            }),
            selected: PropTypes.bool
        })
    ).isRequired,
    stockItemId: PropTypes.number,
    compactView: PropTypes.bool,
    showPrintButton: PropTypes.bool,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    customColumns: PropTypes.shape({
        NAME: columnPropTypes,
        ID: columnPropTypes,
        LOCATION: columnPropTypes,
        LABEL: PropTypes.shape({
            minWidth: PropTypes.number,
            width: PropTypes.string,
            compactWidth: PropTypes.string,
            classes: PropTypes.shape({
                root: PropTypes.string
            })
        }).isRequired
    })
}

export default StockItemDetailsBoxList
