import React, { Fragment, useState, useMemo } from "react"
import { useDispatch } from "react-redux"

import withStyles from "HOC/withStyles"
import { SORT_OPTIONS } from "./configs"
import { toApiDate } from "helpers/date"
import { useIsTablet } from "helpers/rwd"
import { changePagination } from "actions/product"
import { Checkbox, DateRangePicker, SearchBar, Pagination, SelectWithSearch, Select, EmptyList, Button } from "ui"
import { Header, MoreFilters } from "ui/FilterableTable"
import Modal from "components/Modal/Modal"
import ReviewModal from "components/Reusable/ReviewModal"
import Product from "modules/ProductsModule/pages/List/components/Product"
import Skeleton from "components/Skeleton"
import Loader from "components/Reusable/Loader/Loader"
import ListItem from "modules/ProductsModule/pages/List/components/ListItem"
import FilterList from "./components/FilterList"
import FilterCount from "./components/FilterCount"
import AddToSelectionButtonContainer from "modules/SelectionsWidgetModule/components/AddToSelectionButtonContainer"
import SelectionNotification from "modules/SelectionsWidgetModule/components/Notification"

import styles from "./List.css"
import stylesHeader from "./overrides/Header.css"
import stylesSearchBar from "./overrides/SearchBar.css"
import stylesMoreFilters from "./overrides/MoreFilters.css"
import stylesDateRangePicker from "./overrides/DateRangePicker.css"
import stylesCheckbox from "./overrides/Checkbox.css"
import stylesPagination from "./overrides/Pagination.css"
import stylesEmptyList from "./overrides/EmptyList.css"
import stylesEmptyListButton from "./overrides/EmptyListButton.css"
import stylesSelectPerPage from "./overrides/SelectPerPage.css"
import { useSelector } from "react-redux"

import calendarIcon from "assets/calendar.svg"
import productIcon from "assets/ico-projects.svg"

const List = props => {
    const {
        cx,
        dateRangeHandleChange: setDateRangeInStore,
        setDateRange,
        filterIsOpen,
        products,
        checkedProducts,
        setCheckedProducts
    } = props

    const dispatch = useDispatch()
    const [hasSecondChooseDateRange, setHasSecondChooseDateRange] = useState(false)

    const isTablet = useIsTablet()

    function checkFilter(filter) {
        return (
            (typeof filter === "string" && filter === "") ||
            (Array.isArray(filter) && filter.length === 0) ||
            (typeof filter === "object" &&
                !Array.isArray(filter) &&
                Object.keys(filter).reduce((acc, key) => acc && !filter[key], true))
        )
    }

    const selected = {
        ...useSelector(state => state.db.products.filters.filters)
    }

    const selectedCategories = useSelector(state => state.db.products.filters.categories)
    const count =
        Object.keys(selected).reduce((acc, key) => (checkFilter(selected[key]) ? 0 : 1) + acc, 0) +
        (selectedCategories.length > 0 ? 1 : 0)

    const handleSelectDate = date => {
        const apiDate = { from: date.from ? toApiDate(date.from) : "", to: date.to ? toApiDate(date.to) : "" }
        setDateRange(apiDate)

        if (!hasSecondChooseDateRange) {
            setHasSecondChooseDateRange(true)
            return
        }

        setHasSecondChooseDateRange(false)
        setDateRangeInStore(apiDate)
    }

    const checkedVisibleProducts = useMemo(() => {
        const visibleProductIds = products.data.map(product => product.id)

        return checkedProducts.filter(product => visibleProductIds.includes(product.id))
    }, [checkedProducts, products.data])

    const setCheckedAllVisibleProducts = () => {
        const visibleProductIds = products.data.map(product => product.id)

        if (checkedVisibleProducts.length === 0) {
            return setCheckedProducts([...checkedProducts, ...products.data])
        }

        setCheckedProducts(checkedProducts.filter(product => !visibleProductIds.includes(product.id)))
    }

    return (
        <Fragment>
            {renderHeader()}
            {isTablet && filterIsOpen && <div className={cx("filterBackdrop")}></div>}

            <div className={cx("contentContainer", { filterIsOpen, isTablet })}>
                <div className={cx("sidebarContainer")}>
                    <div className={cx("sidebar")}>{renderFilters()}</div>
                </div>
                <div className={cx("listContainer")}>
                    <Skeleton
                        fetchStatus={products.fetchStatus}
                        component={
                            <div className={cx("loader")}>
                                <Loader />
                            </div>
                        }
                        render={() => <Fragment>{renderList()}</Fragment>}
                    />
                </div>
            </div>

            {renderReviewModal()}
        </Fragment>
    )

    function renderHeader() {
        const {
            filterIsOpen,
            filterInitialSearchPhrase,
            filterInitialSearchPhraseRef,
            filterSetSearchPhrase,
            filterHandleFilterToggle,
            dateRange,
            setDateRange,
            dateRangeHandleChange: setDateRangeInStore
        } = props

        return (
            <Header customStyles={stylesHeader}>
                <SearchBar
                    value={filterInitialSearchPhrase}
                    placeholder="Search for product..."
                    valueRef={filterInitialSearchPhraseRef}
                    handleSearch={filterSetSearchPhrase}
                    customStyles={stylesSearchBar}
                />
                <div className={cx("searchBarFilter")}>
                    <DateRangePicker
                        ranges={{ startDate: dateRange.from, endDate: dateRange.to }}
                        onChange={({ range1: { startDate: from, endDate: to } }) => handleSelectDate({ from, to })}
                        resetRanges={() => {
                            const apiDate = { from: "", to: "" }
                            setDateRange(apiDate)
                            setDateRangeInStore(apiDate)
                        }}
                        format="DD MMM YYYY"
                        title="Any date added"
                        icon={calendarIcon}
                        customStyles={stylesDateRangePicker}
                    />
                </div>
                <div className={cx("searchBarFilter")}>
                    <MoreFilters
                        isVisible={filterIsOpen}
                        toggleVisible={filterHandleFilterToggle}
                        moreFiltersText={
                            <span className={cx("filterLabelContainer")}>
                                <span>Filters</span>
                                <FilterCount count={count} customStyles={{ count: styles.filterCount }} />
                            </span>
                        }
                        customStyles={stylesMoreFilters}
                    />
                </div>
            </Header>
        )
    }

    function renderFilters() {
        const { filterHandleFilterToggle } = props

        return <FilterList filterHandleFilterToggle={filterHandleFilterToggle} />
    }

    function renderList() {
        const { products, handleClearAll } = props

        if (!products.data.length) {
            return (
                <EmptyList customStyles={stylesEmptyList} message="Sorry, no results found!" icon={productIcon}>
                    <div className={cx("emptyListActions")}>
                        <Button
                            customStyles={stylesEmptyListButton}
                            className={["first-button", "white"]}
                            label="Back to All Products"
                            onClick={() => handleClearAll()}
                        />
                    </div>
                </EmptyList>
            )
        }

        return (
            <Fragment>
                {renderListHeader()}
                {renderListView()}
                {renderPagination()}
            </Fragment>
        )
    }

    function renderListHeader() {
        const { products, listViewType, listViewTypeHandleChange } = props

        return (
            <header className={cx("listHeaderContainer")}>
                <div className={cx("listHeaderSide")}>
                    <div className={cx("listHeaderMeta")}>
                        <b>
                            {products.meta.from}-{products.meta.to}
                        </b>{" "}
                        over {products.meta.total} results
                    </div>
                </div>
                <div className={cx("listHeaderSide")}>
                    <div className={cx("listHeaderSideItem")}>
                        <span className={cx("listHeaderLabel")}>View:</span>
                        <div className={cx("listHeaderValue")}>
                            <div
                                className={cx("buttonViewType", { current: listViewType === "tiles" })}
                                onClick={() => listViewTypeHandleChange("tiles")}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <g fill="none" fillRule="evenodd">
                                        <path d="M3 3h18v18H3z" />
                                        <path
                                            className={cx("withFill")}
                                            fillRule="nonzero"
                                            d="M6 11h5V6H6v5zm7 0V6h5v5h-5zm-7 7v-5h5v5H6zm7 0v-5h5v5h-5z"
                                        />
                                    </g>
                                </svg>
                            </div>
                            <div
                                className={cx("buttonViewType", { current: listViewType === "list" })}
                                onClick={() => listViewTypeHandleChange("list")}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <g fill="none" fillRule="evenodd">
                                        <path d="M3 3h18v18H3z" />
                                        <path
                                            className={cx("withFill")}
                                            fillRule="nonzero"
                                            d="M5 9V6h3v3H5zm0 4.5v-3h3v3H5zM5 18v-3h3v3H5zm4-3h10v3H9v-3zm0-4.5h10v3H9v-3zM9 6h10v3H9V6z"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>

                    {renderSelects()}
                </div>
            </header>
        )
    }

    function renderSelects() {
        const { filterSortingIndex, filterSortingHandleChange, perPage, setPerPage } = props

        return (
            <div className={cx("listHeaderSideItem")}>
                <span className={cx("listHeaderLabel")}>Sort By:</span>
                <div className={cx("listHeaderValue")}>
                    <SelectWithSearch
                        values={SORT_OPTIONS.map((element, index) => {
                            return {
                                value: index,
                                label: element.display
                            }
                        })}
                        value={((SORT_OPTIONS[filterSortingIndex] || {}).display || "").toString()}
                        setValue={({ value }) => filterSortingHandleChange(value)}
                        withoutFetch
                        isClearBlocked
                    />
                </div>
                <span className={cx("listHeaderLabel", "ml")}>Per Page:</span>
                <div className={cx("listHeaderValue")}>
                    <Select
                        isClearBlocked
                        value={perPage.toString()}
                        values={[20, 50, 100, 500].map(number => ({ value: number, label: number }))}
                        setValue={({ value }) => setPerPage(value)}
                        customStyles={stylesSelectPerPage}
                    />
                </div>
            </div>
        )
    }

    function renderPagination() {
        const { currentPage, products } = props

        return (
            <Pagination
                currentPage={currentPage}
                pages={products.meta.last_page || 1}
                onChange={page => {
                    dispatch(changePagination(page))
                }}
                customStyles={stylesPagination}
            />
        )
    }

    function renderListView() {
        const { products, listViewType, checkedProducts, handleDelete, handleEdit, handleAddToSelection } = props

        if ("list" === listViewType) {
            return (
                <div className={cx("listWrapper")}>
                    <div className={cx("list", "viewList")}>
                        <ListItem>
                            {({ cx }) => (
                                <div className={cx("listItem", "headerListItem")}>
                                    <div className={cx("leftContainer")}>
                                        <div className={cx("checkboxContainer")}>
                                            <Checkbox
                                                checked={checkedVisibleProducts.length > 0}
                                                isMinusIcon={
                                                    checkedVisibleProducts.length &&
                                                    checkedVisibleProducts.length !== products.data.length
                                                }
                                                onChange={setCheckedAllVisibleProducts}
                                                customStyles={stylesCheckbox}
                                            />
                                        </div>
                                        <div className={cx("nameContainer")}>
                                            <div className={cx("name")}>
                                                <span>Product Name</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx("brandContainer")}>
                                        <span>Vendor</span>
                                    </div>
                                    <div className={cx("inStockContainer")}>
                                        <span>In Stock</span>
                                    </div>
                                    <div className={cx("priceContainer")}>
                                        <span>Retail Price</span>
                                    </div>
                                </div>
                            )}
                        </ListItem>

                        {products.data.map((product, index) => {
                            const isChecked = checkedProducts.some(({ id: productId }) => product.id === productId)

                            return (
                                <Product
                                    key={product.id}
                                    isChecked={isChecked}
                                    checkProduct={handleChange}
                                    product={product}
                                    deleteProduct={handleDelete}
                                    editProduct={handleEdit}
                                    addToSelection={handleAddToSelection}
                                >
                                    {props => <ListItem {...props} index={index} />}
                                </Product>
                            )
                        })}
                    </div>
                </div>
            )
        }

        return (
            <div className={cx("listTileWrapper")}>
                <div className={cx("selectAll")}>
                    <Checkbox
                        checked={checkedVisibleProducts.length > 0}
                        isMinusIcon={
                            checkedVisibleProducts.length && checkedVisibleProducts.length !== products.data.length
                        }
                        onChange={setCheckedAllVisibleProducts}
                        name={checkedVisibleProducts.length === 0 ? "Select visible" : "Unselect visible"}
                    />
                </div>
                <div className={cx("list", "viewTiles")}>
                    {products.data.map(product => (
                        <div key={product.id} className={cx("listItem")}>
                            <AddToSelectionButtonContainer product={product}>
                                {({ fetchStatus, isInSelection }) => (
                                    <div style={{ position: "relative" }}>
                                        <Product
                                            isChecked={checkedProducts.some(
                                                ({ id: productId }) => product.id === productId
                                            )}
                                            nameMaxLength={32}
                                            checkProduct={handleChange}
                                            product={product}
                                            deleteProduct={handleDelete}
                                            editProduct={handleEdit}
                                            addToSelection={handleAddToSelection}
                                        />
                                        {fetchStatus.isLoaded && (
                                            <SelectionNotification isInSelection={isInSelection} />
                                        )}
                                    </div>
                                )}
                            </AddToSelectionButtonContainer>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    function renderReviewModal() {
        const {
            fastReviewIsModalOpen,
            fastReviewIsSaving,
            fastReviewFileData,
            fastReviewHandleHideModal,
            fastReviewHandleProductsUpdate
        } = props

        if (!fastReviewIsModalOpen) {
            return null
        }

        return (
            <Modal>
                <ReviewModal
                    handleHide={fastReviewHandleHideModal}
                    fileData={fastReviewFileData}
                    isSaving={fastReviewIsSaving}
                    handleSave={fastReviewHandleProductsUpdate}
                />
            </Modal>
        )
    }

    function handleChange(product, checked) {
        const { checkedProducts, setCheckedProducts } = props

        setCheckedProducts(
            checked
                ? [...checkedProducts, product]
                : checkedProducts.filter(({ id: productId }) => productId !== product.id)
        )
    }
}

export default withStyles(List, styles)
