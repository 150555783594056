import { INPUT_TYPES } from "constants/index"

export default {
    name: "Client Address",
    fields: [
        {
            name: "name",
            label: "",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "type",
            label: "",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "company",
            label: "Company Name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "first_name",
            label: "First name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "last_name",
            label: "Last name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "phone",
            label: "Phone Number",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "post_code",
            label: "ZIP Code",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "city",
            label: "City",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "state",
            label: "State",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "address",
            label: "Address (Street and no.)",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "country",
            label: "Country",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        }
    ]
}
