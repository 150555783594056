import React, { Component } from "react"
import { connect } from "react-redux"
import { cloneDeep } from "lodash"
import config from "./config"
import fetch from "helpers/fetch"
import Edit from "./Edit"
import * as clientActions from "actions/client"
import * as notificationActions from "actions/notification"
import * as userActions from "actions/user"
import { withRouter } from "react-router-dom"
import { ADDRESS_TYPES, SALES_PERSON_ROLE_NAME } from "constants/index"

const mergeValues = clientData => {
    const emptyAddresses = config.formEmpty.addresses

    if (clientData.addresses.length === 0) {
        return emptyAddresses
    }

    const addresses = Object.keys(ADDRESS_TYPES).map((type, typeIndex) => {
        const clientAddressTypeIndex = clientData.addresses.findIndex(address => address.type === type)
        return clientAddressTypeIndex === -1 ? emptyAddresses[typeIndex] : clientData.addresses[clientAddressTypeIndex]
    })

    return { ...clientData, addresses, avatar: { url: clientData.avatar } }
}
class EditContainer extends Component {
    state = {
        hasChanges: false,
        isDeleteModalShown: false,
        isDeleteLoading: false,
        isRestoreModalShown: false,
        isRestoreLoading: false,
        shownAddresses: {
            delivery: true,
            invoice: true
        }
    }

    componentDidMount() {
        const { match, getClientById, getUsers } = this.props
        if (match && match.params && match.params.clientId) {
            getClientById(match.params.clientId)
            getUsers()
        }
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.props.client.isError && nextProps.client.isError) {
            this.props.showErrorNotification()
        }
    }

    handleSubmit = (values, formikProps) => {
        const { isNewProfile, match } = this.props
        const { shownAddresses } = this.state
        const currentFavorites = values.favorites || []
        const newFavorites = currentFavorites.map(el => {
            return {
                id: el.id
            }
        })

        const addresses = [...values.addresses]
        const avatarId = values.avatar.id

        if (!shownAddresses.delivery) {
            addresses[1] = {
                ...addresses[0],
                type: "delivery",
                name: ADDRESS_TYPES.delivery
            }
        }

        if (!shownAddresses.invoice) {
            addresses[2] = {
                ...addresses[0],
                type: "invoice",
                name: ADDRESS_TYPES.invoice
            }
        }

        if (!isNewProfile) {
            const clientId = match && match.params && match.params.clientId
            return fetch
                .patch(`/clients/${clientId}`, {
                    ...values,
                    favorites: newFavorites,
                    addresses,
                    user_id: clientId,
                    avatar: avatarId,
                    users: [
                        ...(values.users || []).map(u => ({
                            id: u.id
                        }))
                    ]
                })
                .then(() => {
                    this.props.history.push(`/contact/${clientId}/profile`)
                })
                .catch(data => {
                    if (data) {
                        if (data.errors) {
                            formikProps.setErrors(data.errors)
                        } else if (data.message) {
                            formikProps.setErrors({
                                message: data.message
                            })
                        }
                    }

                    formikProps.setSubmitting(false)
                })
        }
        return fetch
            .post("/clients", {
                ...values,
                addresses,
                avatar: avatarId,
                users: [
                    ...(values.users || []).map(u => ({
                        id: u.id
                    }))
                ]
            })
            .then(data => {
                this.props.history.push(`/contact/${data.data.id}/profile`)
            })
            .catch(data => {
                if (data) {
                    if (data.errors) {
                        formikProps.setErrors(data.errors)
                    } else if (data.message) {
                        formikProps.setErrors({
                            message: data.message
                        })
                    }
                }

                formikProps.setSubmitting(false)
            })
    }

    handleDelete = clientId => {
        const { deleteClientSimple, history } = this.props

        this.setState(
            {
                isDeleteLoading: true
            },
            () => {
                deleteClientSimple(clientId)
                    .then(() => {
                        setTimeout(() => {
                            this.setState({ isDeleteModalShown: false, isDeleteLoading: false }, () => {
                                history.push("/contact")
                            })
                        }, 1000)
                    })
                    .catch(() => {})
            }
        )

        return
    }

    handleRestore = clientId => {
        const { showErrorNotification, showSuccessNotification, match, getClientById } = this.props

        this.setState({ isRestoreLoading: true })

        fetch
            .patchRAW(`/clients/${clientId}/restore`)
            .then(() => {
                if (match && match.params && match.params.clientId) {
                    getClientById(match.params.clientId)
                    showSuccessNotification({ title: "Restored" })
                    this.setState({
                        isRestoreLoading: true,
                        isRestoreModalShown: false
                    })
                }
            })
            .catch(() => showErrorNotification())
    }

    hasCurrentUserSalesPersonRole = () => {
        const { userId, users } = this.props
        if (users.length > 0) {
            const currentUser = users.find(user => user.id === userId)

            const hasSalesPersonRole = currentUser.role.find(el => {
                if (el && el.name) {
                    return el.name.toLowerCase() === SALES_PERSON_ROLE_NAME.toLowerCase()
                }
                return false
            })

            return !!hasSalesPersonRole
        }

        return false
    }

    setAddressSameAsMain = (type, isSame) =>
        this.setState(prevState => {
            return {
                shownAddresses: {
                    ...prevState.shownAddresses,
                    [type]: !isSame
                }
            }
        })

    render() {
        const { isNewProfile, userId, permissions, history, client, match } = this.props
        const {
            hasChanges,
            isDeleteModalShown,
            isDeleteLoading,
            isRestoreModalShown,
            isRestoreLoading,
            shownAddresses
        } = this.state
        if (isNewProfile) {
            const newProfileInitialValues = cloneDeep(config.formEmpty)
            newProfileInitialValues.users = this.hasCurrentUserSalesPersonRole() ? [{ id: userId }] : []

            return (
                <Edit
                    handleEdit={hasChanges => this.setState({ hasChanges })}
                    handleSubmit={this.handleSubmit}
                    history={history}
                    hasChanges={hasChanges}
                    initialValues={newProfileInitialValues}
                    schema={config.schema}
                    isNewProfile={true}
                    shownAddresses={shownAddresses}
                    setAddressSameAsMain={this.setAddressSameAsMain}
                    isTmpTabShown={permissions.includes("edit tmp tab")}
                />
            )
        }

        if (client.isLoaded) {
            return (
                <Edit
                    clientId={match && match.params && match.params.clientId}
                    handleEdit={hasChanges => this.setState({ hasChanges })}
                    handleDeleteModal={isDeleteModalShown => this.setState({ isDeleteModalShown })}
                    handleDelete={this.handleDelete}
                    isDeleteModalShown={isDeleteModalShown}
                    isDeleteLoading={isDeleteLoading}
                    handleSubmit={this.handleSubmit}
                    history={history}
                    hasChanges={hasChanges}
                    initialValues={mergeValues(client.data)}
                    schema={config.schema}
                    isNewProfile={false}
                    shownAddresses={shownAddresses}
                    setAddressSameAsMain={this.setAddressSameAsMain}
                    isTmpTabShown={permissions.includes("edit tmp tab")}
                    isDeletedClient={!!client.data.deleted_at}
                    isRestoreModalShown={isRestoreModalShown}
                    isRestoreLoading={isRestoreLoading}
                    handleRestore={this.handleRestore}
                    handleRestoreModal={isRestoreModalShown => this.setState({ isRestoreModalShown })}
                    client={client.data}
                />
            )
        }

        return null
    }
}

const mapStateToProps = state => {
    return {
        isSidebarMenuWide: state.menuStore.isSidebarMenuWide,
        client: state.clientStore.client,
        users: state.userStore.users,
        userId: parseFloat(state.loginStore.userId),
        permissions: state.loginStore.permissions
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deleteClientSimple: id => dispatch(clientActions.deleteClientSimple(id)),
        getClientById: id => dispatch(clientActions.getClientById(id)),
        getUsers: () => dispatch(userActions.getUsers()),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification()),
        showSuccessNotification: data => dispatch(notificationActions.showSuccessNotification(data))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(EditContainer))
