import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { objToQueryString } from "../helpers/urls"

export function getClientsStart() {
    return {
        type: constants.GET_CLIENTS_START
    }
}

export function getClientsSuccess(data) {
    return {
        type: constants.GET_CLIENTS_SUCCESS,
        payload: {
            data
        }
    }
}

export function getClientsError(error) {
    return {
        type: constants.GET_CLIENTS_ERROR,
        payload: {
            error
        }
    }
}

export function getClients({ query, length = constants.LENGTH_WITHOUT_PAGINATION, params } = {}) {
    return dispatch => {
        dispatch(getClientsStart())
        fetch
            .get(
                `/clients${query ? `?query=${query}&length=${length}` : `?length=${length}`}${
                    params && Object.keys(params).length > 0 ? "&" + objToQueryString(params) : ""
                }`
            )
            .then(data => {
                dispatch(getClientsSuccess(data))
            })
            .catch(error => {
                dispatch(getClientsError(error))
            })
    }
}

export function getClientsRAW({ query, params } = {}) {
    return fetch.getRAW(
        `/clients/export${query ? `?query=${query}&length=50` : `?length=${constants.LENGTH_WITHOUT_PAGINATION}`}${
            params && Object.keys(params).length > 0 ? "&" + objToQueryString(params) : ""
        }`
    )
}

export function getClientByIdStart() {
    return {
        type: constants.GET_CLIENT_BY_ID_START
    }
}

export function getClientByIdSuccess(data) {
    return {
        type: constants.GET_CLIENT_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function getClientByIdError(error) {
    return {
        type: constants.GET_CLIENT_BY_ID_ERROR,
        payload: {
            error
        }
    }
}

export function getClientById(id) {
    return dispatch => {
        dispatch(getClientByIdStart())

        return new Promise((resolve, reject) => {
            fetch
                .get(`/clients/${id}`)
                .then(data => {
                    resolve(data)
                    dispatch(getClientByIdSuccess(data))
                })
                .catch(error => {
                    reject(error)
                    dispatch(getClientByIdError(error))
                })
        })
    }
}

export function deleteClientStart() {
    return {
        type: constants.DELETE_CLIENT_START
    }
}

export function deleteClientSuccess() {
    return {
        type: constants.DELETE_CLIENT_SUCCESS
    }
}

export function deleteClientError(error) {
    return {
        type: constants.DELETE_CLIENT_ERROR,
        payload: {
            error
        }
    }
}

export function deleteClient(id) {
    return dispatch => {
        dispatch(deleteClientStart())
        fetch
            .deleteRAW(`/clients/${id}`)
            .then(() => dispatch(deleteClientSuccess()))
            .catch(error => {
                dispatch(deleteClientError(error))
            })
    }
}

export function deleteClientSimple(id) {
    return dispatch => {
        return fetch.deleteRAW(`/clients/${id}`)
    }
}

export function getStatesStart() {
    return {
        type: constants.GET_STATES_START
    }
}

export function getStatesSuccess(data) {
    return {
        type: constants.GET_STATES_SUCCESS,
        payload: {
            data
        }
    }
}

export function getStatesError(error) {
    return {
        type: constants.GET_STATES_ERROR,
        payload: {
            error
        }
    }
}

export function getStates() {
    return dispatch => {
        dispatch(getStatesStart())

        fetch
            .get(`/enums?set=states`)
            .then(data => dispatch(getStatesSuccess(data)))
            .catch(error => {
                dispatch(getStatesError(error))
            })
    }
}

export function getCountriesStart() {
    return {
        type: constants.GET_COUNTRIES_START
    }
}

export function getCountriesSuccess(data) {
    return {
        type: constants.GET_COUNTRIES_SUCCESS,
        payload: {
            data
        }
    }
}

export function getCountriesError(error) {
    return {
        type: constants.GET_COUNTRIES_ERROR,
        payload: {
            error
        }
    }
}

export function getCountries() {
    return dispatch => {
        dispatch(getCountriesStart())

        fetch
            .get(`/enums?set=countries`)
            .then(data => dispatch(getCountriesSuccess(data)))
            .catch(error => {
                dispatch(getCountriesError(error))
            })
    }
}

export function getClientTypesStart() {
    return {
        type: constants.GET_CLIENT_TYPES_START
    }
}

export function getClientTypesSuccess(data) {
    return {
        type: constants.GET_CLIENT_TYPES_SUCCESS,
        payload: {
            data
        }
    }
}

export function getClientTypesError(error) {
    return {
        type: constants.GET_CLIENT_TYPES_ERROR,
        payload: {
            error
        }
    }
}

export function getClientTypes() {
    return dispatch => {
        dispatch(getClientTypesStart())

        fetch
            .get(`/client-types?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getClientTypesSuccess(data)))
            .catch(error => {
                dispatch(getClientTypesError(error))
            })
    }
}

export function getClientTypeByIdStart() {
    return {
        type: constants.GET_CLIENT_TYPE_BY_ID_START
    }
}

export function getClientTypeByIdSuccess(data) {
    return {
        type: constants.GET_CLIENT_TYPE_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}

export function getClientTypeByIdError(error) {
    return {
        type: constants.GET_CLIENT_TYPE_BY_ID_ERROR,
        payload: {
            error
        }
    }
}

export function getClientTypeById(id) {
    return dispatch => {
        dispatch(getClientTypeByIdStart())

        fetch
            .get(`/client-types/${id}`)
            .then(data => dispatch(getClientTypeByIdSuccess(data)))
            .catch(error => dispatch(getClientTypeByIdError(error)))
    }
}

export function removeFavoriteSuccess(id) {
    return {
        type: constants.REMOVE_FAVORITE_SUCCESS,
        payload: id
    }
}

export function removeAllFavoritesSuccess() {
    return {
        type: constants.REMOVE_ALL_FAVORITES_SUCCESS
    }
}

export function removeAllFavorites({ clientId }) {
    return dispatch => {
        fetch.patch(`/clients/${clientId}`, { favorites: [] }).then(data => dispatch(removeAllFavoritesSuccess()))
    }
}

export function addProductToFavorites({ clientId, productId, successAction }) {
    return dispatch => {
        fetch
            .patch(`/clients/${clientId}`, { favorites_attach: [{ id: productId }] })
            .then(data => successAction && successAction(data))
    }
}

export function removeProductFromFavorites({ clientId, productId }) {
    return dispatch => {
        fetch
            .patch(`/clients/${clientId}`, { favorites_detach: [{ id: productId }] })
            .then(() => dispatch(removeFavoriteSuccess(productId)))
    }
}
