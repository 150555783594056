const TABLE_COLUMNS = {
    checkbox: {
        width: 27,
        minWidth: 27
    },
    productName: {
        minWidth: 200,
        width: "50%"
    },
    brand: {
        minWidth: 110,
        maxWidth: 200,
        width: "5%"
    },
    id: {
        minWidth: 60,
        maxWidth: 100,
        width: "3%"
    },
    transfers: {
        minWidth: 50,
        maxWidth: 100,
        width: "3%"
    },
    condition: {
        minWidth: 125,
        maxWidth: 140,
        width: "10%"
    },
    status: {
        minWidth: 125,
        maxWidth: 140,
        width: "10%"
    },
    warehouse: {
        minWidth: 100,
        maxWidth: 200,
        width: "10%"
    },
    location: {
        minWidth: 80,
        maxWidth: 150,
        width: "4%"
    },
    action: {
        width: 50,
        minWidth: 50
    }
}

export default TABLE_COLUMNS
