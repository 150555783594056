import React from "react"
import { Route, Switch } from "react-router-dom"

import List from "./pages/List"

import withPermissions from "HOC/withPermissions"

const Routes = props => {
    return (
        <Switch>
            <Route exact path="/services" component={List} />
        </Switch>
    )
}

export default withPermissions(Routes)
