import React, { useState, Fragment, useMemo } from "react"
import PropTypes from "prop-types"

import { Button, CloudIcon, ImageTile } from "@butterfly-frontend/ui"
import { ProductCondition } from "modules/WmsModule/components"
import UploadImages from "modules/WmsModule/modals/UploadImages"

import styles from "./ProductConditionForm.module.css"

const ProductConditionForm = ({ formik, onlyImages = false, addingImagesDisabled }) => {
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
    const condition = formik.values.condition ? formik.values.condition.value : null
    const images = formik.values.conditionImages
    const error = formik.errors.conditionImages

    const productConditionData = useMemo(
        () => ({
            name: condition ? condition.name : "",
            created_at: new Date().toDateString(),
            color: condition ? condition.color : "",
            images
        }),
        [condition, images]
    )

    const onImageRemove = indexToRemove => {
        if (!addingImagesDisabled) {
            const filteredImages = formik.values.conditionImages.filter(image => image.id !== indexToRemove)
            formik.setFieldValue("conditionImages", filteredImages)
        }
    }

    const onUploadImagesSubmit = images => {
        formik.setFieldValue("conditionImages", images)
        setIsUploadModalOpen(false)
    }

    return (
        <Fragment>
            <div className={styles.headerContainer}>
                <h2 className={styles.title}>Item condition</h2>
                {error && <div className={styles.errorMessage}>{error}</div>}
                <Button
                    onClick={() => setIsUploadModalOpen(true)}
                    Icon={CloudIcon}
                    variant="flat"
                    color={error ? "red" : "blue"}
                    disabled={addingImagesDisabled}
                >
                    Add photos
                </Button>
            </div>
            {!!images.length && (
                <div className={styles.productConditionContainer}>
                    {onlyImages ? (
                        <div className={styles.imageTilesList}>
                            {images.map(image => (
                                <ImageTile key={image.id} image={image} onDelete={onImageRemove} />
                            ))}
                        </div>
                    ) : (
                        <ProductCondition productConditionData={productConditionData} />
                    )}
                </div>
            )}
            {isUploadModalOpen && (
                <UploadImages onClose={() => setIsUploadModalOpen(false)} onSubmit={onUploadImagesSubmit} />
            )}
        </Fragment>
    )
}

ProductConditionForm.propTypes = {
    formik: PropTypes.shape({
        values: PropTypes.shape({
            condition: PropTypes.shape({
                label: PropTypes.string.isRequired,
                name: PropTypes.string,
                value: PropTypes.shape({
                    id: PropTypes.number
                }).isRequired,
                color: PropTypes.string.isRequired,
                type: PropTypes.string
            }),
            conditionImages: PropTypes.arrayOf(
                PropTypes.shape({
                    url: PropTypes.string.isRequired
                })
            ).isRequired
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool.isRequired
    }).isRequired,
    onlyImages: PropTypes.bool,
    addingImagesDisabled: PropTypes.bool
}

export default ProductConditionForm
