import * as types from "../../actionTypes"
import * as fetchStatusHelper from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    fetchStatus: fetchStatusHelper.initialState()
}

export default (state = initialState, action) => {
    if (types.GET_CATEGORIES_REQUEST === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.requestState() }
    }

    if (types.GET_CATEGORIES_SUCCESS === action.type) {
        return {
            ...initialState,
            data: action.payload.data || [],
            meta: action.payload.meta || {},
            fetchStatus: fetchStatusHelper.successState()
        }
    }

    if (types.GET_CATEGORIES_FAILURE === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.failureState() }
    }

    if (types.RESET_CATEGORIES === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.initialState() }
    }

    return state
}
