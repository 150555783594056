import { useMemo } from "react"
import { useStockAdjustmentDetails as useStockAdjustmentDetailsData } from "modules/WmsModule/hooks/api/useStockAdjustment"

const useStockAdjustmentDetails = ({ stockAdjustmentId }) => {
    const { fetchStatus, data } = useStockAdjustmentDetailsData({
        id: stockAdjustmentId,
        reactQueryProps: { enabled: !!stockAdjustmentId }
    })

    const {
        adjustment_items = [],
        stock_item_condition: conditionTo = null,
        stock_item_status: statusTo = null,
        adjustment_boxes: adjustmentBoxes = [],
        description = null
    } = data || {}

    const hasAdjustmentBoxes = adjustmentBoxes.length > 0

    const adjustmentItems = useMemo(
        () =>
            adjustment_items.map(adjustmentItem => {
                const {
                    id,
                    stock_item: { product },
                    stock_item_condition: conditionFrom,
                    stock_item_status: statusFrom,
                    warehouse
                } = adjustmentItem

                return {
                    id,
                    product,
                    conditionFrom: conditionFrom && conditionFrom.product_condition,
                    conditionTo: conditionTo && conditionTo.product_condition,
                    statusFrom,
                    statusTo,
                    warehouse
                }
            }),
        [adjustment_items, conditionTo, statusTo, hasAdjustmentBoxes]
    )

    return { adjustmentItems, hasAdjustmentBoxes, adjustmentBoxes, description, fetchStatus }
}

export default useStockAdjustmentDetails
