import React, { useState, Fragment, useMemo, useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useQueryClient } from "react-query"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import _debounce from "lodash/debounce"
import _invokeAfter from "lodash/after"

import { Panel, Table, TableHead, TableHeadCell, TableBody } from "@butterfly-frontend/ui"
import { SaveBar, Textarea, Input, Toggle, ErrorBar } from "ui"
import { useActions } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import UploadImages from "modules/WmsModule/modals/UploadImages"
import { useShipmentEdit, KEY as SHIPMENT_QUERY_KEY } from "modules/WmsModule/hooks/api/useShipment"
import { useBarcodeReader } from "modules/WmsModule/hooks"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"
import { SHIPMENT_PACK_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"

import TABLE_COLUMNS from "./tableColumns"
import ShipmentTableRow from "./components/ShipmentTableRow"
import { usePackBox } from "./hooks"

import styles from "./ShipmentInProgressPage.module.css"

const checkConditionIsDamaged = item => item.condition.type === CONDITION_TYPES.DAMAGED

const DESCRIPTION_MAX_LENGTH = 1000

const ShipmentInProgressPage = ({ data: shipmentData, canEdit }) => {
    const history = useHistory()
    const queryClient = useQueryClient()

    const shipmentPreviousChanges = useMemo(() => shipmentData, [])
    const shipmentQueryKey = useMemo(() => [SHIPMENT_QUERY_KEY, { id: shipmentData.id.toString() }], [shipmentData.id])
    const [shipmentItemIdForUploadConditionPhotosModal, setShipmentItemIdForUploadConditionPhotosModal] = useState(null)
    const [pageError, setPageError] = useState(null)

    const actions = useActions({ showErrorNotification, showSuccessNotification })

    const shipmentEdit = useShipmentEdit({ skipInvalidateQueries: true })
    const shipmentEditForCancellation = useShipmentEdit()

    const isShipmentStatusCancelled = shipmentData.status.status === SHIPMENT_STATUS_KEYS.CANCELLED
    const isShipmentStatusPacked = shipmentData.status.status === SHIPMENT_STATUS_KEYS.PACKED

    const isAllItemsReady = useMemo(
        () =>
            shipmentData.items.every(
                item => checkConditionIsDamaged(item) || (isShipmentStatusCancelled ? !item.packed : item.packed)
            ),
        [shipmentData.items]
    )

    const isAllItemsRejected = useMemo(() => shipmentData.items.every(item => checkConditionIsDamaged(item)), [
        shipmentData.items
    ])

    const onCloseUploadConditionPhotosModal = useCallback(() => {
        setShipmentItemIdForUploadConditionPhotosModal(null)
    }, [])

    const editStatusShipmentInCache = status => {
        queryClient.setQueryData(shipmentQueryKey, ({ data }) => ({
            data: {
                ...data,
                status
            }
        }))
    }

    const editItemConditionPhotosInCache = (itemId, images) => {
        queryClient.setQueryData(shipmentQueryKey, ({ data }) => ({
            data: {
                ...data,
                items: data.items.map(item =>
                    item.id === itemId
                        ? {
                              ...item,
                              condition: { ...item.condition, images: [...item.condition.images, ...images] }
                          }
                        : item
                )
            }
        }))
    }

    const redirectToShipments = useCallback(() => history.push("/wms/shipments"), [history])

    const onUploadConditionPhotos = useCallback(
        images => {
            editItemConditionPhotosInCache(shipmentItemIdForUploadConditionPhotosModal, images)
            onCloseUploadConditionPhotosModal()
        },
        [shipmentItemIdForUploadConditionPhotosModal, editItemConditionPhotosInCache, onCloseUploadConditionPhotosModal]
    )

    const onCancelChanges = useCallback(() => {
        shipmentEditForCancellation.mutate({
            id: shipmentData.id,
            data: shipmentPreviousChanges.items.reduce(
                (result, item) => {
                    return item.packed
                        ? { ...result, items_to_be_packed: [...result.items_to_be_packed, item.shipment_stock_item_id] }
                        : {
                              ...result,
                              items_to_be_unpacked: [...result.items_to_be_unpacked, item.shipment_stock_item_id]
                          }
                },
                {
                    items_to_be_packed: [],
                    items_to_be_unpacked: []
                }
            )
        })
    }, [shipmentPreviousChanges])

    const onCancelShipment = useCallback(() => {
        shipmentEdit
            .mutate({
                id: shipmentData.id,
                data: { status: "cancelled" }
            })
            .then(response => {
                editStatusShipmentInCache(response.data.status)
                actions.showSuccessNotification({ title: "The stock transfer has been cancelled" })
            })
            .catch(actions.showErrorNotification)
    }, [shipmentEdit, actions, shipmentData, editStatusShipmentInCache])

    const onSubmit = useCallback(() => {
        const body = isShipmentStatusCancelled ? { unpack_confirmed: true } : { status: "packed" }

        const successMessage = isShipmentStatusCancelled
            ? "The stock transfer has been unpacked"
            : "The stock transfer has been packed"

        shipmentEdit
            .mutate({
                id: shipmentData.id,
                data: body
            })
            .then(() => {
                actions.showSuccessNotification({ title: successMessage })
                redirectToShipments()
            })
            .catch(actions.showErrorNotification)
    }, [shipmentEdit, shipmentData, redirectToShipments, actions])

    const formik = useFormik({
        initialValues: {
            description: shipmentData.description || ""
        },
        validationSchema: Yup.object().shape({
            description: Yup.string().max(
                DESCRIPTION_MAX_LENGTH,
                `Description may not be greater than ${DESCRIPTION_MAX_LENGTH} characters`
            )
        }),
        onSubmit: ({ description }) =>
            shipmentEdit
                .mutate({
                    id: shipmentData.id,
                    data: { description }
                })
                .catch(actions.showErrorNotification)
    })

    const updateDescription = useCallback(_debounce(_invokeAfter(2, formik.submitForm), 500), [formik.submitForm])

    const {
        barcodeInputProps,
        enableBarcodeInputToggleProps,
        barcodeInputRef,
        clearBarcodeInput,
        isBarcodeReaderEnabled
    } = useBarcodeReader()

    const { onPackBox, isLoading: isBoxPackLoading } = usePackBox({
        shipment: shipmentData,
        onPackError: error => {
            setPageError(error)
            clearBarcodeInput()
        },
        onPackSuccess: clearBarcodeInput
    })

    const onBarcodeSubmit = event => {
        event.preventDefault()
        setPageError(null)
        onPackBox(barcodeInputRef.current.value)
    }

    useEffect(() => {
        updateDescription()
    }, [formik.values.description])

    useEffect(() => {
        if (isAllItemsRejected && !isShipmentStatusCancelled) {
            onCancelShipment()
        }
    }, [isAllItemsRejected, isShipmentStatusCancelled])

    return (
        <Fragment>
            {isShipmentStatusCancelled && <ErrorBar text="Unpack the package and confirm by saving the changes" />}

            {pageError && <ErrorBar text={pageError} />}

            <Panel classes={{ panel: styles.items }}>
                <div className={styles.itemsHeader}>
                    <h2>Items</h2>
                    <div className={styles.barcodeToggle}>
                        <Toggle
                            {...enableBarcodeInputToggleProps}
                            labelPosition="left"
                            label={{ on: "Barcode scanning", off: "Barcode scanning" }}
                            isSmall
                        />
                    </div>
                    <form onSubmit={onBarcodeSubmit} className={styles.barcodeForm}>
                        <Input {...barcodeInputProps} className={styles.barcodeInput} />
                    </form>
                </div>
                <Table classes={{ root: styles.table }}>
                    <TableHead>
                        <TableHeadCell {...TABLE_COLUMNS.no}>#</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.product}>PRODUCT NAME</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.id}>ID</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.condition}>CONDITION</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.location}>LOCATION</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.packAction} />
                    </TableHead>
                    <TableBody>
                        {shipmentData.items.map((item, index) => (
                            <ShipmentTableRow
                                key={item.id}
                                index={index}
                                item={item}
                                shipment={{
                                    id: shipmentData.id,
                                    isCancelled: isShipmentStatusCancelled,
                                    isPacked: isShipmentStatusPacked,
                                    isUnpackConfirmed: shipmentData.unpack_confirmed
                                }}
                                onOpenUploadConditionPhotosModal={() =>
                                    setShipmentItemIdForUploadConditionPhotosModal(item.id)
                                }
                                isBarcodeReaderEnabled={isBarcodeReaderEnabled}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Panel>
            <div className={styles.description}>
                {isShipmentStatusCancelled || isShipmentStatusPacked || !canEdit() ? (
                    <Fragment>
                        <h3>Description</h3>
                        <p>{shipmentData.description}</p>
                    </Fragment>
                ) : (
                    <Textarea
                        label="Description"
                        className={styles.textarea}
                        placeholder="Click here to add a description..."
                        name="description"
                        value={formik.values.description}
                        error={formik.touched.description && formik.errors.description}
                        onBlur={formik.handleBlur}
                        onChange={({ event }) => formik.handleChange(event)}
                    />
                )}
            </div>
            <SaveBar
                isDisabled={
                    (isShipmentStatusCancelled && shipmentData.unpack_confirmed) ||
                    isShipmentStatusPacked ||
                    shipmentEditForCancellation.fetchStatus.isLoading ||
                    shipmentEdit.fetchStatus.isLoading ||
                    !isAllItemsReady ||
                    isAllItemsRejected ||
                    isBoxPackLoading ||
                    !canEdit()
                }
                isDisabledCancel={
                    shipmentEditForCancellation.fetchStatus.isLoading ||
                    shipmentEdit.fetchStatus.isLoading ||
                    isBoxPackLoading
                }
                isShown
                isSubmit
                isFirstButtonWarning={isShipmentStatusCancelled}
                isSaving={shipmentEdit.fetchStatus.isLoading}
                submitLabel={isShipmentStatusCancelled ? "Unpacked" : "Packed"}
                onSubmit={onSubmit}
                onCancel={isShipmentStatusCancelled || isShipmentStatusPacked || !canEdit() ? null : onCancelChanges}
            />
            {shipmentItemIdForUploadConditionPhotosModal && (
                <UploadImages onClose={onCloseUploadConditionPhotosModal} onSubmit={onUploadConditionPhotos} />
            )}
        </Fragment>
    )
}

ShipmentInProgressPage.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        status: PropTypes.shape({
            status: PropTypes.string.isRequired
        }).isRequired,
        items: PropTypes.arrayOf(SHIPMENT_PACK_ITEM_PROP_TYPE).isRequired,
        description: PropTypes.string,
        unpack_confirmed: PropTypes.bool.isRequired
    }),
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(ShipmentInProgressPage, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
