import { INPUT_TYPES } from "constants/index"

export default {
    name: "Feature",
    endpoint: "/product-features",
    fields: [
        {
            name: "name",
            label: "Name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        }
    ]
}
