import React, { Fragment, useMemo } from "react"
import { Link, useParams } from "react-router-dom"

import { H1, Panel, Button, BackIcon } from "@butterfly-frontend/ui"
import { Dropdown } from "ui"
import { useShipmentDetails } from "modules/WmsModule/hooks/api/useShipment"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import useConsignmentNoteOptions from "modules/WmsModule/hooks/useConsignmentNoteOptions"
import { checkIfCanGenerateConsignmentNote } from "modules/WmsModule/helpers/consignmentNote"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import ReceivedPackagePage from "modules/WmsModule/pages/ShipmentDetailsPage/ShipmentSentPage"
import { SnakeLoader } from "ui/Skeleton"
import { checkIfCanReturnShipment } from "modules/WmsModule/helpers/shipment"

import ReceivePackagePage from "./ReceivePackagePage"
import ReturnPackagePage from "./ReturnPackagePage"
import { checkIfItemIsAvailableToReturn } from "./helpers"
import styles from "./ReceivingDetailsPage.module.css"

const checkIfAnyItemIsAvailableToReturn = items => items.some(checkIfItemIsAvailableToReturn)

const getItemsIds = items => items.map(({ id }) => id)

const ReceivingDetailsPage = () => {
    const params = useParams()
    const {
        data,
        fetchStatus,
        query: { error }
    } = useShipmentDetails({ id: params.receivingId })

    const { options: boxLabelsOptions, isLoading: isBoxLabelOptionsLoading } = useBoxLabelOptions({
        ids: data ? getItemsIds(data.items) : []
    })

    const { options: consignmentNoteOptions, isLoading: isConsignmentNoteOptionsLoading } = useConsignmentNoteOptions({
        ids: [params.receivingId]
    })

    const previewDropdownOptions = useMemo(
        () => [
            ...(boxLabelsOptions || []),
            ...(data && checkIfCanGenerateConsignmentNote(data) ? consignmentNoteOptions : [])
        ],
        [consignmentNoteOptions, boxLabelsOptions, data]
    )

    if (fetchStatus.isLoading) {
        return (
            <Panel classes={{ panel: styles.root }}>
                <SnakeLoader />
            </Panel>
        )
    }

    if (fetchStatus.isError) {
        return (
            <Panel classes={{ panel: styles.root }}>
                <p className={styles.error}>{error.statusText}</p>
            </Panel>
        )
    }

    const shipmentStatus = SHIPMENT_STATUSES[data.status.status]
    const isShipmentStatusCancelled = shipmentStatus === SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.CANCELLED]
    const isShipmentStatusSent = shipmentStatus === SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.SENT]
    const isPreviewOptionsLoading = isBoxLabelOptionsLoading || isConsignmentNoteOptionsLoading

    const renderPageContent = () => {
        if (isShipmentStatusSent) {
            return <ReceivePackagePage data={data} />
        }

        if (
            checkIfAnyItemIsAvailableToReturn(data.items) &&
            checkIfCanReturnShipment({ shipmentType: data.type, hasOrder: !!data.order })
        ) {
            return <ReturnPackagePage data={data} />
        }

        return <ReceivedPackagePage data={data} />
    }

    return (
        <Panel classes={{ panel: styles.root }}>
            <div className={styles.headerWrapper}>
                <H1
                    withLeftBorder
                    classes={{ root: [styles.header, { [styles.headerDanger]: isShipmentStatusCancelled }] }}
                >
                    {data.uuid}
                </H1>
                <Dropdown
                    label={
                        <Fragment>
                            Preview
                            {isPreviewOptionsLoading && (
                                <SnakeLoader
                                    classes={{ root: styles.dropdownLoaderWrapper, loader: styles.dropdownLoader }}
                                />
                            )}
                        </Fragment>
                    }
                    className={styles.previewDropdown}
                    options={previewDropdownOptions}
                    disabled={isPreviewOptionsLoading}
                />
            </div>
            {renderPageContent()}
            <Button
                type="link"
                variant="flat"
                Icon={BackIcon}
                classes={{ button: styles.backButton }}
                LinkComponent={Link}
                propsLinkComponent={{ to: "/wms/receivings" }}
            >
                Receiving
            </Button>
        </Panel>
    )
}

export default ReceivingDetailsPage
