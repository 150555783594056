import React, { Component } from "react"

import { withStyles } from "ui/FilterableTable/HOC"
import styles from "./Content.css"

class Content extends Component {
    render() {
        const { cx, className, children } = this.props

        return <div className={cx("content", className)}>{children}</div>
    }
}

export default withStyles(styles)(Content)
