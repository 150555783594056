import PropTypes from "prop-types"
import memoize from "fast-memoize"

const normalizeHttpStatus = status => {
    const statusAsNumber = Number(status)

    return !isNaN(statusAsNumber) ? statusAsNumber : 0
}

export const fetchStatusFromResources = memoize((...resources) => {
    resources = resources.map(item => {
        if (item.fetchStatus) {
            return item
        }

        return {
            ...item,
            fetchStatus: {
                isLoading: item.isLoading,
                isLoaded: item.isLoaded,
                isError: item.isError,
                error: item.isError
            }
        }
    })

    const isLoading = resources.reduce((acc, resource) => {
        return acc || resource.fetchStatus.isLoading ? true : acc === null ? acc : resource.fetchStatus.isLoading
    }, false)

    const isError = resources.reduce(
        (acc, resource) => acc || (resource.fetchStatus ? resource.fetchStatus.isError : true),
        false
    )

    const isLoaded =
        !isLoading &&
        !isError &&
        resources.reduce((acc, resource) => acc && (resource.fetchStatus ? resource.fetchStatus.isLoaded : false), true)

    const error = resources.reduce((acc, resource) => {
        if (!acc && resource.error) {
            acc = resource.error
        }

        if (acc && resource.error && normalizeHttpStatus(resource.error.status) > normalizeHttpStatus(acc.status)) {
            acc = resource.error
        }

        return acc
    }, null)

    return { isLoading, isLoaded, isError, error }
})

export const fromResource = resource => fetchStatusFromResources(resource)

export const fromResources = (...resources) => fetchStatusFromResources(...resources.filter(item => item))

export const isResourceNotInit = (...resources) => {
    const fetchStatus = fromResources(...resources)

    return !fetchStatus.isLoading && !fetchStatus.isLoaded && !fetchStatus.isError
}

export const shouldFetch = (resource, customFetchStatus) => () => {
    if (customFetchStatus) {
        return isResourceNotInit({ ...resource, fetchStatus: customFetchStatus })
    }

    return isResourceNotInit(resource)
}

export const combineFetchStatuses = fetchStatuses => {
    return {
        isLoading: fetchStatuses.some(fs => fs.isLoading),
        isLoaded: fetchStatuses.every(fs => fs.isLoaded),
        isError: fetchStatuses.some(fs => fs.isError),
        error: fetchStatuses.filter(fs => !!fs.error).map(fs => fs.error)
    }
}

export const initialState = () => ({
    isLoading: null,
    isLoaded: null,
    isError: null,
    error: null
})

export const requestState = () => ({
    isLoading: true,
    isLoaded: false,
    isError: false,
    error: null
})

export const successState = () => ({
    isLoading: false,
    isLoaded: true,
    isError: false,
    error: null
})

export const failureState = () => ({
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: null
})

export const fetchStatusPropTypes = PropTypes.shape({
    isLoading: PropTypes.bool,
    isLoaded: PropTypes.bool,
    isError: PropTypes.bool,
    errors: PropTypes.object
})
