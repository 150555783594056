import * as Yup from "yup"
import { requiredFieldFactory, requiredSelectOptionFactory } from "modules/WmsModule/helpers/schema"
import { DESCRIPTION_SCHEMA } from "modules/WmsModule/constants/schema"

const conditionValueSchema = Yup.object({
    id: requiredFieldFactory(Yup.number())
})

const reasonValueSchema = Yup.number()

export default Yup.object().shape({
    condition: requiredSelectOptionFactory(conditionValueSchema),
    reason: requiredSelectOptionFactory(reasonValueSchema),
    description: DESCRIPTION_SCHEMA
})
