import * as yup from "yup"

import { settings as settingsFieldsSchema, product as productSchema } from "../ShowProductLink/schemas"

import { INPUT_MAX_LENGTH } from "constants/index"

export const section = yup.object().shape({
    settings: yup
        .object()
        .shape({
            heading: yup
                .string()
                .max(INPUT_MAX_LENGTH, `Heading may not be greater than ${INPUT_MAX_LENGTH} characters`)
                .nullable(),
            subheading: yup
                .string()
                .max(INPUT_MAX_LENGTH, `Subheading may not be greater than ${INPUT_MAX_LENGTH} characters`)
                .nullable(),
            ...settingsFieldsSchema
        })
        .required(),
    product: productSchema,
    paralax_image: yup.object().required("Field is required")
})
