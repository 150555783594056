import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./profilemanagement.module.css"

class ProfileManagement extends Component {
    render() {
        const { cx, history, handleDeleteModal, handleRestoreModal, clientId, isDeletedClient, canRestore } = this.props

        return (
            <ul className={cx("root")}>
                <li onClick={() => history.push(`/contact/${clientId}/profile`)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g fill="none" fillRule="evenodd">
                            <path d="M3 3h18v18H3z" />
                            <path
                                fill="#4383CD"
                                fillRule="nonzero"
                                d="M12 6.375c-3.75 0-6.953 2.332-8.25 5.625 1.297 3.293 4.5 5.625 8.25 5.625s6.953-2.332 8.25-5.625c-1.297-3.293-4.5-5.625-8.25-5.625zm0 9.375c-2.07 0-3.75-1.68-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75 2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75zm0-6A2.247 2.247 0 0 0 9.75 12 2.247 2.247 0 0 0 12 14.25 2.247 2.247 0 0 0 14.25 12 2.247 2.247 0 0 0 12 9.75z"
                            />
                        </g>
                    </svg>
                    <span>View Profile</span>
                </li>
                {isDeletedClient ? (
                    canRestore() && (
                        <li className={cx("lastButton")} onClick={() => handleRestoreModal(true)}>
                            <span>Restore</span>
                        </li>
                    )
                ) : (
                    <li className={cx("lastButton")} onClick={() => handleDeleteModal(true)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="none" fillRule="evenodd">
                                <path d="M3 3h18v18H3z" />
                                <path
                                    fill="#4383CD"
                                    fillRule="nonzero"
                                    d="M17.25 7.808L16.193 6.75 12 10.942 7.808 6.75 6.75 7.807 10.943 12 6.75 16.193l1.058 1.057L12 13.057l4.193 4.193 1.057-1.057L13.057 12z"
                                />
                            </g>
                        </svg>
                        <span>Delete</span>
                    </li>
                )}
            </ul>
        )
    }
}

export default withStyles(ProfileManagement, styles)
