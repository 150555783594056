import { INPUT_TYPES } from "constants/index"

export default {
    name: "Blog",
    endpoint: "/posts",
    fields: [
        {
            name: "title",
            label: "Title",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "subtitle",
            label: "Subtitle",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: false
            }
        },
        {
            name: "cover",
            label: "Cover",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: ""
        },
        {
            name: "video",
            label: "Video:",
            type: INPUT_TYPES.TEXT,
            defaultValue: ""
        },
        {
            name: "video_title",
            label: "Video_title:",
            type: INPUT_TYPES.TEXT,
            defaultValue: ""
        },
        {
            name: "description",
            label: "",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "thumbnail",
            label: "Post thumbnail:",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: ""
        },
        {
            name: "verified",
            label: { on: "Yes", off: "No" },
            type: INPUT_TYPES.TEXT,
            defaultValue: false
        },
        {
            name: "tags",
            label: "Add a tag...",
            type: INPUT_TYPES.TEXT,
            defaultValue: [],
            attributes: {}
        },
        {
            name: "publish_date",
            label: "Publish Date:",
            type: INPUT_TYPES.TEXT,
            defaultValue: null
        },
        {
            name: "visible",
            label: "Visibility:",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: false
        }
    ]
}
