import React, { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import fetch from "helpers/fetch"
import _ from "lodash"

import { getBrandById, getPriceStructure, resetCurrentBrand } from "actions/brand"

import Form from "./Form"
import { INIT_VALUES, FIELDS_IN_TABS, TABS } from "./constants"

const FormContainer = ({ match, getBrand, resetCurrentBrand, getPriceStructure, brand, history, priceStructure }) => {
    const brandId = match.params.brandId

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [countErrors, setCountErrors] = useState({ [TABS.GENERAL]: 0, [TABS.FINANCIALS]: 0 })

    useEffect(() => {
        getPriceStructure()

        if (brandId) {
            getBrand(brandId)
        }

        return () => {
            resetCurrentBrand()
        }
    }, [])

    const initialValues = () => {
        let data = { ...INIT_VALUES }
        if (brand && brand.id) {
            data = {
                ...data,
                ...brand,
                discounts: brand.discounts || [],
                pricing_note: brand.pricing_note || "",
                payment_terms: brand.payment_terms || "",
                bank_information: brand.bank_information || "",
                accounting_email:
                    Array.isArray(brand.accounting_email) && brand.accounting_email.length
                        ? brand.accounting_email
                        : [""],
                po_email: Array.isArray(brand.po_email) && brand.po_email.length ? brand.po_email : [""],
                pricing_structure_id: brand.pricing_structure_id ? brand.pricing_structure_id.id : null,
                type: !!brand.custom_vendor ? "custom_vendor" : "standard_vendor",
                options: undefined,
                brand_rates_to_remove: []
            }
        }

        return data
    }

    const handleSubmit = async (values, formikActions) => {
        try {
            setCountErrors({ [TABS.GENERAL]: 0, [TABS.FINANCIALS]: 0 })

            const brandValues = {
                ...values,
                manufacturing_min: values.manufacturing_min || null,
                manufacturing_max: values.manufacturing_max || null,
                delivery_min: values.delivery_min || null,
                delivery_max: values.delivery_max || null,
                pricing_mu_usd_retail: values.pricing_mu_usd_retail || null,
                pricing_exchange_rate: values.pricing_exchange_rate || null,
                pricing_structure_id: values.pricing_structure_id || null,
                accounting_email: values.accounting_email.filter(item => item),
                po_email: values.po_email.filter(item => item),
                logo: values.logoId || values.logo,
                image: values.imageId || values.image,
                discounts: values.discounts,
                custom_vendor: values.type === "custom_vendor",
                attachments: undefined,
                rate:
                    (values.rate && values.rate.id) || !values.rate
                        ? undefined
                        : { ...values.rate, value_percentage: values.rate.value_percentage }
            }

            setIsSubmitting(true)

            if (brandId) {
                await fetch.patchRAW(`/brands/${brandId}`, brandValues)
                history.push(`/settings/brands/${brandId}`)
            } else {
                const result = await fetch.post("/brands", brandValues)
                history.push(`/settings/brands/${result.data.id}`)
            }

            setIsSubmitting(false)
        } catch (error) {
            const formErrors = {}
            if (error.name === "ValidationError") {
                for (const item of error.inner) {
                    formErrors[item.path] = item.message
                }
            } else if (error.errors) {
                for (const field of Object.keys(error.errors)) {
                    formErrors[field] = error.errors[field][0]
                }
            }

            calculateErrorCounts(formErrors)
            setIsSubmitting(false)
            formikActions.setErrors(formErrors)
        }
    }

    const calculateErrorCounts = errors => {
        const currentErrors = Object.keys(errors).reduce(
            (counter, fieldKey) => {
                if (Array.isArray(errors[fieldKey]) && errors[fieldKey].length === 0) {
                    return counter
                }

                const tab = _.startsWith(fieldKey, "discounts") ? TABS.FINANCIALS : FIELDS_IN_TABS[fieldKey]
                return { ...counter, [tab]: counter[tab] + 1 }
            },
            { [TABS.GENERAL]: 0, [TABS.FINANCIALS]: 0 }
        )

        if (!_.isEqual(currentErrors, countErrors)) {
            setCountErrors(currentErrors)
        }
    }

    const handleCancel = () => history.push("/settings/brands")
    const onTabChange = useCallback(
        key => history.push(brandId ? `/settings/brands/${brandId}/edit/${key}` : `/settings/brands/add/${key}`),
        [brandId]
    )

    return (
        <div>
            <Form
                initValues={initialValues()}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                isSubmitting={isSubmitting}
                countErrors={countErrors}
                priceStructure={priceStructure}
                sectionName={match.params.sectionName}
                onTabChange={onTabChange}
                calculateErrorCounts={calculateErrorCounts}
            />
        </div>
    )
}

FormContainer.propTypes = {
    match: PropTypes.object.isRequired,
    getBrand: PropTypes.func.isRequired,
    resetCurrentBrand: PropTypes.func.isRequired,
    brand: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        brand: state.db.brands.current.data,
        priceStructure: state.db.brands.priceStructure.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getBrand: id => dispatch(getBrandById(id)),
        resetCurrentBrand: () => dispatch(resetCurrentBrand()),
        getPriceStructure: () => dispatch(getPriceStructure())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormContainer)
