import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import withStyles from "HOC/withStyles"
import style from "./CreateRole.css"
import * as roleActions from "actions/role"
import Form from "components/Reusable/PermissionsForm/Form/Form"
import withForm from "HOC/withForm"
import models from "models/index"
import * as notificationActions from "actions/notification"

class CreateRole extends Component {
    isEdit = false

    componentDidMount() {
        const { match, getPermission, getRole } = this.props
        if (match && match.params && match.params.id) {
            this.isEdit = true
            getRole(match.params.id)
        }
        getPermission()
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { roleStore, fillModel } = nextProps

        if (this.isEdit) {
            fillModel(roleStore.role)
        }
        if (!this.props.isError && nextProps.isError) {
            this.props.showErrorNotification()
        }
    }

    onEdit(event) {
        event.preventDefault()
        const { roleStore } = this.props
        const { id } = roleStore.role
        this.handleSubmit({ method: "patch", id })
    }

    onSubmit(event) {
        event.preventDefault()
        this.handleSubmit({ method: "post" })
    }

    handleSubmitSuccess() {
        const { history, showSuccessNotification } = this.props
        history.goBack()
        showSuccessNotification && showSuccessNotification()
    }

    handleSubmit({ method, id }) {
        const { handleSubmit } = this.props

        handleSubmit({ method, id, handleSuccess: this.handleSubmitSuccess.bind(this) })
    }

    choosePermission({ id, permissionsIds }) {
        const { handleFieldChange } = this.props
        const permissionsWithoutThisId = permissionsIds.filter(element => element !== id)

        let newPermissions = [...permissionsWithoutThisId]
        if (permissionsIds.indexOf(id) < 0) {
            newPermissions = [...permissionsIds, id]
        }

        handleFieldChange({
            event: {
                target: {
                    value: newPermissions
                }
            },
            fieldPath: "permissions"
        })
    }

    render() {
        const { cx, roleStore, isFilled, history } = this.props
        const { toRender, handleFieldChange, isSaving } = this.props

        return (
            <div className={cx("round-border", "create-role-cover", { "form-isFilling": this.isEdit && !isFilled })}>
                <Form
                    isEdit={this.isEdit}
                    formFields={toRender()}
                    isSaving={isSaving}
                    handleFieldChange={handleFieldChange}
                    choosePermission={this.choosePermission.bind(this)}
                    allPermissions={roleStore.permissions}
                    onEdit={this.onEdit.bind(this)}
                    onSubmit={this.onSubmit.bind(this)}
                    history={history}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state }
}

const mapDispatchToProps = dispatch => {
    return {
        getPermission: () => dispatch(roleActions.getPermission()),
        getRoles: () => dispatch(roleActions.getRoles()),
        getRole: id => dispatch(roleActions.getRole(id)),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification()),
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification())
    }
}

const ComponentWithCSS = withStyles(CreateRole, style)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withForm(ComponentWithCSS, { model: models.Role })))
