import { CHANGE_DELETED, CLEAR_DELETED } from "constants/index"

function deleted(state = "", action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case CLEAR_DELETED:
            return ""
        case CHANGE_DELETED:
            return value
        default:
            return state
    }
}

export default deleted
