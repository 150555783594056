import React, { Component } from "react"

import Pagination from "./Pagination"

class PaginationContainer extends Component {
    render() {
        return <Pagination {...this.props} handleClick={this.handleClick} pagesCount={this.getPagesCount()} />
    }

    getPagesCount() {
        const { items, perPage, pagesCount } = this.props

        if (items) {
            return items instanceof Array ? Math.ceil(items.length / perPage) : 0
        }

        return pagesCount
    }

    handleClick = page => {
        const { handleSelectPage } = this.props
        handleSelectPage && handleSelectPage(page)
    }
}

export default PaginationContainer
