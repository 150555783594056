import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"
import useFetch from "./useFetch"

export default ({ exportData = {}, checkedList = [], onError }) => {
    const [handleExport, { isLoading: isExporting }] = useFetch({
        action: payload => {
            const queryString = `?${objToQueryString({ ...exportData, ...payload }, true)}`
            const ids = !!checkedList.length ? `&ids=${checkedList.join(",")}` : ""

            return fetch.getRAW(`/exports${queryString}${ids}`)
        },
        onSuccess: async (response, payload) => {
            try {
                const blob = await response.blob()

                const newBlob = new Blob([blob], { type: "application/pdf" })

                let iFrame = document.createElement("iframe")
                iFrame.name = "pdfIframe"
                iFrame.setAttribute("data-pdf", true)
                iFrame.style = "visibility: hidden;"
                iFrame.src = URL.createObjectURL(newBlob)
                document.body.appendChild(iFrame)

                let a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.setAttribute("data-pdf", true)
                a.href = iFrame.src
                a.download = exportData.objectType + "." + payload.export
                a.click()

                iFrame.remove()
                a.remove()
            } catch (error) {
                onError(error)
            }
        },
        onError
    })

    return [handleExport, isExporting]
}
