import React, { useEffect, useState } from "react"

import fetch from "helpers/fetch"
import { queryStringFromParams } from "ui/FilterableTable/helpers"
import { useActions } from "hooks"
import { showErrorNotification } from "actions/notification"

import Layout from "components/Reusable/Layout"
import List from "./components/List"

const Brands = () => {
    const actions = useActions({ showErrorNotification })

    const [callParams, setCallParams] = useState({
        page: 1,
        sort_by: "name",
        sort_direction: "asc",
        query: "",
        custom_vendor: false
    })
    const [brands, setBrands] = useState({
        data: [],
        meta: {}
    })
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetchBrands()
    }, [callParams])

    const fetchBrands = async () => {
        setIsLoading(true)

        try {
            const brandsResult = await fetch.get(`/brands?${queryStringFromParams(callParams)}`)

            setBrands(brandsResult)
        } catch (error) {
            actions.showErrorNotification()
        }

        setIsLoading(false)
    }

    const changePage = page => {
        setCallParams({
            ...callParams,
            page
        })
    }
    const handleChangeTable = (params, isInit) => {
        !isInit &&
            setCallParams({
                ...callParams,
                sort_by: params.sorting.by || "name",
                sort_direction: params.sorting.direction || "asc",
                query: params.search,
                page: 1
            })
    }

    return (
        <Layout title="Manage Brands">
            <List
                brands={brands}
                isLoading={isLoading}
                page={callParams.page}
                changePage={changePage}
                handleChangeTable={handleChangeTable}
                handlePriceVisibilityChange={fetchBrands}
            />
        </Layout>
    )
}

export default Brands
