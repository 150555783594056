import { useMemo } from "react"
import idx from "idx"

const isAssistance = product => !!idx(product, _ => _.product.is_assistance)
const notAssistance = product => !isAssistance(product)

const useOrderItems = order => {
    const onlyProductOrderItems = useMemo(() => order.items.filter(notAssistance), [order.items])
    const onlyAssistanceOrderItems = useMemo(() => order.items.filter(isAssistance), [order.items])
    const orderItems = useMemo(() => [...onlyProductOrderItems, ...onlyAssistanceOrderItems], [
        onlyProductOrderItems,
        onlyAssistanceOrderItems
    ])

    return {
        onlyProductOrderItems,
        onlyAssistanceOrderItems,
        orderItems
    }
}

export default useOrderItems
