import React, { Component } from "react"
import { withRouter, Link as RouterLink } from "react-router-dom"

import { withStyles } from "ui/FilterableTable/HOC"
import { AppContext } from "ui/FilterableTable"

import styles from "./Link.css"

class Link extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {context => <LinkWithContext {...this.props} context={context} />}
            </AppContext.Consumer>
        )
    }
}

class LinkWithContext extends Component {
    render() {
        const { cx, children, location, to, onClick: handleClick, className } = this.props

        return (
            <span className={cx("root", className)}>
                {!to && handleClick && typeof handleClick === "function" && (
                    <span onClick={handleClick}>{children}</span>
                )}
                {to && (
                    <RouterLink
                        to={{
                            pathname: to,
                            state: { back: location.pathname + location.search }
                        }}
                    >
                        {children}
                    </RouterLink>
                )}
            </span>
        )
    }
}

export default withRouter(withStyles(styles)(Link))
