import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getPlaceStart() {
    return {
        type: constants.GET_PLACE_START
    }
}
export function getPlaceSuccess(data) {
    return {
        type: constants.GET_PLACE_SUCCESS,
        payload: {
            data
        }
    }
}
export function getPlaceError(error) {
    return {
        type: constants.GET_PLACE_ERROR,
        payload: {
            error
        }
    }
}
export function getPlace(query = "") {
    return dispatch => {
        dispatch(getPlaceStart())

        return fetch
            .get(`/places?query=${query}`)
            .then(data => dispatch(getPlaceSuccess(data)))
            .catch(error => {
                dispatch(getPlaceError(error))
            })
    }
}
