import fetch from "helpers/fetch"
import useQueryDetailsFactory from "modules/WmsModule/hooks/factories/useQueryDetailsFactory"

export const KEY = "orders"

const fetchOrderDetails = id => fetch.get(`/orders/${id}`)

export const useOrderDetails = useQueryDetailsFactory({
    key: KEY,
    fetchFunction: fetchOrderDetails
})
