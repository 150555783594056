export default {
    headerColumns: [
        {
            name: "NO.",
            width: "5%"
        },
        {
            name: "NAME",
            width: "80%"
        }
    ],
    dataColumns: [
        {
            name: "name",
            width: "80%"
        }
    ]
}
