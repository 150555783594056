import * as constants from "./../constants/index"
import { startModalLogic } from "./../actions"

function handleLogic(action, id) {
    if (!id && id !== 0) {
        return startModalLogic(
            action.payload
                ? action.payload.products
                    ? action.payload.products.map(product => product.id)
                    : action.payload.product
                    ? [action.payload.product.id]
                    : []
                : []
        )
    } else {
        return action
    }
}

const modalLogic = store => next => action => {
    switch (action.type) {
        case constants.SHOW:
        case constants.ADD_PRODUCT:
        case constants.ADD_PRODUCTS:
            return next(handleLogic(action, store.getState().db.selectionsWidget.current.choosenSelection))

        default:
            return next(action)
    }
}

export default modalLogic
