import * as Yup from "yup"
import compose from "lodash/flow"
import { REQUIRED_MESSAGE } from "modules/WmsModule/constants/validationMessages"

export const requiredFieldFactory = schema => schema.required(REQUIRED_MESSAGE)

export const selectOptionSchemaFactory = schema =>
    Yup.object()
        .nullable(true)
        .shape({ value: requiredFieldFactory(schema) })

export const requiredSelectOptionFactory = compose([selectOptionSchemaFactory, requiredFieldFactory])
