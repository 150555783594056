import { Component } from "react"
import ReactDOM from "react-dom"

class Modal extends Component {
    constructor(props) {
        super(props)
        this.modalRoot = document.getElementById("modal-root")
        this.el = document.createElement("div")
    }

    componentDidMount() {
        if (this.modalRoot !== null) {
            this.modalRoot.appendChild(this.el)
            document.body.classList.add("modal-open")
        }
    }
    componentWillUnmount() {
        if (this.modalRoot !== null) {
            this.modalRoot.removeChild(this.el)
            document.body.classList.remove("modal-open")
        }
    }

    render() {
        return ReactDOM.createPortal(this.props.children, this.el)
    }
}

export default Modal
