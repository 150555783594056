import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import { Textarea } from "ui"
import { Purchases, CheckboxesHolder, Newsletter } from "./components"
import styles from "./analytics.module.css"
import textareaStyles from "./overrides/textareaStyles.css"

class Analytics extends Component {
    render() {
        const {
            cx,
            companies,
            channels,
            services,
            clientTypes,
            setFieldValue,
            values,
            handleEdit,
            clientGroups,
            errors,
            sources,
            selectedIds,
            handleSelect,
            handleChangeGeneralNote
        } = this.props

        return (
            <section className={cx("root")}>
                <section className={cx("generalNote")}>
                    <h2>General note</h2>
                    <Textarea
                        placeholder="Click here to add a general note..."
                        customStyles={textareaStyles}
                        value={values.general_note || ""}
                        onChange={({
                            event: {
                                target: { value }
                            }
                        }) => handleChangeGeneralNote(value)}
                        error={errors.general_note}
                    />
                </section>

                <CheckboxesHolder
                    list={companies.data}
                    selectedIds={selectedIds}
                    handleSelect={handleSelect}
                    title="Division"
                    keyId="companies"
                />
                <CheckboxesHolder
                    list={clientTypes.list.data}
                    selectedIds={selectedIds}
                    handleSelect={handleSelect}
                    title="Type of Contact"
                    keyId="types"
                />
                <CheckboxesHolder
                    list={sources.data}
                    selectedIds={selectedIds}
                    handleSelect={handleSelect}
                    title="Source"
                    keyId="sources"
                />
                <CheckboxesHolder
                    list={clientGroups.data}
                    selectedIds={selectedIds}
                    handleSelect={handleSelect}
                    title="Interaction"
                    keyId="groups"
                />
                <CheckboxesHolder
                    list={channels.data}
                    selectedIds={selectedIds}
                    handleSelect={handleSelect}
                    title="Preferred communication channel"
                    keyId="channels"
                />
                <Newsletter values={values} handleEdit={handleEdit} setFieldValue={setFieldValue} />
                <CheckboxesHolder
                    list={services.data}
                    selectedIds={selectedIds}
                    handleSelect={handleSelect}
                    title="Service"
                    keyId="services"
                />
                <Purchases errors={errors} values={values} handleEdit={handleEdit} setFieldValue={setFieldValue} />
            </section>
        )
    }
}

export default withStyles(Analytics, styles)
