import React from "react"
import ReactSvg from "react-svg"

import OrderTab from "../components/OrderTab"
import { withMultiselectConsumer } from "HOC/withMultiselect"

import expandIcon from "assets/expand.svg"

const Invoices = props => {
    const { projectId } = props

    const overrider = tables => {
        const customTable = Object.entries(tables).reduce(
            (acc, [key, value]) => (["employee", "company"].includes(key) ? { ...acc, [key]: value } : acc),
            {}
        )

        return {
            ...customTable,
            "invoice id": { ...tables["invoice id"], style: { width: "auto" } },
            status: { ...tables["status"], style: { width: "20%" } },
            "": {
                ...tables[""],
                renderCell: ({ cx, id, isExpanded, toggleExpanded }) => (
                    <button className={cx("expandButton")} onClick={() => toggleExpanded(id)}>
                        Details
                        <ReactSvg className={cx("expandIcon", { isExpanded })} src={expandIcon} />
                    </button>
                )
            }
        }
    }

    return <OrderTab overrider={overrider} projectId={projectId} type="invoices" {...props} />
}

export default withMultiselectConsumer("invoices")(Invoices)
