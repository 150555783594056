import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import { Formik } from "formik"
import { connect } from "react-redux"
import moment from "moment"

import * as notificationActions from "actions/notification"
import * as clientActions from "actions/client"
import withPermissions from "HOC/withPermissions"
import fetch from "helpers/fetch"

import { Tabs, Toggle, SavePrompt, WarningBar } from "ui"
import { GeneralInfo, Analytics, TaxCode, Temp, Assignees } from "modules/ClientModule/components"
import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"
import { ProfileManagement } from "./components"

import { PERMISSIONS } from "constants/index"

import styles from "./edit.module.css"
import customTabsStyles from "./overrides/tabs.module.css"
import customInputStyles from "./overrides/input.module.css"
import customToggleStyles from "./overrides/toggle.module.css"
import customModalStyles from "./overrides/modal.module.css"
import invitationModalStyles from "./overrides/invitationModal.module.css"
import customWarningStyles from "./overrides/warning.module.css"

import mailIcon from "assets/ico-mail_blue.svg"

const DEFAULT_TAB = "general-info"

class Edit extends Component {
    state = {
        activeTabKey: DEFAULT_TAB,
        inviting: false,
        isInvitationModal: false
    }

    invite = async () => {
        const { clientId, showErrorNotification, showSuccessNotification, getClientById } = this.props

        this.setState({
            inviting: true
        })

        try {
            await fetch.post(`/partners/${clientId}/invite`)
            getClientById(clientId)

            showSuccessNotification({ title: "Mail has been sent" })
        } catch (error) {
            showErrorNotification()
        }

        this.setState({
            inviting: false
        })
    }

    render() {
        const {
            cx,
            initialValues,
            history,
            handleSubmit,
            handleAddresses,
            handleEdit,
            hasChanges,
            schema,
            isNewProfile,
            isDeleteModalShown,
            isDeleteLoading,
            handleDeleteModal,
            isRestoreLoading,
            isRestoreModalShown,
            handleRestoreModal,
            isDeletedClient,
            isTmpTabShown,
            clientId,
            shownAddresses,
            setAddressSameAsMain,
            canRestore
        } = this.props

        const { activeTabKey } = this.state

        return (
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                {({ handleSubmit, setFieldValue, errors, values, isSubmitting }) => {
                    const handleReset = () => {
                        if (isNewProfile) {
                            return history.push("/contact")
                        }
                        return history.goBack()
                    }

                    const name = `${initialValues.first_name} ${initialValues.last_name}`
                    const tabs = {
                        "general-info": {
                            label: "General Info",
                            Component: (
                                <GeneralInfo
                                    errors={errors}
                                    handleEdit={handleEdit}
                                    handleAddresses={handleAddresses}
                                    values={values}
                                    isNewProfile={isNewProfile}
                                    setFieldValue={setFieldValue}
                                    shownAddresses={shownAddresses}
                                    setAddressSameAsMain={setAddressSameAsMain}
                                    customInputStyles={customInputStyles}
                                />
                            )
                        },
                        analytics: {
                            label: "Analytics",
                            Component: (
                                <Analytics
                                    values={values}
                                    errors={errors}
                                    handleEdit={handleEdit}
                                    setFieldValue={setFieldValue}
                                    isNewProfile={isNewProfile}
                                    customInputStyles={customInputStyles}
                                />
                            )
                        },
                        "tax-code": {
                            label: "Tax Code",
                            Component: (
                                <TaxCode
                                    handleEdit={handleEdit}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    customInputStyles={customInputStyles}
                                />
                            )
                        },
                        Temp: {
                            label: "Temp",
                            Component: (
                                <Temp
                                    errors={errors}
                                    handleEdit={handleEdit}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    customInputStyles={customInputStyles}
                                />
                            )
                        }
                    }
                    const filteredTabs = Object.entries(tabs).reduce(
                        (acc, [key, tab]) => (key !== "Temp" || isTmpTabShown ? { ...acc, [key]: tab } : acc),
                        {}
                    )

                    return (
                        <form className={cx("root")} onSubmit={handleSubmit}>
                            {isDeleteModalShown && this.renderDeleteModal(isDeleteLoading)}
                            {isRestoreModalShown && this.renderRestoreModal(isRestoreLoading)}
                            <header className={cx("top")}>
                                {isNewProfile && <h1>New Contact</h1>}
                                {!isNewProfile && (
                                    <h1>
                                        Edit contact: <span>{name}</span>
                                    </h1>
                                )}
                                {!isNewProfile && (
                                    <ProfileManagement
                                        history={history}
                                        isDeletedClient={isDeletedClient}
                                        handleDeleteModal={handleDeleteModal}
                                        handleRestoreModal={handleRestoreModal}
                                        clientId={clientId}
                                        canRestore={canRestore}
                                    />
                                )}
                            </header>
                            <div className={cx("holder")}>
                                <section className={cx("content")}>
                                    <Tabs
                                        activeTabKey={activeTabKey}
                                        customStyles={customTabsStyles}
                                        onTabChange={key => this.setState({ activeTabKey: key })}
                                        tabs={filteredTabs}
                                    />
                                    {tabs[activeTabKey].Component}
                                </section>
                                <aside className={cx("sidebar")}>
                                    <h2>Options</h2>

                                    <div className={cx("assignTo")}>
                                        <Assignees
                                            setFieldValue={setFieldValue}
                                            handleEdit={handleEdit}
                                            selectedUsers={values.users || []}
                                        />
                                    </div>

                                    {this.renderOptions({ setFieldValue, values })}
                                    {this.renderOnlineStore({ setFieldValue, values })}
                                </aside>
                            </div>
                            <SavePrompt
                                message="Unsaved changes"
                                submitLabel={isNewProfile ? "Create" : "Save"}
                                isShown={hasChanges}
                                isSaving={isSubmitting}
                                onSubmit={() => this.handleSubmit(errors)}
                                onCancel={() => handleReset()}
                            />
                        </form>
                    )
                }}
            </Formik>
        )
    }

    handleSubmit = errors => {
        if (Object.keys(errors).length > 0) {
            this.setState(
                {
                    activeTabKey: DEFAULT_TAB
                },
                () =>
                    window.scrollTo({
                        behavior: "smooth",
                        top: 0,
                        left: 0
                    })
            )
        }
    }

    renderDeleteModal(isLoading) {
        const { handleDeleteModal, handleDelete, initialValues, clientId } = this.props
        const name = `${initialValues.first_name} ${initialValues.last_name}`

        return (
            <Modal>
                <Confirm
                    id={clientId}
                    isLoading={isLoading}
                    customStyles={customModalStyles}
                    confirmModalTitle="Are you sure you want to remove this client?"
                    titleSecondLine={`Are you sure you want to remove client "${name}"?`}
                    handleDelete={() => {
                        handleDelete(clientId)
                    }}
                    handleHideModal={() => handleDeleteModal(false)}
                />
            </Modal>
        )
    }

    renderRestoreModal(isLoading) {
        const { handleRestoreModal, handleRestore, initialValues, clientId } = this.props
        const name = `${initialValues.first_name} ${initialValues.last_name}`

        return (
            <Modal>
                <Confirm
                    id={clientId}
                    isLoading={isLoading}
                    customStyles={customModalStyles}
                    confirmModalTitle="Are you sure you want to restore this client?"
                    titleSecondLine={`Are you sure you want to restore client "${name}"?`}
                    handleDelete={() => handleRestore(clientId)}
                    handleHideModal={() => handleRestoreModal(false)}
                    actionButtonLabel="Restore"
                />
            </Modal>
        )
    }

    renderOptions({ setFieldValue, values }) {
        const { handleEdit } = this.props
        const { active, verified, partner } = values
        const { approved_at } = partner || {}

        const setValue = key => {
            handleEdit(true)
            setFieldValue(key, !values[key])
        }

        return (
            <ul>
                <li>
                    {!!partner && !approved_at && (
                        <WarningBar customStyles={customWarningStyles} text="Awaiting to accept." />
                    )}
                    <span>Verified</span>
                    <Toggle
                        customStyles={customToggleStyles}
                        isOn={verified}
                        handleChange={() => setValue("verified")}
                        label={{ on: "Yes", off: "No" }}
                        labelPosition="left"
                    />
                </li>
                <li>
                    <span>Status</span>
                    <Toggle
                        customStyles={customToggleStyles}
                        isOn={active}
                        handleChange={() => setValue("active")}
                        label={{ on: "Active", off: "Not active" }}
                        labelPosition="left"
                    />
                </li>
            </ul>
        )
    }

    renderOnlineStore({ setFieldValue, values }) {
        const { handleEdit, cx, client } = this.props
        const { online_enabled } = values
        const { email, last_online_visits, invited_at, partner } = client || {}
        const { existing, merged, verified: partnerVerified } = partner || {}

        const setValue = key => {
            handleEdit(true)
            setFieldValue(key, !values[key])
        }

        const isInviteEnabled = !last_online_visits && !!email && !partner
        const isInviteAgainEnabled = !last_online_visits && !!email && !!invited_at && !partnerVerified

        return (
            <React.Fragment>
                <h2 className={cx("withBorder")}>Online store</h2>
                <ul>
                    <li>
                        {!email && (
                            <WarningBar
                                customStyles={customWarningStyles}
                                text="You have to add email address to this client."
                            />
                        )}
                        {!!partner && existing && !merged && (
                            <WarningBar customStyles={customWarningStyles} text="Awaiting to merge." />
                        )}
                        <span className={cx({ disabled: !email })}>Enabled access</span>
                        <div className={cx({ disabled: !email })}>
                            <Toggle
                                customStyles={customToggleStyles}
                                isOn={online_enabled}
                                handleChange={() => setValue("online_enabled")}
                                label={{ on: "Yes", off: "No" }}
                                labelPosition="left"
                                isDisabled={!email}
                            />
                        </div>
                    </li>
                    {(isInviteEnabled || isInviteAgainEnabled) && (
                        <li className={cx("linkWrapper")}>
                            <div className={cx("title")}>
                                {isInviteAgainEnabled
                                    ? `Already invited at ${moment(invited_at).format("MMM D, YYYY")}`
                                    : "Not logged in yet"}
                            </div>
                            <div className={cx("link")} onClick={() => this.setState({ isInvitationModal: true })}>
                                <img src={mailIcon} alt="mail ico" />
                                {!!isInviteAgainEnabled ? "Invite again to Online Shop" : "Invite to Online Shop"}
                            </div>
                            {this.state.isInvitationModal && (
                                <Modal>
                                    <Confirm
                                        isLoading={this.state.inviting}
                                        customStyles={invitationModalStyles}
                                        confirmModalTitle="Invitation confirmation"
                                        titleSecondLine="Are you sure you want to send invitation to client?"
                                        handleDelete={this.invite}
                                        handleHideModal={() => this.setState({ isInvitationModal: false })}
                                        actionButtonLabel="Send"
                                    />
                                </Modal>
                            )}
                        </li>
                    )}
                    {!!(partner || {}).created_at && (
                        <li className={cx("memberWrapper")}>
                            <div className={cx("title")}>
                                {`Member since ${moment(partner.created_at).format("MMM D, YYYY")}`}
                            </div>
                        </li>
                    )}
                </ul>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSidebarMenuWide: state.menuStore.isSidebarMenuWide
    }
}
const mapDispatchToProps = dispatch => ({
    showSuccessNotification: data => dispatch(notificationActions.showSuccessNotification(data)),
    showErrorNotification: data => dispatch(notificationActions.showErrorNotification(data)),
    getClientById: id => dispatch(clientActions.getClientById(id))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPermissions(withStyles(Edit, styles), PERMISSIONS.context.CLIENTS))
