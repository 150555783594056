import React, { useEffect, useState, Fragment, useMemo } from "react"
import { useSelector } from "react-redux"
import { Route, Switch, Redirect } from "react-router-dom"
import { useActions } from "hooks"
import { getOrder } from "actions/orders"

import { formatDate } from "helpers/date"
import withStyles from "HOC/withStyles"
import Skeleton, { SnakeLoader } from "ui/Skeleton"

import styles from "./orderoverview.module.css"

import ReturnHeader from "components/Reusable/ReturnHeader/ReturnHeader"
import returnHeaderCustomStyles from "./overrides/returnheader.module.css"
import Header from "./components/Header"

import { Tabs } from "ui"

import config from "./overviewConfig"

const Overview = props => {
    const {
        cx,
        history,
        match: { params },
        orderType
    } = props

    const overviewConfig = useMemo(() => ({ ...config[orderType], orderType } || {}), [orderType])
    const { name, ordersRoute, tabs: tabConfig } = overviewConfig

    const currentOrder = useSelector(state => state.db.orders.current)
    const {
        created_at,
        issued_at,
        valid_date,
        delivery_date,
        revised_at,
        printed_at,
        emailed_at,
        updated_at,
        type,
        status,
        attachments
    } = currentOrder.data
    const { orderId, sectionName } = params
    const [basePath, setBasePath] = useState(`/orders/${ordersRoute}/${orderId}`)
    const actions = useActions({ getOrder })

    useEffect(() => {
        setBasePath(`/orders/${ordersRoute}/${orderId}`)
        actions.getOrder(orderId, true)
    }, [orderId])

    const tabs = useMemo(
        () =>
            Object.entries(tabConfig || {}).reduce((acc, [key, value]) => {
                const newValue =
                    key === "attachments"
                        ? { ...value, count: Array.isArray(attachments) ? attachments.length : 0 }
                        : value
                return { ...acc, [key]: newValue }
            }, {}),
        [attachments]
    )

    const order = {
        ...currentOrder.data,
        created: formatDate(created_at),
        lastUpdated: formatDate(updated_at),
        revisedAt: formatDate(revised_at),
        validDate: formatDate(valid_date),
        deliveryDate: formatDate(delivery_date),
        issuedAt: formatDate(issued_at),
        printedAt: formatDate(printed_at),
        emailedAt: formatDate(emailed_at),
        label: {
            type,
            status
        }
    }

    const routeProps = useMemo(() => ({ order, overviewConfig }), [order, overviewConfig])
    const { id, uuid, label, client, created, lastUpdated } = order

    return (
        <Skeleton
            fetchStatus={currentOrder.fetchStatus}
            SkeletonComponent={() => (
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            )}
            fallback={error => {
                if (error.status === 404) {
                    return <Redirect to="/404" />
                }

                return <Redirect to={`/orders/${ordersRoute}`} />
            }}
        >
            <Fragment>
                <ReturnHeader
                    link={`/orders/${ordersRoute}`}
                    label={`All ${name}s`}
                    isArrow={true}
                    customStyles={returnHeaderCustomStyles}
                />
                <Header
                    id={id}
                    uuid={uuid}
                    label={label}
                    client={client}
                    created={created}
                    lastUpdated={lastUpdated}
                    inHouseSale={order.in_house_sale}
                    overviewConfig={overviewConfig}
                />

                {Object.keys(tabs).length > 1 && (
                    <Tabs
                        tabs={tabs}
                        activeTabKey={sectionName || Object.keys(tabs)[0]}
                        onTabChange={sectionName => history.push(`${basePath}/${sectionName}`)}
                    />
                )}
                <Switch>
                    {currentOrder.fetchStatus.isLoaded &&
                        Object.entries(tabs).map(([route, { render }]) => (
                            <Route key={route} exact path={`${basePath}/${route}`} render={() => render(routeProps)} />
                        ))}
                    <Route
                        path={`${basePath}`}
                        render={() => <Redirect to={`${basePath}/${Object.keys(tabs)[0]}`} />}
                    />
                </Switch>
            </Fragment>
        </Skeleton>
    )
}

export default withStyles(Overview, styles)
