import React from "react"
import { useParams } from "react-router-dom"

import CollectionForm from "modules/ContentModule/components/CollectionForm"

const SIDEBAR_IMAGES_DIMENSIONS = {
    thumbnail_horizontal: {
        width: 1440,
        height: 490
    },
    thumbnail_vertical: {
        width: 736,
        height: 1086
    },
    home_page: {
        width: 2560
    }
}

const Form = () => {
    const { newsId } = useParams()

    return (
        <CollectionForm
            withHomepagePicture
            collectionLabel="News"
            sectionableType="post"
            collectionId={Number(newsId)}
            basePath="/content/news"
            apiUrl="/posts"
            sidebarImagesDimensions={SIDEBAR_IMAGES_DIMENSIONS}
        />
    )
}

export default Form
