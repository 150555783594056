import React from "react"

import withStyles from "HOC/withStyles"

import styles from "./styles/ListItem.css"
import { DropList } from "ui"

const ListItem = props => {
    const { cx, locationItem, children, handleEdit, handleDelete } = props

    if (typeof children === "function") {
        return children(props)
    }

    function renderDropList() {
        return (
            <div>
                <DropList
                    trigger={
                        <div className={cx("editButton")}>
                            <span />
                            <span />
                            <span />
                        </div>
                    }
                >
                    <DropList.List>
                        <DropList.Item
                            onClick={() => {
                                handleEdit(locationItem.id)
                            }}
                        >
                            Edit
                        </DropList.Item>
                        <DropList.Divider />
                        <DropList.Item
                            className="danger"
                            onClick={() => {
                                handleDelete(locationItem.id, locationItem.name)
                            }}
                        >
                            Delete
                        </DropList.Item>
                    </DropList.List>
                </DropList>
            </div>
        )
    }

    return (
        <div className={cx("listItem")}>
            <div className={cx("nameContainer", "nameItem")}>
                <span>{locationItem.name}</span>
            </div>
            <div className={cx("optionContainer")}>
                <hr className={cx("line")}></hr>
                {renderDropList()}
            </div>
        </div>
    )
}

export default withStyles(ListItem, styles)
