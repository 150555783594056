import React from "react"

import withStyles from "HOC/withStyles"
import { Checkbox } from "ui"

import styles from "./CheckboxItem.css"
import stylesCheckbox from "./overrides/Checkbox.css"

const CheckboxItem = withStyles(props => {
    const { cx, id, name, checked, handleChange, disabled } = props

    return (
        <div className={cx("item", { disabled })}>
            <Checkbox
                customStyles={stylesCheckbox}
                disabled={disabled}
                name={name}
                id={id}
                checked={checked}
                onChange={handleChange}
            />
        </div>
    )
}, styles)

export default withStyles(CheckboxItem, styles)
