import { useMemo } from "react"

import STOCK_ITEM_COUNTERS from "modules/WmsModule/constants/stockItemCounters"

const useCounters = ({ counterKeys, countersFromApi }) => {
    const counters = useMemo(
        () =>
            countersFromApi
                ? counterKeys.map(counterKey => ({
                      id: STOCK_ITEM_COUNTERS[counterKey].id,
                      label: STOCK_ITEM_COUNTERS[counterKey].label,
                      value: countersFromApi[counterKey]
                  }))
                : [],
        [counterKeys, countersFromApi]
    )

    return counters
}

export default useCounters
