import React from "react"
import { useMutation, useQueryClient } from "react-query"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"
import { Modal } from "@butterfly-frontend/ui"

import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"
import { useActions } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { Button, Modal as OldModal, Input, WysiwygEditor } from "ui"

import submitButtonStyles from "./overrides/SubmitButton.css"
import styles from "./EditTermsAndConditionsTemplate.module.css"

const validationSchema = Yup.object().shape({
    content: Yup.string().required("This field is required."),
    title: Yup.string().required("This field is required.")
})

const EditTermsAndConditionsTemplate = ({ cx, handleClose, termsToEdit }) => {
    const { id, key, value, name } = termsToEdit
    const actions = useActions({ showErrorNotification, showSuccessNotification })
    const queryClient = useQueryClient()

    const termSettingsMutation = useMutation(
        ({ title, content }) => fetch.patch(`/settings/${id}`, { key, value: { title, content } }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("settings")
                actions.showSuccessNotification()
                handleClose()
            }
        }
    )

    const onSubmit = async (values, { setErrors }) => {
        const { title, content } = values
        return termSettingsMutation.mutateAsync({ title, content }).catch(error => {
            const { errors: dataErrors, message } = error
            const errors = dataErrors || (message ? { message } : null)
            if (errors) {
                setErrors(errors)
            }
            actions.showErrorNotification()
        })
    }

    const { values, errors, setFieldValue, isSubmitting, handleSubmit } = useFormik({
        initialValues: value ? value : { content: "", title: name },
        validationSchema,
        onSubmit
    })

    const renderBody = () => {
        return (
            <form className={cx("form")} onSubmit={handleSubmit}>
                <div className={cx("row", "type-container")}>
                    <label className={cx("label")}>Title:</label>
                    <Input
                        error={errors.title}
                        value={values.title}
                        onChange={({ event }) => {
                            setFieldValue("title", event.target.value)
                        }}
                    />
                </div>
                <div className={cx("row")}>
                    <label className={cx("label")}>Terms:</label>
                    <WysiwygEditor
                        value={values.content}
                        onChange={termsMarkdown => {
                            setFieldValue("content", termsMarkdown)
                        }}
                        error={errors.content}
                        customModules={{
                            toolbar: [
                                [{ header: [1, false] }],
                                ["bold", "italic", "underline", "strike"],
                                [{ list: "ordered" }],
                                ["clean"]
                            ]
                        }}
                    />
                </div>
                <div className={cx("buttons")}>
                    <span className={cx("form-cancel")} onClick={handleClose}>
                        Cancel
                    </span>
                    <Button
                        type="submit"
                        className="first-button"
                        customStyles={submitButtonStyles}
                        label="Save"
                        isLoading={isSubmitting}
                    />
                </div>
            </form>
        )
    }

    return (
        <Modal onClose={handleClose}>
            <OldModal.Header>Edit Terms and Conditions</OldModal.Header>
            <OldModal.Body>{renderBody()}</OldModal.Body>
        </Modal>
    )
}

EditTermsAndConditionsTemplate.propTypes = {
    termsToEdit: PropTypes.shape({
        content: PropTypes.string,
        title: PropTypes.string
    }).isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withStyles(EditTermsAndConditionsTemplate, styles)
