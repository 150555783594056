import * as yup from "yup"

export const collection = yup.object().shape({
    title: yup
        .string()
        .max(250, "Title may not be greater than 250 characters")
        .required("Title is required"),
    subtitle: yup
        .string()
        .max(250, "Subtitle may not be greater than 250 characters")
        .nullable(),
    description: yup
        .string()
        .max(9999, "Description may not be greater than 9999 characters")
        .nullable(),
    cover_desktop: yup.object().nullable()
})
