import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    fetchStatus: fetchStatus.initialState()
}

function priceStructure(state = initialState, action) {
    switch (action.type) {
        case constants.GET_PRICE_STRUCTURES_START:
            return {
                ...state,
                data: [],
                fetchStatus: fetchStatus.requestState()
            }
        case constants.GET_PRICE_STRUCTURES_SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                fetchStatus: fetchStatus.successState()
            }
        case constants.GET_PRICE_STRUCTURES_ERROR:
            return {
                ...state,
                data: [],
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }

        default:
            return state
    }
}

export default priceStructure
