import fetch from "helpers/fetch"
import useQueryDetailsFactory from "modules/WmsModule/hooks/factories/useQueryDetailsFactory"
import useMutationFactory from "modules/WmsModule/hooks/factories/useMutationFactory"

export const KEY = "purchase-orders"

const fetchPurchaseOrderDetails = id => fetch.get(`/purchase-orders/${id}`)

const fetchPurchaseOrderCreate = ({ data }) => fetch.postRAW("/purchase-orders", data)
const fetchPurchaseOrderEdit = ({ id, data }) => fetch.patch(`/purchase-orders/${id}`, data)

export const usePurchaseOrderDetails = useQueryDetailsFactory({
    key: KEY,
    fetchFunction: fetchPurchaseOrderDetails
})

export const usePurchaseOrderOrderCreate = useMutationFactory({ key: KEY, fetchFunction: fetchPurchaseOrderCreate })
export const usePurchaseOrderOrderEdit = useMutationFactory({ key: KEY, fetchFunction: fetchPurchaseOrderEdit })
