import React, { Component, Fragment } from "react"
import ReturnHeader from "components/Reusable/ReturnHeader/ReturnHeader"
import AddNewUser from "./AddNewUser/AddNewUser"

class Form extends Component {
    render() {
        const { isEdit } = this.props
        return (
            <Fragment>
                <ReturnHeader
                    link="/settings/users"
                    label="User Management"
                    title={isEdit ? "Edit User" : "New User"}
                />
                <AddNewUser {...this.props} />
            </Fragment>
        )
    }
}

export default Form
