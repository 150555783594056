import React from "react"
import ReactSvg from "react-svg"

import OrderTab from "../components/OrderTab"
import { withMultiselectConsumer } from "HOC/withMultiselect"

import expandIcon from "assets/expand.svg"

const PurchaseOrders = props => {
    const { projectId } = props

    const overrider = tables => {
        return {
            ...tables,
            "po id": {
                ...tables["po id"],
                style: { width: "10%" }
            },
            vendor: {
                ...tables["vendor"],
                style: { width: "auto" }
            },
            "": {
                ...tables[""],
                renderCell: ({ cx, id, isExpanded, toggleExpanded }) => (
                    <button className={cx("expandButton")} onClick={() => toggleExpanded(id)}>
                        Details
                        <ReactSvg className={cx("expandIcon", { isExpanded })} src={expandIcon} />
                    </button>
                )
            }
        }
    }

    return <OrderTab projectId={projectId} type="purchase-orders" overrider={overrider} {...props} />
}

export default withMultiselectConsumer("purchaseOrders")(PurchaseOrders)
