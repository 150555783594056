import React, { useState } from "react"
import { SelectWithSearch } from "ui"
import fetch from "helpers/fetch"
import useAddBrand from "./useAddBrand"

const brandOptionMapper = item => ({ id: item.id, value: item.id, label: item.name })

const SelectBrand = props => {
    const {
        customStyles,
        handleSelect,
        name,
        value,
        label,
        multiselect,
        withAddButton = false,
        isRequired,
        error,
        isDisabled
    } = props
    const [brands, setBrands] = useState([])
    const [pagination, setPagination] = useState({ current_page: 0, last_page: 1 })
    const [isLoading, setIsLoading] = useState(false)

    const { setBrandName, addBrand, isBrandAdding } = useAddBrand({
        onSuccess: brand => setValue(brandOptionMapper(brand))
    })

    const fetchBrands = (query, page) => {
        if (isLoading) {
            return
        }

        const formatData = data => data.map(brandOptionMapper)
        setIsLoading(true)

        fetch
            .get(`/brand?query=${query}&page=${page}&length=20`)
            .then(payload => {
                setBrands(prevBrands =>
                    page === 1 ? formatData(payload.data) : [...prevBrands, ...formatData(payload.data)]
                )
                setPagination({ ...payload.meta, current_page: page })
            })
            .finally(() => setIsLoading(false))
    }

    const showAddOptions = withAddButton && !value

    return (
        <SelectWithSearch
            name={name}
            label={label}
            value={value}
            values={brands}
            setValue={setValue}
            placeholder="- Please select -"
            fetchValues={fetchBrands}
            customStyles={customStyles}
            multiselect={multiselect}
            pagination={pagination}
            onInputChange={showAddOptions ? setBrandName : undefined}
            additionalButtonLabel="Create new brand:"
            additionalButtonAction={showAddOptions ? addBrand : undefined}
            isDisabled={(showAddOptions && isBrandAdding) || isDisabled}
            showLoadingIcon={showAddOptions && isBrandAdding}
            error={error}
            isRequired={isRequired}
            disableBtnForEmptyValue
            appendValueToBtnLabel
        />
    )

    function setValue(value) {
        handleSelect({ target: { name, value: multiselect ? value : value.value ? value : null } })
    }
}

SelectBrand.defaultProps = {
    value: [],
    multiselect: false
}

export default SelectBrand
