import React from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import _get from "lodash/get"
import classNames from "classnames"

import { DetailsCard } from "@butterfly-frontend/ui"
import { SelectWithSearch } from "ui"
import { StatusSelectProductConditions } from "ui/Filters/SelectProductConditions"
import { SelectChangeReason, SelectWarehouse } from "ui/Filters"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import STOCK_ITEM_STATUS_SETTER_OPTIONS from "modules/WmsModule/constants/stockItemStatusSetterOptions"
import ON_HOLD_FOR_SELECT_OPTIONS from "modules/WmsModule/constants/forSelectOptions"
import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"

import styles from "./HeroForm.module.css"

const HeroForm = ({ stockItem, formik }) => {
    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)
    const { id } = stockItem
    const getSelectProps = getSelectPropsFactory(formik)

    const setStatusValue = value => {
        const selectedStatus = _get(value, "value")

        if (
            selectedStatus === STOCK_ITEM_STATUS_KEYS.IN_STOCK ||
            selectedStatus === STOCK_ITEM_STATUS_KEYS.NOT_FOR_SALE
        ) {
            formik.setFieldValue("for", null)
            formik.setFieldValue("reason", null)
        }

        formik.setFieldValue("status", value)
    }

    const isOnHoldStatusSelected = _get(formik, "values.status.value") === STOCK_ITEM_STATUS_KEYS.ON_HOLD

    return (
        <div className={styles.details}>
            <div className={styles.detailsRow}>
                <DetailsCard header="ID" classes={{ root: styles.idCard }}>
                    {id}
                </DetailsCard>
                <div className={classNames(styles.selectContainer, styles.warehouseSelect)}>
                    <SelectWarehouse
                        {...getSelectProps("warehouse")}
                        label="Warehouse"
                        isRequired
                        warehouseType="active"
                        warehouseIds={assignedWarehouseIds}
                    />
                </div>
                <DetailsCard header="CONDITION" classes={{ root: styles.conditionSelectCard }}>
                    <div className={styles.conditionSelect}>
                        <StatusSelectProductConditions
                            excludedTypes={[CONDITION_TYPES.REMOVED, CONDITION_TYPES.INCOMPLETE]}
                            value={formik.values.condition}
                            onChange={option => formik.setFieldValue("condition", option)}
                        />
                    </div>
                </DetailsCard>
            </div>
            <div className={styles.detailsRow}>
                <div className={classNames(styles.selectContainer, styles.statusSelect)}>
                    <SelectWithSearch
                        {...getSelectProps("status", {
                            setValue: setStatusValue
                        })}
                        label="Status"
                        values={STOCK_ITEM_STATUS_SETTER_OPTIONS}
                        isClearBlocked
                        withoutFetch
                        isRequired
                    />
                </div>
                <div className={classNames(styles.selectContainer, styles.forSelect)}>
                    <SelectWithSearch
                        {...getSelectProps("for", {
                            isDisabled: !isOnHoldStatusSelected || formik.isSubmitting
                        })}
                        label="For"
                        values={ON_HOLD_FOR_SELECT_OPTIONS}
                        isRequired={isOnHoldStatusSelected}
                        isClearBlocked
                        withoutFetch
                    />
                </div>
                <div className={classNames(styles.selectContainer, styles.reasonSelect)}>
                    <SelectChangeReason
                        {...getSelectProps("reason", {
                            isDisabled: !isOnHoldStatusSelected || formik.isSubmitting
                        })}
                        isRequired={isOnHoldStatusSelected}
                        label="Reason"
                    />
                </div>
            </div>
        </div>
    )
}

HeroForm.propTypes = {
    stockItem: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired,
    formik: PropTypes.shape({
        values: PropTypes.shape({
            condition: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.shape({
                    id: PropTypes.number
                }).isRequired,
                color: PropTypes.string.isRequired,
                type: PropTypes.string
            }),
            warehouse: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired
            }).isRequired,
            status: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            }).isRequired,
            for: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired
            }).isRequired,
            reason: PropTypes.shape({
                label: PropTypes.string.isRequired,
                value: PropTypes.string.isRequired
            }).isRequired
        }).isRequired,
        setFieldValue: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool.isRequired
    })
}

export default HeroForm
