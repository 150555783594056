import * as Yup from "yup"

import { requiredFieldFactory, requiredSelectOptionFactory } from "modules/WmsModule/helpers/schema"
import {
    DESCRIPTION_SCHEMA,
    IMAGES_SCHEMA,
    REQUIRED_IMAGES_SCHEMA,
    COMBINATION_ATTRIBUTES_SCHEMA
} from "modules/WmsModule/constants/schema"
import { STOCK_ITEM_QUANTITY_TO_ADD } from "modules/WmsModule/constants/stockItemQuantity"

const conditionOptionSchema = Yup.object().shape({
    id: Yup.number().required()
})

const locationSchema = Yup.object()
    .shape({
        id: Yup.number().required(),
        name: Yup.string().required()
    })
    .nullable()

const boxSchema = Yup.object().shape({
    location: locationSchema,
    name: Yup.string()
})

const boxesSchema = Yup.array().of(boxSchema)

const conditionImagesSchema = IMAGES_SCHEMA.when(["quantity", "condition.value.require_images"], {
    is: (quantity, require_images) => quantity === 1 && require_images,
    then: REQUIRED_IMAGES_SCHEMA
})

const productSelectSchema = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required(),
    has_combinations: Yup.bool().required()
})

const productSchema = requiredFieldFactory(Yup.string().nullable()).when(
    ["addStockItemFromExistingProducts"],
    (addStockItemFromExistingProducts, schema) =>
        addStockItemFromExistingProducts ? requiredSelectOptionFactory(productSelectSchema) : schema
)

export default Yup.object().shape({
    useImperial: Yup.bool(),
    height: requiredFieldFactory(Yup.number()),
    width: requiredFieldFactory(Yup.number()),
    weight: requiredFieldFactory(Yup.number()),
    length: requiredFieldFactory(Yup.number()),
    conv_length: Yup.number(),
    conv_width: Yup.number(),
    conv_height: Yup.number(),
    conv_weight: Yup.number(),
    product: productSchema,
    description: DESCRIPTION_SCHEMA,
    brand: requiredSelectOptionFactory(Yup.number()),
    warehouse: requiredSelectOptionFactory(Yup.number()),
    location: locationSchema,
    condition: requiredSelectOptionFactory(conditionOptionSchema),
    status: requiredSelectOptionFactory(Yup.string()),
    conditionImages: conditionImagesSchema,
    boxes: boxesSchema,
    quantity: Yup.number()
        .min(STOCK_ITEM_QUANTITY_TO_ADD.MIN)
        .max(STOCK_ITEM_QUANTITY_TO_ADD.MAX)
        .integer()
        .required(),
    selectedCombinationAttributes: COMBINATION_ATTRIBUTES_SCHEMA
})
