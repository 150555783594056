import React from "react"
import { Link } from "react-router-dom"

import Toggle from "ui/Toggle"
import { Image } from "modules/ContentModule/components"

import withStyles from "HOC/withStyles"
import styles from "./categoriesListRow.css"
import imageStyles from "./overrides/image.css"

import addIcon from "assets/add.svg"
import editIcon from "assets/pencil.svg"

const ListRow = props => {
    const {
        cx,
        id,
        name,
        subcategories_count,
        filters_count,
        active,
        changeVisible,
        cover_image,
        handleUploadModal,
        hover_image,
        stock_image
    } = props
    const handleChangeVisible = () => changeVisible(id)
    const category = {
        id,
        name,
        active,
        cover_image,
        hover_image,
        stock_image
    }
    const text = cover_image ? "Edit Image" : "Add Image"
    const icon = cover_image ? editIcon : addIcon

    return (
        <div className={cx("root", { notVisible: !active, isEmpty: !subcategories_count })}>
            <Image customStyles={imageStyles} src={cover_image && `${cover_image}`} />
            <Link className={cx("name")} to={`/content/categories-menu/${id}`}>
                {name}
            </Link>
            <div>{subcategories_count}</div>
            <div>{filters_count}</div>
            <Toggle isOn={active} handleChange={handleChangeVisible} />
            <div>
                <a onClick={() => handleUploadModal(category)} className={cx("action-link")}>
                    <img src={icon} alt="pencil" /> {text}
                </a>
            </div>
        </div>
    )
}

export default withStyles(ListRow, styles)
