import React, { useState, useEffect } from "react"
import { SelectWithSearch } from "ui"
import fetch from "helpers/fetch"
import { displayUserName } from "helpers/user"
import selectStyles from "../../../overrides/select.css"

const SelectUser = props => {
    const { users, name } = props
    const [pagination, setPagination] = useState({ current_page: 0, last_page: 1 })
    const [values, setValues] = useState([])
    const [value, setValue] = useState({ label: "" })
    const [isLoading, setIsLoading] = useState(false)
    const [rawData, setRawData] = useState([])

    useEffect(() => {
        setValues(filterData(rawData))
    }, [users])

    useEffect(() => {
        setValues(filterData(rawData))
    }, [rawData])

    useEffect(() => {
        const { value } = props
        setValue({ label: displayUserName(value), value })
    }, [props.value])

    const filterData = data => {
        return data.reduce((arr, { value: item }) => {
            return users.some(user => user && user.id === item.id)
                ? arr
                : [...arr, { value: item, label: displayUserName(item), secondLabel: "" }]
        }, [])
    }

    const fetchUsers = (query, page) => {
        if (isLoading) return

        const formatData = data => {
            return data.map(item => ({ value: item, label: displayUserName(item), secondLabel: "" }))
        }

        setIsLoading(true)

        fetch
            .get(`/user?query=${query}&page=${page}&length=15&sort_by=first_name`)
            .then(data => {
                setRawData(page === 1 ? formatData(data.data) : [...rawData, ...formatData(data.data)])
                setPagination({ ...data.meta, current_page: page })
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <SelectWithSearch
            name={name}
            value={value.label}
            values={values}
            pagination={pagination}
            setValue={({ value }) => props.handleSelect({ target: { name, value } })}
            placeholder="- Please select -"
            fetchValues={fetchUsers}
            customStyles={selectStyles}
        />
    )
}

export default SelectUser
