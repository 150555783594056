import React, { Fragment } from "react"
import { Field } from "formik"
import PropTypes from "prop-types"
import _compose from "lodash/flow"

import { Input, Quantity } from "ui"
import { formatPrice } from "helpers/units"
import calculateTotalPrice from "helpers/calculateTotalPrice"
import styles from "./ManualEntryPriceForm.module.css"

const getTotalPriceFromValues = _compose(
    ({ price, quantity, discount, tax_code }) => ({
        price: Number(price || 0),
        quantity: Number(quantity || 0),
        discount: Number(discount || 0),
        tax: Number(tax_code || 0)
    }),
    calculateTotalPrice,
    formatPrice
)

const ManualEntryPriceForm = ({ handleChangeField, fieldStyles, values, errors }) => (
    <div className={styles.priceForm}>
        <div className={styles.itemPriceColumn}>
            <Field name="price">
                {({ field, meta: { touched } }) => (
                    <Input
                        type="number"
                        value={field.value || ""}
                        onChange={value => handleChangeField(field.name, value.event.target.value)}
                        label="Item price"
                        className={styles.price}
                        error={touched && errors[field.name]}
                        customStyles={fieldStyles}
                        innerLabel="$"
                        isInnerLabelLeft={true}
                        attributes={{
                            required: true
                        }}
                    />
                )}
            </Field>
        </div>

        <div className={styles.itemQtyColumn}>
            <Field name="quantity">
                {({ field, meta: { touched } }) => (
                    <Fragment>
                        <label>Qty:</label>
                        <Quantity
                            value={field.value}
                            min={1}
                            onChange={value => handleChangeField(field.name, value)}
                            error={touched && errors[field.name]}
                            customStyles={fieldStyles}
                        />
                    </Fragment>
                )}
            </Field>
        </div>

        <div className={styles.itemDiscountColumn}>
            <Field name="discount">
                {({ field, meta: { touched } }) => (
                    <Input
                        type="number"
                        value={field.value}
                        onChange={value => handleChangeField(field.name, value.event.target.value)}
                        label="Discount"
                        className={styles.discount}
                        error={touched && errors[field.name]}
                        customStyles={fieldStyles}
                        customPlaceholder="0"
                        innerLabel="%"
                    />
                )}
            </Field>
        </div>

        <div className={styles.itemTotal}>
            <label>Item total:</label>
            {getTotalPriceFromValues(values)}
        </div>
    </div>
)

ManualEntryPriceForm.propTypes = {
    handleChangeField: PropTypes.func.isRequired,
    values: PropTypes.shape({
        price: PropTypes.string,
        quantity: PropTypes.number,
        discount: PropTypes.number,
        tax_code: PropTypes.string
    }),
    errors: PropTypes.shape({
        price: PropTypes.string,
        quantity: PropTypes.string,
        discount: PropTypes.string,
        tax_code: PropTypes.string
    }),
    fieldStyles: PropTypes.object
}

export default ManualEntryPriceForm
