import React from "react"
import withMultiselect from "HOC/withMultiselect"
import ListContainer from "./ListContainer"

export const Invoices = withMultiselect("INVOICES_LIST")(props => <ListContainer type="invoices" {...props} />)
export const PO = withMultiselect("PURCHASE_ORDERS_LIST")(props => <ListContainer type="purchase-orders" {...props} />)
export const SalesOrders = withMultiselect("SALES_ORDER_LIST")(props => <ListContainer type="sales" {...props} />)
export const Memo = withMultiselect("MEMO_LIST")(props => <ListContainer type="memo" {...props} />)
export const RentalAgreements = withMultiselect("RENTAL_LIST")(props => <ListContainer type="rental" {...props} />)
export const Payments = withMultiselect("PAYMENTS_LIST")(props => <ListContainer type="payments" {...props} />)
