import React, { Component } from "react"
import idx from "idx"
import AddNote from "./AddNote/AddNote"
import { connect } from "react-redux"
import { addNote, editNote } from "actions/nextNote"
import { showSuccessNotification } from "actions/notification"
class AddNoteCover extends Component {
    static defaultProps = {
        text: "",
        attachments: [],
        isEdited: false,
        type: "client",
        isLoading: false,
        hasAttachments: true
    }

    constructor(props) {
        super(props)
        this.changeNote = this.changeNote.bind(this)
        this.onSubmit = this.onSubmit.bind(this)

        this.state = {
            user_id: this.props.userId,
            text: this.props.text,
            attachments: this.props.attachments,
            deletedAttachmentsIds: []
        }
    }

    shouldComponentUpdate(_, prevState) {
        if (prevState.isLoading && this.state.isLoading) {
            return false
        }
        return true
    }

    changeNote(event) {
        const newValue = event.currentTarget.value
        this.setState({
            text: newValue
        })
    }

    onSubmit(event) {
        const { isEdited, successAction, handleHide, id, editNote, addNote, type } = this.props
        const { text, attachments, deletedAttachmentsIds } = this.state

        const payload = {
            text,
            attachments: attachments
                .filter(el => typeof el.id !== "number")
                .map(el => ({ url: el.path, title: el.name })),
            successAction,
            type,
            id
        }

        event.preventDefault()

        const [noteMethod, nextPayload] = isEdited
            ? [editNote, { ...payload, deletedAttachmentsIds }]
            : [addNote, payload]

        noteMethod(nextPayload)

        handleHide()
    }

    handleUpload = state => {
        this.setState(prevState => {
            return {
                isLoading: false,
                attachments: [
                    ...prevState.attachments,
                    ...state.map(el => ({
                        name: el.title,
                        size: el.file.size,
                        path: el.path
                    }))
                ]
            }
        })
    }

    handleRemove = index => {
        const { attachments, deletedAttachmentsIds } = this.state
        const id = idx(attachments, _ => _[index].id)
        const deletedAppendix = typeof id === "number" ? [id] : []

        this.setState({
            deletedAttachmentsIds: [...deletedAttachmentsIds, ...deletedAppendix],
            attachments: attachments.filter((_, i) => i !== index)
        })
    }

    render() {
        const { handleHide, clientId, isEdited, hasAttachments } = this.props
        const { text, attachments, isLoading } = this.state
        return (
            <AddNote
                changeNote={this.changeNote}
                onSubmit={this.onSubmit}
                noteText={text}
                handleHide={handleHide}
                attachments={attachments}
                clientId={clientId}
                isEdited={isEdited}
                isLoading={isLoading}
                hasAttachments={hasAttachments}
                handleLoading={() => this.setState({ isLoading: true })}
                handleRemove={index => this.handleRemove(index)}
                handleUpload={state => this.handleUpload(state)}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        userId: state.loginStore.userId
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editNote: payload => dispatch(editNote(payload)),
        addNote: payload => dispatch(addNote(payload)),
        showSuccessNotification: () => dispatch(showSuccessNotification())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddNoteCover)
