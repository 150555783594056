import React from "react"
import { Route, Switch } from "react-router-dom"

import Listing from "./Listing"
import Form from "./Form"

import withPermissions from "HOC/withPermissions"

const Routes = () => {
    return (
        <Switch>
            <Route path="/content/portfolio" exact component={Listing} />
            <Route path="/content/portfolio/:portfolioId/edit" component={Form} />
            <Route path="/content/portfolio/add" component={Form} />
        </Switch>
    )
}

export default withPermissions(Routes)
