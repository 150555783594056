import React, { Component } from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import Button from "ui/Button"
import { connect } from "react-redux"
import styles from "./SaveBar.css"
import previewButtonStyles from "./overrides/PreviewButton.css"
import submitButtonStyles from "./overrides/SubmitButton.css"

class SaveBar extends Component {
    static propTypes = {
        isShown: PropTypes.bool,
        isDisabled: PropTypes.bool,
        isDisabledCancel: PropTypes.bool,
        isSaving: PropTypes.bool,
        isSubmit: PropTypes.bool,
        submitLabel: PropTypes.string,
        message: PropTypes.string,
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        onPreview: PropTypes.func,
        isFirstButtonWarning: PropTypes.bool
    }

    render() {
        const {
            cx,
            isShown,
            isSidebarMenuWide,
            isDisabled,
            isDisabledCancel,
            isSaving,
            onCancel,
            onSubmit,
            onPreview,
            submitLabel,
            message,
            isSubmit,
            isFirstButtonWarning
        } = this.props

        if (!isShown) {
            return null
        }

        return (
            <footer className={cx("root", { isSidebarMenuWide })}>
                <p>{message}</p>
                <div>
                    {onCancel && (
                        <span
                            className={cx({ disabledCancel: isDisabledCancel })}
                            onClick={isDisabledCancel ? undefined : onCancel}
                        >
                            Cancel
                        </span>
                    )}
                    {onPreview && (
                        <div className={cx("holder")}>
                            <Button
                                customStyles={previewButtonStyles}
                                className={["first-button", "white"]}
                                label="Preview"
                                onClick={() => onPreview()}
                            />
                        </div>
                    )}
                    <div className={cx("holder")}>
                        <Button
                            customStyles={submitButtonStyles}
                            className={["first-button", isFirstButtonWarning ? "warning-button" : ""]}
                            label={submitLabel}
                            onClick={() => onSubmit && onSubmit()}
                            isLoading={isSaving}
                            isDisabled={isDisabled}
                            type={isSubmit ? "submit" : "button"}
                        />
                    </div>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSidebarMenuWide: state.menuStore.isSidebarMenuWide
    }
}

export default connect(mapStateToProps)(withStyles(SaveBar, styles))
