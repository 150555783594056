import React from "react"
import moment from "moment"
import { withRouter, Link } from "react-router-dom"
import ReactSvg from "react-svg"

import withStyles from "HOC/withStyles"
import { Button, EmptyList, DateRangePicker, Status, FilterableTable, Pagination } from "ui"
import { Content, SearchBar, Header, Table, Sort, Checkbox } from "ui/FilterableTable"
import Layout from "components/Reusable/Layout"
import Filters from "./Filters"

import styles from "./List.css"
import stylesDateRangePicker from "./overrides/DateRangePicker.css"
import stylesTableHeader from "./overrides/Header.css"
import stylesPagination from "./overrides/Pagination.css"
import stylesCheckBox from "./overrides/CheckBox.css"

import projectIcon from "assets/ico-projects.svg"
import calendarIcon from "assets/calendar.svg"
import clockIcon from "assets/clock.svg"
import deleteIcon from "assets/close-blue.svg"

const List = props => {
    const { cx } = props

    return (
        <Layout title={renderTitle()} renderActions={renderActions}>
            <div className={cx("listContainer")}>{renderList()}</div>
        </Layout>
    )

    function renderTitle() {
        const { selectedSelections } = props

        const hasCount = selectedSelections && selectedSelections.length > 0

        return (
            <div className={cx("title-container")}>
                <div className={cx("title-name")}>Selections{hasCount && ":"}</div>
                {hasCount && <div className={cx("count-description")}>Selected</div>}
                {hasCount && <div className={cx("count")}>{selectedSelections.length}</div>}
            </div>
        )
    }

    function renderActions() {
        const { openCreateModal, selectedSelections, handleMultiDelete } = props

        return (
            <span className={cx("actions")}>
                {selectedSelections.length === 0 && (
                    <Button
                        className={`first-button ${cx("first-button")}`}
                        onClick={openCreateModal}
                        label="Create New"
                    />
                )}
                {selectedSelections.length > 0 && (
                    <button className={cx("deleteButton")} onClick={handleMultiDelete} key={1}>
                        <ReactSvg src={deleteIcon} /> Delete
                    </button>
                )}
            </span>
        )
    }

    function renderList() {
        const {
            selections,
            handleChangeDateRange,
            moreFilters,
            handleSetMoreFilters,
            handleClearMoreFilters,
            setFilters,
            setPage,
            currentPage,
            type,
            setType,
            dateRange,
            query
        } = props

        return (
            <div>
                <FilterableTable
                    restore={false}
                    fetchStatus={selections.fetchStatus}
                    items={selections.data}
                    meta={selections.meta}
                    handleChange={setFilters}
                >
                    <Header
                        customStyles={stylesTableHeader}
                        MoreFiltersComponent={
                            <Filters
                                moreFilters={{
                                    user: moreFilters.assignedTo,
                                    brand: moreFilters.vendors
                                }}
                                setMoreFilters={handleSetMoreFilters}
                                clearAllFilters={handleClearMoreFilters}
                            />
                        }
                    >
                        <SearchBar search={query} placeholder="Search for selections..." />
                        <Status.Select
                            allSelectedText="All types"
                            type="selection"
                            placeholder="All types"
                            value={type}
                            onChange={setType}
                        />
                        <DateRangePicker
                            ranges={{ startDate: dateRange.from, endDate: dateRange.to }}
                            onChange={({ range1: date }) => handleChangeDateRange(date)}
                            resetRanges={() => handleChangeDateRange({ startDate: "", endDate: "" })}
                            format="DD MMM YYYY"
                            title="Any date created"
                            icon={calendarIcon}
                            customStyles={stylesDateRangePicker}
                        />
                    </Header>
                    <Content className={cx("content")}>
                        <Table
                            className={cx("table")}
                            renderHeader={renderTableHeader}
                            renderBody={renderTableBody}
                            customEmptyMessage={props => {
                                if (props.context.data.filters.length || props.context.data.search) {
                                    return <EmptyList message="No selections found" icon={projectIcon}></EmptyList>
                                }

                                return <EmptyList message="No selections added" icon={projectIcon} />
                            }}
                        />
                    </Content>
                </FilterableTable>
                {selections.fetchStatus.isLoaded && (
                    <Pagination
                        customStyles={stylesPagination}
                        currentPage={currentPage}
                        pages={(selections.meta && selections.meta.last_page) || 1}
                        isLoaded={selections.fetchStatus.isLoaded}
                        onChange={setPage}
                    />
                )}
            </div>
        )
    }

    function renderTableHeader() {
        const { selections, selectedSelections, setSelectedSelections } = props

        const isEmpty = selectedSelections.length === 0
        const isFull = selectedSelections.length === selections.data.length

        return (
            <Table.Head>
                <Table.Tr key={-1}>
                    <Table.Th>
                        <Checkbox
                            id={-1}
                            checked={!isEmpty}
                            customStyles={stylesCheckBox}
                            onChange={() =>
                                isEmpty
                                    ? setSelectedSelections(selections.data.map(selection => selection.id))
                                    : setSelectedSelections([])
                            }
                            isMinusIcon={!isEmpty && !isFull}
                        />
                    </Table.Th>
                    <Table.Th key="name">
                        <Sort sortBy="name" sortDirection="asc">
                            Lightbox name
                        </Sort>
                    </Table.Th>
                    <Table.Th key="products_count" className={cx("textCenter")}>
                        <Sort sortBy="products_count">Products</Sort>
                    </Table.Th>
                    <Table.Th key="type">Type</Table.Th>
                    <Table.Th key="created_at">
                        <Sort sortBy="created_at">CREATED</Sort>
                    </Table.Th>
                </Table.Tr>
            </Table.Head>
        )
    }

    function renderTableBody() {
        const { selections, history, selectedSelections, setSelectedSelections } = props

        const toggleSelectedSelections = id =>
            setSelectedSelections(
                selectedSelections.includes(id)
                    ? selectedSelections.filter(ss => ss !== id)
                    : [...selectedSelections, id]
            )

        return (
            <Table.Body>
                {selections.data.map(selection => (
                    <Table.Tr
                        onClick={() => {
                            history.push(`/products/selections/${selection.id}`)
                        }}
                        key={selection.id}
                    >
                        <Table.Td onClick={e => e.stopPropagation()}>
                            <Checkbox
                                id={selection.id}
                                checked={selectedSelections.includes(selection.id)}
                                onChange={() => toggleSelectedSelections(selection.id)}
                                customStyles={stylesCheckBox}
                            />
                        </Table.Td>
                        <Table.Td key="name">
                            <Link to={`/products/selections/${selection.id}`} onClick={e => e.preventDefault()}>
                                {selection.name}
                            </Link>
                        </Table.Td>
                        <Table.Td key="products_count" className={cx("textCenter")}>
                            {selection.products_count}
                        </Table.Td>
                        <Table.Td key="type">
                            <Status.Label type="selection" status={selection.type} />
                        </Table.Td>
                        <Table.Td key="created_at" className={cx("isIcon")}>
                            <img src={clockIcon} alt="icon" />
                            <span>{moment(selection.created_at).format("DD MMM YYYY")}</span>
                        </Table.Td>
                    </Table.Tr>
                ))}
            </Table.Body>
        )
    }
}

export default withStyles(withRouter(List), styles)
