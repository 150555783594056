import React, { Component } from "react"

import EditableList from "components/Reusable/EditableList"
import config from "./configCategories"

import ListingLayout from "../../ListingLayout/index"

class ListingCategories extends Component {
    render() {
        const { categories, filteredCategories, isLoading, categoryId, handleDeleteCategory } = this.props

        const addUrl = categoryId ? `/settings/categories/${categoryId}/add` : "/settings/categories/add"

        let listTitle = "Categories"
        let returnLink = null

        if (categoryId) {
            const category = categories.find(item => item.id === Number(categoryId))
            const parentCategory = categories.find(c => c.id === category.parent_category)

            if (category) {
                listTitle = category.name

                returnLink = {
                    label: parentCategory || category.parent_category ? parentCategory.name : "Categories",
                    link: !category.parent_category
                        ? `/settings/categories`
                        : `/settings/categories/${category.parent_category}`
                }
            }
        }

        return (
            <ListingLayout returnLink={returnLink}>
                <EditableList
                    config={config}
                    list={filteredCategories}
                    listTitle={listTitle}
                    confirmModalTitle={"Are you sure you want to delete this category?"}
                    editUrlPrefix={"/settings/categories/card"}
                    addUrl={addUrl}
                    isLoading={isLoading}
                    handleDeleteEnableCondition={values =>
                        !values.products_in_category &&
                        values.subcategories &&
                        !values.subcategories.length &&
                        !values.sale
                    }
                    deleteElement={handleDeleteCategory}
                    handleContentLink={id => `/settings/categories/${id}`}
                />
            </ListingLayout>
        )
    }
}

export default ListingCategories
