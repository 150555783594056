import React from "react"
import PropTypes from "prop-types"

import { SelectWithSearch } from "ui"
import { H2 } from "@butterfly-frontend/ui"

import REFUND_METHOD_KEYS from "modules/OrdersModule/constants/refundMethodKeys"
import REFUND_METHOD_OPTIONS from "modules/OrdersModule/constants/refundMethodOptions"
import REFUND_RATE_KEYS from "modules/OrdersModule/constants/refundRateKeys"
import REFUND_RATE_OPTIONS from "modules/OrdersModule/constants/refundRateOptions"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import formikPropTypesFactory from "modules/WmsModule/helpers/formikPropTypesFactory"

import styles from "./ReturnFinancialTerms.module.css"

const ReturnFinancialTerms = ({ formik, isDisabled }) => {
    const getSelectProps = getSelectPropsFactory(formik)

    return (
        <div className={styles.returnFinancialTerms}>
            <H2>Financial terms</H2>
            <div className={styles.returnFinancialTermsForm}>
                <div className={styles.selectWrapper}>
                    <SelectWithSearch
                        {...getSelectProps("refundMethod", {
                            isDisabled
                        })}
                        label="Method"
                        values={REFUND_METHOD_OPTIONS}
                        isRequired
                        isClearBlocked
                        withoutFetch
                    />
                </div>
                <div className={styles.selectWrapper}>
                    <SelectWithSearch
                        {...getSelectProps("refundRate", {
                            isDisabled
                        })}
                        label="Requested deposit"
                        values={REFUND_RATE_OPTIONS}
                        isRequired
                        isClearBlocked
                        withoutFetch
                    />
                </div>
            </div>
        </div>
    )
}

ReturnFinancialTerms.propTypes = {
    isDisabled: PropTypes.bool,
    formik: formikPropTypesFactory({
        refundMethod: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOf(Object.values(REFUND_METHOD_KEYS))
        }),
        refundRate: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOf(Object.values(REFUND_RATE_KEYS))
        })
    })
}

export default ReturnFinancialTerms
