import React, { Component } from "react"
import ReactSVG from "react-svg"

import withStyles from "HOC/withStyles"
import styles from "./Image.css"
import defaultImage from "assets/image.svg"

class Image extends Component {
    render() {
        const { cx, src } = this.props

        return (
            <div className={cx("image", { default: !src })} style={src ? { backgroundImage: `url("${src}")` } : {}}>
                {!src && <ReactSVG src={defaultImage} />}
            </div>
        )
    }
}

export default withStyles(Image, styles)
