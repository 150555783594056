import React from "react"
import { getAddressLines } from "helpers/address"

const CopyAddress = props => {
    const { cx, displayFormatConfig, copyAddress } = props

    const [strongValues, addressValues] = getAddressLines(
        [displayFormatConfig.strong, displayFormatConfig.address],
        copyAddress
    )

    return (
        <section className={cx("sameAddress")}>
            {!!strongValues && <strong>{strongValues}</strong>}
            {!!addressValues && <p>{addressValues}</p>}
        </section>
    )
}

export default CopyAddress
