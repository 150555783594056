import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./DeleteCalendarModal.css"

import Button from "components/Reusable/Form/Button"

import close from "assets/close.svg"
import Modal from "components/Modal/Modal"

class DeleteCalendarModal extends Component {
    state = {
        isDeleting: false
    }

    handleDelete = () => {
        const { handleHideModal, deletedCalendar, handleDelete, showErrorNotification } = this.props
        const { id, is_public } = deletedCalendar

        this.setState({
            isDeleting: true
        })

        handleDelete(id, is_public)
            .finally(() => {
                this.setState({
                    isDeleting: false
                })
            })
            .then(data => {
                if (data && data.payload.error) {
                    showErrorNotification(data.payload.error.message)
                } else {
                    handleHideModal()
                }
            })
    }

    render() {
        const { cx, handleHideModal, title, deletedCalendar } = this.props
        return (
            <Modal>
                <div className={cx("modal-background")}>
                    <div className={cx("modal-cover")}>
                        <img
                            alt="close"
                            className={cx("close-icon")}
                            onClick={() => {
                                handleHideModal()
                            }}
                            src={close}
                        />

                        <p className={cx("title")}>{title}</p>
                        <p>
                            Are you sure you want to delete the calendar <span>{deletedCalendar.title}</span> with all
                            related events? This action cannot be reversed.
                        </p>

                        <div className={cx("separator")} />

                        <div className={cx("footer")}>
                            <div>
                                <span
                                    className={cx(["button-link"])}
                                    onClick={() => {
                                        handleHideModal()
                                    }}
                                >
                                    Cancel
                                </span>
                                <Button
                                    type="button"
                                    disabled={this.state.isDeleting}
                                    isLoading={this.state.isDeleting}
                                    className={cx(["danger-button", "button"])}
                                    label={"Delete"}
                                    onClick={this.handleDelete}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withStyles(DeleteCalendarModal, styles)
