import React from "react"
import PropTypes from "prop-types"

import { ConfirmModal } from "@butterfly-frontend/ui"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import SHIPMENT_TYPES from "modules/WmsModule/constants/shipmentTypes"

const CancelShipmentModal = ({
    isLoading,
    onCancel,
    onAction,
    shipmentType = SHIPMENT_TYPE_KEYS.SHIPMENT,
    returnDestination = "warehouse"
}) => {
    const shipmentTypeLabel = SHIPMENT_TYPES[shipmentType].label.toLowerCase()

    return (
        <ConfirmModal
            onCancel={onCancel}
            onAction={onAction}
            isLoading={isLoading}
            buttonColor="red"
            title={`Cancel ${shipmentTypeLabel}?`}
        >
            {`By canceling the ${shipmentTypeLabel}, the products will not be released to courier and will be returned to ${returnDestination}.`}
        </ConfirmModal>
    )
}

CancelShipmentModal.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onAction: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    shipmentType: PropTypes.oneOf(Object.values(SHIPMENT_TYPE_KEYS)),
    returnDestination: PropTypes.string
}

export default CancelShipmentModal
