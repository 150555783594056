import React from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"
import PropTypes from "prop-types"

import {
    Panel,
    H1,
    Table,
    TableHead,
    TableHeadCell,
    TableSortableHeadCell,
    TableBody,
    TableCell,
    TableRow,
    ArrowUpIcon
} from "@butterfly-frontend/ui"
import { formatPrice } from "helpers/units"

import styles from "./SummarySalesPersonList.module.css"

const TABLE_COLUMNS = {
    salesPerson: { minWidth: 100, width: "25%" },
    clientsCount: { minWidth: 70, width: "15%" },
    invoicesCount: { minWidth: 70, width: "15%" },
    totalAmount: { minWidth: 100, width: "20%" },
    totalAmountCollected: { minWidth: 100, width: "20%" },
    detailsButton: { minWidth: 80, width: "5%" }
}

const SummarySalesPersonList = ({ list, sortState, onChangeSort }) => (
    <Panel classes={{ panel: styles.root }}>
        <H1 classes={{ root: styles.header }} withLeftBorder>
            Sales Person Summary
        </H1>
        <Table>
            <div className={styles.table}>
                <TableHead>
                    <TableSortableHeadCell
                        {...TABLE_COLUMNS.salesPerson}
                        sortState={sortState}
                        columnKey="full_name"
                        onClick={onChangeSort}
                    >
                        SALES PERSON NAME
                    </TableSortableHeadCell>
                    <TableSortableHeadCell
                        {...TABLE_COLUMNS.clientsCount}
                        sortState={sortState}
                        columnKey="clients_count"
                        onClick={onChangeSort}
                    >
                        # OF CLIENTS
                    </TableSortableHeadCell>
                    <TableSortableHeadCell
                        {...TABLE_COLUMNS.invoicesCount}
                        sortState={sortState}
                        columnKey="invoices_count"
                        onClick={onChangeSort}
                    >
                        # OF INVOICES
                    </TableSortableHeadCell>
                    <TableSortableHeadCell
                        {...TABLE_COLUMNS.totalAmount}
                        sortState={sortState}
                        columnKey="total_payments"
                        onClick={onChangeSort}
                    >
                        TOTAL AMOUNT
                    </TableSortableHeadCell>
                    <TableSortableHeadCell
                        {...TABLE_COLUMNS.totalAmountCollected}
                        sortState={sortState}
                        columnKey="total_sales_amount"
                        onClick={onChangeSort}
                    >
                        TOTAL AMOUNT COLLECTED
                    </TableSortableHeadCell>
                    <TableHeadCell {...TABLE_COLUMNS.detailsButton} />
                </TableHead>
                <TableBody>
                    {list.map(row => (
                        <TableRow key={row.id}>
                            <TableCell {...TABLE_COLUMNS.salesPerson} classes={{ root: styles.linkCell }}>
                                <Link to={`/dashboard/sellers/${row.id}`}>{row.full_name}</Link>
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.clientsCount} classes={{ root: styles.cell }}>
                                {row.clients_count}
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.invoicesCount} classes={{ root: styles.cell }}>
                                {row.invoices_count}
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.totalAmount} classes={{ root: styles.priceCell }}>
                                {formatPrice(row.total_sales_amount)}
                            </TableCell>
                            <TableCell {...TABLE_COLUMNS.totalAmountCollected} classes={{ root: styles.priceCell }}>
                                {formatPrice(row.total_payments)}
                            </TableCell>
                            <TableCell
                                {...TABLE_COLUMNS.detailsButton}
                                classes={{ root: classNames(styles.linkCell, styles.detailsButtonCell) }}
                            >
                                <Link to={`/dashboard/sellers/${row.id}`}>Details</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </div>
        </Table>
        <div className={styles.showAllLinkWrapper}>
            <Link className={styles.link} to={"/dashboard/sellers?activeTab=salesPersons"}>
                <span>Show all</span>
                <ArrowUpIcon />
            </Link>
        </div>
    </Panel>
)

SummarySalesPersonList.propTypes = {
    list: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            full_name: PropTypes.string.isRequired,
            clients_count: PropTypes.number.isRequired,
            invoices_count: PropTypes.number.isRequired,
            total_sales_amount: PropTypes.number.isRequired,
            total_payments: PropTypes.number.isRequired
        }).isRequired
    ).isRequired,
    sortState: PropTypes.shape({
        by: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired
    }).isRequired,
    onChangeSort: PropTypes.func.isRequired
}

export default SummarySalesPersonList
