import React, { useState } from "react"
import { useDispatch } from "react-redux"
import withStyles from "HOC/withStyles"

import PropTypes from "prop-types"
import ReactSvg from "react-svg"
import commentIcon from "assets/comment.svg"

import { Note, AddEditModal, DeleteModal } from "ui/Notes"

import { addNote, editNote, deleteNote } from "actions/nextNote"

import style from "./NotesSection.module.css"
import { showErrorNotification } from "actions/notification"

const NotesSection = ({ cx, notes, type, id, isHeaderHidden, getAttachmentsCount, onNoteModification }) => {
    const dispatch = useDispatch()

    const [isEditedFor, setIsEditedFor] = useState(null)
    const [isDeletedFor, setIsDeletedFor] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const closeEditModal = () => setIsEditedFor(null)
    const closeDeleteModal = () => setIsDeletedFor(null)

    const handleEditNote = note => {
        if (isEditing) {
            return
        }

        const isAddNewNote = typeof isEditedFor !== "number"
        setIsEditing(true)

        const data = {
            ...note,
            id,
            type
        }

        const [action, actionData] = isAddNewNote
            ? [addNote, data]
            : [editNote, { ...data, id: isEditedFor, deletedAttachmentsIds: note.attachmentsToDelete }]

        action(actionData)(dispatch)
            .then(({ data }) => {
                getAttachmentsCount && getAttachmentsCount()
                closeEditModal()
                if (typeof onNoteModification === "function") {
                    onNoteModification(data, isAddNewNote ? "add" : "edit")
                }
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => setIsEditing(false))
    }

    const handleDeleteNote = () => {
        if (isDeleting) {
            return
        }

        setIsDeleting(true)

        const successAction = () => {
            getAttachmentsCount && getAttachmentsCount()
            setIsDeleting(false)
            closeDeleteModal()
        }

        deleteNote({ noteId: isDeletedFor, successAction, type })(dispatch)
            .then(noteId => {
                if (typeof onNoteModification === "function") {
                    onNoteModification({ id: noteId }, "delete")
                }
            })
            .catch(() => {
                dispatch(showErrorNotification())
            })
    }

    const editedNote = notes.find(note => note.id === isEditedFor)
    const editedNoteText = editedNote ? editedNote.text : ""
    const editedNoteAttachments = editedNote ? editedNote.attachments : []

    return (
        <ul className={cx("root")}>
            <li className={cx("header", { isHeaderHidden })}>
                <h2>
                    Staff Notes <span>(Not visible to client)</span>
                </h2>
                <button onClick={() => setIsEditedFor(true)}>
                    <ReactSvg src={commentIcon} />
                    Leave a note
                </button>
            </li>
            {notes.map(note => (
                <Note key={note.id} {...note} editNote={setIsEditedFor} deleteNote={setIsDeletedFor} />
            ))}
            <AddEditModal
                isOpen={!!isEditedFor}
                isEdit={!!editedNote}
                hasAttachments
                isLoading={isEditing}
                text={editedNoteText}
                attachments={editedNoteAttachments}
                onClose={closeEditModal}
                onSubmit={handleEditNote}
            />
            <DeleteModal
                isOpen={!!isDeletedFor}
                isLoading={isDeleting}
                onClose={closeDeleteModal}
                onSubmit={handleDeleteNote}
            />
        </ul>
    )
}

NotesSection.propTypes = {
    notes: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    id: PropTypes.number,
    isHeaderHidden: PropTypes.bool,
    getAttachmentsCount: PropTypes.func,
    onNoteModification: PropTypes.func
}

export default withStyles(NotesSection, style)
