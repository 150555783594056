import React, { Component } from "react"
import { withRouter } from "react-router-dom"

import withStyles from "HOC/withStyles"

import styles from "./NotFoundPage.css"
import stylesLayout from "./override/Layout.css"

import Button from "components/Reusable/Form/Button/index"
import Layout from "../LoginPage/Layout"

class NotFoundPage extends Component {
    render() {
        const { cx, history } = this.props
        return (
            <Layout customStyles={stylesLayout}>
                <div className={cx("root")}>
                    <h1>404</h1>
                    <h2>Error</h2>

                    <p>This page is unknown or does not exist</p>
                </div>

                <Button
                    className={["second-button-big", "button", cx("button")]}
                    onClick={() => history.push("/dashboard")}
                    label="Go to Dashboard"
                />
            </Layout>
        )
    }
}

export default withRouter(withStyles(NotFoundPage, styles))
