import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./Toggle.css"

class Toggle extends Component {
    state = {
        isOn: this.props.isOn
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isOn !== this.props.isOn) {
            this.setState({
                isOn: nextProps.isOn
            })
        }
    }

    handleToggle = () => {
        const { handleChange } = this.props
        this.setState(
            {
                isOn: !this.state.isOn
            },
            () => {
                handleChange && handleChange(this.state.isOn)
            }
        )
    }

    render() {
        const { cx, labelOnRight = true } = this.props
        const { isOn } = this.state

        return (
            <div className={cx("root")}>
                {!labelOnRight && this.renderLabel()}
                <span className={cx("toggle", { on: isOn })} onClick={this.handleToggle} />
                {labelOnRight && this.renderLabel()}
            </div>
        )
    }

    renderLabel() {
        return <span className={this.props.cx("label")}>{this.state.isOn ? "on" : "off"}</span>
    }
}
export default withStyles(Toggle, styles)
