import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { connect } from "react-redux"

import Dropdown from "components/Reusable/Dropdown/index"

import withStyles from "HOC/withStyles"

import styles from "./ContentHeader.css"
import stylesDropdown from "./overrides/Dropdown.css"

class ContentHeader extends Component {
    render() {
        const {
            match,
            title,
            clientName,
            afterTitle,
            tabs,
            cx,
            cta,
            dropdown,
            className,
            isDropdownShow,
            links,
            renderActions,
            calendarTitle,
            calendarWeek
        } = this.props

        const renderedTitle = typeof title === "function" ? title() : title

        return (
            <div className={[cx("root"), className].join(" ")}>
                {clientName && (
                    <h1 className={cx("title")}>
                        {renderedTitle}: <span>{clientName}</span>
                    </h1>
                )}
                {!clientName && !calendarTitle && <h1 className={cx("title")}>{renderedTitle}</h1>}
                {calendarTitle && (
                    <h1 className={cx("title", "middle", "calendarTitle")}>
                        {title}&nbsp;<span className={cx("title__date")}>{calendarTitle}</span>
                        <span className={cx("title__week")}>{calendarWeek}</span>
                    </h1>
                )}
                {!!afterTitle && afterTitle}
                <div className={cx("tabs")}>
                    {tabs &&
                        tabs.map((tab, index) => (
                            <Link
                                key={index}
                                to={tab.url}
                                onClick={tab && typeof tab.onClick === "function" ? tab.onClick : () => {}}
                                className={cx("tab", { active: match.url === tab.url })}
                            >
                                {tab.label}
                            </Link>
                        ))}
                </div>

                <div className={cx("actions")}>
                    {dropdown && isDropdownShow && dropdown.elements && dropdown.onClick && (
                        <Dropdown
                            customStyles={stylesDropdown}
                            values={dropdown.elements.map(element => {
                                return {
                                    value: element.value,
                                    label: element.label
                                }
                            })}
                            defaultValue={dropdown.label || "Export"}
                            isLabeledValue={true}
                            isLabel={false}
                            onClick={event => dropdown.onClick(event)}
                            isLoading={dropdown.isLoading}
                        />
                    )}

                    {cta && isDropdownShow && cta[0]}
                    {cta && cta[1]}
                    {cta && cta[2]}

                    {renderActions && renderActions()}

                    {links &&
                        links.map((link, index) => {
                            return (
                                <span key={index} onClick={link.onClick} className={cx("link")}>
                                    {link.title}
                                </span>
                            )
                        })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        isDropdownShow: state.ui.header.isDropdownShow
    }
}

export default connect(
    mapStateToProps,
    null
)(withStyles(withRouter(ContentHeader), styles))
