import React, { useState, useCallback, Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import { Modal, Button } from "@butterfly-frontend/ui"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"
import { Attachments } from "ui/Notes/AddEditModal/components"
import CameraUploader from "modules/WmsModule/components/CameraUploader"

import styles from "./UploadImages.module.css"

const UploadImages = ({ onClose, onSubmit }) => {
    const [isUploading, setIsUploading] = useState(false)
    const [currentImages, setCurrentImages] = useState([])
    const [isCameraUploaderOpen, setIsCameraUploaderOpen] = useState(false)
    const [photoToUpload, setPhotoToUpload] = useState()

    const handleUploadSuccess = useCallback(uploadedImages => {
        const formattedUploadedImages = uploadedImages.map(({ id, title, file, url }) => ({
            id,
            name: title,
            size: file.size,
            url
        }))

        setIsUploading(false)
        setCurrentImages(prevCurrentImages => [...prevCurrentImages, ...formattedUploadedImages])
        setPhotoToUpload(undefined)
    }, [])

    const handleRemoveImage = useCallback(imageId => {
        setCurrentImages(prevCurrentImages => prevCurrentImages.filter(image => image.id !== imageId))
    }, [])

    const handleSubmit = useCallback(() => {
        onSubmit(currentImages)
    }, [currentImages, onSubmit])

    const onPhotoTaken = useCallback(photoFile => {
        setPhotoToUpload(photoFile)
    }, [])

    return (
        <Modal title="Upload photos" onClose={onClose}>
            <div className={classNames([styles.container, { [styles.isCameraUploaderOpen]: isCameraUploaderOpen }])}>
                {isCameraUploaderOpen ? (
                    <CameraUploader onClose={() => setIsCameraUploaderOpen(false)} onPhotoTaken={onPhotoTaken} />
                ) : (
                    <Fragment>
                        <Attachments
                            acceptFormats="image/jpeg,image/png"
                            currentAttachments={currentImages}
                            filesToUpload={photoToUpload}
                            endpoint={DEFAULT_FILE_UPLOAD_ENPOINT}
                            handleUploadSuccess={handleUploadSuccess}
                            handleLoading={() => setIsUploading(true)}
                            handleRemove={handleRemoveImage}
                        />
                        <div className={styles.buttonsContainer}>
                            <Button
                                classes={{ button: styles.addFromCameraBtn }}
                                onClick={() => setIsCameraUploaderOpen(true)}
                                disabled={isUploading}
                            >
                                Add photo from camera
                            </Button>
                            <Button
                                variant="flat"
                                disabled={isUploading}
                                onClick={onClose}
                                classes={{ button: styles.cancelBtn }}
                            >
                                Cancel
                            </Button>
                            <Button color="blue" isLoading={isUploading} onClick={handleSubmit}>
                                Done
                            </Button>
                        </div>
                    </Fragment>
                )}
            </div>
        </Modal>
    )
}

UploadImages.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default UploadImages
