import fetchHelper from "helpers/fetch"
import * as actionTypes from "./actionTypes"
import { serializeQuery } from "helpers/urls"

export { shouldFetch } from "helpers/fetchStatus"

const actionRequest = params => {
    params = {
        method: "get",
        body: {},
        ...params
    }

    return dispatch => {
        dispatch({
            type: params.types[0]
        })

        return new Promise((resolve, reject) => {
            fetchHelper[params.method](params.endpoint, params.body).then(
                data => {
                    dispatch({
                        type: params.types[1],
                        payload: data
                    })

                    resolve(data)
                },
                error => {
                    dispatch({
                        type: params.types[2],
                        payload: {
                            error
                        }
                    })

                    reject(error)
                }
            )
        })
    }
}

export const fetchOrders = (
    params = {},
    types = [actionTypes.GET_ORDERS_REQUEST, actionTypes.GET_ORDERS_SUCCESS, actionTypes.GET_ORDERS_FAILURE]
) => {
    params = {
        page: 1,
        length: 20,
        ...params
    }

    return actionRequest({
        types: types,
        method: "get",
        endpoint: "/orders?" + serializeQuery(params)
    })
}

export const fetchMoreOrders = (params = {}) => {
    return fetchOrders(params, [
        actionTypes.GET_MORE_ORDERS_REQUEST,
        actionTypes.GET_MORE_ORDERS_SUCCESS,
        actionTypes.GET_MORE_ORDERS_FAILURE
    ])
}

export const clearState = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_ORDERS
    })
}
