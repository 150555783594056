import React, { Component, forwardRef } from "react"
import classNames from "classnames/bind"

import { mergeCx } from "helpers/styles"

const withStyles = (WrappedComponent, styles) => {
    class Enhance extends Component {
        state = {
            cx: classNames.bind(styles)
        }

        componentDidMount() {
            const { customStyles } = this.props
            const { cx } = this.state

            if (customStyles) {
                const customCx = classNames.bind(customStyles)
                this.setState({ cx: mergeCx(cx, customCx) })
            }
        }

        // eslint-disable-next-line
        UNSAFE_componentWillReceiveProps(nextProps) {
            const { customStyles } = this.props
            const { cx } = this.state

            if (nextProps.customStyles !== customStyles) {
                const customCx = classNames.bind(nextProps.customStyles)
                this.setState({ cx: mergeCx(cx, customCx) })
            }
        }

        render() {
            const { cx } = this.state
            const { forwardedRef, ...restProps } = this.props
            return <WrappedComponent ref={forwardedRef} {...restProps} cx={cx} />
        }
    }

    return forwardRef((props, ref) => <Enhance {...props} forwardedRef={ref} />)
}

export default withStyles
