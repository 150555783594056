import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    fetchStatus: fetchStatus.initialState()
}

export default (state = initialState, action) => {
    if (constants.GET_PROJECTS_START === action.type) {
        return { ...initialState, fetchStatus: fetchStatus.requestState() }
    }

    if (constants.GET_PROJECTS_SUCCESS === action.type) {
        return {
            data: action.payload.data.data || [],
            meta: action.payload.data.meta || {},
            fetchStatus: fetchStatus.successState()
        }
    }

    if (constants.GET_PROJECTS_ERROR === action.type) {
        return {
            data: [],
            meta: {},
            fetchStatus: {
                ...fetchStatus.failureState(),
                error: action.payload && action.payload.error
            }
        }
    }

    if ("RELOAD_PROJECT_LIST" === action.type) {
        return {
            data: [],
            meta: {},
            fetchStatus: fetchStatus.initialState()
        }
    }

    return state
}
