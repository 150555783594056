import * as Yup from "yup"
import WAREHOUSE_TYPE from "modules/WmsModule/constants/warehouseType"

const REQUIRED_MESSAGE = "This field is required."
const requiredTextField = Yup.string().required(REQUIRED_MESSAGE)

export default Yup.object().shape({
    primary: Yup.boolean(),
    name: requiredTextField,
    code: requiredTextField,
    phone: Yup.string(),
    email: requiredTextField,
    type: Yup.mixed()
        .oneOf([WAREHOUSE_TYPE.NORMAL, WAREHOUSE_TYPE.VIRTUAL])
        .required(REQUIRED_MESSAGE),
    address: Yup.object().shape({
        street: requiredTextField,
        city: requiredTextField,
        state: requiredTextField,
        post_code: requiredTextField,
        country: requiredTextField
    }),
    manager: Yup.object().shape({
        name: requiredTextField,
        job_title: requiredTextField,
        phone: Yup.string(),
        mobile: Yup.string(),
        email: requiredTextField
    }),
    active: Yup.boolean()
})
