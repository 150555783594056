import React, { useState, useEffect, useRef } from "react"
import ReactSVG from "react-svg"
import { useDispatch } from "react-redux"

import env from "env"
import { Modal, Button } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader/SnakeLoader"
import useStyles from "helpers/getCxFromStyles"
import fetch from "helpers/fetch"

import * as notificationActions from "actions/notification"

import styles from "./ExternalCalendarModal.css"
import modalBodyStyles from "./overrides/ModalBody.css"
import modalActionsStyles from "./overrides/ModalActions.css"

import linkIcon from "assets/ico-link.svg"

const API = env.API

const ExternalCalendarModal = props => {
    const { isOpen, onClose, calendar } = props
    const cx = useStyles(styles)
    const [url, setUrl] = useState("")
    const urlRef = useRef(null)
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (calendar && isOpen) {
            setIsLoading(true)
            fetch
                .post("/calendar-feeds", { calendar_id: calendar.id })
                .then(({ data }) => {
                    setUrl(`${API.baseUrl}/calendar-feeds/${data.uuid}`)
                    setIsLoading(false)
                })
                .catch(({ message }) =>
                    dispatch(notificationActions.showErrorNotification(`Error external integrate: ${message}`))
                )
        }
    }, [calendar, isOpen])

    const handleCopyToClipboard = e => {
        if (urlRef && e) {
            urlRef.current.select()
            document.execCommand("copy")
            e.target.focus()
        }
    }

    return (
        <Modal isOpen={isOpen} closeModal={onClose} preventClickOutside>
            <Modal.Header>External integrate</Modal.Header>
            {isLoading ? (
                <div className={cx("loaderContainer")}>
                    <SnakeLoader />
                </div>
            ) : (
                <Modal.Body customStyles={modalBodyStyles}>
                    <p className={cx("message")}>
                        Copy integreting link to your external calendar (Google Calendar, iCal or Outlook).
                    </p>
                    <input className={cx("url")} ref={urlRef} readOnly value={url} />
                </Modal.Body>
            )}
            <Modal.Actions customStyles={modalActionsStyles}>
                {!isLoading && (
                    <button className={cx("copyUrlButton")} onClick={handleCopyToClipboard}>
                        <ReactSVG src={linkIcon} className={cx("copyUrlIcon")} />
                        Copy link
                    </button>
                )}
                <Button className="first-button" label="Done" onClick={onClose} />
            </Modal.Actions>
        </Modal>
    )
}

export default ExternalCalendarModal
