import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./temp.module.css"

class Temp extends Component {
    static defaultProps = {
        mlaSoldBy: "-",
        mbSoldBy: "-",
        mbId: "-",
        mlaId: "-"
    }

    render() {
        const { cx, mbId, mbSoldBy, mlaId, mlaSoldBy } = this.props

        return (
            <section className={cx("root")}>
                <div>
                    <h2>MB</h2>
                    <ul>
                        <li>
                            <h3>MB ID (number):</h3>
                            <p>{mbId}</p>
                        </li>
                        <li>
                            <h3>MB Sold By:</h3>
                            <p>{mbSoldBy}</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <h2>MLA</h2>
                    <ul>
                        <li>
                            <h3>MLA ID:</h3>
                            <p>{mlaId}</p>
                        </li>
                        <li>
                            <h3>MLA Sold By:</h3>
                            <p>{mlaSoldBy}</p>
                        </li>
                    </ul>
                </div>
            </section>
        )
    }
}

export default withStyles(Temp, styles)
