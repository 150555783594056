import { useMemo } from "react"
import _get from "lodash/get"

import { useWarehouseList } from "./api/useWarehouse"

const useDefaultWarehouse = () => {
    const warehouseListResource = useWarehouseList({ params: { is_default_for_po: true } })

    const defaultWarehouse = useMemo(() => _get(warehouseListResource, "data[0]", null), [warehouseListResource.data])

    return {
        fetchStatus: warehouseListResource.fetchStatus,
        value: defaultWarehouse
    }
}

export default useDefaultWarehouse
