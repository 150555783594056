import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./section.css"

const Section = props => {
    const { cx, className, headerClassName, label, Action, children } = props

    return (
        <div className={`${cx("root")} ${className}`}>
            <div className={`${cx("header")} ${headerClassName}`}>
                <h3 className={cx("label")}>{label}</h3>
                {Action}
            </div>
            {children}
        </div>
    )
}

Section.defaultProps = {
    className: "",
    headerClassName: ""
}

const JSXPropType = PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
])

Section.propTypes = {
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    label: PropTypes.string.isRequired,
    Action: JSXPropType,
    children: JSXPropType
}

export default withStyles(Section, styles)
