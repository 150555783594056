import React, { Fragment } from "react"

import DropzoneWithUpload from "../DropzoneWithUpload"

import uploadSvg from "assets/ico-upload-cloud.svg"
import closeSvg from "assets/close.svg"

import withStyles from "HOC/withStyles"
import styles from "./Dropzone.css"

const Dropzone = ({
    cx,
    acceptFormats,
    handleUploadSuccess,
    handleUploadFailure,
    handleRemoveFile,
    imageData,
    setIsLoading
}) => {
    return (
        <DropzoneWithUpload
            {...imageData}
            handleUploadSuccess={state => {
                handleUploadSuccess(state)
            }}
            handleUploadFailure={handleUploadFailure}
            handleUploadReject={handleUploadFailure}
            acceptFormats={acceptFormats}
        >
            {uploadState =>
                imageData ? (
                    <div className={cx("dropzone", "isValue")}>
                        <div className={cx("imageWrapper")}>
                            <div className={cx("image")} style={{ backgroundImage: `url(${imageData.url})` }} />
                        </div>
                        <span className={cx("filename")}>{imageData.name}</span>
                        <span className={cx("size")}>{Math.ceil(imageData.size / 1000)}kB</span>
                        <button onClick={handleRemoveFile}>
                            <img src={closeSvg} alt="close" />
                        </button>
                    </div>
                ) : (
                    <div className={cx("dropzone")}>
                        {typeof setIsLoading === "function" && setIsLoading(uploadState.isUploading)}
                        {uploadState.isUploading ? (
                            <div className={cx("loading")}>
                                <div style={{ width: `${uploadState.progress}%` }} />
                                <p>{uploadState.progress} %</p>
                            </div>
                        ) : (
                            <Fragment>
                                <img src={uploadSvg} alt="" />
                                Upload logo
                                <span>(browse or drop files here)</span>
                            </Fragment>
                        )}
                    </div>
                )
            }
        </DropzoneWithUpload>
    )
}

export default withStyles(Dropzone, styles)
