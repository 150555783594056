import * as constants from "constants/index"

function reorderable(state = "all", action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_REORDERABLE:
            return "all"
        case constants.CHANGE_REORDERABLE:
            return value
        default:
            return state
    }
}

export default reorderable
