export default {
    formSection1: {
        name: "Users"
    },
    formSection2: {
        name: "Roles"
    },
    formSection3: {
        name: "Clients"
    },
    formSection4: {
        name: "Products"
    },
    formSection5: {
        name: "Web Page",
        customFilter: checkbox => checkbox.name.includes("Web Page") || checkbox.name === "Web Editor"
    },
    formSection6: {
        name: "News"
    },
    formSection7: {
        name: "Blog Posts"
    },
    formSection8: {
        name: "Portfolio Posts"
    },
    formSection9: {
        name: "Global",
        customFilter: checkbox => checkbox.name.includes("Upload") || checkbox.name.includes("Full Statistics")
    },
    formSection10: {
        name: "Tmp Tab"
    },
    formSection11: {
        name: "Days Off"
    },
    formSection12: {
        name: "Warehouses",
        customFilter: checkbox =>
            ["Warehouses", "Shipments"].some(permissionNameElement => checkbox.name.includes(permissionNameElement))
    },
    formSection13: {
        name: "Stock Adjustment",
        customFilter: checkbox =>
            ["Status", "Condition", "Stock Adjustment", "Stock Items"].some(permissionNameElement =>
                checkbox.name.includes(permissionNameElement)
            )
    },
    formSection14: {
        name: "Payments"
    },
    formSection15: {
        name: "Invoices"
    }
}
