import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { queryStringFromParams } from "ui/FilterableTable/helpers"
import * as fetchStatus from "../helpers/fetchStatus"

export { shouldFetch } from "helpers/fetchStatus"

function removeEmptyFromParams(params) {
    return Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
            acc[key] = params[key]
        }

        return acc
    }, {})
}

export function getProjectsStart() {
    return {
        type: constants.GET_PROJECTS_START
    }
}

export function getProjectsSuccess(data) {
    return {
        type: constants.GET_PROJECTS_SUCCESS,
        payload: {
            data
        }
    }
}

export function getProjectsError(error) {
    return {
        type: constants.GET_PROJECTS_ERROR,
        payload: {
            error
        }
    }
}

export function createProject(body) {
    return dispatch => {
        dispatch({
            type: constants.CREATE_PROJECT_START
        })

        return new Promise((resolve, reject) => {
            fetch
                .post("/projects", body)
                .then(data => {
                    dispatch({
                        type: constants.CREATE_PROJECT_SUCCESS,
                        payload: {
                            data
                        }
                    })

                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: constants.CREATE_PROJECT_ERROR,
                        payload: {
                            error
                        }
                    })

                    reject(error)
                })
        })
    }
}

export function updateProject(id, body) {
    return dispatch => {
        dispatch({
            type: constants.UPDATE_PROJECT_START
        })

        return new Promise((resolve, reject) => {
            fetch
                .patch("/projects/" + id, body)
                .then(data => {
                    dispatch({
                        type: constants.UPDATE_PROJECT_SUCCESS,
                        payload: {
                            data
                        }
                    })

                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: constants.UPDATE_PROJECT_ERROR,
                        payload: {
                            error
                        }
                    })

                    reject(error)
                })
        })
    }
}

export function deleteProject(id) {
    return dispatch => {
        dispatch({
            type: constants.DELETE_PROJECT_START
        })

        return new Promise((resolve, reject) => {
            fetch
                .deleteRAW("/projects/" + id)
                .then(data => {
                    dispatch({
                        type: constants.DELETE_PROJECT_SUCCESS,
                        payload: {
                            data
                        }
                    })

                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: constants.DELETE_PROJECT_ERROR,
                        payload: {
                            error
                        }
                    })

                    reject(error)
                })
        })
    }
}

export const shouldFetchProject = resource => (dispatch, getState) => {
    return fetchStatus.isResourceNotInit(resource)
}

export function reloadProjects() {
    return {
        type: "RELOAD_PROJECT_LIST"
    }
}

export function getProjects(params = {}) {
    return dispatch => {
        dispatch(getProjectsStart())
        return fetch
            .get("/projects?" + queryStringFromParams(params))
            .then(data => {
                dispatch(getProjectsSuccess(data))
            })
            .catch(error => {
                dispatch(getProjectsError(error))
            })
    }
}

export function addProductToProject(params) {
    return dispatch => {
        params = removeEmptyFromParams(params)

        dispatch({
            type: constants.ADD_PRODUCT_TO_PROJECT_REQUEST
        })

        return new Promise((resolve, reject) => {
            fetch
                .post(`/project-items`, params)
                .then(data => {
                    dispatch({
                        type: constants.ADD_PRODUCT_TO_PROJECT_SUCCESS,
                        payload: params
                    })
                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: constants.ADD_PRODUCT_TO_PROJECT_FAILURE,
                        payload: {
                            error
                        }
                    })
                    reject(error)
                })
        })
    }
}

export function updateProduct(id, body) {
    return dispatch => {
        dispatch({
            type: constants.PROJECT_UPDATE_PRODUCT_REQUEST
        })

        return new Promise((resolve, reject) => {
            fetch
                .patch("/project-items/" + id, body)
                .then(data => {
                    dispatch({
                        type: constants.PROJECT_UPDATE_PRODUCT_SUCCESS,
                        payload: {
                            data
                        }
                    })

                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: constants.PROJECT_UPDATE_PRODUCT_FAILURE,
                        payload: {
                            error
                        }
                    })

                    reject(error)
                })
        })
    }
}

export function resetRoomsFetchStatus() {
    return dispatch => {
        dispatch({
            type: constants.RESET_ROOMS_FETCH_STATUS
        })
    }
}

export function removeProductFromProject(id) {
    return dispatch => {
        dispatch({
            type: constants.REMOVE_PRODUCT_FROM_PROJECT_START
        })

        return new Promise((resolve, reject) => {
            fetch
                .deleteRAW(`/project-items/${id}`)
                .then(data => {
                    dispatch({
                        type: constants.REMOVE_PRODUCT_FROM_PROJECT_SUCCESS
                    })

                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: constants.REMOVE_PRODUCT_FROM_PROJECT_ERROR,
                        payload: {
                            error
                        }
                    })
                    reject(error)
                })
        })
    }
}

export const getProjectStart = () => ({ type: constants.GET_PROJECT_START })
export const getProjectSuccess = data => ({ type: constants.GET_PROJECT_SUCCESS, payload: { data } })
export const getProjectError = error => ({ type: constants.GET_PROJECT_ERROR, payload: { error } })

export const getProject = (id, token = null) => {
    return dispatch => {
        dispatch(getProjectStart())

        return new Promise((resolve, reject) => {
            const action = token ? fetch.get(`/project-shares/${token}`) : fetch.get("/projects/" + id)

            action
                .then(data => {
                    dispatch(getProjectSuccess(data))

                    resolve(data)
                })
                .catch(error => {
                    dispatch(getProjectError(error))

                    reject(error)
                })
        })
    }
}

export const resetCurrentProject = () => {
    return {
        type: "RESET_CURRENT_PROJECT"
    }
}

export const updateProjectProductQuantity = (projectId, productId, quantity) => {
    return dispatch => {
        dispatch({
            type: "UPDATE_PROJECT_PRODUCT_QUANTITY",
            payload: {
                id: productId,
                quantity
            }
        })

        dispatch({
            type: "UPDATE_PRODUCT_QUANTITY_START"
        })

        return new Promise((resolve, reject) => {
            fetch
                .patch("/projects/" + projectId + "/products/" + productId, { quantity })
                .then(data => {
                    dispatch({
                        type: "UPDATE_PRODUCT_QUANTITY_SUCCESS"
                    })

                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: "UPDATE_PRODUCT_QUANTITY_ERROR",
                        payload: {
                            error
                        }
                    })

                    reject(error)
                })
        })
    }
}

export const shareProject = (projectId, values) => {
    return dispatch => {
        dispatch({
            type: constants.SHARE_PROJECT_START
        })

        return new Promise((resolve, reject) => {
            fetch
                .post("/projects/" + projectId + "/share", values)
                .then(data => {
                    dispatch({
                        type: constants.SHARE_PROJECT_SUCCESS,
                        payload: {
                            data
                        }
                    })

                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: constants.SHARE_PROJECT_ERROR,
                        payload: {
                            error
                        }
                    })

                    reject(error)
                })
        })
    }
}

export const getProductsProjects = (params = {}) => {
    return dispatch => {
        dispatch({
            type: constants.GET_PRODUCTS_PROJECTS_START
        })

        return new Promise((resolve, reject) => {
            fetch
                .get("/projects?" + queryStringFromParams({ page: 1, length: 9999, ...params }))
                .then(data => {
                    dispatch({
                        type: constants.GET_PRODUCTS_PROJECTS_SUCCESS,
                        payload: {
                            data
                        }
                    })

                    resolve(data)
                })
                .catch(error => {
                    dispatch({
                        type: constants.GET_PRODUCTS_PROJECTS_ERROR,
                        payload: {
                            error
                        }
                    })

                    reject(error)
                })
        })
    }
}

export const getMoreProducts = (params = {}) => dispatch => {
    params = Object.keys(params).reduce((acc, key) => {
        if (params[key]) {
            acc[key] = params[key]
        }

        return acc
    }, {})

    dispatch({
        type: constants.GET_MORE_PROJECTS_START
    })

    return new Promise((resolve, reject) => {
        fetch
            .get("/projects?" + queryStringFromParams(params))
            .then(data => {
                dispatch({
                    type: constants.GET_MORE_PROJECTS_SUCCESS,
                    payload: {
                        data
                    }
                })

                resolve(data)
            })
            .catch(error => {
                dispatch({
                    type: constants.GET_MORE_PROJECTS_ERROR,
                    payload: {
                        error
                    }
                })

                reject(error)
            })
    })
}

export const createOrderFromProject = (projectId, project_items) => dispatch => {
    dispatch({
        type: constants.CREATE_ORDER_FROM_PROJECT_START
    })

    return new Promise((resolve, reject) => {
        fetch
            .post(`/projects/${projectId}/order`, { project_items })
            .then(data => {
                dispatch({
                    type: constants.CREATE_ORDER_FROM_PROJECT_SUCCESS,
                    payload: {
                        data
                    }
                })

                resolve(data)
            })
            .catch(error => {
                dispatch({
                    type: constants.CREATE_ORDER_FROM_PROJECT_ERROR,
                    payload: {
                        error
                    }
                })

                reject(error)
            })
    })
}

export const updateProductProjects = (id, projects) => dispatch => {
    return dispatch({
        type: "UPDATE_PRODUCT_PROJECTS",
        payload: {
            id,
            projects
        }
    })
}

export const saveFiltersListProjects = filters => dispatch => {
    return dispatch({
        type: "SAVE_FILTERS_LIST_PROJECTS",
        payload: filters
    })
}
