import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getTagsStart() {
    return {
        type: constants.GET_TAGS_START
    }
}
export function getTagsSuccess(data) {
    return {
        type: constants.GET_TAGS_SUCCESS,
        payload: {
            data
        }
    }
}
export function getTagsError(error) {
    return {
        type: constants.GET_TAGS_ERROR,
        payload: {
            error
        }
    }
}
export function getTags(type, length = constants.LENGTH_WITHOUT_PAGINATION) {
    return dispatch => {
        dispatch(getTagsStart())
        fetch
            .get(`/tags${length ? "?length=" + length : ""}${type ? `&type=${type}` : ""}`)
            .then(data => {
                dispatch(getTagsSuccess(data))
            })
            .catch(error => {
                dispatch(getTagsError(error))
            })
    }
}

export function addTagStart() {
    return {
        type: constants.ADD_TAG_START
    }
}
export function addTagSuccess(payload) {
    return {
        type: constants.ADD_TAG_SUCCESS,
        payload
    }
}
export function addTagError(error) {
    return {
        type: constants.ADD_TAG_ERROR,
        payload: {
            error
        }
    }
}
export function addTag({ name }) {
    return async dispatch => {
        dispatch(addTagStart())

        try {
            const data = await fetch.post("/tags", { name })
            dispatch(addTagSuccess(data))
            return data
        } catch (error) {
            dispatch(addTagError(error))
            return Promise.reject(error)
        }
    }
}
