import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import withForm from "HOC/withForm"
import models from "models/index"

import ForgotPassword from "./ForgotPassword"

class ForgotPasswordContainer extends Component {
    state = {
        isSuccess: false
    }

    handleSubmitSuccess(data) {
        this.setState({
            isSuccess: true
        })
    }

    handleSubmitAction(event) {
        const { handleSubmit } = this.props
        event.preventDefault()

        handleSubmit({ method: "post", handleSuccess: this.handleSubmitSuccess.bind(this) })
    }

    render() {
        const { isError, isSaving, toRender, handleFieldChange } = this.props
        const { isSuccess } = this.state
        return (
            <ForgotPassword
                isError={isError}
                isSuccess={isSuccess}
                isSaving={isSaving}
                formFields={toRender()}
                handleFieldChange={handleFieldChange}
                handleSubmitAction={this.handleSubmitAction.bind(this)}
            />
        )
    }
}

export default withForm(withRouter(ForgotPasswordContainer), { model: models.ForgotPassword })
