import React, { useState, useEffect } from "react"

import { connect } from "react-redux"
import * as productActions from "actions/product"
import * as uploadModuleActions from "actions/uploadModule"
import * as notificationActions from "actions/notification"
import * as brandActions from "actions/brand"

import env from "env"

import withStyles from "HOC/withStyles"
import styles from "./styles.module.css"

import Header from "../Header"
import Filters from "./components/Filters"
import ProductsList from "../ProductsList"
import Cta from "./components/Cta"
import Modal from "components/Modal/Modal"
import ReviewModal from "components/Reusable/ReviewModal"

const API = env.API

const defaultSorting = { by: "created_at", direction: "desc" }
const defaultPagination = { page: 1, length: 20 }
const defaultDateRange = {
    startDate: null,
    endDate: null
}
const defaultReviewModalData = { isShown: false, isSaving: false, fileData: {} }
const defaultBrand = { id: null, label: "" }

const Products = ({
    saveTransferedImagesLocally,
    transferedImages,
    triggerImageSelect,
    changeNumberOfImagesInProduct,
    currentFolderId,
    currentlySelectedImages,
    transferFromProduct,
    products,
    brandList,
    prepareImageToSendFromProduct,
    getProductsSearchAndSort,
    showErrorNotification,
    getBrands
}) => {
    const [sorting, setSorting] = useState(defaultSorting)
    const [pagination, setPagination] = useState(defaultPagination)
    const [query, setQuery] = useState("")
    const [dateRange, setDateRange] = useState(defaultDateRange)
    const [isWithoutImages, setWithoutImages] = useState(false)
    const [reviewModalData, setReviewModalData] = useState(defaultReviewModalData)
    const [brand, setBrand] = useState(defaultBrand)
    const [loadedOnce, setLoadedOnce] = useState(false)
    const handleChangeTable = params => {
        const isSortingChanged =
            sorting.by !== (params.sorting || {}).by || sorting.direction !== params.sorting.direction

        if (params.page !== pagination.page) {
            setPagination(oldPagination => ({ ...oldPagination, page: params.page }))
        }
        if (isSortingChanged) {
            setSorting(params.sorting)
        }
    }

    useEffect(() => {
        if (products && products.isLoaded) {
            setLoadedOnce(true)
        }
    }, [products])

    const handleGetProducts = () => {
        let withoutImages = {}
        if (isWithoutImages) {
            withoutImages = {
                images: { max: 0 }
            }
        }

        const searchAndSort = {
            sort_by: sorting ? sorting.by : "created_at",
            sort_direction: sorting ? sorting.direction : "desc",
            ...pagination,
            ...dateRange,
            query,
            ...withoutImages,
            brands: brand.id ? [brand.id] : []
        }
        getProductsSearchAndSort(searchAndSort)
    }

    useEffect(() => {
        setPagination(oldPagination => ({ ...oldPagination, page: 1 }))
    }, [query, dateRange, isWithoutImages, brand])
    useEffect(handleGetProducts, [sorting, pagination, brand])

    const handleUploadReject = data => {
        const error = data && data.error && data.error[0]
        showErrorNotification(error)
    }

    const handleUpdateImportProducts = spreadsheetId => {
        setReviewModalData(prevData => ({ ...prevData, isSaving: true }))
        productActions
            .updateImportProducts(spreadsheetId)
            .then(() => {
                setReviewModalData(prevData => ({ ...prevData, isSaving: false, isShown: false }))
                handleGetProducts()
                getBrands()
            })
            .catch(error => {
                setReviewModalData(prevData => ({
                    ...prevData,
                    fileData: { ...prevData.fileData, errorsCount: error.errorsCount },
                    isSaving: false
                }))
                showErrorNotification(error && error.message ? error.message : "Error with uploaded file")
            })
    }

    return (
        <section>
            <Header
                title="Products"
                cta={
                    <Cta
                        endpoint={`${API.baseUrl}/imports`}
                        type="products"
                        handleUploadSuccess={data => {
                            setReviewModalData(prevData => ({
                                ...prevData,
                                isShown: true,
                                fileData: data && data.length > 0 ? data[0] : {}
                            }))
                        }}
                        handleUploadFailure={data =>
                            showErrorNotification(
                                data && data.error && data.error[0] ? data.error[0] : "Error with uploaded file"
                            )
                        }
                        handleUploadReject={handleUploadReject}
                    />
                }
            />
            <Filters
                query={query}
                setQuery={setQuery}
                isWithoutImages={isWithoutImages}
                setWithoutImages={setWithoutImages}
                dateRange={dateRange}
                setDateRange={setDateRange}
                brandList={brandList}
                brand={brand}
                setBrand={setBrand}
            />
            <ProductsList
                changeNumberOfImagesInProduct={changeNumberOfImagesInProduct}
                currentFolderId={currentFolderId}
                showErrorNotification={showErrorNotification}
                products={products}
                transferedImages={transferedImages}
                saveTransferedImagesLocally={saveTransferedImagesLocally}
                triggerImageSelect={triggerImageSelect}
                transferFromProduct={transferFromProduct}
                prepareImageToSendFromProduct={prepareImageToSendFromProduct}
                currentlySelectedImages={currentlySelectedImages}
                handleChangeTable={handleChangeTable}
                defaultSorting={defaultSorting}
                loadedOnce={loadedOnce}
            />
            {reviewModalData.isShown && (
                <Modal>
                    <ReviewModal
                        handleHide={() => setReviewModalData(prevData => ({ ...prevData, isShown: false }))}
                        fileData={reviewModalData.fileData}
                        isSaving={reviewModalData.isSaving}
                        handleSave={spreadsheetId => handleUpdateImportProducts(spreadsheetId)}
                    />
                </Modal>
            )}
        </section>
    )
}

const mapStateToProps = state => ({
    transferedImages: state.ui.uploadModule.transferedImages,
    transferFromProduct: state.ui.uploadModule.transferFromProduct,
    currentFolderId: state.ui.uploadModule.currentFolderId,
    currentlySelectedImages: state.ui.uploadModule.currentlySelectedImages,
    products: state.db.products.searchAndSortList,
    brandList: state.db.brands.list.data
})

const mapDispatchToProps = dispatch => ({
    saveTransferedImagesLocally: data => dispatch(uploadModuleActions.saveTransferedImagesLocally(data)),
    prepareImageToSendFromProduct: data => dispatch(uploadModuleActions.prepareImageToSendFromProduct(data)),
    triggerImageSelect: data => dispatch(uploadModuleActions.triggerImageSelect(data)),
    changeNumberOfImagesInProduct: data => dispatch(productActions.changeNumberOfImagesInProduct(data)),
    getProductsSearchAndSort: searchAndSort => dispatch(productActions.getProductsSearchAndSort(searchAndSort)),
    showErrorNotification: data => dispatch(notificationActions.showErrorNotification(data)),
    getBrands: () => dispatch(brandActions.getBrands())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(Products, styles))
