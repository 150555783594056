import React from "react"
import ReactSVG from "react-svg"

import useStyles from "helpers/getCxFromStyles"
import styles from "./Search.css"
import searchIcon from "assets/search.svg"
import closeIcon from "assets/close.svg"

const Search = props => {
    const { handleSearch, handleClose, query } = props
    const cx = useStyles(styles)

    return (
        <div className={cx("container")}>
            <ReactSVG src={searchIcon} className={cx("searchIcon")} />
            <div className={cx("input")}>
                <input value={query} placeholder="Search..." onChange={event => handleSearch(event.target.value)} />
            </div>
            <ReactSVG src={closeIcon} className={cx("closeIcon")} onClick={handleClose} />
        </div>
    )
}

export default Search
