import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import useDebounce from "hooks/useDebounce"
import useNonmutableState from "hooks/useNonmutableState"
import List from "./List"

const ListContainer = props => {
    const { filterSearchPhraseHandleChange, dateRange: dateRangeFromStore } = props

    const [filterIsOpen, filterSetIsOpen] = useState(!window.matchMedia(`(max-width: 1024px)`).matches)
    const [filterSearchPhrase, filterSetSearchPhrase] = useState(props.filterSearchPhrase)
    const [filterInitialSearchPhrase, filterInitialSearchPhraseRef, filterSetInitalSearchPhrase] = useNonmutableState(
        ""
    )

    const [dateRange, setDateRange] = useState(dateRangeFromStore)

    const dispatch = useDispatch()
    const listViewType = useSelector(state => state.ui.products.list.viewType)

    useEffect(() => {
        filterSetInitalSearchPhrase(props.filterSearchPhrase)
    }, [])

    useDebounce(
        () => {
            filterSearchPhraseHandleChange(filterSearchPhrase)
        },
        400,
        [filterSearchPhrase]
    )

    const handleClearAll = () => {
        dispatch({ type: "CLEAR_PRODUCT_FILTERS_GROUP" })
        filterSetInitalSearchPhrase("")
        setDateRange({})
    }

    return (
        <List
            {...props}
            dateRange={dateRange}
            setDateRange={setDateRange}
            handleClearAll={handleClearAll}
            filterIsOpen={filterIsOpen}
            filterInitialSearchPhrase={filterInitialSearchPhrase}
            filterInitialSearchPhraseRef={filterInitialSearchPhraseRef}
            filterHandleFilterToggle={filterHandleFilterToggle}
            filterSetSearchPhrase={filterSetSearchPhrase}
            listViewType={listViewType}
            listViewTypeHandleChange={listViewTypeHandleChange}
        />
    )

    function filterHandleFilterToggle() {
        filterSetIsOpen(!filterIsOpen)
    }

    function listViewTypeHandleChange(viewType) {
        dispatch({
            type: "ui/products/list/SET_VALUES",
            payload: {
                viewType: viewType
            }
        })
    }
}

export default ListContainer
