import React, { Fragment } from "react"
import PropTypes from "prop-types"

import ReactSvg from "react-svg"

import getReadableFileSize from "helpers/getReadableFileSize"
import { formatDate } from "helpers/date"

import withStyles from "HOC/withStyles"
import styles from "./listItem.css"

import downloadIcon from "assets/download.svg"
import closeIcon from "assets/close-blue.svg"

const ListItem = props => {
    const { cx, isUploading, id, url, name, employee, created_at, size, progress, handleDelete } = props

    return (
        <li className={cx("root", { isUploading })} key={id}>
            <a href={url} rel="noopener noreferrer" target="_blank" download={name}>
                <ReactSvg src={downloadIcon} />
                <span>{name}</span>
            </a>
            {!isUploading && (
                <Fragment>
                    <div className={cx({ empty: !employee })} />
                    <div className={cx({ empty: !created_at })}>{created_at ? formatDate(created_at) : ""}</div>
                </Fragment>
            )}
            {isUploading ? (
                <div className={cx("progressBar")}>
                    <div style={{ width: `${progress}%` }} />
                </div>
            ) : (
                <div className={cx("flexBox")}>
                    <div className={cx("size")}>{getReadableFileSize(size)}</div>
                    <button type="button" onClick={() => handleDelete(id)} className={cx("closeButton")}>
                        <ReactSvg src={closeIcon} />
                    </button>
                </div>
            )}
        </li>
    )
}

ListItem.propTypes = {
    isUploading: PropTypes.bool,
    id: PropTypes.number,
    url: PropTypes.string,
    name: PropTypes.string.isRequired,
    employee: PropTypes.string,
    created_at: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.number,
    progress: PropTypes.number,
    handleDelete: PropTypes.func
}

export default withStyles(ListItem, styles)
