import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function addNoteStart() {
    return {
        type: constants.ADD_NOTE_START
    }
}

export function addNoteSuccess(payload) {
    return {
        type: constants.ADD_NOTE_SUCCESS,
        payload
    }
}
export function addNoteError(error) {
    return {
        type: constants.ADD_NOTE_ERROR,
        payload: {
            error
        }
    }
}

export function addNote({ type, id, text = "", attachments = [], successAction }) {
    return dispatch => {
        dispatch(addNoteStart())

        return new Promise((resolve, reject) => {
            fetch
                .post("/notes", {
                    text,
                    object_type: type,
                    object_id: id,
                    attachments
                })
                .then(data => {
                    dispatch(addNoteSuccess({ ...data, type }))
                    resolve(data)
                })
                .then(() => successAction && dispatch(successAction))
                .catch(error => {
                    dispatch(addNoteError(error))
                    reject(error)
                })
        })
    }
}

export function editNoteStart() {
    return {
        type: constants.EDIT_NOTE_START
    }
}

export function editNoteSuccess(payload) {
    return {
        type: constants.EDIT_NOTE_SUCCESS,
        payload
    }
}

export function editNote({
    type,
    id,
    text = "",
    attachments = [],
    deletedAttachmentsIds = [],
    successAction = Promise.resolve()
}) {
    const precedingAction =
        deletedAttachmentsIds.length > 0
            ? () => fetch.deleteRAW("/media", { ids: deletedAttachmentsIds })
            : () => Promise.resolve()

    return dispatch => {
        dispatch(editNoteStart())

        return new Promise((resolve, reject) => {
            precedingAction().then(() => {
                fetch
                    .patch(`/notes/${id}`, {
                        text,
                        attachments
                    })
                    .then(({ data }) => {
                        dispatch(editNoteSuccess({ data, type }))
                        resolve({ data, type })
                        successAction && dispatch(successAction)
                    })
                    .catch(error => {
                        dispatch(addNoteError(error))
                        reject(error)
                    })
            })
        })
    }
}

export function deleteNoteStart() {
    return {
        type: constants.DELETE_NOTE_START
    }
}

export function deleteNoteSuccess(payload) {
    return {
        type: constants.DELETE_NOTE_SUCCESS,
        payload
    }
}
export function deleteNoteError(error) {
    return {
        type: constants.DELETE_NOTE_ERROR,
        payload: {
            error
        }
    }
}

export function deleteNote({ noteId, type, successAction = Promise.resolve() }) {
    return dispatch => {
        dispatch(deleteNoteStart())

        return new Promise((resolve, reject) => {
            fetch
                .deleteRAW(`/notes/${noteId}`)
                .then(() => dispatch(deleteNoteSuccess({ data: { id: noteId }, type })))
                .then(() => successAction && dispatch(successAction))
                .then(() => resolve(noteId))
                .catch(error => {
                    dispatch(deleteNoteError(error))
                    reject(error)
                })
        })
    }
}
