import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import fetch from "helpers/fetch"

import withStyles from "HOC/withStyles"
import { getProject } from "actions/projects"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import { SubmitModal, Input } from "ui"

import styles from "./CreateRoomModal.css"
import inputStyles from "./overrides/inputStyles.css"

const ENTER_KEY = 13

const CreateRoomModal = props => {
    const { cx, isOpen, projectId, room, onClose } = props

    const dispatch = useDispatch()

    const [value, setValue] = useState(null)
    const [area, setArea] = useState(null)
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        !isOpen && clearValue()
    }, [isOpen])

    useEffect(() => {
        isLoading && createOrUpdateRoom()
    }, [isLoading])

    const clearValue = () => {
        setValue("")
        setArea("")
        setErrors({})
    }

    useEffect(() => {
        if (room) {
            setValue(room.name)
            setArea(room.calculations.area)
        }
    }, [room])

    useEffect(() => {
        if (value === null || area === null) {
            return
        }

        const errors = validate()
        setErrors(errors)
    }, [value, area])

    const validateField = (name, value, validatorFn) => {
        const isValid = validatorFn(value)

        return {
            name,
            valid: isValid
        }
    }

    const validate = () => {
        const errors = [
            validateField("value", value, value => (value || "").length > 0),
            validateField("area", area, area => {
                if (!area && area !== 0) {
                    return true
                }

                if ((area || area === 0 ? area : "").toString().length === 0) {
                    return false
                }

                area = Number(area)

                return !isNaN(area) && area > 0
            })
        ].reduce((acc, item) => {
            if (!item.valid) {
                acc[item.name] = true
            }

            return acc
        }, {})

        return errors
    }

    const isValid = () => {
        const errors = validate()

        return Object.keys(errors).length === 0
    }

    const onKeyDown = event => event.keyCode === ENTER_KEY && !isLoading && onSubmit()

    const onSubmit = () => {
        if (isLoading) {
            return
        }

        if (validate()) {
            setIsLoading(true)
        }
    }

    const handleInputRefOnInit = inputRef => inputRef.focus()

    const createOrUpdateRoom = () => {
        const body = {
            name: value,
            area: area,
            project_id: projectId
        }

        const makeRequest = () => {
            if (room) {
                return fetch.patchRAW("/rooms/" + room.id, body)
            }

            return fetch.postRAW("/rooms", body)
        }

        makeRequest()
            .then(() => {
                dispatch(showSuccessNotification())
                dispatch(getProject(projectId))
                onClose()
            })
            .catch(response => {
                const errors = Object.keys(response.errors || {})
                    .map(name => ({
                        name: name,
                        valid: false
                    }))
                    .reduce((acc, item) => {
                        if (!item.valid) {
                            acc[item.name] = true
                        }

                        return acc
                    }, {})

                setErrors(errors)
                dispatch(showErrorNotification())
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <SubmitModal
            isOpen={isOpen}
            isLoading={isLoading}
            isSubmitDisabled={!isValid()}
            withLine={false}
            header={displayTitle()}
            preventClickOutside={true}
            body={
                <div className={cx("body")}>
                    <div className={cx("nameContainer")}>
                        <Input
                            type="text"
                            label="Write a room name..."
                            isPlaceholder={true}
                            value={value}
                            onChange={({ event }) => {
                                setValue(event.target.value)
                            }}
                            onKeyDown={onKeyDown}
                            customStyles={inputStyles}
                            handleInputRefOnInit={handleInputRefOnInit}
                            error={errors.value}
                            tabIndex={1}
                        />
                    </div>
                    <div className={cx("surfaceContainer")}>
                        <Input
                            type="text"
                            value={area}
                            isPlaceholder={true}
                            onChange={({ event }) => {
                                setArea(event.target.value)
                            }}
                            onKeyDown={onKeyDown}
                            customStyles={inputStyles}
                            innerLabel={
                                <span>
                                    ft<sup>2</sup>
                                </span>
                            }
                            error={errors.area}
                            tabIndex={1}
                        />
                    </div>
                </div>
            }
            submitText={displayButtonText()}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    )

    function displayTitle() {
        const { room } = props

        if (room) {
            return "Edit room"
        }

        return "Create new room"
    }

    function displayButtonText() {
        const { room } = props

        if (room) {
            return "Save"
        }

        return "Create"
    }
}

CreateRoomModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    projectId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
}

export default withStyles(CreateRoomModal, styles)
