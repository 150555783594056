import React from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import * as Yup from "yup"

import { useActions } from "hooks"
import { updateEmailTemplate } from "actions/email-templates"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import textareaStyles from "./overrides/Textarea.css"
import inputStyles from "./overrides/Input.css"
import submitButtonStyles from "./overrides/SubmitButton.css"
import styles from "./EditEmailTemplate.module.css"
import withStyles from "HOC/withStyles"

import { Button, Textarea, Modal, Input } from "ui"

const schema = Yup.object().shape({
    subject: Yup.string().required("This field is required."),
    message: Yup.string().required("This field is required.")
})

const EditEmailTemplate = ({ cx, handleClose, emailTemplateToEdit: { id, name, subject, message } }) => {
    const actions = useActions({ showErrorNotification, updateEmailTemplate, showSuccessNotification })

    const handleSubmit = async (values, { setErrors, setSubmitting }) => {
        const { subject, message } = values

        const data = { subject, message }

        setSubmitting(true)

        try {
            await actions.updateEmailTemplate(id, data)
            actions.showSuccessNotification()
            handleClose()
        } catch (data) {
            if (data) {
                const { errors: dataErrors, message } = data
                const errors = dataErrors || (message ? { message } : null)

                errors && setErrors(errors)
            }
            actions.showErrorNotification()
            setSubmitting(false)
        }
    }

    const renderBody = () => {
        return (
            <Formik
                initialValues={{
                    subject: subject,
                    message: message
                }}
                onSubmit={handleSubmit}
                validationSchema={schema}
            >
                {({ errors, handleSubmit, isSubmitting, setFieldValue, touched, values }) => {
                    return (
                        <form className={cx("form")} onSubmit={handleSubmit}>
                            <div className={cx("row", "type-container")}>
                                <label className={cx("label")}>Email type:</label>
                                <select className={cx("type")} disabled>
                                    <option>{name}</option>
                                </select>
                            </div>
                            <div className={cx("row")}>
                                <Input
                                    value={values.subject}
                                    name="subject"
                                    label="Subject"
                                    onChange={value => {
                                        setFieldValue("subject", value.event.target.value)
                                    }}
                                    error={touched.subject && errors.subject}
                                    customStyles={inputStyles}
                                />
                            </div>
                            <div className={cx("row")}>
                                <Textarea
                                    value={values.message}
                                    name="message"
                                    label="Message"
                                    onChange={value => {
                                        setFieldValue("message", value.event.target.value)
                                    }}
                                    error={touched.message && errors.message}
                                    customStyles={textareaStyles}
                                />
                            </div>
                            <div className={cx("buttons")}>
                                <span className={cx("form-cancel")} onClick={handleClose}>
                                    Cancel
                                </span>
                                <Button
                                    type="submit"
                                    className={"first-button"}
                                    customStyles={submitButtonStyles}
                                    label="Save"
                                    isDisabled={isSubmitting}
                                    isLoading={isSubmitting}
                                />
                            </div>
                        </form>
                    )
                }}
            </Formik>
        )
    }

    return (
        <Modal isOpen={true} closeModal={handleClose} preventClickOutside={true}>
            <Modal.Header>Edit email template</Modal.Header>
            <Modal.Body>{renderBody()}</Modal.Body>
        </Modal>
    )
}

EditEmailTemplate.propTypes = {
    emailTemplateToEdit: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        subject: PropTypes.string,
        message: PropTypes.string
    }).isRequired,
    handleClose: PropTypes.func.isRequired
}

export default withStyles(EditEmailTemplate, styles)
