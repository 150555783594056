import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { SelectWithSearch } from "ui"

import useSelectProduct from "./useSelectProduct"

const SelectProduct = ({ name, label, value, error, setValue, isDisabled, isRequired, params }) => {
    const { propsForSelectWithSearch } = useSelectProduct({ setValue, params, isDisabled })
    const [valueState, setValueState] = useState(null)

    useEffect(() => {
        setValueState(value)
    }, [value])

    return (
        <SelectWithSearch
            name={name}
            label={label}
            value={valueState}
            error={error}
            isDisabled={isDisabled}
            isRequired={isRequired}
            {...propsForSelectWithSearch}
        />
    )
}

SelectProduct.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool,
    params: PropTypes.shape({
        brand: PropTypes.string
    })
}

export default SelectProduct
