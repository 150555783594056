import fetch from "helpers/fetch"
import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"

export const KEY = "shippingMethods"

const fetchShippingMethodList = ({ page = 1, length = 20 }) =>
    fetch.get(`/shipping-methods?page=${page}&length=${length}`)

export const useShippingMethodList = useQueryListFactory({
    key: KEY,
    fetchFunction: fetchShippingMethodList
})
