import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import debounce from "lodash.debounce"

import * as fetchStatusHelper from "helpers/fetchStatus"
import * as projectActions from "./actions"
import ChooseProject from "./ChooseProject"

let debounceFilterSearchPhrase

const ChooseProjectContainer = props => {
    const { projects, actions, headerText, labelChooseButton, withSelectRoom } = props

    const [filterSearchPhrase, setFilterSearchPhrase] = useState("")
    const [filterDebouncedSearchPhrase, setFilterDebouncedSearchPhrase] = useState("")
    const [selectedProject, setSelectedProject] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [projectList, setProjectList] = useState([])

    const noRoomOption = { id: null, label: "Not assigned to a room", value: null }

    useEffect(() => {
        setProjectList(
            projects.data.map(project => ({
                ...project,
                selectedRoom: noRoomOption
            }))
        )
    }, [projects.data])

    useEffect(() => {
        debounceFilterSearchPhrase = debounce(setFilterDebouncedSearchPhrase, 350)

        return () => {
            debounceFilterSearchPhrase.cancel()
        }
    }, [])

    useEffect(() => {
        if (actions.shouldFetch(projects)) {
            actions.fetchProjects(getCurrentFilters())
        }
    }, [projects])

    useEffect(() => {
        debounceFilterSearchPhrase(filterSearchPhrase)
    }, [filterSearchPhrase])

    useEffect(() => {
        actions.fetchProjects(getCurrentFilters())
    }, [filterDebouncedSearchPhrase])

    useEffect(() => {
        return () => {
            actions.clearState()
        }
    }, [])

    const handleSelectRoom = ({ projectId, selectedRoom }) => {
        setProjectList(
            projectList.map(project =>
                project.id === projectId
                    ? {
                          ...project,
                          selectedRoom: selectedRoom || noRoomOption
                      }
                    : project
            )
        )
    }

    const handleLoadMoreProjects = () => {
        const canLoadMore = !fetchStatusHelper.fromResource({ fetchStatus: projects.nextFetchStatus }).isLoading

        if (canLoadMore) {
            const params = { ...getCurrentFilters(), page: projects.meta.current_page + 1 }
            actions.fetchMoreProjects(params)
        }
    }

    function getCurrentFilters() {
        const params = {}

        if (filterSearchPhrase) {
            params.query = filterDebouncedSearchPhrase
        }

        return { ...params, ...props.filters }
    }

    const handleSelect = project => {
        if (!project) {
            return setSelectedProject(null)
        }

        setSelectedProject(project)
        props.handleSelect(
            withSelectRoom ? { project_id: project.id, room_id: project.selectedRoom.id } : { project_id: project.id }
        )
    }

    return (
        <ChooseProject
            selectedProject={selectedProject}
            isSaving={isSaving}
            setIsSaving={setIsSaving}
            projects={projects}
            projectList={projectList}
            handleSelectRoom={handleSelectRoom}
            filterSearchPhrase={filterSearchPhrase}
            hasMoreProjects={projects.meta.last_page !== projects.meta.current_page}
            handleChangeFilterSearchPhrase={setFilterSearchPhrase}
            handleLoadMoreProjects={handleLoadMoreProjects}
            handleSelect={handleSelect}
            labelChooseButton={labelChooseButton}
            headerText={headerText}
            withSelectRoom={withSelectRoom}
        />
    )
}

const mapStateToProps = state => {
    return {
        projects: state.ui.modals.chooseProject.projects.list
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...projectActions
            },
            dispatch
        )
    }
}

ChooseProjectContainer.defaultProps = {
    filters: {}
}

ChooseProjectContainer.propTypes = {
    handleSelect: PropTypes.func,
    labelChooseButton: PropTypes.string,
    headerText: PropTypes.string,
    withSelectRoom: PropTypes.bool
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseProjectContainer)
