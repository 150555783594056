import React, { useState, useEffect } from "react"

import ProductsList from "components/Reusable/ProductsList/ProductsList"
import containerStyles from "./../RelatedProductsContainer.css"
import Loader from "components/Reusable/Loader/Loader"
import * as productActions from "actions/product"
import { useDispatch } from "react-redux"

function setSlider(productsLength, maxProducts) {
    const isSlider = productsLength > maxProducts
    return {
        isSlider,
        options: isSlider
            ? {
                  dots: false,
                  infinite: false,
                  speed: 500,
                  slidesToShow: maxProducts,
                  slidesToScroll: 1,
                  initialSlide: 0
              }
            : {}
    }
}

function maxProductsQuery() {
    const queryMap = {
        9: 2200,
        8: 2060,
        7: 1850,
        6: 1670,
        5: 1439,
        4: 1266,
        3: 940,
        2: 700,
        1: 300
    }

    let mediaQueries = {}
    for (const key in queryMap) {
        mediaQueries[key] = window.matchMedia(`(max-width: ${queryMap[key]}px)`)
    }

    const keys = Object.keys(queryMap)
    const last = Math.max.apply(null, keys.map(key => queryMap[key]))
    const lastKey = keys.find(key => queryMap[key] === last)
    mediaQueries[parseInt(lastKey, 10) + 1] = window.matchMedia(`(min-width: ${queryMap[lastKey]}px)`)

    return mediaQueries
}

function RelatedProductsContainer(props) {
    const { cx, products, isLoading, productId, setIsLoading, isEmpty } = props

    const [currentIndex, setCurrentIndex] = useState(0)
    const [maxProducts, setMaxProducts] = useState(1)

    const mediaQueries = maxProductsQuery()
    const dispatch = useDispatch()
    const nameMaxLength = 24

    const onResize = () => {
        let newMax = 1
        for (const size in mediaQueries) {
            if (mediaQueries[size].matches) {
                newMax = size
                break
            }
        }

        setMaxProducts(newMax)
    }

    useEffect(() => {
        onResize()
        window.addEventListener("resize", onResize)

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    const [settings, setSettings] = useState(setSlider(products.length, maxProducts))

    const handleRemove = relatedId => {
        const data = [{ id: relatedId }]
        const id = productId

        setIsLoading(true)

        productActions.detachRelatedProducts({ id, data }).then(() => {
            setTimeout(() => {
                productId && dispatch(productActions.getProductForRelatedById(productId))
            }, 350)
        })
    }

    const handleAfterChange = index => {
        setCurrentIndex(index)
    }

    useEffect(() => {
        setSettings(setSlider(products.length, maxProducts))
    }, [products, maxProducts])

    return (
        <div className={isLoading ? cx("empty") : ""}>
            {!isLoading && !isEmpty && (
                <ProductsList
                    currentIndex={currentIndex}
                    handleAfterChange={handleAfterChange}
                    sliderSettings={settings}
                    nameMaxLength={nameMaxLength}
                    cx={cx}
                    isOneLineGrid={true}
                    products={products}
                    handleRemove={handleRemove}
                    isLoading={isLoading}
                    isRelated={true}
                    customProductStyles={containerStyles}
                    customStyles={containerStyles}
                />
            )}
            {isLoading && <Loader />}
        </div>
    )
}

export default RelatedProductsContainer
