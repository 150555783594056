import React, { useState } from "react"
import PropTypes from "prop-types"
import _isNumber from "lodash/isNumber"
import classNames from "classnames"

import { ProductDetailsPopup } from "modules/WmsModule/modals"
import { AlertInfoIcon } from "@butterfly-frontend/ui"
import { Image } from "ui"
import { PRODUCT_TYPE_KEYS } from "ui/ChooseProduct/constants/productTypeKeys"
import { AVAILABLE_ATTRIBUTES_VALUES_PROP_TYPE } from "propTypes/combinationPropType"

import styles from "./ProductInfo.module.css"

const ProductInfo = ({ type, name, combination, imageUrl, inStockCounter, incomingCounter }) => {
    const [isCombinationPopupOpen, setIsCombinationPopupOpen] = useState(false)
    const productHasCombination = combination && combination.attribute_values

    const shouldDisplayCounters = _isNumber(inStockCounter) || _isNumber(incomingCounter) || productHasCombination

    return (
        <div className={styles.productListItemSide}>
            {type !== PRODUCT_TYPE_KEYS.SERVICES && <Image className={styles.photo} src={imageUrl} />}

            <div className={styles.infoContainer}>
                <div className={styles.name}>{name}</div>
                {shouldDisplayCounters && (
                    <div className={styles.details}>
                        {_isNumber(inStockCounter) && (
                            <span>
                                In stock: <span className={styles.counterValue}>{inStockCounter}</span>
                            </span>
                        )}
                        {_isNumber(incomingCounter) && (
                            <span>
                                Incoming: <span className={styles.counterValue}>{incomingCounter}</span>
                            </span>
                        )}
                        {productHasCombination && (
                            <ProductDetailsPopup
                                trigger={
                                    <AlertInfoIcon
                                        className={classNames(styles.helpIcon, {
                                            [styles.active]: isCombinationPopupOpen
                                        })}
                                        onClick={() => setIsCombinationPopupOpen(true)}
                                    />
                                }
                                position="right"
                                renderModalOnTablet
                                isOpen={isCombinationPopupOpen}
                                onClose={() => setIsCombinationPopupOpen(false)}
                                productName={name}
                                productImageSrc={combination ? combination.image || imageUrl : null}
                                combination={combination}
                                classes={{ triggerContainer: styles.combinationPopupTrigger }}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}

ProductInfo.propTypes = {
    type: PropTypes.oneOf(Object.values(PRODUCT_TYPE_KEYS)),
    name: PropTypes.string.isRequired,
    combination: PropTypes.shape({
        attribute_values: AVAILABLE_ATTRIBUTES_VALUES_PROP_TYPE
    }),
    imageUrl: PropTypes.string,
    inStockCounter: PropTypes.number,
    incomingCounter: PropTypes.number
}

export default ProductInfo
