import { Component } from "react"
import { withRouter } from "react-router-dom"

class Home extends Component {
    componentDidMount() {
        const { history } = this.props
        history.push("/dashboard")
    }

    render() {
        return null
    }
}

export default withRouter(Home)
