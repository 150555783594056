import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { getShippingMethods } from "actions/shippingMethods"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"
import ShippingMethods from "./ShippingMethods"

const ShippingMethodsContainer = () => {
    const dispatch = useDispatch()

    const shippingMethods = useSelector(state => state.db.shippingMethods)

    useEffect(() => {
        dispatch(
            getShippingMethods({
                page: 1,
                length: LENGTH_WITHOUT_PAGINATION
            })
        )
    }, [])

    return <ShippingMethods shippingMethods={shippingMethods} />
}

export default ShippingMethodsContainer
