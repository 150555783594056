export const INIT_VALUES = {
    first_name: "",
    last_name: "",
    birthday: "",
    email: "",
    phone: "",

    country: "United States of America",
    address: "",
    city: "",
    state: "",
    post_code: "",

    isChangingPassword: false,
    old_password: "",
    password: "",
    password_confirmation: "",

    avatar: "",
    avatarID: undefined,

    roles: [],

    disabled_calendars: [],

    daily_mail: false,
    daily_mail_time: null,

    signature: "",

    warehouses: []
}

export const TABS = {
    PROFILE: "profile",
    ROLES: "roles",
    NOTIFICATIONS: "notifications",
    WAREHOUSES: "warehouses"
}

export const FIELDS_IN_TABS = {
    first_name: TABS.PROFILE,
    last_name: TABS.PROFILE,
    date_of_birth: TABS.PROFILE,
    email: TABS.PROFILE,
    phone: TABS.PROFILE,

    country: TABS.PROFILE,
    address: TABS.PROFILE,
    post_code: TABS.PROFILE,
    city: TABS.PROFILE,
    state: TABS.PROFILE,

    signature: TABS.PROFILE,

    isChangingPassword: TABS.PROFILE,
    old_password: TABS.PROFILE,
    password: TABS.PROFILE,
    password_confirmation: TABS.PROFILE,

    roles: TABS.NOTIFICATIONS,

    disabled_calendars: TABS.NOTIFICATIONS,

    daily_mail: TABS.NOTIFICATIONS,
    daily_mail_time: TABS.NOTIFICATIONS,

    warehouses: TABS.WAREHOUSES
}
