import React, { Component } from "react"

import withStyles from "HOC/withStyles"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import EmptyList from "../EmptyList"
import DragAndDropList from "../DragAndDropList"
import ListRow from "./MainPageListingsRow"

import styles from "./MainPageListings.css"
import slidesIcon from "assets/slides.svg"

class MainPageListings extends Component {
    render() {
        const {
            cx,
            isLoading,
            list,
            handleDelete,
            handleDeleteAll,
            handleReorder,
            handleEdit,
            handleToggle,
            listingType,
            listMapping,
            customActions,
            customColumns,
            customToggleLabel,
            headerSubtitle
        } = this.props

        if (!isLoading && list && !list.length)
            return <EmptyList message={`No ${listingType} added`} icon={slidesIcon} />

        if (isLoading)
            return (
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            )

        return (
            <div className={cx("root")}>
                <div className={cx("header")}>
                    <div className={cx("countSummary")}>
                        <span className={cx("countSlides")}>
                            {list.length} {listingType} added
                        </span>
                        {/*<span className={cx("maxCountSlides")}>TODO</span>*/}
                        {headerSubtitle && <span className={cx("headerSubtitle")}>{headerSubtitle}</span>}
                    </div>
                    <span className={cx("buttonAction", "buttonDelete")} onClick={handleDeleteAll}>
                        Delete all
                    </span>
                </div>
                <ul className={cx("list")}>
                    <DragAndDropList itemsList={list} handleReorder={handleReorder}>
                        {list.map((item, index) => (
                            <ListRow
                                key={index}
                                index={index}
                                item={item}
                                listMapping={listMapping}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                handleToggle={handleToggle}
                                customActions={customActions}
                                customColumns={customColumns}
                                customToggleLabel={customToggleLabel}
                                isLastElement={list.length === index + 1}
                            />
                        ))}
                    </DragAndDropList>
                </ul>
            </div>
        )
    }
}

export default withStyles(MainPageListings, styles)
