import React from "react"
import getStyles from "helpers/getCxFromStyles"
import styles from "./info.module.css"
import { useMediaQuery } from "react-responsive"

const defaultConfig = {
    "Sales order date:": props => props.issuedAt,
    "Revised date:": props => props.revisedAt,
    "Emailed date:": props => props.emailedAt,
    "Print date:": props => props.printedAt,
    "Valid date": props => props.validDate,
    "Employee:": ({ users }) => {
        const employee = (Array.isArray(users) && users.find(user => user.sales_associate)) || {}
        return employee.full_name || "-"
    }
}

const Info = props => {
    const { config = defaultConfig, isLastColumnMarkedOut } = props

    const cx = getStyles(styles)
    const itemsCount = Object.keys(config).length
    const isMobile = useMediaQuery({ query: "(max-width: 991px)" })
    const generalWidth = isMobile ? "100%" : `${100 / itemsCount}%`

    return (
        <ul className={cx("info", { isLastColumnMarkedOut })}>
            {Object.entries(config).map(([label, render], index) => {
                const isLastMarkedInMobile = isMobile && index + 1 === itemsCount && isLastColumnMarkedOut
                const width = isLastMarkedInMobile ? 170 : generalWidth
                return (
                    <li key={label} style={{ width }}>
                        <span className={cx("label")}>{label}</span>
                        <p>{typeof render === "function" ? render(props) || "-" : "-"}</p>
                    </li>
                )
            })}
        </ul>
    )
}

export default Info
