import * as yup from "yup"

import { INPUT_MAX_LENGTH, TEXTAREA_MAX_LENGTH } from "constants/index"

export const portfolio = yup.object().shape({
    title: yup
        .string()
        .max(INPUT_MAX_LENGTH, `Title may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .required("Portfolio title is required"),
    location: yup
        .string()
        .max(INPUT_MAX_LENGTH, `Location may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .nullable(),
    latitude: yup
        .number()
        .max(90, "Latitude may not be greater than 90")
        .min(-90, "Latitude may not be lesser than -90")
        .test("is-longitude", "Latitude is required when longitude is not empty", function(value) {
            const { longitude } = this.parent
            return !(!value && value !== 0 && (longitude || longitude === 0))
        })
        .nullable(),
    longitude: yup
        .number()
        .max(180, "Latitude may not be greater than 180")
        .min(-180, "Latitude may not be lesser than -180")
        .test("is-latitude", "Longitude is required when latitude is not empty", function(value) {
            const { latitude } = this.parent
            return !(!value && value !== 0 && (latitude || latitude === 0))
        })
        .nullable(),
    photography_by: yup
        .string()
        .max(INPUT_MAX_LENGTH, `Photography by may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .nullable(),
    description: yup
        .string()
        .max(TEXTAREA_MAX_LENGTH, `Description may not be greater than ${TEXTAREA_MAX_LENGTH} characters`)
        .nullable(),
    cover_desktop: yup.object().required()
})
