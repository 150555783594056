import React from "react"
import { useParams } from "react-router-dom"

import CollectionForm from "modules/ContentModule/components/CollectionForm"

const SIDEBAR_IMAGES_DIMENSIONS = {
    thumbnail_horizontal: {
        width: 1440,
        height: 490
    },
    thumbnail_vertical: {
        width: 736,
        height: 1086
    },
    home_page: {
        width: 815,
        height: 642
    }
}

const Form = () => {
    const { inspirationId } = useParams()

    return (
        <CollectionForm
            withHomepageVisibility
            collectionLabel="Inspiration"
            sectionableType="inspiration"
            collectionId={Number(inspirationId)}
            basePath="/content/inspirations"
            apiUrl="/inspirations"
            sidebarImagesDimensions={SIDEBAR_IMAGES_DIMENSIONS}
        />
    )
}

export default Form
