import * as constants from "constants/index"

function list(state = {}, action) {
    switch (action.type) {
        case constants.GET_CHANNELS_START:
            return { ...state, isLoading: true, data: [] }
        case constants.GET_CHANNELS_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data.data }
        case constants.GET_CHANNELS_ERROR:
            return { ...state, isLoading: false, data: [] }

        case constants.DELETE_CHANNEL_START:
            return { ...state, isLoading: true }
        case constants.DELETE_CHANNEL_SUCCESS:
            return { ...state, isLoading: false, data: state.data.filter(el => el.id !== action.payload) }
        case constants.DELETE_CHANNEL_ERROR:
            return { ...state, isLoading: false }

        default:
            return state
    }
}

export default list
