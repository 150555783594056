import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./list.css"

const List = props => {
    const { cx, isVisible, children } = props

    return isVisible && <ul className={cx("root")}>{children}</ul>
}

List.propTypes = {
    isVisible: PropTypes.bool
}

export default withStyles(List, styles)
