import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./styles.css"
import close from "assets/close.svg"
import SearchBar from "components/Reusable/SearchBar"
import ClientsListWithButton from "components/Reusable/ClientsListWithButton"

class NewSessionClients extends Component {
    render() {
        const {
            handleHide,
            activeClient,
            handleWorkWith,
            handleSearch,
            clearInput,
            filter,
            clients,
            isLoaded
        } = this.props
        return (
            <div className="modal-background">
                <div styleName="modal-cover">
                    <img src={close} alt="close" styleName="close-icon" onClick={handleHide} />

                    <div styleName="container">
                        <div styleName="search-bar">
                            <SearchBar
                                placeholder="Search Contact"
                                handleSearch={handleSearch}
                                clearInput={clearInput}
                                filter={filter}
                            />
                        </div>

                        <div styleName="clients-list">
                            <ClientsListWithButton
                                clients={clients}
                                handleWorkWith={handleWorkWith}
                                isLoaded={isLoaded}
                            />
                        </div>
                    </div>
                    <form>
                        <button
                            className="first-button"
                            styleName="fill-in-later"
                            type="submit"
                            onClick={event => {
                                event.preventDefault()
                                handleWorkWith()
                            }}
                        >
                            {activeClient.isTemporary ? "Create New" : "Fill In Later"}
                        </button>
                    </form>
                </div>
            </div>
        )
    }
}

export default CSSModules(NewSessionClients, style)
