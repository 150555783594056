import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"

import { Image } from "@butterfly-frontend/ui"
import { ColorTile, Zoom } from "ui/ColorPicker/components"
import { COMBINATION_PROP_TYPE } from "propTypes"

import styles from "./ProductCombinationInfo.module.css"
import colorTileStyles from "./overrides/ColorTile.module.css"

const ProductCombinationInfo = ({ productImageSrc, combination }) => {
    const [zoomValues, setZoomValues] = useState({
        isZoomed: false,
        background: {},
        name: ""
    })

    const handleColorHover = event => {
        setZoomValues({
            isZoomed: true,
            background: event.background,
            name: event.name
        })
    }

    const handleColorMouseOut = () => {
        setZoomValues({ ...zoomValues, isZoomed: false })
    }

    return (
        <Fragment>
            <Image src={productImageSrc} classes={{ root: styles.image }} />
            <div className={styles.combinationDescription}>
                {combination.attribute_values &&
                    combination.attribute_values.map(attribute => (
                        <Fragment key={attribute.id}>
                            <span className={styles.combinationLabel}>{attribute.attribute_name}:</span>
                            <span className={styles.combinationValue}>
                                {(attribute.color || attribute.texture) && (
                                    <ColorTile
                                        small
                                        handleHover={handleColorHover}
                                        handleMouseOut={handleColorMouseOut}
                                        handleSelectTile={() => {}}
                                        key={attribute.id}
                                        id={attribute.id}
                                        name={attribute.value}
                                        color={attribute.color}
                                        texture={attribute.texture_preview}
                                        customStyles={colorTileStyles}
                                    />
                                )}
                                {attribute.value}
                            </span>
                        </Fragment>
                    ))}
                {zoomValues.isZoomed && <Zoom zoomDirection="left" zoomValues={zoomValues} />}
            </div>
        </Fragment>
    )
}

ProductCombinationInfo.propTypes = {
    productImageSrc: PropTypes.string,
    combination: COMBINATION_PROP_TYPE.isRequired
}

export default ProductCombinationInfo
