import { DEFAULT_CURRENCY } from "constants/index"

// eslint-disable-next-line
const config = [
    {
        name: "name",
        label: "Name",
        apiPath: "name",
        errorName: "name",
        takeType: "TEXT",
        type: "text",
        params: {
            attributes: {
                required: true
            }
        }
    },
    {
        name: "price",
        label: "Price",
        apiPath: "price",
        errorName: "price",
        takeType: "TEXT",
        type: "text",
        params: {
            innerLabel: DEFAULT_CURRENCY,
            isInnerLabelLeft: true,
            attributes: {
                required: true
            }
        }
    },
    {
        name: "height",
        label: "Height",
        apiPath: "height",
        errorName: "height",
        takeType: "TEXT",
        type: "text",
        params: {
            innerLabel: "cm"
        }
    },
    {
        name: "width",
        label: "Width",
        apiPath: "width",
        errorName: "width",
        takeType: "TEXT",
        type: "text",
        params: {
            innerLabel: "cm"
        }
    },
    {
        name: "depth",
        label: "Depth",
        apiPath: "depth",
        errorName: "depth",
        takeType: "TEXT",
        type: "text",
        params: {
            innerLabel: "cm"
        }
    },

    {
        name: "description",
        label: "Description:",
        apiPath: "description",
        errorName: "description",
        takeType: "TEXT",
        type: "textarea",
        params: {
            isPlaceholder: false
        }
    },
    {
        name: "brand",
        label: "Brand",
        apiPath: "brand.id",
        type: "select"
    },
    {
        name: "img",
        label: "Photos",
        apiPath: "media",
        type: "img"
    }
]

export default config
