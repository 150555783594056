import * as constants from "../../constants"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: {},
    meta: {},
    fetchStatus: fetchStatus.initialState()
}

function products(state = initialState, action) {
    switch (action.type) {
        case constants.FETCH_PRODUCTS_REQUEST:
            return {
                ...state,
                fetchStatus: fetchStatus.requestState()
            }

        case constants.FETCH_PRODUCTS_SUCCESS:
            return {
                data: action.payload.data.data,
                meta: action.payload.data.meta,
                fetchStatus: fetchStatus.successState()
            }

        case constants.FETCH_PRODUCTS_FAILURE:
            return {
                data: {},
                meta: {},
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }

        case constants.RESET_PRODUCTS:
        case constants.MOVE_PRODUCTS:
        case constants.DELETE_PRODUCTS:
        case "selections/ADD_PRODUCT_TO_SELECTION_SUCCESS":
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default products
