import React, { Component, Fragment } from "react"
import withStyles from "HOC/withStyles"
import withPermissions from "HOC/withPermissions"

import { Search } from "./components"

import styles from "./CalendarHeader.css"

import menu_list from "assets/ico-menu-list.svg"
import arrow from "assets/ico-arrow.svg"
import search from "assets/search.svg"
import Filters from "../Filters"

class CalendarHeader extends Component {
    render() {
        const {
            cx,
            handlePrev,
            handleToDay,
            handleNext,
            handleMonth,
            handleWeek,
            handleDay,
            handleQuerySearch,
            handleStartSearch,
            handleEndSearch,
            handleChangeFilters,
            toggleCalendarList,
            currentView,
            initFilters,
            isSearching,
            querySearch
        } = this.props

        return (
            <div className={cx("calendar-header")}>
                <div className={cx("calendar-header--left")}>
                    <button className={cx("btn-sidebar-left")} onClick={toggleCalendarList}>
                        <img src={menu_list} alt="" />
                    </button>
                    {!isSearching && (
                        <Fragment>
                            <button onClick={handlePrev} className={cx("prev")}>
                                <img src={arrow} alt="" />
                            </button>
                            <button onClick={handleToDay} className={cx("toDay")}>
                                Today
                            </button>
                            <button onClick={handleNext} className={cx("next")}>
                                <img src={arrow} alt="" />
                            </button>
                        </Fragment>
                    )}
                </div>
                {isSearching ? (
                    <div className={cx("calendar-header--center")}>
                        <Search handleSearch={handleQuerySearch} handleClose={handleEndSearch} query={querySearch} />
                    </div>
                ) : (
                    <div className={cx("calendar-header--center")}>
                        <button onClick={handleMonth} className={cx({ active: currentView === "dayGridMonth" })}>
                            Month
                        </button>
                        <button onClick={handleWeek} className={cx({ active: currentView === "timeGridWeek" })}>
                            Week
                        </button>
                        <button onClick={handleDay} className={cx({ active: currentView === "timeGridDay" })}>
                            Day
                        </button>
                    </div>
                )}
                <div className={cx("calendar-header--right")}>
                    {!isSearching && (
                        <button className={cx("search")} onClick={handleStartSearch}>
                            <img src={search} alt="" />
                        </button>
                    )}
                    <Filters handleChange={handleChangeFilters} initFilters={initFilters} />
                </div>
            </div>
        )
    }
}

const ComponentWithStyles = withStyles(CalendarHeader, styles)
export default withPermissions(ComponentWithStyles)
