import React, { useEffect, useState, Fragment } from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"
import { useDispatch } from "react-redux"

import fetch from "helpers/fetch"
import getCxFromStyles from "helpers/getCxFromStyles"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { Modal, Button } from "ui"
import { SnakeLoader } from "ui/Skeleton"

import { DragAndDropList } from "modules/ContentModule/components"
import LayoutSelect from "./LayoutSelect"

import styles from "./styles.css"
import headerModalStyles from "./overrides/HeaderModal.css"
import modalStyles from "./overrides/Modal.css"
import submitButtonStyles from "./overrides/SubmitButton.css"
import snakeLoaderStyles from "./overrides/SnakeLoader.css"
import dragAndDropListStyles from "./overrides/DragAndDropList.css"
import deleteIcon from "assets/delete.svg"
import addIcon from "assets/add-no-padding.svg"

const SETTINGS = {
    inspiration: {
        id: 1,
        key: "inspirations"
    },
    news: {
        id: 2,
        key: "posts"
    },
    trade: {
        id: 3,
        key: "trades"
    }
}

const ChangeLayoutInspirationsModal = props => {
    const { isOpen, handleCloseModal, collectionLabel, collectionKey } = props
    const cx = getCxFromStyles(styles)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [list, setList] = useState([""])
    const dispatch = useDispatch()

    useEffect(() => {
        fetch
            .get(`/settings/${SETTINGS[collectionKey].id}`)
            .then(({ data }) => {
                setList(data.value || [""])
                setIsLoading(false)
            })
            .catch(err => {
                handleCloseModal()
                dispatch(showErrorNotification(err.message))
            })
    }, [])

    const handleDelete = indexToDelete => setList(list.filter((_, index) => index !== indexToDelete))
    const handleAdd = () => setList([...list, ""])
    const handleChange = (indexToChange, newValue) =>
        setList(list.map((value, index) => (index === indexToChange ? newValue : value)))

    const handleSubmit = () => {
        setIsSubmitting(true)

        const body = {
            key: SETTINGS[collectionKey].key + "_masonry_grid",
            value: list.filter(value => !!value)
        }

        fetch
            .patchRAW(`/settings/${SETTINGS[collectionKey].id}`, body)
            .then(() => {
                handleCloseModal()
                dispatch(showSuccessNotification({ title: "Saved " }))
            })
            .catch(err => {
                setIsSubmitting(false)
                dispatch(showErrorNotification(err.message))
            })
    }

    return (
        <Modal isOpen={isOpen} closeModal={handleCloseModal} preventClickOutside customStyles={modalStyles}>
            <Modal.Header customStyles={headerModalStyles}>
                <strong>Change layout</strong> for {collectionLabel}
            </Modal.Header>
            <Modal.Body>
                {isLoading ? (
                    <SnakeLoader customStyles={snakeLoaderStyles} />
                ) : (
                    <div className={cx("list")}>
                        {!!list.length ? (
                            <Fragment>
                                <ul className={cx("header")}>
                                    <li className={cx("rowNumber")}>ROW NO.</li>
                                    <li>LAYOUT</li>
                                </ul>
                                <ul className={cx("content")}>
                                    <DragAndDropList
                                        itemsList={list}
                                        handleReorder={setList}
                                        withBorder={false}
                                        customStyles={dragAndDropListStyles}
                                    >
                                        {list.map((item, index) => (
                                            <div className={cx("item")} key={index}>
                                                <span className={cx("name")}>Row {index + 1}</span>
                                                <div className={cx("select")}>
                                                    <LayoutSelect
                                                        value={list[index]}
                                                        onChange={value => handleChange(index, value)}
                                                    />
                                                </div>
                                                <ReactSVG
                                                    src={deleteIcon}
                                                    className={cx("delete")}
                                                    onClick={() => handleDelete(index)}
                                                />
                                            </div>
                                        ))}
                                    </DragAndDropList>
                                </ul>
                            </Fragment>
                        ) : (
                            <p className={cx("message")}>Empty list.</p>
                        )}
                        <button className={cx("addButton")} onClick={handleAdd}>
                            <ReactSVG src={addIcon} className={cx("addIcon")} />
                            Add Row
                        </button>
                    </div>
                )}
            </Modal.Body>
            <Modal.Actions>
                <button className={cx("cancelButton")} onClick={handleCloseModal}>
                    Cancel
                </button>
                <Button
                    className="first-button"
                    label="Save"
                    isLoading={isSubmitting}
                    isDisabled={isLoading}
                    onClick={handleSubmit}
                    customStyles={submitButtonStyles}
                />
            </Modal.Actions>
        </Modal>
    )
}

ChangeLayoutInspirationsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleCloseModal: PropTypes.func.isRequired,
    collectionKey: PropTypes.string.isRequired,
    collectionLabel: PropTypes.string.isRequired
}

export default ChangeLayoutInspirationsModal
