import React, { useState, useEffect } from "react"

import withStyles from "HOC/withStyles"

import Modal from "ui/Modal"
import Dropzone from "modules/NewsModule/components/Dropzone/Dropzone"
import Button from "ui/Button"

import styles from "./UploadImageModal.css"

const IMAGES_DIMENSIONS = {
    cover: {
        height: 750
    },
    hover: {
        height: 750
    },
    stock: {
        height: 300
    }
}

const Uploader = ({ cx, handleUploadSuccess, image, handleRemoveFile, type, setIsLoading, label, imageDimensions }) => {
    const previewData = {
        name: image.title,
        url: image.url
    }
    const imageData = {
        ...image,
        value: image.url
    }

    return (
        <div className={cx("dropzoneWrapper")}>
            <span className={cx("label")}>{label}:</span>
            <Dropzone
                isMultiple={false}
                activeClassName={cx("canDrop")}
                acceptFormats={"image/jpeg,image/png"}
                imageData={imageData}
                previewData={previewData}
                handleUploadSuccess={data => handleUploadSuccess(data, type)}
                handleRemoveFile={handleRemoveFile}
                isCoverWithImageDimensions
                imageDimensions={imageDimensions}
                customLabel={"Add image"}
                setIsLoading={setIsLoading}
            />
        </div>
    )
}

export const UploadImageModal = props => {
    const { cx, isOpen, isMainCategory, category, handleClose, handleSubmit, isSaving } = props
    const { id, name, active } = category
    const headerTitle = isMainCategory ? "Main Category" : "Subcategory"
    const [coverImage, setCoverImage] = useState({})
    const [hoverImage, setHoverImage] = useState({})
    const [stockImage, setStockImage] = useState({})
    const [isDisabled, setIsDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const handleUploadSuccess = (data, type) => {
        setIsDisabled(false)
        const setImage = type === "cover" ? setCoverImage : type === "hover" ? setHoverImage : setStockImage
        setImage(data.addedFile)
    }

    const handleRemoveFile = (event, type) => {
        event.stopPropagation()
        setIsDisabled(false)
        const setImage = type === "cover" ? setCoverImage : type === "hover" ? setHoverImage : setStockImage
        setImage({})
    }

    useEffect(() => {
        if (category.cover_image) {
            setCoverImage({
                url: category.cover_image,
                title: category.cover_image.split("/").slice(-1)
            })
        }
        if (category.hover_image) {
            setHoverImage({
                url: category.hover_image,
                title: category.hover_image.split("/").slice(-1)
            })
        }
        if (category.stock_image) {
            setStockImage({
                url: category.stock_image,
                title: category.stock_image.split("/").slice(-1)
            })
        }
    }, [category])

    return (
        <Modal isOpen={isOpen} closeModal={handleClose}>
            <h2 className={cx("header")}>
                {headerTitle}:<span>{name}</span>
            </h2>

            <div className={cx("images")}>
                <Uploader
                    cx={cx}
                    handleUploadSuccess={handleUploadSuccess}
                    image={coverImage}
                    type="cover"
                    label="Image as cover"
                    handleRemoveFile={event => handleRemoveFile(event, "cover")}
                    setIsLoading={setIsLoading}
                    imageDimensions={IMAGES_DIMENSIONS.cover}
                />

                <Uploader
                    cx={cx}
                    handleUploadSuccess={handleUploadSuccess}
                    image={hoverImage}
                    type="hover"
                    label="Image as hover"
                    handleRemoveFile={event => handleRemoveFile(event, "hover")}
                    setIsLoading={setIsLoading}
                    imageDimensions={IMAGES_DIMENSIONS.hover}
                />

                <Uploader
                    cx={cx}
                    handleUploadSuccess={handleUploadSuccess}
                    image={stockImage}
                    type="stock"
                    label="Image in stock"
                    handleRemoveFile={event => handleRemoveFile(event, "stock")}
                    setIsLoading={setIsLoading}
                    imageDimensions={IMAGES_DIMENSIONS.stock}
                />
            </div>

            <div className={cx("actions")}>
                <span className={cx("form-cancel")} onClick={() => handleClose()}>
                    Cancel
                </span>

                <Button
                    className={["first-button"]}
                    label="Save"
                    isLoading={isSaving}
                    isDisabled={isDisabled || isLoading}
                    onClick={() => handleSubmit({ id, active, coverImage, hoverImage, stockImage })}
                />
            </div>
        </Modal>
    )
}

export default withStyles(UploadImageModal, styles)
