import React, { useState } from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import { Product } from "modules/UploadModule/components"

import FilterableTable, { Table, Sort } from "ui/FilterableTable"
import InfiniteScroll from "ui/InfiniteScroll"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import styles from "./styles.module.css"

const uploadProductsFields = [
    { label: "name", value: "name" },
    { label: "category", value: "main_category.name" },
    { label: "brand", value: "brand.name" },
    { label: "date", value: "created_at" },
    { label: "photos", value: "images_count" }
]

const ProductsList = ({
    cx,
    currentlySelectedImages,
    currentFolderId,
    products,
    transferFromProduct,
    handleChangeTable,
    triggerImageSelect,
    defaultSorting,
    showErrorNotification,
    prepareImageToSendFromProduct,
    changeNumberOfImagesInProduct,
    saveTransferedImagesLocally,
    transferedImages,
    loadedOnce
}) => {
    const [productIdWithShownImagePanel, handleImagePanel] = useState(null)
    const [filterParams, handleSetFilterParams] = useState(null)

    const renderTableHeader = () => (
        <Table.Head>
            <Table.Tr>
                {uploadProductsFields.map(field => (
                    <Table.Th key={field.value}>
                        <Sort
                            sortBy={field.value}
                            sortDirection={defaultSorting.direction}
                            isDefault={field.value === defaultSorting.by}
                        >
                            {field.label}
                        </Sort>
                    </Table.Th>
                ))}
            </Table.Tr>
        </Table.Head>
    )

    const renderTableBody = () => {
        return (
            <Table.Body>
                {products.data &&
                    products.data.map((product, index) => {
                        return (
                            <Product
                                currentFolderId={currentFolderId}
                                saveTransferedImagesLocally={saveTransferedImagesLocally}
                                transferFromProduct={transferFromProduct}
                                triggerImageSelect={triggerImageSelect}
                                changeNumberOfImagesInProduct={changeNumberOfImagesInProduct}
                                showErrorNotification={showErrorNotification}
                                currentlySelectedImages={currentlySelectedImages}
                                prepareImageToSendFromProduct={prepareImageToSendFromProduct}
                                isImagePanelShown={productIdWithShownImagePanel === product.id}
                                handleImagePanel={handleImagePanel}
                                key={index}
                                transferedImages={transferedImages}
                                {...product}
                                isOver={true}
                            />
                        )
                    })}
            </Table.Body>
        )
    }

    const handleLoadMore = () => {
        const {
            meta: { to, current_page }
        } = products

        if (current_page < to) {
            handleChangeTable({
                ...filterParams,
                page: current_page + 1
            })
        }
    }

    const handleSetTableParams = params => {
        handleChangeTable(params)
        return handleSetFilterParams(params)
    }

    return (
        <div className={cx("root")}>
            <FilterableTable
                fetchStatus={{
                    isLoading: !loadedOnce,
                    isLoaded: loadedOnce,
                    isError: products.isError
                }}
                items={products.data}
                meta={products.meta}
                handleChange={handleSetTableParams}
            >
                <InfiniteScroll
                    loader={
                        <div className={cx("loader")} key={0}>
                            <span className={cx("insider")}>
                                <SnakeLoader />
                            </span>
                        </div>
                    }
                    threshold={50}
                    useWindow={true}
                    loadMore={handleLoadMore}
                    hasMore={products.meta && products.meta.current_page < products.meta.to}
                >
                    <Table className={cx("root")} renderHeader={renderTableHeader} renderBody={renderTableBody} />
                </InfiniteScroll>
            </FilterableTable>
        </div>
    )
}

ProductsList.propTypes = {
    products: PropTypes.shape({
        data: PropTypes.array,
        isError: PropTypes.bool,
        isLoaded: PropTypes.bool,
        isLoading: PropTypes.bool,
        meta: PropTypes.object
    }),
    handleChangeTable: PropTypes.func.isRequired,
    defaultSorting: PropTypes.shape({
        by: PropTypes.string,
        direction: PropTypes.string
    }).isRequired
}

export default withStyles(ProductsList, styles)
