import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: {},
    fetchStatus: fetchStatus.initialState()
}

function current(state = initialState, action) {
    switch (action.type) {
        case constants.GET_BRAND_BY_ID_START:
            return {
                ...state,
                fetchStatus: fetchStatus.requestState()
            }
        case constants.GET_BRAND_BY_ID_SUCCESS:
            return {
                ...state,
                data: action.payload.data.data,
                fetchStatus: fetchStatus.successState()
            }
        case constants.GET_BRAND_BY_ID_ERROR:
            return {
                ...state,
                data: {},
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }
        case constants.RESET_CURRENT_BRAND:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default current
