import { useState, useCallback, useEffect } from "react"

const useProductsState = ({ fetchStatuses }) => {
    const [itemsState, setItemsState] = useState({})

    const handleChangeItemsState = useCallback((id, value, key) => {
        setItemsState(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                [key]: value
            }
        }))
    }, [])

    const handleChangeQuantity = useCallback((id, value) => handleChangeItemsState(id, value, "quantity"), [
        handleChangeItemsState
    ])
    const handleChangeIsLoading = useCallback((id, value) => handleChangeItemsState(id, value, "isLoading"), [
        handleChangeItemsState
    ])

    useEffect(() => {
        const nextItemsState = Object.keys(itemsState).reduce((acc, id) => {
            if (fetchStatuses[id]) {
                return {
                    ...acc,
                    [id]: {
                        ...itemsState[id],
                        isLoading: fetchStatuses[id].isLoading
                    }
                }
            }

            return acc
        }, {})

        setItemsState(nextItemsState)
    }, [fetchStatuses])

    return { itemsState, setItemsState, handleChangeQuantity, handleChangeIsLoading }
}

export default useProductsState
