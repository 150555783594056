import React from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"

import { SelectWithSearch } from "ui"
import ColorPicker from "ui/ColorPicker"
import getStyles from "helpers/getCxFromStyles"
import { AVAILABLE_ATTRIBUTES_VALUES_PROP_TYPE } from "propTypes/combinationPropType"

import styles from "./CombinationAttributeSelect.module.css"
import ColorPickerStyles from "./overrides/ColorPicker.module.css"

const CombinationAttributeSelect = props => {
    const cx = getStyles(styles)

    const {
        name,
        selectAttribute,
        selectedAttributeValue,
        availableAttributeValues,
        zoomDirection,
        type,
        id,
        isDisabled
    } = props

    const selectedAttributeId = _get(selectedAttributeValue, "id")
    const selectedAttributeName = _get(selectedAttributeValue, "name", "")

    return type === "color" ? (
        <div>
            {name && <label className={cx("label", { disabled: isDisabled })} htmlFor={name}>{`${name}:`}</label>}
            <ColorPicker
                zoomDirection={zoomDirection}
                customStyles={ColorPickerStyles}
                tiles={availableAttributeValues.map(el => ({
                    ...el,
                    id: el.id,
                    name: el.value,
                    disabled: false
                }))}
                handleSelectTile={value => selectAttribute(id, value.id === selectedAttributeId ? {} : value)}
                handleButton={() => selectAttribute(id, {})}
                selectedTile={selectedAttributeId}
                isDisabled={isDisabled}
            />
        </div>
    ) : (
        <SelectWithSearch
            label={name || ""}
            value={selectedAttributeName}
            values={availableAttributeValues.map(el => ({
                value: { id: el.id, name: el.value },
                label: el.value
            }))}
            setValue={({ value }) => selectAttribute(id, value)}
            withoutFetch
            isDisabled={isDisabled}
        />
    )
}

CombinationAttributeSelect.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    selectAttribute: PropTypes.func.isRequired,
    selectedAttributeValue: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }),
    availableAttributeValues: AVAILABLE_ATTRIBUTES_VALUES_PROP_TYPE.isRequired,
    zoomDirection: PropTypes.oneOf(["left", "right"]),
    isDisabled: PropTypes.bool
}

export default CombinationAttributeSelect
