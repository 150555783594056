import React, { Component } from "react"
import { connect } from "react-redux"
import models from "models"

import * as notificationActions from "actions/notification"

import Form from "./Form"
import FormAbstractContainer from "../../FormContainer"

class FormContainer extends Component {
    render() {
        return <Form {...this.props} />
    }
}

const mapStateToProps = null

const mapDispatchToProps = dispatch => {
    return {
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    FormAbstractContainer(FormContainer, {
        mapStateToProps,
        mapDispatchToProps,
        idParamName: "combinationTemplateId",
        model: models.CombinationTemplate
    })
)
