import React, { useState, useRef, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import moment from "moment"

import useStyles from "helpers/getCxFromStyles"

import styles from "./TimePicker.css"

const TimePicker = props => {
    const { disabled, value, isListOnTop, isListOnRight, onChange } = props

    const cx = useStyles(styles)
    const timeoutId = useRef(null)

    const [isOpen, setIsOpen] = useState(false)
    const [inputValue, setInputValue] = useState(null)

    const closeOptions = () => setIsOpen(false)

    const onFocus = () => clearTimeout(timeoutId.current)
    const onBlur = () => (timeoutId.current = setTimeout(closeOptions))
    const onClickOption = option => {
        onChange(option)
        closeOptions()
    }

    const onManualChange = event => {
        setInputValue(event.target.value)
        setIsOpen(false)
    }

    const applyTime = event => {
        event.preventDefault()
        const manualChangedTime = moment(event.target.value, "h:mm a")

        if (!manualChangedTime.isValid()) {
            return setInputValue(value)
        }

        const displayedTime = manualChangedTime.format("h:mm a")
        onChange(displayedTime)
        setInputValue(displayedTime)
    }

    useEffect(() => {
        setInputValue(value)
    }, [value])

    const options = useMemo(() => {
        const time = moment().set({ hour: 0, minute: 0 })
        const now = moment()

        const list = []
        while (time.format("d") === now.format("d")) {
            list.push(time.format("h:mm a"))
            time.add(30, "minutes")
        }

        return list
    }, [])

    return (
        <div className={cx("root", { isOpen, isDisabled: disabled })} onFocus={onFocus} onBlur={onBlur}>
            <div className={cx("timepicker-placeholder")}>
                <input
                    className={cx("input")}
                    value={inputValue || ""}
                    onChange={onManualChange}
                    onBlur={applyTime}
                    onKeyDown={event => event.key === "Enter" && applyTime(event)}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </div>

            {!disabled && isOpen && (
                <ul className={cx("options", { isListOnTop, isListOnRight })}>
                    {options.map((option, index) => (
                        <li key={index} className={cx("option")}>
                            <button className={cx("button")} onClick={() => onClickOption(option)}>
                                {option}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

TimePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isListOnTop: PropTypes.bool,
    isListOnRight: PropTypes.bool,
    value: PropTypes.string
}

export default TimePicker
