import { useMemo, useState, useCallback, useEffect } from "react"
import xor from "lodash/xor"

const mapAttributes = attributes => {
    return attributes.map(({ id, name, type, attribute_values }) => ({
        id,
        name,
        type,
        values: attribute_values.filter(({ color, texture }) => {
            switch (type) {
                case "color":
                    return !!color || !!texture
                case "dropdown":
                    return true
                default:
                    return true
            }
        })
    }))
}

const flatToTree = (combinationPatterns, currentLevel = 0) => {
    return combinationPatterns
        .map((item, index) => ({ ...item, index }))
        .filter(({ active, level }) => active && level === currentLevel)
        .reduce((acc, { attribute_value_id, level, impact, media_id, index }) => {
            const restOfArray = combinationPatterns.slice(index + 1)
            const distanceToSameLevel =
                restOfArray.findIndex(item => item.level === level) + 1 || combinationPatterns.length
            const arrayWithNestedLevels = restOfArray.slice(0, distanceToSameLevel)

            return {
                ...acc,
                [attribute_value_id]: {
                    id: attribute_value_id,
                    impact,
                    media_id,
                    children: flatToTree(arrayWithNestedLevels, currentLevel + 1)
                }
            }
        }, {})
}

const computeBlockedAttributeValueIds = (selectedAttributes, attributes, combinationTree) => {
    const attributeValueIds = selectedAttributes.map(([, value]) => value)
    const firstIndexOfUndefined = attributeValueIds.indexOf(undefined)
    const pickedAttributes =
        firstIndexOfUndefined === -1 ? selectedAttributes : selectedAttributes.slice(0, firstIndexOfUndefined + 1)

    const initialAccumulator = {
        blockedIds: {},
        currentTree: combinationTree
    }

    const { blockedIds } = pickedAttributes.reduce(({ blockedIds, currentTree }, [attributeId, attributeValueId]) => {
        const nextLeaf =
            attributeValueId && currentTree[attributeValueId] ? currentTree[attributeValueId] : { children: [] }
        const allAttributeValueIds = attributes[attributeId].values.map(({ id }) => id)
        const availableAttributeValueIds = Object.values(currentTree).map(({ id }) => id)
        const blockedAttributeValueIds = xor(allAttributeValueIds, availableAttributeValueIds)
        const currentLeafBlockedIds = blockedAttributeValueIds.reduce(
            (acc, id) => (isNaN(id) ? acc : { ...acc, [id]: true }),
            {}
        )
        return {
            blockedIds: { ...blockedIds, ...currentLeafBlockedIds },
            currentTree: nextLeaf.children
        }
    }, initialAccumulator)

    return blockedIds
}

const mapAttributeValueToValueOfSelect = value => {
    if (value.type === "color") {
        return {
            ...value,
            id: value.id,
            name: value.value,
            disabled: false
        }
    }

    return { id: value.id, name: value.value }
}

const getInitialSelectedAttributes = (attributes, initialSelectedAttributeValueIds = []) =>
    Object.values(attributes).map(attribute => {
        const foundAttributeValue = attribute.attribute_values.find(value =>
            initialSelectedAttributeValueIds.includes(value.id)
        )

        return {
            id: attribute.id,
            value: foundAttributeValue ? mapAttributeValueToValueOfSelect(foundAttributeValue) : {}
        }
    })

const useSelectCombination = ({
    combinationPatterns = [],
    attributes = [],
    initialSelectedAttributeValueIds = [],
    enabled = true
}) => {
    const [selectedAttributes, setSelectedAttributes] = useState([])

    useEffect(() => {
        if (enabled) {
            setSelectedAttributes(getInitialSelectedAttributes(attributes, initialSelectedAttributeValueIds))
        }
    }, [enabled])

    const blockedAttributeValueIds = useMemo(() => {
        const combinationTree = flatToTree(
            combinationPatterns.map(item => ({
                active: item.payload.active,
                attribute_value_id: item.attribute_value_id,
                impact: item.payload.impact,
                level: item.level,
                media_id: item.payload.media_id
            }))
        )
        const mappedAttributes = mapAttributes(attributes).reduce(
            (acc, attribute) => ({ ...acc, [attribute.id]: attribute }),
            {}
        )
        const mappedSelectedAttributes = selectedAttributes.map(selectedAttribute => [
            selectedAttribute.id,
            selectedAttribute.value.id
        ])

        return Object.keys(
            computeBlockedAttributeValueIds(mappedSelectedAttributes, mappedAttributes, combinationTree)
        ).map(id => parseInt(id, 10))
    }, [selectedAttributes, attributes, combinationPatterns])

    const selectAttribute = useCallback((attributeId, value) => {
        let wasFoundChangedAttribute = false

        setSelectedAttributes(currentSelectedAttributes =>
            currentSelectedAttributes.reduce((agg, current) => {
                if (current.id === attributeId) {
                    wasFoundChangedAttribute = true
                    return [...agg, { id: attributeId, value }]
                }

                if (wasFoundChangedAttribute) {
                    return [...agg, { ...current, value: {} }]
                }

                return [...agg, current]
            }, [])
        )
    }, [])

    const attributesForComponent = useMemo(() => {
        if (attributes.length !== selectedAttributes.length) {
            return []
        }

        return Object.values(attributes).map(({ id, name, type, attribute_values }, index) => {
            const selectedAttributeValue = selectedAttributes.find(value => value.id === id).value
            const isDisabled = index > 0 && !selectedAttributes[index - 1].value.id

            return {
                id,
                name,
                type,
                selectedAttributeValue,
                attributeValues: attribute_values,
                availableAttributeValues: attribute_values.filter(
                    el => !blockedAttributeValueIds.some(blockedAttributeValueId => blockedAttributeValueId === el.id)
                ),
                isDisabled
            }
        })
    }, [attributes, selectedAttributes, blockedAttributeValueIds, enabled])

    return {
        blockedAttributeValueIds,
        selectedAttributes,
        setSelectedAttributes,
        selectAttribute,
        attributesForComponent
    }
}

export default useSelectCombination
