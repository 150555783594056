import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import style from "./Confirm.css"
import close from "assets/close.svg"

class Confirm extends Component {
    static defaultProps = {
        confirmModalTitle: "Are you sure you want to delete this client?",
        firstName: "",
        lastName: "",
        actionButtonLabel: "Delete",
        isLoading: false
    }

    render() {
        const {
            cx,
            handleHideModal,
            handleDelete,
            confirmModalTitle,
            titleSecondLine,
            titleThirdLine,
            firstName,
            lastName,
            id,
            isLoading,
            actionButtonLabel,
            disabled
        } = this.props

        return (
            <div className="modal-background">
                <div className={cx("modal-cover")}>
                    <img src={close} alt="close" className={cx("close-icon")} onClick={handleHideModal} />
                    <h2 className={cx("modal-title")}>{confirmModalTitle}</h2>
                    {titleSecondLine && <h2 className={cx("modal-title-2")}>{titleSecondLine}</h2>}
                    {titleThirdLine && <h2 className={cx("modal-title-3")}>{titleThirdLine}</h2>}
                    {(firstName || lastName) && (
                        <h2 className={cx("client-name")}>
                            {firstName} {lastName}
                        </h2>
                    )}
                    <div className={cx("buttons")}>
                        <button
                            disabled={disabled}
                            className={cx("first-button", "first-button-override", { isLoading })}
                            onClick={() => {
                                if (isLoading) {
                                    return
                                }

                                handleDelete && handleDelete(id)
                            }}
                        >
                            <span>{actionButtonLabel}</span>
                        </button>
                        <button className={cx("go-back-button")} onClick={handleHideModal}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(Confirm, style)
