const STOCK_ITEM_COUNTER_KEYS = {
    AVAILABLE: "available",
    COMMITTED: "committed",
    IN_STOCK: "in_stock",
    INCOMING: "incoming",
    NOT_FOR_SALE: "not_for_sale",
    ON_HOLD: "on_hold",
    SOLD: "sold"
}

export default STOCK_ITEM_COUNTER_KEYS
