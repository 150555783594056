import React, { Component } from "react"

import { withStyles } from "ui/FilterableTable/HOC"
import styles from "./SnakeLoader.css"

class SnakeLoader extends Component {
    render() {
        const { type, cx } = this.props
        return <div className={cx("spinner", type)} />
    }
}

export default withStyles(styles)(SnakeLoader)
