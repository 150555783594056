import React from "react"
import { useParams } from "react-router-dom"

import CollectionForm from "modules/ContentModule/components/CollectionForm"

const SIDEBAR_IMAGES_DIMENSIONS = {
    thumbnail_horizontal: {
        width: 1440,
        height: 490
    },
    thumbnail_vertical: {
        width: 736,
        height: 1086
    }
}

const Form = () => {
    const { tradeId } = useParams()

    return (
        <CollectionForm
            collectionLabel="Trade"
            sectionableType="trade"
            collectionId={Number(tradeId)}
            basePath="/content/trades"
            apiUrl="/trades"
            sidebarImagesDimensions={SIDEBAR_IMAGES_DIMENSIONS}
        />
    )
}

export default Form
