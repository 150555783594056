import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./DeleteEventModal.css"

import Button from "components/Reusable/Form/Button"

import close from "assets/close.svg"
import Modal from "components/Modal/Modal"

class DeleteEventModal extends Component {
    state = {
        isDeleting: false
    }

    handleDelete = () => {
        const { handleHideModal, entry, handleDelete, showErrorNotification } = this.props
        const entryId = entry.event.extendedProps.entry.id

        this.setState({
            isDeleting: true
        })

        handleDelete(entryId)
            .finally(() => {
                this.setState({
                    isDeleting: false
                })
            })
            .then(
                () => handleHideModal({ success: true }),
                data => {
                    if (data && data.payload && data.payload.error) {
                        showErrorNotification(data.payload.error.message)
                    } else {
                        handleHideModal({ success: true })
                    }
                }
            )
    }

    render() {
        const { cx, handleHideModal, title, entry } = this.props

        return (
            <Modal>
                <div className={cx("modal-background")}>
                    <div className={cx("modal-cover")}>
                        <img
                            alt="close"
                            className={cx("close-icon")}
                            onClick={() => {
                                handleHideModal()
                            }}
                            src={close}
                        />

                        <p className={cx("title")}>{title}</p>
                        <p>
                            Are you sure you want to delete the event{" "}
                            <span>{entry.event.extendedProps.entry.title}</span>? This action cannot be reversed.
                        </p>

                        <div className={cx("separator")} />

                        <div className={cx("footer")}>
                            <div>
                                <span
                                    className={cx(["button-link"])}
                                    onClick={() => {
                                        handleHideModal()
                                    }}
                                >
                                    Cancel
                                </span>
                                <Button
                                    type="button"
                                    isDisabled={this.state.isDeleting}
                                    isLoading={this.state.isDeleting}
                                    className={cx(["danger-button", "button"])}
                                    label={"Delete"}
                                    onClick={this.handleDelete}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default withStyles(DeleteEventModal, styles)
