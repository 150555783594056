import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./actions.css"

const Actions = props => {
    const { cx, className, children, withLine } = props

    return <div className={cx("root", className, { withLine })}>{children}</div>
}

Actions.defaultProps = {
    className: ""
}

Actions.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
    withLine: PropTypes.bool
}

export default withStyles(Actions, styles)
