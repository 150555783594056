import * as Yup from "yup"

import { selectOptionSchemaFactory, requiredFieldFactory } from "modules/WmsModule/helpers/schema"
import { REQUIRED_MESSAGE } from "modules/WmsModule/constants/validationMessages"
import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import {
    DESCRIPTION_SCHEMA,
    IMAGES_SCHEMA,
    REQUIRED_IMAGES_SCHEMA,
    COMBINATION_ATTRIBUTES_SCHEMA
} from "modules/WmsModule/constants/schema"

const inEditRequiredSelectFieldFactory = schema => {
    const selectOptionSchema = selectOptionSchemaFactory(schema)

    return selectOptionSchema.when("isHeroInEditMode", {
        is: true,
        then: requiredFieldFactory(selectOptionSchema)
    })
}

const onHoldRequiredSchemaFactory = schema =>
    schema.when(["status.value", "isHeroInEditMode"], {
        is: (status, isHeroInEditMode) => isHeroInEditMode && status === STOCK_ITEM_STATUS_KEYS.ON_HOLD,
        then: schema.required(REQUIRED_MESSAGE)
    })

const conditionOptionSchema = Yup.object().shape({
    id: Yup.number().required()
})

const locationSchema = Yup.object()
    .shape({
        id: Yup.number().required(),
        name: Yup.string().required()
    })
    .nullable()

const boxSchema = Yup.object().shape({
    location: locationSchema,
    name: Yup.string()
})

const boxesSchema = Yup.array().of(boxSchema)

const conditionSchema = inEditRequiredSelectFieldFactory(conditionOptionSchema)

const forSelectOptionSchema = selectOptionSchemaFactory(Yup.number().nullable(true))

const forSchema = onHoldRequiredSchemaFactory(forSelectOptionSchema)

const reasonOptionSchema = selectOptionSchemaFactory(Yup.string().nullable(true))

const reasonSchema = onHoldRequiredSchemaFactory(reasonOptionSchema)

const conditionImagesSchema = IMAGES_SCHEMA.when(["condition.value.require_images", "isHeroInEditMode"], {
    is: (areImagesRequired, isHeroInEditMode) => isHeroInEditMode && areImagesRequired,
    then: REQUIRED_IMAGES_SCHEMA
})

export default Yup.object().shape({
    status: inEditRequiredSelectFieldFactory(Yup.string()),
    warehouse: inEditRequiredSelectFieldFactory(Yup.string()),
    condition: conditionSchema,
    for: forSchema,
    reason: reasonSchema,
    conditionImages: conditionImagesSchema,
    boxes: boxesSchema,
    description: DESCRIPTION_SCHEMA,
    selectedCombinationAttributes: COMBINATION_ATTRIBUTES_SCHEMA
})
