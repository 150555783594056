import { useCallback } from "react"

const useStockItemConditionChange = ({
    item,
    stockItemEditMutation,
    checkItemIsDamaged,
    selectedCondition,
    setSelectedCondition,
    initialItemCondition
}) => {
    const resetSelectedConditionItem = useCallback(() => {
        setSelectedCondition(initialItemCondition)
    }, [item])

    const onSelectCondition = useCallback(
        option => {
            const {
                value: { id: product_condition_id }
            } = option
            if (selectedCondition.product_condition_id === product_condition_id) {
                return
            }

            setSelectedCondition(option)

            return stockItemEditMutation
                .mutate({
                    id: item.id,
                    data: { condition: { product_condition_id } }
                })
                .catch(resetSelectedConditionItem)
        },
        [stockItemEditMutation, resetSelectedConditionItem, item]
    )

    return {
        onSelectCondition,
        resetSelectedConditionItem,
        statusSelectValue: selectedCondition,
        isLoading: stockItemEditMutation.fetchStatus.isLoading && !checkItemIsDamaged(selectedCondition.type)
    }
}

export default useStockItemConditionChange
