import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./quantity.css"

const Quantity = props => {
    const {
        cx,
        value,
        min,
        max,
        onChange,
        onKeyDown,
        type,
        error,
        disabled,
        isInputDisabled,
        onBlur: onBlurProp
    } = props

    const [isActive, setIsActive] = useState(false)

    const handleOnChange = event =>
        !disabled && onChange(isNaN(Number(event.target.value)) ? 0 : Number(event.target.value))
    const increment = () => !disabled && (typeof max !== "number" || value < max) && onChange(value + 1)
    const decrement = () => !disabled && (typeof min !== "number" || value > min) && onChange(value - 1)

    const onFocus = () => setIsActive(true)
    const onBlur = e => {
        setIsActive(false)
        onBlurProp && onBlurProp(e)
    }

    return (
        <Fragment>
            <div className={cx("root", { active: isActive, disabled, error: !!error })}>
                <button type={type} className={cx("minus")} onClick={decrement} />
                {!isInputDisabled ? (
                    <input
                        type="text"
                        value={value}
                        onChange={handleOnChange}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        disabled={isInputDisabled}
                    />
                ) : (
                    <input
                        type="number"
                        value={value}
                        min={min}
                        max={max}
                        onChange={handleOnChange}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        disabled={isInputDisabled}
                    />
                )}
                <button type={type} className={cx("plus")} onClick={increment} />
            </div>
            {error && <span className={cx("error-message")}>{error}</span>}
        </Fragment>
    )
}

Quantity.defaultProps = {
    type: "button",
    isInputDisabled: true
}

Quantity.propTypes = {
    value: PropTypes.number.isRequired,
    min: PropTypes.number,
    max: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    type: PropTypes.string,
    disabled: PropTypes.bool
}

export default withStyles(Quantity, styles)
