import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"
import { formatDate } from "helpers/date"
import { useActions, useFetch } from "hooks"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import { updatePurchaseOrders } from "actions/purchaseOrders"

import { Toggle, DatePickerWithInput, Modal, Button } from "ui"
import styles from "./Paid.css"
import toggleStyles from "./overrides/Toggle.css"
import modalStyles from "./overrides/Modal.css"

const Paid = ({ orderUuid, paid, paidAt, orderId }) => {
    const cx = useStyles(styles)
    const actions = useActions({ showSuccessNotification, showErrorNotification, updatePurchaseOrders })
    const [isModalOpened, setIsModalOpened] = useState(false)
    const [paidDate, setPaidDate] = useState(new Date().toISOString())

    const [handleSubmit, { isLoading: isSaving }] = useFetch({
        action: () => actions.updatePurchaseOrders(orderId, { paid_at: paid ? null : paidDate.substr(0, 10) }),
        onSuccess: actions.showSuccessNotification,
        onError: actions.showErrorNotification,
        onFinish: () => setIsModalOpened(false)
    })

    return (
        <div className={cx("root")}>
            <Toggle
                isOn={paid}
                customStyles={toggleStyles}
                label={{ on: "", off: "" }}
                handleChange={() => setIsModalOpened(true)}
            />
            <span>{!!paidAt && formatDate(paidAt)}</span>

            {isModalOpened && (
                <Modal isOpen closeModal={() => setIsModalOpened(false)} preventClickOutside customStyles={modalStyles}>
                    <Modal.Header>{`${orderUuid} update confirmation`}</Modal.Header>
                    <Modal.Body>
                        <div className={cx("description")}>{`This order will be set as ${
                            paid ? "unpaid" : "paid"
                        }.`}</div>
                        {!paid && (
                            <div className={cx("date")}>
                                <DatePickerWithInput
                                    label="Paid date:"
                                    date={paidDate}
                                    maxDate={new Date()}
                                    handleSelectDate={setPaidDate}
                                />
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Actions>
                        <button className={cx("cancelButton")} onClick={() => setIsModalOpened(false)}>
                            Cancel
                        </button>
                        <Button
                            className="first-button"
                            label={`Save as ${paid ? "unpaid" : "paid"}`}
                            isLoading={isSaving}
                            isDisabled={!paidDate}
                            onClick={handleSubmit}
                        />
                    </Modal.Actions>
                </Modal>
            )}
        </div>
    )
}

Paid.propTypes = {
    orderUuid: PropTypes.string.isRequired,
    orderId: PropTypes.number.isRequired,
    paid: PropTypes.bool.isRequired,
    paidAt: PropTypes.string
}

export default Paid
