import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./sort.module.css"

class Sort extends Component {
    render() {
        const { cx, sortKeys, sortBy, sortDirection, handleSort } = this.props

        return (
            <nav className={cx("sort")}>
                <ul className={cx("likeTable")}>
                    {sortKeys.map(el => {
                        const { type, title } = el
                        return (
                            <li
                                key={title}
                                className={cx({ hasSort: type, [sortDirection]: sortBy === type })}
                                onClick={() => {
                                    type && handleSort(type)
                                }}
                            >
                                <span>{title}</span>
                                <div className={cx("icons")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5">
                                        <path
                                            className="svg-icon-sort-asc"
                                            fillRule="evenodd"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth=".64"
                                            d="M3.5 0L6 4H1z"
                                        />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5">
                                        <path
                                            className="svg-icon-sort-desc"
                                            fillRule="evenodd"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth=".64"
                                            d="M3.5 5L6 1H1z"
                                        />
                                    </svg>
                                </div>
                            </li>
                        )
                    })}
                    <li />
                </ul>
            </nav>
        )
    }
}

export default withStyles(Sort, styles)
