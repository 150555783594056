import React from "react"
import CollectionList from "modules/ContentModule/components/CollectionList"

const List = () => {
    return (
        <CollectionList
            collectionLabel="Trade"
            collectionLabelPlural="Trades"
            basePath="/content/trades"
            apiUrl="/trades"
            collectionModel="trades"
            canChangeLayout
        />
    )
}

export default List
