import React from "react"
import { withMultiselectProvider } from "HOC/withMultiselect"

import Overview from "./Overview"

export const OrderOverview = withMultiselectProvider("SALES_ORDER_INFO")(props => (
    <Overview orderType="sales" {...props} />
))
export const MemoOverview = withMultiselectProvider("MEMO_INFO")(props => <Overview orderType="memo" {...props} />)
export const RentalOverview = withMultiselectProvider("RENTAL_INFO")(props => (
    <Overview orderType="rental" {...props} />
))
