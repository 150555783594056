import * as constants from "constants/index"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"
import idx from "idx"

function list(state = { data: [], meta: {}, fetchStatus: initialState() }, action) {
    switch (action.type) {
        case constants.GET_EMAIL_TEMPLATES_START:
            return { data: [], meta: {}, fetchStatus: requestState() }
        case constants.GET_EMAIL_TEMPLATES_SUCCESS:
            return {
                data: idx(action, _ => _.payload.data) || [],
                meta: idx(action, _ => _.payload.meta) || {},
                fetchStatus: successState()
            }
        case constants.GET_EMAIL_TEMPLATES_ERROR:
            return { data: {}, meta: {}, fetchStatus: { ...failureState(), error: action.payload } }
        case constants.UPDATE_EMAIL_TEMPLATE_SUCCESS:
            return {
                ...state,
                data: state.data.map(el => (el.id === action.payload.data.id ? action.payload.data : el))
            }
        default:
            return state
    }
}

export default list
