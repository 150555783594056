import React from "react"
import withStyles from "HOC/withStyles"

import styles from "./EmptyRoom.css"

const EmptyRoom = ({ cx, text }) => {
    return (
        <div className={cx("root")}>
            <span className={cx("icon")} />
            <p>{text || "No products added"}</p>
        </div>
    )
}

export default withStyles(EmptyRoom, styles)
