import { INPUT_TYPES } from "constants/index"

export default {
    name: "Login",
    endpoint: "/user/change-password",
    fields: [
        {
            name: "old_password",
            label: "Old password",
            type: INPUT_TYPES.PASSWORD,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "new_password",
            label: "New password",
            type: INPUT_TYPES.PASSWORD,
            defaultValue: "",
            attributes: {
                required: true
            }
        }
    ]
}
