import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function loginUserStart() {
    return {
        type: constants.LOGIN_START
    }
}
export function loginUserSuccess(data) {
    return {
        type: constants.LOGIN_SUCCESS,
        payload: {
            data
        }
    }
}

export function setPasswordStart() {
    return {
        type: constants.SET_PASSWORD_START
    }
}
export function setPasswordSuccess(data) {
    return {
        type: constants.SET_PASSWORD_SUCCESS,
        payload: {
            data
        }
    }
}
export function setPasswordError(error) {
    return {
        type: constants.SET_PASSWORD_ERROR,
        payload: {
            error
        }
    }
}

export function setPassword(body) {
    return dispatch => {
        dispatch(setPasswordStart())

        fetch
            .post("/user/reset-password", body)
            .then(data => dispatch(loginUserSuccess(data)), data => dispatch(setPasswordError(data)))
            .catch(error => dispatch(setPasswordError(error)))
    }
}
