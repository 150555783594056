import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { FieldArray } from "formik"
import Draggable from "react-draggable"

import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"
import { Button, Modal, SearchBar } from "ui"
import InfiniteScroll from "ui/InfiniteScroll"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import SelectFromList from "components/Modal/SelectFromList"
import ProductListItem from "./components/ProductListItem"

import styles from "./ChooseProducts.css"
import stylesModal from "./override/Modal.css"

import iconPlus from "./assets/ico-plus.svg"
import iconImage from "./assets/ico-image.svg"
import iconLeftArrow from "assets/arrow-blue.svg"
import iconPageNotFound from "./assets/ico-page-not-find.svg"
import iconAlertInfo from "assets/alert-info.svg"
import { objToQueryString } from "helpers/urls"

const ChooseProduct = ({ isOpen, onClose, cx, handleChangePhoto, propsForm, isSubmitting }) => {
    const [hoveredPin, setHoveredPin] = useState(null)
    const [searchProductMeta, setSearchProductMeta] = useState({})

    return (
        <Modal
            customStyles={stylesModal}
            isOpen={isOpen}
            closeModal={onClose}
            preventClickOutside={true}
            render={renderDialog}
        />
    )

    function renderDialog() {
        return (
            <div className={cx("root")}>
                <div className={cx("panel-image")}>
                    <header className={cx("header")}>
                        <div className={cx("header-left")}>Add Pins</div>
                        <div className={cx("header-right")}>
                            <span
                                className={cx("changeImageAction", { disabled: !eachPinHasProduct() })}
                                onClick={handleChangePhoto}
                            >
                                <img src={iconImage} alt="" />
                                <span>Change image</span>
                            </span>
                        </div>
                    </header>
                    <FieldArray
                        name="pins"
                        render={({ push, replace, index }) => (
                            <div key={index} className={cx("image-container", { overlay: !eachPinHasProduct() })}>
                                <div
                                    className={cx("image")}
                                    style={{
                                        backgroundImage: "url(" + (propsForm.values.cover_desktop || {}).url + ")"
                                    }}
                                    onClick={e => {
                                        push({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
                                    }}
                                >
                                    {propsForm.values.pins.map((item, index) => (
                                        <Draggable
                                            key={`${(item.product || {}).id}-${item.x}-${item.y}`}
                                            bounds="parent"
                                            defaultPosition={{ x: item.x, y: item.y }}
                                            onStop={(e, data) => {
                                                replace(index, { ...item, x: data.x, y: data.y })
                                            }}
                                        >
                                            <div
                                                onClick={e => {
                                                    e.stopPropagation()
                                                }}
                                                className={cx("pinWrapper", {
                                                    top: !pinHasProduct(index),
                                                    highlightedPinWrapper: hoveredPin === index
                                                })}
                                            >
                                                <div
                                                    onClick={e => {
                                                        e.stopPropagation()
                                                    }}
                                                    className={cx("pin")}
                                                >
                                                    {!item.product ? <img src={iconPlus} alt="" /> : index + 1}
                                                </div>
                                            </div>
                                        </Draggable>
                                    ))}
                                </div>
                            </div>
                        )}
                    />
                </div>
                <div className={cx("panel-products")}>{renderPanelProducts()}</div>
            </div>
        )
    }

    function eachPinHasProduct() {
        return !!propsForm.values.pins.every(item => item.product)
    }

    function pinHasProduct(index) {
        return !!propsForm.values.pins[index].product
    }

    function renderPanelProducts() {
        if (!propsForm.values.pins.length) {
            return (
                <Fragment>
                    <header className={cx("panel-products-header")}>
                        <div className={cx("title")}>Product Tags</div>
                    </header>
                    <div className={cx("panel-products-empty-list")}>
                        <span>
                            <img src={iconAlertInfo} alt="" />
                        </span>
                        <span>Click product in the photo you would like to tag.</span>
                    </div>
                </Fragment>
            )
        }

        if (eachPinHasProduct()) {
            return (
                <Fragment>
                    <header className={cx("panel-products-header")}>
                        <div className={cx("title")}>Product Tags</div>
                    </header>
                    <div className={cx("panel-products-content")}>
                        <div className={cx("panel-products-content-inner")}>
                            {propsForm.values.pins.map((item, index) => (
                                <div
                                    key={`${item.product.id}-${item.x}-${item.y}`}
                                    className={cx("ProductListItemWrapper")}
                                    onMouseEnter={() => setHoveredPin(index)}
                                    onMouseLeave={() => setHoveredPin(null)}
                                >
                                    <ProductListItem
                                        index={index}
                                        isSelected={true}
                                        as="div"
                                        item={item.product}
                                        handleRemove={handleRemoveProduct}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <footer className={cx("panel-products-footer")}>
                        <span onClick={onClose} className={cx("cancelButton")}>
                            Cancel
                        </span>
                        <Button
                            className={cx("first-button")}
                            type="button"
                            label="Save"
                            onClick={propsForm.handleSubmit}
                            isLoading={isSubmitting}
                        />
                    </footer>
                </Fragment>
            )
        }

        return (
            <Fragment>
                <header className={cx("panel-products-header")}>
                    <span className={cx("back")} onClick={() => removePinWithoutProduct()}>
                        <img src={iconLeftArrow} alt="" />
                        <span>Back to Product Tags</span>
                    </span>
                </header>
                <div className={cx("panel-products-content")}>
                    <SelectFromList
                        selectedItems={[]}
                        loadOnInit={false}
                        typeItems="Products"
                        maxSelectedItems={1}
                        exclude={propsForm.values.pins
                            .map(item => (item.product ? item.product.id : null))
                            .filter(item => item)
                            .join(",")}
                        fetchItemsList={(query, { page, length, exclude = "" }) => {
                            const params = {
                                query,
                                page,
                                length,
                                exclude,
                                available_on_web: true
                            }
                            return fetch.get(`/products?${objToQueryString(params)}`).then(data => {
                                setSearchProductMeta(data.meta)
                                return { payload: { data } }
                            })
                        }}
                        prepareItem={item => {
                            return {
                                id: item.id,
                                name: item.name,
                                img: item.media && item.media.url,
                                brand: item.brand
                            }
                        }}
                    >
                        {localProps => {
                            return (
                                <div className={cx("productsWrapper")}>
                                    <div className={localProps.cx("searchbar")}>
                                        <SearchBar
                                            placeholder={`Search and add ${localProps.typeItems}…`}
                                            handleSearch={localProps.handleSearch}
                                            clearInput={() => localProps.handleSearch("")}
                                            filter={localProps.query}
                                        />
                                    </div>

                                    {localProps.isLoading && (
                                        <div className={localProps.cx("loader")}>
                                            <SnakeLoader />
                                        </div>
                                    )}

                                    {localProps.isLoaded && !localProps.displayedItems.length && (
                                        <div className={cx("selectFromListEmptyView")}>
                                            <img src={iconPageNotFound} alt="not found" />
                                            <h3>Sorry, no product found.</h3>
                                            <p>
                                                Please check the spelling <br />
                                                or create new project.
                                            </p>
                                        </div>
                                    )}

                                    {localProps.isLoaded && !!localProps.displayedItems.length && (
                                        <Fragment>
                                            <p className={cx("productsTotal")}>
                                                1-{searchProductMeta.to} over {searchProductMeta.total} results
                                            </p>
                                            <div className={localProps.cx("list-wrapper")}>
                                                <InfiniteScroll
                                                    loadMore={localProps.handleLoadMore}
                                                    hasMore={localProps.meta.current_page < localProps.meta.last_page}
                                                    loader={
                                                        <div className={localProps.cx("loader")} key={0}>
                                                            <SnakeLoader />
                                                        </div>
                                                    }
                                                    useWindow={false}
                                                >
                                                    <ul className={localProps.cx("list")}>
                                                        {localProps.displayedItems.map(item => {
                                                            const preparedItem = localProps.prepareItem(item)

                                                            return (
                                                                <ProductListItem
                                                                    key={item.id}
                                                                    as="li"
                                                                    item={preparedItem}
                                                                    handleSelect={handleSelectProduct}
                                                                />
                                                            )
                                                        })}
                                                    </ul>
                                                </InfiniteScroll>
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                            )
                        }}
                    </SelectFromList>
                </div>
                <footer className={cx("panel-products-footer")} />
            </Fragment>
        )
    }

    function removePinWithoutProduct() {
        propsForm.setFieldValue("pins", propsForm.values.pins.filter(item => !!item.product))
    }

    function handleSelectProduct(product) {
        const index = propsForm.values.pins.findIndex(item => !item.product)

        if (index !== -1) {
            const nextValue = [
                ...propsForm.values.pins.slice(0, index),
                {
                    ...propsForm.values.pins[index],
                    product: product
                },
                ...propsForm.values.pins.slice(index + 1)
            ]

            propsForm.setFieldValue("pins", nextValue)
        }
    }

    function handleRemoveProduct(product, index) {
        propsForm.setFieldValue("pins", propsForm.values.pins.filter((item, pinIndex) => pinIndex !== index))
    }
}

ChooseProduct.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool,
    cx: PropTypes.func.isRequired
}

export default withStyles(ChooseProduct, styles)
