import React from "react"

import withMultipleUploader from "HOC/withMultipleUploader"

import Dropzone from "components/Reusable/Form/Dropzone/index"
import Button from "ui/Button"

import withStyles from "HOC/withStyles"
import styles from "./styles.module.css"

const Cta = ({ cx, uploader: { state, actions } }) => {
    return (
        <div className={cx("root")}>
            <Dropzone
                isUploading={state.isUploading}
                isSuccess={state.isSuccess}
                progress={state.progress}
                handleUpload={actions.handleUpload}
                handleRemove={actions.handleRemove}
                acceptFormats=".xlsx"
            >
                <Button className={cx("first-button", "upload-button")} isLoading={state.isUploading} label="Add XLS" />
            </Dropzone>
        </div>
    )
}

export default withMultipleUploader(withStyles(Cta, styles))
