import React, { Component } from "react"
import ReactSVG from "react-svg"
import moment from "moment"
import { READABLE_STANDARD_DATE } from "constants/index"
import { formatDate } from "helpers/date"
import { formatPrice } from "helpers/units"

import withStyles from "HOC/withStyles"
import styles from "./analytics.module.css"
import personIcon from "assets/person.svg"

class Analytics extends Component {
    render() {
        const { cx } = this.props

        return (
            <section className={cx("root")}>
                {!!this.props.generalNote && this.renderGeneralNotes()}
                {this.renderVisits()}
                {this.renderPurchases()}
                {this.renderNewsletter()}
                {this.renderOtherInfo()}
            </section>
        )
    }

    renderGeneralNotes() {
        const { cx, generalNote } = this.props
        return (
            <section className={cx("generalNote")}>
                <h2>General note</h2>
                <span className={cx("note")}>{generalNote}</span>
            </section>
        )
    }

    renderVisits() {
        const { cx, lastShowroomVisits, showroomVisits, onlineVisits, lastOnlineVisits } = this.props
        return (
            <section className={cx("visits")}>
                <h2>Visits</h2>
                <ul>
                    <li>
                        <h3>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M3 3h18v18H3z" />
                                    <path
                                        className={cx("withFill")}
                                        fillRule="nonzero"
                                        d="M18 6H6v1.5h12V6zm.75 7.5V12L18 8.25H6L5.25 12v1.5H6V18h7.5v-4.5h3V18H18v-4.5h.75zm-6.75 3H7.5v-3H12v3z"
                                    />
                                </g>
                            </svg>
                            <span>Division</span>
                            <strong>{showroomVisits}</strong>
                        </h3>
                        <small>
                            <span>Last visit: </span>
                            {formatDate(lastShowroomVisits)}
                        </small>
                    </li>
                    <li>
                        <h3>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M3 3h18v18H3z" />
                                    <path
                                        className={cx("withFill")}
                                        fillRule="nonzero"
                                        d="M18.875 11.744l-3.125-.006a.633.633 0 0 0-.625.637v5.738c0 .35.281.637.625.637h3.125a.633.633 0 0 0 .625-.637v-5.738a.628.628 0 0 0-.625-.631zm0 5.731H15.75v-4.463h3.125v4.463zM17 6H5.75c-.694 0-1.25.567-1.25 1.275v7.65c0 .701.556 1.275 1.25 1.275h4.375v1.275h-1.25v1.275h5v-1.275h-1.25V16.2h1.25v-1.275H5.75v-7.65H17v3.188h1.25V7.275C18.25 6.567 17.687 6 17 6zm-5.019 4.463l-.606-1.913-.606 1.913H8.875l1.544 1.122-.588 1.855 1.544-1.148 1.544 1.148-.588-1.855 1.544-1.122h-1.894z"
                                    />
                                </g>
                            </svg>
                            <span>Online store</span>
                            <strong>{onlineVisits}</strong>
                        </h3>
                        <small>
                            <span>Last visit: </span>
                            {formatDate(lastOnlineVisits)}
                        </small>
                    </li>
                </ul>
            </section>
        )
    }

    renderPurchases() {
        const {
            cx,
            lastPurchase,
            totalCollectedSales,
            totalPurchaseAmount,
            averagePurchase,
            storeCredits,
            totalReturnAmount
        } = this.props

        return (
            <section className={cx("purchases")}>
                <p>
                    <span>Last purchase: </span>
                    {formatDate(lastPurchase)}
                </p>
                <h2>Purchases</h2>
                <ul>
                    <li>
                        <h3>Total purchase amount:</h3>
                        <span>{formatPrice(totalPurchaseAmount)}</span>
                    </li>
                    <li>
                        <h3>Total collected sales:</h3>
                        <span>{formatPrice(totalCollectedSales)}</span>
                    </li>
                    <li>
                        <h3>Total return amount:</h3>
                        <span>{formatPrice(totalReturnAmount)}</span>
                    </li>
                    <li className={cx("orange")}>
                        <h3>Total store credits:</h3>
                        <span>{formatPrice(storeCredits)}</span>
                    </li>
                    <li className={cx("green")}>
                        <h3>Average purchase:</h3>
                        <span>{formatPrice(averagePurchase)}</span>
                    </li>
                </ul>
            </section>
        )
    }

    renderNewsletter() {
        const { cx, hasNewsletter, newsletterChangedAt } = this.props

        return (
            <section className={cx("newsletter", { hasNewsletter })}>
                <h2>Newsletter</h2>
                <ul>
                    <li>
                        <h3>Newsletter approval:</h3>
                        <span>{hasNewsletter ? "Yes" : "No"}</span>
                    </li>
                    {hasNewsletter && (
                        <li>
                            <div>
                                <h3>Opt-In:</h3>
                                <span>Yes</span>
                            </div>
                            <div>
                                <h3>Since the date:</h3>
                                <span>{formatDate(newsletterChangedAt)}</span>
                            </div>
                        </li>
                    )}
                </ul>
            </section>
        )
    }

    renderOtherInfo = () => {
        const { cx, dateOfBirth, avatar } = this.props

        return (
            <section className={cx("otherInfo")}>
                <h2>Other profile info</h2>
                <div className={cx("box")}>
                    <div className={cx("birthDate")}>
                        <span className={cx("label")}>Date of Birth:</span>
                        <span className={cx("date")}>
                            {dateOfBirth ? moment(dateOfBirth).format(READABLE_STANDARD_DATE) : "-"}
                        </span>
                    </div>
                    {avatar ? (
                        <div className={cx("avatar")} style={{ backgroundImage: `url(${avatar})` }} />
                    ) : (
                        <ReactSVG src={personIcon} className={cx("defaultAvatar")} />
                    )}
                </div>
            </section>
        )
    }
}

export default withStyles(Analytics, styles)
