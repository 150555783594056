export const columns = {
    full_name: { label: "Contact Name" },
    job_title: { label: "Type of contact" },
    email: { label: "Email" },
    phone: { label: "Phone" },
    status: { label: "Status" },
    note: { label: "Note" },
    created_at: { label: "Added" },
    action: { label: "" }
}
