import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Modal, WarningBar, Radio, ActionButton, Button } from "ui"

import withStyles from "HOC/withStyles"
import styles from "./MergeDataModal.css"

const { Header, Actions } = Modal

const labels = {
    first_name: "First name",
    last_name: "Last name",
    company: "Company",
    phone: "Phone number",
    types: "Type of contact"
}

const TableRow = props => {
    const { cx, label, name, data, value, handleSelect } = props

    const formatData = dataToFormat => {
        if (name === "types") {
            return dataToFormat.length ? (
                <Fragment>
                    {dataToFormat.map(({ name }, index, typesArray) =>
                        index === typesArray.length - 1 ? (
                            name
                        ) : (
                            <Fragment key={name}>
                                {name}, <br />
                            </Fragment>
                        )
                    )}
                </Fragment>
            ) : (
                "-"
            )
        }

        return dataToFormat || "-"
    }

    return (
        <tr className={cx("row", { all: name === "all" })}>
            <th>{label}</th>
            <td>
                <Radio
                    className={cx("radioButton")}
                    name={name}
                    label={formatData(data.old)}
                    value="old"
                    checked={value === "old"}
                    handleSelect={handleSelect}
                />
            </td>
            <td>
                <Radio
                    className={cx("radioButton")}
                    name={name}
                    label={formatData(data.new)}
                    value="new"
                    checked={value === "new"}
                    handleSelect={handleSelect}
                />
            </td>
        </tr>
    )
}

const MergeDataModal = props => {
    const { cx, onClose, onSubmit, isSubmitting, data, values, handleSelect } = props

    const allValue = Object.values(values).every((value, _, array) => value === array[0]) && values.first_name

    return (
        <Modal preventClickOutside isOpen closeModal={onClose}>
            <Header className={cx("header")}>Existing user data</Header>
            <WarningBar text="Marge user data with existing before providing approval." />
            <table className={cx("table")}>
                <thead>
                    <tr>
                        <th />
                        <th>Existing data</th>
                        <th>User provided data</th>
                    </tr>
                </thead>
                <tbody>
                    <TableRow
                        cx={cx}
                        label="All data"
                        name="all"
                        data={{ old: "Select all", new: "Select all" }}
                        value={allValue}
                        handleSelect={handleSelect}
                    />
                    {Object.entries(data).map(([name, elementData]) => (
                        <TableRow
                            key={name}
                            cx={cx}
                            label={labels[name]}
                            name={name}
                            data={elementData}
                            value={values[name]}
                            handleSelect={handleSelect}
                        />
                    ))}
                </tbody>
            </table>
            <Actions className={cx("actionsWrapper")}>
                <ActionButton
                    className={cx("cancelButton")}
                    label="Cancel"
                    disabled={isSubmitting}
                    handleClick={onClose}
                />
                <Button
                    className={cx("submitButton", "first-button")}
                    label="Save"
                    isLoading={isSubmitting}
                    onClick={onSubmit}
                />
            </Actions>
        </Modal>
    )
}

const fieldDataElement = PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string
        })
    )
])
const fieldData = PropTypes.shape({
    new: fieldDataElement,
    old: fieldDataElement
})
const fieldValue = PropTypes.oneOf(["new", "old"])

MergeDataModal.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    data: PropTypes.shape({
        first_name: fieldData,
        last_name: fieldData,
        phone: fieldData,
        company: fieldData,
        types: fieldData
    }).isRequired,
    values: PropTypes.shape({
        first_name: fieldValue,
        last_name: fieldValue,
        phone: fieldValue,
        company: fieldValue,
        types: fieldValue
    }).isRequired
}

export default withStyles(MergeDataModal, styles)
