import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./Tags.css"

class Tags extends Component {
    render() {
        const {
            cx,
            label,
            list,
            activeTag,
            defaultSliceParam,
            defaultButtonName,
            toggleButtonName,
            handleToggleList,
            handleSetActiveTag
        } = this.props

        return (
            <div className={cx("root")}>
                {label && <div className={cx("label")}>{label}</div>}
                <div
                    className={cx("tag", { active: activeTag === defaultButtonName })}
                    onClick={() => handleSetActiveTag({ name: defaultButtonName })}
                >
                    {defaultButtonName}
                </div>
                {list &&
                    list.map(({ id, name }) => {
                        return (
                            <div
                                key={id}
                                className={cx("tag", { active: activeTag === name })}
                                onClick={() => handleSetActiveTag({ id, name })}
                            >
                                {name}
                            </div>
                        )
                    })}
                {list && list.length >= defaultSliceParam && (
                    <div className={cx("show-more")} onClick={handleToggleList}>
                        {toggleButtonName}
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(Tags, styles)
