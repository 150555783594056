import fetch from "helpers/fetch"
import { serializeQuery } from "helpers/urls"
import { objToQueryString } from "helpers/queryParams"
import * as constants from "../constants"

export function getCalendarListDataStart() {
    return {
        type: constants.GET_CALENDARS_LIST_DATA_START
    }
}

export function getCalendarListDataSuccess(data) {
    return {
        type: constants.GET_CALENDARS_LIST_DATA_SUCCESS,
        payload: {
            data
        }
    }
}

export function getCalendarListDataError(error) {
    return {
        type: constants.GET_CALENDARS_LIST_DATA_ERROR,
        payload: {
            error
        }
    }
}

export function getCalendarsEntriesDataStart(isClearPrevData) {
    return {
        type: constants.GET_CALENDARS_ENTRIES_DATA_START,
        payload: {
            isClearPrevData
        }
    }
}

export function getCalendarsEntriesDataSuccess(data) {
    return {
        type: constants.GET_CALENDARS_ENTRIES_DATA_SUCCESS,
        payload: {
            data
        }
    }
}

export function getCalendarsEntriesDataError(error) {
    return {
        type: constants.GET_CALENDARS_ENTRIES_DATA_ERROR,
        payload: {
            error
        }
    }
}

export function getCalendarsList() {
    return dispatch => {
        dispatch(getCalendarListDataStart())
        fetch
            .get(`/calendars?length=9999`)
            .then(data => {
                dispatch(getCalendarListDataSuccess(data.data))
            })
            .catch(error => {
                dispatch(getCalendarListDataError(error))
            })
    }
}

export function createCalendar(body) {
    return dispatch => {
        return fetch
            .post(`/calendars`, body)
            .then(() => dispatch(getCalendarsList()))
            .catch(error => dispatch(getCalendarListDataError(error)))
    }
}

export function editCalendar(id, body) {
    return dispatch => {
        return fetch
            .patch(`/calendars/${id}`, body)
            .then(() => dispatch(getCalendarsList()))
            .catch(error => dispatch(getCalendarListDataError(error)))
    }
}

export function deleteCalendar(id, isPublic) {
    return dispatch => {
        return fetch
            .deleteRAW(`/calendars/${id}`)
            .then(() => dispatch(deleteCalendarSuccess(id, isPublic)))
            .catch(error => dispatch(getCalendarListDataError(error)))
    }
}

export function deleteCalendarSuccess(id, isPublic) {
    return {
        type: constants.DELETE_CALENDAR_SUCCESS,
        payload: {
            id,
            isPublic
        }
    }
}

export function getEntries(calendars, isClearPrevData = true) {
    return (dispatch, getState) => {
        dispatch(getCalendarsEntriesDataStart(isClearPrevData))

        const startTimeFetching = new Date()
        const calendarStore = getState().db.calendar
        const query = calendarStore.entryQuery
        const params = { page: 1, length: 9999, ...calendarStore.entryParams }
        const filters = {
            user_id: calendarStore.entryFilters.users.map(user => user.id).join(","),
            sources: calendarStore.entryFilters.sources.map(source => source.id).join(","),
            companies: calendarStore.entryFilters.divisions.map(division => division.id).join(","),
            client_id: calendarStore.entryFilters.client.id || "",
            project: calendarStore.entryFilters.project.id || ""
        }

        return calendars.length > 0
            ? fetch
                  .get(
                      `/calendars-entries?${serializeQuery(calendars, "calendars")}&${objToQueryString(
                          filters
                      )}&query=${query}&${objToQueryString(params)}`
                  )
                  .then(data => dispatch(getCalendarsEntriesDataSuccess({ ...data, startTimeFetching })))
                  .catch(error => dispatch(getCalendarsEntriesDataError(error)))
            : dispatch(
                  getCalendarsEntriesDataSuccess({
                      data: [],
                      meta: { last_page: 1, total: 0, current_page: 1, to: 0, from: 0 },
                      startTimeFetching
                  })
              )
    }
}

export function getMoreEntries(calendars) {
    return (dispatch, getState) => {
        dispatch({ type: constants.GET_MORE_CALENDARS_ENTRIES_DATA_START })

        const calendarStore = getState().db.calendar
        const query = calendarStore.entryQuery
        const params = { page: 1, length: 9999, ...calendarStore.entryParams }
        const filters = {
            user_id: calendarStore.entryFilters.users.map(user => user.id).join(","),
            sources: calendarStore.entryFilters.sources.map(source => source.id).join(","),
            companies: calendarStore.entryFilters.divisions.map(division => division.id).join(","),
            client_id: calendarStore.entryFilters.client.id || "",
            project: calendarStore.entryFilters.project.id || ""
        }

        return fetch
            .get(
                `/calendars-entries?${serializeQuery(calendars, "calendars")}&${objToQueryString(
                    filters
                )}&query=${query}&${objToQueryString(params)}`
            )
            .then(data =>
                dispatch({
                    type: constants.GET_MORE_CALENDARS_ENTRIES_DATA_SUCCESS,
                    payload: data
                })
            )
            .catch(() => dispatch({ type: constants.GET_MORE_CALENDARS_ENTRIES_DATA_ERROR }))
    }
}

export function createCalendarEntry(body, calendars, isWillRefreshAfter = true) {
    return dispatch => {
        dispatch({ type: constants.CREATE_CALENDAR_ENTRY_REQUEST })
        return fetch
            .post(`/calendars-entries`, body)
            .then(result => {
                dispatch(
                    isWillRefreshAfter
                        ? getEntries(calendars)
                        : { type: constants.CREATE_CALENDAR_ENTRY_SUCCESS, payload: result }
                )
            })
            .catch(() => dispatch({ type: constants.DELETE_CALENDAR_ENTRY_ERROR }))
    }
}

export function editCalendarEntry(id, body, calendars, isClearPrevData) {
    return dispatch => {
        dispatch({ type: constants.EDIT_CALENDAR_ENTRY_REQUEST })
        return fetch
            .patch(`/calendars-entries/${id}`, body)
            .then(result => dispatch({ type: constants.EDIT_CALENDAR_ENTRY_SUCCESS, payload: result }))
            .catch(() => dispatch({ type: constants.EDIT_CALENDAR_ENTRY_ERROR }))
    }
}

export function deleteCalendarEntry(id) {
    return dispatch => {
        dispatch({ type: constants.DELETE_CALENDAR_ENTRY_REQUEST })

        return fetch
            .deleteRAW(`/calendars-entries/${id}`)
            .then(() =>
                dispatch({
                    type: constants.DELETE_CALENDAR_ENTRY_SUCCESS,
                    payload: { id }
                })
            )
            .catch(() => dispatch({ type: constants.DELETE_CALENDAR_ENTRY_ERROR }))
    }
}

export function selectCalendarType(id, type) {
    return {
        type: constants.SELECT_CALENDAR_TYPE,
        payload: {
            id,
            type
        }
    }
}

export function unselectCalendarType(id, type) {
    return {
        type: constants.UNSELECT_CALENDAR_TYPE,
        payload: {
            id,
            type
        }
    }
}

export function setEntryQuery(query) {
    return {
        type: constants.SET_ENTRY_QUERY,
        payload: query
    }
}

export function setEntryFilters(filters) {
    return {
        type: constants.SET_ENTRY_FILTERS,
        payload: filters
    }
}

export function setEntryParams(params) {
    return {
        type: constants.SET_ENTRY_PARAMS,
        payload: params
    }
}

export function setDisplayOnlyCalendar(id) {
    return {
        type: constants.SET_DISPLAY_ONLY_CALENDAR,
        payload: id
    }
}

export function clearCalendarEntries() {
    return { type: constants.CLEAR_CALENDARS_ENTRIES }
}

export const moderateEntry = (id, action) => dispatch => {
    dispatch({ type: constants.MODERATE_ENTRY_START })

    return fetch
        .patch(`/calendar-entries/${id}/moderate`, { action })
        .then(() => dispatch({ type: constants.MODERATE_ENTRY_SUCCESS, payload: { id, action } }))
        .catch(() => dispatch({ type: constants.MODERATE_ENTRY_ERROR }))
}
