import React, { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import qs from "querystring"

import fetch from "helpers/fetch"
import { useFetch, useActions } from "hooks"
import { showErrorNotification, showSuccessNotification } from "actions/notification"

import { Layout, Header, List, ModalsWrapper } from "modules/AttributeGroupsModules/components"
import { ACTION_TYPES, LIST_TYPES } from "modules/AttributeGroupsModules/constants"
import { buildLink } from "modules/AttributeGroupsModules/helpers/links"
import MessageTransferNotificationWithLink from "modules/AttributeGroupsModules/components/MessageTransferNotificationWithLink"

const DataLayer = props => {
    const {
        type,
        getHeaderTitle,
        titleAddButton,
        placeholderSearch,
        parseItem,
        resourceChildrenUrl,
        resourceChildrenModelName,
        resourceChildrenParams,
        resourceUrl,
        isMovementActionsDisabled,
        HeaderComponent,
        ItemComponent,
        Navigation
    } = props

    const debounceRef = useRef(0)
    const history = useHistory()
    const actions = useActions({ showErrorNotification, showSuccessNotification })
    const [query, setQuery] = useState("")
    const [items, setItems] = useState([])
    const [resource, setResource] = useState({})
    const [metaItems, setMetaItems] = useState({
        total: 0,
        current_page: 1,
        last_page: 1
    })

    const [openModal, setOpenModal] = useState(null)
    const [focusedItem, setFocusedItem] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)

    useEffect(() => clearTimeout(debounceRef.current), [])

    const handleSuccessFetchData = ({ data, meta }) => {
        const newItems = data.map(parseItem)

        setItems(metaItems.current_page === 1 ? newItems : [...items, ...newItems])
        setMetaItems(meta)
    }

    const [fetchItems, fetchStatusItems] = useFetch({
        action: ({ page }) =>
            fetch.get(
                `${resourceChildrenUrl}?query=${query}&page=${page}&length=20&${qs.stringify(resourceChildrenParams)}`
            ),
        onSuccess: handleSuccessFetchData,
        onError: err => actions.showErrorNotification(err.statusText)
    })

    const [fetchResource, fetchStatusResource] = useFetch({
        action: () => fetch.get(resourceUrl),
        onSuccess: ({ data }) => setResource(data),
        onError: err => actions.showErrorNotification(err.statusText)
    })

    useEffect(() => {
        resourceUrl && fetchResource()
    }, [resourceUrl])

    useEffect(() => {
        clearTimeout(debounceRef.current)

        debounceRef.current = setTimeout(() => {
            setMetaItems({ ...metaItems, current_page: 1 })
            fetchItems({ page: 1 })
        }, 300)
    }, [query, resourceUrl])

    useEffect(() => {
        metaItems.current_page > 1 && fetchItems({ page: metaItems.current_page })
    }, [metaItems.current_page])

    const numberOfSelectedItems = items.filter(item => item.checked).length
    const isDragDisabled = !!query
    const areSomeChecked = numberOfSelectedItems > 0
    const areAllChecked = numberOfSelectedItems === items.length

    const refetchList = () => {
        setMetaItems({ ...metaItems, current_page: 1 })
        fetchItems({ page: 1 })
    }

    const fetchMultiAction = (ids, action, targetId) => {
        setIsLoadingAction(true)

        return fetch
            .postRAW("/multi-actions", {
                ids: ids,
                model: resourceChildrenModelName,
                action: action,
                payload: {
                    target_id: targetId
                }
            })
            .finally(() => setIsLoadingAction(false))
    }

    const handleReorder = newList => {
        setItems(newList)

        fetchMultiAction(newList.map(({ id }) => id), ACTION_TYPES.SORT)
    }
    const handleSearch = ({ event }) => setQuery(event.target.value)
    const handleSelectPage = page => setMetaItems({ ...metaItems, current_page: page })
    const handleCheckAll = () => setItems(items.map(item => ({ ...item, checked: !areSomeChecked })))
    const handleCheck = id => setItems(items.map(item => (item.id === id ? { ...item, checked: !item.checked } : item)))

    const handleItemAction = (actionType, item) => {
        setFocusedItem(item)
        setOpenModal(actionType)
    }

    const handleHeaderAction = actionType => {
        setOpenModal(actionType)
    }

    const handleTransferItems = target => {
        const ids = focusedItem ? [focusedItem.id] : items.filter(item => item.checked).map(item => item.id)
        const link =
            "/" +
            (type === LIST_TYPES.SUBGROUP
                ? buildLink({ groupId: target.id })
                : type === LIST_TYPES.ATTRIBUTE
                ? buildLink({ groupId: target.parent_attribute_group_id, subgroupId: target.id })
                : buildLink({
                      groupId: target.group.parent_attribute_group_id,
                      subgroupId: target.group.id,
                      attributeId: target.id
                  }))

        fetchMultiAction(ids, openModal, target.id)
            .then(() => {
                actions.showSuccessNotification({
                    title: (
                        <MessageTransferNotificationWithLink
                            link={link}
                            textLink={target.name}
                            typeTransfer={openModal}
                            numberOfItems={ids.length}
                            typeItem={type}
                        />
                    )
                })
                handleToggleModal()
                refetchList()
            })
            .catch(err => actions.showErrorNotification(err.statusText))
    }

    const handleDelete = () =>
        fetch
            .deleteRAW(`${resourceChildrenUrl}/${focusedItem.id}`)
            .then(() => {
                actions.showSuccessNotification({ data: { title: "Deleted" } })
                handleToggleModal()
                refetchList()
            })
            .catch(err => actions.showErrorNotification(err.statusText))

    const handleCreate = payload => {
        if (type === LIST_TYPES.ATTRIBUTE) {
            return fetchMultiAction(payload.map(({ id }) => id), ACTION_TYPES.COPY, resourceChildrenParams.group_id)
                .then(() => {
                    actions.showSuccessNotification()
                    handleToggleModal()
                    refetchList()
                })
                .catch(err => actions.showErrorNotification(err.statusText))
        }

        if ((type === LIST_TYPES.GROUP || type === LIST_TYPES.SUBGROUP) && !Array.isArray(payload)) {
            const {
                data: { id, parent_attribute_group_id }
            } = payload

            return history.push(
                parent_attribute_group_id
                    ? `/settings/attribute-groups/${parent_attribute_group_id}/subgroups/${id}/attributes`
                    : `/settings/attribute-groups/${id}/subgroups`
            )
        }

        refetchList()
    }

    const handleToggleModal = type => {
        setOpenModal(type)

        !type && setFocusedItem(null)
    }

    const handleModalAction = (type, payload) => {
        switch (type) {
            case ACTION_TYPES.DELETE:
                return handleDelete()
            case ACTION_TYPES.CREATE:
                return handleCreate(payload)
            case ACTION_TYPES.EDIT:
            case ACTION_TYPES.CREATE_ATTRIBUTE:
                return refetchList()
            case ACTION_TYPES.MOVE:
            case ACTION_TYPES.COPY:
                return handleTransferItems(payload)
            default:
                // TODO: add notification
                console.log(type, payload)
        }
    }

    const handleOpenAttributeModal = () => {
        setFocusedItem({ type: "dropdown" })
        handleToggleModal(ACTION_TYPES.CREATE_ATTRIBUTE)
    }

    return (
        <Layout
            navigation={<Navigation />}
            header={
                <Header
                    title={getHeaderTitle(resource)}
                    titleAddButton={titleAddButton}
                    numberOfItems={metaItems.total}
                    numberOfSelectedItems={numberOfSelectedItems}
                    handleAction={handleHeaderAction}
                    isMovementActionsDisabled={isMovementActionsDisabled}
                />
            }
            list={
                <List
                    items={items}
                    metaItems={metaItems}
                    query={query}
                    fetchStatusItems={fetchStatusItems}
                    areAllChecked={areAllChecked}
                    areSomeChecked={areSomeChecked}
                    isDragDisabled={isDragDisabled}
                    placeholderSearch={placeholderSearch}
                    handleSelectPage={handleSelectPage}
                    handleSearch={handleSearch}
                    handleReorder={handleReorder}
                    handleCheckAll={handleCheckAll}
                    handleDelete={handleDelete}
                    HeaderComponent={() => <HeaderComponent items={items} />}
                >
                    {items.map(item => (
                        <ItemComponent
                            key={item.id}
                            item={item}
                            handleCheck={handleCheck}
                            isDragDisabled={isDragDisabled}
                            handleAction={handleItemAction}
                        />
                    ))}
                </List>
            }
            modals={
                <ModalsWrapper
                    listType={type}
                    oneItem={focusedItem}
                    manyItems={items.filter(item => item.checked)}
                    openModal={openModal || ""}
                    isLoadingAction={isLoadingAction}
                    handleAction={handleModalAction}
                    handleToggleModal={handleToggleModal}
                    handleOpenAttributeModal={handleOpenAttributeModal}
                    resource={resource}
                    fetchStatusResource={fetchStatusResource}
                />
            }
        />
    )
}

DataLayer.defaultProps = {
    resourceParams: {}
}

DataLayer.propTypes = {
    getHeaderTitle: PropTypes.func.isRequired,
    parseItem: PropTypes.func.isRequired,
    HeaderComponent: PropTypes.func.isRequired,
    ItemComponent: PropTypes.func.isRequired,
    Navigation: PropTypes.func.isRequired,
    resourceUrl: PropTypes.string,
    resourceChildrenUrl: PropTypes.string.isRequired,
    resourceChildrenModelName: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(LIST_TYPES)).isRequired,
    titleAddButton: PropTypes.string,
    resourceChildrenParams: PropTypes.object,
    placeholderSearch: PropTypes.string,
    isMovementActionsDisabled: PropTypes.bool
}

export default DataLayer
