import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./body.css"

const Body = props => {
    const { cx, className, children } = props

    return <div className={cx("root", className)}>{children}</div>
}

Body.defaultProps = {
    className: ""
}

Body.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number])
}

export default withStyles(Body, styles)
