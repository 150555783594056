import { combineReducers } from "redux"

import current from "./current"
import list from "./list"
import products from "./products"

export default combineReducers({
    current,
    list,
    products
})
