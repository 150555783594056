import React, { useState, useEffect, useRef, Fragment } from "react"
import getStyles from "helpers/getCxFromStyles"
import styles from "./dropdown.module.css"
import { statusesConfig, statusTypes, statusValues } from "../config"
import PropTypes from "prop-types"
import { SubmitModal } from "ui"

const ESCAPE_KEY_CODE = 27

const Dropdown = props => {
    const cx = getStyles(styles)
    const { type, status, onChange, isListOnTop = false, isDisabled = false } = props
    const [isOpen, setOpen] = useState(false)
    const [modalOptions, setModalOptions] = useState({ isOpen: false, statusText: "", status: "" })
    const node = useRef()
    const config = statusesConfig[type][status]

    useEffect(() => {
        const handleOutsideClick = event => {
            if (node.current && !node.current.contains(event.target)) {
                setOpen(false)
            }
        }

        const handleKeyDown = event => {
            if (isOpen && event.keyCode === ESCAPE_KEY_CODE) {
                setOpen(false)
            }
        }

        document.addEventListener("keydown", handleKeyDown, true)
        document.addEventListener("click", handleOutsideClick, true)
        return () => {
            document.removeEventListener("keydown", handleKeyDown, true)
            document.removeEventListener("click", handleOutsideClick, true)
        }
    }, [isOpen])

    useEffect(() => {
        setOpen(false)
    }, [type, status])

    if (!config || !config.dropdown || !Object.keys(config.dropdown).length) {
        return null
    }

    const { text, canChangeTo, hasLastItemListBorder } = config.dropdown

    const changeStatus = status => {
        onChange(status)
        setOpen(false)
    }

    const handleClick = () => {
        if (!isDisabled) {
            setOpen(!isOpen)
        }
    }

    const { circle, ...dropdownStyles } = config.getStyles()

    return (
        <div className={cx("root", { isOpen, isListOnTop, isDisabled })} ref={node}>
            <div className={cx("label")} style={dropdownStyles} onClick={handleClick}>
                <span className={cx("circle")} style={{ backgroundColor: circle }} />
                <span>{text}</span>
                <div className={cx("arrow")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.847 451.847">
                        <g>
                            <path
                                d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"
                                className={cx("withFill")}
                            />
                        </g>
                    </svg>
                </div>
            </div>

            {isOpen && (
                <ul className={cx("list")}>
                    {canChangeTo.map((curr, index, arr) => {
                        const { isConfirmRequired, text } = ((statusesConfig[type] || {})[curr] || {}).dropdown || {}
                        const onClick = isConfirmRequired
                            ? () => setModalOptions({ isOpen: true, statusText: text, status: curr })
                            : () => changeStatus(curr)
                        return (
                            <li
                                key={curr}
                                onClick={onClick}
                                className={cx({ isLast: hasLastItemListBorder && index === arr.length - 1 })}
                            >
                                {text}
                            </li>
                        )
                    })}
                </ul>
            )}

            <ChangeStatusConfirmationModal
                modalOptions={modalOptions}
                setModalOptions={setModalOptions}
                changeStatus={changeStatus}
            />
        </div>
    )
}

const ChangeStatusConfirmationModal = ({ modalOptions, setModalOptions, changeStatus }) => {
    const { isOpen, statusText, status } = modalOptions
    const closeModal = () => setModalOptions({ ...modalOptions, isOpen: false })

    return (
        <SubmitModal
            isOpen={isOpen}
            preventClickOutside
            header="Do you really want to change status?"
            body={
                <Fragment>
                    Are you sure you want to change status to <strong>{statusText}</strong>?
                </Fragment>
            }
            submitText="Change"
            onClose={closeModal}
            onSubmit={() => {
                changeStatus(status)
                closeModal()
            }}
        />
    )
}

Dropdown.propTypes = {
    type: PropTypes.oneOf(statusTypes).isRequired,
    isDisabled: PropTypes.bool,
    status: PropTypes.oneOf(statusValues).isRequired,
    onChange: PropTypes.func.isRequired,
    isListOnTop: PropTypes.bool
}

export default Dropdown
