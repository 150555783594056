import React from "react"
import PropTypes from "prop-types"

import { getBackground } from "ui/ColorPicker/helpers"
import checkedIcon from "ui/ColorPicker/assets/checked.svg"
import useStyles from "helpers/getCxFromStyles"

import styles from "./ColorTile.module.css"

function ColorTile(props) {
    const {
        color,
        texture,
        name,
        handleHover,
        handleMouseOut,
        id,
        disabled,
        small,
        handleSelectTile,
        selected,
        customStyles
    } = props
    const cx = useStyles(styles, customStyles)

    const background = getBackground({ color, texture })

    const handleMouseOver = () => {
        handleHover({ name, background })
    }

    const handleClick = () => {
        handleSelectTile({ id, name, background, disabled })
    }

    return (
        <div className={cx("tile-container", { disabled })}>
            {!disabled && (
                <div
                    onClick={handleClick}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                    style={getBackground({ color, texture })}
                    className={cx(["tile", "active-tile"], { selected, small })}
                >
                    {selected && <img className={cx("checked")} src={checkedIcon} alt="" />}
                </div>
            )}
            {disabled && <div style={getBackground({ color, texture })} className={cx(["tile", "disabled"])}></div>}
        </div>
    )
}

ColorTile.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    color: PropTypes.string,
    texture: PropTypes.string,
    name: PropTypes.string,
    handleHover: PropTypes.func.isRequired,
    handleMouseOut: PropTypes.func.isRequired,
    handleSelectTile: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    small: PropTypes.bool,
    disabled: PropTypes.bool,
    customStyles: PropTypes.object
}

export default ColorTile
