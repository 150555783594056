import * as constants from "./constants"
import * as globalConstants from "constants/index"

import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"
import { updateManualEntryList } from "./helpers"

export { shouldFetch } from "helpers/fetchStatus"

export function getManualEntriesStart() {
    return {
        type: constants.GET_START
    }
}

export function getManualEntriesSuccess(data) {
    return {
        type: constants.GET_SUCCESS,
        payload: {
            data
        }
    }
}

export function getManualEntriesError(error) {
    return {
        type: constants.GET_ERROR,
        payload: {
            error
        }
    }
}

export function getManualEntries(params = {}) {
    return (dispatch, getState) => {
        const lastFetched = getState().db.manualEntries.lastFetched.data
        const manualEntries = getState().db.manualEntries.list

        if (
            !!lastFetched &&
            ((params.page === 1 && manualEntries.meta.current_page === 1) ||
                !!manualEntries.data.find(entry => entry.id === lastFetched.id))
        ) {
            dispatch(getManualEntriesSuccess(updateManualEntryList(manualEntries, lastFetched)))
            dispatch(resetLastFetchedManualEntry())
            return new Promise(res => res())
        }

        dispatch(getManualEntriesStart())
        return fetch
            .get(
                `/products?${objToQueryString({
                    page: 1,
                    manual_entries: true,
                    length: globalConstants.LENGTH_WITHOUT_PAGINATION,
                    ...params
                })}`
            )
            .then(data => {
                const newData = !!lastFetched ? updateManualEntryList(data, lastFetched) : data
                dispatch(getManualEntriesSuccess(newData))
                dispatch(resetLastFetchedManualEntry())
            })
            .catch(error => dispatch(getManualEntriesError(error)))
    }
}

export function resetManualEntries() {
    return {
        type: constants.RESET
    }
}

export function deleteManualEntry(id) {
    return fetch.deleteRAW(`/products/${id}`)
}

export const createManualEntry = data => {
    return fetch.post(`/product`, data)
}

export const updateManualEntry = ({ data, id }) => {
    return fetch.patch(`/product/${id}`, data)
}

export function changePagination(page) {
    return {
        type: constants.CHANGE_PAGINATION,
        payload: { page }
    }
}

export function multiSelectDelete(ids) {
    return fetch.postRAW("/multi-actions", {
        ids,
        model: "products",
        action: "delete"
    })
}

export function convertManualEntryToProduct(id) {
    return fetch.postRAW(`/products/${id}/convert-to-standard`)
}

export function multiSelectConvertManualEntriesToProduct(ids) {
    return fetch.postRAW("/multi-actions", {
        ids,
        model: "products",
        action: "convert"
    })
}

export function setLastFetchedManualEntry(data) {
    return {
        type: constants.SET_LAST_FETCHED,
        payload: data
    }
}

export function resetLastFetchedManualEntry() {
    return {
        type: constants.RESET_LAST_FETCHED,
        payload: {}
    }
}
