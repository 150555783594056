import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import addIcon from "assets/add.svg"
import styles from "./Header.module.css"
import { Button, Dropdown } from "ui"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

const Header = ({ count, cx, isLoaded, handleAddPayment, canAdd }) => {
    const exportOptions = []

    return (
        <header className={cx("header")}>
            <h2 className={cx("title")}>Payments</h2>
            {isLoaded && <span className={cx("count")}>{count}</span>}
            <div className={cx("buttons")}>
                {count > 0 && (
                    <Dropdown disabled className={cx("dropdown")} label="Export all" options={exportOptions} />
                )}
                {typeof handleAddPayment === "function" && canAdd() && (
                    <Button
                        className={`first-button ${cx("button")}`}
                        icon={addIcon}
                        label="Add new payment"
                        onClick={() => handleAddPayment()}
                    />
                )}
            </div>
        </header>
    )
}

Header.defaultProps = {
    count: 0,
    isLoaded: false
}

Header.propTypes = {
    count: PropTypes.number,
    handleAddPayment: PropTypes.func,
    canAdd: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool
}

export default withPermissions(withStyles(Header, styles), PERMISSIONS.context.PAYMENTS)
