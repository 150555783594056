import React from "react"
import idx from "idx"
import { Input } from "ui"
import withStyles from "HOC/withStyles"
import styles from "./InputRangeAmount.module.css"
import stylesInput from "./overrides/input.css"

const InputRangeAmount = ({ cx, handleSelect, values, error }) => {
    return (
        <div className={cx("inputRange")}>
            <label>Amount Range</label>
            <div className={cx("inputs")}>
                <Input
                    type="number"
                    min={0}
                    isPlaceholder={true}
                    label="min"
                    innerLabel={"$"}
                    customStyles={stylesInput}
                    value={idx(values, _ => _.amount_min)}
                    onChange={({ event }) => handleSelect("amount_min", event.target.value)}
                    error={error && error.amount_min}
                />
                <span className={cx("inputsSeparator")}>-</span>
                <Input
                    type="number"
                    min={0}
                    isPlaceholder={true}
                    value={idx(values, _ => _.amount_max)}
                    label="max"
                    innerLabel={"$"}
                    customStyles={stylesInput}
                    onChange={({ event }) => handleSelect("amount_max", event.target.value)}
                    error={error && error.amount_max}
                />
            </div>
        </div>
    )
}

export default withStyles(InputRangeAmount, styles)
