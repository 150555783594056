import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getPortfolioPostStart() {
    return {
        type: constants.GET_PORTFOLIO_POST_START
    }
}
export function getPortfolioPostSuccess(data) {
    return {
        type: constants.GET_PORTFOLIO_POST_SUCCESS,
        payload: {
            data
        }
    }
}
export function getPortfolioPostError(error) {
    return {
        type: constants.GET_PORTFOLIO_POST_ERROR,
        payload: {
            error
        }
    }
}
export function getPortfolioPost(id) {
    return dispatch => {
        dispatch(getPortfolioPostStart())
        fetch
            .get(`/realizations/${id}`)
            .then(data => dispatch(getPortfolioPostSuccess(data)))
            .catch(error => dispatch(getPortfolioPostError(error)))
    }
}

export function clearPortfolioPost() {
    return {
        type: constants.CLEAR_PORTFOLIO_POST
    }
}

export const getPortfolioStart = () => ({ type: constants.GET_PORTFOLIO_START })
export const getPortfolioSuccess = data => ({ type: constants.GET_PORTFOLIO_SUCCESS, payload: { data } })
export const getPortfolioError = error => ({ type: constants.GET_PORTFOLIO_ERROR, payload: { error } })

export const getPortfolio = ({
    query = "",
    pagination = { length: constants.LENGTH_WITHOUT_PAGINATION, page: 1 },
    sorting = null
}) => dispatch => {
    dispatch(getPortfolioStart())

    return fetch
        .get(
            `/realizations?query=${query}&length=${pagination.length}&page=${pagination.page}${
                sorting ? `&sort_by=${sorting.by}&sort_direction=${sorting.direction}` : ""
            }`
        )
        .then(data => dispatch(getPortfolioSuccess(data)))
        .catch(error => dispatch(getPortfolioError(error)))
}
