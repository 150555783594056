import React, { useState, useEffect } from "react"
import { SelectWithSearch } from "ui"
import fetch from "helpers/fetch"

const SelectShowroom = props => {
    const { name, value, label, error, customStyles } = props
    const [showrooms, setShowrooms] = useState([])

    useEffect(() => {
        fetchCompanies()
    }, [])

    const fetchCompanies = () => {
        const formatData = data => {
            return data.map(item => ({ id: item.id, value: item.id, label: item.name, secondLabel: "" }))
        }

        fetch.get(`/companies?page=1&length=9999`).then(payload => {
            setShowrooms(formatData(payload.data))
        })
    }

    return (
        <SelectWithSearch
            multiselect
            name={name}
            label={label}
            value={value}
            values={showrooms}
            error={error}
            setValue={value => props.handleSelect({ target: { name, value } })}
            placeholder="- Please select -"
            customStyles={customStyles}
            withoutFetch
        />
    )
}

export default SelectShowroom
