import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from "react-router-dom"
import withPermissions from "HOC/withPermissions"

import * as newsActions from "actions/news"
import * as blogActions from "actions/blog"
import * as portfolioActions from "actions/portfolio"

import ListingLayout from "../../NewsListingLayout"
import View from "./View"

class ViewContainer extends Component {
    state = {
        isBlog: false,
        isPortfolio: false
    }

    componentDidMount() {
        const { routePrefix, match, getPost, getBlogPost, getPortfolioPost } = this.props
        const { blogId, portfolioId, newsId } = match.params
        const isBlog = routePrefix.includes("content/blog")
        const isPortfolio = routePrefix.includes("content/portfolio")

        if (isBlog) {
            this.setState({
                isBlog: true
            })
            getBlogPost(blogId)
        }

        if (isPortfolio) {
            this.setState({
                isPortfolio: true
            })
            getPortfolioPost(portfolioId)
        }

        if (!isBlog && !isPortfolio) {
            getPost(newsId)
        }
    }

    componentDidUpdate(prevProps) {
        const isDescriptionRendered = !prevProps.post.description && this.props.post.description
        if (isDescriptionRendered) {
            const addStyleToClass = (className, key, style) => {
                const elementsWithClass = document.getElementsByClassName(className)
                Array.prototype.slice.call(elementsWithClass).forEach(el => {
                    el.style[key] = style
                })
            }
            ;[
                { className: "ql-align-center", key: "textAlign", style: "center" },
                { className: "ql-align-justify", key: "textAlign", style: "justify" },
                { className: "ql-align-right", key: "textAlign", style: "right" }
            ].forEach(({ className, key, style }) => {
                addStyleToClass(className, key, style)
            })
        }
    }

    componentWillUnmount() {
        const { clearPost, clearBlogPost, clearPortfolioPost } = this.props
        clearPost()
        clearBlogPost()
        clearPortfolioPost()
    }

    render() {
        const { isBlog, isPortfolio } = this.state
        const { canEdit, PERMISSIONS } = this.props
        const { post, isLoadingPost, blogPost, isLoadingBlogPost, portfolioPost, isLoadingPortfolioPost } = this.props
        const is404Error =
            (post.error && post.error.status) === 404 ||
            (blogPost.error && blogPost.error.status) === 404 ||
            (portfolioPost.error && portfolioPost.error.status) === 404

        let postData = post
        let isLoading = isLoadingPost
        let returnPath = "/news"
        let userCanEdit = canEdit(PERMISSIONS.context.NEWS)
        if (isBlog) {
            postData = blogPost
            isLoading = isLoadingBlogPost
            returnPath = "/content/blog"
            userCanEdit = canEdit(PERMISSIONS.context.BLOG_POSTS)
        }
        if (isPortfolio) {
            postData = portfolioPost
            isLoading = isLoadingPortfolioPost
            returnPath = "/content/portfolio"
            userCanEdit = canEdit(PERMISSIONS.context.PORTFOLIO_POSTS)
        }

        if (is404Error) {
            return <Redirect to="/404" />
        }

        const returnLink = {
            label: "All news",
            link: returnPath
        }

        return (
            <ListingLayout returnLink={returnLink}>
                <View
                    data={postData}
                    userCanEdit={userCanEdit}
                    isLoading={isLoading}
                    isBlog={isBlog}
                    isPortfolio={isPortfolio}
                />
            </ListingLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        post: state.db.news.current.data,
        isLoadingPost: state.db.news.current.isLoading,

        blogPost: state.db.blog.current.data,
        isLoadingBlogPost: state.db.blog.current.isLoading,

        portfolioPost: state.db.portfolio.current.data,
        isLoadingPortfolioPost: state.db.portfolio.current.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPost: id => dispatch(newsActions.getPost(id)),
        getBlogPost: id => dispatch(blogActions.getBlogPost(id)),
        getPortfolioPost: id => dispatch(portfolioActions.getPortfolioPost(id)),
        clearPost: () => dispatch(newsActions.clearPost()),
        clearBlogPost: () => dispatch(blogActions.clearBlogPost()),
        clearPortfolioPost: () => dispatch(portfolioActions.clearPortfolioPost())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPermissions(ViewContainer))
