import React, { useEffect } from "react"
import PropTypes from "prop-types"

import Dropzone from "ui/Dropzone"

import withUploader from "HOC/withUploader"
import withStyles from "HOC/withStyles"
import { createHintText } from "modules/ContentModule/helpers"
import styles from "./ImageFormUploader.css"
import dropzoneStyles from "./overrides/DropzoneStyle.css"
import cloudSvg from "assets/cloud-dark.svg"
import deleteSvg from "assets/delete.svg"

const ImageFormUploader = props => {
    const { cx, uploader, value, onSuccess, removeFile, imageDimensions } = props
    const { state, actions } = uploader
    const { isUploading, isSuccess, progress, addedFile } = state

    useEffect(() => {
        !isUploading && isSuccess && progress === 100 && addedFile && onSuccess({ ...addedFile, name: addedFile.title })
    }, [isUploading, isSuccess, progress, addedFile])

    if (isUploading) {
        return (
            <div className={cx("dropzoneStateUploading")}>
                <div style={{ width: `${progress}%` }} />
            </div>
        )
    }

    if (value) {
        return (
            <div className={cx("dropzoneStateFile")}>
                <div className={cx("imageWrapper")}>
                    <img src={value.url} alt="thumbnail" />
                </div>
                <div className={cx("bottomInfoWrapper")}>
                    <span>{value.name}</span>
                    <button onClick={removeFile}>
                        <img src={deleteSvg} alt="close" />
                    </button>
                </div>
            </div>
        )
    }

    return (
        <Dropzone
            isUploading={isUploading}
            isSuccess={isSuccess}
            progress={progress}
            handleUpload={actions.handleUpload}
            handleRemove={actions.handleRemove}
            isMultiple={false}
            customStyles={dropzoneStyles}
        >
            <p className={cx("dropzoneStateEmpty")}>
                <img src={cloudSvg} alt="cloud" />
                Upload image <span>{createHintText(imageDimensions)}</span>
            </p>
        </Dropzone>
    )
}

ImageFormUploader.propTypes = {
    value: PropTypes.shape({
        id: PropTypes.number.isRequired,
        url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }),
    onSuccess: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    imageDimensions: PropTypes.object
}

export default withUploader(withStyles(ImageFormUploader, styles))
