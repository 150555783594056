import * as constants from "constants/index"

function brands(state = [], action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_BRANDS_FOR_FILTERS:
            return []
        case constants.PUT_BRANDS_FOR_FILTERS:
            return [...value]
        default:
            return state
    }
}

export default brands
