import { useCallback } from "react"
import { useDispatch } from "react-redux"

import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import { getOrderSuccess } from "actions/orders"

const useRemoveReturnedStockItemsFromOrder = order => {
    const dispatch = useDispatch()

    return useCallback(
        (shipmentId, returnShipment) => {
            if (returnShipment.status.status === SHIPMENT_STATUS_KEYS.CANCELLED) {
                const returnedStockItemIds = returnShipment.items.map(item => item.id)
                const updatedOrderItems = order.items.map(item => ({
                    ...item,
                    returned_stock_items: item.returned_stock_items.filter(
                        returnedStockItemId => !returnedStockItemIds.includes(returnedStockItemId)
                    )
                }))
                dispatch(
                    getOrderSuccess({
                        data: { ...order, items: updatedOrderItems }
                    })
                )
            }
        },
        [order]
    )
}

export default useRemoveReturnedStockItemsFromOrder
