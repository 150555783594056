import React, { Component, Fragment } from "react"
import { Dropzone } from "ui"
import PropTypes from "prop-types"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"

import withMultipleUploader from "HOC/withMultipleUploader"
import withStyles from "HOC/withStyles"

import getReadableFileSize from "helpers/getReadableFileSize"

import styles from "./styles.css"

export const UploadBar = ({ className = styles.start, header = "Add file" }) => {
    return (
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 25">
                <g fill="none" fillRule="evenodd">
                    <path d="M3 3.303h18v18H3z" />
                    <path
                        fill="#dee1e4"
                        fillRule="nonzero"
                        d="M17.512 10.833A5.617 5.617 0 0 0 12 6.303a5.622 5.622 0 0 0-4.987 3.03A4.496 4.496 0 0 0 3 13.803c0 2.482 2.018 4.5 4.5 4.5h9.75c2.07 0 3.75-1.68 3.75-3.75a3.732 3.732 0 0 0-3.488-3.72zm-4.012 2.22v3h-3v-3H8.25L12 9.303l3.75 3.75H13.5z"
                    />
                </g>
            </svg>
            <h3>{header}</h3>
            <p>(browse or drop your files here)</p>
        </div>
    )
}

class FilesUploader extends Component {
    static defaultProps = {
        acceptFormats: "",
        isMultiple: true,
        endpoint: DEFAULT_FILE_UPLOAD_ENPOINT
    }

    render() {
        const {
            cx,
            acceptFormats,
            isMultiple,
            disabled,
            uploader: { actions, state }
        } = this.props

        return (
            <div className={cx("root")}>
                <Dropzone
                    isUploading={state.isUploading}
                    isSuccess={state.isSuccess}
                    progress={state.progress}
                    handleUpload={actions.handleUpload}
                    handleRemove={actions.handleRemove}
                    acceptFormats={acceptFormats}
                    isMultiple={isMultiple}
                    disabled={disabled}
                >
                    <Fragment>
                        {state.error && state.error[0].length > 0 && <p className={cx("error")}>{state.error[0]}</p>}
                        <UploadBar />
                    </Fragment>
                </Dropzone>
                {this.renderList()}
            </div>
        )
    }

    renderList = () => {
        const { renderListComponent, cx, attachments, uploader } = this.props

        if (typeof renderListComponent === "function") {
            return renderListComponent(this.props)
        }

        return (
            <ul className={cx("uploader")}>
                {attachments.map((el, index) => this.renderFile(index, el))}
                {uploader.state.isUploading && this.renderUploading()}
            </ul>
        )
    }

    renderUploading = () => {
        const {
            cx,
            uploader: { state }
        } = this.props

        return state.addedFile.map(({ title, progress, file }, index) => {
            return progress < 100 ? (
                <li className={cx("uploadingFile")} key={-index - 1}>
                    <p className={cx("title")}>{title}</p>
                    <div className={cx("progress")}>
                        <div style={{ width: `${progress}%` }} />
                    </div>
                </li>
            ) : (
                this.renderFile(-index - 1, file)
            )
        })
    }

    renderFile = (index, file) => {
        const { cx } = this.props
        const { size, name } = file

        return (
            <li key={index}>
                <p>{name}</p>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    onClick={event => this.handleRemove(event, index)}
                >
                    <g fill="none" fillRule="evenodd">
                        <path d="M3 3h18v18H3z" />
                        <path
                            fill="#4383CD"
                            fillRule="nonzero"
                            d="M17.25 7.808L16.193 6.75 12 10.942 7.808 6.75 6.75 7.807 10.943 12 6.75 16.193l1.058 1.057L12 13.057l4.193 4.193 1.057-1.057L13.057 12z"
                        />
                    </g>
                </svg>
                <span className={cx("size")}>{getReadableFileSize(size)}</span>
            </li>
        )
    }

    handleRemove = (event, index) => {
        event.stopPropagation()
        this.props.handleRemove(index)
    }
}

FilesUploader.propTypes = {
    endpoint: PropTypes.string.isRequired,
    acceptFormats: PropTypes.string.isRequired,
    isMultiple: PropTypes.bool.isRequired,
    attachments: PropTypes.array.isRequired,
    handleRemove: PropTypes.func.isRequired,
    handleUploadFile: PropTypes.func,
    handleUploadSuccess: PropTypes.func,
    renderListComponent: PropTypes.func
}

export default withMultipleUploader(withStyles(FilesUploader, styles))
