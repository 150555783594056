import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./Address.css"

class Address extends Component {
    render() {
        const {
            first_name,
            last_name,
            company,
            post_code,
            city,
            state,
            address,
            type,
            fullProfile,
            country
        } = this.props
        let addressType
        if (type === "personal") addressType = "Main"
        else if (type === "delivery") addressType = "Shipping"
        else addressType = "Billing"

        return (
            <div styleName={fullProfile ? "address-cover-full-profile" : "address-cover"}>
                <h5 styleName="address-text">{addressType} Address:</h5>
                <div styleName="address-content">
                    <h5 styleName="address-property">{company}</h5>
                    {company && <br />}
                    <h5 styleName="address-property">{first_name} </h5>
                    <h5 styleName="address-property">{last_name}</h5>
                    {last_name && <br />}
                    <h5 styleName="address-property">{address}</h5>
                    {address && <br />}
                    <h5 styleName="address-property-comma">{city}</h5>
                    <h5 styleName="address-property-comma">{state}</h5>
                    <h5 styleName="address-property">{post_code}</h5>
                    {post_code && <br />}
                    <h5 styleName="address-property">{country}</h5>
                    <div className="clear" />
                </div>
            </div>
        )
    }
}

export default CSSModules(Address, style)
