import React, { Fragment, useRef } from "react"
import { Field } from "formik"
import useOnClickOutside from "use-onclickoutside"
import { countSelectedFilters } from "helpers/form"
import useStyles from "helpers/getCxFromStyles"

import { Button, Toggle } from "ui"
import { MoreFilters } from "ui/FilterableTable"
import { SelectClient, SelectUser, SelectProject, SelectShowroom, SelectSource } from "ui/Filters"

import styles from "./styles.css"
import selectStyles from "./overrides/selectStyles.css"
import submitButtonStyles from "./overrides/submitButton.css"
import moreFiltersStyles from "./overrides/moreFilters.css"
import toggleStyles from "./overrides/toggle.css"

const Filters = props => {
    const cx = useStyles(styles)
    const expandMenuRef = useRef(null)
    const { values, handleAction, isVisible, handleOnlyMine } = props
    const { isOnlyMine, ...restValues } = values

    useOnClickOutside(expandMenuRef, () => isVisible && handleAction("unsave"))

    return (
        <Fragment>
            <div className={cx("root")} ref={expandMenuRef}>
                <MoreFilters
                    numberOfSelectedFilters={countSelectedFilters(restValues)}
                    isVisible={isVisible}
                    toggleVisible={() => handleAction("unsave")}
                    customStyles={moreFiltersStyles}
                >
                    <div className={cx("container")}>
                        <div className={cx("filters")}>
                            <div className={cx("column")}>
                                <Field className={cx("field")} name="users">
                                    {({ field: { name, value, onChange } }) => (
                                        <div className={cx("assignedTo")}>
                                            <Toggle
                                                isOn={isOnlyMine}
                                                label={{
                                                    on: "Only mine",
                                                    off: "Only mine"
                                                }}
                                                labelPosition="left"
                                                handleChange={state => handleOnlyMine(state, true)}
                                                customStyles={toggleStyles}
                                            />
                                            <SelectUser
                                                customStyles={selectStyles}
                                                handleSelect={event => {
                                                    handleOnlyMine(false, false)
                                                    onChange(event)
                                                }}
                                                label="Employee"
                                                name={name}
                                                value={value}
                                            />
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className={cx("column")}>
                                <Field name="divisions">
                                    {({ field: { name, value, onChange } }) => (
                                        <SelectShowroom
                                            label="Division"
                                            name={name}
                                            value={value || []}
                                            handleSelect={onChange}
                                            customStyles={selectStyles}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={cx("column")}>
                                <Field name="client">
                                    {({ field, form }) => (
                                        <SelectClient
                                            customStyles={selectStyles}
                                            form={form}
                                            field={field}
                                            label="Client"
                                            name={field.name}
                                            value={field.value}
                                            handleSelect={value =>
                                                field.onChange({ target: { name: field.name, value } })
                                            }
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={cx("column")}>
                                <Field name="project">
                                    {({ field: { name, value, onChange } }) => (
                                        <SelectProject
                                            label="Project"
                                            name={name}
                                            value={value ? value.label : ""}
                                            handleSelect={event =>
                                                onChange({ target: { name, value: event.target.value || "" } })
                                            }
                                            customStyles={selectStyles}
                                        />
                                    )}
                                </Field>
                            </div>
                            <div className={cx("column")}>
                                <Field name="sources">
                                    {({ field: { name, value, onChange } }) => (
                                        <SelectSource
                                            label="Source"
                                            name={name}
                                            value={value || []}
                                            handleSelect={onChange}
                                            customStyles={selectStyles}
                                        />
                                    )}
                                </Field>
                            </div>
                        </div>
                        <div className={cx("actions")}>
                            <Button
                                label="Show results"
                                onClick={() => handleAction("submit")}
                                className="first-button"
                                customStyles={submitButtonStyles}
                            />
                        </div>
                    </div>
                </MoreFilters>
            </div>
            {isVisible && (
                <div
                    className={cx("bluredBackground")}
                    style={{ height: document.documentElement.scrollHeight - 212 }}
                />
            )}
        </Fragment>
    )
}

export default Filters
