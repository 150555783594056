import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import ReactSvg from "react-svg"

import withStyles from "HOC/withStyles"
import Layout from "components/Reusable/Layout"
import { Button, ButtonWithDropdown, ActionButton } from "ui"
import Loader from "components/Reusable/Loader/Loader"
import ListContainer from "./ListContainer"
import Sendable from "./components/Sendable"
import { useIsBreakpoint, useIsTablet } from "helpers/rwd"

import Dropdown from "components/Reusable/Dropdown/index"
import DeleteProductsModal, { DeleteSingleProductModal } from "./components/DeleteProductsModal"
import { ChooseSelectionsModal } from "modules/SelectionsModule/components"
import AddToSelectionButtonContainer from "modules/SelectionsWidgetModule/components/AddToSelectionButtonContainer"
import ChooseProject from "components/Modal/ChooseProject"

import styles from "./Page.css"
import stylesLayout from "./overrides/Layout.css"
import buttonStyles from "./overrides/Button.css"
import stylesDropdown from "./overrides/Dropdown.css"
import buttonWithDropdownStyles from "./overrides/ButtonWithDropdown.css"
import addToProjectButtonStyles from "./overrides/AddToProjectButton.css"

import arrow from "assets/arrow_down_blue.svg"
import deleteIcon from "assets/close-blue.svg"
import pencilIcon from "assets/pencil.svg"
import iconPrinter from "assets/print.svg"
import iconMore from "assets/ico-more.svg"
import mailIcon from "assets/ico-mail_blue.svg"
import addSelectionIcon from "assets/ico-lightbox-add.svg"
import appsIcon from "assets/apps.svg"
import projectIcon from "assets/ico-projects.svg"

const Page = props => {
    const {
        cx,
        isFirstTime,
        products,
        canAdd,
        fastReviewIsModalOpen,
        fastReviewHandleCreate,
        checkedProducts,
        handleExport,
        handleMultiDelete,
        fastReviewIsLoading,
        isExporting,
        filtersUsed,
        isSendableModalOpenFor,
        resetCheckedProducts,
        setIsOpenAddToProjectModal
    } = props

    const isWindowWidthBelow1300 = useIsBreakpoint(1300)
    const isTablet = useIsTablet()

    if (isFirstTime && !products.fetchStatus.isLoaded) {
        return (
            <Layout title="Products" customStyles={stylesLayout}>
                <div className={cx("loader")}>
                    <Loader />
                </div>
            </Layout>
        )
    }

    const productCount = checkedProducts.length
    const hasCount = productCount > 0
    const dropdownElements = [{ label: "Export to CSV", value: "csv" }, { label: "Export to XLS", value: "xls" }]
    const hasCheckedProductWithCombinations = checkedProducts.some(product => product.has_combinations)

    function renderActions() {
        const { printAndSendItems, isPrinting, isSending, handlePrint, handleSend, dropDownItems } = props

        return (
            <span className={cx("actions")}>
                {!hasCount && !isTablet && (
                    <Link to="/apps" className={cx("appsButton")}>
                        <ActionButton icon={appsIcon} label="Scraper for Chrome" handleClick={() => {}} />
                    </Link>
                )}

                {hasCount && !isWindowWidthBelow1300 && (
                    <Fragment>
                        <button className={cx("deleteButton")} onClick={resetCheckedProducts}>
                            Unselect all
                        </button>

                        <button className={cx("deleteButton")} onClick={handleMultiDelete} key={1}>
                            <ReactSvg src={deleteIcon} /> Delete
                        </button>
                    </Fragment>
                )}

                {hasCount && (
                    <Button
                        icon={projectIcon}
                        label={<span>Project</span>}
                        isDisabled={hasCheckedProductWithCombinations}
                        onClick={() => setIsOpenAddToProjectModal(true)}
                        customStyles={addToProjectButtonStyles}
                    />
                )}

                {canAdd() && !isWindowWidthBelow1300 && (hasCount || filtersUsed) && (
                    <a onClick={fastReviewHandleCreate}>
                        <Button
                            customStyles={buttonStyles}
                            isLoading={fastReviewIsLoading && !fastReviewIsModalOpen}
                            label="Edit"
                            icon={!(fastReviewIsLoading && !fastReviewIsModalOpen) && pencilIcon}
                        />
                    </a>
                )}

                <Dropdown
                    customStyles={stylesDropdown}
                    isLoading={isExporting}
                    values={dropdownElements.map(element => {
                        return {
                            value: element.value,
                            label: element.label
                        }
                    })}
                    defaultValue={hasCount ? "Export" : "Export all"}
                    isLabeledValue={true}
                    isLabel={false}
                    arrow={arrow}
                    onClick={event => handleExport(event)}
                />

                {canAdd() && !(hasCount || filtersUsed) && (
                    <Link to="/products/add">
                        <Button className={`first-button ${cx("first-button")}`} label="Create New" />
                    </Link>
                )}

                {hasCount && (
                    <ButtonWithDropdown
                        label="Print"
                        items={printAndSendItems(handlePrint)}
                        icon={iconPrinter}
                        isLoading={isPrinting}
                        customStyles={buttonWithDropdownStyles}
                    />
                )}

                {hasCount && (
                    <ButtonWithDropdown
                        label="Send"
                        className={isWindowWidthBelow1300 ? "" : "blue"}
                        icon={isWindowWidthBelow1300 ? mailIcon : null}
                        items={printAndSendItems(handleSend)}
                        isLoading={isSending}
                        customStyles={buttonWithDropdownStyles}
                    />
                )}

                {hasCount && (
                    <AddToSelectionButtonContainer isMulti={true}>
                        {({ handleClick, lock }) => (
                            <Button
                                className={`first-button ${cx("multiselectSelectionButton", { locked: lock })}`}
                                label={
                                    <ReactSvg className={cx("multiselectSelectionButtonIcon")} src={addSelectionIcon} />
                                }
                                onClick={() => {
                                    handleClick(
                                        products.data.filter(product =>
                                            checkedProducts.some(({ id: productId }) => product.id === productId)
                                        )
                                    )
                                }}
                            />
                        )}
                    </AddToSelectionButtonContainer>
                )}

                {(hasCount || filtersUsed) && isWindowWidthBelow1300 && (
                    <ButtonWithDropdown
                        items={dropDownItems}
                        icon={iconMore}
                        isLoading={fastReviewIsLoading && !fastReviewIsModalOpen}
                        customStyles={buttonWithDropdownStyles}
                        className={isWindowWidthBelow1300 ? "only-icon" : ""}
                    />
                )}
            </span>
        )
    }

    function renderDeleteProductsConfirmModal() {
        const { multiProductDeleteState, toggleMultiProductDelete, handleMultiProductDelete } = props

        return (
            <DeleteProductsModal
                cx={cx}
                isOpen={multiProductDeleteState.isOpen}
                isLoading={multiProductDeleteState.isSaving}
                onCancel={() => toggleMultiProductDelete([])}
                onSubmit={() => handleMultiProductDelete()}
                productsCount={multiProductDeleteState.productIds.length}
            />
        )
    }

    function renderDeleteSingleProductConfirmModal() {
        const { toDeleteProduct, setToDeleteProduct, products, isDeleting, handleConfirmDelete } = props

        return (
            <DeleteSingleProductModal
                cx={cx}
                isOpen={toDeleteProduct !== null}
                isLoading={isDeleting}
                onCancel={() => setToDeleteProduct(null)}
                onSubmit={() => handleConfirmDelete(toDeleteProduct)}
                productName={(products.data.find(({ id }) => id === toDeleteProduct) || {}).name || ""}
            />
        )
    }

    function renderChooseSelectionsModal() {
        const { idProductForChooseSelectionsModal, setIdProductForChooseSelectionsModal } = props

        return (
            <ChooseSelectionsModal
                title="Choose a selection:"
                isOpen={!!idProductForChooseSelectionsModal}
                onClose={() => setIdProductForChooseSelectionsModal(null)}
                handleCreateNewSelection={() => {}}
                handleSelect={() => {}}
                selectedSelectionIds={[]}
            />
        )
    }

    function renderSendModal() {
        const { setIsSendableModalOpenFor, handlePrintForSendable } = props

        const closeSendableModal = () => {
            setIsSendableModalOpenFor("")
        }

        return (
            <Sendable
                ids={checkedProducts.map(product => product.id)}
                printType={isSendableModalOpenFor}
                handleClose={closeSendableModal}
                handlePrint={handlePrintForSendable}
            />
        )
    }

    function renderAddToProjectModal() {
        const { setIsOpenAddToProjectModal, isOpenAddToProjectModal, handleAddToProject, userId } = props
        return (
            <ChooseProject
                headerText="Add this products to a project"
                labelChooseButton="Add products"
                isOpen={isOpenAddToProjectModal}
                filters={{
                    user_with_access: userId,
                    sort_by: "updated_at",
                    sort_direction: "desc"
                }}
                handleSelect={handleAddToProject}
                handleClose={() => setIsOpenAddToProjectModal(false)}
                withSelectRoom
            />
        )
    }

    return (
        <Layout
            title={
                <div className={cx("title-container")}>
                    <div className={cx("title-name")}>Products{hasCount && ":"}</div>
                    {hasCount && <div className={cx("count-description")}>Selected</div>}
                    {hasCount && <div className={cx("productCount")}>{productCount}</div>}
                </div>
            }
            renderActions={renderActions}
            customStyles={stylesLayout}
        >
            <Fragment>
                <ListContainer {...props} />
                {renderDeleteProductsConfirmModal()}
                {renderDeleteSingleProductConfirmModal()}
                {renderChooseSelectionsModal()}
                {renderAddToProjectModal()}
                {isSendableModalOpenFor && renderSendModal()}
            </Fragment>
        </Layout>
    )
}

export default withStyles(Page, styles)
