import * as yup from "yup"

import { settings as settingsFieldsSchema, product as productSchema } from "../ShowProductLink/schemas"

import { INPUT_MAX_LENGTH } from "constants/index"

const verifyFile = () =>
    yup.object().when("settings.video_type", {
        is: "file",
        then: yup.object().required("Field is required"),
        otherwise: yup.object().nullable()
    })

export const section = yup.object().shape({
    settings: yup
        .object()
        .shape({
            video_type: yup.string(),
            external_link: yup.string().when("video_type", {
                is: "link",
                then: yup
                    .string()
                    .url("Link has incorrect format")
                    .max(INPUT_MAX_LENGTH, `Link may not be greater than ${INPUT_MAX_LENGTH} characters`)
                    .required("Link is required"),
                otherwise: yup.string().nullable()
            }),
            ...settingsFieldsSchema
        })
        .required(),
    product: productSchema,
    video: verifyFile(),
    video_thumbnail: verifyFile()
})
