import React from "react"
import styles from "./Combinations.css"
import getStyles from "helpers/getCxFromStyles"
import { CombinationAttributeSelect } from "ui"
import { useIsTablet } from "helpers/rwd"

const CombinationsContainer = props => {
    const cx = getStyles(styles)
    const { combinationsAttributes, selectAttribute } = props
    const isTablet = useIsTablet()

    return (
        <div className={cx("root")}>
            {combinationsAttributes.map(
                ({ id, name, type, availableAttributeValues, isDisabled, selectedAttributeValue }, index) => (
                    <CombinationAttributeSelect
                        id={id}
                        name={name}
                        selectAttribute={selectAttribute}
                        selectedAttributeValue={selectedAttributeValue}
                        isDisabled={isDisabled}
                        availableAttributeValues={availableAttributeValues}
                        type={type}
                        zoomDirection={isTablet && index % 3 === 0 ? "right" : "left"}
                        key={id}
                    />
                )
            )}
        </div>
    )
}

export default CombinationsContainer
