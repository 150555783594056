import React, { Component } from "react"
import { Link } from "react-router-dom"
import withStyles from "HOC/withStyles"
import Checkbox from "components/Reusable/Form/Checkbox/index"
import Input from "components/Reusable/Form/Input/index"
import Button from "components/Reusable/Form/Button/index"
import styles from "./Login.css"

class Login extends Component {
    render() {
        const { isSaving, formFields, cx, handleSubmitAction, handleFieldChange, errorMessage, isError } = this.props
        return (
            <form>
                {isError && errorMessage && (
                    <div id="login-error-message" className={cx("error-message", "error")}>
                        {errorMessage}
                    </div>
                )}
                <div className={cx("input-field")}>
                    {this.renderInput({
                        field: formFields.email,
                        className: "input"
                    })}
                </div>

                <div className={cx("input-field")}>
                    {this.renderInput({
                        field: formFields.password,
                        className: "input"
                    })}
                </div>
                <div className={cx("separator")} />
                <div className={cx("remember")}>
                    <Checkbox
                        id="rememberMe"
                        name="Stay signed in"
                        customStyles={styles}
                        onChange={id =>
                            handleFieldChange({
                                event: { target: { value: !formFields.rememberMe.value } },
                                fieldPath: formFields.rememberMe.fieldPath
                            })
                        }
                        checked={formFields.rememberMe.value}
                    />

                    <Link to="/forgot-password">Forgot password?</Link>
                </div>

                <Button
                    className={["second-button-big", "button"]}
                    customStyles={styles}
                    onClick={handleSubmitAction}
                    label="Log In"
                    isLoading={isSaving}
                />
                <div className="clear" />
            </form>
        )
    }

    renderInput({ field, className }) {
        const { handleFieldChange } = this.props
        return (
            <Input
                {...field}
                customStyles={styles}
                className={className}
                isPlaceholder={true}
                onChange={handleFieldChange}
            />
        )
    }
}

export default withStyles(Login, styles)
