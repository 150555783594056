import * as yup from "yup"

import { INPUT_MAX_LENGTH } from "constants/index"

export default yup.object().shape({
    label: yup
        .string()
        .max(INPUT_MAX_LENGTH, `Section name may not be greater than ${INPUT_MAX_LENGTH} characters`)
        .required("Field is required")
})
