import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { FormikProvider } from "formik"
import get from "lodash/get"
import pick from "lodash/pick"

import { Button, H1, PreviewIcon, SnakeLoader } from "@butterfly-frontend/ui"
import { useOrderDetails } from "modules/WmsModule/hooks/api/useOrder"
import {
    usePurchaseOrderDetails,
    usePurchaseOrderOrderCreate,
    usePurchaseOrderOrderEdit
} from "modules/WmsModule/hooks/api/usePurchaseOrder"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import { useShippingMethodList } from "modules/WmsModule/hooks/api/useShippingMethod"
import PURCHASE_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/purchaseOrderStatusKeys"
import { createLinkToPurchaseOrderListInSO } from "modules/OrdersModule/helpers/createLinkToPurchaseOrder"
import GeneralInfoTab from "modules/OrdersModule/components/PurchaseOrderFormGeneralInfoTab"
import Sidebar from "modules/OrdersModule/components/PurchaseOrderFormSidebar"
import { useDiscountList } from "modules/OrdersModule/components/SelectDiscountSection"
import { PageError } from "modules/OrdersModule/components"
import DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT from "modules/OrdersModule/constants/dropShipSelectOptionOfDestinationPoint"
import usePurchaseOrderForm from "modules/OrdersModule/hooks/usePurchaseOrderForm"
import useDefaultWarehouse from "modules/WmsModule/hooks/useDefaultWarehouse"
import { combineFetchStatuses, successState } from "helpers/fetchStatus"
import { checkIfCanEditPurchaseOrder } from "modules/OrdersModule/helpers/purchaseOrderEdit"
import { SaveBar, Tabs } from "ui"

import ItemsTab from "./ItemsTab"
import styles from "./SalePurchaseOrderFormPage.module.css"

const TABS_KEYS = { ITEMS: "items", GENERAL_INFO: "general-info" }

const TABS = {
    [TABS_KEYS.ITEMS]: { label: "Items" },
    [TABS_KEYS.GENERAL_INFO]: { label: "General Info" }
}

const parseOrderItemToPurchaseOrderItem = orderItem => ({
    ...orderItem,
    quantity: orderItem.quantity - orderItem.stock_items.length
})

const SalePurchaseOrderFormPage = () => {
    const { orderId, purchaseOrderId } = useParams()
    const {
        push: routerPush,
        location: { state: routerLocationState }
    } = useHistory()

    const itemIds = useMemo(() => (routerLocationState ? routerLocationState.itemIds : []), [])
    const isEdit = !!purchaseOrderId

    const redirectToPurchaseOrderListInSO = useCallback(
        () => routerPush(createLinkToPurchaseOrderListInSO({ orderId, forceFetchOrder: true })),
        [routerPush, orderId]
    )

    useEffect(() => {
        if (!isEdit && itemIds.length === 0) {
            redirectToPurchaseOrderListInSO()
        }
    }, [isEdit, itemIds, redirectToPurchaseOrderListInSO])

    const [activeTabKey, setActiveTabKey] = useState(TABS_KEYS.ITEMS)

    const purchaseOrderCreate = usePurchaseOrderOrderCreate()
    const purchaseOrderEdit = usePurchaseOrderOrderEdit()
    const shippingMethodListResource = useShippingMethodList({ params: { length: 9999 } })
    const warehouseListResource = useWarehouseList()
    const orderDetailsResource = useOrderDetails({ id: orderId, reactQueryProps: { cacheTime: 0 } })
    const purchaseOrderDetailsResource = usePurchaseOrderDetails({
        id: purchaseOrderId,
        reactQueryProps: { enabled: !!purchaseOrderId }
    })

    const combinedFetchStatus = combineFetchStatuses([
        orderDetailsResource.fetchStatus,
        warehouseListResource.fetchStatus,
        isEdit ? purchaseOrderDetailsResource.fetchStatus : successState()
    ])

    const isSubmitting = purchaseOrderEdit.fetchStatus.isLoading || purchaseOrderCreate.fetchStatus.isLoading

    const isConfirmed = useMemo(
        () =>
            !!purchaseOrderDetailsResource.data &&
            purchaseOrderDetailsResource.data.status === PURCHASE_ORDER_STATUS_KEYS.confirmed,
        [purchaseOrderDetailsResource.data]
    )

    const orderItemsWithoutServices = useMemo(() => {
        if (!orderDetailsResource.data) {
            return []
        }

        return orderDetailsResource.data.items.filter(orderItem => !orderItem.product.is_assistance)
    }, [orderDetailsResource.data])

    const initialItems = useMemo(() => {
        if (purchaseOrderDetailsResource.data) {
            return purchaseOrderDetailsResource.data.items
        }

        if (orderItemsWithoutServices.length >= itemIds.length) {
            return itemIds
                .map(itemId => orderItemsWithoutServices.find(orderItem => orderItem.id === itemId))
                .map(parseOrderItemToPurchaseOrderItem)
        }

        return []
    }, [itemIds, orderItemsWithoutServices, purchaseOrderDetailsResource.data])

    const destinationPointList = useMemo(
        () => [
            DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT,
            ...(warehouseListResource.data
                ? warehouseListResource.data.map(warehouse => ({
                      label: warehouse.name,
                      id: warehouse.id,
                      value: warehouse
                  }))
                : [])
        ],
        [warehouseListResource.data]
    )

    const shippingMethodList = useMemo(() => {
        if (!shippingMethodListResource.data) {
            return []
        }

        return shippingMethodListResource.data.map(shippingMethod => ({
            id: shippingMethod.id,
            label: shippingMethod.name,
            value: shippingMethod
        }))
    }, [shippingMethodListResource.data])

    const orderShippingAddress = useMemo(() => get(orderDetailsResource.data, "shipping_address", null), [
        orderDetailsResource.data
    ])

    const brand = useMemo(() => {
        if (purchaseOrderDetailsResource.data) {
            return purchaseOrderDetailsResource.data.brand
        }

        return initialItems.length > 0 ? initialItems[0].brand : null
    }, [initialItems, purchaseOrderDetailsResource.data])

    const { mappedDiscountList: discountBrandList } = useDiscountList({
        currentDiscountResource: get(purchaseOrderDetailsResource.data, "discount"),
        discountList: get(brand, "discounts", [])
    })

    const { value: defaultWarehouseForPO } = useDefaultWarehouse()

    const { formik } = usePurchaseOrderForm({
        orderId,
        purchaseOrderId,
        isEdit,
        isConfirmed,
        initialItems,
        defaultWarehouseForPO,
        purchaseOrderDetailsResource,
        purchaseOrderEdit,
        purchaseOrderCreate,
        successSubmitAction: redirectToPurchaseOrderListInSO
    })

    const availableItemList = useMemo(() => {
        if (!brand || orderItemsWithoutServices.length === 0) {
            return []
        }

        const checkItemIsInAnotherPurchaseOrder = item =>
            purchaseOrderId
                ? item.purchase_order && item.purchase_order.id !== parseInt(purchaseOrderId, 10)
                : item.purchase_order

        const checkItemIsSelected = item => formik.values.items.some(({ id }) => id === item.id)

        return orderItemsWithoutServices
            .map(parseOrderItemToPurchaseOrderItem)
            .filter(
                item =>
                    !checkItemIsInAnotherPurchaseOrder(item) &&
                    item.brand.id === brand.id &&
                    item.quantity > 0 &&
                    !checkItemIsSelected(item)
            )
    }, [formik.values.items, orderItemsWithoutServices, brand, purchaseOrderId])

    const handleChangeDestinationPoint = useCallback(
        ({ value }) => {
            const isDropShip = value.type === DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT.value.type

            if (isDropShip) {
                formik.setFieldValue("warehouse", null)
                formik.setFieldValue("shipping_address", {
                    ...orderShippingAddress,
                    name: DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT.label
                })

                return
            }

            formik.setFieldValue("shipping_address", null, true)
            formik.setFieldValue("warehouse", value, false)
        },
        [orderShippingAddress, formik.setFieldValue]
    )

    if (!combinedFetchStatus.isLoaded) {
        return <SnakeLoader />
    }

    if (
        !brand ||
        !initialItems.length ||
        orderDetailsResource.fetchStatus.isError ||
        purchaseOrderDetailsResource.fetchStatus.isError
    ) {
        return <PageError>Unknown error</PageError>
    }

    if (
        isEdit &&
        !checkIfCanEditPurchaseOrder(
            pick(purchaseOrderDetailsResource.data, ["is_any_item_received", "warehouse", "type"])
        )
    ) {
        return <PageError>This purchase order has been already delivered.</PageError>
    }

    return (
        <FormikProvider value={formik}>
            <div className={styles.root}>
                <div className={styles.header}>
                    <H1 withLeftBorder>
                        {isEdit ? "Edit" : "New"} Purchase Order{isEdit && ":"}
                    </H1>
                    {isEdit && (
                        <Fragment>
                            <span className={styles.uuidOfPO}>{purchaseOrderDetailsResource.data.uuid}</span>
                            <Button
                                Icon={PreviewIcon}
                                variant="flat"
                                type="link"
                                LinkComponent={Link}
                                propsLinkComponent={{ to: createLinkToPurchaseOrderListInSO({ orderId }) }}
                                classes={{ button: styles.previewButton }}
                            >
                                View PO
                            </Button>
                        </Fragment>
                    )}
                </div>
                <div className={styles.container}>
                    <div className={styles.leftSide}>
                        <Tabs tabs={TABS} activeTabKey={activeTabKey} onTabChange={setActiveTabKey} />
                        <div className={styles.tabContainer}>
                            {activeTabKey === TABS_KEYS.GENERAL_INFO && (
                                <GeneralInfoTab
                                    isSubmitting={isSubmitting}
                                    isConfirmed={isConfirmed}
                                    destinationPointList={destinationPointList}
                                    handleChangeDestinationPoint={handleChangeDestinationPoint}
                                />
                            )}
                            {activeTabKey === TABS_KEYS.ITEMS && (
                                <ItemsTab
                                    availableItemList={availableItemList}
                                    discountBrandList={discountBrandList}
                                    isConfirmed={isConfirmed}
                                    isSubmitting={isSubmitting}
                                />
                            )}
                        </div>
                    </div>
                    <div className={styles.rightSide}>
                        <Sidebar
                            isSubmitting={isSubmitting}
                            isEdit={isEdit}
                            brand={brand}
                            isConfirmed={isConfirmed}
                            shippingMethodList={shippingMethodList}
                        />
                    </div>
                </div>
            </div>
            <SaveBar
                isShown
                onCancel={redirectToPurchaseOrderListInSO}
                onSubmit={formik.submitForm}
                isDisabled={!formik.isValid || (isEdit && !formik.dirty)}
                isSaving={isSubmitting}
                isDisabledCancel={isSubmitting}
                submitLabel="Save"
            />
        </FormikProvider>
    )
}

export default SalePurchaseOrderFormPage
