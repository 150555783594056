import React, { Fragment, useState, useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"
import xor from "lodash/xor"
import idx from "idx"

import { updatePaymentSuccess, deletePaymentSuccess, getPayments } from "actions/payments"
import useActions from "hooks/useActions"
import withPermissions from "HOC/withPermissions"
import withStyles from "HOC/withStyles"
import { Sort, Checkbox, Table } from "ui/FilterableTable"
import DocumentDetails from "./components/DocumentDetails"
import { AddEditPayment, DeletePayment } from "modules/OrdersModule/components"
import { ITEMS_PER_PAGE } from "constants/index"

import styles from "./List.css"
import { EmptyList } from "ui"
import tableWrapperStyles from "./overrides/TableWrapper.css"
import detailsStyles from "./overrides/DetailsTr.css"
import stylesCheckBox from "./overrides/CheckBox.css"

import { withListContext } from "./ListContainer"

const areAnyParamsSelected = searchParams => {
    const { query, statuses, created_from, created_to, moreFilters } = searchParams
    return !query && !statuses.length && !created_from && !created_to && Object.values(moreFilters).every(p => !p)
}

function List(props) {
    const {
        cx,
        multiselect,
        searchParams,
        onDelete,
        isTabView,
        listConfig,
        order,
        handleOpenModal,
        canEdit,
        canDelete
    } = props
    const { table, name, routerStateKey, listIcon, permissionContext } = listConfig
    const { checkedList, setCheckboxes, clearCheckboxes, toggleCheckboxes } = multiselect
    const canUserEdit = permissionContext ? canEdit(permissionContext) : true
    const canUserDelete = permissionContext ? canDelete(permissionContext) : true
    const { state } = useLocation()
    const initiallyExpandedId = !!state && typeof state[routerStateKey] === "number" ? state[routerStateKey] : null

    const previousSearchParamsRef = useRef(null)

    const emptyListVerb = areAnyParamsSelected(searchParams) ? "created" : "found"
    const [expandedIds, setExpandedIds] = useState(initiallyExpandedId ? [initiallyExpandedId] : [])
    const [editModalData, setEditModalData] = useState({ isOpen: false, data: {} })
    const [deleteModalData, setDeleteModalData] = useState({ isOpen: false, data: {} })

    const toggleExpanded = id => {
        setExpandedIds(xor(expandedIds, [id]))
    }

    const openEditModal = (data = {}) => setEditModalData({ isOpen: true, data })
    const closeEditModal = () => setEditModalData({ isOpen: false, data: {} })
    const openDeleteModal = (data = {}) => setDeleteModalData({ isOpen: true, data })
    const closeDeleteModal = () => setDeleteModalData({ isOpen: false, data: {} })

    const actions = useActions({ updatePaymentSuccess, deletePaymentSuccess, getPayments })

    useEffect(() => {
        if (!isTabView && !!previousSearchParamsRef.current) {
            setExpandedIds([])
        }

        previousSearchParamsRef.current = searchParams
    }, [searchParams, previousSearchParamsRef])

    const preparePaymentsParams = () => {
        const { type, sort_by, sort_direction, page, moreFilters } = searchParams
        const sendParams = {
            type,
            sort_by,
            sort_direction,
            page,
            length: ITEMS_PER_PAGE,
            user_id: moreFilters.user ? moreFilters.user.id : undefined
        }
        Object.keys(moreFilters).forEach(key => {
            if (moreFilters[key]) {
                if (["amount_min", "amount_max"].includes(key)) {
                    sendParams[key] = moreFilters[key]
                }
                if (["client", "brand", "project", "user"].includes(key)) {
                    sendParams[`${key}_id`] = moreFilters[key].id
                }
            }
        })
        return sendParams
    }

    return (
        <Fragment>
            <Table
                customStyles={tableWrapperStyles}
                className={cx("table")}
                renderHeader={renderTableHeader}
                renderBody={renderTableBody}
                customEmptyMessage={() => <EmptyList message={`No ${name} ${emptyListVerb}`} icon={listIcon} />}
            />
            {editModalData.isOpen && (
                <AddEditPayment
                    handleClose={closeEditModal}
                    orderId={idx(editModalData, _ => _.data.order.id)}
                    calculations={idx(editModalData, _ => _.data.order.calculations)}
                    projectId={idx(editModalData, _ => _.data.project.id)}
                    payment={editModalData.data}
                    invoice={idx(editModalData, _ => _.data.invoice) || {}}
                    collectionType={editModalData.data.order ? "order" : editModalData.data.project ? "project" : null}
                    collectionId={editModalData.data.order.id || editModalData.data.project.id}
                    onEditSuccess={({ data }) => {
                        const paymentParams = preparePaymentsParams()
                        actions.updatePaymentSuccess(data)
                        actions.getPayments(paymentParams)
                    }}
                />
            )}
            {deleteModalData.isOpen && (
                <DeletePayment
                    amount={idx(deleteModalData, _ => _.data.amount)}
                    paymentId={idx(deleteModalData, _ => _.data.id)}
                    onDelete={actions.deletePaymentSuccess}
                    handleClose={closeDeleteModal}
                />
            )}
        </Fragment>
    )

    function renderTableHeader(items) {
        const ids = items.map(i => i.id)
        const [areSomeChecked, areAllChecked] = [checkedList.length > 0, checkedList.length === items.length]
        const onChange = areSomeChecked ? clearCheckboxes : setCheckboxes
        const isSorting = !isTabView || order.type !== "rental"

        return (
            <Table.Head>
                <Table.Tr>
                    <Table.Th>
                        <Checkbox
                            id={ids}
                            checked={areSomeChecked}
                            onChange={onChange}
                            isMinusIcon={areSomeChecked && !areAllChecked}
                            isLabelAbsolute={true}
                        />
                    </Table.Th>
                    {Object.entries(table).map(([columnName, { sortBy, classNames, style = {} }]) => (
                        <Table.Th style={style} key={columnName} className={cx(classNames && classNames.th)}>
                            {typeof sortBy === "string" && isSorting ? (
                                <Sort sortBy={sortBy}>{columnName}</Sort>
                            ) : (
                                columnName
                            )}
                        </Table.Th>
                    ))}
                </Table.Tr>
            </Table.Head>
        )
    }

    function renderTableBody(items) {
        return (
            <Table.Body>
                {items.map(item => {
                    const { id, uuid } = item
                    const isExpanded = expandedIds.includes(id)

                    return (
                        <Fragment key={id}>
                            <Table.Tr className={cx({ isExpanded })}>
                                <Table.Td>
                                    <Checkbox
                                        id={id}
                                        customStyles={stylesCheckBox}
                                        checked={checkedList.includes(id)}
                                        onChange={toggleCheckboxes}
                                    />
                                </Table.Td>
                                {Object.entries(table).map(([columnName, { renderCell, classNames, style = {} }]) => (
                                    <Table.Td
                                        style={style}
                                        key={columnName}
                                        className={cx(classNames && classNames.td)}
                                    >
                                        {typeof renderCell === "function" &&
                                            renderCell({
                                                cx,
                                                isExpanded,
                                                toggleExpanded,
                                                openEditModal,
                                                openDeleteModal,
                                                canUserEdit,
                                                canUserDelete,
                                                ...item
                                            })}
                                    </Table.Td>
                                ))}
                            </Table.Tr>
                            {isExpanded && (
                                <Table.Tr customStyles={detailsStyles}>
                                    <Table.Td colSpan={Object.keys(table).length + 1}>
                                        <DocumentDetails
                                            uuid={uuid}
                                            documentId={id}
                                            hideItself={() => toggleExpanded(id)}
                                            onDelete={onDelete}
                                            handleOpenModal={handleOpenModal}
                                        />
                                    </Table.Td>
                                </Table.Tr>
                            )}
                        </Fragment>
                    )
                })}
            </Table.Body>
        )
    }
}

export default withPermissions(withListContext(withStyles(List, styles)))
