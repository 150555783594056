import { combineReducers } from "redux"

import chooseProductReducers from "components/Modal/ChooseProduct/reducers"
import chooseOrderReducers from "components/Modal/ChooseOrder/reducers"
import chooseProjectReducers from "components/Modal/ChooseProject/reducers"

export default combineReducers({
    chooseProduct: chooseProductReducers,
    chooseOrder: chooseOrderReducers,
    chooseProject: chooseProjectReducers
})
