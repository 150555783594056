import React from "react"

import withStyles from "HOC/withStyles"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import styles from "./ActionLink.css"

const ActionLink = ({ cx, as, isLoading, isDisabled, className, children, ...props }) => {
    className = className || ""
    const Component = as ? as : "span"

    return (
        <Component className={[cx("root", { isLoading, isDisabled }), className].join(" ")} {...props}>
            {renderChildren()}
        </Component>
    )

    function renderChildren() {
        if (isLoading) {
            return <SnakeLoader />
        }

        return typeof children === "function" ? children({ cx }) : children
    }
}

export default withStyles(ActionLink, styles)
