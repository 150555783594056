import React, { Component } from "react"
import { connect } from "react-redux"
import env from "env"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"
import idx from "idx"

const withMultipleUploader = WrappedComponent => {
    class Uploader extends Component {
        constructor(props) {
            super(props)

            this.state = {
                addedFile: [],
                progress: 0,
                isUploading: false,
                isSuccess: false,
                error: ""
            }
        }

        handleUpload = (addedFiles, rejectedFiles) => {
            const { handleUploadReject } = this.props
            const addedFile = addedFiles.map(el => {
                return {
                    title: el.name,
                    file: el,
                    isUploading: true,
                    progress: 0,
                    error: ""
                }
            })

            this.setState(
                {
                    addedFile,
                    isUploading: true,
                    progress: 0,
                    error: ""
                },
                this.handleUploadRequest
            )

            if (rejectedFiles && rejectedFiles.length > 0) {
                this.setState(
                    {
                        progress: 0,
                        isUploading: false,
                        isSuccess: false,
                        error: rejectedFiles.map(file => {
                            return `File ${file && file.name ? file.name : ""} is too big or has wrong extension!`
                        })
                    },
                    () => handleUploadReject && handleUploadReject(this.state)
                )
            }
        }

        prepareFormData(index) {
            const { addedFile } = this.state
            const { fileFieldName, appendCustomFormFields, type } = this.props
            let formData = new FormData()

            if (addedFile && addedFile[index].file && addedFile[index].title) {
                formData.append(fileFieldName, addedFile[index].file, addedFile[index].title)
                formData = appendCustomFormFields
                    ? appendCustomFormFields({ fileObject: addedFile[index].file, formData })
                    : formData
            }
            if (type) {
                formData.append("type", type)
            }
            return formData
        }

        handleUploadRequest() {
            const {
                endpoint,
                authorizationHeaders,
                handleUploadFailure,
                handleUploadProgress,
                handleUploadFile,
                handleUploadFileSuccess,
                getFileDataFromResponse,
                isLoggedIn,
                idToken
            } = this.props
            const { addedFile } = this.state

            const promises = addedFile.map((el, index) => {
                if (typeof handleUploadFile === "function") {
                    handleUploadFile(el)
                }

                return new Promise((resolve, reject) => {
                    const form = this.prepareFormData(index)
                    const req = new XMLHttpRequest()

                    req.open("POST", endpoint)
                    Object.keys(authorizationHeaders).map(item => {
                        req.setRequestHeader(
                            item,
                            isLoggedIn && item === "X-Authorization" ? idToken : authorizationHeaders[item]
                        )
                        return item
                    })

                    if (isLoggedIn && authorizationHeaders["X-Authorization"] === undefined) {
                        req.setRequestHeader("X-Authorization", idToken)
                    }

                    req.setRequestHeader("Accept", "application/json")

                    req.withCredentials = false

                    req.addEventListener(
                        "load",
                        e => {
                            if (req.status >= 200 && req.status <= 299) {
                                if (typeof handleUploadFileSuccess === "function") {
                                    const fileData = getFileDataFromResponse(req.response)

                                    handleUploadFileSuccess(
                                        {
                                            ...fileData,
                                            ...addedFile[index],
                                            url: fileData.data,
                                            isUploading: false,
                                            isSuccess: true,
                                            progress: 100,
                                            error: ""
                                        },

                                        index
                                    )
                                }

                                resolve(req.response)
                            } else {
                                const error = idx(JSON.parse(req.response), _ => _.message)

                                this.setState(
                                    {
                                        progress: 100,
                                        isUploading: false,
                                        isSuccess: false,
                                        error: error ? [error] : ["Error with sending!"]
                                    },
                                    () => {
                                        reject()
                                        handleUploadFailure && handleUploadFailure(this.state)
                                    }
                                )
                            }
                        },
                        false
                    )

                    req.addEventListener(
                        "error",
                        e => {
                            this.setState(
                                {
                                    error: "Error with sending!",
                                    isUploading: false,
                                    isSuccess: false
                                },
                                () => {
                                    reject()
                                    handleUploadFailure && handleUploadFailure(this.state)
                                }
                            )
                        },
                        false
                    )

                    req.upload &&
                        req.upload.addEventListener(
                            "progress",
                            e => {
                                let progress = 0
                                if (e.total !== 0) {
                                    progress = parseInt((e.loaded / e.total) * 100, 10)
                                }

                                const stateAddedFile = this.state.addedFile
                                const addedFile = Object.assign(stateAddedFile)

                                addedFile[index] = {
                                    ...addedFile[index],
                                    progress
                                }
                                this.setState(
                                    {
                                        addedFile
                                    },
                                    () => handleUploadProgress && handleUploadProgress(this.state)
                                )
                            },
                            false
                        )

                    req.addEventListener(
                        "abort",
                        e => {
                            const stateAddedFile = this.state.addedFile
                            const addedFile = Object.assign(stateAddedFile)

                            addedFile[index] = {
                                ...addedFile[index],
                                progress: 0
                            }
                            this.setState(
                                {
                                    addedFile
                                },
                                () => {
                                    reject()
                                }
                            )
                        },
                        false
                    )

                    req.send(form)
                })
            })

            Promise.all(promises).then(images => {
                this.handleUploadDone(images)
            })
        }

        handleRemove = () => {
            this.setState({
                isUploading: false,
                isSuccess: false,
                addedFile: []
            })
        }

        handleSingleRemove = indexForDelete => {
            const { addedFile } = this.props
            const newAddedFile = addedFile.filter((_, index) => index !== indexForDelete)
            const isNotEmpty = newAddedFile && !!newAddedFile.length

            this.setState({
                isUploading: isNotEmpty,
                isSuccess: isNotEmpty,
                addedFile: newAddedFile
            })
        }

        handleUploadDone(images) {
            const { getFileDataFromResponse, handleUploadSuccess } = this.props
            const stateAddedFile = this.state.addedFile
            const addedFiles = images.map((image, index) => {
                const fileData = getFileDataFromResponse(image)

                return {
                    ...fileData.data,
                    ...stateAddedFile[index],
                    isUploading: false,
                    isSuccess: true,
                    progress: 100,
                    error: ""
                }
            })

            this.setState(
                {
                    addedFile: [],
                    isUploading: false,
                    isSuccess: true,
                    progress: 100
                },
                () => {
                    handleUploadSuccess && handleUploadSuccess(addedFiles)
                }
            )
        }

        clearAddedFileList() {
            this.setState({
                addedFile: []
            })
        }

        render() {
            const { isUploading, isSuccess, progress, addedFile, error } = this.state

            return (
                <WrappedComponent
                    {...this.props}
                    uploader={{
                        state: {
                            isUploading,
                            isSuccess,
                            progress,
                            addedFile,
                            error
                        },
                        actions: {
                            handleUpload: this.handleUpload,
                            handleRemove: this.handleRemove,
                            handleSingleRemove: this.handleSingleRemove
                        }
                    }}
                />
            )
        }
    }

    Uploader.defaultProps = {
        endpoint: DEFAULT_FILE_UPLOAD_ENPOINT,
        authorizationHeaders: env.API.authorizationHeaders,
        fileFieldName: "file",
        getFileDataFromResponse: response => {
            response = JSON.parse(response)

            if (response) {
                return response
            }

            return {}
        },
        appendCustomFormFields: ({ fileObject, formData }) => {
            return formData
        }
    }

    const mapDispatchToProps = state => {
        return {
            idToken: state.loginStore.token,
            isLoggedIn: state.loginStore.token !== null && state.loginStore.token !== false ? true : false
        }
    }

    return connect(mapDispatchToProps)(Uploader)
}

export default withMultipleUploader
