import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import style from "./Content.css"
import ContentMain from "./ContentMain/ContentMain"

class Content extends Component {
    render() {
        const { cx, isSidebarMenuWide } = this.props
        return (
            <main
                className={cx({
                    "content-cover": isSidebarMenuWide,
                    "content-cover-wide": !isSidebarMenuWide,
                    contentWide: !isSidebarMenuWide
                })}
            >
                <ContentMain />
            </main>
        )
    }
}

export default withStyles(Content, style)
