import React from "react"
import { Route, Switch } from "react-router-dom"

import { Brands } from "../pages"

import withPermissions from "HOC/withPermissions"

const Routes = () => {
    return (
        <Switch>
            <Route path="/online-store/brands" component={Brands} />
        </Switch>
    )
}

export default withPermissions(Routes)
