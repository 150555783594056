import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./MultiCategories.css"
import Categories from "../Categories"

import addIcon from "assets/add.svg"

class MultiCategories extends Component {
    render() {
        const {
            cx,
            categoriesNumber,
            selectedCategories,
            handleAddCategory,
            handleRemoveCategory,
            handleSaveCategories,
            isTablet
        } = this.props
        return (
            <div className={cx("root")}>
                {Array(categoriesNumber)
                    .fill()
                    .map((e, i) => i + 1)
                    .map(categoryIndex => (
                        <Categories
                            {...this.props}
                            selectedCategories={selectedCategories[categoryIndex - 1] || []}
                            key={categoryIndex}
                            categoryIndex={categoryIndex - 1}
                            handleSaveCategories={handleSaveCategories}
                            handleRemoveCategory={handleRemoveCategory}
                            isTablet={isTablet}
                        />
                    ))}
                <div className={cx("add-more")} onClick={() => handleAddCategory()}>
                    <span>
                        <img src={addIcon} alt="addImage" />
                        <span>Add another category</span>
                    </span>
                </div>
            </div>
        )
    }
}

export default withStyles(MultiCategories, styles)
