import React, { Component } from "react"
import { connect } from "react-redux"
import withForm from "HOC/withForm"
import models from "models/index"
import SearchBar from "./SearchBar"
import { getCompanies } from "actions/company"
import { getServices } from "actions/service"
import { getChannels } from "actions/channel"
import { getUsers } from "actions/user"
import { getClientGroups } from "actions/clientGroups"
import { PERMISSIONS } from "constants/index"

const TYPING_TIMEOUT = 250
class SearchBarContainer extends Component {
    timeout = null

    state = {
        isExpanded: false,
        isAssignedToOn: false
    }

    componentDidMount() {
        const {
            getChannels,
            getCompanies,
            getServices,
            getClientGroups,
            toApi,
            fillModel,
            params,
            permissions,
            getUsers,
            autoSelectMyClients,
            currentUserRoles
        } = this.props

        const isSuperAdmin = currentUserRoles.findIndex(role => role.name === "SuperAdmin") >= 0

        if (permissions.includes(`${PERMISSIONS.types.LIST} ${PERMISSIONS.context.USERS}`)) {
            getUsers()
        }

        getCompanies()
        getServices()
        getClientGroups()
        getChannels()
        setTimeout(() => {
            fillModel(
                {
                    ...toApi(),
                    ...params
                },
                true
            ).then(() => {
                if (autoSelectMyClients && !isSuperAdmin) {
                    this.handleToggleIsAssigned()
                    this.handleSearch(null, true)
                }
            })
        }, 500)
    }

    render() {
        const { toRender } = this.props
        const { isExpanded, isAssignedToOn } = this.state

        return (
            <SearchBar
                {...this.props}
                isExpanded={isExpanded}
                isAssignedToOn={isAssignedToOn}
                filtersCount={this.getFiltersCount()}
                handleToggleIsExpanded={this.handleToggleIsExpanded}
                formFields={toRender()}
                handleChooseClientTypes={this.handleChooseClientTypes}
                handleClearAllFilters={this.handleClearAllFilters}
                handleSearch={this.handleSearch}
                handleToggleIsAssigned={this.handleToggleIsAssigned}
            />
        )
    }

    getFiltersCount() {
        const { toApi } = this.props
        const fields = toApi()

        return Object.keys(fields)
            .filter(key => {
                return (
                    (!Array.isArray(fields[key]) &&
                        (fields[key] !== "" && fields[key] !== undefined && Number(fields[key]) !== 0)) ||
                    (Array.isArray(fields[key]) && fields[key].length > 0)
                )
            })
            .reduce((acc, key) => {
                if (Array.isArray(fields[key])) {
                    acc += fields[key].length
                }

                if (!Array.isArray(fields[key])) {
                    acc += 1
                }

                return acc
            }, 0)
    }

    handleToggleIsExpanded = () => {
        this.setState({
            isExpanded: !this.state.isExpanded
        })
    }

    handleChooseClientTypes = id => {
        const { handleFieldChange, toRender } = this.props
        const types = toRender().types.value
        const typesWithoutThisId = types.filter(element => element !== id)

        let newTypes = [...typesWithoutThisId]
        if (types.indexOf(id) < 0) {
            newTypes = [...types, id]
        }

        handleFieldChange({
            event: {
                target: {
                    value: newTypes
                }
            },
            fieldPath: "types"
        })
    }

    handleClearAllFilters = () => {
        const { fillModel, toApi } = this.props
        this.setState({ isAssignedToOn: false })
        return fillModel(
            Object.keys(toApi()).reduce((output, key) => {
                output[key] = null
                return output
            }, {}),
            true
        )
    }

    handleSearch = (inputEvent, isFromFilter = false) => {
        let value = ""
        const { handleSearch, filter, toApi, handleGetFiltersCount } = this.props
        const params = toApi()

        if (typeof handleGetFiltersCount === "function") {
            handleGetFiltersCount(this.getFiltersCount())
        }

        Object.keys(params).forEach(key => {
            if (params[key] === false || params[key] === "") {
                delete params[key]
            }
        })

        if (isFromFilter) {
            return handleSearch({ query: filter, params, isFromFilter: true })
        }

        if (inputEvent && inputEvent.target) {
            value = inputEvent.target.value
            this.setState({
                isExpanded: false
            })
        }

        clearTimeout(this.timeout)

        this.timeout = setTimeout(() => handleSearch({ query: value, params }), TYPING_TIMEOUT)
    }

    handleToggleIsAssigned = () => {
        const { isAssignedToOn } = this.state
        const { handleFieldChange, currentUserId } = this.props

        handleFieldChange({
            event: {
                target: {
                    value: isAssignedToOn ? "" : currentUserId
                }
            },
            fieldPath: "assigned_to"
        })

        this.setState({
            isAssignedToOn: !isAssignedToOn
        })
    }
}

const mapStateToProps = state => {
    return {
        group_id: state.db.clientGroups.list,
        company_client_id: state.db.companies.list,
        channel_id: state.db.channels.list,
        service_id: state.db.services.list,
        statesList: state.clientStore.statesList,
        clientTypesList: state.db.clientTypes.list.data,
        countriesList: state.clientStore.countriesList,
        users: state.userStore.users,
        permissions: state.loginStore.permissions,
        currentUserId: parseFloat(state.loginStore.userId),
        currentUserRoles: state.loginStore.roles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getChannels: () => dispatch(getChannels()),
        getCompanies: () => dispatch(getCompanies()),
        getClientGroups: () => dispatch(getClientGroups()),
        getServices: () => dispatch(getServices()),
        getUsers: () => dispatch(getUsers())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withForm(SearchBarContainer, { model: models.ClientSearch }))
