import React, { Component } from "react"
import { connect } from "react-redux"
import env from "env"
import fetch from "helpers/fetch"

import withMultipleUploader from "HOC/withMultipleUploader"
import * as notificationActions from "actions/notification"
import * as uploadModuleActions from "actions/uploadModule"
import * as productActions from "actions/product"

import ImagesList from "./ImagesList"

const API = env.API
const MultipleUploader = withMultipleUploader(ImagesList)

const sortByDefault = (leftFolder, rightFolder) =>
    Number(rightFolder.name === "Default") - Number(leftFolder.name === "Default")

class ImagesListContainer extends Component {
    state = {
        foldersList: [],
        currentFolder: {},
        areAllImagesSelected: false,
        isEditFolderName: false,
        isAddFolderName: false,
        nameInputValue: "",
        newFolderName: "",
        isFixed: false,
        imageSearchValue: "",
        isDeleteModal: false,
        isImagesDeleting: false
    }

    componentDidMount() {
        this.props.getFoldersList().then(() => {
            this.setState({
                foldersList: this.props.foldersList.sort(sortByDefault)
            })

            const defaultFolder = this.props.foldersList.find(folder => folder.name === "Default")

            if (defaultFolder) {
                this.handleUpdate(defaultFolder)
            }
        })
    }

    handleToggleImagesSelect = () => {
        this.setState(prevState => {
            return {
                areAllImagesSelected: !prevState.areAllImagesSelected
            }
        })
    }

    handleUpdate = folder => {
        const { setCurrentFolderId, foldersList } = this.props
        return (
            this.setState({
                foldersList: foldersList.sort(sortByDefault),
                currentFolder: folder
            }),
            setCurrentFolderId(folder.id),
            this.handleGetImages(folder.id)
        )
    }

    componentDidUpdate(prevProps, prevState) {
        const { currentFolder, foldersList, currentlySelectedImages, images } = this.props
        const prevFoldersLength = prevState.foldersList.length
        const propsFoldersLength = foldersList.length
        const folderWithChangedName = foldersList.filter(
            (folder, index) => prevState.foldersList[index] && folder.name !== prevState.foldersList[index].name
        )[0]
        const changedFolderName = prevFoldersLength === propsFoldersLength && folderWithChangedName
        const firstOnFoldersList = foldersList[0]
        const addedNewFolder = prevFoldersLength > 0 && prevFoldersLength === propsFoldersLength - 1
        const deletedFolder = prevFoldersLength === propsFoldersLength + 1 && propsFoldersLength > 0
        const deselectOneWhenAllSelected =
            prevState.areAllImagesSelected &&
            prevProps.currentlySelectedImages.length === currentlySelectedImages.length + 1
        const selectLastUnselected = !prevState.areAllImagesSelected && currentlySelectedImages.length === images.length
        const changeImagesLength = prevProps.images.length !== images.length

        if (changedFolderName) {
            return this.handleUpdate(folderWithChangedName)
        }

        if (addedNewFolder) {
            const folderWithLatestId = foldersList.reduce((prev, curr) => (curr.id > prev.id ? curr : prev))
            return this.handleUpdate(folderWithLatestId)
        }

        if (deletedFolder) {
            return this.handleUpdate(firstOnFoldersList)
        }

        if (currentFolder && prevState.currentFolder.id !== currentFolder.id) {
            this.setState({
                currentFolder
            })
        }

        if (selectLastUnselected || changeImagesLength || deselectOneWhenAllSelected) {
            this.setState({
                areAllImagesSelected: selectLastUnselected ? true : false
            })
        }
    }

    render() {
        const {
            currentlySelectedImages,
            isImagesLoading,
            isFoldersLoading,
            isImageDisabled,
            isImageDragging
        } = this.props

        const {
            isEditFolderName,
            isAddFolderName,
            nameInputValue,
            newFolderName,
            imageSearchValue,
            isImagesDeleting,
            isDeleteModal
        } = this.state
        const { currentFolder, isFixed } = this.state

        const topDistance = 178
        window.onscroll = () => {
            if (window.pageYOffset > topDistance && !isFixed) {
                this.setState({ isFixed: true })
            }
            if (window.pageYOffset <= topDistance && isFixed) {
                this.setState({ isFixed: false })
            }
        }

        return (
            <MultipleUploader
                {...this.props}
                {...this.state}
                currentFolder={currentFolder}
                currentlySelectedImages={currentlySelectedImages}
                handleToggleImagesSelect={this.handleToggleImagesSelect}
                nameInputValue={nameInputValue}
                newFolderName={newFolderName}
                imageSearchValue={imageSearchValue}
                isImagesLoading={isImagesLoading}
                isFoldersLoading={isFoldersLoading}
                isImageDisabled={isImageDisabled}
                isImageDragging={isImageDragging}
                isEditFolderName={isEditFolderName}
                isAddFolderName={isAddFolderName}
                endpoint={`${API.baseUrl}/media`}
                appendCustomFormFields={({ fileObject, formData }) => {
                    formData.append("model_type", "\\App\\Models\\Folder")
                    formData.append("model_id", currentFolder.id)
                    formData.append("model_collection", "images")
                    return formData
                }}
                isImagesDeleting={isImagesDeleting}
                handleDeleteChosenImages={this.handleDeleteChosenImages}
                handleUploadReject={this.handleUploadReject}
                handleToggleEdit={this.handleToggleEdit}
                handleUploadSuccess={this.handleUploadSuccess}
                handleChangeFolderNameInputValue={this.handleChangeFolderNameInputValue}
                handleChooseFolder={this.handleChooseFolder}
                handleDeleteFolder={this.handleDeleteFolder}
                handleToggleAddFolderModal={this.handleToggleAddFolderModal}
                handleChangeNewFolderName={this.handleChangeNewFolderName}
                handleAddFolder={this.handleAddFolder}
                handleSearch={this.handleSearch}
                handleFilterByUrlAndId={this.handleFilterByUrlAndId}
                handleFilterByName={this.handleFilterByName}
                isDeleteModal={isDeleteModal}
                handleToggleDeleteModal={this.handleToggleDeleteModal}
            />
        )
    }

    handleToggleDeleteModal = () => {
        const { isDeleteModal } = this.state
        this.setState({
            isDeleteModal: !isDeleteModal
        })
    }

    handleDeleteChosenImages = () => {
        const { currentlySelectedImages, triggerImageSelect, getCurrentFolderImages, currentFolderId } = this.props
        const imagesIds = currentlySelectedImages.reduce((output, element) => {
            output = [...output, element.id]
            return output
        }, [])

        this.setState({
            isDeleteModal: false,
            isImagesDeleting: true
        })

        return fetch.patchRAW(`/folders/${currentFolderId}`, { images: { delete: imagesIds } }).then(() => {
            return (
                this.setState({
                    isImagesDeleting: false
                }),
                getCurrentFolderImages(currentFolderId),
                triggerImageSelect([])
            )
        })
    }

    handleUploadReject = data => {
        const { showErrorNotification } = this.props
        const error = data && data.error && data.error[0]
        showErrorNotification(error)
    }

    handleUploadSuccess = () => {
        const { triggerImageSelect } = this.props

        this.handleGetImages()
        this.handleClearInput()
        triggerImageSelect([])
    }

    handleChooseFolder = ({ id }) => {
        const { currentFolder } = this.state
        const { foldersList, triggerImageSelect, setCurrentFolderId, getCurrentFolderImages } = this.props
        if (currentFolder && id === currentFolder.id) return

        const chosenFolder = foldersList.find(folder => folder.id === id)
        this.setState({
            currentFolder: chosenFolder,
            areAllImagesSelected: false
        })

        this.handleClearInput()

        setCurrentFolderId(id)
        getCurrentFolderImages(id)
        triggerImageSelect()
    }

    foldersListWithoutCurrentFolder = id => {
        const { foldersList } = this.props
        return foldersList.filter(folder => folder.id !== id)
    }

    handleGetImages(id) {
        const { getCurrentFolderImages } = this.props
        const { currentFolder } = this.state

        getCurrentFolderImages(id || currentFolder.id)
    }

    handleChangeFolderName = id => {
        const { nameInputValue } = this.state
        const { changeFolderName } = this.props
        changeFolderName(id, { name: nameInputValue })
    }

    handleChangeNewFolderName = value => {
        this.setState({ newFolderName: value })
    }

    handleAddFolder = () => {
        const { newFolderName } = this.state
        const { addFolder, showErrorNotification } = this.props

        if (!newFolderName) showErrorNotification("Catalog name is required")
        else
            addFolder({ name: newFolderName })
                .then(() => this.setState({ isAddFolderName: false }, () => this.handleClearInput()))
                .catch(() => showErrorNotification("Error create folder"))
    }

    handleToggleEdit = (id, type) => {
        const { currentFolder } = this.state

        if (type === "openEdit" && currentFolder) {
            return this.setState({
                isEditFolderName: true,
                nameInputValue: currentFolder.name
            })
        }

        this.setState({
            isEditFolderName: false
        })

        if (type === "saveEdit") {
            return this.handleChangeFolderName(id)
        }
    }

    handleChangeFolderNameInputValue = ({ value }) => {
        this.setState({
            nameInputValue: value
        })
    }

    handleDeleteFolder = id => {
        const { getFoldersList } = this.props
        fetch.deleteRAW(`/folders/${id}`).then(() => getFoldersList())
    }

    handleToggleAddFolderModal = action => {
        this.setState({
            isAddFolderName: action === "open" ? true : false,
            isEditFolderName: false,
            newFolderName: ""
        })
    }

    handleSearch = value => {
        this.setState({
            imageSearchValue: value
        })
    }

    handleClearInput = () => {
        this.setState({
            imageSearchValue: ""
        })
    }

    handleFilterByUrlAndId = list => {
        const { transferedImages } = this.props
        const transferedImagesIds = transferedImages.map(el => el.id)

        return list.filter(item => item.url && !transferedImagesIds.includes(item.id))
    }

    handleFilterByName = list => {
        const { imageSearchValue } = this.state

        return list.filter(item => {
            if (item.name === null && imageSearchValue === "") {
                return true
            }

            return item.name && item.name.toLowerCase().includes(imageSearchValue.toLowerCase())
        })
    }
}

const mapStateToProps = state => {
    return {
        transferedImages: state.ui.uploadModule.transferedImages,
        currentlySelectedImages: state.ui.uploadModule.currentlySelectedImages,
        isImageDragging: state.ui.uploadModule.isImageDragging,
        isFoldersLoading: state.db.uploadModule.list.isLoading,
        foldersList: state.db.uploadModule.list.data,
        currentFolderId: state.ui.uploadModule.currentFolderId,
        images: state.db.uploadModule.current.data,
        isImagesLoading: state.db.uploadModule.current.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        triggerImageSelect: data => dispatch(uploadModuleActions.triggerImageSelect(data)),
        setImageDraggingStatus: status => dispatch(uploadModuleActions.setImageDraggingStatus(status)),
        setCurrentFolderId: id => dispatch(uploadModuleActions.setCurrentFolderId(id)),
        getFoldersList: () => dispatch(uploadModuleActions.getFoldersList()),
        addFolder: body => dispatch(uploadModuleActions.addFolder(body)),
        changeFolderName: (id, body) => dispatch(uploadModuleActions.changeFolderName(id, body)),
        showErrorNotification: data => dispatch(notificationActions.showErrorNotification(data)),
        changeNumberOfImagesInProduct: data => dispatch(productActions.changeNumberOfImagesInProduct(data)),
        getCurrentFolderImages: id => dispatch(uploadModuleActions.getCurrentFolderImages(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImagesListContainer)
