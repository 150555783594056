import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./ProductsList.css"
import Product from "./Product/Product"
import Slider from "react-slick"

class ProductsList extends Component {
    static defaultProps = {
        customProductStyles: {}
    }

    innerLoading() {
        const { isOneLineGrid, isSmallCard, isLoading, isResponsive, isRelated, nameMaxLength } = this.props

        return Array(isOneLineGrid ? 3 : 10)
            .fill()
            .map((e, i) => i + 1)
            .map((value, index) => (
                <Product
                    nameMaxLength={nameMaxLength}
                    key={index}
                    isLoading={isLoading}
                    isResponsive={isResponsive}
                    isSmallCard={isSmallCard}
                    isOneLineGrid={isOneLineGrid}
                    isRelated={isRelated}
                />
            ))
    }

    innerLoaded() {
        const {
            customProductStyles,
            isOneLineGrid,
            products,
            isSmallCard,
            isBrandOnTop,
            handleRemove,
            hideModal,
            isResponsive,
            handleProjectsModalToggle,
            projectsProducts,
            isRelated,
            nameMaxLength
        } = this.props

        return products.map((product, index) => {
            return (
                <Product
                    nameMaxLength={nameMaxLength}
                    customStyles={customProductStyles}
                    key={index}
                    hasProjects={((projectsProducts || {})[product.id] || []).length > 0}
                    {...product}
                    isResponsive={isResponsive}
                    isOneLineGrid={isOneLineGrid}
                    isBrandOnTop={isBrandOnTop}
                    isSmallCard={isSmallCard}
                    hideModal={hideModal}
                    handleRemove={handleRemove}
                    handleProjectsModalToggle={handleProjectsModalToggle || (() => {})}
                    isRelated={isRelated}
                />
            )
        })
    }

    render() {
        const { cx, isOneLineGrid, products, isLoading, customProductStyles } = this.props

        const sliderSettings = this.props.sliderSettings ? this.props.sliderSettings : { options: {}, isSlider: false }
        const settings = { ...sliderSettings.options, slide: "li" }

        return (
            <div>
                {!sliderSettings.isSlider && (
                    <ul className={cx("products-list", { isOneLineGrid })}>
                        {isLoading && this.innerLoading()}
                        {!isLoading && products && this.innerLoaded()}
                        <div className={cx("clear")} />
                    </ul>
                )}
                {!isLoading && sliderSettings.isSlider && (
                    <Slider customProductStyles={customProductStyles} className={cx("slider")} {...settings}>
                        {products && this.innerLoaded()}
                    </Slider>
                )}
            </div>
        )
    }
}

export default withStyles(ProductsList, styles)
