import React from "react"
import PropTypes from "prop-types"
import { SelectWithSearch } from "ui"
import { selectWithSearchPropTypes } from "ui/SelectWithSearch/SelectWithSearch"

import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"

import useSelectProductConditions from "./useSelectProductConditions"

const SelectProductConditions = props => {
    const { customStyles, name, label, selectWithSearchProps, multiselect, conditionType, excludedTypes } = props

    const { propsForSelectWithSearch } = useSelectProductConditions({ conditionType, excludedTypes, setValue })
    const { pagination, ...restPropsForSelectWithSearch } = propsForSelectWithSearch

    return (
        <SelectWithSearch
            multiselect={multiselect}
            name={name}
            label={label}
            value={props.value}
            placeholder="- Please select -"
            customStyles={customStyles}
            withoutSearch
            pagination={{ current_page: 1, last_page: 1 }}
            {...selectWithSearchProps}
            {...restPropsForSelectWithSearch}
        />
    )

    function setValue(value) {
        props.handleSelect({
            target: {
                name,
                value: multiselect || value.value ? value : null
            }
        })
    }
}

SelectProductConditions.defaultProps = {
    multiselect: true,
    selectWithSearchProps: {}
}

const { setValue, values, ...restSelectWithSearchPropTypes } = selectWithSearchPropTypes

const conditionPropTypes = PropTypes.oneOf(Object.values(CONDITION_TYPES))

SelectProductConditions.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    value: selectWithSearchPropTypes.value,
    handleSelect: PropTypes.func.isRequired,
    multiselect: PropTypes.bool,
    selectWithSearchProps: PropTypes.shape({ ...restSelectWithSearchPropTypes }),
    customStyles: PropTypes.object,
    conditionType: conditionPropTypes,
    excludedTypes: PropTypes.arrayOf(conditionPropTypes)
}

export default SelectProductConditions
