import * as constants from "constants/index"
import fetch from "helpers/fetch"

export function getCurrentFolderImagesStart() {
    return {
        type: constants.GET_CURRENT_FOLDER_IMAGES_START
    }
}
export function getCurrentFolderImagesSuccess(data) {
    return {
        type: constants.GET_CURRENT_FOLDER_IMAGES_SUCCESS,
        payload: { data }
    }
}
export function getCurrentFolderImagesError(error) {
    return {
        type: constants.GET_CURRENT_FOLDER_IMAGES_ERROR,
        payload: { error }
    }
}
export function getCurrentFolderImages(folderId) {
    return dispatch => {
        if (!folderId) {
            return dispatch(getCurrentFolderImagesSuccess({ data: { images: [] } }))
        }

        dispatch(getCurrentFolderImagesStart())
        return fetch
            .get(`/folders/${folderId}`)
            .then(data => dispatch(getCurrentFolderImagesSuccess(data)))
            .catch(error => dispatch(getCurrentFolderImagesError(error)))
    }
}

export function getFoldersListStart() {
    return {
        type: constants.GET_FOLDERS_LIST_START
    }
}
export function getFoldersListSuccess(data) {
    return {
        type: constants.GET_FOLDERS_LIST_SUCCESS,
        payload: { data }
    }
}
export function getFoldersListError(error) {
    return {
        type: constants.GET_FOLDERS_LIST_ERROR,
        payload: { error }
    }
}
export function getFoldersList() {
    return dispatch => {
        dispatch(getFoldersListStart())
        return fetch
            .get(`/folders?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getFoldersListSuccess(data)))
            .catch(error => dispatch(getFoldersListError(error)))
    }
}

export function addFolderStart() {
    return {
        type: constants.GET_FOLDERS_LIST_START
    }
}
export function addFolder(body) {
    return dispatch => {
        dispatch(addFolderStart())
        return fetch
            .post("/folders", body)
            .then(() => {
                dispatch(getFoldersList())
            })
            .catch(error => {
                dispatch(getFoldersListError(error))
            })
    }
}

export function changeFolderNameStart() {
    return {
        type: constants.GET_FOLDERS_LIST_START
    }
}

export function changeFolderName(id, body) {
    return dispatch => {
        dispatch(changeFolderNameStart())
        return fetch
            .patch(`/folders/${id}`, body)
            .then(() => {
                dispatch(getFoldersList())
            })
            .catch(error => {
                dispatch(getFoldersListError(error))
            })
    }
}

export function setCurrentFolderId(id = null) {
    return {
        type: constants.SET_CURRENT_FOLDER,
        payload: id
    }
}

export function setImageDraggingStatus(isDragging = false) {
    return {
        type: constants.SET_IMAGE_DRAGGING_STATUS,
        payload: isDragging
    }
}

export function triggerImageSelect(data = []) {
    return {
        type: constants.SELECT_IMAGES,
        payload: data
    }
}

export function prepareImageToSendFromProduct({ productId, imageId, url, name }) {
    return {
        type: constants.TRANSFER_FROM_PRODUCT,
        payload: { productId, imageId, url, name }
    }
}

export function saveTransferedImagesLocally(payload = []) {
    return {
        type: constants.SAVE_TRANSFERED_IMAGES_LOCALLY,
        payload
    }
}
