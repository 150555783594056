import React, { useState } from "react"

import withStyles from "HOC/withStyles"
import { formatPrice } from "helpers/units"

import Product from "../Product"

import styles from "./Room.css"

import expandIcon from "assets/expand.svg"

export const Room = ({ cx, room, options, getProject }) => {
    const [hidden, setHidden] = useState(false)

    const renderProductList = () => {
        if (room.items.length === 0) {
            return null
        }

        return (
            <ul className={cx("productListContainer")}>
                <div className={cx("productListHeader")}>
                    <div>Product name</div>
                    <div>
                        <div>Comments</div>
                        <div>Qty</div>
                        <div>{(options || {}).pricing_visibility && "Price"}</div>
                    </div>
                </div>
                {room.items.map(item => (
                    <Product product={item} key={item.id} options={options} getProject={getProject} />
                ))}
            </ul>
        )
    }

    return (
        <div className={cx("room")}>
            <div className={cx("roomHeader")}>
                <div className={cx("roomTitle")}>
                    <span className={cx("roomName")}>{room.name}</span>
                    {(options || {}).pricing_visibility && (
                        <span className={cx("roomAmounts")}>
                            {room.items.length} products - {formatPrice(room.calculations.total_price)}
                        </span>
                    )}
                </div>
                <span className={cx("hideButton")} onClick={() => setHidden(!hidden)}>
                    <span className={cx("txt")}>{hidden ? "Show" : "Hide"}</span>
                    <img
                        src={expandIcon}
                        alt="expand"
                        className={cx("expandIcon", {
                            areDetailsShown: !hidden
                        })}
                    />
                </span>
            </div>

            {!hidden && <div className={cx("roomProducts")}>{renderProductList()}</div>}
        </div>
    )
}

export default withStyles(Room, styles)
