import React from "react"
import PropTypes from "prop-types"
import { KANBAN_ROW_TYPE } from "@butterfly-frontend/ui"

import { ShipmentKanbanRow } from "modules/WmsModule/components"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import useConsignmentNoteOptions from "modules/WmsModule/hooks/useConsignmentNoteOptions"
import { checkIfCanGenerateConsignmentNote } from "modules/WmsModule/helpers/consignmentNote"
import { SHIPMENT_LIST_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"

const ShipmentAlreadyShippedRow = ({ shipment, rowType, getShipmentHref }) => {
    const disabled = rowType === KANBAN_ROW_TYPE.DANGER

    const { options: boxLabelsOptions, isLoading: isBoxLabelOptionsLoading } = useBoxLabelOptions({
        ids: shipment.stock_items_ids
    })

    const { options: consignmentNoteOptions, isLoading: isConsignmentNoteOptionsLoading } = useConsignmentNoteOptions({
        ids: [shipment.id]
    })

    return (
        <ShipmentKanbanRow
            rowType={rowType}
            shipment={shipment}
            actions={[
                ...(boxLabelsOptions || []),
                ...(checkIfCanGenerateConsignmentNote(shipment) ? consignmentNoteOptions : [])
            ]}
            isLoading={isBoxLabelOptionsLoading || isConsignmentNoteOptionsLoading}
            disabled={disabled}
            shipmentHref={getShipmentHref(shipment)}
        />
    )
}

ShipmentAlreadyShippedRow.propTypes = {
    shipment: SHIPMENT_LIST_ITEM_PROP_TYPE.isRequired,
    rowType: PropTypes.oneOf(Object.values(KANBAN_ROW_TYPE)),
    getShipmentHref: PropTypes.func.isRequired
}

export default ShipmentAlreadyShippedRow
