import React from "react"
import ChooseProductContainer from "./ChooseOrderContainer"
import { Modal } from "ui"

import modalStyles from "./overrides/Modal.css"

const ChooseOrderModal = props => {
    const { isOpen, handleClose } = props

    return (
        <Modal isOpen={isOpen} customStyles={modalStyles} closeModal={handleClose} preventClickOutside={true}>
            <ChooseProductContainer {...props} />
        </Modal>
    )
}

export default ChooseOrderModal
