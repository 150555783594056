import React, { Fragment, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import withStyles from "HOC/withStyles"
import fetch from "helpers/fetch"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import { SelectWithSearch } from "ui"

import AddVendorModal from "./components/AddVendorModal"

import styles from "./VendorSelect.css"
import selectWithSearchOverrides from "./overrides/selectWithSearchStyles.css"

const VendorSelect = ({
    name,
    value,
    label,
    error,
    setValue,
    showAddVendor,
    isRequired,
    isClearBlocked,
    isDisabled
}) => {
    const [vendorsPagination, setVendorsPagination] = useState({ current_page: 0, last_page: 1 })
    const [vendors, setVendors] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [lastQuery, setLastQuery] = useState("")
    const [lastPage, setLastPage] = useState(1)

    useEffect(() => {
        fetchVendors(lastQuery, lastPage)
    }, [lastQuery, lastPage])

    const dispatch = useDispatch()

    const fetchVendors = (query, page) => {
        const formatData = data => {
            return data.map(item => ({ value: item, label: item.name, secondLabel: "" }))
        }

        fetch.get(`/brands?query=${query}&page=${page}&length=15`).then(data => {
            setVendorsPagination({ ...data.meta, current_page: page })

            setVendors(page === 1 ? formatData(data.data) : [...vendors, ...formatData(data.data)])
        })
    }

    const fetchValues = (query, page) => {
        setLastQuery(query)
        setLastPage(page)
    }

    return (
        <Fragment>
            <SelectWithSearch
                name={name}
                value={value}
                error={error}
                values={vendors}
                label={label}
                pagination={vendorsPagination}
                setValue={({ value }) => setValue(value)}
                placeholder="- Please select -"
                fetchValues={fetchValues}
                customStyles={selectWithSearchOverrides}
                additionalButtonLabel={"Create new vendor"}
                additionalButtonAction={showAddVendor ? () => setIsModalOpen(true) : undefined}
                isRequired={isRequired}
                isClearBlocked={typeof isClearBlocked === "boolean" ? isClearBlocked : isRequired}
                isDisabled={isDisabled}
            />

            {isModalOpen && (
                <AddVendorModal
                    isOpen={true}
                    handleClose={() => setIsModalOpen(false)}
                    setVendor={vendor => setValue(vendor)}
                    showSuccessNotification={() => dispatch(showSuccessNotification())}
                    showErrorNotification={() => dispatch(showErrorNotification())}
                />
            )}
        </Fragment>
    )
}

VendorSelect.defaultProps = {
    showAddVendor: false
}

VendorSelect.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    showAddVendor: PropTypes.bool,
    isRequired: PropTypes.bool,
    isClearBlocked: PropTypes.bool,
    isDisabled: PropTypes.bool
}

export default withStyles(VendorSelect, styles)
