import React, { Component } from "react"
import moment from "moment"

import withStyles from "HOC/withStyles"
import styles from "./View.css"
import LinkWithIcon from "components/Reusable/LinkWithIcon/index"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import pencilIcon from "assets/pencil.svg"
import personIcon from "assets/person.svg"
import clockIcon from "assets/clock.svg"

class View extends Component {
    render() {
        const { cx, data, userCanEdit, isLoading, isBlog, isPortfolio } = this.props
        const { cover, id, subtitle, title, author, created_at, description, tags = [] } = data
        let editPath = "/news"
        if (isBlog) {
            editPath = "/content/blog"
        }
        if (isPortfolio) {
            editPath = "/content/portfolio"
        }

        return (
            <div className={cx("root")}>
                <div
                    className={cx("background", { isBackground: cover })}
                    style={cover ? { backgroundImage: `url(${cover})` } : null}
                >
                    {userCanEdit && (
                        <LinkWithIcon icon={pencilIcon} nativeLink={isPortfolio} link={`${editPath}/card/${id}/edit`}>
                            Edit Post
                        </LinkWithIcon>
                    )}
                </div>
                {isLoading ? (
                    <div className={cx("snakeloader")}>
                        <SnakeLoader />
                    </div>
                ) : (
                    <section className={cx("post")}>
                        <h1 className={cx("title")}>{title}</h1>
                        {subtitle && <h2 className={cx("subtitle")}>{subtitle}</h2>}
                        <p className={cx("author")}>
                            by
                            <img src={personIcon} alt="icon" />
                            <span>{author}</span>
                            <img src={clockIcon} alt="icon" />
                            {moment(created_at).format("DD MMM YYYY")}
                        </p>
                        <div className={cx("border")} />
                        <div className={cx("description")} dangerouslySetInnerHTML={{ __html: description }} />
                        <div className={cx("tags")}>
                            {tags.length > 0 && <p>Tags</p>}
                            {tags.map(({ name }) => {
                                return <div key={name}>{name}</div>
                            })}
                        </div>
                    </section>
                )}
            </div>
        )
    }
}

export default withStyles(View, styles)
