import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./newsletter.module.css"
import { Toggle } from "ui"
import moment from "moment"
import customToggleStyles from "./overrides/toggle.module.css"
class Newsletter extends Component {
    toggleValue = () => {
        const { setFieldValue, handleEdit, values } = this.props

        handleEdit(true)
        setFieldValue("newsletter", !values["newsletter"])
    }

    shouldComponentUpdate(nextProps) {
        return this.props.values.newsletter !== nextProps.values.newsletter
    }

    render() {
        const { cx, values } = this.props
        const { newsletter } = values

        return (
            <section className={cx("root")}>
                <h2>Newsletter approval</h2>
                <Toggle
                    customStyles={customToggleStyles}
                    isOn={newsletter}
                    handleChange={() => this.toggleValue()}
                    label={{ on: "Yes", off: "No" }}
                    labelPosition="left"
                />
                {this.renderDescription()}
            </section>
        )
    }

    renderDescription() {
        const { newsletter_changed_at } = this.props.values
        const date = newsletter_changed_at ? moment(newsletter_changed_at).format("YYYY-MM-DD") : "-"

        return (
            <ul>
                <li>
                    Opt-in: <span>No</span>
                </li>
                <li>
                    Since the date: <span>{date}</span>
                </li>
                <li />
                <li>
                    Opt-out: <span>No</span>
                </li>
                <li>
                    Since the date: <span>{date}</span>
                </li>
            </ul>
        )
    }
}

export default withStyles(Newsletter, styles)
