import React, { Fragment } from "react"
import { withRouter } from "react-router-dom"
import ReactSvg from "react-svg"

import { formatDate } from "helpers/date"
import withStyles from "HOC/withStyles"
import { ActionLink } from "../../components"
import Dropdown from "components/Reusable/Dropdown/index"

import styles from "./Head.css"
import stylesDropdown from "./overrides/Dropdown.css"

import iconPerson from "assets/person.svg"
import iconPencil from "assets/pencil.svg"
import iconClose from "assets/close-blue.svg"
import arrow from "assets/arrow_down_blue.svg"

const Head = props => {
    const { cx, item, openDeleteModal, openEditModal, isExporting, handleExport, selectedProducts } = props
    const { client, name, description, created_at, updated_at, isPrivate } = item

    const formatUserName = user => (user ? user.full_name || `${user.first_name || ""} ${user.last_name || ""}` : "-")

    const dropdownElements = [{ label: "Export to CSV", value: "csv" }, { label: "Export to XLS", value: "xls" }]

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <h1 className={cx("name")}>
                    {isPrivate && <span className={cx("nameIcon")}></span>}
                    {name}
                </h1>
                <div className={cx("status")}>
                    <ul className={cx("actionLinkList")}>
                        <li>
                            <Dropdown
                                customStyles={stylesDropdown}
                                isLoading={isExporting}
                                values={dropdownElements.map(element => {
                                    return {
                                        value: element.value,
                                        label: element.label
                                    }
                                })}
                                defaultValue={selectedProducts && selectedProducts.length > 0 ? "Export" : "Export All"}
                                isLabeledValue={true}
                                isLabel={false}
                                arrow={arrow}
                                onClick={event => handleExport(event)}
                                key={1}
                            />
                        </li>
                        <li>
                            <ActionLink onClick={() => openEditModal()}>
                                {({ cx }) => (
                                    <Fragment>
                                        <ReactSvg src={iconPencil} className={cx("icon")} />
                                        <span>Edit</span>
                                    </Fragment>
                                )}
                            </ActionLink>
                        </li>
                        <li>
                            <ActionLink onClick={() => openDeleteModal()}>
                                {({ cx }) => (
                                    <Fragment>
                                        <ReactSvg src={iconClose} className={cx("icon", "close")} />
                                        <span>Delete</span>
                                    </Fragment>
                                )}
                            </ActionLink>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={cx("description")}>{description}</div>
            <div className={cx("line")} />
            <div className={cx("footer")}>
                <ul>
                    <li>
                        <span className={cx("label")}>Created by:</span>
                        <span className={cx("clientValue")}>
                            {client && <img src={iconPerson} alt="" />}
                            {formatUserName(client)}
                        </span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span className={cx("label")}>Created:</span>
                        <span className={cx("value")}>{formatDate(created_at)}</span>
                    </li>
                    <li>
                        <span className={cx("label")}>Last updated:</span>
                        <span className={cx("value")}>{formatDate(updated_at)}</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default withRouter(withStyles(Head, styles))
