import React from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import styles from "./styles.css"
import addIcon from "assets/add.svg"

const EditableList = ({ addUrl, count, cx, isLoading }) => {
    return (
        <div className={cx("root")}>
            <h1 className={cx("list-title")}>
                Brands
                {!isLoading && <span className={cx("list-title-badge")}>{count}</span>}
            </h1>

            <Link to={addUrl}>
                <button className={cx("first-button", "first-button-white")}>
                    <img src={addIcon} alt="addImage" /> Add New Brand
                </button>
            </Link>
        </div>
    )
}

EditableList.propTypes = {
    addUrl: PropTypes.string.isRequired,
    count: PropTypes.number,
    cx: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
}

export default withStyles(EditableList, styles)
