import React, { useState, Fragment, useEffect } from "react"

import { default as ModalComponent } from "ui/Modal"
import Modal from "./modal"
import withStyles from "HOC/withStyles"
import styles from "./styles/modal.css"

const ModalContainer = props => {
    const { isOpen, handleClose, setError, submit, cx, error, saveButtonLabel, title, isFetching, isLoading } = props

    const location = props.location || {}

    const [locationName, setLocationName] = useState(location.name || "")

    useEffect(() => {
        setLocationName(location.name || "")
    }, [location.name, location.description, location.price])

    useEffect(() => {
        !isOpen && setLocationName("")
    }, [isOpen])

    const fieldsProps = {
        locationName: {
            label: (
                <Fragment>
                    {"Location name"}
                    <p className={cx("red-asterix")}>&nbsp;*</p>
                </Fragment>
            ),
            value: locationName,
            onChange: e => {
                setLocationName(e.event.target.value)
                if (error && error.name) {
                    setError({ ...error, name: null })
                }
            },
            error: error && error.name,
            onFocus: () => error && setError({ ...error, name: null })
        }
    }

    const handleButton = () => {
        submit({
            id: location.id,
            name: locationName
        })
    }

    return (
        <ModalComponent isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <Modal
                title={title}
                cx={cx}
                saveButtonLabel={saveButtonLabel}
                handleButton={handleButton}
                closeModal={handleClose}
                fieldsProps={fieldsProps}
                isFetching={isFetching}
                isLoading={isLoading}
            ></Modal>
        </ModalComponent>
    )
}

export default withStyles(ModalContainer, styles)
