import * as constants from "constants/index"

function list(state = {}, action) {
    switch (action.type) {
        case constants.GET_ATTRIBUTES_START:
            return { data: [], isLoading: true }
        case constants.GET_ATTRIBUTES_SUCCESS:
            return { data: action.payload.data.data, isLoading: false }
        case constants.GET_ATTRIBUTES_ERROR:
            return { data: [], isLoading: false }
        default:
            return state
    }
}

export default list
