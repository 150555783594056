export default {
    SALES_ORDER_LIST: [],
    SALES_ORDER_INFO: {
        products: [],
        invoices: [],
        purchaseOrders: []
    },

    INVOICES_LIST: [],

    PURCHASE_ORDERS_LIST: [],

    MEMO_LIST: [],
    MEMO_INFO: {
        products: []
    },

    RENTAL_LIST: [],
    RENTAL_INFO: {
        products: [],
        invoices: []
    },

    PROJECT_INFO: {
        products: [],
        saleOrders: [],
        invoices: [],
        purchaseOrders: []
    },

    CLIENT_INFO: {
        saleOrders: [],
        invoices: [],
        purchaseOrders: []
    },

    PAYMENTS_LIST: []
}
