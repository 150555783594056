import idx from "idx"
import { GET_PURCHASE_ORDER_START, GET_PURCHASE_ORDER_SUCCESS, GET_PURCHASE_ORDER_ERROR } from "constants/index"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"

function current(state = { data: {}, meta: {}, fetchStatus: initialState() }, action) {
    switch (action.type) {
        case GET_PURCHASE_ORDER_START:
            return { data: {}, meta: {}, fetchStatus: requestState() }

        case GET_PURCHASE_ORDER_SUCCESS:
            return {
                data: idx(action, _ => _.payload.data) || {},
                meta: idx(action, _ => _.payload.meta) || {},
                fetchStatus: successState()
            }

        case GET_PURCHASE_ORDER_ERROR:
            return { data: {}, meta: {}, fetchStatus: { ...failureState(), error: action.payload } }

        default:
            return state
    }
}

export default current
