import React, { useEffect, useState, useRef } from "react"
import { useDispatch } from "react-redux"
import debounce from "lodash.debounce"
import env from "env"

import { showErrorNotification, showSuccessNotification } from "actions/notification"
import fetch from "helpers/fetch"
import { slugify } from "helpers/slugify"

import Listing from "./Listing"
import { DEBOUNCE_DRAG_AND_DROP_REQUEST, ITEMS_PER_PAGE } from "constants/index"

const ListingContainer = ({ history }) => {
    const dispatch = useDispatch()

    const [list, setList] = useState([])
    const [listMeta, setListMeta] = useState({
        current_page: 1,
        last_page: 1,
        total: 0
    })
    const [query, setQuery] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false)

    const [isDeleteModalOpenFor, setIsDeleteModalOpenFor] = useState(null)
    const [isChangeActiveModalOpenFor, setIsChangeActiveModalOpenFor] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [isChangingActive, setIsChangingActive] = useState(false)

    const getList = async page => {
        setIsLoading(true)

        try {
            const result = await fetch.get(
                `/realizations?query=${query}&length=${ITEMS_PER_PAGE}&page=${page || listMeta.current_page + 1}`
            )
            setList(page === 1 ? result.data : [...list, ...result.data])
            setListMeta(result.meta)
        } catch (error) {
            dispatch(showErrorNotification())
        }

        setIsLoading(false)
    }

    useEffect(() => {
        getList(1)
    }, [query])

    const handleSearchPortfolio = newQuery => {
        setQuery(newQuery)
    }

    const closeDeleteModal = () => setIsDeleteModalOpenFor(null)
    const closeChangeActiveModal = () => setIsChangeActiveModalOpenFor(null)

    const handleDeletePortfolio = id => {
        setIsDeleting(true)
        fetch
            .deleteRAW(`/realizations/${id}`)
            .then(() => {
                dispatch(showSuccessNotification())
                getList(1)
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                closeDeleteModal()
                setIsDeleting(false)
            })
    }

    const handleChangeActivePortfolio = ({ id, active }) => {
        setIsChangingActive(true)
        fetch
            .patch(`/realizations/${id}`, { active: !active })
            .then(() => {
                dispatch(showSuccessNotification())
                getList(1)
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                closeChangeActiveModal()
                setIsChangingActive(false)
            })
    }

    const onReorder = async items => {
        setList(items)
        debouncedUpdateListOrder(items)
    }

    const debouncedUpdateListOrder = useRef(
        debounce(items => {
            setIsUpdatingOrder(true)
            fetch
                .postRAW("/multi-actions", {
                    ids: items.map(item => item.id),
                    model: "realizations",
                    action: "sort"
                })
                .then(() => dispatch(showSuccessNotification()))
                .catch(() => dispatch(showErrorNotification()))
                .finally(() => setIsUpdatingOrder(false))
        }, DEBOUNCE_DRAG_AND_DROP_REQUEST)
    ).current

    const onPreviewPortfolio = ({ id, title }) =>
        fetch
            .get("/preview?model=realization&id=" + id)
            .then(({ data }) => {
                if (data.preview_token) {
                    const url = `${env.API.webUrl}/project/${slugify(title)}-${id}?preview_token=${data.preview_token}`
                    window.open(url, "_blank")
                }
            })
            .catch(() => dispatch(showErrorNotification()))

    return (
        <Listing
            isLoading={isLoading}
            isUpdatingOrder={isUpdatingOrder}
            query={query}
            handleSearch={handleSearchPortfolio}
            deleteModalData={isDeleteModalOpenFor}
            openDeleteModal={setIsDeleteModalOpenFor}
            closeDeleteModal={closeDeleteModal}
            changeActiveModalData={isChangeActiveModalOpenFor}
            openChangeActiveModal={setIsChangeActiveModalOpenFor}
            closeChangeActiveModal={closeChangeActiveModal}
            isDeleting={isDeleting}
            isChangingActive={isChangingActive}
            deletePortfolio={handleDeletePortfolio}
            changeActivePortfolio={handleChangeActivePortfolio}
            list={list}
            listMeta={listMeta}
            getList={getList}
            history={history}
            onReorder={onReorder}
            onPreviewPortfolio={onPreviewPortfolio}
        />
    )
}

export default ListingContainer
