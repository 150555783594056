export const SORT_OPTIONS = [
    { display: "Name: A-Z", forApi: { sort_by: "name", sort_direction: "asc" } },
    { display: "Name: Z-A", forApi: { sort_by: "name", sort_direction: "desc" } },
    { display: "Newest to Oldest", forApi: { sort_by: "created_at", sort_direction: "desc" } },
    { display: "Oldest to Newest", forApi: { sort_by: "created_at", sort_direction: "asc" } },
    { display: "Price: Low to High", forApi: { sort_by: "price", sort_direction: "asc" } },
    { display: "Price: High to Low", forApi: { sort_by: "price", sort_direction: "desc" } },
    { display: "Brand Name: A-Z", forApi: { sort_by: "brand.name", sort_direction: "asc" } },
    { display: "Brand Name: Z-A", forApi: { sort_by: "brand.name", sort_direction: "desc" } }
]
