import React from "react"

import withStyles from "HOC/withStyles"

import { Note } from "ui/Notes"

import styles from "./clientCommunication.css"
import noteStyles from "./overrides/Note.css"

import closeIcon from "assets/close-blue.svg"
import backIcon from "assets/arrow-dark.svg"
import commentIcon from "assets/comment.svg"

const ClientCommunication = props => {
    const { cx, notes, setDeleteModalID, showCommentModal, hideComments, productName } = props

    return (
        <div className={cx("root")}>
            <div className={cx("topBar")}>
                <img src={backIcon} alt="back" className={cx("backIcon")} onClick={hideComments} />
                <span className={cx("title")}>{productName}</span>
                <img
                    src={commentIcon}
                    alt="comment"
                    className={cx("commentIcon")}
                    onClick={() => showCommentModal({ opened: true, noteId: null, note: "" })}
                />
            </div>

            <div className={cx("content")}>
                <div className={cx("header")}>
                    <h3>
                        Client communication <span>{notes.length}</span>
                    </h3>
                    <button className={cx("closeButton")} onClick={hideComments}>
                        Close
                        <img src={closeIcon} alt="close" />
                    </button>
                </div>
                <ul>
                    {Array.isArray(notes) && !!notes.length ? (
                        notes.map(note => (
                            <Note
                                customStyles={noteStyles}
                                key={note.id}
                                editNote={() => showCommentModal({ opened: true, noteId: note.id, note: note.content })}
                                deleteNote={() => setDeleteModalID(note.id)}
                                {...note}
                                text={note.content}
                                user={note.author || {}}
                                isAnswer={!note.author}
                            />
                        ))
                    ) : (
                        <div className={cx("emptyComments")}>Comments list is empty.</div>
                    )}
                </ul>
            </div>

            <div className={cx("footer")}>&copy; {new Date().getFullYear()} MASS BEVERLY</div>
        </div>
    )
}

export default withStyles(ClientCommunication, styles)
