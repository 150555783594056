import React, { Component } from "react"
import { connect } from "react-redux"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"
import Analytics from "./Analytics"
import * as userActions from "actions/user"
import * as clientActions from "actions/client"
import { getCompanies } from "actions/company"
import { getSources } from "actions/source"
import { getClientGroups } from "actions/clientGroups"
import { getServices } from "actions/service"
import { getChannels } from "actions/channel"

class AnalyticsContainer extends Component {
    state = {
        selectedIds: {
            companies: [],
            types: [],
            sources: [],
            groups: [],
            channels: [],
            services: []
        }
    }

    componentDidMount() {
        const {
            isNewProfile,
            getUsers,
            getCompanies,
            getClientTypes,
            getServices,
            getSources,
            getChannels,
            getClientGroups,
            canView
        } = this.props
        canView() && getUsers()
        getCompanies()
        getClientTypes()
        getSources()
        getClientGroups()
        getServices()
        getChannels()
        !isNewProfile && this.setValues()
    }

    setValues() {
        const { values } = this.props
        const { selectedIds } = this.state

        let preparedSelectedIds = {}
        Object.keys(selectedIds).forEach(key => {
            preparedSelectedIds[key] = values[key] && values[key].length > 0 ? values[key].map(el => el.id) : []
        })

        this.setState({
            selectedIds: preparedSelectedIds
        })
    }

    render() {
        const { selectedIds } = this.state

        return (
            <Analytics
                {...this.props}
                selectedIds={selectedIds}
                handleSelect={this.handleSelect}
                handleChangeGeneralNote={this.handleChangeGeneralNote}
            />
        )
    }

    handleSelect = (key, selectedId) =>
        this.setState(
            prevState => {
                return {
                    selectedIds: {
                        ...prevState.selectedIds,
                        [key]: prevState.selectedIds[key].includes(selectedId)
                            ? prevState.selectedIds[key].filter(id => id !== selectedId)
                            : [...prevState.selectedIds[key], selectedId]
                    }
                }
            },
            () => {
                const { handleEdit, setFieldValue } = this.props
                handleEdit(true)
                setFieldValue(
                    key,
                    this.state.selectedIds[key].map(el => {
                        return { id: el }
                    })
                )
            }
        )

    handleChangeGeneralNote = note => {
        const { handleEdit, setFieldValue } = this.props
        handleEdit(true)
        setFieldValue("general_note", note)
    }
}

const mapStateToProps = state => {
    return {
        users: state.userStore.users,
        companies: state.db.companies.list,
        clientTypes: state.db.clientTypes,
        sources: state.db.sources.list,
        channels: state.db.channels.list,
        services: state.db.services.list,
        clientGroups: state.db.clientGroups.list,
        userId: parseFloat(state.loginStore.userId, 10),
        currentUserRole: state.loginStore.roles
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getClientTypes: () => dispatch(clientActions.getClientTypes()),
        getUsers: () => dispatch(userActions.getUsers()),
        getCompanies: () => dispatch(getCompanies()),
        getSources: () => dispatch(getSources()),
        getClientGroups: () => dispatch(getClientGroups()),
        getServices: () => dispatch(getServices()),
        getChannels: () => dispatch(getChannels())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPermissions(AnalyticsContainer, PERMISSIONS.context.USERS))
