import { useState, useMemo, useCallback, useEffect } from "react"
import isEmpty from "lodash/isEmpty"
import omit from "lodash/omit"

const checkIfIsActiveFilter = filter => (Array.isArray(filter) ? filter.length > 0 : !!filter)

const useProductFilters = ({ initialFilterValues = {}, filtersExcludedFromCounter = [], lockedFilters = {} }) => {
    const [selectedFilterValues, setSelectedFilterValues] = useState({
        ...(initialFilterValues || {}),
        ...(lockedFilters || {})
    })

    const setSelectedFilter = useCallback((key, value) => {
        setSelectedFilterValues(state => ({ ...state, [key]: value }))
    }, [])

    const activeFiltersCount = useMemo(
        () =>
            Object.values(omit(selectedFilterValues, filtersExcludedFromCounter)).filter(checkIfIsActiveFilter).length,
        [selectedFilterValues, filtersExcludedFromCounter]
    )

    useEffect(() => {
        if (!isEmpty(lockedFilters)) {
            setSelectedFilterValues(state => ({ ...state, ...lockedFilters }))
        }
    }, [lockedFilters])

    return {
        setSelectedFilter,
        selectedFilterValues,
        setSelectedFilterValues,
        activeFiltersCount,
        checkIfIsActiveFilter
    }
}

export default useProductFilters
