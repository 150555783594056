import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import { Button } from "ui"
import { connect } from "react-redux"
import styles from "./SavePrompt.css"
import customButtonStyles from "./overrides/Button.css"

class SavePrompt extends Component {
    render() {
        const {
            cx,
            isShown,
            isSidebarMenuWide,
            onCancel = () => {},
            isSaving,
            onSubmit = () => {},
            submitLabel,
            message
        } = this.props

        if (!isShown) {
            return null
        }

        return (
            <footer className={cx("root", { isSidebarMenuWide })}>
                <p>{message}</p>
                <div>
                    <span onClick={() => onCancel()}>Cancel</span>
                    <div className={cx("holder")}>
                        <Button
                            type="submit"
                            customStyles={customButtonStyles}
                            className={["first-button"]}
                            label={submitLabel}
                            onClick={() => onSubmit()}
                            isLoading={isSaving}
                        />
                    </div>
                </div>
            </footer>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSidebarMenuWide: state.menuStore.isSidebarMenuWide
    }
}

export default connect(mapStateToProps)(withStyles(SavePrompt, styles))
