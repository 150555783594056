import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import style from "./Checkbox.css"

class Checkbox extends Component {
    render() {
        const { cx, id, name, checked, onChange, isMinusIcon, isLabelAbsolute = false } = this.props

        return (
            <span className={cx("root")}>
                <label htmlFor={id} className={cx("checkbox-label", { isLabelAbsolute })}>
                    <input
                        tabIndex="-1"
                        type="checkbox"
                        id={id}
                        className={cx("checkbox")}
                        checked={checked}
                        onChange={() => onChange(id)}
                    />
                    <span className={cx("label", { minus: isMinusIcon })}>{name}</span>
                </label>
            </span>
        )
    }
}

export default withStyles(Checkbox, style)
