import { useCallback, useMemo } from "react"
import _isEmpty from "lodash/isEmpty"

import { useLocationSelectWithBoxes } from "modules/WmsModule/hooks"
import getInputPropsFactory from "modules/WmsModule/helpers/getInputPropsFactory"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"

const useBoxListForm = ({ formik, isDisabled }) => {
    const { location, boxes } = formik.values
    const locationSelectValue = location ? { ...location, label: location.name } : null
    const { onChangeSelectLocation, onCreateNewLocationSuccess } = useLocationSelectWithBoxes(formik)

    const getInputProps = getInputPropsFactory(formik)
    const getSelectProps = getSelectPropsFactory(formik)

    const addEmptyBoxes = useCallback(
        (boxesCount = 1) => [...boxes, ...new Array(boxesCount).fill({ id: null, location, label: "" })],
        [boxes, location]
    )

    const addNewBox = useCallback(() => {
        const boxesToAddCount = _isEmpty(boxes) ? 2 : 1
        formik.setFieldValue("boxes", addEmptyBoxes(boxesToAddCount))
    }, [boxes, addEmptyBoxes])

    const onRemoveBox = useCallback(
        indexToRemove => {
            const newData = boxes.filter((box, index) => index !== indexToRemove)
            formik.setFieldValue("boxes", newData)
        },
        [boxes]
    )

    const quantity = useMemo(() => (_isEmpty(boxes) ? 1 : boxes.length), [boxes])

    const getBoxInputProps = boxIndex =>
        getInputProps(`boxes.${boxIndex}.label`, {
            onChange: ({ event }) => formik.setFieldValue(`boxes.${boxIndex}.label`, event.target.value)
        })

    return {
        addNewBox,
        onRemoveBox,
        onChangeSelectLocation,
        onCreateNewLocationSuccess,
        locationSelectValue,
        boxes,
        getBoxInputProps,
        boxTableProps: {
            boxes,
            onRemoveBox,
            getBoxInputProps,
            isDisabled: formik.isSubmitting || isDisabled
        },
        boxCounterProps: {
            handleChange: addNewBox,
            quantity,
            label: "Box: "
        },
        selectLocationProps: {
            handleSelect: onChangeSelectLocation,
            onCreateNewLocationSuccess,
            selectWithSearchProps: getSelectProps("location"),
            value: locationSelectValue
        }
    }
}

export default useBoxListForm
