import React from "react"
import { useParams } from "react-router-dom"

import useStyles from "helpers/getCxFromStyles"
import { DataLayer, ItemList, Navigation } from "modules/AttributeGroupsModules/components"
import { LIST_TYPES } from "modules/AttributeGroupsModules/constants"

import styles from "./ValuesList.css"

const ValuesList = () => {
    const cx = useStyles(styles)
    const { groupId, subgroupId, attributeId } = useParams()

    const getHeaderTitle = resource => (resource && resource.name) || "Attribute values"
    const parseItem = value => ({
        id: value.id,
        value: value.value,
        color: value.color,
        texture: value.texture,
        type: value.attribute_type,
        checked: false
    })

    return (
        <DataLayer
            type={LIST_TYPES.ATTRIBUTE_VALUE}
            getHeaderTitle={getHeaderTitle}
            titleAddButton="Add value(s)"
            placeholderSearch="Search for Attribute Values…"
            parseItem={parseItem}
            resourceChildrenUrl="/attribute-values"
            resourceChildrenParams={{ attribute_id: attributeId }}
            resourceChildrenModelName="AttributeValues"
            resourceUrl={`/attributes/${attributeId}`}
            HeaderComponent={({ items }) => (
                <div className={cx("headerList")}>
                    <div className={cx("headerListLabel", "name")}>VALUE NAME</div>
                    {items.some(({ type }) => type === "color") && (
                        <div className={cx("headerListLabel", "value")}>VALUE</div>
                    )}
                </div>
            )}
            ItemComponent={({ item, handleAction, handleCheck, isDragDisabled }) => (
                <ItemList
                    item={item}
                    onAction={handleAction}
                    onCheck={() => handleCheck(item.id)}
                    key={item.id}
                    isDragDisabled={isDragDisabled}
                >
                    <div className={cx("item")}>
                        <div className={cx("name")}>{item.value}</div>
                        {item.type === "color" && (
                            <div className={cx("value")}>
                                <div
                                    className={cx("color")}
                                    style={{ backgroundImage: `url(${item.texture})`, backgroundColor: item.color }}
                                />
                            </div>
                        )}
                    </div>
                </ItemList>
            )}
            Navigation={() => (
                <Navigation.ArrowBackButton
                    url={`/settings/attribute-groups/${groupId}/subgroups/${subgroupId}/attributes`}
                    label="All Attributes"
                />
            )}
        />
    )
}

export default ValuesList
