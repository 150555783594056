import React from "react"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"
import styles from "./Layout.css"

const Layout = props => {
    const { navigation, header, list, modals } = props
    const cx = useStyles(styles)

    return (
        <div className={cx("root")}>
            {navigation}
            {header}
            {list}
            {modals}
        </div>
    )
}

Layout.propTypes = {
    header: PropTypes.node,
    list: PropTypes.node,
    navigation: PropTypes.node,
    modals: PropTypes.node
}

export default Layout
