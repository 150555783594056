import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import SelectWithSearch, {
    valuesElementShape as selectWithSearchValuePropType
} from "ui/SelectWithSearch/SelectWithSearch"

import useSelectWarehouse from "./useSelectWarehouse"

const SelectWarehouse = ({
    name,
    label,
    placeholder,
    value,
    error,
    setValue,
    isDisabled,
    isRequired,
    renderSticker,
    withPrimaryLabel = false,
    warehouseType,
    multiselect = false,
    additionalValues = [],
    warehouseIds,
    customStyles
}) => {
    const { propsForSelectWithSearch } = useSelectWarehouse({ setValue, withPrimaryLabel, warehouseType, warehouseIds })
    const [valueState, setValueState] = useState(null)

    useEffect(() => {
        setValueState(value)
    }, [value])

    const selectWithSearchValues = useMemo(() => [...propsForSelectWithSearch.values, ...additionalValues], [
        propsForSelectWithSearch.values,
        additionalValues
    ])

    return (
        <SelectWithSearch
            name={name}
            label={label}
            placeholder={placeholder}
            value={valueState}
            error={error}
            isDisabled={isDisabled}
            isRequired={isRequired}
            renderSticker={renderSticker}
            multiselect={multiselect}
            customStyles={customStyles}
            {...propsForSelectWithSearch}
            values={selectWithSearchValues}
        />
    )
}

SelectWarehouse.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    valueSticker: PropTypes.element,
    setValue: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    withPrimaryLabel: PropTypes.bool,
    warehouseType: PropTypes.oneOf(["active", "inactive"]),
    multiselect: PropTypes.bool,
    additionalValues: PropTypes.arrayOf(selectWithSearchValuePropType.isRequired),
    customStyles: PropTypes.any
}

export default SelectWarehouse
