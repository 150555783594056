import * as types from "../../actionTypes"
import * as fetchStatusHelper from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    fetchStatus: fetchStatusHelper.initialState(),
    nextFetchStatus: fetchStatusHelper.initialState()
}

export default (state = initialState, action) => {
    if (types.GET_SERVICES_REQUEST === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.requestState() }
    }

    if (types.GET_SERVICES_SUCCESS === action.type) {
        return {
            ...initialState,
            data: action.payload.data || [],
            meta: action.payload.meta || {},
            fetchStatus: fetchStatusHelper.successState()
        }
    }

    if (types.GET_SERVICES_FAILURE === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.failureState() }
    }

    if (types.GET_MORE_SERVICES_REQUEST === action.type) {
        return { ...state, nextFetchStatus: fetchStatusHelper.requestState() }
    }

    if (types.GET_MORE_SERVICES_SUCCESS === action.type) {
        return {
            ...state,
            data: [...state.data, ...action.payload.data],
            meta: action.payload.meta,
            nextFetchStatus: fetchStatusHelper.successState()
        }
    }

    if (types.GET_MORE_SERVICES_FAILURE === action.type) {
        return { ...initialState, isError: true, nextFetchStatus: fetchStatusHelper.failureState() }
    }

    if (types.RESET_SERVICES === action.type) {
        return { ...initialState, fetchStatus: fetchStatusHelper.initialState() }
    }

    return state
}
