import React, { useState, Fragment } from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import { Modal, Input, Button, ReloadableImageWrap, Image } from "ui"

import styles from "./ChooseProductFromExistingOrder.css"
import inputStyles from "./overrides/Input.css"
import addProductButton from "./overrides/AddProductButton.css"

const ChooseProductFromExistingOrder = props => {
    const { orderItems, handleSelect, cx } = props

    const [isSaving, setIsSaving] = useState(false)
    const [filterData, setFilterData] = useState({ searchPhrase: "", orderItems })

    const onChange = ({ event }) => {
        const searchPhrase = event.target.value
        const isString = value => typeof value === "string"
        const including = phrase => ({ fake_name, name, brand_name }) =>
            [fake_name, name, brand_name].filter(isString).some(str => str.toLowerCase().includes(phrase.toLowerCase()))

        setFilterData({
            searchPhrase,
            orderItems: orderItems.filter(including(searchPhrase))
        })
    }

    return (
        <Fragment>
            <Modal.Header>Order Items</Modal.Header>
            <Modal.Body>{renderBody()}</Modal.Body>
        </Fragment>
    )

    function renderBody() {
        return (
            <Fragment>
                <div className={cx("searchContainer")}>
                    <div className={cx("searchBar")}>
                        <Input
                            name="search"
                            label="Search and add product..."
                            isPlaceholder={true}
                            customStyles={inputStyles}
                            value={filterData.searchPhrase}
                            onChange={onChange}
                            autoComplete="off"
                        />
                    </div>
                </div>
                {renderProductList()}
            </Fragment>
        )
    }

    function renderProductList() {
        return (
            <div className={cx("productListContainer")}>
                {filterData.orderItems.length === 0 ? (
                    <div className={cx("emptyList")}>
                        <p>
                            <b>Sorry, no results found!</b>
                        </p>
                        <p>Please check the spelling or try searching for something else.</p>
                    </div>
                ) : (
                    <ul className={cx("productList")}>
                        {filterData.orderItems.map((item, index) => {
                            return (
                                <li key={index} className={cx("item")}>
                                    <div className={cx("side")}>
                                        <ReloadableImageWrap image={item.image}>
                                            {({ image }) => <Image className={cx("photo")} src={image} />}
                                        </ReloadableImageWrap>
                                        <div className={cx("nameContainer")}>
                                            <div className={cx("name")}>{item.fake_name}</div>
                                            <div className={cx("attributes")}>
                                                {Object.entries(item.attributes || {}).map(([key, value]) => (
                                                    <span key={key} className={cx("attribute")}>
                                                        {key}: <span>{value}</span>
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={cx("side")}>
                                        <Button
                                            label="Add product"
                                            isLoading={isSaving}
                                            customStyles={addProductButton}
                                            onClick={() => {
                                                setIsSaving(true)
                                                if (typeof handleSelect === "function" && !isSaving) {
                                                    handleSelect(item)
                                                }
                                            }}
                                        />
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
        )
    }
}

ChooseProductFromExistingOrder.propTypes = {
    handleSelect: PropTypes.func.isRequired,
    orderItems: PropTypes.array.isRequired
}

export default withStyles(ChooseProductFromExistingOrder, styles)
