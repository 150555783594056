import React, { Fragment, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import _get from "lodash/get"
import classNames from "classnames"

import {
    H1,
    Panel,
    TableFilterBar,
    TableFilterBarSearch,
    TableFilterBarDatepicker,
    TableFilterBarMoreFiltersButton,
    TableFilterBarMoreFilters,
    TableFilterBarMoreFiltersGrid,
    TableFilterBarMoreFiltersGridItem,
    useTableFilterBarMoreFilters,
    useTableFilterBarSearch,
    useTableFilterBarDatepicker,
    useTableSort,
    useTableSelectedRows,
    SnakeLoader,
    EmptyList,
    GridIcon,
    Table,
    TableHead,
    TableSortableHeadCell,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    TableDateCell,
    Button,
    DeleteIcon,
    Checkbox
} from "@butterfly-frontend/ui"

import { Header } from "modules/WmsModule/components"
import { Pagination, Dropdown } from "ui"
import { formatDate, toApiDate } from "helpers/date"
import { useStoreCreditList } from "modules/ClientModule/hooks/api"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import AddEditStoreCreditModal from "modules/OrdersModule/components/AddEditStoreCreditModal"
import DeleteStoreCreditModal from "modules/OrdersModule/components/DeleteStoreCreditModal"
import { InputRangeAmount, SelectClient } from "ui/Filters"
import SummaryItem from "ui/PaymentSummaryItem/PaymentSummaryItem"
import { formatPrice } from "helpers/units"
import { useActions, useExportFile } from "hooks"
import { showErrorNotification } from "actions/notification"

import styles from "./StoreCreditListPage.module.css"

const TABLE_COLUMNS = {
    checkbox: { minWidth: 27, width: 27 },
    amount: { minWidth: 100, width: "12%" },
    client: { minWidth: 100, width: "15%" },
    date: { minWidth: 110, width: "15%" },
    type: { minWidth: 100, width: "10%" },
    note: { minWidth: 100, width: "32%" },
    actions: { minWidth: 140, width: "16%" }
}

const StoreCreditListPage = () => {
    const [addEditStoreCreditModal, setAddEditStoreCreditModal] = useState({ isOpen: false, payment: null })
    const [storeCreditToDelete, setStoreCreditToDelete] = useState(null)

    const { sortState, onChangeSort } = useTableSort({ initialState: { direction: "desc", by: "created_at" } })
    const filterBarSearchProps = useTableFilterBarSearch()
    const filterBarDatepickerProps = useTableFilterBarDatepicker()
    const querySearch = filterBarSearchProps.debouncedValue
    const createdAt = filterBarDatepickerProps.values

    const {
        moreFiltersButtonProps,
        moreFiltersComponentProps,
        ...filterBarMoreFiltersProps
    } = useTableFilterBarMoreFilters({
        emptyFilterValues: {
            createdBy: undefined,
            amount: undefined
        },
        canClear: true
    })

    const filters = useMemo(() => {
        const { createdBy, amount } = filterBarMoreFiltersProps.savedFilterValues

        return {
            length: 20,
            query: querySearch,
            depositor_id: _get(createdBy, "id", undefined),
            amount_min: _get(amount, "amount_min", undefined),
            amount_max: _get(amount, "amount_max", undefined),
            ...(createdAt
                ? {
                      created_from: createdAt ? toApiDate(createdAt.start) : "",
                      created_to: createdAt ? toApiDate(createdAt.end) : ""
                  }
                : {})
        }
    }, [querySearch, createdAt, filterBarMoreFiltersProps.savedFilterValues])

    const { data, meta, fetchStatus, fetchFlags, refetch, pagination } = useTableLogic({
        sort: sortState,
        useQueryList: useStoreCreditList,
        filters
    })

    const {
        selectedRows: selectedStoreCredits,
        isAnyRowSelected,
        areAllRowsOnCurrentPageSelected,
        checkIsRowSelected,
        toggleRowSelection,
        toggleAllCurrentPageRowsSelection
    } = useTableSelectedRows({
        initialSelections: [],
        currentPageRows: data
    })

    const actions = useActions({ showErrorNotification })

    const [handleExport, isExporting] = useExportFile({
        exportData: { objectType: "store-credits" },
        checkedList: selectedStoreCredits.map(({ id }) => id),
        onError: actions.showErrorNotification
    })

    const dropdownElements = [
        { label: "Export to CSV", value: "csv", onClick: () => handleExport({ export: "csv" }) },
        { label: "Export to XLS", value: "xls", onClick: () => handleExport({ export: "xls" }) }
    ]

    return (
        <Fragment>
            <Header>
                <H1 withLeftBorder>Store credits</H1>
                <Dropdown
                    label={
                        <Fragment>
                            {isExporting ? (
                                <SnakeLoader
                                    classes={{
                                        root: styles.dropdownLoaderWrapper,
                                        loader: styles.dropdownLoader
                                    }}
                                />
                            ) : isAnyRowSelected ? (
                                "Export"
                            ) : (
                                "Export all"
                            )}
                        </Fragment>
                    }
                    options={dropdownElements}
                    disabled={isExporting}
                    className={styles.exportAllDropdown}
                    onClick={handleExport}
                    isListOnRight
                />
            </Header>
            <Panel>
                <TableFilterBar
                    moreFiltersComponent={
                        <TableFilterBarMoreFilters {...moreFiltersComponentProps}>
                            <TableFilterBarMoreFiltersGrid>
                                <TableFilterBarMoreFiltersGridItem>
                                    <InputRangeAmount
                                        values={_get(filterBarMoreFiltersProps.selectedFilterValues, "amount", {
                                            amount_min: undefined,
                                            amount_max: undefined
                                        })}
                                        handleSelect={(key, value) =>
                                            filterBarMoreFiltersProps.onChange("amount", {
                                                ...filterBarMoreFiltersProps.selectedFilterValues.amount,
                                                [key]: value
                                            })
                                        }
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectClient
                                        handleSelect={value => filterBarMoreFiltersProps.onChange("createdBy", value)}
                                        label="Client"
                                        placeholder="Please select"
                                        value={_get(filterBarMoreFiltersProps.selectedFilterValues, "createdBy", {})}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                            </TableFilterBarMoreFiltersGrid>
                        </TableFilterBarMoreFilters>
                    }
                >
                    <TableFilterBarSearch placeholder="Search..." {...filterBarSearchProps} />
                    <TableFilterBarDatepicker
                        labelSelectAll="All time"
                        placeholder="Any date created"
                        {...filterBarDatepickerProps}
                    />
                    <TableFilterBarMoreFiltersButton {...moreFiltersButtonProps} />
                </TableFilterBar>
                {fetchStatus.isLoading && <SnakeLoader />}
                {fetchStatus.isLoaded && (
                    <Table>
                        <div className={styles.summaryWrapper}>
                            <SummaryItem label="Store credits" amount={meta.calculations.total_store_credits} />
                            <SummaryItem
                                label="Store credits payments"
                                amount={meta.calculations.store_credits_payments}
                            />
                            <SummaryItem label="Total amount" amount={meta.calculations.total} color="orange" />
                        </div>
                        <div className={styles.table}>
                            <TableHead>
                                <TableHeadCell {...TABLE_COLUMNS.checkbox}>
                                    <Checkbox
                                        checked={areAllRowsOnCurrentPageSelected}
                                        onChange={toggleAllCurrentPageRowsSelection}
                                        disabled={isExporting}
                                    />
                                </TableHeadCell>
                                <TableSortableHeadCell
                                    {...TABLE_COLUMNS.amount}
                                    sortState={sortState}
                                    columnKey="amount"
                                    onClick={onChangeSort}
                                >
                                    AMOUNT
                                </TableSortableHeadCell>
                                <TableSortableHeadCell
                                    {...TABLE_COLUMNS.client}
                                    sortState={sortState}
                                    columnKey="depositor_id"
                                    onClick={onChangeSort}
                                >
                                    CLIENT
                                </TableSortableHeadCell>
                                <TableSortableHeadCell
                                    {...TABLE_COLUMNS.date}
                                    sortState={sortState}
                                    columnKey="date"
                                    onClick={onChangeSort}
                                >
                                    PAYMENT DATE
                                </TableSortableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.type}>TYPE</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.note}>PAYMENT NOTE</TableHeadCell>
                            </TableHead>
                            <TableBody>
                                {fetchFlags.isEmptyList && (
                                    <EmptyList Icon={GridIcon} label="No store credits found." />
                                )}
                                {fetchFlags.isEmptyListWithFilter && (
                                    <EmptyList Icon={GridIcon} label="No records found for selected filters." />
                                )}
                                {fetchStatus.isLoaded &&
                                    data.map(storeCredit => (
                                        <TableRow key={storeCredit.id}>
                                            <TableCell {...TABLE_COLUMNS.checkbox}>
                                                <Checkbox
                                                    checked={checkIsRowSelected(storeCredit)}
                                                    onChange={() => toggleRowSelection(storeCredit)}
                                                    disabled={isExporting}
                                                />
                                            </TableCell>
                                            <TableCell
                                                {...TABLE_COLUMNS.amount}
                                                classes={{
                                                    root: classNames([
                                                        styles.amountCell,
                                                        { [styles.redFont]: storeCredit.store_credit_payment }
                                                    ])
                                                }}
                                            >
                                                {formatPrice(
                                                    storeCredit.store_credit_payment
                                                        ? -storeCredit.amount
                                                        : storeCredit.amount
                                                )}
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.client} classes={{ root: styles.nameCell }}>
                                                <p className={styles.clientName}>
                                                    {storeCredit.depositor.full_name}
                                                    {storeCredit.depositor.vip && (
                                                        <span className={styles.vip}>vip</span>
                                                    )}
                                                </p>
                                                {storeCredit.depositor.company_name && (
                                                    <p className={styles.companyName}>
                                                        {storeCredit.depositor.company_name}
                                                    </p>
                                                )}
                                            </TableCell>
                                            <TableDateCell {...TABLE_COLUMNS.date} classes={{ root: styles.dateCell }}>
                                                {formatDate(storeCredit.date)}
                                            </TableDateCell>
                                            <TableCell {...TABLE_COLUMNS.type} classes={{ root: styles.link }}>
                                                {!!storeCredit.invoice ? (
                                                    <Link
                                                        to={`/orders/invoices/${storeCredit.invoice.id}/edit`}
                                                        className={styles.link}
                                                    >
                                                        {storeCredit.invoice.uuid}
                                                    </Link>
                                                ) : storeCredit.refund &&
                                                  storeCredit.return_shipment &&
                                                  storeCredit.return_shipment.uuid ? (
                                                    <Link
                                                        to={`/orders/returns?query=${storeCredit.return_shipment.uuid}`}
                                                    >
                                                        {storeCredit.return_shipment.uuid}
                                                    </Link>
                                                ) : (
                                                    "Store credit"
                                                )}
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.note} classes={{ root: styles.noteCell }}>
                                                {storeCredit.note}
                                            </TableCell>
                                            <TableCell
                                                {...TABLE_COLUMNS.actions}
                                                classes={{ root: styles.actionsCell }}
                                            >
                                                <Button
                                                    classes={{
                                                        content: styles.actionButtonLabel,
                                                        icon: styles.actionButtonIcon
                                                    }}
                                                    Icon={DeleteIcon}
                                                    variant="flat"
                                                    onClick={() => setStoreCreditToDelete(storeCredit)}
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                            {pagination.canDisplay && (
                                <div className={styles.paginationWrapper}>
                                    <Pagination {...pagination} />
                                </div>
                            )}
                        </div>
                    </Table>
                )}
            </Panel>
            {addEditStoreCreditModal.isOpen && (
                <AddEditStoreCreditModal
                    clientId={addEditStoreCreditModal.payment.depositor.id}
                    payment={addEditStoreCreditModal.payment}
                    handleClose={() => setAddEditStoreCreditModal(state => ({ ...state, isOpen: false }))}
                />
            )}
            {storeCreditToDelete && (
                <DeleteStoreCreditModal
                    onClose={() => {
                        refetch()
                        setStoreCreditToDelete(null)
                    }}
                    storeCredit={storeCreditToDelete}
                />
            )}
        </Fragment>
    )
}

export default StoreCreditListPage
