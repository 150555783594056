const SALES_ORDER_STATUS_KEYS = {
    NEW: "new",
    IN_PROGRESS: "in_progress",
    ON_HOLD: "on_hold",
    AWAITING_PAYMENT: "awaiting_payment",
    SENT: "sent",
    CANCELLED: "cancelled",
    RETURNED: "returned",
    COMPLETED: "completed"
}

export default SALES_ORDER_STATUS_KEYS
