import React, { useMemo, Fragment } from "react"
import PropTypes from "prop-types"
import { Button, PrinterIcon, DropList, DropListDivider, DropListOptions, DropListOption } from "@butterfly-frontend/ui"

import useDocument, { DOCUMENT_ACTIONS } from "modules/WmsModule/hooks/useDocument"
import BOX_LABEL_TYPES from "modules/WmsModule/constants/boxLabelsTypes"
import BOX_LABEL_TEMPLATES from "modules/WmsModule/constants/boxLabelsTemplates"

import styles from "./PrintBoxLabels.module.css"

const PrintBoxLabels = ({ boxes = [], stockItemId, disabled }) => {
    const { mutate: printBoxLabel, isLoading: isPrintingBoxLabel } = useDocument({
        actionType: DOCUMENT_ACTIONS.PRINT,
        documentType: BOX_LABEL_TYPES.BOX,
        templateType: BOX_LABEL_TEMPLATES.WITHOUT_TEMPLATE
    })

    const { mutate: printAllBoxLabels, isLoading: isPrintingAllBoxes } = useDocument({
        actionType: DOCUMENT_ACTIONS.PRINT,
        documentType: BOX_LABEL_TYPES.STOCK_ITEM,
        templateType: BOX_LABEL_TEMPLATES.WITHOUT_TEMPLATE
    })

    const options = useMemo(
        () => [
            {
                onClick: () => printAllBoxLabels({ ids: stockItemId }),
                label: "Print all labels"
            },
            ...boxes.map(({ id }, index) => ({
                onClick: () => printBoxLabel({ ids: [id] }),
                label: `Print box ${index + 1} label`
            }))
        ],
        [boxes, stockItemId, printAllBoxLabels, printBoxLabel]
    )

    const trigger = (
        <Button
            Icon={PrinterIcon}
            variant="flat"
            size="small"
            isLoading={isPrintingBoxLabel || isPrintingAllBoxes}
            disabled={disabled}
        >
            Print box labels
        </Button>
    )

    return disabled ? (
        trigger
    ) : (
        <DropList trigger={trigger}>
            <DropListOptions classes={{ options: styles.optionsSpacer }}>
                {options.map((option, index) => (
                    <Fragment key={option.label}>
                        {index !== 0 && <DropListDivider />}
                        <DropListOption onClick={option.onClick} disabled={option.disabled}>
                            {option.label}
                        </DropListOption>
                    </Fragment>
                ))}
            </DropListOptions>
        </DropList>
    )
}

PrintBoxLabels.propTypes = {
    boxes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number.isRequired })),
    stockItemId: PropTypes.number.isRequired,
    disabled: PropTypes.bool
}

export default PrintBoxLabels
