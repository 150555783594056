import React, { Component } from "react"
import GeneralInfo from "./GeneralInfo"
import { connect } from "react-redux"
import { getChannels } from "actions/channel"
import { ADDRESS_TYPES } from "constants/index"
import { getAddresses } from "helpers/address"

class GeneralInfoContainer extends Component {
    state = {
        addresses: [],
        channels: []
    }

    componentDidMount() {
        const { getChannels } = this.props

        getChannels()
        this.mergeAddresses()
    }

    mergeAddresses = () => {
        const { addresses, first_name, last_name } = this.props

        const namedAddresses = getAddresses(addresses)

        this.setState(() => {
            const mainAddress = {
                ...(namedAddresses.personal || namedAddresses.delivery || namedAddresses.invoice),
                type: "personal",
                name: ADDRESS_TYPES.personal,
                first_name,
                last_name
            }

            const mergedAddresses = Object.keys(namedAddresses).map(
                addressType =>
                    namedAddresses[addressType] || {
                        ...mainAddress,
                        type: addressType,
                        name: ADDRESS_TYPES[addressType]
                    }
            )

            return {
                addresses: mergedAddresses
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.availableChannels.length !== this.props.availableChannels.length &&
            this.props.availableChannels.length > 0 &&
            this.props.channels
        ) {
            this.setState({
                channels: this.props.channels.map(obj => {
                    const channel =
                        this.props.availableChannels && this.props.availableChannels.find(el => el.id === obj.id)
                    return channel ? channel.name : ""
                })
            })
        }
    }

    render() {
        const { channels, addresses } = this.state

        return <GeneralInfo {...this.props} addresses={addresses} channels={channels.join(", ")} />
    }
}

const mapStateToProps = state => {
    return {
        availableChannels: state.db.channels.list.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getChannels: () => dispatch(getChannels())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneralInfoContainer)
