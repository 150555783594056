import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"

const withCrud = ({
    routePrefix = "",
    routePrefixParam = "",
    idParamName = "id",
    parentIdParamName = "parentId",
    Form,
    Listing,
    View
}) => {
    routePrefix = routePrefix + routePrefixParam

    class Enhance extends Component {
        render() {
            return (
                <Switch>
                    {Form && (
                        <Route
                            path={`${routePrefix}/add`}
                            render={routingProps => {
                                const parentId = routingProps.match.params[parentIdParamName]
                                    ? routingProps.match.params[parentIdParamName]
                                    : null
                                return (
                                    <Form routePrefix={routingProps.match.url} parentId={parentId} {...routingProps} />
                                )
                            }}
                        />
                    )}
                    {Form && (
                        <Route
                            path={`${routePrefix}/card/:${idParamName}/edit`}
                            render={routingProps => {
                                const parentId = routingProps.match.params[parentIdParamName]
                                    ? routingProps.match.params[parentIdParamName]
                                    : null
                                return (
                                    <Form
                                        routePrefix={routingProps.match.params.url}
                                        parentId={parentId}
                                        isEdit={true}
                                        {...routingProps}
                                    />
                                )
                            }}
                        />
                    )}
                    {View && (
                        <Route
                            path={`${routePrefix}/card/:${idParamName}`}
                            render={routingProps => <View routePrefix={routingProps.match.url} {...routingProps} />}
                        />
                    )}
                    {Form && (
                        <Route
                            path={`${routePrefix}/:${idParamName}/add`}
                            render={routingProps => {
                                const parentId = routingProps.match.params[parentIdParamName]
                                    ? routingProps.match.params[parentIdParamName]
                                    : null
                                return (
                                    <Form
                                        routePrefix={routingProps.match.url}
                                        parentId={parentId}
                                        isNew={true}
                                        {...routingProps}
                                    />
                                )
                            }}
                        />
                    )}
                    {Listing && (
                        <Route
                            path={`${routePrefix}/:${idParamName}`}
                            render={routingProps => (
                                <Listing
                                    routePrefix={routingProps.match.url}
                                    routePrefixParam={routePrefixParam}
                                    {...routingProps}
                                />
                            )}
                        />
                    )}
                    {Listing && (
                        <Route
                            path={`${routePrefix}`}
                            render={routingProps => {
                                return (
                                    <Listing
                                        routePrefix={routingProps.match.url}
                                        routePrefixParam={routePrefixParam}
                                        {...routingProps}
                                    />
                                )
                            }}
                        />
                    )}
                </Switch>
            )
        }
    }

    return Enhance
}

export default withCrud
