import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"

import styles from "./styles.module.css"

const Header = ({ cx, title, cta }) => {
    return (
        <header className={cx("root")}>
            <h1>{title}</h1>
            <div>{cta}</div>
        </header>
    )
}

Header.propTypes = {
    title: PropTypes.string.isRequired,
    cta: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
}

export default withStyles(Header, styles)
