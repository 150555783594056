import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import styles from "./TemplatesList.module.css"
import FilterableTable, { Sort, Table } from "ui/FilterableTable"

const TemplatesList = ({
    cx,
    sort,
    handleTableChange,
    itemIcon,
    renderActionsButtons,
    renderEmptyList,
    tableData,
    tableHeader
}) => {
    const renderTableHeader = () => (
        <Table.Head>
            <Table.Tr>
                <Table.Th>{sort ? <Sort sortBy="name">{tableHeader}</Sort> : tableHeader}</Table.Th>
                <Table.Th />
            </Table.Tr>
        </Table.Head>
    )
    const renderTableBody = items => {
        const rows = items.map(item => {
            return (
                <Table.Tr key={item.id}>
                    <Table.Td>
                        <p className={cx("table-name")}>
                            {itemIcon && <img src={itemIcon} alt="icon" />}
                            {item.name}
                        </p>
                    </Table.Td>
                    <Table.Td className="textRight">{renderActionsButtons(item)}</Table.Td>
                </Table.Tr>
            )
        })

        return <Table.Body>{rows}</Table.Body>
    }

    return (
        <div className={cx("root")}>
            <FilterableTable
                fetchStatus={tableData.fetchStatus}
                items={tableData.data}
                meta={tableData.meta}
                handleChange={handleTableChange}
            >
                <Table
                    className={cx("table")}
                    renderHeader={renderTableHeader}
                    renderBody={renderTableBody}
                    customEmptyMessage={renderEmptyList}
                />
            </FilterableTable>
        </div>
    )
}

TemplatesList.defaultProps = {
    renderActionsButtons: () => {},
    itemIcon: undefined
}

TemplatesList.propTypes = {
    sort: PropTypes.bool,
    handleTableChange: PropTypes.func,
    itemIcon: PropTypes.string,
    renderActionsButtons: PropTypes.func,
    renderEmptyList: PropTypes.func.isRequired,
    tableData: PropTypes.object.isRequired,
    tableHeader: PropTypes.string.isRequired
}

export default withStyles(TemplatesList, styles)
