import * as constants from "constants/index"
import fetch from "helpers/fetch"

export function getHomePageStart() {
    return {
        type: constants.GET_HOME_PAGE_START
    }
}

export function getHomePageSuccess(data) {
    return {
        type: constants.GET_HOME_PAGE_SUCCESS,
        payload: { data }
    }
}

export function getHomePageError(error) {
    return {
        type: constants.GET_HOME_PAGE_ERROR,
        payload: { error }
    }
}

export function getHomePage(homePageData = {}) {
    const { homePageId, parentCategoryId } = homePageData

    return dispatch => {
        dispatch(getHomePageStart())
        return fetch
            .get(`/home-pages/${homePageId || 1}${parentCategoryId ? `?parent_category_id=${parentCategoryId}` : ""}`)
            .then(data => dispatch(getHomePageSuccess(data)))
            .catch(error => dispatch(getHomePageError(error)))
    }
}

export function getCategoriesStart() {
    return {
        type: constants.GET_MENU_CATEGORIES_START
    }
}

export function getCategoriesSuccess(data) {
    return {
        type: constants.GET_MENU_CATEGORIES_SUCCESS,
        payload: { data }
    }
}

export function getCategoriesError(error) {
    return {
        type: constants.GET_MENU_CATEGORIES_ERROR,
        payload: { error }
    }
}

export function getCategories(categoryData = {}) {
    const { parentCategoryId } = categoryData

    return dispatch => {
        dispatch(getCategoriesStart())
        return fetch
            .get(`/menu-categories${parentCategoryId ? `?parent_category_id=${parentCategoryId}` : ""}`)
            .then(data => dispatch(getCategoriesSuccess(data)))
            .catch(error => dispatch(getCategoriesError(error)))
    }
}

export function updateCategories({ body }) {
    return () => {
        return fetch.post(`/menu-categories`, body)
    }
}

export function updateHomePageStart() {
    return {
        type: constants.UPDATE_HOME_PAGE_START
    }
}

export function updateHomePageSuccess(data) {
    return {
        type: constants.UPDATE_HOME_PAGE_SUCCESS,
        payload: { data }
    }
}

export function updateHomePageError(error) {
    return {
        type: constants.UPDATE_HOME_PAGE_ERROR,
        payload: { error }
    }
}

export function updateHomePage({ body, homePageId = 1, parentCategoryId = false }) {
    return dispatch => {
        dispatch(updateHomePageStart())
        return fetch
            .patch(
                `/home-pages/${homePageId}${parentCategoryId ? `?parent_category_id=${parentCategoryId}` : ""}`,
                body
            )
            .then(data => {
                dispatch(updateHomePageSuccess(data))
                dispatch(getHomePageSuccess(data))
            })
            .catch(error => dispatch(updateHomePageError(error)))
    }
}

export function getHomePageCollectionStart() {
    return {
        type: constants.GET_HOME_PAGE_COLLECTION_START
    }
}

export function getHomePageCollectionSuccess(data) {
    return {
        type: constants.GET_HOME_PAGE_COLLECTION_SUCCESS,
        payload: { data }
    }
}

export function getHomePageCollectionError(error) {
    return {
        type: constants.GET_HOME_PAGE_COLLECTION_ERROR,
        payload: { error }
    }
}

export function getHomePageCollection(id) {
    return dispatch => {
        dispatch(getHomePageCollectionStart())
        return fetch
            .get(`/home-page-collections/${id}`)
            .then(data => dispatch(getHomePageCollectionSuccess(data)))
            .catch(error => dispatch(getHomePageCollectionError(error)))
    }
}

export function updateHomePageCollectionStart() {
    return {
        type: constants.UPDATE_HOME_PAGE_COLLECTION_START
    }
}

export function updateHomePageCollectionSuccess(data) {
    return {
        type: constants.UPDATE_HOME_PAGE_COLLECTION_SUCCESS,
        payload: { data }
    }
}

export function updateHomePageCollectionError(error) {
    return {
        type: constants.UPDATE_HOME_PAGE_COLLECTION_ERROR,
        payload: { error }
    }
}

export function updateHomePageCollection(id, data) {
    return dispatch => {
        dispatch(updateHomePageCollectionStart())
        return fetch
            .patch(`/home-page-collections/${id}`, data)
            .then(data => dispatch(updateHomePageCollectionSuccess(data)))
            .catch(error => dispatch(updateHomePageCollectionError(error)))
    }
}

export function deleteHomePageCollectionStart() {
    return {
        type: constants.DELETE_HOME_PAGE_COLLECTION_START
    }
}

export function deleteHomePageCollectionSuccess(data) {
    return {
        type: constants.DELETE_HOME_PAGE_COLLECTION_SUCCESS,
        payload: { data }
    }
}

export function deleteHomePageCollectionError(error) {
    return {
        type: constants.DELETE_HOME_PAGE_COLLECTION_ERROR,
        payload: { error }
    }
}

export function deleteHomePageCollection(id) {
    return dispatch => {
        dispatch(deleteHomePageCollectionStart())
        return fetch
            .delete(`/home-page-collections/${id}`)
            .then(data => dispatch(deleteHomePageCollectionSuccess(data)))
            .catch(error => dispatch(deleteHomePageCollectionError(error)))
    }
}

export function createHomePageCollectionStart() {
    return {
        type: constants.CREATE_HOME_PAGE_COLLECTION_START
    }
}

export function createHomePageCollectionSuccess(data) {
    return {
        type: constants.CREATE_HOME_PAGE_COLLECTION_SUCCESS,
        payload: { data }
    }
}

export function createHomePageCollectionError(error) {
    return {
        type: constants.CREATE_HOME_PAGE_COLLECTION_ERROR,
        payload: { error }
    }
}

export function createHomePageCollection(body) {
    return dispatch => {
        dispatch(createHomePageCollectionStart())
        return fetch
            .post(`/home-page-collections`, body)
            .then(data => dispatch(createHomePageCollectionSuccess(data)))
            .catch(error => dispatch(createHomePageCollectionError(error)))
    }
}

export function getHomePagePostStart() {
    return {
        type: constants.GET_HOME_PAGE_POST_START
    }
}

export function getHomePagePostSuccess(data) {
    return {
        type: constants.GET_HOME_PAGE_POST_SUCCESS,
        payload: { data }
    }
}

export function getHomePagePostError(error) {
    return {
        type: constants.GET_HOME_PAGE_POST_ERROR,
        payload: { error }
    }
}

export function getHomePagePost(id) {
    return dispatch => {
        dispatch(getHomePagePostStart())
        return fetch
            .get(`/home-page-posts/${id}`)
            .then(data => dispatch(getHomePagePostSuccess(data)))
            .catch(error => dispatch(getHomePagePostError(error)))
    }
}

export function updateHomePagePostStart() {
    return {
        type: constants.UPDATE_HOME_PAGE_POST_START
    }
}

export function updateHomePagePostSuccess(data) {
    return {
        type: constants.UPDATE_HOME_PAGE_POST_SUCCESS,
        payload: { data }
    }
}

export function updateHomePagePostError(error) {
    return {
        type: constants.UPDATE_HOME_PAGE_POST_ERROR,
        payload: { error }
    }
}

export function updateHomePagePost(id, data) {
    return dispatch => {
        dispatch(updateHomePagePostStart())
        return fetch
            .patch(`/home-page-posts/${id}`, data)
            .then(data => dispatch(updateHomePagePostSuccess(data)))
            .catch(error => dispatch(updateHomePagePostError(error)))
    }
}

export function deleteHomePagePostStart() {
    return {
        type: constants.DELETE_HOME_PAGE_POST_START
    }
}

export function deleteHomePagePostSuccess(data) {
    return {
        type: constants.DELETE_HOME_PAGE_POST_SUCCESS,
        payload: { data }
    }
}

export function deleteHomePagePostError(error) {
    return {
        type: constants.DELETE_HOME_PAGE_POST_ERROR,
        payload: { error }
    }
}

export function deleteHomePagePost(id) {
    return dispatch => {
        dispatch(deleteHomePagePostStart())
        return fetch
            .delete(`/home-page-posts/${id}`)
            .then(data => dispatch(deleteHomePagePostSuccess(data)))
            .catch(error => dispatch(deleteHomePagePostError(error)))
    }
}

export function createHomePagePostStart() {
    return {
        type: constants.CREATE_HOME_PAGE_POST_START
    }
}

export function createHomePagePostSuccess(data) {
    return {
        type: constants.CREATE_HOME_PAGE_POST_SUCCESS,
        payload: { data }
    }
}

export function createHomePagePostError(error) {
    return {
        type: constants.CREATE_HOME_PAGE_POST_ERROR,
        payload: { error }
    }
}

export function createHomePagePost(body) {
    return dispatch => {
        dispatch(createHomePagePostStart())
        return fetch
            .post(`/home-page-posts`, body)
            .then(data => dispatch(createHomePagePostSuccess(data)))
            .catch(error => dispatch(createHomePagePostError(error)))
    }
}

export function getHomePageRealizationStart() {
    return {
        type: constants.GET_HOME_PAGE_REALIZATION_START
    }
}

export function getHomePageRealizationSuccess(data) {
    return {
        type: constants.GET_HOME_PAGE_REALIZATION_SUCCESS,
        payload: { data }
    }
}

export function getHomePageRealizationError(error) {
    return {
        type: constants.GET_HOME_PAGE_REALIZATION_ERROR,
        payload: { error }
    }
}

export function getHomePageRealization(id) {
    return dispatch => {
        dispatch(getHomePageRealizationStart())
        return fetch
            .get(`/home-page-realizations/${id}`)
            .then(data => dispatch(getHomePageRealizationSuccess(data)))
            .catch(error => dispatch(getHomePageRealizationError(error)))
    }
}

export function createHomePageRealizationStart() {
    return {
        type: constants.CREATE_HOME_PAGE_REALIZATION_START
    }
}

export function createHomePageRealizationSuccess(data) {
    return {
        type: constants.CREATE_HOME_PAGE_REALIZATION_SUCCESS,
        payload: { data }
    }
}

export function createHomePageRealizationError(error) {
    return {
        type: constants.CREATE_HOME_PAGE_REALIZATION_ERROR,
        payload: { error }
    }
}

export function createHomePageRealization(body) {
    return dispatch => {
        dispatch(createHomePageRealizationStart())
        return fetch
            .post(`/home-page-realizations`, body)
            .then(data => dispatch(createHomePageRealizationSuccess(data)))
            .catch(error => dispatch(createHomePageRealizationError(error)))
    }
}

export function updateHomePageRealizationStart() {
    return {
        type: constants.UPDATE_HOME_PAGE_REALIZATION_START
    }
}

export function updateHomePageRealizationSuccess(data) {
    return {
        type: constants.UPDATE_HOME_PAGE_REALIZATION_SUCCESS,
        payload: { data }
    }
}

export function updateHomePageRealizationError(error) {
    return {
        type: constants.UPDATE_HOME_PAGE_REALIZATION_ERROR,
        payload: { error }
    }
}

export function updateHomePageRealization(id, data) {
    return dispatch => {
        dispatch(updateHomePageRealizationStart())
        return fetch
            .patch(`/home-page-realizations/${id}`, data)
            .then(data => dispatch(updateHomePageRealizationSuccess(data)))
            .catch(error => dispatch(updateHomePageRealizationError(error)))
    }
}

export function deleteHomePageRealizationStart() {
    return {
        type: constants.DELETE_HOME_PAGE_REALIZATION_START
    }
}

export function deleteHomePageRealizationSuccess(data) {
    return {
        type: constants.DELETE_HOME_PAGE_REALIZATION_SUCCESS,
        payload: { data }
    }
}

export function deleteHomePageRealizationError(error) {
    return {
        type: constants.DELETE_HOME_PAGE_REALIZATION_ERROR,
        payload: { error }
    }
}

export function deleteHomePageRealization(id) {
    return dispatch => {
        dispatch(deleteHomePageRealizationStart())
        return fetch
            .delete(`/home-page-realizations/${id}`)
            .then(data => dispatch(deleteHomePageRealizationSuccess(data)))
            .catch(error => dispatch(deleteHomePageRealizationError(error)))
    }
}

export function createHomePageImmediateDeliveryStart() {
    return {
        type: constants.CREATE_HOME_PAGE_IMMEDIATE_DELIVERY_START
    }
}

export function createHomePageImmediateDeliverySuccess(data) {
    return {
        type: constants.CREATE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS,
        payload: { data }
    }
}

export function createHomePageImmediateDeliveryError(error) {
    return {
        type: constants.CREATE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR,
        payload: { error }
    }
}

export function createHomePageImmediateDelivery(body) {
    return dispatch => {
        dispatch(createHomePageImmediateDeliveryStart())
        return fetch
            .post(`/home-page-immediate-deliveries`, body)
            .then(data => dispatch(createHomePageImmediateDeliverySuccess(data)))
            .catch(error => dispatch(createHomePageImmediateDeliveryError(error)))
    }
}

export function deleteHomePageImmediateDeliveryStart() {
    return {
        type: constants.DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_START
    }
}

export function deleteHomePageImmediateDeliverySuccess(data) {
    return {
        type: constants.DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS,
        payload: { data }
    }
}

export function deleteHomePageImmediateDeliveryError(error) {
    return {
        type: constants.DELETE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR,
        payload: { error }
    }
}

export function deleteHomePageImmediateDelivery(id) {
    return dispatch => {
        dispatch(deleteHomePageImmediateDeliveryStart())
        return fetch
            .delete(`/home-page-immediate-deliveries/${id}`)
            .then(data => dispatch(deleteHomePageImmediateDeliverySuccess(data)))
            .catch(error => dispatch(deleteHomePageImmediateDeliveryError(error)))
    }
}

export function getHomePageImmediateDeliveryStart() {
    return {
        type: constants.GET_HOME_PAGE_IMMEDIATE_DELIVERY_START
    }
}

export function getHomePageImmediateDeliverySuccess(data) {
    return {
        type: constants.GET_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS,
        payload: { data }
    }
}

export function getHomePageImmediateDeliveryError(error) {
    return {
        type: constants.GET_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR,
        payload: { error }
    }
}

export function getHomePageImmediateDelivery(id) {
    return dispatch => {
        dispatch(getHomePageImmediateDeliveryStart())
        return fetch
            .get(`/home-page-immediate-deliveries/${id}`)
            .then(data => dispatch(getHomePageImmediateDeliverySuccess(data)))
            .catch(error => dispatch(getHomePageImmediateDeliveryError(error)))
    }
}

export function updateHomePageImmediateDeliveryStart() {
    return {
        type: constants.UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_START
    }
}

export function updateHomePageImmediateDeliverySuccess(data) {
    return {
        type: constants.UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_SUCCESS,
        payload: { data }
    }
}

export function updateHomePageImmediateDeliveryError(error) {
    return {
        type: constants.UPDATE_HOME_PAGE_IMMEDIATE_DELIVERY_ERROR,
        payload: { error }
    }
}

export function updateHomePageImmediateDelivery(id, data) {
    return dispatch => {
        dispatch(updateHomePageImmediateDeliveryStart())
        return fetch
            .patch(`/home-page-immediate-deliveries/${id}`, data)
            .then(data => dispatch(updateHomePageImmediateDeliverySuccess(data)))
            .catch(error => dispatch(updateHomePageImmediateDeliveryError(error)))
    }
}

export function createHomePageOurFavouritesStart() {
    return {
        type: constants.CREATE_HOME_PAGE_OUR_FAVOURITES_START
    }
}

export function createHomePageOurFavouritesSuccess(data) {
    return {
        type: constants.CREATE_HOME_PAGE_OUR_FAVOURITES_SUCCESS,
        payload: { data }
    }
}

export function createHomePageOurFavouritesError(error) {
    return {
        type: constants.CREATE_HOME_PAGE_OUR_FAVOURITES_ERROR,
        payload: { error }
    }
}

export function createHomePageOurFavourites(body) {
    return dispatch => {
        dispatch(createHomePageOurFavouritesStart())
        return fetch
            .post(`/home-page-favourites`, body)
            .then(data => dispatch(createHomePageOurFavouritesSuccess(data)))
            .catch(error => dispatch(createHomePageOurFavouritesError(error)))
    }
}

export function getHomePageOurFavouriteStart() {
    return {
        type: constants.GET_HOME_PAGE_OUR_FAVOURITE_START
    }
}

export function getHomePageOurFavouriteSuccess(data) {
    return {
        type: constants.GET_HOME_PAGE_OUR_FAVOURITE_SUCCESS,
        payload: { data }
    }
}

export function getHomePageOurFavouriteError(error) {
    return {
        type: constants.GET_HOME_PAGE_OUR_FAVOURITE_ERROR,
        payload: { error }
    }
}

export function getHomePageOurFavourite(id) {
    return dispatch => {
        dispatch(getHomePageOurFavouriteStart())
        return fetch
            .get(`/home-page-favourites/${id}`)
            .then(data => dispatch(getHomePageOurFavouriteSuccess(data)))
            .catch(error => dispatch(getHomePageOurFavouriteError(error)))
    }
}

export function updateHomePageOurFavouriteStart() {
    return {
        type: constants.UPDATE_HOME_PAGE_OUR_FAVOURITE_START
    }
}

export function updateHomePageOurFavouriteSuccess(data) {
    return {
        type: constants.UPDATE_HOME_PAGE_OUR_FAVOURITE_SUCCESS,
        payload: { data }
    }
}

export function updateHomePageOurFavouriteError(error) {
    return {
        type: constants.UPDATE_HOME_PAGE_OUR_FAVOURITE_ERROR,
        payload: { error }
    }
}

export function updateHomePageOurFavourite(id, data) {
    return dispatch => {
        dispatch(updateHomePageOurFavouriteStart())
        return fetch
            .patch(`/home-page-favourites/${id}`, data)
            .then(data => dispatch(updateHomePageOurFavouriteSuccess(data)))
            .catch(error => dispatch(updateHomePageOurFavouriteError(error)))
    }
}

export function deleteHomePageOurFavouriteStart() {
    return {
        type: constants.DELETE_HOME_PAGE_OUR_FAVOURITE_START
    }
}

export function deleteHomePageOurFavouriteSuccess(data) {
    return {
        type: constants.DELETE_HOME_PAGE_OUR_FAVOURITE_SUCCESS,
        payload: { data }
    }
}

export function deleteHomePageOurFavouriteError(error) {
    return {
        type: constants.DELETE_HOME_PAGE_OUR_FAVOURITE_ERROR,
        payload: { error }
    }
}

export function deleteHomePageOurFavourite(id) {
    return dispatch => {
        dispatch(deleteHomePageOurFavouriteStart())
        return fetch
            .delete(`/home-page-favourites/${id}`)
            .then(data => dispatch(deleteHomePageOurFavouriteSuccess(data)))
            .catch(error => dispatch(deleteHomePageOurFavouriteError(error)))
    }
}

export function createHomePageHeaderStart() {
    return {
        type: constants.CREATE_HOME_PAGE_HEADER_START
    }
}

export function createHomePageHeaderSuccess(data) {
    return {
        type: constants.CREATE_HOME_PAGE_HEADER_SUCCESS,
        payload: { data }
    }
}

export function createHomePageHeaderError(error) {
    return {
        type: constants.CREATE_HOME_PAGE_HEADER_ERROR,
        payload: { error }
    }
}

export function createHomePageHeader(body) {
    return dispatch => {
        dispatch(createHomePageHeaderStart())
        return fetch
            .post(`/home-page-headers`, body)
            .then(data => dispatch(createHomePageHeaderSuccess(data)))
            .catch(error => dispatch(createHomePageHeaderError(error)))
    }
}

export function deleteHomePageHeaderStart() {
    return {
        type: constants.DELETE_HOME_PAGE_HEADER_START
    }
}

export function deleteHomePageHeaderSuccess(data) {
    return {
        type: constants.DELETE_HOME_PAGE_HEADER_SUCCESS,
        payload: { data }
    }
}

export function deleteHomePageHeaderError(error) {
    return {
        type: constants.DELETE_HOME_PAGE_HEADER_ERROR,
        payload: { error }
    }
}

export function deleteHomePageHeader(id) {
    return dispatch => {
        dispatch(deleteHomePageHeaderStart())
        return fetch
            .delete(`/home-page-headers/${id}`)
            .then(data => dispatch(deleteHomePageHeaderSuccess(data)))
            .catch(error => dispatch(deleteHomePageHeaderError(error)))
    }
}

export function getHomePageHeaderStart() {
    return {
        type: constants.GET_HOME_PAGE_HEADER_START
    }
}

export function getHomePageHeaderSuccess(data) {
    return {
        type: constants.GET_HOME_PAGE_HEADER_SUCCESS,
        payload: { data }
    }
}

export function getHomePageHeaderError(error) {
    return {
        type: constants.GET_HOME_PAGE_HEADER_ERROR,
        payload: { error }
    }
}

export function getHomePageHeader(id) {
    return dispatch => {
        dispatch(getHomePageHeaderStart())
        return fetch
            .get(`/home-page-headers/${id}`)
            .then(data => dispatch(getHomePageHeaderSuccess(data)))
            .catch(error => dispatch(getHomePageHeaderError(error)))
    }
}

export function updateHomePageHeaderStart() {
    return {
        type: constants.UPDATE_HOME_PAGE_HEADER_START
    }
}

export function updateHomePageHeaderSuccess(data) {
    return {
        type: constants.UPDATE_HOME_PAGE_HEADER_SUCCESS,
        payload: { data }
    }
}

export function updateHomePageHeaderError(error) {
    return {
        type: constants.UPDATE_HOME_PAGE_HEADER_ERROR,
        payload: { error }
    }
}

export function updateHomePageHeader(id, data) {
    return dispatch => {
        dispatch(updateHomePageHeaderStart())
        return fetch
            .patch(`/home-page-headers/${id}`, data)
            .then(data => dispatch(updateHomePageHeaderSuccess(data)))
            .catch(error => dispatch(updateHomePageHeaderError(error)))
    }
}
