import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getUsersListDataStart() {
    return {
        type: constants.GET_USERS_LIST_DATA_START
    }
}
export function getUsersListDataSuccess(data) {
    return {
        type: constants.GET_USERS_LIST_DATA_SUCCESS,
        payload: {
            data
        }
    }
}
export function getUsersListDataError(error) {
    return {
        type: constants.GET_USERS_LIST_DATA_ERROR,
        payload: {
            error
        }
    }
}
export function getUsersList() {
    return dispatch => {
        dispatch(getUsersListDataStart())
        fetch
            .get(`/user?length=9999`)
            .then(data => {
                dispatch(getUsersListDataSuccess(data))
            })
            .catch(error => {
                dispatch(getUsersListDataError(error))
            })
    }
}
