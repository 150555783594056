import fetch from "helpers/fetch"
import { serializeQuery } from "helpers/urls"
import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"
import useMutationFactory from "modules/WmsModule/hooks/factories/useMutationFactory"

export const KEY = "payments"

const fetchPaymentsList = ({
    created_from = "",
    created_to = "",
    sort_by = "id",
    sort_direction = "asc",
    page = 1,
    length = 20
}) => {
    const queryString = serializeQuery(
        {
            created_from,
            created_to,
            sort_by,
            sort_direction,
            page,
            length
        },
        "",
        true
    )
    return fetch.get(`/payments?${queryString}`)
}

const fetchPaymentEdit = ({ id, data }) => fetch.patch(`/payments/${id}`, data)
const fetchPaymentDelete = ({ id, data }) => fetch.deleteRAW(`/payments/${id}`, data)

export const usePaymentsList = useQueryListFactory({
    key: KEY,
    fetchFunction: fetchPaymentsList
})

export const usePaymentEdit = useMutationFactory({ key: KEY, fetchFunction: fetchPaymentEdit })
export const usePaymentDelete = useMutationFactory({ key: KEY, fetchFunction: fetchPaymentDelete })
