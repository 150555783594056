import * as constants from "constants/index"

function current(state = {}, action) {
    switch (action.type) {
        case constants.GET_COMBINATION_BY_ID_START:
            return { ...state, isLoading: true, data: {} }
        case constants.GET_COMBINATION_BY_ID_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data.data }
        case constants.GET_COMBINATION_BY_ID_ERROR:
            return { ...state, isLoading: false, data: {} }

        default:
            return state
    }
}

export default current
