import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import fetch from "helpers/fetch"
import { SelectWithSearch } from "ui"

import REASON_TYPES from "modules/WmsModule/constants/reasonTypes"

const reasonOptionsFactory = reasons => reasons.map(({ id, reason }) => ({ label: reason, value: id }))

const SelectChangeReason = ({ name, label, value, error, setValue, isDisabled, reasonType, isRequired }) => {
    const [reasons, setReasons] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const changeReasonOptions = useMemo(() => reasonOptionsFactory(reasons), [reasons])

    const fetchReasons = () => {
        setIsLoading(true)
        fetch
            .get(`/reasons?length=9999&type=${reasonType || ""}`)
            .then(data => {
                setReasons(data.data)
            })
            .finally(() => setIsLoading(false))
    }

    useEffect(fetchReasons, [reasonType])

    return (
        <SelectWithSearch
            name={name}
            label={label}
            value={value}
            values={changeReasonOptions}
            error={error}
            setValue={setValue}
            showLoadingIcon={isLoading}
            isDisabled={isDisabled}
            isRequired={isRequired}
            isClearBlocked
            withoutFetch
        />
    )
}

SelectChangeReason.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    reasonType: PropTypes.oneOf(Object.values(REASON_TYPES))
}

export default SelectChangeReason
