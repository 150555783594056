import React, { Component } from "react"

import { withStyles } from "ui/FilterableTable/HOC"
import { AppContext } from "ui/FilterableTable"
import styles from "./Filter.css"
import selectStyles from "./overrides/Select.css"
import Select from "components/Reusable/Form/Select"

class FilterConsumer extends Component {
    render() {
        return <AppContext.Consumer>{context => <Filter {...this.props} context={context} />}</AppContext.Consumer>
    }
}

class Filter extends Component {
    state = {
        value: null
    }

    componentDidMount() {
        const { context, name, children } = this.props

        const items = React.Children.map(children, child => ({
            value: child.props.value,
            selected: child.props.selected
        }))

        if (!items.length) {
            return
        }

        const found = items.find(item => item.selected)

        const value = found ? found.value : items[0].value

        context.initFilter(name, value)
    }

    render() {
        const { cx, customClass, name, label, context, children } = this.props

        const filter = context.data.filters.find(item => item.name === name)

        return (
            <div className={cx("root", customClass)}>
                <Select
                    label={label}
                    value={this.state.value ? this.state.value : filter ? filter.value : null}
                    values={React.Children.map(children, child => {
                        return {
                            value: child.props.value,
                            label: child.props.children
                        }
                    })}
                    defaultValue="All"
                    isLabeledValue={true}
                    isLabel={true}
                    customStyles={selectStyles}
                    onClick={({
                        event: {
                            target: { value }
                        }
                    }) => {
                        this.setState({ value: value })
                        context.changeFilter(name, value)
                    }}
                />
            </div>
        )
    }
}

export default withStyles(styles)(FilterConsumer)
