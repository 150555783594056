import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./ImagesSection.css"

import { Button } from "ui"

class ImagesSection extends Component {
    render() {
        const { cx, fields, activeTake, handleTake, handleDeleteImage } = this.props

        return (
            <div className={cx("root")}>
                <label>Photos:</label>
                <div>
                    {fields.img &&
                        Object.keys(fields.img).map((image, index) => {
                            return (
                                <span key={index} style={{ backgroundImage: "url(" + image + ")" }}>
                                    <span onClick={() => handleDeleteImage(image)}>x</span>
                                </span>
                            )
                        })}
                </div>

                <Button
                    className={cx("takeButton", { isActive: activeTake === "img" })}
                    onClick={() => {
                        handleTake({ type: "IMAGE", field: "img" })
                    }}
                    label=""
                />
            </div>
        )
    }
}

export default withStyles(ImagesSection, styles)
