import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import _get from "lodash/get"

import styles from "./Header.css"

export default function Header({ children, classes }) {
    return <div className={classNames(styles.header, _get(classes, "header"))}>{children}</div>
}

Header.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    classes: PropTypes.shape({
        header: PropTypes.string
    })
}
