import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./taxcode.module.css"
import { Input, Toggle } from "ui"
import { roundNumber } from "helpers/units"
import { Decimal } from "decimal.js"

import customToggleStyles from "./overrides/toggle.module.css"

class TaxCode extends Component {
    render() {
        const { customInputStyles, cx, setFieldValue, values, handleEdit } = this.props
        const { tax_code, reseller, taxable } = values

        const percentageTaxCode = tax_code ? roundNumber(new Decimal(tax_code).times(100), 6) + "%" : ""

        const setValue = key => {
            handleEdit(true)
            setFieldValue(key, !values[key])
        }

        return (
            <section className={cx("root")}>
                <h2>Tax Details</h2>
                <ul>
                    <li>
                        <Input
                            customStyles={customInputStyles}
                            type="text"
                            value={percentageTaxCode}
                            name="tax_code"
                            id="tax_code"
                            label="Tax Code"
                            isDisabled
                        />
                    </li>
                    <li className={cx("row")}>
                        <span>Resale certified</span>
                        <Toggle
                            customStyles={customToggleStyles}
                            isOn={reseller}
                            handleChange={() => setValue("reseller")}
                            label={{ on: "Yes", off: "No" }}
                            labelPosition="left"
                        />
                    </li>
                    <li className={cx("row")}>
                        <span>Taxable</span>
                        <Toggle
                            customStyles={customToggleStyles}
                            isOn={taxable}
                            handleChange={() => setValue("taxable")}
                            label={{ on: "Yes", off: "No" }}
                            labelPosition="left"
                        />
                    </li>
                </ul>
            </section>
        )
    }
}

export default withStyles(TaxCode, styles)
