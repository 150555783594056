import fetch from "helpers/fetch"
import { serializeQuery } from "helpers/urls"
import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"
import useQueryDetailsFactory from "modules/WmsModule/hooks/factories/useQueryDetailsFactory"

const KEY = "stockAdjustments"

const fetchStockAdjustmentList = ({
    id = "",
    sort_by = "product",
    sort_direction = "asc",
    page = 1,
    length = 20,
    query = "",
    created_to = "",
    created_from = "",
    locations = [],
    warehouses = [],
    statuses = [],
    conditions = [],
    creators = ""
}) => {
    const queryString = serializeQuery({
        id,
        query,
        sort_by,
        sort_direction,
        page,
        length,
        statuses,
        conditions,
        warehouses,
        locations,
        created_to,
        created_from,
        creators
    })
    return fetch.get(`/stock-adjustments?${queryString}`)
}

const fetchStockAdjustmentDetails = id => fetch.get(`/stock-adjustments/${id}`)

export const useStockAdjustmentList = useQueryListFactory({
    key: KEY,
    fetchFunction: fetchStockAdjustmentList
})
export const useStockAdjustmentDetails = useQueryDetailsFactory({
    key: KEY,
    fetchFunction: fetchStockAdjustmentDetails
})
