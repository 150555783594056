import React, { useState, useEffect } from "react"
import { SelectWithSearch } from "ui"
import fetch from "helpers/fetch"
import { displayUserName } from "helpers/user"

const SelectUser = props => {
    const { customStyles, name, label, selectWithSearchProps, multiselect, placeholder } = props
    const [pagination, setPagination] = useState({ current_page: 0, last_page: 1 })
    const [values, setValues] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [rawData] = useState([])

    // TODO usunąć useEffect kiedy BE zrobi search dla userów
    useEffect(() => {
        fetchUsers("", 1)
    }, [])

    const fetchUsers = (query, page) => {
        if (isLoading) return

        const formatData = data => {
            return data.map(item => ({
                id: item.id,
                value: item,
                label: displayUserName(item),
                secondLabel: ""
            }))
        }

        setIsLoading(true)

        fetch
            // TODO: zmienic length kiedy BE bedzie obslugiwal search dla userow
            .get(`/user?query=${query}&page=${page}&length=9999`)
            .then(data => {
                setValues(page === 1 ? formatData(data.data) : [...rawData, ...formatData(data.data)])
                setPagination({ ...data.meta, current_page: page })
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <SelectWithSearch
            multiselect={multiselect}
            name={name}
            label={label}
            value={props.value}
            values={values}
            pagination={pagination}
            setValue={setValue}
            placeholder={placeholder}
            fetchValues={fetchUsers}
            customStyles={customStyles}
            withoutFetch // TODO usunąć withoutFetch kiedy BE zrobi search dla userów
            {...selectWithSearchProps}
        />
    )

    function setValue(value) {
        props.handleSelect({
            target: {
                name,
                value: multiselect ? value : value.value ? value : null
            }
        })
    }
}

SelectUser.defaultProps = {
    multiselect: true,
    selectWithSearchProps: {},
    placeholder: "- Please select -"
}

export default SelectUser
