const ns = "locations/"

export const GET_BY_ID_START = ns + "GET_BY_ID_START"
export const GET_BY_ID_SUCCESS = ns + "GET_BY_ID_SUCCESS"
export const GET_BY_ID_ERROR = ns + "GET_BY_ID_ERROR"
export const RESET_CURRENT = ns + "RESET_CURRENT"

export const GET_LIST_START = ns + "GET_LIST_START"
export const GET_LIST_SUCCESS = ns + "GET_LIST_SUCCESS"
export const GET_LIST_ERROR = ns + "GET_LIST_ERROR"
export const RESET_LIST = ns + "RESET_LIST"
