import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import fetch from "helpers/fetch"

import * as notificationActions from "actions/notification"

import {
    Header,
    NavigationListingLayout,
    NavigationList,
    FiltersListHeader,
    FiltersListRow
} from "modules/ContentModule/components"

const Listing = props => {
    const { match, showErrorNotification, showSuccessNotification } = props

    const [isLoading, setIsLoading] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [defaultFilters, setDefaultFilters] = useState([])
    const [isAddModalOpen, setIsAddModalOpen] = useState(false)

    useEffect(() => {
        getDefaultFilters()
    }, [])

    const toggleAddModal = () => setIsAddModalOpen(wasOpen => !wasOpen)

    const getDefaultFilters = () => {
        setIsLoading(true)
        fetch
            .get("/filters?is_default=true")
            .then(data => setDefaultFilters(data.data))
            .catch(() => showErrorNotification())
            .finally(() => setIsLoading(false))
    }

    const submitDefaultFilters = defaultFiltersList => {
        const body = { ids: defaultFiltersList.map(({ id }) => id) }

        setIsSaving(true)
        fetch
            .patchRAW("/filters/default", body)
            .then(() => {
                getDefaultFilters()
                showSuccessNotification()
            })
            .catch(() => showErrorNotification())
            .finally(() => setIsSaving(false))
    }

    return (
        <NavigationListingLayout>
            <Header title="Default Filters" addButton={{ label: "Add Filter", handler: toggleAddModal }} />
            <NavigationList
                isLoading={isLoading}
                storeList={defaultFilters}
                isSaving={isSaving}
                handleSubmit={submitDefaultFilters}
                isAddModalOpen={isAddModalOpen}
                closeAddModal={toggleAddModal}
                ListHeader={FiltersListHeader}
                ListRow={FiltersListRow}
                match={match}
                isFilters
            />
        </NavigationListingLayout>
    )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
    showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
    showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing)
