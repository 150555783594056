import React, { useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button, EditIcon, DeleteIcon, ExpandIcon, ConfirmModal } from "@butterfly-frontend/ui"

import { Image, ReloadableImageWrap } from "ui"

import styles from "./ItemInfo.module.css"

const ItemInfo = props => {
    const { orderItem, removeOrderItem, isExpanded, toggleDetails, openEditMode, disabledEditAndDeleteItem } = props
    const { image, name, fake_name, product, attributes, note, manufacturer_id } = orderItem

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const renderAttribute = ({ label, value }) => (
        <span key={label} className={styles.attribute}>
            {label}: <span>{value}</span>
        </span>
    )

    return (
        <div className={styles.root}>
            <ReloadableImageWrap image={image}>
                {({ image }) => <Image className={styles.image} src={image} />}
            </ReloadableImageWrap>
            <div className={styles.mainInfoWrapper}>
                <p className={styles.name}>
                    {product.manual_entry ? (
                        fake_name || name
                    ) : (
                        <Link className={styles.name} to={`/products/${product.id}`}>
                            {fake_name || name}
                        </Link>
                    )}
                </p>
                {manufacturer_id && (
                    <div className={classNames(styles.attributes, styles.manufacturerId)}>
                        {renderAttribute({ label: "Manufacture ID", value: manufacturer_id })}
                    </div>
                )}

                {attributes && (
                    <div className={styles.attributes}>
                        {Object.entries(attributes).map(([label, value]) => renderAttribute({ label, value }))}
                    </div>
                )}
                {!!note && <p className={styles.note}>NOTE: {note}</p>}
                <div className={styles.actions}>
                    <Button
                        onClick={toggleDetails}
                        Icon={() => <ExpandIcon className={classNames({ [styles.expandedDetails]: isExpanded })} />}
                        variant="flat"
                    >
                        See details
                    </Button>
                    <div className={styles.actionsSpacer} />
                    <Button onClick={openEditMode} Icon={EditIcon} variant="flat" disabled={disabledEditAndDeleteItem}>
                        Edit
                    </Button>
                    <div className={styles.actionsSpacer} />
                    <Button
                        onClick={() => setIsDeleteModalOpen(true)}
                        Icon={DeleteIcon}
                        variant="flat"
                        disabled={disabledEditAndDeleteItem}
                    >
                        Delete
                    </Button>
                </div>
            </div>
            {isDeleteModalOpen && (
                <ConfirmModal
                    onCancel={() => setIsDeleteModalOpen(false)}
                    onAction={() => removeOrderItem(orderItem)}
                    buttonColor="red"
                    buttonLabel="Delete"
                    title="Delete this product?"
                >
                    Are you sure you want to delete the product <b>{fake_name || name}</b> ?
                </ConfirmModal>
            )}
        </div>
    )
}

ItemInfo.propTypes = {
    orderItem: PropTypes.shape({
        id: PropTypes.number,
        draftId: PropTypes.string,
        image: PropTypes.string,
        name: PropTypes.string.isRequired,
        fake_name: PropTypes.string,
        product: PropTypes.shape({
            id: PropTypes.number.isRequired,
            manual_entry: PropTypes.bool
        }).isRequired,
        attributes: PropTypes.objectOf(PropTypes.string),
        note: PropTypes.string,
        manufacturer_id: PropTypes.string
    }).isRequired,
    removeOrderItem: PropTypes.func.isRequired,
    toggleDetails: PropTypes.func.isRequired,
    openEditMode: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool,
    disabledEditAndDeleteItem: PropTypes.bool
}

export default ItemInfo
