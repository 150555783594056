import React, { Component } from "react"
import { connect } from "react-redux"
import ClientCardFavorites from "./ClientCardFavorites"
import { removeProductFromFavorites, removeAllFavorites } from "actions/client"

class ClientCardFavoritesContainer extends Component {
    state = {
        isModalShown: false
    }

    render() {
        return (
            <ClientCardFavorites
                {...this.props}
                {...this.state}
                handleRemove={this.handleRemove}
                handleRemoveAll={() => this.props.removeAllFavorites(this.props.client.data.id)}
                handleModal={isModalShown => this.setState({ isModalShown })}
            />
        )
    }

    handleRemove = productId => {
        const {
            client: {
                isLoaded,
                data: { id }
            }
        } = this.props

        if (!isLoaded) {
            return
        }

        return this.props.removeProductFromFavorites({ productId, clientId: id })
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeAllFavorites: clientId => dispatch(removeAllFavorites({ clientId })),
        removeProductFromFavorites: ({ productId, clientId }) =>
            dispatch(removeProductFromFavorites({ clientId, productId }))
    }
}

export default connect(
    null,
    mapDispatchToProps
)(ClientCardFavoritesContainer)
