import React, { useEffect } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"
import { Field } from "formik"

import { Modal, Dropzone, Checkbox, Button, Input } from "ui"
import ShowProductLink from "../ShowProductLink"
import { createHintText } from "modules/ContentModule/helpers"

import cloudIcon from "./assets/cloud.svg"
import closeIcon from "assets/close-blue.svg"

import styles from "./styles.css"
import dropzoneStyles from "./overrides/dropzoneStyles.css"
import stylesInput from "./overrides/input.css"
import stylesCheckbox from "./overrides/checkbox.css"

const IMAGE_DIMENSIONS = { width: 2500 }

const SectionModalParallaxWithText = ({
    onClose,
    cx,
    uploader: { state: uploadState, actions: uploadActions },
    form,
    isSubmitting
}) => {
    useEffect(() => {
        form.setFieldError("paralax_image", null)
        if (idx(uploadState, _ => _.addedFile.url)) {
            form.setFieldValue("paralax_image", {
                id: uploadState.addedFile.id,
                name: uploadState.addedFile.title,
                url: uploadState.addedFile.url
            })
        }
    }, [uploadState.isUploading, uploadState.isSuccess, uploadState.addedFile])

    const removeImage = () => {
        form.setFieldValue("paralax_image", null)
    }

    const renderUpladingState = progress => (
        <div className={cx("dropzoneStateUploading")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    )

    const renderEmptyState = () => (
        <Dropzone
            isUploading={uploadState.isUploading}
            isSuccess={uploadState.isSuccess}
            progress={uploadState.progress}
            handleUpload={uploadActions.handleUpload}
            handleRemove={uploadActions.handleRemove}
            isMultiple={false}
            customStyles={dropzoneStyles}
        >
            <p className={cx("dropzoneStateEmpty")}>
                <img src={cloudIcon} alt="cloud" />
                Add image <span>{createHintText(IMAGE_DIMENSIONS)}</span>
            </p>
        </Dropzone>
    )

    const renderImageState = () => (
        <div className={cx("dropzoneStateImage")}>
            <div className={cx("imageWrapper")}>
                {form.values.paralax_image.url && <img src={form.values.paralax_image.url} alt="preview" />}
            </div>
            <span>{form.values.paralax_image.name}</span>
            <button onClick={removeImage}>
                <img src={closeIcon} alt="close" />
            </button>
        </div>
    )

    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Parallax</p>

            <div
                className={cx("dropzoneWrapper", {
                    dropzoneWrapperError: !!form.errors.paralax_image
                })}
            >
                {uploadState.isUploading
                    ? renderUpladingState(uploadState.progress)
                    : !!form.values.paralax_image
                    ? renderImageState()
                    : renderEmptyState()}
            </div>

            <div className={cx("line")} />

            <Field name="settings.showText">
                {({ field }) => (
                    <Checkbox
                        checked={field.value}
                        name="Show text"
                        onChange={() => form.setFieldValue("settings.showText", !field.value)}
                        customStyles={stylesCheckbox}
                    />
                )}
            </Field>

            <Field name="settings.heading">
                {({ field, meta }) => (
                    <Input
                        {...field}
                        label="Heading"
                        onChange={({ event }) => field.onChange(event)}
                        customStyles={stylesInput}
                        isDisabled={!form.values.settings.showText}
                        error={meta.error}
                    />
                )}
            </Field>

            <Field name="settings.subheading">
                {({ field, meta }) => (
                    <Input
                        {...field}
                        label="Subheading"
                        onChange={({ event }) => field.onChange(event)}
                        customStyles={stylesInput}
                        customPlaceholder="Brand, Collection name, etc"
                        isDisabled={!form.values.settings.showText}
                        error={meta.error}
                    />
                )}
            </Field>

            <div className={cx("showLinkWrapper")}>
                <ShowProductLink />
            </div>

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    label="Save"
                    onClick={() => form.submitForm()}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

SectionModalParallaxWithText.propTypes = {
    uploader: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    cx: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired
}

export default withUploader(withStyles(SectionModalParallaxWithText, styles))
