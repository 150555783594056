import AttributeValuesFormModal from "./AttributeValuesFormModal"
import AttributeChooserModal from "./AttributeChooserModal"
import AttributeModal from "./AttributeModal"
import GroupModal from "./GroupModal"

export default {
    AttributeValuesForm: AttributeValuesFormModal,
    AttributeChooserModal,
    AttributeModal,
    GroupModal
}
