import React, { Fragment, useEffect, useState } from "react"
import { Modal as ModalComponent, Button, Checkbox } from "ui"
import withStyles from "HOC/withStyles"
import * as Yup from "yup"

import { Field, Formik } from "formik"

import { Input, Textarea } from "components/Reusable/Form"
import Image from "../MainPageCollectionProductUpload"
import Cover from "../Cover"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import styles from "./styles.css"
import inputOverrides from "../MainPageNameSectionEditModal/overrides/inputOverrides.css"
import modalOverrides from "../MainPageNameSectionEditModal/overrides/modalOverrides.css"

const IMAGE_COVER_DIMENSIONS = {
    desktop: {
        width: 2560
    },
    tablet: {
        width: 1024
    },
    mobile: {
        width: 735
    }
}

const IMAGE_PRODUCT_DIMENSIONS = {
    width: 10,
    height: 10
}

const schema = Yup.object().shape({
    title: Yup.string().required("This field is required."),
    description: Yup.string()
        .required("This field is required.")
        .nullable()
})

const Modal = props => {
    const { cx, isOpen, handleClose, data, onSubmit, handleError } = props

    const [showProduct, setShowProduct] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isSaving, SetIsSaving] = useState(false)

    useEffect(() => {
        SetIsSaving(false)
    }, [isOpen])

    useEffect(() => {
        const { getCollection, collectionId } = props
        if (collectionId && !dataLoaded) {
            getCollection(collectionId)
            setDataLoaded(true)
        }

        if (!isOpen) {
            setDataLoaded(false)
        }
    })

    const handleProductToggle = () => {
        setShowProduct(!showProduct)
    }

    const handleErrors = (form, errors) => {
        form.setErrors(errors.errors)
        handleError()
    }

    const handleSubmit = (values, form) => {
        SetIsSaving(true)
        const parsedValues = {
            ...values,
            cover_desktop: values.cover_desktop && values.cover_desktop.id,
            cover_tablet: values.cover_tablet && values.cover_tablet.id,
            cover_mobile: values.cover_mobile && values.cover_mobile.id,
            product: values.product && values.product.id
        }
        onSubmit(data.data.id, parsedValues, null, errors => handleErrors(form, errors))
    }

    return (
        <ModalComponent isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <ModalComponent.Header>Edit collection</ModalComponent.Header>
            {data.isLoading && (
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            )}

            {!data.isLoading && (
                <Formik
                    initialValues={data.data}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                    validationSchema={schema}
                >
                    {({ handleSubmit, isSubmitting, setFieldValue, errors, touched, values }) => {
                        return (
                            <form className={cx(["form", "root"])} onSubmit={handleSubmit}>
                                <ModalComponent.Body>
                                    <div className={cx("scrollWrapper")}>
                                        <div className={cx("row")}>
                                            <div className={cx("collectionCover")}>
                                                <span className={cx("label")}>Collection cover:</span>
                                                <Cover
                                                    form={{
                                                        values,
                                                        errors,
                                                        setFieldValue
                                                    }}
                                                    imageDimensions={IMAGE_COVER_DIMENSIONS}
                                                />
                                            </div>
                                        </div>

                                        <div className={cx("row")}>
                                            <hr className={cx("hr")} />
                                        </div>

                                        <div className={cx("row")}>
                                            <Field name="title">
                                                {({ field }) => {
                                                    return (
                                                        <Input
                                                            label="Heading"
                                                            customStyles={inputOverrides}
                                                            error={touched.title && errors.title}
                                                            value={values.title}
                                                            onChange={value => {
                                                                setFieldValue(field.name, value.event.target.value)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                        <div className={cx("row")}>
                                            <Field name="description">
                                                {({ field }) => {
                                                    return (
                                                        <Textarea
                                                            label="Description"
                                                            customStyles={inputOverrides}
                                                            error={touched.description && errors.description}
                                                            value={values.description}
                                                            onChange={value => {
                                                                setFieldValue(field.name, value.event.target.value)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                        <Checkbox
                                            name="Show product"
                                            checked={showProduct}
                                            onChange={handleProductToggle}
                                        />

                                        {showProduct && (
                                            <Fragment>
                                                <div className={cx("row")}>
                                                    <hr className={cx("hr")} />
                                                </div>

                                                <div className={cx("row")}>
                                                    <div className={cx("files-uploader")}>
                                                        <Image
                                                            form={{
                                                                values,
                                                                errors,
                                                                setFieldValue
                                                            }}
                                                            imageDimensions={IMAGE_PRODUCT_DIMENSIONS}
                                                        />
                                                    </div>
                                                </div>
                                            </Fragment>
                                        )}
                                    </div>
                                </ModalComponent.Body>
                                <ModalComponent.Actions customStyles={modalOverrides}>
                                    <button className={cx("cancel-button")} onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <Button
                                        className="first-button"
                                        onClick={handleSubmit}
                                        label={"Save"}
                                        isLoading={isSaving}
                                    />
                                </ModalComponent.Actions>
                            </form>
                        )
                    }}
                </Formik>
            )}
        </ModalComponent>
    )
}

export default withStyles(Modal, styles)
