import React, { Component } from "react"

class Skeleton extends Component {
    state = {
        isLoading: false,
        isLoaded: false,
        isError: false,
        lastUpdate: null
    }

    constructor(props) {
        super(props)

        let isLoading, isLoaded, isError

        if (props.fetchStatus) {
            ;({ isLoading, isLoaded, isError } = props.fetchStatus)
        } else {
            ;({ isLoading, isLoaded, isError } = props)
        }

        const nextState = {
            ...this.state,
            isLoading,
            isLoaded,
            isError
        }

        this.state = nextState
    }

    componentDidMount() {
        const { fetchStatus, isLoading, isLoaded, isError } = this.props

        const currentTime = new Date().getTime()

        if (fetchStatus) {
            return this.setState({
                ...fetchStatus,
                lastUpdate: currentTime
            })
        }

        this.setState({
            isLoading,
            isLoaded,
            isError,
            lastUpdate: currentTime
        })
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { fetchStatus } = nextProps

        if (this.props.firstTime) {
            const nextIsLoading = nextProps.fetchStatus ? nextProps.fetchStatus.isLoading : !!nextProps.isLoading

            if (nextIsLoading && this.state.isLoading === false) {
                return
            }
        }

        const nextFetchStatus = fetchStatus
            ? fetchStatus
            : {
                  isLoading: nextProps.isLoading,
                  isLoaded: nextProps.isLoaded,
                  isError: nextProps.isError
              }

        const currentTime = new Date().getTime()

        const nextState = {
            ...nextFetchStatus,
            lastUpdate: currentTime
        }

        return this.setState(prevState => ({
            ...prevState,
            ...nextState
        }))
    }

    render() {
        const { fetchStatus, render, children, component: SkeletonComponent, fallback, ...props } = this.props
        const { isLoading, isLoaded, isError } = this.state

        if (isLoading) {
            if (SkeletonComponent) {
                if (React.isValidElement(SkeletonComponent)) {
                    return SkeletonComponent
                }

                return <SkeletonComponent {...this.props} />
            }
        }

        if (isLoaded) {
            if (typeof render === "function") {
                return render(props)
            }

            return children
        }

        if (isError) {
            if (typeof fallback === "function") {
                return fallback(fetchStatus.error || {})
            }
        }

        return null
    }
}

export default Skeleton
