import React from "react"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"

import ListFiltersContextProvider from "modules/DashboardModule/contexts/ListFiltersContextProvider"
import DashboardPage from "modules/DashboardModule/pages/DashboardPage"
import SellersPage from "modules/DashboardModule/pages/SellersPage"
import SellerDetailsPage from "modules/DashboardModule/pages/SellerDetailsPage"

const Routes = props => {
    const match = useRouteMatch()

    return (
        <ListFiltersContextProvider>
            <Switch>
                <Route path={`${match.url}/sellers/:sellerId`} component={SellerDetailsPage} />
                <Route path={`${match.url}/sellers`} component={SellersPage} />
                <Route path={`${match.url}/main`} component={DashboardPage} />
                <Redirect to={`${match.url}/main`} />
            </Switch>
        </ListFiltersContextProvider>
    )
}

export default Routes
