import * as constants from "constants/index"

const INITIAL_STATE = { from: "", to: "" }
const setInitialState = () => Object.assign({}, INITIAL_STATE)

function dateRange(state = setInitialState(), action) {
    const from = action.payload && action.payload.from
    const to = action.payload && action.payload.to

    switch (action.type) {
        case constants.CLEAR_PRODUCT_DATERANGE:
            return setInitialState()
        case constants.CHANGE_PRODUCT_DATERANGE:
            return { ...state, from, to }

        default:
            return state
    }
}

export default dateRange
