import React from "react"
import PropTypes from "prop-types"

import { Button, AddIcon, H2 } from "@butterfly-frontend/ui"

import styles from "./Header.module.css"

const Header = ({ orderId, numberOfItems, isLoaded, canCreateShipment }) => {
    return (
        <header className={styles.header}>
            <H2 classes={{ h2: styles.title }}>Shipments</H2>
            {isLoaded && <span className={styles.counter}>{numberOfItems}</span>}
            <Button
                href={`/orders/sales-orders/${orderId}/shipments/add`}
                classes={{ button: styles.button }}
                Icon={AddIcon}
                type="link"
                disabled={!canCreateShipment}
            >
                Add new shipment
            </Button>
        </header>
    )
}

Header.propTypes = {
    orderId: PropTypes.number.isRequired,
    numberOfItems: PropTypes.number,
    isLoaded: PropTypes.bool,
    canCreateShipment: PropTypes.bool
}

export default Header
