import React, { Component } from "react"
import HTML5Backend from "react-dnd-html5-backend"
import { DragDropContext } from "react-dnd"

import Layout from "components/Reusable/Layout"

import { Products, ImagesList } from "modules/UploadModule/components"
import layoutStyles from "./overrides/Layout.css"
import downloadXlsx from "helpers/downloadXlsx"

class ProductsUpload extends Component {
    render() {
        return (
            <Layout
                customStyles={layoutStyles}
                title="Upload Products"
                links={[
                    { title: "Dictionary", onClick: () => downloadXlsx("dictionaries") },
                    { title: "Template", onClick: () => downloadXlsx("productsTemplate") }
                ]}
            >
                <Products />
                <ImagesList />
            </Layout>
        )
    }
}

export default DragDropContext(HTML5Backend)(ProductsUpload)
