import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./ClientCardHeader.css"
import { connect } from "react-redux"
import * as actions from "actions/session"
import { Link } from "react-router-dom"
import arrow from "assets/arrow-blue.svg"
import pencil from "assets/pencil.svg"
import activeClientIcon from "assets/active_client.svg"
import withSession from "HOC/withSession"
import Button from "components/Reusable/Form/Button/index"

class ClientCardHeader extends Component {
    assignClientToSession() {
        const { client, sendStartSession, sendAssignClient, activeSession, activeClient, toggleSession } = this.props

        if (activeSession && activeClient.isTemporary) {
            sendAssignClient(client.data)
            return
        }

        toggleSession()
        sendStartSession({
            client_id: client.data.id,
            ...client.data
        })
    }

    render() {
        const { first_name, email, last_name, id } = this.props.client.data
        const { isLoaded } = this.props.client
        const { fullProfile, activeClient, canView, canEdit } = this.props
        return (
            <div styleName="header-cover">
                <h1 styleName={!isLoaded ? "name-placeholder" : "name"}>
                    {!first_name && !last_name ? email : first_name} {last_name}
                </h1>
                {activeClient.id !== id ? (
                    <Button
                        className={["second-button-big", fullProfile ? "none" : "work-with"]}
                        customStyles={style}
                        label="Work With"
                        onClick={this.assignClientToSession.bind(this)}
                        isLoading={!isLoaded}
                    />
                ) : (
                    <img styleName="active-client-icon" src={activeClientIcon} alt="Selected client" />
                )}
                {canView() && id && (
                    <Link to={"/contact/" + id + "/profile"}>
                        <div styleName={fullProfile ? "none" : "full-profile-cover"}>
                            <h3 styleName="full-profile-text">Full profile</h3>
                            <img src={arrow} alt="arrow" styleName="full-profile-arrow" />
                        </div>
                    </Link>
                )}
                {canEdit() && id && (
                    <Link to={"/contact/" + id + "/profile/edit"}>
                        <div styleName={fullProfile ? "full-profile-cover" : "none"}>
                            <img src={pencil} alt="arrow" styleName="full-profile-arrow" />
                            <h3 styleName="full-profile-text">Edit</h3>
                        </div>
                    </Link>
                )}
                <div className="clear" />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        activeSession: state.ui.session.activeSession,
        activeClient: state.ui.session.activeClient
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSession: () => dispatch(actions.toggleSession())
    }
}

const ComponentWithCSS = CSSModules(ClientCardHeader, style)
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSession(ComponentWithCSS))
