import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./temp.module.css"
import { Input } from "ui"

class Temp extends Component {
    render() {
        const { customInputStyles, cx, errors, setFieldValue, values, handleEdit } = this.props
        const { mbid, mlaid, mla_sold_by, mb_sold_by, discount } = values

        const setValue = (key, val) => {
            handleEdit(true)
            setFieldValue(key, val)
        }

        return (
            <section className={cx("root")}>
                <h2>Temp</h2>
                <ul>
                    <li>
                        <Input
                            customStyles={customInputStyles}
                            type="number"
                            value={mbid}
                            name="mbid"
                            id="mbid"
                            label="MBID (number)"
                            error={errors.mbid}
                            onChange={params => setValue("mbid", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={customInputStyles}
                            type="text"
                            value={mb_sold_by}
                            name="mb_sold_by"
                            id="mb_sold_by"
                            label="MB Sold By"
                            error={errors.mb_sold_by}
                            onChange={params => setValue("mb_sold_by", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={customInputStyles}
                            type="number"
                            value={mlaid}
                            name="mlaid"
                            id="mlaid"
                            label="MLAID (Number)"
                            error={errors.mlaid}
                            onChange={params => setValue("mlaid", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={customInputStyles}
                            type="text"
                            value={mla_sold_by}
                            name="mla_sold_by"
                            id="mla_sold_by"
                            label="MLA Sold By"
                            error={errors.mla_sold_by}
                            onChange={params => setValue("mla_sold_by", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <div className={cx("discountValueWrapper")}>
                            <Input
                                customStyles={customInputStyles}
                                type="number"
                                value={discount}
                                name="discount"
                                id="discount"
                                label="Discount"
                                error={errors.discount}
                                onChange={params => setValue("discount", params.event.target.value)}
                            />
                            <span className={cx("depositValueUnit")}>%</span>
                        </div>
                    </li>
                </ul>
            </section>
        )
    }
}

export default withStyles(Temp, styles)
