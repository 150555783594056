import React, { forwardRef, Fragment, useEffect, useMemo, useState } from "react"
import { Formik, Form as FormikForm } from "formik"
import _ from "lodash/core"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"

import { scrollToTop } from "helpers/scrollTo"

import Layout from "components/Reusable/Layout"
import GeneralInfoSection from "./components/GeneralInfoSection"
import FinancialsSection from "./components/FinancialsSection"
import Sidebar from "./components/Sidebar"
import { SaveBar, Tabs } from "ui"

import styles from "./styles.css"
import layoutStyles from "./overrides/layout.css"
import tabsStyles from "./overrides/tabs.css"

const Form = forwardRef(
    (
        {
            cx,
            initValues,
            handleSubmit,
            handleCancel,
            isSubmitting,
            countErrors,
            priceStructure,
            sectionName,
            onTabChange,
            calculateErrorCounts,
            uploader
        },
        ref
    ) => {
        const tabs = useMemo(
            () => ({
                "general-info": {
                    label: "GENERAL INFO",
                    Component: GeneralInfoSection,
                    disabled: false,
                    errorCount: countErrors.general || null
                },
                financials: {
                    label: "FINANCIALS",
                    Component: FinancialsSection,
                    disabled: false,
                    errorCount: countErrors.financials || null
                }
            }),
            [countErrors]
        )
        const tabsKeys = Object.keys(tabs)

        const [activeTabIndex, setActiveTabIndex] = useState(tabsKeys[0])

        useEffect(() => {
            setActiveTabIndex(sectionName || tabsKeys[0])
        }, [sectionName])

        useEffect(() => {
            scrollToTop()
        }, [])

        const TabContent = tabs[sectionName] ? tabs[sectionName].Component : Object.values(tabs)[0].Component

        return (
            <Formik
                initialValues={initValues}
                enableReinitialize
                onSubmit={handleSubmit}
                ref={ref}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {propsForm => {
                    calculateErrorCounts(propsForm.errors)

                    return (
                        <FormikForm>
                            <Layout title="Brand form" customStyles={layoutStyles}>
                                <Fragment>
                                    <div className={cx("contentWrapper")}>
                                        <Tabs
                                            customStyles={tabsStyles}
                                            tabs={tabs}
                                            activeTabKey={activeTabIndex}
                                            onTabChange={onTabChange}
                                        />

                                        <div className={cx("section")}>
                                            <TabContent form={propsForm} priceStructure={priceStructure} />
                                        </div>
                                    </div>
                                    <Sidebar form={propsForm} uploader={uploader} />
                                </Fragment>
                            </Layout>
                            <SaveBar
                                isSaving={isSubmitting}
                                isShown={!_.isEqual(initValues, propsForm.values)}
                                isDisabled={uploader && uploader.state.isUploading}
                                submitLabel="Save"
                                message="Unsaved changes"
                                onCancel={handleCancel}
                                isSubmit
                            />
                        </FormikForm>
                    )
                }}
            </Formik>
        )
    }
)

Form.propTypes = {
    cx: PropTypes.func.isRequired,
    initValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    countErrors: PropTypes.object.isRequired,
    priceStructure: PropTypes.array.isRequired,
    sectionName: PropTypes.string,
    onTabChange: PropTypes.func.isRequired,
    calculateErrorCounts: PropTypes.func.isRequired,
    uploader: PropTypes.object.isRequired
}

export default withUploader(withStyles(Form, styles))
