import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import getStyles from "helpers/getCxFromStyles"
import styles from "./ButtonWithDropdown.css"
import ReactSVG from "react-svg"

const ButtonWithDropdown = props => {
    const { icon, label, isLoading, isDisabled, items, className, customStyles } = props
    const cx = getStyles(styles, customStyles)

    let rootRef = useRef(null)

    const [isShown, setIsShown] = useState(false)

    useEffect(() => {
        document.addEventListener("click", clickOutside)
        return () => document.removeEventListener("click", clickOutside)
    }, [])

    const hideDropdown = () => setIsShown(false)
    const toggleDropdown = () => setIsShown(wasShown => !wasShown)
    const clickOutside = event => !rootRef.current.contains(event.target) && hideDropdown()
    const handleSelect = action => {
        action()
        hideDropdown()
    }

    return (
        <div className={cx("root", className, { isDisabled, isLoading })} ref={rootRef}>
            <div className={cx("button", className)} onClick={() => !isDisabled && toggleDropdown()}>
                {icon && <ReactSVG src={icon} className={cx("icon")} />}
                {label && <span className={cx("label")}>{label}</span>}
            </div>
            <ul className={cx("dropdown", { isShown })}>
                {items.map(item => (
                    <li
                        key={item.text}
                        className={cx("item", { disabled: item.disabled }, item.styles || [])}
                        onClick={() => handleSelect(item.action)}
                    >
                        {item.text}
                    </li>
                ))}
            </ul>
        </div>
    )
}

ButtonWithDropdown.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            action: PropTypes.func,
            text: PropTypes.string
        })
    ).isRequired,
    label: PropTypes.string,
    icon: PropTypes.string,
    className: PropTypes.string,
    isDisabled: PropTypes.bool
}

export default ButtonWithDropdown
