import { INPUT_TYPES } from "constants/index"

export default {
    name: "Role",
    endpoint: "/permissionGroup",
    fields: [
        {
            name: "name",
            label: "Name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "permissions",
            label: "Permissions",
            type: INPUT_TYPES.ARRAY,
            defaultValue: [],
            attributes: {}
        }
    ]
}
