import { useMemo } from "react"
import { useInfiniteQuery } from "react-query"
import _last from "lodash/last"

const INITIAL_META = { current_page: 0, last_page: 0, to: 0, total: 0 }
const INITIAL_PAGE = { data: [], meta: INITIAL_META }
const INITIAL_DATA = { pages: [INITIAL_PAGE] }

const mapReactQueryFetchStatus = query => ({
    isLoading: query.isFetching || query.isFetchingNextPage,
    isLoaded: query.isFetched,
    isError: query.isError,
    lastFetch: query.dataUpdatedAt
})

const getNextPageParam = lastFetchedPage =>
    lastFetchedPage.meta.current_page < lastFetchedPage.meta.last_page
        ? lastFetchedPage.meta.current_page + 1
        : undefined

const useInfiniteQueryListFactory = ({ key, fetchFunction, defaultParams = {}, initialData = INITIAL_DATA }) => {
    return function useInfiniteQueryList(props = {}) {
        const { reactQueryProps = {} } = props
        const params = { ...defaultParams, ...(props.params || {}) }
        const queryKey = useMemo(() => [key, params], [key, params])

        const query = useInfiniteQuery(queryKey, fetchFunction, {
            getNextPageParam,
            initialData,
            ...reactQueryProps
        })

        const fetchStatus = useMemo(() => mapReactQueryFetchStatus(query), [
            query.isError,
            query.isFetching,
            query.isFetchingNextPage,
            query.isFetched,
            query.dataUpdatedAt
        ])

        return {
            meta: _last(query.data.pages).meta,
            pages: query.data.pages,
            hasNextPage: query.hasNextPage,
            isFetchingNextPage: query.isFetchingNextPage,
            fetchNextPage: query.fetchNextPage,
            fetchStatus,
            query,
            queryKey
        }
    }
}

export default useInfiniteQueryListFactory
