import { useState } from "react"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"

const useFetch = fetchConfig => {
    const [fetchStatus, setFetchStatus] = useState(initialState())

    const handleFetch = (...params) => {
        const { action, onSuccess, onError, onFinish } = fetchConfig

        const isFunction = func => typeof func === "function"

        setFetchStatus(requestState())
        action(...params)
            .then(data => {
                isFunction(onSuccess) && onSuccess(data, ...params)
                setFetchStatus(successState())
            })
            .catch(error => {
                isFunction(onError) && onError(error, ...params)
                setFetchStatus({ ...failureState(), error })
            })
            .finally(() => isFunction(onFinish) && onFinish(...params))
    }

    return [handleFetch, fetchStatus]
}

export default useFetch
