import React from "react"
import PropTypes from "prop-types"

import ReactSvg from "react-svg"

import CollapsingText from "ui/CollapsingText"

import { formatDate } from "helpers/date"
import getReadableFileSize from "helpers/getReadableFileSize"

import editIcon from "assets/pencil.svg"
import deleteIcon from "assets/close-blue.svg"
import downloadIcon from "assets/download.svg"

import withStyles from "HOC/withStyles"
import styles from "./Note.css"

const collapsedTextLength = {
    0: 100,
    1024: 150,
    1280: 200,
    1440: 250
}

const Note = props => {
    const { cx, editNote, deleteNote, id, created_at, text, attachments, user, isAnswer } = props

    const shouldDisplayUser = user && (user.first_name || user.last_name)

    return (
        <li className={cx("root", { isAnswer })}>
            <div className={cx("userLabel")}>{shouldDisplayUser && `${user.first_name[0]}${user.last_name[0]}`}</div>
            <div className={cx("content")}>
                <div className={cx("header")}>
                    <div>
                        {shouldDisplayUser && (
                            <span className={cx("name")}>
                                {user.first_name} {user.last_name}
                            </span>
                        )}
                        <span className={cx("date")}>{formatDate(created_at)}</span>
                    </div>
                    <div>
                        <button onClick={() => editNote(id)}>
                            <ReactSvg src={editIcon} />
                            <span>Edit</span>
                        </button>
                        <button onClick={() => deleteNote(id)}>
                            <ReactSvg src={deleteIcon} />
                            <span>Delete</span>
                        </button>
                    </div>
                </div>
                <CollapsingText className={cx("text")} text={text} collapsedTextLength={collapsedTextLength} />
                {Array.isArray(attachments) && !!attachments.length && (
                    <ul className={cx("attachments")}>
                        {attachments.map(attachment => {
                            const { id, name, url, size } = attachment
                            return (
                                <li key={id} className={cx("attachmentItem")}>
                                    <span>{name}</span>
                                    <div>
                                        <span>{getReadableFileSize(size)}</span>
                                        <a href={url} download target="_blank" rel="noopener noreferrer">
                                            <ReactSvg src={downloadIcon} />
                                        </a>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
        </li>
    )
}

Note.propTypes = {
    isAnswer: PropTypes.bool,
    id: PropTypes.number.isRequired,
    created_at: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            url: PropTypes.string,
            size: PropTypes.number
        })
    ),
    user: PropTypes.shape({
        first_name: PropTypes.string,
        last_name: PropTypes.string
    }).isRequired,
    editNote: PropTypes.func.isRequired,
    deleteNote: PropTypes.func.isRequired
}

export default withStyles(Note, styles)
