import React, { Fragment } from "react"

import withStyles from "HOC/withStyles"
import { TopBar } from "../../components"

import { Pagination, Skeleton } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader/SnakeLoader"
import ChooseProductModal from "components/Modal/ChooseProduct"
import EmptyList from "modules/ContentModule/components/EmptyList/EmptyList"
import { Product, Sendable } from "modules/ProductsModule/pages/List/components"
import { ChooseSelectionsModal, DeleteProductsModal, Modal } from "modules/SelectionsModule/components"

import styles from "./SelectionList.css"
import slidesIcon from "assets/slides.svg"

const SelectionList = props => {
    const {
        cx,
        isFetching,
        products,
        currentPage,
        productToDelete,
        selectionId,
        selectedAction,
        selectedProducts,
        setCurrentPage,
        setSelectedAction,
        setProductToDelete,
        handleMove,
        handleDuplicate,
        selectedSelection,
        handleMultiselectProduct,
        handleChooseProduct,
        handleDeleteSingleProduct,
        handleDeleteAllProducts,
        handleDeleteMultiselectProducts,
        handleCreateSelection,
        errorCreateSelection,
        setErrorCreateSelection,
        isSendableModalOpenFor
    } = props

    return (
        <Fragment>
            <Skeleton
                fetchStatus={products.fetchStatus}
                fallback={() => <div>An error occurred, please try again.</div>}
                SkeletonComponent={() => (
                    <div className={cx("loaderContainer")}>
                        <SnakeLoader />
                    </div>
                )}
            >
                <div className={cx("root")}>
                    {renderTopBar()}
                    {renderList()}
                    {renderPagination()}
                </div>
            </Skeleton>
            {renderChooseProductModal()}
            {renderConfirmDeleteModal()}
            {renderChooseSelectionModal()}
            {(selectedAction === "CREATE_SELECTION_IN_MOVE" || selectedAction === "CREATE_SELECTION_IN_DUPLICATE") &&
                renderCreateModal()}
            {!!isSendableModalOpenFor && renderSendableModal()}
        </Fragment>
    )

    function renderTopBar() {
        const { isSendableModalOpenFor, isPrinting, handlePrint, handleSend, printAndSendItems } = props

        return (
            <TopBar
                isMultiSelect={!!selectedProducts.length}
                areAllChecked={false}
                handleAction={setSelectedAction}
                productCount={selectedProducts.length || products.meta.total}
                isSendableModalOpenFor={isSendableModalOpenFor}
                printAndSendItems={printAndSendItems}
                isPrinting={isPrinting}
                handlePrint={handlePrint}
                handleSend={handleSend}
            />
        )
    }

    function renderList() {
        if (!products.data.length) {
            return (
                <div className={cx("emptyList")}>
                    <EmptyList message="No products added" icon={slidesIcon} />
                </div>
            )
        }

        return (
            <div className={cx("listWrapper")}>
                <div className={cx("list", "viewTiles")}>
                    {products.data.map(product => (
                        <div key={product.id} className={cx("listItem")}>
                            <Product
                                isChecked={selectedProducts.includes(product.id)}
                                canAddToSelection={false}
                                canDelete={true}
                                nameMaxLength={32}
                                checkProduct={() => handleMultiselectProduct(product.id)}
                                product={product}
                                deleteProduct={() => {
                                    setSelectedAction("DELETE_PRODUCT")
                                    setProductToDelete(product)
                                }}
                            />
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    function renderPagination() {
        if (!products.data.length) {
            return null
        }

        return (
            <div className={cx("pagination")}>
                <Pagination currentPage={currentPage} pages={products.meta.last_page} onChange={setCurrentPage} />
            </div>
        )
    }

    function renderChooseSelectionModal() {
        return (
            <ChooseSelectionsModal
                title={selectedAction === "MULTISELECT_MOVE" ? "Move to Selection:" : "Copy to Selection:"}
                isOpen={selectedAction === "MULTISELECT_MOVE" || selectedAction === "MULTISELECT_DUPLICATE"}
                onClose={() => setSelectedAction(null)}
                handleCreateNewSelection={() =>
                    setSelectedAction(
                        selectedAction === "MULTISELECT_MOVE"
                            ? "CREATE_SELECTION_IN_MOVE"
                            : "CREATE_SELECTION_IN_DUPLICATE"
                    )
                }
                handleSelect={id => (selectedAction === "MULTISELECT_MOVE" ? handleMove(id) : handleDuplicate(id))}
                activeSelectionId={selectionId && parseInt(selectionId, 10)}
                isVisibleActiveSelection={false}
                loadingSelectionId={selectedSelection}
            />
        )
    }

    function renderConfigDeleteConfirmModal() {
        if (selectedAction === "MULTISELECT_DELETE") {
            return {
                isOpen: true,
                title: "Delete these products?",
                highligthningBody: `${selectedProducts.length} products`,
                onSubmit: handleDeleteMultiselectProducts
            }
        }
        if (selectedAction === "DELETE_PRODUCT") {
            return {
                isOpen: true,
                title: "Delete this products?",
                highligthningBody: `${productToDelete.name}`,
                onSubmit: handleDeleteSingleProduct
            }
        }
        if (selectedAction === "DELETE_ALL_PRODUCTS") {
            return {
                isOpen: true,
                title: "Delete all products?",
                highligthningBody: `all products`,
                onSubmit: handleDeleteAllProducts
            }
        }

        return {
            isOpen: false,
            onSubmit: () => {},
            title: "",
            highligthningBody: ""
        }
    }

    function renderConfirmDeleteModal() {
        return (
            <DeleteProductsModal
                isLoading={isFetching}
                onCancel={() => setSelectedAction(null)}
                {...renderConfigDeleteConfirmModal()}
            />
        )
    }

    function renderChooseProductModal() {
        return (
            <ChooseProductModal
                isOpen={selectedAction === "ADD_PRODUCTS"}
                handleClose={() => setSelectedAction(null)}
                handleSelect={handleChooseProduct}
                disableQuantity={true}
                disableCombinations={true}
                mainTitle="New Item: Products"
            />
        )
    }

    function renderCreateModal() {
        return (
            <Modal
                title="Create new selection"
                saveButtonLabel="Create"
                submit={handleCreateSelection}
                selection={{ name: "", description: "", type: "private" }}
                isFetching={isFetching}
                isOpen={
                    selectedAction === "CREATE_SELECTION_IN_MOVE" || selectedAction === "CREATE_SELECTION_IN_DUPLICATE"
                }
                error={errorCreateSelection}
                setError={setErrorCreateSelection}
                handleClose={() => {
                    setSelectedAction(null)
                    setErrorCreateSelection({})
                }}
            />
        )
    }

    function renderSendableModal() {
        const { closeSendableModal, handlePrintForSendable, selection } = props

        return (
            <Sendable
                ids={selectedProducts}
                uuid={selection.name}
                printType={isSendableModalOpenFor}
                handleClose={closeSendableModal}
                handlePrint={handlePrintForSendable}
            />
        )
    }
}

export default withStyles(SelectionList, styles)
