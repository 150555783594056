import React, { Component } from "react"
import { connect } from "react-redux"
import * as roleActions from "actions/role"
import EditableList from "components/Reusable/EditableList"
import config from "./config"
import ListingLayout from "../../ListingLayout/index"
import fetch from "helpers/fetch"
class UsersInRole extends Component {
    componentDidMount() {
        const {
            getRole,
            match: {
                params: { id }
            }
        } = this.props
        getRole(id)
    }

    handleRemoveRoleFromUser = userId => {
        const { usersInRole, getRole, roleId } = this.props
        const currentUser = usersInRole.find(user => user.id === userId)
        const permissionGroups = currentUser.role.map(el => el.id).filter(id => id !== roleId)

        fetch
            .patch(`/user/${userId}`, {
                ...currentUser,
                permissionGroups
            })
            .then(() => getRole(roleId))
    }

    render() {
        const { isLoading, roleTitle } = this.props

        let listTitle = roleTitle || "Role"
        let returnLink = {
            label: "Roles",
            link: `/settings/users/roles`
        }

        return (
            <ListingLayout returnLink={returnLink}>
                <EditableList
                    config={config}
                    list={this.props.usersInRole}
                    listTitle={listTitle}
                    editUrlPrefix={"/settings/categories/card"}
                    isLoading={isLoading}
                    handleContentLink={id => `/products/${id}`}
                    confirmModalTitle="Are you sure to remove this role from selected user?"
                    deleteElement={this.handleRemoveRoleFromUser}
                    handleDeleteEnableCondition={values => values.role.length > 1}
                    isNoEdit
                />
            </ListingLayout>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.userStore.users,
        isLoading: !state.roleStore.isLoaded,
        roleTitle: state.roleStore.role.name,
        roleId: state.roleStore.role.id,
        usersInRole: state.roleStore.role.users_list
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRole: id => dispatch(roleActions.getRole(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersInRole)
