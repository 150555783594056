import React from "react"
import { Route, Switch } from "react-router-dom"

import { List, Overview, Form } from "../pages"

import withPermissions from "HOC/withPermissions"

const Routes = props => {
    return (
        <Switch>
            <Route exact path="/projects" component={List} />
            <Route path="/projects/:projectId/edit/:sectionName?" component={Form} />
            <Route path="/projects/add/:sectionName?" component={Form} />
            <Route path="/projects/:projectId/:sectionName?" component={Overview} />
        </Switch>
    )
}

export default withPermissions(Routes)
