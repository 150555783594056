import { GET_PARTNERS_START, GET_PARTNERS_SUCCESS, GET_PARTNERS_ERROR } from "constants/index"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"
import idx from "idx"

function list(state = { data: [], meta: {}, fetchStatus: initialState() }, action) {
    switch (action.type) {
        case GET_PARTNERS_START:
            return { data: [], meta: {}, fetchStatus: requestState() }

        case GET_PARTNERS_SUCCESS:
            return {
                data: idx(action.payload, _ => _.data) || [],
                meta: idx(action.payload, _ => _.meta) || {},
                fetchStatus: successState()
            }

        case GET_PARTNERS_ERROR:
            return { data: [], meta: {}, fetchStatus: failureState() }

        default:
            return state
    }
}

export default list
