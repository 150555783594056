import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./FormRow.module.css"

const FormRow = ({ children, className }) => <div className={classNames(styles.formRow, className)}>{children}</div>

FormRow.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string
}

export default FormRow
