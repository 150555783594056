import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import TextArea from "ui/Textarea"
import { SelectWithSearch, WarningBar } from "ui"

import styles from "./PurchaseOrderFormGeneralInfoTab.module.css"
import textareaStyles from "./overrides/textareaStyles.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.module.css"

const PurchaseOrderFormGeneralInfoTab = props => {
    const { destinationPointList, handleChangeDestinationPoint, isConfirmed, isSubmitting } = props

    const [descriptionField, descriptionMeta] = useField("description")
    const [specialInstructionField, specialInstructionMeta] = useField("special_instruction")
    const [warehouseField] = useField("warehouse")
    const [shippingAddressField, shippingAddressMeta] = useField("shipping_address")

    const destinationValueForSelect = useMemo(() => {
        if (!!warehouseField.value) {
            return warehouseField.value.name
        }

        if (!!shippingAddressField.value) {
            return shippingAddressField.value.name
        }

        return undefined
    }, [warehouseField.value, shippingAddressField.value])

    return (
        <div className={styles.root}>
            <div className={styles.wrapper}>
                <SelectWithSearch
                    withoutFetch
                    values={destinationPointList}
                    label="Destination point"
                    value={destinationValueForSelect}
                    setValue={handleChangeDestinationPoint}
                    customStyles={selectWithSearchStyles}
                    isClearBlocked
                    withoutSearch
                    isDisabled={isConfirmed || isSubmitting}
                />
                {shippingAddressMeta.error && (
                    <div className={styles.warningBarWrapper}>
                        <WarningBar text={shippingAddressMeta.error} />
                    </div>
                )}
                <div className={styles.divider} />
                <h3 className={styles.sectionHeader}>PO DESCRIPTION</h3>
                <TextArea
                    name="description"
                    label="Description"
                    placeholder="Click here to add a description..."
                    value={descriptionField.value}
                    error={descriptionMeta.error}
                    onChange={({ event }) => descriptionField.onChange(event)}
                    className={styles.description}
                    customStyles={textareaStyles}
                    isDisabled={isSubmitting}
                />
                <div className={styles.divider} />
                <h3 className={styles.sectionHeader}>SPECIAL INSTRUCTION</h3>
                <TextArea
                    name="special_instruction"
                    label="Description"
                    placeholder="Click here to add a description..."
                    value={specialInstructionField.value}
                    error={specialInstructionMeta.error}
                    onChange={({ event }) => specialInstructionField.onChange(event)}
                    className={styles.specialInstruction}
                    customStyles={textareaStyles}
                    isDisabled={isSubmitting}
                />
            </div>
        </div>
    )
}

PurchaseOrderFormGeneralInfoTab.propTypes = {
    isSubmitting: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    destinationPointList: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            value: PropTypes.object
        }).isRequired
    ).isRequired,
    handleChangeDestinationPoint: PropTypes.func.isRequired
}

export default PurchaseOrderFormGeneralInfoTab
