import SHIPMENT_TYPE_KEYS from "./shipmentTypeKeys"

const SHIPMENT_TYPES = {
    [SHIPMENT_TYPE_KEYS.TRANSFER]: {
        id: SHIPMENT_TYPE_KEYS.TRANSFER,
        label: "Transfer",
        value: SHIPMENT_TYPE_KEYS.TRANSFER
    },
    [SHIPMENT_TYPE_KEYS.SHIPMENT]: {
        id: SHIPMENT_TYPE_KEYS.SHIPMENT,
        label: "Shipment",
        value: SHIPMENT_TYPE_KEYS.SHIPMENT
    },
    [SHIPMENT_TYPE_KEYS.RETURN]: {
        id: SHIPMENT_TYPE_KEYS.RETURN,
        label: "Return",
        value: SHIPMENT_TYPE_KEYS.RETURN
    },
    [SHIPMENT_TYPE_KEYS.PO]: {
        id: SHIPMENT_TYPE_KEYS.PO,
        label: "PO",
        value: SHIPMENT_TYPE_KEYS.PO
    },
    [SHIPMENT_TYPE_KEYS.CLIENT_RETURN]: {
        id: SHIPMENT_TYPE_KEYS.CLIENT_RETURN,
        label: "Client return",
        value: SHIPMENT_TYPE_KEYS.CLIENT_RETURN
    }
}

export default SHIPMENT_TYPES
