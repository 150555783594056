import React, { Fragment, forwardRef } from "react"
import { Link } from "react-router-dom"
import _ from "lodash/core"
import { Formik, Form as FormikForm } from "formik"
import withStyles from "HOC/withStyles"
import { SaveBar } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import Layout from "components/Reusable/Layout"

import { GeneralInfoSection, FinancialsSection, Sidebar, DeleteModal } from "./components"

import styles from "./styles.css"
import layoutStyles from "./overrides/layout.css"
import eyeSvg from "assets/eye.svg"
import closeSvg from "assets/close-blue.svg"

const Form = forwardRef((props, ref) => {
    const {
        cx,
        isEdit,
        isSubmitting,
        isLoading,
        isDeleting,
        isUploading,
        isFinancialsTab,
        isLoadingClient,
        initValues,
        project,
        setOpenModal,
        openModal,
        handleSubmit,
        handleCancel,
        handleSelectClient,
        handleDeleteProject,
        handleUploadSuccess,
        handleUploadStart
    } = props

    const projectName = project.data.name || ""

    return (
        <Fragment>
            <Formik initialValues={initValues} enableReinitialize onSubmit={handleSubmit} ref={ref}>
                {propsForm => (
                    <FormikForm>
                        <Layout
                            title={
                                isEdit ? (
                                    <Fragment>
                                        Edit Project: <span>{projectName}</span>
                                    </Fragment>
                                ) : (
                                    "New Project"
                                )
                            }
                            customStyles={layoutStyles}
                            cta={
                                isEdit && [
                                    <Link to={`/projects/${project.data.id}`} className={cx("headerButton")}>
                                        <img src={eyeSvg} alt="" /> View
                                    </Link>,
                                    <button
                                        className={cx("headerButton", "closeButton")}
                                        onClick={evt => {
                                            evt.preventDefault()
                                            setOpenModal("delete")
                                        }}
                                    >
                                        <img src={closeSvg} alt="" className={cx("closeIcon")} /> Delete
                                    </button>
                                ]
                            }
                        >
                            {isLoading ? (
                                <div className={cx("loader")}>
                                    <SnakeLoader />
                                </div>
                            ) : (
                                <Fragment>
                                    <div className={cx("contentWrapper")}>
                                        <div className={cx("tabs")}>
                                            <Link
                                                className={cx("tab", { active: !isFinancialsTab })}
                                                to={isEdit ? `/projects/${project.data.id}/edit` : "/projects/add"}
                                            >
                                                GENERAL INFO
                                            </Link>
                                            <Link
                                                className={cx("tab", { active: isFinancialsTab })}
                                                to={
                                                    isEdit
                                                        ? `/projects/${project.data.id}/edit/financials`
                                                        : "/projects/add/financials"
                                                }
                                            >
                                                FINANCIALS
                                            </Link>
                                        </div>
                                        <div className={cx("section")}>
                                            {isFinancialsTab ? (
                                                <FinancialsSection form={propsForm} isLoadingClient={isLoadingClient} />
                                            ) : (
                                                <GeneralInfoSection
                                                    form={propsForm}
                                                    handleSelectClient={handleSelectClient}
                                                    handleUploadSuccess={handleUploadSuccess}
                                                    handleUploadStart={handleUploadStart}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <Sidebar form={propsForm} />
                                </Fragment>
                            )}
                        </Layout>
                        <SaveBar
                            isSaving={isSubmitting}
                            isShown={!_.isEqual(initValues, propsForm.values) && !isLoading}
                            isDisabled={isUploading}
                            submitLabel="Save"
                            message="Unsaved changes"
                            onCancel={handleCancel}
                            onSubmit={() => {}}
                            isSubmit
                        />
                    </FormikForm>
                )}
            </Formik>
            {isEdit && (
                <DeleteModal
                    isOpen={openModal === "delete"}
                    isLoading={isDeleting}
                    onClose={() => setOpenModal()}
                    onSubmit={handleDeleteProject}
                    projectName={project.data.name || ""}
                />
            )}
        </Fragment>
    )
})

export default withStyles(Form, styles)
