import React, { Fragment, useCallback, useContext, useMemo, useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useSelector } from "react-redux"

import {
    H1,
    Panel,
    Table,
    TableHead,
    TableBody,
    TableSortableHeadCell,
    TableHeadCell,
    TableCell,
    TableRowWithExpandedDetails,
    TableCellWithExpandButton,
    TableDateCell,
    Status,
    TableFilterBarMoreFilters,
    TableFilterBarMoreFiltersGrid,
    TableFilterBarMoreFiltersGridItem,
    TableFilterBarSearch,
    TableFilterBarSelect,
    TableFilterBarMoreFiltersButton,
    TableFilterBar,
    useTableSort,
    useTableFilterBarMultiselect,
    useTableFilterBarSearch,
    useTableFilterBarMoreFilters,
    EmptyList,
    GridIcon
} from "@butterfly-frontend/ui"
import { formatDate, toApiDate } from "helpers/date"
import { DateRangePicker, Pagination } from "ui"
import { SelectLocation, SelectProductConditions, SelectUser } from "ui/Filters"
import { SnakeLoader } from "ui/Skeleton"
import STOCK_ITEM_STATUSES from "modules/WmsModule/constants/stockItemStatuses"
import STOCK_ITEM_STATUS_FILTER_OPTIONS from "modules/WmsModule/constants/stockItemStatusFilterOptions"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import { useStockAdjustmentList } from "modules/WmsModule/hooks/api/useStockAdjustment"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import { Header, StockAdjustmentDetails } from "modules/WmsModule/components"
import useNumericValueFromQueryString from "modules/WmsModule/hooks/useNumericValueFromQueryString"
import ListFiltersContext from "modules/WmsModule/contexts/ListFiltersContext"

import selectWithSearchStyles from "./overrides/selectWithSearch.module.css"
import dateRangePickerStyles from "./overrides/dateRangePicker.module.css"
import styles from "./StockAdjustmentListPage.module.css"

const TABLE_COLUMNS = {
    id: { minWidth: 60, width: "8%" },
    warehouse: { minWidth: 80, width: "14%" },
    location: { minWidth: 80, width: "10%" },
    condition: { minWidth: 100, width: "10%" },
    status: { minWidth: 100, width: "10%" },
    reason: { minWidth: 100, width: "15%" },
    date: { minWidth: 120, width: "12%" },
    createdBy: { minWidth: 80, width: "8%" },
    action: { minWidth: 100, width: 80 }
}

const warehouseOptionsFactory = warehouseList => warehouseList.map(({ id, name: label }) => ({ id, label, value: id }))

const StockAdjustmentListPage = () => {
    const { replace: replaceUrl } = useHistory()
    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)
    const expandedDetailsIdFromQueryString = useNumericValueFromQueryString({ key: "id" })
    const [expandedRowId, setExpandedRowId] = useState(expandedDetailsIdFromQueryString)
    const [isInitialFetch, setIsInitialFetch] = useState(true)

    const {
        set: setFiltersContext,
        values: { stockAdjustmentList: filtersFromContext }
    } = useContext(ListFiltersContext)

    const { sortState, onChangeSort } = useTableSort({ initialState: { direction: "desc", by: "created_at" } })

    const warehouseList = useWarehouseList({
        params: {
            warehouses: assignedWarehouseIds
        }
    })

    const warehouseMultiselect = useTableFilterBarMultiselect({
        isEnabled: warehouseList.fetchStatus.isLoaded,
        initialOptions: warehouseOptionsFactory(warehouseList.data),
        initialSelectedIds: expandedRowId ? [] : filtersFromContext.warehouses.map(({ id }) => id)
    })

    const filterBarSearchProps = useTableFilterBarSearch({
        initialValue: expandedRowId ? "" : filtersFromContext.query
    })
    const statusFilterBarMultiselectProps = useTableFilterBarMultiselect({
        initialOptions: STOCK_ITEM_STATUS_FILTER_OPTIONS,
        initialSelectedIds: expandedRowId ? [] : filtersFromContext.statuses.map(({ id }) => id)
    })

    const {
        moreFiltersButtonProps,
        moreFiltersComponentProps,
        ...filterBarMoreFiltersProps
    } = useTableFilterBarMoreFilters({
        emptyFilterValues: {
            productConditions: [],
            locations: [],
            createdBy: [],
            date: null
        },
        initialFilterValues: expandedRowId
            ? undefined
            : {
                  productConditions: filtersFromContext.conditions,
                  locations: filtersFromContext.locations,
                  createdBy: filtersFromContext.createdBy,
                  date: filtersFromContext.date
              },
        canClear: true
    })

    useEffect(() => {
        const { productConditions, locations, createdBy, date } = filterBarMoreFiltersProps.savedFilterValues

        setFiltersContext(prevState => ({
            ...prevState,
            stockAdjustmentList: {
                query: filterBarSearchProps.debouncedValue,
                warehouses: warehouseMultiselect.selectedValues,
                statuses: statusFilterBarMultiselectProps.selectedValues,
                date,
                createdBy,
                conditions: productConditions,
                locations
            }
        }))
    }, [
        warehouseMultiselect.selectedValues,
        filterBarSearchProps.debouncedValue,
        filterBarMoreFiltersProps.savedFilterValues,
        statusFilterBarMultiselectProps.selectedValues
    ])

    const filters = useMemo(() => {
        const { productConditions, locations, date, createdBy } = filterBarMoreFiltersProps.savedFilterValues
        const mapToId = array => array.map(({ id }) => id)
        const joinToIdsString = array => mapToId(array).join(",")

        return {
            id: isInitialFetch ? expandedDetailsIdFromQueryString : undefined,
            query: filterBarSearchProps.debouncedValue,
            warehouses: warehouseMultiselect.selectedValues.map(({ value }) => value),
            conditions: mapToId(productConditions),
            locations: mapToId(locations),
            statuses: mapToId(statusFilterBarMultiselectProps.selectedValues),
            ...(date ? { created_from: toApiDate(date.startDate), created_to: toApiDate(date.endDate) } : {}),
            creators: joinToIdsString(createdBy)
        }
    }, [
        sortState,
        warehouseMultiselect.selectedValues,
        filterBarSearchProps.debouncedValue,
        filterBarMoreFiltersProps.savedFilterValues,
        statusFilterBarMultiselectProps.selectedValues
    ])

    const onAfterLoaded = useCallback(() => {
        replaceUrl("/wms/stock-adjustments")
        setIsInitialFetch(false)
    }, [replaceUrl, setExpandedRowId])

    const { data, fetchStatus, pagination, fetchFlags } = useTableLogic({
        isEnabled: warehouseMultiselect.isReady,
        useQueryList: useStockAdjustmentList,
        sort: sortState,
        filters,
        reactQueryProps: {
            onSuccess: onAfterLoaded
        }
    })

    return (
        <Fragment>
            <Header>
                <H1 withLeftBorder>Stock Adjustments</H1>
            </Header>
            {fetchStatus.isFirstFetch ? (
                <Panel>
                    <div className={styles.loader}>
                        <SnakeLoader customStyles={styles.loader} />
                    </div>
                </Panel>
            ) : (
                <Panel>
                    <TableFilterBar
                        moreFiltersComponent={
                            <TableFilterBarMoreFilters {...moreFiltersComponentProps}>
                                <TableFilterBarMoreFiltersGrid>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <SelectProductConditions
                                            name="productConditions"
                                            label="Conditions"
                                            value={filterBarMoreFiltersProps.selectedFilterValues.productConditions}
                                            handleSelect={({ target: { value } }) =>
                                                filterBarMoreFiltersProps.onChange("productConditions", value)
                                            }
                                            customStyles={selectWithSearchStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <SelectLocation
                                            multiselect
                                            name="locations"
                                            handleSelect={({ target: { value } }) =>
                                                filterBarMoreFiltersProps.onChange("locations", value)
                                            }
                                            value={filterBarMoreFiltersProps.selectedFilterValues.locations}
                                            customStyles={selectWithSearchStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <DateRangePicker
                                            title="- Enter date range -"
                                            label="Date"
                                            ranges={
                                                filterBarMoreFiltersProps.selectedFilterValues.date || {
                                                    startDate: "",
                                                    endDate: ""
                                                }
                                            }
                                            resetRanges={() => filterBarMoreFiltersProps.onChange("date", null)}
                                            onChange={({ range1 }) =>
                                                filterBarMoreFiltersProps.onChange("date", range1)
                                            }
                                            customStyles={dateRangePickerStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                    <TableFilterBarMoreFiltersGridItem>
                                        <SelectUser
                                            handleSelect={({ target: { value } }) =>
                                                filterBarMoreFiltersProps.onChange("createdBy", value)
                                            }
                                            label="By"
                                            placeholder="Search user"
                                            name="created_by"
                                            value={filterBarMoreFiltersProps.selectedFilterValues.createdBy}
                                            customStyles={selectWithSearchStyles}
                                        />
                                    </TableFilterBarMoreFiltersGridItem>
                                </TableFilterBarMoreFiltersGrid>
                            </TableFilterBarMoreFilters>
                        }
                    >
                        <TableFilterBarSearch placeholder="Search for product..." {...filterBarSearchProps} />
                        <TableFilterBarSelect
                            labelSelectAll="All warehouse"
                            placeholder="Warehouse"
                            numberOfDisplayedItems={6}
                            isLoading={warehouseList.fetchStatus.isLoading}
                            {...warehouseMultiselect.componentProps}
                        />
                        <TableFilterBarSelect
                            labelSelectAll="All statuses"
                            placeholder="Status"
                            {...statusFilterBarMultiselectProps.componentProps}
                        />
                        <TableFilterBarMoreFiltersButton {...moreFiltersButtonProps} />
                    </TableFilterBar>
                    <div className={styles.tableContainer}>
                        {fetchStatus.isLoading && !fetchStatus.isFirstFetch && (
                            <div className={styles.loader}>
                                <SnakeLoader customStyles={styles.loader} />
                            </div>
                        )}
                        {fetchStatus.isLoaded && (
                            <Table>
                                <TableHead>
                                    <TableSortableHeadCell
                                        {...TABLE_COLUMNS.id}
                                        sortState={sortState}
                                        onClick={onChangeSort}
                                        columnKey="id"
                                    >
                                        ID
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        columnKey="warehouse_id"
                                        {...TABLE_COLUMNS.warehouse}
                                        sortState={sortState}
                                        onClick={onChangeSort}
                                    >
                                        WAREHOUSE
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        columnKey="location_id"
                                        {...TABLE_COLUMNS.location}
                                        sortState={sortState}
                                        onClick={onChangeSort}
                                    >
                                        LOCATION
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        columnKey="condition"
                                        {...TABLE_COLUMNS.condition}
                                        sortState={sortState}
                                        onClick={onChangeSort}
                                    >
                                        CONDITION
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        columnKey="status"
                                        {...TABLE_COLUMNS.status}
                                        sortState={sortState}
                                        onClick={onChangeSort}
                                    >
                                        STATUS
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        columnKey="reason"
                                        {...TABLE_COLUMNS.reason}
                                        sortState={sortState}
                                        onClick={onChangeSort}
                                    >
                                        REASON
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        {...TABLE_COLUMNS.date}
                                        sortState={sortState}
                                        onClick={onChangeSort}
                                        columnKey="created_at"
                                    >
                                        DATE
                                    </TableSortableHeadCell>
                                    <TableSortableHeadCell
                                        {...TABLE_COLUMNS.createdBy}
                                        sortState={sortState}
                                        onClick={onChangeSort}
                                        columnKey="created_by"
                                    >
                                        BY
                                    </TableSortableHeadCell>
                                    <TableHeadCell {...TABLE_COLUMNS.action} />
                                </TableHead>
                                <TableBody>
                                    {fetchFlags.isEmptyList && (
                                        <EmptyList Icon={GridIcon} label="No stock adjustments found." />
                                    )}
                                    {fetchFlags.isEmptyListWithFilter && (
                                        <EmptyList
                                            Icon={GridIcon}
                                            label="No stock adjustments found for selected filters."
                                        />
                                    )}
                                    {data.map(item => (
                                        <TableRowWithExpandedDetails
                                            key={item.id}
                                            isExpanded={expandedRowId === item.id}
                                            detailsComponent={<StockAdjustmentDetails stockAdjustmentId={item.id} />}
                                        >
                                            <TableCell {...TABLE_COLUMNS.id}>{item.id}</TableCell>
                                            <TableCell {...TABLE_COLUMNS.warehouse}>
                                                {item.warehouse ? item.warehouse.name : "---"}
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.location}>
                                                {item.location ? item.location.name : "---"}
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.condition}>
                                                {item.stock_item_condition ? (
                                                    <Status
                                                        theme="outline"
                                                        color={item.stock_item_condition.product_condition.color}
                                                        size="small"
                                                        withCircle
                                                    >
                                                        {item.stock_item_condition.product_condition.name}
                                                    </Status>
                                                ) : (
                                                    "---"
                                                )}
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.status}>
                                                {item.stock_item_status ? (
                                                    <Status
                                                        theme="outline"
                                                        color={STOCK_ITEM_STATUSES[item.stock_item_status.status].color}
                                                        size="small"
                                                        withCircle
                                                    >
                                                        {STOCK_ITEM_STATUSES[item.stock_item_status.status].label}
                                                    </Status>
                                                ) : (
                                                    "---"
                                                )}
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.reason}>
                                                {item.stock_item_status && item.stock_item_status.reason
                                                    ? item.stock_item_status.reason.reason
                                                    : "---"}
                                            </TableCell>
                                            <TableDateCell {...TABLE_COLUMNS.date}>
                                                {formatDate(item.created_at)}
                                            </TableDateCell>
                                            <TableCell {...TABLE_COLUMNS.createdBy}>
                                                {item.created_by ? item.created_by.full_name : "N/A"}
                                            </TableCell>
                                            <TableCellWithExpandButton
                                                isExpanded={expandedRowId === item.id}
                                                onClick={() =>
                                                    setExpandedRowId(prevState =>
                                                        prevState !== item.id ? item.id : null
                                                    )
                                                }
                                            />
                                        </TableRowWithExpandedDetails>
                                    ))}
                                </TableBody>
                                {pagination.canDisplay && (
                                    <div className={styles.paginationWrapper}>
                                        <Pagination {...pagination} />
                                    </div>
                                )}
                            </Table>
                        )}
                    </div>
                </Panel>
            )}
        </Fragment>
    )
}

export default StockAdjustmentListPage
