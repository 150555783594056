import * as Yup from "yup"

import { TEXTAREA_MAX_LENGTH, INPUT_MAX_LENGTH, ADDRESS_REGEX } from "constants/index"

export const ORDERS_TYPES = { sales: "sales", rental: "rental" }

const isRental = orderType => orderType === ORDERS_TYPES.rental

const addressInitValues = {
    copy: true,
    company: "",
    first_name: "",
    last_name: "",
    country: "",
    address: "",
    post_code: "",
    city: "",
    state: ""
}
export const INIT_VALUES = orderType => ({
    id: "",
    order_id: "",
    payment_due_at: "",
    description: "",
    instructions: "",
    total_rent: isRental(orderType) ? 0 : null,
    billing_address: addressInitValues,
    shipping_address: isRental(orderType) ? addressInitValues : null,
    items: []
})

const textareaValidation = Yup.string()
    .max(TEXTAREA_MAX_LENGTH)
    .nullable()
const inputValidation = Yup.string()
    .max(INPUT_MAX_LENGTH)
    .nullable()
const addressValidation = Yup.object().shape({
    copy: Yup.boolean(),
    company: inputValidation,
    first_name: inputValidation,
    last_name: inputValidation,
    country: inputValidation,
    address: inputValidation,
    post_code: inputValidation,
    city: inputValidation,
    state: inputValidation
})

export const VALIDATION_SCHEMA = orderType =>
    Yup.object().shape({
        payment_due_at: Yup.string()
            .matches(ADDRESS_REGEX, "Date format is invalid.")
            .required("Payment due date is required field."),
        description: textareaValidation,
        instructions: textareaValidation,
        total_rent: Yup.number().nullable(),
        billing_address: addressValidation,
        shipping_address: isRental(orderType) ? addressValidation : addressValidation.nullable(),
        items: Yup.array()
    })

export const LABELS_OF = {
    [ORDERS_TYPES.sales]: { name: "Sales Order", copyAddress: "Order" },
    [ORDERS_TYPES.rental]: { name: "Rental", copyAddress: "Rental" }
}
