import fetch from "helpers/fetch"
import { serializeQuery } from "helpers/urls"
import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"

export const KEY = "pricing-logs"

const fetchPricingLogsList = ({ product_id = "", sort_by = "", sort_direction = "desc" }) => {
    const queryString = serializeQuery({ product_id, sort_by, sort_direction })
    return fetch.get(`/pricing-logs?${queryString}`)
}

export const usePricingLogsList = useQueryListFactory({
    key: KEY,
    fetchFunction: fetchPricingLogsList
})
