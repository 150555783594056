import React from "react"
import Layout from "components/Reusable/Layout/index"
import Header from "modules/ContentModule/components/Header"

import HeaderStyles from "./overrides/Header.css"

const NavigationSubcategoriesLayout = ({
    children,
    history,
    match,
    title,
    parentId,
    resetFilters,
    clickAddButton,
    parentCategoryTitle
}) => {
    const handleClickTab = isFilters =>
        history.push(`/content/categories-menu/${match.params.categoryId}${isFilters ? "/filters" : ""}`)

    const isFilters = history.location.pathname.includes("/filters")

    return (
        <Layout
            returnLink={{
                link: `/content/categories-menu${parentId ? `/${parentId}` : ""}`,
                label: parentCategoryTitle || "Categories Menu"
            }}
        >
            <Header
                customStyles={HeaderStyles}
                title={title}
                resetButton={isFilters && { label: "Reset to Default", handler: resetFilters }}
                addButton={isFilters && { label: "Add Filter", handler: clickAddButton }}
                tabs={[
                    {
                        label: "Subcategories",
                        onClick: () => handleClickTab(),
                        isActive: !isFilters
                    }
                ]}
            />
            {children}
        </Layout>
    )
}

export default NavigationSubcategoriesLayout
