import React from "react"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import classNames from "classnames/bind"

import Input from "components/Reusable/Form/Input/index"
import Textarea from "components/Reusable/Form/Textarea/index"
import Button from "components/Reusable/Form/Button/index"

import styles from "./ClientGroupsModal.css"
import inputNameStyles from "./overrides/InputName.css"
import inputDiscountStyles from "./overrides/InputDiscount.css"
import textareaStyles from "./overrides/Textarea.css"

import closeIcon from "assets/close.svg"

const cx = classNames.bind(styles)

const schema = Yup.object().shape({
    name: Yup.string().required("This field is required."),
    discount: Yup.number("Value has to be number.")
        .min(0, "Discount has to be greater than 0%.")
        .max(100, "Discount cannot be greater than 100%.")
        .required("This field is required."),
    description: Yup.string().required("This field is required.")
})

class Modal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isEdit: !!props.currentEditGroup
        }
    }

    handleSubmit = (values, formikProps) => {
        const { createOrUpdateClientGroup } = this.props
        const method = this.state.isEdit ? "patch" : "post"

        createOrUpdateClientGroup(method, values, errors => {
            if (errors) {
                formikProps.setErrors(errors)
            }

            formikProps.setSubmitting(false)
        })
    }

    render() {
        const { handleClose, currentEditGroup } = this.props
        const { isEdit } = this.state

        return (
            <div className={cx("modal-background")}>
                <div className={cx("modal-cover")}>
                    <img src={closeIcon} alt="close" className={cx("close-icon")} onClick={handleClose} />

                    <Formik
                        initialValues={{
                            name: isEdit ? currentEditGroup.name : "",
                            discount: isEdit ? currentEditGroup.discount : "0",
                            description: isEdit ? currentEditGroup.description : ""
                        }}
                        onSubmit={this.handleSubmit}
                        validationSchema={schema}
                    >
                        {({ handleSubmit, isSubmitting, values, touched, errors, setFieldValue }) => {
                            return (
                                <form className={cx("form")} onSubmit={handleSubmit}>
                                    <div className={cx("row")}>
                                        <Field name="name">
                                            {({ field }) => {
                                                return (
                                                    <Input
                                                        label="Interaction name"
                                                        customStyles={inputNameStyles}
                                                        error={touched.name && errors.name}
                                                        value={values.name}
                                                        onChange={value => {
                                                            setFieldValue(field.name, value.event.target.value)
                                                        }}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>
                                    <div className={cx("row")}>
                                        <Field name="discount">
                                            {({ field }) => {
                                                return (
                                                    <Input
                                                        type="number"
                                                        label="Group discount"
                                                        customStyles={inputDiscountStyles}
                                                        innerLabel="%"
                                                        error={touched.discount && errors.discount}
                                                        value={values.discount}
                                                        onChange={value => {
                                                            setFieldValue(field.name, value.event.target.value)
                                                        }}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>
                                    <div className={cx("row")}>
                                        <Field name="description">
                                            {({ field }) => {
                                                return (
                                                    <Textarea
                                                        label="Description"
                                                        customStyles={textareaStyles}
                                                        error={touched.description && errors.description}
                                                        value={values.description}
                                                        onChange={value => {
                                                            setFieldValue(field.name, value.event.target.value)
                                                        }}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>
                                    <div className={cx("buttons")}>
                                        <span className={cx("form-cancel")} onClick={handleClose}>
                                            Cancel
                                        </span>
                                        <Button
                                            type="submit"
                                            className={["first-button", "submit-button"]}
                                            label={isEdit ? "Save" : "Create"}
                                            isLoading={isSubmitting}
                                        />
                                    </div>
                                </form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        )
    }
}

export default Modal
