import * as yup from "yup"

export default yup.object().shape({
    name: yup
        .string()
        .max(250, "Name may not be greater than 250 characters")
        .required("Name is required"),
    note: yup
        .string()
        .max(2000, "Note may not be greater than 2000 characters")
        .nullable(),
    price: yup
        .number()
        .min(0, "ItemList Price must be grater than 0")
        .required("ItemList price is required"),
    quantity: yup
        .number()
        .min(0, "Qty must be grater than 0")
        .nullable(),
    discount: yup
        .number()
        .min(0, "Discount must be grater than 0")
        .max(100, "Discount may not be grater than 100")
        .nullable(),
    tax_code: yup
        .number()
        .min(0, "Tax must be grater than 0")
        .max(100, "Tax may not be grater than 100")
        .nullable()
})
