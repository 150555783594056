import fetch from "helpers/fetch"
import useMutationFactory from "modules/WmsModule/hooks/factories/useMutationFactory"

export const KEY = "boxes"

const fetchStockItemBoxBoxEdit = ({ id, data }) => fetch.patch(`/boxes/${id}`, data)

export const useStockItemBoxEdit = useMutationFactory({
    key: KEY,
    fetchFunction: fetchStockItemBoxBoxEdit
})
