import React, { Fragment, useState, useEffect, useMemo, useRef } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import idx from "idx"
import xorBy from "lodash/xorBy"

import Layout from "components/Reusable/Layout"
import AddEditForm from "ui/AddEditForm"

import { getInvoice, createInvoice, updateInvoice } from "actions/invoices"
import { showErrorNotification, showSuccessNotification } from "actions/notification"

import fetch from "helpers/fetch"
import { successState } from "helpers/fetchStatus"
import { getRouteByOrderType } from "helpers/urls"
import useFetch from "hooks/useFetch"
import useActions from "hooks/useActions"

import { GeneralInfoSection, ItemsSection, PaymentSection } from "./components"

import withStyles from "HOC/withStyles"
import styles from "./styles.css"
import layoutStyles from "./overrides/layout.css"
import eyeSvg from "assets/eye.svg"

import { INIT_VALUES, VALIDATION_SCHEMA, LABELS_OF, ORDERS_TYPES } from "./constants"

const Form = props => {
    const { cx, isNew, location, history } = props

    const { invoiceId } = useParams()

    const invoiceIdNumber = Number(invoiceId)
    const isEdit = !!invoiceIdNumber && !isNew
    const order = useRef(idx(location, _ => _.state.order)).current

    const invoice = useSelector(state => state.db.invoices.current)
    const { id: orderId, type: orderType, billing_address, shipping_address } = useMemo(
        () =>
            (isEdit ? idx(invoice, _ => _.data.order) : order) || {
                id: 0,
                type: ORDERS_TYPES.sales
            },
        [isEdit, invoice.data, order]
    )
    const copyAddress = { billing_address, shipping_address }
    const labelsOfType = LABELS_OF[orderType]
    const route = getRouteByOrderType(orderType)
    const invoicesInOrderPathname = `/orders/${route}/${orderId}/invoices`
    const isRental = orderType === ORDERS_TYPES.rental

    const actions = useActions({
        getInvoice,
        createInvoice,
        updateInvoice,
        showErrorNotification,
        showSuccessNotification
    })

    const config = {
        defaultAside: PaymentSection,
        defaultLeftColumn: GeneralInfoSection,
        tabs: isRental
            ? null
            : {
                  items: { label: "Items", render: ItemsSection },
                  "general-info": { label: "General info", render: GeneralInfoSection }
              }
    }

    const goToInvoicesInOrder = state => history.push({ pathname: invoicesInOrderPathname, state })

    const [onSubmit, submitFetchStatus] = useFetch({
        action: formData => {
            if (!formData.items.length && !isRental) {
                return Promise.reject({ message: "Please add one or more items to this invoice." })
            }

            const body = toApiFormat(formData)
            const [fetchAction, params] = isEdit ? ["update", [invoiceId, body]] : ["create", [body]]
            return actions[`${fetchAction}Invoice`](...params)
        },
        onSuccess: ({ data }) => {
            goToInvoicesInOrder({ invoiceId: data.id, forceFetchOrder: true })
            actions.showSuccessNotification()
        },
        onError: (error, _, formikActions) => {
            formikActions.setErrors(error.errors)
            actions.showErrorNotification(idx(error.errors || {}, _ => _.shipping_address[0]) || error.message)
        }
    })

    const [orderItems, setOrderItems] = useState([])

    useEffect(() => {
        const addAction = !!order ? () => Promise.resolve({ data: { order: { id: orderId } } }) : () => Promise.reject()
        const initAction = isEdit ? () => actions.getInvoice(invoiceIdNumber) : addAction

        initAction()
            .then(({ data }) => fetchOrderItems(data.order.id))
            .then(({ data }) => setOrderItems(filterItemsWithoutInvoice(data)))
            .catch(redirectToList)
    }, [])

    const fetchOrderItems = orderId => fetch.get(`/order-items?order_id=${orderId}`)

    const redirectToList = () => {
        history.push("/orders/invoices")
        actions.showErrorNotification()
    }

    const filterItemsWithoutInvoice = items => (Array.isArray(items) ? items.filter(item => !item.invoice) : [])

    const onCancel = () => goToInvoicesInOrder(isEdit && { invoiceId })

    const uuid = (isEdit ? invoice.data.uuid : idx(order, _ => _.uuid)) || ""
    const fetchStatus = isEdit ? invoice.fetchStatus : successState()

    const getInitialValues = initialData =>
        Object.entries(INIT_VALUES(orderType)).reduce((acc, [key, value]) => {
            if (key === "items") {
                return { ...acc, items: filterItemsWithoutInvoice(initialData.items) }
            }
            if (["billing_address", "shipping_address"].includes(key) && !isEdit) {
                return { ...acc, [key]: { ...(initialData[key] || value), copy: true } }
            }
            return { ...acc, [key]: initialData[key] || value }
        }, {})
    const initialValues = getInitialValues(isEdit ? invoice.data : order || {})

    const basePath = isEdit ? `/orders/invoices/${invoiceId}/edit` : "/orders/invoices/add"

    const canEditItems = !isEdit || invoice.data.status === "new"

    return (
        <Layout
            title={
                isEdit ? (
                    <Fragment>
                        Edit Invoice:<span className={cx("titleSubLabel")}>{uuid}</span>
                    </Fragment>
                ) : (
                    <Fragment>
                        New Invoice<span className={cx("titleSubLabel")}>to {labelsOfType.name}</span>
                        <span className={cx("salesOrderName")}>{uuid}</span>
                    </Fragment>
                )
            }
            cta={
                isEdit && [
                    <Link
                        to={{
                            pathname: invoicesInOrderPathname,
                            state: { invoiceId: invoice.data.id }
                        }}
                        className={cx("headerButton")}
                    >
                        <img src={eyeSvg} alt="" /> View Invoice
                    </Link>
                ]
            }
            customStyles={layoutStyles}
        >
            <AddEditForm
                orderType={orderType}
                isRental={isRental}
                labelsOfType={labelsOfType}
                canEditItems={canEditItems}
                orderItems={orderItems}
                copyAddress={copyAddress}
                isSubmitting={submitFetchStatus.isLoading}
                fetchStatus={fetchStatus}
                basePath={basePath}
                initialValues={initialValues}
                config={config}
                validationSchema={VALIDATION_SCHEMA(orderType)}
                onCancel={onCancel}
                onSubmit={onSubmit}
            />
        </Layout>
    )

    function toApiFormat(formData) {
        const mapItems = items => items.map(item => ({ id: item.id }))

        const currentItems = invoice.data.items
        const editedItemsWithoutAdded =
            isEdit && formData.items.filter(i => currentItems.some(currI => i.id === currI.id))
        const itemsToDetach = isEdit
            ? { items_detach: mapItems(xorBy(currentItems, editedItemsWithoutAdded, "id")) }
            : {}

        return { ...formData, items: mapItems(formData.items), ...itemsToDetach, order_id: orderId }
    }
}

export default withStyles(Form, styles)
