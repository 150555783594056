import React, { Component } from "react"
import { connect } from "react-redux"
import Modal from "components/Modal/Modal"
import * as sessionActions from "actions/session"
import * as clientActions from "actions/client"
import NewSessionClients from "components/Modal/NewSessionClients"
import withDebounce from "HOC/withDebounce"
import withSession from "HOC/withSession"
import { withRouter } from "react-router-dom"

class NewSession extends Component {
    state = {
        searchPhrase: ""
    }

    componentDidMount() {
        this.props.getClients()
    }

    render() {
        const { clients, isLoaded, activeClient } = this.props
        const { searchPhrase } = this.state
        return (
            <Modal>
                <NewSessionClients
                    activeClient={activeClient}
                    handleHide={this.handleHide}
                    handleWorkWith={this.handleWorkWith}
                    handleSearch={this.handleSearch}
                    clearInput={this.clearInput}
                    filter={searchPhrase}
                    clients={clients}
                    isLoaded={isLoaded}
                />
            </Modal>
        )
    }

    handleHide = () => {
        const { handleHide } = this.props
        handleHide()
    }

    handleWorkWith = id => {
        const {
            clients,
            sendStartSession,
            toggleSession,
            sendAssignClient,
            activeClient,
            assignClient,
            isActiveSession,
            history
        } = this.props
        const client = clients.data.filter(element => element.id === id)

        if (isActiveSession && activeClient.isTemporary && !id) {
            history.push("/contact/add")
            return this.handleHide()
        }

        if (!isActiveSession) {
            toggleSession()
            sendStartSession({
                client_id: client && client[0] ? client[0].id : null
            })

            if (client && client[0]) {
                assignClient(client[0])
            }
        }

        if (isActiveSession && client && client[0]) {
            sendAssignClient(client[0])
        }

        return this.handleHide()
    }

    handleSearch = ({ query }) => {
        this.setState(
            {
                searchPhrase: query
            },
            () => {
                this.filterClients(query)
            }
        )
    }

    filterClients = (query, page, length) => {
        const { handleDebounce, getClients } = this.props
        handleDebounce(() => {
            getClients({ query })
        })
    }

    clearInput = () => {
        this.setState(
            {
                searchPhrase: ""
            },
            () => {
                this.filterClients()
            }
        )
    }
}

const mapStateToProps = state => {
    return {
        activeClient: state.ui.session.activeClient,
        clients: state.clientStore.clients,
        isLoaded: state.clientStore.clients.isLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleSession: () => dispatch(sessionActions.toggleSession()),
        assignClient: client => dispatch(sessionActions.assignClient(client)),
        getClients: params => dispatch(clientActions.getClients(params))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withSession(withDebounce(withRouter(NewSession))))
