import React from "react"
import PropTypes from "prop-types"

import ReactSvg from "react-svg"
import Button from "ui/Button"
import { MultiSelectActions } from "../components"

import withStyles from "HOC/withStyles"
import styles from "./topBar.css"

import newRoomIcon from "assets/room-new.svg"

const TopBar = props => {
    const { cx, isMultiSelect, areAllChecked, disabledActions, productCount, handleAction } = props

    const onDelete = () => handleAction("delete")
    const onAddToRoom = () => handleAction("addToRoom")
    const onCopyToRoom = () => handleAction("copyToRoom")
    const onAddToSalesOrder = () => handleAction("addToSalesOrder")
    const onNewRoom = () => handleAction("newRoom")
    const onAddProduct = () => handleAction("addProduct")

    return (
        <header className={cx("root", { isMultiSelect })}>
            <div className={cx("titleWrapper")}>
                <span className={cx("title")}>
                    {isMultiSelect ? `${areAllChecked ? "All s" : "S"}elected` : "Products"}
                </span>
                <span className={cx("productCount")}>{productCount}</span>
            </div>
            {isMultiSelect ? (
                <MultiSelectActions
                    onDelete={onDelete}
                    onAddToRoom={onAddToRoom}
                    onCopyToRoom={onCopyToRoom}
                    onAddToSalesOrder={onAddToSalesOrder}
                />
            ) : (
                <ul className={cx("actionList")}>
                    <li>
                        <button className={cx("newRoomButton")} disabled={disabledActions} onClick={onNewRoom}>
                            <ReactSvg src={newRoomIcon} />
                            New room
                        </button>
                    </li>
                    <li>
                        <Button
                            className={`first-button ${cx("addProductButton")}`}
                            isDisabled={disabledActions}
                            label="Add item"
                            onClick={onAddProduct}
                        />
                    </li>
                </ul>
            )}
        </header>
    )
}

TopBar.propTypes = {
    isMultiSelect: PropTypes.bool.isRequired,
    areAllChecked: PropTypes.bool.isRequired,
    disabledActions: PropTypes.bool,
    productCount: PropTypes.number.isRequired,
    handleAction: PropTypes.func.isRequired
}

export default withStyles(TopBar, styles)
