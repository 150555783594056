import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Image } from "ui"
import {
    Panel,
    Table,
    TableHead,
    TableBody,
    TableHeadCell,
    TableCell,
    TableRow,
    Status,
    DetailsCard
} from "@butterfly-frontend/ui"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"
import STOCK_ITEM_STATUSES from "modules/WmsModule/constants/stockItemStatuses"
import { ShipmentAddress } from "modules/WmsModule/components"
import { ADDRESS_PROP_TYPE } from "modules/WmsModule/propTypes"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import CLIENT_RETURN_SHIPMENT_STATUSES from "modules/WmsModule/constants/clientReturnShipmentStatuses"

import styles from "./ShipmentSentPage.module.css"

const CELL_WIDTH_17 = {
    minWidth: 100,
    width: "17%"
}

const ShipmentSentPage = ({ data }) => {
    const isClientReturn = data.type === SHIPMENT_TYPE_KEYS.RETURN && !!data.order
    const shipmentStatuses = isClientReturn ? CLIENT_RETURN_SHIPMENT_STATUSES : SHIPMENT_STATUSES
    const shipmentStatus = shipmentStatuses[data.status.status]

    return (
        <Fragment>
            <Panel classes={{ panel: styles.details }}>
                <DetailsCard header="SO">{data.order ? data.order.uuid : "---"}</DetailsCard>
                <DetailsCard header="SHIP FROM">
                    {data.source_address ? (
                        <ShipmentAddress shipmentAddress={data.source_address} />
                    ) : (
                        data.source_warehouses.map(({ name }) => name).join(", ")
                    )}
                </DetailsCard>
                <DetailsCard header="SHIP TO">
                    {data.destination_address ? (
                        <ShipmentAddress shipmentAddress={data.destination_address} />
                    ) : (
                        data.destination_warehouse.name
                    )}
                </DetailsCard>
                <DetailsCard header="SHIPPING STATUS">
                    <Status theme={shipmentStatus.theme} color={shipmentStatus.color}>
                        {shipmentStatus.label}
                    </Status>
                </DetailsCard>
                <DetailsCard header="CARRIER">{data.carrier || "---"}</DetailsCard>
                <DetailsCard header="TRACKING NUMBER">{data.tracking_number || "---"}</DetailsCard>
            </Panel>
            <Panel classes={{ panel: styles.items }}>
                <h2 className={styles.itemsHeader}>Items</h2>
                <Table>
                    <TableHead>
                        <TableHeadCell width={40} minWidth={40}>
                            #
                        </TableHeadCell>
                        <TableHeadCell width="50%" minWidth={200}>
                            PRODUCT NAME
                        </TableHeadCell>
                        <TableHeadCell {...CELL_WIDTH_17}>TYPE</TableHeadCell>
                        <TableHeadCell {...CELL_WIDTH_17}>WAREHOUSE</TableHeadCell>
                        <TableHeadCell {...CELL_WIDTH_17}>CONDITION</TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {data.items.map((item, index) => {
                            const stockItemStatus = STOCK_ITEM_STATUSES[item.status] || { label: "N/A" }

                            return (
                                <TableRow key={item.id}>
                                    <TableCell width={40} minWidth={40}>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell width="50%" minWidth={200}>
                                        <div className={styles.productCell}>
                                            {item.thumb && <Image className={styles.productThumb} src={item.thumb} />}
                                            {item.name}
                                        </div>
                                    </TableCell>
                                    <TableCell {...CELL_WIDTH_17}>
                                        <div className={styles.productStatus}>{stockItemStatus.label}</div>
                                    </TableCell>
                                    <TableCell {...CELL_WIDTH_17} classes={{ root: styles.warehouseCell }}>
                                        {item.warehouse.name}
                                    </TableCell>
                                    <TableCell {...CELL_WIDTH_17}>
                                        <Status color={item.condition.color}>{item.condition.name}</Status>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Panel>
            <div className={styles.description}>
                <h3>Description</h3>
                <p>{data.description}</p>
            </div>
        </Fragment>
    )
}

ShipmentSentPage.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        source_warehouses: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired
        ),
        source_address: ADDRESS_PROP_TYPE,
        status: PropTypes.shape({
            status: PropTypes.string.isRequired
        }).isRequired,
        order: PropTypes.shape({
            id: PropTypes.number.isRequired,
            uuid: PropTypes.string.isRequired
        }),
        purchase_order: PropTypes.shape({
            uuid: PropTypes.string.isRequired
        }),
        carrier: PropTypes.string,
        tracking_number: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                thumb: PropTypes.string.isRequired,
                condition: PropTypes.shape({
                    color: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired
                }),
                status: PropTypes.string.isRequired,
                warehouse: PropTypes.shape({
                    name: PropTypes.string.isRequired
                }).isRequired
            })
        ).isRequired,
        destination_warehouse: PropTypes.shape({
            name: PropTypes.string.isRequired
        }),
        destination_address: ADDRESS_PROP_TYPE,
        description: PropTypes.string
    })
}

export default ShipmentSentPage
