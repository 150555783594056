import * as constants from "constants/index"

function current(state = {}, action) {
    switch (action.type) {
        case constants.GET_CHANNEL_START:
            return { ...state, isLoading: true, data: {} }
        case constants.GET_CHANNEL_SUCCESS:
            return { ...state, isLoading: false, data: action.payload.data.data }
        case constants.GET_CHANNEL_ERROR:
            return { ...state, isLoading: false, data: {} }

        case constants.DELETE_CHANNEL_START:
            return { ...state, isLoading: true }
        case constants.DELETE_CHANNEL_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: state.data.id === action.payload ? {} : { ...state.data }
            }
        case constants.DELETE_CHANNEL_ERROR:
            return { ...state, isLoading: false }

        default:
            return state
    }
}

export default current
