import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useCombinationsCreator, useActions } from "hooks"
import { Skeleton } from "ui"
import fetch from "helpers/fetch"
import { treeToFlat } from "reducers/ui/combinationsCreator"
import CombinationsCreator from "./CombinationsCreator"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

const CombinationsCreatorContainer = props => {
    const [
        { pickedAttributes, tree, modifiedIds, resources },
        { getInitialData, setUnsavedChanges, resetCombinationsCreator }
    ] = useCombinationsCreator()
    const actions = useActions({ showSuccessNotification, showErrorNotification })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isWarningModalOpen, setIsWarningModalOpen] = useState(false)
    const [warningModalCallback, setWarningModalCallback] = useState(() => () => {})
    const {
        isTemplate = false,
        match: {
            params: { productId, combinationTemplateId }
        }
    } = props
    const object = useMemo(() => {
        return isTemplate
            ? { type: "templates", id: Number(combinationTemplateId) }
            : { type: "products", id: Number(productId) }
    }, [isTemplate, productId, combinationTemplateId])
    const isCreatingProduct = !isTemplate && !productId

    useEffect(() => {
        if (!isCreatingProduct) {
            getInitialData(object)
        }

        return () => resetCombinationsCreator()
    }, [])

    function submitCombinationPattern() {
        setIsSubmitting(true)
        fetch
            .postRAW("/combination-patterns", {
                object_type: object.type,
                object_id: object.id,
                attributes: pickedAttributes.filter(({ value }) => !!value).map(({ value }) => ({ id: value })),
                attribute_values: treeToFlat(tree, modifiedIds)
            })
            .then(() => {
                actions.showSuccessNotification()
                setUnsavedChanges(false)
            })
            .catch(() => actions.showErrorNotification())
            .finally(() => setIsSubmitting(false))
    }

    function withWarningModal(action) {
        return (...args) => {
            setIsWarningModalOpen(true)
            setWarningModalCallback(() => () => {
                action(...args)
                setIsWarningModalOpen(false)
            })
        }
    }

    if (isCreatingProduct) {
        return <h3>Combinations can be add only after product is created.</h3>
    }

    return (
        <Skeleton fetchStatus={resources.groups.fetchStatus}>
            <CombinationsCreator
                isTemplate={isTemplate}
                isSubmitting={isSubmitting}
                submitCombinationPattern={submitCombinationPattern}
                withWarningModal={withWarningModal}
                warningModal={{
                    isOpen: isWarningModalOpen,
                    onClose: () => setIsWarningModalOpen(false),
                    onSubmit: warningModalCallback
                }}
            />
        </Skeleton>
    )
}
CombinationsCreatorContainer.propTypes = {
    isTemplate: PropTypes.bool
}

export default CombinationsCreatorContainer
