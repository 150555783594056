import React, { Fragment } from "react"
import ReactSVG from "react-svg"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"
import addIcon from "assets/ico-add.svg"
import moveIcon from "assets/move-to.svg"
import copyIcon from "assets/duplicate.svg"
import styles from "./Header.css"
import { ActionButton } from "ui"
import { ACTION_TYPES } from "modules/AttributeGroupsModules/constants"

const Header = props => {
    const {
        title,
        numberOfSelectedItems,
        numberOfItems,
        handleAction,
        isMovementActionsDisabled,
        titleAddButton = "Create new"
    } = props
    const cx = useStyles(styles)

    return (
        <div className={cx("root")}>
            <div className={cx("leftSide")}>
                <h1 className={cx("title")}>
                    {title}
                    {!!numberOfSelectedItems && ":"}
                </h1>
                {!!numberOfSelectedItems ? (
                    <div className={cx("selected")}>
                        <span className={cx("label")}>Selected</span>
                        <span className={cx("numberOfItems")}>{numberOfSelectedItems}</span>
                    </div>
                ) : (
                    <div className={cx("numberOfItems")}>{numberOfItems}</div>
                )}
            </div>
            <div className={cx("rightSide")}>
                {!!numberOfSelectedItems && (
                    <Fragment>
                        {!isMovementActionsDisabled && (
                            <Fragment>
                                <ActionButton
                                    label="Copy to"
                                    icon={copyIcon}
                                    className={cx("button", "outline", "copy")}
                                    handleClick={() => handleAction(ACTION_TYPES.COPY)}
                                />
                                <ActionButton
                                    label="Move to"
                                    icon={moveIcon}
                                    className={cx("button", "outline", "move")}
                                    handleClick={() => handleAction(ACTION_TYPES.MOVE)}
                                />
                            </Fragment>
                        )}
                    </Fragment>
                )}
                <div className={cx("button", "add")} onClick={() => handleAction(ACTION_TYPES.CREATE)}>
                    <ReactSVG src={addIcon} className={cx("icon")} />
                    <span>{titleAddButton}</span>
                </div>
            </div>
        </div>
    )
}

Header.propTypes = {
    title: PropTypes.string,
    titleAddButton: PropTypes.string,
    numberOfItems: PropTypes.number,
    numberOfSelectedItems: PropTypes.number,
    handleAction: PropTypes.func.isRequired,
    isMovementActionsDisabled: PropTypes.bool
}

export default Header
