import {
    GET_SHIPPING_METHODS_SUCCESS,
    GET_SHIPPING_METHODS_ERROR,
    GET_SHIPPING_METHODS_START,
    CREATE_SHIPPING_METHOD_START,
    CREATE_SHIPPING_METHOD_SUCCESS,
    CREATE_SHIPPING_METHOD_ERROR,
    UPDATE_SHIPPING_METHOD_START,
    UPDATE_SHIPPING_METHOD_SUCCESS,
    UPDATE_SHIPPING_METHOD_ERROR,
    DELETE_SHIPPING_METHOD_START,
    DELETE_SHIPPING_METHOD_SUCCESS,
    DELETE_SHIPPING_METHOD_ERROR
} from "../constants"
import fetch from "../helpers/fetch"
import { objToQueryString } from "../helpers/urls"

const getShippingMethodsStart = () => ({ type: GET_SHIPPING_METHODS_START })

const getShippingMethodsSuccess = (data, fromCache) => ({
    type: GET_SHIPPING_METHODS_SUCCESS,
    payload: data,
    fromCache
})

const getShippingMethodsError = error => ({ type: GET_SHIPPING_METHODS_ERROR, payload: error })

export const getShippingMethods = (params = {}, fromCache = false) => {
    return async (dispatch, getState) => {
        const store = getState().db.shippingMethods

        if (fromCache && store.fromCache) {
            return store.data
        }

        dispatch(getShippingMethodsStart())
        try {
            const data = await fetch.get(`/shipping-methods?${objToQueryString(params, true)}`)

            dispatch(getShippingMethodsSuccess(data, fromCache))
            return data
        } catch (error) {
            dispatch(getShippingMethodsError(error))
            throw new Error(error)
        }
    }
}

const createShippingMethodStart = () => ({ type: CREATE_SHIPPING_METHOD_START })

const createShippingMethodSuccess = data => ({ type: CREATE_SHIPPING_METHOD_SUCCESS, payload: data })

const createShippingMethodError = error => ({ type: CREATE_SHIPPING_METHOD_ERROR, payload: error })

export const createShippingMethod = body => {
    return async dispatch => {
        dispatch(createShippingMethodStart())
        try {
            const data = await fetch.post("/shipping-methods", body)
            dispatch(createShippingMethodSuccess(data))
            return data
        } catch (error) {
            dispatch(createShippingMethodError(error))
            throw error
        }
    }
}

const updateShippingMethodStart = () => ({ type: UPDATE_SHIPPING_METHOD_START })

const updateShippingMethodSuccess = data => ({ type: UPDATE_SHIPPING_METHOD_SUCCESS, payload: data })

const updateShippingMethodError = error => {
    return {
        type: UPDATE_SHIPPING_METHOD_ERROR,
        payload: {
            error
        }
    }
}

export const updateShippingMethod = (paymentMethodId, body) => {
    return async dispatch => {
        dispatch(updateShippingMethodStart())
        try {
            const data = await fetch.patch(`/shipping-methods/${paymentMethodId}`, body)
            dispatch(updateShippingMethodSuccess(data))
        } catch (error) {
            dispatch(updateShippingMethodError(error))
            throw error
        }
    }
}

const deleteShippingMethodStart = () => ({ type: DELETE_SHIPPING_METHOD_START })

const deleteShippingMethodSuccess = data => ({ type: DELETE_SHIPPING_METHOD_SUCCESS, payload: data })

const deleteShippingMethodError = error => ({ type: DELETE_SHIPPING_METHOD_ERROR, payload: error })

export const deleteShippingMethod = paymentMethodId => {
    return async dispatch => {
        dispatch(deleteShippingMethodStart())
        try {
            await fetch.deleteRAW(`/shipping-methods/${paymentMethodId}`)
            dispatch(deleteShippingMethodSuccess({ paymentMethodId }))
        } catch (error) {
            dispatch(deleteShippingMethodError(error))
            throw new Error(error)
        }
    }
}
