import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { DEFAULT_COUNTRY } from "constants/index"
import Address from "./Address"
import { getPlace } from "actions/place"
class AddressContainer extends Component {
    state = {
        selectedCountry: [DEFAULT_COUNTRY, DEFAULT_COUNTRY, DEFAULT_COUNTRY],
        selectedAmericanState: ["", "", ""],
        city: ["", "", ""],
        postCode: ["", "", ""],
        street: ["", "", ""],
        isPostCodeLoading: false
    }

    setValues = (key, value, index) => {
        const { values, handleEdit, setFieldValue } = this.props
        const addresses = [...values.addresses]
        addresses[index][key] = value

        handleEdit(true)
        setFieldValue("addresses", addresses)
    }

    setSameAddressAsMain = (type, name, index, isSame) => {
        const { values, handleEdit, setFieldValue, setAddressSameAsMain } = this.props
        const addresses = [...values.addresses]

        addresses[index] = {
            ...addresses[0],
            type,
            name
        }

        this.setState(
            prevState => {
                const prevSelectedCountry = [...prevState.selectedCountry]
                const prevSelectedAmericanState = [...prevState.selectedAmericanState]
                const prevCity = [...prevState.city]
                const prevPostCode = [...prevState.postCode]

                prevSelectedCountry[index] = prevSelectedCountry[0]
                prevSelectedAmericanState[index] = prevSelectedAmericanState[0]
                prevCity[index] = prevCity[0]
                prevPostCode[index] = prevPostCode[0]

                return {
                    selectedCountry: prevSelectedCountry,
                    selectedAmericanState: prevSelectedAmericanState,
                    city: prevCity,
                    postCode: prevPostCode
                }
            },
            () => setAddressSameAsMain(type, isSame)
        )

        handleEdit(true)
        setFieldValue("addresses", addresses)
    }

    getPostCode = addressIndex => {
        const { getPlace } = this.props
        const { city, selectedCountry, selectedAmericanState, postCode, street } = this.state
        const query = `${city[addressIndex]}, ${selectedAmericanState[addressIndex]}, ${street[addressIndex]}`

        if (
            typeof postCode[addressIndex] === "string" &&
            postCode[addressIndex].length === 0 &&
            selectedCountry[addressIndex] === DEFAULT_COUNTRY &&
            query.length > 5
        ) {
            this.setState({ isPostCodeLoading: true })

            getPlace(query)
                .then(action => {
                    const places = action && action.payload && action.payload.data && action.payload.data.data
                    if (places.post_code) {
                        this.setPostCode(places.post_code, addressIndex)
                    }
                })
                .finally(() => this.setState({ isPostCodeLoading: false }))
        }
    }

    setPostCode = (value, index) => {
        this.setState(prevState => {
            let nextPostCodes = prevState.postCode
            nextPostCodes[index] = value
            return { postCode: nextPostCodes }
        })
        this.setValues("post_code", value, index)
    }

    setStreet = (value, index) => {
        this.setState(prevState => {
            let nextStreets = prevState.street
            nextStreets[index] = value
            return { street: nextStreets }
        })
        this.setValues("address", value, index)
    }

    setCountry = (value, index) => {
        this.setState(prevState => {
            let nextCountries = prevState.selectedCountry
            let nextStates = prevState.selectedAmericanState
            nextCountries[index] = value

            if (value === DEFAULT_COUNTRY) {
                nextStates[index] = ""
            } else {
                this.setValues("state", "", index)
            }

            return {
                selectedCountry: nextCountries,
                selectedAmericanState: nextStates
            }
        })
        this.setValues("country", value, index)
    }

    setAmericanState = (value, index) => {
        this.setState(prevState => ({
            selectedAmericanState: prevState.selectedAmericanState.map((stateValue, stateIndex) =>
                index === stateIndex ? value : stateValue
            )
        }))
        this.setValues("state", value, index)
    }

    setCity = (city, index) => {
        this.setState(prevState => ({
            city: prevState.city.map((cityValue, cityIndex) => (index === cityIndex ? city : cityValue))
        }))
        this.setValues("city", city, index)
    }

    componentDidMount() {
        const { values, isNewProfile, setAddressSameAsMain } = this.props

        const personalAddress = values.addresses.find(el => el.type === "personal") || {}
        const deliveryAddress = values.addresses.find(el => el.type === "delivery") || {}
        const invoiceAddress = values.addresses.find(el => el.type === "invoice") || {}

        const addresses = [
            {
                ...personalAddress,
                type: "",
                name: ""
            },
            {
                ...deliveryAddress,
                type: "",
                name: ""
            },
            {
                ...invoiceAddress,
                type: "",
                name: ""
            }
        ]

        const isDeliverySameAsPersonal = isNewProfile || JSON.stringify(addresses[0]) === JSON.stringify(addresses[1])
        const isInvoiceSameAsPersonal = isNewProfile || JSON.stringify(addresses[0]) === JSON.stringify(addresses[2])

        values.addresses.forEach((addr, index) => {
            this.setState(
                prevState => {
                    const nextState = { ...prevState }
                    nextState.selectedCountry[index] = isNewProfile ? DEFAULT_COUNTRY : addr.country || ""
                    nextState.city[index] = addr.city || ""
                    nextState.postCode[index] = addr.post_code || ""
                    nextState.selectedAmericanState[index] = addr.state || ""

                    return {
                        ...nextState
                    }
                },
                () => {
                    setAddressSameAsMain("delivery", isDeliverySameAsPersonal)
                    setAddressSameAsMain("invoice", isInvoiceSameAsPersonal)
                }
            )
        })
    }

    render() {
        const { countries, states, shownAddresses } = this.props

        return (
            <Address
                {...this.state}
                {...this.props}
                setValues={this.setValues}
                setCity={this.setCity}
                setStreet={this.setStreet}
                shownAddresses={shownAddresses}
                setSameAddressAsMain={this.setSameAddressAsMain}
                setPostCode={this.setPostCode}
                countriesList={countries.map(el => ({ value: el, label: el }))}
                statesList={states.map(el => ({ value: el, label: el }))}
                setCountry={this.setCountry}
                setAmericanState={this.setAmericanState}
                getPostCode={this.getPostCode}
                isPostCodeLoading={this.state.isPostCodeLoading}
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        countries: state.clientStore.countriesList,
        states: state.clientStore.statesList
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPlace: query => dispatch(getPlace(query))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AddressContainer))
