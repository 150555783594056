export default {
    useImperial: false,
    height: 0,
    width: 0,
    weight: 0,
    length: 0,
    conv_length: 0,
    conv_width: 0,
    conv_height: 0,
    conv_weight: 0,
    product: null,
    description: "",
    brand: null,
    warehouse: null,
    location: null,
    condition: null,
    status: null,
    boxes: [],
    conditionImages: [],
    addStockItemFromExistingProducts: true,
    quantity: 1,
    selectedCombinationAttributes: []
}
