import fetch from "helpers/fetch"
import { toApiDate } from "helpers/date"
import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"
import useQueryDetailsFactory from "modules/WmsModule/hooks/factories/useQueryDetailsFactory"
import useMutationFactory from "modules/WmsModule/hooks/factories/useMutationFactory"

const KEY = "stockItems"

const parseArrayToQueryString = (name, array) => (array.length > 0 ? `&${name}[]=` + array.join(`&${name}[]=`) : "")

const fetchStockItemList = ({
    sort_by = "product",
    sort_direction = "asc",
    page = 1,
    length = 20,
    query = "",
    warehouses = [],
    conditions = [],
    brands = [],
    statuses = [],
    transfers_count = "",
    order_uuid = "",
    invoice_uuid = "",
    products = "",
    combination_id = "",
    shipment_id = "",
    order_id = "",
    show_sold = true,
    show_dropshipped = false,
    show_not_for_sale = true,
    created_to,
    created_from,
    incoming = "",
    invoiced = false
}) =>
    fetch.get(
        `/stock-items?page=${page}` +
            `&length=${length}` +
            `&sort_by=${sort_by}` +
            `&sort_direction=${sort_direction}` +
            `&query=${query}` +
            `&created_to=${created_to ? toApiDate(created_to) : ""}` +
            `&created_from=${created_from ? toApiDate(created_from) : ""}` +
            `&transfers_count=${transfers_count}` +
            `&order_uuid=${order_uuid}` +
            `&invoice_uuid=${invoice_uuid}` +
            `&products=${products}` +
            `&combination_id=${combination_id}` +
            `&shipment_id=${shipment_id}` +
            `&order_id=${order_id}` +
            `&show_sold=${show_sold}` +
            `&show_dropshipped=${show_dropshipped}` +
            `&show_not_for_sale=${show_not_for_sale}` +
            `&incoming=${incoming}` +
            `&invoiced=${invoiced}` +
            parseArrayToQueryString("warehouses", warehouses) +
            parseArrayToQueryString("conditions", conditions) +
            parseArrayToQueryString("brands", brands) +
            parseArrayToQueryString("statuses", statuses)
    )
const fetchStockItemDetails = id => fetch.get(`/stock-items/${id}`)
const fetchStockItemCreate = ({ data }) => fetch.postRAW("/stock-items", data)
const fetchStockItemEdit = ({ id, data }) => fetch.patch(`/stock-items/${id}`, data)
const fetchStockItemDelete = ({ id, data }) => fetch.deleteRAW(`/stock-items/${id}`, data)
const fetchStockItemReview = ({ ids }) =>
    fetch
        .postRAW("/multi-actions", {
            ids,
            action: "review",
            model: "stock_items"
        })
        .then(data => data.json())
const fetchStockItemReviewUpdate = ({ spreadsheetId }) => fetch.patchRAW(`/reviews/${spreadsheetId}`)
const fetchStockItemChangeStatus = ({ data }) =>
    fetch.postRAW("/multi-actions", {
        ...data,
        action: "change_status",
        model: "stock_items"
    })
export const transferStockItems = ({ ids, warehouse_id }) =>
    fetch.postRAW("/multi-actions", {
        ids,
        action: "transfer",
        model: "stock_items",
        payload: {
            warehouse_id
        }
    })

export const returnStockItems = ({ ids, shipmentId, description }) =>
    fetch.postRAW("/multi-actions", {
        ids,
        action: "return",
        model: "stock_items",
        payload: {
            return_shipment_id: shipmentId,
            description
        }
    })

export const getStockItemsLabels = ({ ids }) =>
    new Promise((resolve, reject) => {
        fetch
            .postRAW("/multi-actions", {
                ids,
                action: "print",
                model: "stock_items"
            })
            .then(response => response.blob())
            .then(blob => resolve(blob))
            .catch(error => reject(error))
    })

export const returnStockItemsFromClient = ({
    ids,
    orderId,
    warehouseId,
    reasonId,
    description,
    refundMethod,
    refundRate
}) =>
    fetch.postRAW("/multi-actions", {
        ids,
        action: "client_return_shipment",
        model: "stock_items",
        payload: {
            order_id: orderId,
            warehouse_id: warehouseId,
            reason_id: reasonId,
            description,
            refund_method: refundMethod,
            refund_rate: refundRate
        }
    })

export const useStockItemList = useQueryListFactory({
    key: KEY,
    fetchFunction: fetchStockItemList
})
export const useStockItemDetails = useQueryDetailsFactory({ key: KEY, fetchFunction: fetchStockItemDetails })
export const useStockItemCreate = useMutationFactory({ key: KEY, fetchFunction: fetchStockItemCreate })
export const useStockItemEdit = useMutationFactory({ key: KEY, fetchFunction: fetchStockItemEdit })
export const useStockItemDelete = useMutationFactory({ key: KEY, fetchFunction: fetchStockItemDelete })
export const useStockItemReview = useMutationFactory({ key: KEY, fetchFunction: fetchStockItemReview })
export const useStockItemChangeStatus = useMutationFactory({
    key: KEY,
    fetchFunction: fetchStockItemChangeStatus
})
export const useStockItemReviewUpdate = useMutationFactory({
    key: KEY,
    fetchFunction: fetchStockItemReviewUpdate
})
export const useStockItemTransfer = useMutationFactory({
    key: KEY,
    fetchFunction: transferStockItems
})
export const useStockItemReturn = useMutationFactory({
    key: KEY,
    fetchFunction: returnStockItems
})

export const useStockItemClientReturn = useMutationFactory({
    key: KEY,
    fetchFunction: returnStockItemsFromClient
})
