import React from "react"
import { Link } from "react-router-dom"

import withStyles from "HOC/withStyles"
import Image from "../../../Image/Image"
import { Button } from "ui"

import styles from "./ProductListItem.css"

const ProductListItem = props => {
    const { index, cx, as: Component, item, isSelected, handleSelect, handleRemove } = props

    return (
        <Component className={cx("root", { selected: isSelected })}>
            <div className={cx("left-side")}>
                {renderCounter()}
                {renderImage()}
                {renderName()}
            </div>
            <div className={cx("right-side")}>{renderActions()}</div>
        </Component>
    )

    function renderCounter() {
        if (!isSelected) {
            return null
        }

        return <div className={cx("counter")}>{index + 1}</div>
    }

    function renderImage() {
        return (
            <div className={cx("image-container")}>
                <div className={cx("image")}>
                    <Image src={item.img} />
                </div>
            </div>
        )
    }

    function renderName() {
        return (
            <div className={cx("name-container", { withCounter: isSelected })}>
                <div className={cx("name")}>
                    <Link to={`/products/${item.id}`}>{item.name}</Link>
                </div>
                <div className={cx("brand")}>{item.brand}</div>
            </div>
        )
    }

    function renderActions() {
        if (!isSelected) {
            return (
                <div className={cx("actions")}>
                    <div className={cx("action-button-add-wrapper")}>
                        <Button
                            customStyles={{
                                root: styles["action-button-add"]
                            }}
                            label="Add"
                            onClick={() => handleSelect(item)}
                        />
                    </div>
                </div>
            )
        }

        return (
            <div className={cx("actions")}>
                <span className={cx("delete")} onClick={() => handleRemove(item, index)}>
                    Delete
                </span>
            </div>
        )
    }
}

ProductListItem.defaultProps = {
    as: "div"
}

export default withStyles(ProductListItem, styles)
