import React, { useCallback, useRef, Fragment, useState } from "react"
import Webcam from "react-webcam"
import PropTypes from "prop-types"

import { Button, Loader } from "@butterfly-frontend/ui"
import { toFilenameDateTime } from "helpers/date"
import base64toBlob from "helpers/base64toBlob"
import { useActions } from "hooks"
import { showErrorNotification } from "actions/notification"

import styles from "./CameraUploader.module.css"

const MIME_TYPE = "image/png"
const WEBCAM_FALLBACK_CONSTRAINTS = { facingMode: "user" }
const WEBCAM_CONSTRAINTS = { facingMode: { exact: "environment" } }

const CameraUploader = ({ onClose, onPhotoTaken }) => {
    const cameraRef = useRef(null)
    const [isCameraLoading, setIsCameraLoading] = useState(true)
    const [cameraConstraints, setCameraConstraints] = useState(WEBCAM_CONSTRAINTS)
    const actions = useActions({ showErrorNotification })

    const getPhoto = useCallback(() => {
        try {
            const base64Data = cameraRef.current.getScreenshot()
            const blob = base64toBlob(base64Data.split(",")[1], MIME_TYPE)
            const dateTimeString = toFilenameDateTime(new Date())
            const file = new File([blob], `${dateTimeString}.${MIME_TYPE.split("/")[1]}`)
            return file
        } catch (e) {
            actions.showErrorNotification({
                title: "An error occurred while taking a photo. Try uploading files manually."
            })
            onClose()
        }
    }, [cameraRef, actions, onClose])

    const onUserMediaError = useCallback(() => {
        if (cameraConstraints.facingMode !== "user") {
            setCameraConstraints(WEBCAM_FALLBACK_CONSTRAINTS)
        } else {
            actions.showErrorNotification({ title: "An error occurred while setting up the camera" })
            onClose()
        }
    }, [cameraConstraints, actions, onClose])

    const handleCapturePhoto = useCallback(() => {
        const file = getPhoto()
        onClose()
        onPhotoTaken([file])
    }, [getPhoto, onClose, onPhotoTaken])

    return (
        <Fragment>
            {isCameraLoading && <Loader classes={styles.loader} />}
            <Webcam
                ref={cameraRef}
                audio={false}
                videoConstraints={cameraConstraints}
                screenshotFormat={MIME_TYPE}
                onUserMedia={() => setIsCameraLoading(false)}
                onUserMediaError={onUserMediaError}
            />
            <div className={styles.buttonsContainer}>
                <Button onClick={onClose} type="flat">
                    Cancel
                </Button>
                <Button
                    classes={{ button: styles.submitButton }}
                    onClick={handleCapturePhoto}
                    type="submit"
                    color="blue"
                >
                    Capture Photo
                </Button>
            </div>
        </Fragment>
    )
}

CameraUploader.propTypes = {
    onPhotoTaken: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default CameraUploader
