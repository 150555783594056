import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getPermissionStart() {
    return {
        type: constants.GET_PERMISSION_START
    }
}
export function getPermissionSuccess(data) {
    return {
        type: constants.GET_PERMISSION_SUCCESS,
        payload: {
            data
        }
    }
}
export function getPermissionError(error) {
    return {
        type: constants.GET_PERMISSION_ERROR,
        payload: {
            error
        }
    }
}
export function getPermission() {
    return dispatch => {
        dispatch(getPermissionStart())

        fetch
            .get(`/permission?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getPermissionSuccess(data)))
            .catch(error => {
                dispatch(getPermissionError(error))
            })
    }
}

export function getRolesStart() {
    return {
        type: constants.GET_ROLES_START
    }
}
export function getRolesSuccess(data) {
    return {
        type: constants.GET_ROLES_SUCCESS,
        payload: {
            data
        }
    }
}
export function getRolesError(error) {
    return {
        type: constants.GET_ROLES_ERROR,
        payload: {
            error
        }
    }
}
export function getRoles() {
    return dispatch => {
        dispatch(getRolesStart())
        dispatch(getRoleStart())

        fetch
            .get(`/permissionGroup?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getRolesSuccess(data)))
            .catch(error => {
                dispatch(getRolesError(error))
            })
    }
}

function getRoleStart() {
    return {
        type: constants.GET_ROLE_START
    }
}
function getRoleSuccess(data) {
    return {
        type: constants.GET_ROLE_SUCCESS,
        payload: {
            data
        }
    }
}
function getRoleError(error) {
    return {
        type: constants.GET_ROLE_ERROR,
        payload: {
            error
        }
    }
}
export function getRole(id) {
    return dispatch => {
        dispatch(getRoleStart())

        fetch
            .get(`/permissionGroup/${id}`)
            .then(data => dispatch(getRoleSuccess(data)))
            .catch(error => {
                dispatch(getRoleError(error))
            })
    }
}

export function deleteRoleStart() {
    return {
        type: constants.DELETE_ROLE_START
    }
}

export function deleteRoleError(error) {
    return {
        type: constants.DELETE_ROLE_ERROR,
        payload: {
            error
        }
    }
}
export function deleteRole(permissionGroupId) {
    return dispatch => {
        dispatch(deleteRoleStart())

        fetch
            .delete(`/permissionGroup/${permissionGroupId}`)
            .then(data => dispatch(getRoles()))
            .catch(error => {
                dispatch(deleteRoleError(error))
            })
    }
}
