import * as constants from "constants/index"

function currentlySelectedImages(state = [], action) {
    switch (action.type) {
        case constants.SELECT_IMAGES:
            if (action.payload.length === 1) {
                const isImageAlreadySelected = state.find(el => el.id === action.payload[0].id)

                if (isImageAlreadySelected) {
                    return state.filter(el => el.id !== action.payload[0].id)
                }

                return [...state, ...action.payload]
            }
            return action.payload
        default:
            return state
    }
}

export default currentlySelectedImages
