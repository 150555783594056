import React, { useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"
import { Pagination, Dropdown } from "ui"
import FilterableTable, { Content, Sort, SearchBar, Header, Table } from "ui/FilterableTable"

import styles from "./styles.css"
import filterableTableStyles from "./overrides/FilterableTable.css"
import tableHeaderStyles from "./overrides/Header.css"
import dropdownStyles from "./overrides/Dropdown.css"
import paginationStyles from "./overrides/Pagination.css"

import notFoundIcon from "assets/ico-page-not-find-grey.svg"
import imageIcon from "assets/ico-image.svg"

const EditableList = ({
    confirmModalTitle,
    cx,
    deleteElement,
    editUrlPrefix,
    brands,
    handleChangeTable,
    changeCustomVendor,
    customVendor,
    changePage,
    page,
    isLoading
}) => {
    const [showModal, setShowModal] = useState(false)
    const [currentDeleteId, setCurrentDeleteId] = useState(null)

    const handleToggleModal = id => {
        setShowModal(!showModal)
        setCurrentDeleteId(id)
    }

    const handleDelete = () => {
        deleteElement(currentDeleteId)
        setShowModal(false)
    }

    const renderTableHeader = () => {
        return (
            <Table.Head>
                <Table.Tr>
                    <Table.Th>LOGO</Table.Th>
                    <Table.Th>
                        <Sort sortBy="name">BRAND NAME</Sort>
                    </Table.Th>
                    {!customVendor && (
                        <Table.Th>
                            <Sort sortBy="products_count">PRODUCTS</Sort>
                        </Table.Th>
                    )}
                    <Table.Th />
                </Table.Tr>
            </Table.Head>
        )
    }

    const renderItem = ({ id, name, products_in_brand, logo, isNoEdit, isNoDelete, custom_vendor }) => {
        const isDeleteEnabled = products_in_brand === 0

        return (
            <Table.Tr key={id}>
                <Table.Td>
                    <div className={cx("logo", { noImage: !logo })}>
                        <img src={logo || imageIcon} alt="img" className={cx("image")} />
                    </div>
                </Table.Td>
                <Table.Td>
                    <Link to={`/settings/brands/${id}`}>
                        <p className={cx("table-id")}>{name}</p>
                    </Link>
                </Table.Td>
                {!customVendor && (
                    <Table.Td>
                        <p>{products_in_brand}</p>
                    </Table.Td>
                )}
                <Table.Td>
                    {!isNoEdit && (
                        <Link to={`${editUrlPrefix}/${id}/edit`}>
                            <div className={cx("edit-cover")}>Edit</div>
                        </Link>
                    )}

                    {!isNoDelete && (
                        <div
                            className={cx("delete-cover", { isDeleteEnabled })}
                            onClick={() => isDeleteEnabled && handleToggleModal(id)}
                        >
                            Delete
                        </div>
                    )}
                </Table.Td>
            </Table.Tr>
        )
    }

    const renderTableBody = items => <Table.Body>{items.map(item => renderItem(item))}</Table.Body>

    return (
        <div className={cx("root")}>
            <FilterableTable
                fetchStatus={{ isLoading, isLoaded: !isLoading }}
                items={brands.data}
                meta={brands.meta}
                handleChange={handleChangeTable}
                customStyles={filterableTableStyles}
            >
                <Header customStyles={tableHeaderStyles}>
                    <SearchBar placeholder="Search for brand..." />
                    <Dropdown
                        label={customVendor ? "Custom brand" : "Brands in catalog"}
                        options={[
                            { label: "Brands in catalog", onClick: () => changeCustomVendor(false) },
                            { label: "Custom brand", onClick: () => changeCustomVendor(true) }
                        ]}
                        customStyles={dropdownStyles}
                    />
                </Header>
                <Content className={cx("content")}>
                    <Table
                        className={cx("table")}
                        renderHeader={renderTableHeader}
                        renderBody={renderTableBody}
                        customEmptyMessage={
                            <div className={cx("emptyList")}>
                                <img src={notFoundIcon} alt="ico" />
                                <span>{customVendor ? "No custom brands created" : "No brands"}</span>
                            </div>
                        }
                    />
                </Content>
            </FilterableTable>
            <Pagination
                customStyles={paginationStyles}
                currentPage={page}
                pages={(brands.meta || {}).last_page || 1}
                isLoaded={(brands.fetchStatus || {}).isLoaded}
                onChange={changePage}
            />

            {showModal && (
                <Modal>
                    <Confirm
                        confirmModalTitle={confirmModalTitle}
                        handleHideModal={() => handleToggleModal()}
                        handleDelete={handleDelete}
                    />
                </Modal>
            )}
        </div>
    )
}

EditableList.propTypes = {
    cx: PropTypes.func.isRequired,
    deleteElement: PropTypes.func.isRequired,
    editUrlPrefix: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    brands: PropTypes.object.isRequired,
    changeCustomVendor: PropTypes.func.isRequired,
    customVendor: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired
}

export default withStyles(EditableList, styles)
