import React, { Fragment } from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import withMultipleUploader from "HOC/withMultipleUploader"

import { Modal, Dropzone, Button } from "ui"
import ShowProductLink from "../ShowProductLink"
import DragAndDropList from "../DragAndDropList/DragAndDropList"
import { createHintText } from "modules/ContentModule/helpers"

import cloudIcon from "./assets/cloud.svg"
import closeIcon from "assets/close-blue.svg"

import styles from "./styles.css"
import dropzoneStyles from "./overrides/dropzoneStyles.css"

const IMAGE_DIMENSIONS = {
    width: 2560,
    height: 1800
}

const SectionModalGallery = ({
    onClose,
    cx,
    uploader: { state: uploadState, actions: uploadActions },
    images,
    form,
    handleRemove,
    handleReorder,
    isSubmitting
}) => {
    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Gallery</p>

            <div
                className={cx("dropzoneWrapper", {
                    dropzoneWrapperError: (form.errors.gallery || []).length > 0
                })}
            >
                <Dropzone
                    isUploading={uploadState.isUploading}
                    isSuccess={uploadState.isSuccess}
                    progress={uploadState.progress}
                    handleUpload={uploadActions.handleUpload}
                    handleRemove={uploadActions.handleRemove}
                    isMultiple={true}
                    customStyles={dropzoneStyles}
                >
                    <p
                        className={cx("dropzoneStateEmpty", {
                            big: images.length === 0 && !uploadState.isUploading
                        })}
                    >
                        <img src={cloudIcon} alt="cloud" />
                        Add images <span>{createHintText(IMAGE_DIMENSIONS)}</span>
                    </p>
                </Dropzone>
            </div>

            {uploadState.isUploading && (
                <div className={cx("uploadingDetails")}>
                    {(uploadState.addedFile || []).map((file, index) => (
                        <div key={`${index}-${file.title}`}>
                            <span className={cx("filename")}>{file.title}</span>

                            <div className={cx("progressBar")}>
                                <div style={{ width: `${file.progress}%` }} />
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {images.length > 0 && (
                <Fragment>
                    <div className={cx("line")} />

                    <p className={cx("header-small")}>{images.length} images added</p>

                    <div className={cx("list")}>
                        <DragAndDropList itemsList={images} handleReorder={handleReorder}>
                            {images.map((image, index) => (
                                <div key={image.id} className={cx("imageLine")}>
                                    <div className={cx("imageWrapper")}>
                                        {image.url && <img src={image.url} alt="preview" />}
                                    </div>
                                    <span>{image.name}</span>
                                    <button onClick={() => handleRemove(index)}>
                                        <img src={closeIcon} alt="close" />
                                    </button>
                                </div>
                            ))}
                        </DragAndDropList>
                    </div>
                </Fragment>
            )}

            <div className={cx("showLinkWrapper")}>
                <ShowProductLink />
            </div>

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    label="Save"
                    onClick={form.submitForm}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

SectionModalGallery.propTypes = {
    uploader: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    images: PropTypes.array.isRequired,
    form: PropTypes.object.isRequired,
    handleUploadSuccess: PropTypes.func.isRequired,
    handleRemove: PropTypes.func.isRequired,
    handleReorder: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    cx: PropTypes.func.isRequired
}

export default withMultipleUploader(withStyles(SectionModalGallery, styles))
