import React from "react"

import Button from "ui/Button"

const CreateSalesOrderButton = props => {
    const { className, onClick, isLoading, isDisabled } = props
    return (
        <Button
            className={`first-button ${className}`}
            label="Add to SO"
            onClick={onClick}
            isLoading={isLoading}
            isDisabled={isDisabled}
        />
    )
}

export default CreateSalesOrderButton
