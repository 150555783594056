import React from "react"
import PropTypes from "prop-types"
import { Panel } from "@butterfly-frontend/ui"

import { StockItemDetailsBoxList } from "modules/WmsModule/components"

import styles from "./StockAdjustmentDetailsBoxList.module.css"

const StockAdjustmentDetailsBoxList = ({ boxes }) => (
    <div className={styles.container}>
        <Panel>
            <div className={styles.listContainer}>
                <StockItemDetailsBoxList boxes={boxes} />
            </div>
        </Panel>
    </div>
)

StockAdjustmentDetailsBoxList.propTypes = {
    boxes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            label: PropTypes.string.isRequired,
            location: PropTypes.shape({
                name: PropTypes.string.isRequired
            })
        }).isRequired
    ).isRequired
}

export default StockAdjustmentDetailsBoxList
