import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import List from "./List"
import Modal from "./components/modal"
import DeleteModal from "./components/deleteModal"
import useDebounce from "hooks/useDebounce"
import { SORT_OPTIONS } from "./configs"

import ListingLayout from "components/Content/ContentMain/Settings/ListingLayout"

import * as locationActions from "../../actions"
import withStyles from "HOC/withStyles"
import styles from "./styles/list.css"
import { showErrorNotification, showSuccessNotification } from "actions/notification"

const ListContainer = ({ cx }) => {
    const dispatch = useDispatch()
    const locations = useSelector(state => state.db.locations.list)
    const [currentLocation, setCurrentLocation] = useState(null)

    const [createModalIsOpen, setCreateModalIsOpen] = useState(false)
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [isModalFetching, setIsModalFetching] = useState(false)
    const [deleteModalIsOpen, setdDeleteModalIsOpen] = useState(false)
    const [filterSortingIndex, setFilterSortingIndex] = useState(0)
    const [deleteModalParams, setDeleteModalParams] = useState({ id: -1, name: "" })
    const [error, setError] = useState(null)
    const [page, changePage] = useState(1)

    useEffect(() => {
        fetchLocations()
    }, [])

    useDebounce(
        () => {
            fetchLocations()
        },
        400,
        [filterSortingIndex, page]
    )

    return (
        <ListingLayout>
            <List
                locations={locations}
                openEditModal={openEditModal}
                filterSortingIndex={filterSortingIndex}
                filterSortingHandleChange={filterSortingHandleChange}
                setCreateModalIsOpen={setCreateModalIsOpen}
                openDeleteModal={openDeleteModal}
                changePage={changePage}
                cx={cx}
            />
            {renderCreateModal()}
            {renderEditModal()}
            {renderDeleteModal()}
        </ListingLayout>
    )

    function openDeleteModal(name, id) {
        setDeleteModalParams({ id, name })
        setdDeleteModalIsOpen(true)
    }

    function openEditModal(id) {
        setCurrentLocation(locations.data.find(location => location.id === id))

        setEditModalIsOpen(true)
    }

    function closeEditModal() {
        setError(null)
        setEditModalIsOpen(false)
    }

    function closeCreateModal() {
        setError(null)
        setCreateModalIsOpen(false)
    }

    function getCurrentParams() {
        return {
            page: page,
            ...SORT_OPTIONS[filterSortingIndex].forApi
        }
    }

    function filterSortingHandleChange(index) {
        setFilterSortingIndex(index || 0)
    }

    function fetchLocations() {
        return dispatch(locationActions.getLocations(getCurrentParams()))
    }

    function deleteLocation(id) {
        setIsModalFetching(true)
        return locationActions
            .deleteLocationById(id)
            .then(() => {
                dispatch(showSuccessNotification())
            })
            .catch(() => {
                dispatch(showErrorNotification())
            })
            .finally(() => {
                setIsModalFetching(false)
                setdDeleteModalIsOpen(false)
                fetchLocations()
            })
    }

    function createNewLocation(body) {
        setIsModalFetching(true)
        return locationActions
            .createLocation(body)
            .then(() => {
                dispatch(showSuccessNotification())
                setCreateModalIsOpen(false)
                fetchLocations()
            })
            .catch(err => {
                setError(err.errors)
            })
            .finally(() => {
                setIsModalFetching(false)
            })
    }

    function editLocation(id, body) {
        setIsModalFetching(true)
        return locationActions
            .editLocation(id, body)
            .then(() => {
                dispatch(showSuccessNotification())
                setEditModalIsOpen(false)
                fetchLocations()
            })
            .catch(err => {
                setError(err.errors)
            })
            .finally(() => setIsModalFetching(false))
    }

    function renderCreateModal() {
        return (
            <Modal
                title={"New location"}
                saveButtonLabel={"Create"}
                submit={data => createNewLocation(data)}
                location={{ name: "", description: "", price: "" }}
                isFetching={isModalFetching}
                isOpen={createModalIsOpen}
                error={error}
                setError={setError}
                handleClose={closeCreateModal}
            ></Modal>
        )
    }

    function renderEditModal() {
        return (
            <Modal
                title={"Edit location"}
                saveButtonLabel={"Save"}
                submit={data => editLocation(data.id, data)}
                isFetching={isModalFetching}
                isLoading={false}
                isOpen={editModalIsOpen}
                location={currentLocation}
                error={error}
                setError={setError}
                handleClose={closeEditModal}
            ></Modal>
        )
    }

    function renderDeleteModal() {
        return (
            <DeleteModal
                isOpen={deleteModalIsOpen}
                locationName={deleteModalParams.name}
                isLoading={isModalFetching}
                onCancel={() => setdDeleteModalIsOpen(false)}
                onSubmit={() => deleteLocation(deleteModalParams.id)}
            ></DeleteModal>
        )
    }
}

export default withStyles(ListContainer, styles)
