import React from "react"

import withStyles from "HOC/withStyles"
import styles from "./ListHeader.css"

import { columns } from "../constants"

const ListHeader = props => {
    const { cx } = props

    return (
        <thead className={cx("root")}>
            <tr>
                {Object.entries(columns).map(([key, { label }]) => (
                    <th key={key} className={cx("headerItem", key)}>
                        {label}
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default withStyles(ListHeader, styles)
