import React, { useState, useMemo } from "react"

import { useDispatch } from "react-redux"

import ReactSvg from "react-svg"
import { Note, AddEditModal, DeleteModal } from "ui/Notes"

import fetch from "helpers/fetch"
import { getProductById } from "actions/product"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import withStyles from "HOC/withStyles"
import styles from "./notes.css"

import commentIcon from "assets/comment.svg"
import documentIcon from "assets/document.svg"

const Notes = props => {
    const { cx, product, productId } = props
    const { notes } = product

    const dispatch = useDispatch()

    const addEditDefaultData = { isOpen: false, noteId: null }

    const [addEditModalData, setAddEditModalData] = useState(addEditDefaultData)
    const [isDeleteModalOpenFor, setIsDeleteModalOpenFor] = useState(null)

    const [isAddingEditing, setIsAddingEditing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const openAddEditModal = noteId => setAddEditModalData({ isOpen: true, noteId })
    const closeAddEditModal = () => setAddEditModalData(addEditDefaultData)
    const closeDeleteModal = () => setIsDeleteModalOpenFor(null)

    const catchCallback = () => dispatch(showErrorNotification())

    const submitAddOrEdit = data => {
        const noteId = addEditModalData.noteId
        const isEdit = !!noteId

        const [method, url] = isEdit ? ["patch", `/${noteId}`] : ["post", ""]

        const body = {
            ...data,
            attachmentsToDelete: null,
            only_attachment: false,
            object_type: "product",
            object_id: productId
        }

        setIsAddingEditing(true)

        const finallyCallback = () => {
            setIsAddingEditing(false)
            closeAddEditModal()
        }

        fetch[method](`/notes${url}`, body)
            .then(() => {
                dispatch(getProductById(productId))
                dispatch(showSuccessNotification())
            })
            .catch(catchCallback)
            .finally(finallyCallback)
    }

    const submitDelete = () => {
        setIsDeleting(true)
        fetch
            .deleteRAW(`/notes/${isDeleteModalOpenFor}`)
            .then(() => {
                dispatch(getProductById(productId))
                dispatch(showSuccessNotification())
            })
            .catch(catchCallback)
            .finally(() => {
                setIsDeleting(false)
                closeDeleteModal()
            })
    }

    const editedNote = useMemo(() => notes.find(({ id }) => id === addEditModalData.noteId), [
        notes,
        addEditModalData.noteId
    ])

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <h2>
                    Notes <span>(Not visible to client)</span>
                </h2>
                <button className={cx("leaveNote")} onClick={() => openAddEditModal()}>
                    <ReactSvg src={commentIcon} />
                    Leave a note
                </button>
            </div>
            <ul>
                {Array.isArray(notes) && !!notes.length ? (
                    notes.map(note => (
                        <Note
                            key={note.id}
                            {...note}
                            editNote={openAddEditModal}
                            deleteNote={setIsDeleteModalOpenFor}
                        />
                    ))
                ) : (
                    <div className={cx("emptyList")}>
                        <ReactSvg src={documentIcon} />
                        No note leaved
                    </div>
                )}
            </ul>
            <AddEditModal
                hasAttachments
                isOpen={addEditModalData.isOpen}
                isEdit={addEditModalData.noteId}
                isLoading={isAddingEditing}
                {...editedNote}
                onClose={closeAddEditModal}
                onSubmit={submitAddOrEdit}
            />
            <DeleteModal
                isOpen={!!isDeleteModalOpenFor}
                isLoading={isDeleting}
                onClose={closeDeleteModal}
                onSubmit={submitDelete}
            />
        </div>
    )
}

export default withStyles(Notes, styles)
