import React from "react"
import PropTypes from "prop-types"

import SendableModal from "ui/SendableModal"

import fetch from "helpers/fetch"
import { useActions, useFetch } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

const Sendable = props => {
    const { isPrinting, id, combinationId, printType, handleClose, handlePrint } = props

    const actions = useActions({ showSuccessNotification, showErrorNotification })

    const [handleSubmit, { isLoading: isSubmitting }] = useFetch({
        action: data => {
            const { subject, message, receivers } = data
            const emails = receivers.map(item => item.email)

            const body = { payload: { emails, message, subject, print_type: printType, combination_id: combinationId } }

            return fetch.postRAW(`/products/${id}/send`, body)
        },
        onSuccess: () => {
            actions.showSuccessNotification({ title: "Sent" })
            handleClose()
        },
        onError: (data, _, { setErrors }) => {
            data && data.errors && setErrors(data.errors)
            actions.showErrorNotification(data.message)
        }
    })

    const print = () => handlePrint(printType)

    return (
        <SendableModal
            isSubmitting={isSubmitting}
            handleClose={handleClose}
            isPrinting={isPrinting}
            id={id}
            uuid={id}
            printType={printType}
            handleSubmit={handleSubmit}
            handlePrint={print}
        />
    )
}

Sendable.propTypes = {
    isPrinting: PropTypes.bool,
    id: PropTypes.number.isRequired,
    combinationId: PropTypes.number,
    printType: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handlePrint: PropTypes.func.isRequired
}

export default Sendable
