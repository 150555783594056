import React, { useState } from "react"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"

import { Pagination } from "ui"
import FilterableTable, { Content, Sort, SearchBar, Header, Table } from "ui/FilterableTable"
import ChangeModal from "./components/ChangeModal"

import styles from "./List.css"

import notFoundIcon from "assets/ico-page-not-find-grey.svg"
import settingsIcon from "assets/ico-settings-white.svg"

const EditableList = ({ brands, page, isLoading, changePage, handleChangeTable, handlePriceVisibilityChange }) => {
    const cx = useStyles(styles)
    const [changingBrand, setChangingBrand] = useState(null)

    const renderTableHeader = () => {
        return (
            <Table.Head>
                <Table.Tr>
                    <Table.Th>
                        <Sort sortBy="name">BRAND NAME</Sort>
                    </Table.Th>
                    <Table.Th>PRICE DISPLAY</Table.Th>
                    <Table.Th />
                </Table.Tr>
            </Table.Head>
        )
    }

    const renderItem = brand => {
        const isPriceDisplayOff = brand.options.length && brand.options[0].value === "none"
        const priceDisplayLabel =
            !brand.options.length || brand.options[0].value === "b2b"
                ? "B2B Partners"
                : brand.options[0].value === "all"
                ? "All Contacts"
                : null

        return (
            <Table.Tr key={brand.id}>
                <Table.Td>{brand.name}</Table.Td>
                <Table.Td>
                    <span className={cx("priceDisplay")}>
                        {!isPriceDisplayOff ? (
                            <span className={cx("label", { on: true })}>ON</span>
                        ) : (
                            <span className={cx("label", { on: false })}>OFF</span>
                        )}
                        {priceDisplayLabel}
                    </span>
                </Table.Td>
                <Table.Td>
                    <button className={cx("changeButton")} onClick={() => setChangingBrand(brand)}>
                        <img src={settingsIcon} alt="ico" /> Change
                    </button>
                </Table.Td>
            </Table.Tr>
        )
    }

    const renderTableBody = items => <Table.Body>{items.map(item => renderItem(item))}</Table.Body>

    return (
        <div className={cx("root")}>
            <FilterableTable
                fetchStatus={{ isLoading, isLoaded: !isLoading }}
                items={brands.data}
                meta={brands.meta}
                handleChange={handleChangeTable}
            >
                <Header>
                    <SearchBar placeholder="Search for brand..." />
                </Header>
                <Content className={cx("content")}>
                    <Table
                        className={cx("table")}
                        renderHeader={renderTableHeader}
                        renderBody={renderTableBody}
                        customEmptyMessage={
                            <div className={cx("emptyList")}>
                                <img src={notFoundIcon} alt="ico" />
                                <span>No brands</span>
                            </div>
                        }
                    />
                </Content>
            </FilterableTable>
            <Pagination
                currentPage={page}
                pages={(brands.meta || {}).last_page || 1}
                isLoaded={(brands.fetchStatus || {}).isLoaded}
                onChange={changePage}
            />

            {!!changingBrand && (
                <ChangeModal
                    brand={changingBrand}
                    onClose={() => setChangingBrand(null)}
                    onPriceShowingChange={handlePriceVisibilityChange}
                />
            )}
        </div>
    )
}

EditableList.propTypes = {
    isLoading: PropTypes.bool,
    brands: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired,
    handleChangeTable: PropTypes.func.isRequired,
    handlePriceVisibilityChange: PropTypes.func.isRequired
}

export default EditableList
