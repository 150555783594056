import React, { Component } from "react"
import { connect } from "react-redux"
import * as userActions from "actions/user"
import UsersList from "../UsersList/UsersList"

class UsersListCover extends Component {
    constructor(props) {
        super(props)
        this.deleteThisUser = this.deleteThisUser.bind(this)
    }

    componentDidMount() {
        this.props.getUsers()
    }

    deleteThisUser(id) {
        this.props.deleteUser(id)
    }

    render() {
        const { users, isLoaded } = this.props
        return <UsersList users={users} isLoaded={isLoaded} deleteThisUser={this.deleteThisUser} />
    }
}

const mapStateToProps = state => {
    return {
        users: state.userStore.users,
        isLoaded: state.userStore.isLoaded
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => dispatch(userActions.getUsers()),
        deleteUser: id => dispatch(userActions.deleteUser(id))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersListCover)
