import React, { Fragment } from "react"

import Button from "ui/Button"

import withStyles from "HOC/withStyles"
import styles from "./SelectedActions.css"

import closeIcon from "assets/close-blue.svg"

const SelectedActions = props => {
    const { cx, checkedCount, handleAction, selectedActions, canDelete } = props

    const onDelete = () => handleAction("delete", "multiselect")

    return (
        <div className={cx("root")}>
            {!!checkedCount && (
                <Button
                    isDisabled={!canDelete}
                    className={cx("deleteButton")}
                    icon={closeIcon}
                    label="Delete"
                    onClick={onDelete}
                />
            )}
            {selectedActions.map((renderButton, index) => (
                <Fragment key={index}>{renderButton(props)}</Fragment>
            ))}
        </div>
    )
}

export default withStyles(SelectedActions, styles)
