import { INPUT_TYPES } from "constants/index"

export default {
    name: "Feature",
    endpoint: "/featureValue",
    fields: [
        {
            name: "value",
            label: "Value",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        }
    ]
}
