import React, { Fragment } from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./styles.css"

import personIcon from "./assets/person.svg"
import disabledPersonIcon from "./assets/person-disabled.svg"
import mailIcon from "./assets/mail.svg"
import disabledMailIcon from "./assets/mail-disabled.svg"
import phoneIcon from "./assets/phone.svg"
import disabledPhoneIcon from "./assets/phone-disabled.svg"

const GeneralInfo = props => {
    const { cx, brand } = props

    return (
        <Fragment>
            <div className={cx("root")}>
                <div className={cx("headerWrapper")}>
                    <h2 className={cx("header")}>Supplier Details</h2>
                </div>

                <ul className={cx("table")}>
                    <li>
                        <h3 className={cx("label")}>Supplier Address:</h3>

                        {!!brand.company_name && <p className={cx("value", "title")}>{brand.company_name}</p>}
                        {!!brand.address && <p className={cx("value")}>{brand.address}</p>}
                        {(!!brand.post_code || !!brand.city || !!brand.state) && (
                            <p className={cx("value")}>
                                {brand.city}, {brand.state} {brand.post_code}
                            </p>
                        )}
                        {!!brand.country && <p className={cx("value")}>{brand.country}</p>}
                    </li>

                    <li>
                        <h3 className={cx("label")}>Manufacturing time:</h3>
                        <p className={cx("value")}>
                            {!!brand.manufacturing_min && !!brand.manufacturing_max
                                ? `${brand.manufacturing_min} - ${brand.manufacturing_max} days`
                                : "-"}
                        </p>
                    </li>

                    <li>
                        <h3 className={cx("label")}>Delivery time:</h3>
                        <p className={cx("value")}>
                            {!!brand.delivery_min && !!brand.delivery_max
                                ? `${brand.delivery_min} - ${brand.delivery_max} days`
                                : "-"}
                        </p>
                    </li>
                </ul>

                <h2 className={cx("header-smaller")}>Contact</h2>

                <ul className={cx("boxes")}>
                    <li>
                        <h3 className={cx("label")}>Contact person:</h3>
                        {!!brand.contact_person ? (
                            <p className={cx("value")}>
                                <img src={personIcon} alt="icon" />
                                {brand.contact_person}
                            </p>
                        ) : (
                            <p className={cx("value", "value-disabled")}>
                                <img src={disabledPersonIcon} alt="icon" /> -
                            </p>
                        )}
                    </li>

                    <li>
                        <h3 className={cx("label")}>Phone number:</h3>
                        {!!brand.phone ? (
                            <p className={cx("value", "value-blue")}>
                                <img src={phoneIcon} alt="icon" />
                                {brand.phone}
                            </p>
                        ) : (
                            <p className={cx("value", "value-disabled")}>
                                <img src={disabledPhoneIcon} alt="icon" /> -
                            </p>
                        )}
                    </li>

                    <li>
                        <h3 className={cx("label")}>Email address for PO:</h3>
                        {Array.isArray(brand.po_email) && brand.po_email.length > 0 ? (
                            brand.po_email.map((email, index) => (
                                <p className={cx("value", "value-blue")} key={index}>
                                    <img src={mailIcon} alt="icon" />
                                    <a href={`mailto:${email}`}>{email}</a>
                                </p>
                            ))
                        ) : (
                            <p className={cx("value", "value-disabled")}>
                                <img src={disabledMailIcon} alt="icon" /> -
                            </p>
                        )}
                    </li>

                    <li>
                        <h3 className={cx("label")}>Accounting email:</h3>
                        {Array.isArray(brand.accounting_email) && brand.accounting_email.length > 0 ? (
                            brand.accounting_email.map((email, index) => (
                                <p className={cx("value", "value-blue")} key={index}>
                                    <img src={mailIcon} alt="icon" />
                                    <a href={`mailto:${email}`}>{email}</a>
                                </p>
                            ))
                        ) : (
                            <p className={cx("value", "value-disabled")}>
                                <img src={disabledMailIcon} alt="icon" /> -
                            </p>
                        )}
                    </li>
                </ul>
            </div>
        </Fragment>
    )
}

GeneralInfo.propTypes = {
    brand: PropTypes.object.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(GeneralInfo, styles)
