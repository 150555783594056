import React, { useState, useEffect, useRef } from "react"
import ReactSvg from "react-svg"
import { useDispatch, useSelector } from "react-redux"

import { Toggle } from "ui"

import fetch from "helpers/fetch"
import { getUsersList } from "actions/users"
import { showErrorNotification } from "actions/notification"

import CalendarEntryDetails from "modules/CalendarModule/components/CalendarEntryDetails/CalendarEntryDetails"
import EventList from "modules/CalendarModule/components/EventList"
import CreateNewEventCalendarModal from "components/Modal/Calendar/AddEditEventModal"
import EditEventCalendarModala from "components/Modal/Calendar/AddEditEventModal"
import DeleteEventCalendarModal from "components/Modal/Calendar/DeleteEventModal"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import useStyles from "helpers/getCxFromStyles"
import styles from "./ClientMeetings.css"
import toggleStyles from "./overrides/Toggle.css"

import addIcon from "assets/add.svg"
import clockIcon from "assets/ico-deadline.svg"

const ClientMeetings = ({ clientId }) => {
    const cx = useStyles(styles)
    const eventDetailsRef = useRef()

    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [sort, setSort] = useState({ direction: "desc", by: "starts_at" })

    const dispatch = useDispatch()
    const userList = useSelector(state => state.db.users)
    const currentUserId = useSelector(state => state.loginStore.userId)
    const isSuperAdmin = useSelector(state => state.loginStore.roles.find(role => role.name === "SuperAdmin"))

    const [meetings, setMeetings] = useState([])
    const [currentFocusEvent, setCurrentFocusEvent] = useState(null)

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    const [isOnlyMyMeetings, setIsOnlyMyMeetings] = useState(!isSuperAdmin)
    const [isOpenAddMeetingModal, setIsOpenAddMeetingModal] = useState(false)
    const [isOpenEditMeetingModal, setIsOpenEditMeetingModal] = useState(false)
    const [isOpenDeleteMeetingModal, setIsOpenDeleteMeetingModal] = useState(false)
    const [isShownDetailsEvent, setIsShownDetailsEvent] = useState(false)

    useEffect(() => {
        fetchMeetings(1)
    }, [sort, isOnlyMyMeetings])

    const fetchMeetings = page => {
        const isMoreLoading = page > 1
        isMoreLoading ? setIsLoadingMore(true) : setIsLoading(true)

        fetch
            .get(
                `/calendars-entries?full=true&meetings&page=${page}&length=10&client_id=${clientId}&sort_by=${
                    sort.by
                }&sort_direction=${sort.direction}&created_by=${isOnlyMyMeetings ? currentUserId : ""}`
            )
            .then(({ data, meta }) => {
                isMoreLoading
                    ? setMeetings([...meetings, ...parseClientMeetingsData(data)])
                    : setMeetings(parseClientMeetingsData(data))
                isMoreLoading ? setIsLoadingMore(false) : setIsLoading(false)
                setPages(meta.last_page)
            })
            .catch(() => {
                dispatch(showErrorNotification())
            })
            .finally(() => {
                setIsLoadingMore(false)
                setIsLoading(false)
            })
    }

    const fetchAddMeeting = payload => fetch.post(`/calendars-entries?meetings`, payload)
    const fetchEditMeeting = (eventId, payload) => fetch.patch(`/calendars-entries/${eventId}?meetings`, payload)
    const fetchDeleteMeeting = eventId => fetch.delete(`/calendars-entries/${eventId}?meetings`)
    const fetchUserList = () => dispatch(getUsersList())

    const toggleShowDetails = (meeting, event) => {
        setCurrentFocusEvent({
            event: { extendedProps: { entry: { ...meeting, title_html: meeting.raw_title_html } } },
            jsEvent: event
        })
        setIsShownDetailsEvent(true)
    }

    const handleHideModal = result => {
        setIsOpenAddMeetingModal(false)
        setIsOpenEditMeetingModal(false)
        setIsOpenDeleteMeetingModal(false)
        setCurrentFocusEvent(null)

        if (result && result.success) {
            setPage(1)
            fetchMeetings(1)
        }
    }

    const handleEditAction = event => {
        setCurrentFocusEvent({ event: { extendedProps: { entry: event } } })
        setIsShownDetailsEvent(false)
        setIsOpenEditMeetingModal(true)
    }

    const handleDeleteAction = () => {
        setIsOpenDeleteMeetingModal(true)
        setIsShownDetailsEvent(false)
    }

    const handleSelectPage = page => {
        fetchMeetings(page)
        setPage(page)
    }

    const parseClientMeetingsData = data =>
        data.map(meeting => ({
            ...meeting,
            title_html: meeting.description || "Meeting",
            raw_title_html: meeting.title_html
        }))

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <div className={cx("left")}>
                    <h2>Client meetings</h2>
                    <div className={cx("divider")} />
                    <Toggle
                        label={{
                            on: "Show only my meetings",
                            off: "Show only my meetings"
                        }}
                        labelPosition="left"
                        isOn={isOnlyMyMeetings}
                        handleChange={state => setIsOnlyMyMeetings(state)}
                        customStyles={toggleStyles}
                    />
                </div>
                <button className={cx("addMeeting")} onClick={() => setIsOpenAddMeetingModal(true)}>
                    <ReactSvg src={addIcon} /> Add a meeting
                </button>
            </div>
            {isLoading ? (
                <div className={cx("loaderWrapper")}>
                    <SnakeLoader />
                </div>
            ) : meetings.length > 0 ? (
                <EventList
                    page={page}
                    pages={pages}
                    sort={sort}
                    list={meetings}
                    handleSort={setSort}
                    isLoadingMore={isLoadingMore}
                    handleSelectPage={handleSelectPage}
                    toggleShowDetails={toggleShowDetails}
                    sortingOptions={[
                        { label: "DATE", key: "starts_at", isSortable: true },
                        { label: "EVENT NAME", key: "name", isSortable: false },
                        { label: "CREATED BY", key: "creator", isSortable: true }
                    ]}
                    PaginationComponent={() =>
                        page < pages && (
                            <button
                                type="button"
                                className={cx("pagination")}
                                onClick={() => !isLoadingMore && handleSelectPage(page + 1)}
                            >
                                Show more
                            </button>
                        )
                    }
                />
            ) : (
                <div className={cx("emptyList")}>
                    <ReactSvg src={clockIcon} /> No meeting added
                </div>
            )}
            {isShownDetailsEvent && (
                <div ref={eventDetailsRef}>
                    <CalendarEntryDetails
                        visible={isShownDetailsEvent}
                        data={currentFocusEvent}
                        handleEventClose={() => setIsShownDetailsEvent(false)}
                        handleDeleteAction={handleDeleteAction}
                        handleEditAction={handleEditAction}
                        withoutLink
                    />
                </div>
            )}
            {isOpenAddMeetingModal && (
                <CreateNewEventCalendarModal
                    handleHideModal={handleHideModal}
                    handleCreate={fetchAddMeeting}
                    showErrorNotification={showErrorNotification}
                    title="New meeting"
                    getClients={() => {}}
                    getUsersList={fetchUserList}
                    users={userList.list}
                    predefinedClient={{ id: clientId }}
                />
            )}
            {isOpenEditMeetingModal && (
                <EditEventCalendarModala
                    handleHideModal={handleHideModal}
                    handleEdit={fetchEditMeeting}
                    showErrorNotification={showErrorNotification}
                    title="Edit meeting"
                    getClients={() => {}}
                    getUsersList={fetchUserList}
                    users={userList.list}
                    predefinedClient={{ id: clientId }}
                    entryDetails={currentFocusEvent}
                />
            )}
            {isOpenDeleteMeetingModal && (
                <DeleteEventCalendarModal
                    handleHideModal={handleHideModal}
                    showErrorNotification={showErrorNotification}
                    title="Delete this meeting?"
                    entry={currentFocusEvent}
                    handleDelete={fetchDeleteMeeting}
                />
            )}
        </div>
    )
}

export default ClientMeetings
