import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { H2 } from "@butterfly-frontend/ui"
import { Input, Toggle } from "ui"
import { SelectProduct } from "ui/Filters"
import { FormSection, FormRow, FormColumn } from "modules/WmsModule/modals/components"
import getInputPropsFactory from "modules/WmsModule/helpers/getInputPropsFactory"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import { SelectBrand } from "ui/Filters"

import styles from "./AddNewItemBasicInfoForm.module.css"

const PRODUCT_FROM_BASE_TOGGLE_LABEL = "New product from base"

const AddNewItemBasicInfoForm = ({ formik }) => (
    <FormSection
        title={<H2 classes={{ h2: styles.sectionTitle }}>Basic info</H2>}
        inputs={
            <Toggle
                isOn={formik.values.addStockItemFromExistingProducts}
                handleChange={value => {
                    formik.setFieldValue("product", null)
                    formik.setFieldValue("addStockItemFromExistingProducts", value)
                }}
                labelPosition="left"
                label={{ on: PRODUCT_FROM_BASE_TOGGLE_LABEL, off: PRODUCT_FROM_BASE_TOGGLE_LABEL }}
                isDisabled={formik.isSubmitting}
                isSmall
            />
        }
        className={styles.basicInfoForm}
    >
        {({ inputClass }) => {
            const getInputProps = getInputPropsFactory(formik, inputClass)
            const getSelectProps = getSelectPropsFactory(formik, styles.selectError)

            const handleBrandSelect = async ({ target: { value } }) => {
                if (formik.values.addStockItemFromExistingProducts) {
                    formik.setFieldValue("product", null)
                    formik.setFieldTouched("product", false)
                }

                await formik.setFieldValue("brand", value)
                formik.setFieldTouched("brand")
            }

            return (
                <Fragment>
                    <FormRow>
                        <FormColumn className={styles.column}>
                            <SelectBrand
                                label="Brand"
                                name="brand"
                                value={formik.values.brand && formik.values.brand.label}
                                error={formik.touched.brand && formik.errors.brand}
                                handleSelect={handleBrandSelect}
                                multiselect={false}
                                withAddButton={!formik.values.addStockItemFromExistingProducts}
                                isDisabled={formik.isSubmitting}
                                isRequired
                            />
                        </FormColumn>
                        <FormColumn className={styles.column}>
                            {formik.values.addStockItemFromExistingProducts ? (
                                <SelectProduct
                                    {...getSelectProps("product", {
                                        isDisabled: !formik.values.brand || formik.isSubmitting
                                    })}
                                    params={{ brands: formik.values.brand ? [formik.values.brand.value] : undefined }}
                                    label="Product name"
                                    isRequired
                                />
                            ) : (
                                <Input
                                    {...getInputProps("product")}
                                    label="Product name"
                                    customPlaceholder="Product name"
                                    attributes={{ required: true }}
                                />
                            )}
                        </FormColumn>
                    </FormRow>
                </Fragment>
            )
        }}
    </FormSection>
)

AddNewItemBasicInfoForm.propTypes = {
    formik: PropTypes.shape({
        setFieldValue: PropTypes.func.isRequired,
        values: PropTypes.shape({
            product: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    value: PropTypes.shape({
                        id: PropTypes.number.isRequired,
                        name: PropTypes.string.isRequired,
                        has_combinations: PropTypes.bool.isRequired
                    }),
                    label: PropTypes.string.isRequired
                })
            ]),
            brand: PropTypes.shape({
                id: PropTypes.number,
                value: PropTypes.number,
                label: PropTypes.string
            }),
            description: PropTypes.string
        }).isRequired
    }).isRequired
}

export default AddNewItemBasicInfoForm
