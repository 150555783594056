import React from "react"
import PropTypes from "prop-types"

import { ActionLink } from "../../components"
import { ButtonWithDropdown } from "ui"
import { useIsTablet } from "helpers/rwd"

import withStyles from "HOC/withStyles"
import styles from "./TopBar.css"
import buttonWithDropdownStyles from "./overrides/ButtonWithDropdown.css"

import iconDelete from "assets/close-blue.svg"
import iconAdd from "assets/add.svg"
import iconMoveTo from "assets/move-to.svg"
import iconDuplicate from "assets/duplicate.svg"
import iconPrinter from "assets/print.svg"
import mailIcon from "assets/ico-mail_blue.svg"

const TopBar = props => {
    const {
        cx,
        isMultiSelect,
        areAllChecked,
        productCount,
        handleAction,
        isPrinting,
        isSending,
        printAndSendItems,
        handlePrint,
        handleSend
    } = props

    const isTablet = useIsTablet()

    return (
        <header className={cx("root", { isMultiSelect })}>
            <div className={cx("titleWrapper")}>
                <span className={cx("title")}>
                    {isMultiSelect ? `${areAllChecked ? "All s" : "S"}elected` : "Products"}
                </span>
                {!!productCount && <span className={cx("productCount")}>{productCount}</span>}
            </div>
            {isMultiSelect ? renderMultiSelectActions() : renderActions()}
        </header>
    )

    function renderMultiSelectActions() {
        return (
            <ul className={cx("actionList")}>
                <li>
                    <ButtonWithDropdown
                        label="Print"
                        items={printAndSendItems(handlePrint)}
                        icon={iconPrinter}
                        isLoading={isPrinting}
                        customStyles={buttonWithDropdownStyles}
                    />
                </li>

                <li>
                    <ButtonWithDropdown
                        label="Send"
                        className={isTablet ? "" : "blue"}
                        icon={isTablet && mailIcon}
                        items={printAndSendItems(handleSend)}
                        isLoading={isSending}
                        customStyles={buttonWithDropdownStyles}
                    />
                </li>

                <li>
                    <ActionLink as="button" type="button" onClick={() => handleAction("MULTISELECT_DUPLICATE")}>
                        <img src={iconDuplicate} alt="" className={cx("iconDuplicate")} />
                        <span>Copy to</span>
                    </ActionLink>
                </li>
                <li>
                    <ActionLink as="button" type="button" onClick={() => handleAction("MULTISELECT_MOVE")}>
                        <img src={iconMoveTo} alt="" className={cx("iconMove")} />
                        <span>Move to</span>
                    </ActionLink>
                </li>
                <li>
                    <ActionLink as="button" type="button" onClick={() => handleAction("MULTISELECT_DELETE")}>
                        <img src={iconDelete} alt="" className={cx("iconDelete")} />
                        <span>Delete</span>
                    </ActionLink>
                </li>
            </ul>
        )
    }

    function renderActions() {
        return (
            <ul className={cx("actionList")}>
                {!!productCount && (
                    <li>
                        <ActionLink as="button" type="button" onClick={() => handleAction("DELETE_ALL_PRODUCTS")}>
                            <img src={iconDelete} alt="" className={cx("iconDelete")} />
                            <span>Delete all</span>
                        </ActionLink>
                    </li>
                )}
                <li>
                    <ActionLink
                        as="button"
                        type="button"
                        onClick={() => handleAction("ADD_PRODUCTS")}
                        className={cx("button")}
                    >
                        <img src={iconAdd} alt="" className={cx("iconAdd")} />
                        <span>Add product</span>
                    </ActionLink>
                </li>
            </ul>
        )
    }
}

TopBar.propTypes = {
    isMultiSelect: PropTypes.bool.isRequired,
    areAllChecked: PropTypes.bool.isRequired,
    productCount: PropTypes.number.isRequired,
    handleAction: PropTypes.func.isRequired
}

export default withStyles(TopBar, styles)
