import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import { Image } from "./components"

import styles from "./styles.css"

const Cover = ({ cx, form, imageDimensions }) => {
    const setImage = (type, image) => {
        form.setFieldValue(`cover_${type}`, image)
    }

    return (
        <div className={cx("root")}>
            {["desktop", "mobile", "tablet"].map(type => (
                <Image
                    key={type}
                    imageType={type}
                    setImage={image => setImage(type, image)}
                    removeImage={() => setImage(type, null)}
                    imageDimensions={imageDimensions ? imageDimensions[type] : {}}
                    image={form.values[`cover_${type}`]}
                    error={form.errors[`cover_${type}`]}
                />
            ))}
        </div>
    )
}

const dimensionPropType = PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
}).isRequired

Cover.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    imageDimensions: PropTypes.shape({
        desktop: dimensionPropType,
        tablet: dimensionPropType,
        mobile: dimensionPropType
    }).isRequired
}

export default withStyles(Cover, styles)
