import React, { useMemo } from "react"
import getStyles from "helpers/getCxFromStyles"
import { CollapsingText, PriceElements } from "ui"
import styles from "./summary.module.css"

const Summary = ({
    overviewConfig,
    order: { id, calculations, instructions, card_charge_fee: cardChargeFee, items, tax_code },
    onRefreshTax = () => {},
    isRefreshingTax
}) => {
    const cx = getStyles(styles)

    const priceConfig = useMemo(() => {
        const config = overviewConfig.summary(calculations, cardChargeFee)

        return config.map(group =>
            group.map(item =>
                item && item.hasRefreshTaxButton
                    ? {
                          ...item,
                          refreshTaxButtonProps: {
                              onClick: onRefreshTax,
                              isLoading: isRefreshingTax,
                              disabled: (items || []).length === 0
                          },
                          customValue: tax_code === null && (
                              <span className={styles.notCalculatedText}>Not yet calculated</span>
                          )
                      }
                    : item
            )
        )
    }, [overviewConfig, calculations, cardChargeFee, isRefreshingTax, items])

    return (
        <div className={cx("root")}>
            <div className={cx("content")}>
                <div className={cx("instructions")}>
                    <p className={cx("label")}>Special instructions:</p>
                    <CollapsingText className={cx("text")} text={instructions || "-"} />
                </div>
                <div className={cx("calculation")}>
                    <PriceElements
                        config={priceConfig}
                        updateResource={overviewConfig.updateResource}
                        id={id}
                        cardChargeFee={cardChargeFee}
                    />
                </div>
            </div>
        </div>
    )
}

export default Summary
