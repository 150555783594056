import * as constants from "./../../constants/index"
import pagination from "./pagination"
import query from "./query"
import sort from "./sort"
import dateRange from "./dateRange"
import type from "./type"
import moreFilters from "./filters"

const reducers = {
    dateRange,
    pagination,
    query,
    sort,
    type,
    filters: moreFilters
}

export const INITIAL_STATE = Object.keys(reducers).reduce(
    (acc, name) => ({ ...acc, [name]: reducers[name](undefined, {}) }),
    {}
)

const initState = () => ({ ...INITIAL_STATE })

const filters = (state = initState(), action) => {
    switch (action.type) {
        case constants.CLEAR_FILTERS_GROUP:
            return initState()

        case constants.REPLACE_FILTERS_GROUP:
            return { ...state, ...action.payload }

        default:
            return Object.keys(reducers).reduce(
                (acc, name) => ({ ...acc, [name]: reducers[name](state[name], action) }),
                {}
            )
    }
}

export default filters
