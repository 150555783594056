import React, { Fragment } from "react"
import classNames from "classnames"

import withStyles from "HOC/withStyles"
import styles from "./ErrorLabel.css"

const ErrorLabel = ({ cx, error, customStyles }) => {
    const isArray = Array.isArray(error)

    return (
        <div className={classNames(cx("root"), customStyles)}>
            {isArray
                ? error.map((e, index) => (
                      <Fragment key={index}>
                          {e}
                          <br />
                      </Fragment>
                  ))
                : error}
        </div>
    )
}

export default withStyles(ErrorLabel, styles)
