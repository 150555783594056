import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useActions } from "hooks"
import { getDocsTemplates } from "actions/docs-templates"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"
import DocsTemplates from "./DocsTemplates"

const DocsTemplatesContainer = () => {
    const actions = useActions({ getDocsTemplates })

    const { list: docsTemplates } = useSelector(state => state.db.docsTemplates)

    const [searchParams, setSearchParams] = useState({
        query: "",
        sort_by: "name",
        sort_direction: "asc",
        page: 1,
        length: LENGTH_WITHOUT_PAGINATION
    })

    const handleTableChange = (tableParams, isInitialCallback) => {
        if (!isInitialCallback) {
            setSearchParams(prevSearchParams => ({
                ...prevSearchParams,
                page: 1,
                query: tableParams.search,
                sort_by: tableParams.sorting.by,
                sort_direction: tableParams.sorting.direction
            }))
        }
    }

    useEffect(() => {
        actions.getDocsTemplates(searchParams)
    }, [searchParams])

    return <DocsTemplates docsTemplates={docsTemplates} handleTableChange={handleTableChange} />
}

export default DocsTemplatesContainer
