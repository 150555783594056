import React, { useContext } from "react"
import { useTableSort } from "@butterfly-frontend/ui"
import { useDashboard } from "modules/DashboardModule/hooks/api/useDashboard"
import { formatDateToApi } from "modules/WmsModule/helpers/date"
import ListFiltersContext from "modules/DashboardModule/contexts/ListFiltersContext"
import { useSalesPersonList } from "modules/DashboardModule/hooks/api/useSalesPerson"

import Dashboard from "./DashboardPage"

export const DashboardContainer = () => {
    const {
        values: { dashboard: filtersFromContext }
    } = useContext(ListFiltersContext)

    const dashboardData = useDashboard({
        date_from: filtersFromContext.date.startDate ? formatDateToApi(filtersFromContext.date.startDate) : null,
        date_to: filtersFromContext.date.endDate ? formatDateToApi(filtersFromContext.date.endDate) : null
    })

    const { sortState, onChangeSort } = useTableSort({
        initialState: { direction: "desc", by: "total_payments" }
    })

    const summarySalesPersonList = useSalesPersonList({
        params: {
            sort_by: sortState.by,
            sort_direction: sortState.direction,
            length: 6,
            date_from: filtersFromContext.date.startDate ? formatDateToApi(filtersFromContext.date.startDate) : null,
            date_to: filtersFromContext.date.endDate ? formatDateToApi(filtersFromContext.date.endDate) : null
        }
    })

    return (
        <Dashboard
            dashboardData={dashboardData.data ? dashboardData.data.data : null}
            summarySalesPersonList={summarySalesPersonList ? summarySalesPersonList.data : []}
            sortState={sortState}
            onChangeSort={onChangeSort}
            isLoading={
                !dashboardData.isFetched ||
                (!summarySalesPersonList.fetchStatus.isLoaded && summarySalesPersonList.fetchStatus.isLoading)
            }
        />
    )
}

export default DashboardContainer
