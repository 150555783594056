import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./Aside.css"

const Aside = props => {
    const { cx, label, children } = props

    return (
        <div className={cx("root")}>
            {label && <h3 className={cx("label")}>{label}</h3>}
            {children}
        </div>
    )
}

Aside.propTypes = {
    label: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.node, PropTypes.array])
}

export default withStyles(Aside, styles)
