import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"

export function getSourcesStart() {
    return {
        type: constants.GET_SOURCES_START
    }
}
export function getSourcesSuccess(data) {
    return {
        type: constants.GET_SOURCES_SUCCESS,
        payload: {
            data
        }
    }
}
export function getSourcesError(error) {
    return {
        type: constants.GET_SOURCES_ERROR,
        payload: {
            error
        }
    }
}
export function getSources() {
    return dispatch => {
        dispatch(getSourcesStart())

        fetch
            .get(`/sources?length=${LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getSourcesSuccess(data)))
            .catch(error => {
                dispatch(getSourcesError(error))
            })
    }
}

function getSourceStart() {
    return {
        type: constants.GET_SOURCE_START
    }
}
function getSourceSuccess(data) {
    return {
        type: constants.GET_SOURCE_SUCCESS,
        payload: {
            data
        }
    }
}
function getSourceError(error) {
    return {
        type: constants.GET_SOURCE_ERROR,
        payload: {
            error
        }
    }
}
export function createSource(name) {
    return dispatch => {
        fetch
            .post(`/sources`, { name })
            .then(data => dispatch(getSourceById(data[data.length - 1].id)))
            .catch(error => dispatch(getSourceError(error)))
    }
}

export function editSource(id, name) {
    return dispatch => {
        fetch
            .patch(`/sources/${id}`, { name })
            .then(() => dispatch(getSourceById(id)))
            .catch(error => dispatch(getSourceError(error)))
    }
}

export function getSourceById(id) {
    return dispatch => {
        dispatch(getSourceStart())

        fetch
            .get(`/sources/${id}`)
            .then(data => dispatch(getSourceSuccess(data)))
            .catch(error => dispatch(getSourceError(error)))
    }
}

export function deleteSourceStart() {
    return {
        type: constants.DELETE_SOURCE_START
    }
}

export function deleteSourceError(error) {
    return {
        type: constants.DELETE_SOURCE_ERROR,
        payload: {
            error
        }
    }
}

export function deleteSourceSuccess(id) {
    return {
        type: constants.DELETE_SOURCE_SUCCESS,
        payload: id
    }
}

export function deleteSource(id) {
    return dispatch => {
        dispatch(deleteSourceStart())

        fetch
            .deleteRAW(`/sources/${id}`)
            .then(() => dispatch(deleteSourceSuccess(id)))
            .catch(error => dispatch(deleteSourceError(error)))
    }
}
