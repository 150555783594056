import React, { Component } from "react"
import moment from "moment"
import pSBC from "shade-blend-color"

import withStyles from "HOC/withStyles"
import withPermissions from "HOC/withPermissions"
import { checkIsDark } from "helpers/color"

import Layout from "components/Reusable/Layout"
import Button from "components/Reusable/Form/Button"

import CreateNewCalendarModal from "components/Modal/Calendar/AddEditModal/AddEditModal"
import EditCalendarModal from "components/Modal/Calendar/AddEditModal/AddEditModal"
import DeleteCalendarModal from "components/Modal/Calendar/DeleteCalendarModal/DeleteCalendarModal"

import ImportCalendarModal from "components/Modal/Calendar/ImportCalendarModal"
import ExternalCalendarModal from "components/Modal/Calendar/ExternalCalendarModal"

import CreateNewEventCalendarModal from "components/Modal/Calendar/AddEditEventModal"
import EditEventCalendarModal from "components/Modal/Calendar/AddEditEventModal"
import DeleteEventCalendarModal from "components/Modal/Calendar/DeleteEventModal/DeleteEventModal"

import AddClientModalContainer from "modules/OrdersModule/components/Form/components/GeneralInfo/AddClientModal/AddClientModal"

import CalendarHeader from "modules/CalendarModule/components/Header/CalendarHeader"
import CalendarList from "modules/CalendarModule/components/CalendarList"
import CalendarGrid from "modules/CalendarModule/components/CalendarGrid"
import SearchList from "modules/CalendarModule/components/SearchList"

import styles from "./Calendar.css"
import layoutStyles from "./overrides/layout.css"

class Calendar extends Component {
    constructor(props) {
        super(props)

        this.calendarRef = React.createRef()
        this.calendarApi = null

        this.state = {
            title: "",
            view: "dayGridMonth",
            events: [],
            expanded: true,
            deleteEventModalVisible: false,
            editEventModalVisible: false,
            createNewCalendarModalVisible: false,
            importCalendarModalVisible: false,
            externalCalendarModalVisible: false,
            selectedExternalCalendar: null,
            isSubmittingImportCalendar: false,
            editModalVisible: false,
            createNewEventModalVisible: false,
            createAddClientModalVisible: false,
            isEntryDetailsOpen: true,
            deleteCalendarModalVisible: false,
            entryDetails: { visible: false, data: {} },
            selectedDate: {},
            isSearching: false,
            createdClient: null
        }
    }

    componentDidMount() {
        this.calendarApi = this.calendarRef.current.getApi()
        this.setTitle()
        this.getWeekRange()
    }

    componentDidUpdate(prevProps) {
        const {
            calendar: {
                list: { data }
            }
        } = this.props

        if (prevProps.calendar.list.data !== data) {
            this.fetchEntries()
        }
    }

    fetchEntries = () => {
        const { isSearching } = this.state
        const {
            calendar: {
                list: { data }
            },
            getEntries,
            setEntryParams
        } = this.props

        const activeRange = this.calendarApi.state.dateProfile.activeRange
        const startDay = moment(activeRange.start).format("YYYY-MM-DD")
        const endDay = moment(activeRange.end).format("YYYY-MM-DD")
        const selectedCalendars = [...data.private, ...data.public]
            .filter(calendar => calendar.is_selected)
            .map(calendar => calendar.id)

        setEntryParams({ page: 1, length: 9999, starts_at: startDay, ends_at: endDay })
        !isSearching && getEntries(selectedCalendars)
    }

    changeView = view => {
        this.calendarApi.changeView(view)
        this.setState({ view })
        this.setTitle()
        this.getWeekRange()
        this.fetchEntries()
    }

    getWeekRange = () => {
        const activeRange = this.calendarApi.state.dateProfile.activeRange
        const weeks = {
            start: moment(activeRange.start).week(),
            end: moment(activeRange.end).week()
        }

        // Condition for week view
        if (weeks.start === weeks.end) {
            this.setState({
                weeksTitle: weeks.start
            })
        } else {
            this.setState({
                weeksTitle: `${weeks.start} - ${weeks.end}`
            })
        }
    }

    setTitle = () => {
        this.setState({
            title: this.calendarApi.view.title
        })
    }

    toggleCalendarList = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    goToDate = target => {
        this.calendarApi[target]()
        this.setTitle()
        this.getWeekRange()
        this.fetchEntries()
    }

    toggleModal = name => {
        this.setState({
            [name]: !this.state[name]
        })
    }

    toggleAddClientModal = () => {
        this.toggleModal("createAddClientModalVisible")
    }

    handleEditCalendarModal = calendar => {
        this.toggleModal("editModalVisible")
        this.setState({
            editedCalendar: calendar
        })
    }

    handleDeleteCalendarModal = calendar => {
        this.toggleModal("deleteCalendarModalVisible")
        this.setState({
            deletedCalendar: calendar
        })
    }

    handleEventClick = event => {
        this.setState({
            entryDetails: {
                data: event,
                visible: true
            }
        })
    }

    handleEventClose = () => {
        this.setState({
            entryDetails: {
                ...this.state.entryDetails,
                visible: false
            }
        })
    }

    handleImportCalendar = ({ name, url }) => {
        // TODO: add when the backend will support
    }

    handleExportCalendar = calendar => {
        this.toggleModal("externalCalendarModalVisible")
        this.setState({ selectedExternalCalendar: calendar })
    }

    handleClickCellCalendar = event => {
        this.setState(
            {
                selectedDate: {
                    starts_at: event.date,
                    ends_at: event.allDay ? event.date : moment(event.date).add(30, "minutes"),
                    allDay: event.allDay
                }
            },
            () => this.toggleModal("createNewEventModalVisible")
        )
    }

    handleCloseCreateEventModal = () => {
        this.toggleModal("createNewEventModalVisible")
        this.setState({ selectedDate: {}, createdClient: null })
    }

    handleCloseEditEventModal = () => {
        this.toggleModal("editEventModalVisible")
        this.setState({ createdClient: null })
    }

    handleStartSearch = () => this.setState({ isSearching: true })
    handleEndSearch = () => {
        const { setEntryQuery, setEntryParams } = this.props

        setEntryQuery("")
        setEntryParams({})

        this.setState({ isSearching: false }, () => this.fetchEntries())
    }

    handleQuerySearch = query => this.props.setEntryQuery(query)
    handleChangeFilters = values => {
        const { setEntryFilters } = this.props

        setEntryFilters(values)
        this.fetchEntries()
    }

    handleCreateClient = data => {
        this.setState({ createdClient: data })
    }

    handleClearCreatedClient = () => this.setState({ createdClient: null })

    handleOpenEditCalendarEntry = entry => {
        this.setState({
            entryDetails: {
                data: {
                    event: {
                        extendedProps: { entry }
                    }
                }
            },
            editEventModalVisible: true
        })
    }

    render() {
        const {
            cx,
            calendar,
            createCalendar,
            editCalendar,
            getUsersList,
            users,
            createCalendarEntry,
            showErrorNotification,
            deleteCalendarEntry,
            editCalendarEntry,
            deleteCalendar,
            handleChangeDateEvent
        } = this.props

        const {
            isSearching,
            title,
            weeksTitle,
            importCalendarModalVisible,
            isSubmittingImportCalendar,
            externalCalendarModalVisible,
            selectedExternalCalendar,
            createNewCalendarModalVisible,
            deleteCalendarModalVisible,
            editModalVisible,
            createNewEventModalVisible,
            view,
            querySearch,
            filters,
            expanded,
            deletedCalendar,
            editedCalendar,
            selectedDate,
            createdClient,
            createAddClientModalVisible,
            editEventModalVisible,
            entryDetails,
            deleteEventModalVisible
        } = this.state

        const parsedEntries = calendar.entries.data.map(entry => {
            const showTime = !entry.is_all_day
            const isDarkBackground = checkIsDark(entry.calendar.color)
            const canBeMultiline =
                !entry.is_all_day && moment(entry.ends_at).diff(moment(entry.starts_at), "minutes") > 59

            return {
                title: entry.title,
                start: entry.starts_at,
                end: !!entry.is_all_day
                    ? moment(entry.ends_at)
                          .add(1, "d")
                          .format("YYYY-MM-DD HH:mm:ss")
                    : entry.ends_at,
                classNames: [
                    `type-
                        ${entry.is_moderable ? "entry-is-moderable" : ""}
                        ${entry.is_moderated ? "entry-is-moderated" : ""}
                        ${!entry.is_public ? "entry-private" : ""}
                        ${canBeMultiline ? "entry-can-be-multiline" : ""}
                        ${entry.is_shared ? "entry-shared" : ""}
                        ${showTime ? "show-time" : ""}
                        ${isDarkBackground ? "light" : "dark"} event-tooltip`
                ],
                allDay: !!entry.is_all_day,
                backgroundColor: entry.calendar.color,
                borderColor: pSBC(-0.3, entry.calendar.color),
                textColor: entry.calendar.font_color || (isDarkBackground ? "#cdd2de" : "#2e3545"),
                editable: entry.is_editable,
                extendedProps: { entry }
            }
        })

        return (
            <Layout
                title="Calendar"
                customStyles={layoutStyles}
                calendarTitle={!isSearching && title}
                calendarWeek={!isSearching && `Week ${weeksTitle}`}
                cta={[
                    // <Link to="" className={cx("decoration-none", "disabled-element")}>
                    //     <Button className={"button__white"} icon={<img src={iconPrint} alt="" />} label="Print" />
                    // </Link>,
                    // <Link to="" className={cx("decoration-none", "disabled-element")}>
                    //     <Button className={"button__white"} label="Send" />
                    // </Link>,
                    <Button
                        className={"button__blue"}
                        onClick={() => this.toggleModal("createNewEventModalVisible")}
                        label="Add Event"
                    />
                ]}
            >
                <div className={cx("root")}>
                    <ImportCalendarModal
                        isOpen={importCalendarModalVisible}
                        onClose={() => this.toggleModal("importCalendarModalVisible")}
                        onSubmit={this.handleImportCalendar}
                        isSubmitting={isSubmittingImportCalendar}
                    />
                    <ExternalCalendarModal
                        isOpen={externalCalendarModalVisible}
                        onClose={() => this.toggleModal("externalCalendarModalVisible")}
                        calendar={selectedExternalCalendar}
                    />
                    <CalendarHeader
                        handlePrev={() => this.goToDate("prev")}
                        handleToDay={() => this.goToDate("today")}
                        handleNext={() => this.goToDate("next")}
                        handleMonth={() => this.changeView("dayGridMonth")}
                        handleWeek={() => this.changeView("timeGridWeek")}
                        handleDay={() => this.changeView("timeGridDay")}
                        toggleCalendarList={() => this.toggleCalendarList()}
                        handleChangeFilters={this.handleChangeFilters}
                        handleQuerySearch={this.handleQuerySearch}
                        handleStartSearch={this.handleStartSearch}
                        handleEndSearch={this.handleEndSearch}
                        currentView={view}
                        initFilters={calendar.entryFilters}
                        isSearching={isSearching}
                        querySearch={querySearch}
                        isLoadingEntries={calendar.entries.isLoading}
                    />

                    <div className={cx("calendar-main", { "calendar-main--expanded": expanded })}>
                        <CalendarList
                            isExpanded={expanded}
                            toggleModal={name => this.toggleModal(name)}
                            handleEdit={calendar => this.handleEditCalendarModal(calendar)}
                            handleDelete={calendar => this.handleDeleteCalendarModal(calendar)}
                            handleExport={this.handleExportCalendar}
                        />
                        {this.state.isSearching && <SearchList query={querySearch} filters={filters} />}
                        <CalendarGrid
                            calendarRef={this.calendarRef}
                            entries={parsedEntries}
                            calendar={calendar}
                            entryDetails={entryDetails}
                            toggleModal={this.toggleModal}
                            fetchEntries={this.fetchEntries}
                            handleEventClose={this.handleEventClose}
                            handleEventClick={this.handleEventClick}
                            handleClickCellCalendar={this.handleClickCellCalendar}
                            handleChangeDateEvent={handleChangeDateEvent}
                            handleOpenEditCalendarEntry={this.handleOpenEditCalendarEntry}
                            isHidden={isSearching}
                        />
                    </div>
                </div>
                {createNewEventModalVisible && (
                    <CreateNewEventCalendarModal
                        handleHideModal={() => this.handleCloseCreateEventModal()}
                        handleAddClientModal={() => this.toggleAddClientModal()}
                        handleCreate={(data, calendars) => createCalendarEntry(data, calendars, isSearching)}
                        showErrorNotification={showErrorNotification}
                        title="New Event:"
                        getUsersList={getUsersList}
                        users={users.list}
                        defaultDate={selectedDate}
                        newClient={createdClient}
                    />
                )}
                {editEventModalVisible && (
                    <EditEventCalendarModal
                        handleHideModal={() => this.handleCloseEditEventModal()}
                        handleAddClientModal={() => this.toggleAddClientModal()}
                        handleEdit={editCalendarEntry}
                        showErrorNotification={showErrorNotification}
                        title="Edit Event:"
                        getUsersList={getUsersList}
                        users={users.list}
                        entryDetails={entryDetails.data}
                        newClient={createdClient}
                    />
                )}
                {deleteEventModalVisible && (
                    <DeleteEventCalendarModal
                        handleHideModal={() => this.toggleModal("deleteEventModalVisible")}
                        showErrorNotification={showErrorNotification}
                        title={"Delete this event?"}
                        entry={entryDetails.data}
                        handleDelete={deleteCalendarEntry}
                    />
                )}
                {createNewCalendarModalVisible && (
                    <CreateNewCalendarModal
                        handleHideModal={() => this.toggleModal("createNewCalendarModalVisible")}
                        handleCreate={createCalendar}
                        getUsersList={getUsersList}
                        users={users.list}
                        showErrorNotification={showErrorNotification}
                        title={"Create new calendar"}
                    />
                )}
                {editModalVisible && (
                    <EditCalendarModal
                        handleHideModal={() => this.toggleModal("editModalVisible")}
                        handleEdit={editCalendar}
                        getUsersList={getUsersList}
                        users={users.list}
                        showErrorNotification={showErrorNotification}
                        editedCalendar={editedCalendar}
                        title={"Edit calendar"}
                    />
                )}
                {deleteCalendarModalVisible && (
                    <DeleteCalendarModal
                        handleHideModal={() => this.toggleModal("deleteCalendarModalVisible")}
                        showErrorNotification={showErrorNotification}
                        title={"Delete this calendar?"}
                        handleDelete={deleteCalendar}
                        deletedCalendar={deletedCalendar}
                    />
                )}
                <AddClientModalContainer
                    closeModal={() => this.toggleModal("createAddClientModalVisible")}
                    isOpen={createAddClientModalVisible}
                    onAddClient={this.handleCreateClient}
                />
            </Layout>
        )
    }
}

export default withPermissions(withStyles(Calendar, styles))
