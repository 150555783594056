import React from "react"

import ADDRESS_PROP_TYPE from "modules/WmsModule/propTypes/shipmentAddressPropType"
import styles from "./ShipmentAddress.module.css"

const ShipmentAddress = ({ shipmentAddress }) => {
    const { company, first_name, last_name, address, city, state, post_code, country } = shipmentAddress

    return (
        <div className={styles.addressContainer}>
            <b>
                {company
                    ? `${company}, ${first_name || ""} ${last_name || ""}`
                    : `${first_name || ""} ${last_name || ""}`}
            </b>
            <span>{address}</span>
            <span>
                {city ? `${city}, ` : ""}
                {state ? `${state} ` : ""}
                {post_code ? `${post_code} ` : ""}
            </span>
            <span>{country}</span>
        </div>
    )
}

ShipmentAddress.propTypes = ADDRESS_PROP_TYPE.isRequired

export default ShipmentAddress
