const initialState = {
    viewType: "tiles"
}

function list(state = initialState, action) {
    switch (action.type) {
        case "ui/products/list/SET_VALUES":
            return {
                ...state,
                ...action.payload
            }
        case "ui/products/list/RESET":
            return {
                ...initialState
            }
        default:
            return state
    }
}

export default list
