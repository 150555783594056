import * as constants from "constants/index"

function categoryRoute(state = [], action) {
    switch (action.type) {
        case constants.CLEAR_FILTERS:
            return []
        case constants.CHOSEN_CATEGORY_ROUTE:
            const id = action.payload
            return [...state, id]
        case constants.CHOSEN_CATEGORY_ROUTE_INIT:
            return [...action.payload]
        case constants.CHOSEN_CATEGORY_RESET:
            return []
        default:
            return state
    }
}

export default categoryRoute
