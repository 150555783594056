import React, { useRef, useMemo } from "react"
import ReactSVG from "react-svg"
import moment from "moment"
import useStyles from "helpers/getCxFromStyles"
import styles from "./Item.css"

import lockIcon from "assets/ico-lock.svg"
import sharedIcon from "assets/ico-people.svg"

const Item = props => {
    const cx = useStyles(styles)
    const showDetailsButton = useRef()
    const { event, toggleShowDetails, isDescription } = props
    const {
        starts_at,
        ends_at,
        calendar: { color },
        is_public,
        shares,
        is_all_day,
        title_html,
        created_by,
        description
    } = event
    const startsAtMoment = moment(starts_at)
    const endsAtMoment = moment(ends_at)
    const isPast = ends_at ? endsAtMoment < moment() : startsAtMoment < moment()

    const hasDifferentYear = startsAtMoment.year() !== endsAtMoment.year()
    const hasDifferentMonth = startsAtMoment.month() !== endsAtMoment.month()
    const hasDifferentDay = startsAtMoment.date() !== endsAtMoment.date()

    const parsedStartTime = startsAtMoment.format("hh:mm a")
    const parsedEndTime = endsAtMoment.format("hh:mm a")

    const date = useMemo(() => {
        if (!ends_at) {
            return startsAtMoment.format("MMM DD, YYYY")
        }

        if (hasDifferentYear) {
            return `${startsAtMoment.format("MMM DD, YYYY")} - ${endsAtMoment.format("MMM DD, YYYY")}`
        }

        if (hasDifferentMonth) {
            return `${startsAtMoment.format("MMM DD")} - ${endsAtMoment.format("MMM DD, YYYY")}`
        }

        if (hasDifferentDay) {
            return `${startsAtMoment.format("MMM DD")} - ${endsAtMoment.format("DD, YYYY")}`
        }

        return startsAtMoment.format("MMM DD, YYYY")
    }, [starts_at, ends_at])

    return (
        <div className={cx("root", { isPast })}>
            <div className={cx("date")}>
                <span>{date}</span>
                {!is_all_day && <span>{`${parsedStartTime} - ${parsedEndTime}`}</span>}
            </div>
            <div className={cx("name")}>
                <div className={cx("divider")} style={{ backgroundColor: color }} />
                {!is_public && <ReactSVG src={lockIcon} className={cx("private")} />}
                {shares && !!shares.length && <ReactSVG src={sharedIcon} className={cx("shared")} />}
                <span dangerouslySetInnerHTML={{ __html: title_html }} />
            </div>
            {isDescription && <div className={cx("description")}>{description}</div>}
            <div className={cx("author")}>{created_by ? created_by.full_name : "-"}</div>
            <div className={cx("action")}>
                <span
                    className={cx("showDetails")}
                    ref={showDetailsButton}
                    onClick={jsEvent => toggleShowDetails(event, jsEvent.nativeEvent)}
                >
                    Details
                </span>
            </div>
        </div>
    )
}

export default Item
