import React, { Fragment } from "react"
import PropTypes from "prop-types"

import SubmitModal from "ui/SubmitModal"

const ActivateDeleteModal = props => {
    const {
        modalType,
        isLoading,
        isCollectionActive,
        collectoinTitle,
        lowerCaseCollectionTypeLabel,
        onClose,
        onSubmit
    } = props

    const activateActionName = isCollectionActive ? "Deactivate" : "Activate"
    const modalBodyLabel = (actionName, secondSentence) => (
        <Fragment>
            Are you sure you want to {actionName} the {lowerCaseCollectionTypeLabel}{" "}
            <strong>"{collectoinTitle}"</strong>?
            <br />
            <br />
            {secondSentence}
        </Fragment>
    )

    const modal = {
        activate: {
            redButton: false,
            header: `${activateActionName} this ${lowerCaseCollectionTypeLabel}?`,
            body: modalBodyLabel(activateActionName.toLowerCase(), "Your change will be visible on the website."),
            submitText: activateActionName
        },
        delete: {
            redButton: true,
            header: `Delete this ${lowerCaseCollectionTypeLabel}?`,
            body: modalBodyLabel("delete", "This action cannot be reversed."),
            submitText: "Delete"
        }
    }

    return (
        <SubmitModal
            isOpen
            withLine
            isLoading={isLoading}
            onClose={onClose}
            onSubmit={onSubmit}
            {...modal[modalType]}
        />
    )
}

ActivateDeleteModal.propTypes = {
    modalType: PropTypes.oneOf(["activate", "delete"]),
    isLoading: PropTypes.bool.isRequired,
    isCollectionActive: PropTypes.bool,
    collectoinTitle: PropTypes.string.isRequired,
    lowerCaseCollectionTypeLabel: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default ActivateDeleteModal
