import React, { useContext } from "react"
import { useParams } from "react-router-dom"

import { useDashboard } from "modules/DashboardModule/hooks/api/useDashboard"
import { useSeller } from "modules/DashboardModule/hooks/api/useSeller"
import ListFiltersContext from "modules/DashboardModule/contexts/ListFiltersContext"
import { formatDateToApi } from "modules/WmsModule/helpers/date"

import SellerDetailsPage from "./SellerDetailsPage"

export const SellerDetailsPageContainer = () => {
    const { sellerId } = useParams()

    const {
        values: { dashboard: filtersFromContext }
    } = useContext(ListFiltersContext)

    const { data, isLoading } = useDashboard({
        sale_person_id: sellerId,
        date_from: filtersFromContext.date.startDate ? formatDateToApi(filtersFromContext.date.startDate) : null,
        date_to: filtersFromContext.date.endDate ? formatDateToApi(filtersFromContext.date.endDate) : null
    })

    const { data: sellerData, isLoading: isSellerLoading } = useSeller(sellerId)

    return (
        <SellerDetailsPage
            seller={sellerData ? sellerData.data : {}}
            data={data ? data.data : {}}
            isLoading={isLoading || isSellerLoading}
            selectedRange={filtersFromContext.date}
        />
    )
}

export default SellerDetailsPageContainer
