import React, { useMemo } from "react"
import { useDispatch } from "react-redux"
import _get from "lodash/get"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import _isEqual from "lodash/isEqual"

import fetch from "helpers/fetch"
import { Textarea, SaveBar } from "ui"
import { showErrorNotification } from "actions/notification"
import apiErrorsAdapter from "modules/WmsModule/helpers/apiErrorsAdapter"
import { conditionToValue } from "ui/Filters/SelectProductConditions"
import { warehouseToValue } from "ui/Filters/SelectWarehouse"
import { ProductHeader, ProductConditionForm, BoxListForm } from "modules/WmsModule/components"
import { formatDatetimeToApi, addDaysFromNow } from "modules/WmsModule/helpers/date"
import STOCK_ITEM_STATUS_SETTER_OPTIONS from "modules/WmsModule/constants/stockItemStatusSetterOptions"
import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import { useStockItemEdit } from "modules/WmsModule/hooks/api/useStockItem"
import HeroForm from "modules/WmsModule/pages/StockAdjustmentAddPage/HeroForm"
import HeroDetails from "modules/WmsModule/pages/StockAdjustmentAddPage/HeroDetails"
import CombinationForm from "modules/WmsModule/pages/StockAdjustmentAddPage/CombinationForm"
import { COMBINATION_PATTERNS_PROP_TYPE, COMBINATION_ATTRIBUTES_PROP_TYPE } from "propTypes/combinationPropType"

import validationSchema from "./validationSchema"
import styles from "./StockItemAdjustmentForm.module.css"

const valuesToApi = (values, initialValues) => {
    const status = _get(values, "status.value")
    const onHoldForDays = _get(values, "for.value")
    const reason_id = _get(values, "reason.value")
    const isHeroInEditMode = _get(values, "isHeroInEditMode")
    const boxes = _get(values, "boxes")
    const location_id = _get(values, "location.id", null)
    const images = _get(values, "conditionImages")
    const images_ids = images.map(image => image.id)

    const initialLocationId = _get(initialValues, "location.id")
    const locationHasBeenChanged = !_isEqual(initialLocationId, location_id)

    const initialBoxes = _get(initialValues, "boxes")
    const boxesHasBeenChanged = !_isEqual(initialBoxes, boxes) || locationHasBeenChanged

    const condition = isHeroInEditMode
        ? {
              product_condition_id: _get(values, "condition.value.id"),
              images_ids
          }
        : {
              images_ids
          }

    const heroFormData = {
        status: {
            name: status,
            valid_date:
                status === STOCK_ITEM_STATUS_KEYS.ON_HOLD && onHoldForDays
                    ? formatDatetimeToApi(addDaysFromNow(onHoldForDays))
                    : undefined,
            reason_id
        },
        warehouse_id: _get(values, "warehouse.value")
    }

    const formData = {
        location_id: locationHasBeenChanged ? location_id : undefined,
        boxes: boxesHasBeenChanged
            ? boxes.map(({ location, ...box }) => ({ ...box, location_id: location_id }))
            : undefined,
        description: values.description,
        condition
    }

    return isHeroInEditMode ? { ...heroFormData, ...formData } : formData
}

const fetchCombinationByAttributeValues = (productId, selectedCombinationAttributes = []) => {
    const selectedAttributesIds = Object.values(selectedCombinationAttributes)
        .map(attribute => _get(attribute, "value.id"))
        .join(",")

    return fetch.get(`/combinations/find?productId=${productId}&attributeValueIds=${selectedAttributesIds}`)
}

const StockItemAdjustmentForm = ({ stockItem, onCancel, onSuccess }) => {
    const {
        id,
        brand,
        product,
        boxes,
        location,
        condition: { product_condition },
        warehouse,
        status
    } = stockItem

    const stockItemEdit = useStockItemEdit()

    const dispatch = useDispatch()

    const initialValues = useMemo(
        () => ({
            isHeroInEditMode: false,
            isCombinationInEditMode: false,
            warehouse: warehouseToValue(warehouse),
            condition: conditionToValue(product_condition),
            boxes,
            location: location ? { ...location, label: location.name } : null,
            status: STOCK_ITEM_STATUS_SETTER_OPTIONS.find(({ value }) => value === status),
            for: null,
            reason: null,
            conditionImages: [],
            description: "",
            selectedCombinationAttributes: []
        }),
        [stockItem]
    )

    const getApiErrors = errors => {
        const { condition, status, warehouse_id: warehouse, boxes, ...restApiErrors } = apiErrorsAdapter(errors)

        return {
            condition: _get(condition, "product_condition_id"),
            status: _get(status, "name"),
            boxes,
            warehouse,
            ...restApiErrors
        }
    }

    const formik = useFormik({
        validationSchema,
        initialValues,
        onSubmit: async (values, { setErrors, setFieldError }) => {
            let combination_id

            try {
                if (values.isCombinationInEditMode && stockItem.product.has_combinations) {
                    const combination = await fetchCombinationByAttributeValues(
                        product.id,
                        values.selectedCombinationAttributes
                    )
                    combination_id = _get(combination, "data.id")
                }
            } catch (err) {
                setFieldError("combination", "The selected combination is invalid")
                return
            }

            return stockItemEdit
                .mutate({ id, data: { ...valuesToApi(values, initialValues), combination_id } })
                .then(onSuccess)
                .catch(error => {
                    const apiErrors = _get(error, "errors", null)
                    const errorMessage = _get(error, "message", null)
                    if (apiErrors) {
                        setErrors(getApiErrors(apiErrors))
                    }
                    dispatch(showErrorNotification(errorMessage))
                })
        }
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={styles.header}>
                <ProductHeader product={{ ...product, brand }} />
            </div>

            {formik.values.isHeroInEditMode ? (
                <HeroForm stockItem={stockItem} formik={formik} />
            ) : (
                <HeroDetails
                    stockItem={stockItem}
                    onEdit={() => formik.setFieldValue("isHeroInEditMode", true, false)}
                />
            )}

            {stockItem.product.has_combinations && (
                <CombinationForm
                    formik={formik}
                    stockItem={stockItem}
                    isInEditMode={formik.values.isCombinationInEditMode}
                    onSetEditMode={() => formik.setFieldValue("isCombinationInEditMode", true, false)}
                />
            )}

            <BoxListForm formik={formik} showSelectLocation />

            <ProductConditionForm formik={formik} />

            <div className={styles.descriptionSection}>
                <Textarea
                    label="Description"
                    className={styles.textarea}
                    name="description"
                    placeholder="Click here to add a description..."
                    value={formik.values.description}
                    error={formik.errors.description}
                    onChange={({ event }) => formik.handleChange(event)}
                />
            </div>

            <SaveBar isShown isSaving={formik.isSubmitting} isSubmit submitLabel="Save" onCancel={onCancel} />
        </form>
    )
}

StockItemAdjustmentForm.propTypes = {
    stockItem: PropTypes.shape({
        id: PropTypes.number.isRequired,
        condition: PropTypes.shape({
            product_condition: PropTypes.shape({
                id: PropTypes.number.isRequired,
                name: PropTypes.string.isRequired,
                color: PropTypes.string.isRequired,
                require_images: PropTypes.bool.isRequired,
                require_reason: PropTypes.bool.isRequired,
                type: PropTypes.string
            }).isRequired
        }).isRequired,
        warehouse: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired,
        status: PropTypes.string.isRequired,
        boxes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                location: PropTypes.shape({
                    id: PropTypes.number,
                    name: PropTypes.string
                })
            }).isRequired
        ).isRequired,
        location: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string
        }),
        combination: PropTypes.shape({
            attribute_values: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired
                }).isRequired
            )
        }),
        product: PropTypes.shape({
            has_combinations: PropTypes.bool,
            attributes: COMBINATION_ATTRIBUTES_PROP_TYPE,
            combination_patterns: COMBINATION_PATTERNS_PROP_TYPE
        }).isRequired
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default StockItemAdjustmentForm
