import * as constants from "constants/index"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"
import idx from "idx"

const createById = data => data.reduce((acc, paymentMethod) => ({ ...acc, [paymentMethod.id]: paymentMethod }), {})

function paymentMethods(
    state = { data: [], byId: {}, meta: {}, fetchStatus: initialState(), fromCache: false },
    action
) {
    switch (action.type) {
        case constants.GET_PAYMENT_METHODS_START:
            return { data: [], meta: {}, fetchStatus: requestState() }

        case constants.GET_PAYMENT_METHODS_SUCCESS:
            const dataResource = idx(action, _ => _.payload.data) || []
            return {
                data: dataResource,
                byId: createById(dataResource),
                meta: idx(action, _ => _.payload.meta) || {},
                fetchStatus: successState(),
                fromCache: idx(action, _ => _.fromCache) || false
            }

        case constants.GET_PAYMENT_METHODS_ERROR:
            return { data: {}, byId: {}, meta: {}, fetchStatus: { ...failureState(), error: action.payload } }

        case constants.CREATE_PAYMENT_METHOD_SUCCESS:
            return (() => {
                const {
                    payload: { data: paymentMethod }
                } = action
                const { data, byId } = state

                return {
                    ...state,
                    data: [...data, paymentMethod],
                    byId: { ...byId, [paymentMethod.id]: paymentMethod }
                }
            })()

        case constants.UPDATE_PAYMENT_METHOD_SUCCESS:
            return (() => {
                const {
                    payload: { data: paymentMethod }
                } = action
                const { data, byId } = state
                return {
                    ...state,
                    data: data.map(obj => (obj.id === paymentMethod.id ? paymentMethod : obj)),
                    byId: { ...byId, [paymentMethod.id]: paymentMethod }
                }
            })()

        case constants.DELETE_PAYMENT_METHOD_SUCCESS:
            return (() => {
                const {
                    payload: { paymentMethodId }
                } = action
                const { data, byId } = state
                const { [paymentMethodId]: filteredMethod, ...newById } = byId
                return { ...state, data: data.filter(obj => obj.id !== paymentMethodId), byId: newById }
            })()

        default:
            return state
    }
}

export default paymentMethods
