import React from "react"
import { useField } from "formik"
import { useMediaQuery } from "react-responsive"
import withStyles from "HOC/withStyles"

import { Aside as AsideWrapper } from "ui/AddEditForm"
import PersonSelect from "modules/OrdersModule/components/PersonSelect"
import CommissionTeam from "modules/OrdersModule/components/CommissionTeam"
import SelectShowroom from "modules/ProjectsModule/pages/Form/components/Sidebar/SelectShowroom"

import selectStyles from "./overrides/selectStyles.css"
import styles from "./styles.css"

const Aside = props => {
    const { cx } = props

    const isMobile = useMediaQuery({ query: "(max-width: 1024px)" })
    const [commissionTeam] = useField("commission_team")
    const [companyId] = useField("company.id")

    return (
        <AsideWrapper label="Team details">
            <PersonSelect
                isListOnTop={isMobile}
                name="sales_associate"
                label="Sales associate"
                customStyles={selectStyles}
                fetchPath="/user"
                exceptedValues={commissionTeam.value}
            />

            <hr className={cx("hr")} />

            <CommissionTeam
                isListOnTop={isMobile}
                name="commission_team"
                label="Commission team"
                customStyles={selectStyles}
                fetchPath="/user"
                {...props}
            />

            <hr className={cx("hr")} />

            <div className={cx("showroom")}>
                <label>Division:</label>
                <SelectShowroom
                    name="company.id"
                    value={companyId.value}
                    handleSelect={event => companyId.onChange(event)}
                />
            </div>
        </AsideWrapper>
    )
}

export default withStyles(Aside, styles)
