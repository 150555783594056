import React from "react"
import { Link, useParams } from "react-router-dom"

import useStyles from "helpers/getCxFromStyles"
import { DataLayer, ItemList, Navigation } from "modules/AttributeGroupsModules/components"
import { LIST_TYPES } from "modules/AttributeGroupsModules/constants"

import styles from "./AttributeList.css"

const AttributeList = () => {
    const cx = useStyles(styles)
    const { groupId, subgroupId } = useParams()

    const getHeaderTitle = resource => (resource && resource.name) || "Attributes"
    const parseItem = attribute => ({
        id: attribute.id,
        name: attribute.name,
        type: attribute.type,
        quantity: attribute.attribute_values_count,
        checked: false
    })

    return (
        <DataLayer
            type={LIST_TYPES.ATTRIBUTE}
            getHeaderTitle={getHeaderTitle}
            titleAddButton="Add attribute(s)"
            placeholderSearch="Search for Attributes…"
            parseItem={parseItem}
            resourceChildrenUrl="/attributes"
            resourceChildrenParams={{ group_id: subgroupId }}
            resourceChildrenModelName="Attributes"
            resourceUrl={`/attribute-groups/${subgroupId}`}
            HeaderComponent={() => (
                <div className={cx("headerList")}>
                    <div className={cx("headerListLabel")}>ATTRIBUTE NAME</div>
                    <div className={cx("headerListLabel", "visualType")}>VISUAL TYPE</div>
                    <div className={cx("headerListLabel", "values")}>VALUES</div>
                </div>
            )}
            ItemComponent={({ item, handleAction, handleCheck, isDragDisabled }) => (
                <ItemList
                    item={item}
                    onAction={handleAction}
                    onCheck={() => handleCheck(item.id)}
                    key={item.id}
                    isDragDisabled={isDragDisabled}
                >
                    <div className={cx("item")}>
                        <div className={cx("name")}>
                            <Link
                                to={`/settings/attribute-groups/${groupId}/subgroups/${subgroupId}/attributes/${item.id}/values`}
                            >
                                {item.name}
                            </Link>
                        </div>
                        <div className={cx("visualType")}>{item.type}</div>
                        <div className={cx("values")}>{item.quantity}</div>
                    </div>
                </ItemList>
            )}
            Navigation={() => (
                <Navigation.ArrowBackButton
                    url={`/settings/attribute-groups/${groupId}/subgroups`}
                    label="All Attribute Subgroups"
                />
            )}
        />
    )
}

export default AttributeList
