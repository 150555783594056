import { INPUT_TYPES } from "constants/index"

export default {
    name: "Product",
    endpoint: "/product",
    fields: [
        {
            name: "name",
            label: "Name*",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "label",
            label: "Typology label",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "price",
            label: "Retail price",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "wholesale_cost",
            label: "Wholesale cost",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "has_combinations",
            label: "Multiple combinations",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: 0,
            attributes: {}
        },
        {
            name: "discount",
            label: "Discount",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "height",
            label: "Height",
            type: INPUT_TYPES.TEXT,
            defaultValue: 0,
            attributes: {}
        },
        {
            name: "width",
            label: "Width",
            type: INPUT_TYPES.TEXT,
            defaultValue: 0,
            attributes: {}
        },
        {
            name: "depth",
            label: "Length",
            type: INPUT_TYPES.TEXT,
            defaultValue: 0,
            attributes: {}
        },
        {
            name: "weight",
            label: "Weight",
            type: INPUT_TYPES.TEXT,
            defaultValue: 0,
            attributes: {}
        },
        {
            name: "brand_id",
            label: "Brand",
            type: INPUT_TYPES.TEXT,
            defaultValue: ""
        },
        {
            name: "short_description",
            label: "Short description",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "description",
            label: "Long description",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "technical_description",
            label: "Finishes and Technical Description",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "location",
            label: "Location",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "tag",
            label: "Tag",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "active",
            label: "Active",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: false,
            attributes: {}
        },
        {
            name: "in_stock",
            label: "In Stock",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "available_on_web",
            label: "Available On Web",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: false,
            attributes: {}
        },
        {
            name: "verified",
            label: "Verified",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: false,
            attributes: {}
        },
        {
            name: "multiplier",
            label: "Multiplier",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "manufacturer_cost",
            label: "Manufacturer Cost",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "manufacturing_time_min",
            label: "Manufac. Time",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "manufacturing_time_max",
            label: "Manufac. Time",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "delivery_time_min",
            label: "",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "delivery_time_max",
            label: "",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "specification",
            label: "Download data sheet",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: null,
            attributes: {}
        },
        {
            name: "certifications",
            label: "Certifications",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "country_of_origin",
            label: "Country Of Origin",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "categories",
            label: "Categories",
            type: INPUT_TYPES.ARRAY,
            defaultValue: [],
            attributes: {}
        },
        {
            name: "images",
            label: "Images",
            type: INPUT_TYPES.ARRAY,
            defaultValue: [],
            attributes: {}
        },
        {
            name: "features",
            label: "Features",
            type: INPUT_TYPES.ARRAY,
            defaultValue: [],
            attributes: {}
        },
        {
            name: "manufacturer_id",
            label: "Manufacturer ID",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "quantity",
            label: "Quantity",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "reorderable",
            label: "Reorderable",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: true,
            attributes: {}
        },
        {
            name: "inventory",
            label: "Inventory",
            type: INPUT_TYPES.ARRAY,
            defaultValue: [],
            attributes: {}
        },
        {
            name: "production_year",
            label: "Production year",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "clearance",
            label: "Clearance",
            type: INPUT_TYPES.CHECKBOX,
            defaultValue: false,
            attributes: {}
        }
    ]
}
