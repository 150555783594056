import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"
import useInfiniteQueryListFactory from "modules/WmsModule/hooks/factories/useInfiniteQueryListFactory"

export const fetchProductInfiniteList = ({ pageParam = 1, queryKey: [_key, params = {}] }) => {
    const { length = 20, query = "", availableOnWeb = false } = params

    return fetch.get(`/products?${objToQueryString({ page: pageParam, length, query, availableOnWeb })}`)
}

export default useInfiniteQueryListFactory({
    key: "product",
    fetchFunction: fetchProductInfiniteList
})
