import { combineReducers } from "redux"

import brands from "./brands"
import categories from "./categories"
import colors from "./colors"
import enabled from "./enabled"

const availableReducer = combineReducers({
    brands,
    categories,
    colors,
    enabled
})

export default availableReducer
