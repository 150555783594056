import React, { useRef, useState, useLayoutEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"

import withStyles from "HOC/withStyles"
import { DropList } from "ui"

import {
    startModalLogic,
    removeProductFromSelection,
    showWidget,
    hideWidget
} from "modules/SelectionsWidgetModule/actions"
import Loader from "components/Reusable/Loader"
import DeleteModal from "modules/SelectionsModule/components/deleteModal"
import EditModal from "modules/SelectionsModule/components/modal"

import styles from "./Widget.css"
import stylesLoader from "./override/Loader.css"

import iconClose from "assets/close-blue.svg"
import iconLock from "assets/ico-lock.svg"
import iconLightBox from "./assets/ico-lightbox-all.svg"
import iconImage from "./assets/image.svg"

const Widget = props => {
    const {
        cx,
        current,
        products,
        openModal,
        isModalFetching,
        setOpenModal,
        handleDeleteSelection,
        handleEditSelection,
        errorEditSelection,
        setErrorEditSelection
    } = props

    const listElement = useRef()
    const dispatch = useDispatch()
    const isHidden = !useSelector(state => state.db.selectionsWidget.current.isShown)
    const [isListScrollable, setIsListScrollable] = useState(false)
    const selectionData = current.data

    useLayoutEffect(() => {
        window.addEventListener("resize", handleChangeWindowSize)
        return () => window.removeEventListener("resize", handleChangeWindowSize)
    }, [])

    useLayoutEffect(() => {
        handleChangeWindowSize()
    })

    function handleChangeWindowSize() {
        if (listElement.current) {
            setIsListScrollable(listElement.current.scrollWidth !== listElement.current.clientWidth)
        }
    }

    function renderDeleteModal() {
        return (
            <DeleteModal
                isOpen={openModal === "MODAL_DELETE"}
                selectionName={selectionData && selectionData.name}
                isLoading={isModalFetching}
                onCancel={() => setOpenModal(null)}
                onSubmit={handleDeleteSelection}
            />
        )
    }

    function renderEditModal() {
        return (
            <EditModal
                title={"Edit selection"}
                saveButtonLabel={"Save"}
                submit={data => handleEditSelection(data)}
                isFetching={isModalFetching}
                isLoading={false}
                isOpen={openModal === "MODAL_EDIT"}
                selection={selectionData}
                error={errorEditSelection}
                setError={setErrorEditSelection}
                handleClose={() => setOpenModal(null)}
            />
        )
    }

    return (
        <div className={cx("root", { isHidden })}>
            <div className={cx("tag")} onClick={() => dispatch(showWidget())}>
                <span className={cx("tagIcon")}>
                    <img src={iconLightBox} alt="" />
                </span>
                <span className={cx("tagLabel")}>My Lightbox</span>
            </div>
            <div className={cx("close")} onClick={() => dispatch(hideWidget())}>
                {products.lock_counter > 0 ? (
                    <Loader customStyles={stylesLoader} />
                ) : (
                    <img src={iconClose} alt="Close" />
                )}
            </div>
            <div className={cx("inner")}>
                <div className={cx("container")}>
                    <div className={cx("left")}>
                        <div className={cx("currentSelection")}>
                            <div className={cx("currentSelectionName")} onClick={() => dispatch(startModalLogic())}>
                                <span>{(current.data || {}).name || ""}</span>
                            </div>
                            <div className={cx("currentSelectionTrigger")}>
                                <DropList
                                    trigger={
                                        <div className={cx("editButton")}>
                                            <span />
                                            <span />
                                            <span />
                                        </div>
                                    }
                                >
                                    <DropList.List>
                                        <DropList.Item onClick={() => setOpenModal("MODAL_EDIT")}>Edit</DropList.Item>
                                        <DropList.Divider />
                                        <DropList.Item className="danger" onClick={() => setOpenModal("MODAL_DELETE")}>
                                            Delete
                                        </DropList.Item>
                                    </DropList.List>
                                </DropList>
                            </div>
                        </div>
                        <div className={cx("bar")}>
                            <div className={cx("barLeft")}>
                                <ul>
                                    {renderType()}
                                    {renderProductCount()}
                                </ul>
                            </div>
                            <div className={cx("barRight")}>
                                <span className={cx("showAll")} onClick={() => dispatch(startModalLogic())}>
                                    Show All
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={cx("right")}>
                        <div className={cx("scrollArea")}>
                            <div
                                className={cx("scrollAreaBackground", {
                                    hasBackground: !isListScrollable
                                })}
                            ></div>
                            {renderList()}
                        </div>
                    </div>
                </div>
            </div>
            {renderDeleteModal()}
            {renderEditModal()}
        </div>
    )

    function renderType() {
        const type = (current.data || {}).type

        if (!type) {
            return null
        }

        if (type === "private") {
            return (
                <li>
                    <img src={iconLock} alt="Close" />
                    <span>Private</span>
                </li>
            )
        }

        return (
            <li>
                <span>Public</span>
            </li>
        )
    }

    function renderProductCount() {
        const count = products.data.length

        return (
            <li>
                <span>
                    {count} product{count > 1 && "s"}
                </span>
            </li>
        )
    }

    function renderList() {
        return (
            <ul ref={listElement}>
                {products.fetchStatus.isLoaded &&
                    products.data.map(item => {
                        return (
                            <li key={item.id}>
                                <div className={cx("product")}>
                                    <Link to={"/products/" + item.id} className={cx("productImage")}>
                                        <img src={getImageUrl(item)} alt="" />
                                    </Link>
                                    <div
                                        className={cx("productClose")}
                                        onClick={() => dispatch(removeProductFromSelection(item))}
                                    ></div>
                                </div>
                            </li>
                        )
                    })}
            </ul>
        )
    }

    function getImageUrl(item) {
        return (item.media || {}).url || (((item.images || {}).thumb || [])[0] || {}).url || iconImage
    }
}

export default withStyles(Widget, styles)
