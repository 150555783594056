import React from "react"
import PropTypes from "prop-types"
import { Status } from "@butterfly-frontend/ui"
import STOCK_ITEM_STATUS_KEYS from "modules/WmsModule/constants/stockItemStatusKeys"
import STATUSES from "modules/WmsModule/constants/stockItemStatuses"

const StockItemStatus = ({ stockItemStatus }) => {
    const status = STATUSES[stockItemStatus]

    return status ? (
        <Status color={status.color} theme={status.theme}>
            {status.label}
        </Status>
    ) : (
        "-"
    )
}

StockItemStatus.propTypes = {
    stockItemStatus: PropTypes.oneOf(Object.values(STOCK_ITEM_STATUS_KEYS)).isRequired
}

export default StockItemStatus
