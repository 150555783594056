const ns = "calendar/"

export const GET_CALENDARS_LIST_DATA_START = ns + "GET_CALENDARS_LIST_DATA_START"
export const GET_CALENDARS_LIST_DATA_SUCCESS = ns + "GET_CALENDARS_LIST_DATA_SUCCESS"
export const GET_CALENDARS_LIST_DATA_ERROR = ns + "GET_CALENDARS_LIST_DATA_ERROR"

export const DELETE_CALENDAR_SUCCESS = ns + "DELETE_CALENDAR_SUCCESS"

export const GET_CALENDARS_ENTRIES_DATA_START = ns + "GET_CALENDARS_ENTRIES_DATA_START"
export const GET_CALENDARS_ENTRIES_DATA_SUCCESS = ns + "GET_CALENDARS_ENTRIES_DATA_SUCCESS"
export const GET_CALENDARS_ENTRIES_DATA_ERROR = ns + "GET_CALENDARS_ENTRIES_DATA_ERROR"
export const CLEAR_CALENDARS_ENTRIES = ns + "CLEAR_CALENDARS_ENTRIES"

export const SELECT_CALENDAR_TYPE = ns + "SELECT_CALENDAR_TYPE"
export const UNSELECT_CALENDAR_TYPE = ns + "UNSELECT_CALENDAR_TYPE"

export const GET_USERS_LIST_DATA_START = ns + "GET_USERS_LIST_DATA_START"
export const GET_USERS_LIST_DATA_SUCCESS = ns + "GET_USERS_LIST_DATA_SUCCESS"
export const GET_USERS_LIST_DATA_ERROR = ns + "GET_USERS_LIST_DATA_ERROR"

export const SET_ENTRY_FILTERS = ns + "SET_ENTRY_FILTERS"
export const SET_ENTRY_QUERY = ns + "SET_ENTRY_QUERY"
export const SET_ENTRY_PARAMS = ns + "SET_ENTRY_PARAMS"
export const SET_DISPLAY_ONLY_CALENDAR = ns + "SET_DISPLAY_ONLY_CALENDAR"

export const MODERATE_ENTRY_START = ns + "MODERATE_ENTRY_START"
export const MODERATE_ENTRY_SUCCESS = ns + "MODERATE_ENTRY_SUCCESS"
export const MODERATE_ENTRY_ERROR = ns + "MODERATE_ENTRY_ERROR"

export const EDIT_CALENDAR_ENTRY_REQUEST = ns + "EDIT_CALENDAR_ENTRY_REQUEST"
export const EDIT_CALENDAR_ENTRY_SUCCESS = ns + "EDIT_CALENDAR_ENTRY_SUCCESS"
export const EDIT_CALENDAR_ENTRY_ERROR = ns + "EDIT_CALENDAR_ENTRY_ERROR"

export const DELETE_CALENDAR_ENTRY_REQUEST = ns + "DELETE_CALENDAR_ENTRY_REQUEST"
export const DELETE_CALENDAR_ENTRY_SUCCESS = ns + "DELETE_CALENDAR_ENTRY_SUCCESS"
export const DELETE_CALENDAR_ENTRY_ERROR = ns + "DELETE_CALENDAR_ENTRY_ERROR"

export const CREATE_CALENDAR_ENTRY_REQUEST = ns + "CREATE_CALENDAR_ENTRY_REQUEST"
export const CREATE_CALENDAR_ENTRY_SUCCESS = ns + "CREATE_CALENDAR_ENTRY_SUCCESS"
export const CREATE_CALENDAR_ENTRY_ERROR = ns + "CREATE_CALENDAR_ENTRY_ERROR"

export const GET_MORE_CALENDARS_ENTRIES_DATA_START = ns + "GET_MORE_CALENDARS_ENTRIES_DATA_START"
export const GET_MORE_CALENDARS_ENTRIES_DATA_SUCCESS = ns + "GET_MORE_CALENDARS_ENTRIES_DATA_SUCCESS"
export const GET_MORE_CALENDARS_ENTRIES_DATA_ERROR = ns + "GET_MORE_CALENDARS_ENTRIES_DATA_ERROR"
