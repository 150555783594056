import React, { useEffect } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"

import { Dropzone } from "ui"

import videoIcon from "../../assets/video.svg"
import closeIcon from "assets/close-blue.svg"

import styles from "./styles.css"
import dropzoneStyles from "../../overrides/dropzoneStyles.css"

const Video = ({ cx, uploader: { state: uploadState, actions: uploadActions }, video, setVideo, isError }) => {
    useEffect(() => {
        if (idx(uploadState, _ => _.addedFile.url)) {
            setVideo({
                id: uploadState.addedFile.id,
                name: uploadState.addedFile.title,
                url: uploadState.addedFile.url
            })
        }
    }, [uploadState.isUploading, uploadState.isSuccess, uploadState.addedFile])

    const removeVideo = () => {
        setVideo(null)
    }

    const renderUpladingState = progress => (
        <div className={cx("dropzoneStateUploading")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    )

    const renderEmptyState = () => (
        <Dropzone
            isUploading={uploadState.isUploading}
            isSuccess={uploadState.isSuccess}
            progress={uploadState.progress}
            handleUpload={uploadActions.handleUpload}
            handleRemove={uploadActions.handleRemove}
            isMultiple={false}
            customStyles={dropzoneStyles}
            acceptFormats="video/mp4,video/webm,video/ogg,video/quicktime"
        >
            <p className={cx("dropzoneStateEmpty")}>
                <img src={videoIcon} alt="videoIcon" />
                Add video <span>(browse or drop video)</span>
            </p>
        </Dropzone>
    )

    const renderVideoState = () => (
        <div className={cx("dropzoneStateVideo")}>
            <img src={videoIcon} alt="ico" />
            <span>{video.name}</span>
            <button onClick={removeVideo}>
                <img src={closeIcon} alt="close" />
            </button>
        </div>
    )

    return (
        <div
            className={cx("dropzoneWrapper", {
                dropzoneWrapperError: isError
            })}
        >
            {uploadState.isUploading
                ? renderUpladingState(uploadState.progress)
                : !!video
                ? renderVideoState()
                : renderEmptyState()}
        </div>
    )
}

Video.propTypes = {
    uploader: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    video: PropTypes.object,
    setVideo: PropTypes.func.isRequired,
    isError: PropTypes.bool.isRequired,
    cx: PropTypes.func.isRequired
}

export default withUploader(withStyles(Video, styles))
