import React, { Fragment, useEffect, useState } from "react"
import { Formik, Form as FormikForm, Field } from "formik"
import isEqual from "lodash/isEqual"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import { scrollToTop } from "helpers/scrollTo"

import Layout from "components/Reusable/Layout"
import Sidebar from "./components/Sidebar"
import Cover from "../Cover"
import SectionList from "../SectionList"
import { SaveBar, Input, Textarea, SubmitModal } from "ui"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import { collection as collectionSchema } from "./schemas"

import styles from "./styles.css"
import layoutStyles from "./overrides/layout.css"
import inputStyles from "./overrides/input.css"
import textareaStyles from "./overrides/textarea.css"

const IMAGE_COVER_DIMENSIONS = {
    desktop: { width: 2560, height: 875 },
    tablet: { width: 1024, height: 550 },
    mobile: { width: 735, height: 550 }
}

const Form = ({
    cx,
    withHomepagePicture,
    withHomepageVisibility,
    collectionLabel,
    sectionableType,
    initialValues,
    handleSubmit,
    handleCancel,
    isSubmitting,
    isLoading,
    showSuccessNotification,
    showErrorNotification,
    sidebarImagesDimensions
}) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false)

    useEffect(() => {
        scrollToTop()
    }, [])

    const onCancel = () => {
        setShowConfirmationModal(true)
    }

    if (isLoading) {
        return (
            <Layout>
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            </Layout>
        )
    }

    const lowerCaseLabel = collectionLabel.toLowerCase()

    return (
        <Fragment>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                onSubmit={handleSubmit}
                validationSchema={collectionSchema}
            >
                {propsForm => {
                    return (
                        <Fragment>
                            <Layout
                                title={`${initialValues.id ? "Edit" : "New"} ${collectionLabel}`}
                                customStyles={layoutStyles}
                            >
                                <div className={cx("contentWrapper")}>
                                    <div className={cx("section")}>
                                        <ul>
                                            <li className={cx("collectionTitle")}>
                                                <Field name="title">
                                                    {({ field, meta }) => (
                                                        <Input
                                                            {...field}
                                                            label="Title *"
                                                            onChange={({ event }) => field.onChange(event)}
                                                            error={meta.touched && meta.error}
                                                            customStyles={inputStyles}
                                                        />
                                                    )}
                                                </Field>
                                            </li>

                                            <li className={cx("collectionSubtitle")}>
                                                <span className={cx("label")}>Subtitle:</span>
                                                <Field name="subtitle">
                                                    {({ field, meta }) => (
                                                        <Input
                                                            {...field}
                                                            customPlaceholder="e.g Brand"
                                                            onChange={({ event }) => field.onChange(event)}
                                                            error={meta.touched && meta.error}
                                                            customStyles={inputStyles}
                                                        />
                                                    )}
                                                </Field>
                                            </li>

                                            <li className={cx("collectionCover")}>
                                                <span className={cx("label")}>{collectionLabel} cover:</span>
                                                <Cover form={propsForm} imageDimensions={IMAGE_COVER_DIMENSIONS} />
                                            </li>

                                            <li className={cx("collectionDescription")}>
                                                <span className={cx("label")}>Description:</span>
                                                <Field name="description">
                                                    {({ field, meta }) => (
                                                        <Textarea
                                                            {...field}
                                                            value={field.value || ""}
                                                            label="Click here to add description..."
                                                            onChange={({ event }) => field.onChange(event)}
                                                            isPlaceholder={true}
                                                            error={meta.error}
                                                            customStyles={textareaStyles}
                                                        />
                                                    )}
                                                </Field>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <Sidebar
                                    imagesDimensions={sidebarImagesDimensions}
                                    withHomepagePicture={withHomepagePicture}
                                    withHomepageVisibility={withHomepageVisibility}
                                />
                            </Layout>

                            {!!initialValues.id && (
                                <SectionList
                                    sections={propsForm.values.sections || []}
                                    setSections={sections => propsForm.setFieldValue("sections", sections)}
                                    sectionableID={propsForm.values.id}
                                    sectionableType={sectionableType}
                                    showErrorNotification={showErrorNotification}
                                    showSuccessNotification={showSuccessNotification}
                                />
                            )}

                            <FormikForm>
                                <SaveBar
                                    isSaving={isSubmitting}
                                    isShown={!isEqual(initialValues, propsForm.values)}
                                    isDisabled={!propsForm.isValid}
                                    submitLabel="Save"
                                    message="Unsaved changes"
                                    onCancel={onCancel}
                                    isSubmit
                                />
                            </FormikForm>
                        </Fragment>
                    )
                }}
            </Formik>

            <SubmitModal
                redButton
                withLine
                isOpen={showConfirmationModal}
                header={`You have unsaved changes on this ${lowerCaseLabel}.`}
                body={`If you leave this ${lowerCaseLabel}, all unsaved changes will be lost. Are you sure you want to leave this ${lowerCaseLabel}?`}
                submitText={`Leave ${lowerCaseLabel}`}
                onClose={() => setShowConfirmationModal(false)}
                onSubmit={handleCancel}
            />
        </Fragment>
    )
}

Form.propTypes = {
    withHomepagePicture: PropTypes.bool,
    collectionLabel: PropTypes.string.isRequired,
    sectionableType: PropTypes.string.isRequired,
    initialValues: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    sidebarImagesDimensions: PropTypes.object.isRequired,
    showSuccessNotification: PropTypes.func,
    showErrorNotification: PropTypes.func,
    isSubmitting: PropTypes.bool,
    isLoading: PropTypes.bool
}

export default withStyles(Form, styles)
