import React, { Component, Fragment } from "react"
import ContentHeader from "components/Content/ContentHeader/index"
import UsersManagement from "./UsersManagement/UsersManagement"
import withPermissions from "HOC/withPermissions"
import { MENU } from "constants/index"
import { getUserManagementTabs } from "components/Content/ContentMain/Settings/helpers"

class UsersDisplay extends Component {
    render() {
        return (
            <Fragment>
                <ContentHeader title={MENU.SETTINGS_USERS_MANAGEMENT} tabs={getUserManagementTabs(this.props)} />
                <UsersManagement {...this.props} />
            </Fragment>
        )
    }
}

export default withPermissions(UsersDisplay)
