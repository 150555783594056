import React, { Component } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import idx from "idx"
import { isEmpty } from "lodash/core"

import withStyles from "HOC/withStyles"
import FilterableTable, { Content, Sort, SearchBar, Header, Table } from "ui/FilterableTable"
import Filters from "./Filters"
import styles from "./styles.css"
import { EmptyList, DateRangePicker, Pagination, Button } from "ui"
import Status from "ui/Status"
import dateRangePickerStyles from "./overrides/DateRangePicker.css"
import paginationStyles from "./overrides/Pagination.css"
import tableHeaderStyles from "./overrides/Header.css"
import filterableTableStyles from "./overrides/FilterableTable.css"
import clearButtonStyles from "./overrides/ClearButton.css"

import clockIcon from "assets/clock.svg"
import projectIcon from "assets/ico-projects.svg"
import calendarIcon from "assets/calendar.svg"
import wrenchIcon from "assets/instalation-date.svg"

class Listing extends Component {
    render() {
        const {
            cx,
            page,
            statuses,
            projects,
            created_at,
            moreFilters,
            handleChangePage,
            handleChangeTable,
            handleChangeParam,
            handleOnlyMyProjects,
            handleActionMoreFilters,
            isVisibleMoreFilters,
            toggleVisibleMoreFilters,
            clearAllFilters,
            isTabView
        } = this.props

        return (
            <div className={cx("root")}>
                <FilterableTable
                    fetchStatus={projects.fetchStatus}
                    items={projects.data}
                    meta={projects.meta}
                    handleChange={handleChangeTable}
                    customStyles={filterableTableStyles}
                >
                    {!isTabView && (
                        <Header
                            customStyles={tableHeaderStyles}
                            MoreFiltersComponent={
                                <Filters
                                    values={moreFilters}
                                    handleAction={handleActionMoreFilters}
                                    isVisible={isVisibleMoreFilters}
                                    toggleVisible={toggleVisibleMoreFilters}
                                    handleOnlyMyProjects={handleOnlyMyProjects}
                                />
                            }
                        >
                            <SearchBar placeholder="Search for project..." />
                            <Status.Select
                                allSelectedText="All statuses"
                                type="project"
                                placeholder="All statuses"
                                value={statuses}
                                onChange={value => handleChangeParam({ statuses: value })}
                            />
                            <DateRangePicker
                                ranges={created_at}
                                onChange={({ range1: date }) => handleChangeParam({ created_at: date })}
                                resetRanges={() => handleChangeParam({ created_at: { startDate: "", endDate: "" } })}
                                format="DD MMM YYYY"
                                title="Any date created"
                                icon={calendarIcon}
                                customStyles={dateRangePickerStyles}
                            />
                        </Header>
                    )}
                    <Content className={cx("content")}>
                        <Table
                            className={cx("table")}
                            renderHeader={this.renderTableHeader}
                            renderBody={this.renderTableBody}
                            customEmptyMessage={props => {
                                if (
                                    props.context.data.filters.length ||
                                    props.context.data.search ||
                                    statuses.length ||
                                    !isEmpty(created_at) ||
                                    !isEmpty(moreFilters)
                                ) {
                                    return (
                                        <EmptyList message="No project found" icon={projectIcon}>
                                            {!isTabView && (
                                                <Button
                                                    customStyles={clearButtonStyles}
                                                    className={["first-button", "white"]}
                                                    label="Clear all filters"
                                                    onClick={() => clearAllFilters()}
                                                />
                                            )}
                                        </EmptyList>
                                    )
                                }

                                return <EmptyList message="No project added" icon={projectIcon} />
                            }}
                        />
                    </Content>
                </FilterableTable>
                <Pagination
                    customStyles={paginationStyles}
                    currentPage={page}
                    pages={projects.meta.last_page || 1}
                    isLoaded={projects.fetchStatus.isLoaded}
                    onChange={handleChangePage}
                />
            </div>
        )
    }

    renderTableHeader = () => {
        return (
            <Table.Head>
                <Table.Tr>
                    <Table.Th>
                        <Sort sortBy="name" sortDirection="asc" isDefault={true}>
                            PROJECT NAME
                        </Sort>
                    </Table.Th>
                    <Table.Th>CLIENT</Table.Th>
                    <Table.Th>
                        <Sort sortBy="leader.first_name">PROJECT LEADER</Sort>
                    </Table.Th>
                    <Table.Th>
                        <Sort sortBy="created_at">CREATED</Sort>
                    </Table.Th>
                    <Table.Th>STATUS</Table.Th>
                    <Table.Th>
                        <Sort sortBy="deadline_at">DEADLINE</Sort>
                    </Table.Th>
                    <Table.Th className="textRight">
                        <Sort sortBy="installation_at">INSTALLATION</Sort>
                    </Table.Th>
                </Table.Tr>
            </Table.Head>
        )
    }

    renderTableBody = items => <Table.Body>{items.map(item => this.renderItem(item))}</Table.Body>

    renderItem = ({ id, name, address, client, created_at, installation_at, deadline_at, leader, status }) => {
        const { cx } = this.props

        const [firstNameClient, lastNameClient, companyClient, isVipClient] = [
            idx(client, _ => _.first_name),
            idx(client, _ => _.last_name),
            idx(client, _ => _.main_address.company),
            idx(client, _ => _.vip)
        ]

        const [firstNameLeader, lastNameLeader] = [idx(leader, _ => _.first_name), idx(leader, _ => _.last_name)]

        const formattedAddress = this.formatAddress(address || {})

        return (
            <Table.Tr key={id}>
                <Table.Td>
                    <Link to={`/projects/${id}`}>
                        <p className={cx("table-id")}>{name}</p>
                    </Link>
                    {formattedAddress && <span className={cx("additionalInfo")}>{formattedAddress}</span>}
                </Table.Td>
                <Table.Td>
                    <p className={cx("table-name")}>
                        {firstNameClient} {lastNameClient}
                        {isVipClient && <span className={cx("vip")}>vip</span>}
                    </p>
                    {companyClient && <span className={cx("additionalInfo")}>{companyClient}</span>}
                </Table.Td>
                <Table.Td>
                    <p>
                        {firstNameLeader} {lastNameLeader}
                    </p>
                </Table.Td>
                <Table.Td className={cx("isIcon")}>
                    <img src={clockIcon} alt="icon" />
                    <p>{moment(created_at).format("DD MMM YYYY")}</p>
                </Table.Td>
                <Table.Td>
                    <Status.Label type="project" status={status} />
                </Table.Td>
                <Table.Td className={cx("isIcon")}>
                    <img src={clockIcon} alt="icon" />
                    <p>{moment(deadline_at).format("DD MMM YYYY")}</p>
                </Table.Td>
                <Table.Td className={cx("isIcon")}>
                    <img src={wrenchIcon} alt="icon" />
                    <p>{moment(installation_at).format("DD MMM YYYY")}</p>
                </Table.Td>
            </Table.Tr>
        )
    }

    formatAddress = ({ address, city, state }) => {
        let formatted = ""

        if (address) formatted += address

        if (address && city) formatted += " "

        if (city) formatted += city

        if ((address || city) && state) formatted += ", "

        if (state) formatted += state

        return formatted
    }
}

export default withStyles(Listing, styles)
