import { DEFAULT_CURRENCY } from "constants/index"

export const formatPrice = (price, hidePrefix, hideLowerThanZero = false) => {
    if ((!price && typeof price !== "number") || isNaN(price) || (hideLowerThanZero && price < 0)) {
        return null
    }

    price = Number.parseFloat(price)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")

    if (hidePrefix) {
        return price
    }

    return `${DEFAULT_CURRENCY} ${price}`
}

export const formatPriceByComa = (price, beforeComa) => {
    if (isNaN(price)) {
        return null
    }

    price = Number.parseFloat(price)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .split(".")

    if (beforeComa) return price[0]
    return price[1]
}

export const roundNumber = (num, dec = 2) => {
    const num_sign = num >= 0 ? 1 : -1
    return parseFloat((Math.round(num * Math.pow(10, dec) + num_sign * 0.0001) / Math.pow(10, dec)).toFixed(dec))
}

export const formatDimension = (value, isImperial = false) => {
    value = isImperial ? (value / 2.54).toFixed(2) : value
    return `${value} ${isImperial ? "in." : "cm"}`
}

export const formatDimensionToImperial = (value, isImperial = true) => (isImperial ? (value / 2.54).toFixed(2) : value)

export const formatDimensionToMetric = (value, isMetric = true) => (isMetric ? (value * 2.54).toFixed(2) : value)

export const formatWeightToImperial = (value, isImperial = true) =>
    isImperial ? (value / 0.45359237).toFixed(2) : value

export const formatWeightToMetric = (value, isMetric = true) => (isMetric ? (value * 0.45359237).toFixed(2) : value)

export const formatWeight = (value, isImperial = false) => {
    value = isImperial ? (value / 0.45359237).toFixed(2) : value
    return `${value} ${isImperial ? "lb" : "kg"}`
}

export const escapeAmountValue = value => {
    const escapedValue = value.replace(/,/g, "").replace(/\s/g, "")
    if (isNaN(escapedValue)) {
        return undefined
    }
    return escapedValue
}
