import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"

import styles from "./FormColumn.module.css"

const FormColumn = ({ children, className }) => (
    <div className={classNames(styles.formColumn, className)}>{children}</div>
)

FormColumn.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    className: PropTypes.string
}

export default FormColumn
