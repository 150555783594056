import * as constants from "constants/index"

function current(state = [], action) {
    switch (action.type) {
        case constants.GET_CURRENT_FOLDER_IMAGES_START:
            return { ...state, isLoading: true, data: [] }
        case constants.GET_CURRENT_FOLDER_IMAGES_SUCCESS:
            return { ...state, isLoading: false, data: [...action.payload.data.data.images] }
        case constants.CHANGE_PRODUCT_IMAGES_COUNT:
            const { nextValue, transferFromProduct } = action.payload

            if (nextValue >= 0 || !transferFromProduct) {
                return state
            }

            const { imageId, url, name } = transferFromProduct

            return {
                ...state,
                data: [
                    { id: imageId, position: null, title: null, type: "image", url: url, name: name },
                    ...state.data.filter(item => item.id !== imageId)
                ]
            }

        case constants.GET_CURRENT_FOLDER_IMAGES_ERROR:
            return { ...state, isLoading: false, data: [] }

        default:
            return state
    }
}

export default current
