import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { StatusSelect } from "@butterfly-frontend/ui"

import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"

import useSelectProductConditions from "./useSelectProductConditions"

const StatusSelectProductConditions = props => {
    const { value, onChange, conditionType, excludedTypes, disabled, isLoading } = props
    const { values, fetchValues } = useSelectProductConditions({ conditionType, excludedTypes })

    useEffect(() => {
        fetchValues()
    }, [])

    return <StatusSelect value={value} options={values} onChange={onChange} disabled={disabled} isLoading={isLoading} />
}

const conditionPropTypes = PropTypes.oneOf(Object.values(CONDITION_TYPES))

StatusSelectProductConditions.propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    conditionType: conditionPropTypes,
    excludedTypes: PropTypes.arrayOf(conditionPropTypes),
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool
}

export default StatusSelectProductConditions
