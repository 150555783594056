import React, { Component } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"

import withPermissions from "HOC/withPermissions"
import ListingLayout from "../../NewsListingLayout"
import Listing from "./Listing"
import Button from "components/Reusable/Form/Button/index"
import * as tagsActions from "actions/tags"
import * as newsActions from "actions/news"
import fetch from "helpers/fetch"

import { TAG_TYPES } from "constants/index"

class ListingContainer extends Component {
    state = {
        listingActiveStyle: "tiles",
        searchParams: {
            search: "",
            sorting: {
                by: "updated_at",
                direction: "desc"
            }
        },
        tagId: null,
        filterValue: null,
        isModalOpen: false,
        deletedPostId: null,
        isBlog: false,
        isPortfolio: false,
        isDeleting: false
    }

    componentDidMount() {
        const { getPosts, getTags, routePrefix } = this.props
        const isBlog = routePrefix.includes("content/blog")
        const isPortfolio = routePrefix.includes("content/portfolio")

        if (isBlog) {
            this.setState({
                isBlog: true
            })
        }
        if (isPortfolio) {
            this.setState({
                isPortfolio: true
            })
        }

        getPosts({
            isBlog,
            isPortfolio,
            searchParams: `&sort_by=${this.state.searchParams.sorting.by}&sort_direction=${this.state.searchParams.sorting.direction}`
        })
        getTags(isBlog)
    }

    render() {
        const {
            listingActiveStyle,
            filterValue,
            isModalOpen,
            isBlog,
            isPortfolio,
            isDeleting,
            deletedPostId
        } = this.state
        const { tagsList, postsList, meta, isPostsLoading, canEdit, PERMISSIONS } = this.props
        const deletedPost = postsList.filter(post => post.id === deletedPostId)[0]
        const modalData = {
            title: "Delete this post?",
            description: "Are you sure you want to delete the post",
            subject: deletedPost && deletedPost.title,
            descriptionClosure: "? This action cannot be reversed.",
            handleHideModal: this.handleToggleModal,
            handleDelete: this.handleDeletePost,
            isLoading: isDeleting
        }

        let ctaLink = "/news"
        let userCanEdit = canEdit(PERMISSIONS.context.NEWS)
        let buttonLabel = "Create New Post"
        if (isBlog) {
            ctaLink = "/content/blog"
            userCanEdit = canEdit(PERMISSIONS.context.BLOG_POSTS)
        }
        if (isPortfolio) {
            ctaLink = "/content/portfolio"
            userCanEdit = canEdit(PERMISSIONS.context.PORTFOLIO_POSTS)
            buttonLabel = "Add New Project"
        }

        return (
            <ListingLayout
                cta={[
                    null,
                    userCanEdit ? (
                        <Link to={ctaLink + "/add"}>
                            <Button className="first-button" label={buttonLabel} />
                        </Link>
                    ) : null
                ]}
                isBlog={isBlog}
                isPortfolio={isPortfolio}
            >
                <Listing
                    tagsList={tagsList}
                    postsList={postsList}
                    meta={meta}
                    filterValue={filterValue}
                    isPostsLoading={isPostsLoading}
                    isModalOpen={isModalOpen}
                    isBlog={isBlog}
                    isPortfolio={isPortfolio}
                    listingActiveStyle={listingActiveStyle}
                    userCanEdit={userCanEdit}
                    deletedPostId={deletedPostId}
                    modalData={modalData}
                    handleGoToEdit={this.handleGoToEdit}
                    handleTableChange={this.handleTableChange}
                    handleSelectFilter={this.handleSelectFilter}
                    handleSetListingStyle={this.handleSetListingStyle}
                    handleGetPostsByTag={this.handleGetPostsByTag}
                    handleToggleModal={this.handleToggleModal}
                    handleSelectPage={this.handleSelectPage}
                />
            </ListingLayout>
        )
    }

    handleSetListingStyle = style => {
        this.setState({
            listingActiveStyle: style
        })
    }

    handleTableChange = (params, isInit) => {
        const { getPosts } = this.props
        const { isPortfolio, searchParams } = this.state

        const reducedParams = {
            search: params.search,
            sorting: {
                by: params.sorting.by,
                direction: params.sorting.direction
            }
        }
        const { search, sorting } = reducedParams
        const requestQuery = `&query=${search}&sort_by=${sorting.by}&sort_direction=${sorting.direction}`
        const isTableChanged = JSON.stringify(searchParams) !== JSON.stringify(reducedParams)

        if (isTableChanged) {
            this.setState({
                searchParams: {
                    search: params.search,
                    sorting: params.sorting
                }
            })
            getPosts({ isPortfolio, searchParams: requestQuery })
        }
    }

    handleGetPostsByTag = ({ id }) => {
        const { filterValue } = this.state
        const { getPosts } = this.props
        const { isBlog, isPortfolio } = this.state

        this.setState({
            tagId: id
        })

        getPosts({ tagId: id, isBlog, isPortfolio, filterValue })
    }

    handleToggleModal = id => {
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            deletedPostId: id
        })
    }

    handleDeletePost = () => {
        const { deletedPostId, isBlog, isPortfolio, filterValue } = this.state
        const { getPosts } = this.props

        let path = "/news/"
        if (isBlog) {
            path = "/posts/"
        }
        if (isPortfolio) {
            path = "/realizations/"
        }
        this.setState({
            isDeleting: true
        })

        fetch.deleteRAW(path + deletedPostId).then(() => {
            getPosts({ isBlog, isPortfolio, filterValue })
            this.setState({
                isModalOpen: !this.state.isModalOpen,
                deletedPostId: null,
                isDeleting: false
            })
        })
    }

    handleSelectPage = page => {
        const { getPosts } = this.props
        const { isBlog, isPortfolio, filterValue } = this.state

        getPosts({ page: page + 1, isBlog, isPortfolio, filterValue })
    }

    handleSelectFilter = filterValue => {
        const { tagId, isBlog } = this.state
        const { getPosts } = this.props
        this.setState({
            filterValue
        })

        getPosts({
            tagId,
            isBlog,
            filterValue,
            searchParams: `&sort_by=${this.state.searchParams.sorting.by}&sort_direction=${this.state.searchParams.sorting.direction}`
        })
    }
}

const mapStateToProps = state => {
    return {
        tagsList: state.db.tags.list.data,
        postsList: state.db.news.list.data,
        meta: state.db.news.list.meta,
        isPostsLoading: state.db.news.list.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTags: isBlog => dispatch(tagsActions.getTags(isBlog ? TAG_TYPES.BLOG : TAG_TYPES.NEWS)),
        getPosts: ({ page, tagId, isBlog, isPortfolio, filterValue, searchParams }) =>
            dispatch(newsActions.getPosts({ page, tagId, isBlog, isPortfolio, filterValue, searchParams }))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withPermissions(ListingContainer))
