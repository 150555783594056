import React from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "../CalendarList.css"

const ButtonShowHide = ({ cx, className, isActive, isDisabled, onClick }) => {
    const disabled = isDisabled ? "disabled-element" : ""
    const active = isActive ? "Hide all" : "Show all"

    return (
        <button className={cx("calendar-list-header-button", { disabled }, { className })} onClick={onClick}>
            {active}
        </button>
    )
}

ButtonShowHide.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired
}

export default withStyles(ButtonShowHide, styles)
