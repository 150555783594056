import React, { useState, useEffect, useRef } from "react"
import debounce from "lodash.debounce"
import idx from "idx"

import withStyles from "HOC/withStyles"

import styles from "./SearchBar.css"
import search from "assets/search.svg"
import close from "assets/close.svg"

const SearchBar = ({ cx, value, valueRef, placeholder, handleSearch, delay = 200 }) => {
    const [text, setText] = useState(value)

    const inputRef = useRef()

    const debouncedHandler = useRef(debounce(targetValue => handleSearch(targetValue), delay)).current

    useEffect(() => {
        setText(value)
    }, [valueRef || value])

    useEffect(() => {
        return () => debouncedHandler.cancel()
    }, [])

    const handleOnChange = event => {
        const targetValue = idx(event, _ => _.target.value) || ""

        setText(targetValue)
        debouncedHandler(targetValue)
    }

    const handleClearInput = () => {
        setText("")
        handleSearch("")
        inputRef.current && inputRef.current.focus()
    }

    return (
        <div className={cx("root", { isFilled: !!text })}>
            <input
                ref={inputRef}
                tabIndex="-1"
                type="text"
                className={cx("input")}
                placeholder={placeholder || ""}
                value={text || ""}
                onChange={handleOnChange}
                required="required"
            />
            <img src={search} alt="search" className={cx("search-icon")} />
            <img src={close} alt="close" className={cx("close-icon")} onClick={handleClearInput} />
        </div>
    )
}

export default withStyles(SearchBar, styles)
