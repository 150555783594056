import React, { Component } from "react"
import { connect } from "react-redux"
import * as roleActions from "actions/role"
import RolesList from "../RolesList/RolesList"

class RolesListCover extends Component {
    componentDidMount() {
        this.props.getRoles()
    }

    render() {
        const { deleteRole, roleStore } = this.props
        return <RolesList {...roleStore} deleteThisRole={index => deleteRole(index)} />
    }
}

const mapStateToProps = state => {
    return {
        roleStore: state.roleStore
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getRoles: () => dispatch(roleActions.getRoles()),
        deleteRole: index => dispatch(roleActions.deleteRole(index))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RolesListCover)
