import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import {
    Table,
    EmptyList,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRowWithExpandedDetails,
    TableCell,
    Status,
    TableDateCell,
    GridIcon,
    AlertErrorIcon,
    TableCellWithExpandButton,
    SnakeLoader
} from "@butterfly-frontend/ui"

import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"
import { formatDate } from "helpers/date"
import CLIENT_RETURN_SHIPMENT_STATUSES from "modules/WmsModule/constants/clientReturnShipmentStatuses"
import { checkIfShipmentIsCancelled } from "modules/WmsModule/helpers/shipment"
import SalesOrderReturnDetails from "modules/OrdersModule/components/SalesOrderReturnDetails"

import styles from "./List.module.css"

const TABLE_COLUMNS = {
    no: {
        minWidth: 30,
        width: 30
    },
    productName: {
        minWidth: 150,
        width: "30%"
    },
    origin: {
        minWidth: 50,
        width: "15%"
    },
    shipTo: {
        minWidth: 50,
        width: "15%"
    },
    createdAt: {
        minWidth: 75,
        width: "17%"
    },
    status: {
        minWidth: 110,
        width: "15%"
    },
    detailsButton: {
        minWidth: 85,
        width: 85
    }
}

const List = ({ data, fetchStatus, onShipmentStatusChanged, initialExpandedShipmentId, canEdit }) => {
    const [expandedRowId, setExpandedRowId] = useState(initialExpandedShipmentId)

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (initialExpandedShipmentId && fetchStatus.isLoaded) {
                const el = document.querySelector(`#id-${initialExpandedShipmentId}`)
                el && el.scrollIntoView({ behavior: "smooth", block: "center" })
            }
        }, 1500)

        return () => clearTimeout(timeout)
    }, [fetchStatus.isLoaded])

    if (fetchStatus.isLoading) {
        return <SnakeLoader />
    }
    if (fetchStatus.isLoaded && data.length === 0) {
        return <EmptyList Icon={GridIcon} label="List is empty." />
    }

    return (
        <Table classes={{ root: styles.table }}>
            <TableHead>
                <TableHeadCell {...TABLE_COLUMNS.no}>#</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.productName}>PRODUCT NAME</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.origin}>FROM</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.shipTo}>TO</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.createdAt}>DATE</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.status}>STATUS</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.detailsButton} />
            </TableHead>
            <TableBody>
                {fetchStatus.isLoaded &&
                    data.map((returnItem, index) => {
                        const status = CLIENT_RETURN_SHIPMENT_STATUSES[returnItem.status.status]
                        const isStatusCancelled = checkIfShipmentIsCancelled({
                            shipmentStatus: status.value,
                            isClientReturn: true
                        })

                        const rowClasses = { root: { [styles.inactiveRow]: isStatusCancelled } }
                        const cellClasses = {
                            root: [styles.cell, { [styles.inactiveCell]: isStatusCancelled }]
                        }
                        const idCellClasses = {
                            root: [styles.idCell, { [styles.alertCell]: isStatusCancelled }]
                        }

                        return (
                            <TableRowWithExpandedDetails
                                id={`id-${returnItem.id}`}
                                key={returnItem.id}
                                isExpanded={expandedRowId === returnItem.id}
                                classes={rowClasses}
                                detailsComponent={
                                    <SalesOrderReturnDetails
                                        shipmentId={returnItem.id}
                                        onStatusChanged={onShipmentStatusChanged}
                                        isEditingStatusEnabled={canEdit()}
                                    />
                                }
                            >
                                <TableCell {...TABLE_COLUMNS.no} classes={{ root: styles.ordinalNumberCell }}>
                                    {index + 1}
                                </TableCell>
                                <TableCell {...TABLE_COLUMNS.productName} classes={idCellClasses}>
                                    {isStatusCancelled && <AlertErrorIcon className={styles.alertErrorIcon} />}
                                    {returnItem.uuid}
                                </TableCell>
                                <TableCell {...TABLE_COLUMNS.origin} classes={cellClasses}>
                                    {returnItem.origins}
                                </TableCell>
                                <TableCell {...TABLE_COLUMNS.shipTo} classes={cellClasses}>
                                    {returnItem.destination_warehouse.name}
                                </TableCell>
                                <TableDateCell {...TABLE_COLUMNS.createdAt} classes={cellClasses}>
                                    {formatDate(returnItem.created_at)}
                                </TableDateCell>
                                <TableCell {...TABLE_COLUMNS.status} classes={cellClasses}>
                                    <Status theme={status.theme} color={status.color}>
                                        {status.label}
                                    </Status>
                                </TableCell>
                                <TableCellWithExpandButton
                                    isExpanded={expandedRowId === returnItem.id}
                                    classes={cellClasses}
                                    onClick={() =>
                                        setExpandedRowId(prevState =>
                                            prevState !== returnItem.id ? returnItem.id : null
                                        )
                                    }
                                />
                            </TableRowWithExpandedDetails>
                        )
                    })}
            </TableBody>
        </Table>
    )
}

List.propTypes = {
    fetchStatus: PropTypes.shape({
        isLoading: PropTypes.bool.isRequired,
        isLoaded: PropTypes.bool.isRequired
    }).isRequired,
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            uuid: PropTypes.string.isRequired,
            origins: PropTypes.string.isRequired,
            destination_warehouse: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            created_at: PropTypes.string.isRequired,
            status: PropTypes.shape({
                status: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    ).isRequired,
    onShipmentStatusChanged: PropTypes.func,
    initialExpandedShipmentId: PropTypes.number,
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(List, PERMISSIONS.context.ORDER_SHIPMENTS)
