import React from "react"
import PropTypes from "prop-types"

import { Input } from "ui"
import { PERMISSIONS } from "constants/index"
import withPermissions from "HOC/withPermissions"
import getInputPropsFactory from "modules/WmsModule/helpers/getInputPropsFactory"
import { FormSection, FormRow, FormColumn } from "modules/WmsModule/modals/components"

const WarehouseManagerSectionForm = ({ formik, canEdit, isEdit }) => (
    <FormSection title="Warehouse Manager" withBorder>
        {({ inputClass }) => {
            const getInputProps = getInputPropsFactory(formik, inputClass)

            return (
                <React.Fragment>
                    <FormRow>
                        <FormColumn>
                            <Input
                                {...getInputProps("manager.name")}
                                label="Name"
                                customPlaceholder="Name"
                                isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                            />
                        </FormColumn>
                        <FormColumn>
                            <Input
                                {...getInputProps("manager[job_title]")}
                                label="Job title"
                                customPlaceholder="Job title"
                                isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn>
                            <Input
                                {...getInputProps("manager.phone", {
                                    onChange: ({ event }) => formik.setFieldValue("manager.phone", event.target.value)
                                })}
                                label="Phone number"
                                customPlaceholder="Phone number"
                                isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                isPhoneInput
                            />
                        </FormColumn>
                        <FormColumn>
                            <Input
                                {...getInputProps("manager.mobile", {
                                    onChange: ({ event }) => formik.setFieldValue("manager.mobile", event.target.value)
                                })}
                                label="Mobile phone number"
                                customPlaceholder="Mobile phone number"
                                isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                                isPhoneInput
                            />
                        </FormColumn>
                    </FormRow>
                    <FormRow>
                        <FormColumn>
                            <Input
                                {...getInputProps("manager.email")}
                                label="E-mail address"
                                customPlaceholder="E-mail"
                                isDisabled={formik.isSubmitting || (isEdit && !canEdit())}
                            />
                        </FormColumn>
                    </FormRow>
                </React.Fragment>
            )
        }}
    </FormSection>
)

const managerPropTypes = PropTypes.shape({
    manager: PropTypes.shape({
        name: PropTypes.string,
        job_title: PropTypes.string,
        phone: PropTypes.string,
        mobile: PropTypes.string,
        email: PropTypes.string
    })
})

WarehouseManagerSectionForm.propTypes = {
    formik: PropTypes.shape({
        handleChange: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        isSubmitting: PropTypes.bool,
        values: managerPropTypes,
        errors: managerPropTypes
    }),
    isEdit: PropTypes.bool,
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(WarehouseManagerSectionForm, PERMISSIONS.context.WMS)
