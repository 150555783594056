import { combineReducers } from "redux"

import current from "./current"
import lastFetched from "./lastFetched"
import list from "./list"

export default combineReducers({
    current,
    lastFetched,
    list
})
