import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    fetchStatus: fetchStatus.initialState()
}

export default (state = initialState, action) => {
    switch (action.type) {
        case constants.GET_PAYMENTS_START:
            return { ...state, fetchStatus: fetchStatus.requestState() }

        case constants.GET_PAYMENTS_SUCCESS:
            return { ...action.payload, fetchStatus: fetchStatus.successState() }

        case constants.GET_PAYMENTS_ERROR:
            return { ...state, fetchStatus: { ...fetchStatus.failureState(), error: action.error } }

        case constants.UPDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                data: state.data.map(payment => (payment.id === action.payload.id ? action.payload : payment)),
                fetchStatus: fetchStatus.successState()
            }

        case constants.DELETE_PAYMENT_SUCCESS:
            return {
                ...state,
                data: state.data.filter(({ id }) => id !== action.id),
                fetchStatus: fetchStatus.successState()
            }

        default:
            return state
    }
}
