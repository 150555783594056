import React, { useEffect, useRef, useState } from "react"
import debounce from "lodash.debounce"

import withStyles from "HOC/withStyles"
import fetch from "helpers/fetch"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useActions } from "hooks"

import { Button } from "ui"
import { DragAndDropList } from "modules/ContentModule/components"
import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"
import AddEditModal from "./components/AddEditModal"

import styles from "./Download.css"

import { DEBOUNCE_DRAG_AND_DROP_REQUEST, DOCUMENT_AVAILABILITY_LEVELS } from "constants/index"

function Download(props) {
    const { cx, product, fetchParent } = props

    const actions = useActions({ showSuccessNotification, showErrorNotification })

    const [list, setList] = useState([])
    const [isUpdatingOrder, setIsUpdatingOrder] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(null)
    const [itemToEdit, setItemToEdit] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setList(product.public_attachments)
    }, [product.public_attachments])

    const remove = async id => {
        setIsDeleting(true)

        try {
            await fetch.patch(`/products/${product.id}`, {
                publicAttachments: product.public_attachments.filter(item => item.id !== id)
            })
            fetchParent()
            actions.showSuccessNotification()
        } catch (error) {
            actions.showErrorNotification()
        }

        setIsDeleting(false)
        setItemToDelete(null)
    }

    const save = async data => {
        setIsSubmitting(true)
        setErrors({})

        try {
            await fetch.patch(`/products/${product.id}`, {
                publicAttachments: data.id
                    ? [...product.public_attachments.filter(item => item.id !== data.id), data]
                    : [...product.public_attachments, data]
            })
            fetchParent()
            actions.showSuccessNotification()
            setItemToEdit(null)
        } catch (error) {
            actions.showErrorNotification()

            setErrors(
                Object.entries(error.errors || {}).reduce((acc, item) => {
                    const [field, message] = item

                    const finalFieldName = ["attachment", "level", "description"].find(name => field.endsWith(name))
                    if (!!finalFieldName) {
                        return {
                            ...acc,
                            [finalFieldName]: message[0].replace(field, finalFieldName)
                        }
                    }

                    return acc
                }, {})
            )
        }

        setIsSubmitting(false)
    }

    const debouncedUpdateListOrder = useRef(
        debounce(items => {
            setIsUpdatingOrder(true)
            fetch
                .postRAW("/multi-actions", {
                    ids: items.map(item => item.id),
                    model: "ProductPublicAttachments",
                    action: "sort"
                })
                .then(() => actions.showSuccessNotification())
                .catch(() => actions.showErrorNotification())
                .finally(() => setIsUpdatingOrder(false))
        }, DEBOUNCE_DRAG_AND_DROP_REQUEST)
    ).current

    const onReorder = items => {
        setList(items)
        debouncedUpdateListOrder(items)
    }

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                <Button
                    className={cx("first-button", "white", "addButton")}
                    label="Add file"
                    onClick={() => setItemToEdit({})}
                />
            </div>

            {list.length > 0 && (
                <div className={cx("list")}>
                    <div className={cx("row", "rowHeader")}>
                        <div className={cx("description")}>DESCRIPTION</div>
                        <div className={cx("level")}>LEVEL</div>
                        <div className={cx("actions")} />
                    </div>
                    <DragAndDropList itemsList={list} handleReorder={onReorder} disabled={isUpdatingOrder}>
                        {list.map(item => (
                            <div key={item.id} className={cx("row")}>
                                <div className={cx("description")}>{item.description}</div>
                                <div className={cx("level")}>
                                    {
                                        (DOCUMENT_AVAILABILITY_LEVELS.find(level => level.value === item.level) || {})
                                            .label
                                    }
                                </div>
                                <div className={cx("actions")}>
                                    <Button className={cx("edit")} label="Edit" onClick={() => setItemToEdit(item)} />
                                    <Button
                                        className={cx("delete")}
                                        label="Delete"
                                        onClick={() => setItemToDelete(item)}
                                    />
                                </div>
                            </div>
                        ))}
                    </DragAndDropList>
                </div>
            )}

            {!!itemToDelete && (
                <Modal>
                    <Confirm
                        confirmModalTitle="Are you sure you want to delete? This action cannot be reversed."
                        handleHideModal={() => setItemToDelete(null)}
                        handleDelete={() => remove(itemToDelete.id)}
                        isLoading={isDeleting}
                    />
                </Modal>
            )}

            {!!itemToEdit && (
                <AddEditModal
                    initialValues={itemToEdit}
                    onClose={() => setItemToEdit(null)}
                    onSave={save}
                    isSubmitting={isSubmitting}
                    errors={errors}
                />
            )}
        </div>
    )
}

export default withStyles(Download, styles)
