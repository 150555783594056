import React from "react"
import { connect } from "react-redux"
import MainPage from "./MainPage"

import * as actions from "actions/homePage"
import * as notificationActions from "actions/notification"

const MainPageContainer = props => {
    return <MainPage {...props} />
}

const mapStateToProps = state => ({
    mainPages: state.db.homePages.current,
    collection: state.db.homePages.collection,
    post: state.db.homePages.post,
    realization: state.db.homePages.realization,
    immediateDelivery: state.db.homePages.immediateDelivery,
    ourFavourite: state.db.homePages.ourFavourite,
    header: state.db.homePages.header
})

const mapDispatchToProps = dispatch => ({
    getData: () => dispatch(actions.getHomePage()),
    updateHomePage: body => dispatch(actions.updateHomePage(body)),
    getCollection: id => dispatch(actions.getHomePageCollection(id)),
    createCollection: body => dispatch(actions.createHomePageCollection(body)),
    updateCollection: (id, data) => dispatch(actions.updateHomePageCollection(id, data)),
    deleteCollection: id => dispatch(actions.deleteHomePageCollection(id)),
    getPost: id => dispatch(actions.getHomePagePost(id)),
    createPost: body => dispatch(actions.createHomePagePost(body)),
    updatePost: (id, data) => dispatch(actions.updateHomePagePost(id, data)),
    deletePost: id => dispatch(actions.deleteHomePagePost(id)),
    getRealization: id => dispatch(actions.getHomePageRealization(id)),
    createRealization: body => dispatch(actions.createHomePageRealization(body)),
    updateRealization: (id, data) => dispatch(actions.updateHomePageRealization(id, data)),
    deleteRealization: id => dispatch(actions.deleteHomePageRealization(id)),
    showErrorNotification: () => dispatch(notificationActions.showErrorNotification()),
    showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
    createImmediateDelivery: body => dispatch(actions.createHomePageImmediateDelivery(body)),
    deleteImmediateDelivery: id => dispatch(actions.deleteHomePageImmediateDelivery(id)),
    getImmediateDelivery: id => dispatch(actions.getHomePageImmediateDelivery(id)),
    updateImmediateDelivery: (id, data) => dispatch(actions.updateHomePageImmediateDelivery(id, data)),
    createOurFavourites: body => dispatch(actions.createHomePageOurFavourites(body)),
    getOurFavourite: id => dispatch(actions.getHomePageOurFavourite(id)),
    updateOurFavourite: (id, body) => dispatch(actions.updateHomePageOurFavourite(id, body)),
    deleteOurFavourite: id => dispatch(actions.deleteHomePageOurFavourite(id)),
    createHomepageHeader: body => dispatch(actions.createHomePageHeader(body)),
    updateHomepageHeader: (id, body) => dispatch(actions.updateHomePageHeader(id, body)),
    deleteHomepageHeader: id => dispatch(actions.deleteHomePageHeader(id)),
    getHeader: id => dispatch(actions.getHomePageHeader(id))
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MainPageContainer)
