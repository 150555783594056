import React, { useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import get from "lodash/get"

import { Panel, DetailsCard, Status, StatusSelect } from "@butterfly-frontend/ui"

import { useShipmentStatusEdit } from "modules/WmsModule/hooks"
import { CancelShipmentModal } from "modules/WmsModule/modals"
import CLIENT_RETURN_SHIPMENT_STATUSES from "modules/WmsModule/constants/clientReturnShipmentStatuses"
import CLIENT_RETURN_SHIPMENT_STATUS_SETTER_OPTIONS from "modules/WmsModule/constants/clientReturnShipmentStatusSetterOptions"
import { ShipmentAddress } from "modules/WmsModule/components"
import { ADDRESS_PROP_TYPE } from "modules/WmsModule/propTypes"
import {
    checkIfShipmentIsCancelled,
    checkIfCanChangeClientReturnShipmentStatus
} from "modules/WmsModule/helpers/shipment"

import styles from "./SalesOrderReturnDetailsHeader.module.css"

const SalesOrderReturnDetailsHeader = ({ shipment, onStatusChanged, isEditingStatusEnabled }) => {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [newStatus, setNewStatus] = useState(null)

    const { handleUpdateShipmentStatus, isStatusLoading } = useShipmentStatusEdit({
        id: shipment.id,
        newStatus,
        setIsConfirmationModalOpen,
        onStatusChanged: (...args) => {
            setIsConfirmationModalOpen(false)
            if (onStatusChanged) {
                onStatusChanged(...args)
            }
        },
        onError: () => setNewStatus(null)
    })

    const onConfirmationDenied = () => {
        setIsConfirmationModalOpen(false)
        setNewStatus(null)
    }

    const isCancelled = checkIfShipmentIsCancelled({ shipmentStatus: shipment.status.status, isClientShipment: true })
    const detailsCardClasses = { root: [styles.detailsCard, { [styles.inactiveCard]: isCancelled }] }
    const isStatusChangeEnabled =
        checkIfCanChangeClientReturnShipmentStatus({
            shipmentStatus: shipment.status.status
        }) && isEditingStatusEnabled
    const status = CLIENT_RETURN_SHIPMENT_STATUSES[shipment.status.status]

    return (
        <Panel classes={{ panel: styles.container }}>
            <DetailsCard header="PARENT SO" classes={detailsCardClasses}>
                <Link to={`/orders/sales-orders/${shipment.order.id}`} className={styles.link}>
                    {shipment.order.uuid}
                </Link>
            </DetailsCard>
            <DetailsCard header="ORIGIN" classes={detailsCardClasses}>
                {shipment.origins}
            </DetailsCard>
            <DetailsCard header="SHIP TO" classes={detailsCardClasses}>
                <p>{shipment.destination_warehouse.name}</p>
                <ShipmentAddress shipmentAddress={shipment.destination_warehouse.address} />
            </DetailsCard>
            <DetailsCard header="SHIPPING STATUS" classes={{ root: styles.detailsCard }}>
                {isStatusChangeEnabled ? (
                    <StatusSelect
                        value={status}
                        options={CLIENT_RETURN_SHIPMENT_STATUS_SETTER_OPTIONS}
                        onChange={({ value }) => setNewStatus(value)}
                        isLoading={isStatusLoading}
                    />
                ) : (
                    <Status theme={status.theme} color={status.color}>
                        {status.label}
                    </Status>
                )}
            </DetailsCard>
            <DetailsCard header="REASON" classes={detailsCardClasses}>
                {get(shipment, "reason.reason", "")}
            </DetailsCard>
            {isConfirmationModalOpen && (
                <CancelShipmentModal
                    onCancel={onConfirmationDenied}
                    onAction={handleUpdateShipmentStatus}
                    isLoading={isStatusLoading}
                    returnDestination="client"
                />
            )}
        </Panel>
    )
}

SalesOrderReturnDetailsHeader.propTypes = {
    shipment: PropTypes.shape({
        id: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired,
        origins: PropTypes.string.isRequired,
        destination_warehouse: PropTypes.shape({
            name: PropTypes.string.isRequired,
            address: ADDRESS_PROP_TYPE.isRequired
        }).isRequired,
        status: PropTypes.shape({
            status: PropTypes.string.isRequired
        }).isRequired,
        reason: PropTypes.shape({ reason: PropTypes.string }),
        order: PropTypes.shape({
            id: PropTypes.number.isRequired,
            uuid: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    onStatusChanged: PropTypes.func,
    isEditingStatusEnabled: PropTypes.bool
}

export default SalesOrderReturnDetailsHeader
