import React from "react"
import PropTypes from "prop-types"

import {
    Panel,
    H3,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableCell,
    TableRow,
    Status
} from "@butterfly-frontend/ui"
import { Image, ReloadableImageWrap } from "ui"
import { formatPrice } from "helpers/units"

import styles from "./SalesOrderReturnDetailsItemList.module.css"

const TABLE_COLUMNS = {
    no: { width: 30, minWidth: 30 },
    productName: { width: "35%", minWidth: 200 },
    condition: { width: "18%", minWidth: 120 },
    warehouse: { width: "15%", minWidth: 100 },
    total: { width: "30%", minWidth: 100 }
}

const SalesOrderReturnDetailsItemList = ({ items }) => (
    <Panel classes={{ panel: styles.panel }}>
        <H3 classes={{ h3: styles.h3 }}>Items</H3>
        <Table>
            <TableHead>
                <TableHeadCell {...TABLE_COLUMNS.no}>#</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.productName}>PRODUCT NAME</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.condition}>CONDITION</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.warehouse}>WAREHOUSE</TableHeadCell>
                <TableHeadCell {...TABLE_COLUMNS.total} classes={{ root: styles.priceCell }}>
                    ITEM TOTAL
                </TableHeadCell>
            </TableHead>
            <TableBody>
                {items.map((item, index) => (
                    <TableRow classNames={{ root: styles.tableRow }} key={item.id}>
                        <TableCell {...TABLE_COLUMNS.no} classes={{ root: styles.ordinalNumberCell }}>
                            {index + 1}
                        </TableCell>
                        <TableCell {...TABLE_COLUMNS.productName} classes={{ root: styles.productNameCell }}>
                            <ReloadableImageWrap image={item.thumb}>
                                {({ image }) => <Image className={styles.productThumb} src={image} />}
                            </ReloadableImageWrap>
                            {item.name}
                        </TableCell>
                        <TableCell {...TABLE_COLUMNS.condition}>
                            <Status color={item.condition.color}>{item.condition.name}</Status>
                        </TableCell>
                        <TableCell {...TABLE_COLUMNS.warehouse} classes={{ root: styles.warehouseCell }}>
                            {item.warehouse.name}
                        </TableCell>
                        <TableCell {...TABLE_COLUMNS.total} classes={{ root: styles.priceCell }}>
                            {formatPrice(item.price)}
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </Panel>
)

SalesOrderReturnDetailsItemList.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            thumb: PropTypes.string,
            condition: PropTypes.shape({
                color: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired
            }).isRequired,
            warehouse: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            price: PropTypes.string.isRequired
        })
    ).isRequired
}

export default SalesOrderReturnDetailsItemList
