import * as yup from "yup"

export default yup.object().shape({
    calendars: yup.array().of(
        yup.object().shape({
            title: yup
                .string()
                .max(250, "Calendar Name may not be greater than 250 characters")
                .required("Calendar Name is required")
        })
    )
})
