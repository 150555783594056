import { SET_LAST_FETCHED, RESET_LAST_FETCHED } from "../constants"

const initialState = {
    data: null
}

function lastFetched(state = initialState, action) {
    switch (action.type) {
        case SET_LAST_FETCHED:
            return {
                ...state,
                data: action.payload
            }

        case RESET_LAST_FETCHED:
            return {
                ...initialState
            }

        default:
            return state
    }
}

export default lastFetched
