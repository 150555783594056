import { useCallback } from "react"

import { useActions } from "hooks"
import { showErrorNotification } from "actions/notification"
import { useStockItemEdit } from "modules/WmsModule/hooks/api/useStockItem"
import { useReplaceStockItemInShipmentCache } from "modules/WmsModule/hooks/cache"

const useShipmentStockItemEdit = ({ shipment, item }) => {
    const replaceStockItemInShipmentCache = useReplaceStockItemInShipmentCache({ shipmentId: shipment.id })

    const actions = useActions({ showErrorNotification })

    const changeStockItemInShipmentCache = useCallback(
        result => {
            const {
                id,
                images,
                product_condition: { name, color, id: product_condition_id, type }
            } = result.data.condition

            replaceStockItemInShipmentCache({
                ...item,
                condition: {
                    id,
                    name,
                    color,
                    images,
                    product_condition_id,
                    type
                },
                boxes: result.data.boxes
            })
        },
        [replaceStockItemInShipmentCache, item]
    )

    const shipmentStockItemEdit = useStockItemEdit({
        skipInvalidateQueries: true,
        reactQueryProps: {
            onSuccess: changeStockItemInShipmentCache,
            onError: actions.showErrorNotification
        }
    })

    return { shipmentStockItemEdit }
}

export default useShipmentStockItemEdit
