import React, { Component } from "react"
import styles from "./Button.css"
import withStyles from "HOC/withStyles"

class Button extends Component {
    render() {
        const { className, type, label, icon, isLoading, onClick, cx, tabIndex, isDisabled } = this.props

        const tabIndexProp = tabIndex ? { tabIndex } : {}
        const typeProp = type ? { type } : {}

        return (
            <button
                {...tabIndexProp}
                {...typeProp}
                className={cx("root", className, { isLoading, isDisabled })}
                onClick={onClick}
            >
                {icon} {label}
            </button>
        )
    }
}

export default withStyles(Button, styles)
