import dropdown from "./Dropdown.css"
import confirmModal from "./ConfirmModal.css"
import loader from "./Loader.css"

const overrides = {
    dropdown,
    confirmModal,
    loader
}

export default overrides
