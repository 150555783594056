import React from "react"
import ReactSVG from "react-svg"

import withStyles from "HOC/withStyles"

import loader from "assets/loader.svg"
import styles from "./Loader.css"

export default withStyles(({ cx }) => {
    return (
        <div className={cx("root")}>
            <span className={cx("loader")}>
                <ReactSVG src={loader} svgStyle={{ width: 36, height: 36 }} />
            </span>
        </div>
    )
}, styles)
