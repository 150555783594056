import { useCallback } from "react"

import _get from "lodash/get"
import { useStockItemBoxEdit } from "modules/WmsModule/hooks/api/useStockItemBox"
import { useReplaceStockItemInShipmentCache } from "modules/WmsModule/hooks/cache"
import decodeStockItemBoxSku from "modules/WmsModule/helpers/decodeStockItemBoxSku"

const getError = ({ box, stockItem, sku }) => {
    if (_get(box, "accepted") || _get(stockItem, "acceptance")) {
        return `The box with sku ${sku} has already been accepted.`
    }

    return `The box with sku ${sku} does not belong to any package in this shipment.`
}

const useAcceptBox = ({ shipment, onAcceptError, onAcceptSuccess }) => {
    const replaceStockItemInShipmentCache = useReplaceStockItemInShipmentCache({ shipmentId: shipment.id })
    const boxAcceptMutation = useStockItemBoxEdit()

    const acceptStockItem = useCallback(
        ({ stockItem }) => {
            replaceStockItemInShipmentCache({
                ...stockItem,
                entireItemBarcodeRead: true
            })
            onAcceptSuccess()
        },
        [replaceStockItemInShipmentCache]
    )

    const acceptStockItemBox = useCallback(
        ({ stockItem, box }) =>
            boxAcceptMutation.mutate(
                {
                    id: box.id,
                    data: {
                        accepted: true
                    }
                },
                {
                    onSuccess: ({ data: updatedBox }) => {
                        replaceStockItemInShipmentCache({
                            ...stockItem,
                            boxes: stockItem.boxes.map(itemBox =>
                                itemBox.id === updatedBox.id ? { ...itemBox, accepted: updatedBox.accepted } : itemBox
                            )
                        })
                        onAcceptSuccess()
                    }
                }
            ),
        [boxAcceptMutation.mutate, replaceStockItemInShipmentCache, onAcceptSuccess]
    )
    const onAcceptBox = useCallback(
        sku => {
            const { stockItemId, boxNumber } = decodeStockItemBoxSku(sku)
            const stockItem = shipment.items.find(currentItem => currentItem.id === stockItemId)
            const shouldAcceptStockItem =
                stockItem && !boxNumber && stockItem.boxes.length === 0 && !stockItem.acceptance

            if (shouldAcceptStockItem) {
                return acceptStockItem({ stockItem })
            }

            const box = stockItem ? stockItem.boxes.find(box => box.sku === sku) : undefined

            if (box && !box.accepted) {
                return acceptStockItemBox({ stockItem, box })
            }

            onAcceptError(getError({ box, sku, stockItem }))
        },
        [shipment, acceptStockItem, acceptStockItemBox, onAcceptError, getError, decodeStockItemBoxSku]
    )
    return { onAcceptBox, isLoading: boxAcceptMutation.isLoading }
}

export default useAcceptBox
