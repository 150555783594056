import useActions from "./useActions"
import { useSelector } from "react-redux"

import { actions } from "reducers/ui/combinationsCreator"

const useCombinationsCreator = () => {
    const combinationsCreatorState = useSelector(state => state.ui.combinationsCreator)
    const combinationCreatorActions = useActions(actions)

    return [combinationsCreatorState, combinationCreatorActions]
}

export default useCombinationsCreator
