import React from "react"
import PropTypes from "prop-types"

import { Button } from "@butterfly-frontend/ui"

import styles from "./ManualEntryFormActions.module.css"

const ManualEntryFormActions = ({ isDisabled, isSubmitting, onClose }) => (
    <div className={styles.formButtons}>
        <Button
            onClick={onClose}
            variant="flat"
            size="small"
            classes={{
                button: styles.cancelButton
            }}
        >
            Cancel
        </Button>
        <Button
            isLoading={isSubmitting}
            disabled={isDisabled}
            size="small"
            type="submit"
            color="blue"
            classes={{
                content: styles.firstButton
            }}
        >
            Add entry
        </Button>
    </div>
)

ManualEntryFormActions.propTypes = {
    isDisabled: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    onClose: PropTypes.func.isRequired
}

export default ManualEntryFormActions
