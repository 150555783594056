import React, { useState, Fragment, useEffect, useCallback } from "react"
import ReactSVG from "react-svg"
import { Link, useHistory } from "react-router-dom"
import moment from "moment"
import idx from "idx"

import getStyles from "helpers/getCxFromStyles"
import fetch from "helpers/fetch"
import { formatPriceByComa, formatDimension, formatWeight } from "helpers/units"
import { formatNotes } from "helpers/notes"
import useFetch from "hooks/useFetch"
import { downloadBlobFile, showPrintWindow } from "actions/print"

import { Quantity, Button, ButtonWithDropdown } from "ui"
import ChooseOrder from "components/Modal/ChooseOrder"
import ChooseProject from "components/Modal/ChooseProject"
import ImageGallery from "../ImageGallery"
import Combinations from "../Combinations"
import Modal from "components/Modal/Modal"
import DuplicateProduct from "components/Modal/DuplicateProduct"
import Loader from "components/Reusable/Loader"
import Sendable from "modules/ProductsModule/pages/Details/Components/Sendable"
import SoldLabel from "modules/ProductsModule/pages/List/components/SoldLabel"
import AddToSelectionButtonContainer from "modules/SelectionsWidgetModule/components/AddToSelectionButtonContainer"

import styles from "./styles.css"
import quantityStyles from "./overrides/Quantity.css"
import buttonStyles from "./overrides/Button.css"
import secondaryButtonStyles from "./overrides/ButtonSecondary.css"
import buttonWithDropdownStyles from "./overrides/ButtonWithDropdown.css"
import stylesSelectionLoader from "./overrides/LoaderSelection.css"
import stylesSoldLabel from "./overrides/SoldLabel.css"

import expandIcon from "assets/expand.svg"
import verifiedIcon from "assets/alert-ok-grey.svg"
import widthIcon from "assets/length_black.svg"
import heightIcon from "assets/height_black.svg"
import depthIcon from "assets/depth_black.svg"
import iconOrder from "assets/order.svg"
import iconProject from "assets/ico-add-to-project-outfill.svg"
import iconPrinter from "assets/print.svg"
import duplicateIcon from "assets/duplicate.svg"
import pencilIcon from "assets/pencil.svg"
import selectionIcon from "assets/ico-lightbox-add.svg"

const DIMENSIONS = {
    depth: { formatter: formatDimension, icon: depthIcon },
    width: { formatter: formatDimension, icon: widthIcon },
    height: { formatter: formatDimension, icon: heightIcon },
    weight: { formatter: formatWeight }
}

const Hero = props => {
    const {
        userId,
        product,
        chooseOrderState,
        dispatchChooseOrder,
        chooseProjectState,
        dispatchChooseProject,
        setSelectedAttributes,
        selectedAttributes,
        isOpenDuplicateModal,
        setIsOpenDuplicateModal,
        combination,
        setCombination
    } = props
    const [quantity, setQuantity] = useState(0)
    const [activeImageId, setActiveImageId] = useState(idx(product.images, _ => _.preview[0].id) || -1)
    const [isShownLongDescription, setIsShownLongDescription] = useState(false)
    const [isExpandedTechnicalDescription, setIsExpandedTechnicalDescription] = useState(false)
    const [isImperialUnits, setIsImperialUnits] = useState(true)
    const [computedPrice, setComputedPrice] = useState(0)
    const [isSendableModalOpenFor, setIsSendableModalOpenFor] = useState("")
    const [isFetchingCombination, setIsFetchingCombination] = useState(true)
    const routerHistory = useHistory()
    const cx = getStyles(styles)
    const {
        id,
        name,
        brand,
        verified,
        available_on_web,
        created_at,
        updated_at,
        images,
        discount,
        price,
        has_combinations,
        width,
        height,
        depth,
        weight,
        short_description,
        description,
        technical_description,
        attributes = {},
        reorderable,
        combination_patterns
    } = product

    const isCombinationSelected = has_combinations && Object.keys(attributes).length === selectedAttributes.length
    const isSelectionReady = !has_combinations || isCombinationSelected
    const combinationId = idx(combination, _ => _.id)

    const inStock = isCombinationSelected && combination ? combination.items_count : product.quantity
    const isSold = !reorderable && !inStock

    useEffect(() => {
        if (isCombinationSelected) {
            setIsFetchingCombination(true)
            fetch
                .get(`/combinations/find?productId=${product.id}&attributeValueIds=${selectedAttributes.join(",")}`)
                .then(({ data: combination }) => {
                    setCombination(combination)
                    routerHistory.replace({ search: `?combination_id=${combination.id}` })

                    const imageIds = (idx(product.images, _ => _.preview) || []).map(({ id }) => id)
                    if (imageIds.includes(combination.media_id)) {
                        setActiveImageId(combination.media_id)
                    }
                })
                .catch(() => {
                    setCombination(null)
                    routerHistory.replace({ search: "" })
                })
                .finally(() => setIsFetchingCombination(false))
        } else {
            setCombination(null)
        }
    }, [selectedAttributes, isCombinationSelected])

    useEffect(() => {
        const basicPrice = price || 0
        const finalPrice =
            isCombinationSelected && combination ? getImpactedPrice(basicPrice, combination.impact) : basicPrice

        setComputedPrice(Math.max(0, finalPrice))
    }, [price, combination, isCombinationSelected])

    function getImpactedPrice(basicPrice, stringImpact) {
        const sign = stringImpact[0]
        const impact = parseFloat(stringImpact.slice(1)) || 0

        const multiplierOf = {
            "+": { basicPrice: 1, impact: 1 },
            "-": { basicPrice: 1, impact: -1 },
            "=": { basicPrice: 0, impact: 1 }
        }[sign] || { basicPrice: 1, impact: 0 }

        return basicPrice * multiplierOf.basicPrice + impact * multiplierOf.impact
    }

    const renderPrice = withDiscount => {
        let finalPrice = computedPrice
        if (withDiscount && discount) {
            finalPrice -= finalPrice * (discount / 100)
        }

        return `$ ${formatPriceByComa(finalPrice, true)}.${formatPriceByComa(finalPrice, false)}`
    }

    const renderDimension = (type, key) => {
        const { icon, formatter } = DIMENSIONS[type]
        const value = product[type]

        return (
            !!value && (
                <li className={cx(type)} key={key}>
                    {icon && <img src={icon} alt="" />}
                    <span>{formatter(value, isImperialUnits)}</span>
                </li>
            )
        )
    }

    const closeSendableModal = () => setIsSendableModalOpenFor("")

    const printAndSendItems = useCallback(
        func => [
            { action: () => func("full"), text: "Full product preview" },
            { action: () => func("full_with_location"), text: "Full with location" },
            { action: () => func("product_name_only"), text: "Product name only" },
            { action: () => func("product_name_and_finish"), text: "Product name & finish" }
        ],
        []
    )

    const getPdfAction = printType =>
        fetch.postRAW(`/products/${id}/pdf`, { payload: { print_type: printType, combination_id: combinationId } })

    const [handlePrint, { isLoading: isPrinting }] = useFetch({
        action: getPdfAction,
        onSuccess: async response => {
            const blob = await response.blob()
            showPrintWindow(blob)
        }
    })

    const [handleDownload, { isLoading: isDownloading }] = useFetch({
        action: getPdfAction,
        onSuccess: async response => {
            const blob = await response.blob()
            downloadBlobFile(blob, { fileName: product.name })
        }
    })

    const hasDimensions = Object.keys(DIMENSIONS).reduce((amount, val) => amount + Number(!!product[val]), 0) > 0

    const maxCollapsedTechnicalDescriptionLength = 180
    const isExpandTechnicalDescriptionButtonShown =
        technical_description && technical_description.length > maxCollapsedTechnicalDescriptionLength
    const technicalDescriptionText =
        isExpandedTechnicalDescription || !isExpandTechnicalDescriptionButtonShown
            ? technical_description
            : technical_description.slice(0, maxCollapsedTechnicalDescriptionLength)

    return (
        <div className={cx("root")}>
            <div className={cx("header")}>
                {brand && brand.logo && (
                    <div className={cx("brand")}>
                        <img src={brand.logo} alt={brand.name} />
                    </div>
                )}
                <div className={cx("productName")}>{name}</div>
                <div className={cx("actions")}>
                    <button type="button" className={cx("outlineButton")} onClick={() => setIsOpenDuplicateModal(true)}>
                        <ReactSVG src={duplicateIcon} className={cx("icon", "duplicateIcon")} />
                        <span className={cx("label")}>Duplicate</span>
                    </button>
                    <Link to={`/products/${id}/edit`} className={cx("outlineButton")}>
                        <ReactSVG src={pencilIcon} className={cx("icon", "editIcon")} />
                        <span className={cx("label")}>Edit</span>
                    </Link>
                    <ButtonWithDropdown
                        label="Print"
                        items={printAndSendItems(handlePrint)}
                        icon={iconPrinter}
                        isLoading={isPrinting}
                        customStyles={buttonWithDropdownStyles}
                    />
                    <ButtonWithDropdown
                        className="blue"
                        label="Send"
                        items={printAndSendItems(setIsSendableModalOpenFor)}
                        customStyles={buttonWithDropdownStyles}
                    />
                </div>
            </div>
            <div className={cx("status")}>
                <div className={cx("verifyAndAvailable")}>
                    <div className={cx("verified")}>
                        {verified ? (
                            <Fragment>
                                <ReactSVG src={verifiedIcon} className={cx("icon")} />
                                Verified
                            </Fragment>
                        ) : (
                            "Not verified"
                        )}
                    </div>
                    {available_on_web ? (
                        <div className={cx("available")}>Available on web</div>
                    ) : (
                        <span className={cx("unavailable")}>Unavailable on web</span>
                    )}
                </div>
                <div className={cx("dates")}>
                    <div className={cx("date")}>
                        <span className={cx("label")}>Created:</span>
                        <span className={cx("value")}>{moment(created_at).format("DD MMM YYYY")}</span>
                    </div>
                    <div className={cx("date")}>
                        <span className={cx("label")}>Last updated:</span>
                        <span className={cx("value")}>{moment(updated_at).format("DD MMM YYYY")}</span>
                    </div>
                </div>
            </div>
            <div className={cx("content")}>
                <ImageGallery images={images} activeImageId={activeImageId} setActiveImageId={setActiveImageId} />
                <div className={cx("rightBar")}>
                    {has_combinations && (
                        <Combinations
                            attributes={attributes}
                            setSelectedAttributes={setSelectedAttributes}
                            initialSelectedAttributeValueIds={selectedAttributes}
                            combinationPatterns={combination_patterns}
                        />
                    )}
                    <div className={cx("cta")}>
                        <div className={cx("price")}>
                            {isSold && <SoldLabel customStyles={stylesSoldLabel} />}
                            {!isSelectionReady && <span className={cx("label")}>From</span>}
                            <div className={cx("values")}>
                                {!isSold && (
                                    <Fragment>
                                        {!!discount && <span className={cx("discount")}>{`- ${discount}%`}</span>}
                                        {!!discount && <span className={cx("normalPrice")}>{renderPrice()}</span>}
                                    </Fragment>
                                )}
                                <span className={cx("lowestPrice")}>{renderPrice(true)}</span>
                            </div>
                            {!isSelectionReady && <span className={cx("message", "error")}>Choose a combination</span>}
                            {isSelectionReady && (
                                <Fragment>
                                    <span className={cx("message", { redMessage: !inStock })}>
                                        {!!inStock ? `In stock (${inStock})` : `Not in stock`}
                                    </span>
                                    {combination && !combination.is_enabled && (
                                        <span className={cx("message", "redMessage")}>Disabled combination</span>
                                    )}
                                    {!isFetchingCombination && !combination && (
                                        <span className={cx("message", "redMessage")}>Combination does not exist</span>
                                    )}
                                </Fragment>
                            )}
                        </div>
                        <div className={cx("quantityAndCta")}>
                            <Quantity
                                disabled={chooseOrderState.isLoading || !(!has_combinations || isSelectionReady)}
                                value={quantity}
                                min={0}
                                onChange={q => setQuantity(q)}
                                customStyles={quantityStyles}
                            />
                            <Button
                                label={
                                    <span>
                                        <ReactSVG src={iconOrder} className={cx("icon")} />
                                        Add to Order
                                    </span>
                                }
                                className={["button", "blue"]}
                                customStyles={buttonStyles}
                                isLoading={chooseOrderState.isLoading}
                                isDisabled={
                                    chooseOrderState.isLoading ||
                                    !(!has_combinations || isSelectionReady) ||
                                    quantity <= 0
                                }
                                onClick={() => {
                                    dispatchChooseOrder({ type: "toggleModal" })
                                }}
                            />
                            <Button
                                label={
                                    <span>
                                        <ReactSVG src={iconProject} className={cx("icon")} />
                                        Add to Project
                                    </span>
                                }
                                className={["button"]}
                                customStyles={{ ...buttonStyles, ...secondaryButtonStyles }}
                                isLoading={chooseProjectState.isLoading}
                                isDisabled={chooseProjectState.isLoading || !(!has_combinations || isSelectionReady)}
                                onClick={() => {
                                    dispatchChooseProject({ type: "toggleModal" })
                                }}
                            />

                            {/* TODO: add during sprint of sessions */}
                            {/* {isActiveSession &&
                                <div className={cx('favorite')}>
                                <ReactSVG src={starOutlineIcon} className={cx('startOutlineIcon')} />
                                <span>Add to favorites</span>
                                </div>
                            }*/}
                            <AddToSelectionButtonContainer product={product}>
                                {({ handleClick, fetchStatus, isInSelection }) => {
                                    if (fetchStatus.isLoading) {
                                        return (
                                            <div className={cx("favoriteContainer")}>
                                                <Loader customStyles={stylesSelectionLoader} />
                                            </div>
                                        )
                                    }

                                    return (
                                        <div className={cx("favoriteContainer")}>
                                            <div
                                                className={cx("favorite", { active: isInSelection })}
                                                onClick={() => handleClick()}
                                            >
                                                <ReactSVG src={selectionIcon} className={cx("startOutlineIcon")} />
                                                <span>
                                                    {isInSelection ? "Remove from Selection" : "Add to Selection"}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                }}
                            </AddToSelectionButtonContainer>
                        </div>
                    </div>
                </div>
            </div>
            <div className={cx("paramsAndDescription")}>
                <div className={cx("params")}>
                    <span className={cx("label")}>Dimensions & Weight</span>
                    <ul className={cx("list")}>
                        {hasDimensions ? (
                            Object.keys(DIMENSIONS).map((dim, index) => renderDimension(dim, index))
                        ) : (
                            <li>-</li>
                        )}
                    </ul>
                    {(!!width || !!height || !!depth || !!weight) && (
                        <a className={cx("switch")} onClick={() => setIsImperialUnits(!isImperialUnits)}>
                            {isImperialUnits ? "Metric units for dimensions " : "Imperial units for dimensions "}
                        </a>
                    )}
                </div>
                <div className={cx("description", { isShownLong: isShownLongDescription })}>
                    <div className={cx("short")}>
                        <span className={cx("label")}>Short description</span>
                        <p className={cx("text")}>{formatNotes(short_description || "-")}</p>
                    </div>
                    <div className={cx("long")}>
                        <span className={cx("label")}>Long description</span>
                        <p className={cx("text")}>{formatNotes(description || "-")}</p>
                    </div>
                    <span
                        className={cx("expandButton")}
                        onClick={() => setIsShownLongDescription(wasShown => !wasShown)}
                    >
                        <ReactSVG src={expandIcon} className={cx("expandIcon")} />
                        {isShownLongDescription ? "Hide" : "Show"} long description
                    </span>
                </div>
            </div>

            {!!technicalDescriptionText && (
                <div
                    className={cx("paramsAndDescription", "technicalDescription", {
                        isExpanded: isExpandedTechnicalDescription
                    })}
                >
                    <span className={cx("label")}>Finishes and Technical Description</span>
                    <p className={cx("text")}>{formatNotes(technicalDescriptionText)}</p>
                    {isExpandTechnicalDescriptionButtonShown && (
                        <span
                            className={cx("expandButton")}
                            onClick={() => setIsExpandedTechnicalDescription(wasExpanded => !wasExpanded)}
                        >
                            <ReactSVG src={expandIcon} className={cx("expandIcon")} />
                            {isExpandedTechnicalDescription ? "Hide" : "Show"} finishes and technical description
                        </span>
                    )}
                </div>
            )}

            <ChooseOrder
                isOpen={chooseOrderState.isOpen}
                filters={{
                    created_by: userId,
                    sort_by: "updated_at",
                    sort_direction: "desc",
                    combinationId
                }}
                handleSelect={value => {
                    dispatchChooseOrder({ type: "toggleModal" })
                    dispatchChooseOrder({ type: "setOrderId", payload: value.order_id })
                    dispatchChooseOrder({
                        type: "setValues",
                        payload: {
                            quantity: quantity,
                            combinationId
                        }
                    })
                }}
                handleClose={() => dispatchChooseOrder({ type: "toggleModal" })}
            />

            <ChooseProject
                isOpen={chooseProjectState.isOpen}
                filters={{
                    user_with_access: userId,
                    sort_by: "updated_at",
                    sort_direction: "desc"
                }}
                handleSelect={value => {
                    dispatchChooseProject({ type: "toggleModal" })
                    dispatchChooseProject({
                        type: "setValues",
                        payload: {
                            id: value.project_id,
                            roomId: value.room_id,
                            quantity: quantity,
                            combinationId
                        }
                    })
                }}
                handleClose={() => dispatchChooseProject({ type: "toggleModal" })}
                withSelectRoom
            />
            {isOpenDuplicateModal && (
                <Modal>
                    <DuplicateProduct
                        productId={id}
                        handleSetModal={() => setIsOpenDuplicateModal(!isOpenDuplicateModal)}
                        productName={name}
                    />
                </Modal>
            )}
            {isSendableModalOpenFor && (
                <Sendable
                    isPrinting={isDownloading}
                    id={id}
                    combinationId={combinationId}
                    printType={isSendableModalOpenFor}
                    handleClose={closeSendableModal}
                    handlePrint={handleDownload}
                />
            )}
        </div>
    )
}

export default Hero
