import React, { Component } from "react"
import styles from "./Section.css"
import withStyles from "HOC/withStyles"

class Section extends Component {
    render() {
        const { children, withoutBorder, cx } = this.props

        return <div className={cx("root", { withBorder: !withoutBorder })}>{children}</div>
    }
}

export default withStyles(Section, styles)
