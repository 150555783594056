import { MULTISELECT_TOGGLE, MULTISELECT_CLEAR, MULTISELECT_SET_STATE } from "constants/index"
import config from "./config"
import { cloneDeep, xor } from "lodash"

const getNewValues = Object.freeze({
    MULTISELECT_TOGGLE: (payload, currentValues) => xor(currentValues, Array.isArray(payload) ? payload : [payload]),

    MULTISELECT_CLEAR: () => [],

    MULTISELECT_SET_STATE: payload => (Array.isArray(payload) ? payload : [payload])
})

const updateCheckboxes = (state, action) => {
    const { payload, meta = {}, type } = action
    const { id, tableId } = meta
    const localState = state[id]
    const localStateIsObjectOrArray = localState && typeof localState === "object"

    if (payload && localStateIsObjectOrArray) {
        const isFlatArrayState = Array.isArray(localState)
        const currentValues = isFlatArrayState ? localState : localState[tableId]

        if (Array.isArray(currentValues)) {
            const newValues = getNewValues[type](payload, currentValues)
            const newLocalState = isFlatArrayState ? newValues : { ...state[id], [tableId]: newValues }

            return { ...state, [id]: newLocalState }
        }
    }

    return state
}

export default (state = cloneDeep(config), action) => {
    if ([MULTISELECT_TOGGLE, MULTISELECT_CLEAR, MULTISELECT_SET_STATE].includes(action.type)) {
        return updateCheckboxes(state, action)
    }

    return state
}
