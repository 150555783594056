import * as yup from "yup"

import { settings as settingsFieldsSchema, product as productSchema } from "../ShowProductLink/schemas"

export const section = yup.object().shape({
    settings: yup
        .object()
        .shape({
            matterport_link: yup
                .string()
                .url("Link has incorrect format")
                .required("Link is required"),
            ...settingsFieldsSchema
        })
        .required(),
    product: productSchema,
    matterport_thumbnail: yup.object().nullable()
})
