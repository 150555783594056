import React, { Fragment, useEffect, useState } from "react"
import { Modal as ModalComponent, Button } from "ui"
import withStyles from "HOC/withStyles"

import { Field, Formik } from "formik"

import { Input } from "components/Reusable/Form"
import Image from "../MainPageImmediateDeliveryProductUpload"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import styles from "./styles.css"
import inputOverrides from "../MainPageNameSectionEditModal/overrides/inputOverrides.css"
import modalOverrides from "../MainPageNameSectionEditModal/overrides/modalOverrides.css"
import * as Yup from "yup"

const IMAGE_DIMENSIONS = {
    width: 384,
    height: 600
}

const schema = Yup.object().shape({
    product_name: Yup.string().required("This field is required."),
    brand_name: Yup.string().required("This field is required.")
})

const Modal = props => {
    const { cx, isOpen, handleClose, data, onSubmit, handleError } = props

    const [dataLoaded, setDataLoaded] = useState(false)
    const [isSaving, SetIsSaving] = useState(false)

    useEffect(() => {
        SetIsSaving(false)
    }, [isOpen])

    useEffect(() => {
        const { getImmediateDelivery, immediateDeliveryId } = props
        if (immediateDeliveryId && !dataLoaded) {
            getImmediateDelivery(immediateDeliveryId)
            setDataLoaded(true)
        }

        if (!isOpen) {
            setDataLoaded(false)
        }
    })

    const handleErrors = (form, errors) => {
        form.setErrors(errors.errors)
        handleError()
    }

    const handleSubmit = (values, form) => {
        SetIsSaving(true)
        const parsedValues = {
            ...values,
            product_image: values.product_image && values.product_image.id
        }
        onSubmit(data.data.id, parsedValues, null, errors => handleErrors(form, errors))
    }

    return (
        <ModalComponent isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <ModalComponent.Header>Edit product</ModalComponent.Header>
            {data.isLoading && (
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            )}

            {!data.isLoading && (
                <Formik
                    initialValues={data.data}
                    onSubmit={handleSubmit}
                    validateOnChange={true}
                    validationSchema={schema}
                >
                    {({ handleSubmit, isSubmitting, setFieldValue, errors, touched, values }) => {
                        return (
                            <form className={cx(["form", "root"])} onSubmit={handleSubmit}>
                                <ModalComponent.Body>
                                    <Fragment>
                                        <div className={cx("row")}>
                                            <div className={cx("files-uploader")}>
                                                <Image
                                                    form={{
                                                        values,
                                                        errors,
                                                        setFieldValue
                                                    }}
                                                    imageDimensions={IMAGE_DIMENSIONS}
                                                />
                                            </div>
                                        </div>
                                        <div className={cx("row")}>
                                            <Field name="product_name">
                                                {({ field }) => {
                                                    return (
                                                        <Input
                                                            label="Product name"
                                                            customStyles={inputOverrides}
                                                            error={touched.product_name && errors.product_name}
                                                            value={values.product_name}
                                                            onChange={value => {
                                                                setFieldValue(field.name, value.event.target.value)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                        <div className={cx("row")}>
                                            <Field name="brand_name">
                                                {({ field }) => {
                                                    return (
                                                        <Input
                                                            label="Brand"
                                                            customStyles={inputOverrides}
                                                            error={touched.brand_name && errors.brand_name}
                                                            value={values.brand_name}
                                                            onChange={value => {
                                                                setFieldValue(field.name, value.event.target.value)
                                                            }}
                                                        />
                                                    )
                                                }}
                                            </Field>
                                        </div>
                                    </Fragment>
                                </ModalComponent.Body>
                                <ModalComponent.Actions customStyles={modalOverrides}>
                                    <button className={cx("cancel-button")} onClick={handleClose}>
                                        Cancel
                                    </button>
                                    <Button
                                        className="first-button"
                                        onClick={handleSubmit}
                                        label={"Save"}
                                        isLoading={isSaving}
                                    />
                                </ModalComponent.Actions>
                            </form>
                        )
                    }}
                </Formik>
            )}
        </ModalComponent>
    )
}

export default withStyles(Modal, styles)
