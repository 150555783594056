import PropTypes from "prop-types"

const formikPropTypesFactory = (valuesPropTypes, customShape = {}) => {
    const errorsPropTypes = Object.keys(valuesPropTypes).reduce(
        (errorsPropTypes, valueKey) => ({ ...errorsPropTypes, [valueKey]: PropTypes.string }),
        {}
    )

    return PropTypes.shape({
        isSubmitting: PropTypes.bool.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        setFieldValue: PropTypes.func.isRequired,
        setFieldTouched: PropTypes.func.isRequired,
        setFieldError: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        values: PropTypes.shape(valuesPropTypes).isRequired,
        errors: PropTypes.shape(errorsPropTypes).isRequired,
        ...customShape
    }).isRequired
}

export default formikPropTypesFactory
