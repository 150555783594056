import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { deletePaymentMethod } from "actions/paymentMethods"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import styles from "./DeletePaymentMethod.module.css"
import withStyles from "HOC/withStyles"
import { Button, Modal } from "ui"
const { Actions, Body, Header } = Modal

const DeletePaymentMethod = ({ cx, handleClose, id, name }) => {
    const [isSubmitting, setSubmitting] = useState(false)

    const dispatch = useDispatch()

    const handleSubmit = async () => {
        setSubmitting(true)

        try {
            await dispatch(deletePaymentMethod(id))
            dispatch(showSuccessNotification({ title: "Deleted" }))
            handleClose()
        } catch (data) {
            if (data && data.message) {
                showErrorNotification(data.message)
            }
            setSubmitting(false)
        }
    }

    return (
        <Modal closeModal={handleClose} isOpen={true} preventClickOutside={true}>
            <Header>Delete this payment method?</Header>
            <Body>
                <div className={cx("content")}>
                    <p>
                        Are you sure you want to delete “<span className={cx("method")}>{name}</span>”? This action
                        cannot be reversed.
                    </p>
                </div>
            </Body>
            <Actions>
                <div className={cx("buttons")}>
                    <Button className="close-button" label="Cancel" onClick={handleClose} type="button" />
                    <Button
                        className="warning-button"
                        isLoading={isSubmitting}
                        label="Delete"
                        onClick={handleSubmit}
                        type="submit"
                    />
                </div>
            </Actions>
        </Modal>
    )
}

DeletePaymentMethod.defaultProps = {
    name: ""
}

DeletePaymentMethod.propTypes = {
    handleClose: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string
}

export default withStyles(DeletePaymentMethod, styles)
