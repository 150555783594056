import * as constants from "../constants"

export function UserReducer(state = {}, action) {
    switch (action.type) {
        case constants.GET_USER_START:
            return { ...state, user: {}, isLoaded: false, isError: false }
        case constants.GET_USERS_START:
            return { ...state, isLoaded: false, isError: false }
        case constants.GET_USERS_SUCCESS:
            const { data, links, meta } = action.payload.data
            return {
                ...state,
                isLoaded: true,
                users: data,
                links,
                meta
            }
        case constants.GET_USERS_ERROR:
        case constants.GET_USER_ERROR:
            return { ...state, isLoaded: false, isError: true }

        case constants.GET_USER_SUCCESS:
            return { ...state, isLoaded: true, user: action.payload.data.data }

        case constants.ADD_USER_START:
            return { ...state, isLoaded: false, errors: null }
        case constants.ADD_USER_SUCCESS:
            return { ...state, isLoaded: true, users: action.payload.data.data, errors: null }
        case constants.ADD_USER_ERROR:
            return { ...state, isLoaded: false, isError: true, errors: action.payload.data }

        case constants.DELETE_USER_START:
            return { ...state, isDeleted: false }

        case constants.DELETE_USER_ERROR:
            return { ...state, isDeleted: false }

        default:
            return state
    }
}
