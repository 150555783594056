import PropTypes from "prop-types"

const selectedItemsPropType = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.number.isRequired,
        product: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired,
        brand: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired,
        condition: PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired
        }).isRequired,
        warehouse: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired,
        location: PropTypes.shape({
            name: PropTypes.string.isRequired
        })
    }).isRequired
).isRequired

export default selectedItemsPropType
