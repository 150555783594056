import React from "react"

import withStyles from "HOC/withStyles"

import { PriceElements } from "ui"
import Layout from "components/Reusable/Layout"

import styles from "./Summary.css"
import layoutStyles from "../../overrides/Layout.css"

const Summary = ({
    cx,
    project: {
        calculations: { subtotal, total }
    }
}) => {
    const priceConfig = [
        [{ label: "Subtotal", value: subtotal }],
        [{ label: "Total Amount", value: total, isBig: true }]
    ]

    return (
        <Layout customStyles={layoutStyles}>
            <div className={cx("content")}>
                <h2 className={cx("header")}>Summary</h2>
                <div className={cx("calculation")}>
                    <PriceElements config={priceConfig} />
                </div>
            </div>
        </Layout>
    )
}

export default withStyles(Summary, styles)
