import fetch from "helpers/fetch"
import { serializeQuery } from "helpers/urls"
import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"
import useQueryDetailsFactory from "modules/WmsModule/hooks/factories/useQueryDetailsFactory"
import useMutationFactory from "modules/WmsModule/hooks/factories/useMutationFactory"

export const KEY = "shipments"

const fetchShipmentList = ({
    type = "",
    todayOnly = false,
    to_package = false,
    ready_for_shipping = false,
    shipped = false,
    pending = false,
    pickup = false,
    returned = false,
    dropship = "",
    query = "",
    statuses = "",
    created_from = "",
    created_to = "",
    source_warehouses = "",
    destination_warehouses = "",
    creators = "",
    clients = "",
    orders = "",
    sort_by = "id",
    sort_direction = "asc",
    page = 1,
    length = 20
}) => {
    const queryString = serializeQuery({
        type,
        today: todayOnly,
        to_package,
        ready_for_shipping,
        shipped,
        pending,
        pickup,
        returned,
        dropship,
        query,
        statuses,
        created_from,
        created_to,
        source_warehouses,
        destination_warehouses,
        creators,
        clients,
        orders,
        sort_by,
        sort_direction,
        page,
        length
    })
    return fetch.get(`/shipments?${queryString}`)
}

const fetchShipmentDetails = id => fetch.get(`/shipments/${id}`)
const fetchShipmentEdit = ({ id, data }) => fetch.patch(`/shipments/${id}`, data)

export const fetchClientShipmentCreate = ({ stockItemIds, orderId, trackingNumber, carrier, description }) =>
    fetch.postRAW("/multi-actions", {
        ids: stockItemIds,
        action: "client_shipment",
        model: "stock_items",
        payload: {
            order_id: orderId,
            tracking_number: trackingNumber,
            carrier,
            description
        }
    })

export const useShipmentList = useQueryListFactory({
    key: KEY,
    fetchFunction: fetchShipmentList
})
export const useShipmentDetails = useQueryDetailsFactory({
    key: KEY,
    fetchFunction: fetchShipmentDetails
})
export const useShipmentEdit = useMutationFactory({ key: KEY, fetchFunction: fetchShipmentEdit })
export const useClientShipmentCreate = useMutationFactory({ key: KEY, fetchFunction: fetchClientShipmentCreate })

export const getConsignmentNotes = ({ ids }) =>
    new Promise((resolve, reject) => {
        fetch
            .postRAW("/multi-actions", {
                ids,
                action: "print",
                model: "shipments",
                payload: {}
            })
            .then(response => response.blob())
            .then(blob => resolve(blob))
            .catch(error => reject(error))
    })
