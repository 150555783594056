import { INPUT_TYPES } from "constants/index"

export default {
    name: "Brand",
    endpoint: "/brands",
    fields: [
        {
            name: "name",
            label: "Name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "logo",
            label: "Logo",
            type: INPUT_TYPES.HIDDEN,
            defaultValue: "",
            attributes: {
                required: true
            }
        }
    ]
}
