import React from "react"

import withStyles from "HOC/withStyles"
import styles from "./list.css"

import checkIcon from "assets/checked.svg"

const Li = props => {
    const { cx, name, active, onClick } = props

    return (
        <li className={cx("listItem", { active })} onClick={onClick}>
            {name} {active && <img className={cx("check")} src={checkIcon} alt="" />}
        </li>
    )
}

const List = props => {
    const { cx, activeRooms, restRooms, setRoom } = props

    const renderRooms = (rooms, active) =>
        rooms.map(({ id, name }) => (
            <Li key={id} cx={cx} name={name} active={active} onClick={() => !active && setRoom(id)} />
        ))

    return (
        <ul className={cx("root")}>
            {renderRooms(activeRooms, true)}
            {renderRooms(restRooms)}
        </ul>
    )
}

export default withStyles(List, styles)
