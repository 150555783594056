import React, { Fragment } from "react"

import Layout from "components/Reusable/Layout"

import downloadXlsx from "helpers/downloadXlsx"
import withUploader from "HOC/withUploader"

import withStyles from "HOC/withStyles"
import styles from "./clients.css"
import layoutStyles from "./overrides/layoutStyles.css"
import dropzoneStyles from "./overrides/dropzoneStyles.css"
import confirmModalStyles from "./overrides/confirmModalStyles.css"

import { Dropzone, Button } from "ui"
import Modal from "components/Modal/Modal"
import ReviewModal from "components/Reusable/ReviewModal"
import ConfirmModal from "components/Modal/Confirm/Confirm"

import cloudSvg from "assets/cloud.svg"
import okSvg from "assets/alert-ok.svg"

const ClientsUpload = props => {
    const {
        cx,
        isModalOpenFor,
        closeModal,
        goToClients,
        uploader: { state, actions },
        spreadsheetData,
        handleSaveSpreadsheet,
        isSpreadsheetSaving
    } = props
    return (
        <Fragment>
            <Layout
                customStyles={layoutStyles}
                title="Upload Contact"
                links={[
                    { title: "Dictionary", onClick: () => downloadXlsx("dictionaries") },
                    { title: "Template", onClick: () => downloadXlsx("clientsTemplate") }
                ]}
            >
                <Dropzone
                    customStyles={dropzoneStyles}
                    acceptFormats=".xlsx,.xls"
                    activeClassName={cx("dropzone-active")}
                    isUploading={state.isUploading}
                    isSuccess={state.isSuccess}
                    progress={state.progress}
                    handleUpload={actions.handleUpload}
                    handleRemove={actions.handleRemove}
                >
                    <img className={cx("cloudImage")} src={cloudSvg} alt="" />
                    <h2 className={cx("h2")}>
                        Drag and drop your file here <br /> or click button Add XLS
                    </h2>
                    <h3 className={cx("h3")}>(only .xls file)</h3>
                    <Button className="first-button" label="Add XLS" isLoading={state.isUploading} />
                </Dropzone>
            </Layout>

            {isModalOpenFor && (
                <Modal>
                    {isModalOpenFor === "spreadsheet" && (
                        <ReviewModal
                            fileData={spreadsheetData}
                            handleHide={closeModal}
                            handleSave={handleSaveSpreadsheet}
                            isSaving={isSpreadsheetSaving}
                            title="Contact"
                        />
                    )}
                    {isModalOpenFor === "confirm" && (
                        <ConfirmModal
                            customStyles={confirmModalStyles}
                            confirmModalTitle={<img src={okSvg} alt="" />}
                            titleSecondLine="New clients have been added"
                            actionButtonLabel="Go to Contact"
                            handleHideModal={closeModal}
                            handleDelete={goToClients}
                        />
                    )}
                </Modal>
            )}
        </Fragment>
    )
}

export default withUploader(withStyles(ClientsUpload, styles))
