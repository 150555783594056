export const secondLink = "/products"
export default {
    headerColumns: [
        {
            name: "NO.",
            width: "5%"
        },
        {
            name: "NAME",
            width: "25%"
        },
        {
            name: "SUBCATEGORIES",
            width: "30%",
            textAlign: "center"
        },
        {
            name: "PRODUCTS",
            width: "25%",
            textAlign: "center"
        }
    ],
    dataColumns: [
        {
            name: "name",
            width: "25%",
            isLink: true
        },
        {
            name: "subcategories",
            width: "30%",
            textAlign: "center"
        },
        {
            name: "products_in_category",
            width: "25%",
            textAlign: "center",
            secondLink: secondLink
        }
    ]
}
