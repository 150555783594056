import React, { useMemo, useState, Fragment } from "react"
import { Link } from "react-router-dom"
import TruncateMarkup from "react-truncate-markup"
import classNames from "classnames"
import PropTypes from "prop-types"

import { Button, DetailsCard, Status, AlertInfoIcon } from "@butterfly-frontend/ui"
import { StockItemStatus, ProductHeader } from "modules/WmsModule/components"
import { ProductDetailsPopup } from "modules/WmsModule/modals"
import { COMBINATION_PROP_TYPE } from "propTypes"

import styles from "./StockItemDetailsHero.module.css"

const StockItemDetailsHero = props => {
    const { id, warehouse, product, status, condition, order, purchaseOrder, invoice, combination } = props
    const [isProductDetailsPopupOpen, setIsProductDetailsPopupOpen] = useState(false)

    const linkToProduct = useMemo(() => {
        if (combination && combination.id) {
            return `/products/${product.id}?combination_id=${combination.id}`
        }

        return `/products/${product.id}`
    }, [product, combination])

    const stockItemHasCombination = combination && combination.attribute_values

    return (
        <Fragment>
            <ProductHeader product={product}>
                <Button
                    classes={{ button: styles.productPageButton }}
                    type="link"
                    href={linkToProduct}
                    variant="flat"
                    size="small"
                >
                    Product page
                </Button>
            </ProductHeader>
            <div className={styles.details}>
                <DetailsCard header="ID">{id}</DetailsCard>
                <DetailsCard header="WAREHOUSE">{warehouse.name}</DetailsCard>
                <DetailsCard header="STATUS">
                    <StockItemStatus stockItemStatus={status} />
                </DetailsCard>
                <DetailsCard header="CONDITION">
                    <Status color={condition.color}>{condition.name}</Status>
                </DetailsCard>
                {(order || purchaseOrder) && (
                    <DetailsCard header="ASSIGNED TO">
                        {order && (
                            <Link to={`/orders/sales-orders/${order.id}`} className={styles.link}>
                                {order.uuid}
                            </Link>
                        )}
                        {order && invoice && (
                            <Link to={`/orders/sales-orders/${order.id}/invoices`} className={styles.link}>
                                {invoice.uuid}
                            </Link>
                        )}
                        {purchaseOrder && (
                            <Link
                                to={
                                    purchaseOrder.order_id
                                        ? `/orders/sales-orders/${purchaseOrder.order_id}/purchase-orders`
                                        : "/orders/purchase-orders"
                                }
                                className={styles.link}
                            >
                                {purchaseOrder.uuid}
                            </Link>
                        )}
                    </DetailsCard>
                )}
                {(stockItemHasCombination || product.technical_description) && (
                    <DetailsCard
                        classes={{ root: styles.productDetails }}
                        header={
                            <Fragment>
                                <h4 className={styles.detailsCardHeader}>
                                    {stockItemHasCombination ? "COMBINATION" : "TECHNICAL DESCRIPTION"}
                                </h4>
                                <ProductDetailsPopup
                                    onClose={() => setIsProductDetailsPopupOpen(false)}
                                    productName={product.name}
                                    productImageSrc={combination ? combination.image || product.image : null}
                                    combination={combination}
                                    description={product.technical_description}
                                    position="right"
                                    isOpen={isProductDetailsPopupOpen}
                                    trigger={
                                        <AlertInfoIcon
                                            className={classNames(styles.helpIcon, {
                                                [styles.active]: isProductDetailsPopupOpen
                                            })}
                                            onClick={() => setIsProductDetailsPopupOpen(true)}
                                        />
                                    }
                                    classes={{
                                        root: styles.combinationPopupContainer,
                                        triggerContainer: styles.combinationPopupTrigger
                                    }}
                                />
                            </Fragment>
                        }
                    >
                        <TruncateMarkup lines={3}>
                            {stockItemHasCombination ? (
                                <span className={styles.combinationAttribute}>
                                    {combination.attribute_values.map(
                                        attribute => `${attribute.attribute_name}: ${attribute.value} \n`
                                    )}
                                    )
                                </span>
                            ) : (
                                <p>{product.technical_description}</p>
                            )}
                        </TruncateMarkup>
                    </DetailsCard>
                )}
            </div>
        </Fragment>
    )
}

StockItemDetailsHero.propTypes = {
    id: PropTypes.number,
    status: PropTypes.string,
    condition: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    }).isRequired,
    warehouse: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string
    }).isRequired,
    product: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        brand: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string
        }),
        image: PropTypes.string,
        technical_description: PropTypes.string
    }).isRequired,
    order: PropTypes.shape({
        id: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired
    }),
    purchaseOrder: PropTypes.shape({
        uuid: PropTypes.string.isRequired,
        order_id: PropTypes.number
    }),
    invoice: PropTypes.shape({
        id: PropTypes.number.isRequired,
        uuid: PropTypes.string.isRequired
    }),
    combination: COMBINATION_PROP_TYPE
}

export default StockItemDetailsHero
