import React, { Fragment, useState, useEffect } from "react"
import idx from "idx"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"
import Dropzone from "modules/NewsModule/components/Dropzone/Dropzone"
import { Modal, Button, Input, Radio, Dropzone as DropFile, Textarea } from "ui"
import { Formik, Field } from "formik"
import TagsSelect from "modules/ContentModule/components/TagsSelect"

import { press as pressSchema } from "./schemas"

import styles from "./PressModal.css"
import inputStyles from "./overrides/input.css"
import cloudSvg from "./assets/cloud.svg"
import deleteSvg from "assets/delete.svg"
import documentSvg from "./assets/ico-document.svg"
import dropzoneImageStyles from "./overrides/dropzone-images.css"
import dropzoneRootStyles from "./overrides/dropzone-root.css"
import dropzonePdfStyles from "./overrides/dropzone-pdf.css"
import radioStyles from "./overrides/radio.css"
import textareaStyles from "./overrides/textarea.css"

const IMAGES_DIMENSIONS = {
    cover: {
        width: 294
    },
    hover: {
        width: 294
    }
}

const Uploader = ({ cx, handleUploadSuccess, image, handleRemoveFile, type, imageDimensions }) => {
    const previewData = {
        name: image.title,
        url: image.url
    }
    const imageData = {
        ...image,
        value: image.url
    }
    const label = type === "cover" ? "Image as cover:" : "Image as hover:"

    return (
        <div className={cx("dropzoneWrapper")}>
            <span className={cx("label")}>{label}</span>
            <Dropzone
                isMultiple={false}
                activeClassName={cx("canDrop")}
                acceptFormats={"image/jpeg,image/png"}
                imageData={imageData}
                previewData={previewData}
                handleUploadSuccess={data => handleUploadSuccess(data, type)}
                handleRemoveFile={handleRemoveFile}
                isCoverWithImageDimensions
                imageDimensions={imageDimensions}
                customLabel={"Add image"}
                customStyles={dropzoneImageStyles}
                rootStyles={dropzoneRootStyles}
            />
        </div>
    )
}

const PressModal = ({
    isOpen,
    handleClose,
    cx,
    press,
    handleSubmitAddEditModal,
    isSaving,
    uploader: { actions, state }
}) => {
    const isEdit = !!press.id
    const [coverImage, setCoverImage] = useState({})
    const [hoverImage, setHoverImage] = useState({})
    const [pdfFile, setPdfFile] = useState({})
    const [isLink, setIsLink] = useState(isEdit ? press.url : true)
    let setField = {}
    const initialValues = {
        id: press.id,
        title: press.title,
        subtitle: press.subtitle,
        cover_desktop: idx(press, _ => _.cover_desktop.url),
        hover_desktop: idx(press, _ => _.hover_desktop.url),
        url: press.url,
        attachment: press.attachment,
        tag: idx(press, _ => _.tag.id)
    }

    useEffect(() => {
        if (idx(state, _ => _.addedFile.url)) {
            setPdfFile({
                id: state.addedFile.id,
                name: state.addedFile.title,
                url: state.addedFile.url
            })
            setField("attachment", state.addedFile.id)
        }
    }, [state.isUploading, state.isSuccess, state.addedFile])

    const handleUploadSuccess = (data, type, setFieldValue) => {
        const setImage = type === "cover" ? setCoverImage : setHoverImage
        setImage(data.addedFile)
        setFieldValue(`${type}_desktop`, data.addedFile.id)
    }

    const handleRemoveFile = (event, type) => {
        event.stopPropagation()
        const setImage = type === "cover" ? setCoverImage : setHoverImage
        setImage({})
        setField(`${type}_desktop`, "")
    }

    useEffect(() => {
        if (press.cover_desktop) {
            setCoverImage({
                url: press.cover_desktop.url,
                title: press.cover_desktop.name
            })
        }
        if (press.hover_desktop) {
            setHoverImage({
                url: press.hover_desktop.url,
                title: press.hover_desktop.name
            })
        }
        if (press.attachment) {
            setPdfFile({
                url: press.attachment.url,
                name: press.attachment.name
            })
        }
    }, [press])

    const renderPdfEmptyState = () => (
        <DropFile
            acceptFormats="application/pdf"
            isUploading={state.isUploading}
            isSuccess={state.isSuccess}
            progress={state.progress}
            handleUpload={actions.handleUpload}
            handleRemove={actions.handleRemove}
            isMultiple={false}
            customStyles={dropzonePdfStyles}
        >
            <div className={cx("dropzoneStateEmpty")}>
                <img src={cloudSvg} alt="cloud" />
                <h3>Upload file</h3>
                <span>(browse or drop your file here)</span>
            </div>
        </DropFile>
    )

    const renderPdfUpladingState = () => (
        <div className={cx("dropzoneStateUploading")}>
            <div style={{ width: `${state.progress}%` }} />
        </div>
    )

    const removePdfFile = setFieldValue => {
        setPdfFile({})
        setFieldValue("attachment", null)
    }

    const renderPdfFileState = setFieldValue => (
        <div className={cx("dropzoneStateFile")}>
            <div className={cx("imageWrapper")}>
                <img src={documentSvg} alt="file ico" />
            </div>
            <div className={cx("bottomInfoWrapper")}>
                <a target="_blank" rel="noopener noreferrer" href={pdfFile.url}>
                    <span>{pdfFile.name}</span>
                </a>
                <button onClick={() => removePdfFile(setFieldValue)}>
                    <img src={deleteSvg} alt="close" />
                </button>
            </div>
        </div>
    )

    const handleSubmitForm = (data, formikActions) => {
        handleSubmitAddEditModal(data, coverImage, hoverImage, pdfFile, formikActions)
    }

    return (
        <Modal isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <p className={cx("header")}>{isEdit ? "Edit" : "Add"} Press</p>

            <Formik initialValues={initialValues} onSubmit={handleSubmitForm} validationSchema={pressSchema}>
                {({ errors, touched, setFieldValue, handleSubmit }) => {
                    setField = setFieldValue

                    return (
                        <Fragment>
                            <form className={cx("form")} onSubmit={handleSubmit}>
                                <div className={cx("uploadersWrapper")}>
                                    <div className={cx("uploader-left")}>
                                        <Uploader
                                            cx={cx}
                                            handleUploadSuccess={(data, type) =>
                                                handleUploadSuccess(data, type, setFieldValue)
                                            }
                                            image={coverImage}
                                            type="cover"
                                            handleRemoveFile={event => handleRemoveFile(event, "cover")}
                                            imageDimensions={IMAGES_DIMENSIONS.cover}
                                        />
                                        {touched.cover_desktop && errors.cover_desktop && (
                                            <p className={cx("errorUploader")}>{errors.cover_desktop}</p>
                                        )}
                                    </div>
                                    <div className={cx("uploader-right")}>
                                        <Uploader
                                            cx={cx}
                                            handleUploadSuccess={(data, type) =>
                                                handleUploadSuccess(data, type, setFieldValue)
                                            }
                                            image={hoverImage}
                                            type="hover"
                                            handleRemoveFile={event => handleRemoveFile(event, "hover")}
                                            imageDimensions={IMAGES_DIMENSIONS.hover}
                                        />
                                        {touched.hover_desktop && errors.hover_desktop && (
                                            <p className={cx("errorUploader")}>{errors.hover_desktop}</p>
                                        )}
                                    </div>
                                </div>
                                <Field name="title">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            value={field.value || ""}
                                            label="Title"
                                            onChange={({ event }) => field.onChange(event)}
                                            customStyles={inputStyles}
                                            error={touched.title && errors.title}
                                        />
                                    )}
                                </Field>
                                <Field name="subtitle">
                                    {({ field }) => (
                                        <Textarea
                                            {...field}
                                            value={field.value || ""}
                                            label="Subtitle"
                                            onChange={({ event }) => field.onChange(event)}
                                            customStyles={textareaStyles}
                                            error={touched.subtitle && errors.subtitle}
                                        />
                                    )}
                                </Field>
                                <label className={cx("radioLabel")}>Link: </label>
                                <div className={cx("radioWrapper")}>
                                    <Radio
                                        label="Link"
                                        name="link"
                                        value={"link"}
                                        checked={!!isLink}
                                        handleSelect={() => setIsLink(true)}
                                        customStyles={radioStyles}
                                    />
                                    <Radio
                                        label="PDF"
                                        name="link"
                                        value={"pdf"}
                                        checked={!isLink}
                                        handleSelect={() => setIsLink(false)}
                                        customStyles={radioStyles}
                                    />
                                </div>
                                {isLink && (
                                    <Field name="url">
                                        {({ field }) => (
                                            <Input
                                                {...field}
                                                value={field.value || ""}
                                                label="Link"
                                                onChange={({ event }) => field.onChange(event)}
                                                customStyles={inputStyles}
                                                error={touched.url && errors.url}
                                            />
                                        )}
                                    </Field>
                                )}
                                {!isLink && (
                                    <div className={cx("modal-upload-holder")}>
                                        {state.isUploading
                                            ? renderPdfUpladingState()
                                            : !!Object.keys(pdfFile).length
                                            ? renderPdfFileState(setFieldValue)
                                            : renderPdfEmptyState()}
                                        {touched.attachment && errors.attachment && (
                                            <p className={cx("errorUploader")}>{errors.attachment}</p>
                                        )}
                                    </div>
                                )}

                                <Field name="tag">
                                    {({ field, meta }) => (
                                        <TagsSelect
                                            isListOnTop
                                            label="Tags"
                                            tagId={field.value}
                                            error={meta.error}
                                            setTag={value => field.onChange({ target: { ...field, value } })}
                                        />
                                    )}
                                </Field>

                                <div className={cx("buttons")}>
                                    <span onClick={handleClose} className={cx("cancelButton")}>
                                        Cancel
                                    </span>
                                    <Button
                                        className={cx("first-button")}
                                        type="submit"
                                        label={"Save"}
                                        isLoading={isSaving}
                                    />
                                </div>
                            </form>
                        </Fragment>
                    )
                }}
            </Formik>
        </Modal>
    )
}

PressModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isSaving: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmitAddEditModal: PropTypes.func.isRequired,
    press: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        subtitle: PropTypes.string,
        cover_desktop: PropTypes.object,
        hover_desktop: PropTypes.object,
        url: PropTypes.string,
        attachment: PropTypes.object
    }),
    uploader: PropTypes.object,
    cx: PropTypes.func.isRequired
}

export default withUploader(withStyles(PressModal, styles))
