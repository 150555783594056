import React, { Fragment } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import withStyles from "HOC/withStyles"
import styles from "./ProductImageBar.css"
import closeIcon from "assets/close-blue.svg"

const ProductImageBar = props => {
    const { cx, isLoading, product, removeProduct } = props

    const imageUrl = idx(product, _ => _.image)

    return (
        <div className={cx("dropzoneStateImage", { isLoading })}>
            {isLoading ? (
                <SnakeLoader />
            ) : (
                <Fragment>
                    <div className={cx("imageWrapper")}>{!!imageUrl && <img src={imageUrl} alt="preview" />}</div>

                    <span>{product.name}</span>

                    <button onClick={removeProduct}>
                        <img src={closeIcon} alt="close" />
                    </button>
                </Fragment>
            )}
        </div>
    )
}

ProductImageBar.defaultProps = {
    isLoading: false
}

ProductImageBar.propTypes = {
    isLoading: PropTypes.bool,
    product: PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string
    }),
    removeProduct: PropTypes.func.isRequired
}

export default withStyles(ProductImageBar, styles)
