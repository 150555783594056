import React, { useEffect } from "react"

import withStyles from "HOC/withStyles"

import Layout from "components/Reusable/Layout"
import Loader from "components/Reusable/Loader"
import Skeleton from "components/Skeleton"
import { Info, Summary, Rooms } from "./components"

import styles from "./ShareProject.css"
import layoutStyles from "./overrides/Layout.css"

import logo from "assets/mass-logo.jpg"
import peopleIcon from "assets/people.svg"

export const ShareProject = ({ cx, data, getProject }) => {
    useEffect(() => {
        document.body.style.minWidth = "auto"
        document.getElementById("modal-root").style.position = "fixed"
        document.getElementById("modal-root").style.top = 0
        document.getElementById("modal-root").style.left = 0
    }, [])

    return (
        <div className={cx("root")}>
            <header>
                <img src={logo} alt="logo" className={cx("logo")} />
                <div>
                    <span className={cx("title")}>Your Project</span>
                    <span className={cx("shares")}>
                        <img src={peopleIcon} alt="user ico" /> Shared for {data.data.shares_count} people
                    </span>
                </div>
            </header>
            <main>
                <Skeleton
                    fetchStatus={data.fetchStatus}
                    component={
                        <Layout customStyles={layoutStyles}>
                            <div className={cx("fallback")} style={{ marginTop: 80 }}>
                                <Loader />
                            </div>
                        </Layout>
                    }
                    fallback={error => {
                        if (error.status === 404) {
                            return (
                                <Layout customStyles={layoutStyles}>
                                    <div className={cx("fallback")}>
                                        <p>Project does not exist.</p>
                                    </div>
                                </Layout>
                            )
                        }

                        return (
                            <Layout customStyles={layoutStyles}>
                                <div className={cx("fallback")}>
                                    <p>Something went wrong.</p>
                                </div>
                            </Layout>
                        )
                    }}
                >
                    <Info project={data.data} />
                    <Rooms project={data.data} getProject={getProject} />
                    {(data.data.options || []).every(option => option.pricing_visibility) && (
                        <Summary project={data.data} />
                    )}
                </Skeleton>
            </main>
            <footer>&copy; {new Date().getFullYear()} MASS BEVERLY</footer>
        </div>
    )
}

export default withStyles(ShareProject, styles)
