import React, { useMemo } from "react"

import { CollapsingText, PriceElements } from "ui"
import { isNumeric } from "rxjs/internal-compatibility"

const Summary = props => {
    const { cx, documentType, parentOrderType, instructions, calculations } = props

    const {
        subtotal,
        tax,
        total,
        applied,
        balance,
        discount,
        card_charge_fee,
        wholesale_cost,
        wholesale_discount,
        wholesale_total
    } = calculations

    const priceConfig = useMemo(() => {
        const isPurchaseOrder = documentType === "PO" && isNumeric(wholesale_cost) && isNumeric(wholesale_total)

        return isPurchaseOrder
            ? [
                  [{ label: "Subtotal", value: wholesale_cost }, { label: "Discount", value: -wholesale_discount }],
                  [{ label: "Total Amount", value: wholesale_total, isBig: true, isColor: true }]
              ]
            : [
                  [
                      { label: "Subtotal", value: subtotal },
                      { label: "Discount", value: -discount },
                      { label: "Sales TAX", value: tax },
                      { label: "Card Charge Fee", value: card_charge_fee || 0 }
                  ].filter(elem => parentOrderType !== "rental" || elem.label !== "Discount"),
                  [
                      { label: "Total Amount", value: total || wholesale_total, isBig: true },
                      { label: "Payment Applied", value: applied }
                  ],
                  [{ label: "Balance Due", value: -balance, isBig: true, isColor: true }]
              ]
    }, [
        subtotal,
        tax,
        total,
        applied,
        balance,
        discount,
        documentType,
        parentOrderType,
        wholesale_cost,
        wholesale_discount,
        wholesale_total
    ])

    return (
        <div className={cx("summary")}>
            <div className={cx("instructions")}>
                <label>Special instructions:</label>
                <CollapsingText text={instructions || "-"} />
            </div>
            <div className={cx("calculations")}>
                <PriceElements config={priceConfig} />
            </div>
        </div>
    )
}

export default Summary
