import { useCallback } from "react"
import { useQueryClient } from "react-query"
import _get from "lodash/get"
import _pick from "lodash/pick"
import _uniqueId from "lodash/uniqueId"

import fetch from "helpers/fetch"
import { useActions, useProductFetchStatuses } from "hooks"
import { showErrorNotification } from "actions/notification"
import calculateTotalPrice from "helpers/calculateTotalPrice"

const ONE_MINUTE_IN_MS = 60000

const DIMENSIONS_DICT = {
    width: { label: "width", unit: "cm" },
    height: { label: "height", unit: "cm" },
    depth: { label: "length", unit: "cm" }
}

const createDimensionsNote = dimensions => {
    const dimensionsNote = Object.entries(dimensions)
        .reduce(
            (dimensionsNotes, [key, value]) =>
                value
                    ? [...dimensionsNotes, `${DIMENSIONS_DICT[key].label}: ${value} ${DIMENSIONS_DICT[key].unit}`]
                    : dimensionsNotes,
            []
        )
        .join(",  ")

    return `Dimensions: ${dimensionsNote}`
}

const createOrderItemNoteFromProduct = product => {
    const technicalDescription = _get(product, "technical_description", "")
    const shortDescription = _get(product, "short_description", "")
    const note = _get(product, "note", "")

    return [
        note || "",
        createDimensionsNote(_pick(product, ["width", "height", "depth"])),
        `Finishes and Technical Description: ${technicalDescription || ""}`,
        `Description: ${shortDescription || ""}`
    ].join("\n")
}

const mapAttributes = product => {
    const attributes = _get(product, "combination.attribute_values", null)

    return attributes
        ? attributes.reduce(
              (attributeValues, { attribute_name, value }) => ({ ...attributeValues, [attribute_name]: value }),
              {}
          )
        : null
}

const getProductImage = product => {
    const combinationMediaId = _get(product, "combination.media_id")
    const images = _get(product, "images.thumb", [])
    const image =
        images.length > 0 && combinationMediaId ? images.find(({ id }) => id === combinationMediaId) : images[0]

    return _get(image, "url")
}

const getCalculations = product => {
    const wholesale_cost = product.wholesale_cost || 0
    const wholesale_discount = product.discount ? product.discount : null

    return {
        wholesale_cost,
        wholesale_discount,
        wholesale_total: calculateTotalPrice({
            price: wholesale_cost,
            discount: wholesale_discount,
            quantity: product.quantity
        })
    }
}

const orderItemProductAdapter = product => ({
    ..._pick(product, ["name", "combination_id", "quantity", "wholesale_cost", "manufacturer_id", "brand"]),
    draftId: _uniqueId("draftId_"),
    attributes: mapAttributes(product),
    note: createOrderItemNoteFromProduct(product),
    image: getProductImage(product),
    product: {
        id: _get(product, "id"),
        manual_entry: _get(product, "manual_entry")
    },
    ...getCalculations(product)
})

const useAddPOProduct = ({ addOrderItem }) => {
    const actions = useActions({ showErrorNotification })
    const queryClient = useQueryClient()
    const { productFetchStatuses, setProductFetchStatus, isAnyProductLoading } = useProductFetchStatuses()

    const getProduct = useCallback(
        productId =>
            queryClient.fetchQuery(["product", productId], () => fetch.get(`/products/${productId}`), {
                staleTime: ONE_MINUTE_IN_MS
            }),
        [queryClient]
    )

    const getCombination = useCallback(
        combinationId =>
            queryClient.fetchQuery(["combination", combinationId], () => fetch.get(`/combinations/${combinationId}`), {
                staleTime: ONE_MINUTE_IN_MS
            }),
        [queryClient]
    )

    const handleProductSelect = useCallback(
        async product => {
            const { product_id, combination_id } = product

            setProductFetchStatus(product_id, true)

            try {
                const { data: productDetails } = await getProduct(product_id)

                let orderItem = { ...productDetails, ...product, discount: productDetails.discount }

                if (combination_id) {
                    const { data: combination } = await getCombination(combination_id)
                    orderItem = { ...orderItem, combination }
                }

                addOrderItem(orderItemProductAdapter(orderItem))
            } catch (error) {
                actions.showErrorNotification()
            } finally {
                setProductFetchStatus(product_id, false)
            }
        },
        [setProductFetchStatus, getProduct, getCombination, actions, addOrderItem]
    )

    return { handleProductSelect, productFetchStatuses, isAnyProductLoading }
}

export default useAddPOProduct
