import React, { Component } from "react"
import { connect } from "react-redux"

import ContentHeader from "./ContentHeader"

class ContentHeaderContainer extends Component {
    render() {
        return <ContentHeader {...this.props} />
    }
}

const mapStateToProps = state => {
    return {
        activeSession: state.ui.session.activeSession,
        activeClient: state.ui.session.activeClient
    }
}

export default connect(mapStateToProps)(ContentHeaderContainer)
