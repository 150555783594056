import { combineReducers } from "redux"

import current from "./current"
import list from "./list"
import searchAndSortList from "./searchAndSortList"
import createFastReview from "./createFastReview"
import combinations from "./combinations"
import conditions from "./conditions"
import filters from "./filters"
import aggregates from "./aggregates"

const productsReducer = combineReducers({
    current,
    list,
    searchAndSortList,
    createFastReview,
    combinations,
    conditions,
    filters,
    aggregates
})

export default productsReducer
