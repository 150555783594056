import * as constants from "./../../constants/index"

function type(state = [], action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_TYPE:
            return []
        case constants.CHANGE_TYPE:
            return [...value]
        default:
            return state
    }
}

export default type
