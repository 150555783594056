import React from "react"
import getStyles from "helpers/getCxFromStyles"

import styles from "./GalleryArrow.css"

const GalleryArrow = ({ customClass, onClick }) => {
    const cx = getStyles(styles)

    return (
        <button onClick={onClick} className={cx("arrow-wrapper")}>
            <span className={cx("arrow", customClass)}></span>
        </button>
    )
}

export default GalleryArrow
