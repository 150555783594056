import React, { useEffect, Fragment } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"

import withStyles from "HOC/withStyles"
import fetch from "helpers/fetch"

import { Modal, SelectWithSearch, ProductSearch, Button, Input } from "ui"

import Dropzone from "./components/Image"

import closeIcon from "assets/close-blue.svg"

import styles from "./styles.css"
import select from "./overrides/select.css"
import stylesProductSearch from "./overrides/ProductSearch.css"
import stylesInput from "./overrides/input.css"

const IMAGE_DIMENSIONS = {
    width: 1024
}

const SectionModalImageWithProduct = ({ onClose, cx, form, isSubmitting }) => {
    const setImage = (field, image) => {
        form.setFieldValue(field, image)
        form.setErrors({
            ...form.errors,
            [field]: undefined
        })
    }

    useEffect(() => {
        if (form.values.product_id && !form.values.single_image_product) {
            fetch.get(`/products/${form.values.product_id}`).then(product => {
                form.setFieldValue(
                    "single_image_product",
                    !!form.values.single_image_product
                        ? form.values.single_image_product
                        : product.data.images.gallery[0]
                )
                form.setFieldValue("settings.heading", product.data.name)
                form.setFieldValue("settings.subheading", product.data.brand ? product.data.brand.name : "")
            })
        }
    }, [form.values.product_id])

    const removeProduct = () => {
        form.setFieldValue("product_id", null)
        form.setFieldValue("single_image_product", null)
        form.setFieldValue("settings.heading", "")
        form.setFieldValue("settings.subheading", "")
    }

    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Image with product</p>

            <Dropzone
                image={form.values.single_image}
                isError={!!form.errors.single_image}
                setImage={image => setImage("single_image", image)}
                imageDimensions={IMAGE_DIMENSIONS}
            />

            <p className={cx("label")}>Image alignment:</p>

            <Field name="settings.alignment" cla>
                {({ field, meta }) => (
                    <SelectWithSearch
                        {...field}
                        placeholder="- Please select -"
                        values={[{ value: "left", label: "Left" }, { value: "right", label: "Right" }]}
                        setValue={({ value }) => {
                            field.onChange({ target: { name: field.name, value } })
                        }}
                        customStyles={select}
                        error={meta.error}
                        isClearBlocked={true}
                        withoutFetch
                    />
                )}
            </Field>

            <div className={cx("line")} />

            {!!form.values.product_id ? (
                <Fragment>
                    <p className={cx("header-small")}>
                        Product
                        <span className={cx("button")} onClick={removeProduct}>
                            <img src={closeIcon} alt="close" /> Delete
                        </span>
                    </p>

                    <Dropzone
                        image={form.values.single_image_product}
                        isError={!!form.errors.single_image_product}
                        setImage={image => setImage("single_image_product", image)}
                    />

                    <Field name="settings.heading">
                        {({ field, meta }) => (
                            <Input
                                {...field}
                                label="Product name"
                                onChange={({ event }) => field.onChange(event)}
                                customStyles={stylesInput}
                                error={meta.error}
                            />
                        )}
                    </Field>

                    <Field name="settings.subheading">
                        {({ field, meta }) => (
                            <Input
                                {...field}
                                label="Subheading"
                                onChange={({ event }) => field.onChange(event)}
                                customStyles={stylesInput}
                                error={meta.error}
                            />
                        )}
                    </Field>
                </Fragment>
            ) : (
                <Fragment>
                    <p className={cx("header-small")}>Add product</p>

                    <div
                        className={cx("productSearchWrapper", {
                            isError: !!form.errors.product_id
                        })}
                    >
                        <ProductSearch
                            customStyles={stylesProductSearch}
                            handleSelectProduct={productID => {
                                form.setFieldValue("product_id", productID)
                                form.setErrors({
                                    ...form.errors,
                                    product_id: undefined
                                })
                            }}
                            placeholder="Search and add product..."
                            availableOnWeb
                        />
                    </div>
                </Fragment>
            )}

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    label="Save"
                    onClick={() => form.submitForm()}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

SectionModalImageWithProduct.propTypes = {
    onClose: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    cx: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired
}

export default withStyles(SectionModalImageWithProduct, styles)
