import React from "react"
import CollectionList from "modules/ContentModule/components/CollectionList"

const List = () => {
    return (
        <CollectionList
            collectionLabel="News"
            collectionLabelPlural="News"
            basePath="/content/news"
            apiUrl="/posts"
            collectionModel="posts"
            canChangeLayout
        />
    )
}

export default List
