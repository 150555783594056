import React, { Component } from "react"
import EditableList from "components/Reusable/EditableList"
import config from "./configProducts"
import ListingLayout from "../../ListingLayout/index"

class ListingProducts extends Component {
    state = {
        currentPage: 0,
        perPage: 4 * 16
    }

    componentDidMount() {
        this.refreshData(this.props)
    }

    componentDiUpdate(prevProps) {
        const { categoryId } = this.props
        if (categoryId !== prevProps.categoryId) {
            this.refreshData(this.props)
        }
    }

    refreshData(props) {
        const { categoryId, getProducts } = props
        const { perPage, currentPage } = this.state
        getProducts({ categories: [categoryId], length: perPage, page: currentPage + 1 })
    }

    componentWillUnmount() {
        const { resetProducts } = this.props
        resetProducts()
    }

    handleSelectPage = page => {
        this.setState(
            {
                currentPage: page
            },
            () => {
                this.refreshData(this.props)
            }
        )
    }

    render() {
        const { categories, categoryId, products } = this.props
        const { currentPage, perPage } = this.state

        let listTitle = "Products"
        let returnLink = null

        if (categoryId) {
            const category = categories.find(item => item.id === Number(categoryId))

            if (category) {
                listTitle = category.name

                returnLink = {
                    label: "Categories",
                    link: `/settings/categories`
                }
            }
        }

        return (
            <ListingLayout returnLink={returnLink}>
                <EditableList
                    config={config}
                    list={products.list && products.list.data ? products.list.data : []}
                    listTitle={listTitle}
                    editUrlPrefix={"/settings/categories/card"}
                    isLoading={products.list.isLoading}
                    handleContentLink={id => `/products/${id}`}
                    isNoEdit
                    isNoDelete
                    perPage={perPage}
                    pagesCount={products.list.meta.last_page}
                    currentPage={currentPage}
                    handleSelectPage={this.handleSelectPage}
                    isDisabledLocalPagination={true}
                />
            </ListingLayout>
        )
    }
}

export default ListingProducts
