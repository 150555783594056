import React, { Component } from "react"
import { Link } from "react-router-dom"
import idx from "idx"
import withStyles from "HOC/withStyles"
import style from "./list.module.css"

import ContentHeader from "components/Content/ContentHeader/index"
import SearchBar from "components/Reusable/SearchBar"
import Button from "components/Reusable/Form/Button/index"
import Modal from "components/Modal/Modal"
import ReviewModal from "components/Reusable/ReviewModal"

import { Table } from "modules/ClientModule/components"

import customContentHeaderStyles from "./overrides/contentheader.module.css"
import customSearchBarStyles from "./overrides/searchbar.module.css"

import pencilIcon from "assets/pencil.svg"

class List extends Component {
    state = {
        filtersCount: null
    }

    render() {
        const {
            cx,
            handleSearch,
            handleExport,
            isExporting,
            clearInput,
            query,
            clientsList,
            currentPage,
            sortBy,
            sortDirection,
            handleSort,
            lastPage,
            isLoaded,
            getClientById,
            handleLoadMoreClients,
            sortKeys,
            canAdd,
            openSpreadsheetModal,
            isSpreadsheetLoading,
            isSpreadsheetModalOpen,
            spreadsheetData,
            closeSpreadsheetModal,
            saveSpreadsheet,
            isSpreadsheetSaving,
            total,
            clientsCount,
            params,
            autoSelectMyClients
        } = this.props
        const { filtersCount } = this.state

        return (
            <section className={cx("root")}>
                <ContentHeader
                    customStyles={customContentHeaderStyles}
                    title="Contact"
                    cta={[
                        (filtersCount > 0 || query) && !!idx(clientsList, _ => _.data.length) && (
                            <Button
                                className="third-button"
                                icon={<img src={pencilIcon} alt="" />}
                                label="Edit"
                                onClick={openSpreadsheetModal}
                                isLoading={isSpreadsheetLoading}
                            />
                        ),
                        canAdd() && (
                            <Link to="/contact/add">
                                <Button className="first-button" label="Create New" />
                            </Link>
                        )
                    ]}
                    dropdown={{
                        elements: [{ label: "Export to CSV", value: "csv" }, { label: "Export to XLS", value: "xls" }],
                        onClick: handleExport,
                        isLoading: isExporting
                    }}
                />
                <div className="round-border">
                    <SearchBar
                        placeholder="Search Contact"
                        handleSearch={handleSearch}
                        clearInput={clearInput}
                        filter={query || ""}
                        params={params}
                        isAdvanced={true}
                        customStyles={customSearchBarStyles}
                        handleGetFiltersCount={this.handleGetFiltersCount}
                        autoSelectMyClients={autoSelectMyClients}
                    />
                    <Table
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        handleSort={handleSort}
                        getClientById={getClientById}
                        handleLoad={handleLoadMoreClients}
                        isLoaded={isLoaded}
                        currentPage={currentPage}
                        lastPage={lastPage}
                        total={total}
                        clientsCount={clientsCount}
                        list={clientsList.data}
                        sortKeys={sortKeys}
                        length={clientsList.data.length}
                    />
                </div>
                {isSpreadsheetModalOpen && (
                    <Modal>
                        <ReviewModal
                            handleHide={closeSpreadsheetModal}
                            handleSave={saveSpreadsheet}
                            fileData={spreadsheetData}
                            isSaving={isSpreadsheetSaving}
                            title="Contact"
                        />
                    </Modal>
                )}
            </section>
        )
    }

    handleGetFiltersCount = filtersCount => {
        this.setState({
            filtersCount
        })
    }
}

export default withStyles(List, style)
