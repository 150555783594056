import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { useActions } from "hooks"
import { createShippingMethod, updateShippingMethod } from "actions/shippingMethods"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import closeIcon from "assets/close.svg"
import inputStyles from "./overrides/InputText.css"
import textareaStyles from "./overrides/Textarea.css"
import styles from "./AddEditShippingMethod.module.css"
import withStyles from "HOC/withStyles"
import { Button, Input, Textarea } from "components/Reusable/Form"

const schema = Yup.object().shape({
    name: Yup.string().required("This field is required."),
    description: Yup.string().nullable()
})

const AddEditShippingMethod = ({ cx, edit, handleClose, initialValues }) => {
    const actions = useActions({
        createShippingMethod,
        showSuccessNotification,
        showErrorNotification,
        updateShippingMethod
    })

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isButtonClicked, setIsButtonClicked] = useState(false)

    const handleSubmit = async (values, { setErrors }) => {
        const { name, description, id } = values

        const data = { name, description }

        const action = id ? () => actions.updateShippingMethod(id, data) : () => actions.createShippingMethod(data)

        setIsSubmitting(true)

        try {
            await action()

            actions.showSuccessNotification()
            handleClose()
        } catch (data) {
            if (data) {
                const { errors: dataErrors, message } = data
                const errors = dataErrors || (message ? { message } : null)

                errors && setErrors(errors)
            }
            actions.showErrorNotification()
            setIsSubmitting(false)
        }
    }

    const onButtonClick = () => {
        setIsButtonClicked(true)
    }

    return (
        <div className={cx("modal-background")}>
            <div className={cx("modal-cover")}>
                <img src={closeIcon} alt="close" className={cx("close-icon")} onClick={handleClose} />

                <h2 className={cx("modal-title")}>{edit ? "Edit" : "New"} shipping method</h2>

                <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {({ errors, handleSubmit, setFieldValue, touched, values }) => {
                        return (
                            <form className={cx("form")} onSubmit={handleSubmit}>
                                <div className={cx("row")}>
                                    <Field name="name">
                                        {({ field }) => {
                                            return (
                                                <Fragment>
                                                    <label className={cx("label")}>
                                                        Method name <span>*</span>:
                                                    </label>
                                                    <Input
                                                        customStyles={inputStyles}
                                                        error={isButtonClicked && errors.name}
                                                        onChange={value => {
                                                            isButtonClicked && setIsButtonClicked(false)
                                                            setFieldValue(field.name, value.event.target.value)
                                                        }}
                                                        value={values.name}
                                                    />
                                                </Fragment>
                                            )
                                        }}
                                    </Field>
                                </div>
                                <div className={cx("row")}>
                                    <Field name="description">
                                        {({ field }) => {
                                            return (
                                                <Textarea
                                                    customStyles={textareaStyles}
                                                    label="Description:"
                                                    placeholderText="Click here to add a description..."
                                                    error={touched.description && errors.description}
                                                    value={values.description || ""}
                                                    onChange={value => {
                                                        setFieldValue(field.name, value.event.target.value)
                                                    }}
                                                />
                                            )
                                        }}
                                    </Field>
                                </div>
                                <div className={cx("buttons")}>
                                    <span className={cx("form-cancel")} onClick={handleClose}>
                                        Cancel
                                    </span>
                                    <Button
                                        type="submit"
                                        className={["first-button", "submit-button"]}
                                        label={edit ? "Save" : "Create"}
                                        isLoading={isSubmitting}
                                        onClick={onButtonClick}
                                    />
                                </div>
                            </form>
                        )
                    }}
                </Formik>
            </div>
        </div>
    )
}

AddEditShippingMethod.defaultProps = {
    edit: false,
    initialValues: {
        name: "",
        description: ""
    }
}

AddEditShippingMethod.propTypes = {
    edit: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    initialValues: PropTypes.object
}

export default withStyles(AddEditShippingMethod, styles)
