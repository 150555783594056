import { PRODUCT_TYPE_KEYS } from "./productTypeKeys"

export const INITIAL_FILTERS = {
    type: PRODUCT_TYPE_KEYS.PRODUCTS,
    brand: null,
    category: null,
    subCategory: null,
    inStock: null,
    location: null,
    manualEntries: false,
    dateRange: null
}

export const INITIAL_DATE_RANGE = { startDate: null, endDate: null }
