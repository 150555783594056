import React, { useState, useEffect } from "react"

import { default as ModalComponent } from "ui/Modal"
import Modal from "./modal"
import withStyles from "HOC/withStyles"
import styles from "./styles/modal.css"
import modalStyles from "./overrides/modal.css"

const ModalContainer = props => {
    const { isOpen, handleClose, setError, submit, error } = props

    const selection = props.selection || {}

    const [name, setName] = useState(selection.name || "")
    const [description, setDescription] = useState(selection.price || "")
    const [type, setType] = useState(selection.type || "private")
    const [purge, setPurge] = useState(false)

    useEffect(() => {
        setType(selection.type)
    }, [selection.type])

    useEffect(() => {
        setName(selection.name || "")
        setDescription(selection.description || "")
    }, [selection.name, selection.description, selection.price])

    const clearInputs = () => {
        setName("")
        setDescription("")
        setType("private")
    }

    // TODO: Remove this workaround and fix problem
    useEffect(() => {
        if (purge && !error) {
            clearInputs()
        }
        setPurge(false)
    }, [purge])

    const fieldsProps = {
        name: {
            label: "Write a selection name...",
            value: name,
            onChange: e => {
                setName(e.event.target.value)
                if (error && error.name) {
                    setError({ ...error, name: null })
                }
            },
            isPlaceholder: true,
            error: error && error.name,
            onFocus: () => error && setError({ ...error, name: null })
        },
        description: {
            label: "Click here to add a short description...",
            value: description,
            onChange: e => {
                setDescription(e.event.target.value)
                if (error && error.description) {
                    setError({ ...error, description: null })
                }
            },
            isPlaceholder: true,
            error: error && error.description
        },
        type: {
            label: "",
            value: type,
            onChange: e => {
                setType(e.value)
                if (error && error.type) {
                    setError({ ...error, type: null })
                }
            },
            values: [
                {
                    display: "Private",
                    value: "private"
                },
                {
                    display: "Public",
                    value: "public"
                }
            ],
            error: error && error.type
        }
    }

    const handleButton = () => {
        submit({
            id: selection.id,
            description: description,
            name: name,
            type: type
        }).then(() => setPurge(true))
    }

    return (
        <ModalComponent customStyles={modalStyles} isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <Modal {...props} handleButton={handleButton} closeModal={handleClose} fieldsProps={fieldsProps}></Modal>
        </ModalComponent>
    )
}

export default withStyles(ModalContainer, styles)
