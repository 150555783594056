import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Field, FieldArray } from "formik"
import PropTypes from "prop-types"
import classnames from "classnames"

import withStyles from "HOC/withStyles"

import { DatePickerWithInput, Input, SelectWithSearch, Textarea } from "ui"
import { ExpandIcon } from "@butterfly-frontend/ui"
import { PricingList } from "modules/ProductsModule/components"

import styles from "./styles.css"
import textareaStyles from "../../overrides/textarea.css"
import inputStyles from "../../overrides/input.css"
import datePickerStyles from "../../overrides/datepicker.css"
import addIcon from "assets/add.svg"
import deleteIcon from "assets/delete.svg"

const FinancialsSection = ({ cx, form, priceStructure }) => {
    const [isBrandRatesDetailsOpen, setIsBrandRatesDetailsOpen] = useState(false)
    const addDiscountDisabled = form.values.discounts.filter(item => !item.name || !item.value).length > 0

    const isAddBrandRateEnabled = useMemo(() => !(form.values.rate && form.values.rate.id), [form.values.rate])

    const selectedPriceStructure = priceStructure.filter(item => item.id === form.values.pricing_structure_id)[0] || {}

    const onRemoveBrandRate = useCallback(
        ({ id }) => {
            const filteredBrandRates = form.values.brand_rates.filter(brandRate => brandRate.id !== id)
            form.setFieldValue("brand_rates", filteredBrandRates)
            form.setFieldValue("brand_rates_to_remove", [...form.values.brand_rates_to_remove, id])
        },
        [form]
    )

    useEffect(() => {
        if (form.values.rate && !form.values.rate.value_percentage && !form.values.rate.effect_date) {
            form.setFieldValue("rate", null)
        }
    }, [form.values.rate])

    return (
        <div className={cx("root")}>
            <div className={cx("section", "brandCostSection")}>
                <h2 className={cx("header")}>Brand Cost</h2>
                <div className={cx("discountRow", "discountRowHeader")}>
                    <div className={cx("brandDiscountDate")}>Effect date</div>
                    <div className={cx("brandDiscountValue")}>Value</div>
                </div>
                <div key="1" className={cx("discountRow")}>
                    <div className={cx("brandDiscountDate")}>
                        <Field name="rate.effect_date">
                            {({ field }) => (
                                <DatePickerWithInput
                                    {...field}
                                    date={field.value}
                                    handleSelectDate={value => form.setFieldValue(field.name, value)}
                                    error={form.errors[field.name]}
                                    customStyles={datePickerStyles}
                                    isDisabled={!isAddBrandRateEnabled}
                                    disableClear={!isAddBrandRateEnabled}
                                />
                            )}
                        </Field>
                    </div>

                    <div className={cx("brandDiscountValue")}>
                        <Field name="rate.value_percentage">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Value"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                    isPlaceholder={true}
                                    innerLabel="%"
                                    isDisabled={!isAddBrandRateEnabled}
                                    value={field.value}
                                    type="number"
                                    isInnerLabelRight
                                />
                            )}
                        </Field>
                    </div>
                </div>
                <div className={cx("buttonsWrapper")}>
                    <div
                        className={cx(
                            "add-more",
                            "add-more-brand-rate",
                            isAddBrandRateEnabled ? "add-more-disabled" : ""
                        )}
                        onClick={!isAddBrandRateEnabled ? () => form.setFieldValue("rate", null) : () => {}}
                    >
                        <span>
                            <img src={addIcon} alt="addImage" />
                            <span>Add new brand cost</span>
                        </span>
                    </div>
                    <div
                        className={classnames(styles.detailsButton, {
                            [styles.expanded]: isBrandRatesDetailsOpen
                        })}
                        onClick={() => setIsBrandRatesDetailsOpen(prevState => !prevState)}
                    >
                        {isBrandRatesDetailsOpen ? "Hide" : "Details"}
                        <ExpandIcon className={styles.detailsButtonIcon} />
                    </div>
                </div>
                {isBrandRatesDetailsOpen && (
                    <PricingList listData={form.values.brand_rates} onRemoveRow={onRemoveBrandRate} />
                )}
            </div>
            <div className={cx("section")}>
                <h2 className={cx("header")}>Discounts</h2>

                {form.values.discounts.length > 0 && (
                    <div className={cx("discountRow", "discountRowHeader")}>
                        <div className={cx("discountName")}>Discount name</div>
                        <div className={cx("discountValue")}>Value</div>
                        <div className={cx("discountDescription")}>Description</div>
                    </div>
                )}

                <FieldArray
                    name="discounts"
                    render={arrayHelpers => (
                        <div>
                            {form.values.discounts.map((item, index) => (
                                <div key={index} className={cx("discountRow")}>
                                    <div className={cx("discountName")}>
                                        <Field name={`discounts.${index}.name`}>
                                            {({ field }) => (
                                                <Input
                                                    {...field}
                                                    label="Write discount name"
                                                    onChange={({ event }) => field.onChange(event)}
                                                    error={form.errors[field.name]}
                                                    customStyles={inputStyles}
                                                    isPlaceholder={true}
                                                />
                                            )}
                                        </Field>
                                    </div>

                                    <div className={cx("discountValue")}>
                                        <Field name={`discounts.${index}.value`}>
                                            {({ field }) => (
                                                <Input
                                                    {...field}
                                                    label="Value"
                                                    onChange={({ event }) => field.onChange(event)}
                                                    error={form.errors[field.name]}
                                                    customStyles={inputStyles}
                                                    isPlaceholder={true}
                                                    innerLabel="%"
                                                    type="number"
                                                    isInnerLabelRight
                                                />
                                            )}
                                        </Field>
                                    </div>

                                    <div className={cx("discountDescription")}>
                                        <Field name={`discounts.${index}.description`}>
                                            {({ field }) => (
                                                <Input
                                                    {...field}
                                                    label="Write a description..."
                                                    onChange={({ event }) => field.onChange(event)}
                                                    error={form.errors[field.name]}
                                                    customStyles={inputStyles}
                                                    isPlaceholder={true}
                                                />
                                            )}
                                        </Field>
                                    </div>

                                    <img
                                        src={deleteIcon}
                                        alt="deleteImage"
                                        className={cx("deleteIcon")}
                                        onClick={() => arrayHelpers.remove(index)}
                                    />
                                </div>
                            ))}
                            <div
                                className={cx("add-more", addDiscountDisabled ? "add-more-disabled" : "")}
                                onClick={() =>
                                    !addDiscountDisabled && arrayHelpers.push({ name: "", value: "", description: "" })
                                }
                            >
                                <span>
                                    <img src={addIcon} alt="addImage" />
                                    <span>Add {form.values.discounts.length > 0 && "another"} discount</span>
                                </span>
                            </div>
                        </div>
                    )}
                />
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>Pricing</h2>
                <ul className={cx("pricing")}>
                    <li className={cx("pricingMarkupToUsdRetail")}>
                        <Field name="pricing_mu_usd_retail">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Mark-Up To USD Retail"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("pricingMarkupMemo")}>
                        <Field name="pricing_mu_memo">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Mark-Up Memo"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("pricingPriceStructure")}>
                        <Field name="pricing_structure_id">
                            {({ field }) => (
                                <SelectWithSearch
                                    name={field.name}
                                    value={selectedPriceStructure.name}
                                    placeholder="- Please select -"
                                    label="Price Structure"
                                    values={priceStructure.map(item => ({
                                        label: item.name,
                                        value: item
                                    }))}
                                    setValue={({ value }) => {
                                        form.setFieldValue("pricing_structure_id", value.id)
                                    }}
                                    withoutFetch
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("pricingExchangeRate")}>
                        <Field name="pricing_exchange_rate">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Exchange rate"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("pricingNote")}>
                        <span className={cx("label")}>Pricing note:</span>
                        <Field name="pricing_note">
                            {({ field }) => (
                                <Textarea
                                    {...field}
                                    label="Click here to add a note..."
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    isPlaceholder
                                    customStyles={textareaStyles}
                                />
                            )}
                        </Field>
                    </li>
                </ul>
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>Payment terms</h2>
                <Field name="payment_terms">
                    {({ field }) => (
                        <Textarea
                            {...field}
                            label="Click here to add a payment terms..."
                            onChange={({ event }) => field.onChange(event)}
                            error={form.errors[field.name]}
                            isPlaceholder
                            customStyles={textareaStyles}
                        />
                    )}
                </Field>
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>Bank information</h2>
                <Field name="bank_information">
                    {({ field }) => (
                        <Textarea
                            {...field}
                            label="Click here to add a Bank information..."
                            onChange={({ event }) => field.onChange(event)}
                            error={form.errors[field.name]}
                            isPlaceholder
                            customStyles={textareaStyles}
                        />
                    )}
                </Field>
            </div>
        </div>
    )
}

FinancialsSection.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    priceStructure: PropTypes.array.isRequired
}

export default withStyles(FinancialsSection, styles)
