import React from "react"
import withStyles from "HOC/withStyles"

import styles from "./CalendarList.css"

import withPermissions from "HOC/withPermissions"
import Checkbox from "ui/Checkbox"
import CalendarSubmenu from "./CalendarSubmenu"
import ButtonShowHide from "modules/CalendarModule/components/CalendarList/CalendarToggleLists"

import publicCalendarIcon from "assets/ico-website.svg"
import privateCalendarIcon from "assets/ico-lock.svg"
import addCalendarIcon from "assets/add.svg"
// TODO: add when will support import calendar
// import importCalendarIcon from "assets/ico-upload.svg"

const CalendarList = ({
    cx,
    handleCheckboxChange,
    handleDisplayOnlyCalendar,
    handleDelete,
    handleEdit,
    handleExport,
    isExpanded,
    privateCount,
    privateList,
    publicCount,
    publicList,
    toggleModal,
    handleCheckboxesTypeChange
}) => {
    const areAllPublicCalendarsSelected = publicList.every(item => item.is_selected)
    const areAllPrivateCalendarsSelected = privateList.every(item => item.is_selected)

    return (
        <div className={cx("calendar-list", { "calendar-list--expanded": isExpanded })}>
            <div className={cx("calendar-list-header")}>
                <img src={publicCalendarIcon} alt={""} className={cx("calendar-list-header-icon")} />
                <span className={cx("calendar-list-header-title")}>Public</span>
                {publicCount > 1 && (
                    <ButtonShowHide
                        isDisabled={false}
                        isActive={areAllPublicCalendarsSelected}
                        onClick={() => handleCheckboxesTypeChange(areAllPublicCalendarsSelected, "public")}
                    />
                )}
            </div>

            <ul className={cx("calendar-list-container")}>
                {publicList.map((calendar, key) => (
                    <li key={key}>
                        <Checkbox
                            color={calendar.color}
                            checked={calendar.is_selected}
                            name={calendar.title}
                            title={calendar.title}
                            onChange={() => handleCheckboxChange(calendar, "public")}
                        />
                        {calendar.is_system ? (
                            <CalendarSubmenu
                                id={`public_${key}`}
                                menuLinks={[
                                    {
                                        name: "Show this only",
                                        onClick: () => handleDisplayOnlyCalendar(calendar.id)
                                    },
                                    {
                                        name: "Integrate calendar",
                                        onClick: () => handleExport(calendar)
                                    }
                                ]}
                            />
                        ) : (
                            <CalendarSubmenu
                                id={`private_${key}`}
                                menuLinks={[
                                    {
                                        name: "Show this only",
                                        onClick: () => handleDisplayOnlyCalendar(calendar.id)
                                    },
                                    {
                                        name: "Edit",
                                        onClick: () => handleEdit(calendar),
                                        hidden: !calendar.is_editable
                                    },
                                    {
                                        name: "Integrate calendar",
                                        onClick: () => handleExport(calendar)
                                    },
                                    {
                                        name: "Delete",
                                        onClick: () => handleDelete(calendar),
                                        hidden: !calendar.is_deletable
                                    }
                                ]}
                            />
                        )}
                    </li>
                ))}
            </ul>

            <div className={cx("calendar-list-header")}>
                <img src={privateCalendarIcon} alt={""} className={cx("calendar-list-header-icon")} />
                <span className={cx("calendar-list-header-title")}>Private</span>
                {privateCount > 1 && (
                    <ButtonShowHide
                        isDisabled={false}
                        isActive={areAllPrivateCalendarsSelected}
                        onClick={() => handleCheckboxesTypeChange(areAllPrivateCalendarsSelected, "private")}
                    />
                )}
            </div>

            <ul className={cx("calendar-list-container")}>
                {privateList.map((calendar, key) => (
                    <li key={key}>
                        <Checkbox
                            checked={calendar.is_selected}
                            color={calendar.color}
                            name={calendar.title}
                            title={calendar.title}
                            onChange={() => handleCheckboxChange(calendar, "private")}
                        />
                        <CalendarSubmenu
                            id={`private_${key}`}
                            menuLinks={[
                                {
                                    name: "Show this only",
                                    onClick: () => handleDisplayOnlyCalendar(calendar.id)
                                },
                                {
                                    name: "Edit",
                                    onClick: () => handleEdit(calendar),
                                    hidden: !calendar.is_editable
                                },
                                {
                                    name: "Integrate calendar",
                                    onClick: () => handleExport(calendar)
                                },
                                {
                                    name: "Delete",
                                    onClick: () => handleDelete(calendar),
                                    hidden: !calendar.is_deletable
                                }
                            ]}
                        />
                    </li>
                ))}
            </ul>

            <button className={cx("calendar-list-add")} onClick={() => toggleModal("createNewCalendarModalVisible")}>
                <img src={addCalendarIcon} alt={""} />
                <span>Add new calendar</span>
            </button>
            {/* TODO: add when the backend will support */}
            {/* <div className={cx("divider")} />
            <button className={cx("calendar-list-add")} onClick={() => toggleModal("importCalendarModalVisible")}>
                <img src={importCalendarIcon} alt={""} />
                <span>Import calendar</span>
            </button> */}
        </div>
    )
}

const ComponentWithStyles = withStyles(CalendarList, styles)
export default withPermissions(ComponentWithStyles)
