import React, { Fragment } from "react"

import Layout from "components/Reusable/Layout"
import Loader from "components/Reusable/Loader/Loader"
import Skeleton from "components/Skeleton"
import { Button, SelectWithSearch, EmptyList, Pagination } from "ui"
import ListItem from "./components/ListItem"
import stylesEmptyList from "./overrides/emptyList.css"
import stylesLayout from "./overrides/layout.css"
import { formatPriceByComa } from "helpers/units"
import { SORT_OPTIONS } from "./configs"
import productIcon from "assets/ico-projects.svg"
import stylesSort from "./overrides/sort.css"
import paginationStyles from "./overrides/pagination.css"

const List = props => {
    const { locations, cx, setCreateModalIsOpen, openEditModal, openDeleteModal, changePage } = props

    return (
        <Layout customStyles={stylesLayout} title="Locations" renderActions={renderActions}>
            <div className={cx("listContainer")}>
                <Skeleton
                    fetchStatus={locations.fetchStatus}
                    component={
                        <div className={cx("loader")}>
                            <Loader />
                        </div>
                    }
                    render={() => (
                        <Fragment>
                            {locations.data.length === 0 && renderNotFound()}

                            {locations.data.length > 0 && (
                                <Fragment>
                                    {renderListHeader()}
                                    {renderList()}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                />

                <Pagination
                    customStyles={paginationStyles}
                    currentPage={(locations.meta || {}).current_page || 1}
                    pages={(locations.meta || {}).last_page || 1}
                    isLoaded={(locations.fetchStatus || {}).isLoaded}
                    onChange={changePage}
                />
            </div>
        </Layout>
    )

    function renderActions() {
        return (
            <span className={cx("actions")}>
                <Button
                    className={`first-button ${cx("first-button")}`}
                    label="Create New"
                    onClick={() => setCreateModalIsOpen(true)}
                />
            </span>
        )
    }

    function renderListHeader() {
        const { locations } = props

        return (
            <header className={cx("listHeaderContainer")}>
                <div className={cx("listHeaderSide")}>
                    <div className={cx("listHeaderMeta")}>
                        <b>
                            {locations.meta.from}-{locations.meta.to}
                        </b>{" "}
                        over {locations.meta.total} results
                    </div>
                </div>
                <div className={cx("listHeaderSide")}>{renderListHeaderSortBy()}</div>
            </header>
        )
    }

    function renderListHeaderSortBy() {
        const { filterSortingIndex, filterSortingHandleChange } = props

        return (
            <div className={cx("listHeaderSideItem")}>
                <span className={cx("listHeaderLabel")}>Sort By:</span>
                <div className={cx("listHeaderValue")}>
                    <SelectWithSearch
                        values={SORT_OPTIONS.map((element, index) => {
                            return {
                                value: index,
                                label: element.display
                            }
                        })}
                        customStyles={stylesSort}
                        value={(SORT_OPTIONS[filterSortingIndex] || {}).display || ""}
                        setValue={({ value }) => filterSortingHandleChange(value)}
                        withoutFetch
                        isClearBlocked
                    />
                </div>
            </div>
        )
    }

    function renderList() {
        return (
            <div className={cx("listWrapper")}>
                <div className={cx("list", "viewList")}>
                    <ListItem>
                        {({ cx }) => (
                            <div className={cx("listItem", "headerListItem")}>
                                <div className={cx("nameContainer")}>
                                    <span>Location Name</span>
                                </div>
                                <div className={cx("optionContainer")}></div>
                            </div>
                        )}
                    </ListItem>

                    {locations.data.map(locationItem => {
                        return (
                            <ListItem
                                key={locationItem.id}
                                locationItem={locationItem}
                                renderPrice={renderPrice}
                                handleEdit={id => openEditModal(id)}
                                handleDelete={(id, name) => openDeleteModal(name, id)}
                            ></ListItem>
                        )
                    })}
                </div>
            </div>
        )
    }

    function renderPrice(price) {
        return (
            <Fragment>
                $&nbsp;<strong>{`${formatPriceByComa(price, true)}.${formatPriceByComa(price, false)}`}</strong>
            </Fragment>
        )
    }

    function renderNotFound() {
        return <EmptyList customStyles={stylesEmptyList} message="No location Added" icon={productIcon}></EmptyList>
    }
}

export default List
