import React, { useEffect, useState } from "react"
import { Modal as ModalComponent } from "ui"
import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"

import SelectFromListModal from "components/Modal/SelectFromList/SelectFromListContainer"

import styles from "./styles.css"
import { objToQueryString } from "helpers/urls"

const Modal = props => {
    const { isOpen, handleClose, onSubmit, data } = props

    const [isSaving, SetIsSaving] = useState(false)

    useEffect(() => {
        SetIsSaving(false)
    }, [isOpen])

    const fetchItemsList = (query, { page, length, exclude = "" }) => {
        const params = {
            query,
            page,
            length,
            exclude,
            available_on_web: true
        }
        return fetch.get(`/products?${objToQueryString(params)}`).then(data => ({ payload: { data } }))
    }

    const handlePrepareItem = item => {
        return {
            name: item.name,
            img: item.media && item.media.url,
            right: "",
            left: item.brand
        }
    }

    const handleSubmit = immediateDelivery => {
        SetIsSaving(true)
        onSubmit(
            immediateDelivery.map(element => {
                return { id: element.id }
            })
        )
    }

    const excludedList = () => {
        return (data.immediate_deliveries || []).map(element => element.product_id)
    }

    return (
        <ModalComponent isOpen={isOpen} closeModal={handleClose} preventClickOutside={true}>
            <SelectFromListModal
                isSaving={isSaving}
                handleClose={handleClose}
                selectedItems={[]}
                exclude={excludedList()}
                typeItems="products"
                prepareItem={handlePrepareItem}
                handleSubmit={handleSubmit}
                fetchItemsList={fetchItemsList}
            />
        </ModalComponent>
    )
}

export default withStyles(Modal, styles)
