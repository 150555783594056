import React, { useState } from "react"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
    H2,
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableRow,
    TableCell,
    useTableSelectedRows,
    Checkbox,
    AddIcon,
    Button,
    EmptyInternalList,
    AlertErrorIcon,
    ListIcon
} from "@butterfly-frontend/ui"

import { MENU } from "constants/index"
import withPermissions from "HOC/withPermissions"
import ContentHeader from "components/Content/ContentHeader/index"
import { AssignUserModal } from "components/Content/ContentMain/Settings/Warehouses"
import { getUserManagementTabs } from "components/Content/ContentMain/Settings/helpers"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"

import styles from "./AssignUsersToWarehouse.module.css"

const TABLE_COLUMNS = {
    checkbox: {
        width: 36,
        minWidth: 36,
        classes: { root: styles.cell }
    },
    no: {
        width: 60,
        minWidth: 60,
        classes: { root: styles.cell }
    },
    name: {
        minWidth: 200,
        width: "80%",
        classes: { root: styles.cell }
    },
    users: {
        minWidth: 160,
        width: "10%",
        classes: { root: styles.cell }
    }
}

const AssignUsersToWarehouse = ({ PERMISSIONS, canList, canEdit, checkPermission }) => {
    const [isUserAssignModalOpen, setIsUserAssignModalOpen] = useState(false)

    const { data, fetchStatus } = useWarehouseList()

    const {
        selectedRows: selectedWarehouses,
        isAnyRowSelected,
        areAllRowsOnCurrentPageSelected,
        checkIsRowSelected,
        toggleRowSelection,
        toggleAllCurrentPageRowsSelection
    } = useTableSelectedRows({
        initialSelections: [],
        currentPageRows: data
    })
    if (fetchStatus.isError) {
        return <EmptyInternalList text="Unknown error" Icon={AlertErrorIcon} />
    }

    return (
        <div>
            <ContentHeader
                title={MENU.SETTINGS_USERS_MANAGEMENT}
                tabs={getUserManagementTabs({ PERMISSIONS, canList, canEdit, checkPermission })}
            />

            <div className={styles.tabContent}>
                <div className={styles.header}>
                    <H2 classes={{ h2: styles.h2 }}>Warehouses</H2>
                    <Button
                        Icon={AddIcon}
                        iconPosition="right"
                        color="blue"
                        classes={{ button: styles.addButton }}
                        disabled={!isAnyRowSelected}
                        onClick={() => setIsUserAssignModalOpen(true)}
                    >
                        Assign
                    </Button>
                </div>

                <Table>
                    <TableHead>
                        <TableHeadCell {...TABLE_COLUMNS.checkbox}>
                            <Checkbox
                                checked={areAllRowsOnCurrentPageSelected}
                                partiallyChecked={isAnyRowSelected}
                                onChange={toggleAllCurrentPageRowsSelection}
                                disabled={fetchStatus.isLoading && !fetchStatus.isLoaded}
                            />
                        </TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.no}>NO.</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.name}>NAME</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.users}>USERS</TableHeadCell>
                    </TableHead>
                    <TableBody>
                        {fetchStatus.isLoading &&
                            !fetchStatus.isLoaded &&
                            Array(10)
                                .fill()
                                .map((_, index) => (
                                    <TableRow key={index}>
                                        <TableCell {...TABLE_COLUMNS.checkbox}>
                                            <Checkbox disabled />
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.no}>
                                            <div className={styles.placeholder} style={{ width: 20 }} />
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.name}>
                                            <div className={styles.placeholder} style={{ width: 120 }} />
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.users}>
                                            <div className={styles.placeholder} style={{ width: 20 }} />
                                        </TableCell>
                                    </TableRow>
                                ))}

                        {fetchStatus.isLoaded &&
                            (data.length > 0 ? (
                                data.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell {...TABLE_COLUMNS.checkbox}>
                                            <Checkbox
                                                checked={checkIsRowSelected(row)}
                                                onChange={() => toggleRowSelection(row)}
                                            />
                                        </TableCell>
                                        <TableCell {...TABLE_COLUMNS.no}>{`${index + 1}.`}</TableCell>
                                        <TableCell {...TABLE_COLUMNS.name}>{row.name}</TableCell>
                                        <TableCell {...TABLE_COLUMNS.users}>
                                            <Button
                                                variant="flat"
                                                type="link"
                                                size="small"
                                                LinkComponent={Link}
                                                propsLinkComponent={{ to: `/settings/users/warehouses/${row.id}` }}
                                                classes={{ button: styles.usersLink }}
                                                disabled={row.users_count === 0}
                                            >
                                                {row.users_count}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <EmptyInternalList text="There is no warehouse to assign" Icon={ListIcon} />
                            ))}
                    </TableBody>
                </Table>
            </div>
            {isUserAssignModalOpen && (
                <AssignUserModal
                    selectedWarehouses={selectedWarehouses}
                    onClose={() => setIsUserAssignModalOpen(false)}
                />
            )}
        </div>
    )
}

AssignUsersToWarehouse.propTypes = {
    PERMISSIONS: PropTypes.shape({
        context: PropTypes.objectOf(PropTypes.string).isRequired,
        types: PropTypes.objectOf(PropTypes.string).isRequired
    }),
    canAdd: PropTypes.func.isRequired,
    canEdit: PropTypes.func.isRequired,
    checkPermission: PropTypes.func.isRequired
}

export default withPermissions(AssignUsersToWarehouse)
