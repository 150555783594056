import React, { useRef } from "react"
import withStyles from "HOC/withStyles"
import styles from "./Skeleton.css"
import PropTypes from "prop-types"
import { fetchStatusPropTypes } from "helpers/fetchStatus"

export const SnakeLoader = withStyles(({ type, cx }) => {
    return (
        <div className={cx("spinnerWrapper")}>
            <div className={cx("spinner", type)} />
        </div>
    )
}, styles)

const Skeleton = ({ fetchStatus, render, children, SkeletonComponent, fallback, onlyFirstTime = false, ...props }) => {
    const isAfterFirstRender = useRef(false)

    if (onlyFirstTime && !isAfterFirstRender.current && fetchStatus.isLoaded) {
        isAfterFirstRender.current = true
    }

    if (fetchStatus.isLoaded || (onlyFirstTime && isAfterFirstRender.current)) {
        if (typeof render === "function") {
            return render(props)
        }

        return children
    }

    if (fetchStatus.isLoading) {
        return !!SkeletonComponent ? <SkeletonComponent /> : <SnakeLoader />
    }

    if (fetchStatus.isError && typeof fallback === "function") {
        return fallback(fetchStatus.error || {})
    }

    return null
}

Skeleton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    fetchStatus: fetchStatusPropTypes.isRequired,
    SkeletonComponent: PropTypes.elementType,
    fallback: PropTypes.func,
    render: PropTypes.func,
    onlyFirstTime: PropTypes.bool
}

export default withStyles(Skeleton, styles)
