import React, { useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import PropTypes from "prop-types"
import idx from "idx"

import fetch from "helpers/fetch"
import useSelectCombination from "hooks/useSelectCombination"
import useProduct from "ui/ChooseProduct/hooks/useProduct"

import Combinations from "./Combinations"

const CombinationsContainer = props => {
    const { productId, handleSelectProductCombination, itemsState, handleChangeIsLoading, clearSelectedProduct } = props

    const { product, shouldFetch, fetchProduct, clearProductState } = useProduct()

    const { selectedAttributes, selectAttribute, attributesForComponent } = useSelectCombination({
        combinationPatterns: idx(product, _ => _.data.combination_patterns),
        attributes: idx(product, _ => _.data.attributes),
        enabled: product.fetchStatus.isLoaded
    })
    const [combination, setCombination] = useState(null)
    const [isFetchingCombination, setIsFetchingCombination] = useState(false)
    const [quantity, setQuantity] = useState(1)

    const queryClient = useQueryClient()

    useEffect(() => {
        if (shouldFetch(product)) {
            fetchProduct(productId)
        }
    }, [product])

    useEffect(() => {
        const attributeValueIds = selectedAttributes.map(selectedAttribute => selectedAttribute.value.id)
        const isCombinationSelected = attributeValueIds.length > 0 && attributeValueIds.every(id => id)

        if (isCombinationSelected && product.fetchStatus.isLoaded) {
            setIsFetchingCombination(true)
            const attributeValueIdsParamString = attributeValueIds.join(",")
            queryClient
                .fetchQuery(
                    [
                        "/combinations/find",
                        { productId: product.data.id, attributeValueIds: attributeValueIdsParamString }
                    ],
                    () =>
                        fetch.get(
                            `/combinations/find?productId=${product.data.id}&attributeValueIds=${attributeValueIdsParamString}`
                        )
                )
                .then(({ data: combination }) => {
                    setCombination(combination)
                })
                .catch(() => setCombination(null))
                .finally(() => setIsFetchingCombination(false))
        } else {
            setCombination(null)
        }
    }, [selectedAttributes, product])

    useEffect(() => {
        return () => {
            clearProductState()
        }
    }, [])

    return (
        <Combinations
            product={product}
            productAttributes={attributesForComponent}
            combination={combination}
            quantity={quantity}
            handleChangeAttribute={selectAttribute}
            handleSelectProductCombination={handleSelectProductCombination}
            handleChangeQuantity={setQuantity}
            itemsState={itemsState}
            handleChangeIsLoading={handleChangeIsLoading}
            isFetchingCombination={isFetchingCombination}
            clearSelectedProduct={clearSelectedProduct}
        />
    )
}

CombinationsContainer.propTypes = {
    productId: PropTypes.number.isRequired,
    handleSelectProductCombination: PropTypes.func.isRequired,
    itemsState: PropTypes.objectOf(
        PropTypes.shape({
            isLoading: PropTypes.bool
        }).isRequired
    ),
    handleChangeIsLoading: PropTypes.func.isRequired
}

export default CombinationsContainer
