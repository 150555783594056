import React, { Component } from "react"
import { Input, DatePickerWithInput } from "ui"
import PhotoUploader from "./PhotoUploader"
import env from "env"

const API = env.API

class Personal extends Component {
    render() {
        const { errors, setFieldValue, className, values, selectedDate, handleSelectDate, handleEdit } = this.props
        const { first_name, last_name, job_title, addresses, avatar } = values
        const { addresses: addresses_error } = errors

        const main_address = addresses && addresses[0]
        const main_address_errors = addresses_error && addresses_error[0]

        const setValue = (key, val) => {
            handleEdit(true)
            setFieldValue(key, val)
        }

        return (
            <section className={className}>
                <h2>Name</h2>
                <ul>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={first_name}
                            name="first_name"
                            id="first_name"
                            label="First name"
                            error={errors.first_name}
                            onChange={params => setValue("first_name", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={last_name}
                            name="last_name"
                            id="last_name"
                            label="Last name"
                            error={errors.last_name}
                            onChange={params => setValue("last_name", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={main_address.company}
                            name="company"
                            id="company"
                            label="Company/Affiliate"
                            error={main_address_errors}
                            onChange={params => setValue("addresses.0.company", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={this.props.customInputStyles}
                            type="text"
                            value={job_title}
                            name="job_title"
                            id="job_title"
                            label="Job title"
                            error={errors.job_title}
                            onChange={params => setValue("job_title", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <DatePickerWithInput
                            date={selectedDate}
                            maxDate={new Date()}
                            label="Date of Birth:"
                            handleSelectDate={date => {
                                handleSelectDate(date)
                                setValue("birth_day", date)
                            }}
                        />
                    </li>
                </ul>
                <PhotoUploader
                    avatar={avatar.url}
                    endpoint={`${API.baseUrl}/media`}
                    handleRemovePhoto={() => setValue("avatar", { id: null, url: null })}
                    handleUploadSuccess={state => setValue("avatar", state.addedFile)}
                />
            </section>
        )
    }
}

export default Personal
