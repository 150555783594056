import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import _merge from "lodash/merge"

import { Popup, H2 } from "@butterfly-frontend/ui"

import { COMBINATION_PROP_TYPE } from "propTypes"
import ProductCombinationInfo from "modules/WmsModule/components/ProductCombinationInfo"

import styles from "./ProductDetailsPopup.module.css"

const ProductDetailsPopup = ({
    productName,
    productImageSrc,
    combination,
    description,
    trigger,
    position,
    isOpen,
    onClose,
    classes = {}
}) => {
    const productHasCombinations = combination && combination.attribute_values

    return (
        <Popup
            trigger={trigger}
            isOpen={isOpen}
            onClose={onClose}
            position={position}
            offset={[105, 0]}
            outsideClickClose={false}
            renderModalOnTablet
            classes={_merge(classes, {
                popup: classNames([
                    styles.content,
                    classes.content,
                    { [styles.withDescription]: !productHasCombinations && description }
                ]),
                closeIcon: classNames([styles.closeIcon, classes.closeIcon])
            })}
        >
            <div className={styles.container}>
                <H2 classes={{ h2: styles.header }}>{productName}</H2>
                <div className={styles.body}>
                    {productHasCombinations && (
                        <ProductCombinationInfo
                            productName={productName}
                            productImageSrc={productImageSrc}
                            combination={combination}
                        />
                    )}
                    {!productHasCombinations && description && <p className={styles.description}>{description}</p>}
                </div>
            </div>
        </Popup>
    )
}

ProductDetailsPopup.propTypes = {
    productName: PropTypes.string.isRequired,
    productImageSrc: PropTypes.string,
    combination: COMBINATION_PROP_TYPE,
    description: PropTypes.string,
    trigger: PropTypes.element.isRequired,
    position: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    classes: PropTypes.shape({
        content: PropTypes.string,
        triggerContainer: PropTypes.string,
        closeIcon: PropTypes.string
    })
}

export default ProductDetailsPopup
