import React, { Component } from "react"
import { Redirect, Switch, Route } from "react-router-dom"

import withPermissions from "HOC/withPermissions"
import Brands from "modules/BrandsModule"
import { Settings as CalendarSettings } from "modules/CalendarModule/pages"
import AttributeGroupsModules from "modules/AttributeGroupsModules"
import Locations from "modules/LocationsModule"

import Categories from "./Categories/index"
import Features from "./Features/index"
import FeatureValues from "./FeatureValues/index"
import ClientTypes from "./ClientTypes/index"
import ClientGroups from "./ClientGroups/index"
import Channels from "./Channels/index"
import Companies from "./Companies/index"
import Orders from "./Orders"
import Sources from "./Sources/index"
import Services from "./Services/index"
import ProductConditions from "./ProductConditions/index"
import CombinationTemplates from "./CombinationTemplates"
import EmailSignature from "./EmailSignature"
import UsersCover from "./UsersCover/UsersCover"
import RolesCover from "./RolesCover/RolesCover"
import { AssignUsersToWarehouse, UsersAssignedToWarehouse } from "./Warehouses"

class Settings extends Component {
    componentDidMount() {
        const { history, canList, canEdit, PERMISSIONS } = this.props

        if (
            !canList(PERMISSIONS.context.PRODUCTS) ||
            !canEdit(PERMISSIONS.context.PRODUCTS) ||
            !canList(PERMISSIONS.context.USERS) ||
            !canEdit(PERMISSIONS.context.USERS)
        ) {
            return history.push("/dashboard")
        }
    }

    render() {
        return (
            <Switch>
                <Redirect from="/settings" exact={true} to="/settings/users" />
                <Route path="/settings/users/warehouses/:warehouseId" component={UsersAssignedToWarehouse} />
                <Route path="/settings/users/warehouses" component={AssignUsersToWarehouse} />
                <Route path="/settings/users/signatures" component={EmailSignature} />
                <Route path="/settings/users/roles" component={RolesCover} />
                <Route path="/settings/users" component={UsersCover} />
                <Route path="/settings/orders" component={Orders} />
                <Route path="/settings/system-events" component={CalendarSettings} />
                <Redirect from="/settings/products" to="/settings/categories" />
                <Route path="/settings/attribute-groups" component={AttributeGroupsModules} />
                <Route path="/settings/brands" component={Brands} />
                <Route path="/settings/categories" component={Categories} />
                <Route path="/settings/features/:parentId/values" component={FeatureValues} />
                <Route path="/settings/features" component={Features} />
                <Route path="/settings/combination-template" component={CombinationTemplates} />
                <Redirect from="/settings/other" to="/settings/types" />
                <Route path="/settings/types" component={ClientTypes} />
                <Route path="/settings/interactions" component={ClientGroups} />
                <Route path="/settings/conditions" component={ProductConditions} />
                <Route path="/settings/channels" component={Channels} />
                <Route path="/settings/divisions" component={Companies} />
                <Route path="/settings/sources" component={Sources} />
                <Route path="/settings/services" component={Services} />
                <Route path="/settings/locations" component={Locations} />
            </Switch>
        )
    }
}

export default withPermissions(Settings)
