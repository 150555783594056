import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import List from "../TemplatesList"
import ListingLayout from "../ListingLayout"
import Header from "../TemplatesHeader"
import styles from "./EmailTemplates.module.css"
import { ActionButton, EmptyList } from "ui"
import mailIcon from "assets/ico-mail.svg"
import editIcon from "assets/pencil.svg"
import mailIconDark from "assets/ico-mail_dark.svg"
import EditEmailTemplate from "./components/EditEmailTemplate"

const EmailTemplates = ({ cx, emailTemplates, handleTableChange }) => {
    const [emailTemplateToEdit, setEmailTemplateToEdit] = useState()

    const closeEditModal = () => {
        setEmailTemplateToEdit(undefined)
    }

    const openEditModal = emailTemplateToEdit => {
        setEmailTemplateToEdit(emailTemplateToEdit)
    }

    const renderTableButtonsActions = emailTemplateToEdit => (
        <ActionButton handleClick={() => openEditModal(emailTemplateToEdit)} label="Edit" icon={editIcon} />
    )

    return (
        <Fragment>
            <ListingLayout>
                <section className={cx("root")}>
                    <Header title={"Email templates"} />
                    <List
                        sort
                        renderActionsButtons={renderTableButtonsActions}
                        renderEmptyList={() => <EmptyList message="No email template added." icon={mailIcon} />}
                        handleTableChange={handleTableChange}
                        itemIcon={mailIconDark}
                        tableData={emailTemplates}
                        tableHeader="email type"
                    />
                </section>
            </ListingLayout>
            {emailTemplateToEdit && (
                <EditEmailTemplate emailTemplateToEdit={emailTemplateToEdit} handleClose={closeEditModal} />
            )}
        </Fragment>
    )
}

EmailTemplates.propTypes = {
    emailTemplates: PropTypes.object.isRequired,
    handleTableChange: PropTypes.func.isRequired
}

export default withStyles(EmailTemplates, styles)
