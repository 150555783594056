const TABLE_COLUMNS = {
    NAME: {
        minWidth: 70,
        width: "25%"
    },
    ID: {
        minWidth: 70,
        width: "25%"
    },
    LOCATION: {
        minWidth: 100,
        width: "25%"
    },
    INPUT: {
        minWidth: 100,
        width: "25%"
    }
}

export default TABLE_COLUMNS
