import React, { useState, useMemo, Fragment, useCallback, useContext, useEffect } from "react"
import { useSelector } from "react-redux"
import { useQueryClient } from "react-query"
import PropTypes from "prop-types"

import {
    Table,
    H1,
    Panel,
    EmptyList,
    SnakeLoader,
    TableHead,
    TableHeadCell,
    useTableSort,
    TableSortableHeadCell,
    TableBody,
    TableRowWithExpandedDetails,
    TableCell,
    Status,
    TableDateCell,
    GridIcon,
    AlertErrorIcon,
    TableCellWithExpandButton,
    useTableFilterBarSearch,
    useTableFilterBarMultiselect,
    TableFilterBar,
    TableFilterBarSearch,
    TableFilterBarSelect,
    useTableFilterBarDatepicker,
    TableFilterBarDatepicker,
    useTableFilterBarMoreFilters,
    TableFilterBarMoreFiltersButton,
    TableFilterBarMoreFilters,
    TableFilterBarMoreFiltersGrid,
    TableFilterBarMoreFiltersGridItem
} from "@butterfly-frontend/ui"
import { Dropdown, Pagination } from "ui"
import { SelectUser, SelectWarehouse } from "ui/Filters"
import { formatDate, toApiDate } from "helpers/date"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import { Header, StockTransferDetails } from "modules/WmsModule/components"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import { useShipmentList } from "modules/WmsModule/hooks/api/useShipment"
import shipmentStatuses from "modules/WmsModule/constants/shipmentStatuses"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import uniqueElementsFilter from "modules/WmsModule/helpers/uniqueElementsFilter"
import STATUS_SELECT_OPTIONS from "modules/WmsModule/constants/shipmentStatusSelectOptions"
import ListFiltersContext from "modules/WmsModule/contexts/ListFiltersContext"

import styles from "./StockTransferListPage.module.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.module.css"

const TABLE_COLUMNS = {
    id: { minWidth: 100, width: "35%" },
    warehouseFrom: { minWidth: 100, width: "18%" },
    warehouseTo: { minWidth: 100, width: "15%" },
    status: { minWidth: 110, width: "12%" },
    date: { minWidth: 120, width: "10%" },
    detailsButton: { minWidth: 85, width: 85 }
}

const StockTransferListPage = ({ canAccess }) => {
    const [expandedRowId, setExpandedRowId] = useState(null)
    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)
    const { sortState, onChangeSort } = useTableSort({ initialState: { direction: "desc", by: "created_at" } })
    const queryClient = useQueryClient()
    const {
        set: setFiltersContext,
        values: { stockTransferList: filtersFromContext }
    } = useContext(ListFiltersContext)

    const filterBarSearchProps = useTableFilterBarSearch({ initialValue: filtersFromContext.query })
    const filterBarDatepickerProps = useTableFilterBarDatepicker({ initialValues: filtersFromContext.updatedAt })
    const statusFilterBarMultiselectProps = useTableFilterBarMultiselect({
        initialOptions: STATUS_SELECT_OPTIONS,
        initialSelectedIds: filtersFromContext.statuses.map(({ id }) => id)
    })

    const selectedStatuses = statusFilterBarMultiselectProps.selectedValues
    const querySearch = filterBarSearchProps.debouncedValue
    const updatedAt = filterBarDatepickerProps.values

    const {
        moreFiltersButtonProps,
        moreFiltersComponentProps,
        ...filterBarMoreFiltersProps
    } = useTableFilterBarMoreFilters({
        emptyFilterValues: {
            createdBy: [],
            sourceWarehouses: [],
            destinationWarehouses: []
        },
        initialFilterValues: {
            createdBy: filtersFromContext.createdBy,
            sourceWarehouses: filtersFromContext.sourceWarehouses,
            destinationWarehouses: filtersFromContext.destinationWarehouses
        },
        canClear: true
    })

    useEffect(() => {
        const { sourceWarehouses, destinationWarehouses, createdBy } = filterBarMoreFiltersProps.savedFilterValues

        setFiltersContext(prevState => ({
            ...prevState,
            stockTransferList: {
                query: querySearch,
                statuses: selectedStatuses,
                updatedAt,
                sourceWarehouses,
                destinationWarehouses,
                createdBy
            }
        }))
    }, [querySearch, selectedStatuses, updatedAt, filterBarMoreFiltersProps.savedFilterValues])

    const filters = useMemo(() => {
        const { sourceWarehouses, destinationWarehouses, createdBy } = filterBarMoreFiltersProps.savedFilterValues
        const mapToCommaSeparatedIdsString = array => array.map(({ id }) => id).join(",")

        return {
            query: querySearch,
            statuses: mapToCommaSeparatedIdsString(selectedStatuses),
            source_warehouses: mapToCommaSeparatedIdsString(sourceWarehouses),
            destination_warehouses: mapToCommaSeparatedIdsString(destinationWarehouses),
            creators: mapToCommaSeparatedIdsString(createdBy),
            type: "transfer",
            ...(updatedAt
                ? {
                      created_from: updatedAt ? toApiDate(updatedAt.start) : "",
                      created_to: updatedAt ? toApiDate(updatedAt.end) : ""
                  }
                : {})
        }
    }, [querySearch, selectedStatuses, updatedAt, filterBarMoreFiltersProps.savedFilterValues])

    const { data, fetchStatus, fetchFlags, pagination, query } = useTableLogic({
        sort: sortState,
        useQueryList: useShipmentList,
        filters,
        reactQueryProps: {
            onSuccess: () => setExpandedRowId(null),
            enabled: canAccess()
        }
    })

    const changeShipmentStatusCacheOnList = useCallback(
        (stockTransferId, updatedShipment) =>
            queryClient.setQueryData(query.queryKey, prevState => ({
                ...prevState,
                data: prevState.data.map(item =>
                    item.id === stockTransferId ? { ...item, status: updatedShipment.status } : item
                )
            })),
        [query.queryKey, queryClient]
    )

    return (
        <Fragment>
            <Header>
                <H1 withLeftBorder>Stock transfers</H1>
                <Dropdown label="Export all" className={styles.exportAllDropdown} options={[]} />
            </Header>
            <Panel>
                <TableFilterBar
                    moreFiltersComponent={
                        <TableFilterBarMoreFilters {...moreFiltersComponentProps}>
                            <TableFilterBarMoreFiltersGrid>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectWarehouse
                                        setValue={value =>
                                            filterBarMoreFiltersProps.onChange("sourceWarehouses", value)
                                        }
                                        value={filterBarMoreFiltersProps.selectedFilterValues.sourceWarehouses}
                                        label="From"
                                        placeholder="All"
                                        warehouseType="active"
                                        multiselect
                                        customStyles={selectWithSearchStyles}
                                        warehouseIds={assignedWarehouseIds}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectWarehouse
                                        setValue={value =>
                                            filterBarMoreFiltersProps.onChange("destinationWarehouses", value)
                                        }
                                        value={filterBarMoreFiltersProps.selectedFilterValues.destinationWarehouses}
                                        label="To"
                                        placeholder="All"
                                        warehouseType="active"
                                        multiselect
                                        customStyles={selectWithSearchStyles}
                                        warehouseIds={assignedWarehouseIds}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectUser
                                        handleSelect={({ target: { value } }) =>
                                            filterBarMoreFiltersProps.onChange("createdBy", value)
                                        }
                                        label="By"
                                        placeholder="Search user"
                                        name="created_by"
                                        value={filterBarMoreFiltersProps.selectedFilterValues.createdBy}
                                        customStyles={selectWithSearchStyles}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                            </TableFilterBarMoreFiltersGrid>
                        </TableFilterBarMoreFilters>
                    }
                >
                    <TableFilterBarSearch placeholder="Search transfer..." {...filterBarSearchProps} />
                    <TableFilterBarSelect
                        labelSelectAll="All statuses"
                        placeholder="Status"
                        {...statusFilterBarMultiselectProps.componentProps}
                    />
                    <TableFilterBarDatepicker
                        labelSelectAll="All time"
                        placeholder="Date"
                        {...filterBarDatepickerProps}
                    />
                    <TableFilterBarMoreFiltersButton {...moreFiltersButtonProps} />
                </TableFilterBar>
                {fetchStatus.isLoading && <SnakeLoader classes={{ root: styles.loader }} />}
                {!canAccess() && <EmptyList Icon={GridIcon} label="You don't have an access to this resource." />}
                {fetchStatus.isLoaded && (
                    <Table>
                        <div className={styles.table}>
                            <TableHead>
                                <TableHeadCell {...TABLE_COLUMNS.id}>ID</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.warehouseFrom}>FROM</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.warehouseTo}>TO</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.status}>STATUS</TableHeadCell>
                                <TableSortableHeadCell
                                    {...TABLE_COLUMNS.date}
                                    sortState={sortState}
                                    columnKey="created_at"
                                    onClick={onChangeSort}
                                >
                                    DATE
                                </TableSortableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.detailsButton} />
                            </TableHead>
                            <TableBody>
                                {fetchFlags.isEmptyList && (
                                    <EmptyList Icon={GridIcon} label="Stock transfers list is empty." />
                                )}
                                {fetchFlags.isEmptyListWithFilter && (
                                    <EmptyList Icon={GridIcon} label="No stock transfers found for selected filters." />
                                )}
                                {data.map(transferItem => {
                                    const isStatusCancelled = transferItem.status === SHIPMENT_STATUS_KEYS.CANCELLED
                                    const rowClasses = { root: { [styles.inactiveRow]: isStatusCancelled } }
                                    const cellClasses = { root: { [styles.inactiveCell]: isStatusCancelled } }
                                    const idCellClasses = {
                                        root: [styles.idCell, { [styles.alertCell]: isStatusCancelled }]
                                    }
                                    const sourceWarehousesNames = transferItem.source_warehouses
                                        .map(warehouse => warehouse.name)
                                        .filter(uniqueElementsFilter)
                                        .join(", ")
                                    const status = shipmentStatuses[transferItem.status.status]

                                    return (
                                        <TableRowWithExpandedDetails
                                            key={transferItem.id}
                                            classNames={rowClasses}
                                            isExpanded={expandedRowId === transferItem.id}
                                            detailsComponent={
                                                <StockTransferDetails
                                                    stockTransferId={transferItem.id}
                                                    onStatusChanged={changeShipmentStatusCacheOnList}
                                                />
                                            }
                                        >
                                            <TableCell {...TABLE_COLUMNS.id} classes={idCellClasses}>
                                                {isStatusCancelled && <AlertErrorIcon />}
                                                {transferItem.uuid}
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.warehouseFrom} classes={cellClasses}>
                                                <div className={styles.warehouseFrom} title={sourceWarehousesNames}>
                                                    {sourceWarehousesNames}
                                                </div>
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.warehouseTo} classes={cellClasses}>
                                                {transferItem.destination_warehouse.name}
                                            </TableCell>
                                            <TableCell {...TABLE_COLUMNS.status}>
                                                <Status theme={status.theme} color={status.color}>
                                                    {status.label}
                                                </Status>
                                            </TableCell>
                                            <TableDateCell {...TABLE_COLUMNS.date} classes={cellClasses}>
                                                {formatDate(transferItem.status.updated_at)}
                                            </TableDateCell>
                                            <TableCellWithExpandButton
                                                isExpanded={expandedRowId === transferItem.id}
                                                onClick={() =>
                                                    setExpandedRowId(prevState =>
                                                        prevState !== transferItem.id ? transferItem.id : null
                                                    )
                                                }
                                            />
                                        </TableRowWithExpandedDetails>
                                    )
                                })}
                            </TableBody>
                            {pagination.canDisplay && (
                                <div className={styles.paginationWrapper}>
                                    <Pagination {...pagination} />
                                </div>
                            )}
                        </div>
                    </Table>
                )}
            </Panel>
        </Fragment>
    )
}

StockTransferListPage.propTypes = {
    canAccess: PropTypes.func.isRequired
}

export default withPermissions(StockTransferListPage, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
