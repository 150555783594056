import * as constants from "constants/index"

function verification(state = "", action) {
    const value = action.payload && action.payload.value

    switch (action.type) {
        case constants.CLEAR_VERIFICATION:
            return ""
        case constants.CHANGE_VERIFICATION:
            return value
        default:
            return state
    }
}

export default verification
