import React, { useRef, useState } from "react"
import ReactSVG from "react-svg"
import PropTypes from "prop-types"

import getCxFromStyles from "helpers/getCxFromStyles"
import { useOutsideClick } from "hooks"

import styles from "./styles.css"
import arrowIcon from "assets/arrow_down_blue.svg"

const VALUES = ["3-9", "9-3", "3-3-6", "3-6-3", "6-3-3", "4-8", "8-4", "6-6", "12", "3-3-3-3", "4-4-4"]

const createFraction = (numerator, denominator) => {
    const calculateGcd = (a, b) => (b ? calculateGcd(b, a % b) : a)
    const foundGcd = calculateGcd(numerator, denominator)

    return `${numerator / foundGcd}/${denominator / foundGcd}`
}

const LayoutSelect = props => {
    const { value: selected, onChange } = props
    const cx = getCxFromStyles(styles)
    const rootRef = useRef()
    const [isExpand, setIsExpand] = useState(false)

    useOutsideClick(rootRef, () => setIsExpand(false))

    const handleSelect = item => {
        onChange(item)
        setIsExpand(false)
    }

    const renderValue = value => (
        <div className={cx("value")}>
            <div className={cx("visual")}>
                {value.split("-").map((part, index, array) => (
                    <div
                        key={index}
                        className={cx("part")}
                        style={{ width: ((66 - 6 * (array.length - 1)) / 12) * parseInt(part, 10) }}
                    />
                ))}
            </div>
            {value
                .split("-")
                .map(numerator => createFraction(numerator, 12))
                .join(" + ")}
        </div>
    )

    return (
        <div className={cx("root", { isExpand })} ref={rootRef}>
            <div className={cx("selected", { placeholder: !selected })} onClick={() => setIsExpand(!isExpand)}>
                {selected ? renderValue(selected) : "- Please select -"}
                <ReactSVG src={arrowIcon} className={cx("arrowIcon")} />
            </div>
            <ul className={cx("list")}>
                {VALUES.map(value => (
                    <li className={cx("item")} onClick={() => handleSelect(value)} key={value}>
                        {renderValue(value)}
                    </li>
                ))}
            </ul>
        </div>
    )
}

LayoutSelect.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
}

export default LayoutSelect
