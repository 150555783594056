import { useEffect, useRef } from "react"
import { createPortal } from "react-dom"

const modalRoot = document.getElementById("modal-root")

const Portal = ({ children }) => {
    const elRef = useRef(null)

    if (!elRef.current) {
        elRef.current = document.createElement("div")
    }

    useEffect(() => {
        modalRoot && modalRoot.appendChild(elRef.current)

        if (!modalRoot && process.env.NODE_ENV === "development") {
            console.error('element of id "modal-root" not found in Portal component')
        }

        return () => modalRoot.removeChild(elRef.current)
    }, [elRef])

    return createPortal(children, elRef.current)
}

export default Portal
