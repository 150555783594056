import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { objToQueryString } from "../helpers/urls"

const getOrdersStart = () => ({ type: constants.GET_ORDERS_START })
const getOrdersSuccess = data => ({ type: constants.GET_ORDERS_SUCCESS, payload: data })
const getOrdersError = error => ({ type: constants.GET_ORDERS_ERROR, payload: error })

export const getOrders = (params = {}) => dispatch => {
    dispatch(getOrdersStart())

    return fetch
        .get(`/orders?${objToQueryString(params, true)}`)
        .then(data => {
            dispatch(getOrdersSuccess(data))
        })
        .catch(error => {
            dispatch(getOrdersError(error))
        })
}

const createOrderStart = () => ({ type: constants.CREATE_ORDER_START })
const createOrderSuccess = data => ({ type: constants.CREATE_ORDER_SUCCESS, payload: data })
const createOrderError = error => ({ type: constants.CREATE_ORDER_ERROR, payload: error })

export const createOrder = body => dispatch => {
    dispatch(createOrderStart())

    return new Promise((resolve, reject) => {
        fetch
            .post("/orders", body)
            .then(data => {
                dispatch(createOrderSuccess(data))
                resolve(data)
            })
            .catch(error => {
                dispatch(createOrderError(error))
                reject(error)
            })
    })
}

export const setLastFetchedOrder = data => ({ type: constants.SET_LAST_FETCHED_ORDER, payload: data })

export const getOrderStart = () => ({ type: constants.GET_ORDER_START })
export const getOrderSuccess = data => ({ type: constants.GET_ORDER_SUCCESS, payload: data })
export const getOrderError = error => ({ type: constants.GET_ORDER_ERROR, payload: error })

export const getOrder = (id, forceFetch = false) => {
    return (dispatch, getState) => {
        const currentOrder = getState().db.orders.current
        const lastFetchedOrder = getState().db.orders.lastFetched.data

        if (!!lastFetchedOrder) {
            dispatch(setLastFetchedOrder(null))

            if (lastFetchedOrder.id === Number(id)) {
                dispatch(
                    getOrderSuccess({
                        data: lastFetchedOrder
                    })
                )
                return Promise.resolve(lastFetchedOrder)
            }
        }

        if (!forceFetch && currentOrder.data.id === Number(id)) {
            return Promise.resolve(currentOrder)
        }

        dispatch(getOrderStart())

        return new Promise((resolve, reject) => {
            fetch
                .get(`/orders/${id}`)
                .then(data => {
                    dispatch(getOrderSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(getOrderError(error))
                    reject(error)
                })
        })
    }
}

const updateOrderStart = () => ({ type: constants.UPDATE_ORDER_START })
const updateOrderSuccess = data => ({ type: constants.UPDATE_ORDER_SUCCESS, payload: data })
const updateOrderError = error => ({ type: constants.UPDATE_ORDER_ERROR, payload: error })

export const updateOrder = (id, body) => {
    return dispatch => {
        dispatch(updateOrderStart())

        return new Promise((resolve, reject) => {
            fetch
                .patch("/orders/" + id, body)
                .then(data => {
                    dispatch(updateOrderSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(updateOrderError(error))

                    reject(error)
                })
        })
    }
}

const deleteOrderStart = () => ({ type: constants.DELETE_ORDER_START })
const deleteOrderSuccess = () => ({ type: constants.DELETE_ORDER_SUCCESS })
const deleteOrderError = error => ({ type: constants.DELETE_ORDER_ERROR, payload: error })

export const deleteOrder = id => {
    return dispatch => {
        dispatch(deleteOrderStart())

        return new Promise((resolve, reject) => {
            fetch
                .deleteRAW("/orders/" + id)
                .then(() => {
                    dispatch(deleteOrderSuccess())
                    resolve()
                })
                .catch(error => {
                    dispatch(deleteOrderError(error))
                    reject(error)
                })
        })
    }
}

const getOrderItemsStart = () => ({ type: constants.GET_ORDER_ITEMS_START })
const getOrderItemsSuccess = data => ({ type: constants.GET_ORDER_ITEMS_SUCCESS, payload: data })
const getOrderItemsError = error => ({ type: constants.GET_ORDER_ITEMS_ERROR, payload: error })

export const getOrderItems = orderId => dispatch => {
    dispatch(getOrderItemsStart())

    return new Promise((resolve, reject) => {
        fetch
            .get(`/order-items?order_id=${orderId}`)
            .then(data => {
                dispatch(getOrderItemsSuccess(data))
                resolve(data)
            })
            .catch(error => {
                dispatch(getOrderItemsError(error))
                reject(error)
            })
    })
}

const createOrderItemsStart = () => ({ type: constants.GET_ORDER_ITEMS_START })
const createOrderItemsSuccess = data => ({ type: constants.CREATE_ORDER_ITEMS_SUCCESS, payload: data })
const createOrderItemsError = error => ({ type: constants.GET_ORDER_ITEMS_ERROR, payload: error })

export const createOrderItems = body => dispatch => {
    dispatch(createOrderItemsStart())

    return new Promise((resolve, reject) =>
        fetch
            .post("/order-items", body)
            .then(data => {
                dispatch(createOrderItemsSuccess(data))
                resolve(data)
            })
            .catch(error => {
                dispatch(createOrderItemsError(error))
                reject(error)
            })
    )
}

const updateOrderItemStart = () => ({ type: constants.UPDATE_ORDER_ITEM_START })
const updateOrderItemSuccess = data => ({ type: constants.UPDATE_ORDER_ITEM_SUCCESS, payload: data })
const updateOrderItemError = error => ({ type: constants.UPDATE_ORDER_ITEM_ERROR, payload: error })

export const updateOrderItem = (id, body) => dispatch => {
    dispatch(updateOrderItemStart())

    return new Promise((resolve, reject) =>
        fetch
            .patch(`/order-items/${id}`, body)
            .then(data => {
                dispatch(updateOrderItemSuccess(data))
                resolve(data)
            })
            .catch(error => {
                dispatch(updateOrderItemError(error))
                reject(error)
            })
    )
}

const deleteOrderItemStart = () => ({ type: constants.DELETE_ORDER_ITEM_START })
const deleteOrderItemSuccess = id => ({ type: constants.DELETE_ORDER_ITEM_SUCCESS, payload: id })
const deleteOrderItemError = error => ({ type: constants.DELETE_ORDER_ITEM_ERROR, payload: error })

export const deleteOrderItem = id => dispatch => {
    if (typeof id !== "number") {
        return
    }

    dispatch(deleteOrderItemStart())

    return new Promise((resolve, reject) => {
        fetch
            .deleteRAW(`/order-items/${id}`)
            .then(() => {
                dispatch(deleteOrderItemSuccess(id))
                resolve()
            })
            .catch(error => {
                dispatch(deleteOrderItemError(error))
                reject(error)
            })
    })
}
