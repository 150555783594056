import React, { useEffect, useState, Fragment } from "react"
import { useFetch, useActions } from "hooks"
import fetch from "helpers/fetch"
import withStyles from "HOC/withStyles"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import Header from "../TemplatesHeader"
import EditCardChargeFeeModal from "./EditCardChargeFee/EditCardChargeFeeModal"
import { ActionButton } from "ui"
import styles from "./CardChargeFee.module.css"
import editIcon from "assets/pencil.svg"

const CardChargeFeeContainer = ({ cx }) => {
    const [cardChargeFee, setCardChargeFee] = useState()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const actions = useActions({ showErrorNotification, showSuccessNotification })

    useEffect(() => {
        fetchSettings()
    }, [])

    const [fetchSettings, fetchStatus] = useFetch({
        action: () => fetch.get("/settings"),
        onSuccess: ({ data }) => {
            const cardChargeFee = data.find(({ key }) => key === "default_card_charge_fee")
            const fee = {
                id: cardChargeFee.id,
                key: cardChargeFee.key,
                percentValue: cardChargeFee.value
            }
            setCardChargeFee(fee)
        },
        onError: () => actions.showErrorNotification()
    })

    return (
        <Fragment>
            <section className={cx("root")}>
                <Header title="Card charge fee" />
                <div className={cx("row")}>
                    <h2>Card charge fee</h2>
                    <div className={cx("cardChargeFeeValue")}>{cardChargeFee ? cardChargeFee.percentValue : "0"} %</div>
                    <ActionButton handleClick={() => setIsModalOpen(true)} label="Edit" icon={editIcon} />
                </div>
            </section>
            {fetchStatus.isLoaded && isModalOpen && (
                <EditCardChargeFeeModal
                    feeToEdit={cardChargeFee}
                    handleClose={() => setIsModalOpen(false)}
                    icon={editIcon}
                    handleSubmitSuccess={setCardChargeFee}
                />
            )}
        </Fragment>
    )
}

export default withStyles(CardChargeFeeContainer, styles)
