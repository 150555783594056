import React from "react"
import { useFormik } from "formik"
import _get from "lodash/get"
import PropTypes from "prop-types"

import { Modal, DetailsCard, Status } from "@butterfly-frontend/ui"
import { Textarea } from "ui"
import { SelectChangeReason } from "ui/Filters"
import SelectProductConditions from "ui/Filters/SelectProductConditions"

import { StockItemStatus, ProductHeader } from "modules/WmsModule/components"
import { FormRow, FormColumn, StockItemModalButtonBar } from "modules/WmsModule/modals/components"
import { useStockItemDelete } from "modules/WmsModule/hooks/api/useStockItem"
import getSelectPropsFactory from "modules/WmsModule/helpers/getSelectPropsFactory"
import REASON_TYPES from "modules/WmsModule/constants/reasonTypes"
import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"

import validationSchema from "./validationSchema"

import styles from "./DeleteStockItem.module.css"

const formValuesToApi = ({ reason, description }) => ({
    reason_id: reason.value,
    description
})

const DeleteStockItem = ({ stockItem, onClose, onSuccess }) => {
    const { id, status, condition, warehouse, brand, product } = stockItem

    const stockItemDelete = useStockItemDelete()

    const formik = useFormik({
        initialValues: {
            condition: null,
            reason: null,
            description: ""
        },
        validationSchema,
        onSubmit: data => stockItemDelete.mutate({ id, data: formValuesToApi(data) }).then(onSuccess)
    })

    const getSelectProps = getSelectPropsFactory(formik)

    return (
        <Modal onClose={onClose} title="Remove item">
            <div className={styles.removeItemContainer}>
                <div className={styles.header}>
                    <ProductHeader product={{ ...product, brand: brand }} />
                </div>

                <div className={styles.details}>
                    <DetailsCard header="ID">{id}</DetailsCard>
                    <DetailsCard header="WAREHOUSE">{warehouse.name}</DetailsCard>
                    <DetailsCard header="CONDITION">
                        <Status color={condition.color}>{condition.name}</Status>
                    </DetailsCard>
                    <DetailsCard header="STATUS">
                        <StockItemStatus stockItemStatus={status} />
                    </DetailsCard>
                </div>

                <form onSubmit={formik.handleSubmit}>
                    <FormRow>
                        <FormColumn>
                            <div className={styles.selectWrapper}>
                                <SelectProductConditions
                                    selectWithSearchProps={getSelectProps("condition", {
                                        isClearBlocked: true,
                                        isRequired: true
                                    })}
                                    label="Condition"
                                    multiselect={false}
                                    value={_get(formik.values, "condition.value.name", "")}
                                    handleSelect={({ target: { value } }) => formik.setFieldValue("condition", value)}
                                    conditionType={CONDITION_TYPES.REMOVED}
                                />
                            </div>
                        </FormColumn>
                        <FormColumn>
                            <div className={styles.selectWrapper}>
                                <SelectChangeReason
                                    {...getSelectProps("reason", { isRequired: true })}
                                    label="Reason"
                                    reasonType={REASON_TYPES.CONDITION_REMOVED}
                                />
                            </div>
                        </FormColumn>
                    </FormRow>

                    <div className={styles.description}>
                        <Textarea
                            label="Description"
                            className={styles.textarea}
                            name="description"
                            placeholder="Click here to add a description..."
                            value={formik.values.description}
                            error={formik.errors.description}
                            onChange={({ event }) => formik.handleChange(event)}
                        />
                    </div>

                    <StockItemModalButtonBar isSubmitting={formik.isSubmitting} onClose={onClose} withBackButton />
                </form>
            </div>
        </Modal>
    )
}

DeleteStockItem.propTypes = {
    stockItem: PropTypes.shape({
        id: PropTypes.number.isRequired,
        product: PropTypes.shape({
            name: PropTypes.string.isRequired
        }),
        brand: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string
        }),
        condition: PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired
        }),
        warehouse: PropTypes.shape({
            name: PropTypes.string.isRequired
        }),
        status: PropTypes.string.isRequired
    }).isRequired,
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired
}

export default DeleteStockItem
