import React from "react"

import OrderTab from "../components/OrderTab"
import { withMultiselectConsumer } from "HOC/withMultiselect"

const SaleOrders = props => {
    const { projectId } = props
    return <OrderTab type={"sales"} projectId={projectId} {...props} />
}

export default withMultiselectConsumer("saleOrders")(SaleOrders)
