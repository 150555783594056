import * as constants from "constants/index"
import fetch from "../helpers/fetch"

function getBrandsStart() {
    return {
        type: constants.GET_BRANDS_START
    }
}
function getBrandsSuccess(data) {
    return {
        type: constants.GET_BRANDS_SUCCESS,
        payload: data
    }
}
function getBrandsError(error) {
    return {
        type: constants.GET_BRANDS_ERROR,
        payload: {
            error
        }
    }
}
export function getBrands() {
    return dispatch => {
        dispatch(getBrandsStart())
        fetch
            .get(`/brands?custom_vendor=false&length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => {
                dispatch(getBrandsSuccess(data))
            })
            .catch(error => {
                dispatch(getBrandsError(error))
            })
    }
}

function getBrandByIdStart() {
    return {
        type: constants.GET_BRAND_BY_ID_START
    }
}
function getBrandByIdSuccess(data) {
    return {
        type: constants.GET_BRAND_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}
function getBrandByIdError(error) {
    return {
        type: constants.GET_BRAND_BY_ID_ERROR,
        payload: {
            error
        }
    }
}
export function getBrandById(id) {
    return dispatch => {
        dispatch(getBrandByIdStart())
        fetch
            .get(`/brands/${id}`)
            .then(data =>
                fetch.get(`/notes?object_type=brand&object_id=${id}&with_attachments=false`).then(notes => {
                    dispatch(
                        getBrandByIdSuccess({
                            ...data,
                            data: {
                                ...data.data,
                                notes: notes.data || []
                            }
                        })
                    )
                })
            )
            .catch(error => {
                dispatch(getBrandByIdError(error))
            })
    }
}

export function resetCurrentBrand() {
    return {
        type: constants.RESET_CURRENT_BRAND
    }
}

function getPriceStructureStart() {
    return {
        type: constants.GET_PRICE_STRUCTURES_START
    }
}
function getPriceStructureSuccess(data) {
    return {
        type: constants.GET_PRICE_STRUCTURES_SUCCESS,
        payload: {
            data
        }
    }
}
function getPriceStructureError(error) {
    return {
        type: constants.GET_PRICE_STRUCTURES_ERROR,
        payload: {
            error
        }
    }
}
export function getPriceStructure() {
    return dispatch => {
        dispatch(getPriceStructureStart())
        fetch
            .get(`/brand-price-structures?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => {
                dispatch(getPriceStructureSuccess(data))
            })
            .catch(error => {
                dispatch(getPriceStructureError(error))
            })
    }
}
