import React from "react"
import { Field, FieldArray } from "formik"
import { compose } from "redux"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import { Input, SelectWithSearch } from "ui"

import styles from "./styles.css"
import inputStyles from "../../overrides/input.css"
import addIcon from "assets/add.svg"
import deleteIcon from "assets/delete.svg"

const GeneralInfoSection = ({ cx, form, states, countries }) => {
    const addAccountingEmailDisabled = form.values.accounting_email.filter(item => !item).length > 0
    const addPoEmailsDisabled = form.values.po_email.filter(item => !item).length > 0

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <ul className={cx("brand")}>
                    <li className={cx("brandName")}>
                        <Field name="name">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Brand name *"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.touched[field.name] && form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                </ul>
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>Supplier Address</h2>
                <ul className={cx("vendorAddress")}>
                    <li className={cx("companyName")}>
                        <Field name="company_name">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Company name"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("country")}>
                        <Field name="country">
                            {({ field }) => (
                                <SelectWithSearch
                                    {...field}
                                    label="Country"
                                    values={countries.map(country => ({ label: country, value: country }))}
                                    setValue={({ value }) => {
                                        if (value !== field.value) {
                                            form.setFieldValue("state", "")
                                            field.onChange({ target: { name: field.name, value } })
                                        }
                                    }}
                                    withoutFetch
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("address")}>
                        <Field name="address">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Address (Street and No.)"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("city")}>
                        <Field name="city">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="City"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("state")}>
                        <Field name="state">
                            {({ field }) =>
                                form.values.country === "United States of America" ? (
                                    <SelectWithSearch
                                        {...field}
                                        label="State"
                                        values={states.map(states => ({ label: states, value: states }))}
                                        setValue={({ value }) =>
                                            field.onChange({ target: { name: field.name, value } })
                                        }
                                        placeholder="- Please select -"
                                        withoutFetch
                                        isListOnTop
                                    />
                                ) : (
                                    <Input
                                        {...field}
                                        label="State"
                                        onChange={({ event }) => field.onChange(event)}
                                        error={form.errors[field.name]}
                                        customStyles={inputStyles}
                                    />
                                )
                            }
                        </Field>
                    </li>
                    <li className={cx("zipCode")}>
                        <Field name="post_code">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="ZIP Code"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                </ul>
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>Contact Details</h2>
                <ul className={cx("contactDetails")}>
                    <li className={cx("person")}>
                        <Field name="contact_person">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Contact person"
                                    onChange={({ event }) => field.onChange(event)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("phone")}>
                        <Field name="phone">
                            {({ field }) => (
                                <Input
                                    {...field}
                                    label="Phone number"
                                    onChange={({ event }) => form.setFieldValue(field.name, event.target.value)}
                                    error={form.errors[field.name]}
                                    customStyles={inputStyles}
                                    isPhoneInput={true}
                                />
                            )}
                        </Field>
                    </li>
                    <li className={cx("emailForPO")}>
                        <FieldArray
                            name="po_email"
                            render={arrayHelpers => (
                                <div>
                                    {form.values.po_email.map((item, index) => (
                                        <div key={index} className={cx("removableInput")}>
                                            <Field name={`po_email[${index}]`}>
                                                {({ field }) => (
                                                    <Input
                                                        {...field}
                                                        label={index ? "" : "Email address for PO"}
                                                        onChange={({ event }) => field.onChange(event)}
                                                        error={form.errors[field.name]}
                                                        customStyles={inputStyles}
                                                    />
                                                )}
                                            </Field>

                                            <img
                                                src={deleteIcon}
                                                alt="deleteImage"
                                                className={cx("deleteIcon")}
                                                onClick={() => arrayHelpers.remove(index)}
                                            />
                                        </div>
                                    ))}
                                    <div
                                        className={cx("add-more", addPoEmailsDisabled ? "add-more-disabled" : "")}
                                        onClick={() => (addPoEmailsDisabled ? null : arrayHelpers.push(""))}
                                    >
                                        <span>
                                            <img src={addIcon} alt="addImage" />
                                            <span>Add more</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        />
                    </li>
                    <li className={cx("accountingEmail")}>
                        <FieldArray
                            name="accounting_email"
                            render={arrayHelpers => (
                                <div>
                                    {form.values.accounting_email.map((item, index) => (
                                        <div key={index} className={cx("removableInput")}>
                                            <Field name={`accounting_email[${index}]`}>
                                                {({ field }) => (
                                                    <Input
                                                        {...field}
                                                        label={index ? "" : "Accounting email"}
                                                        onChange={({ event }) => field.onChange(event)}
                                                        error={form.errors[field.name]}
                                                        customStyles={inputStyles}
                                                    />
                                                )}
                                            </Field>

                                            <img
                                                src={deleteIcon}
                                                alt="deleteImage"
                                                className={cx("deleteIcon")}
                                                onClick={() => arrayHelpers.remove(index)}
                                            />
                                        </div>
                                    ))}
                                    <div
                                        className={cx(
                                            "add-more",
                                            addAccountingEmailDisabled ? "add-more-disabled" : ""
                                        )}
                                        onClick={() => (addAccountingEmailDisabled ? null : arrayHelpers.push(""))}
                                    >
                                        <span>
                                            <img src={addIcon} alt="addImage" />
                                            <span>Add more</span>
                                        </span>
                                    </div>
                                </div>
                            )}
                        />
                    </li>
                </ul>
            </div>

            <div className={cx("section")}>
                <h2 className={cx("header")}>Manufacturing & Delivery time</h2>
                <ul className={cx("times")}>
                    <li className={cx("manufacturingTimes")}>
                        <div>
                            <span className={cx("label")}>Manufacturing time:</span>
                        </div>
                        <div className={cx("timeInputs")}>
                            <div className={cx("timeInput")}>
                                <Field name="manufacturing_min">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            customPlaceholder="min"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </div>
                            <span className={cx("timeSeparator")}>-</span>
                            <div className={cx("timeInput")}>
                                <Field name="manufacturing_max">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            customPlaceholder="max"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </div>
                            <span className={cx("timePostfix")}>days</span>
                        </div>
                    </li>

                    <li className={cx("deliveryTimes")}>
                        <div>
                            <span className={cx("label")}>Delivery time:</span>
                        </div>
                        <div className={cx("timeInputs")}>
                            <div className={cx("timeInput")}>
                                <Field name="delivery_min">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            customPlaceholder="min"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </div>
                            <span className={cx("timeSeparator")}>-</span>
                            <div className={cx("timeInput")}>
                                <Field name="delivery_max">
                                    {({ field }) => (
                                        <Input
                                            {...field}
                                            customPlaceholder="max"
                                            onChange={({ event }) => field.onChange(event)}
                                            error={form.errors[field.name]}
                                            customStyles={inputStyles}
                                        />
                                    )}
                                </Field>
                            </div>
                            <span className={cx("timePostfix")}>days</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

GeneralInfoSection.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    states: PropTypes.array.isRequired,
    countries: PropTypes.array.isRequired
}

const mapStateToProps = state => {
    return {
        states: state.clientStore.statesList,
        countries: state.clientStore.countriesList
    }
}

export default compose(
    connect(
        mapStateToProps,
        null
    )
)(withStyles(GeneralInfoSection, styles))
