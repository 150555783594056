import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import fetch from "helpers/fetch"
import * as categoryActions from "actions/category"
import * as productActions from "actions/product"
import * as notificationActions from "actions/notification"

import { secondLink } from "./configCategories"

import ListingCategories from "./ListingCategories"
import ListingProducts from "./ListingProducts"

class ListingContainer extends Component {
    componentDidMount() {
        const { getCategories } = this.props

        getCategories()
    }

    handleDeleteCategory = id => {
        const { getCategories, showSuccessNotification, showErrorNotification } = this.props
        fetch
            .deleteRAW(`/categories/${id}`)
            .then(() => {
                getCategories()
                showSuccessNotification()
            })
            .catch(() => showErrorNotification())
    }

    render() {
        const { location } = this.props
        return (
            <Fragment>
                {!location.pathname.includes(secondLink) ? (
                    <ListingCategories {...this.props} handleDeleteCategory={this.handleDeleteCategory} />
                ) : (
                    <ListingProducts {...this.props} />
                )}
            </Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const categoryId = ownProps.match.params.categoryId || null
    const categories = state.db.categories.list.data

    return {
        categoryId: categoryId,
        categories: categories,
        products: state.db.products,
        filteredCategories: categories.filter(item =>
            categoryId ? item.parent_category === Number(categoryId) : !item.parent_category
        ),
        currentCategory: categories.filter(item => (categoryId && item.id === Number(categoryId) ? item : null)),
        isLoading: state.db.categories.list.isLoading
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getCategories: () => dispatch(categoryActions.getCategories()),
        getCategoryById: id => dispatch(categoryActions.getCategoryById(id)),
        getProducts: params => dispatch(productActions.getProducts(params)),
        resetProducts: () => dispatch(productActions.resetProducts()),
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingContainer)
