import React, { useEffect, useState, useMemo } from "react"

import SaveBar from "ui/SaveBar"
import ListingLayout from "../MainPageListingLayout"
import Listing from "../../components/MainPageListings"
import { Header, SectionModalImageWithPins } from "modules/ContentModule/components"
import Modal from "components/Modal/Modal"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"

import listingStyles from "./overrides/MainPageListings.css"
import addIcon from "assets/add-no-padding.svg"

const IMAGE_DIMENSIONS = {
    desktop: {
        width: 2500
    },
    tablet: {
        width: 1024
    },
    mobile: {
        width: 420
    }
}

const MainPageImageWithPins = props => {
    const {
        mainPages: { data, isLoading },
        modifiedData,
        isModified,
        handleUpdate,
        handleSubmit,
        handleClearChanges,
        isSaving,
        handleHomepageHeaderCreate,
        handleHomepageHeaderUpdate,
        handleHomepageHeaderDelete,
        handleDeleteAllFromList,
        getHeader,
        header
    } = props

    const [imageWithPinsModalData, setImageWithPinsModalData] = useState(null)
    const [isDeleteHeaderOpen, setIsDeleteHeaderOpen] = useState(null)
    const [isDeleteAllHeadersOpen, setIsDeleteAllHeadersOpen] = useState(false)
    const [deletingHeader, setDeletingHeader] = useState(null)
    const [modalInitialStage, setModalInitialStage] = useState(null)

    useEffect(() => {
        if (imageWithPinsModalData && imageWithPinsModalData.id === (header.data || {}).id) {
            setImageWithPinsModalData(header.data)
        }
    }, [header])

    const listMapping = {
        id: "id",
        source_id: "header_id",
        name: "name",
        image: "cover_desktop",
        active: "active",
        pins: "products_count"
    }

    const handleToggle = itemId => {
        const list = isModified ? modifiedData : data
        handleUpdate({
            ...list,
            headers: list.headers.map(item => ({
                ...item,
                active: item.id === itemId ? !item.active : item.active
            }))
        })
    }

    const handleReorder = headers => {
        handleUpdate({ ...data, headers })
    }

    const handleSave = data => {
        const payload = {
            id: data.id,
            name: data.name,
            cover_desktop: data.image_with_pins_desktop,
            cover_tablet: data.image_with_pins_tablet,
            cover_mobile: data.image_with_pins_mobile,
            products: data.products
        }

        payload.id
            ? handleHomepageHeaderUpdate(payload.id, payload, () => handleOnClose())
            : handleHomepageHeaderCreate(payload, () => handleOnClose())
    }

    const handleEdit = id => {
        getHeader(id)
        setModalInitialStage("chooseImage")
        setImageWithPinsModalData({ id })
    }

    const handleAddPin = id => {
        getHeader(id)
        setModalInitialStage("chooseProducts")
        setImageWithPinsModalData({ id })
    }

    const handleDeleteHeaderClose = () => {
        setIsDeleteHeaderOpen(false)
        setDeletingHeader(null)
    }

    const handleDeleteHeaderOpen = header => {
        setIsDeleteHeaderOpen(true)
        setDeletingHeader(header)
    }

    const handleDeleteHeader = () => {
        handleHomepageHeaderDelete(deletingHeader.id, handleDeleteHeaderClose)
    }

    const handleDeleteAllHeadersClose = () => {
        setIsDeleteAllHeadersOpen(false)
    }

    const handleDeleteAllHeadersOpen = () => {
        setIsDeleteAllHeadersOpen(true)
    }

    const handleDeleteAllHeaders = () => {
        handleDeleteAllFromList(
            { settings: data.settings, header: data.header, headers: [] },
            handleDeleteAllHeadersClose
        )
    }

    const handleOnClose = () => {
        setModalInitialStage(null)
        setImageWithPinsModalData(null)
    }

    const list = useMemo(() => {
        const listToIterate = (modifiedData ? modifiedData.headers : data.headers) || []
        const [newList] = listToIterate.reduce(
            ([headers, displayOrder], header) => {
                const [newHeader, newDisplayOrder] = header[listMapping.active]
                    ? [{ ...header, displayOrder }, displayOrder + 1]
                    : [header, displayOrder]

                return [[...headers, newHeader], newDisplayOrder]
            },
            [[], 1]
        )
        return newList
    }, [modifiedData, data])

    return (
        <ListingLayout>
            <Header
                title="Image with pins"
                addButton={{
                    label: "Add image",
                    handler: () => setImageWithPinsModalData({}),
                    isDisabled: false
                }}
            />

            <Listing
                isLoading={isLoading}
                handleDelete={handleDeleteHeaderOpen}
                handleDeleteAll={handleDeleteAllHeadersOpen}
                handleEdit={handleEdit}
                handleToggle={handleToggle}
                listingType="images"
                handleReorder={handleReorder}
                listMapping={listMapping}
                list={list}
                customActions={[{ name: "Add pin", icon: addIcon, action: handleAddPin }]}
                customColumns={[{ name: "pins", label: ["pin", "pins"] }]}
                customStyles={listingStyles}
            />

            <SaveBar
                message="Unpublished changes"
                submitLabel="Publish"
                isShown={isModified}
                isSaving={isSaving}
                onSubmit={handleSubmit}
                onCancel={handleClearChanges}
            />

            {!!imageWithPinsModalData && (
                <SectionModalImageWithPins
                    onClose={handleOnClose}
                    data={{
                        ...imageWithPinsModalData,
                        image_with_pins_desktop: imageWithPinsModalData.cover_desktop,
                        image_with_pins_desktop_edit: imageWithPinsModalData.cover_desktop_for_edit,
                        image_with_pins_tablet: imageWithPinsModalData.cover_tablet,
                        image_with_pins_mobile: imageWithPinsModalData.cover_mobile,
                        initialStage: modalInitialStage
                    }}
                    onSave={handleSave}
                    imageWithName={true}
                    preventCloseOnSave={true}
                    isLoading={header.isLoading}
                    imageDimensions={IMAGE_DIMENSIONS}
                />
            )}

            {isDeleteHeaderOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete this realization?"
                        description="Are you sure you want to delete the realization?"
                        subject={deletingHeader.name}
                        descriptionClosure="This action cannot be reversed."
                        handleHideModal={handleDeleteHeaderClose}
                        handleDelete={handleDeleteHeader}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            {isDeleteAllHeadersOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete all realizations?"
                        description="Are you sure you want to delete all of the realizations?"
                        descriptionClosure="This action cannot be reversed."
                        handleHideModal={handleDeleteAllHeadersClose}
                        handleDelete={handleDeleteAllHeaders}
                        isLoading={isSaving}
                    />
                </Modal>
            )}
        </ListingLayout>
    )
}

export default MainPageImageWithPins
