import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./RolesManagement.css"
import { Link } from "react-router-dom"
import RolesListCover from "./RolesListCover/RolesListCover"
import Button from "components/Reusable/Form/Button/index"

class RolesManagement extends Component {
    render() {
        const { cx, canAdd, PERMISSIONS } = this.props
        return (
            <div className={cx("round-border", "create-new-role-cover")}>
                <h1 className={cx("roles-title")}>Roles</h1>

                {canAdd(PERMISSIONS.context.ROLES) && (
                    <Link to="/settings/users/roles/newrole">
                        <Button className={["first-button", "add-button"]} label="Create New" customStyles={styles} />
                    </Link>
                )}
                <div className={cx("clear")} />
                <RolesListCover />
            </div>
        )
    }
}

export default withStyles(RolesManagement, styles)
