import React, { useState } from "react"
import { Formik, Form as FormikForm } from "formik"
import PropTypes from "prop-types"
import merge from "lodash/merge"
import idx from "idx"

import SectionModalVideo from "./SectionModalVideo"
import {
    getInitialValues as getProductLinkInitialValues,
    getSaveValues as getProductLinkSaveValues
} from "../ShowProductLink/values"
import { section as sectionSchema } from "./schema"

const Container = ({ onClose, onSave, data }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = merge(
        data,
        {
            video: (data.video || [])[0] || null,
            settings: {
                ...(data.settings || {}),
                video_type: idx(data, _ => _.settings.external_link) ? "link" : "file"
            }
        },
        getProductLinkInitialValues(data)
    )

    const onSubmit = async (values, formikActions) => {
        try {
            setIsSubmitting(true)

            await onSave(
                merge(
                    values,
                    {
                        video: values.settings.video_type === "file" && values.video ? values.video.id : null,
                        video_thumbnail: values.video_thumbnail ? values.video_thumbnail.id : null,
                        settings: {
                            ...values.settings,
                            external_link: values.settings.video_type === "link" ? values.settings.external_link : null,
                            video_type: undefined
                        }
                    },
                    getProductLinkSaveValues(values)
                )
            )

            onClose()
        } catch (error) {
            setIsSubmitting(false)
            formikActions.setErrors(error)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={sectionSchema}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {propsForm => (
                <FormikForm>
                    <SectionModalVideo onClose={onClose} form={propsForm} isSubmitting={isSubmitting} />
                </FormikForm>
            )}
        </Formik>
    )
}

Container.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default Container
