import React, { Component } from "react"
import { connect } from "react-redux"

import Categories from "./Categories"

class CategoriesContainer extends Component {
    render() {
        const { selectedCategories } = this.props

        return (
            <Categories
                {...this.props}
                selectedCategories={selectedCategories}
                handleSelectCategory={this.handleSelectCategory}
            />
        )
    }

    handleSelectCategory = ({ level, id }) => {
        const { selectedCategories, handleSaveCategories, categoryIndex } = this.props
        const newSelections = [...selectedCategories]

        newSelections.splice(level)

        handleSaveCategories({ categories: [...newSelections, id], categoryIndex })
    }
}

const mapStateToProps = state => {
    return {
        categories: state.db.categories.list.data.filter(i => i.name !== "Sale")
    }
}

export default connect(mapStateToProps)(CategoriesContainer)
