import React, { useState, Fragment, useMemo, useContext, useEffect } from "react"
import { useSelector } from "react-redux"
import cx from "classnames"
import PropTypes from "prop-types"
import {
    H1,
    EmptyList,
    AddIcon,
    WarehouseIcon,
    BoxIcon,
    Button,
    Panel,
    Table,
    TableHead,
    TableSortableHeadCell,
    TableHeadCell,
    TableBody,
    TableCell,
    TableCellWithExpandButton,
    TableRowWithExpandedDetails,
    useTableSort,
    TableFilterBarSearch,
    TableFilterBarSelect,
    TableFilterBarDatepicker,
    TableFilterBar,
    useTableFilterBarSearch,
    useTableFilterBarSelect,
    useTableFilterBarDatepicker,
    CheckboxSquare
} from "@butterfly-frontend/ui"

import { AddEditWarehouse, DeleteWarehouse } from "modules/WmsModule/modals"
import { Header, PrimaryLabel, WarehouseDetails, WarehouseStatus } from "modules/WmsModule/components"
import { useWarehouseDetails, useWarehouseDelete, useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import ListFiltersContext from "modules/WmsModule/contexts/ListFiltersContext"
import { SnakeLoader } from "ui/Skeleton"
import { Pagination } from "ui"
import { formatDate, toApiDate } from "helpers/date"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import styles from "./WarehouseListPage.module.css"

const STATUS_OPTIONS = [{ id: 1, label: "Active", value: true }, { id: 2, label: "Inactive", value: false }]

const WarehouseListPage = ({ canAdd }) => {
    const [addEditModalState, setAddEditModalState] = useState({ isOpen: false, isEdit: false })
    const [expandedRowId, setExpandedRowId] = useState(null)
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
    const [isWarehouseDeletionInProgress, setIsWarehouseDeletionInProgress] = useState(false)

    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)

    const {
        set: setFiltersContext,
        values: { warehouseList: filtersFromContext }
    } = useContext(ListFiltersContext)

    const { onChangeSort, sortState } = useTableSort({ initialState: { by: "code", direction: "asc" } })

    const filterBarSearchProps = useTableFilterBarSearch({ initialValue: filtersFromContext.query })
    const filterBarDatepickerProps = useTableFilterBarDatepicker({ initialValues: filtersFromContext.createdAt })
    const filterBarStatusesSelectProps = useTableFilterBarSelect({
        initialOptions: STATUS_OPTIONS,
        initialSelectedId: filtersFromContext.status ? filtersFromContext.status.id : undefined
    })

    const selectedStatus = filterBarStatusesSelectProps.selectedValue
    const createdAt = filterBarDatepickerProps.values
    const querySearch = filterBarSearchProps.debouncedValue

    useEffect(() => {
        setFiltersContext(prevState => ({
            ...prevState,
            warehouseList: {
                query: !querySearch,
                createdAt,
                status: selectedStatus
            }
        }))
    }, [querySearch, selectedStatus, createdAt])

    const filters = useMemo(
        () => ({
            query: querySearch,
            warehouses: assignedWarehouseIds,
            active: selectedStatus ? selectedStatus.value : undefined,
            ...(createdAt
                ? {
                      created_from: createdAt ? toApiDate(createdAt.start) : "",
                      created_to: createdAt ? toApiDate(createdAt.end) : ""
                  }
                : {})
        }),
        [querySearch, selectedStatus, createdAt]
    )

    const { data, fetchStatus, refetch, pagination, fetchFlags } = useTableLogic({
        filters,
        sort: sortState,
        useQueryList: useWarehouseList,
        reactQueryProps: {
            onSuccess: () => setExpandedRowId(null)
        }
    })

    const warehouseDetails = useWarehouseDetails({ id: expandedRowId, reactQueryProps: { enabled: !!expandedRowId } })

    const onAddEditSuccess = () => {
        refetch()

        if (addEditModalState.isEdit) {
            warehouseDetails.query.refetch()
        }

        setAddEditModalState({ isOpen: false })
    }

    const onWarehouseDeleteSuccess = () => {
        refetch()
        setIsDeleteModalOpen(false)
    }

    const warehouseDelete = useWarehouseDelete()

    const onWarehouseDelete = () => {
        setIsWarehouseDeletionInProgress(true)

        return warehouseDelete
            .mutate({ id: warehouseDetails.data.id })
            .then(onWarehouseDeleteSuccess)
            .finally(() => setIsWarehouseDeletionInProgress(false))
    }

    return (
        <React.Fragment>
            <Header>
                <H1 withLeftBorder>Warehouses</H1>
                {canAdd(PERMISSIONS.context.WMS) && (
                    <div>
                        <Button onClick={() => setAddEditModalState({ isOpen: true, isEdit: false })} Icon={AddIcon}>
                            Add warehouse
                        </Button>
                    </div>
                )}
            </Header>

            <Panel>
                {fetchStatus.isFirstFetch ? (
                    <div className={styles.loader}>
                        <SnakeLoader customStyles={styles.loader} />
                    </div>
                ) : (
                    <Fragment>
                        <TableFilterBar>
                            <TableFilterBarSearch placeholder="Search for warehouse..." {...filterBarSearchProps} />
                            <TableFilterBarSelect
                                labelSelectAll="All statuses"
                                placeholder="All statuses"
                                {...filterBarStatusesSelectProps.componentProps}
                            />
                            <TableFilterBarDatepicker
                                labelSelectAll="All time"
                                placeholder="In stock from"
                                {...filterBarDatepickerProps}
                            />
                        </TableFilterBar>
                        <div className={styles.tableContainer}>
                            {fetchStatus.isLoading && !fetchStatus.isFirstFetch && (
                                <div className={styles.loader}>
                                    <SnakeLoader customStyles={styles.loader} />
                                </div>
                            )}
                            {fetchStatus.isLoaded && (
                                <Table>
                                    <TableHead>
                                        <TableSortableHeadCell
                                            sortState={sortState}
                                            minWidth={200}
                                            width="15%"
                                            columnKey="code"
                                            onClick={onChangeSort}
                                        >
                                            ID
                                        </TableSortableHeadCell>
                                        <TableSortableHeadCell
                                            sortState={sortState}
                                            width="20%"
                                            minWidth={200}
                                            columnKey="name"
                                            onClick={onChangeSort}
                                        >
                                            NAME
                                        </TableSortableHeadCell>
                                        <TableSortableHeadCell
                                            sortState={sortState}
                                            width="10%"
                                            minWidth={110}
                                            columnKey="type"
                                            onClick={onChangeSort}
                                        >
                                            TYPE
                                        </TableSortableHeadCell>
                                        <TableSortableHeadCell
                                            sortState={sortState}
                                            width="10%"
                                            minWidth={110}
                                            columnKey="active"
                                            onClick={onChangeSort}
                                        >
                                            STATUS
                                        </TableSortableHeadCell>
                                        <TableHeadCell width="10%" minWidth={120}>
                                            PURPOSE
                                        </TableHeadCell>
                                        <TableHeadCell width="10%" minWidth={120}>
                                            REMOTE
                                        </TableHeadCell>
                                        <TableSortableHeadCell
                                            sortState={sortState}
                                            width="15%"
                                            minWidth={110}
                                            columnKey="created_at"
                                            onClick={onChangeSort}
                                        >
                                            CREATED DATE
                                        </TableSortableHeadCell>
                                        <TableHeadCell width={85} minWidth={85} />
                                    </TableHead>
                                    <TableBody>
                                        {fetchFlags.isEmptyListWithFilter && (
                                            <EmptyList
                                                Icon={WarehouseIcon}
                                                label="We couldn't find warehouses for selected filters."
                                            />
                                        )}
                                        {fetchFlags.isEmptyList && (
                                            <EmptyList Icon={WarehouseIcon} label="We couldn't find warehouses." />
                                        )}
                                        {data.map(row => (
                                            <TableRowWithExpandedDetails
                                                key={row.id}
                                                isExpanded={expandedRowId === row.id}
                                                detailsComponent={
                                                    warehouseDetails.fetchStatus.isLoaded ? (
                                                        <WarehouseDetails
                                                            warehouse={warehouseDetails.data}
                                                            onEdit={() =>
                                                                setAddEditModalState({
                                                                    isOpen: true,
                                                                    isEdit: true
                                                                })
                                                            }
                                                            onDelete={() => setIsDeleteModalOpen(true)}
                                                        />
                                                    ) : (
                                                        <div className={styles.loader}>
                                                            <SnakeLoader customStyles={styles.loader} />
                                                        </div>
                                                    )
                                                }
                                                classes={{ root: cx({ [styles.inactiveCell]: !row.active }) }}
                                            >
                                                <TableCell
                                                    width="15%"
                                                    minWidth={200}
                                                    classes={{ root: styles.cellCode }}
                                                >
                                                    {row.code}
                                                </TableCell>
                                                <TableCell
                                                    minWidth={200}
                                                    width="20%"
                                                    classes={{ root: styles.cellName }}
                                                >
                                                    <span>{row.name}</span>
                                                    {row.primary && <PrimaryLabel />}
                                                </TableCell>
                                                <TableCell
                                                    width="10%"
                                                    minWidth={110}
                                                    classes={{ root: cx(styles.defaultCell, styles.cellType) }}
                                                >
                                                    {row.type}
                                                </TableCell>
                                                <TableCell
                                                    width="10%"
                                                    minWidth={110}
                                                    classes={{ root: styles.defaultCell }}
                                                >
                                                    <WarehouseStatus active={row.active} />
                                                </TableCell>
                                                <TableCell
                                                    width="10%"
                                                    minWidth={120}
                                                    classes={{ root: styles.defaultCell }}
                                                >
                                                    {row.is_default_for_po ? (
                                                        <div className={styles.purpose}>
                                                            <BoxIcon className={styles.purposeIcon} />
                                                            Default for PO
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    width="10%"
                                                    minWidth={120}
                                                    classes={{ root: styles.defaultCell }}
                                                >
                                                    {row.autonomous ? (
                                                        <div className={styles.remote}>
                                                            <CheckboxSquare checked />
                                                            <span className={styles.remoteText}>Autonomous</span>
                                                        </div>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    width="15%"
                                                    minWidth={110}
                                                    classes={{ root: cx(styles.defaultCell, styles.cellCreatedAt) }}
                                                >
                                                    {formatDate(row.created_at)}
                                                </TableCell>
                                                <TableCellWithExpandButton
                                                    isExpanded={expandedRowId === row.id}
                                                    onClick={() =>
                                                        setExpandedRowId(prevState =>
                                                            prevState !== row.id ? row.id : null
                                                        )
                                                    }
                                                />
                                            </TableRowWithExpandedDetails>
                                        ))}
                                    </TableBody>
                                    {pagination.canDisplay && (
                                        <div className={styles.paginationWrapper}>
                                            <Pagination {...pagination} />
                                        </div>
                                    )}
                                </Table>
                            )}
                        </div>
                    </Fragment>
                )}
            </Panel>
            {addEditModalState.isOpen && (
                <AddEditWarehouse
                    onClose={() => setAddEditModalState({ isOpen: false })}
                    onSuccess={onAddEditSuccess}
                    warehouse={addEditModalState.isEdit ? warehouseDetails.data : undefined}
                    isEdit={addEditModalState.isEdit}
                />
            )}
            {isDeleteModalOpen && (
                <DeleteWarehouse
                    warehouse={warehouseDetails.data}
                    onCancel={() => setIsDeleteModalOpen(false)}
                    onDelete={onWarehouseDelete}
                    isLoading={isWarehouseDeletionInProgress}
                />
            )}
        </React.Fragment>
    )
}

WarehouseListPage.propTypes = {
    canAdd: PropTypes.func.isRequired
}

export default withPermissions(WarehouseListPage, PERMISSIONS.context.WMS)
