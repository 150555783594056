import React from "react"
import PropTypes from "prop-types"
import { SnakeLoader } from "ui/Skeleton"

import {
    StockAdjustmentDetailsItemsList,
    StockAdjustmentDetailsDescription,
    StockAdjustmentDetailsBoxList
} from "modules/WmsModule/components"

import useStockAdjustmentDetails from "./hooks/useStockAdjustmentDetails"

import styles from "./StockAdjustmentDetails.module.css"

const StockAdjustmentDetails = ({ stockAdjustmentId }) => {
    const {
        adjustmentItems,
        hasAdjustmentBoxes,
        adjustmentBoxes,
        description,
        fetchStatus
    } = useStockAdjustmentDetails({ stockAdjustmentId })

    if (!fetchStatus.isLoaded) {
        return (
            <div className={styles.loader}>
                <SnakeLoader customStyles={styles.loader} />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <StockAdjustmentDetailsItemsList adjustmentItems={adjustmentItems} />
            {hasAdjustmentBoxes && <StockAdjustmentDetailsBoxList boxes={adjustmentBoxes} />}
            {description && <StockAdjustmentDetailsDescription description={description} />}
        </div>
    )
}

StockAdjustmentDetails.propTypes = {
    stockAdjustmentId: PropTypes.number.isRequired
}

export default StockAdjustmentDetails
