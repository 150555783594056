import * as constants from "../constants"
import fetch from "../helpers/fetch"

export function getUsersStart() {
    return {
        type: constants.GET_USERS_START
    }
}
export function getUsersSuccess(data) {
    return {
        type: constants.GET_USERS_SUCCESS,
        payload: {
            data
        }
    }
}
export function getUsersError(error) {
    return {
        type: constants.GET_USERS_ERROR,
        payload: {
            error
        }
    }
}
export function getUsers() {
    return dispatch => {
        dispatch(getUsersStart())
        dispatch(getUserStart())

        fetch
            .get(`/user?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => dispatch(getUsersSuccess(data)))
            .catch(error => {
                dispatch(getUsersError(error))
            })
    }
}

function getUserStart() {
    return {
        type: constants.GET_USER_START
    }
}
function getUserSuccess(data) {
    return {
        type: constants.GET_USER_SUCCESS,
        payload: {
            data
        }
    }
}
function getUserError(error) {
    return {
        type: constants.GET_USER_ERROR,
        payload: {
            error
        }
    }
}
export function getUserById(id) {
    return dispatch => {
        dispatch(getUserStart())

        fetch
            .get(`/user/${id}`)
            .then(data => dispatch(getUserSuccess(data)))
            .catch(error => {
                dispatch(getUserError(error))
            })
    }
}

export function resetPassword(email) {
    return dispatch => {
        fetch.post(`/user/remind-password`, { email })
    }
}

export function deleteUserStart() {
    return {
        type: constants.DELETE_USER_START
    }
}

export function deleteUserError(error) {
    return {
        type: constants.DELETE_USER_ERROR,
        payload: {
            error
        }
    }
}
export function deleteUser(userId) {
    return dispatch => {
        dispatch(deleteUserStart())

        fetch
            .delete(`/user/${userId}`)
            .then(data => dispatch(getUsers()))
            .catch(error => {
                dispatch(deleteUserError(error))
            })
    }
}
