import { useEffect } from "react"
import { useTableFilterBarSearch } from "@butterfly-frontend/ui"

import fetch from "helpers/fetch"
import { useSelectData } from "ui/Filters/hooks"
import { objToQueryString } from "helpers/urls"

export const productToValue = value => ({ id: value.id, label: value.name, value: value })

const fetchSelectData = ({ pageParam = 1, queryKey: [_key, params] }) => {
    const { query, ...productParams } = params

    return fetch.get(`/products?${objToQueryString({ ...productParams, query, page: pageParam })}`)
}

const useSelectProduct = ({ setValue, params: productParams = {}, isDisabled }) => {
    const { debouncedValue: query, onChange, onClear: clearQuery } = useTableFilterBarSearch("")

    const { values, fetchValues, isLoading, error, propsForSelectWithSearch, selectDataQuery } = useSelectData({
        key: "products",
        params: { query, ...productParams },
        fetchSelectData,
        selectOptionsMapper: productToValue,
        setValue,
        infiniteQueryOptions: { enabled: !isDisabled }
    })

    useEffect(() => {
        clearQuery()
    }, [productParams])

    return {
        values,
        fetchValues,
        isLoading,
        error,
        propsForSelectWithSearch: {
            ...propsForSelectWithSearch,
            onInputChange: value => {
                onChange({ target: { value } })
            }
        },
        selectDataQuery
    }
}

export default useSelectProduct
