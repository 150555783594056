import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import List from "../TemplatesList"
import Header from "../TemplatesHeader"
import ListingLayout from "../ListingLayout"
import styles from "./DocsTemplates.module.css"
import { ActionButton, EmptyList } from "ui"
import editIcon from "assets/pencil.svg"
import documentIcon from "assets/ico-document.svg"
import documentIconDark from "assets/ico-document_dark.svg"
import EditDocsTemplate from "./components/EditDocsTemplate/EditDocsTemplate"

const DocsTemplates = ({ cx, docsTemplates, handleTableChange }) => {
    const [docsTemplateToEdit, setDocsTemplateToEdit] = useState()

    const closeEditModal = () => {
        setDocsTemplateToEdit(undefined)
    }

    const openEditModal = docsTemplate => {
        setDocsTemplateToEdit(docsTemplate)
    }

    const renderTableButtonsActions = docsTemplate => (
        <Fragment>
            <ActionButton handleClick={() => openEditModal(docsTemplate)} label="Edit" icon={editIcon} />
            <ActionButton handleClick={() => {}} label="Preview" className={cx("previewButton")} disabled={true} />
        </Fragment>
    )

    return (
        <Fragment>
            <ListingLayout>
                <section className={cx("root")}>
                    <Header title={"Docs templates"} />
                    <List
                        sort
                        renderActionsButtons={renderTableButtonsActions}
                        renderEmptyList={() => <EmptyList message="No document template added." icon={documentIcon} />}
                        handleTableChange={handleTableChange}
                        itemIcon={documentIconDark}
                        tableData={docsTemplates}
                        tableHeader="document type"
                    />
                </section>
            </ListingLayout>
            {docsTemplateToEdit && (
                <EditDocsTemplate docsTemplateToEdit={docsTemplateToEdit} handleClose={closeEditModal} />
            )}
        </Fragment>
    )
}

DocsTemplates.propTypes = {
    docsTemplates: PropTypes.object.isRequired,
    handleTableChange: PropTypes.func.isRequired
}

export default withStyles(DocsTemplates, styles)
