import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import withForm from "HOC/withForm"
import models from "models/index"

import ResetPassword from "./ResetPassword"

class ResetPasswordContainer extends Component {
    state = {
        isSuccess: false,
        password: "",
        repeat_password: ""
    }

    componentDidMount() {
        this.setToken(this.props)
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setToken(nextProps)
    }

    setToken(props) {
        const { token, fillModel } = props
        if (token === this.props.token) {
            return
        }

        fillModel({
            token
        })
    }

    handleSubmitSuccess(data) {
        this.setState(
            {
                isSuccess: true
            },
            () => {
                this.props.history.push("/login")
            }
        )
    }

    handleSubmitAction(event, fieldPath = "password") {
        event.preventDefault()
        const { handleSubmit } = this.props
        const { password, repeat_password } = this.state

        const { toRender, handleCustomError } = this.props
        const formFields = toRender()

        if (password.length === 0) return null

        if (password.length < 6 && password !== repeat_password) {
            return handleCustomError({
                error: formFields.repeat_password !== password ? "The Password must be at least 6 characters." : "",
                fieldPath
            })
        }

        if (password.length > 5 && password !== repeat_password) {
            fieldPath = "repeat_password"
            return handleCustomError({
                error: formFields.repeat_password !== password ? "Password must match!" : "",
                fieldPath
            })
        }

        return handleSubmit({ method: "post", handleSuccess: this.handleSubmitSuccess.bind(this) })
    }

    handleSetPasswordInState({ event, fieldPath }) {
        const value = event.target.value
        this.setState({
            [fieldPath]: value
        })
    }

    render() {
        const { isError, isSaving, toRender, handleFieldChange } = this.props
        const { isSuccess } = this.state
        return (
            <ResetPassword
                isError={isError}
                isSuccess={isSuccess}
                isSaving={isSaving}
                formFields={toRender()}
                handleSetPasswordInState={this.handleSetPasswordInState.bind(this)}
                handleFieldChange={handleFieldChange}
                handleSubmitAction={this.handleSubmitAction.bind(this)}
            />
        )
    }
}

export default withForm(withRouter(ResetPasswordContainer), { model: models.ResetPassword })
