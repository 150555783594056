import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { formatPrice } from "helpers/units"
import withStyles from "HOC/withStyles"
import styles from "./Summary.module.css"

const Summary = ({ calculations, cx }) => {
    const { sales, refunds, revenue, total_store_credits } = calculations
    const amounts = useMemo(
        () => [
            {
                label: "Sales",
                type: "sales",
                value: sales
            },
            {
                label: "Refunds",
                type: "refunds",
                value: -refunds
            },
            {
                label: "Store credits",
                value: total_store_credits,
                type: "storeCredits",
                color: "orange"
            },
            {
                label: "Revenue",
                value: Math.abs(revenue),
                type: "revenue",
                color: revenue < 0 ? "red" : "green"
            }
        ],
        [sales, refunds, revenue, total_store_credits]
    )

    return (
        <div className={cx("wrapper")}>
            {amounts.map(({ value, color, label, type }) => (
                <div className={`${cx("box")} ${cx(color)}`} key={type}>
                    <p className={cx("label")}>{label}</p>
                    <p className={cx("value")}>{formatPrice(value) || "$ 0"}</p>
                </div>
            ))}
        </div>
    )
}

Summary.propTypes = {
    calculations: PropTypes.shape({
        sales: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        refunds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        revenue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        total_store_credits: PropTypes.number
    }).isRequired
}

export default withStyles(Summary, styles)
