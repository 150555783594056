import { useCallback } from "react"
import { useMutation } from "react-query"

import { useActions } from "hooks"
import getPrintBlob, { showPrintWindow, downloadBlobFile } from "actions/print"
import { showErrorNotification } from "actions/notification"

export const DOCUMENT_ACTIONS = {
    PRINT: "PRINT",
    DOWNLOAD: "DOWNLOAD"
}

const useDocument = ({ actionType = DOCUMENT_ACTIONS.PRINT, documentType, templateType, fileName, customFetch }) => {
    const actions = useActions({ showErrorNotification })

    const fetchFunction = useCallback(
        customFetch ? customFetch : ({ ids }) => getPrintBlob(ids, documentType, templateType),
        [customFetch, documentType, templateType]
    )

    return useMutation(fetchFunction, {
        onError: actions.showErrorNotification,
        onSuccess:
            actionType === DOCUMENT_ACTIONS.PRINT ? showPrintWindow : blob => downloadBlobFile(blob, { fileName })
    })
}

export default useDocument
