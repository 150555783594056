import React, { Fragment } from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import { Field } from "formik"

import { Modal, Radio, Button } from "ui"

import { Image } from "./components"
import ShowProductLink from "../ShowProductLink"

import styles from "./styles.css"
import radioStyles from "./overrides/Radio.css"

const IMAGES_DIMENSIONS = {
    single: {
        width: 1390
    },
    double: {
        width: 685
    }
}

const SectionModalImage = ({ onClose, cx, form, isSubmitting }) => {
    const setImage = (field, image) => {
        form.setFieldValue(field, image)
        form.setErrors({
            ...form.errors,
            [field]: undefined
        })
    }

    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
            <p className={cx("header")}>Image</p>

            <Field name="settings.type">
                {({ field }) => (
                    <Fragment>
                        <Radio
                            checked={field.value === "single"}
                            name={field.name}
                            value="single"
                            label="Single image"
                            handleSelect={(key, value) => {
                                form.setFieldValue(key, value)
                                form.setErrors([])
                            }}
                            customStyles={radioStyles}
                            isHorizonal={true}
                        />

                        <Radio
                            checked={field.value === "double"}
                            name={field.name}
                            value="double"
                            label="Two images"
                            handleSelect={form.setFieldValue}
                            customStyles={radioStyles}
                            isHorizonal={true}
                        />
                    </Fragment>
                )}
            </Field>

            <div className={cx("line")} />

            <div className={cx("dropzones")}>
                <Image
                    form={form}
                    setImage={image => setImage("first_image", image)}
                    image={form.values.first_image}
                    isError={!!form.errors.first_image}
                    imageDimensions={IMAGES_DIMENSIONS[form.values.settings.type]}
                />

                {form.values.settings.type === "double" && (
                    <Image
                        form={form}
                        setImage={image => setImage("second_image", image)}
                        image={form.values.second_image}
                        isError={!!form.errors.second_image}
                        imageDimensions={IMAGES_DIMENSIONS[form.values.settings.type]}
                    />
                )}
            </div>

            <ShowProductLink />

            <div className={cx("buttons")}>
                <span onClick={onClose} className={cx("cancelButton")}>
                    Cancel
                </span>
                <Button
                    className={cx("first-button")}
                    label="Save"
                    onClick={form.submitForm}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

SectionModalImage.propTypes = {
    onClose: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(SectionModalImage, styles)
