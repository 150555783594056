import { createContext } from "react"

export const INITIAL_STOCK_MANAGEMENT_FILTERS = {
    query: "",
    warehouses: [],
    inStockFrom: undefined,
    moreFilters: {
        productConditions: [],
        brands: [],
        statuses: [],
        transfers_count: "",
        order_uuid: "",
        invoice_uuid: "",
        incoming: undefined
    }
}

const INITIAL_WAREHOUSE_LIST_FILTERS = {
    query: "",
    status: undefined,
    createdAt: undefined
}

const INITIAL_STOCK_TRANSFER_LIST_FILTERS = {
    query: "",
    statuses: [],
    updatedAt: undefined,
    sourceWarehouses: [],
    destinationWarehouses: [],
    createdBy: []
}

const INITIAL_STOCK_ADJUSTMENT_LIST_FILTERS = {
    query: "",
    warehouses: [],
    statuses: [],
    conditions: [],
    locations: [],
    date: undefined,
    createdBy: []
}

const INITIAL_LOGISTICS_LIST_FILTERS = {
    query: "",
    types: [],
    statuses: [],
    date: undefined,
    sourceWarehouses: [],
    destinationWarehouses: [],
    createdBy: []
}

const INITIAL_SHIPMENTS_LIST_FILTERS = {
    sourceWarehouse: undefined
}

const INITIAL_RECEIVING_LIST_FILTERS = {
    destinationWarehouse: undefined
}

export const INITIAL_FILTERS = {
    stockManagement: INITIAL_STOCK_MANAGEMENT_FILTERS,
    warehouseList: INITIAL_WAREHOUSE_LIST_FILTERS,
    stockTransferList: INITIAL_STOCK_TRANSFER_LIST_FILTERS,
    stockAdjustmentList: INITIAL_STOCK_ADJUSTMENT_LIST_FILTERS,
    shipmentsList: INITIAL_SHIPMENTS_LIST_FILTERS,
    receivingList: INITIAL_RECEIVING_LIST_FILTERS,
    logisticsList: INITIAL_LOGISTICS_LIST_FILTERS
}

const ListFiltersContext = createContext({
    values: INITIAL_FILTERS,
    set: () => {}
})

export default ListFiltersContext
