import React, { Component } from "react"
import { isTokenValid } from "helpers/jwt"

const withTokenValidation = WrappedComponent => {
    class Enhance extends Component {
        isTokenValid = false

        constructor(props) {
            super(props)

            this.isTokenValid = isTokenValid(window.localStorage.getItem("accessToken"))

            if (!this.isTokenValid) {
                window.localStorage.setItem("redirectPath", window.location.pathname)
                document.location.href = "/login"
                return
            }
        }

        render() {
            if (!this.isTokenValid) {
                return <div>...</div>
            }

            return <WrappedComponent {...this.props} />
        }
    }

    return Enhance
}

export default withTokenValidation
