import { combineReducers } from "redux"

import list from "./list"
import current from "./current"

const assistancesReducer = combineReducers({
    list,
    current
})

export default assistancesReducer
