import React, { useState, useEffect } from "react"
import { SelectWithSearch } from "ui"
import fetch from "helpers/fetch"

const NO_ROOM = {
    id: null,
    label: "Not assigned to a room",
    value: null
}

const SelectProjectRoom = ({ customStyles, handleSelect, label, name, value, projectId, selectWithSearchProps }) => {
    const [rooms, setRooms] = useState([])

    useEffect(() => {
        fetchRooms()
    }, [])

    const fetchRooms = () => {
        const formatData = data => data.map(({ id, name }) => ({ id, value: id, label: name }))

        fetch.get(`/rooms?project_id=${projectId}&page=1&length=9999`).then(payload => {
            setRooms([NO_ROOM, ...formatData(payload.data)])
        })
    }

    return (
        <SelectWithSearch
            name={name}
            label={label}
            value={value}
            values={rooms}
            setValue={setValue}
            customStyles={customStyles}
            withoutFetch
            {...selectWithSearchProps}
        />
    )

    function setValue(value) {
        handleSelect({ target: { name, value: value.value ? value : null } })
    }
}

SelectProjectRoom.defaultProps = {
    value: []
}

export default SelectProjectRoom
