import React, { useState } from "react"
import idx from "idx"

import { useFetch, useActions } from "hooks"
import fetch from "helpers/fetch"
import { getOrder } from "actions/orders"
import { showErrorNotification } from "actions/notification"

import { NotesSection } from "ui/Notes"
import { Header, Info, ToggleDetails, Details, Summary, ProductsList } from "./components"
import Sendable from "modules/OrdersModule/components/Sendable"

import getStyles from "helpers/getCxFromStyles"
import styles from "./overviewinfo.module.css"

const OverviewInfo = ({ order, overviewConfig }) => {
    const cx = getStyles(styles)
    const { id, uuid, revisedAt, validDate, deliveryDate, issuedAt, emailedAt, printedAt, users, client } = order
    const [areDetailsShown, setAreDetailsShown] = useState(false)
    const [isBottomBarVisible, setIsBottomBarVisible] = useState(false)
    const [isSendModalOpened, setIsSendModalOpened] = useState(false)
    const [printTemplateType, setPrintTemplateType] = useState(null)
    const { singleName, info, orderType, ordersRoute } = overviewConfig

    const actions = useActions({ getOrder, showErrorNotification })

    const handleOpenModal = templateType => {
        setIsSendModalOpened(true)
        setPrintTemplateType(templateType)
    }

    const [refreshTax, { isLoading: isRefreshingTax }] = useFetch({
        action: async () => fetch.post(`/orders/${id}/refresh-tax`),
        onSuccess: () => actions.getOrder(id, true),
        onError: error =>
            actions.showErrorNotification(idx(Object.values(error.errors || {}), _ => _[0][0]) || error.message)
    })

    const clientEmails = [client.email, client.alternative_email]

    return (
        <section className={cx("root", { isBottomBarVisible })}>
            <Header
                id={id}
                singleName={singleName}
                ordersRoute={ordersRoute}
                openSendModal={handleOpenModal}
                actionsDisabled={order.items.length === 0}
            />
            <Info
                isLastColumnMarkedOut={true}
                users={users}
                revisedAt={revisedAt}
                validDate={validDate}
                deliveryDate={deliveryDate}
                issuedAt={issuedAt}
                emailedAt={emailedAt}
                printedAt={printedAt}
                config={info}
            />
            <ToggleDetails areDetailsShown={areDetailsShown} setAreDetailsShown={setAreDetailsShown} />
            <Details {...order} areDetailsShown={areDetailsShown} />
            <ProductsList
                setIsBottomBarVisible={setIsBottomBarVisible}
                overviewConfig={overviewConfig}
                refreshTax={refreshTax}
                isRefreshingTax={isRefreshingTax}
            />
            <Summary
                order={order}
                overviewConfig={overviewConfig}
                onRefreshTax={refreshTax}
                isRefreshingTax={isRefreshingTax}
            />
            <NotesSection notes={order.notes} type="order" id={id} />
            {isSendModalOpened && (
                <Sendable
                    handleClose={() => setIsSendModalOpened(false)}
                    id={id}
                    uuid={uuid}
                    clientEmails={clientEmails}
                    printTemplateType={printTemplateType}
                    type={orderType}
                />
            )}
        </section>
    )
}

export default OverviewInfo
