import React, { useState } from "react"
import { SketchPicker } from "react-color"
import PropTypes from "prop-types"
import getCxFromStyles from "helpers/getCxFromStyles"
import styles from "./SketchColorPicker.css"

const SketchColorPicker = props => {
    const { value, onChange, presetColors, isFontColor, backgroundColor } = props
    const cx = getCxFromStyles(styles)
    const [isExpandColorPicker, setIsExpandColorPicker] = useState(false)

    const toggleExpandColorPicker = () => setIsExpandColorPicker(!isExpandColorPicker)
    const handleChange = ({ hex }) => onChange(hex)

    return (
        <div className={cx("root", { selected: !!value, isOpen: isExpandColorPicker })}>
            <div className={cx("colorInput")} onClick={toggleExpandColorPicker}>
                <div
                    className={cx("color")}
                    style={{
                        backgroundColor: backgroundColor || value,
                        color: value
                    }}
                >
                    {!!isFontColor && "Aa"}
                </div>
                <div className={cx("icon")} />
            </div>
            {isExpandColorPicker ? (
                <div className={cx("popover")}>
                    <div className={cx("cover")} onClick={toggleExpandColorPicker} />
                    <SketchPicker color={value} onChange={handleChange} presetColors={presetColors} />
                </div>
            ) : null}
        </div>
    )
}

SketchColorPicker.propTypes = {
    value: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    isFontColor: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    presetColors: PropTypes.arrayOf(PropTypes.string)
}

export default SketchColorPicker
