import React, { Component } from "react"

import Layout from "components/Reusable/Layout"

class ListingLayout extends Component {
    render() {
        const { children } = this.props
        return (
            <Layout
                {...this.props}
                title={"Navigation"}
                tabs={[
                    {
                        url: "/content/categories-menu",
                        label: "Menu Categories"
                    },
                    {
                        url: "/content/default-filters",
                        label: "Default Filters"
                    }
                ]}
            >
                {children}
            </Layout>
        )
    }
}

export default ListingLayout
