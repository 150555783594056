export const checkIsDark = color => {
    const colorRgb = {
        red: parseInt(color.substr(1, 2), 16) * 0.2126,
        green: parseInt(color.substr(3, 2), 16) * 0.7152,
        blue: parseInt(color.substr(5, 2), 16) * 0.0722
    }

    const lunaBrightness = (colorRgb.red + colorRgb.blue + colorRgb.green) / 255

    return lunaBrightness < 0.4
}
