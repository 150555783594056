import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { useActions } from "hooks"
import { selectCalendarType, unselectCalendarType, setDisplayOnlyCalendar } from "modules/CalendarModule/actions"
import CalendarList from "./CalendarList"

const CalendarListContainer = ({ handleDelete, handleEdit, isExpanded, toggleModal, handleExport }) => {
    const dispatch = useDispatch()
    const privateList = useSelector(state => state.db.calendar.list.data.private)
    const publicList = useSelector(state => state.db.calendar.list.data.public)

    const actions = useActions({ selectCalendarType, unselectCalendarType })

    const handleDisplayOnlyCalendar = id => dispatch(setDisplayOnlyCalendar(id))

    const handleCheckboxChange = (calendar, type) => {
        const { id, is_selected } = calendar

        if (is_selected) {
            actions.unselectCalendarType(id, type)
            return
        }
        actions.selectCalendarType(id, type)
    }

    const handleCheckboxesTypeChange = (is_selected, type) => {
        if (is_selected) {
            actions.unselectCalendarType(null, type)
            return
        }
        actions.selectCalendarType(null, type)
    }

    return (
        <CalendarList
            handleDisplayOnlyCalendar={handleDisplayOnlyCalendar}
            handleCheckboxChange={handleCheckboxChange}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            isExpanded={isExpanded}
            privateCount={privateList.length}
            privateList={privateList}
            publicCount={publicList.length}
            publicList={publicList}
            toggleModal={toggleModal}
            handleExport={handleExport}
            handleCheckboxesTypeChange={handleCheckboxesTypeChange}
        />
    )
}

export default CalendarListContainer
