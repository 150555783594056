import React, { useEffect, useState, Fragment, useMemo } from "react"
import idx from "idx"

import ReactSvg from "react-svg"
import { Details, ClientCommunication } from "./tabs"

import withStyles from "HOC/withStyles"
import styles from "./moreDetails.css"

import closeIcon from "assets/close-blue.svg"

const tabs = {
    details: { label: "Details", Component: Details },
    clientCommunication: { label: "Client communication", Component: ClientCommunication }
}

const MoreDetails = props => {
    const { cx, isService, toggleDetails, product, changeTab } = props
    const { id, vendor, categories, name, dimensions, created_at, notes, room_id, description, added_by } = product

    const [currentTab, setCurrentTab] = useState(props.currentTab || "details")

    const CurrentComponent = tabs[currentTab].Component
    const addedByFullName = useMemo(() => idx(added_by, _ => _.full_name), [added_by])

    const currentComponentProps = {
        details: { vendor, categories, name, dimensions, addedByFullName, created_at },
        clientCommunication: { notes, room_id, projectItemId: id }
    }

    const serviceDetails = {
        "Original service name:": name || "-",
        "Description:": description || "-"
    }

    useEffect(() => {
        setCurrentTab(props.currentTab || "details")
    }, [props.changeTab])

    return (
        <div className={cx("root")}>
            {isService ? (
                <div className={cx("serviceWrapper")}>
                    {Object.entries(serviceDetails).map(([key, value]) => (
                        <div key={key}>
                            <label>{key}</label>
                            {value}
                        </div>
                    ))}
                </div>
            ) : (
                <Fragment>
                    <div className={cx("topBar")}>
                        <ul>
                            {Object.entries(tabs).map(([key, { label }]) => (
                                <li
                                    key={key}
                                    className={cx("li", { active: key === currentTab })}
                                    onClick={() => {
                                        changeTab(key)
                                        setCurrentTab(key)
                                    }}
                                >
                                    {label}
                                    {key === "clientCommunication" && (
                                        <span>{Array.isArray(notes) ? notes.length : 0}</span>
                                    )}
                                </li>
                            ))}
                        </ul>
                        <button className={cx("closeButton")} onClick={toggleDetails}>
                            Close <ReactSvg src={closeIcon} />
                        </button>
                    </div>
                    <div>{<CurrentComponent {...currentComponentProps[currentTab]} />}</div>
                </Fragment>
            )}
        </div>
    )
}

export default withStyles(MoreDetails, styles)
