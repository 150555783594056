import * as constants from "constants/index"

const initialState = { data: {}, projects: [], isLoading: false, isLoaded: false, isError: false }

export default (state = initialState, action) => {
    if (constants.GET_PRODUCTS_PROJECTS_START === action.type) {
        return { data: {}, projects: {}, isLoading: true, isLoaded: false, isError: false }
    }

    if (constants.GET_PRODUCTS_PROJECTS_SUCCESS === action.type) {
        return {
            data: (action.payload.data.data || []).reduce((acc, item) => {
                ;(item.products || []).forEach(p => {
                    if (!acc[p.id]) {
                        acc[p.id] = [item]
                    } else {
                        acc[p.id].push(item)
                    }
                })

                return acc
            }, {}),
            projects: (action.payload.data.data || []).reduce((acc, item) => {
                acc[item.id] = item

                return acc
            }, {}),
            isLoading: false,
            isLoaded: true,
            isError: false
        }
    }

    if ("UPDATE_PRODUCT_PROJECTS" === action.type) {
        return {
            ...state,
            data: {
                ...state.data,
                [action.payload.id]: [...action.payload.projects]
            }
        }
    }

    if (constants.GET_PRODUCTS_PROJECTS_ERROR === action.type) {
        return { data: {}, projects: {}, isLoading: false, isLoaded: false, isError: true }
    }

    return state
}
