import React from "react"

import { createSelectable } from "react-selectable-fast"

import withStyles from "HOC/withStyles"
import styles from "./Attribute.css"

const SelectableItem = createSelectable(({ selectableRef, selected, selecting, cx, children }) => (
    <li ref={selectableRef} className={cx("element", { selected, selecting })}>
        {children}
    </li>
))

const Attribute = ({ cx, attribute, isSelectable, handleToggleAddAttribute }) => {
    return (
        <li className={cx("root", { isSelectable })}>
            <div className={cx("attribute")}>
                <div className={cx("attributeLabel")}>{attribute.label}</div>
                {/* <button type="button" className={cx("addElement")} onClick={handleToggleAddAttribute}>
                    +
                </button> */}
            </div>
            <ul className={cx("elementsWrapper")}>
                {attribute.values.map(({ id, value, attribute_id }) => {
                    if (!isSelectable) {
                        return (
                            <li key={`not-selectable-${id}`} className={cx("element")}>
                                {value}
                            </li>
                        )
                    }

                    return (
                        <SelectableItem key={`selectable-${id}`} cx={cx} value={id}>
                            {value}
                        </SelectableItem>
                    )
                })}
            </ul>
        </li>
    )
}

export default withStyles(Attribute, styles)
