import React from "react"
import { connect } from "react-redux"

import withStyles from "HOC/withStyles"
import { Toggle } from "ui"
import MultiCategories from "../GeneralInformations/MultiCategories"
import { useIsTablet } from "helpers/rwd"

import styles from "./styles.css"
import stylesToggle from "../overrides/Toggle.css"

const Sidebar = props => {
    const { cx, isEdit, isMultipleCombinations, form } = props

    const isTablet = useIsTablet()

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <h3>Options</h3>
                <div className={cx("item")}>
                    <div className={cx("itemLabel")}>Combinations</div>
                    <div className={cx("itemValue")}>
                        <Toggle
                            isOn={isMultipleCombinations}
                            handleChange={() => form.setFieldValue("has_combinations", !form.values.has_combinations)}
                            customStyles={stylesToggle}
                        />
                    </div>
                </div>
                <div className={cx("item")}>
                    <div className={cx("itemLabel")}>Verified</div>
                    <div className={cx("itemValue")}>
                        <Toggle
                            isOn={form.values.verified}
                            handleChange={() => form.setFieldValue("verified", !form.values.verified)}
                            customStyles={stylesToggle}
                        />
                    </div>
                </div>
                {!!form.values.verified && (
                    <div className={cx("item")}>
                        <div className={cx("itemLabel")}>Available on web</div>
                        <div className={cx("itemValue")}>
                            <Toggle
                                isOn={form.values.available_on_web}
                                handleChange={() =>
                                    form.setFieldValue("available_on_web", !form.values.available_on_web)
                                }
                                customStyles={stylesToggle}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div className={cx("section")}>
                <h3>Category</h3>
                <div className={cx("item")}>
                    <div className={cx("itemLabel")}>Clearance</div>
                    <div className={cx("itemValue")}>
                        <Toggle
                            isOn={!!form.values.clearance}
                            handleChange={value => form.setFieldValue("clearance", value)}
                            customStyles={stylesToggle}
                        />
                    </div>
                </div>
                <MultiCategories isTablet={isTablet} isEdit={isEdit} form={form} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        categories: state.db.categories.list.data
    }
}

export default withStyles(connect(mapStateToProps)(Sidebar), styles)
