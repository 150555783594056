import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import _get from "lodash/get"
import { ClockIcon } from "@butterfly-frontend/ui"

import { READABLE_STANDARD_DATE } from "constants/index"
import { formatDate } from "helpers/date"

import styles from "./DateLabel.module.css"

const DateLabel = ({ date, format = READABLE_STANDARD_DATE, classes }) => (
    <div className={classNames(styles.date, _get(classes, "date"))}>
        <ClockIcon className={styles.clockIcon} />
        {formatDate(date, format)}
    </div>
)

DateLabel.propTypes = {
    date: PropTypes.string.isRequired,
    format: PropTypes.string,
    classes: PropTypes.shape({
        date: PropTypes.string
    })
}

export default DateLabel
