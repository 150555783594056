import useQueryListFactory from "./useQueryListFactory"
import useQueryDetailsFactory from "./useQueryDetailsFactory"
import useMutationFactory from "./useMutationFactory"

const useCrudFactory = ({ key, fetchFunctions, defaultListParams }) => {
    return {
        list: useQueryListFactory({
            key,
            fetchFunction: fetchFunctions.list,
            defaultParams: defaultListParams
        }),
        details: useQueryDetailsFactory({ key, fetchFunction: fetchFunctions.details }),
        create: useMutationFactory({ key, fetchFunction: fetchFunctions.create }),
        edit: useMutationFactory({ key, fetchFunction: fetchFunctions.edit }),
        delete: useMutationFactory({ key, fetchFunction: fetchFunctions.delete })
    }
}

export default useCrudFactory
