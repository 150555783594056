import React from "react"
import { withRouter } from "react-router-dom"

import withStyles from "HOC/withStyles"

import styles from "./styles/ListItem.css"
import { DropList } from "ui"

const ListItem = props => {
    const { cx, service, children, renderPrice, handleEdit, handleDelete } = props

    if (typeof children === "function") {
        return children(props)
    }

    function renderDropList() {
        return (
            <div>
                <DropList
                    trigger={
                        <div className={cx("editButton")}>
                            <span />
                            <span />
                            <span />
                        </div>
                    }
                >
                    <DropList.List>
                        <DropList.Item
                            onClick={() => {
                                handleEdit(service.id)
                            }}
                        >
                            Edit
                        </DropList.Item>
                        <DropList.Divider />
                        <DropList.Item
                            className="danger"
                            onClick={() => {
                                handleDelete(service.id, service.name)
                            }}
                        >
                            Delete
                        </DropList.Item>
                    </DropList.List>
                </DropList>
            </div>
        )
    }

    return (
        <div className={cx("listItem")}>
            <div className={cx("nameContainer", "nameItem")}>
                <span>{service.name}</span>
            </div>
            <div className={cx("descriptionContainer", "descriptionItem")}>
                <span>{service.description}</span>
            </div>
            <div className={cx("exemptCodeContainer", "exemptCodeItem")}>{service.exempt_code}</div>
            <div className={cx("priceContainer", "priceItem")}>{renderPrice(service.price)}</div>
            <div className={cx("optionContainer")}>
                <hr className={cx("line")} />
                {renderDropList()}
            </div>
        </div>
    )
}

export default withStyles(withRouter(ListItem), styles)
