import { DEFAULT_COUNTRY } from "constants/index"
import WAREHOUSE_TYPE from "modules/WmsModule/constants/warehouseType"

export default {
    primary: false,
    name: "",
    code: "",
    phone: "+1",
    email: "",
    type: WAREHOUSE_TYPE.NORMAL,
    address: {
        street: "",
        city: "",
        state: "",
        post_code: "",
        country: DEFAULT_COUNTRY
    },
    manager: {
        name: "",
        job_title: "",
        phone: "+1",
        mobile: "+1",
        email: ""
    },
    active: true,
    isPrimaryChangeConfirmed: false,
    autonomous: false,
    is_default_for_po: false
}
