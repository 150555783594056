import * as constants from "constants/index"
import fetch from "helpers/fetch"

export function getFeaturesStart() {
    return {
        type: constants.GET_FEATURES_START
    }
}
export function getFeaturesSuccess(data) {
    return {
        type: constants.GET_FEATURES_SUCCESS,
        payload: {
            data
        }
    }
}
export function getFeaturesError(error) {
    return {
        type: constants.GET_FEATURES_ERROR,
        payload: {
            error
        }
    }
}
export function getFeatures() {
    return dispatch => {
        dispatch(getFeaturesStart())
        return fetch
            .get(`/product-features?length=${constants.LENGTH_WITHOUT_PAGINATION}`)
            .then(data => {
                dispatch(getFeaturesSuccess(data))
            })
            .catch(error => {
                dispatch(getFeaturesError(error))
            })
    }
}

export function getFeatureByIdStart() {
    return {
        type: constants.GET_FEATURE_BY_ID_START
    }
}
export function getFeatureByIdSuccess(data) {
    return {
        type: constants.GET_FEATURE_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}
export function getFeatureByIdError(error) {
    return {
        type: constants.GET_FEATURE_BY_ID_ERROR,
        payload: {
            error
        }
    }
}
export function getFeatureById(id) {
    return dispatch => {
        dispatch(getFeatureByIdStart())
        fetch
            .get(`/product-features/${id}`)
            .then(data => {
                dispatch(getFeatureByIdSuccess(data))
            })
            .catch(error => {
                dispatch(getFeatureByIdError(error))
            })
    }
}

export function getFeatureValueByIdStart() {
    return {
        type: constants.GET_FEATURE_VALUE_BY_ID_START
    }
}

export function getFeatureValueByIdSuccess(data) {
    return {
        type: constants.GET_FEATURE_VALUE_BY_ID_SUCCESS,
        payload: {
            data
        }
    }
}
export function getFeatureValueByIdError(error) {
    return {
        type: constants.GET_FEATURE_VALUE_BY_ID_ERROR,
        payload: {
            error
        }
    }
}
export function getFeatureValueById(featureId, id) {
    return dispatch => {
        dispatch(getFeatureValueByIdStart())
        fetch
            .get(`/product-feature-values/${id}`)
            .then(data => {
                dispatch(getFeatureValueByIdSuccess(data))
            })
            .catch(error => {
                dispatch(getFeatureValueByIdError(error))
            })
    }
}
