import React from "react"
import PropTypes from "prop-types"

import SubmitModal from "ui/SubmitModal"

const DeleteModal = props => {
    const { isOpen, isLoading, type, onClose, onSubmit } = props

    return (
        <SubmitModal
            redButton
            isOpen={isOpen}
            isLoading={isLoading}
            header={`Delete this ${type}?`}
            body="This action cannot be reversed."
            submitText="Delete"
            onClose={onClose}
            onSubmit={onSubmit}
        />
    )
}

DeleteModal.defaultProps = {
    type: "note"
}

DeleteModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    type: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default DeleteModal
