import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Field } from "formik"
import classNames from "classnames"

import { Input, Textarea, VendorSelect, PhotoUploader } from "ui"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"

import styles from "./ManualEntryHeroForm.module.css"
import PhotoUploaderStyles from "./overrides/PhotoUploaderStyles.module.css"

const ManualEntryHeroForm = ({ setFieldValue, handleChangeField, values, errors, fieldStyles, selectedBrand }) => (
    <div className={styles.heroForm}>
        <div className={classNames(styles.photoUploader)}>
            <Field name="image">
                {({ field }) => (
                    <Fragment>
                        <label>Image:</label>
                        <PhotoUploader
                            customStyles={PhotoUploaderStyles}
                            avatar={values.image && values.image.url}
                            endpoint={DEFAULT_FILE_UPLOAD_ENPOINT}
                            handleRemovePhoto={() => setFieldValue(field.name, null)}
                            handleUploadSuccess={state => handleChangeField(field.name, state.addedFile)}
                        />
                    </Fragment>
                )}
            </Field>
        </div>

        <div className={styles.informationForm}>
            <Field name="name">
                {({ field, meta: { touched } }) => (
                    <Input
                        value={values[field.name] || ""}
                        onChange={value => handleChangeField(field.name, value.event.target.value)}
                        attributes={{
                            required: true
                        }}
                        label="Product name"
                        className={styles.name}
                        error={touched && errors[field.name]}
                        customStyles={fieldStyles}
                    />
                )}
            </Field>

            <Field name="vendor">
                {({ field }) => (
                    <div className={styles.vendor}>
                        <VendorSelect
                            name={field.name}
                            value={(field.value || {}).name}
                            label="Vendor"
                            setValue={value => handleChangeField(field.name, value)}
                            isClearBlocked={false}
                            isDisabled={!!selectedBrand}
                            isRequired
                        />
                    </div>
                )}
            </Field>

            <Field name="note">
                {({ field, meta: { touched } }) => (
                    <Textarea
                        value={field.value}
                        name={field.name}
                        type={"text"}
                        onChange={value => handleChangeField(field.name, value.event.target.value)}
                        label="Note"
                        placeholder="Click here to add a note..."
                        className={styles.note}
                        error={touched && errors[field.name]}
                        customStyles={fieldStyles}
                    />
                )}
            </Field>
        </div>
    </div>
)

const imagePropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired
})

const brandPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
})

ManualEntryHeroForm.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    handleChangeField: PropTypes.func.isRequired,
    values: PropTypes.shape({
        image: imagePropType,
        name: PropTypes.string,
        note: PropTypes.string,
        vendor: brandPropType
    }),
    errors: PropTypes.shape({
        image: PropTypes.string,
        name: PropTypes.string,
        note: PropTypes.string,
        vendor: PropTypes.string
    }),
    fieldStyles: PropTypes.object,
    selectedBrand: brandPropType
}

export default ManualEntryHeroForm
