import React, { Component, forwardRef, createRef } from "react"
import classnames from "classnames"
import withStyles from "HOC/withStyles"

import forceEmptyDecimalValue from "helpers/forceEmptyDecimalValue"
import {
    formatDimensionToImperial,
    formatDimensionToMetric,
    formatWeightToImperial,
    formatWeightToMetric,
    formatPrice
} from "helpers/units"
import Section from "components/Reusable/Form/Section"
import { ExpandIcon } from "@butterfly-frontend/ui"
import { Input as UIInput, Textarea as UITextarea, SaveBar, SelectWithSearch, Toggle } from "ui"
import fetch from "helpers/fetch"
import { PricingList } from "modules/ProductsModule/components"

import Features from "./Features"
import SpecificationsUploader from "./SpecificationsUploader"
import styles from "./GeneralInformations.css"
import selectStyles from "./overrides/Select.css"
import inputStyles from "../overrides/Input/Input.css"
import manufacturingInputStyles from "../overrides/Input/manufacturingTime.css"
import stylesSection from "../overrides/Section.css"
import stylesSectionFirst from "../overrides/SectionFirst.css"
import textareaStyles from "../overrides/Textarea.css"
import selectErrorStyles from "./overrides/Error.css"
import stylesToggle from "./overrides/Toggle.css"
import stylesTextareaShortDescription from "./overrides/TextareaShortDescription.css"
import stylesTextareaTechnicalDescription from "./overrides/TextareaTechnicalDescription.css"

import lengthIcon from "assets/length.svg"
import heightIcon from "assets/height.svg"
import depthIcon from "assets/depth.svg"

const Input = ({ customStyles, ...props }) => <UIInput {...props} customStyles={customStyles || inputStyles} />
const Textarea = forwardRef((props, ref) => (
    <UITextarea ref={ref} {...props} customStyles={{ ...textareaStyles, ...props.customStyles }} />
))

class GeneralInformations extends Component {
    constructor(props) {
        super(props)
        this.textareasRefs = {
            short_description: createRef(),
            description: createRef(),
            technical_description: createRef()
        }
    }

    state = {
        isPriceHistoryDetailsOpen: false,
        textareasChanged: {
            short_description: false,
            description: false,
            technical_description: false
        }
    }

    togglePriceHistoryDetailsOpen = () =>
        this.setState(prevState => ({ isPriceHistoryDetailsOpen: !prevState.isPriceHistoryDetailsOpen }))

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.values && prevProps.values.brand_id !== this.props.values.brand_id) {
            const action = this.props.values.brand_id
                ? () => fetch.get(`/brands/${this.props.values.brand_id}`)
                : () => Promise.resolve({ data: {} })

            action().then(result => {
                const multiplier = result.data.pricing_mu_usd_retail || null
                this.props.setFieldValue("multiplier", multiplier)
                this.props.setFieldValue("is_multiplier", !!multiplier)
            })
        }
    }

    updateTextAreaChangeStatus = changedFieldName => {
        const isFieldChanged =
            this.textareasRefs[changedFieldName].current.value !== this.props.values[changedFieldName]
        if (this.state.textareasChanged[changedFieldName] !== isFieldChanged) {
            this.setState(prevState => ({
                textareasChanged: { ...prevState.textareasChanged, ...{ [changedFieldName]: isFieldChanged } }
            }))
        }
    }

    checkIfTextAreaHasBeenChanged = () => Object.values(this.state.textareasChanged).some(value => value)

    render() {
        const {
            formFields,
            setFieldValue,
            setFieldError,
            cx,
            brands,
            countriesList,
            isSubmitting,
            values,
            errors,
            handleSubmit,
            dirty,
            history,
            isEdit
        } = this.props

        const handleReset = () => {
            if (!isEdit) {
                return history.push("/products")
            }
            return history.goBack()
        }

        const prepareNumber = value => (typeof value === "string" ? value.replace(",", ".") : value)

        const currentBrand = (brands.find(el => el.id === values.brand_id) || {}).name || ""

        return (
            <div className={cx("sectionInner")}>
                <div className={cx("form-container", { "form-isFilling": false })}>
                    <div className={cx("title")}>Basic informations</div>

                    <Section customStyles={{ ...stylesSection, ...stylesSectionFirst }}>
                        <div className={cx("row")}>
                            <Input
                                label={formFields.name.label}
                                name="name"
                                value={values.name}
                                error={errors.name}
                                onChange={({ event }) => setFieldValue("name", event.target.value)}
                                autoComplete="off"
                                className={cx("name-input")}
                            />
                        </div>
                        <div className={cx("row")}>
                            <Input
                                label={formFields.label.label}
                                name="label"
                                value={values.label}
                                error={errors.label}
                                onChange={({ event }) => setFieldValue("label", event.target.value)}
                                autoComplete="off"
                                className={cx("name-input")}
                            />
                        </div>
                        <div className={cx("row")}>
                            <Textarea
                                ref={this.textareasRefs.short_description}
                                label={formFields.short_description.label}
                                customStyles={stylesTextareaShortDescription}
                                name="short_description"
                                defaultValue={values.short_description}
                                error={errors.short_description}
                                isPlaceholder={false}
                                maxLength={false}
                                onChange={() => this.updateTextAreaChangeStatus("short_description")}
                            />
                        </div>
                        <div className={cx("row")}>
                            <Textarea
                                name="description"
                                ref={this.textareasRefs.description}
                                label={formFields.description.label}
                                defaultValue={values.description}
                                error={errors.description}
                                maxLength={false}
                                onChange={() => this.updateTextAreaChangeStatus("description")}
                            />
                        </div>

                        <div className={cx("row")}>
                            <Textarea
                                name="technical_description"
                                ref={this.textareasRefs.technical_description}
                                label={formFields.technical_description.label}
                                defaultValue={values.technical_description}
                                error={errors.technical_description}
                                maxLength={false}
                                customStyles={stylesTextareaTechnicalDescription}
                                onChange={() => this.updateTextAreaChangeStatus("technical_description")}
                            />
                        </div>
                    </Section>

                    <Section customStyles={stylesSection}>
                        <div className={cx("title")}>
                            <span>Dimensions & Weight:</span>
                            <span className={cx("toggleContainer")}>
                                <span className={cx("toggleLabel")}>Use inches</span>
                                <Toggle
                                    isOn={values.useImperial}
                                    handleChange={() => {
                                        const newVal = !values.useImperial
                                        setFieldValue("useImperial", newVal)
                                        if (newVal) {
                                            setFieldValue("conv_width", formatDimensionToImperial(values.width))
                                            setFieldValue("conv_height", formatDimensionToImperial(values.height))
                                            setFieldValue("conv_depth", formatDimensionToImperial(values.depth))
                                            setFieldValue("conv_weight", formatWeightToImperial(values.weight))
                                        } else {
                                            setFieldValue("conv_width", values.width)
                                            setFieldValue("conv_height", values.height)
                                            setFieldValue("conv_depth", values.depth)
                                            setFieldValue("conv_weight", values.weight)
                                        }
                                    }}
                                    customStyles={stylesToggle}
                                />
                            </span>
                        </div>
                        <div className={cx("dimension-inputs")}>
                            <Input
                                label={formFields.depth.label}
                                name="depth"
                                value={forceEmptyDecimalValue(values.conv_depth)}
                                error={errors.depth}
                                onChange={({ event }) => {
                                    setFieldValue("conv_depth", event.target.value)
                                    const preparedValue = prepareNumber(event.target.value)
                                    setFieldValue(
                                        "depth",
                                        values.useImperial ? formatDimensionToMetric(preparedValue) : preparedValue
                                    )
                                }}
                                innerLabel={values.useImperial ? "inch" : "cm"}
                                className={cx("dimension-input")}
                                icon={depthIcon}
                            />
                            <Input
                                label={formFields.width.label}
                                name="width"
                                value={forceEmptyDecimalValue(values.conv_width)}
                                error={errors.width}
                                onChange={({ event }) => {
                                    setFieldValue("conv_width", event.target.value)
                                    const preparedValue = prepareNumber(event.target.value)
                                    setFieldValue(
                                        "width",
                                        values.useImperial ? formatDimensionToMetric(preparedValue) : preparedValue
                                    )
                                }}
                                innerLabel={values.useImperial ? "inch" : "cm"}
                                className={cx("dimension-input", "length-icon")}
                                icon={lengthIcon}
                            />
                            <Input
                                label={formFields.height.label}
                                name="height"
                                error={errors.height}
                                value={forceEmptyDecimalValue(values.conv_height)}
                                onChange={({ event }) => {
                                    setFieldValue("conv_height", event.target.value)
                                    const preparedValue = prepareNumber(event.target.value)
                                    setFieldValue(
                                        "height",
                                        values.useImperial ? formatDimensionToMetric(preparedValue) : preparedValue
                                    )
                                }}
                                innerLabel={values.useImperial ? "inch" : "cm"}
                                className={cx("dimension-input")}
                                icon={heightIcon}
                            />
                            <Input
                                label={formFields.weight.label}
                                name="weight"
                                error={errors.weight}
                                value={forceEmptyDecimalValue(values.conv_weight)}
                                onChange={({ event }) => {
                                    setFieldValue("conv_weight", event.target.value)
                                    const preparedValue = prepareNumber(event.target.value)
                                    setFieldValue(
                                        "weight",
                                        values.useImperial ? formatWeightToMetric(preparedValue) : preparedValue
                                    )
                                }}
                                innerLabel={values.useImperial ? "lbs" : "kg"}
                            />
                        </div>
                    </Section>

                    <Section customStyles={stylesSection}>
                        <div className={cx("title")}>Product Details</div>
                        <div className={cx("inputs")}>
                            <SelectWithSearch
                                label={formFields.brand_id.label + "*"}
                                value={currentBrand}
                                error={errors.brand_id}
                                values={brands.map(el => ({ value: el.id, label: el.name }))}
                                setValue={({ value }) => setFieldValue("brand_id", value)}
                                customErrorStyles={selectErrorStyles}
                                customStyles={selectStyles}
                                withoutFetch
                            />
                            <div className={cx("manufacturingTime")}>
                                <div>
                                    <span className={cx("label")}>Manufacturing time:</span>
                                </div>
                                <div className={cx("manufacturingTimeInputs")}>
                                    <Input
                                        isPlaceholder={true}
                                        name="manufacturing_time_min"
                                        value={values.manufacturing_time_min}
                                        error={errors.manufacturing_time_min}
                                        customStyles={manufacturingInputStyles}
                                        onChange={({ event }) =>
                                            setFieldValue("manufacturing_time_min", event.target.value)
                                        }
                                    />
                                    <span className={cx("manufacturingTimeSeparator")}>-</span>
                                    <Input
                                        isPlaceholder={true}
                                        name="manufacturing_time_max"
                                        value={values.manufacturing_time_max}
                                        error={errors.manufacturing_time_max}
                                        customStyles={manufacturingInputStyles}
                                        onChange={({ event }) =>
                                            setFieldValue("manufacturing_time_max", event.target.value)
                                        }
                                    />
                                    <span className={cx("manufacturingTimePostfix")}>weeks</span>
                                </div>
                            </div>
                            <div className={cx("productionYearInput")}>
                                <span className={cx("label")}>Production year:</span>
                                <div>
                                    <Input
                                        name="production_year"
                                        value={values.production_year}
                                        error={errors.production_year}
                                        onChange={({ event }) => setFieldValue("production_year", event.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={cx("inputs")}>
                            <SelectWithSearch
                                label={formFields.country_of_origin.label}
                                value={countriesList.find(el => el === values.country_of_origin) || ""}
                                error={errors.country_of_origin}
                                values={countriesList.map(el => ({ value: el, label: el }))}
                                setValue={({ value }) => setFieldValue("country_of_origin", value)}
                                customErrorStyles={selectErrorStyles}
                                customStyles={selectStyles}
                                withoutFetch
                            />
                            <div className={cx("manufacturingTime")}>
                                <div>
                                    <span className={cx("label")}>Delivery time:</span>
                                </div>
                                <div className={cx("manufacturingTimeInputs")}>
                                    <Input
                                        isPlaceholder={true}
                                        name="delivery_time_min"
                                        value={values.delivery_time_min}
                                        error={errors.delivery_time_min}
                                        customStyles={manufacturingInputStyles}
                                        onChange={({ event }) => setFieldValue("delivery_time_min", event.target.value)}
                                    />
                                    <span className={cx("manufacturingTimeSeparator")}>-</span>
                                    <Input
                                        isPlaceholder={true}
                                        name="delivery_time_max"
                                        value={values.delivery_time_max}
                                        error={errors.delivery_time_max}
                                        customStyles={manufacturingInputStyles}
                                        onChange={({ event }) => setFieldValue("delivery_time_max", event.target.value)}
                                    />
                                    <span className={cx("manufacturingTimePostfix")}>weeks</span>
                                </div>
                            </div>
                        </div>
                        <div className={cx("inputs")}>
                            <SelectWithSearch
                                label={formFields.reorderable.label}
                                value={values.reorderable ? "Yes" : "No"}
                                error={errors.reorderable}
                                values={[{ value: true, label: "Yes" }, { value: false, label: "No" }]}
                                setValue={({ value }) => setFieldValue("reorderable", value)}
                                customErrorStyles={selectErrorStyles}
                                customStyles={selectStyles}
                                withoutFetch
                                isClearBlocked
                            />
                            <Input
                                label={formFields.manufacturer_id.label}
                                name="manufacturer_id"
                                value={values.manufacturer_id}
                                error={errors.manufacturer_id}
                                onChange={({ event }) => setFieldValue("manufacturer_id", event.target.value)}
                            />
                        </div>
                        <SpecificationsUploader
                            value={values.specification}
                            error={errors.specification}
                            onChange={value => setFieldValue("specification", value)}
                            setError={error => setFieldError("specification", error)}
                        />
                    </Section>

                    <Section customStyles={stylesSection}>
                        <div className={cx("title")}>
                            <span>Pricing</span>
                            <span className={cx("toggleContainer")}>
                                <span className={cx("toggleLabel")}>Price as multiplier</span>
                                <Toggle
                                    isOn={values.is_multiplier}
                                    handleChange={() => setFieldValue("is_multiplier", !values.is_multiplier)}
                                    customStyles={stylesToggle}
                                />
                            </span>
                        </div>
                        <div className={cx("inputs")}>
                            <Input
                                label={formFields.wholesale_cost.label + (values.is_multiplier ? "*" : "")}
                                name="wholesale_cost"
                                value={forceEmptyDecimalValue(values.wholesale_cost)}
                                error={errors.wholesale_cost}
                                onChange={({ event }) => setFieldValue("wholesale_cost", event.target.value)}
                                isInnerLabelLeft={true}
                                innerLabel={"$"}
                            />
                            <Input
                                label={formFields.multiplier.label + (values.is_multiplier ? "*" : "")}
                                name="multiplier"
                                value={values.is_multiplier ? values.multiplier : ""}
                                error={values.is_multiplier ? errors.multiplier : ""}
                                readOnly={!values.is_multiplier}
                                onChange={({ event }) => setFieldValue("multiplier", event.target.value)}
                            />
                            <Input
                                label={formFields.price.label + (!values.is_multiplier ? "*" : "")}
                                name="price"
                                value={
                                    !values.is_multiplier
                                        ? values.price
                                        : !!values.wholesale_cost && !!values.multiplier
                                        ? formatPrice(values.wholesale_cost * values.multiplier, true, true)
                                        : ""
                                }
                                error={!values.is_multiplier ? errors.price : ""}
                                innerLabel={"$"}
                                isInnerLabelLeft={true}
                                readOnly={values.is_multiplier}
                                onChange={({ event }) => setFieldValue("price", event.target.value)}
                            />
                            <Input
                                label={formFields.discount.label}
                                name="discount"
                                value={forceEmptyDecimalValue(values.discount)}
                                error={errors.discount}
                                onChange={({ event }) => setFieldValue("discount", event.target.value)}
                                innerLabel="%"
                            />
                        </div>
                        {isEdit && (
                            <div
                                className={classnames(styles.detailsButton, {
                                    [styles.expanded]: this.state.isPriceHistoryDetailsOpen
                                })}
                                onClick={this.togglePriceHistoryDetailsOpen}
                            >
                                {this.state.isPriceHistoryDetailsOpen ? "Hide" : "Details"}
                                <ExpandIcon className={styles.detailsButtonIcon} />
                            </div>
                        )}
                        {this.state.isPriceHistoryDetailsOpen && (
                            <PricingList productId={Number(this.props.match.params.productId)} />
                        )}
                    </Section>
                    {this.renderFeatures()}
                </div>
                <SaveBar
                    message={dirty || this.checkIfTextAreaHasBeenChanged() ? "Unsaved changes" : ""}
                    isShown
                    onCancel={handleReset}
                    onSubmit={async () => {
                        await Promise.all(
                            Object.keys(this.textareasRefs).map(fieldName =>
                                setFieldValue(fieldName, this.textareasRefs[fieldName].current.value)
                            )
                        )
                        handleSubmit()
                    }}
                    isSaving={isSubmitting}
                    submitLabel="Save"
                />
            </div>
        )
    }

    renderFeatures() {
        const {
            cx,
            features,
            handleFieldChange,
            isShowedAddFeature,
            handleAddFeatureToggle,
            handleSubmitForm,
            setFieldValue,
            values
        } = this.props

        return (
            <Section withoutBorder={true}>
                <div className={cx("title")}>Features</div>
                <Features
                    currentFeatures={features}
                    handleFieldChange={handleFieldChange}
                    isShowedAddFeature={isShowedAddFeature}
                    handleAddFeatureToggle={handleAddFeatureToggle}
                    handleSubmitForm={event => handleSubmitForm(event, false)}
                    setFieldValue={setFieldValue}
                    values={values}
                />
            </Section>
        )
    }
}

export default withStyles(GeneralInformations, styles)
