import React, { useState } from "react"
import { useSelector } from "react-redux"
import { useFormik, FormikProvider } from "formik"

import Filters from "./Filters"

const INIT_VALUES = {
    users: [],
    client: "",
    project: "",
    sources: [],
    divisions: []
}

const FiltersContainer = props => {
    const { handleChange, initFilters } = props
    const [isVisible, setIsVisible] = useState(false)
    const userId = useSelector(state => state.loginStore.userId)
    const userName = useSelector(state => state.loginStore.userName)

    const formik = useFormik({
        initialValues: { ...INIT_VALUES, ...initFilters },
        onSubmit: handleChange,
        enableReinitialize: true
    })

    const handleAction = type => {
        if (type === "submit") {
            formik.submitForm()
        }

        if (type === "unsave" && isVisible) {
            formik.setValues({ ...INIT_VALUES, ...initFilters })
        }

        toggleVisible()
    }

    const handleOnlyMine = async (state, isFromToggle) => {
        await formik.setFieldValue("isOnlyMine", state)

        if (isFromToggle) {
            await formik.setFieldValue(
                "users",
                state ? [{ id: parseInt(userId, 10), label: userName, value: {}, secondLabel: "" }] : []
            )
        }
    }

    const toggleVisible = () => setIsVisible(!isVisible)

    return (
        <FormikProvider value={formik}>
            <Filters
                values={formik.values}
                isVisible={isVisible}
                toggleVisible={toggleVisible}
                handleAction={handleAction}
                handleOnlyMine={handleOnlyMine}
            />
        </FormikProvider>
    )
}

export default FiltersContainer
