import React from "react"

import ListContainer from "modules/OrdersModule/components/List/ListContainer"
import listConfig from "modules/OrdersModule/components/List/listConfig"

const OrderTab = props => {
    const { projectId, type, overrider = tab => tab, clientId } = props
    const config = listConfig[type]
    const { table } = config

    const customTable = Object.entries(table).reduce(
        (acc, [key, value]) => (key !== "client" ? { ...acc, [key]: value } : acc),
        {}
    )
    const customConfig = { ...config, table: overrider(customTable) }

    return (
        <ListContainer
            additionalParams={{ projectId, clientId }}
            tabViewConfig={{ isExportPrint: true, noSummary: true }}
            customConfig={customConfig}
            isTabView={true}
            type={type}
            {...props}
        />
    )
}

export default OrderTab
