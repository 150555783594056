import React, { Component } from "react"
import styles from "./ActivityLog.css"
import Log from "./Log/Log"
import withStyles from "HOC/withStyles"

import Pagination from "components/Reusable/Pagination/index"

class ActivityLog extends Component {
    perPage = 10

    state = {
        currentPage: 0,
        isShown: false
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { activities } = nextProps

        if (JSON.stringify(activities) !== JSON.stringify(this.props.activities)) {
            this.setState({
                currentPage: 0
            })
        }
    }

    render() {
        const { cx, activities, isLoading } = this.props
        const { currentPage, isShown } = this.state

        if (!isLoading && !activities) {
            return null
        }

        const isEmpty = activities && activities.length === 0

        if (isEmpty || !activities) {
            return null
        }

        return (
            <div className={cx(isLoading)}>
                <div className={cx("log-cover", { isShown })}>
                    <h3 className={cx("log-title")} onClick={this.handleToggleShow}>
                        Activity Log <span />
                    </h3>
                    <div className={cx("columns-titles")}>
                        <h4 className={cx("date")}>DATE</h4>
                        <h4 className={cx("time")}>TIME</h4>
                        <h4 className={cx("event")}>EVENT</h4>
                        <h4 className={cx("employee")}>EMPLOYEE</h4>
                    </div>
                    <ul>
                        {!isEmpty
                            ? activities
                                  .slice(currentPage * this.perPage, (currentPage + 1) * this.perPage)
                                  .map((data, index) => <Log key={index} {...data} />)
                            : null}
                    </ul>
                    <Pagination
                        handleSelectPage={this.handleSelectPage}
                        items={activities}
                        currentPage={currentPage}
                        perPage={this.perPage}
                    />
                </div>
            </div>
        )
    }

    handleSelectPage = page => {
        this.setState({
            currentPage: page
        })
    }

    handleToggleShow = () => {
        this.setState({
            isShown: !this.state.isShown
        })
    }
}

export default withStyles(ActivityLog, styles)
