import React, { Fragment } from "react"
import PropTypes from "prop-types"

import {
    Panel,
    H3,
    Table,
    TableHead,
    TableSortableHeadCell,
    TableHeadCell,
    TableBody,
    TableCell,
    TableRow,
    Status,
    AlertOkIcon
} from "@butterfly-frontend/ui"
import { Image, Pagination } from "ui"
import { SnakeLoader } from "ui/Skeleton"
import STOCK_ITEM_STATUSES from "modules/WmsModule/constants/stockItemStatuses"
import { ShipmentAddress } from "modules/WmsModule/components"
import { ADDRESS_PROP_TYPE } from "modules/WmsModule/propTypes"

import styles from "./ShipmentDetailsItemsList.module.css"

const TABLE_COLUMNS = {
    no: { width: 30, minWidth: 30 },
    productName: { width: "30%", minWidth: 200 },
    type: { width: "12%", minWidth: 100 },
    origin: { width: "22%", minWidth: 150 },
    po: { width: "12%", minWidth: 100 },
    condition: { width: "12%", minWidth: 100 }
}

const ShipmentDetailsItemsList = ({
    shippedItems,
    sortState,
    onChangeSort,
    pagination,
    isShipmentDropship,
    isLoading
}) => (
    <div className={styles.container}>
        <Panel classes={{ panel: styles.panel }}>
            <H3 classes={{ h3: styles.h3 }}>Items</H3>
            <Table>
                <TableHead>
                    <TableHeadCell {...TABLE_COLUMNS.no}>#</TableHeadCell>
                    <TableSortableHeadCell
                        columnKey="product"
                        sortState={sortState}
                        onClick={onChangeSort}
                        {...TABLE_COLUMNS.productName}
                    >
                        PRODUCT NAME
                    </TableSortableHeadCell>
                    <TableSortableHeadCell
                        columnKey="type"
                        sortState={sortState}
                        onClick={onChangeSort}
                        {...TABLE_COLUMNS.type}
                    >
                        TYPE
                    </TableSortableHeadCell>
                    {isShipmentDropship ? (
                        <TableHeadCell {...TABLE_COLUMNS.origin}>ADDRESS</TableHeadCell>
                    ) : (
                        <TableSortableHeadCell
                            columnKey="warehouse"
                            sortState={sortState}
                            onClick={onChangeSort}
                            {...TABLE_COLUMNS.origin}
                        >
                            WAREHOUSE
                        </TableSortableHeadCell>
                    )}
                    <TableHeadCell {...TABLE_COLUMNS.po}>PO</TableHeadCell>
                    <TableHeadCell {...TABLE_COLUMNS.condition}>CONDITION</TableHeadCell>
                </TableHead>
                {isLoading ? (
                    <div className={styles.loader}>
                        <SnakeLoader customStyles={styles.loader} />
                    </div>
                ) : (
                    <TableBody>
                        {shippedItems.map((item, index) => (
                            <TableRow classNames={{ root: styles.tableRow }} key={item.id}>
                                <TableCell {...TABLE_COLUMNS.no}>{index + 1}</TableCell>
                                <TableCell {...TABLE_COLUMNS.productName} classes={{ root: styles.productNameCell }}>
                                    {item.product.thumb && (
                                        <Image className={styles.productThumb} src={item.product.thumb} />
                                    )}
                                    {item.product.name}
                                </TableCell>
                                <TableCell {...TABLE_COLUMNS.type}>
                                    <Status theme="inactiveFill" withCircle={false}>
                                        {STOCK_ITEM_STATUSES[item.status].label}
                                    </Status>
                                </TableCell>
                                <TableCell {...TABLE_COLUMNS.origin}>
                                    {isShipmentDropship ? (
                                        <ShipmentAddress shipmentAddress={item.brand} />
                                    ) : (
                                        item.warehouse.name
                                    )}
                                </TableCell>
                                <TableCell {...TABLE_COLUMNS.po}>
                                    {item.purchase_order ? (
                                        <Fragment>
                                            <AlertOkIcon className={styles.okIcon} />
                                            {item.purchase_order.uuid}
                                        </Fragment>
                                    ) : (
                                        "-"
                                    )}
                                </TableCell>
                                <TableCell {...TABLE_COLUMNS.condition}>
                                    <Status color={item.condition.color}>{item.condition.name}</Status>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                )}
                {pagination.canDisplay && (
                    <div className={styles.paginationWrapper}>
                        <Pagination {...pagination} />
                    </div>
                )}
            </Table>
        </Panel>
    </div>
)

ShipmentDetailsItemsList.propTypes = {
    shippedItems: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            product: PropTypes.shape({
                name: PropTypes.string.isRequired,
                thumb: PropTypes.string
            }).isRequired,
            brand: ADDRESS_PROP_TYPE.isRequired,
            status: PropTypes.string.isRequired,
            warehouse: PropTypes.shape({
                name: PropTypes.string.isRequired
            }),
            purchase_order: PropTypes.shape({
                uuid: PropTypes.string.isRequired
            }),
            condition: PropTypes.shape({
                color: PropTypes.string.isRequired,
                name: PropTypes.string.isRequired
            }).isRequired
        })
    ).isRequired,
    sortState: PropTypes.shape({
        by: PropTypes.string.isRequired,
        direction: PropTypes.string.isRequired
    }).isRequired,
    onChangeSort: PropTypes.func.isRequired,
    pagination: PropTypes.shape({
        currentPage: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        canDisplay: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired
    }).isRequired,
    isShipmentDropship: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired
}

export default ShipmentDetailsItemsList
