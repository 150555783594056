import React, { useState } from "react"
import PropTypes from "prop-types"

import { Panel, DetailsCard, Status, StatusSelect } from "@butterfly-frontend/ui"
import { ShipmentAddress } from "modules/WmsModule/components"
import shipmentStatuses from "modules/WmsModule/constants/shipmentStatuses"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"
import STATUS_SELECT_OPTIONS from "modules/WmsModule/constants/shipmentStatusSelectOptions"
import { useShipmentStatusEdit } from "modules/WmsModule/hooks"
import { CancelShipmentModal } from "modules/WmsModule/modals"
import { ADDRESS_PROP_TYPE } from "modules/WmsModule/propTypes"

import styles from "./ShipmentDetailsHeader.module.css"

const ShipmentDetailsHeader = ({ shipment, onStatusChanged, isEditingStatusEnabled = false }) => {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
    const [newStatus, setNewStatus] = useState(null)

    const { handleUpdateShipmentStatus, isStatusLoading } = useShipmentStatusEdit({
        id: shipment.id,
        newStatus,
        setIsConfirmationModalOpen,
        onStatusChanged: (...args) => {
            setIsConfirmationModalOpen(false)
            if (onStatusChanged) {
                onStatusChanged(...args)
            }
        }
    })

    const onConfirmationDenied = () => {
        setIsConfirmationModalOpen(false)
        setNewStatus(null)
    }

    const status = shipment.status.status
    const isStatusCancelled = status === SHIPMENT_STATUS_KEYS.CANCELLED
    const isStatusOnHold = status === SHIPMENT_STATUS_KEYS.ON_HOLD
    const detailsCardClasses = {
        root: [styles.detailsCard, { [styles.inactiveCard]: isStatusCancelled || isStatusOnHold }]
    }
    const detailsCardClassesActiveOnHold = { root: [styles.detailsCard, { [styles.inactiveCard]: isStatusCancelled }] }
    const isStatusSelectionEnabled =
        isEditingStatusEnabled && status !== SHIPMENT_STATUS_KEYS.DELIVERED && status !== SHIPMENT_STATUS_KEYS.CANCELLED

    return (
        <Panel>
            <div className={styles.container}>
                <DetailsCard header="SO" classes={detailsCardClassesActiveOnHold}>
                    {shipment.order ? shipment.order.uuid : "---"}
                </DetailsCard>
                <DetailsCard header="SHIP FROM" classes={detailsCardClasses}>
                    {shipment.source_address ? (
                        <ShipmentAddress shipmentAddress={shipment.source_address} />
                    ) : (
                        shipment.source_warehouses.map(warehouse => warehouse.name).join(", ")
                    )}
                </DetailsCard>
                <DetailsCard header="SHIP TO" classes={detailsCardClasses}>
                    {shipment.destination_warehouse && shipment.destination_warehouse.name}
                    {shipment.destination_address && <ShipmentAddress shipmentAddress={shipment.destination_address} />}
                </DetailsCard>
                <DetailsCard header="SHIPPING STATUS" classes={{ root: [styles.detailsCard, styles.shipmentStatus] }}>
                    {isStatusSelectionEnabled ? (
                        <StatusSelect
                            value={SHIPMENT_STATUSES[status]}
                            options={STATUS_SELECT_OPTIONS}
                            onChange={({ value }) => setNewStatus(value)}
                            isLoading={isStatusLoading}
                        />
                    ) : (
                        <Status theme={shipmentStatuses[status].theme} color={shipmentStatuses[status].color}>
                            {shipmentStatuses[status].label}
                        </Status>
                    )}
                </DetailsCard>
                <DetailsCard header="CARRIER" classes={detailsCardClasses}>
                    {shipment.carrier || "---"}
                </DetailsCard>
                <DetailsCard header="TRACKING NUMBER" classes={detailsCardClasses}>
                    {shipment.tracking_number || "---"}
                </DetailsCard>
            </div>
            {isConfirmationModalOpen && (
                <CancelShipmentModal
                    onCancel={onConfirmationDenied}
                    onAction={handleUpdateShipmentStatus}
                    isLoading={isStatusLoading}
                />
            )}
        </Panel>
    )
}

ShipmentDetailsHeader.propTypes = {
    shipment: PropTypes.shape({
        source_warehouses: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired
        ),
        source_address: ADDRESS_PROP_TYPE,
        destination_warehouse: PropTypes.shape({
            name: PropTypes.string.isRequired
        }),
        destination_address: ADDRESS_PROP_TYPE,
        status: PropTypes.shape({
            status: PropTypes.string.isRequired
        }).isRequired,
        type: PropTypes.oneOf(["shipment", "transfer"]).isRequired,
        purchase_order: PropTypes.shape({
            uuid: PropTypes.string.isRequired
        })
    }).isRequired,
    onStatusChanged: PropTypes.func,
    isEditingStatusEnabled: PropTypes.bool
}

export default ShipmentDetailsHeader
