import React from "react"
import PropTypes from "prop-types"

import SubmitModal from "ui/SubmitModal"

const DeleteRoomConfirmModal = props => {
    const { isOpen, isLoading, onCancel, onSubmit } = props

    return (
        <SubmitModal
            redButton
            isOpen={isOpen}
            isLoading={isLoading}
            header={`Are you sure you want to delete this room?`}
            body="This action cannot be reversed."
            submitText="Delete"
            onClose={onCancel}
            onSubmit={onSubmit}
        />
    )
}

DeleteRoomConfirmModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default DeleteRoomConfirmModal
