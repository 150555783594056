import React from "react"

import SelectedActions from "../SelectedActions"

import withStyles from "HOC/withStyles"
import styles from "./BottomBar.css"

const BottomBar = props => {
    const {
        cx,
        singleName,
        productsCount,
        checkedCount,
        handleAction,
        canCreate,
        canDelete,
        isCreating,
        selectedActions
    } = props

    const itemsCount = checkedCount || productsCount

    return (
        <div className={cx("root")}>
            <div className={cx("checkedCount")}>
                {!!checkedCount && "Selected "}
                {itemsCount} {!checkedCount && singleName} item{itemsCount !== 1 && "s"}
            </div>
            <SelectedActions
                checkedCount={checkedCount}
                handleAction={handleAction}
                canCreate={canCreate}
                canDelete={canDelete}
                isCreating={isCreating}
                selectedActions={selectedActions}
            />
        </div>
    )
}

export default withStyles(BottomBar, styles)
