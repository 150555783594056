import React, { useState, useLayoutEffect } from "react"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"
import fetch from "helpers/fetch"

import { Button, Input, Modal } from "ui"

import styles from "./GroupModal.css"
import inputStyles from "./overrides/Input.css"

const GroupModal = ({ onClose, parentId, onSuccess, initialValues }) => {
    const cx = useStyles(styles)
    const isEdit = !!initialValues.id
    const [name, setName] = useState(initialValues.name || "")
    const [nameError, setNameError] = useState("")
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [groupNameInput, setGroupNameInput] = useState(null)

    useLayoutEffect(() => {
        groupNameInput && groupNameInput.focus()
    }, [groupNameInput])

    const handleCreate = async () => {
        setIsSubmitting(true)
        try {
            const response = isEdit
                ? await fetch.patch(`/attribute-groups/${initialValues.id}`, {
                      name
                  })
                : await fetch.post("/attribute-groups", {
                      name,
                      parent_attribute_group_id: initialValues.parent_attribute_group_id
                  })

            onSuccess(response)
            onClose()
        } catch (error) {
            setIsSubmitting(false)
            setNameError(error.errors.name)
        }
    }

    return (
        <Modal isOpen closeModal={onClose} preventClickOutside>
            <p className={cx("header")}>
                {initialValues.id ? "Edit a" : "Add new"}
                {!!initialValues.parent_attribute_group_id ? " Subgroup" : " Group"}
            </p>

            <Input
                label={!!parentId ? "Subgroup name" : "Group name"}
                value={name}
                onChange={({ event }) => setName(event.target.value)}
                customStyles={inputStyles}
                error={nameError}
                attributes={{
                    required: true
                }}
                handleInputRefOnInit={setGroupNameInput}
            />

            <div className={cx("actions")}>
                <Button onClick={onClose} label="Cancel" className={cx("link-button")} />
                <Button
                    label="Save"
                    className={cx("first-button")}
                    isDisabled={!name}
                    onClick={handleCreate}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

GroupModal.defaultProps = {
    onClose: () => {},
    onSuccess: () => {},
    initialValues: {}
}

GroupModal.propTypes = {
    onClose: PropTypes.func,
    onSuccess: PropTypes.func,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        parent_attribute_group_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
}

export default GroupModal
