import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./Form.css"
import moduleSectionStyles from "./overrides/ModuleSection.css"

import Section from "components/Reusable/Form/Section/index"
import Layout from "components/Reusable/Layout/index"
import Input from "components/Reusable/Form/Input/index"
import Button from "components/Reusable/Form/Button/index"

class Form extends Component {
    constructor(props) {
        super(props)
        this.checkboxRef = React.createRef()
        this.listRef = React.createRef()
    }

    state = {
        isScrolled: false
    }

    componentDidUpdate() {
        if (!this.props.isLoading && !this.state.isScrolled && this.checkboxRef.current) {
            const listNode = this.listRef.current
            const checkboxNode = this.checkboxRef.current
            const height = Math.abs(listNode.getBoundingClientRect().top - checkboxNode.getBoundingClientRect().top)
            this.setState({ isScrolled: true })
            if (listNode && checkboxNode) {
                listNode.scrollTop = height
            }
        }
    }

    render() {
        const { isEdit, cx, handleSubmitForm, isSaving, isFilled, history, formFields, data } = this.props
        const { name, parent_category_id } = formFields

        return (
            <Layout
                title={isEdit ? "Edit category" : parent_category_id.value ? "New Subcategory" : "New Category"}
                customStyles={styles}
            >
                <div className={cx("form-container", { "form-isFilling": isEdit && !isFilled })}>
                    <Section>
                        <Input {...name} handleInputRefOnInit={node => node.focus()} />
                        {!isEdit && !!parent_category_id.value && (
                            <Input {...parent_category_id} textForHiddenValue={data.parent_category_name} />
                        )}
                        {isEdit && this.renderCategoryTree()}
                    </Section>
                    <Section customStyles={moduleSectionStyles}>
                        <Button
                            className={["first-button", "submit-button"]}
                            customStyles={styles}
                            onClick={event => handleSubmitForm(event)}
                            label={isEdit ? "Save" : "Create"}
                            isLoading={isSaving}
                        />
                        <span className={cx("form-cancel")} onClick={() => history.goBack()}>
                            Cancel
                        </span>
                    </Section>
                </div>
            </Layout>
        )
    }

    renderCategoryTree() {
        const {
            cx,
            categoriesList: { isLoading, data: categories }
        } = this.props

        if (isLoading) {
            return <div className={cx("root", "isLoading")}>Loading...</div>
        }

        const mainCategories = categories.filter(category => !category.parent_category)

        return (
            <div className={cx("root")}>
                <h2>Parent category:</h2>
                <ul className={cx("tree")} ref={this.listRef}>
                    {mainCategories &&
                        mainCategories.map((category, index) => {
                            return this.renderSubcategory({ category, level: 0, index })
                        })}
                </ul>
            </div>
        )
    }

    renderSubcategory({ category, level, index }) {
        const {
            categoriesList: { data: categories },
            formFields,
            handleFieldChange,
            data: { id: currentCategoryId }
        } = this.props
        const { parent_category_id } = formFields

        if (category.id === currentCategoryId) {
            return null
        }

        const categoryMap = categories.reduce((output, category) => {
            output[category.id] = category
            return output
        }, {})

        const isSelected = parent_category_id.value && parent_category_id.value === category.id

        return (
            <li key={index} ref={isSelected && this.checkboxRef}>
                <label>
                    <input
                        name={parent_category_id.name}
                        type="radio"
                        checked={isSelected ? "checked" : ""}
                        value={category.id}
                        onChange={() =>
                            handleFieldChange({
                                event: { target: { value: category.id } },
                                fieldPath: parent_category_id.fieldPath
                            })
                        }
                    />
                    {level > 0 && <span>{"-".repeat(level)}</span>} {category.name}
                </label>
                {category.subcategories && (
                    <ul>
                        {category.subcategories.map((subcategory, subindex) => {
                            if (!categoryMap[subcategory.id]) {
                                return null
                            }
                            return this.renderSubcategory({
                                category: categoryMap[subcategory.id],
                                level: level + 1,
                                index: subindex
                            })
                        })}
                    </ul>
                )}
            </li>
        )
    }
}

export default withStyles(Form, styles)
