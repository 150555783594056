import React from "react"
import idx from "idx"
import withStyles from "HOC/withStyles"
import styles from "./ItemsTable.css"

import { withListContext } from "modules/OrdersModule/components/List/ListContainer"

const ItemsTable = props => {
    const {
        cx,
        items,
        totalRent,
        listConfig: { itemsTable },
        order
    } = props

    const isRental = order && order.type === "rental"
    const rentalPrice = {
        price: totalRent,
        quantity: 1,
        discount: idx(order, _ => _.calculations.discount)
    }
    const rentalItemData = {
        ...rentalPrice,
        fake_name: "Rental",
        total: rentalPrice.price * rentalPrice.quantity
    }

    return (
        <table className={cx("root")}>
            <thead>
                <tr>
                    {Object.entries(itemsTable(order)).map(
                        ([columnName, columnConfig]) =>
                            !!columnConfig && (
                                <th key={columnName} style={columnConfig.style}>
                                    {columnName}
                                </th>
                            )
                    )}
                </tr>
            </thead>
            <tbody>{isRental ? renderItem(rentalItemData, 0) : Array.isArray(items) && items.map(renderItem)}</tbody>
        </table>
    )

    function renderItem(item, index) {
        return (
            <tr key={index}>
                {Object.entries(itemsTable(order)).map(([columnName, columnConfig]) => {
                    if (!columnConfig) {
                        return null
                    }

                    const { renderCell, style = {} } = columnConfig
                    return (
                        <td key={columnName} style={style}>
                            {typeof renderCell === "function" ? renderCell({ cx, index, ...item }) : "-"}
                        </td>
                    )
                })}
            </tr>
        )
    }
}

export default withListContext(withStyles(ItemsTable, styles))
