import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import useStyles from "helpers/getCxFromStyles"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useFetch } from "hooks"
import fetch from "helpers/fetch"

import { Button, Checkbox, Modal, Toggle } from "ui"

import styles from "./ChangeModal.css"
import modalStyles from "./overrides/Modal.css"
import toggleStyles from "./overrides/Toggle.css"
import checkboxStyles from "./overrides/Checkbox.css"

const ChangeModal = ({ brand, onClose, onPriceShowingChange }) => {
    const cx = useStyles(styles)

    const [isPriceDisplayed, setIsPriceDisplayed] = useState()
    const [selectedItems, setSelectedItems] = useState([])
    const [brandOptions, setBrandOptions] = useState([])

    const items = [
        {
            name: "all",
            label: "All Contacts"
        },
        {
            name: "b2b",
            label: "B2B Partners"
        }
    ]

    const [fetchBrandPriceOptions] = useFetch({
        action: () => fetch.get("/brand-options"),
        onSuccess: ({ data }) => setBrandOptions(data),
        onError: () => showErrorNotification()
    })

    const [patchBrandPriceShowingOption, { isLoading: isPatching }] = useFetch({
        action: async optionId =>
            fetch.patch(`/brands/${brand.id}`, {
                options: [{ id: optionId }]
            }),
        onSuccess: async () => {
            await onClose()
            await onPriceShowingChange()
            showSuccessNotification("Brand price show selection successfully changed")
        },
        onError: () => showErrorNotification()
    })

    useEffect(() => {
        fetchBrandPriceOptions()
        if (brand.options.length) {
            setIsPriceDisplayed(brand.options[0].value !== "none")
            setSelectedItems([brand.options[0].value])
        } else {
            setIsPriceDisplayed(true)
            setSelectedItems(["b2b"])
        }
    }, [])

    useEffect(() => {
        if (isPriceDisplayed === false) {
            setSelectedItems(["none"])
        }
    }, [isPriceDisplayed])

    const handleSubmit = () => {
        const option = brandOptions.find(opt => opt.value === selectedItems[0])
        option && patchBrandPriceShowingOption(option.id)
    }

    return (
        <div className={cx("root")}>
            <Modal closeModal={onClose} isOpen preventClickOutside customStyles={modalStyles}>
                <div className={cx("title")}>
                    <span>Manage</span> {brand.name}
                </div>

                <div className={cx("priceDisplay")}>
                    <span className={cx("label")}>Price Display</span>

                    <Toggle
                        handleChange={isOn => {
                            isOn && setSelectedItems([])
                            setIsPriceDisplayed(isOn)
                        }}
                        isOn={!!isPriceDisplayed}
                        label={{ on: "Yes", off: "No" }}
                        labelPosition="left"
                        customStyles={toggleStyles}
                    />
                </div>

                <div className={cx("accessLevel", { disabled: !isPriceDisplayed })}>
                    <span className={cx("label")}>Access level:</span>

                    <div className={cx("options")}>
                        {items.map(item => (
                            <div key={item.name} className={cx("option")}>
                                <Checkbox
                                    name={item.label}
                                    customStyles={checkboxStyles}
                                    checked={selectedItems.includes(item.name)}
                                    onChange={() =>
                                        setSelectedItems(
                                            selectedItems.includes(item.name)
                                                ? selectedItems.filter(selectedItem => selectedItem !== item.name)
                                                : [item.name]
                                        )
                                    }
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={cx("actions")}>
                    <button type="button" className={cx("cancelButton")} onClick={onClose}>
                        Cancel
                    </button>
                    <Button
                        type="button"
                        className={cx("first-button", "submitButton")}
                        label="Save"
                        onClick={handleSubmit}
                        isLoading={isPatching}
                        isDisabled={isPriceDisplayed && selectedItems.length === 0}
                    />
                </div>
            </Modal>
        </div>
    )
}

ChangeModal.propTypes = {
    brand: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onPriceShowingChange: PropTypes.func.isRequired
}

export default ChangeModal
