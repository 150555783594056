import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import { stringify } from "qs"

import getStyles from "helpers/getCxFromStyles"
import { ListItem } from "ui/Attachments"

import styles from "./styles.css"
import ListItemStyles from "./overrides/ListItemStyles.css"
import StockItemsList from "./StockItemsList"

const GeneralInfo = props => {
    const cx = getStyles(styles)
    const { product, selectedCombination } = props
    const {
        id,
        brand,
        country_of_origin,
        delivery_time_min,
        delivery_time_max,
        manufacturing_time_min,
        manufacturing_time_max,
        production_year,
        features,
        specification,
        items_count_per_status
    } = product

    const renderCell = (label, value) => (
        <li>
            <span className={cx("label")}>{label}</span>
            <span className={cx("value")}>{value || "-"}</span>
        </li>
    )

    const parseRangeWeeks = (minWeeks, maxWeeks) => {
        if (minWeeks && maxWeeks) {
            return `${minWeeks}-${maxWeeks} weeks`
        }

        if (minWeeks && !maxWeeks) {
            return `min ${minWeeks} ${minWeeks > 1 ? "weeks" : "week"}`
        }

        if (!minWeeks && maxWeeks) {
            return `max ${maxWeeks} ${maxWeeks > 1 ? "weeks" : "week"}`
        }

        return ""
    }

    return (
        <div className={cx("root")}>
            <span className={cx("title")}>Product Details</span>
            <ul className={cx("details")}>
                {renderCell(
                    "Brand:",
                    brand && (
                        <Link
                            to={`/products?${stringify({
                                filters: {
                                    brands: [brand.id]
                                }
                            })}`}
                        >
                            {brand.name}
                        </Link>
                    )
                )}
                {renderCell("Country of origin:", country_of_origin)}
                {renderCell("Manufacturing time:", parseRangeWeeks(manufacturing_time_min, manufacturing_time_max))}
                {renderCell("Delivery time:", parseRangeWeeks(delivery_time_min, delivery_time_max))}
                {renderCell("Production year:", production_year)}
            </ul>

            <div className={cx("specification")}>
                <label>Download data sheet</label>
                {specification ? (
                    <ListItem {...specification} created_at={null} customStyles={ListItemStyles} />
                ) : (
                    <div className={cx("noSpecificationLabel")}>No data sheet added</div>
                )}
            </div>

            {!!features && !!features.length && (
                <Fragment>
                    <span className={cx("title")}>Features</span>
                    <ul className={cx("features")}>
                        {features.map(({ name, value }, index) => (
                            <li key={index}>
                                <span className={cx("name")}>{name}</span>
                                <span className={cx("value")}>{value}</span>
                            </li>
                        ))}
                    </ul>
                </Fragment>
            )}

            <Fragment>
                <span className={cx("title")}>Inventory</span>
                <div className={cx("inventory")}>
                    <ul className={cx("details")}>
                        {renderCell(
                            "Quantity:",
                            selectedCombination ? selectedCombination.all_items_count : items_count_per_status.available
                        )}
                        {renderCell(
                            "In stock:",
                            selectedCombination ? selectedCombination.items_count : items_count_per_status.in_stock
                        )}
                        {renderCell("Incoming:", items_count_per_status.incoming)}
                        {renderCell("On hold (SO blocked):", items_count_per_status.on_hold)}
                    </ul>
                    <StockItemsList productId={id} selectedCombination={selectedCombination} />
                </div>
            </Fragment>
        </div>
    )
}

export default GeneralInfo
