import React, { Fragment } from "react"
import PropTypes from "prop-types"

import { Modal, Button } from "ui"

import withStyles from "HOC/withStyles"
import styles from "./deleteOneProductModal.css"

const { Header, Body, Actions } = Modal

const DeleteOneProductModal = props => {
    const { cx, isOpen, isLoading, product, onCancel, onSubmit } = props

    return (
        <Modal
            isOpen={isOpen}
            closeModal={onCancel}
            render={() => (
                <Fragment>
                    <Header>Delete this product?</Header>
                    <Body>
                        <div className={cx("content")}>
                            <div className={cx("image")} style={{ backgroundImage: `url(${product.image})` }} />
                            <div className={cx("productData")}>
                                <h3 className={cx("name")}>{product.name}</h3>
                                {renderBrand()}
                                {renderAttributes()}
                            </div>
                        </div>
                    </Body>
                    <Actions withLine>
                        <button className={cx("cancelButton")} onClick={onCancel}>
                            Cancel
                        </button>
                        <Button
                            className={`first-button ${cx("submitButton")}`}
                            isLoading={isLoading}
                            onClick={onSubmit}
                            label="Delete"
                        />
                    </Actions>
                </Fragment>
            )}
        />
    )

    function renderBrand() {
        if (!product.vendor) {
            return null
        }

        return <p className={cx("vendor")}>{product.vendor}</p>
    }

    function renderAttributes() {
        if (!product.attributes) {
            return null
        }

        return (
            <div className={cx("attributes")}>
                {product.attributes &&
                    Object.entries(product.attributes).map(([key, value]) => (
                        <span key={key} className={cx("attribute")}>
                            {key}: <span>{value}</span>
                        </span>
                    ))}
            </div>
        )
    }
}

DeleteOneProductModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default withStyles(DeleteOneProductModal, styles)
