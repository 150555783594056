import React, { useState, useEffect, useRef, Fragment } from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"
import moment from "moment"
import { Calendar } from "react-date-range"

import withStyles from "HOC/withStyles"

import style from "./DatePickerWithInput.css"

import calendar from "assets/calendar-blue.svg"
import close from "assets/close.svg"
import closeBlue from "assets/close-blue.svg"

import { READABLE_STANDARD_DATE, API_DATE_FORMAT } from "constants/index"

const DatePickerWithInput = props => {
    const {
        cx,
        date,
        label,
        placeholder,
        disableClear,
        minDate,
        maxDate,
        error,
        calendarAboveInput,
        isClearInLabel,
        calendarOnRight,
        handleSelectDate,
        isDisabled,
        isRequired
    } = props

    const calendarWindow = useRef(null)

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const [inputValue, setInputValue] = useState(null)

    useEffect(() => {
        if (isDatePickerOpen) {
            document.addEventListener("click", handleCloseCalendar)
            return () => document.removeEventListener("click", handleCloseCalendar)
        }
    }, [isDatePickerOpen])

    useEffect(() => {
        setInputValue(date && moment(date).format(READABLE_STANDARD_DATE))
    }, [date])

    const handleOpenCalendar = () => setIsDatePickerOpen(true)

    const handleCloseCalendar = event => {
        event.stopPropagation()
        const isClickedDateBar = calendarWindow.current && !calendarWindow.current.contains(event.target)
        const isClickedDay = event.target.classList.contains("rdrDayNumber")

        if (isClickedDateBar || isClickedDay) {
            setIsDatePickerOpen(false)
        }
    }

    const onManualChange = event => {
        setInputValue(event.target.value)
        setIsDatePickerOpen(false)
    }

    const applyDate = event => {
        event.preventDefault()
        const manualChangedDate = moment(event.target.value, READABLE_STANDARD_DATE)

        if (!manualChangedDate.isValid()) {
            return setInputValue(date && moment(date).format(READABLE_STANDARD_DATE))
        }

        const formattedMinDate = minDate && moment(minDate).format(API_DATE_FORMAT)
        const formattedMaxDate = maxDate && moment(maxDate).format(API_DATE_FORMAT)
        const formattedDate = manualChangedDate.format(API_DATE_FORMAT)

        handleSelectDate(
            formattedDate === date
                ? date
                : formattedMinDate && formattedDate < formattedMinDate
                ? formattedMinDate
                : formattedMaxDate && formattedDate > formattedMaxDate
                ? formattedMaxDate
                : manualChangedDate.format(API_DATE_FORMAT)
        )
    }

    const formattedError = Array.isArray(error)
        ? error.map((er, index, arr) =>
              index < arr.length ? (
                  <Fragment>
                      {er}
                      <br />
                  </Fragment>
              ) : (
                  er
              )
          )
        : error

    return (
        <div>
            <div
                className={cx("root", {
                    onTop: calendarAboveInput,
                    onRight: calendarOnRight,
                    isValue: !!date,
                    error: !!error,
                    isDisabled
                })}
                onClick={isDisabled ? () => {} : handleOpenCalendar}
            >
                {label && (
                    <div className={cx("label")}>
                        {label}
                        {isRequired && <span className={cx("required-star")}> *</span>}
                    </div>
                )}

                <div className={cx("datepicker-placeholder")}>
                    <input
                        className={cx("input")}
                        value={inputValue || ""}
                        onChange={onManualChange}
                        onBlur={applyDate}
                        onKeyDown={event => event.key === "Enter" && applyDate(event)}
                        placeholder={placeholder}
                    />

                    {!disableClear && date && date.length > 0 && (
                        <ReactSVG
                            src={isClearInLabel ? closeBlue : close}
                            className={cx("clear", { isClearInLabel })}
                            onClick={event => {
                                handleSelectDate(null)
                                handleCloseCalendar(event)
                            }}
                        />
                    )}

                    <ReactSVG src={calendar} className={cx("logo")} />
                </div>

                {isDatePickerOpen && (
                    <span className={cx("calendar")} ref={calendarWindow} onClick={handleCloseCalendar}>
                        <Calendar
                            date={date ? moment(date).toDate() : null}
                            onChange={date => handleSelectDate(moment(date).format(API_DATE_FORMAT))}
                            maxDate={maxDate}
                            minDate={minDate}
                        />
                    </span>
                )}
            </div>
            {error && <p className={cx("error")}>{formattedError}</p>}
        </div>
    )
}

DatePickerWithInput.defaultProps = {
    calendarAboveInput: false,
    disableClear: false,
    placeholder: "- Please select -",
    handleSelectDate: () => {}
}

DatePickerWithInput.propTypes = {
    disableClear: PropTypes.bool,
    calendarAboveInput: PropTypes.bool,
    isClearInLabel: PropTypes.bool,
    calendarOnRight: PropTypes.bool,
    date: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    minDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    maxDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    handleSelectDate: PropTypes.func,
    isDisabled: PropTypes.bool,
    isRequired: PropTypes.bool
}

export default withStyles(DatePickerWithInput, style)
