import React from "react"
import ReactSVG from "react-svg"
import deleteIcon from "assets/delete.svg"
import addButtonStyles from "./AddBtn.css"
import Button from "components/Reusable/Form/Button"

function ActionButton(props) {
    const { cx, icon, text, handleAction, className } = props

    return (
        <div className={[className ? className : "", cx("button")].join(" ")} onClick={handleAction}>
            <ReactSVG src={icon} alt="Action button" />
            {text}
        </div>
    )
}

function Header(props) {
    const { cx, handleAdd, handleDeleteAll, counter, isEmpty } = props

    return (
        <div>
            <ul className={cx("header")}>
                <li>
                    <b>Related Products</b>
                    <span className={cx("counter")}>{String(counter)}</span>
                </li>

                <li>
                    {!isEmpty && (
                        <ActionButton
                            cx={cx}
                            className={cx("DelBtn")}
                            text="Delete All"
                            icon={deleteIcon}
                            handleAction={() => handleDeleteAll()}
                        />
                    )}
                    <Button
                        className={cx("first-button", "white")}
                        customStyles={addButtonStyles}
                        label={"Add product"}
                        onClick={() => handleAdd()}
                        key={"AddBtn"}
                    />
                </li>
            </ul>
        </div>
    )
}

export default Header
