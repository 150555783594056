import React, { Component, Fragment } from "react"
import { Personal, Address, Contact } from "./components"

import withStyles from "HOC/withStyles"
import styles from "./generalinfo.module.css"

class GeneralInfo extends Component {
    render() {
        const { cx } = this.props
        return (
            <Fragment>
                <Personal className={cx("personal")} {...this.props} />
                <Contact className={cx("contact")} {...this.props} />
                <Address {...this.props} />
            </Fragment>
        )
    }
}

export default withStyles(GeneralInfo, styles)
