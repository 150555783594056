import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import idx from "idx"

import fetch from "helpers/fetch"

import ReturnHeader from "components/Reusable/ReturnHeader/ReturnHeader"
import Overview from "./Overview"

class OverviewContainer extends Component {
    state = {
        currentTabIndex: 0,
        attachmentsCount: 0
    }

    tabs = [
        {
            key: "general-info",
            label: "General Info"
        },
        {
            key: "notes",
            label: "Notes"
        },
        {
            key: "analytics",
            label: "Analytics"
        },
        {
            key: "tax-code",
            label: "Tax Code"
        },
        {
            key: "temp",
            label: "Temp"
        },
        {
            key: "attachments",
            label: "Attachments"
        },
        {
            key: "client-meetings",
            label: "Client Meetings"
        },
        {
            key: "projects",
            label: "Projects"
        },
        {
            key: "sales-orders",
            label: "SO"
        },
        {
            key: "purchase-orders",
            label: "PO"
        },
        {
            key: "invoices",
            label: "IN"
        },
        {
            key: "payments",
            label: "Payments"
        },
        {
            key: "store-credit",
            label: "Store Credit"
        }
    ]

    componentDidMount() {
        const { match, getClientById, history } = this.props
        const sectionName = idx(match, _ => _.params.sectionName)

        sectionName
            ? this.setState({
                  currentTabIndex: this.tabs.findIndex(tab => tab.key === sectionName) || 0
              })
            : history && history.push(`${history.location.pathname}/${this.tabs[0].key}`)

        getClientById(match && match.params && match.params.clientId)

        if (idx(match, _ => _.params.clientId)) {
            this.getAttachmentsCount(match.params.clientId)
        }
    }

    componentDidUpdate(prevProps) {
        const updatedNotes = this.props.client.data.notes

        if (prevProps.client.isLoaded && prevProps.client.data.notes.length !== updatedNotes.length) {
            this.setState({
                currentTabIndex: updatedNotes.length > 0 ? 1 : 0
            })
        }
    }

    getAttachmentsCount(externalClientId) {
        const clientId = externalClientId || this.props.client.data.id

        fetch.get(`/notes?object_type=client&object_id=${clientId}&with_attachments=true`).then(data =>
            this.setState({
                attachmentsCount: data.data.length
            })
        )
    }

    handleTab = index => {
        const { history } = this.props

        history && history.push(this.tabs[index].key)

        this.setState({ currentTabIndex: index })
    }

    render() {
        const { match, permissions, client } = this.props
        const { currentTabIndex, attachmentsCount } = this.state

        if (client.isLoading || !client.isLoaded) {
            return null
        }

        return (
            <Fragment>
                <ReturnHeader link="/contact" label="All Contact" isArrow={true} />
                <Overview
                    {...client}
                    handleTab={this.handleTab}
                    tabs={this.tabs.map(item => item.label)}
                    currentTabIndex={currentTabIndex}
                    attachmentsCount={attachmentsCount}
                    handleAttachmentsCount={attachmentsCount => this.setState({ attachmentsCount })}
                    getAttachmentsCount={this.getAttachmentsCount.bind(this)}
                    url={match && match.url.replace(`/${match.params.sectionName}`, "")}
                    isTmpTabShown={permissions.includes("view tmp tab")}
                />
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        permissions: state.loginStore.permissions
    }
}

export default connect(mapStateToProps)(OverviewContainer)
