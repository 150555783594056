import { useCallback } from "react"
import { useQueryClient } from "react-query"

const useChangeShipmentStatusOnListCache = ({ queryKey }) => {
    const queryClient = useQueryClient()

    const changeShipmentStatusOnListCache = useCallback(
        (shipmentId, updatedShipment) => {
            queryClient.setQueryData(queryKey, prevState => ({
                ...prevState,
                data: prevState.data.map(item =>
                    item.id === shipmentId
                        ? {
                              ...item,
                              status: updatedShipment.status,
                              processing_states: updatedShipment.processing_states
                          }
                        : item
                )
            }))
        },
        [queryClient, queryKey]
    )

    return changeShipmentStatusOnListCache
}

export default useChangeShipmentStatusOnListCache
