import { PRODUCT_TYPE_KEYS } from "./productTypeKeys"

export const HEADER_SELECT_OPTIONS = {
    [PRODUCT_TYPE_KEYS.PRODUCTS]: {
        id: PRODUCT_TYPE_KEYS.PRODUCTS,
        label: "Products"
    },
    [PRODUCT_TYPE_KEYS.SERVICES]: {
        id: PRODUCT_TYPE_KEYS.SERVICES,
        label: "Services"
    }
}
