import React, { Fragment, useState } from "react"
import { Formik } from "formik"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"

import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import { Modal } from "ui"

import ChooseImages from "./ChooseImage"
import ChooseImagesWithName from "./ChooseImageWithName"
import ChooseProducts from "./ChooseProducts"

import { schema as validationSchema } from "./schema"

import styles from "./styles.css"

const SectionModalImageWithPinsContainer = ({
    cx,
    onClose,
    onSave,
    data,
    imageWithName,
    preventCloseOnSave,
    imageDimensions,
    isLoading
}) => {
    const baseWidth = 835
    const baseHeight = 446.725
    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = {
        id: data.id,
        name: data.name,
        cover_desktop: data.image_with_pins_desktop_edit,
        cover_tablet: data.image_with_pins_tablet,
        cover_mobile: data.image_with_pins_mobile,
        pins: (data.products || []).map(item => ({
            product: {
                id: item.id,
                name: item.name,
                brand: item.brand,
                img: item.image
            },
            x: (Number(item.x) * baseWidth) / 100,
            y: (Number(item.y) * baseHeight) / 100
        }))
    }

    const [currentStage, setCurrentState] = useState(data.initialStage || "chooseImage")

    const handleSubmit = async (values, formikActions) => {
        if (currentStage === "chooseImage" && data.initialStage !== "chooseImage") {
            return setCurrentState("chooseProducts")
        }

        try {
            setIsSubmitting(true)

            await onSave({
                id: values.id,
                type: "image_with_pins",
                name: values.name,
                image_with_pins_desktop: values.cover_desktop ? values.cover_desktop.id : null,
                image_with_pins_tablet: values.cover_tablet ? values.cover_tablet.id : null,
                image_with_pins_mobile: values.cover_mobile ? values.cover_mobile.id : null,
                products: values.pins.map(item => ({
                    id: item.product.id,
                    x: (item.x * 100) / baseWidth,
                    y: (item.y * 100) / baseHeight
                }))
            })

            !preventCloseOnSave && onClose()
        } catch (error) {
            setIsSubmitting(false)
            formikActions.setErrors(error)
        }
    }

    const handleChangePhoto = () => {
        setCurrentState("chooseImage")
    }

    if (isLoading) {
        return (
            <Modal isOpen={true} closeModal={onClose} preventClickOutside={true}>
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            </Modal>
        )
    }

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
        >
            {propsForm => (
                <Fragment>
                    {!!imageWithName ? (
                        <ChooseImagesWithName
                            isOpen={currentStage === "chooseImage"}
                            propsForm={propsForm}
                            onClose={onClose}
                            isSubmitting={isSubmitting}
                            initialStage={data.initialStage}
                            imageDimensions={imageDimensions}
                        />
                    ) : (
                        <ChooseImages
                            isOpen={currentStage === "chooseImage"}
                            propsForm={propsForm}
                            onClose={onClose}
                            imageDimensions={imageDimensions}
                        />
                    )}
                    <ChooseProducts
                        isOpen={currentStage === "chooseProducts"}
                        propsForm={propsForm}
                        handleChangePhoto={handleChangePhoto}
                        onClose={onClose}
                        isSubmitting={isSubmitting}
                    />
                </Fragment>
            )}
        </Formik>
    )
}

SectionModalImageWithPinsContainer.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    imageDimensions: PropTypes.shape({
        desktop: PropTypes.object.isRequired,
        tablet: PropTypes.object.isRequired,
        mobile: PropTypes.object.isRequired
    }).isRequired,
    imageWithName: PropTypes.bool,
    preventCloseOnSave: PropTypes.bool,
    isLoading: PropTypes.bool
}

export default withStyles(SectionModalImageWithPinsContainer, styles)
