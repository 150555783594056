import * as constants from "constants/index"

export function toggleSession() {
    return {
        type: constants.TOGGLE_SESSION
    }
}

export function assignTemporaryClient(id) {
    return {
        type: constants.ASSIGN_TEMPORARY_CLIENT,
        payload: {
            data: {
                id
            }
        }
    }
}

export function assignClient(client) {
    const { id, first_name, last_name, email } = client
    return {
        type: constants.ASSIGN_CLIENT,
        payload: {
            data: {
                id,
                name:
                    !first_name && !last_name ? email : `${first_name ? first_name : ""} ${last_name ? last_name : ""}`,
                email
            }
        }
    }
}

export function dismissClient() {
    return {
        type: constants.DISMISS_CLIENT
    }
}
