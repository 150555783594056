import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PropTypes from "prop-types"
import moment from "moment"
import debounce from "lodash.debounce"

import { Modal } from "ui"

import * as fetchStatusHelper from "helpers/fetchStatus"
import { fetchStatusPropTypes } from "helpers/fetchStatus"
import * as projectActions from "actions/projects"
import * as productActions from "./actions"
import useNonmutableState from "hooks/useNonmutableState"
import ChooseProduct from "./ChooseProduct"

import modalStyles from "./overrides/Modal.css"

const initialStateDateRange = { startDate: null, endDate: null }

let debounceFilterSearchPhrase

const ChooseProductContainer = props => {
    const {
        areManualEntries,
        products,
        actions,
        handleSelect,
        disableQuantity,
        mainTitle,
        disableCombinations,
        fetchStatuses,
        isOpen,
        handleClose,
        useServices,
        setIsModified,
        selectedBrand,
        isDisabledAddItems,
        useWholesaleCost
    } = props

    const { brands, categories, locations } = products.meta.aggregates || { brands: [], categories: [], locations: [] }

    const additionalFilters = props.additionalFilters ? props.additionalFilters : {}

    const [type, setType] = useState("products")
    const [filterSearchPhrase, setFilterSearchPhrase] = useState("")
    const [filterDebouncedSearchPhrase, setFilterDebouncedSearchPhrase] = useState("")

    const [filterBrand, setFilterBrand] = useState(null)
    const [filterCategory, setFilterCategory] = useState(null)
    const [filterInstock, setFilterInstock] = useState(null)
    const [filterLocation, setFilterLocation] = useState(null)
    const [filterSubCategory, setFilterSubCategory] = useState(null)
    const [filterManualEntries, setFilterManualEntries] = useState(false)

    const [filterDateRange, setFilterDateRange] = useState(null)
    const [filterCount, setFilterCount] = useState(0)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [isSaving, setIsSaving] = useState(false)
    const [itemsState, setItemsState] = useState({})
    const [isFilterExpanded, setIsFilterExpanded] = useState(true)

    const [isDatapickerOpen, isDatapickerOpenRef, setIsDatapickerOpen] = useNonmutableState(false)
    const [isAddManualEntryFormOpen, setIsAddManualEntryFormOpen] = useState(false)

    const availabilityStatuses = [
        {
            label: "In Stock",
            value: "in_stock"
        },
        {
            label: "Available for order",
            value: "available_for_order"
        },
        {
            label: "Incoming",
            value: "incoming"
        }
    ]

    useEffect(() => {
        debounceFilterSearchPhrase = debounce(setFilterDebouncedSearchPhrase, 350)

        return () => {
            debounceFilterSearchPhrase.cancel()
        }
    }, [])

    useEffect(() => {
        debounceFilterSearchPhrase(filterSearchPhrase)
    }, [filterSearchPhrase])

    useEffect(() => {
        setFilterCount(
            [filterBrand, filterInstock, filterLocation, filterCategory, filterSubCategory, filterDateRange].reduce(
                (acc, item) => acc + +!!item,
                0
            )
        )

        const isFilterDateRangeValid = filterDateRange ? filterDateRange.startDate !== filterDateRange.endDate : true

        if (isFilterDateRangeValid) {
            actions.fetchProducts({ type, ...getCurrentFilters(), ...additionalFilters })
            setIsDatapickerOpen(false)
        }
    }, [
        filterDebouncedSearchPhrase,
        filterInstock,
        filterLocation,
        filterBrand,
        filterCategory,
        filterSubCategory,
        filterDateRange,
        type,
        filterManualEntries
    ])

    useEffect(() => {
        return () => {
            actions.clearState()
        }
    }, [])

    useEffect(() => {
        const nextItemsState = Object.keys(itemsState).reduce((acc, id) => {
            let item = itemsState[id]

            if (fetchStatuses[id]) {
                item = {
                    ...item,
                    isLoading: fetchStatuses[id].isLoading
                }
            }

            acc[id] = item

            return acc
        }, {})

        setItemsState(nextItemsState)
    }, [fetchStatuses])

    useEffect(() => {
        const subcategories = !!filterCategory ? filterCategory.children : []

        if (!!filterSubCategory && !subcategories.find(subcategory => subcategory.id === filterSubCategory.id)) {
            setFilterSubCategory(null)
        }
    }, [filterCategory])

    useEffect(() => {
        setIsAddManualEntryFormOpen(false)
    }, [isOpen, type])

    const handleLoadMoreProducts = () => {
        const canLoadMore = !fetchStatusHelper.fromResource({ fetchStatus: products.nextFetchStatus }).isLoading

        if (canLoadMore) {
            const params = { type, ...getCurrentFilters(), page: products.meta.current_page + 1 }

            actions.fetchMoreProducts(params)
        }
    }

    useEffect(() => {
        if (selectedBrand) {
            setFilterBrand(selectedBrand)
        }
    }, [selectedBrand])

    function getCurrentFilters() {
        const params = { manual_entries: filterManualEntries }

        if (filterSearchPhrase) {
            params.query = filterDebouncedSearchPhrase
        }

        if (filterBrand && filterBrand.id) {
            params.brands = [filterBrand.id]
        }

        if (filterCategory && filterCategory.id) {
            params.categories = filterCategory.id
        }

        if (filterSubCategory && filterSubCategory.id) {
            params.categories = filterSubCategory.id
        }

        if (filterDateRange) {
            params.created_from = filterDateRange.startDate
            params.created_to = filterDateRange.endDate
        }

        if (filterInstock) {
            params.availability_status = filterInstock.value
        }

        if (filterLocation) {
            params.locations = filterLocation.id
        }

        return params
    }

    const formatDate = date => moment(date).format("YYYY-MM-DD")

    const handleChangeFilterDateRange = data => {
        const nextRange = {
            startDate: formatDate(data.range.startDate),
            endDate: formatDate(data.range.endDate)
        }

        setFilterDateRange(nextRange)
    }

    const handleResetFilterDate = () => {
        setFilterDateRange(null)
    }

    const handleSelectProduct = product => {
        setIsModified && setIsModified(true)
        if (!product) {
            return setSelectedProduct(null)
        }

        setSelectedProduct(product)

        if (product.has_combinations && !disableCombinations) {
            return false
        }

        const quantity = itemsState[product.id] ? itemsState[product.id].quantity : 1
        const manualEntryData = product.manual_entry
            ? {
                  discount: product.discount,
                  note: product.note,
                  tax_code: product.tax_code,
                  quantity: product.quantity
              }
            : {}

        handleSelectProductCombination({ product_id: product.id, combination_id: null, quantity, ...manualEntryData })
    }

    const handleSelectProductCombination = (
        { product_id, combination_id, quantity = 1, discount, note, tax_code } = {
            product_id: null,
            combination_id: null,
            quantity: 1
        }
    ) => {
        handleSelect({ product_id, combination_id, quantity, discount, note, tax_code })
    }

    const handleChangeQuantity = (value, id) => {
        setItemsState(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                quantity: value
            }
        }))
    }

    const handleChangeIsLoading = (id, value) => {
        setItemsState(prevState => ({
            ...prevState,
            [id]: {
                ...prevState[id],
                isLoading: value
            }
        }))
    }

    const handleChangeType = () => {
        setFilterBrand(null)
        setFilterCategory(null)
        setFilterSubCategory(null)
        setFilterDateRange(null)
        setFilterManualEntries(false)
        setType(type === "products" ? "services" : "products")
        actions.clearState()
    }

    const handleToggleManualEntryForm = () => setIsAddManualEntryFormOpen(wasOpen => !wasOpen)

    return (
        <Modal
            isOpen={isOpen}
            customStyles={modalStyles}
            closeModal={handleClose}
            preventClickOutside={true}
            stopPropagation={false}
        >
            <ChooseProduct
                type={type}
                useServices={useServices}
                itemsState={itemsState}
                selectedProduct={selectedProduct}
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                products={products}
                brands={brands}
                locations={locations}
                availabilityStatuses={availabilityStatuses}
                disableQuantity={disableQuantity}
                categories={categories}
                filterCount={filterCount}
                filterSearchPhrase={filterSearchPhrase}
                filterBrand={filterBrand}
                filterCategory={filterCategory}
                filterSubCategory={filterSubCategory}
                filterDateRange={{ ...(filterDateRange || initialStateDateRange), key: "range" }}
                filterInstock={filterInstock}
                filterLocation={filterLocation}
                filterManualEntries={filterManualEntries}
                isFilterExpanded={isFilterExpanded}
                setIsFilterExpanded={setIsFilterExpanded}
                hasMoreProducts={products.meta.last_page !== products.meta.current_page}
                handleChangeFilterBrand={setFilterBrand}
                handleChangeFilterCategory={setFilterCategory}
                handleChangeFilterSubCategory={setFilterSubCategory}
                handleChangeFilterSearchPhrase={setFilterSearchPhrase}
                handleChangeFilterDateRange={handleChangeFilterDateRange}
                handleChangeFilterInstock={setFilterInstock}
                handleChangeFilterLocation={setFilterLocation}
                handleChangeFilterManualEntries={setFilterManualEntries}
                handleResetFilterRangeDate={handleResetFilterDate}
                handleLoadMoreProducts={handleLoadMoreProducts}
                handleSelectProduct={handleSelectProduct}
                handleChangeQuantity={handleChangeQuantity}
                handleChangeIsLoading={handleChangeIsLoading}
                handleSelectProductCombination={handleSelectProductCombination}
                mainTitle={mainTitle}
                disableCombinations={disableCombinations}
                handleChangeType={handleChangeType}
                isDatapickerOpen={isDatapickerOpen}
                isDatapickerOpenRef={isDatapickerOpenRef}
                setIsDatapickerOpen={setIsDatapickerOpen}
                areManualEntries={areManualEntries}
                isAddManualEntryFormOpen={isAddManualEntryFormOpen}
                handleToggleManualEntryForm={handleToggleManualEntryForm}
                selectedBrand={selectedBrand}
                isDisabledAddItems={isDisabledAddItems}
                useWholesaleCost={useWholesaleCost}
            />
        </Modal>
    )
}

const mapStateToProps = state => {
    return {
        brands: state.ui.modals.chooseProduct.brands.list,
        categories: state.ui.modals.chooseProduct.categories.list,
        products: state.ui.modals.chooseProduct.products.list,
        services: state.ui.modals.chooseProduct.services.list
    }
}

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(
            {
                ...projectActions,
                ...productActions
            },
            dispatch
        )
    }
}

ChooseProductContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    areManualEntries: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
    handleSelect: PropTypes.func.isRequired,
    fetchStatuses: PropTypes.objectOf(fetchStatusPropTypes),
    mainTitle: PropTypes.string,
    useServices: PropTypes.bool,
    setIsModified: PropTypes.func,
    selectedBrand: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    }),
    isDisabledAddItems: PropTypes.bool,
    useWholesaleCost: PropTypes.bool
}

ChooseProductContainer.defaultProps = {
    useServices: false
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseProductContainer)
