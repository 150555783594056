import React, { useEffect, useState } from "react"
import SaveBar from "ui/SaveBar"
import ListingLayout from "../MainPageListingLayout"
import Header from "../../components/Header"
import Listing from "../../components/MainPageListings"
import Modal from "components/Modal/Modal"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"

import SectionNameEditModal from "../../components/MainPageNameSectionEditModal"
import AddImmediateDeliveryModal from "../../components/MainPageAddImmediateDeliveryModal"
import EditImmediateDeliveryModal from "../../components/MainPageEditImmediateDeliveryModal"

const MainPageImmediateDelivery = props => {
    const {
        mainPages: { data, isLoading },
        modifiedData,
        isModified,
        handleSubmit,
        handleUpdate,
        handleClearChanges,
        getImmediateDelivery,
        immediateDelivery,
        handleImmediateDeliveryUpdate,
        isSaving,
        handleCreateImmediateDelivery,
        showErrorNotification,
        handleDeleteAllFromList
    } = props

    useEffect(() => {
        const list = (isModified ? modifiedData.immediate_deliveries : data.immediate_deliveries) || []
        setList(list)
    }, [data, modifiedData])

    const [list, setList] = useState([])
    const [sectionNameEditModalState, setSectionNameEditModalState] = useState(false)
    const [addImmediateDeliveryModalState, setAddImmediateDeliveryModalState] = useState(false)
    const [isDeleteImmediateDeliveryOpen, setIsDeleteImmediateDeliveryOpen] = useState(false)
    const [deletingImmediateDelivery, setDeletingImmediateDelivery] = useState(null)
    const [isDeleteAllImmediateDeliveryOpen, setIsDeleteAllImmediateDeliveryOpen] = useState(false)
    const [editImmediateDeliveryModalState, setEditImmediateDeliveryModalState] = useState(false)
    const [editingImmediateDelivery, setEditingImmediateDelivery] = useState(null)

    const displayTitle = () =>
        (data.settings &&
            data.settings.sections &&
            data.settings.sections.immediateDelivery &&
            data.settings.sections.immediateDelivery.label) ||
        "Immediate delivery"

    const listMapping = {
        id: "id",
        source_id: "product_id",
        name: "product_name",
        image: "product_image"
    }

    const handleReorder = immediate_deliveries => {
        handleUpdate({ ...data, immediate_deliveries })
    }

    const handleSectionNameEditClose = () => {
        setSectionNameEditModalState(false)
    }

    const handleSectionNameEditOpen = () => {
        setSectionNameEditModalState(true)
    }

    const handleSectionNameEdit = data => {
        handleSubmit(data, handleSectionNameEditClose)
    }

    const handleAddImmediateDeliveryClose = () => {
        setAddImmediateDeliveryModalState(false)
    }

    const handleAddImmediateDeliveryOpen = () => {
        setAddImmediateDeliveryModalState(true)
    }

    const handleAddImmediateDelivery = data => {
        handleCreateImmediateDelivery(data, handleAddImmediateDeliveryClose)
    }

    const handleDeleteImmediateDeliveryClose = () => {
        setIsDeleteImmediateDeliveryOpen(false)
        setDeletingImmediateDelivery(null)
    }

    const handleDeleteImmediateDeliveryOpen = immediateDelivery => {
        setIsDeleteImmediateDeliveryOpen(true)
        setDeletingImmediateDelivery(immediateDelivery)
    }

    const handleDeleteImmediateDelivery = () => {
        const { handleDeleteImmediateDelivery } = props
        handleDeleteImmediateDelivery(deletingImmediateDelivery.id, handleDeleteImmediateDeliveryClose)
    }

    const handleEditImmediateDeliveryClose = () => {
        setEditingImmediateDelivery(null)
        setEditImmediateDeliveryModalState(false)
    }

    const handleEditImmediateDeliveryOpen = immediateDeliveryId => {
        setEditingImmediateDelivery(immediateDeliveryId)
        setEditImmediateDeliveryModalState(true)
    }

    const handleEditImmediateDelivery = (id, data, onSuccess = null, onError) => {
        handleImmediateDeliveryUpdate(id, data, handleEditImmediateDeliveryClose, onError)
    }

    const handleDeleteAllImmediateDeliveryClose = () => {
        setIsDeleteAllImmediateDeliveryOpen(false)
    }

    const handleDeleteAllImmediateDeliveryOpen = () => {
        setIsDeleteAllImmediateDeliveryOpen(true)
    }

    const handleDeleteAllImmediateDelivery = () => {
        handleDeleteAllFromList(
            { settings: data.settings, header: data.header, immediate_deliveries: [] },
            handleDeleteAllImmediateDeliveryClose
        )
    }

    return (
        <ListingLayout>
            <Header
                title={displayTitle()}
                editButton={{
                    label: "Edit",
                    handler: handleSectionNameEditOpen,
                    isDisabled: false,
                    order: 1
                }}
                addButton={{
                    label: "Add Product",
                    handler: handleAddImmediateDeliveryOpen,
                    isDisabled: false,
                    order: 2
                }}
                onClickActionButton={null}
            />

            <Listing
                isLoading={isLoading}
                handleDelete={handleDeleteImmediateDeliveryOpen}
                handleDeleteAll={handleDeleteAllImmediateDeliveryOpen}
                handleEdit={handleEditImmediateDeliveryOpen}
                listMapping={listMapping}
                listingType="products"
                handleReorder={handleReorder}
                list={list}
            />

            <SectionNameEditModal
                isSaving={isSaving}
                data={modifiedData || data}
                sectionName="immediateDelivery"
                defaultValues={{ label: "Immediate delivery" }}
                handleClose={handleSectionNameEditClose}
                onSubmit={handleSectionNameEdit}
                isOpen={sectionNameEditModalState}
                extended
            />

            <EditImmediateDeliveryModal
                data={immediateDelivery}
                immediateDeliveryId={editingImmediateDelivery}
                getImmediateDelivery={getImmediateDelivery}
                handleClose={handleEditImmediateDeliveryClose}
                handleError={showErrorNotification}
                onSubmit={handleEditImmediateDelivery}
                isOpen={editImmediateDeliveryModalState}
            />

            <AddImmediateDeliveryModal
                data={modifiedData || data}
                handleClose={handleAddImmediateDeliveryClose}
                onSubmit={handleAddImmediateDelivery}
                isOpen={addImmediateDeliveryModalState}
            />

            {isDeleteImmediateDeliveryOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete this immediate delivery?"
                        description="Are you sure you want to delete the immediate delivery"
                        subject={deletingImmediateDelivery.name}
                        descriptionClosure="? This action cannot be reversed."
                        handleHideModal={handleDeleteImmediateDeliveryClose}
                        handleDelete={handleDeleteImmediateDelivery}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            {isDeleteAllImmediateDeliveryOpen && (
                <Modal>
                    <ConfirmWithSubject
                        title="Delete all products?"
                        description="Are you sure you want to delete all of the products?"
                        handleHideModal={handleDeleteAllImmediateDeliveryClose}
                        handleDelete={handleDeleteAllImmediateDelivery}
                        isLoading={isSaving}
                    />
                </Modal>
            )}

            <SaveBar
                message="Unpublished changes"
                submitLabel="Publish"
                isShown={isModified}
                isSaving={isSaving}
                onSubmit={handleSubmit}
                onCancel={handleClearChanges}
            />
        </ListingLayout>
    )
}

export default MainPageImmediateDelivery
