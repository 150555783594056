import React, { Fragment, useState } from "react"
import { Link } from "react-router-dom"
import getStyles from "helpers/getCxFromStyles"
import styles from "./header.module.css"
import getPrintBlob, { showPrintWindow } from "actions/print"
import { showErrorNotification } from "actions/notification"
import { getOrder } from "actions/orders"
import { useActions } from "hooks"
import printIcon from "assets/print.svg"
import { Button, Dropdown } from "ui"
import buttonStyles from "./overrides/button.css"
import printDropdownStyles from "./overrides/printDropdown.css"
import sendDropdownStyles from "./overrides/sendDropdown.css"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader/SnakeLoader"

const Header = ({ id, openSendModal, singleName, ordersRoute, actionsDisabled }) => {
    const cx = getStyles(styles)

    const [printLoading, setPrintLoading] = useState(false)

    const actions = useActions({ showErrorNotification, getOrder })

    function handlePrint(templateType) {
        setPrintLoading(true)
        getPrintBlob(id, "order", templateType)
            .then(data => {
                showPrintWindow(data)
                actions.getOrder(id, true)
            })
            .catch(() => actions.showErrorNotification())
            .finally(() => {
                setPrintLoading(false)
            })
    }

    return (
        <header className={cx("root", "clearfix")}>
            <h2>Details</h2>
            <nav className={cx("manage")}>
                <Link to={`/orders/${ordersRoute}/${id}/edit`} className={cx("edit")}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path
                            className={cx("withFill")}
                            fillRule="nonzero"
                            d="M6.577 16.076l1.347 1.348-1.523.538a.255.255 0 0 1-.381-.308H6.02l.558-1.578zM13.5 8l2.5 2.5-6 6-.25-.75-1.25-.25-.25-1.25L7.5 14l6-6zm3.027-1.79l.094.083 1.086 1.086a1 1 0 0 1 .083 1.32l-.083.094L17 9.5 14.5 7l.707-.707a1 1 0 0 1 1.32-.083z"
                        />
                    </svg>
                    <span>Edit</span>
                </Link>
                {singleName !== "Memo" && singleName !== "Rental" ? (
                    <Fragment>
                        <Dropdown
                            isDisabled={actionsDisabled}
                            className={cx("printSelectDropdown")}
                            label={
                                <Fragment>
                                    <img src={printIcon} alt="Print" className={cx("printButtonIcon")} />
                                    <span className={cx("printButtonLabel")}>
                                        {printLoading ? <SnakeLoader type="small" /> : <span>Print</span>}
                                    </span>
                                </Fragment>
                            }
                            options={[
                                { label: "With cover", onClick: () => handlePrint("with_cover") },
                                { label: "Bigger pictures", onClick: () => handlePrint("bigger_pictures") },
                                { label: "Smaller pictures", onClick: () => handlePrint("smaller_pictures") }
                            ]}
                            customStyles={printDropdownStyles}
                        />
                        <Dropdown
                            isDisabled={actionsDisabled}
                            className={cx("sendOrder")}
                            arrowIconColor="white"
                            label={`Send    ${singleName}`}
                            options={[
                                { label: "With cover", onClick: () => openSendModal("with_cover") },
                                { label: "Bigger pictures", onClick: () => openSendModal("bigger_pictures") },
                                { label: "Smaller pictures", onClick: () => openSendModal("smaller_pictures") }
                            ]}
                            customStyles={sendDropdownStyles}
                        />
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button
                            isDisabled={actionsDisabled}
                            isLoading={printLoading}
                            className={cx("print")}
                            customStyles={buttonStyles}
                            onClick={() => handlePrint()}
                            icon={printIcon}
                            label={<span className={cx("label")}>Print</span>}
                        />
                        <Button
                            isDisabled={actionsDisabled}
                            className={cx("sendOrder")}
                            onClick={() => openSendModal()}
                            label={`Send ${singleName}`}
                        />
                    </Fragment>
                )}
            </nav>
        </header>
    )
}

export default Header
