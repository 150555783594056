import React, { Fragment, useState } from "react"
import { useSelector } from "react-redux"
import { Field, Formik } from "formik"

import withStyles from "HOC/withStyles"

import { Modal as ModalComponent, Button, Input, Toggle, SelectWithSearch } from "ui"
import Dropzone from "./components/Dropzone"

import vendorSchema from "./schema"

import addIcon from "assets/add.svg"

import styles from "./AddVendorModal.css"
import inputStyles from "./overrides/input.css"
import toggleStyles from "./overrides/Toggle.css"
import selectWithSearchStyles from "./overrides/SelectWithSearch.css"
import modalActionsStyles from "./overrides/modalActions.css"

const Modal = props => {
    const { title, cx, handleClose, handleSubmit, initialValues, isSubmitting } = props

    const [isAddressEdited, setIsAddressEdited] = useState(false)

    const states = useSelector(state => state.clientStore.statesList)
    const countries = useSelector(state => state.clientStore.countriesList)

    const onSubmit = async (values, formikActions) => {
        try {
            await vendorSchema.validate(values, {
                abortEarly: false
            })

            await handleSubmit(values)
        } catch (error) {
            formikActions.setErrors(
                error.inner
                    ? (error.inner || []).reduce((acc, item) => {
                          acc[item.path] = item.message
                          return acc
                      }, {})
                    : Object.keys(error.errors || []).reduce((acc, field) => {
                          acc[field] = error.errors[field][0]
                          return acc
                      }, {})
            )
        }
    }

    return (
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit, values, touched, errors, setFieldValue }) => {
                return (
                    <Fragment>
                        <ModalComponent.Header>{title}</ModalComponent.Header>

                        <ModalComponent.Body>
                            <form className={cx("form")} onSubmit={handleSubmit}>
                                <div className={cx("row", "vendorNameRow")}>
                                    <div className={cx("customVendor")}>
                                        <Field name="custom_vendor">
                                            {({ field }) => (
                                                <Toggle
                                                    isOn={values[field.name]}
                                                    label={{
                                                        on: "Visible only in this SO",
                                                        off: "Visible only in this SO"
                                                    }}
                                                    labelPosition="left"
                                                    handleChange={value => {
                                                        setFieldValue(field.name, value)
                                                    }}
                                                    customStyles={toggleStyles}
                                                />
                                            )}
                                        </Field>
                                    </div>

                                    <div className={cx("name")}>
                                        <Field name="name">
                                            {({ field }) => {
                                                return (
                                                    <Input
                                                        label="Vendor name"
                                                        error={touched[field.name] && errors[field.name]}
                                                        value={values[field.name]}
                                                        customStyles={inputStyles}
                                                        onChange={value => {
                                                            setFieldValue(field.name, value.event.target.value)
                                                        }}
                                                        attributes={{ required: true }}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>
                                </div>
                                <div className={cx("row")}>
                                    <div className={cx("poEmail")}>
                                        <Field name="po_email">
                                            {({ field }) => {
                                                return (
                                                    <Input
                                                        label="Email address for PO"
                                                        error={touched[field.name] && errors[field.name]}
                                                        value={values[field.name]}
                                                        customStyles={inputStyles}
                                                        onChange={value => {
                                                            setFieldValue(field.name, value.event.target.value)
                                                        }}
                                                        attributes={{ required: true }}
                                                    />
                                                )
                                            }}
                                        </Field>
                                    </div>
                                </div>

                                <div className={cx("logo")}>
                                    <Field name="logo">
                                        {({ field }) => {
                                            return (
                                                <Dropzone
                                                    imageData={values[field.name]}
                                                    previewData={values[field.name]}
                                                    handleUploadFailure={() => {}}
                                                    handleUploadSuccess={state => {
                                                        const {
                                                            id,
                                                            url,
                                                            file: { name, size }
                                                        } = state.addedFile

                                                        setFieldValue(field.name, { id, url, name, size })
                                                    }}
                                                    handleRemoveFile={() => setFieldValue(field.name, null)}
                                                    isCover={true}
                                                />
                                            )
                                        }}
                                    </Field>
                                </div>

                                {isAddressEdited && (
                                    <Fragment>
                                        <h5 className={cx("main-address")}>Main address</h5>
                                        <div className={cx("row")}>
                                            <div className={cx("companyName")}>
                                                <Field name="company_name">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            label="Company name"
                                                            onChange={({ event }) => field.onChange(event)}
                                                            error={errors[field.name]}
                                                            customStyles={inputStyles}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={cx("row")}>
                                            <div className={cx("country")}>
                                                <Field name="country">
                                                    {({ field }) => (
                                                        <SelectWithSearch
                                                            {...field}
                                                            label="Country"
                                                            values={countries.map(country => ({
                                                                label: country,
                                                                value: country
                                                            }))}
                                                            setValue={({ value }) => {
                                                                if (value !== field.value) {
                                                                    setFieldValue("state", "")
                                                                    field.onChange({
                                                                        target: { name: field.name, value }
                                                                    })
                                                                }
                                                            }}
                                                            customStyles={selectWithSearchStyles}
                                                            withoutFetch
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className={cx("address")}>
                                                <Field name="address">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            label="Address (Street and No.)"
                                                            onChange={({ event }) => field.onChange(event)}
                                                            error={errors[field.name]}
                                                            customStyles={inputStyles}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                        <div className={cx("row")}>
                                            <div className={cx("city")}>
                                                <Field name="city">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            label="City"
                                                            onChange={({ event }) => field.onChange(event)}
                                                            error={errors[field.name]}
                                                            customStyles={inputStyles}
                                                        />
                                                    )}
                                                </Field>
                                            </div>

                                            <div className={cx("state")}>
                                                <Field name="state">
                                                    {({ field }) =>
                                                        values.country === "United States of America" ? (
                                                            <SelectWithSearch
                                                                {...field}
                                                                label="State"
                                                                values={states.map(states => ({
                                                                    label: states,
                                                                    value: states
                                                                }))}
                                                                setValue={({ value }) =>
                                                                    field.onChange({
                                                                        target: { name: field.name, value }
                                                                    })
                                                                }
                                                                withoutFetch
                                                                isListOnTop
                                                                customStyles={selectWithSearchStyles}
                                                            />
                                                        ) : (
                                                            <Input
                                                                {...field}
                                                                label="State"
                                                                onChange={({ event }) => field.onChange(event)}
                                                                error={errors[field.name]}
                                                                customStyles={inputStyles}
                                                            />
                                                        )
                                                    }
                                                </Field>
                                            </div>

                                            <div className={cx("postCode")}>
                                                <Field name="post_code">
                                                    {({ field }) => (
                                                        <Input
                                                            {...field}
                                                            label="ZIP Code"
                                                            onChange={({ event }) => field.onChange(event)}
                                                            error={errors[field.name]}
                                                            customStyles={inputStyles}
                                                        />
                                                    )}
                                                </Field>
                                            </div>
                                        </div>
                                    </Fragment>
                                )}
                            </form>
                        </ModalComponent.Body>
                        <ModalComponent.Actions customStyles={modalActionsStyles}>
                            {!isAddressEdited && (
                                <div className={cx("add-more")} onClick={() => setIsAddressEdited(true)}>
                                    <span>
                                        <img src={addIcon} alt="addImage" />
                                        <span>Add vendor address</span>
                                    </span>
                                </div>
                            )}
                            <button className={cx("cancel-button")} onClick={handleClose}>
                                Cancel
                            </button>
                            <Button
                                className="first-button"
                                onClick={handleSubmit}
                                label={"Create"}
                                isLoading={isSubmitting}
                                type="submit"
                            />
                        </ModalComponent.Actions>
                    </Fragment>
                )
            }}
        </Formik>
    )
}

export default withStyles(Modal, styles)
