import React, { Component } from "react"

const withDebounce = (WrappedComponent, debounce = 200) => {
    class Enhance extends Component {
        debounceTimeout = 0

        componentWillUnmount() {
            clearTimeout(this.debounceTimeout)
        }

        handleDebounce = handler => {
            clearTimeout(this.debounceTimeout)

            if (handler) {
                this.debounceTimeout = setTimeout(() => {
                    handler()
                }, debounce)
            }
        }

        render() {
            return <WrappedComponent {...this.props} handleDebounce={this.handleDebounce} />
        }
    }

    return Enhance
}

export default withDebounce
