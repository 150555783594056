import React, { Fragment } from "react"
import { Link } from "react-router-dom"

import withStyles from "HOC/withStyles"
import Skeleton from "components/Skeleton"
import Loader from "components/Reusable/Loader"
import Head from "./Head"
import Details from "./Details"
import Summary from "./Summary"

import styles from "./styles.css"
import arrowIcon from "assets/arrow-blue.svg"

const Overview = props => {
    const { cx, isStatusChanging, shouldShowSummary, projectId, project, handleChangeStatus } = props

    return (
        <div className={cx("root")}>
            <Link
                to={{
                    pathname: "/projects",
                    state: { fromOverview: true }
                }}
                className={cx("back")}
            >
                <img src={arrowIcon} className={cx("icon")} alt="icon" />
                <span>All Projects</span>
            </Link>

            <Skeleton
                fetchStatus={project.fetchStatus}
                firstTime={true}
                component={
                    <div className={cx("section", "loader")}>
                        <Loader />
                    </div>
                }
                render={() => (
                    <Fragment>
                        <div className={cx("section")}>
                            <Head
                                isStatusChanging={isStatusChanging}
                                project={project.data}
                                handleChangeStatus={handleChangeStatus}
                            />
                        </div>
                        <div className={cx("section")}>
                            <Details projectId={projectId} project={project} />
                        </div>
                        {shouldShowSummary && (
                            <Skeleton
                                fetchStatus={project.roomsFetchStatus}
                                render={() => {
                                    return (
                                        <div className={cx("section")}>
                                            <Summary project={project.data} />
                                        </div>
                                    )
                                }}
                            />
                        )}
                    </Fragment>
                )}
            />
        </div>
    )
}

export default withStyles(Overview, styles)
