import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import moment from "moment"

import withStyles from "HOC/withStyles"
import Modal from "components/Modal/Modal"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import FilterableTable, { Content, Header, Table, Sort, SearchBar } from "ui/FilterableTable"

import Pagination from "components/Reusable/Pagination/index"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"
import { Tags, ChangeableListingView, MultipleRadio, Button } from "ui"
import styles from "./Listing.css"
import { EmptyList } from "../../../components"
import paginationStyles from "./overrides/Pagination.css"
import radiosStyles from "./overrides/Radios.css"
import tableContentStyles from "./overrides/TableContent.css"
import tableStyles from "./overrides/Table.css"
import pencilIcon from "assets/pencil.svg"
import deleteIcon from "assets/delete-fat.svg"
import personIcon from "assets/person.svg"
import slidesIcon from "assets/slides.svg"
import clockIcon from "assets/clock.svg"
import newsIcon from "assets/news.svg"
import alertGreyIcon from "assets/alert-ok-grey.svg"
import alertColorIcon from "assets/alert-ok.svg"

class Listing extends Component {
    render() {
        const {
            cx,
            tagsList,
            listingActiveStyle,
            postsList,
            meta,
            modalData,
            filterValue,
            handleTableChange,
            isPostsLoading,
            isBlog,
            isPortfolio,
            isModalOpen,
            userCanEdit,
            handleSelectFilter,
            handleToggleModal,
            handleSetListingStyle,
            handleGetPostsByTag,
            handleSelectPage
        } = this.props

        let emptyInfo = "News"
        let pathPrefix = "/news"
        if (isBlog) {
            pathPrefix = "/content/blog"
            emptyInfo = "Blog Entries"
        }
        if (isPortfolio) {
            pathPrefix = "/content/portfolio"
            emptyInfo = "Projects"
        }

        return (
            <div className={cx("root", { isPortfolio })}>
                {isBlog && (
                    <MultipleRadio
                        checked={filterValue}
                        handleSelect={handleSelectFilter}
                        isHorizontal={true}
                        name="news"
                        config={[
                            { value: null, label: "All Posts" },
                            { value: true, label: "Verified" },
                            { value: false, label: "Not Verified" }
                        ]}
                        customStyles={radiosStyles}
                    />
                )}
                {!isPortfolio && (
                    <Tags
                        list={tagsList}
                        label="Hot topics:"
                        defaultButtonName="All news"
                        handleRequest={handleGetPostsByTag}
                    />
                )}
                {isPostsLoading && !isPortfolio ? (
                    <div className={cx("snakeloader")}>
                        <SnakeLoader />
                    </div>
                ) : !isPortfolio ? (
                    <ChangeableListingView
                        listingActiveStyle={listingActiveStyle}
                        handleSetListingStyle={handleSetListingStyle}
                    >
                        {postsList &&
                            postsList.map(
                                ({
                                    id,
                                    title,
                                    subtitle,
                                    short_description,
                                    thumbnail,
                                    author,
                                    verified,
                                    visible,
                                    created_at
                                }) => {
                                    return (
                                        <li
                                            key={id}
                                            className={cx(
                                                "card",
                                                listingActiveStyle === "list" ? "isActiveStyleList" : ""
                                            )}
                                        >
                                            <Link to={`${pathPrefix}/card/${id}`}>
                                                <div
                                                    className={cx("thumbnail")}
                                                    style={thumbnail ? { backgroundImage: `url(${thumbnail})` } : null}
                                                />
                                                <div className={cx("content")}>
                                                    <h1>{title}</h1>
                                                    <div className={cx("description")}>
                                                        {subtitle || short_description}
                                                    </div>
                                                </div>
                                                <div className={cx("info")}>
                                                    <p className={cx("author")}>
                                                        <img src={personIcon} alt="icon" />
                                                        {author}
                                                    </p>
                                                    {isBlog && (
                                                        <p
                                                            className={cx("status", {
                                                                isVerified: verified,
                                                                isVisible: visible
                                                            })}
                                                        >
                                                            {verified ? (
                                                                <Fragment>
                                                                    <img
                                                                        src={visible ? alertColorIcon : alertGreyIcon}
                                                                        alt="icon"
                                                                    />
                                                                    {visible ? "Visible" : "Hidden"}
                                                                </Fragment>
                                                            ) : (
                                                                "Not Verified"
                                                            )}
                                                        </p>
                                                    )}
                                                    <p className={cx("date", { userCanEdit })}>
                                                        <img src={clockIcon} alt="icon" />
                                                        {moment(created_at).format("DD MMM YYYY")}
                                                    </p>
                                                </div>
                                            </Link>
                                            {userCanEdit && (
                                                <div className={cx("buttons")}>
                                                    <button
                                                        className={cx("delete")}
                                                        onClick={() => handleToggleModal(id)}
                                                    >
                                                        <img src={deleteIcon} alt="icon" />
                                                        <p>Delete</p>
                                                    </button>
                                                    <Link to={`${pathPrefix}/card/${id}/edit`}>
                                                        <button>
                                                            <img src={pencilIcon} alt="icon" />
                                                        </button>
                                                        <p>Edit</p>
                                                    </Link>
                                                </div>
                                            )}
                                        </li>
                                    )
                                }
                            )}
                    </ChangeableListingView>
                ) : postsList.length ? (
                    <FilterableTable
                        fetchStatus={{
                            isLoading: isPostsLoading,
                            isLoaded: true
                        }}
                        items={postsList}
                        handleChange={handleTableChange}
                    >
                        <Header>
                            <SearchBar placeholder="Search for portfolio..." />
                        </Header>
                        <Content className={cx("content")} customStyles={tableContentStyles}>
                            <Table
                                className={cx("table")}
                                renderHeader={this.renderTableHeader}
                                renderBody={this.renderTableBody}
                                customStyles={tableStyles}
                            />
                        </Content>
                    </FilterableTable>
                ) : null}
                {!isPostsLoading && postsList.length === 0 && (
                    <EmptyList message={`No ${emptyInfo} Added`} icon={isPortfolio ? slidesIcon : newsIcon} />
                )}
                <Pagination
                    customStyles={paginationStyles}
                    handleSelectPage={handleSelectPage}
                    pagesCount={meta.last_page}
                    currentPage={meta.current_page - 1}
                    perPage={meta.per_page}
                />
                {isModalOpen && (
                    <Modal>
                        <ConfirmWithSubject {...modalData} />
                    </Modal>
                )}
            </div>
        )
    }

    renderTableHeader = () => {
        return (
            <Table.Head>
                <Table.Tr>
                    <Table.Th className="textLeft">
                        <Sort sortBy="title">Title</Sort>
                    </Table.Th>
                    <Table.Th className="textLeft">
                        <Sort sortBy="updated_at" sortDirection="desc" isDefault={true}>
                            Last modified
                        </Sort>
                    </Table.Th>
                    <Table.Th />
                </Table.Tr>
            </Table.Head>
        )
    }

    renderTableBody = items => {
        const { cx, handleToggleModal, userCanEdit } = this.props
        return (
            <Table.Body>
                {items.map(({ id, title, thumbnail, updated_at }) => (
                    <Table.Tr key={id}>
                        <Table.Td>
                            <div
                                className={cx("table-image", { isPlaceholder: !thumbnail })}
                                style={{ backgroundImage: `url(${thumbnail ? thumbnail : ""})` }}
                            >
                                {!thumbnail && (
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <g fill="none" fillRule="evenodd">
                                            <path d="M3 3h18v18H3z" />
                                            <path
                                                fill="#d5d6d9"
                                                fillRule="nonzero"
                                                d="M18.75 17.25V6.75c0-.825-.675-1.5-1.5-1.5H6.75c-.825 0-1.5.675-1.5 1.5v10.5c0 .825.675 1.5 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5zm-9.375-4.125l1.875 2.258L13.875 12l3.375 4.5H6.75l2.625-3.375z"
                                            />
                                        </g>
                                    </svg>
                                )}
                            </div>
                            <p className={cx("table-image-title")}>{title}</p>
                        </Table.Td>
                        <Table.Td>
                            <p>{moment(updated_at).format("MM/DD/YYYY")}</p>
                        </Table.Td>
                        <Table.Td className="textRight">
                            {userCanEdit && (
                                <Fragment>
                                    <Link to={`/content/portfolio/card/${id}/edit`}>
                                        <div className={cx("table-edit")}>
                                            <p>Edit</p>
                                        </div>
                                    </Link>
                                    <div className={cx("table-delete")} onClick={() => handleToggleModal(id)}>
                                        <p>Delete</p>
                                    </div>
                                </Fragment>
                            )}
                            <Link to={`/content/portfolio/card/${id}`} target="_blank">
                                <Button className={cx("table-view", { isNotEditable: !userCanEdit })} />
                            </Link>
                        </Table.Td>
                    </Table.Tr>
                ))}
            </Table.Body>
        )
    }
}

export default withStyles(Listing, styles)
