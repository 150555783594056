import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import useStyles from "helpers/getCxFromStyles"
import fetch from "helpers/fetch"

import { Button, Input, Modal, SelectWithSearch } from "ui"

import styles from "./AttributeModal.css"
import inputStyles from "./overrides/Input.css"
import dropdownStyles from "./overrides/Dropdown.css"

const TYPES = [{ label: "Dropdown", value: "dropdown" }, { label: "Color", value: "color" }]

const AttributeModal = ({ onClose, initialValues, onSuccess }) => {
    const cx = useStyles(styles)

    const isEdit = !!initialValues.id
    const nameInputRef = useRef()
    const [name, setName] = useState(initialValues.name || "")
    const [nameError, setNameError] = useState("")
    const [visualType, setVisualType] = useState(initialValues.type || "")
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        if (!isEdit && nameInputRef.current) {
            nameInputRef.current.focus()
        }
    }, [])

    const handleCreate = async () => {
        setIsSubmitting(true)
        try {
            const body = {
                name,
                type: visualType,
                group_id: initialValues.group_id
            }

            isEdit ? await fetch.patch(`/attributes/${initialValues.id}`, body) : await fetch.post("/attributes", body)

            onSuccess()
            onClose()
        } catch (error) {
            setIsSubmitting(false)
            idx(error, _ => _.errors.name) && setNameError(error.errors.name)
        }
    }

    return (
        <Modal isOpen closeModal={onClose} preventClickOutside>
            <p className={cx("header")}>{isEdit ? "Edit an" : "Add new"} Attribute</p>

            <Input
                label="Attribute Name"
                value={name}
                onChange={({ event }) => setName(event.target.value)}
                customStyles={inputStyles}
                error={nameError}
                attributes={{
                    required: true
                }}
                handleInputRefOnInit={node => (nameInputRef.current = node)}
            />

            <SelectWithSearch
                value={(TYPES.find(type => type.value === visualType) || {}).label}
                label="Visual Type"
                values={TYPES}
                setValue={({ value }) => {
                    setVisualType(value)
                }}
                customStyles={dropdownStyles}
                withoutFetch
                isClearBlocked
            />

            <div className={cx("actions")}>
                <Button onClick={onClose} label="Cancel" className={cx("link-button")} />
                <Button
                    label="Save"
                    className={cx("first-button")}
                    isDisabled={!name || !visualType}
                    onClick={handleCreate}
                    isLoading={isSubmitting}
                />
            </div>
        </Modal>
    )
}

AttributeModal.defaultProps = {
    onSuccess: () => {},
    onClose: () => {}
}

AttributeModal.propTypes = {
    onSuccess: PropTypes.func,
    onClose: PropTypes.func,
    initialValues: PropTypes.shape({
        id: PropTypes.number,
        group_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
    }).isRequired
}

export default AttributeModal
