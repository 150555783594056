import * as Yup from "yup"

import { TEXTAREA_MAX_LENGTH } from "constants/index"

export const REQUIRED_IMAGES_COUNT = 2
export const REQUIRED_IMAGES_MESSAGE = `At least ${REQUIRED_IMAGES_COUNT} photos must be added`

export const DESCRIPTION_SCHEMA = Yup.string().max(
    TEXTAREA_MAX_LENGTH,
    `Description may not be greater than ${TEXTAREA_MAX_LENGTH} characters`
)

export const IMAGE_SCHEMA = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required(),
    url: Yup.string().required()
})

export const IMAGES_SCHEMA = Yup.array().of(IMAGE_SCHEMA)

export const REQUIRED_IMAGES_SCHEMA = IMAGES_SCHEMA.min(REQUIRED_IMAGES_COUNT, REQUIRED_IMAGES_MESSAGE)

export const COMBINATION_ATTRIBUTES_SCHEMA = Yup.array().of(
    Yup.object().shape({
        id: Yup.number(),
        value: Yup.object().shape({
            id: Yup.number(),
            name: Yup.string()
        })
    })
)
