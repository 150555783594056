import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Formik } from "formik"
import { createProduct } from "actions/product"

import { ManualEntryHeroForm, ManualEntryPriceForm, ManualEntryFormActions } from "ui/AddManualEntry/components"
import { valuesToApiAdapter, taxCodeToManualEntryAdapter, apiErrorToFormikAdapter } from "ui/AddManualEntry/helpers"

import customProductSchema from "./schema"
import styles from "./AddManualEntry.module.css"
import fieldStyles from "./overrides/fieldStyles.module.css"

const AddManualEntry = ({ onClose, onSuccess, tax_code, discount, selectedBrand }) => {
    const initialValues = useMemo(
        () => ({
            image: null,
            name: "",
            vendor: selectedBrand || null,
            note: "",
            price: "",
            quantity: 1,
            discount: discount || 0,
            tax_code: tax_code || ""
        }),
        [tax_code, discount, selectedBrand]
    )

    const onSubmit = async (values, formikActions) => {
        try {
            const newManualEntry = await createProduct(valuesToApiAdapter(values))

            await onSuccess({
                product_id: newManualEntry.data.id,
                note: values.note,
                quantity: values.quantity,
                discount: values.discount,
                tax_code: taxCodeToManualEntryAdapter(values.tax_code),
                manual_entry: true
            })

            onClose()
        } catch (error) {
            formikActions.setErrors(apiErrorToFormikAdapter())
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={customProductSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({ handleSubmit, values, errors, setFieldValue, setFieldTouched, isSubmitting }) => {
                const handleChangeField = async (name, value) => {
                    await setFieldValue(name, value)
                    setFieldTouched(name, true)
                }

                return (
                    <form className={styles.form} onSubmit={handleSubmit}>
                        <ManualEntryHeroForm
                            setFieldValue={setFieldValue}
                            handleChangeField={handleChangeField}
                            values={values}
                            errors={errors}
                            fieldStyles={fieldStyles}
                            selectedBrand={selectedBrand}
                        />
                        <div className={styles.priceFormColumn}>
                            <ManualEntryPriceForm
                                values={values}
                                errors={errors}
                                handleChangeField={handleChangeField}
                                fieldStyles={fieldStyles}
                            />
                            <ManualEntryFormActions
                                onClose={onClose}
                                isSubmitting={isSubmitting}
                                isDisabled={!values.name || !values.price || !values.quantity || !values.vendor}
                            />
                        </div>
                    </form>
                )
            }}
        </Formik>
    )
}

AddManualEntry.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    tax_code: PropTypes.number.isRequired,
    discount: PropTypes.number.isRequired,
    selectedBrand: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    })
}

export default AddManualEntry
