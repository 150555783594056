import React, { Component } from "react"
import style from "./Input.css"
import withStyles from "HOC/withStyles"
import PhoneInput from "react-telephone-input"
import clear from "assets/delete.svg"

class Input extends Component {
    static defaultProps = {
        handleInputRefOnInit: () => {}
    }

    constructor(props) {
        super(props)
        this.ref = React.createRef()
    }

    handleClear = () => {
        const { onChange, fieldPath } = this.props
        onChange &&
            onChange({
                event: {
                    target: {
                        value: ""
                    }
                },
                fieldPath
            })
    }

    componentDidMount() {
        const node = this.ref.current
        this.props.handleInputRefOnInit(node)
    }

    render() {
        const {
            name,
            value,
            isClear,
            type,
            label,
            attributes,
            fieldPath,
            error,
            onChange,
            onBlur,
            isPlaceholder,
            isInline,
            isInnerLabelLeft,
            className,
            icon,
            innerLabel,
            cx,
            handleScrollToError,
            textForHiddenValue,
            render,
            isPhoneInput,
            tabIndex
        } = this.props

        const htmlFor = name && type ? `${name}_${type}` : null
        let formattedLabel = `${label}${attributes && attributes.required ? " *" : ""}`

        const tabIndexProps = tabIndex ? { tabIndex } : {}

        return (
            <div className={cx("root", { error: error, "inline-input": isInline })}>
                {!isPlaceholder && label && <label htmlFor={htmlFor}>{formattedLabel}:</label>}

                <span className={cx("input-container", className)}>
                    {!isPhoneInput && (
                        <input
                            {...tabIndexProps}
                            type={type}
                            id={htmlFor}
                            name={name}
                            ref={this.ref}
                            placeholder={isPlaceholder ? formattedLabel : ""}
                            value={value || ""}
                            className={cx("input", { isInnerLabelLeft })}
                            required={attributes && attributes.required}
                            onChange={event => onChange && onChange({ event, fieldPath })}
                            onBlur={onBlur}
                        />
                    )}
                    {isPhoneInput && (
                        <PhoneInput
                            flagsImagePath="/img/flags.png"
                            onChange={value => onChange && onChange({ event: { target: { value } }, fieldPath })}
                            onBlur={onBlur}
                            defaultCountry="us"
                            value={value}
                            preferredCountries={["us", "gb"]}
                        />
                    )}
                    {type === "hidden" && textForHiddenValue && (
                        <span className={cx("textForHiddenValue")}>{textForHiddenValue}</span>
                    )}
                    {type === "hidden" && render && render()}
                    {icon && <img src={icon} alt="" className={cx("icon")} />}
                    {value && isClear && (
                        <img src={clear} alt="clear" className={cx("clear")} onClick={() => this.handleClear()} />
                    )}
                    {innerLabel && <span className={cx("innerLabel", { isInnerLabelLeft })}>{innerLabel}</span>}
                </span>

                {error && (
                    <h2
                        className={cx("error-message")}
                        ref={el => {
                            this.errorInput = el
                        }}
                    >
                        {error}
                        {handleScrollToError && handleScrollToError(this.errorInput)}
                    </h2>
                )}
            </div>
        )
    }
}

export default withStyles(Input, style)
