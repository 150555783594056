import React, { useState, Fragment, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { showErrorNotification, showSuccessNotification } from "actions/notification"
import * as selectionActions from "../../actions"
import * as selectionWidgetActions from "modules/SelectionsWidgetModule/actions"
import * as productActions from "actions/product"

import Details from "./Details"
import Modal from "./../../components/modal"
import DeleteModal from "./../../components/deleteModal"

const DetailsContainer = props => {
    const [editModalIsOpen, setEditModalIsOpen] = useState(false)
    const [isModalFetching, setIsModalFetching] = useState(false)
    const [deleteModalIsOpen, setdDeleteModalIsOpen] = useState(false)
    const [selectedProducts, setSelectedProducts] = useState([])
    const [error, setError] = useState(null)
    const [isExporting, setIsExporting] = useState(false)

    const selection = useSelector(state => state.db.selections.current.current)
    const choosenSelectionIdInWidget = useSelector(state => state.db.selectionsWidget.current.choosenSelection)

    const dispatch = useDispatch()

    const {
        match: {
            params: { selectionId }
        },
        history
    } = props

    useEffect(() => {
        fetchSelectionById()
    }, [])

    function handleExport(value) {
        setIsExporting(true)
        const exportType = value.event.target.value

        const iframe = document.querySelector("iframe[data-pdf], a[data-pdf]")
        iframe && iframe.remove()

        const exportAction =
            selectedProducts && selectedProducts.length > 0
                ? productActions.multiSelectExport(selectedProducts, exportType)
                : productActions.getProductsByFilterRAW(`&selections=${selection.data.id}&export=${exportType}`)

        exportAction
            .then(response => response.blob())
            .then(blob => {
                setIsExporting(false)
                const newBlob = new Blob([blob], { type: "application/pdf" })

                let iFrame = document.createElement("iframe")
                iFrame.name = "pdfIframe"
                iFrame.setAttribute("data-pdf", true)
                iFrame.style = "visibility: hidden;"
                iFrame.src = URL.createObjectURL(newBlob)
                document.body.appendChild(iFrame)

                let a = document.createElement("a")
                document.body.appendChild(a)
                a.style = "display: none"
                a.setAttribute("data-pdf", true)
                a.href = iFrame.src
                a.download = "products." + exportType
                a.click()
            })
            .catch(err => {
                setIsExporting(false)
                showErrorNotification(err.message)
            })
    }

    return (
        <Fragment>
            <Details
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
                fetchStatus={selection.fetchStatus}
                selectionId={selectionId}
                selection={selection && selection.data}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                handleExport={handleExport}
                isExporting={isExporting}
            />
            {renderEditModal()}
            {renderDeleteModal()}
        </Fragment>
    )

    function closeEditModal() {
        setError(null)
        setEditModalIsOpen(false)
    }

    function openEditModal() {
        setEditModalIsOpen(true)
    }

    function openDeleteModal() {
        setdDeleteModalIsOpen(true)
    }

    function fetchSelectionById() {
        return dispatch(selectionActions.getSelectionById(selectionId))
    }

    function editSelection(id, body) {
        setIsModalFetching(true)
        return selectionActions
            .editSelection(id, body)
            .then(() => {
                dispatch(showSuccessNotification())
                setEditModalIsOpen(false)
                fetchSelectionById()
            })
            .catch(err => {
                setError(err.errors)
            })
            .finally(() => setIsModalFetching(false))
    }

    function resetChoosenSelection() {
        selectionWidgetActions.removeChoosenSelectionFromLocalStore()
        dispatch(selectionWidgetActions.resetChoosenSelection())
    }

    function deleteSelection(id) {
        setIsModalFetching(true)
        return selectionActions
            .deleteSelectionById(id)
            .then(() => {
                choosenSelectionIdInWidget === parseInt(id, 10) && resetChoosenSelection()
                dispatch(showSuccessNotification())
                setdDeleteModalIsOpen(false)
                history.push(`/products/selections`)
            })
            .catch(() => {
                dispatch(showErrorNotification())
                setdDeleteModalIsOpen(false)
                fetchSelectionById()
            })
            .finally(() => setIsModalFetching(false))
    }

    function renderEditModal() {
        return (
            <Modal
                title={"Edit selection"}
                saveButtonLabel={"Save"}
                submit={data => editSelection(data.id, data)}
                isFetching={isModalFetching}
                isLoading={false}
                isOpen={editModalIsOpen}
                selection={selection.data}
                error={error}
                setError={setError}
                handleClose={closeEditModal}
            />
        )
    }

    function renderDeleteModal() {
        return (
            <DeleteModal
                isOpen={deleteModalIsOpen}
                selectionName={selection.name}
                isLoading={isModalFetching}
                onCancel={() => setdDeleteModalIsOpen(false)}
                onSubmit={() => deleteSelection(selectionId)}
            />
        )
    }
}

export default DetailsContainer
