import React, { useState } from "react"
import PropTypes from "prop-types"
import { Formik, Form as FormikForm, Field } from "formik"
import * as yup from "yup"

import { showErrorNotification, showSuccessNotification } from "actions/notification"
import fetch from "helpers/fetch"
import { useActions } from "hooks"
import useStyles from "helpers/getCxFromStyles"

import Modal from "ui/Modal"
import { Textarea, Button } from "ui"

import styles from "./NoteModal.css"
import textareaStyles from "./overrides/textarea.css"
import modalActionsStyles from "./overrides/modalActions.css"

const NoteModal = ({ onClose, partner, onSuccess }) => {
    const cx = useStyles(styles)
    const isEdit = !!partner.note
    const actions = useActions({ showErrorNotification, showSuccessNotification })

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async (values, formikActions) => {
        setIsSubmitting(true)

        try {
            await fetch.patch(`/partners/${partner.id}`, {
                email: partner.email,
                ...values
            })

            actions.showSuccessNotification()
            onClose()
            onSuccess()
        } catch (error) {
            actions.showErrorNotification()
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <Modal isOpen closeModal={onClose} preventClickOutside>
            <Formik
                initialValues={{ note: partner.note || "" }}
                onSubmit={handleSubmit}
                validationSchema={yup.object().shape({
                    note: yup
                        .string()
                        .max(2000, `Note may not be greater than 2000 characters`)
                        .nullable()
                })}
                validateOnBlur={false}
                validateOnChange={false}
            >
                {propsForm => (
                    <FormikForm>
                        <Modal.Header>{isEdit ? "Edit" : "Add"} a note</Modal.Header>
                        <Modal.Body>
                            <Field name="note">
                                {({ field }) => (
                                    <Textarea
                                        {...field}
                                        label="Add note here..."
                                        onChange={({ event }) => {
                                            propsForm.setFieldError(field.name, null)
                                            field.onChange(event)
                                        }}
                                        error={propsForm.touched[field.name] && propsForm.errors[field.name]}
                                        isPlaceholder
                                        customStyles={textareaStyles}
                                    />
                                )}
                            </Field>
                        </Modal.Body>
                        <Modal.Actions customStyles={modalActionsStyles}>
                            <button className={cx("cancelButton")} onClick={onClose}>
                                Cancel
                            </button>
                            <Button
                                className="first-button"
                                onClick={propsForm.submitForm}
                                label="Save"
                                isLoading={isSubmitting}
                            />
                        </Modal.Actions>
                    </FormikForm>
                )}
            </Formik>
        </Modal>
    )
}

NoteModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    partner: PropTypes.shape({
        id: PropTypes.number.isRequired,
        note: PropTypes.string
    }).isRequired
}

export default NoteModal
