import useQueryListFactory from "modules/WmsModule/hooks/factories/useQueryListFactory"
import fetch from "helpers/fetch"
import { serializeQuery } from "helpers/urls"

export const KEY = "salePersons"

export const fetchSalesPersonList = ({
    query = "",
    length = 20,
    sort_by = "",
    sort_direction = "",
    date_from = "",
    date_to = ""
}) => {
    const queryString = serializeQuery({ query, length, sort_by, sort_direction, date_from, date_to }, "", true)

    return fetch.get(`/sale-persons?${queryString}`)
}

export const useSalesPersonList = useQueryListFactory({ key: KEY, fetchFunction: fetchSalesPersonList })
