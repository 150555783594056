import React, { Fragment, useMemo } from "react"
import PropTypes from "prop-types"

import SubmitModal from "ui/SubmitModal"

const ActionModal = props => {
    const { isOpen, isLoading, type, manualEntry, selectedCount, onClose, onSubmit } = props

    const label = { delete: "Delete", convert: "Convert" }[type]

    const header = `${label} ${manualEntry ? "this manual entry" : "these manual entries"}?`

    const body = useMemo(
        () => (
            <div>
                Are you sure you want to {type}{" "}
                {manualEntry ? (
                    <Fragment>
                        the manual entry <strong>{manualEntry.name}</strong>
                    </Fragment>
                ) : (
                    <strong>{selectedCount} manual entries</strong>
                )}
                {type === "convert" && ` to product${manualEntry ? "" : "s"}`}? This action cannot be reversed.
            </div>
        ),
        [selectedCount, type, manualEntry]
    )

    return (
        <SubmitModal
            isOpen={isOpen}
            isLoading={isLoading}
            redButton={type === "delete"}
            header={header}
            body={body}
            submitText={label}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    )
}

ActionModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
    type: PropTypes.oneOf(["delete", "convert"]),
    manualEntry: PropTypes.shape({
        name: PropTypes.string
    }),
    selectedCount: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func
}

export default ActionModal
