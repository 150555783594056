import idx from "idx"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"
import { getNotesUpdater } from "helpers/notes"
import { roundNumber } from "helpers/units"
import {
    GET_ORDER_START,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    UPDATE_ORDER_SUCCESS,
    ADD_NOTE_SUCCESS,
    EDIT_NOTE_SUCCESS,
    DELETE_NOTE_SUCCESS,
    GET_ORDER_ITEMS_SUCCESS,
    CREATE_ORDER_ITEMS_SUCCESS,
    UPDATE_ORDER_ITEM_SUCCESS,
    DELETE_ORDER_ITEM_SUCCESS,
    DELETE_INVOICE_SUCCESS,
    DELETE_PURCHASE_ORDER_SUCCESS
} from "constants/index"

const calculate = (items, calculations) => {
    const { applied, card_charge_fee_percentage } = calculations

    const { discount, subtotal, tax } = items.reduce(
        ({ discount, subtotal, tax }, item) => ({
            discount: discount + item.calculations.discount,
            subtotal: subtotal + item.calculations.price,
            tax: tax + item.calculations.tax
        }),
        {
            discount: 0,
            subtotal: 0,
            tax: 0
        }
    )

    const totalWithoutTax = subtotal - discount
    const totalWithoutCardChargeFee = totalWithoutTax + tax
    const cardChargeFee = (card_charge_fee_percentage * totalWithoutCardChargeFee) / 100
    const total = totalWithoutCardChargeFee + cardChargeFee
    const balance = applied - total

    return {
        discount,
        subtotal,
        applied,
        tax,
        total: roundNumber(total),
        card_charge_fee_percentage,
        card_charge_fee: roundNumber(cardChargeFee),
        balance: roundNumber(balance)
    }
}

const getOrderItemUpdater = ({ state, action }) => itemsGenerator => {
    const items = itemsGenerator(state, action)

    return {
        ...state,
        data: {
            ...state.data,
            items,
            calculations: calculate(items, state.data.calculations)
        }
    }
}

const updateOrderItemsAfterDocumentDelete = (state, { docId, docType }) => {
    const isValidDocument = typeof docId === "number" && ["purchase_order", "invoice"].includes(docType)

    if (!isValidDocument || !Array.isArray(idx(state, _ => _.data.items))) {
        return state
    }

    return {
        ...state,
        data: {
            ...state.data,
            items: state.data.items.map(item => {
                const itemWasInDeletedDocument = item[docType] && item[docType].id === docId
                return itemWasInDeletedDocument ? { ...item, [docType]: null } : item
            })
        }
    }
}

function current(state = { data: {}, meta: {}, fetchStatus: initialState() }, action) {
    const notesUpdater = getNotesUpdater({ state, action, validType: "order" })
    const orderItemUpdater = getOrderItemUpdater({ state, action })

    switch (action.type) {
        case GET_ORDER_START:
            return { data: {}, meta: {}, fetchStatus: requestState() }

        case GET_ORDER_SUCCESS:
        case UPDATE_ORDER_SUCCESS:
            return {
                data: idx(action, _ => _.payload.data) || {},
                meta: idx(action, _ => _.payload.meta) || {},
                fetchStatus: successState()
            }

        case GET_ORDER_ERROR:
            return { data: {}, meta: {}, fetchStatus: { ...failureState(), error: action.payload } }

        case ADD_NOTE_SUCCESS:
            return notesUpdater((state, action) => ({
                ...state.data,
                notes: [...state.data.notes, action.payload.data]
            }))

        case EDIT_NOTE_SUCCESS:
            return notesUpdater((state, action) => ({
                ...state.data,
                notes: state.data.notes.map(note => {
                    const updatedData = action.payload.data
                    const isForUpdate = note.id === updatedData.id

                    return isForUpdate ? { ...note, ...updatedData } : note
                })
            }))

        case DELETE_NOTE_SUCCESS:
            return notesUpdater((state, action) => ({
                ...state.data,
                notes: state.data.notes.filter(note => note.id !== action.payload.data.id)
            }))

        case CREATE_ORDER_ITEMS_SUCCESS:
            return orderItemUpdater((state, action) => [...state.data.items, ...action.payload.data.items])

        case UPDATE_ORDER_ITEM_SUCCESS:
            return orderItemUpdater((state, action) =>
                state.data.items.map(item => (item.id === action.payload.data.id ? action.payload.data : item))
            )

        case DELETE_ORDER_ITEM_SUCCESS:
            return orderItemUpdater((state, action) => state.data.items.filter(item => item.id !== action.payload))

        case GET_ORDER_ITEMS_SUCCESS:
            return orderItemUpdater((state, action) => action.payload.data)

        case DELETE_INVOICE_SUCCESS:
            return updateOrderItemsAfterDocumentDelete(state, { docId: action.payload, docType: "invoice" })

        case DELETE_PURCHASE_ORDER_SUCCESS:
            return updateOrderItemsAfterDocumentDelete(state, { docId: action.payload, docType: "purchase_order" })

        default:
            return state
    }
}

export default current
