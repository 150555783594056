import React, { Component, Fragment } from "react"
import { connect } from "react-redux"
import { generatePath } from "react-router-dom"
import idx from "idx"

import { scrollToTop } from "helpers/scrollTo"
import fetch from "helpers/fetch"
import * as fetchStatus from "helpers/fetchStatus"
import withForm from "HOC/withForm"
import models from "models/index"

import * as productActions from "actions/product"
import * as noteActions from "actions/nextNote"
import * as notificationActions from "actions/notification"

import Form from "./Form"
import Modal from "components/Modal/Modal"
import Confirm from "components/Modal/Confirm/Confirm"
import { formatDimensionToImperial, formatWeightToImperial } from "helpers/units"

function scrollToError(errors) {
    if (Object.keys(errors).length > 0) {
        const elementToScroll = document.getElementsByName(Object.keys(errors)[0])[0]
        elementToScroll && elementToScroll.scrollIntoView({ behavior: "smooth" })
    }
}

class FormContainer extends Component {
    productId = null

    state = {
        productName: "product",
        isDuplicateProductModalShown: false,
        isModalOpenFor: "",
        modalIsLoading: false,
        dndOrder: [],
        isSubmitting: false
    }

    handleSetDuplicateProductModal = isOpen =>
        this.setState({
            isDuplicateProductModalShown: isOpen
        })

    componentDidMount() {
        const { match, getProductById, getProductConditions } = this.props

        if (match && match.params && match.params.productId) {
            this.productId = match.params.productId
            getProductById(this.productId).then(({ data }) => {
                this.setState({
                    productName: data.name
                })
            })
        }

        getProductConditions()
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { isEdit, product, getProductById, match } = nextProps

        if (isEdit) {
            this.productId = match.params.productId

            if (fetchStatus.isResourceNotInit(product)) {
                getProductById(this.productId).then(({ data }) => {
                    this.setState({
                        productName: data.name
                    })
                })
            }
        }
    }

    getInitialValues = () => {
        return models.Product.fields.reduce((acc, item) => {
            acc[item.name] =
                typeof item.defaultValue !== "undefined" ? item.defaultValue : item.type === "array" ? [] : ""
            return acc
        }, {})
    }

    setInitialValues = (initialValues, data) => {
        const { isEdit } = this.props
        const is_multiplier = false
        const useImperial = false

        const inchConversionMap = {
            width: "conv_width",
            height: "conv_height",
            depth: "conv_depth"
        }

        const lbsConversionMap = {
            weight: "conv_weight"
        }

        return {
            ...initialValues,
            ...Object.keys(data).reduce((acc, key) => {
                if (typeof initialValues[key] === "undefined") {
                    /*
                        overrides
                    */
                    if ("brand" === key) {
                        acc["brand_id"] = data[key] ? data[key].id : null
                    }

                    return acc
                }

                if (data[key] !== null) {
                    acc[key] = data[key]
                }

                if (!!inchConversionMap[key]) {
                    acc[inchConversionMap[key]] = useImperial ? formatDimensionToImperial(data[key]) : data[key]
                }

                if (!!lbsConversionMap[key]) {
                    acc[lbsConversionMap[key]] = useImperial ? formatWeightToImperial(data[key]) : data[key]
                }

                if ("categories" === key) {
                    acc[key] = data[key]
                        ? data[key]
                              .map(item => item.filter(i => i.name !== "Sale").map(c => ({ id: c.id })))
                              .filter(i => i.length)
                        : []
                }

                if ("images" === key) {
                    acc[key] = data[key] ? (isEdit ? data[key].preview || [] : data[key] || []) : []
                }

                if ("features" === key) {
                    acc[key] = data[key] ? ((data[key] || []).length ? data[key] : []) : null
                }

                if ("price" === key) {
                    if (is_multiplier) {
                        acc[key] = ""
                    }
                }

                if ("multiplier" === key) {
                    if (!is_multiplier) {
                        acc[key] = ""
                    }
                }

                return acc
            }, {}),
            is_multiplier: is_multiplier,
            useImperial: useImperial
        }
    }

    render() {
        const {
            toRender,
            isNew,
            match: {
                params: { productId }
            }
        } = this.props

        const {
            isSubmitting,
            isModalOpenFor,
            productName,
            isDuplicateProductModalShown,
            modalIsLoading,
            dndOrder
        } = this.state

        const formFields = toRender()

        const modalContent = {
            delete: {
                id: productId,
                confirmModalTitle: "Are you sure you want to delete this product?",
                handleDelete: this.handleDeleteProduct
            },
            restore: {
                id: productId,
                confirmModalTitle: "Are you sure you want to restore this product?",
                handleDelete: this.handleRestoreProduct,
                actionButtonLabel: "Restore"
            },
            backToProduct: {
                confirmModalTitle: "You have unsaved changes.",
                titleSecondLine: "Are you sure you want to go back?",
                actionButtonLabel: "Back to product card",
                handleDelete: this.handleBackToProduct
            },
            changeTabFromCombination: {
                confirmModalTitle: "You have unsaved changes.",
                titleSecondLine: "Are you sure you want to change tab?",
                actionButtonLabel: "Change tab",
                handleDelete: this.handleChangeTabFromCombination
            }
        }

        const modal = isModalOpenFor && (
            <Modal>
                <Confirm
                    isLoading={modalIsLoading}
                    handleDelete={() => {
                        this.handleHideModal()
                        modalContent[isModalOpenFor].handleDelete()
                    }}
                    handleHideModal={this.handleHideModal}
                    {...modalContent[isModalOpenFor]}
                />
            </Modal>
        )

        return (
            <Fragment>
                {modal}
                <Form
                    {...this.props}
                    isSubmitting={isSubmitting}
                    isNew={isNew}
                    initialValues={this.getInitialValues()}
                    productName={productName}
                    productId={productId}
                    formFields={formFields}
                    isDuplicateProductModalShown={isDuplicateProductModalShown}
                    handleSubmit={this.handleSubmit}
                    setInitialValues={this.setInitialValues}
                    handleSubmitForm={this.handleSubmitForm}
                    handleDeleteNote={this.handleDeleteNote}
                    handleSetDuplicateProductModal={this.handleSetDuplicateProductModal}
                    addNoteParams={{
                        id: this.productId,
                        type: "product",
                        successAction: productActions.getProductById(this.productId)
                    }}
                    mainImageId={(dndOrder || [])[0] || null}
                    handlePassUploadedImages={this.handlePassUploadedImages}
                    handleDndOrder={this.handleDndOrder}
                    handleDeleteFromSavedFiles={this.handleDeleteFromSavedFiles}
                    handleShowDeleteModal={() => this.handleShowModalFor("delete")}
                    handleShowRestoreModal={() => this.handleShowModalFor("restore")}
                    handleBackToProduct={this.handleBackToProductOrShowModal}
                    handleShowModalIfCombinationEdited={this.handleShowModalIfCombinationEdited}
                />
            </Fragment>
        )
    }

    handleSubmitForm = () => {}

    handleSubmit = (values, formikProps) => {
        const {
            history,
            isEdit,
            isNew,
            resetCurrentProducts,
            showSuccessNotification,
            showErrorNotification
        } = this.props
        const { dndOrder } = this.state

        if (!Number(values.in_stock)) {
            values.in_stock = "0"
        }
        const data = {
            ...values,
            inventory: values.inventory.filter(item => !!item.location || !!item.tag || !!item.origin || !!item.note),
            available_on_web: !values.verified ? false : values.available_on_web,
            categories: values.categories
                .filter(item => item.length)
                .map(item => item.filter(i => i.id))
                .reduce((acc, item) => {
                    acc.push(item.slice().pop())
                    return acc
                }, [])
                .filter(i => i),
            images: values.images
                .slice()
                .map(m => m.id.toString())
                .sort((a, b) => dndOrder.indexOf(a) - dndOrder.indexOf(b)),
            features: values.features
                ? values.features
                      .filter(item => item.feature_id && item.feature_value_id)
                      .map(item => ({
                          id: item.feature_id,
                          feature_value_id: item.feature_value_id
                      }))
                : [],
            multiplier: values.is_multiplier
                ? typeof values.multiplier === "number"
                    ? values.multiplier
                    : (values.multiplier || "").replace(",", ".")
                : "",
            price: !values.is_multiplier
                ? typeof values.price === "number"
                    ? values.price
                    : (values.price || "").replace(",", ".")
                : "",
            wholesale_cost:
                typeof values.wholesale_cost === "number"
                    ? values.wholesale_cost
                    : (values.wholesale_cost || "").replace(",", "."),
            discount: typeof values.discount === "number" ? values.discount : (values.discount || "").replace(",", "."),
            specification: idx(values, _ => _.specification.id),
            clearance: values.clearance
        }

        this.setState({
            isSubmitting: true
        })

        const request = isEdit ? fetch.patchRAW("/products/" + this.productId, data) : fetch.postRAW("/products", data)

        request
            .then(
                response => {
                    showSuccessNotification()

                    if (isNew) {
                        response.json().then(json => {
                            return history.replace(
                                generatePath("/products/:productId?/:action", {
                                    productId: json.data.id,
                                    action: "edit"
                                })
                            )
                        })
                    }

                    resetCurrentProducts()
                },
                response => {
                    if (response.errors) {
                        formikProps.setErrors(response.errors)
                        scrollToError(response.errors)
                    } else {
                        scrollToTop()
                    }

                    showErrorNotification()
                }
            )
            .finally(() => {
                this.setState({
                    isSubmitting: false
                })
            })
    }

    handlePassUploadedImages = (images, ctx) => {
        const formattedImages = images.map(({ id, url, title }) => ({ id, url, name: title }))

        ctx.setFieldValue("images", [...ctx.values.images, ...formattedImages])
    }

    handleDeleteFromSavedFiles = (id, ctx) => {
        const { dndOrder } = this.state

        const otherThan = id => value => value.id !== id
        const byDndOrder = (a, b) => dndOrder.indexOf(a.id.toString()) - dndOrder.indexOf(b.id.toString())
        const newValues = id === "all" ? [] : ctx.values.images.filter(otherThan(id)).sort(byDndOrder)

        ctx.setFieldValue("images", newValues)
    }

    handleDndOrder = dndOrder => {
        return this.setState({ dndOrder })
    }

    handleDeleteNote = ({ noteId, contextId }) => {
        this.props.deleteNote({
            noteId,
            successAction: productActions.getProductById(contextId)
        })
    }

    handleDeleteProduct = () => {
        const {
            clearState,
            history,
            match: {
                params: { productId }
            }
        } = this.props

        this.setState(
            {
                modalIsLoading: true
            },
            () => {
                fetch.deleteRAW(`/product/${productId}`).then(() => {
                    setTimeout(() => {
                        this.setState(
                            {
                                modalIsLoading: false
                            },
                            () => {
                                clearState()
                                history.push("/products")
                            }
                        )
                    }, 2000)
                })
            }
        )
    }

    handleRestoreProduct = () => {
        const {
            getProductById,
            showErrorNotification,
            showSuccessNotification,
            match: {
                params: { productId }
            }
        } = this.props

        this.setState({ modalIsLoading: true }, () => {
            fetch
                .patchRAW(`/products/${productId}/restore`)
                .then(() => {
                    setTimeout(() => {
                        getProductById(this.productId).then(({ data }) => {
                            this.setState({
                                productName: data.name,
                                isModalOpenFor: "",
                                modalIsLoading: false
                            })
                            showSuccessNotification({ title: "Restored" })
                        })
                    }, 2000)
                })
                .catch(() => showErrorNotification())
        })
    }

    handleBackToProduct = () => {
        const { history, match } = this.props
        history.push(`/products/${match.params.productId}`)
    }

    handleBackToProductOrShowModal = dirty => {
        const { isCombinationEdited } = this.props
        const isFormEdited = dirty || isCombinationEdited
        isFormEdited ? this.handleShowModalFor("backToProduct") : this.handleBackToProduct()
    }

    handleShowModalIfCombinationEdited = event => {
        const { isCombinationEdited } = this.props

        if (isCombinationEdited) {
            event.preventDefault()
            this.handleShowModalFor("changeTabFromCombination")
        }
    }

    handleChangeTabFromCombination = () => {
        const { history, match } = this.props
        const productId = match.params.productId

        this.handleHideModal()

        if (productId) {
            return history.push(
                generatePath("/products/:productId/edit", {
                    productId
                })
            )
        }

        return history.push(generatePath("/products/add"))
    }

    handleShowModalFor = isModalOpenFor => this.setState({ isModalOpenFor })

    handleHideModal = () => this.handleShowModalFor("")
}

const mapStateToProps = state => {
    return {
        brands: state.db.brands.list.data,
        product: state.db.products.current,
        countriesList: state.clientStore.countriesList,
        categories: state.db.categories.list,
        isCombinationEdited: state.ui.combinationsCreator.unsavedChanges
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProductById: id => dispatch(productActions.getProductById(id)),
        getProductConditions: () => dispatch(productActions.getProductConditions()),
        deleteNote: ({ noteId, successAction }) => dispatch(noteActions.deleteNote({ noteId, successAction })),
        clearState: () => dispatch(productActions.resetProducts()),
        showSuccessNotification: data => dispatch(notificationActions.showSuccessNotification(data)),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification()),
        resetCurrentProducts: () => dispatch(productActions.resetCurrentProducts())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withForm(FormContainer, { model: models.Product }))
