import React, { useState } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"
import { deleteShippingMethod } from "actions/shippingMethods"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import styles from "./DeleteShippingMethod.module.css"
import withStyles from "HOC/withStyles"
import { Button, Modal } from "ui"
const { Actions, Body, Header } = Modal

const DeleteShippingMethod = ({ cx, handleClose, id, name }) => {
    const [isSubmitting, setSubmitting] = useState(false)

    const dispatch = useDispatch()

    const handleSubmit = async () => {
        setSubmitting(true)

        try {
            await dispatch(deleteShippingMethod(id))
            dispatch(showSuccessNotification({ title: "Deleted" }))
            handleClose()
        } catch (data) {
            if (data && data.message) {
                showErrorNotification(data.message)
            }
            setSubmitting(false)
        }
    }

    return (
        <Modal closeModal={handleClose} isOpen={true} preventClickOutside={true}>
            <Header>Delete this shipping method?</Header>
            <Body>
                <div className={cx("content")}>
                    <p>
                        Are you sure you want to delete “<span className={cx("method")}>{name}</span>”? This action
                        cannot be reversed.
                    </p>
                </div>
            </Body>
            <Actions>
                <div className={cx("buttons")}>
                    <Button className="close-button" label="Cancel" onClick={handleClose} type="button" />
                    <Button
                        className="warning-button"
                        isLoading={isSubmitting}
                        label="Delete"
                        onClick={handleSubmit}
                        type="submit"
                    />
                </div>
            </Actions>
        </Modal>
    )
}

DeleteShippingMethod.defaultProps = {
    name: ""
}

DeleteShippingMethod.propTypes = {
    handleClose: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
}

export default withStyles(DeleteShippingMethod, styles)
