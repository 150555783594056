export const mergeCx = (baseCx, customCx) => {
    return (...styles) => {
        let classes = []

        classes.push(baseCx(styles))

        if (typeof customCx === "function") {
            classes.push(customCx(styles))
        }

        return classes.join(" ")
    }
}
