import React, { Fragment } from "react"
import ReactSvg from "react-svg"
import moment from "moment"
import idx from "idx"
import { Link } from "react-router-dom"
import { Decimal } from "decimal.js"
import _isNil from "lodash/isNil"

import { Status, ActionButton } from "ui"
import { TableDateCell, ProgressDot } from "@butterfly-frontend/ui"
import { Paid } from "./components"

import { getInvoices, deleteInvoice } from "actions/invoices"
import { getPurchaseOrders, deletePurchaseOrder } from "actions/purchaseOrders"
import { getOrders } from "actions/orders"
import { getPayments } from "actions/payments"

import { formatPrice, formatPriceByComa, roundNumber } from "helpers/units"
import { formatDate } from "helpers/date"
import { getRouteByOrderType } from "helpers/urls"
import { PERMISSIONS, READABLE_STANDARD_DATE, SALE_CHANNELS } from "constants/index"

import orderIcon from "assets/order.svg"
import documentIcon from "assets/document.svg"
import clockIcon from "assets/clock.svg"
import expandIcon from "assets/expand.svg"
import paymentIcon from "assets/ico-wallet.svg"
import pencilIcon from "assets/pencil.svg"
import deleteIcon from "assets/close-blue.svg"

const listConfig = {
    sales: {
        getResource: searchParams => getOrders(toApiFormat(searchParams)),
        dbSelector: state => state.db.orders.list,
        name: "sales orders",
        singleName: "order",
        statusType: "sales",
        printType: "order",
        abbreviation: "SO",
        filterName: "order",
        listIcon: orderIcon,
        export: { objectType: "orders", type: "sales" },
        table: {
            "order id": {
                sortBy: "id",
                style: { width: "8%" },
                renderCell: ({ cx, id, uuid }) => (
                    <p className={cx("table-id")}>
                        <Link to={`/orders/sales-orders/${id}`}>{uuid}</Link>
                    </p>
                )
            },
            client: {
                style: { width: "13%" },
                renderCell: ({ cx, client }) => {
                    const [clientName, isVip, companyName] = [
                        idx(client, _ => _.full_name) || "",
                        idx(client, _ => _.vip) || false,
                        idx(client, _ => _.company_name)
                    ]

                    return (
                        <div className={cx("table-description-cell")}>
                            <p className={cx("table-description-cell-type")}>
                                {clientName}
                                {isVip && <span className={cx("vip")}>vip</span>}
                            </p>
                            {companyName && <p className={cx("table-description-cell-value")}>{companyName}</p>}
                        </div>
                    )
                }
            },
            "Sale channel": {
                style: { width: "11%" },
                renderCell: ({ cx, sale_channel: saleChannel }) => {
                    const type = idx(saleChannel, _ => _.type) || "-"
                    const value = idx(saleChannel, _ => _.value.name)

                    return (
                        <div className={cx("table-description-cell")}>
                            <p
                                className={cx(
                                    "table-description-cell-type",
                                    type === SALE_CHANNELS.WEB ? "sale-channel-web" : undefined
                                )}
                            >
                                {type}
                            </p>
                            {value && <p className={cx("table-description-cell-value")}>{value}</p>}
                        </div>
                    )
                }
            },
            status: {
                style: { width: "12%" },
                renderCell: ({ cx, status }) => (
                    <div className={cx("statusWrapper")}>
                        <Status.Label type="sales" status={status} />
                    </div>
                )
            },
            invoiced: {
                style: { width: "7.5%", padding: 0, textAlignLast: "center" },
                renderCell: ({ cx, processing_states }) => (
                    <div className={cx("progressIndicatorWrapper")}>
                        <ProgressDot state={processing_states.invoiced} />
                    </div>
                )
            },
            paid: {
                style: { width: "7.5%", padding: 0, textAlignLast: "center" },
                renderCell: ({ cx, processing_states }) => (
                    <div className={cx("progressIndicatorWrapper")}>
                        <ProgressDot state={processing_states.paid} />
                    </div>
                )
            },
            packed: {
                style: { width: "7.5%", padding: 0, textAlignLast: "center" },
                renderCell: ({ cx, processing_states }) => (
                    <div className={cx("progressIndicatorWrapper")}>
                        <ProgressDot state={processing_states.packed} />
                    </div>
                )
            },
            shipped: {
                style: { width: "7.5%", padding: 0, textAlignLast: "center" },
                renderCell: ({ cx, processing_states }) => (
                    <div className={cx("progressIndicatorWrapper")}>
                        <ProgressDot state={processing_states.shipped} />
                    </div>
                )
            },
            created: {
                sortBy: "created_at",
                classNames: { td: "isIcon" },
                style: { width: "13%" },
                renderCell: ({ cx, created_at }) => (
                    <TableDateCell classes={{ root: cx("soDateCell") }}>{formatDate(created_at)}</TableDateCell>
                )
            },
            total: {
                sortBy: "total",
                classNames: { th: "textRight", td: "textRight" },
                style: { width: "13%" },
                renderCell: ({ cx, calculations: { total, balance }, status }) => (
                    <div className="table-total-wrapper">
                        <p className={cx("table-total")}>{formatPrice(total) || "-"}</p>
                        {status === "awaiting_payment" && balance < 0 && (
                            <p className={cx("table-due")}>Due: {formatPrice(Math.abs(balance))}</p>
                        )}
                    </div>
                )
            }
        }
    },
    invoices: {
        getResource: searchParams => getInvoices(toApiFormat(searchParams)),
        documentEndpoint: "invoices",
        delete: deleteInvoice,
        dbSelector: state => state.db.invoices.list,
        name: "invoices",
        singleName: "invoice",
        statusType: "payment",
        notesType: "invoice",
        printType: "invoice",
        abbreviation: "Invoice",
        filterName: "invoice",
        routerStateKey: "invoiceId",
        listIcon: documentIcon,
        export: { objectType: "invoices" },
        table: {
            "invoice id": {
                sortBy: "id",
                style: { width: "9%" },
                renderCell: ({ cx, uuid, id, toggleExpanded }) => (
                    <p className={cx("table-id", "clickable-table-id")} onClick={() => toggleExpanded(id)}>
                        {uuid}
                    </p>
                )
            },
            client: {
                style: { width: "13%" },
                renderCell: ({ cx, client }) => {
                    const [clientName, isVip] = [idx(client, _ => _.full_name) || "", idx(client, _ => _.vip) || false]
                    return (
                        <p className={cx("table-name")}>
                            {clientName}
                            {isVip && <span className={cx("vip")}>vip</span>}
                        </p>
                    )
                }
            },
            employee: {
                style: { width: "10%" },
                renderCell: ({ cx, user }) => <p className={cx("table-employee")}>{user}</p>
            },
            company: {
                style: { width: "15%" },
                renderCell: ({ cx, client }) => (
                    <p className={cx("table-company")}>{idx(client, _ => _.company_name) || ""}</p>
                )
            },
            created: {
                sortBy: "created_at",
                classNames: { td: "isIcon" },
                style: { width: "10%" },
                renderCell: ({ cx, created_at }) => (
                    <div className={cx("table-created-at-wrapper")}>
                        <img src={clockIcon} alt="icon" />
                        <p>{moment(created_at).format("DD MMM YYYY")}</p>
                    </div>
                )
            },
            total: {
                sortBy: "total",
                style: { width: "10%", textAlign: "right", textAlignLast: "right", paddingRight: 16 },
                renderCell: ({ cx, calculations: { total, balance }, status }) => (
                    <div className={cx("table-total-wrapper")}>
                        <p className={cx("table-total")}>
                            $ {formatPriceByComa(total, true) + "."}
                            {formatPriceByComa(total)}
                        </p>
                        {status === "awaiting_payment" && Math.abs(balance) > 0 && (
                            <p className={cx("table-due")}>
                                Due: $ {formatPriceByComa(Math.abs(balance), true) + "."}
                                {formatPriceByComa(balance)}
                            </p>
                        )}
                    </div>
                )
            },
            status: {
                classNames: { th: "statusHeader" },
                style: { width: "13%" },
                renderCell: ({ cx, status, payment_due_at }) => (
                    <div className={cx("statusWrapper")}>
                        <Status.Label
                            type="payment"
                            status={status}
                            isAfterDue={status !== "paid" && moment().isAfter(payment_due_at)}
                        />
                    </div>
                )
            },
            "": {
                classNames: { td: "textRight" },
                style: { width: "3%" },
                renderCell: ({ cx, id, isExpanded, toggleExpanded }) => (
                    <button className={cx("expandButton")} onClick={() => toggleExpanded(id)}>
                        <ReactSvg className={cx("expandIcon", { isExpanded })} src={expandIcon} />
                    </button>
                )
            }
        },
        itemsTable: order => {
            const isRental = order.type === "rental"
            return {
                "#": {
                    style: { width: 30, paddingLeft: 0 },
                    renderCell: ({ index }) => `${index + 1}.`
                },
                "Item Name": {
                    style: { width: "calc(50% - 30px)", paddingRight: 20 },
                    renderCell: ({ cx, fake_name, note }) => (
                        <Fragment>
                            <div className={cx("name")}>{fake_name || "-"}</div>
                            {!!note && <div className={cx("note")}>NOTE: {note}</div>}
                        </Fragment>
                    )
                },
                "Room Name": !isRental && {
                    style: { width: "15%" },
                    renderCell: ({ room_name }) => room_name || "-"
                },
                "Item price": {
                    style: { width: "10%", textAlign: "center", textAlignLast: "center" },
                    renderCell: ({ price }) => formatPrice(price) || "-"
                },
                Qty: {
                    style: { width: "5%", textAlign: "center", textAlignLast: "center" },
                    renderCell: ({ quantity }) => quantity || 0
                },
                Discount: !isRental && {
                    style: { width: "10%", textAlign: "center", textAlignLast: "center" },
                    renderCell: ({ discount_value }) => `${Number(discount_value)}%` || 0
                },
                Tax: !isRental && {
                    style: { width: "10%", textAlign: "center", textAlignLast: "center" },
                    renderCell: ({ calculations: { tax_code } }) =>
                        `${roundNumber(Number(new Decimal(tax_code).times(100)), 6)}%` || "-"
                },
                "Item total": {
                    style: { width: "10%", textAlign: "end", textAlignLast: "end", fontWeight: 500 },
                    renderCell: ({ total, calculations }) => {
                        const value = total || (calculations && calculations.total)
                        return formatPrice(value) || "-"
                    }
                }
            }
        }
    },
    "purchase-orders": {
        getResource: searchParams => getPurchaseOrders(toApiFormat(searchParams)),
        documentEndpoint: "purchase-orders",
        delete: deletePurchaseOrder,
        dbSelector: state => state.db.purchaseOrders.list,
        name: "purchase orders",
        singleName: "purchase order",
        statusType: "purchase_order",
        notesType: "purchase_order",
        printType: "purchase_order",
        abbreviation: "PO",
        filterName: "PO",
        routerStateKey: "purchaseOrderId",
        listIcon: documentIcon,
        getSendableEmail: po => idx(po, _ => _.brand.po_email),
        export: { objectType: "purchase_orders" },
        table: {
            "po id": {
                sortBy: "id",
                style: { width: "auto" },
                renderCell: ({ cx, uuid, id, toggleExpanded }) => (
                    <p className={cx("table-id", "clickable-table-id")} onClick={() => toggleExpanded(id)}>
                        {uuid}
                    </p>
                )
            },
            vendor: {
                style: { width: "13%" },
                renderCell: ({ cx, brand_name }) => <p className={cx("table-company")}>{brand_name}</p>
            },
            client: {
                style: { width: "14%" },
                renderCell: ({ cx, client }) => {
                    const [clientName, isVip] = [idx(client, _ => _.full_name) || "", idx(client, _ => _.vip) || false]
                    return (
                        <p className={cx("table-name")}>
                            {clientName}
                            {isVip && <span className={cx("vip")}>vip</span>}
                        </p>
                    )
                }
            },
            created: {
                sortBy: "created_at",
                classNames: { td: "isIcon" },
                style: { width: "13%" },
                renderCell: ({ created_at }) => (
                    <Fragment>
                        <img src={clockIcon} alt="icon" />
                        <p>{moment(created_at).format("DD MMM YYYY")}</p>
                    </Fragment>
                )
            },
            status: {
                style: { width: "14%" },
                renderCell: ({ cx, status }) => (
                    <div className={cx("statusWrapper")}>
                        <Status.Label type="purchase_order" status={status} />
                    </div>
                )
            },
            paid: {
                style: { width: "14%" },
                renderCell: ({ cx, uuid, id, paid, paid_at }) => (
                    <Paid orderUuid={uuid} orderId={id} paid={paid} paidAt={paid_at} />
                )
            },
            "": {
                classNames: { td: "textRight" },
                style: { width: "5%" },
                renderCell: ({ cx, id, isExpanded, toggleExpanded }) => (
                    <button className={cx("expandButton")} onClick={() => toggleExpanded(id)}>
                        <ReactSvg className={cx("expandIcon", { isExpanded })} src={expandIcon} />
                    </button>
                )
            }
        },
        itemsTable: () => ({
            "#": {
                style: { width: 30, paddingLeft: 0 },
                renderCell: ({ index }) => `${index + 1}.`
            },
            "Item Name": {
                style: { width: "30%", paddingRight: 20 },
                renderCell: ({ cx, name, note }) => (
                    <Fragment>
                        <div className={cx("name")}>{name || "-"}</div>
                        {!!note && <div className={cx("note")}>NOTE: {note}</div>}
                    </Fragment>
                )
            },
            "Manufacture ID": {
                style: { width: "10%", paddingRight: 20 },
                renderCell: ({ manufacturer_id }) => (manufacturer_id ? manufacturer_id : "-")
            },
            Parameters: {
                style: { width: "calc(25% - 30px)" },
                renderCell: ({ cx, attributes }) => (
                    <div className={cx("attributes")}>
                        {attributes
                            ? Object.entries(attributes).map(([key, value]) => (
                                  <span key={key} className={cx("attribute")}>
                                      {key}: <span>{value}</span>
                                  </span>
                              ))
                            : "-"}
                    </div>
                )
            },
            "Item whole sale price": {
                style: { width: "calc(10% - 20px)" },
                renderCell: ({ cx, wholesale_cost }) => (
                    <div>
                        <span>{_isNil(wholesale_cost) ? "-" : formatPrice(wholesale_cost)}</span>
                    </div>
                )
            },
            Qty: {
                style: { width: "5%", textAlign: "center", textAlignLast: "center" },
                renderCell: ({ quantity }) => quantity || 0
            },
            Discount: {
                style: { width: "5%", textAlign: "center", textAlignLast: "center" },
                renderCell: ({ wholesale_discount }) =>
                    wholesale_discount ? `${roundNumber(parseFloat(wholesale_discount))}%` : "-"
            },
            "Item total": {
                style: { width: "10%", textAlign: "end", textAlignLast: "end", fontWeight: 500 },
                renderCell: ({ wholesale_total, calculations }) => {
                    const value = wholesale_total || (calculations && calculations.total)
                    return formatPrice(value) || "-"
                }
            }
        })
    },
    memo: {
        getResource: searchParams => getOrders(toApiFormat(searchParams)),
        dbSelector: state => state.db.orders.list,
        name: "memos",
        singleName: "memo",
        statusType: "memo",
        printType: "order",
        abbreviation: "Memo",
        filterName: "order",
        listIcon: documentIcon,
        export: { objectType: "orders", type: "memo" },
        table: {
            "memo id": {
                sortBy: "id",
                style: { width: "auto" },
                renderCell: ({ cx, id, uuid }) => (
                    <p className={cx("table-id")}>
                        <Link to={`/orders/memo/${id}`}>{uuid}</Link>
                    </p>
                )
            },
            client: {
                style: { width: "20%" },
                renderCell: ({ cx, client }) => {
                    const [clientName, isVip] = [idx(client, _ => _.full_name) || "", idx(client, _ => _.vip) || false]
                    return (
                        <p className={cx("table-name")}>
                            {clientName}
                            {isVip && <span className={cx("vip")}>vip</span>}
                        </p>
                    )
                }
            },
            company: {
                style: { width: "25%" },
                renderCell: ({ cx, client }) => (
                    <p className={cx("table-company")}>{idx(client, _ => _.company_name) || ""}</p>
                )
            },
            status: {
                style: { width: "20%" },
                renderCell: ({ cx, status }) => (
                    <div className={cx("statusWrapper")}>
                        <Status.Label type="memo" status={status} />
                    </div>
                )
            },
            created: {
                sortBy: "created_at",
                classNames: { td: "isIcon" },
                style: { width: "15%" },
                renderCell: ({ created_at }) => (
                    <Fragment>
                        <img src={clockIcon} alt="icon" />
                        <p>{moment(created_at).format("DD MMM YYYY")}</p>
                    </Fragment>
                )
            }
        }
    },
    rental: {
        getResource: searchParams => getOrders(toApiFormat(searchParams)),
        dbSelector: state => state.db.orders.list,
        name: "rental agreements",
        singleName: "rental",
        statusType: "rental",
        printType: "order",
        abbreviation: "RA",
        filterName: "order",
        listIcon: documentIcon,
        export: { objectType: "orders", type: "rental" },
        table: {
            "rental id": {
                sortBy: "id",
                style: { width: "auto" },
                renderCell: ({ cx, id, uuid }) => (
                    <p className={cx("table-id")}>
                        <Link to={`/orders/rental-agreements/${id}`}>{uuid}</Link>
                    </p>
                )
            },
            client: {
                style: { width: "20%" },
                renderCell: ({ cx, client }) => {
                    const [clientName, isVip] = [idx(client, _ => _.full_name) || "", idx(client, _ => _.vip) || false]
                    return (
                        <p className={cx("table-name")}>
                            {clientName}
                            {isVip && <span className={cx("vip")}>vip</span>}
                        </p>
                    )
                }
            },
            company: {
                style: { width: "25%" },
                renderCell: ({ cx, client }) => (
                    <p className={cx("table-company")}>{idx(client, _ => _.company_name) || ""}</p>
                )
            },
            status: {
                style: { width: "20%" },
                renderCell: ({ cx, status }) => (
                    <div className={cx("statusWrapper")}>
                        <Status.Label type="rental" status={status} />
                    </div>
                )
            },
            created: {
                sortBy: "created_at",
                classNames: { td: "isIcon" },
                style: { width: "15%" },
                renderCell: ({ created_at }) => (
                    <Fragment>
                        <img src={clockIcon} alt="icon" />
                        <p>{moment(created_at).format("DD MMM YYYY")}</p>
                    </Fragment>
                )
            }
        }
    },
    payments: {
        getResource: searchParams => getPayments(toApiFormat(searchParams)),
        dbSelector: state => state.db.payments,
        permissionContext: PERMISSIONS.context.PAYMENTS,
        name: "payments",
        singleName: "payment",
        filterName: "payment",
        listIcon: paymentIcon,
        export: { objectType: "payments" },
        table: {
            amount: {
                sortBy: "amount",
                style: { width: "10%", fontWeight: 500 },
                renderCell: ({ cx, amount }) => <div className={cx("paymentsCell")}>{formatPrice(amount)}</div>
            },
            client: {
                sortBy: "client.first_name",
                style: { width: "10%" },
                renderCell: ({ cx, client }) => (
                    <div className={cx("paymentsCell", "client")}>
                        <div>{idx(client, _ => _.full_name) || "-"}</div>
                        <div>{idx(client, _ => _.company_name) || "-"}</div>
                    </div>
                )
            },
            "payment date": {
                sortBy: "date",
                style: { width: "10%" },
                renderCell: ({ cx, date }) => (
                    <div className={cx("paymentsCell", "date")}>
                        <ReactSvg src={clockIcon} />
                        {moment(date).format(READABLE_STANDARD_DATE)}
                    </div>
                )
            },
            method: {
                sortBy: "payment_method_id",
                style: { width: "10%" },
                renderCell: ({ cx, method }) => (
                    <div className={cx("paymentsCell")}>{idx(method, _ => _.name) || "-"}</div>
                )
            },
            source: {
                style: { width: "10%" },
                renderCell: ({ cx, order, project }) => {
                    return (
                        <div className={cx("paymentsCell")}>
                            {(order || project) && (
                                <Link
                                    to={{
                                        pathname: project
                                            ? `/projects/${project}`
                                            : `/orders/${getRouteByOrderType(order.type)}/${order.id}`
                                    }}
                                >
                                    {project ? "Project" : order.uuid}
                                </Link>
                            )}
                        </div>
                    )
                }
            },
            invoice: {
                style: { width: "10%" },
                renderCell: ({ cx, invoice, order = {} }) => (
                    <div className={cx("paymentsCell")}>
                        {!!invoice ? (
                            <Link
                                to={{
                                    pathname: `/orders/${getRouteByOrderType(order.type)}/${order.id}/invoices`,
                                    state: { invoiceId: invoice.id }
                                }}
                            >
                                {invoice.uuid}
                            </Link>
                        ) : (
                            "No invoice"
                        )}
                    </div>
                )
            },
            "payment note": {
                style: { width: "15%" },
                renderCell: ({ cx, note }) => <div className={cx("paymentsCell", "note")}>{note}</div>
            },
            "": {
                style: { width: 100 },
                renderCell: props => {
                    const { cx, openEditModal, openDeleteModal, id, amount, canUserEdit, canUserDelete } = props
                    return (
                        <div className={cx("actions")}>
                            {canUserEdit && (
                                <ActionButton icon={pencilIcon} label="Edit" handleClick={() => openEditModal(props)} />
                            )}
                            {canUserDelete && (
                                <ActionButton
                                    icon={deleteIcon}
                                    label="Delete"
                                    handleClick={() => openDeleteModal({ id, amount })}
                                />
                            )}
                        </div>
                    )
                }
            }
        }
    }
}

export function toApiFormat(searchParams) {
    return Object.entries(searchParams).reduce((acc, [key, value]) => {
        if (!value || (Array.isArray(value) && !value.length)) {
            return acc
        }

        if (["user", "client", "brand", "project", "payment_method"].includes(key)) {
            return { ...acc, [`${key}_id`]: value.id }
        }

        if (key === "moreFilters") {
            return { ...acc, ...toApiFormat(value) }
        }

        const newValue = key === "statuses" ? value.map(({ id }) => id) : value
        return { ...acc, [key]: newValue }
    }, {})
}

export default listConfig
