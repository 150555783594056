export default {
    headerColumns: [
        {
            name: "NO.",
            width: "10%"
        },
        {
            name: "VALUE",
            width: "70%"
        }
    ],
    dataColumns: [
        {
            name: "value",
            width: "70%"
        }
    ]
}
