import * as constants from "./../constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = () => {
    const currentTime = new Date().getTime()

    return {
        data: [],
        meta: {},
        fetchStatus: fetchStatus.initialState(),
        lastModifiedAt: currentTime,
        lastServerUpdatedAt: currentTime,
        lastServerCompletedAt: currentTime,
        lock_counter: 0
    }
}

function list(state = initialState(), action) {
    switch (action.type) {
        case constants.GET_PRODUCTS_START:
            return {
                ...state,
                fetchStatus: fetchStatus.requestState()
            }

        case constants.GET_PRODUCTS_SUCCESS:
            let productsList = action.payload.data.data ? action.payload.data.data : []
            let productsMeta = action.payload.data.meta ? action.payload.data.meta : {}

            if (action.payload.data.products && action.payload.data.products.data) {
                productsList = action.payload.data.products.data
                productsMeta = action.payload.data.products.meta
            }

            return {
                ...state,
                data: productsList,
                meta: productsMeta,
                lock_counter: 0,
                fetchStatus: fetchStatus.successState()
            }

        case constants.GET_PRODUCTS_ERROR:
            return {
                ...state,
                data: [],
                meta: {},
                lock_counter: 0,
                fetchStatus: { ...fetchStatus.failureState(), error: (action.payload || {}).error || null }
            }

        case constants.RESET_PRODUCTS:
            return {
                ...initialState()
            }

        case constants.ADD_PRODUCT:
            return {
                ...state,
                data: [action.payload.product, ...state.data],
                lastModifiedAt: new Date().getTime()
            }

        case constants.REMOVE_PRODUCT:
            return {
                ...state,
                data: state.data.filter(product => product.id !== action.payload.product.id),
                lastModifiedAt: new Date().getTime()
            }

        case constants.ADD_PRODUCTS:
            return {
                ...state,
                data: [
                    ...action.payload.products.filter(
                        newItem => !state.data.find(currentItem => currentItem.id === newItem.id)
                    ),
                    ...state.data
                ],
                lastModifiedAt: new Date().getTime()
            }

        case constants.RESET_PRODUCTS_FETCH_STATUS:
            return {
                ...state,
                fetchStatus: fetchStatus.initialState()
            }

        case constants.INCREMENT_COUNTER:
            return {
                ...state,
                lock_counter: state.lock_counter + 1
            }

        case constants.DECREMENT_COUNTER:
            return {
                ...state,
                lock_counter: state.lock_counter - 1
            }

        case constants.RESET_LOCK:
            return {
                ...state,
                lock_counter: 0
            }

        case constants.SET_LAST_SERVER_UPDATED_AT:
            return {
                ...state,
                lastServerUpdatedAt: action.payload
            }

        case constants.SET_LAST_SERVER_COMPLETED_AT:
            return {
                ...state,
                lastServerCompletedAt: action.payload
            }

        default:
            return state
    }
}

export default list
