import * as constants from "constants/index"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"
import idx from "idx"

function shippingMethods(state = { data: [], meta: {}, fetchStatus: initialState(), fromCache: false }, action) {
    switch (action.type) {
        case constants.GET_SHIPPING_METHODS_START:
            return { data: [], meta: {}, fetchStatus: requestState() }

        case constants.GET_SHIPPING_METHODS_SUCCESS:
            const dataResource = idx(action, _ => _.payload.data) || []
            return {
                data: dataResource,
                meta: idx(action, _ => _.payload.meta) || {},
                fetchStatus: successState(),
                fromCache: idx(action, _ => _.fromCache) || false
            }

        case constants.GET_SHIPPING_METHODS_ERROR:
            return { data: {}, meta: {}, fetchStatus: { ...failureState(), error: action.payload } }

        case constants.CREATE_SHIPPING_METHOD_SUCCESS:
            return (() => {
                const {
                    payload: { data: paymentMethod }
                } = action
                const { data } = state

                return {
                    ...state,
                    data: [...data, paymentMethod]
                }
            })()

        case constants.UPDATE_SHIPPING_METHOD_SUCCESS:
            return (() => {
                const {
                    payload: { data: paymentMethod }
                } = action
                const { data } = state
                return {
                    ...state,
                    data: data.map(obj => (obj.id === paymentMethod.id ? paymentMethod : obj))
                }
            })()

        case constants.DELETE_SHIPPING_METHOD_SUCCESS:
            return (() => {
                const {
                    payload: { paymentMethodId }
                } = action
                const { data } = state
                return { ...state, data: data.filter(obj => obj.id !== paymentMethodId) }
            })()

        default:
            return state
    }
}

export default shippingMethods
