import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"
import Dropzone from "ui/Dropzone"
import idx from "idx"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import withUploader from "HOC/withUploader"
import withStyles from "HOC/withStyles"

import styles from "./PhotoUploader.css"
import uploadIco from "assets/add.svg"

const UPLOAD_STATUS = {
    INIT: "INIT",
    UPLOADING: "UPLOADING",
    UPLOADED: "UPLOADED"
}

const PhotoUploader = props => {
    const {
        cx,
        className,
        avatar,
        handleRemovePhoto,
        uploader: { actions, state }
    } = props

    const [uploadStatus, setUploadStatus] = useState(avatar ? UPLOAD_STATUS.UPLOADED : UPLOAD_STATUS.INIT)

    useEffect(() => {
        if (state.isUploading) {
            return setUploadStatus(UPLOAD_STATUS.UPLOADING)
        }

        if (state.isSuccess || avatar) {
            return setUploadStatus(UPLOAD_STATUS.UPLOADED)
        }

        setUploadStatus(UPLOAD_STATUS.INIT)
    }, [state.isUploading, state.isSuccess, avatar])

    const handleRemove = evt => {
        evt.stopPropagation()
        actions.handleRemove()
        handleRemovePhoto()
    }

    return (
        <div className={cx("root", uploadStatus, { empty: uploadStatus === UPLOAD_STATUS.INIT }, className)}>
            {uploadStatus === UPLOAD_STATUS.UPLOADED && (
                <div className={cx("outlineButton")} onClick={evt => handleRemove(evt)}>
                    <span>Change image</span>
                </div>
            )}
            <div className={cx("content-wrapper")}>
                <div className={cx("content")}>
                    {uploadStatus === UPLOAD_STATUS.INIT ? (
                        <div className={cx("dropzoneWrapper")}>
                            <Dropzone
                                isUploading={state.isUploading}
                                isSuccess={state.isSuccess}
                                progress={state.progress}
                                handleUpload={actions.handleUpload}
                                handleRemove={actions.handleRemove}
                                isMultiple={true}
                                acceptFormats="image/jpeg,image/png,image/tiff"
                            >
                                <div className={cx("dropzone")}>
                                    <ReactSVG src={uploadIco} className={cx("svgimage")} />
                                </div>
                            </Dropzone>
                        </div>
                    ) : (
                        <div
                            className={cx("image", { "margin-top": uploadStatus !== UPLOAD_STATUS.UPLOADING })}
                            style={{ backgroundImage: `url(${idx(state, _ => _.preview) || avatar})` }}
                        >
                            {uploadStatus === UPLOAD_STATUS.UPLOADING && (
                                <div className={cx("progress")}>
                                    <SnakeLoader />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const ComposedPhotoUploader = withUploader(withStyles(PhotoUploader, styles))

ComposedPhotoUploader.propTypes = {
    className: PropTypes.string,
    avatar: PropTypes.string,
    handleRemovePhoto: PropTypes.func.isRequired,
    handleUploadSuccess: PropTypes.func.isRequired
}

export default ComposedPhotoUploader
