import React, { Fragment, useState, useEffect } from "react"

import fetch from "helpers/fetch"
import { getUserManagementTabs } from "components/Content/ContentMain/Settings/helpers"
import withPermissions from "HOC/withPermissions"
import { useFetch, useActions } from "hooks"

import { showErrorNotification, showSuccessNotification } from "actions/notification"

import ContentHeader from "components/Content/ContentHeader/index"
import EmailSignature from "./EmailSignature"

import { MENU, DEFAULT_SIGNATURE_KEY } from "constants/index"

const EmailSignatureContainer = props => {
    const [signatureId, setSignatureId] = useState(null)
    const [defaultSignature, setDefaultSignature] = useState("")

    const actions = useActions({ showErrorNotification, showSuccessNotification })

    useEffect(() => {
        fetchSettings()
    }, [])

    const [fetchSettings, fetchStatus] = useFetch({
        action: () => fetch.get("/settings"),
        onSuccess: ({ data }) => {
            const signature = data.find(({ key }) => key === DEFAULT_SIGNATURE_KEY)

            setDefaultSignature((signature && signature.value) || "")
            signature && setSignatureId(signature.id)
        },
        onError: () => actions.showErrorNotification()
    })

    const [handleSubmit, { isLoading: isSubmitting }] = useFetch({
        action: values =>
            signatureId
                ? fetch.patch(`/settings/${signatureId}`, {
                      key: DEFAULT_SIGNATURE_KEY,
                      value: values.defaultSignature
                  })
                : Promise.reject(),
        onSuccess: ({ data }) => {
            setDefaultSignature(data.value)
            actions.showSuccessNotification()
        },
        onError: () => actions.showErrorNotification()
    })

    return (
        <Fragment>
            <ContentHeader title={MENU.SETTINGS_USERS_MANAGEMENT} tabs={getUserManagementTabs(props)} />
            <EmailSignature
                fetchStatus={fetchStatus}
                isSubmitting={isSubmitting}
                defaultSignature={defaultSignature}
                onSubmit={handleSubmit}
            />
        </Fragment>
    )
}

export default withPermissions(EmailSignatureContainer)
