import React, { useEffect } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import withStyles from "HOC/withStyles"
import withUploader from "HOC/withUploader"

import { Dropzone } from "ui"

import imageIcon from "../../assets/image.svg"
import closeIcon from "assets/close-blue.svg"

import styles from "./styles.css"
import dropzoneStyles from "../../overrides/dropzoneStyles.css"

const Image = ({ cx, uploader: { state: uploadState, actions: uploadActions }, image, setImage, isError }) => {
    useEffect(() => {
        if (idx(uploadState, _ => _.addedFile.url)) {
            setImage({
                id: uploadState.addedFile.id,
                name: uploadState.addedFile.title,
                url: uploadState.addedFile.url
            })
        }
    }, [uploadState.isUploading, uploadState.isSuccess, uploadState.addedFile])

    const removeImage = () => {
        setImage(null)
    }

    const renderUpladingState = progress => (
        <div className={cx("dropzoneStateUploading")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    )

    const renderEmptyState = () => (
        <Dropzone
            isUploading={uploadState.isUploading}
            isSuccess={uploadState.isSuccess}
            progress={uploadState.progress}
            handleUpload={uploadActions.handleUpload}
            handleRemove={uploadActions.handleRemove}
            isMultiple={false}
            customStyles={dropzoneStyles}
        >
            <p className={cx("dropzoneStateEmpty")}>
                <img src={imageIcon} alt="videoIcon" />
                Upload image <span>(same height/width ratio as the video)</span>
            </p>
        </Dropzone>
    )

    const renderVideoState = () => (
        <div className={cx("dropzoneStateVideo")}>
            <img src={image.url} alt="ico" />
            <span>{image.name}</span>
            <button onClick={removeImage}>
                <img src={closeIcon} alt="close" />
            </button>
        </div>
    )

    return (
        <div
            className={cx("dropzoneWrapper", {
                dropzoneWrapperError: isError
            })}
        >
            {uploadState.isUploading
                ? renderUpladingState(uploadState.progress)
                : !!image
                ? renderVideoState()
                : renderEmptyState()}
        </div>
    )
}

Image.propTypes = {
    uploader: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    image: PropTypes.object,
    setImage: PropTypes.func.isRequired,
    isError: PropTypes.bool.isRequired,
    cx: PropTypes.func.isRequired
}

export default withUploader(withStyles(Image, styles))
