import React, { Component } from "react"
import { compose } from "redux"
import { withRouter } from "react-router-dom"

import { withStyles } from "ui/FilterableTable/HOC"
import { AppProvider } from "ui/FilterableTable"
import styles from "./FilterableTable.css"

class FilterableTable extends Component {
    static defaultProps = {
        handleInit: () => {},
        handleChange: () => {}
    }

    key = new Date().getTime()

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.location.search && this.props.location.search) {
            this.key = new Date().getTime()
        }
    }

    render() {
        const { cx, perPage, meta, fetchStatus, handleChange, items, children, ...props } = this.props
        return (
            <AppProvider
                {...props}
                fetchStatus={fetchStatus}
                items={items}
                meta={meta}
                perPage={perPage}
                handleChange={handleChange}
                key={this.key}
            >
                <div className={cx("root")}>{children}</div>
            </AppProvider>
        )
    }
}

const enhance = compose(
    withRouter,
    withStyles(styles)
)

export default enhance(FilterableTable)
