import React, { useState } from "react"
import idx from "idx"

import ReactSvg from "react-svg"
import { Checkbox, DropList, EditableProductListItem } from "ui"
import MoreDetails from "../MoreDetails"

import { formatPrice } from "helpers/units"
import { EmptyRoom } from "../../components"
import withStyles from "HOC/withStyles"

import styles from "./room.css"
import checkboxStyles from "./overrides/checkboxStyles.css"

import expandIcon from "assets/expand.svg"

const Room = props => {
    const {
        cx,
        project,
        room,
        itemsState,
        areAllProductsInRoomChecked,
        handleActionProduct,
        handleToggleSelectAllProductsInRoom
    } = props

    const [isExpanded, setIsExpanded] = useState(true)

    const toggleExpanded = () => setIsExpanded(wasExpanded => !wasExpanded)

    return (
        <li key={room.id} className={cx("root")}>
            {renderHeader()}
            {isExpanded && renderProductList(room)}
        </li>
    )

    function renderSummaryPrice(room) {
        if (!(room.calculations && room.calculations.total_price)) {
            return null
        }

        return " - " + formatPrice(room.calculations.total_price)
    }

    function renderItemsCount(room) {
        const items_count = (room.calculations || {}).items_count

        if (!items_count) {
            return null
        }

        return (
            <span>
                {items_count} products {renderSummaryPrice(room)}
            </span>
        )
    }

    function renderArea(room) {
        const area = (room.calculations || {}).area

        if (!area) {
            return null
        }

        return (
            <span>
                {area} ft<sup>2</sup>
            </span>
        )
    }

    function renderPricePerArea(room) {
        const area = (room.calculations || {}).area
        const price_per_area = (room.calculations || {}).price_per_area

        if (!area) {
            return null
        }

        if (!price_per_area) {
            return null
        }

        return (
            <span>
                {formatPrice(price_per_area)} / ft<sup>2</sup>
            </span>
        )
    }

    function renderHeader() {
        return (
            <header className={cx("header")}>
                <div>
                    <Checkbox
                        customStyles={checkboxStyles}
                        id={room.id}
                        disabled={!!!room.items.length}
                        checked={areAllProductsInRoomChecked(room.id)}
                        onChange={() => handleToggleSelectAllProductsInRoom(room.id)}
                    />
                    <div className={cx("name")}>{room.name}</div>
                </div>
                <div>
                    <ul className={cx("infoList")}>
                        {[renderItemsCount(room), renderPricePerArea(room), renderArea(room), renderDropList(room)]
                            .filter(item => !!item)
                            .map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                    </ul>
                    <button className={cx("expandButton")} onClick={toggleExpanded}>
                        {isExpanded ? "Hide" : "Show"}
                        <ReactSvg className={cx("expandIcon", { isExpanded })} src={expandIcon} />
                    </button>
                </div>
            </header>
        )
    }

    function renderDropList(room) {
        if (!room.id) {
            return null
        }

        return (
            <div>
                <DropList
                    trigger={
                        <div className={cx("editRoomButton")}>
                            <span />
                            <span />
                            <span />
                        </div>
                    }
                >
                    <DropList.List>
                        <DropList.Item
                            onClick={() => {
                                handleActionProduct({ actionType: "editRoom", id: room.id, payload: room })
                            }}
                        >
                            Edit room
                        </DropList.Item>
                        <DropList.Divider />
                        <DropList.Item
                            className="danger"
                            onClick={() => {
                                handleActionProduct({ actionType: "deleteRoom", id: room.id })
                            }}
                        >
                            Delete
                        </DropList.Item>
                    </DropList.List>
                </DropList>
            </div>
        )
    }

    function renderProductList(room) {
        if (room.items.length === 0) {
            const items_count = (room.calculations || {}).items_count

            return !items_count && <EmptyRoom />
        }

        return (
            <ul className={cx("productListContainer")}>
                <div className={cx("productListHeader")}>
                    <div>Product name</div>
                    <div>
                        <div>Type</div>
                        <div>Comments</div>
                        <div>Item price</div>
                        <div>Qty</div>
                        <div>Discount</div>
                        <div>Item total</div>
                    </div>
                </div>
                {room.items.map((item, index) => renderProjectItem(item, index))}
            </ul>
        )
    }

    function renderProjectItem(projectItem, index) {
        const { id, order_uuid: orderUuid } = projectItem

        const isService = !!idx(projectItem, _ => _.product.is_assistance)
        const customParam = { key: "Consignment", value: projectItem.consignment ? "Yes" : "No" }

        const onChangeTab = payload => handleActionProduct({ id, actionType: "changeTab", payload })
        const onCheck = () => handleActionProduct({ id, actionType: "check" })
        const onToggleDetails = () => handleActionProduct({ id, actionType: "toggleDetails" })
        const onAddToRoom = () => handleActionProduct({ id, actionType: "addToRoom" })
        const onCopyToRoom = () => handleActionProduct({ id, actionType: "copyToRoom" })
        const onToggleEdit = () => handleActionProduct({ id, actionType: "toggleEdit" })
        const onDelete = () => handleActionProduct({ id, actionType: "delete" })
        const onSubmitEdit = (id, payload) => handleActionProduct({ id, actionType: "save", payload })
        const onShowComments = () => handleActionProduct({ id, actionType: "showComments" })

        const renderDetails = areDetailsShown(projectItem)
            ? () => (
                  <MoreDetails
                      isService={isService}
                      toggleDetails={onToggleDetails}
                      currentTab={currentTab(projectItem)}
                      changeTab={onChangeTab}
                      product={projectItem}
                  />
              )
            : null

        return (
            <EditableProductListItem
                key={id}
                showTax={false}
                isService={isService}
                isChecked={isChecked(projectItem)}
                isEdited={isEdited(projectItem)}
                isSaving={isSaving(projectItem)}
                errors={getErrors(projectItem)}
                isLast={room.items.length === index + 1}
                isEditDisabled={false}
                isDeleteDisabled={false}
                item={{
                    ...projectItem,
                    product: projectItem
                }}
                customParam={customParam}
                roomId={room.id}
                renderDetails={renderDetails}
                onCheck={onCheck}
                onToggleDetails={onToggleDetails}
                onAddToRoom={onAddToRoom}
                onCopyToRoom={onCopyToRoom}
                onToggleEdit={onToggleEdit}
                onDelete={onDelete}
                onSubmitEdit={onSubmitEdit}
                onShowComments={onShowComments}
                orderUuid={orderUuid}
                parent={project.data}
                isStockItemChangeEnabled={false}
            />
        )
    }

    function itemState(projectItem) {
        return itemsState[projectItem.id] || {}
    }

    function currentTab(projectItem) {
        return itemState(projectItem).currentTab || false
    }

    function isEdited(projectItem) {
        return itemState(projectItem).isEdited || false
    }

    function isChecked(projectItem) {
        return itemState(projectItem).isChecked || false
    }

    function isSaving(projectItem) {
        return itemState(projectItem).isSaving || false
    }

    function areDetailsShown(projectItem) {
        return itemState(projectItem).areDetailsShown || false
    }

    function getErrors(projectItem) {
        return itemState(projectItem).errors || {}
    }
}

export default withStyles(Room, styles)
