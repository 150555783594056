import React from "react"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import idx from "idx"

import { Modal, Button, ActionButton, PhotoUploader, Input, VendorSelect } from "ui"

import withStyles from "HOC/withStyles"
import styles from "./AddEditModal.css"
import modalStyles from "./overrides/ModalStyles.css"
import nameInputStyles from "./overrides/NameInputStyles.css"
import priceInputStyles from "./overrides/PriceInputStyles.css"

import validationSchema from "./validationSchema"

const initialValues = {
    name: "",
    media: null,
    brand: null,
    price: null
}

const mapValuesToForm = manualEntry => {
    if (!manualEntry) {
        return initialValues
    }
    const { name, media, brand, lowest_price } = manualEntry
    return { name, media, brand: { name: brand }, price: lowest_price }
}

const AddEditModal = props => {
    const { cx, manualEntry, onClose, onSubmit } = props

    const isEdit = !!manualEntry && manualEntry.id

    const mapValuesAndSubmit = (values, formikHelpers) => {
        const { name, media, brand, price } = values
        const mappedValues = {
            name,
            images: media ? [media.id] : undefined,
            brand_id: brand ? brand.id : undefined,
            price
        }
        return onSubmit(mappedValues, formikHelpers)
    }

    const formik = useFormik({
        initialValues: mapValuesToForm(manualEntry),
        validationSchema,
        onSubmit: mapValuesAndSubmit
    })
    const { handleSubmit, isSubmitting, values, touched, errors, setFieldValue, setFieldTouched } = formik

    const onChangeInput = async (name, value) => {
        await setFieldValue(name, value)
        setFieldTouched(name, true)
    }

    return (
        <Modal isOpen preventClickOutside closeModal={onClose} customStyles={modalStyles}>
            <Modal.Header>{isEdit ? "Edit" : "Create"} manual entry</Modal.Header>
            <form onSubmit={(!isSubmitting && handleSubmit) || null}>
                <Modal.Body>
                    <div className={cx("fields-wrapper")}>
                        <div className={cx("photo-wrapper")}>
                            <label>Image:</label>
                            <PhotoUploader
                                className={cx("photo-uploader")}
                                avatar={idx(values, _ => _.media.url)}
                                handleRemovePhoto={() => setFieldValue("media", null)}
                                handleUploadSuccess={({ addedFile }) => setFieldValue("media", addedFile)}
                            />
                        </div>
                        <div className={cx("inputs-wrapper")}>
                            <Input
                                customStyles={nameInputStyles}
                                attributes={{ required: true }}
                                label="Name"
                                value={values.name}
                                onChange={({ event }) => onChangeInput("name", event.target.value)}
                                error={touched.name && errors.name}
                            />
                            <div className={cx("second-row")}>
                                <VendorSelect
                                    isRequired
                                    label="Vendor"
                                    name="brand"
                                    value={idx(values, _ => _.brand.name)}
                                    error={touched.brand && errors.brand}
                                    setValue={brand => onChangeInput("brand", brand)}
                                />
                                <Input
                                    customStyles={priceInputStyles}
                                    attributes={{ required: true }}
                                    label="Price"
                                    type="number"
                                    value={values.price}
                                    innerLabel={"$"}
                                    isInnerLabelLeft
                                    onChange={({ event }) => onChangeInput("price", event.target.value)}
                                    error={touched.price && errors.price}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Actions>
                    <ActionButton type="button" className={cx("cancel-button")} label="Cancel" handleClick={onClose} />
                    <Button
                        className={cx("submit-button", "first-button")}
                        type="submit"
                        label={isEdit ? "Edit" : "Create"}
                        isLoading={isSubmitting}
                    />
                </Modal.Actions>
            </form>
        </Modal>
    )
}

AddEditModal.propTypes = {
    manualEntry: PropTypes.shape({
        id: PropTypes.number.isRequired,
        media: PropTypes.shape({
            url: PropTypes.string
        }),
        name: PropTypes.string.isRequired,
        brand: PropTypes.string.isRequired,
        lowest_price: PropTypes.number.isRequired
    }),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export default withStyles(AddEditModal, styles)
