import React, { useState, useEffect } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { isEqual } from "lodash/core"
import idx from "idx"

import MainPageImageWithPins from "../../pages/MainPageImageWithPins"
import MainPageCollections from "../../pages/MainPageCollections"
import MainPageNews from "../../pages/MainPageNews"
import MainPageRealizations from "../MainPageRealizations"
import MainPageOurFavourites from "../../pages/MainPageOurFavourites"
import MainPageImmediateDelivery from "../../pages/MainPageImmediateDelivery"
import MainPageLink from "../../pages/MainPageLink"

const MainPage = props => {
    const {
        updateCollection,
        updatePost,
        updateImmediateDelivery,
        updateRealization,
        updateOurFavourite,
        showSuccessNotification,
        showErrorNotification
    } = props

    const [isModified, setIsModified] = useState(false)
    const [modifiedData, setModifiedData] = useState(null)
    const [isSaving, setIsSaving] = useState(false)

    useEffect(() => {
        const { getData } = props
        getData()
    }, [])

    const handleUpdate = (body, onSuccess) => {
        const {
            mainPages: { data }
        } = props
        const isModified = !isEqual(data, body)
        setIsModified(isModified)
        setModifiedData(isModified ? body : null)
        if (onSuccess && onSuccess === "function") {
            onSuccess()
        }
    }

    const handleSubmit = (body, onSuccess, onError) => {
        const { updateHomePage } = props
        setIsSaving(true)

        updateHomePage({ body: body || modifiedData }).then(
            data => {
                setIsSaving(false)

                const error = idx(data, _ => _.payload.error)
                if (error) {
                    if (onError) {
                        onError(error)
                    }

                    return showErrorNotification()
                }

                if (onSuccess) {
                    return onSuccess()
                }

                setIsModified(false)
                showSuccessNotification()
            },
            () => setIsSaving(false)
        )
    }

    const handleClearChanges = () => {
        setIsModified(false)
        setModifiedData(null)
    }

    const handleCollectionUpdate = (id, data, onSuccess, onError) => {
        const { getData } = props
        updateCollection(id, data).then(response => {
            if (response.payload.error) {
                return onError(response.payload.error)
            }

            onSuccess()
            handleClearChanges()
            getData()
        })
    }

    const handleCollectionDelete = (id, onSuccess) => {
        const { getData, deleteCollection } = props
        setIsSaving(true)
        deleteCollection(id).then(() => {
            getData()
            handleClearChanges()
            onSuccess()
            setIsSaving(false)
        })
    }

    const handleCollectionCreate = (body, onSuccess) => {
        const { getData, createCollection } = props
        createCollection(body).then(() => {
            getData()
            handleClearChanges()
            onSuccess()
        })
    }

    const handlePostUpdate = (id, data, onSuccess, onError) => {
        const { getData } = props
        updatePost(id, data).then(response => {
            if (response.payload.error) {
                return onError(response.payload.error)
            }

            onSuccess()
            handleClearChanges()
            getData()
        })
    }

    const handlePostDelete = (id, onSuccess) => {
        const { getData, deletePost } = props
        setIsSaving(true)
        deletePost(id).then(() => {
            getData()
            handleClearChanges()
            onSuccess()
            setIsSaving(false)
        })
    }

    const handlePostCreate = (body, onSuccess) => {
        const { getData, createPost } = props
        createPost(body).then(() => {
            getData()
            handleClearChanges()
            onSuccess()
        })
    }

    const handleRealizationCreate = (body, onSuccess) => {
        const { getData, createRealization } = props
        setIsSaving(true)

        createRealization(body).then(() => {
            getData()
            onSuccess()
            setIsSaving(false)
        })
    }

    const handleRealizationUpdate = (id, data, onSuccess, onError) => {
        const { getData } = props
        setIsSaving(true)

        updateRealization(id, data).then(response => {
            if (response.payload.error) {
                return onError(response.payload.error)
            }

            onSuccess()
            getData()
            setIsSaving(false)
        })
    }

    const handleRealizationDelete = (id, onSuccess) => {
        const { getData, deleteRealization } = props
        setIsSaving(true)

        deleteRealization(id).then(() => {
            getData()
            onSuccess()
            setIsSaving(false)
        })
    }

    const handleCreateImmediateDelivery = (body, onSuccess) => {
        const { getData, createImmediateDelivery } = props
        createImmediateDelivery(body).then(() => {
            getData()
            onSuccess()
        })
    }

    const handleDeleteImmediateDelivery = (id, onSuccess) => {
        const { getData, deleteImmediateDelivery } = props
        setIsSaving(true)

        deleteImmediateDelivery(id).then(() => {
            getData()
            onSuccess()
            setIsSaving(false)
        })
    }

    const handleImmediateDeliveryUpdate = (id, data, onSuccess, onError) => {
        const { getData } = props
        updateImmediateDelivery(id, data).then(response => {
            if (response.payload.error) {
                return onError(response.payload.error)
            }
            onSuccess()
            getData()
        })
    }

    const handleOurFavouritesCreate = (body, onSuccess) => {
        const { getData, createOurFavourites } = props
        createOurFavourites(body).then(() => {
            getData()
            onSuccess()
        })
    }

    const handleOurFavouriteUpdate = (id, data, onSuccess, onError) => {
        const { getData } = props
        updateOurFavourite(id, data).then(response => {
            if (response.payload.error) {
                return onError(response.payload.error)
            }
            onSuccess()
            getData()
        })
    }

    const handleDeleteOurFavourite = (id, onSuccess) => {
        const { getData, deleteOurFavourite } = props
        setIsSaving(true)

        deleteOurFavourite(id).then(() => {
            getData()
            onSuccess()
            setIsSaving(false)
        })
    }

    const handleHomepageHeaderCreate = (body, onSuccess) => {
        const { getData, createHomepageHeader } = props
        setIsSaving(true)

        createHomepageHeader(body).then(() => {
            handleClearChanges()
            getData()
            onSuccess()
            setIsSaving(false)
        })
    }

    const handleHomepageHeaderUpdate = (id, data, onSuccess, onError) => {
        const { getData, updateHomepageHeader } = props
        setIsSaving(true)

        updateHomepageHeader(id, data).then(response => {
            if (response.payload.error) {
                return onError(response.payload.error)
            }

            handleClearChanges()
            onSuccess()
            getData()
            setIsSaving(false)
        })
    }

    const handleHomepageHeaderDelete = (id, onSuccess) => {
        const { getData, deleteHomepageHeader } = props
        setIsSaving(true)

        deleteHomepageHeader(id).then(() => {
            getData()
            onSuccess()
            handleClearChanges()
            setIsSaving(false)
            showSuccessNotification()
        })
    }

    const handleDeleteAllFromList = (body, onSuccess) => {
        handleSubmit(body, onSuccess)
        handleClearChanges()
    }

    const mainPageProps = {
        handleUpdate,
        handleSubmit,
        handleClearChanges,
        modifiedData,
        isModified,
        isSaving,
        handleCollectionUpdate,
        handleCollectionDelete,
        handleCollectionCreate,
        handlePostUpdate,
        handlePostDelete,
        handlePostCreate,
        handleRealizationCreate,
        handleRealizationUpdate,
        handleRealizationDelete,
        handleCreateImmediateDelivery,
        handleDeleteImmediateDelivery,
        handleImmediateDeliveryUpdate,
        handleOurFavouritesCreate,
        handleOurFavouriteUpdate,
        handleDeleteOurFavourite,
        handleHomepageHeaderCreate,
        handleHomepageHeaderUpdate,
        handleHomepageHeaderDelete,
        handleDeleteAllFromList
    }

    return (
        <Switch>
            <Redirect from="/content" exact={true} to="/content/main-page/image-with-pins" />
            <Redirect from="/content/main-page" exact={true} to="/content/main-page/image-with-pins" />
            <Route
                path="/content/main-page/image-with-pins"
                render={() => <MainPageImageWithPins {...props} {...mainPageProps} />}
            />
            <Route
                path="/content/main-page/collections"
                render={() => <MainPageCollections {...props} {...mainPageProps} />}
            />
            <Route
                path="/content/main-page/realizations"
                render={() => <MainPageRealizations {...props} {...mainPageProps} />}
            />
            <Route
                path="/content/main-page/our-favourites"
                render={() => <MainPageOurFavourites {...props} {...mainPageProps} />}
            />
            <Route
                path="/content/main-page/immediate-delivery"
                render={() => <MainPageImmediateDelivery {...props} {...mainPageProps} />}
            />
            <Route path="/content/main-page/news" render={() => <MainPageNews {...props} {...mainPageProps} />} />
            <Route path="/content/main-page/link" render={() => <MainPageLink {...props} {...mainPageProps} />} />
            />
        </Switch>
    )
}

export default MainPage
