import * as constants from "constants/index"

function list(state = {}, action) {
    switch (action.type) {
        case constants.ADD_TAG_START:
            return { data: {}, isLoading: true }
        case constants.ADD_TAG_SUCCESS:
            return { data: action.payload.data.data, isLoading: false }
        case constants.ADD_TAG_ERROR:
            return { data: {}, isLoading: false }
        default:
            return state
    }
}

export default list
