import React from "react"
import { Switch, Route } from "react-router-dom"
import DisplayUsers from "./DisplayUsers/DisplayUsers"
import Form from "./Form"
import ProfileModule from "modules/ProfileModule"

const UsersCover = () => {
    return (
        <Switch>
            <Route path={"/settings/users"} exact={true} render={() => <DisplayUsers />} />
            <Route path={"/settings/users/newuser"} render={props => <Form {...props} />} />
            <ProfileModule />
        </Switch>
    )
}

export default UsersCover
