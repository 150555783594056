import { useState, useCallback } from "react"

const useConditionPhotosModal = ({ conditionPhotos, setConditionPhotos }) => {
    const [isPhotosModalOpen, setIsPhotosModalOpen] = useState(false)

    const onCloseUploadConditionPhotosModal = useCallback(() => {
        setIsPhotosModalOpen(false)
    }, [])

    const onOpenUploadConditionPhotosModal = useCallback(() => {
        setIsPhotosModalOpen(true)
    }, [])

    const onUploadConditionPhotos = useCallback(
        images => {
            setConditionPhotos([...conditionPhotos, ...images])
            onCloseUploadConditionPhotosModal()
        },
        [onCloseUploadConditionPhotosModal, setConditionPhotos]
    )

    const onRemoveConditionPhoto = imageId => {
        setConditionPhotos(conditionPhotos.filter(image => image.id !== imageId))
    }

    return {
        isPhotosModalOpen,
        setIsPhotosModalOpen,
        onCloseUploadConditionPhotosModal,
        onOpenUploadConditionPhotosModal,
        onUploadConditionPhotos,
        onRemoveConditionPhoto
    }
}

export default useConditionPhotosModal
