import { INPUT_TYPES } from "constants/index"

export default {
    name: "User",
    endpoint: "/user",
    fields: [
        {
            name: "email",
            label: "E-mail address",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "first_name",
            label: "First name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "last_name",
            label: "Last name",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {
                required: true
            }
        },
        {
            name: "phone",
            label: "Phone Number",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "permissionGroups",
            label: "Roles",
            type: INPUT_TYPES.ARRAY,
            defaultValue: [],
            attributes: {}
        },
        {
            name: "post_code",
            label: "ZIP Code",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "city",
            label: "City",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "state",
            label: "State",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "address",
            label: "Address (Street and no.)",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        },
        {
            name: "country",
            label: "Country",
            type: INPUT_TYPES.TEXT,
            defaultValue: "",
            attributes: {}
        }
    ]
}
