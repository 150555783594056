import React, { useState } from "react"
import { Formik, Form as FormikForm } from "formik"
import PropTypes from "prop-types"
import { merge, set, cloneDeep } from "lodash"

import SectionModalMatterport from "./SectionModalMatterport"
import {
    getInitialValues as getProductLinkInitialValues,
    getSaveValues as getProductLinkSaveValues
} from "../ShowProductLink/values"
import { section as sectionSchema } from "./schema"

const SectionModalMatterportContainer = ({ onClose, onSave, data }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const initialValues = merge(
        data,
        {
            settings: {
                ...(data.settings || {})
            }
        },
        getProductLinkInitialValues(data)
    )

    const onSubmit = async (values, formikActions) => {
        try {
            setIsSubmitting(true)

            await onSave(
                merge(
                    values,
                    {
                        matterport_thumbnail: values.matterport_thumbnail ? values.matterport_thumbnail.id : null,
                        settings: {
                            ...values.settings,
                            matterport_link: values.settings.matterport_link
                        }
                    },
                    getProductLinkSaveValues(values)
                )
            )

            onClose()
        } catch (errors) {
            setIsSubmitting(false)
            formikActions.setErrors(
                Object.entries(errors).reduce((acc, [path, error]) => {
                    const newAcc = cloneDeep(acc)
                    set(newAcc, path, error)
                    return newAcc
                }, {})
            )
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={sectionSchema}
            enableReinitialize
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
        >
            {propsForm => (
                <FormikForm>
                    <SectionModalMatterport onClose={onClose} form={propsForm} isSubmitting={isSubmitting} />
                </FormikForm>
            )}
        </Formik>
    )
}

SectionModalMatterportContainer.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default SectionModalMatterportContainer
