import React, { Component } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import {
    Invoices,
    Memo,
    PurchaseOrders,
    RentalAgreements,
    Payments,
    CreateShipmentPage,
    SalePurchaseOrderFormPage,
    StockPurchaseOrderFormPage,
    SalesOrderReturnListPage,
    SalesOrderReturnAddPage,
    StoreCreditListPage
} from "./pages"
import { SalesOrders } from "modules/OrdersModule/components/List"
import { OrderOverview } from "modules/OrdersModule/components/Overview"
import { Sales as OrderForm } from "modules/OrdersModule/components/Form"
import ServicesList from "modules/ServicesModule/pages/List/ListContainer"
import withPermissions from "HOC/withPermissions"

class OrdersPage extends Component {
    componentDidMount() {
        const { history, canList, PERMISSIONS } = this.props

        if (!canList(PERMISSIONS.context.CONTENT)) {
            return history.push("/dashboard")
        }
    }

    render() {
        return (
            <Switch>
                <Redirect exact from="/orders" to="/orders/sales-orders" />
                <Route path="/orders/services" component={ServicesList} />

                <Route exact path="/orders/sales-orders" component={SalesOrders} />
                <Route
                    path="/orders/sales-orders/add/:sectionName?"
                    render={routerProps => <OrderForm {...routerProps} isNew />}
                />
                <Route path="/orders/sales-orders/:orderId/edit/:sectionName?" component={OrderForm} />
                <Route exact path="/orders/sales-orders/:orderId/shipments/add" component={CreateShipmentPage} />
                <Route
                    exact
                    path={[
                        "/orders/sales-orders/:orderId/purchase-orders/add",
                        "/orders/sales-orders/:orderId/purchase-orders/:purchaseOrderId/edit"
                    ]}
                    component={SalePurchaseOrderFormPage}
                />
                <Route
                    exact
                    path={["/orders/purchase-orders/add", "/orders/purchase-orders/:purchaseOrderId/edit"]}
                    component={StockPurchaseOrderFormPage}
                />
                <Route exact path="/orders/sales-orders/:orderId/:sectionName?" component={OrderOverview} />

                <Route exact path="/orders/invoices" component={Invoices.List} />
                <Route
                    path="/orders/invoices/add/:sectionName?"
                    render={routerProps => <Invoices.Form {...routerProps} isNew />}
                />
                <Route path="/orders/invoices/:invoiceId/edit/:sectionName?" component={Invoices.Form} />

                <Route exact path="/orders/purchase-orders" component={PurchaseOrders.List} />

                <Route exact path="/orders/returns" component={SalesOrderReturnListPage} />
                <Route exact path="/orders/returns/add" component={SalesOrderReturnAddPage} />

                <Route exact path="/orders/memo" component={Memo.List} />
                <Route
                    path="/orders/memo/add/:sectionName?"
                    render={routerProps => <Memo.Form {...routerProps} isNew />}
                />
                <Route path="/orders/memo/:orderId/edit/:sectionName?" component={Memo.Form} />
                <Route exact path="/orders/memo/:orderId/:sectionName?" component={Memo.Overview} />

                <Route exact path="/orders/rental-agreements" component={RentalAgreements.List} />
                <Route
                    path="/orders/rental-agreements/add/:sectionName?"
                    render={routerProps => <RentalAgreements.Form {...routerProps} isNew />}
                />
                <Route path="/orders/rental-agreements/:orderId/edit/:sectionName?" component={RentalAgreements.Form} />
                <Route
                    exact
                    path="/orders/rental-agreements/:orderId/:sectionName?"
                    component={RentalAgreements.Overview}
                />
                <Route exact path="/orders/payments" component={Payments.List} />
                <Route exact path="/orders/store-credits" component={StoreCreditListPage} />

                <Redirect to="/404" />
            </Switch>
        )
    }
}

export default withPermissions(OrdersPage)
