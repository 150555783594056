import { objToQueryString } from "helpers/urls"
import fetch from "helpers/fetch"
import useCrudFactory from "modules/WmsModule/hooks/factories/useCrudFactory"

const fetchWarehouseList = params => fetch.get(`/warehouses?${objToQueryString(params)}`)
const fetchWarehouseDetails = id => fetch.get(`/warehouses/${id}`)
const fetchWarehouseCreate = ({ data }) => fetch.post("/warehouses", data)
const fetchWarehouseEdit = ({ id, data }) => fetch.patch(`/warehouses/${id}`, data)
const fetchWarehouseDelete = ({ id }) => fetch.deleteRAW(`/warehouses/${id}`)

const defaultListParams = {
    page: 1,
    length: 9999,
    sort_by: "id",
    sort_direction: "asc",
    query: "",
    autonomous: undefined,
    is_default_for_po: undefined,
    active: undefined,
    primary: undefined,
    created_from: undefined,
    created_to: undefined
}

const fetchFunctions = {
    list: fetchWarehouseList,
    details: fetchWarehouseDetails,
    create: fetchWarehouseCreate,
    edit: fetchWarehouseEdit,
    delete: fetchWarehouseDelete
}

const warehouseCrud = useCrudFactory({ key: "warehouses", fetchFunctions, defaultListParams })

export const useWarehouseList = warehouseCrud.list
export const useWarehouseDetails = warehouseCrud.details
export const useWarehouseCreate = warehouseCrud.create
export const useWarehouseEdit = warehouseCrud.edit
export const useWarehouseDelete = warehouseCrud.delete
