import React from "react"
import { FieldArray } from "formik"
import withStyles from "HOC/withStyles"

import { SelectWithSearch } from "ui"

import styles from "./Features.css"
import featureSelectStyles from "../overrides/FeatureSelect.css"
import Loader from "components/Reusable/Loader/Loader"

import { useIsTablet } from "helpers/rwd"
import closeIcon from "./assets/close.svg"
import addIcon from "assets/add.svg"

const Features = props => {
    const { cx, features, itemsState, values, dispatchItemAction, handleSaveFeature, handleSaveFeatureValue } = props

    const featureOptions = features.data.map(item => ({
        id: item.id,
        value: item.id,
        label: item.name
    }))

    const isTablet = useIsTablet()
    const valueMaxLength = isTablet ? 16 : 22
    const listValueMaxLength = isTablet ? 35 : 30

    const featuresLength = values.features ? values.features.length : 0
    return (
        <div className={cx("root")}>
            <FieldArray
                name="features"
                render={arrayHelpers => {
                    return (
                        <div>
                            {featuresLength === 0 && <div className={cx("empty")}>No items added yet.</div>}
                            {featuresLength > 0 && (
                                <div>
                                    <div className={cx("header", "row")}>
                                        <div className={cx("left")}>
                                            <div>Feature name</div>
                                            <div>Value</div>
                                        </div>
                                        <div />
                                    </div>
                                    {values.features.map((feature, featureIndex) => {
                                        if (itemsState[featureIndex].isLoading) {
                                            return (
                                                <div key={featureIndex} className={cx("row")}>
                                                    <div className={cx("loader")}>
                                                        <Loader />
                                                    </div>
                                                </div>
                                            )
                                        }

                                        return (
                                            <div key={featureIndex} className={cx("row")}>
                                                <div className={cx("left")}>
                                                    <div>{renderFeature(featureIndex, feature, arrayHelpers)}</div>
                                                    <div>{renderFeatureValue(featureIndex, feature, arrayHelpers)}</div>
                                                </div>
                                                <div
                                                    className={cx("right")}
                                                    onClick={() => arrayHelpers.remove(featureIndex)}
                                                >
                                                    <span className={cx("close")}>
                                                        <img src={closeIcon} alt="addImage" />
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                            <div
                                className={cx("add-more")}
                                onClick={() =>
                                    arrayHelpers.push({
                                        feature_id: null,
                                        feature_value_id: null
                                    })
                                }
                            >
                                <span>
                                    <img src={addIcon} alt="addImage" />
                                    <span>Add another feature</span>
                                </span>
                            </div>
                        </div>
                    )
                }}
            />
        </div>
    )

    function renderFeature(index, feature, arrayHelpers) {
        const value = (featureOptions.find(item => item.id === feature.feature_id) || {}).label || ""
        const additionalProps = {}
        if (!value) {
            additionalProps.additionalButtonAction = () => handleSaveFeature(index)
        }

        return (
            <SelectWithSearch
                withoutFetch
                values={featureOptions
                    .filter(
                        item =>
                            !values.features
                                .reduce((acc, item) => {
                                    acc.push(item.feature_id)
                                    return acc
                                }, [])
                                .includes(item.id)
                    )
                    .filter(item =>
                        itemsState[index].featureQuery
                            ? item.label.toLowerCase().includes(itemsState[index].featureQuery.toLowerCase())
                            : true
                    )}
                value={value}
                appendValueToBtnLabel={true}
                valueMaxLength={valueMaxLength}
                listValueMaxLength={listValueMaxLength}
                disableBtnForEmptyValue={true}
                isListOnTop={true}
                customStyles={featureSelectStyles}
                onInputChange={value => {
                    dispatchItemAction({
                        type: "setValues",
                        payload: {
                            index,
                            values: {
                                featureQuery: value
                            }
                        }
                    })
                }}
                setValue={({ value } = { value: "" }) => {
                    arrayHelpers.replace(index, {
                        ...feature,
                        feature_id: value,
                        feature_value_id: null
                    })
                }}
                isDisabled={false}
                additionalButtonLabel="Create new feature: "
                {...additionalProps}
            />
        )
    }

    function renderFeatureValue(index, feature, arrayHelpers) {
        const options = feature.feature_id
            ? ((features.data.find(item => item.id === Number(feature.feature_id)) || {}).values || []).map(item => ({
                  id: item.id,
                  value: item.id,
                  label: item.value
              }))
            : []

        const value = (options.find(item => item.id === feature.feature_value_id) || {}).label || ""

        const additionalProps = {}

        if (!value) {
            additionalProps.additionalButtonAction = () => handleSaveFeatureValue(index, feature.feature_id)
        }

        return (
            <SelectWithSearch
                withoutFetch
                values={options.filter(item =>
                    item.label.toLowerCase().includes(itemsState[index].featureValueQuery.toLowerCase())
                )}
                value={value}
                appendValueToBtnLabel={true}
                valueMaxLength={valueMaxLength}
                listValueMaxLength={listValueMaxLength}
                disableBtnForEmptyValue={true}
                customStyles={featureSelectStyles}
                onInputChange={value =>
                    dispatchItemAction({
                        type: "setValues",
                        payload: {
                            index,
                            values: {
                                featureValueQuery: value
                            }
                        }
                    })
                }
                setValue={({ value } = { value: "" }) => {
                    arrayHelpers.replace(index, {
                        ...feature,
                        feature_value_id: value
                    })
                }}
                isListOnTop={true}
                isDisabled={!!!feature.feature_id}
                additionalButtonLabel="Create new value: "
                {...additionalProps}
            />
        )
    }
}

export default withStyles(Features, styles)
