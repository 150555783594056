import { useCallback, useMemo } from "react"
import { useFormik } from "formik"
import pick from "lodash/pick"
import get from "lodash/get"

import PURCHASE_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/purchaseOrderStatusKeys"
import { showErrorNotification } from "actions/notification"
import { useActions } from "hooks"
import { getDiscountLabel } from "modules/OrdersModule/components/SelectDiscountSection"

import DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT from "modules/OrdersModule/constants/dropShipSelectOptionOfDestinationPoint"
import validationSchema from "./validationSchema"

const mapDiscountToSelectValue = discount =>
    discount
        ? {
              id: discount.id,
              label: getDiscountLabel(discount),
              value: pick(discount, ["id", "description", "name", "value", "value_type"])
          }
        : null

const usePurchaseOrderForm = ({
    orderId = null,
    purchaseOrderId,
    isEdit,
    isConfirmed,
    initialItems,
    defaultWarehouseForPO,
    purchaseOrderEdit,
    purchaseOrderCreate,
    purchaseOrderDetailsResource,
    successSubmitAction
}) => {
    const actions = useActions({ showErrorNotification })

    const initialValues = useMemo(() => {
        const purchaserOrderData = purchaseOrderDetailsResource.data

        if (purchaserOrderData) {
            const shipping_address = get(purchaserOrderData, "shipping_address")
            const warehouse = get(purchaserOrderData, "warehouse")
            const description = get(purchaserOrderData, "description") || ""
            const special_instruction = get(purchaserOrderData, "special_instruction") || ""
            const status = get(purchaserOrderData, "status")
            const estimated_delivery = get(purchaserOrderData, "estimated_delivery")
            const shipping_method = get(purchaserOrderData, "shipping_method")
            const origin = get(purchaserOrderData, "origin")
            const discount = mapDiscountToSelectValue(get(purchaserOrderData, "discount"))
            const brand = get(purchaserOrderData, "brand", null)

            return {
                warehouse,
                shipping_address: shipping_address
                    ? {
                          ...shipping_address,
                          name: DROP_SHIP_SELECT_OPTION_OF_DESTINATION_POINT.label
                      }
                    : null,
                description,
                special_instruction,
                items: initialItems,
                discount,
                brand,
                status,
                estimated_delivery,
                origin,
                shipping_method
            }
        }

        return {
            warehouse: defaultWarehouseForPO,
            shipping_address: null,
            description: "",
            special_instruction: "",
            items: initialItems,
            discount: null,
            status: PURCHASE_ORDER_STATUS_KEYS.notConfirmed,
            estimated_delivery: "",
            origin: "",
            shipping_method: null
        }
    }, [initialItems, defaultWarehouseForPO, purchaseOrderDetailsResource.data])

    const handleSubmit = useCallback(
        values => {
            const {
                warehouse,
                shipping_address,
                items,
                description,
                status,
                origin,
                estimated_delivery,
                shipping_method,
                discount,
                special_instruction
            } = values

            const findDeletedItemsFromInitialItems = () =>
                initialItems.reduce((foundDeletedItemIds, currentInitialItem) => {
                    const foundItem = items.some(item => item.id === currentInitialItem.id)

                    return foundItem ? foundDeletedItemIds : [...foundDeletedItemIds, { id: currentInitialItem.id }]
                }, [])

            const mapItems = items =>
                items.map(item => ({
                    product_id: item.product.id,
                    discount: item.wholesale_discount,
                    ...pick(item, [
                        "id",
                        "quantity",
                        "combination_id",
                        "fake_name",
                        "note",
                        "manufacturer_id",
                        "wholesale_cost"
                    ])
                }))

            const body = {
                status,
                origin,
                description,
                special_instruction,
                estimated_delivery,
                warehouse_id: warehouse ? warehouse.id : null,
                shipping_address: shipping_address || null,
                shipping_method_id: shipping_method ? shipping_method.id : null,
                ...(orderId ? { order_id: orderId } : {}),
                ...(isConfirmed
                    ? {}
                    : {
                          items: orderId
                              ? items.map(({ id, wholesale_cost }) => ({ id, wholesale_cost: wholesale_cost || 0 }))
                              : mapItems(items),
                          items_detach: findDeletedItemsFromInitialItems()
                      }),
                discount:
                    discount && discount.value
                        ? {
                              ...pick(discount.value, ["name", "description", "value", "value_type"]),
                              ...(get(initialValues.discount, "id") === discount.value.id
                                  ? {
                                        id: discount.value.id,
                                        parent_discount_id: get(discount.value.parent_discount, "id", null)
                                    }
                                  : { parent_discount_id: discount.value.id })
                          }
                        : null
            }

            const fetchResult = isEdit
                ? purchaseOrderEdit.mutate({
                      id: purchaseOrderId,
                      data: body
                  })
                : purchaseOrderCreate.mutate({ data: body })

            fetchResult.then(successSubmitAction).catch(actions.showErrorNotification)
        },
        [
            orderId,
            purchaseOrderId,
            initialValues,
            initialItems,
            isConfirmed,
            purchaseOrderEdit.mutate,
            purchaseOrderCreate.mutate,
            isEdit,
            successSubmitAction,
            actions
        ]
    )

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema,
        onSubmit: handleSubmit
    })

    return { formik }
}

export default usePurchaseOrderForm
