import React, { Component, Fragment } from "react"
import style from "./Input.css"
import withStyles from "HOC/withStyles"
import PhoneInput from "react-telephone-input"
import clear from "assets/delete.svg"
import addIcon from "assets/add-white.svg"
import { shortenString } from "helpers/string"

class Input extends Component {
    static defaultProps = {
        handleInputRefOnInit: () => {},
        onChange: () => {},
        readOnly: false,
        label: ""
    }

    constructor(props) {
        super(props)
        this.ref = React.createRef()
        this.state = { isListOpen: false }
    }

    handleClear = () => {
        const { onChange, fieldPath } = this.props
        onChange &&
            onChange({
                event: {
                    target: {
                        value: ""
                    }
                },
                fieldPath
            })
    }

    componentDidMount() {
        const { handleInputRefOnInit, isPhoneInput, onChange, value, fieldPath } = this.props

        const node = this.ref.current
        handleInputRefOnInit(node)

        if (isPhoneInput && typeof value === "string" && value.charAt(0) !== "+") {
            onChange({ event: { target: { value: "+1 " + value } }, fieldPath })
        }
    }

    render() {
        const {
            name,
            value,
            isClear,
            type,
            label,
            attributes,
            fieldPath,
            error,
            onChange,
            onBlur,
            onFocus,
            onKeyDown,
            isPlaceholder,
            customPlaceholder,
            isInline,
            isInnerLabelLeft,
            isInnerLabelRight,
            className,
            icon,
            innerLabel,
            cx,
            list,
            handleCurrentValue,
            handleScrollToError,
            handleSelect,
            textForHiddenValue,
            render,
            isPhoneInput,
            tabIndex,
            isOptional = false,
            readOnly,
            autoComplete = "on",
            min,
            max,
            suggestions,
            maxValueAddLength,
            isDisabled,
            step,
            title,
            skipLabelColon = false,
            disableBrowserValidation = false
        } = this.props
        const { isListOpen } = this.state
        const htmlFor = name && type ? `${name}_${type}` : null
        let formattedLabel =
            label.length > 0 && !isPlaceholder ? (
                <Fragment>
                    {label}
                    {attributes && attributes.required ? <p>&nbsp;*</p> : ""}
                </Fragment>
            ) : (
                label
            )

        const tabIndexProps = tabIndex ? { tabIndex } : {}
        const isValue = !!value || value === 0
        const showCustomPlaceholder =
            customPlaceholder !== undefined || customPlaceholder !== null ? customPlaceholder : ""

        return (
            <div className={cx("root", { error: error, "inline-input": isInline, optional: isOptional })}>
                {!isPlaceholder && !!label && (
                    <label className={cx("label")} htmlFor={htmlFor}>
                        {formattedLabel}
                        {skipLabelColon ? "" : ":"}
                    </label>
                )}

                <span className={cx("input-container", className, { isListOpen, isValue })}>
                    {!isPhoneInput && (
                        <input
                            {...tabIndexProps}
                            disabled={isDisabled}
                            type={type}
                            id={htmlFor}
                            name={name}
                            ref={this.ref}
                            placeholder={isPlaceholder ? formattedLabel : showCustomPlaceholder}
                            value={value === null ? "" : value}
                            className={cx("input", "ignore-click", { isInnerLabelLeft, isInnerLabelRight })}
                            required={!disableBrowserValidation && attributes && attributes.required}
                            onChange={event => onChange && onChange({ event, fieldPath })}
                            onBlur={onBlur}
                            onFocus={onFocus}
                            onKeyDown={onKeyDown}
                            readOnly={readOnly}
                            autoComplete={autoComplete}
                            onClick={this.handleOpenList}
                            min={min}
                            max={max}
                            step={step}
                            title={title}
                        />
                    )}
                    {isPhoneInput && (
                        <div className={cx("phoneInputHolder", { optional: isOptional })}>
                            <PhoneInput
                                flagsImagePath="/img/flags.png"
                                onChange={value => onChange && onChange({ event: { target: { value } }, fieldPath })}
                                onBlur={onBlur}
                                onFocus={onFocus}
                                defaultCountry="us"
                                value={value}
                                preferredCountries={["us", "gb"]}
                                inputProps={{ readOnly }}
                                disabled={isDisabled}
                            />
                        </div>
                    )}
                    {type === "hidden" && textForHiddenValue && (
                        <span className={cx("textForHiddenValue")}>{textForHiddenValue}</span>
                    )}
                    {type === "hidden" && render && render()}
                    {icon && (typeof icon === "string" ? <img src={icon} alt="" className={cx("icon")} /> : icon)}
                    {!!value && isClear && (
                        <img src={clear} alt="clear" className={cx("clear")} onClick={() => this.handleClear()} />
                    )}
                    {innerLabel && <span className={cx("innerLabel", { isInnerLabelLeft })}>{innerLabel}</span>}
                    {list && isListOpen && (
                        <div className={cx("list", { isEmpty: list.length === 0 })}>
                            <div className={cx("wrapper")}>
                                <ul>
                                    {list.map(({ id, name }) => {
                                        if (!name.toLowerCase().includes(value.toLowerCase())) {
                                            return null
                                        }
                                        return (
                                            <li
                                                key={id}
                                                onClick={event => {
                                                    handleSelect(id, name)
                                                    this.handleCloseList(event)
                                                    this.handleClear()
                                                }}
                                            >
                                                {name}
                                            </li>
                                        )
                                    })}
                                </ul>
                                {value && value.length > 0 && (
                                    <div
                                        className={cx("add")}
                                        onClick={event => {
                                            handleCurrentValue(value)
                                            this.handleCloseList(event)
                                            this.handleClear()
                                        }}
                                    >
                                        <img src={addIcon} alt="" className={cx("icon")} />
                                        Add "{maxValueAddLength ? shortenString(value, maxValueAddLength) : value}"
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {suggestions && !!suggestions.length && isListOpen && (
                        <div className={cx("list", { isEmpty: suggestions.length === 0 })}>
                            <div className={cx("wrapper")}>
                                <ul>
                                    {suggestions.map(value => {
                                        return (
                                            <li
                                                key={value}
                                                onClick={() =>
                                                    onChange &&
                                                    onChange({
                                                        event: { target: { name, value } },
                                                        fieldPath
                                                    })
                                                }
                                            >
                                                {value}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    )}
                </span>
                {error && (
                    <h2
                        className={cx("error-message", { "reset-horizontal-margin": isPlaceholder })}
                        ref={el => {
                            this.errorInput = el
                        }}
                    >
                        {error}
                        {handleScrollToError && handleScrollToError(this.errorInput)}
                    </h2>
                )}
            </div>
        )
    }

    handleOpenList = () => {
        const { list, suggestions } = this.props

        if (!!list || !!suggestions) {
            this.setState(
                {
                    isListOpen: true
                },
                () => document.addEventListener("click", this.handleCloseList)
            )
        }
    }

    handleCloseList = event => {
        const targetIsNotIgnored =
            event && event.target && event && event.target.classList && !event.target.classList.contains("ignore-click")

        if (event.target instanceof Node) {
            const withinTrigger = this.ref && this.ref.current.contains(event.target)

            if (!withinTrigger) {
                this.setState({ isListOpen: false })
            }
        }

        if (targetIsNotIgnored) {
            this.setState(
                {
                    isListOpen: false
                },
                () => document.removeEventListener("click", this.handleCloseList)
            )
        }
    }
}

export default withStyles(Input, style)
