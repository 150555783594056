import React, { useState, useEffect } from "react"
import ReactSVG from "react-svg"
import { Dropzone } from "ui"
import idx from "idx"
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"

import withUploader from "HOC/withUploader"
import withStyles from "HOC/withStyles"

import styles from "./PhotoUploader.css"
import uploadCloudIco from "assets/ico-upload-cloud.svg"
import closeIcon from "assets/close.svg"

const UPLOAD_STATUS = {
    INIT: "INIT",
    UPLOADING: "UPLOADING",
    UPLOADED: "UPLOADED"
}

const PhotoUploader = props => {
    const {
        cx,
        avatar,
        handleRemovePhoto,
        uploader: { actions, state }
    } = props

    const [uploadStatus, setUploadStatus] = useState(avatar ? UPLOAD_STATUS.UPLOADED : UPLOAD_STATUS.INIT)

    useEffect(() => {
        if (state.isUploading) {
            return setUploadStatus(UPLOAD_STATUS.UPLOADING)
        }

        if (state.isSuccess || avatar) {
            return setUploadStatus(UPLOAD_STATUS.UPLOADED)
        }

        setUploadStatus(UPLOAD_STATUS.INIT)
    }, [state.isUploading, state.isSuccess, avatar])

    const handleRemove = evt => {
        evt.stopPropagation()
        actions.handleRemove()
        handleRemovePhoto()
    }

    return (
        <div className={cx("root", uploadStatus, { empty: uploadStatus === UPLOAD_STATUS.INIT })}>
            {uploadStatus !== UPLOAD_STATUS.INIT && (
                <div
                    className={cx("image")}
                    style={{ backgroundImage: `url(${idx(state, _ => _.preview) || avatar})` }}
                >
                    {uploadStatus === UPLOAD_STATUS.UPLOADING && (
                        <div className={cx("progress")}>
                            <SnakeLoader />
                        </div>
                    )}
                </div>
            )}
            <div className={cx("dropzoneWrapper")}>
                <Dropzone
                    isUploading={state.isUploading}
                    isSuccess={state.isSuccess}
                    progress={state.progress}
                    handleUpload={actions.handleUpload}
                    handleRemove={actions.handleRemove}
                    isMultiple={true}
                    acceptFormats="image/jpeg,image/png,image/tiff"
                >
                    {uploadStatus === UPLOAD_STATUS.INIT ? (
                        <div className={cx("dropzone")}>
                            <ReactSVG src={uploadCloudIco} className={cx("image")} />
                            <span>Add photo</span>
                        </div>
                    ) : (
                        <div className={cx("dropzone")}>
                            <div className={cx("button")}>
                                <ReactSVG src={uploadCloudIco} className={cx("icon")} />
                                <span>Add new photo</span>
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
            {uploadStatus !== UPLOAD_STATUS.INIT && (
                <div className={cx("outlineButton")} onClick={evt => handleRemove(evt)}>
                    <ReactSVG src={closeIcon} className={cx("icon")} />
                    <span>Delete</span>
                </div>
            )}
            <span className={cx("text")}>(browse or drop your file here)</span>
        </div>
    )
}

export default withUploader(withStyles(PhotoUploader, styles))
