import { combineReducers } from "redux"

import current from "./current"
import collection from "./collection"
import post from "./post"
import realization from "./realization"
import immediateDelivery from "./immediateDelivery"
import ourFavourite from "./ourFavourite"
import header from "./header"

const homePagesReducer = combineReducers({
    current,
    collection,
    realization,
    immediateDelivery,
    ourFavourite,
    header,
    post
})

export default homePagesReducer
