import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { string } from "yup"
import fetch from "helpers/fetch"
import AddClientModal from "./AddClientModal"
import * as notificationActions from "actions/notification"

const emailSchema = string().email()

const AddClientModalContainer = props => {
    const { isOpen, setClient, closeModal, showErrorNotification, showSuccessNotification } = props

    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")

    const [firstNameError, setFirstNameError] = useState("")
    const [lastNameError, setLastNameError] = useState("")
    const [emailError, setEmailError] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        !isOpen && resetState()
    }, [isOpen])

    useEffect(() => {
        isLoading && createClient()
    }, [isLoading])

    const resetState = () => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setFirstNameError("")
        setLastNameError("")
        setEmailError("")
        setIsLoading(false)
    }

    const handleSubmit = () => {
        const { isValid, setError, errorMessage } = valuesData.email

        if (!isValid(email)) {
            setError(errorMessage)
            return
        }

        setIsLoading(true)
    }

    const handleOnKeyDown = event => {
        const enterKey = 13
        event.keyCode === enterKey && !isDisabled && handleSubmit()
    }

    const createClient = () => {
        const body = {
            first_name: firstName,
            last_name: lastName,
            email
        }

        fetch
            .post("/clients", body)
            .then(data => {
                setClient(data.data)
                showSuccessNotification()
                resetState()
                closeModal()
            })
            .catch(error => {
                error.errors.email && setEmailError(error.errors.email)
                showErrorNotification()
            })
            .finally(() => setIsLoading(false))
    }

    const valuesData = {
        firstName: {
            setValue: setFirstName,
            setError: setFirstNameError,
            isValid: value => !!value,
            errorMessage: "First name is a required field."
        },
        lastName: {
            setValue: setLastName,
            setError: setLastNameError,
            isValid: value => !!value,
            errorMessage: "Last name is a required field."
        },
        email: {
            setValue: setEmail,
            setError: setEmailError,
            isValid: value => emailSchema.isValidSync(value),
            errorMessage: "This field must be a valid email"
        }
    }

    const validate = (value, field) => {
        const { isValid, errorMessage, setError } = valuesData[field]
        const error = isValid(value) ? "" : errorMessage
        setError(error)
    }

    const setValue = (e, field) => {
        const { setValue, setError, isValid } = valuesData[field]
        const value = e.event.target.value

        setValue(value)
        isValid(value) && setError("")
    }

    const isError = firstNameError || lastNameError || emailError
    const isAnyInputEmpty = !firstName || !lastName || !email
    const isDisabled = !!isError || isAnyInputEmpty

    const fieldsProps = {
        firstName: {
            label: "First name",
            value: firstName,
            onChange: e => setValue(e, "firstName"),
            error: firstNameError,
            onBlur: event => validate(event.target.value, "firstName")
        },
        lastName: {
            label: "Last name",
            value: lastName,
            onChange: e => setValue(e, "lastName"),
            error: lastNameError,
            onBlur: event => validate(event.target.value, "lastName")
        },
        email: {
            label: "E-mail address",
            value: email,
            onChange: e => setValue(e, "email"),
            error: emailError,
            onBlur: event => validate(event.target.value, "email")
        }
    }

    return (
        <AddClientModal
            isOpen={isOpen}
            closeModal={closeModal}
            isLoading={isLoading}
            submit={handleSubmit}
            onKeyDown={handleOnKeyDown}
            isDisabled={isDisabled}
            fieldsProps={fieldsProps}
        />
    )
}

AddClientModalContainer.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    setClient: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
}

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(notificationActions, dispatch)
})

export default connect(
    null,
    mapDispatchToProps
)(AddClientModalContainer)
