import { useCallback, useMemo } from "react"
import { useQueryClient } from "react-query"

import { KEY as SHIPMENT_QUERY_KEY } from "modules/WmsModule/hooks/api/useShipment"

const useReplaceStockItemInShipmentCache = ({ shipmentId }) => {
    const queryClient = useQueryClient()
    const shipmentQueryKey = useMemo(() => [SHIPMENT_QUERY_KEY, { id: shipmentId.toString() }], [shipmentId])

    const replaceStockItemInShipmentCache = useCallback(
        stockItemToReplace => {
            queryClient.setQueryData(shipmentQueryKey, ({ data }) => ({
                data: {
                    ...data,
                    items: data.items.map(cachedItem =>
                        stockItemToReplace.id === cachedItem.id ? stockItemToReplace : cachedItem
                    )
                }
            }))
        },
        [queryClient, shipmentQueryKey]
    )

    return replaceStockItemInShipmentCache
}

export default useReplaceStockItemInShipmentCache
