import * as yup from "yup"

export const INIT_VALUES = {
    name: "",
    client: "",
    company: {},
    footage: "",
    deadline_at: new Date(),
    installation_at: new Date(),
    address: {
        company_name: "",
        country: "United States of America",
        address: "",
        post_code: "",
        city: "",
        state: ""
    },
    description: "",
    attachments: [],
    budget: 0,
    minimum_purchase: 0,
    discount: 0,
    total_rent: 0,
    monthly_rent: 0,
    rental_starts_at: new Date(),
    billing_address: {
        copy: false,
        company_name: "",
        first_name: "",
        last_name: "",
        country: "United States of America",
        address: "",
        post_code: "",
        city: "",
        state: ""
    },
    leader: "",
    designTeam: []
}

export const TABS = {
    GENERAL: "general",
    FINANCIALS: "financials"
}

export const FIELDS_IN_TABS = {
    name: TABS.GENERAL,
    client: TABS.GENERAL,
    footage: TABS.GENERAL,
    deadline_at: TABS.GENERAL,
    installation_at: TABS.GENERAL,
    address: TABS.GENERAL,
    description: TABS.GENERAL,
    attachments: TABS.GENERAL,
    budget: TABS.FINANCIALS,
    minimum_purchase: TABS.FINANCIALS,
    discount: TABS.FINANCIALS,
    total_rent: TABS.FINANCIALS,
    monthly_rent: TABS.FINANCIALS,
    rental_starts_at: TABS.FINANCIALS,
    billing_address: TABS.FINANCIALS
}

export const SCHEMA = yup.object().shape({
    name: yup.string().required("Field is required")
})
