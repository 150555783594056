import React, { useState, useEffect } from "react"
import isEqual from "lodash/isEqual"

import { DragAndDropList, EmptyList } from ".."
import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import { SaveBar } from "ui"
import SelectFromListModal from "components/Modal/SelectFromList/SelectFromListContainer"
import UploadImageModal from "../UploadImageModal"

import fetch from "helpers/fetch"

import withStyles from "HOC/withStyles"
import styles from "./navigationList.css"
import selectFromListStyles from "./overrides/selectFromListModal.css"

import filterIcon from "assets/filter.svg"
import viewListIcon from "assets/view-list.svg"

const NavigationCategoriesList = props => {
    const {
        cx,
        isLoading,
        storeList,
        isSaving,
        isEdited,
        handleSubmit,
        isAddModalOpen,
        isUploadModalOpen,
        closeAddModal,
        ListHeader,
        ListRow,
        externalList,
        resetExternalList,
        match: { path },
        isFilters,
        setIsUploadModelOpen,
        handleUpdateCategories,
        setCategoriesModal,
        categoriesModal
    } = props

    const [list, setList] = useState([])

    useEffect(() => {
        !isLoading && !isSaving && setListFromStore()
    }, [isLoading, isSaving, path])

    useEffect(() => {
        if (externalList) {
            setList(externalList)
            resetExternalList()
        }
    }, [externalList])

    const setListFromStore = () => setList(storeList)

    const handleSubmitAddFilters = selectedFilters => {
        const filtersNotRepeated = selectedFilters.filter(item => !list.some(listItem => listItem.id === item.id))
        setList([...list, ...filtersNotRepeated])
        closeAddModal()
    }

    const handleFetchFiltersToAdd = (query, { page, length }) =>
        fetch.get(`/filters?query=${query}&page=${page}&length=${length}`).then(data => ({ payload: { data } }))

    const handlePrepareFilterItem = item => ({
        name: item.name,
        right: item.object_type
    })

    const handleChangeVisible = itemId => {
        const newList = list.map(item => (item.id === itemId ? { ...item, active: !item.active } : item))
        setList(newList)
    }

    const handleDeleteItem = id => {
        const newList = list.filter(item => item.id !== id)
        setList(newList)
    }

    const handleOnSubmit = () => handleSubmit(list)

    const areListEdited = !isEqual(list, storeList)

    const [category, setCategory] = useState({})

    const handleUploadModal = cat => {
        setIsUploadModelOpen(!isUploadModalOpen)
        setCategory(cat)
    }

    const handleSubmitImageModal = ({ id, active, coverImage, hoverImage, stockImage }) => {
        const categoryToChange = {
            id,
            active,
            cover_image: Object.keys(coverImage).length ? coverImage.id || undefined : null,
            hover_image: Object.keys(hoverImage).length ? hoverImage.id || undefined : null,
            stock_image: Object.keys(stockImage).length ? stockImage.id || undefined : null
        }
        const index = categoriesModal.findIndex(c => c.id === id)
        categoriesModal[index] = categoryToChange
        setCategoriesModal(categoriesModal)
        handleUpdateCategories(categoriesModal)
    }

    const renderList = () => {
        if (isLoading) {
            return (
                <div className={cx("loader")}>
                    <SnakeLoader />
                </div>
            )
        }

        if (!list || !list.length) {
            const [message, icon] = isFilters ? ["filters", filterIcon] : ["categories", viewListIcon]
            return <EmptyList message={`No ${message} added`} icon={icon} />
        }

        return (
            <div>
                <ListHeader />
                <DragAndDropList itemsList={list} handleReorder={setList}>
                    {list.map(item => (
                        <ListRow
                            key={item.id}
                            {...item}
                            changeVisible={handleChangeVisible}
                            deleteFilter={handleDeleteItem}
                            handleUploadModal={handleUploadModal}
                        />
                    ))}
                </DragAndDropList>
            </div>
        )
    }

    return (
        <div className={cx("listWrapper")}>
            {renderList()}
            <SaveBar
                isSaving={isSaving}
                isShown={!isLoading && (isEdited || areListEdited)}
                onSubmit={handleOnSubmit}
                submitLabel="Save & Publish"
                onCancel={setListFromStore}
            />
            {isAddModalOpen && (
                <SelectFromListModal
                    handleClose={closeAddModal}
                    selectedItems={list}
                    typeItems="Filters"
                    prepareItem={handlePrepareFilterItem}
                    handleSubmit={handleSubmitAddFilters}
                    fetchItemsList={handleFetchFiltersToAdd}
                    customStyles={selectFromListStyles}
                />
            )}
            {isUploadModalOpen && (
                <UploadImageModal
                    isOpen={isUploadModalOpen}
                    handleClose={() => setIsUploadModelOpen(false)}
                    handleSubmit={handleSubmitImageModal}
                    category={category}
                    isSaving={isSaving}
                />
            )}
        </div>
    )
}

export default withStyles(NavigationCategoriesList, styles)
