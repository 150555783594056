import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./Log.css"
import moment from "moment"

class Log extends Component {
    render() {
        const { name, user_name, client_name } = this.props
        const { date } = this.props.created_at
        return (
            <li styleName="log-li">
                <p styleName="date-yy">{moment(date).format("MM/DD/YY")}</p>
                <p styleName="date-yyyy">{moment(date).format("MM/DD/YYYY")}</p>
                <p styleName="time">{moment(date).format("hh:mm")}</p>
                <p styleName="event">
                    {name} {client_name && <span> ({client_name})</span>}
                    {this.renderDifferences()}
                </p>
                <p styleName="employee">{user_name}</p>
            </li>
        )
    }

    renderDifferences() {
        const { differences } = this.props

        if (!differences) {
            return ""
        }

        const parsedDifferences = JSON.parse(differences)
        if (parsedDifferences && parsedDifferences.length) {
            return (
                <span styleName="event-details">
                    {parsedDifferences.map(element => {
                        return Object.keys(element).map((key, index) => {
                            return (
                                <span key={index} title={`${key}: ${element[key].old} -> ${element[key].new}`}>
                                    <strong>{key}:</strong> {this.parseArraysToString(element[key].old)} ->{" "}
                                    {this.parseArraysToString(element[key].new)}
                                </span>
                            )
                        })
                    })}
                </span>
            )
        }

        return ""
    }

    parseArraysToString(array) {
        if (array instanceof Array) {
            return array.join(", ")
        }

        return array
    }
}

export default CSSModules(Log, style)
