import React from "react"
import PropTypes from "prop-types"
import { DetailsCard, Panel } from "@butterfly-frontend/ui"

import { SnakeLoader } from "ui/Skeleton"
import ShipmentDetailsHeader from "modules/WmsModule/components/ShipmentDetailsHeader"
import ShipmentDetailsItemsList from "modules/WmsModule/components/ShipmentDetailsItemsList"
import { useShipmentDetailsWithItems } from "modules/WmsModule/hooks"

import styles from "./ShipmentDetails.module.css"

const ShipmentDetails = ({ shipmentId, isEditingStatusEnabled, onStatusChanged }) => {
    const {
        shipment,
        stockItems,
        transferFetchStatus,
        stockItemsFetchStatus,
        stockItemsSortState,
        onChangeStockItemsSortState,
        stockItemsPagination
    } = useShipmentDetailsWithItems({ shipmentId })

    if (transferFetchStatus.isLoading) {
        return (
            <div className={styles.loader}>
                <SnakeLoader customStyles={styles.loader} />
            </div>
        )
    }

    return (
        <div className={styles.container}>
            <ShipmentDetailsHeader
                shipment={shipment}
                onStatusChanged={onStatusChanged}
                isEditingStatusEnabled={isEditingStatusEnabled}
            />
            <ShipmentDetailsItemsList
                shippedItems={stockItems}
                sortState={stockItemsSortState}
                onChangeSort={onChangeStockItemsSortState}
                pagination={stockItemsPagination}
                isShipmentDropship={shipment.dropship}
                isLoading={stockItemsFetchStatus.isLoading}
            />
            <Panel classes={{ panel: styles.description }}>
                <DetailsCard header="DESCRIPTION">{shipment.description || "-"}</DetailsCard>
            </Panel>
        </div>
    )
}

ShipmentDetails.propTypes = {
    shipmentId: PropTypes.number.isRequired,
    isEditingStatusEnabled: PropTypes.bool,
    onStatusChanged: PropTypes.func
}

export default ShipmentDetails
