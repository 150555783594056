import React, { useEffect, useMemo, Fragment } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import idx from "idx"
import ReactSvg from "react-svg"
import { Decimal } from "decimal.js"

import Layout from "components/Reusable/Layout"
import { AddEditForm } from "ui"
import { Aside, GeneralInfo, Financials } from "./components"

import { getOrder, createOrder, updateOrder } from "actions/orders"
import { getClientById } from "actions/client"
import { showErrorNotification, showSuccessNotification } from "actions/notification"

import { successState } from "helpers/fetchStatus"
import { displayUserName } from "helpers/user"
import { roundNumber } from "helpers/units"
import { useFetch, useActions } from "hooks"
import { getClientAddress } from "modules/OrdersModule/components/Form/helpers/getClientAddress"

import withStyles from "HOC/withStyles"
import styles from "./Form.css"
import layoutStyles from "./overrides/layoutStyles.css"

import eyeSvg from "assets/eye.svg"

import { INITIAL_VALUES, VALIDATION_SCHEMA, DEPOSIT_UNIT_CONFIG } from "./constants"
import config from "./formConfig"
import { isEmpty } from "lodash"

const Form = props => {
    const { cx, isNew, type, match, history } = props

    const formConfig = useMemo(() => config[type], [type])
    const { name, pathName, hasBillingAddress, hasRevisedDate } = formConfig
    const currentClient = useSelector(state => state.clientStore.client)

    const sessionClient = useSelector(state => state.ui.session)
    const { data: editedOrder, fetchStatus: editedOrderFetchStatus } = useSelector(state => state.db.orders.current)

    const orderId = useMemo(() => Number(match.params.orderId), [match.params.orderId])
    const isEdit = !isNew && !!orderId

    const [currentOrder, fetchStatus] = isEdit ? [editedOrder, editedOrderFetchStatus] : [{}, successState()]

    const modulePath = `/orders/${pathName}`
    const basePath = `${modulePath}/${isEdit ? `${orderId}/edit` : "add"}`
    const orderOverviewPath = `${modulePath}${!!orderId ? `/${orderId}` : ""}`

    const layoutConfig = useMemo(
        () => ({
            defaultAside: Aside,
            tabs: {
                "general-info": { label: "General info", render: GeneralInfo },
                financials: { label: "Financials", render: Financials }
            }
        }),
        [Aside, GeneralInfo, Financials]
    )

    const initialValues = useMemo(() => {
        const formatPerson = (acc, [key, val]) => {
            const field = idx(currentOrder, _ => _[key.replace("_id", "")])
            const mappedField = !!field && { id: field.id, value: field.id, label: displayUserName(field) }
            return { ...acc, [key]: mappedField || val }
        }

        const formatSalesAssociate = () => {
            const field = currentOrder["users"] && currentOrder["users"].find(user => user.sales_associate === true)
            return field ? { id: field.id, value: field.id, label: field.full_name } : {}
        }

        return Object.entries(INITIAL_VALUES).reduce((acc, [key, val]) => {
            if (key === "client_id" && !isEdit && sessionClient.activeSession) {
                const { id, name } = sessionClient.activeClient
                return {
                    ...acc,
                    client_id: { id, value: id, label: name }
                }
            }

            if (["client_id"].includes(key)) {
                return formatPerson(acc, [key, val])
            }

            if (key === "users") {
                return {
                    ...acc,
                    sales_associate: formatSalesAssociate(),
                    commission_team: currentOrder[key]
                        ? currentOrder[key]
                              .filter(user => user.sales_associate === false)
                              .map(user => ({ id: user.id, label: user.full_name }))
                        : []
                }
            }

            if (key === "deposit_unit" && currentOrder[key]) {
                return { ...acc, deposit_unit: DEPOSIT_UNIT_CONFIG.find(({ value }) => currentOrder[key]) }
            }

            if (key === "discount" && currentOrder[key] !== undefined) {
                return { ...acc, discount: currentOrder[key] }
            }

            if (key === "tax_code_manually" && currentOrder[key]) {
                return { ...acc, tax_code_manually: !currentOrder[key] }
            }

            if (key === "tax_code" && currentOrder[key] !== null && currentOrder[key] !== undefined) {
                return { ...acc, tax_code: roundNumber(new Decimal(currentOrder[key]).times(100).toDecimalPlaces(6)) }
            }

            return { ...acc, [key]: currentOrder[key] || val }
        }, {})
    }, [currentOrder])

    const actions = useActions({
        getOrder,
        createOrder,
        updateOrder,
        getClientById,
        showErrorNotification,
        showSuccessNotification
    })

    useEffect(() => {
        const clientId = idx(initialValues, _ => _.client_id.value)
        !!clientId && actions.getClientById(clientId)
    }, [initialValues.client_id])

    useEffect(() => {
        !!orderId &&
            actions.getOrder(orderId).catch(() => {
                onCancel()
                actions.showErrorNotification()
            })
    }, [orderId])

    const [onSubmit, { isLoading: isSubmitting }] = useFetch({
        action: formikData => {
            const body = {
                ...toApiFormat({
                    ...formikData,
                    billing_address: formikData.billing_address.copy
                        ? { ...getClientAddress(currentClient.data, "Billing Address"), copy: true }
                        : formikData.billing_address,
                    shipping_address: currentOrder.in_house_sale
                        ? undefined
                        : formikData.shipping_address.copy
                        ? { ...getClientAddress(currentClient.data, "Shipping Address"), copy: true }
                        : formikData.shipping_address
                }),
                type
            }
            const [fetchAction, params] = isEdit ? ["update", [orderId, body]] : ["create", [body]]
            return actions[`${fetchAction}Order`](...params)
        },
        onSuccess: ({ data }) => {
            history.push(`${modulePath}/${data.id}`)
            actions.showSuccessNotification()
        },
        onError: (error, _, formikActions) => {
            formikActions.setErrors(error.errors)
            actions.showErrorNotification(error.message)
        }
    })

    const onCancel = () => history.push(orderOverviewPath)

    return (
        <Layout title={renderTitle()} cta={renderCta()} customStyles={layoutStyles}>
            <AddEditForm
                formConfig={formConfig}
                fetchStatus={fetchStatus}
                isSubmitting={isSubmitting}
                basePath={basePath}
                config={layoutConfig}
                initialValues={initialValues}
                validationSchema={VALIDATION_SCHEMA}
                onCancel={onCancel}
                onSubmit={onSubmit}
                orderData={currentOrder}
            />
        </Layout>
    )

    function renderTitle() {
        return isEdit ? (
            <Fragment>
                Edit {name}:<span className={cx("orderUuid")}>{currentOrder.uuid || "-"}</span>
            </Fragment>
        ) : (
            `New ${name}`
        )
    }

    function renderCta() {
        return (
            isEdit && [
                <Link className={cx("viewOrderButton")} to={{ pathname: orderOverviewPath }}>
                    <ReactSvg src={eyeSvg} />
                    View {name}
                </Link>
            ]
        )
    }

    function toApiFormat(data) {
        return Object.entries(data).reduce((acc, [key, val]) => {
            if ((!hasBillingAddress && key === "billing_address") || (!hasRevisedDate && key === "revised_at")) {
                return acc
            }

            if (key === "commission_team") {
                return {
                    ...acc,
                    users: [
                        ...(acc.users ? acc.users : []),
                        ...(val && !isEmpty(val[0])
                            ? val
                                  .map(user => user && user.id && { id: user.id, sales_associate: false })
                                  .filter(user => user)
                            : [])
                    ]
                }
            }

            if (key === "sales_associate") {
                return {
                    ...acc,
                    users: [
                        ...(acc.users ? acc.users : []),
                        ...(!isEmpty(val) && val.value !== "" ? [{ id: val.id, sales_associate: true }] : [])
                    ]
                }
            }

            if (["client_id"].includes(key)) {
                return { ...acc, [key]: !!val && !!val.value ? val.value : null }
            }

            if (key === "attachments") {
                return { ...acc, [key]: val.map(({ id }) => id) }
            }

            if (key === "deposit_unit") {
                return { ...acc, [key]: val.value }
            }

            if (key === "tax_code_manually") {
                return { ...acc, [key]: !val }
            }

            if (key === "tax_code") {
                return { ...acc, [key]: val !== "" && val !== null ? roundNumber(new Decimal(val).div(100), 6) : null }
            }

            if (key === "company" && val) {
                return { ...acc, company_id: val.id }
            }

            return { ...acc, [key]: val }
        }, {})
    }
}

export default withStyles(Form, styles)
