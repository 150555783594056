import * as fetchStatus from "helpers/fetchStatus"
import * as global_constants from "constants/index"
import * as constants from "./constants"
import fetch from "helpers/fetch"
import { objToQueryString } from "helpers/urls"

export const shouldFetchLocations = resource => (dispatch, getState) => {
    return fetchStatus.isResourceNotInit(resource)
}

export function getLocationsStart() {
    return {
        type: constants.GET_LIST_START
    }
}

export function getLocationsSuccess(data) {
    return {
        type: constants.GET_LIST_SUCCESS,
        payload: {
            data
        }
    }
}

export function getLocationsError(error) {
    return {
        type: constants.GET_LIST_ERROR,
        payload: {
            error
        }
    }
}

export function getLocations(params = { page: 1, length: global_constants.LENGTH_WITHOUT_PAGINATION, query: "" }) {
    return dispatch => {
        dispatch(getLocationsStart())
        return fetch
            .get(`/locations?${objToQueryString(params)}`)
            .then(data => dispatch(getLocationsSuccess(data)))
            .catch(error => dispatch(getLocationsError(error)))
    }
}

export function deleteLocationById(id) {
    return fetch.deleteRAW(`/locations/${id}`)
}

export function createLocation(body) {
    return fetch.post("/locations", body)
}

export function editLocation(id, body) {
    return fetch.patch(`/locations/${id}`, body)
}
