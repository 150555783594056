import PropTypes from "prop-types"

const SHIPMENT_PACK_ITEM_PROP_TYPE = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    condition: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        product_condition_id: PropTypes.number.isRequired,
        images: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                url: PropTypes.string.isRequired,
                size: PropTypes.number,
                name: PropTypes.string
            }).isRequired
        ).isRequired,
        type: PropTypes.string
    }).isRequired,
    location: PropTypes.string,
    boxes: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            label: PropTypes.string,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string
            })
        }).isRequired
    ).isRequired,
    shipment_stock_item_id: PropTypes.number.isRequired,
    packed: PropTypes.bool.isRequired,
    acceptance: PropTypes.bool,
    is_returned: PropTypes.bool.isRequired
}).isRequired

export default SHIPMENT_PACK_ITEM_PROP_TYPE
