import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { shouldFetch, resetCurrentProject, getProject, updateProject } from "actions/projects"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import Overview from "./Overview"

const OverviewContainer = props => {
    const { match, history } = props
    const projectId = match.params.projectId
    const sectionName = match.params.sectionName

    const dispatch = useDispatch()
    const project = useSelector(state => state.db.projects.current.current)
    const [isStatusChanging, setIsStatusChanging] = useState(false)

    useEffect(() => {
        if (dispatch(shouldFetch(project))) {
            fetchProject()
        }
    }, [project])

    useEffect(() => {
        return () => {
            dispatch(resetCurrentProject())
        }
    }, [])

    function fetchProject() {
        return dispatch(getProject(projectId)).catch(() => history.push("/projects"))
    }

    function handleChangeStatus(status) {
        const body = { name: project.data.name, status }

        setIsStatusChanging(true)
        dispatch(updateProject(projectId, body))
            .then(() => {
                fetchProject().then(() => setIsStatusChanging(false))
                dispatch(showSuccessNotification())
            })
            .catch(() => {
                dispatch(showErrorNotification())
                setIsStatusChanging(false)
            })
    }

    const shouldShowSummary = !sectionName || sectionName === "project-info"

    return (
        <Overview
            isStatusChanging={isStatusChanging}
            shouldShowSummary={shouldShowSummary}
            projectId={projectId}
            project={project}
            handleChangeStatus={handleChangeStatus}
        />
    )
}

export default OverviewContainer
