import React, { Fragment } from "react"
import withStyles from "HOC/withStyles"
import { PriceElements } from "ui"
import { formatPriceByComa } from "helpers/units"
import styles from "./styles.css"

const Summary = props => {
    const { calculations, budget, cx } = props
    const { subtotal, discount, total } = calculations
    const percentBudget = ((total / budget) * 100).toFixed(2)

    const renderPrice = price => (
        <Fragment>
            {formatPriceByComa(price, true) + "."}
            <span className={cx("cents")}>{formatPriceByComa(price)}</span>
        </Fragment>
    )
    const priceConfig = [
        [{ label: "Subtotal", value: subtotal }, { label: "Discount", value: -discount }],
        [{ label: "Total Amount", value: total, isBig: true }]
    ]

    return (
        <div className={cx("root")}>
            <h2 className={cx("header")}>Summary</h2>
            <div className={cx("content")}>
                <div className={cx("budget")}>
                    {!!budget && (
                        <Fragment>
                            <span className={cx("percent")}>Budget: {percentBudget}%</span>
                            <span className={cx("range")}>
                                ($ {renderPrice(total)} of {renderPrice(budget)})
                            </span>
                            <div className={cx("progressBar")}>
                                <div
                                    className={cx("progress", { outOfRange: percentBudget > 100 })}
                                    style={{ width: `${percentBudget}%` }}
                                />
                            </div>
                        </Fragment>
                    )}
                </div>
                <div className={cx("calculation")}>
                    <PriceElements config={priceConfig} />
                </div>
            </div>
        </div>
    )
}

export default withStyles(Summary, styles)
