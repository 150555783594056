const ns = "modals/addProduct/"

export const GET_PRODUCT_REQUEST = ns + "GET_PRODUCT_REQUEST"
export const GET_PRODUCT_SUCCESS = ns + "GET_PRODUCT_SUCCESS"
export const GET_PRODUCT_FAILURE = ns + "GET_PRODUCT_FAILURE"
export const RESET_PRODUCT = ns + "RESET_PRODUCT"

export const GET_PRODUCTS_REQUEST = ns + "GET_PRODUCTS_REQUEST"
export const GET_PRODUCTS_SUCCESS = ns + "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCTS_FAILURE = ns + "GET_PRODUCTS_FAILURE"
export const RESET_PRODUCTS = ns + "RESET_PRODUCTS"

export const GET_SERVICES_REQUEST = ns + "GET_SERVICES_REQUEST"
export const GET_SERVICES_SUCCESS = ns + "GET_SERVICES_SUCCESS"
export const GET_SERVICES_FAILURE = ns + "GET_SERVICES_FAILURE"
export const RESET_SERVICES = ns + "RESET_SERVICES"

export const GET_BRANDS_REQUEST = ns + "GET_BRANDS_REQUEST"
export const GET_BRANDS_SUCCESS = ns + "GET_BRANDS_SUCCESS"
export const GET_BRANDS_FAILURE = ns + "GET_BRANDS_FAILURE"
export const RESET_BRANDS = ns + "RESET_BRANDS"

export const GET_CATEGORIES_REQUEST = ns + "GET_CATEGORIES_REQUEST"
export const GET_CATEGORIES_SUCCESS = ns + "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAILURE = ns + "GET_CATEGORIES_FAILURE"
export const RESET_CATEGORIES = ns + "RESET_CATEGORIES"

export const GET_MORE_PRODUCTS_REQUEST = ns + "GET_MORE_PRODUCTS_REQUEST"
export const GET_MORE_PRODUCTS_SUCCESS = ns + "GET_MORE_PRODUCTS_SUCCESS"
export const GET_MORE_PRODUCTS_FAILURE = ns + "GET_MORE_PRODUCTS_FAILURE"

export const GET_MORE_SERVICES_REQUEST = ns + "GET_MORE_SERVICES_REQUEST"
export const GET_MORE_SERVICES_SUCCESS = ns + "GET_MORE_SERVICES_SUCCESS"
export const GET_MORE_SERVICES_FAILURE = ns + "GET_MORE_SERVICES_FAILURE"
