import React, { useState, useMemo, Fragment, useContext, useEffect } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"

import {
    Table,
    H1,
    Panel,
    EmptyList,
    SnakeLoader,
    TableHead,
    TableHeadCell,
    useTableSort,
    TableSortableHeadCell,
    TableBody,
    TableRowWithExpandedDetails,
    TableCell,
    Status,
    TableDateCell,
    GridIcon,
    AlertErrorIcon,
    TableCellWithExpandButton,
    useTableFilterBarSearch,
    useTableFilterBarMultiselect,
    TableFilterBar,
    TableFilterBarSearch,
    TableFilterBarSelect,
    useTableFilterBarMoreFilters,
    TableFilterBarMoreFiltersButton,
    TableFilterBarMoreFilters,
    TableFilterBarMoreFiltersGrid,
    TableFilterBarMoreFiltersGridItem
} from "@butterfly-frontend/ui"
import { DateRangePicker, Pagination } from "ui"
import { formatDate, toApiDate } from "helpers/date"

import { SelectUser, SelectWarehouse } from "ui/Filters"

import { Header, ShipmentDetails, ShipmentAddress } from "modules/WmsModule/components"
import useTableLogic from "modules/WmsModule/hooks/useTableLogic"
import { useShipmentList } from "modules/WmsModule/hooks/api/useShipment"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"
import CLIENT_RETURN_SHIPMENT_STATUSES from "modules/WmsModule/constants/clientReturnShipmentStatuses"
import uniqueElementsFilter from "modules/WmsModule/helpers/uniqueElementsFilter"
import STATUS_SELECT_OPTIONS from "modules/WmsModule/constants/shipmentStatusSelectOptions"
import SHIPMENT_TYPE_SELECT_OPTIONS from "modules/WmsModule/constants/shipmentTypeSelectOptions"
import ListFiltersContext from "modules/WmsModule/contexts/ListFiltersContext"
import SalesOrderReturnDetails from "modules/OrdersModule/components/SalesOrderReturnDetails"
import useChangeShipmentStatusOnListCache from "modules/WmsModule/hooks/cache/useChangeShipmentStatusOnListCache"
import { checkIfShipmentIsCancelled, checkWhatTypeOfShipmentCanBeSeen } from "modules/WmsModule/helpers/shipment"
import { PERMISSIONS } from "constants/index"
import withPermissions from "HOC/withPermissions"

import styles from "./LogisticsListPage.module.css"
import dateRangePickerStyles from "./overrides/dateRangePicker.module.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.module.css"

const TABLE_COLUMNS = {
    id: { minWidth: 100, width: "25%" },
    warehouseFrom: { minWidth: 100, width: "18%" },
    destination: { minWidth: 100, width: "15%" },
    itemsCount: { minWidth: 80, width: "5%" },
    status: { minWidth: 110, width: "12%" },
    date: { minWidth: 120, width: "10%" },
    createdBy: { minWidth: 100, width: "10%" },
    detailsButton: { minWidth: 85, width: 85 }
}

const LogisticsListPage = ({ canAccess }) => {
    const [expandedRowId, setExpandedRowId] = useState(null)
    const assignedWarehouseIds = useSelector(state => state.loginStore.assignedWarehouseIds)
    const { sortState, onChangeSort } = useTableSort({ initialState: { direction: "desc", by: "created_at" } })
    const canAccessIncomingStockItemShipments = useMemo(() => canAccess(PERMISSIONS.context.INCOMING_SHIPMENTS), [])
    const canAccessShipmentsAndReceivings = useMemo(() => canAccess(PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS), [])

    const {
        set: setFiltersContext,
        values: { logisticsList: filtersFromContext }
    } = useContext(ListFiltersContext)

    const availableShipmentTypeKeys = useMemo(
        () =>
            checkWhatTypeOfShipmentCanBeSeen({
                canSeeRegularShipments: canAccessShipmentsAndReceivings,
                canSeeShipmentsFromPO: canAccessIncomingStockItemShipments
            }),
        []
    )

    const filterBarSearchProps = useTableFilterBarSearch({ initialValue: filtersFromContext.query })
    const statusFilterBarMultiselectProps = useTableFilterBarMultiselect({
        initialOptions: STATUS_SELECT_OPTIONS,
        initialSelectedIds: filtersFromContext.statuses.map(({ id }) => id)
    })
    const typeFilterBarMultiSelectProps = useTableFilterBarMultiselect({
        initialOptions: SHIPMENT_TYPE_SELECT_OPTIONS.filter(selectOption =>
            availableShipmentTypeKeys.includes(selectOption.id)
        ),
        initialSelectedIds: filtersFromContext.types.map(({ id }) => id)
    })

    const selectedStatuses = statusFilterBarMultiselectProps.selectedValues
    const selectedTypes = typeFilterBarMultiSelectProps.selectedValues
    const querySearch = filterBarSearchProps.debouncedValue

    const {
        moreFiltersButtonProps,
        moreFiltersComponentProps,
        ...filterBarMoreFiltersProps
    } = useTableFilterBarMoreFilters({
        emptyFilterValues: {
            createdBy: [],
            sourceWarehouses: [],
            destinationWarehouses: [],
            date: null
        },
        initialFilterValues: {
            createdBy: filtersFromContext.createdBy,
            sourceWarehouses: filtersFromContext.sourceWarehouses,
            destinationWarehouses: filtersFromContext.destinationWarehouses,
            date: filtersFromContext.date
        },
        canClear: true
    })

    useEffect(() => {
        const { sourceWarehouses, destinationWarehouses, date, createdBy } = filterBarMoreFiltersProps.savedFilterValues

        setFiltersContext(prevState => ({
            ...prevState,
            logisticsList: {
                query: querySearch,
                types: selectedTypes,
                statuses: selectedStatuses,
                sourceWarehouses,
                destinationWarehouses,
                date,
                createdBy
            }
        }))
    }, [querySearch, selectedTypes, selectedStatuses, filterBarMoreFiltersProps.savedFilterValues])

    const filters = useMemo(() => {
        const { sourceWarehouses, destinationWarehouses, date, createdBy } = filterBarMoreFiltersProps.savedFilterValues
        const mapToCommaSeparatedIdsString = array => array.map(({ id }) => id).join(",")
        const typeParam =
            selectedTypes.length > 0 ? mapToCommaSeparatedIdsString(selectedTypes) : availableShipmentTypeKeys.join(",")

        return {
            query: querySearch,
            type: typeParam,
            statuses: mapToCommaSeparatedIdsString(selectedStatuses),
            source_warehouses: mapToCommaSeparatedIdsString(sourceWarehouses),
            destination_warehouses: mapToCommaSeparatedIdsString(destinationWarehouses),
            creators: mapToCommaSeparatedIdsString(createdBy),
            ...(date
                ? {
                      created_from: date ? toApiDate(date.startDate) : "",
                      created_to: date ? toApiDate(date.endDate) : ""
                  }
                : {})
        }
    }, [querySearch, selectedTypes, selectedStatuses, filterBarMoreFiltersProps.savedFilterValues])

    const { data, fetchStatus, fetchFlags, query, pagination } = useTableLogic({
        sort: sortState,
        useQueryList: useShipmentList,
        filters,
        reactQueryProps: {
            onSuccess: () => setExpandedRowId(null),
            enabled: availableShipmentTypeKeys.length > 0
        }
    })

    const changeShipmentStatusOnListCache = useChangeShipmentStatusOnListCache({ queryKey: query.queryKey })

    return (
        <Fragment>
            <Header>
                <H1 withLeftBorder>Logistics</H1>
            </Header>
            <Panel>
                <TableFilterBar
                    moreFiltersComponent={
                        <TableFilterBarMoreFilters {...moreFiltersComponentProps}>
                            <TableFilterBarMoreFiltersGrid>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectWarehouse
                                        setValue={value =>
                                            filterBarMoreFiltersProps.onChange("sourceWarehouses", value)
                                        }
                                        value={filterBarMoreFiltersProps.selectedFilterValues.sourceWarehouses}
                                        label="From"
                                        placeholder="All"
                                        warehouseType="active"
                                        multiselect
                                        customStyles={selectWithSearchStyles}
                                        warehouseIds={assignedWarehouseIds}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectWarehouse
                                        setValue={value =>
                                            filterBarMoreFiltersProps.onChange("destinationWarehouses", value)
                                        }
                                        value={filterBarMoreFiltersProps.selectedFilterValues.destinationWarehouses}
                                        label="To"
                                        placeholder="All"
                                        warehouseType="active"
                                        multiselect
                                        customStyles={selectWithSearchStyles}
                                        warehouseIds={assignedWarehouseIds}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                                <TableFilterBarMoreFiltersGridItem>
                                    <DateRangePicker
                                        title="- Enter date range -"
                                        label="Date"
                                        ranges={
                                            filterBarMoreFiltersProps.selectedFilterValues.date || {
                                                startDate: "",
                                                endDate: ""
                                            }
                                        }
                                        resetRanges={() => filterBarMoreFiltersProps.onChange("date", null)}
                                        onChange={({ range1 }) => filterBarMoreFiltersProps.onChange("date", range1)}
                                        customStyles={dateRangePickerStyles}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                                <TableFilterBarMoreFiltersGridItem>
                                    <SelectUser
                                        handleSelect={({ target: { value } }) =>
                                            filterBarMoreFiltersProps.onChange("createdBy", value)
                                        }
                                        label="By"
                                        placeholder="Search user"
                                        name="created_by"
                                        value={filterBarMoreFiltersProps.selectedFilterValues.createdBy}
                                        customStyles={selectWithSearchStyles}
                                    />
                                </TableFilterBarMoreFiltersGridItem>
                            </TableFilterBarMoreFiltersGrid>
                        </TableFilterBarMoreFilters>
                    }
                >
                    <TableFilterBarSearch placeholder="Search..." {...filterBarSearchProps} />
                    <TableFilterBarSelect
                        labelSelectAll="All"
                        placeholder="Type"
                        {...typeFilterBarMultiSelectProps.componentProps}
                    />
                    <TableFilterBarSelect
                        labelSelectAll="All statuses"
                        placeholder="Status"
                        {...statusFilterBarMultiselectProps.componentProps}
                    />
                    <TableFilterBarMoreFiltersButton {...moreFiltersButtonProps} />
                </TableFilterBar>
                {fetchStatus.isLoading && <SnakeLoader classes={{ root: styles.loader }} />}
                {availableShipmentTypeKeys.length === 0 && (
                    <EmptyList Icon={GridIcon} label="You don't have an access to this resource." />
                )}
                {fetchStatus.isLoaded && (
                    <Table>
                        <div className={styles.table}>
                            <TableHead>
                                <TableHeadCell {...TABLE_COLUMNS.id}>ID</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.warehouseFrom}>FROM</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.destination}>TO</TableHeadCell>
                                <TableSortableHeadCell
                                    {...TABLE_COLUMNS.itemsCount}
                                    sortState={sortState}
                                    columnKey="shipment_items_count"
                                    onClick={onChangeSort}
                                >
                                    ITEM
                                </TableSortableHeadCell>
                                <TableSortableHeadCell
                                    {...TABLE_COLUMNS.status}
                                    sortState={sortState}
                                    columnKey="status"
                                    onClick={onChangeSort}
                                >
                                    STATUS
                                </TableSortableHeadCell>
                                <TableSortableHeadCell
                                    {...TABLE_COLUMNS.date}
                                    sortState={sortState}
                                    columnKey="created_at"
                                    onClick={onChangeSort}
                                >
                                    DATE
                                </TableSortableHeadCell>
                                <TableSortableHeadCell
                                    {...TABLE_COLUMNS.createdBy}
                                    sortState={sortState}
                                    columnKey="created_by"
                                    onClick={onChangeSort}
                                >
                                    BY
                                </TableSortableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.detailsButton} />
                            </TableHead>
                            <TableBody>
                                {fetchFlags.isEmptyList && <EmptyList Icon={GridIcon} label="List is empty." />}
                                {fetchFlags.isEmptyListWithFilter && (
                                    <EmptyList Icon={GridIcon} label="No records found for selected filters." />
                                )}
                                {fetchStatus.isLoaded &&
                                    data.map(logisticItem => {
                                        const isClientReturn = logisticItem.is_client_return
                                        const shipmentStatuses = isClientReturn
                                            ? CLIENT_RETURN_SHIPMENT_STATUSES
                                            : SHIPMENT_STATUSES
                                        const isStatusCancelled = checkIfShipmentIsCancelled({
                                            shipmentStatus: logisticItem.status.status,
                                            isClientReturn
                                        })
                                        const isStatusOnHold =
                                            !isClientReturn && logisticItem.status === SHIPMENT_STATUS_KEYS.ON_HOLD
                                        const status = shipmentStatuses[logisticItem.status.status]

                                        const rowClasses = { root: { [styles.inactiveRow]: isStatusCancelled } }
                                        const cellClasses = {
                                            root: [styles.cell, { [styles.inactiveCell]: isStatusCancelled }]
                                        }
                                        const onHoldInactiveCellClasses = {
                                            root: [
                                                styles.cell,
                                                { [styles.inactiveCell]: isStatusCancelled || isStatusOnHold }
                                            ]
                                        }
                                        const idCellClasses = {
                                            root: [styles.idCell, { [styles.alertCell]: isStatusCancelled }]
                                        }
                                        const sourceWarehousesNames = logisticItem.source_warehouses
                                            .map(warehouse => warehouse.name)
                                            .filter(uniqueElementsFilter)
                                            .join(", ")

                                        return (
                                            <TableRowWithExpandedDetails
                                                key={logisticItem.id}
                                                classNames={rowClasses}
                                                isExpanded={expandedRowId === logisticItem.id}
                                                detailsComponent={
                                                    isClientReturn ? (
                                                        <SalesOrderReturnDetails
                                                            shipmentId={logisticItem.id}
                                                            onStatusChanged={changeShipmentStatusOnListCache}
                                                        />
                                                    ) : (
                                                        <ShipmentDetails shipmentId={logisticItem.id} />
                                                    )
                                                }
                                            >
                                                <TableCell {...TABLE_COLUMNS.id} classes={idCellClasses}>
                                                    {isStatusCancelled && <AlertErrorIcon />}
                                                    {logisticItem.uuid}
                                                </TableCell>
                                                <TableCell {...TABLE_COLUMNS.warehouseFrom} classes={cellClasses}>
                                                    {!!logisticItem.purchase_order ? (
                                                        <ShipmentAddress
                                                            shipmentAddress={logisticItem.source_address}
                                                        />
                                                    ) : (
                                                        <div
                                                            className={styles.warehouseFrom}
                                                            title={sourceWarehousesNames}
                                                        >
                                                            {sourceWarehousesNames}
                                                        </div>
                                                    )}
                                                </TableCell>
                                                <TableCell
                                                    {...TABLE_COLUMNS.destination}
                                                    classes={onHoldInactiveCellClasses}
                                                >
                                                    {logisticItem.destination_warehouse &&
                                                        logisticItem.destination_warehouse.name}
                                                    {logisticItem.destination_address &&
                                                        ([
                                                            logisticItem.destination_address.city,
                                                            logisticItem.destination_address.state,
                                                            logisticItem.destination_address.country
                                                        ]
                                                            .filter(item => item)
                                                            .join(", ") ||
                                                            "-")}
                                                </TableCell>
                                                <TableCell {...TABLE_COLUMNS.itemsCount} classes={cellClasses}>
                                                    {logisticItem.shipment_items_count}
                                                </TableCell>
                                                <TableCell {...TABLE_COLUMNS.status}>
                                                    <Status theme={status.theme} color={status.color}>
                                                        {status.label}
                                                    </Status>
                                                </TableCell>
                                                <TableDateCell
                                                    {...TABLE_COLUMNS.date}
                                                    classes={onHoldInactiveCellClasses}
                                                >
                                                    {formatDate(logisticItem.status.updated_at)}
                                                </TableDateCell>
                                                <TableDateCell {...TABLE_COLUMNS.createdBy} classes={cellClasses}>
                                                    {logisticItem.created_by
                                                        ? logisticItem.created_by.full_name
                                                        : "N/A"}
                                                </TableDateCell>
                                                <TableCellWithExpandButton
                                                    isExpanded={expandedRowId === logisticItem.id}
                                                    onClick={() =>
                                                        setExpandedRowId(prevState =>
                                                            prevState !== logisticItem.id ? logisticItem.id : null
                                                        )
                                                    }
                                                />
                                            </TableRowWithExpandedDetails>
                                        )
                                    })}
                            </TableBody>
                            {pagination.canDisplay && (
                                <div className={styles.paginationWrapper}>
                                    <Pagination {...pagination} />
                                </div>
                            )}
                        </div>
                    </Table>
                )}
            </Panel>
        </Fragment>
    )
}

LogisticsListPage.propTypes = {
    canAccess: PropTypes.func.isRequired
}

export default withPermissions(LogisticsListPage)
