import React from "react"

import Checkbox from "ui/Checkbox"

import withStyles from "HOC/withStyles"
import styles from "./ListItem.css"
import checkboxStyles from "./overrides/checkboxStyles.css"
import { shortenString } from "helpers/string"

const ListItem = props => {
    const { cx, multiselect, item, active, chosen, setValue, setListCursor, listValueMaxLength } = props
    const { label = "", secondLabel = "", renderSticker } = item

    return (
        <li
            data-active={active}
            className={cx("root", { multiselect, active, chosen })}
            onClick={setValue}
            onMouseEnter={setListCursor}
            title={`${label}${secondLabel ? `, ${secondLabel}` : ""}`}
        >
            {multiselect && <Checkbox customStyles={checkboxStyles} checked={chosen} onChange={() => {}} />}
            <span className={cx("label")}>{listValueMaxLength ? shortenString(label, listValueMaxLength) : label}</span>
            {secondLabel && <span>, {secondLabel}</span>}
            {renderSticker && renderSticker()}
        </li>
    )
}

export default withStyles(ListItem, styles)
