import React, { useState } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import useStyles from "helpers/getCxFromStyles"
import fetch from "helpers/fetch"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import { useActions } from "hooks"

import { Button, Modal, SelectWithSearch } from "ui"

import styles from "./ManageModal.css"
import selectWithSearchStyles from "./overrides/selectWithSearch.css"

const ManageModal = ({ onClose, onSuccess, product }) => {
    const cx = useStyles(styles)
    const actions = useActions({ showSuccessNotification, showErrorNotification })

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [groups, setGroups] = useState([])
    const [groupsPagination, setGroupsPagination] = useState({ current_page: 0, last_page: 1 })
    const [group, setGroup] = useState(product.group)

    const products = idx(group, _ => _.products) || []

    const generateGroupLabel = item => item.name || (item.products || []).map(product => product.name).join(", ")

    const handleSave = async () => {
        setIsSubmitting(true)

        try {
            await fetch.patch(`/products/${product.id}`, { group_id: idx(group, _ => _.id) || null })
            actions.showSuccessNotification()
            onSuccess && onSuccess()
            onClose()
        } catch (error) {
            actions.showErrorNotification()
        } finally {
            setIsSubmitting(false)
        }
    }

    const fetchGroups = (query, page) => {
        const formatData = data => {
            return data.map(item => ({
                value: item,
                label: generateGroupLabel(item)
            }))
        }

        fetch.get(`/groups?query=${query}&page=${page}&length=15`).then(data => {
            setGroupsPagination({ ...data.meta, current_page: page })
            setGroups(
                page === 1
                    ? formatData([{ id: "new", name: "-- Create new group --" }, ...data.data])
                    : [...groups, ...formatData(data.data)]
            )
        })
    }

    return (
        <Modal isOpen closeModal={onClose} preventClickOutside>
            <p className={cx("header")}>Manage typology</p>

            <SelectWithSearch
                value={generateGroupLabel(group || {})}
                values={groups}
                pagination={groupsPagination}
                setValue={({ value }) => setGroup(value)}
                placeholder="-- No group --"
                fetchValues={fetchGroups}
                customStyles={selectWithSearchStyles}
            />

            <div className={cx("products")}>
                {products.map(item => (
                    <div className={cx("product")} key={item.name}>
                        <div className={cx("image-wrapper")}>
                            <img src={item.media} alt="product" />
                        </div>
                        <span className={cx("name")}>{item.name}</span>
                    </div>
                ))}
            </div>

            <div className={cx("actions")}>
                <Button onClick={onClose} label="Cancel" className={cx("link-button")} />
                <Button label="Save" className={cx("first-button")} onClick={handleSave} isLoading={isSubmitting} />
            </div>
        </Modal>
    )
}

ManageModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    product: PropTypes.object.isRequired
}

export default ManageModal
