import * as constants from "../constants"
import * as fetchStatus from "helpers/fetchStatus"
import { getNotesUpdater } from "helpers/notes"

const initialState = {
    statesList: [],
    countriesList: [],
    clients: {
        links: {},
        meta: {},
        data: [],
        isLoaded: false,
        isError: false,
        nextFetchStatus: fetchStatus.initialState()
    },

    client: {
        data: {},
        isLoading: false,
        isLoaded: false,
        isError: false,
        errors: null,
        deleted: false
    }
}

export function ClientReducer(state = initialState, action) {
    const notesUpdater = getNotesUpdater({ state, action, validType: "client", key: "client" })

    switch (action.type) {
        case constants.ADD_NOTE_SUCCESS:
            return notesUpdater((state, action) => ({
                ...state.client,
                data: { ...state.client.data, notes: [...state.client.data.notes, action.payload.data] }
            }))

        case constants.EDIT_NOTE_SUCCESS:
            return notesUpdater((state, action) => ({
                ...state.client,
                data: {
                    ...state.client.data,
                    notes: state.client.data.notes.map(note => {
                        const updatedData = action.payload.data
                        const isForUpdate = note.id === updatedData.id

                        return isForUpdate ? { ...note, ...updatedData } : note
                    })
                }
            }))

        case constants.DELETE_NOTE_SUCCESS:
            return notesUpdater((state, action) => ({
                ...state.client,
                data: {
                    ...state.client.data,
                    notes: state.client.data.notes.filter(note => note.id !== action.payload.data.id)
                }
            }))

        case constants.GET_CLIENTS_START:
            return {
                ...state,
                clients: {
                    ...state.clients,
                    isLoading: true,
                    isLoaded: false,
                    isError: false,
                    nextFetchStatus: fetchStatus.requestState()
                }
            }

        case constants.GET_STATES_START:
        case constants.GET_COUNTRIES_START:
            return {
                ...state
            }

        case constants.GET_CLIENT_BY_ID_START:
        case constants.DELETE_CLIENT_START:
            return {
                ...state,
                client: {
                    ...state.client,
                    isLoading: true,
                    errors: null,
                    isLoaded: false,
                    isError: false,
                    deleted: false
                }
            }

        case constants.GET_CLIENTS_SUCCESS:
            const { data, links, meta } = action.payload.data
            const { current_page, last_page } = meta

            let clientsData = data || []

            if (current_page > 1 && current_page <= last_page) {
                clientsData = [...state.clients.data, ...data]
            }

            return {
                ...state,
                clients: {
                    ...state.clients,
                    isLoading: false,
                    isLoaded: true,
                    data: clientsData,
                    links: links,
                    meta: meta,
                    nextFetchStatus: fetchStatus.successState()
                }
            }

        case constants.GET_STATES_SUCCESS:
            return {
                ...state,
                statesList: action.payload.data
            }

        case constants.GET_COUNTRIES_SUCCESS:
            return {
                ...state,
                countriesList: action.payload.data
            }

        case constants.REMOVE_ALL_FAVORITES_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    data: {
                        ...state.client.data,
                        favorites: []
                    }
                }
            }

        case constants.REMOVE_FAVORITE_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    data: {
                        ...state.client.data,
                        favorites: state.client.data.favorites.filter(el => el.id !== action.payload)
                    }
                }
            }

        case constants.GET_CLIENT_BY_ID_SUCCESS:
            return {
                ...state,
                client: {
                    ...state.client,
                    data: action.payload.data.data || {},
                    errors: null,
                    isLoading: false,
                    isLoaded: true
                }
            }

        case constants.GET_CLIENTS_ERROR:
            return {
                ...state,
                clients: {
                    ...state.clients,
                    isLoading: false,
                    isLoaded: false,
                    isError: true,
                    nextFetchStatus: fetchStatus.failureState()
                }
            }
        case constants.GET_STATES_ERROR:
        case constants.GET_COUNTRIES_ERROR:
            return {
                ...state
            }
        case constants.GET_CLIENT_BY_ID_ERROR:
        case constants.DELETE_CLIENT_ERROR:
            return {
                ...state,
                client: {
                    ...state.client,
                    isLoading: false,
                    isLoaded: false,
                    errors: action.payload.data,
                    isError: true,
                    deleted: false
                }
            }

        case constants.DELETE_CLIENT_SUCCESS:
            return {
                ...state,
                client: {
                    ...initialState.client
                }
            }

        default:
            return state
    }
}
