import React, { useEffect, useState, useMemo } from "react"
import { SaveBar } from "ui"
import { IMAGE_ACCEPTED_FORMATS } from "constants/index"
import GalleryUploader from "modules/NewsModule/components/GalleryUploader"
import galleryUploaderStyles from "../GeneralInformations/overrides/GalleryUploader.css"

const Pictures = props => {
    const {
        dirty,
        handleDeleteFromSavedFiles,
        handleDndOrder,
        handlePassUploadedImages,
        handleSubmit,
        isSubmitting,
        mainImageId,
        setFieldValue,
        values
    } = props
    const [isFirstTime, setIsFirstTime] = useState(true)

    useEffect(() => {
        setIsFirstTime(false)
    }, [])

    const handleGallerySetup = () => {
        const tileWidth = 188
        const width = ((document.getElementsByClassName("galleryUploader") || [])[0] || {}).clientWidth

        const config = {}

        config.COLS = Math.floor(width / tileWidth)
        config.COLS = config.COLS < 3 ? 3 : config.COLS
        config.WIDTH = config.COLS * tileWidth

        return config
    }

    const savedImages = useMemo(() => {
        const images = values.images
            .filter(i => !!i)
            .map(el => {
                return { ...el, path: el.url }
            })
        return isFirstTime ? [] : images
    }, [isFirstTime, values])

    return (
        <div>
            <GalleryUploader
                className={"galleryUploader"}
                customStyles={galleryUploaderStyles}
                handleGallerySetup={handleGallerySetup}
                withoutButton={true}
                withTopDropdown={true}
                mainImageId={mainImageId}
                placeholder={{ title: "Add images", subtitle: "(browse or drop your files here)" }}
                savedFiles={savedImages}
                handlePassUploadedImages={data => handlePassUploadedImages(data, { values, setFieldValue })}
                handleDeleteFile={data => handleDeleteFromSavedFiles(data, { values, setFieldValue })}
                handleDndOrder={handleDndOrder}
                acceptFormats={IMAGE_ACCEPTED_FORMATS}
                onCropSuccess={handleSubmit}
            />
            <SaveBar
                message={dirty ? "Unsaved changes" : ""}
                isShown
                onSubmit={handleSubmit}
                isSaving={isSubmitting}
                submitLabel="Save"
            />
        </div>
    )
}

export default Pictures
