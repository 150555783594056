import React, { useMemo, useState, useCallback, Fragment, useEffect } from "react"
import { useQueryClient } from "react-query"
import PropTypes from "prop-types"
import classNames from "classnames"
import _get from "lodash/get"

import {
    TableRowWithExpandedDetails,
    TableCell,
    Button,
    DeleteIcon,
    AcceptedIcon,
    CloudIcon,
    ImageTile,
    ActionsDropList
} from "@butterfly-frontend/ui"

import { StatusSelectProductConditions } from "ui/Filters/SelectProductConditions"
import { useActions } from "hooks"
import { showErrorNotification } from "actions/notification"

import CONDITION_TYPES from "modules/WmsModule/constants/conditionTypes"
import { useShipmentEdit, KEY as SHIPMENT_QUERY_KEY } from "modules/WmsModule/hooks/api/useShipment"
import { useStockItemEdit } from "modules/WmsModule/hooks/api/useStockItem"
import { StockItemDetailsBoxList } from "modules/WmsModule/components"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import { SHIPMENT_PACK_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import TABLE_COLUMNS from "modules/WmsModule/pages/ShipmentDetailsPage/ShipmentInProgressPage/tableColumns"
import styles from "./ShipmentTableRow.module.css"

const REQUIRED_IMAGES_MESSAGE = "At least 1 photo must be added"

const checkItemIsDamaged = type => type === CONDITION_TYPES.DAMAGED

const checkIfAllBoxesArePacked = boxes => (boxes.length > 0 ? boxes.every(box => box.packed) : false)

const ShipmentTableRow = props => {
    const { index, item, shipment, onOpenUploadConditionPhotosModal, isBarcodeReaderEnabled, canEdit } = props

    const actions = useActions({ showErrorNotification })

    const queryClient = useQueryClient()
    const [selectedCondition, setSelectedCondition] = useState(item.condition)
    const [hasNoConditionPhotosError, setHasNoConditionPhotosError] = useState(false)
    const shipmentQueryKey = useMemo(() => [SHIPMENT_QUERY_KEY, { id: shipment.id.toString() }], [shipment.id])

    const isChosenToDamagedItem = checkItemIsDamaged(selectedCondition.type)
    const isDamagedItem = checkItemIsDamaged(item.condition.type)

    const isReadyItem = shipment.isCancelled ? !item.packed : item.packed
    const isDisabledRow = isDamagedItem || isReadyItem
    const isWarningRow = shipment.isCancelled && item.packed

    const replaceStockItemInShipmentCache = useCallback(
        replacementItem => {
            queryClient.setQueryData(shipmentQueryKey, ({ data }) => ({
                data: {
                    ...data,
                    items: data.items.map(cachedItem => (item.id === cachedItem.id ? replacementItem : cachedItem))
                }
            }))
        },
        [queryClient, shipmentQueryKey, item.id]
    )

    const changeConditionItemInShipmentCache = useCallback(
        result => {
            const {
                id,
                images,
                product_condition: { name, color, id: product_condition_id, type }
            } = result.data.condition

            replaceStockItemInShipmentCache({
                ...item,
                condition: {
                    id,
                    name,
                    color,
                    images,
                    product_condition_id,
                    type
                }
            })
        },
        [replaceStockItemInShipmentCache]
    )

    const resetSelectedConditionItem = useCallback(() => {
        setSelectedCondition(item.condition)
    }, [item])

    const stockItemEdit = useStockItemEdit({
        skipInvalidateQueries: true,
        reactQueryProps: {
            onSuccess: changeConditionItemInShipmentCache,
            onError: actions.showErrorNotification
        }
    })

    const isFetchingConditionDamagedItem = stockItemEdit.fetchStatus.isLoading && isChosenToDamagedItem

    const onSelectCondition = ({ value: { id: product_condition_id, name, color, type } }) => {
        if (selectedCondition.product_condition_id === product_condition_id) {
            return
        }

        setSelectedCondition({ id: "", name, color, type, product_condition_id })

        if (!checkItemIsDamaged(type)) {
            stockItemEdit
                .mutate({
                    id: item.id,
                    data: { condition: { product_condition_id } }
                })
                .catch(resetSelectedConditionItem)
        }
    }

    const onRemoveConditionPhoto = imageId => {
        if (isFetchingConditionDamagedItem || isDamagedItem) {
            return
        }

        replaceStockItemInShipmentCache({
            ...item,
            condition: {
                ...item.condition,
                images: item.condition.images.filter(image => image.id !== imageId)
            }
        })
    }

    const onRejectItem = useCallback(() => {
        if (item.condition.images.length === 0) {
            setHasNoConditionPhotosError(true)
            return
        }

        stockItemEdit.mutate({
            id: item.id,
            data: {
                condition: {
                    product_condition_id: selectedCondition.product_condition_id,
                    images_ids: item.condition.images.map(({ id }) => id)
                }
            }
        })
    }, [item.condition.images, item.id, selectedCondition.product_condition_id, stockItemEdit.mutate])

    const changePackingStatusInShipmentCache = useCallback(
        result => {
            const replacedItem = _get(result, "data.items", []).find(({ id }) => id === item.id)
            replaceStockItemInShipmentCache(replacedItem)
        },
        [item, replaceStockItemInShipmentCache]
    )

    const shipmentEdit = useShipmentEdit({
        skipInvalidateQueries: true,
        reactQueryProps: {
            onSuccess: changePackingStatusInShipmentCache,
            onError: actions.showErrorNotification
        }
    })

    const onChangePackingStatus = useCallback(() => {
        shipmentEdit.mutate({
            id: shipment.id,
            data: { [item.packed ? "items_to_be_unpacked" : "items_to_be_packed"]: [item.shipment_stock_item_id] }
        })
    }, [item.packed, shipment.id, shipment.shipment_stock_item_id, shipmentEdit.mutate])

    const { options: boxOptions, isLoading: isBoxOptionsLoading } = useBoxLabelOptions({
        ids: [item.id]
    })

    useEffect(() => {
        setHasNoConditionPhotosError(false)
    }, [item.condition.images])

    useEffect(() => {
        if (!item.packed && checkIfAllBoxesArePacked(item.boxes)) {
            isChosenToDamagedItem ? onRejectItem() : onChangePackingStatus()
        }
    }, [item.boxes, item.packed, isChosenToDamagedItem, onRejectItem, onChangePackingStatus])

    useEffect(() => {
        if (!item.packed && item.entireItemBarcodeRead) {
            isChosenToDamagedItem ? onRejectItem() : onChangePackingStatus()
        }
    }, [item.entireItemBarcodeRead, item.packed, isChosenToDamagedItem, onRejectItem, onChangePackingStatus])

    return (
        <TableRowWithExpandedDetails
            classes={{
                root: classNames(styles.rowRoot, { [styles.warning]: isWarningRow, [styles.disabled]: isDisabledRow }),
                detailsComponent: styles.rowDetails
            }}
            detailsComponent={
                <Fragment>
                    {(hasNoConditionPhotosError || item.condition.images.length > 0) && (
                        <div className={classNames(styles.conditionImageList)}>
                            {hasNoConditionPhotosError && (
                                <span className={styles.noConditionPhotosError}>{REQUIRED_IMAGES_MESSAGE}</span>
                            )}
                            {item.condition.images.map(image => (
                                <ImageTile
                                    key={image.id}
                                    onDelete={() => onRemoveConditionPhoto(image.id)}
                                    image={image}
                                    classes={{ imageBox: styles.conditionImage }}
                                />
                            ))}
                        </div>
                    )}
                    {item.boxes.length > 0 && (
                        <StockItemDetailsBoxList
                            boxes={item.boxes.map(box => ({ ...box, selected: box.packed }))}
                            compactView
                        />
                    )}
                </Fragment>
            }
            isExpanded={item.boxes.length > 0 || item.condition.images.length > 0 || hasNoConditionPhotosError}
        >
            <TableCell {...TABLE_COLUMNS.no} classes={{ root: styles.cell }}>
                {index + 1}
            </TableCell>
            <TableCell {...TABLE_COLUMNS.product} classes={{ root: styles.cell }}>
                <div className={styles.productName}>{item.name}</div>
            </TableCell>
            <TableCell {...TABLE_COLUMNS.id} classes={{ root: styles.cell }}>
                {item.id}
            </TableCell>
            <TableCell {...TABLE_COLUMNS.condition} classes={{ root: styles.conditionCell }}>
                <StatusSelectProductConditions
                    excludedTypes={[CONDITION_TYPES.REMOVED, CONDITION_TYPES.INCOMPLETE]}
                    value={{ ...selectedCondition, label: selectedCondition.name }}
                    onChange={onSelectCondition}
                    disabled={
                        isDamagedItem ||
                        isReadyItem ||
                        shipmentEdit.fetchStatus.isLoading ||
                        isFetchingConditionDamagedItem ||
                        !canEdit()
                    }
                    isLoading={stockItemEdit.fetchStatus.isLoading && !checkItemIsDamaged(selectedCondition.type)}
                />
            </TableCell>
            <TableCell classes={{ root: styles.cell }} {...TABLE_COLUMNS.location}>
                {item.location}
            </TableCell>
            {isChosenToDamagedItem || isDamagedItem ? (
                <TableCell {...TABLE_COLUMNS.packAction} classes={{ root: styles.packActionCell }}>
                    <Button
                        color="red"
                        variant="flat"
                        size="small"
                        Icon={CloudIcon}
                        onClick={onOpenUploadConditionPhotosModal}
                        disabled={stockItemEdit.fetchStatus.isLoading || isDamagedItem || !canEdit()}
                    >
                        Add photos
                    </Button>
                    <div className={styles.buttonDivider} />
                    <Button
                        size="small"
                        color={isDamagedItem ? "red" : "white"}
                        iconPosition="right"
                        Icon={DeleteIcon}
                        onClick={onRejectItem}
                        disabled={stockItemEdit.fetchStatus.isLoading || isDamagedItem || !canEdit()}
                        isLoading={isFetchingConditionDamagedItem}
                    >
                        {isBarcodeReaderEnabled ? undefined : "Rejected"}
                    </Button>
                </TableCell>
            ) : (
                <TableCell {...TABLE_COLUMNS.packAction} classes={{ root: styles.packActionCell }}>
                    <Button
                        size="small"
                        iconPosition="right"
                        color={
                            shipment.isCancelled ? (item.packed ? "white" : "green") : item.packed ? "green" : "white"
                        }
                        Icon={AcceptedIcon}
                        onClick={onChangePackingStatus}
                        disabled={
                            stockItemEdit.fetchStatus.isLoading ||
                            shipment.isPacked ||
                            shipment.isUnpackConfirmed ||
                            !canEdit()
                        }
                        isLoading={shipmentEdit.fetchStatus.isLoading}
                    >
                        {isBarcodeReaderEnabled ? undefined : shipment.isCancelled ? "Unpacked" : "Packed"}
                    </Button>
                    <div className={styles.buttonDivider} />
                    <ActionsDropList
                        actions={boxOptions}
                        isLoading={isBoxOptionsLoading}
                        classes={{ dots: styles.threeDots }}
                    />
                </TableCell>
            )}
        </TableRowWithExpandedDetails>
    )
}

ShipmentTableRow.propTypes = {
    index: PropTypes.number.isRequired,
    shipment: PropTypes.shape({
        id: PropTypes.number.isRequired,
        isCancelled: PropTypes.bool,
        isPacked: PropTypes.bool,
        isUnpackConfirmed: PropTypes.bool
    }).isRequired,
    item: SHIPMENT_PACK_ITEM_PROP_TYPE,
    onOpenUploadConditionPhotosModal: PropTypes.func.isRequired,
    isBarcodeReaderEnabled: PropTypes.bool,
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(ShipmentTableRow, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
