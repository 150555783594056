import * as constants from "constants/index"
import fetch from "helpers/fetch"

export const getCombinationFindStart = () => ({ type: constants.GET_COMBINATION_FIND_START })

export const getCombinationFindSuccess = data => ({
    type: constants.GET_COMBINATION_FIND_SUCCESS,
    payload: { data }
})

export const getCombinationFindError = error => ({
    type: constants.GET_COMBINATION_FIND_ERROR,
    payload: { error }
})

export const getCombinationFind = ({ attributeValueIds, productId }) => dispatch => {
    dispatch(getCombinationFindStart())
    fetch
        .get(`/combinations/find?attributeValueIds=${attributeValueIds}&productId=${productId}`)
        .then(data => dispatch(getCombinationFindSuccess(data)))
        .catch(error => dispatch(getCombinationFindError(error)))
}

export const getCombinationByIdStart = () => ({ type: constants.GET_COMBINATION_BY_ID_START })

export const getCombinationByIdSuccess = data => ({
    type: constants.GET_COMBINATION_BY_ID_SUCCESS,
    payload: { data }
})

export const getCombinationByIdError = error => ({
    type: constants.GET_COMBINATION_BY_ID_ERROR,
    payload: { error }
})

export const getCombinationById = id => dispatch => {
    dispatch(getCombinationByIdStart())
    fetch
        .get(`/combinations/${id}`)
        .then(data => dispatch(getCombinationByIdSuccess(data)))
        .catch(error => dispatch(getCombinationByIdError(error)))
}
