import React, { Component } from "react"
import withStyles from "HOC/withStyles"
import styles from "./purchases.module.css"
import { Input, DatePickerWithInput } from "ui"
import customInputStyles from "./overrides/input.module.css"

class Purchases extends Component {
    render() {
        const { cx, dates, errors, values, setValue, handleSelectDate } = this.props
        const {
            purchase_history_amount,
            total_purchase_amount,
            average_purchase,
            showroom_visits,
            online_visits
        } = values

        return (
            <section className={cx("root")}>
                <h2>Purchases</h2>
                <ul>
                    <li>
                        <Input
                            innerLabel="$"
                            isInnerLabelLeft={true}
                            customStyles={customInputStyles}
                            type="number"
                            value={purchase_history_amount}
                            name="purchase_history_amount"
                            id="purchase_history_amount"
                            error={errors.purchase_history_amount}
                            onChange={params => setValue("purchase_history_amount", params.event.target.value)}
                            label="Purchase history amount"
                        />
                    </li>
                    <li>
                        <Input
                            innerLabel="$"
                            isInnerLabelLeft={true}
                            customStyles={customInputStyles}
                            type="text"
                            value={total_purchase_amount}
                            name="total_purchase_amount"
                            id="total_purchase_amount"
                            label="Total Purchase Amount"
                            error={errors.total_purchase_amount}
                            onChange={params => setValue("total_purchase_amount", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <Input
                            innerLabel="$"
                            isInnerLabelLeft={true}
                            customStyles={customInputStyles}
                            type="text"
                            value={average_purchase}
                            name="average_purchase"
                            id="average_purchase"
                            label="Average purchase"
                            error={errors.average_purchase}
                            onChange={params => setValue("average_purchase", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <DatePickerWithInput
                            date={dates.last_purchase_date}
                            maxDate={new Date()}
                            label="Last purchase:"
                            calendarAboveInput={true}
                            handleSelectDate={date => {
                                handleSelectDate("last_purchase_date", date)
                                setValue("last_purchase_date", date)
                            }}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={customInputStyles}
                            type="text"
                            value={showroom_visits}
                            name="showroom_visits"
                            id="showroom_visits"
                            label="Division Visits"
                            error={errors.showroom_visits}
                            onChange={params => setValue("showroom_visits", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <DatePickerWithInput
                            date={dates.last_showroom_visits}
                            maxDate={new Date()}
                            label="Last Division Visit:"
                            calendarAboveInput={true}
                            handleSelectDate={date => {
                                handleSelectDate("last_showroom_visits", date)
                                setValue("last_showroom_visits", date)
                            }}
                        />
                    </li>
                    <li>
                        <Input
                            customStyles={customInputStyles}
                            type="text"
                            value={online_visits}
                            name="online_visits"
                            id="online_visits"
                            label="Online Visits"
                            error={errors.online_visits}
                            onChange={params => setValue("online_visits", params.event.target.value)}
                        />
                    </li>
                    <li>
                        <DatePickerWithInput
                            date={dates.last_online_visits}
                            maxDate={new Date()}
                            label="Last Online Visit:"
                            calendarAboveInput={true}
                            handleSelectDate={date => {
                                handleSelectDate("last_online_visits", date)
                                setValue("last_online_visits", date)
                            }}
                        />
                    </li>
                </ul>
            </section>
        )
    }
}

export default withStyles(Purchases, styles)
