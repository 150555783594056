import * as constants from "./../../../constants/index"
import assignedTo from "./assignedTo"
import vendors from "./vendors"

const reducers = {
    assignedTo,
    vendors
}

export const INITIAL_STATE = Object.keys(reducers).reduce(
    (acc, name) => ({ ...acc, [name]: reducers[name](undefined, {}) }),
    {}
)

const initState = () => ({ ...INITIAL_STATE })

const filters = (state = initState(), action) => {
    switch (action.type) {
        case constants.CLEAR_MOREFILTERS_GROUP:
            return initState()

        case constants.REPLACE_MOREFILTERS_GROUP:
            return { ...state, ...action.payload }

        default:
            return Object.keys(reducers).reduce(
                (acc, name) => ({ ...acc, [name]: reducers[name](state[name], action) }),
                {}
            )
    }
}

export default filters
