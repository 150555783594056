import React, { useState, useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import PropTypes from "prop-types"
import _merge from "lodash/merge"

import { Modal, Button, ConfirmModalView } from "@butterfly-frontend/ui"

import { useActions } from "hooks"
import { showErrorNotification } from "actions/notification"
import apiErrorsAdapter from "modules/WmsModule/helpers/apiErrorsAdapter"

import {
    ContactSectionForm,
    WarehouseManagerSectionForm,
    ManagerActivitySectionForm
} from "modules/WmsModule/modals/components"
import { useWarehouseEdit, useWarehouseCreate } from "modules/WmsModule/hooks/api/useWarehouse"

import initialValues from "./initialValues"
import validationSchema from "./validationSchema"

import styles from "./AddEditWarehouse.module.css"

const warehouseToInitialValues = warehouse =>
    _merge(
        {},
        {
            name: warehouse.name,
            code: warehouse.code,
            phone: warehouse.phone,
            email: warehouse.email,
            type: warehouse.type,
            primary: warehouse.primary,
            active: warehouse.active,
            address: { ...warehouse.address },
            manager: { ...warehouse.manager },
            isPrimaryChangeConfirmed: false,
            autonomous: warehouse.autonomous,
            is_default_for_po: warehouse.is_default_for_po
        }
    )

const AddEditWarehouse = ({ warehouse, onClose, onSuccess, isEdit = false }) => {
    const countriesList = useSelector(state => state.clientStore.countriesList)
    const statesList = useSelector(state => state.clientStore.statesList)
    const actions = useActions({ showErrorNotification })

    const [isPrimaryConfirmModalOpen, setIsPrimaryConfirmModalOpen] = useState(false)
    const [wasPrimaryConfirmModalOpen, setWasPrimaryConfirmModalOpen] = useState(false)

    const modalBackgroundRef = useRef(null)

    const scrollToModalBottom = () => {
        modalBackgroundRef.current.scrollTo(
            0,
            modalBackgroundRef.current.scrollHeight - modalBackgroundRef.current.clientHeight
        )
    }

    const warehouseCreate = useWarehouseCreate()
    const warehouseEdit = useWarehouseEdit()

    const submitAction = isEdit
        ? data => warehouseEdit.mutate({ data, id: warehouse.id })
        : data => warehouseCreate.mutate({ data })

    const formik = useFormik({
        initialValues: isEdit ? warehouseToInitialValues(warehouse) : initialValues,
        validationSchema,
        onSubmit: (formValues, { setSubmitting, setErrors }) => {
            const { isPrimaryChangeConfirmed, ...values } = formValues
            const isPrimary = warehouse && warehouse.primary

            if (!isPrimaryChangeConfirmed && !isPrimary && values.primary) {
                setIsPrimaryConfirmModalOpen(true)

                if (!wasPrimaryConfirmModalOpen) {
                    setWasPrimaryConfirmModalOpen(true)
                }

                return
            }

            return submitAction(values)
                .then(data => {
                    setSubmitting(false)
                    onSuccess(data)
                })
                .catch(error => {
                    setSubmitting(false)
                    setErrors(apiErrorsAdapter(error.errors))
                    actions.showErrorNotification()
                })
        }
    })

    useEffect(() => {
        if (!isPrimaryConfirmModalOpen && wasPrimaryConfirmModalOpen) {
            scrollToModalBottom()
        }
    }, [isPrimaryConfirmModalOpen])

    const modalProps = isPrimaryConfirmModalOpen ? {} : { onClose, title: isEdit ? "Edit warehouse" : "Add warehouse" }

    return (
        <Modal {...modalProps} ref={modalBackgroundRef}>
            {isPrimaryConfirmModalOpen && (
                <ConfirmModalView
                    title="Set this warehouse as primary"
                    onAction={() => {
                        formik.setFieldValue("isPrimaryChangeConfirmed", true)
                        formik.handleSubmit()
                        setIsPrimaryConfirmModalOpen(false)
                    }}
                    onCancel={() => {
                        setIsPrimaryConfirmModalOpen(false)
                    }}
                    contentOnly
                    buttonLabel="Save"
                    buttonColor="blue"
                >
                    Changing the primary warehouse removes the current one.
                </ConfirmModalView>
            )}
            {!isPrimaryConfirmModalOpen && (
                <form className={styles.form} onSubmit={formik.handleSubmit}>
                    <ContactSectionForm
                        formik={formik}
                        countriesList={countriesList}
                        statesList={statesList}
                        isEdit={isEdit}
                    />
                    <WarehouseManagerSectionForm formik={formik} isEdit={isEdit} />
                    <ManagerActivitySectionForm formik={formik} isEdit={isEdit} />
                    <div className={styles.buttonSection}>
                        <Button variant="flat" onClick={onClose} disabled={formik.isSubmitting}>
                            Cancel
                        </Button>
                        <Button type="submit" color="blue" isLoading={formik.isSubmitting}>
                            Save
                        </Button>
                    </div>
                </form>
            )}
        </Modal>
    )
}

AddEditWarehouse.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    isEdit: PropTypes.bool.isRequired,
    warehouse: PropTypes.object
}

export default AddEditWarehouse
