import * as constants from "constants/index"
import brands from "./brands"
import conditions from "./conditions"
import reorderable from "./reorderable"
import priceRange from "./priceRange"
import discount from "./discount"
import manufacturingTime from "./manufacturingTime"
import productionYear from "./productionYear"
import deliveringTime from "./deliveringTime"
import locations from "./locations"
import verification from "./verification"
import availability from "./availability"
import availableOnWeb from "./availableOnWeb"
import withoutPhotos from "./withoutPhotos"
import selections from "./selections"
import deleted from "./deleted"
import clearance from "./clearance"

const reducers = {
    brands,
    conditions,
    reorderable,
    priceRange,
    discount,
    manufacturingTime,
    productionYear,
    deliveringTime,
    locations,
    verification,
    availability,
    withoutPhotos,
    availableOnWeb,
    selections,
    deleted,
    clearance
}

const INITIAL_STATE = Object.keys(reducers).reduce(
    (acc, name) => ({ ...acc, [name]: reducers[name](undefined, {}) }),
    {}
)

const initState = () => ({ ...INITIAL_STATE })

const filters = (state = initState(), action) => {
    switch (action.type) {
        case constants.CLEAR_PRODUCT_ADDITIONAL_FILTERS_GROUP:
            return initState()

        case constants.REPLACE_PRODUCT_ADDITIONAL_FILTERS_GROUP:
            return { ...state, ...action.payload }

        default:
            return Object.keys(reducers).reduce(
                (acc, name) => ({ ...acc, [name]: reducers[name](state[name], action) }),
                {}
            )
    }
}

export default filters
