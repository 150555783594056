import React, { useEffect } from "react"

import ReactSvg from "react-svg"
import Dropzone from "ui/Dropzone"

import withMultipleUploader from "HOC/withMultipleUploader"

import getReadableFileSize from "helpers/getReadableFileSize"

import withStyles from "HOC/withStyles"
import styles from "./attachments.css"

import uploadIcon from "assets/ico-upload-cloud.svg"
import closeIcon from "assets/close-blue.svg"

const Attachment = ({ cx, id, isRemoveDisabled, name, size, handleRemove }) => (
    <li key={id}>
        <p>{name}</p>
        <div className={cx("sizeAndDelete")}>
            {getReadableFileSize(size)}
            <button disabled={isRemoveDisabled} onClick={() => handleRemove(id)}>
                <ReactSvg src={closeIcon} />
            </button>
        </div>
    </li>
)

const UploadingAttachment = ({ cx, title, progress }) => (
    <li className={cx("uploading")}>
        <p>{title}</p>
        <div className={cx("progress")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    </li>
)

const Attachments = props => {
    const {
        cx,
        uploader,
        currentAttachments,
        handleLoading,
        handleRemove,
        acceptFormats = "",
        filesToUpload = []
    } = props
    const { state, actions } = uploader

    useEffect(() => {
        state.isUploading && handleLoading(true)
    }, [state.isUploading])

    useEffect(() => {
        if (filesToUpload.length > 0) {
            actions.handleUpload(filesToUpload)
        }
    }, [filesToUpload])

    return (
        <div className={cx("root")}>
            <Dropzone
                isMultiple
                acceptFormats={acceptFormats}
                isUploading={state.isUploading}
                isSuccess={state.isSuccess}
                progress={state.progress}
                handleUpload={actions.handleUpload}
                handleRemove={actions.handleRemove}
            >
                <div className={cx("dropzone")}>
                    <ReactSvg src={uploadIcon} />
                    Add file<span>(browse or drop your files here)</span>
                </div>
            </Dropzone>
            <ul>
                {currentAttachments.map(({ id, name, size }) => (
                    <Attachment
                        key={`cur-${id}`}
                        cx={cx}
                        id={id}
                        isRemoveDisabled={state.isUploading}
                        name={name}
                        size={size}
                        handleRemove={() => handleRemove(id)}
                    />
                ))}
                {state.addedFile.map(({ title, progress, file }, index) => {
                    const isInProgress = progress < 100

                    return isInProgress ? (
                        <UploadingAttachment key={`upload-${index}`} cx={cx} title={title} progress={progress} />
                    ) : (
                        <Attachment
                            key={`upload-${index}`}
                            cx={cx}
                            id={index}
                            isRemoveDisabled={state.isUploading}
                            name={file.name}
                            size={file.size}
                            handleRemove={handleRemove}
                        />
                    )
                })}
            </ul>
        </div>
    )
}

export default withMultipleUploader(withStyles(Attachments, styles))
