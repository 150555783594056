import React from "react"
import PropTypes from "prop-types"
import {
    Table,
    TableHead,
    TableHeadCell,
    TableBody,
    TableCell,
    TableRow,
    Button,
    TrashIcon,
    Status,
    H3,
    EmptyInternalList,
    ListIcon
} from "@butterfly-frontend/ui"

import selectedItemsPropType from "modules/WmsModule/modals/StockTransfer/selectedItemsPropType"
import styles from "./StockItemsList.module.css"

const CELL_WIDTH_10 = {
    width: "10%",
    minWidth: 110
}

const StockItemsList = ({ selectedItems, onRemoveItem, isRemovingDisabled = false }) => (
    <div className={styles.tableContainer}>
        <H3 classes={{ h3: styles.h3 }}>Items</H3>
        {selectedItems.length > 0 ? (
            <Table>
                <TableHead>
                    <TableHeadCell minWidth={200} width="40%">
                        PRODUCT NAME
                    </TableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_10}>BRAND NAME</TableHeadCell>
                    <TableHeadCell width="5%" minWidth={60}>
                        ID
                    </TableHeadCell>
                    <TableHeadCell minWidth={114} width="15%">
                        CONDITION
                    </TableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_10}>WAREHOUSE</TableHeadCell>
                    <TableHeadCell {...CELL_WIDTH_10}>LOCATION</TableHeadCell>
                    <TableHeadCell width={32} minWidth={32} />
                </TableHead>
                <TableBody>
                    {selectedItems.map(selectedItem => (
                        <TableRow key={selectedItem.id} classNames={{ root: styles.tableRow }}>
                            <TableCell minWidth={200} width="40%">
                                {selectedItem.product.name}
                            </TableCell>
                            <TableCell {...CELL_WIDTH_10}>{selectedItem.brand.name}</TableCell>
                            <TableCell width="5%" minWidth={60}>
                                {selectedItem.id}
                            </TableCell>
                            <TableCell minWidth={114} width="15%">
                                <Status color={selectedItem.condition.color}>{selectedItem.condition.name}</Status>
                            </TableCell>
                            <TableCell {...CELL_WIDTH_10}>{selectedItem.warehouse.name}</TableCell>
                            <TableCell {...CELL_WIDTH_10}>
                                {selectedItem.location ? selectedItem.location.name : "-"}
                            </TableCell>
                            <TableCell width={32} minWidth={32}>
                                <Button
                                    variant="flat"
                                    disabled={isRemovingDisabled}
                                    Icon={TrashIcon}
                                    onClick={() => onRemoveItem(selectedItem)}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        ) : (
            <EmptyInternalList text="There aren't any stock items to transfer." Icon={ListIcon} />
        )}
    </div>
)

StockItemsList.propTypes = {
    onRemoveItem: PropTypes.func.isRequired,
    selectedItems: selectedItemsPropType,
    isRemovingDisabled: PropTypes.bool
}

export default StockItemsList
