import React from "react"
import withStyles from "HOC/withStyles"

import styles from "./EditCombinationModal.css"
// import checkboxStyles from "./overrides/Checkbox.css"
import close from "assets/close.svg"

const EditCombinationModal = props => {
    const { cx, formFields, images, names, handleChange, handleSubmit, onClose } = props
    return (
        <div className="modal-background">
            <div className={cx("modal-cover")}>
                <img src={close} alt="close" className={cx("close-icon")} onClick={onClose} />
                <h2 className={cx("modal-title")}>Edit combination</h2>
                <p className={cx("steps")}>{names.join(" / ")}</p>
                {/* <Checkbox
                    checked={formFields.default}
                    onChange={() => handleChange("default", !formFields.default)}
                    name="Set as default combination"
                    customStyles={checkboxStyles}
                /> */}
                <div className={cx("divider", "top-gallery")} />
                <h3 className={cx("gallery-title")}>Select main picture:</h3>
                {images.length > 0 ? (
                    <div className={cx("gallery")}>
                        {images.map(({ id, url }) => (
                            <div
                                key={id}
                                className={cx("image", { selected: id === formFields.media_id })}
                                style={{ backgroundImage: `url(${url})` }}
                                onClick={() => handleChange("media_id", id)}
                            />
                        ))}
                    </div>
                ) : (
                    <p className={cx("gallery-placeholder")}>No pictures for this product</p>
                )}
                <div className={cx("divider")} />
                <div className={cx("actions")}>
                    <button className={cx("cancel")} onClick={onClose}>
                        Cancel
                    </button>
                    <button className={cx("first-button", "save")} onClick={handleSubmit}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    )
}

export default withStyles(EditCombinationModal, styles)
