import React, { useCallback } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import { useFormik } from "formik"
import * as Yup from "yup"

import { Panel, Table, TableHead, TableHeadCell, TableBody } from "@butterfly-frontend/ui"
import { SaveBar, Textarea } from "ui"
import { useActions } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useStockItemReturn } from "modules/WmsModule/hooks/api/useStockItem"
import { checkIfItemIsAvailableToReturn } from "modules/WmsModule/pages/ReceivingDetailsPage/helpers"
import TABLE_COLUMNS from "modules/WmsModule/pages/ReceivingDetailsPage/constants/tableColumns"
import { SHIPMENT_PACK_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"
import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"

import { ReturnPackageTableRow } from "./components"

import styles from "./ReturnPackagePage.module.css"

const DESCRIPTION_MAX_LENGTH = 1000

const ReturnPackagePage = ({ data: shipmentData, canEdit }) => {
    const history = useHistory()
    const actions = useActions({ showErrorNotification, showSuccessNotification })
    const stockItemReturn = useStockItemReturn()

    const redirectToReceiving = useCallback(() => history.push("/wms/receivings"), [history.push])

    const formik = useFormik({
        initialValues: { ids: [], description: shipmentData.description || "" },
        validationSchema: Yup.object().shape({
            description: Yup.string().max(
                DESCRIPTION_MAX_LENGTH,
                `Description may not be greater than ${DESCRIPTION_MAX_LENGTH} characters`
            )
        }),
        onSubmit: ({ ids, description }) =>
            stockItemReturn
                .mutate({ shipmentId: shipmentData.id, ids, description })
                .then(() => {
                    actions.showSuccessNotification({ title: "The selected items has been returned" })
                    redirectToReceiving()
                })
                .catch(actions.showErrorNotification)
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <Panel classes={{ panel: styles.items }}>
                <h2 className={styles.itemsHeader}>Items</h2>
                <Table classes={{ root: styles.table }}>
                    <TableHead>
                        <TableHeadCell {...TABLE_COLUMNS.no}>#</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.product}>PRODUCT NAME</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.id}>ID</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.condition}>CONDITION</TableHeadCell>
                        <TableHeadCell {...TABLE_COLUMNS.packAction} />
                    </TableHead>
                    <TableBody>
                        {shipmentData.items.filter(checkIfItemIsAvailableToReturn).map((item, index) => (
                            <ReturnPackageTableRow key={item.id} index={index} item={item} formik={formik} />
                        ))}
                    </TableBody>
                </Table>
            </Panel>
            <div className={styles.description}>
                <Textarea
                    label="Description"
                    className={styles.textarea}
                    name="description"
                    placeholder="Click here to add a description..."
                    value={formik.values.description}
                    error={formik.touched.description && formik.errors.description}
                    onBlur={formik.handleBlur}
                    onChange={({ event }) => formik.handleChange(event)}
                />
            </div>
            <SaveBar
                isDisabled={formik.isSubmitting || formik.values.ids.length === 0 || !canEdit()}
                isDisabledCancel={formik.isSubmitting}
                isShown
                isSubmit
                isSaving={formik.isSubmitting}
                submitLabel="Return"
                onCancel={redirectToReceiving}
            />
        </form>
    )
}

ReturnPackagePage.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        items: PropTypes.arrayOf(SHIPMENT_PACK_ITEM_PROP_TYPE).isRequired,
        description: PropTypes.string
    }),
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(ReturnPackagePage, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
