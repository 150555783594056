import PropTypes from "prop-types"

const DISCOUNT_LIST_PROP_TYPE = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.shape({
            description: PropTypes.string
        }).isRequired
    }).isRequired
)

export default DISCOUNT_LIST_PROP_TYPE
