import * as yup from "yup"

import { INPUT_MAX_LENGTH } from "constants/index"

export const section = yup.object().shape({
    settings: yup
        .object()
        .shape({
            alignment: yup
                .string()
                .oneOf(["left", "right"], "Field is required")
                .required("Field is required"),
            heading: yup
                .string()
                .max(INPUT_MAX_LENGTH, `Product name may not be greater than ${INPUT_MAX_LENGTH} characters`)
                .nullable(),
            subheading: yup
                .string()
                .max(INPUT_MAX_LENGTH, `Subheading may not be greater than ${INPUT_MAX_LENGTH} characters`)
                .nullable()
        })
        .required(),
    product_id: yup.number().required("Field is required"),
    single_image: yup.object().required("Field is required"),
    single_image_product: yup.object().required("Field is required")
})
