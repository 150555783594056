import { conditionToValue } from "ui/Filters/SelectProductConditions"

export default condition => {
    const { product_condition_id: id, name, color, type } = condition

    return conditionToValue({
        id,
        name,
        color,
        type
    })
}
