import React from "react"

import Layout from "components/Reusable/Layout/index"
import { CombinationsCreator } from "ui"
import stylesLayout from "./overrides/Layout.css"
import stylesReturnHeader from "./overrides/ReturnHeader.css"

const View = ({ match }) => {
    return (
        <Layout
            returnLink={{
                link: "/settings/combination-template",
                label: "Combination Templates",
                customStyles: stylesReturnHeader
            }}
            customStyles={stylesLayout}
        >
            <CombinationsCreator isTemplate match={match} />
        </Layout>
    )
}

export default View
