import React, { Component, Fragment } from "react"
import withStyles from "HOC/withStyles"
import { findAddressByType } from "helpers/address"
import styles from "./generalinfo.module.css"

class GeneralInfo extends Component {
    render() {
        const { cx, addresses, otherSocial } = this.props

        return (
            <section className={cx("root")}>
                <h2>Address Details</h2>
                <ul className={cx("address")}>{this.renderAddresses(addresses)}</ul>
                {this.renderContact()}
                {this.renderSocial()}
                {otherSocial && this.renderOtherSocial(otherSocial)}
            </section>
        )
    }

    renderAddresses = addresses =>
        ["personal", "delivery", "invoice"]
            .map(type => findAddressByType({ type, addresses }))
            .filter(address => !!address)
            .map(this.renderAddress)

    renderAddress = ({ name, first_name, last_name, country, address, company, post_code, state, city, type }) => (
        <li key={type}>
            <h3>{name}:</h3>
            <strong>
                {company
                    ? `${company}, ${first_name || ""} ${last_name || ""}`
                    : `${first_name || ""} ${last_name || ""}`}
            </strong>
            <p>{address}</p>
            <p>
                {city ? `${city}, ` : ""}
                {state ? `${state} ` : ""}
                {post_code ? `${post_code} ` : ""}
            </p>
            <p>{country}</p>
        </li>
    )

    renderContact() {
        const { cx, email, phone, mobile, alternativeEmail, channels } = this.props
        return (
            <section className={cx("contact")}>
                <h2>Contact</h2>
                <p>
                    {channels.length > 0 && <span>Preferred communication channel: </span>}
                    {channels}
                </p>
                <ul>
                    <li className={cx("isActive")}>
                        <h3>E-mail address:</h3>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M3 3h18v18H3z" />
                                    <path
                                        className={cx("withFill")}
                                        fillRule="nonzero"
                                        d="M18 6H6c-.825 0-1.492.675-1.492 1.5l-.008 9c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-9c0-.825-.675-1.5-1.5-1.5zm0 3l-6 3.75L6 9V7.5l6 3.75 6-3.75V9z"
                                    />
                                </g>
                            </svg>
                            <a href={`mailto:${email}`}>{email}</a>
                        </div>
                    </li>
                    <li className={cx({ isActive: alternativeEmail })}>
                        <h3>Alternative e-mail:</h3>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M3 3h18v18H3z" />
                                    <path
                                        className={cx("withFill")}
                                        fillRule="nonzero"
                                        d="M18 6H6c-.825 0-1.492.675-1.492 1.5l-.008 9c0 .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5v-9c0-.825-.675-1.5-1.5-1.5zm0 3l-6 3.75L6 9V7.5l6 3.75 6-3.75V9z"
                                    />
                                </g>
                            </svg>
                            {alternativeEmail && (
                                <a href={`mailto:${alternativeEmail}`} rel="noopener noreferrer">
                                    {alternativeEmail}
                                </a>
                            )}
                            {!alternativeEmail && <span>-</span>}
                        </div>
                    </li>
                    <li className={cx({ isActive: phone })}>
                        <h3>Phone Number:</h3>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M3 3h18v19H3z" />
                                    <path
                                        className={cx("withFill")}
                                        fillRule="nonzero"
                                        d="M14.625 3.75h-6c-1.035 0-1.875.89-1.875 1.989V19.26c0 1.098.84 1.989 1.875 1.989h6c1.035 0 1.875-.89 1.875-1.989V5.74c0-1.098-.84-1.989-1.875-1.989zm-3 16.705c-.623 0-1.125-.533-1.125-1.194 0-.66.502-1.193 1.125-1.193s1.125.533 1.125 1.193-.502 1.194-1.125 1.194zM15 17.273H8.25V6.136H15v11.137z"
                                    />
                                </g>
                            </svg>
                            {phone && (
                                <a href={`tel:${phone}`} rel="noopener noreferrer">
                                    {phone}
                                </a>
                            )}
                            {!phone && <span>-</span>}
                        </div>
                    </li>
                    <li className={cx({ isActive: mobile })}>
                        <h3>Mobile Phone:</h3>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M3 3h18v19H3z" />
                                    <path
                                        className={cx("withFill")}
                                        fillRule="nonzero"
                                        d="M14.625 3.75h-6c-1.035 0-1.875.89-1.875 1.989V19.26c0 1.098.84 1.989 1.875 1.989h6c1.035 0 1.875-.89 1.875-1.989V5.74c0-1.098-.84-1.989-1.875-1.989zm-3 16.705c-.623 0-1.125-.533-1.125-1.194 0-.66.502-1.193 1.125-1.193s1.125.533 1.125 1.193-.502 1.194-1.125 1.194zM15 17.273H8.25V6.136H15v11.137z"
                                    />
                                </g>
                            </svg>
                            {mobile && (
                                <a href={`tel:${mobile}`} rel="noopener noreferrer">
                                    {mobile}
                                </a>
                            )}
                            {!mobile && <span>-</span>}
                        </div>
                    </li>
                </ul>
            </section>
        )
    }

    renderSocial() {
        const { cx, instagram, website, twitter, facebook } = this.props
        return (
            <Fragment>
                <h2>Website &amp; Social Profiles</h2>
                <ul className={cx("social")}>
                    <li className={cx({ isActive: website })}>
                        <h3>Website</h3>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M3 3h18v18H3z" />
                                    <path
                                        className={cx("withFill")}
                                        fillRule="nonzero"
                                        d="M11.992 4.5C7.853 4.5 4.5 7.86 4.5 12c0 4.14 3.353 7.5 7.492 7.5 4.148 0 7.508-3.36 7.508-7.5 0-4.14-3.36-7.5-7.508-7.5zM17.19 9h-2.213a11.737 11.737 0 0 0-1.035-2.67A6.022 6.022 0 0 1 17.19 9zM12 6.03c.622.9 1.11 1.897 1.432 2.97h-2.865c.323-1.072.81-2.07 1.433-2.97zM6.195 13.5A6.181 6.181 0 0 1 6 12c0-.518.075-1.02.195-1.5H8.73c-.06.495-.105.99-.105 1.5s.045 1.005.105 1.5H6.195zM6.81 15h2.212c.24.938.585 1.838 1.035 2.67A5.99 5.99 0 0 1 6.81 15zm2.212-6H6.81a5.99 5.99 0 0 1 3.247-2.67c-.45.832-.795 1.732-1.035 2.67zM12 17.97c-.623-.9-1.11-1.898-1.433-2.97h2.865A10.565 10.565 0 0 1 12 17.97zm1.755-4.47h-3.51c-.067-.495-.12-.99-.12-1.5s.053-1.013.12-1.5h3.51c.068.487.12.99.12 1.5s-.052 1.005-.12 1.5zm.188 4.17c.45-.833.795-1.733 1.034-2.67h2.213a6.022 6.022 0 0 1-3.248 2.67zm1.327-4.17c.06-.495.105-.99.105-1.5s-.045-1.005-.105-1.5h2.535c.12.48.195.982.195 1.5 0 .517-.075 1.02-.195 1.5H15.27z"
                                    />
                                </g>
                            </svg>
                        </div>
                        {website && (
                            <a href={website} target="_blank" rel="noopener noreferrer">
                                {website}
                            </a>
                        )}
                        {!website && <span>-</span>}
                    </li>
                    <li className={cx({ isActive: facebook })}>
                        <h3>Facebook</h3>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                                <g fill="none" fillRule="evenodd">
                                    <path
                                        fillRule="nonzero"
                                        d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z"
                                    />
                                    <path
                                        className={cx("withFill")}
                                        fillRule="nonzero"
                                        d="M26.502 38.111V25.054h3.604l.478-4.5h-4.082l.006-2.251c0-1.174.111-1.803 1.797-1.803h2.253V12h-3.605c-4.33 0-5.854 2.183-5.854 5.854v2.701H18.4v4.5h2.7V38.11h5.402z"
                                    />
                                </g>
                            </svg>
                        </div>
                        {facebook && (
                            <a href={`https://facebook.com/${facebook}`} target="_blank" rel="noopener noreferrer">
                                {facebook}
                            </a>
                        )}
                        {!facebook && <span>-</span>}
                    </li>
                    <li className={cx({ isActive: instagram })}>
                        <h3>Instagram</h3>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                                <g fill="none" fillRule="nonzero">
                                    <path d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z" />
                                    <path
                                        className={cx("withFill")}
                                        d="M24.001 11.2h-.004c3.478 0 3.912.015 5.278.077 1.362.063 2.293.278 3.109.595a6.256 6.256 0 0 1 2.267 1.476 6.282 6.282 0 0 1 1.477 2.268c.315.813.53 1.744.595 3.106.061 1.366.077 1.802.077 5.278 0 3.476-.016 3.912-.077 5.277-.064 1.362-.28 2.293-.595 3.107a6.284 6.284 0 0 1-1.477 2.267 6.263 6.263 0 0 1-2.266 1.477c-.815.317-1.746.533-3.109.595-1.365.062-1.8.077-5.276.077s-3.912-.015-5.278-.077c-1.362-.062-2.293-.278-3.107-.595a6.268 6.268 0 0 1-2.267-1.477 6.272 6.272 0 0 1-1.476-2.268c-.316-.813-.532-1.744-.595-3.106-.062-1.366-.077-1.8-.077-5.277 0-3.476.016-3.913.077-5.278.061-1.362.277-2.293.595-3.107a6.284 6.284 0 0 1 1.477-2.267 6.268 6.268 0 0 1 2.268-1.476c.813-.317 1.744-.532 3.106-.595 1.366-.062 1.802-.077 5.278-.077zm-1.148 2.307v-.004c-2.386.003-2.843.02-4.024.072-1.248.058-1.926.266-2.377.442a3.972 3.972 0 0 0-1.472.958 3.966 3.966 0 0 0-.958 1.472c-.175.45-.384 1.128-.44 2.376-.062 1.35-.074 1.753-.074 5.171s.012 3.824.073 5.173c.057 1.248.266 1.925.441 2.376.233.597.51 1.023.958 1.471.448.448.875.725 1.472.957.452.176 1.129.384 2.377.442 1.35.061 1.754.074 5.172.074 3.418 0 3.823-.013 5.173-.074 1.248-.057 1.925-.265 2.376-.441a3.958 3.958 0 0 0 1.471-.958c.448-.447.726-.873.958-1.47.175-.45.384-1.128.44-2.376.062-1.35.075-1.755.075-5.17 0-3.417-.013-3.822-.074-5.172-.057-1.248-.266-1.925-.441-2.376a3.972 3.972 0 0 0-.958-1.472 3.958 3.958 0 0 0-1.47-.957c-.452-.175-1.13-.384-2.377-.44-1.35-.062-1.755-.074-5.173-.074h-1.148zm7.98 2.125a1.536 1.536 0 1 1 0 3.071 1.536 1.536 0 0 1 0-3.071zm-6.832 1.795A6.573 6.573 0 1 1 24 30.573 6.573 6.573 0 0 1 24 17.427zm0 2.306a4.267 4.267 0 1 0 0 8.534 4.267 4.267 0 0 0 0-8.534z"
                                    />
                                </g>
                            </svg>
                        </div>
                        {instagram && (
                            <a href={`https://instagram.com/${instagram}`} target="_blank" rel="noopener noreferrer">
                                @{instagram}
                            </a>
                        )}
                        {!instagram && <span>-</span>}
                    </li>
                    <li className={cx({ isActive: twitter })}>
                        <h3>Twitter</h3>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                                <g fill="none" fillRule="nonzero">
                                    <path d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24z" />
                                    <path
                                        className={cx("withFill")}
                                        d="M24.281 20.508l.05.83-.839-.102c-3.055-.39-5.724-1.711-7.99-3.932l-1.109-1.101-.285.813c-.604 1.814-.218 3.729 1.04 5.017.672.712.521.813-.637.39-.403-.136-.756-.238-.79-.187-.117.119.286 1.661.605 2.271.437.848 1.326 1.678 2.3 2.17l.823.39-.974.016c-.94 0-.974.017-.873.373.336 1.102 1.662 2.271 3.14 2.78l1.04.356-.906.542a9.452 9.452 0 0 1-4.5 1.254c-.755.017-1.376.085-1.376.136 0 .17 2.048 1.118 3.24 1.491 3.576 1.102 7.823.627 11.013-1.254 2.266-1.339 4.532-4 5.59-6.576.57-1.373 1.141-3.881 1.141-5.084 0-.78.05-.882.99-1.814.555-.542 1.075-1.135 1.176-1.305.168-.322.151-.322-.705-.034-1.427.509-1.628.441-.923-.322.52-.542 1.141-1.525 1.141-1.813 0-.051-.252.034-.537.186-.302.17-.974.424-1.477.577l-.907.288-.822-.56c-.454-.305-1.092-.644-1.427-.745-.857-.238-2.166-.204-2.938.067-2.099.763-3.425 2.73-3.274 4.882z"
                                    />
                                </g>
                            </svg>
                        </div>
                        {twitter && (
                            <a href={`https://twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer">
                                @{twitter}
                            </a>
                        )}
                        {!twitter && <span>-</span>}
                    </li>
                </ul>
            </Fragment>
        )
    }

    renderOtherSocial(otherSocial) {
        const { cx } = this.props

        return (
            <section className={cx("otherSocial")}>
                <h3>Other social</h3>
                <a href={otherSocial} target="_blank" rel="noopener noreferrer">
                    {otherSocial}
                </a>
            </section>
        )
    }
}

export default withStyles(GeneralInfo, styles)
