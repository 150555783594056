import React, { useEffect } from "react"
import PropTypes from "prop-types"
import idx from "idx"

import withStyles from "HOC/withStyles"

import { Dropzone } from "ui"

import styles from "./styles.css"
import dropzoneStyles from "../../overrides/dropzone.css"
import dropzoneButtonStyles from "../../overrides/dropzoneButton.css"

import cloudSvg from "./assets/cloud.svg"

const Sidebar = ({ cx, form, uploader: { actions: uploadActions, state: uploadState } }) => {
    useEffect(() => {
        if (idx(uploadState, _ => _.addedFile.url)) {
            form.setFieldValue("avatar", uploadState.addedFile.url)
            form.setFieldValue("avatarID", uploadState.addedFile.id)
        }
    }, [uploadState.isUploading, uploadState.isSuccess, uploadState.addedFile])

    const onRemoveImage = () => {
        form.setFieldValue("avatar", null)
        form.setFieldValue("avatarID", null)
    }

    const renderUpladingState = progress => (
        <div className={cx("dropzoneStateUploading")}>
            <div style={{ width: `${progress}%` }} />
        </div>
    )

    const renderEmptyState = () => (
        <div className={cx("dropzoneStateEmpty")}>
            <Dropzone
                isUploading={uploadState.isUploading}
                handleUpload={uploadActions.handleUpload}
                customStyles={dropzoneStyles}
            >
                <img className={cx("cloudImage")} src={cloudSvg} alt="cloud" />
                <p className={cx("dropzoneTitle")}>Add photo</p>
            </Dropzone>

            <p className={cx("dropzoneText")}>(browse or drop your file here)</p>
        </div>
    )

    const renderImageState = () => (
        <div className={cx("dropzoneStateImage")}>
            <div
                className={cx("image")}
                style={{
                    backgroundImage: `url('${form.values.avatar}')`
                }}
            />
            <Dropzone
                isUploading={uploadState.isUploading}
                handleUpload={uploadActions.handleUpload}
                customStyles={dropzoneButtonStyles}
            >
                <div className={cx("addNewPhotoButton")}>Add new photo</div>
            </Dropzone>

            <div className={cx("deleteButton")} onClick={onRemoveImage}>
                Delete
            </div>
        </div>
    )

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <h2 className={cx("header")}>Profile photo</h2>

                <div
                    className={cx("dropzoneWrapper", {
                        isError: !!form.errors.avatar
                    })}
                >
                    {uploadState.isUploading
                        ? renderUpladingState(uploadState.progress)
                        : !!form.values.avatar
                        ? renderImageState()
                        : renderEmptyState()}
                </div>
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    cx: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    uploader: PropTypes.object.isRequired
}

export default withStyles(Sidebar, styles)
