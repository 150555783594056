import React, { Component, Fragment } from "react"
import withStyles from "HOC/withStyles"
import styles from "./EditableList.css"
import { Link } from "react-router-dom"
import Modal from "components/Modal/Modal"
import { MAX_ITEMS_PER_PAGE_PAGINATION } from "constants/index"
import Confirm from "components/Modal/Confirm/Confirm"
import Pagination from "components/Reusable/Pagination"

class EditableList extends Component {
    perPage = MAX_ITEMS_PER_PAGE_PAGINATION

    state = {
        showModal: false,
        currentDeleteId: null,
        currentPage: 0
    }

    handleToggleModal = listElement => {
        if (typeof this.props.handleToggleModal === "function") {
            return this.props.handleToggleModal(listElement)
        }

        const { showModal } = this.state
        this.setState({
            showModal: !showModal,
            currentDeleteId: listElement ? listElement.id : null
        })
    }

    handleDelete = () => {
        const { currentDeleteId } = this.state
        const { deleteElement } = this.props
        deleteElement(currentDeleteId)
        this.setState({
            showModal: false
        })
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { list, isDisabledLocalPagination } = nextProps

        if (!isDisabledLocalPagination && JSON.stringify(list) !== JSON.stringify(this.props.list)) {
            this.setState({
                currentPage: 0
            })
        }
    }

    handleSelectPage = page => {
        const { handleSelectPage } = this.props
        handleSelectPage && handleSelectPage(page)

        this.setState({
            currentPage: page
        })
    }

    render() {
        const {
            cx,
            listTitle,
            addUrl,
            confirmModalTitle,
            list,
            perPage,
            currentPage,
            pagesCount,
            isDisabledLocalPagination,
            addHandler
        } = this.props

        const { currentPage: currentPageState } = this.state

        return (
            <div className={cx("list-cover")}>
                <div>
                    <h1 className={cx("list-title")}>{listTitle}</h1>
                    {addUrl && (
                        <Link to={addUrl}>
                            <button className={cx("first-button")}>Add New</button>
                        </Link>
                    )}
                    {addHandler && (
                        <button onClick={addHandler} className={cx("first-button")}>
                            Add New
                        </button>
                    )}

                    {this.renderList()}

                    {this.state.showModal && (
                        <Modal>
                            <Confirm
                                confirmModalTitle={confirmModalTitle}
                                handleHideModal={() => this.handleToggleModal()}
                                handleDelete={this.handleDelete}
                            />
                        </Modal>
                    )}
                    {isDisabledLocalPagination && (
                        <Pagination
                            customStyles={styles}
                            handleSelectPage={this.handleSelectPage}
                            pagesCount={pagesCount}
                            currentPage={currentPage}
                            perPage={perPage}
                        />
                    )}
                    {!isDisabledLocalPagination && (
                        <Pagination
                            customStyles={styles}
                            handleSelectPage={this.handleSelectPage}
                            items={list}
                            currentPage={currentPageState}
                            perPage={this.perPage}
                        />
                    )}
                </div>
            </div>
        )
    }

    renderList() {
        const {
            cx,
            isLoading,
            editUrlPrefix,
            onEditClick,
            handleContentLink,
            handleDeleteEnableCondition,
            config: { headerColumns, dataColumns },
            isNoEdit,
            isNoDelete,
            isDisabledLocalPagination,
            currentPage: currentPageProps,
            perPage
        } = this.props
        const { currentPage } = this.state

        let list = this.props.list

        if (!isDisabledLocalPagination) {
            list = this.props.list
                ? this.props.list.slice(currentPage * this.perPage, (currentPage + 1) * this.perPage)
                : []
        }

        if (!isLoading && Array.isArray(list) && !list.length) {
            return <div className={cx("emptyList")}>Empty list.</div>
        }

        return (
            <div>
                {headerColumns.map((column, index) => {
                    return (
                        <h4
                            className={cx("header-columns")}
                            style={{ width: column.width, textAlign: column.textAlign }}
                            key={index}
                        >
                            {column.name}
                        </h4>
                    )
                })}
                {isLoading && (
                    <ul className={cx("placeholder-list-ul")}>
                        {Array(10)
                            .fill()
                            .map((e, i) => i + 1)
                            .map((key, i) => {
                                return (
                                    <li key={key} data-key={key} className={cx("placeholder-list-li")}>
                                        {headerColumns.map((column, index) => {
                                            if (column.name === "NO")
                                                return (
                                                    <div
                                                        className={cx("header-columns", "no")}
                                                        style={{ width: column.width }}
                                                        key={index}
                                                    />
                                                )
                                            else if (column.textAlign === "center")
                                                return (
                                                    <div
                                                        className={cx("header-columns", "center")}
                                                        style={{ width: column.width }}
                                                        key={index}
                                                    />
                                                )
                                            else
                                                return (
                                                    <div
                                                        className={cx("header-columns", "default")}
                                                        style={{ width: column.width }}
                                                        key={index}
                                                    />
                                                )
                                        })}
                                        {!isNoEdit && <div className={cx("header-columns", "edit-placeholder")} />}
                                        <div className={cx("header-columns", "delete-placeholder")} />
                                    </li>
                                )
                            })}
                    </ul>
                )}
                <ul className={cx("list-ul")}>
                    {!isLoading &&
                        list &&
                        list.map((_, index) => {
                            const isDeleteEnabled = handleDeleteEnableCondition
                                ? handleDeleteEnableCondition(list[index])
                                : true
                            return (
                                <li className={cx("list-li")} key={index}>
                                    <h4
                                        className={cx("data-columns")}
                                        style={{
                                            width: headerColumns[0].width,
                                            textAlign: headerColumns[0].textAlign
                                        }}
                                    >
                                        {(isDisabledLocalPagination
                                            ? index + 1 + currentPageProps * perPage
                                            : index + 1 + currentPage * this.perPage) + "."}
                                    </h4>
                                    {dataColumns.map(column => {
                                        const listElementsKeys = Object.keys(list[index])
                                        const widthAndCenter = { width: column.width, textAlign: column.textAlign }
                                        return listElementsKeys.map(key => {
                                            const keyValue = list[index][key]
                                            const keyIsArray = Array.isArray(keyValue)
                                            const keyNameIsColumnName = key === column.name
                                            const typeIsImg = column.type === "img"

                                            if (keyNameIsColumnName) {
                                                if (typeIsImg)
                                                    return (
                                                        <div
                                                            key={index}
                                                            className={cx("data-image")}
                                                            style={{ width: column.width }}
                                                        >
                                                            <img src={keyValue} alt="img" className={cx("image")} />
                                                        </div>
                                                    )
                                                else if (keyIsArray)
                                                    return (
                                                        <h4
                                                            className={cx("data-columns")}
                                                            style={widthAndCenter}
                                                            key={index}
                                                        >
                                                            {keyValue.length}
                                                        </h4>
                                                    )
                                                else if (!keyIsArray && (column.isLink || column.secondLink))
                                                    return (
                                                        <Link
                                                            key={index}
                                                            to={
                                                                handleContentLink &&
                                                                handleContentLink(list[index].id) +
                                                                    (column.secondLink ? column.secondLink : "")
                                                            }
                                                        >
                                                            <h4
                                                                className={cx("data-columns", "link")}
                                                                style={widthAndCenter}
                                                                key={index}
                                                            >
                                                                {keyValue}
                                                            </h4>
                                                        </Link>
                                                    )
                                                else if (!keyIsArray && column.isColor)
                                                    return (
                                                        <div
                                                            className={cx("data-columns")}
                                                            style={widthAndCenter}
                                                            key={index}
                                                        >
                                                            {list[index].texture ? (
                                                                <div
                                                                    className={cx("color", "texture")}
                                                                    style={{
                                                                        backgroundImage: `url(${list[index].texture})`
                                                                    }}
                                                                />
                                                            ) : (
                                                                <span
                                                                    className={cx("color", {
                                                                        isWhite: keyValue === "#ffffff"
                                                                    })}
                                                                    style={{ backgroundColor: keyValue }}
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                else if (!keyIsArray)
                                                    return (
                                                        <h4
                                                            className={cx("data-columns")}
                                                            style={widthAndCenter}
                                                            key={index}
                                                        >
                                                            {keyValue}
                                                        </h4>
                                                    )
                                                else return null
                                            } else return null
                                        })
                                    })}
                                    {!isNoEdit && (list[index] && !list[index].isNoEdit) && (
                                        <Fragment>
                                            {onEditClick ? (
                                                <div
                                                    onClick={() => onEditClick(list[index].id)}
                                                    className={cx("edit-cover")}
                                                >
                                                    Edit
                                                </div>
                                            ) : (
                                                <Link to={`${editUrlPrefix}/${list[index].id}/edit`}>
                                                    <div className={cx("edit-cover")}>Edit</div>
                                                </Link>
                                            )}
                                        </Fragment>
                                    )}
                                    {!isNoDelete && (list[index] && !list[index].isNoDelete) && (
                                        <div
                                            className={cx("delete-cover", { isDeleteEnabled })}
                                            onClick={() => isDeleteEnabled && this.handleToggleModal(list[index])}
                                        >
                                            Delete
                                        </div>
                                    )}
                                </li>
                            )
                        })}
                </ul>
            </div>
        )
    }
}

export default withStyles(EditableList, styles)
