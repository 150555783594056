import React, { useMemo } from "react"
import ReactSVG from "react-svg"

import SnakeLoader from "ui/FilterableTable/components/SnakeLoader"
import EventItem from "./Item"

import useStyles from "helpers/getCxFromStyles"
import styles from "./EventList.css"

import ascIcon from "assets/asc.svg"
import descIcon from "assets/desc.svg"

const EventList = props => {
    const { list, sort, isLoadingMore, handleSort, PaginationComponent, toggleShowDetails, sortingOptions } = props
    const cx = useStyles(styles)

    const onSort = key => {
        if (sort.by === key) {
            return handleSort({ direction: sort.direction === "asc" ? "desc" : "asc", by: key })
        }

        handleSort({ direction: "asc", by: key })
    }

    const sortItem = ({ label, key, isSortable }) => (
        <li className={cx("sortItemWrapper", { fullWidth: key === "description" })} key={key}>
            <div
                className={cx("sortItem", {
                    isActive: key === sort.by,
                    isAsc: sort.direction === "asc",
                    isSortable
                })}
                onClick={() => isSortable && onSort(key)}
            >
                <span className={cx("name")}>{label}</span>
                {isSortable && (
                    <span className={cx("icons")}>
                        <ReactSVG src={ascIcon} className={cx("icon-asc")} />
                        <ReactSVG src={descIcon} className={cx("icon-desc")} />
                    </span>
                )}
            </div>
        </li>
    )

    const isDescription = useMemo(() => sortingOptions.some(item => item.key === "description"), [sortingOptions])

    return (
        <div className={cx("root")}>
            <ul className={cx("sort")}>{sortingOptions.map(option => sortItem(option))}</ul>
            <ul className={cx("list")}>
                {list.map((item, index) => (
                    <EventItem
                        key={index}
                        event={item}
                        isDescription={isDescription}
                        toggleShowDetails={toggleShowDetails}
                    />
                ))}
            </ul>
            {isLoadingMore ? (
                <div className={cx("loaderWrapper")}>
                    <SnakeLoader />
                </div>
            ) : (
                <div className={cx("footer")}>
                    <PaginationComponent />
                </div>
            )}
        </div>
    )
}

export default EventList
