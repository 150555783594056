import React, { Fragment } from "react"

import ReactSvg from "react-svg"
import Button from "ui/Button"
import documentIcon from "assets/document.svg"

import { withMultiselectConsumer } from "HOC/withMultiselect"
import ListContainer from "../List/ListContainer"
import { getOrder, updateOrder } from "actions/orders"

import { Payments, Attachments, Shipments, Returns, OverviewInfo } from "./components"

const InvoicesWithMultiselect = withMultiselectConsumer("invoices")(props => (
    <ListContainer type="invoices" {...props} />
))
const PurchaseOrdersWithMultiselect = withMultiselectConsumer("purchaseOrders")(props => (
    <ListContainer type="purchase-orders" {...props} />
))

const getEmployee = ({ users }) => {
    const employee = (Array.isArray(users) && users.find(user => user.sales_associate)) || {}
    return employee.full_name || "-"
}

const overviewConfig = {
    sales: {
        name: "Sales Order",
        singleName: "Order",
        ordersRoute: "sales-orders",
        orderItemHasExternalActions: true,
        isShowroomSaleAvailable: true,
        getResource: getOrder,
        updateResource: updateOrder,
        showBottomBar: ({ orderStatus }) =>
            ["in_progress", "awaiting_payment", "sent", "completed"].includes(orderStatus),
        info: {
            "Sales order date:": ({ issuedAt }) => issuedAt,
            "Revised date:": ({ revisedAt }) => revisedAt,
            "Emailed date:": ({ emailedAt }) => emailedAt,
            "Print date:": ({ printedAt }) => printedAt,
            "Valid date:": ({ validDate }) => validDate,
            "Delivery date:": ({ deliveryDate }) => deliveryDate,
            "Employee:": getEmployee
        },
        tabs: {
            "order-info": {
                label: "Order Info",
                render: props => <OverviewInfo {...props} />
            },
            invoices: {
                label: "Invoices",
                render: ({ order, ...props }) => <InvoicesWithMultiselect {...props} order={order} isTabView={true} />
            },
            "purchase-orders": {
                label: "Purchase Orders",
                render: ({ order, ...props }) => (
                    <PurchaseOrdersWithMultiselect {...props} orderId={order.id} isTabView={true} />
                )
            },
            payments: {
                label: "Payments",
                render: props => <Payments {...props} />
            },
            attachments: {
                label: "Attachments",
                render: props => <Attachments {...props} />
            },
            shipments: {
                label: "Shipments",
                render: props => <Shipments {...props} />
            },
            returns: {
                label: "Returns",
                render: props => <Returns {...props} />
            }
        },
        summary: (calculations, cardChargeFeePercentage = 0) => {
            const {
                subtotal,
                discount,
                tax,
                total,
                applied,
                balance,
                card_charge_fee,
                store_credits_applied,
                refund
            } = calculations

            return [
                [
                    { label: "Subtotal", value: subtotal },
                    { label: "Discount", value: -discount },
                    { label: "Sales TAX", value: tax, hasRefreshTaxButton: true },
                    { label: "Card Charge Percent", value: cardChargeFeePercentage, inputKey: "card_charge_fee" },
                    { label: "Card Charge Fee", value: card_charge_fee || 0 }
                ],
                [
                    { label: "Total Amount", value: total, isBig: true },
                    { label: "Payment Applied", value: applied },
                    { label: "Store Credits Applied", value: store_credits_applied, isGold: true },
                    { label: "Refund", value: refund, isColor: true, isPositive: true }
                ],
                [{ label: "Balance Due", value: -balance, isBig: true, isColor: true, isGreen: balance > 0 }]
            ]
        },
        selectedActions: [
            ({ cx, checkedCount, handleAction, canCreate, isCreating }) => (
                <Button
                    isDisabled={!canCreate("purchase_order")}
                    isLoading={isCreating.PO}
                    className={`first-button white ${cx("createPoButton")}`}
                    label="Create PO"
                    onClick={() => handleAction("createPO", !!checkedCount ? "multiselect" : "all")}
                />
            ),
            ({ cx, checkedCount, handleAction, canCreate }) => (
                <Button
                    isDisabled={!canCreate("invoice")}
                    className={`first-button ${cx("createInvoiceButton")}`}
                    label={
                        <Fragment>
                            <ReactSvg className={cx("createInvoiceIcon")} src={documentIcon} />
                            Create Invoice
                        </Fragment>
                    }
                    onClick={() => handleAction("createInvoice", !!checkedCount ? "multiselect" : "all")}
                />
            )
        ]
    },
    memo: {
        name: "Memo",
        singleName: "Memo",
        ordersRoute: "memo",
        getResource: getOrder,
        updateResource: updateOrder,
        disableServices: true,
        info: {
            "Memo date:": ({ issuedAt }) => issuedAt,
            "Emailed date:": ({ emailedAt }) => emailedAt,
            "Print date:": ({ printedAt }) => printedAt,
            "Employee:": getEmployee
        },
        tabs: {
            "memo-info": {
                label: "Memo Info",
                render: props => <OverviewInfo {...props} />
            },
            attachments: {
                label: "Attachments",
                render: props => <Attachments {...props} />
            }
        },
        summary: calculations => {
            const { subtotal, tax, total } = calculations
            return [
                [{ label: "Subtotal", value: subtotal }, { label: "Sales TAX", value: tax }],
                [{ label: "Total Amount", value: total, isBig: true }]
            ]
        },
        selectedActions: [
            ({ cx, handleAction, canCreate, isCreating }) => (
                <Button
                    styles={{ width: 137 }}
                    isDisabled={!canCreate("rental")}
                    isLoading={isCreating.rental}
                    className={`first-button white ${cx("createPoButton")}`}
                    label="Create Rental"
                    onClick={() => handleAction("createRental", "multiselect")}
                />
            ),
            ({ cx, handleAction, canCreate, isCreating }) => (
                <Button
                    styles={{ width: 175 }}
                    isDisabled={!canCreate("sales")}
                    isLoading={isCreating.sales}
                    className={`first-button ${cx("createInvoiceButton")}`}
                    label="Create Sales Order"
                    onClick={() => handleAction("createSO", "multiselect")}
                />
            )
        ]
    },
    rental: {
        name: "Rental Agreement",
        singleName: "Rental",
        ordersRoute: "rental-agreements",
        getResource: getOrder,
        updateResource: updateOrder,
        disableServices: true,
        showBottomBar: ({ productsCount }) => !!productsCount,
        info: {
            "Rental start date:": ({ issuedAt }) => issuedAt,
            "Emailed date:": ({ emailedAt }) => emailedAt,
            "Print date:": ({ printedAt }) => printedAt,
            "Employee:": getEmployee
        },
        tabs: {
            "rental-info": {
                label: "Rental Info",
                render: props => <OverviewInfo {...props} />
            },
            invoices: {
                label: "Invoice",
                render: ({ order, ...props }) => <InvoicesWithMultiselect {...props} order={order} isTabView={true} />
            },
            payments: {
                label: "Payments",
                render: props => <Payments {...props} />
            },
            attachments: {
                label: "Attachments",
                render: props => <Attachments {...props} />
            }
        },
        summary: calculations => {
            const { subtotal, discount, tax, total } = calculations
            return [
                [
                    { label: "Subtotal", value: subtotal },
                    discount > 0 && { label: "Discount", value: -discount },
                    { label: "Sales TAX", value: tax }
                ].filter(row => !!row),
                [{ label: "Total Amount", value: total, isBig: true }]
            ]
        },
        selectedActions: [
            ({ cx, canCreate, checkedCount, handleAction }) => (
                <Button
                    styles={{ width: 160, marginRight: 10 }}
                    className={`first-button white ${cx("createInvoice")}`}
                    icon={documentIcon}
                    label="Create Invoice"
                    isDisabled={!canCreate("invoice")}
                    onClick={() => handleAction("createInvoice", !!checkedCount ? "multiselect" : "all")}
                />
            ),
            ({ cx, handleAction, isCreating, checkedCount }) => (
                <Button
                    styles={{ width: 175 }}
                    isLoading={isCreating.sales}
                    className={`first-button ${cx("createInvoiceButton")}`}
                    label="Create Sales Order"
                    onClick={() => handleAction("createSO", !!checkedCount ? "multiselect" : "all")}
                />
            )
        ]
    }
}

export default overviewConfig
