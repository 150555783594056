import * as constants from "../constants"

export function showErrorNotification(data) {
    return {
        type: constants.SHOW_ERROR_NOTIFICATION,
        payload: {
            data
        }
    }
}

export function showSuccessNotification(data) {
    return {
        type: constants.SHOW_SUCCESS_NOTIFICATION,
        payload: {
            data
        }
    }
}
