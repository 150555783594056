import React from "react"
import PropTypes from "prop-types"
import _groupBy from "lodash/groupBy"
import _compose from "lodash/flow"
import { WarningBar } from "ui"

import styles from "./StockItemsListWarnings.module.css"

const groupStockItemsByProductName = stockItems => _groupBy(stockItems, "product.name")

const groupedStockItemsToWarningEntries = stockItemsGroupedByName =>
    Object.entries(stockItemsGroupedByName).map(
        ([productName, stockItems]) =>
            `${productName} x${stockItems.length} Id: ${stockItems.map(({ id }) => id).join("; ")}`
    )

const joinWarningEntries = warningEntries => warningEntries.join(", ")

const getWarningEntries = _compose(groupStockItemsByProductName, groupedStockItemsToWarningEntries, joinWarningEntries)

const StockItemsListWarnings = ({
    alreadyInTargetWarehouseStockItems,
    onHoldStockItems,
    alreadyInShipmentStockItems
}) => (
    <div className={styles.container}>
        {alreadyInTargetWarehouseStockItems.length > 0 && (
            <WarningBar
                text={`Following stock items are already in the target warehouse: ${getWarningEntries(
                    alreadyInTargetWarehouseStockItems
                )}`}
            />
        )}
        {onHoldStockItems.length > 0 && (
            <WarningBar
                text={`Following stock items are on hold and cannot be transferred: ${getWarningEntries(
                    onHoldStockItems
                )}`}
            />
        )}
        {alreadyInShipmentStockItems.length > 0 && (
            <WarningBar
                text={`Following stock items are already in another shipment and cannot be transferred: ${getWarningEntries(
                    alreadyInShipmentStockItems
                )}`}
            />
        )}
    </div>
)

const stockItemListPropType = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.number.isRequired,
        product: PropTypes.shape({
            name: PropTypes.string.isRequired
        }).isRequired
    }).isRequired
).isRequired

StockItemsListWarnings.propTypes = {
    alreadyInTargetWarehouseStockItems: stockItemListPropType,
    onHoldStockItems: stockItemListPropType,
    alreadyInShipmentStockItems: stockItemListPropType
}

export default StockItemsListWarnings
