import React from "react"

import withStyles from "HOC/withStyles"
import styles from "./categoriesListHeader.css"

const ListHeader = props => {
    const { cx } = props

    return (
        <div className={cx("root")}>
            <div>NAME</div>
            <div>SUBCATEGORIES</div>
            <div>Filters</div>
            <div>Visible on Web</div>
            <div className={cx("actions")} />
        </div>
    )
}

export default withStyles(ListHeader, styles)
