import React from "react"

import ColorTile from "../ColorTile/ColorTile"

function List(props) {
    const { cx, tiles, handleHover, handleMouseOut, handleSelectTile, selectedTile } = props

    return (
        <div className={cx("list", { selected: selectedTile && selectedTile !== -1 })}>
            <div className={cx("list-inner")}>
                {tiles &&
                    tiles.map(tile => (
                        <ColorTile
                            handleHover={handleHover}
                            handleMouseOut={handleMouseOut}
                            handleSelectTile={handleSelectTile}
                            cx={cx}
                            key={tile.id}
                            id={tile.id}
                            name={tile.name}
                            disabled={tile.disabled}
                            color={tile.color}
                            texture={tile.texture}
                            selected={selectedTile === tile.id}
                        />
                    ))}
            </div>
        </div>
    )
}

export default List
