import React from "react"
import { Redirect, Route } from "react-router-dom"
import PropTypes from "prop-types"
import _get from "lodash/get"

import withPermissions from "HOC/withPermissions"
import { PERMISSIONS } from "constants/index"
import StockAdjustmentAddPage from "modules/WmsModule/pages/StockAdjustmentAddPage"
import StockItemsChangeStatusPage from "modules/WmsModule/pages/StockItemsChangeStatusPage"

const StockAdjustmentRoute = ({ checkPermission, ...routeProps }) => (
    <Route
        {...routeProps}
        render={props => {
            const stockItemsToAdjust = _get(props, "location.state.stockItemsToAdjust", [])

            if (!checkPermission(PERMISSIONS.types.STOCK_ADJUSTMENT_ON, PERMISSIONS.context.STOCK_ITEMS)) {
                return <Redirect to="/wms/warehouses" />
            }

            return stockItemsToAdjust.length === 0 ? (
                <Redirect to="/wms/stock-management/add" />
            ) : stockItemsToAdjust.length === 1 ? (
                <StockAdjustmentAddPage />
            ) : (
                <StockItemsChangeStatusPage />
            )
        }}
    />
)

StockAdjustmentRoute.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            stockItemsToAdjust: PropTypes.arrayOf(PropTypes.object),
            returnPath: PropTypes.string
        })
    }),
    checkPermission: PropTypes.func.isRequired
}

export default withPermissions(StockAdjustmentRoute)
