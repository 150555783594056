import React, { useEffect } from "react"
import { useQuery } from "react-query"
import PropTypes from "prop-types"
import _get from "lodash/get"
import classNames from "classnames"
import { H2 } from "@butterfly-frontend/ui"

import fetch from "helpers/fetch"
import { useIsTablet } from "helpers/rwd"
import useSelectCombination from "hooks/useSelectCombination"
import { ErrorLabel } from "ui/SelectWithSearch/components"
import { SnakeLoader } from "ui/Skeleton"
import { FormSection } from "modules/WmsModule/modals/components"
import formikPropTypesFactory from "modules/WmsModule/helpers/formikPropTypesFactory"
import { CombinationAttributeSelect } from "ui"

import styles from "./CombinationForm.module.css"

const CombinationForm = ({ productId, formik }) => {
    const isTablet = useIsTablet()
    const { data: productDetails, isLoading } = useQuery(["product", productId], () =>
        fetch.get(`/products/${productId}`)
    )

    const attributes = _get(productDetails, "data.attributes", [])
    const combinationPatterns = _get(productDetails, "data.combination_patterns", [])

    const { selectAttribute, attributesForComponent, selectedAttributes } = useSelectCombination({
        attributes,
        combinationPatterns,
        enabled: !isLoading
    })

    useEffect(() => {
        formik.setFieldValue("selectedCombinationAttributes", selectedAttributes)
    }, [selectedAttributes])

    const renderAttributeSelects = () => {
        const selectsInRow = attributes.length <= 2 || isTablet ? 2 : 3
        const attributesClass = selectsInRow === 2 ? styles.attributes50 : styles.attributes33

        return (
            <div className={classNames(styles.attributes, attributesClass)}>
                {attributesForComponent.map(
                    ({ id, name, type, availableAttributeValues, isDisabled, selectedAttributeValue }, index) => (
                        <CombinationAttributeSelect
                            id={id}
                            name={name}
                            selectAttribute={selectAttribute}
                            selectedAttributeValue={selectedAttributeValue}
                            availableAttributeValues={availableAttributeValues}
                            type={type}
                            key={id}
                            isDisabled={isDisabled || formik.isSubmitting}
                            zoomDirection={index % selectsInRow === 0 ? "right" : "left"}
                        />
                    )
                )}
            </div>
        )
    }

    return (
        <FormSection
            title={<H2 classes={{ h2: styles.sectionTitle }}>Combination</H2>}
            className={styles.combinationForm}
        >
            {formik.errors.combination && (
                <ErrorLabel error={formik.errors.combination} customStyles={styles.combinationError} />
            )}
            {isLoading ? (
                <div className={styles.loader}>
                    <SnakeLoader customStyles={styles.loader} />
                </div>
            ) : (
                renderAttributeSelects()
            )}
        </FormSection>
    )
}

CombinationForm.propTypes = {
    productId: PropTypes.number.isRequired,
    formik: formikPropTypesFactory({
        errors: PropTypes.shape({
            combination: PropTypes.string
        }).isRequired
    })
}

export default CombinationForm
