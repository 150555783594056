import { useCallback, useEffect, useState, useMemo } from "react"
import { useTableSelectedRows } from "@butterfly-frontend/ui"

const useShipmentMultiplePackage = props => {
    const { isOnePackageMode, stockItemList, formik } = props
    const [notAddedStockItemToPackages, setNotAddedStockItemToPackages] = useState([])

    const {
        selectedRows,
        isAnyRowSelected,
        areAllRowsOnCurrentPageSelected: isAllRowSelected,
        checkIsRowSelected,
        toggleRowSelection,
        toggleAllCurrentPageRowsSelection: toggleAllRowSelection,
        unselectAllCurrentPageRows: unselectAllRow
    } = useTableSelectedRows({
        initialSelections: [],
        currentPageRows: notAddedStockItemToPackages
    })

    const setPackages = useCallback(
        func => {
            formik.setFieldValue("packages", func(formik.values.packages))
        },
        [formik.setFieldValue, formik.values]
    )

    const packages = useMemo(() => formik.values.packages, [formik.values])

    const clearStateNotAddedStockItemList = useCallback(() => {
        setNotAddedStockItemToPackages(prevState =>
            prevState.filter(({ id }) => !selectedRows.some(row => row.id === id))
        )
        unselectAllRow()
    }, [selectedRows, unselectAllRow])

    const handleCreateShipmentPackage = useCallback(() => {
        setPackages(prevState => [
            ...prevState,
            {
                carrier: "",
                trackingNumber: "",
                stockItemList: selectedRows
            }
        ])
        clearStateNotAddedStockItemList()
    }, [setPackages, selectedRows, clearStateNotAddedStockItemList])

    const handleAddStockItemToSelectedPackage = useCallback(
        packageIndex => {
            setPackages(prevState =>
                prevState.map((currentPackage, index) =>
                    index === packageIndex
                        ? {
                              ...currentPackage,
                              stockItemList: [...currentPackage.stockItemList, ...selectedRows]
                          }
                        : currentPackage
                )
            )

            clearStateNotAddedStockItemList()
        },
        [setPackages, selectedRows, clearStateNotAddedStockItemList]
    )

    const handleRemoveStockItemFromShipmentPackage = useCallback(
        (packageIndex, stockItem) => {
            setNotAddedStockItemToPackages(prevState => [...prevState, stockItem])

            setPackages(prevState =>
                prevState.reduce((aggregatePackages, currentPackage, index) => {
                    if (index === packageIndex) {
                        return currentPackage.stockItemList.length === 1
                            ? aggregatePackages
                            : [
                                  ...aggregatePackages,
                                  {
                                      ...currentPackage,
                                      stockItemList: currentPackage.stockItemList.filter(
                                          ({ id }) => id !== stockItem.id
                                      )
                                  }
                              ]
                    }

                    return [...aggregatePackages, currentPackage]
                }, [])
            )
        },
        [setPackages]
    )

    const handleChangeFieldForOneOfManyPackages = useCallback(
        (packageIndex, key, value) =>
            setPackages(prevState =>
                prevState.map((currentPackage, index) =>
                    index === packageIndex ? { ...currentPackage, [key]: value } : currentPackage
                )
            ),
        [setPackages]
    )

    useEffect(() => {
        if (!isOnePackageMode) {
            formik.setFieldValue("packages", [])
            setNotAddedStockItemToPackages(stockItemList)
        }
    }, [isOnePackageMode, formik.setFieldValue])

    return {
        selectedRows,
        packages,
        notAddedStockItemToPackages,
        isAnyRowSelected,
        isAllRowSelected,
        toggleRowSelection,
        toggleAllRowSelection,
        handleAddStockItemToSelectedPackage,
        handleCreateShipmentPackage,
        handleRemoveStockItemFromShipmentPackage,
        handleChangeFieldForOneOfManyPackages,
        checkIsRowSelected
    }
}

export default useShipmentMultiplePackage
