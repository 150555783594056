import React from "react"
import PropTypes from "prop-types"

import Modal from "ui/Modal"
import { Input, Button } from "ui"

import withStyles from "HOC/withStyles"
import styles from "./addClientModal.css"
import inputStyles from "./overrides/InputStyles.css"

const AddClientModal = props => {
    const { cx, isOpen, isLoading, isDisabled, closeModal, submit, onKeyDown, fieldsProps } = props

    const renderInput = field => (
        <Input customStyles={inputStyles} type="text" onKeyDown={onKeyDown} {...fieldsProps[field]} />
    )

    return (
        <Modal isOpen={isOpen} closeModal={closeModal} preventClickOutside>
            <Modal.Header>Create new client</Modal.Header>
            <Modal.Body>
                <div className={cx("nameWrapper")}>
                    {renderInput("firstName")}
                    {renderInput("lastName")}
                </div>
                {renderInput("email")}
            </Modal.Body>
            <Modal.Actions>
                <button className={cx("cancelButton")} onClick={closeModal}>
                    Cancel
                </button>
                <Button
                    className="first-button"
                    onClick={submit}
                    label="Create"
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                />
            </Modal.Actions>
        </Modal>
    )
}

AddClientModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    fieldsProps: PropTypes.shape({
        firstName: PropTypes.object,
        lastName: PropTypes.object,
        email: PropTypes.object
    }).isRequired
}

export default withStyles(AddClientModal, styles)
