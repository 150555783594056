import React, { Fragment } from "react"

import Layout from "components/Reusable/Layout"
import Loader from "components/Reusable/Loader/Loader"
import Skeleton from "components/Skeleton"
import { Button, SearchBar, SelectWithSearch, EmptyList } from "ui"
import { Header } from "ui/FilterableTable"
import stylesSearchBar from "./overrrides/searchBar.css"
import ListItem from "./components/ListItem"
import stylesEmptyList from "./overrrides/emptyList.css"
import stylesEmptyListButton from "./overrrides/emptyListButton.css"
import { formatPriceByComa } from "helpers/units"
import { SORT_OPTIONS } from "./configs"
import productIcon from "assets/ico-projects.svg"
import stylesSort from "./overrrides/sort.css"

const List = props => {
    const { services, cx, emptyFilters, setCreateModalIsOpen, openEditModal, openDeleteModal, handleClearAll } = props

    function renderActions() {
        return (
            <span className={cx("actions")}>
                <Button
                    className={`first-button ${cx("first-button")}`}
                    label="Create New"
                    onClick={() => setCreateModalIsOpen(true)}
                />
            </span>
        )
    }

    return (
        <Layout title="Services" renderActions={renderActions}>
            {renderHeader()}
            <div className={cx("listContainer")}>
                <Skeleton
                    fetchStatus={services.fetchStatus}
                    component={
                        <div className={cx("loader")}>
                            <Loader />
                        </div>
                    }
                    render={() => (
                        <Fragment>
                            {services.data.length === 0 && emptyFilters && renderNotFound()}
                            {services.data.length === 0 && !emptyFilters && renderNotFoundWithButton()}

                            {services.data.length > 0 && (
                                <Fragment>
                                    {renderListHeader()}
                                    {renderList()}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                />
            </div>
        </Layout>
    )

    function renderListHeader() {
        const { services } = props

        return (
            <header className={cx("listHeaderContainer")}>
                <div className={cx("listHeaderSide`")}>
                    <div className={cx("listHeaderMeta")}>
                        <b>
                            {services.meta.from}-{services.meta.to}
                        </b>{" "}
                        over {services.meta.total} results
                    </div>
                </div>
                <div className={cx("listHeaderSide")}>{renderListHeaderSortBy()}</div>
            </header>
        )
    }

    function renderListHeaderSortBy() {
        const { filterSortingIndex, filterSortingHandleChange } = props

        return (
            <div className={cx("listHeaderSideItem")}>
                <span className={cx("listHeaderLabel")}>Sort By:</span>
                <div className={cx("listHeaderValue")}>
                    <SelectWithSearch
                        values={SORT_OPTIONS.map((element, index) => {
                            return {
                                value: index,
                                label: element.display
                            }
                        })}
                        customStyles={stylesSort}
                        value={(SORT_OPTIONS[filterSortingIndex] || {}).display || ""}
                        setValue={({ value }) => filterSortingHandleChange(value)}
                        withoutFetch
                        isClearBlocked
                    />
                </div>
            </div>
        )
    }

    function renderList() {
        return (
            <div className={cx("listWrapper")}>
                <div className={cx("list", "viewList")}>
                    <ListItem>
                        {({ cx }) => (
                            <div className={cx("listItem", "headerListItem")}>
                                <div className={cx("nameContainer")}>
                                    <span>Service Name</span>
                                </div>
                                <div className={cx("descriptionContainer")}>
                                    <span>Description</span>
                                </div>
                                <div className={cx("exemptCodeContainer")}>
                                    <span>S.E. Code</span>
                                </div>
                                <div className={cx("priceContainer")}>
                                    <span>Price</span>
                                </div>
                                <div className={cx("optionContainer")} />
                            </div>
                        )}
                    </ListItem>

                    {services.data.map(service => {
                        return (
                            <ListItem
                                key={service.id}
                                service={service}
                                renderPrice={renderPrice}
                                handleEdit={id => openEditModal(id)}
                                handleDelete={(id, name) => openDeleteModal(name, id)}
                            />
                        )
                    })}
                </div>
            </div>
        )
    }

    function renderHeader() {
        const { setSearchValue, filterInitialSearchPhrase, filterInitialSearchPhraseRef } = props

        return (
            <Header>
                <SearchBar
                    value={filterInitialSearchPhrase}
                    valueRef={filterInitialSearchPhraseRef}
                    placeholder="Search for service..."
                    handleSearch={setSearchValue}
                    customStyles={stylesSearchBar}
                />
            </Header>
        )
    }

    function renderPrice(price) {
        return (
            <Fragment>
                $&nbsp;<strong>{`${formatPriceByComa(price, true)}.${formatPriceByComa(price, false)}`}</strong>
            </Fragment>
        )
    }

    function renderNotFound() {
        return <EmptyList customStyles={stylesEmptyList} message="No service Added" icon={productIcon} />
    }

    function renderNotFoundWithButton() {
        return (
            <EmptyList customStyles={stylesEmptyList} message="Sorry, no results found!" icon={productIcon}>
                <div className={cx("emptyListActions")}>
                    <Button
                        customStyles={stylesEmptyListButton}
                        className={["first-button", "white"]}
                        label="Back to All Products"
                        onClick={() => handleClearAll()}
                    />
                </div>
            </EmptyList>
        )
    }
}

export default List
