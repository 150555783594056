import React, { Component } from "react"

import Layout from "components/Reusable/Layout/index"
import withPermissions from "HOC/withPermissions"
class OtherListingLayout extends Component {
    getTabs() {
        const { canList, PERMISSIONS } = this.props

        const tabs = [
            {
                url: "/settings/types",
                label: "Type of Contact"
            },
            {
                url: "/settings/interactions",
                label: "Interactions"
            },
            {
                url: "/settings/conditions",
                label: "Product Conditions"
            }
        ]

        if (canList(PERMISSIONS.context.CONTENT)) {
            tabs.push(
                {
                    url: "/settings/channels",
                    label: "Preferred communication channels"
                },
                {
                    url: "/settings/divisions",
                    label: "Divisions"
                },
                {
                    url: "/settings/sources",
                    label: "Sources"
                },
                {
                    url: "/settings/services",
                    label: "Services"
                }
            )
        }

        return tabs
    }

    render() {
        const { children } = this.props

        return (
            <Layout {...this.props} title="Other" tabs={this.getTabs()}>
                {children}
            </Layout>
        )
    }
}

export default withPermissions(OtherListingLayout)
