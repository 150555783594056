import React from "react"
import PropTypes from "prop-types"
import withStyles from "HOC/withStyles"
import styles from "./ActionButton.module.css"

const ActionButton = ({ cx, className, type, disabled, handleClick, icon, label }) => {
    return (
        <button disabled={disabled} className={`${cx("root")} ${className}`} type={type} onClick={handleClick}>
            {icon && <img src={icon} alt={label} />}
            {label}
        </button>
    )
}

ActionButton.defaultProps = {
    className: "",
    type: "button",
    disabled: false
}

ActionButton.propTypes = {
    className: PropTypes.string,
    type: PropTypes.oneOf(["button", "submit", "reset"]),
    disabled: PropTypes.bool,
    handleClick: PropTypes.func.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string.isRequired
}

export default withStyles(ActionButton, styles)
