import React, { Fragment, useMemo } from "react"
import { Link, useParams } from "react-router-dom"

import { H1, Panel, Button, BackIcon, SnakeLoader } from "@butterfly-frontend/ui"
import { Dropdown } from "ui"
import { useShipmentDetails } from "modules/WmsModule/hooks/api/useShipment"
import SHIPMENT_STATUSES from "modules/WmsModule/constants/shipmentStatuses"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import useBoxLabelOptions from "modules/WmsModule/hooks/useBoxLabelOptions"
import useConsignmentNoteOptions from "modules/WmsModule/hooks/useConsignmentNoteOptions"
import { checkIfCanGenerateConsignmentNote } from "modules/WmsModule/helpers/consignmentNote"

import styles from "./ShipmentDetailsPage.module.css"
import ShipmentSentPage from "./ShipmentSentPage"
import ShipmentInProgressPage from "./ShipmentInProgressPage"

const getStockItemsIds = stockItems => stockItems.map(({ id }) => id)

const ShipmentDetailsPage = () => {
    const params = useParams()
    const {
        data,
        fetchStatus,
        query: { error }
    } = useShipmentDetails({ id: params.shipmentId })

    const { options: boxLabelsOptions, isLoading: isBoxLabelOptionsLoading } = useBoxLabelOptions({
        ids: data ? getStockItemsIds(data.items) : []
    })

    const { options: consignmentNoteOptions, isLoading: isConsignmentNoteOptionsLoading } = useConsignmentNoteOptions({
        ids: [params.shipmentId]
    })

    const previewDropdownOptions = useMemo(
        () => [
            ...(boxLabelsOptions || []),
            ...(data && checkIfCanGenerateConsignmentNote(data) ? consignmentNoteOptions : [])
        ],
        [consignmentNoteOptions, boxLabelsOptions, data]
    )

    if (fetchStatus.isLoading) {
        return (
            <Panel classes={{ panel: styles.root }}>
                <SnakeLoader />
            </Panel>
        )
    }

    if (fetchStatus.isError) {
        return (
            <Panel classes={{ panel: styles.root }}>
                <p className={styles.error}>{error.statusText}</p>
            </Panel>
        )
    }

    const shipmentStatus = SHIPMENT_STATUSES[data.status.status]
    const isShipmentStatusSent = shipmentStatus === SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.SENT]
    const isShipmentStatusCancelled = shipmentStatus === SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.CANCELLED]
    const isPreviewOptionsLoading = isBoxLabelOptionsLoading || isConsignmentNoteOptionsLoading

    return (
        <Panel classes={{ panel: styles.root }}>
            <div className={styles.headerWrapper}>
                <H1
                    withLeftBorder
                    classes={{ root: [styles.header, { [styles.headerDanger]: isShipmentStatusCancelled }] }}
                >
                    {data.uuid}
                </H1>
                <Dropdown
                    label={
                        <Fragment>
                            Preview{" "}
                            {isPreviewOptionsLoading && (
                                <SnakeLoader
                                    classes={{ root: styles.dropdownLoaderWrapper, loader: styles.dropdownLoader }}
                                />
                            )}
                        </Fragment>
                    }
                    className={styles.previewDropdown}
                    options={previewDropdownOptions}
                    disabled={isPreviewOptionsLoading}
                />
            </div>
            {isShipmentStatusSent ? <ShipmentSentPage data={data} /> : <ShipmentInProgressPage data={data} />}
            <Button
                type="link"
                variant="flat"
                Icon={BackIcon}
                classes={{ button: styles.backButton }}
                LinkComponent={Link}
                propsLinkComponent={{ to: "/wms/shipments" }}
            >
                Shipments
            </Button>
        </Panel>
    )
}

export default ShipmentDetailsPage
