import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import getCxFromStyles from "helpers/getCxFromStyles"
import { ACTION_TYPES, LIST_TYPES } from "modules/AttributeGroupsModules/constants"
import styles from "./MessageNotificationWithLink.css"

const MESSAGES_FOR_TYPE_ITEM = {
    [LIST_TYPES.SUBGROUP]: "subgroup attributes",
    [LIST_TYPES.ATTRIBUTE]: "attributes",
    [LIST_TYPES.ATTRIBUTE_VALUE]: "attribute values"
}

const MESSAGES_FOR_TYPE_TRANSFER = {
    [ACTION_TYPES.MOVE]: "moved",
    [ACTION_TYPES.COPY]: "copied"
}

const MessageTransferNotificationWithLink = props => {
    const { typeTransfer, typeItem, numberOfItems, link, textLink, customMessage } = props
    const cx = getCxFromStyles(styles)

    return (
        <span>
            {customMessage || (
                <Fragment>
                    {numberOfItems}
                    {` ${MESSAGES_FOR_TYPE_ITEM[typeItem]}`}
                    {` ${MESSAGES_FOR_TYPE_TRANSFER[typeTransfer]} to `}
                </Fragment>
            )}
            <Link to={link} className={cx("link")}>
                {textLink}
            </Link>
        </span>
    )
}

MessageTransferNotificationWithLink.propTypes = {
    link: PropTypes.string.isRequired,
    textLink: PropTypes.string.isRequired,
    typeItem: PropTypes.oneOf(Object.values(LIST_TYPES)),
    typeTransfer: PropTypes.oneOf([ACTION_TYPES.COPY, ACTION_TYPES.MOVE]),
    customMessage: PropTypes.string,
    numberOfItems: PropTypes.number
}

export default MessageTransferNotificationWithLink
