import React, { Fragment, useState } from "react"
import { useLocation } from "react-router-dom"
import { parse } from "qs"
import PropTypes from "prop-types"

import { Tabs } from "ui"
import { Panel, SnakeLoader } from "@butterfly-frontend/ui"
import { Summary, SalesPersonList, OrdersList, PaymentsList, Header } from "modules/DashboardModule/components"

import styles from "./SellersPage.module.css"
import tabsStyles from "./overrides/Tabs.css"

const TABS = {
    salesPersons: {
        label: "SALES PERSONS"
    },
    salesOrders: {
        label: "SALES ORDERS"
    },
    payments: {
        label: "PAYMENTS"
    }
}

const SellersPage = ({ data, selectedRange, isLoading }) => {
    const location = useLocation()
    const initialActiveTab = location.search ? parse(location.search.slice(1)).activeTab : "salesOrders"

    const [activeTabKey, setActiveTabKey] = useState(initialActiveTab)

    return (
        <Fragment>
            <Header title="Sales details" />

            {isLoading ? (
                <SnakeLoader />
            ) : (
                <Fragment>
                    <Summary dashboardData={data} short />

                    <Panel classes={{ panel: styles.panel }}>
                        <Tabs
                            tabs={TABS}
                            activeTabKey={activeTabKey}
                            onTabChange={setActiveTabKey}
                            customStyles={tabsStyles}
                        />

                        {activeTabKey === "salesPersons" && <SalesPersonList selectedRange={selectedRange} />}
                        {activeTabKey === "salesOrders" && <OrdersList selectedRange={selectedRange} />}
                        {activeTabKey === "payments" && <PaymentsList selectedRange={selectedRange} />}
                    </Panel>
                </Fragment>
            )}
        </Fragment>
    )
}

SellersPage.propTypes = {
    data: PropTypes.shape({
        orders_totals: PropTypes.shape({
            total_sales_amount: PropTypes.number,
            total_returns_amount: PropTypes.number,
            total_store_credits: PropTypes.number,
            total_payments: PropTypes.number
        }).isRequired,
        invoiced: PropTypes.shape({
            invoices_count: PropTypes.number,
            fully_paid: PropTypes.number
        }).isRequired,
        orders_by_completion: PropTypes.shape({
            incomplete: PropTypes.number,
            finished: PropTypes.number
        }).isRequired,
        sale_channels: PropTypes.shape({
            showroom: PropTypes.number,
            web: PropTypes.number
        }).isRequired
    }),
    selectedRange: PropTypes.shape({
        date_from: PropTypes.string,
        date_to: PropTypes.string
    }).isRequired,
    isLoading: PropTypes.bool
}

export default SellersPage
