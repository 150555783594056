import React, { useState, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch } from "react-redux"

import withStyles from "HOC/withStyles"
import { Modal } from "ui"
import { List } from "./components"
import Loader from "components/Reusable/Loader"

import fetch from "helpers/fetch"
import { getProject } from "actions/projects"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import styles from "./ChangeRoomModal.css"
import modalStyles from "./overrides/modalStyles.css"
import modalBodyStyles from "./overrides/modalBodyStyles.css"
import loaderStyles from "./overrides/Loader.css"

import addIcon from "assets/add.svg"

const ChangeRoomModal = props => {
    const { cx, isOpen, isCopy, projectId, products, rooms, onClose, handleCreateNewRoom } = props

    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = roomId => {
        setIsLoading(true)

        if (isCopy) {
            return fetch
                .postRAW("/multi-actions", {
                    ids: products.map(({ id }) => id),
                    model: "ProjectItems",
                    action: "copy",
                    payload: {
                        target_id: roomId
                    }
                })
                .then(() => {
                    dispatch(getProject(projectId))
                    dispatch(showSuccessNotification())
                })
                .catch(() => dispatch(showErrorNotification()))
                .finally(() => {
                    setIsLoading(false)
                    onClose()
                })
        }

        if (roomId) {
            const room = rooms.find(({ id }) => id === roomId)
            const [url, name] = [`/${roomId}`, room.name]

            const data = {
                name,
                project_id: projectId,
                projectItems_attach: products.map(({ id }) => ({ id }))
            }

            return fetch
                .patch(`/rooms${url}`, data)
                .then(() => {
                    dispatch(getProject(projectId))
                    dispatch(showSuccessNotification())
                })
                .catch(() => dispatch(showErrorNotification()))
                .finally(() => {
                    setIsLoading(false)
                    onClose()
                })
        }

        return Promise.all(
            products.map(({ id }) => {
                return fetch.patch("/project-items/" + id, {
                    room_id: null
                })
            })
        ).then(
            () => {
                setTimeout(() => {
                    setIsLoading(false)
                    onClose()
                    dispatch(getProject(projectId))
                    dispatch(showSuccessNotification())
                }, 350)
            },
            () => {
                setIsLoading(false)
                dispatch(showErrorNotification())
            }
        )
    }

    const [activeRooms, restRooms] = useMemo(
        () =>
            rooms.reduce(
                (acc, room) => {
                    const isActive = room.items.some(item =>
                        products.some(product => Number(product.id) === Number(item.id))
                    )
                    return isActive ? [[...acc[0], room], acc[1]] : [acc[0], [...acc[1], room]]
                },
                [[], []]
            ),
        [rooms, products]
    )

    return (
        <Modal isOpen={isOpen} closeModal={onClose} customStyles={modalStyles} preventClickOutside={true}>
            <Modal.Header>Choose a room:</Modal.Header>
            <Modal.Body customStyles={modalBodyStyles}>
                {!!rooms.length && <List activeRooms={activeRooms} restRooms={restRooms} setRoom={onSubmit} />}
                <button type="button" className={cx("createRoom")} onClick={handleCreateNewRoom}>
                    <img src={addIcon} alt="addImage" />
                    Create new room
                </button>
                {isLoading && (
                    <div className={cx("loader")}>
                        <Loader customStyles={loaderStyles} />
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

ChangeRoomModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    isCopy: PropTypes.bool.isRequired,
    projectId: PropTypes.number.isRequired,
    products: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number
        })
    ).isRequired,
    rooms: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number
                })
            )
        })
    ).isRequired,
    onClose: PropTypes.func.isRequired,
    handleCreateNewRoom: PropTypes.func.isRequired
}

export default withStyles(ChangeRoomModal, styles)
