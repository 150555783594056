import _get from "lodash/get"
import _merge from "lodash/merge"

const checkErrorType = error => typeof error === "string" || Array.isArray(error)

const getSelectPropsFactory = (formik, customErrorStyles) => (key, additionalProps) => {
    const selectValue = _get(formik.values, key)
    const selectLabel = _get(selectValue, "label", selectValue)
    const renderSticker = _get(selectValue, "renderSticker")

    const error = _get(formik.touched, key) && _get(formik.errors, key)

    const baseProps = {
        value: selectLabel,
        renderSticker,
        error: checkErrorType(error) ? error : undefined,
        setValue: async value => {
            const valueToSet = value && value.value ? value : null
            await formik.setFieldValue(key, valueToSet)
            formik.setFieldTouched(key)
        },
        isDisabled: formik.isSubmitting,
        customErrorStyles
    }

    return additionalProps ? _merge(baseProps, additionalProps) : baseProps
}

export default getSelectPropsFactory
