import React, { Component, Fragment } from "react"
import { NotesSection } from "ui/Notes"
import withStyles from "HOC/withStyles"
import style from "./Overview.module.css"
import notesSectionStyles from "./overrides/NotesSection.module.css"
import {
    Header,
    GeneralInfo,
    Temp,
    TaxCode,
    Analytics,
    Attachments,
    ClientMeetings,
    Projects,
    StoreCredit
} from "./components"
import ClientCardFavorites from "modules/ClientModule/pages/View/ClientCard/ClientCardFavorites/index"
import ClientCardProjects from "modules/ClientModule/pages/View/ClientCard/ClientCardProjects/index"
import { Invoices, SaleOrders, PurchaseOrders, Payments } from "modules/ProjectsModule/pages/Overview/Details/Tabs"
import { withMultiselectProvider } from "HOC/withMultiselect"

class Overview extends Component {
    NOTES_TITLE = "Notes"
    ATTACHMENTS_TITLE = "Attachments"
    TMP_TITLE = "Temp"
    CLIENT_MEETINGS = "Client Meetings"

    render() {
        const { cx, data, currentTabIndex, url, handleAttachmentsCount, getAttachmentsCount } = this.props

        const {
            first_name,
            last_name,
            birth_day,
            active,
            verified,
            tax_code,
            taxable,
            reseller,
            online_enabled,
            companies,
            groups,
            sources,
            services,
            id,
            mb_sold_by,
            mla_sold_by,
            mbid,
            mlaid,
            addresses,
            alternative_email,
            email,
            users,
            phone,
            mobile,
            website,
            twitter,
            instagram,
            channels,
            projects,
            types,
            newsletter,
            newsletter_changed_at,
            showroom_visits,
            last_showroom_visits,
            online_visits,
            last_online_visits,
            created_at,
            updated_at,
            facebook,
            vip,
            other_social,
            job_title,
            avatar,
            general_note,
            analytics
        } = data

        let Components = [
            <GeneralInfo
                addresses={addresses}
                first_name={first_name}
                last_name={last_name}
                alternativeEmail={alternative_email}
                email={email}
                phone={phone}
                channels={channels}
                mobile={mobile}
                website={website}
                twitter={twitter}
                instagram={instagram}
                facebook={facebook}
                otherSocial={other_social}
            />,
            <NotesSection
                notes={data.notes}
                type="client"
                isHeaderHidden
                getAttachmentsCount={getAttachmentsCount}
                customStyles={notesSectionStyles}
            />,
            <Analytics
                totalPurchaseAmount={analytics.total_purchase_amount}
                totalReturnAmount={analytics.total_return_amount}
                lastPurchase={analytics.last_purchase_date}
                totalCollectedSales={analytics.total_collected_sales}
                hasNewsletter={newsletter}
                averagePurchase={analytics.average_purchase}
                newsletterChangedAt={newsletter_changed_at}
                showroomVisits={showroom_visits}
                lastShowroomVisits={last_showroom_visits}
                onlineVisits={online_visits}
                lastOnlineVisits={last_online_visits}
                dateOfBirth={birth_day}
                avatar={avatar}
                generalNote={general_note}
                storeCredits={analytics.store_credits}
            />,
            <TaxCode taxCode={tax_code} taxable={taxable} reseller={reseller} />,
            <Temp mlaId={mlaid} mbId={mbid} mlaSoldBy={mla_sold_by} mbSoldBy={mb_sold_by} />,
            <Attachments clientId={id} handleAttachmentsCount={handleAttachmentsCount} />,
            <ClientMeetings clientId={id} />,
            <Projects clientId={id} />,
            <SaleOrders clientId={id} />,
            <PurchaseOrders clientId={id} />,
            <Invoices clientId={id} />,
            <Payments clientId={id} />,
            <StoreCredit clientId={id} />
        ]

        return (
            <Fragment>
                <section className={cx("root")}>
                    <Header
                        lastUpdated={updated_at}
                        added={created_at}
                        url={url}
                        id={id}
                        vip={vip}
                        users={users || []}
                        isActive={active}
                        isVerified={verified}
                        sources={sources || []}
                        services={services || []}
                        companies={companies || []}
                        groups={groups || []}
                        types={types || []}
                        isOnlineEnabled={online_enabled}
                        name={`${first_name} ${last_name}`}
                        getAttachmentsCount={getAttachmentsCount}
                        job_title={job_title}
                        addresses={addresses}
                    />
                    {this.renderTabs()}
                    {Components[currentTabIndex]}
                </section>
                <ClientCardFavorites client={this.props} />
                <ClientCardProjects list={projects || []} />
            </Fragment>
        )
    }

    renderTabs() {
        const { cx, tabs, attachmentsCount, currentTabIndex, handleTab, isTmpTabShown, data } = this.props
        const { notes } = data

        return (
            <ul className={cx("tabs")}>
                {tabs.map((title, index) => {
                    if (
                        (title === this.TMP_TITLE && !isTmpTabShown) ||
                        (title === this.NOTES_TITLE && notes.length === 0)
                    ) {
                        return null
                    }
                    return (
                        <li
                            key={index}
                            className={cx({ isActive: currentTabIndex === index })}
                            onClick={() => handleTab(index)}
                        >
                            <p>
                                {title}
                                {title === this.NOTES_TITLE && notes.length > 0 && <span>{notes.length}</span>}
                                {title === this.ATTACHMENTS_TITLE && attachmentsCount > 0 && (
                                    <span>{attachmentsCount}</span>
                                )}
                            </p>
                        </li>
                    )
                })}
            </ul>
        )
    }
}

export default withMultiselectProvider("CLIENT_INFO")(withStyles(Overview, style))
