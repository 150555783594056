import React, { Fragment } from "react"
import { Link } from "react-router-dom"
import idx from "idx"
import moment from "moment"
import { formatDate } from "helpers/date"
import { getRouteByOrderType } from "helpers/urls"
import { withListContext } from "modules/OrdersModule/components/List/ListContainer"

const GrayDetails = props => {
    const { cx, documentData, listConfig } = props
    const {
        order,
        created_at,
        payment_due_at,
        emailed_at,
        discount,
        user,
        status,
        estimated_delivery,
        origin,
        shipping_method
    } = documentData
    const { abbreviation } = listConfig

    return (
        <div className={cx("grayDetails")}>
            <div className={cx("wrapper")}>
                <div className={cx("detailsElement")}>
                    <label>Parent SO:</label>
                    {order && order.uuid && order.id && order.type ? (
                        <Link className={cx("orderLink")} to={`/orders/${getRouteByOrderType(order.type)}/${order.id}`}>
                            {order.uuid}
                        </Link>
                    ) : (
                        <span>-</span>
                    )}
                </div>
                <div className={cx("detailsElement")}>
                    <label>{abbreviation} date:</label>
                    <span>{formatDate(created_at)}</span>
                </div>
                {abbreviation === "Invoice" && (
                    <div className={cx("detailsElement")}>
                        <label>Due date:</label>
                        <span
                            className={cx("dueDate", {
                                isAfterDueDate: status !== "paid" && moment().isAfter(payment_due_at)
                            })}
                        >
                            {formatDate(payment_due_at)}
                        </span>
                    </div>
                )}
                <div className={cx("detailsElement")}>
                    <label>Emailed date:</label>
                    <span>{formatDate(emailed_at)}</span>
                </div>
                <div className={cx("detailsElement")}>
                    <label>Discount applied:</label>
                    <span>{discount ? parseFloat(parseFloat(discount.value || discount).toFixed(2)) : 0}%</span>
                </div>
                {status === "confirmed" && (
                    <Fragment>
                        <div className={cx("detailsElement")}>
                            <label>Arrival date:</label>
                            <span>{formatDate(estimated_delivery)}</span>
                        </div>
                        <div className={cx("detailsElement")}>
                            <label>Origin:</label>
                            <span>{origin || "-"}</span>
                        </div>
                        <div className={cx("detailsElement")}>
                            <label>Ship via:</label>
                            <span>{idx(shipping_method, _ => _.name) || "-"}</span>
                        </div>
                    </Fragment>
                )}
                <div className={cx("detailsElement")}>
                    <label>Issued by:</label>
                    <span className={cx("issuedBy")}>{idx(user, _ => _.full_name) || "-"}</span>
                </div>
            </div>
        </div>
    )
}

export default withListContext(GrayDetails)
