import React, { Component, Fragment } from "react"
import CSSModules from "react-css-modules"
import style from "./View.css"
import ClientCard from "./ClientCard"
import ReturnHeader from "components/Reusable/ReturnHeader/ReturnHeader"

class View extends Component {
    componentDidMount() {
        this.resolveClient(this.props)
    }

    // eslint-disable-next-line
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { match } = this.props
        const prevId = match && match.params.clientId ? match.params.clientId : null
        this.resolveClient(nextProps, prevId)
    }

    resolveClient(props, prevId = null) {
        const { match, getClientById } = props

        if (match && match.params && match.params.clientId && match.params.clientId !== prevId) {
            getClientById(match.params.clientId)
        }
    }

    render() {
        const { id } = this.props.client.data
        const { isLoaded } = this.props.client
        return (
            <Fragment>
                <ReturnHeader link={`/contact/${id}`} label="All Contact" isArrow={true} />
                <div className="round-border" styleName="full">
                    <ClientCard showAllAddresses={true} {...this.props} fullProfile={true} isLoaded={isLoaded} />
                    <div className="clear" />
                </div>
            </Fragment>
        )
    }
}

export default CSSModules(View, style)
