import { Component } from "react"

class Logout extends Component {
    componentDidMount() {
        window.localStorage.clear()
        window.location.href = "/login"
    }

    render() {
        return null
    }
}

export default Logout
