import * as constants from "../constants"
import fetch from "../helpers/fetch"
import { objToQueryString } from "../helpers/urls"

const getDocsTemplatesStart = () => ({ type: constants.GET_DOCS_TEMPLATES_START })

const getDocsTemplatesSuccess = data => ({ type: constants.GET_DOCS_TEMPLATES_SUCCESS, payload: data })

const getDocsTemplatesError = error => ({ type: constants.GET_DOCS_TEMPLATES_ERROR, payload: error })

export const getDocsTemplates = (params = {}) => {
    return async dispatch => {
        dispatch(getDocsTemplatesStart())
        try {
            const data = await fetch.get(`/order-templates?type=document&${objToQueryString(params, true)}`)
            dispatch(getDocsTemplatesSuccess(data))
            return data
        } catch (error) {
            dispatch(getDocsTemplatesError(error))
            throw error
        }
    }
}

export const updateDocumentTemplateStart = () => ({ type: constants.UPDATE_DOCUMENT_TEMPLATE_START })

export const updateDocumentTemplateSuccess = data => ({
    type: constants.UPDATE_DOCUMENT_TEMPLATE_SUCCESS,
    payload: data
})

export const updateDocumentTemplateError = error => ({
    type: constants.UPDATE_DOCUMENT_TEMPLATE_ERROR,
    payload: error
})

export const updateDocumentTemplate = (id, data) => async dispatch => {
    dispatch(updateDocumentTemplateStart())
    try {
        const returnedData = await fetch.patch(`/order-templates/${id}`, data)
        dispatch(updateDocumentTemplateSuccess(returnedData))
        return returnedData
    } catch (error) {
        dispatch(updateDocumentTemplateError(error))
        throw error
    }
}
