import React, { Component } from "react"
import { compose } from "redux"

import { withStyles } from "ui/FilterableTable/HOC"
import { AppContext } from "ui/FilterableTable"
import Pagination from "components/Reusable/Pagination"

import styles from "./Pagination.css"

class PaginationConsumer extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {context => <PaginationWithContext {...this.props} context={context} />}
            </AppContext.Consumer>
        )
    }
}

class PaginationWithContext extends Component {
    state = {
        currentPage: null
    }

    componentDidMount() {
        const { context, current } = this.props

        context.initPage(current)
    }

    render() {
        const { cx, pages, context } = this.props

        return (
            <div className={cx("root")}>
                <Pagination
                    customStyles={styles}
                    handleSelectPage={page => {
                        this.setState({ currentPage: page + 1 })
                        context.changePage(page + 1)
                    }}
                    pagesCount={pages}
                    currentPage={this.state.currentPage ? this.state.currentPage - 1 : context.data.page - 1}
                />
            </div>
        )
    }
}

export default compose(withStyles(styles))(PaginationConsumer)
