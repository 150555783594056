import { INPUT_TYPES } from "constants/index"
import _ from "lodash"
import { scrollToError } from "helpers/scrollTo"

const yup = require("yup")

export const countSelectedFilters = values => {
    if (!values || typeof values !== "object") {
        return 0
    }

    return Object.values(values).reduce((counter, value) => {
        const shouldCount = Array.isArray(value) ? value.length > 0 : !!value
        return shouldCount ? counter + 1 : counter
    }, 0)
}

export const getFieldsFromModel = ({ model, withDefaultValue = true, stateFieldValues = null }) => {
    const { fields } = model
    return fields.reduce((output, field) => {
        let fieldValue = withDefaultValue ? field.defaultValue : ""

        if (stateFieldValues && stateFieldValues[field.name]) {
            fieldValue = stateFieldValues[field.name]
        }

        if (field.type === INPUT_TYPES.MODEL_ARRAY && field.model) {
            fieldValue = field.defaultValue.map((values, index) => {
                if (stateFieldValues && stateFieldValues[field.name] && stateFieldValues[field.name][index]) {
                    values = _.merge(stateFieldValues[field.name][index] || {}, values)
                }

                return getFieldsFromModel({ model: field.model, stateFieldValues: withDefaultValue ? values : {} })
            })
        }

        return {
            ...output,
            [field.name]: fieldValue
        }
    }, {})
}

export const getFieldsForRender = ({
    model,
    stateFieldValues = null,
    stateFieldErrors = null,
    initFieldPath = null,
    handleFieldBlur = null,
    handleFieldChange = null
}) => {
    const { fields } = model
    const output = fields.reduce((output, field) => {
        let fieldValue = field.defaultValue
        let fieldError = ""
        let fieldPath = `${initFieldPath ? `${initFieldPath}.` : ""}${field.name}`

        if (stateFieldValues && stateFieldValues[field.name]) {
            fieldValue = stateFieldValues[field.name]
        }

        if (stateFieldErrors && stateFieldErrors[field.name]) {
            fieldError = stateFieldErrors[field.name]
        }

        if (field.type === INPUT_TYPES.MODEL_ARRAY && field.model) {
            fieldValue = field.defaultValue.map((values, index) => {
                if (stateFieldValues && stateFieldValues[field.name] && stateFieldValues[field.name][index]) {
                    values = _.merge(stateFieldValues[field.name][index] || {}, values)
                }

                let errors = null
                if (stateFieldErrors && stateFieldErrors[field.name] && stateFieldErrors[field.name][index]) {
                    errors = stateFieldErrors[field.name][index]
                }

                return getFieldsForRender({
                    model: field.model,
                    stateFieldValues: values,
                    stateFieldErrors: errors,
                    initFieldPath: `${fieldPath}[${index}]`,
                    handleFieldBlur,
                    handleFieldChange
                })
            })
        }

        if (field.type === INPUT_TYPES.MODEL_ARRAY) {
            return {
                ...output,
                [field.name]: {
                    type: field.type,
                    name: field.name,
                    elements: fieldValue,
                    handleScrollToError: () => {}
                }
            }
        }

        return {
            ...output,
            [field.name]: {
                type: field.type,
                name: field.name,
                label: field.label,
                value: fieldValue,
                defaultValue: field.defaultValue,
                attributes: field.attributes,
                fieldPath,
                error: fieldError,
                handleScrollToError: () => {},
                onBlur: () => handleFieldBlur(fieldPath),
                onChange: handleFieldChange
            }
        }
    }, {})

    for (let i = 0; i < model.fields.length; i++) {
        const key = model.fields[i].name

        if (output[key].error && output[key].error.length > 0) {
            output[key].handleScrollToError = scrollToError
            break
        }
    }

    return output
}

export const getFieldsForYup = ({ model, initial = {}, whiteList = null }) => {
    const { fields } = model
    return fields.reduce((output, field) => {
        if (whiteList !== null && !_.has(whiteList, field.name)) {
            return output
        }

        let validator = null

        if (
            ![
                INPUT_TYPES.ARRAY,
                INPUT_TYPES.MODEL_ARRAY,
                INPUT_TYPES.HIDDEN,
                INPUT_TYPES.SUBMIT,
                INPUT_TYPES.SELECT,
                INPUT_TYPES.DATE
            ].includes(field.type)
        ) {
            validator = yup.string().label(field.label)
        }

        if (field.type === INPUT_TYPES.EMAIL) {
            validator = validator.email().label(field.label)
        }

        if (field.type === INPUT_TYPES.DATE) {
            validator = validator ? validator : yup
            validator = validator
                .date()
                .default(function() {
                    return new Date()
                })
                .label(field.label)
        }

        if (field.attributes.required) {
            validator = validator ? validator : yup
            validator = validator.required().label(field.label)
        }

        if (validator) {
            return {
                ...output,
                [field.name]: validator
            }
        }

        return output
    }, initial)
}
