import React, { useState, useEffect, useCallback, useMemo, Fragment } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { xor, unionBy, pick } from "lodash"
import idx from "idx"

import EmptyList from "ui/EmptyList"
import ChooseProductModal from "modules/OrdersModule/components/ChooseProductForSalesOrderModal"
import { TopBar, List, BottomBar } from "./components"
import Modal from "components/Modal/Modal"
import ConfirmWithSubject from "components/Modal/ConfirmWithSubject/ConfirmWithSubject"

import { useFetch, useActions } from "hooks"
import fetch from "helpers/fetch"
import { getRouteByOrderType } from "helpers/urls"
import {
    createOrder,
    updateOrder,
    createOrderItems,
    updateOrderItem,
    deleteOrderItem,
    getOrderItems,
    getOrder,
    getOrderSuccess
} from "actions/orders"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import { useWarehouseList } from "modules/WmsModule/hooks/api/useWarehouse"
import { createLinkToNewPurchaseOrder } from "modules/OrdersModule/helpers/createLinkToPurchaseOrder"
import useOrderItems from "modules/OrdersModule/hooks/useOrderItems"
import { useProductList } from "ui/ChooseProduct/hooks"
import SALES_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/salesOrderStatusKeys"
import { checkIfCanCrudOrderItem, checkIfCanDeleteOrderItem } from "modules/OrdersModule/helpers/orderItem"
import { useShipmentList } from "modules/WmsModule/hooks/api/useShipment"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import { withMultiselectConsumer } from "HOC/withMultiselect"
import withStyles from "HOC/withStyles"
import orderIcon from "assets/order.svg"

import styles from "./ProductsList.css"

const initialModalState = { modalId: "", orderItemId: null, isLoading: false }

const DeleteModal = ({ modalId, subject, isLoading, handleDelete, handleHideModal }) => {
    const modalConfig = {
        deleteOne: {
            title: "Delete this product?",
            description: "Are you sure you want to delete the product",
            descriptionClosure: "? This action cannot be reversed."
        },
        deleteMany: {
            title: "Delete selected products?",
            description: "Are you sure you want to delete",
            descriptionClosure: "? This action cannot be reversed."
        }
    }

    const isValidModalId = Object.keys(modalConfig).includes(modalId)

    if (!isValidModalId) {
        return null
    }

    return (
        <Modal>
            <ConfirmWithSubject
                {...modalConfig[modalId]}
                subject={subject}
                isLoading={isLoading}
                handleDelete={handleDelete}
                handleHideModal={handleHideModal}
                withQuotes={false}
            />
        </Modal>
    )
}

const ProductsList = props => {
    const {
        cx,
        setIsBottomBarVisible,
        multiselect: { checkedList, toggleCheckboxes, clearCheckboxes, setCheckboxes },
        overviewConfig: {
            singleName,
            selectedActions,
            orderItemHasExternalActions,
            disableServices = false,
            showBottomBar
        },
        refreshTax,
        isRefreshingTax
    } = props

    const history = useHistory()

    const orderData = useSelector(state => state.db.orders.current.data)

    const productsCount = (orderData.items || []).length
    const checkedCount = checkedList.length

    const [editedId, setEditedId] = useState(null)
    const [expandedIds, setExpandedIds] = useState([])
    const [isUpdating, setIsUpdating] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [isChooseProductModalOpen, setIsChooseProductModalOpen] = useState(false)
    const [updateErrors, setUpdateErrors] = useState({})
    const [modal, setModal] = useState(initialModalState)
    const [productFetchStatuses, setProductFetchStatuses] = useState({})
    const [creatingOrderType, setCreatingOrderType] = useState("")

    const actions = useActions({
        createOrder,
        updateOrder,
        createOrderItems,
        updateOrderItem,
        deleteOrderItem,
        getOrderItems,
        showSuccessNotification,
        showErrorNotification,
        getOrder,
        getOrderSuccess
    })

    const { fetchProducts } = useProductList()

    const warehouseList = useWarehouseList({ params: { is_default_for_po: true } })
    const defaultWarehouseForPO = useMemo(() => (warehouseList.data.length > 0 ? warehouseList.data[0] : null), [
        warehouseList.data
    ])

    const shouldShowBottomBar =
        typeof showBottomBar === "function"
            ? showBottomBar({ productsCount, checkedCount, orderStatus: orderData.status })
            : !!checkedCount

    useEffect(() => {
        fetchProducts()

        return () => clearCheckboxes()
    }, [])

    useEffect(() => {
        setIsBottomBarVisible(shouldShowBottomBar)
    }, [shouldShowBottomBar])

    useEffect(() => {
        setExpandedIds(expandedIds.filter(id => id !== editedId))
        setUpdateErrors({})
    }, [editedId])

    const toggleChooseProductModal = () =>
        setIsChooseProductModalOpen(wasOpen => {
            if (wasOpen && isModified) {
                actions.getOrder(orderData.id, true)
            }
            return !wasOpen
        })

    const returnShipmentList = useShipmentList({
        params: { type: SHIPMENT_TYPE_KEYS.CLIENT_RETURN, orders: orderData.id }
    })

    const updateOrderForCalculations = () => fetch.get(`/orders/${orderData.id}`).then(actions.getOrderSuccess)

    const chooseProduct = (
        { product_id, quantity, combination_id, note, discount, tax_code, stock_item_ids },
        onSuccess
    ) => {
        const body = {
            product_id,
            quantity,
            combination_id,
            order_id: orderData.id,
            note,
            discount,
            tax_code,
            stock_item_ids
        }
        handleProductFetchStatus(product_id, true)
        actions
            .createOrderItems(body)
            .then(() => {
                updateOrderForCalculations()
                actions.showSuccessNotification()
                onSuccess && onSuccess()
            })
            .catch(() => actions.showErrorNotification())
            .finally(() => handleProductFetchStatus(product_id, false))
    }

    const handleProductFetchStatus = (productId, value) => {
        setProductFetchStatuses(prevState => ({
            ...prevState,
            [productId]: {
                ...prevState[productId],
                isLoading: value
            }
        }))
    }

    const updateProduct = (orderItemId, payload) => {
        setIsUpdating(true)
        actions
            .updateOrderItem(orderItemId, payload)
            .then(() => {
                setEditedId(null)
                actions.getOrder(orderData.id, true)
                actions.showSuccessNotification()
            })
            .catch(error => {
                setUpdateErrors(error.errors)
                actions.showErrorNotification()
            })
            .finally(() => {
                setIsUpdating(false)
            })
    }

    const getCurrentItem = useCallback(itemId => orderData.items.find(item => item.id === itemId), [orderData.items])

    const isService = item => !!idx(item, _ => _.product.is_assistance)
    const isInStock = item => item.type === "in_stock"

    const convertItems = (value, type) => {
        const canCreateIfPO = item => (type === "purchase_order" ? !isService(item) && !isInStock(item) : true)

        const canCreate = item => !item[type] && canCreateIfPO(item)

        if (value === "multiselect") {
            return checkedList.reduce((acc, itemId) => {
                const currentItem = getCurrentItem(itemId)
                return canCreate(currentItem) ? [...acc, currentItem] : acc
            }, [])
        }
        if (value === "all") {
            return orderData.items.filter(canCreate)
        }
        return [value]
    }

    const createByForm = (type, items) => {
        history.push({
            pathname: `/orders/${type}s/add`,
            state: {
                order: { ...orderData, items }
            }
        })
    }

    const [createMultiplePurchaseOrders, { isLoading: isCreatingMultiplePO }] = useFetch({
        action: items =>
            fetch.post("/purchase-orders", {
                items: items.map(({ id }) => ({ id })),
                order_id: orderData.id,
                warehouse_id: defaultWarehouseForPO.id
            }),
        onSuccess: ({ created_purchase_orders }) => {
            history.push(`/orders/sales-orders/${orderData.id}/purchase-orders`)
            actions.getOrderItems(orderData.id)
            actions.showSuccessNotification({
                title: `${created_purchase_orders} new purchase orders have been created`
            })
        },
        onError: error => actions.showErrorNotification(error.message)
    })

    const [createOrderOfType, { isLoading: isCreatingOrder }] = useFetch({
        action: type => {
            if (!["sales", "rental"].includes(type)) {
                return Promise.reject()
            }
            const body = {
                ...toApiFormat(orderData, checkedList),
                type,
                parent_order_id: orderData.id
            }

            setCreatingOrderType(type)
            return actions.createOrder(body)
        },
        onSuccess: ({ data }, type) => {
            const route = getRouteByOrderType(type)
            history.push(`/orders/${route}/${data.id}`)
        },
        onError: () => actions.showErrorNotification()
    })

    const canCreate = useCallback(
        type => {
            const isNewOrder = orderData.status === "new"
            const orderItems = !!checkedList.length
                ? orderData.items.filter(oi => checkedList.includes(oi.id))
                : orderData.items
            const isElementOfType = oi => !!oi[type]

            switch (type) {
                case "purchase_order":
                    return (
                        !isNewOrder &&
                        defaultWarehouseForPO &&
                        orderData.status !== SALES_ORDER_STATUS_KEYS.COMPLETED &&
                        orderItems.some(oi => !isElementOfType(oi) && !isInStock(oi) && !isService(oi))
                    )

                case "invoice":
                    const hasInvoice = Array.isArray(orderData.invoices) && orderData.invoices.length
                    return (
                        !isNewOrder &&
                        (orderData.type === "rental" ? !hasInvoice : orderItems.some(oi => !isElementOfType(oi)))
                    )

                case "sales":
                    return !isNewOrder && ["memo", "rental"].includes(orderData.type) && !isCreatingOrder

                case "rental":
                    return !isNewOrder && orderData.type === "memo" && !isCreatingOrder

                default:
                    return false
            }
        },
        [orderData.items, orderData.status, orderData.type, checkedList, isCreatingOrder, defaultWarehouseForPO]
    )

    const itemsAvailableToDelete = useMemo(
        () =>
            orderData.items.filter(
                orderItem => checkedList.includes(orderItem.id) && checkIfCanDeleteOrderItem(orderItem)
            ),
        [orderData.items, checkedList]
    )

    const canDelete =
        checkIfCanCrudOrderItem({ orderType: orderData.type, orderStatus: orderData.status }) &&
        itemsAvailableToDelete.length > 0

    const createPO = value => {
        const convertedItems = convertItems(value, "purchase_order")
        const isOneBrand = unionBy(convertedItems, "brand.id").length === 1
        const goToPOForm = () =>
            history.push(
                createLinkToNewPurchaseOrder({
                    orderId: orderData.id,
                    itemIds: convertedItems.map(item => item.id)
                })
            )
        isOneBrand ? goToPOForm() : createMultiplePurchaseOrders(convertedItems)
    }

    const createInvoice = value => {
        const convertedItems = convertItems(value, "invoice")
        createByForm("invoice", convertedItems)
    }

    const toggleAllCheckBox = () => {
        const allProductsIds = orderData.items.map(item => item.id)
        !!checkedList.length ? clearCheckboxes() : setCheckboxes(allProductsIds)
    }

    const toggleDetails = id => setExpandedIds(xor(expandedIds, [id]))
    const toggleEdit = id => setEditedId(oldId => (id === oldId ? null : id))

    const actionHandlers = {
        refreshTax,
        addProduct: toggleChooseProductModal,
        delete: value => {
            if (!value) {
                return
            }

            const handleHideModal = () => setModal(initialModalState)
            const generateAction = f => () => {
                setModal(currentModal => ({ ...currentModal, isLoading: true }))

                f()
                    .then(() => {
                        clearCheckboxes()
                        updateOrderForCalculations()
                        actions.showSuccessNotification()
                    })
                    .catch(() => actions.showErrorNotification())
                    .finally(handleHideModal)
            }

            const isSingle = typeof value === "number" || (value === "multiselect" && checkedList.length === 1)
            const id = isSingle && value === "multiselect" ? checkedList[0] : value

            const [modalId, subject, handleDelete] = isSingle
                ? [
                      "deleteOne",
                      idx(orderData.items.find(oi => oi.id === id), _ => _.fake_name),
                      generateAction(() => actions.deleteOrderItem(id))
                  ]
                : [
                      "deleteMany",
                      `${itemsAvailableToDelete.length} selected products`,
                      generateAction(() =>
                          actions.updateOrder(orderData.id, {
                              items_delete: itemsAvailableToDelete.map(({ id }) => ({ id }))
                          })
                      )
                  ]

            setModal({
                modalId,
                subject,
                handleDelete,
                handleHideModal,
                isLoading: false
            })
        },
        createPO,
        createInvoice,
        createSO: () => createOrderOfType("sales"),
        createRental: () => createOrderOfType("rental"),
        toggleCheckboxes,
        toggleAllCheckBox,
        toggleDetails,
        toggleEdit,
        submitEdit: updateProduct
    }

    const { orderItems, onlyProductOrderItems } = useOrderItems(orderData)

    const handleAction = (type, ...args) => actionHandlers[type](...args)
    const barProps = {
        singleName,
        productsCount,
        checkedCount,
        handleAction,
        canCreate,
        canDelete,
        selectedActions,
        isCreating: {
            PO: isCreatingMultiplePO,
            rental: creatingOrderType === "rental" && isCreatingOrder,
            sales: creatingOrderType === "sales" && isCreatingOrder
        },
        shouldRenderReturnButton:
            orderData.type === "sales" &&
            orderData.status !== SALES_ORDER_STATUS_KEYS.NEW &&
            orderData.items.length > 0,
        returnButtonProps: {
            ...pick(orderData, ["id", "uuid"]),
            allOrderItems: onlyProductOrderItems,
            selectedOrderItemsIds: checkedList
        },
        buttonDisabled: !checkIfCanCrudOrderItem({ orderType: orderData.type, orderStatus: orderData.status })
    }

    return (
        <Fragment>
            <section className={cx("root")}>
                <TopBar
                    {...barProps}
                    productsCount={productsCount}
                    areAllChecked={checkedCount === productsCount}
                    isRefreshingTax={isRefreshingTax}
                />
                {!!productsCount ? (
                    <List
                        isUpdating={isUpdating}
                        editedId={editedId}
                        expandedIds={expandedIds}
                        order={orderData}
                        checkedList={checkedList}
                        handleAction={handleAction}
                        updateErrors={updateErrors}
                        canCreate={orderData.status !== "new"}
                        orderItemHasExternalActions={orderItemHasExternalActions}
                        orderItems={orderItems}
                        returnShipmentList={returnShipmentList}
                    />
                ) : (
                    <EmptyList icon={orderIcon} message="No products added" />
                )}
                {shouldShowBottomBar && <BottomBar {...barProps} />}
                <ChooseProductModal
                    areManualEntries
                    handleClose={toggleChooseProductModal}
                    handleSelect={chooseProduct}
                    fetchStatuses={productFetchStatuses}
                    useServices={true}
                    mainTitle={(disableServices && "New item") || ""}
                    setIsModified={setIsModified}
                    isOpen={isChooseProductModalOpen}
                    onClose={() => setIsChooseProductModalOpen(false)}
                />
            </section>
            <DeleteModal {...modal} />
        </Fragment>
    )
}

function toApiFormat(orderData, checkedList) {
    const allowedKeys = [
        "client",
        "project",
        "shipping_address",
        "billing_address",
        "issued_at",
        "revised_at",
        "valid_date",
        "instructions",
        "description",
        "deposit_unit",
        "deposit_value",
        "currency",
        "items"
    ]

    const filteredData = pick(orderData, allowedKeys)
    const validItem = checkedList.length === 0 ? item => !!item : item => checkedList.includes(item.id)
    return Object.entries(filteredData).reduce((acc, [key, value]) => {
        if (!value || (Array.isArray(value) && !value.length)) {
            return acc
        }

        if (["client", "project"].includes(key)) {
            return { ...acc, [`${key}_id`]: value.id }
        }

        if (key === "items") {
            return {
                ...acc,
                items: value.filter(validItem).map(toApiItem)
            }
        }

        return { ...acc, [key]: value }
    }, {})
}

function toApiItem(item) {
    const allowedItemKeys = ["product", "combination", "fake_name", "price", "quantity", "discount", "note"]
    const filteredItem = pick(item, allowedItemKeys)

    return Object.entries(filteredItem).reduce((item, [key, value]) => {
        if (!value || (Array.isArray(value) && !value.length)) {
            return item
        }

        if (["product", "combination"].includes(key)) {
            return { ...item, [`${key}_id`]: value.id }
        }
        return { ...item, [key]: value }
    }, {})
}

export default withMultiselectConsumer("products")(withStyles(ProductsList, styles))
