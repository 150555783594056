import React, { Component } from "react"
import debounce from "lodash.debounce"

import { AppContext } from "ui/FilterableTable"
import SearchBar from "components/Reusable/SearchBar"

class SearchBarConsumer extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {context => <SearchBarContainer {...this.props} context={context} />}
            </AppContext.Consumer>
        )
    }
}

class SearchBarContainer extends Component {
    static defaultProps = {
        placeholder: ""
    }

    state = {
        search: null
    }

    constructor(props) {
        super(props)
        this.emitChangeDebounced = debounce(this.emitChange, 350)
    }

    componentDidMount() {
        this.setState({
            search: this.props.search || this.props.context.data.search
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.context.data.search !== this.props.context.data.search) {
            this.setState({
                search: this.props.context.data.search
            })
        }
    }

    componentWillUnmount() {
        this.emitChangeDebounced.cancel()
    }

    render() {
        const { context, placeholder } = this.props

        if (!context.init) {
            return null
        }

        return (
            <SearchBar
                filter={this.state.search}
                placeholder={placeholder}
                handleSearch={value => {
                    this.setState({ search: value.query }, () => this.handleChange(value.query))
                }}
                clearInput={() => {
                    this.setState({ search: "" }, () => this.handleChange(""))
                }}
            />
        )
    }

    handleChange = query => {
        this.emitChangeDebounced(query)
    }

    emitChange(value) {
        const { context } = this.props
        context.onSearch(value)
    }
}

export default SearchBarConsumer
