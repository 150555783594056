import React from "react"
import PropTypes from "prop-types"

import styles from "./ProductHeader.module.css"

const ProductHeader = ({ product, children }) => {
    const { brand } = product

    return (
        <div className={styles.header}>
            {brand && brand.logo && (
                <div className={styles.brand}>
                    <img src={brand.logo} alt={brand.name} />
                </div>
            )}
            <div className={styles.productName}>{product.name}</div>
            {children}
        </div>
    )
}

ProductHeader.propTypes = {
    product: PropTypes.shape({
        brand: PropTypes.shape({
            logo: PropTypes.string,
            name: PropTypes.string
        }),
        name: PropTypes.string
    }),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default ProductHeader
