import React, { Fragment, useCallback } from "react"
import PropTypes from "prop-types"
import _get from "lodash/get"

import { DateRangePicker, SelectWithSearch, Toggle } from "ui"
import { AVAILABILITY_STATUSES, INITIAL_DATE_RANGE } from "ui/ChooseProduct/constants"
import {
    mapItemsToSelectOptions,
    mapItemsWithPositiveCounterToSelectOptions,
    getFilterData
} from "ui/ChooseProduct/helpers"
import { useDateRangePicker } from "ui/ChooseProduct/hooks"

import dateRangePickerStyles from "./overrides/DateRangePicker.css"
import selectWithSearchStyles from "./overrides/SelectWithSearch.css"

import styles from "./Filters.module.css"

const Filters = ({
    brands,
    categories,
    locations,
    selectedFilterValues,
    setSelectedFilter,
    areManualEntriesEnabled = true,
    lockedFilters,
    disabled
}) => {
    const { brand, category, subCategory, inStock, location, manualEntries, dateRange } = selectedFilterValues
    const subCategories = !!category ? category.children : []

    const setDateRange = useCallback(dateRange => setSelectedFilter("dateRange", dateRange), [setSelectedFilter])

    const {
        isDatepickerOpen,
        isDatepickerOpenRef,
        setIsDatepickerOpen,
        handleChangeFilterDateRange,
        handleResetFilterDateRange
    } = useDateRangePicker({ setDateRange, dateRange })

    return (
        <Fragment>
            <div className={styles.searchFilterContainer}>
                <div>
                    <SelectWithSearch
                        placeholder="All brands"
                        value={_get(brand, "name", "")}
                        values={mapItemsWithPositiveCounterToSelectOptions(brands)}
                        setValue={({ value }) =>
                            setSelectedFilter("brand", { id: value, ...getFilterData(brands, value, { name: "" }) })
                        }
                        handleClear={() => {
                            setSelectedFilter("brand", null)
                        }}
                        customStyles={selectWithSearchStyles}
                        isDisabled={disabled || (lockedFilters && !!lockedFilters.brand)}
                        withoutFetch
                    />
                </div>
                <div>
                    <SelectWithSearch
                        placeholder="All categories"
                        value={_get(category, "name", "")}
                        values={mapItemsWithPositiveCounterToSelectOptions(categories)}
                        setValue={({ value }) =>
                            setSelectedFilter("category", {
                                id: value,
                                ...getFilterData(categories, value, { name: "", children: [] })
                            })
                        }
                        handleClear={() => {
                            setSelectedFilter("category", null)
                            setSelectedFilter("subCategory", null)
                        }}
                        customStyles={selectWithSearchStyles}
                        isDisabled={disabled}
                        withoutFetch
                    />
                </div>
                <div>
                    <SelectWithSearch
                        placeholder="Sub categories"
                        isDisabled={((!subCategory || !subCategory.id) && !subCategories.length) || disabled}
                        value={_get(subCategory, "name", "")}
                        values={mapItemsWithPositiveCounterToSelectOptions(subCategories)}
                        setValue={({ value }) =>
                            setSelectedFilter("subCategory", {
                                id: value,
                                ...getFilterData(subCategories, value, { name: "" })
                            })
                        }
                        handleClear={() => {
                            setSelectedFilter("subCategory", null)
                        }}
                        customStyles={selectWithSearchStyles}
                        withoutFetch
                    />
                </div>
            </div>
            <div className={styles.searchFilterContainer}>
                <div>
                    <SelectWithSearch
                        placeholder="All locations"
                        value={_get(location, "name", "")}
                        values={mapItemsToSelectOptions(locations)}
                        setValue={({ value }) =>
                            setSelectedFilter("location", {
                                id: value,
                                ...getFilterData(locations, value, { name: "" })
                            })
                        }
                        handleClear={() => {
                            setSelectedFilter("location", null)
                        }}
                        customStyles={selectWithSearchStyles}
                        isDisabled={disabled}
                        withoutFetch
                    />
                </div>
                <div>
                    <SelectWithSearch
                        placeholder="Stock"
                        value={_get(inStock, "label", "")}
                        values={AVAILABILITY_STATUSES}
                        setValue={option => setSelectedFilter("inStock", option)}
                        handleClear={() => {
                            setSelectedFilter("inStock", null)
                        }}
                        customStyles={selectWithSearchStyles}
                        isDisabled={disabled}
                        withoutFetch
                    />
                </div>
                <div>
                    <DateRangePicker
                        title="Any date"
                        customStyles={dateRangePickerStyles}
                        ranges={{ ...(dateRange || INITIAL_DATE_RANGE), key: "range" }}
                        onChange={handleChangeFilterDateRange}
                        resetRanges={handleResetFilterDateRange}
                        shownDate={new Date()}
                        isDatapickerOpen={isDatepickerOpen}
                        isDatapickerOpenRef={isDatepickerOpenRef}
                        setIsDatapickerOpen={setIsDatepickerOpen}
                        disabled={disabled}
                    />
                </div>
            </div>
            {areManualEntriesEnabled && (
                <div className={styles.searchFilterContainer}>
                    <div>
                        <Toggle
                            label={{ on: "Manual entries", off: "Manual entries" }}
                            isOn={manualEntries}
                            handleChange={value => setSelectedFilter("manualEntries", value)}
                            isDisabled={disabled}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    )
}

const brandPropType = PropTypes.shape({
    count: PropTypes.number,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    sale: PropTypes.bool,
    selected: PropTypes.bool
})

const categoryPropShape = {
    count: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string.isRequired,
    sale: PropTypes.bool
}

categoryPropShape.children = PropTypes.arrayOf(PropTypes.shape(categoryPropShape))

const categoryPropType = PropTypes.shape(categoryPropShape)

const locationPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
})

const selectOptionPropType = PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
})

Filters.propTypes = {
    brands: PropTypes.arrayOf(brandPropType).isRequired,
    categories: PropTypes.arrayOf(categoryPropType).isRequired,
    locations: PropTypes.arrayOf(locationPropType).isRequired,
    lockedFilters: PropTypes.shape({
        brand: selectOptionPropType
    }),
    setSelectedFilter: PropTypes.func.isRequired,
    selectedFilterValues: PropTypes.shape({
        brand: selectOptionPropType,
        category: categoryPropType,
        subCategory: selectOptionPropType,
        inStock: PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        }),
        location: selectOptionPropType,
        manualEntries: PropTypes.bool,
        dateRange: PropTypes.shape({
            startDate: PropTypes.string.isRequired,
            endDate: PropTypes.string.isRequired
        })
    }).isRequired,
    areManualEntriesEnabled: PropTypes.bool,
    disabled: PropTypes.bool
}

export default Filters
