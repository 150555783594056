import React, { Fragment } from "react"
import PropTypes from "prop-types"

import withStyles from "HOC/withStyles"
import styles from "./styles.css"

const Financials = props => {
    const { cx, brand } = props

    return (
        <Fragment>
            <div className={cx("root")}>
                <div className={cx("headerWrapper")}>
                    <h2 className={cx("header")}>Supplier Details</h2>
                </div>

                {Array.isArray(brand.discounts) && brand.discounts.length > 0 && (
                    <Fragment>
                        <h2 className={cx("header-smaller")}>Discounts</h2>
                        <div className={cx("discounts")}>
                            <div className={cx("discountRow", "discountRowHeader")}>
                                <div className={cx("discountName")}>Discount name</div>
                                <div className={cx("discountValue")}>Value</div>
                                <div className={cx("discountDescription")}>Description</div>
                            </div>

                            {brand.discounts.map(discount => (
                                <div className={cx("discountRow", "discountRowData")}>
                                    <div className={cx("discountName")}>{discount.name}</div>
                                    <div className={cx("discountValue")}>{discount.value}</div>
                                    <div className={cx("discountDescription")}>{discount.description}</div>
                                </div>
                            ))}
                        </div>
                    </Fragment>
                )}

                <h2 className={cx("header-smaller")}>Pricing</h2>

                <div className={cx("pricing")}>
                    <ul>
                        <li>
                            <h3 className={cx("label")}>Mark-Up to USD retail:</h3>
                            <p className={cx("value")}>{brand.pricing_mu_usd_retail || "-"}</p>
                        </li>
                        <li>
                            <h3 className={cx("label")}>Mark-Up Memo:</h3>
                            <p className={cx("value")}>{brand.pricing_mu_memo || "-"}</p>
                        </li>
                        <li>
                            <h3 className={cx("label")}>Price Structure:</h3>
                            <p className={cx("value")}>
                                {brand.pricing_structure_id ? brand.pricing_structure_id.name : "-"}
                            </p>
                        </li>
                        <li>
                            <h3 className={cx("label")}>Exchange rate:</h3>
                            <p className={cx("value")}>{brand.pricing_exchange_rate || "-"}</p>
                        </li>
                    </ul>

                    <div className={cx("pricingNote")}>
                        <h3 className={cx("label")}>Pricing note:</h3>
                        <p className={cx("value")}>{brand.pricing_note || "-"}</p>
                    </div>
                </div>

                {!!brand.payment_terms && (
                    <Fragment>
                        <h2 className={cx("header-smaller")}>Payment terms</h2>
                        <p className={cx("box")}>{brand.payment_terms}</p>
                    </Fragment>
                )}

                {!!brand.bank_information && (
                    <Fragment>
                        <h2 className={cx("header-smaller")}>Bank information</h2>
                        <p className={cx("box")}>{brand.bank_information}</p>
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

Financials.propTypes = {
    brand: PropTypes.object.isRequired,
    cx: PropTypes.func.isRequired
}

export default withStyles(Financials, styles)
