export default {
    API: {
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        webUrl: process.env.REACT_APP_WEB_URL,
        authorizationHeaders: {
            "X-App-Token": process.env.REACT_APP_API_APP_TOKEN,
            "X-Authorization": ""
        }
    },
    LOGIN_SESSION_TIMEOUT: process.env.REACT_APP_LOGIN_SESSION_TIMEOUT || 479, //in minutes
    NUMBER_OF_SLIDES: process.env.NUMBER_OF_SLIDES || 10,
    NUMBER_OF_PRODUCTS: process.env.NUMBER_OF_PRODUCTS || 24,
    NUMBER_OF_COLLECTIONS: process.env.NUMBER_OF_COLLECTIONS || 3,
    SENTRY_DSN_URL: process.env.REACT_APP_SENTRY_DSN_URL || "",
    ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT || "",
    REACT_APP_LUCKYORANGE_SITE_ID: process.env.REACT_APP_LUCKYORANGE_SITE_ID || ""
}
