import React, { Component } from "react"
import withStyles from "HOC/withStyles"

import styles from "./ProductForm.css"
import { Button, SelectWithSearch } from "ui"
import selectErrorStyles from "./overrides/selectError.css"
import Input from "components/Reusable/Form/Input/index"
import Textarea from "components/Reusable/Form/Textarea/index"
import ImagesSection from "../ImagesSection"

class ProductForm extends Component {
    render() {
        const {
            cx,
            currentProductId,
            config,
            fields,
            fieldsErrors,
            brands,
            isSending,
            activeTake,
            handleTake,
            handleDeleteImage,
            handleChangeField,
            handleCreateProduct
        } = this.props

        const currentBrand = brands.find(el => el.id === fields.brand)

        return (
            <div className={cx("root")}>
                <ImagesSection
                    fields={fields}
                    activeTake={activeTake}
                    handleTake={handleTake}
                    handleDeleteImage={handleDeleteImage}
                />

                {config &&
                    config.map((field, index) => {
                        const { name, label, takeType, params, type } = field

                        if (!["textarea", "text"].includes(type)) {
                            return null
                        }

                        const Component = type === "text" ? Input : Textarea

                        const optionalParams = params ? params : {}

                        return (
                            <div className={cx("section")} key={index}>
                                <Component
                                    name={name}
                                    label={label}
                                    onChange={({ event }) => {
                                        handleChangeField({ name, value: event.target.value })
                                    }}
                                    value={fields[name] ? fields[name] : ""}
                                    error={fieldsErrors[name] ? fieldsErrors[name] : null}
                                    {...optionalParams}
                                />
                                <Button
                                    className={cx("takeButton", { isActive: activeTake === name })}
                                    onClick={() => {
                                        handleTake({ type: takeType, field: name })
                                    }}
                                    label=""
                                />
                            </div>
                        )
                    })}
                <div className={cx("section", "select")}>
                    <SelectWithSearch
                        label="Brand *"
                        values={brands.map(element => ({ value: element.id, label: element.name }))}
                        value={currentBrand ? currentBrand.name : ""}
                        error={fieldsErrors.brand_id ? fieldsErrors.brand_id : null}
                        setValue={({ value }) => handleChangeField({ name: "brand", value: value })}
                        customErrorStyles={selectErrorStyles}
                        placeholder="-"
                        withoutFetch
                    />
                </div>

                <Button
                    isLoading={isSending}
                    className={cx("first-button", "cta")}
                    onClick={() => {
                        handleCreateProduct()
                    }}
                    label={currentProductId ? "Save Product" : "Create Product"}
                />
            </div>
        )
    }
}

export default withStyles(ProductForm, styles)
