import REFUND_RATE_KEYS from "./refundRateKeys"

const REFUND_RATE_OPTIONS = [
    {
        id: REFUND_RATE_KEYS.FULL_REFUND,
        label: "Full refund (100%)",
        value: REFUND_RATE_KEYS.FULL_REFUND
    },
    {
        id: REFUND_RATE_KEYS.RESTOCKING_FEE_60,
        label: "Restocking fee applies (60%)",
        value: REFUND_RATE_KEYS.RESTOCKING_FEE_60
    }
]

export default REFUND_RATE_OPTIONS
