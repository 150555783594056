import React, { Component } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import { connect } from "react-redux"
import { PERMISSIONS } from "constants/index"

import withPermissions from "HOC/withPermissions"

import { Products, Clients } from "../pages"

class Routes extends Component {
    componentDidMount() {
        const { checkGlobalPermission } = this.props

        if (!checkGlobalPermission(PERMISSIONS.context.UPLOAD)) {
            document.location.href = "/dashboard"
        }
    }

    render() {
        const { isSidebarMenuWide } = this.props
        return (
            <Switch>
                <Redirect from="/upload" exact to="/upload/products" />
                <Route
                    path="/upload/products"
                    render={routeProps => <Products {...routeProps} sidebarMenuIsWide={isSidebarMenuWide} />}
                />
                <Route
                    path="/upload/contact"
                    render={routeProps => <Clients {...routeProps} sidebarMenuIsWide={isSidebarMenuWide} />}
                />
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        isSidebarMenuWide: state.menuStore.isSidebarMenuWide
    }
}

export default connect(
    mapStateToProps,
    null
)(withPermissions(Routes))
