import React, { Component, Fragment } from "react"
import { DropTarget } from "react-dnd"
import { connect } from "react-redux"
import { DRAGDROP_ACTION_TRANSFER_IMAGE_PRODUCT, LOAD_THUMBNAIL_IMAGE } from "constants/index"
import { getCurrentFolderImages } from "actions/uploadModule"
import Checkbox from "components/Reusable/Form/Checkbox"
import moment from "moment"
import { Table } from "ui/FilterableTable"
import withStyles from "HOC/withStyles"
import styles from "./Product.css"
import { transferImages } from "actions/product"
import { TransferConfirmModal } from "modules/UploadModule/components"
import arrow from "assets/arrow.svg"
import image from "assets/image.svg"

class Product extends Component {
    state = {
        isTransferModalVisible: false
    }

    sendImagesToProduct = image => {
        const {
            id,
            currentFolderId,
            currentlySelectedImages,
            showErrorNotification,
            triggerImageSelect,
            changeNumberOfImagesInProduct,
            saveTransferedImagesLocally
        } = this.props

        const payload =
            currentlySelectedImages.length > 0
                ? currentlySelectedImages.map(image => {
                      return { ...image, productId: id }
                  })
                : [{ id: image.id, url: image.url, productId: id, name: image.name }]

        saveTransferedImagesLocally(payload)
        triggerImageSelect([])
        changeNumberOfImagesInProduct({
            productId: id,
            nextValue: payload.length
        })

        return transferImages(id, currentFolderId, payload.map(image => image.id)).catch(error => {
            showErrorNotification(error && error.message ? error.message : "Error with transfering images")
            changeNumberOfImagesInProduct({
                productId: id,
                nextValue: -payload.length
            })
        })
    }

    handleAcceptTransferConfirmModal = () => {
        const {
            currentFolderId,
            transferFromProduct,
            changeNumberOfImagesInProduct,
            showErrorNotification,
            getCurrentFolderImages
        } = this.props
        const { productId, imageId } = transferFromProduct
        this.setState({ isTransferModalVisible: false }, () => {
            changeNumberOfImagesInProduct({ productId, nextValue: -1, transferFromProduct })
        })

        return transferImages(productId, currentFolderId, [imageId], false)
            .then(() => {
                getCurrentFolderImages(currentFolderId)
            })
            .catch(error => {
                showErrorNotification(error && error.message ? error.message : "Error with transfering images")
                changeNumberOfImagesInProduct({ productId, nextValue: 1 })
            })
    }

    componentDidUpdate(prevProps) {
        const { isOver } = prevProps
        const { nextImage, didDrop, handleImagePanel } = this.props
        if (isOver) {
            handleImagePanel(null)
        }
        if (isOver && didDrop && nextImage) {
            this.sendImagesToProduct(nextImage)
        }
    }

    render() {
        const {
            cx,
            connectDropTarget,
            transferFromProduct,
            isOver,
            images_count,
            id,
            name,
            brand,
            main_category,
            created_at,
            isImagePanelShown
        } = this.props
        const { isTransferModalVisible } = this.state

        return connectDropTarget(
            <tr key={id} className={cx("row", { isOver: isOver, isImagePanelShown: isImagePanelShown })}>
                <Table.Td>
                    <a target="_blank" rel="noopener noreferrer" href={"/products/" + id}>
                        {name}
                    </a>
                </Table.Td>
                <Table.Td>{main_category}</Table.Td>
                <Table.Td>{brand}</Table.Td>
                <Table.Td>{moment(created_at).format("YYYY-MM-DD")}</Table.Td>
                <Table.Td className={cx({ "no-image": images_count === 0 })}>
                    <span onClick={this.handleImagePanel}>
                        <img src={image} alt="show photos" className={cx("toggleImagePanelIcon")} />
                        <span>{images_count}</span>
                        {images_count > 0 && <img src={arrow} alt="Toggle panel" className={cx("toggleImagePanel")} />}
                    </span>
                    {!isOver && isImagePanelShown && this.renderImagePanel()}
                    {isTransferModalVisible && (
                        <TransferConfirmModal
                            handleHide={() => this.setState({ isTransferModalVisible: false })}
                            handleAccept={this.handleAcceptTransferConfirmModal}
                            handleImagePanel={this.handleImagePanel}
                            imageUrl={transferFromProduct.url}
                        />
                    )}
                </Table.Td>
            </tr>
        )
    }

    handleImagePanel = () => {
        const { id, isImagePanelShown, handleImagePanel } = this.props
        if (isImagePanelShown) {
            return handleImagePanel(null)
        }

        return handleImagePanel(id)
    }

    renderImagePanel() {
        const { cx, media, transferedImages, id: productId } = this.props
        const images = [...transferedImages.filter(image => image.productId === productId), ...media]

        if (images.length > 0) {
            return (
                <section className={cx("imagePanel")}>
                    <ul>
                        {images.map((item, index) => (
                            <li key={index}>
                                <Image
                                    id={item.id}
                                    url={item.url}
                                    name={item.name}
                                    handleClick={this.handleImageClick}
                                    alt={item.title ? item.title : ""}
                                />
                            </li>
                        ))}
                    </ul>
                </section>
            )
        }

        return null
    }

    handleImageClick = ({ url, imageId, name }) => {
        const { id, prepareImageToSendFromProduct } = this.props
        this.setState({ isTransferModalVisible: true })
        return prepareImageToSendFromProduct({ productId: id, imageId, url, name })
    }
}

function Image({ id, url = "", alt = "", name = "", handleClick = () => {} }) {
    return (
        <Fragment>
            <img src={url + LOAD_THUMBNAIL_IMAGE} alt={alt} />
            <Checkbox id={`photo${id}`} onClick={() => handleClick({ imageId: id, url, name })} checked={true} />
        </Fragment>
    )
}

function collect(connect, monitor) {
    return {
        isOver: monitor.isOver(),
        didDrop: monitor.didDrop(),
        nextImage: monitor.getItem(),
        connectDropTarget: connect.dropTarget()
    }
}

const mapDispatchToProps = dispatch => ({
    getCurrentFolderImages: id => dispatch(getCurrentFolderImages(id))
})

export default DropTarget(DRAGDROP_ACTION_TRANSFER_IMAGE_PRODUCT, {}, collect)(
    withStyles(
        connect(
            null,
            mapDispatchToProps
        )(Product),
        styles
    )
)
