import React, { useState, useEffect, useMemo, useCallback } from "react"
import withStyles from "HOC/withStyles"

import fetch from "helpers/fetch"

import { LENGTH_WITHOUT_PAGINATION } from "constants/index"

import addIcon from "assets/add.svg"

import styles from "./styles.css"
import { displayUserName } from "helpers/user"

import { SelectWithSearch } from "ui"
import { useField } from "formik"

const CommissionTeam = props => {
    const { isListOnTop, fetchPath, cx, name } = props

    const [field] = useField(name)
    const [dependentField] = useField("sales_associate")

    const [list, setList] = useState([])
    const [isAddNextSelectVisible, setIsAddNextSelectVisible] = useState(false)

    useEffect(() => {
        fetch
            .get(`${fetchPath}?&length=${LENGTH_WITHOUT_PAGINATION}`)
            .then(response =>
                setList((response.data || []).map(value => ({ id: value.id, label: displayUserName(value) })))
            )
    }, [])

    function showAddNextSelect() {
        setIsAddNextSelectVisible(true)
    }

    const selectedUsers = useMemo(() => (Array.isArray(field.value) ? field.value : []), [field.value])

    const setValue = useCallback(
        (key, newValue) => {
            const onChange = value => field.onChange({ target: { name, value } })

            const isDelete = !newValue.id
            const isEdit = !!selectedUsers[key]

            const deleteValue = isDelete && selectedUsers.filter((_, index) => key !== index)
            const editValue = isEdit && selectedUsers.map((user, index) => (index === key ? newValue : user))
            const addValue = [...selectedUsers, newValue]

            const value = deleteValue || editValue || addValue

            onChange(value)
            !isDelete && !isEdit && setIsAddNextSelectVisible(false)
        },
        [selectedUsers]
    )

    const values = useMemo(
        () =>
            list.filter(({ id }) => {
                const isSalesAssociate = id === (dependentField.value && dependentField.value.id)
                const isOneOfSelectedUsers = selectedUsers.some(selected => selected.id === id)
                return !isSalesAssociate && !isOneOfSelectedUsers
            }),
        [list, dependentField.value, selectedUsers]
    )

    const visibleSelects = useMemo(() => {
        return isAddNextSelectVisible || !selectedUsers.length ? [...selectedUsers, {}] : selectedUsers
    }, [isAddNextSelectVisible, selectedUsers])

    const isAddButtonDisabled = useMemo(
        () => isAddNextSelectVisible || !selectedUsers.length || selectedUsers.some(({ id }) => !id),
        [isAddNextSelectVisible, selectedUsers]
    )

    return (
        <div className={cx("root")}>
            <label>Commission team:</label>
            {visibleSelects.map((user, key) => (
                <div key={key} className={cx("dropdown")}>
                    <SelectWithSearch
                        values={values}
                        value={user.label}
                        setValue={value => setValue(key, value)}
                        isListOnTop={isListOnTop}
                        placeholder="- Please select -"
                        withoutFetch
                    />
                </div>
            ))}
            <div className={cx("add-more", { disabled: isAddButtonDisabled })} onClick={showAddNextSelect}>
                <img src={addIcon} alt="addImage" />
                <span>Add member</span>
            </div>
        </div>
    )
}

export default withStyles(CommissionTeam, styles)
