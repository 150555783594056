import React from "react"
import PropTypes from "prop-types"
import { useField } from "formik"

import withStyles from "HOC/withStyles"
import { Toggle, Skeleton } from "ui"
import Section from "ui/AddEditForm/Section"
import { CopyAddress, AddressForm } from "./components"

import { fetchStatusPropTypes, successState } from "helpers/fetchStatus"

import styles from "./Address.css"
import toggleStyles from "./overrides/toggle.css"

const Address = props => {
    const { cx, onlyForm, label, fetchStatus, copyFrom, copyAddress, config, disabledMessage, isDisabled } = props
    const { addressName } = config

    const [copy] = useField(`${addressName}.copy`)

    if (!!disabledMessage) {
        return <Section label={label} Action={<p className={cx("disabledMessage")}>{disabledMessage}</p>} />
    }

    return (
        <Section
            headerClassName={cx("header")}
            label={label}
            Action={
                !onlyForm && (
                    <Toggle
                        isOn={copy.value}
                        label={{
                            on: `Same as ${copyFrom}’s ${label}`,
                            off: `Not same as ${copyFrom}’s ${label}`
                        }}
                        labelPosition="left"
                        handleChange={value => copy.onChange({ target: { ...copy, value } })}
                        customStyles={toggleStyles}
                        isDisabled={isDisabled}
                    />
                )
            }
        >
            <Skeleton fetchStatus={fetchStatus}>
                {!copy.value || onlyForm ? (
                    <AddressForm cx={cx} config={config} isDisabled={isDisabled} />
                ) : (
                    <CopyAddress cx={cx} displayFormatConfig={config.displayFormat} copyAddress={copyAddress} />
                )}
            </Skeleton>
        </Section>
    )
}

Address.defaultProps = {
    fetchStatus: successState()
}

Address.propTypes = {
    onlyForm: PropTypes.bool,
    label: PropTypes.string.isRequired,
    fetchStatus: fetchStatusPropTypes,
    copyFrom: PropTypes.string.isRequired,
    copyAddress: PropTypes.object,
    config: PropTypes.shape({
        addressName: PropTypes.string.isRequired,
        formFields: PropTypes.objectOf(
            PropTypes.shape({
                componentType: PropTypes.oneOf(["input", "select", "state"]),
                selectValuesKey: (props, propName, componentName) => {
                    const possibleValuesKeys = ["countries", "states"]
                    const isKeyCorrect = possibleValuesKeys.includes(props.selectValuesKey)

                    if (props.componentType === "select" && !isKeyCorrect) {
                        return new Error(
                            `Invalid prop '${propName}' supplied to '${componentName}'. Require one of: ${possibleValuesKeys.join(
                                ", "
                            )}.`
                        )
                    }
                },
                width: PropTypes.string,
                label: PropTypes.string
            }).isRequired
        ).isRequired,
        displayFormat: PropTypes.shape({
            strong: PropTypes.array,
            address: PropTypes.array
        }).isRequired
    }).isRequired,
    disabledMessage: PropTypes.string,
    isDisabled: PropTypes.bool
}

export default withStyles(Address, styles)
