import SHIPMENT_STATUSES from "./shipmentStatuses"
import SHIPMENT_STATUS_KEYS from "./shipmentStatusKeys"

const STATUS_SELECT_OPTIONS = [
    SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.ON_HOLD],
    SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.IN_PROGRESS],
    SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.SENT],
    SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.PACKED],
    SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.DELIVERED],
    SHIPMENT_STATUSES[SHIPMENT_STATUS_KEYS.CANCELLED]
]

export default STATUS_SELECT_OPTIONS
