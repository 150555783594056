import React, { Component } from "react"
import ReactSVG from "react-svg"
import withStyles from "HOC/withStyles"
import styles from "./EmptyList.css"

class EmptyList extends Component {
    render() {
        const { cx, message, icon } = this.props

        return (
            <div className={cx("root")}>
                <ReactSVG src={icon} className={cx("image")} />
                <span className={cx("message")}>{message}</span>
            </div>
        )
    }
}

export default withStyles(EmptyList, styles)
