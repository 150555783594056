export default fileSize => {
    const bytesInKilobytes = 1024
    const units = [" B", " kB", " MB", " GB", " TB"]

    if (typeof fileSize !== "number") {
        return `0${units[0]}`
    }

    const formattedSize = units.reduce(
        (acc, curr, index) => {
            const nextUnitValue = acc[0] / bytesInKilobytes
            return nextUnitValue > 1 ? [nextUnitValue, units[index + 1]] : acc
        },
        [fileSize, units[0]]
    )

    const fixedRoundedSize = formattedSize[0].toFixed(1)
    const splittedRoundedSize = fixedRoundedSize.split(".")
    const roundedSize = splittedRoundedSize[1] === "0" ? splittedRoundedSize[0] : fixedRoundedSize

    return roundedSize + formattedSize[1]
}
