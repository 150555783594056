import { combineReducers } from "redux"

import availableFilters from "./availableFilters"
import header from "./header"
import modals from "./modals"
import multiselect from "./multiselect"
import newsModule from "./newsModule"
import notification from "./notification"
import products from "./products/index"
import projects from "./projects"
import searchBar from "./searchBar"
import session from "./session"
import uploadModule from "./uploadModule/index"
import { reducer as combinationsCreator } from "./combinationsCreator"

const uiReducer = combineReducers({
    availableFilters,
    header,
    modals,
    multiselect,
    notification,
    products,
    projects,
    searchBar,
    session,
    uploadModule,
    newsModule,
    combinationsCreator
})

export default uiReducer
