import React from "react"
import PropTypes from "prop-types"
import ReactSVG from "react-svg"
import { Link } from "react-router-dom"

import useStyles from "helpers/getCxFromStyles"
import styles from "./ArrowBackButton.css"
import arrowIcon from "assets/arrow-blue.svg"

const ArrowBackButton = props => {
    const { url, label } = props
    const cx = useStyles(styles)

    return (
        <Link to={url} className={cx("root")}>
            <ReactSVG src={arrowIcon} className={cx("arrowIcon")} />
            <span className={cx("label")}>{label}</span>
        </Link>
    )
}

ArrowBackButton.propTypes = {
    url: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
}

export default ArrowBackButton
