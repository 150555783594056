import * as constants from "constants/index"
import * as fetchStatus from "helpers/fetchStatus"

const initialState = {
    data: [],
    meta: {},
    isLoading: false,
    isLoaded: false,
    isError: false,
    nextFetchStatus: fetchStatus.initialState()
}

function list(state = {}, action) {
    switch (action.type) {
        case constants.GET_PORTFOLIO_START:
            return { ...initialState, isLoading: true }
        case constants.GET_PORTFOLIO_SUCCESS:
            return { ...initialState, meta: action.payload.data.meta, data: action.payload.data.data, isLoaded: true }
        case constants.GET_PORTFOLIO_ERROR:
            return { ...initialState, isError: true }

        default:
            return state
    }
}

export default list
