import * as constants from "constants/index"

const INITIAL_STATE = 1

function pagination(state = INITIAL_STATE, action) {
    const current_page = action.payload ? action.payload.page : INITIAL_STATE

    switch (action.type) {
        case constants.CLEAR_PRODUCT_PAGINATION:
            return INITIAL_STATE
        case constants.CHANGE_PRODUCT_PAGINATION:
            return current_page || INITIAL_STATE

        default:
            return state
    }
}

export default pagination
