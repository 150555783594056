import React, { Component } from "react"
import styles from "./Button.css"
import withStyles from "HOC/withStyles"

class Button extends Component {
    render() {
        const { className, type, icon, label, isLoading, onClick, cx, tabIndex, isDisabled, styles = {} } = this.props

        const tabIndexProp = tabIndex ? { tabIndex } : {}
        const typeProp = type ? { type } : {}

        return (
            <button
                {...tabIndexProp}
                {...typeProp}
                style={styles}
                className={cx("root", className, { isLoading, isDisabled, isIcon: icon })}
                onClick={event => !isLoading && !isDisabled && onClick && onClick(event)}
            >
                {icon && <img src={icon} alt="" className={cx("icon")} />}
                {label}
            </button>
        )
    }
}

export default withStyles(Button, styles)
