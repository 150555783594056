import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"

import withStyles from "HOC/withStyles"
import style from "./Product.css"
import close from "assets/close.svg"
import placeholderImage from "./placeholder.svg"
import { numberToPrice } from "helpers/products"

class Product extends Component {
    render() {
        const {
            cx,
            id,
            brand,
            name,
            isSmallCard,
            hideModal,
            handleRemove,
            isLoading,
            isResponsive,
            hasProjects,
            handleProjectsModalToggle,
            isRelated,
            lowest_price,
            has_combinations
        } = this.props

        const nameMaxLength = this.props.nameMaxLength ? this.props.nameMaxLength : 40

        return (
            <Fragment>
                <li
                    className={cx("product-li", { isSmallCard, isLoading, isResponsive })}
                    onClick={event => {
                        hideModal && hideModal(event)
                    }}
                >
                    {!isLoading && !isRelated && (
                        <div
                            className={cx("flag", { isChosen: hasProjects })}
                            onClick={() => handleProjectsModalToggle(id)}
                        >
                            <span>{hasProjects ? "Added" : "Add to Projects"}</span>
                        </div>
                    )}
                    {handleRemove && (
                        <img
                            onClick={event => {
                                event.preventDefault()
                                handleRemove(id)
                            }}
                            className={cx("remove")}
                            src={close}
                            alt="remove"
                        />
                    )}
                    <Link to={`/products/${id}`}>
                        <div className={cx("photo-cover")}>
                            <span>{!isLoading && this.renderImage()}</span>
                        </div>
                        <h4 className={cx("brand")}>{brand && brand.name ? brand.name : null}</h4>
                        <p className={cx("description")}>
                            {this.shortenString(name, nameMaxLength)}
                            {name && name.length > nameMaxLength
                                ? this.renderSpanWithShy({ text: name, props: { className: "tooltip" } })
                                : null}
                        </p>

                        <p className={cx("price")}>
                            {lowest_price && (
                                <Fragment>
                                    {has_combinations && "from"}{" "}
                                    <strong>$&nbsp;&nbsp;{numberToPrice(lowest_price)}</strong>
                                </Fragment>
                            )}
                        </p>
                    </Link>
                </li>
            </Fragment>
        )
    }

    renderImage() {
        const { cx, media } = this.props

        return (
            <img
                src={media && media.url ? `${media.url}?type=listing` : placeholderImage}
                alt="product"
                className={cx("photo")}
            />
        )
    }

    shortenString(name = "", maxLength = 40, endOfShortenStringSymbol = "...") {
        if (name && name.length > maxLength) {
            return this.renderSpanWithShy({ text: name.slice(0, maxLength) + endOfShortenStringSymbol })
        }

        return this.renderSpanWithShy({ text: name })
    }

    renderSpanWithShy({ text, props = {} }) {
        return (
            <span
                {...props}
                dangerouslySetInnerHTML={{
                    __html: text && text.replace(/_/gi, "_&shy;")
                }}
            />
        )
    }
}

export default withStyles(Product, style)
