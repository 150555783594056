import React, { useReducer } from "react"
import { useSelector } from "react-redux"
import idx from "idx"
import pick from "lodash/pick"
import { useCombinationsCreator } from "hooks"
import { Modal } from "ui"
import EditCombinationModal from "./EditCombinationModal"

function formReducer(state = { media_id: null, default: false }, action = {}) {
    switch (action.name) {
        case "media_id":
            return { ...state, media_id: action.value }
        case "default":
            return { ...state, default: action.value }
        default:
            return state
    }
}

const EditCombinationModalContainer = () => {
    const [{ editedCombination }, actions] = useCombinationsCreator()
    const [formFields, setField] = useReducer(
        formReducer,
        formReducer(idx(editedCombination, _ => _.values) || undefined)
    )
    const currentProduct = useSelector(state => state.db.products.current.data)
    const images = idx(currentProduct, _ => _.images.thumb) || []

    function handleSubmit() {
        const newValue = pick(formFields, ["media_id", "default"])
        const { accessor } = editedCombination
        actions.updateCombination({ accessor, newValue })
        onClose()
    }

    function handleChange(name, value) {
        setField({ name, value })
    }

    function onClose() {
        actions.setEditedCombination(null)
    }

    if (!editedCombination) {
        return null
    }

    return (
        <Modal isOpen={true} closeModal={onClose} preventClickOutside>
            <EditCombinationModal
                formFields={formFields}
                images={images}
                names={editedCombination.names}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                onClose={onClose}
            />
        </Modal>
    )
}

export default EditCombinationModalContainer
