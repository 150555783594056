import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useActions } from "hooks"
import { getEmailTemplates } from "actions/email-templates"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"
import EmailTemplates from "./EmailTemplates"

const EmailTemplatesContainer = () => {
    const actions = useActions({ getEmailTemplates })

    const { list: emailTemplates } = useSelector(state => state.db.emailTemplates)

    const [searchParams, setSearchParams] = useState({
        query: "",
        sort_by: "name",
        sort_direction: "desc",
        page: 1,
        length: LENGTH_WITHOUT_PAGINATION
    })

    const handleTableChange = (tableParams, isInitialCallback) => {
        if (!isInitialCallback) {
            setSearchParams(prevSearchParams => ({
                ...prevSearchParams,
                page: 1,
                query: tableParams.search,
                sort_by: tableParams.sorting.by,
                sort_direction: tableParams.sorting.direction
            }))
        }
    }

    useEffect(() => {
        actions.getEmailTemplates(searchParams)
    }, [searchParams])

    return <EmailTemplates emailTemplates={emailTemplates} handleTableChange={handleTableChange} />
}

export default EmailTemplatesContainer
