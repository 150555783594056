import * as constants from "constants/index"

function chosenLeafs(state = [], action) {
    switch (action.type) {
        case constants.CHOSEN_LEAFS:
            const leafWasAlreadyChosen = action.payload.chosenLeafs.includes(action.payload.id)
            const filtered = action.payload.chosenLeafs.filter(leaf => leaf !== action.payload.id)
            if (leafWasAlreadyChosen) return filtered
            else return [action.payload.id]
        case constants.CHOSEN_LEAFS_INIT:
            return [action.payload]
        case constants.CLEAR_CHOSEN_LEAFS:
            return []
        case constants.CLEAR_FILTERS:
            return []
        default:
            return state
    }
}

export default chosenLeafs
