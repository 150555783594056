import React from "react"

import withStyles from "HOC/withStyles"
import SubmitModal from "ui/SubmitModal"
import styles from "./deleteModal.css"

const DeleteModal = props => {
    const { isOpen, isLoading, serviceName, onCancel, onSubmit, cx } = props

    return (
        <SubmitModal
            redButton
            isOpen={isOpen}
            isLoading={isLoading}
            header={"Delete this service?"}
            body={
                <span>
                    {"Are you sure you want to delete service"}
                    <span className={cx("delete-header-bold")}>
                        &nbsp;{serviceName}
                        <span className={cx("delete-header-normal")}>?</span>
                    </span>
                    {" This action cannot be reversed."}
                </span>
            }
            submitText="Delete"
            onClose={onCancel}
            onSubmit={onSubmit}
        />
    )
}

export default withStyles(DeleteModal, styles)
