import React from "react"

import withStyles from "HOC/withStyles"
import { checkIsDark } from "helpers/color"

import { Table } from "ui/FilterableTable"
import { Input, Toggle, SketchColorPicker } from "ui"

import styles from "./ListRow.css"
import toggleStyles from "../../overrides/toggle.css"
import inputStyles from "../../overrides/input.css"

const ListRow = ({ item, form, index, cx }) => {
    const { title, color, is_visible, original_title, font_color } = item
    const calendarColors = ["#cdd2de", "#e89230", "#6ba64d", "#57a476", "#546382", "#4d7aa6", "#bb7e8f", "#a66037"]

    const replaceItem = item => {
        form.setFieldValue(`calendars[${index}]`, item)
    }

    const onChangeName = ({
        event: {
            target: { value }
        }
    }) => {
        replaceItem({
            ...item,
            title: value
        })
    }

    const onChangeVisibility = is_visible => {
        replaceItem({
            ...item,
            is_visible
        })
    }

    const onChangeColor = (color, isFontColor = false) => {
        replaceItem({
            ...item,
            [isFontColor ? "font_color" : "color"]: color
        })
    }

    return (
        <Table.Tr>
            <Table.Td>
                <div className={cx("colors")}>
                    <SketchColorPicker value={color} presetColors={calendarColors} onChange={onChangeColor} />
                    <SketchColorPicker
                        value={font_color || (checkIsDark(color) ? "#cdd2de" : "#2e3545")}
                        presetColors={calendarColors}
                        onChange={color => onChangeColor(color, true)}
                        backgroundColor={color}
                        isFontColor
                    />
                </div>
            </Table.Td>
            <Table.Td>
                <Input
                    value={title}
                    customStyles={inputStyles}
                    onChange={onChangeName}
                    error={form.errors[`calendars[${index}].title`]}
                />
            </Table.Td>
            <Table.Td>{original_title}</Table.Td>
            <Table.Td>
                <Toggle
                    isOn={is_visible}
                    handleChange={onChangeVisibility}
                    labelPosition="left"
                    label={{ on: "On", off: "Off" }}
                    customStyles={toggleStyles}
                />
            </Table.Td>
        </Table.Tr>
    )
}

export default withStyles(ListRow, styles)
