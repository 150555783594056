import React, { Fragment, useCallback, useMemo, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { useFormik } from "formik"
import _debounce from "lodash/debounce"
import _invokeAfter from "lodash/after"

import { Panel, Table, TableHead, TableHeadCell, TableBody, SnakeLoader } from "@butterfly-frontend/ui"
import { PERMISSIONS } from "constants/index"
import withPermissions from "HOC/withPermissions"
import { SaveBar, Textarea, Input, Toggle, ErrorBar } from "ui"
import useSelectProductConditions from "ui/Filters/SelectProductConditions/useSelectProductConditions"
import { useActions } from "hooks"
import { showSuccessNotification, showErrorNotification } from "actions/notification"
import SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/shipmentStatusKeys"
import { useShipmentEdit } from "modules/WmsModule/hooks/api/useShipment"
import { useBarcodeReader } from "modules/WmsModule/hooks"
import TABLE_COLUMNS from "modules/WmsModule/pages/ReceivingDetailsPage/constants/tableColumns"
import SHIPMENT_TYPE_KEYS from "modules/WmsModule/constants/shipmentTypeKeys"
import CLIENT_RETURN_SHIPMENT_STATUS_KEYS from "modules/WmsModule/constants/clientReturnShipmentStatusKeys"
import { SHIPMENT_PACK_ITEM_PROP_TYPE } from "modules/WmsModule/propTypes"

import { ReceivePackageTableRow } from "./components"
import { useAcceptBox } from "./hooks"
import styles from "./ReceivePackagePage.module.css"

const DESCRIPTION_MAX_LENGTH = 1000

const ReceivePackagePage = ({ data: shipmentData, canEdit }) => {
    const history = useHistory()
    const {
        values: conditionValues,
        fetchValues: fetchConditionValues,
        isLoading: isConditionsLoading
    } = useSelectProductConditions()

    const [pageError, setPageError] = useState(null)

    const shipmentPreviousChanges = useMemo(() => shipmentData, [])
    const isShipmentDelivered = shipmentData.status.status === SHIPMENT_STATUS_KEYS.DELIVERED
    const isShipmentFromPO = !!shipmentData.purchase_order

    const shipmentEdit = useShipmentEdit({ skipInvalidateQueries: true })
    const shipmentEditForCancelation = useShipmentEdit()
    const actions = useActions({ showErrorNotification, showSuccessNotification })

    const redirectToReceiving = useCallback(() => history.push("/wms/receivings"), [history])

    const isAllItemsReady = useMemo(() => shipmentData.items.every(item => typeof item.acceptance === "boolean"), [
        shipmentData.items
    ])

    const {
        barcodeInputProps,
        enableBarcodeInputToggleProps,
        barcodeInputRef,
        clearBarcodeInput,
        isBarcodeReaderEnabled
    } = useBarcodeReader()

    const { onAcceptBox, isLoading: isBoxAcceptLoading } = useAcceptBox({
        shipment: shipmentData,
        onAcceptError: error => {
            setPageError(error)
            clearBarcodeInput()
        },
        onAcceptSuccess: clearBarcodeInput
    })

    const onBarcodeSubmit = event => {
        event.preventDefault()
        setPageError(null)
        onAcceptBox(barcodeInputRef.current.value)
    }

    useEffect(() => {
        if (isShipmentFromPO) {
            fetchConditionValues()
        }
    }, [])

    const { data: defaultCondition, isLoading: isLoadingDefaultCondition } = useMemo(() => {
        if (isConditionsLoading) {
            return { data: null, isLoading: true }
        }
        if (conditionValues) {
            const defaultCondition = conditionValues.find(condition => condition.value.default)
            return { data: defaultCondition, isLoading: false }
        }
        return { data: null, isLoading: false }
    }, [isConditionsLoading, conditionValues])

    const onSubmit = useCallback(() => {
        shipmentEdit
            .mutate({
                id: shipmentData.id,
                data: {
                    status:
                        shipmentData.type === SHIPMENT_TYPE_KEYS.RETURN && !!shipmentData.order
                            ? CLIENT_RETURN_SHIPMENT_STATUS_KEYS.RECEIVED
                            : SHIPMENT_STATUS_KEYS.DELIVERED
                }
            })
            .then(() => {
                actions.showSuccessNotification({ title: "The stock transfer has been picked up" })
                redirectToReceiving()
            })
            .catch(actions.showErrorNotification)
    }, [shipmentEdit, shipmentData, redirectToReceiving, actions])

    const formik = useFormik({
        initialValues: {
            description: shipmentData.description || ""
        },
        validationSchema: Yup.object().shape({
            description: Yup.string().max(
                DESCRIPTION_MAX_LENGTH,
                `Description may not be greater than ${DESCRIPTION_MAX_LENGTH} characters`
            )
        }),
        onSubmit: ({ description }) =>
            shipmentEdit
                .mutate({
                    id: shipmentData.id,
                    data: { description }
                })
                .catch(actions.showErrorNotification)
    })

    const updateDescription = useCallback(_debounce(_invokeAfter(2, formik.submitForm), 500), [formik.submitForm])

    const onCancelChanges = useCallback(() => {
        shipmentEditForCancelation.mutate({
            id: shipmentData.id,
            data: shipmentPreviousChanges.items.reduce(
                (result, previousItem) => {
                    const item = shipmentData.items.find(item => item.id === previousItem.id)
                    return item.acceptance !== previousItem.acceptance
                        ? { items_to_be_accepted: [...result.items_to_be_accepted, item.shipment_stock_item_id] }
                        : result
                },
                { items_to_be_accepted: [] }
            )
        })
    }, [shipmentEditForCancelation, shipmentData, shipmentPreviousChanges])

    useEffect(() => {
        updateDescription()
    }, [formik.values.description])

    return (
        <Fragment>
            {pageError && <ErrorBar text={pageError} />}
            <Panel classes={{ panel: styles.items }}>
                {isLoadingDefaultCondition ? (
                    <SnakeLoader />
                ) : (
                    <Fragment>
                        <div className={styles.itemsHeader}>
                            <h2>Items</h2>
                            <div className={styles.barcodeToggle}>
                                <Toggle
                                    {...enableBarcodeInputToggleProps}
                                    labelPosition="left"
                                    label={{ on: "Barcode scanning", off: "Barcode scanning" }}
                                    isSmall
                                />
                            </div>
                            <form onSubmit={onBarcodeSubmit} className={styles.barcodeForm}>
                                <Input {...barcodeInputProps} disabled={isBoxAcceptLoading} />
                            </form>
                        </div>
                        <Table classes={{ root: styles.table }}>
                            <TableHead>
                                <TableHeadCell {...TABLE_COLUMNS.no}>#</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.product}>PRODUCT NAME</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.id}>ID</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.condition}>CONDITION</TableHeadCell>
                                <TableHeadCell {...TABLE_COLUMNS.packAction} />
                            </TableHead>
                            <TableBody>
                                {shipmentData.items.map((item, index) => (
                                    <ReceivePackageTableRow
                                        key={item.id}
                                        index={index}
                                        item={item}
                                        shipment={{
                                            id: shipmentData.id,
                                            isShipmentDelivered,
                                            isFromPO: !!shipmentData.purchase_order
                                        }}
                                        defaultCondition={defaultCondition}
                                        isBarcodeReaderEnabled={isBarcodeReaderEnabled}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Fragment>
                )}
            </Panel>
            <div className={styles.description}>
                {isShipmentDelivered || !canEdit() ? (
                    <Fragment>
                        <h3>Description</h3>
                        <p>{shipmentData.description}</p>
                    </Fragment>
                ) : (
                    <Textarea
                        label="Description"
                        className={styles.textarea}
                        name="description"
                        placeholder="Click here to add a description..."
                        value={formik.values.description}
                        error={formik.touched.description && formik.errors.description}
                        onBlur={formik.handleBlur}
                        onChange={({ event }) => formik.handleChange(event)}
                    />
                )}
            </div>
            <SaveBar
                isDisabled={
                    !isAllItemsReady ||
                    isShipmentDelivered ||
                    shipmentEdit.fetchStatus.isLoading ||
                    shipmentEditForCancelation.fetchStatus.isLoading ||
                    !canEdit()
                }
                isDisabledCancel={
                    shipmentEdit.fetchStatus.isLoading || shipmentEditForCancelation.fetchStatus.isLoading || !canEdit()
                }
                isShown
                isSubmit
                isSaving={shipmentEdit.fetchStatus.isLoading}
                submitLabel="Pick up"
                onSubmit={onSubmit}
                onCancel={onCancelChanges}
            />
        </Fragment>
    )
}

ReceivePackagePage.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        purchase_order: PropTypes.object,
        order: PropTypes.object,
        status: PropTypes.shape({
            status: PropTypes.string.isRequired
        }).isRequired,
        items: PropTypes.arrayOf(SHIPMENT_PACK_ITEM_PROP_TYPE).isRequired,
        description: PropTypes.string
    }),
    canEdit: PropTypes.func.isRequired
}

export default withPermissions(ReceivePackagePage, PERMISSIONS.context.SHIPMENTS_AND_RECEIVINGS)
