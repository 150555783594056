import { combineReducers } from "redux"
import * as constants from "constants/index"

import currentlySelectedImages from "./currentlySelectedImages"

function currentFolderId(state = null, action) {
    switch (action.type) {
        case constants.SET_CURRENT_FOLDER:
            return action.payload
        default:
            return state
    }
}

function isImageDragging(state = false, action) {
    switch (action.type) {
        case constants.SET_IMAGE_DRAGGING_STATUS:
            return action.payload
        default:
            return state
    }
}

function transferFromProduct(state = { productId: null, imageId: null, url: null, name: null }, action) {
    switch (action.type) {
        case constants.TRANSFER_FROM_PRODUCT:
            return action.payload
        default:
            return state
    }
}

function saveTransferedImagesLocally(state = [], action) {
    switch (action.type) {
        case constants.SAVE_TRANSFERED_IMAGES_LOCALLY:
            return [...state, ...action.payload]

        case constants.CHANGE_PRODUCT_IMAGES_COUNT:
            const { nextValue, transferFromProduct } = action.payload
            if (nextValue >= 0 || !transferFromProduct) {
                return state
            }

            let newState = state.filter(item => item.id !== transferFromProduct.imageId)
            return [...newState]

        default:
            return state
    }
}

const uploadModuleReducer = combineReducers({
    currentFolderId,
    isImageDragging,
    currentlySelectedImages,
    transferFromProduct,
    transferedImages: saveTransferedImagesLocally
})

export default uploadModuleReducer
