import React from "react"

import closeIcon from "assets/close-blue.svg"

import withStyles from "HOC/withStyles"
import styles from "./filtersListRow.css"

const ListRow = props => {
    const { cx, id, name, insight, deleteFilter } = props

    return (
        <div className={cx("root")}>
            <div className={cx("content")}>
                <div>{name}</div>
                <div>{insight}</div>
            </div>
            <button onClick={() => deleteFilter(id)}>
                <img src={closeIcon} alt="" />
                Delete
            </button>
        </div>
    )
}

export default withStyles(ListRow, styles)
