import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import idx from "idx"

import SendableModal from "ui/SendableModal"

import getPrintBlob, { downloadBlobFile } from "actions/print"
import { createSendable } from "actions/sendable"
import { getEmailTemplates } from "actions/email-templates"
import { showSuccessNotification } from "actions/notification"
import { getOrder } from "actions/orders"

import fetch from "helpers/fetch"
import { combineFetchStatuses } from "helpers/fetchStatus"

import { useActions, useFetch } from "hooks"

const config = {
    invoice: { name: "Invoice", printType: "invoice" },
    purchase_order: { name: "Purchase Order", printType: "purchase_order" },
    sales: { name: "Sales Order", printType: "order" },
    memo: { name: "Memo", printType: "order" },
    rental: { name: "Rental Agreement", printType: "order" }
}

const Sendable = props => {
    const { handleClose, id, type, uuid, clientEmails, reloadDocument, printTemplateType } = props

    const templates = useSelector(state => state.db.emailTemplates.list)
    const [userSignature, setUserSignature] = useState("")
    const isLoaded = templates.fetchStatus.isLoaded || templates.fetchStatus.isError
    const { name, printType } = useMemo(() => config[type] || {}, [type])
    const template = useMemo(() => {
        const currentTemplate = templates.data.find(t => t.name === name) || {}
        return { ...currentTemplate, message: `${currentTemplate.message}\n\n${userSignature}` }
    }, [templates, name, userSignature])

    const actions = useActions({
        showSuccessNotification,
        getEmailTemplates,
        createSendable,
        getOrder
    })

    useEffect(() => {
        actions.getEmailTemplates()
        fetchUserProfile()
    }, [])

    const [fetchUserProfile, userProfileFetchStatus] = useFetch({
        action: () => fetch.get("/profile"),
        onSuccess: ({ data }) => setUserSignature(idx(data, _ => _.settings.signature) || "")
    })

    const [handlePrint, { isLoading: isPrinting }] = useFetch({
        action: () => getPrintBlob(id, printType, printTemplateType),
        onSuccess: blob => downloadBlobFile(blob, { fileName: uuid })
    })

    const [handleSubmit, { isLoading: isSubmitting }] = useFetch({
        action: values => {
            const { documents, message, receivers, subject } = values
            const emails = receivers.map(obj => obj.email)

            const data = {
                documents,
                message,
                receivers: emails,
                subject
            }

            Object.keys(data).forEach(key => {
                if (!data[key]) {
                    delete data[key]
                }
            })

            return actions.createSendable(data)
        },
        onSuccess: () => {
            actions.showSuccessNotification({ title: "Sent" })
            actions.getOrder(id, true)
            reloadDocument()
            handleClose()
        },
        onError: (data, _, { setErrors }) => {
            data && data.errors && setErrors(data.errors)
            data && data.message && setErrors({ message: data.message })
        }
    })

    return (
        <SendableModal
            isSubmitting={isSubmitting}
            uuid={uuid}
            template={template}
            fetchStatus={{ ...combineFetchStatuses([templates.fetchStatus, userProfileFetchStatus]), isLoaded }}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            id={id}
            clientEmails={clientEmails}
            printType={printType}
            isPrinting={isPrinting}
            printTemplateType={printTemplateType}
            handlePrint={handlePrint}
        />
    )
}

Sendable.defaultProps = {
    reloadDocument: () => {}
}

Sendable.propTypes = {
    handleClose: PropTypes.func.isRequired,
    reloadDocument: PropTypes.func,
    id: PropTypes.number.isRequired,
    uuid: PropTypes.string.isRequired,
    clientEmails: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.oneOf(Object.keys(config)).isRequired
}

export default Sendable
