import React, { Component } from "react"
import CSSModules from "react-css-modules"
import style from "./styles.css"
import close from "assets/close.svg"
import Button from "components/Reusable/Form/Button/index"
import FilesUploader from "components/Reusable/FilesUploader"

class AddNote extends Component {
    render() {
        const {
            handleHide,
            hasAttachments,
            isEdited,
            isLoading,
            handleUpload,
            handleLoading,
            noteText,
            changeNote,
            onSubmit,
            handleRemove,
            attachments,
            clientId
        } = this.props
        return (
            <div className="modal-background">
                <div styleName="modal-cover">
                    <img src={close} alt="close" styleName="close-icon" onClick={handleHide} />
                    <h1 styleName="input-title">Add Note:</h1>
                    <form>
                        <textarea
                            type="text"
                            placeholder="Click here to add a note..."
                            styleName="textarea"
                            maxLength={10000}
                            value={noteText}
                            onChange={changeNote}
                        />
                        {hasAttachments && (
                            <div styleName="modal-upload-holder">
                                <FilesUploader
                                    handleRemove={index => handleRemove(index)}
                                    attachments={attachments}
                                    handleUploadFile={handleLoading}
                                    handleUploadSuccess={uploadState => handleUpload(uploadState)}
                                />
                            </div>
                        )}
                        <div styleName="modal-button-holder">
                            <span onClick={handleHide} styleName="modal-cancel">
                                Cancel
                            </span>
                            <Button
                                className={["first-button", "add-next-note"]}
                                customStyles={style}
                                label={isEdited ? "Edit" : "Add"}
                                isDisabled={noteText.length === 0 || isLoading}
                                onClick={event => onSubmit(event, clientId)}
                            />
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default CSSModules(AddNote, style)
