import React, { useState, useEffect, Fragment } from "react"
import fetch from "helpers/fetch"
import { displayUserName } from "helpers/user"
import { SelectWithSearch } from "ui"

const SelectClient = props => {
    const { customStyles, name, label } = props
    const [pagination, setPagination] = useState({ current_page: 0, last_page: 1 })
    const [values, setValues] = useState([])
    const [value, setValue] = useState({ label: "" })
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const { value } = props
        setValue({ label: displayUserName(value), value })
    }, [props.value])

    const fetchClients = (query, page) => {
        if (isLoading) {
            return
        }

        const formatData = data => {
            return data.map(item => ({ value: item, label: displayUserName(item), secondLabel: "" }))
        }

        setIsLoading(true)

        fetch
            .get(`/clients?query=${query}&page=${page}&length=15`)
            .then(data => {
                setValues(page === 1 ? formatData(data.data) : [...values, ...formatData(data.data)])
                setPagination({ ...data.meta, current_page: page })
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Fragment>
            <SelectWithSearch
                name={name}
                label={label}
                value={value.label}
                values={values}
                pagination={pagination}
                setValue={({ value }) => props.handleSelect(value)}
                placeholder="- Please select -"
                fetchValues={fetchClients}
                customStyles={customStyles}
            />
        </Fragment>
    )
}

export default SelectClient
