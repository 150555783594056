import React, { useState } from "react"
import { Formik, Form as FormikForm } from "formik"
import PropTypes from "prop-types"
import merge from "lodash/merge"

import SectionModalParallaxWithText from "./SectionModalParallaxWithText"
import {
    getInitialValues as getProductLinkInitialValues,
    getSaveValues as getProductLinkSaveValues
} from "../ShowProductLink/values"
import { section as sectionSchema } from "./schema"

const Container = ({ onClose, onSave, data }) => {
    const [isSubmitting, setIsSubmitting] = useState(false)

    const settings = data.settings || {}
    const initialValues = merge(
        data,
        {
            settings: {
                showText: !!settings.heading || !!settings.subheading,
                heading: settings.heading || "",
                subheading: settings.subheading || "",
                showLink: !!data.product || !!settings.link_prefix || !!settings.link_text,
                link_prefix: settings.link_prefix || "",
                link_text: settings.link_text || ""
            }
        },
        getProductLinkInitialValues(data)
    )

    const onSubmit = async (values, formikActions) => {
        try {
            setIsSubmitting(true)

            await onSave(
                merge(
                    values,
                    { paralax_image: values.paralax_image ? values.paralax_image.id : null },
                    getProductLinkSaveValues(values)
                )
            )

            onClose()
        } catch (error) {
            setIsSubmitting(false)
            formikActions.setErrors(error)
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={sectionSchema}
            enableReinitialize
            onSubmit={onSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {propsForm => (
                <FormikForm>
                    <SectionModalParallaxWithText onClose={onClose} form={propsForm} isSubmitting={isSubmitting} />
                </FormikForm>
            )}
        </Formik>
    )
}

Container.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
}

export default Container
