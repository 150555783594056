import React, { useState, useRef } from "react"
import PropTypes from "prop-types"
import ReactSvg from "react-svg"

import withStyles from "HOC/withStyles"
import styles from "./Dropdown.css"

import whiteArrowIcon from "assets/arrow-white.svg"
import defaultArrowIcon from "assets/arrow_down_blue.svg"

const Dropdown = props => {
    const { cx, className, disabled, label, options, isListOnTop, isListOnRight, arrowIconColor = "default" } = props

    const elementRef = useRef(null)

    const [isOpen, setIsOpen] = useState(false)

    const toggleIsOpen = () => setIsOpen(wasOpen => !wasOpen)
    const closeOptions = () => setIsOpen(false)

    const onClickOption = action => {
        action()
        closeOptions()
    }
    const onClick = () => {
        toggleIsOpen()
        elementRef.current.focus()
    }
    const onBlur = e => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            closeOptions()
        }
    }

    const isOptionsArray = Array.isArray(options)
    const isDisabled = disabled || !isOptionsArray || !options.length

    return (
        <div tabIndex="-1" className={cx("root", { isOpen, isDisabled }, className)} ref={elementRef} onBlur={onBlur}>
            <button className={cx("label")} onClick={onClick}>
                {label}
                <ReactSvg
                    className={cx("arrowIcon")}
                    src={arrowIconColor === "white" ? whiteArrowIcon : defaultArrowIcon}
                />
            </button>
            {!isDisabled && isOpen && (
                <ul className={cx("options", { isListOnTop, isListOnRight })}>
                    {options.map(({ label, onClick, disabled }, index) => (
                        <li key={index} className={cx("option")}>
                            <button onClick={() => onClickOption(onClick)} disabled={disabled || !onClick}>
                                {label}
                            </button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

Dropdown.defaultProps = {
    className: "",
    options: []
}

Dropdown.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.func,
                PropTypes.node,
                PropTypes.object
            ]).isRequired,
            onClick: PropTypes.func,
            disabled: PropTypes.bool
        })
    ),
    isListOnTop: PropTypes.bool,
    isListOnRight: PropTypes.bool
}

export default withStyles(Dropdown, styles)
