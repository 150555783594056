import { useCallback, useEffect, useState, useMemo } from "react"

const useTableLogic = (props = {}) => {
    const { isEnabled = true, filters = {}, sort, useQueryList, reactQueryProps = {} } = props
    const [params, setParams] = useState({ ...filters, sort_by: sort.by, sort_direction: sort.direction, page: 1 })

    useEffect(() => {
        setParams({ ...filters, sort_by: sort.by, sort_direction: sort.direction, page: 1 })
    }, [filters, sort])

    const onChangePage = useCallback(page => setParams(prevParams => ({ ...prevParams, page })), [])

    const queryList = useQueryList({ params, reactQueryProps: { isEnabled, ...reactQueryProps } })
    const { data, meta, refetch, query } = queryList

    const isAnyFilterSent = useMemo(() => {
        return Object.values(filters).some(filter => {
            if (filter === undefined || filter === null) {
                return false
            }
            return filter.length > 0 || filter.toString().length > 0 || typeof filter === "boolean"
        })
    }, [filters])

    const isEmptyListWithFilter = useMemo(() => isAnyFilterSent && query.isFetched && meta.total === 0, [
        isAnyFilterSent,
        meta.total,
        query.isFetched
    ])

    const isEmptyList = useMemo(() => !isAnyFilterSent && query.isFetched && meta.total === 0, [
        isAnyFilterSent,
        meta.total,
        query.isFetched
    ])

    const fetchStatus = useMemo(
        () => ({
            isLoading: query.isFetching,
            isLoaded: query.isFetched && !query.isFetching,
            isError: query.isError,
            lastFetch: query.dataUpdatedAt
        }),
        [query.isError, query.isFetching, query.isFetched, query.dataUpdatedAt]
    )

    const fetchFlags = useMemo(
        () => ({
            isEmptyListWithFilter,
            isEmptyList
        }),
        [isEmptyListWithFilter, isEmptyList]
    )

    const pagination = useMemo(
        () => ({
            currentPage: params.page,
            pages: meta.last_page,
            onChange: onChangePage,
            canDisplay: meta.last_page > 1 && fetchStatus.isLoaded
        }),
        [params.page, meta.last_page, fetchStatus.isLoaded, onChangePage]
    )

    return {
        data,
        meta,
        fetchStatus,
        pagination,
        refetch,
        query: queryList,
        fetchFlags
    }
}

export default useTableLogic
