import React, { Fragment } from "react"
import { Modal as ModalComponent, Input, Button } from "ui"
import buttonStyles from "./styles/button.css"
import headerStyles from "./overrides/header.css"
import inputStyles from "./overrides/input.css"
import Loader from "components/Reusable/Loader/Loader"
import Skeleton from "components/Skeleton"

const Modal = props => {
    const { title, cx, closeModal, handleButton, fieldsProps, saveButtonLabel, isFetching, isLoading } = props

    return (
        <Fragment>
            <ModalComponent.Header customStyles={headerStyles}>{title}</ModalComponent.Header>
            <ModalComponent.Body>
                <Skeleton
                    isLoading={isLoading}
                    isLoaded={!isLoading}
                    component={
                        <div className={cx("loader")}>
                            <Loader />
                        </div>
                    }
                    render={() => (
                        <div className={cx(["input-container", "locationName"])}>
                            <Input customStyles={inputStyles} type="text" {...fieldsProps["locationName"]} />
                        </div>
                    )}
                />
            </ModalComponent.Body>
            <ModalComponent.Actions>
                <button className={cx("cancel-button")} onClick={closeModal}>
                    Cancel
                </button>
                <Button
                    className="first-button"
                    customStyles={buttonStyles}
                    onClick={handleButton}
                    label={saveButtonLabel}
                    isLoading={isFetching}
                />
            </ModalComponent.Actions>
        </Fragment>
    )
}

export default Modal
