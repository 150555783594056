import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useField } from "formik"
import { useMediaQuery } from "react-responsive"

import TagsSelect from "modules/ContentModule/components/TagsSelect"
import ImageFormUploader from "modules/ContentModule/components/ImageFormUploader"
import { Toggle } from "ui"

import withStyles from "HOC/withStyles"
import styles from "./styles.css"

const Sidebar = ({ cx, withHomepagePicture, withHomepageVisibility, imagesDimensions }) => {
    const [thumbnailHorizontal] = useField("thumbnail_horizontal")
    const [thumbnailVertical] = useField("thumbnail_vertical")
    const [tag, tagMeta] = useField("tag")
    const [homepagePicture, homepagePictureMeta] = useField("homepage_image")
    const [inMenu] = useField("in_menu")
    const [inHomepage] = useField("in_homepage")

    const setValue = (value, field) => field.onChange({ target: { ...field, value } })
    const setThumbnailHorizontalValue = value => setValue(value, thumbnailHorizontal)
    const setThumbnailVerticalValue = value => setValue(value, thumbnailVertical)
    const setTagValue = value => setValue(value, tag)
    const setHomepagePictureValue = value => setValue(value, homepagePicture)
    const setMenuVisibilityValue = value => setValue(value, inMenu)
    const setHomepageVisibilityValue = value => setValue(value, inHomepage)

    const isMobile = useMediaQuery({ query: "(max-width: 1023px)" })

    return (
        <div className={cx("root")}>
            <div className={cx("section")}>
                <h2 className={cx("header")}>Options</h2>

                <span className={cx("label")}>Work horizontal thumbnail:</span>
                <ImageFormUploader
                    value={thumbnailHorizontal.value}
                    onSuccess={setThumbnailHorizontalValue}
                    removeFile={() => setThumbnailHorizontalValue(null)}
                    imageDimensions={imagesDimensions.thumbnail_horizontal}
                />
                <span className={cx("label")}>Work vertical thumbnail:</span>
                <ImageFormUploader
                    value={thumbnailVertical.value}
                    onSuccess={setThumbnailVerticalValue}
                    removeFile={() => setThumbnailVerticalValue(null)}
                    imageDimensions={imagesDimensions.thumbnail_vertical}
                />

                <span className={cx("label")}>Tags:</span>
                <TagsSelect isListOnTop={isMobile} tagId={tag.value} error={tagMeta.error} setTag={setTagValue} />

                {withHomepageVisibility && (
                    <Fragment>
                        <span className={cx("label")}>Homepage visibility:</span>
                        <Toggle isOn={!!inHomepage.value} handleChange={setHomepageVisibilityValue} />
                    </Fragment>
                )}

                {(withHomepagePicture || withHomepageVisibility) && (
                    <div className={cx("homepageImageWrapper", { isError: homepagePictureMeta.error })}>
                        <span className={cx("label")}>Home page image:</span>
                        <ImageFormUploader
                            value={homepagePicture.value}
                            onSuccess={setHomepagePictureValue}
                            removeFile={() => setHomepagePictureValue(null)}
                            imageDimensions={imagesDimensions.home_page}
                        />
                        {!!homepagePictureMeta.error && <p className={cx("errorMessage")}>Image is required</p>}
                    </div>
                )}

                <span className={cx("label")}>Menu visibility:</span>
                <Toggle isOn={!!inMenu.value} handleChange={setMenuVisibilityValue} />
            </div>
        </div>
    )
}

Sidebar.propTypes = {
    imagesDimensions: PropTypes.shape({
        home_page: PropTypes.object,
        thumbnail_vertical: PropTypes.object,
        thumbnail_horizontal: PropTypes.object
    }).isRequired,
    withHomepagePicture: PropTypes.bool
}

export default withStyles(Sidebar, styles)
