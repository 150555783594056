import React, { Component } from "react"

import { withStyles } from "ui/FilterableTable/HOC"
import styles from "./Table.css"

const Head = withStyles(styles)(({ cx, children }) => <thead className={cx("thead")}>{children}</thead>)

const Body = withStyles(styles)(({ cx, children }) => <tbody className={cx("tbody")}>{children}</tbody>)

const Tr = withStyles(styles)(({ cx, children, className, ...props }) => (
    <tr {...props} className={cx("tr", className && className.split(" "))}>
        {children}
    </tr>
))

const Th = withStyles(styles)(({ cx, className, customClass, children, ...props }) => (
    <th {...props} className={cx("th", className && className.split(" "), customClass)}>
        {children}
    </th>
))

const Td = withStyles(styles)(({ cx, className, customClass, children, ...props }) => (
    <td {...props} className={cx("td", className && className.split(" "), customClass)}>
        {children}
    </td>
))

class Table extends Component {
    static defaultProps = {
        cellSize: "normal"
    }

    render() {
        const { children, cx, className } = this.props
        return (
            <table className={[cx("table"), className].join(" ")} cellSpacing={0}>
                {children}
            </table>
        )
    }
}

export { Head, Body, Tr, Th, Td }

export default withStyles(styles)(Table)
