import * as constants from "constants/index"

function brands(state = [], action) {
    const id = action.payload

    switch (action.type) {
        case constants.CLEAR_FILTERS:
            return []
        case constants.ADD_BRAND_FILTER:
            return [...state, id]
        case constants.REMOVE_BRAND_FILTER:
            return [...state.filter(stateId => stateId !== id)]
        default:
            return state
    }
}

export default brands
