import React, { Component } from "react"
import ReactSVG from "react-svg"

import withStyles from "HOC/withStyles"
import styles from "./ActionButton.css"

class ActionButton extends Component {
    render() {
        const { cx, icon, text, handleAction } = this.props

        return (
            <div className={cx("button")} onClick={handleAction}>
                <ReactSVG src={icon} alt="Action button" />
                {text}
            </div>
        )
    }
}

export default withStyles(ActionButton, styles)
