import * as yup from "yup"

import {
    settings as settingsFieldsSchema,
    settingsExtraSecondLink as settingsFieldsExtraSchema,
    product as productSchema,
    secondProduct as secondProductSchema
} from "../ShowProductLink/schemas"

export const section = yup.object().shape({
    settings: yup
        .object()
        .shape({
            type: yup
                .string()
                .oneOf(["single", "double"])
                .required(),
            ...settingsFieldsSchema,
            ...settingsFieldsExtraSchema
        })
        .required(),
    product: productSchema,
    second_product: secondProductSchema,
    first_image: yup.object().required("Field is required"),
    second_image: yup.object().when("settings.type", {
        is: "double",
        then: yup.object().required("Field is required"),
        otherwise: yup.object().nullable()
    })
})
