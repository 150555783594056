import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import fetch from "helpers/fetch"

import * as actions from "actions/portfolio"
import * as notificationActions from "actions/notification"

import Form from "./Form"

import { transformError } from "../../../helpers"
import { portfolio as portfolioSchema } from "./schemas"
import { INIT_VALUES } from "./constants"

const FormContainer = ({
    match,
    portfolioPost,
    getPortfolioPost,
    clearPortfolioPost,
    history,
    showSuccessNotification,
    showErrorNotification
}) => {
    const portfolioId = match.params.portfolioId

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (portfolioId) {
            getPortfolioPost(portfolioId)

            return () => {
                clearPortfolioPost()
            }
        }

        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (portfolioId && portfolioPost && portfolioPost.id) {
            setIsLoading(false)
        }
    }, [portfolioPost])

    const initialValues = () => {
        let data = { ...INIT_VALUES }
        if (portfolioPost && portfolioPost.id) {
            data = {
                ...data,
                ...portfolioPost
            }
        }

        return data
    }

    const scrollToError = errors => {
        if (Object.keys(errors).length > 0) {
            const elementToScroll =
                document.getElementsByName(Object.keys(errors)[0])[0] ||
                document.getElementsByClassName(Object.keys(errors)[0])[0]

            if (elementToScroll) {
                elementToScroll && elementToScroll.scrollIntoView()
                window.scrollTo(0, window.scrollY - 200)
            }
        }
    }

    const handleSubmit = async (values, formikActions) => {
        try {
            await portfolioSchema.validate(values, { abortEarly: false })

            const portfolioValues = {
                ...values,
                thumbnail: values.thumbnail ? values.thumbnail.id : null,
                cover_desktop: values.cover_desktop ? values.cover_desktop.id : null,
                cover_tablet: values.cover_tablet ? values.cover_tablet.id : null,
                cover_mobile: values.cover_mobile ? values.cover_mobile.id : null
            }

            setIsSubmitting(true)

            portfolioId
                ? await fetch.patch(`/realizations/${portfolioId}`, portfolioValues)
                : await fetch.post("/realizations", portfolioValues)

            history.push("/content/portfolio")
        } catch (error) {
            setIsSubmitting(false)

            const errors = transformError(error)

            formikActions.setErrors(errors)
            scrollToError(errors)
        }
    }

    const handleCancel = () => history.push("/content/portfolio")

    return (
        <div>
            <Form
                initValues={initialValues()}
                handleSubmit={handleSubmit}
                handleCancel={handleCancel}
                isSubmitting={isSubmitting}
                isLoading={isLoading}
                showErrorNotification={showErrorNotification}
                showSuccessNotification={showSuccessNotification}
            />
        </div>
    )
}

FormContainer.propTypes = {
    match: PropTypes.object.isRequired,
    getPortfolioPost: PropTypes.func.isRequired,
    clearPortfolioPost: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired
}

const mapStateToProps = state => {
    return {
        portfolioPost: state.db.portfolio.current.data
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPortfolioPost: id => dispatch(actions.getPortfolioPost(id)),
        clearPortfolioPost: () => dispatch(actions.clearPortfolioPost()),
        showSuccessNotification: () => dispatch(notificationActions.showSuccessNotification()),
        showErrorNotification: () => dispatch(notificationActions.showErrorNotification())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FormContainer)
