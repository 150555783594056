import React, { useState, useEffect } from "react"

import withStyles from "HOC/withStyles"

import styles from "./RelatedProducts.css"
import ChooseProductModal from "components/Modal/ChooseProduct"
import * as productActions from "actions/product"
import { useDispatch } from "react-redux"
import { showSuccessNotification, showErrorNotification } from "actions/notification"

import RelatedProductsContainer from "./components/RelatedProductsContainer"
import Header from "./components/Header"

function RelatedProducts(props) {
    const { cx, product } = props
    const parentIsLoading = props.isLoading
    const productId = product.id
    const relatedProducts = product.related
    const isEmpty = relatedProducts.length === 0
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(parentIsLoading)
    const [productModalIsOpen, setProductModalIsOpen] = useState(false)
    const [productFetchStatuses, setProductFetchStatuses] = useState({})

    const additionalFilters = {
        exclude: [...product.related.map(rel => rel.id), productId].join(",")
    }

    const toggleProductModalIsOpen = () => {
        setProductModalIsOpen(prevState => !prevState)
    }

    const handleAdd = () => {
        toggleProductModalIsOpen()
    }

    useEffect(() => {
        setIsLoading(false)
    }, [product])

    const handleDeleteAll = () => {
        const id = productId

        setIsLoading(true)

        productActions.detachAllRelatedProducts({ id }).then(() => {
            setTimeout(() => {
                productId && dispatch(productActions.getProductForRelatedById(productId))
            }, 350)
        })
    }

    function renderChooseProductModal() {
        const mainTitle = "Related products"

        return (
            <ChooseProductModal
                mainTitle={mainTitle}
                additionalFilters={additionalFilters}
                isOpen={productModalIsOpen}
                handleClose={toggleProductModalIsOpen}
                handleSelect={handleChooseProduct}
                disableQuantity={true}
                disableCombinations={true}
                fetchStatuses={productFetchStatuses}
            />
        )
    }

    const handleChooseProduct = params => {
        const { product_id } = params
        const data = [{ id: product_id }]
        const id = productId

        setIsLoading(true)
        handleProductFetchStatus(product_id, true)

        productActions
            .attachRelatedProducts({ id, data })
            .then(() => {
                setTimeout(() => {
                    productId && dispatch(productActions.getProductForRelatedById(productId))
                    dispatch(showSuccessNotification())
                }, 350)
            })
            .catch(() => dispatch(showErrorNotification()))
            .finally(() => {
                handleProductFetchStatus(product_id, false)
                setIsLoading(false)
            })
    }

    const handleProductFetchStatus = (productId, value) => {
        setProductFetchStatuses(prevState => ({
            ...prevState,
            [productId]: {
                ...prevState[productId],
                isLoading: value
            }
        }))
    }

    return (
        <div className={cx("root")}>
            {renderChooseProductModal()}

            <Header
                setIsLoading={setIsLoading}
                counter={relatedProducts.length}
                cx={cx}
                handleAdd={handleAdd}
                handleDeleteAll={handleDeleteAll}
                isEmpty={isEmpty}
            />

            <RelatedProductsContainer
                setIsLoading={setIsLoading}
                productId={productId}
                cx={cx}
                isOneLineGrid={true}
                products={relatedProducts}
                isLoading={isLoading}
                isRelated={true}
                isEmpty={isEmpty}
            />
        </div>
    )
}

export default withStyles(RelatedProducts, styles)
