import * as Yup from "yup"

const createMaxLengthErrorMessage = maxLength => `The field must be less than ${maxLength} characters`

const MAX_DESCRIPTION_LENGTH = 1000
const MAX_INPUT_TEXT_LENGTH = 255

const validationSchema = Yup.object().shape({
    description: Yup.string().max(MAX_DESCRIPTION_LENGTH, createMaxLengthErrorMessage(MAX_DESCRIPTION_LENGTH)),
    packages: Yup.array()
        .of(
            Yup.object().shape({
                carrier: Yup.string().max(MAX_INPUT_TEXT_LENGTH, createMaxLengthErrorMessage(MAX_INPUT_TEXT_LENGTH)),
                trackingNumber: Yup.string().max(
                    MAX_INPUT_TEXT_LENGTH,
                    createMaxLengthErrorMessage(MAX_INPUT_TEXT_LENGTH)
                ),
                stockItemList: Yup.array()
                    .of(
                        Yup.object().shape({
                            id: Yup.number().required()
                        })
                    )
                    .min(1)
            })
        )
        .min(1)
})

export default validationSchema
