const TABLE_COLUMNS = {
    id: {
        minWidth: 75,
        width: "20%"
    },
    origin: {
        minWidth: 50,
        width: "17%"
    },
    client: {
        minWidth: 75,
        width: "15%"
    },
    shipTo: {
        minWidth: 75,
        width: "17%"
    },
    status: {
        minWidth: 110,
        width: "12%"
    },
    createdAt: {
        minWidth: 115,
        width: "13%"
    },
    detailsButton: {
        minWidth: 85,
        width: 85
    }
}

export default TABLE_COLUMNS
