import React, { Component } from "react"

const withShowScrollBar = WrappedComponent => {
    class Enhance extends Component {
        render() {
            return <WrappedComponent {...this.props} handleShowScrollBar={this.handleShowScrollBar} />
        }

        handleShowScrollBar = () => {
            document.body.style.overflowY = "auto"
            document.body.style.position = "static"
        }
    }

    return Enhance
}

export default withShowScrollBar
