export const displayUserName = user => {
    if (!user) {
        return ""
    }

    if (user.full_name) {
        return user.full_name
    }

    if (user.first_name && user.last_name) {
        return user.first_name + " " + user.last_name
    }

    if (user.first_name) {
        return user.first_name + (user.email ? " (" + user.email + ")" : "")
    }

    if (user.last_name) {
        return user.last_name + (user.email ? " (" + user.email + ")" : "")
    }

    if (user.company) {
        return user.company
    }

    return user.email || ""
}
