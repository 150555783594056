import idx from "idx"
import { iif, of, merge, forkJoin } from "rxjs"
import { map, switchMap, catchError, startWith, pluck, flatMap as flatMapRx } from "rxjs/operators"
import { ofType, combineEpics } from "redux-observable"
import { update, omit, flatMap, cloneDeep, get, isObject } from "lodash"
import fetch from "helpers/fetch"
import { initialState, requestState, successState, failureState } from "helpers/fetchStatus"
import { LENGTH_WITHOUT_PAGINATION } from "constants/index"

// ACTIONS

const actionType = type => `combinations/${type}`

const fetchActions = {
    getInitialData: {
        init: () => ({ type: actionType("initialData/init") }),
        start: () => ({ type: actionType("initialData/start") }),
        success: payload => ({ type: actionType("initialData/success"), payload }),
        failure: payload => ({ type: actionType("initialData/failure"), payload })
    },
    getSubgroups: {
        init: () => ({ type: actionType("subgroups/init") }),
        start: () => ({ type: actionType("subgroups/start") }),
        success: payload => ({ type: actionType("subgroups/success"), payload }),
        failure: payload => ({ type: actionType("subgroups/failure"), payload }),
        handleByFetchReducer: true
    },
    getAttributes: {
        init: () => ({ type: actionType("attributes/init") }),
        start: () => ({ type: actionType("attributes/start") }),
        success: payload => ({ type: actionType("attributes/success"), payload }),
        failure: payload => ({ type: actionType("attributes/failure"), payload }),
        handleByFetchReducer: true
    }
}

const fetchActionTypes = Object.values(fetchActions)
    .filter(({ handleByFetchReducer }) => handleByFetchReducer)
    .reduce((acc, actions) => {
        const actionTypes = Object.values(actions)
            .filter(action => typeof action === "function")
            .reduce((acc, action) => [...acc, action().type], [])
        return [...acc, ...actionTypes]
    }, [])

export const actions = {
    getInitialData: payload => ({ type: actionType("getInitialData"), payload }),
    setGroup: payload => ({ type: actionType("setGroup"), payload }),
    setSubgroup: payload => ({ type: actionType("setSubgroup"), payload }),
    addAttribute: () => ({ type: actionType("addAttribute") }),
    setAttribute: payload => ({ type: actionType("setAttribute"), payload }),
    removeAttribute: payload => ({ type: actionType("removeAttribute"), payload }),
    addCombinations: payload => ({ type: actionType("addCombinations"), payload }),
    updateCombination: payload => ({ type: actionType("updateCombination"), payload }),
    deleteCombination: payload => ({ type: actionType("deleteCombination"), payload }),
    setUnsavedChanges: payload => ({ type: actionType("setUnsavedChanges"), payload }),
    setEditedCombination: payload => ({ type: actionType("setEditedCombination"), payload }),
    resetCombinationsCreator: () => ({ type: actionType("resetCombinationsCreator") })
}

// REDUCER

const initialSelectValue = { value: null, label: "" }
const initialResource = {
    fetchStatus: initialState(),
    data: []
}
const treeStateAfterReset = { tree: {}, treeLevel: 0, unsavedChanges: true }

export const initialCombinationsCreatorState = Object.freeze({
    group: initialSelectValue,
    subgroup: initialSelectValue,
    pickedAttributes: [initialSelectValue],
    resources: {
        groups: initialResource,
        subgroups: initialResource,
        attributes: initialResource
    },
    ...treeStateAfterReset,
    unsavedChanges: false,
    modifiedIds: [],
    editedCombination: null
})

const fetchReducer = ({ state, action, key = "", fetchState = "" }) => {
    switch (fetchState) {
        case "init":
            return {
                ...state,
                resources: { ...state.resources, [key]: { data: [], fetchStatus: initialState() } }
            }
        case "start":
            return {
                ...state,
                resources: { ...state.resources, [key]: { data: [], fetchStatus: requestState() } }
            }
        case "success":
            return {
                ...state,
                resources: { ...state.resources, [key]: { data: action.payload, fetchStatus: successState() } }
            }
        case "failure":
            return {
                ...state,
                resources: { ...state.resources, [key]: { data: action.payload, fetchStatus: failureState() } }
            }
        default:
            return state
    }
}

export const reducer = (state = initialCombinationsCreatorState, action) => {
    if (fetchActionTypes.includes(action.type)) {
        const regex = /^combinations\/(.+)\/(.+)$/g
        const [key, fetchState] = regex.exec(action.type).slice(1, 3)

        return fetchReducer({ state, action, key, fetchState })
    }

    const { pickedAttributes, tree, treeLevel, modifiedIds, resources } = state

    switch (action.type) {
        case actions.setGroup().type:
            return { ...state, group: action.payload }
        case actions.setSubgroup().type:
            return { ...state, subgroup: action.payload, ...treeStateAfterReset }
        case actions.addAttribute().type:
            const isLastAttributeSelected = !!pickedAttributes[pickedAttributes.length - 1]
            return {
                ...state,
                pickedAttributes: isLastAttributeSelected ? [...pickedAttributes, initialSelectValue] : pickedAttributes
            }
        case actions.setAttribute().type:
            return {
                ...state,
                pickedAttributes: [...pickedAttributes.slice(0, -1), action.payload],
                ...(pickedAttributes.length <= treeLevel ? treeStateAfterReset : {})
            }
        case actions.removeAttribute().type:
            const setInitialAttributes = action.payload.forceInitialState || pickedAttributes.length < 2
            const newPickedAttributes = setInitialAttributes ? [initialSelectValue] : pickedAttributes.slice(0, -1)

            return {
                ...state,
                pickedAttributes: newPickedAttributes,
                ...(setInitialAttributes || newPickedAttributes.length < treeLevel ? treeStateAfterReset : {})
            }
        case actions.addCombinations().type:
            const treeWithNewCombinations = addCombinations(
                tree,
                action.payload,
                pickedAttributes.filter(({ value }) => !!value)
            )
            return {
                ...state,
                tree: treeWithNewCombinations,
                treeLevel: getTreeLevel(treeWithNewCombinations),
                unsavedChanges: true
            }
        case actions.updateCombination().type:
            const [newTree, modifiedId] = updateCombination(tree, action.payload)
            return {
                ...state,
                tree: newTree,
                unsavedChanges: true,
                modifiedIds:
                    modifiedId && !modifiedIds.includes(modifiedId) ? [...modifiedIds, modifiedId] : modifiedIds
            }
        case actions.deleteCombination().type:
            const treeWithoutCombinations = deleteCombination(tree, action.payload)
            return {
                ...state,
                tree: deleteCombination(tree, action.payload),
                treeLevel: getTreeLevel(treeWithoutCombinations),
                unsavedChanges: true
            }
        case actions.setUnsavedChanges().type:
            return { ...state, unsavedChanges: action.payload, modifiedIds: action.payload ? modifiedIds : [] }
        case actions.setEditedCombination().type:
            return {
                ...state,
                editedCombination: Array.isArray(action.payload)
                    ? {
                          accessor: action.payload,
                          names: action.payload.reduce(
                              ([names, treeBranch], id) =>
                                  Object.keys(treeBranch).includes(id)
                                      ? [[...names, treeBranch[id].name], treeBranch[id].children]
                                      : [names, {}],
                              [[], tree]
                          )[0],
                          values: get(tree, getTreePayloadPath(action.payload))
                      }
                    : null
            }
        case actions.resetCombinationsCreator().type:
            return initialCombinationsCreatorState

        case fetchActions.getInitialData.init().type:
            return { ...state, resources: { ...resources, groups: { data: [], fetchStatus: initialState() } } }
        case fetchActions.getInitialData.start().type:
            return { ...state, resources: { ...resources, groups: { data: [], fetchStatus: requestState() } } }
        case fetchActions.getInitialData.success().type:
            const newState = {
                ...state,
                ...(idx(action, _ => _.payload) || {})
            }
            return {
                ...newState,
                treeLevel: getTreeLevel(newState.tree)
            }
        case fetchActions.getInitialData.failure().type:
            return { ...state, resources: { ...resources, groups: { data: [], fetchStatus: failureState() } } }
        default:
            return state
    }
}

function addCombinations(tree, attributeValueIds, pickedAttributes = []) {
    if (pickedAttributes.length === 0 || !Array.isArray(pickedAttributes[0].values)) {
        return tree
    }
    const validAttributeValues = pickedAttributes[0].values.reduce(
        (acc, { id, value }) => ({ ...acc, [id]: value }),
        {}
    )
    const validAttributeValueIds = Object.keys(validAttributeValues).map(id => Number(id))
    const influencedAttributeValueIds = attributeValueIds.filter(id => validAttributeValueIds.includes(id))

    return influencedAttributeValueIds.reduce((acc, id) => {
        const attributeValueIsAlreadyInTree = Object.keys(acc).includes(id.toString())
        return {
            ...acc,
            [id]: attributeValueIsAlreadyInTree
                ? {
                      ...acc[id],
                      children: addCombinations(acc[id].children, attributeValueIds, pickedAttributes.slice(1))
                  }
                : {
                      attribute_value_id: id,
                      name: validAttributeValues[id],
                      payload: {
                          active: true,
                          impact: "+0",
                          default: false,
                          media_id: null
                      },
                      children: addCombinations({}, attributeValueIds, pickedAttributes.slice(1))
                  }
        }
    }, tree)
}

function updateCombination(tree, { accessor, newValue }) {
    const newTree = cloneDeep(tree)
    const path = getTreePayloadPath(accessor)
    const modifiedId = get(tree, [...path.slice(0, -1), "id"])
    update(newTree, path, payload => ({ ...payload, ...newValue }))

    return [newTree, modifiedId]
}

function getTreePayloadPath(accessor) {
    return flatMap(accessor, (attributeValueId, index, arr) => [
        attributeValueId,
        index === arr.length - 1 ? "payload" : "children"
    ])
}

function deleteCombination(tree, accessor) {
    const path = flatMap(accessor, (attributeValueId, index, arr) => [
        attributeValueId,
        ...(index === arr.length - 1 ? [] : ["children"])
    ])

    return omit(tree, [path])
}

export function treeToFlat(tree, modifiedIds = [], level = 0) {
    if (!isObject(tree)) {
        return []
    }

    return Object.values(tree).reduce((acc, { id, attribute_value_id, payload, children }) => {
        const validateImpact = payload =>
            typeof idx(payload, _ => _.impact) === "string"
                ? payload.impact.length === 1
                    ? `${payload.impact}0`
                    : payload.impact
                : "+0"
        const payloadNeeded = !id || modifiedIds.includes(id)
        const requiredFields = { id, attribute_value_id, level }
        const optionalFields = payloadNeeded ? { payload: { ...payload, impact: validateImpact(payload) } } : {}

        return [...acc, { ...requiredFields, ...optionalFields }, ...treeToFlat(children, modifiedIds, level + 1)]
    }, [])
}

export function flatToTree(flat, omitId = false, currentLevel = 0) {
    if (!Array.isArray(flat)) {
        return {}
    }

    return flat
        .map((item, index) => ({ ...item, index }))
        .filter(({ level }) => level === currentLevel)
        .reduce((acc, { id, attribute_value_id, attribute_value, level, payload, index }) => {
            const restOfArray = flat.slice(index + 1)
            const distanceToSameLevel = restOfArray.findIndex(item => item.level === level) + 1 || flat.length
            const arrayWithNestedLevels = restOfArray.slice(0, distanceToSameLevel)

            return {
                ...acc,
                [attribute_value_id]: {
                    ...(omitId ? {} : { id }),
                    name: attribute_value,
                    attribute_value_id,
                    payload,
                    children: flatToTree(arrayWithNestedLevels, omitId, currentLevel + 1)
                }
            }
        }, {})
}

export function getTreeLevel(tree, parentLevel = 0) {
    if (!isObject(tree) || Object.keys(tree).length === 0) {
        return parentLevel
    }

    const levels = Object.values(tree).reduce(
        (acc, { children = {} }) => [...acc, getTreeLevel(children, parentLevel + 1)],
        []
    )

    return Math.max(...levels)
}

// EPICS

const toDropdownValues = ({ data }) => data.map(({ id, name }) => ({ value: id, label: name }))
const toAttributeValues = ({ data }) =>
    data.map(({ id, name, attribute_values = [] }) => ({
        value: id,
        label: name,
        values: attribute_values
    }))

const getGroups = () => fetch.get$(`/attribute-groups?length=${LENGTH_WITHOUT_PAGINATION}`)
const getSubgroups = id =>
    fetch.get$(`/attribute-groups?length=${LENGTH_WITHOUT_PAGINATION}&parent_attribute_group_id=${id}`)
const getAttributes = id => fetch.get$(`/attributes?pagination=false&group_id=${id}`)

const getSubgroupsEpic = (action$, state$) =>
    action$.pipe(
        ofType(actions.setGroup().type),
        switchMap(action =>
            merge(
                iif(
                    () => !!action.payload.value,
                    getSubgroups(action.payload.value).pipe(
                        map(toDropdownValues),
                        map(fetchActions.getSubgroups.success),
                        catchError(fetchActions.getSubgroups.failure),
                        startWith(fetchActions.getSubgroups.start())
                    ),
                    of(fetchActions.getSubgroups.init())
                ),
                of(actions.setSubgroup(initialSelectValue))
            )
        )
    )

const getAttributesEpic = (action$, state$) =>
    action$.pipe(
        ofType(actions.setSubgroup().type),
        switchMap(action =>
            merge(
                iif(
                    () => !!action.payload.value,
                    getAttributes(action.payload.value).pipe(
                        map(toAttributeValues),
                        map(fetchActions.getAttributes.success),
                        catchError(fetchActions.getAttributes.failure),
                        startWith(fetchActions.getAttributes.start())
                    ),
                    of(fetchActions.getAttributes.init())
                ),
                of(actions.removeAttribute({ forceInitialState: true }))
            )
        )
    )

const initializeCombinationsCreatorStateEpic = (action$, state$) =>
    action$.pipe(
        ofType(actions.getInitialData().type),
        switchMap(({ payload: { type, id, omitId = false } }) =>
            fetch.get$(`/combination-patterns?object_type=${type}&object_id=${id}`).pipe(
                pluck("data"),
                map(({ attributes, attribute_values, groups: { primary, secondary } }) => ({
                    tree: flatToTree(attribute_values, omitId),
                    pickedAttributes: Object.keys(attributes).length
                        ? Object.values(attributes).map(({ id }) => ({ value: id }))
                        : [initialSelectValue],
                    group: primary ? { value: primary.id, label: primary.name } : initialSelectValue,
                    subgroup: secondary ? { value: secondary.id, label: secondary.name } : initialSelectValue
                })),
                flatMapRx(({ tree, pickedAttributes, group, subgroup }) =>
                    forkJoin({
                        tree: of(tree),
                        pickedAttributes: of(pickedAttributes),
                        group: of(group),
                        subgroup: of(subgroup),
                        groups: getGroups().pipe(map(toDropdownValues)),
                        subgroups: group.value ? getSubgroups(group.value).pipe(map(toDropdownValues)) : of([]),
                        attributes: subgroup.value ? getAttributes(subgroup.value).pipe(map(toAttributeValues)) : of([])
                    })
                ),
                map(({ tree, pickedAttributes, group, subgroup, groups, subgroups, attributes }) => ({
                    tree,
                    pickedAttributes: pickedAttributes.map(attribute => {
                        const { label, values } = attributes.find(({ value }) => value === attribute.value) || {
                            label: "",
                            values: []
                        }
                        return {
                            ...attribute,
                            label,
                            values
                        }
                    }),
                    group,
                    subgroup,
                    resources: {
                        groups: {
                            data: groups,
                            fetchStatus: successState()
                        },
                        subgroups: {
                            data: subgroups,
                            fetchStatus: group.value ? successState() : initialState()
                        },
                        attributes: {
                            data: attributes,
                            fetchStatus: subgroup.value ? successState() : initialState()
                        }
                    }
                })),
                map(fetchActions.getInitialData.success),
                catchError(fetchActions.getInitialData.failure),
                startWith(fetchActions.getInitialData.start())
            )
        )
    )

export const epic = combineEpics(getSubgroupsEpic, getAttributesEpic, initializeCombinationsCreatorStateEpic)
