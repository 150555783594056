import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { DocsTemplates, EmailTemplates, PaymentMethods, ShippingMethods, Ecommerce } from "./components"

const Orders = () => {
    return (
        <Switch>
            <Route path="/settings/orders/docs-templates" component={DocsTemplates} />
            <Route path="/settings/orders/email-templates" component={EmailTemplates} />
            <Route path="/settings/orders/payment-methods" component={PaymentMethods} />
            <Route path="/settings/orders/shipping-methods" component={ShippingMethods} />
            <Route path="/settings/orders/ecommerce" component={Ecommerce} />
            <Redirect exact from="/settings/orders" to="/settings/orders/payment-methods" />
        </Switch>
    )
}

export default Orders
