import {
    GET_PURCHASE_ORDERS_START,
    GET_PURCHASE_ORDERS_SUCCESS,
    GET_PURCHASE_ORDERS_ERROR,
    GET_PURCHASE_ORDER_START,
    GET_PURCHASE_ORDER_SUCCESS,
    GET_PURCHASE_ORDER_ERROR,
    DELETE_PURCHASE_ORDER_START,
    DELETE_PURCHASE_ORDER_SUCCESS,
    DELETE_PURCHASE_ORDER_ERROR,
    UPDATE_PURCHASE_ORDERS
} from "../constants"
import fetch from "../helpers/fetch"
import { objToQueryString } from "../helpers/urls"

const getPurchaseOrdersStart = () => ({ type: GET_PURCHASE_ORDERS_START })
const getPurchaseOrdersSuccess = data => ({ type: GET_PURCHASE_ORDERS_SUCCESS, payload: data })
const getPurchaseOrdersError = error => ({ type: GET_PURCHASE_ORDERS_ERROR, payload: error })

export const getPurchaseOrders = (params = {}) => {
    return dispatch => {
        dispatch(getPurchaseOrdersStart())

        return new Promise((resolve, reject) => {
            fetch
                .get(`/purchase-orders?${objToQueryString(params, true)}`)
                .then(data => {
                    dispatch(getPurchaseOrdersSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(getPurchaseOrdersError(error))
                    reject(error)
                })
        })
    }
}

const deletePurchaseOrderStart = () => ({ type: DELETE_PURCHASE_ORDER_START })
const deletePurchaseOrderSuccess = data => ({ type: DELETE_PURCHASE_ORDER_SUCCESS, payload: data })
const deletePurchaseOrderError = error => ({ type: DELETE_PURCHASE_ORDER_ERROR, payload: error })

export const deletePurchaseOrder = id => {
    return dispatch => {
        dispatch(deletePurchaseOrderStart())

        return new Promise((resolve, reject) => {
            return fetch
                .deleteRAW(`/purchase-orders/${id}`)
                .then(() => {
                    dispatch(deletePurchaseOrderSuccess(id))
                    resolve(id)
                })
                .catch(error => {
                    dispatch(deletePurchaseOrderError(error))
                    reject(error)
                })
        })
    }
}

const getPurchaseOrderStart = () => ({ type: GET_PURCHASE_ORDER_START })
const getPurchaseOrderSuccess = data => ({ type: GET_PURCHASE_ORDER_SUCCESS, payload: data })
const getPurchaseOrderError = error => ({ type: GET_PURCHASE_ORDER_ERROR, payload: error })

export const getPurchaseOrder = (id, forceFetch = false) => {
    return (dispatch, getState) => {
        const currentPurchaseOrder = getState().db.purchaseOrders.form

        if (!forceFetch && currentPurchaseOrder.data.id === Number(id)) {
            return Promise.resolve(currentPurchaseOrder)
        }

        dispatch(getPurchaseOrderStart())
        return new Promise((resolve, reject) => {
            fetch
                .get(`/purchase-orders/${id}`)
                .then(data => {
                    dispatch(getPurchaseOrderSuccess(data))
                    resolve(data)
                })
                .catch(error => {
                    dispatch(getPurchaseOrderError(error))
                    reject(error)
                })
        })
    }
}

export const createPurchaseOrder = body => dispatch => {
    return new Promise((resolve, reject) => {
        fetch
            .post("/purchase-orders", body)
            .then(data => {
                dispatch(getPurchaseOrderSuccess(data))
                resolve(data)
            })
            .catch(reject)
    })
}

export const updatePurchaseOrder = (id, body) => dispatch => {
    return new Promise((resolve, reject) => {
        fetch
            .patch(`/purchase-orders/${id}`, body)
            .then(data => {
                dispatch(getPurchaseOrderSuccess(data))
                resolve(data)
            })
            .catch(reject)
    })
}

export const updatePurchaseOrders = (id, body) => dispatch => {
    return new Promise((resolve, reject) => {
        fetch
            .patch(`/purchase-orders/${id}`, body)
            .then(data => {
                dispatch({
                    type: UPDATE_PURCHASE_ORDERS,
                    payload: {
                        id,
                        updatedFields: {
                            paid: data.data.paid,
                            paid_at: data.data.paid_at
                        }
                    }
                })
                resolve(data)
            })
            .catch(reject)
    })
}
