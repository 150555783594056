import { useCallback, useMemo } from "react"
import { useQueryClient } from "react-query"
import _isEqual from "lodash/isEqual"
import _sortBy from "lodash/sortBy"
import _uniq from "lodash/uniq"
import _intersection from "lodash/intersection"

import { useActions } from "hooks"
import { showErrorNotification, showSuccessNotification } from "actions/notification"
import { useUserWarehousesEdit } from "modules/WmsModule/hooks/api/useUser"

const getWarehouseIds = (warehouses = []) => warehouses.map(({ id }) => id)
const getSortedWarehouseIds = (warehouses = []) => _sortBy(getWarehouseIds(warehouses))
const getWarehouseNames = (warehouses = []) => warehouses.map(({ name }) => name).join(", ")

const useAssignUserToWarehouse = ({ selectedWarehouses, queryKey }) => {
    const actions = useActions({ showErrorNotification, showSuccessNotification })
    const queryClient = useQueryClient()

    const updateUserInQueryCache = useCallback(
        newUser =>
            queryClient.setQueryData(queryKey, cacheData => {
                const pages = cacheData.pages.reduce((pages, currentPage) => {
                    const pageData = {
                        ...currentPage,
                        data: currentPage.data.reduce((users, user) => {
                            const userToUpdate = user.id === newUser.id ? newUser : user

                            return [...users, userToUpdate]
                        }, [])
                    }
                    return [...pages, pageData]
                }, [])

                return {
                    ...cacheData,
                    pages
                }
            }),
        [queryKey, queryClient.setQueryData]
    )

    const { mutate } = useUserWarehousesEdit({
        reactQueryProps: {
            mutationKey: queryKey,
            onError: actions.showErrorNotification
        }
    })

    const selectedWarehouseIds = useMemo(() => getSortedWarehouseIds(selectedWarehouses), [selectedWarehouses])

    const checkIfShouldAssign = useCallback(
        user => {
            return !_isEqual(
                _sortBy(_intersection(selectedWarehouseIds, getWarehouseIds(user.warehouses))),
                selectedWarehouseIds
            )
        },
        [selectedWarehouseIds]
    )

    const onAssign = useCallback(
        user =>
            mutate(
                {
                    id: user.id,
                    warehouseIds: _uniq([...selectedWarehouseIds, ...getWarehouseIds(user.warehouses)])
                },
                {
                    onSuccess: ({ data: newUser }) => {
                        updateUserInQueryCache(newUser)
                        queryClient.invalidateQueries("warehouses")

                        actions.showSuccessNotification({
                            title: `Assigned to: ${getWarehouseNames(selectedWarehouses)}`
                        })
                    }
                }
            ),
        [selectedWarehouseIds, selectedWarehouses]
    )

    const onUnassign = useCallback(
        user => {
            return mutate(
                {
                    id: user.id,
                    warehouseIds: getWarehouseIds(user.warehouses).filter(
                        userWarehouseId => !selectedWarehouseIds.includes(userWarehouseId)
                    )
                },
                {
                    onSuccess: ({ data: newUser }) => {
                        updateUserInQueryCache(newUser)
                        queryClient.invalidateQueries("warehouses")

                        actions.showSuccessNotification({
                            title: `Unassigned from: ${getWarehouseNames(selectedWarehouses)}`
                        })
                    }
                }
            )
        },
        [selectedWarehouseIds, selectedWarehouses]
    )

    return { checkIfShouldAssign, onAssign, onUnassign }
}

export default useAssignUserToWarehouse
