import * as constants from "constants/index"

function searchBar(state = { params: {} }, action) {
    switch (action.type) {
        case constants.SET_SEARCH_BAR:
            return { ...state, ...action.payload }
        default:
            return state
    }
}

export default searchBar
