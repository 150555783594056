import React, { Component } from "react"
import { Link } from "react-router-dom"
import withStyles from "HOC/withStyles"
import Input from "components/Reusable/Form/Input/index"
import Button from "components/Reusable/Form/Button/index"
import styles from "./ForgotPassword.css"

class ForgotPassword extends Component {
    render() {
        const { isSaving, isSuccess, formFields, cx, handleSubmitAction } = this.props
        return (
            <form>
                {isSuccess && <div className={cx("check-email-info")}>All ok. Check email.</div>}

                <div className={cx("input-field")}>
                    {this.renderInput({
                        field: formFields.email,
                        className: "input"
                    })}
                </div>
                <div className={cx("separator")} />
                <div className={cx("remembered-text")}>
                    Just remembered?
                    <Link to="/login">Sign In</Link>
                </div>

                <Button
                    className={["second-button-big", "button"]}
                    customStyles={styles}
                    onClick={handleSubmitAction}
                    label="Send Reset Link"
                    isLoading={isSaving}
                />
                <div className="clear" />
            </form>
        )
    }

    renderInput({ field, className }) {
        const { handleFieldChange, isError } = this.props
        const errorMessage = isError ? "Error with sending reset link." : ""
        return (
            <Input
                {...field}
                error={field.error ? field.error : errorMessage}
                customStyles={styles}
                className={className}
                isPlaceholder={true}
                onChange={handleFieldChange}
            />
        )
    }
}

export default withStyles(ForgotPassword, styles)
