import React from "react"

import withStyles from "HOC/withStyles"
import styles from "./Zoom.module.css"

function Zoom(props) {
    const { cx, zoomDirection } = props
    const { background, name } = props.zoomValues

    return (
        <div className={cx("zoom-container", zoomDirection)}>
            <div style={background} className={cx("zoom-preview")} />
            <div className={cx("zoom-name")}>
                <p>{name}</p>
            </div>
        </div>
    )
}

export default withStyles(Zoom, styles)
