import React, { Component } from "react"
import { connect } from "react-redux"
import env from "env"
import { DEFAULT_FILE_UPLOAD_ENPOINT } from "constants/index"

const withUploader = WrappedComponent => {
    class Uploader extends Component {
        constructor(props) {
            super(props)

            this.state = {
                progress: 0,
                isUploading: false,
                isSuccess: false,
                error: ""
            }
        }

        handleUpload(addedFiles, rejectedFiles) {
            const { handleUploadReject } = this.props
            if (addedFiles && addedFiles.length === 1) {
                this.setState(
                    {
                        addedFile: {
                            title: addedFiles[0].name,
                            file: addedFiles[0]
                        },
                        isUploading: true,
                        progress: 0
                    },
                    this.handleUploadRequest
                )
            }

            if (rejectedFiles && rejectedFiles.length > 0) {
                this.setState(
                    {
                        progress: 0,
                        isUploading: false,
                        isSuccess: false,
                        error: `File ${
                            rejectedFiles[0] && rejectedFiles[0].name ? rejectedFiles[0].name : ""
                        } is too big or has wrong extension!`
                    },
                    () => handleUploadReject && handleUploadReject(this.state)
                )
            }
        }

        prepareFormData() {
            const { addedFile } = this.state
            const { fileFieldName, importsType, endpoint } = this.props

            const formData = new FormData()
            if (addedFile && addedFile.file && addedFile.title) {
                formData.append(fileFieldName, addedFile.file, addedFile.title)
            }
            if (importsType && endpoint.includes("/imports")) {
                formData.append("type", importsType)
            }

            return formData
        }

        handleUploadRequest() {
            const {
                endpoint,
                authorizationHeaders,
                handleUploadFailure,
                handleUploadProgress,
                getFileUrlFromResponse,
                isLoggedIn,
                idToken
            } = this.props

            const form = this.prepareFormData()
            const req = new XMLHttpRequest()

            req.open("POST", endpoint)
            Object.keys(authorizationHeaders).map(item => {
                req.setRequestHeader(
                    item,
                    isLoggedIn && item === "X-Authorization" ? idToken : authorizationHeaders[item]
                )
                return item
            })

            if (isLoggedIn && authorizationHeaders["X-Authorization"] === undefined) {
                req.setRequestHeader("X-Authorization", idToken)
            }

            req.setRequestHeader("Accept", "application/json")

            req.withCredentials = false

            req.addEventListener(
                "load",
                e => {
                    if (req.status >= 200 && req.status <= 299) {
                        this.handleUploadDone(getFileUrlFromResponse(req.response))
                    } else {
                        this.setState(
                            {
                                progress: 100,
                                isUploading: false,
                                isSuccess: false,
                                error: req.response || "Error with sending!"
                            },
                            () => handleUploadFailure && handleUploadFailure(this.state)
                        )
                    }
                },
                false
            )

            req.addEventListener(
                "error",
                e => {
                    this.setState(
                        {
                            error: "Error with sending!",
                            isUploading: false,
                            isSuccess: false
                        },
                        () => handleUploadFailure && handleUploadFailure(this.state)
                    )
                },
                false
            )

            req.upload &&
                req.upload.addEventListener(
                    "progress",
                    e => {
                        let progress = 0
                        if (e.total !== 0) {
                            progress = parseInt((e.loaded / e.total) * 100, 10)
                        }
                        this.setState(
                            {
                                progress
                            },
                            () => handleUploadProgress && handleUploadProgress(this.state)
                        )
                    },
                    false
                )

            req.addEventListener(
                "abort",
                e => {
                    this.setState({
                        progress: 0
                    })
                },
                false
            )

            req.send(form)
        }

        handleRemove() {
            this.setState({
                isUploading: false,
                isSuccess: false,
                addedFile: {}
            })
        }

        handleUploadDone(data) {
            const { addedFile } = this.state
            const { handleUploadSuccess } = this.props
            const { id, url } = data

            this.setState(
                {
                    addedFile: {
                        ...addedFile,
                        id,
                        url
                    },
                    response: data,
                    isUploading: false,
                    isSuccess: true,
                    progress: 100,
                    error: ""
                },
                () => handleUploadSuccess && handleUploadSuccess(this.state)
            )
        }

        render() {
            const { isUploading, isSuccess, progress, addedFile, error } = this.state

            return (
                <WrappedComponent
                    {...this.props}
                    uploader={{
                        state: {
                            isUploading,
                            isSuccess,
                            progress,
                            addedFile,
                            error
                        },
                        actions: {
                            handleUpload: this.handleUpload.bind(this),
                            handleRemove: this.handleRemove.bind(this)
                        }
                    }}
                />
            )
        }
    }

    Uploader.defaultProps = {
        endpoint: DEFAULT_FILE_UPLOAD_ENPOINT,
        authorizationHeaders: env.API.authorizationHeaders,
        fileFieldName: "file",
        getFileUrlFromResponse: response => {
            response = JSON.parse(response)

            if (response && response.data) {
                return response.data
            }

            if (response && response.path) {
                return response.path
            }

            return ""
        }
    }

    const mapDispatchToProps = state => {
        return {
            idToken: state.loginStore.token,
            isLoggedIn: state.loginStore.token !== null && state.loginStore.token !== false ? true : false
        }
    }

    return connect(mapDispatchToProps)(Uploader)
}

export default withUploader
