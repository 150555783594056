import React, { useState, useMemo, Fragment } from "react"
import { Link } from "react-router-dom"
import _get from "lodash/get"
import { useActions } from "hooks"
import getStyles from "helpers/getCxFromStyles"
import { Status, Toggle } from "ui"
import { Loader } from "@butterfly-frontend/ui"
import { showErrorNotification } from "actions/notification"
import ORDER_TYPE_KEYS from "modules/OrdersModule/constants/orderTypeKeys"
import SALES_ORDER_STATUS_KEYS from "modules/OrdersModule/constants/salesOrderStatusKeys"
import { formatPrice } from "helpers/units"

import styles from "./header.module.css"
import toogleStyles from "./overrides/toggle.css"

const Header = props => {
    const cx = getStyles(styles)
    const { id, uuid, label, client, created, lastUpdated, overviewConfig, inHouseSale } = props
    const { name, singleName, updateResource, isShowroomSaleAvailable } = overviewConfig
    const { status, type } = label
    const actions = useActions({
        updateResource,
        showErrorNotification
    })

    const [isStatusChanging, setIsStatusChanging] = useState(false)
    const [isShowroomSaleChanging, setIsShowroomSaleChanging] = useState(false)

    const handleChangeStatus = newStatus => {
        setIsStatusChanging(true)
        return actions
            .updateResource(id, { status: newStatus })
            .catch(error => {
                actions.showErrorNotification(_get(error, "message"))
            })
            .finally(() => setIsStatusChanging(false))
    }

    const handleChangeShowroomSale = value => {
        setIsShowroomSaleChanging(true)
        actions
            .updateResource(id, { in_house_sale: value })
            .catch(() => actions.showErrorNotification())
            .finally(() => setIsShowroomSaleChanging(false))
    }

    const isShowroomSaleVisible = useMemo(
        () =>
            !!isShowroomSaleAvailable &&
            status !== SALES_ORDER_STATUS_KEYS.COMPLETED &&
            status !== SALES_ORDER_STATUS_KEYS.CANCELLED,
        [isShowroomSaleAvailable, status]
    )

    return (
        <header className={cx("root")}>
            <section className={cx("clearfix", "title")}>
                <h1>
                    {name} <span>{uuid}</span>
                </h1>
                <div>
                    {isShowroomSaleVisible && (
                        <div className={cx("toggleWrapper", { disabled: isShowroomSaleChanging })}>
                            {isShowroomSaleChanging && <Loader classes={cx("loader")} />}
                            <Toggle
                                isOn={isShowroomSaleChanging ? !inHouseSale : inHouseSale}
                                isDisabled={isShowroomSaleChanging}
                                label={{ on: "Showroom sale", off: "Showroom sale" }}
                                handleChange={handleChangeShowroomSale}
                                labelPosition="left"
                                isSmall
                                customStyles={toogleStyles}
                            />
                        </div>
                    )}
                    <span>{singleName} status: </span>
                    <Status.Dropdown
                        status={status}
                        type={type}
                        onChange={handleChangeStatus}
                        isDisabled={
                            isStatusChanging ||
                            (type === ORDER_TYPE_KEYS.SALES && status === SALES_ORDER_STATUS_KEYS.COMPLETED)
                        }
                    />
                </div>
            </section>
            <hr />
            <section className={cx("clearfix", "info")}>
                <ul className={cx("details")}>
                    {client && (
                        <Fragment>
                            <li className={cx({ disabled: client.deleted_at })}>
                                <span>Client: </span>
                                <Link to={`/contact/${client.id}/profile`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            className={cx("withFill")}
                                            fillRule="nonzero"
                                            d="M13.75 14.666v-1.083c.262-.12 1.013-.95 1.092-1.599.207-.015.53-.19.627-.886.05-.373-.153-.582-.277-.649 0 0 .308-.543.308-1.199 0-1.315-.556-2.437-1.75-2.437 0 0-.415-.813-1.75-.813-2.475 0-3.5 1.474-3.5 3.25 0 .598.308 1.2.308 1.2-.124.066-.328.276-.277.648.096.695.42.871.627.886.079.648.83 1.478 1.092 1.6v1.082C9.667 16.292 5 15.208 5 19h14c0-3.792-4.667-2.708-5.25-4.334z"
                                        />
                                    </svg>
                                    <strong>{client.full_name}</strong>
                                </Link>
                            </li>
                            <li className={cx({ disabled: client.deleted_at })}>
                                <span>Available Store Credit: </span>
                                <span className={cx("orange")}>
                                    <strong>{formatPrice(client.store_credits)}</strong>
                                </span>
                            </li>
                        </Fragment>
                    )}
                </ul>
                <ul className={cx("dates")}>
                    <li>
                        <span>Created: </span>
                        <strong>{created}</strong>
                    </li>
                    <li>
                        <span>Last updated: </span>
                        <strong>{lastUpdated}</strong>
                    </li>
                </ul>
            </section>
        </header>
    )
}

export default Header
