import React, { useState, useEffect } from "react"
import { SelectWithSearch } from "ui"
import fetch from "helpers/fetch"

const SelectProject = ({ customStyles, handleSelect, label, name, value }) => {
    const [projects, setProjects] = useState([])

    useEffect(() => {
        fetchProjects()
    }, [])

    const fetchProjects = () => {
        const formatData = data => {
            return data.map(item => ({ id: item.id, value: item.id, label: item.name }))
        }

        fetch.get(`/projects?page=1&length=9999`).then(payload => {
            setProjects(formatData(payload.data))
        })
    }

    return (
        <SelectWithSearch
            name={name}
            label={label}
            value={value}
            values={projects}
            setValue={setValue}
            placeholder="- Please select -"
            customStyles={customStyles}
            withoutFetch
        />
    )

    function setValue(value) {
        handleSelect({ target: { name, value: value.value ? value : null } })
    }
}

SelectProject.defaultProps = {
    value: []
}

export default SelectProject
