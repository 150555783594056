import React, { Component } from "react"
import { Route, Switch } from "react-router-dom"
import { connect } from "react-redux"
import withTokenValidation from "HOC/withTokenValidation"

import MainPage from "../pages/MainPage"

class Routes extends Component {
    render() {
        return (
            <Switch>
                <Route path="/scraper/" render={() => <MainPage />} />
                <Route
                    render={props => {
                        document.location.href = props.location.pathname
                        return null
                    }}
                />
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default connect(
    mapStateToProps,
    null
)(withTokenValidation(Routes))
