import { combineReducers } from "redux"

import list from "./list"
import lastFetched from "./lastFetched"

const manualEntriesReducer = combineReducers({
    list,
    lastFetched
})

export default manualEntriesReducer
