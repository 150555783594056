import STOCK_ITEM_STATUSES from "./stockItemStatuses"
import STOCK_ITEM_STATUS_KEYS from "./stockItemStatusKeys"

const STOCK_ITEM_STATUS_FILTER_OPTIONS = [
    STOCK_ITEM_STATUSES[STOCK_ITEM_STATUS_KEYS.IN_STOCK],
    STOCK_ITEM_STATUSES[STOCK_ITEM_STATUS_KEYS.ON_HOLD],
    STOCK_ITEM_STATUSES[STOCK_ITEM_STATUS_KEYS.NOT_FOR_SALE],
    STOCK_ITEM_STATUSES[STOCK_ITEM_STATUS_KEYS.SOLD]
]

export default STOCK_ITEM_STATUS_FILTER_OPTIONS
