import React, { Component } from "react"
import Confirm from "components/Modal/Confirm/Confirm"
import Modal from "components/Modal/Modal"
import withStyles from "HOC/withStyles"

import styles from "./Note.css"
import moment from "moment"
import remove from "assets/close.svg"

class Note extends Component {
    state = {
        isConfirmShowed: false,
        isMoreShowed: false
    }

    render() {
        const { cx, text, creator, noteId, removeable, created_at, isLoading } = this.props
        const { date } = created_at || { date: "" }
        const { isConfirmShowed, isMoreShown } = this.state

        const modal = isConfirmShowed ? (
            <Modal>
                <Confirm
                    id={noteId}
                    confirmModalTitle="Are you sure you want to delete this note?"
                    handleHideModal={this.handleHideModal.bind(this)}
                    handleDelete={this.handleDelete.bind(this)}
                />
            </Modal>
        ) : null

        const hasMore = text && text.length > 190

        return (
            <div className={cx({ isLoading })}>
                <div className={cx("note-cover")}>
                    {modal}
                    <p
                        className={cx("description", { hasMore, hideMore: isMoreShown && hasMore })}
                        onClick={() => this.handleToggleShowMore()}
                    >
                        {text}
                    </p>
                    <h4 className={cx("date")}>{date && moment(date).format("MM/DD/YYYY")}</h4>
                    <h4 className={cx("name")}>{creator}</h4>
                    {removeable && (
                        <img
                            src={remove}
                            alt="remove"
                            className={cx("remove-note-icon")}
                            onClick={this.handleShowModal.bind(this)}
                        />
                    )}
                </div>
            </div>
        )
    }

    handleShowModal(event) {
        event.preventDefault()
        this.setState({ isConfirmShowed: true })
    }

    handleHideModal() {
        this.setState({ isConfirmShowed: false })
    }

    handleDelete() {
        const { deleteNote, noteId, contextId } = this.props
        this.setState({ isConfirmShowed: false }, () => {
            deleteNote({ noteId, contextId })
        })
    }

    handleToggleShowMore = () => {
        this.setState({
            isMoreShown: !this.state.isMoreShown
        })
    }
}

export default withStyles(Note, styles)
