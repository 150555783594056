import React, { Fragment } from "react"
import PropTypes from "prop-types"
import { useFormikContext } from "formik"

import { Checkbox, Input, ProductSearch, Radio } from "ui"
import ProductImageBar from "../ProductImageBar"

import withStyles from "HOC/withStyles"
import styles from "./ShowProductLink.css"
import inputStyles from "./overrides/inputStyles.css"
import productSearchStyles from "./overrides/productSearchStyles.css"
import radioStyles from "../SectionModalImage/overrides/Radio.css"

const ShowProductLink = props => {
    const {
        cx,
        linkPrefix,
        linkText,
        linkExternal,
        product,
        showLink,
        productLinkType,
        secondProductLinkType,
        isSecondProductShown,
        isLoading,
        selectProduct,
        secondLinkPrefix,
        secondLinkText,
        secondLinkExternal,
        secondProduct
    } = props

    const { setFieldError, setFieldValue } = useFormikContext()

    const onChange = (event, field) => {
        setFieldError(field.name, null)
        field.onChange(event)
    }
    const onShowLinkChange = () => showLink.onChange({ target: { ...showLink, value: !showLink.value } })

    const renderProductLink = ({ prefix, text, externalLink, productField, productLinkTypeField }) => (
        <div>
            <Input
                type="text"
                label={prefix.label}
                value={prefix.field.value || ""}
                customStyles={inputStyles}
                {...prefix.field}
                onChange={({ event }) => onChange(event, prefix.field)}
            />

            <Input
                type="text"
                label={text.label}
                value={text.field.value || ""}
                customStyles={inputStyles}
                {...text.field}
                onChange={({ event }) => onChange(event, text.field)}
            />

            <div className={cx("productLinkTypeWrapper")}>
                <Radio
                    checked={productLinkTypeField.value === "link"}
                    name={productLinkTypeField.name}
                    value="link"
                    label="Link"
                    handleSelect={(key, value) => {
                        setFieldError(productLinkTypeField.name, null)
                        setFieldValue(key, value)
                    }}
                    customStyles={radioStyles}
                    isHorizonal={true}
                />

                <Radio
                    checked={productLinkTypeField.value === "product"}
                    name={productLinkTypeField.name}
                    value="product"
                    label="Product"
                    handleSelect={(key, value) => {
                        setFieldError(productLinkTypeField.name, null)
                        setFieldValue(key, value)
                    }}
                    customStyles={radioStyles}
                    isHorizonal={true}
                />
            </div>

            {productLinkTypeField.value === "product" && (
                <div className={cx("productWrapper")}>
                    {!!productField.value || isLoading[productField.name] ? (
                        <ProductImageBar
                            isLoading={isLoading[productField.name]}
                            product={productField.value}
                            removeProduct={() => selectProduct(null, productField)}
                        />
                    ) : (
                        <div className={cx({ productError: productField.error })}>
                            <ProductSearch
                                placeholder="Search and add product…"
                                handleSelectProduct={id => selectProduct(id, productField)}
                                customStyles={productSearchStyles}
                                availableOnWeb
                            />
                            {productField.error && <span className={cx("error-message")}>{productField.error}</span>}
                        </div>
                    )}
                </div>
            )}

            {productLinkTypeField.value === "link" && (
                <div className={cx("linkWrapper")}>
                    <Input
                        type="text"
                        label={externalLink.label}
                        value={externalLink.field.value || ""}
                        customStyles={inputStyles}
                        {...externalLink.field}
                        onChange={({ event }) => onChange(event, externalLink.field)}
                    />
                </div>
            )}
        </div>
    )

    return (
        <div className={cx("root")}>
            <Checkbox
                id="showLink"
                checked={(showLink || {}).value || false}
                onChange={onShowLinkChange}
                name="Show link"
            />
            {showLink.value && (
                <Fragment>
                    <div className={cx("line")} />
                    <div className={cx("productsContainer", { isSecondProductShown })}>
                        {renderProductLink({
                            productLinkTypeField: productLinkType,
                            prefix: { label: "Text", field: linkPrefix },
                            text: { label: "Link name", field: linkText },
                            externalLink: { label: "Link", field: linkExternal },
                            productField: product
                        })}
                        {isSecondProductShown &&
                            renderProductLink({
                                productLinkTypeField: secondProductLinkType,
                                prefix: { label: "Second text", field: secondLinkPrefix },
                                text: { label: "Second link name", field: secondLinkText },
                                externalLink: { label: "Link", field: secondLinkExternal },
                                productField: secondProduct
                            })}
                    </div>
                </Fragment>
            )}
        </div>
    )
}

const formikFieldProps = PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.object]),
    onChange: PropTypes.func,
    error: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
})

ShowProductLink.propTypes = {
    isLoadig: PropTypes.shape({
        product: PropTypes.bool,
        second_product: PropTypes.bool
    }),
    isSecondProductShown: PropTypes.bool,
    showLink: formikFieldProps,
    productLinkType: formikFieldProps,
    secondProductLinkType: formikFieldProps,
    product: formikFieldProps,
    linkPrefix: formikFieldProps,
    linkText: formikFieldProps,
    linkExternal: formikFieldProps,
    selectProduct: PropTypes.func,
    secondLinkPrefix: formikFieldProps,
    secondLinkText: formikFieldProps,
    secondLinkExternal: formikFieldProps,
    secondProduct: formikFieldProps
}

export default withStyles(ShowProductLink, styles)
