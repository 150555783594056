import env from "env"
import * as constants from "constants/index"

let timeout = null
let isClientAssigned = false

export default store => next => action => {
    if (action.type === constants.TOGGLE_SESSION) {
        isClientAssigned = !isClientAssigned
    }

    if (action.type === constants.DISMISS_CLIENT) {
        isClientAssigned = false
    }

    clearTimeout(timeout)
    if (!isClientAssigned) {
        timeout = setTimeout(() => {
            window.location.href = "/logout"
        }, env.LOGIN_SESSION_TIMEOUT * 60 * 1000)
    }

    return next(action)
}
