import React, { Component } from "react"
import ReactTooltip from "react-tooltip"

import withStyles from "HOC/withStyles"

import SnakeLoader from "ui/FilterableTable/components/SnakeLoader/SnakeLoader"
import CalendarEntryDetails from "modules/CalendarModule/components/CalendarEntryDetails/CalendarEntryDetails"
import FullCalendarWrapper from "./FullCalendarWrapper"

import styles from "./CalendarGrid.css"

class CalendarGrid extends Component {
    constructor(props) {
        super(props)
        this.wrapperRef = React.createRef()
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    handleChangeDurationEvent = ({ event, prevEvent }) => {
        const { handleChangeDateEvent } = this.props

        const newDate = { start: event.start, end: event.end }
        const oldDate = { start: prevEvent.start, end: prevEvent.end }

        if (newDate.start !== oldDate.start && newDate.end !== oldDate.end) {
            handleChangeDateEvent(event.extendedProps.entry, { starts_at: event.start, ends_at: event.end })
        }
    }

    handleDropEvent = ({ event }) => {
        this.props.handleChangeDateEvent(event.extendedProps.entry, {
            starts_at: event.start,
            ends_at: event.end,
            isAllDay: event.allDay
        })
    }

    handleEventAllow = info => {
        const view = this.props.calendarRef.current.calendar.view

        return info.start > view.activeStart && info.end < view.activeEnd
    }

    handleClickOutside = event => {
        if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.props.handleEventClose()
        }
    }

    handleSuccessModerationEntry = () => {
        this.props.handleEventClose()
        this.props.fetchEntries()
    }

    render() {
        const {
            cx,
            calendar,
            entries,
            calendarRef,
            handleClickCellCalendar,
            entryDetails,
            handleEventClose,
            handleEventClick,
            handleOpenEditCalendarEntry,
            toggleModal,
            isHidden
        } = this.props

        return (
            <div className={cx("calendar-grid", { isHidden })}>
                <div className={cx("calendar-wrapper")}>
                    <FullCalendarWrapper
                        events={entries}
                        calendarRef={calendarRef}
                        handleEventPositioned={info => {
                            info.el.setAttribute("data-tip", info.event.title)
                            ReactTooltip.rebuild()
                        }}
                        handleDateClick={handleClickCellCalendar}
                        handleEventClick={handleEventClick}
                        handleEventResize={this.handleChangeDurationEvent}
                        handleEventDrop={this.handleDropEvent}
                        handleEventAllow={this.handleEventAllow}
                    />
                    <ReactTooltip effect="solid" />
                    {calendar.entries.isLoading && (
                        <div className={cx("loader")}>
                            <SnakeLoader />
                        </div>
                    )}
                </div>
                {entryDetails.visible && (
                    <div ref={this.wrapperRef}>
                        <CalendarEntryDetails
                            visible={entryDetails.visible}
                            data={entryDetails.data}
                            handleEventClose={handleEventClose}
                            handleDeleteAction={() => toggleModal("deleteEventModalVisible")}
                            handleEditAction={handleOpenEditCalendarEntry}
                            handleSuccessModeration={this.handleSuccessModerationEntry}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withStyles(CalendarGrid, styles)
